package com.dingvoice.core.data

class Formattable(private val callback: (values: Array<out Any>) -> String) {
    operator fun invoke(vararg values: Any): String {
        return callback(values)
    }

    fun callAsFunction(values: List<Any>): String {
        return callback(values.toTypedArray())
    }
}
