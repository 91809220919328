package com.dingvoice

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.dingvoice.components.Layout
import com.dingvoice.components.MainContentLayout
import com.dingvoice.localizations.Localizations
import com.dingvoice.style.AppStylesheet
import com.dingvoice.style.WtContainer.dropdownContainer
import com.dingvoice.style.WtContainer.dropdownContainerMobile
import com.dingvoice.style.WtContainer.dropdownOptionsContainer
import com.dingvoice.style.WtContainer.iconContainer
import com.dingvoice.style.WtContainer.iconContainerMobile
import com.dingvoice.style.WtContainer.readerFooterContainer
import com.dingvoice.style.WtContainer.readerFooterContainerMobile
import com.dingvoice.style.WtTexts.downloadAppLink
import com.dingvoice.style.WtTexts.downloadAppLinkMobile
import com.dingvoice.style.WtTexts.dropdownOption
import kotlinx.browser.window
import kotlinx.coroutines.await
import org.jetbrains.compose.web.attributes.href
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Object
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.events.Event
import org.w3c.dom.url.URLSearchParams
import pdfConverterUrl

@Composable
fun InvoiceReaderPage() {
    var isMobile by remember {
        mutableStateOf(window.isMobile())
    }

    val queryString = remember { window.location.search }
    val urlParams = remember { URLSearchParams(queryString) }
    val docId = remember { urlParams.get("id") }

    DisposableEffect(Unit) {
        val callback: ((Event) -> Unit) = { _ ->
            isMobile = window.isMobile()
        }
        window.addEventListener("resize", callback = callback)

        onDispose {
            window.removeEventListener("resize", callback)
        }
    }

    Style(AppStylesheet)

//    LaunchedEffect("https://$pdfConverterUrl/doc/company/$docId") {
//        val companyJson = window.fetch(
//            "https://$pdfConverterUrl/doc/company/$docId"
//        ).then { it.json() }.await().toString()
//        val companyInfo = Json.decodeFromString<Company>(companyJson)
//
//        var link = document.querySelector("link[rel~='icon']")
//        if (link == null) {
//            link = document.createElement("link")
//                .also {
//                    it.setAttribute("rel", "icon")
//                    document.head?.appendChild(it)
//                }
//        }
//        console.log("datass ${companyInfo.logo}")
//        companyInfo.logo?.let { link.setAttribute("href", it) }
//    }

    Layout {
        MainContentLayout(isMobile = isMobile) {
            var language by remember { mutableStateOf(urlParams.get("lan") ?: "en") }
            var isLoading by remember { mutableStateOf(true) }
            var pdfUrl by remember { mutableStateOf("") }

            LaunchedEffect(
                "https://$pdfConverterUrl/doc/pdf/$docId/$language"
            ) {
                isLoading = true
                pdfUrl = window.fetch(
                    "https://$pdfConverterUrl/doc/pdf/$docId/$language"
                ).then { it.text() }.await().toString()
                isLoading = false
            }

            LaunchedEffect(language) {
                Localizations.setLocale(language)
            }

            DocumentReader(
                isLoading = isLoading,
                pdfUrl = pdfUrl
            )

            ReaderFooter(
                isMobile = isMobile,
                language = language
            ) { code ->
                language = code
            }
        }
    }
}

@Composable
fun DocumentReader(isLoading: Boolean, pdfUrl: String): Boolean {
    if (isLoading) {
        Div(attrs = {
            style {
                marginTop(320.px)
                marginBottom(330.px)
                height(100.percent)
                width(100.percent)
                textAlign("center")
            }
        }) {
            Text(Localizations.currentLocale.loading)
        }
    } else {
        Object(attrs = {
            attr("data", pdfUrl)
            attr("type", "application/pdf")
            attr("width", "100%")
            attr("height", "600px")
        })
    }

    return isLoading
}

@Composable
fun ReaderFooter(isMobile: Boolean, language: String, onLanguageChange: (String) -> Unit) {
    Div({
        classes(if (isMobile) readerFooterContainerMobile else readerFooterContainer)
    }) {
        BottomBrandIcon(isMobile = isMobile)

        DownloadAppLink(isMobile = isMobile)

        LanguageSelector(
            isMobile = isMobile,
            language = language,
            onLanguageChange = onLanguageChange
        )
    }
}

@Composable
fun BottomBrandIcon(isMobile: Boolean) {
    Img("ic_splash.png", "DingVoice", attrs = {
        classes(if (isMobile) iconContainerMobile else iconContainer)
    })
}

@Composable
fun DownloadAppLink(isMobile: Boolean) {
    A(attrs = {
        classes(if (isMobile) downloadAppLinkMobile else downloadAppLink)

        href(
            "https://play.google.com/store/apps/details?id=com.dingvoice.androidApp&launch=true&utm_source=landing&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        )
    }) {
        Text(Localizations.currentLocale.download_app)
    }
}

@Composable
fun LanguageSelector(isMobile: Boolean, language: String, onLanguageChange: (String) -> Unit) {
    val localeCountries by remember {
        derivedStateOf {
            Localizations.getAllLocales()
                .map {
                    it.displayName to it.code
                }
                .toSet()
                .sortedBy { (displayLanguage, _) -> displayLanguage }
        }
    }

    Div({
        classes(if (isMobile) dropdownContainerMobile else dropdownContainer)
    }) {
        A(attrs = {
            classes("btn", "dropdown-toggle", dropdownOption)
            attr("role", "button")
            attr("data-toggle", "dropdown")
            href("#")
        }) {
            Text(
                Localizations.currentLocale.language_selector(
                    Localizations.getAvailableMapping(
                        language
                    ).displayName
                )
            )
        }
        Div({
            classes("dropdown-menu", dropdownOptionsContainer)
        }) {
            localeCountries.forEach { (displayName, code) ->
                A(attrs = {
                    onClick {
                        onLanguageChange(code)
                    }
                    classes("dropdown-item")
                    style {
                        color(
                            Color(
                                if (language == code) "#3D3D3D" else "#7E7E7E"
                            )
                        )
                    }
                }) {
                    Text(displayName)
                }
            }
        }
    }
}
