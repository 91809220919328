// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Lt  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Priimti ${termsOfService} ir ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Neteisingas kodas."
override val add_client_action: String = "Pridėti klientą"
override val add_customer: String = "Pridėti klientą"
override val add_expense: String = "Pridėti išlaidas"
override val add_fields_label: String = "Pridėti"
override val add_invoice_action: String = "Sukurti"
override val add_item_action: String = "Pridėti prekę"
override val add_item_button: String = "Pridėti prekę"
override val add_signature_action: String = "Pridėti parašą"
override val add_tax_action: String = "Pridėti mokestį"
override val address_fields: String = "Adresas"
override val address_line_hint: String = "Adreso eilutė"
override val advanced: String = "Išplėstinė"
override val agree_marketing: String = "Sutinku gauti tiesioginės rinkodaros pranešimus"
override val all_notifications: String = "Visi pranešimai"
override val amortization: String = "amortization"
override val amount: String = "Suma"
override val asset: String = "asset"
override val attach_file: String = "Pridėti failą"
override val attachment: String = "Priedas"
override val auth_error: String = "Veiksmas nepavyko, bandykite dar kartą."
override val available_for_paid: String = "Sinchronizacija su debesimi galima tik pagal mokamą prenumeratą."
override val backups_option: String = "Atsarginės kopijos"
override val balance: String = "balance"
override val bank_account: String = "Sąskaita"
override val bill_number: String = "Sąskaitos numeris"
override val cancel: String = "Atšaukti"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Kategorijos"
override val category: String = "Kategorija"
override val category_add: String = "Pridėti kategoriją"
override val category_delete: String = "Ištrinti kategoriją"
override val category_name: String = "Kategorijos pavadinimas"
override val change_language: Formattable = Formattable { (language) -> "Sąskaitos faktūros kalba: ${language}" }
override val change_language_label: String = "Sąskaitos faktūros kalba"
override val change_template: String = "Keisti šabloną"
override val city_hint: String = "Miestas"
override val clear: String = "Išvalyti"
override val client: String = "Klientas"
override val client_actions: String = "Kliento veiksmai"
override val client_deleted_message: String = "Klientas pašalintas"
override val clients_error_required_fields: String = "Būtina nurodyti bent vieną reikšmę."
override val clients_option: String = "Klientai"
override val close: String = "Uždaryti"
override val cloud_sync: String = "Sinchronizavimas su debesimi"
override val coming_soon: String = "Jau greitai!"
override val company_logo: String = "Įmonės logotipas"
override val company_name_hint: String = "Organizacija"
override val confirm_password_label: String = "Patvirtinkite slaptažodį"
override val confirmation_title: String = "Ar tikrai?"
override val convert_to_invoice: String = "Konvertuoti į sąskaitą faktūrą"
override val country_hint: String = "Šalis"
override val create_client: String = "Sukurti klientą"
override val create_company: String = "Sukurti įmonę"
override val create_document: String = "Sukurti dokumentą"
override val create_estimate: String = "Apskaičiuokite"
override val create_invoice: String = "Sąskaita faktūra"
override val create_item: String = "Sukurti produktą"
override val create_new_client: String = "Sukurti naują klientą"
override val create_new_item: String = "Kurti naują prekę"
override val create_new_tax: String = "Sukurti naują mokestį"
override val create_signature: String = "Parašas"
override val created: String = "Sukurta"
override val credit: String = "credit"
override val customer: String = "Klientas"
override val date: String = "Data"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Numatytoji valiuta: ${currency}" }
override val default_currency_label: String = "Numatytoji valiuta"
override val delete: String = "Ištrinti"
override val delete_account: String = "Ištrinti paskyrą"
override val delete_confirmation_message: String = "Visi jūsų duomenys bus prarasti."
override val delete_item_title: String = "Ištrinti elementą?"
override val delete_signature: String = "Ištrinti parašą"
override val depreciation: String = "depreciation"
override val description: String = "Aprašymas"
override val description_hint: String = "Aprašymas"
override val detailed_report: String = "Išsami ataskaita"
override val disabled: String = "Išjungta"
override val discount: String = "Nuolaida"
override val discount_hint: String = "Nuolaida"
override val display_af: String = "Afrikiečių kalba"
override val display_am: String = "Amharų kalba"
override val display_ar: String = "Arabų kalba"
override val display_as: String = "Asamių kalba"
override val display_az: String = "Azerbaidžanas"
override val display_be: String = "Baltarusijos"
override val display_bg: String = "Bulgarų"
override val display_bn: String = "Bengalų kalba"
override val display_bs: String = "Bosnijos ir Hercegovinos"
override val display_ca: String = "Katalonų kalba"
override val display_cs: String = "Čekijos"
override val display_da: String = "Danų"
override val display_de: String = "Vokiečių kalba"
override val display_el: String = "Graikų kalba"
override val display_en: String = "Anglų kalba"
override val display_es: String = "Ispanų kalba"
override val display_es_MX: String = "Ispanų kalba (Meksika)"
override val display_et: String = "Estų kalba"
override val display_eu: String = "Baskų kalba"
override val display_fa: String = "Persų kalba"
override val display_fi: String = "Suomijos"
override val display_fil: String = "Filipinų"
override val display_fr: String = "Prancūzų kalba"
override val display_fr_CA: String = "Kanados prancūzų kalba"
override val display_gl: String = "Galisijos"
override val display_gu: String = "Gudžarati"
override val display_he: String = "Hebrajų kalba"
override val display_hi: String = "Hindi"
override val display_hr: String = "Kroatijos"
override val display_hu: String = "Vengrų"
override val display_hy: String = "Armėnų"
override val display_id: String = "Indonezijos"
override val display_is: String = "Islandijos"
override val display_it: String = "Italų kalba"
override val display_ja: String = "Japonų"
override val display_ka: String = "Gruzinų kalba"
override val display_kk: String = "Kazachų"
override val display_km: String = "Centriniai khmerai"
override val display_kn: String = "Kanados"
override val display_ko: String = "Korėjiečių kalba"
override val display_ky: String = "Kirgizų"
override val display_lo: String = "Lao"
override val display_lt: String = "Lietuvių kalba"
override val display_lv: String = "Latvijos"
override val display_mk: String = "Makedonijos"
override val display_ml: String = "Malajalamas"
override val display_mn: String = "Mongoliškas"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malajų kalba"
override val display_my: String = "Birmos"
override val display_nb: String = "Norvegų bokmål"
override val display_ne: String = "Nepalo"
override val display_nl: String = "Olandų kalba"
override val display_or: String = "Oriya"
override val display_pa: String = "Pandžabo"
override val display_pl: String = "Lenkų kalba"
override val display_pt: String = "Portugalų kalba"
override val display_pt_BR: String = "portugalų kalba (Brazilija)"
override val display_pt_PT: String = "Europos portugalų kalba"
override val display_ro: String = "Rumunijos"
override val display_ru: String = "Rusų kalba"
override val display_si: String = "Sinhalų kalba"
override val display_sk: String = "Slovakų"
override val display_sl: String = "Slovėnijos"
override val display_sq: String = "Albanų kalba"
override val display_sr: String = "Serbų kalba"
override val display_sv: String = "Švedų kalba"
override val display_sw: String = "Svahilių kalba"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Tailando"
override val display_tr: String = "Turkijos"
override val display_uk: String = "Ukrainiečių"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbekų"
override val display_vi: String = "Vietnamiečių"
override val display_zh_Hant_TW: String = "Kinų kalba (Taivanas, tradicinė)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Sąmata"
override val document_invoice: String = "Sąskaita faktūra"
override val document_quote: String = "Sąmata"
override val done: String = "Atlikta"
override val download_app: String = "Atsisiųsti programėlę"
override val download_invoice: String = "Atsisiųsti sąskaitą faktūrą"
override val download_pdf: String = "Atsisiųsti PDF"
override val draw_signature: String = "Nubraižykite parašą"
override val due: Formattable = Formattable { (data) -> "Mokėjimo terminas ${data}" }
override val due_date: String = "Terminas"
override val due_day: String = "per dieną"
override val due_label: String = "Sumokėti iki"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} is due ${dueWhen}!" }
override val due_today: String = "Šiandien"
override val due_week: String = "per savaitę"
override val duplicate: String = "Dublikuoti"
override val duration_day: String = "diena"
override val duration_month: String = "Mėnuo"
override val duration_week: String = "savaitė"
override val duration_year: String = "metai"
override val email: String = "El.paštas"
override val email_blank_error: String = "Būtina nurodyti el. paštą"
override val email_error: String = "Neteisingas el. pašto adresas"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Dėkojame Jums už bendradarbiavimą. Norėdami peržiūrėti visą ${documentType} spauskite šio laiško pabaigoje esantį mygtuką. Atkreipkite dėmesį, kad jis galioja iki ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Gavote naują ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "PERŽIŪRĖTI ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Gerbiamas ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Dėkojame Jums už bendradarbiavimą. Norėdami peržiūrėti visą ${documentType} spauskite šio laiško pabaigoje esantį mygtuką. Likutį prašome sumokėti iki ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Gavote naują ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Išsiųstas slaptažodžio atstatymo el. laiškas, patikrinkite savo pašto dėžutę."
override val empty_clients_list_message: String = "Neturite klientų, kuriuos reikėtų stebėti. Pridėkite naują klientą."
override val empty_estimate_list_message: String = "Sukurkite pirmąją sąmatą"
override val empty_expenses: String = "Jums nereikia stebėti išlaidų. Įtraukite naujas išlaidas."
override val empty_invoice_list_message: String = "Sukurkite pirmąją sąskaitą faktūrą"
override val empty_items_list_message: String = "Neturite elementų, pridėkite naują elementą!"
override val empty_signatures_list_message: String = "Parašų nerasta. Sukurkite vieną!"
override val empty_taxes_list_message: String = "Jums netaikomos jokios mokesčių taisyklės. Pridėkite naują mokesčių taisyklę."
override val empty_templates_list_message: String = "Šablonai neprieinami."
override val enabled: String = "Įjungta"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Sąmata #"
override val estimates: String = "Apskaičiavimai"
override val estimates_report: String = "Sąmatos"
override val expense_bank_fees: String = "Banko mokesčiai"
override val expense_benefits: String = "Privalumai"
override val expense_communication: String = "Bendravimas"
override val expense_debt: String = "Skola"
override val expense_deleted: String = "Ištrintos išlaidos"
override val expense_depreciation: String = "Nusidėvėjimas"
override val expense_gifts: String = "Dovanos"
override val expense_insurance: String = "Draudimas"
override val expense_inventory: String = "Inventorius"
override val expense_legal: String = "Teisinis"
override val expense_maintenance: String = "Techninė priežiūra"
override val expense_marketing: String = "Rinkodara"
override val expense_meals: String = "Maitinimas"
override val expense_office_supplies: String = "Biuro reikmenys"
override val expense_rent: String = "Nuoma"
override val expense_salaries: String = "Atlyginimai"
override val expense_shopping: String = "Apsipirkimas"
override val expense_software: String = "Programinė įranga"
override val expense_status_available: String = "galima rasti"
override val expense_status_processing: String = "Apdorojimas"
override val expense_tax: String = "Mokesčiai"
override val expense_training: String = "Mokymas"
override val expense_transport: String = "Transportas"
override val expense_travel: String = "Kelionės"
override val expense_utility: String = "Komunalinės paslaugos"
override val expenses: String = "Išlaidos"
override val feedback_option: String = "Atsiliepimai"
override val filter: String = "Filtras"
override val first_name_hint: String = "Vardas"
override val forgot_password: String = "Atkurti slaptažodį"
override val forgot_password_label: String = "Pamiršote slaptažodį?"
override val from: String = "Nuo"
override val general_error: String = "Įvyko klaida, bandykite vėliau."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Grįžti"
override val group_by: String = "Grupė pagal:"
override val image_view: String = "Vaizdas"
override val install: String = "Įdiegti"
override val invalid_credentials_error: String = "Negaliojantys prisijungimo duomenys"
override val invalid_email_error: String = "Neteisingas el. pašto adresas"
override val invalid_user_error: String = "Neteisingi naudotojo įgaliojimai."
override val invoice_deleted_message: String = "Ištrinta sąskaita faktūra"
override val invoice_menu: String = "Sąskaitos faktūros meniu"
override val invoice_number: String = "Sąskaita faktūra #"
override val invoices: String = "Sąskaitos"
override val items_option: String = "Elementai"
override val keep: String = "Laikyti"
override val language_selector: Formattable = Formattable { (selected) -> "Kalba ${selected}" }
override val last_name_hint: String = "Pavardė"
override val ledger: String = "ledger"
override val legal: String = "Teisinė informacija"
override val legal_error: String = "Turi sutikti su paslaugų teikimo sąlygomis ir privatumo politika"
override val liabilities: String = "liabilities"
override val loading: String = "Įkeliama…"
override val login_required_error: String = "Reikalingas prisijungimas."
override val logo_view: String = "Logotipas"
override val logout: String = "Atsijungti"
override val logout_prompt: String = "Jūsų duomenys nesinchronizuojami su debesimi. Ištrinti sąskaitas faktūras, sąmatas, klientus ir kitą informaciją atsijungus?"
override val main_screen: String = "Pagrindinis ekranas"
override val mark_paid: String = "Pažymėti kaip apmokėtą"
override val message: String = "Pranešimas"
override val missing_sender_msg: String = "Dokumentas be siuntėjo informacijos gali būti negaliojantis."
override val missing_sender_title: String = "Trūksta siuntėjo informacijos"
override val month_april: String = "Balandis"
override val month_april_short: String = "Balandis"
override val month_august: String = "Rugpjūtis"
override val month_august_short: String = "Rgp."
override val month_december: String = "Gruodžio mėn."
override val month_december_short: String = "Grd"
override val month_february: String = "Vasaris"
override val month_february_short: String = "Vas."
override val month_january: String = "Sausis"
override val month_january_short: String = "Sau."
override val month_july: String = "Liepa"
override val month_july_short: String = "Liepa"
override val month_june: String = "Birželio mėn."
override val month_june_short: String = "Birželio mėn."
override val month_march: String = "Kovas"
override val month_march_short: String = "Kovas"
override val month_may: String = "Gegužė"
override val month_may_short: String = "Gegužė"
override val month_november: String = "Lapkritis"
override val month_november_short: String = "Lap."
override val month_october: String = "Spalio mėn."
override val month_october_short: String = "Spl"
override val month_september: String = "Rugsėjis"
override val month_september_short: String = "Rugsj"
override val multifactor_error: String = "Nepavyko patikrinti."
override val new: String = "Naujas"
override val news: String = "Naujienos"
override val next: String = "Kitas"
override val no: String = "no"
override val no_data_available: String = "Nėra duomenų"
override val no_search_results: String = "Atsiprašome, neradome jokių rezultatų."
override val none: String = "nėra"
override val note_fields: String = "Pastabos"
override val note_hint: String = "Pastaba"
override val notifications_option: String = "Pranešimai"
override val ok: String = "GERAI"
override val onboarding: String = "Prisijungimas"
override val onboarding_aboutus_message: String = "Esminės „DingVoice“ dalys yra amžinai nemokamos. Mūsų misija – padėti jums plėsti verslą, padėti mums tobulėti dalinantis atsiliepimais."
override val onboarding_aboutus_title: String = "Esame čia, kad padėtume"
override val onboarding_paid_1: String = "Pasiekite profesionaliai atrodančius šablonus"
override val onboarding_paid_2: String = "Atidžiau pažvelkite į savo finansus"
override val onboarding_paid_3: String = "Greitai išspręskite problemas"
override val onboarding_paid_4: String = "Saugokite savo dokumentus"
override val onboarding_paid_5: String = "Sekite informaciją apie sąskaitos faktūros būsenos pokyčius"
override val onboarding_paid_title: String = "Augkite dar greičiau su..."
override val onboarding_paywall_message: String = "Mėgaukitės visomis aukščiausios kokybės funkcijomis dabar už geriausią kainą."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Tiesiog ${currencySymbol}${introAmount} už ${introDuration} ${introPeriod} ir tada ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Tik ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Nemokamai ${trialDuration} ${trialPeriod} ir tada tik ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Nemokamai ${trialDuration} ${trialPeriod} tada ${currencySymbol}${introductoryAmount} už ${introductoryDuration} ${introPeriod} ir tada tik ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automatiškai atsinaujinantis. Atšaukti bet kada."
override val onboarding_paywall_restore: String = "Atkurti pirkimą"
override val onboarding_paywall_restore_failure: String = "Pirkinių atkūrimas nepavyko, bandykite vėliau arba kreipkitės į palaikymo komandą."
override val onboarding_paywall_restore_success: String = "Atkūrimas sėkmingas!"
override val onboarding_paywall_title: String = "Pasiekite didybės!"
override val onboarding_welcome_message: String = "Išsamus įrankių rinkinys sąskaitoms faktūroms ir sąmatoms tvarkyti."
override val onboarding_welcome_title: String = "Sveiki atvykę į „DingVoice“."
override val other_state: String = "Kita"
override val overdue_state: String = "Vėluojantis"
override val paid_state: String = "Apmokėta"
override val password: String = "Slaptažodis"
override val password_blank_error: String = "Reikalingas slaptažodis"
override val password_mismatch_error: String = "Slaptažodžiai nesutampa"
override val pay: String = "pay"
override val payment_instructions_label: String = "Mokėjimo instrukcijos"
override val payment_reminders: String = "Mokėjimo priminimai"
override val phone_number_fields: String = "Telefono numeris"
override val phone_number_hint: String = "Telefono numeris"
override val postcode_hint: String = "Pašto kodas"
override val preview: String = "Peržiūra"
override val preview_menu_hint: String = "Peržiūros meniu"
override val price: String = "Kaina"
override val privacy_policy: String = "Privatumo politika"
override val profile: String = "Profilis"
override val profitable_client: String = "Jūsų pelningiausias klientas yra..."
override val profits_report: String = "Pelnas"
override val promo_content: String = "Reklaminis turinys"
override val quantity_hint: String = "Kiekis"
override val quantity_label_hint: String = "Kiekis Etiketė"
override val query_info: String = "Užklausa leidžia rašyti pasirinktinius skaičiavimus, kuriuose kaip įvesties duomenys nurodomi ir elementai, ir kitos mokesčių taisyklės. Pridėkite @ simbolį ir elemento arba mokesčio aprašymą, kad pateiktumėte nuorodą į kitą įrašą +, -, *, /, % - matematiniai operatoriai"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} gavo jūsų klientas." }
override val received_state: String = "Gauta"
override val registration_code_fields: String = "Kodas"
override val reminder: String = "Priminimas"
override val reports: String = "Ataskaitos"
override val reports_not_available: String = "Ataskaitų nėra, grįžkite vėliau."
override val reveal_password: String = "Atskleisti slaptažodį"
override val revenue: String = "revenue"
override val sales_tax: String = "PVM"
override val sales_tax_number: String = "PVM mokėtojo kodas"
override val save: String = "Išsaugoti"
override val scan_document: String = "Skenuoti dokumentą"
override val search: String = "Paieška"
override val select: String = "Pasirinkite"
override val select_icon: String = "Pasirinkite piktogramą"
override val select_signature: String = "Pasirinkite parašą"
override val select_template: String = "Pasirinkite šabloną"
override val send: String = "Siųsti"
override val sent_state: String = "Išsiųsta"
override val setup_sender: String = "Nustatyti siuntėją"
override val share_option: String = "Dalintis"
override val ship_to: String = "Išsiųsti į"
override val sign_in: String = "Prisijungti"
override val sign_in_apple: String = "Prisijunkite su „Apple“"
override val sign_in_email: String = "Prisijungti el. paštu"
override val sign_in_facebook: String = "Prisijungti per „Facebook“"
override val sign_in_google: String = "Prisijungti naudojant „Google“"
override val sign_up: String = "Prisiregistruoti"
override val sign_up_label: String = "Neturite paskyros?"
override val sign_up_link_label: String = "Prisiregistruoti"
override val signatures_option: String = "Parašai"
override val skip: String = "Praleisti"
override val sold_items_report: String = "Parduotos prekės"
override val sort_by: String = "Rūšiuoti pagal:"
override val status_update: String = "Dokumento būsenos atnaujinimas"
override val sub_annual: String = "Metinis"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/metus" }
override val sub_backups: String = "Atsarginės kopijos debesyje"
override val sub_insights: String = "Įžvalgos"
override val sub_month: String = "Mėnesinis"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mėn." }
override val sub_notifications: String = "Pranešimai"
override val sub_popup_message: String = "Prenumeratos bus netrukus. Po kurio laiko vėl apsilankykite čia."
override val sub_support: String = "Pagalba"
override val sub_templates: String = "Šablonai"
override val subscribe: String = "Užsiprenumeruoti"
override val subscription_header: String = "Auginkite savo verslą su:"
override val subscription_option: String = "Prenumerata"
override val subscriptions_load_fail: String = "Atsiprašome, šiuo metu negalime atkurti jūsų prenumeratos. Prašome pabandyti vėliau arba susisiekti su mūsų palaikymo komanda ir paprašyti pagalbos."
override val subtotal: Formattable = Formattable { (sum) -> "Tarpinė suma: ${sum}" }
override val subtotal_label: String = "Tarpinė suma"
override val sum: String = "Suma"
override val support_option: String = "Pagalba"
override val switch_reports: String = "Perjungti ataskaitą"
override val tax: String = "Mokesčiai"
override val tax_deleted_message: String = "Išbraukti mokesčiai"
override val tax_description_warning: String = "Jei naudojama, tai rodoma sąskaitoje faktūroje"
override val tax_input_flat: String = "Flat"
override val tax_input_percent: String = "Procentai"
override val tax_number_fields: String = "Mokesčių numeris"
override val tax_number_hint: String = "Mokesčių numeris"
override val tax_query_info_description: String = "Užklausos informacija"
override val tax_report: String = "Mokesčiai"
override val tax_rules_option: String = "Mokesčių taisyklės"
override val taxed_clients_report: String = "Apmokestinti klientai"
override val template: String = "Šablonas"
override val template_selector_info: String = "Braukite, kad pakeistumėte numatytąjį šabloną"
override val template_unlock_promo: String = "Daugiau šablonų su Premium"
override val templates_option: String = "Šablonai"
override val terms_of_service: String = "Paslaugų teikimo sąlygos"
override val thank_you: String = "Ačiū!"
override val to: String = "Gavėjas"
override val total: Formattable = Formattable { (sum) -> "Iš viso: ${sum}" }
override val total_label: String = "IŠ VISO"
override val undo_action: String = "Panaikinti"
override val unit: String = "Matas"
override val unit_cost_hint: String = "Vieneto kaina"
override val unknown_client: String = "Nežinomas klientas"
override val unlock: String = "Atrakinti"
override val unnamed_item: String = "Neįvardytas elementas"
override val upgrade: String = "Atnaujinti"
override val user_collision_error: String = "Tokia paskyra jau yra."
override val valid_until: String = "Galioja iki"
override val warning: String = "Įspėjimas!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Priimti ${termsOfService} ir ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Neteisingas kodas.",
	"add_client_action" to "Pridėti klientą",
	"add_customer" to "Pridėti klientą",
	"add_expense" to "Pridėti išlaidas",
	"add_fields_label" to "Pridėti",
	"add_invoice_action" to "Sukurti",
	"add_item_action" to "Pridėti prekę",
	"add_item_button" to "Pridėti prekę",
	"add_signature_action" to "Pridėti parašą",
	"add_tax_action" to "Pridėti mokestį",
	"address_fields" to "Adresas",
	"address_line_hint" to "Adreso eilutė",
	"advanced" to "Išplėstinė",
	"agree_marketing" to "Sutinku gauti tiesioginės rinkodaros pranešimus",
	"all_notifications" to "Visi pranešimai",
	"amortization" to "amortization",
	"amount" to "Suma",
	"asset" to "asset",
	"attach_file" to "Pridėti failą",
	"attachment" to "Priedas",
	"auth_error" to "Veiksmas nepavyko, bandykite dar kartą.",
	"available_for_paid" to "Sinchronizacija su debesimi galima tik pagal mokamą prenumeratą.",
	"backups_option" to "Atsarginės kopijos",
	"balance" to "balance",
	"bank_account" to "Sąskaita",
	"bill_number" to "Sąskaitos numeris",
	"cancel" to "Atšaukti",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Kategorijos",
	"category" to "Kategorija",
	"category_add" to "Pridėti kategoriją",
	"category_delete" to "Ištrinti kategoriją",
	"category_name" to "Kategorijos pavadinimas",
	"change_language" to Formattable { (language) -> "Sąskaitos faktūros kalba: ${language}" },
	"change_language_label" to "Sąskaitos faktūros kalba",
	"change_template" to "Keisti šabloną",
	"city_hint" to "Miestas",
	"clear" to "Išvalyti",
	"client" to "Klientas",
	"client_actions" to "Kliento veiksmai",
	"client_deleted_message" to "Klientas pašalintas",
	"clients_error_required_fields" to "Būtina nurodyti bent vieną reikšmę.",
	"clients_option" to "Klientai",
	"close" to "Uždaryti",
	"cloud_sync" to "Sinchronizavimas su debesimi",
	"coming_soon" to "Jau greitai!",
	"company_logo" to "Įmonės logotipas",
	"company_name_hint" to "Organizacija",
	"confirm_password_label" to "Patvirtinkite slaptažodį",
	"confirmation_title" to "Ar tikrai?",
	"convert_to_invoice" to "Konvertuoti į sąskaitą faktūrą",
	"country_hint" to "Šalis",
	"create_client" to "Sukurti klientą",
	"create_company" to "Sukurti įmonę",
	"create_document" to "Sukurti dokumentą",
	"create_estimate" to "Apskaičiuokite",
	"create_invoice" to "Sąskaita faktūra",
	"create_item" to "Sukurti produktą",
	"create_new_client" to "Sukurti naują klientą",
	"create_new_item" to "Kurti naują prekę",
	"create_new_tax" to "Sukurti naują mokestį",
	"create_signature" to "Parašas",
	"created" to "Sukurta",
	"credit" to "credit",
	"customer" to "Klientas",
	"date" to "Data",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Numatytoji valiuta: ${currency}" },
	"default_currency_label" to "Numatytoji valiuta",
	"delete" to "Ištrinti",
	"delete_account" to "Ištrinti paskyrą",
	"delete_confirmation_message" to "Visi jūsų duomenys bus prarasti.",
	"delete_item_title" to "Ištrinti elementą?",
	"delete_signature" to "Ištrinti parašą",
	"depreciation" to "depreciation",
	"description" to "Aprašymas",
	"description_hint" to "Aprašymas",
	"detailed_report" to "Išsami ataskaita",
	"disabled" to "Išjungta",
	"discount" to "Nuolaida",
	"discount_hint" to "Nuolaida",
	"display_af" to "Afrikiečių kalba",
	"display_am" to "Amharų kalba",
	"display_ar" to "Arabų kalba",
	"display_as" to "Asamių kalba",
	"display_az" to "Azerbaidžanas",
	"display_be" to "Baltarusijos",
	"display_bg" to "Bulgarų",
	"display_bn" to "Bengalų kalba",
	"display_bs" to "Bosnijos ir Hercegovinos",
	"display_ca" to "Katalonų kalba",
	"display_cs" to "Čekijos",
	"display_da" to "Danų",
	"display_de" to "Vokiečių kalba",
	"display_el" to "Graikų kalba",
	"display_en" to "Anglų kalba",
	"display_es" to "Ispanų kalba",
	"display_es_MX" to "Ispanų kalba (Meksika)",
	"display_et" to "Estų kalba",
	"display_eu" to "Baskų kalba",
	"display_fa" to "Persų kalba",
	"display_fi" to "Suomijos",
	"display_fil" to "Filipinų",
	"display_fr" to "Prancūzų kalba",
	"display_fr_CA" to "Kanados prancūzų kalba",
	"display_gl" to "Galisijos",
	"display_gu" to "Gudžarati",
	"display_he" to "Hebrajų kalba",
	"display_hi" to "Hindi",
	"display_hr" to "Kroatijos",
	"display_hu" to "Vengrų",
	"display_hy" to "Armėnų",
	"display_id" to "Indonezijos",
	"display_is" to "Islandijos",
	"display_it" to "Italų kalba",
	"display_ja" to "Japonų",
	"display_ka" to "Gruzinų kalba",
	"display_kk" to "Kazachų",
	"display_km" to "Centriniai khmerai",
	"display_kn" to "Kanados",
	"display_ko" to "Korėjiečių kalba",
	"display_ky" to "Kirgizų",
	"display_lo" to "Lao",
	"display_lt" to "Lietuvių kalba",
	"display_lv" to "Latvijos",
	"display_mk" to "Makedonijos",
	"display_ml" to "Malajalamas",
	"display_mn" to "Mongoliškas",
	"display_mr" to "Marathi",
	"display_ms" to "Malajų kalba",
	"display_my" to "Birmos",
	"display_nb" to "Norvegų bokmål",
	"display_ne" to "Nepalo",
	"display_nl" to "Olandų kalba",
	"display_or" to "Oriya",
	"display_pa" to "Pandžabo",
	"display_pl" to "Lenkų kalba",
	"display_pt" to "Portugalų kalba",
	"display_pt_BR" to "portugalų kalba (Brazilija)",
	"display_pt_PT" to "Europos portugalų kalba",
	"display_ro" to "Rumunijos",
	"display_ru" to "Rusų kalba",
	"display_si" to "Sinhalų kalba",
	"display_sk" to "Slovakų",
	"display_sl" to "Slovėnijos",
	"display_sq" to "Albanų kalba",
	"display_sr" to "Serbų kalba",
	"display_sv" to "Švedų kalba",
	"display_sw" to "Svahilių kalba",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Tailando",
	"display_tr" to "Turkijos",
	"display_uk" to "Ukrainiečių",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbekų",
	"display_vi" to "Vietnamiečių",
	"display_zh_Hant_TW" to "Kinų kalba (Taivanas, tradicinė)",
	"display_zu" to "Zulu",
	"document_estimate" to "Sąmata",
	"document_invoice" to "Sąskaita faktūra",
	"document_quote" to "Sąmata",
	"done" to "Atlikta",
	"download_app" to "Atsisiųsti programėlę",
	"download_invoice" to "Atsisiųsti sąskaitą faktūrą",
	"download_pdf" to "Atsisiųsti PDF",
	"draw_signature" to "Nubraižykite parašą",
	"due" to Formattable { (data) -> "Mokėjimo terminas ${data}" },
	"due_date" to "Terminas",
	"due_day" to "per dieną",
	"due_label" to "Sumokėti iki",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} is due ${dueWhen}!" },
	"due_today" to "Šiandien",
	"due_week" to "per savaitę",
	"duplicate" to "Dublikuoti",
	"duration_day" to "diena",
	"duration_month" to "Mėnuo",
	"duration_week" to "savaitė",
	"duration_year" to "metai",
	"email" to "El.paštas",
	"email_blank_error" to "Būtina nurodyti el. paštą",
	"email_error" to "Neteisingas el. pašto adresas",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Dėkojame Jums už bendradarbiavimą. Norėdami peržiūrėti visą ${documentType} spauskite šio laiško pabaigoje esantį mygtuką. Atkreipkite dėmesį, kad jis galioja iki ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Gavote naują ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "PERŽIŪRĖTI ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Gerbiamas ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Dėkojame Jums už bendradarbiavimą. Norėdami peržiūrėti visą ${documentType} spauskite šio laiško pabaigoje esantį mygtuką. Likutį prašome sumokėti iki ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Gavote naują ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Išsiųstas slaptažodžio atstatymo el. laiškas, patikrinkite savo pašto dėžutę.",
	"empty_clients_list_message" to "Neturite klientų, kuriuos reikėtų stebėti. Pridėkite naują klientą.",
	"empty_estimate_list_message" to "Sukurkite pirmąją sąmatą",
	"empty_expenses" to "Jums nereikia stebėti išlaidų. Įtraukite naujas išlaidas.",
	"empty_invoice_list_message" to "Sukurkite pirmąją sąskaitą faktūrą",
	"empty_items_list_message" to "Neturite elementų, pridėkite naują elementą!",
	"empty_signatures_list_message" to "Parašų nerasta. Sukurkite vieną!",
	"empty_taxes_list_message" to "Jums netaikomos jokios mokesčių taisyklės. Pridėkite naują mokesčių taisyklę.",
	"empty_templates_list_message" to "Šablonai neprieinami.",
	"enabled" to "Įjungta",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Sąmata #",
	"estimates" to "Apskaičiavimai",
	"estimates_report" to "Sąmatos",
	"expense_bank_fees" to "Banko mokesčiai",
	"expense_benefits" to "Privalumai",
	"expense_communication" to "Bendravimas",
	"expense_debt" to "Skola",
	"expense_deleted" to "Ištrintos išlaidos",
	"expense_depreciation" to "Nusidėvėjimas",
	"expense_gifts" to "Dovanos",
	"expense_insurance" to "Draudimas",
	"expense_inventory" to "Inventorius",
	"expense_legal" to "Teisinis",
	"expense_maintenance" to "Techninė priežiūra",
	"expense_marketing" to "Rinkodara",
	"expense_meals" to "Maitinimas",
	"expense_office_supplies" to "Biuro reikmenys",
	"expense_rent" to "Nuoma",
	"expense_salaries" to "Atlyginimai",
	"expense_shopping" to "Apsipirkimas",
	"expense_software" to "Programinė įranga",
	"expense_status_available" to "galima rasti",
	"expense_status_processing" to "Apdorojimas",
	"expense_tax" to "Mokesčiai",
	"expense_training" to "Mokymas",
	"expense_transport" to "Transportas",
	"expense_travel" to "Kelionės",
	"expense_utility" to "Komunalinės paslaugos",
	"expenses" to "Išlaidos",
	"feedback_option" to "Atsiliepimai",
	"filter" to "Filtras",
	"first_name_hint" to "Vardas",
	"forgot_password" to "Atkurti slaptažodį",
	"forgot_password_label" to "Pamiršote slaptažodį?",
	"from" to "Nuo",
	"general_error" to "Įvyko klaida, bandykite vėliau.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Grįžti",
	"group_by" to "Grupė pagal:",
	"image_view" to "Vaizdas",
	"install" to "Įdiegti",
	"invalid_credentials_error" to "Negaliojantys prisijungimo duomenys",
	"invalid_email_error" to "Neteisingas el. pašto adresas",
	"invalid_user_error" to "Neteisingi naudotojo įgaliojimai.",
	"invoice_deleted_message" to "Ištrinta sąskaita faktūra",
	"invoice_menu" to "Sąskaitos faktūros meniu",
	"invoice_number" to "Sąskaita faktūra #",
	"invoices" to "Sąskaitos",
	"items_option" to "Elementai",
	"keep" to "Laikyti",
	"language_selector" to Formattable { (selected) -> "Kalba ${selected}" },
	"last_name_hint" to "Pavardė",
	"ledger" to "ledger",
	"legal" to "Teisinė informacija",
	"legal_error" to "Turi sutikti su paslaugų teikimo sąlygomis ir privatumo politika",
	"liabilities" to "liabilities",
	"loading" to "Įkeliama…",
	"login_required_error" to "Reikalingas prisijungimas.",
	"logo_view" to "Logotipas",
	"logout" to "Atsijungti",
	"logout_prompt" to "Jūsų duomenys nesinchronizuojami su debesimi. Ištrinti sąskaitas faktūras, sąmatas, klientus ir kitą informaciją atsijungus?",
	"main_screen" to "Pagrindinis ekranas",
	"mark_paid" to "Pažymėti kaip apmokėtą",
	"message" to "Pranešimas",
	"missing_sender_msg" to "Dokumentas be siuntėjo informacijos gali būti negaliojantis.",
	"missing_sender_title" to "Trūksta siuntėjo informacijos",
	"month_april" to "Balandis",
	"month_april_short" to "Balandis",
	"month_august" to "Rugpjūtis",
	"month_august_short" to "Rgp.",
	"month_december" to "Gruodžio mėn.",
	"month_december_short" to "Grd",
	"month_february" to "Vasaris",
	"month_february_short" to "Vas.",
	"month_january" to "Sausis",
	"month_january_short" to "Sau.",
	"month_july" to "Liepa",
	"month_july_short" to "Liepa",
	"month_june" to "Birželio mėn.",
	"month_june_short" to "Birželio mėn.",
	"month_march" to "Kovas",
	"month_march_short" to "Kovas",
	"month_may" to "Gegužė",
	"month_may_short" to "Gegužė",
	"month_november" to "Lapkritis",
	"month_november_short" to "Lap.",
	"month_october" to "Spalio mėn.",
	"month_october_short" to "Spl",
	"month_september" to "Rugsėjis",
	"month_september_short" to "Rugsj",
	"multifactor_error" to "Nepavyko patikrinti.",
	"new" to "Naujas",
	"news" to "Naujienos",
	"next" to "Kitas",
	"no" to "no",
	"no_data_available" to "Nėra duomenų",
	"no_search_results" to "Atsiprašome, neradome jokių rezultatų.",
	"none" to "nėra",
	"note_fields" to "Pastabos",
	"note_hint" to "Pastaba",
	"notifications_option" to "Pranešimai",
	"ok" to "GERAI",
	"onboarding" to "Prisijungimas",
	"onboarding_aboutus_message" to "Esminės „DingVoice“ dalys yra amžinai nemokamos. Mūsų misija – padėti jums plėsti verslą, padėti mums tobulėti dalinantis atsiliepimais.",
	"onboarding_aboutus_title" to "Esame čia, kad padėtume",
	"onboarding_paid_1" to "Pasiekite profesionaliai atrodančius šablonus",
	"onboarding_paid_2" to "Atidžiau pažvelkite į savo finansus",
	"onboarding_paid_3" to "Greitai išspręskite problemas",
	"onboarding_paid_4" to "Saugokite savo dokumentus",
	"onboarding_paid_5" to "Sekite informaciją apie sąskaitos faktūros būsenos pokyčius",
	"onboarding_paid_title" to "Augkite dar greičiau su...",
	"onboarding_paywall_message" to "Mėgaukitės visomis aukščiausios kokybės funkcijomis dabar už geriausią kainą.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Tiesiog ${currencySymbol}${introAmount} už ${introDuration} ${introPeriod} ir tada ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Tik ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Nemokamai ${trialDuration} ${trialPeriod} ir tada tik ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Nemokamai ${trialDuration} ${trialPeriod} tada ${currencySymbol}${introductoryAmount} už ${introductoryDuration} ${introPeriod} ir tada tik ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automatiškai atsinaujinantis. Atšaukti bet kada.",
	"onboarding_paywall_restore" to "Atkurti pirkimą",
	"onboarding_paywall_restore_failure" to "Pirkinių atkūrimas nepavyko, bandykite vėliau arba kreipkitės į palaikymo komandą.",
	"onboarding_paywall_restore_success" to "Atkūrimas sėkmingas!",
	"onboarding_paywall_title" to "Pasiekite didybės!",
	"onboarding_welcome_message" to "Išsamus įrankių rinkinys sąskaitoms faktūroms ir sąmatoms tvarkyti.",
	"onboarding_welcome_title" to "Sveiki atvykę į „DingVoice“.",
	"other_state" to "Kita",
	"overdue_state" to "Vėluojantis",
	"paid_state" to "Apmokėta",
	"password" to "Slaptažodis",
	"password_blank_error" to "Reikalingas slaptažodis",
	"password_mismatch_error" to "Slaptažodžiai nesutampa",
	"pay" to "pay",
	"payment_instructions_label" to "Mokėjimo instrukcijos",
	"payment_reminders" to "Mokėjimo priminimai",
	"phone_number_fields" to "Telefono numeris",
	"phone_number_hint" to "Telefono numeris",
	"postcode_hint" to "Pašto kodas",
	"preview" to "Peržiūra",
	"preview_menu_hint" to "Peržiūros meniu",
	"price" to "Kaina",
	"privacy_policy" to "Privatumo politika",
	"profile" to "Profilis",
	"profitable_client" to "Jūsų pelningiausias klientas yra...",
	"profits_report" to "Pelnas",
	"promo_content" to "Reklaminis turinys",
	"quantity_hint" to "Kiekis",
	"quantity_label_hint" to "Kiekis Etiketė",
	"query_info" to "Užklausa leidžia rašyti pasirinktinius skaičiavimus, kuriuose kaip įvesties duomenys nurodomi ir elementai, ir kitos mokesčių taisyklės. Pridėkite @ simbolį ir elemento arba mokesčio aprašymą, kad pateiktumėte nuorodą į kitą įrašą +, -, *, /, % - matematiniai operatoriai",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} gavo jūsų klientas." },
	"received_state" to "Gauta",
	"registration_code_fields" to "Kodas",
	"reminder" to "Priminimas",
	"reports" to "Ataskaitos",
	"reports_not_available" to "Ataskaitų nėra, grįžkite vėliau.",
	"reveal_password" to "Atskleisti slaptažodį",
	"revenue" to "revenue",
	"sales_tax" to "PVM",
	"sales_tax_number" to "PVM mokėtojo kodas",
	"save" to "Išsaugoti",
	"scan_document" to "Skenuoti dokumentą",
	"search" to "Paieška",
	"select" to "Pasirinkite",
	"select_icon" to "Pasirinkite piktogramą",
	"select_signature" to "Pasirinkite parašą",
	"select_template" to "Pasirinkite šabloną",
	"send" to "Siųsti",
	"sent_state" to "Išsiųsta",
	"setup_sender" to "Nustatyti siuntėją",
	"share_option" to "Dalintis",
	"ship_to" to "Išsiųsti į",
	"sign_in" to "Prisijungti",
	"sign_in_apple" to "Prisijunkite su „Apple“",
	"sign_in_email" to "Prisijungti el. paštu",
	"sign_in_facebook" to "Prisijungti per „Facebook“",
	"sign_in_google" to "Prisijungti naudojant „Google“",
	"sign_up" to "Prisiregistruoti",
	"sign_up_label" to "Neturite paskyros?",
	"sign_up_link_label" to "Prisiregistruoti",
	"signatures_option" to "Parašai",
	"skip" to "Praleisti",
	"sold_items_report" to "Parduotos prekės",
	"sort_by" to "Rūšiuoti pagal:",
	"status_update" to "Dokumento būsenos atnaujinimas",
	"sub_annual" to "Metinis",
	"sub_annual_price" to Formattable { (price) -> "${price}/metus" },
	"sub_backups" to "Atsarginės kopijos debesyje",
	"sub_insights" to "Įžvalgos",
	"sub_month" to "Mėnesinis",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mėn." },
	"sub_notifications" to "Pranešimai",
	"sub_popup_message" to "Prenumeratos bus netrukus. Po kurio laiko vėl apsilankykite čia.",
	"sub_support" to "Pagalba",
	"sub_templates" to "Šablonai",
	"subscribe" to "Užsiprenumeruoti",
	"subscription_header" to "Auginkite savo verslą su:",
	"subscription_option" to "Prenumerata",
	"subscriptions_load_fail" to "Atsiprašome, šiuo metu negalime atkurti jūsų prenumeratos. Prašome pabandyti vėliau arba susisiekti su mūsų palaikymo komanda ir paprašyti pagalbos.",
	"subtotal" to Formattable { (sum) -> "Tarpinė suma: ${sum}" },
	"subtotal_label" to "Tarpinė suma",
	"sum" to "Suma",
	"support_option" to "Pagalba",
	"switch_reports" to "Perjungti ataskaitą",
	"tax" to "Mokesčiai",
	"tax_deleted_message" to "Išbraukti mokesčiai",
	"tax_description_warning" to "Jei naudojama, tai rodoma sąskaitoje faktūroje",
	"tax_input_flat" to "Flat",
	"tax_input_percent" to "Procentai",
	"tax_number_fields" to "Mokesčių numeris",
	"tax_number_hint" to "Mokesčių numeris",
	"tax_query_info_description" to "Užklausos informacija",
	"tax_report" to "Mokesčiai",
	"tax_rules_option" to "Mokesčių taisyklės",
	"taxed_clients_report" to "Apmokestinti klientai",
	"template" to "Šablonas",
	"template_selector_info" to "Braukite, kad pakeistumėte numatytąjį šabloną",
	"template_unlock_promo" to "Daugiau šablonų su Premium",
	"templates_option" to "Šablonai",
	"terms_of_service" to "Paslaugų teikimo sąlygos",
	"thank_you" to "Ačiū!",
	"to" to "Gavėjas",
	"total" to Formattable { (sum) -> "Iš viso: ${sum}" },
	"total_label" to "IŠ VISO",
	"undo_action" to "Panaikinti",
	"unit" to "Matas",
	"unit_cost_hint" to "Vieneto kaina",
	"unknown_client" to "Nežinomas klientas",
	"unlock" to "Atrakinti",
	"unnamed_item" to "Neįvardytas elementas",
	"upgrade" to "Atnaujinti",
	"user_collision_error" to "Tokia paskyra jau yra.",
	"valid_until" to "Galioja iki",
	"warning" to "Įspėjimas!",
	"yes" to "yes",
    )
    
}