// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Bn  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} এবং ${privacyPolicy} স্বীকার করুন" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "অবৈধ কোড।"
override val add_client_action: String = "ক্লায়েন্ট যোগ করুন"
override val add_customer: String = "গ্রাহক যোগ করুন"
override val add_expense: String = "ব্যয় যোগ করুন"
override val add_fields_label: String = "যুক্ত:"
override val add_invoice_action: String = "তৈরি"
override val add_item_action: String = "আইটেম জুড়ুন"
override val add_item_button: String = "আইটেম জুড়ুন"
override val add_signature_action: String = "স্বাক্ষর যোগ করুন"
override val add_tax_action: String = "ট্যাক্স যোগ করুন"
override val address_fields: String = "ঠিকানা"
override val address_line_hint: String = "ঠিকানা লাইন"
override val advanced: String = "উন্নত"
override val agree_marketing: String = "আমি সরাসরি বিপণন যোগাযোগ পেতে সম্মত"
override val all_notifications: String = "সকল বিজ্ঞপ্তি"
override val amortization: String = "amortization"
override val amount: String = "পরিমান"
override val asset: String = "asset"
override val attach_file: String = "ফাইল সংযুক্ত করুন"
override val attachment: String = "সংযুক্তি"
override val auth_error: String = "ক্রিয়াকলাপ ব্যর্থ হয়েছে, আবার চেষ্টা করুন।"
override val available_for_paid: String = "ক্লাউড সিঙ্ক কেবলমাত্র প্রদত্ত সদস্যতার অধীনে সুলভ।"
override val backups_option: String = "ব্যাকআপ"
override val balance: String = "balance"
override val bank_account: String = "ব্যাংক একাউন্ট"
override val bill_number: String = "বিল নম্বর"
override val cancel: String = "বাতিল"
override val cancel_trial: String = "যেকোনো সময় বাতিল করুন"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "বিভাগ"
override val category: String = "বিভাগ"
override val category_add: String = "বিভাগ যোগ করুন"
override val category_delete: String = "বিভাগ মুছে ফেলুন"
override val category_name: String = "বিভাগ নাম"
override val change_language: Formattable = Formattable { (language) -> "চালানের ভাষা: ${language}" }
override val change_language_label: String = "চালানের ভাষা"
override val change_template: String = "টেমপ্লেট পরিবর্তন করুন"
override val city_hint: String = "শহর"
override val clear: String = "অবলোপ"
override val client: String = "ক্লায়েন্ট"
override val client_actions: String = "ক্লায়েন্ট ক্রিয়াকলাপ"
override val client_deleted_message: String = "ক্লায়েন্ট মোছা হয়েছে"
override val clients_error_required_fields: String = "কমপক্ষে একটি মান প্রয়োজন।"
override val clients_option: String = "ক্লায়েন্টরা"
override val close: String = "বন্ধ"
override val cloud_sync: String = "ক্লাউড সিঙ্ক"
override val coming_soon: String = "শীঘ্রই আসছে!"
override val company_logo: String = "কোম্পানির লোগো"
override val company_name_hint: String = "কোম্পানি"
override val confirm_password_label: String = "পাসওয়ার্ড নিশ্চিত করুন"
override val confirmation_title: String = "তুমি কি নিশ্চিত?"
override val convert_to_invoice: String = "চালানে রূপান্তর করুন"
override val country_hint: String = "দেশ"
override val create_client: String = "ক্লায়েন্ট তৈরি করুন"
override val create_company: String = "কোম্পানী তৈরি করুন"
override val create_document: String = "নথি তৈরি করুন"
override val create_estimate: String = "অনুমান"
override val create_invoice: String = "চালান"
override val create_item: String = "আইটেম তৈরি করুন"
override val create_new_client: String = "নতুন ক্লায়েন্ট তৈরি করুন"
override val create_new_item: String = "নতুন বিষয়োপকরণ তৈরি করুন"
override val create_new_tax: String = "নতুন ট্যাক্স তৈরি করুন"
override val create_signature: String = "স্বাক্ষর"
override val created: String = "তৈরি"
override val credit: String = "credit"
override val customer: String = "ক্রেতা"
override val date: String = "তারিখ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "ডিফল্ট মুদ্রা: ${currency}" }
override val default_currency_label: String = "ডিফল্ট মুদ্রা"
override val delete: String = "বিলোপ"
override val delete_account: String = "অ্যাকাউন্ট মুছুন"
override val delete_confirmation_message: String = "আপনার সমস্ত ডেটা হারিয়ে যাবে।"
override val delete_item_title: String = "আইটেমটি মুছবেন?"
override val delete_signature: String = "স্বাক্ষর মুছুন"
override val depreciation: String = "depreciation"
override val description: String = "বর্ণনা"
override val description_hint: String = "বর্ণনা"
override val detailed_report: String = "বিস্তারিত প্রতিবেদন"
override val disabled: String = "অক্ষম"
override val discount: String = "ডিসকাউন্ট"
override val discount_hint: String = "ডিসকাউন্ট"
override val display_af: String = "আফ্রিকাবাসী"
override val display_am: String = "আমহারিক"
override val display_ar: String = "Arabic"
override val display_as: String = "আসামি"
override val display_az: String = "আজারবাইজানীয়"
override val display_be: String = "বেলারুশিয়"
override val display_bg: String = "বুলগেরীয়"
override val display_bn: String = "বাঙালি"
override val display_bs: String = "বসনীয়"
override val display_ca: String = "কাতালান"
override val display_cs: String = "Czech"
override val display_da: String = "ডেনমার্কবাসী"
override val display_de: String = "জার্মান"
override val display_el: String = "গ্রীক"
override val display_en: String = "ইংরেজি"
override val display_es: String = "স্পেনীয়"
override val display_es_MX: String = "স্পেনীয় (মেক্সিকো)"
override val display_et: String = "এস্তোনীয়"
override val display_eu: String = "বাস্ক"
override val display_fa: String = "পারসিক"
override val display_fi: String = "ফিনিশ"
override val display_fil: String = "ফিলিপিনো"
override val display_fr: String = "ফরাসি"
override val display_fr_CA: String = "কানাডিয়ান ফরাসী"
override val display_gl: String = "গ্যালিশিয়"
override val display_gu: String = "গুজরাটি"
override val display_he: String = "হিব্রু"
override val display_hi: String = "হিন্দি"
override val display_hr: String = "ক্রোয়েশীয়"
override val display_hu: String = "হাঙ্গেরীয়"
override val display_hy: String = "আর্মেনিয়"
override val display_id: String = "ইন্দোনেশীয়"
override val display_is: String = "আইসল্যান্ডীয়"
override val display_it: String = "ইতালীয়"
override val display_ja: String = "জাপানি"
override val display_ka: String = "জর্জিয়ান"
override val display_kk: String = "কাজাখ"
override val display_km: String = "মধ্য খেমার"
override val display_kn: String = "কন্নড়"
override val display_ko: String = "কোরিয়ান"
override val display_ky: String = "কার্গিজ"
override val display_lo: String = "লাও"
override val display_lt: String = "লিথুয়েনীয"
override val display_lv: String = "লাত্ভীয়"
override val display_mk: String = "ম্যাসেডোনিয়ার অধিবাসী"
override val display_ml: String = "মালয়ালম"
override val display_mn: String = "মঙ্গোলিয়"
override val display_mr: String = "মারাঠি"
override val display_ms: String = "মালে"
override val display_my: String = "বার্মাবাসী"
override val display_nb: String = "নরওয়েজিয়ান বোকমাল"
override val display_ne: String = "নেপালি"
override val display_nl: String = "ডাচ"
override val display_or: String = "ওড়িয়া"
override val display_pa: String = "পাঞ্জাবি"
override val display_pl: String = "পোলিশ"
override val display_pt: String = "পর্তুগীজ"
override val display_pt_BR: String = "পর্তুগিজ (ব্রাজিল)"
override val display_pt_PT: String = "ইউরোপীয় পর্তুগীজ"
override val display_ro: String = "রোমানিয়ান"
override val display_ru: String = "রাশিয়ান"
override val display_si: String = "সিংহলী"
override val display_sk: String = "স্লোভাক"
override val display_sl: String = "স্লোভেনীয়"
override val display_sq: String = "আলবেনীয়"
override val display_sr: String = "সার্বীয়"
override val display_sv: String = "সুইডিশ"
override val display_sw: String = "সোয়াহিলি"
override val display_ta: String = "তামিল"
override val display_te: String = "তেলুগু"
override val display_th: String = "থাই"
override val display_tr: String = "তুর্কী"
override val display_uk: String = "ইউক্রেনীয়"
override val display_ur: String = "উর্দু"
override val display_uz: String = "উজবেকীয়"
override val display_vi: String = "ভিয়েতনামী"
override val display_zh_Hant_TW: String = "চীনা (তাইওয়ান, ঐতিহ্যবাহী)"
override val display_zu: String = "জুলু"
override val document_estimate: String = "প্রাক্কলন"
override val document_invoice: String = "চালান"
override val document_quote: String = "মূল্য অফার"
override val done: String = "সম্পন্ন"
override val download_app: String = "অ্যাপটি ডাউনলোড করুন"
override val download_invoice: String = "চালান ডাউনলোড"
override val download_pdf: String = "ডাউনলোড পিডিএফ"
override val draw_signature: String = "একটি স্বাক্ষর আঁকুন"
override val due: Formattable = Formattable { (date) -> "ডিউ ${date}" }
override val due_date: String = "নির্ধারিত তারিখ"
override val due_day: String = "এক দিনের মধ্যে"
override val due_label: String = "কারণে"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType}${documentNumber} এর মধ্যে দিতে হবে ${dueWhen}!" }
override val due_today: String = "আজ"
override val due_week: String = "এক সপ্তাহের মধ্যে"
override val duplicate: String = "ডুপ্লিকেট"
override val duration_day: String = "দিন"
override val duration_month: String = "মাস"
override val duration_week: String = "সপ্তাহ"
override val duration_year: String = "বছর"
override val email: String = "ইমেল"
override val email_blank_error: String = "ইমেল আবশ্যক।"
override val email_error: String = "অবৈধ ইমেল ঠিকানা।"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "আপনার ব্যবসার জন্য আপনাকে ধন্যবাদ। সম্পূর্ণ ${documentType} দেখতে এই চিঠির শেষে বোতামে ক্লিক করুন। মনে রাখবেন এটি ${dueDate} পর্যন্ত বৈধ।" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "আপনি একটি নতুন ${documentType} ${invoiceNumber} পেয়েছেন!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} দেখুন" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "প্রিয় ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "আপনার ব্যবসার জন্য আপনাকে ধন্যবাদ। সম্পূর্ণ ${documentType} দেখতে এই চিঠির শেষে বোতামে ক্লিক করুন। অনুগ্রহ করে ${dueDate} পর্যন্ত ব্যালেন্স পরিশোধ করুন।" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "আপনি একটি নতুন ${documentType} ${invoiceNumber} পেয়েছেন!" }
override val email_reset_success_message: String = "প্রেরিত পাসওয়ার্ড রিসেট করতে ইমেল করুন, অনুগ্রহ করে আপনার ইনবক্সটি পরীক্ষা করুন।"
override val empty_clients_list_message: String = "ট্র্যাক করার জন্য আপনার কোনও ক্লায়েন্ট নেই। একটি নতুন ক্লায়েন্ট যোগ করুন।"
override val empty_estimate_list_message: String = "আপনার প্রথম অনুমান তৈরি করুন"
override val empty_expenses: String = "ট্র্যাক করার জন্য আপনার কোন খরচ নেই। একটি নতুন ব্যয় যোগ করুন।"
override val empty_invoice_list_message: String = "আপনার প্রথম চালান তৈরি করুন"
override val empty_items_list_message: String = "আপনার কোনও আইটেম নেই, একটি নতুন আইটেম যোগ করুন!"
override val empty_signatures_list_message: String = "কোনও স্বাক্ষর খুঁজে পাওয়া যায়নি। একটি তৈরি করুন!"
override val empty_taxes_list_message: String = "আপনার কোন ট্যাক্স নিয়ম নেই। একটি নতুন ট্যাক্স নিয়ম যোগ করুন।"
override val empty_templates_list_message: String = "টেমপ্লেটগুলি অনুপলভ্য।"
override val enabled: String = "সক্রিয়"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "প্রাক্কলন #"
override val estimates: String = "অনুমান"
override val estimates_report: String = "অনুমান"
override val expense_bank_fees: String = "ব্যাংক ফি"
override val expense_benefits: String = "বেনিফিট"
override val expense_communication: String = "যোগাযোগের"
override val expense_debt: String = "দেনা"
override val expense_deleted: String = "ব্যয় মুছে ফেলা হয়েছে"
override val expense_depreciation: String = "অবচয়"
override val expense_gifts: String = "উপহার"
override val expense_insurance: String = "বীমা"
override val expense_inventory: String = "ইনভেন্টরি"
override val expense_legal: String = "আইনি"
override val expense_maintenance: String = "রক্ষণাবেক্ষণ"
override val expense_marketing: String = "মার্কেটিং"
override val expense_meals: String = "খাবার"
override val expense_office_supplies: String = "অফিসে ব্যবহারকৃত জিনিসপত্র"
override val expense_rent: String = "ভাড়া"
override val expense_salaries: String = "বেতন"
override val expense_shopping: String = "কেনাকাটা"
override val expense_software: String = "সফটওয়্যার"
override val expense_status_available: String = "উপলব্ধ"
override val expense_status_processing: String = "প্রক্রিয়াকরণ"
override val expense_tax: String = "ট্যাক্স"
override val expense_training: String = "প্রশিক্ষণ"
override val expense_transport: String = "ট্রান্সপোর্ট"
override val expense_travel: String = "ভ্রমণ"
override val expense_utility: String = "উপযোগিতা"
override val expenses: String = "ব্যয়"
override val feedback_option: String = "প্রতিপ্রদান"
override val filter: String = "ফিল্টার"
override val first_name_hint: String = "প্রথম নাম"
override val forgot_password: String = "পাসওয়ার্ড রিসেট করুন"
override val forgot_password_label: String = "পাসওয়ার্ড ভুলে গেছেন"
override val from: String = "থেকে"
override val general_error: String = "ত্রুটি ঘটেছে, অনুগ্রহ করে পরে আবার চেষ্টা করুন।"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "ফিরে যাও"
override val group_by: String = "দ্বারা গ্রুপ:"
override val image_view: String = "প্রতিচ্ছবি"
override val install: String = "ইনস্টল করুন"
override val invalid_credentials_error: String = "অবৈধ প্রমাণপত্রাদি।"
override val invalid_email_error: String = "অবৈধ ইমেল।"
override val invalid_user_error: String = "অবৈধ ব্যবহারকারীর প্রমাণপত্রাদি।"
override val invoice_deleted_message: String = "চালান মোছা হয়েছে"
override val invoice_menu: String = "চালান মেনু"
override val invoice_number: String = "চালান #"
override val invoices: String = "চালানসমূহ"
override val items_option: String = "বিষয়োপকরণ"
override val keep: String = "রাখুন"
override val language_selector: Formattable = Formattable { (selected) -> "ভাষা ${selected}" }
override val last_name_hint: String = "শেষ নাম"
override val ledger: String = "ledger"
override val legal: String = "আইনি"
override val legal_error: String = "পরিষেবার শর্তাবলী এবং গোপনীয়তা নীতি অবশ্যই স্বীকার করতে হবে"
override val liabilities: String = "liabilities"
override val loading: String = "লোড"
override val login_required_error: String = "লগইন আবশ্যক।"
override val logo_view: String = "লোগো"
override val logout: String = "লগআউট"
override val logout_prompt: String = "আপনার তথ্য ক্লাউডের সাথে সিঙ্ক্রোনাইজ করা হয় না। লগআউটে চালান, অনুমান, ক্লায়েন্ট এবং অন্যান্য তথ্য মুছবেন?"
override val main_screen: String = "প্রধান স্ক্রীন"
override val mark_paid: String = "প্রদত্ত হিসাবে চিহ্নিত করুন"
override val message: String = "বার্তা"
override val missing_sender_msg: String = "প্রেরকের তথ্য ছাড়া একটি নথি অবৈধ হতে পারে।"
override val missing_sender_title: String = "প্রেরকের তথ্য অনুপস্থিত"
override val month_april: String = "এপ্রিল"
override val month_april_short: String = "এপ্রিল"
override val month_august: String = "আগস্ট"
override val month_august_short: String = "অগা."
override val month_december: String = "ডিসেম্বর"
override val month_december_short: String = "ডিসে."
override val month_february: String = "ফেব্রুয়ারি"
override val month_february_short: String = "ফেব্."
override val month_january: String = "জানুয়ারী"
override val month_january_short: String = "জানু."
override val month_july: String = "জুলাই"
override val month_july_short: String = "জুলাই"
override val month_june: String = "জুন"
override val month_june_short: String = "জুন"
override val month_march: String = "মার্চ"
override val month_march_short: String = "মার্চ"
override val month_may: String = "মে"
override val month_may_short: String = "মে"
override val month_november: String = "নভেম্বর"
override val month_november_short: String = "নভে."
override val month_october: String = "অক্টোবর"
override val month_october_short: String = "অক্টো."
override val month_september: String = "সেপ্টেম্বর"
override val month_september_short: String = "সেপ্টে."
override val multifactor_error: String = "যাচাই করতে ব্যর্থ হয়েছে।"
override val new: String = "নতুন"
override val news: String = "সংবাদ"
override val next: String = "পরবর্তী"
override val no: String = "no"
override val no_data_available: String = "কোনও ডেটা উপলব্ধ নেই"
override val no_search_results: String = "দুঃখিত, আমরা কোনও ফলাফল খুঁজে পাইনি।"
override val none: String = "কোনোটিই"
override val note_fields: String = "নোট"
override val note_hint: String = "নোট"
override val notifications_option: String = "প্রজ্ঞাপন"
override val ok: String = "ঠিক আছে"
override val onboarding: String = "অনবোর্ডিং"
override val onboarding_aboutus_message: String = "DingVoice-এর প্রয়োজনীয় অংশগুলি চিরতরে বিনামূল্যে। আমাদের লক্ষ্য হল আপনাকে আপনার ব্যবসা বৃদ্ধিতে সাহায্য করা, আপনার প্রতিক্রিয়া শেয়ার করে আমাদের উন্নতিতে সাহায্য করা।"
override val onboarding_aboutus_title: String = "আমরা এখানে সাহায্য করতে এসেছি"
override val onboarding_paid_1: String = "পেশাদার খুঁজছেন টেমপ্লেট অ্যাক্সেস"
override val onboarding_paid_2: String = "আপনার আর্থিক সম্পর্কে গভীরভাবে দেখুন"
override val onboarding_paid_3: String = "সমস্যাগুলি দ্রুত সমাধান করুন"
override val onboarding_paid_4: String = "আপনার কাগজপত্র নিরাপদ রাখুন"
override val onboarding_paid_5: String = "চালানের স্থিতি পরিবর্তনের সাথে আপ টু ডেট থাকুন"
override val onboarding_paid_title: String = "এর সাথে আরও দ্রুত বেড়ে উঠুন..."
override val onboarding_paywall_message: String = "এখন সেরা মূল্যে সমস্ত প্রিমিয়াম বৈশিষ্ট্য উপভোগ করুন।"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} জন্য শুধুমাত্র ${currencySymbol}${introAmount} এবং তারপর ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "শুধু ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} এর জন্য বিনামূল্যে এবং তারপরে শুধুমাত্র ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} এর জন্য বিনামূল্যে তারপর ${introductoryDuration} ${introPeriod} এর জন্য ${currencySymbol}${introductoryAmount} এবং তারপরে শুধুমাত্র ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "স্বয়ংক্রিয় পুনর্নবীকরণযোগ্য। যেকোনো সময় বাতিল করুন।"
override val onboarding_paywall_restore: String = "ক্রয় পুনরুদ্ধার"
override val onboarding_paywall_restore_failure: String = "কেনাকাটা পুনরুদ্ধার করা ব্যর্থ হয়েছে, পরে আবার চেষ্টা করুন বা সহায়তার সাথে যোগাযোগ করুন।"
override val onboarding_paywall_restore_success: String = "সফল পুনরুদ্ধার!"
override val onboarding_paywall_title: String = "মহত্ত্ব অর্জন!"
override val onboarding_welcome_message: String = "আপনার চালান এবং অনুমান পরিচালনা করার জন্য একটি সম্পূর্ণ টুলসেট।"
override val onboarding_welcome_title: String = "DingVoice-এ স্বাগতম"
override val other_state: String = "অন্যান্য"
override val overdue_state: String = "বিলম্বিত"
override val paid_state: String = "প্রদত্ত"
override val password: String = "পাসওয়ার্ড"
override val password_blank_error: String = "পাসওয়ার্ড আবশ্যক।"
override val password_mismatch_error: String = "পাসওয়ার্ড মিলছে না।"
override val pay: String = "pay"
override val payment_instructions_label: String = "অর্থ প্রদানের নির্দেশাবলী"
override val payment_reminders: String = "অর্থপ্রদানের রিমাইন্ডারগুলি"
override val phone_number_fields: String = "ফোন নম্বর"
override val phone_number_hint: String = "ফোন নম্বর"
override val postcode_hint: String = "পোস্টকোড"
override val preview: String = "পূর্বরূপ"
override val preview_menu_hint: String = "প্রাকদর্শন মেনু"
override val price: String = "দাম"
override val privacy_policy: String = "গোপনীয়তা নীতি"
override val profile: String = "প্রোফাইল"
override val profitable_client: String = "আপনার সবচেয়ে লাভজনক ক্লায়েন্ট হল..."
override val profits_report: String = "মুনাফা"
override val promo_content: String = "প্রচারমূলক বিষয়বস্তু"
override val quantity_hint: String = "পরিমাণ"
override val quantity_label_hint: String = "পরিমাণ লেবেল"
override val query_info: String = "কোয়েরি আপনাকে ইনপুট হিসাবে আইটেম এবং অন্যান্য করের নিয়ম উভয়ই উল্লেখ করে কাস্টম গণনা লিখতে দেয়। অন্য এন্ট্রিতে লিঙ্ক করতে @ প্রতীক এবং আইটেম বা করের বিবরণ যোগ করুন +, -, *, /, % - গণিত অপারেটর"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} আপনার ক্লায়েন্ট কর্তৃক গৃহীত হয়েছে।" }
override val received_state: String = "প্রাপ্ত"
override val registration_code_fields: String = "রেজিস্ট্রেশন কোড"
override val reminder: String = "রিমাইন্ডার"
override val reports: String = "প্রতিবেদন"
override val reports_not_available: String = "কোন রিপোর্ট উপলব্ধ নেই, পরে ফিরে আসা."
override val reveal_password: String = "পাসওয়ার্ড প্রকাশ করুন"
override val revenue: String = "revenue"
override val sales_tax: String = "ভ্যাট"
override val sales_tax_number: String = "ভ্যাট নম্বর"
override val save: String = "সংরক্ষণ"
override val scan_document: String = "নথি স্ক্যান করুন"
override val search: String = "সন্ধান"
override val select: String = "নির্বাচন করুন"
override val select_icon: String = "আইকন নির্বাচন করুন"
override val select_signature: String = "স্বাক্ষর নির্বাচন করুন"
override val select_template: String = "টেমপ্লেট নির্বাচন করুন"
override val send: String = "প্রেরণ"
override val sent_state: String = "প্রেরিত"
override val setup_sender: String = "প্রেরক সেট আপ করুন"
override val share_option: String = "অংশীদারি"
override val ship_to: String = "জাহাজে"
override val sign_in: String = "সাইন ইন করো"
override val sign_in_apple: String = "অ্যাপল দিয়ে সাইন ইন করুন"
override val sign_in_email: String = "ইমেল দিয়ে সাইন ইন করুন"
override val sign_in_facebook: String = "Facebook দিয়ে সাইন ইন করুন"
override val sign_in_google: String = "Google দিয়ে সাইন ইন করুন"
override val sign_up: String = "সাইন আপ করো"
override val sign_up_label: String = "আপনার কি কোন একাউন্ট নেই?"
override val sign_up_link_label: String = "সাইন আপ করো!"
override val signatures_option: String = "স্বাক্ষর"
override val skip: String = "এড়িয়ে যান"
override val sold_items_report: String = "বিক্রিত আইটেম"
override val sort_by: String = "দ্বারা বাছাই করুন:"
override val status_update: String = "ডকুমেন্ট স্ট্যাটাস আপডেট"
override val sub_annual: String = "বার্ষিক"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/বছর" }
override val sub_backups: String = "ক্লাউড ব্যাকআপ"
override val sub_insights: String = "অন্তর্দৃষ্টি"
override val sub_month: String = "মাসিক"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/মাস" }
override val sub_notifications: String = "বিজ্ঞপ্তিগুলি"
override val sub_popup_message: String = "সাবস্ক্রিপশন শীঘ্রই আসছে। কিছুক্ষণের মধ্যে আবার এখানে চেক করুন।"
override val sub_support: String = "সমর্থন"
override val sub_templates: String = "টেমপ্লেটসমূহ"
override val subscribe: String = "সদস্যতা"
override val subscription_header: String = "এর সাথে আপনার ব্যবসা বাড়ান:"
override val subscription_option: String = "সদস্যতা"
override val subscriptions_load_fail: String = "দুঃখিত, আমরা এই সময়ে আপনার সদস্যতা পুনরুদ্ধার করতে অক্ষম. অনুগ্রহ করে পরে আবার চেষ্টা করুন বা সহায়তার জন্য আমাদের সহায়তা দলের সাথে যোগাযোগ করুন।"
override val subtotal: Formattable = Formattable { (sum) -> "সাবটোটাল: ${sum}" }
override val subtotal_label: String = "উপমোট"
override val sum: String = "সমষ্টি"
override val support_option: String = "সমর্থন"
override val switch_reports: String = "প্রতিবেদন পরিবর্তন করো"
override val tax: String = "ট্যাক্স"
override val tax_deleted_message: String = "ট্যাক্স মুছে ফেলা হয়েছে"
override val tax_description_warning: String = "যদি ব্যবহৃত হয় তবে এটি একটি চালানে প্রদর্শিত হয়"
override val tax_input_flat: String = "ফ্ল্যাট"
override val tax_input_percent: String = "শতাংশ"
override val tax_number_fields: String = "ট্যাক্স নম্বর"
override val tax_number_hint: String = "ট্যাক্স নম্বর"
override val tax_query_info_description: String = "কোয়েরি তথ্য"
override val tax_report: String = "ট্যাক্স"
override val tax_rules_option: String = "করের নিয়মাবলী"
override val taxed_clients_report: String = "কর আরোপিত ক্লায়েন্টদের"
override val template: String = "টেমপ্লেট"
override val template_selector_info: String = "ডিফল্ট টেমপ্লেটটি পরিবর্তন করতে সোয়াইপ করুন"
override val template_unlock_promo: String = "প্রিমিয়াম সঙ্গে আরো টেমপ্লেট অ্যাক্সেস"
override val templates_option: String = "টেমপ্লেটসমূহ"
override val terms_of_service: String = "পরিষেবার শর্তাবলী"
override val thank_you: String = "আপনাকে ধন্যবাদ"
override val to: String = "করতে"
override val total: Formattable = Formattable { (sum) -> "মোট: ${sum}" }
override val total_label: String = "মোট"
override val undo_action: String = "পূর্বাবস্থা"
override val unit: String = "ইউনিট"
override val unit_cost_hint: String = "ইউনিট খরচ"
override val unknown_client: String = "অজানা ক্লায়েন্ট"
override val unlock: String = "আনলক"
override val unnamed_item: String = "নামহীন আইটেম"
override val upgrade: String = "উচ্চায়ন"
override val user_collision_error: String = "অ্যাকাউন্ট টি ইতিমধ্যে বিদ্যমান।"
override val valid_until: String = "পর্যন্ত এটি বৈধ"
override val warning: String = "সতর্কীকরণ!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} এবং ${privacyPolicy} স্বীকার করুন" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "অবৈধ কোড।",
	"add_client_action" to "ক্লায়েন্ট যোগ করুন",
	"add_customer" to "গ্রাহক যোগ করুন",
	"add_expense" to "ব্যয় যোগ করুন",
	"add_fields_label" to "যুক্ত:",
	"add_invoice_action" to "তৈরি",
	"add_item_action" to "আইটেম জুড়ুন",
	"add_item_button" to "আইটেম জুড়ুন",
	"add_signature_action" to "স্বাক্ষর যোগ করুন",
	"add_tax_action" to "ট্যাক্স যোগ করুন",
	"address_fields" to "ঠিকানা",
	"address_line_hint" to "ঠিকানা লাইন",
	"advanced" to "উন্নত",
	"agree_marketing" to "আমি সরাসরি বিপণন যোগাযোগ পেতে সম্মত",
	"all_notifications" to "সকল বিজ্ঞপ্তি",
	"amortization" to "amortization",
	"amount" to "পরিমান",
	"asset" to "asset",
	"attach_file" to "ফাইল সংযুক্ত করুন",
	"attachment" to "সংযুক্তি",
	"auth_error" to "ক্রিয়াকলাপ ব্যর্থ হয়েছে, আবার চেষ্টা করুন।",
	"available_for_paid" to "ক্লাউড সিঙ্ক কেবলমাত্র প্রদত্ত সদস্যতার অধীনে সুলভ।",
	"backups_option" to "ব্যাকআপ",
	"balance" to "balance",
	"bank_account" to "ব্যাংক একাউন্ট",
	"bill_number" to "বিল নম্বর",
	"cancel" to "বাতিল",
	"cancel_trial" to "যেকোনো সময় বাতিল করুন",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "বিভাগ",
	"category" to "বিভাগ",
	"category_add" to "বিভাগ যোগ করুন",
	"category_delete" to "বিভাগ মুছে ফেলুন",
	"category_name" to "বিভাগ নাম",
	"change_language" to Formattable { (language) -> "চালানের ভাষা: ${language}" },
	"change_language_label" to "চালানের ভাষা",
	"change_template" to "টেমপ্লেট পরিবর্তন করুন",
	"city_hint" to "শহর",
	"clear" to "অবলোপ",
	"client" to "ক্লায়েন্ট",
	"client_actions" to "ক্লায়েন্ট ক্রিয়াকলাপ",
	"client_deleted_message" to "ক্লায়েন্ট মোছা হয়েছে",
	"clients_error_required_fields" to "কমপক্ষে একটি মান প্রয়োজন।",
	"clients_option" to "ক্লায়েন্টরা",
	"close" to "বন্ধ",
	"cloud_sync" to "ক্লাউড সিঙ্ক",
	"coming_soon" to "শীঘ্রই আসছে!",
	"company_logo" to "কোম্পানির লোগো",
	"company_name_hint" to "কোম্পানি",
	"confirm_password_label" to "পাসওয়ার্ড নিশ্চিত করুন",
	"confirmation_title" to "তুমি কি নিশ্চিত?",
	"convert_to_invoice" to "চালানে রূপান্তর করুন",
	"country_hint" to "দেশ",
	"create_client" to "ক্লায়েন্ট তৈরি করুন",
	"create_company" to "কোম্পানী তৈরি করুন",
	"create_document" to "নথি তৈরি করুন",
	"create_estimate" to "অনুমান",
	"create_invoice" to "চালান",
	"create_item" to "আইটেম তৈরি করুন",
	"create_new_client" to "নতুন ক্লায়েন্ট তৈরি করুন",
	"create_new_item" to "নতুন বিষয়োপকরণ তৈরি করুন",
	"create_new_tax" to "নতুন ট্যাক্স তৈরি করুন",
	"create_signature" to "স্বাক্ষর",
	"created" to "তৈরি",
	"credit" to "credit",
	"customer" to "ক্রেতা",
	"date" to "তারিখ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "ডিফল্ট মুদ্রা: ${currency}" },
	"default_currency_label" to "ডিফল্ট মুদ্রা",
	"delete" to "বিলোপ",
	"delete_account" to "অ্যাকাউন্ট মুছুন",
	"delete_confirmation_message" to "আপনার সমস্ত ডেটা হারিয়ে যাবে।",
	"delete_item_title" to "আইটেমটি মুছবেন?",
	"delete_signature" to "স্বাক্ষর মুছুন",
	"depreciation" to "depreciation",
	"description" to "বর্ণনা",
	"description_hint" to "বর্ণনা",
	"detailed_report" to "বিস্তারিত প্রতিবেদন",
	"disabled" to "অক্ষম",
	"discount" to "ডিসকাউন্ট",
	"discount_hint" to "ডিসকাউন্ট",
	"display_af" to "আফ্রিকাবাসী",
	"display_am" to "আমহারিক",
	"display_ar" to "Arabic",
	"display_as" to "আসামি",
	"display_az" to "আজারবাইজানীয়",
	"display_be" to "বেলারুশিয়",
	"display_bg" to "বুলগেরীয়",
	"display_bn" to "বাঙালি",
	"display_bs" to "বসনীয়",
	"display_ca" to "কাতালান",
	"display_cs" to "Czech",
	"display_da" to "ডেনমার্কবাসী",
	"display_de" to "জার্মান",
	"display_el" to "গ্রীক",
	"display_en" to "ইংরেজি",
	"display_es" to "স্পেনীয়",
	"display_es_MX" to "স্পেনীয় (মেক্সিকো)",
	"display_et" to "এস্তোনীয়",
	"display_eu" to "বাস্ক",
	"display_fa" to "পারসিক",
	"display_fi" to "ফিনিশ",
	"display_fil" to "ফিলিপিনো",
	"display_fr" to "ফরাসি",
	"display_fr_CA" to "কানাডিয়ান ফরাসী",
	"display_gl" to "গ্যালিশিয়",
	"display_gu" to "গুজরাটি",
	"display_he" to "হিব্রু",
	"display_hi" to "হিন্দি",
	"display_hr" to "ক্রোয়েশীয়",
	"display_hu" to "হাঙ্গেরীয়",
	"display_hy" to "আর্মেনিয়",
	"display_id" to "ইন্দোনেশীয়",
	"display_is" to "আইসল্যান্ডীয়",
	"display_it" to "ইতালীয়",
	"display_ja" to "জাপানি",
	"display_ka" to "জর্জিয়ান",
	"display_kk" to "কাজাখ",
	"display_km" to "মধ্য খেমার",
	"display_kn" to "কন্নড়",
	"display_ko" to "কোরিয়ান",
	"display_ky" to "কার্গিজ",
	"display_lo" to "লাও",
	"display_lt" to "লিথুয়েনীয",
	"display_lv" to "লাত্ভীয়",
	"display_mk" to "ম্যাসেডোনিয়ার অধিবাসী",
	"display_ml" to "মালয়ালম",
	"display_mn" to "মঙ্গোলিয়",
	"display_mr" to "মারাঠি",
	"display_ms" to "মালে",
	"display_my" to "বার্মাবাসী",
	"display_nb" to "নরওয়েজিয়ান বোকমাল",
	"display_ne" to "নেপালি",
	"display_nl" to "ডাচ",
	"display_or" to "ওড়িয়া",
	"display_pa" to "পাঞ্জাবি",
	"display_pl" to "পোলিশ",
	"display_pt" to "পর্তুগীজ",
	"display_pt_BR" to "পর্তুগিজ (ব্রাজিল)",
	"display_pt_PT" to "ইউরোপীয় পর্তুগীজ",
	"display_ro" to "রোমানিয়ান",
	"display_ru" to "রাশিয়ান",
	"display_si" to "সিংহলী",
	"display_sk" to "স্লোভাক",
	"display_sl" to "স্লোভেনীয়",
	"display_sq" to "আলবেনীয়",
	"display_sr" to "সার্বীয়",
	"display_sv" to "সুইডিশ",
	"display_sw" to "সোয়াহিলি",
	"display_ta" to "তামিল",
	"display_te" to "তেলুগু",
	"display_th" to "থাই",
	"display_tr" to "তুর্কী",
	"display_uk" to "ইউক্রেনীয়",
	"display_ur" to "উর্দু",
	"display_uz" to "উজবেকীয়",
	"display_vi" to "ভিয়েতনামী",
	"display_zh_Hant_TW" to "চীনা (তাইওয়ান, ঐতিহ্যবাহী)",
	"display_zu" to "জুলু",
	"document_estimate" to "প্রাক্কলন",
	"document_invoice" to "চালান",
	"document_quote" to "মূল্য অফার",
	"done" to "সম্পন্ন",
	"download_app" to "অ্যাপটি ডাউনলোড করুন",
	"download_invoice" to "চালান ডাউনলোড",
	"download_pdf" to "ডাউনলোড পিডিএফ",
	"draw_signature" to "একটি স্বাক্ষর আঁকুন",
	"due" to Formattable { (date) -> "ডিউ ${date}" },
	"due_date" to "নির্ধারিত তারিখ",
	"due_day" to "এক দিনের মধ্যে",
	"due_label" to "কারণে",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType}${documentNumber} এর মধ্যে দিতে হবে ${dueWhen}!" },
	"due_today" to "আজ",
	"due_week" to "এক সপ্তাহের মধ্যে",
	"duplicate" to "ডুপ্লিকেট",
	"duration_day" to "দিন",
	"duration_month" to "মাস",
	"duration_week" to "সপ্তাহ",
	"duration_year" to "বছর",
	"email" to "ইমেল",
	"email_blank_error" to "ইমেল আবশ্যক।",
	"email_error" to "অবৈধ ইমেল ঠিকানা।",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "আপনার ব্যবসার জন্য আপনাকে ধন্যবাদ। সম্পূর্ণ ${documentType} দেখতে এই চিঠির শেষে বোতামে ক্লিক করুন। মনে রাখবেন এটি ${dueDate} পর্যন্ত বৈধ।" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "আপনি একটি নতুন ${documentType} ${invoiceNumber} পেয়েছেন!" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} দেখুন" },
	"email_invoice_greeting" to Formattable { (clientName) -> "প্রিয় ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "আপনার ব্যবসার জন্য আপনাকে ধন্যবাদ। সম্পূর্ণ ${documentType} দেখতে এই চিঠির শেষে বোতামে ক্লিক করুন। অনুগ্রহ করে ${dueDate} পর্যন্ত ব্যালেন্স পরিশোধ করুন।" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "আপনি একটি নতুন ${documentType} ${invoiceNumber} পেয়েছেন!" },
	"email_reset_success_message" to "প্রেরিত পাসওয়ার্ড রিসেট করতে ইমেল করুন, অনুগ্রহ করে আপনার ইনবক্সটি পরীক্ষা করুন।",
	"empty_clients_list_message" to "ট্র্যাক করার জন্য আপনার কোনও ক্লায়েন্ট নেই। একটি নতুন ক্লায়েন্ট যোগ করুন।",
	"empty_estimate_list_message" to "আপনার প্রথম অনুমান তৈরি করুন",
	"empty_expenses" to "ট্র্যাক করার জন্য আপনার কোন খরচ নেই। একটি নতুন ব্যয় যোগ করুন।",
	"empty_invoice_list_message" to "আপনার প্রথম চালান তৈরি করুন",
	"empty_items_list_message" to "আপনার কোনও আইটেম নেই, একটি নতুন আইটেম যোগ করুন!",
	"empty_signatures_list_message" to "কোনও স্বাক্ষর খুঁজে পাওয়া যায়নি। একটি তৈরি করুন!",
	"empty_taxes_list_message" to "আপনার কোন ট্যাক্স নিয়ম নেই। একটি নতুন ট্যাক্স নিয়ম যোগ করুন।",
	"empty_templates_list_message" to "টেমপ্লেটগুলি অনুপলভ্য।",
	"enabled" to "সক্রিয়",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "প্রাক্কলন #",
	"estimates" to "অনুমান",
	"estimates_report" to "অনুমান",
	"expense_bank_fees" to "ব্যাংক ফি",
	"expense_benefits" to "বেনিফিট",
	"expense_communication" to "যোগাযোগের",
	"expense_debt" to "দেনা",
	"expense_deleted" to "ব্যয় মুছে ফেলা হয়েছে",
	"expense_depreciation" to "অবচয়",
	"expense_gifts" to "উপহার",
	"expense_insurance" to "বীমা",
	"expense_inventory" to "ইনভেন্টরি",
	"expense_legal" to "আইনি",
	"expense_maintenance" to "রক্ষণাবেক্ষণ",
	"expense_marketing" to "মার্কেটিং",
	"expense_meals" to "খাবার",
	"expense_office_supplies" to "অফিসে ব্যবহারকৃত জিনিসপত্র",
	"expense_rent" to "ভাড়া",
	"expense_salaries" to "বেতন",
	"expense_shopping" to "কেনাকাটা",
	"expense_software" to "সফটওয়্যার",
	"expense_status_available" to "উপলব্ধ",
	"expense_status_processing" to "প্রক্রিয়াকরণ",
	"expense_tax" to "ট্যাক্স",
	"expense_training" to "প্রশিক্ষণ",
	"expense_transport" to "ট্রান্সপোর্ট",
	"expense_travel" to "ভ্রমণ",
	"expense_utility" to "উপযোগিতা",
	"expenses" to "ব্যয়",
	"feedback_option" to "প্রতিপ্রদান",
	"filter" to "ফিল্টার",
	"first_name_hint" to "প্রথম নাম",
	"forgot_password" to "পাসওয়ার্ড রিসেট করুন",
	"forgot_password_label" to "পাসওয়ার্ড ভুলে গেছেন",
	"from" to "থেকে",
	"general_error" to "ত্রুটি ঘটেছে, অনুগ্রহ করে পরে আবার চেষ্টা করুন।",
	"general_ledger" to "general ledger",
	"go_back_label" to "ফিরে যাও",
	"group_by" to "দ্বারা গ্রুপ:",
	"image_view" to "প্রতিচ্ছবি",
	"install" to "ইনস্টল করুন",
	"invalid_credentials_error" to "অবৈধ প্রমাণপত্রাদি।",
	"invalid_email_error" to "অবৈধ ইমেল।",
	"invalid_user_error" to "অবৈধ ব্যবহারকারীর প্রমাণপত্রাদি।",
	"invoice_deleted_message" to "চালান মোছা হয়েছে",
	"invoice_menu" to "চালান মেনু",
	"invoice_number" to "চালান #",
	"invoices" to "চালানসমূহ",
	"items_option" to "বিষয়োপকরণ",
	"keep" to "রাখুন",
	"language_selector" to Formattable { (selected) -> "ভাষা ${selected}" },
	"last_name_hint" to "শেষ নাম",
	"ledger" to "ledger",
	"legal" to "আইনি",
	"legal_error" to "পরিষেবার শর্তাবলী এবং গোপনীয়তা নীতি অবশ্যই স্বীকার করতে হবে",
	"liabilities" to "liabilities",
	"loading" to "লোড",
	"login_required_error" to "লগইন আবশ্যক।",
	"logo_view" to "লোগো",
	"logout" to "লগআউট",
	"logout_prompt" to "আপনার তথ্য ক্লাউডের সাথে সিঙ্ক্রোনাইজ করা হয় না। লগআউটে চালান, অনুমান, ক্লায়েন্ট এবং অন্যান্য তথ্য মুছবেন?",
	"main_screen" to "প্রধান স্ক্রীন",
	"mark_paid" to "প্রদত্ত হিসাবে চিহ্নিত করুন",
	"message" to "বার্তা",
	"missing_sender_msg" to "প্রেরকের তথ্য ছাড়া একটি নথি অবৈধ হতে পারে।",
	"missing_sender_title" to "প্রেরকের তথ্য অনুপস্থিত",
	"month_april" to "এপ্রিল",
	"month_april_short" to "এপ্রিল",
	"month_august" to "আগস্ট",
	"month_august_short" to "অগা.",
	"month_december" to "ডিসেম্বর",
	"month_december_short" to "ডিসে.",
	"month_february" to "ফেব্রুয়ারি",
	"month_february_short" to "ফেব্.",
	"month_january" to "জানুয়ারী",
	"month_january_short" to "জানু.",
	"month_july" to "জুলাই",
	"month_july_short" to "জুলাই",
	"month_june" to "জুন",
	"month_june_short" to "জুন",
	"month_march" to "মার্চ",
	"month_march_short" to "মার্চ",
	"month_may" to "মে",
	"month_may_short" to "মে",
	"month_november" to "নভেম্বর",
	"month_november_short" to "নভে.",
	"month_october" to "অক্টোবর",
	"month_october_short" to "অক্টো.",
	"month_september" to "সেপ্টেম্বর",
	"month_september_short" to "সেপ্টে.",
	"multifactor_error" to "যাচাই করতে ব্যর্থ হয়েছে।",
	"new" to "নতুন",
	"news" to "সংবাদ",
	"next" to "পরবর্তী",
	"no" to "no",
	"no_data_available" to "কোনও ডেটা উপলব্ধ নেই",
	"no_search_results" to "দুঃখিত, আমরা কোনও ফলাফল খুঁজে পাইনি।",
	"none" to "কোনোটিই",
	"note_fields" to "নোট",
	"note_hint" to "নোট",
	"notifications_option" to "প্রজ্ঞাপন",
	"ok" to "ঠিক আছে",
	"onboarding" to "অনবোর্ডিং",
	"onboarding_aboutus_message" to "DingVoice-এর প্রয়োজনীয় অংশগুলি চিরতরে বিনামূল্যে। আমাদের লক্ষ্য হল আপনাকে আপনার ব্যবসা বৃদ্ধিতে সাহায্য করা, আপনার প্রতিক্রিয়া শেয়ার করে আমাদের উন্নতিতে সাহায্য করা।",
	"onboarding_aboutus_title" to "আমরা এখানে সাহায্য করতে এসেছি",
	"onboarding_paid_1" to "পেশাদার খুঁজছেন টেমপ্লেট অ্যাক্সেস",
	"onboarding_paid_2" to "আপনার আর্থিক সম্পর্কে গভীরভাবে দেখুন",
	"onboarding_paid_3" to "সমস্যাগুলি দ্রুত সমাধান করুন",
	"onboarding_paid_4" to "আপনার কাগজপত্র নিরাপদ রাখুন",
	"onboarding_paid_5" to "চালানের স্থিতি পরিবর্তনের সাথে আপ টু ডেট থাকুন",
	"onboarding_paid_title" to "এর সাথে আরও দ্রুত বেড়ে উঠুন...",
	"onboarding_paywall_message" to "এখন সেরা মূল্যে সমস্ত প্রিমিয়াম বৈশিষ্ট্য উপভোগ করুন।",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} জন্য শুধুমাত্র ${currencySymbol}${introAmount} এবং তারপর ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "শুধু ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} এর জন্য বিনামূল্যে এবং তারপরে শুধুমাত্র ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} এর জন্য বিনামূল্যে তারপর ${introductoryDuration} ${introPeriod} এর জন্য ${currencySymbol}${introductoryAmount} এবং তারপরে শুধুমাত্র ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "স্বয়ংক্রিয় পুনর্নবীকরণযোগ্য। যেকোনো সময় বাতিল করুন।",
	"onboarding_paywall_restore" to "ক্রয় পুনরুদ্ধার",
	"onboarding_paywall_restore_failure" to "কেনাকাটা পুনরুদ্ধার করা ব্যর্থ হয়েছে, পরে আবার চেষ্টা করুন বা সহায়তার সাথে যোগাযোগ করুন।",
	"onboarding_paywall_restore_success" to "সফল পুনরুদ্ধার!",
	"onboarding_paywall_title" to "মহত্ত্ব অর্জন!",
	"onboarding_welcome_message" to "আপনার চালান এবং অনুমান পরিচালনা করার জন্য একটি সম্পূর্ণ টুলসেট।",
	"onboarding_welcome_title" to "DingVoice-এ স্বাগতম",
	"other_state" to "অন্যান্য",
	"overdue_state" to "বিলম্বিত",
	"paid_state" to "প্রদত্ত",
	"password" to "পাসওয়ার্ড",
	"password_blank_error" to "পাসওয়ার্ড আবশ্যক।",
	"password_mismatch_error" to "পাসওয়ার্ড মিলছে না।",
	"pay" to "pay",
	"payment_instructions_label" to "অর্থ প্রদানের নির্দেশাবলী",
	"payment_reminders" to "অর্থপ্রদানের রিমাইন্ডারগুলি",
	"phone_number_fields" to "ফোন নম্বর",
	"phone_number_hint" to "ফোন নম্বর",
	"postcode_hint" to "পোস্টকোড",
	"preview" to "পূর্বরূপ",
	"preview_menu_hint" to "প্রাকদর্শন মেনু",
	"price" to "দাম",
	"privacy_policy" to "গোপনীয়তা নীতি",
	"profile" to "প্রোফাইল",
	"profitable_client" to "আপনার সবচেয়ে লাভজনক ক্লায়েন্ট হল...",
	"profits_report" to "মুনাফা",
	"promo_content" to "প্রচারমূলক বিষয়বস্তু",
	"quantity_hint" to "পরিমাণ",
	"quantity_label_hint" to "পরিমাণ লেবেল",
	"query_info" to "কোয়েরি আপনাকে ইনপুট হিসাবে আইটেম এবং অন্যান্য করের নিয়ম উভয়ই উল্লেখ করে কাস্টম গণনা লিখতে দেয়। অন্য এন্ট্রিতে লিঙ্ক করতে @ প্রতীক এবং আইটেম বা করের বিবরণ যোগ করুন +, -, *, /, % - গণিত অপারেটর",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} আপনার ক্লায়েন্ট কর্তৃক গৃহীত হয়েছে।" },
	"received_state" to "প্রাপ্ত",
	"registration_code_fields" to "রেজিস্ট্রেশন কোড",
	"reminder" to "রিমাইন্ডার",
	"reports" to "প্রতিবেদন",
	"reports_not_available" to "কোন রিপোর্ট উপলব্ধ নেই, পরে ফিরে আসা.",
	"reveal_password" to "পাসওয়ার্ড প্রকাশ করুন",
	"revenue" to "revenue",
	"sales_tax" to "ভ্যাট",
	"sales_tax_number" to "ভ্যাট নম্বর",
	"save" to "সংরক্ষণ",
	"scan_document" to "নথি স্ক্যান করুন",
	"search" to "সন্ধান",
	"select" to "নির্বাচন করুন",
	"select_icon" to "আইকন নির্বাচন করুন",
	"select_signature" to "স্বাক্ষর নির্বাচন করুন",
	"select_template" to "টেমপ্লেট নির্বাচন করুন",
	"send" to "প্রেরণ",
	"sent_state" to "প্রেরিত",
	"setup_sender" to "প্রেরক সেট আপ করুন",
	"share_option" to "অংশীদারি",
	"ship_to" to "জাহাজে",
	"sign_in" to "সাইন ইন করো",
	"sign_in_apple" to "অ্যাপল দিয়ে সাইন ইন করুন",
	"sign_in_email" to "ইমেল দিয়ে সাইন ইন করুন",
	"sign_in_facebook" to "Facebook দিয়ে সাইন ইন করুন",
	"sign_in_google" to "Google দিয়ে সাইন ইন করুন",
	"sign_up" to "সাইন আপ করো",
	"sign_up_label" to "আপনার কি কোন একাউন্ট নেই?",
	"sign_up_link_label" to "সাইন আপ করো!",
	"signatures_option" to "স্বাক্ষর",
	"skip" to "এড়িয়ে যান",
	"sold_items_report" to "বিক্রিত আইটেম",
	"sort_by" to "দ্বারা বাছাই করুন:",
	"status_update" to "ডকুমেন্ট স্ট্যাটাস আপডেট",
	"sub_annual" to "বার্ষিক",
	"sub_annual_price" to Formattable { (price) -> "${price}/বছর" },
	"sub_backups" to "ক্লাউড ব্যাকআপ",
	"sub_insights" to "অন্তর্দৃষ্টি",
	"sub_month" to "মাসিক",
	"sub_monthly_price" to Formattable { (price) -> "${price}/মাস" },
	"sub_notifications" to "বিজ্ঞপ্তিগুলি",
	"sub_popup_message" to "সাবস্ক্রিপশন শীঘ্রই আসছে। কিছুক্ষণের মধ্যে আবার এখানে চেক করুন।",
	"sub_support" to "সমর্থন",
	"sub_templates" to "টেমপ্লেটসমূহ",
	"subscribe" to "সদস্যতা",
	"subscription_header" to "এর সাথে আপনার ব্যবসা বাড়ান:",
	"subscription_option" to "সদস্যতা",
	"subscriptions_load_fail" to "দুঃখিত, আমরা এই সময়ে আপনার সদস্যতা পুনরুদ্ধার করতে অক্ষম. অনুগ্রহ করে পরে আবার চেষ্টা করুন বা সহায়তার জন্য আমাদের সহায়তা দলের সাথে যোগাযোগ করুন।",
	"subtotal" to Formattable { (sum) -> "সাবটোটাল: ${sum}" },
	"subtotal_label" to "উপমোট",
	"sum" to "সমষ্টি",
	"support_option" to "সমর্থন",
	"switch_reports" to "প্রতিবেদন পরিবর্তন করো",
	"tax" to "ট্যাক্স",
	"tax_deleted_message" to "ট্যাক্স মুছে ফেলা হয়েছে",
	"tax_description_warning" to "যদি ব্যবহৃত হয় তবে এটি একটি চালানে প্রদর্শিত হয়",
	"tax_input_flat" to "ফ্ল্যাট",
	"tax_input_percent" to "শতাংশ",
	"tax_number_fields" to "ট্যাক্স নম্বর",
	"tax_number_hint" to "ট্যাক্স নম্বর",
	"tax_query_info_description" to "কোয়েরি তথ্য",
	"tax_report" to "ট্যাক্স",
	"tax_rules_option" to "করের নিয়মাবলী",
	"taxed_clients_report" to "কর আরোপিত ক্লায়েন্টদের",
	"template" to "টেমপ্লেট",
	"template_selector_info" to "ডিফল্ট টেমপ্লেটটি পরিবর্তন করতে সোয়াইপ করুন",
	"template_unlock_promo" to "প্রিমিয়াম সঙ্গে আরো টেমপ্লেট অ্যাক্সেস",
	"templates_option" to "টেমপ্লেটসমূহ",
	"terms_of_service" to "পরিষেবার শর্তাবলী",
	"thank_you" to "আপনাকে ধন্যবাদ",
	"to" to "করতে",
	"total" to Formattable { (sum) -> "মোট: ${sum}" },
	"total_label" to "মোট",
	"undo_action" to "পূর্বাবস্থা",
	"unit" to "ইউনিট",
	"unit_cost_hint" to "ইউনিট খরচ",
	"unknown_client" to "অজানা ক্লায়েন্ট",
	"unlock" to "আনলক",
	"unnamed_item" to "নামহীন আইটেম",
	"upgrade" to "উচ্চায়ন",
	"user_collision_error" to "অ্যাকাউন্ট টি ইতিমধ্যে বিদ্যমান।",
	"valid_until" to "পর্যন্ত এটি বৈধ",
	"warning" to "সতর্কীকরণ!",
	"yes" to "yes",
    )
    
}