// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Gu  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} અને ${privacyPolicy} સ્વીકારો" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "અયોગ્ય કોડ."
override val add_client_action: String = "ક્લાયન્ટને ઉમેરો"
override val add_customer: String = "ગ્રાહક ઉમેરો"
override val add_expense: String = "ખર્ચ ઉમેરો"
override val add_fields_label: String = "ઉમેરો:"
override val add_invoice_action: String = "બનાવો"
override val add_item_action: String = "વસ્તુ ઉમેરો"
override val add_item_button: String = "વસ્તુ ઉમેરો"
override val add_signature_action: String = "સહી ઉમેરો"
override val add_tax_action: String = "કર ઉમેરો"
override val address_fields: String = "સરનામું"
override val address_line_hint: String = "સરનામાં લીટી"
override val advanced: String = "અદ્યતન"
override val agree_marketing: String = "હું ડાયરેક્ટ માર્કેટિંગ કોમ્યુનિકેશન પ્રાપ્ત કરવા માટે સંમત છું"
override val all_notifications: String = "બધી સૂચનાઓ"
override val amortization: String = "amortization"
override val amount: String = "રકમ"
override val asset: String = "asset"
override val attach_file: String = "ફાઇલ જોડો"
override val attachment: String = "જોડાણ"
override val auth_error: String = "ક્રિયા નિષ્ફળ, ફરી પ્રયત્ન કરો."
override val available_for_paid: String = "ક્લાઉડ સિન્ક ફક્ત પેઇડ સબ્સ્ક્રિપ્શન હેઠળ જ ઉપલબ્ધ છે."
override val backups_option: String = "બેકઅપ્સ"
override val balance: String = "balance"
override val bank_account: String = "બેંક એકાઉન્ટ"
override val bill_number: String = "બિલ નંબર"
override val cancel: String = "રદ્દ"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "શ્રેણીઓ"
override val category: String = "શ્રેણી"
override val category_add: String = "શ્રેણી ઉમેરો"
override val category_delete: String = "શ્રેણી કાઢી નાખો"
override val category_name: String = "શ્રેણીનું નામ"
override val change_language: Formattable = Formattable { (language) -> "ઇન્વોઇસની ભાષા: ${language}" }
override val change_language_label: String = "ઇન્વોઇસ ભાષા"
override val change_template: String = "ટેમ્પલેટ બદલો"
override val city_hint: String = "શહેર"
override val clear: String = "નિરભ્ર"
override val client: String = "ક્લાયન્ટ"
override val client_actions: String = "ક્લાયન્ટ ક્રિયાઓ"
override val client_deleted_message: String = "ક્લાયન્ટ કાઢી નાંખેલ છે"
override val clients_error_required_fields: String = "ઓછામાં ઓછું એક મૂલ્ય જરૂરી છે."
override val clients_option: String = "ક્લાયન્ટ્સ"
override val close: String = "બંધ કરો"
override val cloud_sync: String = "ક્લાઉડ સિન્ક"
override val coming_soon: String = "જલદી આવે છે!"
override val company_logo: String = "કંપનીનો લોગો"
override val company_name_hint: String = "કંપની"
override val confirm_password_label: String = "ખાતરી પાસવર્ડ"
override val confirmation_title: String = "શું તમને ખાતરી છે?"
override val convert_to_invoice: String = "ઇન્વોઇસમાં રૂપાંતરિત કરો"
override val country_hint: String = "દેશ"
override val create_client: String = "ક્લાયન્ટ બનાવો"
override val create_company: String = "કંપની બનાવો"
override val create_document: String = "દસ્તાવેજ બનાવો"
override val create_estimate: String = "અંદાજ"
override val create_invoice: String = "ઈનવોઈસ"
override val create_item: String = "વસ્તુ બનાવો"
override val create_new_client: String = "નવા ક્લાયન્ટને બનાવો"
override val create_new_item: String = "નવી વસ્તુ બનાવો"
override val create_new_tax: String = "નવો કર બનાવો"
override val create_signature: String = "સહી"
override val created: String = "બનાવેલ"
override val credit: String = "credit"
override val customer: String = "ઘરાક"
override val date: String = "તિથિ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "ડિફૉલ્ટ ચલણ: ${currency}" }
override val default_currency_label: String = "ડિફૉલ્ટ ચલણ"
override val delete: String = "કાઢી નાંખો"
override val delete_account: String = "એકાઉન્ટ કાઢી નાખો"
override val delete_confirmation_message: String = "તમારો બધો ડેટા ખોવાઈ જશે."
override val delete_item_title: String = "વસ્તુને કાઢી નાંખવી છે?"
override val delete_signature: String = "સહી કાઢી નાંખો"
override val depreciation: String = "depreciation"
override val description: String = "વર્ણન"
override val description_hint: String = "વર્ણન"
override val detailed_report: String = "વિગતવાર અહેવાલ"
override val disabled: String = "વિકલાંગ"
override val discount: String = "ડિસ્કાઉન્ટ"
override val discount_hint: String = "ડિસ્કાઉન્ટ"
override val display_af: String = "આફ્રિકન"
override val display_am: String = "એમ્હારિક"
override val display_ar: String = "અરબી"
override val display_as: String = "આસામી"
override val display_az: String = "અઝરબૈજાની"
override val display_be: String = "બેલારુસિયન"
override val display_bg: String = "બલ્ગેરિયન"
override val display_bn: String = "બંગાળી"
override val display_bs: String = "બોસ્નિયન"
override val display_ca: String = "કતલાન"
override val display_cs: String = "ચેક"
override val display_da: String = "ડેનિશ"
override val display_de: String = "જર્મન"
override val display_el: String = "ગ્રીક"
override val display_en: String = "અંગ્રેજી"
override val display_es: String = "સ્પૅનિશ"
override val display_es_MX: String = "સ્પેનિશ (મેક્સિકો)"
override val display_et: String = "એસ્ટોનિયન"
override val display_eu: String = "બાસ્ક"
override val display_fa: String = "ફારસી"
override val display_fi: String = "ફિનિશ"
override val display_fil: String = "ફિલિપિનો"
override val display_fr: String = "ફ્રેન્ચ"
override val display_fr_CA: String = "કેનેડિયન ફ્રેન્ચ"
override val display_gl: String = "ગેલિશિયન"
override val display_gu: String = "ગુજરાતી"
override val display_he: String = "હીબ્રુ"
override val display_hi: String = "ના."
override val display_hr: String = "ક્રોએશિયન"
override val display_hu: String = "હંગેરિયન"
override val display_hy: String = "આર્મેનિયન"
override val display_id: String = "ઇન્ડોનેશિયન"
override val display_is: String = "આઇસલેન્ડિક"
override val display_it: String = "ઇટાલિયન"
override val display_ja: String = "જાપાનીઝ"
override val display_ka: String = "જ્યોર્જિયન"
override val display_kk: String = "કઝાક"
override val display_km: String = "મધ્ય ખ્મેર"
override val display_kn: String = "કન્નડ"
override val display_ko: String = "કોરિયન"
override val display_ky: String = "કિર્ગીઝ"
override val display_lo: String = "શ્રમ"
override val display_lt: String = "લિથુનિયન"
override val display_lv: String = "લાતવિયન"
override val display_mk: String = "મેસેડોનિયન"
override val display_ml: String = "મલયાલમ"
override val display_mn: String = "મોંગોલિયન"
override val display_mr: String = "મરાઠી"
override val display_ms: String = "મલય"
override val display_my: String = "બર્મીઝ"
override val display_nb: String = "નોર્વેજીયન બોકમાલ"
override val display_ne: String = "નેપાળી"
override val display_nl: String = "ડચ"
override val display_or: String = "ઉડિયા"
override val display_pa: String = "પંજાબી"
override val display_pl: String = "પોલિશ"
override val display_pt: String = "પોર્ટુગીઝ"
override val display_pt_BR: String = "પોર્ટુગીઝ (બ્રાઝિલ)"
override val display_pt_PT: String = "યુરોપિયન પોર્ટુગીઝ"
override val display_ro: String = "રોમાનિયન"
override val display_ru: String = "રશિયન"
override val display_si: String = "સિંહલા"
override val display_sk: String = "સ્લોવાક"
override val display_sl: String = "સ્લોવેનિયન"
override val display_sq: String = "અલ્બેનિયન"
override val display_sr: String = "સર્બિયન"
override val display_sv: String = "સ્વીડિશ"
override val display_sw: String = "સ્વાહિલી"
override val display_ta: String = "તમિલ"
override val display_te: String = "તેલુગુ"
override val display_th: String = "થાઈ"
override val display_tr: String = "ટર્કિશ"
override val display_uk: String = "યુક્રેનિયન"
override val display_ur: String = "ઉર્દુ"
override val display_uz: String = "ઉઝબેક"
override val display_vi: String = "વિયેતનામીસ"
override val display_zh_Hant_TW: String = "ચાઇનીઝ (તાઇવાન, પરંપરાગત)"
override val display_zu: String = "ઝુલુ"
override val document_estimate: String = "અંદાજ"
override val document_invoice: String = "ઈનવોઈસ"
override val document_quote: String = "ભાવ"
override val done: String = "થઈ ગયું"
override val download_app: String = "એપ ડાઉનલોડ કરો"
override val download_invoice: String = "ઇન્વોઇસ ડાઉનલોડ કરો"
override val download_pdf: String = "PDF ડાઉનલોડ કરો"
override val draw_signature: String = "સહી દોરો"
override val due: Formattable = Formattable { (date) -> "${date} ને કારણે" }
override val due_date: String = "નિયત તારીખ"
override val due_day: String = "એક દિવસમાં"
override val due_label: String = "કારણે"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} બાકી છે ${dueWhen}!" }
override val due_today: String = "આજે"
override val due_week: String = "અઠવાડિયામાં"
override val duplicate: String = "ડુપ્લિકેટ"
override val duration_day: String = "દિવસ"
override val duration_month: String = "માસ"
override val duration_week: String = "સપ્તાહ"
override val duration_year: String = "વર્ષ"
override val email: String = "ઇમેઇલ"
override val email_blank_error: String = "ઈ-મેઈલ જરૂરી છે."
override val email_error: String = "અયોગ્ય ઈ-મેઈલ સરનામું."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "તમારા વ્યવસાય માટે આભાર. સંપૂર્ણ ${documentType} જોવા માટે આ પત્રના અંતે બટન પર ક્લિક કરો. નોંધ લો કે તે ${dueDate} સુધી માન્ય છે." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "તમે એક નવું પ્રાપ્ત કર્યું છે${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} જુઓ" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "પ્રિય ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "તમારા વ્યવસાય માટે આભાર. સંપૂર્ણ ${documentType} જોવા માટે આ પત્રના અંતે બટન પર ક્લિક કરો. કૃપા કરીને ${dueDate} સુધી બેલેન્સ ચૂકવો." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "તમે એક નવું પ્રાપ્ત કર્યું છે${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "મોકલેલ પાસવર્ડ રીસેટ કરવા માટે ઇમેઇલ કરો, કૃપા કરીને તમારું ઇનબોક્સ તપાસો."
override val empty_clients_list_message: String = "તમારી પાસે ટ્રેક કરવા માટે કોઈ ક્લાયન્ટ નથી. નવો ક્લાયન્ટ ઉમેરો."
override val empty_estimate_list_message: String = "તમારો પ્રથમ અંદાજ બનાવો"
override val empty_expenses: String = "તમારી પાસે ટ્રૅક કરવા માટે કોઈ ખર્ચ નથી. નવો ખર્ચ ઉમેરો."
override val empty_invoice_list_message: String = "તમારું પ્રથમ ભરતિયું બનાવો"
override val empty_items_list_message: String = "તમારી પાસે કોઈ વસ્તુઓ નથી, નવી વસ્તુ ઉમેરો!"
override val empty_signatures_list_message: String = "કોઈ સહીઓ મળી નથી. એક બનાવો!"
override val empty_taxes_list_message: String = "તમારી પાસે ટેક્સના કોઈ નિયમો નથી. ટેક્સનો નવો નિયમ ઉમેરો."
override val empty_templates_list_message: String = "નમૂનાઓ અનુપલબ્ધ છે."
override val enabled: String = "સક્ષમ"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "અંદાજ #"
override val estimates: String = "અંદાજ"
override val estimates_report: String = "અંદાજ"
override val expense_bank_fees: String = "બેંક ફી"
override val expense_benefits: String = "લાભો"
override val expense_communication: String = "કોમ્યુનિકેશન"
override val expense_debt: String = "દેવું"
override val expense_deleted: String = "ખર્ચ કાઢી નાખ્યો"
override val expense_depreciation: String = "અવમૂલ્યન"
override val expense_gifts: String = "ભેટ"
override val expense_insurance: String = "વીમા"
override val expense_inventory: String = "ઇન્વેન્ટરી"
override val expense_legal: String = "કાયદેસર"
override val expense_maintenance: String = "જાળવણી"
override val expense_marketing: String = "માર્કેટિંગ"
override val expense_meals: String = "ભોજન"
override val expense_office_supplies: String = "ઓફિસનો પુરવઠો"
override val expense_rent: String = "ભાડે"
override val expense_salaries: String = "પગાર"
override val expense_shopping: String = "શોપિંગ"
override val expense_software: String = "સોફ્ટવેર"
override val expense_status_available: String = "ઉપલબ્ધ છે"
override val expense_status_processing: String = "પ્રક્રિયા"
override val expense_tax: String = "કર"
override val expense_training: String = "તાલીમ"
override val expense_transport: String = "પરિવહન"
override val expense_travel: String = "પ્રવાસ"
override val expense_utility: String = "ઉપયોગિતા"
override val expenses: String = "ખર્ચ"
override val feedback_option: String = "પ્રતિસાદ"
override val filter: String = "ફિલ્ટર"
override val first_name_hint: String = "પ્રથમ નામ"
override val forgot_password: String = "પાસવર્ડને પુન:સુયોજિત કરો"
override val forgot_password_label: String = "પાસવર્ડ ભૂલી ગયા"
override val from: String = "તરફથી"
override val general_error: String = "ભૂલ આવી, કૃપા કરીને પછીથી ફરી પ્રયાસ કરો."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "પાછા જાઓ"
override val group_by: String = "આના દ્વારા જૂથ:"
override val image_view: String = "ઇમેજ"
override val install: String = "ઇન્સ્ટૉલ કરો"
override val invalid_credentials_error: String = "અયોગ્ય શ્રેય."
override val invalid_email_error: String = "અયોગ્ય ઈ-મેઈલ."
override val invalid_user_error: String = "અયોગ્ય વપરાશકર્તા શ્રેય."
override val invoice_deleted_message: String = "ભરતિયું કાઢી નાંખેલ છે"
override val invoice_menu: String = "ઈનવોઈસ મેનુ"
override val invoice_number: String = "ઈનવોઈસ #"
override val invoices: String = "ઇન્વોઇસેસ"
override val items_option: String = "વસ્તુઓ"
override val keep: String = "રાખો"
override val language_selector: Formattable = Formattable { (selected) -> "ભાષા ${selected}" }
override val last_name_hint: String = "છેલ્લું નામ"
override val ledger: String = "ledger"
override val legal: String = "કાનૂની"
override val legal_error: String = "સેવાની શરતો અને ગોપનીયતા નીતિ સ્વીકારવી આવશ્યક છે"
override val liabilities: String = "liabilities"
override val loading: String = "લોડ કરી રહ્યા છે"
override val login_required_error: String = "પ્રવેશ જરૂરી છે."
override val logo_view: String = "લોગો"
override val logout: String = "બહાર નીકળો"
override val logout_prompt: String = "તમારો ડેટા ક્લાઉડ સાથે સિંક્રનાઇઝ થયેલ નથી. લોગઆઉટ પર ઇન્વોઇસેસ, અંદાજો, ક્લાયન્ટ્સ અને અન્ય માહિતીને ડિલીટ કરવી છે?"
override val main_screen: String = "મુખ્ય સ્ક્રીન"
override val mark_paid: String = "ચૂકવેલ તરીકે ચિહ્નિત કરો"
override val message: String = "સંદેશ"
override val missing_sender_msg: String = "પ્રેષકની માહિતી વિનાનો દસ્તાવેજ અમાન્ય હોઈ શકે છે."
override val missing_sender_title: String = "પ્રેષક માહિતી ખૂટે છે"
override val month_april: String = "એપ્રિલ"
override val month_april_short: String = "એપ્રિલ"
override val month_august: String = "ઓગસ્ટ"
override val month_august_short: String = "ઑગ."
override val month_december: String = "ડિસેમ્બર"
override val month_december_short: String = "ડિસે."
override val month_february: String = "ફેબ્રુઆરી"
override val month_february_short: String = "ફેબ્રુ."
override val month_january: String = "જાન્યુઆરી"
override val month_january_short: String = "જાન્યુ."
override val month_july: String = "જુલાઈ"
override val month_july_short: String = "જુલાઈ"
override val month_june: String = "જૂન"
override val month_june_short: String = "જૂન"
override val month_march: String = "કુચ"
override val month_march_short: String = "કુચ"
override val month_may: String = "મે"
override val month_may_short: String = "મે"
override val month_november: String = "નવેમ્બર"
override val month_november_short: String = "નવે."
override val month_october: String = "ઓક્ટોબર"
override val month_october_short: String = "ઑક્ટો."
override val month_september: String = "સપ્ટેમ્બર"
override val month_september_short: String = "સપ્ટે."
override val multifactor_error: String = "ચકાસણી કરવામાં નિષ્ફળ."
override val new: String = "નવી"
override val news: String = "સમાચાર"
override val next: String = "આગળ"
override val no: String = "no"
override val no_data_available: String = "કોઈ ડેટા ઉપલબ્ધ નથી"
override val no_search_results: String = "માફ કરશો, અમે કોઈ પરિણામ શોધી શક્યા નથી."
override val none: String = "કોઈ નહિ"
override val note_fields: String = "નોંધો"
override val note_hint: String = "નોંધ"
override val notifications_option: String = "સૂચનાઓ"
override val ok: String = "બરાબર"
override val onboarding: String = "ઓનબોર્ડિંગ"
override val onboarding_aboutus_message: String = "DingVoice ના આવશ્યક ભાગો કાયમ માટે મફત છે. અમારું ધ્યેય તમને તમારા વ્યવસાયને વધારવામાં મદદ કરવાનું છે, તમારો પ્રતિસાદ શેર કરીને અમને સુધારવામાં સહાય કરો."
override val onboarding_aboutus_title: String = "અમે મદદ કરવા માટે અહીં છીએ"
override val onboarding_paid_1: String = "વ્યાવસાયિક દેખાતા નમૂનાઓને ઍક્સેસ કરો"
override val onboarding_paid_2: String = "તમારી નાણાકીય બાબતોમાં ઊંડાણપૂર્વક નજર નાખો"
override val onboarding_paid_3: String = "સમસ્યાઓનો ઝડપથી ઉકેલ મેળવો"
override val onboarding_paid_4: String = "તમારા દસ્તાવેજો સુરક્ષિત રાખો"
override val onboarding_paid_5: String = "ઇન્વૉઇસ સ્ટેટસ ફેરફારો સાથે અપ ટુ ડેટ રહો"
override val onboarding_paid_title: String = "આની સાથે વધુ ઝડપથી વધો..."
override val onboarding_paywall_message: String = "હવે શ્રેષ્ઠ કિંમતે તમામ પ્રીમિયમ સુવિધાઓનો આનંદ લો."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} માટે માત્ર ${currencySymbol}${introAmount} અને પછી ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "માત્ર ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} માટે મફત અને પછી માત્ર ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} માટે મફત, પછી ${introductoryDuration}${introPeriod} માટે ${currencySymbol}${introductoryAmount}, પછી માત્ર ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "સ્વતઃ નવીનીકરણીય. કોઈપણ સમયે રદ કરો."
override val onboarding_paywall_restore: String = "ખરીદી પુનઃસ્થાપિત કરો"
override val onboarding_paywall_restore_failure: String = "ખરીદીઓ પુનઃસ્થાપિત કરવાનું નિષ્ફળ થયું છે, પછીથી ફરી પ્રયાસ કરો અથવા સમર્થનનો સંપર્ક કરો."
override val onboarding_paywall_restore_success: String = "પુનઃસ્થાપિત સફળ!"
override val onboarding_paywall_title: String = "મહાનતા પ્રાપ્ત કરો!"
override val onboarding_welcome_message: String = "તમારા ઇન્વૉઇસ અને અંદાજોનું સંચાલન કરવા માટે એક સંપૂર્ણ ટૂલસેટ."
override val onboarding_welcome_title: String = "DingVoice માં આપનું સ્વાગત છે"
override val other_state: String = "અન્ય"
override val overdue_state: String = "ઓવરડ્યુ"
override val paid_state: String = "ચૂકવેલ"
override val password: String = "પાસવર્ડ"
override val password_blank_error: String = "પાસવર્ડ જરૂરી છે."
override val password_mismatch_error: String = "પાસવર્ડો બંધબેસતા નથી."
override val pay: String = "pay"
override val payment_instructions_label: String = "ચૂકવણીની સૂચનાઓ"
override val payment_reminders: String = "ચુકવણી રીમાઇન્ડર્સ"
override val phone_number_fields: String = "ફોન નંબર"
override val phone_number_hint: String = "ફોન નંબર"
override val postcode_hint: String = "પોસ્ટકોડ"
override val preview: String = "પૂર્વદર્શન"
override val preview_menu_hint: String = "પૂર્વદર્શન મેનુ"
override val price: String = "કિંમત"
override val privacy_policy: String = "ગોપનીયતા નીતિ"
override val profile: String = "પ્રોફાઇલ"
override val profitable_client: String = "તમારો સૌથી વધુ નફાકારક ક્લાયન્ટ છે..."
override val profits_report: String = "નફો"
override val promo_content: String = "પ્રમોશનલ સામગ્રી"
override val quantity_hint: String = "જથ્થો"
override val quantity_label_hint: String = "જથ્થો લેબલ"
override val query_info: String = "ક્વેરી તમને ઇનપુટ્સ તરીકે બંને વસ્તુઓ અને અન્ય કર નિયમોનો સંદર્ભ આપતી કસ્ટમ ગણતરીઓ લખવાની મંજૂરી આપે છે. @ સંજ્ઞા અને વસ્તુ અથવા કર વર્ણનને અન્ય નોંધણી +, -, *, /, % - ગણિત ઓપરેટરો સાથે લિંક કરવા માટે ઉમેરો"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} તમારા ક્લાયન્ટ દ્વારા પ્રાપ્ત થયો હતો." }
override val received_state: String = "પ્રાપ્ત"
override val registration_code_fields: String = "નોંધણી નંબર"
override val reminder: String = "રીમાઇન્ડર"
override val reports: String = "અહેવાલો"
override val reports_not_available: String = "કોઈ રિપોર્ટ ઉપલબ્ધ નથી, કૃપા કરીને પછી પાછા આવો."
override val reveal_password: String = "પાસવર્ડને બતાવો"
override val revenue: String = "revenue"
override val sales_tax: String = "વેટ"
override val sales_tax_number: String = "વેટ નંબર"
override val save: String = "સંગ્રહો"
override val scan_document: String = "દસ્તાવેજ સ્કેન કરો"
override val search: String = "શોધવું"
override val select: String = "પસંદ કરો"
override val select_icon: String = "આયકન પસંદ કરો"
override val select_signature: String = "સહી પસંદ કરો"
override val select_template: String = "ટેમ્પલેટ પસંદ કરો"
override val send: String = "મોકલો"
override val sent_state: String = "મોકલેલ"
override val setup_sender: String = "મોકલનારને સેટ કરો"
override val share_option: String = "ભાગ"
override val ship_to: String = "મોકલી આપ્યું"
override val sign_in: String = "સાઇન ઇન કરો"
override val sign_in_apple: String = "Apple સાથે સાઇન ઇન કરો"
override val sign_in_email: String = "ઇમેઇલ સાથે સાઇન ઇન કરો"
override val sign_in_facebook: String = "Facebook સાથે સાઇન ઇન કરો"
override val sign_in_google: String = "Google સાથે સાઇન ઇન કરો"
override val sign_up: String = "સાઇન અપ કરો"
override val sign_up_label: String = "શું તમારી પાસે ખાતું નથી?"
override val sign_up_link_label: String = "સાઇન અપ કરો!"
override val signatures_option: String = "સહીઓ"
override val skip: String = "છોડો"
override val sold_items_report: String = "વેચાયેલી વસ્તુઓ"
override val sort_by: String = "આના દ્વારા ક્રમમાં ગોઠવો:"
override val status_update: String = "દસ્તાવેજ સ્થિતિ અપડેટ"
override val sub_annual: String = "વાર્ષિક"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/વર્ષ" }
override val sub_backups: String = "મેઘ બેકઅપ્સ"
override val sub_insights: String = "આંતરદૃષ્ટિ"
override val sub_month: String = "માસિક"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/મહિને" }
override val sub_notifications: String = "સૂચનાઓ"
override val sub_popup_message: String = "સબ્સ્ક્રિપ્શન્સ ટૂંક સમયમાં આવી રહ્યાં છે. થોડી વારમાં અહીં પાછા તપાસો."
override val sub_support: String = "આધાર"
override val sub_templates: String = "ટેમ્પલેટો"
override val subscribe: String = "ઉમેદવારી નોંધાવો"
override val subscription_header: String = "આની સાથે તમારો વ્યવસાય વધારો:"
override val subscription_option: String = "લવાજમ"
override val subscriptions_load_fail: String = "માફ કરશો, અમે આ સમયે તમારા સબ્સ્ક્રિપ્શન્સ પુનઃપ્રાપ્ત કરવામાં અસમર્થ છીએ. કૃપા કરીને પછીથી ફરી પ્રયાસ કરો અથવા સહાય માટે અમારી સપોર્ટ ટીમનો સંપર્ક કરો."
override val subtotal: Formattable = Formattable { (sum) -> "ઉપ-પૂર્ણતાલ: ${sum}" }
override val subtotal_label: String = "પેટાટોટલ"
override val sum: String = "રકમ"
override val support_option: String = "આધાર"
override val switch_reports: String = "રિપોર્ટ સ્વિચ કરો"
override val tax: String = "વેરો"
override val tax_deleted_message: String = "ટેક્સ ડિલીટ કરાયો"
override val tax_description_warning: String = "જો તેનો ઉપયોગ કરવામાં આવે તો આ ઇન્વોઇસ પર બતાવે છે"
override val tax_input_flat: String = "સપાટ"
override val tax_input_percent: String = "ટકા"
override val tax_number_fields: String = "વેરા નંબર"
override val tax_number_hint: String = "વેરા નંબર"
override val tax_query_info_description: String = "ક્વેરી જાણકારી"
override val tax_report: String = "વેરો"
override val tax_rules_option: String = "કરવેરા નિયમો"
override val taxed_clients_report: String = "કરવેરા ગ્રાહકો"
override val template: String = "ટેમ્પલેટ"
override val template_selector_info: String = "મૂળભૂત ટેમ્પલેટ બદલવા માટે સ્વાઇપ કરો"
override val template_unlock_promo: String = "પ્રીમિયમ સાથે વધુ નમૂનાઓ ઍક્સેસ કરો"
override val templates_option: String = "ટેમ્પલેટો"
override val terms_of_service: String = "સેવાની શરતો"
override val thank_you: String = "આભાર"
override val to: String = "પ્રતિ"
override val total: Formattable = Formattable { (sum) -> "કુલ: ${sum}" }
override val total_label: String = "કુલ"
override val undo_action: String = "રદ કરો"
override val unit: String = "એકમ"
override val unit_cost_hint: String = "એકમ કિંમત"
override val unknown_client: String = "અજ્ઞાત ક્લાયન્ટ"
override val unlock: String = "અનલlockક કરો"
override val unnamed_item: String = "નામ વગરની વસ્તુ"
override val upgrade: String = "સુધારો"
override val user_collision_error: String = "ખાતું પહેલેથી જ અસ્તિત્વમાં છે."
override val valid_until: String = "માન્ય છે ત્યાં સુધી"
override val warning: String = "ચેતવણી!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} અને ${privacyPolicy} સ્વીકારો" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "અયોગ્ય કોડ.",
	"add_client_action" to "ક્લાયન્ટને ઉમેરો",
	"add_customer" to "ગ્રાહક ઉમેરો",
	"add_expense" to "ખર્ચ ઉમેરો",
	"add_fields_label" to "ઉમેરો:",
	"add_invoice_action" to "બનાવો",
	"add_item_action" to "વસ્તુ ઉમેરો",
	"add_item_button" to "વસ્તુ ઉમેરો",
	"add_signature_action" to "સહી ઉમેરો",
	"add_tax_action" to "કર ઉમેરો",
	"address_fields" to "સરનામું",
	"address_line_hint" to "સરનામાં લીટી",
	"advanced" to "અદ્યતન",
	"agree_marketing" to "હું ડાયરેક્ટ માર્કેટિંગ કોમ્યુનિકેશન પ્રાપ્ત કરવા માટે સંમત છું",
	"all_notifications" to "બધી સૂચનાઓ",
	"amortization" to "amortization",
	"amount" to "રકમ",
	"asset" to "asset",
	"attach_file" to "ફાઇલ જોડો",
	"attachment" to "જોડાણ",
	"auth_error" to "ક્રિયા નિષ્ફળ, ફરી પ્રયત્ન કરો.",
	"available_for_paid" to "ક્લાઉડ સિન્ક ફક્ત પેઇડ સબ્સ્ક્રિપ્શન હેઠળ જ ઉપલબ્ધ છે.",
	"backups_option" to "બેકઅપ્સ",
	"balance" to "balance",
	"bank_account" to "બેંક એકાઉન્ટ",
	"bill_number" to "બિલ નંબર",
	"cancel" to "રદ્દ",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "શ્રેણીઓ",
	"category" to "શ્રેણી",
	"category_add" to "શ્રેણી ઉમેરો",
	"category_delete" to "શ્રેણી કાઢી નાખો",
	"category_name" to "શ્રેણીનું નામ",
	"change_language" to Formattable { (language) -> "ઇન્વોઇસની ભાષા: ${language}" },
	"change_language_label" to "ઇન્વોઇસ ભાષા",
	"change_template" to "ટેમ્પલેટ બદલો",
	"city_hint" to "શહેર",
	"clear" to "નિરભ્ર",
	"client" to "ક્લાયન્ટ",
	"client_actions" to "ક્લાયન્ટ ક્રિયાઓ",
	"client_deleted_message" to "ક્લાયન્ટ કાઢી નાંખેલ છે",
	"clients_error_required_fields" to "ઓછામાં ઓછું એક મૂલ્ય જરૂરી છે.",
	"clients_option" to "ક્લાયન્ટ્સ",
	"close" to "બંધ કરો",
	"cloud_sync" to "ક્લાઉડ સિન્ક",
	"coming_soon" to "જલદી આવે છે!",
	"company_logo" to "કંપનીનો લોગો",
	"company_name_hint" to "કંપની",
	"confirm_password_label" to "ખાતરી પાસવર્ડ",
	"confirmation_title" to "શું તમને ખાતરી છે?",
	"convert_to_invoice" to "ઇન્વોઇસમાં રૂપાંતરિત કરો",
	"country_hint" to "દેશ",
	"create_client" to "ક્લાયન્ટ બનાવો",
	"create_company" to "કંપની બનાવો",
	"create_document" to "દસ્તાવેજ બનાવો",
	"create_estimate" to "અંદાજ",
	"create_invoice" to "ઈનવોઈસ",
	"create_item" to "વસ્તુ બનાવો",
	"create_new_client" to "નવા ક્લાયન્ટને બનાવો",
	"create_new_item" to "નવી વસ્તુ બનાવો",
	"create_new_tax" to "નવો કર બનાવો",
	"create_signature" to "સહી",
	"created" to "બનાવેલ",
	"credit" to "credit",
	"customer" to "ઘરાક",
	"date" to "તિથિ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "ડિફૉલ્ટ ચલણ: ${currency}" },
	"default_currency_label" to "ડિફૉલ્ટ ચલણ",
	"delete" to "કાઢી નાંખો",
	"delete_account" to "એકાઉન્ટ કાઢી નાખો",
	"delete_confirmation_message" to "તમારો બધો ડેટા ખોવાઈ જશે.",
	"delete_item_title" to "વસ્તુને કાઢી નાંખવી છે?",
	"delete_signature" to "સહી કાઢી નાંખો",
	"depreciation" to "depreciation",
	"description" to "વર્ણન",
	"description_hint" to "વર્ણન",
	"detailed_report" to "વિગતવાર અહેવાલ",
	"disabled" to "વિકલાંગ",
	"discount" to "ડિસ્કાઉન્ટ",
	"discount_hint" to "ડિસ્કાઉન્ટ",
	"display_af" to "આફ્રિકન",
	"display_am" to "એમ્હારિક",
	"display_ar" to "અરબી",
	"display_as" to "આસામી",
	"display_az" to "અઝરબૈજાની",
	"display_be" to "બેલારુસિયન",
	"display_bg" to "બલ્ગેરિયન",
	"display_bn" to "બંગાળી",
	"display_bs" to "બોસ્નિયન",
	"display_ca" to "કતલાન",
	"display_cs" to "ચેક",
	"display_da" to "ડેનિશ",
	"display_de" to "જર્મન",
	"display_el" to "ગ્રીક",
	"display_en" to "અંગ્રેજી",
	"display_es" to "સ્પૅનિશ",
	"display_es_MX" to "સ્પેનિશ (મેક્સિકો)",
	"display_et" to "એસ્ટોનિયન",
	"display_eu" to "બાસ્ક",
	"display_fa" to "ફારસી",
	"display_fi" to "ફિનિશ",
	"display_fil" to "ફિલિપિનો",
	"display_fr" to "ફ્રેન્ચ",
	"display_fr_CA" to "કેનેડિયન ફ્રેન્ચ",
	"display_gl" to "ગેલિશિયન",
	"display_gu" to "ગુજરાતી",
	"display_he" to "હીબ્રુ",
	"display_hi" to "ના.",
	"display_hr" to "ક્રોએશિયન",
	"display_hu" to "હંગેરિયન",
	"display_hy" to "આર્મેનિયન",
	"display_id" to "ઇન્ડોનેશિયન",
	"display_is" to "આઇસલેન્ડિક",
	"display_it" to "ઇટાલિયન",
	"display_ja" to "જાપાનીઝ",
	"display_ka" to "જ્યોર્જિયન",
	"display_kk" to "કઝાક",
	"display_km" to "મધ્ય ખ્મેર",
	"display_kn" to "કન્નડ",
	"display_ko" to "કોરિયન",
	"display_ky" to "કિર્ગીઝ",
	"display_lo" to "શ્રમ",
	"display_lt" to "લિથુનિયન",
	"display_lv" to "લાતવિયન",
	"display_mk" to "મેસેડોનિયન",
	"display_ml" to "મલયાલમ",
	"display_mn" to "મોંગોલિયન",
	"display_mr" to "મરાઠી",
	"display_ms" to "મલય",
	"display_my" to "બર્મીઝ",
	"display_nb" to "નોર્વેજીયન બોકમાલ",
	"display_ne" to "નેપાળી",
	"display_nl" to "ડચ",
	"display_or" to "ઉડિયા",
	"display_pa" to "પંજાબી",
	"display_pl" to "પોલિશ",
	"display_pt" to "પોર્ટુગીઝ",
	"display_pt_BR" to "પોર્ટુગીઝ (બ્રાઝિલ)",
	"display_pt_PT" to "યુરોપિયન પોર્ટુગીઝ",
	"display_ro" to "રોમાનિયન",
	"display_ru" to "રશિયન",
	"display_si" to "સિંહલા",
	"display_sk" to "સ્લોવાક",
	"display_sl" to "સ્લોવેનિયન",
	"display_sq" to "અલ્બેનિયન",
	"display_sr" to "સર્બિયન",
	"display_sv" to "સ્વીડિશ",
	"display_sw" to "સ્વાહિલી",
	"display_ta" to "તમિલ",
	"display_te" to "તેલુગુ",
	"display_th" to "થાઈ",
	"display_tr" to "ટર્કિશ",
	"display_uk" to "યુક્રેનિયન",
	"display_ur" to "ઉર્દુ",
	"display_uz" to "ઉઝબેક",
	"display_vi" to "વિયેતનામીસ",
	"display_zh_Hant_TW" to "ચાઇનીઝ (તાઇવાન, પરંપરાગત)",
	"display_zu" to "ઝુલુ",
	"document_estimate" to "અંદાજ",
	"document_invoice" to "ઈનવોઈસ",
	"document_quote" to "ભાવ",
	"done" to "થઈ ગયું",
	"download_app" to "એપ ડાઉનલોડ કરો",
	"download_invoice" to "ઇન્વોઇસ ડાઉનલોડ કરો",
	"download_pdf" to "PDF ડાઉનલોડ કરો",
	"draw_signature" to "સહી દોરો",
	"due" to Formattable { (date) -> "${date} ને કારણે" },
	"due_date" to "નિયત તારીખ",
	"due_day" to "એક દિવસમાં",
	"due_label" to "કારણે",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} બાકી છે ${dueWhen}!" },
	"due_today" to "આજે",
	"due_week" to "અઠવાડિયામાં",
	"duplicate" to "ડુપ્લિકેટ",
	"duration_day" to "દિવસ",
	"duration_month" to "માસ",
	"duration_week" to "સપ્તાહ",
	"duration_year" to "વર્ષ",
	"email" to "ઇમેઇલ",
	"email_blank_error" to "ઈ-મેઈલ જરૂરી છે.",
	"email_error" to "અયોગ્ય ઈ-મેઈલ સરનામું.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "તમારા વ્યવસાય માટે આભાર. સંપૂર્ણ ${documentType} જોવા માટે આ પત્રના અંતે બટન પર ક્લિક કરો. નોંધ લો કે તે ${dueDate} સુધી માન્ય છે." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "તમે એક નવું પ્રાપ્ત કર્યું છે${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} જુઓ" },
	"email_invoice_greeting" to Formattable { (clientName) -> "પ્રિય ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "તમારા વ્યવસાય માટે આભાર. સંપૂર્ણ ${documentType} જોવા માટે આ પત્રના અંતે બટન પર ક્લિક કરો. કૃપા કરીને ${dueDate} સુધી બેલેન્સ ચૂકવો." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "તમે એક નવું પ્રાપ્ત કર્યું છે${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "મોકલેલ પાસવર્ડ રીસેટ કરવા માટે ઇમેઇલ કરો, કૃપા કરીને તમારું ઇનબોક્સ તપાસો.",
	"empty_clients_list_message" to "તમારી પાસે ટ્રેક કરવા માટે કોઈ ક્લાયન્ટ નથી. નવો ક્લાયન્ટ ઉમેરો.",
	"empty_estimate_list_message" to "તમારો પ્રથમ અંદાજ બનાવો",
	"empty_expenses" to "તમારી પાસે ટ્રૅક કરવા માટે કોઈ ખર્ચ નથી. નવો ખર્ચ ઉમેરો.",
	"empty_invoice_list_message" to "તમારું પ્રથમ ભરતિયું બનાવો",
	"empty_items_list_message" to "તમારી પાસે કોઈ વસ્તુઓ નથી, નવી વસ્તુ ઉમેરો!",
	"empty_signatures_list_message" to "કોઈ સહીઓ મળી નથી. એક બનાવો!",
	"empty_taxes_list_message" to "તમારી પાસે ટેક્સના કોઈ નિયમો નથી. ટેક્સનો નવો નિયમ ઉમેરો.",
	"empty_templates_list_message" to "નમૂનાઓ અનુપલબ્ધ છે.",
	"enabled" to "સક્ષમ",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "અંદાજ #",
	"estimates" to "અંદાજ",
	"estimates_report" to "અંદાજ",
	"expense_bank_fees" to "બેંક ફી",
	"expense_benefits" to "લાભો",
	"expense_communication" to "કોમ્યુનિકેશન",
	"expense_debt" to "દેવું",
	"expense_deleted" to "ખર્ચ કાઢી નાખ્યો",
	"expense_depreciation" to "અવમૂલ્યન",
	"expense_gifts" to "ભેટ",
	"expense_insurance" to "વીમા",
	"expense_inventory" to "ઇન્વેન્ટરી",
	"expense_legal" to "કાયદેસર",
	"expense_maintenance" to "જાળવણી",
	"expense_marketing" to "માર્કેટિંગ",
	"expense_meals" to "ભોજન",
	"expense_office_supplies" to "ઓફિસનો પુરવઠો",
	"expense_rent" to "ભાડે",
	"expense_salaries" to "પગાર",
	"expense_shopping" to "શોપિંગ",
	"expense_software" to "સોફ્ટવેર",
	"expense_status_available" to "ઉપલબ્ધ છે",
	"expense_status_processing" to "પ્રક્રિયા",
	"expense_tax" to "કર",
	"expense_training" to "તાલીમ",
	"expense_transport" to "પરિવહન",
	"expense_travel" to "પ્રવાસ",
	"expense_utility" to "ઉપયોગિતા",
	"expenses" to "ખર્ચ",
	"feedback_option" to "પ્રતિસાદ",
	"filter" to "ફિલ્ટર",
	"first_name_hint" to "પ્રથમ નામ",
	"forgot_password" to "પાસવર્ડને પુન:સુયોજિત કરો",
	"forgot_password_label" to "પાસવર્ડ ભૂલી ગયા",
	"from" to "તરફથી",
	"general_error" to "ભૂલ આવી, કૃપા કરીને પછીથી ફરી પ્રયાસ કરો.",
	"general_ledger" to "general ledger",
	"go_back_label" to "પાછા જાઓ",
	"group_by" to "આના દ્વારા જૂથ:",
	"image_view" to "ઇમેજ",
	"install" to "ઇન્સ્ટૉલ કરો",
	"invalid_credentials_error" to "અયોગ્ય શ્રેય.",
	"invalid_email_error" to "અયોગ્ય ઈ-મેઈલ.",
	"invalid_user_error" to "અયોગ્ય વપરાશકર્તા શ્રેય.",
	"invoice_deleted_message" to "ભરતિયું કાઢી નાંખેલ છે",
	"invoice_menu" to "ઈનવોઈસ મેનુ",
	"invoice_number" to "ઈનવોઈસ #",
	"invoices" to "ઇન્વોઇસેસ",
	"items_option" to "વસ્તુઓ",
	"keep" to "રાખો",
	"language_selector" to Formattable { (selected) -> "ભાષા ${selected}" },
	"last_name_hint" to "છેલ્લું નામ",
	"ledger" to "ledger",
	"legal" to "કાનૂની",
	"legal_error" to "સેવાની શરતો અને ગોપનીયતા નીતિ સ્વીકારવી આવશ્યક છે",
	"liabilities" to "liabilities",
	"loading" to "લોડ કરી રહ્યા છે",
	"login_required_error" to "પ્રવેશ જરૂરી છે.",
	"logo_view" to "લોગો",
	"logout" to "બહાર નીકળો",
	"logout_prompt" to "તમારો ડેટા ક્લાઉડ સાથે સિંક્રનાઇઝ થયેલ નથી. લોગઆઉટ પર ઇન્વોઇસેસ, અંદાજો, ક્લાયન્ટ્સ અને અન્ય માહિતીને ડિલીટ કરવી છે?",
	"main_screen" to "મુખ્ય સ્ક્રીન",
	"mark_paid" to "ચૂકવેલ તરીકે ચિહ્નિત કરો",
	"message" to "સંદેશ",
	"missing_sender_msg" to "પ્રેષકની માહિતી વિનાનો દસ્તાવેજ અમાન્ય હોઈ શકે છે.",
	"missing_sender_title" to "પ્રેષક માહિતી ખૂટે છે",
	"month_april" to "એપ્રિલ",
	"month_april_short" to "એપ્રિલ",
	"month_august" to "ઓગસ્ટ",
	"month_august_short" to "ઑગ.",
	"month_december" to "ડિસેમ્બર",
	"month_december_short" to "ડિસે.",
	"month_february" to "ફેબ્રુઆરી",
	"month_february_short" to "ફેબ્રુ.",
	"month_january" to "જાન્યુઆરી",
	"month_january_short" to "જાન્યુ.",
	"month_july" to "જુલાઈ",
	"month_july_short" to "જુલાઈ",
	"month_june" to "જૂન",
	"month_june_short" to "જૂન",
	"month_march" to "કુચ",
	"month_march_short" to "કુચ",
	"month_may" to "મે",
	"month_may_short" to "મે",
	"month_november" to "નવેમ્બર",
	"month_november_short" to "નવે.",
	"month_october" to "ઓક્ટોબર",
	"month_october_short" to "ઑક્ટો.",
	"month_september" to "સપ્ટેમ્બર",
	"month_september_short" to "સપ્ટે.",
	"multifactor_error" to "ચકાસણી કરવામાં નિષ્ફળ.",
	"new" to "નવી",
	"news" to "સમાચાર",
	"next" to "આગળ",
	"no" to "no",
	"no_data_available" to "કોઈ ડેટા ઉપલબ્ધ નથી",
	"no_search_results" to "માફ કરશો, અમે કોઈ પરિણામ શોધી શક્યા નથી.",
	"none" to "કોઈ નહિ",
	"note_fields" to "નોંધો",
	"note_hint" to "નોંધ",
	"notifications_option" to "સૂચનાઓ",
	"ok" to "બરાબર",
	"onboarding" to "ઓનબોર્ડિંગ",
	"onboarding_aboutus_message" to "DingVoice ના આવશ્યક ભાગો કાયમ માટે મફત છે. અમારું ધ્યેય તમને તમારા વ્યવસાયને વધારવામાં મદદ કરવાનું છે, તમારો પ્રતિસાદ શેર કરીને અમને સુધારવામાં સહાય કરો.",
	"onboarding_aboutus_title" to "અમે મદદ કરવા માટે અહીં છીએ",
	"onboarding_paid_1" to "વ્યાવસાયિક દેખાતા નમૂનાઓને ઍક્સેસ કરો",
	"onboarding_paid_2" to "તમારી નાણાકીય બાબતોમાં ઊંડાણપૂર્વક નજર નાખો",
	"onboarding_paid_3" to "સમસ્યાઓનો ઝડપથી ઉકેલ મેળવો",
	"onboarding_paid_4" to "તમારા દસ્તાવેજો સુરક્ષિત રાખો",
	"onboarding_paid_5" to "ઇન્વૉઇસ સ્ટેટસ ફેરફારો સાથે અપ ટુ ડેટ રહો",
	"onboarding_paid_title" to "આની સાથે વધુ ઝડપથી વધો...",
	"onboarding_paywall_message" to "હવે શ્રેષ્ઠ કિંમતે તમામ પ્રીમિયમ સુવિધાઓનો આનંદ લો.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} માટે માત્ર ${currencySymbol}${introAmount} અને પછી ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "માત્ર ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} માટે મફત અને પછી માત્ર ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} માટે મફત, પછી ${introductoryDuration}${introPeriod} માટે ${currencySymbol}${introductoryAmount}, પછી માત્ર ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "સ્વતઃ નવીનીકરણીય. કોઈપણ સમયે રદ કરો.",
	"onboarding_paywall_restore" to "ખરીદી પુનઃસ્થાપિત કરો",
	"onboarding_paywall_restore_failure" to "ખરીદીઓ પુનઃસ્થાપિત કરવાનું નિષ્ફળ થયું છે, પછીથી ફરી પ્રયાસ કરો અથવા સમર્થનનો સંપર્ક કરો.",
	"onboarding_paywall_restore_success" to "પુનઃસ્થાપિત સફળ!",
	"onboarding_paywall_title" to "મહાનતા પ્રાપ્ત કરો!",
	"onboarding_welcome_message" to "તમારા ઇન્વૉઇસ અને અંદાજોનું સંચાલન કરવા માટે એક સંપૂર્ણ ટૂલસેટ.",
	"onboarding_welcome_title" to "DingVoice માં આપનું સ્વાગત છે",
	"other_state" to "અન્ય",
	"overdue_state" to "ઓવરડ્યુ",
	"paid_state" to "ચૂકવેલ",
	"password" to "પાસવર્ડ",
	"password_blank_error" to "પાસવર્ડ જરૂરી છે.",
	"password_mismatch_error" to "પાસવર્ડો બંધબેસતા નથી.",
	"pay" to "pay",
	"payment_instructions_label" to "ચૂકવણીની સૂચનાઓ",
	"payment_reminders" to "ચુકવણી રીમાઇન્ડર્સ",
	"phone_number_fields" to "ફોન નંબર",
	"phone_number_hint" to "ફોન નંબર",
	"postcode_hint" to "પોસ્ટકોડ",
	"preview" to "પૂર્વદર્શન",
	"preview_menu_hint" to "પૂર્વદર્શન મેનુ",
	"price" to "કિંમત",
	"privacy_policy" to "ગોપનીયતા નીતિ",
	"profile" to "પ્રોફાઇલ",
	"profitable_client" to "તમારો સૌથી વધુ નફાકારક ક્લાયન્ટ છે...",
	"profits_report" to "નફો",
	"promo_content" to "પ્રમોશનલ સામગ્રી",
	"quantity_hint" to "જથ્થો",
	"quantity_label_hint" to "જથ્થો લેબલ",
	"query_info" to "ક્વેરી તમને ઇનપુટ્સ તરીકે બંને વસ્તુઓ અને અન્ય કર નિયમોનો સંદર્ભ આપતી કસ્ટમ ગણતરીઓ લખવાની મંજૂરી આપે છે. @ સંજ્ઞા અને વસ્તુ અથવા કર વર્ણનને અન્ય નોંધણી +, -, *, /, % - ગણિત ઓપરેટરો સાથે લિંક કરવા માટે ઉમેરો",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} તમારા ક્લાયન્ટ દ્વારા પ્રાપ્ત થયો હતો." },
	"received_state" to "પ્રાપ્ત",
	"registration_code_fields" to "નોંધણી નંબર",
	"reminder" to "રીમાઇન્ડર",
	"reports" to "અહેવાલો",
	"reports_not_available" to "કોઈ રિપોર્ટ ઉપલબ્ધ નથી, કૃપા કરીને પછી પાછા આવો.",
	"reveal_password" to "પાસવર્ડને બતાવો",
	"revenue" to "revenue",
	"sales_tax" to "વેટ",
	"sales_tax_number" to "વેટ નંબર",
	"save" to "સંગ્રહો",
	"scan_document" to "દસ્તાવેજ સ્કેન કરો",
	"search" to "શોધવું",
	"select" to "પસંદ કરો",
	"select_icon" to "આયકન પસંદ કરો",
	"select_signature" to "સહી પસંદ કરો",
	"select_template" to "ટેમ્પલેટ પસંદ કરો",
	"send" to "મોકલો",
	"sent_state" to "મોકલેલ",
	"setup_sender" to "મોકલનારને સેટ કરો",
	"share_option" to "ભાગ",
	"ship_to" to "મોકલી આપ્યું",
	"sign_in" to "સાઇન ઇન કરો",
	"sign_in_apple" to "Apple સાથે સાઇન ઇન કરો",
	"sign_in_email" to "ઇમેઇલ સાથે સાઇન ઇન કરો",
	"sign_in_facebook" to "Facebook સાથે સાઇન ઇન કરો",
	"sign_in_google" to "Google સાથે સાઇન ઇન કરો",
	"sign_up" to "સાઇન અપ કરો",
	"sign_up_label" to "શું તમારી પાસે ખાતું નથી?",
	"sign_up_link_label" to "સાઇન અપ કરો!",
	"signatures_option" to "સહીઓ",
	"skip" to "છોડો",
	"sold_items_report" to "વેચાયેલી વસ્તુઓ",
	"sort_by" to "આના દ્વારા ક્રમમાં ગોઠવો:",
	"status_update" to "દસ્તાવેજ સ્થિતિ અપડેટ",
	"sub_annual" to "વાર્ષિક",
	"sub_annual_price" to Formattable { (price) -> "${price}/વર્ષ" },
	"sub_backups" to "મેઘ બેકઅપ્સ",
	"sub_insights" to "આંતરદૃષ્ટિ",
	"sub_month" to "માસિક",
	"sub_monthly_price" to Formattable { (price) -> "${price}/મહિને" },
	"sub_notifications" to "સૂચનાઓ",
	"sub_popup_message" to "સબ્સ્ક્રિપ્શન્સ ટૂંક સમયમાં આવી રહ્યાં છે. થોડી વારમાં અહીં પાછા તપાસો.",
	"sub_support" to "આધાર",
	"sub_templates" to "ટેમ્પલેટો",
	"subscribe" to "ઉમેદવારી નોંધાવો",
	"subscription_header" to "આની સાથે તમારો વ્યવસાય વધારો:",
	"subscription_option" to "લવાજમ",
	"subscriptions_load_fail" to "માફ કરશો, અમે આ સમયે તમારા સબ્સ્ક્રિપ્શન્સ પુનઃપ્રાપ્ત કરવામાં અસમર્થ છીએ. કૃપા કરીને પછીથી ફરી પ્રયાસ કરો અથવા સહાય માટે અમારી સપોર્ટ ટીમનો સંપર્ક કરો.",
	"subtotal" to Formattable { (sum) -> "ઉપ-પૂર્ણતાલ: ${sum}" },
	"subtotal_label" to "પેટાટોટલ",
	"sum" to "રકમ",
	"support_option" to "આધાર",
	"switch_reports" to "રિપોર્ટ સ્વિચ કરો",
	"tax" to "વેરો",
	"tax_deleted_message" to "ટેક્સ ડિલીટ કરાયો",
	"tax_description_warning" to "જો તેનો ઉપયોગ કરવામાં આવે તો આ ઇન્વોઇસ પર બતાવે છે",
	"tax_input_flat" to "સપાટ",
	"tax_input_percent" to "ટકા",
	"tax_number_fields" to "વેરા નંબર",
	"tax_number_hint" to "વેરા નંબર",
	"tax_query_info_description" to "ક્વેરી જાણકારી",
	"tax_report" to "વેરો",
	"tax_rules_option" to "કરવેરા નિયમો",
	"taxed_clients_report" to "કરવેરા ગ્રાહકો",
	"template" to "ટેમ્પલેટ",
	"template_selector_info" to "મૂળભૂત ટેમ્પલેટ બદલવા માટે સ્વાઇપ કરો",
	"template_unlock_promo" to "પ્રીમિયમ સાથે વધુ નમૂનાઓ ઍક્સેસ કરો",
	"templates_option" to "ટેમ્પલેટો",
	"terms_of_service" to "સેવાની શરતો",
	"thank_you" to "આભાર",
	"to" to "પ્રતિ",
	"total" to Formattable { (sum) -> "કુલ: ${sum}" },
	"total_label" to "કુલ",
	"undo_action" to "રદ કરો",
	"unit" to "એકમ",
	"unit_cost_hint" to "એકમ કિંમત",
	"unknown_client" to "અજ્ઞાત ક્લાયન્ટ",
	"unlock" to "અનલlockક કરો",
	"unnamed_item" to "નામ વગરની વસ્તુ",
	"upgrade" to "સુધારો",
	"user_collision_error" to "ખાતું પહેલેથી જ અસ્તિત્વમાં છે.",
	"valid_until" to "માન્ય છે ત્યાં સુધી",
	"warning" to "ચેતવણી!",
	"yes" to "yes",
    )
    
}