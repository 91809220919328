// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Nb  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Godta ${termsOfService} og ${privacyPolicy}" }
override val account: String = "konto"
override val accounting: String = "regnskap"
override val accruals: String = "periodiseringer"
override val action_code_error: String = "Ugyldig kode."
override val add_client_action: String = "Legg til klient"
override val add_customer: String = "Legg til kunde"
override val add_expense: String = "Legg til utgifter"
override val add_fields_label: String = "Legg til:"
override val add_invoice_action: String = "Opprette"
override val add_item_action: String = "Legg til element"
override val add_item_button: String = "Legg til element"
override val add_signature_action: String = "Legg til signatur"
override val add_tax_action: String = "Legg til skatt"
override val address_fields: String = "Adresse"
override val address_line_hint: String = "Adresselinje"
override val advanced: String = "Avansert"
override val agree_marketing: String = "Jeg godtar å motta direkte markedsføringskommunikasjon"
override val all_notifications: String = "Alle varslinger"
override val amortization: String = "amortisering"
override val amount: String = "Beløp"
override val asset: String = "eiendel"
override val attach_file: String = "Legg ved fil"
override val attachment: String = "Vedlegg"
override val auth_error: String = "Handlingen mislyktes, prøv igjen."
override val available_for_paid: String = "Skysynkronisering er bare tilgjengelig under betalt abonnement."
override val backups_option: String = "Sikkerhetskopier"
override val balance: String = "saldo"
override val bank_account: String = "Bankkonto"
override val bill_number: String = "Regningsnummer"
override val cancel: String = "kansellere"
override val cancel_trial: String = "Avbryt når som helst"
override val capital: String = "kapital"
override val cash: String = "kontanter"
override val categories: String = "Kategorier"
override val category: String = "Kategori"
override val category_add: String = "Legg til kategori"
override val category_delete: String = "Slett kategori"
override val category_name: String = "Navn på kategori"
override val change_language: Formattable = Formattable { (language) -> "Faktura Språk: ${language}" }
override val change_language_label: String = "Fakturaspråk"
override val change_template: String = "Endre mal"
override val city_hint: String = "Byen"
override val clear: String = "Klart"
override val client: String = "Klient"
override val client_actions: String = "Klient handlinger"
override val client_deleted_message: String = "Klient slettet"
override val clients_error_required_fields: String = "Minst en verdi er nødvendig."
override val clients_option: String = "Klienter"
override val close: String = "Lukk"
override val cloud_sync: String = "Sky-synkronisering"
override val coming_soon: String = "Kommer snart!"
override val company_logo: String = "Firma Logo"
override val company_name_hint: String = "Selskap"
override val confirm_password_label: String = "Bekreft passord"
override val confirmation_title: String = "Er du sikker?"
override val convert_to_invoice: String = "Konverter til faktura"
override val country_hint: String = "Land"
override val create_client: String = "Opprett klient"
override val create_company: String = "Opprett selskap"
override val create_document: String = "Opprett dokument"
override val create_estimate: String = "Anslag"
override val create_invoice: String = "Faktura"
override val create_item: String = "Opprett element"
override val create_new_client: String = "Opprett ny klient"
override val create_new_item: String = "Opprett nytt element"
override val create_new_tax: String = "Opprett ny skatt"
override val create_signature: String = "Signatur"
override val created: String = "Opprettet"
override val credit: String = "kreditt"
override val customer: String = "Kunden"
override val date: String = "Dato"
override val debit: String = "debet"
override val debt: String = "gjeld"
override val default_currency: Formattable = Formattable { (currency) -> "Standardvaluta: ${currency}" }
override val default_currency_label: String = "Standard valuta"
override val delete: String = "Slett"
override val delete_account: String = "Slett Konto"
override val delete_confirmation_message: String = "Alle dataene dine vil gå tapt."
override val delete_item_title: String = "Slett elementet?"
override val delete_signature: String = "Slett signatur"
override val depreciation: String = "avskrivninger"
override val description: String = "Beskrivelse"
override val description_hint: String = "Beskrivelse"
override val detailed_report: String = "Detaljert Rapport"
override val disabled: String = "Funksjonshemmet"
override val discount: String = "Rabatt"
override val discount_hint: String = "Rabatt"
override val display_af: String = "Afrikansk"
override val display_am: String = "Amharisk"
override val display_ar: String = "Arabisk"
override val display_as: String = "Assamesisk"
override val display_az: String = "Aserbajdsjansk"
override val display_be: String = "Hviterussisk"
override val display_bg: String = "Bulgarsk"
override val display_bn: String = "Bengalsk"
override val display_bs: String = "Bosnisk"
override val display_ca: String = "Katalansk"
override val display_cs: String = "Tsjekkisk"
override val display_da: String = "Dansk"
override val display_de: String = "Tysk"
override val display_el: String = "Gresk"
override val display_en: String = "Engelsk"
override val display_es: String = "Spansk"
override val display_es_MX: String = "Spansk (Mexico)"
override val display_et: String = "Estisk"
override val display_eu: String = "Baskisk"
override val display_fa: String = "Persisk"
override val display_fi: String = "Finsk"
override val display_fil: String = "Filippinsk"
override val display_fr: String = "Fransk"
override val display_fr_CA: String = "Kanadisk fransk"
override val display_gl: String = "Galisisk"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hebraisk"
override val display_hi: String = "Hindi"
override val display_hr: String = "Kroatisk"
override val display_hu: String = "Ungarsk"
override val display_hy: String = "Armensk"
override val display_id: String = "Indonesisk"
override val display_is: String = "Islandsk"
override val display_it: String = "Italiensk"
override val display_ja: String = "Japansk"
override val display_ka: String = "Georgisk"
override val display_kk: String = "Kasakhisk"
override val display_km: String = "Sentrale Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Koreansk"
override val display_ky: String = "Kirgisisk"
override val display_lo: String = "Laotisk"
override val display_lt: String = "Litauisk"
override val display_lv: String = "Latvisk"
override val display_mk: String = "Makedonsk"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolsk"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malayisk"
override val display_my: String = "Burmesisk"
override val display_nb: String = "Norwegian Bokmål"
override val display_ne: String = "Nepalsk"
override val display_nl: String = "Nederlandsk"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polsk"
override val display_pt: String = "Portugisisk"
override val display_pt_BR: String = "Portugisisk (Brasil)"
override val display_pt_PT: String = "Europeisk portugisisk"
override val display_ro: String = "Rumensk"
override val display_ru: String = "Russisk"
override val display_si: String = "Singalesisk"
override val display_sk: String = "Slovakisk"
override val display_sl: String = "Slovensk"
override val display_sq: String = "Albansk"
override val display_sr: String = "Serbisk"
override val display_sv: String = "Svensk"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Tyrkisk"
override val display_uk: String = "Ukrainsk"
override val display_ur: String = "Urdu"
override val display_uz: String = "Usbekisk"
override val display_vi: String = "Vietnamesisk"
override val display_zh_Hant_TW: String = "Kinesisk (Taiwan, Tradisjonell)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Anslag"
override val document_invoice: String = "Faktura"
override val document_quote: String = "Tilbud"
override val done: String = "Ferdig"
override val download_app: String = "Last ned appen"
override val download_invoice: String = "Last ned Faktura"
override val download_pdf: String = "Last ned PDF"
override val draw_signature: String = "Tegne en signatur"
override val due: Formattable = Formattable { (date) -> "Forfall ${date}" }
override val due_date: String = "Forfallsdato"
override val due_day: String = "på en dag"
override val due_label: String = "På grunn"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} forfaller ${dueWhen}!" }
override val due_today: String = "i dag"
override val due_week: String = "om en uke"
override val duplicate: String = "Duplisere"
override val duration_day: String = "dag"
override val duration_month: String = "Måned"
override val duration_week: String = "uke"
override val duration_year: String = "år"
override val email: String = "E-post"
override val email_blank_error: String = "E-post kreves."
override val email_error: String = "Ugyldig e-postadresse."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Takk for handelen. For å se hele ${documentType} klikk på knappen på slutten av dette brevet. Merk at den er gyldig til ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Du har mottatt en ny ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "VIS ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Kjære ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Takk for handelen. For å se hele ${documentType} klikk på knappen på slutten av dette brevet. Vennligst betal saldoen til ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Du har mottatt en ny ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "E-post for å tilbakestille passordet sendt, vennligst sjekk innboksen din."
override val empty_clients_list_message: String = "Du har ingen klienter å spore. Legg til en ny klient."
override val empty_estimate_list_message: String = "Lag ditt første estimat"
override val empty_expenses: String = "Du har ingen utgifter å spore. Legg til en ny utgift."
override val empty_invoice_list_message: String = "Opprett din første faktura"
override val empty_items_list_message: String = "Du har ingen elementer, legg til et nytt element!"
override val empty_signatures_list_message: String = "Ingen signaturer funnet. Lag en!"
override val empty_taxes_list_message: String = "Du har ingen skatteregler. Legg til en ny skatteregel."
override val empty_templates_list_message: String = "Maler utilgjengelige."
override val enabled: String = "Aktivert"
override val entry: String = "post"
override val equity: String = "egenkapital"
override val estimate_number: String = "Anslag #"
override val estimates: String = "Estimater"
override val estimates_report: String = "Estimater"
override val expense_bank_fees: String = "Bankgebyrer"
override val expense_benefits: String = "Fordeler"
override val expense_communication: String = "Kommunikasjon"
override val expense_debt: String = "Gjeld"
override val expense_deleted: String = "Utgifter slettet"
override val expense_depreciation: String = "Avskrivninger"
override val expense_gifts: String = "Gaver"
override val expense_insurance: String = "Forsikring"
override val expense_inventory: String = "Lagerbeholdning"
override val expense_legal: String = "Juridisk"
override val expense_maintenance: String = "Vedlikehold"
override val expense_marketing: String = "Markedsføring"
override val expense_meals: String = "Måltider"
override val expense_office_supplies: String = "Kontorrekvisita"
override val expense_rent: String = "Leie"
override val expense_salaries: String = "Lønn"
override val expense_shopping: String = "Shopping"
override val expense_software: String = "Programvare"
override val expense_status_available: String = "Tilgjengelig"
override val expense_status_processing: String = "Behandling"
override val expense_tax: String = "Skatt"
override val expense_training: String = "Opplæring"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Reise"
override val expense_utility: String = "Verktøy"
override val expenses: String = "Utgifter"
override val feedback_option: String = "Tilbakemelding"
override val filter: String = "Filtrer"
override val first_name_hint: String = "Fornavn"
override val forgot_password: String = "Tilbakestill passord"
override val forgot_password_label: String = "Glemt passord"
override val from: String = "Fra"
override val general_error: String = "Det oppstod en feil, prøv igjen senere."
override val general_ledger: String = "hovedbok"
override val go_back_label: String = "Gå tilbake"
override val group_by: String = "Gruppe av:"
override val image_view: String = "Bilde"
override val install: String = "Installer"
override val invalid_credentials_error: String = "Ugyldige legitimasjonsbeskrivelser."
override val invalid_email_error: String = "Ugyldig e-post."
override val invalid_user_error: String = "Ugyldig brukerlegitimasjon."
override val invoice_deleted_message: String = "Faktura slettet"
override val invoice_menu: String = "Faktura-menyen"
override val invoice_number: String = "Faktura #"
override val invoices: String = "Fakturaer"
override val items_option: String = "Elementer"
override val keep: String = "Hold"
override val language_selector: Formattable = Formattable { (selected) -> "Språk ${selected}" }
override val last_name_hint: String = "Etternavn"
override val ledger: String = "regnskapsbok"
override val legal: String = "juridisk"
override val legal_error: String = "Må godta Vilkår for bruk og Personvern"
override val liabilities: String = "forpliktelser"
override val loading: String = "Laster"
override val login_required_error: String = "Pålogging kreves."
override val logo_view: String = "Logo"
override val logout: String = "Logg ut"
override val logout_prompt: String = "Dataene dine synkroniseres ikke med skyen. Slett fakturaer, estimater, klienter og annen informasjon om utlogging?"
override val main_screen: String = "Hovedskjerm"
override val mark_paid: String = "Marker som betalt"
override val message: String = "Melding"
override val missing_sender_msg: String = "Et dokument uten avsenderinformasjon kan være ugyldig."
override val missing_sender_title: String = "Informasjon om manglende avsender"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "August"
override val month_august_short: String = "Aug."
override val month_december: String = "Desember"
override val month_december_short: String = "Des."
override val month_february: String = "Februar"
override val month_february_short: String = "Feb."
override val month_january: String = "Januar"
override val month_january_short: String = "Jan."
override val month_july: String = "Juli"
override val month_july_short: String = "Juli"
override val month_june: String = "Juni"
override val month_june_short: String = "Juni"
override val month_march: String = "Mars"
override val month_march_short: String = "Mars"
override val month_may: String = "Mai"
override val month_may_short: String = "Mai"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktober"
override val month_october_short: String = "Okt."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Kan ikke verifisere."
override val new: String = "Ny"
override val news: String = "Nyheter"
override val next: String = "Neste"
override val no: String = "nei"
override val no_data_available: String = "Ingen data tilgjengelig"
override val no_search_results: String = "Vi fant ingen resultater."
override val none: String = "Ingen"
override val note_fields: String = "Noter"
override val note_hint: String = "Note"
override val notifications_option: String = "Varslinger"
override val ok: String = "OK"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Viktige deler av DingVoice er for alltid gratis. Vårt oppdrag er å hjelpe deg med å utvide virksomheten din, hjelpe oss med å forbedre oss ved å dele tilbakemeldingene dine."
override val onboarding_aboutus_title: String = "Vi er her for å hjelpe"
override val onboarding_paid_1: String = "Få tilgang til profesjonelt utseende maler"
override val onboarding_paid_2: String = "Ta en dypere titt på økonomien din"
override val onboarding_paid_3: String = "Få problemer løst raskt"
override val onboarding_paid_4: String = "Hold dokumentene dine trygge"
override val onboarding_paid_5: String = "Hold deg oppdatert med endringer i fakturastatus"
override val onboarding_paid_title: String = "Vokse enda raskere med..."
override val onboarding_paywall_message: String = "Nyt alle premium funksjoner nå til den beste prisen."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Bare ${currencySymbol}${introAmount} for ${introDuration} ${introPeriod} og deretter ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Bare ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis for ${trialDuration} ${trialPeriod} og deretter bare ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis for ${trialDuration} ${trialPeriod}, deretter ${currencySymbol}${introductoryAmount} for ${introductoryDuration} ${introPeriod} og deretter bare ${currencySymbol}${amount} /... ${duration}" }
override val onboarding_paywall_renewable: String = "Automatisk fornybar. Avbryt når som helst."
override val onboarding_paywall_restore: String = "Gjenopprett kjøp"
override val onboarding_paywall_restore_failure: String = "Gjenoppretting av kjøp mislyktes. Prøv igjen senere eller kontakt brukerstøtten."
override val onboarding_paywall_restore_success: String = "Gjenoppretting vellykket!"
override val onboarding_paywall_title: String = "Oppnå storhet!"
override val onboarding_welcome_message: String = "Et komplett verktøysett for å administrere dine fakturaer og estimater."
override val onboarding_welcome_title: String = "Velkommen til DingVoice"
override val other_state: String = "Annet"
override val overdue_state: String = "Forfalt"
override val paid_state: String = "Betalt"
override val password: String = "Passord"
override val password_blank_error: String = "Passord kreves."
override val password_mismatch_error: String = "Passordene stemmer ikke overens."
override val pay: String = "betal"
override val payment_instructions_label: String = "Instruksjoner for betaling"
override val payment_reminders: String = "Betaling påminnelser"
override val phone_number_fields: String = "Telefonnummer"
override val phone_number_hint: String = "Telefonnummer"
override val postcode_hint: String = "Postnummer"
override val preview: String = "Forhåndsvisning"
override val preview_menu_hint: String = "Forhåndsvis meny"
override val price: String = "pris"
override val privacy_policy: String = "Retningslinjer for personvern"
override val profile: String = "Profil"
override val profitable_client: String = "Din mest lønnsomme klient er..."
override val profits_report: String = "Fortjeneste"
override val promo_content: String = "Reklame innhold"
override val quantity_hint: String = "Mengde"
override val quantity_label_hint: String = "Mengde etikett"
override val query_info: String = "Spørring lar deg skrive egendefinerte beregninger som refererer til både elementer og andre skatteregler som innganger. Legg til @ symbol og vare- eller avgiftsbeskrivelse for å lenke til en annen oppføring +, -, *,/,% - matematikkoperatører"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ble mottatt av klienten din." }
override val received_state: String = "Mottatt"
override val registration_code_fields: String = "Registreringskode"
override val reminder: String = "Påminnelse"
override val reports: String = "Rapporterer"
override val reports_not_available: String = "Ingen rapporter tilgjengelig, kom tilbake senere."
override val reveal_password: String = "Avslør passord"
override val revenue: String = "inntekter"
override val sales_tax: String = "MVA"
override val sales_tax_number: String = "MVA-nummer"
override val save: String = "Spar"
override val scan_document: String = "Skann dokument"
override val search: String = "søk"
override val select: String = "Velg"
override val select_icon: String = "Velg ikon"
override val select_signature: String = "Velg signatur"
override val select_template: String = "Velg mal"
override val send: String = "Send"
override val sent_state: String = "Sendt"
override val setup_sender: String = "Sett opp avsender"
override val share_option: String = "Dele"
override val ship_to: String = "Send til"
override val sign_in: String = "Logg på"
override val sign_in_apple: String = "Logg på med Apple"
override val sign_in_email: String = "Logg på med e-post"
override val sign_in_facebook: String = "Logg på med Facebook"
override val sign_in_google: String = "Logg på med Google"
override val sign_up: String = "Registrer deg"
override val sign_up_label: String = "Har du ikke en konto?"
override val sign_up_link_label: String = "Registrer deg!"
override val signatures_option: String = "Signaturer"
override val skip: String = "Hopp over"
override val sold_items_report: String = "Solgte Elementer"
override val sort_by: String = "Sorter etter: "
override val status_update: String = "Oppdatering av dokumentstatus"
override val sub_annual: String = "Årlig"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/år" }
override val sub_backups: String = "Sky sikkerhetskopier"
override val sub_insights: String = "Innsikt"
override val sub_month: String = "Månedlig"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/måned" }
override val sub_notifications: String = "Varslinger"
override val sub_popup_message: String = "Abonnementer kommer snart. Sjekk tilbake hit om en liten stund."
override val sub_support: String = "Kundeservice"
override val sub_templates: String = "Maler"
override val subscribe: String = "Abonner"
override val subscription_header: String = "Utvid virksomheten din med:"
override val subscription_option: String = "abonnement"
override val subscriptions_load_fail: String = "Beklager, vi kan ikke hente abonnementene dine for øyeblikket. Vennligst prøv igjen senere eller kontakt supportteamet vårt for å få hjelp."
override val subtotal: Formattable = Formattable { (sum) -> "Delsum: ${sum}" }
override val subtotal_label: String = "Delsum"
override val sum: String = "Sum"
override val support_option: String = "Støtte"
override val switch_reports: String = "Bytt Rapport"
override val tax: String = "Skatt"
override val tax_deleted_message: String = "Skatt slettet"
override val tax_description_warning: String = "Hvis brukt dette vises på en faktura"
override val tax_input_flat: String = "Flatt"
override val tax_input_percent: String = "prosent"
override val tax_number_fields: String = "Skattenummer"
override val tax_number_hint: String = "Skattenummer"
override val tax_query_info_description: String = "Spørring Info"
override val tax_report: String = "Skatt"
override val tax_rules_option: String = "Skatte Regler"
override val taxed_clients_report: String = "Skattelagte Kunder"
override val template: String = "Mal"
override val template_selector_info: String = "Sveip for å endre standardmalen"
override val template_unlock_promo: String = "Få tilgang til flere maler med Premium"
override val templates_option: String = "Maler"
override val terms_of_service: String = "Vilkår for bruk"
override val thank_you: String = "Takk"
override val to: String = "til"
override val total: Formattable = Formattable { (sum) -> "Totalt: ${sum}" }
override val total_label: String = "Totalt"
override val undo_action: String = "Angre"
override val unit: String = "Enhet"
override val unit_cost_hint: String = "Enhetskostnad"
override val unknown_client: String = "Ukjent Klient"
override val unlock: String = "Låse opp"
override val unnamed_item: String = "Navngitt element"
override val upgrade: String = "Oppgrader"
override val user_collision_error: String = "Kontoen eksisterer allerede."
override val valid_until: String = "Gyldig Til"
override val warning: String = "Advarsel!"
override val yes: String = "ja"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Godta ${termsOfService} og ${privacyPolicy}" },
	"account" to "konto",
	"accounting" to "regnskap",
	"accruals" to "periodiseringer",
	"action_code_error" to "Ugyldig kode.",
	"add_client_action" to "Legg til klient",
	"add_customer" to "Legg til kunde",
	"add_expense" to "Legg til utgifter",
	"add_fields_label" to "Legg til:",
	"add_invoice_action" to "Opprette",
	"add_item_action" to "Legg til element",
	"add_item_button" to "Legg til element",
	"add_signature_action" to "Legg til signatur",
	"add_tax_action" to "Legg til skatt",
	"address_fields" to "Adresse",
	"address_line_hint" to "Adresselinje",
	"advanced" to "Avansert",
	"agree_marketing" to "Jeg godtar å motta direkte markedsføringskommunikasjon",
	"all_notifications" to "Alle varslinger",
	"amortization" to "amortisering",
	"amount" to "Beløp",
	"asset" to "eiendel",
	"attach_file" to "Legg ved fil",
	"attachment" to "Vedlegg",
	"auth_error" to "Handlingen mislyktes, prøv igjen.",
	"available_for_paid" to "Skysynkronisering er bare tilgjengelig under betalt abonnement.",
	"backups_option" to "Sikkerhetskopier",
	"balance" to "saldo",
	"bank_account" to "Bankkonto",
	"bill_number" to "Regningsnummer",
	"cancel" to "kansellere",
	"cancel_trial" to "Avbryt når som helst",
	"capital" to "kapital",
	"cash" to "kontanter",
	"categories" to "Kategorier",
	"category" to "Kategori",
	"category_add" to "Legg til kategori",
	"category_delete" to "Slett kategori",
	"category_name" to "Navn på kategori",
	"change_language" to Formattable { (language) -> "Faktura Språk: ${language}" },
	"change_language_label" to "Fakturaspråk",
	"change_template" to "Endre mal",
	"city_hint" to "Byen",
	"clear" to "Klart",
	"client" to "Klient",
	"client_actions" to "Klient handlinger",
	"client_deleted_message" to "Klient slettet",
	"clients_error_required_fields" to "Minst en verdi er nødvendig.",
	"clients_option" to "Klienter",
	"close" to "Lukk",
	"cloud_sync" to "Sky-synkronisering",
	"coming_soon" to "Kommer snart!",
	"company_logo" to "Firma Logo",
	"company_name_hint" to "Selskap",
	"confirm_password_label" to "Bekreft passord",
	"confirmation_title" to "Er du sikker?",
	"convert_to_invoice" to "Konverter til faktura",
	"country_hint" to "Land",
	"create_client" to "Opprett klient",
	"create_company" to "Opprett selskap",
	"create_document" to "Opprett dokument",
	"create_estimate" to "Anslag",
	"create_invoice" to "Faktura",
	"create_item" to "Opprett element",
	"create_new_client" to "Opprett ny klient",
	"create_new_item" to "Opprett nytt element",
	"create_new_tax" to "Opprett ny skatt",
	"create_signature" to "Signatur",
	"created" to "Opprettet",
	"credit" to "kreditt",
	"customer" to "Kunden",
	"date" to "Dato",
	"debit" to "debet",
	"debt" to "gjeld",
	"default_currency" to Formattable { (currency) -> "Standardvaluta: ${currency}" },
	"default_currency_label" to "Standard valuta",
	"delete" to "Slett",
	"delete_account" to "Slett Konto",
	"delete_confirmation_message" to "Alle dataene dine vil gå tapt.",
	"delete_item_title" to "Slett elementet?",
	"delete_signature" to "Slett signatur",
	"depreciation" to "avskrivninger",
	"description" to "Beskrivelse",
	"description_hint" to "Beskrivelse",
	"detailed_report" to "Detaljert Rapport",
	"disabled" to "Funksjonshemmet",
	"discount" to "Rabatt",
	"discount_hint" to "Rabatt",
	"display_af" to "Afrikansk",
	"display_am" to "Amharisk",
	"display_ar" to "Arabisk",
	"display_as" to "Assamesisk",
	"display_az" to "Aserbajdsjansk",
	"display_be" to "Hviterussisk",
	"display_bg" to "Bulgarsk",
	"display_bn" to "Bengalsk",
	"display_bs" to "Bosnisk",
	"display_ca" to "Katalansk",
	"display_cs" to "Tsjekkisk",
	"display_da" to "Dansk",
	"display_de" to "Tysk",
	"display_el" to "Gresk",
	"display_en" to "Engelsk",
	"display_es" to "Spansk",
	"display_es_MX" to "Spansk (Mexico)",
	"display_et" to "Estisk",
	"display_eu" to "Baskisk",
	"display_fa" to "Persisk",
	"display_fi" to "Finsk",
	"display_fil" to "Filippinsk",
	"display_fr" to "Fransk",
	"display_fr_CA" to "Kanadisk fransk",
	"display_gl" to "Galisisk",
	"display_gu" to "Gujarati",
	"display_he" to "Hebraisk",
	"display_hi" to "Hindi",
	"display_hr" to "Kroatisk",
	"display_hu" to "Ungarsk",
	"display_hy" to "Armensk",
	"display_id" to "Indonesisk",
	"display_is" to "Islandsk",
	"display_it" to "Italiensk",
	"display_ja" to "Japansk",
	"display_ka" to "Georgisk",
	"display_kk" to "Kasakhisk",
	"display_km" to "Sentrale Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Koreansk",
	"display_ky" to "Kirgisisk",
	"display_lo" to "Laotisk",
	"display_lt" to "Litauisk",
	"display_lv" to "Latvisk",
	"display_mk" to "Makedonsk",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolsk",
	"display_mr" to "Marathi",
	"display_ms" to "Malayisk",
	"display_my" to "Burmesisk",
	"display_nb" to "Norwegian Bokmål",
	"display_ne" to "Nepalsk",
	"display_nl" to "Nederlandsk",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polsk",
	"display_pt" to "Portugisisk",
	"display_pt_BR" to "Portugisisk (Brasil)",
	"display_pt_PT" to "Europeisk portugisisk",
	"display_ro" to "Rumensk",
	"display_ru" to "Russisk",
	"display_si" to "Singalesisk",
	"display_sk" to "Slovakisk",
	"display_sl" to "Slovensk",
	"display_sq" to "Albansk",
	"display_sr" to "Serbisk",
	"display_sv" to "Svensk",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Tyrkisk",
	"display_uk" to "Ukrainsk",
	"display_ur" to "Urdu",
	"display_uz" to "Usbekisk",
	"display_vi" to "Vietnamesisk",
	"display_zh_Hant_TW" to "Kinesisk (Taiwan, Tradisjonell)",
	"display_zu" to "Zulu",
	"document_estimate" to "Anslag",
	"document_invoice" to "Faktura",
	"document_quote" to "Tilbud",
	"done" to "Ferdig",
	"download_app" to "Last ned appen",
	"download_invoice" to "Last ned Faktura",
	"download_pdf" to "Last ned PDF",
	"draw_signature" to "Tegne en signatur",
	"due" to Formattable { (date) -> "Forfall ${date}" },
	"due_date" to "Forfallsdato",
	"due_day" to "på en dag",
	"due_label" to "På grunn",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} forfaller ${dueWhen}!" },
	"due_today" to "i dag",
	"due_week" to "om en uke",
	"duplicate" to "Duplisere",
	"duration_day" to "dag",
	"duration_month" to "Måned",
	"duration_week" to "uke",
	"duration_year" to "år",
	"email" to "E-post",
	"email_blank_error" to "E-post kreves.",
	"email_error" to "Ugyldig e-postadresse.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Takk for handelen. For å se hele ${documentType} klikk på knappen på slutten av dette brevet. Merk at den er gyldig til ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Du har mottatt en ny ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "VIS ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Kjære ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Takk for handelen. For å se hele ${documentType} klikk på knappen på slutten av dette brevet. Vennligst betal saldoen til ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Du har mottatt en ny ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "E-post for å tilbakestille passordet sendt, vennligst sjekk innboksen din.",
	"empty_clients_list_message" to "Du har ingen klienter å spore. Legg til en ny klient.",
	"empty_estimate_list_message" to "Lag ditt første estimat",
	"empty_expenses" to "Du har ingen utgifter å spore. Legg til en ny utgift.",
	"empty_invoice_list_message" to "Opprett din første faktura",
	"empty_items_list_message" to "Du har ingen elementer, legg til et nytt element!",
	"empty_signatures_list_message" to "Ingen signaturer funnet. Lag en!",
	"empty_taxes_list_message" to "Du har ingen skatteregler. Legg til en ny skatteregel.",
	"empty_templates_list_message" to "Maler utilgjengelige.",
	"enabled" to "Aktivert",
	"entry" to "post",
	"equity" to "egenkapital",
	"estimate_number" to "Anslag #",
	"estimates" to "Estimater",
	"estimates_report" to "Estimater",
	"expense_bank_fees" to "Bankgebyrer",
	"expense_benefits" to "Fordeler",
	"expense_communication" to "Kommunikasjon",
	"expense_debt" to "Gjeld",
	"expense_deleted" to "Utgifter slettet",
	"expense_depreciation" to "Avskrivninger",
	"expense_gifts" to "Gaver",
	"expense_insurance" to "Forsikring",
	"expense_inventory" to "Lagerbeholdning",
	"expense_legal" to "Juridisk",
	"expense_maintenance" to "Vedlikehold",
	"expense_marketing" to "Markedsføring",
	"expense_meals" to "Måltider",
	"expense_office_supplies" to "Kontorrekvisita",
	"expense_rent" to "Leie",
	"expense_salaries" to "Lønn",
	"expense_shopping" to "Shopping",
	"expense_software" to "Programvare",
	"expense_status_available" to "Tilgjengelig",
	"expense_status_processing" to "Behandling",
	"expense_tax" to "Skatt",
	"expense_training" to "Opplæring",
	"expense_transport" to "Transport",
	"expense_travel" to "Reise",
	"expense_utility" to "Verktøy",
	"expenses" to "Utgifter",
	"feedback_option" to "Tilbakemelding",
	"filter" to "Filtrer",
	"first_name_hint" to "Fornavn",
	"forgot_password" to "Tilbakestill passord",
	"forgot_password_label" to "Glemt passord",
	"from" to "Fra",
	"general_error" to "Det oppstod en feil, prøv igjen senere.",
	"general_ledger" to "hovedbok",
	"go_back_label" to "Gå tilbake",
	"group_by" to "Gruppe av:",
	"image_view" to "Bilde",
	"install" to "Installer",
	"invalid_credentials_error" to "Ugyldige legitimasjonsbeskrivelser.",
	"invalid_email_error" to "Ugyldig e-post.",
	"invalid_user_error" to "Ugyldig brukerlegitimasjon.",
	"invoice_deleted_message" to "Faktura slettet",
	"invoice_menu" to "Faktura-menyen",
	"invoice_number" to "Faktura #",
	"invoices" to "Fakturaer",
	"items_option" to "Elementer",
	"keep" to "Hold",
	"language_selector" to Formattable { (selected) -> "Språk ${selected}" },
	"last_name_hint" to "Etternavn",
	"ledger" to "regnskapsbok",
	"legal" to "juridisk",
	"legal_error" to "Må godta Vilkår for bruk og Personvern",
	"liabilities" to "forpliktelser",
	"loading" to "Laster",
	"login_required_error" to "Pålogging kreves.",
	"logo_view" to "Logo",
	"logout" to "Logg ut",
	"logout_prompt" to "Dataene dine synkroniseres ikke med skyen. Slett fakturaer, estimater, klienter og annen informasjon om utlogging?",
	"main_screen" to "Hovedskjerm",
	"mark_paid" to "Marker som betalt",
	"message" to "Melding",
	"missing_sender_msg" to "Et dokument uten avsenderinformasjon kan være ugyldig.",
	"missing_sender_title" to "Informasjon om manglende avsender",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "August",
	"month_august_short" to "Aug.",
	"month_december" to "Desember",
	"month_december_short" to "Des.",
	"month_february" to "Februar",
	"month_february_short" to "Feb.",
	"month_january" to "Januar",
	"month_january_short" to "Jan.",
	"month_july" to "Juli",
	"month_july_short" to "Juli",
	"month_june" to "Juni",
	"month_june_short" to "Juni",
	"month_march" to "Mars",
	"month_march_short" to "Mars",
	"month_may" to "Mai",
	"month_may_short" to "Mai",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Oktober",
	"month_october_short" to "Okt.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Kan ikke verifisere.",
	"new" to "Ny",
	"news" to "Nyheter",
	"next" to "Neste",
	"no" to "nei",
	"no_data_available" to "Ingen data tilgjengelig",
	"no_search_results" to "Vi fant ingen resultater.",
	"none" to "Ingen",
	"note_fields" to "Noter",
	"note_hint" to "Note",
	"notifications_option" to "Varslinger",
	"ok" to "OK",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Viktige deler av DingVoice er for alltid gratis. Vårt oppdrag er å hjelpe deg med å utvide virksomheten din, hjelpe oss med å forbedre oss ved å dele tilbakemeldingene dine.",
	"onboarding_aboutus_title" to "Vi er her for å hjelpe",
	"onboarding_paid_1" to "Få tilgang til profesjonelt utseende maler",
	"onboarding_paid_2" to "Ta en dypere titt på økonomien din",
	"onboarding_paid_3" to "Få problemer løst raskt",
	"onboarding_paid_4" to "Hold dokumentene dine trygge",
	"onboarding_paid_5" to "Hold deg oppdatert med endringer i fakturastatus",
	"onboarding_paid_title" to "Vokse enda raskere med...",
	"onboarding_paywall_message" to "Nyt alle premium funksjoner nå til den beste prisen.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Bare ${currencySymbol}${introAmount} for ${introDuration} ${introPeriod} og deretter ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Bare ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis for ${trialDuration} ${trialPeriod} og deretter bare ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis for ${trialDuration} ${trialPeriod}, deretter ${currencySymbol}${introductoryAmount} for ${introductoryDuration} ${introPeriod} og deretter bare ${currencySymbol}${amount} /... ${duration}" },
	"onboarding_paywall_renewable" to "Automatisk fornybar. Avbryt når som helst.",
	"onboarding_paywall_restore" to "Gjenopprett kjøp",
	"onboarding_paywall_restore_failure" to "Gjenoppretting av kjøp mislyktes. Prøv igjen senere eller kontakt brukerstøtten.",
	"onboarding_paywall_restore_success" to "Gjenoppretting vellykket!",
	"onboarding_paywall_title" to "Oppnå storhet!",
	"onboarding_welcome_message" to "Et komplett verktøysett for å administrere dine fakturaer og estimater.",
	"onboarding_welcome_title" to "Velkommen til DingVoice",
	"other_state" to "Annet",
	"overdue_state" to "Forfalt",
	"paid_state" to "Betalt",
	"password" to "Passord",
	"password_blank_error" to "Passord kreves.",
	"password_mismatch_error" to "Passordene stemmer ikke overens.",
	"pay" to "betal",
	"payment_instructions_label" to "Instruksjoner for betaling",
	"payment_reminders" to "Betaling påminnelser",
	"phone_number_fields" to "Telefonnummer",
	"phone_number_hint" to "Telefonnummer",
	"postcode_hint" to "Postnummer",
	"preview" to "Forhåndsvisning",
	"preview_menu_hint" to "Forhåndsvis meny",
	"price" to "pris",
	"privacy_policy" to "Retningslinjer for personvern",
	"profile" to "Profil",
	"profitable_client" to "Din mest lønnsomme klient er...",
	"profits_report" to "Fortjeneste",
	"promo_content" to "Reklame innhold",
	"quantity_hint" to "Mengde",
	"quantity_label_hint" to "Mengde etikett",
	"query_info" to "Spørring lar deg skrive egendefinerte beregninger som refererer til både elementer og andre skatteregler som innganger. Legg til @ symbol og vare- eller avgiftsbeskrivelse for å lenke til en annen oppføring +, -, *,/,% - matematikkoperatører",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ble mottatt av klienten din." },
	"received_state" to "Mottatt",
	"registration_code_fields" to "Registreringskode",
	"reminder" to "Påminnelse",
	"reports" to "Rapporterer",
	"reports_not_available" to "Ingen rapporter tilgjengelig, kom tilbake senere.",
	"reveal_password" to "Avslør passord",
	"revenue" to "inntekter",
	"sales_tax" to "MVA",
	"sales_tax_number" to "MVA-nummer",
	"save" to "Spar",
	"scan_document" to "Skann dokument",
	"search" to "søk",
	"select" to "Velg",
	"select_icon" to "Velg ikon",
	"select_signature" to "Velg signatur",
	"select_template" to "Velg mal",
	"send" to "Send",
	"sent_state" to "Sendt",
	"setup_sender" to "Sett opp avsender",
	"share_option" to "Dele",
	"ship_to" to "Send til",
	"sign_in" to "Logg på",
	"sign_in_apple" to "Logg på med Apple",
	"sign_in_email" to "Logg på med e-post",
	"sign_in_facebook" to "Logg på med Facebook",
	"sign_in_google" to "Logg på med Google",
	"sign_up" to "Registrer deg",
	"sign_up_label" to "Har du ikke en konto?",
	"sign_up_link_label" to "Registrer deg!",
	"signatures_option" to "Signaturer",
	"skip" to "Hopp over",
	"sold_items_report" to "Solgte Elementer",
	"sort_by" to "Sorter etter: ",
	"status_update" to "Oppdatering av dokumentstatus",
	"sub_annual" to "Årlig",
	"sub_annual_price" to Formattable { (price) -> "${price}/år" },
	"sub_backups" to "Sky sikkerhetskopier",
	"sub_insights" to "Innsikt",
	"sub_month" to "Månedlig",
	"sub_monthly_price" to Formattable { (price) -> "${price}/måned" },
	"sub_notifications" to "Varslinger",
	"sub_popup_message" to "Abonnementer kommer snart. Sjekk tilbake hit om en liten stund.",
	"sub_support" to "Kundeservice",
	"sub_templates" to "Maler",
	"subscribe" to "Abonner",
	"subscription_header" to "Utvid virksomheten din med:",
	"subscription_option" to "abonnement",
	"subscriptions_load_fail" to "Beklager, vi kan ikke hente abonnementene dine for øyeblikket. Vennligst prøv igjen senere eller kontakt supportteamet vårt for å få hjelp.",
	"subtotal" to Formattable { (sum) -> "Delsum: ${sum}" },
	"subtotal_label" to "Delsum",
	"sum" to "Sum",
	"support_option" to "Støtte",
	"switch_reports" to "Bytt Rapport",
	"tax" to "Skatt",
	"tax_deleted_message" to "Skatt slettet",
	"tax_description_warning" to "Hvis brukt dette vises på en faktura",
	"tax_input_flat" to "Flatt",
	"tax_input_percent" to "prosent",
	"tax_number_fields" to "Skattenummer",
	"tax_number_hint" to "Skattenummer",
	"tax_query_info_description" to "Spørring Info",
	"tax_report" to "Skatt",
	"tax_rules_option" to "Skatte Regler",
	"taxed_clients_report" to "Skattelagte Kunder",
	"template" to "Mal",
	"template_selector_info" to "Sveip for å endre standardmalen",
	"template_unlock_promo" to "Få tilgang til flere maler med Premium",
	"templates_option" to "Maler",
	"terms_of_service" to "Vilkår for bruk",
	"thank_you" to "Takk",
	"to" to "til",
	"total" to Formattable { (sum) -> "Totalt: ${sum}" },
	"total_label" to "Totalt",
	"undo_action" to "Angre",
	"unit" to "Enhet",
	"unit_cost_hint" to "Enhetskostnad",
	"unknown_client" to "Ukjent Klient",
	"unlock" to "Låse opp",
	"unnamed_item" to "Navngitt element",
	"upgrade" to "Oppgrader",
	"user_collision_error" to "Kontoen eksisterer allerede.",
	"valid_until" to "Gyldig Til",
	"warning" to "Advarsel!",
	"yes" to "ja",
    )
    
}