// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Et  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Aktsepteeri ${termsOfService} ja ${privacyPolicy}" }
override val account: String = "konto"
override val accounting: String = "raamatupidamine"
override val accruals: String = "tekkepõhine"
override val action_code_error: String = "Vale kood."
override val add_client_action: String = "Lisa klient"
override val add_customer: String = "Lisa klient"
override val add_expense: String = "Lisa kulu"
override val add_fields_label: String = "Lisa:"
override val add_invoice_action: String = "Loo"
override val add_item_action: String = "Lisa element"
override val add_item_button: String = "Lisa objekt"
override val add_signature_action: String = "Allkirja lisamine"
override val add_tax_action: String = "Lisage maksud"
override val address_fields: String = "Aadress"
override val address_line_hint: String = "Aadressi rida"
override val advanced: String = "Täiustatud"
override val agree_marketing: String = "Nõustun otseturundussõnumite saamisega"
override val all_notifications: String = "Kõik teated"
override val amortization: String = "amortisatsioon"
override val amount: String = "Summa"
override val asset: String = "vara"
override val attach_file: String = "Lisa fail"
override val attachment: String = "Lisa"
override val auth_error: String = "Tegevus ebaõnnestus, proovige uuesti."
override val available_for_paid: String = "Pilvesünkroonimine on saadaval ainult tasulise tellimuse korral."
override val backups_option: String = "Varukoopiaid"
override val balance: String = "saldo"
override val bank_account: String = "Pangakonto"
override val bill_number: String = "Arve number"
override val cancel: String = "Tühista"
override val cancel_trial: String = "Tühista igal ajal"
override val capital: String = "kapitali"
override val cash: String = "sularaha"
override val categories: String = "Kategooriad"
override val category: String = "Kategooria"
override val category_add: String = "Kategooria lisamine"
override val category_delete: String = "Kustuta kategooria"
override val category_name: String = "Kategooria nimi"
override val change_language: Formattable = Formattable { (language) -> "Arve keel: ${language}" }
override val change_language_label: String = "Arve Keel"
override val change_template: String = "Muuta malli"
override val city_hint: String = "Linn"
override val clear: String = "Selge"
override val client: String = "Klient"
override val client_actions: String = "Kliendi tegevused"
override val client_deleted_message: String = "Klient kustutatud"
override val clients_error_required_fields: String = "Vajalik on vähemalt üks väärtus."
override val clients_option: String = "Kliendid"
override val close: String = "Sulge"
override val cloud_sync: String = "Pilvede sünkroonimine"
override val coming_soon: String = "Varsti tulemas!"
override val company_logo: String = "Ettevõtte logo"
override val company_name_hint: String = "Ettevõte"
override val confirm_password_label: String = "Parooli kinnitamine"
override val confirmation_title: String = "Kas oled kindel?"
override val convert_to_invoice: String = "Teisenda arveks"
override val country_hint: String = "Riik"
override val create_client: String = "Kliendi loomine"
override val create_company: String = "Ettevõtte loomine"
override val create_document: String = "Dokumendi loomine"
override val create_estimate: String = "Hinnang:"
override val create_invoice: String = "Arve"
override val create_item: String = "Elemendi loomine"
override val create_new_client: String = "Uue kliendi loomine"
override val create_new_item: String = "Uue elemendi loomine"
override val create_new_tax: String = "Uue maksu loomine"
override val create_signature: String = "Allkiri"
override val created: String = "Loodud"
override val credit: String = "krediit"
override val customer: String = "Klient"
override val date: String = "Kuupäev"
override val debit: String = "deebet"
override val debt: String = "võlg"
override val default_currency: Formattable = Formattable { (currency) -> "Vaikevaluuta: ${currency}" }
override val default_currency_label: String = "Vaikevaluuta"
override val delete: String = "Kustuta"
override val delete_account: String = "Kustuta Konto"
override val delete_confirmation_message: String = "Kõik teie andmed lähevad kaotsi."
override val delete_item_title: String = "Kustutada kirje?"
override val delete_signature: String = "Kustuta allkiri"
override val depreciation: String = "amortisatsioon"
override val description: String = "Kirjeldus"
override val description_hint: String = "Kirjeldus"
override val detailed_report: String = "Üksikasjalik Aruanne"
override val disabled: String = "Puudega"
override val discount: String = "Allahindlus"
override val discount_hint: String = "Allahindlus"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharic"
override val display_ar: String = "Araabia keel"
override val display_as: String = "Assami"
override val display_az: String = "Aserbaidžaan"
override val display_be: String = "Valgevene"
override val display_bg: String = "Bulgaaria"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosnia ja Hertsegoviina"
override val display_ca: String = "Katalaani"
override val display_cs: String = "Tšehhi"
override val display_da: String = "Taani"
override val display_de: String = "Saksa"
override val display_el: String = "Kreeka"
override val display_en: String = "Inglise keeles"
override val display_es: String = "Hispaania"
override val display_es_MX: String = "Hispaania (Mehhiko)"
override val display_et: String = "Eesti"
override val display_eu: String = "Baskimaa"
override val display_fa: String = "Pärsia"
override val display_fi: String = "Soome"
override val display_fil: String = "Filipiinide"
override val display_fr: String = "Prantsuse"
override val display_fr_CA: String = "Kanada prantsuse keel"
override val display_gl: String = "Galicia"
override val display_gu: String = "Gujarati"
override val display_he: String = "Heebrea"
override val display_hi: String = "Hindi"
override val display_hr: String = "Horvaatia"
override val display_hu: String = "Ungari"
override val display_hy: String = "Armeenia"
override val display_id: String = "Indoneesia"
override val display_is: String = "Islandi"
override val display_it: String = "Itaalia"
override val display_ja: String = "Jaapani"
override val display_ka: String = "Gruusia"
override val display_kk: String = "Kasahstani"
override val display_km: String = "Kesk-khmeerid"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korea"
override val display_ky: String = "Kirgiisi"
override val display_lo: String = "Lao"
override val display_lt: String = "Leedu"
override val display_lv: String = "Läti"
override val display_mk: String = "Makedoonia"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongoolia"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malai"
override val display_my: String = "Birma"
override val display_nb: String = "Norra keel Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Hollandi"
override val display_or: String = "Oriya"
override val display_pa: String = "Pandžabi"
override val display_pl: String = "Poola"
override val display_pt: String = "Portugali"
override val display_pt_BR: String = "Portugali keel (Brasiilia)"
override val display_pt_PT: String = "Euroopa portugali keel"
override val display_ro: String = "Rumeenia"
override val display_ru: String = "Vene"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovakkia"
override val display_sl: String = "Sloveenia"
override val display_sq: String = "Albaania"
override val display_sr: String = "Serbia"
override val display_sv: String = "Rootsi"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Tai"
override val display_tr: String = "Türgi"
override val display_uk: String = "Ukraina"
override val display_ur: String = "Urdu"
override val display_uz: String = "Usbeki"
override val display_vi: String = "Vietnami"
override val display_zh_Hant_TW: String = "Hiina (Taiwan, Traditsiooniline)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Hinnang"
override val document_invoice: String = "Arve"
override val document_quote: String = "Hinnapakkumine"
override val done: String = "Valmis"
override val download_app: String = "Rakenduse allalaadimine"
override val download_invoice: String = "Laadige Arve alla"
override val download_pdf: String = "Lae alla PDF"
override val draw_signature: String = "Joonistage allkiri"
override val due: Formattable = Formattable { (date) -> "Tähtaeg ${date}" }
override val due_date: String = "Tähtaeg"
override val due_day: String = "päevas"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} tähtaeg on ${dueWhen}!" }
override val due_today: String = "täna"
override val due_week: String = "nädala pärast"
override val duplicate: String = "Duplikaat"
override val duration_day: String = "päev"
override val duration_month: String = "kuu"
override val duration_week: String = "nädal"
override val duration_year: String = "aasta"
override val email: String = "E-post"
override val email_blank_error: String = "Vajalik e-posti aadress."
override val email_error: String = "Vale e-posti aadress."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Täname teid teie äri eest. Täieliku ${documentType} vaatamiseks klõpsake selle kirja lõpus olevale nupule. Pange tähele, et see kehtib kuni ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Olete saanud uue ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "VAATA ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Lugupeetud ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Täname teid teie äri eest. Täieliku ${documentType} vaatamiseks klõpsake selle kirja lõpus olevale nupule. Palun tasuge saldo kuni ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Olete saanud uue dokumendi: ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "E-kiri parooli lähetus, palun kontrollige oma postkasti."
override val empty_clients_list_message: String = "Teil ei ole kliente, keda jälgida. Lisage uus klient."
override val empty_estimate_list_message: String = "Loo oma esimene hinnang"
override val empty_expenses: String = "Teil ei ole kulusid, mida jälgida. Lisage uus kulu."
override val empty_invoice_list_message: String = "Looge oma esimene arve"
override val empty_items_list_message: String = "Teil ei ole esemeid, lisage uus objekt!"
override val empty_signatures_list_message: String = "Allkirju ei leitud. Loo üks!"
override val empty_taxes_list_message: String = "Teil ei ole maksueeskirju. Lisage uus maksureegel."
override val empty_templates_list_message: String = "Mallid ei ole saadaval."
override val enabled: String = "Lubatud"
override val entry: String = "kanne"
override val equity: String = "omakapital"
override val estimate_number: String = "Hinnang #"
override val estimates: String = "Hinnangud"
override val estimates_report: String = "Hinnangud"
override val expense_bank_fees: String = "Pangatasud"
override val expense_benefits: String = "Eelised"
override val expense_communication: String = "Kommunikatsioon"
override val expense_debt: String = "Võlg"
override val expense_deleted: String = "Kulud kustutatud"
override val expense_depreciation: String = "Amortisatsioon"
override val expense_gifts: String = "Kingitused"
override val expense_insurance: String = "Kindlustus"
override val expense_inventory: String = "Inventuur"
override val expense_legal: String = "Juriidiline"
override val expense_maintenance: String = "Hooldus"
override val expense_marketing: String = "Turundus"
override val expense_meals: String = "Toidud"
override val expense_office_supplies: String = "Kontoritarbed"
override val expense_rent: String = "Rent"
override val expense_salaries: String = "Palgad"
override val expense_shopping: String = "Shopping"
override val expense_software: String = "Tarkvara"
override val expense_status_available: String = "Saadaval"
override val expense_status_processing: String = "Töötlemine"
override val expense_tax: String = "Maksud"
override val expense_training: String = "Koolitus"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Reisimine"
override val expense_utility: String = "Kommunaalteenused"
override val expenses: String = "Kulud"
override val feedback_option: String = "Tagasiside"
override val filter: String = "Filter"
override val first_name_hint: String = "Eesnimi"
override val forgot_password: String = "Parooli lähtestamine"
override val forgot_password_label: String = "Unustatud parool"
override val from: String = "Alates"
override val general_error: String = "Tekkis viga, palun proovige hiljem uuesti."
override val general_ledger: String = "pearaamat"
override val go_back_label: String = "Mine tagasi"
override val group_by: String = "Rühm:"
override val image_view: String = "Pilt"
override val install: String = "Installi"
override val invalid_credentials_error: String = "Väärad volitused."
override val invalid_email_error: String = "Invaliidne e-posti aadress."
override val invalid_user_error: String = "Väärad kasutajatunnused."
override val invoice_deleted_message: String = "Arve kustutatud"
override val invoice_menu: String = "Arve menüü"
override val invoice_number: String = "Arve #"
override val invoices: String = "Arved"
override val items_option: String = "Esemed"
override val keep: String = "Hoidke"
override val language_selector: Formattable = Formattable { (selected) -> "Keel ${selected}" }
override val last_name_hint: String = "Perekonnanimi"
override val ledger: String = "raamatupidamisraamat"
override val legal: String = "Juriidiline"
override val legal_error: String = "Peab nõustuma kasutustingimustega ja privaatsuspoliitikaga"
override val liabilities: String = "kohustused"
override val loading: String = "Laadimine"
override val login_required_error: String = "Nõutav sisselogimine."
override val logo_view: String = "Logo"
override val logout: String = "Väljalogimine"
override val logout_prompt: String = "Teie andmeid ei sünkroniseerita pilvega. Kustutada arveid, kalkulatsioone, kliente ja muud teavet väljumisel?"
override val main_screen: String = "Peaekraan"
override val mark_paid: String = "Märgista tasuliseks"
override val message: String = "Sõnum"
override val missing_sender_msg: String = "Ilma saatjaandmeteta dokument võib olla kehtetu."
override val missing_sender_title: String = "Kadunud saatja teave"
override val month_april: String = "Aprill"
override val month_april_short: String = "Aprill"
override val month_august: String = "August"
override val month_august_short: String = "Aug."
override val month_december: String = "Detsember"
override val month_december_short: String = "Dets."
override val month_february: String = "Veebruar"
override val month_february_short: String = "Veeb."
override val month_january: String = "Jaanuar"
override val month_january_short: String = "Jaan."
override val month_july: String = "juuli"
override val month_july_short: String = "Juuli"
override val month_june: String = "juuni"
override val month_june_short: String = "Juuni"
override val month_march: String = "Märts"
override val month_march_short: String = "Märts"
override val month_may: String = "Mai"
override val month_may_short: String = "Mai"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktoober"
override val month_october_short: String = "Okt."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Ei õnnestunud kontrollida."
override val new: String = "Uus"
override val news: String = "Uudised"
override val next: String = "Järgmine"
override val no: String = "ei"
override val no_data_available: String = "Andmed puuduvad"
override val no_search_results: String = "Kahjuks ei leidnud me ühtegi tulemust."
override val none: String = "Puudub"
override val note_fields: String = "Märkused"
override val note_hint: String = "Märkus"
override val notifications_option: String = "Teated"
override val ok: String = "OK"
override val onboarding: String = "Töölevõtmine"
override val onboarding_aboutus_message: String = "DingVoice'i olulised osad on igavesti tasuta. Meie missioon on aidata teil oma äri kasvatada, aidake meid parandada, jagades oma tagasisidet."
override val onboarding_aboutus_title: String = "Me oleme siin, et aidata"
override val onboarding_paid_1: String = "Juurdepääs professionaalsetele mallidele"
override val onboarding_paid_2: String = "Vaadake põhjalikumalt oma finantse"
override val onboarding_paid_3: String = "Probleemide kiire lahendamine"
override val onboarding_paid_4: String = "Hoidke oma dokumente turvaliselt"
override val onboarding_paid_5: String = "Ole kursis arve staatuse muutustega"
override val onboarding_paid_title: String = "Kasvu veelgi kiiremini koos..."
override val onboarding_paywall_message: String = "Naudi kõiki lisafunktsioone nüüd parima hinnaga."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Lihtsalt ${currencySymbol}${introAmount} jaoks ${introDuration} ${introPeriod} ja seejärel ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (valuutaSümbol, summa, duration) -> "Lihtsalt ${valuutaSümbol}${summa} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Tasuta ${trialDuration} ${trialPeriod} ja seejärel lihtsalt ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Tasuta ${trialDuration} ${trialPeriod}, seejärel ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod} eest ja siis ainult ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automaatselt uuendatav. Tühistada igal ajal."
override val onboarding_paywall_restore: String = "Ostu taastamine"
override val onboarding_paywall_restore_failure: String = "Ostude taastamine ebaõnnestus, proovige hiljem uuesti või võtke ühendust klienditoega."
override val onboarding_paywall_restore_success: String = "Taastada edukalt!"
override val onboarding_paywall_title: String = "Saavuta suurus!"
override val onboarding_welcome_message: String = "Täielik tööriistakomplekt arvete ja kalkulatsioonide haldamiseks."
override val onboarding_welcome_title: String = "Tere tulemast DingVoice'ile"
override val other_state: String = "Muud"
override val overdue_state: String = "Hilinenud"
override val paid_state: String = "Tasuline"
override val password: String = "Parool"
override val password_blank_error: String = "Vajalik parool."
override val password_mismatch_error: String = "Paroolid ei vasta."
override val pay: String = "maksma"
override val payment_instructions_label: String = "Maksejuhised"
override val payment_reminders: String = "Maksemärkused"
override val phone_number_fields: String = "Telefoninumber"
override val phone_number_hint: String = "Telefoninumber"
override val postcode_hint: String = "Postiindeks"
override val preview: String = "Eelvaade"
override val preview_menu_hint: String = "Eelvaate menüü"
override val price: String = "Hind"
override val privacy_policy: String = "Privaatsuspoliitika"
override val profile: String = "Profiil"
override val profitable_client: String = "Sinu kõige kasumlikum klient on..."
override val profits_report: String = "Kasum"
override val promo_content: String = "Reklaami sisu"
override val quantity_hint: String = "Kogus"
override val quantity_label_hint: String = "Koguse etikett"
override val query_info: String = "Query võimaldab teil kirjutada kohandatud arvutusi, mis viitavad nii kirjetele kui ka muudele maksueeskirjadele sisenditena. Lisage @ sümbol ja kirje või maksu kirjeldus, et linkida teise kirjega +, -, *, /, % - matemaatilised operaatorid."
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} sai teie klient." }
override val received_state: String = "Saabunud"
override val registration_code_fields: String = "Registreerimiskood"
override val reminder: String = "Meeldetuletus"
override val reports: String = "Aruanded"
override val reports_not_available: String = "Aruanded pole saadaval, tulge hiljem tagasi."
override val reveal_password: String = "Avasta parool"
override val revenue: String = "tulud"
override val sales_tax: String = "KM"
override val sales_tax_number: String = "KMK reg-nr"
override val save: String = "Salvesta"
override val scan_document: String = "Dokumendi skaneerimine"
override val search: String = "Otsi"
override val select: String = "SELECT"
override val select_icon: String = "Valige ikoon"
override val select_signature: String = "Valige allkiri"
override val select_template: String = "Valige mall"
override val send: String = "Saada"
override val sent_state: String = "Saadetud"
override val setup_sender: String = "Seadistage saatja"
override val share_option: String = "Jaga"
override val ship_to: String = "Laevale aadressile"
override val sign_in: String = "Logi sisse"
override val sign_in_apple: String = "Logi sisse koos Apple'iga"
override val sign_in_email: String = "Logi sisse e-posti aadressiga"
override val sign_in_facebook: String = "Logi sisse Facebooki kaudu"
override val sign_in_google: String = "Logi sisse Google'iga"
override val sign_up: String = "Registreeru"
override val sign_up_label: String = "Kas teil ei ole kontot?"
override val sign_up_link_label: String = "Registreeru!"
override val signatures_option: String = "Allkirjad"
override val skip: String = "Jäta  vahele."
override val sold_items_report: String = "Müüdud Esemed"
override val sort_by: String = "Sorteerimine:"
override val status_update: String = "Dokumendi staatuse ajakohastamine"
override val sub_annual: String = "Iga-aastane"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/aasta" }
override val sub_backups: String = "Pilve varukoopiad"
override val sub_insights: String = "Insights"
override val sub_month: String = "Igakuiselt"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/kuu" }
override val sub_notifications: String = "Teated"
override val sub_popup_message: String = "Tellimused on varsti tulemas. Vaadake siia tagasi mõne aja pärast."
override val sub_support: String = "Toetus"
override val sub_templates: String = "Mallid"
override val subscribe: String = "Telli"
override val subscription_header: String = "Kasvata oma äri koos:"
override val subscription_option: String = "Tellimus"
override val subscriptions_load_fail: String = "Vabandame, me ei saa praegu teie tellimusi taastada. Palun proovige hiljem uuesti või võtke abi saamiseks ühendust meie tugimeeskonnaga."
override val subtotal: Formattable = Formattable { (sum) -> "Vahesumma: ${sum}" }
override val subtotal_label: String = "Vahesumma"
override val sum: String = "Sum"
override val support_option: String = "Toetus"
override val switch_reports: String = "Vaheta Aruanne"
override val tax: String = "Maksud"
override val tax_deleted_message: String = "Maksud välja jäetud"
override val tax_description_warning: String = "Kui seda kasutatakse, ilmneb see arvel"
override val tax_input_flat: String = "Tasane"
override val tax_input_percent: String = "Protsent"
override val tax_number_fields: String = "Maksunumber"
override val tax_number_hint: String = "Maksunumber"
override val tax_query_info_description: String = "Päringu info"
override val tax_report: String = "Maksud"
override val tax_rules_option: String = "Maksueeskirjad"
override val taxed_clients_report: String = "Maksustatud kliendid"
override val template: String = "Mall"
override val template_selector_info: String = "Vaikimisi malli muutmiseks nipsake"
override val template_unlock_promo: String = "Juurdepääs rohkematele mallidele Premiumiga"
override val templates_option: String = "Mallid"
override val terms_of_service: String = "Teenuse tingimused"
override val thank_you: String = "Täname"
override val to: String = ""
override val total: Formattable = Formattable { (sum) -> "Kokku: ${sum}" }
override val total_label: String = "Kokku"
override val undo_action: String = "Undo"
override val unit: String = "Üksus"
override val unit_cost_hint: String = "Ühiku maksumus"
override val unknown_client: String = "Tundmatu Klient"
override val unlock: String = "Ava"
override val unnamed_item: String = "Nimeta üksus"
override val upgrade: String = "Uuendamine"
override val user_collision_error: String = "Konto on juba olemas."
override val valid_until: String = "Kehtiv Kuni"
override val warning: String = "Hoiatus!"
override val yes: String = "jah"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Aktsepteeri ${termsOfService} ja ${privacyPolicy}" },
	"account" to "konto",
	"accounting" to "raamatupidamine",
	"accruals" to "tekkepõhine",
	"action_code_error" to "Vale kood.",
	"add_client_action" to "Lisa klient",
	"add_customer" to "Lisa klient",
	"add_expense" to "Lisa kulu",
	"add_fields_label" to "Lisa:",
	"add_invoice_action" to "Loo",
	"add_item_action" to "Lisa element",
	"add_item_button" to "Lisa objekt",
	"add_signature_action" to "Allkirja lisamine",
	"add_tax_action" to "Lisage maksud",
	"address_fields" to "Aadress",
	"address_line_hint" to "Aadressi rida",
	"advanced" to "Täiustatud",
	"agree_marketing" to "Nõustun otseturundussõnumite saamisega",
	"all_notifications" to "Kõik teated",
	"amortization" to "amortisatsioon",
	"amount" to "Summa",
	"asset" to "vara",
	"attach_file" to "Lisa fail",
	"attachment" to "Lisa",
	"auth_error" to "Tegevus ebaõnnestus, proovige uuesti.",
	"available_for_paid" to "Pilvesünkroonimine on saadaval ainult tasulise tellimuse korral.",
	"backups_option" to "Varukoopiaid",
	"balance" to "saldo",
	"bank_account" to "Pangakonto",
	"bill_number" to "Arve number",
	"cancel" to "Tühista",
	"cancel_trial" to "Tühista igal ajal",
	"capital" to "kapitali",
	"cash" to "sularaha",
	"categories" to "Kategooriad",
	"category" to "Kategooria",
	"category_add" to "Kategooria lisamine",
	"category_delete" to "Kustuta kategooria",
	"category_name" to "Kategooria nimi",
	"change_language" to Formattable { (language) -> "Arve keel: ${language}" },
	"change_language_label" to "Arve Keel",
	"change_template" to "Muuta malli",
	"city_hint" to "Linn",
	"clear" to "Selge",
	"client" to "Klient",
	"client_actions" to "Kliendi tegevused",
	"client_deleted_message" to "Klient kustutatud",
	"clients_error_required_fields" to "Vajalik on vähemalt üks väärtus.",
	"clients_option" to "Kliendid",
	"close" to "Sulge",
	"cloud_sync" to "Pilvede sünkroonimine",
	"coming_soon" to "Varsti tulemas!",
	"company_logo" to "Ettevõtte logo",
	"company_name_hint" to "Ettevõte",
	"confirm_password_label" to "Parooli kinnitamine",
	"confirmation_title" to "Kas oled kindel?",
	"convert_to_invoice" to "Teisenda arveks",
	"country_hint" to "Riik",
	"create_client" to "Kliendi loomine",
	"create_company" to "Ettevõtte loomine",
	"create_document" to "Dokumendi loomine",
	"create_estimate" to "Hinnang:",
	"create_invoice" to "Arve",
	"create_item" to "Elemendi loomine",
	"create_new_client" to "Uue kliendi loomine",
	"create_new_item" to "Uue elemendi loomine",
	"create_new_tax" to "Uue maksu loomine",
	"create_signature" to "Allkiri",
	"created" to "Loodud",
	"credit" to "krediit",
	"customer" to "Klient",
	"date" to "Kuupäev",
	"debit" to "deebet",
	"debt" to "võlg",
	"default_currency" to Formattable { (currency) -> "Vaikevaluuta: ${currency}" },
	"default_currency_label" to "Vaikevaluuta",
	"delete" to "Kustuta",
	"delete_account" to "Kustuta Konto",
	"delete_confirmation_message" to "Kõik teie andmed lähevad kaotsi.",
	"delete_item_title" to "Kustutada kirje?",
	"delete_signature" to "Kustuta allkiri",
	"depreciation" to "amortisatsioon",
	"description" to "Kirjeldus",
	"description_hint" to "Kirjeldus",
	"detailed_report" to "Üksikasjalik Aruanne",
	"disabled" to "Puudega",
	"discount" to "Allahindlus",
	"discount_hint" to "Allahindlus",
	"display_af" to "Afrikaans",
	"display_am" to "Amharic",
	"display_ar" to "Araabia keel",
	"display_as" to "Assami",
	"display_az" to "Aserbaidžaan",
	"display_be" to "Valgevene",
	"display_bg" to "Bulgaaria",
	"display_bn" to "Bengali",
	"display_bs" to "Bosnia ja Hertsegoviina",
	"display_ca" to "Katalaani",
	"display_cs" to "Tšehhi",
	"display_da" to "Taani",
	"display_de" to "Saksa",
	"display_el" to "Kreeka",
	"display_en" to "Inglise keeles",
	"display_es" to "Hispaania",
	"display_es_MX" to "Hispaania (Mehhiko)",
	"display_et" to "Eesti",
	"display_eu" to "Baskimaa",
	"display_fa" to "Pärsia",
	"display_fi" to "Soome",
	"display_fil" to "Filipiinide",
	"display_fr" to "Prantsuse",
	"display_fr_CA" to "Kanada prantsuse keel",
	"display_gl" to "Galicia",
	"display_gu" to "Gujarati",
	"display_he" to "Heebrea",
	"display_hi" to "Hindi",
	"display_hr" to "Horvaatia",
	"display_hu" to "Ungari",
	"display_hy" to "Armeenia",
	"display_id" to "Indoneesia",
	"display_is" to "Islandi",
	"display_it" to "Itaalia",
	"display_ja" to "Jaapani",
	"display_ka" to "Gruusia",
	"display_kk" to "Kasahstani",
	"display_km" to "Kesk-khmeerid",
	"display_kn" to "Kannada",
	"display_ko" to "Korea",
	"display_ky" to "Kirgiisi",
	"display_lo" to "Lao",
	"display_lt" to "Leedu",
	"display_lv" to "Läti",
	"display_mk" to "Makedoonia",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongoolia",
	"display_mr" to "Marathi",
	"display_ms" to "Malai",
	"display_my" to "Birma",
	"display_nb" to "Norra keel Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Hollandi",
	"display_or" to "Oriya",
	"display_pa" to "Pandžabi",
	"display_pl" to "Poola",
	"display_pt" to "Portugali",
	"display_pt_BR" to "Portugali keel (Brasiilia)",
	"display_pt_PT" to "Euroopa portugali keel",
	"display_ro" to "Rumeenia",
	"display_ru" to "Vene",
	"display_si" to "Sinhala",
	"display_sk" to "Slovakkia",
	"display_sl" to "Sloveenia",
	"display_sq" to "Albaania",
	"display_sr" to "Serbia",
	"display_sv" to "Rootsi",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Tai",
	"display_tr" to "Türgi",
	"display_uk" to "Ukraina",
	"display_ur" to "Urdu",
	"display_uz" to "Usbeki",
	"display_vi" to "Vietnami",
	"display_zh_Hant_TW" to "Hiina (Taiwan, Traditsiooniline)",
	"display_zu" to "Zulu",
	"document_estimate" to "Hinnang",
	"document_invoice" to "Arve",
	"document_quote" to "Hinnapakkumine",
	"done" to "Valmis",
	"download_app" to "Rakenduse allalaadimine",
	"download_invoice" to "Laadige Arve alla",
	"download_pdf" to "Lae alla PDF",
	"draw_signature" to "Joonistage allkiri",
	"due" to Formattable { (date) -> "Tähtaeg ${date}" },
	"due_date" to "Tähtaeg",
	"due_day" to "päevas",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} tähtaeg on ${dueWhen}!" },
	"due_today" to "täna",
	"due_week" to "nädala pärast",
	"duplicate" to "Duplikaat",
	"duration_day" to "päev",
	"duration_month" to "kuu",
	"duration_week" to "nädal",
	"duration_year" to "aasta",
	"email" to "E-post",
	"email_blank_error" to "Vajalik e-posti aadress.",
	"email_error" to "Vale e-posti aadress.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Täname teid teie äri eest. Täieliku ${documentType} vaatamiseks klõpsake selle kirja lõpus olevale nupule. Pange tähele, et see kehtib kuni ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Olete saanud uue ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "VAATA ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Lugupeetud ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Täname teid teie äri eest. Täieliku ${documentType} vaatamiseks klõpsake selle kirja lõpus olevale nupule. Palun tasuge saldo kuni ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Olete saanud uue dokumendi: ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "E-kiri parooli lähetus, palun kontrollige oma postkasti.",
	"empty_clients_list_message" to "Teil ei ole kliente, keda jälgida. Lisage uus klient.",
	"empty_estimate_list_message" to "Loo oma esimene hinnang",
	"empty_expenses" to "Teil ei ole kulusid, mida jälgida. Lisage uus kulu.",
	"empty_invoice_list_message" to "Looge oma esimene arve",
	"empty_items_list_message" to "Teil ei ole esemeid, lisage uus objekt!",
	"empty_signatures_list_message" to "Allkirju ei leitud. Loo üks!",
	"empty_taxes_list_message" to "Teil ei ole maksueeskirju. Lisage uus maksureegel.",
	"empty_templates_list_message" to "Mallid ei ole saadaval.",
	"enabled" to "Lubatud",
	"entry" to "kanne",
	"equity" to "omakapital",
	"estimate_number" to "Hinnang #",
	"estimates" to "Hinnangud",
	"estimates_report" to "Hinnangud",
	"expense_bank_fees" to "Pangatasud",
	"expense_benefits" to "Eelised",
	"expense_communication" to "Kommunikatsioon",
	"expense_debt" to "Võlg",
	"expense_deleted" to "Kulud kustutatud",
	"expense_depreciation" to "Amortisatsioon",
	"expense_gifts" to "Kingitused",
	"expense_insurance" to "Kindlustus",
	"expense_inventory" to "Inventuur",
	"expense_legal" to "Juriidiline",
	"expense_maintenance" to "Hooldus",
	"expense_marketing" to "Turundus",
	"expense_meals" to "Toidud",
	"expense_office_supplies" to "Kontoritarbed",
	"expense_rent" to "Rent",
	"expense_salaries" to "Palgad",
	"expense_shopping" to "Shopping",
	"expense_software" to "Tarkvara",
	"expense_status_available" to "Saadaval",
	"expense_status_processing" to "Töötlemine",
	"expense_tax" to "Maksud",
	"expense_training" to "Koolitus",
	"expense_transport" to "Transport",
	"expense_travel" to "Reisimine",
	"expense_utility" to "Kommunaalteenused",
	"expenses" to "Kulud",
	"feedback_option" to "Tagasiside",
	"filter" to "Filter",
	"first_name_hint" to "Eesnimi",
	"forgot_password" to "Parooli lähtestamine",
	"forgot_password_label" to "Unustatud parool",
	"from" to "Alates",
	"general_error" to "Tekkis viga, palun proovige hiljem uuesti.",
	"general_ledger" to "pearaamat",
	"go_back_label" to "Mine tagasi",
	"group_by" to "Rühm:",
	"image_view" to "Pilt",
	"install" to "Installi",
	"invalid_credentials_error" to "Väärad volitused.",
	"invalid_email_error" to "Invaliidne e-posti aadress.",
	"invalid_user_error" to "Väärad kasutajatunnused.",
	"invoice_deleted_message" to "Arve kustutatud",
	"invoice_menu" to "Arve menüü",
	"invoice_number" to "Arve #",
	"invoices" to "Arved",
	"items_option" to "Esemed",
	"keep" to "Hoidke",
	"language_selector" to Formattable { (selected) -> "Keel ${selected}" },
	"last_name_hint" to "Perekonnanimi",
	"ledger" to "raamatupidamisraamat",
	"legal" to "Juriidiline",
	"legal_error" to "Peab nõustuma kasutustingimustega ja privaatsuspoliitikaga",
	"liabilities" to "kohustused",
	"loading" to "Laadimine",
	"login_required_error" to "Nõutav sisselogimine.",
	"logo_view" to "Logo",
	"logout" to "Väljalogimine",
	"logout_prompt" to "Teie andmeid ei sünkroniseerita pilvega. Kustutada arveid, kalkulatsioone, kliente ja muud teavet väljumisel?",
	"main_screen" to "Peaekraan",
	"mark_paid" to "Märgista tasuliseks",
	"message" to "Sõnum",
	"missing_sender_msg" to "Ilma saatjaandmeteta dokument võib olla kehtetu.",
	"missing_sender_title" to "Kadunud saatja teave",
	"month_april" to "Aprill",
	"month_april_short" to "Aprill",
	"month_august" to "August",
	"month_august_short" to "Aug.",
	"month_december" to "Detsember",
	"month_december_short" to "Dets.",
	"month_february" to "Veebruar",
	"month_february_short" to "Veeb.",
	"month_january" to "Jaanuar",
	"month_january_short" to "Jaan.",
	"month_july" to "juuli",
	"month_july_short" to "Juuli",
	"month_june" to "juuni",
	"month_june_short" to "Juuni",
	"month_march" to "Märts",
	"month_march_short" to "Märts",
	"month_may" to "Mai",
	"month_may_short" to "Mai",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Oktoober",
	"month_october_short" to "Okt.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Ei õnnestunud kontrollida.",
	"new" to "Uus",
	"news" to "Uudised",
	"next" to "Järgmine",
	"no" to "ei",
	"no_data_available" to "Andmed puuduvad",
	"no_search_results" to "Kahjuks ei leidnud me ühtegi tulemust.",
	"none" to "Puudub",
	"note_fields" to "Märkused",
	"note_hint" to "Märkus",
	"notifications_option" to "Teated",
	"ok" to "OK",
	"onboarding" to "Töölevõtmine",
	"onboarding_aboutus_message" to "DingVoice'i olulised osad on igavesti tasuta. Meie missioon on aidata teil oma äri kasvatada, aidake meid parandada, jagades oma tagasisidet.",
	"onboarding_aboutus_title" to "Me oleme siin, et aidata",
	"onboarding_paid_1" to "Juurdepääs professionaalsetele mallidele",
	"onboarding_paid_2" to "Vaadake põhjalikumalt oma finantse",
	"onboarding_paid_3" to "Probleemide kiire lahendamine",
	"onboarding_paid_4" to "Hoidke oma dokumente turvaliselt",
	"onboarding_paid_5" to "Ole kursis arve staatuse muutustega",
	"onboarding_paid_title" to "Kasvu veelgi kiiremini koos...",
	"onboarding_paywall_message" to "Naudi kõiki lisafunktsioone nüüd parima hinnaga.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Lihtsalt ${currencySymbol}${introAmount} jaoks ${introDuration} ${introPeriod} ja seejärel ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (valuutaSümbol, summa, duration) -> "Lihtsalt ${valuutaSümbol}${summa} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Tasuta ${trialDuration} ${trialPeriod} ja seejärel lihtsalt ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Tasuta ${trialDuration} ${trialPeriod}, seejärel ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod} eest ja siis ainult ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automaatselt uuendatav. Tühistada igal ajal.",
	"onboarding_paywall_restore" to "Ostu taastamine",
	"onboarding_paywall_restore_failure" to "Ostude taastamine ebaõnnestus, proovige hiljem uuesti või võtke ühendust klienditoega.",
	"onboarding_paywall_restore_success" to "Taastada edukalt!",
	"onboarding_paywall_title" to "Saavuta suurus!",
	"onboarding_welcome_message" to "Täielik tööriistakomplekt arvete ja kalkulatsioonide haldamiseks.",
	"onboarding_welcome_title" to "Tere tulemast DingVoice'ile",
	"other_state" to "Muud",
	"overdue_state" to "Hilinenud",
	"paid_state" to "Tasuline",
	"password" to "Parool",
	"password_blank_error" to "Vajalik parool.",
	"password_mismatch_error" to "Paroolid ei vasta.",
	"pay" to "maksma",
	"payment_instructions_label" to "Maksejuhised",
	"payment_reminders" to "Maksemärkused",
	"phone_number_fields" to "Telefoninumber",
	"phone_number_hint" to "Telefoninumber",
	"postcode_hint" to "Postiindeks",
	"preview" to "Eelvaade",
	"preview_menu_hint" to "Eelvaate menüü",
	"price" to "Hind",
	"privacy_policy" to "Privaatsuspoliitika",
	"profile" to "Profiil",
	"profitable_client" to "Sinu kõige kasumlikum klient on...",
	"profits_report" to "Kasum",
	"promo_content" to "Reklaami sisu",
	"quantity_hint" to "Kogus",
	"quantity_label_hint" to "Koguse etikett",
	"query_info" to "Query võimaldab teil kirjutada kohandatud arvutusi, mis viitavad nii kirjetele kui ka muudele maksueeskirjadele sisenditena. Lisage @ sümbol ja kirje või maksu kirjeldus, et linkida teise kirjega +, -, *, /, % - matemaatilised operaatorid.",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} sai teie klient." },
	"received_state" to "Saabunud",
	"registration_code_fields" to "Registreerimiskood",
	"reminder" to "Meeldetuletus",
	"reports" to "Aruanded",
	"reports_not_available" to "Aruanded pole saadaval, tulge hiljem tagasi.",
	"reveal_password" to "Avasta parool",
	"revenue" to "tulud",
	"sales_tax" to "KM",
	"sales_tax_number" to "KMK reg-nr",
	"save" to "Salvesta",
	"scan_document" to "Dokumendi skaneerimine",
	"search" to "Otsi",
	"select" to "SELECT",
	"select_icon" to "Valige ikoon",
	"select_signature" to "Valige allkiri",
	"select_template" to "Valige mall",
	"send" to "Saada",
	"sent_state" to "Saadetud",
	"setup_sender" to "Seadistage saatja",
	"share_option" to "Jaga",
	"ship_to" to "Laevale aadressile",
	"sign_in" to "Logi sisse",
	"sign_in_apple" to "Logi sisse koos Apple'iga",
	"sign_in_email" to "Logi sisse e-posti aadressiga",
	"sign_in_facebook" to "Logi sisse Facebooki kaudu",
	"sign_in_google" to "Logi sisse Google'iga",
	"sign_up" to "Registreeru",
	"sign_up_label" to "Kas teil ei ole kontot?",
	"sign_up_link_label" to "Registreeru!",
	"signatures_option" to "Allkirjad",
	"skip" to "Jäta  vahele.",
	"sold_items_report" to "Müüdud Esemed",
	"sort_by" to "Sorteerimine:",
	"status_update" to "Dokumendi staatuse ajakohastamine",
	"sub_annual" to "Iga-aastane",
	"sub_annual_price" to Formattable { (price) -> "${price}/aasta" },
	"sub_backups" to "Pilve varukoopiad",
	"sub_insights" to "Insights",
	"sub_month" to "Igakuiselt",
	"sub_monthly_price" to Formattable { (price) -> "${price}/kuu" },
	"sub_notifications" to "Teated",
	"sub_popup_message" to "Tellimused on varsti tulemas. Vaadake siia tagasi mõne aja pärast.",
	"sub_support" to "Toetus",
	"sub_templates" to "Mallid",
	"subscribe" to "Telli",
	"subscription_header" to "Kasvata oma äri koos:",
	"subscription_option" to "Tellimus",
	"subscriptions_load_fail" to "Vabandame, me ei saa praegu teie tellimusi taastada. Palun proovige hiljem uuesti või võtke abi saamiseks ühendust meie tugimeeskonnaga.",
	"subtotal" to Formattable { (sum) -> "Vahesumma: ${sum}" },
	"subtotal_label" to "Vahesumma",
	"sum" to "Sum",
	"support_option" to "Toetus",
	"switch_reports" to "Vaheta Aruanne",
	"tax" to "Maksud",
	"tax_deleted_message" to "Maksud välja jäetud",
	"tax_description_warning" to "Kui seda kasutatakse, ilmneb see arvel",
	"tax_input_flat" to "Tasane",
	"tax_input_percent" to "Protsent",
	"tax_number_fields" to "Maksunumber",
	"tax_number_hint" to "Maksunumber",
	"tax_query_info_description" to "Päringu info",
	"tax_report" to "Maksud",
	"tax_rules_option" to "Maksueeskirjad",
	"taxed_clients_report" to "Maksustatud kliendid",
	"template" to "Mall",
	"template_selector_info" to "Vaikimisi malli muutmiseks nipsake",
	"template_unlock_promo" to "Juurdepääs rohkematele mallidele Premiumiga",
	"templates_option" to "Mallid",
	"terms_of_service" to "Teenuse tingimused",
	"thank_you" to "Täname",
	"to" to "",
	"total" to Formattable { (sum) -> "Kokku: ${sum}" },
	"total_label" to "Kokku",
	"undo_action" to "Undo",
	"unit" to "Üksus",
	"unit_cost_hint" to "Ühiku maksumus",
	"unknown_client" to "Tundmatu Klient",
	"unlock" to "Ava",
	"unnamed_item" to "Nimeta üksus",
	"upgrade" to "Uuendamine",
	"user_collision_error" to "Konto on juba olemas.",
	"valid_until" to "Kehtiv Kuni",
	"warning" to "Hoiatus!",
	"yes" to "jah",
    )
    
}