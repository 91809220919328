// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class My  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} နှင့် ${privacyPolicy} ကို လက်ခံပါ" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "မမှန်ကန်သောကုဒ်။"
override val add_client_action: String = "client ကိုထည့်ပါ။"
override val add_customer: String = "ဖောက်သည်ထည့်ပါ။"
override val add_expense: String = "ကုန်ကျစရိတ်ထည့်ပါ။"
override val add_fields_label: String = "ထည့်ရန်-"
override val add_invoice_action: String = "ဖန်တီးပါ။"
override val add_item_action: String = "ပစ္စည်းထည့်ပါ။"
override val add_item_button: String = "ပစ္စည်းထည့်ပါ။"
override val add_signature_action: String = "လက်မှတ်ထည့်ပါ။"
override val add_tax_action: String = "အခွန်ထည့်ပါ။"
override val address_fields: String = "လိပ်စာ"
override val address_line_hint: String = "လိပ်စာလိုင်း"
override val advanced: String = "အဆင့်မြင့်"
override val agree_marketing: String = "ကျွန်ုပ်သည် တိုက်ရိုက်စျေးကွက်ရှာဖွေရေးဆက်သွယ်ရေးကို လက်ခံရန် သဘောတူပါသည်။"
override val all_notifications: String = "အသိပေးချက်အားလုံး"
override val amortization: String = "amortization"
override val amount: String = "ပမာဏ"
override val asset: String = "asset"
override val attach_file: String = "ဖိုင်တွဲ"
override val attachment: String = "သမုဒယ"
override val auth_error: String = "လုပ်ဆောင်ချက် မအောင်မြင်ပါ၊ ထပ်စမ်းကြည့်ပါ။"
override val available_for_paid: String = "Cloud Sync ကို အခပေး စာရင်းသွင်းမှုအောက်တွင်သာ ရနိုင်ပါသည်။"
override val backups_option: String = "အရန်များ"
override val balance: String = "balance"
override val bank_account: String = "ဘဏ်အကောင့်"
override val bill_number: String = "ဘီလ်နံပါတ်"
override val cancel: String = "ပယ်ဖျက်"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "အမျိုးအစားများ"
override val category: String = "အမျိုးအစား"
override val category_add: String = "အမျိုးအစားထည့်ပါ။"
override val category_delete: String = "အမျိုးအစားကို ဖျက်ပါ။"
override val category_name: String = "အမျိုးအစားအမည်"
override val change_language: Formattable = Formattable { (language) -> "ငွေတောင်းခံလွှာ ဘာသာစကား- ${language}" }
override val change_language_label: String = "ပြေစာ ဘာသာစကား"
override val change_template: String = "ပုံစံခွက်ပြောင်းပါ။"
override val city_hint: String = "မြို"
override val clear: String = "ရှင်းလင်းသော"
override val client: String = "ဖောက်သည်"
override val client_actions: String = "ဖောက်သည်လုပ်ဆောင်ချက်များ"
override val client_deleted_message: String = "ဖောက်သည် ဖျက်လိုက်ပြီ။"
override val clients_error_required_fields: String = "အနည်းဆုံးတန်ဖိုးတစ်ခု လိုအပ်ပါသည်။"
override val clients_option: String = "ဟိုဟာ"
override val close: String = "ပိတ်ရန်"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "မကြာမီလာမည်!"
override val company_logo: String = "ကုမ္ပဏီလိုဂို"
override val company_name_hint: String = "ကုမ္ပဏီ"
override val confirm_password_label: String = "စကားဝှက်ကိုအတည်ပြုပါ"
override val confirmation_title: String = "သေချာလား?"
override val convert_to_invoice: String = "ငွေတောင်းခံလွှာသို့ ပြောင်းပါ။"
override val country_hint: String = "နိုင်ငံ"
override val create_client: String = "Client ဖန်တီးပါ။"
override val create_company: String = "ကုမ္ပဏီကိုဖန်တီးပါ။"
override val create_document: String = "စာရွက်စာတမ်းဖန်တီးပါ။"
override val create_estimate: String = "ခန့်မှန်းချက်"
override val create_invoice: String = "ပြေ"
override val create_item: String = "ပစ္စည်းဖန်တီးပါ။"
override val create_new_client: String = "client အသစ်ဖန်တီးပါ။"
override val create_new_item: String = "ပစ္စည်းအသစ်ဖန်တီးပါ။"
override val create_new_tax: String = "အခွန်အသစ်ဖန်တီးပါ။"
override val create_signature: String = "လက်မှတ်"
override val created: String = "ဖန်တီးခဲ့သည်။"
override val credit: String = "credit"
override val customer: String = "ဖောက်သည်"
override val date: String = "ရက်စွဲ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "မူရင်းငွေကြေး- ${currency}" }
override val default_currency_label: String = "မူရင်းငွေကြေး"
override val delete: String = "ဖျက်မည်"
override val delete_account: String = "အကောင့်ကို ဖျက်ပါ"
override val delete_confirmation_message: String = "သင့်ဒေတာအားလုံး ဆုံးရှုံးသွားပါမည်။"
override val delete_item_title: String = "ဖိုင်ကို ဖျက်မလား။"
override val delete_signature: String = "လက်မှတ်ကို ဖျက်ပါ။"
override val depreciation: String = "depreciation"
override val description: String = "ဖော်ပြချက်"
override val description_hint: String = "ဖော်ပြချက်"
override val detailed_report: String = "အသေးစိတ်အစီရင်ခံစာ"
override val disabled: String = "ပိတ်ထားသည်"
override val discount: String = "လျှော့စျေး"
override val discount_hint: String = "လျှော့စျေး"
override val display_af: String = "အာဖရိကန်"
override val display_am: String = "Amharic"
override val display_ar: String = "အာရဗီ"
override val display_as: String = "အာသံ"
override val display_az: String = "အဇာဘိုင်ဂျန်"
override val display_be: String = "ဘီလာရု"
override val display_bg: String = "ဘူဂေးရီးယား"
override val display_bn: String = "ဘင်္ဂါလီ"
override val display_bs: String = "ဘော့စနီးယား"
override val display_ca: String = "ကတ်တလန်"
override val display_cs: String = "ချက်"
override val display_da: String = "ဒိန်းမတ်"
override val display_de: String = "ဂျာမန်"
override val display_el: String = "ဂရိ"
override val display_en: String = "အင်္ဂလိပ်စာ"
override val display_es: String = "ငပိ"
override val display_es_MX: String = "စပိန် (မက္ကဆီကို)"
override val display_et: String = "အက်စ်တိုးနီးယား"
override val display_eu: String = "ဥေ"
override val display_fa: String = "ပါရှန်း"
override val display_fi: String = "ဖင်"
override val display_fil: String = "ဖိလစ်ပိုင်"
override val display_fr: String = "ပြင်သစ်"
override val display_fr_CA: String = "ကနေဒါ ပြင်သစ်"
override val display_gl: String = "စီယံ"
override val display_gu: String = "ဂူဂျာရတ်"
override val display_he: String = "ဟီး"
override val display_hi: String = "မဟုတ်ဘူး"
override val display_hr: String = "ခရိုအေးရှား"
override val display_hu: String = "ဟန်ဂေရီ"
override val display_hy: String = "အာမေးနီးယန်း"
override val display_id: String = "အင်ဒိုနီးရှား"
override val display_is: String = "အိုက်စလန်"
override val display_it: String = "အီတလီ"
override val display_ja: String = "ဂျပန်"
override val display_ka: String = "ဂျော်ဂျီယံ"
override val display_kk: String = "ဇါ"
override val display_km: String = "ခမာအလယ်ပိုင်း"
override val display_kn: String = "ကန်နာဒါ"
override val display_ko: String = "ကိုးရီးယား"
override val display_ky: String = "ခရူဂစ်"
override val display_lo: String = "အလုပ်သမား"
override val display_lt: String = "လစ်သူယေးနီးယား"
override val display_lv: String = "အံ"
override val display_mk: String = "မက်ဆီဒိုးနီးယား"
override val display_ml: String = "မလေး"
override val display_mn: String = "မွန်ဂိုလီးယား"
override val display_mr: String = "မာရသီ"
override val display_ms: String = "မလေး"
override val display_my: String = "မြန်မာ"
override val display_nb: String = "Norwegian Bokmål"
override val display_ne: String = "နီပေါ"
override val display_nl: String = "ဒတ်ခ်ျ"
override val display_or: String = "အိုရီယာ"
override val display_pa: String = "ပန်ချာပီ"
override val display_pl: String = "ပိုလန်"
override val display_pt: String = "ပေါ်တူဂီ"
override val display_pt_BR: String = "ပေါ်တူဂီ (ဘရာဇီး)"
override val display_pt_PT: String = "ဥရောပပေါ်တူဂီ"
override val display_ro: String = "ရိုမေးနီးယား"
override val display_ru: String = "ရုရှ"
override val display_si: String = "ဆင်ဟာလာ"
override val display_sk: String = "လိုဗက်"
override val display_sl: String = "စလိုဗေးနီးယန်း"
override val display_sq: String = "အယ်လ်ဘေးနီးယန်း"
override val display_sr: String = "ဆားဘီးယား"
override val display_sv: String = "ဒိ"
override val display_sw: String = "ဆွာဟီလီ"
override val display_ta: String = "တမီလ်"
override val display_te: String = "အဂု"
override val display_th: String = "ထိုင်း"
override val display_tr: String = "တူရကီ"
override val display_uk: String = "ယူကရိန်း"
override val display_ur: String = "အူရဒူ"
override val display_uz: String = "ဥဇဘက်"
override val display_vi: String = "ဗီယက်နမ်လူမျိုး"
override val display_zh_Hant_TW: String = "တရုတ် (တိုင်ပေ၊ ရိုးရာ)"
override val display_zu: String = "ဇူးလူး"
override val document_estimate: String = "ခန့်မှန်းချက်"
override val document_invoice: String = "ပြေ"
override val document_quote: String = "ကိုးကား"
override val done: String = "ပြီးပြီ"
override val download_app: String = "အက်ပ်ကို ဒေါင်းလုဒ်လုပ်ပါ။"
override val download_invoice: String = "Invoice ကိုဒေါင်းလုဒ်လုပ်ပါ။"
override val download_pdf: String = "PDF ဒေါင်းလုဒ်လုပ်ပါ။"
override val draw_signature: String = "လက်မှတ်ဆွဲပါ။"
override val due: Formattable = Formattable { (date) -> "ပေးရမည့် ${date}" }
override val due_date: String = "နောက်ဆုံးရက်"
override val due_day: String = "တစ်ရက်အတွင်း"
override val due_label: String = "စူးစူးရဲ"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} သည် ${dueWhen} ကုန်ဆုံးမည်ဖြစ်သည်။" }
override val due_today: String = "ယနေ့"
override val due_week: String = "တစ်ပတ်အတွင်း"
override val duplicate: String = "ပွား"
override val duration_day: String = "နေ့"
override val duration_month: String = "လ"
override val duration_week: String = "အပါတ်"
override val duration_year: String = "နှစ်"
override val email: String = "အီးမေးလ်"
override val email_blank_error: String = "အီးမေးလ် လိုအပ်သည်။"
override val email_error: String = "အီးမေးလ်လိပ်စာ မမှန်ကန်ပါ။"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "သင့်လုပ်ငန်းအတွက် ကျေးဇူးတင်ပါသည်။ ${documentType} အပြည့်အစုံကြည့်ရှုရန် ဤစာ၏အဆုံးရှိ ခလုတ်ကို နှိပ်ပါ။ ၎င်းသည် ${dueDate} အထိ တရားဝင်ကြောင်း သတိပြုပါ။" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "သင်အသစ်တစ်ခုလက်ခံရရှိပြီးဖြစ်သည်။${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} ကိုကြည့်ပါ" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "လေးစားအပ်ပါသော ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "သင့်လုပ်ငန်းအတွက် ကျေးဇူးတင်ပါသည်။ ${documentType} အပြည့်အစုံကြည့်ရှုရန် ဤစာ၏အဆုံးရှိ ခလုတ်ကို နှိပ်ပါ။ ကျေးဇူးပြု၍ လက်ကျန်ကို ${dueDate} အထိ ပေးချေပါ။" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "သင်အသစ်တစ်ခုလက်ခံရရှိပြီးဖြစ်သည်။${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "ပေးပို့ထားသော စကားဝှက်ကို ပြန်လည်သတ်မှတ်ရန် အီးမေးလ်၊ သင့်ဝင်စာပုံးကို စစ်ဆေးပါ။"
override val empty_clients_list_message: String = "သင့်တွင် ခြေရာခံရန် ဖောက်သည်များ မရှိပါ။ သုံးစွဲသူအသစ်ကို ထည့်ပါ။"
override val empty_estimate_list_message: String = "သင်၏ပထမဆုံးခန့်မှန်းချက်ကိုဖန်တီးပါ။"
override val empty_expenses: String = "သင်ခြေရာခံရန်ကုန်ကျစရိတ်မရှိပါ။ ကုန်ကျစရိတ်အသစ်ထည့်ပါ။"
override val empty_invoice_list_message: String = "သင်၏ပထမဆုံးငွေတောင်းခံလွှာကိုဖန်တီးပါ။"
override val empty_items_list_message: String = "သင့်တွင် မည်သည့်အရာမှမရှိပါ၊ ပစ္စည်းအသစ်တစ်ခုထည့်ပါ။"
override val empty_signatures_list_message: String = "လက်မှတ်မတွေ့ရပါ။ တစ်ခုဖန်တီးပါ။"
override val empty_taxes_list_message: String = "သင့်တွင် အခွန်စည်းကြပ်မှု မရှိပါ။ အခွန်စည်းမျဥ်းအသစ်ကို ထည့်ပါ။"
override val empty_templates_list_message: String = "နမူနာများ မရရှိနိုင်ပါ။"
override val enabled: String = "ဖွင့်ထားသည်။"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "ခန့်မှန်းချက် #"
override val estimates: String = "ခန့်မှန်းတွက်ချက်မှု"
override val estimates_report: String = "ခန့်မှန်းတွက်ချက်မှု"
override val expense_bank_fees: String = "ဘဏ်အခကြေးငွေ"
override val expense_benefits: String = "အကျိုးကျေးဇူးများ"
override val expense_communication: String = "ဆက်သွယ်ထားခြင်း"
override val expense_debt: String = "အကြွေး"
override val expense_deleted: String = "ကုန်ကျစရိတ်ကို ဖျက်လိုက်ပါပြီ။"
override val expense_depreciation: String = "တန်ဖိုးလျှော့"
override val expense_gifts: String = "လက်ဆောင်များ"
override val expense_insurance: String = "အာမခံ"
override val expense_inventory: String = "စာရင်း"
override val expense_legal: String = "တရားဝင်"
override val expense_maintenance: String = "ထိန်းသိမ်းခြင်း။"
override val expense_marketing: String = "စျေးကွက်ရှာဖွေရေး"
override val expense_meals: String = "အစားအစာများ"
override val expense_office_supplies: String = "ရုံးသုံးပစ္စည်းများ"
override val expense_rent: String = "ငှား"
override val expense_salaries: String = "လစာ"
override val expense_shopping: String = "ဈေးဝယ်ထွက်"
override val expense_software: String = "ဆော့ဝဲ"
override val expense_status_available: String = "ရရှိနိုင်ပါသည်။"
override val expense_status_processing: String = "လုပ်ဆောင်နေသည်"
override val expense_tax: String = "အခွန်"
override val expense_training: String = "လေ့ကျင့်ရေး"
override val expense_transport: String = "သယ်ယူပို့ဆောင်ရေး"
override val expense_travel: String = "ခရီးသွား"
override val expense_utility: String = "ရှိမှာပေါ့။"
override val expenses: String = "အသုံးစရိတ်များ"
override val feedback_option: String = "တုံ့ပြန်ချက်"
override val filter: String = "စစ်ထုတ်သည်"
override val first_name_hint: String = "နာမည်"
override val forgot_password: String = "လျှို့ဝှတ်နံပါတ်အားမူလအတိုင်းပြန်လုပ်သည်"
override val forgot_password_label: String = "စကားဝှက်ကိုမေ့နေပါသလား"
override val from: String = "ထံမှ"
override val general_error: String = "အမှားဖြစ်သွားသည်၊ ကျေးဇူးပြု၍ နောက်မှ ထပ်ကြိုးစားပါ။"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "ပြန်သွားသည်"
override val group_by: String = "အဖွဲ့အားဖြင့်-"
override val image_view: String = "ပုံ"
override val install: String = "ထည့်သွင်းပါ"
override val invalid_credentials_error: String = "မမှန်ကန်သောအထောက်အထားများ။"
override val invalid_email_error: String = "မမှန်ကန်သောအီးမေးလ်။"
override val invalid_user_error: String = "အသုံးပြုသူအထောက်အထားများ မမှန်ကန်ပါ။"
override val invoice_deleted_message: String = "ငွေတောင်းခံလွှာကို ဖျက်လိုက်ပါပြီ။"
override val invoice_menu: String = "ပြေစာမီနူး"
override val invoice_number: String = "ပြေစာ #"
override val invoices: String = "ပြေစာများ"
override val items_option: String = "ပစ္စည်းများ"
override val keep: String = "စောင့်ရှောက်ပါ။"
override val language_selector: Formattable = Formattable { (selected) -> "ဘာသာစကား ${selected}" }
override val last_name_hint: String = "မျိုးနွယ်အမည်"
override val ledger: String = "ledger"
override val legal: String = "တရားဝင်"
override val legal_error: String = "ဝန်ဆောင်မှုစည်းမျဥ်းစည်းကမ်းများနှင့် ကိုယ်ရေးကိုယ်တာမူဝါဒကို လက်ခံရပါမည်။"
override val liabilities: String = "liabilities"
override val loading: String = "တင်နေသည်"
override val login_required_error: String = "အကောင့်ဝင်ရန် လိုအပ်ပါသည်။"
override val logo_view: String = "လိုဂို"
override val logout: String = "ထွက်လိုက်ပါ။"
override val logout_prompt: String = "သင့်ဒေတာကို cloud နှင့် ထပ်တူပြု၍မရပါ။ အကောင့်ထွက်ခြင်းတွင် ငွေတောင်းခံလွှာများ၊ ခန့်မှန်းချက်များ၊ သုံးစွဲသူများနှင့် အခြားအချက်အလက်များကို ဖျက်မလား။"
override val main_screen: String = "ပင်မမျက်နှာပြင်"
override val mark_paid: String = "အခကြေးငွေအဖြစ် အမှတ်အသားပြုပါ။"
override val message: String = "မက်ဆေ့ချ်"
override val missing_sender_msg: String = "ပေးပို့သူအချက်အလက်မပါဘဲ စာရွက်စာတမ်းသည် မမှန်ကန်ပါ။"
override val missing_sender_title: String = "ပေးပို့သူအချက်အလက် ပျောက်ဆုံးနေပါသည်။"
override val month_april: String = "ဧပြီလ"
override val month_april_short: String = "ဧပြီလ"
override val month_august: String = "သြဂုတ်လ"
override val month_august_short: String = "သြဂုတ်လ"
override val month_december: String = "ဒီဇင်ဘာ"
override val month_december_short: String = "ဒီဇင်ဘာ"
override val month_february: String = "ဖေဖော်ဝါရီ"
override val month_february_short: String = "ဖေဖော်ဝါရီ"
override val month_january: String = "ဇန်နဝါရီလ"
override val month_january_short: String = "ဇန်နဝါရီ"
override val month_july: String = "ဇူလိုင်လ"
override val month_july_short: String = "ဇူလိုင်လ"
override val month_june: String = "ဇွန်လ"
override val month_june_short: String = "ဇွန်လ"
override val month_march: String = "မတ်လ"
override val month_march_short: String = "မတ်လ"
override val month_may: String = "မေ"
override val month_may_short: String = "မေ"
override val month_november: String = "နိုဝင်ဘာလ"
override val month_november_short: String = "နိုဝင်ဘာ"
override val month_october: String = "အောက်တိုဘာလ"
override val month_october_short: String = "အောက်တိုဘာ"
override val month_september: String = "စက်တင်ဘာ"
override val month_september_short: String = "စက်တင်ဘာ"
override val multifactor_error: String = "စစ်ဆေးရန် မအောင်မြင်ပါ။"
override val new: String = "အသစ်"
override val news: String = "သတင်း"
override val next: String = "နောက်တစ်ခု"
override val no: String = "no"
override val no_data_available: String = "ဒေတာမရရှိနိုင်ပါ။"
override val no_search_results: String = "ဝမ်းနည်းပါသည်၊ မည်သည့်ရလဒ်မှ ရှာမတွေ့ပါ။"
override val none: String = "မရှိပါ။"
override val note_fields: String = "မှတ်စုများ"
override val note_hint: String = "မှတ်ချက်"
override val notifications_option: String = "အသိပေးချက်များ"
override val ok: String = "အိုကေ"
override val onboarding: String = "စတင်အသုံးပြုခြင်း။"
override val onboarding_aboutus_message: String = "DingVoice ၏ မရှိမဖြစ် အစိတ်အပိုင်းများသည် ထာဝရအခမဲ့ဖြစ်သည်။ ကျွန်ုပ်တို့၏တာဝန်မှာ သင့်လုပ်ငန်းကို ကြီးထွားလာစေရန်နှင့် သင့်အကြံပြုချက်ကို မျှဝေခြင်းဖြင့် ကျွန်ုပ်တို့ကို တိုးတက်အောင် ကူညီပေးရန်ဖြစ်သည်။"
override val onboarding_aboutus_title: String = "ကူညီရန် ကျွန်ုပ်တို့ ဤနေရာတွင် ရှိနေပါသည်။"
override val onboarding_paid_1: String = "ပရော်ဖက်ရှင်နယ်ပုံစံ နမူနာများကို ဝင်ရောက်ကြည့်ရှုပါ။"
override val onboarding_paid_2: String = "သင်၏ဘဏ္ဍာရေးပိုင်းကို လေးနက်စွာ စူးစမ်းပါ။"
override val onboarding_paid_3: String = "ပြဿနာများကို အမြန်ဖြေရှင်းပါ။"
override val onboarding_paid_4: String = "သင်၏စာရွက်စာတမ်းများကိုလုံခြုံအောင်ထားပါ။"
override val onboarding_paid_5: String = "ငွေတောင်းခံလွှာအခြေအနေ အပြောင်းအလဲများဖြင့် နောက်ဆုံးပေါ်နေပါ။"
override val onboarding_paid_title: String = "ပိုမိုမြန်ဆန်စွာ ကြီးထွားလာပါစေ..."
override val onboarding_paywall_message: String = "ပရီမီယံဝန်ဆောင်မှုအားလုံးကို အကောင်းဆုံးစျေးနှုန်းဖြင့် ယခုပဲ ခံစားလိုက်ပါ။"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} အတွက် ${currencySymbol}${introAmount} နှင့် ထို့နောက် ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} / ${duration} မျှသာ" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} နှင့် ${currencySymbol}${amount} / ${duration} ဖြင့် အခမဲ့" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "အခမဲ့${trialDuration}${trialPeriod} ထို့နောက်${currencySymbol}${introductoryAmount} ဘို့${introductoryDuration}${introPeriod} ပြီးမှပဲ${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "အလိုအလျောက် သက်တမ်းတိုးနိုင်သည်။ အချိန်မရွေး ပယ်ဖျက်ပါ။"
override val onboarding_paywall_restore: String = "ဝယ်ယူမှုကို ပြန်လည်ရယူပါ။"
override val onboarding_paywall_restore_failure: String = "ဝယ်ယူမှုများကို ပြန်လည်ရယူခြင်း မအောင်မြင်ပါ၊ နောက်မှ ထပ်စမ်းကြည့်ပါ သို့မဟုတ် အကူအညီကို ဆက်သွယ်ပါ။"
override val onboarding_paywall_restore_success: String = "ပြန်လည်ရယူခြင်း အောင်မြင်ပါသည်။"
override val onboarding_paywall_title: String = "ကြီးမြတ်မှုကိုရယူပါ။"
override val onboarding_welcome_message: String = "သင့်ငွေတောင်းခံလွှာများနှင့် ခန့်မှန်းချက်များကို စီမံခန့်ခွဲရန် ပြီးပြည့်စုံသော ကိရိယာအစုံ။"
override val onboarding_welcome_title: String = "DingVoice မှကြိုဆိုပါတယ်။"
override val other_state: String = "အခြား"
override val overdue_state: String = "ရက်လွန်ပြီ။"
override val paid_state: String = "အခကြေးငွေ"
override val password: String = "စကားဝှက်"
override val password_blank_error: String = "စကားဝှက် လိုအပ်သည်။"
override val password_mismatch_error: String = "စကားဝှက်များ မတိုက်ဆိုင်ပါ။"
override val pay: String = "pay"
override val payment_instructions_label: String = "ငွေပေးချေမှုညွှန်ကြားချက်များ"
override val payment_reminders: String = "ငွေပေးချေမှု သတိပေးချက်များ"
override val phone_number_fields: String = "ဖုန်းနံပါတ်"
override val phone_number_hint: String = "ဖုန်းနံပါတ်"
override val postcode_hint: String = "စာပို့ကုဒ်"
override val preview: String = "စမ်းကြည့်ပါ။"
override val preview_menu_hint: String = "မီနူးကို အစမ်းကြည့်ပါ။"
override val price: String = "စျေးနှုန်း"
override val privacy_policy: String = "ကိုယ်ရေးလုံခြုံမှု မူဝါဒ"
override val profile: String = "ကိုယ်ရေးအကျဉ်း"
override val profitable_client: String = "မင်းရဲ့အမြတ်အစွန်းအများဆုံး ဖောက်သည်က..."
override val profits_report: String = "အမြတ်အစွန်း"
override val promo_content: String = "ပရိုမိုးရှင်းအကြောင်းအရာ"
override val quantity_hint: String = "အရေအတွက်"
override val quantity_label_hint: String = "အရေအတွက် အညွှန်း"
override val query_info: String = "Query သည် သွင်းအားစုများအဖြစ် ပစ္စည်းများနှင့် အခြားအခွန်စည်းမျဥ်းနှစ်ခုလုံးကို ရည်ညွှန်းသည့် စိတ်ကြိုက်တွက်ချက်မှုများကို ရေးသားနိုင်စေပါသည်။ အခြားထည့်သွင်းမှု +, -, *, /, % - သင်္ချာအော်ပရေတာများသို့ လင့်ခ်ချိတ်ရန် @ သင်္ကေတနှင့် ပစ္စည်း သို့မဟုတ် အခွန်ဖော်ပြချက်ကို ထည့်ပါ။"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ကို သင့်ဖောက်သည်မှ လက်ခံရရှိခဲ့သည်။" }
override val received_state: String = "ရရှိခဲ့သည်။"
override val registration_code_fields: String = "မှတ်ပုံတင်ကုဒ်"
override val reminder: String = "သတိပေးခြင်း"
override val reports: String = "အစီရင်ခံစာများ"
override val reports_not_available: String = "အစီရင်ခံစာမရရှိနိုင်ပါ၊ နောက်မှပြန်လာပါ။"
override val reveal_password: String = "စကားဝှက်ကိုထုတ်ပြပါ။"
override val revenue: String = "revenue"
override val sales_tax: String = "VAT"
override val sales_tax_number: String = "VAT နံပါတ်"
override val save: String = "သိမ်းဆည်း"
override val scan_document: String = "စာရွက်စာတမ်းကို စကင်ဖတ်ပါ။"
override val search: String = "ရှာဖွေပါ"
override val select: String = "ရွေးချယ်ပါ။"
override val select_icon: String = "အိုင်ကွန်ကို ရွေးပါ။"
override val select_signature: String = "လက်မှတ်ကို ရွေးပါ။"
override val select_template: String = "နမူနာပုံစံကို ရွေးပါ။"
override val send: String = "ပို့ပါ။"
override val sent_state: String = "ပို့လိုက်ပါတယ်။"
override val setup_sender: String = "ပေးပို့သူကို သတ်မှတ်ပါ။"
override val share_option: String = "မျှဝေပါ"
override val ship_to: String = "သို့တင်ပို့သည်"
override val sign_in: String = "လက်မှတ်ထိုး ဝင်ရန်"
override val sign_in_apple: String = "Apple ဖြင့် အကောင့်ဝင်ပါ။"
override val sign_in_email: String = "အီးမေးလ်ဖြင့် ဝင်ရောက်ပါ။"
override val sign_in_facebook: String = "Facebook ဖြင့် ဝင်ရောက်ပါ။"
override val sign_in_google: String = "Google ဖြင့် အကောင့်ဝင်ပါ။"
override val sign_up: String = "စာရင်းသွင်း"
override val sign_up_label: String = "အကောင့်မရှိဘူးလား။"
override val sign_up_link_label: String = "ဆိုင်းအပ်!"
override val signatures_option: String = "လက်မှတ်များ"
override val skip: String = "ကျော်သွား"
override val sold_items_report: String = "ရောင်းချပြီးသောပစ္စည်းများ"
override val sort_by: String = "အလိုက်စဥ်သည်:"
override val status_update: String = "စာရွက်စာတမ်း အခြေအနေ အပ်ဒိတ်"
override val sub_annual: String = "နှစ်စဉ်"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/နှစ်" }
override val sub_backups: String = "Cloud Backups"
override val sub_insights: String = "ထိုးထွင်းဥာဏ်"
override val sub_month: String = "လစဉ်"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/လ" }
override val sub_notifications: String = "အသိပေးချက်များ"
override val sub_popup_message: String = "စာရင်းသွင်းမှုများသည် မကြာမီလာပါမည်။ ခဏနေ ဒီမှာ ပြန်ကြည့်ပါ။"
override val sub_support: String = "အထောက်အပံ့"
override val sub_templates: String = "ပုံစံများ"
override val subscribe: String = "စာရင်းသွင်းပါ"
override val subscription_header: String = "သင့်လုပ်ငန်းကို ကြီးထွားစေသည်-"
override val subscription_option: String = "စာရင်းသွင်းခြင်း။"
override val subscriptions_load_fail: String = "ဝမ်းနည်းပါသည်၊ သင့်စာရင်းသွင်းမှုများကို ယခုအချိန်တွင် ပြန်လည်ရယူ၍မရပါ။ ကျေးဇူးပြု၍ နောက်မှ ထပ်စမ်းကြည့်ပါ သို့မဟုတ် အကူအညီအတွက် ကျွန်ုပ်တို့၏ ပံ့ပိုးကူညီမှုအဖွဲ့ထံ ဆက်သွယ်ပါ။"
override val subtotal: Formattable = Formattable { (sum) -> "စုစုပေါင်း- ${sum}" }
override val subtotal_label: String = "စုစုပေါင်း"
override val sum: String = "မိုးဆမ်း"
override val support_option: String = "အထောက်အပံ့"
override val switch_reports: String = "အစီရင်ခံစာပြောင်းပါ။"
override val tax: String = "အခွန်"
override val tax_deleted_message: String = "အခွန်ဖျက်လိုက်ပြီ။"
override val tax_description_warning: String = "အသုံးပြုပါက ပြေစာတွင် ပေါ်လာပါသည်။"
override val tax_input_flat: String = "တိုက်ခန်း"
override val tax_input_percent: String = "ရာခိုင်နှုန်း"
override val tax_number_fields: String = "အခွန်ကုဒ်နံပါတ်"
override val tax_number_hint: String = "အခွန်ကုဒ်နံပါတ်"
override val tax_query_info_description: String = "မေးမြန်းမှု အချက်အလက်"
override val tax_report: String = "အခွန်"
override val tax_rules_option: String = "အခွန်စည်းကမ်းများ"
override val taxed_clients_report: String = "အခွန်ကောက်သော ဖောက်သည်များ"
override val template: String = "ပုံစံခွက်"
override val template_selector_info: String = "မူရင်းပုံစံကို ပြောင်းရန် ပွတ်ဆွဲပါ။"
override val template_unlock_promo: String = "Premium ဖြင့် နောက်ထပ် နမူနာများကို အသုံးပြုပါ။"
override val templates_option: String = "ပုံစံများ"
override val terms_of_service: String = "ဝန်ဆောင်မှုစည်းမျဉ်းများ"
override val thank_you: String = "ကျေးဇူးပါ"
override val to: String = "ရန်"
override val total: Formattable = Formattable { (sum) -> "စုစုပေါင်း- ${sum}" }
override val total_label: String = "စုစုပေါင်း"
override val undo_action: String = "ပယ်ဖျက်ပါ"
override val unit: String = "ယူနစ်"
override val unit_cost_hint: String = "ယူနစ်ကုန်ကျစရိတ်"
override val unknown_client: String = "အမည်မသိ သုံးစွဲသူ"
override val unlock: String = "သော့ခတ်"
override val unnamed_item: String = "အမည်မသိ ပစ္စည်း"
override val upgrade: String = "အဆင့်မြှင့်ပါ။"
override val user_collision_error: String = "အကောင့်ရှိပြီးသားပါ။"
override val valid_until: String = "သည်အထိ အကျုံးဝင်သည်။"
override val warning: String = "သတိပေးချက်"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} နှင့် ${privacyPolicy} ကို လက်ခံပါ" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "မမှန်ကန်သောကုဒ်။",
	"add_client_action" to "client ကိုထည့်ပါ။",
	"add_customer" to "ဖောက်သည်ထည့်ပါ။",
	"add_expense" to "ကုန်ကျစရိတ်ထည့်ပါ။",
	"add_fields_label" to "ထည့်ရန်-",
	"add_invoice_action" to "ဖန်တီးပါ။",
	"add_item_action" to "ပစ္စည်းထည့်ပါ။",
	"add_item_button" to "ပစ္စည်းထည့်ပါ။",
	"add_signature_action" to "လက်မှတ်ထည့်ပါ။",
	"add_tax_action" to "အခွန်ထည့်ပါ။",
	"address_fields" to "လိပ်စာ",
	"address_line_hint" to "လိပ်စာလိုင်း",
	"advanced" to "အဆင့်မြင့်",
	"agree_marketing" to "ကျွန်ုပ်သည် တိုက်ရိုက်စျေးကွက်ရှာဖွေရေးဆက်သွယ်ရေးကို လက်ခံရန် သဘောတူပါသည်။",
	"all_notifications" to "အသိပေးချက်အားလုံး",
	"amortization" to "amortization",
	"amount" to "ပမာဏ",
	"asset" to "asset",
	"attach_file" to "ဖိုင်တွဲ",
	"attachment" to "သမုဒယ",
	"auth_error" to "လုပ်ဆောင်ချက် မအောင်မြင်ပါ၊ ထပ်စမ်းကြည့်ပါ။",
	"available_for_paid" to "Cloud Sync ကို အခပေး စာရင်းသွင်းမှုအောက်တွင်သာ ရနိုင်ပါသည်။",
	"backups_option" to "အရန်များ",
	"balance" to "balance",
	"bank_account" to "ဘဏ်အကောင့်",
	"bill_number" to "ဘီလ်နံပါတ်",
	"cancel" to "ပယ်ဖျက်",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "အမျိုးအစားများ",
	"category" to "အမျိုးအစား",
	"category_add" to "အမျိုးအစားထည့်ပါ။",
	"category_delete" to "အမျိုးအစားကို ဖျက်ပါ။",
	"category_name" to "အမျိုးအစားအမည်",
	"change_language" to Formattable { (language) -> "ငွေတောင်းခံလွှာ ဘာသာစကား- ${language}" },
	"change_language_label" to "ပြေစာ ဘာသာစကား",
	"change_template" to "ပုံစံခွက်ပြောင်းပါ။",
	"city_hint" to "မြို",
	"clear" to "ရှင်းလင်းသော",
	"client" to "ဖောက်သည်",
	"client_actions" to "ဖောက်သည်လုပ်ဆောင်ချက်များ",
	"client_deleted_message" to "ဖောက်သည် ဖျက်လိုက်ပြီ။",
	"clients_error_required_fields" to "အနည်းဆုံးတန်ဖိုးတစ်ခု လိုအပ်ပါသည်။",
	"clients_option" to "ဟိုဟာ",
	"close" to "ပိတ်ရန်",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "မကြာမီလာမည်!",
	"company_logo" to "ကုမ္ပဏီလိုဂို",
	"company_name_hint" to "ကုမ္ပဏီ",
	"confirm_password_label" to "စကားဝှက်ကိုအတည်ပြုပါ",
	"confirmation_title" to "သေချာလား?",
	"convert_to_invoice" to "ငွေတောင်းခံလွှာသို့ ပြောင်းပါ။",
	"country_hint" to "နိုင်ငံ",
	"create_client" to "Client ဖန်တီးပါ။",
	"create_company" to "ကုမ္ပဏီကိုဖန်တီးပါ။",
	"create_document" to "စာရွက်စာတမ်းဖန်တီးပါ။",
	"create_estimate" to "ခန့်မှန်းချက်",
	"create_invoice" to "ပြေ",
	"create_item" to "ပစ္စည်းဖန်တီးပါ။",
	"create_new_client" to "client အသစ်ဖန်တီးပါ။",
	"create_new_item" to "ပစ္စည်းအသစ်ဖန်တီးပါ။",
	"create_new_tax" to "အခွန်အသစ်ဖန်တီးပါ။",
	"create_signature" to "လက်မှတ်",
	"created" to "ဖန်တီးခဲ့သည်။",
	"credit" to "credit",
	"customer" to "ဖောက်သည်",
	"date" to "ရက်စွဲ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "မူရင်းငွေကြေး- ${currency}" },
	"default_currency_label" to "မူရင်းငွေကြေး",
	"delete" to "ဖျက်မည်",
	"delete_account" to "အကောင့်ကို ဖျက်ပါ",
	"delete_confirmation_message" to "သင့်ဒေတာအားလုံး ဆုံးရှုံးသွားပါမည်။",
	"delete_item_title" to "ဖိုင်ကို ဖျက်မလား။",
	"delete_signature" to "လက်မှတ်ကို ဖျက်ပါ။",
	"depreciation" to "depreciation",
	"description" to "ဖော်ပြချက်",
	"description_hint" to "ဖော်ပြချက်",
	"detailed_report" to "အသေးစိတ်အစီရင်ခံစာ",
	"disabled" to "ပိတ်ထားသည်",
	"discount" to "လျှော့စျေး",
	"discount_hint" to "လျှော့စျေး",
	"display_af" to "အာဖရိကန်",
	"display_am" to "Amharic",
	"display_ar" to "အာရဗီ",
	"display_as" to "အာသံ",
	"display_az" to "အဇာဘိုင်ဂျန်",
	"display_be" to "ဘီလာရု",
	"display_bg" to "ဘူဂေးရီးယား",
	"display_bn" to "ဘင်္ဂါလီ",
	"display_bs" to "ဘော့စနီးယား",
	"display_ca" to "ကတ်တလန်",
	"display_cs" to "ချက်",
	"display_da" to "ဒိန်းမတ်",
	"display_de" to "ဂျာမန်",
	"display_el" to "ဂရိ",
	"display_en" to "အင်္ဂလိပ်စာ",
	"display_es" to "ငပိ",
	"display_es_MX" to "စပိန် (မက္ကဆီကို)",
	"display_et" to "အက်စ်တိုးနီးယား",
	"display_eu" to "ဥေ",
	"display_fa" to "ပါရှန်း",
	"display_fi" to "ဖင်",
	"display_fil" to "ဖိလစ်ပိုင်",
	"display_fr" to "ပြင်သစ်",
	"display_fr_CA" to "ကနေဒါ ပြင်သစ်",
	"display_gl" to "စီယံ",
	"display_gu" to "ဂူဂျာရတ်",
	"display_he" to "ဟီး",
	"display_hi" to "မဟုတ်ဘူး",
	"display_hr" to "ခရိုအေးရှား",
	"display_hu" to "ဟန်ဂေရီ",
	"display_hy" to "အာမေးနီးယန်း",
	"display_id" to "အင်ဒိုနီးရှား",
	"display_is" to "အိုက်စလန်",
	"display_it" to "အီတလီ",
	"display_ja" to "ဂျပန်",
	"display_ka" to "ဂျော်ဂျီယံ",
	"display_kk" to "ဇါ",
	"display_km" to "ခမာအလယ်ပိုင်း",
	"display_kn" to "ကန်နာဒါ",
	"display_ko" to "ကိုးရီးယား",
	"display_ky" to "ခရူဂစ်",
	"display_lo" to "အလုပ်သမား",
	"display_lt" to "လစ်သူယေးနီးယား",
	"display_lv" to "အံ",
	"display_mk" to "မက်ဆီဒိုးနီးယား",
	"display_ml" to "မလေး",
	"display_mn" to "မွန်ဂိုလီးယား",
	"display_mr" to "မာရသီ",
	"display_ms" to "မလေး",
	"display_my" to "မြန်မာ",
	"display_nb" to "Norwegian Bokmål",
	"display_ne" to "နီပေါ",
	"display_nl" to "ဒတ်ခ်ျ",
	"display_or" to "အိုရီယာ",
	"display_pa" to "ပန်ချာပီ",
	"display_pl" to "ပိုလန်",
	"display_pt" to "ပေါ်တူဂီ",
	"display_pt_BR" to "ပေါ်တူဂီ (ဘရာဇီး)",
	"display_pt_PT" to "ဥရောပပေါ်တူဂီ",
	"display_ro" to "ရိုမေးနီးယား",
	"display_ru" to "ရုရှ",
	"display_si" to "ဆင်ဟာလာ",
	"display_sk" to "လိုဗက်",
	"display_sl" to "စလိုဗေးနီးယန်း",
	"display_sq" to "အယ်လ်ဘေးနီးယန်း",
	"display_sr" to "ဆားဘီးယား",
	"display_sv" to "ဒိ",
	"display_sw" to "ဆွာဟီလီ",
	"display_ta" to "တမီလ်",
	"display_te" to "အဂု",
	"display_th" to "ထိုင်း",
	"display_tr" to "တူရကီ",
	"display_uk" to "ယူကရိန်း",
	"display_ur" to "အူရဒူ",
	"display_uz" to "ဥဇဘက်",
	"display_vi" to "ဗီယက်နမ်လူမျိုး",
	"display_zh_Hant_TW" to "တရုတ် (တိုင်ပေ၊ ရိုးရာ)",
	"display_zu" to "ဇူးလူး",
	"document_estimate" to "ခန့်မှန်းချက်",
	"document_invoice" to "ပြေ",
	"document_quote" to "ကိုးကား",
	"done" to "ပြီးပြီ",
	"download_app" to "အက်ပ်ကို ဒေါင်းလုဒ်လုပ်ပါ။",
	"download_invoice" to "Invoice ကိုဒေါင်းလုဒ်လုပ်ပါ။",
	"download_pdf" to "PDF ဒေါင်းလုဒ်လုပ်ပါ။",
	"draw_signature" to "လက်မှတ်ဆွဲပါ။",
	"due" to Formattable { (date) -> "ပေးရမည့် ${date}" },
	"due_date" to "နောက်ဆုံးရက်",
	"due_day" to "တစ်ရက်အတွင်း",
	"due_label" to "စူးစူးရဲ",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} သည် ${dueWhen} ကုန်ဆုံးမည်ဖြစ်သည်။" },
	"due_today" to "ယနေ့",
	"due_week" to "တစ်ပတ်အတွင်း",
	"duplicate" to "ပွား",
	"duration_day" to "နေ့",
	"duration_month" to "လ",
	"duration_week" to "အပါတ်",
	"duration_year" to "နှစ်",
	"email" to "အီးမေးလ်",
	"email_blank_error" to "အီးမေးလ် လိုအပ်သည်။",
	"email_error" to "အီးမေးလ်လိပ်စာ မမှန်ကန်ပါ။",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "သင့်လုပ်ငန်းအတွက် ကျေးဇူးတင်ပါသည်။ ${documentType} အပြည့်အစုံကြည့်ရှုရန် ဤစာ၏အဆုံးရှိ ခလုတ်ကို နှိပ်ပါ။ ၎င်းသည် ${dueDate} အထိ တရားဝင်ကြောင်း သတိပြုပါ။" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "သင်အသစ်တစ်ခုလက်ခံရရှိပြီးဖြစ်သည်။${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} ကိုကြည့်ပါ" },
	"email_invoice_greeting" to Formattable { (clientName) -> "လေးစားအပ်ပါသော ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "သင့်လုပ်ငန်းအတွက် ကျေးဇူးတင်ပါသည်။ ${documentType} အပြည့်အစုံကြည့်ရှုရန် ဤစာ၏အဆုံးရှိ ခလုတ်ကို နှိပ်ပါ။ ကျေးဇူးပြု၍ လက်ကျန်ကို ${dueDate} အထိ ပေးချေပါ။" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "သင်အသစ်တစ်ခုလက်ခံရရှိပြီးဖြစ်သည်။${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "ပေးပို့ထားသော စကားဝှက်ကို ပြန်လည်သတ်မှတ်ရန် အီးမေးလ်၊ သင့်ဝင်စာပုံးကို စစ်ဆေးပါ။",
	"empty_clients_list_message" to "သင့်တွင် ခြေရာခံရန် ဖောက်သည်များ မရှိပါ။ သုံးစွဲသူအသစ်ကို ထည့်ပါ။",
	"empty_estimate_list_message" to "သင်၏ပထမဆုံးခန့်မှန်းချက်ကိုဖန်တီးပါ။",
	"empty_expenses" to "သင်ခြေရာခံရန်ကုန်ကျစရိတ်မရှိပါ။ ကုန်ကျစရိတ်အသစ်ထည့်ပါ။",
	"empty_invoice_list_message" to "သင်၏ပထမဆုံးငွေတောင်းခံလွှာကိုဖန်တီးပါ။",
	"empty_items_list_message" to "သင့်တွင် မည်သည့်အရာမှမရှိပါ၊ ပစ္စည်းအသစ်တစ်ခုထည့်ပါ။",
	"empty_signatures_list_message" to "လက်မှတ်မတွေ့ရပါ။ တစ်ခုဖန်တီးပါ။",
	"empty_taxes_list_message" to "သင့်တွင် အခွန်စည်းကြပ်မှု မရှိပါ။ အခွန်စည်းမျဥ်းအသစ်ကို ထည့်ပါ။",
	"empty_templates_list_message" to "နမူနာများ မရရှိနိုင်ပါ။",
	"enabled" to "ဖွင့်ထားသည်။",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "ခန့်မှန်းချက် #",
	"estimates" to "ခန့်မှန်းတွက်ချက်မှု",
	"estimates_report" to "ခန့်မှန်းတွက်ချက်မှု",
	"expense_bank_fees" to "ဘဏ်အခကြေးငွေ",
	"expense_benefits" to "အကျိုးကျေးဇူးများ",
	"expense_communication" to "ဆက်သွယ်ထားခြင်း",
	"expense_debt" to "အကြွေး",
	"expense_deleted" to "ကုန်ကျစရိတ်ကို ဖျက်လိုက်ပါပြီ။",
	"expense_depreciation" to "တန်ဖိုးလျှော့",
	"expense_gifts" to "လက်ဆောင်များ",
	"expense_insurance" to "အာမခံ",
	"expense_inventory" to "စာရင်း",
	"expense_legal" to "တရားဝင်",
	"expense_maintenance" to "ထိန်းသိမ်းခြင်း။",
	"expense_marketing" to "စျေးကွက်ရှာဖွေရေး",
	"expense_meals" to "အစားအစာများ",
	"expense_office_supplies" to "ရုံးသုံးပစ္စည်းများ",
	"expense_rent" to "ငှား",
	"expense_salaries" to "လစာ",
	"expense_shopping" to "ဈေးဝယ်ထွက်",
	"expense_software" to "ဆော့ဝဲ",
	"expense_status_available" to "ရရှိနိုင်ပါသည်။",
	"expense_status_processing" to "လုပ်ဆောင်နေသည်",
	"expense_tax" to "အခွန်",
	"expense_training" to "လေ့ကျင့်ရေး",
	"expense_transport" to "သယ်ယူပို့ဆောင်ရေး",
	"expense_travel" to "ခရီးသွား",
	"expense_utility" to "ရှိမှာပေါ့။",
	"expenses" to "အသုံးစရိတ်များ",
	"feedback_option" to "တုံ့ပြန်ချက်",
	"filter" to "စစ်ထုတ်သည်",
	"first_name_hint" to "နာမည်",
	"forgot_password" to "လျှို့ဝှတ်နံပါတ်အားမူလအတိုင်းပြန်လုပ်သည်",
	"forgot_password_label" to "စကားဝှက်ကိုမေ့နေပါသလား",
	"from" to "ထံမှ",
	"general_error" to "အမှားဖြစ်သွားသည်၊ ကျေးဇူးပြု၍ နောက်မှ ထပ်ကြိုးစားပါ။",
	"general_ledger" to "general ledger",
	"go_back_label" to "ပြန်သွားသည်",
	"group_by" to "အဖွဲ့အားဖြင့်-",
	"image_view" to "ပုံ",
	"install" to "ထည့်သွင်းပါ",
	"invalid_credentials_error" to "မမှန်ကန်သောအထောက်အထားများ။",
	"invalid_email_error" to "မမှန်ကန်သောအီးမေးလ်။",
	"invalid_user_error" to "အသုံးပြုသူအထောက်အထားများ မမှန်ကန်ပါ။",
	"invoice_deleted_message" to "ငွေတောင်းခံလွှာကို ဖျက်လိုက်ပါပြီ။",
	"invoice_menu" to "ပြေစာမီနူး",
	"invoice_number" to "ပြေစာ #",
	"invoices" to "ပြေစာများ",
	"items_option" to "ပစ္စည်းများ",
	"keep" to "စောင့်ရှောက်ပါ။",
	"language_selector" to Formattable { (selected) -> "ဘာသာစကား ${selected}" },
	"last_name_hint" to "မျိုးနွယ်အမည်",
	"ledger" to "ledger",
	"legal" to "တရားဝင်",
	"legal_error" to "ဝန်ဆောင်မှုစည်းမျဥ်းစည်းကမ်းများနှင့် ကိုယ်ရေးကိုယ်တာမူဝါဒကို လက်ခံရပါမည်။",
	"liabilities" to "liabilities",
	"loading" to "တင်နေသည်",
	"login_required_error" to "အကောင့်ဝင်ရန် လိုအပ်ပါသည်။",
	"logo_view" to "လိုဂို",
	"logout" to "ထွက်လိုက်ပါ။",
	"logout_prompt" to "သင့်ဒေတာကို cloud နှင့် ထပ်တူပြု၍မရပါ။ အကောင့်ထွက်ခြင်းတွင် ငွေတောင်းခံလွှာများ၊ ခန့်မှန်းချက်များ၊ သုံးစွဲသူများနှင့် အခြားအချက်အလက်များကို ဖျက်မလား။",
	"main_screen" to "ပင်မမျက်နှာပြင်",
	"mark_paid" to "အခကြေးငွေအဖြစ် အမှတ်အသားပြုပါ။",
	"message" to "မက်ဆေ့ချ်",
	"missing_sender_msg" to "ပေးပို့သူအချက်အလက်မပါဘဲ စာရွက်စာတမ်းသည် မမှန်ကန်ပါ။",
	"missing_sender_title" to "ပေးပို့သူအချက်အလက် ပျောက်ဆုံးနေပါသည်။",
	"month_april" to "ဧပြီလ",
	"month_april_short" to "ဧပြီလ",
	"month_august" to "သြဂုတ်လ",
	"month_august_short" to "သြဂုတ်လ",
	"month_december" to "ဒီဇင်ဘာ",
	"month_december_short" to "ဒီဇင်ဘာ",
	"month_february" to "ဖေဖော်ဝါရီ",
	"month_february_short" to "ဖေဖော်ဝါရီ",
	"month_january" to "ဇန်နဝါရီလ",
	"month_january_short" to "ဇန်နဝါရီ",
	"month_july" to "ဇူလိုင်လ",
	"month_july_short" to "ဇူလိုင်လ",
	"month_june" to "ဇွန်လ",
	"month_june_short" to "ဇွန်လ",
	"month_march" to "မတ်လ",
	"month_march_short" to "မတ်လ",
	"month_may" to "မေ",
	"month_may_short" to "မေ",
	"month_november" to "နိုဝင်ဘာလ",
	"month_november_short" to "နိုဝင်ဘာ",
	"month_october" to "အောက်တိုဘာလ",
	"month_october_short" to "အောက်တိုဘာ",
	"month_september" to "စက်တင်ဘာ",
	"month_september_short" to "စက်တင်ဘာ",
	"multifactor_error" to "စစ်ဆေးရန် မအောင်မြင်ပါ။",
	"new" to "အသစ်",
	"news" to "သတင်း",
	"next" to "နောက်တစ်ခု",
	"no" to "no",
	"no_data_available" to "ဒေတာမရရှိနိုင်ပါ။",
	"no_search_results" to "ဝမ်းနည်းပါသည်၊ မည်သည့်ရလဒ်မှ ရှာမတွေ့ပါ။",
	"none" to "မရှိပါ။",
	"note_fields" to "မှတ်စုများ",
	"note_hint" to "မှတ်ချက်",
	"notifications_option" to "အသိပေးချက်များ",
	"ok" to "အိုကေ",
	"onboarding" to "စတင်အသုံးပြုခြင်း။",
	"onboarding_aboutus_message" to "DingVoice ၏ မရှိမဖြစ် အစိတ်အပိုင်းများသည် ထာဝရအခမဲ့ဖြစ်သည်။ ကျွန်ုပ်တို့၏တာဝန်မှာ သင့်လုပ်ငန်းကို ကြီးထွားလာစေရန်နှင့် သင့်အကြံပြုချက်ကို မျှဝေခြင်းဖြင့် ကျွန်ုပ်တို့ကို တိုးတက်အောင် ကူညီပေးရန်ဖြစ်သည်။",
	"onboarding_aboutus_title" to "ကူညီရန် ကျွန်ုပ်တို့ ဤနေရာတွင် ရှိနေပါသည်။",
	"onboarding_paid_1" to "ပရော်ဖက်ရှင်နယ်ပုံစံ နမူနာများကို ဝင်ရောက်ကြည့်ရှုပါ။",
	"onboarding_paid_2" to "သင်၏ဘဏ္ဍာရေးပိုင်းကို လေးနက်စွာ စူးစမ်းပါ။",
	"onboarding_paid_3" to "ပြဿနာများကို အမြန်ဖြေရှင်းပါ။",
	"onboarding_paid_4" to "သင်၏စာရွက်စာတမ်းများကိုလုံခြုံအောင်ထားပါ။",
	"onboarding_paid_5" to "ငွေတောင်းခံလွှာအခြေအနေ အပြောင်းအလဲများဖြင့် နောက်ဆုံးပေါ်နေပါ။",
	"onboarding_paid_title" to "ပိုမိုမြန်ဆန်စွာ ကြီးထွားလာပါစေ...",
	"onboarding_paywall_message" to "ပရီမီယံဝန်ဆောင်မှုအားလုံးကို အကောင်းဆုံးစျေးနှုန်းဖြင့် ယခုပဲ ခံစားလိုက်ပါ။",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} အတွက် ${currencySymbol}${introAmount} နှင့် ထို့နောက် ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} / ${duration} မျှသာ" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} နှင့် ${currencySymbol}${amount} / ${duration} ဖြင့် အခမဲ့" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "အခမဲ့${trialDuration}${trialPeriod} ထို့နောက်${currencySymbol}${introductoryAmount} ဘို့${introductoryDuration}${introPeriod} ပြီးမှပဲ${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "အလိုအလျောက် သက်တမ်းတိုးနိုင်သည်။ အချိန်မရွေး ပယ်ဖျက်ပါ။",
	"onboarding_paywall_restore" to "ဝယ်ယူမှုကို ပြန်လည်ရယူပါ။",
	"onboarding_paywall_restore_failure" to "ဝယ်ယူမှုများကို ပြန်လည်ရယူခြင်း မအောင်မြင်ပါ၊ နောက်မှ ထပ်စမ်းကြည့်ပါ သို့မဟုတ် အကူအညီကို ဆက်သွယ်ပါ။",
	"onboarding_paywall_restore_success" to "ပြန်လည်ရယူခြင်း အောင်မြင်ပါသည်။",
	"onboarding_paywall_title" to "ကြီးမြတ်မှုကိုရယူပါ။",
	"onboarding_welcome_message" to "သင့်ငွေတောင်းခံလွှာများနှင့် ခန့်မှန်းချက်များကို စီမံခန့်ခွဲရန် ပြီးပြည့်စုံသော ကိရိယာအစုံ။",
	"onboarding_welcome_title" to "DingVoice မှကြိုဆိုပါတယ်။",
	"other_state" to "အခြား",
	"overdue_state" to "ရက်လွန်ပြီ။",
	"paid_state" to "အခကြေးငွေ",
	"password" to "စကားဝှက်",
	"password_blank_error" to "စကားဝှက် လိုအပ်သည်။",
	"password_mismatch_error" to "စကားဝှက်များ မတိုက်ဆိုင်ပါ။",
	"pay" to "pay",
	"payment_instructions_label" to "ငွေပေးချေမှုညွှန်ကြားချက်များ",
	"payment_reminders" to "ငွေပေးချေမှု သတိပေးချက်များ",
	"phone_number_fields" to "ဖုန်းနံပါတ်",
	"phone_number_hint" to "ဖုန်းနံပါတ်",
	"postcode_hint" to "စာပို့ကုဒ်",
	"preview" to "စမ်းကြည့်ပါ။",
	"preview_menu_hint" to "မီနူးကို အစမ်းကြည့်ပါ။",
	"price" to "စျေးနှုန်း",
	"privacy_policy" to "ကိုယ်ရေးလုံခြုံမှု မူဝါဒ",
	"profile" to "ကိုယ်ရေးအကျဉ်း",
	"profitable_client" to "မင်းရဲ့အမြတ်အစွန်းအများဆုံး ဖောက်သည်က...",
	"profits_report" to "အမြတ်အစွန်း",
	"promo_content" to "ပရိုမိုးရှင်းအကြောင်းအရာ",
	"quantity_hint" to "အရေအတွက်",
	"quantity_label_hint" to "အရေအတွက် အညွှန်း",
	"query_info" to "Query သည် သွင်းအားစုများအဖြစ် ပစ္စည်းများနှင့် အခြားအခွန်စည်းမျဥ်းနှစ်ခုလုံးကို ရည်ညွှန်းသည့် စိတ်ကြိုက်တွက်ချက်မှုများကို ရေးသားနိုင်စေပါသည်။ အခြားထည့်သွင်းမှု +, -, *, /, % - သင်္ချာအော်ပရေတာများသို့ လင့်ခ်ချိတ်ရန် @ သင်္ကေတနှင့် ပစ္စည်း သို့မဟုတ် အခွန်ဖော်ပြချက်ကို ထည့်ပါ။",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ကို သင့်ဖောက်သည်မှ လက်ခံရရှိခဲ့သည်။" },
	"received_state" to "ရရှိခဲ့သည်။",
	"registration_code_fields" to "မှတ်ပုံတင်ကုဒ်",
	"reminder" to "သတိပေးခြင်း",
	"reports" to "အစီရင်ခံစာများ",
	"reports_not_available" to "အစီရင်ခံစာမရရှိနိုင်ပါ၊ နောက်မှပြန်လာပါ။",
	"reveal_password" to "စကားဝှက်ကိုထုတ်ပြပါ။",
	"revenue" to "revenue",
	"sales_tax" to "VAT",
	"sales_tax_number" to "VAT နံပါတ်",
	"save" to "သိမ်းဆည်း",
	"scan_document" to "စာရွက်စာတမ်းကို စကင်ဖတ်ပါ။",
	"search" to "ရှာဖွေပါ",
	"select" to "ရွေးချယ်ပါ။",
	"select_icon" to "အိုင်ကွန်ကို ရွေးပါ။",
	"select_signature" to "လက်မှတ်ကို ရွေးပါ။",
	"select_template" to "နမူနာပုံစံကို ရွေးပါ။",
	"send" to "ပို့ပါ။",
	"sent_state" to "ပို့လိုက်ပါတယ်။",
	"setup_sender" to "ပေးပို့သူကို သတ်မှတ်ပါ။",
	"share_option" to "မျှဝေပါ",
	"ship_to" to "သို့တင်ပို့သည်",
	"sign_in" to "လက်မှတ်ထိုး ဝင်ရန်",
	"sign_in_apple" to "Apple ဖြင့် အကောင့်ဝင်ပါ။",
	"sign_in_email" to "အီးမေးလ်ဖြင့် ဝင်ရောက်ပါ။",
	"sign_in_facebook" to "Facebook ဖြင့် ဝင်ရောက်ပါ။",
	"sign_in_google" to "Google ဖြင့် အကောင့်ဝင်ပါ။",
	"sign_up" to "စာရင်းသွင်း",
	"sign_up_label" to "အကောင့်မရှိဘူးလား။",
	"sign_up_link_label" to "ဆိုင်းအပ်!",
	"signatures_option" to "လက်မှတ်များ",
	"skip" to "ကျော်သွား",
	"sold_items_report" to "ရောင်းချပြီးသောပစ္စည်းများ",
	"sort_by" to "အလိုက်စဥ်သည်:",
	"status_update" to "စာရွက်စာတမ်း အခြေအနေ အပ်ဒိတ်",
	"sub_annual" to "နှစ်စဉ်",
	"sub_annual_price" to Formattable { (price) -> "${price}/နှစ်" },
	"sub_backups" to "Cloud Backups",
	"sub_insights" to "ထိုးထွင်းဥာဏ်",
	"sub_month" to "လစဉ်",
	"sub_monthly_price" to Formattable { (price) -> "${price}/လ" },
	"sub_notifications" to "အသိပေးချက်များ",
	"sub_popup_message" to "စာရင်းသွင်းမှုများသည် မကြာမီလာပါမည်။ ခဏနေ ဒီမှာ ပြန်ကြည့်ပါ။",
	"sub_support" to "အထောက်အပံ့",
	"sub_templates" to "ပုံစံများ",
	"subscribe" to "စာရင်းသွင်းပါ",
	"subscription_header" to "သင့်လုပ်ငန်းကို ကြီးထွားစေသည်-",
	"subscription_option" to "စာရင်းသွင်းခြင်း။",
	"subscriptions_load_fail" to "ဝမ်းနည်းပါသည်၊ သင့်စာရင်းသွင်းမှုများကို ယခုအချိန်တွင် ပြန်လည်ရယူ၍မရပါ။ ကျေးဇူးပြု၍ နောက်မှ ထပ်စမ်းကြည့်ပါ သို့မဟုတ် အကူအညီအတွက် ကျွန်ုပ်တို့၏ ပံ့ပိုးကူညီမှုအဖွဲ့ထံ ဆက်သွယ်ပါ။",
	"subtotal" to Formattable { (sum) -> "စုစုပေါင်း- ${sum}" },
	"subtotal_label" to "စုစုပေါင်း",
	"sum" to "မိုးဆမ်း",
	"support_option" to "အထောက်အပံ့",
	"switch_reports" to "အစီရင်ခံစာပြောင်းပါ။",
	"tax" to "အခွန်",
	"tax_deleted_message" to "အခွန်ဖျက်လိုက်ပြီ။",
	"tax_description_warning" to "အသုံးပြုပါက ပြေစာတွင် ပေါ်လာပါသည်။",
	"tax_input_flat" to "တိုက်ခန်း",
	"tax_input_percent" to "ရာခိုင်နှုန်း",
	"tax_number_fields" to "အခွန်ကုဒ်နံပါတ်",
	"tax_number_hint" to "အခွန်ကုဒ်နံပါတ်",
	"tax_query_info_description" to "မေးမြန်းမှု အချက်အလက်",
	"tax_report" to "အခွန်",
	"tax_rules_option" to "အခွန်စည်းကမ်းများ",
	"taxed_clients_report" to "အခွန်ကောက်သော ဖောက်သည်များ",
	"template" to "ပုံစံခွက်",
	"template_selector_info" to "မူရင်းပုံစံကို ပြောင်းရန် ပွတ်ဆွဲပါ။",
	"template_unlock_promo" to "Premium ဖြင့် နောက်ထပ် နမူနာများကို အသုံးပြုပါ။",
	"templates_option" to "ပုံစံများ",
	"terms_of_service" to "ဝန်ဆောင်မှုစည်းမျဉ်းများ",
	"thank_you" to "ကျေးဇူးပါ",
	"to" to "ရန်",
	"total" to Formattable { (sum) -> "စုစုပေါင်း- ${sum}" },
	"total_label" to "စုစုပေါင်း",
	"undo_action" to "ပယ်ဖျက်ပါ",
	"unit" to "ယူနစ်",
	"unit_cost_hint" to "ယူနစ်ကုန်ကျစရိတ်",
	"unknown_client" to "အမည်မသိ သုံးစွဲသူ",
	"unlock" to "သော့ခတ်",
	"unnamed_item" to "အမည်မသိ ပစ္စည်း",
	"upgrade" to "အဆင့်မြှင့်ပါ။",
	"user_collision_error" to "အကောင့်ရှိပြီးသားပါ။",
	"valid_until" to "သည်အထိ အကျုံးဝင်သည်။",
	"warning" to "သတိပေးချက်",
	"yes" to "yes",
    )
    
}