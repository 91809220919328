// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ky  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} жана ${privacyPolicy} кабыл алыңыз" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Жараксыз код."
override val add_client_action: String = "Кардар кошуу"
override val add_customer: String = "Кардар кошуу"
override val add_expense: String = "Чыгым кошуу"
override val add_fields_label: String = "Кошуу:"
override val add_invoice_action: String = "Түзүү"
override val add_item_action: String = "Элемент кошуу"
override val add_item_button: String = "Элемент кошуу"
override val add_signature_action: String = "Кол коюу"
override val add_tax_action: String = "Салык кошуу"
override val address_fields: String = "Дарек"
override val address_line_hint: String = "Дарек сызыгы"
override val advanced: String = "Өркүндөтүлгөн"
override val agree_marketing: String = "Мен түз маркетингдик байланыштарды алууга макулмун"
override val all_notifications: String = "Бардык эскертмелер"
override val amortization: String = "amortization"
override val amount: String = "Суммасы"
override val asset: String = "asset"
override val attach_file: String = "Файлды тиркөө"
override val attachment: String = "Тиркеме"
override val auth_error: String = "Аракет ишке ашкан жок, кайра аракет кылыңыз."
override val available_for_paid: String = "Булуттагы синхрондоштуруу акы төлөнүүчү жазылуу боюнча гана жеткиликтүү."
override val backups_option: String = "Камдык көчүрмөлөр"
override val balance: String = "balance"
override val bank_account: String = "Банк эсеби"
override val bill_number: String = "Эсеп саны"
override val cancel: String = "Жокко чыгаруу"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "категориялар"
override val category: String = "Категория"
override val category_add: String = "Категория кошуу"
override val category_delete: String = "Категорияны жок кылуу"
override val category_name: String = "Категориянын аталышы"
override val change_language: Formattable = Formattable { (language) -> "Эсеп-фактура тили: ${language}" }
override val change_language_label: String = "Эсеп-фактура тили"
override val change_template: String = "Шаблонду өзгөртүү"
override val city_hint: String = "City"
override val clear: String = "Таза"
override val client: String = "Кардар"
override val client_actions: String = "Кардардын аракеттери"
override val client_deleted_message: String = "Кардар жок кылынды"
override val clients_error_required_fields: String = "Кеминде бир маани талап кылынат."
override val clients_option: String = "Кардарлар"
override val close: String = "Жабуу"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "Келе жакында!"
override val company_logo: String = "Компания логотиби"
override val company_name_hint: String = "Компания"
override val confirm_password_label: String = "Сырсөздү ыраста"
override val confirmation_title: String = "Ишенесиңби?"
override val convert_to_invoice: String = "Эсеп-фактурага айландыруу"
override val country_hint: String = "Өлкө"
override val create_client: String = "Клиент түзүү"
override val create_company: String = "Компания түзүү"
override val create_document: String = "Документ түзүү"
override val create_estimate: String = "Баалоо"
override val create_invoice: String = "Эсеп-фактура"
override val create_item: String = "Элемент түзүү"
override val create_new_client: String = "Жаңы кардарды түзүү"
override val create_new_item: String = "Жаңы нерсе түзүү"
override val create_new_tax: String = "Жаңы салык түзүңүз"
override val create_signature: String = "Кол коюу"
override val created: String = "түзүлгөн"
override val credit: String = "credit"
override val customer: String = "Кардар"
override val date: String = "Дата"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Демейки валюта: ${currency}" }
override val default_currency_label: String = "Демейки валюта"
override val delete: String = "Delete"
override val delete_account: String = "Каттоо эсебин жок кылуу"
override val delete_confirmation_message: String = "Бардык дайындарыңыз жоголот."
override val delete_item_title: String = "Нерсени өчүрөсүзбү?"
override val delete_signature: String = "Кол тамганы жок кылуу"
override val depreciation: String = "depreciation"
override val description: String = "баяндоо"
override val description_hint: String = "Description"
override val detailed_report: String = "Толук отчет"
override val disabled: String = "Өчүрүлдү"
override val discount: String = "Арзандатуу"
override val discount_hint: String = "Арзандатуу"
override val display_af: String = "африкалык"
override val display_am: String = "Амхарча"
override val display_ar: String = "Арабча"
override val display_as: String = "Ассам"
override val display_az: String = "азербайжан"
override val display_be: String = "Беларусча"
override val display_bg: String = "Болгарча"
override val display_bn: String = "Бенгалча"
override val display_bs: String = "Бошнакча"
override val display_ca: String = "Каталанча"
override val display_cs: String = "Чехче"
override val display_da: String = "Датча"
override val display_de: String = "Немисче"
override val display_el: String = "Грекче"
override val display_en: String = "Англисче"
override val display_es: String = "Испанча"
override val display_es_MX: String = "Испанча (Мексика)"
override val display_et: String = "Эстончо"
override val display_eu: String = "Баскча"
override val display_fa: String = "Парсча"
override val display_fi: String = "Финче"
override val display_fil: String = "Филиппин"
override val display_fr: String = "Франсузча"
override val display_fr_CA: String = "Канадалык француз"
override val display_gl: String = "Галицияча"
override val display_gu: String = "Гужаратиче"
override val display_he: String = "Еврейче"
override val display_hi: String = "Жок"
override val display_hr: String = "Хорватча"
override val display_hu: String = "Венгерче"
override val display_hy: String = "Армянча"
override val display_id: String = "Индонезияча"
override val display_is: String = "Исландияча"
override val display_it: String = "Итальянча"
override val display_ja: String = "Жапончо"
override val display_ka: String = "Грузинче"
override val display_kk: String = "Казакча"
override val display_km: String = "Борбордук кхмер"
override val display_kn: String = "Каннадача"
override val display_ko: String = "Корейче"
override val display_ky: String = "Киргиз"
override val display_lo: String = "Эмгек"
override val display_lt: String = "Литвача"
override val display_lv: String = "Латвияча"
override val display_mk: String = "Македонияча"
override val display_ml: String = "Малаяламча"
override val display_mn: String = "Монголчо"
override val display_mr: String = "Маратиче"
override val display_ms: String = "Малайча"
override val display_my: String = "Бурмача"
override val display_nb: String = "Norwegian Bokmål"
override val display_ne: String = "Непаличе"
override val display_nl: String = "Нидерландча"
override val display_or: String = "Орияча"
override val display_pa: String = "Пунжабиче"
override val display_pl: String = "Полякча"
override val display_pt: String = "Португалча"
override val display_pt_BR: String = "Португалча (Бразилия)"
override val display_pt_PT: String = "Европалык португалча"
override val display_ro: String = "Румынча"
override val display_ru: String = "Орусча"
override val display_si: String = "Sinhala"
override val display_sk: String = "Словакча"
override val display_sl: String = "Словенияча"
override val display_sq: String = "Албанча"
override val display_sr: String = "Сербче"
override val display_sv: String = "Шведче"
override val display_sw: String = "Свахиличе"
override val display_ta: String = "Тамилче"
override val display_te: String = "Телугуча"
override val display_th: String = "Тайча"
override val display_tr: String = "Түркчө"
override val display_uk: String = "Украинче"
override val display_ur: String = "Урдуча"
override val display_uz: String = "Өзбекче"
override val display_vi: String = "Вьетнамча"
override val display_zh_Hant_TW: String = "Кытай (Тайвань, салттуу)"
override val display_zu: String = "Зулуча"
override val document_estimate: String = "Баалоо"
override val document_invoice: String = "Эсеп-фактура"
override val document_quote: String = "Цитата"
override val done: String = "Бүттү"
override val download_app: String = "Колдонмону жүктөп алуу"
override val download_invoice: String = "Эсеп-фактураны жүктөп алыңыз"
override val download_pdf: String = "PDF жүктөп алуу"
override val draw_signature: String = "Кол коюу"
override val due: Formattable = Formattable { (date) -> "Төлөө мөөнөтү ${date}" }
override val due_date: String = "Бүтүмдүк дата"
override val due_day: String = "күндө"
override val due_label: String = "Төлөө"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ${dueWhen} күнү бүтөт!" }
override val due_today: String = "Бүгүн"
override val due_week: String = "бир жумада"
override val duplicate: String = "Дубликат"
override val duration_day: String = "күн"
override val duration_month: String = "ай"
override val duration_week: String = "апта"
override val duration_year: String = "жыл"
override val email: String = "Электрондук почта"
override val email_blank_error: String = "Электрондук почта керек."
override val email_error: String = "Жараксыз электрондук почта дареги."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Сиздин бизнес үчүн рахмат. ${documentType} толук көрүү үчүн бул каттын аягындагы баскычты басыңыз. Ал ${dueDate} чейин жарактуу экенин эске алыңыз." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Сиз жаңысын алдыңыз${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} КӨРҮҮ" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Урматтуу ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Сиздин бизнес үчүн рахмат. ${documentType} толук көрүү үчүн бул каттын аягындагы баскычты басыңыз. Сураныч, балансты ${dueDate} чейин төлөңүз." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Сиз жаңысын алдыңыз${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "Сырсөздү калыбына келтирүү үчүн электрондук кат жөнөтүлдү, келүүчү кутуңузду текшериңиз."
override val empty_clients_list_message: String = "Көзөмөл кыла турган кардарларыңыз жок. Жаңы кардар кошуу."
override val empty_estimate_list_message: String = "Биринчи бааңызды түзүңүз"
override val empty_expenses: String = "Көз салууга эч кандай чыгымыңыз жок. Жаңы чыгаша кошуу."
override val empty_invoice_list_message: String = "Биринчи эсеп-фактураңызды түзүңүз"
override val empty_items_list_message: String = "Сизде эч нерсе жок, жаңы нерсе кошуңуз!"
override val empty_signatures_list_message: String = "Колдор табылган жок. Бир түзүү!"
override val empty_taxes_list_message: String = "Сизде салык эрежелери жок. Жаңы салык эрежесин кошуңуз."
override val empty_templates_list_message: String = "Калыптар жеткиликсиз."
override val enabled: String = "Иштетилди"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Болжолдуу №"
override val estimates: String = "Сметалар"
override val estimates_report: String = "Сметалар"
override val expense_bank_fees: String = "Банк комиссиялары"
override val expense_benefits: String = "Артыкчылыктары"
override val expense_communication: String = "Байланыш"
override val expense_debt: String = "Карыз"
override val expense_deleted: String = "Чыгым өчүрүлдү"
override val expense_depreciation: String = "Амортизация"
override val expense_gifts: String = "Белектер"
override val expense_insurance: String = "Камсыздандыруу"
override val expense_inventory: String = "Инвентаризация"
override val expense_legal: String = "Юридикалык"
override val expense_maintenance: String = "Техникалык тейлөө"
override val expense_marketing: String = "Маркетинг"
override val expense_meals: String = "Тамак-аш"
override val expense_office_supplies: String = "Office Supplies"
override val expense_rent: String = "Ижарага алуу"
override val expense_salaries: String = "Эмгек акылар"
override val expense_shopping: String = "Соода"
override val expense_software: String = "Программалык камсыздоо"
override val expense_status_available: String = "жеткиликтүү"
override val expense_status_processing: String = "Иштетүү"
override val expense_tax: String = "Салык"
override val expense_training: String = "Тренинг"
override val expense_transport: String = "Транспорт"
override val expense_travel: String = "Саякат"
override val expense_utility: String = "Утилита"
override val expenses: String = "Чыгымдар"
override val feedback_option: String = "Пикирлер"
override val filter: String = "Чыпка"
override val first_name_hint: String = "Биринчи аты"
override val forgot_password: String = "Сырсөздү кайра коюу"
override val forgot_password_label: String = "Паролду унутуп калдыңыз"
override val from: String = "From"
override val general_error: String = "Ката кетти, бир аздан кийин кайталап көрүңүз."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Артка кайрыл"
override val group_by: String = "Топ боюнча:"
override val image_view: String = "Сүрөт"
override val install: String = "Орнотуу"
override val invalid_credentials_error: String = "Жараксыз эсептик дайындар."
override val invalid_email_error: String = "Жараксыз электрондук почта."
override val invalid_user_error: String = "Колдонуучунун эсептик дайындары жараксыз."
override val invoice_deleted_message: String = "Эсеп-фактура жок кылынды"
override val invoice_menu: String = "Эсеп-фактура менюсу"
override val invoice_number: String = "Эсеп-фактура №"
override val invoices: String = "Эсеп-фактуралар"
override val items_option: String = "Items"
override val keep: String = "Сактоо"
override val language_selector: Formattable = Formattable { (selected) -> "Тил ${selected}" }
override val last_name_hint: String = "Фамилия"
override val ledger: String = "ledger"
override val legal: String = "Юридикалык"
override val legal_error: String = "Тейлөө шарттарын жана Купуялык саясатын кабыл алышы керек"
override val liabilities: String = "liabilities"
override val loading: String = "Жүктөө"
override val login_required_error: String = "Кирүү талап кылынат."
override val logo_view: String = "Логотип"
override val logout: String = "Чыгуу"
override val logout_prompt: String = "Сиздин дайындар булут менен синхрондоштуруу эмес. Чыгуу учурунда эсеп-фактураларды, эсептерди, кардарларды жана башка маалымат жок кылынсынбы?"
override val main_screen: String = "Негизги экран"
override val mark_paid: String = "Төлөнгөн деп белгилөө"
override val message: String = "билдирүү"
override val missing_sender_msg: String = "Жөнөтүүчү маалыматы жок документ жараксыз болушу мүмкүн."
override val missing_sender_title: String = "Жөнөтүүчү маалымат жок"
override val month_april: String = "Апрель"
override val month_april_short: String = "Апрель"
override val month_august: String = "Август"
override val month_august_short: String = "август"
override val month_december: String = "Декабрь"
override val month_december_short: String = "декабрь"
override val month_february: String = "Февраль"
override val month_february_short: String = "февраль"
override val month_january: String = "Январь"
override val month_january_short: String = "январь."
override val month_july: String = "Июль"
override val month_july_short: String = "Июль"
override val month_june: String = "Июнь"
override val month_june_short: String = "Июнь"
override val month_march: String = "Март"
override val month_march_short: String = "Март"
override val month_may: String = "Май"
override val month_may_short: String = "Май"
override val month_november: String = "Ноябрь"
override val month_november_short: String = "ноябрь"
override val month_october: String = "Октябрь"
override val month_october_short: String = "октябрь"
override val month_september: String = "Сентябрь"
override val month_september_short: String = "сентябрь"
override val multifactor_error: String = "Текшерилбей калды."
override val new: String = "Жаңы"
override val news: String = "Жаңылыктар"
override val next: String = "Кийинки"
override val no: String = "no"
override val no_data_available: String = "Дайындар жок"
override val no_search_results: String = "Кечиресиз, биз эч кандай жыйынтык таба алган жокпуз."
override val none: String = "Жок"
override val note_fields: String = "Эскертүүлөр"
override val note_hint: String = "Эскертүү"
override val notifications_option: String = "Эскертмелер"
override val ok: String = "Макул"
override val onboarding: String = "Конууда"
override val onboarding_aboutus_message: String = "DingVoiceтин негизги бөлүктөрү түбөлүккө акысыз. Биздин миссия - сизге бизнесиңизди өнүктүрүүгө жардам берүү, пикириңизди бөлүшүү аркылуу жакшыртууга жардам берүү."
override val onboarding_aboutus_title: String = "Биз жардам берүү үчүн бул жердебиз"
override val onboarding_paid_1: String = "Профессионалдуу үлгүлөргө кириңиз"
override val onboarding_paid_2: String = "Каржылооңузга тереңирээк көз салыңыз"
override val onboarding_paid_3: String = "Көйгөйлөр тез чечилет"
override val onboarding_paid_4: String = "Документтериңизди сактаңыз"
override val onboarding_paid_5: String = "Эсеп-фактуранын абалын өзгөртүүлөрдөн кабардар болуңуз"
override val onboarding_paid_title: String = "менен дагы тез өсө..."
override val onboarding_paywall_message: String = "Бардык премиум функциялардан азыр эң жакшы баада ырахат алыңыз."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} үчүн ${currencySymbol}${introAmount}, андан кийин ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Жөн гана ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} акысыз, андан кийин жөн гана ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} акысыз, андан кийин ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}, андан кийин жөн гана ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Авто-жаңылануучу. Каалаган убакта жокко чыгарыңыз."
override val onboarding_paywall_restore: String = "Сатып алууну калыбына келтирүү"
override val onboarding_paywall_restore_failure: String = "Сатып алуулар калыбына келтирилбей калды, кийинчерээк кайталап көрүңүз же колдоо кызматына кайрылыңыз."
override val onboarding_paywall_restore_success: String = "Ийгиликтүү калыбына келтириңиз!"
override val onboarding_paywall_title: String = "Улуулукка жет!"
override val onboarding_welcome_message: String = "Эсеп-фактураларыңызды жана сметаларды башкаруу үчүн толук куралдар топтому."
override val onboarding_welcome_title: String = "DingVoice кош келиңиз"
override val other_state: String = "Башка"
override val overdue_state: String = "Мөөнөтү өтүп кеткен"
override val paid_state: String = "Төлөндү"
override val password: String = "Купуя сөз"
override val password_blank_error: String = "Сырсөз талап кылынат."
override val password_mismatch_error: String = "Сырсөздөр дал келбейт."
override val pay: String = "pay"
override val payment_instructions_label: String = "Төлөм нускамалары"
override val payment_reminders: String = "Төлөм эскертүүлөрү"
override val phone_number_fields: String = "Тел номери"
override val phone_number_hint: String = "Тел номери"
override val postcode_hint: String = "Почта индекси"
override val preview: String = "Алдын ала көрүү"
override val preview_menu_hint: String = "Алдын ала көрүү менюсу"
override val price: String = "Баасы"
override val privacy_policy: String = "Купуялык саясаты"
override val profile: String = "Профиль"
override val profitable_client: String = "Сиздин эң кирешелүү кардарыңыз..."
override val profits_report: String = "Пайда"
override val promo_content: String = "Жарнамалык мазмун"
override val quantity_hint: String = "Саны"
override val quantity_label_hint: String = "Сандык белги"
override val query_info: String = "Суроо элементтерге жана башка салык эрежелерине киргизүү катары колдонуучу эсептөөлөрдү жазууга мүмкүндүк берет. Башка жазууга +, -, *, /, % - математикалык операторлорду шилтемелөө үчүн @ белгисин жана нерсени же салыктын сүрөттөмөсүн кошуңуз"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} сиздин кардарыңыз тарабынан алынды." }
override val received_state: String = "Кабыл алынган"
override val registration_code_fields: String = "Катталуу коду"
override val reminder: String = "Эскертүү"
override val reports: String = "Отчеттор"
override val reports_not_available: String = "Отчеттор жок, кийинчерээк кайтып келиңиз."
override val reveal_password: String = "Сырсөздү ачуу"
override val revenue: String = "revenue"
override val sales_tax: String = "КНС"
override val sales_tax_number: String = "КНС номери"
override val save: String = "Сактоо"
override val scan_document: String = "Документти сканерлөө"
override val search: String = "Издөө"
override val select: String = "Тандоо"
override val select_icon: String = "Тандоо сөлөкөтү"
override val select_signature: String = "Кол коюуну тандаңыз"
override val select_template: String = "Үлгү тандаңыз"
override val send: String = "Жөнөтүү"
override val sent_state: String = "Жөнөтүлдү"
override val setup_sender: String = "Жөнөтүүчүнү орнотуу"
override val share_option: String = "Бөлүшүү"
override val ship_to: String = "жөнөтүү"
override val sign_in: String = "Кирүү"
override val sign_in_apple: String = "Apple менен кириңиз"
override val sign_in_email: String = "Электрондук почта менен кириңиз"
override val sign_in_facebook: String = "Facebook менен кирүү"
override val sign_in_google: String = "Google менен кириңиз"
override val sign_up: String = "Кирүү"
override val sign_up_label: String = "Каттоо эсебиңиз жокпу?"
override val sign_up_link_label: String = "Кирүү!"
override val signatures_option: String = "Кол коюулар"
override val skip: String = "Өткөрүп жибер"
override val sold_items_report: String = "Сатылган буюмдар"
override val sort_by: String = "Иреттөө:"
override val status_update: String = "Документтин абалын жаңыртуу"
override val sub_annual: String = "Жылдык"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/жылына" }
override val sub_backups: String = "Булуттагы камдык көчүрмөлөр"
override val sub_insights: String = "Insights"
override val sub_month: String = "Ай сайын"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ай" }
override val sub_notifications: String = "Билдирмелер"
override val sub_popup_message: String = "Жазылуулар жакында келет. Бир аздан кийин бул жерде кайра текшериңиз."
override val sub_support: String = "Колдоо"
override val sub_templates: String = "Шаблондор"
override val subscribe: String = "Жазылуу"
override val subscription_header: String = "Төмөнкүлөр менен бизнесиңизди өстүрүңүз:"
override val subscription_option: String = "Жазылуу"
override val subscriptions_load_fail: String = "Кечиресиз, учурда жазылууларыңызды калыбына келтире албайбыз. Сураныч, кийинчерээк кайра аракет кылыңыз же жардам алуу үчүн колдоо тобубузга кайрылыңыз."
override val subtotal: Formattable = Formattable { (sum) -> "Аралык сумма: ${sum}" }
override val subtotal_label: String = "Аралык сумма"
override val sum: String = "Сум"
override val support_option: String = "Колдоо"
override val switch_reports: String = "Отчетту которуу"
override val tax: String = "Салык"
override val tax_deleted_message: String = "Салык өчүрүлдү"
override val tax_description_warning: String = "Эгер колдонулган болсо, бул эсеп-фактурада көрсөтүлөт"
override val tax_input_flat: String = "Жалпак"
override val tax_input_percent: String = "пайыз"
override val tax_number_fields: String = "Салык номуру"
override val tax_number_hint: String = "Салык номуру"
override val tax_query_info_description: String = "Сурам маалымат"
override val tax_report: String = "Салык"
override val tax_rules_option: String = "Салык эрежелери"
override val taxed_clients_report: String = "Салык салынган кардарлар"
override val template: String = "Шаблон"
override val template_selector_info: String = "Демейки шаблонду өзгөртүү үчүн серпиңиз"
override val template_unlock_promo: String = "Premium менен көбүрөөк калыптарга кириңиз"
override val templates_option: String = "Шаблондор"
override val terms_of_service: String = "Тейлөө шарттары"
override val thank_you: String = "Рахмат"
override val to: String = "үчүн"
override val total: Formattable = Formattable { (sum) -> "Бардыгы: ${sum}" }
override val total_label: String = "Бардыгы"
override val undo_action: String = "Жок кылуу"
override val unit: String = "бирдиги"
override val unit_cost_hint: String = "Бирдиктин баасы"
override val unknown_client: String = "Белгисиз кардар"
override val unlock: String = "Кулпуну ачуу"
override val unnamed_item: String = "Аты жок нерсе"
override val upgrade: String = "Жаңыртуу"
override val user_collision_error: String = "Каттоо эсеби мурунтан эле бар."
override val valid_until: String = "Чейин жарактуу"
override val warning: String = "Эскертүү!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} жана ${privacyPolicy} кабыл алыңыз" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Жараксыз код.",
	"add_client_action" to "Кардар кошуу",
	"add_customer" to "Кардар кошуу",
	"add_expense" to "Чыгым кошуу",
	"add_fields_label" to "Кошуу:",
	"add_invoice_action" to "Түзүү",
	"add_item_action" to "Элемент кошуу",
	"add_item_button" to "Элемент кошуу",
	"add_signature_action" to "Кол коюу",
	"add_tax_action" to "Салык кошуу",
	"address_fields" to "Дарек",
	"address_line_hint" to "Дарек сызыгы",
	"advanced" to "Өркүндөтүлгөн",
	"agree_marketing" to "Мен түз маркетингдик байланыштарды алууга макулмун",
	"all_notifications" to "Бардык эскертмелер",
	"amortization" to "amortization",
	"amount" to "Суммасы",
	"asset" to "asset",
	"attach_file" to "Файлды тиркөө",
	"attachment" to "Тиркеме",
	"auth_error" to "Аракет ишке ашкан жок, кайра аракет кылыңыз.",
	"available_for_paid" to "Булуттагы синхрондоштуруу акы төлөнүүчү жазылуу боюнча гана жеткиликтүү.",
	"backups_option" to "Камдык көчүрмөлөр",
	"balance" to "balance",
	"bank_account" to "Банк эсеби",
	"bill_number" to "Эсеп саны",
	"cancel" to "Жокко чыгаруу",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "категориялар",
	"category" to "Категория",
	"category_add" to "Категория кошуу",
	"category_delete" to "Категорияны жок кылуу",
	"category_name" to "Категориянын аталышы",
	"change_language" to Formattable { (language) -> "Эсеп-фактура тили: ${language}" },
	"change_language_label" to "Эсеп-фактура тили",
	"change_template" to "Шаблонду өзгөртүү",
	"city_hint" to "City",
	"clear" to "Таза",
	"client" to "Кардар",
	"client_actions" to "Кардардын аракеттери",
	"client_deleted_message" to "Кардар жок кылынды",
	"clients_error_required_fields" to "Кеминде бир маани талап кылынат.",
	"clients_option" to "Кардарлар",
	"close" to "Жабуу",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "Келе жакында!",
	"company_logo" to "Компания логотиби",
	"company_name_hint" to "Компания",
	"confirm_password_label" to "Сырсөздү ыраста",
	"confirmation_title" to "Ишенесиңби?",
	"convert_to_invoice" to "Эсеп-фактурага айландыруу",
	"country_hint" to "Өлкө",
	"create_client" to "Клиент түзүү",
	"create_company" to "Компания түзүү",
	"create_document" to "Документ түзүү",
	"create_estimate" to "Баалоо",
	"create_invoice" to "Эсеп-фактура",
	"create_item" to "Элемент түзүү",
	"create_new_client" to "Жаңы кардарды түзүү",
	"create_new_item" to "Жаңы нерсе түзүү",
	"create_new_tax" to "Жаңы салык түзүңүз",
	"create_signature" to "Кол коюу",
	"created" to "түзүлгөн",
	"credit" to "credit",
	"customer" to "Кардар",
	"date" to "Дата",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Демейки валюта: ${currency}" },
	"default_currency_label" to "Демейки валюта",
	"delete" to "Delete",
	"delete_account" to "Каттоо эсебин жок кылуу",
	"delete_confirmation_message" to "Бардык дайындарыңыз жоголот.",
	"delete_item_title" to "Нерсени өчүрөсүзбү?",
	"delete_signature" to "Кол тамганы жок кылуу",
	"depreciation" to "depreciation",
	"description" to "баяндоо",
	"description_hint" to "Description",
	"detailed_report" to "Толук отчет",
	"disabled" to "Өчүрүлдү",
	"discount" to "Арзандатуу",
	"discount_hint" to "Арзандатуу",
	"display_af" to "африкалык",
	"display_am" to "Амхарча",
	"display_ar" to "Арабча",
	"display_as" to "Ассам",
	"display_az" to "азербайжан",
	"display_be" to "Беларусча",
	"display_bg" to "Болгарча",
	"display_bn" to "Бенгалча",
	"display_bs" to "Бошнакча",
	"display_ca" to "Каталанча",
	"display_cs" to "Чехче",
	"display_da" to "Датча",
	"display_de" to "Немисче",
	"display_el" to "Грекче",
	"display_en" to "Англисче",
	"display_es" to "Испанча",
	"display_es_MX" to "Испанча (Мексика)",
	"display_et" to "Эстончо",
	"display_eu" to "Баскча",
	"display_fa" to "Парсча",
	"display_fi" to "Финче",
	"display_fil" to "Филиппин",
	"display_fr" to "Франсузча",
	"display_fr_CA" to "Канадалык француз",
	"display_gl" to "Галицияча",
	"display_gu" to "Гужаратиче",
	"display_he" to "Еврейче",
	"display_hi" to "Жок",
	"display_hr" to "Хорватча",
	"display_hu" to "Венгерче",
	"display_hy" to "Армянча",
	"display_id" to "Индонезияча",
	"display_is" to "Исландияча",
	"display_it" to "Итальянча",
	"display_ja" to "Жапончо",
	"display_ka" to "Грузинче",
	"display_kk" to "Казакча",
	"display_km" to "Борбордук кхмер",
	"display_kn" to "Каннадача",
	"display_ko" to "Корейче",
	"display_ky" to "Киргиз",
	"display_lo" to "Эмгек",
	"display_lt" to "Литвача",
	"display_lv" to "Латвияча",
	"display_mk" to "Македонияча",
	"display_ml" to "Малаяламча",
	"display_mn" to "Монголчо",
	"display_mr" to "Маратиче",
	"display_ms" to "Малайча",
	"display_my" to "Бурмача",
	"display_nb" to "Norwegian Bokmål",
	"display_ne" to "Непаличе",
	"display_nl" to "Нидерландча",
	"display_or" to "Орияча",
	"display_pa" to "Пунжабиче",
	"display_pl" to "Полякча",
	"display_pt" to "Португалча",
	"display_pt_BR" to "Португалча (Бразилия)",
	"display_pt_PT" to "Европалык португалча",
	"display_ro" to "Румынча",
	"display_ru" to "Орусча",
	"display_si" to "Sinhala",
	"display_sk" to "Словакча",
	"display_sl" to "Словенияча",
	"display_sq" to "Албанча",
	"display_sr" to "Сербче",
	"display_sv" to "Шведче",
	"display_sw" to "Свахиличе",
	"display_ta" to "Тамилче",
	"display_te" to "Телугуча",
	"display_th" to "Тайча",
	"display_tr" to "Түркчө",
	"display_uk" to "Украинче",
	"display_ur" to "Урдуча",
	"display_uz" to "Өзбекче",
	"display_vi" to "Вьетнамча",
	"display_zh_Hant_TW" to "Кытай (Тайвань, салттуу)",
	"display_zu" to "Зулуча",
	"document_estimate" to "Баалоо",
	"document_invoice" to "Эсеп-фактура",
	"document_quote" to "Цитата",
	"done" to "Бүттү",
	"download_app" to "Колдонмону жүктөп алуу",
	"download_invoice" to "Эсеп-фактураны жүктөп алыңыз",
	"download_pdf" to "PDF жүктөп алуу",
	"draw_signature" to "Кол коюу",
	"due" to Formattable { (date) -> "Төлөө мөөнөтү ${date}" },
	"due_date" to "Бүтүмдүк дата",
	"due_day" to "күндө",
	"due_label" to "Төлөө",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ${dueWhen} күнү бүтөт!" },
	"due_today" to "Бүгүн",
	"due_week" to "бир жумада",
	"duplicate" to "Дубликат",
	"duration_day" to "күн",
	"duration_month" to "ай",
	"duration_week" to "апта",
	"duration_year" to "жыл",
	"email" to "Электрондук почта",
	"email_blank_error" to "Электрондук почта керек.",
	"email_error" to "Жараксыз электрондук почта дареги.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Сиздин бизнес үчүн рахмат. ${documentType} толук көрүү үчүн бул каттын аягындагы баскычты басыңыз. Ал ${dueDate} чейин жарактуу экенин эске алыңыз." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Сиз жаңысын алдыңыз${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} КӨРҮҮ" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Урматтуу ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Сиздин бизнес үчүн рахмат. ${documentType} толук көрүү үчүн бул каттын аягындагы баскычты басыңыз. Сураныч, балансты ${dueDate} чейин төлөңүз." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Сиз жаңысын алдыңыз${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "Сырсөздү калыбына келтирүү үчүн электрондук кат жөнөтүлдү, келүүчү кутуңузду текшериңиз.",
	"empty_clients_list_message" to "Көзөмөл кыла турган кардарларыңыз жок. Жаңы кардар кошуу.",
	"empty_estimate_list_message" to "Биринчи бааңызды түзүңүз",
	"empty_expenses" to "Көз салууга эч кандай чыгымыңыз жок. Жаңы чыгаша кошуу.",
	"empty_invoice_list_message" to "Биринчи эсеп-фактураңызды түзүңүз",
	"empty_items_list_message" to "Сизде эч нерсе жок, жаңы нерсе кошуңуз!",
	"empty_signatures_list_message" to "Колдор табылган жок. Бир түзүү!",
	"empty_taxes_list_message" to "Сизде салык эрежелери жок. Жаңы салык эрежесин кошуңуз.",
	"empty_templates_list_message" to "Калыптар жеткиликсиз.",
	"enabled" to "Иштетилди",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Болжолдуу №",
	"estimates" to "Сметалар",
	"estimates_report" to "Сметалар",
	"expense_bank_fees" to "Банк комиссиялары",
	"expense_benefits" to "Артыкчылыктары",
	"expense_communication" to "Байланыш",
	"expense_debt" to "Карыз",
	"expense_deleted" to "Чыгым өчүрүлдү",
	"expense_depreciation" to "Амортизация",
	"expense_gifts" to "Белектер",
	"expense_insurance" to "Камсыздандыруу",
	"expense_inventory" to "Инвентаризация",
	"expense_legal" to "Юридикалык",
	"expense_maintenance" to "Техникалык тейлөө",
	"expense_marketing" to "Маркетинг",
	"expense_meals" to "Тамак-аш",
	"expense_office_supplies" to "Office Supplies",
	"expense_rent" to "Ижарага алуу",
	"expense_salaries" to "Эмгек акылар",
	"expense_shopping" to "Соода",
	"expense_software" to "Программалык камсыздоо",
	"expense_status_available" to "жеткиликтүү",
	"expense_status_processing" to "Иштетүү",
	"expense_tax" to "Салык",
	"expense_training" to "Тренинг",
	"expense_transport" to "Транспорт",
	"expense_travel" to "Саякат",
	"expense_utility" to "Утилита",
	"expenses" to "Чыгымдар",
	"feedback_option" to "Пикирлер",
	"filter" to "Чыпка",
	"first_name_hint" to "Биринчи аты",
	"forgot_password" to "Сырсөздү кайра коюу",
	"forgot_password_label" to "Паролду унутуп калдыңыз",
	"from" to "From",
	"general_error" to "Ката кетти, бир аздан кийин кайталап көрүңүз.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Артка кайрыл",
	"group_by" to "Топ боюнча:",
	"image_view" to "Сүрөт",
	"install" to "Орнотуу",
	"invalid_credentials_error" to "Жараксыз эсептик дайындар.",
	"invalid_email_error" to "Жараксыз электрондук почта.",
	"invalid_user_error" to "Колдонуучунун эсептик дайындары жараксыз.",
	"invoice_deleted_message" to "Эсеп-фактура жок кылынды",
	"invoice_menu" to "Эсеп-фактура менюсу",
	"invoice_number" to "Эсеп-фактура №",
	"invoices" to "Эсеп-фактуралар",
	"items_option" to "Items",
	"keep" to "Сактоо",
	"language_selector" to Formattable { (selected) -> "Тил ${selected}" },
	"last_name_hint" to "Фамилия",
	"ledger" to "ledger",
	"legal" to "Юридикалык",
	"legal_error" to "Тейлөө шарттарын жана Купуялык саясатын кабыл алышы керек",
	"liabilities" to "liabilities",
	"loading" to "Жүктөө",
	"login_required_error" to "Кирүү талап кылынат.",
	"logo_view" to "Логотип",
	"logout" to "Чыгуу",
	"logout_prompt" to "Сиздин дайындар булут менен синхрондоштуруу эмес. Чыгуу учурунда эсеп-фактураларды, эсептерди, кардарларды жана башка маалымат жок кылынсынбы?",
	"main_screen" to "Негизги экран",
	"mark_paid" to "Төлөнгөн деп белгилөө",
	"message" to "билдирүү",
	"missing_sender_msg" to "Жөнөтүүчү маалыматы жок документ жараксыз болушу мүмкүн.",
	"missing_sender_title" to "Жөнөтүүчү маалымат жок",
	"month_april" to "Апрель",
	"month_april_short" to "Апрель",
	"month_august" to "Август",
	"month_august_short" to "август",
	"month_december" to "Декабрь",
	"month_december_short" to "декабрь",
	"month_february" to "Февраль",
	"month_february_short" to "февраль",
	"month_january" to "Январь",
	"month_january_short" to "январь.",
	"month_july" to "Июль",
	"month_july_short" to "Июль",
	"month_june" to "Июнь",
	"month_june_short" to "Июнь",
	"month_march" to "Март",
	"month_march_short" to "Март",
	"month_may" to "Май",
	"month_may_short" to "Май",
	"month_november" to "Ноябрь",
	"month_november_short" to "ноябрь",
	"month_october" to "Октябрь",
	"month_october_short" to "октябрь",
	"month_september" to "Сентябрь",
	"month_september_short" to "сентябрь",
	"multifactor_error" to "Текшерилбей калды.",
	"new" to "Жаңы",
	"news" to "Жаңылыктар",
	"next" to "Кийинки",
	"no" to "no",
	"no_data_available" to "Дайындар жок",
	"no_search_results" to "Кечиресиз, биз эч кандай жыйынтык таба алган жокпуз.",
	"none" to "Жок",
	"note_fields" to "Эскертүүлөр",
	"note_hint" to "Эскертүү",
	"notifications_option" to "Эскертмелер",
	"ok" to "Макул",
	"onboarding" to "Конууда",
	"onboarding_aboutus_message" to "DingVoiceтин негизги бөлүктөрү түбөлүккө акысыз. Биздин миссия - сизге бизнесиңизди өнүктүрүүгө жардам берүү, пикириңизди бөлүшүү аркылуу жакшыртууга жардам берүү.",
	"onboarding_aboutus_title" to "Биз жардам берүү үчүн бул жердебиз",
	"onboarding_paid_1" to "Профессионалдуу үлгүлөргө кириңиз",
	"onboarding_paid_2" to "Каржылооңузга тереңирээк көз салыңыз",
	"onboarding_paid_3" to "Көйгөйлөр тез чечилет",
	"onboarding_paid_4" to "Документтериңизди сактаңыз",
	"onboarding_paid_5" to "Эсеп-фактуранын абалын өзгөртүүлөрдөн кабардар болуңуз",
	"onboarding_paid_title" to "менен дагы тез өсө...",
	"onboarding_paywall_message" to "Бардык премиум функциялардан азыр эң жакшы баада ырахат алыңыз.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} үчүн ${currencySymbol}${introAmount}, андан кийин ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Жөн гана ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} акысыз, андан кийин жөн гана ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} акысыз, андан кийин ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}, андан кийин жөн гана ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Авто-жаңылануучу. Каалаган убакта жокко чыгарыңыз.",
	"onboarding_paywall_restore" to "Сатып алууну калыбына келтирүү",
	"onboarding_paywall_restore_failure" to "Сатып алуулар калыбына келтирилбей калды, кийинчерээк кайталап көрүңүз же колдоо кызматына кайрылыңыз.",
	"onboarding_paywall_restore_success" to "Ийгиликтүү калыбына келтириңиз!",
	"onboarding_paywall_title" to "Улуулукка жет!",
	"onboarding_welcome_message" to "Эсеп-фактураларыңызды жана сметаларды башкаруу үчүн толук куралдар топтому.",
	"onboarding_welcome_title" to "DingVoice кош келиңиз",
	"other_state" to "Башка",
	"overdue_state" to "Мөөнөтү өтүп кеткен",
	"paid_state" to "Төлөндү",
	"password" to "Купуя сөз",
	"password_blank_error" to "Сырсөз талап кылынат.",
	"password_mismatch_error" to "Сырсөздөр дал келбейт.",
	"pay" to "pay",
	"payment_instructions_label" to "Төлөм нускамалары",
	"payment_reminders" to "Төлөм эскертүүлөрү",
	"phone_number_fields" to "Тел номери",
	"phone_number_hint" to "Тел номери",
	"postcode_hint" to "Почта индекси",
	"preview" to "Алдын ала көрүү",
	"preview_menu_hint" to "Алдын ала көрүү менюсу",
	"price" to "Баасы",
	"privacy_policy" to "Купуялык саясаты",
	"profile" to "Профиль",
	"profitable_client" to "Сиздин эң кирешелүү кардарыңыз...",
	"profits_report" to "Пайда",
	"promo_content" to "Жарнамалык мазмун",
	"quantity_hint" to "Саны",
	"quantity_label_hint" to "Сандык белги",
	"query_info" to "Суроо элементтерге жана башка салык эрежелерине киргизүү катары колдонуучу эсептөөлөрдү жазууга мүмкүндүк берет. Башка жазууга +, -, *, /, % - математикалык операторлорду шилтемелөө үчүн @ белгисин жана нерсени же салыктын сүрөттөмөсүн кошуңуз",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} сиздин кардарыңыз тарабынан алынды." },
	"received_state" to "Кабыл алынган",
	"registration_code_fields" to "Катталуу коду",
	"reminder" to "Эскертүү",
	"reports" to "Отчеттор",
	"reports_not_available" to "Отчеттор жок, кийинчерээк кайтып келиңиз.",
	"reveal_password" to "Сырсөздү ачуу",
	"revenue" to "revenue",
	"sales_tax" to "КНС",
	"sales_tax_number" to "КНС номери",
	"save" to "Сактоо",
	"scan_document" to "Документти сканерлөө",
	"search" to "Издөө",
	"select" to "Тандоо",
	"select_icon" to "Тандоо сөлөкөтү",
	"select_signature" to "Кол коюуну тандаңыз",
	"select_template" to "Үлгү тандаңыз",
	"send" to "Жөнөтүү",
	"sent_state" to "Жөнөтүлдү",
	"setup_sender" to "Жөнөтүүчүнү орнотуу",
	"share_option" to "Бөлүшүү",
	"ship_to" to "жөнөтүү",
	"sign_in" to "Кирүү",
	"sign_in_apple" to "Apple менен кириңиз",
	"sign_in_email" to "Электрондук почта менен кириңиз",
	"sign_in_facebook" to "Facebook менен кирүү",
	"sign_in_google" to "Google менен кириңиз",
	"sign_up" to "Кирүү",
	"sign_up_label" to "Каттоо эсебиңиз жокпу?",
	"sign_up_link_label" to "Кирүү!",
	"signatures_option" to "Кол коюулар",
	"skip" to "Өткөрүп жибер",
	"sold_items_report" to "Сатылган буюмдар",
	"sort_by" to "Иреттөө:",
	"status_update" to "Документтин абалын жаңыртуу",
	"sub_annual" to "Жылдык",
	"sub_annual_price" to Formattable { (price) -> "${price}/жылына" },
	"sub_backups" to "Булуттагы камдык көчүрмөлөр",
	"sub_insights" to "Insights",
	"sub_month" to "Ай сайын",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ай" },
	"sub_notifications" to "Билдирмелер",
	"sub_popup_message" to "Жазылуулар жакында келет. Бир аздан кийин бул жерде кайра текшериңиз.",
	"sub_support" to "Колдоо",
	"sub_templates" to "Шаблондор",
	"subscribe" to "Жазылуу",
	"subscription_header" to "Төмөнкүлөр менен бизнесиңизди өстүрүңүз:",
	"subscription_option" to "Жазылуу",
	"subscriptions_load_fail" to "Кечиресиз, учурда жазылууларыңызды калыбына келтире албайбыз. Сураныч, кийинчерээк кайра аракет кылыңыз же жардам алуу үчүн колдоо тобубузга кайрылыңыз.",
	"subtotal" to Formattable { (sum) -> "Аралык сумма: ${sum}" },
	"subtotal_label" to "Аралык сумма",
	"sum" to "Сум",
	"support_option" to "Колдоо",
	"switch_reports" to "Отчетту которуу",
	"tax" to "Салык",
	"tax_deleted_message" to "Салык өчүрүлдү",
	"tax_description_warning" to "Эгер колдонулган болсо, бул эсеп-фактурада көрсөтүлөт",
	"tax_input_flat" to "Жалпак",
	"tax_input_percent" to "пайыз",
	"tax_number_fields" to "Салык номуру",
	"tax_number_hint" to "Салык номуру",
	"tax_query_info_description" to "Сурам маалымат",
	"tax_report" to "Салык",
	"tax_rules_option" to "Салык эрежелери",
	"taxed_clients_report" to "Салык салынган кардарлар",
	"template" to "Шаблон",
	"template_selector_info" to "Демейки шаблонду өзгөртүү үчүн серпиңиз",
	"template_unlock_promo" to "Premium менен көбүрөөк калыптарга кириңиз",
	"templates_option" to "Шаблондор",
	"terms_of_service" to "Тейлөө шарттары",
	"thank_you" to "Рахмат",
	"to" to "үчүн",
	"total" to Formattable { (sum) -> "Бардыгы: ${sum}" },
	"total_label" to "Бардыгы",
	"undo_action" to "Жок кылуу",
	"unit" to "бирдиги",
	"unit_cost_hint" to "Бирдиктин баасы",
	"unknown_client" to "Белгисиз кардар",
	"unlock" to "Кулпуну ачуу",
	"unnamed_item" to "Аты жок нерсе",
	"upgrade" to "Жаңыртуу",
	"user_collision_error" to "Каттоо эсеби мурунтан эле бар.",
	"valid_until" to "Чейин жарактуу",
	"warning" to "Эскертүү!",
	"yes" to "yes",
    )
    
}