// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class En  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Accept ${termsOfService} and ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Invalid code."
override val add_client_action: String = "Add client"
override val add_customer: String = "Add customer"
override val add_expense: String = "Add expense"
override val add_fields_label: String = "Add:"
override val add_invoice_action: String = "Create"
override val add_item_action: String = "Add item"
override val add_item_button: String = "Add item"
override val add_signature_action: String = "Add signature"
override val add_tax_action: String = "Add tax"
override val address_fields: String = "Address"
override val address_line_hint: String = "Address Line"
override val advanced: String = "Advanced"
override val agree_marketing: String = "I agree to receive Direct Marketing Communications"
override val all_notifications: String = "All Notifications"
override val amortization: String = "amortization"
override val amount: String = "Amount"
override val asset: String = "asset"
override val attach_file: String = "Attach File"
override val attachment: String = "Attachment"
override val auth_error: String = "Action failed, try again."
override val available_for_paid: String = "Cloud sync is available only under paid subscription."
override val backups_option: String = "Backups"
override val balance: String = "balance"
override val bank_account: String = "Bank Account"
override val bill_number: String = "Bill Number"
override val cancel: String = "Cancel"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Categories"
override val category: String = "Category"
override val category_add: String = "Add Category"
override val category_delete: String = "Delete category"
override val category_name: String = "Category name"
override val change_language: Formattable = Formattable { (language) -> "Invoice Language: ${language}" }
override val change_language_label: String = "Invoice Language"
override val change_template: String = "Change template"
override val city_hint: String = "City"
override val clear: String = "Clear"
override val client: String = "Client"
override val client_actions: String = "Client actions"
override val client_deleted_message: String = "Client deleted"
override val clients_error_required_fields: String = "At least one value is required."
override val clients_option: String = "Clients"
override val close: String = "Close"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "Coming soon!"
override val company_logo: String = "Company Logo"
override val company_name_hint: String = "Company"
override val confirm_password_label: String = "Confirm password"
override val confirmation_title: String = "Are you sure?"
override val convert_to_invoice: String = "Convert to invoice"
override val country_hint: String = "Country"
override val create_client: String = "Create Client"
override val create_company: String = "Create Company"
override val create_document: String = "Create document"
override val create_estimate: String = "Estimate"
override val create_invoice: String = "Invoice"
override val create_item: String = "Create Item"
override val create_new_client: String = "Create new client"
override val create_new_item: String = "Create new item"
override val create_new_tax: String = "Create new tax"
override val create_signature: String = "Signature"
override val created: String = "Created"
override val credit: String = "credit"
override val customer: String = "Customer"
override val date: String = "Date"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Default currency: ${currency}" }
override val default_currency_label: String = "Default currency"
override val delete: String = "Delete"
override val delete_account: String = "Delete Account"
override val delete_confirmation_message: String = "All of your data will be lost."
override val delete_item_title: String = "Delete the item?"
override val delete_signature: String = "Delete signature"
override val depreciation: String = "depreciation"
override val description: String = "Description"
override val description_hint: String = "Description"
override val detailed_report: String = "Detailed Report"
override val disabled: String = "Disabled"
override val discount: String = "Discount"
override val discount_hint: String = "Discount"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharic"
override val display_ar: String = "Arabic"
override val display_as: String = "Assamese"
override val display_az: String = "Azerbaijani"
override val display_be: String = "Belarusian"
override val display_bg: String = "Bulgarian"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosnian"
override val display_ca: String = "Catalan"
override val display_cs: String = "Czech"
override val display_da: String = "Danish"
override val display_de: String = "German"
override val display_el: String = "Greek"
override val display_en: String = "English"
override val display_es: String = "Spanish"
override val display_es_MX: String = "Spanish (Mexico)"
override val display_et: String = "Estonian"
override val display_eu: String = "Basque"
override val display_fa: String = "Persian"
override val display_fi: String = "Finnish"
override val display_fil: String = "Filipino"
override val display_fr: String = "French"
override val display_fr_CA: String = "Canadian French"
override val display_gl: String = "Galician"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hebrew"
override val display_hi: String = "Hindi"
override val display_hr: String = "Croatian"
override val display_hu: String = "Hungarian"
override val display_hy: String = "Armenian"
override val display_id: String = "Indonesian"
override val display_is: String = "Icelandic"
override val display_it: String = "Italian"
override val display_ja: String = "Japanese"
override val display_ka: String = "Georgian"
override val display_kk: String = "Kazakh"
override val display_km: String = "Central Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korean"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Lithuanian"
override val display_lv: String = "Latvian"
override val display_mk: String = "Macedonian"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolian"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malay"
override val display_my: String = "Burmese"
override val display_nb: String = "Norwegian Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Dutch"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polish"
override val display_pt: String = "Portuguese"
override val display_pt_BR: String = "Portuguese (Brazil)"
override val display_pt_PT: String = "European Portuguese"
override val display_ro: String = "Romanian"
override val display_ru: String = "Russian"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovak"
override val display_sl: String = "Slovenian"
override val display_sq: String = "Albanian"
override val display_sr: String = "Serbian"
override val display_sv: String = "Swedish"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Turkish"
override val display_uk: String = "Ukrainian"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbek"
override val display_vi: String = "Vietnamese"
override val display_zh_Hant_TW: String = "Chinese (Taiwan, Traditional)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Estimate"
override val document_invoice: String = "Invoice"
override val document_quote: String = "Quote"
override val done: String = "Done"
override val download_app: String = "Download the app"
override val download_invoice: String = "Download Invoice"
override val download_pdf: String = "Download PDF"
override val draw_signature: String = "Draw a signature"
override val due: Formattable = Formattable { (date) -> "Due ${date}" }
override val due_date: String = "Due Date"
override val due_day: String = "in a day"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} is due ${dueWhen}!" }
override val due_today: String = "today"
override val due_week: String = "in a week"
override val duplicate: String = "Duplicate"
override val duration_day: String = "day"
override val duration_month: String = "month"
override val duration_week: String = "week"
override val duration_year: String = "year"
override val email: String = "Email"
override val email_blank_error: String = "Email required."
override val email_error: String = "Invalid email address."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Thank you for your business. To view full ${documentType} click the button at the end of this letter. Note it is valid till ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "You have received a new ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "VIEW ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Dear ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Thank you for your business. To view full ${documentType} click the button at the end of this letter. Please pay the balance till ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "You have received a new ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Email to reset password sent, please check your inbox."
override val empty_clients_list_message: String = "You have no clients to track. Add a new client."
override val empty_estimate_list_message: String = "Create your first estimate"
override val empty_expenses: String = "You have no expenses to track. Add a new expense."
override val empty_invoice_list_message: String = "Create your first invoice"
override val empty_items_list_message: String = "You have no items, add a new item!"
override val empty_signatures_list_message: String = "No signatures found. Create one!"
override val empty_taxes_list_message: String = "You have no tax rules. Add a new tax rule."
override val empty_templates_list_message: String = "Templates unavailable."
override val enabled: String = "Enabled"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Estimate #"
override val estimates: String = "Estimates"
override val estimates_report: String = "Estimates"
override val expense_bank_fees: String = "Bank Fees"
override val expense_benefits: String = "Benefits"
override val expense_communication: String = "Communication"
override val expense_debt: String = "Debt"
override val expense_deleted: String = "Expense deleted"
override val expense_depreciation: String = "Depreciation"
override val expense_gifts: String = "Gifts"
override val expense_insurance: String = "Insurance"
override val expense_inventory: String = "Inventory"
override val expense_legal: String = "Legal"
override val expense_maintenance: String = "Maintenance"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Meals"
override val expense_office_supplies: String = "Office Supplies"
override val expense_rent: String = "Rent"
override val expense_salaries: String = "Salaries"
override val expense_shopping: String = "Shopping"
override val expense_software: String = "Software"
override val expense_status_available: String = "Available"
override val expense_status_processing: String = "Processing"
override val expense_tax: String = "Tax"
override val expense_training: String = "Training"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Travel"
override val expense_utility: String = "Utility"
override val expenses: String = "Expenses"
override val feedback_option: String = "Feedback"
override val filter: String = "Filter"
override val first_name_hint: String = "First Name"
override val forgot_password: String = "Reset password"
override val forgot_password_label: String = "Forgot password"
override val from: String = "From"
override val general_error: String = "Error occured, please try again later."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Go back"
override val group_by: String = "Group By:"
override val image_view: String = "Image"
override val install: String = "Install"
override val invalid_credentials_error: String = "Invalid credentials."
override val invalid_email_error: String = "Invalid email."
override val invalid_user_error: String = "Invalid user credentials."
override val invoice_deleted_message: String = "Invoice deleted"
override val invoice_menu: String = "Invoice menu"
override val invoice_number: String = "Invoice #"
override val invoices: String = "Invoices"
override val items_option: String = "Items"
override val keep: String = "Keep"
override val language_selector: Formattable = Formattable { (selected) -> "Language ${selected}" }
override val last_name_hint: String = "Last Name"
override val ledger: String = "ledger"
override val legal: String = "Legal"
override val legal_error: String = "Must accept Terms of Service and Privacy Policy"
override val liabilities: String = "liabilities"
override val loading: String = "Loading"
override val login_required_error: String = "Login required."
override val logo_view: String = "Logo"
override val logout: String = "Logout"
override val logout_prompt: String = "Your data is not synchronized with cloud. Delete invoices, estimates, clients & other information on logout?"
override val main_screen: String = "Main screen"
override val mark_paid: String = "Mark as Paid"
override val message: String = "Message"
override val missing_sender_msg: String = "A document without sender information might be invalid."
override val missing_sender_title: String = "Missing sender information"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "August"
override val month_august_short: String = "Aug."
override val month_december: String = "December"
override val month_december_short: String = "Dec."
override val month_february: String = "February"
override val month_february_short: String = "Feb."
override val month_january: String = "January"
override val month_january_short: String = "Jan."
override val month_july: String = "July"
override val month_july_short: String = "July"
override val month_june: String = "June"
override val month_june_short: String = "June"
override val month_march: String = "March"
override val month_march_short: String = "March"
override val month_may: String = "May"
override val month_may_short: String = "May"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "October"
override val month_october_short: String = "Oct."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Failed to verify."
override val new: String = "New"
override val news: String = "News"
override val next: String = "Next"
override val no: String = "no"
override val no_data_available: String = "No data available"
override val no_search_results: String = "Sorry, we couldn\'t find any results."
override val none: String = "None"
override val note_fields: String = "Notes"
override val note_hint: String = "Note"
override val notifications_option: String = "Notifications"
override val ok: String = "Ok"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Essential parts of DingVoice are forever free. Our mission is to help you grow your business, help us improve by sharing your feedback."
override val onboarding_aboutus_title: String = "We are here to help"
override val onboarding_paid_1: String = "Access professional looking templates"
override val onboarding_paid_2: String = "Take a deeper look into your financials"
override val onboarding_paid_3: String = "Get problems resolved fast"
override val onboarding_paid_4: String = "Keep your documents safe"
override val onboarding_paid_5: String = "Stay up to date with invoice status changes"
override val onboarding_paid_6: String = "Uninterrupted use of latest features"
override val onboarding_paid_title: String = "Grow even faster with..."
override val onboarding_paywall_message: String = "Enjoy all premium features now at the best price."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Just ${currencySymbol}${introAmount} for ${introDuration} ${introPeriod} and then ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Just ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} and then just ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} then ${currencySymbol}${introductoryAmount} for ${introductoryDuration} ${introPeriod} and then just ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Auto-renewable. Cancel anytime."
override val onboarding_paywall_restore: String = "Restore purchase"
override val onboarding_paywall_restore_failure: String = "Restoring purchases has failed, try again later or contact support."
override val onboarding_paywall_restore_success: String = "Restore successful!"
override val onboarding_paywall_title: String = "Achieve Greatness!"
override val onboarding_welcome_message: String = "A complete toolset to manage your invoices & estimates."
override val onboarding_welcome_title: String = "Welcome to DingVoice"
override val other_state: String = "Other"
override val overdue_state: String = "Overdue"
override val paid_state: String = "Paid"
override val password: String = "Password"
override val password_blank_error: String = "Password required."
override val password_mismatch_error: String = "Passwords do not match."
override val pay: String = "pay"
override val payment_instructions_label: String = "Payment Instructions"
override val payment_reminders: String = "Payment reminders"
override val phone_number_fields: String = "Phone number"
override val phone_number_hint: String = "Phone Number"
override val postcode_hint: String = "Postcode"
override val preview: String = "Preview"
override val preview_menu_hint: String = "Preview menu"
override val price: String = "Price"
override val privacy_policy: String = "Privacy Policy"
override val profile: String = "Profile"
override val profitable_client: String = "Your most profitable client is..."
override val profits_report: String = "Profits"
override val promo_content: String = "Promotional content"
override val quantity_hint: String = "Quantity"
override val quantity_label_hint: String = "Quantity Label"
override val query_info: String = "Query allows you to write custom calculations referring to both items and other tax rules as inputs. Add @ symbol and item or tax description to link to another entry +, -, *, /, % -  math operators"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} was received by your client." }
override val received_state: String = "Received"
override val registration_code_fields: String = "Registration Code"
override val reminder: String = "Reminder"
override val reports: String = "Reports"
override val reports_not_available: String = "No reports available, come back later."
override val reveal_password: String = "Reveal password"
override val revenue: String = "revenue"
override val sales_tax: String = "VAT"
override val sales_tax_number: String = "VAT number"
override val save: String = "Save"
override val scan_document: String = "Scan document"
override val search: String = "Search"
override val select: String = "Select"
override val select_icon: String = "Select icon"
override val select_signature: String = "Select signature"
override val select_template: String = "Select template"
override val send: String = "Send"
override val sent_state: String = "Sent"
override val setup_sender: String = "Set up sender"
override val share_option: String = "Share"
override val ship_to: String = "Ship to"
override val sign_in: String = "Sign In"
override val sign_in_apple: String = "Sign in with Apple"
override val sign_in_email: String = "Sign in with email"
override val sign_in_facebook: String = "Sign in with Facebook"
override val sign_in_google: String = "Sign in with Google"
override val sign_up: String = "Sign Up"
override val sign_up_label: String = "Don't have an account?"
override val sign_up_link_label: String = "Sign Up!"
override val signatures_option: String = "Signatures"
override val skip: String = "Skip"
override val sold_items_report: String = "Sold Items"
override val sort_by: String = "Sort By:"
override val status_update: String = "Document status update"
override val sub_annual: String = "Annual"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/year" }
override val sub_backups: String = "Cloud Backups"
override val sub_insights: String = "Insights"
override val sub_month: String = "Monthly"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/month" }
override val sub_notifications: String = "Notifications"
override val sub_popup_message: String = "Subscriptions are coming soon. Check back here in a little while."
override val sub_support: String = "Support"
override val sub_templates: String = "Templates"
override val subscribe: String = "Subscribe"
override val subscription_header: String = "Grow your business with:"
override val subscription_option: String = "Subscription"
override val subscriptions_load_fail: String = "Sorry, we're unable to retrieve your subscriptions at this time. Please try again later or contact our support team for assistance."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "Subtotal"
override val sum: String = "Sum"
override val support_option: String = "Support"
override val switch_reports: String = "Switch Report"
override val tax: String = "Tax"
override val tax_deleted_message: String = "Tax deleted"
override val tax_description_warning: String = "If used this shows up on an invoice"
override val tax_input_flat: String = "Flat"
override val tax_input_percent: String = "Percent"
override val tax_number_fields: String = "Tax number"
override val tax_number_hint: String = "Tax Number"
override val tax_query_info_description: String = "Query Info"
override val tax_report: String = "Tax"
override val tax_rules_option: String = "Tax Rules"
override val taxed_clients_report: String = "Taxed Clients"
override val template: String = "Template"
override val template_selector_info: String = "Swipe to change the default template"
override val template_unlock_promo: String = "Access more templates with Premium"
override val templates_option: String = "Templates"
override val terms_of_service: String = "Terms of Service"
override val thank_you: String = "Thank You"
override val to: String = "To"
override val total: Formattable = Formattable { (sum) -> "Total: ${sum}" }
override val total_label: String = "Total"
override val undo_action: String = "Undo"
override val unit: String = "Unit"
override val unit_cost_hint: String = "Unit Cost"
override val unknown_client: String = "Unknown Client"
override val unlock: String = "Unlock"
override val unnamed_item: String = "Unnamed item"
override val upgrade: String = "Upgrade"
override val user_collision_error: String = "Account already exists."
override val valid_until: String = "Valid Until"
override val warning: String = "Warning!"
override val yes: String = "yes"
override val no_ads: String = "No advertisement"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Accept ${termsOfService} and ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Invalid code.",
	"add_client_action" to "Add client",
	"add_customer" to "Add customer",
	"add_expense" to "Add expense",
	"add_fields_label" to "Add:",
	"add_invoice_action" to "Create",
	"add_item_action" to "Add item",
	"add_item_button" to "Add item",
	"add_signature_action" to "Add signature",
	"add_tax_action" to "Add tax",
	"address_fields" to "Address",
	"address_line_hint" to "Address Line",
	"advanced" to "Advanced",
	"agree_marketing" to "I agree to receive Direct Marketing Communications",
	"all_notifications" to "All Notifications",
	"amortization" to "amortization",
	"amount" to "Amount",
	"asset" to "asset",
	"attach_file" to "Attach File",
	"attachment" to "Attachment",
	"auth_error" to "Action failed, try again.",
	"available_for_paid" to "Cloud sync is available only under paid subscription.",
	"backups_option" to "Backups",
	"balance" to "balance",
	"bank_account" to "Bank Account",
	"bill_number" to "Bill Number",
	"cancel" to "Cancel",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Categories",
	"category" to "Category",
	"category_add" to "Add Category",
	"category_delete" to "Delete category",
	"category_name" to "Category name",
	"change_language" to Formattable { (language) -> "Invoice Language: ${language}" },
	"change_language_label" to "Invoice Language",
	"change_template" to "Change template",
	"city_hint" to "City",
	"clear" to "Clear",
	"client" to "Client",
	"client_actions" to "Client actions",
	"client_deleted_message" to "Client deleted",
	"clients_error_required_fields" to "At least one value is required.",
	"clients_option" to "Clients",
	"close" to "Close",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "Coming soon!",
	"company_logo" to "Company Logo",
	"company_name_hint" to "Company",
	"confirm_password_label" to "Confirm password",
	"confirmation_title" to "Are you sure?",
	"convert_to_invoice" to "Convert to invoice",
	"country_hint" to "Country",
	"create_client" to "Create Client",
	"create_company" to "Create Company",
	"create_document" to "Create document",
	"create_estimate" to "Estimate",
	"create_invoice" to "Invoice",
	"create_item" to "Create Item",
	"create_new_client" to "Create new client",
	"create_new_item" to "Create new item",
	"create_new_tax" to "Create new tax",
	"create_signature" to "Signature",
	"created" to "Created",
	"credit" to "credit",
	"customer" to "Customer",
	"date" to "Date",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Default currency: ${currency}" },
	"default_currency_label" to "Default currency",
	"delete" to "Delete",
	"delete_account" to "Delete Account",
	"delete_confirmation_message" to "All of your data will be lost.",
	"delete_item_title" to "Delete the item?",
	"delete_signature" to "Delete signature",
	"depreciation" to "depreciation",
	"description" to "Description",
	"description_hint" to "Description",
	"detailed_report" to "Detailed Report",
	"disabled" to "Disabled",
	"discount" to "Discount",
	"discount_hint" to "Discount",
	"display_af" to "Afrikaans",
	"display_am" to "Amharic",
	"display_ar" to "Arabic",
	"display_as" to "Assamese",
	"display_az" to "Azerbaijani",
	"display_be" to "Belarusian",
	"display_bg" to "Bulgarian",
	"display_bn" to "Bengali",
	"display_bs" to "Bosnian",
	"display_ca" to "Catalan",
	"display_cs" to "Czech",
	"display_da" to "Danish",
	"display_de" to "German",
	"display_el" to "Greek",
	"display_en" to "English",
	"display_es" to "Spanish",
	"display_es_MX" to "Spanish (Mexico)",
	"display_et" to "Estonian",
	"display_eu" to "Basque",
	"display_fa" to "Persian",
	"display_fi" to "Finnish",
	"display_fil" to "Filipino",
	"display_fr" to "French",
	"display_fr_CA" to "Canadian French",
	"display_gl" to "Galician",
	"display_gu" to "Gujarati",
	"display_he" to "Hebrew",
	"display_hi" to "Hindi",
	"display_hr" to "Croatian",
	"display_hu" to "Hungarian",
	"display_hy" to "Armenian",
	"display_id" to "Indonesian",
	"display_is" to "Icelandic",
	"display_it" to "Italian",
	"display_ja" to "Japanese",
	"display_ka" to "Georgian",
	"display_kk" to "Kazakh",
	"display_km" to "Central Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Korean",
	"display_ky" to "Kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Lithuanian",
	"display_lv" to "Latvian",
	"display_mk" to "Macedonian",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolian",
	"display_mr" to "Marathi",
	"display_ms" to "Malay",
	"display_my" to "Burmese",
	"display_nb" to "Norwegian Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Dutch",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polish",
	"display_pt" to "Portuguese",
	"display_pt_BR" to "Portuguese (Brazil)",
	"display_pt_PT" to "European Portuguese",
	"display_ro" to "Romanian",
	"display_ru" to "Russian",
	"display_si" to "Sinhala",
	"display_sk" to "Slovak",
	"display_sl" to "Slovenian",
	"display_sq" to "Albanian",
	"display_sr" to "Serbian",
	"display_sv" to "Swedish",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Turkish",
	"display_uk" to "Ukrainian",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbek",
	"display_vi" to "Vietnamese",
	"display_zh_Hant_TW" to "Chinese (Taiwan, Traditional)",
	"display_zu" to "Zulu",
	"document_estimate" to "Estimate",
	"document_invoice" to "Invoice",
	"document_quote" to "Quote",
	"done" to "Done",
	"download_app" to "Download the app",
	"download_invoice" to "Download Invoice",
	"download_pdf" to "Download PDF",
	"draw_signature" to "Draw a signature",
	"due" to Formattable { (date) -> "Due ${date}" },
	"due_date" to "Due Date",
	"due_day" to "in a day",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} is due ${dueWhen}!" },
	"due_today" to "today",
	"due_week" to "in a week",
	"duplicate" to "Duplicate",
	"duration_day" to "day",
	"duration_month" to "month",
	"duration_week" to "week",
	"duration_year" to "year",
	"email" to "Email",
	"email_blank_error" to "Email required.",
	"email_error" to "Invalid email address.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Thank you for your business. To view full ${documentType} click the button at the end of this letter. Note it is valid till ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "You have received a new ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "VIEW ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Dear ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Thank you for your business. To view full ${documentType} click the button at the end of this letter. Please pay the balance till ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "You have received a new ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Email to reset password sent, please check your inbox.",
	"empty_clients_list_message" to "You have no clients to track. Add a new client.",
	"empty_estimate_list_message" to "Create your first estimate",
	"empty_expenses" to "You have no expenses to track. Add a new expense.",
	"empty_invoice_list_message" to "Create your first invoice",
	"empty_items_list_message" to "You have no items, add a new item!",
	"empty_signatures_list_message" to "No signatures found. Create one!",
	"empty_taxes_list_message" to "You have no tax rules. Add a new tax rule.",
	"empty_templates_list_message" to "Templates unavailable.",
	"enabled" to "Enabled",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Estimate #",
	"estimates" to "Estimates",
	"estimates_report" to "Estimates",
	"expense_bank_fees" to "Bank Fees",
	"expense_benefits" to "Benefits",
	"expense_communication" to "Communication",
	"expense_debt" to "Debt",
	"expense_deleted" to "Expense deleted",
	"expense_depreciation" to "Depreciation",
	"expense_gifts" to "Gifts",
	"expense_insurance" to "Insurance",
	"expense_inventory" to "Inventory",
	"expense_legal" to "Legal",
	"expense_maintenance" to "Maintenance",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Meals",
	"expense_office_supplies" to "Office Supplies",
	"expense_rent" to "Rent",
	"expense_salaries" to "Salaries",
	"expense_shopping" to "Shopping",
	"expense_software" to "Software",
	"expense_status_available" to "Available",
	"expense_status_processing" to "Processing",
	"expense_tax" to "Tax",
	"expense_training" to "Training",
	"expense_transport" to "Transport",
	"expense_travel" to "Travel",
	"expense_utility" to "Utility",
	"expenses" to "Expenses",
	"feedback_option" to "Feedback",
	"filter" to "Filter",
	"first_name_hint" to "First Name",
	"forgot_password" to "Reset password",
	"forgot_password_label" to "Forgot password",
	"from" to "From",
	"general_error" to "Error occured, please try again later.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Go back",
	"group_by" to "Group By:",
	"image_view" to "Image",
	"install" to "Install",
	"invalid_credentials_error" to "Invalid credentials.",
	"invalid_email_error" to "Invalid email.",
	"invalid_user_error" to "Invalid user credentials.",
	"invoice_deleted_message" to "Invoice deleted",
	"invoice_menu" to "Invoice menu",
	"invoice_number" to "Invoice #",
	"invoices" to "Invoices",
	"items_option" to "Items",
	"keep" to "Keep",
	"language_selector" to Formattable { (selected) -> "Language ${selected}" },
	"last_name_hint" to "Last Name",
	"ledger" to "ledger",
	"legal" to "Legal",
	"legal_error" to "Must accept Terms of Service and Privacy Policy",
	"liabilities" to "liabilities",
	"loading" to "Loading",
	"login_required_error" to "Login required.",
	"logo_view" to "Logo",
	"logout" to "Logout",
	"logout_prompt" to "Your data is not synchronized with cloud. Delete invoices, estimates, clients & other information on logout?",
	"main_screen" to "Main screen",
	"mark_paid" to "Mark as Paid",
	"message" to "Message",
	"missing_sender_msg" to "A document without sender information might be invalid.",
	"missing_sender_title" to "Missing sender information",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "August",
	"month_august_short" to "Aug.",
	"month_december" to "December",
	"month_december_short" to "Dec.",
	"month_february" to "February",
	"month_february_short" to "Feb.",
	"month_january" to "January",
	"month_january_short" to "Jan.",
	"month_july" to "July",
	"month_july_short" to "July",
	"month_june" to "June",
	"month_june_short" to "June",
	"month_march" to "March",
	"month_march_short" to "March",
	"month_may" to "May",
	"month_may_short" to "May",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "October",
	"month_october_short" to "Oct.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Failed to verify.",
	"new" to "New",
	"news" to "News",
	"next" to "Next",
	"no" to "no",
	"no_data_available" to "No data available",
	"no_search_results" to "Sorry, we couldn\'t find any results.",
	"none" to "None",
	"note_fields" to "Notes",
	"note_hint" to "Note",
	"notifications_option" to "Notifications",
	"ok" to "Ok",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Essential parts of DingVoice are forever free. Our mission is to help you grow your business, help us improve by sharing your feedback.",
	"onboarding_aboutus_title" to "We are here to help",
	"onboarding_paid_1" to "Access professional looking templates",
	"onboarding_paid_2" to "Take a deeper look into your financials",
	"onboarding_paid_3" to "Get problems resolved fast",
	"onboarding_paid_4" to "Keep your documents safe",
	"onboarding_paid_5" to "Stay up to date with invoice status changes",
	"onboarding_paid_6" to "Uninterrupted use of latest features",
	"onboarding_paid_title" to "Grow even faster with...",
	"onboarding_paywall_message" to "Enjoy all premium features now at the best price.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Just ${currencySymbol}${introAmount} for ${introDuration} ${introPeriod} and then ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Just ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} and then just ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} then ${currencySymbol}${introductoryAmount} for ${introductoryDuration} ${introPeriod} and then just ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Auto-renewable. Cancel anytime.",
	"onboarding_paywall_restore" to "Restore purchase",
	"onboarding_paywall_restore_failure" to "Restoring purchases has failed, try again later or contact support.",
	"onboarding_paywall_restore_success" to "Restore successful!",
	"onboarding_paywall_title" to "Achieve Greatness!",
	"onboarding_welcome_message" to "A complete toolset to manage your invoices & estimates.",
	"onboarding_welcome_title" to "Welcome to DingVoice",
	"other_state" to "Other",
	"overdue_state" to "Overdue",
	"paid_state" to "Paid",
	"password" to "Password",
	"password_blank_error" to "Password required.",
	"password_mismatch_error" to "Passwords do not match.",
	"pay" to "pay",
	"payment_instructions_label" to "Payment Instructions",
	"payment_reminders" to "Payment reminders",
	"phone_number_fields" to "Phone number",
	"phone_number_hint" to "Phone Number",
	"postcode_hint" to "Postcode",
	"preview" to "Preview",
	"preview_menu_hint" to "Preview menu",
	"price" to "Price",
	"privacy_policy" to "Privacy Policy",
	"profile" to "Profile",
	"profitable_client" to "Your most profitable client is...",
	"profits_report" to "Profits",
	"promo_content" to "Promotional content",
	"quantity_hint" to "Quantity",
	"quantity_label_hint" to "Quantity Label",
	"query_info" to "Query allows you to write custom calculations referring to both items and other tax rules as inputs. Add @ symbol and item or tax description to link to another entry +, -, *, /, % -  math operators",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} was received by your client." },
	"received_state" to "Received",
	"registration_code_fields" to "Registration Code",
	"reminder" to "Reminder",
	"reports" to "Reports",
	"reports_not_available" to "No reports available, come back later.",
	"reveal_password" to "Reveal password",
	"revenue" to "revenue",
	"sales_tax" to "VAT",
	"sales_tax_number" to "VAT number",
	"save" to "Save",
	"scan_document" to "Scan document",
	"search" to "Search",
	"select" to "Select",
	"select_icon" to "Select icon",
	"select_signature" to "Select signature",
	"select_template" to "Select template",
	"send" to "Send",
	"sent_state" to "Sent",
	"setup_sender" to "Set up sender",
	"share_option" to "Share",
	"ship_to" to "Ship to",
	"sign_in" to "Sign In",
	"sign_in_apple" to "Sign in with Apple",
	"sign_in_email" to "Sign in with email",
	"sign_in_facebook" to "Sign in with Facebook",
	"sign_in_google" to "Sign in with Google",
	"sign_up" to "Sign Up",
	"sign_up_label" to "Don't have an account?",
	"sign_up_link_label" to "Sign Up!",
	"signatures_option" to "Signatures",
	"skip" to "Skip",
	"sold_items_report" to "Sold Items",
	"sort_by" to "Sort By:",
	"status_update" to "Document status update",
	"sub_annual" to "Annual",
	"sub_annual_price" to Formattable { (price) -> "${price}/year" },
	"sub_backups" to "Cloud Backups",
	"sub_insights" to "Insights",
	"sub_month" to "Monthly",
	"sub_monthly_price" to Formattable { (price) -> "${price}/month" },
	"sub_notifications" to "Notifications",
	"sub_popup_message" to "Subscriptions are coming soon. Check back here in a little while.",
	"sub_support" to "Support",
	"sub_templates" to "Templates",
	"subscribe" to "Subscribe",
	"subscription_header" to "Grow your business with:",
	"subscription_option" to "Subscription",
	"subscriptions_load_fail" to "Sorry, we're unable to retrieve your subscriptions at this time. Please try again later or contact our support team for assistance.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "Subtotal",
	"sum" to "Sum",
	"support_option" to "Support",
	"switch_reports" to "Switch Report",
	"tax" to "Tax",
	"tax_deleted_message" to "Tax deleted",
	"tax_description_warning" to "If used this shows up on an invoice",
	"tax_input_flat" to "Flat",
	"tax_input_percent" to "Percent",
	"tax_number_fields" to "Tax number",
	"tax_number_hint" to "Tax Number",
	"tax_query_info_description" to "Query Info",
	"tax_report" to "Tax",
	"tax_rules_option" to "Tax Rules",
	"taxed_clients_report" to "Taxed Clients",
	"template" to "Template",
	"template_selector_info" to "Swipe to change the default template",
	"template_unlock_promo" to "Access more templates with Premium",
	"templates_option" to "Templates",
	"terms_of_service" to "Terms of Service",
	"thank_you" to "Thank You",
	"to" to "To",
	"total" to Formattable { (sum) -> "Total: ${sum}" },
	"total_label" to "Total",
	"undo_action" to "Undo",
	"unit" to "Unit",
	"unit_cost_hint" to "Unit Cost",
	"unknown_client" to "Unknown Client",
	"unlock" to "Unlock",
	"unnamed_item" to "Unnamed item",
	"upgrade" to "Upgrade",
	"user_collision_error" to "Account already exists.",
	"valid_until" to "Valid Until",
	"warning" to "Warning!",
	"yes" to "yes",
	"no_ads" to "No advertisement",
    )
    
}