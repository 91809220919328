package com.dingvoice.style

import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.auto

@OptIn(ExperimentalComposeWebApi::class)
object WtContainer : StyleSheet(AppStylesheet) {
    val wtContainer by style {
        property("margin-left", "auto")
        property("margin-right", "auto")
        boxSizing("border-box")
        paddingLeft(22.px)
        paddingRight(22.px)

        media(mediaMaxWidth(640.px)) {
            self style {
                maxWidth(100.percent)
                paddingLeft(16.px)
                paddingRight(16.px)
            }
        }

        media(mediaMaxWidth(1276.px)) {
            self style {
                maxWidth(996.px)
                paddingLeft(22.px)
                paddingRight(22.px)
            }
        }

        media(mediaMaxWidth(1000.px)) {
            self style {
                maxWidth(100.percent)
                paddingLeft(22.px)
                paddingRight(22.px)
            }
        }
    }

    val readerContainer by style {
        marginTop(82.px)
        marginLeft(200.px)
        marginRight(200.px)
        width(70.percent)
    }

    val readerContainerMobile by style {
        marginTop(0.px)
        marginLeft(0.px)
        marginRight(0.px)
        transform { scale(0.5f, 0.5f) }
        width(200.percent)
        property("-moz-transform-origin", "top left")
        property("-webkit-transform-origin", "top left")
        property("-o-transform-origin", "top left")
        property("-ms-transform-origin", "top left")
        property("transform-origin", "top left")
    }

    val readerFooterContainer by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        marginLeft(200.px)
        marginTop(80.px)
        marginRight(200.px)
        paddingBottom(80.px)
    }

    val readerFooterContainerMobile by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        marginLeft(12.px)
        marginTop(80.px)
        marginRight(12.px)
        paddingBottom(20.px)
    }

    val dropdownOptionsContainer by style {
        color(Color("#7E7E7E"))
        height(300.px)
        overflow("auto")
    }

    val dropdownContainer by style {
        marginTop(16.px)
        marginLeft(60.px)
        alignSelf(AlignSelf.Center)
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
    }

    val dropdownContainerMobile by style {
        marginTop(4.px)
        alignSelf(AlignSelf.Center)
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
    }

    val iconContainer by style {
        alignSelf(AlignSelf.Start)
        maxWidth(39.px)
        maxHeight(39.px)
        width(auto)
        height(auto)
    }

    val iconContainerMobile by style {
        alignSelf(AlignSelf.Center)
        maxWidth(19.px)
        maxHeight(19.px)
        width(auto)
        height(auto)
    }
}
