package com.dingvoice.style

import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.value

object WtSections : StyleSheet(AppStylesheet) {

    val wtSection by style {
        boxSizing("border-box")
        paddingBottom(96.px)
        paddingTop(1.px)
        property(
            propertyName = "padding-bottom",
            value = "calc(4*${AppCSSVariables.wtOffsetTopUnit.value(24.px)})"
        )
        backgroundColor(Color("#fff"))
    }

    val wtSectionBgBrand by style {
        backgroundColor(Color("#6a1b9a"))
        backgroundColor(AppCSSVariables.wtColorBrand.value())
    }
}
