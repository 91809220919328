// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ka  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "მიიღეთ ${termsOfService} და ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "არასწორი კოდი."
override val add_client_action: String = "კლიენტის დამატება"
override val add_customer: String = "მომხმარებლის დამატება"
override val add_expense: String = "დაამატეთ ხარჯები"
override val add_fields_label: String = "დამატება:"
override val add_invoice_action: String = "შექმნა"
override val add_item_action: String = "ელემენტის დამატება"
override val add_item_button: String = "ელემენტის დამატება"
override val add_signature_action: String = "ხელმოწერის დამატება"
override val add_tax_action: String = "გადასახადის დამატება"
override val address_fields: String = "მისამართი"
override val address_line_hint: String = "მისამართის ხაზი"
override val advanced: String = "გაფართოებული"
override val agree_marketing: String = "ვეთანხმები პირდაპირი მარკეტინგული კომუნიკაციების მიღებას"
override val all_notifications: String = "ყველა შეტყობინება"
override val amortization: String = "amortization"
override val amount: String = "თანხა"
override val asset: String = "asset"
override val attach_file: String = "Ფაილის მიმაგრება"
override val attachment: String = "დანართი"
override val auth_error: String = "აქცია ვერ მოხერხდა, კვლავ სცადეთ."
override val available_for_paid: String = "Cloud sync ხელმისაწვდომია მხოლოდ ფასიანი გამოწერის ქვეშ."
override val backups_option: String = "სარეზერვო"
override val balance: String = "balance"
override val bank_account: String = "Საბანკო ანგარიში"
override val bill_number: String = "კანონპროექტის ნომერი"
override val cancel: String = "გაუქმება"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "კატეგორიები"
override val category: String = "კატეგორია"
override val category_add: String = "დაამატეთ კატეგორია"
override val category_delete: String = "კატეგორიის წაშლა"
override val category_name: String = "კატეგორიის სახელი"
override val change_language: Formattable = Formattable { (language) -> "ინვოისის ენა: ${language}" }
override val change_language_label: String = "ინვოისის ენა"
override val change_template: String = "თარგის შეცვლა"
override val city_hint: String = "ქალაქი"
override val clear: String = "წმინდა"
override val client: String = "კლიენტი"
override val client_actions: String = "კლიენტის ქმედებები"
override val client_deleted_message: String = "კლიენტი წაშლილია"
override val clients_error_required_fields: String = "საჭიროა მინიმუმ ერთი მნიშვნელობა."
override val clients_option: String = "კლიენტები"
override val close: String = "დახურვა"
override val cloud_sync: String = "ღრუბლოვანი სინქრონიზაცია"
override val coming_soon: String = "მალე!"
override val company_logo: String = "კომპანიის ლოგო"
override val company_name_hint: String = "კომპანია"
override val confirm_password_label: String = "დაადასტურეთ პაროლი"
override val confirmation_title: String = "Დარწმუნებული ხარ?"
override val convert_to_invoice: String = "ინვოისის კონვერტაცია"
override val country_hint: String = "ქვეყანა"
override val create_client: String = "კლიენტის შექმნა"
override val create_company: String = "კომპანიის შექმნა"
override val create_document: String = "დოკუმენტის შექმნა"
override val create_estimate: String = "შეფასება"
override val create_invoice: String = "ინვოისი"
override val create_item: String = "ელემენტის შექმნა"
override val create_new_client: String = "ახალი კლიენტის შექმნა"
override val create_new_item: String = "ახალი ელემენტის შექმნა"
override val create_new_tax: String = "ახალი გადასახადის შექმნა"
override val create_signature: String = "ხელმოწერა"
override val created: String = "შექმნა"
override val credit: String = "credit"
override val customer: String = "მომხმარებელი"
override val date: String = "თარიღი"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "ნაგულისხმევი ვალუტა: ${currency}" }
override val default_currency_label: String = "ნაგულისხმევი ვალუტა"
override val delete: String = "წაშალე"
override val delete_account: String = "Ანგარიშის წაშლა"
override val delete_confirmation_message: String = "თქვენი ყველა მონაცემი დაიკარგება."
override val delete_item_title: String = "ნივთის წაშლა?"
override val delete_signature: String = "ხელმოწერის წაშლა"
override val depreciation: String = "depreciation"
override val description: String = "აღწერილობა"
override val description_hint: String = "აღწერილობა"
override val detailed_report: String = "დეტალური ანგარიში"
override val disabled: String = "ინვალიდი"
override val discount: String = "ფასდაკლება"
override val discount_hint: String = "ფასდაკლება"
override val display_af: String = "აფრიკელი"
override val display_am: String = "ამჰარული"
override val display_ar: String = "არაბული"
override val display_as: String = "ასამური"
override val display_az: String = "აზერბაიჯანული"
override val display_be: String = "ბელორუსული"
override val display_bg: String = "ბულგარული"
override val display_bn: String = "ბენგალური"
override val display_bs: String = "ბოსნიელი"
override val display_ca: String = "კატალონიური"
override val display_cs: String = "ჩეხური"
override val display_da: String = "დანიური"
override val display_de: String = "გერმანული"
override val display_el: String = "ბერძენი"
override val display_en: String = "ინგლისური"
override val display_es: String = "ესპანური"
override val display_es_MX: String = "ესპანური (მექსიკა)"
override val display_et: String = "ესტონური"
override val display_eu: String = "ბასკური"
override val display_fa: String = "სპარსული"
override val display_fi: String = "ფინური"
override val display_fil: String = "ფილიპინელი"
override val display_fr: String = "ფრანგული"
override val display_fr_CA: String = "კანადური ფრანგული"
override val display_gl: String = "გალისიური"
override val display_gu: String = "გუჯარათი"
override val display_he: String = "ებრაული"
override val display_hi: String = "არა"
override val display_hr: String = "ხორვატული"
override val display_hu: String = "უნგრული"
override val display_hy: String = "სომხური"
override val display_id: String = "ინდონეზიური"
override val display_is: String = "ისლანდიური"
override val display_it: String = "იტალიური"
override val display_ja: String = "იაპონელი"
override val display_ka: String = "ქართული"
override val display_kk: String = "ყაზახური"
override val display_km: String = "ცენტრალური ქმერები"
override val display_kn: String = "კანადა"
override val display_ko: String = "კორეული"
override val display_ky: String = "ყირგიზული"
override val display_lo: String = "შრომა"
override val display_lt: String = "ლიტვური"
override val display_lv: String = "ლატვიური"
override val display_mk: String = "მაკედონელი"
override val display_ml: String = "მალაიალამური"
override val display_mn: String = "მონღოლური"
override val display_mr: String = "მარათჰი"
override val display_ms: String = "მალაიზიური"
override val display_my: String = "ბირმული"
override val display_nb: String = "ნორვეგიული ბოკმალი"
override val display_ne: String = "ნეპალური"
override val display_nl: String = "ჰოლანდიური"
override val display_or: String = "ორია"
override val display_pa: String = "პენჯაბური"
override val display_pl: String = "პოლონური"
override val display_pt: String = "პორტუგალიური"
override val display_pt_BR: String = "პორტუგალიური (ბრაზილია)"
override val display_pt_PT: String = "ევროპული პორტუგალიელი"
override val display_ro: String = "რუმინული"
override val display_ru: String = "რუსული"
override val display_si: String = "სინჰალური"
override val display_sk: String = "სლოვაკური"
override val display_sl: String = "სლოვენური"
override val display_sq: String = "ალბანური"
override val display_sr: String = "სერბული"
override val display_sv: String = "შვედური"
override val display_sw: String = "სუაჰილი"
override val display_ta: String = "ტამილური"
override val display_te: String = "ტელუგუ"
override val display_th: String = "ტაილანდური"
override val display_tr: String = "თურქული"
override val display_uk: String = "უკრაინული"
override val display_ur: String = "ურდუ"
override val display_uz: String = "უზბეკური"
override val display_vi: String = "ვიეტნამური"
override val display_zh_Hant_TW: String = "ჩინური (ტაივანი, ტრადიციული)"
override val display_zu: String = "ზულუ"
override val document_estimate: String = "შეფასება"
override val document_invoice: String = "ინვოისი"
override val document_quote: String = "ციტატა"
override val done: String = "შესრულებულია"
override val download_app: String = "ჩამოტვირთეთ აპლიკაცია"
override val download_invoice: String = "ინვოისის ჩამოტვირთვა"
override val download_pdf: String = "ჩამოტვირთეთ PDF"
override val draw_signature: String = "ხელმოწერის დახატვა"
override val due: Formattable = Formattable { (date) -> "გამო ${date}" }
override val due_date: String = "გამოცემის თარიღი"
override val due_day: String = "დღეში"
override val due_label: String = "იმის გამო"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ვადაა ${dueWhen}!" }
override val due_today: String = "დღეს"
override val due_week: String = "ერთ კვირაში"
override val duplicate: String = "დუბლიკატი"
override val duration_day: String = "დღე"
override val duration_month: String = "თვე"
override val duration_week: String = "კვირა"
override val duration_year: String = "წელი"
override val email: String = "ელფოსტა"
override val email_blank_error: String = "ელ საჭირო."
override val email_error: String = "არასწორი ელ. ფოსტის მისამართი."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Მადლობა თქვენი საქმიანობისთვის. სრული ${documentType}-ის სანახავად დააწკაპუნეთ ღილაკზე ამ წერილის ბოლოს. გაითვალისწინეთ, რომ ის მოქმედებს ${dueDate}-მდე." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "თქვენ მიიღეთ ახალი${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType}-ის ნახვა" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "ძვირფასო ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Მადლობა თქვენი საქმიანობისთვის. სრული ${documentType}-ის სანახავად დააწკაპუნეთ ღილაკზე ამ წერილის ბოლოს. გთხოვთ, გადაიხადოთ ბალანსი ${dueDate}-მდე." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "თქვენ მიიღეთ ახალი${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "ელ. ფოსტის პაროლის აღდგენა, გთხოვთ გადაამოწმოთ თქვენი შემოსულები."
override val empty_clients_list_message: String = "თქვენ არ გაქვთ კლიენტები თვალყური. ახალი კლიენტის დამატება."
override val empty_estimate_list_message: String = "შექმენით თქვენი პირველი შეფასება"
override val empty_expenses: String = "თქვენ არ გაქვთ გასაკონტროლებელი ხარჯები. დაამატეთ ახალი ხარჯი."
override val empty_invoice_list_message: String = "შექმენით თქვენი პირველი ინვოისი"
override val empty_items_list_message: String = "თქვენ არ გაქვთ ნივთები, დაამატეთ ახალი ელემენტი!"
override val empty_signatures_list_message: String = "ხელმოწერები არ მოიძებნა. შექმნა ერთი!"
override val empty_taxes_list_message: String = "თქვენ არ გაქვთ საგადასახადო წესები. ახალი საგადასახადო წესის დამატება."
override val empty_templates_list_message: String = "შაბლონები მიუწვდომელია."
override val enabled: String = "ჩართულია"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "შეფასება #"
override val estimates: String = "შეფასებები"
override val estimates_report: String = "შეფასებები"
override val expense_bank_fees: String = "ბანკის საკომისიო"
override val expense_benefits: String = "სარგებელი"
override val expense_communication: String = "Კომუნიკაცია"
override val expense_debt: String = "ვალი"
override val expense_deleted: String = "ხარჯი წაშლილია"
override val expense_depreciation: String = "ამორტიზაცია"
override val expense_gifts: String = "საჩუქრები"
override val expense_insurance: String = "დაზღვევა"
override val expense_inventory: String = "ინვენტარი"
override val expense_legal: String = "იურიდიული"
override val expense_maintenance: String = "მოვლა"
override val expense_marketing: String = "მარკეტინგი"
override val expense_meals: String = "კვება"
override val expense_office_supplies: String = "საოფისე მასალები"
override val expense_rent: String = "Ქირავდება"
override val expense_salaries: String = "ხელფასები"
override val expense_shopping: String = "შოპინგი"
override val expense_software: String = "პროგრამული უზრუნველყოფა"
override val expense_status_available: String = "ხელმისაწვდომია"
override val expense_status_processing: String = "დამუშავება"
override val expense_tax: String = "გადასახადი"
override val expense_training: String = "ტრენინგი"
override val expense_transport: String = "ტრანსპორტი"
override val expense_travel: String = "მოგზაურობა"
override val expense_utility: String = "კომუნალური"
override val expenses: String = "Ხარჯები"
override val feedback_option: String = "კავშირი"
override val filter: String = "გაფილტვრა"
override val first_name_hint: String = "სახელი"
override val forgot_password: String = "პაროლის აღდგენა"
override val forgot_password_label: String = "დაგავიწყდათ პაროლი"
override val from: String = "დან"
override val general_error: String = "მოხდა შეცდომა, გთხოვთ, სცადოთ მოგვიანებით."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "უკან დაბრუნება"
override val group_by: String = "ჯგუფის მიხედვით:"
override val image_view: String = "გამოსახულება"
override val install: String = "ინსტალაცია"
override val invalid_credentials_error: String = "არასწორი რწმუნებათა სიგელები."
override val invalid_email_error: String = "არასწორი ელ."
override val invalid_user_error: String = "არასწორი მომხმარებლის რწმუნებათა სიგელები."
override val invoice_deleted_message: String = "ინვოისი ამოღებულია"
override val invoice_menu: String = "ინვოისის მენიუ"
override val invoice_number: String = "ინვოისი #"
override val invoices: String = "ინვოისები"
override val items_option: String = "საგნები"
override val keep: String = "შეინახეთ"
override val language_selector: Formattable = Formattable { (არჩეულია) -> "ენა ${არჩეულია}" }
override val last_name_hint: String = "გვარი"
override val ledger: String = "ledger"
override val legal: String = "იურიდიული"
override val legal_error: String = "უნდა მიიღოს მომსახურების პირობები და კონფიდენციალურობის პოლიტიკა"
override val liabilities: String = "liabilities"
override val loading: String = "იტვირთება"
override val login_required_error: String = "შესვლა საჭირო."
override val logo_view: String = "ლოგო"
override val logout: String = "გამოსვლა"
override val logout_prompt: String = "თქვენი მონაცემები არ არის სინქრონიზებული ღრუბლით. ინვოისების, შეფასების, კლიენტებისა და სხვა ინფორმაციის წაშლა?"
override val main_screen: String = "მთავარი ეკრანი"
override val mark_paid: String = "მონიშნე როგორც ფასიანი"
override val message: String = "შეტყობინება"
override val missing_sender_msg: String = "დოკუმენტი გამგზავნის ინფორმაციის გარეშე შეიძლება არასწორი იყოს."
override val missing_sender_title: String = "გამომგზავნის ინფორმაცია აკლია"
override val month_april: String = "აპრილი"
override val month_april_short: String = "აპრილი"
override val month_august: String = "აგვისტო"
override val month_august_short: String = "აგვ."
override val month_december: String = "დეკემბერი"
override val month_december_short: String = "დეკ."
override val month_february: String = "თებერვალი"
override val month_february_short: String = "თებ."
override val month_january: String = "იანვარი"
override val month_january_short: String = "იან."
override val month_july: String = "ივლისი"
override val month_july_short: String = "ივლისი"
override val month_june: String = "ივნისი"
override val month_june_short: String = "ივნისი"
override val month_march: String = "მარტი"
override val month_march_short: String = "მარტი"
override val month_may: String = "მაისი"
override val month_may_short: String = "მაისი"
override val month_november: String = "ნოემბერი"
override val month_november_short: String = "ნოემ."
override val month_october: String = "ოქტომბერი"
override val month_october_short: String = "ოქტ."
override val month_september: String = "სექტემბერი"
override val month_september_short: String = "სექტემბერი"
override val multifactor_error: String = "ვერ მოხერხდა შემოწმება."
override val new: String = "ახალი"
override val news: String = "სიახლეები"
override val next: String = "შემდეგი"
override val no: String = "no"
override val no_data_available: String = "Მონაცემები ხელმისაწვდომი არაა"
override val no_search_results: String = "უკაცრავად, ჩვენ ვერ ვიპოვეთ რაიმე შედეგი."
override val none: String = "არცერთი"
override val note_fields: String = "შენიშვნები"
override val note_hint: String = "შენიშვნა"
override val notifications_option: String = "შეტყობინებები"
override val ok: String = "OK"
override val onboarding: String = "ჩართვა"
override val onboarding_aboutus_message: String = "DingVoice-ის ძირითადი ნაწილები სამუდამოდ უფასოა. ჩვენი მისიაა დაგეხმაროთ თქვენი ბიზნესის განვითარებაში, დაგვეხმაროთ გაუმჯობესებაში თქვენი გამოხმაურების გაზიარებით."
override val onboarding_aboutus_title: String = "ჩვენ აქ ვართ დასახმარებლად"
override val onboarding_paid_1: String = "წვდომა პროფესიონალურ შაბლონებზე"
override val onboarding_paid_2: String = "უფრო ღრმად შეხედეთ თქვენს ფინანსურ მდგომარეობას"
override val onboarding_paid_3: String = "სწრაფად მოაგვარეთ პრობლემები"
override val onboarding_paid_4: String = "შეინახეთ თქვენი დოკუმენტები უსაფრთხოდ"
override val onboarding_paid_5: String = "იყავით განახლებული ინვოისის სტატუსის ცვლილებების შესახებ"
override val onboarding_paid_title: String = "კიდევ უფრო სწრაფად გაიზარდეთ..."
override val onboarding_paywall_message: String = "ისიამოვნეთ ყველა პრემიუმ ფუნქციით ახლა საუკეთესო ფასად."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "მხოლოდ ${currencySymbol}${introAmount} ${introDuration} ${introPeriod}-ისთვის და შემდეგ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "მხოლოდ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "უფასოა ${trialDuration} ${trialPeriod} და შემდეგ მხოლოდ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "უფასოა ${trialDuration} ${trialPeriod}, შემდეგ ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}-ისთვის და შემდეგ მხოლოდ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "ავტომატური განახლებადი. გააუქმეთ ნებისმიერ დროს."
override val onboarding_paywall_restore: String = "შესყიდვის აღდგენა"
override val onboarding_paywall_restore_failure: String = "შესყიდვების აღდგენა ვერ მოხერხდა, სცადეთ მოგვიანებით ან დაუკავშირდით მხარდაჭერას."
override val onboarding_paywall_restore_success: String = "აღდგენა წარმატებულია!"
override val onboarding_paywall_title: String = "მიაღწიეთ სიდიადეს!"
override val onboarding_welcome_message: String = "სრული ინსტრუმენტების ნაკრები თქვენი ინვოისების და შეფასებების სამართავად."
override val onboarding_welcome_title: String = "კეთილი იყოს თქვენი მობრძანება DingVoice-ში"
override val other_state: String = "სხვა"
override val overdue_state: String = "ვადაგადაცილ"
override val paid_state: String = "გადახდილი"
override val password: String = "პაროლი"
override val password_blank_error: String = "პაროლი საჭირო."
override val password_mismatch_error: String = "პაროლები არ ემთხვევა."
override val pay: String = "pay"
override val payment_instructions_label: String = "გადახდის ინსტრუქცია"
override val payment_reminders: String = "გადახდის შეხსენებები"
override val phone_number_fields: String = "ტელეფონის ნომერი"
override val phone_number_hint: String = "ტელეფონის ნომერი"
override val postcode_hint: String = "საფოსტო კოდი"
override val preview: String = "Preview"
override val preview_menu_hint: String = "გადახედვის მენიუ"
override val price: String = "ფასი"
override val privacy_policy: String = "კონფიდენციალურობის პოლიტიკა"
override val profile: String = "პროფილი"
override val profitable_client: String = "თქვენი ყველაზე მომგებიანი კლიენტი არის..."
override val profits_report: String = "მოგება"
override val promo_content: String = "სარეკლამო კონტენტი"
override val quantity_hint: String = "რაოდენობა"
override val quantity_label_hint: String = "რაოდენობა ეტიკეტი"
override val query_info: String = "შეკითხვის საშუალებას გაძლევთ დაწეროთ საბაჟო გათვლები, რომლებიც გულისხმობენ ორივე ნივთს და სხვა საგადასახადო წესებს, როგორც საშუალებებით. რჩეულებში @ სიმბოლო და ნივთი ან საგადასახადო აღწერა ბმული სხვა შესვლის +, -, *,/,% - მათემატიკის ოპერატორები"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} მიიღო თქვენმა კლიენტმა." }
override val received_state: String = "მიღებული"
override val registration_code_fields: String = "Სარეგისტრაციო კოდი"
override val reminder: String = "შეხსენება"
override val reports: String = "ცნობები"
override val reports_not_available: String = "მოხსენებები არ არის ხელმისაწვდომი, დაბრუნდით მოგვიანებით."
override val reveal_password: String = "გამოავლინე პაროლი"
override val revenue: String = "revenue"
override val sales_tax: String = "დღგ"
override val sales_tax_number: String = "დღგ ნომერი"
override val save: String = "შეინახე"
override val scan_document: String = "დოკუმენტის სკანირება"
override val search: String = "ძიება"
override val select: String = "აირჩიეთ"
override val select_icon: String = "აირჩიეთ ხატულა"
override val select_signature: String = "აირჩიეთ ხელმოწერა"
override val select_template: String = "შაბლონის არჩევა"
override val send: String = "გაგზავნა"
override val sent_state: String = "გაგზავნილი"
override val setup_sender: String = "დააყენეთ გამგზავნი"
override val share_option: String = "წილადი"
override val ship_to: String = "გაგზავნეთ"
override val sign_in: String = "შედით სისტემაში"
override val sign_in_apple: String = "შედით Apple- თან"
override val sign_in_email: String = "შედით ელ. ფოსტით"
override val sign_in_facebook: String = "შესვლა Facebook- თან"
override val sign_in_google: String = "შესვლა Google- თან"
override val sign_up: String = "დარეგისტრირდით"
override val sign_up_label: String = "არ გაქვთ ანგარიში?"
override val sign_up_link_label: String = "დარეგისტრირდით!"
override val signatures_option: String = "ხელმოწერები"
override val skip: String = "გამოტოვება"
override val sold_items_report: String = "გაყიდული ნივთები"
override val sort_by: String = "სორტირება:"
override val status_update: String = "დოკუმენტის სტატუსის განახლება"
override val sub_annual: String = "წლიური"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/წელი" }
override val sub_backups: String = "ღრუბლოვანი სარეზერვო"
override val sub_insights: String = "Insights"
override val sub_month: String = "ყოველთვიურად"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/თვე" }
override val sub_notifications: String = "შეტყობინებები"
override val sub_popup_message: String = "გამოწერები მალე მოდიან. შეამოწმეთ აქ ცოტა ხნით."
override val sub_support: String = "მხარდაჭერა"
override val sub_templates: String = "შაბლონები"
override val subscribe: String = "გამოწერა"
override val subscription_header: String = "იზრდება თქვენი ბიზნესის:"
override val subscription_option: String = "გამოწერა"
override val subscriptions_load_fail: String = "უკაცრავად, ამჯერად თქვენი გამოწერების აღდგენა არ შეგვიძლია. გთხოვთ, სცადოთ მოგვიანებით ან დაუკავშირდით ჩვენს მხარდაჭერის გუნდს დახმარებისთვის."
override val subtotal: Formattable = Formattable { (sum) -> "ჯამი: ${sum}" }
override val subtotal_label: String = "სუბტოტალი"
override val sum: String = "ჯამი"
override val support_option: String = "მხარდაჭერა"
override val switch_reports: String = "ანგარიშის შეცვლა"
override val tax: String = "გადასახადი"
override val tax_deleted_message: String = "გადასახადი წაშლილია"
override val tax_description_warning: String = "თუ გამოიყენება ეს გვიჩვენებს up on ინვოისი"
override val tax_input_flat: String = "ბრტყელი"
override val tax_input_percent: String = "პროცენტი"
override val tax_number_fields: String = "საგადასახადო ნომერი"
override val tax_number_hint: String = "საგადასახადო ნომერი"
override val tax_query_info_description: String = "შეკითხვის ინფორმაცია"
override val tax_report: String = "გადასახადი"
override val tax_rules_option: String = "საგადასახადო წესები"
override val taxed_clients_report: String = "დაბეგვრის კლიენტები"
override val template: String = "თარგი"
override val template_selector_info: String = "გადაათრიეთ ნაგულისხმევი შაბლონის შეცვლა"
override val template_unlock_promo: String = "უფრო მეტ შაბლონზე წვდომა Premium-ით"
override val templates_option: String = "შაბლონები"
override val terms_of_service: String = "მომსახურების პირობები"
override val thank_you: String = "Გმადლობთ"
override val to: String = "დან"
override val total: Formattable = Formattable { (sum) -> "სულ: ${sum}" }
override val total_label: String = "სულ"
override val undo_action: String = "გაუქმება"
override val unit: String = "ერთეული"
override val unit_cost_hint: String = "ერთეულის ღირებულება"
override val unknown_client: String = "უცნობი კლიენტი"
override val unlock: String = "განბლოკვა"
override val unnamed_item: String = "უსახელო ელემენტი"
override val upgrade: String = "განახლება"
override val user_collision_error: String = "ანგარიში უკვე არსებობს."
override val valid_until: String = "Ძალაშია"
override val warning: String = "გაფრთხილება!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "მიიღეთ ${termsOfService} და ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "არასწორი კოდი.",
	"add_client_action" to "კლიენტის დამატება",
	"add_customer" to "მომხმარებლის დამატება",
	"add_expense" to "დაამატეთ ხარჯები",
	"add_fields_label" to "დამატება:",
	"add_invoice_action" to "შექმნა",
	"add_item_action" to "ელემენტის დამატება",
	"add_item_button" to "ელემენტის დამატება",
	"add_signature_action" to "ხელმოწერის დამატება",
	"add_tax_action" to "გადასახადის დამატება",
	"address_fields" to "მისამართი",
	"address_line_hint" to "მისამართის ხაზი",
	"advanced" to "გაფართოებული",
	"agree_marketing" to "ვეთანხმები პირდაპირი მარკეტინგული კომუნიკაციების მიღებას",
	"all_notifications" to "ყველა შეტყობინება",
	"amortization" to "amortization",
	"amount" to "თანხა",
	"asset" to "asset",
	"attach_file" to "Ფაილის მიმაგრება",
	"attachment" to "დანართი",
	"auth_error" to "აქცია ვერ მოხერხდა, კვლავ სცადეთ.",
	"available_for_paid" to "Cloud sync ხელმისაწვდომია მხოლოდ ფასიანი გამოწერის ქვეშ.",
	"backups_option" to "სარეზერვო",
	"balance" to "balance",
	"bank_account" to "Საბანკო ანგარიში",
	"bill_number" to "კანონპროექტის ნომერი",
	"cancel" to "გაუქმება",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "კატეგორიები",
	"category" to "კატეგორია",
	"category_add" to "დაამატეთ კატეგორია",
	"category_delete" to "კატეგორიის წაშლა",
	"category_name" to "კატეგორიის სახელი",
	"change_language" to Formattable { (language) -> "ინვოისის ენა: ${language}" },
	"change_language_label" to "ინვოისის ენა",
	"change_template" to "თარგის შეცვლა",
	"city_hint" to "ქალაქი",
	"clear" to "წმინდა",
	"client" to "კლიენტი",
	"client_actions" to "კლიენტის ქმედებები",
	"client_deleted_message" to "კლიენტი წაშლილია",
	"clients_error_required_fields" to "საჭიროა მინიმუმ ერთი მნიშვნელობა.",
	"clients_option" to "კლიენტები",
	"close" to "დახურვა",
	"cloud_sync" to "ღრუბლოვანი სინქრონიზაცია",
	"coming_soon" to "მალე!",
	"company_logo" to "კომპანიის ლოგო",
	"company_name_hint" to "კომპანია",
	"confirm_password_label" to "დაადასტურეთ პაროლი",
	"confirmation_title" to "Დარწმუნებული ხარ?",
	"convert_to_invoice" to "ინვოისის კონვერტაცია",
	"country_hint" to "ქვეყანა",
	"create_client" to "კლიენტის შექმნა",
	"create_company" to "კომპანიის შექმნა",
	"create_document" to "დოკუმენტის შექმნა",
	"create_estimate" to "შეფასება",
	"create_invoice" to "ინვოისი",
	"create_item" to "ელემენტის შექმნა",
	"create_new_client" to "ახალი კლიენტის შექმნა",
	"create_new_item" to "ახალი ელემენტის შექმნა",
	"create_new_tax" to "ახალი გადასახადის შექმნა",
	"create_signature" to "ხელმოწერა",
	"created" to "შექმნა",
	"credit" to "credit",
	"customer" to "მომხმარებელი",
	"date" to "თარიღი",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "ნაგულისხმევი ვალუტა: ${currency}" },
	"default_currency_label" to "ნაგულისხმევი ვალუტა",
	"delete" to "წაშალე",
	"delete_account" to "Ანგარიშის წაშლა",
	"delete_confirmation_message" to "თქვენი ყველა მონაცემი დაიკარგება.",
	"delete_item_title" to "ნივთის წაშლა?",
	"delete_signature" to "ხელმოწერის წაშლა",
	"depreciation" to "depreciation",
	"description" to "აღწერილობა",
	"description_hint" to "აღწერილობა",
	"detailed_report" to "დეტალური ანგარიში",
	"disabled" to "ინვალიდი",
	"discount" to "ფასდაკლება",
	"discount_hint" to "ფასდაკლება",
	"display_af" to "აფრიკელი",
	"display_am" to "ამჰარული",
	"display_ar" to "არაბული",
	"display_as" to "ასამური",
	"display_az" to "აზერბაიჯანული",
	"display_be" to "ბელორუსული",
	"display_bg" to "ბულგარული",
	"display_bn" to "ბენგალური",
	"display_bs" to "ბოსნიელი",
	"display_ca" to "კატალონიური",
	"display_cs" to "ჩეხური",
	"display_da" to "დანიური",
	"display_de" to "გერმანული",
	"display_el" to "ბერძენი",
	"display_en" to "ინგლისური",
	"display_es" to "ესპანური",
	"display_es_MX" to "ესპანური (მექსიკა)",
	"display_et" to "ესტონური",
	"display_eu" to "ბასკური",
	"display_fa" to "სპარსული",
	"display_fi" to "ფინური",
	"display_fil" to "ფილიპინელი",
	"display_fr" to "ფრანგული",
	"display_fr_CA" to "კანადური ფრანგული",
	"display_gl" to "გალისიური",
	"display_gu" to "გუჯარათი",
	"display_he" to "ებრაული",
	"display_hi" to "არა",
	"display_hr" to "ხორვატული",
	"display_hu" to "უნგრული",
	"display_hy" to "სომხური",
	"display_id" to "ინდონეზიური",
	"display_is" to "ისლანდიური",
	"display_it" to "იტალიური",
	"display_ja" to "იაპონელი",
	"display_ka" to "ქართული",
	"display_kk" to "ყაზახური",
	"display_km" to "ცენტრალური ქმერები",
	"display_kn" to "კანადა",
	"display_ko" to "კორეული",
	"display_ky" to "ყირგიზული",
	"display_lo" to "შრომა",
	"display_lt" to "ლიტვური",
	"display_lv" to "ლატვიური",
	"display_mk" to "მაკედონელი",
	"display_ml" to "მალაიალამური",
	"display_mn" to "მონღოლური",
	"display_mr" to "მარათჰი",
	"display_ms" to "მალაიზიური",
	"display_my" to "ბირმული",
	"display_nb" to "ნორვეგიული ბოკმალი",
	"display_ne" to "ნეპალური",
	"display_nl" to "ჰოლანდიური",
	"display_or" to "ორია",
	"display_pa" to "პენჯაბური",
	"display_pl" to "პოლონური",
	"display_pt" to "პორტუგალიური",
	"display_pt_BR" to "პორტუგალიური (ბრაზილია)",
	"display_pt_PT" to "ევროპული პორტუგალიელი",
	"display_ro" to "რუმინული",
	"display_ru" to "რუსული",
	"display_si" to "სინჰალური",
	"display_sk" to "სლოვაკური",
	"display_sl" to "სლოვენური",
	"display_sq" to "ალბანური",
	"display_sr" to "სერბული",
	"display_sv" to "შვედური",
	"display_sw" to "სუაჰილი",
	"display_ta" to "ტამილური",
	"display_te" to "ტელუგუ",
	"display_th" to "ტაილანდური",
	"display_tr" to "თურქული",
	"display_uk" to "უკრაინული",
	"display_ur" to "ურდუ",
	"display_uz" to "უზბეკური",
	"display_vi" to "ვიეტნამური",
	"display_zh_Hant_TW" to "ჩინური (ტაივანი, ტრადიციული)",
	"display_zu" to "ზულუ",
	"document_estimate" to "შეფასება",
	"document_invoice" to "ინვოისი",
	"document_quote" to "ციტატა",
	"done" to "შესრულებულია",
	"download_app" to "ჩამოტვირთეთ აპლიკაცია",
	"download_invoice" to "ინვოისის ჩამოტვირთვა",
	"download_pdf" to "ჩამოტვირთეთ PDF",
	"draw_signature" to "ხელმოწერის დახატვა",
	"due" to Formattable { (date) -> "გამო ${date}" },
	"due_date" to "გამოცემის თარიღი",
	"due_day" to "დღეში",
	"due_label" to "იმის გამო",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ვადაა ${dueWhen}!" },
	"due_today" to "დღეს",
	"due_week" to "ერთ კვირაში",
	"duplicate" to "დუბლიკატი",
	"duration_day" to "დღე",
	"duration_month" to "თვე",
	"duration_week" to "კვირა",
	"duration_year" to "წელი",
	"email" to "ელფოსტა",
	"email_blank_error" to "ელ საჭირო.",
	"email_error" to "არასწორი ელ. ფოსტის მისამართი.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Მადლობა თქვენი საქმიანობისთვის. სრული ${documentType}-ის სანახავად დააწკაპუნეთ ღილაკზე ამ წერილის ბოლოს. გაითვალისწინეთ, რომ ის მოქმედებს ${dueDate}-მდე." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "თქვენ მიიღეთ ახალი${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType}-ის ნახვა" },
	"email_invoice_greeting" to Formattable { (clientName) -> "ძვირფასო ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Მადლობა თქვენი საქმიანობისთვის. სრული ${documentType}-ის სანახავად დააწკაპუნეთ ღილაკზე ამ წერილის ბოლოს. გთხოვთ, გადაიხადოთ ბალანსი ${dueDate}-მდე." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "თქვენ მიიღეთ ახალი${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "ელ. ფოსტის პაროლის აღდგენა, გთხოვთ გადაამოწმოთ თქვენი შემოსულები.",
	"empty_clients_list_message" to "თქვენ არ გაქვთ კლიენტები თვალყური. ახალი კლიენტის დამატება.",
	"empty_estimate_list_message" to "შექმენით თქვენი პირველი შეფასება",
	"empty_expenses" to "თქვენ არ გაქვთ გასაკონტროლებელი ხარჯები. დაამატეთ ახალი ხარჯი.",
	"empty_invoice_list_message" to "შექმენით თქვენი პირველი ინვოისი",
	"empty_items_list_message" to "თქვენ არ გაქვთ ნივთები, დაამატეთ ახალი ელემენტი!",
	"empty_signatures_list_message" to "ხელმოწერები არ მოიძებნა. შექმნა ერთი!",
	"empty_taxes_list_message" to "თქვენ არ გაქვთ საგადასახადო წესები. ახალი საგადასახადო წესის დამატება.",
	"empty_templates_list_message" to "შაბლონები მიუწვდომელია.",
	"enabled" to "ჩართულია",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "შეფასება #",
	"estimates" to "შეფასებები",
	"estimates_report" to "შეფასებები",
	"expense_bank_fees" to "ბანკის საკომისიო",
	"expense_benefits" to "სარგებელი",
	"expense_communication" to "Კომუნიკაცია",
	"expense_debt" to "ვალი",
	"expense_deleted" to "ხარჯი წაშლილია",
	"expense_depreciation" to "ამორტიზაცია",
	"expense_gifts" to "საჩუქრები",
	"expense_insurance" to "დაზღვევა",
	"expense_inventory" to "ინვენტარი",
	"expense_legal" to "იურიდიული",
	"expense_maintenance" to "მოვლა",
	"expense_marketing" to "მარკეტინგი",
	"expense_meals" to "კვება",
	"expense_office_supplies" to "საოფისე მასალები",
	"expense_rent" to "Ქირავდება",
	"expense_salaries" to "ხელფასები",
	"expense_shopping" to "შოპინგი",
	"expense_software" to "პროგრამული უზრუნველყოფა",
	"expense_status_available" to "ხელმისაწვდომია",
	"expense_status_processing" to "დამუშავება",
	"expense_tax" to "გადასახადი",
	"expense_training" to "ტრენინგი",
	"expense_transport" to "ტრანსპორტი",
	"expense_travel" to "მოგზაურობა",
	"expense_utility" to "კომუნალური",
	"expenses" to "Ხარჯები",
	"feedback_option" to "კავშირი",
	"filter" to "გაფილტვრა",
	"first_name_hint" to "სახელი",
	"forgot_password" to "პაროლის აღდგენა",
	"forgot_password_label" to "დაგავიწყდათ პაროლი",
	"from" to "დან",
	"general_error" to "მოხდა შეცდომა, გთხოვთ, სცადოთ მოგვიანებით.",
	"general_ledger" to "general ledger",
	"go_back_label" to "უკან დაბრუნება",
	"group_by" to "ჯგუფის მიხედვით:",
	"image_view" to "გამოსახულება",
	"install" to "ინსტალაცია",
	"invalid_credentials_error" to "არასწორი რწმუნებათა სიგელები.",
	"invalid_email_error" to "არასწორი ელ.",
	"invalid_user_error" to "არასწორი მომხმარებლის რწმუნებათა სიგელები.",
	"invoice_deleted_message" to "ინვოისი ამოღებულია",
	"invoice_menu" to "ინვოისის მენიუ",
	"invoice_number" to "ინვოისი #",
	"invoices" to "ინვოისები",
	"items_option" to "საგნები",
	"keep" to "შეინახეთ",
	"language_selector" to Formattable { (არჩეულია) -> "ენა ${არჩეულია}" },
	"last_name_hint" to "გვარი",
	"ledger" to "ledger",
	"legal" to "იურიდიული",
	"legal_error" to "უნდა მიიღოს მომსახურების პირობები და კონფიდენციალურობის პოლიტიკა",
	"liabilities" to "liabilities",
	"loading" to "იტვირთება",
	"login_required_error" to "შესვლა საჭირო.",
	"logo_view" to "ლოგო",
	"logout" to "გამოსვლა",
	"logout_prompt" to "თქვენი მონაცემები არ არის სინქრონიზებული ღრუბლით. ინვოისების, შეფასების, კლიენტებისა და სხვა ინფორმაციის წაშლა?",
	"main_screen" to "მთავარი ეკრანი",
	"mark_paid" to "მონიშნე როგორც ფასიანი",
	"message" to "შეტყობინება",
	"missing_sender_msg" to "დოკუმენტი გამგზავნის ინფორმაციის გარეშე შეიძლება არასწორი იყოს.",
	"missing_sender_title" to "გამომგზავნის ინფორმაცია აკლია",
	"month_april" to "აპრილი",
	"month_april_short" to "აპრილი",
	"month_august" to "აგვისტო",
	"month_august_short" to "აგვ.",
	"month_december" to "დეკემბერი",
	"month_december_short" to "დეკ.",
	"month_february" to "თებერვალი",
	"month_february_short" to "თებ.",
	"month_january" to "იანვარი",
	"month_january_short" to "იან.",
	"month_july" to "ივლისი",
	"month_july_short" to "ივლისი",
	"month_june" to "ივნისი",
	"month_june_short" to "ივნისი",
	"month_march" to "მარტი",
	"month_march_short" to "მარტი",
	"month_may" to "მაისი",
	"month_may_short" to "მაისი",
	"month_november" to "ნოემბერი",
	"month_november_short" to "ნოემ.",
	"month_october" to "ოქტომბერი",
	"month_october_short" to "ოქტ.",
	"month_september" to "სექტემბერი",
	"month_september_short" to "სექტემბერი",
	"multifactor_error" to "ვერ მოხერხდა შემოწმება.",
	"new" to "ახალი",
	"news" to "სიახლეები",
	"next" to "შემდეგი",
	"no" to "no",
	"no_data_available" to "Მონაცემები ხელმისაწვდომი არაა",
	"no_search_results" to "უკაცრავად, ჩვენ ვერ ვიპოვეთ რაიმე შედეგი.",
	"none" to "არცერთი",
	"note_fields" to "შენიშვნები",
	"note_hint" to "შენიშვნა",
	"notifications_option" to "შეტყობინებები",
	"ok" to "OK",
	"onboarding" to "ჩართვა",
	"onboarding_aboutus_message" to "DingVoice-ის ძირითადი ნაწილები სამუდამოდ უფასოა. ჩვენი მისიაა დაგეხმაროთ თქვენი ბიზნესის განვითარებაში, დაგვეხმაროთ გაუმჯობესებაში თქვენი გამოხმაურების გაზიარებით.",
	"onboarding_aboutus_title" to "ჩვენ აქ ვართ დასახმარებლად",
	"onboarding_paid_1" to "წვდომა პროფესიონალურ შაბლონებზე",
	"onboarding_paid_2" to "უფრო ღრმად შეხედეთ თქვენს ფინანსურ მდგომარეობას",
	"onboarding_paid_3" to "სწრაფად მოაგვარეთ პრობლემები",
	"onboarding_paid_4" to "შეინახეთ თქვენი დოკუმენტები უსაფრთხოდ",
	"onboarding_paid_5" to "იყავით განახლებული ინვოისის სტატუსის ცვლილებების შესახებ",
	"onboarding_paid_title" to "კიდევ უფრო სწრაფად გაიზარდეთ...",
	"onboarding_paywall_message" to "ისიამოვნეთ ყველა პრემიუმ ფუნქციით ახლა საუკეთესო ფასად.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "მხოლოდ ${currencySymbol}${introAmount} ${introDuration} ${introPeriod}-ისთვის და შემდეგ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "მხოლოდ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "უფასოა ${trialDuration} ${trialPeriod} და შემდეგ მხოლოდ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "უფასოა ${trialDuration} ${trialPeriod}, შემდეგ ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}-ისთვის და შემდეგ მხოლოდ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "ავტომატური განახლებადი. გააუქმეთ ნებისმიერ დროს.",
	"onboarding_paywall_restore" to "შესყიდვის აღდგენა",
	"onboarding_paywall_restore_failure" to "შესყიდვების აღდგენა ვერ მოხერხდა, სცადეთ მოგვიანებით ან დაუკავშირდით მხარდაჭერას.",
	"onboarding_paywall_restore_success" to "აღდგენა წარმატებულია!",
	"onboarding_paywall_title" to "მიაღწიეთ სიდიადეს!",
	"onboarding_welcome_message" to "სრული ინსტრუმენტების ნაკრები თქვენი ინვოისების და შეფასებების სამართავად.",
	"onboarding_welcome_title" to "კეთილი იყოს თქვენი მობრძანება DingVoice-ში",
	"other_state" to "სხვა",
	"overdue_state" to "ვადაგადაცილ",
	"paid_state" to "გადახდილი",
	"password" to "პაროლი",
	"password_blank_error" to "პაროლი საჭირო.",
	"password_mismatch_error" to "პაროლები არ ემთხვევა.",
	"pay" to "pay",
	"payment_instructions_label" to "გადახდის ინსტრუქცია",
	"payment_reminders" to "გადახდის შეხსენებები",
	"phone_number_fields" to "ტელეფონის ნომერი",
	"phone_number_hint" to "ტელეფონის ნომერი",
	"postcode_hint" to "საფოსტო კოდი",
	"preview" to "Preview",
	"preview_menu_hint" to "გადახედვის მენიუ",
	"price" to "ფასი",
	"privacy_policy" to "კონფიდენციალურობის პოლიტიკა",
	"profile" to "პროფილი",
	"profitable_client" to "თქვენი ყველაზე მომგებიანი კლიენტი არის...",
	"profits_report" to "მოგება",
	"promo_content" to "სარეკლამო კონტენტი",
	"quantity_hint" to "რაოდენობა",
	"quantity_label_hint" to "რაოდენობა ეტიკეტი",
	"query_info" to "შეკითხვის საშუალებას გაძლევთ დაწეროთ საბაჟო გათვლები, რომლებიც გულისხმობენ ორივე ნივთს და სხვა საგადასახადო წესებს, როგორც საშუალებებით. რჩეულებში @ სიმბოლო და ნივთი ან საგადასახადო აღწერა ბმული სხვა შესვლის +, -, *,/,% - მათემატიკის ოპერატორები",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} მიიღო თქვენმა კლიენტმა." },
	"received_state" to "მიღებული",
	"registration_code_fields" to "Სარეგისტრაციო კოდი",
	"reminder" to "შეხსენება",
	"reports" to "ცნობები",
	"reports_not_available" to "მოხსენებები არ არის ხელმისაწვდომი, დაბრუნდით მოგვიანებით.",
	"reveal_password" to "გამოავლინე პაროლი",
	"revenue" to "revenue",
	"sales_tax" to "დღგ",
	"sales_tax_number" to "დღგ ნომერი",
	"save" to "შეინახე",
	"scan_document" to "დოკუმენტის სკანირება",
	"search" to "ძიება",
	"select" to "აირჩიეთ",
	"select_icon" to "აირჩიეთ ხატულა",
	"select_signature" to "აირჩიეთ ხელმოწერა",
	"select_template" to "შაბლონის არჩევა",
	"send" to "გაგზავნა",
	"sent_state" to "გაგზავნილი",
	"setup_sender" to "დააყენეთ გამგზავნი",
	"share_option" to "წილადი",
	"ship_to" to "გაგზავნეთ",
	"sign_in" to "შედით სისტემაში",
	"sign_in_apple" to "შედით Apple- თან",
	"sign_in_email" to "შედით ელ. ფოსტით",
	"sign_in_facebook" to "შესვლა Facebook- თან",
	"sign_in_google" to "შესვლა Google- თან",
	"sign_up" to "დარეგისტრირდით",
	"sign_up_label" to "არ გაქვთ ანგარიში?",
	"sign_up_link_label" to "დარეგისტრირდით!",
	"signatures_option" to "ხელმოწერები",
	"skip" to "გამოტოვება",
	"sold_items_report" to "გაყიდული ნივთები",
	"sort_by" to "სორტირება:",
	"status_update" to "დოკუმენტის სტატუსის განახლება",
	"sub_annual" to "წლიური",
	"sub_annual_price" to Formattable { (price) -> "${price}/წელი" },
	"sub_backups" to "ღრუბლოვანი სარეზერვო",
	"sub_insights" to "Insights",
	"sub_month" to "ყოველთვიურად",
	"sub_monthly_price" to Formattable { (price) -> "${price}/თვე" },
	"sub_notifications" to "შეტყობინებები",
	"sub_popup_message" to "გამოწერები მალე მოდიან. შეამოწმეთ აქ ცოტა ხნით.",
	"sub_support" to "მხარდაჭერა",
	"sub_templates" to "შაბლონები",
	"subscribe" to "გამოწერა",
	"subscription_header" to "იზრდება თქვენი ბიზნესის:",
	"subscription_option" to "გამოწერა",
	"subscriptions_load_fail" to "უკაცრავად, ამჯერად თქვენი გამოწერების აღდგენა არ შეგვიძლია. გთხოვთ, სცადოთ მოგვიანებით ან დაუკავშირდით ჩვენს მხარდაჭერის გუნდს დახმარებისთვის.",
	"subtotal" to Formattable { (sum) -> "ჯამი: ${sum}" },
	"subtotal_label" to "სუბტოტალი",
	"sum" to "ჯამი",
	"support_option" to "მხარდაჭერა",
	"switch_reports" to "ანგარიშის შეცვლა",
	"tax" to "გადასახადი",
	"tax_deleted_message" to "გადასახადი წაშლილია",
	"tax_description_warning" to "თუ გამოიყენება ეს გვიჩვენებს up on ინვოისი",
	"tax_input_flat" to "ბრტყელი",
	"tax_input_percent" to "პროცენტი",
	"tax_number_fields" to "საგადასახადო ნომერი",
	"tax_number_hint" to "საგადასახადო ნომერი",
	"tax_query_info_description" to "შეკითხვის ინფორმაცია",
	"tax_report" to "გადასახადი",
	"tax_rules_option" to "საგადასახადო წესები",
	"taxed_clients_report" to "დაბეგვრის კლიენტები",
	"template" to "თარგი",
	"template_selector_info" to "გადაათრიეთ ნაგულისხმევი შაბლონის შეცვლა",
	"template_unlock_promo" to "უფრო მეტ შაბლონზე წვდომა Premium-ით",
	"templates_option" to "შაბლონები",
	"terms_of_service" to "მომსახურების პირობები",
	"thank_you" to "Გმადლობთ",
	"to" to "დან",
	"total" to Formattable { (sum) -> "სულ: ${sum}" },
	"total_label" to "სულ",
	"undo_action" to "გაუქმება",
	"unit" to "ერთეული",
	"unit_cost_hint" to "ერთეულის ღირებულება",
	"unknown_client" to "უცნობი კლიენტი",
	"unlock" to "განბლოკვა",
	"unnamed_item" to "უსახელო ელემენტი",
	"upgrade" to "განახლება",
	"user_collision_error" to "ანგარიში უკვე არსებობს.",
	"valid_until" to "Ძალაშია",
	"warning" to "გაფრთხილება!",
	"yes" to "yes",
    )
    
}