// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Cs  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Odsouhlaste ${termsOfService} a ${privacyPolicy}" }
override val account: String = "účet"
override val accounting: String = "účetnictví"
override val accruals: String = "časové rozlišení"
override val action_code_error: String = "Neplatný kód."
override val add_client_action: String = "Přidat klienta"
override val add_customer: String = "Přidat zákazníka"
override val add_expense: String = "Přidat výdaje"
override val add_fields_label: String = "Přidat:"
override val add_invoice_action: String = "Vytvořit"
override val add_item_action: String = "Přidat položku"
override val add_item_button: String = "Přidat položku"
override val add_signature_action: String = "Přidat podpis"
override val add_tax_action: String = "Přidat daň"
override val address_fields: String = "Adresa"
override val address_line_hint: String = "Adresní řádek"
override val advanced: String = "ADVANCED"
override val agree_marketing: String = "Souhlasím se zasíláním přímých marketingových sdělení"
override val all_notifications: String = "Všechna oznámení"
override val amortization: String = "amortizace"
override val amount: String = "Částka"
override val asset: String = "aktivum"
override val attach_file: String = "Připojit soubor"
override val attachment: String = "Příloha"
override val auth_error: String = "Akce se nezdařila, zkuste to znovu."
override val available_for_paid: String = "Cloudová synchronizace je k dispozici pouze v rámci placeného předplatného."
override val backups_option: String = "Zálohování"
override val balance: String = "zůstatek"
override val bank_account: String = "Bankovní Účet"
override val bill_number: String = "Číslo účtu"
override val cancel: String = "Zrušit"
override val cancel_trial: String = "Zrušte kdykoli"
override val capital: String = "kapitál"
override val cash: String = "hotovost"
override val categories: String = "Kategorie"
override val category: String = "Kategorie"
override val category_add: String = "Přidat kategorii"
override val category_delete: String = "Odstranit kategorii"
override val category_name: String = "Název kategorie"
override val change_language: Formattable = Formattable { (language) -> "Jazyk faktury: ${language}" }
override val change_language_label: String = "Jazyk Faktury"
override val change_template: String = "Změna šablony"
override val city_hint: String = "Město"
override val clear: String = "Přehledně"
override val client: String = "Klient"
override val client_actions: String = "Akce klienta"
override val client_deleted_message: String = "Klient smazán"
override val clients_error_required_fields: String = "Je vyžadována alespoň jedna hodnota."
override val clients_option: String = "Klienti"
override val close: String = "Zavřít"
override val cloud_sync: String = "Synchronizace s cloudem"
override val coming_soon: String = "Již brzy!"
override val company_logo: String = "Logo společnosti"
override val company_name_hint: String = "Společnost"
override val confirm_password_label: String = "Potvrzení hesla"
override val confirmation_title: String = "Jste si jisti?"
override val convert_to_invoice: String = "Převést na fakturu"
override val country_hint: String = "Země"
override val create_client: String = "Vytvořit klienta"
override val create_company: String = "Vytvořit společnost"
override val create_document: String = "Vytvořit dokument"
override val create_estimate: String = "Odhad"
override val create_invoice: String = "Faktura"
override val create_item: String = "Vytvořit položku"
override val create_new_client: String = "Vytvořit nového klienta"
override val create_new_item: String = "Vytvořit novou položku"
override val create_new_tax: String = "Vytvořit novou daň"
override val create_signature: String = "Podpis"
override val created: String = "Vytvořeno"
override val credit: String = "kreditní"
override val customer: String = "Zákazník"
override val date: String = "Datum"
override val debit: String = "debetní"
override val debt: String = "dluh"
override val default_currency: Formattable = Formattable { (currency) -> "Výchozí měna: ${currency}" }
override val default_currency_label: String = "Výchozí měna"
override val delete: String = "Odstranit"
override val delete_account: String = "Smazat Účet"
override val delete_confirmation_message: String = "Všechna vaše data budou ztracena."
override val delete_item_title: String = "Smazat položku?"
override val delete_signature: String = "Odstranění podpisu"
override val depreciation: String = "odpisy"
override val description: String = "Popis"
override val description_hint: String = "Popis"
override val detailed_report: String = "Podrobná Zpráva"
override val disabled: String = "Bezbariérový"
override val discount: String = "Sleva"
override val discount_hint: String = "Sleva"
override val display_af: String = "Afrikánština"
override val display_am: String = "Amharština"
override val display_ar: String = "Arabština"
override val display_as: String = "Ásámština"
override val display_az: String = "Ázerbájdžánština"
override val display_be: String = "Běloruština"
override val display_bg: String = "Bulharština"
override val display_bn: String = "Bengálština"
override val display_bs: String = "Bosenština"
override val display_ca: String = "Katalánština"
override val display_cs: String = "Čeština"
override val display_da: String = "Dánština"
override val display_de: String = "Němčina"
override val display_el: String = "Řečtina"
override val display_en: String = "Angličtina"
override val display_es: String = "Španělština"
override val display_es_MX: String = "Španělština (Mexiko)"
override val display_et: String = "Estonština"
override val display_eu: String = "Baskičtina"
override val display_fa: String = "Perština"
override val display_fi: String = "Finština"
override val display_fil: String = "Filipínština"
override val display_fr: String = "Francouzština"
override val display_fr_CA: String = "Kanadská francouzština"
override val display_gl: String = "Galicijština"
override val display_gu: String = "Gudžarátština"
override val display_he: String = "Hebrejština"
override val display_hi: String = "Hindština"
override val display_hr: String = "Chorvatština"
override val display_hu: String = "Maďarština"
override val display_hy: String = "Arménština"
override val display_id: String = "Indonéština"
override val display_is: String = "Islandština"
override val display_it: String = "Italština"
override val display_ja: String = "Japonština"
override val display_ka: String = "Gruzínština"
override val display_kk: String = "Kazachština"
override val display_km: String = "Střední Khmerové"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korejština"
override val display_ky: String = "Kirgizština"
override val display_lo: String = "Lao"
override val display_lt: String = "Litevština"
override val display_lv: String = "Lotyština"
override val display_mk: String = "Makedonština"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolština"
override val display_mr: String = "Maráthština"
override val display_ms: String = "Malajština"
override val display_my: String = "Barmština"
override val display_nb: String = "Norština - Bokmål"
override val display_ne: String = "Nepálština"
override val display_nl: String = "Holandština"
override val display_or: String = "Oriya"
override val display_pa: String = "Paňdžábština"
override val display_pl: String = "Polština"
override val display_pt: String = "Portugalština"
override val display_pt_BR: String = "Portugalština (Brazílie)"
override val display_pt_PT: String = "Evropská portugalština"
override val display_ro: String = "Rumunština"
override val display_ru: String = "Ruština"
override val display_si: String = "Sinhálština"
override val display_sk: String = "Slovenština"
override val display_sl: String = "Slovinština"
override val display_sq: String = "Albánština"
override val display_sr: String = "Srbština"
override val display_sv: String = "Švédština"
override val display_sw: String = "Svahilština"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thajština"
override val display_tr: String = "Turečtina"
override val display_uk: String = "Ukrajinština"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbečtina"
override val display_vi: String = "Vietnamština"
override val display_zh_Hant_TW: String = "Čínština (Tchaj-wan, Tradiční)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Odhad"
override val document_invoice: String = "Faktura"
override val document_quote: String = "Cenová nabídka"
override val done: String = "Hotovo"
override val download_app: String = "Stáhněte si aplikaci"
override val download_invoice: String = "Stáhnout Fakturu"
override val download_pdf: String = "Stáhnout PDF"
override val draw_signature: String = "Nakreslete podpis"
override val due: Formattable = Formattable { (date) -> "Splatnost ${date}" }
override val due_date: String = "Datum splatnosti"
override val due_day: String = "za den"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} je splatný ${dueWhen}!" }
override val due_today: String = "dnes"
override val due_week: String = "do týdne"
override val duplicate: String = "Duplikovat"
override val duration_day: String = "den"
override val duration_month: String = "Měsíc"
override val duration_week: String = "týden"
override val duration_year: String = "rok"
override val email: String = "E-mail"
override val email_blank_error: String = "Požadovaný e-mail."
override val email_error: String = "Neplatná e-mailová adresa."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Děkujeme vám za váš obchod. Pro zobrazení celého dokumentu ${documentType} klikněte na tlačítko na konci tohoto dopisu. Upozorňujeme, že je platný do ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Obdrželi jste nový dokument: ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ZOBRAZIT ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Vážený ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Děkujeme vám za váš obchod. Pro zobrazení celé/ho ${documentType} klikněte na tlačítko na konci tohoto dopisu. Zůstatek prosím uhraďte do ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Obdrželi jste nový dokument: ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "E-mail pro obnovení hesla byl odeslán, zkontrolujte si prosím schránku."
override val empty_clients_list_message: String = "Nemáte žádné klienty, které byste museli sledovat. Přidejte nového klienta."
override val empty_estimate_list_message: String = "Vytvoření prvního odhadu"
override val empty_expenses: String = "Nemusíte sledovat žádné výdaje. Přidejte nový výdaj."
override val empty_invoice_list_message: String = "Vytvoření první faktury"
override val empty_items_list_message: String = "Nemáte žádné položky, přidejte novou položku!"
override val empty_signatures_list_message: String = "Nebyly nalezeny žádné podpisy. Vytvořte jeden!"
override val empty_taxes_list_message: String = "Neplatí pro vás žádná daňová pravidla. Přidejte nové daňové pravidlo."
override val empty_templates_list_message: String = "Šablony nejsou k dispozici."
override val enabled: String = "Povoleno"
override val entry: String = "položka"
override val equity: String = "vlastní kapitál"
override val estimate_number: String = "Odhad #"
override val estimates: String = "Odhady"
override val estimates_report: String = "Odhady"
override val expense_bank_fees: String = "Bankovní poplatky"
override val expense_benefits: String = "Výhody"
override val expense_communication: String = "Komunikace"
override val expense_debt: String = "Dluh"
override val expense_deleted: String = "Výdaje odstraněny"
override val expense_depreciation: String = "Odpisy"
override val expense_gifts: String = "Dárky"
override val expense_insurance: String = "Pojištění"
override val expense_inventory: String = "Inventura"
override val expense_legal: String = "Právní"
override val expense_maintenance: String = "Údržba"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Stravování"
override val expense_office_supplies: String = "Kancelářské potřeby"
override val expense_rent: String = "Pronájem"
override val expense_salaries: String = "Platy"
override val expense_shopping: String = "Nakupování"
override val expense_software: String = "Software"
override val expense_status_available: String = "Dostupné na"
override val expense_status_processing: String = "Zpracování"
override val expense_tax: String = "Daň"
override val expense_training: String = "Školení"
override val expense_transport: String = "Doprava"
override val expense_travel: String = "Cestování"
override val expense_utility: String = "Utility"
override val expenses: String = "Výdaje"
override val feedback_option: String = "Zpětná vazba"
override val filter: String = "Filtr"
override val first_name_hint: String = "Křestní jméno"
override val forgot_password: String = "Obnovení hesla"
override val forgot_password_label: String = "Zapomenuté heslo"
override val from: String = "Z"
override val general_error: String = "Došlo k chybě, zkuste to prosím později."
override val general_ledger: String = "hlavní kniha"
override val go_back_label: String = "Vrátit se zpět"
override val group_by: String = "Skupina podle:"
override val image_view: String = "Obrázek"
override val install: String = "Instalovat"
override val invalid_credentials_error: String = "Neplatné pověření."
override val invalid_email_error: String = "Neplatný e-mail."
override val invalid_user_error: String = "Neplatné pověření uživatele."
override val invoice_deleted_message: String = "Vymazaná faktura"
override val invoice_menu: String = "Nabídka faktur"
override val invoice_number: String = "Faktura #"
override val invoices: String = "faktury"
override val items_option: String = "položky"
override val keep: String = "Udržujte"
override val language_selector: Formattable = Formattable { (selected) -> "Jazyk ${selected}" }
override val last_name_hint: String = "Příjmení"
override val ledger: String = "účetní kniha"
override val legal: String = "Právní"
override val legal_error: String = "Musí přijmout podmínky služby a zásady ochrany osobních údajů"
override val liabilities: String = "závazky"
override val loading: String = "Načítání"
override val login_required_error: String = "Vyžaduje se přihlášení."
override val logo_view: String = "Logo"
override val logout: String = "Odhlášení"
override val logout_prompt: String = "Vaše data nejsou synchronizována s cloudem. Smazat faktury, odhady, klienty a další informace při odhlášení?"
override val main_screen: String = "Hlavní obrazovka"
override val mark_paid: String = "Označit jako zaplacené"
override val message: String = "Zpráva"
override val missing_sender_msg: String = "Dokument bez informací o odesílateli může být neplatný."
override val missing_sender_title: String = "Chybějící informace o odesílateli"
override val month_april: String = "Duben"
override val month_april_short: String = "Duben"
override val month_august: String = "Srpen"
override val month_august_short: String = "Srp."
override val month_december: String = "Prosinec"
override val month_december_short: String = "Pros."
override val month_february: String = "Únor"
override val month_february_short: String = "Únor"
override val month_january: String = "Leden"
override val month_january_short: String = "Led."
override val month_july: String = "Červenec"
override val month_july_short: String = "Červenec"
override val month_june: String = "Červen"
override val month_june_short: String = "Červen"
override val month_march: String = "Březen"
override val month_march_short: String = "Březen"
override val month_may: String = "Květen"
override val month_may_short: String = "Květen"
override val month_november: String = "Listopad"
override val month_november_short: String = "Lis."
override val month_october: String = "Říjen"
override val month_october_short: String = "Říj."
override val month_september: String = "září"
override val month_september_short: String = "Září"
override val multifactor_error: String = "Nepodařilo se ověřit."
override val new: String = "Nový"
override val news: String = "Zprávy"
override val next: String = "Další"
override val no: String = "ne"
override val no_data_available: String = "Nejsou k dispozici žádná data"
override val no_search_results: String = "Je nám líto, ale nenašli jsme žádné výsledky."
override val none: String = "Žádné"
override val note_fields: String = "Poznámky"
override val note_hint: String = "Poznámka"
override val notifications_option: String = "Oznámení"
override val ok: String = "OK"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Základní části aplikace DingVoice jsou navždy zdarma. Naším posláním je pomáhat vám rozvíjet vaše podnikání, pomozte nám zlepšovat se sdílením vaší zpětné vazby."
override val onboarding_aboutus_title: String = "Jsme tu, abychom vám pomohli"
override val onboarding_paid_1: String = "Přístup k profesionálně vypadajícím šablonám"
override val onboarding_paid_2: String = "Podívejte se hlouběji do svých financí"
override val onboarding_paid_3: String = "Rychlé řešení problémů"
override val onboarding_paid_4: String = "Uchovávejte své dokumenty v bezpečí"
override val onboarding_paid_5: String = "Aktuální informace o změnách stavu faktur"
override val onboarding_paid_title: String = "Ještě rychlejší růst díky..."
override val onboarding_paywall_message: String = "Využijte všechny prémiové funkce za nejlepší cenu."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Jen ${currencySymbol}${introAmount} za ${introDuration} ${introPeriod} a poté ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Jen ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Zdarma po dobu ${trialDuration} ${trialPeriod} a poté jen ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Zdarma na ${trialDuration} ${trialPeriod}, poté ${currencySymbol}${introductoryAmount} na ${introductoryDuration} ${introPeriod} a poté jen ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automatická obnova. Zrušit kdykoli."
override val onboarding_paywall_restore: String = "Obnovení nákupu"
override val onboarding_paywall_restore_failure: String = "Obnovení nákupů se nezdařilo, zkuste to později nebo kontaktujte podporu."
override val onboarding_paywall_restore_success: String = "Obnova úspěšná!"
override val onboarding_paywall_title: String = "Dosáhněte velikosti!"
override val onboarding_welcome_message: String = "Kompletní sada nástrojů pro správu faktur a odhadů."
override val onboarding_welcome_title: String = "Vítejte v DingVoice"
override val other_state: String = "Ostatní"
override val overdue_state: String = "Zpoždění"
override val paid_state: String = "Placené"
override val password: String = "Heslo"
override val password_blank_error: String = "Heslo je vyžadováno."
override val password_mismatch_error: String = "Hesla se neshodují."
override val pay: String = "zaplatit"
override val payment_instructions_label: String = "Pokyny k platbě"
override val payment_reminders: String = "Připomínky plateb"
override val phone_number_fields: String = "Telefonní číslo"
override val phone_number_hint: String = "Telefonní číslo"
override val postcode_hint: String = "Poštovní směrovací číslo"
override val preview: String = "Náhled"
override val preview_menu_hint: String = "Nabídka náhledu"
override val price: String = "Cena"
override val privacy_policy: String = "Zásady ochrany osobních údajů"
override val profile: String = "Profil"
override val profitable_client: String = "Vaším nejziskovějším klientem je..."
override val profits_report: String = "Zisky"
override val promo_content: String = "Propagační obsah"
override val quantity_hint: String = "Množství"
override val quantity_label_hint: String = "Označení množství"
override val query_info: String = "Dotaz umožňuje psát vlastní výpočty odkazující na položky i další daňová pravidla jako na vstupy. Přidáním symbolu @ a popisu položky nebo daně můžete odkazovat na jinou položku +, -, *, /, % - matematické operátory"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} byl přijat vaším klientem." }
override val received_state: String = "Přijaté"
override val registration_code_fields: String = "Registrační kód"
override val reminder: String = "Připomínka"
override val reports: String = "Zprávy"
override val reports_not_available: String = "Nejsou k dispozici žádné zprávy, vraťte se později."
override val reveal_password: String = "Odhalit heslo"
override val revenue: String = "výnosy"
override val sales_tax: String = "DPH"
override val sales_tax_number: String = "DIČ"
override val save: String = "Uložit"
override val scan_document: String = "Skenování dokumentu"
override val search: String = "Vyhledávání"
override val select: String = "Vyberte"
override val select_icon: String = "Vybrat ikonu"
override val select_signature: String = "Zvolte podpis"
override val select_template: String = "Vyberte šablonu"
override val send: String = "Odeslat"
override val sent_state: String = "Odesláno"
override val setup_sender: String = "Nastavit odesílatele"
override val share_option: String = "Sdílet"
override val ship_to: String = "Odeslat do"
override val sign_in: String = "Přihlásit se"
override val sign_in_apple: String = "Přihlášení pomocí Apple"
override val sign_in_email: String = "Přihlaste se pomocí e-mailu"
override val sign_in_facebook: String = "Přihlaste se pomocí služby Facebook"
override val sign_in_google: String = "Přihlášení pomocí služby Google"
override val sign_up: String = "Zaregistrujte se"
override val sign_up_label: String = "Nemáte účet?"
override val sign_up_link_label: String = "Zaregistrujte se!"
override val signatures_option: String = "Podpisy"
override val skip: String = "Přeskočit"
override val sold_items_report: String = "Prodané Položky"
override val sort_by: String = "Třídit podle:"
override val status_update: String = "Aktualizace stavu dokumentu"
override val sub_annual: String = "výroční"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/rok" }
override val sub_backups: String = "Zálohování do cloudu"
override val sub_insights: String = "Insights"
override val sub_month: String = "měsíční"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/měsíc" }
override val sub_notifications: String = "Oznámení"
override val sub_popup_message: String = "Předplatné se blíží. Za chvíli se sem vraťte."
override val sub_support: String = "Podpora"
override val sub_templates: String = "Šablony"
override val subscribe: String = "Přihlaste se k odběru na"
override val subscription_header: String = "Rozšiřte své podnikání pomocí:"
override val subscription_option: String = "Předplatné"
override val subscriptions_load_fail: String = "Je nám líto, ale v tuto chvíli nemůžeme načíst vaše předplatné. Zkuste to prosím později nebo se obraťte na náš tým podpory."
override val subtotal: Formattable = Formattable { (sum) -> "Mezisoučet: ${sum}" }
override val subtotal_label: String = "Mezisoučet"
override val sum: String = "Součet"
override val support_option: String = "Podpora"
override val switch_reports: String = "Přepnout Report"
override val tax: String = "DAŇ"
override val tax_deleted_message: String = "Vymazána daň"
override val tax_description_warning: String = "Pokud se použije, zobrazí se na faktuře"
override val tax_input_flat: String = "Ploché"
override val tax_input_percent: String = "Procenta"
override val tax_number_fields: String = "Daňové číslo"
override val tax_number_hint: String = "Daňové číslo"
override val tax_query_info_description: String = "Informace o dotazu"
override val tax_report: String = "Daň"
override val tax_rules_option: String = "Daňová pravidla"
override val taxed_clients_report: String = "Zdanění klienti"
override val template: String = "Šablona"
override val template_selector_info: String = "Posunutím změníte výchozí šablonu"
override val template_unlock_promo: String = "Přístup k dalším šablonám s funkcí Premium"
override val templates_option: String = "Šablony"
override val terms_of_service: String = "Podmínky služby"
override val thank_you: String = "Děkujeme"
override val to: String = "Na"
override val total: Formattable = Formattable { (sum) -> "Celkem: ${sum}" }
override val total_label: String = "CELKEM"
override val undo_action: String = "Zrušit"
override val unit: String = "Jednotka"
override val unit_cost_hint: String = "Jednotkové náklady"
override val unknown_client: String = "Neznámý Klient"
override val unlock: String = "Odemknutí stránky"
override val unnamed_item: String = "Nepojmenovaná položka"
override val upgrade: String = "Upgrade"
override val user_collision_error: String = "Účet již existuje."
override val valid_until: String = "Platnost Do"
override val warning: String = "Pozor!"
override val yes: String = "ano"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Odsouhlaste ${termsOfService} a ${privacyPolicy}" },
	"account" to "účet",
	"accounting" to "účetnictví",
	"accruals" to "časové rozlišení",
	"action_code_error" to "Neplatný kód.",
	"add_client_action" to "Přidat klienta",
	"add_customer" to "Přidat zákazníka",
	"add_expense" to "Přidat výdaje",
	"add_fields_label" to "Přidat:",
	"add_invoice_action" to "Vytvořit",
	"add_item_action" to "Přidat položku",
	"add_item_button" to "Přidat položku",
	"add_signature_action" to "Přidat podpis",
	"add_tax_action" to "Přidat daň",
	"address_fields" to "Adresa",
	"address_line_hint" to "Adresní řádek",
	"advanced" to "ADVANCED",
	"agree_marketing" to "Souhlasím se zasíláním přímých marketingových sdělení",
	"all_notifications" to "Všechna oznámení",
	"amortization" to "amortizace",
	"amount" to "Částka",
	"asset" to "aktivum",
	"attach_file" to "Připojit soubor",
	"attachment" to "Příloha",
	"auth_error" to "Akce se nezdařila, zkuste to znovu.",
	"available_for_paid" to "Cloudová synchronizace je k dispozici pouze v rámci placeného předplatného.",
	"backups_option" to "Zálohování",
	"balance" to "zůstatek",
	"bank_account" to "Bankovní Účet",
	"bill_number" to "Číslo účtu",
	"cancel" to "Zrušit",
	"cancel_trial" to "Zrušte kdykoli",
	"capital" to "kapitál",
	"cash" to "hotovost",
	"categories" to "Kategorie",
	"category" to "Kategorie",
	"category_add" to "Přidat kategorii",
	"category_delete" to "Odstranit kategorii",
	"category_name" to "Název kategorie",
	"change_language" to Formattable { (language) -> "Jazyk faktury: ${language}" },
	"change_language_label" to "Jazyk Faktury",
	"change_template" to "Změna šablony",
	"city_hint" to "Město",
	"clear" to "Přehledně",
	"client" to "Klient",
	"client_actions" to "Akce klienta",
	"client_deleted_message" to "Klient smazán",
	"clients_error_required_fields" to "Je vyžadována alespoň jedna hodnota.",
	"clients_option" to "Klienti",
	"close" to "Zavřít",
	"cloud_sync" to "Synchronizace s cloudem",
	"coming_soon" to "Již brzy!",
	"company_logo" to "Logo společnosti",
	"company_name_hint" to "Společnost",
	"confirm_password_label" to "Potvrzení hesla",
	"confirmation_title" to "Jste si jisti?",
	"convert_to_invoice" to "Převést na fakturu",
	"country_hint" to "Země",
	"create_client" to "Vytvořit klienta",
	"create_company" to "Vytvořit společnost",
	"create_document" to "Vytvořit dokument",
	"create_estimate" to "Odhad",
	"create_invoice" to "Faktura",
	"create_item" to "Vytvořit položku",
	"create_new_client" to "Vytvořit nového klienta",
	"create_new_item" to "Vytvořit novou položku",
	"create_new_tax" to "Vytvořit novou daň",
	"create_signature" to "Podpis",
	"created" to "Vytvořeno",
	"credit" to "kreditní",
	"customer" to "Zákazník",
	"date" to "Datum",
	"debit" to "debetní",
	"debt" to "dluh",
	"default_currency" to Formattable { (currency) -> "Výchozí měna: ${currency}" },
	"default_currency_label" to "Výchozí měna",
	"delete" to "Odstranit",
	"delete_account" to "Smazat Účet",
	"delete_confirmation_message" to "Všechna vaše data budou ztracena.",
	"delete_item_title" to "Smazat položku?",
	"delete_signature" to "Odstranění podpisu",
	"depreciation" to "odpisy",
	"description" to "Popis",
	"description_hint" to "Popis",
	"detailed_report" to "Podrobná Zpráva",
	"disabled" to "Bezbariérový",
	"discount" to "Sleva",
	"discount_hint" to "Sleva",
	"display_af" to "Afrikánština",
	"display_am" to "Amharština",
	"display_ar" to "Arabština",
	"display_as" to "Ásámština",
	"display_az" to "Ázerbájdžánština",
	"display_be" to "Běloruština",
	"display_bg" to "Bulharština",
	"display_bn" to "Bengálština",
	"display_bs" to "Bosenština",
	"display_ca" to "Katalánština",
	"display_cs" to "Čeština",
	"display_da" to "Dánština",
	"display_de" to "Němčina",
	"display_el" to "Řečtina",
	"display_en" to "Angličtina",
	"display_es" to "Španělština",
	"display_es_MX" to "Španělština (Mexiko)",
	"display_et" to "Estonština",
	"display_eu" to "Baskičtina",
	"display_fa" to "Perština",
	"display_fi" to "Finština",
	"display_fil" to "Filipínština",
	"display_fr" to "Francouzština",
	"display_fr_CA" to "Kanadská francouzština",
	"display_gl" to "Galicijština",
	"display_gu" to "Gudžarátština",
	"display_he" to "Hebrejština",
	"display_hi" to "Hindština",
	"display_hr" to "Chorvatština",
	"display_hu" to "Maďarština",
	"display_hy" to "Arménština",
	"display_id" to "Indonéština",
	"display_is" to "Islandština",
	"display_it" to "Italština",
	"display_ja" to "Japonština",
	"display_ka" to "Gruzínština",
	"display_kk" to "Kazachština",
	"display_km" to "Střední Khmerové",
	"display_kn" to "Kannada",
	"display_ko" to "Korejština",
	"display_ky" to "Kirgizština",
	"display_lo" to "Lao",
	"display_lt" to "Litevština",
	"display_lv" to "Lotyština",
	"display_mk" to "Makedonština",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolština",
	"display_mr" to "Maráthština",
	"display_ms" to "Malajština",
	"display_my" to "Barmština",
	"display_nb" to "Norština - Bokmål",
	"display_ne" to "Nepálština",
	"display_nl" to "Holandština",
	"display_or" to "Oriya",
	"display_pa" to "Paňdžábština",
	"display_pl" to "Polština",
	"display_pt" to "Portugalština",
	"display_pt_BR" to "Portugalština (Brazílie)",
	"display_pt_PT" to "Evropská portugalština",
	"display_ro" to "Rumunština",
	"display_ru" to "Ruština",
	"display_si" to "Sinhálština",
	"display_sk" to "Slovenština",
	"display_sl" to "Slovinština",
	"display_sq" to "Albánština",
	"display_sr" to "Srbština",
	"display_sv" to "Švédština",
	"display_sw" to "Svahilština",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thajština",
	"display_tr" to "Turečtina",
	"display_uk" to "Ukrajinština",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbečtina",
	"display_vi" to "Vietnamština",
	"display_zh_Hant_TW" to "Čínština (Tchaj-wan, Tradiční)",
	"display_zu" to "Zulu",
	"document_estimate" to "Odhad",
	"document_invoice" to "Faktura",
	"document_quote" to "Cenová nabídka",
	"done" to "Hotovo",
	"download_app" to "Stáhněte si aplikaci",
	"download_invoice" to "Stáhnout Fakturu",
	"download_pdf" to "Stáhnout PDF",
	"draw_signature" to "Nakreslete podpis",
	"due" to Formattable { (date) -> "Splatnost ${date}" },
	"due_date" to "Datum splatnosti",
	"due_day" to "za den",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} je splatný ${dueWhen}!" },
	"due_today" to "dnes",
	"due_week" to "do týdne",
	"duplicate" to "Duplikovat",
	"duration_day" to "den",
	"duration_month" to "Měsíc",
	"duration_week" to "týden",
	"duration_year" to "rok",
	"email" to "E-mail",
	"email_blank_error" to "Požadovaný e-mail.",
	"email_error" to "Neplatná e-mailová adresa.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Děkujeme vám za váš obchod. Pro zobrazení celého dokumentu ${documentType} klikněte na tlačítko na konci tohoto dopisu. Upozorňujeme, že je platný do ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Obdrželi jste nový dokument: ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "ZOBRAZIT ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Vážený ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Děkujeme vám za váš obchod. Pro zobrazení celé/ho ${documentType} klikněte na tlačítko na konci tohoto dopisu. Zůstatek prosím uhraďte do ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Obdrželi jste nový dokument: ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "E-mail pro obnovení hesla byl odeslán, zkontrolujte si prosím schránku.",
	"empty_clients_list_message" to "Nemáte žádné klienty, které byste museli sledovat. Přidejte nového klienta.",
	"empty_estimate_list_message" to "Vytvoření prvního odhadu",
	"empty_expenses" to "Nemusíte sledovat žádné výdaje. Přidejte nový výdaj.",
	"empty_invoice_list_message" to "Vytvoření první faktury",
	"empty_items_list_message" to "Nemáte žádné položky, přidejte novou položku!",
	"empty_signatures_list_message" to "Nebyly nalezeny žádné podpisy. Vytvořte jeden!",
	"empty_taxes_list_message" to "Neplatí pro vás žádná daňová pravidla. Přidejte nové daňové pravidlo.",
	"empty_templates_list_message" to "Šablony nejsou k dispozici.",
	"enabled" to "Povoleno",
	"entry" to "položka",
	"equity" to "vlastní kapitál",
	"estimate_number" to "Odhad #",
	"estimates" to "Odhady",
	"estimates_report" to "Odhady",
	"expense_bank_fees" to "Bankovní poplatky",
	"expense_benefits" to "Výhody",
	"expense_communication" to "Komunikace",
	"expense_debt" to "Dluh",
	"expense_deleted" to "Výdaje odstraněny",
	"expense_depreciation" to "Odpisy",
	"expense_gifts" to "Dárky",
	"expense_insurance" to "Pojištění",
	"expense_inventory" to "Inventura",
	"expense_legal" to "Právní",
	"expense_maintenance" to "Údržba",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Stravování",
	"expense_office_supplies" to "Kancelářské potřeby",
	"expense_rent" to "Pronájem",
	"expense_salaries" to "Platy",
	"expense_shopping" to "Nakupování",
	"expense_software" to "Software",
	"expense_status_available" to "Dostupné na",
	"expense_status_processing" to "Zpracování",
	"expense_tax" to "Daň",
	"expense_training" to "Školení",
	"expense_transport" to "Doprava",
	"expense_travel" to "Cestování",
	"expense_utility" to "Utility",
	"expenses" to "Výdaje",
	"feedback_option" to "Zpětná vazba",
	"filter" to "Filtr",
	"first_name_hint" to "Křestní jméno",
	"forgot_password" to "Obnovení hesla",
	"forgot_password_label" to "Zapomenuté heslo",
	"from" to "Z",
	"general_error" to "Došlo k chybě, zkuste to prosím později.",
	"general_ledger" to "hlavní kniha",
	"go_back_label" to "Vrátit se zpět",
	"group_by" to "Skupina podle:",
	"image_view" to "Obrázek",
	"install" to "Instalovat",
	"invalid_credentials_error" to "Neplatné pověření.",
	"invalid_email_error" to "Neplatný e-mail.",
	"invalid_user_error" to "Neplatné pověření uživatele.",
	"invoice_deleted_message" to "Vymazaná faktura",
	"invoice_menu" to "Nabídka faktur",
	"invoice_number" to "Faktura #",
	"invoices" to "faktury",
	"items_option" to "položky",
	"keep" to "Udržujte",
	"language_selector" to Formattable { (selected) -> "Jazyk ${selected}" },
	"last_name_hint" to "Příjmení",
	"ledger" to "účetní kniha",
	"legal" to "Právní",
	"legal_error" to "Musí přijmout podmínky služby a zásady ochrany osobních údajů",
	"liabilities" to "závazky",
	"loading" to "Načítání",
	"login_required_error" to "Vyžaduje se přihlášení.",
	"logo_view" to "Logo",
	"logout" to "Odhlášení",
	"logout_prompt" to "Vaše data nejsou synchronizována s cloudem. Smazat faktury, odhady, klienty a další informace při odhlášení?",
	"main_screen" to "Hlavní obrazovka",
	"mark_paid" to "Označit jako zaplacené",
	"message" to "Zpráva",
	"missing_sender_msg" to "Dokument bez informací o odesílateli může být neplatný.",
	"missing_sender_title" to "Chybějící informace o odesílateli",
	"month_april" to "Duben",
	"month_april_short" to "Duben",
	"month_august" to "Srpen",
	"month_august_short" to "Srp.",
	"month_december" to "Prosinec",
	"month_december_short" to "Pros.",
	"month_february" to "Únor",
	"month_february_short" to "Únor",
	"month_january" to "Leden",
	"month_january_short" to "Led.",
	"month_july" to "Červenec",
	"month_july_short" to "Červenec",
	"month_june" to "Červen",
	"month_june_short" to "Červen",
	"month_march" to "Březen",
	"month_march_short" to "Březen",
	"month_may" to "Květen",
	"month_may_short" to "Květen",
	"month_november" to "Listopad",
	"month_november_short" to "Lis.",
	"month_october" to "Říjen",
	"month_october_short" to "Říj.",
	"month_september" to "září",
	"month_september_short" to "Září",
	"multifactor_error" to "Nepodařilo se ověřit.",
	"new" to "Nový",
	"news" to "Zprávy",
	"next" to "Další",
	"no" to "ne",
	"no_data_available" to "Nejsou k dispozici žádná data",
	"no_search_results" to "Je nám líto, ale nenašli jsme žádné výsledky.",
	"none" to "Žádné",
	"note_fields" to "Poznámky",
	"note_hint" to "Poznámka",
	"notifications_option" to "Oznámení",
	"ok" to "OK",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Základní části aplikace DingVoice jsou navždy zdarma. Naším posláním je pomáhat vám rozvíjet vaše podnikání, pomozte nám zlepšovat se sdílením vaší zpětné vazby.",
	"onboarding_aboutus_title" to "Jsme tu, abychom vám pomohli",
	"onboarding_paid_1" to "Přístup k profesionálně vypadajícím šablonám",
	"onboarding_paid_2" to "Podívejte se hlouběji do svých financí",
	"onboarding_paid_3" to "Rychlé řešení problémů",
	"onboarding_paid_4" to "Uchovávejte své dokumenty v bezpečí",
	"onboarding_paid_5" to "Aktuální informace o změnách stavu faktur",
	"onboarding_paid_title" to "Ještě rychlejší růst díky...",
	"onboarding_paywall_message" to "Využijte všechny prémiové funkce za nejlepší cenu.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Jen ${currencySymbol}${introAmount} za ${introDuration} ${introPeriod} a poté ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Jen ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Zdarma po dobu ${trialDuration} ${trialPeriod} a poté jen ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Zdarma na ${trialDuration} ${trialPeriod}, poté ${currencySymbol}${introductoryAmount} na ${introductoryDuration} ${introPeriod} a poté jen ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automatická obnova. Zrušit kdykoli.",
	"onboarding_paywall_restore" to "Obnovení nákupu",
	"onboarding_paywall_restore_failure" to "Obnovení nákupů se nezdařilo, zkuste to později nebo kontaktujte podporu.",
	"onboarding_paywall_restore_success" to "Obnova úspěšná!",
	"onboarding_paywall_title" to "Dosáhněte velikosti!",
	"onboarding_welcome_message" to "Kompletní sada nástrojů pro správu faktur a odhadů.",
	"onboarding_welcome_title" to "Vítejte v DingVoice",
	"other_state" to "Ostatní",
	"overdue_state" to "Zpoždění",
	"paid_state" to "Placené",
	"password" to "Heslo",
	"password_blank_error" to "Heslo je vyžadováno.",
	"password_mismatch_error" to "Hesla se neshodují.",
	"pay" to "zaplatit",
	"payment_instructions_label" to "Pokyny k platbě",
	"payment_reminders" to "Připomínky plateb",
	"phone_number_fields" to "Telefonní číslo",
	"phone_number_hint" to "Telefonní číslo",
	"postcode_hint" to "Poštovní směrovací číslo",
	"preview" to "Náhled",
	"preview_menu_hint" to "Nabídka náhledu",
	"price" to "Cena",
	"privacy_policy" to "Zásady ochrany osobních údajů",
	"profile" to "Profil",
	"profitable_client" to "Vaším nejziskovějším klientem je...",
	"profits_report" to "Zisky",
	"promo_content" to "Propagační obsah",
	"quantity_hint" to "Množství",
	"quantity_label_hint" to "Označení množství",
	"query_info" to "Dotaz umožňuje psát vlastní výpočty odkazující na položky i další daňová pravidla jako na vstupy. Přidáním symbolu @ a popisu položky nebo daně můžete odkazovat na jinou položku +, -, *, /, % - matematické operátory",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} byl přijat vaším klientem." },
	"received_state" to "Přijaté",
	"registration_code_fields" to "Registrační kód",
	"reminder" to "Připomínka",
	"reports" to "Zprávy",
	"reports_not_available" to "Nejsou k dispozici žádné zprávy, vraťte se později.",
	"reveal_password" to "Odhalit heslo",
	"revenue" to "výnosy",
	"sales_tax" to "DPH",
	"sales_tax_number" to "DIČ",
	"save" to "Uložit",
	"scan_document" to "Skenování dokumentu",
	"search" to "Vyhledávání",
	"select" to "Vyberte",
	"select_icon" to "Vybrat ikonu",
	"select_signature" to "Zvolte podpis",
	"select_template" to "Vyberte šablonu",
	"send" to "Odeslat",
	"sent_state" to "Odesláno",
	"setup_sender" to "Nastavit odesílatele",
	"share_option" to "Sdílet",
	"ship_to" to "Odeslat do",
	"sign_in" to "Přihlásit se",
	"sign_in_apple" to "Přihlášení pomocí Apple",
	"sign_in_email" to "Přihlaste se pomocí e-mailu",
	"sign_in_facebook" to "Přihlaste se pomocí služby Facebook",
	"sign_in_google" to "Přihlášení pomocí služby Google",
	"sign_up" to "Zaregistrujte se",
	"sign_up_label" to "Nemáte účet?",
	"sign_up_link_label" to "Zaregistrujte se!",
	"signatures_option" to "Podpisy",
	"skip" to "Přeskočit",
	"sold_items_report" to "Prodané Položky",
	"sort_by" to "Třídit podle:",
	"status_update" to "Aktualizace stavu dokumentu",
	"sub_annual" to "výroční",
	"sub_annual_price" to Formattable { (price) -> "${price}/rok" },
	"sub_backups" to "Zálohování do cloudu",
	"sub_insights" to "Insights",
	"sub_month" to "měsíční",
	"sub_monthly_price" to Formattable { (price) -> "${price}/měsíc" },
	"sub_notifications" to "Oznámení",
	"sub_popup_message" to "Předplatné se blíží. Za chvíli se sem vraťte.",
	"sub_support" to "Podpora",
	"sub_templates" to "Šablony",
	"subscribe" to "Přihlaste se k odběru na",
	"subscription_header" to "Rozšiřte své podnikání pomocí:",
	"subscription_option" to "Předplatné",
	"subscriptions_load_fail" to "Je nám líto, ale v tuto chvíli nemůžeme načíst vaše předplatné. Zkuste to prosím později nebo se obraťte na náš tým podpory.",
	"subtotal" to Formattable { (sum) -> "Mezisoučet: ${sum}" },
	"subtotal_label" to "Mezisoučet",
	"sum" to "Součet",
	"support_option" to "Podpora",
	"switch_reports" to "Přepnout Report",
	"tax" to "DAŇ",
	"tax_deleted_message" to "Vymazána daň",
	"tax_description_warning" to "Pokud se použije, zobrazí se na faktuře",
	"tax_input_flat" to "Ploché",
	"tax_input_percent" to "Procenta",
	"tax_number_fields" to "Daňové číslo",
	"tax_number_hint" to "Daňové číslo",
	"tax_query_info_description" to "Informace o dotazu",
	"tax_report" to "Daň",
	"tax_rules_option" to "Daňová pravidla",
	"taxed_clients_report" to "Zdanění klienti",
	"template" to "Šablona",
	"template_selector_info" to "Posunutím změníte výchozí šablonu",
	"template_unlock_promo" to "Přístup k dalším šablonám s funkcí Premium",
	"templates_option" to "Šablony",
	"terms_of_service" to "Podmínky služby",
	"thank_you" to "Děkujeme",
	"to" to "Na",
	"total" to Formattable { (sum) -> "Celkem: ${sum}" },
	"total_label" to "CELKEM",
	"undo_action" to "Zrušit",
	"unit" to "Jednotka",
	"unit_cost_hint" to "Jednotkové náklady",
	"unknown_client" to "Neznámý Klient",
	"unlock" to "Odemknutí stránky",
	"unnamed_item" to "Nepojmenovaná položka",
	"upgrade" to "Upgrade",
	"user_collision_error" to "Účet již existuje.",
	"valid_until" to "Platnost Do",
	"warning" to "Pozor!",
	"yes" to "ano",
    )
    
}