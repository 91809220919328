// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Am  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} እና ${privacyPolicy}ን ይቀበሉ" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "የተሳሳተ ኮድ።"
override val add_client_action: String = "ተገልጋይ እዚህ ያስገቡ"
override val add_customer: String = "ደንበኛ እዚህ ያክሉ"
override val add_expense: String = "ወጪ ጨምር"
override val add_fields_label: String = "አክል:"
override val add_invoice_action: String = "ይፍጠሩ"
override val add_item_action: String = "ዓይነቱን ያስገቡ"
override val add_item_button: String = "ዓይነቱን ያስገቡ"
override val add_signature_action: String = "ፊርማ አክል"
override val add_tax_action: String = "ግብር አክል"
override val address_fields: String = "አድራሻ"
override val address_line_hint: String = "የአድራሻ መስመር"
override val advanced: String = "የላቀ"
override val agree_marketing: String = "ቀጥተኛ የግብይት ግንኙነቶችን ለመቀበል ተስማምቻለሁ"
override val all_notifications: String = "ሁሉም ማሳወቂያዎች"
override val amortization: String = "amortization"
override val amount: String = "መጠን"
override val asset: String = "asset"
override val attach_file: String = "ፋይል አያይዝ"
override val attachment: String = "አባሪ"
override val auth_error: String = "እርምጃ አልተሳካም, እንደገና ይሞክሩ።"
override val available_for_paid: String = "የደመና ማመሳሰል የሚገኘው በሚከፈልበት የደንበኝነት ምዝገባ ብቻ ነው።"
override val backups_option: String = "ምትኬዎች"
override val balance: String = "balance"
override val bank_account: String = "የባንክ ሒሳብ"
override val bill_number: String = "የሂሳብ ቁጥር"
override val cancel: String = "ሰርዝ"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "ምድቦች"
override val category: String = "ምድብ"
override val category_add: String = "ምድብ ጨምር"
override val category_delete: String = "ምድብ ሰርዝ"
override val category_name: String = "የምድብ ስም"
override val change_language: Formattable = Formattable { (language) -> "የክፍያ መጠየቂያ ቋንቋ፡ ${language}" }
override val change_language_label: String = "የክፍያ መጠየቂያ ቋንቋ"
override val change_template: String = "አብነት ይቀይሩ"
override val city_hint: String = "ከተማ"
override val clear: String = "አጽዳ"
override val client: String = "ደንበኛ"
override val client_actions: String = "የደንበኛ እርምጃዎች"
override val client_deleted_message: String = "ደንበኛ ተሰርዟል"
override val clients_error_required_fields: String = "ቢያንስ አንድ እሴት ያስፈልጋል።"
override val clients_option: String = "ደንበኞች"
override val close: String = "ዝጋ"
override val cloud_sync: String = "የደመና አመሳስል"
override val coming_soon: String = "በቅርቡ ይመጣል!"
override val company_logo: String = "የኩባንያ አርማ"
override val company_name_hint: String = "ኩባንያ"
override val confirm_password_label: String = "ይለፍ ቃል ያረጋግጡ"
override val confirmation_title: String = "እርግጠኛ ነህ?"
override val convert_to_invoice: String = "ወደ ደረሰኝ መለወጥ"
override val country_hint: String = "አገር"
override val create_client: String = "ደንበኛ ይፍጠሩ"
override val create_company: String = "ኩባንያ ፍጠር"
override val create_document: String = "ሰነድ ይፍጠሩ"
override val create_estimate: String = "መገመት"
override val create_invoice: String = "የክፍያ መጠየቂያ"
override val create_item: String = "ንጥል ይፍጠሩ"
override val create_new_client: String = "አዲስ ደንበኛ ይፍጠሩ"
override val create_new_item: String = "አዲስ ንጥል ይፍጠሩ"
override val create_new_tax: String = "አዲስ ግብር ይፍጠሩ"
override val create_signature: String = "ፊርማ"
override val created: String = "ተፈጥሯል"
override val credit: String = "credit"
override val customer: String = "ደንበኛ"
override val date: String = "ቀን"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "ነባሪ ገንዘብ ${currency}" }
override val default_currency_label: String = "ነባሪ ምንዛሬ"
override val delete: String = "ሰርዝ"
override val delete_account: String = "መለያ ሰርዝ"
override val delete_confirmation_message: String = "ሁሉም የእርስዎ ውሂብ ይጠፋል።"
override val delete_item_title: String = "ንጥሉን ሰርዝ?"
override val delete_signature: String = "ፊርማ ሰርዝ"
override val depreciation: String = "depreciation"
override val description: String = "መግለጫ"
override val description_hint: String = "መግለጫ"
override val detailed_report: String = "ዝርዝር ዘገባ"
override val disabled: String = "ተሰናክሏል"
override val discount: String = "የዋጋ ቅናሽ"
override val discount_hint: String = "የዋጋ ቅናሽ"
override val display_af: String = "አፍሪካንስ"
override val display_am: String = "አማርኛ"
override val display_ar: String = "አረብኛ"
override val display_as: String = "አዛሚስ"
override val display_az: String = "አዘርባጃኒኛ"
override val display_be: String = "ቤላሩሳዊ"
override val display_bg: String = "ቡልጋርያኛ"
override val display_bn: String = "ቤንጋሊ"
override val display_bs: String = "ቦስንያን"
override val display_ca: String = "ካታላን"
override val display_cs: String = "ቼክ"
override val display_da: String = "ዴንማርካዊ"
override val display_de: String = "ጀርመንኛ"
override val display_el: String = "ግሪክኛ"
override val display_en: String = "እንግሊዝኛ"
override val display_es: String = "ስፓኒሽ"
override val display_es_MX: String = "ስፓኒሽ (ሜክሲኮ)"
override val display_et: String = "ኢስቶኒያን"
override val display_eu: String = "ባስክ"
override val display_fa: String = "ፐርሺያዊ"
override val display_fi: String = "ፊንላንዳዊ"
override val display_fil: String = "ፊሊፒንስ"
override val display_fr: String = "ፈረንሳይኛ"
override val display_fr_CA: String = "ካናዳዊ ፈረንሳይኛ"
override val display_gl: String = "ጋላሺያን"
override val display_gu: String = "ጉጅራቲ"
override val display_he: String = "ሂብሩ"
override val display_hi: String = "ሂንዲ"
override val display_hr: String = "ክሮሽያዊ"
override val display_hu: String = "ሃንጋሪያን"
override val display_hy: String = "አርመንያኛ"
override val display_id: String = "ኢንዶኔዥያን"
override val display_is: String = "አይስላንዳዊ"
override val display_it: String = "ጣሊያንኛ"
override val display_ja: String = "ጃፓንኛ"
override val display_ka: String = "ጆርጅያዊ"
override val display_kk: String = "ካዛክሀ"
override val display_km: String = "ማዕከላዊ ክመርኛ"
override val display_kn: String = "ካናዳ"
override val display_ko: String = "ኮሪያኛ"
override val display_ky: String = "ኪርጊዝ"
override val display_lo: String = "ላኦ"
override val display_lt: String = "ሊቱኒያን"
override val display_lv: String = "ላትቪያን"
override val display_mk: String = "ማስዶንያን"
override val display_ml: String = "ማላያላም"
override val display_mn: String = "ሞኒጎሊያን"
override val display_mr: String = "ማራዚኛ"
override val display_ms: String = "ማላይ"
override val display_my: String = "የበርማ ሰው"
override val display_nb: String = "የኖርዌይ ቦክማል"
override val display_ne: String = "ኔፓሊ"
override val display_nl: String = "ደች"
override val display_or: String = "ኦሪያ"
override val display_pa: String = "ፑንጃቢ"
override val display_pl: String = "መጥረግ"
override val display_pt: String = "ፖርቹጋልኛ"
override val display_pt_BR: String = "ፖርቹጋልኛ (ብራዚል)"
override val display_pt_PT: String = "አውሮፓውያን ፖርቱጋ"
override val display_ro: String = "ሮማንያዊ"
override val display_ru: String = "ራሺያኛ"
override val display_si: String = "ሲንሃላ"
override val display_sk: String = "ስሎቫክ"
override val display_sl: String = "ስሎቬንያን"
override val display_sq: String = "አልበንያኛ"
override val display_sr: String = "ሰሪቢያን"
override val display_sv: String = "ስዊድንኛ"
override val display_sw: String = "ስዋሂሊኛ"
override val display_ta: String = "ታሚል"
override val display_te: String = "ተሉጉ"
override val display_th: String = "ታይ"
override val display_tr: String = "ቱርካዊ"
override val display_uk: String = "ዩክሬንያን"
override val display_ur: String = "ኡርዱ"
override val display_uz: String = "ኡዝቤክ"
override val display_vi: String = "ቪትናሜሴ"
override val display_zh_Hant_TW: String = "ቻይንኛ (ታይዋን, ባህላዊ)"
override val display_zu: String = "ዙሉ"
override val document_estimate: String = "መገመት"
override val document_invoice: String = "የክፍያ መጠየቂያ"
override val document_quote: String = "የዋጋ አቅርቦት"
override val done: String = "ተከናውኗል"
override val download_app: String = "መተግበሪያውን ያውርዱ"
override val download_invoice: String = "ደረሰኝ አውርድ"
override val download_pdf: String = "ፒዲኤፍ አውርድ"
override val draw_signature: String = "ፊርማ ይሳሉ"
override val due: Formattable = Formattable { (date) -> "ምክንያት ${date}" }
override val due_date: String = "የጊዜ ገደብ"
override val due_day: String = "በአንድ ቀን"
override val due_label: String = "ምክንያት"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ምክንያት ነው ${dueWhen}!" }
override val due_today: String = "ዛሬ"
override val due_week: String = "በአንድ ሳምንት ውስጥ"
override val duplicate: String = "የተባዛ"
override val duration_day: String = "ቀን"
override val duration_month: String = "ወር"
override val duration_week: String = "ሳምንት"
override val duration_year: String = "አመት"
override val email: String = "ኢሜይል"
override val email_blank_error: String = "ኢሜይል ያስፈልጋል።"
override val email_error: String = "ልክ ያልሆነ የኢሜይል አድራሻ።"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "የእርስዎን ንግድ እናመሰግናለን። ሙሉ ለማየት ${documentType} በዚህ ደብዳቤ መጨረሻ ላይ ያለውን አዝራር ጠቅ ያድርጉ። ማስታወሻ እስከ ${dueDate} ድረስ የሚሰራ መሆኑን ልብ ይበሉ።" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "አዲስ ${documentType} ${invoiceNumber} ተቀብለዋል!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ደረሰኝ ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "ውድ ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "የእርስዎን ንግድ እናመሰግናለን። ሙሉ ለማየት ${documentType} በዚህ ደብዳቤ መጨረሻ ላይ ያለውን አዝራር ጠቅ ያድርጉ። እባክዎ እስከ ${dueDate} ድረስ ሂሳብዎን ይክፈሉ።" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "አዲስ ${documentType} ${invoiceNumber} ተቀብለዋል!" }
override val email_reset_success_message: String = "የተላከ የይለፍ ቃል ዳግም ለማስጀመር ኢሜይል, እባክዎ የገቢ መልዕክት ሳጥንዎን ያረጋግጡ።"
override val empty_clients_list_message: String = "ለመከታተል ምንም ደንበኞች የሉዎትም። አዲስ ደንበኛ ያክሉ።"
override val empty_estimate_list_message: String = "የመጀመሪያ ግምትዎን ይፍጠሩ"
override val empty_expenses: String = "ለመከታተል ምንም ወጪ የለዎትም። አዲስ ወጪ ጨምር።"
override val empty_invoice_list_message: String = "የመጀመሪያ ደረሰኝዎን ይፍጠሩ"
override val empty_items_list_message: String = "ምንም ንጥሎች የሉዎትም, አዲስ ንጥል ያክሉ!"
override val empty_signatures_list_message: String = "ምንም ፊርማ አልተገኘም። አንድ ፍጠር!"
override val empty_taxes_list_message: String = "ምንም የግብር ደንቦች የሉዎትም። አዲስ የግብር ደንብ ያክሉ።"
override val empty_templates_list_message: String = "አይገኝም አብነቶች።"
override val enabled: String = "ነቅቷል"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "ግምቱ #"
override val estimates: String = "ግምቶች"
override val estimates_report: String = "ግምቶች"
override val expense_bank_fees: String = "የባንክ ክፍያዎች"
override val expense_benefits: String = "ጥቅሞች"
override val expense_communication: String = "ግንኙነት"
override val expense_debt: String = "ዕዳ"
override val expense_deleted: String = "ወጪ ተሰርዟል።"
override val expense_depreciation: String = "የዋጋ ቅነሳ"
override val expense_gifts: String = "ስጦታዎች"
override val expense_insurance: String = "ኢንሹራንስ"
override val expense_inventory: String = "ቆጠራ"
override val expense_legal: String = "ህጋዊ"
override val expense_maintenance: String = "ጥገና"
override val expense_marketing: String = "ግብይት"
override val expense_meals: String = "ምግቦች"
override val expense_office_supplies: String = "የቢሮ መገልገያ ዕቃዎች"
override val expense_rent: String = "ይከራዩ"
override val expense_salaries: String = "ደሞዝ"
override val expense_shopping: String = "ግዢ"
override val expense_software: String = "ሶፍትዌር"
override val expense_status_available: String = "ይገኛል።"
override val expense_status_processing: String = "በማቀነባበር ላይ"
override val expense_tax: String = "ግብር"
override val expense_training: String = "ስልጠና"
override val expense_transport: String = "መጓጓዣ"
override val expense_travel: String = "ጉዞ"
override val expense_utility: String = "መገልገያ"
override val expenses: String = "ወጪዎች"
override val feedback_option: String = "ግብረመልስ"
override val filter: String = "ማጣሪያ"
override val first_name_hint: String = "የመጀመሪያ ስም"
override val forgot_password: String = "የይለፍ ቃል ዳግም አስጀምር"
override val forgot_password_label: String = "የይለፍ ቃል ረሱ"
override val from: String = "ከ"
override val general_error: String = "ስህተት ተከስቷል፣ እባክዎ ቆይተው እንደገና ይሞክሩ።"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "ወደ ኋላ ተመለስ"
override val group_by: String = "ቡድን በ:"
override val image_view: String = "ምስል"
override val install: String = "ጫን"
override val invalid_credentials_error: String = "ልክ ያልሆነ ምስክርነቶች።"
override val invalid_email_error: String = "ልክ ያልሆነ ኢሜይል።"
override val invalid_user_error: String = "ልክ ያልሆነ የተጠቃሚ ምስክርነቶች።"
override val invoice_deleted_message: String = "ደረሰኝ ተሰርዟል"
override val invoice_menu: String = "የክፍያ መጠየቂያ ምናሌ"
override val invoice_number: String = "የክፍያ መጠየቂያ ቁጥር"
override val invoices: String = "ደረሰኞች"
override val items_option: String = "ንጥሎች"
override val keep: String = "ጠብቅ"
override val language_selector: Formattable = Formattable { (selected) -> "ቋንቋ ${selected}" }
override val last_name_hint: String = "የመጨረሻ ስም"
override val ledger: String = "ledger"
override val legal: String = "ሕጋዊ"
override val legal_error: String = "የአገልግሎት ውል እና የግላዊነት ፖሊሲ መቀበል አለበት"
override val liabilities: String = "liabilities"
override val loading: String = "በመጫን ላይ"
override val login_required_error: String = "መግቢያ ያስፈልጋል።"
override val logo_view: String = "አርማ"
override val logout: String = "መውጣት"
override val logout_prompt: String = "የእርስዎ ውሂብ ከደመና ጋር አልተመሳሰለም። በመውጣት ላይ ደረሰኞችን, ግምቶችን, ደንበኞችን እና ሌሎች መረጃዎችን ይሰርዙ?"
override val main_screen: String = "ዋና ማያ ገጽ"
override val mark_paid: String = "እንደ ተከፈለ ምልክት"
override val message: String = "መልእክት"
override val missing_sender_msg: String = "የላኪ መረጃ የሌለው ሰነድ ልክ ላይሆን ይችላል።"
override val missing_sender_title: String = "የተላላኪ መረጃ ይጎድላል"
override val month_april: String = "ሚያዚያ"
override val month_april_short: String = "ሚያዚያ"
override val month_august: String = "ነሐሴ"
override val month_august_short: String = "ኦገስት።"
override val month_december: String = "ታህሳስ"
override val month_december_short: String = "ታህሳስ።"
override val month_february: String = "የካቲት"
override val month_february_short: String = "የካቲት።"
override val month_january: String = "ጥር"
override val month_january_short: String = "ጃን።"
override val month_july: String = "ሀምሌ"
override val month_july_short: String = "ሀምሌ"
override val month_june: String = "ሰኔ"
override val month_june_short: String = "ሰኔ"
override val month_march: String = "መጋቢት"
override val month_march_short: String = "መጋቢት"
override val month_may: String = "ሜይ"
override val month_may_short: String = "ሜይ"
override val month_november: String = "ህዳር"
override val month_november_short: String = "ኖቬምበር።"
override val month_october: String = "ጥቅምት"
override val month_october_short: String = "ኦክቶር"
override val month_september: String = "መስከረም"
override val month_september_short: String = "ሴፕት።"
override val multifactor_error: String = "ማረጋገጥ አልተቻለም።"
override val new: String = "አዲስ"
override val news: String = "ዜና"
override val next: String = "ቀጥሎ"
override val no: String = "no"
override val no_data_available: String = "ምንም ውሂብ አይገኝም"
override val no_search_results: String = "ይቅርታ ምንም ውጤት ማግኘት አልቻልንም።"
override val none: String = "ምንም"
override val note_fields: String = "ማስታወሻዎች"
override val note_hint: String = "ማስታወሻ"
override val notifications_option: String = "ማሳወቂያዎች"
override val ok: String = "እሺ"
override val onboarding: String = "በመሳፈር ላይ"
override val onboarding_aboutus_message: String = "የDingVoice አስፈላጊ ክፍሎች ለዘላለም ነፃ ናቸው። የእኛ ተልእኮ ንግድዎን እንዲያሳድጉ መርዳት ነው፣ አስተያየትዎን በማጋራት እንድናሻሽል ያግዙን።"
override val onboarding_aboutus_title: String = "እኛ ለመርዳት እዚህ ነን"
override val onboarding_paid_1: String = "ሙያዊ የሚመስሉ አብነቶችን ይድረሱ"
override val onboarding_paid_2: String = "የእርስዎን የፋይናንስ ሁኔታ በጥልቀት ይመልከቱ"
override val onboarding_paid_3: String = "ችግሮችን በፍጥነት መፍታት"
override val onboarding_paid_4: String = "ሰነዶችዎን በጥንቃቄ ያስቀምጡ"
override val onboarding_paid_5: String = "በክፍያ መጠየቂያ ሁኔታ ለውጦች እንደተዘመኑ ይቆዩ"
override val onboarding_paid_title: String = "በ..."
override val onboarding_paywall_message: String = "አሁን በሁሉም የፕሪሚየም ባህሪያት በተሻለ ዋጋ ይደሰቱ።"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "ልክ ${currencySymbol}${introAmount} ለ${introDuration} ${introPeriod} እና ከዚያ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, መጠን, duration) -> "ልክ ${currencySymbol}${መጠን} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "ነጻ ለ${trialDuration} ${trialPeriod} እና ከዚያ በ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "ነፃ ለ${trialDuration} ${trialPeriod} ከዚያ ${currencySymbol}${introductoryAmount} ለ${introductoryDuration} ${introPeriod} እና ከዚያ በ${currencySymbol}${amount} / ${duration} ብቻ" }
override val onboarding_paywall_renewable: String = "በራስ-የሚታደስ። በማንኛውም ጊዜ ይሰርዙ።"
override val onboarding_paywall_restore: String = "ግዢን ወደነበረበት መልስ"
override val onboarding_paywall_restore_failure: String = "ግዢዎችን ወደነበሩበት መመለስ አልተሳካም፣ ቆይተው እንደገና ይሞክሩ ወይም ድጋፍን ያግኙ።"
override val onboarding_paywall_restore_success: String = "ወደነበረበት መመለስ ተሳክቷል!"
override val onboarding_paywall_title: String = "ታላቅነትን አስገኝ!"
override val onboarding_welcome_message: String = "የእርስዎን ደረሰኞች እና ግምቶች ለማስተዳደር የተሟላ የመሳሪያ ስብስብ።"
override val onboarding_welcome_title: String = "ወደ DingVoice እንኳን በደህና መጡ"
override val other_state: String = "ሌላ"
override val overdue_state: String = "ያለፈበት"
override val paid_state: String = "የተከፈለ"
override val password: String = "የይለፍ ቃል"
override val password_blank_error: String = "የይለፍ ቃል ያስፈልጋል።"
override val password_mismatch_error: String = "የይለፍ ቃላት አይዛመዱም።"
override val pay: String = "pay"
override val payment_instructions_label: String = "የክፍያ መመሪያዎች"
override val payment_reminders: String = "የክፍያ ማሳሰቢያዎች"
override val phone_number_fields: String = "ስልክ ቁጥር"
override val phone_number_hint: String = "ስልክ ቁጥር"
override val postcode_hint: String = "የፖስታ ኮድ"
override val preview: String = "ቅድመ ዕይታ"
override val preview_menu_hint: String = "የቅድመ እይታ ምናሌ"
override val price: String = "ዋጋ"
override val privacy_policy: String = "የግላዊነት ፖሊሲ"
override val profile: String = "ገጽታ"
override val profitable_client: String = "በጣም ጠቃሚ የሆነ ደንበኛዎ..."
override val profits_report: String = "ትርፍ"
override val promo_content: String = "የማስተዋወቂያ ይዘት"
override val quantity_hint: String = "ብዛት"
override val quantity_label_hint: String = "የብዛት መለያ"
override val query_info: String = "መጠይቅ ሁለቱንም ንጥሎች እና ሌሎች የግብር ደንቦችን እንደ ግብዓቶች በመጥቀስ ብጁ ስሌቶችን እንዲጽፉ ያስችልዎታል። ወደ ሌላ ግቤት ለማገናኘት @ ምልክት እና ንጥል ወይም የግብር መግለጫ ያክሉ +, -, *,/,% - የሂሳብ ኦፕሬተሮች"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} በደንበኛዎ የተቀበለው ነው።" }
override val received_state: String = "ተቀብሏል"
override val registration_code_fields: String = "የምዝገባ ኮድ"
override val reminder: String = "አስታዋሽ"
override val reports: String = "ሪፖርቶች"
override val reports_not_available: String = "ምንም ሪፖርቶች አይገኙም, በኋላ ተመልሰው ይመጣሉ።"
override val reveal_password: String = "የይለፍ ቃል ያሳያል"
override val revenue: String = "revenue"
override val sales_tax: String = "ተ.እ.ታ"
override val sales_tax_number: String = "የቫት ቁጥር"
override val save: String = "አስቀምጥ"
override val scan_document: String = "ሰነድ ይቃኙ"
override val search: String = "ፍለጋ"
override val select: String = "ይምረጡ"
override val select_icon: String = "አዶ ይምረጡ"
override val select_signature: String = "ፊርማ ይምረጡ"
override val select_template: String = "አብነት ይምረጡ"
override val send: String = "ላክ"
override val sent_state: String = "ተልኳል"
override val setup_sender: String = "ላኪን ያዋቅሩ"
override val share_option: String = "ያካፍሉ"
override val ship_to: String = "ወደ መርከብ"
override val sign_in: String = "ይግቡ"
override val sign_in_apple: String = "ከ Apple ጋር ይግቡ"
override val sign_in_email: String = "በኢሜይል ይግቡ"
override val sign_in_facebook: String = "በፌስቡክ ይግቡ"
override val sign_in_google: String = "ከGoogle ጋር ይግቡ"
override val sign_up: String = "ይመዝገቡ"
override val sign_up_label: String = "ሂሳብ የለውም?"
override val sign_up_link_label: String = "ይመዝገቡ!"
override val signatures_option: String = "ፊርማዎች"
override val skip: String = "ዝለል"
override val sold_items_report: String = "የተሸጡ እቃዎች"
override val sort_by: String = "ደርድር በ:"
override val status_update: String = "የሰነድ ሁኔታ ዝማኔ"
override val sub_annual: String = "ዓመታዊ"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/ዓመት" }
override val sub_backups: String = "የደመና ምትኬዎች"
override val sub_insights: String = "ግንዛቤዎች"
override val sub_month: String = "ወርሃዊ"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ወር" }
override val sub_notifications: String = "ማሳወቂያዎች"
override val sub_popup_message: String = "የደንበኝነት ምዝገባዎች በቅርቡ ይመጣል። በጥቂት ጊዜ ውስጥ እዚህ ተመልሰው ይመልከቱ።"
override val sub_support: String = "ድጋፍ"
override val sub_templates: String = "ቅንብር ደንቦች"
override val subscribe: String = "ይመዝገቡ"
override val subscription_header: String = "ንግድዎን ያሳድጉ:"
override val subscription_option: String = "ምዝገባ"
override val subscriptions_load_fail: String = "ይቅርታ፣ በዚህ ጊዜ የደንበኝነት ምዝገባዎችዎን ማውጣት አልቻልንም። እባክዎ ቆይተው እንደገና ይሞክሩ ወይም ለእርዳታ የድጋፍ ቡድናችንን ያነጋግሩ።"
override val subtotal: Formattable = Formattable { (sum) -> "ንዑስ ድምር: ${sum}" }
override val subtotal_label: String = "ንዑስ ድምር።"
override val sum: String = "ድምር"
override val support_option: String = "ድጋፍ"
override val switch_reports: String = "ሪፖርት ቀይር"
override val tax: String = "ግብር"
override val tax_deleted_message: String = "ግብር ተሰርዟል"
override val tax_description_warning: String = "ጥቅም ላይ ከዋለ, ይህ መጠየቂያ ደረሰኝ ላይ ያሳያል"
override val tax_input_flat: String = "ጠፍጣፋ"
override val tax_input_percent: String = "መቶኛ"
override val tax_number_fields: String = "የግብር ቁጥር"
override val tax_number_hint: String = "የግብር ቁጥር"
override val tax_query_info_description: String = "የመጠይቅ መረጃ"
override val tax_report: String = "ግብር"
override val tax_rules_option: String = "የግብር ደንቦች"
override val taxed_clients_report: String = "የታክስ ደንበኞች"
override val template: String = "አብነት"
override val template_selector_info: String = "ነባሪውን አብነት ለመለወጥ ያንሸራትቱ"
override val template_unlock_promo: String = "ተጨማሪ አብነቶችን ከ ፕሪሚየም ጋር ይድረሱ"
override val templates_option: String = "ቅንብር ደንቦች"
override val terms_of_service: String = "የአገልግሎት ውል"
override val thank_you: String = "እናመሰግናለን"
override val to: String = "ወደ"
override val total: Formattable = Formattable { (sum) -> "ጠቅላላ: ${sum}" }
override val total_label: String = "ጠቅላላ"
override val undo_action: String = "ቀልበስ"
override val unit: String = "አሃድ"
override val unit_cost_hint: String = "የዩኒት ወጪ"
override val unknown_client: String = "ያልታወቀ ደንበኛ"
override val unlock: String = "ክፈት"
override val unnamed_item: String = "ያልተሰየመ ንጥል"
override val upgrade: String = "አሻሽል"
override val user_collision_error: String = "መለያ ቀድሞውኑ አለ።"
override val valid_until: String = "ሕጋዊ እስከሚሆን ድረስ"
override val warning: String = "ማስጠንቀቂያ!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} እና ${privacyPolicy}ን ይቀበሉ" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "የተሳሳተ ኮድ።",
	"add_client_action" to "ተገልጋይ እዚህ ያስገቡ",
	"add_customer" to "ደንበኛ እዚህ ያክሉ",
	"add_expense" to "ወጪ ጨምር",
	"add_fields_label" to "አክል:",
	"add_invoice_action" to "ይፍጠሩ",
	"add_item_action" to "ዓይነቱን ያስገቡ",
	"add_item_button" to "ዓይነቱን ያስገቡ",
	"add_signature_action" to "ፊርማ አክል",
	"add_tax_action" to "ግብር አክል",
	"address_fields" to "አድራሻ",
	"address_line_hint" to "የአድራሻ መስመር",
	"advanced" to "የላቀ",
	"agree_marketing" to "ቀጥተኛ የግብይት ግንኙነቶችን ለመቀበል ተስማምቻለሁ",
	"all_notifications" to "ሁሉም ማሳወቂያዎች",
	"amortization" to "amortization",
	"amount" to "መጠን",
	"asset" to "asset",
	"attach_file" to "ፋይል አያይዝ",
	"attachment" to "አባሪ",
	"auth_error" to "እርምጃ አልተሳካም, እንደገና ይሞክሩ።",
	"available_for_paid" to "የደመና ማመሳሰል የሚገኘው በሚከፈልበት የደንበኝነት ምዝገባ ብቻ ነው።",
	"backups_option" to "ምትኬዎች",
	"balance" to "balance",
	"bank_account" to "የባንክ ሒሳብ",
	"bill_number" to "የሂሳብ ቁጥር",
	"cancel" to "ሰርዝ",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "ምድቦች",
	"category" to "ምድብ",
	"category_add" to "ምድብ ጨምር",
	"category_delete" to "ምድብ ሰርዝ",
	"category_name" to "የምድብ ስም",
	"change_language" to Formattable { (language) -> "የክፍያ መጠየቂያ ቋንቋ፡ ${language}" },
	"change_language_label" to "የክፍያ መጠየቂያ ቋንቋ",
	"change_template" to "አብነት ይቀይሩ",
	"city_hint" to "ከተማ",
	"clear" to "አጽዳ",
	"client" to "ደንበኛ",
	"client_actions" to "የደንበኛ እርምጃዎች",
	"client_deleted_message" to "ደንበኛ ተሰርዟል",
	"clients_error_required_fields" to "ቢያንስ አንድ እሴት ያስፈልጋል።",
	"clients_option" to "ደንበኞች",
	"close" to "ዝጋ",
	"cloud_sync" to "የደመና አመሳስል",
	"coming_soon" to "በቅርቡ ይመጣል!",
	"company_logo" to "የኩባንያ አርማ",
	"company_name_hint" to "ኩባንያ",
	"confirm_password_label" to "ይለፍ ቃል ያረጋግጡ",
	"confirmation_title" to "እርግጠኛ ነህ?",
	"convert_to_invoice" to "ወደ ደረሰኝ መለወጥ",
	"country_hint" to "አገር",
	"create_client" to "ደንበኛ ይፍጠሩ",
	"create_company" to "ኩባንያ ፍጠር",
	"create_document" to "ሰነድ ይፍጠሩ",
	"create_estimate" to "መገመት",
	"create_invoice" to "የክፍያ መጠየቂያ",
	"create_item" to "ንጥል ይፍጠሩ",
	"create_new_client" to "አዲስ ደንበኛ ይፍጠሩ",
	"create_new_item" to "አዲስ ንጥል ይፍጠሩ",
	"create_new_tax" to "አዲስ ግብር ይፍጠሩ",
	"create_signature" to "ፊርማ",
	"created" to "ተፈጥሯል",
	"credit" to "credit",
	"customer" to "ደንበኛ",
	"date" to "ቀን",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "ነባሪ ገንዘብ ${currency}" },
	"default_currency_label" to "ነባሪ ምንዛሬ",
	"delete" to "ሰርዝ",
	"delete_account" to "መለያ ሰርዝ",
	"delete_confirmation_message" to "ሁሉም የእርስዎ ውሂብ ይጠፋል።",
	"delete_item_title" to "ንጥሉን ሰርዝ?",
	"delete_signature" to "ፊርማ ሰርዝ",
	"depreciation" to "depreciation",
	"description" to "መግለጫ",
	"description_hint" to "መግለጫ",
	"detailed_report" to "ዝርዝር ዘገባ",
	"disabled" to "ተሰናክሏል",
	"discount" to "የዋጋ ቅናሽ",
	"discount_hint" to "የዋጋ ቅናሽ",
	"display_af" to "አፍሪካንስ",
	"display_am" to "አማርኛ",
	"display_ar" to "አረብኛ",
	"display_as" to "አዛሚስ",
	"display_az" to "አዘርባጃኒኛ",
	"display_be" to "ቤላሩሳዊ",
	"display_bg" to "ቡልጋርያኛ",
	"display_bn" to "ቤንጋሊ",
	"display_bs" to "ቦስንያን",
	"display_ca" to "ካታላን",
	"display_cs" to "ቼክ",
	"display_da" to "ዴንማርካዊ",
	"display_de" to "ጀርመንኛ",
	"display_el" to "ግሪክኛ",
	"display_en" to "እንግሊዝኛ",
	"display_es" to "ስፓኒሽ",
	"display_es_MX" to "ስፓኒሽ (ሜክሲኮ)",
	"display_et" to "ኢስቶኒያን",
	"display_eu" to "ባስክ",
	"display_fa" to "ፐርሺያዊ",
	"display_fi" to "ፊንላንዳዊ",
	"display_fil" to "ፊሊፒንስ",
	"display_fr" to "ፈረንሳይኛ",
	"display_fr_CA" to "ካናዳዊ ፈረንሳይኛ",
	"display_gl" to "ጋላሺያን",
	"display_gu" to "ጉጅራቲ",
	"display_he" to "ሂብሩ",
	"display_hi" to "ሂንዲ",
	"display_hr" to "ክሮሽያዊ",
	"display_hu" to "ሃንጋሪያን",
	"display_hy" to "አርመንያኛ",
	"display_id" to "ኢንዶኔዥያን",
	"display_is" to "አይስላንዳዊ",
	"display_it" to "ጣሊያንኛ",
	"display_ja" to "ጃፓንኛ",
	"display_ka" to "ጆርጅያዊ",
	"display_kk" to "ካዛክሀ",
	"display_km" to "ማዕከላዊ ክመርኛ",
	"display_kn" to "ካናዳ",
	"display_ko" to "ኮሪያኛ",
	"display_ky" to "ኪርጊዝ",
	"display_lo" to "ላኦ",
	"display_lt" to "ሊቱኒያን",
	"display_lv" to "ላትቪያን",
	"display_mk" to "ማስዶንያን",
	"display_ml" to "ማላያላም",
	"display_mn" to "ሞኒጎሊያን",
	"display_mr" to "ማራዚኛ",
	"display_ms" to "ማላይ",
	"display_my" to "የበርማ ሰው",
	"display_nb" to "የኖርዌይ ቦክማል",
	"display_ne" to "ኔፓሊ",
	"display_nl" to "ደች",
	"display_or" to "ኦሪያ",
	"display_pa" to "ፑንጃቢ",
	"display_pl" to "መጥረግ",
	"display_pt" to "ፖርቹጋልኛ",
	"display_pt_BR" to "ፖርቹጋልኛ (ብራዚል)",
	"display_pt_PT" to "አውሮፓውያን ፖርቱጋ",
	"display_ro" to "ሮማንያዊ",
	"display_ru" to "ራሺያኛ",
	"display_si" to "ሲንሃላ",
	"display_sk" to "ስሎቫክ",
	"display_sl" to "ስሎቬንያን",
	"display_sq" to "አልበንያኛ",
	"display_sr" to "ሰሪቢያን",
	"display_sv" to "ስዊድንኛ",
	"display_sw" to "ስዋሂሊኛ",
	"display_ta" to "ታሚል",
	"display_te" to "ተሉጉ",
	"display_th" to "ታይ",
	"display_tr" to "ቱርካዊ",
	"display_uk" to "ዩክሬንያን",
	"display_ur" to "ኡርዱ",
	"display_uz" to "ኡዝቤክ",
	"display_vi" to "ቪትናሜሴ",
	"display_zh_Hant_TW" to "ቻይንኛ (ታይዋን, ባህላዊ)",
	"display_zu" to "ዙሉ",
	"document_estimate" to "መገመት",
	"document_invoice" to "የክፍያ መጠየቂያ",
	"document_quote" to "የዋጋ አቅርቦት",
	"done" to "ተከናውኗል",
	"download_app" to "መተግበሪያውን ያውርዱ",
	"download_invoice" to "ደረሰኝ አውርድ",
	"download_pdf" to "ፒዲኤፍ አውርድ",
	"draw_signature" to "ፊርማ ይሳሉ",
	"due" to Formattable { (date) -> "ምክንያት ${date}" },
	"due_date" to "የጊዜ ገደብ",
	"due_day" to "በአንድ ቀን",
	"due_label" to "ምክንያት",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ምክንያት ነው ${dueWhen}!" },
	"due_today" to "ዛሬ",
	"due_week" to "በአንድ ሳምንት ውስጥ",
	"duplicate" to "የተባዛ",
	"duration_day" to "ቀን",
	"duration_month" to "ወር",
	"duration_week" to "ሳምንት",
	"duration_year" to "አመት",
	"email" to "ኢሜይል",
	"email_blank_error" to "ኢሜይል ያስፈልጋል።",
	"email_error" to "ልክ ያልሆነ የኢሜይል አድራሻ።",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "የእርስዎን ንግድ እናመሰግናለን። ሙሉ ለማየት ${documentType} በዚህ ደብዳቤ መጨረሻ ላይ ያለውን አዝራር ጠቅ ያድርጉ። ማስታወሻ እስከ ${dueDate} ድረስ የሚሰራ መሆኑን ልብ ይበሉ።" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "አዲስ ${documentType} ${invoiceNumber} ተቀብለዋል!" },
	"email_invoice_cta" to Formattable { (documentType) -> "ደረሰኝ ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "ውድ ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "የእርስዎን ንግድ እናመሰግናለን። ሙሉ ለማየት ${documentType} በዚህ ደብዳቤ መጨረሻ ላይ ያለውን አዝራር ጠቅ ያድርጉ። እባክዎ እስከ ${dueDate} ድረስ ሂሳብዎን ይክፈሉ።" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "አዲስ ${documentType} ${invoiceNumber} ተቀብለዋል!" },
	"email_reset_success_message" to "የተላከ የይለፍ ቃል ዳግም ለማስጀመር ኢሜይል, እባክዎ የገቢ መልዕክት ሳጥንዎን ያረጋግጡ።",
	"empty_clients_list_message" to "ለመከታተል ምንም ደንበኞች የሉዎትም። አዲስ ደንበኛ ያክሉ።",
	"empty_estimate_list_message" to "የመጀመሪያ ግምትዎን ይፍጠሩ",
	"empty_expenses" to "ለመከታተል ምንም ወጪ የለዎትም። አዲስ ወጪ ጨምር።",
	"empty_invoice_list_message" to "የመጀመሪያ ደረሰኝዎን ይፍጠሩ",
	"empty_items_list_message" to "ምንም ንጥሎች የሉዎትም, አዲስ ንጥል ያክሉ!",
	"empty_signatures_list_message" to "ምንም ፊርማ አልተገኘም። አንድ ፍጠር!",
	"empty_taxes_list_message" to "ምንም የግብር ደንቦች የሉዎትም። አዲስ የግብር ደንብ ያክሉ።",
	"empty_templates_list_message" to "አይገኝም አብነቶች።",
	"enabled" to "ነቅቷል",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "ግምቱ #",
	"estimates" to "ግምቶች",
	"estimates_report" to "ግምቶች",
	"expense_bank_fees" to "የባንክ ክፍያዎች",
	"expense_benefits" to "ጥቅሞች",
	"expense_communication" to "ግንኙነት",
	"expense_debt" to "ዕዳ",
	"expense_deleted" to "ወጪ ተሰርዟል።",
	"expense_depreciation" to "የዋጋ ቅነሳ",
	"expense_gifts" to "ስጦታዎች",
	"expense_insurance" to "ኢንሹራንስ",
	"expense_inventory" to "ቆጠራ",
	"expense_legal" to "ህጋዊ",
	"expense_maintenance" to "ጥገና",
	"expense_marketing" to "ግብይት",
	"expense_meals" to "ምግቦች",
	"expense_office_supplies" to "የቢሮ መገልገያ ዕቃዎች",
	"expense_rent" to "ይከራዩ",
	"expense_salaries" to "ደሞዝ",
	"expense_shopping" to "ግዢ",
	"expense_software" to "ሶፍትዌር",
	"expense_status_available" to "ይገኛል።",
	"expense_status_processing" to "በማቀነባበር ላይ",
	"expense_tax" to "ግብር",
	"expense_training" to "ስልጠና",
	"expense_transport" to "መጓጓዣ",
	"expense_travel" to "ጉዞ",
	"expense_utility" to "መገልገያ",
	"expenses" to "ወጪዎች",
	"feedback_option" to "ግብረመልስ",
	"filter" to "ማጣሪያ",
	"first_name_hint" to "የመጀመሪያ ስም",
	"forgot_password" to "የይለፍ ቃል ዳግም አስጀምር",
	"forgot_password_label" to "የይለፍ ቃል ረሱ",
	"from" to "ከ",
	"general_error" to "ስህተት ተከስቷል፣ እባክዎ ቆይተው እንደገና ይሞክሩ።",
	"general_ledger" to "general ledger",
	"go_back_label" to "ወደ ኋላ ተመለስ",
	"group_by" to "ቡድን በ:",
	"image_view" to "ምስል",
	"install" to "ጫን",
	"invalid_credentials_error" to "ልክ ያልሆነ ምስክርነቶች።",
	"invalid_email_error" to "ልክ ያልሆነ ኢሜይል።",
	"invalid_user_error" to "ልክ ያልሆነ የተጠቃሚ ምስክርነቶች።",
	"invoice_deleted_message" to "ደረሰኝ ተሰርዟል",
	"invoice_menu" to "የክፍያ መጠየቂያ ምናሌ",
	"invoice_number" to "የክፍያ መጠየቂያ ቁጥር",
	"invoices" to "ደረሰኞች",
	"items_option" to "ንጥሎች",
	"keep" to "ጠብቅ",
	"language_selector" to Formattable { (selected) -> "ቋንቋ ${selected}" },
	"last_name_hint" to "የመጨረሻ ስም",
	"ledger" to "ledger",
	"legal" to "ሕጋዊ",
	"legal_error" to "የአገልግሎት ውል እና የግላዊነት ፖሊሲ መቀበል አለበት",
	"liabilities" to "liabilities",
	"loading" to "በመጫን ላይ",
	"login_required_error" to "መግቢያ ያስፈልጋል።",
	"logo_view" to "አርማ",
	"logout" to "መውጣት",
	"logout_prompt" to "የእርስዎ ውሂብ ከደመና ጋር አልተመሳሰለም። በመውጣት ላይ ደረሰኞችን, ግምቶችን, ደንበኞችን እና ሌሎች መረጃዎችን ይሰርዙ?",
	"main_screen" to "ዋና ማያ ገጽ",
	"mark_paid" to "እንደ ተከፈለ ምልክት",
	"message" to "መልእክት",
	"missing_sender_msg" to "የላኪ መረጃ የሌለው ሰነድ ልክ ላይሆን ይችላል።",
	"missing_sender_title" to "የተላላኪ መረጃ ይጎድላል",
	"month_april" to "ሚያዚያ",
	"month_april_short" to "ሚያዚያ",
	"month_august" to "ነሐሴ",
	"month_august_short" to "ኦገስት።",
	"month_december" to "ታህሳስ",
	"month_december_short" to "ታህሳስ።",
	"month_february" to "የካቲት",
	"month_february_short" to "የካቲት።",
	"month_january" to "ጥር",
	"month_january_short" to "ጃን።",
	"month_july" to "ሀምሌ",
	"month_july_short" to "ሀምሌ",
	"month_june" to "ሰኔ",
	"month_june_short" to "ሰኔ",
	"month_march" to "መጋቢት",
	"month_march_short" to "መጋቢት",
	"month_may" to "ሜይ",
	"month_may_short" to "ሜይ",
	"month_november" to "ህዳር",
	"month_november_short" to "ኖቬምበር።",
	"month_october" to "ጥቅምት",
	"month_october_short" to "ኦክቶር",
	"month_september" to "መስከረም",
	"month_september_short" to "ሴፕት።",
	"multifactor_error" to "ማረጋገጥ አልተቻለም።",
	"new" to "አዲስ",
	"news" to "ዜና",
	"next" to "ቀጥሎ",
	"no" to "no",
	"no_data_available" to "ምንም ውሂብ አይገኝም",
	"no_search_results" to "ይቅርታ ምንም ውጤት ማግኘት አልቻልንም።",
	"none" to "ምንም",
	"note_fields" to "ማስታወሻዎች",
	"note_hint" to "ማስታወሻ",
	"notifications_option" to "ማሳወቂያዎች",
	"ok" to "እሺ",
	"onboarding" to "በመሳፈር ላይ",
	"onboarding_aboutus_message" to "የDingVoice አስፈላጊ ክፍሎች ለዘላለም ነፃ ናቸው። የእኛ ተልእኮ ንግድዎን እንዲያሳድጉ መርዳት ነው፣ አስተያየትዎን በማጋራት እንድናሻሽል ያግዙን።",
	"onboarding_aboutus_title" to "እኛ ለመርዳት እዚህ ነን",
	"onboarding_paid_1" to "ሙያዊ የሚመስሉ አብነቶችን ይድረሱ",
	"onboarding_paid_2" to "የእርስዎን የፋይናንስ ሁኔታ በጥልቀት ይመልከቱ",
	"onboarding_paid_3" to "ችግሮችን በፍጥነት መፍታት",
	"onboarding_paid_4" to "ሰነዶችዎን በጥንቃቄ ያስቀምጡ",
	"onboarding_paid_5" to "በክፍያ መጠየቂያ ሁኔታ ለውጦች እንደተዘመኑ ይቆዩ",
	"onboarding_paid_title" to "በ...",
	"onboarding_paywall_message" to "አሁን በሁሉም የፕሪሚየም ባህሪያት በተሻለ ዋጋ ይደሰቱ።",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "ልክ ${currencySymbol}${introAmount} ለ${introDuration} ${introPeriod} እና ከዚያ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, መጠን, duration) -> "ልክ ${currencySymbol}${መጠን} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "ነጻ ለ${trialDuration} ${trialPeriod} እና ከዚያ በ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "ነፃ ለ${trialDuration} ${trialPeriod} ከዚያ ${currencySymbol}${introductoryAmount} ለ${introductoryDuration} ${introPeriod} እና ከዚያ በ${currencySymbol}${amount} / ${duration} ብቻ" },
	"onboarding_paywall_renewable" to "በራስ-የሚታደስ። በማንኛውም ጊዜ ይሰርዙ።",
	"onboarding_paywall_restore" to "ግዢን ወደነበረበት መልስ",
	"onboarding_paywall_restore_failure" to "ግዢዎችን ወደነበሩበት መመለስ አልተሳካም፣ ቆይተው እንደገና ይሞክሩ ወይም ድጋፍን ያግኙ።",
	"onboarding_paywall_restore_success" to "ወደነበረበት መመለስ ተሳክቷል!",
	"onboarding_paywall_title" to "ታላቅነትን አስገኝ!",
	"onboarding_welcome_message" to "የእርስዎን ደረሰኞች እና ግምቶች ለማስተዳደር የተሟላ የመሳሪያ ስብስብ።",
	"onboarding_welcome_title" to "ወደ DingVoice እንኳን በደህና መጡ",
	"other_state" to "ሌላ",
	"overdue_state" to "ያለፈበት",
	"paid_state" to "የተከፈለ",
	"password" to "የይለፍ ቃል",
	"password_blank_error" to "የይለፍ ቃል ያስፈልጋል።",
	"password_mismatch_error" to "የይለፍ ቃላት አይዛመዱም።",
	"pay" to "pay",
	"payment_instructions_label" to "የክፍያ መመሪያዎች",
	"payment_reminders" to "የክፍያ ማሳሰቢያዎች",
	"phone_number_fields" to "ስልክ ቁጥር",
	"phone_number_hint" to "ስልክ ቁጥር",
	"postcode_hint" to "የፖስታ ኮድ",
	"preview" to "ቅድመ ዕይታ",
	"preview_menu_hint" to "የቅድመ እይታ ምናሌ",
	"price" to "ዋጋ",
	"privacy_policy" to "የግላዊነት ፖሊሲ",
	"profile" to "ገጽታ",
	"profitable_client" to "በጣም ጠቃሚ የሆነ ደንበኛዎ...",
	"profits_report" to "ትርፍ",
	"promo_content" to "የማስተዋወቂያ ይዘት",
	"quantity_hint" to "ብዛት",
	"quantity_label_hint" to "የብዛት መለያ",
	"query_info" to "መጠይቅ ሁለቱንም ንጥሎች እና ሌሎች የግብር ደንቦችን እንደ ግብዓቶች በመጥቀስ ብጁ ስሌቶችን እንዲጽፉ ያስችልዎታል። ወደ ሌላ ግቤት ለማገናኘት @ ምልክት እና ንጥል ወይም የግብር መግለጫ ያክሉ +, -, *,/,% - የሂሳብ ኦፕሬተሮች",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} በደንበኛዎ የተቀበለው ነው።" },
	"received_state" to "ተቀብሏል",
	"registration_code_fields" to "የምዝገባ ኮድ",
	"reminder" to "አስታዋሽ",
	"reports" to "ሪፖርቶች",
	"reports_not_available" to "ምንም ሪፖርቶች አይገኙም, በኋላ ተመልሰው ይመጣሉ።",
	"reveal_password" to "የይለፍ ቃል ያሳያል",
	"revenue" to "revenue",
	"sales_tax" to "ተ.እ.ታ",
	"sales_tax_number" to "የቫት ቁጥር",
	"save" to "አስቀምጥ",
	"scan_document" to "ሰነድ ይቃኙ",
	"search" to "ፍለጋ",
	"select" to "ይምረጡ",
	"select_icon" to "አዶ ይምረጡ",
	"select_signature" to "ፊርማ ይምረጡ",
	"select_template" to "አብነት ይምረጡ",
	"send" to "ላክ",
	"sent_state" to "ተልኳል",
	"setup_sender" to "ላኪን ያዋቅሩ",
	"share_option" to "ያካፍሉ",
	"ship_to" to "ወደ መርከብ",
	"sign_in" to "ይግቡ",
	"sign_in_apple" to "ከ Apple ጋር ይግቡ",
	"sign_in_email" to "በኢሜይል ይግቡ",
	"sign_in_facebook" to "በፌስቡክ ይግቡ",
	"sign_in_google" to "ከGoogle ጋር ይግቡ",
	"sign_up" to "ይመዝገቡ",
	"sign_up_label" to "ሂሳብ የለውም?",
	"sign_up_link_label" to "ይመዝገቡ!",
	"signatures_option" to "ፊርማዎች",
	"skip" to "ዝለል",
	"sold_items_report" to "የተሸጡ እቃዎች",
	"sort_by" to "ደርድር በ:",
	"status_update" to "የሰነድ ሁኔታ ዝማኔ",
	"sub_annual" to "ዓመታዊ",
	"sub_annual_price" to Formattable { (price) -> "${price}/ዓመት" },
	"sub_backups" to "የደመና ምትኬዎች",
	"sub_insights" to "ግንዛቤዎች",
	"sub_month" to "ወርሃዊ",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ወር" },
	"sub_notifications" to "ማሳወቂያዎች",
	"sub_popup_message" to "የደንበኝነት ምዝገባዎች በቅርቡ ይመጣል። በጥቂት ጊዜ ውስጥ እዚህ ተመልሰው ይመልከቱ።",
	"sub_support" to "ድጋፍ",
	"sub_templates" to "ቅንብር ደንቦች",
	"subscribe" to "ይመዝገቡ",
	"subscription_header" to "ንግድዎን ያሳድጉ:",
	"subscription_option" to "ምዝገባ",
	"subscriptions_load_fail" to "ይቅርታ፣ በዚህ ጊዜ የደንበኝነት ምዝገባዎችዎን ማውጣት አልቻልንም። እባክዎ ቆይተው እንደገና ይሞክሩ ወይም ለእርዳታ የድጋፍ ቡድናችንን ያነጋግሩ።",
	"subtotal" to Formattable { (sum) -> "ንዑስ ድምር: ${sum}" },
	"subtotal_label" to "ንዑስ ድምር።",
	"sum" to "ድምር",
	"support_option" to "ድጋፍ",
	"switch_reports" to "ሪፖርት ቀይር",
	"tax" to "ግብር",
	"tax_deleted_message" to "ግብር ተሰርዟል",
	"tax_description_warning" to "ጥቅም ላይ ከዋለ, ይህ መጠየቂያ ደረሰኝ ላይ ያሳያል",
	"tax_input_flat" to "ጠፍጣፋ",
	"tax_input_percent" to "መቶኛ",
	"tax_number_fields" to "የግብር ቁጥር",
	"tax_number_hint" to "የግብር ቁጥር",
	"tax_query_info_description" to "የመጠይቅ መረጃ",
	"tax_report" to "ግብር",
	"tax_rules_option" to "የግብር ደንቦች",
	"taxed_clients_report" to "የታክስ ደንበኞች",
	"template" to "አብነት",
	"template_selector_info" to "ነባሪውን አብነት ለመለወጥ ያንሸራትቱ",
	"template_unlock_promo" to "ተጨማሪ አብነቶችን ከ ፕሪሚየም ጋር ይድረሱ",
	"templates_option" to "ቅንብር ደንቦች",
	"terms_of_service" to "የአገልግሎት ውል",
	"thank_you" to "እናመሰግናለን",
	"to" to "ወደ",
	"total" to Formattable { (sum) -> "ጠቅላላ: ${sum}" },
	"total_label" to "ጠቅላላ",
	"undo_action" to "ቀልበስ",
	"unit" to "አሃድ",
	"unit_cost_hint" to "የዩኒት ወጪ",
	"unknown_client" to "ያልታወቀ ደንበኛ",
	"unlock" to "ክፈት",
	"unnamed_item" to "ያልተሰየመ ንጥል",
	"upgrade" to "አሻሽል",
	"user_collision_error" to "መለያ ቀድሞውኑ አለ።",
	"valid_until" to "ሕጋዊ እስከሚሆን ድረስ",
	"warning" to "ማስጠንቀቂያ!",
	"yes" to "yes",
    )
    
}