// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

abstract class Locale  {
    open val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Accept ${termsOfService} and ${privacyPolicy}" }
open val account: String = "account"
open val accounting: String = "accounting"
open val accruals: String = "accruals"
open val action_code_error: String = "Invalid code."
open val add_client_action: String = "Add client"
open val add_customer: String = "Add customer"
open val add_expense: String = "Add expense"
open val add_fields_label: String = "Add:"
open val add_invoice_action: String = "Create"
open val add_item_action: String = "Add item"
open val add_item_button: String = "Add item"
open val add_signature_action: String = "Add signature"
open val add_tax_action: String = "Add tax"
open val address_fields: String = "Address"
open val address_line_hint: String = "Address Line"
open val advanced: String = "Advanced"
open val agree_marketing: String = "I agree to receive Direct Marketing Communications"
open val all_notifications: String = "All Notifications"
open val amortization: String = "amortization"
open val amount: String = "Amount"
open val asset: String = "asset"
open val attach_file: String = "Attach File"
open val attachment: String = "Attachment"
open val auth_error: String = "Action failed, try again."
open val available_for_paid: String = "Cloud sync is available only under paid subscription."
open val backups_option: String = "Backups"
open val balance: String = "balance"
open val bank_account: String = "Bank Account"
open val bill_number: String = "Bill Number"
open val cancel: String = "Cancel"
open val cancel_trial: String = "Cancel at any time"
open val capital: String = "capital"
open val cash: String = "cash"
open val categories: String = "Categories"
open val category: String = "Category"
open val category_add: String = "Add Category"
open val category_delete: String = "Delete category"
open val category_name: String = "Category name"
open val change_language: Formattable = Formattable { (language) -> "Invoice Language: ${language}" }
open val change_language_label: String = "Invoice Language"
open val change_template: String = "Change template"
open val city_hint: String = "City"
open val clear: String = "Clear"
open val client: String = "Client"
open val client_actions: String = "Client actions"
open val client_deleted_message: String = "Client deleted"
open val clients_error_required_fields: String = "At least one value is required."
open val clients_option: String = "Clients"
open val close: String = "Close"
open val cloud_sync: String = "Cloud Sync"
open val coming_soon: String = "Coming soon!"
open val company_logo: String = "Company Logo"
open val company_name_hint: String = "Company"
open val confirm_password_label: String = "Confirm password"
open val confirmation_title: String = "Are you sure?"
open val convert_to_invoice: String = "Convert to invoice"
open val country_hint: String = "Country"
open val create_client: String = "Create Client"
open val create_company: String = "Create Company"
open val create_document: String = "Create document"
open val create_estimate: String = "Estimate"
open val create_invoice: String = "Invoice"
open val create_item: String = "Create Item"
open val create_new_client: String = "Create new client"
open val create_new_item: String = "Create new item"
open val create_new_tax: String = "Create new tax"
open val create_signature: String = "Signature"
open val created: String = "Created"
open val credit: String = "credit"
open val customer: String = "Customer"
open val date: String = "Date"
open val debit: String = "debit"
open val debt: String = "debt"
open val default_currency: Formattable = Formattable { (currency) -> "Default currency: ${currency}" }
open val default_currency_label: String = "Default currency"
open val delete: String = "Delete"
open val delete_account: String = "Delete Account"
open val delete_confirmation_message: String = "All of your data will be lost."
open val delete_item_title: String = "Delete the item?"
open val delete_signature: String = "Delete signature"
open val depreciation: String = "depreciation"
open val description: String = "Description"
open val description_hint: String = "Description"
open val detailed_report: String = "Detailed Report"
open val disabled: String = "Disabled"
open val discount: String = "Discount"
open val discount_hint: String = "Discount"
open val display_af: String = "Afrikaans"
open val display_am: String = "Amharic"
open val display_ar: String = "Arabic"
open val display_as: String = "Assamese"
open val display_az: String = "Azerbaijani"
open val display_be: String = "Belarusian"
open val display_bg: String = "Bulgarian"
open val display_bn: String = "Bengali"
open val display_bs: String = "Bosnian"
open val display_ca: String = "Catalan"
open val display_cs: String = "Czech"
open val display_da: String = "Danish"
open val display_de: String = "German"
open val display_el: String = "Greek"
open val display_en: String = "English"
open val display_es: String = "Spanish"
open val display_es_MX: String = "Spanish (Mexico)"
open val display_et: String = "Estonian"
open val display_eu: String = "Basque"
open val display_fa: String = "Persian"
open val display_fi: String = "Finnish"
open val display_fil: String = "Filipino"
open val display_fr: String = "French"
open val display_fr_CA: String = "Canadian French"
open val display_gl: String = "Galician"
open val display_gu: String = "Gujarati"
open val display_he: String = "Hebrew"
open val display_hi: String = "Hindi"
open val display_hr: String = "Croatian"
open val display_hu: String = "Hungarian"
open val display_hy: String = "Armenian"
open val display_id: String = "Indonesian"
open val display_is: String = "Icelandic"
open val display_it: String = "Italian"
open val display_ja: String = "Japanese"
open val display_ka: String = "Georgian"
open val display_kk: String = "Kazakh"
open val display_km: String = "Central Khmer"
open val display_kn: String = "Kannada"
open val display_ko: String = "Korean"
open val display_ky: String = "Kirghiz"
open val display_lo: String = "Lao"
open val display_lt: String = "Lithuanian"
open val display_lv: String = "Latvian"
open val display_mk: String = "Macedonian"
open val display_ml: String = "Malayalam"
open val display_mn: String = "Mongolian"
open val display_mr: String = "Marathi"
open val display_ms: String = "Malay"
open val display_my: String = "Burmese"
open val display_nb: String = "Norwegian Bokmål"
open val display_ne: String = "Nepali"
open val display_nl: String = "Dutch"
open val display_or: String = "Oriya"
open val display_pa: String = "Punjabi"
open val display_pl: String = "Polish"
open val display_pt: String = "Portuguese"
open val display_pt_BR: String = "Portuguese (Brazil)"
open val display_pt_PT: String = "European Portuguese"
open val display_ro: String = "Romanian"
open val display_ru: String = "Russian"
open val display_si: String = "Sinhala"
open val display_sk: String = "Slovak"
open val display_sl: String = "Slovenian"
open val display_sq: String = "Albanian"
open val display_sr: String = "Serbian"
open val display_sv: String = "Swedish"
open val display_sw: String = "Swahili"
open val display_ta: String = "Tamil"
open val display_te: String = "Telugu"
open val display_th: String = "Thai"
open val display_tr: String = "Turkish"
open val display_uk: String = "Ukrainian"
open val display_ur: String = "Urdu"
open val display_uz: String = "Uzbek"
open val display_vi: String = "Vietnamese"
open val display_zh_Hant_TW: String = "Chinese (Taiwan, Traditional)"
open val display_zu: String = "Zulu"
open val document_estimate: String = "Estimate"
open val document_invoice: String = "Invoice"
open val document_quote: String = "Quote"
open val done: String = "Done"
open val download_app: String = "Download the app"
open val download_invoice: String = "Download Invoice"
open val download_pdf: String = "Download PDF"
open val draw_signature: String = "Draw a signature"
open val due: Formattable = Formattable { (date) -> "Due ${date}" }
open val due_date: String = "Due Date"
open val due_day: String = "in a day"
open val due_label: String = "Due"
open val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} is due ${dueWhen}!" }
open val due_today: String = "today"
open val due_week: String = "in a week"
open val duplicate: String = "Duplicate"
open val duration_day: String = "day"
open val duration_month: String = "month"
open val duration_week: String = "week"
open val duration_year: String = "year"
open val email: String = "Email"
open val email_blank_error: String = "Email required."
open val email_error: String = "Invalid email address."
open val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Thank you for your business. To view full ${documentType} click the button at the end of this letter. Note it is valid till ${dueDate}." }
open val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "You have received a new ${documentType} ${invoiceNumber}!" }
open val email_invoice_cta: Formattable = Formattable { (documentType) -> "VIEW ${documentType}" }
open val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Dear ${clientName}" }
open val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Thank you for your business. To view full ${documentType} click the button at the end of this letter. Please pay the balance till ${dueDate}." }
open val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "You have received a new ${documentType} ${invoiceNumber}!" }
open val email_reset_success_message: String = "Email to reset password sent, please check your inbox."
open val empty_clients_list_message: String = "You have no clients to track. Add a new client."
open val empty_estimate_list_message: String = "Create your first estimate"
open val empty_expenses: String = "You have no expenses to track. Add a new expense."
open val empty_invoice_list_message: String = "Create your first invoice"
open val empty_items_list_message: String = "You have no items, add a new item!"
open val empty_signatures_list_message: String = "No signatures found. Create one!"
open val empty_taxes_list_message: String = "You have no tax rules. Add a new tax rule."
open val empty_templates_list_message: String = "Templates unavailable."
open val enabled: String = "Enabled"
open val entry: String = "entry"
open val equity: String = "equity"
open val estimate_number: String = "Estimate #"
open val estimates: String = "Estimates"
open val estimates_report: String = "Estimates"
open val expense_bank_fees: String = "Bank Fees"
open val expense_benefits: String = "Benefits"
open val expense_communication: String = "Communication"
open val expense_debt: String = "Debt"
open val expense_deleted: String = "Expense deleted"
open val expense_depreciation: String = "Depreciation"
open val expense_gifts: String = "Gifts"
open val expense_insurance: String = "Insurance"
open val expense_inventory: String = "Inventory"
open val expense_legal: String = "Legal"
open val expense_maintenance: String = "Maintenance"
open val expense_marketing: String = "Marketing"
open val expense_meals: String = "Meals"
open val expense_office_supplies: String = "Office Supplies"
open val expense_rent: String = "Rent"
open val expense_salaries: String = "Salaries"
open val expense_shopping: String = "Shopping"
open val expense_software: String = "Software"
open val expense_status_available: String = "Available"
open val expense_status_processing: String = "Processing"
open val expense_tax: String = "Tax"
open val expense_training: String = "Training"
open val expense_transport: String = "Transport"
open val expense_travel: String = "Travel"
open val expense_utility: String = "Utility"
open val expenses: String = "Expenses"
open val feedback_option: String = "Feedback"
open val filter: String = "Filter"
open val first_name_hint: String = "First Name"
open val forgot_password: String = "Reset password"
open val forgot_password_label: String = "Forgot password"
open val from: String = "From"
open val general_error: String = "Error occured, please try again later."
open val general_ledger: String = "general ledger"
open val go_back_label: String = "Go back"
open val group_by: String = "Group By:"
open val image_view: String = "Image"
open val install: String = "Install"
open val invalid_credentials_error: String = "Invalid credentials."
open val invalid_email_error: String = "Invalid email."
open val invalid_user_error: String = "Invalid user credentials."
open val invoice_deleted_message: String = "Invoice deleted"
open val invoice_menu: String = "Invoice menu"
open val invoice_number: String = "Invoice #"
open val invoices: String = "Invoices"
open val items_option: String = "Items"
open val keep: String = "Keep"
open val language_selector: Formattable = Formattable { (selected) -> "Language ${selected}" }
open val last_name_hint: String = "Last Name"
open val ledger: String = "ledger"
open val legal: String = "Legal"
open val legal_error: String = "Must accept Terms of Service and Privacy Policy"
open val liabilities: String = "liabilities"
open val loading: String = "Loading"
open val login_required_error: String = "Login required."
open val logo_view: String = "Logo"
open val logout: String = "Logout"
open val logout_prompt: String = "Your data is not synchronized with cloud. Delete invoices, estimates, clients & other information on logout?"
open val main_screen: String = "Main screen"
open val mark_paid: String = "Mark as Paid"
open val message: String = "Message"
open val missing_sender_msg: String = "A document without sender information might be invalid."
open val missing_sender_title: String = "Missing sender information"
open val month_april: String = "April"
open val month_april_short: String = "April"
open val month_august: String = "August"
open val month_august_short: String = "Aug."
open val month_december: String = "December"
open val month_december_short: String = "Dec."
open val month_february: String = "February"
open val month_february_short: String = "Feb."
open val month_january: String = "January"
open val month_january_short: String = "Jan."
open val month_july: String = "July"
open val month_july_short: String = "July"
open val month_june: String = "June"
open val month_june_short: String = "June"
open val month_march: String = "March"
open val month_march_short: String = "March"
open val month_may: String = "May"
open val month_may_short: String = "May"
open val month_november: String = "November"
open val month_november_short: String = "Nov."
open val month_october: String = "October"
open val month_october_short: String = "Oct."
open val month_september: String = "September"
open val month_september_short: String = "Sept."
open val multifactor_error: String = "Failed to verify."
open val new: String = "New"
open val news: String = "News"
open val next: String = "Next"
open val no: String = "no"
open val no_data_available: String = "No data available"
open val no_search_results: String = "Sorry, we couldn\'t find any results."
open val none: String = "None"
open val note_fields: String = "Notes"
open val note_hint: String = "Note"
open val notifications_option: String = "Notifications"
open val ok: String = "Ok"
open val onboarding: String = "Onboarding"
open val onboarding_aboutus_message: String = "Essential parts of DingVoice are forever free. Our mission is to help you grow your business, help us improve by sharing your feedback."
open val onboarding_aboutus_title: String = "We are here to help"
open val onboarding_paid_1: String = "Access professional looking templates"
open val onboarding_paid_2: String = "Take a deeper look into your financials"
open val onboarding_paid_3: String = "Get problems resolved fast"
open val onboarding_paid_4: String = "Keep your documents safe"
open val onboarding_paid_5: String = "Stay up to date with invoice status changes"
open val onboarding_paid_6: String = "Uninterrupted use of latest features"
open val onboarding_paid_title: String = "Grow even faster with..."
open val onboarding_paywall_message: String = "Enjoy all premium features now at the best price."
open val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Just ${currencySymbol}${introAmount} for ${introDuration} ${introPeriod} and then ${currencySymbol}${amount} / ${duration}" }
open val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Just ${currencySymbol}${amount} / ${duration}" }
open val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} and then just ${currencySymbol}${amount} / ${duration}" }
open val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} then ${currencySymbol}${introductoryAmount} for ${introductoryDuration} ${introPeriod} and then just ${currencySymbol}${amount} / ${duration}" }
open val onboarding_paywall_renewable: String = "Auto-renewable. Cancel anytime."
open val onboarding_paywall_restore: String = "Restore purchase"
open val onboarding_paywall_restore_failure: String = "Restoring purchases has failed, try again later or contact support."
open val onboarding_paywall_restore_success: String = "Restore successful!"
open val onboarding_paywall_title: String = "Achieve Greatness!"
open val onboarding_welcome_message: String = "A complete toolset to manage your invoices & estimates."
open val onboarding_welcome_title: String = "Welcome to DingVoice"
open val other_state: String = "Other"
open val overdue_state: String = "Overdue"
open val paid_state: String = "Paid"
open val password: String = "Password"
open val password_blank_error: String = "Password required."
open val password_mismatch_error: String = "Passwords do not match."
open val pay: String = "pay"
open val payment_instructions_label: String = "Payment Instructions"
open val payment_reminders: String = "Payment reminders"
open val phone_number_fields: String = "Phone number"
open val phone_number_hint: String = "Phone Number"
open val postcode_hint: String = "Postcode"
open val preview: String = "Preview"
open val preview_menu_hint: String = "Preview menu"
open val price: String = "Price"
open val privacy_policy: String = "Privacy Policy"
open val profile: String = "Profile"
open val profitable_client: String = "Your most profitable client is..."
open val profits_report: String = "Profits"
open val promo_content: String = "Promotional content"
open val quantity_hint: String = "Quantity"
open val quantity_label_hint: String = "Quantity Label"
open val query_info: String = "Query allows you to write custom calculations referring to both items and other tax rules as inputs. Add @ symbol and item or tax description to link to another entry +, -, *, /, % -  math operators"
open val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} was received by your client." }
open val received_state: String = "Received"
open val registration_code_fields: String = "Registration Code"
open val reminder: String = "Reminder"
open val reports: String = "Reports"
open val reports_not_available: String = "No reports available, come back later."
open val reveal_password: String = "Reveal password"
open val revenue: String = "revenue"
open val sales_tax: String = "VAT"
open val sales_tax_number: String = "VAT number"
open val save: String = "Save"
open val scan_document: String = "Scan document"
open val search: String = "Search"
open val select: String = "Select"
open val select_icon: String = "Select icon"
open val select_signature: String = "Select signature"
open val select_template: String = "Select template"
open val send: String = "Send"
open val sent_state: String = "Sent"
open val setup_sender: String = "Set up sender"
open val share_option: String = "Share"
open val ship_to: String = "Ship to"
open val sign_in: String = "Sign In"
open val sign_in_apple: String = "Sign in with Apple"
open val sign_in_email: String = "Sign in with email"
open val sign_in_facebook: String = "Sign in with Facebook"
open val sign_in_google: String = "Sign in with Google"
open val sign_up: String = "Sign Up"
open val sign_up_label: String = "Don't have an account?"
open val sign_up_link_label: String = "Sign Up!"
open val signatures_option: String = "Signatures"
open val skip: String = "Skip"
open val sold_items_report: String = "Sold Items"
open val sort_by: String = "Sort By:"
open val status_update: String = "Document status update"
open val sub_annual: String = "Annual"
open val sub_annual_price: Formattable = Formattable { (price) -> "${price}/year" }
open val sub_backups: String = "Cloud Backups"
open val sub_insights: String = "Insights"
open val sub_month: String = "Monthly"
open val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/month" }
open val sub_notifications: String = "Notifications"
open val sub_popup_message: String = "Subscriptions are coming soon. Check back here in a little while."
open val sub_support: String = "Support"
open val sub_templates: String = "Templates"
open val subscribe: String = "Subscribe"
open val subscription_header: String = "Grow your business with:"
open val subscription_option: String = "Subscription"
open val subscriptions_load_fail: String = "Sorry, we're unable to retrieve your subscriptions at this time. Please try again later or contact our support team for assistance."
open val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
open val subtotal_label: String = "Subtotal"
open val sum: String = "Sum"
open val support_option: String = "Support"
open val switch_reports: String = "Switch Report"
open val tax: String = "Tax"
open val tax_deleted_message: String = "Tax deleted"
open val tax_description_warning: String = "If used this shows up on an invoice"
open val tax_input_flat: String = "Flat"
open val tax_input_percent: String = "Percent"
open val tax_number_fields: String = "Tax number"
open val tax_number_hint: String = "Tax Number"
open val tax_query_info_description: String = "Query Info"
open val tax_report: String = "Tax"
open val tax_rules_option: String = "Tax Rules"
open val taxed_clients_report: String = "Taxed Clients"
open val template: String = "Template"
open val template_selector_info: String = "Swipe to change the default template"
open val template_unlock_promo: String = "Access more templates with Premium"
open val templates_option: String = "Templates"
open val terms_of_service: String = "Terms of Service"
open val thank_you: String = "Thank You"
open val to: String = "To"
open val total: Formattable = Formattable { (sum) -> "Total: ${sum}" }
open val total_label: String = "Total"
open val undo_action: String = "Undo"
open val unit: String = "Unit"
open val unit_cost_hint: String = "Unit Cost"
open val unknown_client: String = "Unknown Client"
open val unlock: String = "Unlock"
open val unnamed_item: String = "Unnamed item"
open val upgrade: String = "Upgrade"
open val user_collision_error: String = "Account already exists."
open val valid_until: String = "Valid Until"
open val warning: String = "Warning!"
open val yes: String = "yes"
open val no_ads: String = "No advertisement"
    open val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Accept ${termsOfService} and ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Invalid code.",
	"add_client_action" to "Add client",
	"add_customer" to "Add customer",
	"add_expense" to "Add expense",
	"add_fields_label" to "Add:",
	"add_invoice_action" to "Create",
	"add_item_action" to "Add item",
	"add_item_button" to "Add item",
	"add_signature_action" to "Add signature",
	"add_tax_action" to "Add tax",
	"address_fields" to "Address",
	"address_line_hint" to "Address Line",
	"advanced" to "Advanced",
	"agree_marketing" to "I agree to receive Direct Marketing Communications",
	"all_notifications" to "All Notifications",
	"amortization" to "amortization",
	"amount" to "Amount",
	"asset" to "asset",
	"attach_file" to "Attach File",
	"attachment" to "Attachment",
	"auth_error" to "Action failed, try again.",
	"available_for_paid" to "Cloud sync is available only under paid subscription.",
	"backups_option" to "Backups",
	"balance" to "balance",
	"bank_account" to "Bank Account",
	"bill_number" to "Bill Number",
	"cancel" to "Cancel",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Categories",
	"category" to "Category",
	"category_add" to "Add Category",
	"category_delete" to "Delete category",
	"category_name" to "Category name",
	"change_language" to Formattable { (language) -> "Invoice Language: ${language}" },
	"change_language_label" to "Invoice Language",
	"change_template" to "Change template",
	"city_hint" to "City",
	"clear" to "Clear",
	"client" to "Client",
	"client_actions" to "Client actions",
	"client_deleted_message" to "Client deleted",
	"clients_error_required_fields" to "At least one value is required.",
	"clients_option" to "Clients",
	"close" to "Close",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "Coming soon!",
	"company_logo" to "Company Logo",
	"company_name_hint" to "Company",
	"confirm_password_label" to "Confirm password",
	"confirmation_title" to "Are you sure?",
	"convert_to_invoice" to "Convert to invoice",
	"country_hint" to "Country",
	"create_client" to "Create Client",
	"create_company" to "Create Company",
	"create_document" to "Create document",
	"create_estimate" to "Estimate",
	"create_invoice" to "Invoice",
	"create_item" to "Create Item",
	"create_new_client" to "Create new client",
	"create_new_item" to "Create new item",
	"create_new_tax" to "Create new tax",
	"create_signature" to "Signature",
	"created" to "Created",
	"credit" to "credit",
	"customer" to "Customer",
	"date" to "Date",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Default currency: ${currency}" },
	"default_currency_label" to "Default currency",
	"delete" to "Delete",
	"delete_account" to "Delete Account",
	"delete_confirmation_message" to "All of your data will be lost.",
	"delete_item_title" to "Delete the item?",
	"delete_signature" to "Delete signature",
	"depreciation" to "depreciation",
	"description" to "Description",
	"description_hint" to "Description",
	"detailed_report" to "Detailed Report",
	"disabled" to "Disabled",
	"discount" to "Discount",
	"discount_hint" to "Discount",
	"display_af" to "Afrikaans",
	"display_am" to "Amharic",
	"display_ar" to "Arabic",
	"display_as" to "Assamese",
	"display_az" to "Azerbaijani",
	"display_be" to "Belarusian",
	"display_bg" to "Bulgarian",
	"display_bn" to "Bengali",
	"display_bs" to "Bosnian",
	"display_ca" to "Catalan",
	"display_cs" to "Czech",
	"display_da" to "Danish",
	"display_de" to "German",
	"display_el" to "Greek",
	"display_en" to "English",
	"display_es" to "Spanish",
	"display_es_MX" to "Spanish (Mexico)",
	"display_et" to "Estonian",
	"display_eu" to "Basque",
	"display_fa" to "Persian",
	"display_fi" to "Finnish",
	"display_fil" to "Filipino",
	"display_fr" to "French",
	"display_fr_CA" to "Canadian French",
	"display_gl" to "Galician",
	"display_gu" to "Gujarati",
	"display_he" to "Hebrew",
	"display_hi" to "Hindi",
	"display_hr" to "Croatian",
	"display_hu" to "Hungarian",
	"display_hy" to "Armenian",
	"display_id" to "Indonesian",
	"display_is" to "Icelandic",
	"display_it" to "Italian",
	"display_ja" to "Japanese",
	"display_ka" to "Georgian",
	"display_kk" to "Kazakh",
	"display_km" to "Central Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Korean",
	"display_ky" to "Kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Lithuanian",
	"display_lv" to "Latvian",
	"display_mk" to "Macedonian",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolian",
	"display_mr" to "Marathi",
	"display_ms" to "Malay",
	"display_my" to "Burmese",
	"display_nb" to "Norwegian Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Dutch",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polish",
	"display_pt" to "Portuguese",
	"display_pt_BR" to "Portuguese (Brazil)",
	"display_pt_PT" to "European Portuguese",
	"display_ro" to "Romanian",
	"display_ru" to "Russian",
	"display_si" to "Sinhala",
	"display_sk" to "Slovak",
	"display_sl" to "Slovenian",
	"display_sq" to "Albanian",
	"display_sr" to "Serbian",
	"display_sv" to "Swedish",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Turkish",
	"display_uk" to "Ukrainian",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbek",
	"display_vi" to "Vietnamese",
	"display_zh_Hant_TW" to "Chinese (Taiwan, Traditional)",
	"display_zu" to "Zulu",
	"document_estimate" to "Estimate",
	"document_invoice" to "Invoice",
	"document_quote" to "Quote",
	"done" to "Done",
	"download_app" to "Download the app",
	"download_invoice" to "Download Invoice",
	"download_pdf" to "Download PDF",
	"draw_signature" to "Draw a signature",
	"due" to Formattable { (date) -> "Due ${date}" },
	"due_date" to "Due Date",
	"due_day" to "in a day",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} is due ${dueWhen}!" },
	"due_today" to "today",
	"due_week" to "in a week",
	"duplicate" to "Duplicate",
	"duration_day" to "day",
	"duration_month" to "month",
	"duration_week" to "week",
	"duration_year" to "year",
	"email" to "Email",
	"email_blank_error" to "Email required.",
	"email_error" to "Invalid email address.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Thank you for your business. To view full ${documentType} click the button at the end of this letter. Note it is valid till ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "You have received a new ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "VIEW ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Dear ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Thank you for your business. To view full ${documentType} click the button at the end of this letter. Please pay the balance till ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "You have received a new ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Email to reset password sent, please check your inbox.",
	"empty_clients_list_message" to "You have no clients to track. Add a new client.",
	"empty_estimate_list_message" to "Create your first estimate",
	"empty_expenses" to "You have no expenses to track. Add a new expense.",
	"empty_invoice_list_message" to "Create your first invoice",
	"empty_items_list_message" to "You have no items, add a new item!",
	"empty_signatures_list_message" to "No signatures found. Create one!",
	"empty_taxes_list_message" to "You have no tax rules. Add a new tax rule.",
	"empty_templates_list_message" to "Templates unavailable.",
	"enabled" to "Enabled",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Estimate #",
	"estimates" to "Estimates",
	"estimates_report" to "Estimates",
	"expense_bank_fees" to "Bank Fees",
	"expense_benefits" to "Benefits",
	"expense_communication" to "Communication",
	"expense_debt" to "Debt",
	"expense_deleted" to "Expense deleted",
	"expense_depreciation" to "Depreciation",
	"expense_gifts" to "Gifts",
	"expense_insurance" to "Insurance",
	"expense_inventory" to "Inventory",
	"expense_legal" to "Legal",
	"expense_maintenance" to "Maintenance",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Meals",
	"expense_office_supplies" to "Office Supplies",
	"expense_rent" to "Rent",
	"expense_salaries" to "Salaries",
	"expense_shopping" to "Shopping",
	"expense_software" to "Software",
	"expense_status_available" to "Available",
	"expense_status_processing" to "Processing",
	"expense_tax" to "Tax",
	"expense_training" to "Training",
	"expense_transport" to "Transport",
	"expense_travel" to "Travel",
	"expense_utility" to "Utility",
	"expenses" to "Expenses",
	"feedback_option" to "Feedback",
	"filter" to "Filter",
	"first_name_hint" to "First Name",
	"forgot_password" to "Reset password",
	"forgot_password_label" to "Forgot password",
	"from" to "From",
	"general_error" to "Error occured, please try again later.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Go back",
	"group_by" to "Group By:",
	"image_view" to "Image",
	"install" to "Install",
	"invalid_credentials_error" to "Invalid credentials.",
	"invalid_email_error" to "Invalid email.",
	"invalid_user_error" to "Invalid user credentials.",
	"invoice_deleted_message" to "Invoice deleted",
	"invoice_menu" to "Invoice menu",
	"invoice_number" to "Invoice #",
	"invoices" to "Invoices",
	"items_option" to "Items",
	"keep" to "Keep",
	"language_selector" to Formattable { (selected) -> "Language ${selected}" },
	"last_name_hint" to "Last Name",
	"ledger" to "ledger",
	"legal" to "Legal",
	"legal_error" to "Must accept Terms of Service and Privacy Policy",
	"liabilities" to "liabilities",
	"loading" to "Loading",
	"login_required_error" to "Login required.",
	"logo_view" to "Logo",
	"logout" to "Logout",
	"logout_prompt" to "Your data is not synchronized with cloud. Delete invoices, estimates, clients & other information on logout?",
	"main_screen" to "Main screen",
	"mark_paid" to "Mark as Paid",
	"message" to "Message",
	"missing_sender_msg" to "A document without sender information might be invalid.",
	"missing_sender_title" to "Missing sender information",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "August",
	"month_august_short" to "Aug.",
	"month_december" to "December",
	"month_december_short" to "Dec.",
	"month_february" to "February",
	"month_february_short" to "Feb.",
	"month_january" to "January",
	"month_january_short" to "Jan.",
	"month_july" to "July",
	"month_july_short" to "July",
	"month_june" to "June",
	"month_june_short" to "June",
	"month_march" to "March",
	"month_march_short" to "March",
	"month_may" to "May",
	"month_may_short" to "May",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "October",
	"month_october_short" to "Oct.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Failed to verify.",
	"new" to "New",
	"news" to "News",
	"next" to "Next",
	"no" to "no",
	"no_data_available" to "No data available",
	"no_search_results" to "Sorry, we couldn\'t find any results.",
	"none" to "None",
	"note_fields" to "Notes",
	"note_hint" to "Note",
	"notifications_option" to "Notifications",
	"ok" to "Ok",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Essential parts of DingVoice are forever free. Our mission is to help you grow your business, help us improve by sharing your feedback.",
	"onboarding_aboutus_title" to "We are here to help",
	"onboarding_paid_1" to "Access professional looking templates",
	"onboarding_paid_2" to "Take a deeper look into your financials",
	"onboarding_paid_3" to "Get problems resolved fast",
	"onboarding_paid_4" to "Keep your documents safe",
	"onboarding_paid_5" to "Stay up to date with invoice status changes",
	"onboarding_paid_6" to "Uninterrupted use of latest features",
	"onboarding_paid_title" to "Grow even faster with...",
	"onboarding_paywall_message" to "Enjoy all premium features now at the best price.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Just ${currencySymbol}${introAmount} for ${introDuration} ${introPeriod} and then ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Just ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} and then just ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} then ${currencySymbol}${introductoryAmount} for ${introductoryDuration} ${introPeriod} and then just ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Auto-renewable. Cancel anytime.",
	"onboarding_paywall_restore" to "Restore purchase",
	"onboarding_paywall_restore_failure" to "Restoring purchases has failed, try again later or contact support.",
	"onboarding_paywall_restore_success" to "Restore successful!",
	"onboarding_paywall_title" to "Achieve Greatness!",
	"onboarding_welcome_message" to "A complete toolset to manage your invoices & estimates.",
	"onboarding_welcome_title" to "Welcome to DingVoice",
	"other_state" to "Other",
	"overdue_state" to "Overdue",
	"paid_state" to "Paid",
	"password" to "Password",
	"password_blank_error" to "Password required.",
	"password_mismatch_error" to "Passwords do not match.",
	"pay" to "pay",
	"payment_instructions_label" to "Payment Instructions",
	"payment_reminders" to "Payment reminders",
	"phone_number_fields" to "Phone number",
	"phone_number_hint" to "Phone Number",
	"postcode_hint" to "Postcode",
	"preview" to "Preview",
	"preview_menu_hint" to "Preview menu",
	"price" to "Price",
	"privacy_policy" to "Privacy Policy",
	"profile" to "Profile",
	"profitable_client" to "Your most profitable client is...",
	"profits_report" to "Profits",
	"promo_content" to "Promotional content",
	"quantity_hint" to "Quantity",
	"quantity_label_hint" to "Quantity Label",
	"query_info" to "Query allows you to write custom calculations referring to both items and other tax rules as inputs. Add @ symbol and item or tax description to link to another entry +, -, *, /, % -  math operators",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} was received by your client." },
	"received_state" to "Received",
	"registration_code_fields" to "Registration Code",
	"reminder" to "Reminder",
	"reports" to "Reports",
	"reports_not_available" to "No reports available, come back later.",
	"reveal_password" to "Reveal password",
	"revenue" to "revenue",
	"sales_tax" to "VAT",
	"sales_tax_number" to "VAT number",
	"save" to "Save",
	"scan_document" to "Scan document",
	"search" to "Search",
	"select" to "Select",
	"select_icon" to "Select icon",
	"select_signature" to "Select signature",
	"select_template" to "Select template",
	"send" to "Send",
	"sent_state" to "Sent",
	"setup_sender" to "Set up sender",
	"share_option" to "Share",
	"ship_to" to "Ship to",
	"sign_in" to "Sign In",
	"sign_in_apple" to "Sign in with Apple",
	"sign_in_email" to "Sign in with email",
	"sign_in_facebook" to "Sign in with Facebook",
	"sign_in_google" to "Sign in with Google",
	"sign_up" to "Sign Up",
	"sign_up_label" to "Don't have an account?",
	"sign_up_link_label" to "Sign Up!",
	"signatures_option" to "Signatures",
	"skip" to "Skip",
	"sold_items_report" to "Sold Items",
	"sort_by" to "Sort By:",
	"status_update" to "Document status update",
	"sub_annual" to "Annual",
	"sub_annual_price" to Formattable { (price) -> "${price}/year" },
	"sub_backups" to "Cloud Backups",
	"sub_insights" to "Insights",
	"sub_month" to "Monthly",
	"sub_monthly_price" to Formattable { (price) -> "${price}/month" },
	"sub_notifications" to "Notifications",
	"sub_popup_message" to "Subscriptions are coming soon. Check back here in a little while.",
	"sub_support" to "Support",
	"sub_templates" to "Templates",
	"subscribe" to "Subscribe",
	"subscription_header" to "Grow your business with:",
	"subscription_option" to "Subscription",
	"subscriptions_load_fail" to "Sorry, we're unable to retrieve your subscriptions at this time. Please try again later or contact our support team for assistance.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "Subtotal",
	"sum" to "Sum",
	"support_option" to "Support",
	"switch_reports" to "Switch Report",
	"tax" to "Tax",
	"tax_deleted_message" to "Tax deleted",
	"tax_description_warning" to "If used this shows up on an invoice",
	"tax_input_flat" to "Flat",
	"tax_input_percent" to "Percent",
	"tax_number_fields" to "Tax number",
	"tax_number_hint" to "Tax Number",
	"tax_query_info_description" to "Query Info",
	"tax_report" to "Tax",
	"tax_rules_option" to "Tax Rules",
	"taxed_clients_report" to "Taxed Clients",
	"template" to "Template",
	"template_selector_info" to "Swipe to change the default template",
	"template_unlock_promo" to "Access more templates with Premium",
	"templates_option" to "Templates",
	"terms_of_service" to "Terms of Service",
	"thank_you" to "Thank You",
	"to" to "To",
	"total" to Formattable { (sum) -> "Total: ${sum}" },
	"total_label" to "Total",
	"undo_action" to "Undo",
	"unit" to "Unit",
	"unit_cost_hint" to "Unit Cost",
	"unknown_client" to "Unknown Client",
	"unlock" to "Unlock",
	"unnamed_item" to "Unnamed item",
	"upgrade" to "Upgrade",
	"user_collision_error" to "Account already exists.",
	"valid_until" to "Valid Until",
	"warning" to "Warning!",
	"yes" to "yes",
	"no_ads" to "No advertisement",
    )
    
}