// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Sv  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Acceptera ${termsOfService} och ${privacyPolicy}" }
override val account: String = "konto"
override val accounting: String = "redovisning"
override val accruals: String = "periodiseringar"
override val action_code_error: String = "Felaktig kod."
override val add_client_action: String = "Lägg till klient"
override val add_customer: String = "Lägg till kund"
override val add_expense: String = "Tillkommande kostnader"
override val add_fields_label: String = "Lägg till:"
override val add_invoice_action: String = "Skapa"
override val add_item_action: String = "Lägg till objekt"
override val add_item_button: String = "Lägg till objekt"
override val add_signature_action: String = "Lägg till signatur"
override val add_tax_action: String = "Lägg till skatt"
override val address_fields: String = "Adress"
override val address_line_hint: String = "Adressrad"
override val advanced: String = "Avancerad"
override val agree_marketing: String = "Jag samtycker till att ta emot direktmarknadsföring"
override val all_notifications: String = "Alla meddelanden"
override val amortization: String = "amortering"
override val amount: String = "Belopp"
override val asset: String = "tillgang"
override val attach_file: String = "Bifoga fil"
override val attachment: String = "Bilaga"
override val auth_error: String = "Åtgärden misslyckades, försök igen."
override val available_for_paid: String = "Molnsynkronisering är endast tillgänglig om du har en betald prenumeration."
override val backups_option: String = "Säkerhetskopior"
override val balance: String = "saldo"
override val bank_account: String = "Bankkonto"
override val bill_number: String = "Nummer på räkning"
override val cancel: String = "Avbryt"
override val cancel_trial: String = "Avbryt när som helst"
override val capital: String = "kapital"
override val cash: String = "kontanter"
override val categories: String = "Kategorier"
override val category: String = "Kategori"
override val category_add: String = "Lägg till kategori"
override val category_delete: String = "Radera kategori"
override val category_name: String = "Namn på kategori"
override val change_language: Formattable = Formattable { (language) -> "Fakturans språk: ${language}" }
override val change_language_label: String = "Fakturaspråk"
override val change_template: String = "Ändra mall"
override val city_hint: String = "Staden"
override val clear: String = "Klart"
override val client: String = "Klient"
override val client_actions: String = "Åtgärder för klienterna"
override val client_deleted_message: String = "Klient borttagen"
override val clients_error_required_fields: String = "Minst ett värde krävs."
override val clients_option: String = "Klienter"
override val close: String = "Stäng"
override val cloud_sync: String = "Synkronisering i molnet"
override val coming_soon: String = "Kommer snart!"
override val company_logo: String = "Företagets logotyp"
override val company_name_hint: String = "Företag"
override val confirm_password_label: String = "Bekräfta lösenordet"
override val confirmation_title: String = "Är du säker?"
override val convert_to_invoice: String = "Konvertera till faktura"
override val country_hint: String = "Land"
override val create_client: String = "Skapa en klient"
override val create_company: String = "Skapa ett företag"
override val create_document: String = "Skapa ett dokument"
override val create_estimate: String = "Uppskattning"
override val create_invoice: String = "Faktura"
override val create_item: String = "Skapa objekt"
override val create_new_client: String = "Skapa en ny klient"
override val create_new_item: String = "Skapa ett nytt objekt"
override val create_new_tax: String = "Skapa en ny skatt"
override val create_signature: String = "Signatur"
override val created: String = "Skapad"
override val credit: String = "kredit"
override val customer: String = "Kund"
override val date: String = "Datum"
override val debit: String = "debet"
override val debt: String = "skuld"
override val default_currency: Formattable = Formattable { (currency) -> "Standardvaluta: ${currency}" }
override val default_currency_label: String = "Standardvaluta"
override val delete: String = "Ta bort"
override val delete_account: String = "Ta bort Konto"
override val delete_confirmation_message: String = "Alla dina data kommer att gå förlorade."
override val delete_item_title: String = "Ta bort objektet?"
override val delete_signature: String = "Ta bort signatur"
override val depreciation: String = "avskrivning"
override val description: String = "Beskrivning"
override val description_hint: String = "Beskrivning"
override val detailed_report: String = "Detaljerad Rapport"
override val disabled: String = "Inaktiverad"
override val discount: String = "Rabatt"
override val discount_hint: String = "Rabatt"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amhariska"
override val display_ar: String = "Arabiska"
override val display_as: String = "Assamese"
override val display_az: String = "Azerbajdzjanska"
override val display_be: String = "Vitryska"
override val display_bg: String = "Bulgariska"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosniska"
override val display_ca: String = "Katalanska"
override val display_cs: String = "Tjeckiska"
override val display_da: String = "Danska"
override val display_de: String = "Tyska"
override val display_el: String = "Grekiska"
override val display_en: String = "Engelska"
override val display_es: String = "Spanska"
override val display_es_MX: String = "Spanska (Mexiko)"
override val display_et: String = "Estniska"
override val display_eu: String = "Baskiska"
override val display_fa: String = "Persiska"
override val display_fi: String = "Finska"
override val display_fil: String = "Filippinska"
override val display_fr: String = "Franska"
override val display_fr_CA: String = "Kanadensisk franska"
override val display_gl: String = "Galiciska"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hebreiska"
override val display_hi: String = "Hindi"
override val display_hr: String = "Kroatiska"
override val display_hu: String = "Ungerska"
override val display_hy: String = "Armeniska"
override val display_id: String = "Indonesiska"
override val display_is: String = "Isländska"
override val display_it: String = "Italienska"
override val display_ja: String = "Japanska"
override val display_ka: String = "Georgiska"
override val display_kk: String = "Kazakh"
override val display_km: String = "Centrala khmerer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Koreanska"
override val display_ky: String = "Kirgizistan"
override val display_lo: String = "Lao"
override val display_lt: String = "Litauiska"
override val display_lv: String = "Lettiska"
override val display_mk: String = "Makedonska"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongoliska"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malajiska"
override val display_my: String = "Burmesiska"
override val display_nb: String = "Norska Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Nederländska"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polska"
override val display_pt: String = "Portugisiska"
override val display_pt_BR: String = "Portugisiska (Brasilien)"
override val display_pt_PT: String = "Europeisk portugisiska"
override val display_ro: String = "Rumänska"
override val display_ru: String = "Ryska"
override val display_si: String = "Singalesiska"
override val display_sk: String = "Slovakiska"
override val display_sl: String = "Slovenska"
override val display_sq: String = "Albanska"
override val display_sr: String = "Serbiska"
override val display_sv: String = "Svenska"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thailändska"
override val display_tr: String = "Turkiska"
override val display_uk: String = "Ukrainska"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbekiska"
override val display_vi: String = "Vietnamesiska"
override val display_zh_Hant_TW: String = "Kinesiska (Taiwan, Traditionell)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Uppskattning"
override val document_invoice: String = "Faktura"
override val document_quote: String = "Offert"
override val done: String = "Klart"
override val download_app: String = "Ladda ner appen"
override val download_invoice: String = "Ladda ner Faktura"
override val download_pdf: String = "Ladda ner PDF"
override val draw_signature: String = "Rita en signatur"
override val due: Formattable = Formattable { (date) -> "Förfaller till betalning ${date}" }
override val due_date: String = "Förfallodag"
override val due_day: String = "på en dag"
override val due_label: String = "Förfallna"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} är förfallet ${dueWhen}!" }
override val due_today: String = "i dag"
override val due_week: String = "på en vecka"
override val duplicate: String = "Duplicera"
override val duration_day: String = "dag"
override val duration_month: String = "Månad"
override val duration_week: String = "vecka"
override val duration_year: String = "år"
override val email: String = "E-post"
override val email_blank_error: String = "E-post krävs."
override val email_error: String = "Ogiltig e-postadress."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Tack för din verksamhet. För att se hela ${documentType} klicka på knappen i slutet av detta brev. Observera att den är giltig till ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Du har fått en ny ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "VISA ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Kära ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Tack för din verksamhet. För att se hela ${documentType} klicka på knappen i slutet av detta brev. Vänligen betala resten av beloppet till ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Du har fått en ny ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "E-post för att återställa lösenordet har skickats, kontrollera din inkorg."
override val empty_clients_list_message: String = "Du har inga klienter att följa. Lägg till en ny klient."
override val empty_estimate_list_message: String = "Skapa din första uppskattning"
override val empty_expenses: String = "Du har inga utgifter att spåra. Lägg till en ny kostnad."
override val empty_invoice_list_message: String = "Skapa din första faktura"
override val empty_items_list_message: String = "Du har inga objekt, lägg till ett nytt objekt!"
override val empty_signatures_list_message: String = "Inga signaturer hittades. Skapa en!"
override val empty_taxes_list_message: String = "Du har inga skatteregler. Lägg till en ny skatteregel."
override val empty_templates_list_message: String = "Mallar är inte tillgängliga."
override val enabled: String = "Aktiverad"
override val entry: String = "post"
override val equity: String = "eget kapital"
override val estimate_number: String = "Uppskattning #"
override val estimates: String = "Uppskattningar"
override val estimates_report: String = "Uppskattningar"
override val expense_bank_fees: String = "Bankavgifter"
override val expense_benefits: String = "Fördelar"
override val expense_communication: String = "Kommunikation"
override val expense_debt: String = "Skuld"
override val expense_deleted: String = "Kostnader borttagna"
override val expense_depreciation: String = "Avskrivningar"
override val expense_gifts: String = "Gåvor"
override val expense_insurance: String = "Försäkring"
override val expense_inventory: String = "Inventarieförteckning"
override val expense_legal: String = "Juridisk"
override val expense_maintenance: String = "Underhåll"
override val expense_marketing: String = "Marknadsföring"
override val expense_meals: String = "Måltider"
override val expense_office_supplies: String = "Kontorsmaterial"
override val expense_rent: String = "Hyra"
override val expense_salaries: String = "Löner"
override val expense_shopping: String = "Shopping"
override val expense_software: String = "Programvara"
override val expense_status_available: String = "tillgänglig"
override val expense_status_processing: String = "Bearbetning"
override val expense_tax: String = "Skatt"
override val expense_training: String = "Utbildning"
override val expense_transport: String = "Transport"
override val expense_travel: String = "resor"
override val expense_utility: String = "Verktyg"
override val expenses: String = "Kostnader"
override val feedback_option: String = "Feedback"
override val filter: String = "Filter"
override val first_name_hint: String = "Förnamn"
override val forgot_password: String = "Återställ lösenordet"
override val forgot_password_label: String = "Glömt lösenord"
override val from: String = "Från"
override val general_error: String = "Ett fel inträffade, försök igen senare."
override val general_ledger: String = "huvudbok"
override val go_back_label: String = "Gå tillbaka"
override val group_by: String = "Grupp av:"
override val image_view: String = "Bild"
override val install: String = "Installera"
override val invalid_credentials_error: String = "Ogiltiga autentiseringsuppgifter."
override val invalid_email_error: String = "Felaktigt e-postmeddelande."
override val invalid_user_error: String = "Ogiltiga användaruppgifter."
override val invoice_deleted_message: String = "Faktura raderad"
override val invoice_menu: String = "Menyn Faktura"
override val invoice_number: String = "Faktura #"
override val invoices: String = "fakturor"
override val items_option: String = "objekt"
override val keep: String = "Håll"
override val language_selector: Formattable = Formattable { (selected) -> "Språk ${selected}" }
override val last_name_hint: String = "Efternamn"
override val ledger: String = "kontobok"
override val legal: String = "Juridisk"
override val legal_error: String = "Måste acceptera användarvillkoren och sekretesspolicyn"
override val liabilities: String = "skulder"
override val loading: String = "Laddar"
override val login_required_error: String = "Inloggning krävs."
override val logo_view: String = "Logotyp"
override val logout: String = "Logga ut"
override val logout_prompt: String = "Dina data synkroniseras inte med molnet. Ta bort fakturor, uppskattningar, klienter och annan information vid utloggning?"
override val main_screen: String = "Huvudskärmen"
override val mark_paid: String = "Markera som betald"
override val message: String = "Meddelande"
override val missing_sender_msg: String = "Ett dokument utan avsändarinformation kan vara ogiltigt."
override val missing_sender_title: String = "Avsändarinformation saknas"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "augusti"
override val month_august_short: String = "Aug."
override val month_december: String = "December"
override val month_december_short: String = "Dec."
override val month_february: String = "Februari"
override val month_february_short: String = "Feb."
override val month_january: String = "Januari"
override val month_january_short: String = "Jan."
override val month_july: String = "Juli"
override val month_july_short: String = "Juli"
override val month_june: String = "Juni"
override val month_june_short: String = "Juni"
override val month_march: String = "Mars"
override val month_march_short: String = "Mars"
override val month_may: String = "Maj"
override val month_may_short: String = "Maj"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktober"
override val month_october_short: String = "Okt."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Kontrollen misslyckades."
override val new: String = " ny"
override val news: String = "Nyheter"
override val next: String = "Nästa"
override val no: String = "nej"
override val no_data_available: String = "Inga data tillgängliga"
override val no_search_results: String = "Tyvärr kunde vi inte hitta några resultat."
override val none: String = "Ingen"
override val note_fields: String = "Anteckningar"
override val note_hint: String = "Obs"
override val notifications_option: String = "Meddelanden"
override val ok: String = "OK"
override val onboarding: String = "Inskolning"
override val onboarding_aboutus_message: String = "Viktiga delar av DingVoice är gratis för alltid. Vårt uppdrag är att hjälpa dig att utveckla ditt företag, hjälp oss att förbättra oss genom att dela med dig av din feedback."
override val onboarding_aboutus_title: String = "Vi är här för att hjälpa dig"
override val onboarding_paid_1: String = "Få tillgång till professionella mallar"
override val onboarding_paid_2: String = "Ta en djupare titt på din ekonomi"
override val onboarding_paid_3: String = "Få problem lösta snabbt"
override val onboarding_paid_4: String = "Håll dina dokument säkra"
override val onboarding_paid_5: String = "Håll dig uppdaterad om ändringar i fakturastatusen"
override val onboarding_paid_title: String = "Växer ännu snabbare med..."
override val onboarding_paywall_message: String = "Njut av alla premiumfunktioner nu till det bästa priset."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Bara ${currencySymbol}${introAmount} för ${introDuration} ${introPeriod} och sedan ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Bara ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis för ${trialDuration} ${trialPeriod} och sedan bara ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis för ${trialDuration} ${trialPeriod} sedan ${currencySymbol}${introductoryAmount} för ${introductoryDuration} ${introPeriod} och sedan bara ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Auto-förnyelsebar. Avbryt när som helst."
override val onboarding_paywall_restore: String = "Återställa köpet"
override val onboarding_paywall_restore_failure: String = "Återställningen av köp har misslyckats, försök igen senare eller kontakta supporten."
override val onboarding_paywall_restore_success: String = "Återställning lyckad!"
override val onboarding_paywall_title: String = "Uppnå storhet!"
override val onboarding_welcome_message: String = "En komplett verktygslåda för att hantera dina fakturor och uppskattningar."
override val onboarding_welcome_title: String = "Välkommen till DingVoice"
override val other_state: String = "Övrigt"
override val overdue_state: String = "Försenad"
override val paid_state: String = "Betald"
override val password: String = "Lösenord"
override val password_blank_error: String = "Lösenord krävs."
override val password_mismatch_error: String = "Lösenorden stämmer inte överens."
override val pay: String = "betala"
override val payment_instructions_label: String = "Betalningsanvisningar"
override val payment_reminders: String = "Påminnelser om betalningar"
override val phone_number_fields: String = "Telefonnummer"
override val phone_number_hint: String = "Telefonnummer"
override val postcode_hint: String = "Postnummer"
override val preview: String = "Förhandsgranska"
override val preview_menu_hint: String = "Meny för förhandsgranskning"
override val price: String = "Pris"
override val privacy_policy: String = "Integritetspolicy"
override val profile: String = "Profil"
override val profitable_client: String = "Din mest lönsamma klient är..."
override val profits_report: String = "Vinster"
override val promo_content: String = "Kampanjinnehåll"
override val quantity_hint: String = "Kvantitet"
override val quantity_label_hint: String = "Kvantitetsetikett"
override val query_info: String = "Med Query kan du skriva anpassade beräkningar som hänvisar till både poster och andra skatteregler som indata. Lägg till @-symbol och artikel- eller skattebeskrivning för att länka till en annan post +, -, *, /, % - matematiska operatörer."
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} har tagits emot av din klient." }
override val received_state: String = "Mottagen"
override val registration_code_fields: String = "Registreringskod"
override val reminder: String = "Påminnelse"
override val reports: String = "Rapporter"
override val reports_not_available: String = "Inga rapporter finns tillgängliga, återkom senare."
override val reveal_password: String = "Visa lösenordet"
override val revenue: String = "inkomst"
override val sales_tax: String = "MOMS"
override val sales_tax_number: String = "MOMS-nummer"
override val save: String = "Spara"
override val scan_document: String = "Skanna dokument"
override val search: String = "Sök på"
override val select: String = "Välj"
override val select_icon: String = "Välj ikon"
override val select_signature: String = "Välj signatur"
override val select_template: String = "Välj mall"
override val send: String = "Skicka"
override val sent_state: String = "Skickat"
override val setup_sender: String = "Ställ in avsändare"
override val share_option: String = "Dela"
override val ship_to: String = "Skicka till"
override val sign_in: String = "Logga in"
override val sign_in_apple: String = "Logga in med Apple"
override val sign_in_email: String = "Logga in med e-post"
override val sign_in_facebook: String = "Logga in med Facebook"
override val sign_in_google: String = "Logga in med Google"
override val sign_up: String = "Registrera dig"
override val sign_up_label: String = "Har du inget konto?"
override val sign_up_link_label: String = "Registrera dig!"
override val signatures_option: String = "Underskrifter"
override val skip: String = "Hoppa över"
override val sold_items_report: String = "Sålda Objekt"
override val sort_by: String = "Sortera efter:"
override val status_update: String = "Uppdatering av dokumentets status"
override val sub_annual: String = "Årlig"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/år" }
override val sub_backups: String = "Säkerhetskopiering i molnet"
override val sub_insights: String = "Insikter"
override val sub_month: String = "Månadsvis"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/månad" }
override val sub_notifications: String = "Aviseringar"
override val sub_popup_message: String = "Prenumerationer kommer snart. Titta tillbaka här om en liten stund."
override val sub_support: String = "Stöd"
override val sub_templates: String = "Mallar"
override val subscribe: String = "Prenumerera på"
override val subscription_header: String = "Utveckla ditt företag med:"
override val subscription_option: String = "Prenumeration"
override val subscriptions_load_fail: String = "Tyvärr kan vi inte hämta dina prenumerationer just nu. Försök igen senare eller kontakta vårt supportteam för att få hjälp."
override val subtotal: Formattable = Formattable { (sum) -> "Delsumma: ${sum}" }
override val subtotal_label: String = "Delsumma"
override val sum: String = "Summa"
override val support_option: String = "Stöd"
override val switch_reports: String = "Växla Rapport"
override val tax: String = "SKATT"
override val tax_deleted_message: String = "Skatt utgår"
override val tax_description_warning: String = "Om det används visas detta på en faktura."
override val tax_input_flat: String = "Platt"
override val tax_input_percent: String = "Procent"
override val tax_number_fields: String = "Skattenummer"
override val tax_number_hint: String = "Skattenummer"
override val tax_query_info_description: String = "Info om förfrågan"
override val tax_report: String = "Skatt"
override val tax_rules_option: String = "Skatteregler"
override val taxed_clients_report: String = "Beskattade kunder"
override val template: String = "Mall"
override val template_selector_info: String = "Svep för att ändra standardmall"
override val template_unlock_promo: String = "Få tillgång till fler mallar med Premium"
override val templates_option: String = "Mallar"
override val terms_of_service: String = "Villkor för tjänsten"
override val thank_you: String = "Tack"
override val to: String = "Till"
override val total: Formattable = Formattable { (sum) -> "Totalt: ${sum}" }
override val total_label: String = "TOTALT"
override val undo_action: String = "Ångra"
override val unit: String = "Enhet"
override val unit_cost_hint: String = "Kostnad per enhet"
override val unknown_client: String = "Okänd Klient"
override val unlock: String = "Lås upp"
override val unnamed_item: String = "Namnlösa objekt"
override val upgrade: String = "Uppgradera"
override val user_collision_error: String = "Kontot finns redan."
override val valid_until: String = "Giltig Till"
override val warning: String = "Varning!"
override val yes: String = "ja"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Acceptera ${termsOfService} och ${privacyPolicy}" },
	"account" to "konto",
	"accounting" to "redovisning",
	"accruals" to "periodiseringar",
	"action_code_error" to "Felaktig kod.",
	"add_client_action" to "Lägg till klient",
	"add_customer" to "Lägg till kund",
	"add_expense" to "Tillkommande kostnader",
	"add_fields_label" to "Lägg till:",
	"add_invoice_action" to "Skapa",
	"add_item_action" to "Lägg till objekt",
	"add_item_button" to "Lägg till objekt",
	"add_signature_action" to "Lägg till signatur",
	"add_tax_action" to "Lägg till skatt",
	"address_fields" to "Adress",
	"address_line_hint" to "Adressrad",
	"advanced" to "Avancerad",
	"agree_marketing" to "Jag samtycker till att ta emot direktmarknadsföring",
	"all_notifications" to "Alla meddelanden",
	"amortization" to "amortering",
	"amount" to "Belopp",
	"asset" to "tillgang",
	"attach_file" to "Bifoga fil",
	"attachment" to "Bilaga",
	"auth_error" to "Åtgärden misslyckades, försök igen.",
	"available_for_paid" to "Molnsynkronisering är endast tillgänglig om du har en betald prenumeration.",
	"backups_option" to "Säkerhetskopior",
	"balance" to "saldo",
	"bank_account" to "Bankkonto",
	"bill_number" to "Nummer på räkning",
	"cancel" to "Avbryt",
	"cancel_trial" to "Avbryt när som helst",
	"capital" to "kapital",
	"cash" to "kontanter",
	"categories" to "Kategorier",
	"category" to "Kategori",
	"category_add" to "Lägg till kategori",
	"category_delete" to "Radera kategori",
	"category_name" to "Namn på kategori",
	"change_language" to Formattable { (language) -> "Fakturans språk: ${language}" },
	"change_language_label" to "Fakturaspråk",
	"change_template" to "Ändra mall",
	"city_hint" to "Staden",
	"clear" to "Klart",
	"client" to "Klient",
	"client_actions" to "Åtgärder för klienterna",
	"client_deleted_message" to "Klient borttagen",
	"clients_error_required_fields" to "Minst ett värde krävs.",
	"clients_option" to "Klienter",
	"close" to "Stäng",
	"cloud_sync" to "Synkronisering i molnet",
	"coming_soon" to "Kommer snart!",
	"company_logo" to "Företagets logotyp",
	"company_name_hint" to "Företag",
	"confirm_password_label" to "Bekräfta lösenordet",
	"confirmation_title" to "Är du säker?",
	"convert_to_invoice" to "Konvertera till faktura",
	"country_hint" to "Land",
	"create_client" to "Skapa en klient",
	"create_company" to "Skapa ett företag",
	"create_document" to "Skapa ett dokument",
	"create_estimate" to "Uppskattning",
	"create_invoice" to "Faktura",
	"create_item" to "Skapa objekt",
	"create_new_client" to "Skapa en ny klient",
	"create_new_item" to "Skapa ett nytt objekt",
	"create_new_tax" to "Skapa en ny skatt",
	"create_signature" to "Signatur",
	"created" to "Skapad",
	"credit" to "kredit",
	"customer" to "Kund",
	"date" to "Datum",
	"debit" to "debet",
	"debt" to "skuld",
	"default_currency" to Formattable { (currency) -> "Standardvaluta: ${currency}" },
	"default_currency_label" to "Standardvaluta",
	"delete" to "Ta bort",
	"delete_account" to "Ta bort Konto",
	"delete_confirmation_message" to "Alla dina data kommer att gå förlorade.",
	"delete_item_title" to "Ta bort objektet?",
	"delete_signature" to "Ta bort signatur",
	"depreciation" to "avskrivning",
	"description" to "Beskrivning",
	"description_hint" to "Beskrivning",
	"detailed_report" to "Detaljerad Rapport",
	"disabled" to "Inaktiverad",
	"discount" to "Rabatt",
	"discount_hint" to "Rabatt",
	"display_af" to "Afrikaans",
	"display_am" to "Amhariska",
	"display_ar" to "Arabiska",
	"display_as" to "Assamese",
	"display_az" to "Azerbajdzjanska",
	"display_be" to "Vitryska",
	"display_bg" to "Bulgariska",
	"display_bn" to "Bengali",
	"display_bs" to "Bosniska",
	"display_ca" to "Katalanska",
	"display_cs" to "Tjeckiska",
	"display_da" to "Danska",
	"display_de" to "Tyska",
	"display_el" to "Grekiska",
	"display_en" to "Engelska",
	"display_es" to "Spanska",
	"display_es_MX" to "Spanska (Mexiko)",
	"display_et" to "Estniska",
	"display_eu" to "Baskiska",
	"display_fa" to "Persiska",
	"display_fi" to "Finska",
	"display_fil" to "Filippinska",
	"display_fr" to "Franska",
	"display_fr_CA" to "Kanadensisk franska",
	"display_gl" to "Galiciska",
	"display_gu" to "Gujarati",
	"display_he" to "Hebreiska",
	"display_hi" to "Hindi",
	"display_hr" to "Kroatiska",
	"display_hu" to "Ungerska",
	"display_hy" to "Armeniska",
	"display_id" to "Indonesiska",
	"display_is" to "Isländska",
	"display_it" to "Italienska",
	"display_ja" to "Japanska",
	"display_ka" to "Georgiska",
	"display_kk" to "Kazakh",
	"display_km" to "Centrala khmerer",
	"display_kn" to "Kannada",
	"display_ko" to "Koreanska",
	"display_ky" to "Kirgizistan",
	"display_lo" to "Lao",
	"display_lt" to "Litauiska",
	"display_lv" to "Lettiska",
	"display_mk" to "Makedonska",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongoliska",
	"display_mr" to "Marathi",
	"display_ms" to "Malajiska",
	"display_my" to "Burmesiska",
	"display_nb" to "Norska Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Nederländska",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polska",
	"display_pt" to "Portugisiska",
	"display_pt_BR" to "Portugisiska (Brasilien)",
	"display_pt_PT" to "Europeisk portugisiska",
	"display_ro" to "Rumänska",
	"display_ru" to "Ryska",
	"display_si" to "Singalesiska",
	"display_sk" to "Slovakiska",
	"display_sl" to "Slovenska",
	"display_sq" to "Albanska",
	"display_sr" to "Serbiska",
	"display_sv" to "Svenska",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thailändska",
	"display_tr" to "Turkiska",
	"display_uk" to "Ukrainska",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbekiska",
	"display_vi" to "Vietnamesiska",
	"display_zh_Hant_TW" to "Kinesiska (Taiwan, Traditionell)",
	"display_zu" to "Zulu",
	"document_estimate" to "Uppskattning",
	"document_invoice" to "Faktura",
	"document_quote" to "Offert",
	"done" to "Klart",
	"download_app" to "Ladda ner appen",
	"download_invoice" to "Ladda ner Faktura",
	"download_pdf" to "Ladda ner PDF",
	"draw_signature" to "Rita en signatur",
	"due" to Formattable { (date) -> "Förfaller till betalning ${date}" },
	"due_date" to "Förfallodag",
	"due_day" to "på en dag",
	"due_label" to "Förfallna",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} är förfallet ${dueWhen}!" },
	"due_today" to "i dag",
	"due_week" to "på en vecka",
	"duplicate" to "Duplicera",
	"duration_day" to "dag",
	"duration_month" to "Månad",
	"duration_week" to "vecka",
	"duration_year" to "år",
	"email" to "E-post",
	"email_blank_error" to "E-post krävs.",
	"email_error" to "Ogiltig e-postadress.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Tack för din verksamhet. För att se hela ${documentType} klicka på knappen i slutet av detta brev. Observera att den är giltig till ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Du har fått en ny ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "VISA ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Kära ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Tack för din verksamhet. För att se hela ${documentType} klicka på knappen i slutet av detta brev. Vänligen betala resten av beloppet till ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Du har fått en ny ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "E-post för att återställa lösenordet har skickats, kontrollera din inkorg.",
	"empty_clients_list_message" to "Du har inga klienter att följa. Lägg till en ny klient.",
	"empty_estimate_list_message" to "Skapa din första uppskattning",
	"empty_expenses" to "Du har inga utgifter att spåra. Lägg till en ny kostnad.",
	"empty_invoice_list_message" to "Skapa din första faktura",
	"empty_items_list_message" to "Du har inga objekt, lägg till ett nytt objekt!",
	"empty_signatures_list_message" to "Inga signaturer hittades. Skapa en!",
	"empty_taxes_list_message" to "Du har inga skatteregler. Lägg till en ny skatteregel.",
	"empty_templates_list_message" to "Mallar är inte tillgängliga.",
	"enabled" to "Aktiverad",
	"entry" to "post",
	"equity" to "eget kapital",
	"estimate_number" to "Uppskattning #",
	"estimates" to "Uppskattningar",
	"estimates_report" to "Uppskattningar",
	"expense_bank_fees" to "Bankavgifter",
	"expense_benefits" to "Fördelar",
	"expense_communication" to "Kommunikation",
	"expense_debt" to "Skuld",
	"expense_deleted" to "Kostnader borttagna",
	"expense_depreciation" to "Avskrivningar",
	"expense_gifts" to "Gåvor",
	"expense_insurance" to "Försäkring",
	"expense_inventory" to "Inventarieförteckning",
	"expense_legal" to "Juridisk",
	"expense_maintenance" to "Underhåll",
	"expense_marketing" to "Marknadsföring",
	"expense_meals" to "Måltider",
	"expense_office_supplies" to "Kontorsmaterial",
	"expense_rent" to "Hyra",
	"expense_salaries" to "Löner",
	"expense_shopping" to "Shopping",
	"expense_software" to "Programvara",
	"expense_status_available" to "tillgänglig",
	"expense_status_processing" to "Bearbetning",
	"expense_tax" to "Skatt",
	"expense_training" to "Utbildning",
	"expense_transport" to "Transport",
	"expense_travel" to "resor",
	"expense_utility" to "Verktyg",
	"expenses" to "Kostnader",
	"feedback_option" to "Feedback",
	"filter" to "Filter",
	"first_name_hint" to "Förnamn",
	"forgot_password" to "Återställ lösenordet",
	"forgot_password_label" to "Glömt lösenord",
	"from" to "Från",
	"general_error" to "Ett fel inträffade, försök igen senare.",
	"general_ledger" to "huvudbok",
	"go_back_label" to "Gå tillbaka",
	"group_by" to "Grupp av:",
	"image_view" to "Bild",
	"install" to "Installera",
	"invalid_credentials_error" to "Ogiltiga autentiseringsuppgifter.",
	"invalid_email_error" to "Felaktigt e-postmeddelande.",
	"invalid_user_error" to "Ogiltiga användaruppgifter.",
	"invoice_deleted_message" to "Faktura raderad",
	"invoice_menu" to "Menyn Faktura",
	"invoice_number" to "Faktura #",
	"invoices" to "fakturor",
	"items_option" to "objekt",
	"keep" to "Håll",
	"language_selector" to Formattable { (selected) -> "Språk ${selected}" },
	"last_name_hint" to "Efternamn",
	"ledger" to "kontobok",
	"legal" to "Juridisk",
	"legal_error" to "Måste acceptera användarvillkoren och sekretesspolicyn",
	"liabilities" to "skulder",
	"loading" to "Laddar",
	"login_required_error" to "Inloggning krävs.",
	"logo_view" to "Logotyp",
	"logout" to "Logga ut",
	"logout_prompt" to "Dina data synkroniseras inte med molnet. Ta bort fakturor, uppskattningar, klienter och annan information vid utloggning?",
	"main_screen" to "Huvudskärmen",
	"mark_paid" to "Markera som betald",
	"message" to "Meddelande",
	"missing_sender_msg" to "Ett dokument utan avsändarinformation kan vara ogiltigt.",
	"missing_sender_title" to "Avsändarinformation saknas",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "augusti",
	"month_august_short" to "Aug.",
	"month_december" to "December",
	"month_december_short" to "Dec.",
	"month_february" to "Februari",
	"month_february_short" to "Feb.",
	"month_january" to "Januari",
	"month_january_short" to "Jan.",
	"month_july" to "Juli",
	"month_july_short" to "Juli",
	"month_june" to "Juni",
	"month_june_short" to "Juni",
	"month_march" to "Mars",
	"month_march_short" to "Mars",
	"month_may" to "Maj",
	"month_may_short" to "Maj",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Oktober",
	"month_october_short" to "Okt.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Kontrollen misslyckades.",
	"new" to " ny",
	"news" to "Nyheter",
	"next" to "Nästa",
	"no" to "nej",
	"no_data_available" to "Inga data tillgängliga",
	"no_search_results" to "Tyvärr kunde vi inte hitta några resultat.",
	"none" to "Ingen",
	"note_fields" to "Anteckningar",
	"note_hint" to "Obs",
	"notifications_option" to "Meddelanden",
	"ok" to "OK",
	"onboarding" to "Inskolning",
	"onboarding_aboutus_message" to "Viktiga delar av DingVoice är gratis för alltid. Vårt uppdrag är att hjälpa dig att utveckla ditt företag, hjälp oss att förbättra oss genom att dela med dig av din feedback.",
	"onboarding_aboutus_title" to "Vi är här för att hjälpa dig",
	"onboarding_paid_1" to "Få tillgång till professionella mallar",
	"onboarding_paid_2" to "Ta en djupare titt på din ekonomi",
	"onboarding_paid_3" to "Få problem lösta snabbt",
	"onboarding_paid_4" to "Håll dina dokument säkra",
	"onboarding_paid_5" to "Håll dig uppdaterad om ändringar i fakturastatusen",
	"onboarding_paid_title" to "Växer ännu snabbare med...",
	"onboarding_paywall_message" to "Njut av alla premiumfunktioner nu till det bästa priset.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Bara ${currencySymbol}${introAmount} för ${introDuration} ${introPeriod} och sedan ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Bara ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis för ${trialDuration} ${trialPeriod} och sedan bara ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis för ${trialDuration} ${trialPeriod} sedan ${currencySymbol}${introductoryAmount} för ${introductoryDuration} ${introPeriod} och sedan bara ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Auto-förnyelsebar. Avbryt när som helst.",
	"onboarding_paywall_restore" to "Återställa köpet",
	"onboarding_paywall_restore_failure" to "Återställningen av köp har misslyckats, försök igen senare eller kontakta supporten.",
	"onboarding_paywall_restore_success" to "Återställning lyckad!",
	"onboarding_paywall_title" to "Uppnå storhet!",
	"onboarding_welcome_message" to "En komplett verktygslåda för att hantera dina fakturor och uppskattningar.",
	"onboarding_welcome_title" to "Välkommen till DingVoice",
	"other_state" to "Övrigt",
	"overdue_state" to "Försenad",
	"paid_state" to "Betald",
	"password" to "Lösenord",
	"password_blank_error" to "Lösenord krävs.",
	"password_mismatch_error" to "Lösenorden stämmer inte överens.",
	"pay" to "betala",
	"payment_instructions_label" to "Betalningsanvisningar",
	"payment_reminders" to "Påminnelser om betalningar",
	"phone_number_fields" to "Telefonnummer",
	"phone_number_hint" to "Telefonnummer",
	"postcode_hint" to "Postnummer",
	"preview" to "Förhandsgranska",
	"preview_menu_hint" to "Meny för förhandsgranskning",
	"price" to "Pris",
	"privacy_policy" to "Integritetspolicy",
	"profile" to "Profil",
	"profitable_client" to "Din mest lönsamma klient är...",
	"profits_report" to "Vinster",
	"promo_content" to "Kampanjinnehåll",
	"quantity_hint" to "Kvantitet",
	"quantity_label_hint" to "Kvantitetsetikett",
	"query_info" to "Med Query kan du skriva anpassade beräkningar som hänvisar till både poster och andra skatteregler som indata. Lägg till @-symbol och artikel- eller skattebeskrivning för att länka till en annan post +, -, *, /, % - matematiska operatörer.",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} har tagits emot av din klient." },
	"received_state" to "Mottagen",
	"registration_code_fields" to "Registreringskod",
	"reminder" to "Påminnelse",
	"reports" to "Rapporter",
	"reports_not_available" to "Inga rapporter finns tillgängliga, återkom senare.",
	"reveal_password" to "Visa lösenordet",
	"revenue" to "inkomst",
	"sales_tax" to "MOMS",
	"sales_tax_number" to "MOMS-nummer",
	"save" to "Spara",
	"scan_document" to "Skanna dokument",
	"search" to "Sök på",
	"select" to "Välj",
	"select_icon" to "Välj ikon",
	"select_signature" to "Välj signatur",
	"select_template" to "Välj mall",
	"send" to "Skicka",
	"sent_state" to "Skickat",
	"setup_sender" to "Ställ in avsändare",
	"share_option" to "Dela",
	"ship_to" to "Skicka till",
	"sign_in" to "Logga in",
	"sign_in_apple" to "Logga in med Apple",
	"sign_in_email" to "Logga in med e-post",
	"sign_in_facebook" to "Logga in med Facebook",
	"sign_in_google" to "Logga in med Google",
	"sign_up" to "Registrera dig",
	"sign_up_label" to "Har du inget konto?",
	"sign_up_link_label" to "Registrera dig!",
	"signatures_option" to "Underskrifter",
	"skip" to "Hoppa över",
	"sold_items_report" to "Sålda Objekt",
	"sort_by" to "Sortera efter:",
	"status_update" to "Uppdatering av dokumentets status",
	"sub_annual" to "Årlig",
	"sub_annual_price" to Formattable { (price) -> "${price}/år" },
	"sub_backups" to "Säkerhetskopiering i molnet",
	"sub_insights" to "Insikter",
	"sub_month" to "Månadsvis",
	"sub_monthly_price" to Formattable { (price) -> "${price}/månad" },
	"sub_notifications" to "Aviseringar",
	"sub_popup_message" to "Prenumerationer kommer snart. Titta tillbaka här om en liten stund.",
	"sub_support" to "Stöd",
	"sub_templates" to "Mallar",
	"subscribe" to "Prenumerera på",
	"subscription_header" to "Utveckla ditt företag med:",
	"subscription_option" to "Prenumeration",
	"subscriptions_load_fail" to "Tyvärr kan vi inte hämta dina prenumerationer just nu. Försök igen senare eller kontakta vårt supportteam för att få hjälp.",
	"subtotal" to Formattable { (sum) -> "Delsumma: ${sum}" },
	"subtotal_label" to "Delsumma",
	"sum" to "Summa",
	"support_option" to "Stöd",
	"switch_reports" to "Växla Rapport",
	"tax" to "SKATT",
	"tax_deleted_message" to "Skatt utgår",
	"tax_description_warning" to "Om det används visas detta på en faktura.",
	"tax_input_flat" to "Platt",
	"tax_input_percent" to "Procent",
	"tax_number_fields" to "Skattenummer",
	"tax_number_hint" to "Skattenummer",
	"tax_query_info_description" to "Info om förfrågan",
	"tax_report" to "Skatt",
	"tax_rules_option" to "Skatteregler",
	"taxed_clients_report" to "Beskattade kunder",
	"template" to "Mall",
	"template_selector_info" to "Svep för att ändra standardmall",
	"template_unlock_promo" to "Få tillgång till fler mallar med Premium",
	"templates_option" to "Mallar",
	"terms_of_service" to "Villkor för tjänsten",
	"thank_you" to "Tack",
	"to" to "Till",
	"total" to Formattable { (sum) -> "Totalt: ${sum}" },
	"total_label" to "TOTALT",
	"undo_action" to "Ångra",
	"unit" to "Enhet",
	"unit_cost_hint" to "Kostnad per enhet",
	"unknown_client" to "Okänd Klient",
	"unlock" to "Lås upp",
	"unnamed_item" to "Namnlösa objekt",
	"upgrade" to "Uppgradera",
	"user_collision_error" to "Kontot finns redan.",
	"valid_until" to "Giltig Till",
	"warning" to "Varning!",
	"yes" to "ja",
    )
    
}