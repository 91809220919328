package com.dingvoice.content

import androidx.compose.runtime.Composable
import com.dingvoice.style.WtTexts
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun FeaturesPage(isMobile: Boolean) {
    Div(attrs = {
        style {
            paddingTop(32.px)
            alignContent(AlignContent.Center)
            justifyContent(JustifyContent.Center)
            width(auto)
            height(auto)
            property("margin-left", "auto")
            property("margin-right", "auto")
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
        }
    }) {
        if (isMobile) {
            FeatureColumn(
                title = "Professional, easy to print and share invoicing.",
                description = "Grow your business with easy invoicing. Your customers will appreciate professional-looking and easy-to-read invoices. Download documents in PDF format, print, or share hassle-free with your client. Easily track missing payments and earnings per client. No limits on the number of free invoices!",
                image = "invoice.png"
            )
            FeatureColumn(
                "Share estimates with your clients.",
                description = "Not ready to send an invoice? Keep communication flowing. Let your customers know your business service or product costs early on by using estimates. Convey an expert value your business delivers with estimates matching your brand!",
                image = "estimate.png"
            )
            FeatureColumn(
                "Inventory.",
                description = "Keep track of all products or services offered by your business. The item management section provides a quick and straightforward way to manage the entire inventory. Add products from the inventory list directly into invoices or estimates for ease.",
                image = "items.png"
            )
            FeatureColumn(
                "Client management.",
                description = "Asking clients for their business information each time an invoice has to be sent can be embarrassing. Instead, keep track of your clients with an innovative client management tool. Quickly send new bills to your existing clientele and know which customers are the most valuable.",
                image = "clients.png"
            )
            FeatureColumn(
                "Taxation.",
                description = "Taxes are complicated, and they can differ for each customer, country, product, or service. Remembering different taxation rules for each situation can be a cumbersome and error-prone process. Instead, avoid time-consuming steps - record tax rule once and reuse it for a new invoice when future use case appears.",
                image = "taxes.png"
            )
            FeatureColumn(
                "Cloud Sync",
                description = "Keep your accounting in check and data safe by syncing all accounting information with various third-party cloud services. For extra security and even quicker invoicing, use our cloud sync option.",
                image = "cloud.png"
            )
        } else {
            FeatureBlock(
                title = "Professional, easy to print and share invoicing.",
                description = "Grow your business with easy invoicing. Your customers will appreciate professional-looking and easy-to-read invoices. Download documents in PDF format, print, or share hassle-free with your client. Easily track missing payments and earnings per client. No limits on the number of free invoices!",
                image = "invoice.png"
            )
            FeatureBlockInverse(
                "Share estimates with your clients.",
                description = "Not ready to send an invoice? Keep communication flowing. Let your customers know your business service or product costs early on by using estimates. Convey an expert value your business delivers with estimates matching your brand!",
                image = "estimate.png"
            )
            FeatureBlock(
                "Inventory.",
                description = "Keep track of all products or services offered by your business. The item management section provides a quick and straightforward way to manage the entire inventory. Add products from the inventory list directly into invoices or estimates for ease.",
                image = "items.png"
            )
            FeatureBlockInverse(
                "Client management.",
                description = "Asking clients for their business information each time an invoice has to be sent can be embarrassing. Instead, keep track of your clients with an innovative client management tool. Quickly send new bills to your existing clientele and know which customers are the most valuable.",
                image = "clients.png"
            )
            FeatureBlock(
                "Taxation.",
                description = "Taxes are complicated, and they can differ for each customer, country, product, or service. Remembering different taxation rules for each situation can be a cumbersome and error-prone process. Instead, avoid time-consuming steps - record tax rule once and reuse it for a new invoice when future use case appears.",
                image = "taxes.png"
            )
            FeatureBlockInverse(
                "Cloud Sync",
                description = "Keep your accounting in check and data safe by syncing all accounting information with various third-party cloud services. For extra security and even quicker invoicing, use our cloud sync option.",
                image = "cloud.png"
            )
        }
    }
}

@Composable
fun FeatureBlock(title: String, description: String, image: String = "ic_splash.png") {
    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            property("flex-basis", "auto")
        }
    }) {
        Div(attrs = {
            style {
                width(30.percent)
                paddingTop(16.px)
                display(DisplayStyle.Flex)
                alignItems(AlignItems.End)
                flexDirection(FlexDirection.Column)
            }
        }) {
            Span({
                classes(WtTexts.wtH3)
                style {
                    width(100.percent)
                    textAlign("end")
                }
            }) {
                Text(title)
            }

            Span({
                classes(WtTexts.wtText1)
                style {
                    width(100.percent)
                    paddingTop(8.px)
                    textAlign("end")
                }
            }) {
                Text(description)
            }
        }

        Img(image, "DingVoice", attrs = {
            style {
                paddingLeft(16.px)
                maxWidth(40.percent)
                width(40.percent)
                width(auto)
                height(auto)
            }
        })
    }
}

@Composable
fun FeatureBlockInverse(title: String, description: String, image: String = "ic_splash.png") {
    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            property("flex-basis", "auto")
        }
    }) {
        Img(image, "DingVoice", attrs = {
            style {
                paddingRight(16.px)
                maxWidth(40.percent)
                width(40.percent)
                width(auto)
                height(auto)
            }
        })

        Div(attrs = {
            style {
                paddingTop(16.px)
                width(30.percent)
                display(DisplayStyle.Flex)
                alignItems(AlignItems.End)
                flexDirection(FlexDirection.Column)
            }
        }) {
            Span({
                classes(WtTexts.wtH3)
                style {
                    width(100.percent)
                    textAlign("start")
                }
            }) {
                Text(title)
            }

            Span({
                classes(WtTexts.wtText1)
                style {
                    width(100.percent)
                    paddingTop(8.px)
                    textAlign("start")
                }
            }) {
                Text(description)
            }
        }
    }
}

@Composable
fun FeatureColumn(title: String, description: String, image: String = "ic_splash.png") {
    Div(attrs = {
        style {
            marginTop(60.px)
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            flexDirection(FlexDirection.Column)
        }
    }) {
        Div(attrs = {
            style {
                width(100.percent)
                paddingTop(16.px)
                display(DisplayStyle.Flex)
                alignItems(AlignItems.End)
                flexDirection(FlexDirection.Column)
            }
        }) {
            Span({
                classes(WtTexts.wtH3)
                style {
                    width(100.percent)
                    textAlign("start")
                }
            }) {
                Text(title)
            }

            Span({
                classes(WtTexts.wtText1)
                style {
                    width(100.percent)
                    paddingTop(8.px)
                    textAlign("start")
                }
            }) {
                Text(description)
            }
        }

        Img(image, "DingVoice", attrs = {
            style {
                paddingLeft(16.px)
                paddingRight(16.px)
                maxWidth(100.percent)
                width(100.percent)
                width(auto)
                height(auto)
            }
        })
    }
}
