package com.dingvoice.style

import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSUnitValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StylePropertyNumber
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.animation
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.borderWidth
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.delay
import org.jetbrains.compose.web.css.duration
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.minWidth
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.timingFunction
import org.jetbrains.compose.web.css.variable

object AppCSSVariables {
    val wtColorBrand by variable<CSSColorValue>()

    val wtOffsetTopUnit by variable<CSSUnitValue>()
    val wtHorizontalLayoutGutter by variable<CSSUnitValue>()
    val wtFlowUnit by variable<CSSUnitValue>()

    val wtHeroFontSize by variable<CSSUnitValue>()
    val wtHeroLineHeight by variable<CSSUnitValue>()
    val wtSubtitle2FontSize by variable<CSSUnitValue>()
    val wtSubtitle2LineHeight by variable<CSSUnitValue>()
    val wtH1FontSize by variable<CSSUnitValue>()
    val wtH2FontSize by variable<CSSUnitValue>()
    val wtH2LineHeight by variable<CSSUnitValue>()
    val wtH3FontSize by variable<CSSUnitValue>()
    val wtH1LineHeight by variable<CSSUnitValue>()
    val wtH3LineHeight by variable<CSSUnitValue>()

    val wtColCount by variable<StylePropertyNumber>()
}

object AppStylesheet : StyleSheet() {
    val actionButton by style {
        borderRadius(6.px)
        borderWidth(0.px)

        // hover selector for a class
        self + hover style {
            borderWidth(1.px)
            backgroundColor(Color("#B7C8E8"))
        }
    }

    val loaderAnim by keyframes {
        from {
            property("transform", "rotate(0deg)")
        }

        to {
            property("transform", "rotate(360deg)")
        }
    }

    val loader by style {
        property("border", "8px solid #f3f3f3")
        property("border-radius", "50%")
        property("border-top", "8px solid #3498db")
        property("width", "60px")
        property("height", "60px")

        animation(loaderAnim) {
            duration(2.s)
            timingFunction(AnimationTimingFunction.Linear)
            iterationCount = listOf(null)
        }
    }

    val fadein by keyframes {
        from {
            property("bottom", 0)
            property("opacity", 0)
        }

        to {
            property("bottom", 30.px)
            property("opacity", 1)
        }
    }

    val fadeout by keyframes {
        from {
            property("bottom", 30.px)
            property("opacity", 1)
        }

        to {
            property("bottom", 0)
            property("opacity", 0)
        }
    }

    val snackbar by style {
        property("visibility", "hidden")
        minWidth(250.px)
        marginLeft((-125).px)
        backgroundColor(Color("#333"))
        color(Color("#fff"))
        textAlign("center")
        borderRadius(2.px)
        padding(16.px)
        position(Position.Fixed)
        property("z-index", 1)
        left(50.percent)
        bottom(30.px)
    }

    val snackbarShowSuccess by style {
        property("visibility", "visible")
        backgroundColor(Color("green"))

        animation(fadein) {
            duration(0.5.s)
        }

        animation(fadeout) {
            duration(0.5.s)
            delay(2.5.s)
        }
    }
}
