// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Te  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} మరియు ${privacyPolicy}ని ఆమోదించండి" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "చెల్లని కోడ్."
override val add_client_action: String = "క్లయింట్ జోడించు"
override val add_customer: String = "కస్టమర్ ని జోడించండి"
override val add_expense: String = "ఖర్చును జోడించండి"
override val add_fields_label: String = "కూడండి:"
override val add_invoice_action: String = "ఏర్పరచు"
override val add_item_action: String = "ఐటమ్ జోడించు"
override val add_item_button: String = "ఐటమ్ జోడించు"
override val add_signature_action: String = "సంతకాన్ని జోడించు"
override val add_tax_action: String = "పన్ను జోడించు"
override val address_fields: String = "చిరునామా"
override val address_line_hint: String = "చిరునామా రేఖ"
override val advanced: String = "అధునాతన"
override val agree_marketing: String = "నేను డైరెక్ట్ మార్కెటింగ్ కమ్యూనికేషన్‌లను స్వీకరించడానికి అంగీకరిస్తున్నాను"
override val all_notifications: String = "అన్ని నోటిఫికేషన్ లు"
override val amortization: String = "amortization"
override val amount: String = "మొత్తం"
override val asset: String = "asset"
override val attach_file: String = "ఫైలు జత చేయుము"
override val attachment: String = "అటాచ్మెంట్"
override val auth_error: String = "చర్య విఫలమైంది, మళ్లీ ప్రయత్నించండి."
override val available_for_paid: String = "క్లౌడ్ సింక్ పెయిడ్ సబ్ స్క్రిప్షన్ కింద మాత్రమే లభ్యం అవుతుంది."
override val backups_option: String = "నకలు నిల్వలు"
override val balance: String = "balance"
override val bank_account: String = "బ్యాంక్ ఖాతా"
override val bill_number: String = "బిల్లు సంఖ్య"
override val cancel: String = "రద్దు"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "కేటగిరీలు"
override val category: String = "వర్గం"
override val category_add: String = "వర్గాన్ని జోడించండి"
override val category_delete: String = "వర్గాన్ని తొలగించండి"
override val category_name: String = "వర్గం పేరు"
override val change_language: Formattable = Formattable { (language) -> "ఇన్‌వాయిస్ భాష: ${language}" }
override val change_language_label: String = "ఇన్వాయిస్ భాష"
override val change_template: String = "టెంప్లెట్ మార్చండి"
override val city_hint: String = "నగరం"
override val clear: String = "స్పష్ట"
override val client: String = "క్లయింట్"
override val client_actions: String = "క్లయింట్ చర్యలు"
override val client_deleted_message: String = "క్లయింట్ డిలీట్ చేయబడ్డాడు"
override val clients_error_required_fields: String = "కనీసం ఒక విలువ అవసరం."
override val clients_option: String = "క్లయింట్ లు"
override val close: String = "దగ్గర"
override val cloud_sync: String = "క్లౌడ్ సింక్"
override val coming_soon: String = "త్వరలో వస్తోంది!"
override val company_logo: String = "కంపెనీ లోగో"
override val company_name_hint: String = "కంపెనీ"
override val confirm_password_label: String = "పాస్ వర్డ్ ధృవీకరించు"
override val confirmation_title: String = "మీరు చెప్పేది నిజమా?"
override val convert_to_invoice: String = "ఇన్ వాయిస్ కు మార్పిడి చేయండి"
override val country_hint: String = "దేశం"
override val create_client: String = "క్లయింట్ సృష్టించు"
override val create_company: String = "కంపెనీని సృష్టించు"
override val create_document: String = "డాక్యుమెంట్ సృష్టించు"
override val create_estimate: String = "అంచనా"
override val create_invoice: String = "ఇన్ వాయిస్"
override val create_item: String = "అంశాన్ని సృష్టించు"
override val create_new_client: String = "కొత్త క్లయింట్ సృష్టించండి"
override val create_new_item: String = "కొత్త ఐటమ్ సృష్టించండి"
override val create_new_tax: String = "కొత్త పన్నుసృష్టించు"
override val create_signature: String = "సంతకం"
override val created: String = "సృష్టించబడింది"
override val credit: String = "credit"
override val customer: String = "ఖాతాదారుడు"
override val date: String = "ఖర్జూరం"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "డిఫాల్ట్ కరెన్సీ: ${currency}" }
override val default_currency_label: String = "డిఫాల్ట్ కరెన్సీ"
override val delete: String = "పరిహరించు"
override val delete_account: String = "ఖాతాను తొలగించండి"
override val delete_confirmation_message: String = "మీ డేటా మొత్తం పోతుంది."
override val delete_item_title: String = "ఐటమ్ డిలీట్ చేయాలా?"
override val delete_signature: String = "సంతకాన్ని తొలగించు"
override val depreciation: String = "depreciation"
override val description: String = "వివరణ"
override val description_hint: String = "వర్ణన"
override val detailed_report: String = "వివరణాత్మక నివేదిక"
override val disabled: String = "నిలిపివేయబడింది"
override val discount: String = "తగ్గింపు"
override val discount_hint: String = "ముదరా"
override val display_af: String = "ఆఫ్రికన్"
override val display_am: String = "అమ్హారిక్"
override val display_ar: String = "అరబిక్"
override val display_as: String = "అస్సామీ"
override val display_az: String = "అజర్బైజాన్"
override val display_be: String = "బెలారసియన్"
override val display_bg: String = "బల్గేరియన్"
override val display_bn: String = "బెంగాలీ"
override val display_bs: String = "బోస్నియన్"
override val display_ca: String = "కాటలాన్"
override val display_cs: String = "చెక్"
override val display_da: String = "డానిష్"
override val display_de: String = "జర్మన్"
override val display_el: String = "గ్రీకు"
override val display_en: String = "ఇంగ్లీష్"
override val display_es: String = "స్పానిష్"
override val display_es_MX: String = "స్పానిష్ (మెక్సికో)"
override val display_et: String = "ఎస్టోనియన్"
override val display_eu: String = "బాస్క్"
override val display_fa: String = "పర్షియన్"
override val display_fi: String = "ఫిన్నిష్"
override val display_fil: String = "ఫిలిపినో"
override val display_fr: String = "ఫ్రెంచ్"
override val display_fr_CA: String = "కెనడియన్ ఫ్రెంచ్"
override val display_gl: String = "గలీషియన్"
override val display_gu: String = "గుజరాతీ"
override val display_he: String = "హిబ్రూ"
override val display_hi: String = "లేదు"
override val display_hr: String = "క్రొయేషియన్"
override val display_hu: String = "హంగేరియన్"
override val display_hy: String = "అర్మేనియన్"
override val display_id: String = "ఇండోనేషియన్"
override val display_is: String = "ఐస్లాండిక్"
override val display_it: String = "ఇటాలియన్"
override val display_ja: String = "జపనీస్"
override val display_ka: String = "జార్జియన్"
override val display_kk: String = "కజఖ్"
override val display_km: String = "సెంట్రల్ ఖైమర్"
override val display_kn: String = "కన్నడ"
override val display_ko: String = "కొరియన్"
override val display_ky: String = "కిర్గిజ్"
override val display_lo: String = "శ్రమ"
override val display_lt: String = "లిథువేనియన్"
override val display_lv: String = "లాట్వియన్"
override val display_mk: String = "మాసిడోనియన్"
override val display_ml: String = "మలయాళం"
override val display_mn: String = "మంగోలియన్"
override val display_mr: String = "మరాఠీ"
override val display_ms: String = "మలయ్"
override val display_my: String = "బర్మీస్"
override val display_nb: String = "నార్వేజియన్ బోక్మాల్"
override val display_ne: String = "నేపాలీ"
override val display_nl: String = "డచ్"
override val display_or: String = "ఒరియా"
override val display_pa: String = "పంజాబీ"
override val display_pl: String = "పోలిష్"
override val display_pt: String = "పోర్చుగీస్"
override val display_pt_BR: String = "పోర్చుగీస్ (బ్రెజిల్)"
override val display_pt_PT: String = "యూరోపియన్ పోర్చుగీస్"
override val display_ro: String = "రొమేనియన్"
override val display_ru: String = "రష్యన్"
override val display_si: String = "సింహళం"
override val display_sk: String = "స్లోవాక్"
override val display_sl: String = "స్లోవేనియన్"
override val display_sq: String = "అల్బేనియన్"
override val display_sr: String = "సెర్బియన్"
override val display_sv: String = "స్వీడిష్"
override val display_sw: String = "స్వాహిలి"
override val display_ta: String = "తమిళం"
override val display_te: String = "తెలుగు"
override val display_th: String = "థాయ్"
override val display_tr: String = "టర్కిష్"
override val display_uk: String = "ఉక్రేనియన్"
override val display_ur: String = "ఉర్దూ"
override val display_uz: String = "ఉజ్బెక్"
override val display_vi: String = "వియత్నామీస్"
override val display_zh_Hant_TW: String = "చైనీస్ (తైవాన్, సాంప్రదాయ)"
override val display_zu: String = "జులు"
override val document_estimate: String = "అంచనా"
override val document_invoice: String = "ఇన్ వాయిస్"
override val document_quote: String = "కోట్"
override val done: String = "పూర్తి"
override val download_app: String = "యాప్‌ని డౌన్‌లోడ్ చేయండి"
override val download_invoice: String = "ఇన్‌వాయిస్‌ని డౌన్‌లోడ్ చేయండి"
override val download_pdf: String = "PDFని డౌన్‌లోడ్ చేయండి"
override val draw_signature: String = "సంతకం గీయండి"
override val due: Formattable = Formattable { (date) -> "గడువు ${date}" }
override val due_date: String = "గడువు తేదీ"
override val due_day: String = "ఒక రోజులో"
override val due_label: String = "కట్టవలసిన"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} గడువు ${dueWhen}!" }
override val due_today: String = "ఈ రోజు"
override val due_week: String = "ఒక వారం లో"
override val duplicate: String = "నకిలీ"
override val duration_day: String = "రోజు"
override val duration_month: String = "నెల"
override val duration_week: String = "వారం"
override val duration_year: String = "సంవత్సరం"
override val email: String = "ఇమెయిల్"
override val email_blank_error: String = "ఇమెయిల్ అవసరం."
override val email_error: String = "చెల్లని ఇమెయిల్ చిరునామా."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "మీ వ్యాపారానికి ధన్యవాదములు. పూర్తి ${documentType}ని వీక్షించడానికి ఈ అక్షరం చివర ఉన్న బటన్‌ను క్లిక్ చేయండి. ఇది ${dueDate} వరకు చెల్లుబాటులో ఉంటుందని గమనించండి." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "మీరు కొత్తది అందుకున్నారు${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType}ని వీక్షించండి" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "ప్రియమైన ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "మీ వ్యాపారానికి ధన్యవాదములు. పూర్తి ${documentType}ని వీక్షించడానికి ఈ అక్షరం చివర ఉన్న బటన్‌ను క్లిక్ చేయండి. దయచేసి ${dueDate} వరకు బ్యాలెన్స్ చెల్లించండి." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "మీరు కొత్తది అందుకున్నారు${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "పంపిన పాస్ వర్డ్ ను రీసెట్ చేయడానికి ఇమెయిల్, దయచేసి మీ ఇన్ బాక్స్ ను తనిఖీ చేయండి."
override val empty_clients_list_message: String = "ట్రాక్ చేయడానికి మీకు క్లయింట్ లు లేరు. కొత్త క్లయింట్ జోడించండి."
override val empty_estimate_list_message: String = "మీ మొదటి అంచనాను సృష్టించండి"
override val empty_expenses: String = "మీరు ట్రాక్ చేయడానికి ఎటువంటి ఖర్చులు లేవు. కొత్త ఖర్చును జోడించండి."
override val empty_invoice_list_message: String = "మీ మొదటి ఇన్ వాయిస్ సృష్టించండి"
override val empty_items_list_message: String = "మీకు ఎలాంటి ఐటమ్ లు లేవు, కొత్త ఐటమ్ జోడించండి!"
override val empty_signatures_list_message: String = "ఎలాంటి సంతకాలు కనుగొనబడలేదు. ఒకటి సృష్టించండి!"
override val empty_taxes_list_message: String = "మీకు పన్ను నియమాలు లేవు. కొత్త పన్ను నిబంధనను జోడించండి."
override val empty_templates_list_message: String = "టెంప్లెట్ లు లభ్యం కావడం లేదు."
override val enabled: String = "ప్రారంభించబడింది"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "అంచనా #"
override val estimates: String = "అంచనాలు"
override val estimates_report: String = "అంచనాలు"
override val expense_bank_fees: String = "బ్యాంక్ ఫీజు"
override val expense_benefits: String = "లాభాలు"
override val expense_communication: String = "కమ్యూనికేషన్"
override val expense_debt: String = "అప్పు"
override val expense_deleted: String = "ఖర్చు తొలగించబడింది"
override val expense_depreciation: String = "తరుగుదల"
override val expense_gifts: String = "బహుమతులు"
override val expense_insurance: String = "భీమా"
override val expense_inventory: String = "ఇన్వెంటరీ"
override val expense_legal: String = "చట్టపరమైన"
override val expense_maintenance: String = "నిర్వహణ"
override val expense_marketing: String = "మార్కెటింగ్"
override val expense_meals: String = "భోజనం"
override val expense_office_supplies: String = "కార్యాలయ సామాగ్రి"
override val expense_rent: String = "అద్దె"
override val expense_salaries: String = "జీతాలు"
override val expense_shopping: String = "షాపింగ్"
override val expense_software: String = "సాఫ్ట్‌వేర్"
override val expense_status_available: String = "అందుబాటులో"
override val expense_status_processing: String = "ప్రాసెసింగ్"
override val expense_tax: String = "పన్ను"
override val expense_training: String = "శిక్షణ"
override val expense_transport: String = "రవాణా"
override val expense_travel: String = "ప్రయాణం"
override val expense_utility: String = "వినియోగ"
override val expenses: String = "ఖర్చులు"
override val feedback_option: String = "ఫీడ్ బ్యాక్"
override val filter: String = "వడపొయ్యి"
override val first_name_hint: String = "మొదటి పేరు"
override val forgot_password: String = "పాస్ వర్డ్ రీసెట్ చేయి"
override val forgot_password_label: String = "పాస్ వర్డ్ మర్చిపోయారు"
override val from: String = "నుండి"
override val general_error: String = "లోపం సంభవించింది, దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "వెనక్కి వెళ్ళు"
override val group_by: String = "గ్రూపు ద్వారా:"
override val image_view: String = "విగ్రహం"
override val install: String = "ఇన్‌స్టాల్ చేయండి"
override val invalid_credentials_error: String = "చెల్లని ఆధారాలు."
override val invalid_email_error: String = "చెల్లని ఇమెయిల్."
override val invalid_user_error: String = "చెల్లని వినియోగదారు ఆధారాలు."
override val invoice_deleted_message: String = "ఇన్ వాయిస్ డిలీట్ చేయబడింది"
override val invoice_menu: String = "ఇన్ వాయిస్ మెనూ"
override val invoice_number: String = "ఇన్ వాయిస్ #"
override val invoices: String = "ఇన్ వాయిస్ లు"
override val items_option: String = "అంశాలు"
override val keep: String = "ఉంచు"
override val language_selector: Formattable = Formattable { (selected) -> "భాష ${selected}" }
override val last_name_hint: String = "చివరి పేరు"
override val ledger: String = "ledger"
override val legal: String = "చట్టబద్ధ"
override val legal_error: String = "సర్వీస్ నిబంధనలు మరియు గోప్యతా పాలసీని విధిగా ఆమోదించాలి."
override val liabilities: String = "liabilities"
override val loading: String = "లోడింగ్"
override val login_required_error: String = "లాగిన్ అవసరం."
override val logo_view: String = "లోగో"
override val logout: String = "లాగౌట్"
override val logout_prompt: String = "మీ డేటా క్లౌడ్ తో సింక్రనైజ్ చేయబడలేదు. లాగౌట్ పై ఇన్ వాయిస్ లు, అంచనాలు, క్లయింట్ లు మరియు ఇతర సమాచారాన్ని తొలగించాలా?"
override val main_screen: String = "ప్రధాన తెర"
override val mark_paid: String = "చెల్లించినవిధంగా మార్క్"
override val message: String = "సందేశం"
override val missing_sender_msg: String = "పంపినవారి సమాచారం లేని పత్రం చెల్లదు."
override val missing_sender_title: String = "పంపినవారి సమాచారం లేదు"
override val month_april: String = "ఏప్రి"
override val month_april_short: String = "ఏప్రి"
override val month_august: String = "ఆగష్టు"
override val month_august_short: String = "ఆగస్ట్."
override val month_december: String = "డిసెంబర్"
override val month_december_short: String = "డిసెంబర్"
override val month_february: String = "ఫిబ్రవరి"
override val month_february_short: String = "ఫిబ్రవరి."
override val month_january: String = "జనవరి"
override val month_january_short: String = "జనవరి"
override val month_july: String = "జూలై"
override val month_july_short: String = "జూలై"
override val month_june: String = "జూన్"
override val month_june_short: String = "జూన్"
override val month_march: String = "మార్చి"
override val month_march_short: String = "మార్చి"
override val month_may: String = "మే"
override val month_may_short: String = "మే"
override val month_november: String = "నవంబర్"
override val month_november_short: String = "నవంబర్"
override val month_october: String = "అక్టోబర్"
override val month_october_short: String = "అక్టో"
override val month_september: String = "సెప్టెంబర్"
override val month_september_short: String = "సెప్టెం"
override val multifactor_error: String = "వెరిఫై చేయడం విఫలమైంది."
override val new: String = "కొత్తది"
override val news: String = "వార్త"
override val next: String = "తరువాత"
override val no: String = "no"
override val no_data_available: String = "సమాచారం అందుబాటులో లేదు"
override val no_search_results: String = "క్షమించండి, మేం ఎలాంటి ఫలితాలను కనుగొనలేకపోయాం."
override val none: String = "ఏదీ లేదు"
override val note_fields: String = "గమనికలు"
override val note_hint: String = "రాసుకో"
override val notifications_option: String = "నోటిఫికేషన్ లు"
override val ok: String = "సరే"
override val onboarding: String = "ఆన్‌బోర్డింగ్"
override val onboarding_aboutus_message: String = "DingVoice యొక్క ముఖ్యమైన భాగాలు ఎప్పటికీ ఉచితం. మా లక్ష్యం మీ వ్యాపారాన్ని వృద్ధి చేయడంలో మీకు సహాయం చేయడం, మీ అభిప్రాయాన్ని పంచుకోవడం ద్వారా మెరుగుపరచడంలో మాకు సహాయపడటం."
override val onboarding_aboutus_title: String = "మేము సహాయం చేయడానికి ఇక్కడ ఉన్నాము"
override val onboarding_paid_1: String = "ప్రొఫెషనల్ లుకింగ్ టెంప్లేట్‌లను యాక్సెస్ చేయండి"
override val onboarding_paid_2: String = "మీ ఆర్థిక స్థితిగతులను లోతుగా పరిశీలించండి"
override val onboarding_paid_3: String = "సమస్యలను త్వరగా పరిష్కరించండి"
override val onboarding_paid_4: String = "మీ పత్రాలను సురక్షితంగా ఉంచండి"
override val onboarding_paid_5: String = "ఇన్‌వాయిస్ స్థితి మార్పులతో తాజాగా ఉండండి"
override val onboarding_paid_title: String = "దీనితో మరింత వేగంగా ఎదగండి..."
override val onboarding_paywall_message: String = "ఇప్పుడు అన్ని ప్రీమియం ఫీచర్‌లను ఉత్తమ ధరతో ఆస్వాదించండి."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod}కి కేవలం ${currencySymbol}${introAmount} ఆపై ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "కేవలం ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod}కి ఉచితం ఆపై కేవలం ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "కోసం ఉచితం${trialDuration}${trialPeriod} అప్పుడు${currencySymbol}${introductoryAmount} కోసం${introductoryDuration}${introPeriod} ఆపై కేవలం${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "స్వయంచాలకంగా పునరుద్ధరించదగినది. ఎప్పుడైనా రద్దు చేయండి."
override val onboarding_paywall_restore: String = "కొనుగోలు పునరుద్ధరించడానికి"
override val onboarding_paywall_restore_failure: String = "కొనుగోళ్లను పునరుద్ధరించడం విఫలమైంది, తర్వాత మళ్లీ ప్రయత్నించండి లేదా మద్దతును సంప్రదించండి."
override val onboarding_paywall_restore_success: String = "పునరుద్ధరించడం విజయవంతమైంది!"
override val onboarding_paywall_title: String = "గొప్పతనాన్ని సాధించండి!"
override val onboarding_welcome_message: String = "మీ ఇన్‌వాయిస్‌లు &amp; అంచనాలను నిర్వహించడానికి పూర్తి టూల్‌సెట్."
override val onboarding_welcome_title: String = "DingVoiceకి స్వాగతం"
override val other_state: String = "ఇతర"
override val overdue_state: String = "బకాయి"
override val paid_state: String = "చెల్లించబడింది"
override val password: String = "పాస్ వర్డ్"
override val password_blank_error: String = "పాస్ వర్డ్ అవసరం."
override val password_mismatch_error: String = "పాస్ వర్డ్ లు జతకావడం లేదు."
override val pay: String = "pay"
override val payment_instructions_label: String = "చెల్లింపు సూచనలు"
override val payment_reminders: String = "చెల్లింపు జ్ఞాపికలు"
override val phone_number_fields: String = "ఫోన్ నంబర్"
override val phone_number_hint: String = "ఫోన్ నంబర్"
override val postcode_hint: String = "పోస్ట్ కోడ్"
override val preview: String = "పరిదృశ్యం"
override val preview_menu_hint: String = "పరిదృశ్యం మెనూ"
override val price: String = "వెల"
override val privacy_policy: String = "గోప్యతా విధానం"
override val profile: String = "ప్రొఫైల్"
override val profitable_client: String = "మీ అత్యంత లాభదాయకమైన క్లయింట్..."
override val profits_report: String = "లాభాలు"
override val promo_content: String = "ప్రమోషనల్ కంటెంట్"
override val quantity_hint: String = "పరిమాణం"
override val quantity_label_hint: String = "పరిమాణ లేబుల్"
override val query_info: String = "ఐటమ్ లు మరియు ఇతర ట్యాక్స్ రూల్స్ రెండింటిని ఇన్ పుట్ లుగా పేర్కొంటూ కస్టమ్ కాలిక్యులేషన్ లను రాయడానికి క్వైరీ మిమ్మల్ని అనుమతిస్తుంది. మరొక ఎంట్రీ +, -, *, /, % - గణిత ఆపరేటర్లకు లింక్ చేయడానికి @ చిహ్నం మరియు అంశం లేదా పన్ను వివరణను జోడించండి"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} మీ క్లయింట్ ద్వారా స్వీకరించబడింది." }
override val received_state: String = "అందుకుంది"
override val registration_code_fields: String = "రిజిస్ట్రేషన్ కోడ్"
override val reminder: String = "రిమైండర్"
override val reports: String = "నివేదికలు"
override val reports_not_available: String = "నివేదికలు ఏవీ అందుబాటులో లేవు, తర్వాత తిరిగి రండి."
override val reveal_password: String = "పాస్ వర్డ్ వెల్లడించు"
override val revenue: String = "revenue"
override val sales_tax: String = "VAT"
override val sales_tax_number: String = "వ్యాట్ సంఖ్య"
override val save: String = "కాపాడు"
override val scan_document: String = "పత్రాన్ని స్కాన్ చేయండి"
override val search: String = "వెతుకు"
override val select: String = "ఎంచుకోండి"
override val select_icon: String = "చిహ్నాన్ని ఎంచుకోండి"
override val select_signature: String = "సంతకాన్ని ఎంచుకోండి"
override val select_template: String = "టెంప్లెట్ ఎంచుకోండి"
override val send: String = "పంపించు"
override val sent_state: String = "పంపబడింది"
override val setup_sender: String = "పంపేవారిని సెటప్ చేయండి"
override val share_option: String = "భాగం"
override val ship_to: String = "బదిలీ"
override val sign_in: String = "సైన్ ఇన్"
override val sign_in_apple: String = "యాపిల్ తో సైన్ ఇన్ చేయండి"
override val sign_in_email: String = "ఇమెయిల్ తో సైన్ ఇన్ చేయండి"
override val sign_in_facebook: String = "ఫేస్ బుక్ తో సైన్ ఇన్ చేయండి"
override val sign_in_google: String = "గూగుల్ తో సైన్ ఇన్ చేయండి"
override val sign_up: String = "సైన్ అప్"
override val sign_up_label: String = "ఖాతా లేదా?"
override val sign_up_link_label: String = "సైన్ అప్!"
override val signatures_option: String = "సంతకాలు"
override val skip: String = "దాటవేయి"
override val sold_items_report: String = "అమ్మిన వస్తువులు"
override val sort_by: String = "సార్ట్ బై:"
override val status_update: String = "పత్రం స్థితి నవీకరణ"
override val sub_annual: String = "వార్షిక"
override val sub_annual_price: Formattable = Formattable { (price) -> "సంవత్సరానికి ${price}" }
override val sub_backups: String = "క్లౌడ్ బ్యాకప్‌లు"
override val sub_insights: String = "అంతర్దృష్టులు"
override val sub_month: String = "నెలవారీ"
override val sub_monthly_price: Formattable = Formattable { (price) -> "నెలకు ${price}" }
override val sub_notifications: String = "నోటిఫికేషన్లు"
override val sub_popup_message: String = "సభ్యత్వాలు త్వరలో రానున్నాయి. కొద్దిసేపటి తర్వాత ఇక్కడ తిరిగి తనిఖీ చేయండి."
override val sub_support: String = "మద్దతు"
override val sub_templates: String = "టెంప్లేట్లు"
override val subscribe: String = "సబ్ స్క్రైబ్ అవ్వండి"
override val subscription_header: String = "దీనితో మీ వ్యాపారాన్ని పెంచుకోండి:"
override val subscription_option: String = "సబ్ స్క్రిప్షన్"
override val subscriptions_load_fail: String = "క్షమించండి, మేము ఈ సమయంలో మీ సభ్యత్వాలను తిరిగి పొందలేకపోతున్నాము. దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి లేదా సహాయం కోసం మా మద్దతు బృందాన్ని సంప్రదించండి."
override val subtotal: Formattable = Formattable { (sum) -> "సబ్ టోటల్: ${sum}" }
override val subtotal_label: String = "ఉపమొత్తం"
override val sum: String = "మొత్తం"
override val support_option: String = "సహాయంగా"
override val switch_reports: String = "స్విచ్ రిపోర్ట్"
override val tax: String = "పన్ను"
override val tax_deleted_message: String = "ట్యాక్స్ డిలీట్ చేయబడింది"
override val tax_description_warning: String = "ఒకవేళ ఉపయోగించినట్లయితే, ఇది ఇన్ వాయిస్ మీద చూపుతుంది."
override val tax_input_flat: String = "చదును"
override val tax_input_percent: String = "శాతం"
override val tax_number_fields: String = "పన్ను సంఖ్య"
override val tax_number_hint: String = "పన్ను సంఖ్య"
override val tax_query_info_description: String = "క్వైరీ సమాచారం"
override val tax_report: String = "పన్ను"
override val tax_rules_option: String = "పన్ను నియమాలు"
override val taxed_clients_report: String = "పన్ను విధించిన ఖాతాదారులు"
override val template: String = "మూస"
override val template_selector_info: String = "డిఫాల్ట్ టెంప్లెట్ మార్చడానికి స్వైప్ చేయండి"
override val template_unlock_promo: String = "Premiumతో మరిన్ని టెంప్లేట్‌లను యాక్సెస్ చేయండి"
override val templates_option: String = "టెంప్లేట్లు"
override val terms_of_service: String = "సేవా నిబంధనలు"
override val thank_you: String = "ధన్యవాదాలు"
override val to: String = "కు"
override val total: Formattable = Formattable { (sum) -> "మొత్తం: ${sum}" }
override val total_label: String = "మొత్తం"
override val undo_action: String = "రద్దు"
override val unit: String = "యూనిట్"
override val unit_cost_hint: String = "యూనిట్ ఖర్చు"
override val unknown_client: String = "తెలియని క్లయింట్"
override val unlock: String = "అన్‌లాక్ చేయండి"
override val unnamed_item: String = "పేరులేని అంశం"
override val upgrade: String = "అప్ గ్రేడ్ చేయి"
override val user_collision_error: String = "ఖాతా ఇప్పటికే ఉంది."
override val valid_until: String = "చెల్లుబాటు అయ్యే వరకు"
override val warning: String = "హెచ్చరిక!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} మరియు ${privacyPolicy}ని ఆమోదించండి" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "చెల్లని కోడ్.",
	"add_client_action" to "క్లయింట్ జోడించు",
	"add_customer" to "కస్టమర్ ని జోడించండి",
	"add_expense" to "ఖర్చును జోడించండి",
	"add_fields_label" to "కూడండి:",
	"add_invoice_action" to "ఏర్పరచు",
	"add_item_action" to "ఐటమ్ జోడించు",
	"add_item_button" to "ఐటమ్ జోడించు",
	"add_signature_action" to "సంతకాన్ని జోడించు",
	"add_tax_action" to "పన్ను జోడించు",
	"address_fields" to "చిరునామా",
	"address_line_hint" to "చిరునామా రేఖ",
	"advanced" to "అధునాతన",
	"agree_marketing" to "నేను డైరెక్ట్ మార్కెటింగ్ కమ్యూనికేషన్‌లను స్వీకరించడానికి అంగీకరిస్తున్నాను",
	"all_notifications" to "అన్ని నోటిఫికేషన్ లు",
	"amortization" to "amortization",
	"amount" to "మొత్తం",
	"asset" to "asset",
	"attach_file" to "ఫైలు జత చేయుము",
	"attachment" to "అటాచ్మెంట్",
	"auth_error" to "చర్య విఫలమైంది, మళ్లీ ప్రయత్నించండి.",
	"available_for_paid" to "క్లౌడ్ సింక్ పెయిడ్ సబ్ స్క్రిప్షన్ కింద మాత్రమే లభ్యం అవుతుంది.",
	"backups_option" to "నకలు నిల్వలు",
	"balance" to "balance",
	"bank_account" to "బ్యాంక్ ఖాతా",
	"bill_number" to "బిల్లు సంఖ్య",
	"cancel" to "రద్దు",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "కేటగిరీలు",
	"category" to "వర్గం",
	"category_add" to "వర్గాన్ని జోడించండి",
	"category_delete" to "వర్గాన్ని తొలగించండి",
	"category_name" to "వర్గం పేరు",
	"change_language" to Formattable { (language) -> "ఇన్‌వాయిస్ భాష: ${language}" },
	"change_language_label" to "ఇన్వాయిస్ భాష",
	"change_template" to "టెంప్లెట్ మార్చండి",
	"city_hint" to "నగరం",
	"clear" to "స్పష్ట",
	"client" to "క్లయింట్",
	"client_actions" to "క్లయింట్ చర్యలు",
	"client_deleted_message" to "క్లయింట్ డిలీట్ చేయబడ్డాడు",
	"clients_error_required_fields" to "కనీసం ఒక విలువ అవసరం.",
	"clients_option" to "క్లయింట్ లు",
	"close" to "దగ్గర",
	"cloud_sync" to "క్లౌడ్ సింక్",
	"coming_soon" to "త్వరలో వస్తోంది!",
	"company_logo" to "కంపెనీ లోగో",
	"company_name_hint" to "కంపెనీ",
	"confirm_password_label" to "పాస్ వర్డ్ ధృవీకరించు",
	"confirmation_title" to "మీరు చెప్పేది నిజమా?",
	"convert_to_invoice" to "ఇన్ వాయిస్ కు మార్పిడి చేయండి",
	"country_hint" to "దేశం",
	"create_client" to "క్లయింట్ సృష్టించు",
	"create_company" to "కంపెనీని సృష్టించు",
	"create_document" to "డాక్యుమెంట్ సృష్టించు",
	"create_estimate" to "అంచనా",
	"create_invoice" to "ఇన్ వాయిస్",
	"create_item" to "అంశాన్ని సృష్టించు",
	"create_new_client" to "కొత్త క్లయింట్ సృష్టించండి",
	"create_new_item" to "కొత్త ఐటమ్ సృష్టించండి",
	"create_new_tax" to "కొత్త పన్నుసృష్టించు",
	"create_signature" to "సంతకం",
	"created" to "సృష్టించబడింది",
	"credit" to "credit",
	"customer" to "ఖాతాదారుడు",
	"date" to "ఖర్జూరం",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "డిఫాల్ట్ కరెన్సీ: ${currency}" },
	"default_currency_label" to "డిఫాల్ట్ కరెన్సీ",
	"delete" to "పరిహరించు",
	"delete_account" to "ఖాతాను తొలగించండి",
	"delete_confirmation_message" to "మీ డేటా మొత్తం పోతుంది.",
	"delete_item_title" to "ఐటమ్ డిలీట్ చేయాలా?",
	"delete_signature" to "సంతకాన్ని తొలగించు",
	"depreciation" to "depreciation",
	"description" to "వివరణ",
	"description_hint" to "వర్ణన",
	"detailed_report" to "వివరణాత్మక నివేదిక",
	"disabled" to "నిలిపివేయబడింది",
	"discount" to "తగ్గింపు",
	"discount_hint" to "ముదరా",
	"display_af" to "ఆఫ్రికన్",
	"display_am" to "అమ్హారిక్",
	"display_ar" to "అరబిక్",
	"display_as" to "అస్సామీ",
	"display_az" to "అజర్బైజాన్",
	"display_be" to "బెలారసియన్",
	"display_bg" to "బల్గేరియన్",
	"display_bn" to "బెంగాలీ",
	"display_bs" to "బోస్నియన్",
	"display_ca" to "కాటలాన్",
	"display_cs" to "చెక్",
	"display_da" to "డానిష్",
	"display_de" to "జర్మన్",
	"display_el" to "గ్రీకు",
	"display_en" to "ఇంగ్లీష్",
	"display_es" to "స్పానిష్",
	"display_es_MX" to "స్పానిష్ (మెక్సికో)",
	"display_et" to "ఎస్టోనియన్",
	"display_eu" to "బాస్క్",
	"display_fa" to "పర్షియన్",
	"display_fi" to "ఫిన్నిష్",
	"display_fil" to "ఫిలిపినో",
	"display_fr" to "ఫ్రెంచ్",
	"display_fr_CA" to "కెనడియన్ ఫ్రెంచ్",
	"display_gl" to "గలీషియన్",
	"display_gu" to "గుజరాతీ",
	"display_he" to "హిబ్రూ",
	"display_hi" to "లేదు",
	"display_hr" to "క్రొయేషియన్",
	"display_hu" to "హంగేరియన్",
	"display_hy" to "అర్మేనియన్",
	"display_id" to "ఇండోనేషియన్",
	"display_is" to "ఐస్లాండిక్",
	"display_it" to "ఇటాలియన్",
	"display_ja" to "జపనీస్",
	"display_ka" to "జార్జియన్",
	"display_kk" to "కజఖ్",
	"display_km" to "సెంట్రల్ ఖైమర్",
	"display_kn" to "కన్నడ",
	"display_ko" to "కొరియన్",
	"display_ky" to "కిర్గిజ్",
	"display_lo" to "శ్రమ",
	"display_lt" to "లిథువేనియన్",
	"display_lv" to "లాట్వియన్",
	"display_mk" to "మాసిడోనియన్",
	"display_ml" to "మలయాళం",
	"display_mn" to "మంగోలియన్",
	"display_mr" to "మరాఠీ",
	"display_ms" to "మలయ్",
	"display_my" to "బర్మీస్",
	"display_nb" to "నార్వేజియన్ బోక్మాల్",
	"display_ne" to "నేపాలీ",
	"display_nl" to "డచ్",
	"display_or" to "ఒరియా",
	"display_pa" to "పంజాబీ",
	"display_pl" to "పోలిష్",
	"display_pt" to "పోర్చుగీస్",
	"display_pt_BR" to "పోర్చుగీస్ (బ్రెజిల్)",
	"display_pt_PT" to "యూరోపియన్ పోర్చుగీస్",
	"display_ro" to "రొమేనియన్",
	"display_ru" to "రష్యన్",
	"display_si" to "సింహళం",
	"display_sk" to "స్లోవాక్",
	"display_sl" to "స్లోవేనియన్",
	"display_sq" to "అల్బేనియన్",
	"display_sr" to "సెర్బియన్",
	"display_sv" to "స్వీడిష్",
	"display_sw" to "స్వాహిలి",
	"display_ta" to "తమిళం",
	"display_te" to "తెలుగు",
	"display_th" to "థాయ్",
	"display_tr" to "టర్కిష్",
	"display_uk" to "ఉక్రేనియన్",
	"display_ur" to "ఉర్దూ",
	"display_uz" to "ఉజ్బెక్",
	"display_vi" to "వియత్నామీస్",
	"display_zh_Hant_TW" to "చైనీస్ (తైవాన్, సాంప్రదాయ)",
	"display_zu" to "జులు",
	"document_estimate" to "అంచనా",
	"document_invoice" to "ఇన్ వాయిస్",
	"document_quote" to "కోట్",
	"done" to "పూర్తి",
	"download_app" to "యాప్‌ని డౌన్‌లోడ్ చేయండి",
	"download_invoice" to "ఇన్‌వాయిస్‌ని డౌన్‌లోడ్ చేయండి",
	"download_pdf" to "PDFని డౌన్‌లోడ్ చేయండి",
	"draw_signature" to "సంతకం గీయండి",
	"due" to Formattable { (date) -> "గడువు ${date}" },
	"due_date" to "గడువు తేదీ",
	"due_day" to "ఒక రోజులో",
	"due_label" to "కట్టవలసిన",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} గడువు ${dueWhen}!" },
	"due_today" to "ఈ రోజు",
	"due_week" to "ఒక వారం లో",
	"duplicate" to "నకిలీ",
	"duration_day" to "రోజు",
	"duration_month" to "నెల",
	"duration_week" to "వారం",
	"duration_year" to "సంవత్సరం",
	"email" to "ఇమెయిల్",
	"email_blank_error" to "ఇమెయిల్ అవసరం.",
	"email_error" to "చెల్లని ఇమెయిల్ చిరునామా.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "మీ వ్యాపారానికి ధన్యవాదములు. పూర్తి ${documentType}ని వీక్షించడానికి ఈ అక్షరం చివర ఉన్న బటన్‌ను క్లిక్ చేయండి. ఇది ${dueDate} వరకు చెల్లుబాటులో ఉంటుందని గమనించండి." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "మీరు కొత్తది అందుకున్నారు${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType}ని వీక్షించండి" },
	"email_invoice_greeting" to Formattable { (clientName) -> "ప్రియమైన ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "మీ వ్యాపారానికి ధన్యవాదములు. పూర్తి ${documentType}ని వీక్షించడానికి ఈ అక్షరం చివర ఉన్న బటన్‌ను క్లిక్ చేయండి. దయచేసి ${dueDate} వరకు బ్యాలెన్స్ చెల్లించండి." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "మీరు కొత్తది అందుకున్నారు${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "పంపిన పాస్ వర్డ్ ను రీసెట్ చేయడానికి ఇమెయిల్, దయచేసి మీ ఇన్ బాక్స్ ను తనిఖీ చేయండి.",
	"empty_clients_list_message" to "ట్రాక్ చేయడానికి మీకు క్లయింట్ లు లేరు. కొత్త క్లయింట్ జోడించండి.",
	"empty_estimate_list_message" to "మీ మొదటి అంచనాను సృష్టించండి",
	"empty_expenses" to "మీరు ట్రాక్ చేయడానికి ఎటువంటి ఖర్చులు లేవు. కొత్త ఖర్చును జోడించండి.",
	"empty_invoice_list_message" to "మీ మొదటి ఇన్ వాయిస్ సృష్టించండి",
	"empty_items_list_message" to "మీకు ఎలాంటి ఐటమ్ లు లేవు, కొత్త ఐటమ్ జోడించండి!",
	"empty_signatures_list_message" to "ఎలాంటి సంతకాలు కనుగొనబడలేదు. ఒకటి సృష్టించండి!",
	"empty_taxes_list_message" to "మీకు పన్ను నియమాలు లేవు. కొత్త పన్ను నిబంధనను జోడించండి.",
	"empty_templates_list_message" to "టెంప్లెట్ లు లభ్యం కావడం లేదు.",
	"enabled" to "ప్రారంభించబడింది",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "అంచనా #",
	"estimates" to "అంచనాలు",
	"estimates_report" to "అంచనాలు",
	"expense_bank_fees" to "బ్యాంక్ ఫీజు",
	"expense_benefits" to "లాభాలు",
	"expense_communication" to "కమ్యూనికేషన్",
	"expense_debt" to "అప్పు",
	"expense_deleted" to "ఖర్చు తొలగించబడింది",
	"expense_depreciation" to "తరుగుదల",
	"expense_gifts" to "బహుమతులు",
	"expense_insurance" to "భీమా",
	"expense_inventory" to "ఇన్వెంటరీ",
	"expense_legal" to "చట్టపరమైన",
	"expense_maintenance" to "నిర్వహణ",
	"expense_marketing" to "మార్కెటింగ్",
	"expense_meals" to "భోజనం",
	"expense_office_supplies" to "కార్యాలయ సామాగ్రి",
	"expense_rent" to "అద్దె",
	"expense_salaries" to "జీతాలు",
	"expense_shopping" to "షాపింగ్",
	"expense_software" to "సాఫ్ట్‌వేర్",
	"expense_status_available" to "అందుబాటులో",
	"expense_status_processing" to "ప్రాసెసింగ్",
	"expense_tax" to "పన్ను",
	"expense_training" to "శిక్షణ",
	"expense_transport" to "రవాణా",
	"expense_travel" to "ప్రయాణం",
	"expense_utility" to "వినియోగ",
	"expenses" to "ఖర్చులు",
	"feedback_option" to "ఫీడ్ బ్యాక్",
	"filter" to "వడపొయ్యి",
	"first_name_hint" to "మొదటి పేరు",
	"forgot_password" to "పాస్ వర్డ్ రీసెట్ చేయి",
	"forgot_password_label" to "పాస్ వర్డ్ మర్చిపోయారు",
	"from" to "నుండి",
	"general_error" to "లోపం సంభవించింది, దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి.",
	"general_ledger" to "general ledger",
	"go_back_label" to "వెనక్కి వెళ్ళు",
	"group_by" to "గ్రూపు ద్వారా:",
	"image_view" to "విగ్రహం",
	"install" to "ఇన్‌స్టాల్ చేయండి",
	"invalid_credentials_error" to "చెల్లని ఆధారాలు.",
	"invalid_email_error" to "చెల్లని ఇమెయిల్.",
	"invalid_user_error" to "చెల్లని వినియోగదారు ఆధారాలు.",
	"invoice_deleted_message" to "ఇన్ వాయిస్ డిలీట్ చేయబడింది",
	"invoice_menu" to "ఇన్ వాయిస్ మెనూ",
	"invoice_number" to "ఇన్ వాయిస్ #",
	"invoices" to "ఇన్ వాయిస్ లు",
	"items_option" to "అంశాలు",
	"keep" to "ఉంచు",
	"language_selector" to Formattable { (selected) -> "భాష ${selected}" },
	"last_name_hint" to "చివరి పేరు",
	"ledger" to "ledger",
	"legal" to "చట్టబద్ధ",
	"legal_error" to "సర్వీస్ నిబంధనలు మరియు గోప్యతా పాలసీని విధిగా ఆమోదించాలి.",
	"liabilities" to "liabilities",
	"loading" to "లోడింగ్",
	"login_required_error" to "లాగిన్ అవసరం.",
	"logo_view" to "లోగో",
	"logout" to "లాగౌట్",
	"logout_prompt" to "మీ డేటా క్లౌడ్ తో సింక్రనైజ్ చేయబడలేదు. లాగౌట్ పై ఇన్ వాయిస్ లు, అంచనాలు, క్లయింట్ లు మరియు ఇతర సమాచారాన్ని తొలగించాలా?",
	"main_screen" to "ప్రధాన తెర",
	"mark_paid" to "చెల్లించినవిధంగా మార్క్",
	"message" to "సందేశం",
	"missing_sender_msg" to "పంపినవారి సమాచారం లేని పత్రం చెల్లదు.",
	"missing_sender_title" to "పంపినవారి సమాచారం లేదు",
	"month_april" to "ఏప్రి",
	"month_april_short" to "ఏప్రి",
	"month_august" to "ఆగష్టు",
	"month_august_short" to "ఆగస్ట్.",
	"month_december" to "డిసెంబర్",
	"month_december_short" to "డిసెంబర్",
	"month_february" to "ఫిబ్రవరి",
	"month_february_short" to "ఫిబ్రవరి.",
	"month_january" to "జనవరి",
	"month_january_short" to "జనవరి",
	"month_july" to "జూలై",
	"month_july_short" to "జూలై",
	"month_june" to "జూన్",
	"month_june_short" to "జూన్",
	"month_march" to "మార్చి",
	"month_march_short" to "మార్చి",
	"month_may" to "మే",
	"month_may_short" to "మే",
	"month_november" to "నవంబర్",
	"month_november_short" to "నవంబర్",
	"month_october" to "అక్టోబర్",
	"month_october_short" to "అక్టో",
	"month_september" to "సెప్టెంబర్",
	"month_september_short" to "సెప్టెం",
	"multifactor_error" to "వెరిఫై చేయడం విఫలమైంది.",
	"new" to "కొత్తది",
	"news" to "వార్త",
	"next" to "తరువాత",
	"no" to "no",
	"no_data_available" to "సమాచారం అందుబాటులో లేదు",
	"no_search_results" to "క్షమించండి, మేం ఎలాంటి ఫలితాలను కనుగొనలేకపోయాం.",
	"none" to "ఏదీ లేదు",
	"note_fields" to "గమనికలు",
	"note_hint" to "రాసుకో",
	"notifications_option" to "నోటిఫికేషన్ లు",
	"ok" to "సరే",
	"onboarding" to "ఆన్‌బోర్డింగ్",
	"onboarding_aboutus_message" to "DingVoice యొక్క ముఖ్యమైన భాగాలు ఎప్పటికీ ఉచితం. మా లక్ష్యం మీ వ్యాపారాన్ని వృద్ధి చేయడంలో మీకు సహాయం చేయడం, మీ అభిప్రాయాన్ని పంచుకోవడం ద్వారా మెరుగుపరచడంలో మాకు సహాయపడటం.",
	"onboarding_aboutus_title" to "మేము సహాయం చేయడానికి ఇక్కడ ఉన్నాము",
	"onboarding_paid_1" to "ప్రొఫెషనల్ లుకింగ్ టెంప్లేట్‌లను యాక్సెస్ చేయండి",
	"onboarding_paid_2" to "మీ ఆర్థిక స్థితిగతులను లోతుగా పరిశీలించండి",
	"onboarding_paid_3" to "సమస్యలను త్వరగా పరిష్కరించండి",
	"onboarding_paid_4" to "మీ పత్రాలను సురక్షితంగా ఉంచండి",
	"onboarding_paid_5" to "ఇన్‌వాయిస్ స్థితి మార్పులతో తాజాగా ఉండండి",
	"onboarding_paid_title" to "దీనితో మరింత వేగంగా ఎదగండి...",
	"onboarding_paywall_message" to "ఇప్పుడు అన్ని ప్రీమియం ఫీచర్‌లను ఉత్తమ ధరతో ఆస్వాదించండి.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod}కి కేవలం ${currencySymbol}${introAmount} ఆపై ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "కేవలం ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod}కి ఉచితం ఆపై కేవలం ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "కోసం ఉచితం${trialDuration}${trialPeriod} అప్పుడు${currencySymbol}${introductoryAmount} కోసం${introductoryDuration}${introPeriod} ఆపై కేవలం${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "స్వయంచాలకంగా పునరుద్ధరించదగినది. ఎప్పుడైనా రద్దు చేయండి.",
	"onboarding_paywall_restore" to "కొనుగోలు పునరుద్ధరించడానికి",
	"onboarding_paywall_restore_failure" to "కొనుగోళ్లను పునరుద్ధరించడం విఫలమైంది, తర్వాత మళ్లీ ప్రయత్నించండి లేదా మద్దతును సంప్రదించండి.",
	"onboarding_paywall_restore_success" to "పునరుద్ధరించడం విజయవంతమైంది!",
	"onboarding_paywall_title" to "గొప్పతనాన్ని సాధించండి!",
	"onboarding_welcome_message" to "మీ ఇన్‌వాయిస్‌లు &amp; అంచనాలను నిర్వహించడానికి పూర్తి టూల్‌సెట్.",
	"onboarding_welcome_title" to "DingVoiceకి స్వాగతం",
	"other_state" to "ఇతర",
	"overdue_state" to "బకాయి",
	"paid_state" to "చెల్లించబడింది",
	"password" to "పాస్ వర్డ్",
	"password_blank_error" to "పాస్ వర్డ్ అవసరం.",
	"password_mismatch_error" to "పాస్ వర్డ్ లు జతకావడం లేదు.",
	"pay" to "pay",
	"payment_instructions_label" to "చెల్లింపు సూచనలు",
	"payment_reminders" to "చెల్లింపు జ్ఞాపికలు",
	"phone_number_fields" to "ఫోన్ నంబర్",
	"phone_number_hint" to "ఫోన్ నంబర్",
	"postcode_hint" to "పోస్ట్ కోడ్",
	"preview" to "పరిదృశ్యం",
	"preview_menu_hint" to "పరిదృశ్యం మెనూ",
	"price" to "వెల",
	"privacy_policy" to "గోప్యతా విధానం",
	"profile" to "ప్రొఫైల్",
	"profitable_client" to "మీ అత్యంత లాభదాయకమైన క్లయింట్...",
	"profits_report" to "లాభాలు",
	"promo_content" to "ప్రమోషనల్ కంటెంట్",
	"quantity_hint" to "పరిమాణం",
	"quantity_label_hint" to "పరిమాణ లేబుల్",
	"query_info" to "ఐటమ్ లు మరియు ఇతర ట్యాక్స్ రూల్స్ రెండింటిని ఇన్ పుట్ లుగా పేర్కొంటూ కస్టమ్ కాలిక్యులేషన్ లను రాయడానికి క్వైరీ మిమ్మల్ని అనుమతిస్తుంది. మరొక ఎంట్రీ +, -, *, /, % - గణిత ఆపరేటర్లకు లింక్ చేయడానికి @ చిహ్నం మరియు అంశం లేదా పన్ను వివరణను జోడించండి",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} మీ క్లయింట్ ద్వారా స్వీకరించబడింది." },
	"received_state" to "అందుకుంది",
	"registration_code_fields" to "రిజిస్ట్రేషన్ కోడ్",
	"reminder" to "రిమైండర్",
	"reports" to "నివేదికలు",
	"reports_not_available" to "నివేదికలు ఏవీ అందుబాటులో లేవు, తర్వాత తిరిగి రండి.",
	"reveal_password" to "పాస్ వర్డ్ వెల్లడించు",
	"revenue" to "revenue",
	"sales_tax" to "VAT",
	"sales_tax_number" to "వ్యాట్ సంఖ్య",
	"save" to "కాపాడు",
	"scan_document" to "పత్రాన్ని స్కాన్ చేయండి",
	"search" to "వెతుకు",
	"select" to "ఎంచుకోండి",
	"select_icon" to "చిహ్నాన్ని ఎంచుకోండి",
	"select_signature" to "సంతకాన్ని ఎంచుకోండి",
	"select_template" to "టెంప్లెట్ ఎంచుకోండి",
	"send" to "పంపించు",
	"sent_state" to "పంపబడింది",
	"setup_sender" to "పంపేవారిని సెటప్ చేయండి",
	"share_option" to "భాగం",
	"ship_to" to "బదిలీ",
	"sign_in" to "సైన్ ఇన్",
	"sign_in_apple" to "యాపిల్ తో సైన్ ఇన్ చేయండి",
	"sign_in_email" to "ఇమెయిల్ తో సైన్ ఇన్ చేయండి",
	"sign_in_facebook" to "ఫేస్ బుక్ తో సైన్ ఇన్ చేయండి",
	"sign_in_google" to "గూగుల్ తో సైన్ ఇన్ చేయండి",
	"sign_up" to "సైన్ అప్",
	"sign_up_label" to "ఖాతా లేదా?",
	"sign_up_link_label" to "సైన్ అప్!",
	"signatures_option" to "సంతకాలు",
	"skip" to "దాటవేయి",
	"sold_items_report" to "అమ్మిన వస్తువులు",
	"sort_by" to "సార్ట్ బై:",
	"status_update" to "పత్రం స్థితి నవీకరణ",
	"sub_annual" to "వార్షిక",
	"sub_annual_price" to Formattable { (price) -> "సంవత్సరానికి ${price}" },
	"sub_backups" to "క్లౌడ్ బ్యాకప్‌లు",
	"sub_insights" to "అంతర్దృష్టులు",
	"sub_month" to "నెలవారీ",
	"sub_monthly_price" to Formattable { (price) -> "నెలకు ${price}" },
	"sub_notifications" to "నోటిఫికేషన్లు",
	"sub_popup_message" to "సభ్యత్వాలు త్వరలో రానున్నాయి. కొద్దిసేపటి తర్వాత ఇక్కడ తిరిగి తనిఖీ చేయండి.",
	"sub_support" to "మద్దతు",
	"sub_templates" to "టెంప్లేట్లు",
	"subscribe" to "సబ్ స్క్రైబ్ అవ్వండి",
	"subscription_header" to "దీనితో మీ వ్యాపారాన్ని పెంచుకోండి:",
	"subscription_option" to "సబ్ స్క్రిప్షన్",
	"subscriptions_load_fail" to "క్షమించండి, మేము ఈ సమయంలో మీ సభ్యత్వాలను తిరిగి పొందలేకపోతున్నాము. దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి లేదా సహాయం కోసం మా మద్దతు బృందాన్ని సంప్రదించండి.",
	"subtotal" to Formattable { (sum) -> "సబ్ టోటల్: ${sum}" },
	"subtotal_label" to "ఉపమొత్తం",
	"sum" to "మొత్తం",
	"support_option" to "సహాయంగా",
	"switch_reports" to "స్విచ్ రిపోర్ట్",
	"tax" to "పన్ను",
	"tax_deleted_message" to "ట్యాక్స్ డిలీట్ చేయబడింది",
	"tax_description_warning" to "ఒకవేళ ఉపయోగించినట్లయితే, ఇది ఇన్ వాయిస్ మీద చూపుతుంది.",
	"tax_input_flat" to "చదును",
	"tax_input_percent" to "శాతం",
	"tax_number_fields" to "పన్ను సంఖ్య",
	"tax_number_hint" to "పన్ను సంఖ్య",
	"tax_query_info_description" to "క్వైరీ సమాచారం",
	"tax_report" to "పన్ను",
	"tax_rules_option" to "పన్ను నియమాలు",
	"taxed_clients_report" to "పన్ను విధించిన ఖాతాదారులు",
	"template" to "మూస",
	"template_selector_info" to "డిఫాల్ట్ టెంప్లెట్ మార్చడానికి స్వైప్ చేయండి",
	"template_unlock_promo" to "Premiumతో మరిన్ని టెంప్లేట్‌లను యాక్సెస్ చేయండి",
	"templates_option" to "టెంప్లేట్లు",
	"terms_of_service" to "సేవా నిబంధనలు",
	"thank_you" to "ధన్యవాదాలు",
	"to" to "కు",
	"total" to Formattable { (sum) -> "మొత్తం: ${sum}" },
	"total_label" to "మొత్తం",
	"undo_action" to "రద్దు",
	"unit" to "యూనిట్",
	"unit_cost_hint" to "యూనిట్ ఖర్చు",
	"unknown_client" to "తెలియని క్లయింట్",
	"unlock" to "అన్‌లాక్ చేయండి",
	"unnamed_item" to "పేరులేని అంశం",
	"upgrade" to "అప్ గ్రేడ్ చేయి",
	"user_collision_error" to "ఖాతా ఇప్పటికే ఉంది.",
	"valid_until" to "చెల్లుబాటు అయ్యే వరకు",
	"warning" to "హెచ్చరిక!",
	"yes" to "yes",
    )
    
}