// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Lv  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Pieņemt ${termsOfService} un ${privacyPolicy}" }
override val account: String = "konts"
override val accounting: String = "grāmatvedība"
override val accruals: String = "uzkrājumi"
override val action_code_error: String = "Nederīgs kods."
override val add_client_action: String = "Pievienot klientu"
override val add_customer: String = "Pievienot klientu"
override val add_expense: String = "Pievienot izdevumus"
override val add_fields_label: String = "Pievienot:"
override val add_invoice_action: String = "Izveidot"
override val add_item_action: String = "Pievienot vienību"
override val add_item_button: String = "Pievienot objektu"
override val add_signature_action: String = "Pievienot parakstu"
override val add_tax_action: String = "Pievienot nodokli"
override val address_fields: String = "Adrese"
override val address_line_hint: String = "Adreses līnija"
override val advanced: String = "Advanced"
override val agree_marketing: String = "Es piekrītu saņemt tiešā mārketinga paziņojumus"
override val all_notifications: String = "Visi paziņojumi"
override val amortization: String = "amortizācija"
override val amount: String = "Summa"
override val asset: String = "aktīvi"
override val attach_file: String = "Pievienot failu"
override val attachment: String = "Pielikums"
override val auth_error: String = "Darbība neizdevās, mēģiniet vēlreiz."
override val available_for_paid: String = "Mākoņa sinhronizācija ir pieejama tikai ar maksas abonementu."
override val backups_option: String = "Rezerves kopijas"
override val balance: String = "atlikums"
override val bank_account: String = "Bankas Konts"
override val bill_number: String = "Rēķina numurs"
override val cancel: String = "Atcelt"
override val cancel_trial: String = "Atcelt jebkurā laikā"
override val capital: String = "kapitāls"
override val cash: String = "skaidrā naudā"
override val categories: String = "Kategorijas"
override val category: String = "Kategorija"
override val category_add: String = "Pievienot kategoriju"
override val category_delete: String = "Dzēst kategoriju"
override val category_name: String = "Kategorijas nosaukums"
override val change_language: Formattable = Formattable { (language) -> "Rēķina valoda: ${language}" }
override val change_language_label: String = "Rēķina Valoda"
override val change_template: String = "Mainīt veidni"
override val city_hint: String = "Pilsēta"
override val clear: String = "Skaidrs"
override val client: String = "Klients"
override val client_actions: String = "Klienta darbības"
override val client_deleted_message: String = "Klients dzēsts"
override val clients_error_required_fields: String = "Nepieciešama vismaz viena vērtība."
override val clients_option: String = "Klienti"
override val close: String = "Aizvērt"
override val cloud_sync: String = "Mākoņa sinhronizācija"
override val coming_soon: String = "Drīzumā!"
override val company_logo: String = "Uzņēmuma logotips"
override val company_name_hint: String = "Uzņēmums"
override val confirm_password_label: String = "Paroles apstiprināšana"
override val confirmation_title: String = "Vai esi pārliecināts?"
override val convert_to_invoice: String = "Konvertēt uz rēķinu"
override val country_hint: String = "Valsts"
override val create_client: String = "Izveidot klientu"
override val create_company: String = "Izveidot uzņēmumu"
override val create_document: String = "Izveidot dokumentu"
override val create_estimate: String = "Aplēse"
override val create_invoice: String = "Rēķins"
override val create_item: String = "Izveidot vienumu"
override val create_new_client: String = "Izveidot jaunu klientu"
override val create_new_item: String = "Izveidot jaunu vienumu"
override val create_new_tax: String = "Izveidot jaunu nodokli"
override val create_signature: String = "Paraksts"
override val created: String = "Izveidots"
override val credit: String = "kredīt"
override val customer: String = "Klients"
override val date: String = "Datums"
override val debit: String = "debet"
override val debt: String = "parāds"
override val default_currency: Formattable = Formattable { (currency) -> "Noklusējuma valūta: ${currency}" }
override val default_currency_label: String = "Noklusējuma valūta"
override val delete: String = "Dzēst"
override val delete_account: String = "Dzēst Kontu"
override val delete_confirmation_message: String = "Visi jūsu dati tiks zaudēti."
override val delete_item_title: String = "Dzēst objektu?"
override val delete_signature: String = "Dzēst parakstu"
override val depreciation: String = "nolietojums"
override val description: String = "Apraksts"
override val description_hint: String = "Apraksts"
override val detailed_report: String = "Detalizēts Ziņojums"
override val disabled: String = "Invalīdi"
override val discount: String = "Atlaide"
override val discount_hint: String = "Atlaide"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amhāru valodā"
override val display_ar: String = "Arābu valodā"
override val display_as: String = "Asamēziešu"
override val display_az: String = "Azerbaidžānas"
override val display_be: String = "Baltkrievu"
override val display_bg: String = "Bulgāru"
override val display_bn: String = "Bengāļu"
override val display_bs: String = "Bosniešu"
override val display_ca: String = "Kataloniski"
override val display_cs: String = "Čehu"
override val display_da: String = "Dānijas"
override val display_de: String = "Vācu"
override val display_el: String = "Grieķu"
override val display_en: String = "Angļu valodā"
override val display_es: String = "Spāņu"
override val display_es_MX: String = "spāņu (Meksika)"
override val display_et: String = "Igauņu"
override val display_eu: String = "Basku"
override val display_fa: String = "Persiešu"
override val display_fi: String = "Somu"
override val display_fil: String = "Filipīniešu"
override val display_fr: String = "Franču"
override val display_fr_CA: String = "Kanādas franču valoda"
override val display_gl: String = "Galisijas"
override val display_gu: String = "Gudžarati"
override val display_he: String = "Ebreju valodā"
override val display_hi: String = "Hindi"
override val display_hr: String = "Horvātijas"
override val display_hu: String = "Ungāru"
override val display_hy: String = "Armēņu"
override val display_id: String = "Indonēzijas"
override val display_is: String = "Islandiešu"
override val display_it: String = "Itāļu"
override val display_ja: String = "Japāņu"
override val display_ka: String = "Gruzīnu"
override val display_kk: String = "Kazahstānas"
override val display_km: String = "Centrālā khmeru"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korejas"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Lietuviešu"
override val display_lv: String = "Latviešu"
override val display_mk: String = "Maķedonijas"
override val display_ml: String = "Malajāļu valodā"
override val display_mn: String = "Mongoļu"
override val display_mr: String = "Marati"
override val display_ms: String = "Malay"
override val display_my: String = "Birmas"
override val display_nb: String = "Norvēģu bokmål"
override val display_ne: String = "Nepālieši"
override val display_nl: String = "Holandiešu"
override val display_or: String = "Oriya"
override val display_pa: String = "Pendžabi"
override val display_pl: String = "Polijas"
override val display_pt: String = "Portugāļu"
override val display_pt_BR: String = "portugāļu (Brazīlija)"
override val display_pt_PT: String = "Eiropas portugāļu valoda"
override val display_ro: String = "Rumāņu"
override val display_ru: String = "Krievu"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovākijas"
override val display_sl: String = "Slovēņu"
override val display_sq: String = "Albāņu"
override val display_sr: String = "Serbijas"
override val display_sv: String = "Zviedru"
override val display_sw: String = "Svahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Taizemes"
override val display_tr: String = "Turcijas"
override val display_uk: String = "Ukraiņu"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbekistāna"
override val display_vi: String = "Vjetnamiešu"
override val display_zh_Hant_TW: String = "Ķīniešu (Taivāna, Tradicionālā)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Aplēse"
override val document_invoice: String = "Rēķins"
override val document_quote: String = "Piedāvājums"
override val done: String = "Paveikts"
override val download_app: String = "Lejupielādēt lietotni"
override val download_invoice: String = "Lejupielādēt Rēķinu"
override val download_pdf: String = "Lejupielādēt PDF"
override val draw_signature: String = "Zīmēt parakstu"
override val due: Formattable = Formattable { (date) -> "Maksāšanas termiņš ${date}" }
override val due_date: String = "Termiņš"
override val due_day: String = "dienā"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} termiņš ir ${dueWhen}!" }
override val due_today: String = "šodien"
override val due_week: String = "nedēļas laikā"
override val duplicate: String = "Dublikāts"
override val duration_day: String = "diena"
override val duration_month: String = "mēnesis"
override val duration_week: String = "nedēļa"
override val duration_year: String = "gads"
override val email: String = "E-pasts"
override val email_blank_error: String = "Nepieciešamais e-pasta adrese."
override val email_error: String = "Nederīga e-pasta adrese."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Paldies par jūsu biznesu. Lai skatītu visu ${documentType}, noklikšķiniet uz pogas šīs vēstules beigās. Ņemiet vērā, ka tas ir derīgs līdz ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Jūs esat saņēmis jaunu ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "RĒĶINA ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Cienījamie ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Paldies par jūsu biznesu. Lai skatītu visu ${documentType}, noklikšķiniet uz pogas šīs vēstules beigās. Lūdzu, samaksājiet atlikumu līdz ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Jūs esat saņēmis jaunu ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Nosūtīts e-pasta vēstule paroles atiestatīšanai, lūdzu, pārbaudiet savu iesūtni."
override val empty_clients_list_message: String = "Jums nav jāseko līdzi klientiem. Pievienojiet jaunu klientu."
override val empty_estimate_list_message: String = "Izveidojiet pirmo aplēsi"
override val empty_expenses: String = "Jums nav jāseko līdzi izdevumiem. Pievienojiet jaunus izdevumus."
override val empty_invoice_list_message: String = "Izveidojiet pirmo rēķinu"
override val empty_items_list_message: String = "Jums nav nevienas preces, pievienojiet jaunu preci!"
override val empty_signatures_list_message: String = "Paraksti nav atrasti. Izveidojiet vienu!"
override val empty_taxes_list_message: String = "Jums nav nodokļu noteikumu. Pievienojiet jaunu nodokļu noteikumu."
override val empty_templates_list_message: String = "Šabloni nav pieejami."
override val enabled: String = "Iespējots"
override val entry: String = "ieraksts"
override val equity: String = "pašu kapitāls"
override val estimate_number: String = "Aplēse #"
override val estimates: String = "Aplēses"
override val estimates_report: String = "Aplēses"
override val expense_bank_fees: String = "Bankas komisijas maksas"
override val expense_benefits: String = "Ieguvumi"
override val expense_communication: String = "Saziņa"
override val expense_debt: String = "Parāds"
override val expense_deleted: String = "Dzēsti izdevumi"
override val expense_depreciation: String = "Amortizācija"
override val expense_gifts: String = "Dāvanas"
override val expense_insurance: String = "Apdrošināšana"
override val expense_inventory: String = "Inventārs"
override val expense_legal: String = "Juridiskais"
override val expense_maintenance: String = "Uzturēšana"
override val expense_marketing: String = "Mārketings"
override val expense_meals: String = "Ēdieni"
override val expense_office_supplies: String = "Biroja piederumi"
override val expense_rent: String = "Noma"
override val expense_salaries: String = "Algas"
override val expense_shopping: String = "Iepirkšanās"
override val expense_software: String = "Programmatūra"
override val expense_status_available: String = "Pieejams"
override val expense_status_processing: String = "Apstrāde"
override val expense_tax: String = "Nodokļi"
override val expense_training: String = "Apmācība"
override val expense_transport: String = "Transports"
override val expense_travel: String = "Ceļojumi"
override val expense_utility: String = "Komunālie pakalpojumi"
override val expenses: String = "Izdevumi"
override val feedback_option: String = "Atsauksmes"
override val filter: String = "Filtrs"
override val first_name_hint: String = "Vārds"
override val forgot_password: String = "Paroles atiestatīšana"
override val forgot_password_label: String = "Aizmirsāt paroli"
override val from: String = "No"
override val general_error: String = "Radās kļūda, lūdzu, mēģiniet vēlreiz vēlāk."
override val general_ledger: String = "galvenā virsgrāmata"
override val go_back_label: String = "Atgriezties"
override val group_by: String = "Grupas autors:"
override val image_view: String = "Attēls"
override val install: String = "Instalēt"
override val invalid_credentials_error: String = "Nederīgi akreditācijas dati."
override val invalid_email_error: String = "Nederīgs e-pasta adrese."
override val invalid_user_error: String = "Nederīgi lietotāja akreditācijas dati."
override val invoice_deleted_message: String = "Dzēsts rēķins"
override val invoice_menu: String = "Rēķina izvēlne"
override val invoice_number: String = "Rēķins #"
override val invoices: String = "Rēķini"
override val items_option: String = "Preces"
override val keep: String = "Saglabājiet"
override val language_selector: Formattable = Formattable { (selected) -> "Valoda ${selected}" }
override val last_name_hint: String = "Uzvārds"
override val ledger: String = "virsgrāmata"
override val legal: String = "Juridiskais"
override val legal_error: String = "Jāakceptē pakalpojumu sniegšanas noteikumi un konfidencialitātes politika"
override val liabilities: String = "saistības"
override val loading: String = "Iekraušana"
override val login_required_error: String = "Nepieciešams pieteikties."
override val logo_view: String = "Logotips"
override val logout: String = "Izrakstīšanās"
override val logout_prompt: String = "Jūsu dati netiek sinhronizēti ar mākoni. Dzēst rēķinus, tāmes, klientus un citu informāciju pēc izrakstīšanās?"
override val main_screen: String = "Galvenais ekrāns"
override val mark_paid: String = "Atzīmēt kā apmaksātu"
override val message: String = "Ziņa"
override val missing_sender_msg: String = "Dokuments bez sūtītāja informācijas var būt nederīgs."
override val missing_sender_title: String = "Trūkst sūtītāja informācijas"
override val month_april: String = "Aprīlis"
override val month_april_short: String = "Aprīlis"
override val month_august: String = "Augusts"
override val month_august_short: String = "Aug."
override val month_december: String = "Decembris"
override val month_december_short: String = "Dec."
override val month_february: String = "Februāris"
override val month_february_short: String = "Febr."
override val month_january: String = "Janvāris"
override val month_january_short: String = "Janv."
override val month_july: String = "Jūlijs"
override val month_july_short: String = "Jūlijs"
override val month_june: String = "Jūnijs"
override val month_june_short: String = "Jūnijs"
override val month_march: String = "Marts"
override val month_march_short: String = "Marts"
override val month_may: String = "Maijs"
override val month_may_short: String = "Maijs"
override val month_november: String = "Novembris"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktobris"
override val month_october_short: String = "Okt."
override val month_september: String = "Septembris"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Neizdevās pārbaudīt."
override val new: String = "Jauns"
override val news: String = "Ziņas"
override val next: String = "Nākamais"
override val no: String = "nē"
override val no_data_available: String = "Dati nav pieejami"
override val no_search_results: String = "Atvainojiet, mēs nevarējām atrast nevienu rezultātu."
override val none: String = "Nav"
override val note_fields: String = "Piezīmes"
override val note_hint: String = "Piezīme"
override val notifications_option: String = "Paziņojumi"
override val ok: String = "LABI"
override val onboarding: String = "Uzņemšana"
override val onboarding_aboutus_message: String = "Būtiskas DingVoice daļas ir mūžīgi bezmaksas. Mūsu misija ir palīdzēt jums attīstīt jūsu uzņēmumu, palīdziet mums to uzlabot, daloties ar savām atsauksmēm."
override val onboarding_aboutus_title: String = "Mēs esam šeit, lai palīdzētu"
override val onboarding_paid_1: String = "Piekļūstiet profesionāla izskata veidnēm"
override val onboarding_paid_2: String = "Padziļināti izpētiet savus finanšu datus"
override val onboarding_paid_3: String = "Ātri atrisiniet problēmas"
override val onboarding_paid_4: String = "Saglabājiet dokumentus drošībā"
override val onboarding_paid_5: String = "Informējiet par rēķina statusa izmaiņām"
override val onboarding_paid_title: String = "Augiet vēl ātrāk ar..."
override val onboarding_paywall_message: String = "Izbaudiet visas augstākās klases funkcijas par labāko cenu."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Tikai ${currencySymbol}${introAmount} par ${introDuration} ${introPeriod} un pēc tam ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Tikai ${currencySymbol}${amount}/${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Bezmaksas ${trialDuration} ${trialPeriod} un pēc tam tikai ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Bezmaksas par ${trialDuration} ${trialPeriod}, pēc tam ${currencySymbol}${introductoryAmount} par ${introductoryDuration} ${introPeriod} un pēc tam tikai ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automātiski atjaunojams. Atcelt jebkurā laikā."
override val onboarding_paywall_restore: String = "Pirkuma atjaunošana"
override val onboarding_paywall_restore_failure: String = "Pirkumu atjaunošana nav izdevusies, mēģiniet vēlreiz vēlāk vai sazinieties ar atbalsta dienestu."
override val onboarding_paywall_restore_success: String = "Veiksmīga atjaunošana!"
override val onboarding_paywall_title: String = "Sasniedziet diženumu!"
override val onboarding_welcome_message: String = "Pilnīgs rīku komplekts rēķinu un tāmju pārvaldīšanai."
override val onboarding_welcome_title: String = "Laipni lūdzam DingVoice"
override val other_state: String = "Cits"
override val overdue_state: String = "Nokavēts"
override val paid_state: String = "Apmaksāts"
override val password: String = "Parole"
override val password_blank_error: String = "Nepieciešamā parole."
override val password_mismatch_error: String = "Paroles nesakrīt."
override val pay: String = "maksāt"
override val payment_instructions_label: String = "Maksājuma instrukcijas"
override val payment_reminders: String = "Maksājumu atgādinājumi"
override val phone_number_fields: String = "Tālruņa numurs"
override val phone_number_hint: String = "Tālruņa numurs"
override val postcode_hint: String = "Pasta indekss"
override val preview: String = "Priekšskatījums"
override val preview_menu_hint: String = "Priekšskatījuma izvēlne"
override val price: String = "Cena"
override val privacy_policy: String = "Konfidencialitātes politika"
override val profile: String = "Profils"
override val profitable_client: String = "Jūsu ienesīgākais klients ir..."
override val profits_report: String = "Peļņa"
override val promo_content: String = "Reklāmas saturs"
override val quantity_hint: String = "Daudzums"
override val quantity_label_hint: String = "Daudzuma etiķete"
override val query_info: String = "Pieprasījums ļauj rakstīt pielāgotus aprēķinus, kuros kā ievades datus izmanto gan vienumus, gan citus nodokļu noteikumus. Pievienojiet @ simbolu un preces vai nodokļa aprakstu, lai izveidotu saiti uz citu ierakstu +, -, *, /, % - matemātiskie operatori"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ir saņemts no jūsu klienta." }
override val received_state: String = "Saņemts"
override val registration_code_fields: String = "Reģistrācijas Kods"
override val reminder: String = "Atgādinājums"
override val reports: String = "Ziņojumi"
override val reports_not_available: String = "Ziņojumi nav pieejami, atgriezieties vēlāk."
override val reveal_password: String = "Atklāt paroli"
override val revenue: String = "ieņēmumi"
override val sales_tax: String = "PVN"
override val sales_tax_number: String = "PVN numurs"
override val save: String = "Saglabāt"
override val scan_document: String = "Dokumenta skenēšana"
override val search: String = "Meklēšana"
override val select: String = "Atlasiet"
override val select_icon: String = "Izvēlieties ikonu"
override val select_signature: String = "Izvēlieties parakstu"
override val select_template: String = "Atlasiet veidni"
override val send: String = "Sūtīt"
override val sent_state: String = "Nosūtīts"
override val setup_sender: String = "Iestatīt sūtītāju"
override val share_option: String = "Dalīties"
override val ship_to: String = "Nosūtiet uz"
override val sign_in: String = "Pierakstīties"
override val sign_in_apple: String = "Pierakstīties, izmantojot Apple"
override val sign_in_email: String = "Pierakstīties, izmantojot e-pastu"
override val sign_in_facebook: String = "Pierakstīties, izmantojot Facebook"
override val sign_in_google: String = "Pierakstīties, izmantojot Google"
override val sign_up: String = "Reģistrējieties"
override val sign_up_label: String = "Jums nav konta?"
override val sign_up_link_label: String = "Reģistrējieties!"
override val signatures_option: String = "Paraksti"
override val skip: String = "Izlaist"
override val sold_items_report: String = "Pārdotās Preces"
override val sort_by: String = "Atlasīt pēc:"
override val status_update: String = "Dokumenta statusa atjaunināšana"
override val sub_annual: String = "Ikgadējais"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/gadā" }
override val sub_backups: String = "Mākoņa rezerves kopijas"
override val sub_insights: String = "Ieskats"
override val sub_month: String = "Ikmēneša"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mēnesī" }
override val sub_notifications: String = "Paziņojumi"
override val sub_popup_message: String = "Abonēšanas drīzumā. Pārbaudiet šeit pēc brīža."
override val sub_support: String = "Atbalsts"
override val sub_templates: String = "Veidnes"
override val subscribe: String = "Abonēt"
override val subscription_header: String = "Paplašiniet savu biznesu ar:"
override val subscription_option: String = "Abonements"
override val subscriptions_load_fail: String = "Atvainojiet, šobrīd mēs nevaram atgūt jūsu abonementus. Lūdzu, mēģiniet vēlāk vai sazinieties ar mūsu atbalsta komandu, lai saņemtu palīdzību."
override val subtotal: Formattable = Formattable { (sum) -> "Starpsumma: ${sum}" }
override val subtotal_label: String = "Starpsumma"
override val sum: String = "Summa"
override val support_option: String = "Atbalsts"
override val switch_reports: String = "Pārslēgt Ziņojumu"
override val tax: String = "Nodokļi"
override val tax_deleted_message: String = "Izdzēsts nodoklis"
override val tax_description_warning: String = "Ja tas tiek izmantots, tas parādās rēķinā."
override val tax_input_flat: String = "Plakana"
override val tax_input_percent: String = "Procenti"
override val tax_number_fields: String = "Nodokļu maksātāja numurs"
override val tax_number_hint: String = "Nodokļu maksātāja numurs"
override val tax_query_info_description: String = "Pieprasījuma informācija"
override val tax_report: String = "Nodokļi"
override val tax_rules_option: String = "Nodokļu noteikumi"
override val taxed_clients_report: String = "Klienti, kam uzlikti nodokļi"
override val template: String = "Šablons"
override val template_selector_info: String = "Pārvelciet, lai mainītu noklusējuma veidni"
override val template_unlock_promo: String = "Piekļūstiet vairāk veidnēm, izmantojot Premium"
override val templates_option: String = "Šabloni"
override val terms_of_service: String = "Pakalpojumu sniegšanas noteikumi"
override val thank_you: String = "Paldies"
override val to: String = "Uz"
override val total: Formattable = Formattable { (summa) -> "Kopā: ${summa}" }
override val total_label: String = "Kopā"
override val undo_action: String = "Atcelt"
override val unit: String = "Vienība"
override val unit_cost_hint: String = "Vienības izmaksas"
override val unknown_client: String = "Nezināms Klients"
override val unlock: String = "Atbloķēt"
override val unnamed_item: String = "Vienums bez nosaukuma"
override val upgrade: String = "Atjaunināšana"
override val user_collision_error: String = "Konts jau pastāv."
override val valid_until: String = "Derīgs līdz"
override val warning: String = "Brīdinājums!"
override val yes: String = "jā"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Pieņemt ${termsOfService} un ${privacyPolicy}" },
	"account" to "konts",
	"accounting" to "grāmatvedība",
	"accruals" to "uzkrājumi",
	"action_code_error" to "Nederīgs kods.",
	"add_client_action" to "Pievienot klientu",
	"add_customer" to "Pievienot klientu",
	"add_expense" to "Pievienot izdevumus",
	"add_fields_label" to "Pievienot:",
	"add_invoice_action" to "Izveidot",
	"add_item_action" to "Pievienot vienību",
	"add_item_button" to "Pievienot objektu",
	"add_signature_action" to "Pievienot parakstu",
	"add_tax_action" to "Pievienot nodokli",
	"address_fields" to "Adrese",
	"address_line_hint" to "Adreses līnija",
	"advanced" to "Advanced",
	"agree_marketing" to "Es piekrītu saņemt tiešā mārketinga paziņojumus",
	"all_notifications" to "Visi paziņojumi",
	"amortization" to "amortizācija",
	"amount" to "Summa",
	"asset" to "aktīvi",
	"attach_file" to "Pievienot failu",
	"attachment" to "Pielikums",
	"auth_error" to "Darbība neizdevās, mēģiniet vēlreiz.",
	"available_for_paid" to "Mākoņa sinhronizācija ir pieejama tikai ar maksas abonementu.",
	"backups_option" to "Rezerves kopijas",
	"balance" to "atlikums",
	"bank_account" to "Bankas Konts",
	"bill_number" to "Rēķina numurs",
	"cancel" to "Atcelt",
	"cancel_trial" to "Atcelt jebkurā laikā",
	"capital" to "kapitāls",
	"cash" to "skaidrā naudā",
	"categories" to "Kategorijas",
	"category" to "Kategorija",
	"category_add" to "Pievienot kategoriju",
	"category_delete" to "Dzēst kategoriju",
	"category_name" to "Kategorijas nosaukums",
	"change_language" to Formattable { (language) -> "Rēķina valoda: ${language}" },
	"change_language_label" to "Rēķina Valoda",
	"change_template" to "Mainīt veidni",
	"city_hint" to "Pilsēta",
	"clear" to "Skaidrs",
	"client" to "Klients",
	"client_actions" to "Klienta darbības",
	"client_deleted_message" to "Klients dzēsts",
	"clients_error_required_fields" to "Nepieciešama vismaz viena vērtība.",
	"clients_option" to "Klienti",
	"close" to "Aizvērt",
	"cloud_sync" to "Mākoņa sinhronizācija",
	"coming_soon" to "Drīzumā!",
	"company_logo" to "Uzņēmuma logotips",
	"company_name_hint" to "Uzņēmums",
	"confirm_password_label" to "Paroles apstiprināšana",
	"confirmation_title" to "Vai esi pārliecināts?",
	"convert_to_invoice" to "Konvertēt uz rēķinu",
	"country_hint" to "Valsts",
	"create_client" to "Izveidot klientu",
	"create_company" to "Izveidot uzņēmumu",
	"create_document" to "Izveidot dokumentu",
	"create_estimate" to "Aplēse",
	"create_invoice" to "Rēķins",
	"create_item" to "Izveidot vienumu",
	"create_new_client" to "Izveidot jaunu klientu",
	"create_new_item" to "Izveidot jaunu vienumu",
	"create_new_tax" to "Izveidot jaunu nodokli",
	"create_signature" to "Paraksts",
	"created" to "Izveidots",
	"credit" to "kredīt",
	"customer" to "Klients",
	"date" to "Datums",
	"debit" to "debet",
	"debt" to "parāds",
	"default_currency" to Formattable { (currency) -> "Noklusējuma valūta: ${currency}" },
	"default_currency_label" to "Noklusējuma valūta",
	"delete" to "Dzēst",
	"delete_account" to "Dzēst Kontu",
	"delete_confirmation_message" to "Visi jūsu dati tiks zaudēti.",
	"delete_item_title" to "Dzēst objektu?",
	"delete_signature" to "Dzēst parakstu",
	"depreciation" to "nolietojums",
	"description" to "Apraksts",
	"description_hint" to "Apraksts",
	"detailed_report" to "Detalizēts Ziņojums",
	"disabled" to "Invalīdi",
	"discount" to "Atlaide",
	"discount_hint" to "Atlaide",
	"display_af" to "Afrikaans",
	"display_am" to "Amhāru valodā",
	"display_ar" to "Arābu valodā",
	"display_as" to "Asamēziešu",
	"display_az" to "Azerbaidžānas",
	"display_be" to "Baltkrievu",
	"display_bg" to "Bulgāru",
	"display_bn" to "Bengāļu",
	"display_bs" to "Bosniešu",
	"display_ca" to "Kataloniski",
	"display_cs" to "Čehu",
	"display_da" to "Dānijas",
	"display_de" to "Vācu",
	"display_el" to "Grieķu",
	"display_en" to "Angļu valodā",
	"display_es" to "Spāņu",
	"display_es_MX" to "spāņu (Meksika)",
	"display_et" to "Igauņu",
	"display_eu" to "Basku",
	"display_fa" to "Persiešu",
	"display_fi" to "Somu",
	"display_fil" to "Filipīniešu",
	"display_fr" to "Franču",
	"display_fr_CA" to "Kanādas franču valoda",
	"display_gl" to "Galisijas",
	"display_gu" to "Gudžarati",
	"display_he" to "Ebreju valodā",
	"display_hi" to "Hindi",
	"display_hr" to "Horvātijas",
	"display_hu" to "Ungāru",
	"display_hy" to "Armēņu",
	"display_id" to "Indonēzijas",
	"display_is" to "Islandiešu",
	"display_it" to "Itāļu",
	"display_ja" to "Japāņu",
	"display_ka" to "Gruzīnu",
	"display_kk" to "Kazahstānas",
	"display_km" to "Centrālā khmeru",
	"display_kn" to "Kannada",
	"display_ko" to "Korejas",
	"display_ky" to "Kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Lietuviešu",
	"display_lv" to "Latviešu",
	"display_mk" to "Maķedonijas",
	"display_ml" to "Malajāļu valodā",
	"display_mn" to "Mongoļu",
	"display_mr" to "Marati",
	"display_ms" to "Malay",
	"display_my" to "Birmas",
	"display_nb" to "Norvēģu bokmål",
	"display_ne" to "Nepālieši",
	"display_nl" to "Holandiešu",
	"display_or" to "Oriya",
	"display_pa" to "Pendžabi",
	"display_pl" to "Polijas",
	"display_pt" to "Portugāļu",
	"display_pt_BR" to "portugāļu (Brazīlija)",
	"display_pt_PT" to "Eiropas portugāļu valoda",
	"display_ro" to "Rumāņu",
	"display_ru" to "Krievu",
	"display_si" to "Sinhala",
	"display_sk" to "Slovākijas",
	"display_sl" to "Slovēņu",
	"display_sq" to "Albāņu",
	"display_sr" to "Serbijas",
	"display_sv" to "Zviedru",
	"display_sw" to "Svahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Taizemes",
	"display_tr" to "Turcijas",
	"display_uk" to "Ukraiņu",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbekistāna",
	"display_vi" to "Vjetnamiešu",
	"display_zh_Hant_TW" to "Ķīniešu (Taivāna, Tradicionālā)",
	"display_zu" to "Zulu",
	"document_estimate" to "Aplēse",
	"document_invoice" to "Rēķins",
	"document_quote" to "Piedāvājums",
	"done" to "Paveikts",
	"download_app" to "Lejupielādēt lietotni",
	"download_invoice" to "Lejupielādēt Rēķinu",
	"download_pdf" to "Lejupielādēt PDF",
	"draw_signature" to "Zīmēt parakstu",
	"due" to Formattable { (date) -> "Maksāšanas termiņš ${date}" },
	"due_date" to "Termiņš",
	"due_day" to "dienā",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} termiņš ir ${dueWhen}!" },
	"due_today" to "šodien",
	"due_week" to "nedēļas laikā",
	"duplicate" to "Dublikāts",
	"duration_day" to "diena",
	"duration_month" to "mēnesis",
	"duration_week" to "nedēļa",
	"duration_year" to "gads",
	"email" to "E-pasts",
	"email_blank_error" to "Nepieciešamais e-pasta adrese.",
	"email_error" to "Nederīga e-pasta adrese.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Paldies par jūsu biznesu. Lai skatītu visu ${documentType}, noklikšķiniet uz pogas šīs vēstules beigās. Ņemiet vērā, ka tas ir derīgs līdz ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Jūs esat saņēmis jaunu ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "RĒĶINA ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Cienījamie ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Paldies par jūsu biznesu. Lai skatītu visu ${documentType}, noklikšķiniet uz pogas šīs vēstules beigās. Lūdzu, samaksājiet atlikumu līdz ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Jūs esat saņēmis jaunu ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Nosūtīts e-pasta vēstule paroles atiestatīšanai, lūdzu, pārbaudiet savu iesūtni.",
	"empty_clients_list_message" to "Jums nav jāseko līdzi klientiem. Pievienojiet jaunu klientu.",
	"empty_estimate_list_message" to "Izveidojiet pirmo aplēsi",
	"empty_expenses" to "Jums nav jāseko līdzi izdevumiem. Pievienojiet jaunus izdevumus.",
	"empty_invoice_list_message" to "Izveidojiet pirmo rēķinu",
	"empty_items_list_message" to "Jums nav nevienas preces, pievienojiet jaunu preci!",
	"empty_signatures_list_message" to "Paraksti nav atrasti. Izveidojiet vienu!",
	"empty_taxes_list_message" to "Jums nav nodokļu noteikumu. Pievienojiet jaunu nodokļu noteikumu.",
	"empty_templates_list_message" to "Šabloni nav pieejami.",
	"enabled" to "Iespējots",
	"entry" to "ieraksts",
	"equity" to "pašu kapitāls",
	"estimate_number" to "Aplēse #",
	"estimates" to "Aplēses",
	"estimates_report" to "Aplēses",
	"expense_bank_fees" to "Bankas komisijas maksas",
	"expense_benefits" to "Ieguvumi",
	"expense_communication" to "Saziņa",
	"expense_debt" to "Parāds",
	"expense_deleted" to "Dzēsti izdevumi",
	"expense_depreciation" to "Amortizācija",
	"expense_gifts" to "Dāvanas",
	"expense_insurance" to "Apdrošināšana",
	"expense_inventory" to "Inventārs",
	"expense_legal" to "Juridiskais",
	"expense_maintenance" to "Uzturēšana",
	"expense_marketing" to "Mārketings",
	"expense_meals" to "Ēdieni",
	"expense_office_supplies" to "Biroja piederumi",
	"expense_rent" to "Noma",
	"expense_salaries" to "Algas",
	"expense_shopping" to "Iepirkšanās",
	"expense_software" to "Programmatūra",
	"expense_status_available" to "Pieejams",
	"expense_status_processing" to "Apstrāde",
	"expense_tax" to "Nodokļi",
	"expense_training" to "Apmācība",
	"expense_transport" to "Transports",
	"expense_travel" to "Ceļojumi",
	"expense_utility" to "Komunālie pakalpojumi",
	"expenses" to "Izdevumi",
	"feedback_option" to "Atsauksmes",
	"filter" to "Filtrs",
	"first_name_hint" to "Vārds",
	"forgot_password" to "Paroles atiestatīšana",
	"forgot_password_label" to "Aizmirsāt paroli",
	"from" to "No",
	"general_error" to "Radās kļūda, lūdzu, mēģiniet vēlreiz vēlāk.",
	"general_ledger" to "galvenā virsgrāmata",
	"go_back_label" to "Atgriezties",
	"group_by" to "Grupas autors:",
	"image_view" to "Attēls",
	"install" to "Instalēt",
	"invalid_credentials_error" to "Nederīgi akreditācijas dati.",
	"invalid_email_error" to "Nederīgs e-pasta adrese.",
	"invalid_user_error" to "Nederīgi lietotāja akreditācijas dati.",
	"invoice_deleted_message" to "Dzēsts rēķins",
	"invoice_menu" to "Rēķina izvēlne",
	"invoice_number" to "Rēķins #",
	"invoices" to "Rēķini",
	"items_option" to "Preces",
	"keep" to "Saglabājiet",
	"language_selector" to Formattable { (selected) -> "Valoda ${selected}" },
	"last_name_hint" to "Uzvārds",
	"ledger" to "virsgrāmata",
	"legal" to "Juridiskais",
	"legal_error" to "Jāakceptē pakalpojumu sniegšanas noteikumi un konfidencialitātes politika",
	"liabilities" to "saistības",
	"loading" to "Iekraušana",
	"login_required_error" to "Nepieciešams pieteikties.",
	"logo_view" to "Logotips",
	"logout" to "Izrakstīšanās",
	"logout_prompt" to "Jūsu dati netiek sinhronizēti ar mākoni. Dzēst rēķinus, tāmes, klientus un citu informāciju pēc izrakstīšanās?",
	"main_screen" to "Galvenais ekrāns",
	"mark_paid" to "Atzīmēt kā apmaksātu",
	"message" to "Ziņa",
	"missing_sender_msg" to "Dokuments bez sūtītāja informācijas var būt nederīgs.",
	"missing_sender_title" to "Trūkst sūtītāja informācijas",
	"month_april" to "Aprīlis",
	"month_april_short" to "Aprīlis",
	"month_august" to "Augusts",
	"month_august_short" to "Aug.",
	"month_december" to "Decembris",
	"month_december_short" to "Dec.",
	"month_february" to "Februāris",
	"month_february_short" to "Febr.",
	"month_january" to "Janvāris",
	"month_january_short" to "Janv.",
	"month_july" to "Jūlijs",
	"month_july_short" to "Jūlijs",
	"month_june" to "Jūnijs",
	"month_june_short" to "Jūnijs",
	"month_march" to "Marts",
	"month_march_short" to "Marts",
	"month_may" to "Maijs",
	"month_may_short" to "Maijs",
	"month_november" to "Novembris",
	"month_november_short" to "Nov.",
	"month_october" to "Oktobris",
	"month_october_short" to "Okt.",
	"month_september" to "Septembris",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Neizdevās pārbaudīt.",
	"new" to "Jauns",
	"news" to "Ziņas",
	"next" to "Nākamais",
	"no" to "nē",
	"no_data_available" to "Dati nav pieejami",
	"no_search_results" to "Atvainojiet, mēs nevarējām atrast nevienu rezultātu.",
	"none" to "Nav",
	"note_fields" to "Piezīmes",
	"note_hint" to "Piezīme",
	"notifications_option" to "Paziņojumi",
	"ok" to "LABI",
	"onboarding" to "Uzņemšana",
	"onboarding_aboutus_message" to "Būtiskas DingVoice daļas ir mūžīgi bezmaksas. Mūsu misija ir palīdzēt jums attīstīt jūsu uzņēmumu, palīdziet mums to uzlabot, daloties ar savām atsauksmēm.",
	"onboarding_aboutus_title" to "Mēs esam šeit, lai palīdzētu",
	"onboarding_paid_1" to "Piekļūstiet profesionāla izskata veidnēm",
	"onboarding_paid_2" to "Padziļināti izpētiet savus finanšu datus",
	"onboarding_paid_3" to "Ātri atrisiniet problēmas",
	"onboarding_paid_4" to "Saglabājiet dokumentus drošībā",
	"onboarding_paid_5" to "Informējiet par rēķina statusa izmaiņām",
	"onboarding_paid_title" to "Augiet vēl ātrāk ar...",
	"onboarding_paywall_message" to "Izbaudiet visas augstākās klases funkcijas par labāko cenu.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Tikai ${currencySymbol}${introAmount} par ${introDuration} ${introPeriod} un pēc tam ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Tikai ${currencySymbol}${amount}/${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Bezmaksas ${trialDuration} ${trialPeriod} un pēc tam tikai ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Bezmaksas par ${trialDuration} ${trialPeriod}, pēc tam ${currencySymbol}${introductoryAmount} par ${introductoryDuration} ${introPeriod} un pēc tam tikai ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automātiski atjaunojams. Atcelt jebkurā laikā.",
	"onboarding_paywall_restore" to "Pirkuma atjaunošana",
	"onboarding_paywall_restore_failure" to "Pirkumu atjaunošana nav izdevusies, mēģiniet vēlreiz vēlāk vai sazinieties ar atbalsta dienestu.",
	"onboarding_paywall_restore_success" to "Veiksmīga atjaunošana!",
	"onboarding_paywall_title" to "Sasniedziet diženumu!",
	"onboarding_welcome_message" to "Pilnīgs rīku komplekts rēķinu un tāmju pārvaldīšanai.",
	"onboarding_welcome_title" to "Laipni lūdzam DingVoice",
	"other_state" to "Cits",
	"overdue_state" to "Nokavēts",
	"paid_state" to "Apmaksāts",
	"password" to "Parole",
	"password_blank_error" to "Nepieciešamā parole.",
	"password_mismatch_error" to "Paroles nesakrīt.",
	"pay" to "maksāt",
	"payment_instructions_label" to "Maksājuma instrukcijas",
	"payment_reminders" to "Maksājumu atgādinājumi",
	"phone_number_fields" to "Tālruņa numurs",
	"phone_number_hint" to "Tālruņa numurs",
	"postcode_hint" to "Pasta indekss",
	"preview" to "Priekšskatījums",
	"preview_menu_hint" to "Priekšskatījuma izvēlne",
	"price" to "Cena",
	"privacy_policy" to "Konfidencialitātes politika",
	"profile" to "Profils",
	"profitable_client" to "Jūsu ienesīgākais klients ir...",
	"profits_report" to "Peļņa",
	"promo_content" to "Reklāmas saturs",
	"quantity_hint" to "Daudzums",
	"quantity_label_hint" to "Daudzuma etiķete",
	"query_info" to "Pieprasījums ļauj rakstīt pielāgotus aprēķinus, kuros kā ievades datus izmanto gan vienumus, gan citus nodokļu noteikumus. Pievienojiet @ simbolu un preces vai nodokļa aprakstu, lai izveidotu saiti uz citu ierakstu +, -, *, /, % - matemātiskie operatori",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ir saņemts no jūsu klienta." },
	"received_state" to "Saņemts",
	"registration_code_fields" to "Reģistrācijas Kods",
	"reminder" to "Atgādinājums",
	"reports" to "Ziņojumi",
	"reports_not_available" to "Ziņojumi nav pieejami, atgriezieties vēlāk.",
	"reveal_password" to "Atklāt paroli",
	"revenue" to "ieņēmumi",
	"sales_tax" to "PVN",
	"sales_tax_number" to "PVN numurs",
	"save" to "Saglabāt",
	"scan_document" to "Dokumenta skenēšana",
	"search" to "Meklēšana",
	"select" to "Atlasiet",
	"select_icon" to "Izvēlieties ikonu",
	"select_signature" to "Izvēlieties parakstu",
	"select_template" to "Atlasiet veidni",
	"send" to "Sūtīt",
	"sent_state" to "Nosūtīts",
	"setup_sender" to "Iestatīt sūtītāju",
	"share_option" to "Dalīties",
	"ship_to" to "Nosūtiet uz",
	"sign_in" to "Pierakstīties",
	"sign_in_apple" to "Pierakstīties, izmantojot Apple",
	"sign_in_email" to "Pierakstīties, izmantojot e-pastu",
	"sign_in_facebook" to "Pierakstīties, izmantojot Facebook",
	"sign_in_google" to "Pierakstīties, izmantojot Google",
	"sign_up" to "Reģistrējieties",
	"sign_up_label" to "Jums nav konta?",
	"sign_up_link_label" to "Reģistrējieties!",
	"signatures_option" to "Paraksti",
	"skip" to "Izlaist",
	"sold_items_report" to "Pārdotās Preces",
	"sort_by" to "Atlasīt pēc:",
	"status_update" to "Dokumenta statusa atjaunināšana",
	"sub_annual" to "Ikgadējais",
	"sub_annual_price" to Formattable { (price) -> "${price}/gadā" },
	"sub_backups" to "Mākoņa rezerves kopijas",
	"sub_insights" to "Ieskats",
	"sub_month" to "Ikmēneša",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mēnesī" },
	"sub_notifications" to "Paziņojumi",
	"sub_popup_message" to "Abonēšanas drīzumā. Pārbaudiet šeit pēc brīža.",
	"sub_support" to "Atbalsts",
	"sub_templates" to "Veidnes",
	"subscribe" to "Abonēt",
	"subscription_header" to "Paplašiniet savu biznesu ar:",
	"subscription_option" to "Abonements",
	"subscriptions_load_fail" to "Atvainojiet, šobrīd mēs nevaram atgūt jūsu abonementus. Lūdzu, mēģiniet vēlāk vai sazinieties ar mūsu atbalsta komandu, lai saņemtu palīdzību.",
	"subtotal" to Formattable { (sum) -> "Starpsumma: ${sum}" },
	"subtotal_label" to "Starpsumma",
	"sum" to "Summa",
	"support_option" to "Atbalsts",
	"switch_reports" to "Pārslēgt Ziņojumu",
	"tax" to "Nodokļi",
	"tax_deleted_message" to "Izdzēsts nodoklis",
	"tax_description_warning" to "Ja tas tiek izmantots, tas parādās rēķinā.",
	"tax_input_flat" to "Plakana",
	"tax_input_percent" to "Procenti",
	"tax_number_fields" to "Nodokļu maksātāja numurs",
	"tax_number_hint" to "Nodokļu maksātāja numurs",
	"tax_query_info_description" to "Pieprasījuma informācija",
	"tax_report" to "Nodokļi",
	"tax_rules_option" to "Nodokļu noteikumi",
	"taxed_clients_report" to "Klienti, kam uzlikti nodokļi",
	"template" to "Šablons",
	"template_selector_info" to "Pārvelciet, lai mainītu noklusējuma veidni",
	"template_unlock_promo" to "Piekļūstiet vairāk veidnēm, izmantojot Premium",
	"templates_option" to "Šabloni",
	"terms_of_service" to "Pakalpojumu sniegšanas noteikumi",
	"thank_you" to "Paldies",
	"to" to "Uz",
	"total" to Formattable { (summa) -> "Kopā: ${summa}" },
	"total_label" to "Kopā",
	"undo_action" to "Atcelt",
	"unit" to "Vienība",
	"unit_cost_hint" to "Vienības izmaksas",
	"unknown_client" to "Nezināms Klients",
	"unlock" to "Atbloķēt",
	"unnamed_item" to "Vienums bez nosaukuma",
	"upgrade" to "Atjaunināšana",
	"user_collision_error" to "Konts jau pastāv.",
	"valid_until" to "Derīgs līdz",
	"warning" to "Brīdinājums!",
	"yes" to "jā",
    )
    
}