package com.dingvoice.content

import androidx.compose.runtime.Composable
import com.dingvoice.localizations.Localizations
import com.dingvoice.style.WtContainer
import com.dingvoice.style.WtOffsets
import com.dingvoice.style.WtRows
import com.dingvoice.style.WtSections
import com.dingvoice.style.WtTexts
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.flexShrink
import org.jetbrains.compose.web.css.flexWrap
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Footer
import org.jetbrains.compose.web.dom.Section
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun PageFooter(isMobile: Boolean) {
    Footer({
        style {
            flexShrink(0)
            boxSizing("border-box")
            width(100.percent)
            bottom(0.px)
        }
    }) {
        Section({
            classes(WtSections.wtSectionBgBrand)
            style {
                padding(24.px, 0.px)
            }
        }) {
            Div({ classes(WtContainer.wtContainer) }) {
                Div({
                    classes(WtRows.wtRow, WtRows.wtRowSizeM, WtRows.wtRowSmAlignItemsCenter)
                    style {
                        justifyContent(JustifyContent.Center)
                        flexWrap(FlexWrap.Wrap)
                        flexDirection(FlexDirection.Column)
                    }
                }) {
                    LinkInFooter(
                        title = Localizations.currentLocale.privacy_policy,
                        url = "https://dingvoice.com/privacy.html",
                        isMobile = isMobile,
                    )
                    LinkInFooter(
                        title = Localizations.currentLocale.terms_of_service,
                        url = "https://dingvoice.com/tos.html",
                        isMobile = isMobile,
                    )
                }

                CopyrightInFooter(isMobile)
            }
        }
    }
}

@Composable
private fun LinkInFooter(
    title: String,
    url: String,
    isMobile: Boolean,
) {
    A(attrs = {
        classes(WtTexts.wtLink)
        style {
            padding(8.px)
            if (isMobile) {
                fontSize(18.px)
            } else {
                fontSize(14.px)
            }
        }
        target(ATarget.Blank)
    }, href = url) {
        Text(title)
    }
}

@Composable
private fun CopyrightInFooter(isMobile: Boolean) {
    Div({
        classes(
            WtRows.wtRow,
            WtRows.wtRowSizeM,
            WtRows.wtRowSmAlignItemsCenter,
            WtOffsets.wtTopOffset48,
        )
        style {
            justifyContent(JustifyContent.SpaceEvenly)
            flexWrap(FlexWrap.Wrap)
            padding(0.px, 12.px)
        }
    }) {
        Span({
            classes(WtTexts.wtText3)
            style {
                color(Color("#27282c"))
                if (isMobile) {
                    fontSize(16.px)
                } else {
                    fontSize(14.px)
                }
            }
        }) {
            Text("Copyright © 2022  Jscriptech, MB")
        }
    }
}
