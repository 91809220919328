// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Sw  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Kubali ${termsOfService} na ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Msimbo batili."
override val add_client_action: String = "Ongeza mteja"
override val add_customer: String = "Ongeza mteja"
override val add_expense: String = "Ongeza gharama"
override val add_fields_label: String = "Ongeza:"
override val add_invoice_action: String = "Unda"
override val add_item_action: String = "Ongeza kipengee"
override val add_item_button: String = "Ongeza kipengee"
override val add_signature_action: String = "Ongeza saini"
override val add_tax_action: String = "Ongeza kodi"
override val address_fields: String = "Anwani"
override val address_line_hint: String = "Mstari wa Anwani"
override val advanced: String = "Pevu"
override val agree_marketing: String = "Ninakubali kupokea Mawasiliano ya Moja kwa Moja ya Masoko"
override val all_notifications: String = "Taarifa Zote"
override val amortization: String = "amortization"
override val amount: String = "Kiasi"
override val asset: String = "asset"
override val attach_file: String = "Ambatisha Faili"
override val attachment: String = "Kiambatisho"
override val auth_error: String = "Kitendo kimeshindikana, jaribu tena."
override val available_for_paid: String = "Ulandanishi wa wingu unapatikana tu chini ya usajili uliolipwa."
override val backups_option: String = "Chelezo"
override val balance: String = "balance"
override val bank_account: String = "Akaunti ya Benki"
override val bill_number: String = "Nambari ya Bili"
override val cancel: String = "Katisha"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Kategoria"
override val category: String = "Kategoria"
override val category_add: String = "Ongeza Kategoria"
override val category_delete: String = "Futa kategoria"
override val category_name: String = "Jina la kategoria"
override val change_language: Formattable = Formattable { (language) -> "Lugha ya ankara: ${language}" }
override val change_language_label: String = "Lugha ya ankara"
override val change_template: String = "Badilisha kiolezo"
override val city_hint: String = "Mji"
override val clear: String = "Wazi"
override val client: String = "Mteja"
override val client_actions: String = "Vitendo vya mteja"
override val client_deleted_message: String = "Mteja afutwa"
override val clients_error_required_fields: String = "Angalau thamani moja inahitajika."
override val clients_option: String = "Wateja"
override val close: String = "Funga"
override val cloud_sync: String = "Ulandanishi wa Wingu"
override val coming_soon: String = "Yaja hivi karibuni!"
override val company_logo: String = "Nembo ya Kampuni"
override val company_name_hint: String = "Kampuni"
override val confirm_password_label: String = "Thibitisha nywila"
override val confirmation_title: String = "Una uhakika?"
override val convert_to_invoice: String = "Geuza kuwa ankara"
override val country_hint: String = "Nchi"
override val create_client: String = "Unda Mteja"
override val create_company: String = "Unda Kampuni"
override val create_document: String = "Unda waraka"
override val create_estimate: String = "Makisio"
override val create_invoice: String = "Ankara"
override val create_item: String = "Unda Kipengee"
override val create_new_client: String = "Unda mteja mpya"
override val create_new_item: String = "Unda kipengee kipya"
override val create_new_tax: String = "Unda kodi mpya"
override val create_signature: String = "Saini"
override val created: String = "Aliumba"
override val credit: String = "credit"
override val customer: String = "Wateja"
override val date: String = "Tarehe"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Sarafu ya Default: ${currency}" }
override val default_currency_label: String = "Sarafu chaguo-msingi"
override val delete: String = "Futa"
override val delete_account: String = "Futa Akaunti"
override val delete_confirmation_message: String = "Data yako yote itapotea."
override val delete_item_title: String = "Futa kipengee?"
override val delete_signature: String = "Futa saini"
override val depreciation: String = "depreciation"
override val description: String = "Maelezo"
override val description_hint: String = "Maelezo"
override val detailed_report: String = "Ripoti ya Kina"
override val disabled: String = "Walemavu"
override val discount: String = "Punguzo"
override val discount_hint: String = "Discount"
override val display_af: String = "Mwafrika"
override val display_am: String = "Kiamhari"
override val display_ar: String = "Kiarabu"
override val display_as: String = "Kiassamese"
override val display_az: String = "Kiazabajani"
override val display_be: String = "Kibelarusi"
override val display_bg: String = "Kibulgaria"
override val display_bn: String = "Kibengali"
override val display_bs: String = "Kibosnia"
override val display_ca: String = "Kikatalani"
override val display_cs: String = "Kicheki"
override val display_da: String = "Kideni"
override val display_de: String = "Kijerumani"
override val display_el: String = "Kigiriki"
override val display_en: String = "KIINGEREZA"
override val display_es: String = "Kihispania"
override val display_es_MX: String = "Kihispania (Meksiko)"
override val display_et: String = "Kiestonia"
override val display_eu: String = "Kibasque"
override val display_fa: String = "Kiajemi"
override val display_fi: String = "Kifini"
override val display_fil: String = "Kifilipino"
override val display_fr: String = "Kifaransa"
override val display_fr_CA: String = "Mfaransa wa Kanada"
override val display_gl: String = "Kigalisia"
override val display_gu: String = "Kigujarati"
override val display_he: String = "Kiebrania"
override val display_hi: String = "Hapana"
override val display_hr: String = "Kikroeshia"
override val display_hu: String = "Kihungaria"
override val display_hy: String = "Kiarmenia"
override val display_id: String = "Kiindonesia"
override val display_is: String = "Kiaislandi"
override val display_it: String = "Kiitaliano"
override val display_ja: String = "Kijapani"
override val display_ka: String = "Kijojiajia"
override val display_kk: String = "Kazakh"
override val display_km: String = "Khmer ya Kati"
override val display_kn: String = "Kikanada"
override val display_ko: String = "Kikorea"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Kazi"
override val display_lt: String = "Kilithuania"
override val display_lv: String = "Kilatvia"
override val display_mk: String = "Kimasedonia"
override val display_ml: String = "Kimalayalam"
override val display_mn: String = "Kimongolia"
override val display_mr: String = "Marathi"
override val display_ms: String = "Kimalei"
override val display_my: String = "Kiburma"
override val display_nb: String = "Bokmål ya Norway"
override val display_ne: String = "Kinepali"
override val display_nl: String = "Kiholanzi"
override val display_or: String = "Oriya"
override val display_pa: String = "Kipunjabi"
override val display_pl: String = "Kipolandi"
override val display_pt: String = "Kireno"
override val display_pt_BR: String = "Kireno (Brazili)"
override val display_pt_PT: String = "Kireno cha Ulaya"
override val display_ro: String = "Kiromania"
override val display_ru: String = "Kirusi"
override val display_si: String = "Kisinhala"
override val display_sk: String = "Kislovakia"
override val display_sl: String = "Kislovenia"
override val display_sq: String = "Kialbeni"
override val display_sr: String = "Kiserbia"
override val display_sv: String = "Kiswidi"
override val display_sw: String = "SWAHILI"
override val display_ta: String = "Kitamil"
override val display_te: String = "Kitelugu"
override val display_th: String = "Thai"
override val display_tr: String = "Kituruki"
override val display_uk: String = "Kiukreni"
override val display_ur: String = "Kiurdu"
override val display_uz: String = "Kiuzbeki"
override val display_vi: String = "Kivietinamu"
override val display_zh_Hant_TW: String = "Kichina (Taiwan, Jadi)"
override val display_zu: String = "Kizulu"
override val document_estimate: String = "Makisio"
override val document_invoice: String = "Ankara"
override val document_quote: String = "Nukuu"
override val done: String = "Imekamilika"
override val download_app: String = "Pakua programu"
override val download_invoice: String = "Pakua ankara"
override val download_pdf: String = "Pakua PDF"
override val draw_signature: String = "Chora saini"
override val due: Formattable = Formattable { (date) -> "Due ${date}" }
override val due_date: String = "Tarehe ya Mwisho"
override val due_day: String = "katika siku moja"
override val due_label: String = "Kutokana"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} inadaiwa ${dueWhen}!" }
override val due_today: String = "Leo"
override val due_week: String = "kwa wiki"
override val duplicate: String = "Nakala"
override val duration_day: String = "siku"
override val duration_month: String = "mwezi"
override val duration_week: String = "wiki"
override val duration_year: String = "mwaka"
override val email: String = "Barua pepe"
override val email_blank_error: String = "Barua pepe inahitajika."
override val email_error: String = "Anwani batili ya baruapepe."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Asante kwa biashara yako. Ili kuona ${documentType} kamili bofya kitufe kilicho mwishoni mwa barua hii. Kumbuka ni halali hadi ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Umepokea mpya${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ANGALIA ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Mpendwa ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Asante kwa biashara yako. Ili kuona ${documentType} kamili bofya kitufe kilicho mwishoni mwa barua hii. Tafadhali lipa salio hadi ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Umepokea mpya${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "Barua pepe ili kuweka upya nywila iliyotumwa, tafadhali angalia kikasha pokezi chako."
override val empty_clients_list_message: String = "Huna wateja wa kufuatilia. Ongeza mteja mpya."
override val empty_estimate_list_message: String = "Unda makadirio yako ya kwanza"
override val empty_expenses: String = "Huna gharama za kufuatilia. Ongeza gharama mpya."
override val empty_invoice_list_message: String = "Unda ankara yako ya kwanza"
override val empty_items_list_message: String = "Huna vipengee, ongeza kipengee kipya!"
override val empty_signatures_list_message: String = "Hakuna saini zilizopatikana. Unda moja!"
override val empty_taxes_list_message: String = "Hakuna sheria za kodi. Ongeza sheria mpya ya kodi."
override val empty_templates_list_message: String = "Violezo havipatikani."
override val enabled: String = "Imewashwa"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Kadiria #"
override val estimates: String = "Makadirio"
override val estimates_report: String = "Makadirio"
override val expense_bank_fees: String = "Ada za Benki"
override val expense_benefits: String = "Faida"
override val expense_communication: String = "Mawasiliano"
override val expense_debt: String = "Deni"
override val expense_deleted: String = "Gharama imefutwa"
override val expense_depreciation: String = "Kushuka kwa thamani"
override val expense_gifts: String = "Zawadi"
override val expense_insurance: String = "Bima"
override val expense_inventory: String = "Malipo"
override val expense_legal: String = "Kisheria"
override val expense_maintenance: String = "Matengenezo"
override val expense_marketing: String = "Masoko"
override val expense_meals: String = "Milo"
override val expense_office_supplies: String = "Vifaa vya Ofisi"
override val expense_rent: String = "Kodisha"
override val expense_salaries: String = "Mishahara"
override val expense_shopping: String = "Ununuzi"
override val expense_software: String = "Programu"
override val expense_status_available: String = "Inapatikana"
override val expense_status_processing: String = "Inachakata"
override val expense_tax: String = "Kodi"
override val expense_training: String = "Mafunzo"
override val expense_transport: String = "Usafiri"
override val expense_travel: String = "Safari"
override val expense_utility: String = "Huduma"
override val expenses: String = "Gharama"
override val feedback_option: String = "Maoni"
override val filter: String = "Kichujio"
override val first_name_hint: String = "Jina la kwanza"
override val forgot_password: String = "Weka upya nywila"
override val forgot_password_label: String = "Umesahau Nywila"
override val from: String = "Kutoka"
override val general_error: String = "Hitilafu imetokea, tafadhali jaribu tena baadaye."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Rudi"
override val group_by: String = "Kundisha Kwa:"
override val image_view: String = "Taswira"
override val install: String = "Sakinisha"
override val invalid_credentials_error: String = "Hati za utambulisho batili."
override val invalid_email_error: String = "Barua pepe batili."
override val invalid_user_error: String = "Hati za utambulisho za mtumiaji ni batili."
override val invoice_deleted_message: String = "Ankara imefutwa"
override val invoice_menu: String = "Menyu ya ankara"
override val invoice_number: String = "Ankara #"
override val invoices: String = "Ankara"
override val items_option: String = "Vipengee"
override val keep: String = "Kuweka"
override val language_selector: Formattable = Formattable { (iliyochaguliwa) -> "Lugha ${iliyochaguliwa}" }
override val last_name_hint: String = "Jina la Mwisho"
override val ledger: String = "ledger"
override val legal: String = "Kisheria"
override val legal_error: String = "Lazima kukubali Masharti ya Huduma na Sera ya Faragha"
override val liabilities: String = "liabilities"
override val loading: String = "Kupakia"
override val login_required_error: String = "Ingia inahitajika."
override val logo_view: String = "Nembo"
override val logout: String = "Logi"
override val logout_prompt: String = "Data yako haijalandanishwa na wingu. Futa ankara, makadirio, wateja na habari nyingine juu ya logout?"
override val main_screen: String = "Skrini kuu"
override val mark_paid: String = "Tia alama kama Imelipwa"
override val message: String = "Ujumbe"
override val missing_sender_msg: String = "Hati isiyo na maelezo ya mtumaji inaweza kuwa batili."
override val missing_sender_title: String = "Maelezo ya mtumaji hayapo"
override val month_april: String = "Aprili"
override val month_april_short: String = "Aprili"
override val month_august: String = "Agosti"
override val month_august_short: String = "Aug."
override val month_december: String = "Desemba"
override val month_december_short: String = "Des."
override val month_february: String = "Februari"
override val month_february_short: String = "Feb."
override val month_january: String = "Januari"
override val month_january_short: String = "Jan."
override val month_july: String = "Julai"
override val month_july_short: String = "Julai"
override val month_june: String = "Juni"
override val month_june_short: String = "Juni"
override val month_march: String = "Machi"
override val month_march_short: String = "Machi"
override val month_may: String = "Mei"
override val month_may_short: String = "Mei"
override val month_november: String = "Novemba"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktoba"
override val month_october_short: String = "Okt."
override val month_september: String = "Septemba"
override val month_september_short: String = "Septemba."
override val multifactor_error: String = "Imeshindwa kuthibitisha."
override val new: String = "Mpya"
override val news: String = "Habari"
override val next: String = "Inayofuata"
override val no: String = "no"
override val no_data_available: String = "Hakuna data inayopatikana"
override val no_search_results: String = "Samahani, hatukuweza kupata matokeo yoyote."
override val none: String = "Hakuna"
override val note_fields: String = "Madokezo"
override val note_hint: String = "Kumbuka"
override val notifications_option: String = "Taarifa"
override val ok: String = "Sawa kabisa"
override val onboarding: String = "Kupanda"
override val onboarding_aboutus_message: String = "Sehemu muhimu za DingVoice ni bure milele. Dhamira yetu ni kukusaidia kukuza biashara yako, tusaidie kuboresha kwa kushiriki maoni yako."
override val onboarding_aboutus_title: String = "Tuko hapa kusaidia"
override val onboarding_paid_1: String = "Fikia violezo vya kitaalamu"
override val onboarding_paid_2: String = "Chunguza mambo yako ya kifedha kwa undani zaidi"
override val onboarding_paid_3: String = "Pata shida kutatuliwa haraka"
override val onboarding_paid_4: String = "Weka hati zako salama"
override val onboarding_paid_5: String = "Pata taarifa kuhusu mabadiliko ya hali ya ankara"
override val onboarding_paid_title: String = "Kuza haraka zaidi na..."
override val onboarding_paywall_message: String = "Furahia vipengele vyote vinavyolipishwa sasa kwa bei nzuri zaidi."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "${currencySymbol}${introAmount} kwa ${introDuration} ${introPeriod} na kisha ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} / ${duration} pekee" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Bila malipo kwa ${trialDuration} ${trialPeriod} na kisha ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Bure kwa${trialDuration}${trialPeriod} basi${currencySymbol}${introductoryAmount} kwa${introductoryDuration}${introPeriod} na kisha tu${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "Inaweza kufanywa upya kiotomatiki. Ghairi wakati wowote."
override val onboarding_paywall_restore: String = "Rejesha ununuzi"
override val onboarding_paywall_restore_failure: String = "Kurejesha ununuzi kumeshindwa, jaribu tena baadaye au uwasiliane na usaidizi."
override val onboarding_paywall_restore_success: String = "Kurejesha kumefaulu!"
override val onboarding_paywall_title: String = "Fikia Ukuu!"
override val onboarding_welcome_message: String = "Chombo kamili cha kudhibiti ankara na makadirio yako."
override val onboarding_welcome_title: String = "Karibu DingVoice"
override val other_state: String = "Nyingine"
override val overdue_state: String = "Muda wa ziada"
override val paid_state: String = "Kulipwa"
override val password: String = "Nywila"
override val password_blank_error: String = "Nywila inahitajika."
override val password_mismatch_error: String = "Nywila hazioani."
override val pay: String = "pay"
override val payment_instructions_label: String = "Maelekezo ya Malipo"
override val payment_reminders: String = "Vikumbusho vya malipo"
override val phone_number_fields: String = "Namba ya Simu"
override val phone_number_hint: String = "Namba ya Simu"
override val postcode_hint: String = "Msimbo posta"
override val preview: String = "Mwoneko awali"
override val preview_menu_hint: String = "Menyu ya mwoneko awali"
override val price: String = "Bei"
override val privacy_policy: String = "Sera ya Faragha"
override val profile: String = "Umbo"
override val profitable_client: String = "Mteja wako mwenye faida zaidi ni..."
override val profits_report: String = "Faida"
override val promo_content: String = "Maudhui ya uendelezaji"
override val quantity_hint: String = "Kiasi"
override val quantity_label_hint: String = "Lebo ya Wingi"
override val query_info: String = "Ulizo hukuruhusu kuandika mahesabu maalum yanayorejelea vitu vyote na sheria zingine za ushuru kama pembejeo. Ongeza @ ishara na bidhaa au maelezo ya kodi ili kuunganisha kwenye kuingia mwingine +, -, *, /, % - waendeshaji wa hesabu"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ilipokelewa na mteja wako." }
override val received_state: String = "Imepokelewa"
override val registration_code_fields: String = "Msimbo wa Usajili"
override val reminder: String = "Kikumbusho"
override val reports: String = "Ripoti"
override val reports_not_available: String = "Hakuna ripoti zinazopatikana, rudi baadaye."
override val reveal_password: String = "Fichua nywila"
override val revenue: String = "revenue"
override val sales_tax: String = "VAT"
override val sales_tax_number: String = "Nambari ya VAT"
override val save: String = "Hifadhi"
override val scan_document: String = "Changanua hati"
override val search: String = "Tafutiza"
override val select: String = "Chagua"
override val select_icon: String = "Chagua ikoni"
override val select_signature: String = "Teua saini"
override val select_template: String = "Teua kiolezo"
override val send: String = "Tuma"
override val sent_state: String = "Alimtuma"
override val setup_sender: String = "Sanidi mtumaji"
override val share_option: String = "Kushiriki"
override val ship_to: String = "Kusafirisha hadi"
override val sign_in: String = "Ingia ndani"
override val sign_in_apple: String = "Kuingia na Apple"
override val sign_in_email: String = "Ingia na barua pepe"
override val sign_in_facebook: String = "Ingia na Facebook"
override val sign_in_google: String = "Ingia na Google"
override val sign_up: String = "Jisajili"
override val sign_up_label: String = "Huna akaunti?"
override val sign_up_link_label: String = "Jisajili!"
override val signatures_option: String = "Saini"
override val skip: String = "Ruka"
override val sold_items_report: String = "Vitu Vilivyouzwa"
override val sort_by: String = "Panga Kwa:"
override val status_update: String = "Sasisho la hali ya hati"
override val sub_annual: String = "Mwaka"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/mwaka" }
override val sub_backups: String = "Hifadhi ya wingu"
override val sub_insights: String = "Ufahamu"
override val sub_month: String = "Kila mwezi"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mwezi" }
override val sub_notifications: String = "Arifa"
override val sub_popup_message: String = "Usajili unakuja hivi karibuni. Angalia hapa kwa muda mfupi."
override val sub_support: String = "Auni"
override val sub_templates: String = "Violezo"
override val subscribe: String = "Kujiunga"
override val subscription_header: String = "Kukua biashara yako na:"
override val subscription_option: String = "Michango"
override val subscriptions_load_fail: String = "Samahani, hatuwezi kurejesha usajili wako kwa wakati huu. Tafadhali jaribu tena baadaye au wasiliana na timu yetu ya usaidizi kwa usaidizi."
override val subtotal: Formattable = Formattable { (sum) -> "Jumla ndogo: ${sum}" }
override val subtotal_label: String = "jumla ndogo"
override val sum: String = "Jumla"
override val support_option: String = "Msaada"
override val switch_reports: String = "Badilisha Ripoti"
override val tax: String = "Kodi"
override val tax_deleted_message: String = "Kodi imefutwa"
override val tax_description_warning: String = "Ikiwa inatumiwa hii inaonyesha kwenye ankara"
override val tax_input_flat: String = "Gorofa"
override val tax_input_percent: String = "Asilimia"
override val tax_number_fields: String = "Namba ya Kodi"
override val tax_number_hint: String = "Namba ya Kodi"
override val tax_query_info_description: String = "Taarifa ya Ulizo"
override val tax_report: String = "Kodi"
override val tax_rules_option: String = "Sheria za Kodi"
override val taxed_clients_report: String = "Wateja Waliotozwa Ushuru"
override val template: String = "Kiolezo"
override val template_selector_info: String = "Telezesha ili kubadilisha kiolezo chaguo-msingi"
override val template_unlock_promo: String = "Fikia violezo zaidi ukitumia Premium"
override val templates_option: String = "Violezo"
override val terms_of_service: String = "Masharti ya Huduma"
override val thank_you: String = "Asante"
override val to: String = "kwa"
override val total: Formattable = Formattable { (sum) -> "Jumla: ${sum}" }
override val total_label: String = "Jumla"
override val undo_action: String = "Tendua"
override val unit: String = "Kitengo"
override val unit_cost_hint: String = "Gharama ya Kitengo"
override val unknown_client: String = "Mteja Asiyejulikana"
override val unlock: String = "Fungua"
override val unnamed_item: String = "Kipengee kisicho na jina"
override val upgrade: String = "Kuboresha"
override val user_collision_error: String = "Akaunti tayari ipo."
override val valid_until: String = "Inatumika Hadi"
override val warning: String = "Onyo!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Kubali ${termsOfService} na ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Msimbo batili.",
	"add_client_action" to "Ongeza mteja",
	"add_customer" to "Ongeza mteja",
	"add_expense" to "Ongeza gharama",
	"add_fields_label" to "Ongeza:",
	"add_invoice_action" to "Unda",
	"add_item_action" to "Ongeza kipengee",
	"add_item_button" to "Ongeza kipengee",
	"add_signature_action" to "Ongeza saini",
	"add_tax_action" to "Ongeza kodi",
	"address_fields" to "Anwani",
	"address_line_hint" to "Mstari wa Anwani",
	"advanced" to "Pevu",
	"agree_marketing" to "Ninakubali kupokea Mawasiliano ya Moja kwa Moja ya Masoko",
	"all_notifications" to "Taarifa Zote",
	"amortization" to "amortization",
	"amount" to "Kiasi",
	"asset" to "asset",
	"attach_file" to "Ambatisha Faili",
	"attachment" to "Kiambatisho",
	"auth_error" to "Kitendo kimeshindikana, jaribu tena.",
	"available_for_paid" to "Ulandanishi wa wingu unapatikana tu chini ya usajili uliolipwa.",
	"backups_option" to "Chelezo",
	"balance" to "balance",
	"bank_account" to "Akaunti ya Benki",
	"bill_number" to "Nambari ya Bili",
	"cancel" to "Katisha",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Kategoria",
	"category" to "Kategoria",
	"category_add" to "Ongeza Kategoria",
	"category_delete" to "Futa kategoria",
	"category_name" to "Jina la kategoria",
	"change_language" to Formattable { (language) -> "Lugha ya ankara: ${language}" },
	"change_language_label" to "Lugha ya ankara",
	"change_template" to "Badilisha kiolezo",
	"city_hint" to "Mji",
	"clear" to "Wazi",
	"client" to "Mteja",
	"client_actions" to "Vitendo vya mteja",
	"client_deleted_message" to "Mteja afutwa",
	"clients_error_required_fields" to "Angalau thamani moja inahitajika.",
	"clients_option" to "Wateja",
	"close" to "Funga",
	"cloud_sync" to "Ulandanishi wa Wingu",
	"coming_soon" to "Yaja hivi karibuni!",
	"company_logo" to "Nembo ya Kampuni",
	"company_name_hint" to "Kampuni",
	"confirm_password_label" to "Thibitisha nywila",
	"confirmation_title" to "Una uhakika?",
	"convert_to_invoice" to "Geuza kuwa ankara",
	"country_hint" to "Nchi",
	"create_client" to "Unda Mteja",
	"create_company" to "Unda Kampuni",
	"create_document" to "Unda waraka",
	"create_estimate" to "Makisio",
	"create_invoice" to "Ankara",
	"create_item" to "Unda Kipengee",
	"create_new_client" to "Unda mteja mpya",
	"create_new_item" to "Unda kipengee kipya",
	"create_new_tax" to "Unda kodi mpya",
	"create_signature" to "Saini",
	"created" to "Aliumba",
	"credit" to "credit",
	"customer" to "Wateja",
	"date" to "Tarehe",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Sarafu ya Default: ${currency}" },
	"default_currency_label" to "Sarafu chaguo-msingi",
	"delete" to "Futa",
	"delete_account" to "Futa Akaunti",
	"delete_confirmation_message" to "Data yako yote itapotea.",
	"delete_item_title" to "Futa kipengee?",
	"delete_signature" to "Futa saini",
	"depreciation" to "depreciation",
	"description" to "Maelezo",
	"description_hint" to "Maelezo",
	"detailed_report" to "Ripoti ya Kina",
	"disabled" to "Walemavu",
	"discount" to "Punguzo",
	"discount_hint" to "Discount",
	"display_af" to "Mwafrika",
	"display_am" to "Kiamhari",
	"display_ar" to "Kiarabu",
	"display_as" to "Kiassamese",
	"display_az" to "Kiazabajani",
	"display_be" to "Kibelarusi",
	"display_bg" to "Kibulgaria",
	"display_bn" to "Kibengali",
	"display_bs" to "Kibosnia",
	"display_ca" to "Kikatalani",
	"display_cs" to "Kicheki",
	"display_da" to "Kideni",
	"display_de" to "Kijerumani",
	"display_el" to "Kigiriki",
	"display_en" to "KIINGEREZA",
	"display_es" to "Kihispania",
	"display_es_MX" to "Kihispania (Meksiko)",
	"display_et" to "Kiestonia",
	"display_eu" to "Kibasque",
	"display_fa" to "Kiajemi",
	"display_fi" to "Kifini",
	"display_fil" to "Kifilipino",
	"display_fr" to "Kifaransa",
	"display_fr_CA" to "Mfaransa wa Kanada",
	"display_gl" to "Kigalisia",
	"display_gu" to "Kigujarati",
	"display_he" to "Kiebrania",
	"display_hi" to "Hapana",
	"display_hr" to "Kikroeshia",
	"display_hu" to "Kihungaria",
	"display_hy" to "Kiarmenia",
	"display_id" to "Kiindonesia",
	"display_is" to "Kiaislandi",
	"display_it" to "Kiitaliano",
	"display_ja" to "Kijapani",
	"display_ka" to "Kijojiajia",
	"display_kk" to "Kazakh",
	"display_km" to "Khmer ya Kati",
	"display_kn" to "Kikanada",
	"display_ko" to "Kikorea",
	"display_ky" to "Kirghiz",
	"display_lo" to "Kazi",
	"display_lt" to "Kilithuania",
	"display_lv" to "Kilatvia",
	"display_mk" to "Kimasedonia",
	"display_ml" to "Kimalayalam",
	"display_mn" to "Kimongolia",
	"display_mr" to "Marathi",
	"display_ms" to "Kimalei",
	"display_my" to "Kiburma",
	"display_nb" to "Bokmål ya Norway",
	"display_ne" to "Kinepali",
	"display_nl" to "Kiholanzi",
	"display_or" to "Oriya",
	"display_pa" to "Kipunjabi",
	"display_pl" to "Kipolandi",
	"display_pt" to "Kireno",
	"display_pt_BR" to "Kireno (Brazili)",
	"display_pt_PT" to "Kireno cha Ulaya",
	"display_ro" to "Kiromania",
	"display_ru" to "Kirusi",
	"display_si" to "Kisinhala",
	"display_sk" to "Kislovakia",
	"display_sl" to "Kislovenia",
	"display_sq" to "Kialbeni",
	"display_sr" to "Kiserbia",
	"display_sv" to "Kiswidi",
	"display_sw" to "SWAHILI",
	"display_ta" to "Kitamil",
	"display_te" to "Kitelugu",
	"display_th" to "Thai",
	"display_tr" to "Kituruki",
	"display_uk" to "Kiukreni",
	"display_ur" to "Kiurdu",
	"display_uz" to "Kiuzbeki",
	"display_vi" to "Kivietinamu",
	"display_zh_Hant_TW" to "Kichina (Taiwan, Jadi)",
	"display_zu" to "Kizulu",
	"document_estimate" to "Makisio",
	"document_invoice" to "Ankara",
	"document_quote" to "Nukuu",
	"done" to "Imekamilika",
	"download_app" to "Pakua programu",
	"download_invoice" to "Pakua ankara",
	"download_pdf" to "Pakua PDF",
	"draw_signature" to "Chora saini",
	"due" to Formattable { (date) -> "Due ${date}" },
	"due_date" to "Tarehe ya Mwisho",
	"due_day" to "katika siku moja",
	"due_label" to "Kutokana",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} inadaiwa ${dueWhen}!" },
	"due_today" to "Leo",
	"due_week" to "kwa wiki",
	"duplicate" to "Nakala",
	"duration_day" to "siku",
	"duration_month" to "mwezi",
	"duration_week" to "wiki",
	"duration_year" to "mwaka",
	"email" to "Barua pepe",
	"email_blank_error" to "Barua pepe inahitajika.",
	"email_error" to "Anwani batili ya baruapepe.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Asante kwa biashara yako. Ili kuona ${documentType} kamili bofya kitufe kilicho mwishoni mwa barua hii. Kumbuka ni halali hadi ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Umepokea mpya${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "ANGALIA ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Mpendwa ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Asante kwa biashara yako. Ili kuona ${documentType} kamili bofya kitufe kilicho mwishoni mwa barua hii. Tafadhali lipa salio hadi ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Umepokea mpya${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "Barua pepe ili kuweka upya nywila iliyotumwa, tafadhali angalia kikasha pokezi chako.",
	"empty_clients_list_message" to "Huna wateja wa kufuatilia. Ongeza mteja mpya.",
	"empty_estimate_list_message" to "Unda makadirio yako ya kwanza",
	"empty_expenses" to "Huna gharama za kufuatilia. Ongeza gharama mpya.",
	"empty_invoice_list_message" to "Unda ankara yako ya kwanza",
	"empty_items_list_message" to "Huna vipengee, ongeza kipengee kipya!",
	"empty_signatures_list_message" to "Hakuna saini zilizopatikana. Unda moja!",
	"empty_taxes_list_message" to "Hakuna sheria za kodi. Ongeza sheria mpya ya kodi.",
	"empty_templates_list_message" to "Violezo havipatikani.",
	"enabled" to "Imewashwa",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Kadiria #",
	"estimates" to "Makadirio",
	"estimates_report" to "Makadirio",
	"expense_bank_fees" to "Ada za Benki",
	"expense_benefits" to "Faida",
	"expense_communication" to "Mawasiliano",
	"expense_debt" to "Deni",
	"expense_deleted" to "Gharama imefutwa",
	"expense_depreciation" to "Kushuka kwa thamani",
	"expense_gifts" to "Zawadi",
	"expense_insurance" to "Bima",
	"expense_inventory" to "Malipo",
	"expense_legal" to "Kisheria",
	"expense_maintenance" to "Matengenezo",
	"expense_marketing" to "Masoko",
	"expense_meals" to "Milo",
	"expense_office_supplies" to "Vifaa vya Ofisi",
	"expense_rent" to "Kodisha",
	"expense_salaries" to "Mishahara",
	"expense_shopping" to "Ununuzi",
	"expense_software" to "Programu",
	"expense_status_available" to "Inapatikana",
	"expense_status_processing" to "Inachakata",
	"expense_tax" to "Kodi",
	"expense_training" to "Mafunzo",
	"expense_transport" to "Usafiri",
	"expense_travel" to "Safari",
	"expense_utility" to "Huduma",
	"expenses" to "Gharama",
	"feedback_option" to "Maoni",
	"filter" to "Kichujio",
	"first_name_hint" to "Jina la kwanza",
	"forgot_password" to "Weka upya nywila",
	"forgot_password_label" to "Umesahau Nywila",
	"from" to "Kutoka",
	"general_error" to "Hitilafu imetokea, tafadhali jaribu tena baadaye.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Rudi",
	"group_by" to "Kundisha Kwa:",
	"image_view" to "Taswira",
	"install" to "Sakinisha",
	"invalid_credentials_error" to "Hati za utambulisho batili.",
	"invalid_email_error" to "Barua pepe batili.",
	"invalid_user_error" to "Hati za utambulisho za mtumiaji ni batili.",
	"invoice_deleted_message" to "Ankara imefutwa",
	"invoice_menu" to "Menyu ya ankara",
	"invoice_number" to "Ankara #",
	"invoices" to "Ankara",
	"items_option" to "Vipengee",
	"keep" to "Kuweka",
	"language_selector" to Formattable { (iliyochaguliwa) -> "Lugha ${iliyochaguliwa}" },
	"last_name_hint" to "Jina la Mwisho",
	"ledger" to "ledger",
	"legal" to "Kisheria",
	"legal_error" to "Lazima kukubali Masharti ya Huduma na Sera ya Faragha",
	"liabilities" to "liabilities",
	"loading" to "Kupakia",
	"login_required_error" to "Ingia inahitajika.",
	"logo_view" to "Nembo",
	"logout" to "Logi",
	"logout_prompt" to "Data yako haijalandanishwa na wingu. Futa ankara, makadirio, wateja na habari nyingine juu ya logout?",
	"main_screen" to "Skrini kuu",
	"mark_paid" to "Tia alama kama Imelipwa",
	"message" to "Ujumbe",
	"missing_sender_msg" to "Hati isiyo na maelezo ya mtumaji inaweza kuwa batili.",
	"missing_sender_title" to "Maelezo ya mtumaji hayapo",
	"month_april" to "Aprili",
	"month_april_short" to "Aprili",
	"month_august" to "Agosti",
	"month_august_short" to "Aug.",
	"month_december" to "Desemba",
	"month_december_short" to "Des.",
	"month_february" to "Februari",
	"month_february_short" to "Feb.",
	"month_january" to "Januari",
	"month_january_short" to "Jan.",
	"month_july" to "Julai",
	"month_july_short" to "Julai",
	"month_june" to "Juni",
	"month_june_short" to "Juni",
	"month_march" to "Machi",
	"month_march_short" to "Machi",
	"month_may" to "Mei",
	"month_may_short" to "Mei",
	"month_november" to "Novemba",
	"month_november_short" to "Nov.",
	"month_october" to "Oktoba",
	"month_october_short" to "Okt.",
	"month_september" to "Septemba",
	"month_september_short" to "Septemba.",
	"multifactor_error" to "Imeshindwa kuthibitisha.",
	"new" to "Mpya",
	"news" to "Habari",
	"next" to "Inayofuata",
	"no" to "no",
	"no_data_available" to "Hakuna data inayopatikana",
	"no_search_results" to "Samahani, hatukuweza kupata matokeo yoyote.",
	"none" to "Hakuna",
	"note_fields" to "Madokezo",
	"note_hint" to "Kumbuka",
	"notifications_option" to "Taarifa",
	"ok" to "Sawa kabisa",
	"onboarding" to "Kupanda",
	"onboarding_aboutus_message" to "Sehemu muhimu za DingVoice ni bure milele. Dhamira yetu ni kukusaidia kukuza biashara yako, tusaidie kuboresha kwa kushiriki maoni yako.",
	"onboarding_aboutus_title" to "Tuko hapa kusaidia",
	"onboarding_paid_1" to "Fikia violezo vya kitaalamu",
	"onboarding_paid_2" to "Chunguza mambo yako ya kifedha kwa undani zaidi",
	"onboarding_paid_3" to "Pata shida kutatuliwa haraka",
	"onboarding_paid_4" to "Weka hati zako salama",
	"onboarding_paid_5" to "Pata taarifa kuhusu mabadiliko ya hali ya ankara",
	"onboarding_paid_title" to "Kuza haraka zaidi na...",
	"onboarding_paywall_message" to "Furahia vipengele vyote vinavyolipishwa sasa kwa bei nzuri zaidi.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "${currencySymbol}${introAmount} kwa ${introDuration} ${introPeriod} na kisha ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} / ${duration} pekee" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Bila malipo kwa ${trialDuration} ${trialPeriod} na kisha ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Bure kwa${trialDuration}${trialPeriod} basi${currencySymbol}${introductoryAmount} kwa${introductoryDuration}${introPeriod} na kisha tu${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "Inaweza kufanywa upya kiotomatiki. Ghairi wakati wowote.",
	"onboarding_paywall_restore" to "Rejesha ununuzi",
	"onboarding_paywall_restore_failure" to "Kurejesha ununuzi kumeshindwa, jaribu tena baadaye au uwasiliane na usaidizi.",
	"onboarding_paywall_restore_success" to "Kurejesha kumefaulu!",
	"onboarding_paywall_title" to "Fikia Ukuu!",
	"onboarding_welcome_message" to "Chombo kamili cha kudhibiti ankara na makadirio yako.",
	"onboarding_welcome_title" to "Karibu DingVoice",
	"other_state" to "Nyingine",
	"overdue_state" to "Muda wa ziada",
	"paid_state" to "Kulipwa",
	"password" to "Nywila",
	"password_blank_error" to "Nywila inahitajika.",
	"password_mismatch_error" to "Nywila hazioani.",
	"pay" to "pay",
	"payment_instructions_label" to "Maelekezo ya Malipo",
	"payment_reminders" to "Vikumbusho vya malipo",
	"phone_number_fields" to "Namba ya Simu",
	"phone_number_hint" to "Namba ya Simu",
	"postcode_hint" to "Msimbo posta",
	"preview" to "Mwoneko awali",
	"preview_menu_hint" to "Menyu ya mwoneko awali",
	"price" to "Bei",
	"privacy_policy" to "Sera ya Faragha",
	"profile" to "Umbo",
	"profitable_client" to "Mteja wako mwenye faida zaidi ni...",
	"profits_report" to "Faida",
	"promo_content" to "Maudhui ya uendelezaji",
	"quantity_hint" to "Kiasi",
	"quantity_label_hint" to "Lebo ya Wingi",
	"query_info" to "Ulizo hukuruhusu kuandika mahesabu maalum yanayorejelea vitu vyote na sheria zingine za ushuru kama pembejeo. Ongeza @ ishara na bidhaa au maelezo ya kodi ili kuunganisha kwenye kuingia mwingine +, -, *, /, % - waendeshaji wa hesabu",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ilipokelewa na mteja wako." },
	"received_state" to "Imepokelewa",
	"registration_code_fields" to "Msimbo wa Usajili",
	"reminder" to "Kikumbusho",
	"reports" to "Ripoti",
	"reports_not_available" to "Hakuna ripoti zinazopatikana, rudi baadaye.",
	"reveal_password" to "Fichua nywila",
	"revenue" to "revenue",
	"sales_tax" to "VAT",
	"sales_tax_number" to "Nambari ya VAT",
	"save" to "Hifadhi",
	"scan_document" to "Changanua hati",
	"search" to "Tafutiza",
	"select" to "Chagua",
	"select_icon" to "Chagua ikoni",
	"select_signature" to "Teua saini",
	"select_template" to "Teua kiolezo",
	"send" to "Tuma",
	"sent_state" to "Alimtuma",
	"setup_sender" to "Sanidi mtumaji",
	"share_option" to "Kushiriki",
	"ship_to" to "Kusafirisha hadi",
	"sign_in" to "Ingia ndani",
	"sign_in_apple" to "Kuingia na Apple",
	"sign_in_email" to "Ingia na barua pepe",
	"sign_in_facebook" to "Ingia na Facebook",
	"sign_in_google" to "Ingia na Google",
	"sign_up" to "Jisajili",
	"sign_up_label" to "Huna akaunti?",
	"sign_up_link_label" to "Jisajili!",
	"signatures_option" to "Saini",
	"skip" to "Ruka",
	"sold_items_report" to "Vitu Vilivyouzwa",
	"sort_by" to "Panga Kwa:",
	"status_update" to "Sasisho la hali ya hati",
	"sub_annual" to "Mwaka",
	"sub_annual_price" to Formattable { (price) -> "${price}/mwaka" },
	"sub_backups" to "Hifadhi ya wingu",
	"sub_insights" to "Ufahamu",
	"sub_month" to "Kila mwezi",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mwezi" },
	"sub_notifications" to "Arifa",
	"sub_popup_message" to "Usajili unakuja hivi karibuni. Angalia hapa kwa muda mfupi.",
	"sub_support" to "Auni",
	"sub_templates" to "Violezo",
	"subscribe" to "Kujiunga",
	"subscription_header" to "Kukua biashara yako na:",
	"subscription_option" to "Michango",
	"subscriptions_load_fail" to "Samahani, hatuwezi kurejesha usajili wako kwa wakati huu. Tafadhali jaribu tena baadaye au wasiliana na timu yetu ya usaidizi kwa usaidizi.",
	"subtotal" to Formattable { (sum) -> "Jumla ndogo: ${sum}" },
	"subtotal_label" to "jumla ndogo",
	"sum" to "Jumla",
	"support_option" to "Msaada",
	"switch_reports" to "Badilisha Ripoti",
	"tax" to "Kodi",
	"tax_deleted_message" to "Kodi imefutwa",
	"tax_description_warning" to "Ikiwa inatumiwa hii inaonyesha kwenye ankara",
	"tax_input_flat" to "Gorofa",
	"tax_input_percent" to "Asilimia",
	"tax_number_fields" to "Namba ya Kodi",
	"tax_number_hint" to "Namba ya Kodi",
	"tax_query_info_description" to "Taarifa ya Ulizo",
	"tax_report" to "Kodi",
	"tax_rules_option" to "Sheria za Kodi",
	"taxed_clients_report" to "Wateja Waliotozwa Ushuru",
	"template" to "Kiolezo",
	"template_selector_info" to "Telezesha ili kubadilisha kiolezo chaguo-msingi",
	"template_unlock_promo" to "Fikia violezo zaidi ukitumia Premium",
	"templates_option" to "Violezo",
	"terms_of_service" to "Masharti ya Huduma",
	"thank_you" to "Asante",
	"to" to "kwa",
	"total" to Formattable { (sum) -> "Jumla: ${sum}" },
	"total_label" to "Jumla",
	"undo_action" to "Tendua",
	"unit" to "Kitengo",
	"unit_cost_hint" to "Gharama ya Kitengo",
	"unknown_client" to "Mteja Asiyejulikana",
	"unlock" to "Fungua",
	"unnamed_item" to "Kipengee kisicho na jina",
	"upgrade" to "Kuboresha",
	"user_collision_error" to "Akaunti tayari ipo.",
	"valid_until" to "Inatumika Hadi",
	"warning" to "Onyo!",
	"yes" to "yes",
    )
    
}