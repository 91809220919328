// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Fil  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Tanggapin ang ${termsOfService} at ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Imbalidong code."
override val add_client_action: String = "Magdagdag ng kliyente"
override val add_customer: String = "Magdagdag ng kustomer"
override val add_expense: String = "Magdagdag ng gastos"
override val add_fields_label: String = "Idagdag:"
override val add_invoice_action: String = "Lumikha"
override val add_item_action: String = "Magdagdag ng aytem"
override val add_item_button: String = "Magdagdag ng aytem"
override val add_signature_action: String = "Magdagdag ng lagda"
override val add_tax_action: String = "Magdagdag ng buwis"
override val address_fields: String = "Adres"
override val address_line_hint: String = "Linya ng Adres"
override val advanced: String = "Naunang"
override val agree_marketing: String = "Sumasang-ayon akong tumanggap ng Direct Marketing Communications"
override val all_notifications: String = "Lahat ng Notification"
override val amortization: String = "amortization"
override val amount: String = "Halaga"
override val asset: String = "asset"
override val attach_file: String = "Ilakip ang File"
override val attachment: String = "Attachment"
override val auth_error: String = "Nabigo ang pagkilos, subukang muli."
override val available_for_paid: String = "Ang maulap na singk ay magagamit lamang sa ilalim ng binabayarang subscription."
override val backups_option: String = "Mga Backup"
override val balance: String = "balance"
override val bank_account: String = "Account sa Bangko"
override val bill_number: String = "Numero ng Bill"
override val cancel: String = "Kanselahin ang"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Mga Kategorya"
override val category: String = "Kategorya"
override val category_add: String = "Magdagdag ng kategorya"
override val category_delete: String = "I-delete ang kategorya"
override val category_name: String = "Pangalan ng kategorya"
override val change_language: Formattable = Formattable { (language) -> "Wika ng Invoice: ${language}" }
override val change_language_label: String = "Wika ng Invoice"
override val change_template: String = "Magpalit ng template"
override val city_hint: String = "Lungsod"
override val clear: String = "Malinaw"
override val client: String = "Kliyente"
override val client_actions: String = "Mga aksyong kliyente"
override val client_deleted_message: String = "Kliyente tinanggal"
override val clients_error_required_fields: String = "Hindi bababa sa isang halaga ang kailangan."
override val clients_option: String = "Mga Kliyente"
override val close: String = "Isara"
override val cloud_sync: String = "Maulap na Singk"
override val coming_soon: String = "Pagdating sa lalong madaling panahon!"
override val company_logo: String = "Logo ng Kumpanya"
override val company_name_hint: String = "Kumpanya"
override val confirm_password_label: String = "Kumpirmahin ang password"
override val confirmation_title: String = "Sigurado ka ba?"
override val convert_to_invoice: String = "Convert sa invoice"
override val country_hint: String = "Bansa"
override val create_client: String = "Lumikha ng Kliyente"
override val create_company: String = "Lumikha ng Kumpanya"
override val create_document: String = "Lumikha ng dokumento"
override val create_estimate: String = "Tantiyahin"
override val create_invoice: String = "Invoice"
override val create_item: String = "Lumikha ng Aytem"
override val create_new_client: String = "Lumikha ng bagong kliyente"
override val create_new_item: String = "Lumikha ng bagong aytem"
override val create_new_tax: String = "Lumikha ng bagong buwis"
override val create_signature: String = "Lagda"
override val created: String = "Nilikha"
override val credit: String = "credit"
override val customer: String = "Customer"
override val date: String = "Petsa"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Default na pera: ${currency}" }
override val default_currency_label: String = "Default na pera"
override val delete: String = "Magtanggal"
override val delete_account: String = "Tanggalin ang Account"
override val delete_confirmation_message: String = "Mawawala ang lahat ng iyong data."
override val delete_item_title: String = "Magtanggal ng aytem?"
override val delete_signature: String = "Magtanggal ng lagda"
override val depreciation: String = "depreciation"
override val description: String = "Paglalarawan"
override val description_hint: String = "Deskrip"
override val detailed_report: String = "Detalyadong Ulat"
override val disabled: String = "Huwag paganahin ang"
override val discount: String = "Diskwento"
override val discount_hint: String = "Diskuwento"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharic"
override val display_ar: String = "Arabic"
override val display_as: String = "Assamese"
override val display_az: String = "Azerbaijani"
override val display_be: String = "Belarusian"
override val display_bg: String = "Bulgarian"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosnian"
override val display_ca: String = "Catalan"
override val display_cs: String = "Czech"
override val display_da: String = "Danish"
override val display_de: String = "Aleman"
override val display_el: String = "Griyego"
override val display_en: String = "Ingles"
override val display_es: String = "Espanyol"
override val display_es_MX: String = "Espanyol (Mehiko)"
override val display_et: String = "Estonian"
override val display_eu: String = "Basque"
override val display_fa: String = "Persian"
override val display_fi: String = "Finnish"
override val display_fil: String = "Pilipino"
override val display_fr: String = "Pranses"
override val display_fr_CA: String = "Pranses ng Canada"
override val display_gl: String = "Galician"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hebreo"
override val display_hi: String = "Tagalog"
override val display_hr: String = "Croatian"
override val display_hu: String = "Hungarian"
override val display_hy: String = "Armenian"
override val display_id: String = "Indonesian"
override val display_is: String = "Icelandic"
override val display_it: String = "Italyano"
override val display_ja: String = "Hapon"
override val display_ka: String = "Georgian"
override val display_kk: String = "Kazakh"
override val display_km: String = "Gitnang Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korean"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Lithuanian"
override val display_lv: String = "Latvian"
override val display_mk: String = "Macedonian"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolian"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malay"
override val display_my: String = "Burmese"
override val display_nb: String = "Norwegian Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Dutch"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polish"
override val display_pt: String = "Portuges"
override val display_pt_BR: String = "Portuges (Brazil)"
override val display_pt_PT: String = "European Portuges"
override val display_ro: String = "Romanian"
override val display_ru: String = "Russian"
override val display_si: String = "Sinhala"
override val display_sk: String = "Eslobako"
override val display_sl: String = "Eslobenya"
override val display_sq: String = "Albanian"
override val display_sr: String = "Serbian"
override val display_sv: String = "Suweko"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Turkish"
override val display_uk: String = "Ukrainian"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbek"
override val display_vi: String = "Vietnamese"
override val display_zh_Hant_TW: String = "Chinese (Taiwan, Tradisyonal)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Tantiyahin"
override val document_invoice: String = "Invoice"
override val document_quote: String = "Quote"
override val done: String = "Tapos na"
override val download_app: String = "I-download ang app"
override val download_invoice: String = "I-download ang Invoice"
override val download_pdf: String = "I-download ang PDF"
override val draw_signature: String = "Magdrowing ng lagda"
override val due: Formattable = Formattable { (date) -> "Takdang ${date}" }
override val due_date: String = "Takdang Petsa"
override val due_day: String = "sa isang araw"
override val due_label: String = "Takdang Petsa"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ay dapat bayaran ${dueWhen}!" }
override val due_today: String = "Ngayon"
override val due_week: String = "sa isang linggo"
override val duplicate: String = "Kopyahin"
override val duration_day: String = "araw"
override val duration_month: String = "buwan"
override val duration_week: String = "linggo"
override val duration_year: String = "taon"
override val email: String = "Email"
override val email_blank_error: String = "Kailangan ang email."
override val email_error: String = "Di-maaaring email address."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Salamat sa iyong negosyo. Upang tingnan ang buong ${documentType} i-click ang button sa dulo ng liham na ito. Tandaan na ito ay wasto hanggang ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Nakatanggap ka ng bago ${documentType}${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "TINGNAN ANG ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Mahal na ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Salamat sa iyong negosyo. Upang tingnan ang buong ${documentType} i-click ang button sa dulo ng liham na ito. Mangyaring bayaran ang balanse hanggang ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Nakatanggap ka ng bago ${documentType}${invoiceNumber}!" }
override val email_reset_success_message: String = "Email upang i-reset ang password na ipinadala, mangyaring suriin ang iyong inbox."
override val empty_clients_list_message: String = "Wala kang mga kliyente para subaybayan. Magdagdag ng bagong kliyente."
override val empty_estimate_list_message: String = "Lumikha ng iyong unang pagtatantya"
override val empty_expenses: String = "Wala kang gastos upang subaybayan. Magdagdag ng bagong gastos."
override val empty_invoice_list_message: String = "Lumikha ng iyong unang invoice"
override val empty_items_list_message: String = "Wala kang mga aytem, magdagdag ng bagong aytem!"
override val empty_signatures_list_message: String = "Walang natagpuang mga lagda. Lumikha ng isa!"
override val empty_taxes_list_message: String = "Wala kang mga tuntunin sa buwis. Magdagdag ng bagong tuntunin sa buwis."
override val empty_templates_list_message: String = "Hindi available ang mga template."
override val enabled: String = "Pinagana"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Tantyahin #"
override val estimates: String = "Mga Pagtatantya"
override val estimates_report: String = "Mga Pagtatantya"
override val expense_bank_fees: String = "Mga Bayad sa Bangko"
override val expense_benefits: String = "Mga Benepisyo"
override val expense_communication: String = "Komunikasyon"
override val expense_debt: String = "Utang"
override val expense_deleted: String = "Tinanggal ang gastos"
override val expense_depreciation: String = "Pagpapahalaga"
override val expense_gifts: String = "Regalo"
override val expense_insurance: String = "Seguro"
override val expense_inventory: String = "Imbentaryo"
override val expense_legal: String = "Legal"
override val expense_maintenance: String = "Pagpapanatili"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Mga pagkain"
override val expense_office_supplies: String = "Mga gamit sa Opisina"
override val expense_rent: String = "Rentahan"
override val expense_salaries: String = "Suweldo"
override val expense_shopping: String = "Pamimili"
override val expense_software: String = "Software"
override val expense_status_available: String = "Magagamit"
override val expense_status_processing: String = "Pagproseso"
override val expense_tax: String = "Buwis"
override val expense_training: String = "Pagsasanay"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Paglalakbay"
override val expense_utility: String = "Kagamitan"
override val expenses: String = "Mga gastos"
override val feedback_option: String = "Feedback"
override val filter: String = "I-filter"
override val first_name_hint: String = "Unang Pangalan"
override val forgot_password: String = "I-reset ang Password"
override val forgot_password_label: String = "Nakalimutan ang password"
override val from: String = "Mula sa"
override val general_error: String = "Naganap ang error, mangyaring subukang muli sa ibang pagkakataon."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Bumalik"
override val group_by: String = "Pangkat ayon sa:"
override val image_view: String = "Imahe"
override val install: String = "I-install"
override val invalid_credentials_error: String = "Di-maaaring mga kredensyal."
override val invalid_email_error: String = "Di-maaaring email."
override val invalid_user_error: String = "Di-maaaring mga kredensyal ng gumagamit."
override val invoice_deleted_message: String = "Invoice tinanggal"
override val invoice_menu: String = "Invoice menu"
override val invoice_number: String = "Invoice #"
override val invoices: String = "Mga Invoice"
override val items_option: String = "Mga Aytem"
override val keep: String = "Panatilihin"
override val language_selector: Formattable = Formattable { (selected) -> "Wika ${selected}" }
override val last_name_hint: String = "Apelyido"
override val ledger: String = "ledger"
override val legal: String = "Legal"
override val legal_error: String = "Kailangang tanggapin ang mga Tuntunin ng Paglilingkod at Patakaran sa Pribasidad"
override val liabilities: String = "liabilities"
override val loading: String = "Naglo-load"
override val login_required_error: String = "Kailangan ang pagpasok."
override val logo_view: String = "Logo"
override val logout: String = "Mag-logout"
override val logout_prompt: String = "Hindi naka-synchronize ang data mo sa ulap. Tanggalin ang mga invoice, pagtatantya, kliyente at iba pang impormasyon sa logout?"
override val main_screen: String = "Main screen"
override val mark_paid: String = "Markahan bilang Bayad"
override val message: String = "Mensahe"
override val missing_sender_msg: String = "Maaaring hindi wasto ang isang dokumento na walang impormasyon ng nagpadala."
override val missing_sender_title: String = "Nawawalang impormasyon ng nagpadala"
override val month_april: String = "Abril"
override val month_april_short: String = "Abril"
override val month_august: String = "Agosto"
override val month_august_short: String = "Aug."
override val month_december: String = "Disyembre"
override val month_december_short: String = "Dec."
override val month_february: String = "Pebrero"
override val month_february_short: String = "Feb."
override val month_january: String = "Enero"
override val month_january_short: String = "Jan."
override val month_july: String = "Hulyo"
override val month_july_short: String = "Hulyo"
override val month_june: String = "Hunyo"
override val month_june_short: String = "Hunyo"
override val month_march: String = "Marso"
override val month_march_short: String = "Marso"
override val month_may: String = "Mayo"
override val month_may_short: String = "May"
override val month_november: String = "Nobyembre"
override val month_november_short: String = "Nob."
override val month_october: String = "Oktubre"
override val month_october_short: String = "Oct."
override val month_september: String = "Setyembre"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Hindi na-verify."
override val new: String = "Bago"
override val news: String = "Balita"
override val next: String = "Susunod"
override val no: String = "no"
override val no_data_available: String = "Walang magagamit na data"
override val no_search_results: String = "Paumanhin, wala kaming makitang anumang resulta."
override val none: String = "Wala"
override val note_fields: String = "Mga Tala"
override val note_hint: String = "Tala"
override val notifications_option: String = "Mga Notification"
override val ok: String = "Okey lang"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Ang mga mahahalagang bahagi ng DingVoice ay libre nang walang hanggan. Ang aming misyon ay tulungan kang mapalago ang iyong negosyo, tulungan kaming mapabuti sa pamamagitan ng pagbabahagi ng iyong feedback."
override val onboarding_aboutus_title: String = "Nandito kami para tumulong"
override val onboarding_paid_1: String = "I-access ang mga template na mukhang propesyonal"
override val onboarding_paid_2: String = "Mas malalim na tingnan ang iyong mga pananalapi"
override val onboarding_paid_3: String = "Malutas ang mga problema nang mabilis"
override val onboarding_paid_4: String = "Panatilihing ligtas ang iyong mga dokumento"
override val onboarding_paid_5: String = "Manatiling napapanahon sa mga pagbabago sa status ng invoice"
override val onboarding_paid_title: String = "Mas mabilis na lumago sa..."
override val onboarding_paywall_message: String = "I-enjoy ang lahat ng premium na feature ngayon sa pinakamagandang presyo."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "${currencySymbol}${introAmount} lang para sa ${introDuration} ${introPeriod} at pagkatapos ay ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} lang / ${duration} lang" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Libre para sa ${trialDuration} ${trialPeriod} at pagkatapos ay ${currencySymbol}${amount} / ${duration} lang" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Libre para sa${trialDuration}${trialPeriod} pagkatapos${currencySymbol}${introductoryAmount} para sa${introductoryDuration}${introPeriod} at saka lang${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "Auto-renewable. Kanselahin anumang oras."
override val onboarding_paywall_restore: String = "Ibalik ang pagbili"
override val onboarding_paywall_restore_failure: String = "Nabigo ang pagpapanumbalik ng mga pagbili, subukang muli sa ibang pagkakataon o makipag-ugnayan sa suporta."
override val onboarding_paywall_restore_success: String = "Ibalik ang matagumpay!"
override val onboarding_paywall_title: String = "Makamit ang Kadakilaan!"
override val onboarding_welcome_message: String = "Isang kumpletong toolset upang pamahalaan ang iyong mga invoice at pagtatantya."
override val onboarding_welcome_title: String = "Maligayang pagdating sa DingVoice"
override val other_state: String = "Iba"
override val overdue_state: String = "Lagpas na"
override val paid_state: String = "Binayaran"
override val password: String = "Password"
override val password_blank_error: String = "Kailangan ang password."
override val password_mismatch_error: String = "Hindi magkatugma ang mga password."
override val pay: String = "pay"
override val payment_instructions_label: String = "Mga Tagubilin sa Pagbabayad"
override val payment_reminders: String = "Mga paalala sa pagbabayad"
override val phone_number_fields: String = "Numero ng Telepono"
override val phone_number_hint: String = "Numero ng Telepono"
override val postcode_hint: String = "Postcode"
override val preview: String = "Preview"
override val preview_menu_hint: String = "Menu ng Preview menu"
override val price: String = "Presyo"
override val privacy_policy: String = "Patakaran sa Pribasidad"
override val profile: String = "Profile"
override val profitable_client: String = "Ang iyong pinaka-kapaki-pakinabang na kliyente ay..."
override val profits_report: String = "Kita"
override val promo_content: String = "Promotional content"
override val quantity_hint: String = "Dami"
override val quantity_label_hint: String = "Dami label"
override val query_info: String = "Query ay nagbibigay-daan sa iyo upang sumulat ng custom calculations na tumutukoy sa parehong mga item at iba pang mga patakaran sa buwis bilang mga input. Magdagdag ng @ simbolo at item o buwis paglalarawan upang link sa isa pang entry +, -, *, / , % - matematika operator"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ay natanggap ng iyong kliyente." }
override val received_state: String = "Natanggap"
override val registration_code_fields: String = "Code ng Rehistrasyon"
override val reminder: String = "Paalala"
override val reports: String = "Mga Ulat"
override val reports_not_available: String = "Walang mga ulat na magagamit, bumalik mamaya."
override val reveal_password: String = "Maghayag ng password"
override val revenue: String = "revenue"
override val sales_tax: String = "TANGKE"
override val sales_tax_number: String = "numero ng VAT"
override val save: String = "I-save"
override val scan_document: String = "I-scan ang dokumento"
override val search: String = "Paghahanap"
override val select: String = "Piliin"
override val select_icon: String = "Piliin ang icon"
override val select_signature: String = "Pumili ng lagda"
override val select_template: String = "Pumili ng template"
override val send: String = "Ipadala"
override val sent_state: String = "Naipadala"
override val setup_sender: String = "I-set up ang nagpadala"
override val share_option: String = "Ibahagi"
override val ship_to: String = "Ipadala sa"
override val sign_in: String = "Mag-sign In"
override val sign_in_apple: String = "Mag-sign in gamit ang Apple"
override val sign_in_email: String = "Mag-sign in gamit ang email"
override val sign_in_facebook: String = "Mag-sign in gamit ang Facebook"
override val sign_in_google: String = "Mag-sign in gamit ang Google"
override val sign_up: String = "Mag-sign up"
override val sign_up_label: String = "Wala ka bang account?"
override val sign_up_link_label: String = "Mag-sign Up!"
override val signatures_option: String = "Mga Lagda"
override val skip: String = "Laktawan"
override val sold_items_report: String = "Nabenta na Mga Item"
override val sort_by: String = "Mag-ingat ayon sa:"
override val status_update: String = "Pag-update ng status ng dokumento"
override val sub_annual: String = "Taunang"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price} /taon" }
override val sub_backups: String = "Mga Backup ng Cloud"
override val sub_insights: String = "Insight"
override val sub_month: String = "Buwanang"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price} /buwan" }
override val sub_notifications: String = "Mga Notification"
override val sub_popup_message: String = "Ang mga subscription ay paparating na. Suriin pabalik dito sa isang maliit na habang."
override val sub_support: String = "Suporta"
override val sub_templates: String = "Mga Template"
override val subscribe: String = "Mag-subscribe"
override val subscription_header: String = "Palakihin ang iyong negosyo gamit ang:"
override val subscription_option: String = "Subscription"
override val subscriptions_load_fail: String = "Paumanhin, hindi namin makuha ang iyong mga subscription sa ngayon. Pakisubukang muli sa ibang pagkakataon o makipag-ugnayan sa aming team ng suporta para sa tulong."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "Subtotal"
override val sum: String = "Sum"
override val support_option: String = "Suporta"
override val switch_reports: String = "Ulat ng Lumipat"
override val tax: String = "Buwis"
override val tax_deleted_message: String = "Tax tinanggal"
override val tax_description_warning: String = "Kung ginamit ito ay nagpapakita up sa isang invoice"
override val tax_input_flat: String = "Flat"
override val tax_input_percent: String = "Porsiyento"
override val tax_number_fields: String = "Numero ng Buwis"
override val tax_number_hint: String = "Numero ng Buwis"
override val tax_query_info_description: String = "Query Info"
override val tax_report: String = "Buwis"
override val tax_rules_option: String = "Mga Tuntunin sa Buwis"
override val taxed_clients_report: String = "Mga Kliyente na Nabuwis"
override val template: String = "Template"
override val template_selector_info: String = "Mag-swipe para baguhin ang default na template"
override val template_unlock_promo: String = "Mag-access ng higit pang mga template gamit ang Premium"
override val templates_option: String = "Mga Template"
override val terms_of_service: String = "Mga Tuntunin ng Paglilingkod"
override val thank_you: String = "Salamat"
override val to: String = "Sa"
override val total: Formattable = Formattable { (sum) -> "Kabuuang: ${sum}" }
override val total_label: String = "Kabuuang"
override val undo_action: String = "Magbalik sa Dati"
override val unit: String = "Unit"
override val unit_cost_hint: String = "Gastos sa Unit"
override val unknown_client: String = "Hindi kilalang Client"
override val unlock: String = "I-unlock"
override val unnamed_item: String = "Walang pangalan na item"
override val upgrade: String = "Mag-upgrade"
override val user_collision_error: String = "Umiiral na ang akawnt."
override val valid_until: String = "Wasto Hanggang"
override val warning: String = "Babala!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Tanggapin ang ${termsOfService} at ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Imbalidong code.",
	"add_client_action" to "Magdagdag ng kliyente",
	"add_customer" to "Magdagdag ng kustomer",
	"add_expense" to "Magdagdag ng gastos",
	"add_fields_label" to "Idagdag:",
	"add_invoice_action" to "Lumikha",
	"add_item_action" to "Magdagdag ng aytem",
	"add_item_button" to "Magdagdag ng aytem",
	"add_signature_action" to "Magdagdag ng lagda",
	"add_tax_action" to "Magdagdag ng buwis",
	"address_fields" to "Adres",
	"address_line_hint" to "Linya ng Adres",
	"advanced" to "Naunang",
	"agree_marketing" to "Sumasang-ayon akong tumanggap ng Direct Marketing Communications",
	"all_notifications" to "Lahat ng Notification",
	"amortization" to "amortization",
	"amount" to "Halaga",
	"asset" to "asset",
	"attach_file" to "Ilakip ang File",
	"attachment" to "Attachment",
	"auth_error" to "Nabigo ang pagkilos, subukang muli.",
	"available_for_paid" to "Ang maulap na singk ay magagamit lamang sa ilalim ng binabayarang subscription.",
	"backups_option" to "Mga Backup",
	"balance" to "balance",
	"bank_account" to "Account sa Bangko",
	"bill_number" to "Numero ng Bill",
	"cancel" to "Kanselahin ang",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Mga Kategorya",
	"category" to "Kategorya",
	"category_add" to "Magdagdag ng kategorya",
	"category_delete" to "I-delete ang kategorya",
	"category_name" to "Pangalan ng kategorya",
	"change_language" to Formattable { (language) -> "Wika ng Invoice: ${language}" },
	"change_language_label" to "Wika ng Invoice",
	"change_template" to "Magpalit ng template",
	"city_hint" to "Lungsod",
	"clear" to "Malinaw",
	"client" to "Kliyente",
	"client_actions" to "Mga aksyong kliyente",
	"client_deleted_message" to "Kliyente tinanggal",
	"clients_error_required_fields" to "Hindi bababa sa isang halaga ang kailangan.",
	"clients_option" to "Mga Kliyente",
	"close" to "Isara",
	"cloud_sync" to "Maulap na Singk",
	"coming_soon" to "Pagdating sa lalong madaling panahon!",
	"company_logo" to "Logo ng Kumpanya",
	"company_name_hint" to "Kumpanya",
	"confirm_password_label" to "Kumpirmahin ang password",
	"confirmation_title" to "Sigurado ka ba?",
	"convert_to_invoice" to "Convert sa invoice",
	"country_hint" to "Bansa",
	"create_client" to "Lumikha ng Kliyente",
	"create_company" to "Lumikha ng Kumpanya",
	"create_document" to "Lumikha ng dokumento",
	"create_estimate" to "Tantiyahin",
	"create_invoice" to "Invoice",
	"create_item" to "Lumikha ng Aytem",
	"create_new_client" to "Lumikha ng bagong kliyente",
	"create_new_item" to "Lumikha ng bagong aytem",
	"create_new_tax" to "Lumikha ng bagong buwis",
	"create_signature" to "Lagda",
	"created" to "Nilikha",
	"credit" to "credit",
	"customer" to "Customer",
	"date" to "Petsa",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Default na pera: ${currency}" },
	"default_currency_label" to "Default na pera",
	"delete" to "Magtanggal",
	"delete_account" to "Tanggalin ang Account",
	"delete_confirmation_message" to "Mawawala ang lahat ng iyong data.",
	"delete_item_title" to "Magtanggal ng aytem?",
	"delete_signature" to "Magtanggal ng lagda",
	"depreciation" to "depreciation",
	"description" to "Paglalarawan",
	"description_hint" to "Deskrip",
	"detailed_report" to "Detalyadong Ulat",
	"disabled" to "Huwag paganahin ang",
	"discount" to "Diskwento",
	"discount_hint" to "Diskuwento",
	"display_af" to "Afrikaans",
	"display_am" to "Amharic",
	"display_ar" to "Arabic",
	"display_as" to "Assamese",
	"display_az" to "Azerbaijani",
	"display_be" to "Belarusian",
	"display_bg" to "Bulgarian",
	"display_bn" to "Bengali",
	"display_bs" to "Bosnian",
	"display_ca" to "Catalan",
	"display_cs" to "Czech",
	"display_da" to "Danish",
	"display_de" to "Aleman",
	"display_el" to "Griyego",
	"display_en" to "Ingles",
	"display_es" to "Espanyol",
	"display_es_MX" to "Espanyol (Mehiko)",
	"display_et" to "Estonian",
	"display_eu" to "Basque",
	"display_fa" to "Persian",
	"display_fi" to "Finnish",
	"display_fil" to "Pilipino",
	"display_fr" to "Pranses",
	"display_fr_CA" to "Pranses ng Canada",
	"display_gl" to "Galician",
	"display_gu" to "Gujarati",
	"display_he" to "Hebreo",
	"display_hi" to "Tagalog",
	"display_hr" to "Croatian",
	"display_hu" to "Hungarian",
	"display_hy" to "Armenian",
	"display_id" to "Indonesian",
	"display_is" to "Icelandic",
	"display_it" to "Italyano",
	"display_ja" to "Hapon",
	"display_ka" to "Georgian",
	"display_kk" to "Kazakh",
	"display_km" to "Gitnang Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Korean",
	"display_ky" to "Kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Lithuanian",
	"display_lv" to "Latvian",
	"display_mk" to "Macedonian",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolian",
	"display_mr" to "Marathi",
	"display_ms" to "Malay",
	"display_my" to "Burmese",
	"display_nb" to "Norwegian Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Dutch",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polish",
	"display_pt" to "Portuges",
	"display_pt_BR" to "Portuges (Brazil)",
	"display_pt_PT" to "European Portuges",
	"display_ro" to "Romanian",
	"display_ru" to "Russian",
	"display_si" to "Sinhala",
	"display_sk" to "Eslobako",
	"display_sl" to "Eslobenya",
	"display_sq" to "Albanian",
	"display_sr" to "Serbian",
	"display_sv" to "Suweko",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Turkish",
	"display_uk" to "Ukrainian",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbek",
	"display_vi" to "Vietnamese",
	"display_zh_Hant_TW" to "Chinese (Taiwan, Tradisyonal)",
	"display_zu" to "Zulu",
	"document_estimate" to "Tantiyahin",
	"document_invoice" to "Invoice",
	"document_quote" to "Quote",
	"done" to "Tapos na",
	"download_app" to "I-download ang app",
	"download_invoice" to "I-download ang Invoice",
	"download_pdf" to "I-download ang PDF",
	"draw_signature" to "Magdrowing ng lagda",
	"due" to Formattable { (date) -> "Takdang ${date}" },
	"due_date" to "Takdang Petsa",
	"due_day" to "sa isang araw",
	"due_label" to "Takdang Petsa",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ay dapat bayaran ${dueWhen}!" },
	"due_today" to "Ngayon",
	"due_week" to "sa isang linggo",
	"duplicate" to "Kopyahin",
	"duration_day" to "araw",
	"duration_month" to "buwan",
	"duration_week" to "linggo",
	"duration_year" to "taon",
	"email" to "Email",
	"email_blank_error" to "Kailangan ang email.",
	"email_error" to "Di-maaaring email address.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Salamat sa iyong negosyo. Upang tingnan ang buong ${documentType} i-click ang button sa dulo ng liham na ito. Tandaan na ito ay wasto hanggang ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Nakatanggap ka ng bago ${documentType}${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "TINGNAN ANG ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Mahal na ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Salamat sa iyong negosyo. Upang tingnan ang buong ${documentType} i-click ang button sa dulo ng liham na ito. Mangyaring bayaran ang balanse hanggang ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Nakatanggap ka ng bago ${documentType}${invoiceNumber}!" },
	"email_reset_success_message" to "Email upang i-reset ang password na ipinadala, mangyaring suriin ang iyong inbox.",
	"empty_clients_list_message" to "Wala kang mga kliyente para subaybayan. Magdagdag ng bagong kliyente.",
	"empty_estimate_list_message" to "Lumikha ng iyong unang pagtatantya",
	"empty_expenses" to "Wala kang gastos upang subaybayan. Magdagdag ng bagong gastos.",
	"empty_invoice_list_message" to "Lumikha ng iyong unang invoice",
	"empty_items_list_message" to "Wala kang mga aytem, magdagdag ng bagong aytem!",
	"empty_signatures_list_message" to "Walang natagpuang mga lagda. Lumikha ng isa!",
	"empty_taxes_list_message" to "Wala kang mga tuntunin sa buwis. Magdagdag ng bagong tuntunin sa buwis.",
	"empty_templates_list_message" to "Hindi available ang mga template.",
	"enabled" to "Pinagana",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Tantyahin #",
	"estimates" to "Mga Pagtatantya",
	"estimates_report" to "Mga Pagtatantya",
	"expense_bank_fees" to "Mga Bayad sa Bangko",
	"expense_benefits" to "Mga Benepisyo",
	"expense_communication" to "Komunikasyon",
	"expense_debt" to "Utang",
	"expense_deleted" to "Tinanggal ang gastos",
	"expense_depreciation" to "Pagpapahalaga",
	"expense_gifts" to "Regalo",
	"expense_insurance" to "Seguro",
	"expense_inventory" to "Imbentaryo",
	"expense_legal" to "Legal",
	"expense_maintenance" to "Pagpapanatili",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Mga pagkain",
	"expense_office_supplies" to "Mga gamit sa Opisina",
	"expense_rent" to "Rentahan",
	"expense_salaries" to "Suweldo",
	"expense_shopping" to "Pamimili",
	"expense_software" to "Software",
	"expense_status_available" to "Magagamit",
	"expense_status_processing" to "Pagproseso",
	"expense_tax" to "Buwis",
	"expense_training" to "Pagsasanay",
	"expense_transport" to "Transport",
	"expense_travel" to "Paglalakbay",
	"expense_utility" to "Kagamitan",
	"expenses" to "Mga gastos",
	"feedback_option" to "Feedback",
	"filter" to "I-filter",
	"first_name_hint" to "Unang Pangalan",
	"forgot_password" to "I-reset ang Password",
	"forgot_password_label" to "Nakalimutan ang password",
	"from" to "Mula sa",
	"general_error" to "Naganap ang error, mangyaring subukang muli sa ibang pagkakataon.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Bumalik",
	"group_by" to "Pangkat ayon sa:",
	"image_view" to "Imahe",
	"install" to "I-install",
	"invalid_credentials_error" to "Di-maaaring mga kredensyal.",
	"invalid_email_error" to "Di-maaaring email.",
	"invalid_user_error" to "Di-maaaring mga kredensyal ng gumagamit.",
	"invoice_deleted_message" to "Invoice tinanggal",
	"invoice_menu" to "Invoice menu",
	"invoice_number" to "Invoice #",
	"invoices" to "Mga Invoice",
	"items_option" to "Mga Aytem",
	"keep" to "Panatilihin",
	"language_selector" to Formattable { (selected) -> "Wika ${selected}" },
	"last_name_hint" to "Apelyido",
	"ledger" to "ledger",
	"legal" to "Legal",
	"legal_error" to "Kailangang tanggapin ang mga Tuntunin ng Paglilingkod at Patakaran sa Pribasidad",
	"liabilities" to "liabilities",
	"loading" to "Naglo-load",
	"login_required_error" to "Kailangan ang pagpasok.",
	"logo_view" to "Logo",
	"logout" to "Mag-logout",
	"logout_prompt" to "Hindi naka-synchronize ang data mo sa ulap. Tanggalin ang mga invoice, pagtatantya, kliyente at iba pang impormasyon sa logout?",
	"main_screen" to "Main screen",
	"mark_paid" to "Markahan bilang Bayad",
	"message" to "Mensahe",
	"missing_sender_msg" to "Maaaring hindi wasto ang isang dokumento na walang impormasyon ng nagpadala.",
	"missing_sender_title" to "Nawawalang impormasyon ng nagpadala",
	"month_april" to "Abril",
	"month_april_short" to "Abril",
	"month_august" to "Agosto",
	"month_august_short" to "Aug.",
	"month_december" to "Disyembre",
	"month_december_short" to "Dec.",
	"month_february" to "Pebrero",
	"month_february_short" to "Feb.",
	"month_january" to "Enero",
	"month_january_short" to "Jan.",
	"month_july" to "Hulyo",
	"month_july_short" to "Hulyo",
	"month_june" to "Hunyo",
	"month_june_short" to "Hunyo",
	"month_march" to "Marso",
	"month_march_short" to "Marso",
	"month_may" to "Mayo",
	"month_may_short" to "May",
	"month_november" to "Nobyembre",
	"month_november_short" to "Nob.",
	"month_october" to "Oktubre",
	"month_october_short" to "Oct.",
	"month_september" to "Setyembre",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Hindi na-verify.",
	"new" to "Bago",
	"news" to "Balita",
	"next" to "Susunod",
	"no" to "no",
	"no_data_available" to "Walang magagamit na data",
	"no_search_results" to "Paumanhin, wala kaming makitang anumang resulta.",
	"none" to "Wala",
	"note_fields" to "Mga Tala",
	"note_hint" to "Tala",
	"notifications_option" to "Mga Notification",
	"ok" to "Okey lang",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Ang mga mahahalagang bahagi ng DingVoice ay libre nang walang hanggan. Ang aming misyon ay tulungan kang mapalago ang iyong negosyo, tulungan kaming mapabuti sa pamamagitan ng pagbabahagi ng iyong feedback.",
	"onboarding_aboutus_title" to "Nandito kami para tumulong",
	"onboarding_paid_1" to "I-access ang mga template na mukhang propesyonal",
	"onboarding_paid_2" to "Mas malalim na tingnan ang iyong mga pananalapi",
	"onboarding_paid_3" to "Malutas ang mga problema nang mabilis",
	"onboarding_paid_4" to "Panatilihing ligtas ang iyong mga dokumento",
	"onboarding_paid_5" to "Manatiling napapanahon sa mga pagbabago sa status ng invoice",
	"onboarding_paid_title" to "Mas mabilis na lumago sa...",
	"onboarding_paywall_message" to "I-enjoy ang lahat ng premium na feature ngayon sa pinakamagandang presyo.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "${currencySymbol}${introAmount} lang para sa ${introDuration} ${introPeriod} at pagkatapos ay ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} lang / ${duration} lang" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Libre para sa ${trialDuration} ${trialPeriod} at pagkatapos ay ${currencySymbol}${amount} / ${duration} lang" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Libre para sa${trialDuration}${trialPeriod} pagkatapos${currencySymbol}${introductoryAmount} para sa${introductoryDuration}${introPeriod} at saka lang${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "Auto-renewable. Kanselahin anumang oras.",
	"onboarding_paywall_restore" to "Ibalik ang pagbili",
	"onboarding_paywall_restore_failure" to "Nabigo ang pagpapanumbalik ng mga pagbili, subukang muli sa ibang pagkakataon o makipag-ugnayan sa suporta.",
	"onboarding_paywall_restore_success" to "Ibalik ang matagumpay!",
	"onboarding_paywall_title" to "Makamit ang Kadakilaan!",
	"onboarding_welcome_message" to "Isang kumpletong toolset upang pamahalaan ang iyong mga invoice at pagtatantya.",
	"onboarding_welcome_title" to "Maligayang pagdating sa DingVoice",
	"other_state" to "Iba",
	"overdue_state" to "Lagpas na",
	"paid_state" to "Binayaran",
	"password" to "Password",
	"password_blank_error" to "Kailangan ang password.",
	"password_mismatch_error" to "Hindi magkatugma ang mga password.",
	"pay" to "pay",
	"payment_instructions_label" to "Mga Tagubilin sa Pagbabayad",
	"payment_reminders" to "Mga paalala sa pagbabayad",
	"phone_number_fields" to "Numero ng Telepono",
	"phone_number_hint" to "Numero ng Telepono",
	"postcode_hint" to "Postcode",
	"preview" to "Preview",
	"preview_menu_hint" to "Menu ng Preview menu",
	"price" to "Presyo",
	"privacy_policy" to "Patakaran sa Pribasidad",
	"profile" to "Profile",
	"profitable_client" to "Ang iyong pinaka-kapaki-pakinabang na kliyente ay...",
	"profits_report" to "Kita",
	"promo_content" to "Promotional content",
	"quantity_hint" to "Dami",
	"quantity_label_hint" to "Dami label",
	"query_info" to "Query ay nagbibigay-daan sa iyo upang sumulat ng custom calculations na tumutukoy sa parehong mga item at iba pang mga patakaran sa buwis bilang mga input. Magdagdag ng @ simbolo at item o buwis paglalarawan upang link sa isa pang entry +, -, *, / , % - matematika operator",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ay natanggap ng iyong kliyente." },
	"received_state" to "Natanggap",
	"registration_code_fields" to "Code ng Rehistrasyon",
	"reminder" to "Paalala",
	"reports" to "Mga Ulat",
	"reports_not_available" to "Walang mga ulat na magagamit, bumalik mamaya.",
	"reveal_password" to "Maghayag ng password",
	"revenue" to "revenue",
	"sales_tax" to "TANGKE",
	"sales_tax_number" to "numero ng VAT",
	"save" to "I-save",
	"scan_document" to "I-scan ang dokumento",
	"search" to "Paghahanap",
	"select" to "Piliin",
	"select_icon" to "Piliin ang icon",
	"select_signature" to "Pumili ng lagda",
	"select_template" to "Pumili ng template",
	"send" to "Ipadala",
	"sent_state" to "Naipadala",
	"setup_sender" to "I-set up ang nagpadala",
	"share_option" to "Ibahagi",
	"ship_to" to "Ipadala sa",
	"sign_in" to "Mag-sign In",
	"sign_in_apple" to "Mag-sign in gamit ang Apple",
	"sign_in_email" to "Mag-sign in gamit ang email",
	"sign_in_facebook" to "Mag-sign in gamit ang Facebook",
	"sign_in_google" to "Mag-sign in gamit ang Google",
	"sign_up" to "Mag-sign up",
	"sign_up_label" to "Wala ka bang account?",
	"sign_up_link_label" to "Mag-sign Up!",
	"signatures_option" to "Mga Lagda",
	"skip" to "Laktawan",
	"sold_items_report" to "Nabenta na Mga Item",
	"sort_by" to "Mag-ingat ayon sa:",
	"status_update" to "Pag-update ng status ng dokumento",
	"sub_annual" to "Taunang",
	"sub_annual_price" to Formattable { (price) -> "${price} /taon" },
	"sub_backups" to "Mga Backup ng Cloud",
	"sub_insights" to "Insight",
	"sub_month" to "Buwanang",
	"sub_monthly_price" to Formattable { (price) -> "${price} /buwan" },
	"sub_notifications" to "Mga Notification",
	"sub_popup_message" to "Ang mga subscription ay paparating na. Suriin pabalik dito sa isang maliit na habang.",
	"sub_support" to "Suporta",
	"sub_templates" to "Mga Template",
	"subscribe" to "Mag-subscribe",
	"subscription_header" to "Palakihin ang iyong negosyo gamit ang:",
	"subscription_option" to "Subscription",
	"subscriptions_load_fail" to "Paumanhin, hindi namin makuha ang iyong mga subscription sa ngayon. Pakisubukang muli sa ibang pagkakataon o makipag-ugnayan sa aming team ng suporta para sa tulong.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "Subtotal",
	"sum" to "Sum",
	"support_option" to "Suporta",
	"switch_reports" to "Ulat ng Lumipat",
	"tax" to "Buwis",
	"tax_deleted_message" to "Tax tinanggal",
	"tax_description_warning" to "Kung ginamit ito ay nagpapakita up sa isang invoice",
	"tax_input_flat" to "Flat",
	"tax_input_percent" to "Porsiyento",
	"tax_number_fields" to "Numero ng Buwis",
	"tax_number_hint" to "Numero ng Buwis",
	"tax_query_info_description" to "Query Info",
	"tax_report" to "Buwis",
	"tax_rules_option" to "Mga Tuntunin sa Buwis",
	"taxed_clients_report" to "Mga Kliyente na Nabuwis",
	"template" to "Template",
	"template_selector_info" to "Mag-swipe para baguhin ang default na template",
	"template_unlock_promo" to "Mag-access ng higit pang mga template gamit ang Premium",
	"templates_option" to "Mga Template",
	"terms_of_service" to "Mga Tuntunin ng Paglilingkod",
	"thank_you" to "Salamat",
	"to" to "Sa",
	"total" to Formattable { (sum) -> "Kabuuang: ${sum}" },
	"total_label" to "Kabuuang",
	"undo_action" to "Magbalik sa Dati",
	"unit" to "Unit",
	"unit_cost_hint" to "Gastos sa Unit",
	"unknown_client" to "Hindi kilalang Client",
	"unlock" to "I-unlock",
	"unnamed_item" to "Walang pangalan na item",
	"upgrade" to "Mag-upgrade",
	"user_collision_error" to "Umiiral na ang akawnt.",
	"valid_until" to "Wasto Hanggang",
	"warning" to "Babala!",
	"yes" to "yes",
    )
    
}