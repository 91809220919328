package com.dingvoice.content

import androidx.compose.runtime.Composable
import com.dingvoice.style.AppCSSVariables
import com.dingvoice.style.WtCols
import com.dingvoice.style.WtContainer
import com.dingvoice.style.WtRows
import com.dingvoice.style.WtSections
import com.dingvoice.style.WtTexts
import com.dingvoice.style.WtTexts.wtH1
import kotlinx.browser.window
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.maxHeight
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Section
import org.jetbrains.compose.web.dom.Text

@Composable
fun Header() {
    Section(attrs = {
        classes(WtSections.wtSectionBgBrand)
    }) {
        Div({ classes(WtContainer.wtContainer) }) {
            Div({
                classes(WtRows.wtRow, WtRows.wtRowSizeM)
            }) {
                Logo()
            }
        }
    }
}

@Composable
fun HeaderCentered() {
    Section(attrs = {
        classes(WtSections.wtSectionBgBrand)
    }) {
        Div({ classes(WtContainer.wtContainer) }) {
            Div({
                classes(WtCols.wtColAutoFill, WtCols.wtColMd10)
            }) {
                Logo(isVertical = true)
            }
        }
    }
}

@Composable
private fun Logo(isVertical: Boolean = false) {
    Div(attrs = {
        classes(WtCols.wtColInline)
        style {
            AppCSSVariables.wtColCount(0)
            width(100.percent)
            display(DisplayStyle.Flex)
            property("flex-basis", "auto")
            if (isVertical) {
                property("flex-direction", "column")
                property("justify-content", "center")
            }
            property("align-items", "center")
        }
    }) {
        Img("ic_splash.png", "DingVoice", attrs = {
            style {
                maxWidth(52.px)
                maxHeight(52.px)
                width(auto)
                height(auto)
                padding(8.px)
            }
        })

        H1(attrs = {
            classes(wtH1)
            style {
                Color("#27282c")
            }
        }) {
            Text("DingVoice")
        }
    }
}

@Composable
private fun LanguageButton() {
    Div(attrs = {
        classes(WtCols.wtColInline)
    }) {
        Button(attrs = {
            classes(WtTexts.wtButton, WtTexts.wtLangButton)
            onClick { window.alert("Oops! This feature is yet to be implemented") }
        }) {
            Img(src = "ic_lang.svg", attrs = {
                style {
                    paddingLeft(8.px)
                    paddingRight(8.px)
                }
            })
            Text("English")
        }
    }
}
