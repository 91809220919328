// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Af  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Aanvaar ${termsOfService} en ${privacyPolicy}" }
override val account: String = "rekening"
override val accounting: String = "rekeningkunde"
override val accruals: String = "aanwas"
override val action_code_error: String = "Ongeldige kode."
override val add_client_action: String = "Voeg kliënt by"
override val add_customer: String = "Voeg kliënt by"
override val add_expense: String = "Voeg uitgawes by"
override val add_fields_label: String = "Voeg:"
override val add_invoice_action: String = "Skep"
override val add_item_action: String = "Voeg item by"
override val add_item_button: String = "Voeg item by"
override val add_signature_action: String = "Voeg handtekening by"
override val add_tax_action: String = "Voeg belasting by"
override val address_fields: String = "Adres"
override val address_line_hint: String = "Adreslyn"
override val advanced: String = "Gevorderde"
override val agree_marketing: String = "Ek stem in om direkte bemarkingskommunikasie te ontvang"
override val all_notifications: String = "Alle kennisgewings"
override val amortization: String = "amortisasie"
override val amount: String = "Bedrag"
override val asset: String = "bate"
override val attach_file: String = "Heg Lêer aan"
override val attachment: String = "Aanhangsel"
override val auth_error: String = "Aksie het misluk, probeer weer."
override val available_for_paid: String = "Wolk sinchro is slegs beskikbaar onder betaalde intekening."
override val backups_option: String = "Rugsteune"
override val balance: String = "saldo"
override val bank_account: String = "Bankrekening"
override val bill_number: String = "Rekeningnommer"
override val cancel: String = "KANSELLEER"
override val cancel_trial: String = "Kanselleer te eniger tyd"
override val capital: String = "kapitaal"
override val cash: String = "kontant"
override val categories: String = "Kategorieë"
override val category: String = "Kategorie"
override val category_add: String = "Voeg Kategorie by"
override val category_delete: String = "Vee kategorie uit"
override val category_name: String = "Kategorienaam"
override val change_language: Formattable = Formattable { (language) -> "Faktuurtaal: ${language}" }
override val change_language_label: String = "Faktuur Taal"
override val change_template: String = "Verander sjabloon"
override val city_hint: String = "Stad"
override val clear: String = "Duidelik"
override val client: String = "Kliënt"
override val client_actions: String = "Kliënt aksies"
override val client_deleted_message: String = "Kliënt geskrap"
override val clients_error_required_fields: String = "Ten minste een waarde is nodig."
override val clients_option: String = "Kliënte"
override val close: String = "Sluit"
override val cloud_sync: String = "Wolk sinchro"
override val coming_soon: String = "Kom binnekort!"
override val company_logo: String = "Maatskappy Logo"
override val company_name_hint: String = "Maatskappy"
override val confirm_password_label: String = "Bevestig wagwoord"
override val confirmation_title: String = "Is u seker?"
override val convert_to_invoice: String = "Skakel om na faktuur"
override val country_hint: String = "Land"
override val create_client: String = "Skep Kliënt"
override val create_company: String = "Skep Maatskappy"
override val create_document: String = "Skep dokument"
override val create_estimate: String = "Skatting"
override val create_invoice: String = "Faktuur"
override val create_item: String = "Skep item"
override val create_new_client: String = "Skep nuwe kliënt"
override val create_new_item: String = "Skep nuwe item"
override val create_new_tax: String = "Skep nuwe belasting"
override val create_signature: String = "Handtekening"
override val created: String = "Geskep"
override val credit: String = "krediet"
override val customer: String = "Kliënt"
override val date: String = "Datum"
override val debit: String = "debiet"
override val debt: String = "skuld"
override val default_currency: Formattable = Formattable { (currency) -> "Verstek geldeenheid: ${currency}" }
override val default_currency_label: String = "Verstek geldeenheid"
override val delete: String = "Skrap"
override val delete_account: String = "Uitvee Rekening"
override val delete_confirmation_message: String = "Al u data sal verlore gaan."
override val delete_item_title: String = "Skrap die item?"
override val delete_signature: String = "Skrap handtekening"
override val depreciation: String = "waardevermindering"
override val description: String = "Beskrywing"
override val description_hint: String = "Beskrywing"
override val detailed_report: String = "Gedetailleerde Verslag"
override val disabled: String = "Gedeaktiveer"
override val discount: String = "Afslag"
override val discount_hint: String = "Afslag"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharies"
override val display_ar: String = "Arabies"
override val display_as: String = "Assamees"
override val display_az: String = "Azerbeidjans"
override val display_be: String = "Belarussiese"
override val display_bg: String = "Bulgaarse"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosniese"
override val display_ca: String = "Katalonië"
override val display_cs: String = "Tsjeggië"
override val display_da: String = "Deens"
override val display_de: String = "Duitse"
override val display_el: String = "Grieks"
override val display_en: String = "Engels"
override val display_es: String = "Spaans"
override val display_es_MX: String = "Spaans (Mexiko)"
override val display_et: String = "Estoniaanse"
override val display_eu: String = "Baskeland"
override val display_fa: String = "Persiese"
override val display_fi: String = "Finse"
override val display_fil: String = "Fillipyne"
override val display_fr: String = "Franse"
override val display_fr_CA: String = "Kanadese Frans"
override val display_gl: String = "Galicies"
override val display_gu: String = "Goedjarati"
override val display_he: String = "Hebreeus"
override val display_hi: String = "Hindi"
override val display_hr: String = "Kroatiese"
override val display_hu: String = "Hongaars"
override val display_hy: String = "Armeense"
override val display_id: String = "Indonesiese"
override val display_is: String = "Yslandse"
override val display_it: String = "Italiaans"
override val display_ja: String = "Japannese"
override val display_ka: String = "Georgiaanse"
override val display_kk: String = "Kazak"
override val display_km: String = "Sentraal Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Koreaanse"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Litause"
override val display_lv: String = "Lettiese"
override val display_mk: String = "Masedoniese"
override val display_ml: String = "Malajalam"
override val display_mn: String = "Mongoolse"
override val display_mr: String = "Marathi"
override val display_ms: String = "Maleisiese"
override val display_my: String = "Birmaanse"
override val display_nb: String = "Noorse Bokmål"
override val display_ne: String = "Nepalees"
override val display_nl: String = "Hollands"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Pools"
override val display_pt: String = "Portugese"
override val display_pt_BR: String = "Portugees (Brasilië)"
override val display_pt_PT: String = "Europese Portugees"
override val display_ro: String = "Roemeense"
override val display_ru: String = "Russiese"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slowakye"
override val display_sl: String = "Sloweense"
override val display_sq: String = "Albaniese"
override val display_sr: String = "Serwiese"
override val display_sv: String = "Sweeds"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Teloegoe"
override val display_th: String = "Thai"
override val display_tr: String = "Turkse"
override val display_uk: String = "Oekraïense"
override val display_ur: String = "Oerdoe"
override val display_uz: String = "Oesbeekse"
override val display_vi: String = "Viëtnamese"
override val display_zh_Hant_TW: String = "Chinees (Taiwan, Traditioneel)"
override val display_zu: String = "Zoeloe"
override val document_estimate: String = "Skatting"
override val document_invoice: String = "Faktuur"
override val document_quote: String = "Prys aanbod"
override val done: String = "Klaar"
override val download_app: String = "Laai die toepassing af"
override val download_invoice: String = "Laai Faktuur af"
override val download_pdf: String = "Laai PDF af"
override val draw_signature: String = "Teken 'n handtekening"
override val due: Formattable = Formattable { (date) -> "As gevolg van ${date}" }
override val due_date: String = "Sperdatum"
override val due_day: String = "in 'n dag"
override val due_label: String = "As gevolg van"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} is verskuldig ${dueWhen}!" }
override val due_today: String = "vandag"
override val due_week: String = "binne `n week"
override val duplicate: String = "Duplikaat"
override val duration_day: String = "dag"
override val duration_month: String = "Maand"
override val duration_week: String = "week"
override val duration_year: String = "jaar"
override val email: String = "E-pos"
override val email_blank_error: String = "E-pos benodig."
override val email_error: String = "Ongeldige e-posadres."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Dankie vir jou besigheid. Om die volledige ${documentType} te sien, klik op die knoppie aan die einde van hierdie brief. Let wel dit is geldig tot ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Jy het 'n nuwe ${documentType} ${invoiceNumber} ontvang!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "BEKYK ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Geagte ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Dankie vir jou besigheid. Om die volledige ${documentType} te sien, klik op die knoppie aan die einde van hierdie brief. Betaal asseblief die saldo tot ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Jy het 'n nuwe ${documentType} ${invoiceNumber} ontvang!" }
override val email_reset_success_message: String = "E-pos om wagwoord wat gestuur is, terug te stel, gaan asseblief jou inkassie na."
override val empty_clients_list_message: String = "Jy het geen kliënte om op te spoor nie. Voeg 'n nuwe kliënt by."
override val empty_estimate_list_message: String = "Skep jou eerste skatting"
override val empty_expenses: String = "Jy het geen uitgawes om op te spoor nie. Voeg 'n nuwe uitgawe by."
override val empty_invoice_list_message: String = "Skep jou eerste faktuur"
override val empty_items_list_message: String = "Jy het geen items nie, voeg 'n nuwe item by!"
override val empty_signatures_list_message: String = "Geen handtekeninge gevind nie. Skep een!"
override val empty_taxes_list_message: String = "Jy het geen belastingreëls nie. Voeg 'n nuwe belastingreël by."
override val empty_templates_list_message: String = "Templates is nie beskikbaar nie."
override val enabled: String = "Geaktiveer"
override val entry: String = "inskrywing"
override val equity: String = "billikheid"
override val estimate_number: String = "Skat #"
override val estimates: String = "Ramings"
override val estimates_report: String = "Ramings"
override val expense_bank_fees: String = "Bankfooie"
override val expense_benefits: String = "Voordele"
override val expense_communication: String = "Kommunikasie"
override val expense_debt: String = "Skuld"
override val expense_deleted: String = "Uitgawes verwyder"
override val expense_depreciation: String = "Waardevermindering"
override val expense_gifts: String = "Geskenke"
override val expense_insurance: String = "Versekering"
override val expense_inventory: String = "Inventaris"
override val expense_legal: String = "Wettige"
override val expense_maintenance: String = "Onderhoud"
override val expense_marketing: String = "Bemarking"
override val expense_meals: String = "Etes"
override val expense_office_supplies: String = "Kantoor Voorrade"
override val expense_rent: String = "Huur"
override val expense_salaries: String = "Salarisse"
override val expense_shopping: String = "Inkopies"
override val expense_software: String = "Sagteware"
override val expense_status_available: String = "Beskikbaar"
override val expense_status_processing: String = "Verwerking"
override val expense_tax: String = "Belasting"
override val expense_training: String = "Opleiding"
override val expense_transport: String = "Vervoer"
override val expense_travel: String = "Reis"
override val expense_utility: String = "Nut"
override val expenses: String = "Uitgawes"
override val feedback_option: String = "Terugvoer"
override val filter: String = "Filter"
override val first_name_hint: String = "Eerste naam"
override val forgot_password: String = "Stel wagwoord terug"
override val forgot_password_label: String = "Vergeet wagwoord"
override val from: String = "Van"
override val general_error: String = "Fout het voorgekom, probeer asseblief weer later."
override val general_ledger: String = "algemene grootboek"
override val go_back_label: String = "Gaan terug"
override val group_by: String = "Groep deur:"
override val image_view: String = "Beeld"
override val install: String = "Installeer"
override val invalid_credentials_error: String = "Ongeldige verwysings."
override val invalid_email_error: String = "Ongeldige e-pos."
override val invalid_user_error: String = "Ongeldige gebruikersverwysings."
override val invoice_deleted_message: String = "Faktuur geskrap"
override val invoice_menu: String = "Faktuurkieslys"
override val invoice_number: String = "Faktuur #"
override val invoices: String = "Fakture"
override val items_option: String = "Items"
override val keep: String = "Hou"
override val language_selector: Formattable = Formattable { (gekies) -> "Taal ${gekies}" }
override val last_name_hint: String = "Laaste naam"
override val ledger: String = "grootboek"
override val legal: String = "Wettige"
override val legal_error: String = "Moet diensbepalings en privaatheidsbeleid aanvaar"
override val liabilities: String = "laste"
override val loading: String = "Laai tans"
override val login_required_error: String = "Aanmelding benodig."
override val logo_view: String = "Logo"
override val logout: String = "Logout"
override val logout_prompt: String = "Jou data word nie met wolk gesinchroniseer nie. Skrap fakture, ramings, kliënte en ander inligting oor logout?"
override val main_screen: String = "Hoofskerm"
override val mark_paid: String = "Merk as betaal"
override val message: String = "Boodskap"
override val missing_sender_msg: String = "'N Dokument sonder sender inligting kan ongeldig wees."
override val missing_sender_title: String = "Vermiste sender inligting"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "Augustus"
override val month_august_short: String = "Aug."
override val month_december: String = "Desember"
override val month_december_short: String = "Desember."
override val month_february: String = "Februarie"
override val month_february_short: String = "Februarie."
override val month_january: String = "Januarie"
override val month_january_short: String = "Jan."
override val month_july: String = "Julie"
override val month_july_short: String = "Julie"
override val month_june: String = "Junie"
override val month_june_short: String = "Junie"
override val month_march: String = "Maart"
override val month_march_short: String = "Maart"
override val month_may: String = "Mei"
override val month_may_short: String = "Mei"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktober"
override val month_october_short: String = "Okt."
override val month_september: String = "September"
override val month_september_short: String = "September."
override val multifactor_error: String = "Kon nie verifieer nie."
override val new: String = "Nuut"
override val news: String = "Nuus"
override val next: String = "Volgende"
override val no: String = "nee"
override val no_data_available: String = "Geen data beskikbaar nie"
override val no_search_results: String = "Jammer, ons kon geen resultate vind nie."
override val none: String = "Geen"
override val note_fields: String = "Notas"
override val note_hint: String = "Nota"
override val notifications_option: String = "Kennisgewings"
override val ok: String = "OUKEI"
override val onboarding: String = "Op instap"
override val onboarding_aboutus_message: String = "Noodsaaklike dele van DingVoice is vir ewig gratis. Ons missie is om jou te help om jou besigheid te laat groei, ons te help verbeter deur jou terugvoer te deel."
override val onboarding_aboutus_title: String = "Ons is hier om te help"
override val onboarding_paid_1: String = "Toegang tot professionele sjablone"
override val onboarding_paid_2: String = "Kyk dieper na jou finansies"
override val onboarding_paid_3: String = "Kry probleme vinnig opgelos"
override val onboarding_paid_4: String = "Hou jou dokumente veilig"
override val onboarding_paid_5: String = "Bly op hoogte van faktuurstatusveranderinge"
override val onboarding_paid_title: String = "Groei selfs vinniger met..."
override val onboarding_paywall_message: String = "Geniet nou alle premium kenmerke teen die beste prys."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Net ${currencySymbol}${introAmount} vir ${introDuration} ${introPeriod} en dan ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Net ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis vir ${trialDuration} ${trialPeriod} en dan net ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis vir${trialDuration}${trialPeriod} dan${currencySymbol}${introductoryAmount} vir${introductoryDuration}${introPeriod} en dan net${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "Outo-hernubaar. Kanselleer enige tyd."
override val onboarding_paywall_restore: String = "Herstel aankoop"
override val onboarding_paywall_restore_failure: String = "Die herstel van aankope het misluk, probeer later weer of kontak steundiens."
override val onboarding_paywall_restore_success: String = "Herstel suksesvol!"
override val onboarding_paywall_title: String = "Bereik Grootheid!"
override val onboarding_welcome_message: String = "&#39;n Volledige gereedskapstel om u fakture en skattings te bestuur."
override val onboarding_welcome_title: String = "Welkom by DingVoice"
override val other_state: String = "Ander"
override val overdue_state: String = "Agterstallige"
override val paid_state: String = "Betaal"
override val password: String = "Wagwoord"
override val password_blank_error: String = "Wagwoord benodig."
override val password_mismatch_error: String = "Wagwoorde stem nie ooreen nie."
override val pay: String = "betaal"
override val payment_instructions_label: String = "Betalingsinstruksies"
override val payment_reminders: String = "Betaling herinnerings"
override val phone_number_fields: String = "Telefoonnommer"
override val phone_number_hint: String = "Telefoonnommer"
override val postcode_hint: String = "Poskode"
override val preview: String = "Voorskou"
override val preview_menu_hint: String = "Voorskou kieslys"
override val price: String = "Prys"
override val privacy_policy: String = "Privaatheidsbeleid"
override val profile: String = "Profiel"
override val profitable_client: String = "Jou mees winsgewende kliënt is..."
override val profits_report: String = "Winste"
override val promo_content: String = "Promosie-inhoud"
override val quantity_hint: String = "Hoeveelheid"
override val quantity_label_hint: String = "Hoeveelheid etiket"
override val query_info: String = "Navraag laat jou toe om persoonlike berekeninge te skryf wat na beide items en ander belastingreëls as insette verwys. Voeg @ simbool en item of belastingbeskrywing by om na 'n ander inskrywing te skakel +, -, *, /, % - wiskunde operateurs"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} is deur jou kliënt ontvang." }
override val received_state: String = "Ontvang"
override val registration_code_fields: String = "Registrasie-kode"
override val reminder: String = "Onthounota"
override val reports: String = "Verslae"
override val reports_not_available: String = "Geen verslae beskikbaar, kom later terug."
override val reveal_password: String = "Openbaar wagwoord"
override val revenue: String = "inkomste"
override val sales_tax: String = "BTW"
override val sales_tax_number: String = "BTW-nommer"
override val save: String = "Stoor"
override val scan_document: String = "Skandeer dokument"
override val search: String = "Soek"
override val select: String = "Kies"
override val select_icon: String = "Kies ikoon"
override val select_signature: String = "Kies handtekening"
override val select_template: String = "Kies sjabloon"
override val send: String = "Stuur"
override val sent_state: String = "Gestuur"
override val setup_sender: String = "Stel sender op"
override val share_option: String = "Deel"
override val ship_to: String = "Stuur na"
override val sign_in: String = "Teken aan"
override val sign_in_apple: String = "Teken in met Apple"
override val sign_in_email: String = "Teken aan met e-pos"
override val sign_in_facebook: String = "Teken in met Facebook"
override val sign_in_google: String = "Teken in met Google"
override val sign_up: String = "Teken in"
override val sign_up_label: String = "Het jy nie 'n rekening nie?"
override val sign_up_link_label: String = "Teken in!"
override val signatures_option: String = "Handtekeninge"
override val skip: String = "HUPPEL"
override val sold_items_report: String = "Verkoop Items"
override val sort_by: String = "Sorteer volgens:"
override val status_update: String = "Opdatering van dokumentstatus"
override val sub_annual: String = "Jaarlikse"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/jaar" }
override val sub_backups: String = "Wolk rugsteun"
override val sub_insights: String = "Insigte"
override val sub_month: String = "maandeliks"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/maand" }
override val sub_notifications: String = "Kennisgewings"
override val sub_popup_message: String = "Intekeninge kom binnekort. Gaan terug hier in 'n rukkie."
override val sub_support: String = "Ondersteuning"
override val sub_templates: String = "Sjablone"
override val subscribe: String = "Inteken"
override val subscription_header: String = "Groei jou besigheid met:"
override val subscription_option: String = "Intekening"
override val subscriptions_load_fail: String = "Jammer, ons kan nie op die oomblik jou intekeninge ophaal nie. Probeer asseblief later weer of kontak ons ondersteuningspan vir bystand."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotaal: ${sum}" }
override val subtotal_label: String = "Subtotaal"
override val sum: String = "Som"
override val support_option: String = "Ondersteuning"
override val switch_reports: String = "Wissel Verslag"
override val tax: String = "Belasting"
override val tax_deleted_message: String = "Belasting geskrap"
override val tax_description_warning: String = "As dit gebruik word, verskyn dit op 'n faktuur"
override val tax_input_flat: String = "Plat"
override val tax_input_percent: String = "Persent"
override val tax_number_fields: String = "Belastingnommer"
override val tax_number_hint: String = "Belastingnommer"
override val tax_query_info_description: String = "Navraag Inligting"
override val tax_report: String = "Belasting"
override val tax_rules_option: String = "Belastingreëls"
override val taxed_clients_report: String = "Belaste kliënte"
override val template: String = "Patroonplaat"
override val template_selector_info: String = "Vee om die verstek sjabloon te verander"
override val template_unlock_promo: String = "Kry toegang tot meer sjablone met Premium"
override val templates_option: String = "Patrone"
override val terms_of_service: String = "Diensbepalings"
override val thank_you: String = "Dankie"
override val to: String = "Om"
override val total: Formattable = Formattable { (sum) -> "Totaal: ${sum}" }
override val total_label: String = "Totale"
override val undo_action: String = "Ontdoen"
override val unit: String = "Eenheid"
override val unit_cost_hint: String = "Eenheid Koste"
override val unknown_client: String = "Onbekende Kliënt"
override val unlock: String = "Ontsluit"
override val unnamed_item: String = "Naamlose item"
override val upgrade: String = "Opgradering"
override val user_collision_error: String = "Rekening bestaan reeds."
override val valid_until: String = "Geldig Tot"
override val warning: String = "Waarskuwing!"
override val yes: String = "ja"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Aanvaar ${termsOfService} en ${privacyPolicy}" },
	"account" to "rekening",
	"accounting" to "rekeningkunde",
	"accruals" to "aanwas",
	"action_code_error" to "Ongeldige kode.",
	"add_client_action" to "Voeg kliënt by",
	"add_customer" to "Voeg kliënt by",
	"add_expense" to "Voeg uitgawes by",
	"add_fields_label" to "Voeg:",
	"add_invoice_action" to "Skep",
	"add_item_action" to "Voeg item by",
	"add_item_button" to "Voeg item by",
	"add_signature_action" to "Voeg handtekening by",
	"add_tax_action" to "Voeg belasting by",
	"address_fields" to "Adres",
	"address_line_hint" to "Adreslyn",
	"advanced" to "Gevorderde",
	"agree_marketing" to "Ek stem in om direkte bemarkingskommunikasie te ontvang",
	"all_notifications" to "Alle kennisgewings",
	"amortization" to "amortisasie",
	"amount" to "Bedrag",
	"asset" to "bate",
	"attach_file" to "Heg Lêer aan",
	"attachment" to "Aanhangsel",
	"auth_error" to "Aksie het misluk, probeer weer.",
	"available_for_paid" to "Wolk sinchro is slegs beskikbaar onder betaalde intekening.",
	"backups_option" to "Rugsteune",
	"balance" to "saldo",
	"bank_account" to "Bankrekening",
	"bill_number" to "Rekeningnommer",
	"cancel" to "KANSELLEER",
	"cancel_trial" to "Kanselleer te eniger tyd",
	"capital" to "kapitaal",
	"cash" to "kontant",
	"categories" to "Kategorieë",
	"category" to "Kategorie",
	"category_add" to "Voeg Kategorie by",
	"category_delete" to "Vee kategorie uit",
	"category_name" to "Kategorienaam",
	"change_language" to Formattable { (language) -> "Faktuurtaal: ${language}" },
	"change_language_label" to "Faktuur Taal",
	"change_template" to "Verander sjabloon",
	"city_hint" to "Stad",
	"clear" to "Duidelik",
	"client" to "Kliënt",
	"client_actions" to "Kliënt aksies",
	"client_deleted_message" to "Kliënt geskrap",
	"clients_error_required_fields" to "Ten minste een waarde is nodig.",
	"clients_option" to "Kliënte",
	"close" to "Sluit",
	"cloud_sync" to "Wolk sinchro",
	"coming_soon" to "Kom binnekort!",
	"company_logo" to "Maatskappy Logo",
	"company_name_hint" to "Maatskappy",
	"confirm_password_label" to "Bevestig wagwoord",
	"confirmation_title" to "Is u seker?",
	"convert_to_invoice" to "Skakel om na faktuur",
	"country_hint" to "Land",
	"create_client" to "Skep Kliënt",
	"create_company" to "Skep Maatskappy",
	"create_document" to "Skep dokument",
	"create_estimate" to "Skatting",
	"create_invoice" to "Faktuur",
	"create_item" to "Skep item",
	"create_new_client" to "Skep nuwe kliënt",
	"create_new_item" to "Skep nuwe item",
	"create_new_tax" to "Skep nuwe belasting",
	"create_signature" to "Handtekening",
	"created" to "Geskep",
	"credit" to "krediet",
	"customer" to "Kliënt",
	"date" to "Datum",
	"debit" to "debiet",
	"debt" to "skuld",
	"default_currency" to Formattable { (currency) -> "Verstek geldeenheid: ${currency}" },
	"default_currency_label" to "Verstek geldeenheid",
	"delete" to "Skrap",
	"delete_account" to "Uitvee Rekening",
	"delete_confirmation_message" to "Al u data sal verlore gaan.",
	"delete_item_title" to "Skrap die item?",
	"delete_signature" to "Skrap handtekening",
	"depreciation" to "waardevermindering",
	"description" to "Beskrywing",
	"description_hint" to "Beskrywing",
	"detailed_report" to "Gedetailleerde Verslag",
	"disabled" to "Gedeaktiveer",
	"discount" to "Afslag",
	"discount_hint" to "Afslag",
	"display_af" to "Afrikaans",
	"display_am" to "Amharies",
	"display_ar" to "Arabies",
	"display_as" to "Assamees",
	"display_az" to "Azerbeidjans",
	"display_be" to "Belarussiese",
	"display_bg" to "Bulgaarse",
	"display_bn" to "Bengali",
	"display_bs" to "Bosniese",
	"display_ca" to "Katalonië",
	"display_cs" to "Tsjeggië",
	"display_da" to "Deens",
	"display_de" to "Duitse",
	"display_el" to "Grieks",
	"display_en" to "Engels",
	"display_es" to "Spaans",
	"display_es_MX" to "Spaans (Mexiko)",
	"display_et" to "Estoniaanse",
	"display_eu" to "Baskeland",
	"display_fa" to "Persiese",
	"display_fi" to "Finse",
	"display_fil" to "Fillipyne",
	"display_fr" to "Franse",
	"display_fr_CA" to "Kanadese Frans",
	"display_gl" to "Galicies",
	"display_gu" to "Goedjarati",
	"display_he" to "Hebreeus",
	"display_hi" to "Hindi",
	"display_hr" to "Kroatiese",
	"display_hu" to "Hongaars",
	"display_hy" to "Armeense",
	"display_id" to "Indonesiese",
	"display_is" to "Yslandse",
	"display_it" to "Italiaans",
	"display_ja" to "Japannese",
	"display_ka" to "Georgiaanse",
	"display_kk" to "Kazak",
	"display_km" to "Sentraal Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Koreaanse",
	"display_ky" to "Kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Litause",
	"display_lv" to "Lettiese",
	"display_mk" to "Masedoniese",
	"display_ml" to "Malajalam",
	"display_mn" to "Mongoolse",
	"display_mr" to "Marathi",
	"display_ms" to "Maleisiese",
	"display_my" to "Birmaanse",
	"display_nb" to "Noorse Bokmål",
	"display_ne" to "Nepalees",
	"display_nl" to "Hollands",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Pools",
	"display_pt" to "Portugese",
	"display_pt_BR" to "Portugees (Brasilië)",
	"display_pt_PT" to "Europese Portugees",
	"display_ro" to "Roemeense",
	"display_ru" to "Russiese",
	"display_si" to "Sinhala",
	"display_sk" to "Slowakye",
	"display_sl" to "Sloweense",
	"display_sq" to "Albaniese",
	"display_sr" to "Serwiese",
	"display_sv" to "Sweeds",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Teloegoe",
	"display_th" to "Thai",
	"display_tr" to "Turkse",
	"display_uk" to "Oekraïense",
	"display_ur" to "Oerdoe",
	"display_uz" to "Oesbeekse",
	"display_vi" to "Viëtnamese",
	"display_zh_Hant_TW" to "Chinees (Taiwan, Traditioneel)",
	"display_zu" to "Zoeloe",
	"document_estimate" to "Skatting",
	"document_invoice" to "Faktuur",
	"document_quote" to "Prys aanbod",
	"done" to "Klaar",
	"download_app" to "Laai die toepassing af",
	"download_invoice" to "Laai Faktuur af",
	"download_pdf" to "Laai PDF af",
	"draw_signature" to "Teken 'n handtekening",
	"due" to Formattable { (date) -> "As gevolg van ${date}" },
	"due_date" to "Sperdatum",
	"due_day" to "in 'n dag",
	"due_label" to "As gevolg van",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} is verskuldig ${dueWhen}!" },
	"due_today" to "vandag",
	"due_week" to "binne `n week",
	"duplicate" to "Duplikaat",
	"duration_day" to "dag",
	"duration_month" to "Maand",
	"duration_week" to "week",
	"duration_year" to "jaar",
	"email" to "E-pos",
	"email_blank_error" to "E-pos benodig.",
	"email_error" to "Ongeldige e-posadres.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Dankie vir jou besigheid. Om die volledige ${documentType} te sien, klik op die knoppie aan die einde van hierdie brief. Let wel dit is geldig tot ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Jy het 'n nuwe ${documentType} ${invoiceNumber} ontvang!" },
	"email_invoice_cta" to Formattable { (documentType) -> "BEKYK ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Geagte ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Dankie vir jou besigheid. Om die volledige ${documentType} te sien, klik op die knoppie aan die einde van hierdie brief. Betaal asseblief die saldo tot ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Jy het 'n nuwe ${documentType} ${invoiceNumber} ontvang!" },
	"email_reset_success_message" to "E-pos om wagwoord wat gestuur is, terug te stel, gaan asseblief jou inkassie na.",
	"empty_clients_list_message" to "Jy het geen kliënte om op te spoor nie. Voeg 'n nuwe kliënt by.",
	"empty_estimate_list_message" to "Skep jou eerste skatting",
	"empty_expenses" to "Jy het geen uitgawes om op te spoor nie. Voeg 'n nuwe uitgawe by.",
	"empty_invoice_list_message" to "Skep jou eerste faktuur",
	"empty_items_list_message" to "Jy het geen items nie, voeg 'n nuwe item by!",
	"empty_signatures_list_message" to "Geen handtekeninge gevind nie. Skep een!",
	"empty_taxes_list_message" to "Jy het geen belastingreëls nie. Voeg 'n nuwe belastingreël by.",
	"empty_templates_list_message" to "Templates is nie beskikbaar nie.",
	"enabled" to "Geaktiveer",
	"entry" to "inskrywing",
	"equity" to "billikheid",
	"estimate_number" to "Skat #",
	"estimates" to "Ramings",
	"estimates_report" to "Ramings",
	"expense_bank_fees" to "Bankfooie",
	"expense_benefits" to "Voordele",
	"expense_communication" to "Kommunikasie",
	"expense_debt" to "Skuld",
	"expense_deleted" to "Uitgawes verwyder",
	"expense_depreciation" to "Waardevermindering",
	"expense_gifts" to "Geskenke",
	"expense_insurance" to "Versekering",
	"expense_inventory" to "Inventaris",
	"expense_legal" to "Wettige",
	"expense_maintenance" to "Onderhoud",
	"expense_marketing" to "Bemarking",
	"expense_meals" to "Etes",
	"expense_office_supplies" to "Kantoor Voorrade",
	"expense_rent" to "Huur",
	"expense_salaries" to "Salarisse",
	"expense_shopping" to "Inkopies",
	"expense_software" to "Sagteware",
	"expense_status_available" to "Beskikbaar",
	"expense_status_processing" to "Verwerking",
	"expense_tax" to "Belasting",
	"expense_training" to "Opleiding",
	"expense_transport" to "Vervoer",
	"expense_travel" to "Reis",
	"expense_utility" to "Nut",
	"expenses" to "Uitgawes",
	"feedback_option" to "Terugvoer",
	"filter" to "Filter",
	"first_name_hint" to "Eerste naam",
	"forgot_password" to "Stel wagwoord terug",
	"forgot_password_label" to "Vergeet wagwoord",
	"from" to "Van",
	"general_error" to "Fout het voorgekom, probeer asseblief weer later.",
	"general_ledger" to "algemene grootboek",
	"go_back_label" to "Gaan terug",
	"group_by" to "Groep deur:",
	"image_view" to "Beeld",
	"install" to "Installeer",
	"invalid_credentials_error" to "Ongeldige verwysings.",
	"invalid_email_error" to "Ongeldige e-pos.",
	"invalid_user_error" to "Ongeldige gebruikersverwysings.",
	"invoice_deleted_message" to "Faktuur geskrap",
	"invoice_menu" to "Faktuurkieslys",
	"invoice_number" to "Faktuur #",
	"invoices" to "Fakture",
	"items_option" to "Items",
	"keep" to "Hou",
	"language_selector" to Formattable { (gekies) -> "Taal ${gekies}" },
	"last_name_hint" to "Laaste naam",
	"ledger" to "grootboek",
	"legal" to "Wettige",
	"legal_error" to "Moet diensbepalings en privaatheidsbeleid aanvaar",
	"liabilities" to "laste",
	"loading" to "Laai tans",
	"login_required_error" to "Aanmelding benodig.",
	"logo_view" to "Logo",
	"logout" to "Logout",
	"logout_prompt" to "Jou data word nie met wolk gesinchroniseer nie. Skrap fakture, ramings, kliënte en ander inligting oor logout?",
	"main_screen" to "Hoofskerm",
	"mark_paid" to "Merk as betaal",
	"message" to "Boodskap",
	"missing_sender_msg" to "'N Dokument sonder sender inligting kan ongeldig wees.",
	"missing_sender_title" to "Vermiste sender inligting",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "Augustus",
	"month_august_short" to "Aug.",
	"month_december" to "Desember",
	"month_december_short" to "Desember.",
	"month_february" to "Februarie",
	"month_february_short" to "Februarie.",
	"month_january" to "Januarie",
	"month_january_short" to "Jan.",
	"month_july" to "Julie",
	"month_july_short" to "Julie",
	"month_june" to "Junie",
	"month_june_short" to "Junie",
	"month_march" to "Maart",
	"month_march_short" to "Maart",
	"month_may" to "Mei",
	"month_may_short" to "Mei",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Oktober",
	"month_october_short" to "Okt.",
	"month_september" to "September",
	"month_september_short" to "September.",
	"multifactor_error" to "Kon nie verifieer nie.",
	"new" to "Nuut",
	"news" to "Nuus",
	"next" to "Volgende",
	"no" to "nee",
	"no_data_available" to "Geen data beskikbaar nie",
	"no_search_results" to "Jammer, ons kon geen resultate vind nie.",
	"none" to "Geen",
	"note_fields" to "Notas",
	"note_hint" to "Nota",
	"notifications_option" to "Kennisgewings",
	"ok" to "OUKEI",
	"onboarding" to "Op instap",
	"onboarding_aboutus_message" to "Noodsaaklike dele van DingVoice is vir ewig gratis. Ons missie is om jou te help om jou besigheid te laat groei, ons te help verbeter deur jou terugvoer te deel.",
	"onboarding_aboutus_title" to "Ons is hier om te help",
	"onboarding_paid_1" to "Toegang tot professionele sjablone",
	"onboarding_paid_2" to "Kyk dieper na jou finansies",
	"onboarding_paid_3" to "Kry probleme vinnig opgelos",
	"onboarding_paid_4" to "Hou jou dokumente veilig",
	"onboarding_paid_5" to "Bly op hoogte van faktuurstatusveranderinge",
	"onboarding_paid_title" to "Groei selfs vinniger met...",
	"onboarding_paywall_message" to "Geniet nou alle premium kenmerke teen die beste prys.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Net ${currencySymbol}${introAmount} vir ${introDuration} ${introPeriod} en dan ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Net ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis vir ${trialDuration} ${trialPeriod} en dan net ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis vir${trialDuration}${trialPeriod} dan${currencySymbol}${introductoryAmount} vir${introductoryDuration}${introPeriod} en dan net${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "Outo-hernubaar. Kanselleer enige tyd.",
	"onboarding_paywall_restore" to "Herstel aankoop",
	"onboarding_paywall_restore_failure" to "Die herstel van aankope het misluk, probeer later weer of kontak steundiens.",
	"onboarding_paywall_restore_success" to "Herstel suksesvol!",
	"onboarding_paywall_title" to "Bereik Grootheid!",
	"onboarding_welcome_message" to "&#39;n Volledige gereedskapstel om u fakture en skattings te bestuur.",
	"onboarding_welcome_title" to "Welkom by DingVoice",
	"other_state" to "Ander",
	"overdue_state" to "Agterstallige",
	"paid_state" to "Betaal",
	"password" to "Wagwoord",
	"password_blank_error" to "Wagwoord benodig.",
	"password_mismatch_error" to "Wagwoorde stem nie ooreen nie.",
	"pay" to "betaal",
	"payment_instructions_label" to "Betalingsinstruksies",
	"payment_reminders" to "Betaling herinnerings",
	"phone_number_fields" to "Telefoonnommer",
	"phone_number_hint" to "Telefoonnommer",
	"postcode_hint" to "Poskode",
	"preview" to "Voorskou",
	"preview_menu_hint" to "Voorskou kieslys",
	"price" to "Prys",
	"privacy_policy" to "Privaatheidsbeleid",
	"profile" to "Profiel",
	"profitable_client" to "Jou mees winsgewende kliënt is...",
	"profits_report" to "Winste",
	"promo_content" to "Promosie-inhoud",
	"quantity_hint" to "Hoeveelheid",
	"quantity_label_hint" to "Hoeveelheid etiket",
	"query_info" to "Navraag laat jou toe om persoonlike berekeninge te skryf wat na beide items en ander belastingreëls as insette verwys. Voeg @ simbool en item of belastingbeskrywing by om na 'n ander inskrywing te skakel +, -, *, /, % - wiskunde operateurs",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} is deur jou kliënt ontvang." },
	"received_state" to "Ontvang",
	"registration_code_fields" to "Registrasie-kode",
	"reminder" to "Onthounota",
	"reports" to "Verslae",
	"reports_not_available" to "Geen verslae beskikbaar, kom later terug.",
	"reveal_password" to "Openbaar wagwoord",
	"revenue" to "inkomste",
	"sales_tax" to "BTW",
	"sales_tax_number" to "BTW-nommer",
	"save" to "Stoor",
	"scan_document" to "Skandeer dokument",
	"search" to "Soek",
	"select" to "Kies",
	"select_icon" to "Kies ikoon",
	"select_signature" to "Kies handtekening",
	"select_template" to "Kies sjabloon",
	"send" to "Stuur",
	"sent_state" to "Gestuur",
	"setup_sender" to "Stel sender op",
	"share_option" to "Deel",
	"ship_to" to "Stuur na",
	"sign_in" to "Teken aan",
	"sign_in_apple" to "Teken in met Apple",
	"sign_in_email" to "Teken aan met e-pos",
	"sign_in_facebook" to "Teken in met Facebook",
	"sign_in_google" to "Teken in met Google",
	"sign_up" to "Teken in",
	"sign_up_label" to "Het jy nie 'n rekening nie?",
	"sign_up_link_label" to "Teken in!",
	"signatures_option" to "Handtekeninge",
	"skip" to "HUPPEL",
	"sold_items_report" to "Verkoop Items",
	"sort_by" to "Sorteer volgens:",
	"status_update" to "Opdatering van dokumentstatus",
	"sub_annual" to "Jaarlikse",
	"sub_annual_price" to Formattable { (price) -> "${price}/jaar" },
	"sub_backups" to "Wolk rugsteun",
	"sub_insights" to "Insigte",
	"sub_month" to "maandeliks",
	"sub_monthly_price" to Formattable { (price) -> "${price}/maand" },
	"sub_notifications" to "Kennisgewings",
	"sub_popup_message" to "Intekeninge kom binnekort. Gaan terug hier in 'n rukkie.",
	"sub_support" to "Ondersteuning",
	"sub_templates" to "Sjablone",
	"subscribe" to "Inteken",
	"subscription_header" to "Groei jou besigheid met:",
	"subscription_option" to "Intekening",
	"subscriptions_load_fail" to "Jammer, ons kan nie op die oomblik jou intekeninge ophaal nie. Probeer asseblief later weer of kontak ons ondersteuningspan vir bystand.",
	"subtotal" to Formattable { (sum) -> "Subtotaal: ${sum}" },
	"subtotal_label" to "Subtotaal",
	"sum" to "Som",
	"support_option" to "Ondersteuning",
	"switch_reports" to "Wissel Verslag",
	"tax" to "Belasting",
	"tax_deleted_message" to "Belasting geskrap",
	"tax_description_warning" to "As dit gebruik word, verskyn dit op 'n faktuur",
	"tax_input_flat" to "Plat",
	"tax_input_percent" to "Persent",
	"tax_number_fields" to "Belastingnommer",
	"tax_number_hint" to "Belastingnommer",
	"tax_query_info_description" to "Navraag Inligting",
	"tax_report" to "Belasting",
	"tax_rules_option" to "Belastingreëls",
	"taxed_clients_report" to "Belaste kliënte",
	"template" to "Patroonplaat",
	"template_selector_info" to "Vee om die verstek sjabloon te verander",
	"template_unlock_promo" to "Kry toegang tot meer sjablone met Premium",
	"templates_option" to "Patrone",
	"terms_of_service" to "Diensbepalings",
	"thank_you" to "Dankie",
	"to" to "Om",
	"total" to Formattable { (sum) -> "Totaal: ${sum}" },
	"total_label" to "Totale",
	"undo_action" to "Ontdoen",
	"unit" to "Eenheid",
	"unit_cost_hint" to "Eenheid Koste",
	"unknown_client" to "Onbekende Kliënt",
	"unlock" to "Ontsluit",
	"unnamed_item" to "Naamlose item",
	"upgrade" to "Opgradering",
	"user_collision_error" to "Rekening bestaan reeds.",
	"valid_until" to "Geldig Tot",
	"warning" to "Waarskuwing!",
	"yes" to "ja",
    )
    
}