// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ru  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Примите ${termsOfService} и ${privacyPolicy}" }
override val account: String = "счёт"
override val accounting: String = "бухгалтерия"
override val accruals: String = "начисления"
override val action_code_error: String = "Неверный код."
override val add_client_action: String = "Добавить клиента"
override val add_customer: String = "Добавить клиента"
override val add_expense: String = "Добавить расход"
override val add_fields_label: String = "Добавить:"
override val add_invoice_action: String = "Создать"
override val add_item_action: String = "Добавить элемент"
override val add_item_button: String = "Добавить элемент"
override val add_signature_action: String = "Добавить подпись"
override val add_tax_action: String = "Добавить налог"
override val address_fields: String = "Адрес"
override val address_line_hint: String = "Адресная строка"
override val advanced: String = "Расширенный"
override val agree_marketing: String = "Я согласен получать прямые маркетинговые сообщения"
override val all_notifications: String = "Все уведомления"
override val amortization: String = "амортизация"
override val amount: String = "Сумма"
override val asset: String = "актив"
override val attach_file: String = "Прикрепить файл"
override val attachment: String = "Вложение"
override val auth_error: String = "Действие не удалось, попробуйте еще раз."
override val available_for_paid: String = "Облачная синхронизация доступна только по платной подписке."
override val backups_option: String = "Резервные копии"
override val balance: String = "остаток"
override val bank_account: String = "Банковский Cчет"
override val bill_number: String = "Номер Счета"
override val cancel: String = "Отмена"
override val cancel_trial: String = "Отменить в любое время"
override val capital: String = "капитал"
override val cash: String = "наличные"
override val categories: String = "Категории"
override val category: String = "Категория"
override val category_add: String = "Добавить Категорию"
override val category_delete: String = "Удалить категорию"
override val category_name: String = "Название категории"
override val change_language: Formattable = Formattable { (language) -> "Язык счета-фактуры: ${language}" }
override val change_language_label: String = "Язык Cчета-фактуры"
override val change_template: String = "Изменить шаблон"
override val city_hint: String = "Город"
override val clear: String = "Очистить"
override val client: String = "Клиент"
override val client_actions: String = "Действия клиента"
override val client_deleted_message: String = "Клиент удален"
override val clients_error_required_fields: String = "Требуется хотя бы одно значение."
override val clients_option: String = "Клиенты"
override val close: String = "Закрыть"
override val cloud_sync: String = "Облачная синхронизация"
override val coming_soon: String = "Скоро будет!"
override val company_logo: String = "Логотип компании"
override val company_name_hint: String = "Компания"
override val confirm_password_label: String = "Подтверждение пароля"
override val confirmation_title: String = "Вы уверены?"
override val convert_to_invoice: String = "Конвертировать в счет-фактуру"
override val country_hint: String = "Страна"
override val create_client: String = "Создать клиента"
override val create_company: String = "Создать компанию"
override val create_document: String = "Создать документ"
override val create_estimate: String = "Оценка"
override val create_invoice: String = "СЧЕТ-ФАКТУРА"
override val create_item: String = "Создать элемент"
override val create_new_client: String = "Создать нового клиента"
override val create_new_item: String = "Создать новый элемент"
override val create_new_tax: String = "Создание нового налога"
override val create_signature: String = "Подпись"
override val created: String = "Создано"
override val credit: String = "кредитная"
override val customer: String = "Клиент"
override val date: String = "дата"
override val debit: String = "дебетовая"
override val debt: String = "долг"
override val default_currency: Formattable = Formattable { (currency) -> "Валюта по умолчанию: ${currency}" }
override val default_currency_label: String = "Валюта по умолчанию"
override val delete: String = "Удалить"
override val delete_account: String = "Удалить Аккаунт"
override val delete_confirmation_message: String = "Все ваши данные будут потеряны."
override val delete_item_title: String = "Удалить элемент?"
override val delete_signature: String = "Удалить подпись"
override val depreciation: String = "амортизация"
override val description: String = "Описание"
override val description_hint: String = "Описание"
override val detailed_report: String = "Подробный Oтчет"
override val disabled: String = "Инвалид"
override val discount: String = "Скидка"
override val discount_hint: String = "Скидка"
override val display_af: String = "Afrikaans"
override val display_am: String = "Амхарский язык"
override val display_ar: String = "Арабский язык"
override val display_as: String = "Ассамцы"
override val display_az: String = "Азербайджан"
override val display_be: String = "Белорусский"
override val display_bg: String = "Болгарский"
override val display_bn: String = "Бенгалия"
override val display_bs: String = "Босния и Герцеговина"
override val display_ca: String = "Каталония"
override val display_cs: String = "Чехия"
override val display_da: String = "Датский"
override val display_de: String = "Немецкий"
override val display_el: String = "Греция"
override val display_en: String = "Английский язык"
override val display_es: String = "Испанский"
override val display_es_MX: String = "Испанский (Мексика)"
override val display_et: String = "Эстония"
override val display_eu: String = "Баск"
override val display_fa: String = "Персидский"
override val display_fi: String = "Финский"
override val display_fil: String = "Филипины"
override val display_fr: String = "Французский"
override val display_fr_CA: String = "Канадский французский"
override val display_gl: String = "Галисийский"
override val display_gu: String = "Гуджарати"
override val display_he: String = "Иврит"
override val display_hi: String = "Хинди"
override val display_hr: String = "Хорватский"
override val display_hu: String = "Венгерский"
override val display_hy: String = "Армянский"
override val display_id: String = "Индонезийский"
override val display_is: String = "Исландия"
override val display_it: String = "Итальянский"
override val display_ja: String = "Японский"
override val display_ka: String = "Грузинский"
override val display_kk: String = "Казахстан"
override val display_km: String = "Центральные кхмеры"
override val display_kn: String = "Каннада"
override val display_ko: String = "Корейский"
override val display_ky: String = "Киргизы"
override val display_lo: String = "Лао"
override val display_lt: String = "Литовский"
override val display_lv: String = "Латвия"
override val display_mk: String = "Македония"
override val display_ml: String = "Малаялам"
override val display_mn: String = "Монгольский"
override val display_mr: String = "Маратхи"
override val display_ms: String = "Малайский"
override val display_my: String = "Бирманский"
override val display_nb: String = "Норвежский язык бокмоль"
override val display_ne: String = "Непали"
override val display_nl: String = "Голландский"
override val display_or: String = "Oriya"
override val display_pa: String = "Пенджаби"
override val display_pl: String = "Польский"
override val display_pt: String = "Португальский язык"
override val display_pt_BR: String = "Португальский (Бразилия)"
override val display_pt_PT: String = "Европейский португальский"
override val display_ro: String = "Румынский"
override val display_ru: String = "Русский"
override val display_si: String = "Синхала"
override val display_sk: String = "Словакия"
override val display_sl: String = "Словения"
override val display_sq: String = "Албанский"
override val display_sr: String = "Сербия"
override val display_sv: String = "Шведский"
override val display_sw: String = "Суахили"
override val display_ta: String = "Тамил"
override val display_te: String = "Телугу"
override val display_th: String = "Тайский"
override val display_tr: String = "Турецкий"
override val display_uk: String = "Украинский"
override val display_ur: String = "Урду"
override val display_uz: String = "Узбек"
override val display_vi: String = "Вьетнамцы"
override val display_zh_Hant_TW: String = "Китайский (Тайвань, Традиционный)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Оценка"
override val document_invoice: String = "Счет-фактура"
override val document_quote: String = "Предложение"
override val done: String = "Выполнено"
override val download_app: String = "Скачать приложение"
override val download_invoice: String = "Скачать Cчет-Фактуру"
override val download_pdf: String = "Скачать PDF"
override val draw_signature: String = "Нарисовать подпись"
override val due: Formattable = Formattable { (date) -> "Срок исполнения ${date}" }
override val due_date: String = "Срок исполнения"
override val due_day: String = "в день"
override val due_label: String = "Должен"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} должен быть оплачен ${dueWhen}!" }
override val due_today: String = "сегодня"
override val due_week: String = "в течение недели"
override val duplicate: String = "Дублировать"
override val duration_day: String = "день"
override val duration_month: String = "месяц"
override val duration_week: String = "неделя"
override val duration_year: String = "год"
override val email: String = "Электронная почта"
override val email_blank_error: String = "Требуется электронная почта."
override val email_error: String = "Неверный адрес электронной почты."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Спасибо за ваш бизнес. Чтобы просмотреть полный документ ${documentType}, нажмите кнопку в конце этого письма. Обратите внимание, что он действителен до ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Вы получили новый документ: ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "Посмотреть ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Уважаемый ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Спасибо за ваш бизнес. Чтобы просмотреть полный документ ${documentType}, нажмите кнопку в конце этого письма. Пожалуйста, оплатите остаток до ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Вы получили новый документ: ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Электронное письмо для сброса пароля отправлено, пожалуйста, проверьте свой почтовый ящик."
override val empty_clients_list_message: String = "У вас нет клиентов для отслеживания. Добавьте нового клиента."
override val empty_estimate_list_message: String = "Создайте свою первую смету"
override val empty_expenses: String = "У вас нет расходов для отслеживания. Добавьте новый расход."
override val empty_invoice_list_message: String = "Создайте свой первый счет-фактуру"
override val empty_items_list_message: String = "У вас нет товаров, добавьте новый товар!"
override val empty_signatures_list_message: String = "Подписи не найдены. Создайте одну!"
override val empty_taxes_list_message: String = "У вас нет налоговых правил. Добавьте новое налоговое правило."
override val empty_templates_list_message: String = "Шаблоны недоступны."
override val enabled: String = "Включено"
override val entry: String = "запись"
override val equity: String = "собственный капитал"
override val estimate_number: String = "Оценка #"
override val estimates: String = "Оценки"
override val estimates_report: String = "Оценки"
override val expense_bank_fees: String = "Банковские Сборы"
override val expense_benefits: String = "Преимущества"
override val expense_communication: String = "Коммуникация"
override val expense_debt: String = "Долг"
override val expense_deleted: String = "Расходы удалены"
override val expense_depreciation: String = "Амортизация"
override val expense_gifts: String = "Подарки"
override val expense_insurance: String = "Страхование"
override val expense_inventory: String = "Инвентарь"
override val expense_legal: String = "Юридический"
override val expense_maintenance: String = "Техобслуживание"
override val expense_marketing: String = "Маркетинг"
override val expense_meals: String = "Питание"
override val expense_office_supplies: String = "Офисные Принадлежности"
override val expense_rent: String = "Аренда"
override val expense_salaries: String = "Зарплаты"
override val expense_shopping: String = "Покупки"
override val expense_software: String = "Программное обеспечение"
override val expense_status_available: String = "Доступно"
override val expense_status_processing: String = "Обработка"
override val expense_tax: String = "Налог"
override val expense_training: String = "Обучение"
override val expense_transport: String = "Транспорт"
override val expense_travel: String = "Путешествия"
override val expense_utility: String = "Полезность"
override val expenses: String = "Расходы"
override val feedback_option: String = "Обратная связь"
override val filter: String = "Фильтр"
override val first_name_hint: String = "Имя"
override val forgot_password: String = "Сброс пароля"
override val forgot_password_label: String = "Забыли пароль"
override val from: String = "С сайта"
override val general_error: String = "Произошла ошибка, пожалуйста, повторите попытку позже."
override val general_ledger: String = "главная книга"
override val go_back_label: String = "возвращаться"
override val group_by: String = "Группа By:"
override val image_view: String = "Изображение"
override val install: String = "Установить"
override val invalid_credentials_error: String = "Неверные учетные данные."
override val invalid_email_error: String = "Неверный адрес электронной почты."
override val invalid_user_error: String = "Неверные учетные данные пользователя."
override val invoice_deleted_message: String = "Счет-фактура удален"
override val invoice_menu: String = "Меню счета-фактуры"
override val invoice_number: String = "Счет-фактура №"
override val invoices: String = "Счета-фактуры"
override val items_option: String = "Элементы"
override val keep: String = "Сохраните"
override val language_selector: Formattable = Formattable { (selected) -> "Язык ${selected}" }
override val last_name_hint: String = "Фамилия"
override val ledger: String = "бухгалтерская книга"
override val legal: String = "Юридический"
override val legal_error: String = "Должен принять Условия предоставления услуг и Политику конфиденциальности"
override val liabilities: String = "обязательства"
override val loading: String = "Загрузка"
override val login_required_error: String = "Требуется вход в систему."
override val logo_view: String = "Логотип"
override val logout: String = "Выход из системы"
override val logout_prompt: String = "Ваши данные не синхронизируются с облаком. Удалять счета, сметы, клиентов и другую информацию при выходе из системы?"
override val main_screen: String = "Главный экран"
override val mark_paid: String = "Отметить как оплаченный"
override val message: String = "Сообщение"
override val missing_sender_msg: String = "Документ без информации об отправителе может быть недействительным."
override val missing_sender_title: String = "Отсутствует информация об отправителе"
override val month_april: String = "Апрель"
override val month_april_short: String = "Апрель"
override val month_august: String = "Август"
override val month_august_short: String = "Авг."
override val month_december: String = "Декабрь"
override val month_december_short: String = "Дек."
override val month_february: String = "Февраль"
override val month_february_short: String = "фев."
override val month_january: String = "Январь"
override val month_january_short: String = "Янв."
override val month_july: String = "Июль"
override val month_july_short: String = "Июль"
override val month_june: String = "Июнь"
override val month_june_short: String = "Июнь"
override val month_march: String = "Март"
override val month_march_short: String = "Март"
override val month_may: String = "Май"
override val month_may_short: String = "Май"
override val month_november: String = "Ноябрь"
override val month_november_short: String = "Нояб."
override val month_october: String = "Октябрь"
override val month_october_short: String = "Окт."
override val month_september: String = "Сентябрь"
override val month_september_short: String = "Сент."
override val multifactor_error: String = "Не удалось проверить."
override val new: String = "Новое"
override val news: String = "Новости"
override val next: String = "Следующий"
override val no: String = "нет"
override val no_data_available: String = "Нет доступных данных"
override val no_search_results: String = "К сожалению, мы не смогли найти никаких результатов."
override val none: String = "Нет"
override val note_fields: String = "Примечания"
override val note_hint: String = "Примечание"
override val notifications_option: String = "Уведомления"
override val ok: String = "OK"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Основные части DingVoice навсегда остаются бесплатными. Наша задача - помочь вам развивать свой бизнес, помогайте нам совершенствоваться, делясь своими отзывами."
override val onboarding_aboutus_title: String = "Мы здесь, чтобы помочь"
override val onboarding_paid_1: String = "Доступ к профессионально оформленным шаблонам"
override val onboarding_paid_2: String = "Глубоко изучите свои финансовые показатели"
override val onboarding_paid_3: String = "Быстрое решение проблем"
override val onboarding_paid_4: String = "Обеспечьте сохранность своих документов"
override val onboarding_paid_5: String = "Будьте в курсе изменений статуса счетов-фактур"
override val onboarding_paid_title: String = "Растите еще быстрее с..."
override val onboarding_paywall_message: String = "Наслаждайтесь всеми функциями премиум-класса по лучшей цене."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Просто ${currencySymbol}${introAmount} для ${introDuration} ${introPeriod} и затем ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (валюта, сумма, длительность) -> "Всего ${валюта}${сумма} / ${длительность}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Бесплатно в течение ${trialDuration} ${trialPeriod}, а затем просто ${currencySymbol} ${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Бесплатно для ${trialDuration} ${trialPeriod}, затем ${currencySymbol}${introductoryAmount} для ${introductoryDuration} ${introPeriod} и затем просто ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Автовозобновляемая. Отменить в любое время."
override val onboarding_paywall_restore: String = "Восстановить покупку"
override val onboarding_paywall_restore_failure: String = "Восстановление покупок не удалось, повторите попытку позже или обратитесь в службу поддержки."
override val onboarding_paywall_restore_success: String = "Восстановление прошло успешно!"
override val onboarding_paywall_title: String = "Достичь величия!"
override val onboarding_welcome_message: String = "Полный набор инструментов для управления счетами и сметами."
override val onboarding_welcome_title: String = "Добро пожаловать в DingVoice"
override val other_state: String = "Другое"
override val overdue_state: String = "Просроченные"
override val paid_state: String = "Оплачивается"
override val password: String = "Пароль"
override val password_blank_error: String = "Требуется пароль."
override val password_mismatch_error: String = "Пароли не совпадают."
override val pay: String = "оплатить"
override val payment_instructions_label: String = "Инструкции по оплате"
override val payment_reminders: String = "Напоминания о платежах"
override val phone_number_fields: String = "Номер телефона"
override val phone_number_hint: String = "Номер телефона"
override val postcode_hint: String = "Почтовый индекс"
override val preview: String = "Предварительный просмотр"
override val preview_menu_hint: String = "Меню предварительного просмотра"
override val price: String = "Цена"
override val privacy_policy: String = "Политика конфиденциальности"
override val profile: String = "Профиль"
override val profitable_client: String = "Ваш самый прибыльный клиент - это..."
override val profits_report: String = "Прибыль"
override val promo_content: String = "Рекламный контент"
override val quantity_hint: String = "QUANTITY"
override val quantity_label_hint: String = "Этикетка количества"
override val query_info: String = "Запрос позволяет писать пользовательские вычисления, используя в качестве входных данных как товары, так и другие налоговые правила. Добавьте символ @ и описание товара или налога для ссылки на другую запись +, -, *, /, % - математические операторы"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} был получен вашим клиентом." }
override val received_state: String = "Получено"
override val registration_code_fields: String = "Регистрационный Kод"
override val reminder: String = "Напоминание"
override val reports: String = "Отчеты"
override val reports_not_available: String = "Нет доступных отчетов, вернитесь позже."
override val reveal_password: String = "Раскрыть пароль"
override val revenue: String = "доход"
override val sales_tax: String = "НДС"
override val sales_tax_number: String = "Номер НДС"
override val save: String = "Сохранить"
override val scan_document: String = "Сканировать документ"
override val search: String = "Поиск"
override val select: String = "Выбрать"
override val select_icon: String = "Выбрать иконку"
override val select_signature: String = "Выберите подпись"
override val select_template: String = "Выберите шаблон"
override val send: String = "Отправить"
override val sent_state: String = "Отправлено"
override val setup_sender: String = "Настроить отправителя"
override val share_option: String = "Поделиться"
override val ship_to: String = "Отправка в"
override val sign_in: String = "Войти"
override val sign_in_apple: String = "Вход в систему с помощью Apple"
override val sign_in_email: String = "Войти с помощью электронной почты"
override val sign_in_facebook: String = "Войти с помощью Facebook"
override val sign_in_google: String = "Войти в систему с помощью Google"
override val sign_up: String = "Зарегистрироваться"
override val sign_up_label: String = "У вас нет аккаунта?"
override val sign_up_link_label: String = "ПОДПИСЫВАЙТЕСЬ!"
override val signatures_option: String = "Подписи"
override val skip: String = "Скип"
override val sold_items_report: String = "Проданные Tовары"
override val sort_by: String = "Сортировать по:"
override val status_update: String = "Обновление статуса документа"
override val sub_annual: String = "ежегодный"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/год" }
override val sub_backups: String = "Облачные резервные копии"
override val sub_insights: String = "Инсайты"
override val sub_month: String = "Ежемесячно"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/месяц" }
override val sub_notifications: String = "Уведомления"
override val sub_popup_message: String = "Подписки скоро появятся. Загляните сюда через некоторое время."
override val sub_support: String = "Поддержка"
override val sub_templates: String = "Шаблоны"
override val subscribe: String = "Подписаться"
override val subscription_header: String = "Развивайте свой бизнес с помощью:"
override val subscription_option: String = "Подписка"
override val subscriptions_load_fail: String = "Извините, в данный момент мы не можем восстановить ваши подписки. Пожалуйста, повторите попытку позже или обратитесь за помощью в нашу службу поддержки."
override val subtotal: Formattable = Formattable { (sum) -> "Промежуточный итог: ${sum}" }
override val subtotal_label: String = "Промежуточный итог"
override val sum: String = "Сумма"
override val support_option: String = "Поддержка"
override val switch_reports: String = "Переключить Oтчет"
override val tax: String = "Налог"
override val tax_deleted_message: String = "Налог удален"
override val tax_description_warning: String = "При использовании это отображается в счете-фактуре"
override val tax_input_flat: String = "Плоский"
override val tax_input_percent: String = "Процент"
override val tax_number_fields: String = "Налоговый номер"
override val tax_number_hint: String = "Налоговый номер"
override val tax_query_info_description: String = "Информация о запросе"
override val tax_report: String = "Налог"
override val tax_rules_option: String = "Налоговые правила"
override val taxed_clients_report: String = "Налогооблагаемые клиенты"
override val template: String = "Шаблон"
override val template_selector_info: String = "Проведите пальцем, чтобы изменить шаблон по умолчанию"
override val template_unlock_promo: String = "Получите доступ к большему количеству шаблонов с Premium"
override val templates_option: String = "Шаблоны"
override val terms_of_service: String = "Условия предоставления услуг"
override val thank_you: String = "Спасибо"
override val to: String = "На"
override val total: Formattable = Formattable { (sum) -> "Итого: ${sum}" }
override val total_label: String = "Всего"
override val undo_action: String = "Отменить"
override val unit: String = "Единица"
override val unit_cost_hint: String = "Стоимость единицы продукции"
override val unknown_client: String = "Неизвестный Kлиент"
override val unlock: String = "Разблокировать"
override val unnamed_item: String = "Безымянный элемент"
override val upgrade: String = "Обновление"
override val user_collision_error: String = "Учетная запись уже существует."
override val valid_until: String = "Действует до"
override val warning: String = "Внимание!"
override val yes: String = "да"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Примите ${termsOfService} и ${privacyPolicy}" },
	"account" to "счёт",
	"accounting" to "бухгалтерия",
	"accruals" to "начисления",
	"action_code_error" to "Неверный код.",
	"add_client_action" to "Добавить клиента",
	"add_customer" to "Добавить клиента",
	"add_expense" to "Добавить расход",
	"add_fields_label" to "Добавить:",
	"add_invoice_action" to "Создать",
	"add_item_action" to "Добавить элемент",
	"add_item_button" to "Добавить элемент",
	"add_signature_action" to "Добавить подпись",
	"add_tax_action" to "Добавить налог",
	"address_fields" to "Адрес",
	"address_line_hint" to "Адресная строка",
	"advanced" to "Расширенный",
	"agree_marketing" to "Я согласен получать прямые маркетинговые сообщения",
	"all_notifications" to "Все уведомления",
	"amortization" to "амортизация",
	"amount" to "Сумма",
	"asset" to "актив",
	"attach_file" to "Прикрепить файл",
	"attachment" to "Вложение",
	"auth_error" to "Действие не удалось, попробуйте еще раз.",
	"available_for_paid" to "Облачная синхронизация доступна только по платной подписке.",
	"backups_option" to "Резервные копии",
	"balance" to "остаток",
	"bank_account" to "Банковский Cчет",
	"bill_number" to "Номер Счета",
	"cancel" to "Отмена",
	"cancel_trial" to "Отменить в любое время",
	"capital" to "капитал",
	"cash" to "наличные",
	"categories" to "Категории",
	"category" to "Категория",
	"category_add" to "Добавить Категорию",
	"category_delete" to "Удалить категорию",
	"category_name" to "Название категории",
	"change_language" to Formattable { (language) -> "Язык счета-фактуры: ${language}" },
	"change_language_label" to "Язык Cчета-фактуры",
	"change_template" to "Изменить шаблон",
	"city_hint" to "Город",
	"clear" to "Очистить",
	"client" to "Клиент",
	"client_actions" to "Действия клиента",
	"client_deleted_message" to "Клиент удален",
	"clients_error_required_fields" to "Требуется хотя бы одно значение.",
	"clients_option" to "Клиенты",
	"close" to "Закрыть",
	"cloud_sync" to "Облачная синхронизация",
	"coming_soon" to "Скоро будет!",
	"company_logo" to "Логотип компании",
	"company_name_hint" to "Компания",
	"confirm_password_label" to "Подтверждение пароля",
	"confirmation_title" to "Вы уверены?",
	"convert_to_invoice" to "Конвертировать в счет-фактуру",
	"country_hint" to "Страна",
	"create_client" to "Создать клиента",
	"create_company" to "Создать компанию",
	"create_document" to "Создать документ",
	"create_estimate" to "Оценка",
	"create_invoice" to "СЧЕТ-ФАКТУРА",
	"create_item" to "Создать элемент",
	"create_new_client" to "Создать нового клиента",
	"create_new_item" to "Создать новый элемент",
	"create_new_tax" to "Создание нового налога",
	"create_signature" to "Подпись",
	"created" to "Создано",
	"credit" to "кредитная",
	"customer" to "Клиент",
	"date" to "дата",
	"debit" to "дебетовая",
	"debt" to "долг",
	"default_currency" to Formattable { (currency) -> "Валюта по умолчанию: ${currency}" },
	"default_currency_label" to "Валюта по умолчанию",
	"delete" to "Удалить",
	"delete_account" to "Удалить Аккаунт",
	"delete_confirmation_message" to "Все ваши данные будут потеряны.",
	"delete_item_title" to "Удалить элемент?",
	"delete_signature" to "Удалить подпись",
	"depreciation" to "амортизация",
	"description" to "Описание",
	"description_hint" to "Описание",
	"detailed_report" to "Подробный Oтчет",
	"disabled" to "Инвалид",
	"discount" to "Скидка",
	"discount_hint" to "Скидка",
	"display_af" to "Afrikaans",
	"display_am" to "Амхарский язык",
	"display_ar" to "Арабский язык",
	"display_as" to "Ассамцы",
	"display_az" to "Азербайджан",
	"display_be" to "Белорусский",
	"display_bg" to "Болгарский",
	"display_bn" to "Бенгалия",
	"display_bs" to "Босния и Герцеговина",
	"display_ca" to "Каталония",
	"display_cs" to "Чехия",
	"display_da" to "Датский",
	"display_de" to "Немецкий",
	"display_el" to "Греция",
	"display_en" to "Английский язык",
	"display_es" to "Испанский",
	"display_es_MX" to "Испанский (Мексика)",
	"display_et" to "Эстония",
	"display_eu" to "Баск",
	"display_fa" to "Персидский",
	"display_fi" to "Финский",
	"display_fil" to "Филипины",
	"display_fr" to "Французский",
	"display_fr_CA" to "Канадский французский",
	"display_gl" to "Галисийский",
	"display_gu" to "Гуджарати",
	"display_he" to "Иврит",
	"display_hi" to "Хинди",
	"display_hr" to "Хорватский",
	"display_hu" to "Венгерский",
	"display_hy" to "Армянский",
	"display_id" to "Индонезийский",
	"display_is" to "Исландия",
	"display_it" to "Итальянский",
	"display_ja" to "Японский",
	"display_ka" to "Грузинский",
	"display_kk" to "Казахстан",
	"display_km" to "Центральные кхмеры",
	"display_kn" to "Каннада",
	"display_ko" to "Корейский",
	"display_ky" to "Киргизы",
	"display_lo" to "Лао",
	"display_lt" to "Литовский",
	"display_lv" to "Латвия",
	"display_mk" to "Македония",
	"display_ml" to "Малаялам",
	"display_mn" to "Монгольский",
	"display_mr" to "Маратхи",
	"display_ms" to "Малайский",
	"display_my" to "Бирманский",
	"display_nb" to "Норвежский язык бокмоль",
	"display_ne" to "Непали",
	"display_nl" to "Голландский",
	"display_or" to "Oriya",
	"display_pa" to "Пенджаби",
	"display_pl" to "Польский",
	"display_pt" to "Португальский язык",
	"display_pt_BR" to "Португальский (Бразилия)",
	"display_pt_PT" to "Европейский португальский",
	"display_ro" to "Румынский",
	"display_ru" to "Русский",
	"display_si" to "Синхала",
	"display_sk" to "Словакия",
	"display_sl" to "Словения",
	"display_sq" to "Албанский",
	"display_sr" to "Сербия",
	"display_sv" to "Шведский",
	"display_sw" to "Суахили",
	"display_ta" to "Тамил",
	"display_te" to "Телугу",
	"display_th" to "Тайский",
	"display_tr" to "Турецкий",
	"display_uk" to "Украинский",
	"display_ur" to "Урду",
	"display_uz" to "Узбек",
	"display_vi" to "Вьетнамцы",
	"display_zh_Hant_TW" to "Китайский (Тайвань, Традиционный)",
	"display_zu" to "Zulu",
	"document_estimate" to "Оценка",
	"document_invoice" to "Счет-фактура",
	"document_quote" to "Предложение",
	"done" to "Выполнено",
	"download_app" to "Скачать приложение",
	"download_invoice" to "Скачать Cчет-Фактуру",
	"download_pdf" to "Скачать PDF",
	"draw_signature" to "Нарисовать подпись",
	"due" to Formattable { (date) -> "Срок исполнения ${date}" },
	"due_date" to "Срок исполнения",
	"due_day" to "в день",
	"due_label" to "Должен",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} должен быть оплачен ${dueWhen}!" },
	"due_today" to "сегодня",
	"due_week" to "в течение недели",
	"duplicate" to "Дублировать",
	"duration_day" to "день",
	"duration_month" to "месяц",
	"duration_week" to "неделя",
	"duration_year" to "год",
	"email" to "Электронная почта",
	"email_blank_error" to "Требуется электронная почта.",
	"email_error" to "Неверный адрес электронной почты.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Спасибо за ваш бизнес. Чтобы просмотреть полный документ ${documentType}, нажмите кнопку в конце этого письма. Обратите внимание, что он действителен до ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Вы получили новый документ: ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "Посмотреть ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Уважаемый ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Спасибо за ваш бизнес. Чтобы просмотреть полный документ ${documentType}, нажмите кнопку в конце этого письма. Пожалуйста, оплатите остаток до ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Вы получили новый документ: ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Электронное письмо для сброса пароля отправлено, пожалуйста, проверьте свой почтовый ящик.",
	"empty_clients_list_message" to "У вас нет клиентов для отслеживания. Добавьте нового клиента.",
	"empty_estimate_list_message" to "Создайте свою первую смету",
	"empty_expenses" to "У вас нет расходов для отслеживания. Добавьте новый расход.",
	"empty_invoice_list_message" to "Создайте свой первый счет-фактуру",
	"empty_items_list_message" to "У вас нет товаров, добавьте новый товар!",
	"empty_signatures_list_message" to "Подписи не найдены. Создайте одну!",
	"empty_taxes_list_message" to "У вас нет налоговых правил. Добавьте новое налоговое правило.",
	"empty_templates_list_message" to "Шаблоны недоступны.",
	"enabled" to "Включено",
	"entry" to "запись",
	"equity" to "собственный капитал",
	"estimate_number" to "Оценка #",
	"estimates" to "Оценки",
	"estimates_report" to "Оценки",
	"expense_bank_fees" to "Банковские Сборы",
	"expense_benefits" to "Преимущества",
	"expense_communication" to "Коммуникация",
	"expense_debt" to "Долг",
	"expense_deleted" to "Расходы удалены",
	"expense_depreciation" to "Амортизация",
	"expense_gifts" to "Подарки",
	"expense_insurance" to "Страхование",
	"expense_inventory" to "Инвентарь",
	"expense_legal" to "Юридический",
	"expense_maintenance" to "Техобслуживание",
	"expense_marketing" to "Маркетинг",
	"expense_meals" to "Питание",
	"expense_office_supplies" to "Офисные Принадлежности",
	"expense_rent" to "Аренда",
	"expense_salaries" to "Зарплаты",
	"expense_shopping" to "Покупки",
	"expense_software" to "Программное обеспечение",
	"expense_status_available" to "Доступно",
	"expense_status_processing" to "Обработка",
	"expense_tax" to "Налог",
	"expense_training" to "Обучение",
	"expense_transport" to "Транспорт",
	"expense_travel" to "Путешествия",
	"expense_utility" to "Полезность",
	"expenses" to "Расходы",
	"feedback_option" to "Обратная связь",
	"filter" to "Фильтр",
	"first_name_hint" to "Имя",
	"forgot_password" to "Сброс пароля",
	"forgot_password_label" to "Забыли пароль",
	"from" to "С сайта",
	"general_error" to "Произошла ошибка, пожалуйста, повторите попытку позже.",
	"general_ledger" to "главная книга",
	"go_back_label" to "возвращаться",
	"group_by" to "Группа By:",
	"image_view" to "Изображение",
	"install" to "Установить",
	"invalid_credentials_error" to "Неверные учетные данные.",
	"invalid_email_error" to "Неверный адрес электронной почты.",
	"invalid_user_error" to "Неверные учетные данные пользователя.",
	"invoice_deleted_message" to "Счет-фактура удален",
	"invoice_menu" to "Меню счета-фактуры",
	"invoice_number" to "Счет-фактура №",
	"invoices" to "Счета-фактуры",
	"items_option" to "Элементы",
	"keep" to "Сохраните",
	"language_selector" to Formattable { (selected) -> "Язык ${selected}" },
	"last_name_hint" to "Фамилия",
	"ledger" to "бухгалтерская книга",
	"legal" to "Юридический",
	"legal_error" to "Должен принять Условия предоставления услуг и Политику конфиденциальности",
	"liabilities" to "обязательства",
	"loading" to "Загрузка",
	"login_required_error" to "Требуется вход в систему.",
	"logo_view" to "Логотип",
	"logout" to "Выход из системы",
	"logout_prompt" to "Ваши данные не синхронизируются с облаком. Удалять счета, сметы, клиентов и другую информацию при выходе из системы?",
	"main_screen" to "Главный экран",
	"mark_paid" to "Отметить как оплаченный",
	"message" to "Сообщение",
	"missing_sender_msg" to "Документ без информации об отправителе может быть недействительным.",
	"missing_sender_title" to "Отсутствует информация об отправителе",
	"month_april" to "Апрель",
	"month_april_short" to "Апрель",
	"month_august" to "Август",
	"month_august_short" to "Авг.",
	"month_december" to "Декабрь",
	"month_december_short" to "Дек.",
	"month_february" to "Февраль",
	"month_february_short" to "фев.",
	"month_january" to "Январь",
	"month_january_short" to "Янв.",
	"month_july" to "Июль",
	"month_july_short" to "Июль",
	"month_june" to "Июнь",
	"month_june_short" to "Июнь",
	"month_march" to "Март",
	"month_march_short" to "Март",
	"month_may" to "Май",
	"month_may_short" to "Май",
	"month_november" to "Ноябрь",
	"month_november_short" to "Нояб.",
	"month_october" to "Октябрь",
	"month_october_short" to "Окт.",
	"month_september" to "Сентябрь",
	"month_september_short" to "Сент.",
	"multifactor_error" to "Не удалось проверить.",
	"new" to "Новое",
	"news" to "Новости",
	"next" to "Следующий",
	"no" to "нет",
	"no_data_available" to "Нет доступных данных",
	"no_search_results" to "К сожалению, мы не смогли найти никаких результатов.",
	"none" to "Нет",
	"note_fields" to "Примечания",
	"note_hint" to "Примечание",
	"notifications_option" to "Уведомления",
	"ok" to "OK",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Основные части DingVoice навсегда остаются бесплатными. Наша задача - помочь вам развивать свой бизнес, помогайте нам совершенствоваться, делясь своими отзывами.",
	"onboarding_aboutus_title" to "Мы здесь, чтобы помочь",
	"onboarding_paid_1" to "Доступ к профессионально оформленным шаблонам",
	"onboarding_paid_2" to "Глубоко изучите свои финансовые показатели",
	"onboarding_paid_3" to "Быстрое решение проблем",
	"onboarding_paid_4" to "Обеспечьте сохранность своих документов",
	"onboarding_paid_5" to "Будьте в курсе изменений статуса счетов-фактур",
	"onboarding_paid_title" to "Растите еще быстрее с...",
	"onboarding_paywall_message" to "Наслаждайтесь всеми функциями премиум-класса по лучшей цене.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Просто ${currencySymbol}${introAmount} для ${introDuration} ${introPeriod} и затем ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (валюта, сумма, длительность) -> "Всего ${валюта}${сумма} / ${длительность}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Бесплатно в течение ${trialDuration} ${trialPeriod}, а затем просто ${currencySymbol} ${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Бесплатно для ${trialDuration} ${trialPeriod}, затем ${currencySymbol}${introductoryAmount} для ${introductoryDuration} ${introPeriod} и затем просто ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Автовозобновляемая. Отменить в любое время.",
	"onboarding_paywall_restore" to "Восстановить покупку",
	"onboarding_paywall_restore_failure" to "Восстановление покупок не удалось, повторите попытку позже или обратитесь в службу поддержки.",
	"onboarding_paywall_restore_success" to "Восстановление прошло успешно!",
	"onboarding_paywall_title" to "Достичь величия!",
	"onboarding_welcome_message" to "Полный набор инструментов для управления счетами и сметами.",
	"onboarding_welcome_title" to "Добро пожаловать в DingVoice",
	"other_state" to "Другое",
	"overdue_state" to "Просроченные",
	"paid_state" to "Оплачивается",
	"password" to "Пароль",
	"password_blank_error" to "Требуется пароль.",
	"password_mismatch_error" to "Пароли не совпадают.",
	"pay" to "оплатить",
	"payment_instructions_label" to "Инструкции по оплате",
	"payment_reminders" to "Напоминания о платежах",
	"phone_number_fields" to "Номер телефона",
	"phone_number_hint" to "Номер телефона",
	"postcode_hint" to "Почтовый индекс",
	"preview" to "Предварительный просмотр",
	"preview_menu_hint" to "Меню предварительного просмотра",
	"price" to "Цена",
	"privacy_policy" to "Политика конфиденциальности",
	"profile" to "Профиль",
	"profitable_client" to "Ваш самый прибыльный клиент - это...",
	"profits_report" to "Прибыль",
	"promo_content" to "Рекламный контент",
	"quantity_hint" to "QUANTITY",
	"quantity_label_hint" to "Этикетка количества",
	"query_info" to "Запрос позволяет писать пользовательские вычисления, используя в качестве входных данных как товары, так и другие налоговые правила. Добавьте символ @ и описание товара или налога для ссылки на другую запись +, -, *, /, % - математические операторы",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} был получен вашим клиентом." },
	"received_state" to "Получено",
	"registration_code_fields" to "Регистрационный Kод",
	"reminder" to "Напоминание",
	"reports" to "Отчеты",
	"reports_not_available" to "Нет доступных отчетов, вернитесь позже.",
	"reveal_password" to "Раскрыть пароль",
	"revenue" to "доход",
	"sales_tax" to "НДС",
	"sales_tax_number" to "Номер НДС",
	"save" to "Сохранить",
	"scan_document" to "Сканировать документ",
	"search" to "Поиск",
	"select" to "Выбрать",
	"select_icon" to "Выбрать иконку",
	"select_signature" to "Выберите подпись",
	"select_template" to "Выберите шаблон",
	"send" to "Отправить",
	"sent_state" to "Отправлено",
	"setup_sender" to "Настроить отправителя",
	"share_option" to "Поделиться",
	"ship_to" to "Отправка в",
	"sign_in" to "Войти",
	"sign_in_apple" to "Вход в систему с помощью Apple",
	"sign_in_email" to "Войти с помощью электронной почты",
	"sign_in_facebook" to "Войти с помощью Facebook",
	"sign_in_google" to "Войти в систему с помощью Google",
	"sign_up" to "Зарегистрироваться",
	"sign_up_label" to "У вас нет аккаунта?",
	"sign_up_link_label" to "ПОДПИСЫВАЙТЕСЬ!",
	"signatures_option" to "Подписи",
	"skip" to "Скип",
	"sold_items_report" to "Проданные Tовары",
	"sort_by" to "Сортировать по:",
	"status_update" to "Обновление статуса документа",
	"sub_annual" to "ежегодный",
	"sub_annual_price" to Formattable { (price) -> "${price}/год" },
	"sub_backups" to "Облачные резервные копии",
	"sub_insights" to "Инсайты",
	"sub_month" to "Ежемесячно",
	"sub_monthly_price" to Formattable { (price) -> "${price}/месяц" },
	"sub_notifications" to "Уведомления",
	"sub_popup_message" to "Подписки скоро появятся. Загляните сюда через некоторое время.",
	"sub_support" to "Поддержка",
	"sub_templates" to "Шаблоны",
	"subscribe" to "Подписаться",
	"subscription_header" to "Развивайте свой бизнес с помощью:",
	"subscription_option" to "Подписка",
	"subscriptions_load_fail" to "Извините, в данный момент мы не можем восстановить ваши подписки. Пожалуйста, повторите попытку позже или обратитесь за помощью в нашу службу поддержки.",
	"subtotal" to Formattable { (sum) -> "Промежуточный итог: ${sum}" },
	"subtotal_label" to "Промежуточный итог",
	"sum" to "Сумма",
	"support_option" to "Поддержка",
	"switch_reports" to "Переключить Oтчет",
	"tax" to "Налог",
	"tax_deleted_message" to "Налог удален",
	"tax_description_warning" to "При использовании это отображается в счете-фактуре",
	"tax_input_flat" to "Плоский",
	"tax_input_percent" to "Процент",
	"tax_number_fields" to "Налоговый номер",
	"tax_number_hint" to "Налоговый номер",
	"tax_query_info_description" to "Информация о запросе",
	"tax_report" to "Налог",
	"tax_rules_option" to "Налоговые правила",
	"taxed_clients_report" to "Налогооблагаемые клиенты",
	"template" to "Шаблон",
	"template_selector_info" to "Проведите пальцем, чтобы изменить шаблон по умолчанию",
	"template_unlock_promo" to "Получите доступ к большему количеству шаблонов с Premium",
	"templates_option" to "Шаблоны",
	"terms_of_service" to "Условия предоставления услуг",
	"thank_you" to "Спасибо",
	"to" to "На",
	"total" to Formattable { (sum) -> "Итого: ${sum}" },
	"total_label" to "Всего",
	"undo_action" to "Отменить",
	"unit" to "Единица",
	"unit_cost_hint" to "Стоимость единицы продукции",
	"unknown_client" to "Неизвестный Kлиент",
	"unlock" to "Разблокировать",
	"unnamed_item" to "Безымянный элемент",
	"upgrade" to "Обновление",
	"user_collision_error" to "Учетная запись уже существует.",
	"valid_until" to "Действует до",
	"warning" to "Внимание!",
	"yes" to "да",
    )
    
}