// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Th  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "ยอมรับ ${termsOfService} และ ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "รหัสไม่ถูกต้อง"
override val add_client_action: String = "เพิ่มไคลเอนต์"
override val add_customer: String = "เพิ่มลูกค้า"
override val add_expense: String = "เพิ่มค่าใช้จ่าย"
override val add_fields_label: String = "เพิ่ม:"
override val add_invoice_action: String = "สร้าง"
override val add_item_action: String = "เพิ่มรายการ"
override val add_item_button: String = "เพิ่มรายการ"
override val add_signature_action: String = "เพิ่มลายเซ็น"
override val add_tax_action: String = "เพิ่มภาษี"
override val address_fields: String = "ที่อยู่"
override val address_line_hint: String = "บรรทัดที่อยู่"
override val advanced: String = "ดึก"
override val agree_marketing: String = "ฉันตกลงที่จะรับการสื่อสารการตลาดโดยตรง"
override val all_notifications: String = "การแจ้งเตือนทั้งหมด"
override val amortization: String = "amortization"
override val amount: String = "ปริมาณ"
override val asset: String = "asset"
override val attach_file: String = "แนบไฟล์"
override val attachment: String = "ไฟล์แนบ"
override val auth_error: String = "การกระทําล้มเหลว ให้ลองอีกครั้ง"
override val available_for_paid: String = "การซิงค์บนคลาวด์จะพร้อมใช้งานภายใต้การสมัครใช้งานแบบชําระเงินเท่านั้น"
override val backups_option: String = "สำรอง ข้อมูล"
override val balance: String = "balance"
override val bank_account: String = "บัญชีธนาคาร"
override val bill_number: String = "หมายเลขบิล"
override val cancel: String = "ยกเลิก"
override val cancel_trial: String = "ยกเลิกได้ตลอดเวลา"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "หมวดหมู่"
override val category: String = "หมวดหมู่"
override val category_add: String = "เพิ่มหมวดหมู่"
override val category_delete: String = "ลบหมวดหมู่"
override val category_name: String = "ชื่อหมวดหมู่"
override val change_language: Formattable = Formattable { (language) -> "ภาษาของใบแจ้งหนี้: ${language}" }
override val change_language_label: String = "ภาษาที่ใช้ในใบแจ้งหนี้"
override val change_template: String = "เปลี่ยนเท็มเพลต"
override val city_hint: String = "เมือง"
override val clear: String = "ใส"
override val client: String = "ลูกค้า"
override val client_actions: String = "การดําเนินการของไคลเอนต์"
override val client_deleted_message: String = "ลบไคลเอนต์แล้ว"
override val clients_error_required_fields: String = "ต้องมีอย่างน้อยหนึ่งค่า"
override val clients_option: String = "ไคล เอนต์"
override val close: String = "ปิด"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "เร็ว ๆ นี้!"
override val company_logo: String = "โลโก้บริษัท"
override val company_name_hint: String = "บริษัท"
override val confirm_password_label: String = "ยืนยันรหัสผ่าน"
override val confirmation_title: String = "คุณแน่ใจหรือไม่"
override val convert_to_invoice: String = "แปลงเป็นใบแจ้งหนี้"
override val country_hint: String = "ประเทศ"
override val create_client: String = "สร้างไคลเอนต์"
override val create_company: String = "สร้างบริษัท"
override val create_document: String = "สร้างเอกสาร"
override val create_estimate: String = "กะ"
override val create_invoice: String = "ใบส่งของ"
override val create_item: String = "สร้างรายการ"
override val create_new_client: String = "สร้างไคลเอนต์ใหม่"
override val create_new_item: String = "สร้างรายการใหม่"
override val create_new_tax: String = "สร้างภาษีใหม่"
override val create_signature: String = "ลายเซ็น"
override val created: String = "สร้าง"
override val credit: String = "credit"
override val customer: String = "ลูกค้า"
override val date: String = "วันที่"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "สกุลเงินเริ่มต้น: ${currency}" }
override val default_currency_label: String = "สกุลเงินเริ่มต้น"
override val delete: String = "ลบ"
override val delete_account: String = "ลบบัญชี"
override val delete_confirmation_message: String = "ข้อมูลทั้งหมดของคุณจะสูญหาย"
override val delete_item_title: String = "ลบรายการหรือไม่?"
override val delete_signature: String = "ลบลายเซ็น"
override val depreciation: String = "depreciation"
override val description: String = "การบรรยาย"
override val description_hint: String = "การบรรยาย"
override val detailed_report: String = "รายงานโดยละเอียด"
override val disabled: String = "พิการ"
override val discount: String = "การลดราคา"
override val discount_hint: String = "ส่วนลด"
override val display_af: String = "แอฟริกัน"
override val display_am: String = "อัมฮาริก"
override val display_ar: String = "อารบิก"
override val display_as: String = "อัสสัม"
override val display_az: String = "อาเซอร์ไบจาน"
override val display_be: String = "เบลารุส"
override val display_bg: String = "บัลแกเรีย"
override val display_bn: String = "เบงกาลี"
override val display_bs: String = "บอสเนีย"
override val display_ca: String = "คาตาลัน"
override val display_cs: String = "เช็ก"
override val display_da: String = "ภาษาเดนมาร์ก"
override val display_de: String = "เยอรมัน"
override val display_el: String = "กรีก"
override val display_en: String = "ภาษาอังกฤษ"
override val display_es: String = "สเปน"
override val display_es_MX: String = "สเปน (เม็กซิโก)"
override val display_et: String = "เอสโตเนีย"
override val display_eu: String = "บาสก์"
override val display_fa: String = "เปอร์เซีย"
override val display_fi: String = "ภาษาฟินแลนด์"
override val display_fil: String = "ฟิลิปปินส์"
override val display_fr: String = "ภาษาฝรั่งเศส"
override val display_fr_CA: String = "ภาษาฝรั่งเศสแบบแคนาดา"
override val display_gl: String = "กาลิเซีย"
override val display_gu: String = "คุชราต"
override val display_he: String = "ภาษาฮิบรู"
override val display_hi: String = "เลขที่"
override val display_hr: String = "โครเอเชีย"
override val display_hu: String = "ฮังการี"
override val display_hy: String = "อาร์เมเนีย"
override val display_id: String = "ชาวอินโดนีเซีย"
override val display_is: String = "ไอซ์แลนด์"
override val display_it: String = "ภาษาอิตาลี"
override val display_ja: String = "ญี่ปุ่น"
override val display_ka: String = "จอร์เจียน"
override val display_kk: String = "คาซัค"
override val display_km: String = "เขมรกลาง"
override val display_kn: String = "กันนาดา"
override val display_ko: String = "เกาหลี"
override val display_ky: String = "คีร์กีซ"
override val display_lo: String = "แรงงาน"
override val display_lt: String = "ลิทัวเนีย"
override val display_lv: String = "ลัตเวีย"
override val display_mk: String = "ภาษามาซิโดเนีย"
override val display_ml: String = "มาลายาลัม"
override val display_mn: String = "มองโกเลีย"
override val display_mr: String = "มราฐี"
override val display_ms: String = "มาเลย์"
override val display_my: String = "พม่า"
override val display_nb: String = "บุ๊กมอลนอร์เวย์"
override val display_ne: String = "เนปาล"
override val display_nl: String = "ดัตช์"
override val display_or: String = "โอริยา"
override val display_pa: String = "ปัญจาบ"
override val display_pl: String = "ขัด"
override val display_pt: String = "โปรตุเกส"
override val display_pt_BR: String = "โปรตุเกส (บราซิล)"
override val display_pt_PT: String = "โปรตุเกสยุโรป"
override val display_ro: String = "ภาษาโรมาเนีย"
override val display_ru: String = "รัสเซีย"
override val display_si: String = "สิงหล"
override val display_sk: String = "สโลวัก"
override val display_sl: String = "สโลวีเนีย"
override val display_sq: String = "แอลเบเนีย"
override val display_sr: String = "เซอร์เบีย"
override val display_sv: String = "ภาษาสวีเดน"
override val display_sw: String = "ภาษาสวาฮิลี"
override val display_ta: String = "ภาษาทมิฬ"
override val display_te: String = "ภาษาเตลูกู"
override val display_th: String = "ไทย"
override val display_tr: String = "ภาษาตุรกี"
override val display_uk: String = "ยูเครน"
override val display_ur: String = "ภาษาอูรดู"
override val display_uz: String = "อุซเบก"
override val display_vi: String = "ภาษาเวียดนาม"
override val display_zh_Hant_TW: String = "จีน (ไต้หวัน, ตัวเต็ม)"
override val display_zu: String = "ซูลู"
override val document_estimate: String = "กะ"
override val document_invoice: String = "ใบส่งของ"
override val document_quote: String = "อ้างอิง"
override val done: String = "เสร็จแล้ว"
override val download_app: String = "ดาวน์โหลดแอป"
override val download_invoice: String = "ดาวน์โหลดใบแจ้งหนี้"
override val download_pdf: String = "ดาวน์โหลด PDF"
override val draw_signature: String = "การวาดลายเซ็น"
override val due: Formattable = Formattable { (date) -> "ครบกําหนด ${date}" }
override val due_date: String = "วันครบกําหนด"
override val due_day: String = "ในหนึ่งวัน"
override val due_label: String = "ค่าธรรมเนียม"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ครบกำหนด ${dueWhen}!" }
override val due_today: String = "วันนี้"
override val due_week: String = "ในหนึ่งสัปดาห์"
override val duplicate: String = "ทำซ้ำ"
override val duration_day: String = "วัน"
override val duration_month: String = "เดือน"
override val duration_week: String = "สัปดาห์"
override val duration_year: String = "ปี"
override val email: String = "อีเมล"
override val email_blank_error: String = "ต้องการอีเมล"
override val email_error: String = "ที่อยู่อีเมลไม่ถูกต้อง"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "ขอบคุณสำหรับธุรกิจของคุณ หากต้องการดู ${documentType} แบบเต็ม ให้คลิกปุ่มที่ท้ายจดหมายฉบับนี้ โปรดทราบว่าดูได้จนถึง ${dueDate}" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "คุณได้รับ ${documentType} ${invoiceNumber} ใหม่!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ดู ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "เรียน ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "ขอบคุณสำหรับธุรกิจของคุณ หากต้องการดู ${documentType} แบบเต็ม ให้คลิกปุ่มที่ท้ายจดหมายฉบับนี้ โปรดชำระยอดคงเหลือภายใน ${dueDate}" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "คุณได้รับ ${documentType} ${invoiceNumber} ใหม่แล้ว!" }
override val email_reset_success_message: String = "อีเมลเพื่อรีเซ็ตรหัสผ่านที่ส่งโปรดตรวจสอบกล่องจดหมายของคุณ"
override val empty_clients_list_message: String = "คุณไม่มีลูกค้าที่จะติดตาม เพิ่มไคลเอนต์ใหม่"
override val empty_estimate_list_message: String = "สร้างการประเมินแรกของคุณ"
override val empty_expenses: String = "คุณไม่มีค่าใช้จ่ายในการติดตาม เพิ่มค่าใช้จ่ายใหม่"
override val empty_invoice_list_message: String = "สร้างใบแจ้งหนี้แรกของคุณ"
override val empty_items_list_message: String = "คุณไม่มีรายการเพิ่มรายการใหม่!"
override val empty_signatures_list_message: String = "ไม่พบลายเซ็น สร้างหนึ่ง!"
override val empty_taxes_list_message: String = "คุณไม่มีกฎภาษี เพิ่มกฎภาษีใหม่"
override val empty_templates_list_message: String = "แม่แบบไม่พร้อมใช้งาน"
override val enabled: String = "เปิดใช้งานแล้ว"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "ประมาณการ #"
override val estimates: String = "ประเมิน"
override val estimates_report: String = "ประมาณการ"
override val expense_bank_fees: String = "ค่าธรรมเนียมธนาคาร"
override val expense_benefits: String = "ประโยชน์"
override val expense_communication: String = "การสื่อสาร"
override val expense_debt: String = "หนี้"
override val expense_deleted: String = "ลบค่าใช้จ่ายแล้ว"
override val expense_depreciation: String = "ค่าเสื่อมราคา"
override val expense_gifts: String = "ของขวัญ"
override val expense_insurance: String = "ประกันภัย"
override val expense_inventory: String = "รายการสิ่งของ"
override val expense_legal: String = "ถูกกฎหมาย"
override val expense_maintenance: String = "การซ่อมบำรุง"
override val expense_marketing: String = "การตลาด"
override val expense_meals: String = "มื้ออาหาร"
override val expense_office_supplies: String = "เครื่องใช้สำนักงาน"
override val expense_rent: String = "เช่า"
override val expense_salaries: String = "เงินเดือน"
override val expense_shopping: String = "ช้อปปิ้ง"
override val expense_software: String = "ซอฟต์แวร์"
override val expense_status_available: String = "มีอยู่"
override val expense_status_processing: String = "กำลังประมวลผล"
override val expense_tax: String = "ภาษี"
override val expense_training: String = "การฝึกอบรม"
override val expense_transport: String = "ขนส่ง"
override val expense_travel: String = "การท่องเที่ยว"
override val expense_utility: String = "คุณประโยชน์"
override val expenses: String = "ค่าใช้จ่าย"
override val feedback_option: String = "การตอบสนอง"
override val filter: String = "กรอง"
override val first_name_hint: String = "ชื่อ"
override val forgot_password: String = "ตั้งค่ารหัสผ่านใหม่"
override val forgot_password_label: String = "ลืมรหัสผ่าน"
override val from: String = "จาก"
override val general_error: String = "เกิดข้อผิดพลาด โปรดลองอีกครั้งในภายหลัง"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "ย้อนกลับ"
override val group_by: String = "จัดกลุ่มตาม:"
override val image_view: String = "ภาพ"
override val install: String = "ติดตั้ง"
override val invalid_credentials_error: String = "ข้อมูลประจําตัวไม่ถูกต้อง"
override val invalid_email_error: String = "อีเมลไม่ถูกต้อง"
override val invalid_user_error: String = "ข้อมูลประจําตัวของผู้ใช้ไม่ถูกต้อง"
override val invoice_deleted_message: String = "ลบใบแจ้งหนี้แล้ว"
override val invoice_menu: String = "เมนูใบแจ้งหนี้"
override val invoice_number: String = "ใบส่งของ #"
override val invoices: String = "ใบ แจ้ง หนี้"
override val items_option: String = "รายการ"
override val keep: String = "เก็บ"
override val language_selector: Formattable = Formattable { (selected) -> "ภาษา ${selected}" }
override val last_name_hint: String = "นามสกุล"
override val ledger: String = "ledger"
override val legal: String = "ชอบด้วยกฎหมาย"
override val legal_error: String = "ต้องยอมรับข้อกําหนดในการให้บริการและนโยบายความเป็นส่วนตัว"
override val liabilities: String = "liabilities"
override val loading: String = "การโหลด"
override val login_required_error: String = "ต้องเข้าสู่ระบบ"
override val logo_view: String = "โลโก้"
override val logout: String = "ออกจากระบบ"
override val logout_prompt: String = "ข้อมูลของคุณไม่ได้ซิงโครไนซ์กับ Cloud ลบใบแจ้งหนี้ การประเมิน ลูกค้า และข้อมูลอื่นๆ เกี่ยวกับการออกจากระบบหรือไม่?"
override val main_screen: String = "หน้าจอหลัก"
override val mark_paid: String = "ทําเครื่องหมายเป็นชําระแล้ว"
override val message: String = "ข้อความ"
override val missing_sender_msg: String = "เอกสารที่ไม่มีข้อมูลผู้ส่งอาจถือว่าไม่ถูกต้อง"
override val missing_sender_title: String = "ขาดข้อมูลผู้ส่ง"
override val month_april: String = "เม.ย."
override val month_april_short: String = "เม.ย."
override val month_august: String = "สิงหาคม"
override val month_august_short: String = "ส.ค."
override val month_december: String = "ธันวาคม"
override val month_december_short: String = "ธ.ค."
override val month_february: String = "กุมภาพันธ์"
override val month_february_short: String = "ก.พ."
override val month_january: String = "มกราคม"
override val month_january_short: String = "ม.ค."
override val month_july: String = "กรกฎาคม"
override val month_july_short: String = "กรกฎาคม"
override val month_june: String = "มิถุนายน"
override val month_june_short: String = "มิ.ย."
override val month_march: String = "มีนาคม"
override val month_march_short: String = "มี.ค."
override val month_may: String = "พ.ค."
override val month_may_short: String = "พ.ค."
override val month_november: String = "พฤศจิกายน"
override val month_november_short: String = "พ.ย."
override val month_october: String = "ตุลาคม"
override val month_october_short: String = "ต.ค."
override val month_september: String = "กันยายน"
override val month_september_short: String = "ก.ย."
override val multifactor_error: String = "ไม่สามารถตรวจสอบได้"
override val new: String = "ใหม่"
override val news: String = "ข่าว"
override val next: String = "ต่อไป"
override val no: String = "no"
override val no_data_available: String = "ไม่มีข้อมูลที่สามารถใช้ได้"
override val no_search_results: String = "ขออภัย เราไม่พบผลลัพธ์ใดๆ"
override val none: String = "ไม่มี"
override val note_fields: String = "หมาย เหตุ"
override val note_hint: String = "โน้ต"
override val notifications_option: String = "แจ้ง เตือน"
override val ok: String = "ตกลง, ได้"
override val onboarding: String = "ออนบอร์ด"
override val onboarding_aboutus_message: String = "ส่วนสำคัญของ DingVoice นั้นฟรีตลอดไป ภารกิจของเราคือการช่วยให้คุณเติบโตทางธุรกิจ ช่วยเราปรับปรุงด้วยการแบ่งปันความคิดเห็นของคุณ"
override val onboarding_aboutus_title: String = "เราอยู่ที่นี่เพื่อช่วย"
override val onboarding_paid_1: String = "เข้าถึงเทมเพลตที่ดูเป็นมืออาชีพ"
override val onboarding_paid_2: String = "เจาะลึกเรื่องการเงินของคุณ"
override val onboarding_paid_3: String = "แก้ไขปัญหาได้อย่างรวดเร็ว"
override val onboarding_paid_4: String = "รักษาเอกสารของคุณให้ปลอดภัย"
override val onboarding_paid_5: String = "ติดตามข่าวสารล่าสุดเกี่ยวกับการเปลี่ยนแปลงสถานะใบแจ้งหนี้"
override val onboarding_paid_title: String = "เติบโตเร็วขึ้นด้วย..."
override val onboarding_paywall_message: String = "เพลิดเพลินกับฟีเจอร์พรีเมียมทั้งหมดในราคาที่ดีที่สุด"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "เพียง ${currencySymbol}${introAmount} สำหรับ ${introDuration} ${introPeriod} จากนั้น ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "เพียง ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "ฟรีเป็นเวลา ${trialDuration} ${trialPeriod} จากนั้นเพียง ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "ฟรีสำหรับ${trialDuration}${trialPeriod} แล้ว${currencySymbol}${introductoryAmount} สำหรับ${introductoryDuration}${introPeriod} แล้วก็เพียง${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "ต่ออายุอัตโนมัติ ยกเลิกได้ตลอดเวลา"
override val onboarding_paywall_restore: String = "เรียกคืนการซื้อ"
override val onboarding_paywall_restore_failure: String = "การกู้คืนการซื้อล้มเหลว โปรดลองอีกครั้งในภายหลังหรือติดต่อฝ่ายสนับสนุน"
override val onboarding_paywall_restore_success: String = "คืนค่าสำเร็จ!"
override val onboarding_paywall_title: String = "บรรลุความยิ่งใหญ่!"
override val onboarding_welcome_message: String = "ชุดเครื่องมือที่สมบูรณ์สำหรับจัดการใบแจ้งหนี้และประมาณการของคุณ"
override val onboarding_welcome_title: String = "ยินดีต้อนรับสู่ DingVoice"
override val other_state: String = "อื่นๆ"
override val overdue_state: String = "เกิน"
override val paid_state: String = "จ่าย"
override val password: String = "รหัสผ่าน"
override val password_blank_error: String = "ต้องใช้รหัสผ่าน"
override val password_mismatch_error: String = "รหัสผ่านไม่ตรงกัน"
override val pay: String = "pay"
override val payment_instructions_label: String = "คําแนะนําในการชําระเงิน"
override val payment_reminders: String = "จดหมายเตือนชําระเงิน"
override val phone_number_fields: String = "หมายเลขโทรศัพท์"
override val phone_number_hint: String = "หมายเลขโทรศัพท์"
override val postcode_hint: String = "รหัสไปรษณีย์"
override val preview: String = "ดูตัวอย่าง"
override val preview_menu_hint: String = "เมนูแสดงตัวอย่าง"
override val price: String = "ราคา"
override val privacy_policy: String = "นโยบายความเป็นส่วนตัว"
override val profile: String = "โปรไฟล์"
override val profitable_client: String = "ลูกค้าที่ทำกำไรได้มากที่สุดของคุณคือ..."
override val profits_report: String = "กำไร"
override val promo_content: String = "เนื้อหาส่งเสริมการขาย"
override val quantity_hint: String = "ปริมาณ"
override val quantity_label_hint: String = "ป้ายชื่อปริมาณ"
override val query_info: String = "แบบสอบถามช่วยให้คุณสามารถเขียนการคํานวณแบบกําหนดเองที่อ้างถึงทั้งสินค้าและกฎภาษีอื่นๆ เป็นข้อมูลป้อนเข้า เพิ่มสัญลักษณ์ @ และคําอธิบายสินค้าหรือภาษีเพื่อเชื่อมโยงไปยังรายการอื่น +, -, *, *, /, % - ตัวดําเนินการทางคณิตศาสตร์"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "ลูกค้าของคุณได้รับ ${documentType} ${documentNumber} แล้ว" }
override val received_state: String = "ได้รับ"
override val registration_code_fields: String = "รหัสลงทะเบียน"
override val reminder: String = "เตือนความจำ"
override val reports: String = "รายงาน"
override val reports_not_available: String = "ไม่มีรายงานที่สามารถใช้ได้, กลับมาในภายหลัง."
override val reveal_password: String = "เปิดเผยรหัสผ่าน"
override val revenue: String = "revenue"
override val sales_tax: String = "ภาษีมูลค่าเพิ่ม"
override val sales_tax_number: String = "หมายเลขภาษีมูลค่าเพิ่ม"
override val save: String = "ประหยัด"
override val scan_document: String = "สแกนเอกสาร"
override val search: String = "ค้น"
override val select: String = "เลือก"
override val select_icon: String = "เลือกไอคอน"
override val select_signature: String = "เลือกลายเซ็น"
override val select_template: String = "เลือกเท็มเพลต"
override val send: String = "ส่ง"
override val sent_state: String = "ส่ง"
override val setup_sender: String = "ตั้งค่าผู้ส่ง"
override val share_option: String = "ใช้ร่วมกัน"
override val ship_to: String = "ส่งไปที่"
override val sign_in: String = "เข้าสู่ระบบ"
override val sign_in_apple: String = "ลงชื่อเข้าใช้ด้วย Apple"
override val sign_in_email: String = "ลงชื่อเข้าใช้ด้วยอีเมล"
override val sign_in_facebook: String = "ลงชื่อเข้าใช้ด้วย Facebook"
override val sign_in_google: String = "ลงชื่อเข้าใช้ด้วย Google"
override val sign_up: String = "ไซน์อัพ"
override val sign_up_label: String = "ยังไม่มีบัญชี?"
override val sign_up_link_label: String = "ไซน์อัพ!"
override val signatures_option: String = "ลาย เซ็น"
override val skip: String = "ข้าม"
override val sold_items_report: String = "สินค้าที่ขาย"
override val sort_by: String = "เรียงลําดับตาม:"
override val status_update: String = "อัพเดทสถานะเอกสาร"
override val sub_annual: String = "รายปี"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/ปี" }
override val sub_backups: String = "การสำรองข้อมูลระบบคลาวด์"
override val sub_insights: String = "ข้อมูลเชิงลึก"
override val sub_month: String = "รายเดือน"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/เดือน" }
override val sub_notifications: String = "การแจ้งเตือน"
override val sub_popup_message: String = "การสมัครสมาชิกกำลังจะมาถึงเร็ว ๆ นี้กลับมาดูที่นี่ในอีกสักครู่"
override val sub_support: String = "ฝ่ายสนับสนุน"
override val sub_templates: String = "เทมเพลต"
override val subscribe: String = "ลงนาม"
override val subscription_header: String = "ขยายธุรกิจของคุณด้วย:"
override val subscription_option: String = "การสมัครสมาชิก"
override val subscriptions_load_fail: String = "ขออภัย เราไม่สามารถเรียกข้อมูลการสมัครของคุณได้ในขณะนี้ โปรดลองอีกครั้งในภายหลังหรือติดต่อทีมสนับสนุนเพื่อขอความช่วยเหลือ"
override val subtotal: Formattable = Formattable { (sum) -> "ผลรวมย่อย: ${sum}" }
override val subtotal_label: String = "ยอดรวม"
override val sum: String = "ยอดรวม"
override val support_option: String = "สนับสนุน"
override val switch_reports: String = "สลับรายงาน"
override val tax: String = "ภาษี"
override val tax_deleted_message: String = "ลบภาษีแล้ว"
override val tax_description_warning: String = "ถ้าใช้สิ่งนี้จะแสดงบนใบแจ้งหนี้"
override val tax_input_flat: String = "แบน"
override val tax_input_percent: String = "ร้อยละ"
override val tax_number_fields: String = "หมายเลขภาษี"
override val tax_number_hint: String = "หมายเลขภาษี"
override val tax_query_info_description: String = "ข้อมูลแบบสอบถาม"
override val tax_report: String = "ภาษี"
override val tax_rules_option: String = "กฎภาษี"
override val taxed_clients_report: String = "ลูกค้าที่ต้องเสียภาษี"
override val template: String = "แม่ แบบ"
override val template_selector_info: String = "ปัดนิ้วเพื่อเปลี่ยนเทมเพลตเริ่มต้น"
override val template_unlock_promo: String = "เข้าถึงเทมเพลตเพิ่มเติมด้วย Premium"
override val templates_option: String = "แม่ แบบ"
override val terms_of_service: String = "ข้อกําหนดในการให้บริการ"
override val thank_you: String = "ขอบคุณ"
override val to: String = "ถึง"
override val total: Formattable = Formattable { (sum) -> "ทั้งหมด: ${sum}" }
override val total_label: String = "รวม"
override val undo_action: String = "แก้"
override val unit: String = "หน่วย"
override val unit_cost_hint: String = "ต้นทุนต่อหน่วย"
override val unknown_client: String = "ลูกค้าที่ไม่รู้จัก"
override val unlock: String = "ปลดล็อค"
override val unnamed_item: String = "รายการที่ไม่มีชื่อ"
override val upgrade: String = "อัพ เกรด"
override val user_collision_error: String = "มีบัญชีผู้ใช้อยู่แล้ว"
override val valid_until: String = "ใช้ได้จนถึง"
override val warning: String = "คำเตือน!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "ยอมรับ ${termsOfService} และ ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "รหัสไม่ถูกต้อง",
	"add_client_action" to "เพิ่มไคลเอนต์",
	"add_customer" to "เพิ่มลูกค้า",
	"add_expense" to "เพิ่มค่าใช้จ่าย",
	"add_fields_label" to "เพิ่ม:",
	"add_invoice_action" to "สร้าง",
	"add_item_action" to "เพิ่มรายการ",
	"add_item_button" to "เพิ่มรายการ",
	"add_signature_action" to "เพิ่มลายเซ็น",
	"add_tax_action" to "เพิ่มภาษี",
	"address_fields" to "ที่อยู่",
	"address_line_hint" to "บรรทัดที่อยู่",
	"advanced" to "ดึก",
	"agree_marketing" to "ฉันตกลงที่จะรับการสื่อสารการตลาดโดยตรง",
	"all_notifications" to "การแจ้งเตือนทั้งหมด",
	"amortization" to "amortization",
	"amount" to "ปริมาณ",
	"asset" to "asset",
	"attach_file" to "แนบไฟล์",
	"attachment" to "ไฟล์แนบ",
	"auth_error" to "การกระทําล้มเหลว ให้ลองอีกครั้ง",
	"available_for_paid" to "การซิงค์บนคลาวด์จะพร้อมใช้งานภายใต้การสมัครใช้งานแบบชําระเงินเท่านั้น",
	"backups_option" to "สำรอง ข้อมูล",
	"balance" to "balance",
	"bank_account" to "บัญชีธนาคาร",
	"bill_number" to "หมายเลขบิล",
	"cancel" to "ยกเลิก",
	"cancel_trial" to "ยกเลิกได้ตลอดเวลา",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "หมวดหมู่",
	"category" to "หมวดหมู่",
	"category_add" to "เพิ่มหมวดหมู่",
	"category_delete" to "ลบหมวดหมู่",
	"category_name" to "ชื่อหมวดหมู่",
	"change_language" to Formattable { (language) -> "ภาษาของใบแจ้งหนี้: ${language}" },
	"change_language_label" to "ภาษาที่ใช้ในใบแจ้งหนี้",
	"change_template" to "เปลี่ยนเท็มเพลต",
	"city_hint" to "เมือง",
	"clear" to "ใส",
	"client" to "ลูกค้า",
	"client_actions" to "การดําเนินการของไคลเอนต์",
	"client_deleted_message" to "ลบไคลเอนต์แล้ว",
	"clients_error_required_fields" to "ต้องมีอย่างน้อยหนึ่งค่า",
	"clients_option" to "ไคล เอนต์",
	"close" to "ปิด",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "เร็ว ๆ นี้!",
	"company_logo" to "โลโก้บริษัท",
	"company_name_hint" to "บริษัท",
	"confirm_password_label" to "ยืนยันรหัสผ่าน",
	"confirmation_title" to "คุณแน่ใจหรือไม่",
	"convert_to_invoice" to "แปลงเป็นใบแจ้งหนี้",
	"country_hint" to "ประเทศ",
	"create_client" to "สร้างไคลเอนต์",
	"create_company" to "สร้างบริษัท",
	"create_document" to "สร้างเอกสาร",
	"create_estimate" to "กะ",
	"create_invoice" to "ใบส่งของ",
	"create_item" to "สร้างรายการ",
	"create_new_client" to "สร้างไคลเอนต์ใหม่",
	"create_new_item" to "สร้างรายการใหม่",
	"create_new_tax" to "สร้างภาษีใหม่",
	"create_signature" to "ลายเซ็น",
	"created" to "สร้าง",
	"credit" to "credit",
	"customer" to "ลูกค้า",
	"date" to "วันที่",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "สกุลเงินเริ่มต้น: ${currency}" },
	"default_currency_label" to "สกุลเงินเริ่มต้น",
	"delete" to "ลบ",
	"delete_account" to "ลบบัญชี",
	"delete_confirmation_message" to "ข้อมูลทั้งหมดของคุณจะสูญหาย",
	"delete_item_title" to "ลบรายการหรือไม่?",
	"delete_signature" to "ลบลายเซ็น",
	"depreciation" to "depreciation",
	"description" to "การบรรยาย",
	"description_hint" to "การบรรยาย",
	"detailed_report" to "รายงานโดยละเอียด",
	"disabled" to "พิการ",
	"discount" to "การลดราคา",
	"discount_hint" to "ส่วนลด",
	"display_af" to "แอฟริกัน",
	"display_am" to "อัมฮาริก",
	"display_ar" to "อารบิก",
	"display_as" to "อัสสัม",
	"display_az" to "อาเซอร์ไบจาน",
	"display_be" to "เบลารุส",
	"display_bg" to "บัลแกเรีย",
	"display_bn" to "เบงกาลี",
	"display_bs" to "บอสเนีย",
	"display_ca" to "คาตาลัน",
	"display_cs" to "เช็ก",
	"display_da" to "ภาษาเดนมาร์ก",
	"display_de" to "เยอรมัน",
	"display_el" to "กรีก",
	"display_en" to "ภาษาอังกฤษ",
	"display_es" to "สเปน",
	"display_es_MX" to "สเปน (เม็กซิโก)",
	"display_et" to "เอสโตเนีย",
	"display_eu" to "บาสก์",
	"display_fa" to "เปอร์เซีย",
	"display_fi" to "ภาษาฟินแลนด์",
	"display_fil" to "ฟิลิปปินส์",
	"display_fr" to "ภาษาฝรั่งเศส",
	"display_fr_CA" to "ภาษาฝรั่งเศสแบบแคนาดา",
	"display_gl" to "กาลิเซีย",
	"display_gu" to "คุชราต",
	"display_he" to "ภาษาฮิบรู",
	"display_hi" to "เลขที่",
	"display_hr" to "โครเอเชีย",
	"display_hu" to "ฮังการี",
	"display_hy" to "อาร์เมเนีย",
	"display_id" to "ชาวอินโดนีเซีย",
	"display_is" to "ไอซ์แลนด์",
	"display_it" to "ภาษาอิตาลี",
	"display_ja" to "ญี่ปุ่น",
	"display_ka" to "จอร์เจียน",
	"display_kk" to "คาซัค",
	"display_km" to "เขมรกลาง",
	"display_kn" to "กันนาดา",
	"display_ko" to "เกาหลี",
	"display_ky" to "คีร์กีซ",
	"display_lo" to "แรงงาน",
	"display_lt" to "ลิทัวเนีย",
	"display_lv" to "ลัตเวีย",
	"display_mk" to "ภาษามาซิโดเนีย",
	"display_ml" to "มาลายาลัม",
	"display_mn" to "มองโกเลีย",
	"display_mr" to "มราฐี",
	"display_ms" to "มาเลย์",
	"display_my" to "พม่า",
	"display_nb" to "บุ๊กมอลนอร์เวย์",
	"display_ne" to "เนปาล",
	"display_nl" to "ดัตช์",
	"display_or" to "โอริยา",
	"display_pa" to "ปัญจาบ",
	"display_pl" to "ขัด",
	"display_pt" to "โปรตุเกส",
	"display_pt_BR" to "โปรตุเกส (บราซิล)",
	"display_pt_PT" to "โปรตุเกสยุโรป",
	"display_ro" to "ภาษาโรมาเนีย",
	"display_ru" to "รัสเซีย",
	"display_si" to "สิงหล",
	"display_sk" to "สโลวัก",
	"display_sl" to "สโลวีเนีย",
	"display_sq" to "แอลเบเนีย",
	"display_sr" to "เซอร์เบีย",
	"display_sv" to "ภาษาสวีเดน",
	"display_sw" to "ภาษาสวาฮิลี",
	"display_ta" to "ภาษาทมิฬ",
	"display_te" to "ภาษาเตลูกู",
	"display_th" to "ไทย",
	"display_tr" to "ภาษาตุรกี",
	"display_uk" to "ยูเครน",
	"display_ur" to "ภาษาอูรดู",
	"display_uz" to "อุซเบก",
	"display_vi" to "ภาษาเวียดนาม",
	"display_zh_Hant_TW" to "จีน (ไต้หวัน, ตัวเต็ม)",
	"display_zu" to "ซูลู",
	"document_estimate" to "กะ",
	"document_invoice" to "ใบส่งของ",
	"document_quote" to "อ้างอิง",
	"done" to "เสร็จแล้ว",
	"download_app" to "ดาวน์โหลดแอป",
	"download_invoice" to "ดาวน์โหลดใบแจ้งหนี้",
	"download_pdf" to "ดาวน์โหลด PDF",
	"draw_signature" to "การวาดลายเซ็น",
	"due" to Formattable { (date) -> "ครบกําหนด ${date}" },
	"due_date" to "วันครบกําหนด",
	"due_day" to "ในหนึ่งวัน",
	"due_label" to "ค่าธรรมเนียม",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ครบกำหนด ${dueWhen}!" },
	"due_today" to "วันนี้",
	"due_week" to "ในหนึ่งสัปดาห์",
	"duplicate" to "ทำซ้ำ",
	"duration_day" to "วัน",
	"duration_month" to "เดือน",
	"duration_week" to "สัปดาห์",
	"duration_year" to "ปี",
	"email" to "อีเมล",
	"email_blank_error" to "ต้องการอีเมล",
	"email_error" to "ที่อยู่อีเมลไม่ถูกต้อง",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "ขอบคุณสำหรับธุรกิจของคุณ หากต้องการดู ${documentType} แบบเต็ม ให้คลิกปุ่มที่ท้ายจดหมายฉบับนี้ โปรดทราบว่าดูได้จนถึง ${dueDate}" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "คุณได้รับ ${documentType} ${invoiceNumber} ใหม่!" },
	"email_invoice_cta" to Formattable { (documentType) -> "ดู ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "เรียน ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "ขอบคุณสำหรับธุรกิจของคุณ หากต้องการดู ${documentType} แบบเต็ม ให้คลิกปุ่มที่ท้ายจดหมายฉบับนี้ โปรดชำระยอดคงเหลือภายใน ${dueDate}" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "คุณได้รับ ${documentType} ${invoiceNumber} ใหม่แล้ว!" },
	"email_reset_success_message" to "อีเมลเพื่อรีเซ็ตรหัสผ่านที่ส่งโปรดตรวจสอบกล่องจดหมายของคุณ",
	"empty_clients_list_message" to "คุณไม่มีลูกค้าที่จะติดตาม เพิ่มไคลเอนต์ใหม่",
	"empty_estimate_list_message" to "สร้างการประเมินแรกของคุณ",
	"empty_expenses" to "คุณไม่มีค่าใช้จ่ายในการติดตาม เพิ่มค่าใช้จ่ายใหม่",
	"empty_invoice_list_message" to "สร้างใบแจ้งหนี้แรกของคุณ",
	"empty_items_list_message" to "คุณไม่มีรายการเพิ่มรายการใหม่!",
	"empty_signatures_list_message" to "ไม่พบลายเซ็น สร้างหนึ่ง!",
	"empty_taxes_list_message" to "คุณไม่มีกฎภาษี เพิ่มกฎภาษีใหม่",
	"empty_templates_list_message" to "แม่แบบไม่พร้อมใช้งาน",
	"enabled" to "เปิดใช้งานแล้ว",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "ประมาณการ #",
	"estimates" to "ประเมิน",
	"estimates_report" to "ประมาณการ",
	"expense_bank_fees" to "ค่าธรรมเนียมธนาคาร",
	"expense_benefits" to "ประโยชน์",
	"expense_communication" to "การสื่อสาร",
	"expense_debt" to "หนี้",
	"expense_deleted" to "ลบค่าใช้จ่ายแล้ว",
	"expense_depreciation" to "ค่าเสื่อมราคา",
	"expense_gifts" to "ของขวัญ",
	"expense_insurance" to "ประกันภัย",
	"expense_inventory" to "รายการสิ่งของ",
	"expense_legal" to "ถูกกฎหมาย",
	"expense_maintenance" to "การซ่อมบำรุง",
	"expense_marketing" to "การตลาด",
	"expense_meals" to "มื้ออาหาร",
	"expense_office_supplies" to "เครื่องใช้สำนักงาน",
	"expense_rent" to "เช่า",
	"expense_salaries" to "เงินเดือน",
	"expense_shopping" to "ช้อปปิ้ง",
	"expense_software" to "ซอฟต์แวร์",
	"expense_status_available" to "มีอยู่",
	"expense_status_processing" to "กำลังประมวลผล",
	"expense_tax" to "ภาษี",
	"expense_training" to "การฝึกอบรม",
	"expense_transport" to "ขนส่ง",
	"expense_travel" to "การท่องเที่ยว",
	"expense_utility" to "คุณประโยชน์",
	"expenses" to "ค่าใช้จ่าย",
	"feedback_option" to "การตอบสนอง",
	"filter" to "กรอง",
	"first_name_hint" to "ชื่อ",
	"forgot_password" to "ตั้งค่ารหัสผ่านใหม่",
	"forgot_password_label" to "ลืมรหัสผ่าน",
	"from" to "จาก",
	"general_error" to "เกิดข้อผิดพลาด โปรดลองอีกครั้งในภายหลัง",
	"general_ledger" to "general ledger",
	"go_back_label" to "ย้อนกลับ",
	"group_by" to "จัดกลุ่มตาม:",
	"image_view" to "ภาพ",
	"install" to "ติดตั้ง",
	"invalid_credentials_error" to "ข้อมูลประจําตัวไม่ถูกต้อง",
	"invalid_email_error" to "อีเมลไม่ถูกต้อง",
	"invalid_user_error" to "ข้อมูลประจําตัวของผู้ใช้ไม่ถูกต้อง",
	"invoice_deleted_message" to "ลบใบแจ้งหนี้แล้ว",
	"invoice_menu" to "เมนูใบแจ้งหนี้",
	"invoice_number" to "ใบส่งของ #",
	"invoices" to "ใบ แจ้ง หนี้",
	"items_option" to "รายการ",
	"keep" to "เก็บ",
	"language_selector" to Formattable { (selected) -> "ภาษา ${selected}" },
	"last_name_hint" to "นามสกุล",
	"ledger" to "ledger",
	"legal" to "ชอบด้วยกฎหมาย",
	"legal_error" to "ต้องยอมรับข้อกําหนดในการให้บริการและนโยบายความเป็นส่วนตัว",
	"liabilities" to "liabilities",
	"loading" to "การโหลด",
	"login_required_error" to "ต้องเข้าสู่ระบบ",
	"logo_view" to "โลโก้",
	"logout" to "ออกจากระบบ",
	"logout_prompt" to "ข้อมูลของคุณไม่ได้ซิงโครไนซ์กับ Cloud ลบใบแจ้งหนี้ การประเมิน ลูกค้า และข้อมูลอื่นๆ เกี่ยวกับการออกจากระบบหรือไม่?",
	"main_screen" to "หน้าจอหลัก",
	"mark_paid" to "ทําเครื่องหมายเป็นชําระแล้ว",
	"message" to "ข้อความ",
	"missing_sender_msg" to "เอกสารที่ไม่มีข้อมูลผู้ส่งอาจถือว่าไม่ถูกต้อง",
	"missing_sender_title" to "ขาดข้อมูลผู้ส่ง",
	"month_april" to "เม.ย.",
	"month_april_short" to "เม.ย.",
	"month_august" to "สิงหาคม",
	"month_august_short" to "ส.ค.",
	"month_december" to "ธันวาคม",
	"month_december_short" to "ธ.ค.",
	"month_february" to "กุมภาพันธ์",
	"month_february_short" to "ก.พ.",
	"month_january" to "มกราคม",
	"month_january_short" to "ม.ค.",
	"month_july" to "กรกฎาคม",
	"month_july_short" to "กรกฎาคม",
	"month_june" to "มิถุนายน",
	"month_june_short" to "มิ.ย.",
	"month_march" to "มีนาคม",
	"month_march_short" to "มี.ค.",
	"month_may" to "พ.ค.",
	"month_may_short" to "พ.ค.",
	"month_november" to "พฤศจิกายน",
	"month_november_short" to "พ.ย.",
	"month_october" to "ตุลาคม",
	"month_october_short" to "ต.ค.",
	"month_september" to "กันยายน",
	"month_september_short" to "ก.ย.",
	"multifactor_error" to "ไม่สามารถตรวจสอบได้",
	"new" to "ใหม่",
	"news" to "ข่าว",
	"next" to "ต่อไป",
	"no" to "no",
	"no_data_available" to "ไม่มีข้อมูลที่สามารถใช้ได้",
	"no_search_results" to "ขออภัย เราไม่พบผลลัพธ์ใดๆ",
	"none" to "ไม่มี",
	"note_fields" to "หมาย เหตุ",
	"note_hint" to "โน้ต",
	"notifications_option" to "แจ้ง เตือน",
	"ok" to "ตกลง, ได้",
	"onboarding" to "ออนบอร์ด",
	"onboarding_aboutus_message" to "ส่วนสำคัญของ DingVoice นั้นฟรีตลอดไป ภารกิจของเราคือการช่วยให้คุณเติบโตทางธุรกิจ ช่วยเราปรับปรุงด้วยการแบ่งปันความคิดเห็นของคุณ",
	"onboarding_aboutus_title" to "เราอยู่ที่นี่เพื่อช่วย",
	"onboarding_paid_1" to "เข้าถึงเทมเพลตที่ดูเป็นมืออาชีพ",
	"onboarding_paid_2" to "เจาะลึกเรื่องการเงินของคุณ",
	"onboarding_paid_3" to "แก้ไขปัญหาได้อย่างรวดเร็ว",
	"onboarding_paid_4" to "รักษาเอกสารของคุณให้ปลอดภัย",
	"onboarding_paid_5" to "ติดตามข่าวสารล่าสุดเกี่ยวกับการเปลี่ยนแปลงสถานะใบแจ้งหนี้",
	"onboarding_paid_title" to "เติบโตเร็วขึ้นด้วย...",
	"onboarding_paywall_message" to "เพลิดเพลินกับฟีเจอร์พรีเมียมทั้งหมดในราคาที่ดีที่สุด",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "เพียง ${currencySymbol}${introAmount} สำหรับ ${introDuration} ${introPeriod} จากนั้น ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "เพียง ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "ฟรีเป็นเวลา ${trialDuration} ${trialPeriod} จากนั้นเพียง ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "ฟรีสำหรับ${trialDuration}${trialPeriod} แล้ว${currencySymbol}${introductoryAmount} สำหรับ${introductoryDuration}${introPeriod} แล้วก็เพียง${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "ต่ออายุอัตโนมัติ ยกเลิกได้ตลอดเวลา",
	"onboarding_paywall_restore" to "เรียกคืนการซื้อ",
	"onboarding_paywall_restore_failure" to "การกู้คืนการซื้อล้มเหลว โปรดลองอีกครั้งในภายหลังหรือติดต่อฝ่ายสนับสนุน",
	"onboarding_paywall_restore_success" to "คืนค่าสำเร็จ!",
	"onboarding_paywall_title" to "บรรลุความยิ่งใหญ่!",
	"onboarding_welcome_message" to "ชุดเครื่องมือที่สมบูรณ์สำหรับจัดการใบแจ้งหนี้และประมาณการของคุณ",
	"onboarding_welcome_title" to "ยินดีต้อนรับสู่ DingVoice",
	"other_state" to "อื่นๆ",
	"overdue_state" to "เกิน",
	"paid_state" to "จ่าย",
	"password" to "รหัสผ่าน",
	"password_blank_error" to "ต้องใช้รหัสผ่าน",
	"password_mismatch_error" to "รหัสผ่านไม่ตรงกัน",
	"pay" to "pay",
	"payment_instructions_label" to "คําแนะนําในการชําระเงิน",
	"payment_reminders" to "จดหมายเตือนชําระเงิน",
	"phone_number_fields" to "หมายเลขโทรศัพท์",
	"phone_number_hint" to "หมายเลขโทรศัพท์",
	"postcode_hint" to "รหัสไปรษณีย์",
	"preview" to "ดูตัวอย่าง",
	"preview_menu_hint" to "เมนูแสดงตัวอย่าง",
	"price" to "ราคา",
	"privacy_policy" to "นโยบายความเป็นส่วนตัว",
	"profile" to "โปรไฟล์",
	"profitable_client" to "ลูกค้าที่ทำกำไรได้มากที่สุดของคุณคือ...",
	"profits_report" to "กำไร",
	"promo_content" to "เนื้อหาส่งเสริมการขาย",
	"quantity_hint" to "ปริมาณ",
	"quantity_label_hint" to "ป้ายชื่อปริมาณ",
	"query_info" to "แบบสอบถามช่วยให้คุณสามารถเขียนการคํานวณแบบกําหนดเองที่อ้างถึงทั้งสินค้าและกฎภาษีอื่นๆ เป็นข้อมูลป้อนเข้า เพิ่มสัญลักษณ์ @ และคําอธิบายสินค้าหรือภาษีเพื่อเชื่อมโยงไปยังรายการอื่น +, -, *, *, /, % - ตัวดําเนินการทางคณิตศาสตร์",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "ลูกค้าของคุณได้รับ ${documentType} ${documentNumber} แล้ว" },
	"received_state" to "ได้รับ",
	"registration_code_fields" to "รหัสลงทะเบียน",
	"reminder" to "เตือนความจำ",
	"reports" to "รายงาน",
	"reports_not_available" to "ไม่มีรายงานที่สามารถใช้ได้, กลับมาในภายหลัง.",
	"reveal_password" to "เปิดเผยรหัสผ่าน",
	"revenue" to "revenue",
	"sales_tax" to "ภาษีมูลค่าเพิ่ม",
	"sales_tax_number" to "หมายเลขภาษีมูลค่าเพิ่ม",
	"save" to "ประหยัด",
	"scan_document" to "สแกนเอกสาร",
	"search" to "ค้น",
	"select" to "เลือก",
	"select_icon" to "เลือกไอคอน",
	"select_signature" to "เลือกลายเซ็น",
	"select_template" to "เลือกเท็มเพลต",
	"send" to "ส่ง",
	"sent_state" to "ส่ง",
	"setup_sender" to "ตั้งค่าผู้ส่ง",
	"share_option" to "ใช้ร่วมกัน",
	"ship_to" to "ส่งไปที่",
	"sign_in" to "เข้าสู่ระบบ",
	"sign_in_apple" to "ลงชื่อเข้าใช้ด้วย Apple",
	"sign_in_email" to "ลงชื่อเข้าใช้ด้วยอีเมล",
	"sign_in_facebook" to "ลงชื่อเข้าใช้ด้วย Facebook",
	"sign_in_google" to "ลงชื่อเข้าใช้ด้วย Google",
	"sign_up" to "ไซน์อัพ",
	"sign_up_label" to "ยังไม่มีบัญชี?",
	"sign_up_link_label" to "ไซน์อัพ!",
	"signatures_option" to "ลาย เซ็น",
	"skip" to "ข้าม",
	"sold_items_report" to "สินค้าที่ขาย",
	"sort_by" to "เรียงลําดับตาม:",
	"status_update" to "อัพเดทสถานะเอกสาร",
	"sub_annual" to "รายปี",
	"sub_annual_price" to Formattable { (price) -> "${price}/ปี" },
	"sub_backups" to "การสำรองข้อมูลระบบคลาวด์",
	"sub_insights" to "ข้อมูลเชิงลึก",
	"sub_month" to "รายเดือน",
	"sub_monthly_price" to Formattable { (price) -> "${price}/เดือน" },
	"sub_notifications" to "การแจ้งเตือน",
	"sub_popup_message" to "การสมัครสมาชิกกำลังจะมาถึงเร็ว ๆ นี้กลับมาดูที่นี่ในอีกสักครู่",
	"sub_support" to "ฝ่ายสนับสนุน",
	"sub_templates" to "เทมเพลต",
	"subscribe" to "ลงนาม",
	"subscription_header" to "ขยายธุรกิจของคุณด้วย:",
	"subscription_option" to "การสมัครสมาชิก",
	"subscriptions_load_fail" to "ขออภัย เราไม่สามารถเรียกข้อมูลการสมัครของคุณได้ในขณะนี้ โปรดลองอีกครั้งในภายหลังหรือติดต่อทีมสนับสนุนเพื่อขอความช่วยเหลือ",
	"subtotal" to Formattable { (sum) -> "ผลรวมย่อย: ${sum}" },
	"subtotal_label" to "ยอดรวม",
	"sum" to "ยอดรวม",
	"support_option" to "สนับสนุน",
	"switch_reports" to "สลับรายงาน",
	"tax" to "ภาษี",
	"tax_deleted_message" to "ลบภาษีแล้ว",
	"tax_description_warning" to "ถ้าใช้สิ่งนี้จะแสดงบนใบแจ้งหนี้",
	"tax_input_flat" to "แบน",
	"tax_input_percent" to "ร้อยละ",
	"tax_number_fields" to "หมายเลขภาษี",
	"tax_number_hint" to "หมายเลขภาษี",
	"tax_query_info_description" to "ข้อมูลแบบสอบถาม",
	"tax_report" to "ภาษี",
	"tax_rules_option" to "กฎภาษี",
	"taxed_clients_report" to "ลูกค้าที่ต้องเสียภาษี",
	"template" to "แม่ แบบ",
	"template_selector_info" to "ปัดนิ้วเพื่อเปลี่ยนเทมเพลตเริ่มต้น",
	"template_unlock_promo" to "เข้าถึงเทมเพลตเพิ่มเติมด้วย Premium",
	"templates_option" to "แม่ แบบ",
	"terms_of_service" to "ข้อกําหนดในการให้บริการ",
	"thank_you" to "ขอบคุณ",
	"to" to "ถึง",
	"total" to Formattable { (sum) -> "ทั้งหมด: ${sum}" },
	"total_label" to "รวม",
	"undo_action" to "แก้",
	"unit" to "หน่วย",
	"unit_cost_hint" to "ต้นทุนต่อหน่วย",
	"unknown_client" to "ลูกค้าที่ไม่รู้จัก",
	"unlock" to "ปลดล็อค",
	"unnamed_item" to "รายการที่ไม่มีชื่อ",
	"upgrade" to "อัพ เกรด",
	"user_collision_error" to "มีบัญชีผู้ใช้อยู่แล้ว",
	"valid_until" to "ใช้ได้จนถึง",
	"warning" to "คำเตือน!",
	"yes" to "yes",
    )
    
}