// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Kn  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} ಮತ್ತು ${privacyPolicy} ಅನ್ನು ಸ್ವೀಕರಿಸಿ" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "ಅಮಾನ್ಯ ಕೋಡ್."
override val add_client_action: String = "ಕ್ಲೈಂಟ್ ಸೇರಿಸು"
override val add_customer: String = "ಗ್ರಾಹಕ ಸೇರಿಸು"
override val add_expense: String = "ವೆಚ್ಚವನ್ನು ಸೇರಿಸಿ"
override val add_fields_label: String = "ಸೇರಿಸು:"
override val add_invoice_action: String = "ರಚಿಸು"
override val add_item_action: String = "ಐಟಂ ಸೇರಿಸು"
override val add_item_button: String = "ಐಟಂ ಸೇರಿಸು"
override val add_signature_action: String = "ಸಹಿ ಸೇರಿಸಿ"
override val add_tax_action: String = "ತೆರಿಗೆ ಸೇರಿಸಿ"
override val address_fields: String = "ವಿಳಾಸ"
override val address_line_hint: String = "ವಿಳಾಸ ಸಾಲು"
override val advanced: String = "ಆಧುನೀಕೃತ"
override val agree_marketing: String = "ನೇರ ಮಾರ್ಕೆಟಿಂಗ್ ಸಂವಹನಗಳನ್ನು ಸ್ವೀಕರಿಸಲು ನಾನು ಒಪ್ಪುತ್ತೇನೆ"
override val all_notifications: String = "ಎಲ್ಲಾ ಅಧಿಸೂಚನೆಗಳು"
override val amortization: String = "amortization"
override val amount: String = "ಮೊತ್ತ"
override val asset: String = "asset"
override val attach_file: String = "ಕಡತವನ್ನು ಲಗತ್ತಿಸಿ"
override val attachment: String = "ಲಗತ್ತು"
override val auth_error: String = "ಕ್ರಿಯೆ ವಿಫಲವಾಗಿದೆ, ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ."
override val available_for_paid: String = "ಪಾವತಿಸಿದ ಚಂದಾದಾರಿಕೆಅಡಿಯಲ್ಲಿ ಮಾತ್ರ ಕ್ಲೌಡ್ ಸಿಂಕ್ ಲಭ್ಯವಿದೆ."
override val backups_option: String = "ಬ್ಯಾಕಪ್ ಗಳು"
override val balance: String = "balance"
override val bank_account: String = "ಬ್ಯಾಂಕ್ ಖಾತೆ"
override val bill_number: String = "ಬಿಲ್ ಸಂಖ್ಯೆ"
override val cancel: String = "ರದ್ದುಮಾಡಿ"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "ವಿಭಾಗಗಳು"
override val category: String = "ವರ್ಗ"
override val category_add: String = "ವರ್ಗವನ್ನು ಸೇರಿಸಿ"
override val category_delete: String = "ವರ್ಗವನ್ನು ಅಳಿಸಿ"
override val category_name: String = "ವರ್ಗದ ಹೆಸರು"
override val change_language: Formattable = Formattable { (language) -> "ಸರಕುಪಟ್ಟಿ ಭಾಷೆ: ${language}" }
override val change_language_label: String = "ಸರಕುಪಟ್ಟಿ ಭಾಷೆ"
override val change_template: String = "ಟೆಂಪ್ಲೇಟ್ ಬದಲಿಸಿ"
override val city_hint: String = "ನಗರ"
override val clear: String = "ತೆರವುಗೊಳಿಸು"
override val client: String = "ಕ್ಲೈಂಟ್"
override val client_actions: String = "ಕ್ಲೈಂಟ್ ಕ್ರಿಯೆಗಳು"
override val client_deleted_message: String = "ಕ್ಲೈಂಟ್ ಅಳಿಸಲಾಗಿದೆ"
override val clients_error_required_fields: String = "ಕನಿಷ್ಠ ಒಂದು ಮೌಲ್ಯ ಬೇಕು."
override val clients_option: String = "ಕ್ಲೈಂಟ್ ಗಳು"
override val close: String = "ಮುಚ್ಚು"
override val cloud_sync: String = "ಕ್ಲೌಡ್ ಸಿಂಕ್"
override val coming_soon: String = "ಶೀಘ್ರದಲ್ಲೇ ಬರಲಿದೆ!"
override val company_logo: String = "ಕಂಪನಿ ಲೋಗೋ"
override val company_name_hint: String = "ಕಂಪನಿ"
override val confirm_password_label: String = "ಪಾಸ್ ವರ್ಡ್ ದೃಢಪಡಿಸಿ"
override val confirmation_title: String = "ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ?"
override val convert_to_invoice: String = "ಇನ್ ವಾಯ್ಸ್ ಗೆ ಪರಿವರ್ತಿಸಿ"
override val country_hint: String = "ದೇಶ"
override val create_client: String = "ಕ್ಲೈಂಟ್ ರಚಿಸು"
override val create_company: String = "ಕಂಪನಿ ರಚಿಸಿ"
override val create_document: String = "ದಾಖಲೆ ರಚಿಸು"
override val create_estimate: String = "ಅಂದಾಜು"
override val create_invoice: String = "ಇನ್ ವಾಯ್ಸ್"
override val create_item: String = "ಐಟಂ ರಚಿಸು"
override val create_new_client: String = "ಹೊಸ ಕ್ಲೈಂಟ್ ರಚಿಸಿ"
override val create_new_item: String = "ಹೊಸ ಐಟಂ ರಚಿಸಿ"
override val create_new_tax: String = "ಹೊಸ ತೆರಿಗೆಯನ್ನು ರಚಿಸಿ"
override val create_signature: String = "ಸಹಿ"
override val created: String = "ರಚಿಸಲಾಗಿದೆ"
override val credit: String = "credit"
override val customer: String = "ಗ್ರಾಹಕ"
override val date: String = "ದಿನಾಂಕ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "ಡೀಫಾಲ್ಟ್ ಕರೆನ್ಸಿ: ${currency}" }
override val default_currency_label: String = "ಡೀಫಾಲ್ಟ್ ಕರೆನ್ಸಿ"
override val delete: String = "ಅಳಿಸು"
override val delete_account: String = "ಖಾತೆಯನ್ನು ಅಳಿಸಿ"
override val delete_confirmation_message: String = "ನಿಮ್ಮ ಎಲ್ಲಾ ಡೇಟಾ ಕಳೆದುಹೋಗುತ್ತದೆ."
override val delete_item_title: String = "ಐಟಂ ಅಳಿಸಬೇಕೆ?"
override val delete_signature: String = "ಸಹಿ ಅಳಿಸು"
override val depreciation: String = "depreciation"
override val description: String = "ವಿವರಣೆ"
override val description_hint: String = "ವಿವರಣೆ"
override val detailed_report: String = "ವಿವರವಾದ ವರದಿ"
override val disabled: String = "ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ"
override val discount: String = "ರಿಯಾಯಿತಿ"
override val discount_hint: String = "ರಿಯಾಯಿತಿ"
override val display_af: String = "ಆಫ್ರಿಕನ್"
override val display_am: String = "ಅಂಹರಿಕ್"
override val display_ar: String = "ಅರೇಬಿಕ್"
override val display_as: String = "ಅಸ್ಸಾಮಿ"
override val display_az: String = "ಅಜೆರ್ಬೈಜಾನಿ"
override val display_be: String = "ಬೆಲರೂಸಿಯನ್"
override val display_bg: String = "ಬಲ್ಗೇರಿಯನ್"
override val display_bn: String = "ಬೆಂಗಾಲಿ"
override val display_bs: String = "ಬೋಸ್ನಿಯನ್"
override val display_ca: String = "ಕ್ಯಾಟಲಾನ್"
override val display_cs: String = "ಜೆಕ್"
override val display_da: String = "ಡ್ಯಾನಿಶ್"
override val display_de: String = "ಜರ್ಮನ್"
override val display_el: String = "ಗ್ರೀಕ್"
override val display_en: String = "ಆಂಗ್ಲ"
override val display_es: String = "ಸ್ಪ್ಯಾನಿಷ್"
override val display_es_MX: String = "ಸ್ಪ್ಯಾನಿಷ್ (ಮೆಕ್ಸಿಕೋ)"
override val display_et: String = "ಎಸ್ಟೋನಿಯನ್"
override val display_eu: String = "ಬಾಸ್ಕ್"
override val display_fa: String = "ಪರ್ಷಿಯನ್"
override val display_fi: String = "ಫಿನ್ನಿಶ್"
override val display_fil: String = "ಫಿಲಿಪಿನೋ"
override val display_fr: String = "ಫ್ರೆಂಚ್"
override val display_fr_CA: String = "ಕೆನಡಿಯನ್ ಫ್ರೆಂಚ್"
override val display_gl: String = "ಗ್ಯಾಲಿಷಿಯನ್"
override val display_gu: String = "ಗುಜರಾತಿ"
override val display_he: String = "ಹೀಬ್ರೂ"
override val display_hi: String = "ಇಲ್ಲ"
override val display_hr: String = "ಕ್ರೊಯೇಷಿಯನ್"
override val display_hu: String = "ಹಂಗೇರಿಯನ್"
override val display_hy: String = "ಅರ್ಮೇನಿಯನ್"
override val display_id: String = "ಇಂಡೋನೇಷಿಯನ್"
override val display_is: String = "ಐಸ್ಲ್ಯಾಂಡಿಕ್"
override val display_it: String = "ಇಟಾಲಿಯನ್"
override val display_ja: String = "ಜಪಾನೀಸ್"
override val display_ka: String = "ಜಾರ್ಜಿಯನ್"
override val display_kk: String = "ಕಝಕ್"
override val display_km: String = "ಕೇಂದ್ರ ಖಮೇರ್"
override val display_kn: String = "ಕನ್ನಡ"
override val display_ko: String = "ಕೊರಿಯನ್"
override val display_ky: String = "ಕಿರ್ಗಿಜ್"
override val display_lo: String = "ಕಾರ್ಮಿಕ"
override val display_lt: String = "ಲಿಥುವೇನಿಯನ್"
override val display_lv: String = "ಲಟ್ವಿಯನ್"
override val display_mk: String = "ಮೆಸಿಡೋನಿಯನ್"
override val display_ml: String = "ಮಲಯಾಳಂ"
override val display_mn: String = "ಮಂಗೋಲಿಯನ್"
override val display_mr: String = "ಮರಾಠಿ"
override val display_ms: String = "ಮಲಯ"
override val display_my: String = "ಬರ್ಮೀಸ್"
override val display_nb: String = "ನಾರ್ವೇಜಿಯನ್ ಬೊಕ್ಮಾಲ್"
override val display_ne: String = "ನೇಪಾಳಿ"
override val display_nl: String = "ಡಚ್"
override val display_or: String = "ಒರಿಯಾ"
override val display_pa: String = "ಪಂಜಾಬಿ"
override val display_pl: String = "ಹೊಳಪು ಕೊಡು"
override val display_pt: String = "ಪೋರ್ಚುಗೀಸ್"
override val display_pt_BR: String = "ಪೋರ್ಚುಗೀಸ್ (ಬ್ರೆಜಿಲ್)"
override val display_pt_PT: String = "ಯುರೋಪಿಯನ್ ಪೋರ್ಚುಗೀಸ್"
override val display_ro: String = "ರೊಮೇನಿಯನ್"
override val display_ru: String = "ರಷ್ಯನ್"
override val display_si: String = "ಸಿಂಹಳೀಯ"
override val display_sk: String = "ಸ್ಲೋವಾಕ್"
override val display_sl: String = "ಸ್ಲೊವೇನಿಯನ್"
override val display_sq: String = "ಅಲ್ಬೇನಿಯನ್"
override val display_sr: String = "ಸರ್ಬಿಯನ್"
override val display_sv: String = "ಸ್ವೀಡಿಷ್"
override val display_sw: String = "ಸ್ವಾಹಿಲಿ"
override val display_ta: String = "ತಮಿಳು"
override val display_te: String = "ತೆಲುಗು"
override val display_th: String = "ಥಾಯ್"
override val display_tr: String = "ಟರ್ಕಿಶ್"
override val display_uk: String = "ಉಕ್ರೇನಿಯನ್"
override val display_ur: String = "ಉರ್ದು"
override val display_uz: String = "ಉಜ್ಬೆಕ್"
override val display_vi: String = "ವಿಯೆಟ್ನಾಮೀಸ್"
override val display_zh_Hant_TW: String = "ಚೈನೀಸ್ (ತೈವಾನ್, ಸಾಂಪ್ರದಾಯಿಕ)"
override val display_zu: String = "ಜುಲು"
override val document_estimate: String = "ಅಂದಾಜು"
override val document_invoice: String = "ಇನ್ ವಾಯ್ಸ್"
override val document_quote: String = "ಉಲ್ಲೇಖ"
override val done: String = "ಮುಗಿದಿದೆ"
override val download_app: String = "ಅಪ್ಲಿಕೇಶನ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ"
override val download_invoice: String = "ಸರಕುಪಟ್ಟಿ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ"
override val download_pdf: String = "PDF ಅನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ"
override val draw_signature: String = "ಸಹಿ ಯನ್ನು ಚಿತ್ರಿಸಿ"
override val due: Formattable = Formattable { (date) -> "ಬಾಕಿ ${date}" }
override val due_date: String = "ಬಾಕಿ ದಿನಾಂಕ"
override val due_day: String = "ಒಂದು ದಿನದಲ್ಲಿ"
override val due_label: String = "ಬಾಕಿ"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ಗೆ ${dueWhen} ಬಾಕಿಯಿದೆ!" }
override val due_today: String = "ಇಂದು"
override val due_week: String = "ಒಂದು ವಾರದಲ್ಲಿ"
override val duplicate: String = "ನಕಲು"
override val duration_day: String = "ದಿನ"
override val duration_month: String = "ತಿಂಗಳು"
override val duration_week: String = "ವಾರ"
override val duration_year: String = "ವರ್ಷ"
override val email: String = "ಇಮೇಲ್"
override val email_blank_error: String = "ಇಮೇಲ್ ಅಗತ್ಯವಿದೆ."
override val email_error: String = "ಅಮಾನ್ಯ ಇಮೇಲ್ ವಿಳಾಸ."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "ನಿಮ್ಮ ವ್ಯಾಪಾರಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ಪೂರ್ಣ ${documentType} ವೀಕ್ಷಿಸಲು ಈ ಪತ್ರದ ಕೊನೆಯಲ್ಲಿ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ. ಇದು ${dueDate} ವರೆಗೆ ಮಾನ್ಯವಾಗಿರುತ್ತದೆ ಎಂಬುದನ್ನು ಗಮನಿಸಿ." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "ನೀವು ಹೊಸದನ್ನು ಸ್ವೀಕರಿಸಿದ್ದೀರಿ${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} ವೀಕ್ಷಿಸಿ" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "ಆತ್ಮೀಯ ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "ನಿಮ್ಮ ವ್ಯಾಪಾರಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ಪೂರ್ಣ ${documentType} ವೀಕ್ಷಿಸಲು ಈ ಪತ್ರದ ಕೊನೆಯಲ್ಲಿ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ. ದಯವಿಟ್ಟು ${dueDate} ವರೆಗೆ ಬಾಕಿಯನ್ನು ಪಾವತಿಸಿ." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "ನೀವು ಹೊಸದನ್ನು ಸ್ವೀಕರಿಸಿದ್ದೀರಿ${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "ಕಳುಹಿಸಲಾದ ಪಾಸ್ ವರ್ಡ್ ಮರುಹೊಂದಿಸಲು ಇಮೇಲ್, ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇನ್ ಬಾಕ್ಸ್ ಅನ್ನು ಪರಿಶೀಲಿಸಿ."
override val empty_clients_list_message: String = "ಟ್ರ್ಯಾಕ್ ಮಾಡಲು ನಿಮಗೆ ಯಾವುದೇ ಗ್ರಾಹಕರಿಲ್ಲ. ಹೊಸ ಕ್ಲೈಂಟ್ ಸೇರಿಸಿ."
override val empty_estimate_list_message: String = "ನಿಮ್ಮ ಮೊದಲ ಅಂದಾಜು ರಚಿಸಿ"
override val empty_expenses: String = "ನೀವು ಟ್ರ್ಯಾಕ್ ಮಾಡಲು ಯಾವುದೇ ವೆಚ್ಚಗಳನ್ನು ಹೊಂದಿಲ್ಲ. ಹೊಸ ವೆಚ್ಚವನ್ನು ಸೇರಿಸಿ."
override val empty_invoice_list_message: String = "ನಿಮ್ಮ ಮೊದಲ ಇನ್ ವಾಯ್ಸ್ ರಚಿಸಿ"
override val empty_items_list_message: String = "ನಿಮ್ಮ ಬಳಿ ಯಾವುದೇ ಐಟಂಗಳಿಲ್ಲ, ಹೊಸ ಐಟಂ ಸೇರಿಸಿ!"
override val empty_signatures_list_message: String = "ಯಾವುದೇ ಸಹಿಗಳು ಕಂಡುಬಂದಿಲ್ಲ. ಒಂದನ್ನು ರಚಿಸಿ!"
override val empty_taxes_list_message: String = "ನಿಮಗೆ ಯಾವುದೇ ತೆರಿಗೆ ನಿಯಮಗಳಿಲ್ಲ. ಹೊಸ ತೆರಿಗೆ ನಿಯಮವನ್ನು ಸೇರಿಸಿ."
override val empty_templates_list_message: String = "ಟೆಂಪ್ಲೇಟ್ ಗಳು ಲಭ್ಯವಿಲ್ಲ."
override val enabled: String = "ಸಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "ಅಂದಾಜು #"
override val estimates: String = "ಅಂದಾಜುಗಳು"
override val estimates_report: String = "ಅಂದಾಜುಗಳು"
override val expense_bank_fees: String = "ಬ್ಯಾಂಕ್ ಶುಲ್ಕಗಳು"
override val expense_benefits: String = "ಪ್ರಯೋಜನಗಳು"
override val expense_communication: String = "ಸಂವಹನ"
override val expense_debt: String = "ಸಾಲ"
override val expense_deleted: String = "ವೆಚ್ಚವನ್ನು ಅಳಿಸಲಾಗಿದೆ"
override val expense_depreciation: String = "ಸವಕಳಿ"
override val expense_gifts: String = "ಉಡುಗೊರೆಗಳು"
override val expense_insurance: String = "ವಿಮೆ"
override val expense_inventory: String = "ದಾಸ್ತಾನು"
override val expense_legal: String = "ಕಾನೂನುಬದ್ಧ"
override val expense_maintenance: String = "ನಿರ್ವಹಣೆ"
override val expense_marketing: String = "ಮಾರ್ಕೆಟಿಂಗ್"
override val expense_meals: String = "ಊಟ"
override val expense_office_supplies: String = "ಕಚೇರಿ ಸಾಮಗ್ರಿ"
override val expense_rent: String = "ಬಾಡಿಗೆ"
override val expense_salaries: String = "ಸಂಬಳಗಳು"
override val expense_shopping: String = "ಶಾಪಿಂಗ್"
override val expense_software: String = "ಸಾಫ್ಟ್ವೇರ್"
override val expense_status_available: String = "ಲಭ್ಯವಿದೆ"
override val expense_status_processing: String = "ಸಂಸ್ಕರಣೆ"
override val expense_tax: String = "ತೆರಿಗೆ"
override val expense_training: String = "ತರಬೇತಿ"
override val expense_transport: String = "ಸಾರಿಗೆ"
override val expense_travel: String = "ಪ್ರಯಾಣ"
override val expense_utility: String = "ಉಪಯುಕ್ತತೆ"
override val expenses: String = "ವೆಚ್ಚಗಳು"
override val feedback_option: String = "ಪ್ರತಿಕ್ರಿಯೆ"
override val filter: String = "ಫಿಲ್ಟರ್"
override val first_name_hint: String = "ಮೊದಲ ಹೆಸರು"
override val forgot_password: String = "ಪಾಸ್ ವರ್ಡ್ ಮರುಹೊಂದಿಸು"
override val forgot_password_label: String = "ಪಾಸ್ ವರ್ಡ್ ಮರೆತಿದೆ"
override val from: String = "ನಿಂದ"
override val general_error: String = "ದೋಷ ಸಂಭವಿಸಿದೆ, ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "ಹಿಂದೆ ಹೋಗು"
override val group_by: String = "ಇಂದ ಸಮೂಹ:"
override val image_view: String = "ಚಿತ್ರ"
override val install: String = "ಸ್ಥಾಪಿಸಿ"
override val invalid_credentials_error: String = "ಅಮಾನ್ಯ ರುಜುವಾತುಗಳು."
override val invalid_email_error: String = "ಅಮಾನ್ಯ ಇಮೇಲ್."
override val invalid_user_error: String = "ಅಮಾನ್ಯ ಬಳಕೆದಾರ ರುಜುವಾತುಗಳು."
override val invoice_deleted_message: String = "ಇನ್ ವಾಯ್ಸ್ ಅಳಿಸಲಾಗಿದೆ"
override val invoice_menu: String = "ಇನ್ ವಾಯ್ಸ್ ಮೆನು"
override val invoice_number: String = "ಇನ್ ವಾಯ್ಸ್ #"
override val invoices: String = "ಇನ್ ವಾಯ್ಸ್ ಗಳು"
override val items_option: String = "ಐಟಂಗಳು"
override val keep: String = "ಇರಿಸಿ"
override val language_selector: Formattable = Formattable { (selected) -> "ಭಾಷೆ ${selected}" }
override val last_name_hint: String = "ಕೊನೆಯ ಹೆಸರು"
override val ledger: String = "ledger"
override val legal: String = "ಕಾನೂನು"
override val legal_error: String = "ಸೇವಾ ನಿಯಮಗಳು ಮತ್ತು ಗೌಪ್ಯತೆ ನೀತಿಯನ್ನು ಸ್ವೀಕರಿಸಬೇಕು"
override val liabilities: String = "liabilities"
override val loading: String = "ಲೋಡ್ ಆಗುತ್ತಿದೆ"
override val login_required_error: String = "ಲಾಗಿನ್ ಅಗತ್ಯವಿದೆ."
override val logo_view: String = "ಲೋಗೋ"
override val logout: String = "ಲಾಗ್ ಔಟ್"
override val logout_prompt: String = "ನಿಮ್ಮ ಡೇಟಾ ವನ್ನು ಕ್ಲೌಡ್ ನೊಂದಿಗೆ ಸಿಂಕ್ರೊನೈಸ್ ಮಾಡಿಲ್ಲ. ಲಾಗ್ ಔಟ್ ನಲ್ಲಿ ಇನ್ ವಾಯ್ಸ್ ಗಳು, ಅಂದಾಜುಗಳು, ಕ್ಲೈಂಟ್ ಗಳು ಮತ್ತು ಇತರ ಮಾಹಿತಿಯನ್ನು ಅಳಿಸಿ?"
override val main_screen: String = "ಮುಖ್ಯ ಪರದೆ"
override val mark_paid: String = "ಪಾವತಿಸಿದಂತೆ ಗುರುತು"
override val message: String = "ಸಂದೇಶ"
override val missing_sender_msg: String = "ಕಳುಹಿಸುವವರ ಮಾಹಿತಿಯಿಲ್ಲದ ಡಾಕ್ಯುಮೆಂಟ್ ಅಮಾನ್ಯವಾಗಬಹುದು."
override val missing_sender_title: String = "ಕಳುಹಿಸುವವರ ಮಾಹಿತಿ ಕಾಣೆಯಾಗಿದೆ"
override val month_april: String = "ಏಪ್ರಿಲ್"
override val month_april_short: String = "ಏಪ್ರಿಲ್"
override val month_august: String = "ಆಗಸ್ಟ್"
override val month_august_short: String = "ಆಗಸ್ಟ್."
override val month_december: String = "ಡಿಸೆಂಬರ್"
override val month_december_short: String = "ಡಿಸೆಂಬರ್."
override val month_february: String = "ಫೆಬ್ರವರಿ"
override val month_february_short: String = "ಫೆಬ್ರವರಿ."
override val month_january: String = "ಜನವರಿ"
override val month_january_short: String = "ಜನವರಿ."
override val month_july: String = "ಜುಲೈ"
override val month_july_short: String = "ಜುಲೈ"
override val month_june: String = "ಜೂನ್"
override val month_june_short: String = "ಜೂನ್"
override val month_march: String = "ಮಾರ್ಚ್"
override val month_march_short: String = "ಮಾರ್ಚ್"
override val month_may: String = "ಮೇ"
override val month_may_short: String = "ಮೇ"
override val month_november: String = "ನವೆಂಬರ್"
override val month_november_short: String = "ನವೆಂಬರ್."
override val month_october: String = "ಅಕ್ಟೋಬರ್"
override val month_october_short: String = "ಅಕ್ಟೋಬರ್."
override val month_september: String = "ಸೆಪ್ಟೆಂಬರ್"
override val month_september_short: String = "ಸೆ."
override val multifactor_error: String = "ಪರಿಶೀಲಿಸಲು ವಿಫಲವಾಗಿದೆ."
override val new: String = "ಹೊಸದು"
override val news: String = "ಸುದ್ದಿ"
override val next: String = "ಮುಂದೆ"
override val no: String = "no"
override val no_data_available: String = "ಯಾವುದೇ ಮಾಹಿತಿ ಇಲ್ಲ"
override val no_search_results: String = "ಕ್ಷಮಿಸಿ, ನಾವು ಯಾವುದೇ ಫಲಿತಾಂಶಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ."
override val none: String = "ಯಾವುದೂ"
override val note_fields: String = "ಟಿಪ್ಪಣಿಗಳು"
override val note_hint: String = "ಟಿಪ್ಪಣಿ"
override val notifications_option: String = "ಅಧಿಸೂಚನೆಗಳು"
override val ok: String = "ಸರಿ"
override val onboarding: String = "ಆನ್ಬೋರ್ಡಿಂಗ್"
override val onboarding_aboutus_message: String = "DingVoice ನ ಅಗತ್ಯ ಭಾಗಗಳು ಶಾಶ್ವತವಾಗಿ ಉಚಿತ. ನಿಮ್ಮ ವ್ಯವಹಾರವನ್ನು ಬೆಳೆಸಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುವುದು, ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಹಂಚಿಕೊಳ್ಳುವ ಮೂಲಕ ಸುಧಾರಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುವುದು ನಮ್ಮ ಉದ್ದೇಶವಾಗಿದೆ."
override val onboarding_aboutus_title: String = "ನಾವು ಸಹಾಯ ಮಾಡಲು ಇಲ್ಲಿದ್ದೇವೆ"
override val onboarding_paid_1: String = "ವೃತ್ತಿಪರವಾಗಿ ಕಾಣುವ ಟೆಂಪ್ಲೇಟ್‌ಗಳನ್ನು ಪ್ರವೇಶಿಸಿ"
override val onboarding_paid_2: String = "ನಿಮ್ಮ ಹಣಕಾಸಿನ ಬಗ್ಗೆ ಆಳವಾದ ನೋಟವನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ"
override val onboarding_paid_3: String = "ಸಮಸ್ಯೆಗಳನ್ನು ತ್ವರಿತವಾಗಿ ಪರಿಹರಿಸಿ"
override val onboarding_paid_4: String = "ನಿಮ್ಮ ದಾಖಲೆಗಳನ್ನು ಸುರಕ್ಷಿತವಾಗಿರಿಸಿ"
override val onboarding_paid_5: String = "ಸರಕುಪಟ್ಟಿ ಸ್ಥಿತಿ ಬದಲಾವಣೆಗಳೊಂದಿಗೆ ನವೀಕೃತವಾಗಿರಿ"
override val onboarding_paid_title: String = "ಇದರೊಂದಿಗೆ ಇನ್ನಷ್ಟು ವೇಗವಾಗಿ ಬೆಳೆಯಿರಿ..."
override val onboarding_paywall_message: String = "ಇದೀಗ ಉತ್ತಮ ಬೆಲೆಯಲ್ಲಿ ಎಲ್ಲಾ ಪ್ರೀಮಿಯಂ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಆನಂದಿಸಿ."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} ಗೆ ಕೇವಲ ${currencySymbol}${introAmount} ಮತ್ತು ನಂತರ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "ಕೇವಲ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} ಗೆ ಉಚಿತ ಮತ್ತು ನಂತರ ಕೇವಲ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} ಗೆ ಉಚಿತ ನಂತರ ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod} ಮತ್ತು ನಂತರ ಕೇವಲ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "ಸ್ವಯಂ ನವೀಕರಿಸಬಹುದಾದ. ಯಾವಾಗ ಬೇಕಾದರೂ ರದ್ದುಮಾಡಿ."
override val onboarding_paywall_restore: String = "ಖರೀದಿಯನ್ನು ಮರುಸ್ಥಾಪಿಸಿ"
override val onboarding_paywall_restore_failure: String = "ಖರೀದಿಗಳನ್ನು ಮರುಸ್ಥಾಪಿಸುವುದು ವಿಫಲವಾಗಿದೆ, ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ ಅಥವಾ ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ."
override val onboarding_paywall_restore_success: String = "ಮರುಸ್ಥಾಪನೆ ಯಶಸ್ವಿಯಾಗಿದೆ!"
override val onboarding_paywall_title: String = "ಶ್ರೇಷ್ಠತೆಯನ್ನು ಸಾಧಿಸಿ!"
override val onboarding_welcome_message: String = "ನಿಮ್ಮ ಇನ್‌ವಾಯ್ಸ್‌ಗಳು ಮತ್ತು ಅಂದಾಜುಗಳನ್ನು ನಿರ್ವಹಿಸಲು ಸಂಪೂರ್ಣ ಟೂಲ್‌ಸೆಟ್."
override val onboarding_welcome_title: String = "DingVoice ಗೆ ಸುಸ್ವಾಗತ"
override val other_state: String = "ಇತರೆ"
override val overdue_state: String = "ಅವಧಿ ಮೀರಿದ"
override val paid_state: String = "ಪಾವತಿಸಲಾಗಿದೆ"
override val password: String = "ಪಾಸ್ ವರ್ಡ್"
override val password_blank_error: String = "ಪಾಸ್ ವರ್ಡ್ ಅಗತ್ಯವಿದೆ."
override val password_mismatch_error: String = "ಪಾಸ್ ವರ್ಡ್ ಗಳು ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ."
override val pay: String = "pay"
override val payment_instructions_label: String = "ಪಾವತಿ ಸೂಚನೆಗಳು"
override val payment_reminders: String = "ಪಾವತಿ ಜ್ಞಾಪಕಗಳು"
override val phone_number_fields: String = "ದೂರವಾಣಿ ಸಂಖ್ಯೆ"
override val phone_number_hint: String = "ದೂರವಾಣಿ ಸಂಖ್ಯೆ"
override val postcode_hint: String = "ಪೋಸ್ಟ್ ಕೋಡ್"
override val preview: String = "ಮುನ್ನೋಟ"
override val preview_menu_hint: String = "ಮುನ್ನೋಟ ಮೆನು"
override val price: String = "ಬೆಲೆ"
override val privacy_policy: String = "ಗೌಪ್ಯತಾ ನೀತಿ"
override val profile: String = "ಪ್ರೊಫೈಲ್"
override val profitable_client: String = "ನಿಮ್ಮ ಅತ್ಯಂತ ಲಾಭದಾಯಕ ಕ್ಲೈಂಟ್..."
override val profits_report: String = "ಲಾಭಗಳು"
override val promo_content: String = "ಪ್ರಚಾರ ವಿಷಯ"
override val quantity_hint: String = "ಪ್ರಮಾಣ"
override val quantity_label_hint: String = "ಪ್ರಮಾಣ ಲೇಬಲ್"
override val query_info: String = "ಐಟಂಗಳು ಮತ್ತು ಇತರ ತೆರಿಗೆ ನಿಯಮಗಳನ್ನು ಇನ್ ಪುಟ್ ಗಳಾಗಿ ಉಲ್ಲೇಖಿಸಿ ಕಸ್ಟಮ್ ಲೆಕ್ಕಾಚಾರಗಳನ್ನು ಬರೆಯಲು ಕ್ವೆರಿ ನಿಮಗೆ ಅನುಮತಿಸುತ್ತದೆ. ಮತ್ತೊಂದು ನಮೂದು +, -, *, /, % - ಗಣಿತ ಆಪರೇಟರ್ ಗಳಿಗೆ ಲಿಂಕ್ ಮಾಡಲು @ ಸಂಕೇತ ಮತ್ತು ಐಟಂ ಅಥವಾ ತೆರಿಗೆ ವಿವರಣೆಯನ್ನು ಸೇರಿಸಿ"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ಅನ್ನು ನಿಮ್ಮ ಕ್ಲೈಂಟ್ ಸ್ವೀಕರಿಸಿದೆ." }
override val received_state: String = "ಸ್ವೀಕರಿಸಲಾಗಿದೆ"
override val registration_code_fields: String = "ನೋಂದಣಿ ಕೋಡ್"
override val reminder: String = "ಜ್ಞಾಪನೆ"
override val reports: String = "ವರದಿಗಳು"
override val reports_not_available: String = "ಯಾವುದೇ ವರದಿಗಳು ಲಭ್ಯವಿಲ್ಲ, ನಂತರ ಹಿಂತಿರುಗಿ."
override val reveal_password: String = "ಪಾಸ್ ವರ್ಡ್ ಬಹಿರಂಗಪಡಿಸು"
override val revenue: String = "revenue"
override val sales_tax: String = "ವ್ಯಾಟ್"
override val sales_tax_number: String = "ವ್ಯಾಟ್ ಸಂಖ್ಯೆ"
override val save: String = "ಉಳಿಸು"
override val scan_document: String = "ಡಾಕ್ಯುಮೆಂಟ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ"
override val search: String = "ಶೋಧಿಸು"
override val select: String = "ಆಯ್ಕೆ ಮಾಡಿ"
override val select_icon: String = "ಐಕಾನ್ ಆಯ್ಕೆಮಾಡಿ"
override val select_signature: String = "ಸಹಿ ಆಯ್ಕೆಮಾಡಿ"
override val select_template: String = "ಟೆಂಪ್ಲೇಟ್ ಆಯ್ಕೆಮಾಡಿ"
override val send: String = "ಕಳಿಸಿ"
override val sent_state: String = "ಕಳುಹಿಸಲಾಗಿದೆ"
override val setup_sender: String = "ಕಳುಹಿಸುವವರನ್ನು ಹೊಂದಿಸಿ"
override val share_option: String = "ಹಂಚಿಕೊಳ್ಳಿ"
override val ship_to: String = "ಗೆ ಕಳಿಸು"
override val sign_in: String = "ಸೈನ್ ಇನ್ ಮಾಡಿ"
override val sign_in_apple: String = "ಆಪಲ್ ನೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡಿ"
override val sign_in_email: String = "ಇಮೇಲ್ ನೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡಿ"
override val sign_in_facebook: String = "ಫೇಸ್ ಬುಕ್ ನೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡಿ"
override val sign_in_google: String = "ಗೂಗಲ್ ನೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡಿ"
override val sign_up: String = "ಸೈನ್ ಅಪ್ ಮಾಡಿ"
override val sign_up_label: String = "ಖಾತೆ ಇಲ್ಲ?"
override val sign_up_link_label: String = "ಸೈನ್ ಅಪ್ ಮಾಡಿ!"
override val signatures_option: String = "ಸಹಿಗಳು"
override val skip: String = "ಸ್ಕಿಪ್"
override val sold_items_report: String = "ಮಾರಾಟವಾದ ವಸ್ತುಗಳು"
override val sort_by: String = "ಇದರಮೂಲಕ ವಿಂಗಡಿಸು:"
override val status_update: String = "ಡಾಕ್ಯುಮೆಂಟ್ ಸ್ಥಿತಿ ನವೀಕರಣ"
override val sub_annual: String = "ವಾರ್ಷಿಕ"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/ವರ್ಷ" }
override val sub_backups: String = "ಮೇಘ ಬ್ಯಾಕಪ್‌ಗಳು"
override val sub_insights: String = "ಒಳನೋಟಗಳು"
override val sub_month: String = "ಮಾಸಿಕ"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ತಿಂಗಳಿಗೆ" }
override val sub_notifications: String = "ಅಧಿಸೂಚನೆಗಳು"
override val sub_popup_message: String = "ಚಂದಾದಾರಿಕೆಗಳು ಶೀಘ್ರದಲ್ಲೇ ಬರಲಿವೆ. ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಇಲ್ಲಿ ಮತ್ತೆ ಪರಿಶೀಲಿಸಿ."
override val sub_support: String = "ಬೆಂಬಲ"
override val sub_templates: String = "ಟೆಂಪ್ಲೇಟ್ಗಳು"
override val subscribe: String = "ಚಂದಾದಾರರಾಗಿ"
override val subscription_header: String = "ಇದರೊಂದಿಗೆ ನಿಮ್ಮ ವ್ಯಾಪಾರವನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಿ:"
override val subscription_option: String = "ಚಂದಾದಾರಿಕೆ"
override val subscriptions_load_fail: String = "ಕ್ಷಮಿಸಿ, ಈ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ ಚಂದಾದಾರಿಕೆಗಳನ್ನು ಹಿಂಪಡೆಯಲು ನಮಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ ಅಥವಾ ಸಹಾಯಕ್ಕಾಗಿ ನಮ್ಮ ಬೆಂಬಲ ತಂಡವನ್ನು ಸಂಪರ್ಕಿಸಿ."
override val subtotal: Formattable = Formattable { (sum) -> "ಉಪಮೊತ್ತ: ${sum}" }
override val subtotal_label: String = "ಉಪಮೊತ್ತ"
override val sum: String = "ಮೊತ್ತ"
override val support_option: String = "ಬೆಂಬಲ"
override val switch_reports: String = "ಸ್ವಿಚ್ ವರದಿ"
override val tax: String = "ತೆರಿಗೆ"
override val tax_deleted_message: String = "ತೆರಿಗೆ ಅಳಿಸಲಾಗಿದೆ"
override val tax_description_warning: String = "ಇದನ್ನು ಬಳಸಿದರೆ ಇದು ಇನ್ ವಾಯ್ಸ್ ನಲ್ಲಿ ತೋರಿಸುತ್ತದೆ"
override val tax_input_flat: String = "ಚಪ್ಪಟೆ"
override val tax_input_percent: String = "ಪ್ರತಿಶತ"
override val tax_number_fields: String = "ತೆರಿಗೆ ಸಂಖ್ಯೆ"
override val tax_number_hint: String = "ತೆರಿಗೆ ಸಂಖ್ಯೆ"
override val tax_query_info_description: String = "ಕ್ವೆರಿ ಮಾಹಿತಿ"
override val tax_report: String = "ತೆರಿಗೆ"
override val tax_rules_option: String = "ತೆರಿಗೆ ನಿಯಮಗಳು"
override val taxed_clients_report: String = "ತೆರಿಗೆ ವಿಧಿಸಿದ ಗ್ರಾಹಕರು"
override val template: String = "ಟೆಂಪ್ಲೇಟ್"
override val template_selector_info: String = "ಡೀಫಾಲ್ಟ್ ಟೆಂಪ್ಲೇಟ್ ಬದಲಿಸಲು ಸ್ವೈಪ್ ಮಾಡಿ"
override val template_unlock_promo: String = "Premium ನೊಂದಿಗೆ ಹೆಚ್ಚಿನ ಟೆಂಪ್ಲೇಟ್‌ಗಳನ್ನು ಪ್ರವೇಶಿಸಿ"
override val templates_option: String = "ಟೆಂಪ್ಲೇಟ್ ಗಳು"
override val terms_of_service: String = "ಸೇವೆಯ ನಿಯಮಗಳು"
override val thank_you: String = "ಧನ್ಯವಾದಗಳು"
override val to: String = "ಗೆ"
override val total: Formattable = Formattable { (sum) -> "ಒಟ್ಟು: ${sum}" }
override val total_label: String = "ಒಟ್ಟು"
override val undo_action: String = "ಪೂರ್ವಕ್ರಿಯೆ"
override val unit: String = "ಘಟಕ"
override val unit_cost_hint: String = "ಯೂನಿಟ್ ವೆಚ್ಚ"
override val unknown_client: String = "ಅಜ್ಞಾತ ಗ್ರಾಹಕ"
override val unlock: String = "ಅನ್ಲಾಕ್ ಮಾಡಿ"
override val unnamed_item: String = "ಹೆಸರಿಸದ ಐಟಂ"
override val upgrade: String = "ನವೀಕರಿಸು"
override val user_collision_error: String = "ಖಾತೆ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ."
override val valid_until: String = "ವರೆಗೆ ಮಾನ್ಯವಾಗಿರುತ್ತದೆ"
override val warning: String = "ಎಚ್ಚರಿಕೆ!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} ಮತ್ತು ${privacyPolicy} ಅನ್ನು ಸ್ವೀಕರಿಸಿ" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "ಅಮಾನ್ಯ ಕೋಡ್.",
	"add_client_action" to "ಕ್ಲೈಂಟ್ ಸೇರಿಸು",
	"add_customer" to "ಗ್ರಾಹಕ ಸೇರಿಸು",
	"add_expense" to "ವೆಚ್ಚವನ್ನು ಸೇರಿಸಿ",
	"add_fields_label" to "ಸೇರಿಸು:",
	"add_invoice_action" to "ರಚಿಸು",
	"add_item_action" to "ಐಟಂ ಸೇರಿಸು",
	"add_item_button" to "ಐಟಂ ಸೇರಿಸು",
	"add_signature_action" to "ಸಹಿ ಸೇರಿಸಿ",
	"add_tax_action" to "ತೆರಿಗೆ ಸೇರಿಸಿ",
	"address_fields" to "ವಿಳಾಸ",
	"address_line_hint" to "ವಿಳಾಸ ಸಾಲು",
	"advanced" to "ಆಧುನೀಕೃತ",
	"agree_marketing" to "ನೇರ ಮಾರ್ಕೆಟಿಂಗ್ ಸಂವಹನಗಳನ್ನು ಸ್ವೀಕರಿಸಲು ನಾನು ಒಪ್ಪುತ್ತೇನೆ",
	"all_notifications" to "ಎಲ್ಲಾ ಅಧಿಸೂಚನೆಗಳು",
	"amortization" to "amortization",
	"amount" to "ಮೊತ್ತ",
	"asset" to "asset",
	"attach_file" to "ಕಡತವನ್ನು ಲಗತ್ತಿಸಿ",
	"attachment" to "ಲಗತ್ತು",
	"auth_error" to "ಕ್ರಿಯೆ ವಿಫಲವಾಗಿದೆ, ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
	"available_for_paid" to "ಪಾವತಿಸಿದ ಚಂದಾದಾರಿಕೆಅಡಿಯಲ್ಲಿ ಮಾತ್ರ ಕ್ಲೌಡ್ ಸಿಂಕ್ ಲಭ್ಯವಿದೆ.",
	"backups_option" to "ಬ್ಯಾಕಪ್ ಗಳು",
	"balance" to "balance",
	"bank_account" to "ಬ್ಯಾಂಕ್ ಖಾತೆ",
	"bill_number" to "ಬಿಲ್ ಸಂಖ್ಯೆ",
	"cancel" to "ರದ್ದುಮಾಡಿ",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "ವಿಭಾಗಗಳು",
	"category" to "ವರ್ಗ",
	"category_add" to "ವರ್ಗವನ್ನು ಸೇರಿಸಿ",
	"category_delete" to "ವರ್ಗವನ್ನು ಅಳಿಸಿ",
	"category_name" to "ವರ್ಗದ ಹೆಸರು",
	"change_language" to Formattable { (language) -> "ಸರಕುಪಟ್ಟಿ ಭಾಷೆ: ${language}" },
	"change_language_label" to "ಸರಕುಪಟ್ಟಿ ಭಾಷೆ",
	"change_template" to "ಟೆಂಪ್ಲೇಟ್ ಬದಲಿಸಿ",
	"city_hint" to "ನಗರ",
	"clear" to "ತೆರವುಗೊಳಿಸು",
	"client" to "ಕ್ಲೈಂಟ್",
	"client_actions" to "ಕ್ಲೈಂಟ್ ಕ್ರಿಯೆಗಳು",
	"client_deleted_message" to "ಕ್ಲೈಂಟ್ ಅಳಿಸಲಾಗಿದೆ",
	"clients_error_required_fields" to "ಕನಿಷ್ಠ ಒಂದು ಮೌಲ್ಯ ಬೇಕು.",
	"clients_option" to "ಕ್ಲೈಂಟ್ ಗಳು",
	"close" to "ಮುಚ್ಚು",
	"cloud_sync" to "ಕ್ಲೌಡ್ ಸಿಂಕ್",
	"coming_soon" to "ಶೀಘ್ರದಲ್ಲೇ ಬರಲಿದೆ!",
	"company_logo" to "ಕಂಪನಿ ಲೋಗೋ",
	"company_name_hint" to "ಕಂಪನಿ",
	"confirm_password_label" to "ಪಾಸ್ ವರ್ಡ್ ದೃಢಪಡಿಸಿ",
	"confirmation_title" to "ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ?",
	"convert_to_invoice" to "ಇನ್ ವಾಯ್ಸ್ ಗೆ ಪರಿವರ್ತಿಸಿ",
	"country_hint" to "ದೇಶ",
	"create_client" to "ಕ್ಲೈಂಟ್ ರಚಿಸು",
	"create_company" to "ಕಂಪನಿ ರಚಿಸಿ",
	"create_document" to "ದಾಖಲೆ ರಚಿಸು",
	"create_estimate" to "ಅಂದಾಜು",
	"create_invoice" to "ಇನ್ ವಾಯ್ಸ್",
	"create_item" to "ಐಟಂ ರಚಿಸು",
	"create_new_client" to "ಹೊಸ ಕ್ಲೈಂಟ್ ರಚಿಸಿ",
	"create_new_item" to "ಹೊಸ ಐಟಂ ರಚಿಸಿ",
	"create_new_tax" to "ಹೊಸ ತೆರಿಗೆಯನ್ನು ರಚಿಸಿ",
	"create_signature" to "ಸಹಿ",
	"created" to "ರಚಿಸಲಾಗಿದೆ",
	"credit" to "credit",
	"customer" to "ಗ್ರಾಹಕ",
	"date" to "ದಿನಾಂಕ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "ಡೀಫಾಲ್ಟ್ ಕರೆನ್ಸಿ: ${currency}" },
	"default_currency_label" to "ಡೀಫಾಲ್ಟ್ ಕರೆನ್ಸಿ",
	"delete" to "ಅಳಿಸು",
	"delete_account" to "ಖಾತೆಯನ್ನು ಅಳಿಸಿ",
	"delete_confirmation_message" to "ನಿಮ್ಮ ಎಲ್ಲಾ ಡೇಟಾ ಕಳೆದುಹೋಗುತ್ತದೆ.",
	"delete_item_title" to "ಐಟಂ ಅಳಿಸಬೇಕೆ?",
	"delete_signature" to "ಸಹಿ ಅಳಿಸು",
	"depreciation" to "depreciation",
	"description" to "ವಿವರಣೆ",
	"description_hint" to "ವಿವರಣೆ",
	"detailed_report" to "ವಿವರವಾದ ವರದಿ",
	"disabled" to "ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ",
	"discount" to "ರಿಯಾಯಿತಿ",
	"discount_hint" to "ರಿಯಾಯಿತಿ",
	"display_af" to "ಆಫ್ರಿಕನ್",
	"display_am" to "ಅಂಹರಿಕ್",
	"display_ar" to "ಅರೇಬಿಕ್",
	"display_as" to "ಅಸ್ಸಾಮಿ",
	"display_az" to "ಅಜೆರ್ಬೈಜಾನಿ",
	"display_be" to "ಬೆಲರೂಸಿಯನ್",
	"display_bg" to "ಬಲ್ಗೇರಿಯನ್",
	"display_bn" to "ಬೆಂಗಾಲಿ",
	"display_bs" to "ಬೋಸ್ನಿಯನ್",
	"display_ca" to "ಕ್ಯಾಟಲಾನ್",
	"display_cs" to "ಜೆಕ್",
	"display_da" to "ಡ್ಯಾನಿಶ್",
	"display_de" to "ಜರ್ಮನ್",
	"display_el" to "ಗ್ರೀಕ್",
	"display_en" to "ಆಂಗ್ಲ",
	"display_es" to "ಸ್ಪ್ಯಾನಿಷ್",
	"display_es_MX" to "ಸ್ಪ್ಯಾನಿಷ್ (ಮೆಕ್ಸಿಕೋ)",
	"display_et" to "ಎಸ್ಟೋನಿಯನ್",
	"display_eu" to "ಬಾಸ್ಕ್",
	"display_fa" to "ಪರ್ಷಿಯನ್",
	"display_fi" to "ಫಿನ್ನಿಶ್",
	"display_fil" to "ಫಿಲಿಪಿನೋ",
	"display_fr" to "ಫ್ರೆಂಚ್",
	"display_fr_CA" to "ಕೆನಡಿಯನ್ ಫ್ರೆಂಚ್",
	"display_gl" to "ಗ್ಯಾಲಿಷಿಯನ್",
	"display_gu" to "ಗುಜರಾತಿ",
	"display_he" to "ಹೀಬ್ರೂ",
	"display_hi" to "ಇಲ್ಲ",
	"display_hr" to "ಕ್ರೊಯೇಷಿಯನ್",
	"display_hu" to "ಹಂಗೇರಿಯನ್",
	"display_hy" to "ಅರ್ಮೇನಿಯನ್",
	"display_id" to "ಇಂಡೋನೇಷಿಯನ್",
	"display_is" to "ಐಸ್ಲ್ಯಾಂಡಿಕ್",
	"display_it" to "ಇಟಾಲಿಯನ್",
	"display_ja" to "ಜಪಾನೀಸ್",
	"display_ka" to "ಜಾರ್ಜಿಯನ್",
	"display_kk" to "ಕಝಕ್",
	"display_km" to "ಕೇಂದ್ರ ಖಮೇರ್",
	"display_kn" to "ಕನ್ನಡ",
	"display_ko" to "ಕೊರಿಯನ್",
	"display_ky" to "ಕಿರ್ಗಿಜ್",
	"display_lo" to "ಕಾರ್ಮಿಕ",
	"display_lt" to "ಲಿಥುವೇನಿಯನ್",
	"display_lv" to "ಲಟ್ವಿಯನ್",
	"display_mk" to "ಮೆಸಿಡೋನಿಯನ್",
	"display_ml" to "ಮಲಯಾಳಂ",
	"display_mn" to "ಮಂಗೋಲಿಯನ್",
	"display_mr" to "ಮರಾಠಿ",
	"display_ms" to "ಮಲಯ",
	"display_my" to "ಬರ್ಮೀಸ್",
	"display_nb" to "ನಾರ್ವೇಜಿಯನ್ ಬೊಕ್ಮಾಲ್",
	"display_ne" to "ನೇಪಾಳಿ",
	"display_nl" to "ಡಚ್",
	"display_or" to "ಒರಿಯಾ",
	"display_pa" to "ಪಂಜಾಬಿ",
	"display_pl" to "ಹೊಳಪು ಕೊಡು",
	"display_pt" to "ಪೋರ್ಚುಗೀಸ್",
	"display_pt_BR" to "ಪೋರ್ಚುಗೀಸ್ (ಬ್ರೆಜಿಲ್)",
	"display_pt_PT" to "ಯುರೋಪಿಯನ್ ಪೋರ್ಚುಗೀಸ್",
	"display_ro" to "ರೊಮೇನಿಯನ್",
	"display_ru" to "ರಷ್ಯನ್",
	"display_si" to "ಸಿಂಹಳೀಯ",
	"display_sk" to "ಸ್ಲೋವಾಕ್",
	"display_sl" to "ಸ್ಲೊವೇನಿಯನ್",
	"display_sq" to "ಅಲ್ಬೇನಿಯನ್",
	"display_sr" to "ಸರ್ಬಿಯನ್",
	"display_sv" to "ಸ್ವೀಡಿಷ್",
	"display_sw" to "ಸ್ವಾಹಿಲಿ",
	"display_ta" to "ತಮಿಳು",
	"display_te" to "ತೆಲುಗು",
	"display_th" to "ಥಾಯ್",
	"display_tr" to "ಟರ್ಕಿಶ್",
	"display_uk" to "ಉಕ್ರೇನಿಯನ್",
	"display_ur" to "ಉರ್ದು",
	"display_uz" to "ಉಜ್ಬೆಕ್",
	"display_vi" to "ವಿಯೆಟ್ನಾಮೀಸ್",
	"display_zh_Hant_TW" to "ಚೈನೀಸ್ (ತೈವಾನ್, ಸಾಂಪ್ರದಾಯಿಕ)",
	"display_zu" to "ಜುಲು",
	"document_estimate" to "ಅಂದಾಜು",
	"document_invoice" to "ಇನ್ ವಾಯ್ಸ್",
	"document_quote" to "ಉಲ್ಲೇಖ",
	"done" to "ಮುಗಿದಿದೆ",
	"download_app" to "ಅಪ್ಲಿಕೇಶನ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
	"download_invoice" to "ಸರಕುಪಟ್ಟಿ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
	"download_pdf" to "PDF ಅನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
	"draw_signature" to "ಸಹಿ ಯನ್ನು ಚಿತ್ರಿಸಿ",
	"due" to Formattable { (date) -> "ಬಾಕಿ ${date}" },
	"due_date" to "ಬಾಕಿ ದಿನಾಂಕ",
	"due_day" to "ಒಂದು ದಿನದಲ್ಲಿ",
	"due_label" to "ಬಾಕಿ",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ಗೆ ${dueWhen} ಬಾಕಿಯಿದೆ!" },
	"due_today" to "ಇಂದು",
	"due_week" to "ಒಂದು ವಾರದಲ್ಲಿ",
	"duplicate" to "ನಕಲು",
	"duration_day" to "ದಿನ",
	"duration_month" to "ತಿಂಗಳು",
	"duration_week" to "ವಾರ",
	"duration_year" to "ವರ್ಷ",
	"email" to "ಇಮೇಲ್",
	"email_blank_error" to "ಇಮೇಲ್ ಅಗತ್ಯವಿದೆ.",
	"email_error" to "ಅಮಾನ್ಯ ಇಮೇಲ್ ವಿಳಾಸ.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "ನಿಮ್ಮ ವ್ಯಾಪಾರಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ಪೂರ್ಣ ${documentType} ವೀಕ್ಷಿಸಲು ಈ ಪತ್ರದ ಕೊನೆಯಲ್ಲಿ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ. ಇದು ${dueDate} ವರೆಗೆ ಮಾನ್ಯವಾಗಿರುತ್ತದೆ ಎಂಬುದನ್ನು ಗಮನಿಸಿ." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "ನೀವು ಹೊಸದನ್ನು ಸ್ವೀಕರಿಸಿದ್ದೀರಿ${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} ವೀಕ್ಷಿಸಿ" },
	"email_invoice_greeting" to Formattable { (clientName) -> "ಆತ್ಮೀಯ ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "ನಿಮ್ಮ ವ್ಯಾಪಾರಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ಪೂರ್ಣ ${documentType} ವೀಕ್ಷಿಸಲು ಈ ಪತ್ರದ ಕೊನೆಯಲ್ಲಿ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ. ದಯವಿಟ್ಟು ${dueDate} ವರೆಗೆ ಬಾಕಿಯನ್ನು ಪಾವತಿಸಿ." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "ನೀವು ಹೊಸದನ್ನು ಸ್ವೀಕರಿಸಿದ್ದೀರಿ${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "ಕಳುಹಿಸಲಾದ ಪಾಸ್ ವರ್ಡ್ ಮರುಹೊಂದಿಸಲು ಇಮೇಲ್, ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇನ್ ಬಾಕ್ಸ್ ಅನ್ನು ಪರಿಶೀಲಿಸಿ.",
	"empty_clients_list_message" to "ಟ್ರ್ಯಾಕ್ ಮಾಡಲು ನಿಮಗೆ ಯಾವುದೇ ಗ್ರಾಹಕರಿಲ್ಲ. ಹೊಸ ಕ್ಲೈಂಟ್ ಸೇರಿಸಿ.",
	"empty_estimate_list_message" to "ನಿಮ್ಮ ಮೊದಲ ಅಂದಾಜು ರಚಿಸಿ",
	"empty_expenses" to "ನೀವು ಟ್ರ್ಯಾಕ್ ಮಾಡಲು ಯಾವುದೇ ವೆಚ್ಚಗಳನ್ನು ಹೊಂದಿಲ್ಲ. ಹೊಸ ವೆಚ್ಚವನ್ನು ಸೇರಿಸಿ.",
	"empty_invoice_list_message" to "ನಿಮ್ಮ ಮೊದಲ ಇನ್ ವಾಯ್ಸ್ ರಚಿಸಿ",
	"empty_items_list_message" to "ನಿಮ್ಮ ಬಳಿ ಯಾವುದೇ ಐಟಂಗಳಿಲ್ಲ, ಹೊಸ ಐಟಂ ಸೇರಿಸಿ!",
	"empty_signatures_list_message" to "ಯಾವುದೇ ಸಹಿಗಳು ಕಂಡುಬಂದಿಲ್ಲ. ಒಂದನ್ನು ರಚಿಸಿ!",
	"empty_taxes_list_message" to "ನಿಮಗೆ ಯಾವುದೇ ತೆರಿಗೆ ನಿಯಮಗಳಿಲ್ಲ. ಹೊಸ ತೆರಿಗೆ ನಿಯಮವನ್ನು ಸೇರಿಸಿ.",
	"empty_templates_list_message" to "ಟೆಂಪ್ಲೇಟ್ ಗಳು ಲಭ್ಯವಿಲ್ಲ.",
	"enabled" to "ಸಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "ಅಂದಾಜು #",
	"estimates" to "ಅಂದಾಜುಗಳು",
	"estimates_report" to "ಅಂದಾಜುಗಳು",
	"expense_bank_fees" to "ಬ್ಯಾಂಕ್ ಶುಲ್ಕಗಳು",
	"expense_benefits" to "ಪ್ರಯೋಜನಗಳು",
	"expense_communication" to "ಸಂವಹನ",
	"expense_debt" to "ಸಾಲ",
	"expense_deleted" to "ವೆಚ್ಚವನ್ನು ಅಳಿಸಲಾಗಿದೆ",
	"expense_depreciation" to "ಸವಕಳಿ",
	"expense_gifts" to "ಉಡುಗೊರೆಗಳು",
	"expense_insurance" to "ವಿಮೆ",
	"expense_inventory" to "ದಾಸ್ತಾನು",
	"expense_legal" to "ಕಾನೂನುಬದ್ಧ",
	"expense_maintenance" to "ನಿರ್ವಹಣೆ",
	"expense_marketing" to "ಮಾರ್ಕೆಟಿಂಗ್",
	"expense_meals" to "ಊಟ",
	"expense_office_supplies" to "ಕಚೇರಿ ಸಾಮಗ್ರಿ",
	"expense_rent" to "ಬಾಡಿಗೆ",
	"expense_salaries" to "ಸಂಬಳಗಳು",
	"expense_shopping" to "ಶಾಪಿಂಗ್",
	"expense_software" to "ಸಾಫ್ಟ್ವೇರ್",
	"expense_status_available" to "ಲಭ್ಯವಿದೆ",
	"expense_status_processing" to "ಸಂಸ್ಕರಣೆ",
	"expense_tax" to "ತೆರಿಗೆ",
	"expense_training" to "ತರಬೇತಿ",
	"expense_transport" to "ಸಾರಿಗೆ",
	"expense_travel" to "ಪ್ರಯಾಣ",
	"expense_utility" to "ಉಪಯುಕ್ತತೆ",
	"expenses" to "ವೆಚ್ಚಗಳು",
	"feedback_option" to "ಪ್ರತಿಕ್ರಿಯೆ",
	"filter" to "ಫಿಲ್ಟರ್",
	"first_name_hint" to "ಮೊದಲ ಹೆಸರು",
	"forgot_password" to "ಪಾಸ್ ವರ್ಡ್ ಮರುಹೊಂದಿಸು",
	"forgot_password_label" to "ಪಾಸ್ ವರ್ಡ್ ಮರೆತಿದೆ",
	"from" to "ನಿಂದ",
	"general_error" to "ದೋಷ ಸಂಭವಿಸಿದೆ, ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
	"general_ledger" to "general ledger",
	"go_back_label" to "ಹಿಂದೆ ಹೋಗು",
	"group_by" to "ಇಂದ ಸಮೂಹ:",
	"image_view" to "ಚಿತ್ರ",
	"install" to "ಸ್ಥಾಪಿಸಿ",
	"invalid_credentials_error" to "ಅಮಾನ್ಯ ರುಜುವಾತುಗಳು.",
	"invalid_email_error" to "ಅಮಾನ್ಯ ಇಮೇಲ್.",
	"invalid_user_error" to "ಅಮಾನ್ಯ ಬಳಕೆದಾರ ರುಜುವಾತುಗಳು.",
	"invoice_deleted_message" to "ಇನ್ ವಾಯ್ಸ್ ಅಳಿಸಲಾಗಿದೆ",
	"invoice_menu" to "ಇನ್ ವಾಯ್ಸ್ ಮೆನು",
	"invoice_number" to "ಇನ್ ವಾಯ್ಸ್ #",
	"invoices" to "ಇನ್ ವಾಯ್ಸ್ ಗಳು",
	"items_option" to "ಐಟಂಗಳು",
	"keep" to "ಇರಿಸಿ",
	"language_selector" to Formattable { (selected) -> "ಭಾಷೆ ${selected}" },
	"last_name_hint" to "ಕೊನೆಯ ಹೆಸರು",
	"ledger" to "ledger",
	"legal" to "ಕಾನೂನು",
	"legal_error" to "ಸೇವಾ ನಿಯಮಗಳು ಮತ್ತು ಗೌಪ್ಯತೆ ನೀತಿಯನ್ನು ಸ್ವೀಕರಿಸಬೇಕು",
	"liabilities" to "liabilities",
	"loading" to "ಲೋಡ್ ಆಗುತ್ತಿದೆ",
	"login_required_error" to "ಲಾಗಿನ್ ಅಗತ್ಯವಿದೆ.",
	"logo_view" to "ಲೋಗೋ",
	"logout" to "ಲಾಗ್ ಔಟ್",
	"logout_prompt" to "ನಿಮ್ಮ ಡೇಟಾ ವನ್ನು ಕ್ಲೌಡ್ ನೊಂದಿಗೆ ಸಿಂಕ್ರೊನೈಸ್ ಮಾಡಿಲ್ಲ. ಲಾಗ್ ಔಟ್ ನಲ್ಲಿ ಇನ್ ವಾಯ್ಸ್ ಗಳು, ಅಂದಾಜುಗಳು, ಕ್ಲೈಂಟ್ ಗಳು ಮತ್ತು ಇತರ ಮಾಹಿತಿಯನ್ನು ಅಳಿಸಿ?",
	"main_screen" to "ಮುಖ್ಯ ಪರದೆ",
	"mark_paid" to "ಪಾವತಿಸಿದಂತೆ ಗುರುತು",
	"message" to "ಸಂದೇಶ",
	"missing_sender_msg" to "ಕಳುಹಿಸುವವರ ಮಾಹಿತಿಯಿಲ್ಲದ ಡಾಕ್ಯುಮೆಂಟ್ ಅಮಾನ್ಯವಾಗಬಹುದು.",
	"missing_sender_title" to "ಕಳುಹಿಸುವವರ ಮಾಹಿತಿ ಕಾಣೆಯಾಗಿದೆ",
	"month_april" to "ಏಪ್ರಿಲ್",
	"month_april_short" to "ಏಪ್ರಿಲ್",
	"month_august" to "ಆಗಸ್ಟ್",
	"month_august_short" to "ಆಗಸ್ಟ್.",
	"month_december" to "ಡಿಸೆಂಬರ್",
	"month_december_short" to "ಡಿಸೆಂಬರ್.",
	"month_february" to "ಫೆಬ್ರವರಿ",
	"month_february_short" to "ಫೆಬ್ರವರಿ.",
	"month_january" to "ಜನವರಿ",
	"month_january_short" to "ಜನವರಿ.",
	"month_july" to "ಜುಲೈ",
	"month_july_short" to "ಜುಲೈ",
	"month_june" to "ಜೂನ್",
	"month_june_short" to "ಜೂನ್",
	"month_march" to "ಮಾರ್ಚ್",
	"month_march_short" to "ಮಾರ್ಚ್",
	"month_may" to "ಮೇ",
	"month_may_short" to "ಮೇ",
	"month_november" to "ನವೆಂಬರ್",
	"month_november_short" to "ನವೆಂಬರ್.",
	"month_october" to "ಅಕ್ಟೋಬರ್",
	"month_october_short" to "ಅಕ್ಟೋಬರ್.",
	"month_september" to "ಸೆಪ್ಟೆಂಬರ್",
	"month_september_short" to "ಸೆ.",
	"multifactor_error" to "ಪರಿಶೀಲಿಸಲು ವಿಫಲವಾಗಿದೆ.",
	"new" to "ಹೊಸದು",
	"news" to "ಸುದ್ದಿ",
	"next" to "ಮುಂದೆ",
	"no" to "no",
	"no_data_available" to "ಯಾವುದೇ ಮಾಹಿತಿ ಇಲ್ಲ",
	"no_search_results" to "ಕ್ಷಮಿಸಿ, ನಾವು ಯಾವುದೇ ಫಲಿತಾಂಶಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ.",
	"none" to "ಯಾವುದೂ",
	"note_fields" to "ಟಿಪ್ಪಣಿಗಳು",
	"note_hint" to "ಟಿಪ್ಪಣಿ",
	"notifications_option" to "ಅಧಿಸೂಚನೆಗಳು",
	"ok" to "ಸರಿ",
	"onboarding" to "ಆನ್ಬೋರ್ಡಿಂಗ್",
	"onboarding_aboutus_message" to "DingVoice ನ ಅಗತ್ಯ ಭಾಗಗಳು ಶಾಶ್ವತವಾಗಿ ಉಚಿತ. ನಿಮ್ಮ ವ್ಯವಹಾರವನ್ನು ಬೆಳೆಸಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುವುದು, ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಹಂಚಿಕೊಳ್ಳುವ ಮೂಲಕ ಸುಧಾರಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುವುದು ನಮ್ಮ ಉದ್ದೇಶವಾಗಿದೆ.",
	"onboarding_aboutus_title" to "ನಾವು ಸಹಾಯ ಮಾಡಲು ಇಲ್ಲಿದ್ದೇವೆ",
	"onboarding_paid_1" to "ವೃತ್ತಿಪರವಾಗಿ ಕಾಣುವ ಟೆಂಪ್ಲೇಟ್‌ಗಳನ್ನು ಪ್ರವೇಶಿಸಿ",
	"onboarding_paid_2" to "ನಿಮ್ಮ ಹಣಕಾಸಿನ ಬಗ್ಗೆ ಆಳವಾದ ನೋಟವನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ",
	"onboarding_paid_3" to "ಸಮಸ್ಯೆಗಳನ್ನು ತ್ವರಿತವಾಗಿ ಪರಿಹರಿಸಿ",
	"onboarding_paid_4" to "ನಿಮ್ಮ ದಾಖಲೆಗಳನ್ನು ಸುರಕ್ಷಿತವಾಗಿರಿಸಿ",
	"onboarding_paid_5" to "ಸರಕುಪಟ್ಟಿ ಸ್ಥಿತಿ ಬದಲಾವಣೆಗಳೊಂದಿಗೆ ನವೀಕೃತವಾಗಿರಿ",
	"onboarding_paid_title" to "ಇದರೊಂದಿಗೆ ಇನ್ನಷ್ಟು ವೇಗವಾಗಿ ಬೆಳೆಯಿರಿ...",
	"onboarding_paywall_message" to "ಇದೀಗ ಉತ್ತಮ ಬೆಲೆಯಲ್ಲಿ ಎಲ್ಲಾ ಪ್ರೀಮಿಯಂ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಆನಂದಿಸಿ.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} ಗೆ ಕೇವಲ ${currencySymbol}${introAmount} ಮತ್ತು ನಂತರ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "ಕೇವಲ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} ಗೆ ಉಚಿತ ಮತ್ತು ನಂತರ ಕೇವಲ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} ಗೆ ಉಚಿತ ನಂತರ ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod} ಮತ್ತು ನಂತರ ಕೇವಲ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "ಸ್ವಯಂ ನವೀಕರಿಸಬಹುದಾದ. ಯಾವಾಗ ಬೇಕಾದರೂ ರದ್ದುಮಾಡಿ.",
	"onboarding_paywall_restore" to "ಖರೀದಿಯನ್ನು ಮರುಸ್ಥಾಪಿಸಿ",
	"onboarding_paywall_restore_failure" to "ಖರೀದಿಗಳನ್ನು ಮರುಸ್ಥಾಪಿಸುವುದು ವಿಫಲವಾಗಿದೆ, ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ ಅಥವಾ ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ.",
	"onboarding_paywall_restore_success" to "ಮರುಸ್ಥಾಪನೆ ಯಶಸ್ವಿಯಾಗಿದೆ!",
	"onboarding_paywall_title" to "ಶ್ರೇಷ್ಠತೆಯನ್ನು ಸಾಧಿಸಿ!",
	"onboarding_welcome_message" to "ನಿಮ್ಮ ಇನ್‌ವಾಯ್ಸ್‌ಗಳು ಮತ್ತು ಅಂದಾಜುಗಳನ್ನು ನಿರ್ವಹಿಸಲು ಸಂಪೂರ್ಣ ಟೂಲ್‌ಸೆಟ್.",
	"onboarding_welcome_title" to "DingVoice ಗೆ ಸುಸ್ವಾಗತ",
	"other_state" to "ಇತರೆ",
	"overdue_state" to "ಅವಧಿ ಮೀರಿದ",
	"paid_state" to "ಪಾವತಿಸಲಾಗಿದೆ",
	"password" to "ಪಾಸ್ ವರ್ಡ್",
	"password_blank_error" to "ಪಾಸ್ ವರ್ಡ್ ಅಗತ್ಯವಿದೆ.",
	"password_mismatch_error" to "ಪಾಸ್ ವರ್ಡ್ ಗಳು ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ.",
	"pay" to "pay",
	"payment_instructions_label" to "ಪಾವತಿ ಸೂಚನೆಗಳು",
	"payment_reminders" to "ಪಾವತಿ ಜ್ಞಾಪಕಗಳು",
	"phone_number_fields" to "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
	"phone_number_hint" to "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
	"postcode_hint" to "ಪೋಸ್ಟ್ ಕೋಡ್",
	"preview" to "ಮುನ್ನೋಟ",
	"preview_menu_hint" to "ಮುನ್ನೋಟ ಮೆನು",
	"price" to "ಬೆಲೆ",
	"privacy_policy" to "ಗೌಪ್ಯತಾ ನೀತಿ",
	"profile" to "ಪ್ರೊಫೈಲ್",
	"profitable_client" to "ನಿಮ್ಮ ಅತ್ಯಂತ ಲಾಭದಾಯಕ ಕ್ಲೈಂಟ್...",
	"profits_report" to "ಲಾಭಗಳು",
	"promo_content" to "ಪ್ರಚಾರ ವಿಷಯ",
	"quantity_hint" to "ಪ್ರಮಾಣ",
	"quantity_label_hint" to "ಪ್ರಮಾಣ ಲೇಬಲ್",
	"query_info" to "ಐಟಂಗಳು ಮತ್ತು ಇತರ ತೆರಿಗೆ ನಿಯಮಗಳನ್ನು ಇನ್ ಪುಟ್ ಗಳಾಗಿ ಉಲ್ಲೇಖಿಸಿ ಕಸ್ಟಮ್ ಲೆಕ್ಕಾಚಾರಗಳನ್ನು ಬರೆಯಲು ಕ್ವೆರಿ ನಿಮಗೆ ಅನುಮತಿಸುತ್ತದೆ. ಮತ್ತೊಂದು ನಮೂದು +, -, *, /, % - ಗಣಿತ ಆಪರೇಟರ್ ಗಳಿಗೆ ಲಿಂಕ್ ಮಾಡಲು @ ಸಂಕೇತ ಮತ್ತು ಐಟಂ ಅಥವಾ ತೆರಿಗೆ ವಿವರಣೆಯನ್ನು ಸೇರಿಸಿ",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ಅನ್ನು ನಿಮ್ಮ ಕ್ಲೈಂಟ್ ಸ್ವೀಕರಿಸಿದೆ." },
	"received_state" to "ಸ್ವೀಕರಿಸಲಾಗಿದೆ",
	"registration_code_fields" to "ನೋಂದಣಿ ಕೋಡ್",
	"reminder" to "ಜ್ಞಾಪನೆ",
	"reports" to "ವರದಿಗಳು",
	"reports_not_available" to "ಯಾವುದೇ ವರದಿಗಳು ಲಭ್ಯವಿಲ್ಲ, ನಂತರ ಹಿಂತಿರುಗಿ.",
	"reveal_password" to "ಪಾಸ್ ವರ್ಡ್ ಬಹಿರಂಗಪಡಿಸು",
	"revenue" to "revenue",
	"sales_tax" to "ವ್ಯಾಟ್",
	"sales_tax_number" to "ವ್ಯಾಟ್ ಸಂಖ್ಯೆ",
	"save" to "ಉಳಿಸು",
	"scan_document" to "ಡಾಕ್ಯುಮೆಂಟ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
	"search" to "ಶೋಧಿಸು",
	"select" to "ಆಯ್ಕೆ ಮಾಡಿ",
	"select_icon" to "ಐಕಾನ್ ಆಯ್ಕೆಮಾಡಿ",
	"select_signature" to "ಸಹಿ ಆಯ್ಕೆಮಾಡಿ",
	"select_template" to "ಟೆಂಪ್ಲೇಟ್ ಆಯ್ಕೆಮಾಡಿ",
	"send" to "ಕಳಿಸಿ",
	"sent_state" to "ಕಳುಹಿಸಲಾಗಿದೆ",
	"setup_sender" to "ಕಳುಹಿಸುವವರನ್ನು ಹೊಂದಿಸಿ",
	"share_option" to "ಹಂಚಿಕೊಳ್ಳಿ",
	"ship_to" to "ಗೆ ಕಳಿಸು",
	"sign_in" to "ಸೈನ್ ಇನ್ ಮಾಡಿ",
	"sign_in_apple" to "ಆಪಲ್ ನೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡಿ",
	"sign_in_email" to "ಇಮೇಲ್ ನೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡಿ",
	"sign_in_facebook" to "ಫೇಸ್ ಬುಕ್ ನೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡಿ",
	"sign_in_google" to "ಗೂಗಲ್ ನೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡಿ",
	"sign_up" to "ಸೈನ್ ಅಪ್ ಮಾಡಿ",
	"sign_up_label" to "ಖಾತೆ ಇಲ್ಲ?",
	"sign_up_link_label" to "ಸೈನ್ ಅಪ್ ಮಾಡಿ!",
	"signatures_option" to "ಸಹಿಗಳು",
	"skip" to "ಸ್ಕಿಪ್",
	"sold_items_report" to "ಮಾರಾಟವಾದ ವಸ್ತುಗಳು",
	"sort_by" to "ಇದರಮೂಲಕ ವಿಂಗಡಿಸು:",
	"status_update" to "ಡಾಕ್ಯುಮೆಂಟ್ ಸ್ಥಿತಿ ನವೀಕರಣ",
	"sub_annual" to "ವಾರ್ಷಿಕ",
	"sub_annual_price" to Formattable { (price) -> "${price}/ವರ್ಷ" },
	"sub_backups" to "ಮೇಘ ಬ್ಯಾಕಪ್‌ಗಳು",
	"sub_insights" to "ಒಳನೋಟಗಳು",
	"sub_month" to "ಮಾಸಿಕ",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ತಿಂಗಳಿಗೆ" },
	"sub_notifications" to "ಅಧಿಸೂಚನೆಗಳು",
	"sub_popup_message" to "ಚಂದಾದಾರಿಕೆಗಳು ಶೀಘ್ರದಲ್ಲೇ ಬರಲಿವೆ. ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಇಲ್ಲಿ ಮತ್ತೆ ಪರಿಶೀಲಿಸಿ.",
	"sub_support" to "ಬೆಂಬಲ",
	"sub_templates" to "ಟೆಂಪ್ಲೇಟ್ಗಳು",
	"subscribe" to "ಚಂದಾದಾರರಾಗಿ",
	"subscription_header" to "ಇದರೊಂದಿಗೆ ನಿಮ್ಮ ವ್ಯಾಪಾರವನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಿ:",
	"subscription_option" to "ಚಂದಾದಾರಿಕೆ",
	"subscriptions_load_fail" to "ಕ್ಷಮಿಸಿ, ಈ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ ಚಂದಾದಾರಿಕೆಗಳನ್ನು ಹಿಂಪಡೆಯಲು ನಮಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ ಅಥವಾ ಸಹಾಯಕ್ಕಾಗಿ ನಮ್ಮ ಬೆಂಬಲ ತಂಡವನ್ನು ಸಂಪರ್ಕಿಸಿ.",
	"subtotal" to Formattable { (sum) -> "ಉಪಮೊತ್ತ: ${sum}" },
	"subtotal_label" to "ಉಪಮೊತ್ತ",
	"sum" to "ಮೊತ್ತ",
	"support_option" to "ಬೆಂಬಲ",
	"switch_reports" to "ಸ್ವಿಚ್ ವರದಿ",
	"tax" to "ತೆರಿಗೆ",
	"tax_deleted_message" to "ತೆರಿಗೆ ಅಳಿಸಲಾಗಿದೆ",
	"tax_description_warning" to "ಇದನ್ನು ಬಳಸಿದರೆ ಇದು ಇನ್ ವಾಯ್ಸ್ ನಲ್ಲಿ ತೋರಿಸುತ್ತದೆ",
	"tax_input_flat" to "ಚಪ್ಪಟೆ",
	"tax_input_percent" to "ಪ್ರತಿಶತ",
	"tax_number_fields" to "ತೆರಿಗೆ ಸಂಖ್ಯೆ",
	"tax_number_hint" to "ತೆರಿಗೆ ಸಂಖ್ಯೆ",
	"tax_query_info_description" to "ಕ್ವೆರಿ ಮಾಹಿತಿ",
	"tax_report" to "ತೆರಿಗೆ",
	"tax_rules_option" to "ತೆರಿಗೆ ನಿಯಮಗಳು",
	"taxed_clients_report" to "ತೆರಿಗೆ ವಿಧಿಸಿದ ಗ್ರಾಹಕರು",
	"template" to "ಟೆಂಪ್ಲೇಟ್",
	"template_selector_info" to "ಡೀಫಾಲ್ಟ್ ಟೆಂಪ್ಲೇಟ್ ಬದಲಿಸಲು ಸ್ವೈಪ್ ಮಾಡಿ",
	"template_unlock_promo" to "Premium ನೊಂದಿಗೆ ಹೆಚ್ಚಿನ ಟೆಂಪ್ಲೇಟ್‌ಗಳನ್ನು ಪ್ರವೇಶಿಸಿ",
	"templates_option" to "ಟೆಂಪ್ಲೇಟ್ ಗಳು",
	"terms_of_service" to "ಸೇವೆಯ ನಿಯಮಗಳು",
	"thank_you" to "ಧನ್ಯವಾದಗಳು",
	"to" to "ಗೆ",
	"total" to Formattable { (sum) -> "ಒಟ್ಟು: ${sum}" },
	"total_label" to "ಒಟ್ಟು",
	"undo_action" to "ಪೂರ್ವಕ್ರಿಯೆ",
	"unit" to "ಘಟಕ",
	"unit_cost_hint" to "ಯೂನಿಟ್ ವೆಚ್ಚ",
	"unknown_client" to "ಅಜ್ಞಾತ ಗ್ರಾಹಕ",
	"unlock" to "ಅನ್ಲಾಕ್ ಮಾಡಿ",
	"unnamed_item" to "ಹೆಸರಿಸದ ಐಟಂ",
	"upgrade" to "ನವೀಕರಿಸು",
	"user_collision_error" to "ಖಾತೆ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ.",
	"valid_until" to "ವರೆಗೆ ಮಾನ್ಯವಾಗಿರುತ್ತದೆ",
	"warning" to "ಎಚ್ಚರಿಕೆ!",
	"yes" to "yes",
    )
    
}