// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Fa  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} و ${privacyPolicy} را بپذیرید" }
override val account: String = "حساب"
override val accounting: String = "حسابداری"
override val accruals: String = "تعهدی"
override val action_code_error: String = "کد نامعتبر ."
override val add_client_action: String = "اضافه کردن مشتری"
override val add_customer: String = "اضافه کردن مشتری"
override val add_expense: String = "اضافه کردن هزینه"
override val add_fields_label: String = "اضافه کردن:"
override val add_invoice_action: String = "ایجاد"
override val add_item_action: String = "افزودن آیتم"
override val add_item_button: String = "افزودن آیتم"
override val add_signature_action: String = "افزودن امضا"
override val add_tax_action: String = "اضافه کردن مالیات"
override val address_fields: String = "آدرس"
override val address_line_hint: String = "خط آدرس"
override val advanced: String = "پیشرفته"
override val agree_marketing: String = "من با دریافت ارتباطات بازاریابی مستقیم موافقت می کنم"
override val all_notifications: String = "همه اعلان ها"
override val amortization: String = "انقضاء"
override val amount: String = "مقدار"
override val asset: String = "دارایی"
override val attach_file: String = "پیوست پرونده"
override val attachment: String = "پیوست"
override val auth_error: String = "عمل شکست خورد، دوباره امتحان کنید."
override val available_for_paid: String = "همگام سازی ابر تنها تحت اشتراک پرداخت شده در دسترس است."
override val backups_option: String = "پشتیبان گیری"
override val balance: String = "تعادل"
override val bank_account: String = "حساب بانکی"
override val bill_number: String = "شماره صورتحساب"
override val cancel: String = "لغو"
override val cancel_trial: String = "در هر زمان لغو کنید"
override val capital: String = "سرمايه"
override val cash: String = "نقد"
override val categories: String = "دسته"
override val category: String = "رده"
override val category_add: String = "اضافه کردن دسته"
override val category_delete: String = "حذف دسته"
override val category_name: String = "نام دسته بندی"
override val change_language: Formattable = Formattable { (language) -> "زبان فاکتور: ${language}" }
override val change_language_label: String = "زبان صورت حساب"
override val change_template: String = "تغییر قالب"
override val city_hint: String = "شهر"
override val clear: String = "روشن"
override val client: String = "مشتری"
override val client_actions: String = "اقدامات مشتری"
override val client_deleted_message: String = "مشتری حذف شده"
override val clients_error_required_fields: String = "حداقل یک مقدار مورد نیاز است."
override val clients_option: String = "مشتریان"
override val close: String = "نزدیک"
override val cloud_sync: String = "همگام سازی ابر"
override val coming_soon: String = "زود می آیم!"
override val company_logo: String = "لوگوی شرکت"
override val company_name_hint: String = "شرکت"
override val confirm_password_label: String = "تایید رمز عبور"
override val confirmation_title: String = "مطمئنید؟"
override val convert_to_invoice: String = "تبدیل به فاکتور"
override val country_hint: String = "کشور"
override val create_client: String = "ایجاد مشتری"
override val create_company: String = "ایجاد شرکت"
override val create_document: String = "ایجاد سند"
override val create_estimate: String = "برآورد"
override val create_invoice: String = "فاکتور"
override val create_item: String = "ایجاد آیتم"
override val create_new_client: String = "ایجاد مشتری جدید"
override val create_new_item: String = "ایجاد آیتم جدید"
override val create_new_tax: String = "ایجاد مالیات جدید"
override val create_signature: String = "امضا"
override val created: String = "ایجاد"
override val credit: String = "اعتبار"
override val customer: String = "مشتری"
override val date: String = "تاریخ"
override val debit: String = "بدهي"
override val debt: String = "بدهی"
override val default_currency: Formattable = Formattable { (currency) -> "ارز پیش فرض: ${currency}" }
override val default_currency_label: String = "ارز پیش فرض"
override val delete: String = "حذف"
override val delete_account: String = "حذف حساب کاربری"
override val delete_confirmation_message: String = "تمام اطلاعات شما از بین خواهد رفت."
override val delete_item_title: String = "حذف مورد؟"
override val delete_signature: String = "حذف امضا"
override val depreciation: String = "استهلاک"
override val description: String = "توضیحات"
override val description_hint: String = "توضیحات"
override val detailed_report: String = "گزارش تفصیلی"
override val disabled: String = "غیر فعال"
override val discount: String = "تخفیف"
override val discount_hint: String = "تخفیف"
override val display_af: String = "آفریقایی"
override val display_am: String = "آمهری"
override val display_ar: String = "عربی"
override val display_as: String = "آسامی"
override val display_az: String = "آذربایجانی"
override val display_be: String = "بلاروسی"
override val display_bg: String = "بلغاری"
override val display_bn: String = "بنگالی"
override val display_bs: String = "بوسنیایی"
override val display_ca: String = "کاتالان"
override val display_cs: String = "کشور چک"
override val display_da: String = "دانمارکی"
override val display_de: String = "آلمانی"
override val display_el: String = "یونانی"
override val display_en: String = "انگلیسی"
override val display_es: String = "اسپانیایی"
override val display_es_MX: String = "اسپانیایی (مکزیک)"
override val display_et: String = "استونیایی"
override val display_eu: String = "باسکی"
override val display_fa: String = "فارسی"
override val display_fi: String = "فنلاندی"
override val display_fil: String = "فیلیپینی"
override val display_fr: String = "فرانسوی"
override val display_fr_CA: String = "فرانسوی کانادایی"
override val display_gl: String = "گالیسیایی"
override val display_gu: String = "گجراتی"
override val display_he: String = "عبری"
override val display_hi: String = "جواب منفی"
override val display_hr: String = "کروات"
override val display_hu: String = "مجارستانی"
override val display_hy: String = "ارمنی"
override val display_id: String = "اندونزیایی"
override val display_is: String = "ایسلندی"
override val display_it: String = "ایتالیایی"
override val display_ja: String = "ژاپنی"
override val display_ka: String = "گرجی"
override val display_kk: String = "قزاقی"
override val display_km: String = "خمر مرکزی"
override val display_kn: String = "کانادا"
override val display_ko: String = "کره ای"
override val display_ky: String = "قرقیزی"
override val display_lo: String = "کار یدی"
override val display_lt: String = "لیتوانیایی"
override val display_lv: String = "لتونی"
override val display_mk: String = "مقدونی"
override val display_ml: String = "مالایایی"
override val display_mn: String = "مغولی"
override val display_mr: String = "مراتی"
override val display_ms: String = "مالایی"
override val display_my: String = "برمه ای"
override val display_nb: String = "نروژی بوکمال"
override val display_ne: String = "نپالی"
override val display_nl: String = "هلندی"
override val display_or: String = "اوریا"
override val display_pa: String = "پنجابی"
override val display_pl: String = "لهستانی"
override val display_pt: String = "پرتغالی"
override val display_pt_BR: String = "پرتغالی (برزیل)"
override val display_pt_PT: String = "پرتغالی اروپایی"
override val display_ro: String = "رومانیایی"
override val display_ru: String = "روسی"
override val display_si: String = "سینهالی"
override val display_sk: String = "اسلواکی"
override val display_sl: String = "اسلوونیایی"
override val display_sq: String = "آلبانیایی"
override val display_sr: String = "صربی"
override val display_sv: String = "سوئدی"
override val display_sw: String = "سواحیلی"
override val display_ta: String = "تامیل"
override val display_te: String = "تلوگو"
override val display_th: String = "تایلندی"
override val display_tr: String = "ترکی"
override val display_uk: String = "اوکراینی"
override val display_ur: String = "اردو"
override val display_uz: String = "ازبکستان"
override val display_vi: String = "ویتنامی"
override val display_zh_Hant_TW: String = "چینی (تایوان، سنتی)"
override val display_zu: String = "زولو"
override val document_estimate: String = "تخمینی"
override val document_invoice: String = "فاکتور"
override val document_quote: String = "نقل قول"
override val done: String = "انجام شده"
override val download_app: String = "برنامه را دانلود کنید"
override val download_invoice: String = "دانلود فاکتور"
override val download_pdf: String = "PDF را دانلود کنید"
override val draw_signature: String = "رسم امضا"
override val due: Formattable = Formattable { (date) -> "با توجه به ${date}" }
override val due_date: String = "موعد مقرر"
override val due_day: String = "در یک روز"
override val due_label: String = "دلیل"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "سررسید ${documentType} ${documentNumber} ${dueWhen} است!" }
override val due_today: String = "امروز"
override val due_week: String = "در یک هفته"
override val duplicate: String = "تکراری"
override val duration_day: String = "روز"
override val duration_month: String = "ماه"
override val duration_week: String = "هفته"
override val duration_year: String = "سال"
override val email: String = "ایمیل"
override val email_blank_error: String = "ایمیل مورد نیاز."
override val email_error: String = "آدرس ایمیل نامعتبر."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "از کار شما متشکرم. برای مشاهده کامل ${documentType} روی دکمه انتهای این نامه کلیک کنید. توجه داشته باشید که تا ${dueDate} معتبر است." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "شما یک ${documentType} ${invoiceNumber} جدید دریافت کرده‌اید!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "مشاهده ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "${clientName} عزیز" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "از کار شما متشکرم. برای مشاهده کامل ${documentType} روی دکمه انتهای این نامه کلیک کنید. لطفاً موجودی را تا ${dueDate} پرداخت کنید." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "شما یک ${documentType} ${invoiceNumber} جدید دریافت کرده‌اید!" }
override val email_reset_success_message: String = "ایمیل برای تنظیم مجدد رمز عبور ارسال شده، لطفا صندوق پستی خود را بررسی کنید."
override val empty_clients_list_message: String = "تو هيچ مشتري اي براي رديابي نداري یک سرویس گیرنده جدید اضافه کنید."
override val empty_estimate_list_message: String = "اولین برآورد خود را ایجاد کنید"
override val empty_expenses: String = "شما هیچ هزینه ای برای پیگیری ندارید. یک هزینه جدید اضافه کنید."
override val empty_invoice_list_message: String = "اولین فاکتور خود را ایجاد کنید"
override val empty_items_list_message: String = "شما هیچ موردی داري، يک مورد جديد اضافه کن!"
override val empty_signatures_list_message: String = "هيچ امضايي پيدا نشد . ایجاد یکی!"
override val empty_taxes_list_message: String = "تو هيچ قانون مالياتي نداري . یک قانون مالیاتی جدید اضافه کنید."
override val empty_templates_list_message: String = "قالب ها در دسترس نیستند."
override val enabled: String = "فعال"
override val entry: String = "ورود"
override val equity: String = "سهام"
override val estimate_number: String = "تخمین زدن #"
override val estimates: String = "برآورد"
override val estimates_report: String = "برآوردها"
override val expense_bank_fees: String = "هزینه های بانکی"
override val expense_benefits: String = "مزایای"
override val expense_communication: String = "ارتباطات"
override val expense_debt: String = "بدهی"
override val expense_deleted: String = "هزینه حذف شده"
override val expense_depreciation: String = "استهلاک"
override val expense_gifts: String = "هدیه"
override val expense_insurance: String = "بیمه"
override val expense_inventory: String = "موجودی"
override val expense_legal: String = "قانونی"
override val expense_maintenance: String = "نگهداری"
override val expense_marketing: String = "بازاریابی"
override val expense_meals: String = "غذا"
override val expense_office_supplies: String = "لوازم اداری"
override val expense_rent: String = "اجاره"
override val expense_salaries: String = "حقوق"
override val expense_shopping: String = "خرید"
override val expense_software: String = "نرم افزار"
override val expense_status_available: String = "دسترس"
override val expense_status_processing: String = "پردازش"
override val expense_tax: String = "مالیات"
override val expense_training: String = "آموزش"
override val expense_transport: String = "انتقال"
override val expense_travel: String = "سفر"
override val expense_utility: String = "سودمند"
override val expenses: String = "هزینه"
override val feedback_option: String = "بازخورد"
override val filter: String = "فیلتر"
override val first_name_hint: String = "نام و نام خانوادگی"
override val forgot_password: String = "بازنشانی رمز عبور"
override val forgot_password_label: String = "رمز عبور را فراموش کرده ام"
override val from: String = "از"
override val general_error: String = "خطایی رخ داد، لطفاً بعداً دوباره امتحان کنید."
override val general_ledger: String = "دفتر کل"
override val go_back_label: String = "برگرد"
override val group_by: String = "گروه بر اساس:"
override val image_view: String = "تصویر"
override val install: String = "نصب"
override val invalid_credentials_error: String = "اعتبار نامعتبر."
override val invalid_email_error: String = "ایمیل نامعتبر."
override val invalid_user_error: String = "اعتبار کاربری نامعتبر."
override val invoice_deleted_message: String = "فاکتور حذف شده"
override val invoice_menu: String = "منوی فاکتور"
override val invoice_number: String = "فاکتور #"
override val invoices: String = "فاکتورها"
override val items_option: String = "موارد"
override val keep: String = "نگه داشتن"
override val language_selector: Formattable = Formattable { (selected) -> "زبان ${selected}" }
override val last_name_hint: String = "نام خانوادگی"
override val ledger: String = "دفتر کل"
override val legal: String = "قانونی"
override val legal_error: String = "باید شرایط سیاست خدمات و حریم خصوصی را بپذیرد"
override val liabilities: String = "بدهی ها"
override val loading: String = "بارگذاری"
override val login_required_error: String = "ورود مورد نیاز است."
override val logo_view: String = "عالی"
override val logout: String = "خروج"
override val logout_prompt: String = "داده های شما با ابر همگام سازی نشده اند. حذف فاکتورها، برآوردها، مشتریان & اطلاعات دیگر در مورد ورود به سیستم؟"
override val main_screen: String = "صفحه اصلی"
override val mark_paid: String = "علامت گذاری به عنوان پرداخت"
override val message: String = "پیام"
override val missing_sender_msg: String = "سندی بدون اطلاعات فرستنده ممکن است نامعتبر باشد."
override val missing_sender_title: String = "اطلاعات فرستنده وجود ندارد"
override val month_april: String = "آوریل"
override val month_april_short: String = "آوریل"
override val month_august: String = "مرداد"
override val month_august_short: String = "اوت"
override val month_december: String = "دسامبر"
override val month_december_short: String = "دسامبر"
override val month_february: String = "فوریه"
override val month_february_short: String = "فوریه"
override val month_january: String = "ژانویه"
override val month_january_short: String = "ژان"
override val month_july: String = "جولای"
override val month_july_short: String = "جولای"
override val month_june: String = "ژوئن"
override val month_june_short: String = "ژوئن"
override val month_march: String = "مارس"
override val month_march_short: String = "مارس"
override val month_may: String = "ممکن است"
override val month_may_short: String = "ممکن است"
override val month_november: String = "نوامبر"
override val month_november_short: String = "نوامبر"
override val month_october: String = "اکتبر"
override val month_october_short: String = "اکتبر"
override val month_september: String = "سپتامبر"
override val month_september_short: String = "سپتامبر"
override val multifactor_error: String = "موفق به تایید نشد."
override val new: String = "جدید"
override val news: String = "اخبار"
override val next: String = "بعد"
override val no: String = "نه"
override val no_data_available: String = "اطلاعاتی موجود نیست"
override val no_search_results: String = "ببخشيد، نتونستيم نتيجه اي پيدا کنيم."
override val none: String = "هیچ کدام"
override val note_fields: String = "یادداشت"
override val note_hint: String = "توجه داشته باشید"
override val notifications_option: String = "اطلاعیه ها"
override val ok: String = "باشه"
override val onboarding: String = "شبانه روزی"
override val onboarding_aboutus_message: String = "بخش های اساسی DingVoice برای همیشه رایگان است. ماموریت ما این است که به شما کمک کنیم تا کسب و کار خود را توسعه دهید، با به اشتراک گذاشتن نظرات خود به ما کمک کنید تا بهبود پیدا کنیم."
override val onboarding_aboutus_title: String = "ما اینجاییم تا کمک کنیم"
override val onboarding_paid_1: String = "دسترسی به قالب های حرفه ای"
override val onboarding_paid_2: String = "نگاهی عمیق تر به امور مالی خود بیندازید"
override val onboarding_paid_3: String = "مشکلات را سریع حل کنید"
override val onboarding_paid_4: String = "اسناد خود را ایمن نگه دارید"
override val onboarding_paid_5: String = "با تغییرات وضعیت فاکتور به روز باشید"
override val onboarding_paid_title: String = "حتی سریعتر رشد کنید با ..."
override val onboarding_paywall_message: String = "اکنون با بهترین قیمت از همه ویژگی های برتر لذت ببرید."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "فقط ${currencySymbol}${introAmount} برای ${introDuration} ${introPeriod} و سپس ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "فقط ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "رایگان برای ${trialDuration} ${trialPeriod} و سپس فقط ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "رایگان برای ${trialDuration} ${trialPeriod} سپس ${currencySymbol}${introductoryAmount} برای ${introductoryDuration} ${introPeriod} و سپس فقط ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "قابل تمدید خودکار هر زمان خواستید لغو کنید."
override val onboarding_paywall_restore: String = "خرید را بازیابی کنید"
override val onboarding_paywall_restore_failure: String = "بازیابی خریدها انجام نشد، بعداً دوباره امتحان کنید یا با پشتیبانی تماس بگیرید."
override val onboarding_paywall_restore_success: String = "بازیابی موفقیت آمیز!"
override val onboarding_paywall_title: String = "بدست آوردن عظمت!"
override val onboarding_welcome_message: String = "یک مجموعه ابزار کامل برای مدیریت فاکتورها و برآوردهای شما."
override val onboarding_welcome_title: String = "به DingVoice خوش آمدید"
override val other_state: String = "دیگر"
override val overdue_state: String = "گذشته"
override val paid_state: String = "پرداخت"
override val password: String = "رمز عبور"
override val password_blank_error: String = "رمز عبور مورد نیاز است."
override val password_mismatch_error: String = "رمزهای عبور با هم مطابقت نمی کنند."
override val pay: String = "پرداخت"
override val payment_instructions_label: String = "دستورالعمل پرداخت"
override val payment_reminders: String = "یادآوری پرداخت"
override val phone_number_fields: String = "شماره تلفن"
override val phone_number_hint: String = "شماره تلفن"
override val postcode_hint: String = "کد پستی"
override val preview: String = "پیشنمایش"
override val preview_menu_hint: String = "منوی پیش نمایش"
override val price: String = "قيمت"
override val privacy_policy: String = "سیاست حفظ حریم خصوصی"
override val profile: String = "مشخصات"
override val profitable_client: String = "سودآورترین مشتری شما..."
override val profits_report: String = "سود"
override val promo_content: String = "محتوای تبلیغاتی"
override val quantity_hint: String = "مقدار"
override val quantity_label_hint: String = "برچسب کمیت"
override val query_info: String = "پرس و جو اجازه می دهد تا شما را به نوشتن محاسبات سفارشی با اشاره به هر دو مورد و قوانین مالیاتی دیگر به عنوان ورودی. اضافه کردن @ نماد و آیتم یا شرح مالیات به یکی دیگر از ورودی لینک + ، -- ، ، / ، ٪ -- اپراتورهای ریاضی"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} توسط مشتری شما دریافت شد." }
override val received_state: String = "اخذ شده"
override val registration_code_fields: String = "کد ثبت نام"
override val reminder: String = "یادآور"
override val reports: String = "گزارش"
override val reports_not_available: String = "گزارشی در دسترس نیست، بعداً بازگردید."
override val reveal_password: String = "آشکار کردن رمز عبور"
override val revenue: String = "درآمد"
override val sales_tax: String = "مالیات بر ارزش افزوده"
override val sales_tax_number: String = "شماره مالیات بر ارزش افزوده"
override val save: String = "ذخیره"
override val scan_document: String = "پویش سند"
override val search: String = "جستجو"
override val select: String = "انتخاب"
override val select_icon: String = "برگزیدن شمایل"
override val select_signature: String = "امضا را انتخاب کنید"
override val select_template: String = "انتخاب الگو"
override val send: String = "ارسال"
override val sent_state: String = "فرستاده"
override val setup_sender: String = "فرستنده را تنظیم کنید"
override val share_option: String = "اشتراک گذاشتن"
override val ship_to: String = "ارسال به"
override val sign_in: String = "ورود به برنامه"
override val sign_in_apple: String = "با اپل ثبت نام کنید"
override val sign_in_email: String = "ورود به سیستم با ایمیل"
override val sign_in_facebook: String = "ورود به سیستم با فیس بوک"
override val sign_in_google: String = "ورود به سیستم با Google"
override val sign_up: String = "ثبت نام"
override val sign_up_label: String = "حسابي نداري؟"
override val sign_up_link_label: String = "ثبت نام!"
override val signatures_option: String = "امضا"
override val skip: String = "رد شدن"
override val sold_items_report: String = "اقلام فروخته شده"
override val sort_by: String = "مرتب سازی بر اساس:"
override val status_update: String = "به روز رسانی وضعیت سند"
override val sub_annual: String = "سالانه"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/سال" }
override val sub_backups: String = "پشتیبان گیری های ابری"
override val sub_insights: String = "بینش‌ها"
override val sub_month: String = "ماهانه"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ماه" }
override val sub_notifications: String = "اعلانات"
override val sub_popup_message: String = "اشتراک ها به زودی می آیند. يه کم ديگه اينجا رو چک کن"
override val sub_support: String = "پشتیبانی"
override val sub_templates: String = "الگوها"
override val subscribe: String = "اشتراک"
override val subscription_header: String = "رشد کسب و کار خود را با:"
override val subscription_option: String = "اشتراک"
override val subscriptions_load_fail: String = "متأسفیم، در حال حاضر نمی‌توانیم اشتراک‌های شما را بازیابی کنیم. لطفاً بعداً دوباره امتحان کنید یا برای راهنمایی با تیم پشتیبانی ما تماس بگیرید."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "فرعی"
override val sum: String = "مجموع"
override val support_option: String = "حمایت"
override val switch_reports: String = "گزارش سوئیچ"
override val tax: String = "مالیات"
override val tax_deleted_message: String = "مالیات حذف شده"
override val tax_description_warning: String = "اگر استفاده می شود این نشان می دهد تا در فاکتور"
override val tax_input_flat: String = "تخت"
override val tax_input_percent: String = "درصد"
override val tax_number_fields: String = "شماره مالیاتی"
override val tax_number_hint: String = "شماره مالیاتی"
override val tax_query_info_description: String = "اطلاعات پرس و جو"
override val tax_report: String = "مالیات"
override val tax_rules_option: String = "قوانین مالیاتی"
override val taxed_clients_report: String = "مشتریان مشمول مالیات"
override val template: String = "الگو"
override val template_selector_info: String = "کش رفتن برای تغییر قالب پیش فرض"
override val template_unlock_promo: String = "با Premium به الگوهای بیشتری دسترسی داشته باشید"
override val templates_option: String = "قالب"
override val terms_of_service: String = "شرایط خدمات"
override val thank_you: String = "متشکرم"
override val to: String = "به"
override val total: Formattable = Formattable { (sum) -> "مجموع: ${sum}" }
override val total_label: String = "جمع"
override val undo_action: String = "خنثیسازی"
override val unit: String = "واحد"
override val unit_cost_hint: String = "هزینه واحد"
override val unknown_client: String = "مشتری ناشناس"
override val unlock: String = "باز کردن قفل"
override val unnamed_item: String = "آیتم بدون نام"
override val upgrade: String = "ارتقاء"
override val user_collision_error: String = "حساب در حال حاضر وجود دارد."
override val valid_until: String = "معتبر است تا"
override val warning: String = "هشدار!"
override val yes: String = "بله"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} و ${privacyPolicy} را بپذیرید" },
	"account" to "حساب",
	"accounting" to "حسابداری",
	"accruals" to "تعهدی",
	"action_code_error" to "کد نامعتبر .",
	"add_client_action" to "اضافه کردن مشتری",
	"add_customer" to "اضافه کردن مشتری",
	"add_expense" to "اضافه کردن هزینه",
	"add_fields_label" to "اضافه کردن:",
	"add_invoice_action" to "ایجاد",
	"add_item_action" to "افزودن آیتم",
	"add_item_button" to "افزودن آیتم",
	"add_signature_action" to "افزودن امضا",
	"add_tax_action" to "اضافه کردن مالیات",
	"address_fields" to "آدرس",
	"address_line_hint" to "خط آدرس",
	"advanced" to "پیشرفته",
	"agree_marketing" to "من با دریافت ارتباطات بازاریابی مستقیم موافقت می کنم",
	"all_notifications" to "همه اعلان ها",
	"amortization" to "انقضاء",
	"amount" to "مقدار",
	"asset" to "دارایی",
	"attach_file" to "پیوست پرونده",
	"attachment" to "پیوست",
	"auth_error" to "عمل شکست خورد، دوباره امتحان کنید.",
	"available_for_paid" to "همگام سازی ابر تنها تحت اشتراک پرداخت شده در دسترس است.",
	"backups_option" to "پشتیبان گیری",
	"balance" to "تعادل",
	"bank_account" to "حساب بانکی",
	"bill_number" to "شماره صورتحساب",
	"cancel" to "لغو",
	"cancel_trial" to "در هر زمان لغو کنید",
	"capital" to "سرمايه",
	"cash" to "نقد",
	"categories" to "دسته",
	"category" to "رده",
	"category_add" to "اضافه کردن دسته",
	"category_delete" to "حذف دسته",
	"category_name" to "نام دسته بندی",
	"change_language" to Formattable { (language) -> "زبان فاکتور: ${language}" },
	"change_language_label" to "زبان صورت حساب",
	"change_template" to "تغییر قالب",
	"city_hint" to "شهر",
	"clear" to "روشن",
	"client" to "مشتری",
	"client_actions" to "اقدامات مشتری",
	"client_deleted_message" to "مشتری حذف شده",
	"clients_error_required_fields" to "حداقل یک مقدار مورد نیاز است.",
	"clients_option" to "مشتریان",
	"close" to "نزدیک",
	"cloud_sync" to "همگام سازی ابر",
	"coming_soon" to "زود می آیم!",
	"company_logo" to "لوگوی شرکت",
	"company_name_hint" to "شرکت",
	"confirm_password_label" to "تایید رمز عبور",
	"confirmation_title" to "مطمئنید؟",
	"convert_to_invoice" to "تبدیل به فاکتور",
	"country_hint" to "کشور",
	"create_client" to "ایجاد مشتری",
	"create_company" to "ایجاد شرکت",
	"create_document" to "ایجاد سند",
	"create_estimate" to "برآورد",
	"create_invoice" to "فاکتور",
	"create_item" to "ایجاد آیتم",
	"create_new_client" to "ایجاد مشتری جدید",
	"create_new_item" to "ایجاد آیتم جدید",
	"create_new_tax" to "ایجاد مالیات جدید",
	"create_signature" to "امضا",
	"created" to "ایجاد",
	"credit" to "اعتبار",
	"customer" to "مشتری",
	"date" to "تاریخ",
	"debit" to "بدهي",
	"debt" to "بدهی",
	"default_currency" to Formattable { (currency) -> "ارز پیش فرض: ${currency}" },
	"default_currency_label" to "ارز پیش فرض",
	"delete" to "حذف",
	"delete_account" to "حذف حساب کاربری",
	"delete_confirmation_message" to "تمام اطلاعات شما از بین خواهد رفت.",
	"delete_item_title" to "حذف مورد؟",
	"delete_signature" to "حذف امضا",
	"depreciation" to "استهلاک",
	"description" to "توضیحات",
	"description_hint" to "توضیحات",
	"detailed_report" to "گزارش تفصیلی",
	"disabled" to "غیر فعال",
	"discount" to "تخفیف",
	"discount_hint" to "تخفیف",
	"display_af" to "آفریقایی",
	"display_am" to "آمهری",
	"display_ar" to "عربی",
	"display_as" to "آسامی",
	"display_az" to "آذربایجانی",
	"display_be" to "بلاروسی",
	"display_bg" to "بلغاری",
	"display_bn" to "بنگالی",
	"display_bs" to "بوسنیایی",
	"display_ca" to "کاتالان",
	"display_cs" to "کشور چک",
	"display_da" to "دانمارکی",
	"display_de" to "آلمانی",
	"display_el" to "یونانی",
	"display_en" to "انگلیسی",
	"display_es" to "اسپانیایی",
	"display_es_MX" to "اسپانیایی (مکزیک)",
	"display_et" to "استونیایی",
	"display_eu" to "باسکی",
	"display_fa" to "فارسی",
	"display_fi" to "فنلاندی",
	"display_fil" to "فیلیپینی",
	"display_fr" to "فرانسوی",
	"display_fr_CA" to "فرانسوی کانادایی",
	"display_gl" to "گالیسیایی",
	"display_gu" to "گجراتی",
	"display_he" to "عبری",
	"display_hi" to "جواب منفی",
	"display_hr" to "کروات",
	"display_hu" to "مجارستانی",
	"display_hy" to "ارمنی",
	"display_id" to "اندونزیایی",
	"display_is" to "ایسلندی",
	"display_it" to "ایتالیایی",
	"display_ja" to "ژاپنی",
	"display_ka" to "گرجی",
	"display_kk" to "قزاقی",
	"display_km" to "خمر مرکزی",
	"display_kn" to "کانادا",
	"display_ko" to "کره ای",
	"display_ky" to "قرقیزی",
	"display_lo" to "کار یدی",
	"display_lt" to "لیتوانیایی",
	"display_lv" to "لتونی",
	"display_mk" to "مقدونی",
	"display_ml" to "مالایایی",
	"display_mn" to "مغولی",
	"display_mr" to "مراتی",
	"display_ms" to "مالایی",
	"display_my" to "برمه ای",
	"display_nb" to "نروژی بوکمال",
	"display_ne" to "نپالی",
	"display_nl" to "هلندی",
	"display_or" to "اوریا",
	"display_pa" to "پنجابی",
	"display_pl" to "لهستانی",
	"display_pt" to "پرتغالی",
	"display_pt_BR" to "پرتغالی (برزیل)",
	"display_pt_PT" to "پرتغالی اروپایی",
	"display_ro" to "رومانیایی",
	"display_ru" to "روسی",
	"display_si" to "سینهالی",
	"display_sk" to "اسلواکی",
	"display_sl" to "اسلوونیایی",
	"display_sq" to "آلبانیایی",
	"display_sr" to "صربی",
	"display_sv" to "سوئدی",
	"display_sw" to "سواحیلی",
	"display_ta" to "تامیل",
	"display_te" to "تلوگو",
	"display_th" to "تایلندی",
	"display_tr" to "ترکی",
	"display_uk" to "اوکراینی",
	"display_ur" to "اردو",
	"display_uz" to "ازبکستان",
	"display_vi" to "ویتنامی",
	"display_zh_Hant_TW" to "چینی (تایوان، سنتی)",
	"display_zu" to "زولو",
	"document_estimate" to "تخمینی",
	"document_invoice" to "فاکتور",
	"document_quote" to "نقل قول",
	"done" to "انجام شده",
	"download_app" to "برنامه را دانلود کنید",
	"download_invoice" to "دانلود فاکتور",
	"download_pdf" to "PDF را دانلود کنید",
	"draw_signature" to "رسم امضا",
	"due" to Formattable { (date) -> "با توجه به ${date}" },
	"due_date" to "موعد مقرر",
	"due_day" to "در یک روز",
	"due_label" to "دلیل",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "سررسید ${documentType} ${documentNumber} ${dueWhen} است!" },
	"due_today" to "امروز",
	"due_week" to "در یک هفته",
	"duplicate" to "تکراری",
	"duration_day" to "روز",
	"duration_month" to "ماه",
	"duration_week" to "هفته",
	"duration_year" to "سال",
	"email" to "ایمیل",
	"email_blank_error" to "ایمیل مورد نیاز.",
	"email_error" to "آدرس ایمیل نامعتبر.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "از کار شما متشکرم. برای مشاهده کامل ${documentType} روی دکمه انتهای این نامه کلیک کنید. توجه داشته باشید که تا ${dueDate} معتبر است." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "شما یک ${documentType} ${invoiceNumber} جدید دریافت کرده‌اید!" },
	"email_invoice_cta" to Formattable { (documentType) -> "مشاهده ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "${clientName} عزیز" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "از کار شما متشکرم. برای مشاهده کامل ${documentType} روی دکمه انتهای این نامه کلیک کنید. لطفاً موجودی را تا ${dueDate} پرداخت کنید." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "شما یک ${documentType} ${invoiceNumber} جدید دریافت کرده‌اید!" },
	"email_reset_success_message" to "ایمیل برای تنظیم مجدد رمز عبور ارسال شده، لطفا صندوق پستی خود را بررسی کنید.",
	"empty_clients_list_message" to "تو هيچ مشتري اي براي رديابي نداري یک سرویس گیرنده جدید اضافه کنید.",
	"empty_estimate_list_message" to "اولین برآورد خود را ایجاد کنید",
	"empty_expenses" to "شما هیچ هزینه ای برای پیگیری ندارید. یک هزینه جدید اضافه کنید.",
	"empty_invoice_list_message" to "اولین فاکتور خود را ایجاد کنید",
	"empty_items_list_message" to "شما هیچ موردی داري، يک مورد جديد اضافه کن!",
	"empty_signatures_list_message" to "هيچ امضايي پيدا نشد . ایجاد یکی!",
	"empty_taxes_list_message" to "تو هيچ قانون مالياتي نداري . یک قانون مالیاتی جدید اضافه کنید.",
	"empty_templates_list_message" to "قالب ها در دسترس نیستند.",
	"enabled" to "فعال",
	"entry" to "ورود",
	"equity" to "سهام",
	"estimate_number" to "تخمین زدن #",
	"estimates" to "برآورد",
	"estimates_report" to "برآوردها",
	"expense_bank_fees" to "هزینه های بانکی",
	"expense_benefits" to "مزایای",
	"expense_communication" to "ارتباطات",
	"expense_debt" to "بدهی",
	"expense_deleted" to "هزینه حذف شده",
	"expense_depreciation" to "استهلاک",
	"expense_gifts" to "هدیه",
	"expense_insurance" to "بیمه",
	"expense_inventory" to "موجودی",
	"expense_legal" to "قانونی",
	"expense_maintenance" to "نگهداری",
	"expense_marketing" to "بازاریابی",
	"expense_meals" to "غذا",
	"expense_office_supplies" to "لوازم اداری",
	"expense_rent" to "اجاره",
	"expense_salaries" to "حقوق",
	"expense_shopping" to "خرید",
	"expense_software" to "نرم افزار",
	"expense_status_available" to "دسترس",
	"expense_status_processing" to "پردازش",
	"expense_tax" to "مالیات",
	"expense_training" to "آموزش",
	"expense_transport" to "انتقال",
	"expense_travel" to "سفر",
	"expense_utility" to "سودمند",
	"expenses" to "هزینه",
	"feedback_option" to "بازخورد",
	"filter" to "فیلتر",
	"first_name_hint" to "نام و نام خانوادگی",
	"forgot_password" to "بازنشانی رمز عبور",
	"forgot_password_label" to "رمز عبور را فراموش کرده ام",
	"from" to "از",
	"general_error" to "خطایی رخ داد، لطفاً بعداً دوباره امتحان کنید.",
	"general_ledger" to "دفتر کل",
	"go_back_label" to "برگرد",
	"group_by" to "گروه بر اساس:",
	"image_view" to "تصویر",
	"install" to "نصب",
	"invalid_credentials_error" to "اعتبار نامعتبر.",
	"invalid_email_error" to "ایمیل نامعتبر.",
	"invalid_user_error" to "اعتبار کاربری نامعتبر.",
	"invoice_deleted_message" to "فاکتور حذف شده",
	"invoice_menu" to "منوی فاکتور",
	"invoice_number" to "فاکتور #",
	"invoices" to "فاکتورها",
	"items_option" to "موارد",
	"keep" to "نگه داشتن",
	"language_selector" to Formattable { (selected) -> "زبان ${selected}" },
	"last_name_hint" to "نام خانوادگی",
	"ledger" to "دفتر کل",
	"legal" to "قانونی",
	"legal_error" to "باید شرایط سیاست خدمات و حریم خصوصی را بپذیرد",
	"liabilities" to "بدهی ها",
	"loading" to "بارگذاری",
	"login_required_error" to "ورود مورد نیاز است.",
	"logo_view" to "عالی",
	"logout" to "خروج",
	"logout_prompt" to "داده های شما با ابر همگام سازی نشده اند. حذف فاکتورها، برآوردها، مشتریان & اطلاعات دیگر در مورد ورود به سیستم؟",
	"main_screen" to "صفحه اصلی",
	"mark_paid" to "علامت گذاری به عنوان پرداخت",
	"message" to "پیام",
	"missing_sender_msg" to "سندی بدون اطلاعات فرستنده ممکن است نامعتبر باشد.",
	"missing_sender_title" to "اطلاعات فرستنده وجود ندارد",
	"month_april" to "آوریل",
	"month_april_short" to "آوریل",
	"month_august" to "مرداد",
	"month_august_short" to "اوت",
	"month_december" to "دسامبر",
	"month_december_short" to "دسامبر",
	"month_february" to "فوریه",
	"month_february_short" to "فوریه",
	"month_january" to "ژانویه",
	"month_january_short" to "ژان",
	"month_july" to "جولای",
	"month_july_short" to "جولای",
	"month_june" to "ژوئن",
	"month_june_short" to "ژوئن",
	"month_march" to "مارس",
	"month_march_short" to "مارس",
	"month_may" to "ممکن است",
	"month_may_short" to "ممکن است",
	"month_november" to "نوامبر",
	"month_november_short" to "نوامبر",
	"month_october" to "اکتبر",
	"month_october_short" to "اکتبر",
	"month_september" to "سپتامبر",
	"month_september_short" to "سپتامبر",
	"multifactor_error" to "موفق به تایید نشد.",
	"new" to "جدید",
	"news" to "اخبار",
	"next" to "بعد",
	"no" to "نه",
	"no_data_available" to "اطلاعاتی موجود نیست",
	"no_search_results" to "ببخشيد، نتونستيم نتيجه اي پيدا کنيم.",
	"none" to "هیچ کدام",
	"note_fields" to "یادداشت",
	"note_hint" to "توجه داشته باشید",
	"notifications_option" to "اطلاعیه ها",
	"ok" to "باشه",
	"onboarding" to "شبانه روزی",
	"onboarding_aboutus_message" to "بخش های اساسی DingVoice برای همیشه رایگان است. ماموریت ما این است که به شما کمک کنیم تا کسب و کار خود را توسعه دهید، با به اشتراک گذاشتن نظرات خود به ما کمک کنید تا بهبود پیدا کنیم.",
	"onboarding_aboutus_title" to "ما اینجاییم تا کمک کنیم",
	"onboarding_paid_1" to "دسترسی به قالب های حرفه ای",
	"onboarding_paid_2" to "نگاهی عمیق تر به امور مالی خود بیندازید",
	"onboarding_paid_3" to "مشکلات را سریع حل کنید",
	"onboarding_paid_4" to "اسناد خود را ایمن نگه دارید",
	"onboarding_paid_5" to "با تغییرات وضعیت فاکتور به روز باشید",
	"onboarding_paid_title" to "حتی سریعتر رشد کنید با ...",
	"onboarding_paywall_message" to "اکنون با بهترین قیمت از همه ویژگی های برتر لذت ببرید.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "فقط ${currencySymbol}${introAmount} برای ${introDuration} ${introPeriod} و سپس ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "فقط ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "رایگان برای ${trialDuration} ${trialPeriod} و سپس فقط ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "رایگان برای ${trialDuration} ${trialPeriod} سپس ${currencySymbol}${introductoryAmount} برای ${introductoryDuration} ${introPeriod} و سپس فقط ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "قابل تمدید خودکار هر زمان خواستید لغو کنید.",
	"onboarding_paywall_restore" to "خرید را بازیابی کنید",
	"onboarding_paywall_restore_failure" to "بازیابی خریدها انجام نشد، بعداً دوباره امتحان کنید یا با پشتیبانی تماس بگیرید.",
	"onboarding_paywall_restore_success" to "بازیابی موفقیت آمیز!",
	"onboarding_paywall_title" to "بدست آوردن عظمت!",
	"onboarding_welcome_message" to "یک مجموعه ابزار کامل برای مدیریت فاکتورها و برآوردهای شما.",
	"onboarding_welcome_title" to "به DingVoice خوش آمدید",
	"other_state" to "دیگر",
	"overdue_state" to "گذشته",
	"paid_state" to "پرداخت",
	"password" to "رمز عبور",
	"password_blank_error" to "رمز عبور مورد نیاز است.",
	"password_mismatch_error" to "رمزهای عبور با هم مطابقت نمی کنند.",
	"pay" to "پرداخت",
	"payment_instructions_label" to "دستورالعمل پرداخت",
	"payment_reminders" to "یادآوری پرداخت",
	"phone_number_fields" to "شماره تلفن",
	"phone_number_hint" to "شماره تلفن",
	"postcode_hint" to "کد پستی",
	"preview" to "پیشنمایش",
	"preview_menu_hint" to "منوی پیش نمایش",
	"price" to "قيمت",
	"privacy_policy" to "سیاست حفظ حریم خصوصی",
	"profile" to "مشخصات",
	"profitable_client" to "سودآورترین مشتری شما...",
	"profits_report" to "سود",
	"promo_content" to "محتوای تبلیغاتی",
	"quantity_hint" to "مقدار",
	"quantity_label_hint" to "برچسب کمیت",
	"query_info" to "پرس و جو اجازه می دهد تا شما را به نوشتن محاسبات سفارشی با اشاره به هر دو مورد و قوانین مالیاتی دیگر به عنوان ورودی. اضافه کردن @ نماد و آیتم یا شرح مالیات به یکی دیگر از ورودی لینک + ، -- ، ، / ، ٪ -- اپراتورهای ریاضی",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} توسط مشتری شما دریافت شد." },
	"received_state" to "اخذ شده",
	"registration_code_fields" to "کد ثبت نام",
	"reminder" to "یادآور",
	"reports" to "گزارش",
	"reports_not_available" to "گزارشی در دسترس نیست، بعداً بازگردید.",
	"reveal_password" to "آشکار کردن رمز عبور",
	"revenue" to "درآمد",
	"sales_tax" to "مالیات بر ارزش افزوده",
	"sales_tax_number" to "شماره مالیات بر ارزش افزوده",
	"save" to "ذخیره",
	"scan_document" to "پویش سند",
	"search" to "جستجو",
	"select" to "انتخاب",
	"select_icon" to "برگزیدن شمایل",
	"select_signature" to "امضا را انتخاب کنید",
	"select_template" to "انتخاب الگو",
	"send" to "ارسال",
	"sent_state" to "فرستاده",
	"setup_sender" to "فرستنده را تنظیم کنید",
	"share_option" to "اشتراک گذاشتن",
	"ship_to" to "ارسال به",
	"sign_in" to "ورود به برنامه",
	"sign_in_apple" to "با اپل ثبت نام کنید",
	"sign_in_email" to "ورود به سیستم با ایمیل",
	"sign_in_facebook" to "ورود به سیستم با فیس بوک",
	"sign_in_google" to "ورود به سیستم با Google",
	"sign_up" to "ثبت نام",
	"sign_up_label" to "حسابي نداري؟",
	"sign_up_link_label" to "ثبت نام!",
	"signatures_option" to "امضا",
	"skip" to "رد شدن",
	"sold_items_report" to "اقلام فروخته شده",
	"sort_by" to "مرتب سازی بر اساس:",
	"status_update" to "به روز رسانی وضعیت سند",
	"sub_annual" to "سالانه",
	"sub_annual_price" to Formattable { (price) -> "${price}/سال" },
	"sub_backups" to "پشتیبان گیری های ابری",
	"sub_insights" to "بینش‌ها",
	"sub_month" to "ماهانه",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ماه" },
	"sub_notifications" to "اعلانات",
	"sub_popup_message" to "اشتراک ها به زودی می آیند. يه کم ديگه اينجا رو چک کن",
	"sub_support" to "پشتیبانی",
	"sub_templates" to "الگوها",
	"subscribe" to "اشتراک",
	"subscription_header" to "رشد کسب و کار خود را با:",
	"subscription_option" to "اشتراک",
	"subscriptions_load_fail" to "متأسفیم، در حال حاضر نمی‌توانیم اشتراک‌های شما را بازیابی کنیم. لطفاً بعداً دوباره امتحان کنید یا برای راهنمایی با تیم پشتیبانی ما تماس بگیرید.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "فرعی",
	"sum" to "مجموع",
	"support_option" to "حمایت",
	"switch_reports" to "گزارش سوئیچ",
	"tax" to "مالیات",
	"tax_deleted_message" to "مالیات حذف شده",
	"tax_description_warning" to "اگر استفاده می شود این نشان می دهد تا در فاکتور",
	"tax_input_flat" to "تخت",
	"tax_input_percent" to "درصد",
	"tax_number_fields" to "شماره مالیاتی",
	"tax_number_hint" to "شماره مالیاتی",
	"tax_query_info_description" to "اطلاعات پرس و جو",
	"tax_report" to "مالیات",
	"tax_rules_option" to "قوانین مالیاتی",
	"taxed_clients_report" to "مشتریان مشمول مالیات",
	"template" to "الگو",
	"template_selector_info" to "کش رفتن برای تغییر قالب پیش فرض",
	"template_unlock_promo" to "با Premium به الگوهای بیشتری دسترسی داشته باشید",
	"templates_option" to "قالب",
	"terms_of_service" to "شرایط خدمات",
	"thank_you" to "متشکرم",
	"to" to "به",
	"total" to Formattable { (sum) -> "مجموع: ${sum}" },
	"total_label" to "جمع",
	"undo_action" to "خنثیسازی",
	"unit" to "واحد",
	"unit_cost_hint" to "هزینه واحد",
	"unknown_client" to "مشتری ناشناس",
	"unlock" to "باز کردن قفل",
	"unnamed_item" to "آیتم بدون نام",
	"upgrade" to "ارتقاء",
	"user_collision_error" to "حساب در حال حاضر وجود دارد.",
	"valid_until" to "معتبر است تا",
	"warning" to "هشدار!",
	"yes" to "بله",
    )
    
}