// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class It  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Accetta ${termsOfService} e ${privacyPolicy}" }
override val account: String = "conto"
override val accounting: String = "contabilità"
override val accruals: String = "ratei"
override val action_code_error: String = "Codice non valido."
override val add_client_action: String = "Aggiungi cliente"
override val add_customer: String = "Aggiungi cliente"
override val add_expense: String = "Aggiungi spese"
override val add_fields_label: String = "Aggiungere:"
override val add_invoice_action: String = "Creare"
override val add_item_action: String = "Aggiungi elemento"
override val add_item_button: String = "Aggiungi articolo"
override val add_signature_action: String = "Aggiungere la firma"
override val add_tax_action: String = "Aggiungere la tassa"
override val address_fields: String = "Indirizzo"
override val address_line_hint: String = "Linea dell'indirizzo"
override val advanced: String = "ADVANCED"
override val agree_marketing: String = "Accetto di ricevere comunicazioni di marketing diretto"
override val all_notifications: String = "Tutte le notifiche"
override val amortization: String = "ammortamento"
override val amount: String = "Importo"
override val asset: String = "bene"
override val attach_file: String = "Allegare il file"
override val attachment: String = "Allegato"
override val auth_error: String = "Azione fallita, riprova."
override val available_for_paid: String = "La sincronizzazione cloud è disponibile solo con un abbonamento a pagamento."
override val backups_option: String = "Backups"
override val balance: String = "saldo"
override val bank_account: String = "Conto Bancario"
override val bill_number: String = "Numero di fattura"
override val cancel: String = "Cancella"
override val cancel_trial: String = "Annulla in qualsiasi momento"
override val capital: String = "capitale"
override val cash: String = "contanti"
override val categories: String = "Categorie"
override val category: String = "Categoria"
override val category_add: String = "Aggiungi categoria"
override val category_delete: String = "Cancellare la categoria"
override val category_name: String = "Nome della categoria"
override val change_language: Formattable = Formattable { (language) -> "Lingua della fattura: ${language}" }
override val change_language_label: String = "Lingua della Fattura"
override val change_template: String = "Cambia modello"
override val city_hint: String = "Città"
override val clear: String = "Chiaro"
override val client: String = "Cliente"
override val client_actions: String = "Azioni del cliente"
override val client_deleted_message: String = "Cliente cancellato"
override val clients_error_required_fields: String = "È richiesto almeno un valore."
override val clients_option: String = "Clienti"
override val close: String = "Chiudere"
override val cloud_sync: String = "Sincronizzazione del cloud"
override val coming_soon: String = "In arrivo!"
override val company_logo: String = "Logo aziendale"
override val company_name_hint: String = "Azienda"
override val confirm_password_label: String = "Conferma la password"
override val confirmation_title: String = "Sei sicuro?"
override val convert_to_invoice: String = "Convertire in fattura"
override val country_hint: String = "Paese"
override val create_client: String = "Crea cliente"
override val create_company: String = "Creare un'azienda"
override val create_document: String = "Creare un documento"
override val create_estimate: String = "Stima"
override val create_invoice: String = "Fattura"
override val create_item: String = "Crea articolo"
override val create_new_client: String = "Creare un nuovo cliente"
override val create_new_item: String = "Creare un nuovo elemento"
override val create_new_tax: String = "Creare una nuova tassa"
override val create_signature: String = "Firma"
override val created: String = "creato"
override val credit: String = "credito"
override val customer: String = "Cliente"
override val date: String = "Data"
override val debit: String = "debito"
override val debt: String = "debito"
override val default_currency: Formattable = Formattable { (currency) -> "Valuta predefinita: ${currency}" }
override val default_currency_label: String = "Valuta predefinita"
override val delete: String = "Cancellare"
override val delete_account: String = "Cancella Account"
override val delete_confirmation_message: String = "Tutti i tuoi dati andranno persi."
override val delete_item_title: String = "Cancellare la voce?"
override val delete_signature: String = "Cancellare la firma"
override val depreciation: String = "deprezzamento"
override val description: String = "Descrizione"
override val description_hint: String = "Descrizione"
override val detailed_report: String = "Rapporto Dettagliato"
override val disabled: String = "Disabile"
override val discount: String = "Sconto"
override val discount_hint: String = "Sconto"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amarico"
override val display_ar: String = "Arabo"
override val display_as: String = "Assamese"
override val display_az: String = "Azerbaigian"
override val display_be: String = "Bielorusso"
override val display_bg: String = "Bulgaro"
override val display_bn: String = "Bengalese"
override val display_bs: String = "Bosniaco"
override val display_ca: String = "Catalano"
override val display_cs: String = "Ceco"
override val display_da: String = "Danese"
override val display_de: String = "Tedesco"
override val display_el: String = "Greco"
override val display_en: String = "Inglese"
override val display_es: String = "Spagnolo"
override val display_es_MX: String = "Spagnolo (Messico)"
override val display_et: String = "Estone"
override val display_eu: String = "Basco"
override val display_fa: String = "Persiano"
override val display_fi: String = "Finlandese"
override val display_fil: String = "Filippino"
override val display_fr: String = "Francese"
override val display_fr_CA: String = "Francese canadese"
override val display_gl: String = "Galiziano"
override val display_gu: String = "Gujarati"
override val display_he: String = "Ebraico"
override val display_hi: String = "Hindi"
override val display_hr: String = "Croato"
override val display_hu: String = "Ungherese"
override val display_hy: String = "Armeno"
override val display_id: String = "Indonesiano"
override val display_is: String = "Islandese"
override val display_it: String = "Italiano"
override val display_ja: String = "Giapponese"
override val display_ka: String = "Georgiano"
override val display_kk: String = "Kazako"
override val display_km: String = "Khmer centrale"
override val display_kn: String = "Kannada"
override val display_ko: String = "Coreano"
override val display_ky: String = "Kirghizistan"
override val display_lo: String = "Lao"
override val display_lt: String = "Lituano"
override val display_lv: String = "Lettone"
override val display_mk: String = "Macedone"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolo"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malese"
override val display_my: String = "Birmano"
override val display_nb: String = "Norvegese Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Olandese"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polacco"
override val display_pt: String = "Portoghese"
override val display_pt_BR: String = "Portoghese (Brasile)"
override val display_pt_PT: String = "Portoghese europeo"
override val display_ro: String = "Rumeno"
override val display_ru: String = "Russo"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovacco"
override val display_sl: String = "Sloveno"
override val display_sq: String = "Albanese"
override val display_sr: String = "Serbo"
override val display_sv: String = "Svedese"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Turco"
override val display_uk: String = "Ucraino"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbekistan"
override val display_vi: String = "Vietnamita"
override val display_zh_Hant_TW: String = "Cinese (Taiwan, Tradizionale)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Stima"
override val document_invoice: String = "Fattura"
override val document_quote: String = "Oferta"
override val done: String = "Fatto"
override val download_app: String = "Scarica l'applicazione"
override val download_invoice: String = "Scarica la Fattura"
override val download_pdf: String = "Scaricare PDF"
override val draw_signature: String = "Disegnare una firma"
override val due: Formattable = Formattable { (data) -> "Scadenza ${data}" }
override val due_date: String = "Data di scadenza"
override val due_day: String = "in un giorno"
override val due_label: String = "Dovuto"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} è dovuto ${dueWhen}!" }
override val due_today: String = "Oggi"
override val due_week: String = "in una settimana"
override val duplicate: String = "Duplicare"
override val duration_day: String = "giorno"
override val duration_month: String = "Mese"
override val duration_week: String = "settimana"
override val duration_year: String = "anno"
override val email: String = "Email"
override val email_blank_error: String = "Email richiesta."
override val email_error: String = "Indirizzo email non valido."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Grazie per il tuo business. Per visualizzare ${documentType} completo, fai clic sul pulsante alla fine di questa lettera. Nota che è valido fino a ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Hai ricevuto un nuovo ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "VISUALIZZA ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Gentile ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Grazie per il tuo business. Per visualizzare ${documentType} completo, fai clic sul pulsante alla fine di questa lettera. Si prega di pagare il saldo fino a ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Hai ricevuto un nuovo ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Email per reimpostare la password inviata, controlla la tua casella di posta."
override val empty_clients_list_message: String = "Non hai clienti da tracciare. Aggiungi un nuovo cliente."
override val empty_estimate_list_message: String = "Crea il tuo primo preventivo"
override val empty_expenses: String = "Non ci sono spese da monitorare. Aggiungere una nuova spesa."
override val empty_invoice_list_message: String = "Crea la tua prima fattura"
override val empty_items_list_message: String = "Non hai articoli, aggiungi un nuovo articolo!"
override val empty_signatures_list_message: String = "Nessuna firma trovata. Creane una!"
override val empty_taxes_list_message: String = "Non hai regole fiscali. Aggiungi una nuova regola fiscale."
override val empty_templates_list_message: String = "Modelli non disponibili."
override val enabled: String = "Abilitato"
override val entry: String = "partita"
override val equity: String = "patrimonio netto"
override val estimate_number: String = "Stima #"
override val estimates: String = "Stime"
override val estimates_report: String = "Stime"
override val expense_bank_fees: String = "Commissioni bancarie"
override val expense_benefits: String = "Vantaggi"
override val expense_communication: String = "Comunicazione"
override val expense_debt: String = "Debito"
override val expense_deleted: String = "Spese eliminate"
override val expense_depreciation: String = "Ammortamento"
override val expense_gifts: String = "Regali"
override val expense_insurance: String = "Assicurazione"
override val expense_inventory: String = "Inventario"
override val expense_legal: String = "Legale"
override val expense_maintenance: String = "Manutenzione"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Pasti"
override val expense_office_supplies: String = "Forniture per ufficio"
override val expense_rent: String = "Affitto"
override val expense_salaries: String = "Stipendi"
override val expense_shopping: String = "Shopping"
override val expense_software: String = "Software"
override val expense_status_available: String = "DISPONIBILE"
override val expense_status_processing: String = "Elaborazione"
override val expense_tax: String = "Imposta"
override val expense_training: String = "formazione"
override val expense_transport: String = "Trasporto"
override val expense_travel: String = "Viaggi"
override val expense_utility: String = "Utilità"
override val expenses: String = "Spese"
override val feedback_option: String = "Feedback"
override val filter: String = "Filtro"
override val first_name_hint: String = "Nome"
override val forgot_password: String = "Ripristina la password"
override val forgot_password_label: String = "Password dimenticata"
override val from: String = "da"
override val general_error: String = "Si è verificato un errore, riprovare più tardi."
override val general_ledger: String = "libro mastro generale"
override val go_back_label: String = "Torna indietro"
override val group_by: String = "Gruppo da:"
override val image_view: String = "Immagine"
override val install: String = "Installa"
override val invalid_credentials_error: String = "Credenziali non valide."
override val invalid_email_error: String = "Email non valida."
override val invalid_user_error: String = "Credenziali utente non valide."
override val invoice_deleted_message: String = "Fattura cancellata"
override val invoice_menu: String = "Menu Fattura"
override val invoice_number: String = "Fattura #"
override val invoices: String = "fatture"
override val items_option: String = "articoli"
override val keep: String = "Tenere"
override val language_selector: Formattable = Formattable { (selected) -> "Lingua ${selected}" }
override val last_name_hint: String = "Nome e cognome"
override val ledger: String = "libro contabile"
override val legal: String = "Legale"
override val legal_error: String = "Deve accettare i Termini di servizio e l'Informativa sulla privacy"
override val liabilities: String = "passività"
override val loading: String = "Caricamento"
override val login_required_error: String = "Richiesto il login."
override val logo_view: String = "Logo"
override val logout: String = "Logout"
override val logout_prompt: String = "I tuoi dati non sono sincronizzati con il cloud. Cancellare fatture, preventivi, clienti e altre informazioni al momento del logout?"
override val main_screen: String = "Schermo principale"
override val mark_paid: String = "Segna come pagato"
override val message: String = "Messaggio"
override val missing_sender_msg: String = "Un documento senza informazioni sul mittente potrebbe non essere valido."
override val missing_sender_title: String = "Informazioni sul mittente mancanti"
override val month_april: String = "Aprile"
override val month_april_short: String = "Aprile"
override val month_august: String = "Agosto"
override val month_august_short: String = "Ago."
override val month_december: String = "Dicembre"
override val month_december_short: String = "Dic."
override val month_february: String = "Febbraio"
override val month_february_short: String = "Feb."
override val month_january: String = "Gennaio"
override val month_january_short: String = "Gen."
override val month_july: String = "Luglio"
override val month_july_short: String = "Luglio"
override val month_june: String = "Giugno"
override val month_june_short: String = "Giugno"
override val month_march: String = "Marzo"
override val month_march_short: String = "Marzo"
override val month_may: String = "Maggio"
override val month_may_short: String = "Maggio"
override val month_november: String = "Novembre"
override val month_november_short: String = "Nov."
override val month_october: String = "Ottobre"
override val month_october_short: String = "Ott."
override val month_september: String = "Settembre"
override val month_september_short: String = "Sett."
override val multifactor_error: String = "Impossibile verificare."
override val new: String = "Nuovo"
override val news: String = "Notizie"
override val next: String = "Prossimo"
override val no: String = "no"
override val no_data_available: String = "Nessun dato disponibile"
override val no_search_results: String = "Spiacenti, non abbiamo trovato alcun risultato."
override val none: String = "Nessuno"
override val note_fields: String = "Note"
override val note_hint: String = "Nota"
override val notifications_option: String = "Notifiche"
override val ok: String = "OK"
override val onboarding: String = "imbarco"
override val onboarding_aboutus_message: String = "Le parti essenziali di DingVoice sono gratuite per sempre. La nostra missione è aiutarti a far crescere la tua attività, aiutaci a migliorare condividendo il tuo feedback."
override val onboarding_aboutus_title: String = "Siamo qui per aiutare"
override val onboarding_paid_1: String = "Accedi a modelli dall&#39;aspetto professionale"
override val onboarding_paid_2: String = "Dai uno sguardo più approfondito ai tuoi dati finanziari"
override val onboarding_paid_3: String = "Risolvi rapidamente i problemi"
override val onboarding_paid_4: String = "Mantieni i tuoi documenti al sicuro"
override val onboarding_paid_5: String = "Rimani aggiornato sulle modifiche allo stato delle fatture"
override val onboarding_paid_title: String = "Cresci ancora più velocemente con..."
override val onboarding_paywall_message: String = "Goditi tutte le funzionalità premium ora al miglior prezzo."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Solo ${currencySymbol}${introAmount} per ${introDuration} ${introPeriod} e poi ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Solo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratuito per ${trialDuration} ${trialPeriod} e poi solo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratuito per ${trialDuration} ${trialPeriod}, poi ${currencySymbol}${introductoryAmount} per ${introductoryDuration} ${introPeriod} e poi solo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Rinnovabile automaticamente. Annullamento in qualsiasi momento."
override val onboarding_paywall_restore: String = "Ripristinare l'acquisto"
override val onboarding_paywall_restore_failure: String = "Il ripristino degli acquisti non è riuscito, riprovare più tardi o contattare l'assistenza."
override val onboarding_paywall_restore_success: String = "Ripristino riuscito!"
override val onboarding_paywall_title: String = "Raggiungete la grandezza!"
override val onboarding_welcome_message: String = "Un set di strumenti completo per gestire fatture e preventivi."
override val onboarding_welcome_title: String = "Benvenuti a DingVoice"
override val other_state: String = "Altro"
override val overdue_state: String = "Scaduto"
override val paid_state: String = "Pagato"
override val password: String = "Password"
override val password_blank_error: String = "Password richiesta."
override val password_mismatch_error: String = "Le password non corrispondono."
override val pay: String = "paga"
override val payment_instructions_label: String = "Istruzioni per il pagamento"
override val payment_reminders: String = "Promemoria di pagamento"
override val phone_number_fields: String = "Numero di telefono"
override val phone_number_hint: String = "Numero di telefono"
override val postcode_hint: String = "Codice postale"
override val preview: String = "Anteprima"
override val preview_menu_hint: String = "Menu di anteprima"
override val price: String = "Prezzo"
override val privacy_policy: String = "Politica sulla privacy"
override val profile: String = "Profilo"
override val profitable_client: String = "Il tuo cliente più redditizio è..."
override val profits_report: String = "Profitti"
override val promo_content: String = "Contenuto promozionale"
override val quantity_hint: String = "Quantità"
override val quantity_label_hint: String = "Quantità Etichetta"
override val query_info: String = "Query permette di scrivere calcoli personalizzati facendo riferimento sia agli articoli che ad altre regole fiscali come input. Aggiungi il simbolo @ e la descrizione dell'articolo o dell'imposta per collegare un'altra voce +, -, *, /, % - operatori matematici"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} è stato ricevuto dal vostro cliente." }
override val received_state: String = "Ricevuto"
override val registration_code_fields: String = "Codice di Registrazione"
override val reminder: String = "Promemoria"
override val reports: String = "Rapporti"
override val reports_not_available: String = "Non ci sono rapporti disponibili, torna più tardi"
override val reveal_password: String = "Rivela la password"
override val revenue: String = "ricavi"
override val sales_tax: String = "IVA"
override val sales_tax_number: String = "Numero di partita IVA"
override val save: String = "Salva"
override val scan_document: String = "Scansione del documento"
override val search: String = "Cerca"
override val select: String = "Selezionare"
override val select_icon: String = "Selezionare l'icona"
override val select_signature: String = "Seleziona la firma"
override val select_template: String = "Seleziona il modello"
override val send: String = "Invia"
override val sent_state: String = "Inviato"
override val setup_sender: String = "Imposta mittente"
override val share_option: String = "Condividi"
override val ship_to: String = "Spedire a"
override val sign_in: String = "Accedi"
override val sign_in_apple: String = "Accedi con Apple"
override val sign_in_email: String = "Accedi con l'email"
override val sign_in_facebook: String = "Accedi con Facebook"
override val sign_in_google: String = "Accedi con Google"
override val sign_up: String = "Iscriviti"
override val sign_up_label: String = "Non hai un account?"
override val sign_up_link_label: String = "Iscriviti!"
override val signatures_option: String = "Firme"
override val skip: String = "Salta"
override val sold_items_report: String = "Articoli Venduti"
override val sort_by: String = "Ordina per:"
override val status_update: String = "Aggiornamento sullo stato del documento"
override val sub_annual: String = "Annuale"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/anno" }
override val sub_backups: String = "Backup nel cloud"
override val sub_insights: String = "Approfondimenti"
override val sub_month: String = "Mensile"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mese" }
override val sub_notifications: String = "Notifiche"
override val sub_popup_message: String = "Gli abbonamenti sono in arrivo. Controllate qui tra un po'."
override val sub_support: String = "Assistenza"
override val sub_templates: String = "Modelli"
override val subscribe: String = "Iscriviti"
override val subscription_header: String = "Fai crescere il tuo business con:"
override val subscription_option: String = "Abbonamento"
override val subscriptions_load_fail: String = "Siamo spiacenti, ma in questo momento non siamo in grado di recuperare i vostri abbonamenti. Riprovi più tardi o contatti il nostro team di assistenza per ricevere assistenza."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotale: ${sum}" }
override val subtotal_label: String = "Subtotale"
override val sum: String = "Somma"
override val support_option: String = "Supporto"
override val switch_reports: String = "Cambia Rapporto"
override val tax: String = "TAX"
override val tax_deleted_message: String = "Tassa cancellata"
override val tax_description_warning: String = "Se usato, questo appare su una fattura"
override val tax_input_flat: String = "Flat"
override val tax_input_percent: String = "Percentuale"
override val tax_number_fields: String = "Codice fiscale"
override val tax_number_hint: String = "Codice fiscale"
override val tax_query_info_description: String = "Informazioni sulla query"
override val tax_report: String = "Tassa"
override val tax_rules_option: String = "Regole fiscali"
override val taxed_clients_report: String = "Clienti tassati"
override val template: String = "Template"
override val template_selector_info: String = "Scorri per cambiare il modello predefinito"
override val template_unlock_promo: String = "Accesso a più modelli con Premium"
override val templates_option: String = "Modelli"
override val terms_of_service: String = "Termini di servizio"
override val thank_you: String = "Grazie"
override val to: String = "a"
override val total: Formattable = Formattable { (sum) -> "Totale: ${sum}" }
override val total_label: String = "TOTALE"
override val undo_action: String = "Undo"
override val unit: String = "Unità"
override val unit_cost_hint: String = "Costo unitario"
override val unknown_client: String = "Cliente sconosciuto"
override val unlock: String = "Sblocca"
override val unnamed_item: String = "Articolo senza nome"
override val upgrade: String = "Aggiornare"
override val user_collision_error: String = "L'account esiste già."
override val valid_until: String = "Valido fino a"
override val warning: String = "Attenzione!"
override val yes: String = "sì"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Accetta ${termsOfService} e ${privacyPolicy}" },
	"account" to "conto",
	"accounting" to "contabilità",
	"accruals" to "ratei",
	"action_code_error" to "Codice non valido.",
	"add_client_action" to "Aggiungi cliente",
	"add_customer" to "Aggiungi cliente",
	"add_expense" to "Aggiungi spese",
	"add_fields_label" to "Aggiungere:",
	"add_invoice_action" to "Creare",
	"add_item_action" to "Aggiungi elemento",
	"add_item_button" to "Aggiungi articolo",
	"add_signature_action" to "Aggiungere la firma",
	"add_tax_action" to "Aggiungere la tassa",
	"address_fields" to "Indirizzo",
	"address_line_hint" to "Linea dell'indirizzo",
	"advanced" to "ADVANCED",
	"agree_marketing" to "Accetto di ricevere comunicazioni di marketing diretto",
	"all_notifications" to "Tutte le notifiche",
	"amortization" to "ammortamento",
	"amount" to "Importo",
	"asset" to "bene",
	"attach_file" to "Allegare il file",
	"attachment" to "Allegato",
	"auth_error" to "Azione fallita, riprova.",
	"available_for_paid" to "La sincronizzazione cloud è disponibile solo con un abbonamento a pagamento.",
	"backups_option" to "Backups",
	"balance" to "saldo",
	"bank_account" to "Conto Bancario",
	"bill_number" to "Numero di fattura",
	"cancel" to "Cancella",
	"cancel_trial" to "Annulla in qualsiasi momento",
	"capital" to "capitale",
	"cash" to "contanti",
	"categories" to "Categorie",
	"category" to "Categoria",
	"category_add" to "Aggiungi categoria",
	"category_delete" to "Cancellare la categoria",
	"category_name" to "Nome della categoria",
	"change_language" to Formattable { (language) -> "Lingua della fattura: ${language}" },
	"change_language_label" to "Lingua della Fattura",
	"change_template" to "Cambia modello",
	"city_hint" to "Città",
	"clear" to "Chiaro",
	"client" to "Cliente",
	"client_actions" to "Azioni del cliente",
	"client_deleted_message" to "Cliente cancellato",
	"clients_error_required_fields" to "È richiesto almeno un valore.",
	"clients_option" to "Clienti",
	"close" to "Chiudere",
	"cloud_sync" to "Sincronizzazione del cloud",
	"coming_soon" to "In arrivo!",
	"company_logo" to "Logo aziendale",
	"company_name_hint" to "Azienda",
	"confirm_password_label" to "Conferma la password",
	"confirmation_title" to "Sei sicuro?",
	"convert_to_invoice" to "Convertire in fattura",
	"country_hint" to "Paese",
	"create_client" to "Crea cliente",
	"create_company" to "Creare un'azienda",
	"create_document" to "Creare un documento",
	"create_estimate" to "Stima",
	"create_invoice" to "Fattura",
	"create_item" to "Crea articolo",
	"create_new_client" to "Creare un nuovo cliente",
	"create_new_item" to "Creare un nuovo elemento",
	"create_new_tax" to "Creare una nuova tassa",
	"create_signature" to "Firma",
	"created" to "creato",
	"credit" to "credito",
	"customer" to "Cliente",
	"date" to "Data",
	"debit" to "debito",
	"debt" to "debito",
	"default_currency" to Formattable { (currency) -> "Valuta predefinita: ${currency}" },
	"default_currency_label" to "Valuta predefinita",
	"delete" to "Cancellare",
	"delete_account" to "Cancella Account",
	"delete_confirmation_message" to "Tutti i tuoi dati andranno persi.",
	"delete_item_title" to "Cancellare la voce?",
	"delete_signature" to "Cancellare la firma",
	"depreciation" to "deprezzamento",
	"description" to "Descrizione",
	"description_hint" to "Descrizione",
	"detailed_report" to "Rapporto Dettagliato",
	"disabled" to "Disabile",
	"discount" to "Sconto",
	"discount_hint" to "Sconto",
	"display_af" to "Afrikaans",
	"display_am" to "Amarico",
	"display_ar" to "Arabo",
	"display_as" to "Assamese",
	"display_az" to "Azerbaigian",
	"display_be" to "Bielorusso",
	"display_bg" to "Bulgaro",
	"display_bn" to "Bengalese",
	"display_bs" to "Bosniaco",
	"display_ca" to "Catalano",
	"display_cs" to "Ceco",
	"display_da" to "Danese",
	"display_de" to "Tedesco",
	"display_el" to "Greco",
	"display_en" to "Inglese",
	"display_es" to "Spagnolo",
	"display_es_MX" to "Spagnolo (Messico)",
	"display_et" to "Estone",
	"display_eu" to "Basco",
	"display_fa" to "Persiano",
	"display_fi" to "Finlandese",
	"display_fil" to "Filippino",
	"display_fr" to "Francese",
	"display_fr_CA" to "Francese canadese",
	"display_gl" to "Galiziano",
	"display_gu" to "Gujarati",
	"display_he" to "Ebraico",
	"display_hi" to "Hindi",
	"display_hr" to "Croato",
	"display_hu" to "Ungherese",
	"display_hy" to "Armeno",
	"display_id" to "Indonesiano",
	"display_is" to "Islandese",
	"display_it" to "Italiano",
	"display_ja" to "Giapponese",
	"display_ka" to "Georgiano",
	"display_kk" to "Kazako",
	"display_km" to "Khmer centrale",
	"display_kn" to "Kannada",
	"display_ko" to "Coreano",
	"display_ky" to "Kirghizistan",
	"display_lo" to "Lao",
	"display_lt" to "Lituano",
	"display_lv" to "Lettone",
	"display_mk" to "Macedone",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolo",
	"display_mr" to "Marathi",
	"display_ms" to "Malese",
	"display_my" to "Birmano",
	"display_nb" to "Norvegese Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Olandese",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polacco",
	"display_pt" to "Portoghese",
	"display_pt_BR" to "Portoghese (Brasile)",
	"display_pt_PT" to "Portoghese europeo",
	"display_ro" to "Rumeno",
	"display_ru" to "Russo",
	"display_si" to "Sinhala",
	"display_sk" to "Slovacco",
	"display_sl" to "Sloveno",
	"display_sq" to "Albanese",
	"display_sr" to "Serbo",
	"display_sv" to "Svedese",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Turco",
	"display_uk" to "Ucraino",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbekistan",
	"display_vi" to "Vietnamita",
	"display_zh_Hant_TW" to "Cinese (Taiwan, Tradizionale)",
	"display_zu" to "Zulu",
	"document_estimate" to "Stima",
	"document_invoice" to "Fattura",
	"document_quote" to "Oferta",
	"done" to "Fatto",
	"download_app" to "Scarica l'applicazione",
	"download_invoice" to "Scarica la Fattura",
	"download_pdf" to "Scaricare PDF",
	"draw_signature" to "Disegnare una firma",
	"due" to Formattable { (data) -> "Scadenza ${data}" },
	"due_date" to "Data di scadenza",
	"due_day" to "in un giorno",
	"due_label" to "Dovuto",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} è dovuto ${dueWhen}!" },
	"due_today" to "Oggi",
	"due_week" to "in una settimana",
	"duplicate" to "Duplicare",
	"duration_day" to "giorno",
	"duration_month" to "Mese",
	"duration_week" to "settimana",
	"duration_year" to "anno",
	"email" to "Email",
	"email_blank_error" to "Email richiesta.",
	"email_error" to "Indirizzo email non valido.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Grazie per il tuo business. Per visualizzare ${documentType} completo, fai clic sul pulsante alla fine di questa lettera. Nota che è valido fino a ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Hai ricevuto un nuovo ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "VISUALIZZA ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Gentile ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Grazie per il tuo business. Per visualizzare ${documentType} completo, fai clic sul pulsante alla fine di questa lettera. Si prega di pagare il saldo fino a ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Hai ricevuto un nuovo ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Email per reimpostare la password inviata, controlla la tua casella di posta.",
	"empty_clients_list_message" to "Non hai clienti da tracciare. Aggiungi un nuovo cliente.",
	"empty_estimate_list_message" to "Crea il tuo primo preventivo",
	"empty_expenses" to "Non ci sono spese da monitorare. Aggiungere una nuova spesa.",
	"empty_invoice_list_message" to "Crea la tua prima fattura",
	"empty_items_list_message" to "Non hai articoli, aggiungi un nuovo articolo!",
	"empty_signatures_list_message" to "Nessuna firma trovata. Creane una!",
	"empty_taxes_list_message" to "Non hai regole fiscali. Aggiungi una nuova regola fiscale.",
	"empty_templates_list_message" to "Modelli non disponibili.",
	"enabled" to "Abilitato",
	"entry" to "partita",
	"equity" to "patrimonio netto",
	"estimate_number" to "Stima #",
	"estimates" to "Stime",
	"estimates_report" to "Stime",
	"expense_bank_fees" to "Commissioni bancarie",
	"expense_benefits" to "Vantaggi",
	"expense_communication" to "Comunicazione",
	"expense_debt" to "Debito",
	"expense_deleted" to "Spese eliminate",
	"expense_depreciation" to "Ammortamento",
	"expense_gifts" to "Regali",
	"expense_insurance" to "Assicurazione",
	"expense_inventory" to "Inventario",
	"expense_legal" to "Legale",
	"expense_maintenance" to "Manutenzione",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Pasti",
	"expense_office_supplies" to "Forniture per ufficio",
	"expense_rent" to "Affitto",
	"expense_salaries" to "Stipendi",
	"expense_shopping" to "Shopping",
	"expense_software" to "Software",
	"expense_status_available" to "DISPONIBILE",
	"expense_status_processing" to "Elaborazione",
	"expense_tax" to "Imposta",
	"expense_training" to "formazione",
	"expense_transport" to "Trasporto",
	"expense_travel" to "Viaggi",
	"expense_utility" to "Utilità",
	"expenses" to "Spese",
	"feedback_option" to "Feedback",
	"filter" to "Filtro",
	"first_name_hint" to "Nome",
	"forgot_password" to "Ripristina la password",
	"forgot_password_label" to "Password dimenticata",
	"from" to "da",
	"general_error" to "Si è verificato un errore, riprovare più tardi.",
	"general_ledger" to "libro mastro generale",
	"go_back_label" to "Torna indietro",
	"group_by" to "Gruppo da:",
	"image_view" to "Immagine",
	"install" to "Installa",
	"invalid_credentials_error" to "Credenziali non valide.",
	"invalid_email_error" to "Email non valida.",
	"invalid_user_error" to "Credenziali utente non valide.",
	"invoice_deleted_message" to "Fattura cancellata",
	"invoice_menu" to "Menu Fattura",
	"invoice_number" to "Fattura #",
	"invoices" to "fatture",
	"items_option" to "articoli",
	"keep" to "Tenere",
	"language_selector" to Formattable { (selected) -> "Lingua ${selected}" },
	"last_name_hint" to "Nome e cognome",
	"ledger" to "libro contabile",
	"legal" to "Legale",
	"legal_error" to "Deve accettare i Termini di servizio e l'Informativa sulla privacy",
	"liabilities" to "passività",
	"loading" to "Caricamento",
	"login_required_error" to "Richiesto il login.",
	"logo_view" to "Logo",
	"logout" to "Logout",
	"logout_prompt" to "I tuoi dati non sono sincronizzati con il cloud. Cancellare fatture, preventivi, clienti e altre informazioni al momento del logout?",
	"main_screen" to "Schermo principale",
	"mark_paid" to "Segna come pagato",
	"message" to "Messaggio",
	"missing_sender_msg" to "Un documento senza informazioni sul mittente potrebbe non essere valido.",
	"missing_sender_title" to "Informazioni sul mittente mancanti",
	"month_april" to "Aprile",
	"month_april_short" to "Aprile",
	"month_august" to "Agosto",
	"month_august_short" to "Ago.",
	"month_december" to "Dicembre",
	"month_december_short" to "Dic.",
	"month_february" to "Febbraio",
	"month_february_short" to "Feb.",
	"month_january" to "Gennaio",
	"month_january_short" to "Gen.",
	"month_july" to "Luglio",
	"month_july_short" to "Luglio",
	"month_june" to "Giugno",
	"month_june_short" to "Giugno",
	"month_march" to "Marzo",
	"month_march_short" to "Marzo",
	"month_may" to "Maggio",
	"month_may_short" to "Maggio",
	"month_november" to "Novembre",
	"month_november_short" to "Nov.",
	"month_october" to "Ottobre",
	"month_october_short" to "Ott.",
	"month_september" to "Settembre",
	"month_september_short" to "Sett.",
	"multifactor_error" to "Impossibile verificare.",
	"new" to "Nuovo",
	"news" to "Notizie",
	"next" to "Prossimo",
	"no" to "no",
	"no_data_available" to "Nessun dato disponibile",
	"no_search_results" to "Spiacenti, non abbiamo trovato alcun risultato.",
	"none" to "Nessuno",
	"note_fields" to "Note",
	"note_hint" to "Nota",
	"notifications_option" to "Notifiche",
	"ok" to "OK",
	"onboarding" to "imbarco",
	"onboarding_aboutus_message" to "Le parti essenziali di DingVoice sono gratuite per sempre. La nostra missione è aiutarti a far crescere la tua attività, aiutaci a migliorare condividendo il tuo feedback.",
	"onboarding_aboutus_title" to "Siamo qui per aiutare",
	"onboarding_paid_1" to "Accedi a modelli dall&#39;aspetto professionale",
	"onboarding_paid_2" to "Dai uno sguardo più approfondito ai tuoi dati finanziari",
	"onboarding_paid_3" to "Risolvi rapidamente i problemi",
	"onboarding_paid_4" to "Mantieni i tuoi documenti al sicuro",
	"onboarding_paid_5" to "Rimani aggiornato sulle modifiche allo stato delle fatture",
	"onboarding_paid_title" to "Cresci ancora più velocemente con...",
	"onboarding_paywall_message" to "Goditi tutte le funzionalità premium ora al miglior prezzo.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Solo ${currencySymbol}${introAmount} per ${introDuration} ${introPeriod} e poi ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Solo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratuito per ${trialDuration} ${trialPeriod} e poi solo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratuito per ${trialDuration} ${trialPeriod}, poi ${currencySymbol}${introductoryAmount} per ${introductoryDuration} ${introPeriod} e poi solo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Rinnovabile automaticamente. Annullamento in qualsiasi momento.",
	"onboarding_paywall_restore" to "Ripristinare l'acquisto",
	"onboarding_paywall_restore_failure" to "Il ripristino degli acquisti non è riuscito, riprovare più tardi o contattare l'assistenza.",
	"onboarding_paywall_restore_success" to "Ripristino riuscito!",
	"onboarding_paywall_title" to "Raggiungete la grandezza!",
	"onboarding_welcome_message" to "Un set di strumenti completo per gestire fatture e preventivi.",
	"onboarding_welcome_title" to "Benvenuti a DingVoice",
	"other_state" to "Altro",
	"overdue_state" to "Scaduto",
	"paid_state" to "Pagato",
	"password" to "Password",
	"password_blank_error" to "Password richiesta.",
	"password_mismatch_error" to "Le password non corrispondono.",
	"pay" to "paga",
	"payment_instructions_label" to "Istruzioni per il pagamento",
	"payment_reminders" to "Promemoria di pagamento",
	"phone_number_fields" to "Numero di telefono",
	"phone_number_hint" to "Numero di telefono",
	"postcode_hint" to "Codice postale",
	"preview" to "Anteprima",
	"preview_menu_hint" to "Menu di anteprima",
	"price" to "Prezzo",
	"privacy_policy" to "Politica sulla privacy",
	"profile" to "Profilo",
	"profitable_client" to "Il tuo cliente più redditizio è...",
	"profits_report" to "Profitti",
	"promo_content" to "Contenuto promozionale",
	"quantity_hint" to "Quantità",
	"quantity_label_hint" to "Quantità Etichetta",
	"query_info" to "Query permette di scrivere calcoli personalizzati facendo riferimento sia agli articoli che ad altre regole fiscali come input. Aggiungi il simbolo @ e la descrizione dell'articolo o dell'imposta per collegare un'altra voce +, -, *, /, % - operatori matematici",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} è stato ricevuto dal vostro cliente." },
	"received_state" to "Ricevuto",
	"registration_code_fields" to "Codice di Registrazione",
	"reminder" to "Promemoria",
	"reports" to "Rapporti",
	"reports_not_available" to "Non ci sono rapporti disponibili, torna più tardi",
	"reveal_password" to "Rivela la password",
	"revenue" to "ricavi",
	"sales_tax" to "IVA",
	"sales_tax_number" to "Numero di partita IVA",
	"save" to "Salva",
	"scan_document" to "Scansione del documento",
	"search" to "Cerca",
	"select" to "Selezionare",
	"select_icon" to "Selezionare l'icona",
	"select_signature" to "Seleziona la firma",
	"select_template" to "Seleziona il modello",
	"send" to "Invia",
	"sent_state" to "Inviato",
	"setup_sender" to "Imposta mittente",
	"share_option" to "Condividi",
	"ship_to" to "Spedire a",
	"sign_in" to "Accedi",
	"sign_in_apple" to "Accedi con Apple",
	"sign_in_email" to "Accedi con l'email",
	"sign_in_facebook" to "Accedi con Facebook",
	"sign_in_google" to "Accedi con Google",
	"sign_up" to "Iscriviti",
	"sign_up_label" to "Non hai un account?",
	"sign_up_link_label" to "Iscriviti!",
	"signatures_option" to "Firme",
	"skip" to "Salta",
	"sold_items_report" to "Articoli Venduti",
	"sort_by" to "Ordina per:",
	"status_update" to "Aggiornamento sullo stato del documento",
	"sub_annual" to "Annuale",
	"sub_annual_price" to Formattable { (price) -> "${price}/anno" },
	"sub_backups" to "Backup nel cloud",
	"sub_insights" to "Approfondimenti",
	"sub_month" to "Mensile",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mese" },
	"sub_notifications" to "Notifiche",
	"sub_popup_message" to "Gli abbonamenti sono in arrivo. Controllate qui tra un po'.",
	"sub_support" to "Assistenza",
	"sub_templates" to "Modelli",
	"subscribe" to "Iscriviti",
	"subscription_header" to "Fai crescere il tuo business con:",
	"subscription_option" to "Abbonamento",
	"subscriptions_load_fail" to "Siamo spiacenti, ma in questo momento non siamo in grado di recuperare i vostri abbonamenti. Riprovi più tardi o contatti il nostro team di assistenza per ricevere assistenza.",
	"subtotal" to Formattable { (sum) -> "Subtotale: ${sum}" },
	"subtotal_label" to "Subtotale",
	"sum" to "Somma",
	"support_option" to "Supporto",
	"switch_reports" to "Cambia Rapporto",
	"tax" to "TAX",
	"tax_deleted_message" to "Tassa cancellata",
	"tax_description_warning" to "Se usato, questo appare su una fattura",
	"tax_input_flat" to "Flat",
	"tax_input_percent" to "Percentuale",
	"tax_number_fields" to "Codice fiscale",
	"tax_number_hint" to "Codice fiscale",
	"tax_query_info_description" to "Informazioni sulla query",
	"tax_report" to "Tassa",
	"tax_rules_option" to "Regole fiscali",
	"taxed_clients_report" to "Clienti tassati",
	"template" to "Template",
	"template_selector_info" to "Scorri per cambiare il modello predefinito",
	"template_unlock_promo" to "Accesso a più modelli con Premium",
	"templates_option" to "Modelli",
	"terms_of_service" to "Termini di servizio",
	"thank_you" to "Grazie",
	"to" to "a",
	"total" to Formattable { (sum) -> "Totale: ${sum}" },
	"total_label" to "TOTALE",
	"undo_action" to "Undo",
	"unit" to "Unità",
	"unit_cost_hint" to "Costo unitario",
	"unknown_client" to "Cliente sconosciuto",
	"unlock" to "Sblocca",
	"unnamed_item" to "Articolo senza nome",
	"upgrade" to "Aggiornare",
	"user_collision_error" to "L'account esiste già.",
	"valid_until" to "Valido fino a",
	"warning" to "Attenzione!",
	"yes" to "sì",
    )
    
}