// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Bg  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Приемете ${termsOfService} и ${privacyPolicy}" }
override val account: String = "сметка"
override val accounting: String = "счетоводство"
override val accruals: String = "начисления"
override val action_code_error: String = "Невалиден код."
override val add_client_action: String = "Добавяне на клиент"
override val add_customer: String = "Добавяне на клиент"
override val add_expense: String = "Добавяне на разходи"
override val add_fields_label: String = "Добавете:"
override val add_invoice_action: String = "Създаване на"
override val add_item_action: String = "Добавяне на елемент"
override val add_item_button: String = "Добавяне на елемент"
override val add_signature_action: String = "Добавяне на подпис"
override val add_tax_action: String = "Добавяне на данък"
override val address_fields: String = "Адрес"
override val address_line_hint: String = "Адресна линия"
override val advanced: String = "Разширен"
override val agree_marketing: String = "Съгласен съм да получавам съобщения за директен маркетинг"
override val all_notifications: String = "Всички известия"
override val amortization: String = "амортизация"
override val amount: String = "Сума"
override val asset: String = "актив"
override val attach_file: String = "Прикачете файл"
override val attachment: String = "Приложение"
override val auth_error: String = "Действието е неуспешно, опитайте отново."
override val available_for_paid: String = "Синхронизацията в облак е налична само при платен абонамент."
override val backups_option: String = "Резервни копия"
override val balance: String = "баланс"
override val bank_account: String = "Банкова Сметка"
override val bill_number: String = "Номер на сметката"
override val cancel: String = "Отмяна на"
override val cancel_trial: String = "Отказ по всяко време"
override val capital: String = "капитал"
override val cash: String = "б брой"
override val categories: String = "Категории"
override val category: String = "Категория"
override val category_add: String = "Добавяне на категория"
override val category_delete: String = "Изтриване на категория"
override val category_name: String = "Име на категорията"
override val change_language: Formattable = Formattable { (language) -> "Език на фактурата: ${language}" }
override val change_language_label: String = "Език на фактурата"
override val change_template: String = "Промяна на шаблона"
override val city_hint: String = "Град"
override val clear: String = "Clear"
override val client: String = "Клиент"
override val client_actions: String = "Действия на клиента"
override val client_deleted_message: String = "Изтрит клиент"
override val clients_error_required_fields: String = "Изисква се поне една стойност."
override val clients_option: String = "Клиенти"
override val close: String = "Затвори"
override val cloud_sync: String = "Синхронизиране в облак"
override val coming_soon: String = "Очаквайте скоро!"
override val company_logo: String = "Лого на компанията"
override val company_name_hint: String = "Компания"
override val confirm_password_label: String = "Потвърждаване на паролата"
override val confirmation_title: String = "Сигурни ли сте?"
override val convert_to_invoice: String = "Преобразуване във фактура"
override val country_hint: String = "Държава"
override val create_client: String = "Създаване на клиент"
override val create_company: String = "Създаване на компания"
override val create_document: String = "Създаване на документ"
override val create_estimate: String = "Прогноза"
override val create_invoice: String = "Фактура"
override val create_item: String = "Създаване на елемент"
override val create_new_client: String = "Създаване на нов клиент"
override val create_new_item: String = "Създаване на нов елемент"
override val create_new_tax: String = "Създаване на нов данък"
override val create_signature: String = "Подпис"
override val created: String = "Създаден"
override val credit: String = "кредит"
override val customer: String = "Клиент"
override val date: String = "Дата"
override val debit: String = "дебит"
override val debt: String = "дълг"
override val default_currency: Formattable = Formattable { (currency) -> "Валута по подразбиране: ${currency}" }
override val default_currency_label: String = "Валута по подразбиране"
override val delete: String = "Изтриване на"
override val delete_account: String = "Изтриване на Aкаунт"
override val delete_confirmation_message: String = "Всички ваши данни ще бъдат загубени."
override val delete_item_title: String = "Да изтриете елемента?"
override val delete_signature: String = "Изтриване на подписа"
override val depreciation: String = "амортизация"
override val description: String = "Описание"
override val description_hint: String = "Описание"
override val detailed_report: String = "Подробен доклад"
override val disabled: String = "Инвалиди"
override val discount: String = "Отстъпка"
override val discount_hint: String = "Отстъпка"
override val display_af: String = "Африканс"
override val display_am: String = "Амхарски"
override val display_ar: String = "Арабски"
override val display_as: String = "Асамски"
override val display_az: String = "Азербайджански"
override val display_be: String = "Беларуски"
override val display_bg: String = "Български"
override val display_bn: String = "Бенгалски"
override val display_bs: String = "Босненски"
override val display_ca: String = "Каталонски"
override val display_cs: String = "Чешки"
override val display_da: String = "Датски"
override val display_de: String = "Немски"
override val display_el: String = "Гръцки"
override val display_en: String = "Английски"
override val display_es: String = "Испански"
override val display_es_MX: String = "Испански (Мексико)"
override val display_et: String = "Естонски"
override val display_eu: String = "Баски"
override val display_fa: String = "Персийски"
override val display_fi: String = "Финландски"
override val display_fil: String = "Филипински"
override val display_fr: String = "Френски"
override val display_fr_CA: String = "Канадски френски"
override val display_gl: String = "Галисийски"
override val display_gu: String = "Гуджарати"
override val display_he: String = "Иврит"
override val display_hi: String = "Хинди"
override val display_hr: String = "Хърватски"
override val display_hu: String = "Унгарски"
override val display_hy: String = "Арменски"
override val display_id: String = "Индонезийски"
override val display_is: String = "Исландски"
override val display_it: String = "Италиански"
override val display_ja: String = "Японски"
override val display_ka: String = "Грузински"
override val display_kk: String = "Казахски"
override val display_km: String = "Централен кхмер"
override val display_kn: String = "Каннада"
override val display_ko: String = "Корейски"
override val display_ky: String = "Киргиз"
override val display_lo: String = "Лао"
override val display_lt: String = "Литовски"
override val display_lv: String = "Латвийски"
override val display_mk: String = "Македонски"
override val display_ml: String = "Малаялам"
override val display_mn: String = "Монголски"
override val display_mr: String = "Маратхи"
override val display_ms: String = "Малайски"
override val display_my: String = "Бирмански"
override val display_nb: String = "Норвежки Bokmål"
override val display_ne: String = "Непалски"
override val display_nl: String = "Холандски"
override val display_or: String = "Ория"
override val display_pa: String = "Пенджаби"
override val display_pl: String = "Полски"
override val display_pt: String = "Португалски"
override val display_pt_BR: String = "Португалски (Бразилия)"
override val display_pt_PT: String = "Европейски португалски"
override val display_ro: String = "Румънски"
override val display_ru: String = "Руски"
override val display_si: String = "Синхала"
override val display_sk: String = "Словашки"
override val display_sl: String = "Словенски"
override val display_sq: String = "Албански"
override val display_sr: String = "Сръбски"
override val display_sv: String = "Шведски"
override val display_sw: String = "Суахили"
override val display_ta: String = "Тамил"
override val display_te: String = "Телугу"
override val display_th: String = "Тайландски"
override val display_tr: String = "Турски"
override val display_uk: String = "Украински"
override val display_ur: String = "Урду"
override val display_uz: String = "Узбекистан"
override val display_vi: String = "Виетнамски"
override val display_zh_Hant_TW: String = "Китайски (Тайван, Традиционен)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Оценка"
override val document_invoice: String = "Фактура"
override val document_quote: String = "Оферта"
override val done: String = "Готово"
override val download_app: String = "Изтегляне на приложението"
override val download_invoice: String = "Изтеглете Фактура"
override val download_pdf: String = "Изтегляне на PDF"
override val draw_signature: String = "Нарисувайте подпис"
override val due: Formattable = Formattable { (date) -> "Срок за плащане ${date}" }
override val due_date: String = "Срок за изпълнение"
override val due_day: String = "в един ден"
override val due_label: String = "Задължение"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} е дължимо ${dueWhen}!" }
override val due_today: String = "днес"
override val due_week: String = "за една седмица"
override val duplicate: String = "Дублиране"
override val duration_day: String = "ден"
override val duration_month: String = "Месец"
override val duration_week: String = "седмица"
override val duration_year: String = "година"
override val email: String = "Имейл"
override val email_blank_error: String = "Изисква се имейл."
override val email_error: String = "Невалиден имейл адрес."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Благодарим ви за вашия бизнес. За да видите пълния ${documentType}, кликнете върху бутона в края на това писмо. Имайте предвид, че е валиден до ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Получихте нов ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ПРЕГЛЕД НА ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Уважаеми ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Благодарим ви за вашия бизнес. За да видите пълния ${documentType}, кликнете върху бутона в края на това писмо. Моля, платете остатъка до ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Получихте нов ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Изпратен е имейл за възстановяване на паролата, моля, проверете входящата си поща."
override val empty_clients_list_message: String = "Нямате клиенти, които да следите. Добавете нов клиент."
override val empty_estimate_list_message: String = "Създаване на първа оценка"
override val empty_expenses: String = "Нямате разходи, които да следите. Добавете нов разход."
override val empty_invoice_list_message: String = "Създайте първата си фактура"
override val empty_items_list_message: String = "Нямате елементи, добавете нов елемент!"
override val empty_signatures_list_message: String = "Не са намерени подписи. Създайте такъв!"
override val empty_taxes_list_message: String = "Нямате данъчни правила. Добавете ново данъчно правило."
override val empty_templates_list_message: String = "Шаблоните не са налични."
override val enabled: String = "Разрешено"
override val entry: String = "влизане"
override val equity: String = "капитал"
override val estimate_number: String = "Оценка #"
override val estimates: String = "Оценки"
override val estimates_report: String = "Оценки"
override val expense_bank_fees: String = "Банкови такси"
override val expense_benefits: String = "Ползи"
override val expense_communication: String = "Комуникация"
override val expense_debt: String = "Дълг"
override val expense_deleted: String = "Изтрити разходи"
override val expense_depreciation: String = "Амортизация"
override val expense_gifts: String = "Подаръци"
override val expense_insurance: String = "застраховка"
override val expense_inventory: String = "Инвентарна книга"
override val expense_legal: String = "Юридически"
override val expense_maintenance: String = "Поддръжка"
override val expense_marketing: String = "маркетинг"
override val expense_meals: String = "Хранене"
override val expense_office_supplies: String = "Офис консумативи"
override val expense_rent: String = "Наем"
override val expense_salaries: String = "Заплати"
override val expense_shopping: String = "Пазаруване"
override val expense_software: String = "Софтуер"
override val expense_status_available: String = "Наличен"
override val expense_status_processing: String = "Обработка"
override val expense_tax: String = "Данък"
override val expense_training: String = "обучение"
override val expense_transport: String = "Транспорт"
override val expense_travel: String = "Пътуване"
override val expense_utility: String = "Полезност"
override val expenses: String = "Разходи"
override val feedback_option: String = "Обратна връзка"
override val filter: String = "Филтър"
override val first_name_hint: String = "Първо име"
override val forgot_password: String = "Възстановяване на паролата"
override val forgot_password_label: String = "Забравена парола"
override val from: String = "От"
override val general_error: String = "Възникнала е грешка, моля, опитайте отново по-късно."
override val general_ledger: String = "главна счетоводна книга"
override val go_back_label: String = "Върнете се назад"
override val group_by: String = "Група от:"
override val image_view: String = "Изображение"
override val install: String = "Инсталиране"
override val invalid_credentials_error: String = "Невалидни пълномощия."
override val invalid_email_error: String = "Невалиден имейл."
override val invalid_user_error: String = "Невалидни потребителски пълномощия."
override val invoice_deleted_message: String = "Изтрита фактура"
override val invoice_menu: String = "Меню Фактура"
override val invoice_number: String = "Фактура #"
override val invoices: String = "Фактури"
override val items_option: String = "Елементи"
override val keep: String = "Запазете"
override val language_selector: Formattable = Formattable { (избрано) -> "Език ${избрано}" }
override val last_name_hint: String = "Фамилия"
override val ledger: String = "счетоводна книга"
override val legal: String = "Юридически"
override val legal_error: String = "Трябва да приемете Условията за ползване и Политиката за поверителност"
override val liabilities: String = "пасиви"
override val loading: String = "Зареждане на"
override val login_required_error: String = "Изисква се вход."
override val logo_view: String = "Лого"
override val logout: String = "Излизане от системата"
override val logout_prompt: String = "Данните ви не се синхронизират с облака. Изтриване на фактури, оценки, клиенти и друга информация при излизане от системата?"
override val main_screen: String = "Основен екран"
override val mark_paid: String = "Маркирайте като платено"
override val message: String = "Съобщение"
override val missing_sender_msg: String = "Документ без информация за изпращача може да е невалиден."
override val missing_sender_title: String = "Липсваща информация за изпращача"
override val month_april: String = "април"
override val month_april_short: String = "Април"
override val month_august: String = "Август"
override val month_august_short: String = "Авг."
override val month_december: String = "декември"
override val month_december_short: String = "Дек."
override val month_february: String = "февруари"
override val month_february_short: String = "Фев."
override val month_january: String = "януари"
override val month_january_short: String = "Ян."
override val month_july: String = "Юли"
override val month_july_short: String = "Юли"
override val month_june: String = "юни"
override val month_june_short: String = "Юни"
override val month_march: String = "Март"
override val month_march_short: String = "Март"
override val month_may: String = "Май"
override val month_may_short: String = "Май"
override val month_november: String = "ноември"
override val month_november_short: String = "Ное."
override val month_october: String = "Октомври"
override val month_october_short: String = "Окт."
override val month_september: String = "септември"
override val month_september_short: String = "Септ."
override val multifactor_error: String = "Не успя да провери."
override val new: String = "Нов"
override val news: String = "Новини"
override val next: String = "Следваща"
override val no: String = "не"
override val no_data_available: String = "Няма налични данни"
override val no_search_results: String = "Съжаляваме, но не успяхме да намерим никакви резултати."
override val none: String = "Няма"
override val note_fields: String = "Бележки"
override val note_hint: String = "Забележка"
override val notifications_option: String = "Известия"
override val ok: String = "ОК"
override val onboarding: String = "Въвеждане в експлоатация"
override val onboarding_aboutus_message: String = "Основните части на DingVoice са завинаги безплатни. Нашата мисия е да ви помогнем да развиете бизнеса си, помогнете ни да се усъвършенстваме, като споделите обратната си връзка."
override val onboarding_aboutus_title: String = "Ние сме тук, за да ви помогнем"
override val onboarding_paid_1: String = "Достъп до професионално изглеждащи шаблони"
override val onboarding_paid_2: String = "Направете по-задълбочен преглед на финансите си"
override val onboarding_paid_3: String = "Решаване на проблемите бързо"
override val onboarding_paid_4: String = "Запазете документите си на сигурно място"
override val onboarding_paid_5: String = "Бъдете в крак с промените в статуса на фактурите"
override val onboarding_paid_title: String = "Растете още по-бързо с..."
override val onboarding_paywall_message: String = "Насладете се на всички първокласни функции сега на най-добрата цена."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Просто ${currencySymbol}${introAmount} за ${introDuration} ${introPeriod} и след това ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, продължителност) -> "Само ${currencySymbol}${amount} / ${продължителност}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Безплатно за ${trialDuration} ${trialPeriod} и след това само ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Безплатно за ${trialDuration} ${trialPeriod} след това ${currencySymbol}${introductoryAmount} за ${introductoryDuration} ${introPeriod} и след това само ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Автоматично възобновяеми. Отказ по всяко време."
override val onboarding_paywall_restore: String = "Възстановяване на покупката"
override val onboarding_paywall_restore_failure: String = "Възстановяването на покупките е неуспешно, опитайте отново по-късно или се свържете с поддръжката."
override val onboarding_paywall_restore_success: String = "Възстановяването е успешно!"
override val onboarding_paywall_title: String = "Постигнете величие!"
override val onboarding_welcome_message: String = "Пълен набор от инструменти за управление на фактури и оценки."
override val onboarding_welcome_title: String = "Добре дошли в DingVoice"
override val other_state: String = "Други"
override val overdue_state: String = "Просрочени"
override val paid_state: String = "Платено"
override val password: String = "Парола"
override val password_blank_error: String = "Изисква се парола."
override val password_mismatch_error: String = "Паролите не съвпадат."
override val pay: String = "плати"
override val payment_instructions_label: String = "Инструкции за плащане"
override val payment_reminders: String = "Напомняния за плащане"
override val phone_number_fields: String = "Телефонен номер"
override val phone_number_hint: String = "Телефонен номер"
override val postcode_hint: String = "Пощенски код"
override val preview: String = "Преглед"
override val preview_menu_hint: String = "Меню за преглед"
override val price: String = "Цена"
override val privacy_policy: String = "Политика за поверителност"
override val profile: String = "Профил"
override val profitable_client: String = "Вашият най-печеливш клиент е..."
override val profits_report: String = "Печалби"
override val promo_content: String = "Промоционално съдържание"
override val quantity_hint: String = "Количество"
override val quantity_label_hint: String = "Етикет за количество"
override val query_info: String = "Запитването ви позволява да записвате потребителски изчисления, като използвате като входни данни както елементи, така и други данъчни правила. Добавете символа @ и описание на елемента или данъка, за да се свържете с друг запис +, -, *, /, % - математически оператори"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} е получен от вашия клиент." }
override val received_state: String = "Получени"
override val registration_code_fields: String = "Регистрационен код"
override val reminder: String = "Напомняне"
override val reports: String = "Доклади"
override val reports_not_available: String = "Няма налични доклади, върни се по-късно."
override val reveal_password: String = "Разкриване на паролата"
override val revenue: String = "приходи"
override val sales_tax: String = "ДДС"
override val sales_tax_number: String = "Номер по ДДС"
override val save: String = "Запазете"
override val scan_document: String = "Сканиране на документ"
override val search: String = "Търсене"
override val select: String = "Изберете"
override val select_icon: String = "Изберете икона"
override val select_signature: String = "Изберете подпис"
override val select_template: String = "Изберете шаблон"
override val send: String = "Изпрати"
override val sent_state: String = "Изпратено"
override val setup_sender: String = "Настройте подателя"
override val share_option: String = "Споделете"
override val ship_to: String = "Изпращане до"
override val sign_in: String = "Влезте"
override val sign_in_apple: String = "Влезте с Apple"
override val sign_in_email: String = "Влезте с имейл"
override val sign_in_facebook: String = "Влезте във Facebook"
override val sign_in_google: String = "Влезте в Google"
override val sign_up: String = "Регистрирайте се"
override val sign_up_label: String = "Нямате акаунт?"
override val sign_up_link_label: String = "Регистрирайте се!"
override val signatures_option: String = "Подписи"
override val skip: String = "Пропуснете"
override val sold_items_report: String = "Продадени Елементи"
override val sort_by: String = "Сортиране по:"
override val status_update: String = "Актуализация на състоянието на документа"
override val sub_annual: String = "Годишен"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/година" }
override val sub_backups: String = "Клауд архиви"
override val sub_insights: String = "Прозрения"
override val sub_month: String = "Месечно"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/месец" }
override val sub_notifications: String = "Нотификации"
override val sub_popup_message: String = "Абонаментите идват скоро. Проверете отново тук след малко."
override val sub_support: String = "Поддръжка"
override val sub_templates: String = "Шаблони"
override val subscribe: String = "Абонирайте се за"
override val subscription_header: String = "Развийте бизнеса си с:"
override val subscription_option: String = "Абонамент"
override val subscriptions_load_fail: String = "Съжаляваме, но в момента не можем да изтеглим абонаментите ви. Моля, опитайте отново по-късно или се свържете с нашия екип за поддръжка за съдействие."
override val subtotal: Formattable = Formattable { (sum) -> "Междинна сума: ${sum}" }
override val subtotal_label: String = "Междинна сума"
override val sum: String = "Сума"
override val support_option: String = "Подкрепа"
override val switch_reports: String = "Превключване на Доклад"
override val tax: String = "Данък"
override val tax_deleted_message: String = "Изтрити данъци"
override val tax_description_warning: String = "Ако се използва, това се отразява във фактурата."
override val tax_input_flat: String = "Плосък"
override val tax_input_percent: String = "Процент"
override val tax_number_fields: String = "Данъчен номер"
override val tax_number_hint: String = "Данъчен номер"
override val tax_query_info_description: String = "Информация за заявката"
override val tax_report: String = "Данък"
override val tax_rules_option: String = "Данъчни правила"
override val taxed_clients_report: String = "Данъчно задължени клиенти"
override val template: String = "Шаблон"
override val template_selector_info: String = "Плъзнете пръст, за да промените шаблона по подразбиране"
override val template_unlock_promo: String = "Достъп до повече шаблони с Premium"
override val templates_option: String = "Шаблони"
override val terms_of_service: String = "Условия за ползване"
override val thank_you: String = "Благодаря ви"
override val to: String = "За"
override val total: Formattable = Formattable { (sum) -> "Общо: ${sum}" }
override val total_label: String = "Общо"
override val undo_action: String = "Отмяна на"
override val unit: String = "Единица"
override val unit_cost_hint: String = "Разходи за единица"
override val unknown_client: String = "Неизвестен Kлиент"
override val unlock: String = "Отключване на"
override val unnamed_item: String = "Елемент без име"
override val upgrade: String = "Надграждане"
override val user_collision_error: String = "Акаунтът вече съществува."
override val valid_until: String = "Валидно до"
override val warning: String = "Предупреждение!"
override val yes: String = "да"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Приемете ${termsOfService} и ${privacyPolicy}" },
	"account" to "сметка",
	"accounting" to "счетоводство",
	"accruals" to "начисления",
	"action_code_error" to "Невалиден код.",
	"add_client_action" to "Добавяне на клиент",
	"add_customer" to "Добавяне на клиент",
	"add_expense" to "Добавяне на разходи",
	"add_fields_label" to "Добавете:",
	"add_invoice_action" to "Създаване на",
	"add_item_action" to "Добавяне на елемент",
	"add_item_button" to "Добавяне на елемент",
	"add_signature_action" to "Добавяне на подпис",
	"add_tax_action" to "Добавяне на данък",
	"address_fields" to "Адрес",
	"address_line_hint" to "Адресна линия",
	"advanced" to "Разширен",
	"agree_marketing" to "Съгласен съм да получавам съобщения за директен маркетинг",
	"all_notifications" to "Всички известия",
	"amortization" to "амортизация",
	"amount" to "Сума",
	"asset" to "актив",
	"attach_file" to "Прикачете файл",
	"attachment" to "Приложение",
	"auth_error" to "Действието е неуспешно, опитайте отново.",
	"available_for_paid" to "Синхронизацията в облак е налична само при платен абонамент.",
	"backups_option" to "Резервни копия",
	"balance" to "баланс",
	"bank_account" to "Банкова Сметка",
	"bill_number" to "Номер на сметката",
	"cancel" to "Отмяна на",
	"cancel_trial" to "Отказ по всяко време",
	"capital" to "капитал",
	"cash" to "б брой",
	"categories" to "Категории",
	"category" to "Категория",
	"category_add" to "Добавяне на категория",
	"category_delete" to "Изтриване на категория",
	"category_name" to "Име на категорията",
	"change_language" to Formattable { (language) -> "Език на фактурата: ${language}" },
	"change_language_label" to "Език на фактурата",
	"change_template" to "Промяна на шаблона",
	"city_hint" to "Град",
	"clear" to "Clear",
	"client" to "Клиент",
	"client_actions" to "Действия на клиента",
	"client_deleted_message" to "Изтрит клиент",
	"clients_error_required_fields" to "Изисква се поне една стойност.",
	"clients_option" to "Клиенти",
	"close" to "Затвори",
	"cloud_sync" to "Синхронизиране в облак",
	"coming_soon" to "Очаквайте скоро!",
	"company_logo" to "Лого на компанията",
	"company_name_hint" to "Компания",
	"confirm_password_label" to "Потвърждаване на паролата",
	"confirmation_title" to "Сигурни ли сте?",
	"convert_to_invoice" to "Преобразуване във фактура",
	"country_hint" to "Държава",
	"create_client" to "Създаване на клиент",
	"create_company" to "Създаване на компания",
	"create_document" to "Създаване на документ",
	"create_estimate" to "Прогноза",
	"create_invoice" to "Фактура",
	"create_item" to "Създаване на елемент",
	"create_new_client" to "Създаване на нов клиент",
	"create_new_item" to "Създаване на нов елемент",
	"create_new_tax" to "Създаване на нов данък",
	"create_signature" to "Подпис",
	"created" to "Създаден",
	"credit" to "кредит",
	"customer" to "Клиент",
	"date" to "Дата",
	"debit" to "дебит",
	"debt" to "дълг",
	"default_currency" to Formattable { (currency) -> "Валута по подразбиране: ${currency}" },
	"default_currency_label" to "Валута по подразбиране",
	"delete" to "Изтриване на",
	"delete_account" to "Изтриване на Aкаунт",
	"delete_confirmation_message" to "Всички ваши данни ще бъдат загубени.",
	"delete_item_title" to "Да изтриете елемента?",
	"delete_signature" to "Изтриване на подписа",
	"depreciation" to "амортизация",
	"description" to "Описание",
	"description_hint" to "Описание",
	"detailed_report" to "Подробен доклад",
	"disabled" to "Инвалиди",
	"discount" to "Отстъпка",
	"discount_hint" to "Отстъпка",
	"display_af" to "Африканс",
	"display_am" to "Амхарски",
	"display_ar" to "Арабски",
	"display_as" to "Асамски",
	"display_az" to "Азербайджански",
	"display_be" to "Беларуски",
	"display_bg" to "Български",
	"display_bn" to "Бенгалски",
	"display_bs" to "Босненски",
	"display_ca" to "Каталонски",
	"display_cs" to "Чешки",
	"display_da" to "Датски",
	"display_de" to "Немски",
	"display_el" to "Гръцки",
	"display_en" to "Английски",
	"display_es" to "Испански",
	"display_es_MX" to "Испански (Мексико)",
	"display_et" to "Естонски",
	"display_eu" to "Баски",
	"display_fa" to "Персийски",
	"display_fi" to "Финландски",
	"display_fil" to "Филипински",
	"display_fr" to "Френски",
	"display_fr_CA" to "Канадски френски",
	"display_gl" to "Галисийски",
	"display_gu" to "Гуджарати",
	"display_he" to "Иврит",
	"display_hi" to "Хинди",
	"display_hr" to "Хърватски",
	"display_hu" to "Унгарски",
	"display_hy" to "Арменски",
	"display_id" to "Индонезийски",
	"display_is" to "Исландски",
	"display_it" to "Италиански",
	"display_ja" to "Японски",
	"display_ka" to "Грузински",
	"display_kk" to "Казахски",
	"display_km" to "Централен кхмер",
	"display_kn" to "Каннада",
	"display_ko" to "Корейски",
	"display_ky" to "Киргиз",
	"display_lo" to "Лао",
	"display_lt" to "Литовски",
	"display_lv" to "Латвийски",
	"display_mk" to "Македонски",
	"display_ml" to "Малаялам",
	"display_mn" to "Монголски",
	"display_mr" to "Маратхи",
	"display_ms" to "Малайски",
	"display_my" to "Бирмански",
	"display_nb" to "Норвежки Bokmål",
	"display_ne" to "Непалски",
	"display_nl" to "Холандски",
	"display_or" to "Ория",
	"display_pa" to "Пенджаби",
	"display_pl" to "Полски",
	"display_pt" to "Португалски",
	"display_pt_BR" to "Португалски (Бразилия)",
	"display_pt_PT" to "Европейски португалски",
	"display_ro" to "Румънски",
	"display_ru" to "Руски",
	"display_si" to "Синхала",
	"display_sk" to "Словашки",
	"display_sl" to "Словенски",
	"display_sq" to "Албански",
	"display_sr" to "Сръбски",
	"display_sv" to "Шведски",
	"display_sw" to "Суахили",
	"display_ta" to "Тамил",
	"display_te" to "Телугу",
	"display_th" to "Тайландски",
	"display_tr" to "Турски",
	"display_uk" to "Украински",
	"display_ur" to "Урду",
	"display_uz" to "Узбекистан",
	"display_vi" to "Виетнамски",
	"display_zh_Hant_TW" to "Китайски (Тайван, Традиционен)",
	"display_zu" to "Zulu",
	"document_estimate" to "Оценка",
	"document_invoice" to "Фактура",
	"document_quote" to "Оферта",
	"done" to "Готово",
	"download_app" to "Изтегляне на приложението",
	"download_invoice" to "Изтеглете Фактура",
	"download_pdf" to "Изтегляне на PDF",
	"draw_signature" to "Нарисувайте подпис",
	"due" to Formattable { (date) -> "Срок за плащане ${date}" },
	"due_date" to "Срок за изпълнение",
	"due_day" to "в един ден",
	"due_label" to "Задължение",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} е дължимо ${dueWhen}!" },
	"due_today" to "днес",
	"due_week" to "за една седмица",
	"duplicate" to "Дублиране",
	"duration_day" to "ден",
	"duration_month" to "Месец",
	"duration_week" to "седмица",
	"duration_year" to "година",
	"email" to "Имейл",
	"email_blank_error" to "Изисква се имейл.",
	"email_error" to "Невалиден имейл адрес.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Благодарим ви за вашия бизнес. За да видите пълния ${documentType}, кликнете върху бутона в края на това писмо. Имайте предвид, че е валиден до ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Получихте нов ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "ПРЕГЛЕД НА ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Уважаеми ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Благодарим ви за вашия бизнес. За да видите пълния ${documentType}, кликнете върху бутона в края на това писмо. Моля, платете остатъка до ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Получихте нов ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Изпратен е имейл за възстановяване на паролата, моля, проверете входящата си поща.",
	"empty_clients_list_message" to "Нямате клиенти, които да следите. Добавете нов клиент.",
	"empty_estimate_list_message" to "Създаване на първа оценка",
	"empty_expenses" to "Нямате разходи, които да следите. Добавете нов разход.",
	"empty_invoice_list_message" to "Създайте първата си фактура",
	"empty_items_list_message" to "Нямате елементи, добавете нов елемент!",
	"empty_signatures_list_message" to "Не са намерени подписи. Създайте такъв!",
	"empty_taxes_list_message" to "Нямате данъчни правила. Добавете ново данъчно правило.",
	"empty_templates_list_message" to "Шаблоните не са налични.",
	"enabled" to "Разрешено",
	"entry" to "влизане",
	"equity" to "капитал",
	"estimate_number" to "Оценка #",
	"estimates" to "Оценки",
	"estimates_report" to "Оценки",
	"expense_bank_fees" to "Банкови такси",
	"expense_benefits" to "Ползи",
	"expense_communication" to "Комуникация",
	"expense_debt" to "Дълг",
	"expense_deleted" to "Изтрити разходи",
	"expense_depreciation" to "Амортизация",
	"expense_gifts" to "Подаръци",
	"expense_insurance" to "застраховка",
	"expense_inventory" to "Инвентарна книга",
	"expense_legal" to "Юридически",
	"expense_maintenance" to "Поддръжка",
	"expense_marketing" to "маркетинг",
	"expense_meals" to "Хранене",
	"expense_office_supplies" to "Офис консумативи",
	"expense_rent" to "Наем",
	"expense_salaries" to "Заплати",
	"expense_shopping" to "Пазаруване",
	"expense_software" to "Софтуер",
	"expense_status_available" to "Наличен",
	"expense_status_processing" to "Обработка",
	"expense_tax" to "Данък",
	"expense_training" to "обучение",
	"expense_transport" to "Транспорт",
	"expense_travel" to "Пътуване",
	"expense_utility" to "Полезност",
	"expenses" to "Разходи",
	"feedback_option" to "Обратна връзка",
	"filter" to "Филтър",
	"first_name_hint" to "Първо име",
	"forgot_password" to "Възстановяване на паролата",
	"forgot_password_label" to "Забравена парола",
	"from" to "От",
	"general_error" to "Възникнала е грешка, моля, опитайте отново по-късно.",
	"general_ledger" to "главна счетоводна книга",
	"go_back_label" to "Върнете се назад",
	"group_by" to "Група от:",
	"image_view" to "Изображение",
	"install" to "Инсталиране",
	"invalid_credentials_error" to "Невалидни пълномощия.",
	"invalid_email_error" to "Невалиден имейл.",
	"invalid_user_error" to "Невалидни потребителски пълномощия.",
	"invoice_deleted_message" to "Изтрита фактура",
	"invoice_menu" to "Меню Фактура",
	"invoice_number" to "Фактура #",
	"invoices" to "Фактури",
	"items_option" to "Елементи",
	"keep" to "Запазете",
	"language_selector" to Formattable { (избрано) -> "Език ${избрано}" },
	"last_name_hint" to "Фамилия",
	"ledger" to "счетоводна книга",
	"legal" to "Юридически",
	"legal_error" to "Трябва да приемете Условията за ползване и Политиката за поверителност",
	"liabilities" to "пасиви",
	"loading" to "Зареждане на",
	"login_required_error" to "Изисква се вход.",
	"logo_view" to "Лого",
	"logout" to "Излизане от системата",
	"logout_prompt" to "Данните ви не се синхронизират с облака. Изтриване на фактури, оценки, клиенти и друга информация при излизане от системата?",
	"main_screen" to "Основен екран",
	"mark_paid" to "Маркирайте като платено",
	"message" to "Съобщение",
	"missing_sender_msg" to "Документ без информация за изпращача може да е невалиден.",
	"missing_sender_title" to "Липсваща информация за изпращача",
	"month_april" to "април",
	"month_april_short" to "Април",
	"month_august" to "Август",
	"month_august_short" to "Авг.",
	"month_december" to "декември",
	"month_december_short" to "Дек.",
	"month_february" to "февруари",
	"month_february_short" to "Фев.",
	"month_january" to "януари",
	"month_january_short" to "Ян.",
	"month_july" to "Юли",
	"month_july_short" to "Юли",
	"month_june" to "юни",
	"month_june_short" to "Юни",
	"month_march" to "Март",
	"month_march_short" to "Март",
	"month_may" to "Май",
	"month_may_short" to "Май",
	"month_november" to "ноември",
	"month_november_short" to "Ное.",
	"month_october" to "Октомври",
	"month_october_short" to "Окт.",
	"month_september" to "септември",
	"month_september_short" to "Септ.",
	"multifactor_error" to "Не успя да провери.",
	"new" to "Нов",
	"news" to "Новини",
	"next" to "Следваща",
	"no" to "не",
	"no_data_available" to "Няма налични данни",
	"no_search_results" to "Съжаляваме, но не успяхме да намерим никакви резултати.",
	"none" to "Няма",
	"note_fields" to "Бележки",
	"note_hint" to "Забележка",
	"notifications_option" to "Известия",
	"ok" to "ОК",
	"onboarding" to "Въвеждане в експлоатация",
	"onboarding_aboutus_message" to "Основните части на DingVoice са завинаги безплатни. Нашата мисия е да ви помогнем да развиете бизнеса си, помогнете ни да се усъвършенстваме, като споделите обратната си връзка.",
	"onboarding_aboutus_title" to "Ние сме тук, за да ви помогнем",
	"onboarding_paid_1" to "Достъп до професионално изглеждащи шаблони",
	"onboarding_paid_2" to "Направете по-задълбочен преглед на финансите си",
	"onboarding_paid_3" to "Решаване на проблемите бързо",
	"onboarding_paid_4" to "Запазете документите си на сигурно място",
	"onboarding_paid_5" to "Бъдете в крак с промените в статуса на фактурите",
	"onboarding_paid_title" to "Растете още по-бързо с...",
	"onboarding_paywall_message" to "Насладете се на всички първокласни функции сега на най-добрата цена.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Просто ${currencySymbol}${introAmount} за ${introDuration} ${introPeriod} и след това ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, продължителност) -> "Само ${currencySymbol}${amount} / ${продължителност}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Безплатно за ${trialDuration} ${trialPeriod} и след това само ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Безплатно за ${trialDuration} ${trialPeriod} след това ${currencySymbol}${introductoryAmount} за ${introductoryDuration} ${introPeriod} и след това само ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Автоматично възобновяеми. Отказ по всяко време.",
	"onboarding_paywall_restore" to "Възстановяване на покупката",
	"onboarding_paywall_restore_failure" to "Възстановяването на покупките е неуспешно, опитайте отново по-късно или се свържете с поддръжката.",
	"onboarding_paywall_restore_success" to "Възстановяването е успешно!",
	"onboarding_paywall_title" to "Постигнете величие!",
	"onboarding_welcome_message" to "Пълен набор от инструменти за управление на фактури и оценки.",
	"onboarding_welcome_title" to "Добре дошли в DingVoice",
	"other_state" to "Други",
	"overdue_state" to "Просрочени",
	"paid_state" to "Платено",
	"password" to "Парола",
	"password_blank_error" to "Изисква се парола.",
	"password_mismatch_error" to "Паролите не съвпадат.",
	"pay" to "плати",
	"payment_instructions_label" to "Инструкции за плащане",
	"payment_reminders" to "Напомняния за плащане",
	"phone_number_fields" to "Телефонен номер",
	"phone_number_hint" to "Телефонен номер",
	"postcode_hint" to "Пощенски код",
	"preview" to "Преглед",
	"preview_menu_hint" to "Меню за преглед",
	"price" to "Цена",
	"privacy_policy" to "Политика за поверителност",
	"profile" to "Профил",
	"profitable_client" to "Вашият най-печеливш клиент е...",
	"profits_report" to "Печалби",
	"promo_content" to "Промоционално съдържание",
	"quantity_hint" to "Количество",
	"quantity_label_hint" to "Етикет за количество",
	"query_info" to "Запитването ви позволява да записвате потребителски изчисления, като използвате като входни данни както елементи, така и други данъчни правила. Добавете символа @ и описание на елемента или данъка, за да се свържете с друг запис +, -, *, /, % - математически оператори",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} е получен от вашия клиент." },
	"received_state" to "Получени",
	"registration_code_fields" to "Регистрационен код",
	"reminder" to "Напомняне",
	"reports" to "Доклади",
	"reports_not_available" to "Няма налични доклади, върни се по-късно.",
	"reveal_password" to "Разкриване на паролата",
	"revenue" to "приходи",
	"sales_tax" to "ДДС",
	"sales_tax_number" to "Номер по ДДС",
	"save" to "Запазете",
	"scan_document" to "Сканиране на документ",
	"search" to "Търсене",
	"select" to "Изберете",
	"select_icon" to "Изберете икона",
	"select_signature" to "Изберете подпис",
	"select_template" to "Изберете шаблон",
	"send" to "Изпрати",
	"sent_state" to "Изпратено",
	"setup_sender" to "Настройте подателя",
	"share_option" to "Споделете",
	"ship_to" to "Изпращане до",
	"sign_in" to "Влезте",
	"sign_in_apple" to "Влезте с Apple",
	"sign_in_email" to "Влезте с имейл",
	"sign_in_facebook" to "Влезте във Facebook",
	"sign_in_google" to "Влезте в Google",
	"sign_up" to "Регистрирайте се",
	"sign_up_label" to "Нямате акаунт?",
	"sign_up_link_label" to "Регистрирайте се!",
	"signatures_option" to "Подписи",
	"skip" to "Пропуснете",
	"sold_items_report" to "Продадени Елементи",
	"sort_by" to "Сортиране по:",
	"status_update" to "Актуализация на състоянието на документа",
	"sub_annual" to "Годишен",
	"sub_annual_price" to Formattable { (price) -> "${price}/година" },
	"sub_backups" to "Клауд архиви",
	"sub_insights" to "Прозрения",
	"sub_month" to "Месечно",
	"sub_monthly_price" to Formattable { (price) -> "${price}/месец" },
	"sub_notifications" to "Нотификации",
	"sub_popup_message" to "Абонаментите идват скоро. Проверете отново тук след малко.",
	"sub_support" to "Поддръжка",
	"sub_templates" to "Шаблони",
	"subscribe" to "Абонирайте се за",
	"subscription_header" to "Развийте бизнеса си с:",
	"subscription_option" to "Абонамент",
	"subscriptions_load_fail" to "Съжаляваме, но в момента не можем да изтеглим абонаментите ви. Моля, опитайте отново по-късно или се свържете с нашия екип за поддръжка за съдействие.",
	"subtotal" to Formattable { (sum) -> "Междинна сума: ${sum}" },
	"subtotal_label" to "Междинна сума",
	"sum" to "Сума",
	"support_option" to "Подкрепа",
	"switch_reports" to "Превключване на Доклад",
	"tax" to "Данък",
	"tax_deleted_message" to "Изтрити данъци",
	"tax_description_warning" to "Ако се използва, това се отразява във фактурата.",
	"tax_input_flat" to "Плосък",
	"tax_input_percent" to "Процент",
	"tax_number_fields" to "Данъчен номер",
	"tax_number_hint" to "Данъчен номер",
	"tax_query_info_description" to "Информация за заявката",
	"tax_report" to "Данък",
	"tax_rules_option" to "Данъчни правила",
	"taxed_clients_report" to "Данъчно задължени клиенти",
	"template" to "Шаблон",
	"template_selector_info" to "Плъзнете пръст, за да промените шаблона по подразбиране",
	"template_unlock_promo" to "Достъп до повече шаблони с Premium",
	"templates_option" to "Шаблони",
	"terms_of_service" to "Условия за ползване",
	"thank_you" to "Благодаря ви",
	"to" to "За",
	"total" to Formattable { (sum) -> "Общо: ${sum}" },
	"total_label" to "Общо",
	"undo_action" to "Отмяна на",
	"unit" to "Единица",
	"unit_cost_hint" to "Разходи за единица",
	"unknown_client" to "Неизвестен Kлиент",
	"unlock" to "Отключване на",
	"unnamed_item" to "Елемент без име",
	"upgrade" to "Надграждане",
	"user_collision_error" to "Акаунтът вече съществува.",
	"valid_until" to "Валидно до",
	"warning" to "Предупреждение!",
	"yes" to "да",
    )
    
}