// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Id  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Terima ${termsOfService} dan ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Kode tidak valid."
override val add_client_action: String = "Tambahkan klien"
override val add_customer: String = "Tambahkan pelanggan"
override val add_expense: String = "Tambahkan biaya"
override val add_fields_label: String = "Tambah:"
override val add_invoice_action: String = "menciptakan"
override val add_item_action: String = "Menambahkan item"
override val add_item_button: String = "Menambahkan item"
override val add_signature_action: String = "Menambahkan tanda tangan"
override val add_tax_action: String = "Tambahkan pajak"
override val address_fields: String = "Alamat"
override val address_line_hint: String = "Baris Alamat"
override val advanced: String = "Maju"
override val agree_marketing: String = "Saya setuju untuk menerima Komunikasi Pemasaran Langsung"
override val all_notifications: String = "Semua Pemberitahuan"
override val amortization: String = "amortization"
override val amount: String = "Jumlah"
override val asset: String = "asset"
override val attach_file: String = "Lampirkan File"
override val attachment: String = "Lampiran"
override val auth_error: String = "Tindakan gagal, coba lagi."
override val available_for_paid: String = "Sinkronisasi cloud hanya tersedia di bawah langganan berbayar."
override val backups_option: String = "Backup"
override val balance: String = "balance"
override val bank_account: String = "Rekening Bank"
override val bill_number: String = "Nomor Tagihan"
override val cancel: String = "membatalkan"
override val cancel_trial: String = "Batalkan kapan saja"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Kategori"
override val category: String = "Kategori"
override val category_add: String = "Tambah Kategori"
override val category_delete: String = "Menghapus kategori"
override val category_name: String = "Nama kategori"
override val change_language: Formattable = Formattable { (language) -> "Bahasa Faktur: ${language}" }
override val change_language_label: String = "Bahasa Faktur"
override val change_template: String = "Mengubah templat"
override val city_hint: String = "Kota"
override val clear: String = "Jelas"
override val client: String = "Klien"
override val client_actions: String = "Tindakan klien"
override val client_deleted_message: String = "Klien dihapus"
override val clients_error_required_fields: String = "Setidaknya diperlukan satu nilai."
override val clients_option: String = "Klien"
override val close: String = "tutup"
override val cloud_sync: String = "Sinkronisasi Cloud"
override val coming_soon: String = "Segera datang!"
override val company_logo: String = "Logo Perusahaan"
override val company_name_hint: String = "Firma"
override val confirm_password_label: String = "Konfirmasi kata sandi"
override val confirmation_title: String = "Apakah Anda yakin?"
override val convert_to_invoice: String = "Konversi ke faktur"
override val country_hint: String = "Negara"
override val create_client: String = "Buat Klien"
override val create_company: String = "Buat Perusahaan"
override val create_document: String = "Membuat dokumen"
override val create_estimate: String = "Kira"
override val create_invoice: String = "Faktur"
override val create_item: String = "Buat Item"
override val create_new_client: String = "Membuat klien baru"
override val create_new_item: String = "Membuat item baru"
override val create_new_tax: String = "Buat pajak baru"
override val create_signature: String = "Tanda tangan"
override val created: String = "Dibuat"
override val credit: String = "credit"
override val customer: String = "Pelanggan"
override val date: String = "Tanggal"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Mata uang default: ${currency}" }
override val default_currency_label: String = "Mata uang default"
override val delete: String = "menghapus"
override val delete_account: String = "Hapus akun"
override val delete_confirmation_message: String = "Semua data Anda akan hilang."
override val delete_item_title: String = "Menghapus item?"
override val delete_signature: String = "Menghapus tanda tangan"
override val depreciation: String = "depreciation"
override val description: String = "deskripsi"
override val description_hint: String = "deskripsi"
override val detailed_report: String = "Laporan Rinci"
override val disabled: String = "cacat"
override val discount: String = "Diskon"
override val discount_hint: String = "Diskon"
override val display_af: String = "Bahasa Afrikaans"
override val display_am: String = "Bahasa Amharik"
override val display_ar: String = "Bahasa Arab"
override val display_as: String = "Bahasa Assam"
override val display_az: String = "Azerbaijan"
override val display_be: String = "Belarusia"
override val display_bg: String = "Bahasa Bulgaria"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosnia"
override val display_ca: String = "Bahasa Katalan"
override val display_cs: String = "Ceko"
override val display_da: String = "Denmark"
override val display_de: String = "Jerman"
override val display_el: String = "Bahasa Yunani"
override val display_en: String = "Bahasa Inggris"
override val display_es: String = "Bahasa Spanyol"
override val display_es_MX: String = "Bahasa Spanyol (Meksiko)"
override val display_et: String = "Bahasa Estonia"
override val display_eu: String = "Basque"
override val display_fa: String = "Persia"
override val display_fi: String = "Finlandia"
override val display_fil: String = "Filipina"
override val display_fr: String = "Prancis"
override val display_fr_CA: String = "Bahasa Prancis Kanada"
override val display_gl: String = "Galicia"
override val display_gu: String = "Gujarati"
override val display_he: String = "Ibrani"
override val display_hi: String = "Hindi"
override val display_hr: String = "Kroasia"
override val display_hu: String = "Hongaria"
override val display_hy: String = "Armenia"
override val display_id: String = "Bahasa Indonesia"
override val display_is: String = "Islandia"
override val display_it: String = "Italia"
override val display_ja: String = "Bahasa Jepang"
override val display_ka: String = "Georgia"
override val display_kk: String = "Kazakh"
override val display_km: String = "Khmer Tengah"
override val display_kn: String = "Kannada"
override val display_ko: String = "Bahasa Korea"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Bahasa Lituania"
override val display_lv: String = "Bahasa Latvia"
override val display_mk: String = "Makedonia"
override val display_ml: String = "Bahasa Melayu"
override val display_mn: String = "Mongolia"
override val display_mr: String = "Marathi"
override val display_ms: String = "Bahasa Melayu"
override val display_my: String = "Orang Burma"
override val display_nb: String = "Bahasa Norwegia Bokmål"
override val display_ne: String = "Nepal"
override val display_nl: String = "Belanda"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polandia"
override val display_pt: String = "Bahasa Portugis"
override val display_pt_BR: String = "Bahasa Portugis (Brasil)"
override val display_pt_PT: String = "Bahasa Portugis Eropa"
override val display_ro: String = "Bahasa Rumania"
override val display_ru: String = "Rusia"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slowakia"
override val display_sl: String = "Bahasa Slovenia"
override val display_sq: String = "Bahasa Albania"
override val display_sr: String = "Bahasa Serbia"
override val display_sv: String = "Swedia"
override val display_sw: String = "Bahasa Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thailand"
override val display_tr: String = "Turki"
override val display_uk: String = "Ukraina"
override val display_ur: String = "Bahasa Urdu"
override val display_uz: String = "Uzbek"
override val display_vi: String = "Bahasa Vietnam"
override val display_zh_Hant_TW: String = "Tionghoa (Taiwan, Tradisional)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Estimasi"
override val document_invoice: String = "Faktur"
override val document_quote: String = "Kutipan"
override val done: String = "Selesai"
override val download_app: String = "Unduh aplikasinya"
override val download_invoice: String = "Unduh Faktur"
override val download_pdf: String = "Unduh PDF"
override val draw_signature: String = "Menggambar tanda tangan"
override val due: Formattable = Formattable { (date) -> "Jatuh tempo ${date}" }
override val due_date: String = "Tanggal Jatuh Tempo"
override val due_day: String = "dalam sehari"
override val due_label: String = "Karena"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} sudah jatuh tempo ${dueWhen}!" }
override val due_today: String = "Hari ini"
override val due_week: String = "dalam seminggu"
override val duplicate: String = "Duplikat"
override val duration_day: String = "hari"
override val duration_month: String = "bulan"
override val duration_week: String = "minggu"
override val duration_year: String = "tahun"
override val email: String = "Email"
override val email_blank_error: String = "Email diperlukan."
override val email_error: String = "Alamat email tidak valid."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Terima kasih untuk bisnis Anda. Untuk melihat secara lengkap ${documentType} klik tombol di akhir surat ini. Catatan: berlaku sampai ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Anda telah menerima ${documentType} ${invoiceNumber} baru!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "LIHAT ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Kepada ${clientName} yang terhormat" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Terima kasih atas bisnis Anda. Untuk melihat secara lengkap ${documentType} klik tombol di akhir surat ini. Harap bayar saldo sampai ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Anda telah menerima ${documentType} ${invoiceNumber} baru!" }
override val email_reset_success_message: String = "Email untuk mengatur ulang kata sandi yang dikirim, silakan periksa kotak masuk Anda."
override val empty_clients_list_message: String = "Anda tidak memiliki klien untuk dilacak. Tambahkan klien baru."
override val empty_estimate_list_message: String = "Buat perkiraan pertama Anda"
override val empty_expenses: String = "Anda tidak memiliki pengeluaran untuk dilacak. Tambahkan pengeluaran baru."
override val empty_invoice_list_message: String = "Membuat faktur pertama Anda"
override val empty_items_list_message: String = "Anda tidak memiliki item, tambahkan item baru!"
override val empty_signatures_list_message: String = "Tidak ada tanda tangan yang ditemukan. Buat satu!"
override val empty_taxes_list_message: String = "Anda tidak memiliki aturan pajak. Tambahkan aturan pajak baru."
override val empty_templates_list_message: String = "Templat tidak tersedia."
override val enabled: String = "Diaktifkan"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Perkiraan #"
override val estimates: String = "Perkiraan"
override val estimates_report: String = "Perkiraan"
override val expense_bank_fees: String = "Biaya Bank"
override val expense_benefits: String = "Manfaat"
override val expense_communication: String = "Komunikasi"
override val expense_debt: String = "Hutang"
override val expense_deleted: String = "Pengeluaran dihapus"
override val expense_depreciation: String = "Penyusutan"
override val expense_gifts: String = "Hadiah"
override val expense_insurance: String = "Asuransi"
override val expense_inventory: String = "Persediaan"
override val expense_legal: String = "Hukum"
override val expense_maintenance: String = "Pemeliharaan"
override val expense_marketing: String = "Pemasaran"
override val expense_meals: String = "Makanan"
override val expense_office_supplies: String = "Perlengkapan Kantor"
override val expense_rent: String = "Sewa"
override val expense_salaries: String = "Gaji"
override val expense_shopping: String = "Belanja"
override val expense_software: String = "Perangkat lunak"
override val expense_status_available: String = "Tersedia"
override val expense_status_processing: String = "Pengolahan"
override val expense_tax: String = "Pajak"
override val expense_training: String = "Pelatihan"
override val expense_transport: String = "Transportasi"
override val expense_travel: String = "Perjalanan"
override val expense_utility: String = "Utilitas"
override val expenses: String = "Biaya"
override val feedback_option: String = "Umpan balik"
override val filter: String = "Saringan"
override val first_name_hint: String = "Nama Depan"
override val forgot_password: String = "Reset Kata Sandi"
override val forgot_password_label: String = "Lupa kata sandi"
override val from: String = "Dari"
override val general_error: String = "Terjadi kesalahan, silakan coba lagi nanti."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Kembali"
override val group_by: String = "Kelompok dengan:"
override val image_view: String = "Citra"
override val install: String = "Pasang"
override val invalid_credentials_error: String = "Kredensial tidak valid."
override val invalid_email_error: String = "Email tidak valid."
override val invalid_user_error: String = "Kredensial pengguna yang tidak valid."
override val invoice_deleted_message: String = "Faktur dihapus"
override val invoice_menu: String = "Menu faktur"
override val invoice_number: String = "Faktur #"
override val invoices: String = "Faktur"
override val items_option: String = "Item"
override val keep: String = "Menyimpan"
override val language_selector: Formattable = Formattable { (selected) -> "Bahasa ${selected}" }
override val last_name_hint: String = "Nama Belakang"
override val ledger: String = "ledger"
override val legal: String = "Hukum"
override val legal_error: String = "Harus menerima Ketentuan Layanan dan Kebijakan Privasi"
override val liabilities: String = "liabilities"
override val loading: String = "Loading"
override val login_required_error: String = "Login diperlukan."
override val logo_view: String = "Logo"
override val logout: String = "Logout"
override val logout_prompt: String = "Data Anda tidak disinkronkan dengan cloud. Menghapus faktur, perkiraan, klien , dan informasi lain tentang logout?"
override val main_screen: String = "Layar utama"
override val mark_paid: String = "Tandai sebagai Berbayar"
override val message: String = "Pesan"
override val missing_sender_msg: String = "Dokumen tanpa informasi pengirim mungkin tidak valid."
override val missing_sender_title: String = "Tidak ada informasi pengirim"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "Agustus"
override val month_august_short: String = "Agus."
override val month_december: String = "Desember"
override val month_december_short: String = "Des."
override val month_february: String = "Februari"
override val month_february_short: String = "Feb."
override val month_january: String = "Januari"
override val month_january_short: String = "Jan."
override val month_july: String = "Juli"
override val month_july_short: String = "Juli"
override val month_june: String = "Juni"
override val month_june_short: String = "Juni"
override val month_march: String = "Maret"
override val month_march_short: String = "Maret"
override val month_may: String = "Mei"
override val month_may_short: String = "Mei"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktober"
override val month_october_short: String = "Okt."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Gagal memverifikasi."
override val new: String = "Baru"
override val news: String = "Berita"
override val next: String = "Berikutnya"
override val no: String = "no"
override val no_data_available: String = "Tidak ada data yang tersedia"
override val no_search_results: String = "Maaf, kami tidak dapat menemukan hasil apapun."
override val none: String = "Tidak ada"
override val note_fields: String = "Catatan"
override val note_hint: String = "Nota"
override val notifications_option: String = "Pemberitahuan"
override val ok: String = "Oke"
override val onboarding: String = "Orientasi"
override val onboarding_aboutus_message: String = "Bagian penting dari DingVoice selamanya gratis. Misi kami adalah membantu Anda mengembangkan bisnis Anda, bantu kami meningkatkan dengan berbagi umpan balik Anda."
override val onboarding_aboutus_title: String = "Kami di sini untuk membantu"
override val onboarding_paid_1: String = "Akses templat yang terlihat profesional"
override val onboarding_paid_2: String = "Lihatlah lebih dalam tentang keuangan Anda"
override val onboarding_paid_3: String = "Dapatkan masalah terselesaikan dengan cepat"
override val onboarding_paid_4: String = "Amankan dokumen Anda"
override val onboarding_paid_5: String = "Tetap up to date dengan perubahan status faktur"
override val onboarding_paid_title: String = "Tumbuh lebih cepat dengan..."
override val onboarding_paywall_message: String = "Nikmati semua fitur premium sekarang dengan harga terbaik."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Hanya ${currencySymbol}${introAmount} untuk ${introDuration} ${introPeriod} dan lalu ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, durasi) -> "Hanya ${currencySymbol}${amount} / ${durasi}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis untuk ${trialDuration} ${trialPeriod} dan kemudian hanya ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis untuk ${trialDuration} ${trialPeriod} lalu ${currencySymbol}${introductoryAmount} untuk ${introductoryDuration} ${introPeriod} dan kemudian hanya ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Dapat diperpanjang secara otomatis. Batalkan kapan saja."
override val onboarding_paywall_restore: String = "Kembalikan pembelian"
override val onboarding_paywall_restore_failure: String = "Memulihkan pembelian telah gagal, coba lagi nanti atau hubungi dukungan."
override val onboarding_paywall_restore_success: String = "Pemulihan berhasil!"
override val onboarding_paywall_title: String = "Raih Kehebatan!"
override val onboarding_welcome_message: String = "Perangkat lengkap untuk mengelola faktur & estimasi Anda."
override val onboarding_welcome_title: String = "Selamat datang di DingVoice"
override val other_state: String = "Lainnya"
override val overdue_state: String = "Terlambat"
override val paid_state: String = "Dibayar"
override val password: String = "Kata sandi"
override val password_blank_error: String = "Kata sandi diperlukan."
override val password_mismatch_error: String = "Kata sandi tidak cocok."
override val pay: String = "pay"
override val payment_instructions_label: String = "Petunjuk Pembayaran"
override val payment_reminders: String = "Pengingat pembayaran"
override val phone_number_fields: String = "Nomor Telepon"
override val phone_number_hint: String = "Nomor Telepon"
override val postcode_hint: String = "Kodepos"
override val preview: String = "Pratayang"
override val preview_menu_hint: String = "Menu Pratinjau"
override val price: String = "Harga"
override val privacy_policy: String = "Kebijakan Privasi"
override val profile: String = "Profil"
override val profitable_client: String = "Klien Anda yang paling menguntungkan adalah..."
override val profits_report: String = "Keuntungan"
override val promo_content: String = "Konten promosi"
override val quantity_hint: String = "Jumlah"
override val quantity_label_hint: String = "Label Kuantitas"
override val query_info: String = "Kueri memungkinkan Anda menulis perhitungan khusus yang mengacu pada item dan aturan pajak lainnya sebagai input. Tambahkan @ simbol dan item atau deskripsi pajak untuk menautkan ke entri lain +, -, *, /, % - operator matematika"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} telah diterima oleh klien Anda." }
override val received_state: String = "Diterima"
override val registration_code_fields: String = "Kode Registrasi"
override val reminder: String = "Pengingat"
override val reports: String = "Laporan"
override val reports_not_available: String = "Tidak ada laporan yang tersedia, kembali lagi nanti."
override val reveal_password: String = "Mengungkapkan kata sandi"
override val revenue: String = "revenue"
override val sales_tax: String = "PAJAK PERTAMBAHAN NILAI"
override val sales_tax_number: String = "Nomor PPN"
override val save: String = "simpan"
override val scan_document: String = "Memindai dokumen"
override val search: String = "Mencari"
override val select: String = "Pilih"
override val select_icon: String = "Pilih ikon"
override val select_signature: String = "Pilih tanda tangan"
override val select_template: String = "Pilih templat"
override val send: String = "Kirim"
override val sent_state: String = "Dikirim"
override val setup_sender: String = "Atur pengirim"
override val share_option: String = "Bagi"
override val ship_to: String = "Kirim ke"
override val sign_in: String = "Tandatangan"
override val sign_in_apple: String = "Login dengan Apple"
override val sign_in_email: String = "Masuk dengan email"
override val sign_in_facebook: String = "Login dengan Facebook"
override val sign_in_google: String = "Login dengan Google"
override val sign_up: String = "Daftar"
override val sign_up_label: String = "Tidak punya akun?"
override val sign_up_link_label: String = "Daftar!"
override val signatures_option: String = "Tanda tangan"
override val skip: String = "SKIP"
override val sold_items_report: String = "Barang Terjual"
override val sort_by: String = "Urutkan Menurut:"
override val status_update: String = "Pembaruan status dokumen"
override val sub_annual: String = "Tahunan"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/tahun" }
override val sub_backups: String = "Cadangan Cloud"
override val sub_insights: String = "Wawasan"
override val sub_month: String = "Bulanan"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/bulan" }
override val sub_notifications: String = "Pemberitahuan"
override val sub_popup_message: String = "Langganan akan segera hadir. Periksa kembali ke sini sebentar lagi."
override val sub_support: String = "Dukung"
override val sub_templates: String = "Template"
override val subscribe: String = "Berlangganan"
override val subscription_header: String = "Kembangkan bisnis Anda dengan:"
override val subscription_option: String = "Abonemen"
override val subscriptions_load_fail: String = "Maaf, kami tidak dapat mengambil langganan Anda saat ini. Silakan coba lagi nanti atau hubungi tim dukungan kami untuk mendapatkan bantuan."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "Subtotal"
override val sum: String = "Jumlah"
override val support_option: String = "Dukung"
override val switch_reports: String = "Beralih Laporan"
override val tax: String = "Pajak"
override val tax_deleted_message: String = "Pajak dihapus"
override val tax_description_warning: String = "Jika digunakan ini muncul di faktur"
override val tax_input_flat: String = "Rata"
override val tax_input_percent: String = "Persen"
override val tax_number_fields: String = "Nomor Pajak"
override val tax_number_hint: String = "Nomor Pajak"
override val tax_query_info_description: String = "Info Kueri"
override val tax_report: String = "Pajak"
override val tax_rules_option: String = "Aturan Pajak"
override val taxed_clients_report: String = "Klien yang Dikenai Pajak"
override val template: String = "Templat"
override val template_selector_info: String = "Gesek untuk mengubah templat default"
override val template_unlock_promo: String = "Akses lebih banyak templat dengan Premium"
override val templates_option: String = "Template"
override val terms_of_service: String = "Ketentuan Layanan"
override val thank_you: String = "Terima kasih"
override val to: String = "ke"
override val total: Formattable = Formattable { (sum) -> "Total: ${sum}" }
override val total_label: String = "Total"
override val undo_action: String = "Buka"
override val unit: String = "Satuan"
override val unit_cost_hint: String = "Biaya Unit"
override val unknown_client: String = "Klien Tidak Dikenal"
override val unlock: String = "Buka kunci"
override val unnamed_item: String = "Item tanpa nama"
override val upgrade: String = "Upgrade"
override val user_collision_error: String = "Akun sudah ada."
override val valid_until: String = "Berlaku Hingga"
override val warning: String = "Peringatan!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Terima ${termsOfService} dan ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Kode tidak valid.",
	"add_client_action" to "Tambahkan klien",
	"add_customer" to "Tambahkan pelanggan",
	"add_expense" to "Tambahkan biaya",
	"add_fields_label" to "Tambah:",
	"add_invoice_action" to "menciptakan",
	"add_item_action" to "Menambahkan item",
	"add_item_button" to "Menambahkan item",
	"add_signature_action" to "Menambahkan tanda tangan",
	"add_tax_action" to "Tambahkan pajak",
	"address_fields" to "Alamat",
	"address_line_hint" to "Baris Alamat",
	"advanced" to "Maju",
	"agree_marketing" to "Saya setuju untuk menerima Komunikasi Pemasaran Langsung",
	"all_notifications" to "Semua Pemberitahuan",
	"amortization" to "amortization",
	"amount" to "Jumlah",
	"asset" to "asset",
	"attach_file" to "Lampirkan File",
	"attachment" to "Lampiran",
	"auth_error" to "Tindakan gagal, coba lagi.",
	"available_for_paid" to "Sinkronisasi cloud hanya tersedia di bawah langganan berbayar.",
	"backups_option" to "Backup",
	"balance" to "balance",
	"bank_account" to "Rekening Bank",
	"bill_number" to "Nomor Tagihan",
	"cancel" to "membatalkan",
	"cancel_trial" to "Batalkan kapan saja",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Kategori",
	"category" to "Kategori",
	"category_add" to "Tambah Kategori",
	"category_delete" to "Menghapus kategori",
	"category_name" to "Nama kategori",
	"change_language" to Formattable { (language) -> "Bahasa Faktur: ${language}" },
	"change_language_label" to "Bahasa Faktur",
	"change_template" to "Mengubah templat",
	"city_hint" to "Kota",
	"clear" to "Jelas",
	"client" to "Klien",
	"client_actions" to "Tindakan klien",
	"client_deleted_message" to "Klien dihapus",
	"clients_error_required_fields" to "Setidaknya diperlukan satu nilai.",
	"clients_option" to "Klien",
	"close" to "tutup",
	"cloud_sync" to "Sinkronisasi Cloud",
	"coming_soon" to "Segera datang!",
	"company_logo" to "Logo Perusahaan",
	"company_name_hint" to "Firma",
	"confirm_password_label" to "Konfirmasi kata sandi",
	"confirmation_title" to "Apakah Anda yakin?",
	"convert_to_invoice" to "Konversi ke faktur",
	"country_hint" to "Negara",
	"create_client" to "Buat Klien",
	"create_company" to "Buat Perusahaan",
	"create_document" to "Membuat dokumen",
	"create_estimate" to "Kira",
	"create_invoice" to "Faktur",
	"create_item" to "Buat Item",
	"create_new_client" to "Membuat klien baru",
	"create_new_item" to "Membuat item baru",
	"create_new_tax" to "Buat pajak baru",
	"create_signature" to "Tanda tangan",
	"created" to "Dibuat",
	"credit" to "credit",
	"customer" to "Pelanggan",
	"date" to "Tanggal",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Mata uang default: ${currency}" },
	"default_currency_label" to "Mata uang default",
	"delete" to "menghapus",
	"delete_account" to "Hapus akun",
	"delete_confirmation_message" to "Semua data Anda akan hilang.",
	"delete_item_title" to "Menghapus item?",
	"delete_signature" to "Menghapus tanda tangan",
	"depreciation" to "depreciation",
	"description" to "deskripsi",
	"description_hint" to "deskripsi",
	"detailed_report" to "Laporan Rinci",
	"disabled" to "cacat",
	"discount" to "Diskon",
	"discount_hint" to "Diskon",
	"display_af" to "Bahasa Afrikaans",
	"display_am" to "Bahasa Amharik",
	"display_ar" to "Bahasa Arab",
	"display_as" to "Bahasa Assam",
	"display_az" to "Azerbaijan",
	"display_be" to "Belarusia",
	"display_bg" to "Bahasa Bulgaria",
	"display_bn" to "Bengali",
	"display_bs" to "Bosnia",
	"display_ca" to "Bahasa Katalan",
	"display_cs" to "Ceko",
	"display_da" to "Denmark",
	"display_de" to "Jerman",
	"display_el" to "Bahasa Yunani",
	"display_en" to "Bahasa Inggris",
	"display_es" to "Bahasa Spanyol",
	"display_es_MX" to "Bahasa Spanyol (Meksiko)",
	"display_et" to "Bahasa Estonia",
	"display_eu" to "Basque",
	"display_fa" to "Persia",
	"display_fi" to "Finlandia",
	"display_fil" to "Filipina",
	"display_fr" to "Prancis",
	"display_fr_CA" to "Bahasa Prancis Kanada",
	"display_gl" to "Galicia",
	"display_gu" to "Gujarati",
	"display_he" to "Ibrani",
	"display_hi" to "Hindi",
	"display_hr" to "Kroasia",
	"display_hu" to "Hongaria",
	"display_hy" to "Armenia",
	"display_id" to "Bahasa Indonesia",
	"display_is" to "Islandia",
	"display_it" to "Italia",
	"display_ja" to "Bahasa Jepang",
	"display_ka" to "Georgia",
	"display_kk" to "Kazakh",
	"display_km" to "Khmer Tengah",
	"display_kn" to "Kannada",
	"display_ko" to "Bahasa Korea",
	"display_ky" to "Kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Bahasa Lituania",
	"display_lv" to "Bahasa Latvia",
	"display_mk" to "Makedonia",
	"display_ml" to "Bahasa Melayu",
	"display_mn" to "Mongolia",
	"display_mr" to "Marathi",
	"display_ms" to "Bahasa Melayu",
	"display_my" to "Orang Burma",
	"display_nb" to "Bahasa Norwegia Bokmål",
	"display_ne" to "Nepal",
	"display_nl" to "Belanda",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polandia",
	"display_pt" to "Bahasa Portugis",
	"display_pt_BR" to "Bahasa Portugis (Brasil)",
	"display_pt_PT" to "Bahasa Portugis Eropa",
	"display_ro" to "Bahasa Rumania",
	"display_ru" to "Rusia",
	"display_si" to "Sinhala",
	"display_sk" to "Slowakia",
	"display_sl" to "Bahasa Slovenia",
	"display_sq" to "Bahasa Albania",
	"display_sr" to "Bahasa Serbia",
	"display_sv" to "Swedia",
	"display_sw" to "Bahasa Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thailand",
	"display_tr" to "Turki",
	"display_uk" to "Ukraina",
	"display_ur" to "Bahasa Urdu",
	"display_uz" to "Uzbek",
	"display_vi" to "Bahasa Vietnam",
	"display_zh_Hant_TW" to "Tionghoa (Taiwan, Tradisional)",
	"display_zu" to "Zulu",
	"document_estimate" to "Estimasi",
	"document_invoice" to "Faktur",
	"document_quote" to "Kutipan",
	"done" to "Selesai",
	"download_app" to "Unduh aplikasinya",
	"download_invoice" to "Unduh Faktur",
	"download_pdf" to "Unduh PDF",
	"draw_signature" to "Menggambar tanda tangan",
	"due" to Formattable { (date) -> "Jatuh tempo ${date}" },
	"due_date" to "Tanggal Jatuh Tempo",
	"due_day" to "dalam sehari",
	"due_label" to "Karena",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} sudah jatuh tempo ${dueWhen}!" },
	"due_today" to "Hari ini",
	"due_week" to "dalam seminggu",
	"duplicate" to "Duplikat",
	"duration_day" to "hari",
	"duration_month" to "bulan",
	"duration_week" to "minggu",
	"duration_year" to "tahun",
	"email" to "Email",
	"email_blank_error" to "Email diperlukan.",
	"email_error" to "Alamat email tidak valid.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Terima kasih untuk bisnis Anda. Untuk melihat secara lengkap ${documentType} klik tombol di akhir surat ini. Catatan: berlaku sampai ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Anda telah menerima ${documentType} ${invoiceNumber} baru!" },
	"email_invoice_cta" to Formattable { (documentType) -> "LIHAT ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Kepada ${clientName} yang terhormat" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Terima kasih atas bisnis Anda. Untuk melihat secara lengkap ${documentType} klik tombol di akhir surat ini. Harap bayar saldo sampai ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Anda telah menerima ${documentType} ${invoiceNumber} baru!" },
	"email_reset_success_message" to "Email untuk mengatur ulang kata sandi yang dikirim, silakan periksa kotak masuk Anda.",
	"empty_clients_list_message" to "Anda tidak memiliki klien untuk dilacak. Tambahkan klien baru.",
	"empty_estimate_list_message" to "Buat perkiraan pertama Anda",
	"empty_expenses" to "Anda tidak memiliki pengeluaran untuk dilacak. Tambahkan pengeluaran baru.",
	"empty_invoice_list_message" to "Membuat faktur pertama Anda",
	"empty_items_list_message" to "Anda tidak memiliki item, tambahkan item baru!",
	"empty_signatures_list_message" to "Tidak ada tanda tangan yang ditemukan. Buat satu!",
	"empty_taxes_list_message" to "Anda tidak memiliki aturan pajak. Tambahkan aturan pajak baru.",
	"empty_templates_list_message" to "Templat tidak tersedia.",
	"enabled" to "Diaktifkan",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Perkiraan #",
	"estimates" to "Perkiraan",
	"estimates_report" to "Perkiraan",
	"expense_bank_fees" to "Biaya Bank",
	"expense_benefits" to "Manfaat",
	"expense_communication" to "Komunikasi",
	"expense_debt" to "Hutang",
	"expense_deleted" to "Pengeluaran dihapus",
	"expense_depreciation" to "Penyusutan",
	"expense_gifts" to "Hadiah",
	"expense_insurance" to "Asuransi",
	"expense_inventory" to "Persediaan",
	"expense_legal" to "Hukum",
	"expense_maintenance" to "Pemeliharaan",
	"expense_marketing" to "Pemasaran",
	"expense_meals" to "Makanan",
	"expense_office_supplies" to "Perlengkapan Kantor",
	"expense_rent" to "Sewa",
	"expense_salaries" to "Gaji",
	"expense_shopping" to "Belanja",
	"expense_software" to "Perangkat lunak",
	"expense_status_available" to "Tersedia",
	"expense_status_processing" to "Pengolahan",
	"expense_tax" to "Pajak",
	"expense_training" to "Pelatihan",
	"expense_transport" to "Transportasi",
	"expense_travel" to "Perjalanan",
	"expense_utility" to "Utilitas",
	"expenses" to "Biaya",
	"feedback_option" to "Umpan balik",
	"filter" to "Saringan",
	"first_name_hint" to "Nama Depan",
	"forgot_password" to "Reset Kata Sandi",
	"forgot_password_label" to "Lupa kata sandi",
	"from" to "Dari",
	"general_error" to "Terjadi kesalahan, silakan coba lagi nanti.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Kembali",
	"group_by" to "Kelompok dengan:",
	"image_view" to "Citra",
	"install" to "Pasang",
	"invalid_credentials_error" to "Kredensial tidak valid.",
	"invalid_email_error" to "Email tidak valid.",
	"invalid_user_error" to "Kredensial pengguna yang tidak valid.",
	"invoice_deleted_message" to "Faktur dihapus",
	"invoice_menu" to "Menu faktur",
	"invoice_number" to "Faktur #",
	"invoices" to "Faktur",
	"items_option" to "Item",
	"keep" to "Menyimpan",
	"language_selector" to Formattable { (selected) -> "Bahasa ${selected}" },
	"last_name_hint" to "Nama Belakang",
	"ledger" to "ledger",
	"legal" to "Hukum",
	"legal_error" to "Harus menerima Ketentuan Layanan dan Kebijakan Privasi",
	"liabilities" to "liabilities",
	"loading" to "Loading",
	"login_required_error" to "Login diperlukan.",
	"logo_view" to "Logo",
	"logout" to "Logout",
	"logout_prompt" to "Data Anda tidak disinkronkan dengan cloud. Menghapus faktur, perkiraan, klien , dan informasi lain tentang logout?",
	"main_screen" to "Layar utama",
	"mark_paid" to "Tandai sebagai Berbayar",
	"message" to "Pesan",
	"missing_sender_msg" to "Dokumen tanpa informasi pengirim mungkin tidak valid.",
	"missing_sender_title" to "Tidak ada informasi pengirim",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "Agustus",
	"month_august_short" to "Agus.",
	"month_december" to "Desember",
	"month_december_short" to "Des.",
	"month_february" to "Februari",
	"month_february_short" to "Feb.",
	"month_january" to "Januari",
	"month_january_short" to "Jan.",
	"month_july" to "Juli",
	"month_july_short" to "Juli",
	"month_june" to "Juni",
	"month_june_short" to "Juni",
	"month_march" to "Maret",
	"month_march_short" to "Maret",
	"month_may" to "Mei",
	"month_may_short" to "Mei",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Oktober",
	"month_october_short" to "Okt.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Gagal memverifikasi.",
	"new" to "Baru",
	"news" to "Berita",
	"next" to "Berikutnya",
	"no" to "no",
	"no_data_available" to "Tidak ada data yang tersedia",
	"no_search_results" to "Maaf, kami tidak dapat menemukan hasil apapun.",
	"none" to "Tidak ada",
	"note_fields" to "Catatan",
	"note_hint" to "Nota",
	"notifications_option" to "Pemberitahuan",
	"ok" to "Oke",
	"onboarding" to "Orientasi",
	"onboarding_aboutus_message" to "Bagian penting dari DingVoice selamanya gratis. Misi kami adalah membantu Anda mengembangkan bisnis Anda, bantu kami meningkatkan dengan berbagi umpan balik Anda.",
	"onboarding_aboutus_title" to "Kami di sini untuk membantu",
	"onboarding_paid_1" to "Akses templat yang terlihat profesional",
	"onboarding_paid_2" to "Lihatlah lebih dalam tentang keuangan Anda",
	"onboarding_paid_3" to "Dapatkan masalah terselesaikan dengan cepat",
	"onboarding_paid_4" to "Amankan dokumen Anda",
	"onboarding_paid_5" to "Tetap up to date dengan perubahan status faktur",
	"onboarding_paid_title" to "Tumbuh lebih cepat dengan...",
	"onboarding_paywall_message" to "Nikmati semua fitur premium sekarang dengan harga terbaik.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Hanya ${currencySymbol}${introAmount} untuk ${introDuration} ${introPeriod} dan lalu ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, durasi) -> "Hanya ${currencySymbol}${amount} / ${durasi}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis untuk ${trialDuration} ${trialPeriod} dan kemudian hanya ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis untuk ${trialDuration} ${trialPeriod} lalu ${currencySymbol}${introductoryAmount} untuk ${introductoryDuration} ${introPeriod} dan kemudian hanya ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Dapat diperpanjang secara otomatis. Batalkan kapan saja.",
	"onboarding_paywall_restore" to "Kembalikan pembelian",
	"onboarding_paywall_restore_failure" to "Memulihkan pembelian telah gagal, coba lagi nanti atau hubungi dukungan.",
	"onboarding_paywall_restore_success" to "Pemulihan berhasil!",
	"onboarding_paywall_title" to "Raih Kehebatan!",
	"onboarding_welcome_message" to "Perangkat lengkap untuk mengelola faktur & estimasi Anda.",
	"onboarding_welcome_title" to "Selamat datang di DingVoice",
	"other_state" to "Lainnya",
	"overdue_state" to "Terlambat",
	"paid_state" to "Dibayar",
	"password" to "Kata sandi",
	"password_blank_error" to "Kata sandi diperlukan.",
	"password_mismatch_error" to "Kata sandi tidak cocok.",
	"pay" to "pay",
	"payment_instructions_label" to "Petunjuk Pembayaran",
	"payment_reminders" to "Pengingat pembayaran",
	"phone_number_fields" to "Nomor Telepon",
	"phone_number_hint" to "Nomor Telepon",
	"postcode_hint" to "Kodepos",
	"preview" to "Pratayang",
	"preview_menu_hint" to "Menu Pratinjau",
	"price" to "Harga",
	"privacy_policy" to "Kebijakan Privasi",
	"profile" to "Profil",
	"profitable_client" to "Klien Anda yang paling menguntungkan adalah...",
	"profits_report" to "Keuntungan",
	"promo_content" to "Konten promosi",
	"quantity_hint" to "Jumlah",
	"quantity_label_hint" to "Label Kuantitas",
	"query_info" to "Kueri memungkinkan Anda menulis perhitungan khusus yang mengacu pada item dan aturan pajak lainnya sebagai input. Tambahkan @ simbol dan item atau deskripsi pajak untuk menautkan ke entri lain +, -, *, /, % - operator matematika",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} telah diterima oleh klien Anda." },
	"received_state" to "Diterima",
	"registration_code_fields" to "Kode Registrasi",
	"reminder" to "Pengingat",
	"reports" to "Laporan",
	"reports_not_available" to "Tidak ada laporan yang tersedia, kembali lagi nanti.",
	"reveal_password" to "Mengungkapkan kata sandi",
	"revenue" to "revenue",
	"sales_tax" to "PAJAK PERTAMBAHAN NILAI",
	"sales_tax_number" to "Nomor PPN",
	"save" to "simpan",
	"scan_document" to "Memindai dokumen",
	"search" to "Mencari",
	"select" to "Pilih",
	"select_icon" to "Pilih ikon",
	"select_signature" to "Pilih tanda tangan",
	"select_template" to "Pilih templat",
	"send" to "Kirim",
	"sent_state" to "Dikirim",
	"setup_sender" to "Atur pengirim",
	"share_option" to "Bagi",
	"ship_to" to "Kirim ke",
	"sign_in" to "Tandatangan",
	"sign_in_apple" to "Login dengan Apple",
	"sign_in_email" to "Masuk dengan email",
	"sign_in_facebook" to "Login dengan Facebook",
	"sign_in_google" to "Login dengan Google",
	"sign_up" to "Daftar",
	"sign_up_label" to "Tidak punya akun?",
	"sign_up_link_label" to "Daftar!",
	"signatures_option" to "Tanda tangan",
	"skip" to "SKIP",
	"sold_items_report" to "Barang Terjual",
	"sort_by" to "Urutkan Menurut:",
	"status_update" to "Pembaruan status dokumen",
	"sub_annual" to "Tahunan",
	"sub_annual_price" to Formattable { (price) -> "${price}/tahun" },
	"sub_backups" to "Cadangan Cloud",
	"sub_insights" to "Wawasan",
	"sub_month" to "Bulanan",
	"sub_monthly_price" to Formattable { (price) -> "${price}/bulan" },
	"sub_notifications" to "Pemberitahuan",
	"sub_popup_message" to "Langganan akan segera hadir. Periksa kembali ke sini sebentar lagi.",
	"sub_support" to "Dukung",
	"sub_templates" to "Template",
	"subscribe" to "Berlangganan",
	"subscription_header" to "Kembangkan bisnis Anda dengan:",
	"subscription_option" to "Abonemen",
	"subscriptions_load_fail" to "Maaf, kami tidak dapat mengambil langganan Anda saat ini. Silakan coba lagi nanti atau hubungi tim dukungan kami untuk mendapatkan bantuan.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "Subtotal",
	"sum" to "Jumlah",
	"support_option" to "Dukung",
	"switch_reports" to "Beralih Laporan",
	"tax" to "Pajak",
	"tax_deleted_message" to "Pajak dihapus",
	"tax_description_warning" to "Jika digunakan ini muncul di faktur",
	"tax_input_flat" to "Rata",
	"tax_input_percent" to "Persen",
	"tax_number_fields" to "Nomor Pajak",
	"tax_number_hint" to "Nomor Pajak",
	"tax_query_info_description" to "Info Kueri",
	"tax_report" to "Pajak",
	"tax_rules_option" to "Aturan Pajak",
	"taxed_clients_report" to "Klien yang Dikenai Pajak",
	"template" to "Templat",
	"template_selector_info" to "Gesek untuk mengubah templat default",
	"template_unlock_promo" to "Akses lebih banyak templat dengan Premium",
	"templates_option" to "Template",
	"terms_of_service" to "Ketentuan Layanan",
	"thank_you" to "Terima kasih",
	"to" to "ke",
	"total" to Formattable { (sum) -> "Total: ${sum}" },
	"total_label" to "Total",
	"undo_action" to "Buka",
	"unit" to "Satuan",
	"unit_cost_hint" to "Biaya Unit",
	"unknown_client" to "Klien Tidak Dikenal",
	"unlock" to "Buka kunci",
	"unnamed_item" to "Item tanpa nama",
	"upgrade" to "Upgrade",
	"user_collision_error" to "Akun sudah ada.",
	"valid_until" to "Berlaku Hingga",
	"warning" to "Peringatan!",
	"yes" to "yes",
    )
    
}