// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Da  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Accepter ${termsOfService} og ${privacyPolicy}" }
override val account: String = "konto"
override val accounting: String = "regnskab"
override val accruals: String = "periodiseringer"
override val action_code_error: String = "Ugyldig kode."
override val add_client_action: String = "Tilføj klient"
override val add_customer: String = "Tilføj kunde"
override val add_expense: String = "Tilføj udgifter"
override val add_fields_label: String = "Tilføj:"
override val add_invoice_action: String = "Opret"
override val add_item_action: String = "Tilføj vare"
override val add_item_button: String = "Tilføj vare"
override val add_signature_action: String = "Tilføj signatur"
override val add_tax_action: String = "Tilføj skat"
override val address_fields: String = "Adresse"
override val address_line_hint: String = "Adresse linje"
override val advanced: String = "Avanceret"
override val agree_marketing: String = "Jeg accepterer at modtage direkte marketingkommunikation"
override val all_notifications: String = "Alle meddelelser"
override val amortization: String = "amortisering"
override val amount: String = "Beløb"
override val asset: String = "aktiv"
override val attach_file: String = "Vedhæft fil"
override val attachment: String = "Vedhæftede fil"
override val auth_error: String = "Handlingen mislykkedes, prøv igen."
override val available_for_paid: String = "Synkronisering i skyen er kun tilgængelig med et betalt abonnement."
override val backups_option: String = "Sikkerhedskopiering"
override val balance: String = "saldo"
override val bank_account: String = "Bankkonto"
override val bill_number: String = "Regningsnummer"
override val cancel: String = "Annuller"
override val cancel_trial: String = "Annuller når som helst"
override val capital: String = "kapital"
override val cash: String = "kontanter"
override val categories: String = "Kategorier"
override val category: String = "Kategori"
override val category_add: String = "Tilføj kategori"
override val category_delete: String = "Slet kategori"
override val category_name: String = "Navn på kategori"
override val change_language: Formattable = Formattable { (language) -> "Fakturasprog: ${language}" }
override val change_language_label: String = "Fakturasprog"
override val change_template: String = "Ændre skabelon"
override val city_hint: String = "By"
override val clear: String = "Klar"
override val client: String = "Kunde"
override val client_actions: String = "Kundeforanstaltninger"
override val client_deleted_message: String = "Klient slettet"
override val clients_error_required_fields: String = "Der kræves mindst én værdi."
override val clients_option: String = "Kunder"
override val close: String = "Luk"
override val cloud_sync: String = "Synkronisering i skyen"
override val coming_soon: String = "Kommer snart!"
override val company_logo: String = "Virksomhedens logo"
override val company_name_hint: String = "Virksomhed"
override val confirm_password_label: String = "Bekræft adgangskode"
override val confirmation_title: String = "Er du sikker?"
override val convert_to_invoice: String = "Konverter til faktura"
override val country_hint: String = "Land"
override val create_client: String = "Opret klient"
override val create_company: String = "Opret virksomhed"
override val create_document: String = "Opret dokument"
override val create_estimate: String = "Skøn"
override val create_invoice: String = "Faktura"
override val create_item: String = "Opret Vare"
override val create_new_client: String = "Opret ny klient"
override val create_new_item: String = "Opret ny vare"
override val create_new_tax: String = "Oprette ny afgift"
override val create_signature: String = "Signatur"
override val created: String = "Oprettet"
override val credit: String = "kredit"
override val customer: String = "Kunde"
override val date: String = "Dato"
override val debit: String = "debet"
override val debt: String = "gæld"
override val default_currency: Formattable = Formattable { (currency) -> "Standardvaluta: ${currency}" }
override val default_currency_label: String = "Standardvaluta"
override val delete: String = "Slet"
override val delete_account: String = "Slet Konto"
override val delete_confirmation_message: String = "Alle dine data vil gå tabt."
override val delete_item_title: String = "Sletning af varen?"
override val delete_signature: String = "Slet signatur"
override val depreciation: String = "afskrivning"
override val description: String = "Beskrivelse"
override val description_hint: String = "Beskrivelse"
override val detailed_report: String = "Detaljeret Rapport"
override val disabled: String = "Handicappede"
override val discount: String = "Rabat"
override val discount_hint: String = "Rabat"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharisk"
override val display_ar: String = "Arabisk"
override val display_as: String = "Assamesisk"
override val display_az: String = "Aserbajdsjansk"
override val display_be: String = "Hviderussisk"
override val display_bg: String = "Bulgarsk"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosnisk"
override val display_ca: String = "Katalansk"
override val display_cs: String = "Tjekkisk"
override val display_da: String = "Dansk"
override val display_de: String = "Tysk"
override val display_el: String = "Græsk"
override val display_en: String = "Engelsk"
override val display_es: String = "Spansk"
override val display_es_MX: String = "Spansk (Mexico)"
override val display_et: String = "Estisk"
override val display_eu: String = "Baskisk"
override val display_fa: String = "Persisk"
override val display_fi: String = "Finsk"
override val display_fil: String = "Filippinsk"
override val display_fr: String = "Fransk"
override val display_fr_CA: String = "Canadisk fransk"
override val display_gl: String = "Galicisk"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hebraisk"
override val display_hi: String = "Hindi"
override val display_hr: String = "Kroatisk"
override val display_hu: String = "Ungarsk"
override val display_hy: String = "Armensk"
override val display_id: String = "Indonesisk"
override val display_is: String = "Islandsk"
override val display_it: String = "Italiensk"
override val display_ja: String = "Japansk"
override val display_ka: String = "Georgisk"
override val display_kk: String = "Kasakhisk"
override val display_km: String = "Central Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Koreansk"
override val display_ky: String = "kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Litauisk"
override val display_lv: String = "Lettiske"
override val display_mk: String = "Makedonsk"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolsk"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malay"
override val display_my: String = "Burmesisk"
override val display_nb: String = "Norsk Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Hollandsk"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polsk"
override val display_pt: String = "Portugisisk"
override val display_pt_BR: String = "Portugisisk (Brasilien)"
override val display_pt_PT: String = "Europæisk portugisisk"
override val display_ro: String = "Rumænsk"
override val display_ru: String = "Russisk"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovakisk"
override val display_sl: String = "Slovensk"
override val display_sq: String = "Albansk"
override val display_sr: String = "Serbisk"
override val display_sv: String = "Svensk"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Tyrkisk"
override val display_uk: String = "Ukrainsk"
override val display_ur: String = "Urdu"
override val display_uz: String = "Usbekisk"
override val display_vi: String = "Vietnamesisk"
override val display_zh_Hant_TW: String = "Kinesisk (Taiwan, Traditionel)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Skøn"
override val document_invoice: String = "Faktura"
override val document_quote: String = "Tilbud"
override val done: String = "Udført"
override val download_app: String = "Download appen"
override val download_invoice: String = "Download Faktura"
override val download_pdf: String = "Download PDF"
override val draw_signature: String = "Tegn en signatur"
override val due: Formattable = Formattable { (date) -> "Forfaldsdato ${date}" }
override val due_date: String = "Forfaldsdato"
override val due_day: String = "på en dag"
override val due_label: String = "På"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} forfalder ${dueWhen}!" }
override val due_today: String = "i dag"
override val due_week: String = "på en uge"
override val duplicate: String = "Dupliker"
override val duration_day: String = "dag"
override val duration_month: String = "Måned"
override val duration_week: String = "uge"
override val duration_year: String = "år"
override val email: String = "E-mail"
override val email_blank_error: String = "E-mail påkrævet."
override val email_error: String = "Ugyldig e-mail-adresse."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Tak for din handel. For at se hele ${documentType} klik på knappen i slutningen af dette brev. Bemærk, at det er gyldigt indtil ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Du har modtaget en ny ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "SE ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Kære ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Tak for din handel. For at se hele ${documentType} klik på knappen i slutningen af dette brev. Betal venligst restbeløbet indtil ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Du har modtaget en ny ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Der er sendt en e-mail til nulstilling af adgangskode, se venligst din indbakke."
override val empty_clients_list_message: String = "Du har ingen kunder at spore. Tilføj en ny kunde."
override val empty_estimate_list_message: String = "Lav dit første overslag"
override val empty_expenses: String = "Du har ingen udgifter at spore. Tilføj en ny udgift."
override val empty_invoice_list_message: String = "Opret din første faktura"
override val empty_items_list_message: String = "Du har ingen varer, tilføj en ny vare!"
override val empty_signatures_list_message: String = "Ingen signaturer fundet. Opret en!"
override val empty_taxes_list_message: String = "Du har ingen skatteregler. Tilføj en ny skatteregel."
override val empty_templates_list_message: String = "Skabeloner er ikke tilgængelige."
override val enabled: String = "Aktiveret"
override val entry: String = "post"
override val equity: String = "egenkapital"
override val estimate_number: String = "Estimat #"
override val estimates: String = "Skøn"
override val estimates_report: String = "Skøn"
override val expense_bank_fees: String = "Bankgebyrer"
override val expense_benefits: String = "Fordele"
override val expense_communication: String = "Kommunikation"
override val expense_debt: String = "Gæld"
override val expense_deleted: String = "Udgift slettet"
override val expense_depreciation: String = "Afskrivninger"
override val expense_gifts: String = "Gaver"
override val expense_insurance: String = "Forsikring"
override val expense_inventory: String = "Opgørelse"
override val expense_legal: String = "Juridisk"
override val expense_maintenance: String = "Vedligeholdelse"
override val expense_marketing: String = "Markedsføring"
override val expense_meals: String = "Måltider"
override val expense_office_supplies: String = "Kontorartikler"
override val expense_rent: String = "Leje"
override val expense_salaries: String = "Lønninger"
override val expense_shopping: String = "Shopping"
override val expense_software: String = "Software"
override val expense_status_available: String = "Tilgængelig"
override val expense_status_processing: String = "Behandling"
override val expense_tax: String = "Skat"
override val expense_training: String = "Uddannelse"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Rejser"
override val expense_utility: String = "Forsyning"
override val expenses: String = "Udgifter"
override val feedback_option: String = "Feedback"
override val filter: String = "Filter"
override val first_name_hint: String = "Fornavn"
override val forgot_password: String = "Nulstil kodeord"
override val forgot_password_label: String = "Glemt adgangskode"
override val from: String = "Fra"
override val general_error: String = "Der opstod en fejl, prøv igen senere."
override val general_ledger: String = "hovedbog"
override val go_back_label: String = "Gå tilbage"
override val group_by: String = "Gruppe af:"
override val image_view: String = "Billede"
override val install: String = "Installer"
override val invalid_credentials_error: String = "Ugyldige legitimationsoplysninger."
override val invalid_email_error: String = "Ugyldig e-mail."
override val invalid_user_error: String = "Ugyldige brugeroplysninger."
override val invoice_deleted_message: String = "Faktura slettet"
override val invoice_menu: String = "Menuen Faktura"
override val invoice_number: String = "Faktura #"
override val invoices: String = "fakturaer"
override val items_option: String = "varer"
override val keep: String = "Hold"
override val language_selector: Formattable = Formattable { (valgt) -> "Sprog ${valgt}" }
override val last_name_hint: String = "Efternavn"
override val ledger: String = "regnskabsbog"
override val legal: String = "Juridisk"
override val legal_error: String = "Skal acceptere Servicevilkår og privatlivspolitik"
override val liabilities: String = "forpligtelser"
override val loading: String = "Indlæsning"
override val login_required_error: String = "Login påkrævet."
override val logo_view: String = "Logo"
override val logout: String = "Logout"
override val logout_prompt: String = "Dine data er ikke synkroniseret med skyen. Slet fakturaer, overslag, kunder og andre oplysninger ved logout?"
override val main_screen: String = "Hovedskærm"
override val mark_paid: String = "Markér som betalt"
override val message: String = "Meddelelse"
override val missing_sender_msg: String = "Et dokument uden afsenderoplysninger kan være ugyldigt."
override val missing_sender_title: String = "Manglende oplysninger om afsender"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "August"
override val month_august_short: String = "Aug."
override val month_december: String = "December"
override val month_december_short: String = "Dec."
override val month_february: String = "Februar"
override val month_february_short: String = "Feb."
override val month_january: String = "Januar"
override val month_january_short: String = "Jan."
override val month_july: String = "Juli"
override val month_july_short: String = "Juli"
override val month_june: String = "Juni"
override val month_june_short: String = "Juni"
override val month_march: String = "Marts"
override val month_march_short: String = "Marts"
override val month_may: String = "maj"
override val month_may_short: String = "Maj"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktober"
override val month_october_short: String = "Okt."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Det er ikke lykkedes at kontrollere."
override val new: String = "Ny"
override val news: String = "Nyheder"
override val next: String = "Næste"
override val no: String = "nej"
override val no_data_available: String = "Ingen data tilgængelige"
override val no_search_results: String = "Beklager, vi kunne ikke finde nogen resultater."
override val none: String = "Ingen"
override val note_fields: String = "Noter"
override val note_hint: String = "Bemærk"
override val notifications_option: String = "Meddelelser"
override val ok: String = "OK"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "De vigtigste dele af DingVoice er gratis for altid. Vores mission er at hjælpe dig med at få din virksomhed til at vokse, så hjælp os med at forbedre os ved at dele din feedback."
override val onboarding_aboutus_title: String = "Vi er her for at hjælpe"
override val onboarding_paid_1: String = "Få adgang til professionelt udseende skabeloner"
override val onboarding_paid_2: String = "Se nærmere på din økonomi"
override val onboarding_paid_3: String = "Få problemerne løst hurtigt"
override val onboarding_paid_4: String = "Hold dine dokumenter sikre"
override val onboarding_paid_5: String = "Hold dig opdateret med ændringer i fakturaens status"
override val onboarding_paid_title: String = "Vækst endnu hurtigere med..."
override val onboarding_paywall_message: String = "Få glæde af alle premium-funktionerne nu til den bedste pris."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Bare ${currencySymbol}${introAmount} for ${introDuration} ${introPeriod} og derefter ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (valutaSymbol, beløb, duration) -> "Bare ${valutaSymbol}${beløb} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis for ${trialDuration} ${trialPeriod} og derefter bare ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis for ${trialDuration} ${trialPeriod} derefter ${currencySymbol}${introductoryAmount} for ${introductoryDuration} ${introPeriod} og derefter bare ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Selvfornyende. Du kan annullere når som helst."
override val onboarding_paywall_restore: String = "Gendanne køb"
override val onboarding_paywall_restore_failure: String = "Gendannelse af køb er mislykkedes, prøv igen senere, eller kontakt support."
override val onboarding_paywall_restore_success: String = "Gendannelse lykkedes!"
override val onboarding_paywall_title: String = "Opnå storhed!"
override val onboarding_welcome_message: String = "Et komplet værktøjssæt til at administrere dine fakturaer og overslag."
override val onboarding_welcome_title: String = "Velkommen til DingVoice"
override val other_state: String = "Andet"
override val overdue_state: String = "Forsinket"
override val paid_state: String = "Betalt"
override val password: String = "Adgangskode"
override val password_blank_error: String = "Kodeord påkrævet."
override val password_mismatch_error: String = "Adgangskoderne stemmer ikke overens."
override val pay: String = "betal"
override val payment_instructions_label: String = "Betalingsvejledning"
override val payment_reminders: String = "Påmindelser om betaling"
override val phone_number_fields: String = "Telefon nummer"
override val phone_number_hint: String = "Telefon nummer"
override val postcode_hint: String = "Postnummer"
override val preview: String = "Forhåndsvisning"
override val preview_menu_hint: String = "Menu Vis udskrift"
override val price: String = "Pris"
override val privacy_policy: String = "Politik om beskyttelse af personlige oplysninger"
override val profile: String = "Profil"
override val profitable_client: String = "Din mest rentable kunde er..."
override val profits_report: String = "Fortjeneste"
override val promo_content: String = "Salgsfremmende indhold"
override val quantity_hint: String = "Antal"
override val quantity_label_hint: String = "Mængde Etiket"
override val query_info: String = "Query giver dig mulighed for at skrive brugerdefinerede beregninger, der henviser til både poster og andre skatteregler som input. Tilføj @-symbolet og en beskrivelse af varen eller afgiften for at linke til en anden post +, -, *, /, % - matematiske operatorer"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} blev modtaget af din klient." }
override val received_state: String = "Modtaget"
override val registration_code_fields: String = "Registreringskode"
override val reminder: String = "Påmindelse"
override val reports: String = "Rapporter"
override val reports_not_available: String = "Ingen rapporter tilgængelige, kom tilbage senere."
override val reveal_password: String = "Afslør adgangskode"
override val revenue: String = "indtægter"
override val sales_tax: String = "MOMS"
override val sales_tax_number: String = "MOMS nummer"
override val save: String = "Gem"
override val scan_document: String = "Scan dokument"
override val search: String = "Søg på"
override val select: String = "Vælg"
override val select_icon: String = "Vælg ikon"
override val select_signature: String = "Vælg signatur"
override val select_template: String = "Vælg skabelon"
override val send: String = "Send"
override val sent_state: String = "Sendt"
override val setup_sender: String = "Indstil afsender"
override val share_option: String = "Del"
override val ship_to: String = "Send til"
override val sign_in: String = "Log ind"
override val sign_in_apple: String = "Log på med Apple"
override val sign_in_email: String = "Log ind med e-mail"
override val sign_in_facebook: String = "Log på med Facebook"
override val sign_in_google: String = "Log på med Google"
override val sign_up: String = "Tilmeld dig"
override val sign_up_label: String = "Har du ikke en konto?"
override val sign_up_link_label: String = "Tilmeld dig!"
override val signatures_option: String = "Underskrifter"
override val skip: String = "Spring"
override val sold_items_report: String = "Solgte Varer"
override val sort_by: String = "Sorter efter:"
override val status_update: String = "Opdatering af dokumentstatus"
override val sub_annual: String = "Årlig"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/år" }
override val sub_backups: String = "Sikkerhedskopiering i skyen"
override val sub_insights: String = "Indsigt"
override val sub_month: String = "Månedlig"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/måned" }
override val sub_notifications: String = "Notifikationer"
override val sub_popup_message: String = "Abonnementer kommer snart. Se tilbage her om lidt."
override val sub_support: String = "Støt"
override val sub_templates: String = "Skabeloner"
override val subscribe: String = "Tilmeld dig"
override val subscription_header: String = "Få din virksomhed til at vokse med:"
override val subscription_option: String = "Abonnement"
override val subscriptions_load_fail: String = "Vi kan desværre ikke hente dine abonnementer på dette tidspunkt. Prøv igen senere, eller kontakt vores supportteam for at få hjælp."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "Subtotal"
override val sum: String = "Sum"
override val support_option: String = "Støtte"
override val switch_reports: String = "Skift Rapport"
override val tax: String = "SKAT"
override val tax_deleted_message: String = "Skat udgår"
override val tax_description_warning: String = "Hvis det anvendes, vises det på en faktura"
override val tax_input_flat: String = "Flad"
override val tax_input_percent: String = "Procent"
override val tax_number_fields: String = "Skattenummer"
override val tax_number_hint: String = "Skattenummer"
override val tax_query_info_description: String = "Oplysninger om forespørgsel"
override val tax_report: String = "Skat"
override val tax_rules_option: String = "Skatteregler"
override val taxed_clients_report: String = "Skattepligtige kunder"
override val template: String = "Skabelon"
override val template_selector_info: String = "Stryg for at ændre standardskabelonen"
override val template_unlock_promo: String = "Få adgang til flere skabeloner med Premium"
override val templates_option: String = "Skabeloner"
override val terms_of_service: String = "Betingelser for service"
override val thank_you: String = "Tak"
override val to: String = "Til"
override val total: Formattable = Formattable { (sum) -> "I alt: ${sum}" }
override val total_label: String = "I ALT"
override val undo_action: String = "Fortryd"
override val unit: String = "Enhed"
override val unit_cost_hint: String = "Enhedsomkostninger"
override val unknown_client: String = "Ukendt Klient"
override val unlock: String = "Lås op"
override val unnamed_item: String = "Unavngiven vare"
override val upgrade: String = "Opgradering"
override val user_collision_error: String = "Der findes allerede en konto."
override val valid_until: String = "Gyldig Indtil"
override val warning: String = "Advarsel!"
override val yes: String = "ja"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Accepter ${termsOfService} og ${privacyPolicy}" },
	"account" to "konto",
	"accounting" to "regnskab",
	"accruals" to "periodiseringer",
	"action_code_error" to "Ugyldig kode.",
	"add_client_action" to "Tilføj klient",
	"add_customer" to "Tilføj kunde",
	"add_expense" to "Tilføj udgifter",
	"add_fields_label" to "Tilføj:",
	"add_invoice_action" to "Opret",
	"add_item_action" to "Tilføj vare",
	"add_item_button" to "Tilføj vare",
	"add_signature_action" to "Tilføj signatur",
	"add_tax_action" to "Tilføj skat",
	"address_fields" to "Adresse",
	"address_line_hint" to "Adresse linje",
	"advanced" to "Avanceret",
	"agree_marketing" to "Jeg accepterer at modtage direkte marketingkommunikation",
	"all_notifications" to "Alle meddelelser",
	"amortization" to "amortisering",
	"amount" to "Beløb",
	"asset" to "aktiv",
	"attach_file" to "Vedhæft fil",
	"attachment" to "Vedhæftede fil",
	"auth_error" to "Handlingen mislykkedes, prøv igen.",
	"available_for_paid" to "Synkronisering i skyen er kun tilgængelig med et betalt abonnement.",
	"backups_option" to "Sikkerhedskopiering",
	"balance" to "saldo",
	"bank_account" to "Bankkonto",
	"bill_number" to "Regningsnummer",
	"cancel" to "Annuller",
	"cancel_trial" to "Annuller når som helst",
	"capital" to "kapital",
	"cash" to "kontanter",
	"categories" to "Kategorier",
	"category" to "Kategori",
	"category_add" to "Tilføj kategori",
	"category_delete" to "Slet kategori",
	"category_name" to "Navn på kategori",
	"change_language" to Formattable { (language) -> "Fakturasprog: ${language}" },
	"change_language_label" to "Fakturasprog",
	"change_template" to "Ændre skabelon",
	"city_hint" to "By",
	"clear" to "Klar",
	"client" to "Kunde",
	"client_actions" to "Kundeforanstaltninger",
	"client_deleted_message" to "Klient slettet",
	"clients_error_required_fields" to "Der kræves mindst én værdi.",
	"clients_option" to "Kunder",
	"close" to "Luk",
	"cloud_sync" to "Synkronisering i skyen",
	"coming_soon" to "Kommer snart!",
	"company_logo" to "Virksomhedens logo",
	"company_name_hint" to "Virksomhed",
	"confirm_password_label" to "Bekræft adgangskode",
	"confirmation_title" to "Er du sikker?",
	"convert_to_invoice" to "Konverter til faktura",
	"country_hint" to "Land",
	"create_client" to "Opret klient",
	"create_company" to "Opret virksomhed",
	"create_document" to "Opret dokument",
	"create_estimate" to "Skøn",
	"create_invoice" to "Faktura",
	"create_item" to "Opret Vare",
	"create_new_client" to "Opret ny klient",
	"create_new_item" to "Opret ny vare",
	"create_new_tax" to "Oprette ny afgift",
	"create_signature" to "Signatur",
	"created" to "Oprettet",
	"credit" to "kredit",
	"customer" to "Kunde",
	"date" to "Dato",
	"debit" to "debet",
	"debt" to "gæld",
	"default_currency" to Formattable { (currency) -> "Standardvaluta: ${currency}" },
	"default_currency_label" to "Standardvaluta",
	"delete" to "Slet",
	"delete_account" to "Slet Konto",
	"delete_confirmation_message" to "Alle dine data vil gå tabt.",
	"delete_item_title" to "Sletning af varen?",
	"delete_signature" to "Slet signatur",
	"depreciation" to "afskrivning",
	"description" to "Beskrivelse",
	"description_hint" to "Beskrivelse",
	"detailed_report" to "Detaljeret Rapport",
	"disabled" to "Handicappede",
	"discount" to "Rabat",
	"discount_hint" to "Rabat",
	"display_af" to "Afrikaans",
	"display_am" to "Amharisk",
	"display_ar" to "Arabisk",
	"display_as" to "Assamesisk",
	"display_az" to "Aserbajdsjansk",
	"display_be" to "Hviderussisk",
	"display_bg" to "Bulgarsk",
	"display_bn" to "Bengali",
	"display_bs" to "Bosnisk",
	"display_ca" to "Katalansk",
	"display_cs" to "Tjekkisk",
	"display_da" to "Dansk",
	"display_de" to "Tysk",
	"display_el" to "Græsk",
	"display_en" to "Engelsk",
	"display_es" to "Spansk",
	"display_es_MX" to "Spansk (Mexico)",
	"display_et" to "Estisk",
	"display_eu" to "Baskisk",
	"display_fa" to "Persisk",
	"display_fi" to "Finsk",
	"display_fil" to "Filippinsk",
	"display_fr" to "Fransk",
	"display_fr_CA" to "Canadisk fransk",
	"display_gl" to "Galicisk",
	"display_gu" to "Gujarati",
	"display_he" to "Hebraisk",
	"display_hi" to "Hindi",
	"display_hr" to "Kroatisk",
	"display_hu" to "Ungarsk",
	"display_hy" to "Armensk",
	"display_id" to "Indonesisk",
	"display_is" to "Islandsk",
	"display_it" to "Italiensk",
	"display_ja" to "Japansk",
	"display_ka" to "Georgisk",
	"display_kk" to "Kasakhisk",
	"display_km" to "Central Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Koreansk",
	"display_ky" to "kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Litauisk",
	"display_lv" to "Lettiske",
	"display_mk" to "Makedonsk",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolsk",
	"display_mr" to "Marathi",
	"display_ms" to "Malay",
	"display_my" to "Burmesisk",
	"display_nb" to "Norsk Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Hollandsk",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polsk",
	"display_pt" to "Portugisisk",
	"display_pt_BR" to "Portugisisk (Brasilien)",
	"display_pt_PT" to "Europæisk portugisisk",
	"display_ro" to "Rumænsk",
	"display_ru" to "Russisk",
	"display_si" to "Sinhala",
	"display_sk" to "Slovakisk",
	"display_sl" to "Slovensk",
	"display_sq" to "Albansk",
	"display_sr" to "Serbisk",
	"display_sv" to "Svensk",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Tyrkisk",
	"display_uk" to "Ukrainsk",
	"display_ur" to "Urdu",
	"display_uz" to "Usbekisk",
	"display_vi" to "Vietnamesisk",
	"display_zh_Hant_TW" to "Kinesisk (Taiwan, Traditionel)",
	"display_zu" to "Zulu",
	"document_estimate" to "Skøn",
	"document_invoice" to "Faktura",
	"document_quote" to "Tilbud",
	"done" to "Udført",
	"download_app" to "Download appen",
	"download_invoice" to "Download Faktura",
	"download_pdf" to "Download PDF",
	"draw_signature" to "Tegn en signatur",
	"due" to Formattable { (date) -> "Forfaldsdato ${date}" },
	"due_date" to "Forfaldsdato",
	"due_day" to "på en dag",
	"due_label" to "På",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} forfalder ${dueWhen}!" },
	"due_today" to "i dag",
	"due_week" to "på en uge",
	"duplicate" to "Dupliker",
	"duration_day" to "dag",
	"duration_month" to "Måned",
	"duration_week" to "uge",
	"duration_year" to "år",
	"email" to "E-mail",
	"email_blank_error" to "E-mail påkrævet.",
	"email_error" to "Ugyldig e-mail-adresse.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Tak for din handel. For at se hele ${documentType} klik på knappen i slutningen af dette brev. Bemærk, at det er gyldigt indtil ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Du har modtaget en ny ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "SE ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Kære ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Tak for din handel. For at se hele ${documentType} klik på knappen i slutningen af dette brev. Betal venligst restbeløbet indtil ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Du har modtaget en ny ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Der er sendt en e-mail til nulstilling af adgangskode, se venligst din indbakke.",
	"empty_clients_list_message" to "Du har ingen kunder at spore. Tilføj en ny kunde.",
	"empty_estimate_list_message" to "Lav dit første overslag",
	"empty_expenses" to "Du har ingen udgifter at spore. Tilføj en ny udgift.",
	"empty_invoice_list_message" to "Opret din første faktura",
	"empty_items_list_message" to "Du har ingen varer, tilføj en ny vare!",
	"empty_signatures_list_message" to "Ingen signaturer fundet. Opret en!",
	"empty_taxes_list_message" to "Du har ingen skatteregler. Tilføj en ny skatteregel.",
	"empty_templates_list_message" to "Skabeloner er ikke tilgængelige.",
	"enabled" to "Aktiveret",
	"entry" to "post",
	"equity" to "egenkapital",
	"estimate_number" to "Estimat #",
	"estimates" to "Skøn",
	"estimates_report" to "Skøn",
	"expense_bank_fees" to "Bankgebyrer",
	"expense_benefits" to "Fordele",
	"expense_communication" to "Kommunikation",
	"expense_debt" to "Gæld",
	"expense_deleted" to "Udgift slettet",
	"expense_depreciation" to "Afskrivninger",
	"expense_gifts" to "Gaver",
	"expense_insurance" to "Forsikring",
	"expense_inventory" to "Opgørelse",
	"expense_legal" to "Juridisk",
	"expense_maintenance" to "Vedligeholdelse",
	"expense_marketing" to "Markedsføring",
	"expense_meals" to "Måltider",
	"expense_office_supplies" to "Kontorartikler",
	"expense_rent" to "Leje",
	"expense_salaries" to "Lønninger",
	"expense_shopping" to "Shopping",
	"expense_software" to "Software",
	"expense_status_available" to "Tilgængelig",
	"expense_status_processing" to "Behandling",
	"expense_tax" to "Skat",
	"expense_training" to "Uddannelse",
	"expense_transport" to "Transport",
	"expense_travel" to "Rejser",
	"expense_utility" to "Forsyning",
	"expenses" to "Udgifter",
	"feedback_option" to "Feedback",
	"filter" to "Filter",
	"first_name_hint" to "Fornavn",
	"forgot_password" to "Nulstil kodeord",
	"forgot_password_label" to "Glemt adgangskode",
	"from" to "Fra",
	"general_error" to "Der opstod en fejl, prøv igen senere.",
	"general_ledger" to "hovedbog",
	"go_back_label" to "Gå tilbage",
	"group_by" to "Gruppe af:",
	"image_view" to "Billede",
	"install" to "Installer",
	"invalid_credentials_error" to "Ugyldige legitimationsoplysninger.",
	"invalid_email_error" to "Ugyldig e-mail.",
	"invalid_user_error" to "Ugyldige brugeroplysninger.",
	"invoice_deleted_message" to "Faktura slettet",
	"invoice_menu" to "Menuen Faktura",
	"invoice_number" to "Faktura #",
	"invoices" to "fakturaer",
	"items_option" to "varer",
	"keep" to "Hold",
	"language_selector" to Formattable { (valgt) -> "Sprog ${valgt}" },
	"last_name_hint" to "Efternavn",
	"ledger" to "regnskabsbog",
	"legal" to "Juridisk",
	"legal_error" to "Skal acceptere Servicevilkår og privatlivspolitik",
	"liabilities" to "forpligtelser",
	"loading" to "Indlæsning",
	"login_required_error" to "Login påkrævet.",
	"logo_view" to "Logo",
	"logout" to "Logout",
	"logout_prompt" to "Dine data er ikke synkroniseret med skyen. Slet fakturaer, overslag, kunder og andre oplysninger ved logout?",
	"main_screen" to "Hovedskærm",
	"mark_paid" to "Markér som betalt",
	"message" to "Meddelelse",
	"missing_sender_msg" to "Et dokument uden afsenderoplysninger kan være ugyldigt.",
	"missing_sender_title" to "Manglende oplysninger om afsender",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "August",
	"month_august_short" to "Aug.",
	"month_december" to "December",
	"month_december_short" to "Dec.",
	"month_february" to "Februar",
	"month_february_short" to "Feb.",
	"month_january" to "Januar",
	"month_january_short" to "Jan.",
	"month_july" to "Juli",
	"month_july_short" to "Juli",
	"month_june" to "Juni",
	"month_june_short" to "Juni",
	"month_march" to "Marts",
	"month_march_short" to "Marts",
	"month_may" to "maj",
	"month_may_short" to "Maj",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Oktober",
	"month_october_short" to "Okt.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Det er ikke lykkedes at kontrollere.",
	"new" to "Ny",
	"news" to "Nyheder",
	"next" to "Næste",
	"no" to "nej",
	"no_data_available" to "Ingen data tilgængelige",
	"no_search_results" to "Beklager, vi kunne ikke finde nogen resultater.",
	"none" to "Ingen",
	"note_fields" to "Noter",
	"note_hint" to "Bemærk",
	"notifications_option" to "Meddelelser",
	"ok" to "OK",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "De vigtigste dele af DingVoice er gratis for altid. Vores mission er at hjælpe dig med at få din virksomhed til at vokse, så hjælp os med at forbedre os ved at dele din feedback.",
	"onboarding_aboutus_title" to "Vi er her for at hjælpe",
	"onboarding_paid_1" to "Få adgang til professionelt udseende skabeloner",
	"onboarding_paid_2" to "Se nærmere på din økonomi",
	"onboarding_paid_3" to "Få problemerne løst hurtigt",
	"onboarding_paid_4" to "Hold dine dokumenter sikre",
	"onboarding_paid_5" to "Hold dig opdateret med ændringer i fakturaens status",
	"onboarding_paid_title" to "Vækst endnu hurtigere med...",
	"onboarding_paywall_message" to "Få glæde af alle premium-funktionerne nu til den bedste pris.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Bare ${currencySymbol}${introAmount} for ${introDuration} ${introPeriod} og derefter ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (valutaSymbol, beløb, duration) -> "Bare ${valutaSymbol}${beløb} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis for ${trialDuration} ${trialPeriod} og derefter bare ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis for ${trialDuration} ${trialPeriod} derefter ${currencySymbol}${introductoryAmount} for ${introductoryDuration} ${introPeriod} og derefter bare ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Selvfornyende. Du kan annullere når som helst.",
	"onboarding_paywall_restore" to "Gendanne køb",
	"onboarding_paywall_restore_failure" to "Gendannelse af køb er mislykkedes, prøv igen senere, eller kontakt support.",
	"onboarding_paywall_restore_success" to "Gendannelse lykkedes!",
	"onboarding_paywall_title" to "Opnå storhed!",
	"onboarding_welcome_message" to "Et komplet værktøjssæt til at administrere dine fakturaer og overslag.",
	"onboarding_welcome_title" to "Velkommen til DingVoice",
	"other_state" to "Andet",
	"overdue_state" to "Forsinket",
	"paid_state" to "Betalt",
	"password" to "Adgangskode",
	"password_blank_error" to "Kodeord påkrævet.",
	"password_mismatch_error" to "Adgangskoderne stemmer ikke overens.",
	"pay" to "betal",
	"payment_instructions_label" to "Betalingsvejledning",
	"payment_reminders" to "Påmindelser om betaling",
	"phone_number_fields" to "Telefon nummer",
	"phone_number_hint" to "Telefon nummer",
	"postcode_hint" to "Postnummer",
	"preview" to "Forhåndsvisning",
	"preview_menu_hint" to "Menu Vis udskrift",
	"price" to "Pris",
	"privacy_policy" to "Politik om beskyttelse af personlige oplysninger",
	"profile" to "Profil",
	"profitable_client" to "Din mest rentable kunde er...",
	"profits_report" to "Fortjeneste",
	"promo_content" to "Salgsfremmende indhold",
	"quantity_hint" to "Antal",
	"quantity_label_hint" to "Mængde Etiket",
	"query_info" to "Query giver dig mulighed for at skrive brugerdefinerede beregninger, der henviser til både poster og andre skatteregler som input. Tilføj @-symbolet og en beskrivelse af varen eller afgiften for at linke til en anden post +, -, *, /, % - matematiske operatorer",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} blev modtaget af din klient." },
	"received_state" to "Modtaget",
	"registration_code_fields" to "Registreringskode",
	"reminder" to "Påmindelse",
	"reports" to "Rapporter",
	"reports_not_available" to "Ingen rapporter tilgængelige, kom tilbage senere.",
	"reveal_password" to "Afslør adgangskode",
	"revenue" to "indtægter",
	"sales_tax" to "MOMS",
	"sales_tax_number" to "MOMS nummer",
	"save" to "Gem",
	"scan_document" to "Scan dokument",
	"search" to "Søg på",
	"select" to "Vælg",
	"select_icon" to "Vælg ikon",
	"select_signature" to "Vælg signatur",
	"select_template" to "Vælg skabelon",
	"send" to "Send",
	"sent_state" to "Sendt",
	"setup_sender" to "Indstil afsender",
	"share_option" to "Del",
	"ship_to" to "Send til",
	"sign_in" to "Log ind",
	"sign_in_apple" to "Log på med Apple",
	"sign_in_email" to "Log ind med e-mail",
	"sign_in_facebook" to "Log på med Facebook",
	"sign_in_google" to "Log på med Google",
	"sign_up" to "Tilmeld dig",
	"sign_up_label" to "Har du ikke en konto?",
	"sign_up_link_label" to "Tilmeld dig!",
	"signatures_option" to "Underskrifter",
	"skip" to "Spring",
	"sold_items_report" to "Solgte Varer",
	"sort_by" to "Sorter efter:",
	"status_update" to "Opdatering af dokumentstatus",
	"sub_annual" to "Årlig",
	"sub_annual_price" to Formattable { (price) -> "${price}/år" },
	"sub_backups" to "Sikkerhedskopiering i skyen",
	"sub_insights" to "Indsigt",
	"sub_month" to "Månedlig",
	"sub_monthly_price" to Formattable { (price) -> "${price}/måned" },
	"sub_notifications" to "Notifikationer",
	"sub_popup_message" to "Abonnementer kommer snart. Se tilbage her om lidt.",
	"sub_support" to "Støt",
	"sub_templates" to "Skabeloner",
	"subscribe" to "Tilmeld dig",
	"subscription_header" to "Få din virksomhed til at vokse med:",
	"subscription_option" to "Abonnement",
	"subscriptions_load_fail" to "Vi kan desværre ikke hente dine abonnementer på dette tidspunkt. Prøv igen senere, eller kontakt vores supportteam for at få hjælp.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "Subtotal",
	"sum" to "Sum",
	"support_option" to "Støtte",
	"switch_reports" to "Skift Rapport",
	"tax" to "SKAT",
	"tax_deleted_message" to "Skat udgår",
	"tax_description_warning" to "Hvis det anvendes, vises det på en faktura",
	"tax_input_flat" to "Flad",
	"tax_input_percent" to "Procent",
	"tax_number_fields" to "Skattenummer",
	"tax_number_hint" to "Skattenummer",
	"tax_query_info_description" to "Oplysninger om forespørgsel",
	"tax_report" to "Skat",
	"tax_rules_option" to "Skatteregler",
	"taxed_clients_report" to "Skattepligtige kunder",
	"template" to "Skabelon",
	"template_selector_info" to "Stryg for at ændre standardskabelonen",
	"template_unlock_promo" to "Få adgang til flere skabeloner med Premium",
	"templates_option" to "Skabeloner",
	"terms_of_service" to "Betingelser for service",
	"thank_you" to "Tak",
	"to" to "Til",
	"total" to Formattable { (sum) -> "I alt: ${sum}" },
	"total_label" to "I ALT",
	"undo_action" to "Fortryd",
	"unit" to "Enhed",
	"unit_cost_hint" to "Enhedsomkostninger",
	"unknown_client" to "Ukendt Klient",
	"unlock" to "Lås op",
	"unnamed_item" to "Unavngiven vare",
	"upgrade" to "Opgradering",
	"user_collision_error" to "Der findes allerede en konto.",
	"valid_until" to "Gyldig Indtil",
	"warning" to "Advarsel!",
	"yes" to "ja",
    )
    
}