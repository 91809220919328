// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ml  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService}, ${privacyPolicy} എന്നിവ അംഗീകരിക്കുക" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "അസാധുവായ കോഡ്."
override val add_client_action: String = "ക്ലയന്റ് ചേർക്കുക"
override val add_customer: String = "കസ്റ്റമറെ ചേർക്കുക"
override val add_expense: String = "ചെലവ് ചേർക്കുക"
override val add_fields_label: String = "ചേർക്കുക:"
override val add_invoice_action: String = "സൃഷ്ടിക്കുക"
override val add_item_action: String = "ഇനം ചേർക്കുക"
override val add_item_button: String = "ഇനം ചേർക്കുക"
override val add_signature_action: String = "ഒപ്പ് ചേർക്കുക"
override val add_tax_action: String = "നികുതി ചേർക്കുക"
override val address_fields: String = "വിലാസം"
override val address_line_hint: String = "വിലാസ രേഖ"
override val advanced: String = "പുരോഗമിച്ച"
override val agree_marketing: String = "ഡയറക്ട് മാർക്കറ്റിംഗ് കമ്മ്യൂണിക്കേഷനുകൾ സ്വീകരിക്കാൻ ഞാൻ സമ്മതിക്കുന്നു"
override val all_notifications: String = "എല്ലാ അറിയിപ്പുകളും"
override val amortization: String = "amortization"
override val amount: String = "തുക"
override val asset: String = "asset"
override val attach_file: String = "ഫയൽ അറ്റാച്ചുചെയ്യുക"
override val attachment: String = "ബന്ധം"
override val auth_error: String = "നടപടി പരാജയപ്പെട്ടു, വീണ്ടും ശ്രമിക്കുക."
override val available_for_paid: String = "പെയ്ഡ് സബ് സ് ക്രിപ്ഷന് കീഴിൽ മാത്രമാണ് ക്ലൗഡ് സിങ്ക് ലഭ്യമാവുക."
override val backups_option: String = "ബാക്കപ്പുകൾ"
override val balance: String = "balance"
override val bank_account: String = "ബാങ്ക് അക്കൗണ്ട്"
override val bill_number: String = "ബിൽ നമ്പർ"
override val cancel: String = "ക്യാൻസൽ ചെയ്യ്"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "വിഭാഗങ്ങൾ"
override val category: String = "വിഭാഗം"
override val category_add: String = "വിഭാഗം ചേർക്കുക"
override val category_delete: String = "വിഭാഗം ഇല്ലാതാക്കുക"
override val category_name: String = "വിഭാഗത്തിന്റെ പേര്"
override val change_language: Formattable = Formattable { (language) -> "ഇൻവോയ്സ് ഭാഷ: ${language}" }
override val change_language_label: String = "ഇൻവോയ്സ് ഭാഷ"
override val change_template: String = "ഫലകം മാറ്റുക"
override val city_hint: String = "നഗരം"
override val clear: String = "ക്ലിയർ"
override val client: String = "കക്ഷി"
override val client_actions: String = "ക്ലയന്റ് പ്രവർത്തനങ്ങൾ"
override val client_deleted_message: String = "ക്ലയന്റ് ഇല്ലാതാക്കി"
override val clients_error_required_fields: String = "കുറഞ്ഞത് ഒരു മൂല്യമെങ്കിലും ആവശ്യമാണ്."
override val clients_option: String = "ക്ലയന്റുകൾ"
override val close: String = "അടയ്ക്കുക"
override val cloud_sync: String = "മേഘസമന്വയം"
override val coming_soon: String = "ഉടൻ വരുന്നു!"
override val company_logo: String = "കമ്പനി ലോഗോ"
override val company_name_hint: String = "സംഘം"
override val confirm_password_label: String = "അടയാളവാക്ക് സ്ഥിരീകരിക്കുക"
override val confirmation_title: String = "നിങ്ങള്ക്ക് ഉറപ്പാണോ?"
override val convert_to_invoice: String = "ഇൻവോയ്സിലേക്ക് മാറ്റുക"
override val country_hint: String = "നാട്"
override val create_client: String = "ക്ലയന്റ് സൃഷ്ടിക്കുക"
override val create_company: String = "കമ്പനി സൃഷ്ടിക്കുക"
override val create_document: String = "പ്രമാണം സൃഷ്ടിക്കുക"
override val create_estimate: String = "എസ്റ്റിമേറ്റ്"
override val create_invoice: String = "ഇൻവോയ്സ്"
override val create_item: String = "ഇനം സൃഷ്ടിക്കുക"
override val create_new_client: String = "പുതിയ ക്ലയന്റ് സൃഷ്ടിക്കുക"
override val create_new_item: String = "പുതിയ ഇനം സൃഷ്ടിക്കുക"
override val create_new_tax: String = "പുതിയ നികുതി സൃഷ്ടിക്കുക"
override val create_signature: String = "ഒപ്പ്"
override val created: String = "സൃഷ്ടിച്ചത്"
override val credit: String = "credit"
override val customer: String = "കസ്റ്റമർ"
override val date: String = "ഈന്തപ്പന"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "സ്ഥിര കറൻസി: ${currency}" }
override val default_currency_label: String = "സ്ഥിര കറൻസി"
override val delete: String = "ഇല്ലാതാക്കുക"
override val delete_account: String = "അക്കൗണ്ട് ഇല്ലാതാക്കുക"
override val delete_confirmation_message: String = "നിങ്ങളുടെ എല്ലാ ഡാറ്റയും നഷ്ടപ്പെടും."
override val delete_item_title: String = "ഇനം ഇല്ലാതാക്കണോ?"
override val delete_signature: String = "ഒപ്പ് ഇല്ലാതാക്കുക"
override val depreciation: String = "depreciation"
override val description: String = "വിവരണം"
override val description_hint: String = "വിവരണം"
override val detailed_report: String = "വിശദമായ റിപ്പോർട്ട്"
override val disabled: String = "പ്രവർത്തനരഹിതം"
override val discount: String = "ഡിസ്കൗണ്ട്"
override val discount_hint: String = "ഡിസ്കൗണ്ട്"
override val display_af: String = "ആഫ്രിക്കക്കാർ"
override val display_am: String = "അംഹാരിക്"
override val display_ar: String = "അറബിക്"
override val display_as: String = "അസമീസ്"
override val display_az: String = "അസർബൈജാനി"
override val display_be: String = "ബെലറൂഷ്യൻ"
override val display_bg: String = "ബൾഗേറിയൻ"
override val display_bn: String = "ബംഗാളി"
override val display_bs: String = "ബോസ്നിയൻ"
override val display_ca: String = "കാറ്റലൻ"
override val display_cs: String = "ചെക്"
override val display_da: String = "ഡാനിഷ്"
override val display_de: String = "ജർമ്മൻ"
override val display_el: String = "ഗ്രീക്ക്"
override val display_en: String = "ഇംഗ്ലീഷ്"
override val display_es: String = "സ്പാനിഷ്"
override val display_es_MX: String = "സ്പാനിഷ് (മെക്സിക്കോ)"
override val display_et: String = "എസ്റ്റോണിയൻ"
override val display_eu: String = "ബാസ്ക്"
override val display_fa: String = "പേർഷ്യൻ"
override val display_fi: String = "ഫിന്നിഷ്"
override val display_fil: String = "ഫിലിപ്പിനോ"
override val display_fr: String = "ഫ്രഞ്ച്"
override val display_fr_CA: String = "കനേഡിയൻ ഫ്രഞ്ച്"
override val display_gl: String = "ഗലീഷ്യൻ"
override val display_gu: String = "ഗുജറാത്തി"
override val display_he: String = "ഹീബ്രു"
override val display_hi: String = "ഇല്ല"
override val display_hr: String = "ക്രോയേഷ്യൻ"
override val display_hu: String = "ഹംഗേറിയൻ"
override val display_hy: String = "അർമേനിയൻ"
override val display_id: String = "ഇന്തൊനേഷ്യന്‍"
override val display_is: String = "ഐസ്‌ലാൻഡിക്"
override val display_it: String = "ഇറ്റലിക്കാരൻ"
override val display_ja: String = "ജപ്പാൻകാരൻ"
override val display_ka: String = "ജോർജിയൻ"
override val display_kk: String = "കസാഖ്"
override val display_km: String = "സെൻട്രൽ ഖ്മെർ"
override val display_kn: String = "കന്നഡ"
override val display_ko: String = "കൊറിയൻ"
override val display_ky: String = "കിർഗിസ്"
override val display_lo: String = "ലാവോ"
override val display_lt: String = "ലിത്വാനിയൻ"
override val display_lv: String = "ലാത്വിയൻ"
override val display_mk: String = "മാസിഡോണിയന്‍"
override val display_ml: String = "മലയാളം"
override val display_mn: String = "മംഗോളിയൻ"
override val display_mr: String = "മറാഠി"
override val display_ms: String = "മലയ്"
override val display_my: String = "ബർമീസ്"
override val display_nb: String = "നോർവീജിയൻ ബോക്മാൽ"
override val display_ne: String = "നേപ്പാളി"
override val display_nl: String = "ഡച്ച്"
override val display_or: String = "ഒറിയ"
override val display_pa: String = "പഞ്ചാബി"
override val display_pl: String = "പോളിഷ്"
override val display_pt: String = "പോർച്ചുഗീസ്"
override val display_pt_BR: String = "പോർച്ചുഗീസ് (ബ്രസീൽ)"
override val display_pt_PT: String = "യൂറോപ്യൻ പോർച്ചുഗീസ്"
override val display_ro: String = "റൊമാനിയൻ"
override val display_ru: String = "റഷ്യൻ"
override val display_si: String = "സിംഹള"
override val display_sk: String = "സ്ലൊവാക്"
override val display_sl: String = "സ്ലൊവേനിയൻ"
override val display_sq: String = "അൽബേനിയൻ"
override val display_sr: String = "സെർബിയൻ"
override val display_sv: String = "സ്വീഡിഷ്"
override val display_sw: String = "സ്വാഹിലി"
override val display_ta: String = "തമിഴ്"
override val display_te: String = "തെലുങ്ക്"
override val display_th: String = "തായ്"
override val display_tr: String = "ടർക്കിഷ്"
override val display_uk: String = "ഉക്രേനിയൻ"
override val display_ur: String = "ഉറുദു"
override val display_uz: String = "ഉസ്ബെക്ക്"
override val display_vi: String = "വിയറ്റ്നാമീസ്"
override val display_zh_Hant_TW: String = "ചൈനീസ് (തായ്‌വാൻ, പരമ്പരാഗതം)"
override val display_zu: String = "സുളു"
override val document_estimate: String = "എസ്റ്റിമേറ്റ്"
override val document_invoice: String = "ഇൻവോയ്സ്"
override val document_quote: String = "ഉദ്ധരണി"
override val done: String = "പൂർത്തിയാക്കി"
override val download_app: String = "ആപ്പ് ഡൗൺലോഡ് ചെയ്യുക"
override val download_invoice: String = "ഇൻവോയ്സ് ഡൗൺലോഡ് ചെയ്യുക"
override val download_pdf: String = "PDF ഡൌണ് ലോഡ് ചെയ്യുക"
override val draw_signature: String = "ഒരു ഒപ്പ് വരയ്ക്കുക"
override val due: Formattable = Formattable { (date) -> "കുടിശ്ശിക ${date}" }
override val due_date: String = "അവസാന തീയതി"
override val due_day: String = "ഒരു ദിവസം കൊണ്ട്"
override val due_label: String = "കുടിശ്ശിക"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} എന്നത് ${dueWhen} ആണ്!" }
override val due_today: String = "ഇന്ന്"
override val due_week: String = "ഒരാഴ്ചയ്ക്കുള്ളിൽ"
override val duplicate: String = "ഡ്യൂപ്ലിക്കേറ്റ്"
override val duration_day: String = "ദിവസം"
override val duration_month: String = "മാസം"
override val duration_week: String = "ആഴ്ച"
override val duration_year: String = "വർഷം"
override val email: String = "ഇമെയില്"
override val email_blank_error: String = "ഇമെയിൽ ആവശ്യമാണ്."
override val email_error: String = "അസാധുവായ ഇമെയിൽ വിലാസം."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "നിങ്ങളുടെ ബിസിനസ്സിന് നന്ദി. മുഴുവൻ ${documentType} കാണുന്നതിന് ഈ കത്തിന്റെ അവസാനത്തിലുള്ള ബട്ടൺ ക്ലിക്ക് ചെയ്യുക. ഇത് ${dueDate} വരെ സാധുതയുള്ളതാണെന്ന് ശ്രദ്ധിക്കുക." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "നിങ്ങൾക്ക് പുതിയത് ലഭിച്ചു${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "കാണുക ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "പ്രിയ ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "നിങ്ങളുടെ ബിസിനസ്സിന് നന്ദി. മുഴുവൻ ${documentType} കാണുന്നതിന് ഈ കത്തിന്റെ അവസാനത്തിലുള്ള ബട്ടൺ ക്ലിക്ക് ചെയ്യുക. ${dueDate} വരെ ബാക്കി തുക അടയ്ക്കുക." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "നിങ്ങൾക്ക് പുതിയത് ലഭിച്ചു${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "അയച്ച പാസ് വേഡ് പുനഃക്രമീകരിക്കുന്നതിന് ഇമെയിൽ ചെയ്യുക, ദയവായി നിങ്ങളുടെ ഇൻബോക്സ് പരിശോധിക്കുക."
override val empty_clients_list_message: String = "നിങ്ങൾക്ക് ട്രാക്ക് ചെയ്യാൻ ക്ലയന്റുകളില്ല. ഒരു പുതിയ ക്ലയന്റ് ചേർക്കുക."
override val empty_estimate_list_message: String = "നിങ്ങളുടെ ആദ്യത്തെ എസ്റ്റിമേറ്റ് സൃഷ്ടിക്കുക"
override val empty_expenses: String = "ട്രാക്ക് ചെയ്യാൻ നിങ്ങൾക്ക് ചെലവുകളൊന്നുമില്ല. ഒരു പുതിയ ചെലവ് ചേർക്കുക."
override val empty_invoice_list_message: String = "നിങ്ങളുടെ ആദ്യത്തെ ഇൻവോയ്സ് സൃഷ്ടിക്കുക"
override val empty_items_list_message: String = "നിങ്ങൾക്ക് ഇനങ്ങൾ ഇല്ല, ഒരു പുതിയ ഇനം ചേർക്കുക!"
override val empty_signatures_list_message: String = "ഒപ്പുകൾ കണ്ടെത്തിയില്ല. ഒരെണ്ണം സൃഷ്ടിക്കൂ!"
override val empty_taxes_list_message: String = "നിങ്ങൾക്ക് നികുതി നിയമങ്ങളൊന്നുമില്ല. ഒരു പുതിയ നികുതി നിയമം ചേർക്കുക."
override val empty_templates_list_message: String = "ടെംപ്ലേറ്റുകൾ ലഭ്യമല്ല."
override val enabled: String = "പ്രവർത്തനക്ഷമമാക്കിയിരിക്കുന്നു"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "എസ്റ്റിമേറ്റ് #"
override val estimates: String = "എസ്റ്റിമേറ്റുകൾ"
override val estimates_report: String = "എസ്റ്റിമേറ്റുകൾ"
override val expense_bank_fees: String = "ബാങ്ക് ഫീസ്"
override val expense_benefits: String = "ആനുകൂല്യങ്ങൾ"
override val expense_communication: String = "ആശയവിനിമയം"
override val expense_debt: String = "കടം"
override val expense_deleted: String = "ചെലവ് ഇല്ലാതാക്കി"
override val expense_depreciation: String = "മൂല്യത്തകർച്ച"
override val expense_gifts: String = "സമ്മാനങ്ങൾ"
override val expense_insurance: String = "ഇൻഷുറൻസ്"
override val expense_inventory: String = "ഇൻവെന്ററി"
override val expense_legal: String = "നിയമപരമായ"
override val expense_maintenance: String = "മെയിന്റനൻസ്"
override val expense_marketing: String = "മാർക്കറ്റിംഗ്"
override val expense_meals: String = "ഭക്ഷണം"
override val expense_office_supplies: String = "ഓഫീസ് സാധനങ്ങൾ"
override val expense_rent: String = "വാടക"
override val expense_salaries: String = "ശമ്പളം"
override val expense_shopping: String = "ഷോപ്പിംഗ്"
override val expense_software: String = "സോഫ്റ്റ്വെയർ"
override val expense_status_available: String = "ലഭ്യമാണ്"
override val expense_status_processing: String = "പ്രോസസ്സിംഗ്"
override val expense_tax: String = "നികുതി"
override val expense_training: String = "പരിശീലനം"
override val expense_transport: String = "ഗതാഗതം"
override val expense_travel: String = "യാത്ര"
override val expense_utility: String = "യൂട്ടിലിറ്റി"
override val expenses: String = "ചെലവുകൾ"
override val feedback_option: String = "ഫീഡ്ബാക്ക്"
override val filter: String = "അരിപ്പ"
override val first_name_hint: String = "ആദ്യ പേര്"
override val forgot_password: String = "അടയാളവാക്ക് പുനഃക്രമീകരിക്കുക"
override val forgot_password_label: String = "പാസ് വേഡ് മറന്നു"
override val from: String = "നിന്ന്"
override val general_error: String = "പിശക് സംഭവിച്ചു, ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "തിരിച്ച് പോകൂ"
override val group_by: String = "ഗ്രൂപ്പ് ബൈ:"
override val image_view: String = "ചിത്രം"
override val install: String = "ഇൻസ്റ്റാൾ ചെയ്യുക"
override val invalid_credentials_error: String = "അസാധുവായ യോഗ്യതാപത്രങ്ങൾ."
override val invalid_email_error: String = "അസാധുവായ ഇമെയിൽ."
override val invalid_user_error: String = "അസാധുവായ ഉപയോക്തൃ ക്രെഡൻഷ്യലുകൾ."
override val invoice_deleted_message: String = "ഇൻവോയ്സ് ഇല്ലാതാക്കി"
override val invoice_menu: String = "ഇൻവോയ്സ് മെനു"
override val invoice_number: String = "ഇൻവോയ്സ് #"
override val invoices: String = "ഇൻവോയ്സുകൾ"
override val items_option: String = "ഇനങ്ങൾ"
override val keep: String = "സൂക്ഷിക്കുക"
override val language_selector: Formattable = Formattable { (selected) -> "ഭാഷ ${selected}" }
override val last_name_hint: String = "അവസാന പേര്"
override val ledger: String = "ledger"
override val legal: String = "നിയമപരം"
override val legal_error: String = "സേവന വ്യവസ്ഥകളും സ്വകാര്യതാ നയവും അംഗീകരിക്കണം"
override val liabilities: String = "liabilities"
override val loading: String = "ലോഡ് ചെയ്യുന്നു"
override val login_required_error: String = "ലോഗിൻ ആവശ്യമാണ്."
override val logo_view: String = "ലോഗോ"
override val logout: String = "ലോഗൗട്ട്"
override val logout_prompt: String = "നിങ്ങളുടെ ഡാറ്റ ക്ലൗഡുമായി സമന്വയിപ്പിച്ചിട്ടില്ല. ലോഗൗട്ടിനെക്കുറിച്ചുള്ള ഇൻവോയ്സുകൾ, എസ്റ്റിമേറ്റുകൾ, ക്ലയന്റുകൾ , മറ്റ് വിവരങ്ങൾ ഇല്ലാതാക്കുക?"
override val main_screen: String = "പ്രധാന സ്ക്രീൻ"
override val mark_paid: String = "പണമടച്ചതായി അടയാളപ്പെടുത്തുക"
override val message: String = "സന്ദേശം"
override val missing_sender_msg: String = "അയച്ചയാളുടെ വിവരങ്ങളില്ലാത്ത ഒരു പ്രമാണം അസാധുവായിരിക്കാം."
override val missing_sender_title: String = "അയച്ചയാളുടെ വിവരങ്ങൾ നഷ്‌ടമായി"
override val month_april: String = "ഏപ്രിൽ"
override val month_april_short: String = "ഏപ്രിൽ"
override val month_august: String = "ഓഗസ്റ്റ്"
override val month_august_short: String = "ആഗ"
override val month_december: String = "ഡിസംബര്"
override val month_december_short: String = "ഡിസംബർ."
override val month_february: String = "ഫെബ്രുവരി"
override val month_february_short: String = "ഫെബ്രുവരി."
override val month_january: String = "ജനുവരി"
override val month_january_short: String = "ജനു"
override val month_july: String = "ജൂലൈ"
override val month_july_short: String = "ജൂലൈ"
override val month_june: String = "ജൂൺ"
override val month_june_short: String = "ജൂൺ"
override val month_march: String = "മാർച്ച്"
override val month_march_short: String = "മാർച്ച്"
override val month_may: String = "മെയ്"
override val month_may_short: String = "മെയ്"
override val month_november: String = "നവംബർ"
override val month_november_short: String = "നവം."
override val month_october: String = "ഒക്ടോബർ"
override val month_october_short: String = "ഒക്ടോ."
override val month_september: String = "സെപ്റ്റംബർ"
override val month_september_short: String = "സെപ്തംബർ."
override val multifactor_error: String = "പരിശോധിച്ചുറപ്പിക്കുന്നതിൽ പരാജയപ്പെട്ടു."
override val new: String = "പുതിയത്"
override val news: String = "വാര് ത്ത"
override val next: String = "അടുത്തത്"
override val no: String = "no"
override val no_data_available: String = "ഡാറ്റ ലഭ്യമല്ല"
override val no_search_results: String = "ക്ഷമിക്കണം, ഞങ്ങൾക്ക് ഫലങ്ങളൊന്നും കണ്ടെത്താൻ കഴിഞ്ഞില്ല."
override val none: String = "ഒന്നുമില്ല"
override val note_fields: String = "കുറിപ്പുകൾ"
override val note_hint: String = "കുറിപ്പ്"
override val notifications_option: String = "അറിയിപ്പുകൾ"
override val ok: String = "ശരി"
override val onboarding: String = "ഓൺബോർഡിംഗ്"
override val onboarding_aboutus_message: String = "DingVoice-ന്റെ അവശ്യ ഭാഗങ്ങൾ എന്നേക്കും സൗജന്യമാണ്. നിങ്ങളുടെ ബിസിനസ്സ് വളർത്താൻ സഹായിക്കുക, നിങ്ങളുടെ ഫീഡ്‌ബാക്ക് പങ്കിടുന്നതിലൂടെ ഞങ്ങളെ മെച്ചപ്പെടുത്താൻ സഹായിക്കുക എന്നതാണ് ഞങ്ങളുടെ ലക്ഷ്യം."
override val onboarding_aboutus_title: String = "സഹായിക്കാൻ ഞങ്ങൾ ഇവിടെയുണ്ട്"
override val onboarding_paid_1: String = "പ്രൊഫഷണൽ ലുക്കിംഗ് ടെംപ്ലേറ്റുകൾ ആക്സസ് ചെയ്യുക"
override val onboarding_paid_2: String = "നിങ്ങളുടെ സാമ്പത്തിക സ്ഥിതി കൂടുതൽ ആഴത്തിൽ പരിശോധിക്കുക"
override val onboarding_paid_3: String = "പ്രശ്നങ്ങൾ വേഗത്തിൽ പരിഹരിക്കുക"
override val onboarding_paid_4: String = "നിങ്ങളുടെ പ്രമാണങ്ങൾ സുരക്ഷിതമായി സൂക്ഷിക്കുക"
override val onboarding_paid_5: String = "ഇൻവോയ്സ് സ്റ്റാറ്റസ് മാറ്റങ്ങളുമായി കാലികമായി തുടരുക"
override val onboarding_paid_title: String = "ഇതുപയോഗിച്ച് കൂടുതൽ വേഗത്തിൽ വളരുക..."
override val onboarding_paywall_message: String = "എല്ലാ പ്രീമിയം ഫീച്ചറുകളും ഇപ്പോൾ മികച്ച വിലയിൽ ആസ്വദിക്കൂ."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} എന്നതിന് ${currencySymbol}${introAmount}, തുടർന്ന് ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "വെറും ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod}-ന് സൗജന്യം, തുടർന്ന് ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} എന്നതിന് സൗജന്യം തുടർന്ന് ${introductoryDuration} ${introPeriod}-ന് ${currencySymbol}${introductoryAmount}, തുടർന്ന് വെറും ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "സ്വയമേവ പുതുക്കാവുന്നത്. എപ്പോൾ വേണമെങ്കിലും റദ്ദാക്കുക."
override val onboarding_paywall_restore: String = "വാങ്ങൽ പുനഃസ്ഥാപിക്കുക"
override val onboarding_paywall_restore_failure: String = "വാങ്ങലുകൾ പുനഃസ്ഥാപിക്കുന്നത് പരാജയപ്പെട്ടു, പിന്നീട് വീണ്ടും ശ്രമിക്കുക അല്ലെങ്കിൽ പിന്തുണയുമായി ബന്ധപ്പെടുക."
override val onboarding_paywall_restore_success: String = "പുനഃസ്ഥാപിക്കൽ വിജയിച്ചു!"
override val onboarding_paywall_title: String = "മഹത്വം കൈവരിക്കുക!"
override val onboarding_welcome_message: String = "നിങ്ങളുടെ ഇൻവോയ്‌സുകളും എസ്റ്റിമേറ്റുകളും നിയന്ത്രിക്കുന്നതിനുള്ള ഒരു സമ്പൂർണ്ണ ടൂൾസെറ്റ്."
override val onboarding_welcome_title: String = "DingVoice-ലേക്ക് സ്വാഗതം"
override val other_state: String = "മറ്റുള്ളവ"
override val overdue_state: String = "കാലഹരണപ്പെട്ട"
override val paid_state: String = "പണം നൽകി"
override val password: String = "അടയാളവാക്ക്"
override val password_blank_error: String = "പാസ് വേഡ് ആവശ്യമാണ്."
override val password_mismatch_error: String = "പാസ് വേഡുകൾ പൊരുത്തപ്പെടുന്നില്ല."
override val pay: String = "pay"
override val payment_instructions_label: String = "പേയ് മെന്റ് നിർദ്ദേശങ്ങൾ"
override val payment_reminders: String = "പേയ് മെന്റ് ഓർമ്മപ്പെടുത്തലുകൾ"
override val phone_number_fields: String = "ഫോൺ നമ്പർ"
override val phone_number_hint: String = "ഫോൺ നമ്പർ"
override val postcode_hint: String = "പോസ്റ്റ് കോഡ്"
override val preview: String = "തിരനോട്ടം"
override val preview_menu_hint: String = "പ്രിവ്യൂ മെനു"
override val price: String = "വില"
override val privacy_policy: String = "സ്വകാര്യതാ നയം"
override val profile: String = "പ്രൊഫൈൽ"
override val profitable_client: String = "നിങ്ങളുടെ ഏറ്റവും ലാഭകരമായ ക്ലയന്റ്..."
override val profits_report: String = "ലാഭം"
override val promo_content: String = "പ്രമോഷണൽ ഉള്ളടക്കം"
override val quantity_hint: String = "അളവ്"
override val quantity_label_hint: String = "അളവ് ലേബൽ"
override val query_info: String = "ഇനങ്ങളെയും മറ്റ് നികുതി നിയമങ്ങളെയും ഇൻപുട്ടുകളായി പരാമർശിച്ച് ഇഷ്ടാനുസൃത കണക്കുകൂട്ടലുകൾ എഴുതാൻ ക്വറി നിങ്ങളെ അനുവദിക്കുന്നു. മറ്റൊരു എൻട്രി +, -, *, /, % - ഗണിത ഓപ്പറേറ്റർമാരെ ലിങ്ക് ചെയ്യുന്നതിന് @ ചിഹ്നവും ഇനവും അല്ലെങ്കിൽ നികുതി വിവരണവും ചേർക്കുക"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "നിങ്ങളുടെ ക്ലയന്റിന് ${documentType} ${documentNumber} ലഭിച്ചു." }
override val received_state: String = "ലഭിച്ചു"
override val registration_code_fields: String = "രജിസ്ട്രേഷൻ കോഡ്"
override val reminder: String = "ഓർമ്മപ്പെടുത്തൽ"
override val reports: String = "റിപ്പോർട്ടുകൾ"
override val reports_not_available: String = "റിപ്പോര് ട്ടുകള് ലഭ്യമല്ല, പിന്നീട് വരൂ."
override val reveal_password: String = "അടയാളവാക്ക് വെളിപ്പെടുത്തുക"
override val revenue: String = "revenue"
override val sales_tax: String = "വാറ്റ്"
override val sales_tax_number: String = "VAT നമ്പർ"
override val save: String = "സൂക്ഷിയ്ക്കുക"
override val scan_document: String = "പ്രമാണം സ്കാൻ ചെയ്യുക"
override val search: String = "തിരയുക"
override val select: String = "തിരഞ്ഞെടുക്കുക"
override val select_icon: String = "ഐക്കൺ തിരഞ്ഞെടുക്കുക"
override val select_signature: String = "ഒപ്പ് തെരഞ്ഞെടുക്കുക"
override val select_template: String = "ഫലകം തെരഞ്ഞെടുക്കുക"
override val send: String = "അയയ്ക്കുക"
override val sent_state: String = "അയച്ചത്"
override val setup_sender: String = "അയച്ചയാളെ സജ്ജമാക്കുക"
override val share_option: String = "പങ്കിടുക"
override val ship_to: String = "അതിലേക്ക് കപ്പല് കയറ്റുക"
override val sign_in: String = "സൈൻ ഇൻ ചെയ്യുക"
override val sign_in_apple: String = "ആപ്പിളുമായി സൈൻ ഇൻ ചെയ്യുക"
override val sign_in_email: String = "ഇമെയിൽ ഉപയോഗിച്ച് സൈൻ ഇൻ ചെയ്യുക"
override val sign_in_facebook: String = "ഫെയ്സ്ബുക്കുമായി സൈൻ ഇൻ ചെയ്യുക"
override val sign_in_google: String = "ഗൂഗിളുമായി സൈൻ ഇൻ ചെയ്യുക"
override val sign_up: String = "സൈൻ അപ്പ് ചെയ്യുക"
override val sign_up_label: String = "അക്കൗണ്ട് ഇല്ലേ?"
override val sign_up_link_label: String = "സൈൻ അപ്പ് ചെയ്യുക!"
override val signatures_option: String = "ഒപ്പുകൾ"
override val skip: String = "ഒഴിവാക്കുക"
override val sold_items_report: String = "വിറ്റ ഇനങ്ങൾ"
override val sort_by: String = "അടുക്കുക:"
override val status_update: String = "ഡോക്യുമെന്റ് സ്റ്റാറ്റസ് അപ് ഡേറ്റ്"
override val sub_annual: String = "വാർഷികം"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/വർഷം" }
override val sub_backups: String = "ക്ലൗഡ് ബാക്കപ്പുകൾ"
override val sub_insights: String = "ഉൾക്കാഴ്ചകൾ"
override val sub_month: String = "പ്രതിമാസ"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/മാസം" }
override val sub_notifications: String = "അറിയിപ്പുകൾ"
override val sub_popup_message: String = "സബ്‌സ്‌ക്രിപ്‌ഷനുകൾ ഉടൻ വരുന്നു. കുറച്ച് സമയത്തിന് ശേഷം ഇവിടെ വീണ്ടും പരിശോധിക്കുക."
override val sub_support: String = "പിന്തുണ"
override val sub_templates: String = "ഫലകങ്ങൾ"
override val subscribe: String = "സബ് സ് ക്രൈബ് ചെയ്യുക"
override val subscription_header: String = "ഇനിപ്പറയുന്നവ ഉപയോഗിച്ച് നിങ്ങളുടെ ബിസിനസ്സ് വളർത്തുക:"
override val subscription_option: String = "വരിസംഖ്യ"
override val subscriptions_load_fail: String = "ക്ഷമിക്കണം, ഞങ്ങൾക്ക് ഇപ്പോൾ നിങ്ങളുടെ സബ്‌സ്‌ക്രിപ്‌ഷനുകൾ വീണ്ടെടുക്കാൻ കഴിയുന്നില്ല. ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക അല്ലെങ്കിൽ സഹായത്തിനായി ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുക."
override val subtotal: Formattable = Formattable { (sum) -> "ഉപടോട്ടൽ: ${sum}" }
override val subtotal_label: String = "സബ് ടോട്ടൽ"
override val sum: String = "സം"
override val support_option: String = "പിന്തുണ"
override val switch_reports: String = "റിപ്പോർട്ട് മാറ്റുക"
override val tax: String = "നികുതി"
override val tax_deleted_message: String = "നികുതി ഇല്ലാതാക്കി"
override val tax_description_warning: String = "ഉപയോഗിച്ചാൽ ഇത് ഒരു ഇൻവോയ്സിൽ കാണിക്കുന്നു"
override val tax_input_flat: String = "പരന്ന"
override val tax_input_percent: String = "ശതമാനം"
override val tax_number_fields: String = "നികുതി നമ്പർ"
override val tax_number_hint: String = "നികുതി നമ്പർ"
override val tax_query_info_description: String = "ക്വറി വിവരം"
override val tax_report: String = "നികുതി"
override val tax_rules_option: String = "നികുതി നിയമങ്ങൾ"
override val taxed_clients_report: String = "ടാക്സ്ഡ് ക്ലയന്റുകൾ"
override val template: String = "ഫലകം"
override val template_selector_info: String = "സഹജമായ ഫലകം മാറ്റുന്നതിന് സ്വൈപ്പ് ചെയ്യുക"
override val template_unlock_promo: String = "പ്രീമിയം ഉപയോഗിച്ച് കൂടുതൽ ഫലകങ്ങൾ ആക്സസ് ചെയ്യുക"
override val templates_option: String = "ഫലകങ്ങൾ"
override val terms_of_service: String = "സേവന വ്യവസ്ഥകൾ"
override val thank_you: String = "നന്ദി"
override val to: String = "ടു"
override val total: Formattable = Formattable { (sum) -> "ആകെ: ${sum}" }
override val total_label: String = "ആകെ"
override val undo_action: String = "നീക്കം ചെയ്യുക"
override val unit: String = "യൂണിറ്റ്"
override val unit_cost_hint: String = "യൂണിറ്റ് ചെലവ്"
override val unknown_client: String = "അജ്ഞാത ക്ലയന്റ്"
override val unlock: String = "അൺലോക്ക് ചെയ്യുക"
override val unnamed_item: String = "പേരിടാത്ത ഇനം"
override val upgrade: String = "നവീകരിക്കുക"
override val user_collision_error: String = "അക്കൗണ്ട് ഇതിനകം നിലവിലുണ്ട്."
override val valid_until: String = "വരെ സാധുവാണ്"
override val warning: String = "മുന്നറിയിപ്പ്!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService}, ${privacyPolicy} എന്നിവ അംഗീകരിക്കുക" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "അസാധുവായ കോഡ്.",
	"add_client_action" to "ക്ലയന്റ് ചേർക്കുക",
	"add_customer" to "കസ്റ്റമറെ ചേർക്കുക",
	"add_expense" to "ചെലവ് ചേർക്കുക",
	"add_fields_label" to "ചേർക്കുക:",
	"add_invoice_action" to "സൃഷ്ടിക്കുക",
	"add_item_action" to "ഇനം ചേർക്കുക",
	"add_item_button" to "ഇനം ചേർക്കുക",
	"add_signature_action" to "ഒപ്പ് ചേർക്കുക",
	"add_tax_action" to "നികുതി ചേർക്കുക",
	"address_fields" to "വിലാസം",
	"address_line_hint" to "വിലാസ രേഖ",
	"advanced" to "പുരോഗമിച്ച",
	"agree_marketing" to "ഡയറക്ട് മാർക്കറ്റിംഗ് കമ്മ്യൂണിക്കേഷനുകൾ സ്വീകരിക്കാൻ ഞാൻ സമ്മതിക്കുന്നു",
	"all_notifications" to "എല്ലാ അറിയിപ്പുകളും",
	"amortization" to "amortization",
	"amount" to "തുക",
	"asset" to "asset",
	"attach_file" to "ഫയൽ അറ്റാച്ചുചെയ്യുക",
	"attachment" to "ബന്ധം",
	"auth_error" to "നടപടി പരാജയപ്പെട്ടു, വീണ്ടും ശ്രമിക്കുക.",
	"available_for_paid" to "പെയ്ഡ് സബ് സ് ക്രിപ്ഷന് കീഴിൽ മാത്രമാണ് ക്ലൗഡ് സിങ്ക് ലഭ്യമാവുക.",
	"backups_option" to "ബാക്കപ്പുകൾ",
	"balance" to "balance",
	"bank_account" to "ബാങ്ക് അക്കൗണ്ട്",
	"bill_number" to "ബിൽ നമ്പർ",
	"cancel" to "ക്യാൻസൽ ചെയ്യ്",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "വിഭാഗങ്ങൾ",
	"category" to "വിഭാഗം",
	"category_add" to "വിഭാഗം ചേർക്കുക",
	"category_delete" to "വിഭാഗം ഇല്ലാതാക്കുക",
	"category_name" to "വിഭാഗത്തിന്റെ പേര്",
	"change_language" to Formattable { (language) -> "ഇൻവോയ്സ് ഭാഷ: ${language}" },
	"change_language_label" to "ഇൻവോയ്സ് ഭാഷ",
	"change_template" to "ഫലകം മാറ്റുക",
	"city_hint" to "നഗരം",
	"clear" to "ക്ലിയർ",
	"client" to "കക്ഷി",
	"client_actions" to "ക്ലയന്റ് പ്രവർത്തനങ്ങൾ",
	"client_deleted_message" to "ക്ലയന്റ് ഇല്ലാതാക്കി",
	"clients_error_required_fields" to "കുറഞ്ഞത് ഒരു മൂല്യമെങ്കിലും ആവശ്യമാണ്.",
	"clients_option" to "ക്ലയന്റുകൾ",
	"close" to "അടയ്ക്കുക",
	"cloud_sync" to "മേഘസമന്വയം",
	"coming_soon" to "ഉടൻ വരുന്നു!",
	"company_logo" to "കമ്പനി ലോഗോ",
	"company_name_hint" to "സംഘം",
	"confirm_password_label" to "അടയാളവാക്ക് സ്ഥിരീകരിക്കുക",
	"confirmation_title" to "നിങ്ങള്ക്ക് ഉറപ്പാണോ?",
	"convert_to_invoice" to "ഇൻവോയ്സിലേക്ക് മാറ്റുക",
	"country_hint" to "നാട്",
	"create_client" to "ക്ലയന്റ് സൃഷ്ടിക്കുക",
	"create_company" to "കമ്പനി സൃഷ്ടിക്കുക",
	"create_document" to "പ്രമാണം സൃഷ്ടിക്കുക",
	"create_estimate" to "എസ്റ്റിമേറ്റ്",
	"create_invoice" to "ഇൻവോയ്സ്",
	"create_item" to "ഇനം സൃഷ്ടിക്കുക",
	"create_new_client" to "പുതിയ ക്ലയന്റ് സൃഷ്ടിക്കുക",
	"create_new_item" to "പുതിയ ഇനം സൃഷ്ടിക്കുക",
	"create_new_tax" to "പുതിയ നികുതി സൃഷ്ടിക്കുക",
	"create_signature" to "ഒപ്പ്",
	"created" to "സൃഷ്ടിച്ചത്",
	"credit" to "credit",
	"customer" to "കസ്റ്റമർ",
	"date" to "ഈന്തപ്പന",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "സ്ഥിര കറൻസി: ${currency}" },
	"default_currency_label" to "സ്ഥിര കറൻസി",
	"delete" to "ഇല്ലാതാക്കുക",
	"delete_account" to "അക്കൗണ്ട് ഇല്ലാതാക്കുക",
	"delete_confirmation_message" to "നിങ്ങളുടെ എല്ലാ ഡാറ്റയും നഷ്ടപ്പെടും.",
	"delete_item_title" to "ഇനം ഇല്ലാതാക്കണോ?",
	"delete_signature" to "ഒപ്പ് ഇല്ലാതാക്കുക",
	"depreciation" to "depreciation",
	"description" to "വിവരണം",
	"description_hint" to "വിവരണം",
	"detailed_report" to "വിശദമായ റിപ്പോർട്ട്",
	"disabled" to "പ്രവർത്തനരഹിതം",
	"discount" to "ഡിസ്കൗണ്ട്",
	"discount_hint" to "ഡിസ്കൗണ്ട്",
	"display_af" to "ആഫ്രിക്കക്കാർ",
	"display_am" to "അംഹാരിക്",
	"display_ar" to "അറബിക്",
	"display_as" to "അസമീസ്",
	"display_az" to "അസർബൈജാനി",
	"display_be" to "ബെലറൂഷ്യൻ",
	"display_bg" to "ബൾഗേറിയൻ",
	"display_bn" to "ബംഗാളി",
	"display_bs" to "ബോസ്നിയൻ",
	"display_ca" to "കാറ്റലൻ",
	"display_cs" to "ചെക്",
	"display_da" to "ഡാനിഷ്",
	"display_de" to "ജർമ്മൻ",
	"display_el" to "ഗ്രീക്ക്",
	"display_en" to "ഇംഗ്ലീഷ്",
	"display_es" to "സ്പാനിഷ്",
	"display_es_MX" to "സ്പാനിഷ് (മെക്സിക്കോ)",
	"display_et" to "എസ്റ്റോണിയൻ",
	"display_eu" to "ബാസ്ക്",
	"display_fa" to "പേർഷ്യൻ",
	"display_fi" to "ഫിന്നിഷ്",
	"display_fil" to "ഫിലിപ്പിനോ",
	"display_fr" to "ഫ്രഞ്ച്",
	"display_fr_CA" to "കനേഡിയൻ ഫ്രഞ്ച്",
	"display_gl" to "ഗലീഷ്യൻ",
	"display_gu" to "ഗുജറാത്തി",
	"display_he" to "ഹീബ്രു",
	"display_hi" to "ഇല്ല",
	"display_hr" to "ക്രോയേഷ്യൻ",
	"display_hu" to "ഹംഗേറിയൻ",
	"display_hy" to "അർമേനിയൻ",
	"display_id" to "ഇന്തൊനേഷ്യന്‍",
	"display_is" to "ഐസ്‌ലാൻഡിക്",
	"display_it" to "ഇറ്റലിക്കാരൻ",
	"display_ja" to "ജപ്പാൻകാരൻ",
	"display_ka" to "ജോർജിയൻ",
	"display_kk" to "കസാഖ്",
	"display_km" to "സെൻട്രൽ ഖ്മെർ",
	"display_kn" to "കന്നഡ",
	"display_ko" to "കൊറിയൻ",
	"display_ky" to "കിർഗിസ്",
	"display_lo" to "ലാവോ",
	"display_lt" to "ലിത്വാനിയൻ",
	"display_lv" to "ലാത്വിയൻ",
	"display_mk" to "മാസിഡോണിയന്‍",
	"display_ml" to "മലയാളം",
	"display_mn" to "മംഗോളിയൻ",
	"display_mr" to "മറാഠി",
	"display_ms" to "മലയ്",
	"display_my" to "ബർമീസ്",
	"display_nb" to "നോർവീജിയൻ ബോക്മാൽ",
	"display_ne" to "നേപ്പാളി",
	"display_nl" to "ഡച്ച്",
	"display_or" to "ഒറിയ",
	"display_pa" to "പഞ്ചാബി",
	"display_pl" to "പോളിഷ്",
	"display_pt" to "പോർച്ചുഗീസ്",
	"display_pt_BR" to "പോർച്ചുഗീസ് (ബ്രസീൽ)",
	"display_pt_PT" to "യൂറോപ്യൻ പോർച്ചുഗീസ്",
	"display_ro" to "റൊമാനിയൻ",
	"display_ru" to "റഷ്യൻ",
	"display_si" to "സിംഹള",
	"display_sk" to "സ്ലൊവാക്",
	"display_sl" to "സ്ലൊവേനിയൻ",
	"display_sq" to "അൽബേനിയൻ",
	"display_sr" to "സെർബിയൻ",
	"display_sv" to "സ്വീഡിഷ്",
	"display_sw" to "സ്വാഹിലി",
	"display_ta" to "തമിഴ്",
	"display_te" to "തെലുങ്ക്",
	"display_th" to "തായ്",
	"display_tr" to "ടർക്കിഷ്",
	"display_uk" to "ഉക്രേനിയൻ",
	"display_ur" to "ഉറുദു",
	"display_uz" to "ഉസ്ബെക്ക്",
	"display_vi" to "വിയറ്റ്നാമീസ്",
	"display_zh_Hant_TW" to "ചൈനീസ് (തായ്‌വാൻ, പരമ്പരാഗതം)",
	"display_zu" to "സുളു",
	"document_estimate" to "എസ്റ്റിമേറ്റ്",
	"document_invoice" to "ഇൻവോയ്സ്",
	"document_quote" to "ഉദ്ധരണി",
	"done" to "പൂർത്തിയാക്കി",
	"download_app" to "ആപ്പ് ഡൗൺലോഡ് ചെയ്യുക",
	"download_invoice" to "ഇൻവോയ്സ് ഡൗൺലോഡ് ചെയ്യുക",
	"download_pdf" to "PDF ഡൌണ് ലോഡ് ചെയ്യുക",
	"draw_signature" to "ഒരു ഒപ്പ് വരയ്ക്കുക",
	"due" to Formattable { (date) -> "കുടിശ്ശിക ${date}" },
	"due_date" to "അവസാന തീയതി",
	"due_day" to "ഒരു ദിവസം കൊണ്ട്",
	"due_label" to "കുടിശ്ശിക",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} എന്നത് ${dueWhen} ആണ്!" },
	"due_today" to "ഇന്ന്",
	"due_week" to "ഒരാഴ്ചയ്ക്കുള്ളിൽ",
	"duplicate" to "ഡ്യൂപ്ലിക്കേറ്റ്",
	"duration_day" to "ദിവസം",
	"duration_month" to "മാസം",
	"duration_week" to "ആഴ്ച",
	"duration_year" to "വർഷം",
	"email" to "ഇമെയില്",
	"email_blank_error" to "ഇമെയിൽ ആവശ്യമാണ്.",
	"email_error" to "അസാധുവായ ഇമെയിൽ വിലാസം.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "നിങ്ങളുടെ ബിസിനസ്സിന് നന്ദി. മുഴുവൻ ${documentType} കാണുന്നതിന് ഈ കത്തിന്റെ അവസാനത്തിലുള്ള ബട്ടൺ ക്ലിക്ക് ചെയ്യുക. ഇത് ${dueDate} വരെ സാധുതയുള്ളതാണെന്ന് ശ്രദ്ധിക്കുക." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "നിങ്ങൾക്ക് പുതിയത് ലഭിച്ചു${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "കാണുക ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "പ്രിയ ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "നിങ്ങളുടെ ബിസിനസ്സിന് നന്ദി. മുഴുവൻ ${documentType} കാണുന്നതിന് ഈ കത്തിന്റെ അവസാനത്തിലുള്ള ബട്ടൺ ക്ലിക്ക് ചെയ്യുക. ${dueDate} വരെ ബാക്കി തുക അടയ്ക്കുക." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "നിങ്ങൾക്ക് പുതിയത് ലഭിച്ചു${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "അയച്ച പാസ് വേഡ് പുനഃക്രമീകരിക്കുന്നതിന് ഇമെയിൽ ചെയ്യുക, ദയവായി നിങ്ങളുടെ ഇൻബോക്സ് പരിശോധിക്കുക.",
	"empty_clients_list_message" to "നിങ്ങൾക്ക് ട്രാക്ക് ചെയ്യാൻ ക്ലയന്റുകളില്ല. ഒരു പുതിയ ക്ലയന്റ് ചേർക്കുക.",
	"empty_estimate_list_message" to "നിങ്ങളുടെ ആദ്യത്തെ എസ്റ്റിമേറ്റ് സൃഷ്ടിക്കുക",
	"empty_expenses" to "ട്രാക്ക് ചെയ്യാൻ നിങ്ങൾക്ക് ചെലവുകളൊന്നുമില്ല. ഒരു പുതിയ ചെലവ് ചേർക്കുക.",
	"empty_invoice_list_message" to "നിങ്ങളുടെ ആദ്യത്തെ ഇൻവോയ്സ് സൃഷ്ടിക്കുക",
	"empty_items_list_message" to "നിങ്ങൾക്ക് ഇനങ്ങൾ ഇല്ല, ഒരു പുതിയ ഇനം ചേർക്കുക!",
	"empty_signatures_list_message" to "ഒപ്പുകൾ കണ്ടെത്തിയില്ല. ഒരെണ്ണം സൃഷ്ടിക്കൂ!",
	"empty_taxes_list_message" to "നിങ്ങൾക്ക് നികുതി നിയമങ്ങളൊന്നുമില്ല. ഒരു പുതിയ നികുതി നിയമം ചേർക്കുക.",
	"empty_templates_list_message" to "ടെംപ്ലേറ്റുകൾ ലഭ്യമല്ല.",
	"enabled" to "പ്രവർത്തനക്ഷമമാക്കിയിരിക്കുന്നു",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "എസ്റ്റിമേറ്റ് #",
	"estimates" to "എസ്റ്റിമേറ്റുകൾ",
	"estimates_report" to "എസ്റ്റിമേറ്റുകൾ",
	"expense_bank_fees" to "ബാങ്ക് ഫീസ്",
	"expense_benefits" to "ആനുകൂല്യങ്ങൾ",
	"expense_communication" to "ആശയവിനിമയം",
	"expense_debt" to "കടം",
	"expense_deleted" to "ചെലവ് ഇല്ലാതാക്കി",
	"expense_depreciation" to "മൂല്യത്തകർച്ച",
	"expense_gifts" to "സമ്മാനങ്ങൾ",
	"expense_insurance" to "ഇൻഷുറൻസ്",
	"expense_inventory" to "ഇൻവെന്ററി",
	"expense_legal" to "നിയമപരമായ",
	"expense_maintenance" to "മെയിന്റനൻസ്",
	"expense_marketing" to "മാർക്കറ്റിംഗ്",
	"expense_meals" to "ഭക്ഷണം",
	"expense_office_supplies" to "ഓഫീസ് സാധനങ്ങൾ",
	"expense_rent" to "വാടക",
	"expense_salaries" to "ശമ്പളം",
	"expense_shopping" to "ഷോപ്പിംഗ്",
	"expense_software" to "സോഫ്റ്റ്വെയർ",
	"expense_status_available" to "ലഭ്യമാണ്",
	"expense_status_processing" to "പ്രോസസ്സിംഗ്",
	"expense_tax" to "നികുതി",
	"expense_training" to "പരിശീലനം",
	"expense_transport" to "ഗതാഗതം",
	"expense_travel" to "യാത്ര",
	"expense_utility" to "യൂട്ടിലിറ്റി",
	"expenses" to "ചെലവുകൾ",
	"feedback_option" to "ഫീഡ്ബാക്ക്",
	"filter" to "അരിപ്പ",
	"first_name_hint" to "ആദ്യ പേര്",
	"forgot_password" to "അടയാളവാക്ക് പുനഃക്രമീകരിക്കുക",
	"forgot_password_label" to "പാസ് വേഡ് മറന്നു",
	"from" to "നിന്ന്",
	"general_error" to "പിശക് സംഭവിച്ചു, ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക.",
	"general_ledger" to "general ledger",
	"go_back_label" to "തിരിച്ച് പോകൂ",
	"group_by" to "ഗ്രൂപ്പ് ബൈ:",
	"image_view" to "ചിത്രം",
	"install" to "ഇൻസ്റ്റാൾ ചെയ്യുക",
	"invalid_credentials_error" to "അസാധുവായ യോഗ്യതാപത്രങ്ങൾ.",
	"invalid_email_error" to "അസാധുവായ ഇമെയിൽ.",
	"invalid_user_error" to "അസാധുവായ ഉപയോക്തൃ ക്രെഡൻഷ്യലുകൾ.",
	"invoice_deleted_message" to "ഇൻവോയ്സ് ഇല്ലാതാക്കി",
	"invoice_menu" to "ഇൻവോയ്സ് മെനു",
	"invoice_number" to "ഇൻവോയ്സ് #",
	"invoices" to "ഇൻവോയ്സുകൾ",
	"items_option" to "ഇനങ്ങൾ",
	"keep" to "സൂക്ഷിക്കുക",
	"language_selector" to Formattable { (selected) -> "ഭാഷ ${selected}" },
	"last_name_hint" to "അവസാന പേര്",
	"ledger" to "ledger",
	"legal" to "നിയമപരം",
	"legal_error" to "സേവന വ്യവസ്ഥകളും സ്വകാര്യതാ നയവും അംഗീകരിക്കണം",
	"liabilities" to "liabilities",
	"loading" to "ലോഡ് ചെയ്യുന്നു",
	"login_required_error" to "ലോഗിൻ ആവശ്യമാണ്.",
	"logo_view" to "ലോഗോ",
	"logout" to "ലോഗൗട്ട്",
	"logout_prompt" to "നിങ്ങളുടെ ഡാറ്റ ക്ലൗഡുമായി സമന്വയിപ്പിച്ചിട്ടില്ല. ലോഗൗട്ടിനെക്കുറിച്ചുള്ള ഇൻവോയ്സുകൾ, എസ്റ്റിമേറ്റുകൾ, ക്ലയന്റുകൾ , മറ്റ് വിവരങ്ങൾ ഇല്ലാതാക്കുക?",
	"main_screen" to "പ്രധാന സ്ക്രീൻ",
	"mark_paid" to "പണമടച്ചതായി അടയാളപ്പെടുത്തുക",
	"message" to "സന്ദേശം",
	"missing_sender_msg" to "അയച്ചയാളുടെ വിവരങ്ങളില്ലാത്ത ഒരു പ്രമാണം അസാധുവായിരിക്കാം.",
	"missing_sender_title" to "അയച്ചയാളുടെ വിവരങ്ങൾ നഷ്‌ടമായി",
	"month_april" to "ഏപ്രിൽ",
	"month_april_short" to "ഏപ്രിൽ",
	"month_august" to "ഓഗസ്റ്റ്",
	"month_august_short" to "ആഗ",
	"month_december" to "ഡിസംബര്",
	"month_december_short" to "ഡിസംബർ.",
	"month_february" to "ഫെബ്രുവരി",
	"month_february_short" to "ഫെബ്രുവരി.",
	"month_january" to "ജനുവരി",
	"month_january_short" to "ജനു",
	"month_july" to "ജൂലൈ",
	"month_july_short" to "ജൂലൈ",
	"month_june" to "ജൂൺ",
	"month_june_short" to "ജൂൺ",
	"month_march" to "മാർച്ച്",
	"month_march_short" to "മാർച്ച്",
	"month_may" to "മെയ്",
	"month_may_short" to "മെയ്",
	"month_november" to "നവംബർ",
	"month_november_short" to "നവം.",
	"month_october" to "ഒക്ടോബർ",
	"month_october_short" to "ഒക്ടോ.",
	"month_september" to "സെപ്റ്റംബർ",
	"month_september_short" to "സെപ്തംബർ.",
	"multifactor_error" to "പരിശോധിച്ചുറപ്പിക്കുന്നതിൽ പരാജയപ്പെട്ടു.",
	"new" to "പുതിയത്",
	"news" to "വാര് ത്ത",
	"next" to "അടുത്തത്",
	"no" to "no",
	"no_data_available" to "ഡാറ്റ ലഭ്യമല്ല",
	"no_search_results" to "ക്ഷമിക്കണം, ഞങ്ങൾക്ക് ഫലങ്ങളൊന്നും കണ്ടെത്താൻ കഴിഞ്ഞില്ല.",
	"none" to "ഒന്നുമില്ല",
	"note_fields" to "കുറിപ്പുകൾ",
	"note_hint" to "കുറിപ്പ്",
	"notifications_option" to "അറിയിപ്പുകൾ",
	"ok" to "ശരി",
	"onboarding" to "ഓൺബോർഡിംഗ്",
	"onboarding_aboutus_message" to "DingVoice-ന്റെ അവശ്യ ഭാഗങ്ങൾ എന്നേക്കും സൗജന്യമാണ്. നിങ്ങളുടെ ബിസിനസ്സ് വളർത്താൻ സഹായിക്കുക, നിങ്ങളുടെ ഫീഡ്‌ബാക്ക് പങ്കിടുന്നതിലൂടെ ഞങ്ങളെ മെച്ചപ്പെടുത്താൻ സഹായിക്കുക എന്നതാണ് ഞങ്ങളുടെ ലക്ഷ്യം.",
	"onboarding_aboutus_title" to "സഹായിക്കാൻ ഞങ്ങൾ ഇവിടെയുണ്ട്",
	"onboarding_paid_1" to "പ്രൊഫഷണൽ ലുക്കിംഗ് ടെംപ്ലേറ്റുകൾ ആക്സസ് ചെയ്യുക",
	"onboarding_paid_2" to "നിങ്ങളുടെ സാമ്പത്തിക സ്ഥിതി കൂടുതൽ ആഴത്തിൽ പരിശോധിക്കുക",
	"onboarding_paid_3" to "പ്രശ്നങ്ങൾ വേഗത്തിൽ പരിഹരിക്കുക",
	"onboarding_paid_4" to "നിങ്ങളുടെ പ്രമാണങ്ങൾ സുരക്ഷിതമായി സൂക്ഷിക്കുക",
	"onboarding_paid_5" to "ഇൻവോയ്സ് സ്റ്റാറ്റസ് മാറ്റങ്ങളുമായി കാലികമായി തുടരുക",
	"onboarding_paid_title" to "ഇതുപയോഗിച്ച് കൂടുതൽ വേഗത്തിൽ വളരുക...",
	"onboarding_paywall_message" to "എല്ലാ പ്രീമിയം ഫീച്ചറുകളും ഇപ്പോൾ മികച്ച വിലയിൽ ആസ്വദിക്കൂ.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} എന്നതിന് ${currencySymbol}${introAmount}, തുടർന്ന് ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "വെറും ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod}-ന് സൗജന്യം, തുടർന്ന് ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} എന്നതിന് സൗജന്യം തുടർന്ന് ${introductoryDuration} ${introPeriod}-ന് ${currencySymbol}${introductoryAmount}, തുടർന്ന് വെറും ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "സ്വയമേവ പുതുക്കാവുന്നത്. എപ്പോൾ വേണമെങ്കിലും റദ്ദാക്കുക.",
	"onboarding_paywall_restore" to "വാങ്ങൽ പുനഃസ്ഥാപിക്കുക",
	"onboarding_paywall_restore_failure" to "വാങ്ങലുകൾ പുനഃസ്ഥാപിക്കുന്നത് പരാജയപ്പെട്ടു, പിന്നീട് വീണ്ടും ശ്രമിക്കുക അല്ലെങ്കിൽ പിന്തുണയുമായി ബന്ധപ്പെടുക.",
	"onboarding_paywall_restore_success" to "പുനഃസ്ഥാപിക്കൽ വിജയിച്ചു!",
	"onboarding_paywall_title" to "മഹത്വം കൈവരിക്കുക!",
	"onboarding_welcome_message" to "നിങ്ങളുടെ ഇൻവോയ്‌സുകളും എസ്റ്റിമേറ്റുകളും നിയന്ത്രിക്കുന്നതിനുള്ള ഒരു സമ്പൂർണ്ണ ടൂൾസെറ്റ്.",
	"onboarding_welcome_title" to "DingVoice-ലേക്ക് സ്വാഗതം",
	"other_state" to "മറ്റുള്ളവ",
	"overdue_state" to "കാലഹരണപ്പെട്ട",
	"paid_state" to "പണം നൽകി",
	"password" to "അടയാളവാക്ക്",
	"password_blank_error" to "പാസ് വേഡ് ആവശ്യമാണ്.",
	"password_mismatch_error" to "പാസ് വേഡുകൾ പൊരുത്തപ്പെടുന്നില്ല.",
	"pay" to "pay",
	"payment_instructions_label" to "പേയ് മെന്റ് നിർദ്ദേശങ്ങൾ",
	"payment_reminders" to "പേയ് മെന്റ് ഓർമ്മപ്പെടുത്തലുകൾ",
	"phone_number_fields" to "ഫോൺ നമ്പർ",
	"phone_number_hint" to "ഫോൺ നമ്പർ",
	"postcode_hint" to "പോസ്റ്റ് കോഡ്",
	"preview" to "തിരനോട്ടം",
	"preview_menu_hint" to "പ്രിവ്യൂ മെനു",
	"price" to "വില",
	"privacy_policy" to "സ്വകാര്യതാ നയം",
	"profile" to "പ്രൊഫൈൽ",
	"profitable_client" to "നിങ്ങളുടെ ഏറ്റവും ലാഭകരമായ ക്ലയന്റ്...",
	"profits_report" to "ലാഭം",
	"promo_content" to "പ്രമോഷണൽ ഉള്ളടക്കം",
	"quantity_hint" to "അളവ്",
	"quantity_label_hint" to "അളവ് ലേബൽ",
	"query_info" to "ഇനങ്ങളെയും മറ്റ് നികുതി നിയമങ്ങളെയും ഇൻപുട്ടുകളായി പരാമർശിച്ച് ഇഷ്ടാനുസൃത കണക്കുകൂട്ടലുകൾ എഴുതാൻ ക്വറി നിങ്ങളെ അനുവദിക്കുന്നു. മറ്റൊരു എൻട്രി +, -, *, /, % - ഗണിത ഓപ്പറേറ്റർമാരെ ലിങ്ക് ചെയ്യുന്നതിന് @ ചിഹ്നവും ഇനവും അല്ലെങ്കിൽ നികുതി വിവരണവും ചേർക്കുക",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "നിങ്ങളുടെ ക്ലയന്റിന് ${documentType} ${documentNumber} ലഭിച്ചു." },
	"received_state" to "ലഭിച്ചു",
	"registration_code_fields" to "രജിസ്ട്രേഷൻ കോഡ്",
	"reminder" to "ഓർമ്മപ്പെടുത്തൽ",
	"reports" to "റിപ്പോർട്ടുകൾ",
	"reports_not_available" to "റിപ്പോര് ട്ടുകള് ലഭ്യമല്ല, പിന്നീട് വരൂ.",
	"reveal_password" to "അടയാളവാക്ക് വെളിപ്പെടുത്തുക",
	"revenue" to "revenue",
	"sales_tax" to "വാറ്റ്",
	"sales_tax_number" to "VAT നമ്പർ",
	"save" to "സൂക്ഷിയ്ക്കുക",
	"scan_document" to "പ്രമാണം സ്കാൻ ചെയ്യുക",
	"search" to "തിരയുക",
	"select" to "തിരഞ്ഞെടുക്കുക",
	"select_icon" to "ഐക്കൺ തിരഞ്ഞെടുക്കുക",
	"select_signature" to "ഒപ്പ് തെരഞ്ഞെടുക്കുക",
	"select_template" to "ഫലകം തെരഞ്ഞെടുക്കുക",
	"send" to "അയയ്ക്കുക",
	"sent_state" to "അയച്ചത്",
	"setup_sender" to "അയച്ചയാളെ സജ്ജമാക്കുക",
	"share_option" to "പങ്കിടുക",
	"ship_to" to "അതിലേക്ക് കപ്പല് കയറ്റുക",
	"sign_in" to "സൈൻ ഇൻ ചെയ്യുക",
	"sign_in_apple" to "ആപ്പിളുമായി സൈൻ ഇൻ ചെയ്യുക",
	"sign_in_email" to "ഇമെയിൽ ഉപയോഗിച്ച് സൈൻ ഇൻ ചെയ്യുക",
	"sign_in_facebook" to "ഫെയ്സ്ബുക്കുമായി സൈൻ ഇൻ ചെയ്യുക",
	"sign_in_google" to "ഗൂഗിളുമായി സൈൻ ഇൻ ചെയ്യുക",
	"sign_up" to "സൈൻ അപ്പ് ചെയ്യുക",
	"sign_up_label" to "അക്കൗണ്ട് ഇല്ലേ?",
	"sign_up_link_label" to "സൈൻ അപ്പ് ചെയ്യുക!",
	"signatures_option" to "ഒപ്പുകൾ",
	"skip" to "ഒഴിവാക്കുക",
	"sold_items_report" to "വിറ്റ ഇനങ്ങൾ",
	"sort_by" to "അടുക്കുക:",
	"status_update" to "ഡോക്യുമെന്റ് സ്റ്റാറ്റസ് അപ് ഡേറ്റ്",
	"sub_annual" to "വാർഷികം",
	"sub_annual_price" to Formattable { (price) -> "${price}/വർഷം" },
	"sub_backups" to "ക്ലൗഡ് ബാക്കപ്പുകൾ",
	"sub_insights" to "ഉൾക്കാഴ്ചകൾ",
	"sub_month" to "പ്രതിമാസ",
	"sub_monthly_price" to Formattable { (price) -> "${price}/മാസം" },
	"sub_notifications" to "അറിയിപ്പുകൾ",
	"sub_popup_message" to "സബ്‌സ്‌ക്രിപ്‌ഷനുകൾ ഉടൻ വരുന്നു. കുറച്ച് സമയത്തിന് ശേഷം ഇവിടെ വീണ്ടും പരിശോധിക്കുക.",
	"sub_support" to "പിന്തുണ",
	"sub_templates" to "ഫലകങ്ങൾ",
	"subscribe" to "സബ് സ് ക്രൈബ് ചെയ്യുക",
	"subscription_header" to "ഇനിപ്പറയുന്നവ ഉപയോഗിച്ച് നിങ്ങളുടെ ബിസിനസ്സ് വളർത്തുക:",
	"subscription_option" to "വരിസംഖ്യ",
	"subscriptions_load_fail" to "ക്ഷമിക്കണം, ഞങ്ങൾക്ക് ഇപ്പോൾ നിങ്ങളുടെ സബ്‌സ്‌ക്രിപ്‌ഷനുകൾ വീണ്ടെടുക്കാൻ കഴിയുന്നില്ല. ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക അല്ലെങ്കിൽ സഹായത്തിനായി ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുക.",
	"subtotal" to Formattable { (sum) -> "ഉപടോട്ടൽ: ${sum}" },
	"subtotal_label" to "സബ് ടോട്ടൽ",
	"sum" to "സം",
	"support_option" to "പിന്തുണ",
	"switch_reports" to "റിപ്പോർട്ട് മാറ്റുക",
	"tax" to "നികുതി",
	"tax_deleted_message" to "നികുതി ഇല്ലാതാക്കി",
	"tax_description_warning" to "ഉപയോഗിച്ചാൽ ഇത് ഒരു ഇൻവോയ്സിൽ കാണിക്കുന്നു",
	"tax_input_flat" to "പരന്ന",
	"tax_input_percent" to "ശതമാനം",
	"tax_number_fields" to "നികുതി നമ്പർ",
	"tax_number_hint" to "നികുതി നമ്പർ",
	"tax_query_info_description" to "ക്വറി വിവരം",
	"tax_report" to "നികുതി",
	"tax_rules_option" to "നികുതി നിയമങ്ങൾ",
	"taxed_clients_report" to "ടാക്സ്ഡ് ക്ലയന്റുകൾ",
	"template" to "ഫലകം",
	"template_selector_info" to "സഹജമായ ഫലകം മാറ്റുന്നതിന് സ്വൈപ്പ് ചെയ്യുക",
	"template_unlock_promo" to "പ്രീമിയം ഉപയോഗിച്ച് കൂടുതൽ ഫലകങ്ങൾ ആക്സസ് ചെയ്യുക",
	"templates_option" to "ഫലകങ്ങൾ",
	"terms_of_service" to "സേവന വ്യവസ്ഥകൾ",
	"thank_you" to "നന്ദി",
	"to" to "ടു",
	"total" to Formattable { (sum) -> "ആകെ: ${sum}" },
	"total_label" to "ആകെ",
	"undo_action" to "നീക്കം ചെയ്യുക",
	"unit" to "യൂണിറ്റ്",
	"unit_cost_hint" to "യൂണിറ്റ് ചെലവ്",
	"unknown_client" to "അജ്ഞാത ക്ലയന്റ്",
	"unlock" to "അൺലോക്ക് ചെയ്യുക",
	"unnamed_item" to "പേരിടാത്ത ഇനം",
	"upgrade" to "നവീകരിക്കുക",
	"user_collision_error" to "അക്കൗണ്ട് ഇതിനകം നിലവിലുണ്ട്.",
	"valid_until" to "വരെ സാധുവാണ്",
	"warning" to "മുന്നറിയിപ്പ്!",
	"yes" to "yes",
    )
    
}