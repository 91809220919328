// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ur  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} اور ${privacyPolicy} کو قبول کریں" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "ناجائز ضابطہ."
override val add_client_action: String = "گاہک کا اضافہ کریں"
override val add_customer: String = "گاہک کا اضافہ کریں"
override val add_expense: String = "اخراجات شامل کریں۔"
override val add_fields_label: String = "اضافہ کرنا:"
override val add_invoice_action: String = "تخلیق"
override val add_item_action: String = "شے اضافہ کریں"
override val add_item_button: String = "شے اضافہ کریں"
override val add_signature_action: String = "دستخط اضافہ کریں"
override val add_tax_action: String = "ٹیکس شامل کریں"
override val address_fields: String = "پتہ"
override val address_line_hint: String = "پتا لائن"
override val advanced: String = "اعلی"
override val agree_marketing: String = "میں براہ راست مارکیٹنگ کمیونیکیشن حاصل کرنے سے اتفاق کرتا ہوں۔"
override val all_notifications: String = "تمام نوٹیفکیشن"
override val amortization: String = "amortization"
override val amount: String = "مقدار"
override val asset: String = "asset"
override val attach_file: String = "فائل منسلک"
override val attachment: String = "منسلکہ"
override val auth_error: String = "عمل ناکام، دوبارہ کوشش کریں۔"
override val available_for_paid: String = "کلاؤڈ سنک صرف پیڈ سبسکرپشن کے تحت دستیاب ہے۔"
override val backups_option: String = "پشتاروں"
override val balance: String = "balance"
override val bank_account: String = "بینک اکاؤنٹ"
override val bill_number: String = "بل نمبر"
override val cancel: String = "منسوخ"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "اقسام"
override val category: String = "قسم"
override val category_add: String = "زمرہ شامل کریں۔"
override val category_delete: String = "زمرہ حذف کریں۔"
override val category_name: String = "قسم کا نام"
override val change_language: Formattable = Formattable { (language) -> "رسید کی زبان: ${language}" }
override val change_language_label: String = "رسید کی زبان"
override val change_template: String = "سانچہ تبدیل کریں"
override val city_hint: String = "شہر"
override val clear: String = "صاف"
override val client: String = "کلائنٹ"
override val client_actions: String = "گاہک عمل"
override val client_deleted_message: String = "مؤکل حذف"
override val clients_error_required_fields: String = "کم از کم ایک قدر درکار ہے۔"
override val clients_option: String = "کلائنٹس"
override val close: String = "بند کرنا"
override val cloud_sync: String = "کلاؤڈ سنک"
override val coming_soon: String = "جلد واپسی!"
override val company_logo: String = "کمپنی لوگو"
override val company_name_hint: String = "کمپنی"
override val confirm_password_label: String = "پاس ورڈ کی تصدیق کریں"
override val confirmation_title: String = "کیا تمہیں یقین ہے؟"
override val convert_to_invoice: String = "انوائس میں تبدیل کریں"
override val country_hint: String = "ملک"
override val create_client: String = "گاہک بنائیں"
override val create_company: String = "کمپنی بنائیں"
override val create_document: String = "دستاویز بنائیں"
override val create_estimate: String = "اندازہ"
override val create_invoice: String = "اصل پٹی"
override val create_item: String = "شے بنائیں"
override val create_new_client: String = "نیا گاہک بنائیں"
override val create_new_item: String = "نئی شے بنائیں"
override val create_new_tax: String = "نیا ٹیکس بنائیں"
override val create_signature: String = "دستخط"
override val created: String = "بنائی"
override val credit: String = "credit"
override val customer: String = "مشتری"
override val date: String = "تاریخ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "ڈیفالٹ کرنسی: ${currency}" }
override val default_currency_label: String = "پہلے سے طے شدہ کرنسی"
override val delete: String = "مٹانا"
override val delete_account: String = "کھاتہ مٹا دو"
override val delete_confirmation_message: String = "آپ کا تمام ڈیٹا ضائع ہو جائے گا۔"
override val delete_item_title: String = "شے حذف کریں؟"
override val delete_signature: String = "دستخط حذف کریں"
override val depreciation: String = "depreciation"
override val description: String = "تفصیل"
override val description_hint: String = "بیان"
override val detailed_report: String = "تفصیلی رپورٹ"
override val disabled: String = "نااہل"
override val discount: String = "رعایت"
override val discount_hint: String = "رعایت"
override val display_af: String = "افریقی"
override val display_am: String = "امہاری"
override val display_ar: String = "عربی"
override val display_as: String = "آسامی"
override val display_az: String = "آذربائیجانی"
override val display_be: String = "بیلاروسی"
override val display_bg: String = "بلغاریائی"
override val display_bn: String = "بنگالی"
override val display_bs: String = "بوسنیائی"
override val display_ca: String = "کاتالان"
override val display_cs: String = "چیک"
override val display_da: String = "ڈینش"
override val display_de: String = "جرمن"
override val display_el: String = "یونانی"
override val display_en: String = "انگریزی"
override val display_es: String = "ہسپانوی"
override val display_es_MX: String = "ہسپانوی (میکسیکو)"
override val display_et: String = "اسٹونین"
override val display_eu: String = "باسکی"
override val display_fa: String = "فارسی"
override val display_fi: String = "فنش"
override val display_fil: String = "فلپائنی"
override val display_fr: String = "فرانسیسی"
override val display_fr_CA: String = "کینیڈین فرانسیسی"
override val display_gl: String = "گالیشین"
override val display_gu: String = "گجراتی"
override val display_he: String = "عبرانی"
override val display_hi: String = "نہیں."
override val display_hr: String = "کروشین"
override val display_hu: String = "ہنگری"
override val display_hy: String = "آرمینیائی"
override val display_id: String = "انڈونیشین"
override val display_is: String = "آئس لینڈی"
override val display_it: String = "اطالوی"
override val display_ja: String = "جاپانی"
override val display_ka: String = "جارجیائی"
override val display_kk: String = "قازق"
override val display_km: String = "وسطی خمیر"
override val display_kn: String = "کنڑ"
override val display_ko: String = "کورین"
override val display_ky: String = "کرغیز"
override val display_lo: String = "مزدور"
override val display_lt: String = "لتھوینیائی"
override val display_lv: String = "لیٹوین"
override val display_mk: String = "مقدونیائی"
override val display_ml: String = "ملیالم"
override val display_mn: String = "منگول"
override val display_mr: String = "مراٹھی"
override val display_ms: String = "مالائی"
override val display_my: String = "برمی"
override val display_nb: String = "نارویجن بوکمل"
override val display_ne: String = "نیپالی"
override val display_nl: String = "ڈچ"
override val display_or: String = "اوریا"
override val display_pa: String = "پنجابی"
override val display_pl: String = "پولش"
override val display_pt: String = "پرتگالی"
override val display_pt_BR: String = "پرتگالی (برازیل)"
override val display_pt_PT: String = "یورپی پرتگالی۔"
override val display_ro: String = "رومانیہ"
override val display_ru: String = "روسی"
override val display_si: String = "سنہالا"
override val display_sk: String = "سلوواک"
override val display_sl: String = "سلووینیائی"
override val display_sq: String = "البانوی"
override val display_sr: String = "سربیائی"
override val display_sv: String = "سویڈش"
override val display_sw: String = "سواحلی"
override val display_ta: String = "تامل"
override val display_te: String = "تیلگو"
override val display_th: String = "تھائی"
override val display_tr: String = "ترکی"
override val display_uk: String = "یوکرینی"
override val display_ur: String = "اردو"
override val display_uz: String = "ازبک"
override val display_vi: String = "ویتنامی"
override val display_zh_Hant_TW: String = "چینی (تائیوان، روایتی)"
override val display_zu: String = "زولو"
override val document_estimate: String = "اندازہ"
override val document_invoice: String = "اصل پٹی"
override val document_quote: String = "اقتباس"
override val done: String = "ہو گیا"
override val download_app: String = "ایپ ڈاؤن لوڈ کریں۔"
override val download_invoice: String = "انوائس ڈاؤن لوڈ کریں۔"
override val download_pdf: String = "پی ڈی ایف ڈاؤن لوڈ کریں"
override val draw_signature: String = "دستخط بنائیں"
override val due: Formattable = Formattable { (date) -> "واجب الادا ${date}" }
override val due_date: String = "مقررہ تاریخ"
override val due_day: String = "ایک دن میں"
override val due_label: String = "واجب"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} واجب الادا ہے ${dueWhen}!" }
override val due_today: String = "آج"
override val due_week: String = "ایک ہفتے میں"
override val duplicate: String = "نقل"
override val duration_day: String = "دن"
override val duration_month: String = "مہینہ"
override val duration_week: String = "ہفتہ"
override val duration_year: String = "سال"
override val email: String = "ای میل"
override val email_blank_error: String = "ای میل کی ضرورت ہے."
override val email_error: String = "ناجائز ای میل پتہ۔"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "آپ کے کاروبار کے لئے آپ کا شکریہ. مکمل ${documentType} دیکھنے کے لیے اس خط کے آخر میں بٹن پر کلک کریں۔ نوٹ کریں کہ یہ ${dueDate} تک درست ہے۔" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "آپ کو ایک نیا موصول ہوا ہے۔${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} دیکھیں" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "محترم ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "آپ کے کاروبار کے لئے آپ کا شکریہ. مکمل ${documentType} دیکھنے کے لیے اس خط کے آخر میں بٹن پر کلک کریں۔ براہ کرم ${dueDate} تک بیلنس ادا کریں۔" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "آپ کو ایک نیا موصول ہوا ہے۔${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "بھیجے گئے پاس ورڈ کو ری سیٹ کرنے کے لیے ای میل کریں، براہ کرم اپنا ان باکس چیک کریں۔"
override val empty_clients_list_message: String = "آپ کو ٹریک کرنے کے لئے کوئی گاہک نہیں ہے. ایک نیا گاہک شامل کریں۔"
override val empty_estimate_list_message: String = "اپنا پہلا تخمینہ بنائیں"
override val empty_expenses: String = "آپ کو ٹریک کرنے کے لئے کوئی اخراجات نہیں ہیں۔ ایک نیا خرچ شامل کریں۔"
override val empty_invoice_list_message: String = "اپنا پہلا انوائس بنائیں"
override val empty_items_list_message: String = "آپ کے پاس کوئی آئٹم نہیں ہے، ایک نئی شے شامل کریں!"
override val empty_signatures_list_message: String = "کوئی دستخط نہیں ملے۔ ایک بنائیں!"
override val empty_taxes_list_message: String = "آپ کے پاس ٹیکس کے کوئی قواعد نہیں ہیں۔ ایک نیا ٹیکس قاعدہ شامل کریں۔"
override val empty_templates_list_message: String = "سانچے دستیاب نہیں۔"
override val enabled: String = "فعال"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "تخمینہ #"
override val estimates: String = "اندازے"
override val estimates_report: String = "اندازے"
override val expense_bank_fees: String = "بینک فیس"
override val expense_benefits: String = "فوائد"
override val expense_communication: String = "مواصلات"
override val expense_debt: String = "قرضہ"
override val expense_deleted: String = "اخراجات کو حذف کر دیا گیا۔"
override val expense_depreciation: String = "فرسودگی"
override val expense_gifts: String = "تحفے"
override val expense_insurance: String = "انشورنس"
override val expense_inventory: String = "انوینٹری"
override val expense_legal: String = "قانونی"
override val expense_maintenance: String = "دیکھ بھال"
override val expense_marketing: String = "مارکیٹنگ"
override val expense_meals: String = "کھانے"
override val expense_office_supplies: String = "آفس کا سامان"
override val expense_rent: String = "کرایہ"
override val expense_salaries: String = "تنخواہیں"
override val expense_shopping: String = "خریداری"
override val expense_software: String = "سافٹ ویئر"
override val expense_status_available: String = "دستیاب"
override val expense_status_processing: String = "پروسیسنگ"
override val expense_tax: String = "ٹیکس"
override val expense_training: String = "تربیت"
override val expense_transport: String = "ٹرانسپورٹ"
override val expense_travel: String = "سفر"
override val expense_utility: String = "افادیت"
override val expenses: String = "اخراجات"
override val feedback_option: String = "رائے"
override val filter: String = "فلٹر"
override val first_name_hint: String = "پہلا نام"
override val forgot_password: String = "پاس ورڈ پھر سیٹ کریں"
override val forgot_password_label: String = "پاس ورڈ بھول گیا"
override val from: String = "سے"
override val general_error: String = "خرابی پیش آگئی، براہ کرم بعد میں دوبارہ کوشش کریں۔"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "واپس جاؤ"
override val group_by: String = "گروپ بمطابق:"
override val image_view: String = "روپ"
override val install: String = "انسٹال کریں"
override val invalid_credentials_error: String = "ناجائز اسناد."
override val invalid_email_error: String = "ناجائز ای میل۔"
override val invalid_user_error: String = "ناجائز صارف اسناد."
override val invoice_deleted_message: String = "انوائس حذف"
override val invoice_menu: String = "انوائس مینیو"
override val invoice_number: String = "اصل پٹی #"
override val invoices: String = "انوائسز"
override val items_option: String = "اشیا"
override val keep: String = "رکھنے"
override val language_selector: Formattable = Formattable { (selected) -> "زبان ${selected}" }
override val last_name_hint: String = "آخری نام"
override val ledger: String = "ledger"
override val legal: String = "قانونی"
override val legal_error: String = "خدمت اور رازداری کی پالیسی کی شرائط قبول کرنا ضروری ہے"
override val liabilities: String = "liabilities"
override val loading: String = "لوڈنگ"
override val login_required_error: String = "لاگ ان درکار ہے۔"
override val logo_view: String = "طغرہ"
override val logout: String = "لاگ آؤٹ کریں"
override val logout_prompt: String = "آپ کا کوائف کلاؤڈ کے ساتھ ہم وقت سازی نہیں ہے۔ لاگ آؤٹ پر انوائسز، تخمینہ، گاہکوں اور دیگر معلومات حذف کریں؟"
override val main_screen: String = "مرکزی سکرین"
override val mark_paid: String = "بطور ادائیگی نشان لگائیں"
override val message: String = "پیغام"
override val missing_sender_msg: String = "بھیجنے والے کی معلومات کے بغیر دستاویز غلط ہو سکتی ہے۔"
override val missing_sender_title: String = "بھیجنے والے کی معلومات غائب ہے۔"
override val month_april: String = "اپریل"
override val month_april_short: String = "اپریل"
override val month_august: String = "اگست"
override val month_august_short: String = "اگست"
override val month_december: String = "دسمبر"
override val month_december_short: String = "دسمبر"
override val month_february: String = "فروری"
override val month_february_short: String = "فروری"
override val month_january: String = "جنوری"
override val month_january_short: String = "جنوری"
override val month_july: String = "جولائی"
override val month_july_short: String = "جولائی"
override val month_june: String = "جون"
override val month_june_short: String = "جون"
override val month_march: String = "مارچ"
override val month_march_short: String = "مارچ"
override val month_may: String = "مئی"
override val month_may_short: String = "مئی"
override val month_november: String = "نومبر"
override val month_november_short: String = "نومبر"
override val month_october: String = "اکتوبر"
override val month_october_short: String = "اکتوبر"
override val month_september: String = "ستمبر"
override val month_september_short: String = "ستمبر"
override val multifactor_error: String = "تصدیق کرنے میں ناکام رہا۔"
override val new: String = "نئی"
override val news: String = "خبر"
override val next: String = "اگلے"
override val no: String = "no"
override val no_data_available: String = "مواد موجود نہیں ہے"
override val no_search_results: String = "معذرت، ہمیں کوئی نتیجہ نہیں مل سکا۔"
override val none: String = "کوئی نہیں۔"
override val note_fields: String = "نوٹ"
override val note_hint: String = "نوٹ"
override val notifications_option: String = "اطلاعات"
override val ok: String = "ٹھيک ہے"
override val onboarding: String = "آن بورڈنگ"
override val onboarding_aboutus_message: String = "DingVoice کے ضروری حصے ہمیشہ کے لیے مفت ہیں۔ ہمارا مشن آپ کے کاروبار کو بڑھانے میں مدد کرنا ہے، اپنے تاثرات کا اشتراک کرکے ہمیں بہتر بنانے میں مدد کرنا ہے۔"
override val onboarding_aboutus_title: String = "ہم مدد کے لیے حاضر ہیں۔"
override val onboarding_paid_1: String = "پیشہ ورانہ نظر آنے والے ٹیمپلیٹس تک رسائی حاصل کریں۔"
override val onboarding_paid_2: String = "اپنے مالی معاملات پر گہری نظر ڈالیں۔"
override val onboarding_paid_3: String = "مسائل کو تیزی سے حل کریں۔"
override val onboarding_paid_4: String = "اپنے دستاویزات کو محفوظ رکھیں"
override val onboarding_paid_5: String = "انوائس کی حیثیت کی تبدیلیوں کے ساتھ تازہ ترین رہیں"
override val onboarding_paid_title: String = "اس کے ساتھ اور بھی تیزی سے بڑھو..."
override val onboarding_paywall_message: String = "اب بہترین قیمت پر تمام پریمیم خصوصیات سے لطف اندوز ہوں۔"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} کے لیے صرف ${currencySymbol}${introAmount} اور پھر ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "صرف ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} کے لیے مفت اور پھر صرف ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "کے لیے مفت${trialDuration}${trialPeriod} پھر${currencySymbol}${introductoryAmount} کے لیے${introductoryDuration}${introPeriod} اور پھر بس${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "خودکار قابل تجدید۔ کسی بھی وقت منسوخ کریں۔"
override val onboarding_paywall_restore: String = "خریداری کو بحال کریں۔"
override val onboarding_paywall_restore_failure: String = "خریداریوں کو بحال کرنا ناکام ہو گیا، بعد میں دوبارہ کوشش کریں یا سپورٹ سے رابطہ کریں۔"
override val onboarding_paywall_restore_success: String = "بحال کامیاب!"
override val onboarding_paywall_title: String = "عظمت حاصل کرو!"
override val onboarding_welcome_message: String = "آپ کی رسیدوں اور تخمینوں کا نظم کرنے کے لیے ایک مکمل ٹول سیٹ۔"
override val onboarding_welcome_title: String = "DingVoice میں خوش آمدید"
override val other_state: String = "دیگر"
override val overdue_state: String = "موخر"
override val paid_state: String = "ادا"
override val password: String = "پاسورڈ"
override val password_blank_error: String = "پاس ورڈ درکار ہے۔"
override val password_mismatch_error: String = "پاس ورڈ میل نہیں کھاتے۔"
override val pay: String = "pay"
override val payment_instructions_label: String = "ادائیگی کی ہدایات"
override val payment_reminders: String = "ادائیگی یاد دہانی"
override val phone_number_fields: String = "فون نمبر"
override val phone_number_hint: String = "فون نمبر"
override val postcode_hint: String = "پوسٹ کوڈ"
override val preview: String = "پیش"
override val preview_menu_hint: String = "پیش منظر مینیو"
override val price: String = "قیمت"
override val privacy_policy: String = "رازداری کی پالیسی"
override val profile: String = "پروفائل"
override val profitable_client: String = "آپ کا سب سے زیادہ منافع بخش کلائنٹ ہے..."
override val profits_report: String = "منافع"
override val promo_content: String = "تشہیری مواد"
override val quantity_hint: String = "مقدار"
override val quantity_label_hint: String = "مقدار لیبل"
override val query_info: String = "طلب آپ کو اشیاء اور دیگر ٹیکس قواعد دونوں کو ان پٹ کے طور پر حوالہ دیتے ہوئے مخصوص حساب لکھنے کی اجازت دیتا ہے۔ کسی دوسرے اندراج +، *، /, ٪ - ریاضی آپریٹرز سے لنک کرنے کے لئے @ علامت اور شے یا ٹیکس کی وضاحت شامل کریں"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} آپ کے کلائنٹ کو موصول ہوا۔" }
override val received_state: String = "موصول ہوا۔"
override val registration_code_fields: String = "رجسٹریشن کوڈ"
override val reminder: String = "یاد دہانی"
override val reports: String = "رپورٹیں"
override val reports_not_available: String = "کوئی رپورٹ دستیاب نہیں، بعد میں واپس آئیں۔"
override val reveal_password: String = "پاس ورڈ ظاہر کریں"
override val revenue: String = "revenue"
override val sales_tax: String = "ویٹ"
override val sales_tax_number: String = "VAT نمبر"
override val save: String = "بچانا"
override val scan_document: String = "دستاویز اسکین کریں۔"
override val search: String = "تلاش"
override val select: String = "منتخب کریں۔"
override val select_icon: String = "آئیکن منتخب کریں۔"
override val select_signature: String = "دستخط منتخب کریں"
override val select_template: String = "سانچہ منتخب کریں"
override val send: String = "بھیجنا"
override val sent_state: String = "بھیجا"
override val setup_sender: String = "بھیجنے والے کو ترتیب دیں۔"
override val share_option: String = "حصہ"
override val ship_to: String = "کو بھیجیں۔"
override val sign_in: String = "سائن ان کریں"
override val sign_in_apple: String = "ایپل کے ساتھ سائن ان کریں"
override val sign_in_email: String = "ای میل کے ساتھ سائن ان کریں"
override val sign_in_facebook: String = "فیس بک کے ساتھ سائن ان کریں"
override val sign_in_google: String = "گوگل کے ساتھ سائن ان کریں"
override val sign_up: String = "سائن اپ کریں"
override val sign_up_label: String = "ایک اکاؤنٹ نہیں ہے؟"
override val sign_up_link_label: String = "سائن اپ کریں!"
override val signatures_option: String = "دستخط"
override val skip: String = "چھوڑ دو"
override val sold_items_report: String = "فروخت شدہ اشیاء"
override val sort_by: String = "چھانٹیں از:"
override val status_update: String = "دستاویز کی حیثیت کی تازہ کاری"
override val sub_annual: String = "سالانہ"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/سال" }
override val sub_backups: String = "کلاؤڈ بیک اپ"
override val sub_insights: String = "بصیرت"
override val sub_month: String = "ماہانہ"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ماہ" }
override val sub_notifications: String = "اطلاعات"
override val sub_popup_message: String = "سبسکرائب جلد ہی آ رہے ہیں. تھوڑی دیر میں یہاں واپس چیک کریں."
override val sub_support: String = "سپورٹ"
override val sub_templates: String = "تمثیلات"
override val subscribe: String = "رکنیت"
override val subscription_header: String = "اپنے کاروبار کو بڑھائیں:"
override val subscription_option: String = "اشتراک"
override val subscriptions_load_fail: String = "معذرت، ہم اس وقت آپ کی سبسکرپشنز کو بازیافت کرنے سے قاصر ہیں۔ براہ کرم بعد میں دوبارہ کوشش کریں یا مدد کے لیے ہماری سپورٹ ٹیم سے رابطہ کریں۔"
override val subtotal: Formattable = Formattable { (sum) -> "ذیلی کل: ${sum}" }
override val subtotal_label: String = "ذیلی کل"
override val sum: String = "رقم"
override val support_option: String = "معاونت"
override val switch_reports: String = "رپورٹ سوئچ کریں۔"
override val tax: String = "محصول"
override val tax_deleted_message: String = "ٹیکس حذف"
override val tax_description_warning: String = "اگر استعمال کیا جائے تو یہ انوائس پر ظاہر ہوتا ہے"
override val tax_input_flat: String = "فلیٹ"
override val tax_input_percent: String = "فیصد"
override val tax_number_fields: String = "ٹیکس نمبر"
override val tax_number_hint: String = "ٹیکس نمبر"
override val tax_query_info_description: String = "معلومات طلب کریں"
override val tax_report: String = "محصول"
override val tax_rules_option: String = "ٹیکس قواعد"
override val taxed_clients_report: String = "ٹیکس شدہ کلائنٹس"
override val template: String = "سانچا"
override val template_selector_info: String = "طے شدہ سانچا تبدیل کرنے کے لیے سوائپ کریں"
override val template_unlock_promo: String = "Premium کے ساتھ مزید ٹیمپلیٹس تک رسائی حاصل کریں۔"
override val templates_option: String = "سانچے"
override val terms_of_service: String = "سروس کی شرائط"
override val thank_you: String = "شکریہ"
override val to: String = "کو"
override val total: Formattable = Formattable { (sum) -> "کل: ${sum}" }
override val total_label: String = "کل"
override val undo_action: String = "کالعدم"
override val unit: String = "یونٹ"
override val unit_cost_hint: String = "یونٹ لاگت"
override val unknown_client: String = "نامعلوم کلائنٹ"
override val unlock: String = "غیر مقفل کریں۔"
override val unnamed_item: String = "بے نام آئٹم"
override val upgrade: String = "اپ گریڈ"
override val user_collision_error: String = "اکاؤنٹ پہلے ہی موجود ہے۔"
override val valid_until: String = "قابل قبول جب تک"
override val warning: String = "اشارہ!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} اور ${privacyPolicy} کو قبول کریں" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "ناجائز ضابطہ.",
	"add_client_action" to "گاہک کا اضافہ کریں",
	"add_customer" to "گاہک کا اضافہ کریں",
	"add_expense" to "اخراجات شامل کریں۔",
	"add_fields_label" to "اضافہ کرنا:",
	"add_invoice_action" to "تخلیق",
	"add_item_action" to "شے اضافہ کریں",
	"add_item_button" to "شے اضافہ کریں",
	"add_signature_action" to "دستخط اضافہ کریں",
	"add_tax_action" to "ٹیکس شامل کریں",
	"address_fields" to "پتہ",
	"address_line_hint" to "پتا لائن",
	"advanced" to "اعلی",
	"agree_marketing" to "میں براہ راست مارکیٹنگ کمیونیکیشن حاصل کرنے سے اتفاق کرتا ہوں۔",
	"all_notifications" to "تمام نوٹیفکیشن",
	"amortization" to "amortization",
	"amount" to "مقدار",
	"asset" to "asset",
	"attach_file" to "فائل منسلک",
	"attachment" to "منسلکہ",
	"auth_error" to "عمل ناکام، دوبارہ کوشش کریں۔",
	"available_for_paid" to "کلاؤڈ سنک صرف پیڈ سبسکرپشن کے تحت دستیاب ہے۔",
	"backups_option" to "پشتاروں",
	"balance" to "balance",
	"bank_account" to "بینک اکاؤنٹ",
	"bill_number" to "بل نمبر",
	"cancel" to "منسوخ",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "اقسام",
	"category" to "قسم",
	"category_add" to "زمرہ شامل کریں۔",
	"category_delete" to "زمرہ حذف کریں۔",
	"category_name" to "قسم کا نام",
	"change_language" to Formattable { (language) -> "رسید کی زبان: ${language}" },
	"change_language_label" to "رسید کی زبان",
	"change_template" to "سانچہ تبدیل کریں",
	"city_hint" to "شہر",
	"clear" to "صاف",
	"client" to "کلائنٹ",
	"client_actions" to "گاہک عمل",
	"client_deleted_message" to "مؤکل حذف",
	"clients_error_required_fields" to "کم از کم ایک قدر درکار ہے۔",
	"clients_option" to "کلائنٹس",
	"close" to "بند کرنا",
	"cloud_sync" to "کلاؤڈ سنک",
	"coming_soon" to "جلد واپسی!",
	"company_logo" to "کمپنی لوگو",
	"company_name_hint" to "کمپنی",
	"confirm_password_label" to "پاس ورڈ کی تصدیق کریں",
	"confirmation_title" to "کیا تمہیں یقین ہے؟",
	"convert_to_invoice" to "انوائس میں تبدیل کریں",
	"country_hint" to "ملک",
	"create_client" to "گاہک بنائیں",
	"create_company" to "کمپنی بنائیں",
	"create_document" to "دستاویز بنائیں",
	"create_estimate" to "اندازہ",
	"create_invoice" to "اصل پٹی",
	"create_item" to "شے بنائیں",
	"create_new_client" to "نیا گاہک بنائیں",
	"create_new_item" to "نئی شے بنائیں",
	"create_new_tax" to "نیا ٹیکس بنائیں",
	"create_signature" to "دستخط",
	"created" to "بنائی",
	"credit" to "credit",
	"customer" to "مشتری",
	"date" to "تاریخ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "ڈیفالٹ کرنسی: ${currency}" },
	"default_currency_label" to "پہلے سے طے شدہ کرنسی",
	"delete" to "مٹانا",
	"delete_account" to "کھاتہ مٹا دو",
	"delete_confirmation_message" to "آپ کا تمام ڈیٹا ضائع ہو جائے گا۔",
	"delete_item_title" to "شے حذف کریں؟",
	"delete_signature" to "دستخط حذف کریں",
	"depreciation" to "depreciation",
	"description" to "تفصیل",
	"description_hint" to "بیان",
	"detailed_report" to "تفصیلی رپورٹ",
	"disabled" to "نااہل",
	"discount" to "رعایت",
	"discount_hint" to "رعایت",
	"display_af" to "افریقی",
	"display_am" to "امہاری",
	"display_ar" to "عربی",
	"display_as" to "آسامی",
	"display_az" to "آذربائیجانی",
	"display_be" to "بیلاروسی",
	"display_bg" to "بلغاریائی",
	"display_bn" to "بنگالی",
	"display_bs" to "بوسنیائی",
	"display_ca" to "کاتالان",
	"display_cs" to "چیک",
	"display_da" to "ڈینش",
	"display_de" to "جرمن",
	"display_el" to "یونانی",
	"display_en" to "انگریزی",
	"display_es" to "ہسپانوی",
	"display_es_MX" to "ہسپانوی (میکسیکو)",
	"display_et" to "اسٹونین",
	"display_eu" to "باسکی",
	"display_fa" to "فارسی",
	"display_fi" to "فنش",
	"display_fil" to "فلپائنی",
	"display_fr" to "فرانسیسی",
	"display_fr_CA" to "کینیڈین فرانسیسی",
	"display_gl" to "گالیشین",
	"display_gu" to "گجراتی",
	"display_he" to "عبرانی",
	"display_hi" to "نہیں.",
	"display_hr" to "کروشین",
	"display_hu" to "ہنگری",
	"display_hy" to "آرمینیائی",
	"display_id" to "انڈونیشین",
	"display_is" to "آئس لینڈی",
	"display_it" to "اطالوی",
	"display_ja" to "جاپانی",
	"display_ka" to "جارجیائی",
	"display_kk" to "قازق",
	"display_km" to "وسطی خمیر",
	"display_kn" to "کنڑ",
	"display_ko" to "کورین",
	"display_ky" to "کرغیز",
	"display_lo" to "مزدور",
	"display_lt" to "لتھوینیائی",
	"display_lv" to "لیٹوین",
	"display_mk" to "مقدونیائی",
	"display_ml" to "ملیالم",
	"display_mn" to "منگول",
	"display_mr" to "مراٹھی",
	"display_ms" to "مالائی",
	"display_my" to "برمی",
	"display_nb" to "نارویجن بوکمل",
	"display_ne" to "نیپالی",
	"display_nl" to "ڈچ",
	"display_or" to "اوریا",
	"display_pa" to "پنجابی",
	"display_pl" to "پولش",
	"display_pt" to "پرتگالی",
	"display_pt_BR" to "پرتگالی (برازیل)",
	"display_pt_PT" to "یورپی پرتگالی۔",
	"display_ro" to "رومانیہ",
	"display_ru" to "روسی",
	"display_si" to "سنہالا",
	"display_sk" to "سلوواک",
	"display_sl" to "سلووینیائی",
	"display_sq" to "البانوی",
	"display_sr" to "سربیائی",
	"display_sv" to "سویڈش",
	"display_sw" to "سواحلی",
	"display_ta" to "تامل",
	"display_te" to "تیلگو",
	"display_th" to "تھائی",
	"display_tr" to "ترکی",
	"display_uk" to "یوکرینی",
	"display_ur" to "اردو",
	"display_uz" to "ازبک",
	"display_vi" to "ویتنامی",
	"display_zh_Hant_TW" to "چینی (تائیوان، روایتی)",
	"display_zu" to "زولو",
	"document_estimate" to "اندازہ",
	"document_invoice" to "اصل پٹی",
	"document_quote" to "اقتباس",
	"done" to "ہو گیا",
	"download_app" to "ایپ ڈاؤن لوڈ کریں۔",
	"download_invoice" to "انوائس ڈاؤن لوڈ کریں۔",
	"download_pdf" to "پی ڈی ایف ڈاؤن لوڈ کریں",
	"draw_signature" to "دستخط بنائیں",
	"due" to Formattable { (date) -> "واجب الادا ${date}" },
	"due_date" to "مقررہ تاریخ",
	"due_day" to "ایک دن میں",
	"due_label" to "واجب",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} واجب الادا ہے ${dueWhen}!" },
	"due_today" to "آج",
	"due_week" to "ایک ہفتے میں",
	"duplicate" to "نقل",
	"duration_day" to "دن",
	"duration_month" to "مہینہ",
	"duration_week" to "ہفتہ",
	"duration_year" to "سال",
	"email" to "ای میل",
	"email_blank_error" to "ای میل کی ضرورت ہے.",
	"email_error" to "ناجائز ای میل پتہ۔",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "آپ کے کاروبار کے لئے آپ کا شکریہ. مکمل ${documentType} دیکھنے کے لیے اس خط کے آخر میں بٹن پر کلک کریں۔ نوٹ کریں کہ یہ ${dueDate} تک درست ہے۔" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "آپ کو ایک نیا موصول ہوا ہے۔${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} دیکھیں" },
	"email_invoice_greeting" to Formattable { (clientName) -> "محترم ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "آپ کے کاروبار کے لئے آپ کا شکریہ. مکمل ${documentType} دیکھنے کے لیے اس خط کے آخر میں بٹن پر کلک کریں۔ براہ کرم ${dueDate} تک بیلنس ادا کریں۔" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "آپ کو ایک نیا موصول ہوا ہے۔${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "بھیجے گئے پاس ورڈ کو ری سیٹ کرنے کے لیے ای میل کریں، براہ کرم اپنا ان باکس چیک کریں۔",
	"empty_clients_list_message" to "آپ کو ٹریک کرنے کے لئے کوئی گاہک نہیں ہے. ایک نیا گاہک شامل کریں۔",
	"empty_estimate_list_message" to "اپنا پہلا تخمینہ بنائیں",
	"empty_expenses" to "آپ کو ٹریک کرنے کے لئے کوئی اخراجات نہیں ہیں۔ ایک نیا خرچ شامل کریں۔",
	"empty_invoice_list_message" to "اپنا پہلا انوائس بنائیں",
	"empty_items_list_message" to "آپ کے پاس کوئی آئٹم نہیں ہے، ایک نئی شے شامل کریں!",
	"empty_signatures_list_message" to "کوئی دستخط نہیں ملے۔ ایک بنائیں!",
	"empty_taxes_list_message" to "آپ کے پاس ٹیکس کے کوئی قواعد نہیں ہیں۔ ایک نیا ٹیکس قاعدہ شامل کریں۔",
	"empty_templates_list_message" to "سانچے دستیاب نہیں۔",
	"enabled" to "فعال",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "تخمینہ #",
	"estimates" to "اندازے",
	"estimates_report" to "اندازے",
	"expense_bank_fees" to "بینک فیس",
	"expense_benefits" to "فوائد",
	"expense_communication" to "مواصلات",
	"expense_debt" to "قرضہ",
	"expense_deleted" to "اخراجات کو حذف کر دیا گیا۔",
	"expense_depreciation" to "فرسودگی",
	"expense_gifts" to "تحفے",
	"expense_insurance" to "انشورنس",
	"expense_inventory" to "انوینٹری",
	"expense_legal" to "قانونی",
	"expense_maintenance" to "دیکھ بھال",
	"expense_marketing" to "مارکیٹنگ",
	"expense_meals" to "کھانے",
	"expense_office_supplies" to "آفس کا سامان",
	"expense_rent" to "کرایہ",
	"expense_salaries" to "تنخواہیں",
	"expense_shopping" to "خریداری",
	"expense_software" to "سافٹ ویئر",
	"expense_status_available" to "دستیاب",
	"expense_status_processing" to "پروسیسنگ",
	"expense_tax" to "ٹیکس",
	"expense_training" to "تربیت",
	"expense_transport" to "ٹرانسپورٹ",
	"expense_travel" to "سفر",
	"expense_utility" to "افادیت",
	"expenses" to "اخراجات",
	"feedback_option" to "رائے",
	"filter" to "فلٹر",
	"first_name_hint" to "پہلا نام",
	"forgot_password" to "پاس ورڈ پھر سیٹ کریں",
	"forgot_password_label" to "پاس ورڈ بھول گیا",
	"from" to "سے",
	"general_error" to "خرابی پیش آگئی، براہ کرم بعد میں دوبارہ کوشش کریں۔",
	"general_ledger" to "general ledger",
	"go_back_label" to "واپس جاؤ",
	"group_by" to "گروپ بمطابق:",
	"image_view" to "روپ",
	"install" to "انسٹال کریں",
	"invalid_credentials_error" to "ناجائز اسناد.",
	"invalid_email_error" to "ناجائز ای میل۔",
	"invalid_user_error" to "ناجائز صارف اسناد.",
	"invoice_deleted_message" to "انوائس حذف",
	"invoice_menu" to "انوائس مینیو",
	"invoice_number" to "اصل پٹی #",
	"invoices" to "انوائسز",
	"items_option" to "اشیا",
	"keep" to "رکھنے",
	"language_selector" to Formattable { (selected) -> "زبان ${selected}" },
	"last_name_hint" to "آخری نام",
	"ledger" to "ledger",
	"legal" to "قانونی",
	"legal_error" to "خدمت اور رازداری کی پالیسی کی شرائط قبول کرنا ضروری ہے",
	"liabilities" to "liabilities",
	"loading" to "لوڈنگ",
	"login_required_error" to "لاگ ان درکار ہے۔",
	"logo_view" to "طغرہ",
	"logout" to "لاگ آؤٹ کریں",
	"logout_prompt" to "آپ کا کوائف کلاؤڈ کے ساتھ ہم وقت سازی نہیں ہے۔ لاگ آؤٹ پر انوائسز، تخمینہ، گاہکوں اور دیگر معلومات حذف کریں؟",
	"main_screen" to "مرکزی سکرین",
	"mark_paid" to "بطور ادائیگی نشان لگائیں",
	"message" to "پیغام",
	"missing_sender_msg" to "بھیجنے والے کی معلومات کے بغیر دستاویز غلط ہو سکتی ہے۔",
	"missing_sender_title" to "بھیجنے والے کی معلومات غائب ہے۔",
	"month_april" to "اپریل",
	"month_april_short" to "اپریل",
	"month_august" to "اگست",
	"month_august_short" to "اگست",
	"month_december" to "دسمبر",
	"month_december_short" to "دسمبر",
	"month_february" to "فروری",
	"month_february_short" to "فروری",
	"month_january" to "جنوری",
	"month_january_short" to "جنوری",
	"month_july" to "جولائی",
	"month_july_short" to "جولائی",
	"month_june" to "جون",
	"month_june_short" to "جون",
	"month_march" to "مارچ",
	"month_march_short" to "مارچ",
	"month_may" to "مئی",
	"month_may_short" to "مئی",
	"month_november" to "نومبر",
	"month_november_short" to "نومبر",
	"month_october" to "اکتوبر",
	"month_october_short" to "اکتوبر",
	"month_september" to "ستمبر",
	"month_september_short" to "ستمبر",
	"multifactor_error" to "تصدیق کرنے میں ناکام رہا۔",
	"new" to "نئی",
	"news" to "خبر",
	"next" to "اگلے",
	"no" to "no",
	"no_data_available" to "مواد موجود نہیں ہے",
	"no_search_results" to "معذرت، ہمیں کوئی نتیجہ نہیں مل سکا۔",
	"none" to "کوئی نہیں۔",
	"note_fields" to "نوٹ",
	"note_hint" to "نوٹ",
	"notifications_option" to "اطلاعات",
	"ok" to "ٹھيک ہے",
	"onboarding" to "آن بورڈنگ",
	"onboarding_aboutus_message" to "DingVoice کے ضروری حصے ہمیشہ کے لیے مفت ہیں۔ ہمارا مشن آپ کے کاروبار کو بڑھانے میں مدد کرنا ہے، اپنے تاثرات کا اشتراک کرکے ہمیں بہتر بنانے میں مدد کرنا ہے۔",
	"onboarding_aboutus_title" to "ہم مدد کے لیے حاضر ہیں۔",
	"onboarding_paid_1" to "پیشہ ورانہ نظر آنے والے ٹیمپلیٹس تک رسائی حاصل کریں۔",
	"onboarding_paid_2" to "اپنے مالی معاملات پر گہری نظر ڈالیں۔",
	"onboarding_paid_3" to "مسائل کو تیزی سے حل کریں۔",
	"onboarding_paid_4" to "اپنے دستاویزات کو محفوظ رکھیں",
	"onboarding_paid_5" to "انوائس کی حیثیت کی تبدیلیوں کے ساتھ تازہ ترین رہیں",
	"onboarding_paid_title" to "اس کے ساتھ اور بھی تیزی سے بڑھو...",
	"onboarding_paywall_message" to "اب بہترین قیمت پر تمام پریمیم خصوصیات سے لطف اندوز ہوں۔",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} کے لیے صرف ${currencySymbol}${introAmount} اور پھر ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "صرف ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} کے لیے مفت اور پھر صرف ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "کے لیے مفت${trialDuration}${trialPeriod} پھر${currencySymbol}${introductoryAmount} کے لیے${introductoryDuration}${introPeriod} اور پھر بس${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "خودکار قابل تجدید۔ کسی بھی وقت منسوخ کریں۔",
	"onboarding_paywall_restore" to "خریداری کو بحال کریں۔",
	"onboarding_paywall_restore_failure" to "خریداریوں کو بحال کرنا ناکام ہو گیا، بعد میں دوبارہ کوشش کریں یا سپورٹ سے رابطہ کریں۔",
	"onboarding_paywall_restore_success" to "بحال کامیاب!",
	"onboarding_paywall_title" to "عظمت حاصل کرو!",
	"onboarding_welcome_message" to "آپ کی رسیدوں اور تخمینوں کا نظم کرنے کے لیے ایک مکمل ٹول سیٹ۔",
	"onboarding_welcome_title" to "DingVoice میں خوش آمدید",
	"other_state" to "دیگر",
	"overdue_state" to "موخر",
	"paid_state" to "ادا",
	"password" to "پاسورڈ",
	"password_blank_error" to "پاس ورڈ درکار ہے۔",
	"password_mismatch_error" to "پاس ورڈ میل نہیں کھاتے۔",
	"pay" to "pay",
	"payment_instructions_label" to "ادائیگی کی ہدایات",
	"payment_reminders" to "ادائیگی یاد دہانی",
	"phone_number_fields" to "فون نمبر",
	"phone_number_hint" to "فون نمبر",
	"postcode_hint" to "پوسٹ کوڈ",
	"preview" to "پیش",
	"preview_menu_hint" to "پیش منظر مینیو",
	"price" to "قیمت",
	"privacy_policy" to "رازداری کی پالیسی",
	"profile" to "پروفائل",
	"profitable_client" to "آپ کا سب سے زیادہ منافع بخش کلائنٹ ہے...",
	"profits_report" to "منافع",
	"promo_content" to "تشہیری مواد",
	"quantity_hint" to "مقدار",
	"quantity_label_hint" to "مقدار لیبل",
	"query_info" to "طلب آپ کو اشیاء اور دیگر ٹیکس قواعد دونوں کو ان پٹ کے طور پر حوالہ دیتے ہوئے مخصوص حساب لکھنے کی اجازت دیتا ہے۔ کسی دوسرے اندراج +، *، /, ٪ - ریاضی آپریٹرز سے لنک کرنے کے لئے @ علامت اور شے یا ٹیکس کی وضاحت شامل کریں",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} آپ کے کلائنٹ کو موصول ہوا۔" },
	"received_state" to "موصول ہوا۔",
	"registration_code_fields" to "رجسٹریشن کوڈ",
	"reminder" to "یاد دہانی",
	"reports" to "رپورٹیں",
	"reports_not_available" to "کوئی رپورٹ دستیاب نہیں، بعد میں واپس آئیں۔",
	"reveal_password" to "پاس ورڈ ظاہر کریں",
	"revenue" to "revenue",
	"sales_tax" to "ویٹ",
	"sales_tax_number" to "VAT نمبر",
	"save" to "بچانا",
	"scan_document" to "دستاویز اسکین کریں۔",
	"search" to "تلاش",
	"select" to "منتخب کریں۔",
	"select_icon" to "آئیکن منتخب کریں۔",
	"select_signature" to "دستخط منتخب کریں",
	"select_template" to "سانچہ منتخب کریں",
	"send" to "بھیجنا",
	"sent_state" to "بھیجا",
	"setup_sender" to "بھیجنے والے کو ترتیب دیں۔",
	"share_option" to "حصہ",
	"ship_to" to "کو بھیجیں۔",
	"sign_in" to "سائن ان کریں",
	"sign_in_apple" to "ایپل کے ساتھ سائن ان کریں",
	"sign_in_email" to "ای میل کے ساتھ سائن ان کریں",
	"sign_in_facebook" to "فیس بک کے ساتھ سائن ان کریں",
	"sign_in_google" to "گوگل کے ساتھ سائن ان کریں",
	"sign_up" to "سائن اپ کریں",
	"sign_up_label" to "ایک اکاؤنٹ نہیں ہے؟",
	"sign_up_link_label" to "سائن اپ کریں!",
	"signatures_option" to "دستخط",
	"skip" to "چھوڑ دو",
	"sold_items_report" to "فروخت شدہ اشیاء",
	"sort_by" to "چھانٹیں از:",
	"status_update" to "دستاویز کی حیثیت کی تازہ کاری",
	"sub_annual" to "سالانہ",
	"sub_annual_price" to Formattable { (price) -> "${price}/سال" },
	"sub_backups" to "کلاؤڈ بیک اپ",
	"sub_insights" to "بصیرت",
	"sub_month" to "ماہانہ",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ماہ" },
	"sub_notifications" to "اطلاعات",
	"sub_popup_message" to "سبسکرائب جلد ہی آ رہے ہیں. تھوڑی دیر میں یہاں واپس چیک کریں.",
	"sub_support" to "سپورٹ",
	"sub_templates" to "تمثیلات",
	"subscribe" to "رکنیت",
	"subscription_header" to "اپنے کاروبار کو بڑھائیں:",
	"subscription_option" to "اشتراک",
	"subscriptions_load_fail" to "معذرت، ہم اس وقت آپ کی سبسکرپشنز کو بازیافت کرنے سے قاصر ہیں۔ براہ کرم بعد میں دوبارہ کوشش کریں یا مدد کے لیے ہماری سپورٹ ٹیم سے رابطہ کریں۔",
	"subtotal" to Formattable { (sum) -> "ذیلی کل: ${sum}" },
	"subtotal_label" to "ذیلی کل",
	"sum" to "رقم",
	"support_option" to "معاونت",
	"switch_reports" to "رپورٹ سوئچ کریں۔",
	"tax" to "محصول",
	"tax_deleted_message" to "ٹیکس حذف",
	"tax_description_warning" to "اگر استعمال کیا جائے تو یہ انوائس پر ظاہر ہوتا ہے",
	"tax_input_flat" to "فلیٹ",
	"tax_input_percent" to "فیصد",
	"tax_number_fields" to "ٹیکس نمبر",
	"tax_number_hint" to "ٹیکس نمبر",
	"tax_query_info_description" to "معلومات طلب کریں",
	"tax_report" to "محصول",
	"tax_rules_option" to "ٹیکس قواعد",
	"taxed_clients_report" to "ٹیکس شدہ کلائنٹس",
	"template" to "سانچا",
	"template_selector_info" to "طے شدہ سانچا تبدیل کرنے کے لیے سوائپ کریں",
	"template_unlock_promo" to "Premium کے ساتھ مزید ٹیمپلیٹس تک رسائی حاصل کریں۔",
	"templates_option" to "سانچے",
	"terms_of_service" to "سروس کی شرائط",
	"thank_you" to "شکریہ",
	"to" to "کو",
	"total" to Formattable { (sum) -> "کل: ${sum}" },
	"total_label" to "کل",
	"undo_action" to "کالعدم",
	"unit" to "یونٹ",
	"unit_cost_hint" to "یونٹ لاگت",
	"unknown_client" to "نامعلوم کلائنٹ",
	"unlock" to "غیر مقفل کریں۔",
	"unnamed_item" to "بے نام آئٹم",
	"upgrade" to "اپ گریڈ",
	"user_collision_error" to "اکاؤنٹ پہلے ہی موجود ہے۔",
	"valid_until" to "قابل قبول جب تک",
	"warning" to "اشارہ!",
	"yes" to "yes",
    )
    
}