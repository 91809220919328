// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Is  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Samþykkja ${termsOfService} og ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Ógildur kóði."
override val add_client_action: String = "Bæta við biðlara"
override val add_customer: String = "Bæta við viðskiptavini"
override val add_expense: String = "Bæta við kostnaði"
override val add_fields_label: String = "Bæta við:"
override val add_invoice_action: String = "Skapa"
override val add_item_action: String = "Bæta við vöru"
override val add_item_button: String = "Bæta við vöru"
override val add_signature_action: String = "Bæta við undirskrift"
override val add_tax_action: String = "Bæta við skatti"
override val address_fields: String = "Heimilisfang"
override val address_line_hint: String = "Aðseturslína"
override val advanced: String = "Háþróaður"
override val agree_marketing: String = "Ég samþykki að fá bein markaðssamskipti"
override val all_notifications: String = "Allar tilkynningar"
override val amortization: String = "amortization"
override val amount: String = "Magn"
override val asset: String = "asset"
override val attach_file: String = "Hengja skrá"
override val attachment: String = "Viðhengi"
override val auth_error: String = "Aðgerð mistókst, reyndu aftur."
override val available_for_paid: String = "Cloud sync er aðeins í boði undir greiddri áskrift."
override val backups_option: String = "Afrit"
override val balance: String = "balance"
override val bank_account: String = "Bankareikning"
override val bill_number: String = "Reikningsnúmer"
override val cancel: String = "Afturkalla"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Flokkar"
override val category: String = "Flokkur"
override val category_add: String = "Bæta við flokki"
override val category_delete: String = "Eyða flokki"
override val category_name: String = "Nafn flokks"
override val change_language: Formattable = Formattable { (language) -> "Tungumál reikninga: ${language}" }
override val change_language_label: String = "Tungumál reikninga"
override val change_template: String = "Breyta sniðmáti"
override val city_hint: String = "Borg"
override val clear: String = "Skýr"
override val client: String = "Skjólstæðingur"
override val client_actions: String = "Aðgerðir biðlara"
override val client_deleted_message: String = "Biðlara eytt"
override val clients_error_required_fields: String = "Að minnsta kosti eitt gildi er nauðsynlegt."
override val clients_option: String = "Viðskiptavini"
override val close: String = "Loka"
override val cloud_sync: String = "Samstilling skýja"
override val coming_soon: String = "Kemur bráðum!"
override val company_logo: String = "Merki fyrirtækis"
override val company_name_hint: String = "Fyrirtæki"
override val confirm_password_label: String = "Staðfesta aðgangsorð"
override val confirmation_title: String = "Ertu viss?"
override val convert_to_invoice: String = "Umbreyta í reikning"
override val country_hint: String = "Land"
override val create_client: String = "Stofna biðlara"
override val create_company: String = "Stofna fyrirtæki"
override val create_document: String = "Stofna skjal"
override val create_estimate: String = "Áætla"
override val create_invoice: String = "Reikningur"
override val create_item: String = "Stofna vöru"
override val create_new_client: String = "Stofna nýjan biðlara"
override val create_new_item: String = "Stofna nýja vöru"
override val create_new_tax: String = "Stofna nýjan skatt"
override val create_signature: String = "Undirskrift"
override val created: String = "Stofnuð"
override val credit: String = "credit"
override val customer: String = "Viðskiptavinur"
override val date: String = "Dagsetning"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Sjálfgefinn gjaldmiðill: ${currency}" }
override val default_currency_label: String = "Sjálfgefinn gjaldmiðill"
override val delete: String = "Eyða"
override val delete_account: String = "Eyða reikningi"
override val delete_confirmation_message: String = "Öll gögn þín munu glatast."
override val delete_item_title: String = "Á að eyða atriðinu?"
override val delete_signature: String = "Eyða undirskrift"
override val depreciation: String = "depreciation"
override val description: String = "Lýsing"
override val description_hint: String = "Lýsing"
override val detailed_report: String = "Ítarleg skýrsla"
override val disabled: String = "Fatlaður"
override val discount: String = "Afsláttur"
override val discount_hint: String = "Afsláttur"
override val display_af: String = "Afríku"
override val display_am: String = "amharíska"
override val display_ar: String = "arabíska"
override val display_as: String = "Assamskir"
override val display_az: String = "Aserbaídsjan"
override val display_be: String = "hvítrússneska"
override val display_bg: String = "búlgarska"
override val display_bn: String = "bengalska"
override val display_bs: String = "bosníska"
override val display_ca: String = "katalónska"
override val display_cs: String = "Tékkneska"
override val display_da: String = "Danska"
override val display_de: String = "Þýsku"
override val display_el: String = "gríska"
override val display_en: String = "Enska"
override val display_es: String = "Spænska"
override val display_es_MX: String = "Spænska (Mexíkó)"
override val display_et: String = "eistneska, eisti, eistneskur"
override val display_eu: String = "baskneska"
override val display_fa: String = "persneska"
override val display_fi: String = "Finnska"
override val display_fil: String = "filippseyska"
override val display_fr: String = "Franska"
override val display_fr_CA: String = "kanadísk franska"
override val display_gl: String = "galisíska"
override val display_gu: String = "Gújaratí"
override val display_he: String = "hebreska"
override val display_hi: String = "Neibb"
override val display_hr: String = "Króatíska"
override val display_hu: String = "ungverska, Ungverji, ungverskt"
override val display_hy: String = "Armenska"
override val display_id: String = "indónesíska"
override val display_is: String = "Íslenska"
override val display_it: String = "Ítalska"
override val display_ja: String = "Japanska"
override val display_ka: String = "georgískt"
override val display_kk: String = "kasakska"
override val display_km: String = "Mið-Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "kóreska"
override val display_ky: String = "Kirgiskir"
override val display_lo: String = "Vinnuafl"
override val display_lt: String = "litháískur"
override val display_lv: String = "lettneska"
override val display_mk: String = "makedónska"
override val display_ml: String = "Malajalam"
override val display_mn: String = "mongólska"
override val display_mr: String = "Marathi"
override val display_ms: String = "malaíska"
override val display_my: String = "Búrma"
override val display_nb: String = "norskt bókmál"
override val display_ne: String = "nepalska"
override val display_nl: String = "Hollenska"
override val display_or: String = "Oriya"
override val display_pa: String = "Púndjabí"
override val display_pl: String = "Pólska"
override val display_pt: String = "Portúgalska"
override val display_pt_BR: String = "Portúgalska (Brasilía)"
override val display_pt_PT: String = "Evrópskt portúgalskt"
override val display_ro: String = "rúmenska"
override val display_ru: String = "Rússneskt"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slóvakíska"
override val display_sl: String = "slóvenska"
override val display_sq: String = "albanska"
override val display_sr: String = "serbneska"
override val display_sv: String = "Sænska"
override val display_sw: String = "svahílí"
override val display_ta: String = "tamílska"
override val display_te: String = "telúgú"
override val display_th: String = "Tælensk"
override val display_tr: String = "tyrkneska"
override val display_uk: String = "úkraínska"
override val display_ur: String = "Úrdú"
override val display_uz: String = "úsbekskur"
override val display_vi: String = "Víetnamska"
override val display_zh_Hant_TW: String = "Kínverska (Taiwan, hefðbundið)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Áætla"
override val document_invoice: String = "Reikningur"
override val document_quote: String = "Tilvitnun"
override val done: String = "Búið"
override val download_app: String = "Sækja appið"
override val download_invoice: String = "Sækja reikning"
override val download_pdf: String = "Sækja PDF"
override val draw_signature: String = "Teikna undirskrift"
override val due: Formattable = Formattable { (date) -> "Gjaldfallin ${date}" }
override val due_date: String = "Gjalddagi"
override val due_day: String = "á einum degi"
override val due_label: String = "Gjaldfallinn"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} á gjalddaga ${dueWhen}!" }
override val due_today: String = "Í dag"
override val due_week: String = "á viku"
override val duplicate: String = "Afrit"
override val duration_day: String = "á dag"
override val duration_month: String = "Mánuður"
override val duration_week: String = "vika"
override val duration_year: String = "ár"
override val email: String = "Tölvupóstur"
override val email_blank_error: String = "Tölvupósts krafist."
override val email_error: String = "Ógilt netfang."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Takk fyrir viðskiptin. Til að skoða ${documentType} í heild sinni smelltu á hnappinn í lok þessa bréfs. Athugaðu að það gildir til ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Þú hefur fengið nýtt${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "SKOÐA ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Kæri ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Takk fyrir viðskiptin. Til að skoða ${documentType} í heild sinni smelltu á hnappinn í lok þessa bréfs. Vinsamlegast greiddu stöðuna til ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Þú hefur fengið nýtt${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "Sendu tölvupóst til að endurstilla lykilorð sent, vinsamlegast athugaðu pósthólfið þitt."
override val empty_clients_list_message: String = "Þú hefur enga viðskiptavini til að fylgjast með. Bæta við nýjum biðlara."
override val empty_estimate_list_message: String = "Búðu til fyrsta mat þitt"
override val empty_expenses: String = "Þú hefur engin útgjöld til að fylgjast með. Bættu við nýjum kostnaði."
override val empty_invoice_list_message: String = "Búðu til fyrsta reikninginn þinn"
override val empty_items_list_message: String = "Þú hefur engar vörur, bættu við nýjum hlut!"
override val empty_signatures_list_message: String = "Engar undirskriftir fundust. Búðu til einn!"
override val empty_taxes_list_message: String = "Þú hefur engar skattareglur. Bæta við nýrri skattreglu."
override val empty_templates_list_message: String = "Sniðmát ekki tiltæk."
override val enabled: String = "Virkt"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Áætlun #"
override val estimates: String = "Mat"
override val estimates_report: String = "Mat"
override val expense_bank_fees: String = "Bankagjöld"
override val expense_benefits: String = "Kostir"
override val expense_communication: String = "Samskipti"
override val expense_debt: String = "Skuld"
override val expense_deleted: String = "Kostnaði eytt"
override val expense_depreciation: String = "gengislækkun"
override val expense_gifts: String = "Gjafir"
override val expense_insurance: String = "Tryggingar"
override val expense_inventory: String = "Birgðir"
override val expense_legal: String = "Löglegt"
override val expense_maintenance: String = "Viðhald"
override val expense_marketing: String = "Markaðssetning"
override val expense_meals: String = "Máltíðir"
override val expense_office_supplies: String = "Skrifstofuvörur"
override val expense_rent: String = "Leigu"
override val expense_salaries: String = "Laun"
override val expense_shopping: String = "Innkaup"
override val expense_software: String = "Hugbúnaður"
override val expense_status_available: String = "Laus"
override val expense_status_processing: String = "Vinnsla"
override val expense_tax: String = "Skattur"
override val expense_training: String = "Þjálfun"
override val expense_transport: String = "Flutningur"
override val expense_travel: String = "Ferðalög"
override val expense_utility: String = "Gagnsemi"
override val expenses: String = "Útgjöld"
override val feedback_option: String = "Afturverkun"
override val filter: String = "Sía"
override val first_name_hint: String = "Skírnarnafn"
override val forgot_password: String = "Endurstilla aðgangsorð"
override val forgot_password_label: String = "Gleymt lykilorð"
override val from: String = "Frá"
override val general_error: String = "Villa kom upp, vinsamlegast reyndu aftur síðar."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Farðu til baka"
override val group_by: String = "Flokka eftir:"
override val image_view: String = "Ímynd"
override val install: String = "Settu upp"
override val invalid_credentials_error: String = "Ógild skilríki."
override val invalid_email_error: String = "Ógildur tölvupóstur."
override val invalid_user_error: String = "Ógild notandaskilríki."
override val invoice_deleted_message: String = "Reikningi eytt"
override val invoice_menu: String = "Valmynd reiknings"
override val invoice_number: String = "Reikningur #"
override val invoices: String = "Reikninga"
override val items_option: String = "Vörur"
override val keep: String = "Geyma"
override val language_selector: Formattable = Formattable { (selected) -> "Tungumál ${selected}" }
override val last_name_hint: String = "Eftirnafn"
override val ledger: String = "ledger"
override val legal: String = "Löglegur"
override val legal_error: String = "Verður að samþykkja þjónustuskilmála og persónuverndarstefnu"
override val liabilities: String = "liabilities"
override val loading: String = "Hleðsla"
override val login_required_error: String = "Innskráningar krafist."
override val logo_view: String = "Merki"
override val logout: String = "Nafnplata"
override val logout_prompt: String = "Gögnin þín eru ekki samstillt við ský. Eyða reikningum, mati, biðlurum &öðrum upplýsingum um útskráningu?"
override val main_screen: String = "Aðalskjár"
override val mark_paid: String = "Merkja sem greitt"
override val message: String = "Skilaboð"
override val missing_sender_msg: String = "Skjal án sendandaupplýsinga gæti verið ógilt."
override val missing_sender_title: String = "Vantar upplýsingar um sendanda"
override val month_april: String = "nisan"
override val month_april_short: String = "nisan"
override val month_august: String = "Ágúst"
override val month_august_short: String = "ágúst"
override val month_december: String = "Desember"
override val month_december_short: String = "des."
override val month_february: String = "Febrúar"
override val month_february_short: String = "feb."
override val month_january: String = "Janúar"
override val month_january_short: String = "Jan."
override val month_july: String = "Júlí"
override val month_july_short: String = "Júlí"
override val month_june: String = "Júní"
override val month_june_short: String = "Júní"
override val month_march: String = "Mars"
override val month_march_short: String = "Mars"
override val month_may: String = "Maí"
override val month_may_short: String = "Maí"
override val month_november: String = "Nóvember"
override val month_november_short: String = "nóv."
override val month_october: String = "Október"
override val month_october_short: String = "okt."
override val month_september: String = "september"
override val month_september_short: String = "sept."
override val multifactor_error: String = "Ekki tókst að sannreyna."
override val new: String = "Nýtt"
override val news: String = "Fréttir"
override val next: String = "Næsta"
override val no: String = "no"
override val no_data_available: String = "Engin gögn tiltæk"
override val no_search_results: String = "Því miður fundum við engar niðurstöður."
override val none: String = "Enginn"
override val note_fields: String = "Athugasemdir"
override val note_hint: String = "Nóta"
override val notifications_option: String = "Tilkynningar"
override val ok: String = "Ókei"
override val onboarding: String = "Um borð"
override val onboarding_aboutus_message: String = "Nauðsynlegir hlutar DingVoice eru að eilífu ókeypis. Markmið okkar er að hjálpa þér að auka viðskipti þín, hjálpa okkur að bæta okkur með því að deila athugasemdum þínum."
override val onboarding_aboutus_title: String = "Við erum hér til að hjálpa"
override val onboarding_paid_1: String = "Fáðu aðgang að fagmennskusniðmátum"
override val onboarding_paid_2: String = "Skoðaðu fjárhagsstöðu þína dýpra"
override val onboarding_paid_3: String = "Fáðu leyst vandamál hratt"
override val onboarding_paid_4: String = "Haltu skjölunum þínum öruggum"
override val onboarding_paid_5: String = "Fylgstu með breytingum á stöðu reikninga"
override val onboarding_paid_title: String = "Vaxið enn hraðar með..."
override val onboarding_paywall_message: String = "Njóttu allra úrvals eiginleika núna á besta verði."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Bara ${currencySymbol}${introAmount} fyrir ${introDuration} ${introPeriod} og svo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Bara ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Ókeypis fyrir ${trialDuration} ${trialPeriod} og svo bara ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Ókeypis fyrir ${trialDuration} ${trialPeriod}, síðan ${currencySymbol}${introductoryAmount} fyrir ${introductoryDuration} ${introPeriod} og svo bara ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Sjálfvirk endurnýjanleg. Hætta við hvenær sem er."
override val onboarding_paywall_restore: String = "Endurheimta Kaup"
override val onboarding_paywall_restore_failure: String = "Mistókst að endurheimta kaup, reyndu aftur síðar eða hafðu samband við þjónustudeild."
override val onboarding_paywall_restore_success: String = "Endurheimt tókst!"
override val onboarding_paywall_title: String = "Náðu hátign!"
override val onboarding_welcome_message: String = "Fullkomið verkfærasett til að stjórna reikningum þínum og áætlunum."
override val onboarding_welcome_title: String = "Velkomin í DingVoice"
override val other_state: String = "Annað"
override val overdue_state: String = "Gjaldfallna"
override val paid_state: String = "Greiddur"
override val password: String = "Aðgangsorð"
override val password_blank_error: String = "Aðgangsorðs krafist."
override val password_mismatch_error: String = "Aðgangsorð passa ekki saman."
override val pay: String = "pay"
override val payment_instructions_label: String = "Greiðsluleiðbeiningar"
override val payment_reminders: String = "Áminningar um greiðslu"
override val phone_number_fields: String = "Símanúmer"
override val phone_number_hint: String = "Símanúmer"
override val postcode_hint: String = "Póstnúmer"
override val preview: String = "Forsýning"
override val preview_menu_hint: String = "Forskoðunarvalmynd"
override val price: String = "Verð"
override val privacy_policy: String = "Persónuverndarstefnu"
override val profile: String = "Vangasvipur"
override val profitable_client: String = "Arðvænlegasti viðskiptavinurinn þinn er..."
override val profits_report: String = "Hagnaður"
override val promo_content: String = "Kynningarefni"
override val quantity_hint: String = "Magn"
override val quantity_label_hint: String = "Merki magns"
override val query_info: String = "Fyrirspurn gerir þér kleift að skrifa sérsniðna útreikninga sem vísa bæði til atriða og annarra skattareglna sem inntaks. Bættu við @ tákni og vöru eða skattalýsingu til að tengja við aðra færslu +, -, *, /, % - stærðfræði rekstraraðila"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} barst viðskiptavinur þinn." }
override val received_state: String = "Tekið á móti"
override val registration_code_fields: String = "Skráningarkóði"
override val reminder: String = "Áminning"
override val reports: String = "Skýrslur"
override val reports_not_available: String = "Engar skýrslur tiltækar, komdu aftur síðar."
override val reveal_password: String = "Sýna aðgangsorð"
override val revenue: String = "revenue"
override val sales_tax: String = "vsk"
override val sales_tax_number: String = "VSK númer"
override val save: String = "Spara"
override val scan_document: String = "Skannaðu skjal"
override val search: String = "Leita"
override val select: String = "Veldu"
override val select_icon: String = "Veldu táknmynd"
override val select_signature: String = "Velja undirskrift"
override val select_template: String = "Velja sniðmát"
override val send: String = "Senda"
override val sent_state: String = "Send"
override val setup_sender: String = "Settu upp sendanda"
override val share_option: String = "Deila"
override val ship_to: String = "Senda til"
override val sign_in: String = "Skrá inn"
override val sign_in_apple: String = "Skráðu þig inn með Apple"
override val sign_in_email: String = "Skráðu þig inn með tölvupósti"
override val sign_in_facebook: String = "Skráðu þig inn með Facebook"
override val sign_in_google: String = "Skráðu þig inn með Google"
override val sign_up: String = "Skrá sig"
override val sign_up_label: String = "Ertu ekki með reikning?"
override val sign_up_link_label: String = "Skrá sig!"
override val signatures_option: String = "Undirskrift"
override val skip: String = "Sleppa"
override val sold_items_report: String = "Seldar vörur"
override val sort_by: String = "Raða eftir:"
override val status_update: String = "Uppfærsla á stöðu skjala"
override val sub_annual: String = "Árlegt"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price} á ári" }
override val sub_backups: String = "Cloud öryggisafrit"
override val sub_insights: String = "Innsýn"
override val sub_month: String = "Mánaðarlega"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price} á mánuði" }
override val sub_notifications: String = "Tilkynningar"
override val sub_popup_message: String = "Áskriftir koma fljótlega. Kíktu aftur hér eftir smá stund."
override val sub_support: String = "Aðstoð"
override val sub_templates: String = "Sniðmát"
override val subscribe: String = "Gerast áskrifandi"
override val subscription_header: String = "Stækkaðu fyrirtækið þitt með:"
override val subscription_option: String = "Áskrift"
override val subscriptions_load_fail: String = "Því miður getum við ekki sótt áskriftirnar þínar eins og er. Vinsamlegast reyndu aftur síðar eða hafðu samband við þjónustudeild okkar til að fá aðstoð."
override val subtotal: Formattable = Formattable { (sum) -> "Tímatala: ${sum}" }
override val subtotal_label: String = "Samtala"
override val sum: String = "Summa"
override val support_option: String = "Styðja"
override val switch_reports: String = "Skipta skýrslu"
override val tax: String = "Skattur"
override val tax_deleted_message: String = "Skatti eytt"
override val tax_description_warning: String = "Ef þetta er notað birtist þetta á reikningi"
override val tax_input_flat: String = "Flatur"
override val tax_input_percent: String = "Hundraðshluti"
override val tax_number_fields: String = "Skattnúmer"
override val tax_number_hint: String = "Skattnúmer"
override val tax_query_info_description: String = "Upplýsingar um fyrirspurn"
override val tax_report: String = "Skattur"
override val tax_rules_option: String = "Skattareglur"
override val taxed_clients_report: String = "Skattskyldir viðskiptavinir"
override val template: String = "Sniðmát"
override val template_selector_info: String = "Strjúktu til að breyta sjálfgefna sniðmátinu"
override val template_unlock_promo: String = "Fáðu aðgang að fleiri sniðmátum með Premium"
override val templates_option: String = "Sniðmát"
override val terms_of_service: String = "Þjónustuskilmálar"
override val thank_you: String = "Þakka þér fyrir"
override val to: String = "Til"
override val total: Formattable = Formattable { (sum) -> "Samtals: ${sum}" }
override val total_label: String = "Samtals"
override val undo_action: String = "Afturkalla"
override val unit: String = "Eining"
override val unit_cost_hint: String = "Kostnaðarverð"
override val unknown_client: String = "Óþekktur viðskiptavinur"
override val unlock: String = "Opna"
override val unnamed_item: String = "Ónefndur hlutur"
override val upgrade: String = "Uppfærsla"
override val user_collision_error: String = "Lykill er þegar til."
override val valid_until: String = "Gildir til"
override val warning: String = "Viðvörun!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Samþykkja ${termsOfService} og ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Ógildur kóði.",
	"add_client_action" to "Bæta við biðlara",
	"add_customer" to "Bæta við viðskiptavini",
	"add_expense" to "Bæta við kostnaði",
	"add_fields_label" to "Bæta við:",
	"add_invoice_action" to "Skapa",
	"add_item_action" to "Bæta við vöru",
	"add_item_button" to "Bæta við vöru",
	"add_signature_action" to "Bæta við undirskrift",
	"add_tax_action" to "Bæta við skatti",
	"address_fields" to "Heimilisfang",
	"address_line_hint" to "Aðseturslína",
	"advanced" to "Háþróaður",
	"agree_marketing" to "Ég samþykki að fá bein markaðssamskipti",
	"all_notifications" to "Allar tilkynningar",
	"amortization" to "amortization",
	"amount" to "Magn",
	"asset" to "asset",
	"attach_file" to "Hengja skrá",
	"attachment" to "Viðhengi",
	"auth_error" to "Aðgerð mistókst, reyndu aftur.",
	"available_for_paid" to "Cloud sync er aðeins í boði undir greiddri áskrift.",
	"backups_option" to "Afrit",
	"balance" to "balance",
	"bank_account" to "Bankareikning",
	"bill_number" to "Reikningsnúmer",
	"cancel" to "Afturkalla",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Flokkar",
	"category" to "Flokkur",
	"category_add" to "Bæta við flokki",
	"category_delete" to "Eyða flokki",
	"category_name" to "Nafn flokks",
	"change_language" to Formattable { (language) -> "Tungumál reikninga: ${language}" },
	"change_language_label" to "Tungumál reikninga",
	"change_template" to "Breyta sniðmáti",
	"city_hint" to "Borg",
	"clear" to "Skýr",
	"client" to "Skjólstæðingur",
	"client_actions" to "Aðgerðir biðlara",
	"client_deleted_message" to "Biðlara eytt",
	"clients_error_required_fields" to "Að minnsta kosti eitt gildi er nauðsynlegt.",
	"clients_option" to "Viðskiptavini",
	"close" to "Loka",
	"cloud_sync" to "Samstilling skýja",
	"coming_soon" to "Kemur bráðum!",
	"company_logo" to "Merki fyrirtækis",
	"company_name_hint" to "Fyrirtæki",
	"confirm_password_label" to "Staðfesta aðgangsorð",
	"confirmation_title" to "Ertu viss?",
	"convert_to_invoice" to "Umbreyta í reikning",
	"country_hint" to "Land",
	"create_client" to "Stofna biðlara",
	"create_company" to "Stofna fyrirtæki",
	"create_document" to "Stofna skjal",
	"create_estimate" to "Áætla",
	"create_invoice" to "Reikningur",
	"create_item" to "Stofna vöru",
	"create_new_client" to "Stofna nýjan biðlara",
	"create_new_item" to "Stofna nýja vöru",
	"create_new_tax" to "Stofna nýjan skatt",
	"create_signature" to "Undirskrift",
	"created" to "Stofnuð",
	"credit" to "credit",
	"customer" to "Viðskiptavinur",
	"date" to "Dagsetning",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Sjálfgefinn gjaldmiðill: ${currency}" },
	"default_currency_label" to "Sjálfgefinn gjaldmiðill",
	"delete" to "Eyða",
	"delete_account" to "Eyða reikningi",
	"delete_confirmation_message" to "Öll gögn þín munu glatast.",
	"delete_item_title" to "Á að eyða atriðinu?",
	"delete_signature" to "Eyða undirskrift",
	"depreciation" to "depreciation",
	"description" to "Lýsing",
	"description_hint" to "Lýsing",
	"detailed_report" to "Ítarleg skýrsla",
	"disabled" to "Fatlaður",
	"discount" to "Afsláttur",
	"discount_hint" to "Afsláttur",
	"display_af" to "Afríku",
	"display_am" to "amharíska",
	"display_ar" to "arabíska",
	"display_as" to "Assamskir",
	"display_az" to "Aserbaídsjan",
	"display_be" to "hvítrússneska",
	"display_bg" to "búlgarska",
	"display_bn" to "bengalska",
	"display_bs" to "bosníska",
	"display_ca" to "katalónska",
	"display_cs" to "Tékkneska",
	"display_da" to "Danska",
	"display_de" to "Þýsku",
	"display_el" to "gríska",
	"display_en" to "Enska",
	"display_es" to "Spænska",
	"display_es_MX" to "Spænska (Mexíkó)",
	"display_et" to "eistneska, eisti, eistneskur",
	"display_eu" to "baskneska",
	"display_fa" to "persneska",
	"display_fi" to "Finnska",
	"display_fil" to "filippseyska",
	"display_fr" to "Franska",
	"display_fr_CA" to "kanadísk franska",
	"display_gl" to "galisíska",
	"display_gu" to "Gújaratí",
	"display_he" to "hebreska",
	"display_hi" to "Neibb",
	"display_hr" to "Króatíska",
	"display_hu" to "ungverska, Ungverji, ungverskt",
	"display_hy" to "Armenska",
	"display_id" to "indónesíska",
	"display_is" to "Íslenska",
	"display_it" to "Ítalska",
	"display_ja" to "Japanska",
	"display_ka" to "georgískt",
	"display_kk" to "kasakska",
	"display_km" to "Mið-Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "kóreska",
	"display_ky" to "Kirgiskir",
	"display_lo" to "Vinnuafl",
	"display_lt" to "litháískur",
	"display_lv" to "lettneska",
	"display_mk" to "makedónska",
	"display_ml" to "Malajalam",
	"display_mn" to "mongólska",
	"display_mr" to "Marathi",
	"display_ms" to "malaíska",
	"display_my" to "Búrma",
	"display_nb" to "norskt bókmál",
	"display_ne" to "nepalska",
	"display_nl" to "Hollenska",
	"display_or" to "Oriya",
	"display_pa" to "Púndjabí",
	"display_pl" to "Pólska",
	"display_pt" to "Portúgalska",
	"display_pt_BR" to "Portúgalska (Brasilía)",
	"display_pt_PT" to "Evrópskt portúgalskt",
	"display_ro" to "rúmenska",
	"display_ru" to "Rússneskt",
	"display_si" to "Sinhala",
	"display_sk" to "Slóvakíska",
	"display_sl" to "slóvenska",
	"display_sq" to "albanska",
	"display_sr" to "serbneska",
	"display_sv" to "Sænska",
	"display_sw" to "svahílí",
	"display_ta" to "tamílska",
	"display_te" to "telúgú",
	"display_th" to "Tælensk",
	"display_tr" to "tyrkneska",
	"display_uk" to "úkraínska",
	"display_ur" to "Úrdú",
	"display_uz" to "úsbekskur",
	"display_vi" to "Víetnamska",
	"display_zh_Hant_TW" to "Kínverska (Taiwan, hefðbundið)",
	"display_zu" to "Zulu",
	"document_estimate" to "Áætla",
	"document_invoice" to "Reikningur",
	"document_quote" to "Tilvitnun",
	"done" to "Búið",
	"download_app" to "Sækja appið",
	"download_invoice" to "Sækja reikning",
	"download_pdf" to "Sækja PDF",
	"draw_signature" to "Teikna undirskrift",
	"due" to Formattable { (date) -> "Gjaldfallin ${date}" },
	"due_date" to "Gjalddagi",
	"due_day" to "á einum degi",
	"due_label" to "Gjaldfallinn",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} á gjalddaga ${dueWhen}!" },
	"due_today" to "Í dag",
	"due_week" to "á viku",
	"duplicate" to "Afrit",
	"duration_day" to "á dag",
	"duration_month" to "Mánuður",
	"duration_week" to "vika",
	"duration_year" to "ár",
	"email" to "Tölvupóstur",
	"email_blank_error" to "Tölvupósts krafist.",
	"email_error" to "Ógilt netfang.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Takk fyrir viðskiptin. Til að skoða ${documentType} í heild sinni smelltu á hnappinn í lok þessa bréfs. Athugaðu að það gildir til ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Þú hefur fengið nýtt${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "SKOÐA ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Kæri ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Takk fyrir viðskiptin. Til að skoða ${documentType} í heild sinni smelltu á hnappinn í lok þessa bréfs. Vinsamlegast greiddu stöðuna til ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Þú hefur fengið nýtt${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "Sendu tölvupóst til að endurstilla lykilorð sent, vinsamlegast athugaðu pósthólfið þitt.",
	"empty_clients_list_message" to "Þú hefur enga viðskiptavini til að fylgjast með. Bæta við nýjum biðlara.",
	"empty_estimate_list_message" to "Búðu til fyrsta mat þitt",
	"empty_expenses" to "Þú hefur engin útgjöld til að fylgjast með. Bættu við nýjum kostnaði.",
	"empty_invoice_list_message" to "Búðu til fyrsta reikninginn þinn",
	"empty_items_list_message" to "Þú hefur engar vörur, bættu við nýjum hlut!",
	"empty_signatures_list_message" to "Engar undirskriftir fundust. Búðu til einn!",
	"empty_taxes_list_message" to "Þú hefur engar skattareglur. Bæta við nýrri skattreglu.",
	"empty_templates_list_message" to "Sniðmát ekki tiltæk.",
	"enabled" to "Virkt",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Áætlun #",
	"estimates" to "Mat",
	"estimates_report" to "Mat",
	"expense_bank_fees" to "Bankagjöld",
	"expense_benefits" to "Kostir",
	"expense_communication" to "Samskipti",
	"expense_debt" to "Skuld",
	"expense_deleted" to "Kostnaði eytt",
	"expense_depreciation" to "gengislækkun",
	"expense_gifts" to "Gjafir",
	"expense_insurance" to "Tryggingar",
	"expense_inventory" to "Birgðir",
	"expense_legal" to "Löglegt",
	"expense_maintenance" to "Viðhald",
	"expense_marketing" to "Markaðssetning",
	"expense_meals" to "Máltíðir",
	"expense_office_supplies" to "Skrifstofuvörur",
	"expense_rent" to "Leigu",
	"expense_salaries" to "Laun",
	"expense_shopping" to "Innkaup",
	"expense_software" to "Hugbúnaður",
	"expense_status_available" to "Laus",
	"expense_status_processing" to "Vinnsla",
	"expense_tax" to "Skattur",
	"expense_training" to "Þjálfun",
	"expense_transport" to "Flutningur",
	"expense_travel" to "Ferðalög",
	"expense_utility" to "Gagnsemi",
	"expenses" to "Útgjöld",
	"feedback_option" to "Afturverkun",
	"filter" to "Sía",
	"first_name_hint" to "Skírnarnafn",
	"forgot_password" to "Endurstilla aðgangsorð",
	"forgot_password_label" to "Gleymt lykilorð",
	"from" to "Frá",
	"general_error" to "Villa kom upp, vinsamlegast reyndu aftur síðar.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Farðu til baka",
	"group_by" to "Flokka eftir:",
	"image_view" to "Ímynd",
	"install" to "Settu upp",
	"invalid_credentials_error" to "Ógild skilríki.",
	"invalid_email_error" to "Ógildur tölvupóstur.",
	"invalid_user_error" to "Ógild notandaskilríki.",
	"invoice_deleted_message" to "Reikningi eytt",
	"invoice_menu" to "Valmynd reiknings",
	"invoice_number" to "Reikningur #",
	"invoices" to "Reikninga",
	"items_option" to "Vörur",
	"keep" to "Geyma",
	"language_selector" to Formattable { (selected) -> "Tungumál ${selected}" },
	"last_name_hint" to "Eftirnafn",
	"ledger" to "ledger",
	"legal" to "Löglegur",
	"legal_error" to "Verður að samþykkja þjónustuskilmála og persónuverndarstefnu",
	"liabilities" to "liabilities",
	"loading" to "Hleðsla",
	"login_required_error" to "Innskráningar krafist.",
	"logo_view" to "Merki",
	"logout" to "Nafnplata",
	"logout_prompt" to "Gögnin þín eru ekki samstillt við ský. Eyða reikningum, mati, biðlurum &öðrum upplýsingum um útskráningu?",
	"main_screen" to "Aðalskjár",
	"mark_paid" to "Merkja sem greitt",
	"message" to "Skilaboð",
	"missing_sender_msg" to "Skjal án sendandaupplýsinga gæti verið ógilt.",
	"missing_sender_title" to "Vantar upplýsingar um sendanda",
	"month_april" to "nisan",
	"month_april_short" to "nisan",
	"month_august" to "Ágúst",
	"month_august_short" to "ágúst",
	"month_december" to "Desember",
	"month_december_short" to "des.",
	"month_february" to "Febrúar",
	"month_february_short" to "feb.",
	"month_january" to "Janúar",
	"month_january_short" to "Jan.",
	"month_july" to "Júlí",
	"month_july_short" to "Júlí",
	"month_june" to "Júní",
	"month_june_short" to "Júní",
	"month_march" to "Mars",
	"month_march_short" to "Mars",
	"month_may" to "Maí",
	"month_may_short" to "Maí",
	"month_november" to "Nóvember",
	"month_november_short" to "nóv.",
	"month_october" to "Október",
	"month_october_short" to "okt.",
	"month_september" to "september",
	"month_september_short" to "sept.",
	"multifactor_error" to "Ekki tókst að sannreyna.",
	"new" to "Nýtt",
	"news" to "Fréttir",
	"next" to "Næsta",
	"no" to "no",
	"no_data_available" to "Engin gögn tiltæk",
	"no_search_results" to "Því miður fundum við engar niðurstöður.",
	"none" to "Enginn",
	"note_fields" to "Athugasemdir",
	"note_hint" to "Nóta",
	"notifications_option" to "Tilkynningar",
	"ok" to "Ókei",
	"onboarding" to "Um borð",
	"onboarding_aboutus_message" to "Nauðsynlegir hlutar DingVoice eru að eilífu ókeypis. Markmið okkar er að hjálpa þér að auka viðskipti þín, hjálpa okkur að bæta okkur með því að deila athugasemdum þínum.",
	"onboarding_aboutus_title" to "Við erum hér til að hjálpa",
	"onboarding_paid_1" to "Fáðu aðgang að fagmennskusniðmátum",
	"onboarding_paid_2" to "Skoðaðu fjárhagsstöðu þína dýpra",
	"onboarding_paid_3" to "Fáðu leyst vandamál hratt",
	"onboarding_paid_4" to "Haltu skjölunum þínum öruggum",
	"onboarding_paid_5" to "Fylgstu með breytingum á stöðu reikninga",
	"onboarding_paid_title" to "Vaxið enn hraðar með...",
	"onboarding_paywall_message" to "Njóttu allra úrvals eiginleika núna á besta verði.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Bara ${currencySymbol}${introAmount} fyrir ${introDuration} ${introPeriod} og svo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Bara ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Ókeypis fyrir ${trialDuration} ${trialPeriod} og svo bara ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Ókeypis fyrir ${trialDuration} ${trialPeriod}, síðan ${currencySymbol}${introductoryAmount} fyrir ${introductoryDuration} ${introPeriod} og svo bara ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Sjálfvirk endurnýjanleg. Hætta við hvenær sem er.",
	"onboarding_paywall_restore" to "Endurheimta Kaup",
	"onboarding_paywall_restore_failure" to "Mistókst að endurheimta kaup, reyndu aftur síðar eða hafðu samband við þjónustudeild.",
	"onboarding_paywall_restore_success" to "Endurheimt tókst!",
	"onboarding_paywall_title" to "Náðu hátign!",
	"onboarding_welcome_message" to "Fullkomið verkfærasett til að stjórna reikningum þínum og áætlunum.",
	"onboarding_welcome_title" to "Velkomin í DingVoice",
	"other_state" to "Annað",
	"overdue_state" to "Gjaldfallna",
	"paid_state" to "Greiddur",
	"password" to "Aðgangsorð",
	"password_blank_error" to "Aðgangsorðs krafist.",
	"password_mismatch_error" to "Aðgangsorð passa ekki saman.",
	"pay" to "pay",
	"payment_instructions_label" to "Greiðsluleiðbeiningar",
	"payment_reminders" to "Áminningar um greiðslu",
	"phone_number_fields" to "Símanúmer",
	"phone_number_hint" to "Símanúmer",
	"postcode_hint" to "Póstnúmer",
	"preview" to "Forsýning",
	"preview_menu_hint" to "Forskoðunarvalmynd",
	"price" to "Verð",
	"privacy_policy" to "Persónuverndarstefnu",
	"profile" to "Vangasvipur",
	"profitable_client" to "Arðvænlegasti viðskiptavinurinn þinn er...",
	"profits_report" to "Hagnaður",
	"promo_content" to "Kynningarefni",
	"quantity_hint" to "Magn",
	"quantity_label_hint" to "Merki magns",
	"query_info" to "Fyrirspurn gerir þér kleift að skrifa sérsniðna útreikninga sem vísa bæði til atriða og annarra skattareglna sem inntaks. Bættu við @ tákni og vöru eða skattalýsingu til að tengja við aðra færslu +, -, *, /, % - stærðfræði rekstraraðila",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} barst viðskiptavinur þinn." },
	"received_state" to "Tekið á móti",
	"registration_code_fields" to "Skráningarkóði",
	"reminder" to "Áminning",
	"reports" to "Skýrslur",
	"reports_not_available" to "Engar skýrslur tiltækar, komdu aftur síðar.",
	"reveal_password" to "Sýna aðgangsorð",
	"revenue" to "revenue",
	"sales_tax" to "vsk",
	"sales_tax_number" to "VSK númer",
	"save" to "Spara",
	"scan_document" to "Skannaðu skjal",
	"search" to "Leita",
	"select" to "Veldu",
	"select_icon" to "Veldu táknmynd",
	"select_signature" to "Velja undirskrift",
	"select_template" to "Velja sniðmát",
	"send" to "Senda",
	"sent_state" to "Send",
	"setup_sender" to "Settu upp sendanda",
	"share_option" to "Deila",
	"ship_to" to "Senda til",
	"sign_in" to "Skrá inn",
	"sign_in_apple" to "Skráðu þig inn með Apple",
	"sign_in_email" to "Skráðu þig inn með tölvupósti",
	"sign_in_facebook" to "Skráðu þig inn með Facebook",
	"sign_in_google" to "Skráðu þig inn með Google",
	"sign_up" to "Skrá sig",
	"sign_up_label" to "Ertu ekki með reikning?",
	"sign_up_link_label" to "Skrá sig!",
	"signatures_option" to "Undirskrift",
	"skip" to "Sleppa",
	"sold_items_report" to "Seldar vörur",
	"sort_by" to "Raða eftir:",
	"status_update" to "Uppfærsla á stöðu skjala",
	"sub_annual" to "Árlegt",
	"sub_annual_price" to Formattable { (price) -> "${price} á ári" },
	"sub_backups" to "Cloud öryggisafrit",
	"sub_insights" to "Innsýn",
	"sub_month" to "Mánaðarlega",
	"sub_monthly_price" to Formattable { (price) -> "${price} á mánuði" },
	"sub_notifications" to "Tilkynningar",
	"sub_popup_message" to "Áskriftir koma fljótlega. Kíktu aftur hér eftir smá stund.",
	"sub_support" to "Aðstoð",
	"sub_templates" to "Sniðmát",
	"subscribe" to "Gerast áskrifandi",
	"subscription_header" to "Stækkaðu fyrirtækið þitt með:",
	"subscription_option" to "Áskrift",
	"subscriptions_load_fail" to "Því miður getum við ekki sótt áskriftirnar þínar eins og er. Vinsamlegast reyndu aftur síðar eða hafðu samband við þjónustudeild okkar til að fá aðstoð.",
	"subtotal" to Formattable { (sum) -> "Tímatala: ${sum}" },
	"subtotal_label" to "Samtala",
	"sum" to "Summa",
	"support_option" to "Styðja",
	"switch_reports" to "Skipta skýrslu",
	"tax" to "Skattur",
	"tax_deleted_message" to "Skatti eytt",
	"tax_description_warning" to "Ef þetta er notað birtist þetta á reikningi",
	"tax_input_flat" to "Flatur",
	"tax_input_percent" to "Hundraðshluti",
	"tax_number_fields" to "Skattnúmer",
	"tax_number_hint" to "Skattnúmer",
	"tax_query_info_description" to "Upplýsingar um fyrirspurn",
	"tax_report" to "Skattur",
	"tax_rules_option" to "Skattareglur",
	"taxed_clients_report" to "Skattskyldir viðskiptavinir",
	"template" to "Sniðmát",
	"template_selector_info" to "Strjúktu til að breyta sjálfgefna sniðmátinu",
	"template_unlock_promo" to "Fáðu aðgang að fleiri sniðmátum með Premium",
	"templates_option" to "Sniðmát",
	"terms_of_service" to "Þjónustuskilmálar",
	"thank_you" to "Þakka þér fyrir",
	"to" to "Til",
	"total" to Formattable { (sum) -> "Samtals: ${sum}" },
	"total_label" to "Samtals",
	"undo_action" to "Afturkalla",
	"unit" to "Eining",
	"unit_cost_hint" to "Kostnaðarverð",
	"unknown_client" to "Óþekktur viðskiptavinur",
	"unlock" to "Opna",
	"unnamed_item" to "Ónefndur hlutur",
	"upgrade" to "Uppfærsla",
	"user_collision_error" to "Lykill er þegar til.",
	"valid_until" to "Gildir til",
	"warning" to "Viðvörun!",
	"yes" to "yes",
    )
    
}