// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ar  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "الموافقة على ${termsOfService}شروط الخدمة و${privacyPolicy}سياسة الخصوصية" }
override val account: String = "حساب"
override val accounting: String = "المحاسبة"
override val accruals: String = "المستحقَّات"
override val action_code_error: String = "رمز غير صالح."
override val add_client_action: String = "إضافة عميل"
override val add_customer: String = "إضافة عميل"
override val add_expense: String = "إضافة نفَقة"
override val add_fields_label: String = "جمع:"
override val add_invoice_action: String = "خلق"
override val add_item_action: String = "إضافة عنصر"
override val add_item_button: String = "إضافة عنصر"
override val add_signature_action: String = "إضافة توقيع"
override val add_tax_action: String = "إضافة ضريبة"
override val address_fields: String = "عنوان"
override val address_line_hint: String = "خط العنوان"
override val advanced: String = "متقدم"
override val agree_marketing: String = "أوافق على تلقي الاتصالات التسويقية المباشرة"
override val all_notifications: String = "جميع الإخطارات"
override val amortization: String = "استهلاك الدَّين"
override val amount: String = "مبلغ"
override val asset: String = "أصل"
override val attach_file: String = "إلحاق ملف"
override val attachment: String = "المرفقات"
override val auth_error: String = "فشل الاجراء،حاول مرة ثانية"
override val available_for_paid: String = "تتوفر مزامنة السحابة فقط ضمن الاشتراك المدفوع."
override val backups_option: String = "النسخ الاحتياطي"
override val balance: String = "الرّصيد"
override val bank_account: String = "حساب بنكي"
override val bill_number: String = "رقم الفاتورة"
override val cancel: String = "إلغاء الأمر"
override val cancel_trial: String = "ألغِ متى شئت"
override val capital: String = "رأس المال"
override val cash: String = "المبلغ نقدًّا"
override val categories: String = "الأصناف"
override val category: String = "الصنف"
override val category_add: String = "إضافة صنف"
override val category_delete: String = "حذف الصنف"
override val category_name: String = "اسم الصنف"
override val change_language: Formattable = Formattable { (language) -> "لغة الفاتورة: ${language}" }
override val change_language_label: String = "لغة الإيصالات"
override val change_template: String = "تغيير القالب"
override val city_hint: String = "مدينة"
override val clear: String = "واضح"
override val client: String = "عميل"
override val client_actions: String = "إجراءات العميل"
override val client_deleted_message: String = "تم حذف العميل"
override val clients_error_required_fields: String = "هناك حاجة إلى قيمة واحدة على الأقل."
override val clients_option: String = "العملاء"
override val close: String = "غلق"
override val cloud_sync: String = "مزامنة السحابة"
override val coming_soon: String = "قريباً!"
override val company_logo: String = "شعار الشركة"
override val company_name_hint: String = "شركة"
override val confirm_password_label: String = "تأكيد كلمة المرور"
override val confirmation_title: String = "هل أنتَ متأكّد؟"
override val convert_to_invoice: String = "التحويل إلى فاتورة"
override val country_hint: String = "بلد"
override val create_client: String = "إنشاء عميل"
override val create_company: String = "إنشاء شركة"
override val create_document: String = "إنشاء مستند"
override val create_estimate: String = "تقدير"
override val create_invoice: String = "فاتورة"
override val create_item: String = "إنشاء عنصر"
override val create_new_client: String = "إنشاء عميل جديد"
override val create_new_item: String = "إنشاء عنصر جديد"
override val create_new_tax: String = "إنشاء ضريبة جديدة"
override val create_signature: String = "توقيع"
override val created: String = "انشاء"
override val credit: String = "الحساب"
override val customer: String = "زبون"
override val date: String = "تاريخ"
override val debit: String = "الخصم"
override val debt: String = "الدَّين"
override val default_currency: Formattable = Formattable { (currency) -> "العملة الافتراضية ${currency}" }
override val default_currency_label: String = "العملة الأصليّة"
override val delete: String = "حذف"
override val delete_account: String = "حذف الحساب"
override val delete_confirmation_message: String = "ستضيعُ كلّ بياناتك."
override val delete_item_title: String = "حذف العنصر؟"
override val delete_signature: String = "حذف التوقيع"
override val depreciation: String = "تضاؤل القيمة"
override val description: String = "وصف"
override val description_hint: String = "وصف"
override val detailed_report: String = "تقرير مفصل"
override val disabled: String = "ذوي الاحتياجات الخاصه"
override val discount: String = "خصم"
override val discount_hint: String = "خصم"
override val display_af: String = "الأفريكانية"
override val display_am: String = "الأمهرية"
override val display_ar: String = "العربية"
override val display_as: String = "الأسامي"
override val display_az: String = "الأذربيجانية"
override val display_be: String = "البيلاروسية"
override val display_bg: String = "البلغارية"
override val display_bn: String = "بنغالي"
override val display_bs: String = "البوسنية"
override val display_ca: String = "الكاتالونية"
override val display_cs: String = "التشيكية"
override val display_da: String = "دانماركي"
override val display_de: String = "الألمانية"
override val display_el: String = "يوناني"
override val display_en: String = "الإنجليزية"
override val display_es: String = "الأسبانية"
override val display_es_MX: String = "الإسبانية (المكسيك)"
override val display_et: String = "الإستونية"
override val display_eu: String = "الباسك"
override val display_fa: String = "فارسي"
override val display_fi: String = "فنلندية"
override val display_fil: String = "فلبينية"
override val display_fr: String = "الفرنسية"
override val display_fr_CA: String = "الفرنسية الكندية"
override val display_gl: String = "الجاليكية"
override val display_gu: String = "الغوجاراتية"
override val display_he: String = "اللغة العبرية"
override val display_hi: String = "هندية"
override val display_hr: String = "كرواتية"
override val display_hu: String = "الهنغارية"
override val display_hy: String = "أرمينية"
override val display_id: String = "الأندونيسية"
override val display_is: String = "ايسلندي"
override val display_it: String = "إيطالي"
override val display_ja: String = "اليابانية"
override val display_ka: String = "الجورجية"
override val display_kk: String = "الكازاخية"
override val display_km: String = "خمير الوسطى"
override val display_kn: String = "الكانادا"
override val display_ko: String = "كورية"
override val display_ky: String = "قرغيز"
override val display_lo: String = "لاو"
override val display_lt: String = "الليتوانية"
override val display_lv: String = "لاتفية"
override val display_mk: String = "المقدونية"
override val display_ml: String = "المالايالامية"
override val display_mn: String = "المنغولية"
override val display_mr: String = "الماراثى"
override val display_ms: String = "لغة الملايو"
override val display_my: String = "بورمي"
override val display_nb: String = "بوكمال النرويجية"
override val display_ne: String = "النيبالية"
override val display_nl: String = "اللغة الهولندية"
override val display_or: String = "أوريا"
override val display_pa: String = "البنجابية"
override val display_pl: String = "البولندي"
override val display_pt: String = "البرتغالية"
override val display_pt_BR: String = "البرتغالية (البرازيل)"
override val display_pt_PT: String = "برتغالية أوروبية"
override val display_ro: String = "روماني"
override val display_ru: String = "الروسية"
override val display_si: String = "سنهالية"
override val display_sk: String = "السلوفاكية"
override val display_sl: String = "السلوفينية"
override val display_sq: String = "ألبانية"
override val display_sr: String = "الصربية"
override val display_sv: String = "السويدية"
override val display_sw: String = "السواحيلية"
override val display_ta: String = "التاميل"
override val display_te: String = "التيلجو"
override val display_th: String = "التايلاندية"
override val display_tr: String = "اللغة التركية"
override val display_uk: String = "أوكرانية"
override val display_ur: String = "الأردية"
override val display_uz: String = "أوزبكي"
override val display_vi: String = "فيتنامي"
override val display_zh_Hant_TW: String = "الصينية (تايوان، التقليدية)"
override val display_zu: String = "الزولو"
override val document_estimate: String = "تقدير"
override val document_invoice: String = "فاتورة"
override val document_quote: String = "هرض"
override val done: String = "منتهي"
override val download_app: String = "تنزيل التطبيق "
override val download_invoice: String = "تنزيل الفاتورة"
override val download_pdf: String = "تنزيل ملف PDF"
override val draw_signature: String = "رسم توقيع"
override val due: Formattable = Formattable { (date) -> "مستحق ${date}" }
override val due_date: String = "تاريخ الاستحقاق"
override val due_day: String = "في اليوم"
override val due_label: String = "سبب"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} مستحق ${dueWhen}!" }
override val due_today: String = "اليوم"
override val due_week: String = "في أسبوع"
override val duplicate: String = "نسخ"
override val duration_day: String = "يوم"
override val duration_month: String = "شهر"
override val duration_week: String = "أسبوع"
override val duration_year: String = "سنة"
override val email: String = "البريد الإلكتروني"
override val email_blank_error: String = "البريد الإلكتروني المطلوب."
override val email_error: String = "عنوان بريد إلكتروني غير صالح."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "نشكرك على عملك. انقر على الزرّ في خاتمة هذه الرسالة لعرض ملف ${documentType} كاملًا. ولا تنسَ أنها صالحةٌ حتّى ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (invoiceNumber, documentType) -> "وردتك للتّو فاتورة تحت رقم ${invoiceNumber} بصيغة ${documentType}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} الفاتورة" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "عزيزي ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "نشكرك على عملك. انقر على الزرّ في خاتمة هذه الرسالة لعرض ملف ${documentType} كاملًا. نلتمس منك دفع المبلغ في أجَل ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (invoiceNumber, documentType) -> "وردتك للتّو فاتورة تحت رقم ${invoiceNumber} بصيغة ${documentType}!" }
override val email_reset_success_message: String = "البريد الإلكتروني لإعادة تعيين كلمة المرور المرسلة، يرجى التحقق من البريد الوارد الخاص بك."
override val empty_clients_list_message: String = "ليس لديك عملاء لتعقبهم إضافة عميل جديد."
override val empty_estimate_list_message: String = "إنشاء التقدير الأول"
override val empty_expenses: String = "ليس لديك من نفقات لمتابعتها. أضف نفقة جديدة."
override val empty_invoice_list_message: String = "إنشاء فاتورتك الأولى"
override val empty_items_list_message: String = "ليس لديك أي عناصر، إضافة عنصر جديد!"
override val empty_signatures_list_message: String = "لم يتم العثور على أي توقيعات. خلق واحد!"
override val empty_taxes_list_message: String = "ليس لديك قواعد ضريبية إضافة قاعدة ضريبية جديدة."
override val empty_templates_list_message: String = "القوالب غير متوفرة."
override val enabled: String = "تم التمكين"
override val entry: String = "مدخل"
override val equity: String = "قيمة الأسهم"
override val estimate_number: String = "التقدير #"
override val estimates: String = "تقديرات"
override val estimates_report: String = "تقديرات"
override val expense_bank_fees: String = "الرسوم المصرفيّة"
override val expense_benefits: String = "الفوائد"
override val expense_communication: String = "التواصل"
override val expense_debt: String = "الدين"
override val expense_deleted: String = "تم مسح النفقة"
override val expense_depreciation: String = "تضاؤل القيمة"
override val expense_gifts: String = "الهدايا"
override val expense_insurance: String = "التأمين"
override val expense_inventory: String = "قائمة الجرد"
override val expense_legal: String = "الأمور القانونيّة"
override val expense_maintenance: String = "الصيانة"
override val expense_marketing: String = "التسويق"
override val expense_meals: String = "الوجبات"
override val expense_office_supplies: String = "اللوازم المكتبية"
override val expense_rent: String = "الإيجار"
override val expense_salaries: String = "الرواتب"
override val expense_shopping: String = "التّسوق"
override val expense_software: String = "البرمجيّة"
override val expense_status_available: String = "المتاحة"
override val expense_status_processing: String = "العملية جارية"
override val expense_tax: String = "الضريبة"
override val expense_training: String = "التدريب"
override val expense_transport: String = "التّنقّل"
override val expense_travel: String = "السّفر"
override val expense_utility: String = "أدوات المساعدة"
override val expenses: String = "النّفقات"
override val feedback_option: String = "ردود الفعل"
override val filter: String = "راووق"
override val first_name_hint: String = "الاسم الأول"
override val forgot_password: String = "إعادة تعيين كلمة المرور"
override val forgot_password_label: String = "نسيت كلمة المرور"
override val from: String = "من"
override val general_error: String = "حدث خطأ، يرجى المحاولة مرة أخرى لاحقًا."
override val general_ledger: String = "دفتر المحاسبة الشاملة"
override val go_back_label: String = "الرجوع للخلف"
override val group_by: String = "المجموعة حسب:"
override val image_view: String = "صورة"
override val install: String = "تثبيت"
override val invalid_credentials_error: String = "بيانات اعتماد غير صالحة."
override val invalid_email_error: String = "بريد إلكتروني غير صالح."
override val invalid_user_error: String = "بيانات اعتماد المستخدم غير صالحة "
override val invoice_deleted_message: String = "تم حذف الفاتورة"
override val invoice_menu: String = "قائمة الفاتورة"
override val invoice_number: String = "فاتورة #"
override val invoices: String = "الفواتير"
override val items_option: String = "العناصر"
override val keep: String = "حافظ"
override val language_selector: Formattable = Formattable { (selected) -> "اللغة ${selected}" }
override val last_name_hint: String = "اسم العائلة"
override val ledger: String = "دفتر محاسبة"
override val legal: String = "قانوني"
override val legal_error: String = "يجب أن تقبل شروط الخدمة وسياسة الخصوصية"
override val liabilities: String = "المسؤوليّات القانونيّة"
override val loading: String = "تحميل"
override val login_required_error: String = "تسجيل الدخول المطلوب."
override val logo_view: String = "شعار"
override val logout: String = "الخروج"
override val logout_prompt: String = "البيانات غير متزامنة مع مجموعة النظراء. حذف الفواتير والتقديرات والعملاء وغيرها من المعلومات على تسجيل الخروج؟"
override val main_screen: String = "الشاشة الرئيسية"
override val mark_paid: String = "علامة الدفع"
override val message: String = "رسالة"
override val missing_sender_msg: String = "قد تتعذّر المصادقة على ملفّ لا يحمل معلومات المرسل."
override val missing_sender_title: String = "معلومات المرسل غير موجودة"
override val month_april: String = "أبريل"
override val month_april_short: String = "أبريل"
override val month_august: String = "شهر اغسطس"
override val month_august_short: String = "أوت"
override val month_december: String = "ديسمبر"
override val month_december_short: String = "ديسمبر"
override val month_february: String = "شهر فبراير"
override val month_february_short: String = "فيفري"
override val month_january: String = "كانون الثاني"
override val month_january_short: String = "جانفي"
override val month_july: String = "تموز"
override val month_july_short: String = "جويلية"
override val month_june: String = "يونيو"
override val month_june_short: String = "يونيو"
override val month_march: String = "آذار"
override val month_march_short: String = "آذار"
override val month_may: String = "مايو"
override val month_may_short: String = "مايو"
override val month_november: String = "شهر نوفمبر"
override val month_november_short: String = "نوفمبر"
override val month_october: String = "اكتوبر"
override val month_october_short: String = "أكتوبر"
override val month_september: String = "سبتمبر"
override val month_september_short: String = "سبتمبر"
override val multifactor_error: String = "فشل التحقيق"
override val new: String = "جديد"
override val news: String = "خبر"
override val next: String = "التالي"
override val no: String = "لا"
override val no_data_available: String = "لا توجد بيانات متاحة"
override val no_search_results: String = "عذرا، لم نتمكن من العثور على أي نتائج."
override val none: String = "لا شيء"
override val note_fields: String = "تلاحظ"
override val note_hint: String = "ملاحظه"
override val notifications_option: String = "الاخطارات"
override val ok: String = "موافق"
override val onboarding: String = "على متن الطائرة"
override val onboarding_aboutus_message: String = "الأجزاء الأساسية من DingVoice مجانية إلى الأبد. مهمتنا هي مساعدتك على تنمية عملك ، ومساعدتنا على التحسين من خلال مشاركة ملاحظاتك."
override val onboarding_aboutus_title: String = "نحن هنا للمساعدة"
override val onboarding_paid_1: String = "الوصول إلى قوالب ذات مظهر احترافي"
override val onboarding_paid_2: String = "ألق نظرة أعمق على بياناتك المالية"
override val onboarding_paid_3: String = "حل المشاكل بسرعة"
override val onboarding_paid_4: String = "حافظ على مستنداتك آمنة"
override val onboarding_paid_5: String = "ابق على اطلاع دائم بتغييرات حالة الفاتورة"
override val onboarding_paid_title: String = "تنمو بشكل أسرع مع ..."
override val onboarding_paywall_message: String = "استمتع بجميع الميزات المميزة الآن بأفضل سعر."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "فقط ${currencySymbol} ${introAmount} مقابل ${introDuration} ${introPeriod} ثم ${currencySymbol} ${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "فقط ${currencySymbol} ${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "مجانًا مقابل ${trialDuration} ${trialPeriod} ثم ${currencySymbol} ${amount} / ${duration} فقط" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "مجانًا مقابل ${trialDuration} ${trialPeriod} ثم ${currencySymbol} ${introductoryAmount} مقابل ${introductoryDuration} ${introPeriod} ثم ${currencySymbol} ${amount} / ${duration} فقط" }
override val onboarding_paywall_renewable: String = "قابلة للتجديد التلقائي. يمكنك الإلغاء في أي وقت."
override val onboarding_paywall_restore: String = "استعادة شراء"
override val onboarding_paywall_restore_failure: String = "فشلت استعادة المشتريات ، حاول مرة أخرى لاحقًا أو اتصل بالدعم."
override val onboarding_paywall_restore_success: String = "تمت الاستعادة بنجاح!"
override val onboarding_paywall_title: String = "تحقيق العظمة!"
override val onboarding_welcome_message: String = "مجموعة أدوات كاملة لإدارة فواتيرك وتقديراتك."
override val onboarding_welcome_title: String = "مرحبًا بك في DingVoice"
override val other_state: String = "أخرى"
override val overdue_state: String = "المتاخره"
override val paid_state: String = "مدفوع"
override val password: String = "شعار"
override val password_blank_error: String = "كلمة المرور المطلوبة."
override val password_mismatch_error: String = "كلمات المرور غير متطابقة."
override val pay: String = "دفع"
override val payment_instructions_label: String = "تعليمات الدفع"
override val payment_reminders: String = "تذكيرات الدفع"
override val phone_number_fields: String = "رقم الهاتف"
override val phone_number_hint: String = "رقم الهاتف"
override val postcode_hint: String = "الرمز البريدي"
override val preview: String = "معاينه"
override val preview_menu_hint: String = "قائمة المعاينة "
override val price: String = "ثمن"
override val privacy_policy: String = "سياسة الخصوصية"
override val profile: String = "ملف تعريف"
override val profitable_client: String = "عميلك الأكثر ربحية هو ..."
override val profits_report: String = "الارباح"
override val promo_content: String = "المحتوى الترويجي"
override val quantity_hint: String = "كم"
override val quantity_label_hint: String = "تسمية الكمية"
override val query_info: String = "يسمح لك الاستعلام بكتابة حسابات مخصصة تشير إلى كل من العناصر وقواعد الضريبة الأخرى كمدخلات. إضافة @ رمز والبند أو وصف الضريبة لربط إدخال آخر +، -، *، / ، ٪ - مشغلي الرياضيات"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "تم استلام ${documentType} ${documentNumber} بواسطة عميلك" }
override val received_state: String = "تم الاستلام"
override val registration_code_fields: String = "رمز التسجيل"
override val reminder: String = "التذكير"
override val reports: String = "التقارير"
override val reports_not_available: String = "لا توجد تقارير متاحة، عد لاحقًا."
override val reveal_password: String = "كشف كلمة المرور"
override val revenue: String = "الدّخل"
override val sales_tax: String = "ضريبة القيمة المضافة"
override val sales_tax_number: String = "رقم ضريبة القيمة المضافة"
override val save: String = "أنقذ"
override val scan_document: String = "مسح الملف ضوئيًّا"
override val search: String = "بحث"
override val select: String = "اختيار"
override val select_icon: String = "اختر الأيقونة"
override val select_signature: String = "تحديد التوقيع"
override val select_template: String = "تحديد قالب"
override val send: String = "ارسل"
override val sent_state: String = "ارسال"
override val setup_sender: String = "تضمين المرسل"
override val share_option: String = "سهم"
override val ship_to: String = "اشحن إلى"
override val sign_in: String = "تسجيل الدخول"
override val sign_in_apple: String = "تسجيل الدخول باستخدام Apple"
override val sign_in_email: String = "تسجيل الدخول باستخدام البريد الإلكتروني"
override val sign_in_facebook: String = "تسجيل الدخول باستخدام فيسبوك"
override val sign_in_google: String = "تسجيل الدخول باستخدام Google"
override val sign_up: String = "التسجيل"
override val sign_up_label: String = "ليس لديك حساب؟"
override val sign_up_link_label: String = "التسجيل!"
override val signatures_option: String = "التواقيع"
override val skip: String = "يتخطى"
override val sold_items_report: String = "العناصر المباعة"
override val sort_by: String = "الفرز حسب:"
override val status_update: String = "تحديث حالة المستند"
override val sub_annual: String = "سنوي"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/السنة" }
override val sub_backups: String = "النسخ الاحتياطية السحابية"
override val sub_insights: String = "رؤى"
override val sub_month: String = "شهريا"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/شهر" }
override val sub_notifications: String = "الاخطارات"
override val sub_popup_message: String = "الاشتراكات ستأتي قريبًا. تحقق مرة أخرى هنا في بعض الوقت."
override val sub_support: String = "دعم"
override val sub_templates: String = "قوالب"
override val subscribe: String = "اشترك"
override val subscription_header: String = "قم بتنمية أعمالك مع:"
override val subscription_option: String = "اكتتاب"
override val subscriptions_load_fail: String = "عذرًا ، لا يمكننا استرداد اشتراكاتك في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بفريق الدعم للحصول على المساعدة."
override val subtotal: Formattable = Formattable { (sum) -> "المجموع الفرعي: ${sum}" }
override val subtotal_label: String = "المجموع الفرعي"
override val sum: String = "مجموع"
override val support_option: String = "دعم"
override val switch_reports: String = "تبديل التقرير"
override val tax: String = "ضريبة"
override val tax_deleted_message: String = "تم حذف الضريبة"
override val tax_description_warning: String = "إذا تم استخدام هذا يظهر على فاتورة"
override val tax_input_flat: String = "وطيء"
override val tax_input_percent: String = "المائه"
override val tax_number_fields: String = "رقم الضريبة"
override val tax_number_hint: String = "رقم الضريبة"
override val tax_query_info_description: String = "معلومات الاستعلام"
override val tax_report: String = "ضريبة"
override val tax_rules_option: String = "القواعد الضريبية"
override val taxed_clients_report: String = "العملاء الخاضعين للضريبة"
override val template: String = "قالب"
override val template_selector_info: String = "السحب لتغيير القالب الافتراضي"
override val template_unlock_promo: String = "الوصول إلى المزيد من القوالب باستخدام Premium"
override val templates_option: String = "قوالب"
override val terms_of_service: String = "شروط الخدمة"
override val thank_you: String = "شكرا لك"
override val to: String = "ل"
override val total: Formattable = Formattable { (sum) -> "المجموع: ${sum}" }
override val total_label: String = "المجموع"
override val undo_action: String = "تراجع"
override val unit: String = "وحدة"
override val unit_cost_hint: String = "تكلفة الوحدة"
override val unknown_client: String = "عميل غير معروف"
override val unlock: String = "فتح القفل"
override val unnamed_item: String = "عنصر غير مسمى"
override val upgrade: String = "ترقيه"
override val user_collision_error: String = "الحساب موجود بالفعل."
override val valid_until: String = "تاريخ انتهاء الصلاحية"
override val warning: String = "تحذير!"
override val yes: String = "نَعَم"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "الموافقة على ${termsOfService}شروط الخدمة و${privacyPolicy}سياسة الخصوصية" },
	"account" to "حساب",
	"accounting" to "المحاسبة",
	"accruals" to "المستحقَّات",
	"action_code_error" to "رمز غير صالح.",
	"add_client_action" to "إضافة عميل",
	"add_customer" to "إضافة عميل",
	"add_expense" to "إضافة نفَقة",
	"add_fields_label" to "جمع:",
	"add_invoice_action" to "خلق",
	"add_item_action" to "إضافة عنصر",
	"add_item_button" to "إضافة عنصر",
	"add_signature_action" to "إضافة توقيع",
	"add_tax_action" to "إضافة ضريبة",
	"address_fields" to "عنوان",
	"address_line_hint" to "خط العنوان",
	"advanced" to "متقدم",
	"agree_marketing" to "أوافق على تلقي الاتصالات التسويقية المباشرة",
	"all_notifications" to "جميع الإخطارات",
	"amortization" to "استهلاك الدَّين",
	"amount" to "مبلغ",
	"asset" to "أصل",
	"attach_file" to "إلحاق ملف",
	"attachment" to "المرفقات",
	"auth_error" to "فشل الاجراء،حاول مرة ثانية",
	"available_for_paid" to "تتوفر مزامنة السحابة فقط ضمن الاشتراك المدفوع.",
	"backups_option" to "النسخ الاحتياطي",
	"balance" to "الرّصيد",
	"bank_account" to "حساب بنكي",
	"bill_number" to "رقم الفاتورة",
	"cancel" to "إلغاء الأمر",
	"cancel_trial" to "ألغِ متى شئت",
	"capital" to "رأس المال",
	"cash" to "المبلغ نقدًّا",
	"categories" to "الأصناف",
	"category" to "الصنف",
	"category_add" to "إضافة صنف",
	"category_delete" to "حذف الصنف",
	"category_name" to "اسم الصنف",
	"change_language" to Formattable { (language) -> "لغة الفاتورة: ${language}" },
	"change_language_label" to "لغة الإيصالات",
	"change_template" to "تغيير القالب",
	"city_hint" to "مدينة",
	"clear" to "واضح",
	"client" to "عميل",
	"client_actions" to "إجراءات العميل",
	"client_deleted_message" to "تم حذف العميل",
	"clients_error_required_fields" to "هناك حاجة إلى قيمة واحدة على الأقل.",
	"clients_option" to "العملاء",
	"close" to "غلق",
	"cloud_sync" to "مزامنة السحابة",
	"coming_soon" to "قريباً!",
	"company_logo" to "شعار الشركة",
	"company_name_hint" to "شركة",
	"confirm_password_label" to "تأكيد كلمة المرور",
	"confirmation_title" to "هل أنتَ متأكّد؟",
	"convert_to_invoice" to "التحويل إلى فاتورة",
	"country_hint" to "بلد",
	"create_client" to "إنشاء عميل",
	"create_company" to "إنشاء شركة",
	"create_document" to "إنشاء مستند",
	"create_estimate" to "تقدير",
	"create_invoice" to "فاتورة",
	"create_item" to "إنشاء عنصر",
	"create_new_client" to "إنشاء عميل جديد",
	"create_new_item" to "إنشاء عنصر جديد",
	"create_new_tax" to "إنشاء ضريبة جديدة",
	"create_signature" to "توقيع",
	"created" to "انشاء",
	"credit" to "الحساب",
	"customer" to "زبون",
	"date" to "تاريخ",
	"debit" to "الخصم",
	"debt" to "الدَّين",
	"default_currency" to Formattable { (currency) -> "العملة الافتراضية ${currency}" },
	"default_currency_label" to "العملة الأصليّة",
	"delete" to "حذف",
	"delete_account" to "حذف الحساب",
	"delete_confirmation_message" to "ستضيعُ كلّ بياناتك.",
	"delete_item_title" to "حذف العنصر؟",
	"delete_signature" to "حذف التوقيع",
	"depreciation" to "تضاؤل القيمة",
	"description" to "وصف",
	"description_hint" to "وصف",
	"detailed_report" to "تقرير مفصل",
	"disabled" to "ذوي الاحتياجات الخاصه",
	"discount" to "خصم",
	"discount_hint" to "خصم",
	"display_af" to "الأفريكانية",
	"display_am" to "الأمهرية",
	"display_ar" to "العربية",
	"display_as" to "الأسامي",
	"display_az" to "الأذربيجانية",
	"display_be" to "البيلاروسية",
	"display_bg" to "البلغارية",
	"display_bn" to "بنغالي",
	"display_bs" to "البوسنية",
	"display_ca" to "الكاتالونية",
	"display_cs" to "التشيكية",
	"display_da" to "دانماركي",
	"display_de" to "الألمانية",
	"display_el" to "يوناني",
	"display_en" to "الإنجليزية",
	"display_es" to "الأسبانية",
	"display_es_MX" to "الإسبانية (المكسيك)",
	"display_et" to "الإستونية",
	"display_eu" to "الباسك",
	"display_fa" to "فارسي",
	"display_fi" to "فنلندية",
	"display_fil" to "فلبينية",
	"display_fr" to "الفرنسية",
	"display_fr_CA" to "الفرنسية الكندية",
	"display_gl" to "الجاليكية",
	"display_gu" to "الغوجاراتية",
	"display_he" to "اللغة العبرية",
	"display_hi" to "هندية",
	"display_hr" to "كرواتية",
	"display_hu" to "الهنغارية",
	"display_hy" to "أرمينية",
	"display_id" to "الأندونيسية",
	"display_is" to "ايسلندي",
	"display_it" to "إيطالي",
	"display_ja" to "اليابانية",
	"display_ka" to "الجورجية",
	"display_kk" to "الكازاخية",
	"display_km" to "خمير الوسطى",
	"display_kn" to "الكانادا",
	"display_ko" to "كورية",
	"display_ky" to "قرغيز",
	"display_lo" to "لاو",
	"display_lt" to "الليتوانية",
	"display_lv" to "لاتفية",
	"display_mk" to "المقدونية",
	"display_ml" to "المالايالامية",
	"display_mn" to "المنغولية",
	"display_mr" to "الماراثى",
	"display_ms" to "لغة الملايو",
	"display_my" to "بورمي",
	"display_nb" to "بوكمال النرويجية",
	"display_ne" to "النيبالية",
	"display_nl" to "اللغة الهولندية",
	"display_or" to "أوريا",
	"display_pa" to "البنجابية",
	"display_pl" to "البولندي",
	"display_pt" to "البرتغالية",
	"display_pt_BR" to "البرتغالية (البرازيل)",
	"display_pt_PT" to "برتغالية أوروبية",
	"display_ro" to "روماني",
	"display_ru" to "الروسية",
	"display_si" to "سنهالية",
	"display_sk" to "السلوفاكية",
	"display_sl" to "السلوفينية",
	"display_sq" to "ألبانية",
	"display_sr" to "الصربية",
	"display_sv" to "السويدية",
	"display_sw" to "السواحيلية",
	"display_ta" to "التاميل",
	"display_te" to "التيلجو",
	"display_th" to "التايلاندية",
	"display_tr" to "اللغة التركية",
	"display_uk" to "أوكرانية",
	"display_ur" to "الأردية",
	"display_uz" to "أوزبكي",
	"display_vi" to "فيتنامي",
	"display_zh_Hant_TW" to "الصينية (تايوان، التقليدية)",
	"display_zu" to "الزولو",
	"document_estimate" to "تقدير",
	"document_invoice" to "فاتورة",
	"document_quote" to "هرض",
	"done" to "منتهي",
	"download_app" to "تنزيل التطبيق ",
	"download_invoice" to "تنزيل الفاتورة",
	"download_pdf" to "تنزيل ملف PDF",
	"draw_signature" to "رسم توقيع",
	"due" to Formattable { (date) -> "مستحق ${date}" },
	"due_date" to "تاريخ الاستحقاق",
	"due_day" to "في اليوم",
	"due_label" to "سبب",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} مستحق ${dueWhen}!" },
	"due_today" to "اليوم",
	"due_week" to "في أسبوع",
	"duplicate" to "نسخ",
	"duration_day" to "يوم",
	"duration_month" to "شهر",
	"duration_week" to "أسبوع",
	"duration_year" to "سنة",
	"email" to "البريد الإلكتروني",
	"email_blank_error" to "البريد الإلكتروني المطلوب.",
	"email_error" to "عنوان بريد إلكتروني غير صالح.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "نشكرك على عملك. انقر على الزرّ في خاتمة هذه الرسالة لعرض ملف ${documentType} كاملًا. ولا تنسَ أنها صالحةٌ حتّى ${dueDate}." },
	"email_estimate_title" to Formattable { (invoiceNumber, documentType) -> "وردتك للتّو فاتورة تحت رقم ${invoiceNumber} بصيغة ${documentType}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} الفاتورة" },
	"email_invoice_greeting" to Formattable { (clientName) -> "عزيزي ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "نشكرك على عملك. انقر على الزرّ في خاتمة هذه الرسالة لعرض ملف ${documentType} كاملًا. نلتمس منك دفع المبلغ في أجَل ${dueDate}." },
	"email_invoice_title" to Formattable { (invoiceNumber, documentType) -> "وردتك للتّو فاتورة تحت رقم ${invoiceNumber} بصيغة ${documentType}!" },
	"email_reset_success_message" to "البريد الإلكتروني لإعادة تعيين كلمة المرور المرسلة، يرجى التحقق من البريد الوارد الخاص بك.",
	"empty_clients_list_message" to "ليس لديك عملاء لتعقبهم إضافة عميل جديد.",
	"empty_estimate_list_message" to "إنشاء التقدير الأول",
	"empty_expenses" to "ليس لديك من نفقات لمتابعتها. أضف نفقة جديدة.",
	"empty_invoice_list_message" to "إنشاء فاتورتك الأولى",
	"empty_items_list_message" to "ليس لديك أي عناصر، إضافة عنصر جديد!",
	"empty_signatures_list_message" to "لم يتم العثور على أي توقيعات. خلق واحد!",
	"empty_taxes_list_message" to "ليس لديك قواعد ضريبية إضافة قاعدة ضريبية جديدة.",
	"empty_templates_list_message" to "القوالب غير متوفرة.",
	"enabled" to "تم التمكين",
	"entry" to "مدخل",
	"equity" to "قيمة الأسهم",
	"estimate_number" to "التقدير #",
	"estimates" to "تقديرات",
	"estimates_report" to "تقديرات",
	"expense_bank_fees" to "الرسوم المصرفيّة",
	"expense_benefits" to "الفوائد",
	"expense_communication" to "التواصل",
	"expense_debt" to "الدين",
	"expense_deleted" to "تم مسح النفقة",
	"expense_depreciation" to "تضاؤل القيمة",
	"expense_gifts" to "الهدايا",
	"expense_insurance" to "التأمين",
	"expense_inventory" to "قائمة الجرد",
	"expense_legal" to "الأمور القانونيّة",
	"expense_maintenance" to "الصيانة",
	"expense_marketing" to "التسويق",
	"expense_meals" to "الوجبات",
	"expense_office_supplies" to "اللوازم المكتبية",
	"expense_rent" to "الإيجار",
	"expense_salaries" to "الرواتب",
	"expense_shopping" to "التّسوق",
	"expense_software" to "البرمجيّة",
	"expense_status_available" to "المتاحة",
	"expense_status_processing" to "العملية جارية",
	"expense_tax" to "الضريبة",
	"expense_training" to "التدريب",
	"expense_transport" to "التّنقّل",
	"expense_travel" to "السّفر",
	"expense_utility" to "أدوات المساعدة",
	"expenses" to "النّفقات",
	"feedback_option" to "ردود الفعل",
	"filter" to "راووق",
	"first_name_hint" to "الاسم الأول",
	"forgot_password" to "إعادة تعيين كلمة المرور",
	"forgot_password_label" to "نسيت كلمة المرور",
	"from" to "من",
	"general_error" to "حدث خطأ، يرجى المحاولة مرة أخرى لاحقًا.",
	"general_ledger" to "دفتر المحاسبة الشاملة",
	"go_back_label" to "الرجوع للخلف",
	"group_by" to "المجموعة حسب:",
	"image_view" to "صورة",
	"install" to "تثبيت",
	"invalid_credentials_error" to "بيانات اعتماد غير صالحة.",
	"invalid_email_error" to "بريد إلكتروني غير صالح.",
	"invalid_user_error" to "بيانات اعتماد المستخدم غير صالحة ",
	"invoice_deleted_message" to "تم حذف الفاتورة",
	"invoice_menu" to "قائمة الفاتورة",
	"invoice_number" to "فاتورة #",
	"invoices" to "الفواتير",
	"items_option" to "العناصر",
	"keep" to "حافظ",
	"language_selector" to Formattable { (selected) -> "اللغة ${selected}" },
	"last_name_hint" to "اسم العائلة",
	"ledger" to "دفتر محاسبة",
	"legal" to "قانوني",
	"legal_error" to "يجب أن تقبل شروط الخدمة وسياسة الخصوصية",
	"liabilities" to "المسؤوليّات القانونيّة",
	"loading" to "تحميل",
	"login_required_error" to "تسجيل الدخول المطلوب.",
	"logo_view" to "شعار",
	"logout" to "الخروج",
	"logout_prompt" to "البيانات غير متزامنة مع مجموعة النظراء. حذف الفواتير والتقديرات والعملاء وغيرها من المعلومات على تسجيل الخروج؟",
	"main_screen" to "الشاشة الرئيسية",
	"mark_paid" to "علامة الدفع",
	"message" to "رسالة",
	"missing_sender_msg" to "قد تتعذّر المصادقة على ملفّ لا يحمل معلومات المرسل.",
	"missing_sender_title" to "معلومات المرسل غير موجودة",
	"month_april" to "أبريل",
	"month_april_short" to "أبريل",
	"month_august" to "شهر اغسطس",
	"month_august_short" to "أوت",
	"month_december" to "ديسمبر",
	"month_december_short" to "ديسمبر",
	"month_february" to "شهر فبراير",
	"month_february_short" to "فيفري",
	"month_january" to "كانون الثاني",
	"month_january_short" to "جانفي",
	"month_july" to "تموز",
	"month_july_short" to "جويلية",
	"month_june" to "يونيو",
	"month_june_short" to "يونيو",
	"month_march" to "آذار",
	"month_march_short" to "آذار",
	"month_may" to "مايو",
	"month_may_short" to "مايو",
	"month_november" to "شهر نوفمبر",
	"month_november_short" to "نوفمبر",
	"month_october" to "اكتوبر",
	"month_october_short" to "أكتوبر",
	"month_september" to "سبتمبر",
	"month_september_short" to "سبتمبر",
	"multifactor_error" to "فشل التحقيق",
	"new" to "جديد",
	"news" to "خبر",
	"next" to "التالي",
	"no" to "لا",
	"no_data_available" to "لا توجد بيانات متاحة",
	"no_search_results" to "عذرا، لم نتمكن من العثور على أي نتائج.",
	"none" to "لا شيء",
	"note_fields" to "تلاحظ",
	"note_hint" to "ملاحظه",
	"notifications_option" to "الاخطارات",
	"ok" to "موافق",
	"onboarding" to "على متن الطائرة",
	"onboarding_aboutus_message" to "الأجزاء الأساسية من DingVoice مجانية إلى الأبد. مهمتنا هي مساعدتك على تنمية عملك ، ومساعدتنا على التحسين من خلال مشاركة ملاحظاتك.",
	"onboarding_aboutus_title" to "نحن هنا للمساعدة",
	"onboarding_paid_1" to "الوصول إلى قوالب ذات مظهر احترافي",
	"onboarding_paid_2" to "ألق نظرة أعمق على بياناتك المالية",
	"onboarding_paid_3" to "حل المشاكل بسرعة",
	"onboarding_paid_4" to "حافظ على مستنداتك آمنة",
	"onboarding_paid_5" to "ابق على اطلاع دائم بتغييرات حالة الفاتورة",
	"onboarding_paid_title" to "تنمو بشكل أسرع مع ...",
	"onboarding_paywall_message" to "استمتع بجميع الميزات المميزة الآن بأفضل سعر.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "فقط ${currencySymbol} ${introAmount} مقابل ${introDuration} ${introPeriod} ثم ${currencySymbol} ${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "فقط ${currencySymbol} ${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "مجانًا مقابل ${trialDuration} ${trialPeriod} ثم ${currencySymbol} ${amount} / ${duration} فقط" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "مجانًا مقابل ${trialDuration} ${trialPeriod} ثم ${currencySymbol} ${introductoryAmount} مقابل ${introductoryDuration} ${introPeriod} ثم ${currencySymbol} ${amount} / ${duration} فقط" },
	"onboarding_paywall_renewable" to "قابلة للتجديد التلقائي. يمكنك الإلغاء في أي وقت.",
	"onboarding_paywall_restore" to "استعادة شراء",
	"onboarding_paywall_restore_failure" to "فشلت استعادة المشتريات ، حاول مرة أخرى لاحقًا أو اتصل بالدعم.",
	"onboarding_paywall_restore_success" to "تمت الاستعادة بنجاح!",
	"onboarding_paywall_title" to "تحقيق العظمة!",
	"onboarding_welcome_message" to "مجموعة أدوات كاملة لإدارة فواتيرك وتقديراتك.",
	"onboarding_welcome_title" to "مرحبًا بك في DingVoice",
	"other_state" to "أخرى",
	"overdue_state" to "المتاخره",
	"paid_state" to "مدفوع",
	"password" to "شعار",
	"password_blank_error" to "كلمة المرور المطلوبة.",
	"password_mismatch_error" to "كلمات المرور غير متطابقة.",
	"pay" to "دفع",
	"payment_instructions_label" to "تعليمات الدفع",
	"payment_reminders" to "تذكيرات الدفع",
	"phone_number_fields" to "رقم الهاتف",
	"phone_number_hint" to "رقم الهاتف",
	"postcode_hint" to "الرمز البريدي",
	"preview" to "معاينه",
	"preview_menu_hint" to "قائمة المعاينة ",
	"price" to "ثمن",
	"privacy_policy" to "سياسة الخصوصية",
	"profile" to "ملف تعريف",
	"profitable_client" to "عميلك الأكثر ربحية هو ...",
	"profits_report" to "الارباح",
	"promo_content" to "المحتوى الترويجي",
	"quantity_hint" to "كم",
	"quantity_label_hint" to "تسمية الكمية",
	"query_info" to "يسمح لك الاستعلام بكتابة حسابات مخصصة تشير إلى كل من العناصر وقواعد الضريبة الأخرى كمدخلات. إضافة @ رمز والبند أو وصف الضريبة لربط إدخال آخر +، -، *، / ، ٪ - مشغلي الرياضيات",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "تم استلام ${documentType} ${documentNumber} بواسطة عميلك" },
	"received_state" to "تم الاستلام",
	"registration_code_fields" to "رمز التسجيل",
	"reminder" to "التذكير",
	"reports" to "التقارير",
	"reports_not_available" to "لا توجد تقارير متاحة، عد لاحقًا.",
	"reveal_password" to "كشف كلمة المرور",
	"revenue" to "الدّخل",
	"sales_tax" to "ضريبة القيمة المضافة",
	"sales_tax_number" to "رقم ضريبة القيمة المضافة",
	"save" to "أنقذ",
	"scan_document" to "مسح الملف ضوئيًّا",
	"search" to "بحث",
	"select" to "اختيار",
	"select_icon" to "اختر الأيقونة",
	"select_signature" to "تحديد التوقيع",
	"select_template" to "تحديد قالب",
	"send" to "ارسل",
	"sent_state" to "ارسال",
	"setup_sender" to "تضمين المرسل",
	"share_option" to "سهم",
	"ship_to" to "اشحن إلى",
	"sign_in" to "تسجيل الدخول",
	"sign_in_apple" to "تسجيل الدخول باستخدام Apple",
	"sign_in_email" to "تسجيل الدخول باستخدام البريد الإلكتروني",
	"sign_in_facebook" to "تسجيل الدخول باستخدام فيسبوك",
	"sign_in_google" to "تسجيل الدخول باستخدام Google",
	"sign_up" to "التسجيل",
	"sign_up_label" to "ليس لديك حساب؟",
	"sign_up_link_label" to "التسجيل!",
	"signatures_option" to "التواقيع",
	"skip" to "يتخطى",
	"sold_items_report" to "العناصر المباعة",
	"sort_by" to "الفرز حسب:",
	"status_update" to "تحديث حالة المستند",
	"sub_annual" to "سنوي",
	"sub_annual_price" to Formattable { (price) -> "${price}/السنة" },
	"sub_backups" to "النسخ الاحتياطية السحابية",
	"sub_insights" to "رؤى",
	"sub_month" to "شهريا",
	"sub_monthly_price" to Formattable { (price) -> "${price}/شهر" },
	"sub_notifications" to "الاخطارات",
	"sub_popup_message" to "الاشتراكات ستأتي قريبًا. تحقق مرة أخرى هنا في بعض الوقت.",
	"sub_support" to "دعم",
	"sub_templates" to "قوالب",
	"subscribe" to "اشترك",
	"subscription_header" to "قم بتنمية أعمالك مع:",
	"subscription_option" to "اكتتاب",
	"subscriptions_load_fail" to "عذرًا ، لا يمكننا استرداد اشتراكاتك في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بفريق الدعم للحصول على المساعدة.",
	"subtotal" to Formattable { (sum) -> "المجموع الفرعي: ${sum}" },
	"subtotal_label" to "المجموع الفرعي",
	"sum" to "مجموع",
	"support_option" to "دعم",
	"switch_reports" to "تبديل التقرير",
	"tax" to "ضريبة",
	"tax_deleted_message" to "تم حذف الضريبة",
	"tax_description_warning" to "إذا تم استخدام هذا يظهر على فاتورة",
	"tax_input_flat" to "وطيء",
	"tax_input_percent" to "المائه",
	"tax_number_fields" to "رقم الضريبة",
	"tax_number_hint" to "رقم الضريبة",
	"tax_query_info_description" to "معلومات الاستعلام",
	"tax_report" to "ضريبة",
	"tax_rules_option" to "القواعد الضريبية",
	"taxed_clients_report" to "العملاء الخاضعين للضريبة",
	"template" to "قالب",
	"template_selector_info" to "السحب لتغيير القالب الافتراضي",
	"template_unlock_promo" to "الوصول إلى المزيد من القوالب باستخدام Premium",
	"templates_option" to "قوالب",
	"terms_of_service" to "شروط الخدمة",
	"thank_you" to "شكرا لك",
	"to" to "ل",
	"total" to Formattable { (sum) -> "المجموع: ${sum}" },
	"total_label" to "المجموع",
	"undo_action" to "تراجع",
	"unit" to "وحدة",
	"unit_cost_hint" to "تكلفة الوحدة",
	"unknown_client" to "عميل غير معروف",
	"unlock" to "فتح القفل",
	"unnamed_item" to "عنصر غير مسمى",
	"upgrade" to "ترقيه",
	"user_collision_error" to "الحساب موجود بالفعل.",
	"valid_until" to "تاريخ انتهاء الصلاحية",
	"warning" to "تحذير!",
	"yes" to "نَعَم",
    )
    
}