// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Hy  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Ընդունեք ${termsOfService} և ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Անվավեր կոդը:"
override val add_client_action: String = "Ավելացնել հաճախորդ"
override val add_customer: String = "Ավելացնել հաճախորդ"
override val add_expense: String = "Ավելացրեք ծախսեր"
override val add_fields_label: String = "Համալրել"
override val add_invoice_action: String = "Ստեղծել"
override val add_item_action: String = "Ավելացնել տարր"
override val add_item_button: String = "Ավելացնել տարր"
override val add_signature_action: String = "Ավելացնել ստորագրություն"
override val add_tax_action: String = "Ավելացնել հարկ"
override val address_fields: String = "Հասցե"
override val address_line_hint: String = "Հասցեատող"
override val advanced: String = "Ընդլայնված"
override val agree_marketing: String = "Ես համաձայն եմ ստանալ ուղղակի մարքեթինգային հաղորդակցություն"
override val all_notifications: String = "Բոլոր ծանուցումները"
override val amortization: String = "amortization"
override val amount: String = "Գումարը"
override val asset: String = "asset"
override val attach_file: String = "Կցել ֆայլը"
override val attachment: String = "Հավելված"
override val auth_error: String = "Գործողությունը ձախողվեց, նորից փորձեք:"
override val available_for_paid: String = "Cloud sync-ը հասանելի է միայն վճարովի բաժանորդագրության դեպքում:"
override val backups_option: String = "Կրկնօրինակներ"
override val balance: String = "balance"
override val bank_account: String = "Բանկային հաշիվ"
override val bill_number: String = "Հաշվարկի համարը"
override val cancel: String = "Չեղարկել"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Կատեգորիաներ"
override val category: String = "Կարգավիճակ"
override val category_add: String = "Ավելացնել կատեգորիա"
override val category_delete: String = "Ջնջել կատեգորիան"
override val category_name: String = "Կատեգորիայի անվանումը"
override val change_language: Formattable = Formattable { (language) -> "Ապրանքագրի լեզուն՝ ${language}" }
override val change_language_label: String = "Հաշիվ-ապրանքագրի լեզուն"
override val change_template: String = "Փոխել ձևանմուշը"
override val city_hint: String = "Քաղաք"
override val clear: String = "Պարզ"
override val client: String = "Հաճախորդ"
override val client_actions: String = "Հաճախորդի գործողություններ"
override val client_deleted_message: String = "Հաճախորդը ջնջվել է"
override val clients_error_required_fields: String = "Պահանջվում է առնվազն մեկ արժեք:"
override val clients_option: String = "Հաճախորդներ"
override val close: String = "փակել"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "Շուտով!"
override val company_logo: String = "Ընկերության լոգոն"
override val company_name_hint: String = "Ընկերություն"
override val confirm_password_label: String = "Հաստատել գաղտնաբառը"
override val confirmation_title: String = "Վստահ եք?"
override val convert_to_invoice: String = "Փոխակերպել հաշիվ-ապրանքագրի"
override val country_hint: String = "Երկիրը"
override val create_client: String = "Ստեղծել հաճախորդ"
override val create_company: String = "Ստեղծել ընկերություն"
override val create_document: String = "Ստեղծել փաստաթուղթ"
override val create_estimate: String = "Գնահատում"
override val create_invoice: String = "Հաշիվ-ապրանքագիր"
override val create_item: String = "Ստեղծել նյութ"
override val create_new_client: String = "Ստեղծեք նոր հաճախորդ"
override val create_new_item: String = "Ստեղծեք նոր տարր"
override val create_new_tax: String = "Ստեղծել նոր հարկ"
override val create_signature: String = "Ստորագրություն"
override val created: String = "Ստեղծվել է"
override val credit: String = "credit"
override val customer: String = "Հաճախորդ"
override val date: String = "Ամսաթիվ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Կանխադրված արժույթ ${currency}" }
override val default_currency_label: String = "Կանխադրված արժույթ"
override val delete: String = "Ջնջել"
override val delete_account: String = "Հաշիվը ջնջել"
override val delete_confirmation_message: String = "Ձեր բոլոր տվյալները կկորչեն:"
override val delete_item_title: String = "Ջնջե՞լ տարրը"
override val delete_signature: String = "Ջնջել ստորագրությունը"
override val depreciation: String = "depreciation"
override val description: String = "Նկարագրություն"
override val description_hint: String = "Նկարագրություն"
override val detailed_report: String = "Մանրամասն հաշվետվություն"
override val disabled: String = "Անաշխատունակ"
override val discount: String = "Զեղչ"
override val discount_hint: String = "Զեղչ"
override val display_af: String = "Աֆրիկյան"
override val display_am: String = "ամհարերեն"
override val display_ar: String = "Արաբերեն"
override val display_as: String = "Ասամերեն"
override val display_az: String = "ադրբեջաներեն"
override val display_be: String = "բելառուս"
override val display_bg: String = "բուլղարերեն"
override val display_bn: String = "Բենգալերեն"
override val display_bs: String = "բոսնիերեն"
override val display_ca: String = "կատալոներեն"
override val display_cs: String = "չեխ"
override val display_da: String = "Դանիերեն"
override val display_de: String = "Գերմանական"
override val display_el: String = "հունարեն"
override val display_en: String = "Անգլերեն"
override val display_es: String = "Իսպաներեն"
override val display_es_MX: String = "իսպաներեն (Մեքսիկա)"
override val display_et: String = "էստոնական"
override val display_eu: String = "բասկերեն"
override val display_fa: String = "պարսկ"
override val display_fi: String = "Ֆիններեն"
override val display_fil: String = "ֆիլիպիներեն"
override val display_fr: String = "Ֆրանսերեն"
override val display_fr_CA: String = "Կանադական ֆրանսերեն"
override val display_gl: String = "գալիցերեն"
override val display_gu: String = "գուջարաթի"
override val display_he: String = "եբրայերեն"
override val display_hi: String = "ոչ"
override val display_hr: String = "խորվաթերեն"
override val display_hu: String = "հունգարերեն"
override val display_hy: String = "հայերեն"
override val display_id: String = "Ինդոնեզերեն"
override val display_is: String = "իսլանդերեն"
override val display_it: String = "Իտալական"
override val display_ja: String = "ճապոներեն"
override val display_ka: String = "վրաց"
override val display_kk: String = "ղազախ"
override val display_km: String = "Կենտրոնական քմեր"
override val display_kn: String = "Կաննադա"
override val display_ko: String = "կորեերեն"
override val display_ky: String = "ղրղզ"
override val display_lo: String = "Աշխատանք"
override val display_lt: String = "լիտվերեն"
override val display_lv: String = "լատվիերեն"
override val display_mk: String = "մակեդոնական"
override val display_ml: String = "մալայալամ"
override val display_mn: String = "Մոնղոլական"
override val display_mr: String = "Մարաթի"
override val display_ms: String = "մալայերեն"
override val display_my: String = "բիրմայերեն"
override val display_nb: String = "Նորվեգական Բոկմալ"
override val display_ne: String = "Նեպալերեն"
override val display_nl: String = "հոլանդերեն"
override val display_or: String = "Օրիա"
override val display_pa: String = "փենջաբերեն"
override val display_pl: String = "լեհ"
override val display_pt: String = "Պորտուգալերեն"
override val display_pt_BR: String = "Պորտուգալերեն (Բրազիլիա)"
override val display_pt_PT: String = "Եվրոպական պորտուգալերեն"
override val display_ro: String = "ռումիներեն"
override val display_ru: String = "Ռուսերեն"
override val display_si: String = "Սինհալա"
override val display_sk: String = "սլովակ"
override val display_sl: String = "սլովեներեն"
override val display_sq: String = "ալբանացի"
override val display_sr: String = "սերբերեն"
override val display_sv: String = "Շվեդերեն"
override val display_sw: String = "Սուահիլի"
override val display_ta: String = "թամիլերեն"
override val display_te: String = "թելուգու"
override val display_th: String = "թայերեն"
override val display_tr: String = "թուրքական"
override val display_uk: String = "Ուկրաինական"
override val display_ur: String = "ուրդու"
override val display_uz: String = "Ուզբեկ"
override val display_vi: String = "Վիետնամերեն"
override val display_zh_Hant_TW: String = "չինարեն (Թայվան, ավանդական)"
override val display_zu: String = "Զուլուս"
override val document_estimate: String = "Գնահատում"
override val document_invoice: String = "Հաշիվ-ապրանքագիր"
override val document_quote: String = "Մեջբերում"
override val done: String = "Կատարած"
override val download_app: String = "Ներբեռնեք հավելվածը"
override val download_invoice: String = "Ներբեռնեք հաշիվ-ապրանքագիր"
override val download_pdf: String = "Ներբեռնեք PDF"
override val draw_signature: String = "Ստորագրություն նկարիր"
override val due: Formattable = Formattable { (date) -> "Ժամկետը՝ ${date}" }
override val due_date: String = "Վերջնաժամկետ"
override val due_day: String = "մեկ օրում"
override val due_label: String = "Պայմանավորված"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber}-ի վերջնաժամկետը ${dueWhen} է:" }
override val due_today: String = "Այսօր"
override val due_week: String = "մեկ շաբաթում"
override val duplicate: String = "Կրկնօրինակեք"
override val duration_day: String = "օր"
override val duration_month: String = "Ամիս"
override val duration_week: String = "Շաբաթ"
override val duration_year: String = "Տարի"
override val email: String = "Էլ"
override val email_blank_error: String = "Պահանջվում է էլ."
override val email_error: String = "Անվավեր էլ․ հասցե։"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Շնորհակալություն ձեր բիզնեսի համար: Ամբողջական ${documentType}-ը դիտելու համար սեղմեք այս նամակի վերջում գտնվող կոճակը: Նկատի ունեցեք, որ այն ուժի մեջ է մինչև ${dueDate}:" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Դուք նոր եք ստացել${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ԴԻՏԵԼ ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Հարգելի ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Շնորհակալություն ձեր բիզնեսի համար: Ամբողջական ${documentType}-ը դիտելու համար սեղմեք այս նամակի վերջում գտնվող կոճակը: Խնդրում ենք վճարել մնացորդը մինչև ${dueDate}:" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Դուք նոր եք ստացել${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "Գաղտնաբառը վերականգնելու համար ուղարկվել է էլ."
override val empty_clients_list_message: String = "Դուք հետևելու հաճախորդներ չունեք: Ավելացնել նոր հաճախորդ:"
override val empty_estimate_list_message: String = "Ստեղծեք ձեր առաջին գնահատականը"
override val empty_expenses: String = "Դուք հետևելու ծախսեր չունեք: Ավելացնել նոր ծախս:"
override val empty_invoice_list_message: String = "Ստեղծեք ձեր առաջին հաշիվը"
override val empty_items_list_message: String = "Դուք տարրեր չունեք, ավելացրեք նոր տարր:"
override val empty_signatures_list_message: String = "Ստորագրություններ չեն գտնվել։ Ստեղծեք մեկը:"
override val empty_taxes_list_message: String = "Դուք չունեք հարկային կանոններ: Ավելացնել նոր հարկային կանոն."
override val empty_templates_list_message: String = "Կաղապարները անհասանելի են:"
override val enabled: String = "Միացված է"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Գնահատում #"
override val estimates: String = "Գնահատականներ"
override val estimates_report: String = "Գնահատականներ"
override val expense_bank_fees: String = "Բանկային վճարներ"
override val expense_benefits: String = "Օգուտները"
override val expense_communication: String = "Հաղորդակցություն"
override val expense_debt: String = "Պարտք"
override val expense_deleted: String = "Ծախսը ջնջված է"
override val expense_depreciation: String = "Արժեզրկում"
override val expense_gifts: String = "Նվերներ"
override val expense_insurance: String = "Ապահովագրություն"
override val expense_inventory: String = "Գույքագրում"
override val expense_legal: String = "Իրավական"
override val expense_maintenance: String = "Տեխնիկական սպասարկում"
override val expense_marketing: String = "Մարքեթինգ"
override val expense_meals: String = "Սնունդ"
override val expense_office_supplies: String = "Գրասենյակային պարագաներ"
override val expense_rent: String = "Վարձակալություն"
override val expense_salaries: String = "Աշխատավարձեր"
override val expense_shopping: String = "Գնումներ"
override val expense_software: String = "Ծրագրային ապահովում"
override val expense_status_available: String = "Հասանելի է"
override val expense_status_processing: String = "Մշակում"
override val expense_tax: String = "Հարկային"
override val expense_training: String = "Ուսուցում"
override val expense_transport: String = "Տրանսպորտ"
override val expense_travel: String = "Ճամփորդություն"
override val expense_utility: String = "Կոմունալ"
override val expenses: String = "Ծախսեր"
override val feedback_option: String = "Հետադարձ կապ"
override val filter: String = "Filտել"
override val first_name_hint: String = "Անուն"
override val forgot_password: String = "Վերակայել գաղտնաբառը"
override val forgot_password_label: String = "Մոռացել եք գաղտնաբառը"
override val from: String = "ից"
override val general_error: String = "Սխալ է տեղի ունեցել, խնդրում ենք ավելի ուշ կրկին փորձել:"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Վերադառնալ"
override val group_by: String = "Խումբ ըստ՝"
override val image_view: String = "Պատկեր"
override val install: String = "Տեղադրել"
override val invalid_credentials_error: String = "Անվավեր հավատարմագրեր:"
override val invalid_email_error: String = "Սխալ Էլեկտրոնային փոստի հասցե."
override val invalid_user_error: String = "Անվավեր օգտվողի հավատարմագրեր:"
override val invoice_deleted_message: String = "Հաշիվը ջնջված է"
override val invoice_menu: String = "Հաշիվ-ապրանքագրի ընտրացանկ"
override val invoice_number: String = "Հաշիվ-ապրանքագիր #"
override val invoices: String = "Հաշիվ-ապրանքագրեր"
override val items_option: String = "Նյութեր"
override val keep: String = "Պահիր"
override val language_selector: Formattable = Formattable { (selected) -> "Լեզուն ${selected}" }
override val last_name_hint: String = "Ազգանուն"
override val ledger: String = "ledger"
override val legal: String = "Իրավական"
override val legal_error: String = "Պետք է ընդունի Ծառայության պայմանները և Գաղտնիության քաղաքականությունը"
override val liabilities: String = "liabilities"
override val loading: String = "Բեռնվում է"
override val login_required_error: String = "Մուտք պահանջվում է:"
override val logo_view: String = "Լոգոն"
override val logout: String = "Դուրս գալ"
override val logout_prompt: String = "Ձեր տվյալները համաժամանակացված չեն ամպի հետ: Ջնջե՞լ հաշիվ-ապրանքագրերը, նախահաշիվները, հաճախորդների և այլ տեղեկություններ դուրս գալու վերաբերյալ:"
override val main_screen: String = "Հիմնական էկրան"
override val mark_paid: String = "Նշել որպես վճարված"
override val message: String = "Հաղորդագրություն"
override val missing_sender_msg: String = "Ուղարկողի տեղեկություններ չունեցող փաստաթուղթը կարող է անվավեր լինել:"
override val missing_sender_title: String = "Ուղարկողի տեղեկությունները բացակայում են"
override val month_april: String = "ապրիլ"
override val month_april_short: String = "ապրիլ"
override val month_august: String = "օգոստոսին"
override val month_august_short: String = "օգ."
override val month_december: String = "դեկտեմբեր"
override val month_december_short: String = "դեկտ."
override val month_february: String = "փետրվար"
override val month_february_short: String = "փետր."
override val month_january: String = "հունվար"
override val month_january_short: String = "հուն."
override val month_july: String = "հուլիս"
override val month_july_short: String = "հուլիս"
override val month_june: String = "հունիս"
override val month_june_short: String = "հունիս"
override val month_march: String = "երթ"
override val month_march_short: String = "երթ"
override val month_may: String = "կարող է"
override val month_may_short: String = "կարող է"
override val month_november: String = "նոյեմբեր"
override val month_november_short: String = "նոյ."
override val month_october: String = "հոկտեմբերին"
override val month_october_short: String = "հոկտ."
override val month_september: String = "սեպտեմբեր"
override val month_september_short: String = "սեպտ."
override val multifactor_error: String = "Չհաջողվեց հաստատել:"
override val new: String = "Նոր"
override val news: String = "Նորություններ"
override val next: String = "Հաջորդը"
override val no: String = "no"
override val no_data_available: String = "Տվյալներ չկան"
override val no_search_results: String = "Ներեցեք, մենք չկարողացանք որևէ արդյունք գտնել:"
override val none: String = "Ոչ ոք"
override val note_fields: String = "Նշումներ"
override val note_hint: String = "Նշում"
override val notifications_option: String = "Notանուցումներ"
override val ok: String = "լավ"
override val onboarding: String = "Ներբեռնում"
override val onboarding_aboutus_message: String = "DingVoice-ի հիմնական մասերը ընդմիշտ անվճար են: Մեր առաքելությունն է օգնել ձեզ զարգացնել ձեր բիզնեսը, օգնել մեզ բարելավել՝ կիսվելով ձեր կարծիքներով:"
override val onboarding_aboutus_title: String = "Մենք այստեղ ենք օգնելու համար"
override val onboarding_paid_1: String = "Մուտք գործեք պրոֆեսիոնալ տեսք ունեցող ձևանմուշներ"
override val onboarding_paid_2: String = "Ավելի խորը նայեք ձեր ֆինանսական վիճակին"
override val onboarding_paid_3: String = "Ստացեք արագ լուծումներ"
override val onboarding_paid_4: String = "Ապահովեք ձեր փաստաթղթերը"
override val onboarding_paid_5: String = "Եղեք արդիական հաշիվ-ապրանքագրի կարգավիճակի փոփոխությունների մասին"
override val onboarding_paid_title: String = "Էլ ավելի արագ աճեք..."
override val onboarding_paywall_message: String = "Վայելեք բոլոր պրեմիում գործառույթները հիմա լավագույն գնով:"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Պարզապես ${currencySymbol}${introAmount} ${introDuration} ${introPeriod}-ի համար, ապա ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Ընդամենը ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Անվճար ${trialDuration} ${trialPeriod}, ապա ընդամենը ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Անվճար ${trialDuration} ${trialPeriod}, ապա ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}-ի համար, այնուհետև ընդամենը ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Ավտոմատ վերականգնվող: Չեղարկել ցանկացած ժամանակ:"
override val onboarding_paywall_restore: String = "Վերականգնել գնումը"
override val onboarding_paywall_restore_failure: String = "Գնումների վերականգնումը ձախողվեց, փորձեք ավելի ուշ կամ կապվեք աջակցման ծառայության հետ:"
override val onboarding_paywall_restore_success: String = "Վերականգնումը հաջող է:"
override val onboarding_paywall_title: String = "Հասնել մեծության!"
override val onboarding_welcome_message: String = "Ամբողջական գործիքակազմ՝ ձեր հաշիվ-ապրանքագրերը և գնահատումները կառավարելու համար:"
override val onboarding_welcome_title: String = "Բարի գալուստ DingVoice"
override val other_state: String = "Այլ"
override val overdue_state: String = "Ժամկետանց"
override val paid_state: String = "Վճարված"
override val password: String = "Գաղտնաբառ"
override val password_blank_error: String = "Պահանջվում է գաղտնաբառ:"
override val password_mismatch_error: String = "Գաղտնաբառեռրը չեն համընկնում:"
override val pay: String = "pay"
override val payment_instructions_label: String = "Վճարման հրահանգներ"
override val payment_reminders: String = "Վճարման հիշեցումներ"
override val phone_number_fields: String = "Հեռախոսահամար"
override val phone_number_hint: String = "Հեռախոսահամար"
override val postcode_hint: String = "Ինդեքս"
override val preview: String = "Նախադիտում"
override val preview_menu_hint: String = "Նախադիտման ընտրացանկ"
override val price: String = "Գին"
override val privacy_policy: String = "Գաղտնիության քաղաքականություն"
override val profile: String = "Պրոֆիլ"
override val profitable_client: String = "Ձեր ամենաշահութաբեր հաճախորդը..."
override val profits_report: String = "Շահույթներ"
override val promo_content: String = "Գովազդային բովանդակություն"
override val quantity_hint: String = "Քանակ"
override val quantity_label_hint: String = "Քանակի պիտակ"
override val query_info: String = "Հարցումը թույլ է տալիս գրել անհատական հաշվարկներ՝ հղում կատարելով և՛ իրերին, և՛ հարկային այլ կանոններին որպես մուտքագրում: Ավելացրե՛ք @ խորհրդանիշ և տարր կամ հարկային նկարագրություն՝ մեկ այլ մուտքի հղման համար +, -, *, /, % - մաթեմատիկական օպերատորներ"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ստացվել է ձեր հաճախորդի կողմից:" }
override val received_state: String = "Ստացել է"
override val registration_code_fields: String = "Գրանցման կոդ"
override val reminder: String = "Հիշեցում"
override val reports: String = "Զեկույցներ"
override val reports_not_available: String = "Զեկույցներ չկան, վերադարձեք ավելի ուշ:"
override val reveal_password: String = "Բացահայտեք գաղտնաբառը"
override val revenue: String = "revenue"
override val sales_tax: String = "ԱԱՀ"
override val sales_tax_number: String = "ԱԱՀ համարը"
override val save: String = "Խնայել"
override val scan_document: String = "Փաստաթղթի սկանավորում"
override val search: String = "Որոնել"
override val select: String = "Ընտրել"
override val select_icon: String = "Ընտրեք պատկերակը"
override val select_signature: String = "Ընտրեք ստորագրությունը"
override val select_template: String = "Ընտրեք ձևանմուշ"
override val send: String = "Ուղարկել"
override val sent_state: String = "Ուղարկված"
override val setup_sender: String = "Կարգավորել ուղարկողը"
override val share_option: String = "Կիսվել"
override val ship_to: String = "Նավարկել"
override val sign_in: String = "Մուտք գործել"
override val sign_in_apple: String = "Մուտք գործեք Apple-ի միջոցով"
override val sign_in_email: String = "Մուտք գործեք էլ.փոստով"
override val sign_in_facebook: String = "Մուտք գործեք Facebook- ով"
override val sign_in_google: String = "Մուտք գործել Google-ով"
override val sign_up: String = "ԳՐԱՆՑՎԵԼ"
override val sign_up_label: String = "Հաշիվ չունե՞ք:"
override val sign_up_link_label: String = "Գրանցվել!"
override val signatures_option: String = "Ստորագրություններ"
override val skip: String = "Բաց թողնել"
override val sold_items_report: String = "Վաճառված ապրանքներ"
override val sort_by: String = "Դասավորել ըստ"
override val status_update: String = "Փաստաթղթի կարգավիճակի թարմացում"
override val sub_annual: String = "Տարեկան"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/տարեկան" }
override val sub_backups: String = "Ամպային կրկնօրինակումներ"
override val sub_insights: String = "Խորաթափանցություն"
override val sub_month: String = "Ամսական"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ամսական" }
override val sub_notifications: String = "Notանուցումներ"
override val sub_popup_message: String = "Բաժանորդագրությունները շուտով կլինեն: Քիչ հետո նորից ստուգեք այստեղ:"
override val sub_support: String = "Աջակցություն"
override val sub_templates: String = "Կաղապարներ"
override val subscribe: String = "Բաժանորդագրվել"
override val subscription_header: String = "Աճեք ձեր բիզնեսը հետևյալի միջոցով."
override val subscription_option: String = "Բաժանորդագրություն"
override val subscriptions_load_fail: String = "Ներեցեք, մենք այս պահին չենք կարող առբերել ձեր բաժանորդագրությունները: Փորձեք ավելի ուշ կամ կապվեք մեր աջակցման թիմին օգնության համար:"
override val subtotal: Formattable = Formattable { (sum) -> "Ենթագումար՝ ${sum}" }
override val subtotal_label: String = "Ենթագումար"
override val sum: String = "Գումարը"
override val support_option: String = "Աջակցություն"
override val switch_reports: String = "Փոխարկել հաշվետվությունը"
override val tax: String = "Հարկային"
override val tax_deleted_message: String = "Հարկը ջնջված է"
override val tax_description_warning: String = "Եթե օգտագործվում է, սա հայտնվում է հաշիվ-ապրանքագրի վրա"
override val tax_input_flat: String = "Տափակ"
override val tax_input_percent: String = "տոկոս"
override val tax_number_fields: String = "Հարկային համարը"
override val tax_number_hint: String = "Հարկային համարը"
override val tax_query_info_description: String = "Հարցման տեղեկատվություն"
override val tax_report: String = "Հարկային"
override val tax_rules_option: String = "Հարկային կանոններ"
override val taxed_clients_report: String = "Հարկվող հաճախորդներ"
override val template: String = "Կաղապար"
override val template_selector_info: String = "Նախնական ձևանմուշը փոխելու համար սահեցրեք"
override val template_unlock_promo: String = "Մուտք գործեք ավելի շատ ձևանմուշներ Premium-ի միջոցով"
override val templates_option: String = "Կաղապարներ"
override val terms_of_service: String = "Ծառայությունների մատուցման պայմաններ"
override val thank_you: String = "Շնորհակալություն"
override val to: String = "դեպի"
override val total: Formattable = Formattable { (sum) -> "Ընդհանուր՝ ${sum}" }
override val total_label: String = "Ընդհանուր"
override val undo_action: String = "Հետարկել"
override val unit: String = "Միավոր"
override val unit_cost_hint: String = "Միավորի արժեքը"
override val unknown_client: String = "Անհայտ հաճախորդ"
override val unlock: String = "Բացել"
override val unnamed_item: String = "Անանուն տարր"
override val upgrade: String = "Թարմացնել"
override val user_collision_error: String = "Հաշիվն արդեն գոյություն ունի:"
override val valid_until: String = "Ուժի մեջ է մինչեւ"
override val warning: String = "Զգուշացում."
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Ընդունեք ${termsOfService} և ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Անվավեր կոդը:",
	"add_client_action" to "Ավելացնել հաճախորդ",
	"add_customer" to "Ավելացնել հաճախորդ",
	"add_expense" to "Ավելացրեք ծախսեր",
	"add_fields_label" to "Համալրել",
	"add_invoice_action" to "Ստեղծել",
	"add_item_action" to "Ավելացնել տարր",
	"add_item_button" to "Ավելացնել տարր",
	"add_signature_action" to "Ավելացնել ստորագրություն",
	"add_tax_action" to "Ավելացնել հարկ",
	"address_fields" to "Հասցե",
	"address_line_hint" to "Հասցեատող",
	"advanced" to "Ընդլայնված",
	"agree_marketing" to "Ես համաձայն եմ ստանալ ուղղակի մարքեթինգային հաղորդակցություն",
	"all_notifications" to "Բոլոր ծանուցումները",
	"amortization" to "amortization",
	"amount" to "Գումարը",
	"asset" to "asset",
	"attach_file" to "Կցել ֆայլը",
	"attachment" to "Հավելված",
	"auth_error" to "Գործողությունը ձախողվեց, նորից փորձեք:",
	"available_for_paid" to "Cloud sync-ը հասանելի է միայն վճարովի բաժանորդագրության դեպքում:",
	"backups_option" to "Կրկնօրինակներ",
	"balance" to "balance",
	"bank_account" to "Բանկային հաշիվ",
	"bill_number" to "Հաշվարկի համարը",
	"cancel" to "Չեղարկել",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Կատեգորիաներ",
	"category" to "Կարգավիճակ",
	"category_add" to "Ավելացնել կատեգորիա",
	"category_delete" to "Ջնջել կատեգորիան",
	"category_name" to "Կատեգորիայի անվանումը",
	"change_language" to Formattable { (language) -> "Ապրանքագրի լեզուն՝ ${language}" },
	"change_language_label" to "Հաշիվ-ապրանքագրի լեզուն",
	"change_template" to "Փոխել ձևանմուշը",
	"city_hint" to "Քաղաք",
	"clear" to "Պարզ",
	"client" to "Հաճախորդ",
	"client_actions" to "Հաճախորդի գործողություններ",
	"client_deleted_message" to "Հաճախորդը ջնջվել է",
	"clients_error_required_fields" to "Պահանջվում է առնվազն մեկ արժեք:",
	"clients_option" to "Հաճախորդներ",
	"close" to "փակել",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "Շուտով!",
	"company_logo" to "Ընկերության լոգոն",
	"company_name_hint" to "Ընկերություն",
	"confirm_password_label" to "Հաստատել գաղտնաբառը",
	"confirmation_title" to "Վստահ եք?",
	"convert_to_invoice" to "Փոխակերպել հաշիվ-ապրանքագրի",
	"country_hint" to "Երկիրը",
	"create_client" to "Ստեղծել հաճախորդ",
	"create_company" to "Ստեղծել ընկերություն",
	"create_document" to "Ստեղծել փաստաթուղթ",
	"create_estimate" to "Գնահատում",
	"create_invoice" to "Հաշիվ-ապրանքագիր",
	"create_item" to "Ստեղծել նյութ",
	"create_new_client" to "Ստեղծեք նոր հաճախորդ",
	"create_new_item" to "Ստեղծեք նոր տարր",
	"create_new_tax" to "Ստեղծել նոր հարկ",
	"create_signature" to "Ստորագրություն",
	"created" to "Ստեղծվել է",
	"credit" to "credit",
	"customer" to "Հաճախորդ",
	"date" to "Ամսաթիվ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Կանխադրված արժույթ ${currency}" },
	"default_currency_label" to "Կանխադրված արժույթ",
	"delete" to "Ջնջել",
	"delete_account" to "Հաշիվը ջնջել",
	"delete_confirmation_message" to "Ձեր բոլոր տվյալները կկորչեն:",
	"delete_item_title" to "Ջնջե՞լ տարրը",
	"delete_signature" to "Ջնջել ստորագրությունը",
	"depreciation" to "depreciation",
	"description" to "Նկարագրություն",
	"description_hint" to "Նկարագրություն",
	"detailed_report" to "Մանրամասն հաշվետվություն",
	"disabled" to "Անաշխատունակ",
	"discount" to "Զեղչ",
	"discount_hint" to "Զեղչ",
	"display_af" to "Աֆրիկյան",
	"display_am" to "ամհարերեն",
	"display_ar" to "Արաբերեն",
	"display_as" to "Ասամերեն",
	"display_az" to "ադրբեջաներեն",
	"display_be" to "բելառուս",
	"display_bg" to "բուլղարերեն",
	"display_bn" to "Բենգալերեն",
	"display_bs" to "բոսնիերեն",
	"display_ca" to "կատալոներեն",
	"display_cs" to "չեխ",
	"display_da" to "Դանիերեն",
	"display_de" to "Գերմանական",
	"display_el" to "հունարեն",
	"display_en" to "Անգլերեն",
	"display_es" to "Իսպաներեն",
	"display_es_MX" to "իսպաներեն (Մեքսիկա)",
	"display_et" to "էստոնական",
	"display_eu" to "բասկերեն",
	"display_fa" to "պարսկ",
	"display_fi" to "Ֆիններեն",
	"display_fil" to "ֆիլիպիներեն",
	"display_fr" to "Ֆրանսերեն",
	"display_fr_CA" to "Կանադական ֆրանսերեն",
	"display_gl" to "գալիցերեն",
	"display_gu" to "գուջարաթի",
	"display_he" to "եբրայերեն",
	"display_hi" to "ոչ",
	"display_hr" to "խորվաթերեն",
	"display_hu" to "հունգարերեն",
	"display_hy" to "հայերեն",
	"display_id" to "Ինդոնեզերեն",
	"display_is" to "իսլանդերեն",
	"display_it" to "Իտալական",
	"display_ja" to "ճապոներեն",
	"display_ka" to "վրաց",
	"display_kk" to "ղազախ",
	"display_km" to "Կենտրոնական քմեր",
	"display_kn" to "Կաննադա",
	"display_ko" to "կորեերեն",
	"display_ky" to "ղրղզ",
	"display_lo" to "Աշխատանք",
	"display_lt" to "լիտվերեն",
	"display_lv" to "լատվիերեն",
	"display_mk" to "մակեդոնական",
	"display_ml" to "մալայալամ",
	"display_mn" to "Մոնղոլական",
	"display_mr" to "Մարաթի",
	"display_ms" to "մալայերեն",
	"display_my" to "բիրմայերեն",
	"display_nb" to "Նորվեգական Բոկմալ",
	"display_ne" to "Նեպալերեն",
	"display_nl" to "հոլանդերեն",
	"display_or" to "Օրիա",
	"display_pa" to "փենջաբերեն",
	"display_pl" to "լեհ",
	"display_pt" to "Պորտուգալերեն",
	"display_pt_BR" to "Պորտուգալերեն (Բրազիլիա)",
	"display_pt_PT" to "Եվրոպական պորտուգալերեն",
	"display_ro" to "ռումիներեն",
	"display_ru" to "Ռուսերեն",
	"display_si" to "Սինհալա",
	"display_sk" to "սլովակ",
	"display_sl" to "սլովեներեն",
	"display_sq" to "ալբանացի",
	"display_sr" to "սերբերեն",
	"display_sv" to "Շվեդերեն",
	"display_sw" to "Սուահիլի",
	"display_ta" to "թամիլերեն",
	"display_te" to "թելուգու",
	"display_th" to "թայերեն",
	"display_tr" to "թուրքական",
	"display_uk" to "Ուկրաինական",
	"display_ur" to "ուրդու",
	"display_uz" to "Ուզբեկ",
	"display_vi" to "Վիետնամերեն",
	"display_zh_Hant_TW" to "չինարեն (Թայվան, ավանդական)",
	"display_zu" to "Զուլուս",
	"document_estimate" to "Գնահատում",
	"document_invoice" to "Հաշիվ-ապրանքագիր",
	"document_quote" to "Մեջբերում",
	"done" to "Կատարած",
	"download_app" to "Ներբեռնեք հավելվածը",
	"download_invoice" to "Ներբեռնեք հաշիվ-ապրանքագիր",
	"download_pdf" to "Ներբեռնեք PDF",
	"draw_signature" to "Ստորագրություն նկարիր",
	"due" to Formattable { (date) -> "Ժամկետը՝ ${date}" },
	"due_date" to "Վերջնաժամկետ",
	"due_day" to "մեկ օրում",
	"due_label" to "Պայմանավորված",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber}-ի վերջնաժամկետը ${dueWhen} է:" },
	"due_today" to "Այսօր",
	"due_week" to "մեկ շաբաթում",
	"duplicate" to "Կրկնօրինակեք",
	"duration_day" to "օր",
	"duration_month" to "Ամիս",
	"duration_week" to "Շաբաթ",
	"duration_year" to "Տարի",
	"email" to "Էլ",
	"email_blank_error" to "Պահանջվում է էլ.",
	"email_error" to "Անվավեր էլ․ հասցե։",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Շնորհակալություն ձեր բիզնեսի համար: Ամբողջական ${documentType}-ը դիտելու համար սեղմեք այս նամակի վերջում գտնվող կոճակը: Նկատի ունեցեք, որ այն ուժի մեջ է մինչև ${dueDate}:" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Դուք նոր եք ստացել${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "ԴԻՏԵԼ ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Հարգելի ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Շնորհակալություն ձեր բիզնեսի համար: Ամբողջական ${documentType}-ը դիտելու համար սեղմեք այս նամակի վերջում գտնվող կոճակը: Խնդրում ենք վճարել մնացորդը մինչև ${dueDate}:" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Դուք նոր եք ստացել${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "Գաղտնաբառը վերականգնելու համար ուղարկվել է էլ.",
	"empty_clients_list_message" to "Դուք հետևելու հաճախորդներ չունեք: Ավելացնել նոր հաճախորդ:",
	"empty_estimate_list_message" to "Ստեղծեք ձեր առաջին գնահատականը",
	"empty_expenses" to "Դուք հետևելու ծախսեր չունեք: Ավելացնել նոր ծախս:",
	"empty_invoice_list_message" to "Ստեղծեք ձեր առաջին հաշիվը",
	"empty_items_list_message" to "Դուք տարրեր չունեք, ավելացրեք նոր տարր:",
	"empty_signatures_list_message" to "Ստորագրություններ չեն գտնվել։ Ստեղծեք մեկը:",
	"empty_taxes_list_message" to "Դուք չունեք հարկային կանոններ: Ավելացնել նոր հարկային կանոն.",
	"empty_templates_list_message" to "Կաղապարները անհասանելի են:",
	"enabled" to "Միացված է",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Գնահատում #",
	"estimates" to "Գնահատականներ",
	"estimates_report" to "Գնահատականներ",
	"expense_bank_fees" to "Բանկային վճարներ",
	"expense_benefits" to "Օգուտները",
	"expense_communication" to "Հաղորդակցություն",
	"expense_debt" to "Պարտք",
	"expense_deleted" to "Ծախսը ջնջված է",
	"expense_depreciation" to "Արժեզրկում",
	"expense_gifts" to "Նվերներ",
	"expense_insurance" to "Ապահովագրություն",
	"expense_inventory" to "Գույքագրում",
	"expense_legal" to "Իրավական",
	"expense_maintenance" to "Տեխնիկական սպասարկում",
	"expense_marketing" to "Մարքեթինգ",
	"expense_meals" to "Սնունդ",
	"expense_office_supplies" to "Գրասենյակային պարագաներ",
	"expense_rent" to "Վարձակալություն",
	"expense_salaries" to "Աշխատավարձեր",
	"expense_shopping" to "Գնումներ",
	"expense_software" to "Ծրագրային ապահովում",
	"expense_status_available" to "Հասանելի է",
	"expense_status_processing" to "Մշակում",
	"expense_tax" to "Հարկային",
	"expense_training" to "Ուսուցում",
	"expense_transport" to "Տրանսպորտ",
	"expense_travel" to "Ճամփորդություն",
	"expense_utility" to "Կոմունալ",
	"expenses" to "Ծախսեր",
	"feedback_option" to "Հետադարձ կապ",
	"filter" to "Filտել",
	"first_name_hint" to "Անուն",
	"forgot_password" to "Վերակայել գաղտնաբառը",
	"forgot_password_label" to "Մոռացել եք գաղտնաբառը",
	"from" to "ից",
	"general_error" to "Սխալ է տեղի ունեցել, խնդրում ենք ավելի ուշ կրկին փորձել:",
	"general_ledger" to "general ledger",
	"go_back_label" to "Վերադառնալ",
	"group_by" to "Խումբ ըստ՝",
	"image_view" to "Պատկեր",
	"install" to "Տեղադրել",
	"invalid_credentials_error" to "Անվավեր հավատարմագրեր:",
	"invalid_email_error" to "Սխալ Էլեկտրոնային փոստի հասցե.",
	"invalid_user_error" to "Անվավեր օգտվողի հավատարմագրեր:",
	"invoice_deleted_message" to "Հաշիվը ջնջված է",
	"invoice_menu" to "Հաշիվ-ապրանքագրի ընտրացանկ",
	"invoice_number" to "Հաշիվ-ապրանքագիր #",
	"invoices" to "Հաշիվ-ապրանքագրեր",
	"items_option" to "Նյութեր",
	"keep" to "Պահիր",
	"language_selector" to Formattable { (selected) -> "Լեզուն ${selected}" },
	"last_name_hint" to "Ազգանուն",
	"ledger" to "ledger",
	"legal" to "Իրավական",
	"legal_error" to "Պետք է ընդունի Ծառայության պայմանները և Գաղտնիության քաղաքականությունը",
	"liabilities" to "liabilities",
	"loading" to "Բեռնվում է",
	"login_required_error" to "Մուտք պահանջվում է:",
	"logo_view" to "Լոգոն",
	"logout" to "Դուրս գալ",
	"logout_prompt" to "Ձեր տվյալները համաժամանակացված չեն ամպի հետ: Ջնջե՞լ հաշիվ-ապրանքագրերը, նախահաշիվները, հաճախորդների և այլ տեղեկություններ դուրս գալու վերաբերյալ:",
	"main_screen" to "Հիմնական էկրան",
	"mark_paid" to "Նշել որպես վճարված",
	"message" to "Հաղորդագրություն",
	"missing_sender_msg" to "Ուղարկողի տեղեկություններ չունեցող փաստաթուղթը կարող է անվավեր լինել:",
	"missing_sender_title" to "Ուղարկողի տեղեկությունները բացակայում են",
	"month_april" to "ապրիլ",
	"month_april_short" to "ապրիլ",
	"month_august" to "օգոստոսին",
	"month_august_short" to "օգ.",
	"month_december" to "դեկտեմբեր",
	"month_december_short" to "դեկտ.",
	"month_february" to "փետրվար",
	"month_february_short" to "փետր.",
	"month_january" to "հունվար",
	"month_january_short" to "հուն.",
	"month_july" to "հուլիս",
	"month_july_short" to "հուլիս",
	"month_june" to "հունիս",
	"month_june_short" to "հունիս",
	"month_march" to "երթ",
	"month_march_short" to "երթ",
	"month_may" to "կարող է",
	"month_may_short" to "կարող է",
	"month_november" to "նոյեմբեր",
	"month_november_short" to "նոյ.",
	"month_october" to "հոկտեմբերին",
	"month_october_short" to "հոկտ.",
	"month_september" to "սեպտեմբեր",
	"month_september_short" to "սեպտ.",
	"multifactor_error" to "Չհաջողվեց հաստատել:",
	"new" to "Նոր",
	"news" to "Նորություններ",
	"next" to "Հաջորդը",
	"no" to "no",
	"no_data_available" to "Տվյալներ չկան",
	"no_search_results" to "Ներեցեք, մենք չկարողացանք որևէ արդյունք գտնել:",
	"none" to "Ոչ ոք",
	"note_fields" to "Նշումներ",
	"note_hint" to "Նշում",
	"notifications_option" to "Notանուցումներ",
	"ok" to "լավ",
	"onboarding" to "Ներբեռնում",
	"onboarding_aboutus_message" to "DingVoice-ի հիմնական մասերը ընդմիշտ անվճար են: Մեր առաքելությունն է օգնել ձեզ զարգացնել ձեր բիզնեսը, օգնել մեզ բարելավել՝ կիսվելով ձեր կարծիքներով:",
	"onboarding_aboutus_title" to "Մենք այստեղ ենք օգնելու համար",
	"onboarding_paid_1" to "Մուտք գործեք պրոֆեսիոնալ տեսք ունեցող ձևանմուշներ",
	"onboarding_paid_2" to "Ավելի խորը նայեք ձեր ֆինանսական վիճակին",
	"onboarding_paid_3" to "Ստացեք արագ լուծումներ",
	"onboarding_paid_4" to "Ապահովեք ձեր փաստաթղթերը",
	"onboarding_paid_5" to "Եղեք արդիական հաշիվ-ապրանքագրի կարգավիճակի փոփոխությունների մասին",
	"onboarding_paid_title" to "Էլ ավելի արագ աճեք...",
	"onboarding_paywall_message" to "Վայելեք բոլոր պրեմիում գործառույթները հիմա լավագույն գնով:",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Պարզապես ${currencySymbol}${introAmount} ${introDuration} ${introPeriod}-ի համար, ապա ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Ընդամենը ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Անվճար ${trialDuration} ${trialPeriod}, ապա ընդամենը ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Անվճար ${trialDuration} ${trialPeriod}, ապա ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}-ի համար, այնուհետև ընդամենը ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Ավտոմատ վերականգնվող: Չեղարկել ցանկացած ժամանակ:",
	"onboarding_paywall_restore" to "Վերականգնել գնումը",
	"onboarding_paywall_restore_failure" to "Գնումների վերականգնումը ձախողվեց, փորձեք ավելի ուշ կամ կապվեք աջակցման ծառայության հետ:",
	"onboarding_paywall_restore_success" to "Վերականգնումը հաջող է:",
	"onboarding_paywall_title" to "Հասնել մեծության!",
	"onboarding_welcome_message" to "Ամբողջական գործիքակազմ՝ ձեր հաշիվ-ապրանքագրերը և գնահատումները կառավարելու համար:",
	"onboarding_welcome_title" to "Բարի գալուստ DingVoice",
	"other_state" to "Այլ",
	"overdue_state" to "Ժամկետանց",
	"paid_state" to "Վճարված",
	"password" to "Գաղտնաբառ",
	"password_blank_error" to "Պահանջվում է գաղտնաբառ:",
	"password_mismatch_error" to "Գաղտնաբառեռրը չեն համընկնում:",
	"pay" to "pay",
	"payment_instructions_label" to "Վճարման հրահանգներ",
	"payment_reminders" to "Վճարման հիշեցումներ",
	"phone_number_fields" to "Հեռախոսահամար",
	"phone_number_hint" to "Հեռախոսահամար",
	"postcode_hint" to "Ինդեքս",
	"preview" to "Նախադիտում",
	"preview_menu_hint" to "Նախադիտման ընտրացանկ",
	"price" to "Գին",
	"privacy_policy" to "Գաղտնիության քաղաքականություն",
	"profile" to "Պրոֆիլ",
	"profitable_client" to "Ձեր ամենաշահութաբեր հաճախորդը...",
	"profits_report" to "Շահույթներ",
	"promo_content" to "Գովազդային բովանդակություն",
	"quantity_hint" to "Քանակ",
	"quantity_label_hint" to "Քանակի պիտակ",
	"query_info" to "Հարցումը թույլ է տալիս գրել անհատական հաշվարկներ՝ հղում կատարելով և՛ իրերին, և՛ հարկային այլ կանոններին որպես մուտքագրում: Ավելացրե՛ք @ խորհրդանիշ և տարր կամ հարկային նկարագրություն՝ մեկ այլ մուտքի հղման համար +, -, *, /, % - մաթեմատիկական օպերատորներ",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ստացվել է ձեր հաճախորդի կողմից:" },
	"received_state" to "Ստացել է",
	"registration_code_fields" to "Գրանցման կոդ",
	"reminder" to "Հիշեցում",
	"reports" to "Զեկույցներ",
	"reports_not_available" to "Զեկույցներ չկան, վերադարձեք ավելի ուշ:",
	"reveal_password" to "Բացահայտեք գաղտնաբառը",
	"revenue" to "revenue",
	"sales_tax" to "ԱԱՀ",
	"sales_tax_number" to "ԱԱՀ համարը",
	"save" to "Խնայել",
	"scan_document" to "Փաստաթղթի սկանավորում",
	"search" to "Որոնել",
	"select" to "Ընտրել",
	"select_icon" to "Ընտրեք պատկերակը",
	"select_signature" to "Ընտրեք ստորագրությունը",
	"select_template" to "Ընտրեք ձևանմուշ",
	"send" to "Ուղարկել",
	"sent_state" to "Ուղարկված",
	"setup_sender" to "Կարգավորել ուղարկողը",
	"share_option" to "Կիսվել",
	"ship_to" to "Նավարկել",
	"sign_in" to "Մուտք գործել",
	"sign_in_apple" to "Մուտք գործեք Apple-ի միջոցով",
	"sign_in_email" to "Մուտք գործեք էլ.փոստով",
	"sign_in_facebook" to "Մուտք գործեք Facebook- ով",
	"sign_in_google" to "Մուտք գործել Google-ով",
	"sign_up" to "ԳՐԱՆՑՎԵԼ",
	"sign_up_label" to "Հաշիվ չունե՞ք:",
	"sign_up_link_label" to "Գրանցվել!",
	"signatures_option" to "Ստորագրություններ",
	"skip" to "Բաց թողնել",
	"sold_items_report" to "Վաճառված ապրանքներ",
	"sort_by" to "Դասավորել ըստ",
	"status_update" to "Փաստաթղթի կարգավիճակի թարմացում",
	"sub_annual" to "Տարեկան",
	"sub_annual_price" to Formattable { (price) -> "${price}/տարեկան" },
	"sub_backups" to "Ամպային կրկնօրինակումներ",
	"sub_insights" to "Խորաթափանցություն",
	"sub_month" to "Ամսական",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ամսական" },
	"sub_notifications" to "Notանուցումներ",
	"sub_popup_message" to "Բաժանորդագրությունները շուտով կլինեն: Քիչ հետո նորից ստուգեք այստեղ:",
	"sub_support" to "Աջակցություն",
	"sub_templates" to "Կաղապարներ",
	"subscribe" to "Բաժանորդագրվել",
	"subscription_header" to "Աճեք ձեր բիզնեսը հետևյալի միջոցով.",
	"subscription_option" to "Բաժանորդագրություն",
	"subscriptions_load_fail" to "Ներեցեք, մենք այս պահին չենք կարող առբերել ձեր բաժանորդագրությունները: Փորձեք ավելի ուշ կամ կապվեք մեր աջակցման թիմին օգնության համար:",
	"subtotal" to Formattable { (sum) -> "Ենթագումար՝ ${sum}" },
	"subtotal_label" to "Ենթագումար",
	"sum" to "Գումարը",
	"support_option" to "Աջակցություն",
	"switch_reports" to "Փոխարկել հաշվետվությունը",
	"tax" to "Հարկային",
	"tax_deleted_message" to "Հարկը ջնջված է",
	"tax_description_warning" to "Եթե օգտագործվում է, սա հայտնվում է հաշիվ-ապրանքագրի վրա",
	"tax_input_flat" to "Տափակ",
	"tax_input_percent" to "տոկոս",
	"tax_number_fields" to "Հարկային համարը",
	"tax_number_hint" to "Հարկային համարը",
	"tax_query_info_description" to "Հարցման տեղեկատվություն",
	"tax_report" to "Հարկային",
	"tax_rules_option" to "Հարկային կանոններ",
	"taxed_clients_report" to "Հարկվող հաճախորդներ",
	"template" to "Կաղապար",
	"template_selector_info" to "Նախնական ձևանմուշը փոխելու համար սահեցրեք",
	"template_unlock_promo" to "Մուտք գործեք ավելի շատ ձևանմուշներ Premium-ի միջոցով",
	"templates_option" to "Կաղապարներ",
	"terms_of_service" to "Ծառայությունների մատուցման պայմաններ",
	"thank_you" to "Շնորհակալություն",
	"to" to "դեպի",
	"total" to Formattable { (sum) -> "Ընդհանուր՝ ${sum}" },
	"total_label" to "Ընդհանուր",
	"undo_action" to "Հետարկել",
	"unit" to "Միավոր",
	"unit_cost_hint" to "Միավորի արժեքը",
	"unknown_client" to "Անհայտ հաճախորդ",
	"unlock" to "Բացել",
	"unnamed_item" to "Անանուն տարր",
	"upgrade" to "Թարմացնել",
	"user_collision_error" to "Հաշիվն արդեն գոյություն ունի:",
	"valid_until" to "Ուժի մեջ է մինչեւ",
	"warning" to "Զգուշացում.",
	"yes" to "yes",
    )
    
}