// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Kk  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} және ${privacyPolicy} қабылдаңыз" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Жарамсыз код."
override val add_client_action: String = "Клиентті қосу"
override val add_customer: String = "Тұтынушыны қосу"
override val add_expense: String = "Шығын қосыңыз"
override val add_fields_label: String = "Қосу:"
override val add_invoice_action: String = "Жасау"
override val add_item_action: String = "Элементті қосу"
override val add_item_button: String = "Элементті қосу"
override val add_signature_action: String = "Қолтаңба қосу"
override val add_tax_action: String = "Салық қосу"
override val address_fields: String = "Адрес"
override val address_line_hint: String = "Адрес жолы"
override val advanced: String = "Қосымша"
override val agree_marketing: String = "Мен тікелей маркетингтік коммуникацияларды алуға келісемін"
override val all_notifications: String = "Барлық хабарландырулар"
override val amortization: String = "amortization"
override val amount: String = "Сомасы"
override val asset: String = "asset"
override val attach_file: String = "Файлды тіркеңіз"
override val attachment: String = "Тіркеме"
override val auth_error: String = "Әрекет орындалмады, қайталап көріңіз."
override val available_for_paid: String = "Бұлтты синхрондау тек ақылы жазылым астында қолжетімді."
override val backups_option: String = "Сақтық көшірмелер"
override val balance: String = "balance"
override val bank_account: String = "Банк шоты"
override val bill_number: String = "Шот нөмірі"
override val cancel: String = "Болдырмау"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Санаттар"
override val category: String = "Санат"
override val category_add: String = "Санат қосу"
override val category_delete: String = "Санатты жою"
override val category_name: String = "Санат атауы"
override val change_language: Formattable = Formattable { (language) -> "Шот-фактура тілі: ${language}" }
override val change_language_label: String = "Шот-фактура тілі"
override val change_template: String = "Үлгіні өзгерту"
override val city_hint: String = "Қала"
override val clear: String = "Тазалау"
override val client: String = "Клиент"
override val client_actions: String = "Клиент әрекеттері"
override val client_deleted_message: String = "Клиент жойылды"
override val clients_error_required_fields: String = "Бiр мәннен кем емес мән талап етiледi."
override val clients_option: String = "Клиенттер"
override val close: String = "Жабу"
override val cloud_sync: String = "Бұлтты синхрондау"
override val coming_soon: String = "Жақында!"
override val company_logo: String = "Компания логотипі"
override val company_name_hint: String = "Компания"
override val confirm_password_label: String = "Құпиясөзді растау"
override val confirmation_title: String = "Сіз сенімдісіз бе?"
override val convert_to_invoice: String = "Шот-фактураға түрлендіру"
override val country_hint: String = "Ел"
override val create_client: String = "Клиент жасау"
override val create_company: String = "Компания құру"
override val create_document: String = "Құжатты жасау"
override val create_estimate: String = "Бағалау"
override val create_invoice: String = "Шот-фактура"
override val create_item: String = "Элементті жасау"
override val create_new_client: String = "Жаңа клиент жасау"
override val create_new_item: String = "Жаңа элементті жасау"
override val create_new_tax: String = "Жаңа салық жасау"
override val create_signature: String = "Қол қою"
override val created: String = "Жасалған"
override val credit: String = "credit"
override val customer: String = "Тұтынушы"
override val date: String = "Күні"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Әдепкі валюта: ${currency}" }
override val default_currency_label: String = "Әдепкі валюта"
override val delete: String = "Өшіру"
override val delete_account: String = "Тіркелгіні жою"
override val delete_confirmation_message: String = "Барлық деректеріңіз жоғалады."
override val delete_item_title: String = "Элементті жою ма?"
override val delete_signature: String = "Қолтаңбаны жою"
override val depreciation: String = "depreciation"
override val description: String = "Сипаттама"
override val description_hint: String = "Сипаттама"
override val detailed_report: String = "Егжей-тегжейлі есеп"
override val disabled: String = "Ажыратылсын"
override val discount: String = "Жеңілдік"
override val discount_hint: String = "Жеңілдік"
override val display_af: String = "африкалық"
override val display_am: String = "амхар"
override val display_ar: String = "араб"
override val display_as: String = "Ассам"
override val display_az: String = "әзірбайжан"
override val display_be: String = "белорус"
override val display_bg: String = "болгар"
override val display_bn: String = "бенгал"
override val display_bs: String = "босниялық"
override val display_ca: String = "каталон"
override val display_cs: String = "чех"
override val display_da: String = "дат"
override val display_de: String = "неміс"
override val display_el: String = "грек"
override val display_en: String = "Ағылшын"
override val display_es: String = "испан"
override val display_es_MX: String = "Испан (Мексика)"
override val display_et: String = "эстон"
override val display_eu: String = "баск"
override val display_fa: String = "парсы"
override val display_fi: String = "фин"
override val display_fil: String = "Филиппин"
override val display_fr: String = "француз"
override val display_fr_CA: String = "канадалық француз"
override val display_gl: String = "галисиан"
override val display_gu: String = "гуджарати"
override val display_he: String = "иврит"
override val display_hi: String = "Жоқ"
override val display_hr: String = "хорват"
override val display_hu: String = "венгр"
override val display_hy: String = "армян"
override val display_id: String = "индонезиялық"
override val display_is: String = "исланд"
override val display_it: String = "итальян"
override val display_ja: String = "жапон"
override val display_ka: String = "грузин"
override val display_kk: String = "қазақ"
override val display_km: String = "Орталық кхмер"
override val display_kn: String = "Каннада"
override val display_ko: String = "корей"
override val display_ky: String = "қырғыз"
override val display_lo: String = "Еңбек"
override val display_lt: String = "литва"
override val display_lv: String = "латыш"
override val display_mk: String = "македон"
override val display_ml: String = "малаялам"
override val display_mn: String = "моңғол"
override val display_mr: String = "Марати"
override val display_ms: String = "малай"
override val display_my: String = "Бирма"
override val display_nb: String = "Норвегиялық бокмал"
override val display_ne: String = "Непал"
override val display_nl: String = "голланд"
override val display_or: String = "Ория"
override val display_pa: String = "Пенджаби"
override val display_pl: String = "поляк"
override val display_pt: String = "португал тілі"
override val display_pt_BR: String = "Португал (Бразилия)"
override val display_pt_PT: String = "Еуропалық португал"
override val display_ro: String = "румын"
override val display_ru: String = "орыс"
override val display_si: String = "Сингал"
override val display_sk: String = "словак"
override val display_sl: String = "словен"
override val display_sq: String = "албан"
override val display_sr: String = "серб"
override val display_sv: String = "швед"
override val display_sw: String = "суахили"
override val display_ta: String = "Тамил"
override val display_te: String = "телугу"
override val display_th: String = "тай"
override val display_tr: String = "түрік"
override val display_uk: String = "украин"
override val display_ur: String = "урду"
override val display_uz: String = "өзбек"
override val display_vi: String = "вьетнамдық"
override val display_zh_Hant_TW: String = "Қытай (Тайвань, дәстүрлі)"
override val display_zu: String = "Зулу"
override val document_estimate: String = "Бағалау"
override val document_invoice: String = "Шот-фактура"
override val document_quote: String = "Дәйексөз"
override val done: String = "Дайын"
override val download_app: String = "Қолданбаны жүктеп алыңыз"
override val download_invoice: String = "Шот-фактураны жүктеп алыңыз"
override val download_pdf: String = "PDF файлын жүктеп алыңыз"
override val draw_signature: String = "Қолтаңбаны сызу"
override val due: Formattable = Formattable { (date) -> "Тиесілі ${date}" }
override val due_date: String = "Орындау мерзімі"
override val due_day: String = "бір күнде"
override val due_label: String = "Жаңылыс"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} мерзімі ${dueWhen} күні!" }
override val due_today: String = "Бүгін"
override val due_week: String = "бір аптада"
override val duplicate: String = "Көшірме"
override val duration_day: String = "күн"
override val duration_month: String = "ай"
override val duration_week: String = "Апта"
override val duration_year: String = "Жыл"
override val email: String = "Электрондық пошта"
override val email_blank_error: String = "Электрондық пошта қажет."
override val email_error: String = "Электрондық пошта мекенжайы жарамсыз."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Сіздің бизнесіңіз үшін рахмет. Толық ${documentType} көру үшін осы әріптің соңындағы түймені басыңыз. Оның ${dueDate} дейін жарамды екенін ескеріңіз." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Сіз жаңасын алдыңыз${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} ҚАРАУ" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Құрметті ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Сіздің бизнесіңіз үшін рахмет. Толық ${documentType} көру үшін осы әріптің соңындағы түймені басыңыз. ${dueDate} дейін балансты төлеңіз." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Сіз жаңасын алдыңыз${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "Жіберілген құпиясөзді қалпына келтіруге арналған электрондық пошта кіріс жәшігін тексеріңіз."
override val empty_clients_list_message: String = "Бақылап отыратын клиенттеріңіз жоқ. Жаңа клиентті қосыңыз."
override val empty_estimate_list_message: String = "Алғашқы бағалауыңызды жасау"
override val empty_expenses: String = "Сізде қадағалайтын шығындар жоқ. Жаңа шығындарды қосыңыз."
override val empty_invoice_list_message: String = "Бірінші шот-фактураны жасау"
override val empty_items_list_message: String = "Сізде элементтер жоқ, жаңа элемент қосыңыз!"
override val empty_signatures_list_message: String = "Қолтаңбалар табылмады. Біреуін жасаңыз!"
override val empty_taxes_list_message: String = "Сізде салық ережелері жоқ. Жаңа салық ережесін қосыңыз."
override val empty_templates_list_message: String = "Үлгілер қол жетімді емес."
override val enabled: String = "Қосылған"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Бағалау №"
override val estimates: String = "Бағалаулар"
override val estimates_report: String = "Бағалаулар"
override val expense_bank_fees: String = "Банк комиссиялары"
override val expense_benefits: String = "Артықшылықтары"
override val expense_communication: String = "Байланыс"
override val expense_debt: String = "Қарыз"
override val expense_deleted: String = "Шығын жойылды"
override val expense_depreciation: String = "Амортизация"
override val expense_gifts: String = "Сыйлықтар"
override val expense_insurance: String = "Сақтандыру"
override val expense_inventory: String = "Түгендеу"
override val expense_legal: String = "Заңды"
override val expense_maintenance: String = "Техникалық қызмет көрсету"
override val expense_marketing: String = "Маркетинг"
override val expense_meals: String = "Тамақтану"
override val expense_office_supplies: String = "Кеңсе керек-жарақтары"
override val expense_rent: String = "Жалға алу"
override val expense_salaries: String = "Жалақылар"
override val expense_shopping: String = "Шопинг"
override val expense_software: String = "Бағдарламалық қамтамасыз ету"
override val expense_status_available: String = "Қол жетімді"
override val expense_status_processing: String = "Өңдеу"
override val expense_tax: String = "Салық"
override val expense_training: String = "Тренинг"
override val expense_transport: String = "Көлік"
override val expense_travel: String = "Саяхат"
override val expense_utility: String = "Утилита"
override val expenses: String = "Шығындар"
override val feedback_option: String = "Кері байланыс"
override val filter: String = "Сүзгі"
override val first_name_hint: String = "Аты"
override val forgot_password: String = "Құпиясөзді қалпына келтіру"
override val forgot_password_label: String = "Құпиясөзді жамылғылату"
override val from: String = "Кідiрiсi"
override val general_error: String = "Қате орын алды, әрекетті кейінірек қайталаңыз."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Қайтып бар"
override val group_by: String = "Топ бойынша:"
override val image_view: String = "Кескін"
override val install: String = "Орнату"
override val invalid_credentials_error: String = "Тіркелгі деректері жарамсыз."
override val invalid_email_error: String = "Электрондық пошта жарамсыз."
override val invalid_user_error: String = "Жарамсыз пайдаланушы тіркелгі деректері."
override val invoice_deleted_message: String = "Шот-фактура жойылды"
override val invoice_menu: String = "Шот-фактура мәзірі"
override val invoice_number: String = "Шот-фактура #"
override val invoices: String = "Шот-фактуралар"
override val items_option: String = "Элементтер"
override val keep: String = "Сақтау"
override val language_selector: Formattable = Formattable { (selected) -> "${selected} тілі" }
override val last_name_hint: String = "Тегі"
override val ledger: String = "ledger"
override val legal: String = "Заңды"
override val legal_error: String = "Қызмет көрсету және құпиялылық саясатының шарттарын қабылдау керек"
override val liabilities: String = "liabilities"
override val loading: String = "Жүктеу"
override val login_required_error: String = "Кіру қажет."
override val logo_view: String = "Логотип"
override val logout: String = "Logout"
override val logout_prompt: String = "Деректеріңіз бұлтпен синхрондалмаған. Шот-фактураларды, бағалауларды, клиенттерді және басқа ақпаратты журналға жазу туралы мәліметтерді жойыңыз ба?"
override val main_screen: String = "Негізгі экран"
override val mark_paid: String = "Ақылы деп белгілеу"
override val message: String = "Жолдау"
override val missing_sender_msg: String = "Жіберуші ақпараты жоқ құжат жарамсыз болуы мүмкін."
override val missing_sender_title: String = "Жіберуші туралы ақпарат жоқ"
override val month_april: String = "сәуір"
override val month_april_short: String = "сәуір"
override val month_august: String = "тамыз"
override val month_august_short: String = "тамыз"
override val month_december: String = "желтоқсан"
override val month_december_short: String = "желтоқсан"
override val month_february: String = "ақпан"
override val month_february_short: String = "ақпан"
override val month_january: String = "қаңтар"
override val month_january_short: String = "қаңтар"
override val month_july: String = "шілде"
override val month_july_short: String = "шілде"
override val month_june: String = "маусым"
override val month_june_short: String = "маусым"
override val month_march: String = "шеру"
override val month_march_short: String = "шеру"
override val month_may: String = "мүмкін"
override val month_may_short: String = "мүмкін"
override val month_november: String = "қараша"
override val month_november_short: String = "қараша"
override val month_october: String = "қазан"
override val month_october_short: String = "қазан"
override val month_september: String = "қыркүйек"
override val month_september_short: String = "қыркүйек"
override val multifactor_error: String = "Тексерілмеді."
override val new: String = "Жаңа"
override val news: String = "Жаңалықтар"
override val next: String = "Келесі"
override val no: String = "no"
override val no_data_available: String = "Дерек жоқ"
override val no_search_results: String = "Кешіріңіз, ешқандай нәтиже таба алмадық."
override val none: String = "Жоқ"
override val note_fields: String = "Жазбалар"
override val note_hint: String = "Ескерту"
override val notifications_option: String = "Ескертулер"
override val ok: String = "Жақсы"
override val onboarding: String = "Борттық"
override val onboarding_aboutus_message: String = "DingVoice бағдарламасының маңызды бөліктері мәңгілікке тегін. Біздің міндетіміз - сіздің бизнесіңізді дамытуға көмектесу, пікіріңізді бөлісу арқылы жақсартуға көмектесу."
override val onboarding_aboutus_title: String = "Біз көмектесу үшін осындамыз"
override val onboarding_paid_1: String = "Кәсіби көрінетін үлгілерге қол жеткізіңіз"
override val onboarding_paid_2: String = "Қаржылық жағдайыңызды тереңірек қарастырыңыз"
override val onboarding_paid_3: String = "Мәселелерді тез шешіңіз"
override val onboarding_paid_4: String = "Құжаттарыңызды қауіпсіз сақтаңыз"
override val onboarding_paid_5: String = "Шот-фактура күйіндегі өзгерістер туралы хабардар болыңыз"
override val onboarding_paid_title: String = "Одан да жылдам өсіңіз..."
override val onboarding_paywall_message: String = "Барлық премиум мүмкіндіктерді қазір ең жақсы бағамен пайдаланыңыз."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "Тек ${introDuration} ${introPeriod} үшін ${currencySymbol}${introAmount}, содан кейін ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Тек ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} тегін, содан кейін ${currencySymbol}${amount} / ${duration} ақысыз" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} тегін, содан кейін ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}, содан кейін жай ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Автоматты түрде жаңартылатын. Кез келген уақытта бас тарту."
override val onboarding_paywall_restore: String = "Сатып алуды қалпына келтіру"
override val onboarding_paywall_restore_failure: String = "Сатып алуларды қалпына келтіру сәтсіз аяқталды, әрекетті кейінірек қайталаңыз немесе қолдау қызметіне хабарласыңыз."
override val onboarding_paywall_restore_success: String = "Қалпына келтіру сәтті болды!"
override val onboarding_paywall_title: String = "Ұлылыққа қол жеткізіңіз!"
override val onboarding_welcome_message: String = "Шот-фактураларыңыз бен бағалауларыңызды басқаруға арналған толық құралдар жинағы."
override val onboarding_welcome_title: String = "DingVoice қолданбасына қош келдіңіз"
override val other_state: String = "Басқа"
override val overdue_state: String = "Мерзімі өткен"
override val paid_state: String = "Ақылы"
override val password: String = "Пароль"
override val password_blank_error: String = "Құпиясөз қажет."
override val password_mismatch_error: String = "Құпия сөздер сәйкес келмейді."
override val pay: String = "pay"
override val payment_instructions_label: String = "Төлем жөніндегі нұсқаулық"
override val payment_reminders: String = "Төлем туралы еске салулар"
override val phone_number_fields: String = "Телефон нөмірі"
override val phone_number_hint: String = "Телефон нөмірі"
override val postcode_hint: String = "Пошта индексі"
override val preview: String = "Алдын ала қарау"
override val preview_menu_hint: String = "Алдын ала қарау мәзірі"
override val price: String = "Бағасы"
override val privacy_policy: String = "Құпиялылық саясаты"
override val profile: String = "Профилі"
override val profitable_client: String = "Сіздің ең табысты клиентіңіз..."
override val profits_report: String = "Пайда"
override val promo_content: String = "Жарнамалық мазмұн"
override val quantity_hint: String = "Саны"
override val quantity_label_hint: String = "Сан белгісі"
override val query_info: String = "Сұрау элементтерге де, басқа салық ережелеріне де сілтеме жасай отырып, реттелетін есептеулерді енгізу ретінде жазуға мүмкіндік береді. Басқа жазба +, -, *, /, % - математика операторларына сілтеме жасау үшін @ символы мен элементін немесе салық сипаттамасын қосыңыз"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} клиентіңіз алды." }
override val received_state: String = "Алынған"
override val registration_code_fields: String = "Тіркеу коды"
override val reminder: String = "Еске салу"
override val reports: String = "Есептер"
override val reports_not_available: String = "Есептер жоқ, кейінірек оралыңыз."
override val reveal_password: String = "Құпиясөзді ашу"
override val revenue: String = "revenue"
override val sales_tax: String = "ҚҚС"
override val sales_tax_number: String = "ҚҚС бойынша нөмірі"
override val save: String = "Сақтау"
override val scan_document: String = "Құжатты сканерлеу"
override val search: String = "Іздеу"
override val select: String = "таңдаңыз"
override val select_icon: String = "Белгішені таңдаңыз"
override val select_signature: String = "Қолтаңбаны таңдау"
override val select_template: String = "Үлгіні таңдау"
override val send: String = "Жіберу"
override val sent_state: String = "Жіберілді"
override val setup_sender: String = "Жіберушіні орнату"
override val share_option: String = "Ортақ пайдалану"
override val ship_to: String = "Жеткізу"
override val sign_in: String = "Жүйеге кіру"
override val sign_in_apple: String = "Apple арқылы кіру"
override val sign_in_email: String = "Электрондық поштамен кіру"
override val sign_in_facebook: String = "Facebook желісімен кіру"
override val sign_in_google: String = "Google бағдарламасына кіру"
override val sign_up: String = "Тіркелу"
override val sign_up_label: String = "Тіркелгі жоқ па?"
override val sign_up_link_label: String = "Тіркелу!"
override val signatures_option: String = "Қолдар"
override val skip: String = "Өткізіп жіберіңіз"
override val sold_items_report: String = "Сатылған заттар"
override val sort_by: String = "Сұрыптау:"
override val status_update: String = "Құжат күйін жаңарту"
override val sub_annual: String = "Жыл сайынғы"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/жыл" }
override val sub_backups: String = "Бұлтты сақтық көшірмелер"
override val sub_insights: String = "Мәліметтер"
override val sub_month: String = "Ай сайын"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ай" }
override val sub_notifications: String = "Хабарландырулар"
override val sub_popup_message: String = "Жазылымдар жақын арада басталады. Біраз уақыттан кейін осында қайта тексеріңіз."
override val sub_support: String = "Қолдау"
override val sub_templates: String = "Үлгілер"
override val subscribe: String = "Жазылу"
override val subscription_header: String = "Бизнесіңізді мыналармен бірге өсіріңіз:"
override val subscription_option: String = "Жазылым"
override val subscriptions_load_fail: String = "Кешіріңіз, қазір сіздің жазылымдарыңызды шығарып алу мүмкін емес. Әрекетті кейінірек қайталаңыз немесе көмек алу үшін қолдау көрсету тобына хабарласыңыз."
override val subtotal: Formattable = Formattable { (sum) -> "Аралық жиын: ${sum}" }
override val subtotal_label: String = "Аралық жиынтық"
override val sum: String = "Қосынды"
override val support_option: String = "Қолдау"
override val switch_reports: String = "Есепті ауыстыру"
override val tax: String = "Салық"
override val tax_deleted_message: String = "Салық жойылды"
override val tax_description_warning: String = "Егер бұл қолданылса, шот-фактурада көрсетіледі"
override val tax_input_flat: String = "Жалпақ"
override val tax_input_percent: String = "Пайыз"
override val tax_number_fields: String = "Салықтың нөмірі"
override val tax_number_hint: String = "Салықтың нөмірі"
override val tax_query_info_description: String = "Сұрау ақпараты"
override val tax_report: String = "Салық"
override val tax_rules_option: String = "Салық салу ережесі"
override val taxed_clients_report: String = "Салық салынған клиенттер"
override val template: String = "Үлгі"
override val template_selector_info: String = "Әдепкі үлгіні өзгерту үшін айналдыру"
override val template_unlock_promo: String = "Premium көмегімен көбірек үлгілерге қол жеткізіңіз"
override val templates_option: String = "Үлгілер"
override val terms_of_service: String = "Қызмет көрсету шарттары"
override val thank_you: String = "Рақмет сізге"
override val to: String = "Кідірту"
override val total: Formattable = Formattable { (sum) -> "Барлығы: ${sum}" }
override val total_label: String = "Барлығы"
override val undo_action: String = "Болдырмау"
override val unit: String = "Бірлік"
override val unit_cost_hint: String = "Бірлік құны"
override val unknown_client: String = "Белгісіз клиент"
override val unlock: String = "Ашу"
override val unnamed_item: String = "Аты жоқ элемент"
override val upgrade: String = "Жаңарту"
override val user_collision_error: String = "Тіркелгі қазірдің өзінде бар."
override val valid_until: String = "дейін жарамды"
override val warning: String = "Ескерту!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} және ${privacyPolicy} қабылдаңыз" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Жарамсыз код.",
	"add_client_action" to "Клиентті қосу",
	"add_customer" to "Тұтынушыны қосу",
	"add_expense" to "Шығын қосыңыз",
	"add_fields_label" to "Қосу:",
	"add_invoice_action" to "Жасау",
	"add_item_action" to "Элементті қосу",
	"add_item_button" to "Элементті қосу",
	"add_signature_action" to "Қолтаңба қосу",
	"add_tax_action" to "Салық қосу",
	"address_fields" to "Адрес",
	"address_line_hint" to "Адрес жолы",
	"advanced" to "Қосымша",
	"agree_marketing" to "Мен тікелей маркетингтік коммуникацияларды алуға келісемін",
	"all_notifications" to "Барлық хабарландырулар",
	"amortization" to "amortization",
	"amount" to "Сомасы",
	"asset" to "asset",
	"attach_file" to "Файлды тіркеңіз",
	"attachment" to "Тіркеме",
	"auth_error" to "Әрекет орындалмады, қайталап көріңіз.",
	"available_for_paid" to "Бұлтты синхрондау тек ақылы жазылым астында қолжетімді.",
	"backups_option" to "Сақтық көшірмелер",
	"balance" to "balance",
	"bank_account" to "Банк шоты",
	"bill_number" to "Шот нөмірі",
	"cancel" to "Болдырмау",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Санаттар",
	"category" to "Санат",
	"category_add" to "Санат қосу",
	"category_delete" to "Санатты жою",
	"category_name" to "Санат атауы",
	"change_language" to Formattable { (language) -> "Шот-фактура тілі: ${language}" },
	"change_language_label" to "Шот-фактура тілі",
	"change_template" to "Үлгіні өзгерту",
	"city_hint" to "Қала",
	"clear" to "Тазалау",
	"client" to "Клиент",
	"client_actions" to "Клиент әрекеттері",
	"client_deleted_message" to "Клиент жойылды",
	"clients_error_required_fields" to "Бiр мәннен кем емес мән талап етiледi.",
	"clients_option" to "Клиенттер",
	"close" to "Жабу",
	"cloud_sync" to "Бұлтты синхрондау",
	"coming_soon" to "Жақында!",
	"company_logo" to "Компания логотипі",
	"company_name_hint" to "Компания",
	"confirm_password_label" to "Құпиясөзді растау",
	"confirmation_title" to "Сіз сенімдісіз бе?",
	"convert_to_invoice" to "Шот-фактураға түрлендіру",
	"country_hint" to "Ел",
	"create_client" to "Клиент жасау",
	"create_company" to "Компания құру",
	"create_document" to "Құжатты жасау",
	"create_estimate" to "Бағалау",
	"create_invoice" to "Шот-фактура",
	"create_item" to "Элементті жасау",
	"create_new_client" to "Жаңа клиент жасау",
	"create_new_item" to "Жаңа элементті жасау",
	"create_new_tax" to "Жаңа салық жасау",
	"create_signature" to "Қол қою",
	"created" to "Жасалған",
	"credit" to "credit",
	"customer" to "Тұтынушы",
	"date" to "Күні",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Әдепкі валюта: ${currency}" },
	"default_currency_label" to "Әдепкі валюта",
	"delete" to "Өшіру",
	"delete_account" to "Тіркелгіні жою",
	"delete_confirmation_message" to "Барлық деректеріңіз жоғалады.",
	"delete_item_title" to "Элементті жою ма?",
	"delete_signature" to "Қолтаңбаны жою",
	"depreciation" to "depreciation",
	"description" to "Сипаттама",
	"description_hint" to "Сипаттама",
	"detailed_report" to "Егжей-тегжейлі есеп",
	"disabled" to "Ажыратылсын",
	"discount" to "Жеңілдік",
	"discount_hint" to "Жеңілдік",
	"display_af" to "африкалық",
	"display_am" to "амхар",
	"display_ar" to "араб",
	"display_as" to "Ассам",
	"display_az" to "әзірбайжан",
	"display_be" to "белорус",
	"display_bg" to "болгар",
	"display_bn" to "бенгал",
	"display_bs" to "босниялық",
	"display_ca" to "каталон",
	"display_cs" to "чех",
	"display_da" to "дат",
	"display_de" to "неміс",
	"display_el" to "грек",
	"display_en" to "Ағылшын",
	"display_es" to "испан",
	"display_es_MX" to "Испан (Мексика)",
	"display_et" to "эстон",
	"display_eu" to "баск",
	"display_fa" to "парсы",
	"display_fi" to "фин",
	"display_fil" to "Филиппин",
	"display_fr" to "француз",
	"display_fr_CA" to "канадалық француз",
	"display_gl" to "галисиан",
	"display_gu" to "гуджарати",
	"display_he" to "иврит",
	"display_hi" to "Жоқ",
	"display_hr" to "хорват",
	"display_hu" to "венгр",
	"display_hy" to "армян",
	"display_id" to "индонезиялық",
	"display_is" to "исланд",
	"display_it" to "итальян",
	"display_ja" to "жапон",
	"display_ka" to "грузин",
	"display_kk" to "қазақ",
	"display_km" to "Орталық кхмер",
	"display_kn" to "Каннада",
	"display_ko" to "корей",
	"display_ky" to "қырғыз",
	"display_lo" to "Еңбек",
	"display_lt" to "литва",
	"display_lv" to "латыш",
	"display_mk" to "македон",
	"display_ml" to "малаялам",
	"display_mn" to "моңғол",
	"display_mr" to "Марати",
	"display_ms" to "малай",
	"display_my" to "Бирма",
	"display_nb" to "Норвегиялық бокмал",
	"display_ne" to "Непал",
	"display_nl" to "голланд",
	"display_or" to "Ория",
	"display_pa" to "Пенджаби",
	"display_pl" to "поляк",
	"display_pt" to "португал тілі",
	"display_pt_BR" to "Португал (Бразилия)",
	"display_pt_PT" to "Еуропалық португал",
	"display_ro" to "румын",
	"display_ru" to "орыс",
	"display_si" to "Сингал",
	"display_sk" to "словак",
	"display_sl" to "словен",
	"display_sq" to "албан",
	"display_sr" to "серб",
	"display_sv" to "швед",
	"display_sw" to "суахили",
	"display_ta" to "Тамил",
	"display_te" to "телугу",
	"display_th" to "тай",
	"display_tr" to "түрік",
	"display_uk" to "украин",
	"display_ur" to "урду",
	"display_uz" to "өзбек",
	"display_vi" to "вьетнамдық",
	"display_zh_Hant_TW" to "Қытай (Тайвань, дәстүрлі)",
	"display_zu" to "Зулу",
	"document_estimate" to "Бағалау",
	"document_invoice" to "Шот-фактура",
	"document_quote" to "Дәйексөз",
	"done" to "Дайын",
	"download_app" to "Қолданбаны жүктеп алыңыз",
	"download_invoice" to "Шот-фактураны жүктеп алыңыз",
	"download_pdf" to "PDF файлын жүктеп алыңыз",
	"draw_signature" to "Қолтаңбаны сызу",
	"due" to Formattable { (date) -> "Тиесілі ${date}" },
	"due_date" to "Орындау мерзімі",
	"due_day" to "бір күнде",
	"due_label" to "Жаңылыс",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} мерзімі ${dueWhen} күні!" },
	"due_today" to "Бүгін",
	"due_week" to "бір аптада",
	"duplicate" to "Көшірме",
	"duration_day" to "күн",
	"duration_month" to "ай",
	"duration_week" to "Апта",
	"duration_year" to "Жыл",
	"email" to "Электрондық пошта",
	"email_blank_error" to "Электрондық пошта қажет.",
	"email_error" to "Электрондық пошта мекенжайы жарамсыз.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Сіздің бизнесіңіз үшін рахмет. Толық ${documentType} көру үшін осы әріптің соңындағы түймені басыңыз. Оның ${dueDate} дейін жарамды екенін ескеріңіз." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Сіз жаңасын алдыңыз${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} ҚАРАУ" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Құрметті ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Сіздің бизнесіңіз үшін рахмет. Толық ${documentType} көру үшін осы әріптің соңындағы түймені басыңыз. ${dueDate} дейін балансты төлеңіз." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Сіз жаңасын алдыңыз${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "Жіберілген құпиясөзді қалпына келтіруге арналған электрондық пошта кіріс жәшігін тексеріңіз.",
	"empty_clients_list_message" to "Бақылап отыратын клиенттеріңіз жоқ. Жаңа клиентті қосыңыз.",
	"empty_estimate_list_message" to "Алғашқы бағалауыңызды жасау",
	"empty_expenses" to "Сізде қадағалайтын шығындар жоқ. Жаңа шығындарды қосыңыз.",
	"empty_invoice_list_message" to "Бірінші шот-фактураны жасау",
	"empty_items_list_message" to "Сізде элементтер жоқ, жаңа элемент қосыңыз!",
	"empty_signatures_list_message" to "Қолтаңбалар табылмады. Біреуін жасаңыз!",
	"empty_taxes_list_message" to "Сізде салық ережелері жоқ. Жаңа салық ережесін қосыңыз.",
	"empty_templates_list_message" to "Үлгілер қол жетімді емес.",
	"enabled" to "Қосылған",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Бағалау №",
	"estimates" to "Бағалаулар",
	"estimates_report" to "Бағалаулар",
	"expense_bank_fees" to "Банк комиссиялары",
	"expense_benefits" to "Артықшылықтары",
	"expense_communication" to "Байланыс",
	"expense_debt" to "Қарыз",
	"expense_deleted" to "Шығын жойылды",
	"expense_depreciation" to "Амортизация",
	"expense_gifts" to "Сыйлықтар",
	"expense_insurance" to "Сақтандыру",
	"expense_inventory" to "Түгендеу",
	"expense_legal" to "Заңды",
	"expense_maintenance" to "Техникалық қызмет көрсету",
	"expense_marketing" to "Маркетинг",
	"expense_meals" to "Тамақтану",
	"expense_office_supplies" to "Кеңсе керек-жарақтары",
	"expense_rent" to "Жалға алу",
	"expense_salaries" to "Жалақылар",
	"expense_shopping" to "Шопинг",
	"expense_software" to "Бағдарламалық қамтамасыз ету",
	"expense_status_available" to "Қол жетімді",
	"expense_status_processing" to "Өңдеу",
	"expense_tax" to "Салық",
	"expense_training" to "Тренинг",
	"expense_transport" to "Көлік",
	"expense_travel" to "Саяхат",
	"expense_utility" to "Утилита",
	"expenses" to "Шығындар",
	"feedback_option" to "Кері байланыс",
	"filter" to "Сүзгі",
	"first_name_hint" to "Аты",
	"forgot_password" to "Құпиясөзді қалпына келтіру",
	"forgot_password_label" to "Құпиясөзді жамылғылату",
	"from" to "Кідiрiсi",
	"general_error" to "Қате орын алды, әрекетті кейінірек қайталаңыз.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Қайтып бар",
	"group_by" to "Топ бойынша:",
	"image_view" to "Кескін",
	"install" to "Орнату",
	"invalid_credentials_error" to "Тіркелгі деректері жарамсыз.",
	"invalid_email_error" to "Электрондық пошта жарамсыз.",
	"invalid_user_error" to "Жарамсыз пайдаланушы тіркелгі деректері.",
	"invoice_deleted_message" to "Шот-фактура жойылды",
	"invoice_menu" to "Шот-фактура мәзірі",
	"invoice_number" to "Шот-фактура #",
	"invoices" to "Шот-фактуралар",
	"items_option" to "Элементтер",
	"keep" to "Сақтау",
	"language_selector" to Formattable { (selected) -> "${selected} тілі" },
	"last_name_hint" to "Тегі",
	"ledger" to "ledger",
	"legal" to "Заңды",
	"legal_error" to "Қызмет көрсету және құпиялылық саясатының шарттарын қабылдау керек",
	"liabilities" to "liabilities",
	"loading" to "Жүктеу",
	"login_required_error" to "Кіру қажет.",
	"logo_view" to "Логотип",
	"logout" to "Logout",
	"logout_prompt" to "Деректеріңіз бұлтпен синхрондалмаған. Шот-фактураларды, бағалауларды, клиенттерді және басқа ақпаратты журналға жазу туралы мәліметтерді жойыңыз ба?",
	"main_screen" to "Негізгі экран",
	"mark_paid" to "Ақылы деп белгілеу",
	"message" to "Жолдау",
	"missing_sender_msg" to "Жіберуші ақпараты жоқ құжат жарамсыз болуы мүмкін.",
	"missing_sender_title" to "Жіберуші туралы ақпарат жоқ",
	"month_april" to "сәуір",
	"month_april_short" to "сәуір",
	"month_august" to "тамыз",
	"month_august_short" to "тамыз",
	"month_december" to "желтоқсан",
	"month_december_short" to "желтоқсан",
	"month_february" to "ақпан",
	"month_february_short" to "ақпан",
	"month_january" to "қаңтар",
	"month_january_short" to "қаңтар",
	"month_july" to "шілде",
	"month_july_short" to "шілде",
	"month_june" to "маусым",
	"month_june_short" to "маусым",
	"month_march" to "шеру",
	"month_march_short" to "шеру",
	"month_may" to "мүмкін",
	"month_may_short" to "мүмкін",
	"month_november" to "қараша",
	"month_november_short" to "қараша",
	"month_october" to "қазан",
	"month_october_short" to "қазан",
	"month_september" to "қыркүйек",
	"month_september_short" to "қыркүйек",
	"multifactor_error" to "Тексерілмеді.",
	"new" to "Жаңа",
	"news" to "Жаңалықтар",
	"next" to "Келесі",
	"no" to "no",
	"no_data_available" to "Дерек жоқ",
	"no_search_results" to "Кешіріңіз, ешқандай нәтиже таба алмадық.",
	"none" to "Жоқ",
	"note_fields" to "Жазбалар",
	"note_hint" to "Ескерту",
	"notifications_option" to "Ескертулер",
	"ok" to "Жақсы",
	"onboarding" to "Борттық",
	"onboarding_aboutus_message" to "DingVoice бағдарламасының маңызды бөліктері мәңгілікке тегін. Біздің міндетіміз - сіздің бизнесіңізді дамытуға көмектесу, пікіріңізді бөлісу арқылы жақсартуға көмектесу.",
	"onboarding_aboutus_title" to "Біз көмектесу үшін осындамыз",
	"onboarding_paid_1" to "Кәсіби көрінетін үлгілерге қол жеткізіңіз",
	"onboarding_paid_2" to "Қаржылық жағдайыңызды тереңірек қарастырыңыз",
	"onboarding_paid_3" to "Мәселелерді тез шешіңіз",
	"onboarding_paid_4" to "Құжаттарыңызды қауіпсіз сақтаңыз",
	"onboarding_paid_5" to "Шот-фактура күйіндегі өзгерістер туралы хабардар болыңыз",
	"onboarding_paid_title" to "Одан да жылдам өсіңіз...",
	"onboarding_paywall_message" to "Барлық премиум мүмкіндіктерді қазір ең жақсы бағамен пайдаланыңыз.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "Тек ${introDuration} ${introPeriod} үшін ${currencySymbol}${introAmount}, содан кейін ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Тек ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} тегін, содан кейін ${currencySymbol}${amount} / ${duration} ақысыз" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} тегін, содан кейін ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}, содан кейін жай ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Автоматты түрде жаңартылатын. Кез келген уақытта бас тарту.",
	"onboarding_paywall_restore" to "Сатып алуды қалпына келтіру",
	"onboarding_paywall_restore_failure" to "Сатып алуларды қалпына келтіру сәтсіз аяқталды, әрекетті кейінірек қайталаңыз немесе қолдау қызметіне хабарласыңыз.",
	"onboarding_paywall_restore_success" to "Қалпына келтіру сәтті болды!",
	"onboarding_paywall_title" to "Ұлылыққа қол жеткізіңіз!",
	"onboarding_welcome_message" to "Шот-фактураларыңыз бен бағалауларыңызды басқаруға арналған толық құралдар жинағы.",
	"onboarding_welcome_title" to "DingVoice қолданбасына қош келдіңіз",
	"other_state" to "Басқа",
	"overdue_state" to "Мерзімі өткен",
	"paid_state" to "Ақылы",
	"password" to "Пароль",
	"password_blank_error" to "Құпиясөз қажет.",
	"password_mismatch_error" to "Құпия сөздер сәйкес келмейді.",
	"pay" to "pay",
	"payment_instructions_label" to "Төлем жөніндегі нұсқаулық",
	"payment_reminders" to "Төлем туралы еске салулар",
	"phone_number_fields" to "Телефон нөмірі",
	"phone_number_hint" to "Телефон нөмірі",
	"postcode_hint" to "Пошта индексі",
	"preview" to "Алдын ала қарау",
	"preview_menu_hint" to "Алдын ала қарау мәзірі",
	"price" to "Бағасы",
	"privacy_policy" to "Құпиялылық саясаты",
	"profile" to "Профилі",
	"profitable_client" to "Сіздің ең табысты клиентіңіз...",
	"profits_report" to "Пайда",
	"promo_content" to "Жарнамалық мазмұн",
	"quantity_hint" to "Саны",
	"quantity_label_hint" to "Сан белгісі",
	"query_info" to "Сұрау элементтерге де, басқа салық ережелеріне де сілтеме жасай отырып, реттелетін есептеулерді енгізу ретінде жазуға мүмкіндік береді. Басқа жазба +, -, *, /, % - математика операторларына сілтеме жасау үшін @ символы мен элементін немесе салық сипаттамасын қосыңыз",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} клиентіңіз алды." },
	"received_state" to "Алынған",
	"registration_code_fields" to "Тіркеу коды",
	"reminder" to "Еске салу",
	"reports" to "Есептер",
	"reports_not_available" to "Есептер жоқ, кейінірек оралыңыз.",
	"reveal_password" to "Құпиясөзді ашу",
	"revenue" to "revenue",
	"sales_tax" to "ҚҚС",
	"sales_tax_number" to "ҚҚС бойынша нөмірі",
	"save" to "Сақтау",
	"scan_document" to "Құжатты сканерлеу",
	"search" to "Іздеу",
	"select" to "таңдаңыз",
	"select_icon" to "Белгішені таңдаңыз",
	"select_signature" to "Қолтаңбаны таңдау",
	"select_template" to "Үлгіні таңдау",
	"send" to "Жіберу",
	"sent_state" to "Жіберілді",
	"setup_sender" to "Жіберушіні орнату",
	"share_option" to "Ортақ пайдалану",
	"ship_to" to "Жеткізу",
	"sign_in" to "Жүйеге кіру",
	"sign_in_apple" to "Apple арқылы кіру",
	"sign_in_email" to "Электрондық поштамен кіру",
	"sign_in_facebook" to "Facebook желісімен кіру",
	"sign_in_google" to "Google бағдарламасына кіру",
	"sign_up" to "Тіркелу",
	"sign_up_label" to "Тіркелгі жоқ па?",
	"sign_up_link_label" to "Тіркелу!",
	"signatures_option" to "Қолдар",
	"skip" to "Өткізіп жіберіңіз",
	"sold_items_report" to "Сатылған заттар",
	"sort_by" to "Сұрыптау:",
	"status_update" to "Құжат күйін жаңарту",
	"sub_annual" to "Жыл сайынғы",
	"sub_annual_price" to Formattable { (price) -> "${price}/жыл" },
	"sub_backups" to "Бұлтты сақтық көшірмелер",
	"sub_insights" to "Мәліметтер",
	"sub_month" to "Ай сайын",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ай" },
	"sub_notifications" to "Хабарландырулар",
	"sub_popup_message" to "Жазылымдар жақын арада басталады. Біраз уақыттан кейін осында қайта тексеріңіз.",
	"sub_support" to "Қолдау",
	"sub_templates" to "Үлгілер",
	"subscribe" to "Жазылу",
	"subscription_header" to "Бизнесіңізді мыналармен бірге өсіріңіз:",
	"subscription_option" to "Жазылым",
	"subscriptions_load_fail" to "Кешіріңіз, қазір сіздің жазылымдарыңызды шығарып алу мүмкін емес. Әрекетті кейінірек қайталаңыз немесе көмек алу үшін қолдау көрсету тобына хабарласыңыз.",
	"subtotal" to Formattable { (sum) -> "Аралық жиын: ${sum}" },
	"subtotal_label" to "Аралық жиынтық",
	"sum" to "Қосынды",
	"support_option" to "Қолдау",
	"switch_reports" to "Есепті ауыстыру",
	"tax" to "Салық",
	"tax_deleted_message" to "Салық жойылды",
	"tax_description_warning" to "Егер бұл қолданылса, шот-фактурада көрсетіледі",
	"tax_input_flat" to "Жалпақ",
	"tax_input_percent" to "Пайыз",
	"tax_number_fields" to "Салықтың нөмірі",
	"tax_number_hint" to "Салықтың нөмірі",
	"tax_query_info_description" to "Сұрау ақпараты",
	"tax_report" to "Салық",
	"tax_rules_option" to "Салық салу ережесі",
	"taxed_clients_report" to "Салық салынған клиенттер",
	"template" to "Үлгі",
	"template_selector_info" to "Әдепкі үлгіні өзгерту үшін айналдыру",
	"template_unlock_promo" to "Premium көмегімен көбірек үлгілерге қол жеткізіңіз",
	"templates_option" to "Үлгілер",
	"terms_of_service" to "Қызмет көрсету шарттары",
	"thank_you" to "Рақмет сізге",
	"to" to "Кідірту",
	"total" to Formattable { (sum) -> "Барлығы: ${sum}" },
	"total_label" to "Барлығы",
	"undo_action" to "Болдырмау",
	"unit" to "Бірлік",
	"unit_cost_hint" to "Бірлік құны",
	"unknown_client" to "Белгісіз клиент",
	"unlock" to "Ашу",
	"unnamed_item" to "Аты жоқ элемент",
	"upgrade" to "Жаңарту",
	"user_collision_error" to "Тіркелгі қазірдің өзінде бар.",
	"valid_until" to "дейін жарамды",
	"warning" to "Ескерту!",
	"yes" to "yes",
    )
    
}