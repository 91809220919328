// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Pa  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} ਅਤੇ ${privacyPolicy} ਨੂੰ ਸਵੀਕਾਰ ਕਰੋ" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "ਅਵੈਧ ਕੋਡ।"
override val add_client_action: String = "ਕਲਾਇਟ ਸ਼ਾਮਲ"
override val add_customer: String = "ਗਾਹਕ ਸ਼ਾਮਲ"
override val add_expense: String = "ਖਰਚਾ ਜੋੜੋ"
override val add_fields_label: String = "ਸ਼ਾਮਲ:"
override val add_invoice_action: String = "ਬਣਾਓ"
override val add_item_action: String = "ਆਈਟਮ ਸ਼ਾਮਲ"
override val add_item_button: String = "ਆਈਟਮ ਸ਼ਾਮਲ"
override val add_signature_action: String = "ਦਸਤਖਤ ਸ਼ਾਮਲ"
override val add_tax_action: String = "ਟੈਕਸ ਸ਼ਾਮਲ"
override val address_fields: String = "ਐਡਰੈੱਸ"
override val address_line_hint: String = "ਐਡਰੈੱਸ ਲਾਈਨ"
override val advanced: String = "ਉੱਨਤ"
override val agree_marketing: String = "ਮੈਂ ਡਾਇਰੈਕਟ ਮਾਰਕੀਟਿੰਗ ਸੰਚਾਰ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਸਹਿਮਤ ਹਾਂ"
override val all_notifications: String = "ਸਭ ਨੋਟੀਫਿਕੇਸ਼ਨ"
override val amortization: String = "amortization"
override val amount: String = "ਰਕਮ"
override val asset: String = "asset"
override val attach_file: String = "ਫਾਈਲ ਅਟੈਚ ਕਰੋ"
override val attachment: String = "ਅਟੈਚਮੈਂਟ"
override val auth_error: String = "ਕਾਰਵਾਈ ਅਸਫਲ ਰਹੀ, ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।"
override val available_for_paid: String = "Cloud ਸਿੰਕ ਸਿਰਫ਼ ਭੁਗਤਾਨ-ਯੋਗ ਸਬਸਕ੍ਰਿਪਸ਼ਨ ਦੇ ਤਹਿਤ ਹੀ ਉਪਲਬਧ ਹੈ।"
override val backups_option: String = "ਬੈਕਅੱਪ"
override val balance: String = "balance"
override val bank_account: String = "ਬੈੰਕ ਖਾਤਾ"
override val bill_number: String = "ਬਿੱਲ ਨੰਬਰ"
override val cancel: String = "ਰੱਦ ਕਰੋ"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "ਵਰਗ"
override val category: String = "ਸ਼੍ਰੇਣੀ"
override val category_add: String = "ਸ਼੍ਰੇਣੀ ਸ਼ਾਮਲ ਕਰੋ"
override val category_delete: String = "ਸ਼੍ਰੇਣੀ ਮਿਟਾਓ"
override val category_name: String = "ਸ਼੍ਰੇਣੀ ਦਾ ਨਾਮ"
override val change_language: Formattable = Formattable { (language) -> "ਇਨਵੌਇਸ ਭਾਸ਼ਾ: ${language}" }
override val change_language_label: String = "ਇਨਵੌਇਸ ਭਾਸ਼ਾ"
override val change_template: String = "ਟੈਂਪਲੇਟ ਬਦਲੋ"
override val city_hint: String = "ਸ਼ਹਿਰ"
override val clear: String = "ਸਾਫ਼ ਕਰੋ"
override val client: String = "ਗਾਹਕ"
override val client_actions: String = "ਗਾਹਕ ਕਾਰਵਾਈਆਂ"
override val client_deleted_message: String = "ਗਾਹਕ ਹਟਾਇਆ ਗਿਆ"
override val clients_error_required_fields: String = "ਘੱਟੋ-ਘੱਟ ਇੱਕ ਮੁੱਲ ਲੋੜੀਂਦਾ ਹੈ।"
override val clients_option: String = "ਗਾਹਕ"
override val close: String = "ਬੰਦ ਕਰੋ"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "ਜਲਦੀ ਆ ਰਿਹਾ ਹੈ!"
override val company_logo: String = "ਕੰਪਨੀ ਲੋਗੋ"
override val company_name_hint: String = "ਕੰਪਨੀ"
override val confirm_password_label: String = "ਪਾਸਵਰਡ ਪੁਸ਼ਟੀ"
override val confirmation_title: String = "ਤੁਹਾਨੂੰ ਪੂਰਾ ਵਿਸ਼ਵਾਸ ਹੈ?"
override val convert_to_invoice: String = "ਚਲਾਨ ਲਈ ਬਦਲੋ"
override val country_hint: String = "ਦੇਸ਼"
override val create_client: String = "ਕਲਾਇਟ ਬਣਾਓ"
override val create_company: String = "ਕੰਪਨੀ ਬਣਾਓ"
override val create_document: String = "ਡੌਕੂਮੈਂਟ ਬਣਾਓ"
override val create_estimate: String = "ਅੰਦਾਜ਼ਾ"
override val create_invoice: String = "ਇਨਵੌਇਸ"
override val create_item: String = "ਆਈਟਮ ਬਣਾਓ"
override val create_new_client: String = "ਨਵਾਂ ਕਲਾਇਟ ਬਣਾਓ"
override val create_new_item: String = "ਨਵੀਂ ਆਈਟਮ ਬਣਾਓ"
override val create_new_tax: String = "ਨਵਾਂ ਟੈਕਸ ਬਣਾਓ"
override val create_signature: String = "ਦਸਤਖਤ"
override val created: String = "ਬਣਾਇਆ"
override val credit: String = "credit"
override val customer: String = "ਗਾਹਕ"
override val date: String = "ਮਿਤੀ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "ਪੂਰਵ-ਨਿਰਧਾਰਤ ਮੁਦਰਾ: ${currency}" }
override val default_currency_label: String = "ਮੂਲ ਮੁਦਰਾ"
override val delete: String = "ਹਟਾਓ"
override val delete_account: String = "ਖਾਤਾ ਮਿਟਾਓ"
override val delete_confirmation_message: String = "ਤੁਹਾਡਾ ਸਾਰਾ ਡਾਟਾ ਖਤਮ ਹੋ ਜਾਵੇਗਾ।"
override val delete_item_title: String = "ਕੀ ਆਈਟਮ ਹਟਾਉਣੀ ਹੈ?"
override val delete_signature: String = "ਦਸਤਖਤ ਹਟਾਓ"
override val depreciation: String = "depreciation"
override val description: String = "ਵਰਣਨ"
override val description_hint: String = "ਵੇਰਵਾ"
override val detailed_report: String = "ਵਿਸਤ੍ਰਿਤ ਰਿਪੋਰਟ"
override val disabled: String = "ਆਯੋਗ"
override val discount: String = "ਛੂਟ"
override val discount_hint: String = "ਛੋਟ"
override val display_af: String = "ਅਫਰੀਕਨ"
override val display_am: String = "ਅਮਹਾਰਿਕ"
override val display_ar: String = "ਅਰਬੀ"
override val display_as: String = "ਅਸਾਮੀ"
override val display_az: String = "ਅਜ਼ਰਬਾਈਜਾਨੀ"
override val display_be: String = "ਬੇਲਾਰੂਸੀ"
override val display_bg: String = "ਬਲਗੇਰੀਅਨ"
override val display_bn: String = "ਬੰਗਾਲੀ"
override val display_bs: String = "ਬੋਸਨੀਆਈ"
override val display_ca: String = "ਕੈਟਲਨ"
override val display_cs: String = "ਚੈੱਕ"
override val display_da: String = "ਡੈਨਿਸ਼"
override val display_de: String = "ਜਰਮਨ"
override val display_el: String = "ਯੂਨਾਨੀ"
override val display_en: String = "ਅੰਗਰੇਜ਼ੀ"
override val display_es: String = "ਸਪੇਨੀ"
override val display_es_MX: String = "ਸਪੇਨੀ (ਮੈਕਸੀਕੋ)"
override val display_et: String = "ਇਸਟੋਨੀਅਨ"
override val display_eu: String = "ਬਾਸਕ"
override val display_fa: String = "ਫਾਰਸੀ"
override val display_fi: String = "ਫਿਨਿਸ਼"
override val display_fil: String = "ਫਿਲੀਪੀਨੋ"
override val display_fr: String = "ਫ੍ਰੈਂਚ"
override val display_fr_CA: String = "ਕੈਨੇਡੀਅਨ ਫ੍ਰੈਂਚ"
override val display_gl: String = "ਗੈਲੀਸ਼ੀਅਨ"
override val display_gu: String = "ਗੁਜਰਾਤੀ"
override val display_he: String = "ਹਿਬਰੂ"
override val display_hi: String = "ਸੰ."
override val display_hr: String = "ਕਰੋਸ਼ੀਅਨ"
override val display_hu: String = "ਹੰਗਰੀਆਈ"
override val display_hy: String = "ਅਰਮੀਨੀਆਈ"
override val display_id: String = "ਇੰਡੋਨੇਸ਼ੀਆਈ"
override val display_is: String = "ਆਈਸਲੈਂਡਿਕ"
override val display_it: String = "ਇਤਾਲਵੀ"
override val display_ja: String = "ਜਾਪਾਨੀ"
override val display_ka: String = "ਜਾਰਜੀਅਨ"
override val display_kk: String = "ਕਜ਼ਾਖ"
override val display_km: String = "ਕੇਂਦਰੀ ਖਮੇਰ"
override val display_kn: String = "ਕੰਨੜ"
override val display_ko: String = "ਕੋਰੀਅਨ"
override val display_ky: String = "ਕਿਰਗੀਜ਼"
override val display_lo: String = "ਲੇਬਰ"
override val display_lt: String = "ਲਿਥੁਆਨੀਅਨ"
override val display_lv: String = "ਲਾਤਵੀਅਨ"
override val display_mk: String = "ਮੈਸੇਡੋਨੀਅਨ"
override val display_ml: String = "ਮਲਿਆਲਮ"
override val display_mn: String = "ਮੰਗੋਲੀਆਈ"
override val display_mr: String = "ਮਰਾਠੀ"
override val display_ms: String = "ਮਾਲੇ"
override val display_my: String = "ਬਰਮੀ"
override val display_nb: String = "ਨਾਰਵੇਜਿਅਨ ਬੋਕਮਾਲ"
override val display_ne: String = "ਨੇਪਾਲੀ"
override val display_nl: String = "ਡੱਚ"
override val display_or: String = "ਉੜੀਆ"
override val display_pa: String = "ਪੰਜਾਬੀ"
override val display_pl: String = "ਪੋਲਿਸ਼"
override val display_pt: String = "ਪੁਰਤਗਾਲੀ"
override val display_pt_BR: String = "ਪੁਰਤਗਾਲੀ (ਬ੍ਰਾਜ਼ੀਲ)"
override val display_pt_PT: String = "ਯੂਰਪੀ ਪੁਰਤਗਾਲੀ"
override val display_ro: String = "ਰੋਮਾਨੀਅਨ"
override val display_ru: String = "ਰੂਸੀ"
override val display_si: String = "ਸਿੰਹਾਲਾ"
override val display_sk: String = "ਸਲੋਵਾਕ"
override val display_sl: String = "ਸਲੋਵੇਨੀਆਈ"
override val display_sq: String = "ਅਲਬਾਨੀਅਨ"
override val display_sr: String = "ਸਰਬੀਆਈ"
override val display_sv: String = "ਸਵੀਡਿਸ਼"
override val display_sw: String = "ਸਵਾਹਿਲੀ"
override val display_ta: String = "ਤਾਮਿਲ"
override val display_te: String = "ਤੇਲਗੂ"
override val display_th: String = "ਥਾਈ"
override val display_tr: String = "ਤੁਰਕੀ"
override val display_uk: String = "ਯੂਕਰੇਨੀ"
override val display_ur: String = "ਉਰਦੂ"
override val display_uz: String = "ਉਜ਼ਬੇਕ"
override val display_vi: String = "ਵੀਅਤਨਾਮੀ"
override val display_zh_Hant_TW: String = "ਚੀਨੀ (ਤਾਈਵਾਨ, ਪਰੰਪਰਾਗਤ)"
override val display_zu: String = "ਜ਼ੁਲੂ"
override val document_estimate: String = "ਅੰਦਾਜ਼ਾ"
override val document_invoice: String = "ਇਨਵੌਇਸ"
override val document_quote: String = "ਹਵਾਲਾ"
override val done: String = "ਹੋ ਗਿਆ"
override val download_app: String = "ਐਪ ਨੂੰ ਡਾਊਨਲੋਡ ਕਰੋ"
override val download_invoice: String = "ਇਨਵੌਇਸ ਡਾਊਨਲੋਡ ਕਰੋ"
override val download_pdf: String = "PDF ਡਾਊਨਲੋਡ ਕਰੋ"
override val draw_signature: String = "ਇੱਕ ਦਸਤਖਤ ਬਣਾਓ"
override val due: Formattable = Formattable { (date) -> "ਬਕਾਇਆ ${date}" }
override val due_date: String = "ਦੇਣ ਮਿਤੀ"
override val due_day: String = "ਇੱਕ ਦਿਨ ਵਿੱਚ"
override val due_label: String = "ਕਾਰਨ"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ਬਕਾਇਆ ${dueWhen} ਹੈ!" }
override val due_today: String = "ਅੱਜ"
override val due_week: String = "ਇੱਕ ਹਫਤੇ ਵਿੱਚ"
override val duplicate: String = "ਡੁਪਲੀਕੇਟ"
override val duration_day: String = "ਦਿਨ"
override val duration_month: String = "ਮਹੀਨਾ"
override val duration_week: String = "ਹਫਤਾ"
override val duration_year: String = "ਸਾਲ"
override val email: String = "ਈ- ਮੇਲ"
override val email_blank_error: String = "ਈ- ਮੇਲ ਦੀ ਲੋੜ ਹੈ ।"
override val email_error: String = "ਅਵੈਧ ਈਮੇਲ ਪਤਾ।"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "ਤੁਹਾਡੇ ਕਾਰੋਬਾਰ ਲਈ ਧੰਨਵਾਦ। ਪੂਰਾ ${documentType} ਦੇਖਣ ਲਈ ਇਸ ਅੱਖਰ ਦੇ ਅੰਤ &#39;ਤੇ ਬਟਨ &#39;ਤੇ ਕਲਿੱਕ ਕਰੋ। ਨੋਟ ਕਰੋ ਕਿ ਇਹ ${dueDate} ਤੱਕ ਵੈਧ ਹੈ।" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "ਤੁਹਾਨੂੰ ਇੱਕ ਨਵਾਂ ਪ੍ਰਾਪਤ ਹੋਇਆ ਹੈ${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} ਦੇਖੋ" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "ਪਿਆਰੇ ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "ਤੁਹਾਡੇ ਕਾਰੋਬਾਰ ਲਈ ਧੰਨਵਾਦ। ਪੂਰਾ ${documentType} ਦੇਖਣ ਲਈ ਇਸ ਅੱਖਰ ਦੇ ਅੰਤ &#39;ਤੇ ਬਟਨ &#39;ਤੇ ਕਲਿੱਕ ਕਰੋ। ਕਿਰਪਾ ਕਰਕੇ ${dueDate} ਤੱਕ ਬਕਾਇਆ ਦਾ ਭੁਗਤਾਨ ਕਰੋ।" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "ਤੁਹਾਨੂੰ ਇੱਕ ਨਵਾਂ ਪ੍ਰਾਪਤ ਹੋਇਆ ਹੈ${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "ਭੇਜੇ ਗਏ ਪਾਸਵਰਡ ਨੂੰ ਰੀਸੈੱਟ ਕਰਨ ਲਈ ਈਮੇਲ ਕਰੋ, ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਇਨਬਾਕਸ ਦੀ ਜਾਂਚ ਕਰੋ।"
override val empty_clients_list_message: String = "ਤੁਹਾਡੇ ਕੋਲ ਟਰੈਕ ਕਰਨ ਲਈ ਕੋਈ ਗਾਹਕ ਨਹੀਂ ਹਨ। ਇੱਕ ਨਵਾਂ ਕਲਾਈਂਟ ਸ਼ਾਮਲ ਕਰੋ ।"
override val empty_estimate_list_message: String = "ਆਪਣਾ ਪਹਿਲਾ ਅੰਦਾਜ਼ਾ ਬਣਾਓ"
override val empty_expenses: String = "ਤੁਹਾਡੇ ਕੋਲ ਟਰੈਕ ਕਰਨ ਲਈ ਕੋਈ ਖਰਚਾ ਨਹੀਂ ਹੈ। ਕੋਈ ਨਵਾਂ ਖਰਚਾ ਜੋੜੋ।"
override val empty_invoice_list_message: String = "ਆਪਣਾ ਪਹਿਲਾ ਚਲਾਨ ਬਣਾਓ"
override val empty_items_list_message: String = "ਤੁਹਾਡੇ ਕੋਲ ਕੋਈ ਆਈਟਮਾਂ ਨਹੀਂ ਹਨ, ਇੱਕ ਨਵੀਂ ਆਈਟਮ ਸ਼ਾਮਲ ਕਰੋ!"
override val empty_signatures_list_message: String = "ਕੋਈ ਦਸਤਖਤ ਨਹੀਂ ਲੱਭੇ ਹਨ । ਇੱਕ ਬਣਾਓ!"
override val empty_taxes_list_message: String = "ਤੁਹਾਡੇ ਕੋਲ ਕੋਈ ਟੈਕਸ ਨਿਯਮ ਨਹੀਂ ਹਨ। ਇੱਕ ਨਵਾਂ ਟੈਕਸ ਨਿਯਮ ਸ਼ਾਮਲ ਕਰੋ।"
override val empty_templates_list_message: String = "ਟੈਂਪਲੇਟ ਉਪਲਬਧ ਨਹੀਂ ਹਨ।"
override val enabled: String = "ਯੋਗ ਕੀਤਾ"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "ਅੰਦਾਜ਼ਾ #"
override val estimates: String = "ਅੰਦਾਜ਼ੇ"
override val estimates_report: String = "ਅੰਦਾਜ਼ੇ"
override val expense_bank_fees: String = "ਬੈਂਕ ਫੀਸ"
override val expense_benefits: String = "ਲਾਭ"
override val expense_communication: String = "ਸੰਚਾਰ"
override val expense_debt: String = "ਕਰਜ਼ਾ"
override val expense_deleted: String = "ਖਰਚਾ ਮਿਟਾਇਆ ਗਿਆ"
override val expense_depreciation: String = "ਘਟਾਓ"
override val expense_gifts: String = "ਤੋਹਫ਼ੇ"
override val expense_insurance: String = "ਬੀਮਾ"
override val expense_inventory: String = "ਵਸਤੂ ਸੂਚੀ"
override val expense_legal: String = "ਕਾਨੂੰਨੀ"
override val expense_maintenance: String = "ਰੱਖ-ਰਖਾਅ"
override val expense_marketing: String = "ਮਾਰਕੀਟਿੰਗ"
override val expense_meals: String = "ਭੋਜਨ"
override val expense_office_supplies: String = "ਦਫ਼ਤਰੀ ਸਪਲਾਈ"
override val expense_rent: String = "ਕਿਰਾਇਆ"
override val expense_salaries: String = "ਤਨਖਾਹ"
override val expense_shopping: String = "ਖਰੀਦਦਾਰੀ"
override val expense_software: String = "ਸਾਫਟਵੇਅਰ"
override val expense_status_available: String = "ਉਪਲੱਬਧ"
override val expense_status_processing: String = "ਕਾਰਵਾਈ"
override val expense_tax: String = "ਟੈਕਸ"
override val expense_training: String = "ਸਿਖਲਾਈ"
override val expense_transport: String = "ਆਵਾਜਾਈ"
override val expense_travel: String = "ਯਾਤਰਾ"
override val expense_utility: String = "ਉਪਯੋਗਤਾ"
override val expenses: String = "ਖਰਚੇ"
override val feedback_option: String = "ਫੀਡਬੈਕ"
override val filter: String = "ਫਿਲਟਰ"
override val first_name_hint: String = "ਪਹਿਲਾਂ ਨਾਂ"
override val forgot_password: String = "ਪਾਸਵਰਡ ਮੁੜ- ਸੈੱਟ"
override val forgot_password_label: String = "ਪਾਸਵਰਡ ਭੁੱਲ ਗਿਆ"
override val from: String = "ਤੋਂ"
override val general_error: String = "ਗੜਬੜ ਹੋ ਗਈ, ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "ਵਾਪਸ ਜਾਓ"
override val group_by: String = "ਗਰੁੱਪ:"
override val image_view: String = "ਚਿੱਤਰ"
override val install: String = "ਸਥਾਪਤ ਕਰੋ"
override val invalid_credentials_error: String = "ਗਲਤ ਪ੍ਰਮਾਣ-ਪੱਤਰ ।"
override val invalid_email_error: String = "ਅਵੈਧ ਈਮੇਲ।"
override val invalid_user_error: String = "ਅਵੈਧ ਯੂਜ਼ਰ ਕ੍ਰੈਡੈਂਸ਼ੀਅਲ।"
override val invoice_deleted_message: String = "ਇਨਵੌਇਸ ਹਟਾਇਆ ਗਿਆ"
override val invoice_menu: String = "ਇਨਵੌਇਸ ਮੇਨੂ"
override val invoice_number: String = "ਇਨਵੌਇਸ #"
override val invoices: String = "ਇਨਵੌਇਸ"
override val items_option: String = "ਆਈਟਮਾਂ"
override val keep: String = "ਰੱਖੋ"
override val language_selector: Formattable = Formattable { (selected) -> "ਭਾਸ਼ਾ ${selected}" }
override val last_name_hint: String = "ਆਖਰੀ ਨਾਂ"
override val ledger: String = "ledger"
override val legal: String = "ਕਾਨੂੰਨੀ"
override val legal_error: String = "ਸੇਵਾ ਦੀਆਂ ਮਦਾਂ ਅਤੇ ਪਰਦੇਦਾਰੀ ਨੀਤੀ ਨੂੰ ਸਵੀਕਾਰ ਕਰਨਾ ਲਾਜ਼ਮੀ ਹੈ"
override val liabilities: String = "liabilities"
override val loading: String = "ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ"
override val login_required_error: String = "ਲਾਗਇਨ ਲੋੜੀਂਦਾ ਹੈ ।"
override val logo_view: String = "ਲੋਗੋ"
override val logout: String = "ਲਾਗਆਉਟ"
override val logout_prompt: String = "ਤੁਹਾਡਾ ਡੇਟਾ ਕਲਾਉਡ ਨਾਲ ਸਿੰਕ੍ਰੋਨਾਈਜ਼ ਨਹੀਂ ਕੀਤਾ ਗਿਆ ਹੈ। ਲੌਗਆਊਟ 'ਤੇ ਇਨਵੌਇਸ, ਅੰਦਾਜ਼ਿਆਂ, ਗਾਹਕਾਂ ਅਤੇ ਹੋਰ ਜਾਣਕਾਰੀ ਨੂੰ ਮਿਟਾਉਣਾ ਹੈ?"
override val main_screen: String = "ਮੁੱਖ ਸਕਰੀਨ"
override val mark_paid: String = "ਭੁਗਤਾਨ ਕੀਤੇ ਵਜੋਂ ਨਿਸ਼ਾਨਦੇਹੀ ਕਰੋ"
override val message: String = "ਸੁਨੇਹਾ"
override val missing_sender_msg: String = "ਭੇਜਣ ਵਾਲੇ ਦੀ ਜਾਣਕਾਰੀ ਤੋਂ ਬਿਨਾਂ ਇੱਕ ਦਸਤਾਵੇਜ਼ ਅਵੈਧ ਹੋ ਸਕਦਾ ਹੈ।"
override val missing_sender_title: String = "ਭੇਜਣ ਵਾਲੇ ਦੀ ਜਾਣਕਾਰੀ ਮੌਜੂਦ ਨਹੀਂ ਹੈ"
override val month_april: String = "ਅਪ੍ਰੈਲ"
override val month_april_short: String = "ਅਪ੍ਰੈਲ"
override val month_august: String = "ਅਗਸਤ"
override val month_august_short: String = "ਅਗਸਤ"
override val month_december: String = "ਦਸੰਬਰ"
override val month_december_short: String = "ਦਸੰਬਰ"
override val month_february: String = "ਫਰਵਰੀ"
override val month_february_short: String = "ਫਰਵਰੀ"
override val month_january: String = "ਜਨਵਰੀ"
override val month_january_short: String = "ਜਨ"
override val month_july: String = "ਜੁਲਾਈ"
override val month_july_short: String = "ਜੁਲਾਈ"
override val month_june: String = "ਜੂਨ"
override val month_june_short: String = "ਜੂਨ"
override val month_march: String = "ਮਾਰਚ"
override val month_march_short: String = "ਮਾਰਚ"
override val month_may: String = "ਮਈ"
override val month_may_short: String = "ਮਈ"
override val month_november: String = "ਨਵੰਬਰ"
override val month_november_short: String = "ਨਵੰਬਰ"
override val month_october: String = "ਅਕਤੂਬਰ"
override val month_october_short: String = "ਅਕ"
override val month_september: String = "ਸਤੰਬਰ"
override val month_september_short: String = "ਸਤੰਬਰ"
override val multifactor_error: String = "ਤਸਦੀਕ ਕਰਨਾ ਅਸਫਲ ਰਿਹਾ।"
override val new: String = "ਨਵਾਂ"
override val news: String = "ਖ਼ਬਰਾਂ"
override val next: String = "ਅੱਗੇ"
override val no: String = "no"
override val no_data_available: String = "ਕੋਈ ਡਾਟਾ ਉਪਲਬਧ ਨਹੀਂ ਹੈ"
override val no_search_results: String = "ਮਾਫ਼ ਕਰਨਾ, ਅਸੀਂ ਕੋਈ ਵੀ ਨਤੀਜੇ ਨਹੀਂ ਲੱਭ ਸਕੇ।"
override val none: String = "ਕੋਈ ਨਹੀਂ"
override val note_fields: String = "ਨੋਟਿਸComment"
override val note_hint: String = "ਨੋਟ"
override val notifications_option: String = "ਨੋਟੀਫਿਕੇਸ਼ਨ"
override val ok: String = "ਠੀਕ ਹੈ"
override val onboarding: String = "ਆਨਬੋਰਡਿੰਗ"
override val onboarding_aboutus_message: String = "DingVoice ਦੇ ਜ਼ਰੂਰੀ ਹਿੱਸੇ ਹਮੇਸ਼ਾ ਲਈ ਮੁਫ਼ਤ ਹਨ। ਸਾਡਾ ਮਿਸ਼ਨ ਤੁਹਾਡੇ ਕਾਰੋਬਾਰ ਨੂੰ ਵਧਾਉਣ ਵਿੱਚ ਤੁਹਾਡੀ ਮਦਦ ਕਰਨਾ ਹੈ, ਤੁਹਾਡੇ ਫੀਡਬੈਕ ਨੂੰ ਸਾਂਝਾ ਕਰਕੇ ਸਾਨੂੰ ਬਿਹਤਰ ਬਣਾਉਣ ਵਿੱਚ ਮਦਦ ਕਰਨਾ ਹੈ।"
override val onboarding_aboutus_title: String = "ਅਸੀਂ ਮਦਦ ਕਰਨ ਲਈ ਇੱਥੇ ਹਾਂ"
override val onboarding_paid_1: String = "ਪੇਸ਼ੇਵਰ ਦਿਖਣ ਵਾਲੇ ਟੈਂਪਲੇਟਸ ਤੱਕ ਪਹੁੰਚ ਕਰੋ"
override val onboarding_paid_2: String = "ਆਪਣੇ ਵਿੱਤੀ ਬਾਰੇ ਡੂੰਘਾਈ ਨਾਲ ਵਿਚਾਰ ਕਰੋ"
override val onboarding_paid_3: String = "ਸਮੱਸਿਆਵਾਂ ਨੂੰ ਜਲਦੀ ਹੱਲ ਕਰੋ"
override val onboarding_paid_4: String = "ਆਪਣੇ ਦਸਤਾਵੇਜ਼ ਸੁਰੱਖਿਅਤ ਰੱਖੋ"
override val onboarding_paid_5: String = "ਇਨਵੌਇਸ ਸਥਿਤੀ ਤਬਦੀਲੀਆਂ ਨਾਲ ਅੱਪ ਟੂ ਡੇਟ ਰਹੋ"
override val onboarding_paid_title: String = "ਨਾਲ ਹੋਰ ਵੀ ਤੇਜ਼ੀ ਨਾਲ ਵਧੋ..."
override val onboarding_paywall_message: String = "ਹੁਣ ਸਭ ਤੋਂ ਵਧੀਆ ਕੀਮਤ &#39;ਤੇ ਸਾਰੀਆਂ ਪ੍ਰੀਮੀਅਮ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਦਾ ਅਨੰਦ ਲਓ।"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} ਲਈ ਸਿਰਫ਼ ${currencySymbol}${introAmount} ਅਤੇ ਫਿਰ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "ਸਿਰਫ਼ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} ਲਈ ਮੁਫ਼ਤ ਅਤੇ ਫਿਰ ਸਿਰਫ਼ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "ਲਈ ਮੁਫ਼ਤ${trialDuration}${trialPeriod} ਫਿਰ${currencySymbol}${introductoryAmount} ਲਈ${introductoryDuration}${introPeriod} ਅਤੇ ਫਿਰ ਬਸ${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "ਸਵੈ-ਨਵਿਆਉਣਯੋਗ। ਕਿਸੇ ਵੀ ਸਮੇਂ ਰੱਦ ਕਰੋ।"
override val onboarding_paywall_restore: String = "ਖਰੀਦ ਨੂੰ ਮੁੜ-ਬਹਾਲ ਕਰੋ"
override val onboarding_paywall_restore_failure: String = "ਖਰੀਦਦਾਰੀ ਨੂੰ ਰੀਸਟੋਰ ਕਰਨਾ ਅਸਫਲ ਰਿਹਾ, ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜਾਂ ਸਹਾਇਤਾ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।"
override val onboarding_paywall_restore_success: String = "ਰੀਸਟੋਰ ਸਫਲ!"
override val onboarding_paywall_title: String = "ਮਹਾਨਤਾ ਪ੍ਰਾਪਤ ਕਰੋ!"
override val onboarding_welcome_message: String = "ਤੁਹਾਡੇ ਇਨਵੌਇਸ ਅਤੇ ਅਨੁਮਾਨਾਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰਨ ਲਈ ਇੱਕ ਪੂਰਾ ਟੂਲਸੈੱਟ।"
override val onboarding_welcome_title: String = "DingVoice ਵਿੱਚ ਸੁਆਗਤ ਹੈ"
override val other_state: String = "ਹੋਰ"
override val overdue_state: String = "ਓਵਰਡਿਊ"
override val paid_state: String = "ਭੁਗਤਾਨ- ਯੋਗ"
override val password: String = "ਪਾਸਵਰਡ"
override val password_blank_error: String = "ਪਾਸਵਰਡ ਲੋੜੀਂਦਾ ਹੈ।"
override val password_mismatch_error: String = "ਪਾਸਵਰਡ ਮੇਲ ਨਹੀਂ ਖਾਂਦੇ।"
override val pay: String = "pay"
override val payment_instructions_label: String = "ਭੁਗਤਾਨ ਬਾਰੇ ਹਿਦਾਇਤਾਂ"
override val payment_reminders: String = "ਭੁਗਤਾਨ ਯਾਦ-ਦਹਾਨੀਆਂ"
override val phone_number_fields: String = "ਫੋਨ ਨੰਬਰ"
override val phone_number_hint: String = "ਫੋਨ ਨੰਬਰ"
override val postcode_hint: String = "ਪੋਸਟਕੋਡ"
override val preview: String = "ਝਲਕ"
override val preview_menu_hint: String = "ਝਲਕ ਮੇਨੂ"
override val price: String = "ਕੀਮਤ"
override val privacy_policy: String = "ਪਰਦੇਦਾਰੀ ਨੀਤੀ"
override val profile: String = "ਪਰੋਫਾਇਲ"
override val profitable_client: String = "ਤੁਹਾਡਾ ਸਭ ਤੋਂ ਵੱਧ ਲਾਭਦਾਇਕ ਗਾਹਕ ਹੈ..."
override val profits_report: String = "ਲਾਭ"
override val promo_content: String = "ਪ੍ਰਚਾਰ ਸਮੱਗਰੀ"
override val quantity_hint: String = "ਮਾਤਰਾ"
override val quantity_label_hint: String = "ਮਾਤਰਾ ਲੇਬਲ"
override val query_info: String = "ਪੁੱਛਗਿੱਛ ਤੁਹਾਨੂੰ ਦੋਵਾਂ ਚੀਜ਼ਾਂ ਅਤੇ ਹੋਰ ਟੈਕਸ ਨਿਯਮਾਂ ਨੂੰ ਇਨਪੁਟ ਵਜੋਂ ਦਰਸਾਉਂਦੇ ਹੋਏ ਕਸਟਮ ਗਣਨਾ ਲਿਖਣ ਦੀ ਆਗਿਆ ਦਿੰਦੀ ਹੈ। ਕਿਸੇ ਹੋਰ ਐਂਟਰੀ +, -, *, *, /, % - ਗਣਿਤ ਦੇ ਆਪਰੇਟਰਾਂ ਨਾਲ ਲਿੰਕ ਕਰਨ ਲਈ @ ਚਿੰਨ੍ਹ ਅਤੇ ਆਈਟਮ ਜਾਂ ਟੈਕਸ ਵਰਣਨ ਸ਼ਾਮਲ ਕਰੋ"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ਤੁਹਾਡੇ ਕਲਾਇੰਟ ਦੁਆਰਾ ਪ੍ਰਾਪਤ ਕੀਤਾ ਗਿਆ ਸੀ।" }
override val received_state: String = "ਪ੍ਰਾਪਤ"
override val registration_code_fields: String = "ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ"
override val reminder: String = "ਰੀਮਾਈਂਡਰ"
override val reports: String = "ਰਿਪੋਰਟਾਂ"
override val reports_not_available: String = "ਕੋਈ ਰਿਪੋਰਟ ਉਪਲਬਧ ਨਹੀਂ ਹੈ, ਬਾਅਦ ਵਿੱਚ ਵਾਪਸ ਆਓ।"
override val reveal_password: String = "ਪਾਸਵਰਡ ਖੋਲ੍ਹੋ"
override val revenue: String = "revenue"
override val sales_tax: String = "ਵੈਟ"
override val sales_tax_number: String = "ਵੈਟ ਨੰਬਰ"
override val save: String = "ਸੰਭਾਲੋ"
override val scan_document: String = "ਦਸਤਾਵੇਜ਼ ਸਕੈਨ ਕਰੋ"
override val search: String = "ਖੋਜ"
override val select: String = "ਚੁਣੋ"
override val select_icon: String = "ਆਈਕਨ ਚੁਣੋ"
override val select_signature: String = "ਦਸਤਖਤ ਚੁਣੋ"
override val select_template: String = "ਟੈਂਪਲੇਟ ਚੁਣੋ"
override val send: String = "ਭੇਜੋ"
override val sent_state: String = "ਭੇਜੇ"
override val setup_sender: String = "ਭੇਜਣ ਵਾਲੇ ਨੂੰ ਸੈੱਟਅੱਪ ਕਰੋ"
override val share_option: String = "ਸਾਂਝਾ ਕਰੋ"
override val ship_to: String = "ਭੇਜ ਦਿਓ"
override val sign_in: String = "ਸਾਈਨ ਇਨ ਕਰੋ"
override val sign_in_apple: String = "ਐਪਲ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰੋ"
override val sign_in_email: String = "ਈਮੇਲ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰੋ"
override val sign_in_facebook: String = "ਫੇਸਬੁੱਕ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰੋ"
override val sign_in_google: String = "Google ਨਾਲ ਸਾਈਨ ਇਨ ਕਰੋ"
override val sign_up: String = "ਸਾਈਨ ਅੱਪ ਕਰੋ"
override val sign_up_label: String = "ਤੁਹਾਡਾ ਕੋਈ ਖਾਤਾ ਨਹੀਂ ਹੈ?"
override val sign_up_link_label: String = "ਸਾਈਨ ਅੱਪ ਕਰੋ!"
override val signatures_option: String = "ਦਸਤਖਤ"
override val skip: String = "ਛੱਡੋ"
override val sold_items_report: String = "ਵਿਕੀਆਂ ਵਸਤੂਆਂ"
override val sort_by: String = "ਲੜੀਬੱਧ:"
override val status_update: String = "ਦਸਤਾਵੇਜ਼ ਸਥਿਤੀ ਅੱਪਡੇਟ"
override val sub_annual: String = "ਸਾਲਾਨਾ"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/ਸਾਲ" }
override val sub_backups: String = "ਕਲਾਊਡ ਬੈਕਅੱਪ"
override val sub_insights: String = "ਇਨਸਾਈਟਸ"
override val sub_month: String = "ਮਹੀਨਾਵਾਰ"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ਮਹੀਨਾ" }
override val sub_notifications: String = "ਸੂਚਨਾਵਾਂ"
override val sub_popup_message: String = "ਗਾਹਕੀਆਂ ਜਲਦੀ ਆ ਰਹੀਆਂ ਹਨ। ਥੋੜ੍ਹੀ ਦੇਰ ਬਾਅਦ ਇੱਥੇ ਵਾਪਸ ਜਾਂਚ ਕਰੋ।"
override val sub_support: String = "ਸਮਰਥਨ"
override val sub_templates: String = "ਟੈਮਪਲੇਟ"
override val subscribe: String = "ਮੈਂਬਰ ਬਣੋ"
override val subscription_header: String = "ਇਸ ਨਾਲ ਆਪਣਾ ਕਾਰੋਬਾਰ ਵਧਾਓ:"
override val subscription_option: String = "ਸਬਸਕ੍ਰਿਪਸ਼ਨ"
override val subscriptions_load_fail: String = "ਮਾਫ਼ ਕਰਨਾ, ਅਸੀਂ ਇਸ ਸਮੇਂ ਤੁਹਾਡੀਆਂ ਗਾਹਕੀਆਂ ਨੂੰ ਮੁੜ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ ਹਾਂ। ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜਾਂ ਸਹਾਇਤਾ ਲਈ ਸਾਡੀ ਸਹਾਇਤਾ ਟੀਮ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।"
override val subtotal: Formattable = Formattable { (sum) -> "ਸਬ-ਟੋਟਲ: ${sum}" }
override val subtotal_label: String = "ਉਪ-ਯੋਗ"
override val sum: String = "Sum"
override val support_option: String = "ਸਹਿਯੋਗ"
override val switch_reports: String = "ਰਿਪੋਰਟ ਬਦਲੋ"
override val tax: String = "ਟੈਕਸ"
override val tax_deleted_message: String = "ਟੈਕਸ ਹਟਾਇਆ"
override val tax_description_warning: String = "ਜੇ ਵਰਤਿਆ ਜਾਂਦਾ ਹੈ ਤਾਂ ਇਹ ਇੱਕ ਚਲਾਨ 'ਤੇ ਦਿਖਾਈ ਦਿੰਦਾ ਹੈ"
override val tax_input_flat: String = "ਫਲੈਟ"
override val tax_input_percent: String = "ਪ੍ਰਤੀਸ਼ਤ"
override val tax_number_fields: String = "ਟੈਕਸ ਨੰਬਰ"
override val tax_number_hint: String = "ਟੈਕਸ ਨੰਬਰ"
override val tax_query_info_description: String = "ਕਿਊਰੀ ਜਾਣਕਾਰੀ"
override val tax_report: String = "ਟੈਕਸ"
override val tax_rules_option: String = "ਟੈਕਸ ਨਿਯਮ"
override val taxed_clients_report: String = "ਟੈਕਸ ਲਗਾਇਆ ਗਿਆ ਗਾਹਕ"
override val template: String = "ਟੈਂਪਲੇਟ"
override val template_selector_info: String = "ਡਿਫੌਲਟ ਟੈਂਪਲੇਟ ਨੂੰ ਬਦਲਣ ਲਈ ਸਵਾਈਪ ਕਰੋ"
override val template_unlock_promo: String = "ਪ੍ਰੀਮੀਅਮ ਨਾਲ ਹੋਰ ਟੈਂਪਲੇਟਸ ਤੱਕ ਪਹੁੰਚ ਕਰੋ"
override val templates_option: String = "ਟੈਂਪਲੇਟ"
override val terms_of_service: String = "ਸੇਵਾ ਦੀਆਂ ਮਦਾਂ"
override val thank_you: String = "ਤੁਹਾਡਾ ਧੰਨਵਾਦ"
override val to: String = "ਇਸ ਲਈ"
override val total: Formattable = Formattable { (sum) -> "ਕੁੱਲ: ${sum}" }
override val total_label: String = "ਕੁੱਲ"
override val undo_action: String = "ਵਾਪਿਸ"
override val unit: String = "ਇਕਾਈ"
override val unit_cost_hint: String = "ਯੂਨਿਟ ਲਾਗਤ"
override val unknown_client: String = "ਅਗਿਆਤ ਕਲਾਇੰਟ"
override val unlock: String = "ਅਨਲੌਕ ਕਰੋ"
override val unnamed_item: String = "ਬੇਨਾਮ ਆਈਟਮ"
override val upgrade: String = "ਅੱਪਗਰੇਡ"
override val user_collision_error: String = "ਖਾਤਾ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ।"
override val valid_until: String = "ਤੱਕ ਵੈਧ"
override val warning: String = "ਚੇਤਾਵਨੀ!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} ਅਤੇ ${privacyPolicy} ਨੂੰ ਸਵੀਕਾਰ ਕਰੋ" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "ਅਵੈਧ ਕੋਡ।",
	"add_client_action" to "ਕਲਾਇਟ ਸ਼ਾਮਲ",
	"add_customer" to "ਗਾਹਕ ਸ਼ਾਮਲ",
	"add_expense" to "ਖਰਚਾ ਜੋੜੋ",
	"add_fields_label" to "ਸ਼ਾਮਲ:",
	"add_invoice_action" to "ਬਣਾਓ",
	"add_item_action" to "ਆਈਟਮ ਸ਼ਾਮਲ",
	"add_item_button" to "ਆਈਟਮ ਸ਼ਾਮਲ",
	"add_signature_action" to "ਦਸਤਖਤ ਸ਼ਾਮਲ",
	"add_tax_action" to "ਟੈਕਸ ਸ਼ਾਮਲ",
	"address_fields" to "ਐਡਰੈੱਸ",
	"address_line_hint" to "ਐਡਰੈੱਸ ਲਾਈਨ",
	"advanced" to "ਉੱਨਤ",
	"agree_marketing" to "ਮੈਂ ਡਾਇਰੈਕਟ ਮਾਰਕੀਟਿੰਗ ਸੰਚਾਰ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਸਹਿਮਤ ਹਾਂ",
	"all_notifications" to "ਸਭ ਨੋਟੀਫਿਕੇਸ਼ਨ",
	"amortization" to "amortization",
	"amount" to "ਰਕਮ",
	"asset" to "asset",
	"attach_file" to "ਫਾਈਲ ਅਟੈਚ ਕਰੋ",
	"attachment" to "ਅਟੈਚਮੈਂਟ",
	"auth_error" to "ਕਾਰਵਾਈ ਅਸਫਲ ਰਹੀ, ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
	"available_for_paid" to "Cloud ਸਿੰਕ ਸਿਰਫ਼ ਭੁਗਤਾਨ-ਯੋਗ ਸਬਸਕ੍ਰਿਪਸ਼ਨ ਦੇ ਤਹਿਤ ਹੀ ਉਪਲਬਧ ਹੈ।",
	"backups_option" to "ਬੈਕਅੱਪ",
	"balance" to "balance",
	"bank_account" to "ਬੈੰਕ ਖਾਤਾ",
	"bill_number" to "ਬਿੱਲ ਨੰਬਰ",
	"cancel" to "ਰੱਦ ਕਰੋ",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "ਵਰਗ",
	"category" to "ਸ਼੍ਰੇਣੀ",
	"category_add" to "ਸ਼੍ਰੇਣੀ ਸ਼ਾਮਲ ਕਰੋ",
	"category_delete" to "ਸ਼੍ਰੇਣੀ ਮਿਟਾਓ",
	"category_name" to "ਸ਼੍ਰੇਣੀ ਦਾ ਨਾਮ",
	"change_language" to Formattable { (language) -> "ਇਨਵੌਇਸ ਭਾਸ਼ਾ: ${language}" },
	"change_language_label" to "ਇਨਵੌਇਸ ਭਾਸ਼ਾ",
	"change_template" to "ਟੈਂਪਲੇਟ ਬਦਲੋ",
	"city_hint" to "ਸ਼ਹਿਰ",
	"clear" to "ਸਾਫ਼ ਕਰੋ",
	"client" to "ਗਾਹਕ",
	"client_actions" to "ਗਾਹਕ ਕਾਰਵਾਈਆਂ",
	"client_deleted_message" to "ਗਾਹਕ ਹਟਾਇਆ ਗਿਆ",
	"clients_error_required_fields" to "ਘੱਟੋ-ਘੱਟ ਇੱਕ ਮੁੱਲ ਲੋੜੀਂਦਾ ਹੈ।",
	"clients_option" to "ਗਾਹਕ",
	"close" to "ਬੰਦ ਕਰੋ",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "ਜਲਦੀ ਆ ਰਿਹਾ ਹੈ!",
	"company_logo" to "ਕੰਪਨੀ ਲੋਗੋ",
	"company_name_hint" to "ਕੰਪਨੀ",
	"confirm_password_label" to "ਪਾਸਵਰਡ ਪੁਸ਼ਟੀ",
	"confirmation_title" to "ਤੁਹਾਨੂੰ ਪੂਰਾ ਵਿਸ਼ਵਾਸ ਹੈ?",
	"convert_to_invoice" to "ਚਲਾਨ ਲਈ ਬਦਲੋ",
	"country_hint" to "ਦੇਸ਼",
	"create_client" to "ਕਲਾਇਟ ਬਣਾਓ",
	"create_company" to "ਕੰਪਨੀ ਬਣਾਓ",
	"create_document" to "ਡੌਕੂਮੈਂਟ ਬਣਾਓ",
	"create_estimate" to "ਅੰਦਾਜ਼ਾ",
	"create_invoice" to "ਇਨਵੌਇਸ",
	"create_item" to "ਆਈਟਮ ਬਣਾਓ",
	"create_new_client" to "ਨਵਾਂ ਕਲਾਇਟ ਬਣਾਓ",
	"create_new_item" to "ਨਵੀਂ ਆਈਟਮ ਬਣਾਓ",
	"create_new_tax" to "ਨਵਾਂ ਟੈਕਸ ਬਣਾਓ",
	"create_signature" to "ਦਸਤਖਤ",
	"created" to "ਬਣਾਇਆ",
	"credit" to "credit",
	"customer" to "ਗਾਹਕ",
	"date" to "ਮਿਤੀ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "ਪੂਰਵ-ਨਿਰਧਾਰਤ ਮੁਦਰਾ: ${currency}" },
	"default_currency_label" to "ਮੂਲ ਮੁਦਰਾ",
	"delete" to "ਹਟਾਓ",
	"delete_account" to "ਖਾਤਾ ਮਿਟਾਓ",
	"delete_confirmation_message" to "ਤੁਹਾਡਾ ਸਾਰਾ ਡਾਟਾ ਖਤਮ ਹੋ ਜਾਵੇਗਾ।",
	"delete_item_title" to "ਕੀ ਆਈਟਮ ਹਟਾਉਣੀ ਹੈ?",
	"delete_signature" to "ਦਸਤਖਤ ਹਟਾਓ",
	"depreciation" to "depreciation",
	"description" to "ਵਰਣਨ",
	"description_hint" to "ਵੇਰਵਾ",
	"detailed_report" to "ਵਿਸਤ੍ਰਿਤ ਰਿਪੋਰਟ",
	"disabled" to "ਆਯੋਗ",
	"discount" to "ਛੂਟ",
	"discount_hint" to "ਛੋਟ",
	"display_af" to "ਅਫਰੀਕਨ",
	"display_am" to "ਅਮਹਾਰਿਕ",
	"display_ar" to "ਅਰਬੀ",
	"display_as" to "ਅਸਾਮੀ",
	"display_az" to "ਅਜ਼ਰਬਾਈਜਾਨੀ",
	"display_be" to "ਬੇਲਾਰੂਸੀ",
	"display_bg" to "ਬਲਗੇਰੀਅਨ",
	"display_bn" to "ਬੰਗਾਲੀ",
	"display_bs" to "ਬੋਸਨੀਆਈ",
	"display_ca" to "ਕੈਟਲਨ",
	"display_cs" to "ਚੈੱਕ",
	"display_da" to "ਡੈਨਿਸ਼",
	"display_de" to "ਜਰਮਨ",
	"display_el" to "ਯੂਨਾਨੀ",
	"display_en" to "ਅੰਗਰੇਜ਼ੀ",
	"display_es" to "ਸਪੇਨੀ",
	"display_es_MX" to "ਸਪੇਨੀ (ਮੈਕਸੀਕੋ)",
	"display_et" to "ਇਸਟੋਨੀਅਨ",
	"display_eu" to "ਬਾਸਕ",
	"display_fa" to "ਫਾਰਸੀ",
	"display_fi" to "ਫਿਨਿਸ਼",
	"display_fil" to "ਫਿਲੀਪੀਨੋ",
	"display_fr" to "ਫ੍ਰੈਂਚ",
	"display_fr_CA" to "ਕੈਨੇਡੀਅਨ ਫ੍ਰੈਂਚ",
	"display_gl" to "ਗੈਲੀਸ਼ੀਅਨ",
	"display_gu" to "ਗੁਜਰਾਤੀ",
	"display_he" to "ਹਿਬਰੂ",
	"display_hi" to "ਸੰ.",
	"display_hr" to "ਕਰੋਸ਼ੀਅਨ",
	"display_hu" to "ਹੰਗਰੀਆਈ",
	"display_hy" to "ਅਰਮੀਨੀਆਈ",
	"display_id" to "ਇੰਡੋਨੇਸ਼ੀਆਈ",
	"display_is" to "ਆਈਸਲੈਂਡਿਕ",
	"display_it" to "ਇਤਾਲਵੀ",
	"display_ja" to "ਜਾਪਾਨੀ",
	"display_ka" to "ਜਾਰਜੀਅਨ",
	"display_kk" to "ਕਜ਼ਾਖ",
	"display_km" to "ਕੇਂਦਰੀ ਖਮੇਰ",
	"display_kn" to "ਕੰਨੜ",
	"display_ko" to "ਕੋਰੀਅਨ",
	"display_ky" to "ਕਿਰਗੀਜ਼",
	"display_lo" to "ਲੇਬਰ",
	"display_lt" to "ਲਿਥੁਆਨੀਅਨ",
	"display_lv" to "ਲਾਤਵੀਅਨ",
	"display_mk" to "ਮੈਸੇਡੋਨੀਅਨ",
	"display_ml" to "ਮਲਿਆਲਮ",
	"display_mn" to "ਮੰਗੋਲੀਆਈ",
	"display_mr" to "ਮਰਾਠੀ",
	"display_ms" to "ਮਾਲੇ",
	"display_my" to "ਬਰਮੀ",
	"display_nb" to "ਨਾਰਵੇਜਿਅਨ ਬੋਕਮਾਲ",
	"display_ne" to "ਨੇਪਾਲੀ",
	"display_nl" to "ਡੱਚ",
	"display_or" to "ਉੜੀਆ",
	"display_pa" to "ਪੰਜਾਬੀ",
	"display_pl" to "ਪੋਲਿਸ਼",
	"display_pt" to "ਪੁਰਤਗਾਲੀ",
	"display_pt_BR" to "ਪੁਰਤਗਾਲੀ (ਬ੍ਰਾਜ਼ੀਲ)",
	"display_pt_PT" to "ਯੂਰਪੀ ਪੁਰਤਗਾਲੀ",
	"display_ro" to "ਰੋਮਾਨੀਅਨ",
	"display_ru" to "ਰੂਸੀ",
	"display_si" to "ਸਿੰਹਾਲਾ",
	"display_sk" to "ਸਲੋਵਾਕ",
	"display_sl" to "ਸਲੋਵੇਨੀਆਈ",
	"display_sq" to "ਅਲਬਾਨੀਅਨ",
	"display_sr" to "ਸਰਬੀਆਈ",
	"display_sv" to "ਸਵੀਡਿਸ਼",
	"display_sw" to "ਸਵਾਹਿਲੀ",
	"display_ta" to "ਤਾਮਿਲ",
	"display_te" to "ਤੇਲਗੂ",
	"display_th" to "ਥਾਈ",
	"display_tr" to "ਤੁਰਕੀ",
	"display_uk" to "ਯੂਕਰੇਨੀ",
	"display_ur" to "ਉਰਦੂ",
	"display_uz" to "ਉਜ਼ਬੇਕ",
	"display_vi" to "ਵੀਅਤਨਾਮੀ",
	"display_zh_Hant_TW" to "ਚੀਨੀ (ਤਾਈਵਾਨ, ਪਰੰਪਰਾਗਤ)",
	"display_zu" to "ਜ਼ੁਲੂ",
	"document_estimate" to "ਅੰਦਾਜ਼ਾ",
	"document_invoice" to "ਇਨਵੌਇਸ",
	"document_quote" to "ਹਵਾਲਾ",
	"done" to "ਹੋ ਗਿਆ",
	"download_app" to "ਐਪ ਨੂੰ ਡਾਊਨਲੋਡ ਕਰੋ",
	"download_invoice" to "ਇਨਵੌਇਸ ਡਾਊਨਲੋਡ ਕਰੋ",
	"download_pdf" to "PDF ਡਾਊਨਲੋਡ ਕਰੋ",
	"draw_signature" to "ਇੱਕ ਦਸਤਖਤ ਬਣਾਓ",
	"due" to Formattable { (date) -> "ਬਕਾਇਆ ${date}" },
	"due_date" to "ਦੇਣ ਮਿਤੀ",
	"due_day" to "ਇੱਕ ਦਿਨ ਵਿੱਚ",
	"due_label" to "ਕਾਰਨ",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ਬਕਾਇਆ ${dueWhen} ਹੈ!" },
	"due_today" to "ਅੱਜ",
	"due_week" to "ਇੱਕ ਹਫਤੇ ਵਿੱਚ",
	"duplicate" to "ਡੁਪਲੀਕੇਟ",
	"duration_day" to "ਦਿਨ",
	"duration_month" to "ਮਹੀਨਾ",
	"duration_week" to "ਹਫਤਾ",
	"duration_year" to "ਸਾਲ",
	"email" to "ਈ- ਮੇਲ",
	"email_blank_error" to "ਈ- ਮੇਲ ਦੀ ਲੋੜ ਹੈ ।",
	"email_error" to "ਅਵੈਧ ਈਮੇਲ ਪਤਾ।",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "ਤੁਹਾਡੇ ਕਾਰੋਬਾਰ ਲਈ ਧੰਨਵਾਦ। ਪੂਰਾ ${documentType} ਦੇਖਣ ਲਈ ਇਸ ਅੱਖਰ ਦੇ ਅੰਤ &#39;ਤੇ ਬਟਨ &#39;ਤੇ ਕਲਿੱਕ ਕਰੋ। ਨੋਟ ਕਰੋ ਕਿ ਇਹ ${dueDate} ਤੱਕ ਵੈਧ ਹੈ।" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "ਤੁਹਾਨੂੰ ਇੱਕ ਨਵਾਂ ਪ੍ਰਾਪਤ ਹੋਇਆ ਹੈ${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} ਦੇਖੋ" },
	"email_invoice_greeting" to Formattable { (clientName) -> "ਪਿਆਰੇ ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "ਤੁਹਾਡੇ ਕਾਰੋਬਾਰ ਲਈ ਧੰਨਵਾਦ। ਪੂਰਾ ${documentType} ਦੇਖਣ ਲਈ ਇਸ ਅੱਖਰ ਦੇ ਅੰਤ &#39;ਤੇ ਬਟਨ &#39;ਤੇ ਕਲਿੱਕ ਕਰੋ। ਕਿਰਪਾ ਕਰਕੇ ${dueDate} ਤੱਕ ਬਕਾਇਆ ਦਾ ਭੁਗਤਾਨ ਕਰੋ।" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "ਤੁਹਾਨੂੰ ਇੱਕ ਨਵਾਂ ਪ੍ਰਾਪਤ ਹੋਇਆ ਹੈ${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "ਭੇਜੇ ਗਏ ਪਾਸਵਰਡ ਨੂੰ ਰੀਸੈੱਟ ਕਰਨ ਲਈ ਈਮੇਲ ਕਰੋ, ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਇਨਬਾਕਸ ਦੀ ਜਾਂਚ ਕਰੋ।",
	"empty_clients_list_message" to "ਤੁਹਾਡੇ ਕੋਲ ਟਰੈਕ ਕਰਨ ਲਈ ਕੋਈ ਗਾਹਕ ਨਹੀਂ ਹਨ। ਇੱਕ ਨਵਾਂ ਕਲਾਈਂਟ ਸ਼ਾਮਲ ਕਰੋ ।",
	"empty_estimate_list_message" to "ਆਪਣਾ ਪਹਿਲਾ ਅੰਦਾਜ਼ਾ ਬਣਾਓ",
	"empty_expenses" to "ਤੁਹਾਡੇ ਕੋਲ ਟਰੈਕ ਕਰਨ ਲਈ ਕੋਈ ਖਰਚਾ ਨਹੀਂ ਹੈ। ਕੋਈ ਨਵਾਂ ਖਰਚਾ ਜੋੜੋ।",
	"empty_invoice_list_message" to "ਆਪਣਾ ਪਹਿਲਾ ਚਲਾਨ ਬਣਾਓ",
	"empty_items_list_message" to "ਤੁਹਾਡੇ ਕੋਲ ਕੋਈ ਆਈਟਮਾਂ ਨਹੀਂ ਹਨ, ਇੱਕ ਨਵੀਂ ਆਈਟਮ ਸ਼ਾਮਲ ਕਰੋ!",
	"empty_signatures_list_message" to "ਕੋਈ ਦਸਤਖਤ ਨਹੀਂ ਲੱਭੇ ਹਨ । ਇੱਕ ਬਣਾਓ!",
	"empty_taxes_list_message" to "ਤੁਹਾਡੇ ਕੋਲ ਕੋਈ ਟੈਕਸ ਨਿਯਮ ਨਹੀਂ ਹਨ। ਇੱਕ ਨਵਾਂ ਟੈਕਸ ਨਿਯਮ ਸ਼ਾਮਲ ਕਰੋ।",
	"empty_templates_list_message" to "ਟੈਂਪਲੇਟ ਉਪਲਬਧ ਨਹੀਂ ਹਨ।",
	"enabled" to "ਯੋਗ ਕੀਤਾ",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "ਅੰਦਾਜ਼ਾ #",
	"estimates" to "ਅੰਦਾਜ਼ੇ",
	"estimates_report" to "ਅੰਦਾਜ਼ੇ",
	"expense_bank_fees" to "ਬੈਂਕ ਫੀਸ",
	"expense_benefits" to "ਲਾਭ",
	"expense_communication" to "ਸੰਚਾਰ",
	"expense_debt" to "ਕਰਜ਼ਾ",
	"expense_deleted" to "ਖਰਚਾ ਮਿਟਾਇਆ ਗਿਆ",
	"expense_depreciation" to "ਘਟਾਓ",
	"expense_gifts" to "ਤੋਹਫ਼ੇ",
	"expense_insurance" to "ਬੀਮਾ",
	"expense_inventory" to "ਵਸਤੂ ਸੂਚੀ",
	"expense_legal" to "ਕਾਨੂੰਨੀ",
	"expense_maintenance" to "ਰੱਖ-ਰਖਾਅ",
	"expense_marketing" to "ਮਾਰਕੀਟਿੰਗ",
	"expense_meals" to "ਭੋਜਨ",
	"expense_office_supplies" to "ਦਫ਼ਤਰੀ ਸਪਲਾਈ",
	"expense_rent" to "ਕਿਰਾਇਆ",
	"expense_salaries" to "ਤਨਖਾਹ",
	"expense_shopping" to "ਖਰੀਦਦਾਰੀ",
	"expense_software" to "ਸਾਫਟਵੇਅਰ",
	"expense_status_available" to "ਉਪਲੱਬਧ",
	"expense_status_processing" to "ਕਾਰਵਾਈ",
	"expense_tax" to "ਟੈਕਸ",
	"expense_training" to "ਸਿਖਲਾਈ",
	"expense_transport" to "ਆਵਾਜਾਈ",
	"expense_travel" to "ਯਾਤਰਾ",
	"expense_utility" to "ਉਪਯੋਗਤਾ",
	"expenses" to "ਖਰਚੇ",
	"feedback_option" to "ਫੀਡਬੈਕ",
	"filter" to "ਫਿਲਟਰ",
	"first_name_hint" to "ਪਹਿਲਾਂ ਨਾਂ",
	"forgot_password" to "ਪਾਸਵਰਡ ਮੁੜ- ਸੈੱਟ",
	"forgot_password_label" to "ਪਾਸਵਰਡ ਭੁੱਲ ਗਿਆ",
	"from" to "ਤੋਂ",
	"general_error" to "ਗੜਬੜ ਹੋ ਗਈ, ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
	"general_ledger" to "general ledger",
	"go_back_label" to "ਵਾਪਸ ਜਾਓ",
	"group_by" to "ਗਰੁੱਪ:",
	"image_view" to "ਚਿੱਤਰ",
	"install" to "ਸਥਾਪਤ ਕਰੋ",
	"invalid_credentials_error" to "ਗਲਤ ਪ੍ਰਮਾਣ-ਪੱਤਰ ।",
	"invalid_email_error" to "ਅਵੈਧ ਈਮੇਲ।",
	"invalid_user_error" to "ਅਵੈਧ ਯੂਜ਼ਰ ਕ੍ਰੈਡੈਂਸ਼ੀਅਲ।",
	"invoice_deleted_message" to "ਇਨਵੌਇਸ ਹਟਾਇਆ ਗਿਆ",
	"invoice_menu" to "ਇਨਵੌਇਸ ਮੇਨੂ",
	"invoice_number" to "ਇਨਵੌਇਸ #",
	"invoices" to "ਇਨਵੌਇਸ",
	"items_option" to "ਆਈਟਮਾਂ",
	"keep" to "ਰੱਖੋ",
	"language_selector" to Formattable { (selected) -> "ਭਾਸ਼ਾ ${selected}" },
	"last_name_hint" to "ਆਖਰੀ ਨਾਂ",
	"ledger" to "ledger",
	"legal" to "ਕਾਨੂੰਨੀ",
	"legal_error" to "ਸੇਵਾ ਦੀਆਂ ਮਦਾਂ ਅਤੇ ਪਰਦੇਦਾਰੀ ਨੀਤੀ ਨੂੰ ਸਵੀਕਾਰ ਕਰਨਾ ਲਾਜ਼ਮੀ ਹੈ",
	"liabilities" to "liabilities",
	"loading" to "ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ",
	"login_required_error" to "ਲਾਗਇਨ ਲੋੜੀਂਦਾ ਹੈ ।",
	"logo_view" to "ਲੋਗੋ",
	"logout" to "ਲਾਗਆਉਟ",
	"logout_prompt" to "ਤੁਹਾਡਾ ਡੇਟਾ ਕਲਾਉਡ ਨਾਲ ਸਿੰਕ੍ਰੋਨਾਈਜ਼ ਨਹੀਂ ਕੀਤਾ ਗਿਆ ਹੈ। ਲੌਗਆਊਟ 'ਤੇ ਇਨਵੌਇਸ, ਅੰਦਾਜ਼ਿਆਂ, ਗਾਹਕਾਂ ਅਤੇ ਹੋਰ ਜਾਣਕਾਰੀ ਨੂੰ ਮਿਟਾਉਣਾ ਹੈ?",
	"main_screen" to "ਮੁੱਖ ਸਕਰੀਨ",
	"mark_paid" to "ਭੁਗਤਾਨ ਕੀਤੇ ਵਜੋਂ ਨਿਸ਼ਾਨਦੇਹੀ ਕਰੋ",
	"message" to "ਸੁਨੇਹਾ",
	"missing_sender_msg" to "ਭੇਜਣ ਵਾਲੇ ਦੀ ਜਾਣਕਾਰੀ ਤੋਂ ਬਿਨਾਂ ਇੱਕ ਦਸਤਾਵੇਜ਼ ਅਵੈਧ ਹੋ ਸਕਦਾ ਹੈ।",
	"missing_sender_title" to "ਭੇਜਣ ਵਾਲੇ ਦੀ ਜਾਣਕਾਰੀ ਮੌਜੂਦ ਨਹੀਂ ਹੈ",
	"month_april" to "ਅਪ੍ਰੈਲ",
	"month_april_short" to "ਅਪ੍ਰੈਲ",
	"month_august" to "ਅਗਸਤ",
	"month_august_short" to "ਅਗਸਤ",
	"month_december" to "ਦਸੰਬਰ",
	"month_december_short" to "ਦਸੰਬਰ",
	"month_february" to "ਫਰਵਰੀ",
	"month_february_short" to "ਫਰਵਰੀ",
	"month_january" to "ਜਨਵਰੀ",
	"month_january_short" to "ਜਨ",
	"month_july" to "ਜੁਲਾਈ",
	"month_july_short" to "ਜੁਲਾਈ",
	"month_june" to "ਜੂਨ",
	"month_june_short" to "ਜੂਨ",
	"month_march" to "ਮਾਰਚ",
	"month_march_short" to "ਮਾਰਚ",
	"month_may" to "ਮਈ",
	"month_may_short" to "ਮਈ",
	"month_november" to "ਨਵੰਬਰ",
	"month_november_short" to "ਨਵੰਬਰ",
	"month_october" to "ਅਕਤੂਬਰ",
	"month_october_short" to "ਅਕ",
	"month_september" to "ਸਤੰਬਰ",
	"month_september_short" to "ਸਤੰਬਰ",
	"multifactor_error" to "ਤਸਦੀਕ ਕਰਨਾ ਅਸਫਲ ਰਿਹਾ।",
	"new" to "ਨਵਾਂ",
	"news" to "ਖ਼ਬਰਾਂ",
	"next" to "ਅੱਗੇ",
	"no" to "no",
	"no_data_available" to "ਕੋਈ ਡਾਟਾ ਉਪਲਬਧ ਨਹੀਂ ਹੈ",
	"no_search_results" to "ਮਾਫ਼ ਕਰਨਾ, ਅਸੀਂ ਕੋਈ ਵੀ ਨਤੀਜੇ ਨਹੀਂ ਲੱਭ ਸਕੇ।",
	"none" to "ਕੋਈ ਨਹੀਂ",
	"note_fields" to "ਨੋਟਿਸComment",
	"note_hint" to "ਨੋਟ",
	"notifications_option" to "ਨੋਟੀਫਿਕੇਸ਼ਨ",
	"ok" to "ਠੀਕ ਹੈ",
	"onboarding" to "ਆਨਬੋਰਡਿੰਗ",
	"onboarding_aboutus_message" to "DingVoice ਦੇ ਜ਼ਰੂਰੀ ਹਿੱਸੇ ਹਮੇਸ਼ਾ ਲਈ ਮੁਫ਼ਤ ਹਨ। ਸਾਡਾ ਮਿਸ਼ਨ ਤੁਹਾਡੇ ਕਾਰੋਬਾਰ ਨੂੰ ਵਧਾਉਣ ਵਿੱਚ ਤੁਹਾਡੀ ਮਦਦ ਕਰਨਾ ਹੈ, ਤੁਹਾਡੇ ਫੀਡਬੈਕ ਨੂੰ ਸਾਂਝਾ ਕਰਕੇ ਸਾਨੂੰ ਬਿਹਤਰ ਬਣਾਉਣ ਵਿੱਚ ਮਦਦ ਕਰਨਾ ਹੈ।",
	"onboarding_aboutus_title" to "ਅਸੀਂ ਮਦਦ ਕਰਨ ਲਈ ਇੱਥੇ ਹਾਂ",
	"onboarding_paid_1" to "ਪੇਸ਼ੇਵਰ ਦਿਖਣ ਵਾਲੇ ਟੈਂਪਲੇਟਸ ਤੱਕ ਪਹੁੰਚ ਕਰੋ",
	"onboarding_paid_2" to "ਆਪਣੇ ਵਿੱਤੀ ਬਾਰੇ ਡੂੰਘਾਈ ਨਾਲ ਵਿਚਾਰ ਕਰੋ",
	"onboarding_paid_3" to "ਸਮੱਸਿਆਵਾਂ ਨੂੰ ਜਲਦੀ ਹੱਲ ਕਰੋ",
	"onboarding_paid_4" to "ਆਪਣੇ ਦਸਤਾਵੇਜ਼ ਸੁਰੱਖਿਅਤ ਰੱਖੋ",
	"onboarding_paid_5" to "ਇਨਵੌਇਸ ਸਥਿਤੀ ਤਬਦੀਲੀਆਂ ਨਾਲ ਅੱਪ ਟੂ ਡੇਟ ਰਹੋ",
	"onboarding_paid_title" to "ਨਾਲ ਹੋਰ ਵੀ ਤੇਜ਼ੀ ਨਾਲ ਵਧੋ...",
	"onboarding_paywall_message" to "ਹੁਣ ਸਭ ਤੋਂ ਵਧੀਆ ਕੀਮਤ &#39;ਤੇ ਸਾਰੀਆਂ ਪ੍ਰੀਮੀਅਮ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਦਾ ਅਨੰਦ ਲਓ।",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} ਲਈ ਸਿਰਫ਼ ${currencySymbol}${introAmount} ਅਤੇ ਫਿਰ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "ਸਿਰਫ਼ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} ਲਈ ਮੁਫ਼ਤ ਅਤੇ ਫਿਰ ਸਿਰਫ਼ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "ਲਈ ਮੁਫ਼ਤ${trialDuration}${trialPeriod} ਫਿਰ${currencySymbol}${introductoryAmount} ਲਈ${introductoryDuration}${introPeriod} ਅਤੇ ਫਿਰ ਬਸ${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "ਸਵੈ-ਨਵਿਆਉਣਯੋਗ। ਕਿਸੇ ਵੀ ਸਮੇਂ ਰੱਦ ਕਰੋ।",
	"onboarding_paywall_restore" to "ਖਰੀਦ ਨੂੰ ਮੁੜ-ਬਹਾਲ ਕਰੋ",
	"onboarding_paywall_restore_failure" to "ਖਰੀਦਦਾਰੀ ਨੂੰ ਰੀਸਟੋਰ ਕਰਨਾ ਅਸਫਲ ਰਿਹਾ, ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜਾਂ ਸਹਾਇਤਾ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।",
	"onboarding_paywall_restore_success" to "ਰੀਸਟੋਰ ਸਫਲ!",
	"onboarding_paywall_title" to "ਮਹਾਨਤਾ ਪ੍ਰਾਪਤ ਕਰੋ!",
	"onboarding_welcome_message" to "ਤੁਹਾਡੇ ਇਨਵੌਇਸ ਅਤੇ ਅਨੁਮਾਨਾਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰਨ ਲਈ ਇੱਕ ਪੂਰਾ ਟੂਲਸੈੱਟ।",
	"onboarding_welcome_title" to "DingVoice ਵਿੱਚ ਸੁਆਗਤ ਹੈ",
	"other_state" to "ਹੋਰ",
	"overdue_state" to "ਓਵਰਡਿਊ",
	"paid_state" to "ਭੁਗਤਾਨ- ਯੋਗ",
	"password" to "ਪਾਸਵਰਡ",
	"password_blank_error" to "ਪਾਸਵਰਡ ਲੋੜੀਂਦਾ ਹੈ।",
	"password_mismatch_error" to "ਪਾਸਵਰਡ ਮੇਲ ਨਹੀਂ ਖਾਂਦੇ।",
	"pay" to "pay",
	"payment_instructions_label" to "ਭੁਗਤਾਨ ਬਾਰੇ ਹਿਦਾਇਤਾਂ",
	"payment_reminders" to "ਭੁਗਤਾਨ ਯਾਦ-ਦਹਾਨੀਆਂ",
	"phone_number_fields" to "ਫੋਨ ਨੰਬਰ",
	"phone_number_hint" to "ਫੋਨ ਨੰਬਰ",
	"postcode_hint" to "ਪੋਸਟਕੋਡ",
	"preview" to "ਝਲਕ",
	"preview_menu_hint" to "ਝਲਕ ਮੇਨੂ",
	"price" to "ਕੀਮਤ",
	"privacy_policy" to "ਪਰਦੇਦਾਰੀ ਨੀਤੀ",
	"profile" to "ਪਰੋਫਾਇਲ",
	"profitable_client" to "ਤੁਹਾਡਾ ਸਭ ਤੋਂ ਵੱਧ ਲਾਭਦਾਇਕ ਗਾਹਕ ਹੈ...",
	"profits_report" to "ਲਾਭ",
	"promo_content" to "ਪ੍ਰਚਾਰ ਸਮੱਗਰੀ",
	"quantity_hint" to "ਮਾਤਰਾ",
	"quantity_label_hint" to "ਮਾਤਰਾ ਲੇਬਲ",
	"query_info" to "ਪੁੱਛਗਿੱਛ ਤੁਹਾਨੂੰ ਦੋਵਾਂ ਚੀਜ਼ਾਂ ਅਤੇ ਹੋਰ ਟੈਕਸ ਨਿਯਮਾਂ ਨੂੰ ਇਨਪੁਟ ਵਜੋਂ ਦਰਸਾਉਂਦੇ ਹੋਏ ਕਸਟਮ ਗਣਨਾ ਲਿਖਣ ਦੀ ਆਗਿਆ ਦਿੰਦੀ ਹੈ। ਕਿਸੇ ਹੋਰ ਐਂਟਰੀ +, -, *, *, /, % - ਗਣਿਤ ਦੇ ਆਪਰੇਟਰਾਂ ਨਾਲ ਲਿੰਕ ਕਰਨ ਲਈ @ ਚਿੰਨ੍ਹ ਅਤੇ ਆਈਟਮ ਜਾਂ ਟੈਕਸ ਵਰਣਨ ਸ਼ਾਮਲ ਕਰੋ",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ਤੁਹਾਡੇ ਕਲਾਇੰਟ ਦੁਆਰਾ ਪ੍ਰਾਪਤ ਕੀਤਾ ਗਿਆ ਸੀ।" },
	"received_state" to "ਪ੍ਰਾਪਤ",
	"registration_code_fields" to "ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ",
	"reminder" to "ਰੀਮਾਈਂਡਰ",
	"reports" to "ਰਿਪੋਰਟਾਂ",
	"reports_not_available" to "ਕੋਈ ਰਿਪੋਰਟ ਉਪਲਬਧ ਨਹੀਂ ਹੈ, ਬਾਅਦ ਵਿੱਚ ਵਾਪਸ ਆਓ।",
	"reveal_password" to "ਪਾਸਵਰਡ ਖੋਲ੍ਹੋ",
	"revenue" to "revenue",
	"sales_tax" to "ਵੈਟ",
	"sales_tax_number" to "ਵੈਟ ਨੰਬਰ",
	"save" to "ਸੰਭਾਲੋ",
	"scan_document" to "ਦਸਤਾਵੇਜ਼ ਸਕੈਨ ਕਰੋ",
	"search" to "ਖੋਜ",
	"select" to "ਚੁਣੋ",
	"select_icon" to "ਆਈਕਨ ਚੁਣੋ",
	"select_signature" to "ਦਸਤਖਤ ਚੁਣੋ",
	"select_template" to "ਟੈਂਪਲੇਟ ਚੁਣੋ",
	"send" to "ਭੇਜੋ",
	"sent_state" to "ਭੇਜੇ",
	"setup_sender" to "ਭੇਜਣ ਵਾਲੇ ਨੂੰ ਸੈੱਟਅੱਪ ਕਰੋ",
	"share_option" to "ਸਾਂਝਾ ਕਰੋ",
	"ship_to" to "ਭੇਜ ਦਿਓ",
	"sign_in" to "ਸਾਈਨ ਇਨ ਕਰੋ",
	"sign_in_apple" to "ਐਪਲ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰੋ",
	"sign_in_email" to "ਈਮੇਲ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰੋ",
	"sign_in_facebook" to "ਫੇਸਬੁੱਕ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰੋ",
	"sign_in_google" to "Google ਨਾਲ ਸਾਈਨ ਇਨ ਕਰੋ",
	"sign_up" to "ਸਾਈਨ ਅੱਪ ਕਰੋ",
	"sign_up_label" to "ਤੁਹਾਡਾ ਕੋਈ ਖਾਤਾ ਨਹੀਂ ਹੈ?",
	"sign_up_link_label" to "ਸਾਈਨ ਅੱਪ ਕਰੋ!",
	"signatures_option" to "ਦਸਤਖਤ",
	"skip" to "ਛੱਡੋ",
	"sold_items_report" to "ਵਿਕੀਆਂ ਵਸਤੂਆਂ",
	"sort_by" to "ਲੜੀਬੱਧ:",
	"status_update" to "ਦਸਤਾਵੇਜ਼ ਸਥਿਤੀ ਅੱਪਡੇਟ",
	"sub_annual" to "ਸਾਲਾਨਾ",
	"sub_annual_price" to Formattable { (price) -> "${price}/ਸਾਲ" },
	"sub_backups" to "ਕਲਾਊਡ ਬੈਕਅੱਪ",
	"sub_insights" to "ਇਨਸਾਈਟਸ",
	"sub_month" to "ਮਹੀਨਾਵਾਰ",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ਮਹੀਨਾ" },
	"sub_notifications" to "ਸੂਚਨਾਵਾਂ",
	"sub_popup_message" to "ਗਾਹਕੀਆਂ ਜਲਦੀ ਆ ਰਹੀਆਂ ਹਨ। ਥੋੜ੍ਹੀ ਦੇਰ ਬਾਅਦ ਇੱਥੇ ਵਾਪਸ ਜਾਂਚ ਕਰੋ।",
	"sub_support" to "ਸਮਰਥਨ",
	"sub_templates" to "ਟੈਮਪਲੇਟ",
	"subscribe" to "ਮੈਂਬਰ ਬਣੋ",
	"subscription_header" to "ਇਸ ਨਾਲ ਆਪਣਾ ਕਾਰੋਬਾਰ ਵਧਾਓ:",
	"subscription_option" to "ਸਬਸਕ੍ਰਿਪਸ਼ਨ",
	"subscriptions_load_fail" to "ਮਾਫ਼ ਕਰਨਾ, ਅਸੀਂ ਇਸ ਸਮੇਂ ਤੁਹਾਡੀਆਂ ਗਾਹਕੀਆਂ ਨੂੰ ਮੁੜ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ ਹਾਂ। ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜਾਂ ਸਹਾਇਤਾ ਲਈ ਸਾਡੀ ਸਹਾਇਤਾ ਟੀਮ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।",
	"subtotal" to Formattable { (sum) -> "ਸਬ-ਟੋਟਲ: ${sum}" },
	"subtotal_label" to "ਉਪ-ਯੋਗ",
	"sum" to "Sum",
	"support_option" to "ਸਹਿਯੋਗ",
	"switch_reports" to "ਰਿਪੋਰਟ ਬਦਲੋ",
	"tax" to "ਟੈਕਸ",
	"tax_deleted_message" to "ਟੈਕਸ ਹਟਾਇਆ",
	"tax_description_warning" to "ਜੇ ਵਰਤਿਆ ਜਾਂਦਾ ਹੈ ਤਾਂ ਇਹ ਇੱਕ ਚਲਾਨ 'ਤੇ ਦਿਖਾਈ ਦਿੰਦਾ ਹੈ",
	"tax_input_flat" to "ਫਲੈਟ",
	"tax_input_percent" to "ਪ੍ਰਤੀਸ਼ਤ",
	"tax_number_fields" to "ਟੈਕਸ ਨੰਬਰ",
	"tax_number_hint" to "ਟੈਕਸ ਨੰਬਰ",
	"tax_query_info_description" to "ਕਿਊਰੀ ਜਾਣਕਾਰੀ",
	"tax_report" to "ਟੈਕਸ",
	"tax_rules_option" to "ਟੈਕਸ ਨਿਯਮ",
	"taxed_clients_report" to "ਟੈਕਸ ਲਗਾਇਆ ਗਿਆ ਗਾਹਕ",
	"template" to "ਟੈਂਪਲੇਟ",
	"template_selector_info" to "ਡਿਫੌਲਟ ਟੈਂਪਲੇਟ ਨੂੰ ਬਦਲਣ ਲਈ ਸਵਾਈਪ ਕਰੋ",
	"template_unlock_promo" to "ਪ੍ਰੀਮੀਅਮ ਨਾਲ ਹੋਰ ਟੈਂਪਲੇਟਸ ਤੱਕ ਪਹੁੰਚ ਕਰੋ",
	"templates_option" to "ਟੈਂਪਲੇਟ",
	"terms_of_service" to "ਸੇਵਾ ਦੀਆਂ ਮਦਾਂ",
	"thank_you" to "ਤੁਹਾਡਾ ਧੰਨਵਾਦ",
	"to" to "ਇਸ ਲਈ",
	"total" to Formattable { (sum) -> "ਕੁੱਲ: ${sum}" },
	"total_label" to "ਕੁੱਲ",
	"undo_action" to "ਵਾਪਿਸ",
	"unit" to "ਇਕਾਈ",
	"unit_cost_hint" to "ਯੂਨਿਟ ਲਾਗਤ",
	"unknown_client" to "ਅਗਿਆਤ ਕਲਾਇੰਟ",
	"unlock" to "ਅਨਲੌਕ ਕਰੋ",
	"unnamed_item" to "ਬੇਨਾਮ ਆਈਟਮ",
	"upgrade" to "ਅੱਪਗਰੇਡ",
	"user_collision_error" to "ਖਾਤਾ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ।",
	"valid_until" to "ਤੱਕ ਵੈਧ",
	"warning" to "ਚੇਤਾਵਨੀ!",
	"yes" to "yes",
    )
    
}