// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ms  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Terima ${termsOfService} dan ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Kod tidak sah."
override val add_client_action: String = "Tambah klien"
override val add_customer: String = "Tambah pelanggan"
override val add_expense: String = "Tambah perbelanjaan"
override val add_fields_label: String = "Menambah:"
override val add_invoice_action: String = "Mencipta"
override val add_item_action: String = "Tambah item"
override val add_item_button: String = "Tambah item"
override val add_signature_action: String = "Tambah tandatangan"
override val add_tax_action: String = "Tambah cukai"
override val address_fields: String = "Alamat"
override val address_line_hint: String = "Talian Alamat"
override val advanced: String = "Lanjutan"
override val agree_marketing: String = "Saya bersetuju untuk menerima Komunikasi Pemasaran Langsung"
override val all_notifications: String = "Semua Pemberitahuan"
override val amortization: String = "amortization"
override val amount: String = "Jumlah"
override val asset: String = "asset"
override val attach_file: String = "Melampirkan fail"
override val attachment: String = "Lampiran"
override val auth_error: String = "Tindakan gagal, cuba lagi."
override val available_for_paid: String = "Penyegerakan awan hanya tersedia di bawah langganan berbayar."
override val backups_option: String = "Sandaran"
override val balance: String = "balance"
override val bank_account: String = "Akaun Bank"
override val bill_number: String = "Nombor Bil"
override val cancel: String = "Membatalkan"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Kategori"
override val category: String = "kategori"
override val category_add: String = "Tambah Kategori"
override val category_delete: String = "Padamkan kategori"
override val category_name: String = "Nama kategori"
override val change_language: Formattable = Formattable { (language) -> "Bahasa Invois: ${language}" }
override val change_language_label: String = "Bahasa Invois"
override val change_template: String = "Tukar templat"
override val city_hint: String = "Bandar"
override val clear: String = "Jelas"
override val client: String = "Pelanggan"
override val client_actions: String = "Tindakan pelanggan"
override val client_deleted_message: String = "Klien dipadam"
override val clients_error_required_fields: String = "Sekurang-kurangnya satu nilai diperlukan."
override val clients_option: String = "Pelanggan"
override val close: String = "Tutup"
override val cloud_sync: String = "Penyegerakan Awan"
override val coming_soon: String = "Akan datang!"
override val company_logo: String = "Logo Syarikat"
override val company_name_hint: String = "Syarikat"
override val confirm_password_label: String = "Sahkan kata laluan"
override val confirmation_title: String = "Adakah anda pasti?"
override val convert_to_invoice: String = "Tukar kepada invois"
override val country_hint: String = "Negara"
override val create_client: String = "Cipta Pelanggan"
override val create_company: String = "Buat Syarikat"
override val create_document: String = "Cipta dokumen"
override val create_estimate: String = "Anggaran"
override val create_invoice: String = "Invois"
override val create_item: String = "Cipta Item"
override val create_new_client: String = "Cipta klien baru"
override val create_new_item: String = "Cipta item baru"
override val create_new_tax: String = "Buat cukai baru"
override val create_signature: String = "Tandatangan"
override val created: String = "Dicipta"
override val credit: String = "credit"
override val customer: String = "Pelanggan"
override val date: String = "Tarikh"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Mata wang lalai: ${currency}" }
override val default_currency_label: String = "Mata wang lalai"
override val delete: String = "Menghapuskan"
override val delete_account: String = "Padam Akaun"
override val delete_confirmation_message: String = "Semua data anda akan hilang."
override val delete_item_title: String = "Padam item?"
override val delete_signature: String = "Hapuskan tandatangan"
override val depreciation: String = "depreciation"
override val description: String = "Keterangan"
override val description_hint: String = "Keterangan"
override val detailed_report: String = "Laporan Terperinci"
override val disabled: String = "Dilumpuhkan"
override val discount: String = "Diskaun"
override val discount_hint: String = "Diskaun"
override val display_af: String = "Afrika"
override val display_am: String = "Amharic"
override val display_ar: String = "bahasa arab"
override val display_as: String = "Assam"
override val display_az: String = "Azerbaijan"
override val display_be: String = "Belarusia"
override val display_bg: String = "bahasa Bulgaria"
override val display_bn: String = "Benggali"
override val display_bs: String = "Bosnia"
override val display_ca: String = "Catalan"
override val display_cs: String = "bahasa Czech"
override val display_da: String = "Danish"
override val display_de: String = "Jerman"
override val display_el: String = "bahasa Yunani"
override val display_en: String = "Inggeris"
override val display_es: String = "bahasa Sepanyol"
override val display_es_MX: String = "Sepanyol (Mexico)"
override val display_et: String = "bahasa Estonia"
override val display_eu: String = "Basque"
override val display_fa: String = "Parsi"
override val display_fi: String = "bahasa Finland"
override val display_fil: String = "Filipina"
override val display_fr: String = "Perancis"
override val display_fr_CA: String = "Perancis Kanada"
override val display_gl: String = "Galicia"
override val display_gu: String = "Gujerat"
override val display_he: String = "bahasa Ibrani"
override val display_hi: String = "Tidak."
override val display_hr: String = "bahasa Croatia"
override val display_hu: String = "bahasa Hungary"
override val display_hy: String = "bahasa Armenia"
override val display_id: String = "Indonesia"
override val display_is: String = "bahasa Iceland"
override val display_it: String = "Itali"
override val display_ja: String = "Jepun"
override val display_ka: String = "Georgian"
override val display_kk: String = "bahasa Kazakhstan"
override val display_km: String = "Khmer Tengah"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korea"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "buruh"
override val display_lt: String = "bahasa Lithuania"
override val display_lv: String = "bahasa Latvia"
override val display_mk: String = "Macedonia"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolia"
override val display_mr: String = "Marathi"
override val display_ms: String = "Melayu"
override val display_my: String = "Burma"
override val display_nb: String = "Bokmål Norway"
override val display_ne: String = "Nepal"
override val display_nl: String = "Belanda"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Poland"
override val display_pt: String = "Portugis"
override val display_pt_BR: String = "Portugis (Brazil)"
override val display_pt_PT: String = "Portugis Eropah"
override val display_ro: String = "bahasa Romania"
override val display_ru: String = "bahasa Rusia"
override val display_si: String = "Sinhala"
override val display_sk: String = "bahasa Slovak"
override val display_sl: String = "Bahasa Slovenia"
override val display_sq: String = "bahasa Albania"
override val display_sr: String = "bahasa Serbia"
override val display_sv: String = "Bahasa Sweden"
override val display_sw: String = "bahasa Swahili"
override val display_ta: String = "bahasa Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "bahasa Turki"
override val display_uk: String = "Ukraine"
override val display_ur: String = "bahasa Urdu"
override val display_uz: String = "Uzbek"
override val display_vi: String = "bahasa Vietnam"
override val display_zh_Hant_TW: String = "Cina (Taiwan, Tradisional)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Anggaran"
override val document_invoice: String = "Invois"
override val document_quote: String = "petikan"
override val done: String = "Selesai"
override val download_app: String = "Muat turun aplikasi"
override val download_invoice: String = "Muat turun Invois"
override val download_pdf: String = "Muat turun PDF"
override val draw_signature: String = "Lukis tandatangan"
override val due: Formattable = Formattable { (date) -> "Perlu Dibayar ${date}" }
override val due_date: String = "Tarikh Matang"
override val due_day: String = "dalam sehari"
override val due_label: String = "Perlu Dibayar"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} perlu dibayar ${dueWhen}!" }
override val due_today: String = "Hari ini"
override val due_week: String = "dalam seminggu"
override val duplicate: String = "Pendua"
override val duration_day: String = "hari"
override val duration_month: String = "bulan"
override val duration_week: String = "minggu"
override val duration_year: String = "tahun"
override val email: String = "E-mel"
override val email_blank_error: String = "E-mel diperlukan."
override val email_error: String = "Alamat e-mel tidak sah."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Terima kasih atas urusan awak. Untuk melihat ${documentType} penuh klik butang di hujung surat ini. Ambil perhatian bahawa ia sah sehingga ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Anda telah menerima yang baru${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "LIHAT ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "${clientName} yang dihormati" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Terima kasih atas urusan awak. Untuk melihat ${documentType} penuh klik butang di hujung surat ini. Sila bayar baki sehingga ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Anda telah menerima yang baru${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "E-mel untuk menetapkan semula kata laluan yang dihantar, sila semak peti masuk anda."
override val empty_clients_list_message: String = "Anda tidak mempunyai pelanggan untuk mengesan. Tambah klien baru."
override val empty_estimate_list_message: String = "Buat anggaran pertama anda"
override val empty_expenses: String = "Anda tidak mempunyai perbelanjaan untuk dijejaki. Tambah perbelanjaan baharu."
override val empty_invoice_list_message: String = "Buat invois pertama anda"
override val empty_items_list_message: String = "Anda tidak mempunyai item, tambah item baru!"
override val empty_signatures_list_message: String = "Tiada tandatangan ditemui. Buat satu!"
override val empty_taxes_list_message: String = "Anda tidak mempunyai peraturan cukai. Tambah peraturan cukai baru."
override val empty_templates_list_message: String = "Templat tidak tersedia."
override val enabled: String = "Didayakan"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Anggaran #"
override val estimates: String = "Anggaran"
override val estimates_report: String = "Anggaran"
override val expense_bank_fees: String = "Yuran bank"
override val expense_benefits: String = "Faedah"
override val expense_communication: String = "Komunikasi"
override val expense_debt: String = "hutang"
override val expense_deleted: String = "Perbelanjaan dipadamkan"
override val expense_depreciation: String = "Susut nilai"
override val expense_gifts: String = "Hadiah"
override val expense_insurance: String = "Insurans"
override val expense_inventory: String = "Inventori"
override val expense_legal: String = "sah"
override val expense_maintenance: String = "Penyelenggaraan"
override val expense_marketing: String = "Pemasaran"
override val expense_meals: String = "Makanan"
override val expense_office_supplies: String = "Kelengkapan Pejabat"
override val expense_rent: String = "Sewa"
override val expense_salaries: String = "Gaji"
override val expense_shopping: String = "Membeli-belah"
override val expense_software: String = "Perisian"
override val expense_status_available: String = "Tersedia"
override val expense_status_processing: String = "Memproses"
override val expense_tax: String = "Cukai"
override val expense_training: String = "Latihan"
override val expense_transport: String = "Pengangkutan"
override val expense_travel: String = "Perjalanan"
override val expense_utility: String = "Utiliti"
override val expenses: String = "Perbelanjaan"
override val feedback_option: String = "Maklum balas"
override val filter: String = "Penapis"
override val first_name_hint: String = "Nama Pertama"
override val forgot_password: String = "Tetapkan semula kata laluan"
override val forgot_password_label: String = "Terlupa Kata Laluan"
override val from: String = "Dari"
override val general_error: String = "Ralat berlaku, sila cuba lagi kemudian."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Pergi balik"
override val group_by: String = "Kumpulan Mengikut:"
override val image_view: String = "Imej"
override val install: String = "Pasang"
override val invalid_credentials_error: String = "Kelayakan tidak sah."
override val invalid_email_error: String = "E-mel tidak sah."
override val invalid_user_error: String = "Kelayakan pengguna tidak sah."
override val invoice_deleted_message: String = "Invois dipadam"
override val invoice_menu: String = "Menu invois"
override val invoice_number: String = "Invois #"
override val invoices: String = "Invois"
override val items_option: String = "Item"
override val keep: String = "Menyimpan"
override val language_selector: Formattable = Formattable { (selected) -> "Bahasa ${selected}" }
override val last_name_hint: String = "Nama Keluarga"
override val ledger: String = "ledger"
override val legal: String = "Undang"
override val legal_error: String = "Mesti menerima Terma Perkhidmatan dan Dasar Privasi"
override val liabilities: String = "liabilities"
override val loading: String = "Memuatkan"
override val login_required_error: String = "Log masuk diperlukan."
override val logo_view: String = "Logo"
override val logout: String = "Log keluar"
override val logout_prompt: String = "Data anda tidak disegerakkan dengan awan. Padam invois, anggaran, pelanggan & maklumat lain mengenai log keluar?"
override val main_screen: String = "Skrin utama"
override val mark_paid: String = "Tandakan sebagai Dibayar"
override val message: String = "Mesej"
override val missing_sender_msg: String = "Dokumen tanpa maklumat penghantar mungkin tidak sah."
override val missing_sender_title: String = "Tiada maklumat penghantar"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "Ogos"
override val month_august_short: String = "Ogos"
override val month_december: String = "Disember"
override val month_december_short: String = "Dis."
override val month_february: String = "Februari"
override val month_february_short: String = "Feb."
override val month_january: String = "Januari"
override val month_january_short: String = "Jan."
override val month_july: String = "Julai"
override val month_july_short: String = "Julai"
override val month_june: String = "Jun"
override val month_june_short: String = "Jun"
override val month_march: String = "Mac"
override val month_march_short: String = "Mac"
override val month_may: String = "Mungkin"
override val month_may_short: String = "Mungkin"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktober"
override val month_october_short: String = "Okt."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Gagal mengesahkan."
override val new: String = "Baru"
override val news: String = "Berita"
override val next: String = "Seterusnya"
override val no: String = "no"
override val no_data_available: String = "Tiada data tersedia"
override val no_search_results: String = "Maaf, kami tidak dapat mencari sebarang keputusan."
override val none: String = "tiada"
override val note_fields: String = "Nota"
override val note_hint: String = "Ambil perhatian"
override val notifications_option: String = "Pemberitahuan"
override val ok: String = "Ok"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Bahagian penting DingVoice adalah percuma selamanya. Misi kami adalah untuk membantu anda mengembangkan perniagaan anda, membantu kami menambah baik dengan berkongsi maklum balas anda."
override val onboarding_aboutus_title: String = "Kami di sini untuk membantu"
override val onboarding_paid_1: String = "Akses templat yang kelihatan profesional"
override val onboarding_paid_2: String = "Lihatlah kewangan anda dengan lebih mendalam"
override val onboarding_paid_3: String = "Dapatkan masalah diselesaikan dengan cepat"
override val onboarding_paid_4: String = "Simpan dokumen anda dengan selamat"
override val onboarding_paid_5: String = "Ikuti perkembangan terkini dengan perubahan status invois"
override val onboarding_paid_title: String = "Tumbuh lebih cepat dengan..."
override val onboarding_paywall_message: String = "Nikmati semua ciri premium sekarang pada harga terbaik."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Hanya ${currencySymbol}${introAmount} untuk ${introDuration} ${introPeriod} dan kemudian ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Hanya ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Percuma untuk ${trialDuration} ${trialPeriod} dan kemudian hanya ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Percuma untuk${trialDuration}${trialPeriod} kemudian${currencySymbol}${introductoryAmount} untuk${introductoryDuration}${introPeriod} dan kemudian hanya${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "Boleh diperbaharui secara automatik. Batalkan bila-bila masa."
override val onboarding_paywall_restore: String = "Pulihkan pembelian"
override val onboarding_paywall_restore_failure: String = "Pemulihan pembelian telah gagal, cuba lagi kemudian atau hubungi sokongan."
override val onboarding_paywall_restore_success: String = "Pemulihan berjaya!"
override val onboarding_paywall_title: String = "Capai Kehebatan!"
override val onboarding_welcome_message: String = "Set alat lengkap untuk mengurus invois &amp; anggaran anda."
override val onboarding_welcome_title: String = "Selamat datang ke DingVoice"
override val other_state: String = "Lain-lain"
override val overdue_state: String = "Tertunggak"
override val paid_state: String = "Dibayar"
override val password: String = "Kata laluan"
override val password_blank_error: String = "Kata laluan diperlukan."
override val password_mismatch_error: String = "Kata laluan tidak sepadan."
override val pay: String = "pay"
override val payment_instructions_label: String = "Arahan Pembayaran"
override val payment_reminders: String = "Peringatan pembayaran"
override val phone_number_fields: String = "Nombor Telefon"
override val phone_number_hint: String = "Nombor Telefon"
override val postcode_hint: String = "Poskod"
override val preview: String = "Pratonton"
override val preview_menu_hint: String = "Menu pratonton"
override val price: String = "Harga"
override val privacy_policy: String = "Dasar Privasi"
override val profile: String = "Profil"
override val profitable_client: String = "Pelanggan anda yang paling menguntungkan ialah..."
override val profits_report: String = "Keuntungan"
override val promo_content: String = "Kandungan promosi"
override val quantity_hint: String = "Kuantiti"
override val quantity_label_hint: String = "Label Kuantiti"
override val query_info: String = "Pertanyaan membolehkan anda menulis pengiraan tersuai yang merujuk kepada kedua-dua item dan peraturan cukai lain sebagai input. Tambah @ simbol dan item atau perihalan cukai untuk memaut ke entri lain +, -, *, /, % - operator matematik"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} telah diterima oleh pelanggan anda." }
override val received_state: String = "Menerima"
override val registration_code_fields: String = "Kod Pendaftaran"
override val reminder: String = "Peringatan"
override val reports: String = "Laporan"
override val reports_not_available: String = "Tiada laporan tersedia, kembali kemudian."
override val reveal_password: String = "Dedahkan kata laluan"
override val revenue: String = "revenue"
override val sales_tax: String = "VAT"
override val sales_tax_number: String = "Nombor VAT"
override val save: String = "Simpan"
override val scan_document: String = "Imbas dokumen"
override val search: String = "Carian"
override val select: String = "Pilih"
override val select_icon: String = "Pilih ikon"
override val select_signature: String = "Pilih tandatangan"
override val select_template: String = "Pilih templat"
override val send: String = "Menghantar"
override val sent_state: String = "Dihantar"
override val setup_sender: String = "Sediakan penghantar"
override val share_option: String = "Berkongsi"
override val ship_to: String = "Hantar ke"
override val sign_in: String = "Daftar masuk"
override val sign_in_apple: String = "Daftar masuk dengan Apple"
override val sign_in_email: String = "Daftar masuk dengan e-mel"
override val sign_in_facebook: String = "Daftar masuk dengan Facebook"
override val sign_in_google: String = "Log masuk dengan Google"
override val sign_up: String = "Mendaftar"
override val sign_up_label: String = "Tidak mempunyai akaun?"
override val sign_up_link_label: String = "Mendaftar!"
override val signatures_option: String = "Tandatangan"
override val skip: String = "Langkau"
override val sold_items_report: String = "Barang Terjual"
override val sort_by: String = "Isih Mengikut:"
override val status_update: String = "Kemas kini status dokumen"
override val sub_annual: String = "Tahunan"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/tahun" }
override val sub_backups: String = "Sandaran Awan"
override val sub_insights: String = "Wawasan"
override val sub_month: String = "Bulanan"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/bulan" }
override val sub_notifications: String = "Pemberitahuan"
override val sub_popup_message: String = "Langganan akan datang tidak lama lagi. Semak kembali di sini dalam masa yang sedikit."
override val sub_support: String = "Sokongan"
override val sub_templates: String = "Templat"
override val subscribe: String = "Melanggan"
override val subscription_header: String = "Kembangkan perniagaan anda dengan:"
override val subscription_option: String = "Langganan"
override val subscriptions_load_fail: String = "Maaf, kami tidak dapat mendapatkan semula langganan anda pada masa ini. Sila cuba sebentar lagi atau hubungi pasukan sokongan kami untuk mendapatkan bantuan."
override val subtotal: Formattable = Formattable { (sum) -> "Subjumlah: ${sum}" }
override val subtotal_label: String = "jumlah kecil"
override val sum: String = "Jumlah"
override val support_option: String = "Sokongan"
override val switch_reports: String = "Tukar Laporan"
override val tax: String = "Cukai"
override val tax_deleted_message: String = "Cukai dihapuskan"
override val tax_description_warning: String = "Jika digunakan ini muncul pada invois"
override val tax_input_flat: String = "Rata"
override val tax_input_percent: String = "Peratus"
override val tax_number_fields: String = "Nombor Cukai"
override val tax_number_hint: String = "Nombor Cukai"
override val tax_query_info_description: String = "Maklumat Pertanyaan"
override val tax_report: String = "Cukai"
override val tax_rules_option: String = "Peraturan Cukai"
override val taxed_clients_report: String = "Pelanggan Bercukai"
override val template: String = "Template"
override val template_selector_info: String = "Leret untuk menukar templat lalai"
override val template_unlock_promo: String = "Akses lebih banyak templat dengan Premium"
override val templates_option: String = "Template"
override val terms_of_service: String = "Syarat Perkhidmatan"
override val thank_you: String = "Terima kasih"
override val to: String = "Untuk"
override val total: Formattable = Formattable { (sum) -> "Jumlah: ${sum}" }
override val total_label: String = "Jumlah"
override val undo_action: String = "Membatalkan"
override val unit: String = "Unit"
override val unit_cost_hint: String = "Kos Unit"
override val unknown_client: String = "Pelanggan Tidak Dikenali"
override val unlock: String = "Buka kunci"
override val unnamed_item: String = "Item tidak dinamakan"
override val upgrade: String = "Naik taraf"
override val user_collision_error: String = "Akaun sudah wujud."
override val valid_until: String = "Sah sehingga"
override val warning: String = "Amaran!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Terima ${termsOfService} dan ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Kod tidak sah.",
	"add_client_action" to "Tambah klien",
	"add_customer" to "Tambah pelanggan",
	"add_expense" to "Tambah perbelanjaan",
	"add_fields_label" to "Menambah:",
	"add_invoice_action" to "Mencipta",
	"add_item_action" to "Tambah item",
	"add_item_button" to "Tambah item",
	"add_signature_action" to "Tambah tandatangan",
	"add_tax_action" to "Tambah cukai",
	"address_fields" to "Alamat",
	"address_line_hint" to "Talian Alamat",
	"advanced" to "Lanjutan",
	"agree_marketing" to "Saya bersetuju untuk menerima Komunikasi Pemasaran Langsung",
	"all_notifications" to "Semua Pemberitahuan",
	"amortization" to "amortization",
	"amount" to "Jumlah",
	"asset" to "asset",
	"attach_file" to "Melampirkan fail",
	"attachment" to "Lampiran",
	"auth_error" to "Tindakan gagal, cuba lagi.",
	"available_for_paid" to "Penyegerakan awan hanya tersedia di bawah langganan berbayar.",
	"backups_option" to "Sandaran",
	"balance" to "balance",
	"bank_account" to "Akaun Bank",
	"bill_number" to "Nombor Bil",
	"cancel" to "Membatalkan",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Kategori",
	"category" to "kategori",
	"category_add" to "Tambah Kategori",
	"category_delete" to "Padamkan kategori",
	"category_name" to "Nama kategori",
	"change_language" to Formattable { (language) -> "Bahasa Invois: ${language}" },
	"change_language_label" to "Bahasa Invois",
	"change_template" to "Tukar templat",
	"city_hint" to "Bandar",
	"clear" to "Jelas",
	"client" to "Pelanggan",
	"client_actions" to "Tindakan pelanggan",
	"client_deleted_message" to "Klien dipadam",
	"clients_error_required_fields" to "Sekurang-kurangnya satu nilai diperlukan.",
	"clients_option" to "Pelanggan",
	"close" to "Tutup",
	"cloud_sync" to "Penyegerakan Awan",
	"coming_soon" to "Akan datang!",
	"company_logo" to "Logo Syarikat",
	"company_name_hint" to "Syarikat",
	"confirm_password_label" to "Sahkan kata laluan",
	"confirmation_title" to "Adakah anda pasti?",
	"convert_to_invoice" to "Tukar kepada invois",
	"country_hint" to "Negara",
	"create_client" to "Cipta Pelanggan",
	"create_company" to "Buat Syarikat",
	"create_document" to "Cipta dokumen",
	"create_estimate" to "Anggaran",
	"create_invoice" to "Invois",
	"create_item" to "Cipta Item",
	"create_new_client" to "Cipta klien baru",
	"create_new_item" to "Cipta item baru",
	"create_new_tax" to "Buat cukai baru",
	"create_signature" to "Tandatangan",
	"created" to "Dicipta",
	"credit" to "credit",
	"customer" to "Pelanggan",
	"date" to "Tarikh",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Mata wang lalai: ${currency}" },
	"default_currency_label" to "Mata wang lalai",
	"delete" to "Menghapuskan",
	"delete_account" to "Padam Akaun",
	"delete_confirmation_message" to "Semua data anda akan hilang.",
	"delete_item_title" to "Padam item?",
	"delete_signature" to "Hapuskan tandatangan",
	"depreciation" to "depreciation",
	"description" to "Keterangan",
	"description_hint" to "Keterangan",
	"detailed_report" to "Laporan Terperinci",
	"disabled" to "Dilumpuhkan",
	"discount" to "Diskaun",
	"discount_hint" to "Diskaun",
	"display_af" to "Afrika",
	"display_am" to "Amharic",
	"display_ar" to "bahasa arab",
	"display_as" to "Assam",
	"display_az" to "Azerbaijan",
	"display_be" to "Belarusia",
	"display_bg" to "bahasa Bulgaria",
	"display_bn" to "Benggali",
	"display_bs" to "Bosnia",
	"display_ca" to "Catalan",
	"display_cs" to "bahasa Czech",
	"display_da" to "Danish",
	"display_de" to "Jerman",
	"display_el" to "bahasa Yunani",
	"display_en" to "Inggeris",
	"display_es" to "bahasa Sepanyol",
	"display_es_MX" to "Sepanyol (Mexico)",
	"display_et" to "bahasa Estonia",
	"display_eu" to "Basque",
	"display_fa" to "Parsi",
	"display_fi" to "bahasa Finland",
	"display_fil" to "Filipina",
	"display_fr" to "Perancis",
	"display_fr_CA" to "Perancis Kanada",
	"display_gl" to "Galicia",
	"display_gu" to "Gujerat",
	"display_he" to "bahasa Ibrani",
	"display_hi" to "Tidak.",
	"display_hr" to "bahasa Croatia",
	"display_hu" to "bahasa Hungary",
	"display_hy" to "bahasa Armenia",
	"display_id" to "Indonesia",
	"display_is" to "bahasa Iceland",
	"display_it" to "Itali",
	"display_ja" to "Jepun",
	"display_ka" to "Georgian",
	"display_kk" to "bahasa Kazakhstan",
	"display_km" to "Khmer Tengah",
	"display_kn" to "Kannada",
	"display_ko" to "Korea",
	"display_ky" to "Kirghiz",
	"display_lo" to "buruh",
	"display_lt" to "bahasa Lithuania",
	"display_lv" to "bahasa Latvia",
	"display_mk" to "Macedonia",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolia",
	"display_mr" to "Marathi",
	"display_ms" to "Melayu",
	"display_my" to "Burma",
	"display_nb" to "Bokmål Norway",
	"display_ne" to "Nepal",
	"display_nl" to "Belanda",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Poland",
	"display_pt" to "Portugis",
	"display_pt_BR" to "Portugis (Brazil)",
	"display_pt_PT" to "Portugis Eropah",
	"display_ro" to "bahasa Romania",
	"display_ru" to "bahasa Rusia",
	"display_si" to "Sinhala",
	"display_sk" to "bahasa Slovak",
	"display_sl" to "Bahasa Slovenia",
	"display_sq" to "bahasa Albania",
	"display_sr" to "bahasa Serbia",
	"display_sv" to "Bahasa Sweden",
	"display_sw" to "bahasa Swahili",
	"display_ta" to "bahasa Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "bahasa Turki",
	"display_uk" to "Ukraine",
	"display_ur" to "bahasa Urdu",
	"display_uz" to "Uzbek",
	"display_vi" to "bahasa Vietnam",
	"display_zh_Hant_TW" to "Cina (Taiwan, Tradisional)",
	"display_zu" to "Zulu",
	"document_estimate" to "Anggaran",
	"document_invoice" to "Invois",
	"document_quote" to "petikan",
	"done" to "Selesai",
	"download_app" to "Muat turun aplikasi",
	"download_invoice" to "Muat turun Invois",
	"download_pdf" to "Muat turun PDF",
	"draw_signature" to "Lukis tandatangan",
	"due" to Formattable { (date) -> "Perlu Dibayar ${date}" },
	"due_date" to "Tarikh Matang",
	"due_day" to "dalam sehari",
	"due_label" to "Perlu Dibayar",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} perlu dibayar ${dueWhen}!" },
	"due_today" to "Hari ini",
	"due_week" to "dalam seminggu",
	"duplicate" to "Pendua",
	"duration_day" to "hari",
	"duration_month" to "bulan",
	"duration_week" to "minggu",
	"duration_year" to "tahun",
	"email" to "E-mel",
	"email_blank_error" to "E-mel diperlukan.",
	"email_error" to "Alamat e-mel tidak sah.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Terima kasih atas urusan awak. Untuk melihat ${documentType} penuh klik butang di hujung surat ini. Ambil perhatian bahawa ia sah sehingga ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Anda telah menerima yang baru${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "LIHAT ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "${clientName} yang dihormati" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Terima kasih atas urusan awak. Untuk melihat ${documentType} penuh klik butang di hujung surat ini. Sila bayar baki sehingga ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Anda telah menerima yang baru${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "E-mel untuk menetapkan semula kata laluan yang dihantar, sila semak peti masuk anda.",
	"empty_clients_list_message" to "Anda tidak mempunyai pelanggan untuk mengesan. Tambah klien baru.",
	"empty_estimate_list_message" to "Buat anggaran pertama anda",
	"empty_expenses" to "Anda tidak mempunyai perbelanjaan untuk dijejaki. Tambah perbelanjaan baharu.",
	"empty_invoice_list_message" to "Buat invois pertama anda",
	"empty_items_list_message" to "Anda tidak mempunyai item, tambah item baru!",
	"empty_signatures_list_message" to "Tiada tandatangan ditemui. Buat satu!",
	"empty_taxes_list_message" to "Anda tidak mempunyai peraturan cukai. Tambah peraturan cukai baru.",
	"empty_templates_list_message" to "Templat tidak tersedia.",
	"enabled" to "Didayakan",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Anggaran #",
	"estimates" to "Anggaran",
	"estimates_report" to "Anggaran",
	"expense_bank_fees" to "Yuran bank",
	"expense_benefits" to "Faedah",
	"expense_communication" to "Komunikasi",
	"expense_debt" to "hutang",
	"expense_deleted" to "Perbelanjaan dipadamkan",
	"expense_depreciation" to "Susut nilai",
	"expense_gifts" to "Hadiah",
	"expense_insurance" to "Insurans",
	"expense_inventory" to "Inventori",
	"expense_legal" to "sah",
	"expense_maintenance" to "Penyelenggaraan",
	"expense_marketing" to "Pemasaran",
	"expense_meals" to "Makanan",
	"expense_office_supplies" to "Kelengkapan Pejabat",
	"expense_rent" to "Sewa",
	"expense_salaries" to "Gaji",
	"expense_shopping" to "Membeli-belah",
	"expense_software" to "Perisian",
	"expense_status_available" to "Tersedia",
	"expense_status_processing" to "Memproses",
	"expense_tax" to "Cukai",
	"expense_training" to "Latihan",
	"expense_transport" to "Pengangkutan",
	"expense_travel" to "Perjalanan",
	"expense_utility" to "Utiliti",
	"expenses" to "Perbelanjaan",
	"feedback_option" to "Maklum balas",
	"filter" to "Penapis",
	"first_name_hint" to "Nama Pertama",
	"forgot_password" to "Tetapkan semula kata laluan",
	"forgot_password_label" to "Terlupa Kata Laluan",
	"from" to "Dari",
	"general_error" to "Ralat berlaku, sila cuba lagi kemudian.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Pergi balik",
	"group_by" to "Kumpulan Mengikut:",
	"image_view" to "Imej",
	"install" to "Pasang",
	"invalid_credentials_error" to "Kelayakan tidak sah.",
	"invalid_email_error" to "E-mel tidak sah.",
	"invalid_user_error" to "Kelayakan pengguna tidak sah.",
	"invoice_deleted_message" to "Invois dipadam",
	"invoice_menu" to "Menu invois",
	"invoice_number" to "Invois #",
	"invoices" to "Invois",
	"items_option" to "Item",
	"keep" to "Menyimpan",
	"language_selector" to Formattable { (selected) -> "Bahasa ${selected}" },
	"last_name_hint" to "Nama Keluarga",
	"ledger" to "ledger",
	"legal" to "Undang",
	"legal_error" to "Mesti menerima Terma Perkhidmatan dan Dasar Privasi",
	"liabilities" to "liabilities",
	"loading" to "Memuatkan",
	"login_required_error" to "Log masuk diperlukan.",
	"logo_view" to "Logo",
	"logout" to "Log keluar",
	"logout_prompt" to "Data anda tidak disegerakkan dengan awan. Padam invois, anggaran, pelanggan & maklumat lain mengenai log keluar?",
	"main_screen" to "Skrin utama",
	"mark_paid" to "Tandakan sebagai Dibayar",
	"message" to "Mesej",
	"missing_sender_msg" to "Dokumen tanpa maklumat penghantar mungkin tidak sah.",
	"missing_sender_title" to "Tiada maklumat penghantar",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "Ogos",
	"month_august_short" to "Ogos",
	"month_december" to "Disember",
	"month_december_short" to "Dis.",
	"month_february" to "Februari",
	"month_february_short" to "Feb.",
	"month_january" to "Januari",
	"month_january_short" to "Jan.",
	"month_july" to "Julai",
	"month_july_short" to "Julai",
	"month_june" to "Jun",
	"month_june_short" to "Jun",
	"month_march" to "Mac",
	"month_march_short" to "Mac",
	"month_may" to "Mungkin",
	"month_may_short" to "Mungkin",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Oktober",
	"month_october_short" to "Okt.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Gagal mengesahkan.",
	"new" to "Baru",
	"news" to "Berita",
	"next" to "Seterusnya",
	"no" to "no",
	"no_data_available" to "Tiada data tersedia",
	"no_search_results" to "Maaf, kami tidak dapat mencari sebarang keputusan.",
	"none" to "tiada",
	"note_fields" to "Nota",
	"note_hint" to "Ambil perhatian",
	"notifications_option" to "Pemberitahuan",
	"ok" to "Ok",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Bahagian penting DingVoice adalah percuma selamanya. Misi kami adalah untuk membantu anda mengembangkan perniagaan anda, membantu kami menambah baik dengan berkongsi maklum balas anda.",
	"onboarding_aboutus_title" to "Kami di sini untuk membantu",
	"onboarding_paid_1" to "Akses templat yang kelihatan profesional",
	"onboarding_paid_2" to "Lihatlah kewangan anda dengan lebih mendalam",
	"onboarding_paid_3" to "Dapatkan masalah diselesaikan dengan cepat",
	"onboarding_paid_4" to "Simpan dokumen anda dengan selamat",
	"onboarding_paid_5" to "Ikuti perkembangan terkini dengan perubahan status invois",
	"onboarding_paid_title" to "Tumbuh lebih cepat dengan...",
	"onboarding_paywall_message" to "Nikmati semua ciri premium sekarang pada harga terbaik.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Hanya ${currencySymbol}${introAmount} untuk ${introDuration} ${introPeriod} dan kemudian ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Hanya ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Percuma untuk ${trialDuration} ${trialPeriod} dan kemudian hanya ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Percuma untuk${trialDuration}${trialPeriod} kemudian${currencySymbol}${introductoryAmount} untuk${introductoryDuration}${introPeriod} dan kemudian hanya${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "Boleh diperbaharui secara automatik. Batalkan bila-bila masa.",
	"onboarding_paywall_restore" to "Pulihkan pembelian",
	"onboarding_paywall_restore_failure" to "Pemulihan pembelian telah gagal, cuba lagi kemudian atau hubungi sokongan.",
	"onboarding_paywall_restore_success" to "Pemulihan berjaya!",
	"onboarding_paywall_title" to "Capai Kehebatan!",
	"onboarding_welcome_message" to "Set alat lengkap untuk mengurus invois &amp; anggaran anda.",
	"onboarding_welcome_title" to "Selamat datang ke DingVoice",
	"other_state" to "Lain-lain",
	"overdue_state" to "Tertunggak",
	"paid_state" to "Dibayar",
	"password" to "Kata laluan",
	"password_blank_error" to "Kata laluan diperlukan.",
	"password_mismatch_error" to "Kata laluan tidak sepadan.",
	"pay" to "pay",
	"payment_instructions_label" to "Arahan Pembayaran",
	"payment_reminders" to "Peringatan pembayaran",
	"phone_number_fields" to "Nombor Telefon",
	"phone_number_hint" to "Nombor Telefon",
	"postcode_hint" to "Poskod",
	"preview" to "Pratonton",
	"preview_menu_hint" to "Menu pratonton",
	"price" to "Harga",
	"privacy_policy" to "Dasar Privasi",
	"profile" to "Profil",
	"profitable_client" to "Pelanggan anda yang paling menguntungkan ialah...",
	"profits_report" to "Keuntungan",
	"promo_content" to "Kandungan promosi",
	"quantity_hint" to "Kuantiti",
	"quantity_label_hint" to "Label Kuantiti",
	"query_info" to "Pertanyaan membolehkan anda menulis pengiraan tersuai yang merujuk kepada kedua-dua item dan peraturan cukai lain sebagai input. Tambah @ simbol dan item atau perihalan cukai untuk memaut ke entri lain +, -, *, /, % - operator matematik",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} telah diterima oleh pelanggan anda." },
	"received_state" to "Menerima",
	"registration_code_fields" to "Kod Pendaftaran",
	"reminder" to "Peringatan",
	"reports" to "Laporan",
	"reports_not_available" to "Tiada laporan tersedia, kembali kemudian.",
	"reveal_password" to "Dedahkan kata laluan",
	"revenue" to "revenue",
	"sales_tax" to "VAT",
	"sales_tax_number" to "Nombor VAT",
	"save" to "Simpan",
	"scan_document" to "Imbas dokumen",
	"search" to "Carian",
	"select" to "Pilih",
	"select_icon" to "Pilih ikon",
	"select_signature" to "Pilih tandatangan",
	"select_template" to "Pilih templat",
	"send" to "Menghantar",
	"sent_state" to "Dihantar",
	"setup_sender" to "Sediakan penghantar",
	"share_option" to "Berkongsi",
	"ship_to" to "Hantar ke",
	"sign_in" to "Daftar masuk",
	"sign_in_apple" to "Daftar masuk dengan Apple",
	"sign_in_email" to "Daftar masuk dengan e-mel",
	"sign_in_facebook" to "Daftar masuk dengan Facebook",
	"sign_in_google" to "Log masuk dengan Google",
	"sign_up" to "Mendaftar",
	"sign_up_label" to "Tidak mempunyai akaun?",
	"sign_up_link_label" to "Mendaftar!",
	"signatures_option" to "Tandatangan",
	"skip" to "Langkau",
	"sold_items_report" to "Barang Terjual",
	"sort_by" to "Isih Mengikut:",
	"status_update" to "Kemas kini status dokumen",
	"sub_annual" to "Tahunan",
	"sub_annual_price" to Formattable { (price) -> "${price}/tahun" },
	"sub_backups" to "Sandaran Awan",
	"sub_insights" to "Wawasan",
	"sub_month" to "Bulanan",
	"sub_monthly_price" to Formattable { (price) -> "${price}/bulan" },
	"sub_notifications" to "Pemberitahuan",
	"sub_popup_message" to "Langganan akan datang tidak lama lagi. Semak kembali di sini dalam masa yang sedikit.",
	"sub_support" to "Sokongan",
	"sub_templates" to "Templat",
	"subscribe" to "Melanggan",
	"subscription_header" to "Kembangkan perniagaan anda dengan:",
	"subscription_option" to "Langganan",
	"subscriptions_load_fail" to "Maaf, kami tidak dapat mendapatkan semula langganan anda pada masa ini. Sila cuba sebentar lagi atau hubungi pasukan sokongan kami untuk mendapatkan bantuan.",
	"subtotal" to Formattable { (sum) -> "Subjumlah: ${sum}" },
	"subtotal_label" to "jumlah kecil",
	"sum" to "Jumlah",
	"support_option" to "Sokongan",
	"switch_reports" to "Tukar Laporan",
	"tax" to "Cukai",
	"tax_deleted_message" to "Cukai dihapuskan",
	"tax_description_warning" to "Jika digunakan ini muncul pada invois",
	"tax_input_flat" to "Rata",
	"tax_input_percent" to "Peratus",
	"tax_number_fields" to "Nombor Cukai",
	"tax_number_hint" to "Nombor Cukai",
	"tax_query_info_description" to "Maklumat Pertanyaan",
	"tax_report" to "Cukai",
	"tax_rules_option" to "Peraturan Cukai",
	"taxed_clients_report" to "Pelanggan Bercukai",
	"template" to "Template",
	"template_selector_info" to "Leret untuk menukar templat lalai",
	"template_unlock_promo" to "Akses lebih banyak templat dengan Premium",
	"templates_option" to "Template",
	"terms_of_service" to "Syarat Perkhidmatan",
	"thank_you" to "Terima kasih",
	"to" to "Untuk",
	"total" to Formattable { (sum) -> "Jumlah: ${sum}" },
	"total_label" to "Jumlah",
	"undo_action" to "Membatalkan",
	"unit" to "Unit",
	"unit_cost_hint" to "Kos Unit",
	"unknown_client" to "Pelanggan Tidak Dikenali",
	"unlock" to "Buka kunci",
	"unnamed_item" to "Item tidak dinamakan",
	"upgrade" to "Naik taraf",
	"user_collision_error" to "Akaun sudah wujud.",
	"valid_until" to "Sah sehingga",
	"warning" to "Amaran!",
	"yes" to "yes",
    )
    
}