// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Nl  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Accepteer ${termsOfService} en ${privacyPolicy}" }
override val account: String = "rekening"
override val accounting: String = "boekhouding"
override val accruals: String = "overlopende posten"
override val action_code_error: String = "Ongeldige code"
override val add_client_action: String = "Client toevoegen"
override val add_customer: String = "Klant toevoegen"
override val add_expense: String = "Kosten toevoegen"
override val add_fields_label: String = "Toevoegen:"
override val add_invoice_action: String = "Maken"
override val add_item_action: String = "Artikel toevoegen"
override val add_item_button: String = "Artikel toevoegen"
override val add_signature_action: String = "Handtekening toevoegen"
override val add_tax_action: String = "Belasting toevoegen"
override val address_fields: String = "Adres"
override val address_line_hint: String = "Adresregel"
override val advanced: String = "Geavanceerd"
override val agree_marketing: String = "Ik ga akkoord met het ontvangen van direct marketingcommunicatie"
override val all_notifications: String = "Alle meldingen"
override val amortization: String = "afschrijving"
override val amount: String = "Bedrag"
override val asset: String = "actief"
override val attach_file: String = "Bestand bijvoegen"
override val attachment: String = "Bijlage"
override val auth_error: String = "Actie is mislukt, probeer het opnieuw."
override val available_for_paid: String = "Cloudsynchronisatie is alleen beschikbaar onder betaald abonnement."
override val backups_option: String = "Back-ups"
override val balance: String = "saldo"
override val bank_account: String = "Bankrekening"
override val bill_number: String = "Rekeningnummer"
override val cancel: String = "Annuleren"
override val cancel_trial: String = "Annuleer op elk gewenst moment"
override val capital: String = "kapitaal"
override val cash: String = "contant"
override val categories: String = "Categorieën"
override val category: String = "Categorie"
override val category_add: String = "Categorie toevoegen"
override val category_delete: String = "Categorie verwijderen"
override val category_name: String = "Naam categorie"
override val change_language: Formattable = Formattable { (language) -> "Factuur Taal: ${language}" }
override val change_language_label: String = "Factuur Taal"
override val change_template: String = "Sjabloon wijzigen"
override val city_hint: String = "Plaats"
override val clear: String = "Wissen"
override val client: String = "Cliënt"
override val client_actions: String = "Acties van cliënten"
override val client_deleted_message: String = "Client verwijderd"
override val clients_error_required_fields: String = "Er is minimaal één waarde vereist."
override val clients_option: String = "Cliënten"
override val close: String = "Sluiten"
override val cloud_sync: String = "Cloud synchroniseren"
override val coming_soon: String = "Binnenkort beschikbaar"
override val company_logo: String = "Bedrijfslogo"
override val company_name_hint: String = "Bedrijf"
override val confirm_password_label: String = "Paswoord bevestigen"
override val confirmation_title: String = "Weet u het zeker?"
override val convert_to_invoice: String = "Omzetten naar factuur"
override val country_hint: String = "Land"
override val create_client: String = "Client maken"
override val create_company: String = "Bedrijf aanmaken"
override val create_document: String = "Een document maken"
override val create_estimate: String = "schatting"
override val create_invoice: String = "Factuur"
override val create_item: String = "Een item aanmaken"
override val create_new_client: String = "Nieuwe opdrachtgever aanleggen"
override val create_new_item: String = "Nieuw item maken"
override val create_new_tax: String = "Nieuwe belasting creëren"
override val create_signature: String = "Handtekening"
override val created: String = "Gemaakt"
override val credit: String = "credit"
override val customer: String = "Klant"
override val date: String = "Datum"
override val debit: String = "debet"
override val debt: String = "schuld"
override val default_currency: Formattable = Formattable { (currency) -> "Standaardvaluta: ${currency}" }
override val default_currency_label: String = "Standaardvaluta"
override val delete: String = "Verwijderen"
override val delete_account: String = "Account verwijderen"
override val delete_confirmation_message: String = "Al uw gegevens gaan verloren."
override val delete_item_title: String = "Item verwijderen?"
override val delete_signature: String = "Handtekening verwijderen"
override val depreciation: String = "afschrijving"
override val description: String = "Omschrijving"
override val description_hint: String = "Omschrijving"
override val detailed_report: String = "Gedetailleerd Rapport"
override val disabled: String = "Uitgeschakeld"
override val discount: String = "Korting"
override val discount_hint: String = "Korting"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amhaars"
override val display_ar: String = "Arabisch"
override val display_as: String = "Assamees"
override val display_az: String = "Azerbeidzjaans"
override val display_be: String = "Wit-Russische"
override val display_bg: String = "Bulgaars"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosnische"
override val display_ca: String = "Catalaans"
override val display_cs: String = "Tsjechisch"
override val display_da: String = "Deens"
override val display_de: String = "Duits"
override val display_el: String = "Grieks"
override val display_en: String = "Engels"
override val display_es: String = "Spaans"
override val display_es_MX: String = "Spaans (Mexico)"
override val display_et: String = "Estlands"
override val display_eu: String = "Baskisch"
override val display_fa: String = "Perzisch"
override val display_fi: String = "Fins"
override val display_fil: String = "Filipijns"
override val display_fr: String = "Frans"
override val display_fr_CA: String = "Canadees Frans"
override val display_gl: String = "Galicië"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hebreeuws"
override val display_hi: String = "Hindi"
override val display_hr: String = "Kroatisch"
override val display_hu: String = "Hongaars"
override val display_hy: String = "Armeens"
override val display_id: String = "Indonesisch"
override val display_is: String = "IJslands"
override val display_it: String = "Italiaans"
override val display_ja: String = "Japans"
override val display_ka: String = "Georgian"
override val display_kk: String = "Kazachse"
override val display_km: String = "Centraal Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Koreaans"
override val display_ky: String = "kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Litouws"
override val display_lv: String = "Lets"
override val display_mk: String = "Macedonisch"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongools"
override val display_mr: String = "Marathi"
override val display_ms: String = "Maleis"
override val display_my: String = "Birmese"
override val display_nb: String = "Noors Bokmål"
override val display_ne: String = "Nepalees"
override val display_nl: String = "Nederlands"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Pools"
override val display_pt: String = "Portugees"
override val display_pt_BR: String = "Portugees (Brazilië)"
override val display_pt_PT: String = "Europees Portugees"
override val display_ro: String = "Roemeens"
override val display_ru: String = "Russisch"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slowaaks"
override val display_sl: String = "Sloveens"
override val display_sq: String = "Albanees"
override val display_sr: String = "Servisch"
override val display_sv: String = "Zweeds"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Turks"
override val display_uk: String = "Oekraïens"
override val display_ur: String = "Urdu"
override val display_uz: String = "Oezbeeks"
override val display_vi: String = "Vietnamees"
override val display_zh_Hant_TW: String = "Chinees (Taiwan, Traditioneel)"
override val display_zu: String = "Zoeloe"
override val document_estimate: String = "Schatting"
override val document_invoice: String = "Factuur"
override val document_quote: String = "Offerte"
override val done: String = "Gedaan"
override val download_app: String = "Download de app"
override val download_invoice: String = "Factuur downloaden"
override val download_pdf: String = "Download PDF"
override val draw_signature: String = "Teken een handtekening"
override val due: Formattable = Formattable { (date) -> "Verschuldigd op ${date}" }
override val due_date: String = "Vervaldag"
override val due_day: String = "in een dag"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} is verschuldigd ${dueWhen}!" }
override val due_today: String = "vandaag"
override val due_week: String = "in een week"
override val duplicate: String = "Dupliceren"
override val duration_day: String = "dag"
override val duration_month: String = "Maand"
override val duration_week: String = "week"
override val duration_year: String = "jaar"
override val email: String = "E-mail"
override val email_blank_error: String = "E-mailadres vereist"
override val email_error: String = "Ongeldig e-mailadres"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Dank u voor uw bedrijf. Om de volledige ${documentType} te bekijken klikt u op de knop aan het einde van deze brief. Let op: deze is geldig tot ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "U heeft een nieuwe ${documentType} ${invoiceNumber} ontvangen!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "Factuur ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Beste ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Dank u voor uw bedrijf. Om de volledige ${documentType} te bekijken klikt u op de knop aan het einde van deze brief. Gelieve het saldo te betalen tot ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "U heeft een nieuwe ${documentType} ${invoiceNumber} ontvangen!" }
override val email_reset_success_message: String = "e-mail om het verzonden wachtwoord opnieuw in te stellen, controleer je inbox."
override val empty_clients_list_message: String = "U hebt geen cliënten om te volgen. Voeg een nieuwe client toe."
override val empty_estimate_list_message: String = "Maak je eerste schatting"
override val empty_expenses: String = "Je hebt geen uitgaven om bij te houden. Voeg een nieuwe uitgave toe."
override val empty_invoice_list_message: String = "Maak je eerste factuur"
override val empty_items_list_message: String = "Je hebt geen items, voeg een nieuw item toe!"
override val empty_signatures_list_message: String = "Er zijn geen handtekeningen gevonden. Maak er een!"
override val empty_taxes_list_message: String = "Je hebt geen belastingregels. Voeg een nieuwe belastingregel toe."
override val empty_templates_list_message: String = "Sjablonen niet beschikbaar."
override val enabled: String = "Ingeschakeld"
override val entry: String = "boeking"
override val equity: String = "eigen vermogen"
override val estimate_number: String = "Schatting #"
override val estimates: String = "Schattingen"
override val estimates_report: String = "Schattingen"
override val expense_bank_fees: String = "Bankkosten"
override val expense_benefits: String = "Voordelen"
override val expense_communication: String = "Communicatie"
override val expense_debt: String = "Schuld"
override val expense_deleted: String = "Uitgaven verwijderd"
override val expense_depreciation: String = "Afschrijving"
override val expense_gifts: String = "Geschenken"
override val expense_insurance: String = "Verzekering"
override val expense_inventory: String = "Inventaris"
override val expense_legal: String = "Wettelijk"
override val expense_maintenance: String = "Onderhoud"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Maaltijden"
override val expense_office_supplies: String = "Kantoorbenodigdheden"
override val expense_rent: String = "Huur"
override val expense_salaries: String = "Salarissen"
override val expense_shopping: String = "Winkelen"
override val expense_software: String = "Software"
override val expense_status_available: String = "Beschikbaar"
override val expense_status_processing: String = "Verwerking"
override val expense_tax: String = "Belasting"
override val expense_training: String = "Opleiding"
override val expense_transport: String = "Vervoer"
override val expense_travel: String = "Reizen"
override val expense_utility: String = "Utility"
override val expenses: String = "Uitgaven"
override val feedback_option: String = "Terugkoppeling"
override val filter: String = "Filteren"
override val first_name_hint: String = "Voornaam"
override val forgot_password: String = "Wachtwoord opnieuw instellen"
override val forgot_password_label: String = "Wachtwoord vergeten?"
override val from: String = "Van"
override val general_error: String = "Er is een fout opgetreden, probeer het later nog eens."
override val general_ledger: String = "algemeen grootboek"
override val go_back_label: String = "Terug"
override val group_by: String = "Groeperen op"
override val image_view: String = "Afbeelding"
override val install: String = "Installeren"
override val invalid_credentials_error: String = "Ongeldige aanmeldingsgegevens"
override val invalid_email_error: String = "Ongeldig e-mailadres"
override val invalid_user_error: String = "Ongeldige gebruikersreferenties."
override val invoice_deleted_message: String = "Factuur verwijderd"
override val invoice_menu: String = "Menu factuur"
override val invoice_number: String = "Factuur #"
override val invoices: String = "Facturen"
override val items_option: String = "Artikelen"
override val keep: String = "Behouden"
override val language_selector: Formattable = Formattable { (geselecteerd) -> "Taal ${geselecteerd}" }
override val last_name_hint: String = "Achternaam"
override val ledger: String = "grootboek"
override val legal: String = "Juridisch"
override val legal_error: String = "De Servicevoorwaarden en het Privacybeleid moeten accepteren"
override val liabilities: String = "verplichtingen"
override val loading: String = "Laden..."
override val login_required_error: String = "Aanmelden vereist"
override val logo_view: String = "Logo"
override val logout: String = "Uitloggen"
override val logout_prompt: String = "Uw gegevens worden niet gesynchroniseerd met de cloud. Facturen, schattingen, klanten en andere informatie bij uitloggen verwijderen?"
override val main_screen: String = "Hoofdscherm"
override val mark_paid: String = "Markeren als betaald"
override val message: String = "Bericht"
override val missing_sender_msg: String = "Een document zonder afzenderinformatie kan ongeldig zijn."
override val missing_sender_title: String = "Informatie over afzender ontbreekt"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "Augustus"
override val month_august_short: String = "Aug."
override val month_december: String = "December"
override val month_december_short: String = "Dec."
override val month_february: String = "Februari"
override val month_february_short: String = "Feb."
override val month_january: String = "Januari"
override val month_january_short: String = "Jan."
override val month_july: String = "Juli"
override val month_july_short: String = "Juli"
override val month_june: String = "Juni"
override val month_june_short: String = "Juni"
override val month_march: String = "Maart"
override val month_march_short: String = "Maart"
override val month_may: String = "Mei"
override val month_may_short: String = "Mei"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktober"
override val month_october_short: String = "Okt."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Kon niet worden geverifieerd."
override val new: String = "nieuwe"
override val news: String = "Nieuws"
override val next: String = "Volgende"
override val no: String = "nee"
override val no_data_available: String = "Geen gegevens beschikbaar"
override val no_search_results: String = "Sorry, we konden geen resultaten vinden."
override val none: String = "Geen"
override val note_fields: String = "Notities"
override val note_hint: String = "Notitie"
override val notifications_option: String = "Meldingen"
override val ok: String = "OK"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Essentiële onderdelen van DingVoice zijn voor altijd gratis. Onze missie is om u te helpen uw bedrijf te laten groeien, help ons te verbeteren door uw feedback te delen."
override val onboarding_aboutus_title: String = "We zijn hier om te helpen"
override val onboarding_paid_1: String = "Toegang tot professioneel ogende sjablonen"
override val onboarding_paid_2: String = "Kijk dieper in uw financiën"
override val onboarding_paid_3: String = "Problemen snel oplossen"
override val onboarding_paid_4: String = "Bewaar uw documenten veilig"
override val onboarding_paid_5: String = "Blijf op de hoogte van wijzigingen in de factuurstatus"
override val onboarding_paid_title: String = "Groei nog sneller met..."
override val onboarding_paywall_message: String = "Geniet nu van alle premium functies voor de beste prijs."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Gewoon ${currencySymbol}${introAmount} voor ${introDuration} ${introPeriod} en dan ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, bedrag, duur) -> "Gewoon ${currencySymbol}${bedrag} / ${duur}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis voor ${trialDuration} ${trialPeriod} en dan gewoon ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis voor ${trialDuration} ${trialPeriod} dan ${currencySymbol}${introductoryAmount} voor ${introductoryDuration} ${introPeriod} en dan alleen ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automatisch verlengbaar. Op elk moment opzegbaar."
override val onboarding_paywall_restore: String = "Herstel aankoop"
override val onboarding_paywall_restore_failure: String = "Het herstellen van aankopen is mislukt, probeer het later nog eens of neem contact op met support."
override val onboarding_paywall_restore_success: String = "Herstel succesvol!"
override val onboarding_paywall_title: String = "Bereik Grootheid!"
override val onboarding_welcome_message: String = "Een complete toolset om uw facturen en schattingen te beheren."
override val onboarding_welcome_title: String = "Welkom bij DingVoice"
override val other_state: String = "Anders"
override val overdue_state: String = "Achterstallige"
override val paid_state: String = "Betaald"
override val password: String = "Wachtwoord"
override val password_blank_error: String = "Wachtwoord vereist"
override val password_mismatch_error: String = "Wachtwoorden komen niet overeen"
override val pay: String = "betalen"
override val payment_instructions_label: String = "betalingsinstructies"
override val payment_reminders: String = "Betalingsherinneringen"
override val phone_number_fields: String = "Telefoonnummer"
override val phone_number_hint: String = "Telefoonnummer"
override val postcode_hint: String = "Postcode"
override val preview: String = "Voorbeeld"
override val preview_menu_hint: String = "Voorbeeld menu"
override val price: String = "Prijs"
override val privacy_policy: String = "Privacybeleid"
override val profile: String = "Profiel"
override val profitable_client: String = "Uw meest winstgevende client is..."
override val profits_report: String = "Winsten"
override val promo_content: String = "Promotie-inhoud"
override val quantity_hint: String = "Hoeveelheid"
override val quantity_label_hint: String = "Label hoeveelheid"
override val query_info: String = "Query stelt u in staat om aangepaste berekeningen te schrijven die verwijzen naar zowel items als andere belastingregels als invoer. Voeg @symbool en item- of belastingbeschrijving toe om te linken naar een andere vermelding +, -, *,/,% - wiskunde-operatoren"
override val received_notification_body: Formattable = Formattable { (documentType, documentNummer) -> "${documentType} ${documentNummer} is ontvangen door uw klant." }
override val received_state: String = "Ontvangen"
override val registration_code_fields: String = "Registratie code"
override val reminder: String = "Herinnering"
override val reports: String = "Rapporten"
override val reports_not_available: String = "Geen rapporten beschikbaar, kom later terug."
override val reveal_password: String = "Wachtwoord onthullen"
override val revenue: String = "omzet"
override val sales_tax: String = "BTW"
override val sales_tax_number: String = "BTW-nummer"
override val save: String = "Opslaan"
override val scan_document: String = "Document scannen"
override val search: String = "Zoeken"
override val select: String = "Selecteer"
override val select_icon: String = "Pictogram selecteren"
override val select_signature: String = "Handtekening selecteren"
override val select_template: String = "Selecteer een template"
override val send: String = "Verzenden"
override val sent_state: String = "Verzonden"
override val setup_sender: String = "Afzender instellen"
override val share_option: String = "Delen"
override val ship_to: String = "Verzenden naar"
override val sign_in: String = "Inloggen"
override val sign_in_apple: String = "Inloggen met Apple"
override val sign_in_email: String = "Inloggen met e-mailadres"
override val sign_in_facebook: String = "Inloggen met Facebook"
override val sign_in_google: String = "Inloggen met Google"
override val sign_up: String = "Aanmelden"
override val sign_up_label: String = "Heb je nog geen account?"
override val sign_up_link_label: String = "Aanmelden"
override val signatures_option: String = "Handtekeningen"
override val skip: String = "Skip"
override val sold_items_report: String = "Verkochte artikelen"
override val sort_by: String = "Sorteer op"
override val status_update: String = "Update van de documentstatus"
override val sub_annual: String = "Jaarlijks"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/year" }
override val sub_backups: String = "Cloud Back-ups"
override val sub_insights: String = "Inzichten"
override val sub_month: String = "Maandelijks"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/maand" }
override val sub_notifications: String = "Meldingen"
override val sub_popup_message: String = "Abonnementen komen binnenkort. Kijk hier over een tijdje nog eens."
override val sub_support: String = "Ondersteuning"
override val sub_templates: String = "Sjablonen"
override val subscribe: String = "Abonneren"
override val subscription_header: String = "Laat uw bedrijf groeien met:"
override val subscription_option: String = "Abonnement"
override val subscriptions_load_fail: String = "Sorry, we kunnen uw abonnementen op dit moment niet ophalen. Probeer het later nog eens of neem contact op met ons ondersteuningsteam voor hulp."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotaal: ${sum}" }
override val subtotal_label: String = "Subtotaal"
override val sum: String = "Som"
override val support_option: String = "Ondersteuning"
override val switch_reports: String = "Rapport wisselen"
override val tax: String = "Belasting"
override val tax_deleted_message: String = "Belasting geschrapt"
override val tax_description_warning: String = "Indien gebruikt, wordt dit weergegeven op een factuur"
override val tax_input_flat: String = "Vlak"
override val tax_input_percent: String = "Procent"
override val tax_number_fields: String = "BTW nummer"
override val tax_number_hint: String = "BTW nummer"
override val tax_query_info_description: String = "Query Info"
override val tax_report: String = "Belasting"
override val tax_rules_option: String = "Fiscale regels"
override val taxed_clients_report: String = "Belaste cliënten"
override val template: String = "Sjabloon"
override val template_selector_info: String = "Veeg om de standaardsjabloon te wijzigen"
override val template_unlock_promo: String = "Toegang tot meer sjablonen met Premium"
override val templates_option: String = "Sjablonen"
override val terms_of_service: String = "Servicevoorwaarden"
override val thank_you: String = "Dank u"
override val to: String = "Aan"
override val total: Formattable = Formattable { (sum) -> "Totaal ${sum}" }
override val total_label: String = "TOTAAL"
override val undo_action: String = "Ongedaan maken"
override val unit: String = "Eenheid"
override val unit_cost_hint: String = "Kosten per Eenheid"
override val unknown_client: String = "Onbekende Client"
override val unlock: String = "Ontgrendelen"
override val unnamed_item: String = "Naamloos item"
override val upgrade: String = "Upgraden"
override val user_collision_error: String = "Account bestaat al"
override val valid_until: String = "Geldig Tot"
override val warning: String = "Waarschuwing"
override val yes: String = "ja"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Accepteer ${termsOfService} en ${privacyPolicy}" },
	"account" to "rekening",
	"accounting" to "boekhouding",
	"accruals" to "overlopende posten",
	"action_code_error" to "Ongeldige code",
	"add_client_action" to "Client toevoegen",
	"add_customer" to "Klant toevoegen",
	"add_expense" to "Kosten toevoegen",
	"add_fields_label" to "Toevoegen:",
	"add_invoice_action" to "Maken",
	"add_item_action" to "Artikel toevoegen",
	"add_item_button" to "Artikel toevoegen",
	"add_signature_action" to "Handtekening toevoegen",
	"add_tax_action" to "Belasting toevoegen",
	"address_fields" to "Adres",
	"address_line_hint" to "Adresregel",
	"advanced" to "Geavanceerd",
	"agree_marketing" to "Ik ga akkoord met het ontvangen van direct marketingcommunicatie",
	"all_notifications" to "Alle meldingen",
	"amortization" to "afschrijving",
	"amount" to "Bedrag",
	"asset" to "actief",
	"attach_file" to "Bestand bijvoegen",
	"attachment" to "Bijlage",
	"auth_error" to "Actie is mislukt, probeer het opnieuw.",
	"available_for_paid" to "Cloudsynchronisatie is alleen beschikbaar onder betaald abonnement.",
	"backups_option" to "Back-ups",
	"balance" to "saldo",
	"bank_account" to "Bankrekening",
	"bill_number" to "Rekeningnummer",
	"cancel" to "Annuleren",
	"cancel_trial" to "Annuleer op elk gewenst moment",
	"capital" to "kapitaal",
	"cash" to "contant",
	"categories" to "Categorieën",
	"category" to "Categorie",
	"category_add" to "Categorie toevoegen",
	"category_delete" to "Categorie verwijderen",
	"category_name" to "Naam categorie",
	"change_language" to Formattable { (language) -> "Factuur Taal: ${language}" },
	"change_language_label" to "Factuur Taal",
	"change_template" to "Sjabloon wijzigen",
	"city_hint" to "Plaats",
	"clear" to "Wissen",
	"client" to "Cliënt",
	"client_actions" to "Acties van cliënten",
	"client_deleted_message" to "Client verwijderd",
	"clients_error_required_fields" to "Er is minimaal één waarde vereist.",
	"clients_option" to "Cliënten",
	"close" to "Sluiten",
	"cloud_sync" to "Cloud synchroniseren",
	"coming_soon" to "Binnenkort beschikbaar",
	"company_logo" to "Bedrijfslogo",
	"company_name_hint" to "Bedrijf",
	"confirm_password_label" to "Paswoord bevestigen",
	"confirmation_title" to "Weet u het zeker?",
	"convert_to_invoice" to "Omzetten naar factuur",
	"country_hint" to "Land",
	"create_client" to "Client maken",
	"create_company" to "Bedrijf aanmaken",
	"create_document" to "Een document maken",
	"create_estimate" to "schatting",
	"create_invoice" to "Factuur",
	"create_item" to "Een item aanmaken",
	"create_new_client" to "Nieuwe opdrachtgever aanleggen",
	"create_new_item" to "Nieuw item maken",
	"create_new_tax" to "Nieuwe belasting creëren",
	"create_signature" to "Handtekening",
	"created" to "Gemaakt",
	"credit" to "credit",
	"customer" to "Klant",
	"date" to "Datum",
	"debit" to "debet",
	"debt" to "schuld",
	"default_currency" to Formattable { (currency) -> "Standaardvaluta: ${currency}" },
	"default_currency_label" to "Standaardvaluta",
	"delete" to "Verwijderen",
	"delete_account" to "Account verwijderen",
	"delete_confirmation_message" to "Al uw gegevens gaan verloren.",
	"delete_item_title" to "Item verwijderen?",
	"delete_signature" to "Handtekening verwijderen",
	"depreciation" to "afschrijving",
	"description" to "Omschrijving",
	"description_hint" to "Omschrijving",
	"detailed_report" to "Gedetailleerd Rapport",
	"disabled" to "Uitgeschakeld",
	"discount" to "Korting",
	"discount_hint" to "Korting",
	"display_af" to "Afrikaans",
	"display_am" to "Amhaars",
	"display_ar" to "Arabisch",
	"display_as" to "Assamees",
	"display_az" to "Azerbeidzjaans",
	"display_be" to "Wit-Russische",
	"display_bg" to "Bulgaars",
	"display_bn" to "Bengali",
	"display_bs" to "Bosnische",
	"display_ca" to "Catalaans",
	"display_cs" to "Tsjechisch",
	"display_da" to "Deens",
	"display_de" to "Duits",
	"display_el" to "Grieks",
	"display_en" to "Engels",
	"display_es" to "Spaans",
	"display_es_MX" to "Spaans (Mexico)",
	"display_et" to "Estlands",
	"display_eu" to "Baskisch",
	"display_fa" to "Perzisch",
	"display_fi" to "Fins",
	"display_fil" to "Filipijns",
	"display_fr" to "Frans",
	"display_fr_CA" to "Canadees Frans",
	"display_gl" to "Galicië",
	"display_gu" to "Gujarati",
	"display_he" to "Hebreeuws",
	"display_hi" to "Hindi",
	"display_hr" to "Kroatisch",
	"display_hu" to "Hongaars",
	"display_hy" to "Armeens",
	"display_id" to "Indonesisch",
	"display_is" to "IJslands",
	"display_it" to "Italiaans",
	"display_ja" to "Japans",
	"display_ka" to "Georgian",
	"display_kk" to "Kazachse",
	"display_km" to "Centraal Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Koreaans",
	"display_ky" to "kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Litouws",
	"display_lv" to "Lets",
	"display_mk" to "Macedonisch",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongools",
	"display_mr" to "Marathi",
	"display_ms" to "Maleis",
	"display_my" to "Birmese",
	"display_nb" to "Noors Bokmål",
	"display_ne" to "Nepalees",
	"display_nl" to "Nederlands",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Pools",
	"display_pt" to "Portugees",
	"display_pt_BR" to "Portugees (Brazilië)",
	"display_pt_PT" to "Europees Portugees",
	"display_ro" to "Roemeens",
	"display_ru" to "Russisch",
	"display_si" to "Sinhala",
	"display_sk" to "Slowaaks",
	"display_sl" to "Sloveens",
	"display_sq" to "Albanees",
	"display_sr" to "Servisch",
	"display_sv" to "Zweeds",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Turks",
	"display_uk" to "Oekraïens",
	"display_ur" to "Urdu",
	"display_uz" to "Oezbeeks",
	"display_vi" to "Vietnamees",
	"display_zh_Hant_TW" to "Chinees (Taiwan, Traditioneel)",
	"display_zu" to "Zoeloe",
	"document_estimate" to "Schatting",
	"document_invoice" to "Factuur",
	"document_quote" to "Offerte",
	"done" to "Gedaan",
	"download_app" to "Download de app",
	"download_invoice" to "Factuur downloaden",
	"download_pdf" to "Download PDF",
	"draw_signature" to "Teken een handtekening",
	"due" to Formattable { (date) -> "Verschuldigd op ${date}" },
	"due_date" to "Vervaldag",
	"due_day" to "in een dag",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} is verschuldigd ${dueWhen}!" },
	"due_today" to "vandaag",
	"due_week" to "in een week",
	"duplicate" to "Dupliceren",
	"duration_day" to "dag",
	"duration_month" to "Maand",
	"duration_week" to "week",
	"duration_year" to "jaar",
	"email" to "E-mail",
	"email_blank_error" to "E-mailadres vereist",
	"email_error" to "Ongeldig e-mailadres",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Dank u voor uw bedrijf. Om de volledige ${documentType} te bekijken klikt u op de knop aan het einde van deze brief. Let op: deze is geldig tot ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "U heeft een nieuwe ${documentType} ${invoiceNumber} ontvangen!" },
	"email_invoice_cta" to Formattable { (documentType) -> "Factuur ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Beste ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Dank u voor uw bedrijf. Om de volledige ${documentType} te bekijken klikt u op de knop aan het einde van deze brief. Gelieve het saldo te betalen tot ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "U heeft een nieuwe ${documentType} ${invoiceNumber} ontvangen!" },
	"email_reset_success_message" to "e-mail om het verzonden wachtwoord opnieuw in te stellen, controleer je inbox.",
	"empty_clients_list_message" to "U hebt geen cliënten om te volgen. Voeg een nieuwe client toe.",
	"empty_estimate_list_message" to "Maak je eerste schatting",
	"empty_expenses" to "Je hebt geen uitgaven om bij te houden. Voeg een nieuwe uitgave toe.",
	"empty_invoice_list_message" to "Maak je eerste factuur",
	"empty_items_list_message" to "Je hebt geen items, voeg een nieuw item toe!",
	"empty_signatures_list_message" to "Er zijn geen handtekeningen gevonden. Maak er een!",
	"empty_taxes_list_message" to "Je hebt geen belastingregels. Voeg een nieuwe belastingregel toe.",
	"empty_templates_list_message" to "Sjablonen niet beschikbaar.",
	"enabled" to "Ingeschakeld",
	"entry" to "boeking",
	"equity" to "eigen vermogen",
	"estimate_number" to "Schatting #",
	"estimates" to "Schattingen",
	"estimates_report" to "Schattingen",
	"expense_bank_fees" to "Bankkosten",
	"expense_benefits" to "Voordelen",
	"expense_communication" to "Communicatie",
	"expense_debt" to "Schuld",
	"expense_deleted" to "Uitgaven verwijderd",
	"expense_depreciation" to "Afschrijving",
	"expense_gifts" to "Geschenken",
	"expense_insurance" to "Verzekering",
	"expense_inventory" to "Inventaris",
	"expense_legal" to "Wettelijk",
	"expense_maintenance" to "Onderhoud",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Maaltijden",
	"expense_office_supplies" to "Kantoorbenodigdheden",
	"expense_rent" to "Huur",
	"expense_salaries" to "Salarissen",
	"expense_shopping" to "Winkelen",
	"expense_software" to "Software",
	"expense_status_available" to "Beschikbaar",
	"expense_status_processing" to "Verwerking",
	"expense_tax" to "Belasting",
	"expense_training" to "Opleiding",
	"expense_transport" to "Vervoer",
	"expense_travel" to "Reizen",
	"expense_utility" to "Utility",
	"expenses" to "Uitgaven",
	"feedback_option" to "Terugkoppeling",
	"filter" to "Filteren",
	"first_name_hint" to "Voornaam",
	"forgot_password" to "Wachtwoord opnieuw instellen",
	"forgot_password_label" to "Wachtwoord vergeten?",
	"from" to "Van",
	"general_error" to "Er is een fout opgetreden, probeer het later nog eens.",
	"general_ledger" to "algemeen grootboek",
	"go_back_label" to "Terug",
	"group_by" to "Groeperen op",
	"image_view" to "Afbeelding",
	"install" to "Installeren",
	"invalid_credentials_error" to "Ongeldige aanmeldingsgegevens",
	"invalid_email_error" to "Ongeldig e-mailadres",
	"invalid_user_error" to "Ongeldige gebruikersreferenties.",
	"invoice_deleted_message" to "Factuur verwijderd",
	"invoice_menu" to "Menu factuur",
	"invoice_number" to "Factuur #",
	"invoices" to "Facturen",
	"items_option" to "Artikelen",
	"keep" to "Behouden",
	"language_selector" to Formattable { (geselecteerd) -> "Taal ${geselecteerd}" },
	"last_name_hint" to "Achternaam",
	"ledger" to "grootboek",
	"legal" to "Juridisch",
	"legal_error" to "De Servicevoorwaarden en het Privacybeleid moeten accepteren",
	"liabilities" to "verplichtingen",
	"loading" to "Laden...",
	"login_required_error" to "Aanmelden vereist",
	"logo_view" to "Logo",
	"logout" to "Uitloggen",
	"logout_prompt" to "Uw gegevens worden niet gesynchroniseerd met de cloud. Facturen, schattingen, klanten en andere informatie bij uitloggen verwijderen?",
	"main_screen" to "Hoofdscherm",
	"mark_paid" to "Markeren als betaald",
	"message" to "Bericht",
	"missing_sender_msg" to "Een document zonder afzenderinformatie kan ongeldig zijn.",
	"missing_sender_title" to "Informatie over afzender ontbreekt",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "Augustus",
	"month_august_short" to "Aug.",
	"month_december" to "December",
	"month_december_short" to "Dec.",
	"month_february" to "Februari",
	"month_february_short" to "Feb.",
	"month_january" to "Januari",
	"month_january_short" to "Jan.",
	"month_july" to "Juli",
	"month_july_short" to "Juli",
	"month_june" to "Juni",
	"month_june_short" to "Juni",
	"month_march" to "Maart",
	"month_march_short" to "Maart",
	"month_may" to "Mei",
	"month_may_short" to "Mei",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Oktober",
	"month_october_short" to "Okt.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Kon niet worden geverifieerd.",
	"new" to "nieuwe",
	"news" to "Nieuws",
	"next" to "Volgende",
	"no" to "nee",
	"no_data_available" to "Geen gegevens beschikbaar",
	"no_search_results" to "Sorry, we konden geen resultaten vinden.",
	"none" to "Geen",
	"note_fields" to "Notities",
	"note_hint" to "Notitie",
	"notifications_option" to "Meldingen",
	"ok" to "OK",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Essentiële onderdelen van DingVoice zijn voor altijd gratis. Onze missie is om u te helpen uw bedrijf te laten groeien, help ons te verbeteren door uw feedback te delen.",
	"onboarding_aboutus_title" to "We zijn hier om te helpen",
	"onboarding_paid_1" to "Toegang tot professioneel ogende sjablonen",
	"onboarding_paid_2" to "Kijk dieper in uw financiën",
	"onboarding_paid_3" to "Problemen snel oplossen",
	"onboarding_paid_4" to "Bewaar uw documenten veilig",
	"onboarding_paid_5" to "Blijf op de hoogte van wijzigingen in de factuurstatus",
	"onboarding_paid_title" to "Groei nog sneller met...",
	"onboarding_paywall_message" to "Geniet nu van alle premium functies voor de beste prijs.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Gewoon ${currencySymbol}${introAmount} voor ${introDuration} ${introPeriod} en dan ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, bedrag, duur) -> "Gewoon ${currencySymbol}${bedrag} / ${duur}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis voor ${trialDuration} ${trialPeriod} en dan gewoon ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis voor ${trialDuration} ${trialPeriod} dan ${currencySymbol}${introductoryAmount} voor ${introductoryDuration} ${introPeriod} en dan alleen ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automatisch verlengbaar. Op elk moment opzegbaar.",
	"onboarding_paywall_restore" to "Herstel aankoop",
	"onboarding_paywall_restore_failure" to "Het herstellen van aankopen is mislukt, probeer het later nog eens of neem contact op met support.",
	"onboarding_paywall_restore_success" to "Herstel succesvol!",
	"onboarding_paywall_title" to "Bereik Grootheid!",
	"onboarding_welcome_message" to "Een complete toolset om uw facturen en schattingen te beheren.",
	"onboarding_welcome_title" to "Welkom bij DingVoice",
	"other_state" to "Anders",
	"overdue_state" to "Achterstallige",
	"paid_state" to "Betaald",
	"password" to "Wachtwoord",
	"password_blank_error" to "Wachtwoord vereist",
	"password_mismatch_error" to "Wachtwoorden komen niet overeen",
	"pay" to "betalen",
	"payment_instructions_label" to "betalingsinstructies",
	"payment_reminders" to "Betalingsherinneringen",
	"phone_number_fields" to "Telefoonnummer",
	"phone_number_hint" to "Telefoonnummer",
	"postcode_hint" to "Postcode",
	"preview" to "Voorbeeld",
	"preview_menu_hint" to "Voorbeeld menu",
	"price" to "Prijs",
	"privacy_policy" to "Privacybeleid",
	"profile" to "Profiel",
	"profitable_client" to "Uw meest winstgevende client is...",
	"profits_report" to "Winsten",
	"promo_content" to "Promotie-inhoud",
	"quantity_hint" to "Hoeveelheid",
	"quantity_label_hint" to "Label hoeveelheid",
	"query_info" to "Query stelt u in staat om aangepaste berekeningen te schrijven die verwijzen naar zowel items als andere belastingregels als invoer. Voeg @symbool en item- of belastingbeschrijving toe om te linken naar een andere vermelding +, -, *,/,% - wiskunde-operatoren",
	"received_notification_body" to Formattable { (documentType, documentNummer) -> "${documentType} ${documentNummer} is ontvangen door uw klant." },
	"received_state" to "Ontvangen",
	"registration_code_fields" to "Registratie code",
	"reminder" to "Herinnering",
	"reports" to "Rapporten",
	"reports_not_available" to "Geen rapporten beschikbaar, kom later terug.",
	"reveal_password" to "Wachtwoord onthullen",
	"revenue" to "omzet",
	"sales_tax" to "BTW",
	"sales_tax_number" to "BTW-nummer",
	"save" to "Opslaan",
	"scan_document" to "Document scannen",
	"search" to "Zoeken",
	"select" to "Selecteer",
	"select_icon" to "Pictogram selecteren",
	"select_signature" to "Handtekening selecteren",
	"select_template" to "Selecteer een template",
	"send" to "Verzenden",
	"sent_state" to "Verzonden",
	"setup_sender" to "Afzender instellen",
	"share_option" to "Delen",
	"ship_to" to "Verzenden naar",
	"sign_in" to "Inloggen",
	"sign_in_apple" to "Inloggen met Apple",
	"sign_in_email" to "Inloggen met e-mailadres",
	"sign_in_facebook" to "Inloggen met Facebook",
	"sign_in_google" to "Inloggen met Google",
	"sign_up" to "Aanmelden",
	"sign_up_label" to "Heb je nog geen account?",
	"sign_up_link_label" to "Aanmelden",
	"signatures_option" to "Handtekeningen",
	"skip" to "Skip",
	"sold_items_report" to "Verkochte artikelen",
	"sort_by" to "Sorteer op",
	"status_update" to "Update van de documentstatus",
	"sub_annual" to "Jaarlijks",
	"sub_annual_price" to Formattable { (price) -> "${price}/year" },
	"sub_backups" to "Cloud Back-ups",
	"sub_insights" to "Inzichten",
	"sub_month" to "Maandelijks",
	"sub_monthly_price" to Formattable { (price) -> "${price}/maand" },
	"sub_notifications" to "Meldingen",
	"sub_popup_message" to "Abonnementen komen binnenkort. Kijk hier over een tijdje nog eens.",
	"sub_support" to "Ondersteuning",
	"sub_templates" to "Sjablonen",
	"subscribe" to "Abonneren",
	"subscription_header" to "Laat uw bedrijf groeien met:",
	"subscription_option" to "Abonnement",
	"subscriptions_load_fail" to "Sorry, we kunnen uw abonnementen op dit moment niet ophalen. Probeer het later nog eens of neem contact op met ons ondersteuningsteam voor hulp.",
	"subtotal" to Formattable { (sum) -> "Subtotaal: ${sum}" },
	"subtotal_label" to "Subtotaal",
	"sum" to "Som",
	"support_option" to "Ondersteuning",
	"switch_reports" to "Rapport wisselen",
	"tax" to "Belasting",
	"tax_deleted_message" to "Belasting geschrapt",
	"tax_description_warning" to "Indien gebruikt, wordt dit weergegeven op een factuur",
	"tax_input_flat" to "Vlak",
	"tax_input_percent" to "Procent",
	"tax_number_fields" to "BTW nummer",
	"tax_number_hint" to "BTW nummer",
	"tax_query_info_description" to "Query Info",
	"tax_report" to "Belasting",
	"tax_rules_option" to "Fiscale regels",
	"taxed_clients_report" to "Belaste cliënten",
	"template" to "Sjabloon",
	"template_selector_info" to "Veeg om de standaardsjabloon te wijzigen",
	"template_unlock_promo" to "Toegang tot meer sjablonen met Premium",
	"templates_option" to "Sjablonen",
	"terms_of_service" to "Servicevoorwaarden",
	"thank_you" to "Dank u",
	"to" to "Aan",
	"total" to Formattable { (sum) -> "Totaal ${sum}" },
	"total_label" to "TOTAAL",
	"undo_action" to "Ongedaan maken",
	"unit" to "Eenheid",
	"unit_cost_hint" to "Kosten per Eenheid",
	"unknown_client" to "Onbekende Client",
	"unlock" to "Ontgrendelen",
	"unnamed_item" to "Naamloos item",
	"upgrade" to "Upgraden",
	"user_collision_error" to "Account bestaat al",
	"valid_until" to "Geldig Tot",
	"warning" to "Waarschuwing",
	"yes" to "ja",
    )
    
}