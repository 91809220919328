// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Sl  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Sprejmite ${termsOfService} in ${privacyPolicy}" }
override val account: String = "račun"
override val accounting: String = "računovodstvo"
override val accruals: String = "kopičenja"
override val action_code_error: String = "Nepravilna koda."
override val add_client_action: String = "Dodajte stranko"
override val add_customer: String = "Dodajte stranko"
override val add_expense: String = "Dodajte stroške"
override val add_fields_label: String = "Dodajte:"
override val add_invoice_action: String = "Ustvarite spletno stran"
override val add_item_action: String = "Dodajte element"
override val add_item_button: String = "Dodajte element"
override val add_signature_action: String = "Dodajte podpis"
override val add_tax_action: String = "Dodajte davek"
override val address_fields: String = "Naslov"
override val address_line_hint: String = "Naslovna vrstica"
override val advanced: String = "Napredno"
override val agree_marketing: String = "Strinjam se s prejemanjem neposrednih tržnih sporočil"
override val all_notifications: String = "Vsa obvestila"
override val amortization: String = "amortizacija"
override val amount: String = "Znesek"
override val asset: String = "sredstvo"
override val attach_file: String = "Priložite datoteko"
override val attachment: String = "Priponka"
override val auth_error: String = "Akcija ni uspela, poskusite znova."
override val available_for_paid: String = "Sinhronizacija v oblaku je na voljo le v okviru plačljive naročnine."
override val backups_option: String = "Varnostne kopije"
override val balance: String = "stanje"
override val bank_account: String = "Bančni Račun"
override val bill_number: String = "Številka računa"
override val cancel: String = "Prekliči"
override val cancel_trial: String = "Prekličite kadarkoli"
override val capital: String = "kapital"
override val cash: String = "gotovina"
override val categories: String = "Kategorije"
override val category: String = "Kategorija"
override val category_add: String = "Dodajanje kategorije"
override val category_delete: String = "Izbriši kategorijo"
override val category_name: String = "Ime kategorije"
override val change_language: Formattable = Formattable { (language) -> "Jezik računa: ${language}" }
override val change_language_label: String = "Jezik Računa"
override val change_template: String = "Spremeni predlogo"
override val city_hint: String = "Mesto"
override val clear: String = "Jasno"
override val client: String = "Stranka"
override val client_actions: String = "Ukrepi stranke"
override val client_deleted_message: String = "Stranka je izbrisana"
override val clients_error_required_fields: String = "Zahteva se vsaj ena vrednost."
override val clients_option: String = "Stranke"
override val close: String = "Zapri"
override val cloud_sync: String = "Sinhronizacija v oblaku"
override val coming_soon: String = "Kmalu bo na voljo!"
override val company_logo: String = "Logotip podjetja"
override val company_name_hint: String = "Podjetje"
override val confirm_password_label: String = "Potrditev gesla"
override val confirmation_title: String = "Ste prepričani?"
override val convert_to_invoice: String = "Pretvarjanje v račun"
override val country_hint: String = "Država"
override val create_client: String = "Ustvari stranko"
override val create_company: String = "Ustvari podjetje"
override val create_document: String = "Ustvari dokument"
override val create_estimate: String = "Ocena"
override val create_invoice: String = "Račun"
override val create_item: String = "Ustvari predmet"
override val create_new_client: String = "Ustvari novo stranko"
override val create_new_item: String = "Ustvarjanje novega elementa"
override val create_new_tax: String = "Ustvarjanje novega davka"
override val create_signature: String = "Podpis"
override val created: String = "Ustvarjeno"
override val credit: String = "kredit"
override val customer: String = "Stranka"
override val date: String = "Datum"
override val debit: String = "bremenitev"
override val debt: String = "dolga"
override val default_currency: Formattable = Formattable { (currency) -> "Privzeta valuta: ${currency}" }
override val default_currency_label: String = "Privzeta valuta"
override val delete: String = "Izbriši"
override val delete_account: String = "Izbriši Račun"
override val delete_confirmation_message: String = "Vsi vaši podatki bodo izgubljeni."
override val delete_item_title: String = "Izbrisati element?"
override val delete_signature: String = "Brisanje podpisa"
override val depreciation: String = "amortizacija"
override val description: String = "Opis"
override val description_hint: String = "Opis"
override val detailed_report: String = "Podrobno Poročilo"
override val disabled: String = "Onemogočeno"
override val discount: String = "Popust"
override val discount_hint: String = "Popust"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharščina"
override val display_ar: String = "Arabščina"
override val display_as: String = "Assamese"
override val display_az: String = "Azerbajdžan"
override val display_be: String = "Beloruski"
override val display_bg: String = "Bolgarski"
override val display_bn: String = "Bengalščina"
override val display_bs: String = "Bosanski"
override val display_ca: String = "Katalonščina"
override val display_cs: String = "Češka"
override val display_da: String = "Danska"
override val display_de: String = "Nemščina"
override val display_el: String = "Grški"
override val display_en: String = "Angleščina"
override val display_es: String = "Španščina"
override val display_es_MX: String = "španščina (Mehika)"
override val display_et: String = "Estonski"
override val display_eu: String = "Baskije"
override val display_fa: String = "Perzijski"
override val display_fi: String = "Finska"
override val display_fil: String = "Filipinci"
override val display_fr: String = "Francoski"
override val display_fr_CA: String = "Kanadska francoščina"
override val display_gl: String = "Galicijski"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hebrejščina"
override val display_hi: String = "Hindi"
override val display_hr: String = "Hrvaška"
override val display_hu: String = "Madžarski"
override val display_hy: String = "Armenski"
override val display_id: String = "Indonezijski"
override val display_is: String = "Islandski"
override val display_it: String = "Italijanski"
override val display_ja: String = "Japonski"
override val display_ka: String = "Gruzijski"
override val display_kk: String = "Kazahstanski"
override val display_km: String = "Osrednji kmeri"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korejski"
override val display_ky: String = "Kirgiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Litvanski"
override val display_lv: String = "Latvijski"
override val display_mk: String = "Makedonski"
override val display_ml: String = "Malajalam"
override val display_mn: String = "Mongolski"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malajščina"
override val display_my: String = "Burmanski"
override val display_nb: String = "norveški bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Nizozemska"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Poljski"
override val display_pt: String = "Portugalska"
override val display_pt_BR: String = "portugalščina (Brazilija)"
override val display_pt_PT: String = "Evropska portugalščina"
override val display_ro: String = "Romunski"
override val display_ru: String = "Ruski"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovaška"
override val display_sl: String = "Slovenski"
override val display_sq: String = "Albanski"
override val display_sr: String = "Srbski"
override val display_sv: String = "Švedski"
override val display_sw: String = "Svahilščina"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Turški"
override val display_uk: String = "Ukrajinski"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbek"
override val display_vi: String = "Vietnamski"
override val display_zh_Hant_TW: String = "Kitajščina (Tajvan, Tradicionalna)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Ocena"
override val document_invoice: String = "Račun"
override val document_quote: String = "Cenovna ponudba"
override val done: String = "Končano"
override val download_app: String = "Prenesite aplikacijo"
override val download_invoice: String = "Prenesi Račun"
override val download_pdf: String = "Prenesi PDF"
override val draw_signature: String = "Narišite podpis"
override val due: Formattable = Formattable { (date) -> "Rok plačila ${date}" }
override val due_date: String = "Rok plačila"
override val due_day: String = "v enem dnevu"
override val due_label: String = "Na spletni strani"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} je zapadel ${dueWhen}!" }
override val due_today: String = "danes"
override val due_week: String = "v enem tednu"
override val duplicate: String = "Podvojeno"
override val duration_day: String = "dan"
override val duration_month: String = "Mesec"
override val duration_week: String = "teden"
override val duration_year: String = "leto"
override val email: String = "E-pošta"
override val email_blank_error: String = "Zahtevana e-pošta."
override val email_error: String = "Nepravilen e-poštni naslov."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Zahvaljujemo se vam za vaše poslovanje. Za ogled celotnega ${documentType} kliknite gumb na koncu tega pisma. Upoštevajte, da velja do ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Prejeli ste nov dokument: ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "PREGLEDANJE ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Spoštovani ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Zahvaljujemo se vam za vaše poslovanje. Za ogled celotnega ${documentType} kliknite gumb na koncu tega pisma. Prosimo, da preostanek plačate do ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Prejeli ste nov dokument: ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Poslano e-poštno sporočilo za ponastavitev gesla, preverite svoj e-poštni predal."
override val empty_clients_list_message: String = "Nimate strank, ki bi jih bilo treba spremljati. Dodajte novo stranko."
override val empty_estimate_list_message: String = "Ustvarite prvo oceno"
override val empty_expenses: String = "Ni vam treba spremljati stroškov. Dodajte nov strošek."
override val empty_invoice_list_message: String = "Ustvarite svoj prvi račun"
override val empty_items_list_message: String = "Nimate predmetov, dodajte nov predmet!"
override val empty_signatures_list_message: String = "Ni bilo najdenih podpisov. Ustvarite ga!"
override val empty_taxes_list_message: String = "Davčnih pravil nimate. Dodajte novo davčno pravilo."
override val empty_templates_list_message: String = "Predloge niso na voljo."
override val enabled: String = "Omogočeno"
override val entry: String = "vnos"
override val equity: String = "pravičnost"
override val estimate_number: String = "Ocena #"
override val estimates: String = "Ocene"
override val estimates_report: String = "Ocene"
override val expense_bank_fees: String = "Bančne provizije"
override val expense_benefits: String = "Prednosti"
override val expense_communication: String = "Komunikacija"
override val expense_debt: String = "Dolg"
override val expense_deleted: String = "Črtani odhodki"
override val expense_depreciation: String = "Amortizacija"
override val expense_gifts: String = "Darila"
override val expense_insurance: String = "Zavarovanje"
override val expense_inventory: String = "Inventarizacija"
override val expense_legal: String = "Pravni naslov"
override val expense_maintenance: String = "Vzdrževanje"
override val expense_marketing: String = "Trženje"
override val expense_meals: String = "Obroki"
override val expense_office_supplies: String = "Pisarniška oprema"
override val expense_rent: String = "Najem"
override val expense_salaries: String = "Plače"
override val expense_shopping: String = "Nakupovanje"
override val expense_software: String = "Programska oprema"
override val expense_status_available: String = "Na voljo"
override val expense_status_processing: String = "Obdelava"
override val expense_tax: String = "Davek"
override val expense_training: String = "usposabljanje"
override val expense_transport: String = "Prevoz"
override val expense_travel: String = "Potovanje"
override val expense_utility: String = "Storitve"
override val expenses: String = "Odhodki"
override val feedback_option: String = "Povratne informacije"
override val filter: String = "Filter"
override val first_name_hint: String = "Ime in priimek"
override val forgot_password: String = "Ponastavitev gesla"
override val forgot_password_label: String = "Pozabljeno geslo"
override val from: String = "S spletne strani"
override val general_error: String = "Prišlo je do napake, poskusite znova pozneje."
override val general_ledger: String = "glavna knjiga"
override val go_back_label: String = "Pojdi nazaj"
override val group_by: String = "Skupina po:"
override val image_view: String = "Slika"
override val install: String = "Namesti"
override val invalid_credentials_error: String = "Neveljavne poverilnice."
override val invalid_email_error: String = "Nepravilna e-pošta."
override val invalid_user_error: String = "Neveljavne poverilnice uporabnika."
override val invoice_deleted_message: String = "Črtan račun"
override val invoice_menu: String = "Meni računa"
override val invoice_number: String = "Račun #"
override val invoices: String = "Računi"
override val items_option: String = "Elementi"
override val keep: String = "Ohranite"
override val language_selector: Formattable = Formattable { (selected) -> "Jezik ${selected}" }
override val last_name_hint: String = "Priimek"
override val ledger: String = "knjiga"
override val legal: String = "Pravni naslov"
override val legal_error: String = "Sprejeti moraš pogoje storitve in pravilnik o zasebnosti"
override val liabilities: String = "obveznosti"
override val loading: String = "Nalaganje"
override val login_required_error: String = "Potrebna je prijava."
override val logo_view: String = "Logotip"
override val logout: String = "Odjava"
override val logout_prompt: String = "Vaši podatki niso sinhronizirani z oblakom. Brisanje računov, predračunov, strank in drugih podatkov ob odjavi?"
override val main_screen: String = "Glavni zaslon"
override val mark_paid: String = "Označi kot plačano"
override val message: String = "Sporočilo"
override val missing_sender_msg: String = "Dokument brez informacij o pošiljatelju je morda neveljaven."
override val missing_sender_title: String = "Manjkajoči podatki o pošiljatelju"
override val month_april: String = "april"
override val month_april_short: String = "April"
override val month_august: String = "Avgust"
override val month_august_short: String = "Avg."
override val month_december: String = "December"
override val month_december_short: String = "Dec."
override val month_february: String = "Februar"
override val month_february_short: String = "Feb."
override val month_january: String = "Januar"
override val month_january_short: String = "Jan."
override val month_july: String = "Julij"
override val month_july_short: String = "Julij"
override val month_june: String = "junij"
override val month_june_short: String = "Junij"
override val month_march: String = "marec"
override val month_march_short: String = "Marec"
override val month_may: String = "maj"
override val month_may_short: String = "Maj"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktober"
override val month_october_short: String = "Okt."
override val month_september: String = "september"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Ni uspelo preveriti."
override val new: String = "Novo"
override val news: String = "Novice"
override val next: String = "Naslednji"
override val no: String = "ne"
override val no_data_available: String = "Podatki niso na voljo"
override val no_search_results: String = "Žal nismo našli nobenih rezultatov."
override val none: String = "Ni"
override val note_fields: String = "Opombe"
override val note_hint: String = "Opomba"
override val notifications_option: String = "Obvestila"
override val ok: String = "V REDU"
override val onboarding: String = "Uvajanje v sistem"
override val onboarding_aboutus_message: String = "Bistveni deli programa DingVoice so za vedno brezplačni. Naše poslanstvo je pomagati vam pri rasti vašega podjetja, zato nam pomagajte pri izboljšavah tako, da nam posredujete svoje povratne informacije."
override val onboarding_aboutus_title: String = "Tu smo, da vam pomagamo"
override val onboarding_paid_1: String = "Dostop do profesionalnih predlog"
override val onboarding_paid_2: String = "Poglobite se v svoje finančne podatke"
override val onboarding_paid_3: String = "Hitro rešite težave"
override val onboarding_paid_4: String = "Hranite svoje dokumente na varnem"
override val onboarding_paid_5: String = "Bodite na tekočem s spremembami statusa računa"
override val onboarding_paid_title: String = "Še hitreje rastite z..."
override val onboarding_paywall_message: String = "Uživajte v vseh funkcijah Premium zdaj po najboljši ceni."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Samo ${currencySymbol}${introAmount} za ${introDuration} ${introPeriod} in nato ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, trajanje) -> "Samo ${currencySymbol}${amount} / ${trajanje}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Brezplačno za ${trialDuration} ${trialPeriod} in nato samo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Brezplačno za ${trialDuration} ${trialPeriod} nato ${currencySymbol}${introductoryAmount} za ${introductoryDuration} ${introPeriod} in nato samo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Samodejno obnovljiv. Prekličite kadar koli."
override val onboarding_paywall_restore: String = "Obnovitev nakupa"
override val onboarding_paywall_restore_failure: String = "Obnovitev nakupov ni uspela, poskusite znova pozneje ali se obrnite na podporo."
override val onboarding_paywall_restore_success: String = "Obnovitev je bila uspešna!"
override val onboarding_paywall_title: String = "Dosezite veličino!"
override val onboarding_welcome_message: String = "Celovit nabor orodij za upravljanje računov in ocen."
override val onboarding_welcome_title: String = "Dobrodošli v DingVoice"
override val other_state: String = "Drugo"
override val overdue_state: String = "Zapadli"
override val paid_state: String = "Plačano"
override val password: String = "Geslo"
override val password_blank_error: String = "Zahteva se geslo."
override val password_mismatch_error: String = "Gesla se ne ujemajo."
override val pay: String = "plačaj"
override val payment_instructions_label: String = "Navodila za plačilo"
override val payment_reminders: String = "Opomini za plačilo"
override val phone_number_fields: String = "Telefonska številka"
override val phone_number_hint: String = "Telefonska številka"
override val postcode_hint: String = "Poštna številka"
override val preview: String = "Predogled"
override val preview_menu_hint: String = "Meni za predogled"
override val price: String = "Cena"
override val privacy_policy: String = "Pravilnik o zasebnosti"
override val profile: String = "Profil"
override val profitable_client: String = "Vaša najbolj donosna stranka je..."
override val profits_report: String = "Dobički"
override val promo_content: String = "Promocijska vsebina"
override val quantity_hint: String = "Količina"
override val quantity_label_hint: String = "Oznaka količine"
override val query_info: String = "Poizvedba omogoča pisanje izračunov po meri, ki se nanašajo na elemente in druga davčna pravila kot vhodne podatke. Dodajte simbol @ in opis postavke ali davka za povezavo z drugim vnosom +, -, *, /, % - matematični operatorji"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} je prejel vaš odjemalec." }
override val received_state: String = "Prejeto"
override val registration_code_fields: String = "Registracijska Koda"
override val reminder: String = "Opomnik"
override val reports: String = "Poročila"
override val reports_not_available: String = "Poročila niso na voljo, vrnite se pozneje."
override val reveal_password: String = "Razkritje gesla"
override val revenue: String = "prihodek"
override val sales_tax: String = "DDV"
override val sales_tax_number: String = "Številka za DDV"
override val save: String = "Shrani"
override val scan_document: String = "Skeniranje dokumenta"
override val search: String = "Iskanje"
override val select: String = "Izberite"
override val select_icon: String = "Izberite ikono"
override val select_signature: String = "Izberite podpis"
override val select_template: String = "Izberite predlogo"
override val send: String = "Pošlji"
override val sent_state: String = "Poslano"
override val setup_sender: String = "Nastavite pošiljatelja"
override val share_option: String = "Delite"
override val ship_to: String = "Pošljite v"
override val sign_in: String = "Prijavite se"
override val sign_in_apple: String = "Prijavite se z družbo Apple"
override val sign_in_email: String = "Prijavite se z e-pošto"
override val sign_in_facebook: String = "Prijavite se s storitvijo Facebook"
override val sign_in_google: String = "Prijavite se z Googlom"
override val sign_up: String = "Prijavite se"
override val sign_up_label: String = "Nimate računa?"
override val sign_up_link_label: String = "Prijavite se!"
override val signatures_option: String = "Podpisi"
override val skip: String = "Preskoči"
override val sold_items_report: String = "Prodani Elementi"
override val sort_by: String = "Razvrsti po:"
override val status_update: String = "Posodobitev statusa dokumenta"
override val sub_annual: String = "Letno"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/leto" }
override val sub_backups: String = "Varnostno kopiranje v oblaku"
override val sub_insights: String = "Vpogledi"
override val sub_month: String = "Mesečno"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mesec" }
override val sub_notifications: String = "Obvestila"
override val sub_popup_message: String = "Kmalu prihajajo naročnine. Čez nekaj časa se oglasite tukaj."
override val sub_support: String = "Podpora"
override val sub_templates: String = "Predloge"
override val subscribe: String = "Naročite se na"
override val subscription_header: String = "Razvijte svoje podjetje z:"
override val subscription_option: String = "Naročnina"
override val subscriptions_load_fail: String = "Žal vaših naročnin trenutno ne moremo priklicati. Poskusite znova pozneje ali se za pomoč obrnite na našo ekipo za podporo."
override val subtotal: Formattable = Formattable { (sum) -> "Vmesni seštevek: ${sum}" }
override val subtotal_label: String = "Vmesni seštevek"
override val sum: String = "Vsota"
override val support_option: String = "Podpora"
override val switch_reports: String = "Preklopi Poročilo"
override val tax: String = "Davek"
override val tax_deleted_message: String = "Črtan davek"
override val tax_description_warning: String = "Če se uporablja, se to prikaže na računu."
override val tax_input_flat: String = "Ravna stran"
override val tax_input_percent: String = "Odstotek"
override val tax_number_fields: String = "Davčna številka"
override val tax_number_hint: String = "Davčna številka"
override val tax_query_info_description: String = "Informacije o poizvedbi"
override val tax_report: String = "Davek"
override val tax_rules_option: String = "Davčni predpisi"
override val taxed_clients_report: String = "Obdavčene stranke"
override val template: String = "Predloga"
override val template_selector_info: String = "Podrsnite za spremembo privzete predloge"
override val template_unlock_promo: String = "Dostop do več predlog s storitvijo Premium"
override val templates_option: String = "Predloge"
override val terms_of_service: String = "Pogoji uporabe storitev"
override val thank_you: String = "Hvala"
override val to: String = "Na naslov"
override val total: Formattable = Formattable { (sum) -> "Skupaj: ${sum}" }
override val total_label: String = "Skupaj"
override val undo_action: String = "Razveljavi"
override val unit: String = "Enota"
override val unit_cost_hint: String = "Stroški na enoto"
override val unknown_client: String = "Neznana Stranka"
override val unlock: String = "Odklepanje spletne strani"
override val unnamed_item: String = "Neimenovani element"
override val upgrade: String = "Nadgradnja"
override val user_collision_error: String = "Račun že obstaja."
override val valid_until: String = "Veljavno Do"
override val warning: String = "Opozorilo!"
override val yes: String = "da"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Sprejmite ${termsOfService} in ${privacyPolicy}" },
	"account" to "račun",
	"accounting" to "računovodstvo",
	"accruals" to "kopičenja",
	"action_code_error" to "Nepravilna koda.",
	"add_client_action" to "Dodajte stranko",
	"add_customer" to "Dodajte stranko",
	"add_expense" to "Dodajte stroške",
	"add_fields_label" to "Dodajte:",
	"add_invoice_action" to "Ustvarite spletno stran",
	"add_item_action" to "Dodajte element",
	"add_item_button" to "Dodajte element",
	"add_signature_action" to "Dodajte podpis",
	"add_tax_action" to "Dodajte davek",
	"address_fields" to "Naslov",
	"address_line_hint" to "Naslovna vrstica",
	"advanced" to "Napredno",
	"agree_marketing" to "Strinjam se s prejemanjem neposrednih tržnih sporočil",
	"all_notifications" to "Vsa obvestila",
	"amortization" to "amortizacija",
	"amount" to "Znesek",
	"asset" to "sredstvo",
	"attach_file" to "Priložite datoteko",
	"attachment" to "Priponka",
	"auth_error" to "Akcija ni uspela, poskusite znova.",
	"available_for_paid" to "Sinhronizacija v oblaku je na voljo le v okviru plačljive naročnine.",
	"backups_option" to "Varnostne kopije",
	"balance" to "stanje",
	"bank_account" to "Bančni Račun",
	"bill_number" to "Številka računa",
	"cancel" to "Prekliči",
	"cancel_trial" to "Prekličite kadarkoli",
	"capital" to "kapital",
	"cash" to "gotovina",
	"categories" to "Kategorije",
	"category" to "Kategorija",
	"category_add" to "Dodajanje kategorije",
	"category_delete" to "Izbriši kategorijo",
	"category_name" to "Ime kategorije",
	"change_language" to Formattable { (language) -> "Jezik računa: ${language}" },
	"change_language_label" to "Jezik Računa",
	"change_template" to "Spremeni predlogo",
	"city_hint" to "Mesto",
	"clear" to "Jasno",
	"client" to "Stranka",
	"client_actions" to "Ukrepi stranke",
	"client_deleted_message" to "Stranka je izbrisana",
	"clients_error_required_fields" to "Zahteva se vsaj ena vrednost.",
	"clients_option" to "Stranke",
	"close" to "Zapri",
	"cloud_sync" to "Sinhronizacija v oblaku",
	"coming_soon" to "Kmalu bo na voljo!",
	"company_logo" to "Logotip podjetja",
	"company_name_hint" to "Podjetje",
	"confirm_password_label" to "Potrditev gesla",
	"confirmation_title" to "Ste prepričani?",
	"convert_to_invoice" to "Pretvarjanje v račun",
	"country_hint" to "Država",
	"create_client" to "Ustvari stranko",
	"create_company" to "Ustvari podjetje",
	"create_document" to "Ustvari dokument",
	"create_estimate" to "Ocena",
	"create_invoice" to "Račun",
	"create_item" to "Ustvari predmet",
	"create_new_client" to "Ustvari novo stranko",
	"create_new_item" to "Ustvarjanje novega elementa",
	"create_new_tax" to "Ustvarjanje novega davka",
	"create_signature" to "Podpis",
	"created" to "Ustvarjeno",
	"credit" to "kredit",
	"customer" to "Stranka",
	"date" to "Datum",
	"debit" to "bremenitev",
	"debt" to "dolga",
	"default_currency" to Formattable { (currency) -> "Privzeta valuta: ${currency}" },
	"default_currency_label" to "Privzeta valuta",
	"delete" to "Izbriši",
	"delete_account" to "Izbriši Račun",
	"delete_confirmation_message" to "Vsi vaši podatki bodo izgubljeni.",
	"delete_item_title" to "Izbrisati element?",
	"delete_signature" to "Brisanje podpisa",
	"depreciation" to "amortizacija",
	"description" to "Opis",
	"description_hint" to "Opis",
	"detailed_report" to "Podrobno Poročilo",
	"disabled" to "Onemogočeno",
	"discount" to "Popust",
	"discount_hint" to "Popust",
	"display_af" to "Afrikaans",
	"display_am" to "Amharščina",
	"display_ar" to "Arabščina",
	"display_as" to "Assamese",
	"display_az" to "Azerbajdžan",
	"display_be" to "Beloruski",
	"display_bg" to "Bolgarski",
	"display_bn" to "Bengalščina",
	"display_bs" to "Bosanski",
	"display_ca" to "Katalonščina",
	"display_cs" to "Češka",
	"display_da" to "Danska",
	"display_de" to "Nemščina",
	"display_el" to "Grški",
	"display_en" to "Angleščina",
	"display_es" to "Španščina",
	"display_es_MX" to "španščina (Mehika)",
	"display_et" to "Estonski",
	"display_eu" to "Baskije",
	"display_fa" to "Perzijski",
	"display_fi" to "Finska",
	"display_fil" to "Filipinci",
	"display_fr" to "Francoski",
	"display_fr_CA" to "Kanadska francoščina",
	"display_gl" to "Galicijski",
	"display_gu" to "Gujarati",
	"display_he" to "Hebrejščina",
	"display_hi" to "Hindi",
	"display_hr" to "Hrvaška",
	"display_hu" to "Madžarski",
	"display_hy" to "Armenski",
	"display_id" to "Indonezijski",
	"display_is" to "Islandski",
	"display_it" to "Italijanski",
	"display_ja" to "Japonski",
	"display_ka" to "Gruzijski",
	"display_kk" to "Kazahstanski",
	"display_km" to "Osrednji kmeri",
	"display_kn" to "Kannada",
	"display_ko" to "Korejski",
	"display_ky" to "Kirgiz",
	"display_lo" to "Lao",
	"display_lt" to "Litvanski",
	"display_lv" to "Latvijski",
	"display_mk" to "Makedonski",
	"display_ml" to "Malajalam",
	"display_mn" to "Mongolski",
	"display_mr" to "Marathi",
	"display_ms" to "Malajščina",
	"display_my" to "Burmanski",
	"display_nb" to "norveški bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Nizozemska",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Poljski",
	"display_pt" to "Portugalska",
	"display_pt_BR" to "portugalščina (Brazilija)",
	"display_pt_PT" to "Evropska portugalščina",
	"display_ro" to "Romunski",
	"display_ru" to "Ruski",
	"display_si" to "Sinhala",
	"display_sk" to "Slovaška",
	"display_sl" to "Slovenski",
	"display_sq" to "Albanski",
	"display_sr" to "Srbski",
	"display_sv" to "Švedski",
	"display_sw" to "Svahilščina",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Turški",
	"display_uk" to "Ukrajinski",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbek",
	"display_vi" to "Vietnamski",
	"display_zh_Hant_TW" to "Kitajščina (Tajvan, Tradicionalna)",
	"display_zu" to "Zulu",
	"document_estimate" to "Ocena",
	"document_invoice" to "Račun",
	"document_quote" to "Cenovna ponudba",
	"done" to "Končano",
	"download_app" to "Prenesite aplikacijo",
	"download_invoice" to "Prenesi Račun",
	"download_pdf" to "Prenesi PDF",
	"draw_signature" to "Narišite podpis",
	"due" to Formattable { (date) -> "Rok plačila ${date}" },
	"due_date" to "Rok plačila",
	"due_day" to "v enem dnevu",
	"due_label" to "Na spletni strani",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} je zapadel ${dueWhen}!" },
	"due_today" to "danes",
	"due_week" to "v enem tednu",
	"duplicate" to "Podvojeno",
	"duration_day" to "dan",
	"duration_month" to "Mesec",
	"duration_week" to "teden",
	"duration_year" to "leto",
	"email" to "E-pošta",
	"email_blank_error" to "Zahtevana e-pošta.",
	"email_error" to "Nepravilen e-poštni naslov.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Zahvaljujemo se vam za vaše poslovanje. Za ogled celotnega ${documentType} kliknite gumb na koncu tega pisma. Upoštevajte, da velja do ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Prejeli ste nov dokument: ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "PREGLEDANJE ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Spoštovani ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Zahvaljujemo se vam za vaše poslovanje. Za ogled celotnega ${documentType} kliknite gumb na koncu tega pisma. Prosimo, da preostanek plačate do ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Prejeli ste nov dokument: ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Poslano e-poštno sporočilo za ponastavitev gesla, preverite svoj e-poštni predal.",
	"empty_clients_list_message" to "Nimate strank, ki bi jih bilo treba spremljati. Dodajte novo stranko.",
	"empty_estimate_list_message" to "Ustvarite prvo oceno",
	"empty_expenses" to "Ni vam treba spremljati stroškov. Dodajte nov strošek.",
	"empty_invoice_list_message" to "Ustvarite svoj prvi račun",
	"empty_items_list_message" to "Nimate predmetov, dodajte nov predmet!",
	"empty_signatures_list_message" to "Ni bilo najdenih podpisov. Ustvarite ga!",
	"empty_taxes_list_message" to "Davčnih pravil nimate. Dodajte novo davčno pravilo.",
	"empty_templates_list_message" to "Predloge niso na voljo.",
	"enabled" to "Omogočeno",
	"entry" to "vnos",
	"equity" to "pravičnost",
	"estimate_number" to "Ocena #",
	"estimates" to "Ocene",
	"estimates_report" to "Ocene",
	"expense_bank_fees" to "Bančne provizije",
	"expense_benefits" to "Prednosti",
	"expense_communication" to "Komunikacija",
	"expense_debt" to "Dolg",
	"expense_deleted" to "Črtani odhodki",
	"expense_depreciation" to "Amortizacija",
	"expense_gifts" to "Darila",
	"expense_insurance" to "Zavarovanje",
	"expense_inventory" to "Inventarizacija",
	"expense_legal" to "Pravni naslov",
	"expense_maintenance" to "Vzdrževanje",
	"expense_marketing" to "Trženje",
	"expense_meals" to "Obroki",
	"expense_office_supplies" to "Pisarniška oprema",
	"expense_rent" to "Najem",
	"expense_salaries" to "Plače",
	"expense_shopping" to "Nakupovanje",
	"expense_software" to "Programska oprema",
	"expense_status_available" to "Na voljo",
	"expense_status_processing" to "Obdelava",
	"expense_tax" to "Davek",
	"expense_training" to "usposabljanje",
	"expense_transport" to "Prevoz",
	"expense_travel" to "Potovanje",
	"expense_utility" to "Storitve",
	"expenses" to "Odhodki",
	"feedback_option" to "Povratne informacije",
	"filter" to "Filter",
	"first_name_hint" to "Ime in priimek",
	"forgot_password" to "Ponastavitev gesla",
	"forgot_password_label" to "Pozabljeno geslo",
	"from" to "S spletne strani",
	"general_error" to "Prišlo je do napake, poskusite znova pozneje.",
	"general_ledger" to "glavna knjiga",
	"go_back_label" to "Pojdi nazaj",
	"group_by" to "Skupina po:",
	"image_view" to "Slika",
	"install" to "Namesti",
	"invalid_credentials_error" to "Neveljavne poverilnice.",
	"invalid_email_error" to "Nepravilna e-pošta.",
	"invalid_user_error" to "Neveljavne poverilnice uporabnika.",
	"invoice_deleted_message" to "Črtan račun",
	"invoice_menu" to "Meni računa",
	"invoice_number" to "Račun #",
	"invoices" to "Računi",
	"items_option" to "Elementi",
	"keep" to "Ohranite",
	"language_selector" to Formattable { (selected) -> "Jezik ${selected}" },
	"last_name_hint" to "Priimek",
	"ledger" to "knjiga",
	"legal" to "Pravni naslov",
	"legal_error" to "Sprejeti moraš pogoje storitve in pravilnik o zasebnosti",
	"liabilities" to "obveznosti",
	"loading" to "Nalaganje",
	"login_required_error" to "Potrebna je prijava.",
	"logo_view" to "Logotip",
	"logout" to "Odjava",
	"logout_prompt" to "Vaši podatki niso sinhronizirani z oblakom. Brisanje računov, predračunov, strank in drugih podatkov ob odjavi?",
	"main_screen" to "Glavni zaslon",
	"mark_paid" to "Označi kot plačano",
	"message" to "Sporočilo",
	"missing_sender_msg" to "Dokument brez informacij o pošiljatelju je morda neveljaven.",
	"missing_sender_title" to "Manjkajoči podatki o pošiljatelju",
	"month_april" to "april",
	"month_april_short" to "April",
	"month_august" to "Avgust",
	"month_august_short" to "Avg.",
	"month_december" to "December",
	"month_december_short" to "Dec.",
	"month_february" to "Februar",
	"month_february_short" to "Feb.",
	"month_january" to "Januar",
	"month_january_short" to "Jan.",
	"month_july" to "Julij",
	"month_july_short" to "Julij",
	"month_june" to "junij",
	"month_june_short" to "Junij",
	"month_march" to "marec",
	"month_march_short" to "Marec",
	"month_may" to "maj",
	"month_may_short" to "Maj",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Oktober",
	"month_october_short" to "Okt.",
	"month_september" to "september",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Ni uspelo preveriti.",
	"new" to "Novo",
	"news" to "Novice",
	"next" to "Naslednji",
	"no" to "ne",
	"no_data_available" to "Podatki niso na voljo",
	"no_search_results" to "Žal nismo našli nobenih rezultatov.",
	"none" to "Ni",
	"note_fields" to "Opombe",
	"note_hint" to "Opomba",
	"notifications_option" to "Obvestila",
	"ok" to "V REDU",
	"onboarding" to "Uvajanje v sistem",
	"onboarding_aboutus_message" to "Bistveni deli programa DingVoice so za vedno brezplačni. Naše poslanstvo je pomagati vam pri rasti vašega podjetja, zato nam pomagajte pri izboljšavah tako, da nam posredujete svoje povratne informacije.",
	"onboarding_aboutus_title" to "Tu smo, da vam pomagamo",
	"onboarding_paid_1" to "Dostop do profesionalnih predlog",
	"onboarding_paid_2" to "Poglobite se v svoje finančne podatke",
	"onboarding_paid_3" to "Hitro rešite težave",
	"onboarding_paid_4" to "Hranite svoje dokumente na varnem",
	"onboarding_paid_5" to "Bodite na tekočem s spremembami statusa računa",
	"onboarding_paid_title" to "Še hitreje rastite z...",
	"onboarding_paywall_message" to "Uživajte v vseh funkcijah Premium zdaj po najboljši ceni.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Samo ${currencySymbol}${introAmount} za ${introDuration} ${introPeriod} in nato ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, trajanje) -> "Samo ${currencySymbol}${amount} / ${trajanje}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Brezplačno za ${trialDuration} ${trialPeriod} in nato samo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Brezplačno za ${trialDuration} ${trialPeriod} nato ${currencySymbol}${introductoryAmount} za ${introductoryDuration} ${introPeriod} in nato samo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Samodejno obnovljiv. Prekličite kadar koli.",
	"onboarding_paywall_restore" to "Obnovitev nakupa",
	"onboarding_paywall_restore_failure" to "Obnovitev nakupov ni uspela, poskusite znova pozneje ali se obrnite na podporo.",
	"onboarding_paywall_restore_success" to "Obnovitev je bila uspešna!",
	"onboarding_paywall_title" to "Dosezite veličino!",
	"onboarding_welcome_message" to "Celovit nabor orodij za upravljanje računov in ocen.",
	"onboarding_welcome_title" to "Dobrodošli v DingVoice",
	"other_state" to "Drugo",
	"overdue_state" to "Zapadli",
	"paid_state" to "Plačano",
	"password" to "Geslo",
	"password_blank_error" to "Zahteva se geslo.",
	"password_mismatch_error" to "Gesla se ne ujemajo.",
	"pay" to "plačaj",
	"payment_instructions_label" to "Navodila za plačilo",
	"payment_reminders" to "Opomini za plačilo",
	"phone_number_fields" to "Telefonska številka",
	"phone_number_hint" to "Telefonska številka",
	"postcode_hint" to "Poštna številka",
	"preview" to "Predogled",
	"preview_menu_hint" to "Meni za predogled",
	"price" to "Cena",
	"privacy_policy" to "Pravilnik o zasebnosti",
	"profile" to "Profil",
	"profitable_client" to "Vaša najbolj donosna stranka je...",
	"profits_report" to "Dobički",
	"promo_content" to "Promocijska vsebina",
	"quantity_hint" to "Količina",
	"quantity_label_hint" to "Oznaka količine",
	"query_info" to "Poizvedba omogoča pisanje izračunov po meri, ki se nanašajo na elemente in druga davčna pravila kot vhodne podatke. Dodajte simbol @ in opis postavke ali davka za povezavo z drugim vnosom +, -, *, /, % - matematični operatorji",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} je prejel vaš odjemalec." },
	"received_state" to "Prejeto",
	"registration_code_fields" to "Registracijska Koda",
	"reminder" to "Opomnik",
	"reports" to "Poročila",
	"reports_not_available" to "Poročila niso na voljo, vrnite se pozneje.",
	"reveal_password" to "Razkritje gesla",
	"revenue" to "prihodek",
	"sales_tax" to "DDV",
	"sales_tax_number" to "Številka za DDV",
	"save" to "Shrani",
	"scan_document" to "Skeniranje dokumenta",
	"search" to "Iskanje",
	"select" to "Izberite",
	"select_icon" to "Izberite ikono",
	"select_signature" to "Izberite podpis",
	"select_template" to "Izberite predlogo",
	"send" to "Pošlji",
	"sent_state" to "Poslano",
	"setup_sender" to "Nastavite pošiljatelja",
	"share_option" to "Delite",
	"ship_to" to "Pošljite v",
	"sign_in" to "Prijavite se",
	"sign_in_apple" to "Prijavite se z družbo Apple",
	"sign_in_email" to "Prijavite se z e-pošto",
	"sign_in_facebook" to "Prijavite se s storitvijo Facebook",
	"sign_in_google" to "Prijavite se z Googlom",
	"sign_up" to "Prijavite se",
	"sign_up_label" to "Nimate računa?",
	"sign_up_link_label" to "Prijavite se!",
	"signatures_option" to "Podpisi",
	"skip" to "Preskoči",
	"sold_items_report" to "Prodani Elementi",
	"sort_by" to "Razvrsti po:",
	"status_update" to "Posodobitev statusa dokumenta",
	"sub_annual" to "Letno",
	"sub_annual_price" to Formattable { (price) -> "${price}/leto" },
	"sub_backups" to "Varnostno kopiranje v oblaku",
	"sub_insights" to "Vpogledi",
	"sub_month" to "Mesečno",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mesec" },
	"sub_notifications" to "Obvestila",
	"sub_popup_message" to "Kmalu prihajajo naročnine. Čez nekaj časa se oglasite tukaj.",
	"sub_support" to "Podpora",
	"sub_templates" to "Predloge",
	"subscribe" to "Naročite se na",
	"subscription_header" to "Razvijte svoje podjetje z:",
	"subscription_option" to "Naročnina",
	"subscriptions_load_fail" to "Žal vaših naročnin trenutno ne moremo priklicati. Poskusite znova pozneje ali se za pomoč obrnite na našo ekipo za podporo.",
	"subtotal" to Formattable { (sum) -> "Vmesni seštevek: ${sum}" },
	"subtotal_label" to "Vmesni seštevek",
	"sum" to "Vsota",
	"support_option" to "Podpora",
	"switch_reports" to "Preklopi Poročilo",
	"tax" to "Davek",
	"tax_deleted_message" to "Črtan davek",
	"tax_description_warning" to "Če se uporablja, se to prikaže na računu.",
	"tax_input_flat" to "Ravna stran",
	"tax_input_percent" to "Odstotek",
	"tax_number_fields" to "Davčna številka",
	"tax_number_hint" to "Davčna številka",
	"tax_query_info_description" to "Informacije o poizvedbi",
	"tax_report" to "Davek",
	"tax_rules_option" to "Davčni predpisi",
	"taxed_clients_report" to "Obdavčene stranke",
	"template" to "Predloga",
	"template_selector_info" to "Podrsnite za spremembo privzete predloge",
	"template_unlock_promo" to "Dostop do več predlog s storitvijo Premium",
	"templates_option" to "Predloge",
	"terms_of_service" to "Pogoji uporabe storitev",
	"thank_you" to "Hvala",
	"to" to "Na naslov",
	"total" to Formattable { (sum) -> "Skupaj: ${sum}" },
	"total_label" to "Skupaj",
	"undo_action" to "Razveljavi",
	"unit" to "Enota",
	"unit_cost_hint" to "Stroški na enoto",
	"unknown_client" to "Neznana Stranka",
	"unlock" to "Odklepanje spletne strani",
	"unnamed_item" to "Neimenovani element",
	"upgrade" to "Nadgradnja",
	"user_collision_error" to "Račun že obstaja.",
	"valid_until" to "Veljavno Do",
	"warning" to "Opozorilo!",
	"yes" to "da",
    )
    
}