// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Eu  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Onartu ${termsOfService} eta ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Kode baliogabea."
override val add_client_action: String = "Gehitu bezeroa"
override val add_customer: String = "Gehitu bezeroa"
override val add_expense: String = "Gehitu gastua"
override val add_fields_label: String = "Gehitu:"
override val add_invoice_action: String = "Sortu"
override val add_item_action: String = "Gehitu elementua"
override val add_item_button: String = "Gehitu elementua"
override val add_signature_action: String = "Gehitu sinadura"
override val add_tax_action: String = "Gehitu zerga"
override val address_fields: String = "Helbidea"
override val address_line_hint: String = "Helbide-lerroa"
override val advanced: String = "Aurreratua"
override val agree_marketing: String = "Zuzeneko Marketing Komunikazioak jasotzea onartzen dut"
override val all_notifications: String = "Jakinarazpen guztiak"
override val amortization: String = "amortization"
override val amount: String = "Zenbatekoa"
override val asset: String = "asset"
override val attach_file: String = "Erantsi fitxategia"
override val attachment: String = "Eranskina"
override val auth_error: String = "Ekintzak huts egin du, saiatu berriro."
override val available_for_paid: String = "Hodeiaren sinkronizazioa ordainpeko harpidetzapean soilik dago erabilgarri."
override val backups_option: String = "Backups"
override val balance: String = "balance"
override val bank_account: String = "Banku-kontua"
override val bill_number: String = "Faktura zenbakia"
override val cancel: String = "bertan behera utzi"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Kategoriak"
override val category: String = "Kategoria"
override val category_add: String = "Gehitu Kategoria"
override val category_delete: String = "Ezabatu kategoria"
override val category_name: String = "Kategoriaren izena"
override val change_language: Formattable = Formattable { (language) -> "Fakturaren hizkuntza: ${language}" }
override val change_language_label: String = "Fakturaren hizkuntza"
override val change_template: String = "Aldatu txantiloia"
override val city_hint: String = "Hiria"
override val clear: String = "Garbi"
override val client: String = "Bezeroa"
override val client_actions: String = "Bezeroen ekintzak"
override val client_deleted_message: String = "Bezeroa ezabatu da"
override val clients_error_required_fields: String = "Gutxienez balio bat behar da."
override val clients_option: String = "Bezeroak"
override val close: String = "Itxi"
override val cloud_sync: String = "Hodeiaren sinkronizazioa"
override val coming_soon: String = "Laister!"
override val company_logo: String = "Enpresaren logotipoa"
override val company_name_hint: String = "Enpresa"
override val confirm_password_label: String = "Pasahitza baieztatu"
override val confirmation_title: String = "Ziur zaude?"
override val convert_to_invoice: String = "Bihurtu faktura"
override val country_hint: String = "Herrialdea"
override val create_client: String = "Sortu Bezeroa"
override val create_company: String = "Sortu Enpresa"
override val create_document: String = "Sortu dokumentua"
override val create_estimate: String = "Estimazioa"
override val create_invoice: String = "Faktura"
override val create_item: String = "Sortu elementua"
override val create_new_client: String = "Sortu bezero berria"
override val create_new_item: String = "Sortu elementu berria"
override val create_new_tax: String = "Zerga berria sortu"
override val create_signature: String = "Sinadura"
override val created: String = "Sortu"
override val credit: String = "credit"
override val customer: String = "Bezeroa"
override val date: String = "Data"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Moneta lehenetsia: ${currency}" }
override val default_currency_label: String = "Lehenetsitako moneta"
override val delete: String = "Ezabatu"
override val delete_account: String = "Ezabatu kontua"
override val delete_confirmation_message: String = "Zure datu guztiak galduko dira."
override val delete_item_title: String = "Elementua ezabatu?"
override val delete_signature: String = "Ezabatu sinadura"
override val depreciation: String = "depreciation"
override val description: String = "Deskribapena"
override val description_hint: String = "Deskribapena"
override val detailed_report: String = "Txosten xehatua"
override val disabled: String = "Desgaituta"
override val discount: String = "Deskontua"
override val discount_hint: String = "Deskontua"
override val display_af: String = "afrikarra"
override val display_am: String = "Amharikoa"
override val display_ar: String = "arabiera"
override val display_as: String = "Asamera"
override val display_az: String = "Azerbaijangoa"
override val display_be: String = "bielorrusiera"
override val display_bg: String = "bulgariera"
override val display_bn: String = "bengalera"
override val display_bs: String = "bosniarra"
override val display_ca: String = "katalana"
override val display_cs: String = "Txekiar"
override val display_da: String = "daniera"
override val display_de: String = "alemana"
override val display_el: String = "grekoa"
override val display_en: String = "ingelesa"
override val display_es: String = "gaztelania"
override val display_es_MX: String = "gaztelania (Mexiko)"
override val display_et: String = "Estoniarra"
override val display_eu: String = "euskara"
override val display_fa: String = "pertsiera"
override val display_fi: String = "Finlandiera"
override val display_fil: String = "Filipina"
override val display_fr: String = "frantsesa"
override val display_fr_CA: String = "Kanadako frantsesa"
override val display_gl: String = "galegoa"
override val display_gu: String = "gujaratera"
override val display_he: String = "hebreera"
override val display_hi: String = "Ezetz"
override val display_hr: String = "kroaziera"
override val display_hu: String = "hungariera"
override val display_hy: String = "armeniarra"
override val display_id: String = "Indonesian"
override val display_is: String = "islandiera"
override val display_it: String = "italiarra"
override val display_ja: String = "japoniarra"
override val display_ka: String = "georgiarra"
override val display_kk: String = "Kazakh"
override val display_km: String = "Erdialdeko Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korean"
override val display_ky: String = "kirgiza"
override val display_lo: String = "Lana"
override val display_lt: String = "Lituaniera"
override val display_lv: String = "letoniera"
override val display_mk: String = "Mazedoniera"
override val display_ml: String = "malayalamera"
override val display_mn: String = "Mongoliera"
override val display_mr: String = "Marathia"
override val display_ms: String = "malaysiera"
override val display_my: String = "birmaniera"
override val display_nb: String = "Norvegiako bokmål"
override val display_ne: String = "Nepalera"
override val display_nl: String = "holandarra"
override val display_or: String = "Oriya"
override val display_pa: String = "punjabera"
override val display_pl: String = "poloniarra"
override val display_pt: String = "portugesa"
override val display_pt_BR: String = "portugesa (Brasil)"
override val display_pt_PT: String = "Europako portugesa"
override val display_ro: String = "errumaniera"
override val display_ru: String = "errusiera"
override val display_si: String = "Sinhalera"
override val display_sk: String = "eslovakiera"
override val display_sl: String = "Esloveniera"
override val display_sq: String = "Albaniarra"
override val display_sr: String = "serbiarra"
override val display_sv: String = "Suediera"
override val display_sw: String = "Swahilia"
override val display_ta: String = "Tamila"
override val display_te: String = "Telugu"
override val display_th: String = "thailandiera"
override val display_tr: String = "turkiera"
override val display_uk: String = "Ukrainara"
override val display_ur: String = "Urdu"
override val display_uz: String = "uzbekera"
override val display_vi: String = "Vietnamera"
override val display_zh_Hant_TW: String = "Txinatarra (Taiwan, tradizionala)"
override val display_zu: String = "Zulua"
override val document_estimate: String = "Estimazioa"
override val document_invoice: String = "Faktura"
override val document_quote: String = "Aipatu"
override val done: String = "Eginda"
override val download_app: String = "Deskargatu aplikazioa"
override val download_invoice: String = "Deskargatu faktura"
override val download_pdf: String = "Deskargatu PDFa"
override val draw_signature: String = "Sinadura bat marraztu"
override val due: Formattable = Formattable { (date) -> "Epemuga ${date}" }
override val due_date: String = "Epemuga"
override val due_day: String = "egun batean"
override val due_label: String = "Ondorioz"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ${dueWhen} da!" }
override val due_today: String = "Gaur"
override val due_week: String = "aste batean"
override val duplicate: String = "Bikoiztu"
override val duration_day: String = "Eguna"
override val duration_month: String = "hilabete"
override val duration_week: String = "Astea"
override val duration_year: String = "Urtea"
override val email: String = "posta elektronikoa"
override val email_blank_error: String = "Posta elektronikoa behar da."
override val email_error: String = "Helbide elektroniko baliogabea."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Eskerrik asko zure negozioagatik. ${documentType} osoa ikusteko, egin klik gutun honen amaierako botoian. Kontuan izan ${dueDate} arte balio duela." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Berri bat jaso duzu${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "IKUSI ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Agur ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Eskerrik asko zure negozioagatik. ${documentType} osoa ikusteko, egin klik gutun honen amaierako botoian. Mesedez, ordaindu saldoa ${dueDate} arte." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Berri bat jaso duzu${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "Bidalitako pasahitza berrezartzeko posta elektronikoa, egiaztatu sarrera-ontzia."
override val empty_clients_list_message: String = "Ez duzu bezerorik jarraitzeko. Gehitu bezero berri bat."
override val empty_estimate_list_message: String = "Sortu zure lehen aurrekontua"
override val empty_expenses: String = "Ez duzu jarraipena egiteko gasturik. Gehitu gastu berri bat."
override val empty_invoice_list_message: String = "Sortu zure lehen faktura"
override val empty_items_list_message: String = "Ez duzu elementurik, gehitu elementu berri bat!"
override val empty_signatures_list_message: String = "Ez da sinadurarik aurkitu. Sortu bat!"
override val empty_taxes_list_message: String = "Ez duzu zerga araurik. Gehitu zerga-arau berri bat."
override val empty_templates_list_message: String = "Txantiloiak ez daude erabilgarri."
override val enabled: String = "Gaituta"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Zenbatespena #"
override val estimates: String = "Estimazioak"
override val estimates_report: String = "Estimazioak"
override val expense_bank_fees: String = "Banku-kuotak"
override val expense_benefits: String = "Onurak"
override val expense_communication: String = "Komunikazioa"
override val expense_debt: String = "Zorra"
override val expense_deleted: String = "Gastua ezabatu da"
override val expense_depreciation: String = "Amortizazioa"
override val expense_gifts: String = "Opariak"
override val expense_insurance: String = "Aseguruak"
override val expense_inventory: String = "Inbentarioa"
override val expense_legal: String = "Legezkoa"
override val expense_maintenance: String = "Mantentzea"
override val expense_marketing: String = "Marketina"
override val expense_meals: String = "Otorduak"
override val expense_office_supplies: String = "Bulegoko materiala"
override val expense_rent: String = "Alokairua"
override val expense_salaries: String = "Soldatak"
override val expense_shopping: String = "Erosketak"
override val expense_software: String = "Softwarea"
override val expense_status_available: String = "Eskuragarri"
override val expense_status_processing: String = "Tramitazioa"
override val expense_tax: String = "Zerga"
override val expense_training: String = "Prestakuntza"
override val expense_transport: String = "Garraioa"
override val expense_travel: String = "Bidaia"
override val expense_utility: String = "Erabilgarritasuna"
override val expenses: String = "Gastuak"
override val feedback_option: String = "feedback"
override val filter: String = "Iragazi"
override val first_name_hint: String = "Izena"
override val forgot_password: String = "Pasahitza berrezarri"
override val forgot_password_label: String = "Pasahitza ahaztua"
override val from: String = "Bertatik"
override val general_error: String = "Errore bat gertatu da. Saiatu berriro geroago."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Atzera egin"
override val group_by: String = "Taldeka:"
override val image_view: String = "Irudia"
override val install: String = "Instalatu"
override val invalid_credentials_error: String = "Kredentzialak baliogabeak."
override val invalid_email_error: String = "Helbide elektroniko baliogabea."
override val invalid_user_error: String = "Erabiltzailearen kredentzialak baliogabeak."
override val invoice_deleted_message: String = "Faktura ezabatu da"
override val invoice_menu: String = "Fakturaren menua"
override val invoice_number: String = "# faktura"
override val invoices: String = "Fakturak"
override val items_option: String = "Elementuak"
override val keep: String = "Gorde"
override val language_selector: Formattable = Formattable { (selected) -> "${selected} hizkuntza" }
override val last_name_hint: String = "Abizena"
override val ledger: String = "ledger"
override val legal: String = "Legezkoa"
override val legal_error: String = "Zerbitzu-baldintzak eta pribatutasun-politika onartu behar dira"
override val liabilities: String = "liabilities"
override val loading: String = "Kargatzen"
override val login_required_error: String = "Saioa hasi behar da."
override val logo_view: String = "Logotipoa"
override val logout: String = "Saioa amaitu"
override val logout_prompt: String = "Zure datuak ez daude hodeiarekin sinkronizatuta. Saioa amaitzean fakturak, aurrekontuak, bezeroak eta bestelako informazioa ezabatu?"
override val main_screen: String = "Pantaila nagusia"
override val mark_paid: String = "Markatu Ordaindu gisa"
override val message: String = "Mezua"
override val missing_sender_msg: String = "Baliteke igorleari buruzko informaziorik gabeko dokumentu bat baliogabea izatea."
override val missing_sender_title: String = "Bidaltzailearen informazioa falta da"
override val month_april: String = "Apirila"
override val month_april_short: String = "Apirila"
override val month_august: String = "Abuztu"
override val month_august_short: String = "abuztua."
override val month_december: String = "abendua"
override val month_december_short: String = "abendua"
override val month_february: String = "Otsaila"
override val month_february_short: String = "ots."
override val month_january: String = "Urtarrila"
override val month_january_short: String = "urt."
override val month_july: String = "uztaila"
override val month_july_short: String = "uztaila"
override val month_june: String = "ekaina"
override val month_june_short: String = "ekaina"
override val month_march: String = "martxoa"
override val month_march_short: String = "martxoa"
override val month_may: String = "maiatza"
override val month_may_short: String = "maiatza"
override val month_november: String = "Azaroa"
override val month_november_short: String = "Azaroa"
override val month_october: String = "urria"
override val month_october_short: String = "Urr."
override val month_september: String = "Iraila"
override val month_september_short: String = "iraila"
override val multifactor_error: String = "Ezin izan da egiaztatu."
override val new: String = "Berria"
override val news: String = "Berriak"
override val next: String = "HURRENGOAK"
override val no: String = "no"
override val no_data_available: String = "Ez dago daturik eskuragarri"
override val no_search_results: String = "Barkatu, ezin izan dugu emaitzarik aurkitu."
override val none: String = "Bat ere ez"
override val note_fields: String = "Oharrak"
override val note_hint: String = "Ohar"
override val notifications_option: String = "Jakinarazpenak"
override val ok: String = "Ados"
override val onboarding: String = "Sartzea"
override val onboarding_aboutus_message: String = "DingVoice-ren funtsezko atalak doakoak dira betirako. Gure eginkizuna zure negozioa hazten laguntzea da, lagun gaitzazu hobetzen zure iritzia partekatuz."
override val onboarding_aboutus_title: String = "Hemen gaude laguntzeko"
override val onboarding_paid_1: String = "Sartu itxura profesionaleko txantiloiak"
override val onboarding_paid_2: String = "Begiratu sakonago zure finantzak"
override val onboarding_paid_3: String = "Lortu arazoak azkar konpontzea"
override val onboarding_paid_4: String = "Gorde zure dokumentuak seguru"
override val onboarding_paid_5: String = "Egon eguneratuta fakturen egoera-aldaketen berri"
override val onboarding_paid_title: String = "Are azkarrago hazi..."
override val onboarding_paywall_message: String = "Gozatu orain premium funtzio guztiak prezio onenean."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "${currencySymbol}${introAmount} ${introDuration} ${introPeriod} eta gero ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} / ${duration} besterik ez" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Doan ${trialDuration} ${trialPeriod} eta gero ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Doan ${trialDuration} ${trialPeriod} eta gero ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod} eta gero ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Auto-berriztagarria. Utzi edonoiz."
override val onboarding_paywall_restore: String = "Berreskuratu erosketa"
override val onboarding_paywall_restore_failure: String = "Ezin izan da erosketak leheneratu. Saiatu berriro geroago edo jarri laguntzarekin harremanetan."
override val onboarding_paywall_restore_success: String = "Leheneratu arrakasta!"
override val onboarding_paywall_title: String = "Lortu Handia!"
override val onboarding_welcome_message: String = "Zure fakturak eta aurrekontuak kudeatzeko tresna multzo osoa."
override val onboarding_welcome_title: String = "Ongi etorri DingVoice-ra"
override val other_state: String = "Bestela"
override val overdue_state: String = "Atzeratuta"
override val paid_state: String = "Ordaindua"
override val password: String = "pasahitza"
override val password_blank_error: String = "Pasahitza beharrezkoa da."
override val password_mismatch_error: String = "Pasahitzak ez datoz bat."
override val pay: String = "pay"
override val payment_instructions_label: String = "Ordainketa Jarraibideak"
override val payment_reminders: String = "Ordainketa abisuak"
override val phone_number_fields: String = "Telefono zenbakia"
override val phone_number_hint: String = "Telefono zenbakia"
override val postcode_hint: String = "Posta kodea"
override val preview: String = "Aurrebista"
override val preview_menu_hint: String = "Aurrebista menua"
override val price: String = "Prezioa"
override val privacy_policy: String = "Pribatutasun politika"
override val profile: String = "Profila"
override val profitable_client: String = "Zure bezerorik errentagarriena da..."
override val profits_report: String = "Mozkinak"
override val promo_content: String = "Promozio-edukia"
override val quantity_hint: String = "Kantitatea"
override val quantity_label_hint: String = "Kopuru Etiketa"
override val query_info: String = "Kontsultak kalkulu pertsonalizatuak idazteko aukera ematen du, elementuak eta beste zerga arau batzuk sarrera gisa aipatzen dituztenak. Gehitu @ ikurra eta elementua edo zerga-deskribapena beste sarrera batekin estekatzeko +, -, *, /, % - operadore matematikoak"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} zure bezeroak jaso du." }
override val received_state: String = "Jaso"
override val registration_code_fields: String = "Erregistro kodea"
override val reminder: String = "Oroigarria"
override val reports: String = "Txostenak"
override val reports_not_available: String = "Ez dago txostenik eskuragarri, itzuli geroago."
override val reveal_password: String = "Erakutsi pasahitza"
override val revenue: String = "revenue"
override val sales_tax: String = "BEZa"
override val sales_tax_number: String = "BEZ zenbakia"
override val save: String = "Gorde"
override val scan_document: String = "Eskaneatu dokumentua"
override val search: String = "Bilatu"
override val select: String = "AUKERATU"
override val select_icon: String = "Hautatu ikonoa"
override val select_signature: String = "Hautatu sinadura"
override val select_template: String = "Hautatu txantiloia"
override val send: String = "Bidali"
override val sent_state: String = "Bidali"
override val setup_sender: String = "Konfiguratu bidaltzailea"
override val share_option: String = "Partekatu…"
override val ship_to: String = "Bidali"
override val sign_in: String = "Hasi saioa"
override val sign_in_apple: String = "Hasi saioa Apple-rekin"
override val sign_in_email: String = "Hasi saioa e-mailarekin"
override val sign_in_facebook: String = "Hasi saioa Facebook-ekin"
override val sign_in_google: String = "Hasi saioa Google-rekin"
override val sign_up: String = "Izena eman"
override val sign_up_label: String = "Ez al duzu konturik?"
override val sign_up_link_label: String = "Izena eman!"
override val signatures_option: String = "Sinadurak"
override val skip: String = "Saltatu"
override val sold_items_report: String = "Saldutako elementuak"
override val sort_by: String = "Ordenatu:"
override val status_update: String = "Dokumentuaren egoera eguneratzea"
override val sub_annual: String = "Urtekoa"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/urte" }
override val sub_backups: String = "Hodeiko babeskopiak"
override val sub_insights: String = "Ikuspegiak"
override val sub_month: String = "Hilerokoa"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/hileko" }
override val sub_notifications: String = "Jakinarazpenak"
override val sub_popup_message: String = "Harpidetzak laster etorriko dira. Begiratu hemen pixka bat barru."
override val sub_support: String = "Laguntza"
override val sub_templates: String = "Txantiloiak"
override val subscribe: String = "Harpidetu"
override val subscription_header: String = "Haz ezazu zure negozioa:"
override val subscription_option: String = "Harpidetza"
override val subscriptions_load_fail: String = "Barkatu, momentu honetan ezin ditugu zure harpidetzak berreskuratu. Mesedez, saiatu berriro geroago edo jarri harremanetan gure laguntza-taldearekin laguntza eskatzeko."
override val subtotal: Formattable = Formattable { (sum) -> "Azpi-totala: ${sum}" }
override val subtotal_label: String = "Azpitotala"
override val sum: String = "Batura"
override val support_option: String = "Laguntza"
override val switch_reports: String = "Aldatu txostena"
override val tax: String = "Zerga"
override val tax_deleted_message: String = "Zerga ezabatu da"
override val tax_description_warning: String = "Erabiliz gero, faktura batean agertzen da"
override val tax_input_flat: String = "Laua"
override val tax_input_percent: String = "Ehunekoa"
override val tax_number_fields: String = "Zerga-zenbakia"
override val tax_number_hint: String = "Zerga-zenbakia"
override val tax_query_info_description: String = "Kontsulta informazioa"
override val tax_report: String = "Zerga"
override val tax_rules_option: String = "Zerga Arauak"
override val taxed_clients_report: String = "Zergapeko Bezeroak"
override val template: String = "Txantiloia"
override val template_selector_info: String = "Irristatu txantiloi lehenetsia aldatzeko"
override val template_unlock_promo: String = "Atzitu txantiloi gehiago Premium-ekin"
override val templates_option: String = "Txantiloiak"
override val terms_of_service: String = "Zerbitzu-baldintzak"
override val thank_you: String = "Eskerrik asko"
override val to: String = "To"
override val total: Formattable = Formattable { (sum) -> "Guztira: ${sum}" }
override val total_label: String = "Guztira"
override val undo_action: String = "Desegin"
override val unit: String = "Unitatea"
override val unit_cost_hint: String = "Kostu Unitarioa"
override val unknown_client: String = "Bezero ezezaguna"
override val unlock: String = "Desblokeatu"
override val unnamed_item: String = "Izenik gabeko elementua"
override val upgrade: String = "Berritu"
override val user_collision_error: String = "Kontua badago jada."
override val valid_until: String = "Baliozkoa arte"
override val warning: String = "Abisua!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Onartu ${termsOfService} eta ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Kode baliogabea.",
	"add_client_action" to "Gehitu bezeroa",
	"add_customer" to "Gehitu bezeroa",
	"add_expense" to "Gehitu gastua",
	"add_fields_label" to "Gehitu:",
	"add_invoice_action" to "Sortu",
	"add_item_action" to "Gehitu elementua",
	"add_item_button" to "Gehitu elementua",
	"add_signature_action" to "Gehitu sinadura",
	"add_tax_action" to "Gehitu zerga",
	"address_fields" to "Helbidea",
	"address_line_hint" to "Helbide-lerroa",
	"advanced" to "Aurreratua",
	"agree_marketing" to "Zuzeneko Marketing Komunikazioak jasotzea onartzen dut",
	"all_notifications" to "Jakinarazpen guztiak",
	"amortization" to "amortization",
	"amount" to "Zenbatekoa",
	"asset" to "asset",
	"attach_file" to "Erantsi fitxategia",
	"attachment" to "Eranskina",
	"auth_error" to "Ekintzak huts egin du, saiatu berriro.",
	"available_for_paid" to "Hodeiaren sinkronizazioa ordainpeko harpidetzapean soilik dago erabilgarri.",
	"backups_option" to "Backups",
	"balance" to "balance",
	"bank_account" to "Banku-kontua",
	"bill_number" to "Faktura zenbakia",
	"cancel" to "bertan behera utzi",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Kategoriak",
	"category" to "Kategoria",
	"category_add" to "Gehitu Kategoria",
	"category_delete" to "Ezabatu kategoria",
	"category_name" to "Kategoriaren izena",
	"change_language" to Formattable { (language) -> "Fakturaren hizkuntza: ${language}" },
	"change_language_label" to "Fakturaren hizkuntza",
	"change_template" to "Aldatu txantiloia",
	"city_hint" to "Hiria",
	"clear" to "Garbi",
	"client" to "Bezeroa",
	"client_actions" to "Bezeroen ekintzak",
	"client_deleted_message" to "Bezeroa ezabatu da",
	"clients_error_required_fields" to "Gutxienez balio bat behar da.",
	"clients_option" to "Bezeroak",
	"close" to "Itxi",
	"cloud_sync" to "Hodeiaren sinkronizazioa",
	"coming_soon" to "Laister!",
	"company_logo" to "Enpresaren logotipoa",
	"company_name_hint" to "Enpresa",
	"confirm_password_label" to "Pasahitza baieztatu",
	"confirmation_title" to "Ziur zaude?",
	"convert_to_invoice" to "Bihurtu faktura",
	"country_hint" to "Herrialdea",
	"create_client" to "Sortu Bezeroa",
	"create_company" to "Sortu Enpresa",
	"create_document" to "Sortu dokumentua",
	"create_estimate" to "Estimazioa",
	"create_invoice" to "Faktura",
	"create_item" to "Sortu elementua",
	"create_new_client" to "Sortu bezero berria",
	"create_new_item" to "Sortu elementu berria",
	"create_new_tax" to "Zerga berria sortu",
	"create_signature" to "Sinadura",
	"created" to "Sortu",
	"credit" to "credit",
	"customer" to "Bezeroa",
	"date" to "Data",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Moneta lehenetsia: ${currency}" },
	"default_currency_label" to "Lehenetsitako moneta",
	"delete" to "Ezabatu",
	"delete_account" to "Ezabatu kontua",
	"delete_confirmation_message" to "Zure datu guztiak galduko dira.",
	"delete_item_title" to "Elementua ezabatu?",
	"delete_signature" to "Ezabatu sinadura",
	"depreciation" to "depreciation",
	"description" to "Deskribapena",
	"description_hint" to "Deskribapena",
	"detailed_report" to "Txosten xehatua",
	"disabled" to "Desgaituta",
	"discount" to "Deskontua",
	"discount_hint" to "Deskontua",
	"display_af" to "afrikarra",
	"display_am" to "Amharikoa",
	"display_ar" to "arabiera",
	"display_as" to "Asamera",
	"display_az" to "Azerbaijangoa",
	"display_be" to "bielorrusiera",
	"display_bg" to "bulgariera",
	"display_bn" to "bengalera",
	"display_bs" to "bosniarra",
	"display_ca" to "katalana",
	"display_cs" to "Txekiar",
	"display_da" to "daniera",
	"display_de" to "alemana",
	"display_el" to "grekoa",
	"display_en" to "ingelesa",
	"display_es" to "gaztelania",
	"display_es_MX" to "gaztelania (Mexiko)",
	"display_et" to "Estoniarra",
	"display_eu" to "euskara",
	"display_fa" to "pertsiera",
	"display_fi" to "Finlandiera",
	"display_fil" to "Filipina",
	"display_fr" to "frantsesa",
	"display_fr_CA" to "Kanadako frantsesa",
	"display_gl" to "galegoa",
	"display_gu" to "gujaratera",
	"display_he" to "hebreera",
	"display_hi" to "Ezetz",
	"display_hr" to "kroaziera",
	"display_hu" to "hungariera",
	"display_hy" to "armeniarra",
	"display_id" to "Indonesian",
	"display_is" to "islandiera",
	"display_it" to "italiarra",
	"display_ja" to "japoniarra",
	"display_ka" to "georgiarra",
	"display_kk" to "Kazakh",
	"display_km" to "Erdialdeko Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Korean",
	"display_ky" to "kirgiza",
	"display_lo" to "Lana",
	"display_lt" to "Lituaniera",
	"display_lv" to "letoniera",
	"display_mk" to "Mazedoniera",
	"display_ml" to "malayalamera",
	"display_mn" to "Mongoliera",
	"display_mr" to "Marathia",
	"display_ms" to "malaysiera",
	"display_my" to "birmaniera",
	"display_nb" to "Norvegiako bokmål",
	"display_ne" to "Nepalera",
	"display_nl" to "holandarra",
	"display_or" to "Oriya",
	"display_pa" to "punjabera",
	"display_pl" to "poloniarra",
	"display_pt" to "portugesa",
	"display_pt_BR" to "portugesa (Brasil)",
	"display_pt_PT" to "Europako portugesa",
	"display_ro" to "errumaniera",
	"display_ru" to "errusiera",
	"display_si" to "Sinhalera",
	"display_sk" to "eslovakiera",
	"display_sl" to "Esloveniera",
	"display_sq" to "Albaniarra",
	"display_sr" to "serbiarra",
	"display_sv" to "Suediera",
	"display_sw" to "Swahilia",
	"display_ta" to "Tamila",
	"display_te" to "Telugu",
	"display_th" to "thailandiera",
	"display_tr" to "turkiera",
	"display_uk" to "Ukrainara",
	"display_ur" to "Urdu",
	"display_uz" to "uzbekera",
	"display_vi" to "Vietnamera",
	"display_zh_Hant_TW" to "Txinatarra (Taiwan, tradizionala)",
	"display_zu" to "Zulua",
	"document_estimate" to "Estimazioa",
	"document_invoice" to "Faktura",
	"document_quote" to "Aipatu",
	"done" to "Eginda",
	"download_app" to "Deskargatu aplikazioa",
	"download_invoice" to "Deskargatu faktura",
	"download_pdf" to "Deskargatu PDFa",
	"draw_signature" to "Sinadura bat marraztu",
	"due" to Formattable { (date) -> "Epemuga ${date}" },
	"due_date" to "Epemuga",
	"due_day" to "egun batean",
	"due_label" to "Ondorioz",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ${dueWhen} da!" },
	"due_today" to "Gaur",
	"due_week" to "aste batean",
	"duplicate" to "Bikoiztu",
	"duration_day" to "Eguna",
	"duration_month" to "hilabete",
	"duration_week" to "Astea",
	"duration_year" to "Urtea",
	"email" to "posta elektronikoa",
	"email_blank_error" to "Posta elektronikoa behar da.",
	"email_error" to "Helbide elektroniko baliogabea.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Eskerrik asko zure negozioagatik. ${documentType} osoa ikusteko, egin klik gutun honen amaierako botoian. Kontuan izan ${dueDate} arte balio duela." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Berri bat jaso duzu${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "IKUSI ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Agur ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Eskerrik asko zure negozioagatik. ${documentType} osoa ikusteko, egin klik gutun honen amaierako botoian. Mesedez, ordaindu saldoa ${dueDate} arte." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Berri bat jaso duzu${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "Bidalitako pasahitza berrezartzeko posta elektronikoa, egiaztatu sarrera-ontzia.",
	"empty_clients_list_message" to "Ez duzu bezerorik jarraitzeko. Gehitu bezero berri bat.",
	"empty_estimate_list_message" to "Sortu zure lehen aurrekontua",
	"empty_expenses" to "Ez duzu jarraipena egiteko gasturik. Gehitu gastu berri bat.",
	"empty_invoice_list_message" to "Sortu zure lehen faktura",
	"empty_items_list_message" to "Ez duzu elementurik, gehitu elementu berri bat!",
	"empty_signatures_list_message" to "Ez da sinadurarik aurkitu. Sortu bat!",
	"empty_taxes_list_message" to "Ez duzu zerga araurik. Gehitu zerga-arau berri bat.",
	"empty_templates_list_message" to "Txantiloiak ez daude erabilgarri.",
	"enabled" to "Gaituta",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Zenbatespena #",
	"estimates" to "Estimazioak",
	"estimates_report" to "Estimazioak",
	"expense_bank_fees" to "Banku-kuotak",
	"expense_benefits" to "Onurak",
	"expense_communication" to "Komunikazioa",
	"expense_debt" to "Zorra",
	"expense_deleted" to "Gastua ezabatu da",
	"expense_depreciation" to "Amortizazioa",
	"expense_gifts" to "Opariak",
	"expense_insurance" to "Aseguruak",
	"expense_inventory" to "Inbentarioa",
	"expense_legal" to "Legezkoa",
	"expense_maintenance" to "Mantentzea",
	"expense_marketing" to "Marketina",
	"expense_meals" to "Otorduak",
	"expense_office_supplies" to "Bulegoko materiala",
	"expense_rent" to "Alokairua",
	"expense_salaries" to "Soldatak",
	"expense_shopping" to "Erosketak",
	"expense_software" to "Softwarea",
	"expense_status_available" to "Eskuragarri",
	"expense_status_processing" to "Tramitazioa",
	"expense_tax" to "Zerga",
	"expense_training" to "Prestakuntza",
	"expense_transport" to "Garraioa",
	"expense_travel" to "Bidaia",
	"expense_utility" to "Erabilgarritasuna",
	"expenses" to "Gastuak",
	"feedback_option" to "feedback",
	"filter" to "Iragazi",
	"first_name_hint" to "Izena",
	"forgot_password" to "Pasahitza berrezarri",
	"forgot_password_label" to "Pasahitza ahaztua",
	"from" to "Bertatik",
	"general_error" to "Errore bat gertatu da. Saiatu berriro geroago.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Atzera egin",
	"group_by" to "Taldeka:",
	"image_view" to "Irudia",
	"install" to "Instalatu",
	"invalid_credentials_error" to "Kredentzialak baliogabeak.",
	"invalid_email_error" to "Helbide elektroniko baliogabea.",
	"invalid_user_error" to "Erabiltzailearen kredentzialak baliogabeak.",
	"invoice_deleted_message" to "Faktura ezabatu da",
	"invoice_menu" to "Fakturaren menua",
	"invoice_number" to "# faktura",
	"invoices" to "Fakturak",
	"items_option" to "Elementuak",
	"keep" to "Gorde",
	"language_selector" to Formattable { (selected) -> "${selected} hizkuntza" },
	"last_name_hint" to "Abizena",
	"ledger" to "ledger",
	"legal" to "Legezkoa",
	"legal_error" to "Zerbitzu-baldintzak eta pribatutasun-politika onartu behar dira",
	"liabilities" to "liabilities",
	"loading" to "Kargatzen",
	"login_required_error" to "Saioa hasi behar da.",
	"logo_view" to "Logotipoa",
	"logout" to "Saioa amaitu",
	"logout_prompt" to "Zure datuak ez daude hodeiarekin sinkronizatuta. Saioa amaitzean fakturak, aurrekontuak, bezeroak eta bestelako informazioa ezabatu?",
	"main_screen" to "Pantaila nagusia",
	"mark_paid" to "Markatu Ordaindu gisa",
	"message" to "Mezua",
	"missing_sender_msg" to "Baliteke igorleari buruzko informaziorik gabeko dokumentu bat baliogabea izatea.",
	"missing_sender_title" to "Bidaltzailearen informazioa falta da",
	"month_april" to "Apirila",
	"month_april_short" to "Apirila",
	"month_august" to "Abuztu",
	"month_august_short" to "abuztua.",
	"month_december" to "abendua",
	"month_december_short" to "abendua",
	"month_february" to "Otsaila",
	"month_february_short" to "ots.",
	"month_january" to "Urtarrila",
	"month_january_short" to "urt.",
	"month_july" to "uztaila",
	"month_july_short" to "uztaila",
	"month_june" to "ekaina",
	"month_june_short" to "ekaina",
	"month_march" to "martxoa",
	"month_march_short" to "martxoa",
	"month_may" to "maiatza",
	"month_may_short" to "maiatza",
	"month_november" to "Azaroa",
	"month_november_short" to "Azaroa",
	"month_october" to "urria",
	"month_october_short" to "Urr.",
	"month_september" to "Iraila",
	"month_september_short" to "iraila",
	"multifactor_error" to "Ezin izan da egiaztatu.",
	"new" to "Berria",
	"news" to "Berriak",
	"next" to "HURRENGOAK",
	"no" to "no",
	"no_data_available" to "Ez dago daturik eskuragarri",
	"no_search_results" to "Barkatu, ezin izan dugu emaitzarik aurkitu.",
	"none" to "Bat ere ez",
	"note_fields" to "Oharrak",
	"note_hint" to "Ohar",
	"notifications_option" to "Jakinarazpenak",
	"ok" to "Ados",
	"onboarding" to "Sartzea",
	"onboarding_aboutus_message" to "DingVoice-ren funtsezko atalak doakoak dira betirako. Gure eginkizuna zure negozioa hazten laguntzea da, lagun gaitzazu hobetzen zure iritzia partekatuz.",
	"onboarding_aboutus_title" to "Hemen gaude laguntzeko",
	"onboarding_paid_1" to "Sartu itxura profesionaleko txantiloiak",
	"onboarding_paid_2" to "Begiratu sakonago zure finantzak",
	"onboarding_paid_3" to "Lortu arazoak azkar konpontzea",
	"onboarding_paid_4" to "Gorde zure dokumentuak seguru",
	"onboarding_paid_5" to "Egon eguneratuta fakturen egoera-aldaketen berri",
	"onboarding_paid_title" to "Are azkarrago hazi...",
	"onboarding_paywall_message" to "Gozatu orain premium funtzio guztiak prezio onenean.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "${currencySymbol}${introAmount} ${introDuration} ${introPeriod} eta gero ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} / ${duration} besterik ez" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Doan ${trialDuration} ${trialPeriod} eta gero ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Doan ${trialDuration} ${trialPeriod} eta gero ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod} eta gero ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Auto-berriztagarria. Utzi edonoiz.",
	"onboarding_paywall_restore" to "Berreskuratu erosketa",
	"onboarding_paywall_restore_failure" to "Ezin izan da erosketak leheneratu. Saiatu berriro geroago edo jarri laguntzarekin harremanetan.",
	"onboarding_paywall_restore_success" to "Leheneratu arrakasta!",
	"onboarding_paywall_title" to "Lortu Handia!",
	"onboarding_welcome_message" to "Zure fakturak eta aurrekontuak kudeatzeko tresna multzo osoa.",
	"onboarding_welcome_title" to "Ongi etorri DingVoice-ra",
	"other_state" to "Bestela",
	"overdue_state" to "Atzeratuta",
	"paid_state" to "Ordaindua",
	"password" to "pasahitza",
	"password_blank_error" to "Pasahitza beharrezkoa da.",
	"password_mismatch_error" to "Pasahitzak ez datoz bat.",
	"pay" to "pay",
	"payment_instructions_label" to "Ordainketa Jarraibideak",
	"payment_reminders" to "Ordainketa abisuak",
	"phone_number_fields" to "Telefono zenbakia",
	"phone_number_hint" to "Telefono zenbakia",
	"postcode_hint" to "Posta kodea",
	"preview" to "Aurrebista",
	"preview_menu_hint" to "Aurrebista menua",
	"price" to "Prezioa",
	"privacy_policy" to "Pribatutasun politika",
	"profile" to "Profila",
	"profitable_client" to "Zure bezerorik errentagarriena da...",
	"profits_report" to "Mozkinak",
	"promo_content" to "Promozio-edukia",
	"quantity_hint" to "Kantitatea",
	"quantity_label_hint" to "Kopuru Etiketa",
	"query_info" to "Kontsultak kalkulu pertsonalizatuak idazteko aukera ematen du, elementuak eta beste zerga arau batzuk sarrera gisa aipatzen dituztenak. Gehitu @ ikurra eta elementua edo zerga-deskribapena beste sarrera batekin estekatzeko +, -, *, /, % - operadore matematikoak",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} zure bezeroak jaso du." },
	"received_state" to "Jaso",
	"registration_code_fields" to "Erregistro kodea",
	"reminder" to "Oroigarria",
	"reports" to "Txostenak",
	"reports_not_available" to "Ez dago txostenik eskuragarri, itzuli geroago.",
	"reveal_password" to "Erakutsi pasahitza",
	"revenue" to "revenue",
	"sales_tax" to "BEZa",
	"sales_tax_number" to "BEZ zenbakia",
	"save" to "Gorde",
	"scan_document" to "Eskaneatu dokumentua",
	"search" to "Bilatu",
	"select" to "AUKERATU",
	"select_icon" to "Hautatu ikonoa",
	"select_signature" to "Hautatu sinadura",
	"select_template" to "Hautatu txantiloia",
	"send" to "Bidali",
	"sent_state" to "Bidali",
	"setup_sender" to "Konfiguratu bidaltzailea",
	"share_option" to "Partekatu…",
	"ship_to" to "Bidali",
	"sign_in" to "Hasi saioa",
	"sign_in_apple" to "Hasi saioa Apple-rekin",
	"sign_in_email" to "Hasi saioa e-mailarekin",
	"sign_in_facebook" to "Hasi saioa Facebook-ekin",
	"sign_in_google" to "Hasi saioa Google-rekin",
	"sign_up" to "Izena eman",
	"sign_up_label" to "Ez al duzu konturik?",
	"sign_up_link_label" to "Izena eman!",
	"signatures_option" to "Sinadurak",
	"skip" to "Saltatu",
	"sold_items_report" to "Saldutako elementuak",
	"sort_by" to "Ordenatu:",
	"status_update" to "Dokumentuaren egoera eguneratzea",
	"sub_annual" to "Urtekoa",
	"sub_annual_price" to Formattable { (price) -> "${price}/urte" },
	"sub_backups" to "Hodeiko babeskopiak",
	"sub_insights" to "Ikuspegiak",
	"sub_month" to "Hilerokoa",
	"sub_monthly_price" to Formattable { (price) -> "${price}/hileko" },
	"sub_notifications" to "Jakinarazpenak",
	"sub_popup_message" to "Harpidetzak laster etorriko dira. Begiratu hemen pixka bat barru.",
	"sub_support" to "Laguntza",
	"sub_templates" to "Txantiloiak",
	"subscribe" to "Harpidetu",
	"subscription_header" to "Haz ezazu zure negozioa:",
	"subscription_option" to "Harpidetza",
	"subscriptions_load_fail" to "Barkatu, momentu honetan ezin ditugu zure harpidetzak berreskuratu. Mesedez, saiatu berriro geroago edo jarri harremanetan gure laguntza-taldearekin laguntza eskatzeko.",
	"subtotal" to Formattable { (sum) -> "Azpi-totala: ${sum}" },
	"subtotal_label" to "Azpitotala",
	"sum" to "Batura",
	"support_option" to "Laguntza",
	"switch_reports" to "Aldatu txostena",
	"tax" to "Zerga",
	"tax_deleted_message" to "Zerga ezabatu da",
	"tax_description_warning" to "Erabiliz gero, faktura batean agertzen da",
	"tax_input_flat" to "Laua",
	"tax_input_percent" to "Ehunekoa",
	"tax_number_fields" to "Zerga-zenbakia",
	"tax_number_hint" to "Zerga-zenbakia",
	"tax_query_info_description" to "Kontsulta informazioa",
	"tax_report" to "Zerga",
	"tax_rules_option" to "Zerga Arauak",
	"taxed_clients_report" to "Zergapeko Bezeroak",
	"template" to "Txantiloia",
	"template_selector_info" to "Irristatu txantiloi lehenetsia aldatzeko",
	"template_unlock_promo" to "Atzitu txantiloi gehiago Premium-ekin",
	"templates_option" to "Txantiloiak",
	"terms_of_service" to "Zerbitzu-baldintzak",
	"thank_you" to "Eskerrik asko",
	"to" to "To",
	"total" to Formattable { (sum) -> "Guztira: ${sum}" },
	"total_label" to "Guztira",
	"undo_action" to "Desegin",
	"unit" to "Unitatea",
	"unit_cost_hint" to "Kostu Unitarioa",
	"unknown_client" to "Bezero ezezaguna",
	"unlock" to "Desblokeatu",
	"unnamed_item" to "Izenik gabeko elementua",
	"upgrade" to "Berritu",
	"user_collision_error" to "Kontua badago jada.",
	"valid_until" to "Baliozkoa arte",
	"warning" to "Abisua!",
	"yes" to "yes",
    )
    
}