// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Pl  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Zaakceptuj ${termsOfService} i ${privacyPolicy}" }
override val account: String = "konto"
override val accounting: String = "rachunkowość"
override val accruals: String = "rozliczenia międzyokresowe"
override val action_code_error: String = "Nieprawidłowy kod."
override val add_client_action: String = "Dodaj klienta"
override val add_customer: String = "Dodaj klienta"
override val add_expense: String = "Dodaj wydatek"
override val add_fields_label: String = "Dodaj:"
override val add_invoice_action: String = "Utwórz stronę"
override val add_item_action: String = "Dodaj pozycję"
override val add_item_button: String = "Dodaj pozycję"
override val add_signature_action: String = "Dodaj podpis"
override val add_tax_action: String = "Dodaj podatek"
override val address_fields: String = "Adres"
override val address_line_hint: String = "Linia adresowa"
override val advanced: String = "ZAAWANSOWANY"
override val agree_marketing: String = "Wyrażam zgodę na otrzymywanie komunikatów marketingu bezpośredniego"
override val all_notifications: String = "Wszystkie powiadomienia"
override val amortization: String = "amortyzacja"
override val amount: String = "Kwota"
override val asset: String = "aktywa"
override val attach_file: String = "Załącz plik"
override val attachment: String = "Załącznik"
override val auth_error: String = "Akcja nie powiodła się, spróbuj ponownie."
override val available_for_paid: String = "Synchronizacja w chmurze jest dostępna tylko w ramach płatnej subskrypcji."
override val backups_option: String = "Kopie zapasowe"
override val balance: String = "saldo"
override val bank_account: String = "Konto Bankowe"
override val bill_number: String = "Numer rachunku"
override val cancel: String = "Anuluj"
override val cancel_trial: String = "Anuluj w dowolnym momencie"
override val capital: String = "kapitał"
override val cash: String = "gotówka"
override val categories: String = "Kategorie"
override val category: String = "Kategoria"
override val category_add: String = "Dodaj kategorię"
override val category_delete: String = "Usuń kategorię"
override val category_name: String = "Nazwa kategorii"
override val change_language: Formattable = Formattable { (language) -> "Język faktury: ${language}" }
override val change_language_label: String = "Język Faktury"
override val change_template: String = "Zmiana szablonu"
override val city_hint: String = "Miasto"
override val clear: String = "Jasne"
override val client: String = "Klient"
override val client_actions: String = "Działania klienta"
override val client_deleted_message: String = "Klient usunięty"
override val clients_error_required_fields: String = "Wymagana jest co najmniej jedna wartość."
override val clients_option: String = "Klienci"
override val close: String = "Zamknij"
override val cloud_sync: String = "Synchronizacja w chmurze"
override val coming_soon: String = "Już wkrótce!"
override val company_logo: String = "Logo firmy"
override val company_name_hint: String = "Firma"
override val confirm_password_label: String = "Potwierdź hasło"
override val confirmation_title: String = "Czy jesteś pewien?"
override val convert_to_invoice: String = "Konwersja do faktury"
override val country_hint: String = "Kraj"
override val create_client: String = "Utwórz klienta"
override val create_company: String = "Utwórz firmę"
override val create_document: String = "Utwórz dokument"
override val create_estimate: String = "Oszacowanie"
override val create_invoice: String = "Faktura"
override val create_item: String = "Utwórz pozycję"
override val create_new_client: String = "Utwórz nowego klienta"
override val create_new_item: String = "Utwórz nowy element"
override val create_new_tax: String = "Tworzenie nowego podatku"
override val create_signature: String = "Podpis"
override val created: String = "utworzony"
override val credit: String = "kredytowa"
override val customer: String = "Klient"
override val date: String = "Data"
override val debit: String = "debetowa"
override val debt: String = "dług"
override val default_currency: Formattable = Formattable { (currency) -> "Domyślna waluta: ${currency}" }
override val default_currency_label: String = "Domyślna waluta"
override val delete: String = "Usuń"
override val delete_account: String = "Usunąć Konto"
override val delete_confirmation_message: String = "Wszystkie Twoje dane zostaną utracone."
override val delete_item_title: String = "Usunąć element?"
override val delete_signature: String = "Usunąć podpis"
override val depreciation: String = "deprecjacja"
override val description: String = "Opis"
override val description_hint: String = "Opis"
override val detailed_report: String = "Raport Szczegółowy"
override val disabled: String = "Niepełnosprawni"
override val discount: String = "Rabat"
override val discount_hint: String = "Rabat"
override val display_af: String = "Język afrikaans"
override val display_am: String = "Amharic"
override val display_ar: String = "Arabski"
override val display_as: String = "Assamese"
override val display_az: String = "Azerbejdżan"
override val display_be: String = "Białoruski"
override val display_bg: String = "Bułgarski"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bośniacki"
override val display_ca: String = "Kataloński"
override val display_cs: String = "Czeski"
override val display_da: String = "duński"
override val display_de: String = "Niemiecki"
override val display_el: String = "Grecki"
override val display_en: String = "English"
override val display_es: String = "Hiszpański"
override val display_es_MX: String = "Język hiszpański (Meksyk)"
override val display_et: String = "Estoński"
override val display_eu: String = "Baskijski"
override val display_fa: String = "Perski"
override val display_fi: String = "Finlandia"
override val display_fil: String = "Filipina"
override val display_fr: String = "Francuski"
override val display_fr_CA: String = "Kanadyjski francuski"
override val display_gl: String = "Galicyjska"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hebrajski"
override val display_hi: String = "Hindi"
override val display_hr: String = "Chorwacki"
override val display_hu: String = "Węgierski"
override val display_hy: String = "Ormiańska"
override val display_id: String = "Indonezyjski"
override val display_is: String = "Islandzki"
override val display_it: String = "Włoski"
override val display_ja: String = "Japońska"
override val display_ka: String = "Gruzińska"
override val display_kk: String = "Kazachski"
override val display_km: String = "Centralny Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Koreański"
override val display_ky: String = "Kirgiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Litwa"
override val display_lv: String = "Łotwa"
override val display_mk: String = "Macedońska"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolska"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malaj"
override val display_my: String = "Birmańska"
override val display_nb: String = "Norweski Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Niderlandzki"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polski"
override val display_pt: String = "Portugalski"
override val display_pt_BR: String = "portugalski (Brazylia)"
override val display_pt_PT: String = "Europejski język portugalski"
override val display_ro: String = "Rumuński"
override val display_ru: String = "Rosyjski"
override val display_si: String = "Sinhala"
override val display_sk: String = "Słowacki"
override val display_sl: String = "Słowenia"
override val display_sq: String = "albański"
override val display_sr: String = "Serbski"
override val display_sv: String = "Szwedzki"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Tajlandia"
override val display_tr: String = "Turecki"
override val display_uk: String = "Ukraiński"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbek"
override val display_vi: String = "Wietnamski"
override val display_zh_Hant_TW: String = "Chiński (Tajwan, Tradycyjny)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Oszacowanie"
override val document_invoice: String = "Faktura"
override val document_quote: String = "Oferta"
override val done: String = "Gotowe"
override val download_app: String = "Pobierz aplikację"
override val download_invoice: String = "Pobierz Fakturę"
override val download_pdf: String = "Pobierz PDF"
override val draw_signature: String = "Narysuj podpis"
override val due: Formattable = Formattable { (date) -> "Termin płatności ${date}" }
override val due_date: String = "Termin płatności"
override val due_day: String = "w ciągu jednego dnia"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} jest należny ${dueWhen}!" }
override val due_today: String = "dzisiaj"
override val due_week: String = "w ciągu tygodnia"
override val duplicate: String = "Duplikuj"
override val duration_day: String = "dzień"
override val duration_month: String = "Miesiąc"
override val duration_week: String = "tydzień"
override val duration_year: String = "rok"
override val email: String = "E-mail"
override val email_blank_error: String = "Wymagany adres e-mail."
override val email_error: String = "Nieprawidłowy adres e-mail."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Dziękujemy za Twój biznes. Aby zobaczyć pełną wersję ${documentType} kliknij przycisk na końcu tego listu. Pamiętaj, że jest ważny do ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Otrzymałeś nowy dokument: ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "WIDZENIE ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Drogi ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Dziękujemy za Twój biznes. Aby zobaczyć pełną wersję ${documentType} kliknij przycisk na końcu tego listu. Prosimy o zapłatę salda do dnia ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Otrzymałeś nowy dokument: ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Email do zresetowania hasła został wysłany, proszę sprawdzić skrzynkę odbiorczą."
override val empty_clients_list_message: String = "Nie masz klientów do śledzenia. Dodaj nowego klienta."
override val empty_estimate_list_message: String = "Stwórz swój pierwszy kosztorys"
override val empty_expenses: String = "Nie masz żadnych wydatków do śledzenia. Dodaj nowy wydatek."
override val empty_invoice_list_message: String = "Utwórz swoją pierwszą fakturę"
override val empty_items_list_message: String = "Nie masz żadnych przedmiotów, dodaj nowy przedmiot!"
override val empty_signatures_list_message: String = "Nie znaleziono żadnych podpisów. Stwórz jedną!"
override val empty_taxes_list_message: String = "Nie masz żadnych reguł podatkowych. Dodaj nową regułę podatkową."
override val empty_templates_list_message: String = "Szablony niedostępne."
override val enabled: String = "Włączone"
override val entry: String = "wpis"
override val equity: String = "kapitał własny"
override val estimate_number: String = "Szacunek #"
override val estimates: String = "Szacunki"
override val estimates_report: String = "Szacunki"
override val expense_bank_fees: String = "Opłaty bankowe"
override val expense_benefits: String = "Korzyści"
override val expense_communication: String = "Komunikacja"
override val expense_debt: String = "Dług"
override val expense_deleted: String = "Usunięte wydatki"
override val expense_depreciation: String = "Amortyzacja"
override val expense_gifts: String = "Prezenty"
override val expense_insurance: String = "Ubezpieczenia"
override val expense_inventory: String = "Inwentaryzacja"
override val expense_legal: String = "Prawna"
override val expense_maintenance: String = "Konserwacja"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Posiłki"
override val expense_office_supplies: String = "Materiały biurowe"
override val expense_rent: String = "Czynsz"
override val expense_salaries: String = "Wynagrodzenia"
override val expense_shopping: String = "Sklep"
override val expense_software: String = "Oprogramowanie"
override val expense_status_available: String = "Dostępne"
override val expense_status_processing: String = "Przetwarzanie"
override val expense_tax: String = "Podatek"
override val expense_training: String = "Szkolenie"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Podróże"
override val expense_utility: String = "Użyteczność"
override val expenses: String = "Wydatki"
override val feedback_option: String = "Informacje zwrotne"
override val filter: String = "Filtr"
override val first_name_hint: String = "Imię"
override val forgot_password: String = "Resetowanie hasła"
override val forgot_password_label: String = "Nie pamiętam hasła"
override val from: String = "z"
override val general_error: String = "Wystąpił błąd, proszę spróbować ponownie później."
override val general_ledger: String = "księga główna"
override val go_back_label: String = "Wróć"
override val group_by: String = "Grupa By:"
override val image_view: String = "Obraz"
override val install: String = "Zainstaluj"
override val invalid_credentials_error: String = "Nieprawidłowe dane uwierzytelniające."
override val invalid_email_error: String = "Nieprawidłowy adres e-mail."
override val invalid_user_error: String = "Nieprawidłowe dane uwierzytelniające użytkownika."
override val invoice_deleted_message: String = "Faktura usunięta"
override val invoice_menu: String = "Menu Fakturowanie"
override val invoice_number: String = "Faktura #"
override val invoices: String = "Faktury"
override val items_option: String = "Przedmioty"
override val keep: String = "Zachowaj"
override val language_selector: Formattable = Formattable { (wybrany) -> "Język ${wybrany}" }
override val last_name_hint: String = "Nazwisko"
override val ledger: String = "księga rachunkowa"
override val legal: String = "Prawna"
override val legal_error: String = "Musi zaakceptować Warunki korzystania z usługi i Politykę prywatności"
override val liabilities: String = "zobowiązania"
override val loading: String = "Ładowanie"
override val login_required_error: String = "Wymagane jest zalogowanie się."
override val logo_view: String = "Logo"
override val logout: String = "Wyloguj się"
override val logout_prompt: String = "Twoje dane nie są zsynchronizowane z chmurą. Usuwać faktury, kosztorysy, klientów i inne informacje po wylogowaniu?"
override val main_screen: String = "Ekran główny"
override val mark_paid: String = "Zaznacz jako opłacone"
override val message: String = "Wiadomość"
override val missing_sender_msg: String = "Dokument bez informacji o nadawcy może być nieprawidłowy."
override val missing_sender_title: String = "Brakujące informacje o nadawcy"
override val month_april: String = "Kwiecień"
override val month_april_short: String = "Kwiecień"
override val month_august: String = "Sierpień"
override val month_august_short: String = "Sie."
override val month_december: String = "Grudzień"
override val month_december_short: String = "Gru."
override val month_february: String = "Luty"
override val month_february_short: String = "Luty"
override val month_january: String = "Styczeń"
override val month_january_short: String = "Sty."
override val month_july: String = "Lipiec"
override val month_july_short: String = "Lipiec"
override val month_june: String = "Czerwiec"
override val month_june_short: String = "Czerwiec"
override val month_march: String = "Marzec"
override val month_march_short: String = "Marzec"
override val month_may: String = "Maj"
override val month_may_short: String = "Maj"
override val month_november: String = "Listopad"
override val month_november_short: String = "Lis."
override val month_october: String = "Październik"
override val month_october_short: String = "Paź."
override val month_september: String = "Wrzesień"
override val month_september_short: String = "Wrz."
override val multifactor_error: String = "Nie udało się zweryfikować."
override val new: String = "Nowość"
override val news: String = "Wiadomości"
override val next: String = "Następny"
override val no: String = "nie"
override val no_data_available: String = "Brak dostępnych danych"
override val no_search_results: String = "Niestety, nie udało nam się znaleźć żadnych wyników."
override val none: String = "Brak"
override val note_fields: String = "Uwagi"
override val note_hint: String = "Uwaga"
override val notifications_option: String = "powiadomienia"
override val ok: String = "OK"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Zasadnicze części DingVoice są zawsze bezpłatne. Naszą misją jest pomóc Ci rozwinąć Twój biznes, pomóż nam się poprawić dzieląc się swoimi uwagami."
override val onboarding_aboutus_title: String = "Jesteśmy tu, aby pomóc"
override val onboarding_paid_1: String = "Dostęp do profesjonalnie wyglądających szablonów"
override val onboarding_paid_2: String = "Przyjrzyj się dokładniej swoim finansom"
override val onboarding_paid_3: String = "Szybkie rozwiązywanie problemów"
override val onboarding_paid_4: String = "Zabezpiecz swoje dokumenty"
override val onboarding_paid_5: String = "Bądź na bieżąco ze zmianami statusu faktury"
override val onboarding_paid_title: String = "Rozwijaj się jeszcze szybciej z..."
override val onboarding_paywall_message: String = "Ciesz się wszystkimi funkcjami premium teraz w najlepszej cenie."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Wystarczy ${currencySymbol}${introAmount} za ${introDuration}${introPeriod}, a następnie ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Tylko ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Darmowy przez ${trialDuration} ${trialPeriod}, a potem już tylko ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Bezpłatnie dla ${trialDuration} ${trialPeriod}, następnie ${currencySymbol}${introductoryAmount} dla ${introductoryDuration} ${introPeriod}, a potem już tylko ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Samoodnawialny. Anuluj w dowolnym momencie."
override val onboarding_paywall_restore: String = "Przywrócenie zakupu"
override val onboarding_paywall_restore_failure: String = "Przywracanie zakupów nie powiodło się, spróbuj ponownie później lub skontaktuj się z pomocą techniczną."
override val onboarding_paywall_restore_success: String = "Przywrócenie udane!"
override val onboarding_paywall_title: String = "Osiągnij wielkość!"
override val onboarding_welcome_message: String = "Kompletny zestaw narzędzi do zarządzania fakturami i kosztorysami."
override val onboarding_welcome_title: String = "Witamy w DingVoice"
override val other_state: String = "Inne"
override val overdue_state: String = "Zaległe"
override val paid_state: String = "Płatne"
override val password: String = "Hasło"
override val password_blank_error: String = "Wymagane hasło."
override val password_mismatch_error: String = "Hasła nie pasują do siebie."
override val pay: String = "zapłać"
override val payment_instructions_label: String = "Instrukcje dotyczące płatności"
override val payment_reminders: String = "Przypomnienia o płatnościach"
override val phone_number_fields: String = "Numer telefonu"
override val phone_number_hint: String = "Numer telefonu"
override val postcode_hint: String = "Kod pocztowy"
override val preview: String = "Podgląd"
override val preview_menu_hint: String = "Menu podglądu"
override val price: String = "Cena"
override val privacy_policy: String = "Polityka prywatności"
override val profile: String = "Profil"
override val profitable_client: String = "Twoim najbardziej dochodowym klientem jest..."
override val profits_report: String = "Zyski"
override val promo_content: String = "Treści promocyjne"
override val quantity_hint: String = "Ilość"
override val quantity_label_hint: String = "Ilość Etykieta"
override val query_info: String = "Query pozwala na pisanie własnych obliczeń odnoszących się zarówno do pozycji jak i innych reguł podatkowych jako danych wejściowych. Dodaj symbol @ i opis pozycji lub podatku, aby połączyć się z innym wpisem +, -, *, /, % - operatory matematyczne"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} został odebrany przez Twojego klienta." }
override val received_state: String = "Otrzymane"
override val registration_code_fields: String = "Kod Rejestracyjny"
override val reminder: String = "Przypomnienie"
override val reports: String = "Raporty"
override val reports_not_available: String = "Brak dostępnych raportów, wróć później."
override val reveal_password: String = "Ujawnij hasło"
override val revenue: String = "przychody"
override val sales_tax: String = "VAT"
override val sales_tax_number: String = "Numer VAT"
override val save: String = "Zapisz"
override val scan_document: String = "Skanowanie dokumentu"
override val search: String = "Szukaj"
override val select: String = "Wybierz"
override val select_icon: String = "Wybierz ikonę"
override val select_signature: String = "Wybierz podpis"
override val select_template: String = "Wybierz szablon"
override val send: String = "Wyślij"
override val sent_state: String = "Wysłane"
override val setup_sender: String = "Ustawienie nadawcy"
override val share_option: String = "Udostępnij"
override val ship_to: String = "Wysyłka do"
override val sign_in: String = "Zaloguj się"
override val sign_in_apple: String = "Zaloguj się za pomocą Apple"
override val sign_in_email: String = "Zaloguj się za pomocą poczty elektronicznej"
override val sign_in_facebook: String = "Zaloguj się za pomocą Facebooka"
override val sign_in_google: String = "Zaloguj się w Google"
override val sign_up: String = "Zarejestruj się"
override val sign_up_label: String = "Nie masz konta?"
override val sign_up_link_label: String = "Zarejestruj się!"
override val signatures_option: String = "Podpisy"
override val skip: String = "Skip"
override val sold_items_report: String = "Sprzedane Przedmioty"
override val sort_by: String = "Sort By:"
override val status_update: String = "Aktualizacja statusu dokumentu"
override val sub_annual: String = "coroczny"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/rok" }
override val sub_backups: String = "Kopie zapasowe w chmurze"
override val sub_insights: String = "Insights"
override val sub_month: String = "Miesięcznie"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/miesiąc" }
override val sub_notifications: String = "Powiadomienia"
override val sub_popup_message: String = "Wkrótce pojawią się subskrypcje. Za jakiś czas zajrzyjcie tu ponownie."
override val sub_support: String = "Wsparcie"
override val sub_templates: String = "Szablony"
override val subscribe: String = "Subskrybuj"
override val subscription_header: String = "Rozwijaj swoją działalność dzięki:"
override val subscription_option: String = "Abonament"
override val subscriptions_load_fail: String = "Przykro nam, ale w tej chwili nie możemy odzyskać Twoich subskrypcji. Prosimy spróbować ponownie później lub skontaktować się z naszym zespołem wsparcia w celu uzyskania pomocy."
override val subtotal: Formattable = Formattable { (sum) -> "Suma częściowa: ${sum}" }
override val subtotal_label: String = "Suma cząstkowa"
override val sum: String = "Suma"
override val support_option: String = "Wsparcie"
override val switch_reports: String = "Przełącz Raport"
override val tax: String = "Podatek"
override val tax_deleted_message: String = "Skreślony podatek"
override val tax_description_warning: String = "W przypadku użycia jest to widoczne na fakturze"
override val tax_input_flat: String = "Płaska"
override val tax_input_percent: String = "Procent"
override val tax_number_fields: String = "Numer podatkowy"
override val tax_number_hint: String = "Numer podatkowy"
override val tax_query_info_description: String = "Informacje o zapytaniu"
override val tax_report: String = "Podatek"
override val tax_rules_option: String = "Zasady podatkowe"
override val taxed_clients_report: String = "Klienci opodatkowani"
override val template: String = "Szablon"
override val template_selector_info: String = "Przesuń palcem, aby zmienić domyślny szablon"
override val template_unlock_promo: String = "Dostęp do większej ilości szablonów dzięki usłudze Premium"
override val templates_option: String = "Szablony"
override val terms_of_service: String = "Warunki korzystania z usługi"
override val thank_you: String = "Dziękujemy"
override val to: String = "do"
override val total: Formattable = Formattable { (sum) -> "Razem: ${sum}" }
override val total_label: String = "Ogółem"
override val undo_action: String = "Undo"
override val unit: String = "Jednostka"
override val unit_cost_hint: String = "Koszt jednostkowy"
override val unknown_client: String = "Nieznany Klient"
override val unlock: String = "Odblokuj"
override val unnamed_item: String = "Element bez nazwy"
override val upgrade: String = "Aktualizacja"
override val user_collision_error: String = "Konto już istnieje."
override val valid_until: String = "Ważne Do"
override val warning: String = "Ostrzeżenie!"
override val yes: String = "tak"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Zaakceptuj ${termsOfService} i ${privacyPolicy}" },
	"account" to "konto",
	"accounting" to "rachunkowość",
	"accruals" to "rozliczenia międzyokresowe",
	"action_code_error" to "Nieprawidłowy kod.",
	"add_client_action" to "Dodaj klienta",
	"add_customer" to "Dodaj klienta",
	"add_expense" to "Dodaj wydatek",
	"add_fields_label" to "Dodaj:",
	"add_invoice_action" to "Utwórz stronę",
	"add_item_action" to "Dodaj pozycję",
	"add_item_button" to "Dodaj pozycję",
	"add_signature_action" to "Dodaj podpis",
	"add_tax_action" to "Dodaj podatek",
	"address_fields" to "Adres",
	"address_line_hint" to "Linia adresowa",
	"advanced" to "ZAAWANSOWANY",
	"agree_marketing" to "Wyrażam zgodę na otrzymywanie komunikatów marketingu bezpośredniego",
	"all_notifications" to "Wszystkie powiadomienia",
	"amortization" to "amortyzacja",
	"amount" to "Kwota",
	"asset" to "aktywa",
	"attach_file" to "Załącz plik",
	"attachment" to "Załącznik",
	"auth_error" to "Akcja nie powiodła się, spróbuj ponownie.",
	"available_for_paid" to "Synchronizacja w chmurze jest dostępna tylko w ramach płatnej subskrypcji.",
	"backups_option" to "Kopie zapasowe",
	"balance" to "saldo",
	"bank_account" to "Konto Bankowe",
	"bill_number" to "Numer rachunku",
	"cancel" to "Anuluj",
	"cancel_trial" to "Anuluj w dowolnym momencie",
	"capital" to "kapitał",
	"cash" to "gotówka",
	"categories" to "Kategorie",
	"category" to "Kategoria",
	"category_add" to "Dodaj kategorię",
	"category_delete" to "Usuń kategorię",
	"category_name" to "Nazwa kategorii",
	"change_language" to Formattable { (language) -> "Język faktury: ${language}" },
	"change_language_label" to "Język Faktury",
	"change_template" to "Zmiana szablonu",
	"city_hint" to "Miasto",
	"clear" to "Jasne",
	"client" to "Klient",
	"client_actions" to "Działania klienta",
	"client_deleted_message" to "Klient usunięty",
	"clients_error_required_fields" to "Wymagana jest co najmniej jedna wartość.",
	"clients_option" to "Klienci",
	"close" to "Zamknij",
	"cloud_sync" to "Synchronizacja w chmurze",
	"coming_soon" to "Już wkrótce!",
	"company_logo" to "Logo firmy",
	"company_name_hint" to "Firma",
	"confirm_password_label" to "Potwierdź hasło",
	"confirmation_title" to "Czy jesteś pewien?",
	"convert_to_invoice" to "Konwersja do faktury",
	"country_hint" to "Kraj",
	"create_client" to "Utwórz klienta",
	"create_company" to "Utwórz firmę",
	"create_document" to "Utwórz dokument",
	"create_estimate" to "Oszacowanie",
	"create_invoice" to "Faktura",
	"create_item" to "Utwórz pozycję",
	"create_new_client" to "Utwórz nowego klienta",
	"create_new_item" to "Utwórz nowy element",
	"create_new_tax" to "Tworzenie nowego podatku",
	"create_signature" to "Podpis",
	"created" to "utworzony",
	"credit" to "kredytowa",
	"customer" to "Klient",
	"date" to "Data",
	"debit" to "debetowa",
	"debt" to "dług",
	"default_currency" to Formattable { (currency) -> "Domyślna waluta: ${currency}" },
	"default_currency_label" to "Domyślna waluta",
	"delete" to "Usuń",
	"delete_account" to "Usunąć Konto",
	"delete_confirmation_message" to "Wszystkie Twoje dane zostaną utracone.",
	"delete_item_title" to "Usunąć element?",
	"delete_signature" to "Usunąć podpis",
	"depreciation" to "deprecjacja",
	"description" to "Opis",
	"description_hint" to "Opis",
	"detailed_report" to "Raport Szczegółowy",
	"disabled" to "Niepełnosprawni",
	"discount" to "Rabat",
	"discount_hint" to "Rabat",
	"display_af" to "Język afrikaans",
	"display_am" to "Amharic",
	"display_ar" to "Arabski",
	"display_as" to "Assamese",
	"display_az" to "Azerbejdżan",
	"display_be" to "Białoruski",
	"display_bg" to "Bułgarski",
	"display_bn" to "Bengali",
	"display_bs" to "Bośniacki",
	"display_ca" to "Kataloński",
	"display_cs" to "Czeski",
	"display_da" to "duński",
	"display_de" to "Niemiecki",
	"display_el" to "Grecki",
	"display_en" to "English",
	"display_es" to "Hiszpański",
	"display_es_MX" to "Język hiszpański (Meksyk)",
	"display_et" to "Estoński",
	"display_eu" to "Baskijski",
	"display_fa" to "Perski",
	"display_fi" to "Finlandia",
	"display_fil" to "Filipina",
	"display_fr" to "Francuski",
	"display_fr_CA" to "Kanadyjski francuski",
	"display_gl" to "Galicyjska",
	"display_gu" to "Gujarati",
	"display_he" to "Hebrajski",
	"display_hi" to "Hindi",
	"display_hr" to "Chorwacki",
	"display_hu" to "Węgierski",
	"display_hy" to "Ormiańska",
	"display_id" to "Indonezyjski",
	"display_is" to "Islandzki",
	"display_it" to "Włoski",
	"display_ja" to "Japońska",
	"display_ka" to "Gruzińska",
	"display_kk" to "Kazachski",
	"display_km" to "Centralny Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Koreański",
	"display_ky" to "Kirgiz",
	"display_lo" to "Lao",
	"display_lt" to "Litwa",
	"display_lv" to "Łotwa",
	"display_mk" to "Macedońska",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolska",
	"display_mr" to "Marathi",
	"display_ms" to "Malaj",
	"display_my" to "Birmańska",
	"display_nb" to "Norweski Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Niderlandzki",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polski",
	"display_pt" to "Portugalski",
	"display_pt_BR" to "portugalski (Brazylia)",
	"display_pt_PT" to "Europejski język portugalski",
	"display_ro" to "Rumuński",
	"display_ru" to "Rosyjski",
	"display_si" to "Sinhala",
	"display_sk" to "Słowacki",
	"display_sl" to "Słowenia",
	"display_sq" to "albański",
	"display_sr" to "Serbski",
	"display_sv" to "Szwedzki",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Tajlandia",
	"display_tr" to "Turecki",
	"display_uk" to "Ukraiński",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbek",
	"display_vi" to "Wietnamski",
	"display_zh_Hant_TW" to "Chiński (Tajwan, Tradycyjny)",
	"display_zu" to "Zulu",
	"document_estimate" to "Oszacowanie",
	"document_invoice" to "Faktura",
	"document_quote" to "Oferta",
	"done" to "Gotowe",
	"download_app" to "Pobierz aplikację",
	"download_invoice" to "Pobierz Fakturę",
	"download_pdf" to "Pobierz PDF",
	"draw_signature" to "Narysuj podpis",
	"due" to Formattable { (date) -> "Termin płatności ${date}" },
	"due_date" to "Termin płatności",
	"due_day" to "w ciągu jednego dnia",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} jest należny ${dueWhen}!" },
	"due_today" to "dzisiaj",
	"due_week" to "w ciągu tygodnia",
	"duplicate" to "Duplikuj",
	"duration_day" to "dzień",
	"duration_month" to "Miesiąc",
	"duration_week" to "tydzień",
	"duration_year" to "rok",
	"email" to "E-mail",
	"email_blank_error" to "Wymagany adres e-mail.",
	"email_error" to "Nieprawidłowy adres e-mail.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Dziękujemy za Twój biznes. Aby zobaczyć pełną wersję ${documentType} kliknij przycisk na końcu tego listu. Pamiętaj, że jest ważny do ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Otrzymałeś nowy dokument: ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "WIDZENIE ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Drogi ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Dziękujemy za Twój biznes. Aby zobaczyć pełną wersję ${documentType} kliknij przycisk na końcu tego listu. Prosimy o zapłatę salda do dnia ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Otrzymałeś nowy dokument: ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Email do zresetowania hasła został wysłany, proszę sprawdzić skrzynkę odbiorczą.",
	"empty_clients_list_message" to "Nie masz klientów do śledzenia. Dodaj nowego klienta.",
	"empty_estimate_list_message" to "Stwórz swój pierwszy kosztorys",
	"empty_expenses" to "Nie masz żadnych wydatków do śledzenia. Dodaj nowy wydatek.",
	"empty_invoice_list_message" to "Utwórz swoją pierwszą fakturę",
	"empty_items_list_message" to "Nie masz żadnych przedmiotów, dodaj nowy przedmiot!",
	"empty_signatures_list_message" to "Nie znaleziono żadnych podpisów. Stwórz jedną!",
	"empty_taxes_list_message" to "Nie masz żadnych reguł podatkowych. Dodaj nową regułę podatkową.",
	"empty_templates_list_message" to "Szablony niedostępne.",
	"enabled" to "Włączone",
	"entry" to "wpis",
	"equity" to "kapitał własny",
	"estimate_number" to "Szacunek #",
	"estimates" to "Szacunki",
	"estimates_report" to "Szacunki",
	"expense_bank_fees" to "Opłaty bankowe",
	"expense_benefits" to "Korzyści",
	"expense_communication" to "Komunikacja",
	"expense_debt" to "Dług",
	"expense_deleted" to "Usunięte wydatki",
	"expense_depreciation" to "Amortyzacja",
	"expense_gifts" to "Prezenty",
	"expense_insurance" to "Ubezpieczenia",
	"expense_inventory" to "Inwentaryzacja",
	"expense_legal" to "Prawna",
	"expense_maintenance" to "Konserwacja",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Posiłki",
	"expense_office_supplies" to "Materiały biurowe",
	"expense_rent" to "Czynsz",
	"expense_salaries" to "Wynagrodzenia",
	"expense_shopping" to "Sklep",
	"expense_software" to "Oprogramowanie",
	"expense_status_available" to "Dostępne",
	"expense_status_processing" to "Przetwarzanie",
	"expense_tax" to "Podatek",
	"expense_training" to "Szkolenie",
	"expense_transport" to "Transport",
	"expense_travel" to "Podróże",
	"expense_utility" to "Użyteczność",
	"expenses" to "Wydatki",
	"feedback_option" to "Informacje zwrotne",
	"filter" to "Filtr",
	"first_name_hint" to "Imię",
	"forgot_password" to "Resetowanie hasła",
	"forgot_password_label" to "Nie pamiętam hasła",
	"from" to "z",
	"general_error" to "Wystąpił błąd, proszę spróbować ponownie później.",
	"general_ledger" to "księga główna",
	"go_back_label" to "Wróć",
	"group_by" to "Grupa By:",
	"image_view" to "Obraz",
	"install" to "Zainstaluj",
	"invalid_credentials_error" to "Nieprawidłowe dane uwierzytelniające.",
	"invalid_email_error" to "Nieprawidłowy adres e-mail.",
	"invalid_user_error" to "Nieprawidłowe dane uwierzytelniające użytkownika.",
	"invoice_deleted_message" to "Faktura usunięta",
	"invoice_menu" to "Menu Fakturowanie",
	"invoice_number" to "Faktura #",
	"invoices" to "Faktury",
	"items_option" to "Przedmioty",
	"keep" to "Zachowaj",
	"language_selector" to Formattable { (wybrany) -> "Język ${wybrany}" },
	"last_name_hint" to "Nazwisko",
	"ledger" to "księga rachunkowa",
	"legal" to "Prawna",
	"legal_error" to "Musi zaakceptować Warunki korzystania z usługi i Politykę prywatności",
	"liabilities" to "zobowiązania",
	"loading" to "Ładowanie",
	"login_required_error" to "Wymagane jest zalogowanie się.",
	"logo_view" to "Logo",
	"logout" to "Wyloguj się",
	"logout_prompt" to "Twoje dane nie są zsynchronizowane z chmurą. Usuwać faktury, kosztorysy, klientów i inne informacje po wylogowaniu?",
	"main_screen" to "Ekran główny",
	"mark_paid" to "Zaznacz jako opłacone",
	"message" to "Wiadomość",
	"missing_sender_msg" to "Dokument bez informacji o nadawcy może być nieprawidłowy.",
	"missing_sender_title" to "Brakujące informacje o nadawcy",
	"month_april" to "Kwiecień",
	"month_april_short" to "Kwiecień",
	"month_august" to "Sierpień",
	"month_august_short" to "Sie.",
	"month_december" to "Grudzień",
	"month_december_short" to "Gru.",
	"month_february" to "Luty",
	"month_february_short" to "Luty",
	"month_january" to "Styczeń",
	"month_january_short" to "Sty.",
	"month_july" to "Lipiec",
	"month_july_short" to "Lipiec",
	"month_june" to "Czerwiec",
	"month_june_short" to "Czerwiec",
	"month_march" to "Marzec",
	"month_march_short" to "Marzec",
	"month_may" to "Maj",
	"month_may_short" to "Maj",
	"month_november" to "Listopad",
	"month_november_short" to "Lis.",
	"month_october" to "Październik",
	"month_october_short" to "Paź.",
	"month_september" to "Wrzesień",
	"month_september_short" to "Wrz.",
	"multifactor_error" to "Nie udało się zweryfikować.",
	"new" to "Nowość",
	"news" to "Wiadomości",
	"next" to "Następny",
	"no" to "nie",
	"no_data_available" to "Brak dostępnych danych",
	"no_search_results" to "Niestety, nie udało nam się znaleźć żadnych wyników.",
	"none" to "Brak",
	"note_fields" to "Uwagi",
	"note_hint" to "Uwaga",
	"notifications_option" to "powiadomienia",
	"ok" to "OK",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Zasadnicze części DingVoice są zawsze bezpłatne. Naszą misją jest pomóc Ci rozwinąć Twój biznes, pomóż nam się poprawić dzieląc się swoimi uwagami.",
	"onboarding_aboutus_title" to "Jesteśmy tu, aby pomóc",
	"onboarding_paid_1" to "Dostęp do profesjonalnie wyglądających szablonów",
	"onboarding_paid_2" to "Przyjrzyj się dokładniej swoim finansom",
	"onboarding_paid_3" to "Szybkie rozwiązywanie problemów",
	"onboarding_paid_4" to "Zabezpiecz swoje dokumenty",
	"onboarding_paid_5" to "Bądź na bieżąco ze zmianami statusu faktury",
	"onboarding_paid_title" to "Rozwijaj się jeszcze szybciej z...",
	"onboarding_paywall_message" to "Ciesz się wszystkimi funkcjami premium teraz w najlepszej cenie.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Wystarczy ${currencySymbol}${introAmount} za ${introDuration}${introPeriod}, a następnie ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Tylko ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Darmowy przez ${trialDuration} ${trialPeriod}, a potem już tylko ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Bezpłatnie dla ${trialDuration} ${trialPeriod}, następnie ${currencySymbol}${introductoryAmount} dla ${introductoryDuration} ${introPeriod}, a potem już tylko ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Samoodnawialny. Anuluj w dowolnym momencie.",
	"onboarding_paywall_restore" to "Przywrócenie zakupu",
	"onboarding_paywall_restore_failure" to "Przywracanie zakupów nie powiodło się, spróbuj ponownie później lub skontaktuj się z pomocą techniczną.",
	"onboarding_paywall_restore_success" to "Przywrócenie udane!",
	"onboarding_paywall_title" to "Osiągnij wielkość!",
	"onboarding_welcome_message" to "Kompletny zestaw narzędzi do zarządzania fakturami i kosztorysami.",
	"onboarding_welcome_title" to "Witamy w DingVoice",
	"other_state" to "Inne",
	"overdue_state" to "Zaległe",
	"paid_state" to "Płatne",
	"password" to "Hasło",
	"password_blank_error" to "Wymagane hasło.",
	"password_mismatch_error" to "Hasła nie pasują do siebie.",
	"pay" to "zapłać",
	"payment_instructions_label" to "Instrukcje dotyczące płatności",
	"payment_reminders" to "Przypomnienia o płatnościach",
	"phone_number_fields" to "Numer telefonu",
	"phone_number_hint" to "Numer telefonu",
	"postcode_hint" to "Kod pocztowy",
	"preview" to "Podgląd",
	"preview_menu_hint" to "Menu podglądu",
	"price" to "Cena",
	"privacy_policy" to "Polityka prywatności",
	"profile" to "Profil",
	"profitable_client" to "Twoim najbardziej dochodowym klientem jest...",
	"profits_report" to "Zyski",
	"promo_content" to "Treści promocyjne",
	"quantity_hint" to "Ilość",
	"quantity_label_hint" to "Ilość Etykieta",
	"query_info" to "Query pozwala na pisanie własnych obliczeń odnoszących się zarówno do pozycji jak i innych reguł podatkowych jako danych wejściowych. Dodaj symbol @ i opis pozycji lub podatku, aby połączyć się z innym wpisem +, -, *, /, % - operatory matematyczne",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} został odebrany przez Twojego klienta." },
	"received_state" to "Otrzymane",
	"registration_code_fields" to "Kod Rejestracyjny",
	"reminder" to "Przypomnienie",
	"reports" to "Raporty",
	"reports_not_available" to "Brak dostępnych raportów, wróć później.",
	"reveal_password" to "Ujawnij hasło",
	"revenue" to "przychody",
	"sales_tax" to "VAT",
	"sales_tax_number" to "Numer VAT",
	"save" to "Zapisz",
	"scan_document" to "Skanowanie dokumentu",
	"search" to "Szukaj",
	"select" to "Wybierz",
	"select_icon" to "Wybierz ikonę",
	"select_signature" to "Wybierz podpis",
	"select_template" to "Wybierz szablon",
	"send" to "Wyślij",
	"sent_state" to "Wysłane",
	"setup_sender" to "Ustawienie nadawcy",
	"share_option" to "Udostępnij",
	"ship_to" to "Wysyłka do",
	"sign_in" to "Zaloguj się",
	"sign_in_apple" to "Zaloguj się za pomocą Apple",
	"sign_in_email" to "Zaloguj się za pomocą poczty elektronicznej",
	"sign_in_facebook" to "Zaloguj się za pomocą Facebooka",
	"sign_in_google" to "Zaloguj się w Google",
	"sign_up" to "Zarejestruj się",
	"sign_up_label" to "Nie masz konta?",
	"sign_up_link_label" to "Zarejestruj się!",
	"signatures_option" to "Podpisy",
	"skip" to "Skip",
	"sold_items_report" to "Sprzedane Przedmioty",
	"sort_by" to "Sort By:",
	"status_update" to "Aktualizacja statusu dokumentu",
	"sub_annual" to "coroczny",
	"sub_annual_price" to Formattable { (price) -> "${price}/rok" },
	"sub_backups" to "Kopie zapasowe w chmurze",
	"sub_insights" to "Insights",
	"sub_month" to "Miesięcznie",
	"sub_monthly_price" to Formattable { (price) -> "${price}/miesiąc" },
	"sub_notifications" to "Powiadomienia",
	"sub_popup_message" to "Wkrótce pojawią się subskrypcje. Za jakiś czas zajrzyjcie tu ponownie.",
	"sub_support" to "Wsparcie",
	"sub_templates" to "Szablony",
	"subscribe" to "Subskrybuj",
	"subscription_header" to "Rozwijaj swoją działalność dzięki:",
	"subscription_option" to "Abonament",
	"subscriptions_load_fail" to "Przykro nam, ale w tej chwili nie możemy odzyskać Twoich subskrypcji. Prosimy spróbować ponownie później lub skontaktować się z naszym zespołem wsparcia w celu uzyskania pomocy.",
	"subtotal" to Formattable { (sum) -> "Suma częściowa: ${sum}" },
	"subtotal_label" to "Suma cząstkowa",
	"sum" to "Suma",
	"support_option" to "Wsparcie",
	"switch_reports" to "Przełącz Raport",
	"tax" to "Podatek",
	"tax_deleted_message" to "Skreślony podatek",
	"tax_description_warning" to "W przypadku użycia jest to widoczne na fakturze",
	"tax_input_flat" to "Płaska",
	"tax_input_percent" to "Procent",
	"tax_number_fields" to "Numer podatkowy",
	"tax_number_hint" to "Numer podatkowy",
	"tax_query_info_description" to "Informacje o zapytaniu",
	"tax_report" to "Podatek",
	"tax_rules_option" to "Zasady podatkowe",
	"taxed_clients_report" to "Klienci opodatkowani",
	"template" to "Szablon",
	"template_selector_info" to "Przesuń palcem, aby zmienić domyślny szablon",
	"template_unlock_promo" to "Dostęp do większej ilości szablonów dzięki usłudze Premium",
	"templates_option" to "Szablony",
	"terms_of_service" to "Warunki korzystania z usługi",
	"thank_you" to "Dziękujemy",
	"to" to "do",
	"total" to Formattable { (sum) -> "Razem: ${sum}" },
	"total_label" to "Ogółem",
	"undo_action" to "Undo",
	"unit" to "Jednostka",
	"unit_cost_hint" to "Koszt jednostkowy",
	"unknown_client" to "Nieznany Klient",
	"unlock" to "Odblokuj",
	"unnamed_item" to "Element bez nazwy",
	"upgrade" to "Aktualizacja",
	"user_collision_error" to "Konto już istnieje.",
	"valid_until" to "Ważne Do",
	"warning" to "Ostrzeżenie!",
	"yes" to "tak",
    )
    
}