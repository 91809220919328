// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ta  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} மற்றும் ${privacyPolicy} ஆகியவற்றை ஏற்கவும்" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "செல்லுபடியாகாத குறியீடு."
override val add_client_action: String = "கிளையன்ட் சேர்"
override val add_customer: String = "வாடிக்கையாளரைச் சேர்க்கவும்"
override val add_expense: String = "செலவைச் சேர்க்கவும்"
override val add_fields_label: String = "சேர்:"
override val add_invoice_action: String = "படை"
override val add_item_action: String = "உருப்படியை சேர்"
override val add_item_button: String = "உருப்படியை சேர்"
override val add_signature_action: String = "கையொப்பம் சேர்"
override val add_tax_action: String = "வரி சேர்"
override val address_fields: String = "முகவரி"
override val address_line_hint: String = "முகவரி வரி"
override val advanced: String = "முற்போக்கான"
override val agree_marketing: String = "நேரடி சந்தைப்படுத்தல் தொடர்புகளைப் பெற ஒப்புக்கொள்கிறேன்"
override val all_notifications: String = "அனைத்து அறிவிப்புகளும்"
override val amortization: String = "amortization"
override val amount: String = "மொத்தம்"
override val asset: String = "asset"
override val attach_file: String = "கோப்பினை இணைக்கவும்"
override val attachment: String = "இணைப்பு"
override val auth_error: String = "செயல் தோல்வியுற்றது, மீண்டும் முயற்சிக்கவும்."
override val available_for_paid: String = "கிளவுட் ஒத்திசைவு கட்டண சந்தாவின் கீழ் மட்டுமே கிடைக்கிறது."
override val backups_option: String = "மறுபிரதிகள்"
override val balance: String = "balance"
override val bank_account: String = "பேங்க் அக்கவுண்ட்"
override val bill_number: String = "பில் எண்"
override val cancel: String = "ரத்து"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "வகைகள்"
override val category: String = "வகை"
override val category_add: String = "வகையைச் சேர்க்கவும்"
override val category_delete: String = "வகையை நீக்கு"
override val category_name: String = "வகை பெயர்"
override val change_language: Formattable = Formattable { (language) -> "விலைப்பட்டியல் மொழி: ${language}" }
override val change_language_label: String = "விலைப்பட்டியல் மொழி"
override val change_template: String = "வார்ப்புருவை மாற்று"
override val city_hint: String = "மாநகரம்"
override val clear: String = "தெளிந்த"
override val client: String = "கட்சிக்காரர்"
override val client_actions: String = "கிளையன்ட் செயல்கள்"
override val client_deleted_message: String = "கிளையன்ட் நீக்கப்பட்டது"
override val clients_error_required_fields: String = "குறைந்தபட்சம் ஒரு மதிப்பு தேவைப்படுகிறது."
override val clients_option: String = "பயனகங்களிடம்"
override val close: String = "அடைப்பு"
override val cloud_sync: String = "கிளவுட் ஒத்திசைவு"
override val coming_soon: String = "விரைவில் வருகிறது!"
override val company_logo: String = "நிறுவனத்தின் லோகோ"
override val company_name_hint: String = "மக்கள் கூட்டம்"
override val confirm_password_label: String = "கடவுச்சொல்லை உறுதிப்படுத்து"
override val confirmation_title: String = "நீ சொல்வது உறுதியா?"
override val convert_to_invoice: String = "விலைப்பட்டியலாக மாற்று"
override val country_hint: String = "நாடு"
override val create_client: String = "கிளையன்ட் உருவாக்கு"
override val create_company: String = "நிறுவனத்தை உருவாக்கு"
override val create_document: String = "ஆவணத்தை உருவாக்கு"
override val create_estimate: String = "மதிப்பீடு"
override val create_invoice: String = "விலைப்பட்டி"
override val create_item: String = "உருப்படியை உருவாக்கு"
override val create_new_client: String = "புதிய கிளையன்டை உருவாக்கு"
override val create_new_item: String = "புதிய உருப்படியை உருவாக்கு"
override val create_new_tax: String = "புதிய வரி உருவாக்கவும்"
override val create_signature: String = "கையொப்பம்"
override val created: String = "உருவாக்க"
override val credit: String = "credit"
override val customer: String = "வாடிக்கைக்காரர்"
override val date: String = "பேரீச்ச மரம்"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "இயல்புநிலை நாணயம்: ${currency}" }
override val default_currency_label: String = "இயல்புநிலை நாணயம்"
override val delete: String = "தீங்கான"
override val delete_account: String = "கணக்கை நீக்குக"
override val delete_confirmation_message: String = "உங்கள் தரவு அனைத்தும் இழக்கப்படும்."
override val delete_item_title: String = "உருப்படியை நீக்கு?"
override val delete_signature: String = "கையொப்பத்தை நீக்கு"
override val depreciation: String = "depreciation"
override val description: String = "விளக்கம்"
override val description_hint: String = "விரித்துரைத்தல்"
override val detailed_report: String = "விரிவான அறிக்கை"
override val disabled: String = "முடக்கப்பட்டுள்ளது"
override val discount: String = "தள்ளுபடி"
override val discount_hint: String = "கழிவு"
override val display_af: String = "ஆப்பிரிக்க"
override val display_am: String = "அம்ஹாரிக்"
override val display_ar: String = "அரபு"
override val display_as: String = "ஆசாமிகள்"
override val display_az: String = "அஜர்பைஜானி"
override val display_be: String = "பெலாரசியன்"
override val display_bg: String = "பல்கேரியன்"
override val display_bn: String = "பெங்காலி"
override val display_bs: String = "போஸ்னியன்"
override val display_ca: String = "கற்றலான்"
override val display_cs: String = "செக்"
override val display_da: String = "டேனிஷ்"
override val display_de: String = "ஜெர்மன்"
override val display_el: String = "கிரேக்கம்"
override val display_en: String = "ஆங்கிலம்"
override val display_es: String = "ஸ்பானிஷ்"
override val display_es_MX: String = "ஸ்பானிஷ் (மெக்சிகோ)"
override val display_et: String = "எஸ்டோனியன்"
override val display_eu: String = "பாஸ்க்"
override val display_fa: String = "பாரசீக"
override val display_fi: String = "பின்னிஷ்"
override val display_fil: String = "பிலிப்பைன்ஸ்"
override val display_fr: String = "பிரெஞ்சு"
override val display_fr_CA: String = "கனடிய பிரஞ்சு"
override val display_gl: String = "காலிசியன்"
override val display_gu: String = "குஜராத்தி"
override val display_he: String = "ஹீப்ரு"
override val display_hi: String = "இல்லை."
override val display_hr: String = "குரோஷியன்"
override val display_hu: String = "ஹங்கேரிய"
override val display_hy: String = "ஆர்மேனியன்"
override val display_id: String = "இந்தோனேஷியன்"
override val display_is: String = "ஐஸ்லாந்து"
override val display_it: String = "இத்தாலிய"
override val display_ja: String = "ஜப்பானியர்"
override val display_ka: String = "ஜார்ஜியன்"
override val display_kk: String = "கசாக்"
override val display_km: String = "மத்திய கெமர்"
override val display_kn: String = "கன்னடம்"
override val display_ko: String = "கொரிய"
override val display_ky: String = "கிர்கிஸ்"
override val display_lo: String = "தொழிலாளர்"
override val display_lt: String = "லிதுவேனியன்"
override val display_lv: String = "லாட்வியன்"
override val display_mk: String = "மாசிடோனியன்"
override val display_ml: String = "மலையாளம்"
override val display_mn: String = "மங்கோலியன்"
override val display_mr: String = "மராத்தி"
override val display_ms: String = "மலாய்"
override val display_my: String = "பர்மியர்"
override val display_nb: String = "நார்வே பொக்மால்"
override val display_ne: String = "நேபாளி"
override val display_nl: String = "டச்சு"
override val display_or: String = "ஒரியா"
override val display_pa: String = "பஞ்சாபி"
override val display_pl: String = "போலிஷ்"
override val display_pt: String = "போர்த்துகீசியம்"
override val display_pt_BR: String = "போர்த்துகீசியம் (பிரேசில்)"
override val display_pt_PT: String = "ஐரோப்பிய போர்த்துகீசியம்"
override val display_ro: String = "ரோமானியன்"
override val display_ru: String = "ரஷ்யன்"
override val display_si: String = "சிங்களம்"
override val display_sk: String = "ஸ்லோவாக்"
override val display_sl: String = "ஸ்லோவேனியன்"
override val display_sq: String = "அல்பேனியன்"
override val display_sr: String = "செர்பியன்"
override val display_sv: String = "ஸ்வீடிஷ்"
override val display_sw: String = "சுவாஹிலி"
override val display_ta: String = "தமிழ்"
override val display_te: String = "தெலுங்கு"
override val display_th: String = "தாய்"
override val display_tr: String = "துருக்கிய"
override val display_uk: String = "உக்ரைனியன்"
override val display_ur: String = "உருது"
override val display_uz: String = "உஸ்பெக்"
override val display_vi: String = "வியட்நாமியர்"
override val display_zh_Hant_TW: String = "சீனம் (தைவான், பாரம்பரியம்)"
override val display_zu: String = "ஜூலு"
override val document_estimate: String = "மதிப்பீடு"
override val document_invoice: String = "விலைப்பட்டி"
override val document_quote: String = "மேற்கோள்"
override val done: String = "முடிந்தது"
override val download_app: String = "பயன்பாட்டைப் பதிவிறக்கவும்"
override val download_invoice: String = "விலைப்பட்டியல் பதிவிறக்கவும்"
override val download_pdf: String = "Pdf ஐ பதிவிறக்கவும்"
override val draw_signature: String = "கையொப்பம் வரை"
override val due: Formattable = Formattable { (date) -> "நிலுவை ${date}" }
override val due_date: String = "கெடு தேதி"
override val due_day: String = "ஒரு நாளில்"
override val due_label: String = "நிலுவை"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} நிலுவைத் தொகை ${dueWhen}!" }
override val due_today: String = "இன்று"
override val due_week: String = "ஒரு வாரத்தில்"
override val duplicate: String = "நகல்"
override val duration_day: String = "நாள்"
override val duration_month: String = "மாதம்"
override val duration_week: String = "வாரம்"
override val duration_year: String = "ஆண்டு"
override val email: String = "மின்னஞ்சல்"
override val email_blank_error: String = "மின்னஞ்சல் தேவை."
override val email_error: String = "செல்லுபடியாகாத மின்னஞ்சல் முகவரி."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "உங்கள் வணிகத்திற்கு நன்றி. முழு ${documentType}ஐப் பார்க்க, இந்தக் கடிதத்தின் முடிவில் உள்ள பொத்தானைக் கிளிக் செய்யவும். இது ${dueDate} வரை செல்லுபடியாகும் என்பதை நினைவில் கொள்ளவும்." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "நீங்கள் புதியதைப் பெற்றுள்ளீர்கள்${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} ஐக் காண்க" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "அன்புள்ள ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "உங்கள் வணிகத்திற்கு நன்றி. முழு ${documentType}ஐப் பார்க்க, இந்தக் கடிதத்தின் முடிவில் உள்ள பொத்தானைக் கிளிக் செய்யவும். ${dueDate} வரை நிலுவைத் தொகையைச் செலுத்தவும்." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "நீங்கள் புதியதைப் பெற்றுள்ளீர்கள்${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "அனுப்பிய கடவுச்சொல்லை மீட்டமைக்க மின்னஞ்சல், உங்கள் இன்பாக்ஸை சரிபார்க்கவும்."
override val empty_clients_list_message: String = "நீங்கள் கண்காணிக்க வாடிக்கையாளர்கள் இல்லை. புதிய கிளையன்டைச் சேர்க்கவும்."
override val empty_estimate_list_message: String = "உங்கள் முதல் மதிப்பீட்டை உருவாக்கவும்"
override val empty_expenses: String = "நீங்கள் கண்காணிக்க எந்த செலவும் இல்லை. புதிய செலவைச் சேர்க்கவும்."
override val empty_invoice_list_message: String = "உங்கள் முதல் விலைப்பட்டியலை உருவாக்கவும்"
override val empty_items_list_message: String = "உங்களிடம் உருப்படிகள் இல்லை, புதிய உருப்படியைச் சேர்க்கவும்!"
override val empty_signatures_list_message: String = "கையொப்பங்கள் எதுவும் காணப்படவில்லை. ஒன்றை உருவாக்கு!"
override val empty_taxes_list_message: String = "உங்களிடம் வரி விதிகள் இல்லை. ஒரு புதிய வரி விதி சேர்க்கவும்."
override val empty_templates_list_message: String = "வார்ப்புருக்கள் கிடைக்கவில்லை."
override val enabled: String = "செயல்படுத்தப்பட்டது"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "மதிப்பீடு #"
override val estimates: String = "மதிப்பீடுகள்"
override val estimates_report: String = "மதிப்பீடுகள்"
override val expense_bank_fees: String = "வங்கி கட்டணம்"
override val expense_benefits: String = "நன்மைகள்"
override val expense_communication: String = "தொடர்பு"
override val expense_debt: String = "கடன்"
override val expense_deleted: String = "செலவு நீக்கப்பட்டது"
override val expense_depreciation: String = "தேய்மானம்"
override val expense_gifts: String = "பரிசுகள்"
override val expense_insurance: String = "காப்பீடு"
override val expense_inventory: String = "சரக்கு"
override val expense_legal: String = "சட்டபூர்வமானது"
override val expense_maintenance: String = "பராமரிப்பு"
override val expense_marketing: String = "சந்தைப்படுத்தல்"
override val expense_meals: String = "சாப்பாடு"
override val expense_office_supplies: String = "அலுவலக பொருட்கள்"
override val expense_rent: String = "வாடகை"
override val expense_salaries: String = "சம்பளம்"
override val expense_shopping: String = "கடையில் பொருட்கள் வாங்குதல்"
override val expense_software: String = "மென்பொருள்"
override val expense_status_available: String = "கிடைக்கும்"
override val expense_status_processing: String = "செயலாக்கம்"
override val expense_tax: String = "வரி"
override val expense_training: String = "பயிற்சி"
override val expense_transport: String = "போக்குவரத்து"
override val expense_travel: String = "பயணம்"
override val expense_utility: String = "பயன்பாடு"
override val expenses: String = "செலவுகள்"
override val feedback_option: String = "கருத்து"
override val filter: String = "வடிகட்டும் அமைவு"
override val first_name_hint: String = "முதல் பெயர்"
override val forgot_password: String = "கடவுச்சொல்லை மீட்டமை"
override val forgot_password_label: String = "கடவுச்சொல்லை மறந்துவிட்டது"
override val from: String = "இருந்து"
override val general_error: String = "பிழை ஏற்பட்டது, பிறகு முயற்சிக்கவும்."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "பின் செல்"
override val group_by: String = "குழு வாக்கு:"
override val image_view: String = "உருவம்"
override val install: String = "நிறுவுக"
override val invalid_credentials_error: String = "செல்லுபடியாகாத நம்பிக்கைச்சான்றுகள்."
override val invalid_email_error: String = "செல்லுபடியாகாத மின்னஞ்சல்."
override val invalid_user_error: String = "செல்லுபடியாகாத பயனர் நம்பிக்கைச்சான்றுகள்."
override val invoice_deleted_message: String = "விலைப்பட்டியல் நீக்கப்பட்டது"
override val invoice_menu: String = "விலைப்பட்டியல் மெனு"
override val invoice_number: String = "விலைப்பட்டி #"
override val invoices: String = "விலைப்பட்டியல்கள்"
override val items_option: String = "உருப்படிகளை"
override val keep: String = "காவற்கூண்டு"
override val language_selector: Formattable = Formattable { (selected) -> "மொழி ${selected}" }
override val last_name_hint: String = "கடைசி பெயர்"
override val ledger: String = "ledger"
override val legal: String = "சட்டஞ் சார்ந்த"
override val legal_error: String = "சேவை விதிமுறைகள் மற்றும் தனியுரிமைக் கொள்கையை ஏற்க வேண்டும்"
override val liabilities: String = "liabilities"
override val loading: String = "ஏற்றுகிறது"
override val login_required_error: String = "உள்நுழைவு தேவை."
override val logo_view: String = "சின்னம்"
override val logout: String = "வெளியேறு"
override val logout_prompt: String = "உங்கள் தரவு கிளவுட் உடன் ஒத்திசைக்கப்படவில்லை. உள்நுழைவு பற்றிய விலைப்பட்டியல்கள், மதிப்பீடுகள், வாடிக்கையாளர்கள் மற்றும் பிற தகவல்களை நீக்கவேண்டுமா?"
override val main_screen: String = "முதன்மை திரை"
override val mark_paid: String = "செலுத்தியதாக குறியிடு"
override val message: String = "செய்தி"
override val missing_sender_msg: String = "அனுப்புநரின் தகவல் இல்லாத ஆவணம் தவறானதாக இருக்கலாம்."
override val missing_sender_title: String = "அனுப்புநர் தகவல் இல்லை"
override val month_april: String = "ஏப்ரல்"
override val month_april_short: String = "ஏப்ரல்"
override val month_august: String = "ஆகஸ்ட்"
override val month_august_short: String = "ஆக."
override val month_december: String = "டிசம்பர்"
override val month_december_short: String = "டிச."
override val month_february: String = "பிப்ரவரி"
override val month_february_short: String = "பிப்."
override val month_january: String = "ஜனவரி"
override val month_january_short: String = "ஜன."
override val month_july: String = "ஜூலை"
override val month_july_short: String = "ஜூலை"
override val month_june: String = "ஜூன்"
override val month_june_short: String = "ஜூன்"
override val month_march: String = "மார்ச்"
override val month_march_short: String = "மார்ச்"
override val month_may: String = "மே"
override val month_may_short: String = "மே"
override val month_november: String = "நவம்பர்"
override val month_november_short: String = "நவ."
override val month_october: String = "அக்டோபர்"
override val month_october_short: String = "அக்."
override val month_september: String = "செப்டம்பர்"
override val month_september_short: String = "செப்."
override val multifactor_error: String = "சரிபார்க்க முடியவில்லை."
override val new: String = "புதியது"
override val news: String = "செய்தி"
override val next: String = "அடுத்தது"
override val no: String = "no"
override val no_data_available: String = "தரவு எதுவும் கிடைக்கவில்லை"
override val no_search_results: String = "மன்னிக்கவும், எங்களால் எந்த முடிவுகளையும் கண்டுபிடிக்க முடியவில்லை."
override val none: String = "இல்லை"
override val note_fields: String = "குறிப்புகள்"
override val note_hint: String = "தனிக்குரலிசை"
override val notifications_option: String = "அறிவிக்கைகள்"
override val ok: String = "சரி"
override val onboarding: String = "ஆன்போர்டிங்"
override val onboarding_aboutus_message: String = "DingVoice இன் அத்தியாவசிய பகுதிகள் எப்போதும் இலவசம். உங்கள் வணிகத்தை வளர்க்க உதவுவதும், உங்கள் கருத்தைப் பகிர்வதன் மூலம் மேம்படுத்த உதவுவதும் எங்கள் நோக்கம்."
override val onboarding_aboutus_title: String = "நாங்கள் உதவ இங்கே இருக்கிறோம்"
override val onboarding_paid_1: String = "தொழில்முறை தோற்றம் கொண்ட டெம்ப்ளேட்களை அணுகவும்"
override val onboarding_paid_2: String = "உங்கள் நிதிநிலையை ஆழமாகப் பாருங்கள்"
override val onboarding_paid_3: String = "பிரச்சனைகளை விரைவாக தீர்க்கவும்"
override val onboarding_paid_4: String = "உங்கள் ஆவணங்களை பாதுகாப்பாக வைத்திருங்கள்"
override val onboarding_paid_5: String = "இன்வாய்ஸ் நிலை மாற்றங்களுடன் புதுப்பித்த நிலையில் இருங்கள்"
override val onboarding_paid_title: String = "மேலும் வேகமாக வளர..."
override val onboarding_paywall_message: String = "அனைத்து பிரீமியம் அம்சங்களையும் இப்போது சிறந்த விலையில் அனுபவிக்கவும்."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} க்கு ${currencySymbol}${introAmount} பிறகு ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "வெறும் ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod}க்கு இலவசம், பிறகு ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "இலவசம்${trialDuration}${trialPeriod} பிறகு${currencySymbol}${introductoryAmount} க்கான${introductoryDuration}${introPeriod} பின்னர் தான்${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "தானாக புதுப்பிக்கக்கூடியது. எப்போது வேண்டுமானாலும் ரத்து செய்யலாம்."
override val onboarding_paywall_restore: String = "வாங்குதலை மீட்டெடுக்கவும்"
override val onboarding_paywall_restore_failure: String = "வாங்குதல்களை மீட்டெடுப்பது தோல்வியடைந்தது, பிறகு முயற்சிக்கவும் அல்லது ஆதரவைத் தொடர்புகொள்ளவும்."
override val onboarding_paywall_restore_success: String = "வெற்றிகரமாக மீட்டமை!"
override val onboarding_paywall_title: String = "மேன்மை அடைய!"
override val onboarding_welcome_message: String = "உங்கள் இன்வாய்ஸ்கள் மற்றும் மதிப்பீடுகளை நிர்வகிப்பதற்கான முழுமையான கருவித்தொகுப்பு."
override val onboarding_welcome_title: String = "DingVoice க்கு வரவேற்கிறோம்"
override val other_state: String = "இதர"
override val overdue_state: String = "நிலுவை"
override val paid_state: String = "பணம் செலுத்தியது"
override val password: String = "அடையாளச் சொல்"
override val password_blank_error: String = "கடவுச்சொல் தேவை."
override val password_mismatch_error: String = "கடவுச்சொற்கள் பொருந்தவில்லை."
override val pay: String = "pay"
override val payment_instructions_label: String = "கொடுப்பனவு அறிவுறுத்தல்கள்"
override val payment_reminders: String = "கட்டண நினைவூட்டல்கள்"
override val phone_number_fields: String = "தொலைபேசி எண்"
override val phone_number_hint: String = "தொலைபேசி எண்"
override val postcode_hint: String = "அஞ்சல் குறியீடு"
override val preview: String = "வெள்ளோட்டம்"
override val preview_menu_hint: String = "முன்னோட்ட மெனு"
override val price: String = "விலை"
override val privacy_policy: String = "தனியுரிமைக் கொள்கை"
override val profile: String = "பக்கத்தோற்ற வடிவம்"
override val profitable_client: String = "உங்கள் மிகவும் இலாபகரமான வாடிக்கையாளர்..."
override val profits_report: String = "இலாபங்கள்"
override val promo_content: String = "விளம்பர உள்ளடக்கம்"
override val quantity_hint: String = "அளவு"
override val quantity_label_hint: String = "அளவு லேபிள்"
override val query_info: String = "கேள்வி நீங்கள் உள்ளீடுகள் என உருப்படிகள் மற்றும் பிற வரி விதிகள் இருவரும் குறிப்பிடும் விருப்ப கணக்கீடுகள் எழுத அனுமதிக்கிறது. மற்றொரு உள்ளீடு +, -, *, /, % - கணித ஆபரேட்டர்களுடன் இணைக்க @ சின்னம் மற்றும் உருப்படி அல்லது வரி விளக்கத்தைச் சேர்க்கவும்"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} உங்கள் கிளையண்டால் பெறப்பட்டது." }
override val received_state: String = "பெற்றது"
override val registration_code_fields: String = "பதிவுக் குறியீடு"
override val reminder: String = "நினைவூட்டல்"
override val reports: String = "அறிக்கைகள்"
override val reports_not_available: String = "அறிக்கைகள் இல்லை, பிறகு வரவும்."
override val reveal_password: String = "கடவுச்சொல்லை வெளிப்படுத்து"
override val revenue: String = "revenue"
override val sales_tax: String = "வாட்"
override val sales_tax_number: String = "வாட் எண்"
override val save: String = "கெலிப்புத்தடங்கல்"
override val scan_document: String = "ஆவணத்தை ஸ்கேன் செய்யவும்"
override val search: String = "தேட்டம்"
override val select: String = "தேர்ந்தெடு"
override val select_icon: String = "ஐகானைத் தேர்ந்தெடுக்கவும்"
override val select_signature: String = "கையொப்பம் தேர்ந்தெடு"
override val select_template: String = "வார்ப்புருவை தேர்ந்தெடு"
override val send: String = "அனுப்பு"
override val sent_state: String = "அனுப்பிய"
override val setup_sender: String = "அனுப்புநரை அமைக்கவும்"
override val share_option: String = "பங்கு"
override val ship_to: String = "அனுப்பவும்"
override val sign_in: String = "உள்நுழைக"
override val sign_in_apple: String = "ஆப்பிள் உள்நுழைய"
override val sign_in_email: String = "மின்னஞ்சலுடன் உள்நுழைக"
override val sign_in_facebook: String = "பேஸ்புக் உள்நுழைய"
override val sign_in_google: String = "Gggpag"
override val sign_up: String = "பதிவுபெறு"
override val sign_up_label: String = "கணக்கு இல்லையா?"
override val sign_up_link_label: String = "பதிவுபெறு!"
override val signatures_option: String = "கையொப்பங்கள்"
override val skip: String = "தவிர்க்கவும்"
override val sold_items_report: String = "விற்கப்பட்ட பொருட்கள்"
override val sort_by: String = "வரிசைப்படுத்து:"
override val status_update: String = "ஆவண நிலை புதுப்பிப்பு"
override val sub_annual: String = "வருடாந்தம்"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price} /ஆண்டு" }
override val sub_backups: String = "கிளவுட் காப்புப்பிரதிகள்"
override val sub_insights: String = "உள்ளுணர்வை"
override val sub_month: String = "மாதந்தோறும்"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price} /மாதம்" }
override val sub_notifications: String = "Notifications, அறிவிப்புகள்"
override val sub_popup_message: String = "சந்தாக்கள் விரைவில் வருகின்றன. சிறிது நேரத்தில் இங்கே மீண்டும் சரிபார்க்கவும்."
override val sub_support: String = "ஆதரவு"
override val sub_templates: String = "டெம்ப்ளேட்கள்"
override val subscribe: String = "அடியில் எழுது"
override val subscription_header: String = "உங்கள் வணிக வளர:"
override val subscription_option: String = "அடியில் எழுதுதல்"
override val subscriptions_load_fail: String = "மன்னிக்கவும், இந்த நேரத்தில் உங்கள் சந்தாக்களை எங்களால் மீட்டெடுக்க முடியவில்லை. பிறகு முயற்சிக்கவும் அல்லது உதவிக்கு எங்கள் ஆதரவுக் குழுவைத் தொடர்பு கொள்ளவும்."
override val subtotal: Formattable = Formattable { (sum) -> "துணைமொத்தம்: ${sum}" }
override val subtotal_label: String = "கூட்டுத்தொகை"
override val sum: String = "தொகை"
override val support_option: String = "துணை"
override val switch_reports: String = "ஸ்விட்ச் அறிக்கை"
override val tax: String = "வரி"
override val tax_deleted_message: String = "வரி நீக்கப்பட்டது"
override val tax_description_warning: String = "இதைப் பயன்படுத்தினால் விலைப்பட்டியலில் காண்பிக்கப்படும்"
override val tax_input_flat: String = "அறைக்கட்டு"
override val tax_input_percent: String = "விழுக்காடு"
override val tax_number_fields: String = "வரி எண்"
override val tax_number_hint: String = "வரி எண்"
override val tax_query_info_description: String = "கேள்வி தகவல்"
override val tax_report: String = "வரி"
override val tax_rules_option: String = "வரி விதிகள்"
override val taxed_clients_report: String = "வரி விதிக்கப்பட்ட வாடிக்கையாளர்கள்"
override val template: String = "வார்ப்புரு"
override val template_selector_info: String = "இயல்புநிலை வார்ப்புருவை மாற்ற ஸ்வைப் செய்யவும்"
override val template_unlock_promo: String = "Premium மூலம் மேலும் டெம்ப்ளேட்களை அணுகவும்"
override val templates_option: String = "வார்ப்புரு"
override val terms_of_service: String = "சேவை விதிமுறைகள்"
override val thank_you: String = "நன்றி"
override val to: String = "நோக்கி"
override val total: Formattable = Formattable { (sum) -> "மொத்தம்: ${sum}" }
override val total_label: String = "மொத்தம்"
override val undo_action: String = "கெடு"
override val unit: String = "ஒன்ற"
override val unit_cost_hint: String = "அலகு செலவு"
override val unknown_client: String = "தெரியாத வாடிக்கையாளர்"
override val unlock: String = "திறக்கவும்"
override val unnamed_item: String = "பெயரிடப்படாத பொருள்"
override val upgrade: String = "மேல்நோக்கிய சாய்வு"
override val user_collision_error: String = "கணக்கு ஏற்கனவே உள்ளது."
override val valid_until: String = "வரை செல்லுபடியாகும்"
override val warning: String = "எச்சரிக்கை!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} மற்றும் ${privacyPolicy} ஆகியவற்றை ஏற்கவும்" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "செல்லுபடியாகாத குறியீடு.",
	"add_client_action" to "கிளையன்ட் சேர்",
	"add_customer" to "வாடிக்கையாளரைச் சேர்க்கவும்",
	"add_expense" to "செலவைச் சேர்க்கவும்",
	"add_fields_label" to "சேர்:",
	"add_invoice_action" to "படை",
	"add_item_action" to "உருப்படியை சேர்",
	"add_item_button" to "உருப்படியை சேர்",
	"add_signature_action" to "கையொப்பம் சேர்",
	"add_tax_action" to "வரி சேர்",
	"address_fields" to "முகவரி",
	"address_line_hint" to "முகவரி வரி",
	"advanced" to "முற்போக்கான",
	"agree_marketing" to "நேரடி சந்தைப்படுத்தல் தொடர்புகளைப் பெற ஒப்புக்கொள்கிறேன்",
	"all_notifications" to "அனைத்து அறிவிப்புகளும்",
	"amortization" to "amortization",
	"amount" to "மொத்தம்",
	"asset" to "asset",
	"attach_file" to "கோப்பினை இணைக்கவும்",
	"attachment" to "இணைப்பு",
	"auth_error" to "செயல் தோல்வியுற்றது, மீண்டும் முயற்சிக்கவும்.",
	"available_for_paid" to "கிளவுட் ஒத்திசைவு கட்டண சந்தாவின் கீழ் மட்டுமே கிடைக்கிறது.",
	"backups_option" to "மறுபிரதிகள்",
	"balance" to "balance",
	"bank_account" to "பேங்க் அக்கவுண்ட்",
	"bill_number" to "பில் எண்",
	"cancel" to "ரத்து",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "வகைகள்",
	"category" to "வகை",
	"category_add" to "வகையைச் சேர்க்கவும்",
	"category_delete" to "வகையை நீக்கு",
	"category_name" to "வகை பெயர்",
	"change_language" to Formattable { (language) -> "விலைப்பட்டியல் மொழி: ${language}" },
	"change_language_label" to "விலைப்பட்டியல் மொழி",
	"change_template" to "வார்ப்புருவை மாற்று",
	"city_hint" to "மாநகரம்",
	"clear" to "தெளிந்த",
	"client" to "கட்சிக்காரர்",
	"client_actions" to "கிளையன்ட் செயல்கள்",
	"client_deleted_message" to "கிளையன்ட் நீக்கப்பட்டது",
	"clients_error_required_fields" to "குறைந்தபட்சம் ஒரு மதிப்பு தேவைப்படுகிறது.",
	"clients_option" to "பயனகங்களிடம்",
	"close" to "அடைப்பு",
	"cloud_sync" to "கிளவுட் ஒத்திசைவு",
	"coming_soon" to "விரைவில் வருகிறது!",
	"company_logo" to "நிறுவனத்தின் லோகோ",
	"company_name_hint" to "மக்கள் கூட்டம்",
	"confirm_password_label" to "கடவுச்சொல்லை உறுதிப்படுத்து",
	"confirmation_title" to "நீ சொல்வது உறுதியா?",
	"convert_to_invoice" to "விலைப்பட்டியலாக மாற்று",
	"country_hint" to "நாடு",
	"create_client" to "கிளையன்ட் உருவாக்கு",
	"create_company" to "நிறுவனத்தை உருவாக்கு",
	"create_document" to "ஆவணத்தை உருவாக்கு",
	"create_estimate" to "மதிப்பீடு",
	"create_invoice" to "விலைப்பட்டி",
	"create_item" to "உருப்படியை உருவாக்கு",
	"create_new_client" to "புதிய கிளையன்டை உருவாக்கு",
	"create_new_item" to "புதிய உருப்படியை உருவாக்கு",
	"create_new_tax" to "புதிய வரி உருவாக்கவும்",
	"create_signature" to "கையொப்பம்",
	"created" to "உருவாக்க",
	"credit" to "credit",
	"customer" to "வாடிக்கைக்காரர்",
	"date" to "பேரீச்ச மரம்",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "இயல்புநிலை நாணயம்: ${currency}" },
	"default_currency_label" to "இயல்புநிலை நாணயம்",
	"delete" to "தீங்கான",
	"delete_account" to "கணக்கை நீக்குக",
	"delete_confirmation_message" to "உங்கள் தரவு அனைத்தும் இழக்கப்படும்.",
	"delete_item_title" to "உருப்படியை நீக்கு?",
	"delete_signature" to "கையொப்பத்தை நீக்கு",
	"depreciation" to "depreciation",
	"description" to "விளக்கம்",
	"description_hint" to "விரித்துரைத்தல்",
	"detailed_report" to "விரிவான அறிக்கை",
	"disabled" to "முடக்கப்பட்டுள்ளது",
	"discount" to "தள்ளுபடி",
	"discount_hint" to "கழிவு",
	"display_af" to "ஆப்பிரிக்க",
	"display_am" to "அம்ஹாரிக்",
	"display_ar" to "அரபு",
	"display_as" to "ஆசாமிகள்",
	"display_az" to "அஜர்பைஜானி",
	"display_be" to "பெலாரசியன்",
	"display_bg" to "பல்கேரியன்",
	"display_bn" to "பெங்காலி",
	"display_bs" to "போஸ்னியன்",
	"display_ca" to "கற்றலான்",
	"display_cs" to "செக்",
	"display_da" to "டேனிஷ்",
	"display_de" to "ஜெர்மன்",
	"display_el" to "கிரேக்கம்",
	"display_en" to "ஆங்கிலம்",
	"display_es" to "ஸ்பானிஷ்",
	"display_es_MX" to "ஸ்பானிஷ் (மெக்சிகோ)",
	"display_et" to "எஸ்டோனியன்",
	"display_eu" to "பாஸ்க்",
	"display_fa" to "பாரசீக",
	"display_fi" to "பின்னிஷ்",
	"display_fil" to "பிலிப்பைன்ஸ்",
	"display_fr" to "பிரெஞ்சு",
	"display_fr_CA" to "கனடிய பிரஞ்சு",
	"display_gl" to "காலிசியன்",
	"display_gu" to "குஜராத்தி",
	"display_he" to "ஹீப்ரு",
	"display_hi" to "இல்லை.",
	"display_hr" to "குரோஷியன்",
	"display_hu" to "ஹங்கேரிய",
	"display_hy" to "ஆர்மேனியன்",
	"display_id" to "இந்தோனேஷியன்",
	"display_is" to "ஐஸ்லாந்து",
	"display_it" to "இத்தாலிய",
	"display_ja" to "ஜப்பானியர்",
	"display_ka" to "ஜார்ஜியன்",
	"display_kk" to "கசாக்",
	"display_km" to "மத்திய கெமர்",
	"display_kn" to "கன்னடம்",
	"display_ko" to "கொரிய",
	"display_ky" to "கிர்கிஸ்",
	"display_lo" to "தொழிலாளர்",
	"display_lt" to "லிதுவேனியன்",
	"display_lv" to "லாட்வியன்",
	"display_mk" to "மாசிடோனியன்",
	"display_ml" to "மலையாளம்",
	"display_mn" to "மங்கோலியன்",
	"display_mr" to "மராத்தி",
	"display_ms" to "மலாய்",
	"display_my" to "பர்மியர்",
	"display_nb" to "நார்வே பொக்மால்",
	"display_ne" to "நேபாளி",
	"display_nl" to "டச்சு",
	"display_or" to "ஒரியா",
	"display_pa" to "பஞ்சாபி",
	"display_pl" to "போலிஷ்",
	"display_pt" to "போர்த்துகீசியம்",
	"display_pt_BR" to "போர்த்துகீசியம் (பிரேசில்)",
	"display_pt_PT" to "ஐரோப்பிய போர்த்துகீசியம்",
	"display_ro" to "ரோமானியன்",
	"display_ru" to "ரஷ்யன்",
	"display_si" to "சிங்களம்",
	"display_sk" to "ஸ்லோவாக்",
	"display_sl" to "ஸ்லோவேனியன்",
	"display_sq" to "அல்பேனியன்",
	"display_sr" to "செர்பியன்",
	"display_sv" to "ஸ்வீடிஷ்",
	"display_sw" to "சுவாஹிலி",
	"display_ta" to "தமிழ்",
	"display_te" to "தெலுங்கு",
	"display_th" to "தாய்",
	"display_tr" to "துருக்கிய",
	"display_uk" to "உக்ரைனியன்",
	"display_ur" to "உருது",
	"display_uz" to "உஸ்பெக்",
	"display_vi" to "வியட்நாமியர்",
	"display_zh_Hant_TW" to "சீனம் (தைவான், பாரம்பரியம்)",
	"display_zu" to "ஜூலு",
	"document_estimate" to "மதிப்பீடு",
	"document_invoice" to "விலைப்பட்டி",
	"document_quote" to "மேற்கோள்",
	"done" to "முடிந்தது",
	"download_app" to "பயன்பாட்டைப் பதிவிறக்கவும்",
	"download_invoice" to "விலைப்பட்டியல் பதிவிறக்கவும்",
	"download_pdf" to "Pdf ஐ பதிவிறக்கவும்",
	"draw_signature" to "கையொப்பம் வரை",
	"due" to Formattable { (date) -> "நிலுவை ${date}" },
	"due_date" to "கெடு தேதி",
	"due_day" to "ஒரு நாளில்",
	"due_label" to "நிலுவை",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} நிலுவைத் தொகை ${dueWhen}!" },
	"due_today" to "இன்று",
	"due_week" to "ஒரு வாரத்தில்",
	"duplicate" to "நகல்",
	"duration_day" to "நாள்",
	"duration_month" to "மாதம்",
	"duration_week" to "வாரம்",
	"duration_year" to "ஆண்டு",
	"email" to "மின்னஞ்சல்",
	"email_blank_error" to "மின்னஞ்சல் தேவை.",
	"email_error" to "செல்லுபடியாகாத மின்னஞ்சல் முகவரி.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "உங்கள் வணிகத்திற்கு நன்றி. முழு ${documentType}ஐப் பார்க்க, இந்தக் கடிதத்தின் முடிவில் உள்ள பொத்தானைக் கிளிக் செய்யவும். இது ${dueDate} வரை செல்லுபடியாகும் என்பதை நினைவில் கொள்ளவும்." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "நீங்கள் புதியதைப் பெற்றுள்ளீர்கள்${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} ஐக் காண்க" },
	"email_invoice_greeting" to Formattable { (clientName) -> "அன்புள்ள ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "உங்கள் வணிகத்திற்கு நன்றி. முழு ${documentType}ஐப் பார்க்க, இந்தக் கடிதத்தின் முடிவில் உள்ள பொத்தானைக் கிளிக் செய்யவும். ${dueDate} வரை நிலுவைத் தொகையைச் செலுத்தவும்." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "நீங்கள் புதியதைப் பெற்றுள்ளீர்கள்${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "அனுப்பிய கடவுச்சொல்லை மீட்டமைக்க மின்னஞ்சல், உங்கள் இன்பாக்ஸை சரிபார்க்கவும்.",
	"empty_clients_list_message" to "நீங்கள் கண்காணிக்க வாடிக்கையாளர்கள் இல்லை. புதிய கிளையன்டைச் சேர்க்கவும்.",
	"empty_estimate_list_message" to "உங்கள் முதல் மதிப்பீட்டை உருவாக்கவும்",
	"empty_expenses" to "நீங்கள் கண்காணிக்க எந்த செலவும் இல்லை. புதிய செலவைச் சேர்க்கவும்.",
	"empty_invoice_list_message" to "உங்கள் முதல் விலைப்பட்டியலை உருவாக்கவும்",
	"empty_items_list_message" to "உங்களிடம் உருப்படிகள் இல்லை, புதிய உருப்படியைச் சேர்க்கவும்!",
	"empty_signatures_list_message" to "கையொப்பங்கள் எதுவும் காணப்படவில்லை. ஒன்றை உருவாக்கு!",
	"empty_taxes_list_message" to "உங்களிடம் வரி விதிகள் இல்லை. ஒரு புதிய வரி விதி சேர்க்கவும்.",
	"empty_templates_list_message" to "வார்ப்புருக்கள் கிடைக்கவில்லை.",
	"enabled" to "செயல்படுத்தப்பட்டது",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "மதிப்பீடு #",
	"estimates" to "மதிப்பீடுகள்",
	"estimates_report" to "மதிப்பீடுகள்",
	"expense_bank_fees" to "வங்கி கட்டணம்",
	"expense_benefits" to "நன்மைகள்",
	"expense_communication" to "தொடர்பு",
	"expense_debt" to "கடன்",
	"expense_deleted" to "செலவு நீக்கப்பட்டது",
	"expense_depreciation" to "தேய்மானம்",
	"expense_gifts" to "பரிசுகள்",
	"expense_insurance" to "காப்பீடு",
	"expense_inventory" to "சரக்கு",
	"expense_legal" to "சட்டபூர்வமானது",
	"expense_maintenance" to "பராமரிப்பு",
	"expense_marketing" to "சந்தைப்படுத்தல்",
	"expense_meals" to "சாப்பாடு",
	"expense_office_supplies" to "அலுவலக பொருட்கள்",
	"expense_rent" to "வாடகை",
	"expense_salaries" to "சம்பளம்",
	"expense_shopping" to "கடையில் பொருட்கள் வாங்குதல்",
	"expense_software" to "மென்பொருள்",
	"expense_status_available" to "கிடைக்கும்",
	"expense_status_processing" to "செயலாக்கம்",
	"expense_tax" to "வரி",
	"expense_training" to "பயிற்சி",
	"expense_transport" to "போக்குவரத்து",
	"expense_travel" to "பயணம்",
	"expense_utility" to "பயன்பாடு",
	"expenses" to "செலவுகள்",
	"feedback_option" to "கருத்து",
	"filter" to "வடிகட்டும் அமைவு",
	"first_name_hint" to "முதல் பெயர்",
	"forgot_password" to "கடவுச்சொல்லை மீட்டமை",
	"forgot_password_label" to "கடவுச்சொல்லை மறந்துவிட்டது",
	"from" to "இருந்து",
	"general_error" to "பிழை ஏற்பட்டது, பிறகு முயற்சிக்கவும்.",
	"general_ledger" to "general ledger",
	"go_back_label" to "பின் செல்",
	"group_by" to "குழு வாக்கு:",
	"image_view" to "உருவம்",
	"install" to "நிறுவுக",
	"invalid_credentials_error" to "செல்லுபடியாகாத நம்பிக்கைச்சான்றுகள்.",
	"invalid_email_error" to "செல்லுபடியாகாத மின்னஞ்சல்.",
	"invalid_user_error" to "செல்லுபடியாகாத பயனர் நம்பிக்கைச்சான்றுகள்.",
	"invoice_deleted_message" to "விலைப்பட்டியல் நீக்கப்பட்டது",
	"invoice_menu" to "விலைப்பட்டியல் மெனு",
	"invoice_number" to "விலைப்பட்டி #",
	"invoices" to "விலைப்பட்டியல்கள்",
	"items_option" to "உருப்படிகளை",
	"keep" to "காவற்கூண்டு",
	"language_selector" to Formattable { (selected) -> "மொழி ${selected}" },
	"last_name_hint" to "கடைசி பெயர்",
	"ledger" to "ledger",
	"legal" to "சட்டஞ் சார்ந்த",
	"legal_error" to "சேவை விதிமுறைகள் மற்றும் தனியுரிமைக் கொள்கையை ஏற்க வேண்டும்",
	"liabilities" to "liabilities",
	"loading" to "ஏற்றுகிறது",
	"login_required_error" to "உள்நுழைவு தேவை.",
	"logo_view" to "சின்னம்",
	"logout" to "வெளியேறு",
	"logout_prompt" to "உங்கள் தரவு கிளவுட் உடன் ஒத்திசைக்கப்படவில்லை. உள்நுழைவு பற்றிய விலைப்பட்டியல்கள், மதிப்பீடுகள், வாடிக்கையாளர்கள் மற்றும் பிற தகவல்களை நீக்கவேண்டுமா?",
	"main_screen" to "முதன்மை திரை",
	"mark_paid" to "செலுத்தியதாக குறியிடு",
	"message" to "செய்தி",
	"missing_sender_msg" to "அனுப்புநரின் தகவல் இல்லாத ஆவணம் தவறானதாக இருக்கலாம்.",
	"missing_sender_title" to "அனுப்புநர் தகவல் இல்லை",
	"month_april" to "ஏப்ரல்",
	"month_april_short" to "ஏப்ரல்",
	"month_august" to "ஆகஸ்ட்",
	"month_august_short" to "ஆக.",
	"month_december" to "டிசம்பர்",
	"month_december_short" to "டிச.",
	"month_february" to "பிப்ரவரி",
	"month_february_short" to "பிப்.",
	"month_january" to "ஜனவரி",
	"month_january_short" to "ஜன.",
	"month_july" to "ஜூலை",
	"month_july_short" to "ஜூலை",
	"month_june" to "ஜூன்",
	"month_june_short" to "ஜூன்",
	"month_march" to "மார்ச்",
	"month_march_short" to "மார்ச்",
	"month_may" to "மே",
	"month_may_short" to "மே",
	"month_november" to "நவம்பர்",
	"month_november_short" to "நவ.",
	"month_october" to "அக்டோபர்",
	"month_october_short" to "அக்.",
	"month_september" to "செப்டம்பர்",
	"month_september_short" to "செப்.",
	"multifactor_error" to "சரிபார்க்க முடியவில்லை.",
	"new" to "புதியது",
	"news" to "செய்தி",
	"next" to "அடுத்தது",
	"no" to "no",
	"no_data_available" to "தரவு எதுவும் கிடைக்கவில்லை",
	"no_search_results" to "மன்னிக்கவும், எங்களால் எந்த முடிவுகளையும் கண்டுபிடிக்க முடியவில்லை.",
	"none" to "இல்லை",
	"note_fields" to "குறிப்புகள்",
	"note_hint" to "தனிக்குரலிசை",
	"notifications_option" to "அறிவிக்கைகள்",
	"ok" to "சரி",
	"onboarding" to "ஆன்போர்டிங்",
	"onboarding_aboutus_message" to "DingVoice இன் அத்தியாவசிய பகுதிகள் எப்போதும் இலவசம். உங்கள் வணிகத்தை வளர்க்க உதவுவதும், உங்கள் கருத்தைப் பகிர்வதன் மூலம் மேம்படுத்த உதவுவதும் எங்கள் நோக்கம்.",
	"onboarding_aboutus_title" to "நாங்கள் உதவ இங்கே இருக்கிறோம்",
	"onboarding_paid_1" to "தொழில்முறை தோற்றம் கொண்ட டெம்ப்ளேட்களை அணுகவும்",
	"onboarding_paid_2" to "உங்கள் நிதிநிலையை ஆழமாகப் பாருங்கள்",
	"onboarding_paid_3" to "பிரச்சனைகளை விரைவாக தீர்க்கவும்",
	"onboarding_paid_4" to "உங்கள் ஆவணங்களை பாதுகாப்பாக வைத்திருங்கள்",
	"onboarding_paid_5" to "இன்வாய்ஸ் நிலை மாற்றங்களுடன் புதுப்பித்த நிலையில் இருங்கள்",
	"onboarding_paid_title" to "மேலும் வேகமாக வளர...",
	"onboarding_paywall_message" to "அனைத்து பிரீமியம் அம்சங்களையும் இப்போது சிறந்த விலையில் அனுபவிக்கவும்.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} க்கு ${currencySymbol}${introAmount} பிறகு ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "வெறும் ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod}க்கு இலவசம், பிறகு ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "இலவசம்${trialDuration}${trialPeriod} பிறகு${currencySymbol}${introductoryAmount} க்கான${introductoryDuration}${introPeriod} பின்னர் தான்${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "தானாக புதுப்பிக்கக்கூடியது. எப்போது வேண்டுமானாலும் ரத்து செய்யலாம்.",
	"onboarding_paywall_restore" to "வாங்குதலை மீட்டெடுக்கவும்",
	"onboarding_paywall_restore_failure" to "வாங்குதல்களை மீட்டெடுப்பது தோல்வியடைந்தது, பிறகு முயற்சிக்கவும் அல்லது ஆதரவைத் தொடர்புகொள்ளவும்.",
	"onboarding_paywall_restore_success" to "வெற்றிகரமாக மீட்டமை!",
	"onboarding_paywall_title" to "மேன்மை அடைய!",
	"onboarding_welcome_message" to "உங்கள் இன்வாய்ஸ்கள் மற்றும் மதிப்பீடுகளை நிர்வகிப்பதற்கான முழுமையான கருவித்தொகுப்பு.",
	"onboarding_welcome_title" to "DingVoice க்கு வரவேற்கிறோம்",
	"other_state" to "இதர",
	"overdue_state" to "நிலுவை",
	"paid_state" to "பணம் செலுத்தியது",
	"password" to "அடையாளச் சொல்",
	"password_blank_error" to "கடவுச்சொல் தேவை.",
	"password_mismatch_error" to "கடவுச்சொற்கள் பொருந்தவில்லை.",
	"pay" to "pay",
	"payment_instructions_label" to "கொடுப்பனவு அறிவுறுத்தல்கள்",
	"payment_reminders" to "கட்டண நினைவூட்டல்கள்",
	"phone_number_fields" to "தொலைபேசி எண்",
	"phone_number_hint" to "தொலைபேசி எண்",
	"postcode_hint" to "அஞ்சல் குறியீடு",
	"preview" to "வெள்ளோட்டம்",
	"preview_menu_hint" to "முன்னோட்ட மெனு",
	"price" to "விலை",
	"privacy_policy" to "தனியுரிமைக் கொள்கை",
	"profile" to "பக்கத்தோற்ற வடிவம்",
	"profitable_client" to "உங்கள் மிகவும் இலாபகரமான வாடிக்கையாளர்...",
	"profits_report" to "இலாபங்கள்",
	"promo_content" to "விளம்பர உள்ளடக்கம்",
	"quantity_hint" to "அளவு",
	"quantity_label_hint" to "அளவு லேபிள்",
	"query_info" to "கேள்வி நீங்கள் உள்ளீடுகள் என உருப்படிகள் மற்றும் பிற வரி விதிகள் இருவரும் குறிப்பிடும் விருப்ப கணக்கீடுகள் எழுத அனுமதிக்கிறது. மற்றொரு உள்ளீடு +, -, *, /, % - கணித ஆபரேட்டர்களுடன் இணைக்க @ சின்னம் மற்றும் உருப்படி அல்லது வரி விளக்கத்தைச் சேர்க்கவும்",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} உங்கள் கிளையண்டால் பெறப்பட்டது." },
	"received_state" to "பெற்றது",
	"registration_code_fields" to "பதிவுக் குறியீடு",
	"reminder" to "நினைவூட்டல்",
	"reports" to "அறிக்கைகள்",
	"reports_not_available" to "அறிக்கைகள் இல்லை, பிறகு வரவும்.",
	"reveal_password" to "கடவுச்சொல்லை வெளிப்படுத்து",
	"revenue" to "revenue",
	"sales_tax" to "வாட்",
	"sales_tax_number" to "வாட் எண்",
	"save" to "கெலிப்புத்தடங்கல்",
	"scan_document" to "ஆவணத்தை ஸ்கேன் செய்யவும்",
	"search" to "தேட்டம்",
	"select" to "தேர்ந்தெடு",
	"select_icon" to "ஐகானைத் தேர்ந்தெடுக்கவும்",
	"select_signature" to "கையொப்பம் தேர்ந்தெடு",
	"select_template" to "வார்ப்புருவை தேர்ந்தெடு",
	"send" to "அனுப்பு",
	"sent_state" to "அனுப்பிய",
	"setup_sender" to "அனுப்புநரை அமைக்கவும்",
	"share_option" to "பங்கு",
	"ship_to" to "அனுப்பவும்",
	"sign_in" to "உள்நுழைக",
	"sign_in_apple" to "ஆப்பிள் உள்நுழைய",
	"sign_in_email" to "மின்னஞ்சலுடன் உள்நுழைக",
	"sign_in_facebook" to "பேஸ்புக் உள்நுழைய",
	"sign_in_google" to "Gggpag",
	"sign_up" to "பதிவுபெறு",
	"sign_up_label" to "கணக்கு இல்லையா?",
	"sign_up_link_label" to "பதிவுபெறு!",
	"signatures_option" to "கையொப்பங்கள்",
	"skip" to "தவிர்க்கவும்",
	"sold_items_report" to "விற்கப்பட்ட பொருட்கள்",
	"sort_by" to "வரிசைப்படுத்து:",
	"status_update" to "ஆவண நிலை புதுப்பிப்பு",
	"sub_annual" to "வருடாந்தம்",
	"sub_annual_price" to Formattable { (price) -> "${price} /ஆண்டு" },
	"sub_backups" to "கிளவுட் காப்புப்பிரதிகள்",
	"sub_insights" to "உள்ளுணர்வை",
	"sub_month" to "மாதந்தோறும்",
	"sub_monthly_price" to Formattable { (price) -> "${price} /மாதம்" },
	"sub_notifications" to "Notifications, அறிவிப்புகள்",
	"sub_popup_message" to "சந்தாக்கள் விரைவில் வருகின்றன. சிறிது நேரத்தில் இங்கே மீண்டும் சரிபார்க்கவும்.",
	"sub_support" to "ஆதரவு",
	"sub_templates" to "டெம்ப்ளேட்கள்",
	"subscribe" to "அடியில் எழுது",
	"subscription_header" to "உங்கள் வணிக வளர:",
	"subscription_option" to "அடியில் எழுதுதல்",
	"subscriptions_load_fail" to "மன்னிக்கவும், இந்த நேரத்தில் உங்கள் சந்தாக்களை எங்களால் மீட்டெடுக்க முடியவில்லை. பிறகு முயற்சிக்கவும் அல்லது உதவிக்கு எங்கள் ஆதரவுக் குழுவைத் தொடர்பு கொள்ளவும்.",
	"subtotal" to Formattable { (sum) -> "துணைமொத்தம்: ${sum}" },
	"subtotal_label" to "கூட்டுத்தொகை",
	"sum" to "தொகை",
	"support_option" to "துணை",
	"switch_reports" to "ஸ்விட்ச் அறிக்கை",
	"tax" to "வரி",
	"tax_deleted_message" to "வரி நீக்கப்பட்டது",
	"tax_description_warning" to "இதைப் பயன்படுத்தினால் விலைப்பட்டியலில் காண்பிக்கப்படும்",
	"tax_input_flat" to "அறைக்கட்டு",
	"tax_input_percent" to "விழுக்காடு",
	"tax_number_fields" to "வரி எண்",
	"tax_number_hint" to "வரி எண்",
	"tax_query_info_description" to "கேள்வி தகவல்",
	"tax_report" to "வரி",
	"tax_rules_option" to "வரி விதிகள்",
	"taxed_clients_report" to "வரி விதிக்கப்பட்ட வாடிக்கையாளர்கள்",
	"template" to "வார்ப்புரு",
	"template_selector_info" to "இயல்புநிலை வார்ப்புருவை மாற்ற ஸ்வைப் செய்யவும்",
	"template_unlock_promo" to "Premium மூலம் மேலும் டெம்ப்ளேட்களை அணுகவும்",
	"templates_option" to "வார்ப்புரு",
	"terms_of_service" to "சேவை விதிமுறைகள்",
	"thank_you" to "நன்றி",
	"to" to "நோக்கி",
	"total" to Formattable { (sum) -> "மொத்தம்: ${sum}" },
	"total_label" to "மொத்தம்",
	"undo_action" to "கெடு",
	"unit" to "ஒன்ற",
	"unit_cost_hint" to "அலகு செலவு",
	"unknown_client" to "தெரியாத வாடிக்கையாளர்",
	"unlock" to "திறக்கவும்",
	"unnamed_item" to "பெயரிடப்படாத பொருள்",
	"upgrade" to "மேல்நோக்கிய சாய்வு",
	"user_collision_error" to "கணக்கு ஏற்கனவே உள்ளது.",
	"valid_until" to "வரை செல்லுபடியாகும்",
	"warning" to "எச்சரிக்கை!",
	"yes" to "yes",
    )
    
}