// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Gl  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Acepta ${termsOfService} e ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Código non válido."
override val add_client_action: String = "Engadir cliente"
override val add_customer: String = "Engadir cliente"
override val add_expense: String = "Engadir gasto"
override val add_fields_label: String = "Engadir:"
override val add_invoice_action: String = "Crear"
override val add_item_action: String = "Engadir elemento"
override val add_item_button: String = "Engadir elemento"
override val add_signature_action: String = "Engadir sinatura"
override val add_tax_action: String = "Engadir impostos"
override val address_fields: String = "Enderezo"
override val address_line_hint: String = "Enderezo"
override val advanced: String = "Avanzado"
override val agree_marketing: String = "Acepto recibir comunicacións de mercadotecnia directa"
override val all_notifications: String = "Todas as notificacións"
override val amortization: String = "amortization"
override val amount: String = "Cantidade"
override val asset: String = "asset"
override val attach_file: String = "Achegar ficheiro"
override val attachment: String = "Anexo"
override val auth_error: String = "Produciuse un erro na acción. Téntao de novo."
override val available_for_paid: String = "A sincronización na nube só está dispoñible baixo a subscrición de pago."
override val backups_option: String = "Copias de seguridade"
override val balance: String = "balance"
override val bank_account: String = "Conta bancaria"
override val bill_number: String = "Número de factura"
override val cancel: String = "Cancelar"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Categorías"
override val category: String = "Categoría"
override val category_add: String = "Engadir categoría"
override val category_delete: String = "Eliminar categoría"
override val category_name: String = "Nome da categoría"
override val change_language: Formattable = Formattable { (language) -> "Idioma da factura: ${language}" }
override val change_language_label: String = "Idioma da factura"
override val change_template: String = "Cambiar modelo"
override val city_hint: String = "Cidade"
override val clear: String = "Limpar"
override val client: String = "Clienta"
override val client_actions: String = "Accións do cliente"
override val client_deleted_message: String = "Cliente eliminado"
override val clients_error_required_fields: String = "Requírese polo menos un valor."
override val clients_option: String = "Clientes"
override val close: String = "Pechar"
override val cloud_sync: String = "Sincronización na nube"
override val coming_soon: String = "Proximamente!"
override val company_logo: String = "Logotipo da empresa"
override val company_name_hint: String = "Compañía"
override val confirm_password_label: String = "Confirme o contrasinal"
override val confirmation_title: String = "Estás seguro?"
override val convert_to_invoice: String = "Converter en factura"
override val country_hint: String = "País"
override val create_client: String = "Crear cliente"
override val create_company: String = "Crear empresa"
override val create_document: String = "Crear documento"
override val create_estimate: String = "Estimación"
override val create_invoice: String = "Factura"
override val create_item: String = "Crear elemento"
override val create_new_client: String = "Crear novo cliente"
override val create_new_item: String = "Crear novo elemento"
override val create_new_tax: String = "Crear un novo imposto"
override val create_signature: String = "Sinatura"
override val created: String = "Creado"
override val credit: String = "credit"
override val customer: String = "Cliente"
override val date: String = "Data"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Moeda predeterminada: ${currency}" }
override val default_currency_label: String = "Moeda predeterminada"
override val delete: String = "Borrar"
override val delete_account: String = "Eliminar conta"
override val delete_confirmation_message: String = "Todos os teus datos perderanse."
override val delete_item_title: String = "Queres eliminar o elemento?"
override val delete_signature: String = "Eliminar a sinatura"
override val depreciation: String = "depreciation"
override val description: String = "Descrición"
override val description_hint: String = "Descrición"
override val detailed_report: String = "Informe detallado"
override val disabled: String = "Desactivado"
override val discount: String = "Desconto"
override val discount_hint: String = "Desconto"
override val display_af: String = "africano"
override val display_am: String = "amárico"
override val display_ar: String = "árabe"
override val display_as: String = "asamés"
override val display_az: String = "azerbaiyano"
override val display_be: String = "bielorruso"
override val display_bg: String = "búlgaro"
override val display_bn: String = "bengalí"
override val display_bs: String = "bosníaco"
override val display_ca: String = "Catalán"
override val display_cs: String = "checo"
override val display_da: String = "danés"
override val display_de: String = "Alemán"
override val display_el: String = "grego"
override val display_en: String = "Inglés"
override val display_es: String = "Español"
override val display_es_MX: String = "español (México)"
override val display_et: String = "Estonio"
override val display_eu: String = "vasco"
override val display_fa: String = "persa"
override val display_fi: String = "finés"
override val display_fil: String = "Filipino"
override val display_fr: String = "francés"
override val display_fr_CA: String = "francés canadense"
override val display_gl: String = "Galego"
override val display_gu: String = "Gujarati"
override val display_he: String = "hebreo"
override val display_hi: String = "non"
override val display_hr: String = "croata"
override val display_hu: String = "húngaro"
override val display_hy: String = "armenio"
override val display_id: String = "indonesio"
override val display_is: String = "islandés"
override val display_it: String = "italiano"
override val display_ja: String = "xaponés"
override val display_ka: String = "xeorxiano"
override val display_kk: String = "Casaco"
override val display_km: String = "Khmer central"
override val display_kn: String = "Kannada"
override val display_ko: String = "coreano"
override val display_ky: String = "Kirguiz"
override val display_lo: String = "Laboral"
override val display_lt: String = "lituano"
override val display_lv: String = "letón"
override val display_mk: String = "macedonio"
override val display_ml: String = "malayalam"
override val display_mn: String = "mongol"
override val display_mr: String = "Marathi"
override val display_ms: String = "malaio"
override val display_my: String = "birmano"
override val display_nb: String = "noruegués bokmål"
override val display_ne: String = "nepalí"
override val display_nl: String = "holandés"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "polaco"
override val display_pt: String = "portugués"
override val display_pt_BR: String = "Portugués (Brasil)"
override val display_pt_PT: String = "portugués europeo"
override val display_ro: String = "romanés"
override val display_ru: String = "ruso"
override val display_si: String = "Cingalés"
override val display_sk: String = "eslovaco"
override val display_sl: String = "esloveno"
override val display_sq: String = "albanés"
override val display_sr: String = "serbio"
override val display_sv: String = "sueco"
override val display_sw: String = "suahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "tailandés"
override val display_tr: String = "turco"
override val display_uk: String = "ucraíno"
override val display_ur: String = "Urdú"
override val display_uz: String = "uzbeco"
override val display_vi: String = "vietnamita"
override val display_zh_Hant_TW: String = "Chinés (Taiwán, Tradicional)"
override val display_zu: String = "Zulú"
override val document_estimate: String = "Estimación"
override val document_invoice: String = "Factura"
override val document_quote: String = "Cita"
override val done: String = "Feito"
override val download_app: String = "Descarga a aplicación"
override val download_invoice: String = "Descargar factura"
override val download_pdf: String = "Descargar PDF"
override val draw_signature: String = "Debuxa unha sinatura"
override val due: Formattable = Formattable { (date) -> "Vencemento ${date}" }
override val due_date: String = "Data de caducidade"
override val due_day: String = "nun día"
override val due_label: String = "Debido"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} vence ${dueWhen}!" }
override val due_today: String = "Hoxe"
override val due_week: String = "nunha semana"
override val duplicate: String = "Duplicar"
override val duration_day: String = "día"
override val duration_month: String = "Mes"
override val duration_week: String = "semana"
override val duration_year: String = "ano"
override val email: String = "Correo electrónico"
override val email_blank_error: String = "Correo electrónico necesario."
override val email_error: String = "Enderezo de correo electrónico non válido."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Grazas polo teu negocio. Para ver ${documentType} completo, fai clic no botón ao final desta carta. Ten en conta que é válido ata ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Recibiches un novo${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "VER ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Estimado ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Grazas polo teu negocio. Para ver ${documentType} completo, fai clic no botón ao final desta carta. Paga o saldo ata ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Recibiches un novo${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "Enviouse o correo electrónico para restablecer o contrasinal, revisa a túa caixa de entrada."
override val empty_clients_list_message: String = "Non tes clientes que rastrexar. Engade un novo cliente."
override val empty_estimate_list_message: String = "Crea a túa primeira estimación"
override val empty_expenses: String = "Non tes gastos para rastrexar. Engade un novo gasto."
override val empty_invoice_list_message: String = "Crea a túa primeira factura"
override val empty_items_list_message: String = "Non tes elementos, engade un novo!"
override val empty_signatures_list_message: String = "Non se atoparon sinaturas. Crea un!"
override val empty_taxes_list_message: String = "Non tes regras fiscais. Engade unha nova norma fiscal."
override val empty_templates_list_message: String = "Modelos non dispoñibles."
override val enabled: String = "Activado"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Estimación #"
override val estimates: String = "Estimacións"
override val estimates_report: String = "Estimacións"
override val expense_bank_fees: String = "Comisións bancarias"
override val expense_benefits: String = "Beneficios"
override val expense_communication: String = "Comunicación"
override val expense_debt: String = "Débeda"
override val expense_deleted: String = "Gasto eliminado"
override val expense_depreciation: String = "Amortización"
override val expense_gifts: String = "Agasallos"
override val expense_insurance: String = "Seguros"
override val expense_inventory: String = "Inventario"
override val expense_legal: String = "Legal"
override val expense_maintenance: String = "Mantemento"
override val expense_marketing: String = "Márketing"
override val expense_meals: String = "Comidas"
override val expense_office_supplies: String = "Material de oficina"
override val expense_rent: String = "Aluguer"
override val expense_salaries: String = "Salarios"
override val expense_shopping: String = "Compras"
override val expense_software: String = "Software"
override val expense_status_available: String = "Dispoñible"
override val expense_status_processing: String = "Procesamento"
override val expense_tax: String = "Imposto"
override val expense_training: String = "Formación"
override val expense_transport: String = "Transporte"
override val expense_travel: String = "Viaxar"
override val expense_utility: String = "Utilidade"
override val expenses: String = "Gastos"
override val feedback_option: String = "Comentarios"
override val filter: String = "Filtro"
override val first_name_hint: String = "Nome"
override val forgot_password: String = "Restablecer o contrasinal"
override val forgot_password_label: String = "Esqueciches o contrasinal"
override val from: String = "Desde"
override val general_error: String = "Produciuse un erro, téntao de novo máis tarde."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Volve atrás"
override val group_by: String = "Agrupar por:"
override val image_view: String = "imaxe"
override val install: String = "Instalar"
override val invalid_credentials_error: String = "Credenciais non válidas."
override val invalid_email_error: String = "Correo electrónico non válido."
override val invalid_user_error: String = "Credenciais de usuario non válidas."
override val invoice_deleted_message: String = "Factura eliminada"
override val invoice_menu: String = "Menú de factura"
override val invoice_number: String = "Factura #"
override val invoices: String = "Facturas"
override val items_option: String = "Elementos"
override val keep: String = "Mantéñase"
override val language_selector: Formattable = Formattable { (seleccionado) -> "Idioma ${seleccionado}" }
override val last_name_hint: String = "Apelido"
override val ledger: String = "ledger"
override val legal: String = "Legal"
override val legal_error: String = "Debe aceptar as Condicións de servizo e a Política de privacidade"
override val liabilities: String = "liabilities"
override val loading: String = "Cargando"
override val login_required_error: String = "É necesario iniciar sesión."
override val logo_view: String = "Logo"
override val logout: String = "Pechar sesión"
override val logout_prompt: String = "Os teus datos non están sincronizados coa nube. Queres eliminar facturas, estimacións, clientes e outra información ao pechar a sesión?"
override val main_screen: String = "Pantalla principal"
override val mark_paid: String = "Marcar como pago"
override val message: String = "Mensaxe"
override val missing_sender_msg: String = "É posible que un documento sen información do remitente non sexa válido."
override val missing_sender_title: String = "Falta información do remitente"
override val month_april: String = "abril"
override val month_april_short: String = "abril"
override val month_august: String = "agosto"
override val month_august_short: String = "ago."
override val month_december: String = "decembro"
override val month_december_short: String = "Dec."
override val month_february: String = "febreiro"
override val month_february_short: String = "febreiro"
override val month_january: String = "xaneiro"
override val month_january_short: String = "Xaneiro"
override val month_july: String = "xullo"
override val month_july_short: String = "xullo"
override val month_june: String = "xuño"
override val month_june_short: String = "xuño"
override val month_march: String = "marzo"
override val month_march_short: String = "marzo"
override val month_may: String = "maio"
override val month_may_short: String = "maio"
override val month_november: String = "novembro"
override val month_november_short: String = "nov."
override val month_october: String = "Outubro"
override val month_october_short: String = "outubro"
override val month_september: String = "setembro"
override val month_september_short: String = "setembro"
override val multifactor_error: String = "Produciuse un erro ao verificar."
override val new: String = "novo"
override val news: String = "Novidades"
override val next: String = "A continuación"
override val no: String = "no"
override val no_data_available: String = "Non hai datos dispoñibles"
override val no_search_results: String = "Sentímolo, non puidemos atopar ningún resultado."
override val none: String = "Ningún"
override val note_fields: String = "Notas"
override val note_hint: String = "Nota"
override val notifications_option: String = "Notificacións"
override val ok: String = "Ok"
override val onboarding: String = "Incorporación"
override val onboarding_aboutus_message: String = "As partes esenciais de DingVoice son gratuítas para sempre. A nosa misión é axudarche a facer crecer o teu negocio, axúdanos a mellorar compartindo os teus comentarios."
override val onboarding_aboutus_title: String = "Estamos aquí para axudar"
override val onboarding_paid_1: String = "Accede a modelos de aspecto profesional"
override val onboarding_paid_2: String = "Bótalle unha ollada máis profunda ás túas finanzas"
override val onboarding_paid_3: String = "Resolva os problemas rapidamente"
override val onboarding_paid_4: String = "Mantén seguros os teus documentos"
override val onboarding_paid_5: String = "Mantente ao día dos cambios de estado das facturas"
override val onboarding_paid_title: String = "Crece aínda máis rápido con..."
override val onboarding_paywall_message: String = "Goza de todas as funcións premium agora ao mellor prezo."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Só ${currencySymbol}${introAmount} por ${introDuration} ${introPeriod} e despois ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Só ${currencySymbol}${amount}/${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis por ${trialDuration} ${trialPeriod} e despois só ${currencySymbol}${amount}/${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis por ${trialDuration} ${trialPeriod}, despois ${currencySymbol}${introductoryAmount} por ${introductoryDuration} ${introPeriod} e despois só ${currencySymbol}${amount}/${duration}" }
override val onboarding_paywall_renewable: String = "Auto-renovable. Cancela en calquera momento."
override val onboarding_paywall_restore: String = "Restaurar compra"
override val onboarding_paywall_restore_failure: String = "Produciuse un erro ao restaurar as compras. Téntao de novo máis tarde ou ponte en contacto co servizo de asistencia."
override val onboarding_paywall_restore_success: String = "Restauración exitosa!"
override val onboarding_paywall_title: String = "Acada a grandeza!"
override val onboarding_welcome_message: String = "Un conxunto completo de ferramentas para xestionar as súas facturas e estimacións."
override val onboarding_welcome_title: String = "Benvido a DingVoice"
override val other_state: String = "Outro"
override val overdue_state: String = "Retraso"
override val paid_state: String = "Pagado"
override val password: String = "Contrasinal"
override val password_blank_error: String = "Requírese o contrasinal."
override val password_mismatch_error: String = "Os contrasinais non coinciden."
override val pay: String = "pay"
override val payment_instructions_label: String = "Instrucións de pago"
override val payment_reminders: String = "Recordatorios de pago"
override val phone_number_fields: String = "Número de teléfono"
override val phone_number_hint: String = "Número de teléfono"
override val postcode_hint: String = "Código postal"
override val preview: String = "Vista previa"
override val preview_menu_hint: String = "Menú de vista previa"
override val price: String = "Prezo"
override val privacy_policy: String = "Política de privacidade"
override val profile: String = "Perfil"
override val profitable_client: String = "O teu cliente máis rendible é..."
override val profits_report: String = "Beneficios"
override val promo_content: String = "Contido promocional"
override val quantity_hint: String = "Cantidade"
override val quantity_label_hint: String = "Etiqueta de cantidade"
override val query_info: String = "A consulta permítelle escribir cálculos personalizados referidos tanto a elementos como a outras regras fiscais como entradas. Engade o símbolo @ e a descrición do artigo ou do imposto para vincular a outra entrada +, -, *, /, % - operadores matemáticos"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "O teu cliente recibiu ${documentType} ${documentNumber}." }
override val received_state: String = "Recibido"
override val registration_code_fields: String = "Código de rexistro"
override val reminder: String = "Recordatorio"
override val reports: String = "Informes"
override val reports_not_available: String = "Non hai informes dispoñibles, volve máis tarde."
override val reveal_password: String = "Revelar o contrasinal"
override val revenue: String = "revenue"
override val sales_tax: String = "IVE"
override val sales_tax_number: String = "número de IVE"
override val save: String = "Gardar"
override val scan_document: String = "Escanear documento"
override val search: String = "Buscar"
override val select: String = "Seleccione"
override val select_icon: String = "Seleccione a icona"
override val select_signature: String = "Seleccione a sinatura"
override val select_template: String = "Seleccione o modelo"
override val send: String = "Enviar"
override val sent_state: String = "Enviado"
override val setup_sender: String = "Configurar o remitente"
override val share_option: String = "Compartir"
override val ship_to: String = "Enviar a"
override val sign_in: String = "Rexístrate"
override val sign_in_apple: String = "Inicia sesión con Apple"
override val sign_in_email: String = "Inicia sesión co correo electrónico"
override val sign_in_facebook: String = "Inicia sesión con Facebook"
override val sign_in_google: String = "Inicia sesión con Google"
override val sign_up: String = "Incribirse"
override val sign_up_label: String = "Non tes unha conta?"
override val sign_up_link_label: String = "Incribirse!"
override val signatures_option: String = "Sinaturas"
override val skip: String = "Saltar"
override val sold_items_report: String = "Artigos vendidos"
override val sort_by: String = "Ordenar por:"
override val status_update: String = "Actualización do estado do documento"
override val sub_annual: String = "Anuais"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/ano" }
override val sub_backups: String = "Copias de seguridade na nube"
override val sub_insights: String = "Insights"
override val sub_month: String = "Mensual"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mes" }
override val sub_notifications: String = "Notificacións"
override val sub_popup_message: String = "As subscricións chegarán en breve. Volve consultar aquí dentro dun tempo."
override val sub_support: String = "Soporte"
override val sub_templates: String = "Modelos"
override val subscribe: String = "Subscribirse"
override val subscription_header: String = "Fai crecer o teu negocio con:"
override val subscription_option: String = "Subscrición"
override val subscriptions_load_fail: String = "Sentímolo, non podemos recuperar as túas subscricións neste momento. Téntao de novo máis tarde ou ponte en contacto co noso equipo de asistencia para obter axuda."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "Subtotal"
override val sum: String = "Suma"
override val support_option: String = "Apoiar"
override val switch_reports: String = "Informe de cambio"
override val tax: String = "Imposto"
override val tax_deleted_message: String = "Imposto eliminado"
override val tax_description_warning: String = "Se se usa, aparece nunha factura"
override val tax_input_flat: String = "Plano"
override val tax_input_percent: String = "Por cento"
override val tax_number_fields: String = "Número de impostos"
override val tax_number_hint: String = "Número de impostos"
override val tax_query_info_description: String = "Consulta información"
override val tax_report: String = "Imposto"
override val tax_rules_option: String = "Normas Tributarias"
override val taxed_clients_report: String = "Clientes Impostos"
override val template: String = "Modelo"
override val template_selector_info: String = "Pasa o dedo para cambiar o modelo predeterminado"
override val template_unlock_promo: String = "Accede a máis modelos con Premium"
override val templates_option: String = "Modelos"
override val terms_of_service: String = "Condicións de servicio"
override val thank_you: String = "Grazas"
override val to: String = "Para"
override val total: Formattable = Formattable { (sum) -> "Total: ${sum}" }
override val total_label: String = "Total"
override val undo_action: String = "Desfacer"
override val unit: String = "Unidade"
override val unit_cost_hint: String = "Custo unitario"
override val unknown_client: String = "Cliente descoñecido"
override val unlock: String = "Desbloquear"
override val unnamed_item: String = "Elemento sen nome"
override val upgrade: String = "Actualizar"
override val user_collision_error: String = "A conta xa existe."
override val valid_until: String = "Válido ata"
override val warning: String = "Aviso!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Acepta ${termsOfService} e ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Código non válido.",
	"add_client_action" to "Engadir cliente",
	"add_customer" to "Engadir cliente",
	"add_expense" to "Engadir gasto",
	"add_fields_label" to "Engadir:",
	"add_invoice_action" to "Crear",
	"add_item_action" to "Engadir elemento",
	"add_item_button" to "Engadir elemento",
	"add_signature_action" to "Engadir sinatura",
	"add_tax_action" to "Engadir impostos",
	"address_fields" to "Enderezo",
	"address_line_hint" to "Enderezo",
	"advanced" to "Avanzado",
	"agree_marketing" to "Acepto recibir comunicacións de mercadotecnia directa",
	"all_notifications" to "Todas as notificacións",
	"amortization" to "amortization",
	"amount" to "Cantidade",
	"asset" to "asset",
	"attach_file" to "Achegar ficheiro",
	"attachment" to "Anexo",
	"auth_error" to "Produciuse un erro na acción. Téntao de novo.",
	"available_for_paid" to "A sincronización na nube só está dispoñible baixo a subscrición de pago.",
	"backups_option" to "Copias de seguridade",
	"balance" to "balance",
	"bank_account" to "Conta bancaria",
	"bill_number" to "Número de factura",
	"cancel" to "Cancelar",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Categorías",
	"category" to "Categoría",
	"category_add" to "Engadir categoría",
	"category_delete" to "Eliminar categoría",
	"category_name" to "Nome da categoría",
	"change_language" to Formattable { (language) -> "Idioma da factura: ${language}" },
	"change_language_label" to "Idioma da factura",
	"change_template" to "Cambiar modelo",
	"city_hint" to "Cidade",
	"clear" to "Limpar",
	"client" to "Clienta",
	"client_actions" to "Accións do cliente",
	"client_deleted_message" to "Cliente eliminado",
	"clients_error_required_fields" to "Requírese polo menos un valor.",
	"clients_option" to "Clientes",
	"close" to "Pechar",
	"cloud_sync" to "Sincronización na nube",
	"coming_soon" to "Proximamente!",
	"company_logo" to "Logotipo da empresa",
	"company_name_hint" to "Compañía",
	"confirm_password_label" to "Confirme o contrasinal",
	"confirmation_title" to "Estás seguro?",
	"convert_to_invoice" to "Converter en factura",
	"country_hint" to "País",
	"create_client" to "Crear cliente",
	"create_company" to "Crear empresa",
	"create_document" to "Crear documento",
	"create_estimate" to "Estimación",
	"create_invoice" to "Factura",
	"create_item" to "Crear elemento",
	"create_new_client" to "Crear novo cliente",
	"create_new_item" to "Crear novo elemento",
	"create_new_tax" to "Crear un novo imposto",
	"create_signature" to "Sinatura",
	"created" to "Creado",
	"credit" to "credit",
	"customer" to "Cliente",
	"date" to "Data",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Moeda predeterminada: ${currency}" },
	"default_currency_label" to "Moeda predeterminada",
	"delete" to "Borrar",
	"delete_account" to "Eliminar conta",
	"delete_confirmation_message" to "Todos os teus datos perderanse.",
	"delete_item_title" to "Queres eliminar o elemento?",
	"delete_signature" to "Eliminar a sinatura",
	"depreciation" to "depreciation",
	"description" to "Descrición",
	"description_hint" to "Descrición",
	"detailed_report" to "Informe detallado",
	"disabled" to "Desactivado",
	"discount" to "Desconto",
	"discount_hint" to "Desconto",
	"display_af" to "africano",
	"display_am" to "amárico",
	"display_ar" to "árabe",
	"display_as" to "asamés",
	"display_az" to "azerbaiyano",
	"display_be" to "bielorruso",
	"display_bg" to "búlgaro",
	"display_bn" to "bengalí",
	"display_bs" to "bosníaco",
	"display_ca" to "Catalán",
	"display_cs" to "checo",
	"display_da" to "danés",
	"display_de" to "Alemán",
	"display_el" to "grego",
	"display_en" to "Inglés",
	"display_es" to "Español",
	"display_es_MX" to "español (México)",
	"display_et" to "Estonio",
	"display_eu" to "vasco",
	"display_fa" to "persa",
	"display_fi" to "finés",
	"display_fil" to "Filipino",
	"display_fr" to "francés",
	"display_fr_CA" to "francés canadense",
	"display_gl" to "Galego",
	"display_gu" to "Gujarati",
	"display_he" to "hebreo",
	"display_hi" to "non",
	"display_hr" to "croata",
	"display_hu" to "húngaro",
	"display_hy" to "armenio",
	"display_id" to "indonesio",
	"display_is" to "islandés",
	"display_it" to "italiano",
	"display_ja" to "xaponés",
	"display_ka" to "xeorxiano",
	"display_kk" to "Casaco",
	"display_km" to "Khmer central",
	"display_kn" to "Kannada",
	"display_ko" to "coreano",
	"display_ky" to "Kirguiz",
	"display_lo" to "Laboral",
	"display_lt" to "lituano",
	"display_lv" to "letón",
	"display_mk" to "macedonio",
	"display_ml" to "malayalam",
	"display_mn" to "mongol",
	"display_mr" to "Marathi",
	"display_ms" to "malaio",
	"display_my" to "birmano",
	"display_nb" to "noruegués bokmål",
	"display_ne" to "nepalí",
	"display_nl" to "holandés",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "polaco",
	"display_pt" to "portugués",
	"display_pt_BR" to "Portugués (Brasil)",
	"display_pt_PT" to "portugués europeo",
	"display_ro" to "romanés",
	"display_ru" to "ruso",
	"display_si" to "Cingalés",
	"display_sk" to "eslovaco",
	"display_sl" to "esloveno",
	"display_sq" to "albanés",
	"display_sr" to "serbio",
	"display_sv" to "sueco",
	"display_sw" to "suahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "tailandés",
	"display_tr" to "turco",
	"display_uk" to "ucraíno",
	"display_ur" to "Urdú",
	"display_uz" to "uzbeco",
	"display_vi" to "vietnamita",
	"display_zh_Hant_TW" to "Chinés (Taiwán, Tradicional)",
	"display_zu" to "Zulú",
	"document_estimate" to "Estimación",
	"document_invoice" to "Factura",
	"document_quote" to "Cita",
	"done" to "Feito",
	"download_app" to "Descarga a aplicación",
	"download_invoice" to "Descargar factura",
	"download_pdf" to "Descargar PDF",
	"draw_signature" to "Debuxa unha sinatura",
	"due" to Formattable { (date) -> "Vencemento ${date}" },
	"due_date" to "Data de caducidade",
	"due_day" to "nun día",
	"due_label" to "Debido",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} vence ${dueWhen}!" },
	"due_today" to "Hoxe",
	"due_week" to "nunha semana",
	"duplicate" to "Duplicar",
	"duration_day" to "día",
	"duration_month" to "Mes",
	"duration_week" to "semana",
	"duration_year" to "ano",
	"email" to "Correo electrónico",
	"email_blank_error" to "Correo electrónico necesario.",
	"email_error" to "Enderezo de correo electrónico non válido.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Grazas polo teu negocio. Para ver ${documentType} completo, fai clic no botón ao final desta carta. Ten en conta que é válido ata ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Recibiches un novo${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "VER ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Estimado ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Grazas polo teu negocio. Para ver ${documentType} completo, fai clic no botón ao final desta carta. Paga o saldo ata ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Recibiches un novo${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "Enviouse o correo electrónico para restablecer o contrasinal, revisa a túa caixa de entrada.",
	"empty_clients_list_message" to "Non tes clientes que rastrexar. Engade un novo cliente.",
	"empty_estimate_list_message" to "Crea a túa primeira estimación",
	"empty_expenses" to "Non tes gastos para rastrexar. Engade un novo gasto.",
	"empty_invoice_list_message" to "Crea a túa primeira factura",
	"empty_items_list_message" to "Non tes elementos, engade un novo!",
	"empty_signatures_list_message" to "Non se atoparon sinaturas. Crea un!",
	"empty_taxes_list_message" to "Non tes regras fiscais. Engade unha nova norma fiscal.",
	"empty_templates_list_message" to "Modelos non dispoñibles.",
	"enabled" to "Activado",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Estimación #",
	"estimates" to "Estimacións",
	"estimates_report" to "Estimacións",
	"expense_bank_fees" to "Comisións bancarias",
	"expense_benefits" to "Beneficios",
	"expense_communication" to "Comunicación",
	"expense_debt" to "Débeda",
	"expense_deleted" to "Gasto eliminado",
	"expense_depreciation" to "Amortización",
	"expense_gifts" to "Agasallos",
	"expense_insurance" to "Seguros",
	"expense_inventory" to "Inventario",
	"expense_legal" to "Legal",
	"expense_maintenance" to "Mantemento",
	"expense_marketing" to "Márketing",
	"expense_meals" to "Comidas",
	"expense_office_supplies" to "Material de oficina",
	"expense_rent" to "Aluguer",
	"expense_salaries" to "Salarios",
	"expense_shopping" to "Compras",
	"expense_software" to "Software",
	"expense_status_available" to "Dispoñible",
	"expense_status_processing" to "Procesamento",
	"expense_tax" to "Imposto",
	"expense_training" to "Formación",
	"expense_transport" to "Transporte",
	"expense_travel" to "Viaxar",
	"expense_utility" to "Utilidade",
	"expenses" to "Gastos",
	"feedback_option" to "Comentarios",
	"filter" to "Filtro",
	"first_name_hint" to "Nome",
	"forgot_password" to "Restablecer o contrasinal",
	"forgot_password_label" to "Esqueciches o contrasinal",
	"from" to "Desde",
	"general_error" to "Produciuse un erro, téntao de novo máis tarde.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Volve atrás",
	"group_by" to "Agrupar por:",
	"image_view" to "imaxe",
	"install" to "Instalar",
	"invalid_credentials_error" to "Credenciais non válidas.",
	"invalid_email_error" to "Correo electrónico non válido.",
	"invalid_user_error" to "Credenciais de usuario non válidas.",
	"invoice_deleted_message" to "Factura eliminada",
	"invoice_menu" to "Menú de factura",
	"invoice_number" to "Factura #",
	"invoices" to "Facturas",
	"items_option" to "Elementos",
	"keep" to "Mantéñase",
	"language_selector" to Formattable { (seleccionado) -> "Idioma ${seleccionado}" },
	"last_name_hint" to "Apelido",
	"ledger" to "ledger",
	"legal" to "Legal",
	"legal_error" to "Debe aceptar as Condicións de servizo e a Política de privacidade",
	"liabilities" to "liabilities",
	"loading" to "Cargando",
	"login_required_error" to "É necesario iniciar sesión.",
	"logo_view" to "Logo",
	"logout" to "Pechar sesión",
	"logout_prompt" to "Os teus datos non están sincronizados coa nube. Queres eliminar facturas, estimacións, clientes e outra información ao pechar a sesión?",
	"main_screen" to "Pantalla principal",
	"mark_paid" to "Marcar como pago",
	"message" to "Mensaxe",
	"missing_sender_msg" to "É posible que un documento sen información do remitente non sexa válido.",
	"missing_sender_title" to "Falta información do remitente",
	"month_april" to "abril",
	"month_april_short" to "abril",
	"month_august" to "agosto",
	"month_august_short" to "ago.",
	"month_december" to "decembro",
	"month_december_short" to "Dec.",
	"month_february" to "febreiro",
	"month_february_short" to "febreiro",
	"month_january" to "xaneiro",
	"month_january_short" to "Xaneiro",
	"month_july" to "xullo",
	"month_july_short" to "xullo",
	"month_june" to "xuño",
	"month_june_short" to "xuño",
	"month_march" to "marzo",
	"month_march_short" to "marzo",
	"month_may" to "maio",
	"month_may_short" to "maio",
	"month_november" to "novembro",
	"month_november_short" to "nov.",
	"month_october" to "Outubro",
	"month_october_short" to "outubro",
	"month_september" to "setembro",
	"month_september_short" to "setembro",
	"multifactor_error" to "Produciuse un erro ao verificar.",
	"new" to "novo",
	"news" to "Novidades",
	"next" to "A continuación",
	"no" to "no",
	"no_data_available" to "Non hai datos dispoñibles",
	"no_search_results" to "Sentímolo, non puidemos atopar ningún resultado.",
	"none" to "Ningún",
	"note_fields" to "Notas",
	"note_hint" to "Nota",
	"notifications_option" to "Notificacións",
	"ok" to "Ok",
	"onboarding" to "Incorporación",
	"onboarding_aboutus_message" to "As partes esenciais de DingVoice son gratuítas para sempre. A nosa misión é axudarche a facer crecer o teu negocio, axúdanos a mellorar compartindo os teus comentarios.",
	"onboarding_aboutus_title" to "Estamos aquí para axudar",
	"onboarding_paid_1" to "Accede a modelos de aspecto profesional",
	"onboarding_paid_2" to "Bótalle unha ollada máis profunda ás túas finanzas",
	"onboarding_paid_3" to "Resolva os problemas rapidamente",
	"onboarding_paid_4" to "Mantén seguros os teus documentos",
	"onboarding_paid_5" to "Mantente ao día dos cambios de estado das facturas",
	"onboarding_paid_title" to "Crece aínda máis rápido con...",
	"onboarding_paywall_message" to "Goza de todas as funcións premium agora ao mellor prezo.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Só ${currencySymbol}${introAmount} por ${introDuration} ${introPeriod} e despois ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Só ${currencySymbol}${amount}/${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis por ${trialDuration} ${trialPeriod} e despois só ${currencySymbol}${amount}/${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis por ${trialDuration} ${trialPeriod}, despois ${currencySymbol}${introductoryAmount} por ${introductoryDuration} ${introPeriod} e despois só ${currencySymbol}${amount}/${duration}" },
	"onboarding_paywall_renewable" to "Auto-renovable. Cancela en calquera momento.",
	"onboarding_paywall_restore" to "Restaurar compra",
	"onboarding_paywall_restore_failure" to "Produciuse un erro ao restaurar as compras. Téntao de novo máis tarde ou ponte en contacto co servizo de asistencia.",
	"onboarding_paywall_restore_success" to "Restauración exitosa!",
	"onboarding_paywall_title" to "Acada a grandeza!",
	"onboarding_welcome_message" to "Un conxunto completo de ferramentas para xestionar as súas facturas e estimacións.",
	"onboarding_welcome_title" to "Benvido a DingVoice",
	"other_state" to "Outro",
	"overdue_state" to "Retraso",
	"paid_state" to "Pagado",
	"password" to "Contrasinal",
	"password_blank_error" to "Requírese o contrasinal.",
	"password_mismatch_error" to "Os contrasinais non coinciden.",
	"pay" to "pay",
	"payment_instructions_label" to "Instrucións de pago",
	"payment_reminders" to "Recordatorios de pago",
	"phone_number_fields" to "Número de teléfono",
	"phone_number_hint" to "Número de teléfono",
	"postcode_hint" to "Código postal",
	"preview" to "Vista previa",
	"preview_menu_hint" to "Menú de vista previa",
	"price" to "Prezo",
	"privacy_policy" to "Política de privacidade",
	"profile" to "Perfil",
	"profitable_client" to "O teu cliente máis rendible é...",
	"profits_report" to "Beneficios",
	"promo_content" to "Contido promocional",
	"quantity_hint" to "Cantidade",
	"quantity_label_hint" to "Etiqueta de cantidade",
	"query_info" to "A consulta permítelle escribir cálculos personalizados referidos tanto a elementos como a outras regras fiscais como entradas. Engade o símbolo @ e a descrición do artigo ou do imposto para vincular a outra entrada +, -, *, /, % - operadores matemáticos",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "O teu cliente recibiu ${documentType} ${documentNumber}." },
	"received_state" to "Recibido",
	"registration_code_fields" to "Código de rexistro",
	"reminder" to "Recordatorio",
	"reports" to "Informes",
	"reports_not_available" to "Non hai informes dispoñibles, volve máis tarde.",
	"reveal_password" to "Revelar o contrasinal",
	"revenue" to "revenue",
	"sales_tax" to "IVE",
	"sales_tax_number" to "número de IVE",
	"save" to "Gardar",
	"scan_document" to "Escanear documento",
	"search" to "Buscar",
	"select" to "Seleccione",
	"select_icon" to "Seleccione a icona",
	"select_signature" to "Seleccione a sinatura",
	"select_template" to "Seleccione o modelo",
	"send" to "Enviar",
	"sent_state" to "Enviado",
	"setup_sender" to "Configurar o remitente",
	"share_option" to "Compartir",
	"ship_to" to "Enviar a",
	"sign_in" to "Rexístrate",
	"sign_in_apple" to "Inicia sesión con Apple",
	"sign_in_email" to "Inicia sesión co correo electrónico",
	"sign_in_facebook" to "Inicia sesión con Facebook",
	"sign_in_google" to "Inicia sesión con Google",
	"sign_up" to "Incribirse",
	"sign_up_label" to "Non tes unha conta?",
	"sign_up_link_label" to "Incribirse!",
	"signatures_option" to "Sinaturas",
	"skip" to "Saltar",
	"sold_items_report" to "Artigos vendidos",
	"sort_by" to "Ordenar por:",
	"status_update" to "Actualización do estado do documento",
	"sub_annual" to "Anuais",
	"sub_annual_price" to Formattable { (price) -> "${price}/ano" },
	"sub_backups" to "Copias de seguridade na nube",
	"sub_insights" to "Insights",
	"sub_month" to "Mensual",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mes" },
	"sub_notifications" to "Notificacións",
	"sub_popup_message" to "As subscricións chegarán en breve. Volve consultar aquí dentro dun tempo.",
	"sub_support" to "Soporte",
	"sub_templates" to "Modelos",
	"subscribe" to "Subscribirse",
	"subscription_header" to "Fai crecer o teu negocio con:",
	"subscription_option" to "Subscrición",
	"subscriptions_load_fail" to "Sentímolo, non podemos recuperar as túas subscricións neste momento. Téntao de novo máis tarde ou ponte en contacto co noso equipo de asistencia para obter axuda.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "Subtotal",
	"sum" to "Suma",
	"support_option" to "Apoiar",
	"switch_reports" to "Informe de cambio",
	"tax" to "Imposto",
	"tax_deleted_message" to "Imposto eliminado",
	"tax_description_warning" to "Se se usa, aparece nunha factura",
	"tax_input_flat" to "Plano",
	"tax_input_percent" to "Por cento",
	"tax_number_fields" to "Número de impostos",
	"tax_number_hint" to "Número de impostos",
	"tax_query_info_description" to "Consulta información",
	"tax_report" to "Imposto",
	"tax_rules_option" to "Normas Tributarias",
	"taxed_clients_report" to "Clientes Impostos",
	"template" to "Modelo",
	"template_selector_info" to "Pasa o dedo para cambiar o modelo predeterminado",
	"template_unlock_promo" to "Accede a máis modelos con Premium",
	"templates_option" to "Modelos",
	"terms_of_service" to "Condicións de servicio",
	"thank_you" to "Grazas",
	"to" to "Para",
	"total" to Formattable { (sum) -> "Total: ${sum}" },
	"total_label" to "Total",
	"undo_action" to "Desfacer",
	"unit" to "Unidade",
	"unit_cost_hint" to "Custo unitario",
	"unknown_client" to "Cliente descoñecido",
	"unlock" to "Desbloquear",
	"unnamed_item" to "Elemento sen nome",
	"upgrade" to "Actualizar",
	"user_collision_error" to "A conta xa existe.",
	"valid_until" to "Válido ata",
	"warning" to "Aviso!",
	"yes" to "yes",
    )
    
}