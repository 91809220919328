// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Zh_Hant_TW  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "接受 ${termsOfService} 和 ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "无效代码。"
override val add_client_action: String = "添加客户"
override val add_customer: String = "添加客户"
override val add_expense: String = "新增支出項目"
override val add_fields_label: String = "加。"
override val add_invoice_action: String = "创建"
override val add_item_action: String = "添加项目"
override val add_item_button: String = "添加项目"
override val add_signature_action: String = "添加签名"
override val add_tax_action: String = "加税"
override val address_fields: String = "地址"
override val address_line_hint: String = "地址线"
override val advanced: String = "高级"
override val agree_marketing: String = "我同意接收直销通讯"
override val all_notifications: String = "所有通知"
override val amortization: String = "amortization"
override val amount: String = "数量"
override val asset: String = "asset"
override val attach_file: String = "附加文件"
override val attachment: String = "附件"
override val auth_error: String = "行动失败，再试。"
override val available_for_paid: String = "云同步仅在付费订阅的情况下可用。"
override val backups_option: String = "备份"
override val balance: String = "balance"
override val bank_account: String = "银行账户"
override val bill_number: String = "賬單編號"
override val cancel: String = "取消"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "類別"
override val category: String = "類別"
override val category_add: String = "新增類別"
override val category_delete: String = "刪除類別"
override val category_name: String = "類別名稱"
override val change_language: Formattable = Formattable { (语言) -> "发票语言：${语言}。" }
override val change_language_label: String = "發票語言"
override val change_template: String = "更改模板"
override val city_hint: String = "城市"
override val clear: String = "清楚"
override val client: String = "客户"
override val client_actions: String = "客户行动"
override val client_deleted_message: String = "客户删除"
override val clients_error_required_fields: String = "至少需要一个值。"
override val clients_option: String = "客户"
override val close: String = "关闭"
override val cloud_sync: String = "云同步"
override val coming_soon: String = "即将推出!"
override val company_logo: String = "公司标志"
override val company_name_hint: String = "公司"
override val confirm_password_label: String = "确认密码"
override val confirmation_title: String = "您確定嗎？"
override val convert_to_invoice: String = "转换为发票"
override val country_hint: String = "国家"
override val create_client: String = "创建客户"
override val create_company: String = "创建公司"
override val create_document: String = "创建文件"
override val create_estimate: String = "估计数"
override val create_invoice: String = "发票"
override val create_item: String = "创建项目"
override val create_new_client: String = "创建新客户"
override val create_new_item: String = "创建新项目"
override val create_new_tax: String = "创建新税种"
override val create_signature: String = "签名"
override val created: String = "创建"
override val credit: String = "credit"
override val customer: String = "客户"
override val date: String = "日期"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "默认货币：${currency}。" }
override val default_currency_label: String = "預設貨幣"
override val delete: String = "删除"
override val delete_account: String = "刪除帳號"
override val delete_confirmation_message: String = "您所有的數據都將丟失。"
override val delete_item_title: String = "删除该项目？"
override val delete_signature: String = "删除签名"
override val depreciation: String = "depreciation"
override val description: String = "描述"
override val description_hint: String = "描述"
override val detailed_report: String = "详细报告"
override val disabled: String = "失能"
override val discount: String = "折扣"
override val discount_hint: String = "折扣"
override val display_af: String = "南非语"
override val display_am: String = "阿姆哈拉语"
override val display_ar: String = "阿拉伯语"
override val display_as: String = "阿萨姆语"
override val display_az: String = "阿塞拜疆语"
override val display_be: String = "白俄罗斯语"
override val display_bg: String = "保加利亚语"
override val display_bn: String = "孟加拉语"
override val display_bs: String = "波斯尼亚语"
override val display_ca: String = "加泰罗尼亚语"
override val display_cs: String = "捷克"
override val display_da: String = "丹麦"
override val display_de: String = "德国"
override val display_el: String = "希腊语"
override val display_en: String = "英语"
override val display_es: String = "西班牙"
override val display_es_MX: String = "西班牙语（墨西哥）"
override val display_et: String = "爱沙尼亚语"
override val display_eu: String = "巴斯克"
override val display_fa: String = "波斯语"
override val display_fi: String = "芬兰语"
override val display_fil: String = "菲律宾人"
override val display_fr: String = "法国"
override val display_fr_CA: String = "加拿大法语"
override val display_gl: String = "加利西亚语"
override val display_gu: String = "古吉拉特语"
override val display_he: String = "希伯来语"
override val display_hi: String = "印地语"
override val display_hr: String = "克罗地亚语"
override val display_hu: String = "匈牙利语"
override val display_hy: String = "亚美尼亚语"
override val display_id: String = "印尼语"
override val display_is: String = "冰岛语"
override val display_it: String = "意大利语"
override val display_ja: String = "日本人"
override val display_ka: String = "格鲁吉亚"
override val display_kk: String = "哈萨克"
override val display_km: String = "中高棉语"
override val display_kn: String = "卡纳达语"
override val display_ko: String = "韩国"
override val display_ky: String = "柯尔克孜族"
override val display_lo: String = "老挝"
override val display_lt: String = "立陶宛语"
override val display_lv: String = "拉脱维亚语"
override val display_mk: String = "马其顿语"
override val display_ml: String = "拉雅拉姆语"
override val display_mn: String = "蒙古语"
override val display_mr: String = "马拉提语"
override val display_ms: String = "马来语"
override val display_my: String = "缅甸语"
override val display_nb: String = "挪威语"
override val display_ne: String = "尼泊尔语"
override val display_nl: String = "荷兰语"
override val display_or: String = "奥里亚语"
override val display_pa: String = "旁遮普语"
override val display_pl: String = "波兰语"
override val display_pt: String = "葡萄牙语"
override val display_pt_BR: String = "葡萄牙语(巴西)"
override val display_pt_PT: String = "欧洲葡萄牙语"
override val display_ro: String = "罗马尼亚语"
override val display_ru: String = "俄罗斯"
override val display_si: String = "僧伽罗语"
override val display_sk: String = "斯洛伐克语"
override val display_sl: String = "斯洛文尼亚语"
override val display_sq: String = "阿尔巴尼亚语"
override val display_sr: String = "塞尔维亚语"
override val display_sv: String = "瑞典语"
override val display_sw: String = "斯瓦希里语"
override val display_ta: String = "泰米尔语"
override val display_te: String = "泰卢固语"
override val display_th: String = "泰国"
override val display_tr: String = "土耳其"
override val display_uk: String = "乌克兰语"
override val display_ur: String = "乌尔都语"
override val display_uz: String = "乌兹别克语"
override val display_vi: String = "越南语"
override val display_zh_Hant_TW: String = "中文（台灣，繁體）"
override val display_zu: String = "祖鲁人"
override val document_estimate: String = "估計"
override val document_invoice: String = "發票"
override val document_quote: String = "報價"
override val done: String = "已完成"
override val download_app: String = "下载应用程序"
override val download_invoice: String = "下载发票"
override val download_pdf: String = "下载PDF"
override val draw_signature: String = "画一个签名"
override val due: Formattable = Formattable { (日期) -> "应付${日期}" }
override val due_date: String = "到期日"
override val due_day: String = "在一天之内"
override val due_label: String = "应付"
override val due_notification_body: Formattable = Formattable { (文件类型, 文件编号, 逾期时间) -> "${文件类型}${文件编号}的到期日为${逾期时间}!" }
override val due_today: String = "今天"
override val due_week: String = "在一个星期内"
override val duplicate: String = "複製"
override val duration_day: String = "天"
override val duration_month: String = "月"
override val duration_week: String = "星期"
override val duration_year: String = "年"
override val email: String = "电子邮件"
override val email_blank_error: String = "需要电子邮件。"
override val email_error: String = "无效的电子邮件地址。"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "感謝您的業務。若要檢視完整的 ${documentType}，請按一下此字母末尾的按鈕。請注意，它的有效期是到 ${dueDate}。" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "您已收到新的 ${documentType} ${invoiceNumber}！" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "查看 ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "亲爱的${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "感谢你的业务。要查看完整的 ${documentType}，请点击本信末尾的按钮。请将余额支付到 ${dueDate}。" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "您已收到新的 ${documentType} ${invoiceNumber}！" }
override val email_reset_success_message: String = "重置密码的电子邮件已经发出，请检查您的收件箱。"
override val empty_clients_list_message: String = "你没有客户可以追踪。添加一个新的客户。"
override val empty_estimate_list_message: String = "创建你的第一个估计"
override val empty_expenses: String = "您沒有需要追蹤的費用。新增支出項目。"
override val empty_invoice_list_message: String = "创建你的第一张发票"
override val empty_items_list_message: String = "您没有项目，请添加一个新项目!"
override val empty_signatures_list_message: String = "没有找到签名。创建一个!"
override val empty_taxes_list_message: String = "你没有税收规则。添加一个新的税收规则。"
override val empty_templates_list_message: String = "模板不可用。"
override val enabled: String = "已启用"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "估計 #"
override val estimates: String = "估算"
override val estimates_report: String = "估算"
override val expense_bank_fees: String = "銀行費用"
override val expense_benefits: String = "好處"
override val expense_communication: String = "溝通"
override val expense_debt: String = "債務"
override val expense_deleted: String = "費用項目已刪除"
override val expense_depreciation: String = "折舊"
override val expense_gifts: String = "禮物"
override val expense_insurance: String = "保險"
override val expense_inventory: String = "庫存"
override val expense_legal: String = "法律"
override val expense_maintenance: String = "保養"
override val expense_marketing: String = "市場營銷"
override val expense_meals: String = "膳食"
override val expense_office_supplies: String = "辦公室文儀用品"
override val expense_rent: String = "房租"
override val expense_salaries: String = "工資"
override val expense_shopping: String = "購物"
override val expense_software: String = "購物"
override val expense_status_available: String = "可用的"
override val expense_status_processing: String = "處理"
override val expense_tax: String = "稅"
override val expense_training: String = "訓練"
override val expense_transport: String = "交通"
override val expense_travel: String = "旅行"
override val expense_utility: String = "雜費"
override val expenses: String = "支出"
override val feedback_option: String = "反馈信息"
override val filter: String = "过滤器"
override val first_name_hint: String = "名字"
override val forgot_password: String = "重置密码"
override val forgot_password_label: String = "忘记密码"
override val from: String = "来自"
override val general_error: String = "发生了错误，请稍后再试。"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "返回首页"
override val group_by: String = "集团由。"
override val image_view: String = "图片"
override val install: String = "安装"
override val invalid_credentials_error: String = "无效凭证。"
override val invalid_email_error: String = "无效的电子邮件。"
override val invalid_user_error: String = "无效的用户凭证。"
override val invoice_deleted_message: String = "删除的发票"
override val invoice_menu: String = "发票菜单"
override val invoice_number: String = "发票号"
override val invoices: String = "发票"
override val items_option: String = "项目"
override val keep: String = "保持"
override val language_selector: Formattable = Formattable { (所选) -> "语言 ${所选}" }
override val last_name_hint: String = "姓氏"
override val ledger: String = "ledger"
override val legal: String = "法律"
override val legal_error: String = "必须接受服务条款和隐私政策"
override val liabilities: String = "liabilities"
override val loading: String = "负载"
override val login_required_error: String = "需要登录。"
override val logo_view: String = "徽标"
override val logout: String = "登出"
override val logout_prompt: String = "你的数据没有与云端同步。退出时删除发票、估算、客户和其他信息？"
override val main_screen: String = "主屏幕"
override val mark_paid: String = "标记为已付款"
override val message: String = "留言"
override val missing_sender_msg: String = "沒有寄件者資訊的文件可能無效。"
override val missing_sender_title: String = "缺少寄件者資訊"
override val month_april: String = "四月"
override val month_april_short: String = "四月"
override val month_august: String = "八月"
override val month_august_short: String = "八月"
override val month_december: String = "十二月"
override val month_december_short: String = "十二月"
override val month_february: String = "二月"
override val month_february_short: String = "二月"
override val month_january: String = "一月"
override val month_january_short: String = "一月"
override val month_july: String = "七月"
override val month_july_short: String = "七月"
override val month_june: String = "六月"
override val month_june_short: String = "六月"
override val month_march: String = "三月"
override val month_march_short: String = "三月"
override val month_may: String = "五月"
override val month_may_short: String = "五月"
override val month_november: String = "十一月"
override val month_november_short: String = "十一月"
override val month_october: String = "十月"
override val month_october_short: String = "十月"
override val month_september: String = "九月"
override val month_september_short: String = "九月"
override val multifactor_error: String = "验证失败。"
override val new: String = "新"
override val news: String = "新闻"
override val next: String = "下一页"
override val no: String = "no"
override val no_data_available: String = "无数据"
override val no_search_results: String = "对不起，我们无法找到任何结果。"
override val none: String = "沒有"
override val note_fields: String = "笔记"
override val note_hint: String = "注意事项"
override val notifications_option: String = "通知"
override val ok: String = "好的"
override val onboarding: String = "入职培训"
override val onboarding_aboutus_message: String = "DingVoice的基本部分是永远免费的。我们的使命是帮助你发展你的业务，通过分享你的反馈帮助我们改进。"
override val onboarding_aboutus_title: String = "我们在此提供帮助"
override val onboarding_paid_1: String = "访问专业的外观模板"
override val onboarding_paid_2: String = "深入研究你的财务状况"
override val onboarding_paid_3: String = "迅速获得问题解决"
override val onboarding_paid_4: String = "保持你的文件安全"
override val onboarding_paid_5: String = "保持最新的发票状态变化"
override val onboarding_paid_title: String = "增长得更快的是..."
override val onboarding_paywall_message: String = "现在以最优惠的价格享受所有高级功能。"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "只需将${currencySymbol}${introAmount}用于${introDuration}${introPeriod}，然后将${currencySymbol}${amount}除以${duration}。" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "只需 ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "在${trialDuration}${trialPeriod}期间免费，然后只需${currencySymbol}${amount} / ${duration}。" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} 免费，${currencySymbol}${introductoryAmount} ，${introductoryDuration} ${introPeriod} ，然后只需${currencySymbol}${amount} /。${duration}" }
override val onboarding_paywall_renewable: String = "自动续约。随时取消。"
override val onboarding_paywall_restore: String = "恢复购买"
override val onboarding_paywall_restore_failure: String = "恢复购买已经失败，请稍后再试或联系支持。"
override val onboarding_paywall_restore_success: String = "恢复成功!"
override val onboarding_paywall_title: String = "实现伟大!"
override val onboarding_welcome_message: String = "一个完整的工具集来管理你的发票和估算。"
override val onboarding_welcome_title: String = "欢迎来到DingVoice"
override val other_state: String = "其他"
override val overdue_state: String = "逾期未交"
override val paid_state: String = "付费"
override val password: String = "密码"
override val password_blank_error: String = "需要密码。"
override val password_mismatch_error: String = "密码不匹配。"
override val pay: String = "pay"
override val payment_instructions_label: String = "付款说明"
override val payment_reminders: String = "付款提醒"
override val phone_number_fields: String = "电话"
override val phone_number_hint: String = "电话"
override val postcode_hint: String = "邮政编号"
override val preview: String = "预览"
override val preview_menu_hint: String = "预览菜单"
override val price: String = "价格"
override val privacy_policy: String = "私隐政策"
override val profile: String = "形象"
override val profitable_client: String = "你最有利可图的客户是..."
override val profits_report: String = "利润"
override val promo_content: String = "宣传内容"
override val quantity_hint: String = "数量"
override val quantity_label_hint: String = "数量标签"
override val query_info: String = "查询允许你写自定义计算，参考项目和其他税收规则作为输入。添加@符号和项目或税收描述以链接到另一个条目 +, -, *, /, % - 数学运算符"
override val received_notification_body: Formattable = Formattable { (文件类型, 文件编号) -> "您的客户收到了${文件类型}${文件编号}。" }
override val received_state: String = "收到"
override val registration_code_fields: String = "注册代码"
override val reminder: String = "提醒"
override val reports: String = "报告"
override val reports_not_available: String = "没有报告，稍后再来。"
override val reveal_password: String = "揭开密码"
override val revenue: String = "revenue"
override val sales_tax: String = "增值税"
override val sales_tax_number: String = "增值税号"
override val save: String = "拯救"
override val scan_document: String = "掃描文件"
override val search: String = "搜索"
override val select: String = "選擇"
override val select_icon: String = "選擇圖標"
override val select_signature: String = "选择签名"
override val select_template: String = "选择模板"
override val send: String = "发送"
override val sent_state: String = "发送"
override val setup_sender: String = "設定寄件者"
override val share_option: String = "分享"
override val ship_to: String = "运送到"
override val sign_in: String = "登录"
override val sign_in_apple: String = "用苹果登录"
override val sign_in_email: String = "用电子邮件登录"
override val sign_in_facebook: String = "用Facebook登录"
override val sign_in_google: String = "用谷歌登录"
override val sign_up: String = "报名参加"
override val sign_up_label: String = "没有账户？"
override val sign_up_link_label: String = "报名参加!"
override val signatures_option: String = "签名"
override val skip: String = "跳过"
override val sold_items_report: String = "已售物品"
override val sort_by: String = "排序方式。"
override val status_update: String = "文件状态更新"
override val sub_annual: String = "年度"
override val sub_annual_price: Formattable = Formattable { (价格) -> "${价格}/年" }
override val sub_backups: String = "云备份"
override val sub_insights: String = "洞察力"
override val sub_month: String = "月度"
override val sub_monthly_price: Formattable = Formattable { (价格) -> "${价格}/月" }
override val sub_notifications: String = "通知"
override val sub_popup_message: String = "订阅很快就会到来。过一段时间再来这里看看。"
override val sub_support: String = "支持"
override val sub_templates: String = "模板"
override val subscribe: String = "订阅"
override val subscription_header: String = "通过以下方式发展你的业务。"
override val subscription_option: String = "订阅"
override val subscriptions_load_fail: String = "对不起，我们现在无法检索到您的订阅信息。请稍后再试，或联系我们的支持团队寻求帮助。"
override val subtotal: Formattable = Formattable { (sum) -> "小计：${sum}" }
override val subtotal_label: String = "小计"
override val sum: String = "总数"
override val support_option: String = "支持"
override val switch_reports: String = "开关报告"
override val tax: String = "税收"
override val tax_deleted_message: String = "删除的税收"
override val tax_description_warning: String = "如果使用，这将显示在发票上"
override val tax_input_flat: String = "平坦"
override val tax_input_percent: String = "百分比"
override val tax_number_fields: String = "税号"
override val tax_number_hint: String = "税号"
override val tax_query_info_description: String = "查询信息"
override val tax_report: String = "税收"
override val tax_rules_option: String = "税收规则"
override val taxed_clients_report: String = "征税的客户"
override val template: String = "模板"
override val template_selector_info: String = "扫一扫，改变默认模板"
override val template_unlock_promo: String = "使用高级版获取更多模板"
override val templates_option: String = "模板"
override val terms_of_service: String = "服务条款"
override val thank_you: String = "谢谢你"
override val to: String = "至"
override val total: Formattable = Formattable { (sum) -> "总计：${sum}" }
override val total_label: String = "共计"
override val undo_action: String = "撤销"
override val unit: String = "单位"
override val unit_cost_hint: String = "单位成本"
override val unknown_client: String = "未知客户"
override val unlock: String = "解锁"
override val unnamed_item: String = "无名物品"
override val upgrade: String = "升级"
override val user_collision_error: String = "帳戶已存在。"
override val valid_until: String = "有效期至"
override val warning: String = "警告!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "接受 ${termsOfService} 和 ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "无效代码。",
	"add_client_action" to "添加客户",
	"add_customer" to "添加客户",
	"add_expense" to "新增支出項目",
	"add_fields_label" to "加。",
	"add_invoice_action" to "创建",
	"add_item_action" to "添加项目",
	"add_item_button" to "添加项目",
	"add_signature_action" to "添加签名",
	"add_tax_action" to "加税",
	"address_fields" to "地址",
	"address_line_hint" to "地址线",
	"advanced" to "高级",
	"agree_marketing" to "我同意接收直销通讯",
	"all_notifications" to "所有通知",
	"amortization" to "amortization",
	"amount" to "数量",
	"asset" to "asset",
	"attach_file" to "附加文件",
	"attachment" to "附件",
	"auth_error" to "行动失败，再试。",
	"available_for_paid" to "云同步仅在付费订阅的情况下可用。",
	"backups_option" to "备份",
	"balance" to "balance",
	"bank_account" to "银行账户",
	"bill_number" to "賬單編號",
	"cancel" to "取消",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "類別",
	"category" to "類別",
	"category_add" to "新增類別",
	"category_delete" to "刪除類別",
	"category_name" to "類別名稱",
	"change_language" to Formattable { (语言) -> "发票语言：${语言}。" },
	"change_language_label" to "發票語言",
	"change_template" to "更改模板",
	"city_hint" to "城市",
	"clear" to "清楚",
	"client" to "客户",
	"client_actions" to "客户行动",
	"client_deleted_message" to "客户删除",
	"clients_error_required_fields" to "至少需要一个值。",
	"clients_option" to "客户",
	"close" to "关闭",
	"cloud_sync" to "云同步",
	"coming_soon" to "即将推出!",
	"company_logo" to "公司标志",
	"company_name_hint" to "公司",
	"confirm_password_label" to "确认密码",
	"confirmation_title" to "您確定嗎？",
	"convert_to_invoice" to "转换为发票",
	"country_hint" to "国家",
	"create_client" to "创建客户",
	"create_company" to "创建公司",
	"create_document" to "创建文件",
	"create_estimate" to "估计数",
	"create_invoice" to "发票",
	"create_item" to "创建项目",
	"create_new_client" to "创建新客户",
	"create_new_item" to "创建新项目",
	"create_new_tax" to "创建新税种",
	"create_signature" to "签名",
	"created" to "创建",
	"credit" to "credit",
	"customer" to "客户",
	"date" to "日期",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "默认货币：${currency}。" },
	"default_currency_label" to "預設貨幣",
	"delete" to "删除",
	"delete_account" to "刪除帳號",
	"delete_confirmation_message" to "您所有的數據都將丟失。",
	"delete_item_title" to "删除该项目？",
	"delete_signature" to "删除签名",
	"depreciation" to "depreciation",
	"description" to "描述",
	"description_hint" to "描述",
	"detailed_report" to "详细报告",
	"disabled" to "失能",
	"discount" to "折扣",
	"discount_hint" to "折扣",
	"display_af" to "南非语",
	"display_am" to "阿姆哈拉语",
	"display_ar" to "阿拉伯语",
	"display_as" to "阿萨姆语",
	"display_az" to "阿塞拜疆语",
	"display_be" to "白俄罗斯语",
	"display_bg" to "保加利亚语",
	"display_bn" to "孟加拉语",
	"display_bs" to "波斯尼亚语",
	"display_ca" to "加泰罗尼亚语",
	"display_cs" to "捷克",
	"display_da" to "丹麦",
	"display_de" to "德国",
	"display_el" to "希腊语",
	"display_en" to "英语",
	"display_es" to "西班牙",
	"display_es_MX" to "西班牙语（墨西哥）",
	"display_et" to "爱沙尼亚语",
	"display_eu" to "巴斯克",
	"display_fa" to "波斯语",
	"display_fi" to "芬兰语",
	"display_fil" to "菲律宾人",
	"display_fr" to "法国",
	"display_fr_CA" to "加拿大法语",
	"display_gl" to "加利西亚语",
	"display_gu" to "古吉拉特语",
	"display_he" to "希伯来语",
	"display_hi" to "印地语",
	"display_hr" to "克罗地亚语",
	"display_hu" to "匈牙利语",
	"display_hy" to "亚美尼亚语",
	"display_id" to "印尼语",
	"display_is" to "冰岛语",
	"display_it" to "意大利语",
	"display_ja" to "日本人",
	"display_ka" to "格鲁吉亚",
	"display_kk" to "哈萨克",
	"display_km" to "中高棉语",
	"display_kn" to "卡纳达语",
	"display_ko" to "韩国",
	"display_ky" to "柯尔克孜族",
	"display_lo" to "老挝",
	"display_lt" to "立陶宛语",
	"display_lv" to "拉脱维亚语",
	"display_mk" to "马其顿语",
	"display_ml" to "拉雅拉姆语",
	"display_mn" to "蒙古语",
	"display_mr" to "马拉提语",
	"display_ms" to "马来语",
	"display_my" to "缅甸语",
	"display_nb" to "挪威语",
	"display_ne" to "尼泊尔语",
	"display_nl" to "荷兰语",
	"display_or" to "奥里亚语",
	"display_pa" to "旁遮普语",
	"display_pl" to "波兰语",
	"display_pt" to "葡萄牙语",
	"display_pt_BR" to "葡萄牙语(巴西)",
	"display_pt_PT" to "欧洲葡萄牙语",
	"display_ro" to "罗马尼亚语",
	"display_ru" to "俄罗斯",
	"display_si" to "僧伽罗语",
	"display_sk" to "斯洛伐克语",
	"display_sl" to "斯洛文尼亚语",
	"display_sq" to "阿尔巴尼亚语",
	"display_sr" to "塞尔维亚语",
	"display_sv" to "瑞典语",
	"display_sw" to "斯瓦希里语",
	"display_ta" to "泰米尔语",
	"display_te" to "泰卢固语",
	"display_th" to "泰国",
	"display_tr" to "土耳其",
	"display_uk" to "乌克兰语",
	"display_ur" to "乌尔都语",
	"display_uz" to "乌兹别克语",
	"display_vi" to "越南语",
	"display_zh_Hant_TW" to "中文（台灣，繁體）",
	"display_zu" to "祖鲁人",
	"document_estimate" to "估計",
	"document_invoice" to "發票",
	"document_quote" to "報價",
	"done" to "已完成",
	"download_app" to "下载应用程序",
	"download_invoice" to "下载发票",
	"download_pdf" to "下载PDF",
	"draw_signature" to "画一个签名",
	"due" to Formattable { (日期) -> "应付${日期}" },
	"due_date" to "到期日",
	"due_day" to "在一天之内",
	"due_label" to "应付",
	"due_notification_body" to Formattable { (文件类型, 文件编号, 逾期时间) -> "${文件类型}${文件编号}的到期日为${逾期时间}!" },
	"due_today" to "今天",
	"due_week" to "在一个星期内",
	"duplicate" to "複製",
	"duration_day" to "天",
	"duration_month" to "月",
	"duration_week" to "星期",
	"duration_year" to "年",
	"email" to "电子邮件",
	"email_blank_error" to "需要电子邮件。",
	"email_error" to "无效的电子邮件地址。",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "感謝您的業務。若要檢視完整的 ${documentType}，請按一下此字母末尾的按鈕。請注意，它的有效期是到 ${dueDate}。" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "您已收到新的 ${documentType} ${invoiceNumber}！" },
	"email_invoice_cta" to Formattable { (documentType) -> "查看 ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "亲爱的${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "感谢你的业务。要查看完整的 ${documentType}，请点击本信末尾的按钮。请将余额支付到 ${dueDate}。" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "您已收到新的 ${documentType} ${invoiceNumber}！" },
	"email_reset_success_message" to "重置密码的电子邮件已经发出，请检查您的收件箱。",
	"empty_clients_list_message" to "你没有客户可以追踪。添加一个新的客户。",
	"empty_estimate_list_message" to "创建你的第一个估计",
	"empty_expenses" to "您沒有需要追蹤的費用。新增支出項目。",
	"empty_invoice_list_message" to "创建你的第一张发票",
	"empty_items_list_message" to "您没有项目，请添加一个新项目!",
	"empty_signatures_list_message" to "没有找到签名。创建一个!",
	"empty_taxes_list_message" to "你没有税收规则。添加一个新的税收规则。",
	"empty_templates_list_message" to "模板不可用。",
	"enabled" to "已启用",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "估計 #",
	"estimates" to "估算",
	"estimates_report" to "估算",
	"expense_bank_fees" to "銀行費用",
	"expense_benefits" to "好處",
	"expense_communication" to "溝通",
	"expense_debt" to "債務",
	"expense_deleted" to "費用項目已刪除",
	"expense_depreciation" to "折舊",
	"expense_gifts" to "禮物",
	"expense_insurance" to "保險",
	"expense_inventory" to "庫存",
	"expense_legal" to "法律",
	"expense_maintenance" to "保養",
	"expense_marketing" to "市場營銷",
	"expense_meals" to "膳食",
	"expense_office_supplies" to "辦公室文儀用品",
	"expense_rent" to "房租",
	"expense_salaries" to "工資",
	"expense_shopping" to "購物",
	"expense_software" to "購物",
	"expense_status_available" to "可用的",
	"expense_status_processing" to "處理",
	"expense_tax" to "稅",
	"expense_training" to "訓練",
	"expense_transport" to "交通",
	"expense_travel" to "旅行",
	"expense_utility" to "雜費",
	"expenses" to "支出",
	"feedback_option" to "反馈信息",
	"filter" to "过滤器",
	"first_name_hint" to "名字",
	"forgot_password" to "重置密码",
	"forgot_password_label" to "忘记密码",
	"from" to "来自",
	"general_error" to "发生了错误，请稍后再试。",
	"general_ledger" to "general ledger",
	"go_back_label" to "返回首页",
	"group_by" to "集团由。",
	"image_view" to "图片",
	"install" to "安装",
	"invalid_credentials_error" to "无效凭证。",
	"invalid_email_error" to "无效的电子邮件。",
	"invalid_user_error" to "无效的用户凭证。",
	"invoice_deleted_message" to "删除的发票",
	"invoice_menu" to "发票菜单",
	"invoice_number" to "发票号",
	"invoices" to "发票",
	"items_option" to "项目",
	"keep" to "保持",
	"language_selector" to Formattable { (所选) -> "语言 ${所选}" },
	"last_name_hint" to "姓氏",
	"ledger" to "ledger",
	"legal" to "法律",
	"legal_error" to "必须接受服务条款和隐私政策",
	"liabilities" to "liabilities",
	"loading" to "负载",
	"login_required_error" to "需要登录。",
	"logo_view" to "徽标",
	"logout" to "登出",
	"logout_prompt" to "你的数据没有与云端同步。退出时删除发票、估算、客户和其他信息？",
	"main_screen" to "主屏幕",
	"mark_paid" to "标记为已付款",
	"message" to "留言",
	"missing_sender_msg" to "沒有寄件者資訊的文件可能無效。",
	"missing_sender_title" to "缺少寄件者資訊",
	"month_april" to "四月",
	"month_april_short" to "四月",
	"month_august" to "八月",
	"month_august_short" to "八月",
	"month_december" to "十二月",
	"month_december_short" to "十二月",
	"month_february" to "二月",
	"month_february_short" to "二月",
	"month_january" to "一月",
	"month_january_short" to "一月",
	"month_july" to "七月",
	"month_july_short" to "七月",
	"month_june" to "六月",
	"month_june_short" to "六月",
	"month_march" to "三月",
	"month_march_short" to "三月",
	"month_may" to "五月",
	"month_may_short" to "五月",
	"month_november" to "十一月",
	"month_november_short" to "十一月",
	"month_october" to "十月",
	"month_october_short" to "十月",
	"month_september" to "九月",
	"month_september_short" to "九月",
	"multifactor_error" to "验证失败。",
	"new" to "新",
	"news" to "新闻",
	"next" to "下一页",
	"no" to "no",
	"no_data_available" to "无数据",
	"no_search_results" to "对不起，我们无法找到任何结果。",
	"none" to "沒有",
	"note_fields" to "笔记",
	"note_hint" to "注意事项",
	"notifications_option" to "通知",
	"ok" to "好的",
	"onboarding" to "入职培训",
	"onboarding_aboutus_message" to "DingVoice的基本部分是永远免费的。我们的使命是帮助你发展你的业务，通过分享你的反馈帮助我们改进。",
	"onboarding_aboutus_title" to "我们在此提供帮助",
	"onboarding_paid_1" to "访问专业的外观模板",
	"onboarding_paid_2" to "深入研究你的财务状况",
	"onboarding_paid_3" to "迅速获得问题解决",
	"onboarding_paid_4" to "保持你的文件安全",
	"onboarding_paid_5" to "保持最新的发票状态变化",
	"onboarding_paid_title" to "增长得更快的是...",
	"onboarding_paywall_message" to "现在以最优惠的价格享受所有高级功能。",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "只需将${currencySymbol}${introAmount}用于${introDuration}${introPeriod}，然后将${currencySymbol}${amount}除以${duration}。" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "只需 ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "在${trialDuration}${trialPeriod}期间免费，然后只需${currencySymbol}${amount} / ${duration}。" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} 免费，${currencySymbol}${introductoryAmount} ，${introductoryDuration} ${introPeriod} ，然后只需${currencySymbol}${amount} /。${duration}" },
	"onboarding_paywall_renewable" to "自动续约。随时取消。",
	"onboarding_paywall_restore" to "恢复购买",
	"onboarding_paywall_restore_failure" to "恢复购买已经失败，请稍后再试或联系支持。",
	"onboarding_paywall_restore_success" to "恢复成功!",
	"onboarding_paywall_title" to "实现伟大!",
	"onboarding_welcome_message" to "一个完整的工具集来管理你的发票和估算。",
	"onboarding_welcome_title" to "欢迎来到DingVoice",
	"other_state" to "其他",
	"overdue_state" to "逾期未交",
	"paid_state" to "付费",
	"password" to "密码",
	"password_blank_error" to "需要密码。",
	"password_mismatch_error" to "密码不匹配。",
	"pay" to "pay",
	"payment_instructions_label" to "付款说明",
	"payment_reminders" to "付款提醒",
	"phone_number_fields" to "电话",
	"phone_number_hint" to "电话",
	"postcode_hint" to "邮政编号",
	"preview" to "预览",
	"preview_menu_hint" to "预览菜单",
	"price" to "价格",
	"privacy_policy" to "私隐政策",
	"profile" to "形象",
	"profitable_client" to "你最有利可图的客户是...",
	"profits_report" to "利润",
	"promo_content" to "宣传内容",
	"quantity_hint" to "数量",
	"quantity_label_hint" to "数量标签",
	"query_info" to "查询允许你写自定义计算，参考项目和其他税收规则作为输入。添加@符号和项目或税收描述以链接到另一个条目 +, -, *, /, % - 数学运算符",
	"received_notification_body" to Formattable { (文件类型, 文件编号) -> "您的客户收到了${文件类型}${文件编号}。" },
	"received_state" to "收到",
	"registration_code_fields" to "注册代码",
	"reminder" to "提醒",
	"reports" to "报告",
	"reports_not_available" to "没有报告，稍后再来。",
	"reveal_password" to "揭开密码",
	"revenue" to "revenue",
	"sales_tax" to "增值税",
	"sales_tax_number" to "增值税号",
	"save" to "拯救",
	"scan_document" to "掃描文件",
	"search" to "搜索",
	"select" to "選擇",
	"select_icon" to "選擇圖標",
	"select_signature" to "选择签名",
	"select_template" to "选择模板",
	"send" to "发送",
	"sent_state" to "发送",
	"setup_sender" to "設定寄件者",
	"share_option" to "分享",
	"ship_to" to "运送到",
	"sign_in" to "登录",
	"sign_in_apple" to "用苹果登录",
	"sign_in_email" to "用电子邮件登录",
	"sign_in_facebook" to "用Facebook登录",
	"sign_in_google" to "用谷歌登录",
	"sign_up" to "报名参加",
	"sign_up_label" to "没有账户？",
	"sign_up_link_label" to "报名参加!",
	"signatures_option" to "签名",
	"skip" to "跳过",
	"sold_items_report" to "已售物品",
	"sort_by" to "排序方式。",
	"status_update" to "文件状态更新",
	"sub_annual" to "年度",
	"sub_annual_price" to Formattable { (价格) -> "${价格}/年" },
	"sub_backups" to "云备份",
	"sub_insights" to "洞察力",
	"sub_month" to "月度",
	"sub_monthly_price" to Formattable { (价格) -> "${价格}/月" },
	"sub_notifications" to "通知",
	"sub_popup_message" to "订阅很快就会到来。过一段时间再来这里看看。",
	"sub_support" to "支持",
	"sub_templates" to "模板",
	"subscribe" to "订阅",
	"subscription_header" to "通过以下方式发展你的业务。",
	"subscription_option" to "订阅",
	"subscriptions_load_fail" to "对不起，我们现在无法检索到您的订阅信息。请稍后再试，或联系我们的支持团队寻求帮助。",
	"subtotal" to Formattable { (sum) -> "小计：${sum}" },
	"subtotal_label" to "小计",
	"sum" to "总数",
	"support_option" to "支持",
	"switch_reports" to "开关报告",
	"tax" to "税收",
	"tax_deleted_message" to "删除的税收",
	"tax_description_warning" to "如果使用，这将显示在发票上",
	"tax_input_flat" to "平坦",
	"tax_input_percent" to "百分比",
	"tax_number_fields" to "税号",
	"tax_number_hint" to "税号",
	"tax_query_info_description" to "查询信息",
	"tax_report" to "税收",
	"tax_rules_option" to "税收规则",
	"taxed_clients_report" to "征税的客户",
	"template" to "模板",
	"template_selector_info" to "扫一扫，改变默认模板",
	"template_unlock_promo" to "使用高级版获取更多模板",
	"templates_option" to "模板",
	"terms_of_service" to "服务条款",
	"thank_you" to "谢谢你",
	"to" to "至",
	"total" to Formattable { (sum) -> "总计：${sum}" },
	"total_label" to "共计",
	"undo_action" to "撤销",
	"unit" to "单位",
	"unit_cost_hint" to "单位成本",
	"unknown_client" to "未知客户",
	"unlock" to "解锁",
	"unnamed_item" to "无名物品",
	"upgrade" to "升级",
	"user_collision_error" to "帳戶已存在。",
	"valid_until" to "有效期至",
	"warning" to "警告!",
	"yes" to "yes",
    )
    
}