// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Pt  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Aceite ${termsOfService} e ${privacyPolicy}" }
override val account: String = "conta"
override val accounting: String = "contabilidade"
override val accruals: String = "acréscimos"
override val action_code_error: String = "Código inválido."
override val add_client_action: String = "Adicionar cliente"
override val add_customer: String = "Adicionar cliente"
override val add_expense: String = "Adicionar despesa"
override val add_fields_label: String = "Acrescentar:"
override val add_invoice_action: String = "Criar"
override val add_item_action: String = "Adicionar item"
override val add_item_button: String = "Adicionar item"
override val add_signature_action: String = "Acrescentar assinatura"
override val add_tax_action: String = "Acrescentar imposto"
override val address_fields: String = "Endereço"
override val address_line_hint: String = "Linha de endereço"
override val advanced: String = "AVANÇADO"
override val agree_marketing: String = "Aceito receber comunicações de marketing direto"
override val all_notifications: String = "Todas as Notificações"
override val amortization: String = "amortização"
override val amount: String = "Montante"
override val asset: String = "ativo"
override val attach_file: String = "Anexar Ficheiro"
override val attachment: String = "Anexo"
override val auth_error: String = "A acção falhou, tente novamente."
override val available_for_paid: String = "A sincronização de nuvens só está disponível sob assinatura paga."
override val backups_option: String = "Cópias de segurança"
override val balance: String = "saldo"
override val bank_account: String = "Conta bancária"
override val bill_number: String = "Número da Fatura"
override val cancel: String = "Cancelar"
override val cancel_trial: String = "Cancele a qualquer momento"
override val capital: String = "capital"
override val cash: String = "dinheiro"
override val categories: String = "Categorias"
override val category: String = "Categoria"
override val category_add: String = "Adicionar Categoria"
override val category_delete: String = "Eliminar categoria"
override val category_name: String = "Nome da categoria"
override val change_language: Formattable = Formattable { (language) -> "Língua de facturação: ${language}" }
override val change_language_label: String = "Língua de Facturação"
override val change_template: String = "Alterar modelo"
override val city_hint: String = "Cidade"
override val clear: String = "Limpar"
override val client: String = "Cliente"
override val client_actions: String = "Acções do cliente"
override val client_deleted_message: String = "Cliente apagado"
override val clients_error_required_fields: String = "É necessário pelo menos um valor."
override val clients_option: String = "Clientes"
override val close: String = "Fechar"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "Em breve!"
override val company_logo: String = "Logótipo da empresa"
override val company_name_hint: String = "Empresa"
override val confirm_password_label: String = "Confirmar Palavra-passe"
override val confirmation_title: String = "Você tem certeza?"
override val convert_to_invoice: String = "Converter em factura"
override val country_hint: String = "País"
override val create_client: String = "Criar Cliente"
override val create_company: String = "Criar empresa"
override val create_document: String = "Criar documento"
override val create_estimate: String = "Estimativa"
override val create_invoice: String = "Factura"
override val create_item: String = "Criar Item"
override val create_new_client: String = "Criar novo cliente"
override val create_new_item: String = "Criar novo item"
override val create_new_tax: String = "Criar novo imposto"
override val create_signature: String = "Assinatura"
override val created: String = "criado"
override val credit: String = "crédito"
override val customer: String = "Cliente"
override val date: String = "Data"
override val debit: String = "débito"
override val debt: String = "dívida"
override val default_currency: Formattable = Formattable { (currency) -> "Moeda Padrão: ${currency}" }
override val default_currency_label: String = "Moeda padrão"
override val delete: String = "Eliminar"
override val delete_account: String = "Eliminar Conta"
override val delete_confirmation_message: String = "Todos os seus dados serão perdidos."
override val delete_item_title: String = "Eliminar o item?"
override val delete_signature: String = "Apagar assinatura"
override val depreciation: String = "depreciação"
override val description: String = "Descrição"
override val description_hint: String = "Descrição"
override val detailed_report: String = "Relatório detalhado"
override val disabled: String = "Deficientes"
override val discount: String = "Desconto"
override val discount_hint: String = "Desconto"
override val display_af: String = "Africâner"
override val display_am: String = "Amárico"
override val display_ar: String = "Árabe"
override val display_as: String = "Assamês"
override val display_az: String = "Azerbaijano"
override val display_be: String = "Bielorrusso"
override val display_bg: String = "Búlgaro"
override val display_bn: String = "Bengalês"
override val display_bs: String = "Bósnio"
override val display_ca: String = "Catalão"
override val display_cs: String = "Checo"
override val display_da: String = "Dinamarquês"
override val display_de: String = "Alemão"
override val display_el: String = "Grego"
override val display_en: String = "Inglês"
override val display_es: String = "Espanhol"
override val display_es_MX: String = "Espanhol (México)"
override val display_et: String = "Estónio"
override val display_eu: String = "Basco"
override val display_fa: String = "Persa"
override val display_fi: String = "Finlandês"
override val display_fil: String = "Filipino"
override val display_fr: String = "Francês"
override val display_fr_CA: String = "Francês Canadense"
override val display_gl: String = "Galego"
override val display_gu: String = "Guzerate"
override val display_he: String = "Hebraico"
override val display_hi: String = "Hindu"
override val display_hr: String = "Croata"
override val display_hu: String = "Húngaro"
override val display_hy: String = "Arménio"
override val display_id: String = "Indonésio"
override val display_is: String = "Islandês"
override val display_it: String = "Italiano"
override val display_ja: String = "Japonês"
override val display_ka: String = "Georgiano"
override val display_kk: String = "Cazaque"
override val display_km: String = "Khmer Central"
override val display_kn: String = "Canarês"
override val display_ko: String = "Coreano"
override val display_ky: String = "Quirguiz"
override val display_lo: String = "Laosiano"
override val display_lt: String = "Lituano"
override val display_lv: String = "Letão"
override val display_mk: String = "Macedónio"
override val display_ml: String = "Malaiala"
override val display_mn: String = "Mongol"
override val display_mr: String = "Marati"
override val display_ms: String = "Malaio"
override val display_my: String = "Birmanês"
override val display_nb: String = "Bokmål Norueguês"
override val display_ne: String = "Nepalês"
override val display_nl: String = "Holandês"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polaco"
override val display_pt: String = "Português"
override val display_pt_BR: String = "Português (Brasil)"
override val display_pt_PT: String = "Português Europeu"
override val display_ro: String = "Romeno"
override val display_ru: String = "Russo"
override val display_si: String = "Cingalês"
override val display_sk: String = "Eslovaco"
override val display_sl: String = "Esloveno"
override val display_sq: String = "Albanês"
override val display_sr: String = "Sérvio"
override val display_sv: String = "Sueco"
override val display_sw: String = "Suaíli"
override val display_ta: String = "Tâmil"
override val display_te: String = "Télugo"
override val display_th: String = "Tailandês"
override val display_tr: String = "Turco"
override val display_uk: String = "Ucraniano"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbeque"
override val display_vi: String = "Vietnamita"
override val display_zh_Hant_TW: String = "Chinês (Taiwan, Tradicional)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Estimativa"
override val document_invoice: String = "Fatura"
override val document_quote: String = "Oferta"
override val done: String = "Feito"
override val download_app: String = "Descarregar a aplicação"
override val download_invoice: String = "Descarregar fatura"
override val download_pdf: String = "Descarregar PDF"
override val draw_signature: String = "Desenhe uma assinatura"
override val due: Formattable = Formattable { (data) -> "Devido ${data}" }
override val due_date: String = "Data de vencimento"
override val due_day: String = "em um dia"
override val due_label: String = "devido"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} está vencendo ${dueWhen}!" }
override val due_today: String = "hoje"
override val due_week: String = "em uma semana"
override val duplicate: String = "Duplicar"
override val duration_day: String = "dia"
override val duration_month: String = "mês"
override val duration_week: String = "semana"
override val duration_year: String = "ano"
override val email: String = "Email"
override val email_blank_error: String = "E-mail necessário."
override val email_error: String = "Endereço de correio electrónico inválido."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Obrigado pelo seu negócio. Para ver o ${documentType} completo, clique no botão no final desta carta. Note que é válido até ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Recebeu um novo ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "VER ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Caro/a ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Obrigado pelo seu negócio. Para ver o ${documentType} completo, clique no botão no final desta carta. Por favor, pague o saldo até ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Recebeu um novo ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Email para redefinir a senha enviada, por favor verifique a sua caixa de entrada."
override val empty_clients_list_message: String = "Não tem clientes para localizar. Adicione um novo cliente."
override val empty_estimate_list_message: String = "Crie a sua primeira estimativa"
override val empty_expenses: String = "Não tem despesas para registar. Adicione uma nova despesa."
override val empty_invoice_list_message: String = "Crie a sua primeira factura"
override val empty_items_list_message: String = "Não tem artigos, adicione um novo artigo!"
override val empty_signatures_list_message: String = "Não foram encontradas assinaturas. Crie uma!"
override val empty_taxes_list_message: String = "Não tem regras fiscais. Adicione uma nova regra fiscal."
override val empty_templates_list_message: String = "Modelos indisponíveis."
override val enabled: String = "Ativado"
override val entry: String = "registo"
override val equity: String = "capital própio"
override val estimate_number: String = "Estimativa #"
override val estimates: String = "Estimativas"
override val estimates_report: String = "Estimativas"
override val expense_bank_fees: String = "Taxas Bancárias"
override val expense_benefits: String = "Benefícios"
override val expense_communication: String = "Comunicação"
override val expense_debt: String = "Dívida"
override val expense_deleted: String = "Despesa excluída"
override val expense_depreciation: String = "Depreciação"
override val expense_gifts: String = "Presentes"
override val expense_insurance: String = "Seguro"
override val expense_inventory: String = "Inventário"
override val expense_legal: String = "Legal"
override val expense_maintenance: String = "Manutenção"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Refeições"
override val expense_office_supplies: String = "Material de Escritório"
override val expense_rent: String = "Aluguer"
override val expense_salaries: String = "Salários"
override val expense_shopping: String = "Compras"
override val expense_software: String = "Programas"
override val expense_status_available: String = "Disponível"
override val expense_status_processing: String = "Em processamento"
override val expense_tax: String = "Imposto"
override val expense_training: String = "Formação"
override val expense_transport: String = "Transporte"
override val expense_travel: String = "Viagens"
override val expense_utility: String = "Utilidade"
override val expenses: String = "Despesas"
override val feedback_option: String = "Feedback"
override val filter: String = "Filtro"
override val first_name_hint: String = "Primeiro nome"
override val forgot_password: String = "Redefinir Palavra-passe"
override val forgot_password_label: String = "Esqueci-me da palavra-chave"
override val from: String = "a partir de"
override val general_error: String = "Ocorreu um erro,por favor tente novamente mais tarde."
override val general_ledger: String = "razão geral"
override val go_back_label: String = "Voltar"
override val group_by: String = "Grupo Por:"
override val image_view: String = "Imagem"
override val install: String = "Instalar"
override val invalid_credentials_error: String = "Credenciais inválidas."
override val invalid_email_error: String = "E-mail inválido."
override val invalid_user_error: String = "Credenciais de utilizador inválidas."
override val invoice_deleted_message: String = "Factura eliminada"
override val invoice_menu: String = "Menu de facturas"
override val invoice_number: String = "Factura #"
override val invoices: String = "Facturas"
override val items_option: String = "Artigos"
override val keep: String = "Guarde"
override val language_selector: Formattable = Formattable { (selected) -> "Língua ${selected}" }
override val last_name_hint: String = "Sobrenome"
override val ledger: String = "livro-razão"
override val legal: String = "Legal"
override val legal_error: String = "Deve aceitar os Termos de Serviço e a Política de Privacidade"
override val liabilities: String = "responsabilidades"
override val loading: String = "Carregamento"
override val login_required_error: String = "Login necessário."
override val logo_view: String = "Logótipo"
override val logout: String = "Sair"
override val logout_prompt: String = "Os seus dados não estão sincronizados com a nuvem. Eliminar facturas, orçamentos, clientes e outras informações sobre o logout?"
override val main_screen: String = "Tela principal"
override val mark_paid: String = "Marca como paga"
override val message: String = "Mensagem"
override val missing_sender_msg: String = "Um documento sem informações do remetente pode ser inválido."
override val missing_sender_title: String = "Informação sobre o remetente em falta"
override val month_april: String = "Abril"
override val month_april_short: String = "Abril"
override val month_august: String = "Agosto"
override val month_august_short: String = "Ago."
override val month_december: String = "Dezembro"
override val month_december_short: String = "Dez."
override val month_february: String = "Fevereiro"
override val month_february_short: String = "Fev."
override val month_january: String = "Janeiro"
override val month_january_short: String = "Jan."
override val month_july: String = "Julho"
override val month_july_short: String = "Julho"
override val month_june: String = "Junho"
override val month_june_short: String = "Junho"
override val month_march: String = "Março"
override val month_march_short: String = "Março"
override val month_may: String = "Maio"
override val month_may_short: String = "Maio"
override val month_november: String = "Novembro"
override val month_november_short: String = "Nov."
override val month_october: String = "Outubro"
override val month_october_short: String = "Out."
override val month_september: String = "Setembro"
override val month_september_short: String = "Set."
override val multifactor_error: String = "Falha na verificação."
override val new: String = "Novo"
override val news: String = "Notícias"
override val next: String = "Próximo"
override val no: String = "não"
override val no_data_available: String = "Não há dados disponíveis"
override val no_search_results: String = "Lamentamos, não conseguimos encontrar quaisquer resultados."
override val none: String = "Nenhum/a"
override val note_fields: String = "Notas"
override val note_hint: String = "Nota"
override val notifications_option: String = "Notificações"
override val ok: String = "OK"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "As partes essenciais do DingVoice são para sempre livres. A nossa missão é ajudá-lo a fazer crescer o seu negócio, ajudar-nos a melhorar, partilhando o seu feedback."
override val onboarding_aboutus_title: String = "Estamos aqui para ajudar"
override val onboarding_paid_1: String = "Aceder a modelos com aspecto profissional"
override val onboarding_paid_2: String = "Analise mais profundamente as suas finanças"
override val onboarding_paid_3: String = "Resolver problemas rapidamente"
override val onboarding_paid_4: String = "Guarde os seus documentos em segurança"
override val onboarding_paid_5: String = "Manter-se actualizado com as alterações do estado das facturas"
override val onboarding_paid_title: String = "Crescer ainda mais rápido com..."
override val onboarding_paywall_message: String = "Desfrute agora de todas as funcionalidades premium ao melhor preço."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Apenas ${currencySymbol}${introAmount} por ${introDuration} ${introPeriod} e depois ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Apenas ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Grátis por ${trialDuration} ${trialPeriod} e depois apenas ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Livre para ${trialDuration} ${trialPeriod} e depois ${currencySymbol}${introductoryAmount} para ${introductoryDuration} ${introPeriod} e depois apenas ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Auto-renovável. Cancelar a qualquer momento."
override val onboarding_paywall_restore: String = "Restaurar a compra"
override val onboarding_paywall_restore_failure: String = "O restabelecimento das compras falhou, tentar novamente mais tarde ou contactar o apoio."
override val onboarding_paywall_restore_success: String = "Restaurar o sucesso!"
override val onboarding_paywall_title: String = "Atingir a Grandeza!"
override val onboarding_welcome_message: String = "Um conjunto completo de ferramentas para gerir as suas facturas e orçamentos."
override val onboarding_welcome_title: String = "Bem-vindo à DingVoice"
override val other_state: String = "Outro"
override val overdue_state: String = "Vencido"
override val paid_state: String = "Pago"
override val password: String = "Senha"
override val password_blank_error: String = "Senha necessária."
override val password_mismatch_error: String = "As palavras-passe não coincidem."
override val pay: String = "pagar"
override val payment_instructions_label: String = "Instruções de pagamento"
override val payment_reminders: String = "Lembretes de pagamento"
override val phone_number_fields: String = "Número de telefone"
override val phone_number_hint: String = "Número de telefone"
override val postcode_hint: String = "Código postal"
override val preview: String = "Pré-visualização"
override val preview_menu_hint: String = "Menu de pré-visualização"
override val price: String = "Preço"
override val privacy_policy: String = "Política de Privacidade"
override val profile: String = "Perfil"
override val profitable_client: String = "O seu cliente mais lucrativo é..."
override val profits_report: String = "Lucros"
override val promo_content: String = "Conteúdo promocional"
override val quantity_hint: String = "Quantidade"
override val quantity_label_hint: String = "Rótulo de quantidade"
override val query_info: String = "A consulta permite-lhe escrever cálculos personalizados referentes tanto a itens como a outras regras fiscais como inputs. Adicionar @ símbolo e item ou descrição fiscal para ligar a outra entrada +, -, *, /, % - operadores matemáticos"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} foi recebido pelo seu cliente." }
override val received_state: String = "Recebido"
override val registration_code_fields: String = "Código de Registo"
override val reminder: String = "Lembrete"
override val reports: String = "Relatórios"
override val reports_not_available: String = "Não há relatórios disponíveis, volte mais tarde."
override val reveal_password: String = "Revelar palavra-chave"
override val revenue: String = "receitas"
override val sales_tax: String = "Taxa"
override val sales_tax_number: String = "Número do IVA"
override val save: String = "Guardar"
override val scan_document: String = "Digitalizar documento"
override val search: String = "Pesquisa"
override val select: String = "Selecionar"
override val select_icon: String = "Selecionar ícone"
override val select_signature: String = "Seleccionar assinatura"
override val select_template: String = "Seleccione o modelo"
override val send: String = "Enviar"
override val sent_state: String = "Enviado"
override val setup_sender: String = "Configurar remetente"
override val share_option: String = "Partilhar"
override val ship_to: String = "Enviar para"
override val sign_in: String = "Iniciar sessão"
override val sign_in_apple: String = "Iniciar sessão com a Apple"
override val sign_in_email: String = "Inicie a sessão com e-mail"
override val sign_in_facebook: String = "Inicie sessão no Facebook"
override val sign_in_google: String = "Iniciar sessão com o Google"
override val sign_up: String = "Inscrição"
override val sign_up_label: String = "Não tem uma conta?"
override val sign_up_link_label: String = "ASSINE!"
override val signatures_option: String = "Assinaturas"
override val skip: String = "Saltar"
override val sold_items_report: String = "Artigos Vendidos"
override val sort_by: String = "Ordenar por:"
override val status_update: String = "Atualização do estado do documento"
override val sub_annual: String = "Anual"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/ano" }
override val sub_backups: String = "Cópias de segurança de nuvens"
override val sub_insights: String = "Insights"
override val sub_month: String = "Mensal"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mês" }
override val sub_notifications: String = "Notificações"
override val sub_popup_message: String = "As assinaturas estão a chegar em breve. Verifique aqui dentro de pouco tempo."
override val sub_support: String = "Apoio"
override val sub_templates: String = "Modelos"
override val subscribe: String = "Subscrever"
override val subscription_header: String = "Cresça o seu negócio com:"
override val subscription_option: String = "Assinatura"
override val subscriptions_load_fail: String = "Lamentamos, mas não podemos recuperar as suas subscrições neste momento. Por favor, tente novamente mais tarde ou contacte a nossa equipa de apoio para obter assistência."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "Subtotal"
override val sum: String = "Soma"
override val support_option: String = "Apoio"
override val switch_reports: String = "Alternar Relatório"
override val tax: String = "Imposto"
override val tax_deleted_message: String = "Imposto eliminado"
override val tax_description_warning: String = "Se for utilizado, aparece numa factura"
override val tax_input_flat: String = "Apartamento"
override val tax_input_percent: String = "Porcentagem"
override val tax_number_fields: String = "Número de imposto"
override val tax_number_hint: String = "Número de imposto"
override val tax_query_info_description: String = "Informações de Consulta"
override val tax_report: String = "Imposto"
override val tax_rules_option: String = "Regras fiscais"
override val taxed_clients_report: String = "Clientes Tributados"
override val template: String = "Modelo"
override val template_selector_info: String = "Desviar para alterar o modelo padrão"
override val template_unlock_promo: String = "Acesse mais modelos com o Premium"
override val templates_option: String = "Modelos"
override val terms_of_service: String = "Termos de Serviço"
override val thank_you: String = "Obrigado"
override val to: String = "para"
override val total: Formattable = Formattable { (sum) -> "Total: ${sum}" }
override val total_label: String = "Total"
override val undo_action: String = "Desfazer"
override val unit: String = "Unidade"
override val unit_cost_hint: String = "Custo unitário"
override val unknown_client: String = "Cliente desconhecido"
override val unlock: String = "Desbloquear"
override val unnamed_item: String = "Item sem nome"
override val upgrade: String = "Actualização"
override val user_collision_error: String = "A conta já existe."
override val valid_until: String = "Válido Até"
override val warning: String = "Advertência!"
override val yes: String = "sim"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Aceite ${termsOfService} e ${privacyPolicy}" },
	"account" to "conta",
	"accounting" to "contabilidade",
	"accruals" to "acréscimos",
	"action_code_error" to "Código inválido.",
	"add_client_action" to "Adicionar cliente",
	"add_customer" to "Adicionar cliente",
	"add_expense" to "Adicionar despesa",
	"add_fields_label" to "Acrescentar:",
	"add_invoice_action" to "Criar",
	"add_item_action" to "Adicionar item",
	"add_item_button" to "Adicionar item",
	"add_signature_action" to "Acrescentar assinatura",
	"add_tax_action" to "Acrescentar imposto",
	"address_fields" to "Endereço",
	"address_line_hint" to "Linha de endereço",
	"advanced" to "AVANÇADO",
	"agree_marketing" to "Aceito receber comunicações de marketing direto",
	"all_notifications" to "Todas as Notificações",
	"amortization" to "amortização",
	"amount" to "Montante",
	"asset" to "ativo",
	"attach_file" to "Anexar Ficheiro",
	"attachment" to "Anexo",
	"auth_error" to "A acção falhou, tente novamente.",
	"available_for_paid" to "A sincronização de nuvens só está disponível sob assinatura paga.",
	"backups_option" to "Cópias de segurança",
	"balance" to "saldo",
	"bank_account" to "Conta bancária",
	"bill_number" to "Número da Fatura",
	"cancel" to "Cancelar",
	"cancel_trial" to "Cancele a qualquer momento",
	"capital" to "capital",
	"cash" to "dinheiro",
	"categories" to "Categorias",
	"category" to "Categoria",
	"category_add" to "Adicionar Categoria",
	"category_delete" to "Eliminar categoria",
	"category_name" to "Nome da categoria",
	"change_language" to Formattable { (language) -> "Língua de facturação: ${language}" },
	"change_language_label" to "Língua de Facturação",
	"change_template" to "Alterar modelo",
	"city_hint" to "Cidade",
	"clear" to "Limpar",
	"client" to "Cliente",
	"client_actions" to "Acções do cliente",
	"client_deleted_message" to "Cliente apagado",
	"clients_error_required_fields" to "É necessário pelo menos um valor.",
	"clients_option" to "Clientes",
	"close" to "Fechar",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "Em breve!",
	"company_logo" to "Logótipo da empresa",
	"company_name_hint" to "Empresa",
	"confirm_password_label" to "Confirmar Palavra-passe",
	"confirmation_title" to "Você tem certeza?",
	"convert_to_invoice" to "Converter em factura",
	"country_hint" to "País",
	"create_client" to "Criar Cliente",
	"create_company" to "Criar empresa",
	"create_document" to "Criar documento",
	"create_estimate" to "Estimativa",
	"create_invoice" to "Factura",
	"create_item" to "Criar Item",
	"create_new_client" to "Criar novo cliente",
	"create_new_item" to "Criar novo item",
	"create_new_tax" to "Criar novo imposto",
	"create_signature" to "Assinatura",
	"created" to "criado",
	"credit" to "crédito",
	"customer" to "Cliente",
	"date" to "Data",
	"debit" to "débito",
	"debt" to "dívida",
	"default_currency" to Formattable { (currency) -> "Moeda Padrão: ${currency}" },
	"default_currency_label" to "Moeda padrão",
	"delete" to "Eliminar",
	"delete_account" to "Eliminar Conta",
	"delete_confirmation_message" to "Todos os seus dados serão perdidos.",
	"delete_item_title" to "Eliminar o item?",
	"delete_signature" to "Apagar assinatura",
	"depreciation" to "depreciação",
	"description" to "Descrição",
	"description_hint" to "Descrição",
	"detailed_report" to "Relatório detalhado",
	"disabled" to "Deficientes",
	"discount" to "Desconto",
	"discount_hint" to "Desconto",
	"display_af" to "Africâner",
	"display_am" to "Amárico",
	"display_ar" to "Árabe",
	"display_as" to "Assamês",
	"display_az" to "Azerbaijano",
	"display_be" to "Bielorrusso",
	"display_bg" to "Búlgaro",
	"display_bn" to "Bengalês",
	"display_bs" to "Bósnio",
	"display_ca" to "Catalão",
	"display_cs" to "Checo",
	"display_da" to "Dinamarquês",
	"display_de" to "Alemão",
	"display_el" to "Grego",
	"display_en" to "Inglês",
	"display_es" to "Espanhol",
	"display_es_MX" to "Espanhol (México)",
	"display_et" to "Estónio",
	"display_eu" to "Basco",
	"display_fa" to "Persa",
	"display_fi" to "Finlandês",
	"display_fil" to "Filipino",
	"display_fr" to "Francês",
	"display_fr_CA" to "Francês Canadense",
	"display_gl" to "Galego",
	"display_gu" to "Guzerate",
	"display_he" to "Hebraico",
	"display_hi" to "Hindu",
	"display_hr" to "Croata",
	"display_hu" to "Húngaro",
	"display_hy" to "Arménio",
	"display_id" to "Indonésio",
	"display_is" to "Islandês",
	"display_it" to "Italiano",
	"display_ja" to "Japonês",
	"display_ka" to "Georgiano",
	"display_kk" to "Cazaque",
	"display_km" to "Khmer Central",
	"display_kn" to "Canarês",
	"display_ko" to "Coreano",
	"display_ky" to "Quirguiz",
	"display_lo" to "Laosiano",
	"display_lt" to "Lituano",
	"display_lv" to "Letão",
	"display_mk" to "Macedónio",
	"display_ml" to "Malaiala",
	"display_mn" to "Mongol",
	"display_mr" to "Marati",
	"display_ms" to "Malaio",
	"display_my" to "Birmanês",
	"display_nb" to "Bokmål Norueguês",
	"display_ne" to "Nepalês",
	"display_nl" to "Holandês",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polaco",
	"display_pt" to "Português",
	"display_pt_BR" to "Português (Brasil)",
	"display_pt_PT" to "Português Europeu",
	"display_ro" to "Romeno",
	"display_ru" to "Russo",
	"display_si" to "Cingalês",
	"display_sk" to "Eslovaco",
	"display_sl" to "Esloveno",
	"display_sq" to "Albanês",
	"display_sr" to "Sérvio",
	"display_sv" to "Sueco",
	"display_sw" to "Suaíli",
	"display_ta" to "Tâmil",
	"display_te" to "Télugo",
	"display_th" to "Tailandês",
	"display_tr" to "Turco",
	"display_uk" to "Ucraniano",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbeque",
	"display_vi" to "Vietnamita",
	"display_zh_Hant_TW" to "Chinês (Taiwan, Tradicional)",
	"display_zu" to "Zulu",
	"document_estimate" to "Estimativa",
	"document_invoice" to "Fatura",
	"document_quote" to "Oferta",
	"done" to "Feito",
	"download_app" to "Descarregar a aplicação",
	"download_invoice" to "Descarregar fatura",
	"download_pdf" to "Descarregar PDF",
	"draw_signature" to "Desenhe uma assinatura",
	"due" to Formattable { (data) -> "Devido ${data}" },
	"due_date" to "Data de vencimento",
	"due_day" to "em um dia",
	"due_label" to "devido",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} está vencendo ${dueWhen}!" },
	"due_today" to "hoje",
	"due_week" to "em uma semana",
	"duplicate" to "Duplicar",
	"duration_day" to "dia",
	"duration_month" to "mês",
	"duration_week" to "semana",
	"duration_year" to "ano",
	"email" to "Email",
	"email_blank_error" to "E-mail necessário.",
	"email_error" to "Endereço de correio electrónico inválido.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Obrigado pelo seu negócio. Para ver o ${documentType} completo, clique no botão no final desta carta. Note que é válido até ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Recebeu um novo ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "VER ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Caro/a ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Obrigado pelo seu negócio. Para ver o ${documentType} completo, clique no botão no final desta carta. Por favor, pague o saldo até ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Recebeu um novo ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Email para redefinir a senha enviada, por favor verifique a sua caixa de entrada.",
	"empty_clients_list_message" to "Não tem clientes para localizar. Adicione um novo cliente.",
	"empty_estimate_list_message" to "Crie a sua primeira estimativa",
	"empty_expenses" to "Não tem despesas para registar. Adicione uma nova despesa.",
	"empty_invoice_list_message" to "Crie a sua primeira factura",
	"empty_items_list_message" to "Não tem artigos, adicione um novo artigo!",
	"empty_signatures_list_message" to "Não foram encontradas assinaturas. Crie uma!",
	"empty_taxes_list_message" to "Não tem regras fiscais. Adicione uma nova regra fiscal.",
	"empty_templates_list_message" to "Modelos indisponíveis.",
	"enabled" to "Ativado",
	"entry" to "registo",
	"equity" to "capital própio",
	"estimate_number" to "Estimativa #",
	"estimates" to "Estimativas",
	"estimates_report" to "Estimativas",
	"expense_bank_fees" to "Taxas Bancárias",
	"expense_benefits" to "Benefícios",
	"expense_communication" to "Comunicação",
	"expense_debt" to "Dívida",
	"expense_deleted" to "Despesa excluída",
	"expense_depreciation" to "Depreciação",
	"expense_gifts" to "Presentes",
	"expense_insurance" to "Seguro",
	"expense_inventory" to "Inventário",
	"expense_legal" to "Legal",
	"expense_maintenance" to "Manutenção",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Refeições",
	"expense_office_supplies" to "Material de Escritório",
	"expense_rent" to "Aluguer",
	"expense_salaries" to "Salários",
	"expense_shopping" to "Compras",
	"expense_software" to "Programas",
	"expense_status_available" to "Disponível",
	"expense_status_processing" to "Em processamento",
	"expense_tax" to "Imposto",
	"expense_training" to "Formação",
	"expense_transport" to "Transporte",
	"expense_travel" to "Viagens",
	"expense_utility" to "Utilidade",
	"expenses" to "Despesas",
	"feedback_option" to "Feedback",
	"filter" to "Filtro",
	"first_name_hint" to "Primeiro nome",
	"forgot_password" to "Redefinir Palavra-passe",
	"forgot_password_label" to "Esqueci-me da palavra-chave",
	"from" to "a partir de",
	"general_error" to "Ocorreu um erro,por favor tente novamente mais tarde.",
	"general_ledger" to "razão geral",
	"go_back_label" to "Voltar",
	"group_by" to "Grupo Por:",
	"image_view" to "Imagem",
	"install" to "Instalar",
	"invalid_credentials_error" to "Credenciais inválidas.",
	"invalid_email_error" to "E-mail inválido.",
	"invalid_user_error" to "Credenciais de utilizador inválidas.",
	"invoice_deleted_message" to "Factura eliminada",
	"invoice_menu" to "Menu de facturas",
	"invoice_number" to "Factura #",
	"invoices" to "Facturas",
	"items_option" to "Artigos",
	"keep" to "Guarde",
	"language_selector" to Formattable { (selected) -> "Língua ${selected}" },
	"last_name_hint" to "Sobrenome",
	"ledger" to "livro-razão",
	"legal" to "Legal",
	"legal_error" to "Deve aceitar os Termos de Serviço e a Política de Privacidade",
	"liabilities" to "responsabilidades",
	"loading" to "Carregamento",
	"login_required_error" to "Login necessário.",
	"logo_view" to "Logótipo",
	"logout" to "Sair",
	"logout_prompt" to "Os seus dados não estão sincronizados com a nuvem. Eliminar facturas, orçamentos, clientes e outras informações sobre o logout?",
	"main_screen" to "Tela principal",
	"mark_paid" to "Marca como paga",
	"message" to "Mensagem",
	"missing_sender_msg" to "Um documento sem informações do remetente pode ser inválido.",
	"missing_sender_title" to "Informação sobre o remetente em falta",
	"month_april" to "Abril",
	"month_april_short" to "Abril",
	"month_august" to "Agosto",
	"month_august_short" to "Ago.",
	"month_december" to "Dezembro",
	"month_december_short" to "Dez.",
	"month_february" to "Fevereiro",
	"month_february_short" to "Fev.",
	"month_january" to "Janeiro",
	"month_january_short" to "Jan.",
	"month_july" to "Julho",
	"month_july_short" to "Julho",
	"month_june" to "Junho",
	"month_june_short" to "Junho",
	"month_march" to "Março",
	"month_march_short" to "Março",
	"month_may" to "Maio",
	"month_may_short" to "Maio",
	"month_november" to "Novembro",
	"month_november_short" to "Nov.",
	"month_october" to "Outubro",
	"month_october_short" to "Out.",
	"month_september" to "Setembro",
	"month_september_short" to "Set.",
	"multifactor_error" to "Falha na verificação.",
	"new" to "Novo",
	"news" to "Notícias",
	"next" to "Próximo",
	"no" to "não",
	"no_data_available" to "Não há dados disponíveis",
	"no_search_results" to "Lamentamos, não conseguimos encontrar quaisquer resultados.",
	"none" to "Nenhum/a",
	"note_fields" to "Notas",
	"note_hint" to "Nota",
	"notifications_option" to "Notificações",
	"ok" to "OK",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "As partes essenciais do DingVoice são para sempre livres. A nossa missão é ajudá-lo a fazer crescer o seu negócio, ajudar-nos a melhorar, partilhando o seu feedback.",
	"onboarding_aboutus_title" to "Estamos aqui para ajudar",
	"onboarding_paid_1" to "Aceder a modelos com aspecto profissional",
	"onboarding_paid_2" to "Analise mais profundamente as suas finanças",
	"onboarding_paid_3" to "Resolver problemas rapidamente",
	"onboarding_paid_4" to "Guarde os seus documentos em segurança",
	"onboarding_paid_5" to "Manter-se actualizado com as alterações do estado das facturas",
	"onboarding_paid_title" to "Crescer ainda mais rápido com...",
	"onboarding_paywall_message" to "Desfrute agora de todas as funcionalidades premium ao melhor preço.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Apenas ${currencySymbol}${introAmount} por ${introDuration} ${introPeriod} e depois ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Apenas ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Grátis por ${trialDuration} ${trialPeriod} e depois apenas ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Livre para ${trialDuration} ${trialPeriod} e depois ${currencySymbol}${introductoryAmount} para ${introductoryDuration} ${introPeriod} e depois apenas ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Auto-renovável. Cancelar a qualquer momento.",
	"onboarding_paywall_restore" to "Restaurar a compra",
	"onboarding_paywall_restore_failure" to "O restabelecimento das compras falhou, tentar novamente mais tarde ou contactar o apoio.",
	"onboarding_paywall_restore_success" to "Restaurar o sucesso!",
	"onboarding_paywall_title" to "Atingir a Grandeza!",
	"onboarding_welcome_message" to "Um conjunto completo de ferramentas para gerir as suas facturas e orçamentos.",
	"onboarding_welcome_title" to "Bem-vindo à DingVoice",
	"other_state" to "Outro",
	"overdue_state" to "Vencido",
	"paid_state" to "Pago",
	"password" to "Senha",
	"password_blank_error" to "Senha necessária.",
	"password_mismatch_error" to "As palavras-passe não coincidem.",
	"pay" to "pagar",
	"payment_instructions_label" to "Instruções de pagamento",
	"payment_reminders" to "Lembretes de pagamento",
	"phone_number_fields" to "Número de telefone",
	"phone_number_hint" to "Número de telefone",
	"postcode_hint" to "Código postal",
	"preview" to "Pré-visualização",
	"preview_menu_hint" to "Menu de pré-visualização",
	"price" to "Preço",
	"privacy_policy" to "Política de Privacidade",
	"profile" to "Perfil",
	"profitable_client" to "O seu cliente mais lucrativo é...",
	"profits_report" to "Lucros",
	"promo_content" to "Conteúdo promocional",
	"quantity_hint" to "Quantidade",
	"quantity_label_hint" to "Rótulo de quantidade",
	"query_info" to "A consulta permite-lhe escrever cálculos personalizados referentes tanto a itens como a outras regras fiscais como inputs. Adicionar @ símbolo e item ou descrição fiscal para ligar a outra entrada +, -, *, /, % - operadores matemáticos",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} foi recebido pelo seu cliente." },
	"received_state" to "Recebido",
	"registration_code_fields" to "Código de Registo",
	"reminder" to "Lembrete",
	"reports" to "Relatórios",
	"reports_not_available" to "Não há relatórios disponíveis, volte mais tarde.",
	"reveal_password" to "Revelar palavra-chave",
	"revenue" to "receitas",
	"sales_tax" to "Taxa",
	"sales_tax_number" to "Número do IVA",
	"save" to "Guardar",
	"scan_document" to "Digitalizar documento",
	"search" to "Pesquisa",
	"select" to "Selecionar",
	"select_icon" to "Selecionar ícone",
	"select_signature" to "Seleccionar assinatura",
	"select_template" to "Seleccione o modelo",
	"send" to "Enviar",
	"sent_state" to "Enviado",
	"setup_sender" to "Configurar remetente",
	"share_option" to "Partilhar",
	"ship_to" to "Enviar para",
	"sign_in" to "Iniciar sessão",
	"sign_in_apple" to "Iniciar sessão com a Apple",
	"sign_in_email" to "Inicie a sessão com e-mail",
	"sign_in_facebook" to "Inicie sessão no Facebook",
	"sign_in_google" to "Iniciar sessão com o Google",
	"sign_up" to "Inscrição",
	"sign_up_label" to "Não tem uma conta?",
	"sign_up_link_label" to "ASSINE!",
	"signatures_option" to "Assinaturas",
	"skip" to "Saltar",
	"sold_items_report" to "Artigos Vendidos",
	"sort_by" to "Ordenar por:",
	"status_update" to "Atualização do estado do documento",
	"sub_annual" to "Anual",
	"sub_annual_price" to Formattable { (price) -> "${price}/ano" },
	"sub_backups" to "Cópias de segurança de nuvens",
	"sub_insights" to "Insights",
	"sub_month" to "Mensal",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mês" },
	"sub_notifications" to "Notificações",
	"sub_popup_message" to "As assinaturas estão a chegar em breve. Verifique aqui dentro de pouco tempo.",
	"sub_support" to "Apoio",
	"sub_templates" to "Modelos",
	"subscribe" to "Subscrever",
	"subscription_header" to "Cresça o seu negócio com:",
	"subscription_option" to "Assinatura",
	"subscriptions_load_fail" to "Lamentamos, mas não podemos recuperar as suas subscrições neste momento. Por favor, tente novamente mais tarde ou contacte a nossa equipa de apoio para obter assistência.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "Subtotal",
	"sum" to "Soma",
	"support_option" to "Apoio",
	"switch_reports" to "Alternar Relatório",
	"tax" to "Imposto",
	"tax_deleted_message" to "Imposto eliminado",
	"tax_description_warning" to "Se for utilizado, aparece numa factura",
	"tax_input_flat" to "Apartamento",
	"tax_input_percent" to "Porcentagem",
	"tax_number_fields" to "Número de imposto",
	"tax_number_hint" to "Número de imposto",
	"tax_query_info_description" to "Informações de Consulta",
	"tax_report" to "Imposto",
	"tax_rules_option" to "Regras fiscais",
	"taxed_clients_report" to "Clientes Tributados",
	"template" to "Modelo",
	"template_selector_info" to "Desviar para alterar o modelo padrão",
	"template_unlock_promo" to "Acesse mais modelos com o Premium",
	"templates_option" to "Modelos",
	"terms_of_service" to "Termos de Serviço",
	"thank_you" to "Obrigado",
	"to" to "para",
	"total" to Formattable { (sum) -> "Total: ${sum}" },
	"total_label" to "Total",
	"undo_action" to "Desfazer",
	"unit" to "Unidade",
	"unit_cost_hint" to "Custo unitário",
	"unknown_client" to "Cliente desconhecido",
	"unlock" to "Desbloquear",
	"unnamed_item" to "Item sem nome",
	"upgrade" to "Actualização",
	"user_collision_error" to "A conta já existe.",
	"valid_until" to "Válido Até",
	"warning" to "Advertência!",
	"yes" to "sim",
    )
    
}