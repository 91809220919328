// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Uz  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} va ${privacyPolicy} ni qabul qiling" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Yaroqsiz kod."
override val add_client_action: String = "Mijoz qo&#39;shing"
override val add_customer: String = "Mijoz qo&#39;shing"
override val add_expense: String = "Xarajatlarni qo&#39;shing"
override val add_fields_label: String = "Qo&#39;shish:"
override val add_invoice_action: String = "Yaratmoq"
override val add_item_action: String = "Element qo&#39;shish"
override val add_item_button: String = "Element qo&#39;shish"
override val add_signature_action: String = "Imzo qo&#39;shing"
override val add_tax_action: String = "Soliq qo&#39;shing"
override val address_fields: String = "Manzil"
override val address_line_hint: String = "Manzil qatori"
override val advanced: String = "Murakkab"
override val agree_marketing: String = "Men Direct Marketing Communications qabul qilishga roziman"
override val all_notifications: String = "Barcha bildirishnomalar"
override val amortization: String = "amortization"
override val amount: String = "Miqdori"
override val asset: String = "asset"
override val attach_file: String = "Fayl biriktiring"
override val attachment: String = "Qo&#39;shimcha"
override val auth_error: String = "Harakat amalga oshmadi, qaytadan urining."
override val available_for_paid: String = "Bulutli sinxronlash faqat pulli obuna ostida mavjud."
override val backups_option: String = "Zaxira nusxalari"
override val balance: String = "balance"
override val bank_account: String = "Bank hisob raqami"
override val bill_number: String = "Hisob raqami"
override val cancel: String = "Bekor qil"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "kategoriya"
override val category: String = "Turkum"
override val category_add: String = "Kategoriya qo&#39;shish"
override val category_delete: String = "Kategoriyani oʻchirish"
override val category_name: String = "Kategoriya nomi"
override val change_language: Formattable = Formattable { (language) -> "Hisob-faktura tili: ${language}" }
override val change_language_label: String = "Hisob-faktura tili"
override val change_template: String = "Shablonni o&#39;zgartirish"
override val city_hint: String = "Shahar"
override val clear: String = "Toza"
override val client: String = "Mijoz"
override val client_actions: String = "Mijoz harakatlari"
override val client_deleted_message: String = "Mijoz o&#39;chirildi"
override val clients_error_required_fields: String = "Kamida bitta qiymat talab qilinadi."
override val clients_option: String = "Mijozlar"
override val close: String = "Yoping"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "Tez kunda!"
override val company_logo: String = "Kompaniya logotipi"
override val company_name_hint: String = "Kompaniya"
override val confirm_password_label: String = "Parolni tasdiqlang"
override val confirmation_title: String = "Ishonchingiz komilmi?"
override val convert_to_invoice: String = "Hisob-fakturaga aylantiring"
override val country_hint: String = "Mamlakat"
override val create_client: String = "Mijoz yaratish"
override val create_company: String = "Kompaniya yaratish"
override val create_document: String = "Hujjat yaratish"
override val create_estimate: String = "Taxmin qilish"
override val create_invoice: String = "Hisob-faktura"
override val create_item: String = "Element yaratish"
override val create_new_client: String = "Yangi mijoz yarating"
override val create_new_item: String = "Yangi element yarating"
override val create_new_tax: String = "Yangi soliq yarating"
override val create_signature: String = "Imzo"
override val created: String = "Yaratilgan"
override val credit: String = "credit"
override val customer: String = "Mijoz"
override val date: String = "Sana"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Birlamchi valyuta: ${currency}" }
override val default_currency_label: String = "Standart valyuta"
override val delete: String = "O‘chirib tashlash"
override val delete_account: String = "Akkauntni o‘chirish"
override val delete_confirmation_message: String = "Barcha ma&#39;lumotlaringiz yo&#39;qoladi."
override val delete_item_title: String = "Element oʻchirilsinmi?"
override val delete_signature: String = "Imzoni o&#39;chirish"
override val depreciation: String = "depreciation"
override val description: String = "Tavsif"
override val description_hint: String = "Tavsif"
override val detailed_report: String = "Batafsil hisobot"
override val disabled: String = "Nogiron"
override val discount: String = "Chegirma"
override val discount_hint: String = "Chegirma"
override val display_af: String = "afrikalik"
override val display_am: String = "Amhar"
override val display_ar: String = "arabcha"
override val display_as: String = "Assam"
override val display_az: String = "ozarbayjon"
override val display_be: String = "belarus"
override val display_bg: String = "bolgar"
override val display_bn: String = "bengal"
override val display_bs: String = "bosniyalik"
override val display_ca: String = "katalon"
override val display_cs: String = "chex"
override val display_da: String = "Daniya"
override val display_de: String = "nemis"
override val display_el: String = "yunoncha"
override val display_en: String = "Ingliz"
override val display_es: String = "ispancha"
override val display_es_MX: String = "Ispan (Meksika)"
override val display_et: String = "eston"
override val display_eu: String = "bask"
override val display_fa: String = "forscha"
override val display_fi: String = "fin"
override val display_fil: String = "Filippin"
override val display_fr: String = "frantsuz"
override val display_fr_CA: String = "Kanada frantsuz"
override val display_gl: String = "Galisian"
override val display_gu: String = "Gujarati"
override val display_he: String = "ibroniy"
override val display_hi: String = "yoq"
override val display_hr: String = "xorvat"
override val display_hu: String = "venger"
override val display_hy: String = "arman"
override val display_id: String = "indonez"
override val display_is: String = "island"
override val display_it: String = "italyancha"
override val display_ja: String = "yapon"
override val display_ka: String = "gruzin"
override val display_kk: String = "qozoq"
override val display_km: String = "Markaziy Kxmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "koreys"
override val display_ky: String = "qirg&#39;iz"
override val display_lo: String = "Mehnat"
override val display_lt: String = "litva"
override val display_lv: String = "latviyalik"
override val display_mk: String = "makedon"
override val display_ml: String = "Malayalam"
override val display_mn: String = "mo&#39;g&#39;ul"
override val display_mr: String = "Marathi"
override val display_ms: String = "malay"
override val display_my: String = "Birma"
override val display_nb: String = "Norvegiya bokmåli"
override val display_ne: String = "Nepal"
override val display_nl: String = "golland"
override val display_or: String = "Oriya"
override val display_pa: String = "panjob"
override val display_pl: String = "polyak"
override val display_pt: String = "portugal"
override val display_pt_BR: String = "Portugal (Braziliya)"
override val display_pt_PT: String = "Yevropa portugal"
override val display_ro: String = "rumin"
override val display_ru: String = "rus"
override val display_si: String = "Sinhala"
override val display_sk: String = "slovak"
override val display_sl: String = "sloven"
override val display_sq: String = "alban"
override val display_sr: String = "serb"
override val display_sv: String = "shvedcha"
override val display_sw: String = "suahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "tay"
override val display_tr: String = "turkcha"
override val display_uk: String = "ukrain"
override val display_ur: String = "urdu"
override val display_uz: String = "Uzbek"
override val display_vi: String = "Vetnam"
override val display_zh_Hant_TW: String = "Xitoy (Tayvan, an&#39;anaviy)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Taxmin qilish"
override val document_invoice: String = "Hisob-faktura"
override val document_quote: String = "Iqtibos"
override val done: String = "Bajarildi"
override val download_app: String = "Ilovani yuklab oling"
override val download_invoice: String = "Invoys yuklab olish"
override val download_pdf: String = "PDF yuklab olish"
override val draw_signature: String = "Imzo chizish"
override val due: Formattable = Formattable { (date) -> "Muddati ${date}" }
override val due_date: String = "Muddati"
override val due_day: String = "bir kunda"
override val due_label: String = "Muddati"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} muddati ${dueWhen}!" }
override val due_today: String = "Bugun"
override val due_week: String = "bir hafta ichida"
override val duplicate: String = "Dublikat"
override val duration_day: String = "kun"
override val duration_month: String = "Oy"
override val duration_week: String = "hafta"
override val duration_year: String = "yil"
override val email: String = "Elektron pochta"
override val email_blank_error: String = "Email kerak."
override val email_error: String = "Email manzili noto‘g‘ri."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Biznesingiz uchun rahmat. Toʻliq ${documentType} koʻrish uchun ushbu xat oxiridagi tugmani bosing. U ${dueDate} gacha amal qiladi." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Siz yangisini oldingiz${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} NI KOʻRISH" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Hurmatli ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Biznesingiz uchun rahmat. Toʻliq ${documentType} koʻrish uchun ushbu xat oxiridagi tugmani bosing. Iltimos, balansni ${dueDate} gacha toʻlang." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Siz yangisini oldingiz${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "Parolni tiklash uchun elektron pochta xabari yuborildi, iltimos, kirish qutingizni tekshiring."
override val empty_clients_list_message: String = "Kuzatadigan mijozlaringiz yoʻq. Yangi mijoz qo&#39;shing."
override val empty_estimate_list_message: String = "Birinchi taxminingizni yarating"
override val empty_expenses: String = "Sizda kuzatadigan xarajatlar yo&#39;q. Yangi xarajat qo&#39;shing."
override val empty_invoice_list_message: String = "Birinchi hisob-fakturangizni yarating"
override val empty_items_list_message: String = "Sizda hech narsa yo&#39;q, yangi element qo&#39;shing!"
override val empty_signatures_list_message: String = "Hech qanday imzo topilmadi. Bir yarating!"
override val empty_taxes_list_message: String = "Sizda soliq qoidalari yo&#39;q. Yangi soliq qoidasini qo&#39;shing."
override val empty_templates_list_message: String = "Shablonlar mavjud emas."
override val enabled: String = "Yoqilgan"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Taxmin №"
override val estimates: String = "Hisob-kitoblar"
override val estimates_report: String = "Hisob-kitoblar"
override val expense_bank_fees: String = "Bank to&#39;lovlari"
override val expense_benefits: String = "Foyda"
override val expense_communication: String = "Aloqa"
override val expense_debt: String = "Qarz"
override val expense_deleted: String = "Xarajat o&#39;chirildi"
override val expense_depreciation: String = "Amortizatsiya"
override val expense_gifts: String = "Sovg&#39;alar"
override val expense_insurance: String = "Sug&#39;urta"
override val expense_inventory: String = "Inventarizatsiya"
override val expense_legal: String = "Huquqiy"
override val expense_maintenance: String = "Xizmat"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Ovqatlar"
override val expense_office_supplies: String = "Ofis jihozlari"
override val expense_rent: String = "Ijara"
override val expense_salaries: String = "Maoshlar"
override val expense_shopping: String = "Xarid qilish"
override val expense_software: String = "Dasturiy ta&#39;minot"
override val expense_status_available: String = "Mavjud"
override val expense_status_processing: String = "Qayta ishlash"
override val expense_tax: String = "Soliq"
override val expense_training: String = "Trening"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Qatnov"
override val expense_utility: String = "Qulaylik"
override val expenses: String = "Xarajatlar"
override val feedback_option: String = "Fikr-mulohaza"
override val filter: String = "filter"
override val first_name_hint: String = "Ism"
override val forgot_password: String = "Parolni tiklash"
override val forgot_password_label: String = "Parolni unutdingizmi"
override val from: String = "Kimdan"
override val general_error: String = "Xatolik yuz berdi, keyinroq qayta urinib koʻring."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Ortga qaytish"
override val group_by: String = "Guruh boʻyicha:"
override val image_view: String = "Rasm"
override val install: String = "O&#39;rnatish"
override val invalid_credentials_error: String = "Hisob maʼlumotlari yaroqsiz."
override val invalid_email_error: String = "Yaroqsiz email."
override val invalid_user_error: String = "Foydalanuvchi hisob maʼlumotlari notoʻgʻri."
override val invoice_deleted_message: String = "Hisob-faktura oʻchirildi"
override val invoice_menu: String = "Hisob-faktura menyusi"
override val invoice_number: String = "Invoys #"
override val invoices: String = "Hisob-fakturalar"
override val items_option: String = "Elementlar"
override val keep: String = "Saqlamoq"
override val language_selector: Formattable = Formattable { (tanlangan) -> "Til ${tanlangan}" }
override val last_name_hint: String = "Familiya"
override val ledger: String = "ledger"
override val legal: String = "Huquqiy"
override val legal_error: String = "Foydalanish shartlari va Maxfiylik siyosatini qabul qilish kerak"
override val liabilities: String = "liabilities"
override val loading: String = "Yuklanmoqda"
override val login_required_error: String = "Kirish talab qilinadi."
override val logo_view: String = "Logotip"
override val logout: String = "Chiqish"
override val logout_prompt: String = "Sizning ma&#39;lumotlaringiz bulut bilan sinxronlashtirilmagan. Chiqish paytida hisob-fakturalar, hisob-kitoblar, mijozlar va boshqa ma&#39;lumotlar o&#39;chirilsinmi?"
override val main_screen: String = "Asosiy ekran"
override val mark_paid: String = "To&#39;langan deb belgilang"
override val message: String = "Xabar"
override val missing_sender_msg: String = "Yuboruvchi ma&#39;lumotlari bo&#39;lmagan hujjat yaroqsiz bo&#39;lishi mumkin."
override val missing_sender_title: String = "Yuboruvchi ma&#39;lumotlari etishmayapti"
override val month_april: String = "aprel"
override val month_april_short: String = "aprel"
override val month_august: String = "avgust"
override val month_august_short: String = "avgust"
override val month_december: String = "dekabr"
override val month_december_short: String = "dekabr"
override val month_february: String = "fevral"
override val month_february_short: String = "Fevral"
override val month_january: String = "Yanvar"
override val month_january_short: String = "Yanvar"
override val month_july: String = "iyul"
override val month_july_short: String = "iyul"
override val month_june: String = "iyun"
override val month_june_short: String = "iyun"
override val month_march: String = "Mart"
override val month_march_short: String = "Mart"
override val month_may: String = "Май"
override val month_may_short: String = "Май"
override val month_november: String = "noyabr"
override val month_november_short: String = "Noyabr"
override val month_october: String = "oktyabr"
override val month_october_short: String = "Okt."
override val month_september: String = "sentyabr"
override val month_september_short: String = "sentabr"
override val multifactor_error: String = "Tekshirib bo‘lmadi."
override val new: String = "REJA"
override val news: String = "Yangiliklar"
override val next: String = "Keyingi"
override val no: String = "no"
override val no_data_available: String = "Maʼlumotlar mavjud emas"
override val no_search_results: String = "Kechirasiz, biz hech qanday natija topa olmadik."
override val none: String = "Yo&#39;q"
override val note_fields: String = "Qaydlar"
override val note_hint: String = "Izoh qo"
override val notifications_option: String = "Bildirishnomalar"
override val ok: String = "ОК"
override val onboarding: String = "Bortga kirish"
override val onboarding_aboutus_message: String = "DingVoice-ning asosiy qismlari abadiy bepul. Bizning vazifamiz sizning biznesingizni rivojlantirishga yordam berish, fikr-mulohazalaringizni baham ko&#39;rish orqali bizni yaxshilashga yordam berishdir."
override val onboarding_aboutus_title: String = "Biz yordam berish uchun shu yerdamiz"
override val onboarding_paid_1: String = "Professional ko&#39;rinishdagi shablonlarga kiring"
override val onboarding_paid_2: String = "Moliyaviy ahvolingizni chuqurroq ko&#39;rib chiqing"
override val onboarding_paid_3: String = "Muammolarni tezda hal qiling"
override val onboarding_paid_4: String = "Hujjatlaringizni xavfsiz saqlang"
override val onboarding_paid_5: String = "Hisob-faktura holatidagi o‘zgarishlardan xabardor bo‘ling"
override val onboarding_paid_title: String = "Bundan ham tezroq o&#39;sing..."
override val onboarding_paywall_message: String = "Barcha premium funksiyalardan hozir eng yaxshi narxda rohatlaning."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} uchun faqat ${currencySymbol}${introAmount} va keyin ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Faqat ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} bepul, keyin esa ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} uchun bepul, keyin ${introductoryDuration} ${introPeriod} uchun ${currencySymbol}${introductoryAmount} va keyin faqat ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Avtomatik yangilanadigan. Istalgan vaqtda bekor qiling."
override val onboarding_paywall_restore: String = "Xaridni tiklash"
override val onboarding_paywall_restore_failure: String = "Xaridlarni tiklab boʻlmadi, keyinroq qayta urinib koʻring yoki koʻmak xizmatiga murojaat qiling."
override val onboarding_paywall_restore_success: String = "Qayta tiklash muvaffaqiyatli!"
override val onboarding_paywall_title: String = "Buyuklikka erishing!"
override val onboarding_welcome_message: String = "Hisob-fakturalar va hisob-kitoblarni boshqarish uchun to&#39;liq vositalar to&#39;plami."
override val onboarding_welcome_title: String = "DingVoice-ga xush kelibsiz"
override val other_state: String = "Boshqa"
override val overdue_state: String = "Muddati o&#39;tgan"
override val paid_state: String = "To&#39;langan"
override val password: String = "Parol"
override val password_blank_error: String = "Parol talab qilinadi."
override val password_mismatch_error: String = "Parollar mos kelmaydi."
override val pay: String = "pay"
override val payment_instructions_label: String = "To&#39;lov ko&#39;rsatmalari"
override val payment_reminders: String = "To&#39;lov eslatmalari"
override val phone_number_fields: String = "Telefon raqami"
override val phone_number_hint: String = "Telefon raqami"
override val postcode_hint: String = "Pochta indeksi"
override val preview: String = "Ko‘rib chiqish"
override val preview_menu_hint: String = "Ko‘rib chiqish menyusi"
override val price: String = "Narx"
override val privacy_policy: String = "Maxfiylik siyosatiga"
override val profile: String = "Foydalanuvchining profili"
override val profitable_client: String = "Sizning eng daromadli mijozingiz..."
override val profits_report: String = "Foyda"
override val promo_content: String = "Reklama mazmuni"
override val quantity_hint: String = "Miqdori"
override val quantity_label_hint: String = "Miqdor yorlig&#39;i"
override val query_info: String = "So&#39;rov sizga har ikkala ob&#39;ektga va boshqa soliq qoidalariga kirish sifatida moslashtirilgan hisob-kitoblarni yozish imkonini beradi. Boshqa yozuvga +, -, *, /, % - matematik operatorlarga havola qilish uchun @ belgisi va element yoki soliq tavsifini qo&#39;shing"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} mijoz tomonidan qabul qilindi." }
override val received_state: String = "Qabul qildi"
override val registration_code_fields: String = "Ro&#39;yxatdan o&#39;tish kodi"
override val reminder: String = "Eslatma"
override val reports: String = "Hisobotlar"
override val reports_not_available: String = "Hisobotlar mavjud emas, keyinroq qaytib keling."
override val reveal_password: String = "Parolni ochish"
override val revenue: String = "revenue"
override val sales_tax: String = "QQS"
override val sales_tax_number: String = "QQS raqami"
override val save: String = "Saqlab olish"
override val scan_document: String = "Hujjatni skanerlash"
override val search: String = "Qidirish"
override val select: String = "Tanlang"
override val select_icon: String = "Belgini tanlang"
override val select_signature: String = "Imzoni tanlang"
override val select_template: String = "Shablonni tanlang"
override val send: String = "Yuborish"
override val sent_state: String = "Yuborildi"
override val setup_sender: String = "Yuboruvchini sozlang"
override val share_option: String = "Ulashish"
override val ship_to: String = "Ga yetkazib berish"
override val sign_in: String = "tizimga kirish"
override val sign_in_apple: String = "Apple bilan tizimga kiring"
override val sign_in_email: String = "Elektron pochta orqali tizimga kiring"
override val sign_in_facebook: String = "Facebook bilan tizimga kiring"
override val sign_in_google: String = "Google bilan kiring"
override val sign_up: String = "Ro&#39;yxatdan o&#39;tish"
override val sign_up_label: String = "Hisobingiz yo&#39;qmi?"
override val sign_up_link_label: String = "Ro&#39;yxatdan o&#39;tish!"
override val signatures_option: String = "Imzolar"
override val skip: String = "O&#39;tkazib yuborish"
override val sold_items_report: String = "Sotilgan buyumlar"
override val sort_by: String = "Saralash turi:"
override val status_update: String = "Hujjat holatini yangilash"
override val sub_annual: String = "Yillik"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/yil" }
override val sub_backups: String = "Bulutli zaxira nusxalari"
override val sub_insights: String = "Insights"
override val sub_month: String = "oylik"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/oy" }
override val sub_notifications: String = "Bildirishnomalar"
override val sub_popup_message: String = "Obunalar tez orada keladi. Birozdan keyin bu yerni tekshiring."
override val sub_support: String = "Qo'llab-quvvatlash"
override val sub_templates: String = "Shablonlar"
override val subscribe: String = "Obuna bo&#39;lish"
override val subscription_header: String = "Biznesingizni quyidagi bilan rivojlantiring:"
override val subscription_option: String = "Obuna"
override val subscriptions_load_fail: String = "Kechirasiz, hozirda obunalaringizni tiklay olmaymiz. Iltimos, keyinroq qayta urinib ko&#39;ring yoki yordam uchun qo&#39;llab-quvvatlash guruhimizga murojaat qiling."
override val subtotal: Formattable = Formattable { (sum) -> "Oraliq jami: ${sum}" }
override val subtotal_label: String = "Oraliq jami"
override val sum: String = "Jami"
override val support_option: String = "Qo'llab-quvvatlash"
override val switch_reports: String = "Hisobotni almashtirish"
override val tax: String = "Soliq"
override val tax_deleted_message: String = "Soliq olib tashlandi"
override val tax_description_warning: String = "Agar foydalanilgan bo&#39;lsa, bu hisob-fakturada ko&#39;rsatiladi"
override val tax_input_flat: String = "Yassi"
override val tax_input_percent: String = "Foiz"
override val tax_number_fields: String = "Soliq raqami"
override val tax_number_hint: String = "Soliq raqami"
override val tax_query_info_description: String = "So&#39;rov ma&#39;lumotlari"
override val tax_report: String = "Soliq"
override val tax_rules_option: String = "Soliq qoidalari"
override val taxed_clients_report: String = "Soliq to&#39;lanadigan mijozlar"
override val template: String = "Shablon"
override val template_selector_info: String = "Standart shablonni o&#39;zgartirish uchun suring"
override val template_unlock_promo: String = "Premium bilan boshqa andozalarga kiring"
override val templates_option: String = "Shablonlar"
override val terms_of_service: String = "Xizmat ko&#39;rsatish shartlari"
override val thank_you: String = "rahmat"
override val to: String = "k"
override val total: Formattable = Formattable { (sum) -> "Jami: ${sum}" }
override val total_label: String = "Jami"
override val undo_action: String = "Bekor qilish"
override val unit: String = "Birlik"
override val unit_cost_hint: String = "Birlik narxi"
override val unknown_client: String = "Noma&#39;lum mijoz"
override val unlock: String = "Qulfni ochish"
override val unnamed_item: String = "Nomsiz element"
override val upgrade: String = "Yangilash"
override val user_collision_error: String = "Hisob allaqachon mavjud."
override val valid_until: String = "Yaroqlilik muddati"
override val warning: String = "Ogohlantirish!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} va ${privacyPolicy} ni qabul qiling" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Yaroqsiz kod.",
	"add_client_action" to "Mijoz qo&#39;shing",
	"add_customer" to "Mijoz qo&#39;shing",
	"add_expense" to "Xarajatlarni qo&#39;shing",
	"add_fields_label" to "Qo&#39;shish:",
	"add_invoice_action" to "Yaratmoq",
	"add_item_action" to "Element qo&#39;shish",
	"add_item_button" to "Element qo&#39;shish",
	"add_signature_action" to "Imzo qo&#39;shing",
	"add_tax_action" to "Soliq qo&#39;shing",
	"address_fields" to "Manzil",
	"address_line_hint" to "Manzil qatori",
	"advanced" to "Murakkab",
	"agree_marketing" to "Men Direct Marketing Communications qabul qilishga roziman",
	"all_notifications" to "Barcha bildirishnomalar",
	"amortization" to "amortization",
	"amount" to "Miqdori",
	"asset" to "asset",
	"attach_file" to "Fayl biriktiring",
	"attachment" to "Qo&#39;shimcha",
	"auth_error" to "Harakat amalga oshmadi, qaytadan urining.",
	"available_for_paid" to "Bulutli sinxronlash faqat pulli obuna ostida mavjud.",
	"backups_option" to "Zaxira nusxalari",
	"balance" to "balance",
	"bank_account" to "Bank hisob raqami",
	"bill_number" to "Hisob raqami",
	"cancel" to "Bekor qil",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "kategoriya",
	"category" to "Turkum",
	"category_add" to "Kategoriya qo&#39;shish",
	"category_delete" to "Kategoriyani oʻchirish",
	"category_name" to "Kategoriya nomi",
	"change_language" to Formattable { (language) -> "Hisob-faktura tili: ${language}" },
	"change_language_label" to "Hisob-faktura tili",
	"change_template" to "Shablonni o&#39;zgartirish",
	"city_hint" to "Shahar",
	"clear" to "Toza",
	"client" to "Mijoz",
	"client_actions" to "Mijoz harakatlari",
	"client_deleted_message" to "Mijoz o&#39;chirildi",
	"clients_error_required_fields" to "Kamida bitta qiymat talab qilinadi.",
	"clients_option" to "Mijozlar",
	"close" to "Yoping",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "Tez kunda!",
	"company_logo" to "Kompaniya logotipi",
	"company_name_hint" to "Kompaniya",
	"confirm_password_label" to "Parolni tasdiqlang",
	"confirmation_title" to "Ishonchingiz komilmi?",
	"convert_to_invoice" to "Hisob-fakturaga aylantiring",
	"country_hint" to "Mamlakat",
	"create_client" to "Mijoz yaratish",
	"create_company" to "Kompaniya yaratish",
	"create_document" to "Hujjat yaratish",
	"create_estimate" to "Taxmin qilish",
	"create_invoice" to "Hisob-faktura",
	"create_item" to "Element yaratish",
	"create_new_client" to "Yangi mijoz yarating",
	"create_new_item" to "Yangi element yarating",
	"create_new_tax" to "Yangi soliq yarating",
	"create_signature" to "Imzo",
	"created" to "Yaratilgan",
	"credit" to "credit",
	"customer" to "Mijoz",
	"date" to "Sana",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Birlamchi valyuta: ${currency}" },
	"default_currency_label" to "Standart valyuta",
	"delete" to "O‘chirib tashlash",
	"delete_account" to "Akkauntni o‘chirish",
	"delete_confirmation_message" to "Barcha ma&#39;lumotlaringiz yo&#39;qoladi.",
	"delete_item_title" to "Element oʻchirilsinmi?",
	"delete_signature" to "Imzoni o&#39;chirish",
	"depreciation" to "depreciation",
	"description" to "Tavsif",
	"description_hint" to "Tavsif",
	"detailed_report" to "Batafsil hisobot",
	"disabled" to "Nogiron",
	"discount" to "Chegirma",
	"discount_hint" to "Chegirma",
	"display_af" to "afrikalik",
	"display_am" to "Amhar",
	"display_ar" to "arabcha",
	"display_as" to "Assam",
	"display_az" to "ozarbayjon",
	"display_be" to "belarus",
	"display_bg" to "bolgar",
	"display_bn" to "bengal",
	"display_bs" to "bosniyalik",
	"display_ca" to "katalon",
	"display_cs" to "chex",
	"display_da" to "Daniya",
	"display_de" to "nemis",
	"display_el" to "yunoncha",
	"display_en" to "Ingliz",
	"display_es" to "ispancha",
	"display_es_MX" to "Ispan (Meksika)",
	"display_et" to "eston",
	"display_eu" to "bask",
	"display_fa" to "forscha",
	"display_fi" to "fin",
	"display_fil" to "Filippin",
	"display_fr" to "frantsuz",
	"display_fr_CA" to "Kanada frantsuz",
	"display_gl" to "Galisian",
	"display_gu" to "Gujarati",
	"display_he" to "ibroniy",
	"display_hi" to "yoq",
	"display_hr" to "xorvat",
	"display_hu" to "venger",
	"display_hy" to "arman",
	"display_id" to "indonez",
	"display_is" to "island",
	"display_it" to "italyancha",
	"display_ja" to "yapon",
	"display_ka" to "gruzin",
	"display_kk" to "qozoq",
	"display_km" to "Markaziy Kxmer",
	"display_kn" to "Kannada",
	"display_ko" to "koreys",
	"display_ky" to "qirg&#39;iz",
	"display_lo" to "Mehnat",
	"display_lt" to "litva",
	"display_lv" to "latviyalik",
	"display_mk" to "makedon",
	"display_ml" to "Malayalam",
	"display_mn" to "mo&#39;g&#39;ul",
	"display_mr" to "Marathi",
	"display_ms" to "malay",
	"display_my" to "Birma",
	"display_nb" to "Norvegiya bokmåli",
	"display_ne" to "Nepal",
	"display_nl" to "golland",
	"display_or" to "Oriya",
	"display_pa" to "panjob",
	"display_pl" to "polyak",
	"display_pt" to "portugal",
	"display_pt_BR" to "Portugal (Braziliya)",
	"display_pt_PT" to "Yevropa portugal",
	"display_ro" to "rumin",
	"display_ru" to "rus",
	"display_si" to "Sinhala",
	"display_sk" to "slovak",
	"display_sl" to "sloven",
	"display_sq" to "alban",
	"display_sr" to "serb",
	"display_sv" to "shvedcha",
	"display_sw" to "suahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "tay",
	"display_tr" to "turkcha",
	"display_uk" to "ukrain",
	"display_ur" to "urdu",
	"display_uz" to "Uzbek",
	"display_vi" to "Vetnam",
	"display_zh_Hant_TW" to "Xitoy (Tayvan, an&#39;anaviy)",
	"display_zu" to "Zulu",
	"document_estimate" to "Taxmin qilish",
	"document_invoice" to "Hisob-faktura",
	"document_quote" to "Iqtibos",
	"done" to "Bajarildi",
	"download_app" to "Ilovani yuklab oling",
	"download_invoice" to "Invoys yuklab olish",
	"download_pdf" to "PDF yuklab olish",
	"draw_signature" to "Imzo chizish",
	"due" to Formattable { (date) -> "Muddati ${date}" },
	"due_date" to "Muddati",
	"due_day" to "bir kunda",
	"due_label" to "Muddati",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} muddati ${dueWhen}!" },
	"due_today" to "Bugun",
	"due_week" to "bir hafta ichida",
	"duplicate" to "Dublikat",
	"duration_day" to "kun",
	"duration_month" to "Oy",
	"duration_week" to "hafta",
	"duration_year" to "yil",
	"email" to "Elektron pochta",
	"email_blank_error" to "Email kerak.",
	"email_error" to "Email manzili noto‘g‘ri.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Biznesingiz uchun rahmat. Toʻliq ${documentType} koʻrish uchun ushbu xat oxiridagi tugmani bosing. U ${dueDate} gacha amal qiladi." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Siz yangisini oldingiz${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} NI KOʻRISH" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Hurmatli ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Biznesingiz uchun rahmat. Toʻliq ${documentType} koʻrish uchun ushbu xat oxiridagi tugmani bosing. Iltimos, balansni ${dueDate} gacha toʻlang." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Siz yangisini oldingiz${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "Parolni tiklash uchun elektron pochta xabari yuborildi, iltimos, kirish qutingizni tekshiring.",
	"empty_clients_list_message" to "Kuzatadigan mijozlaringiz yoʻq. Yangi mijoz qo&#39;shing.",
	"empty_estimate_list_message" to "Birinchi taxminingizni yarating",
	"empty_expenses" to "Sizda kuzatadigan xarajatlar yo&#39;q. Yangi xarajat qo&#39;shing.",
	"empty_invoice_list_message" to "Birinchi hisob-fakturangizni yarating",
	"empty_items_list_message" to "Sizda hech narsa yo&#39;q, yangi element qo&#39;shing!",
	"empty_signatures_list_message" to "Hech qanday imzo topilmadi. Bir yarating!",
	"empty_taxes_list_message" to "Sizda soliq qoidalari yo&#39;q. Yangi soliq qoidasini qo&#39;shing.",
	"empty_templates_list_message" to "Shablonlar mavjud emas.",
	"enabled" to "Yoqilgan",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Taxmin №",
	"estimates" to "Hisob-kitoblar",
	"estimates_report" to "Hisob-kitoblar",
	"expense_bank_fees" to "Bank to&#39;lovlari",
	"expense_benefits" to "Foyda",
	"expense_communication" to "Aloqa",
	"expense_debt" to "Qarz",
	"expense_deleted" to "Xarajat o&#39;chirildi",
	"expense_depreciation" to "Amortizatsiya",
	"expense_gifts" to "Sovg&#39;alar",
	"expense_insurance" to "Sug&#39;urta",
	"expense_inventory" to "Inventarizatsiya",
	"expense_legal" to "Huquqiy",
	"expense_maintenance" to "Xizmat",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Ovqatlar",
	"expense_office_supplies" to "Ofis jihozlari",
	"expense_rent" to "Ijara",
	"expense_salaries" to "Maoshlar",
	"expense_shopping" to "Xarid qilish",
	"expense_software" to "Dasturiy ta&#39;minot",
	"expense_status_available" to "Mavjud",
	"expense_status_processing" to "Qayta ishlash",
	"expense_tax" to "Soliq",
	"expense_training" to "Trening",
	"expense_transport" to "Transport",
	"expense_travel" to "Qatnov",
	"expense_utility" to "Qulaylik",
	"expenses" to "Xarajatlar",
	"feedback_option" to "Fikr-mulohaza",
	"filter" to "filter",
	"first_name_hint" to "Ism",
	"forgot_password" to "Parolni tiklash",
	"forgot_password_label" to "Parolni unutdingizmi",
	"from" to "Kimdan",
	"general_error" to "Xatolik yuz berdi, keyinroq qayta urinib koʻring.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Ortga qaytish",
	"group_by" to "Guruh boʻyicha:",
	"image_view" to "Rasm",
	"install" to "O&#39;rnatish",
	"invalid_credentials_error" to "Hisob maʼlumotlari yaroqsiz.",
	"invalid_email_error" to "Yaroqsiz email.",
	"invalid_user_error" to "Foydalanuvchi hisob maʼlumotlari notoʻgʻri.",
	"invoice_deleted_message" to "Hisob-faktura oʻchirildi",
	"invoice_menu" to "Hisob-faktura menyusi",
	"invoice_number" to "Invoys #",
	"invoices" to "Hisob-fakturalar",
	"items_option" to "Elementlar",
	"keep" to "Saqlamoq",
	"language_selector" to Formattable { (tanlangan) -> "Til ${tanlangan}" },
	"last_name_hint" to "Familiya",
	"ledger" to "ledger",
	"legal" to "Huquqiy",
	"legal_error" to "Foydalanish shartlari va Maxfiylik siyosatini qabul qilish kerak",
	"liabilities" to "liabilities",
	"loading" to "Yuklanmoqda",
	"login_required_error" to "Kirish talab qilinadi.",
	"logo_view" to "Logotip",
	"logout" to "Chiqish",
	"logout_prompt" to "Sizning ma&#39;lumotlaringiz bulut bilan sinxronlashtirilmagan. Chiqish paytida hisob-fakturalar, hisob-kitoblar, mijozlar va boshqa ma&#39;lumotlar o&#39;chirilsinmi?",
	"main_screen" to "Asosiy ekran",
	"mark_paid" to "To&#39;langan deb belgilang",
	"message" to "Xabar",
	"missing_sender_msg" to "Yuboruvchi ma&#39;lumotlari bo&#39;lmagan hujjat yaroqsiz bo&#39;lishi mumkin.",
	"missing_sender_title" to "Yuboruvchi ma&#39;lumotlari etishmayapti",
	"month_april" to "aprel",
	"month_april_short" to "aprel",
	"month_august" to "avgust",
	"month_august_short" to "avgust",
	"month_december" to "dekabr",
	"month_december_short" to "dekabr",
	"month_february" to "fevral",
	"month_february_short" to "Fevral",
	"month_january" to "Yanvar",
	"month_january_short" to "Yanvar",
	"month_july" to "iyul",
	"month_july_short" to "iyul",
	"month_june" to "iyun",
	"month_june_short" to "iyun",
	"month_march" to "Mart",
	"month_march_short" to "Mart",
	"month_may" to "Май",
	"month_may_short" to "Май",
	"month_november" to "noyabr",
	"month_november_short" to "Noyabr",
	"month_october" to "oktyabr",
	"month_october_short" to "Okt.",
	"month_september" to "sentyabr",
	"month_september_short" to "sentabr",
	"multifactor_error" to "Tekshirib bo‘lmadi.",
	"new" to "REJA",
	"news" to "Yangiliklar",
	"next" to "Keyingi",
	"no" to "no",
	"no_data_available" to "Maʼlumotlar mavjud emas",
	"no_search_results" to "Kechirasiz, biz hech qanday natija topa olmadik.",
	"none" to "Yo&#39;q",
	"note_fields" to "Qaydlar",
	"note_hint" to "Izoh qo",
	"notifications_option" to "Bildirishnomalar",
	"ok" to "ОК",
	"onboarding" to "Bortga kirish",
	"onboarding_aboutus_message" to "DingVoice-ning asosiy qismlari abadiy bepul. Bizning vazifamiz sizning biznesingizni rivojlantirishga yordam berish, fikr-mulohazalaringizni baham ko&#39;rish orqali bizni yaxshilashga yordam berishdir.",
	"onboarding_aboutus_title" to "Biz yordam berish uchun shu yerdamiz",
	"onboarding_paid_1" to "Professional ko&#39;rinishdagi shablonlarga kiring",
	"onboarding_paid_2" to "Moliyaviy ahvolingizni chuqurroq ko&#39;rib chiqing",
	"onboarding_paid_3" to "Muammolarni tezda hal qiling",
	"onboarding_paid_4" to "Hujjatlaringizni xavfsiz saqlang",
	"onboarding_paid_5" to "Hisob-faktura holatidagi o‘zgarishlardan xabardor bo‘ling",
	"onboarding_paid_title" to "Bundan ham tezroq o&#39;sing...",
	"onboarding_paywall_message" to "Barcha premium funksiyalardan hozir eng yaxshi narxda rohatlaning.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} uchun faqat ${currencySymbol}${introAmount} va keyin ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Faqat ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} bepul, keyin esa ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} uchun bepul, keyin ${introductoryDuration} ${introPeriod} uchun ${currencySymbol}${introductoryAmount} va keyin faqat ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Avtomatik yangilanadigan. Istalgan vaqtda bekor qiling.",
	"onboarding_paywall_restore" to "Xaridni tiklash",
	"onboarding_paywall_restore_failure" to "Xaridlarni tiklab boʻlmadi, keyinroq qayta urinib koʻring yoki koʻmak xizmatiga murojaat qiling.",
	"onboarding_paywall_restore_success" to "Qayta tiklash muvaffaqiyatli!",
	"onboarding_paywall_title" to "Buyuklikka erishing!",
	"onboarding_welcome_message" to "Hisob-fakturalar va hisob-kitoblarni boshqarish uchun to&#39;liq vositalar to&#39;plami.",
	"onboarding_welcome_title" to "DingVoice-ga xush kelibsiz",
	"other_state" to "Boshqa",
	"overdue_state" to "Muddati o&#39;tgan",
	"paid_state" to "To&#39;langan",
	"password" to "Parol",
	"password_blank_error" to "Parol talab qilinadi.",
	"password_mismatch_error" to "Parollar mos kelmaydi.",
	"pay" to "pay",
	"payment_instructions_label" to "To&#39;lov ko&#39;rsatmalari",
	"payment_reminders" to "To&#39;lov eslatmalari",
	"phone_number_fields" to "Telefon raqami",
	"phone_number_hint" to "Telefon raqami",
	"postcode_hint" to "Pochta indeksi",
	"preview" to "Ko‘rib chiqish",
	"preview_menu_hint" to "Ko‘rib chiqish menyusi",
	"price" to "Narx",
	"privacy_policy" to "Maxfiylik siyosatiga",
	"profile" to "Foydalanuvchining profili",
	"profitable_client" to "Sizning eng daromadli mijozingiz...",
	"profits_report" to "Foyda",
	"promo_content" to "Reklama mazmuni",
	"quantity_hint" to "Miqdori",
	"quantity_label_hint" to "Miqdor yorlig&#39;i",
	"query_info" to "So&#39;rov sizga har ikkala ob&#39;ektga va boshqa soliq qoidalariga kirish sifatida moslashtirilgan hisob-kitoblarni yozish imkonini beradi. Boshqa yozuvga +, -, *, /, % - matematik operatorlarga havola qilish uchun @ belgisi va element yoki soliq tavsifini qo&#39;shing",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} mijoz tomonidan qabul qilindi." },
	"received_state" to "Qabul qildi",
	"registration_code_fields" to "Ro&#39;yxatdan o&#39;tish kodi",
	"reminder" to "Eslatma",
	"reports" to "Hisobotlar",
	"reports_not_available" to "Hisobotlar mavjud emas, keyinroq qaytib keling.",
	"reveal_password" to "Parolni ochish",
	"revenue" to "revenue",
	"sales_tax" to "QQS",
	"sales_tax_number" to "QQS raqami",
	"save" to "Saqlab olish",
	"scan_document" to "Hujjatni skanerlash",
	"search" to "Qidirish",
	"select" to "Tanlang",
	"select_icon" to "Belgini tanlang",
	"select_signature" to "Imzoni tanlang",
	"select_template" to "Shablonni tanlang",
	"send" to "Yuborish",
	"sent_state" to "Yuborildi",
	"setup_sender" to "Yuboruvchini sozlang",
	"share_option" to "Ulashish",
	"ship_to" to "Ga yetkazib berish",
	"sign_in" to "tizimga kirish",
	"sign_in_apple" to "Apple bilan tizimga kiring",
	"sign_in_email" to "Elektron pochta orqali tizimga kiring",
	"sign_in_facebook" to "Facebook bilan tizimga kiring",
	"sign_in_google" to "Google bilan kiring",
	"sign_up" to "Ro&#39;yxatdan o&#39;tish",
	"sign_up_label" to "Hisobingiz yo&#39;qmi?",
	"sign_up_link_label" to "Ro&#39;yxatdan o&#39;tish!",
	"signatures_option" to "Imzolar",
	"skip" to "O&#39;tkazib yuborish",
	"sold_items_report" to "Sotilgan buyumlar",
	"sort_by" to "Saralash turi:",
	"status_update" to "Hujjat holatini yangilash",
	"sub_annual" to "Yillik",
	"sub_annual_price" to Formattable { (price) -> "${price}/yil" },
	"sub_backups" to "Bulutli zaxira nusxalari",
	"sub_insights" to "Insights",
	"sub_month" to "oylik",
	"sub_monthly_price" to Formattable { (price) -> "${price}/oy" },
	"sub_notifications" to "Bildirishnomalar",
	"sub_popup_message" to "Obunalar tez orada keladi. Birozdan keyin bu yerni tekshiring.",
	"sub_support" to "Qo'llab-quvvatlash",
	"sub_templates" to "Shablonlar",
	"subscribe" to "Obuna bo&#39;lish",
	"subscription_header" to "Biznesingizni quyidagi bilan rivojlantiring:",
	"subscription_option" to "Obuna",
	"subscriptions_load_fail" to "Kechirasiz, hozirda obunalaringizni tiklay olmaymiz. Iltimos, keyinroq qayta urinib ko&#39;ring yoki yordam uchun qo&#39;llab-quvvatlash guruhimizga murojaat qiling.",
	"subtotal" to Formattable { (sum) -> "Oraliq jami: ${sum}" },
	"subtotal_label" to "Oraliq jami",
	"sum" to "Jami",
	"support_option" to "Qo'llab-quvvatlash",
	"switch_reports" to "Hisobotni almashtirish",
	"tax" to "Soliq",
	"tax_deleted_message" to "Soliq olib tashlandi",
	"tax_description_warning" to "Agar foydalanilgan bo&#39;lsa, bu hisob-fakturada ko&#39;rsatiladi",
	"tax_input_flat" to "Yassi",
	"tax_input_percent" to "Foiz",
	"tax_number_fields" to "Soliq raqami",
	"tax_number_hint" to "Soliq raqami",
	"tax_query_info_description" to "So&#39;rov ma&#39;lumotlari",
	"tax_report" to "Soliq",
	"tax_rules_option" to "Soliq qoidalari",
	"taxed_clients_report" to "Soliq to&#39;lanadigan mijozlar",
	"template" to "Shablon",
	"template_selector_info" to "Standart shablonni o&#39;zgartirish uchun suring",
	"template_unlock_promo" to "Premium bilan boshqa andozalarga kiring",
	"templates_option" to "Shablonlar",
	"terms_of_service" to "Xizmat ko&#39;rsatish shartlari",
	"thank_you" to "rahmat",
	"to" to "k",
	"total" to Formattable { (sum) -> "Jami: ${sum}" },
	"total_label" to "Jami",
	"undo_action" to "Bekor qilish",
	"unit" to "Birlik",
	"unit_cost_hint" to "Birlik narxi",
	"unknown_client" to "Noma&#39;lum mijoz",
	"unlock" to "Qulfni ochish",
	"unnamed_item" to "Nomsiz element",
	"upgrade" to "Yangilash",
	"user_collision_error" to "Hisob allaqachon mavjud.",
	"valid_until" to "Yaroqlilik muddati",
	"warning" to "Ogohlantirish!",
	"yes" to "yes",
    )
    
}