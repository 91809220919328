package com.dingvoice

import androidx.compose.runtime.*
import com.dingvoice.components.Layout
import com.dingvoice.components.MainContentLayout
import com.dingvoice.content.Header
import com.dingvoice.content.PageFooter
import com.dingvoice.style.AppStylesheet
import kotlinx.browser.window
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.Style
import org.w3c.dom.events.Event

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun MainLandingPage() {
    var isMobile by remember {
        mutableStateOf(
            window.isMobile()
        )
    }

    DisposableEffect(Unit) {
        val callback: ((Event) -> Unit) = { _ ->
            isMobile = window.isMobile()
        }
        window.addEventListener("resize", callback = callback)

        onDispose {
            window.removeEventListener("resize", callback)
        }
    }

    Style(AppStylesheet)

    Layout {
        Header()
        MainContentLayout(isMobile = isMobile) {
            Landing(isMobile)
        }
        PageFooter(isMobile)
    }
}
