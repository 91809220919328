// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Si  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} සහ ${privacyPolicy} පිළිගන්න" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "වලංගු නොවන කේතය."
override val add_client_action: String = "සේවාදායකයා එක් කරන්න"
override val add_customer: String = "පාරිභෝගික එකතු කරන්න"
override val add_expense: String = "වියදම් එකතු කරන්න"
override val add_fields_label: String = "එකතු කරන්න:"
override val add_invoice_action: String = "සාදන්න"
override val add_item_action: String = "අයිතමය එකතු කරන්න"
override val add_item_button: String = "අයිතමය එකතු කරන්න"
override val add_signature_action: String = "අත්සන එකතු කරන්න"
override val add_tax_action: String = "බදු එකතු කරන්න"
override val address_fields: String = "ලිපිනය"
override val address_line_hint: String = "ලිපිනය ලියන තීරුව"
override val advanced: String = "උසස්"
override val agree_marketing: String = "සෘජු අලෙවිකරණ සන්නිවේදනය ලබා ගැනීමට මම එකඟ වෙමි"
override val all_notifications: String = "සියලුම දැනුම්දීම්"
override val amortization: String = "amortization"
override val amount: String = "ප්රමාණය"
override val asset: String = "asset"
override val attach_file: String = "ගොනුව අමුණන්න"
override val attachment: String = "ඇමුණුම"
override val auth_error: String = "ක්‍රියාව අසාර්ථක විය, නැවත උත්සාහ කරන්න."
override val available_for_paid: String = "Cloud Sync ලබා ගත හැක්කේ ගෙවන දායකත්වය යටතේ පමණි."
override val backups_option: String = "උපස්ථ"
override val balance: String = "balance"
override val bank_account: String = "බැංකු ගිණුම"
override val bill_number: String = "බිල්පත් අංකය"
override val cancel: String = "නවතන්න"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "වර්ග"
override val category: String = "වර්ගය"
override val category_add: String = "ප්රවර්ගය එකතු කරන්න"
override val category_delete: String = "ප්‍රවර්ගය මකන්න"
override val category_name: String = "කාණ්ඩයේ නම"
override val change_language: Formattable = Formattable { (language) -> "ඉන්වොයිස් භාෂාව: ${language}" }
override val change_language_label: String = "ඉන්වොයිස් භාෂාව"
override val change_template: String = "අච්චුව වෙනස් කරන්න"
override val city_hint: String = "නගරය"
override val clear: String = "පැහැදිලිව"
override val client: String = "සේවාදායකයා"
override val client_actions: String = "පාරිභෝගික ක්රියා"
override val client_deleted_message: String = "සේවාදායකයා මකා ඇත"
override val clients_error_required_fields: String = "අවම වශයෙන් එක් අගයක් අවශ්ය වේ."
override val clients_option: String = "ගනුදෙනුකරුවන්"
override val close: String = "වසන්න"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "ඉක්මණින් පැමිණෙන!"
override val company_logo: String = "සමාගම් ලාංඡනය"
override val company_name_hint: String = "සමාගම"
override val confirm_password_label: String = "මුරපදය තහවුරු කරන්න"
override val confirmation_title: String = "ඔයාට විශ්වාස ද?"
override val convert_to_invoice: String = "ඉන්වොයිසිය බවට පරිවර්තනය කරන්න"
override val country_hint: String = "රට"
override val create_client: String = "සේවාදායකයා සාදන්න"
override val create_company: String = "සමාගමක් සාදන්න"
override val create_document: String = "ලේඛනය සාදන්න"
override val create_estimate: String = "ඇස්තමේන්තු කරන්න"
override val create_invoice: String = "ඉන්වොයිසිය"
override val create_item: String = "අයිතමය සාදන්න"
override val create_new_client: String = "නව සේවාදායකයා සාදන්න"
override val create_new_item: String = "නව අයිතමයක් සාදන්න"
override val create_new_tax: String = "අලුත් බද්දක් හදන්න"
override val create_signature: String = "අත්සන"
override val created: String = "නිර්මාණය කර ඇත"
override val credit: String = "credit"
override val customer: String = "පාරිභෝගික"
override val date: String = "Datum"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "පෙරනිමි මුදල්: ${currency}" }
override val default_currency_label: String = "පෙරනිමි මුදල්"
override val delete: String = "ඩිලීට්"
override val delete_account: String = "ගිණුම මකන්න"
override val delete_confirmation_message: String = "ඔබගේ සියලු දත්ත නැති වනු ඇත."
override val delete_item_title: String = "අයිතමය මකන්නද?"
override val delete_signature: String = "අත්සන මකන්න"
override val depreciation: String = "depreciation"
override val description: String = "විස්තරය"
override val description_hint: String = "විස්තරය"
override val detailed_report: String = "සවිස්තර වාර්තාව"
override val disabled: String = "ආබාධිතයි"
override val discount: String = "වට්ටම්"
override val discount_hint: String = "වට්ටම්"
override val display_af: String = "අප්රිකානු"
override val display_am: String = "අම්හාරික්"
override val display_ar: String = "අරාබි"
override val display_as: String = "ඇසෑමි"
override val display_az: String = "අසර්බයිජානියානු"
override val display_be: String = "බෙලාරුසියානු"
override val display_bg: String = "බල්ගේරියානු"
override val display_bn: String = "බෙංගාලි"
override val display_bs: String = "බොස්නියානු"
override val display_ca: String = "කැටලන්"
override val display_cs: String = "චෙක්"
override val display_da: String = "ඩෙන්මාර්ක"
override val display_de: String = "ජර්මානු"
override val display_el: String = "ග්රීක"
override val display_en: String = "ඉංග්රීසි"
override val display_es: String = "ස්පාඤ්ඤ"
override val display_es_MX: String = "ස්පාඤ්ඤ (මෙක්සිකෝව)"
override val display_et: String = "එස්තෝනියානු"
override val display_eu: String = "බාස්ක්"
override val display_fa: String = "පර්සියානු"
override val display_fi: String = "ෆින්ලන්ත"
override val display_fil: String = "පිලිපීනය"
override val display_fr: String = "ප්රංශ"
override val display_fr_CA: String = "කැනේඩියානු ප්රංශ"
override val display_gl: String = "ගැලීසියන්"
override val display_gu: String = "ගුජරාටි"
override val display_he: String = "හෙබ්රෙව්"
override val display_hi: String = "නැහැ"
override val display_hr: String = "ක්රොඒෂියානු"
override val display_hu: String = "හංගේරියානු"
override val display_hy: String = "ආර්මේනියානු"
override val display_id: String = "ඉන්දුනීසියානු"
override val display_is: String = "අයිස්ලන්ත"
override val display_it: String = "ඉතාලි"
override val display_ja: String = "ජපන්"
override val display_ka: String = "ජෝර්ජියානු"
override val display_kk: String = "කසාක්"
override val display_km: String = "මධ්යම කෙමර්"
override val display_kn: String = "කන්නඩ"
override val display_ko: String = "කොරියානු"
override val display_ky: String = "කිර්ගිස්"
override val display_lo: String = "කම්කරු"
override val display_lt: String = "ලිතුවේනියානු"
override val display_lv: String = "ලැට්වියානු"
override val display_mk: String = "මැසිඩෝනියානු"
override val display_ml: String = "මලයාලම්"
override val display_mn: String = "මොන්ගෝලියානු"
override val display_mr: String = "මරාති"
override val display_ms: String = "මැලේ"
override val display_my: String = "බුරුම"
override val display_nb: String = "නෝර්වීජියානු බොක්මාල්"
override val display_ne: String = "නේපාල"
override val display_nl: String = "ලන්දේසි"
override val display_or: String = "ඔරියා"
override val display_pa: String = "පන්ජාබි"
override val display_pl: String = "පෝලන්ත"
override val display_pt: String = "පෘතුගීසි"
override val display_pt_BR: String = "පෘතුගීසි (බ්‍රසීලය)"
override val display_pt_PT: String = "යුරෝපීය පෘතුගීසි"
override val display_ro: String = "රුමේනියානු"
override val display_ru: String = "රුසියානු"
override val display_si: String = "සිංහල"
override val display_sk: String = "ස්ලෝවැක්"
override val display_sl: String = "ස්ලෝවේනියානු"
override val display_sq: String = "ඇල්බේනියානු"
override val display_sr: String = "සර්බියානු"
override val display_sv: String = "ස්වීඩන්"
override val display_sw: String = "ස්වහීලී"
override val display_ta: String = "දෙමළ"
override val display_te: String = "තෙළිඟු"
override val display_th: String = "තායි"
override val display_tr: String = "තුර්කි"
override val display_uk: String = "යුක්රේනියානු"
override val display_ur: String = "උර්දු"
override val display_uz: String = "උස්බෙක්"
override val display_vi: String = "වියට්නාම"
override val display_zh_Hant_TW: String = "චීන (තායිවානය, සම්ප්‍රදායික)"
override val display_zu: String = "සූලු"
override val document_estimate: String = "ඇස්තමේන්තු කරන්න"
override val document_invoice: String = "ඉන්වොයිසිය"
override val document_quote: String = "උපුටා ගැනීම"
override val done: String = "සිදු විය"
override val download_app: String = "යෙදුම බාගන්න"
override val download_invoice: String = "ඉන්වොයිසිය බාගන්න"
override val download_pdf: String = "PDF බාගන්න"
override val draw_signature: String = "අත්සනක් අඳින්න"
override val due: Formattable = Formattable { (date) -> "නියමිත ${date}" }
override val due_date: String = "ගෙවිය යුතු දිනය"
override val due_day: String = "දවසකින්"
override val due_label: String = "නියමිත"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ගෙවිය යුතු ${dueWhen}!" }
override val due_today: String = "අද"
override val due_week: String = "සතියකින්"
override val duplicate: String = "අනුපිටපත් කරන්න"
override val duration_day: String = "දින"
override val duration_month: String = "මාසික"
override val duration_week: String = "සතිය"
override val duration_year: String = "අවුරුදු"
override val email: String = "විද්යුත් තැපෑල"
override val email_blank_error: String = "විද්‍යුත් තැපෑල අවශ්‍යයි."
override val email_error: String = "වලංගු නොවන ඊමේල් ලිපිනයකි."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "ඔබේ ව්‍යාපාරයට ස්තූතියි. සම්පූර්ණ ${documentType} බැලීමට මෙම ලිපියේ අවසානයේ ඇති බොත්තම ක්ලික් කරන්න. එය ${dueDate} දක්වා වලංගු බව සලකන්න." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "ඔබට අලුත් එකක් ලැබී ඇත${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} බලන්න" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "හිතවත් ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "ඔබේ ව්‍යාපාරයට ස්තූතියි. සම්පූර්ණ ${documentType} බැලීමට මෙම ලිපියේ අවසානයේ ඇති බොත්තම ක්ලික් කරන්න. කරුණාකර ${dueDate} දක්වා ශේෂය ගෙවන්න." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "ඔබට අලුත් එකක් ලැබී ඇත${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "මුරපදය යළි පිහිටුවීමට ඊමේල් යවා ඇත, කරුණාකර ඔබගේ එන ලිපි පරීක්ෂා කරන්න."
override val empty_clients_list_message: String = "ඔබට හඹා යාමට ගනුදෙනුකරුවන් නැත. නව සේවාලාභියෙකු එක් කරන්න."
override val empty_estimate_list_message: String = "ඔබේ පළමු ඇස්තමේන්තුව සාදන්න"
override val empty_expenses: String = "ඔබට නිරීක්ෂණය කිරීමට වියදම් නැත. නව වියදමක් එකතු කරන්න."
override val empty_invoice_list_message: String = "ඔබේ පළමු ඉන්වොයිසිය සාදන්න"
override val empty_items_list_message: String = "ඔබට අයිතම කිසිවක් නැත, නව අයිතමයක් එක් කරන්න!"
override val empty_signatures_list_message: String = "අත්සන් කිසිවක් හමු නොවීය. එකක් නිර්මාණය!"
override val empty_taxes_list_message: String = "ඔබට බදු නීති නොමැත. නව බදු රීතියක් එක් කරන්න."
override val empty_templates_list_message: String = "සැකිලි නොමැත."
override val enabled: String = "සක්‍රීය කර ඇත"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "ඇස්තමේන්තු #"
override val estimates: String = "ඇස්තමේන්තු"
override val estimates_report: String = "ඇස්තමේන්තු"
override val expense_bank_fees: String = "බැංකු ගාස්තු"
override val expense_benefits: String = "ප්රතිලාභ"
override val expense_communication: String = "සන්නිවේදන"
override val expense_debt: String = "ණය"
override val expense_deleted: String = "වියදම් මකා ඇත"
override val expense_depreciation: String = "ක්ෂය වීම"
override val expense_gifts: String = "තෑගි"
override val expense_insurance: String = "රක්ෂණ"
override val expense_inventory: String = "ඉන්වෙන්ටරි"
override val expense_legal: String = "නීතිමය"
override val expense_maintenance: String = "නඩත්තු"
override val expense_marketing: String = "අලෙවි"
override val expense_meals: String = "ආහාර"
override val expense_office_supplies: String = "කාර්යාල සැපයුම්"
override val expense_rent: String = "කුලියට"
override val expense_salaries: String = "වැටුප්"
override val expense_shopping: String = "සාප්පු සවාරි"
override val expense_software: String = "මෘදුකාංග"
override val expense_status_available: String = "පවතින"
override val expense_status_processing: String = "සැකසීම"
override val expense_tax: String = "බද්ද"
override val expense_training: String = "පුහුණුව"
override val expense_transport: String = "ප්රවාහන"
override val expense_travel: String = "ගමන්"
override val expense_utility: String = "උපයෝගිතා"
override val expenses: String = "වියදම්"
override val feedback_option: String = "ප්රතිපෝෂණය"
override val filter: String = "පෙරහන"
override val first_name_hint: String = "මුල් නම"
override val forgot_password: String = "මුරපදය නැවත සකසන්න"
override val forgot_password_label: String = "මුරපදය අමතක වුණා ද"
override val from: String = "සිට"
override val general_error: String = "දෝෂයක් සිදු විය, කරුණාකර පසුව නැවත උත්සාහ කරන්න."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "යළිගමනය කරන්න"
override val group_by: String = "සමූහය විසින්:"
override val image_view: String = "රූපය"
override val install: String = "ස්ථාපනය කරන්න"
override val invalid_credentials_error: String = "වලංගු නොවන අක්තපත්‍ර."
override val invalid_email_error: String = "වලංගු නොවන ඊමේල්."
override val invalid_user_error: String = "වලංගු නොවන පරිශීලක අක්තපත්‍ර."
override val invoice_deleted_message: String = "ඉන්වොයිසිය මකා ඇත"
override val invoice_menu: String = "ඉන්වොයිස් මෙනුව"
override val invoice_number: String = "ඉන්වොයිසිය #"
override val invoices: String = "ඉන්වොයිසි"
override val items_option: String = "අයිතම"
override val keep: String = "තබා ගන්න"
override val language_selector: Formattable = Formattable { (selected) -> "භාෂාව ${selected}" }
override val last_name_hint: String = "අවසන් නම"
override val ledger: String = "ledger"
override val legal: String = "නීතිමය"
override val legal_error: String = "සේවා කොන්දේසි සහ රහස්‍යතා ප්‍රතිපත්ති පිළිගත යුතුය"
override val liabilities: String = "liabilities"
override val loading: String = "පූරණය කරමින්…"
override val login_required_error: String = "පුරනය වීම අවශ්‍යයි."
override val logo_view: String = "ලාංඡනය"
override val logout: String = "පිටවීම"
override val logout_prompt: String = "ඔබගේ දත්ත වලාකුළු සමඟ සමමුහුර්ත කර නැත. ඉන්වොයිසි, ඇස්තමේන්තු, සේවාලාභීන් සහ පිටවීම පිළිබඳ වෙනත් තොරතුරු මකන්නද?"
override val main_screen: String = "ප්රධාන තිරය"
override val mark_paid: String = "ගෙවූ ලෙස ලකුණු කරන්න"
override val message: String = "පණිවුඩය"
override val missing_sender_msg: String = "යවන්නාගේ තොරතුරු නොමැති ලේඛනයක් අවලංගු විය හැකිය."
override val missing_sender_title: String = "යවන්නාගේ තොරතුරු අතුරුදහන්"
override val month_april: String = "අප්රේ"
override val month_april_short: String = "අප්රේ"
override val month_august: String = "අගෝස්තු"
override val month_august_short: String = "අගෝ"
override val month_december: String = "දෙසැම්බර්"
override val month_december_short: String = "දෙසැ"
override val month_february: String = "පෙබරවාරි"
override val month_february_short: String = "පෙබ"
override val month_january: String = "ජනවාරි"
override val month_january_short: String = "ජන"
override val month_july: String = "ජුලි"
override val month_july_short: String = "ජුලි"
override val month_june: String = "ජූනි"
override val month_june_short: String = "ජූනි"
override val month_march: String = "මාර්තු"
override val month_march_short: String = "මාර්තු"
override val month_may: String = "මැයි"
override val month_may_short: String = "මැයි"
override val month_november: String = "නොවැම්බර්"
override val month_november_short: String = "නොවැ"
override val month_october: String = "ඔක්තෝම්බර්"
override val month_october_short: String = "ඔක්"
override val month_september: String = "සැප්තැම්බර්"
override val month_september_short: String = "සැප්"
override val multifactor_error: String = "සත්‍යාපනය කිරීමට අසමත් විය."
override val new: String = "අලුත්"
override val news: String = "පුවත්"
override val next: String = "මීළඟට"
override val no: String = "no"
override val no_data_available: String = "ලබා ගත හැකි දත්ත නැත"
override val no_search_results: String = "සමාවන්න, අපට ප්‍රතිඵල කිසිවක් සොයාගත නොහැකි විය."
override val none: String = "කිසිවක් නැත"
override val note_fields: String = "සටහන්"
override val note_hint: String = "සටහන"
override val notifications_option: String = "දැනුම්දීම්"
override val ok: String = "හරි"
override val onboarding: String = "ඇතුල් වීම"
override val onboarding_aboutus_message: String = "DingVoice හි අත්‍යවශ්‍ය කොටස් සදහටම නොමිලේ. අපගේ මෙහෙවර වන්නේ ඔබට ඔබේ ව්‍යාපාරය වර්ධනය කර ගැනීමට උදවු කිරීම, ඔබේ ප්‍රතිපෝෂණ බෙදාගැනීමෙන් අපට වැඩිදියුණු කිරීමට උදවු කිරීමයි."
override val onboarding_aboutus_title: String = "අපි උදව් කිරීමට මෙහි සිටිමු"
override val onboarding_paid_1: String = "වෘත්තීය පෙනුමක් ඇති ආකෘති වෙත ප්‍රවේශ වන්න"
override val onboarding_paid_2: String = "ඔබේ මූල්‍ය කටයුතු ගැන ගැඹුරින් බලන්න"
override val onboarding_paid_3: String = "ගැටළු ඉක්මනින් විසඳා ගන්න"
override val onboarding_paid_4: String = "ඔබගේ ලේඛන සුරක්ෂිතව තබා ගන්න"
override val onboarding_paid_5: String = "ඉන්වොයිස් තත්ත්‍වයේ වෙනස්කම් සමඟ යාවත්කාලීනව සිටින්න"
override val onboarding_paid_title: String = "සමඟ තවත් වේගයෙන් වර්ධනය වන්න..."
override val onboarding_paywall_message: String = "සියලුම වාරික විශේෂාංග දැන් හොඳම මිලට භුක්ති විඳින්න."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} සඳහා ${currencySymbol}${introAmount} සහ පසුව ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "හුදෙක් ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} සඳහා නොමිලේ සහ පසුව ${currencySymbol}${amount} / ${duration} සඳහා නොමිලේ" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "සඳහා නොමිලේ${trialDuration}${trialPeriod} ඉන්පසු${currencySymbol}${introductoryAmount} සදහා${introductoryDuration}${introPeriod} පසුව නිකම්${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "ස්වයංක්‍රීය පුනර්ජනනීය. ඕනෑම වේලාවක අවලංගු කරන්න."
override val onboarding_paywall_restore: String = "මිලදී ගැනීම ප්‍රතිසාධනය කරන්න"
override val onboarding_paywall_restore_failure: String = "මිලදී ගැනීම් ප්‍රතිසාධනය කිරීම අසාර්ථක විය, පසුව නැවත උත්සාහ කරන්න හෝ සහාය සම්බන්ධ කර ගන්න."
override val onboarding_paywall_restore_success: String = "ප්‍රතිසාධනය සාර්ථකයි!"
override val onboarding_paywall_title: String = "ශ්රේෂ්ඨත්වය අත් කර ගන්න!"
override val onboarding_welcome_message: String = "ඔබේ ඉන්වොයිසි සහ ඇස්තමේන්තු කළමනාකරණය කිරීමට සම්පූර්ණ මෙවලම් කට්ටලයක්."
override val onboarding_welcome_title: String = "DingVoice වෙත සාදරයෙන් පිළිගනිමු"
override val other_state: String = "වෙනත්"
override val overdue_state: String = "කල් ඉකුත් වී ඇත"
override val paid_state: String = "ගෙවා ඇත"
override val password: String = "මුරපදය"
override val password_blank_error: String = "මුරපදය අවශ්‍යයි."
override val password_mismatch_error: String = "මුරපද නොගැලපේ."
override val pay: String = "pay"
override val payment_instructions_label: String = "ගෙවීම් උපදෙස්"
override val payment_reminders: String = "ගෙවීම් මතක් කිරීම්"
override val phone_number_fields: String = "දුරකතන අංකය"
override val phone_number_hint: String = "දුරකතන අංකය"
override val postcode_hint: String = "තැපැල් කේතය"
override val preview: String = "පෙරදසුන"
override val preview_menu_hint: String = "පෙරදසුන් මෙනුව"
override val price: String = "මිල"
override val privacy_policy: String = "රහස්යතා ප්රතිපත්තිය"
override val profile: String = "පැතිකඩ"
override val profitable_client: String = "ඔබේ වඩාත්ම ලාභදායී සේවාදායකයා වන්නේ..."
override val profits_report: String = "ලාභ"
override val promo_content: String = "ප්‍රවර්ධන අන්තර්ගතය"
override val quantity_hint: String = "ප්‍රමාණය"
override val quantity_label_hint: String = "ප්රමාණ ලේබලය"
override val query_info: String = "Query මඟින් ඔබට අයිතම සහ අනෙකුත් බදු නීති යන දෙකටම ආදාන ලෙස අභිරුචි ගණනය කිරීම් ලිවීමට ඉඩ සලසයි. තවත් ප්‍රවේශයක් +, -, *, /, % - ගණිත ක්‍රියාකරුවන් වෙත සම්බන්ධ කිරීමට @ සංකේතය සහ අයිතමය හෝ බදු විස්තරය එක් කරන්න"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ඔබේ සේවාදායකයාට ලැබී ඇත." }
override val received_state: String = "ලැබුනා"
override val registration_code_fields: String = "ලියාපදිංචි කේතය"
override val reminder: String = "මතක්"
override val reports: String = "වාර්තා"
override val reports_not_available: String = "වාර්තා නොමැත, පසුව ආපසු එන්න."
override val reveal_password: String = "මුරපදය හෙළි කරන්න"
override val revenue: String = "revenue"
override val sales_tax: String = "වැට් බද්ද"
override val sales_tax_number: String = "වැට් අංකය"
override val save: String = "ඉතිරිකර ගන්න"
override val scan_document: String = "ලේඛනය පරිලෝකනය කරන්න"
override val search: String = "සෙවීම"
override val select: String = "තෝරන්න"
override val select_icon: String = "අයිකනය තෝරන්න"
override val select_signature: String = "අත්සන තෝරන්න"
override val select_template: String = "අච්චුව තෝරන්න"
override val send: String = "යවන්න"
override val sent_state: String = "යවා ඇත"
override val setup_sender: String = "යවන්නා සකසන්න"
override val share_option: String = "බෙදා ගන්න"
override val ship_to: String = "වෙත නැව්ගත කරන්න"
override val sign_in: String = "පුරන්න"
override val sign_in_apple: String = "Apple සමඟින් පුරනය වන්න"
override val sign_in_email: String = "විද්‍යුත් තැපෑලෙන් පුරන්න"
override val sign_in_facebook: String = "Facebook සමඟින් පුරනය වන්න"
override val sign_in_google: String = "Google සමඟින් පුරනය වන්න"
override val sign_up: String = "ලියාපදිංචි වන්න"
override val sign_up_label: String = "ගිණුමක් නැද්ද?"
override val sign_up_link_label: String = "ලියාපදිංචි වන්න!"
override val signatures_option: String = "අත්සන්"
override val skip: String = "මඟ හරින්න"
override val sold_items_report: String = "විකුණන ලද භාණ්ඩ"
override val sort_by: String = "අනුව තෝරන්න:"
override val status_update: String = "ලේඛන තත්ත්වය යාවත්කාලීන කිරීම"
override val sub_annual: String = "වාර්ෂික"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/වසරකට" }
override val sub_backups: String = "Cloud Backups"
override val sub_insights: String = "සූක්ෂ්ම දෘෂ්ටියක්"
override val sub_month: String = "මාසිකව"
override val sub_monthly_price: Formattable = Formattable { (price) -> "මසකට ${price}" }
override val sub_notifications: String = "දැනුම්දීම්"
override val sub_popup_message: String = "දායකත්වයන් ඉක්මනින් පැමිණේ. ටික වේලාවකින් නැවත මෙහි පරීක්ෂා කරන්න."
override val sub_support: String = "සහාය"
override val sub_templates: String = "අච්චු"
override val subscribe: String = "දායක වන්න"
override val subscription_header: String = "ඔබේ ව්‍යාපාරය දියුණු කරන්න:"
override val subscription_option: String = "දායකත්වය"
override val subscriptions_load_fail: String = "සමාවන්න, අපට මෙම අවස්ථාවේදී ඔබගේ දායකත්වයන් ලබා ගැනීමට නොහැකි විය. කරුණාකර පසුව නැවත උත්සාහ කරන්න හෝ සහාය සඳහා අපගේ සහාය කණ්ඩායම අමතන්න."
override val subtotal: Formattable = Formattable { (sum) -> "උප එකතුව: ${sum}" }
override val subtotal_label: String = "උප එකතුව"
override val sum: String = "එකතුව"
override val support_option: String = "සහාය"
override val switch_reports: String = "ස්විච් වාර්තාව"
override val tax: String = "බද්ද"
override val tax_deleted_message: String = "බදු මකා දමන ලදී"
override val tax_description_warning: String = "භාවිතා කළහොත් මෙය ඉන්වොයිසියක පෙන්වයි"
override val tax_input_flat: String = "පැතලි"
override val tax_input_percent: String = "සියයට"
override val tax_number_fields: String = "බදු අංකය"
override val tax_number_hint: String = "බදු අංකය"
override val tax_query_info_description: String = "විමසුම් තොරතුරු"
override val tax_report: String = "බද්ද"
override val tax_rules_option: String = "බදු නීති"
override val taxed_clients_report: String = "බදු අය කළ ගනුදෙනුකරුවන්"
override val template: String = "සැකිල්ල"
override val template_selector_info: String = "පෙරනිමි අච්චුව වෙනස් කිරීමට ස්වයිප් කරන්න"
override val template_unlock_promo: String = "Premium සමඟ තවත් අච්චු වෙත ප්‍රවේශ වන්න"
override val templates_option: String = "සැකිලි"
override val terms_of_service: String = "සේවා කොන්දේසි"
override val thank_you: String = "ඔයාට ස්තූතියි"
override val to: String = "දක්වා"
override val total: Formattable = Formattable { (sum) -> "එකතුව: ${sum}" }
override val total_label: String = "සමස්ත"
override val undo_action: String = "අහෝසි කරන්න"
override val unit: String = "ඒකකය"
override val unit_cost_hint: String = "ඒකක පිරිවැය"
override val unknown_client: String = "නොදන්නා සේවාදායකයා"
override val unlock: String = "අගුළු ඇරීම"
override val unnamed_item: String = "නම් නොකළ අයිතමය"
override val upgrade: String = "උත්ශ්‍රේණි කරන්න"
override val user_collision_error: String = "ගිණුම දැනටමත් පවතී."
override val valid_until: String = "දක්වා වලංගු වේ"
override val warning: String = "අවවාදයයි!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} සහ ${privacyPolicy} පිළිගන්න" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "වලංගු නොවන කේතය.",
	"add_client_action" to "සේවාදායකයා එක් කරන්න",
	"add_customer" to "පාරිභෝගික එකතු කරන්න",
	"add_expense" to "වියදම් එකතු කරන්න",
	"add_fields_label" to "එකතු කරන්න:",
	"add_invoice_action" to "සාදන්න",
	"add_item_action" to "අයිතමය එකතු කරන්න",
	"add_item_button" to "අයිතමය එකතු කරන්න",
	"add_signature_action" to "අත්සන එකතු කරන්න",
	"add_tax_action" to "බදු එකතු කරන්න",
	"address_fields" to "ලිපිනය",
	"address_line_hint" to "ලිපිනය ලියන තීරුව",
	"advanced" to "උසස්",
	"agree_marketing" to "සෘජු අලෙවිකරණ සන්නිවේදනය ලබා ගැනීමට මම එකඟ වෙමි",
	"all_notifications" to "සියලුම දැනුම්දීම්",
	"amortization" to "amortization",
	"amount" to "ප්රමාණය",
	"asset" to "asset",
	"attach_file" to "ගොනුව අමුණන්න",
	"attachment" to "ඇමුණුම",
	"auth_error" to "ක්‍රියාව අසාර්ථක විය, නැවත උත්සාහ කරන්න.",
	"available_for_paid" to "Cloud Sync ලබා ගත හැක්කේ ගෙවන දායකත්වය යටතේ පමණි.",
	"backups_option" to "උපස්ථ",
	"balance" to "balance",
	"bank_account" to "බැංකු ගිණුම",
	"bill_number" to "බිල්පත් අංකය",
	"cancel" to "නවතන්න",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "වර්ග",
	"category" to "වර්ගය",
	"category_add" to "ප්රවර්ගය එකතු කරන්න",
	"category_delete" to "ප්‍රවර්ගය මකන්න",
	"category_name" to "කාණ්ඩයේ නම",
	"change_language" to Formattable { (language) -> "ඉන්වොයිස් භාෂාව: ${language}" },
	"change_language_label" to "ඉන්වොයිස් භාෂාව",
	"change_template" to "අච්චුව වෙනස් කරන්න",
	"city_hint" to "නගරය",
	"clear" to "පැහැදිලිව",
	"client" to "සේවාදායකයා",
	"client_actions" to "පාරිභෝගික ක්රියා",
	"client_deleted_message" to "සේවාදායකයා මකා ඇත",
	"clients_error_required_fields" to "අවම වශයෙන් එක් අගයක් අවශ්ය වේ.",
	"clients_option" to "ගනුදෙනුකරුවන්",
	"close" to "වසන්න",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "ඉක්මණින් පැමිණෙන!",
	"company_logo" to "සමාගම් ලාංඡනය",
	"company_name_hint" to "සමාගම",
	"confirm_password_label" to "මුරපදය තහවුරු කරන්න",
	"confirmation_title" to "ඔයාට විශ්වාස ද?",
	"convert_to_invoice" to "ඉන්වොයිසිය බවට පරිවර්තනය කරන්න",
	"country_hint" to "රට",
	"create_client" to "සේවාදායකයා සාදන්න",
	"create_company" to "සමාගමක් සාදන්න",
	"create_document" to "ලේඛනය සාදන්න",
	"create_estimate" to "ඇස්තමේන්තු කරන්න",
	"create_invoice" to "ඉන්වොයිසිය",
	"create_item" to "අයිතමය සාදන්න",
	"create_new_client" to "නව සේවාදායකයා සාදන්න",
	"create_new_item" to "නව අයිතමයක් සාදන්න",
	"create_new_tax" to "අලුත් බද්දක් හදන්න",
	"create_signature" to "අත්සන",
	"created" to "නිර්මාණය කර ඇත",
	"credit" to "credit",
	"customer" to "පාරිභෝගික",
	"date" to "Datum",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "පෙරනිමි මුදල්: ${currency}" },
	"default_currency_label" to "පෙරනිමි මුදල්",
	"delete" to "ඩිලීට්",
	"delete_account" to "ගිණුම මකන්න",
	"delete_confirmation_message" to "ඔබගේ සියලු දත්ත නැති වනු ඇත.",
	"delete_item_title" to "අයිතමය මකන්නද?",
	"delete_signature" to "අත්සන මකන්න",
	"depreciation" to "depreciation",
	"description" to "විස්තරය",
	"description_hint" to "විස්තරය",
	"detailed_report" to "සවිස්තර වාර්තාව",
	"disabled" to "ආබාධිතයි",
	"discount" to "වට්ටම්",
	"discount_hint" to "වට්ටම්",
	"display_af" to "අප්රිකානු",
	"display_am" to "අම්හාරික්",
	"display_ar" to "අරාබි",
	"display_as" to "ඇසෑමි",
	"display_az" to "අසර්බයිජානියානු",
	"display_be" to "බෙලාරුසියානු",
	"display_bg" to "බල්ගේරියානු",
	"display_bn" to "බෙංගාලි",
	"display_bs" to "බොස්නියානු",
	"display_ca" to "කැටලන්",
	"display_cs" to "චෙක්",
	"display_da" to "ඩෙන්මාර්ක",
	"display_de" to "ජර්මානු",
	"display_el" to "ග්රීක",
	"display_en" to "ඉංග්රීසි",
	"display_es" to "ස්පාඤ්ඤ",
	"display_es_MX" to "ස්පාඤ්ඤ (මෙක්සිකෝව)",
	"display_et" to "එස්තෝනියානු",
	"display_eu" to "බාස්ක්",
	"display_fa" to "පර්සියානු",
	"display_fi" to "ෆින්ලන්ත",
	"display_fil" to "පිලිපීනය",
	"display_fr" to "ප්රංශ",
	"display_fr_CA" to "කැනේඩියානු ප්රංශ",
	"display_gl" to "ගැලීසියන්",
	"display_gu" to "ගුජරාටි",
	"display_he" to "හෙබ්රෙව්",
	"display_hi" to "නැහැ",
	"display_hr" to "ක්රොඒෂියානු",
	"display_hu" to "හංගේරියානු",
	"display_hy" to "ආර්මේනියානු",
	"display_id" to "ඉන්දුනීසියානු",
	"display_is" to "අයිස්ලන්ත",
	"display_it" to "ඉතාලි",
	"display_ja" to "ජපන්",
	"display_ka" to "ජෝර්ජියානු",
	"display_kk" to "කසාක්",
	"display_km" to "මධ්යම කෙමර්",
	"display_kn" to "කන්නඩ",
	"display_ko" to "කොරියානු",
	"display_ky" to "කිර්ගිස්",
	"display_lo" to "කම්කරු",
	"display_lt" to "ලිතුවේනියානු",
	"display_lv" to "ලැට්වියානු",
	"display_mk" to "මැසිඩෝනියානු",
	"display_ml" to "මලයාලම්",
	"display_mn" to "මොන්ගෝලියානු",
	"display_mr" to "මරාති",
	"display_ms" to "මැලේ",
	"display_my" to "බුරුම",
	"display_nb" to "නෝර්වීජියානු බොක්මාල්",
	"display_ne" to "නේපාල",
	"display_nl" to "ලන්දේසි",
	"display_or" to "ඔරියා",
	"display_pa" to "පන්ජාබි",
	"display_pl" to "පෝලන්ත",
	"display_pt" to "පෘතුගීසි",
	"display_pt_BR" to "පෘතුගීසි (බ්‍රසීලය)",
	"display_pt_PT" to "යුරෝපීය පෘතුගීසි",
	"display_ro" to "රුමේනියානු",
	"display_ru" to "රුසියානු",
	"display_si" to "සිංහල",
	"display_sk" to "ස්ලෝවැක්",
	"display_sl" to "ස්ලෝවේනියානු",
	"display_sq" to "ඇල්බේනියානු",
	"display_sr" to "සර්බියානු",
	"display_sv" to "ස්වීඩන්",
	"display_sw" to "ස්වහීලී",
	"display_ta" to "දෙමළ",
	"display_te" to "තෙළිඟු",
	"display_th" to "තායි",
	"display_tr" to "තුර්කි",
	"display_uk" to "යුක්රේනියානු",
	"display_ur" to "උර්දු",
	"display_uz" to "උස්බෙක්",
	"display_vi" to "වියට්නාම",
	"display_zh_Hant_TW" to "චීන (තායිවානය, සම්ප්‍රදායික)",
	"display_zu" to "සූලු",
	"document_estimate" to "ඇස්තමේන්තු කරන්න",
	"document_invoice" to "ඉන්වොයිසිය",
	"document_quote" to "උපුටා ගැනීම",
	"done" to "සිදු විය",
	"download_app" to "යෙදුම බාගන්න",
	"download_invoice" to "ඉන්වොයිසිය බාගන්න",
	"download_pdf" to "PDF බාගන්න",
	"draw_signature" to "අත්සනක් අඳින්න",
	"due" to Formattable { (date) -> "නියමිත ${date}" },
	"due_date" to "ගෙවිය යුතු දිනය",
	"due_day" to "දවසකින්",
	"due_label" to "නියමිත",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ගෙවිය යුතු ${dueWhen}!" },
	"due_today" to "අද",
	"due_week" to "සතියකින්",
	"duplicate" to "අනුපිටපත් කරන්න",
	"duration_day" to "දින",
	"duration_month" to "මාසික",
	"duration_week" to "සතිය",
	"duration_year" to "අවුරුදු",
	"email" to "විද්යුත් තැපෑල",
	"email_blank_error" to "විද්‍යුත් තැපෑල අවශ්‍යයි.",
	"email_error" to "වලංගු නොවන ඊමේල් ලිපිනයකි.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "ඔබේ ව්‍යාපාරයට ස්තූතියි. සම්පූර්ණ ${documentType} බැලීමට මෙම ලිපියේ අවසානයේ ඇති බොත්තම ක්ලික් කරන්න. එය ${dueDate} දක්වා වලංගු බව සලකන්න." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "ඔබට අලුත් එකක් ලැබී ඇත${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} බලන්න" },
	"email_invoice_greeting" to Formattable { (clientName) -> "හිතවත් ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "ඔබේ ව්‍යාපාරයට ස්තූතියි. සම්පූර්ණ ${documentType} බැලීමට මෙම ලිපියේ අවසානයේ ඇති බොත්තම ක්ලික් කරන්න. කරුණාකර ${dueDate} දක්වා ශේෂය ගෙවන්න." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "ඔබට අලුත් එකක් ලැබී ඇත${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "මුරපදය යළි පිහිටුවීමට ඊමේල් යවා ඇත, කරුණාකර ඔබගේ එන ලිපි පරීක්ෂා කරන්න.",
	"empty_clients_list_message" to "ඔබට හඹා යාමට ගනුදෙනුකරුවන් නැත. නව සේවාලාභියෙකු එක් කරන්න.",
	"empty_estimate_list_message" to "ඔබේ පළමු ඇස්තමේන්තුව සාදන්න",
	"empty_expenses" to "ඔබට නිරීක්ෂණය කිරීමට වියදම් නැත. නව වියදමක් එකතු කරන්න.",
	"empty_invoice_list_message" to "ඔබේ පළමු ඉන්වොයිසිය සාදන්න",
	"empty_items_list_message" to "ඔබට අයිතම කිසිවක් නැත, නව අයිතමයක් එක් කරන්න!",
	"empty_signatures_list_message" to "අත්සන් කිසිවක් හමු නොවීය. එකක් නිර්මාණය!",
	"empty_taxes_list_message" to "ඔබට බදු නීති නොමැත. නව බදු රීතියක් එක් කරන්න.",
	"empty_templates_list_message" to "සැකිලි නොමැත.",
	"enabled" to "සක්‍රීය කර ඇත",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "ඇස්තමේන්තු #",
	"estimates" to "ඇස්තමේන්තු",
	"estimates_report" to "ඇස්තමේන්තු",
	"expense_bank_fees" to "බැංකු ගාස්තු",
	"expense_benefits" to "ප්රතිලාභ",
	"expense_communication" to "සන්නිවේදන",
	"expense_debt" to "ණය",
	"expense_deleted" to "වියදම් මකා ඇත",
	"expense_depreciation" to "ක්ෂය වීම",
	"expense_gifts" to "තෑගි",
	"expense_insurance" to "රක්ෂණ",
	"expense_inventory" to "ඉන්වෙන්ටරි",
	"expense_legal" to "නීතිමය",
	"expense_maintenance" to "නඩත්තු",
	"expense_marketing" to "අලෙවි",
	"expense_meals" to "ආහාර",
	"expense_office_supplies" to "කාර්යාල සැපයුම්",
	"expense_rent" to "කුලියට",
	"expense_salaries" to "වැටුප්",
	"expense_shopping" to "සාප්පු සවාරි",
	"expense_software" to "මෘදුකාංග",
	"expense_status_available" to "පවතින",
	"expense_status_processing" to "සැකසීම",
	"expense_tax" to "බද්ද",
	"expense_training" to "පුහුණුව",
	"expense_transport" to "ප්රවාහන",
	"expense_travel" to "ගමන්",
	"expense_utility" to "උපයෝගිතා",
	"expenses" to "වියදම්",
	"feedback_option" to "ප්රතිපෝෂණය",
	"filter" to "පෙරහන",
	"first_name_hint" to "මුල් නම",
	"forgot_password" to "මුරපදය නැවත සකසන්න",
	"forgot_password_label" to "මුරපදය අමතක වුණා ද",
	"from" to "සිට",
	"general_error" to "දෝෂයක් සිදු විය, කරුණාකර පසුව නැවත උත්සාහ කරන්න.",
	"general_ledger" to "general ledger",
	"go_back_label" to "යළිගමනය කරන්න",
	"group_by" to "සමූහය විසින්:",
	"image_view" to "රූපය",
	"install" to "ස්ථාපනය කරන්න",
	"invalid_credentials_error" to "වලංගු නොවන අක්තපත්‍ර.",
	"invalid_email_error" to "වලංගු නොවන ඊමේල්.",
	"invalid_user_error" to "වලංගු නොවන පරිශීලක අක්තපත්‍ර.",
	"invoice_deleted_message" to "ඉන්වොයිසිය මකා ඇත",
	"invoice_menu" to "ඉන්වොයිස් මෙනුව",
	"invoice_number" to "ඉන්වොයිසිය #",
	"invoices" to "ඉන්වොයිසි",
	"items_option" to "අයිතම",
	"keep" to "තබා ගන්න",
	"language_selector" to Formattable { (selected) -> "භාෂාව ${selected}" },
	"last_name_hint" to "අවසන් නම",
	"ledger" to "ledger",
	"legal" to "නීතිමය",
	"legal_error" to "සේවා කොන්දේසි සහ රහස්‍යතා ප්‍රතිපත්ති පිළිගත යුතුය",
	"liabilities" to "liabilities",
	"loading" to "පූරණය කරමින්…",
	"login_required_error" to "පුරනය වීම අවශ්‍යයි.",
	"logo_view" to "ලාංඡනය",
	"logout" to "පිටවීම",
	"logout_prompt" to "ඔබගේ දත්ත වලාකුළු සමඟ සමමුහුර්ත කර නැත. ඉන්වොයිසි, ඇස්තමේන්තු, සේවාලාභීන් සහ පිටවීම පිළිබඳ වෙනත් තොරතුරු මකන්නද?",
	"main_screen" to "ප්රධාන තිරය",
	"mark_paid" to "ගෙවූ ලෙස ලකුණු කරන්න",
	"message" to "පණිවුඩය",
	"missing_sender_msg" to "යවන්නාගේ තොරතුරු නොමැති ලේඛනයක් අවලංගු විය හැකිය.",
	"missing_sender_title" to "යවන්නාගේ තොරතුරු අතුරුදහන්",
	"month_april" to "අප්රේ",
	"month_april_short" to "අප්රේ",
	"month_august" to "අගෝස්තු",
	"month_august_short" to "අගෝ",
	"month_december" to "දෙසැම්බර්",
	"month_december_short" to "දෙසැ",
	"month_february" to "පෙබරවාරි",
	"month_february_short" to "පෙබ",
	"month_january" to "ජනවාරි",
	"month_january_short" to "ජන",
	"month_july" to "ජුලි",
	"month_july_short" to "ජුලි",
	"month_june" to "ජූනි",
	"month_june_short" to "ජූනි",
	"month_march" to "මාර්තු",
	"month_march_short" to "මාර්තු",
	"month_may" to "මැයි",
	"month_may_short" to "මැයි",
	"month_november" to "නොවැම්බර්",
	"month_november_short" to "නොවැ",
	"month_october" to "ඔක්තෝම්බර්",
	"month_october_short" to "ඔක්",
	"month_september" to "සැප්තැම්බර්",
	"month_september_short" to "සැප්",
	"multifactor_error" to "සත්‍යාපනය කිරීමට අසමත් විය.",
	"new" to "අලුත්",
	"news" to "පුවත්",
	"next" to "මීළඟට",
	"no" to "no",
	"no_data_available" to "ලබා ගත හැකි දත්ත නැත",
	"no_search_results" to "සමාවන්න, අපට ප්‍රතිඵල කිසිවක් සොයාගත නොහැකි විය.",
	"none" to "කිසිවක් නැත",
	"note_fields" to "සටහන්",
	"note_hint" to "සටහන",
	"notifications_option" to "දැනුම්දීම්",
	"ok" to "හරි",
	"onboarding" to "ඇතුල් වීම",
	"onboarding_aboutus_message" to "DingVoice හි අත්‍යවශ්‍ය කොටස් සදහටම නොමිලේ. අපගේ මෙහෙවර වන්නේ ඔබට ඔබේ ව්‍යාපාරය වර්ධනය කර ගැනීමට උදවු කිරීම, ඔබේ ප්‍රතිපෝෂණ බෙදාගැනීමෙන් අපට වැඩිදියුණු කිරීමට උදවු කිරීමයි.",
	"onboarding_aboutus_title" to "අපි උදව් කිරීමට මෙහි සිටිමු",
	"onboarding_paid_1" to "වෘත්තීය පෙනුමක් ඇති ආකෘති වෙත ප්‍රවේශ වන්න",
	"onboarding_paid_2" to "ඔබේ මූල්‍ය කටයුතු ගැන ගැඹුරින් බලන්න",
	"onboarding_paid_3" to "ගැටළු ඉක්මනින් විසඳා ගන්න",
	"onboarding_paid_4" to "ඔබගේ ලේඛන සුරක්ෂිතව තබා ගන්න",
	"onboarding_paid_5" to "ඉන්වොයිස් තත්ත්‍වයේ වෙනස්කම් සමඟ යාවත්කාලීනව සිටින්න",
	"onboarding_paid_title" to "සමඟ තවත් වේගයෙන් වර්ධනය වන්න...",
	"onboarding_paywall_message" to "සියලුම වාරික විශේෂාංග දැන් හොඳම මිලට භුක්ති විඳින්න.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} සඳහා ${currencySymbol}${introAmount} සහ පසුව ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "හුදෙක් ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} සඳහා නොමිලේ සහ පසුව ${currencySymbol}${amount} / ${duration} සඳහා නොමිලේ" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "සඳහා නොමිලේ${trialDuration}${trialPeriod} ඉන්පසු${currencySymbol}${introductoryAmount} සදහා${introductoryDuration}${introPeriod} පසුව නිකම්${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "ස්වයංක්‍රීය පුනර්ජනනීය. ඕනෑම වේලාවක අවලංගු කරන්න.",
	"onboarding_paywall_restore" to "මිලදී ගැනීම ප්‍රතිසාධනය කරන්න",
	"onboarding_paywall_restore_failure" to "මිලදී ගැනීම් ප්‍රතිසාධනය කිරීම අසාර්ථක විය, පසුව නැවත උත්සාහ කරන්න හෝ සහාය සම්බන්ධ කර ගන්න.",
	"onboarding_paywall_restore_success" to "ප්‍රතිසාධනය සාර්ථකයි!",
	"onboarding_paywall_title" to "ශ්රේෂ්ඨත්වය අත් කර ගන්න!",
	"onboarding_welcome_message" to "ඔබේ ඉන්වොයිසි සහ ඇස්තමේන්තු කළමනාකරණය කිරීමට සම්පූර්ණ මෙවලම් කට්ටලයක්.",
	"onboarding_welcome_title" to "DingVoice වෙත සාදරයෙන් පිළිගනිමු",
	"other_state" to "වෙනත්",
	"overdue_state" to "කල් ඉකුත් වී ඇත",
	"paid_state" to "ගෙවා ඇත",
	"password" to "මුරපදය",
	"password_blank_error" to "මුරපදය අවශ්‍යයි.",
	"password_mismatch_error" to "මුරපද නොගැලපේ.",
	"pay" to "pay",
	"payment_instructions_label" to "ගෙවීම් උපදෙස්",
	"payment_reminders" to "ගෙවීම් මතක් කිරීම්",
	"phone_number_fields" to "දුරකතන අංකය",
	"phone_number_hint" to "දුරකතන අංකය",
	"postcode_hint" to "තැපැල් කේතය",
	"preview" to "පෙරදසුන",
	"preview_menu_hint" to "පෙරදසුන් මෙනුව",
	"price" to "මිල",
	"privacy_policy" to "රහස්යතා ප්රතිපත්තිය",
	"profile" to "පැතිකඩ",
	"profitable_client" to "ඔබේ වඩාත්ම ලාභදායී සේවාදායකයා වන්නේ...",
	"profits_report" to "ලාභ",
	"promo_content" to "ප්‍රවර්ධන අන්තර්ගතය",
	"quantity_hint" to "ප්‍රමාණය",
	"quantity_label_hint" to "ප්රමාණ ලේබලය",
	"query_info" to "Query මඟින් ඔබට අයිතම සහ අනෙකුත් බදු නීති යන දෙකටම ආදාන ලෙස අභිරුචි ගණනය කිරීම් ලිවීමට ඉඩ සලසයි. තවත් ප්‍රවේශයක් +, -, *, /, % - ගණිත ක්‍රියාකරුවන් වෙත සම්බන්ධ කිරීමට @ සංකේතය සහ අයිතමය හෝ බදු විස්තරය එක් කරන්න",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ඔබේ සේවාදායකයාට ලැබී ඇත." },
	"received_state" to "ලැබුනා",
	"registration_code_fields" to "ලියාපදිංචි කේතය",
	"reminder" to "මතක්",
	"reports" to "වාර්තා",
	"reports_not_available" to "වාර්තා නොමැත, පසුව ආපසු එන්න.",
	"reveal_password" to "මුරපදය හෙළි කරන්න",
	"revenue" to "revenue",
	"sales_tax" to "වැට් බද්ද",
	"sales_tax_number" to "වැට් අංකය",
	"save" to "ඉතිරිකර ගන්න",
	"scan_document" to "ලේඛනය පරිලෝකනය කරන්න",
	"search" to "සෙවීම",
	"select" to "තෝරන්න",
	"select_icon" to "අයිකනය තෝරන්න",
	"select_signature" to "අත්සන තෝරන්න",
	"select_template" to "අච්චුව තෝරන්න",
	"send" to "යවන්න",
	"sent_state" to "යවා ඇත",
	"setup_sender" to "යවන්නා සකසන්න",
	"share_option" to "බෙදා ගන්න",
	"ship_to" to "වෙත නැව්ගත කරන්න",
	"sign_in" to "පුරන්න",
	"sign_in_apple" to "Apple සමඟින් පුරනය වන්න",
	"sign_in_email" to "විද්‍යුත් තැපෑලෙන් පුරන්න",
	"sign_in_facebook" to "Facebook සමඟින් පුරනය වන්න",
	"sign_in_google" to "Google සමඟින් පුරනය වන්න",
	"sign_up" to "ලියාපදිංචි වන්න",
	"sign_up_label" to "ගිණුමක් නැද්ද?",
	"sign_up_link_label" to "ලියාපදිංචි වන්න!",
	"signatures_option" to "අත්සන්",
	"skip" to "මඟ හරින්න",
	"sold_items_report" to "විකුණන ලද භාණ්ඩ",
	"sort_by" to "අනුව තෝරන්න:",
	"status_update" to "ලේඛන තත්ත්වය යාවත්කාලීන කිරීම",
	"sub_annual" to "වාර්ෂික",
	"sub_annual_price" to Formattable { (price) -> "${price}/වසරකට" },
	"sub_backups" to "Cloud Backups",
	"sub_insights" to "සූක්ෂ්ම දෘෂ්ටියක්",
	"sub_month" to "මාසිකව",
	"sub_monthly_price" to Formattable { (price) -> "මසකට ${price}" },
	"sub_notifications" to "දැනුම්දීම්",
	"sub_popup_message" to "දායකත්වයන් ඉක්මනින් පැමිණේ. ටික වේලාවකින් නැවත මෙහි පරීක්ෂා කරන්න.",
	"sub_support" to "සහාය",
	"sub_templates" to "අච්චු",
	"subscribe" to "දායක වන්න",
	"subscription_header" to "ඔබේ ව්‍යාපාරය දියුණු කරන්න:",
	"subscription_option" to "දායකත්වය",
	"subscriptions_load_fail" to "සමාවන්න, අපට මෙම අවස්ථාවේදී ඔබගේ දායකත්වයන් ලබා ගැනීමට නොහැකි විය. කරුණාකර පසුව නැවත උත්සාහ කරන්න හෝ සහාය සඳහා අපගේ සහාය කණ්ඩායම අමතන්න.",
	"subtotal" to Formattable { (sum) -> "උප එකතුව: ${sum}" },
	"subtotal_label" to "උප එකතුව",
	"sum" to "එකතුව",
	"support_option" to "සහාය",
	"switch_reports" to "ස්විච් වාර්තාව",
	"tax" to "බද්ද",
	"tax_deleted_message" to "බදු මකා දමන ලදී",
	"tax_description_warning" to "භාවිතා කළහොත් මෙය ඉන්වොයිසියක පෙන්වයි",
	"tax_input_flat" to "පැතලි",
	"tax_input_percent" to "සියයට",
	"tax_number_fields" to "බදු අංකය",
	"tax_number_hint" to "බදු අංකය",
	"tax_query_info_description" to "විමසුම් තොරතුරු",
	"tax_report" to "බද්ද",
	"tax_rules_option" to "බදු නීති",
	"taxed_clients_report" to "බදු අය කළ ගනුදෙනුකරුවන්",
	"template" to "සැකිල්ල",
	"template_selector_info" to "පෙරනිමි අච්චුව වෙනස් කිරීමට ස්වයිප් කරන්න",
	"template_unlock_promo" to "Premium සමඟ තවත් අච්චු වෙත ප්‍රවේශ වන්න",
	"templates_option" to "සැකිලි",
	"terms_of_service" to "සේවා කොන්දේසි",
	"thank_you" to "ඔයාට ස්තූතියි",
	"to" to "දක්වා",
	"total" to Formattable { (sum) -> "එකතුව: ${sum}" },
	"total_label" to "සමස්ත",
	"undo_action" to "අහෝසි කරන්න",
	"unit" to "ඒකකය",
	"unit_cost_hint" to "ඒකක පිරිවැය",
	"unknown_client" to "නොදන්නා සේවාදායකයා",
	"unlock" to "අගුළු ඇරීම",
	"unnamed_item" to "නම් නොකළ අයිතමය",
	"upgrade" to "උත්ශ්‍රේණි කරන්න",
	"user_collision_error" to "ගිණුම දැනටමත් පවතී.",
	"valid_until" to "දක්වා වලංගු වේ",
	"warning" to "අවවාදයයි!",
	"yes" to "yes",
    )
    
}