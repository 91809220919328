// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class As  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "গ্ৰহণ কৰা ${termsOfService} আৰু ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "অবৈধ কোড।"
override val add_client_action: String = "ক্লায়েন্ট যোগ কৰক"
override val add_customer: String = "গ্ৰাহক যোগ কৰক"
override val add_expense: String = "ব্যয় যোগ কৰক"
override val add_fields_label: String = "যোগ কৰক:"
override val add_invoice_action: String = "সৃষ্টি কৰক"
override val add_item_action: String = "বস্তু যোগ কৰক"
override val add_item_button: String = "বস্তু যোগ কৰক"
override val add_signature_action: String = "স্বাক্ষৰ যোগ কৰক"
override val add_tax_action: String = "কৰ যোগ কৰক"
override val address_fields: String = "ঠিকনা"
override val address_line_hint: String = "ঠিকনা ৰেখা"
override val advanced: String = "উন্নত"
override val agree_marketing: String = "মই প্ৰত্যক্ষ বিপণন যোগাযোগ প্ৰাপ্ত কৰিবলৈ সন্মত"
override val all_notifications: String = "সকলো অধিসূচনা"
override val amortization: String = "amortization"
override val amount: String = "পৰিমাণ"
override val asset: String = "asset"
override val attach_file: String = "ফাইল সংলগ্ন কৰক"
override val attachment: String = "সংযুক্ত"
override val auth_error: String = "কাৰ্য্য বিফল হৈছে, পুনৰ চেষ্টা কৰক।"
override val available_for_paid: String = "ক্লাউড চিংক কেৱল পৰিশোধ কৰা সদস্যতাৰ অধীনত উপলব্ধ।"
override val backups_option: String = "বেকআপসমূহ"
override val balance: String = "balance"
override val bank_account: String = "বেংক একাউণ্ট"
override val bill_number: String = "বিল নম্বৰ"
override val cancel: String = "বাতিল কৰা"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "শ্ৰেণীসমূহ"
override val category: String = "শ্ৰেণী"
override val category_add: String = "শ্ৰেণী যোগ কৰক"
override val category_delete: String = "শ্ৰেণী বিলোপ কৰক"
override val category_name: String = "শ্ৰেণীৰ নাম"
override val change_language: Formattable = Formattable { (language) -> "ইনভইচ ভাষা: ${language}" }
override val change_language_label: String = "ইনভইচ ভাষা"
override val change_template: String = "টেমপ্লেট পৰিবৰ্তন কৰক"
override val city_hint: String = "নগৰ"
override val clear: String = "পৰিষ্কাৰ কৰক"
override val client: String = "গ্রাহক"
override val client_actions: String = "গ্ৰাহকৰ কাৰ্য্যসমূহ"
override val client_deleted_message: String = "ক্লায়েন্ট বিলোপ কৰা হৈছে"
override val clients_error_required_fields: String = "কমেও এটা মূল্যৰ প্ৰয়োজন।"
override val clients_option: String = "গ্ৰাহক"
override val close: String = "ওচৰ"
override val cloud_sync: String = "ক্লাউড চিংক"
override val coming_soon: String = "সোনকালে আহিব!"
override val company_logo: String = "কোম্পানীৰ লোগো"
override val company_name_hint: String = "কোম্পানী"
override val confirm_password_label: String = "পাছৱাৰ্ড নিশ্চিত কৰক"
override val confirmation_title: String = "আপুনি নিশ্চিতনে?"
override val convert_to_invoice: String = "ইনভইচলৈ ৰূপান্তৰ কৰক"
override val country_hint: String = "দেশ"
override val create_client: String = "ক্লায়েন্ট সৃষ্টি কৰক"
override val create_company: String = "কোম্পানী সৃষ্টি কৰক"
override val create_document: String = "দস্তাবেজ সৃষ্টি কৰক"
override val create_estimate: String = "অনুমান"
override val create_invoice: String = "ইনভইচ"
override val create_item: String = "বস্তু সৃষ্টি কৰক"
override val create_new_client: String = "নতুন ক্লায়েন্ট সৃষ্টি কৰক"
override val create_new_item: String = "নতুন আইটেম সৃষ্টি কৰক"
override val create_new_tax: String = "নতুন কৰ সৃষ্টি কৰক"
override val create_signature: String = "চহী"
override val created: String = "সৃষ্টি কৰা হৈছে"
override val credit: String = "credit"
override val customer: String = "গ্ৰাহক"
override val date: String = "তাৰিখ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "ডিফল্ট মুদ্ৰা: ${currency}" }
override val default_currency_label: String = "অবিকল্পিত মুদ্ৰা"
override val delete: String = "মচি পেলাওক"
override val delete_account: String = "একাওন্ট মচি পেলাওক"
override val delete_confirmation_message: String = "আপোনাৰ সকলো ডাটা হেৰাই যাব।"
override val delete_item_title: String = "আইটেমটো বিলোপ কৰিব নেকি?"
override val delete_signature: String = "স্বাক্ষৰ মচি পেলাওক"
override val depreciation: String = "depreciation"
override val description: String = "বিৱৰণ"
override val description_hint: String = "বিৱৰণ"
override val detailed_report: String = "বিতং প্ৰতিবেদন"
override val disabled: String = "অসামৰ্থবান"
override val discount: String = "ৰেহাই"
override val discount_hint: String = "ৰেহাই"
override val display_af: String = "আফ্ৰিকীয়"
override val display_am: String = "আমহাৰিক"
override val display_ar: String = "আৰবী"
override val display_as: String = "অসমীয়া"
override val display_az: String = "আজাৰবাইজানী"
override val display_be: String = "বেলাৰুচিয়ান"
override val display_bg: String = "বুলগেৰীয়"
override val display_bn: String = "বাংলা"
override val display_bs: String = "বোছনিয়ান"
override val display_ca: String = "ক্যাটালান"
override val display_cs: String = "চেক ভাষা"
override val display_da: String = "ডেনিশ্ব"
override val display_de: String = "জাৰ্মান"
override val display_el: String = "গ্ৰীক"
override val display_en: String = "ইংৰাজী"
override val display_es: String = "স্পেনিছ"
override val display_es_MX: String = "স্পেনিছ (মেক্সিকো)"
override val display_et: String = "এষ্ট'নিয়"
override val display_eu: String = "বাস্ক"
override val display_fa: String = "ফাৰ্ছি"
override val display_fi: String = "ফিনলেণ্ডৰ ভাষা"
override val display_fil: String = "ফিলিপাইনৰ ভাষা"
override val display_fr: String = "ফ্ৰেন্স"
override val display_fr_CA: String = "কানাডিয়ান ফৰাচী"
override val display_gl: String = "গে'লিচিয়ান"
override val display_gu: String = "গুজৰাটি"
override val display_he: String = "হিব্ৰু"
override val display_hi: String = "হিন্দী"
override val display_hr: String = "ক্ৰ'ৱেছিয়"
override val display_hu: String = "হাঙ্গেৰিৰ ভাষা"
override val display_hy: String = "আৰ্মেনিয়ান"
override val display_id: String = "ইণ্ডোনেছিয়ান"
override val display_is: String = "আইচলেণ্ডিক"
override val display_it: String = "ইটালীয়"
override val display_ja: String = "জাপানী"
override val display_ka: String = "জৰ্জিয়ান"
override val display_kk: String = "কাজাখ"
override val display_km: String = "কেন্দ্ৰীয় খেমাৰ"
override val display_kn: String = "কানাড়া"
override val display_ko: String = "কোৰিয়ান"
override val display_ky: String = "কিৰ্ঘিজ"
override val display_lo: String = "লাও"
override val display_lt: String = "লিথুৱানিয়ান"
override val display_lv: String = "লাটভিয়ান"
override val display_mk: String = "মেচিদনিয়ান"
override val display_ml: String = "মালয়ালম"
override val display_mn: String = "মঙ্গোলিয়ান"
override val display_mr: String = "মাৰাঠী"
override val display_ms: String = "মালয়"
override val display_my: String = "বাৰ্মিজ"
override val display_nb: String = "নৰৱেজিয়ান Bokmål"
override val display_ne: String = "নেপালী"
override val display_nl: String = "ডাচ্"
override val display_or: String = "ওড়িয়া"
override val display_pa: String = "পাঞ্জাবি"
override val display_pl: String = "প'লেণ্ডৰ ভাষা"
override val display_pt: String = "পৰ্তুগীজ"
override val display_pt_BR: String = "পৰ্তুগীজ (ব্ৰাজিল)"
override val display_pt_PT: String = "ইউৰোপীয় পৰ্তুগীজ"
override val display_ro: String = "ৰোমানীয়ান"
override val display_ru: String = "ৰুছ ভাষা"
override val display_si: String = "সিংহল"
override val display_sk: String = "শ্লোভাক"
override val display_sl: String = "শ্লোভেনিয়ান"
override val display_sq: String = "আল্বেনিয়"
override val display_sr: String = "ছাৰ্বিয়ান"
override val display_sv: String = "ছুইডিশ্ব"
override val display_sw: String = "স্বাহিলী"
override val display_ta: String = "তামিল"
override val display_te: String = "তেলেগু"
override val display_th: String = "থাই"
override val display_tr: String = "তুৰস্কৰ ভাষা"
override val display_uk: String = "ইউক্ৰেইনৰ ভাষা"
override val display_ur: String = "উৰ্দু"
override val display_uz: String = "উজবেক"
override val display_vi: String = "ভিয়েটনামীজ"
override val display_zh_Hant_TW: String = "চীনা (টাইৱান, পৰম্পৰাগত)"
override val display_zu: String = "জুলু"
override val document_estimate: String = "অনুমান"
override val document_invoice: String = "ইনভইচ"
override val document_quote: String = "অনুমান"
override val done: String = "সম্পন্ন কৰা হ'ল"
override val download_app: String = "এপ্পটো ডাউনলোড কৰক"
override val download_invoice: String = "ইনভইচ ডাউনলোড কৰক"
override val download_pdf: String = "পিডিএফ ডাউনলোড কৰক"
override val draw_signature: String = "স্বাক্ষৰ আঁকক"
override val due: Formattable = Formattable { (date) -> "প্ৰাপ্য ${date}" }
override val due_date: String = "প্ৰযোজ্য তাৰিখ"
override val due_day: String = "এটা দিনত"
override val due_label: String = "প্ৰাপ্য"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ${dueWhen}ৰ বাকী আছে!" }
override val due_today: String = "আজি"
override val due_week: String = "এসপ্তাহত"
override val duplicate: String = "ডুপ্লিকে’ট"
override val duration_day: String = "দিন"
override val duration_month: String = "মাহ"
override val duration_week: String = "সপ্তাহ"
override val duration_year: String = "বছৰ"
override val email: String = "ইমেইল"
override val email_blank_error: String = "ইমেইলৰ প্ৰয়োজন।"
override val email_error: String = "অবৈধ ইমেইল ঠিকনা।"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "আপোনাৰ ব্যৱসায়ৰ বাবে ধন্যবাদ। সম্পূৰ্ণ ${documentType} চাবলৈ এই আখৰৰ শেষত বুটামটো ক্লিক কৰক। মন কৰিব যে ই ${dueDate}লৈকে বৈধ।" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "আপুনি এটা নতুন ${documentType} ${invoiceNumber} লাভ কৰিছে!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "চাওক ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "মৰমৰ ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "আপোনাৰ ব্যৱসায়ৰ বাবে ধন্যবাদ। সম্পূৰ্ণ ${documentType} চাবলৈ এই আখৰৰ শেষত বুটামটো ক্লিক কৰক। অনুগ্ৰহ কৰি ${dueDate}লৈকে বেলেন্স পৰিশোধ কৰক।" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "আপুনি এটা নতুন ${documentType} ${invoiceNumber} লাভ কৰিছে!" }
override val email_reset_success_message: String = "প্ৰেৰণ কৰা পাছৱৰ্ড ৰিছেট কৰিবলৈ ইমেইল কৰক, অনুগ্ৰহ কৰি আপোনাৰ ইনবক্স পৰীক্ষা কৰক।"
override val empty_clients_list_message: String = "ট্ৰেক কৰিবলৈ আপোনাৰ কোনো গ্ৰাহক নাই। নতুন গ্ৰাহক এটা যোগ দিয়ক।"
override val empty_estimate_list_message: String = "আপোনাৰ প্ৰথম অনুমান সৃষ্টি কৰক"
override val empty_expenses: String = "ট্ৰেক কৰিবলৈ আপোনাৰ কোনো ব্যয় নাই। এটা নতুন ব্যয় যোগ দিয়ক।"
override val empty_invoice_list_message: String = "আপোনাৰ প্ৰথম ইনভইচ সৃষ্টি কৰক"
override val empty_items_list_message: String = "আপোনাৰ কোনো আইটেম নাই, এটা নতুন আইটেম যোগ দিয়ক!"
override val empty_signatures_list_message: String = "কোনো স্বাক্ষৰ পোৱা নগ'ল। এটা সৃষ্টি কৰক!"
override val empty_taxes_list_message: String = "আপোনাৰ কোনো কৰ নিয়ম নাই। এটা নতুন কৰ নিয়ম যোগ দিয়ক।"
override val empty_templates_list_message: String = "টেমপ্লেটসমূহ উপলব্ধ নহয়।"
override val enabled: String = "সামৰ্থবান"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "অনুমান #"
override val estimates: String = "অনুমান"
override val estimates_report: String = "অনুমান"
override val expense_bank_fees: String = "বেঙ্ক মাচুল"
override val expense_benefits: String = "লাভালাভসমূহ"
override val expense_communication: String = "যোগাযোগ"
override val expense_debt: String = "ঋণ"
override val expense_deleted: String = "ব্যয় বিলোপ কৰা হৈছে"
override val expense_depreciation: String = "মূল্যহ্ৰাস"
override val expense_gifts: String = "উপহাৰসমূহ"
override val expense_insurance: String = "বীমা"
override val expense_inventory: String = "ইনভেণ্টৰী"
override val expense_legal: String = "আইনী"
override val expense_maintenance: String = "তত্বাৱধান"
override val expense_marketing: String = "বিপণন"
override val expense_meals: String = "আহাৰ"
override val expense_office_supplies: String = "কাৰ্যালয়ৰ যোগান"
override val expense_rent: String = "ভাড়া"
override val expense_salaries: String = "দৰমহা"
override val expense_shopping: String = "বজাৰ কৰা"
override val expense_software: String = "ছফ্টৱেৰ"
override val expense_status_available: String = "উপলব্ধ"
override val expense_status_processing: String = "প্ৰক্ৰিয়াকৰণ"
override val expense_tax: String = "কৰ"
override val expense_training: String = "প্ৰশিক্ষণ"
override val expense_transport: String = "পৰিবহন"
override val expense_travel: String = "ভ্ৰমণ"
override val expense_utility: String = "উপযোগিতা"
override val expenses: String = "ব্যয়"
override val feedback_option: String = "প্ৰতিক্ৰিয়া"
override val filter: String = "ফিল্টাৰ"
override val first_name_hint: String = "প্ৰথম নাম"
override val forgot_password: String = "পাছৱাৰ্ড ৰিছেট কৰক"
override val forgot_password_label: String = "পাছৱাৰ্ড পাহৰিগ'ল"
override val from: String = "পৰা"
override val general_error: String = "ত্ৰুটি ঘটিছে, অনুগ্ৰহ কৰি পিছত পুনৰ চেষ্টা কৰক।"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "উভতি যোৱা"
override val group_by: String = "গ্ৰুপ বাই:"
override val image_view: String = "ছবি"
override val install: String = "ইনষ্টল কৰক"
override val invalid_credentials_error: String = "অবৈধ প্ৰমাণপত্ৰ।"
override val invalid_email_error: String = "অবৈধ ইমেইল।"
override val invalid_user_error: String = "অবৈধ ব্যৱহাৰকাৰীৰ পৰিচয়।"
override val invoice_deleted_message: String = "ইনভইচ বিলোপ কৰা হৈছে"
override val invoice_menu: String = "ইনভইচ মেনু"
override val invoice_number: String = "ইনভইচ #"
override val invoices: String = "ইনভইচসমূহ"
override val items_option: String = "বস্তুসমূহ"
override val keep: String = "ৰাখক"
override val language_selector: Formattable = Formattable { (selected) -> "ভাষা ${selected}" }
override val last_name_hint: String = "অন্তিম নাম"
override val ledger: String = "ledger"
override val legal: String = "আইনী"
override val legal_error: String = "সেৱাৰ চৰ্তাৱলী আৰু গোপনীয়তা নীতি গ্ৰহণ কৰিব লাগিব"
override val liabilities: String = "liabilities"
override val loading: String = "লোড কৰা হৈছে"
override val login_required_error: String = "লগইন ৰ প্ৰয়োজন।"
override val logo_view: String = "লোগো"
override val logout: String = "লগআউট"
override val logout_prompt: String = "আপোনাৰ ডাটা ক্লাউডৰ সৈতে সংমিহলি নহয়। লগআউটৰ ওপৰত ইনভইচ, অনুমান, গ্ৰাহক আৰু অন্যান্য তথ্য বিলোপ কৰিব নেকি?"
override val main_screen: String = "প্ৰধান পৰ্দা"
override val mark_paid: String = "পৰিশোধ কৰা হিচাপে চিহ্নিত কৰা হ'ব"
override val message: String = "খবৰ"
override val missing_sender_msg: String = "প্ৰেৰকতথ্য অবিহনে এখন দস্তাবেজ অবৈধ হ'ব পাৰে।"
override val missing_sender_title: String = "প্ৰেৰকৰ তথ্য নাই"
override val month_april: String = "এপ্ৰিল"
override val month_april_short: String = "এপ্ৰিল"
override val month_august: String = "আগষ্ট"
override val month_august_short: String = "আগ"
override val month_december: String = "ডিচেম্বৰ"
override val month_december_short: String = "ডিচে"
override val month_february: String = "ফেব্ৰুৱাৰী"
override val month_february_short: String = "ফেব্ৰু"
override val month_january: String = "জানুৱাৰী"
override val month_january_short: String = "জানু"
override val month_july: String = "জুলাই"
override val month_july_short: String = "জুলাই"
override val month_june: String = "জুন"
override val month_june_short: String = "জুন"
override val month_march: String = "মাৰ্চ"
override val month_march_short: String = "মাৰ্চ"
override val month_may: String = "মে"
override val month_may_short: String = "মে"
override val month_november: String = "নৱেম্বৰ"
override val month_november_short: String = "নৱে"
override val month_october: String = "অক্টোবৰ"
override val month_october_short: String = "অক্টো"
override val month_september: String = "ছেপ্টেম্বৰ"
override val month_september_short: String = "সাত।"
override val multifactor_error: String = "সত্যাপন কৰিবলৈ ব্যৰ্থ।"
override val new: String = "নতুন"
override val news: String = "বাতৰি"
override val next: String = "পৰৱৰ্তী"
override val no: String = "no"
override val no_data_available: String = "কোনো তথ্য উপলব্ধ নাই"
override val no_search_results: String = "দুঃখিত, আমি কোনো ফলাফল বিচাৰি পোৱা নাছিলোঁ।"
override val none: String = "একো নহয়"
override val note_fields: String = "টোকাসমূহ"
override val note_hint: String = "টোকা"
override val notifications_option: String = "অধিসূচনাসমূহ"
override val ok: String = "হ'ব"
override val onboarding: String = "অনবৰ্ডিং"
override val onboarding_aboutus_message: String = "ডিংভইচৰ অত্যাৱশ্যকীয় অংশবোৰ চিৰদিনৰ বাবে বিনামূলীয়া। আমাৰ লক্ষ্য হৈছে আপোনাক আপোনাৰ ব্যৱসায় বৃদ্ধি কৰাত সহায় কৰা, আপোনাৰ মতামত ভাগ বতৰা কৰি আমাক উন্নত কৰাত সহায় কৰা।"
override val onboarding_aboutus_title: String = "আমি ইয়াত সহায় কৰিবলৈ আহিছোঁ"
override val onboarding_paid_1: String = "পেছাদাৰী দেখা টেমপ্লেটসমূহত প্ৰৱেশ কৰক"
override val onboarding_paid_2: String = "আপোনাৰ বিত্তীয় বিষয়ে গভীৰভাৱে চাওঁক"
override val onboarding_paid_3: String = "সমস্যাবোৰ দ্ৰুততাৰে সমাধান কৰক"
override val onboarding_paid_4: String = "আপোনাৰ নথিপত্ৰসুৰক্ষিত ৰাখিব"
override val onboarding_paid_5: String = "ইনভইচ স্থিতি পৰিবৰ্তনৰ সৈতে আপ টু ডেট থাকক"
override val onboarding_paid_title: String = "ইয়াৰ সৈতে আৰু দ্ৰুতগতিত বৃদ্ধি হ'ব..."
override val onboarding_paywall_message: String = "এতিয়া সকলো প্ৰিমিয়াম সুবিধা সৰ্বশ্ৰেষ্ঠ মূল্যত উপভোগ কৰক।"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "মাত্ৰ ${currencySymbol}${introAmount} ৰ বাবে ${introDuration} ${introPeriod} আৰু তাৰ পিছত ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "মাত্ৰ ${currencySymbol} ${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} আৰু তাৰ পিছত মাত্ৰ ${currencySymbol} ${amount} / ${duration} ৰ বাবে বিনামূলীয়া" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} ৰ বাবে বিনামূলীয়া, তাৰ পিছত ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod} আৰু তাৰ পিছত মাত্ৰ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "স্বয়ং-নৱীকৰণযোগ্য। যিকোনো সময়তে বাতিল কৰক।"
override val onboarding_paywall_restore: String = "ক্ৰয় পুনৰুদ্ধাৰ কৰক"
override val onboarding_paywall_restore_failure: String = "ক্ৰয় পুনৰুদ্ধাৰ কৰাবিফল হৈছে, পিছত পুনৰ চেষ্টা কৰক বা যোগাযোগ সমৰ্থন কৰক।"
override val onboarding_paywall_restore_success: String = "সফল পুনৰুদ্ধাৰ কৰক!"
override val onboarding_paywall_title: String = "মহানতা প্ৰাপ্ত কৰক!"
override val onboarding_welcome_message: String = "আপোনাৰ ইনভইচ আৰু আকলন বোৰ নিয়ন্ত্ৰণ কৰিবলৈ এটা সম্পূৰ্ণ টুলছেট।"
override val onboarding_welcome_title: String = "ডিংভইচলৈ স্বাগতম"
override val other_state: String = "অন্যান্য"
override val overdue_state: String = "অতিৰিক্ত"
override val paid_state: String = "পৰিশোধ কৰা হৈছে"
override val password: String = "পাছৱাৰ্ড"
override val password_blank_error: String = "পাছৱৰ্ড ৰ প্ৰয়োজন।"
override val password_mismatch_error: String = "পাছৱৰ্ডসমূহ মিলা নাই।"
override val pay: String = "pay"
override val payment_instructions_label: String = "পৰিশোধৰ নিৰ্দেশনা"
override val payment_reminders: String = "পৰিশোধ স্মাৰকসমূহ"
override val phone_number_fields: String = "ফোন নম্বৰ"
override val phone_number_hint: String = "ফোন নম্বৰ"
override val postcode_hint: String = "পোষ্টকোড"
override val preview: String = "পূৰ্বদৰ্শন"
override val preview_menu_hint: String = "পূৰ্বদৰ্শন মেনু"
override val price: String = "দাম"
override val privacy_policy: String = "গোপনীয়তা নীতি"
override val profile: String = "প্ৰ'ফাইল"
override val profitable_client: String = "আপোনাৰ আটাইতকৈ লাভজনক গ্ৰাহক হৈছে..."
override val profits_report: String = "লাভ"
override val promo_content: String = "প্ৰচাৰমূলক সমল"
override val quantity_hint: String = "পৰিমাণ"
override val quantity_label_hint: String = "পৰিমাণৰ লেবেল"
override val query_info: String = "প্ৰশ্নই আপোনাক ইনপুট হিচাপে আইটেম আৰু অন্যান্য কৰ নিয়ম দুয়োটা উল্লেখ কৰি কাষ্টম গণনা লিখিবলৈ অনুমতি দিয়ে। আন এটা প্ৰবিষ্টি +, *, *, /, % - গণিত অপাৰেটৰৰ সৈতে লিংক কৰিবলৈ @ চিহ্ন আৰু আইটেম বা কৰ বিৱৰণ যোগ দিয়ক"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} আপোনাৰ গ্ৰাহকে প্ৰাপ্ত কৰিছিল।" }
override val received_state: String = "প্ৰাপ্ত হৈছে"
override val registration_code_fields: String = "পঞ্জীয়ন কোড"
override val reminder: String = "স্মাৰক"
override val reports: String = "প্ৰতিবেদনসমূহ"
override val reports_not_available: String = "কোনো প্ৰতিবেদন উপলব্ধ নহয়, পিছত ঘূৰি আহক।"
override val reveal_password: String = "পাছৱাৰ্ড প্ৰকাশ কৰক"
override val revenue: String = "revenue"
override val sales_tax: String = "ভেট"
override val sales_tax_number: String = "ভেট নম্বৰ"
override val save: String = "সংৰক্ষণ কৰক"
override val scan_document: String = "দস্তাবেজ স্কেন কৰক"
override val search: String = "অনুসন্ধান"
override val select: String = "নিৰ্বাচন কৰক"
override val select_icon: String = "আইকন নিৰ্বাচন কৰক"
override val select_signature: String = "স্বাক্ষৰ নিৰ্বাচন কৰক"
override val select_template: String = "টেমপ্লেট বাছক"
override val send: String = "প্ৰেৰণ কৰক"
override val sent_state: String = "প্ৰেৰণ কৰা হৈছে"
override val setup_sender: String = "প্ৰেৰক সংস্থাপন কৰক"
override val share_option: String = "ভাগ বতৰা কৰক"
override val ship_to: String = "জাহাজলৈ"
override val sign_in: String = "ছাইন ইন"
override val sign_in_apple: String = "এপলৰ সৈতে ছাইন ইন কৰক"
override val sign_in_email: String = "ইমেইলৰ সৈতে ছাইন ইন কৰক"
override val sign_in_facebook: String = "ফেচবুকৰ সৈতে ছাইন ইন কৰক"
override val sign_in_google: String = "গুগলৰ সৈতে ছাইন ইন কৰক"
override val sign_up: String = "ছাই আপ"
override val sign_up_label: String = "একাউণ্ট নাই নেকি?"
override val sign_up_link_label: String = "ছাই আপ!"
override val signatures_option: String = "স্বাক্ষৰসমূহ"
override val skip: String = "এৰি যাওক"
override val sold_items_report: String = "বিক্ৰী কৰা সামগ্ৰীসমূহ"
override val sort_by: String = "ক্ৰম অনুসৰি:"
override val status_update: String = "দস্তাবেজৰ স্থিতি আপডেট"
override val sub_annual: String = "বাৰ্ষিক"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/বছৰ" }
override val sub_backups: String = "ক্লাউড বেকআপ"
override val sub_insights: String = "অন্তৰ্দৃষ্টি"
override val sub_month: String = "মাহিলি"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/মাহে" }
override val sub_notifications: String = "অধিসূচনাসমূহ"
override val sub_popup_message: String = "সদস্যতা সোনকালে আহি আছে। অলপ সময়ৰ ভিতৰতে ইয়াত পৰীক্ষা কৰক।"
override val sub_support: String = "সমৰ্থন"
override val sub_templates: String = "টেমপ্লেটসমূহ"
override val subscribe: String = "সাবস্ক্ৰাইব কৰক"
override val subscription_header: String = "আপোনাৰ ব্যৱসায় ইয়াৰ সৈতে বৃদ্ধি কৰক:"
override val subscription_option: String = "সদস্যতা"
override val subscriptions_load_fail: String = "দুঃখিত, আমি এই সময়ত আপোনাৰ সদস্যতা পুনৰুদ্ধাৰ কৰিবলৈ অক্ষম। অনুগ্ৰহ কৰি পিছত পুনৰ চেষ্টা কৰক বা সহায়ৰ বাবে আমাৰ সমৰ্থন দলৰ সৈতে যোগাযোগ কৰক।"
override val subtotal: Formattable = Formattable { (sum) -> "উপমুঠ: ${sum}" }
override val subtotal_label: String = "উপমুঠ"
override val sum: String = "যোগফল"
override val support_option: String = "সমৰ্থন"
override val switch_reports: String = "প্ৰতিবেদন সলনি কৰক"
override val tax: String = "কৰ"
override val tax_deleted_message: String = "কৰ বিলোপ কৰা হৈছে"
override val tax_description_warning: String = "যদি ব্যৱহাৰ কৰা হয় এইটো এটা ইনভইচত দেখা যায়"
override val tax_input_flat: String = "সমতল"
override val tax_input_percent: String = "শতাংশ"
override val tax_number_fields: String = "কৰ নম্বৰ"
override val tax_number_hint: String = "কৰ নম্বৰ"
override val tax_query_info_description: String = "প্ৰশ্ন তথ্য"
override val tax_report: String = "কৰ"
override val tax_rules_option: String = "কৰ নিয়ম"
override val taxed_clients_report: String = "কৰ যুক্ত গ্ৰাহক"
override val template: String = "টেমপ্লেট"
override val template_selector_info: String = "ডিফল্ট টেমপ্লেট সলনি কৰিবলৈ চোৱাইপ কৰক"
override val template_unlock_promo: String = "প্ৰিমিয়ামৰ সৈতে অধিক টেমপ্লেট ত প্ৰৱেশ কৰক"
override val templates_option: String = "টেমপ্লেটসমূহ"
override val terms_of_service: String = "সেৱাৰ চৰ্তাৱলী"
override val thank_you: String = "ধন্যবাদ"
override val to: String = "লৈ"
override val total: Formattable = Formattable { (sum) -> "মুঠ: ${sum}" }
override val total_label: String = "মুঠ"
override val undo_action: String = "বাতিল কৰক"
override val unit: String = "একক"
override val unit_cost_hint: String = "একক ব্যয়"
override val unknown_client: String = "অজ্ঞাত গ্ৰাহক"
override val unlock: String = "আনলক কৰক"
override val unnamed_item: String = "নামহীন বস্তু"
override val upgrade: String = "আপগ্ৰেড কৰক"
override val user_collision_error: String = "একাউণ্ট ইতিমধ্যে বিদ্যমান।"
override val valid_until: String = "বৈধ"
override val warning: String = "সতৰ্কবাৰ্তা!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "গ্ৰহণ কৰা ${termsOfService} আৰু ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "অবৈধ কোড।",
	"add_client_action" to "ক্লায়েন্ট যোগ কৰক",
	"add_customer" to "গ্ৰাহক যোগ কৰক",
	"add_expense" to "ব্যয় যোগ কৰক",
	"add_fields_label" to "যোগ কৰক:",
	"add_invoice_action" to "সৃষ্টি কৰক",
	"add_item_action" to "বস্তু যোগ কৰক",
	"add_item_button" to "বস্তু যোগ কৰক",
	"add_signature_action" to "স্বাক্ষৰ যোগ কৰক",
	"add_tax_action" to "কৰ যোগ কৰক",
	"address_fields" to "ঠিকনা",
	"address_line_hint" to "ঠিকনা ৰেখা",
	"advanced" to "উন্নত",
	"agree_marketing" to "মই প্ৰত্যক্ষ বিপণন যোগাযোগ প্ৰাপ্ত কৰিবলৈ সন্মত",
	"all_notifications" to "সকলো অধিসূচনা",
	"amortization" to "amortization",
	"amount" to "পৰিমাণ",
	"asset" to "asset",
	"attach_file" to "ফাইল সংলগ্ন কৰক",
	"attachment" to "সংযুক্ত",
	"auth_error" to "কাৰ্য্য বিফল হৈছে, পুনৰ চেষ্টা কৰক।",
	"available_for_paid" to "ক্লাউড চিংক কেৱল পৰিশোধ কৰা সদস্যতাৰ অধীনত উপলব্ধ।",
	"backups_option" to "বেকআপসমূহ",
	"balance" to "balance",
	"bank_account" to "বেংক একাউণ্ট",
	"bill_number" to "বিল নম্বৰ",
	"cancel" to "বাতিল কৰা",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "শ্ৰেণীসমূহ",
	"category" to "শ্ৰেণী",
	"category_add" to "শ্ৰেণী যোগ কৰক",
	"category_delete" to "শ্ৰেণী বিলোপ কৰক",
	"category_name" to "শ্ৰেণীৰ নাম",
	"change_language" to Formattable { (language) -> "ইনভইচ ভাষা: ${language}" },
	"change_language_label" to "ইনভইচ ভাষা",
	"change_template" to "টেমপ্লেট পৰিবৰ্তন কৰক",
	"city_hint" to "নগৰ",
	"clear" to "পৰিষ্কাৰ কৰক",
	"client" to "গ্রাহক",
	"client_actions" to "গ্ৰাহকৰ কাৰ্য্যসমূহ",
	"client_deleted_message" to "ক্লায়েন্ট বিলোপ কৰা হৈছে",
	"clients_error_required_fields" to "কমেও এটা মূল্যৰ প্ৰয়োজন।",
	"clients_option" to "গ্ৰাহক",
	"close" to "ওচৰ",
	"cloud_sync" to "ক্লাউড চিংক",
	"coming_soon" to "সোনকালে আহিব!",
	"company_logo" to "কোম্পানীৰ লোগো",
	"company_name_hint" to "কোম্পানী",
	"confirm_password_label" to "পাছৱাৰ্ড নিশ্চিত কৰক",
	"confirmation_title" to "আপুনি নিশ্চিতনে?",
	"convert_to_invoice" to "ইনভইচলৈ ৰূপান্তৰ কৰক",
	"country_hint" to "দেশ",
	"create_client" to "ক্লায়েন্ট সৃষ্টি কৰক",
	"create_company" to "কোম্পানী সৃষ্টি কৰক",
	"create_document" to "দস্তাবেজ সৃষ্টি কৰক",
	"create_estimate" to "অনুমান",
	"create_invoice" to "ইনভইচ",
	"create_item" to "বস্তু সৃষ্টি কৰক",
	"create_new_client" to "নতুন ক্লায়েন্ট সৃষ্টি কৰক",
	"create_new_item" to "নতুন আইটেম সৃষ্টি কৰক",
	"create_new_tax" to "নতুন কৰ সৃষ্টি কৰক",
	"create_signature" to "চহী",
	"created" to "সৃষ্টি কৰা হৈছে",
	"credit" to "credit",
	"customer" to "গ্ৰাহক",
	"date" to "তাৰিখ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "ডিফল্ট মুদ্ৰা: ${currency}" },
	"default_currency_label" to "অবিকল্পিত মুদ্ৰা",
	"delete" to "মচি পেলাওক",
	"delete_account" to "একাওন্ট মচি পেলাওক",
	"delete_confirmation_message" to "আপোনাৰ সকলো ডাটা হেৰাই যাব।",
	"delete_item_title" to "আইটেমটো বিলোপ কৰিব নেকি?",
	"delete_signature" to "স্বাক্ষৰ মচি পেলাওক",
	"depreciation" to "depreciation",
	"description" to "বিৱৰণ",
	"description_hint" to "বিৱৰণ",
	"detailed_report" to "বিতং প্ৰতিবেদন",
	"disabled" to "অসামৰ্থবান",
	"discount" to "ৰেহাই",
	"discount_hint" to "ৰেহাই",
	"display_af" to "আফ্ৰিকীয়",
	"display_am" to "আমহাৰিক",
	"display_ar" to "আৰবী",
	"display_as" to "অসমীয়া",
	"display_az" to "আজাৰবাইজানী",
	"display_be" to "বেলাৰুচিয়ান",
	"display_bg" to "বুলগেৰীয়",
	"display_bn" to "বাংলা",
	"display_bs" to "বোছনিয়ান",
	"display_ca" to "ক্যাটালান",
	"display_cs" to "চেক ভাষা",
	"display_da" to "ডেনিশ্ব",
	"display_de" to "জাৰ্মান",
	"display_el" to "গ্ৰীক",
	"display_en" to "ইংৰাজী",
	"display_es" to "স্পেনিছ",
	"display_es_MX" to "স্পেনিছ (মেক্সিকো)",
	"display_et" to "এষ্ট'নিয়",
	"display_eu" to "বাস্ক",
	"display_fa" to "ফাৰ্ছি",
	"display_fi" to "ফিনলেণ্ডৰ ভাষা",
	"display_fil" to "ফিলিপাইনৰ ভাষা",
	"display_fr" to "ফ্ৰেন্স",
	"display_fr_CA" to "কানাডিয়ান ফৰাচী",
	"display_gl" to "গে'লিচিয়ান",
	"display_gu" to "গুজৰাটি",
	"display_he" to "হিব্ৰু",
	"display_hi" to "হিন্দী",
	"display_hr" to "ক্ৰ'ৱেছিয়",
	"display_hu" to "হাঙ্গেৰিৰ ভাষা",
	"display_hy" to "আৰ্মেনিয়ান",
	"display_id" to "ইণ্ডোনেছিয়ান",
	"display_is" to "আইচলেণ্ডিক",
	"display_it" to "ইটালীয়",
	"display_ja" to "জাপানী",
	"display_ka" to "জৰ্জিয়ান",
	"display_kk" to "কাজাখ",
	"display_km" to "কেন্দ্ৰীয় খেমাৰ",
	"display_kn" to "কানাড়া",
	"display_ko" to "কোৰিয়ান",
	"display_ky" to "কিৰ্ঘিজ",
	"display_lo" to "লাও",
	"display_lt" to "লিথুৱানিয়ান",
	"display_lv" to "লাটভিয়ান",
	"display_mk" to "মেচিদনিয়ান",
	"display_ml" to "মালয়ালম",
	"display_mn" to "মঙ্গোলিয়ান",
	"display_mr" to "মাৰাঠী",
	"display_ms" to "মালয়",
	"display_my" to "বাৰ্মিজ",
	"display_nb" to "নৰৱেজিয়ান Bokmål",
	"display_ne" to "নেপালী",
	"display_nl" to "ডাচ্",
	"display_or" to "ওড়িয়া",
	"display_pa" to "পাঞ্জাবি",
	"display_pl" to "প'লেণ্ডৰ ভাষা",
	"display_pt" to "পৰ্তুগীজ",
	"display_pt_BR" to "পৰ্তুগীজ (ব্ৰাজিল)",
	"display_pt_PT" to "ইউৰোপীয় পৰ্তুগীজ",
	"display_ro" to "ৰোমানীয়ান",
	"display_ru" to "ৰুছ ভাষা",
	"display_si" to "সিংহল",
	"display_sk" to "শ্লোভাক",
	"display_sl" to "শ্লোভেনিয়ান",
	"display_sq" to "আল্বেনিয়",
	"display_sr" to "ছাৰ্বিয়ান",
	"display_sv" to "ছুইডিশ্ব",
	"display_sw" to "স্বাহিলী",
	"display_ta" to "তামিল",
	"display_te" to "তেলেগু",
	"display_th" to "থাই",
	"display_tr" to "তুৰস্কৰ ভাষা",
	"display_uk" to "ইউক্ৰেইনৰ ভাষা",
	"display_ur" to "উৰ্দু",
	"display_uz" to "উজবেক",
	"display_vi" to "ভিয়েটনামীজ",
	"display_zh_Hant_TW" to "চীনা (টাইৱান, পৰম্পৰাগত)",
	"display_zu" to "জুলু",
	"document_estimate" to "অনুমান",
	"document_invoice" to "ইনভইচ",
	"document_quote" to "অনুমান",
	"done" to "সম্পন্ন কৰা হ'ল",
	"download_app" to "এপ্পটো ডাউনলোড কৰক",
	"download_invoice" to "ইনভইচ ডাউনলোড কৰক",
	"download_pdf" to "পিডিএফ ডাউনলোড কৰক",
	"draw_signature" to "স্বাক্ষৰ আঁকক",
	"due" to Formattable { (date) -> "প্ৰাপ্য ${date}" },
	"due_date" to "প্ৰযোজ্য তাৰিখ",
	"due_day" to "এটা দিনত",
	"due_label" to "প্ৰাপ্য",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ${dueWhen}ৰ বাকী আছে!" },
	"due_today" to "আজি",
	"due_week" to "এসপ্তাহত",
	"duplicate" to "ডুপ্লিকে’ট",
	"duration_day" to "দিন",
	"duration_month" to "মাহ",
	"duration_week" to "সপ্তাহ",
	"duration_year" to "বছৰ",
	"email" to "ইমেইল",
	"email_blank_error" to "ইমেইলৰ প্ৰয়োজন।",
	"email_error" to "অবৈধ ইমেইল ঠিকনা।",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "আপোনাৰ ব্যৱসায়ৰ বাবে ধন্যবাদ। সম্পূৰ্ণ ${documentType} চাবলৈ এই আখৰৰ শেষত বুটামটো ক্লিক কৰক। মন কৰিব যে ই ${dueDate}লৈকে বৈধ।" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "আপুনি এটা নতুন ${documentType} ${invoiceNumber} লাভ কৰিছে!" },
	"email_invoice_cta" to Formattable { (documentType) -> "চাওক ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "মৰমৰ ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "আপোনাৰ ব্যৱসায়ৰ বাবে ধন্যবাদ। সম্পূৰ্ণ ${documentType} চাবলৈ এই আখৰৰ শেষত বুটামটো ক্লিক কৰক। অনুগ্ৰহ কৰি ${dueDate}লৈকে বেলেন্স পৰিশোধ কৰক।" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "আপুনি এটা নতুন ${documentType} ${invoiceNumber} লাভ কৰিছে!" },
	"email_reset_success_message" to "প্ৰেৰণ কৰা পাছৱৰ্ড ৰিছেট কৰিবলৈ ইমেইল কৰক, অনুগ্ৰহ কৰি আপোনাৰ ইনবক্স পৰীক্ষা কৰক।",
	"empty_clients_list_message" to "ট্ৰেক কৰিবলৈ আপোনাৰ কোনো গ্ৰাহক নাই। নতুন গ্ৰাহক এটা যোগ দিয়ক।",
	"empty_estimate_list_message" to "আপোনাৰ প্ৰথম অনুমান সৃষ্টি কৰক",
	"empty_expenses" to "ট্ৰেক কৰিবলৈ আপোনাৰ কোনো ব্যয় নাই। এটা নতুন ব্যয় যোগ দিয়ক।",
	"empty_invoice_list_message" to "আপোনাৰ প্ৰথম ইনভইচ সৃষ্টি কৰক",
	"empty_items_list_message" to "আপোনাৰ কোনো আইটেম নাই, এটা নতুন আইটেম যোগ দিয়ক!",
	"empty_signatures_list_message" to "কোনো স্বাক্ষৰ পোৱা নগ'ল। এটা সৃষ্টি কৰক!",
	"empty_taxes_list_message" to "আপোনাৰ কোনো কৰ নিয়ম নাই। এটা নতুন কৰ নিয়ম যোগ দিয়ক।",
	"empty_templates_list_message" to "টেমপ্লেটসমূহ উপলব্ধ নহয়।",
	"enabled" to "সামৰ্থবান",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "অনুমান #",
	"estimates" to "অনুমান",
	"estimates_report" to "অনুমান",
	"expense_bank_fees" to "বেঙ্ক মাচুল",
	"expense_benefits" to "লাভালাভসমূহ",
	"expense_communication" to "যোগাযোগ",
	"expense_debt" to "ঋণ",
	"expense_deleted" to "ব্যয় বিলোপ কৰা হৈছে",
	"expense_depreciation" to "মূল্যহ্ৰাস",
	"expense_gifts" to "উপহাৰসমূহ",
	"expense_insurance" to "বীমা",
	"expense_inventory" to "ইনভেণ্টৰী",
	"expense_legal" to "আইনী",
	"expense_maintenance" to "তত্বাৱধান",
	"expense_marketing" to "বিপণন",
	"expense_meals" to "আহাৰ",
	"expense_office_supplies" to "কাৰ্যালয়ৰ যোগান",
	"expense_rent" to "ভাড়া",
	"expense_salaries" to "দৰমহা",
	"expense_shopping" to "বজাৰ কৰা",
	"expense_software" to "ছফ্টৱেৰ",
	"expense_status_available" to "উপলব্ধ",
	"expense_status_processing" to "প্ৰক্ৰিয়াকৰণ",
	"expense_tax" to "কৰ",
	"expense_training" to "প্ৰশিক্ষণ",
	"expense_transport" to "পৰিবহন",
	"expense_travel" to "ভ্ৰমণ",
	"expense_utility" to "উপযোগিতা",
	"expenses" to "ব্যয়",
	"feedback_option" to "প্ৰতিক্ৰিয়া",
	"filter" to "ফিল্টাৰ",
	"first_name_hint" to "প্ৰথম নাম",
	"forgot_password" to "পাছৱাৰ্ড ৰিছেট কৰক",
	"forgot_password_label" to "পাছৱাৰ্ড পাহৰিগ'ল",
	"from" to "পৰা",
	"general_error" to "ত্ৰুটি ঘটিছে, অনুগ্ৰহ কৰি পিছত পুনৰ চেষ্টা কৰক।",
	"general_ledger" to "general ledger",
	"go_back_label" to "উভতি যোৱা",
	"group_by" to "গ্ৰুপ বাই:",
	"image_view" to "ছবি",
	"install" to "ইনষ্টল কৰক",
	"invalid_credentials_error" to "অবৈধ প্ৰমাণপত্ৰ।",
	"invalid_email_error" to "অবৈধ ইমেইল।",
	"invalid_user_error" to "অবৈধ ব্যৱহাৰকাৰীৰ পৰিচয়।",
	"invoice_deleted_message" to "ইনভইচ বিলোপ কৰা হৈছে",
	"invoice_menu" to "ইনভইচ মেনু",
	"invoice_number" to "ইনভইচ #",
	"invoices" to "ইনভইচসমূহ",
	"items_option" to "বস্তুসমূহ",
	"keep" to "ৰাখক",
	"language_selector" to Formattable { (selected) -> "ভাষা ${selected}" },
	"last_name_hint" to "অন্তিম নাম",
	"ledger" to "ledger",
	"legal" to "আইনী",
	"legal_error" to "সেৱাৰ চৰ্তাৱলী আৰু গোপনীয়তা নীতি গ্ৰহণ কৰিব লাগিব",
	"liabilities" to "liabilities",
	"loading" to "লোড কৰা হৈছে",
	"login_required_error" to "লগইন ৰ প্ৰয়োজন।",
	"logo_view" to "লোগো",
	"logout" to "লগআউট",
	"logout_prompt" to "আপোনাৰ ডাটা ক্লাউডৰ সৈতে সংমিহলি নহয়। লগআউটৰ ওপৰত ইনভইচ, অনুমান, গ্ৰাহক আৰু অন্যান্য তথ্য বিলোপ কৰিব নেকি?",
	"main_screen" to "প্ৰধান পৰ্দা",
	"mark_paid" to "পৰিশোধ কৰা হিচাপে চিহ্নিত কৰা হ'ব",
	"message" to "খবৰ",
	"missing_sender_msg" to "প্ৰেৰকতথ্য অবিহনে এখন দস্তাবেজ অবৈধ হ'ব পাৰে।",
	"missing_sender_title" to "প্ৰেৰকৰ তথ্য নাই",
	"month_april" to "এপ্ৰিল",
	"month_april_short" to "এপ্ৰিল",
	"month_august" to "আগষ্ট",
	"month_august_short" to "আগ",
	"month_december" to "ডিচেম্বৰ",
	"month_december_short" to "ডিচে",
	"month_february" to "ফেব্ৰুৱাৰী",
	"month_february_short" to "ফেব্ৰু",
	"month_january" to "জানুৱাৰী",
	"month_january_short" to "জানু",
	"month_july" to "জুলাই",
	"month_july_short" to "জুলাই",
	"month_june" to "জুন",
	"month_june_short" to "জুন",
	"month_march" to "মাৰ্চ",
	"month_march_short" to "মাৰ্চ",
	"month_may" to "মে",
	"month_may_short" to "মে",
	"month_november" to "নৱেম্বৰ",
	"month_november_short" to "নৱে",
	"month_october" to "অক্টোবৰ",
	"month_october_short" to "অক্টো",
	"month_september" to "ছেপ্টেম্বৰ",
	"month_september_short" to "সাত।",
	"multifactor_error" to "সত্যাপন কৰিবলৈ ব্যৰ্থ।",
	"new" to "নতুন",
	"news" to "বাতৰি",
	"next" to "পৰৱৰ্তী",
	"no" to "no",
	"no_data_available" to "কোনো তথ্য উপলব্ধ নাই",
	"no_search_results" to "দুঃখিত, আমি কোনো ফলাফল বিচাৰি পোৱা নাছিলোঁ।",
	"none" to "একো নহয়",
	"note_fields" to "টোকাসমূহ",
	"note_hint" to "টোকা",
	"notifications_option" to "অধিসূচনাসমূহ",
	"ok" to "হ'ব",
	"onboarding" to "অনবৰ্ডিং",
	"onboarding_aboutus_message" to "ডিংভইচৰ অত্যাৱশ্যকীয় অংশবোৰ চিৰদিনৰ বাবে বিনামূলীয়া। আমাৰ লক্ষ্য হৈছে আপোনাক আপোনাৰ ব্যৱসায় বৃদ্ধি কৰাত সহায় কৰা, আপোনাৰ মতামত ভাগ বতৰা কৰি আমাক উন্নত কৰাত সহায় কৰা।",
	"onboarding_aboutus_title" to "আমি ইয়াত সহায় কৰিবলৈ আহিছোঁ",
	"onboarding_paid_1" to "পেছাদাৰী দেখা টেমপ্লেটসমূহত প্ৰৱেশ কৰক",
	"onboarding_paid_2" to "আপোনাৰ বিত্তীয় বিষয়ে গভীৰভাৱে চাওঁক",
	"onboarding_paid_3" to "সমস্যাবোৰ দ্ৰুততাৰে সমাধান কৰক",
	"onboarding_paid_4" to "আপোনাৰ নথিপত্ৰসুৰক্ষিত ৰাখিব",
	"onboarding_paid_5" to "ইনভইচ স্থিতি পৰিবৰ্তনৰ সৈতে আপ টু ডেট থাকক",
	"onboarding_paid_title" to "ইয়াৰ সৈতে আৰু দ্ৰুতগতিত বৃদ্ধি হ'ব...",
	"onboarding_paywall_message" to "এতিয়া সকলো প্ৰিমিয়াম সুবিধা সৰ্বশ্ৰেষ্ঠ মূল্যত উপভোগ কৰক।",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "মাত্ৰ ${currencySymbol}${introAmount} ৰ বাবে ${introDuration} ${introPeriod} আৰু তাৰ পিছত ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "মাত্ৰ ${currencySymbol} ${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} আৰু তাৰ পিছত মাত্ৰ ${currencySymbol} ${amount} / ${duration} ৰ বাবে বিনামূলীয়া" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} ৰ বাবে বিনামূলীয়া, তাৰ পিছত ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod} আৰু তাৰ পিছত মাত্ৰ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "স্বয়ং-নৱীকৰণযোগ্য। যিকোনো সময়তে বাতিল কৰক।",
	"onboarding_paywall_restore" to "ক্ৰয় পুনৰুদ্ধাৰ কৰক",
	"onboarding_paywall_restore_failure" to "ক্ৰয় পুনৰুদ্ধাৰ কৰাবিফল হৈছে, পিছত পুনৰ চেষ্টা কৰক বা যোগাযোগ সমৰ্থন কৰক।",
	"onboarding_paywall_restore_success" to "সফল পুনৰুদ্ধাৰ কৰক!",
	"onboarding_paywall_title" to "মহানতা প্ৰাপ্ত কৰক!",
	"onboarding_welcome_message" to "আপোনাৰ ইনভইচ আৰু আকলন বোৰ নিয়ন্ত্ৰণ কৰিবলৈ এটা সম্পূৰ্ণ টুলছেট।",
	"onboarding_welcome_title" to "ডিংভইচলৈ স্বাগতম",
	"other_state" to "অন্যান্য",
	"overdue_state" to "অতিৰিক্ত",
	"paid_state" to "পৰিশোধ কৰা হৈছে",
	"password" to "পাছৱাৰ্ড",
	"password_blank_error" to "পাছৱৰ্ড ৰ প্ৰয়োজন।",
	"password_mismatch_error" to "পাছৱৰ্ডসমূহ মিলা নাই।",
	"pay" to "pay",
	"payment_instructions_label" to "পৰিশোধৰ নিৰ্দেশনা",
	"payment_reminders" to "পৰিশোধ স্মাৰকসমূহ",
	"phone_number_fields" to "ফোন নম্বৰ",
	"phone_number_hint" to "ফোন নম্বৰ",
	"postcode_hint" to "পোষ্টকোড",
	"preview" to "পূৰ্বদৰ্শন",
	"preview_menu_hint" to "পূৰ্বদৰ্শন মেনু",
	"price" to "দাম",
	"privacy_policy" to "গোপনীয়তা নীতি",
	"profile" to "প্ৰ'ফাইল",
	"profitable_client" to "আপোনাৰ আটাইতকৈ লাভজনক গ্ৰাহক হৈছে...",
	"profits_report" to "লাভ",
	"promo_content" to "প্ৰচাৰমূলক সমল",
	"quantity_hint" to "পৰিমাণ",
	"quantity_label_hint" to "পৰিমাণৰ লেবেল",
	"query_info" to "প্ৰশ্নই আপোনাক ইনপুট হিচাপে আইটেম আৰু অন্যান্য কৰ নিয়ম দুয়োটা উল্লেখ কৰি কাষ্টম গণনা লিখিবলৈ অনুমতি দিয়ে। আন এটা প্ৰবিষ্টি +, *, *, /, % - গণিত অপাৰেটৰৰ সৈতে লিংক কৰিবলৈ @ চিহ্ন আৰু আইটেম বা কৰ বিৱৰণ যোগ দিয়ক",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} আপোনাৰ গ্ৰাহকে প্ৰাপ্ত কৰিছিল।" },
	"received_state" to "প্ৰাপ্ত হৈছে",
	"registration_code_fields" to "পঞ্জীয়ন কোড",
	"reminder" to "স্মাৰক",
	"reports" to "প্ৰতিবেদনসমূহ",
	"reports_not_available" to "কোনো প্ৰতিবেদন উপলব্ধ নহয়, পিছত ঘূৰি আহক।",
	"reveal_password" to "পাছৱাৰ্ড প্ৰকাশ কৰক",
	"revenue" to "revenue",
	"sales_tax" to "ভেট",
	"sales_tax_number" to "ভেট নম্বৰ",
	"save" to "সংৰক্ষণ কৰক",
	"scan_document" to "দস্তাবেজ স্কেন কৰক",
	"search" to "অনুসন্ধান",
	"select" to "নিৰ্বাচন কৰক",
	"select_icon" to "আইকন নিৰ্বাচন কৰক",
	"select_signature" to "স্বাক্ষৰ নিৰ্বাচন কৰক",
	"select_template" to "টেমপ্লেট বাছক",
	"send" to "প্ৰেৰণ কৰক",
	"sent_state" to "প্ৰেৰণ কৰা হৈছে",
	"setup_sender" to "প্ৰেৰক সংস্থাপন কৰক",
	"share_option" to "ভাগ বতৰা কৰক",
	"ship_to" to "জাহাজলৈ",
	"sign_in" to "ছাইন ইন",
	"sign_in_apple" to "এপলৰ সৈতে ছাইন ইন কৰক",
	"sign_in_email" to "ইমেইলৰ সৈতে ছাইন ইন কৰক",
	"sign_in_facebook" to "ফেচবুকৰ সৈতে ছাইন ইন কৰক",
	"sign_in_google" to "গুগলৰ সৈতে ছাইন ইন কৰক",
	"sign_up" to "ছাই আপ",
	"sign_up_label" to "একাউণ্ট নাই নেকি?",
	"sign_up_link_label" to "ছাই আপ!",
	"signatures_option" to "স্বাক্ষৰসমূহ",
	"skip" to "এৰি যাওক",
	"sold_items_report" to "বিক্ৰী কৰা সামগ্ৰীসমূহ",
	"sort_by" to "ক্ৰম অনুসৰি:",
	"status_update" to "দস্তাবেজৰ স্থিতি আপডেট",
	"sub_annual" to "বাৰ্ষিক",
	"sub_annual_price" to Formattable { (price) -> "${price}/বছৰ" },
	"sub_backups" to "ক্লাউড বেকআপ",
	"sub_insights" to "অন্তৰ্দৃষ্টি",
	"sub_month" to "মাহিলি",
	"sub_monthly_price" to Formattable { (price) -> "${price}/মাহে" },
	"sub_notifications" to "অধিসূচনাসমূহ",
	"sub_popup_message" to "সদস্যতা সোনকালে আহি আছে। অলপ সময়ৰ ভিতৰতে ইয়াত পৰীক্ষা কৰক।",
	"sub_support" to "সমৰ্থন",
	"sub_templates" to "টেমপ্লেটসমূহ",
	"subscribe" to "সাবস্ক্ৰাইব কৰক",
	"subscription_header" to "আপোনাৰ ব্যৱসায় ইয়াৰ সৈতে বৃদ্ধি কৰক:",
	"subscription_option" to "সদস্যতা",
	"subscriptions_load_fail" to "দুঃখিত, আমি এই সময়ত আপোনাৰ সদস্যতা পুনৰুদ্ধাৰ কৰিবলৈ অক্ষম। অনুগ্ৰহ কৰি পিছত পুনৰ চেষ্টা কৰক বা সহায়ৰ বাবে আমাৰ সমৰ্থন দলৰ সৈতে যোগাযোগ কৰক।",
	"subtotal" to Formattable { (sum) -> "উপমুঠ: ${sum}" },
	"subtotal_label" to "উপমুঠ",
	"sum" to "যোগফল",
	"support_option" to "সমৰ্থন",
	"switch_reports" to "প্ৰতিবেদন সলনি কৰক",
	"tax" to "কৰ",
	"tax_deleted_message" to "কৰ বিলোপ কৰা হৈছে",
	"tax_description_warning" to "যদি ব্যৱহাৰ কৰা হয় এইটো এটা ইনভইচত দেখা যায়",
	"tax_input_flat" to "সমতল",
	"tax_input_percent" to "শতাংশ",
	"tax_number_fields" to "কৰ নম্বৰ",
	"tax_number_hint" to "কৰ নম্বৰ",
	"tax_query_info_description" to "প্ৰশ্ন তথ্য",
	"tax_report" to "কৰ",
	"tax_rules_option" to "কৰ নিয়ম",
	"taxed_clients_report" to "কৰ যুক্ত গ্ৰাহক",
	"template" to "টেমপ্লেট",
	"template_selector_info" to "ডিফল্ট টেমপ্লেট সলনি কৰিবলৈ চোৱাইপ কৰক",
	"template_unlock_promo" to "প্ৰিমিয়ামৰ সৈতে অধিক টেমপ্লেট ত প্ৰৱেশ কৰক",
	"templates_option" to "টেমপ্লেটসমূহ",
	"terms_of_service" to "সেৱাৰ চৰ্তাৱলী",
	"thank_you" to "ধন্যবাদ",
	"to" to "লৈ",
	"total" to Formattable { (sum) -> "মুঠ: ${sum}" },
	"total_label" to "মুঠ",
	"undo_action" to "বাতিল কৰক",
	"unit" to "একক",
	"unit_cost_hint" to "একক ব্যয়",
	"unknown_client" to "অজ্ঞাত গ্ৰাহক",
	"unlock" to "আনলক কৰক",
	"unnamed_item" to "নামহীন বস্তু",
	"upgrade" to "আপগ্ৰেড কৰক",
	"user_collision_error" to "একাউণ্ট ইতিমধ্যে বিদ্যমান।",
	"valid_until" to "বৈধ",
	"warning" to "সতৰ্কবাৰ্তা!",
	"yes" to "yes",
    )
    
}