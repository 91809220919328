// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Lo  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "ຍອມຮັບ ${termsOfService} ແລະ ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "ລະຫັດບໍ່ຖືກຕ້ອງ."
override val add_client_action: String = "ເພີ່ມລູກຄ້າ"
override val add_customer: String = "ເພີ່ມລູກຄ້າ"
override val add_expense: String = "ເພີ່ມຄ່າໃຊ້ຈ່າຍ"
override val add_fields_label: String = "ເພີ່ມ:"
override val add_invoice_action: String = "ສ້າງ"
override val add_item_action: String = "ເພີ່ມລາຍການ"
override val add_item_button: String = "ເພີ່ມລາຍການ"
override val add_signature_action: String = "ເພີ່ມລາຍເຊັນ"
override val add_tax_action: String = "ເພີ່ມພາສີ"
override val address_fields: String = "ທີ່ຢູ່"
override val address_line_hint: String = "ແຖວທີ່ຢູ່"
override val advanced: String = "ຂັ້ນສູງ"
override val agree_marketing: String = "ຂ້າພະເຈົ້າຕົກລົງເຫັນດີທີ່ຈະໄດ້ຮັບການສື່ສານການຕະຫຼາດໂດຍກົງ"
override val all_notifications: String = "ການແຈ້ງເຕືອນທັງໝົດ"
override val amortization: String = "amortization"
override val amount: String = "ຈໍາ​ນວນ"
override val asset: String = "asset"
override val attach_file: String = "ແນບໄຟລ໌"
override val attachment: String = "ໄຟລ໌ແນບ"
override val auth_error: String = "ການດຳເນີນການບໍ່ສຳເລັດ, ກະລຸນາລອງໃໝ່ອີກຄັ້ງ."
override val available_for_paid: String = "ການຊິງຄ໌ຄລາວສາມາດໃຊ້ໄດ້ພາຍໃຕ້ການສະໝັກໃຊ້ແບບເສຍເງິນເທົ່ານັ້ນ."
override val backups_option: String = "ຂໍ້ມູນສຳຮອງ"
override val balance: String = "balance"
override val bank_account: String = "ບັນ​ຊີ​ທະ​ນາ​ຄານ"
override val bill_number: String = "ໝາຍເລກໃບບິນ"
override val cancel: String = "ຍົກເລີກ"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "ໝວດໝູ່"
override val category: String = "ປະເພດ"
override val category_add: String = "ເພີ່ມໝວດໝູ່"
override val category_delete: String = "ລຶບໝວດໝູ່"
override val category_name: String = "ຊື່ປະເພດ"
override val change_language: Formattable = Formattable { (language) -> "ພາສາໃບເກັບເງິນ: ${language}" }
override val change_language_label: String = "ພາສາໃບເກັບເງິນ"
override val change_template: String = "ປ່ຽນແມ່ແບບ"
override val city_hint: String = "ເມືອງ"
override val clear: String = "ແຈ້ງ"
override val client: String = "ລູກ​ຄ້າ"
override val client_actions: String = "ການປະຕິບັດຂອງລູກຄ້າ"
override val client_deleted_message: String = "ລຶບລູກຄ້າແລ້ວ"
override val clients_error_required_fields: String = "ຕ້ອງມີຢ່າງໜ້ອຍໜຶ່ງຄ່າ."
override val clients_option: String = "ລູກຄ້າ"
override val close: String = "ປິດ"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "ໄວໆນີ້!"
override val company_logo: String = "ໂລໂກ້ບໍລິສັດ"
override val company_name_hint: String = "ບໍລິສັດ"
override val confirm_password_label: String = "ຢືນ​ຢັນ​ລະ​ຫັດ"
override val confirmation_title: String = "ເຈົ້າ​ແນ່​ໃຈ​ບໍ່?"
override val convert_to_invoice: String = "ປ່ຽນເປັນໃບແຈ້ງໜີ້"
override val country_hint: String = "ປະເທດ"
override val create_client: String = "ສ້າງລູກຄ້າ"
override val create_company: String = "ສ້າງບໍລິສັດ"
override val create_document: String = "ສ້າງເອກະສານ"
override val create_estimate: String = "ຄາດຄະເນ"
override val create_invoice: String = "ໃບເກັບເງິນ"
override val create_item: String = "ສ້າງລາຍການ"
override val create_new_client: String = "ສ້າງລູກຄ້າໃຫມ່"
override val create_new_item: String = "ສ້າງລາຍການໃຫມ່"
override val create_new_tax: String = "ສ້າງພາສີໃຫມ່"
override val create_signature: String = "ລາຍເຊັນ"
override val created: String = "ສ້າງແລ້ວ"
override val credit: String = "credit"
override val customer: String = "ລູກຄ້າ"
override val date: String = "ວັນທີ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "ສະກຸນເງິນເລີ່ມຕົ້ນ: ${currency}" }
override val default_currency_label: String = "ສະກຸນເງິນເລີ່ມຕົ້ນ"
override val delete: String = "ລຶບ"
override val delete_account: String = "ລຶບບັນຊີ"
override val delete_confirmation_message: String = "ຂໍ້​ມູນ​ທັງ​ຫມົດ​ຂອງ​ທ່ານ​ຈະ​ສູນ​ເສຍ​ໄປ​."
override val delete_item_title: String = "ລຶບລາຍການອອກບໍ?"
override val delete_signature: String = "ລຶບລາຍເຊັນ"
override val depreciation: String = "depreciation"
override val description: String = "ລາຍລະອຽດ"
override val description_hint: String = "ລາຍລະອຽດ"
override val detailed_report: String = "ລາຍ​ງານ​ລະ​ອຽດ"
override val disabled: String = "ພິການ"
override val discount: String = "ສ່ວນຫຼຸດ"
override val discount_hint: String = "ສ່ວນຫຼຸດ"
override val display_af: String = "ອາຟຣິກາ"
override val display_am: String = "Amharic"
override val display_ar: String = "ອາຣັບ"
override val display_as: String = "Assamese"
override val display_az: String = "ອາເຊີໄບຈັນ"
override val display_be: String = "ເບລາຣຸດ"
override val display_bg: String = "ບຸນກາຣີ"
override val display_bn: String = "ເບັງກາລີ"
override val display_bs: String = "ໂບສເນຍ"
override val display_ca: String = "ຄາຕາລານ"
override val display_cs: String = "ເຊັກ"
override val display_da: String = "ເດັນມາກ"
override val display_de: String = "ເຢຍລະມັນ"
override val display_el: String = "ກຣີກ"
override val display_en: String = "ພາສາອັງກິດ"
override val display_es: String = "ແອສປາໂຍນ"
override val display_es_MX: String = "ແອສປາໂຍນ (ເມັກຊິໂກ)"
override val display_et: String = "ເອສໂຕເນຍ"
override val display_eu: String = "Basque"
override val display_fa: String = "ເປີເຊຍ"
override val display_fi: String = "ຟິນແລນ"
override val display_fil: String = "ຟີລິບປິນ"
override val display_fr: String = "ຝຣັ່ງ"
override val display_fr_CA: String = "ຝຣັ່ງ ການາດາ"
override val display_gl: String = "ກາລິຊຽນ"
override val display_gu: String = "ກູຈາຣາຕີ"
override val display_he: String = "ເຮັບເຣີ"
override val display_hi: String = "ບໍ່"
override val display_hr: String = "ໂຄຣເອເຊຍ"
override val display_hu: String = "ຮັງກາຣີ"
override val display_hy: String = "ອາເມເນຍ"
override val display_id: String = "ອິນໂດເນເຊຍ"
override val display_is: String = "ໄອສແລນຕິກ"
override val display_it: String = "ອິຕາລຽນ"
override val display_ja: String = "ພາສາຍີ່ປຸ່ນ"
override val display_ka: String = "ຈໍເຈຍ"
override val display_kk: String = "ຄາຊັກ"
override val display_km: String = "ຂະເໝນກາງ"
override val display_kn: String = "ແຄນນາດາ"
override val display_ko: String = "ເກົາຫຼີ"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "ແຮງ​ງານ"
override val display_lt: String = "ລິທົວເນຍ"
override val display_lv: String = "ລັດເວຍ"
override val display_mk: String = "ມາເຊໂດເນຍ"
override val display_ml: String = "ມາລາຢາລາມ"
override val display_mn: String = "ມົງໂກລີ"
override val display_mr: String = "Marathi"
override val display_ms: String = "ມາເລ"
override val display_my: String = "ມຽນມາ"
override val display_nb: String = "Norwegian Bokmål"
override val display_ne: String = "ເນປານ"
override val display_nl: String = "ໂຮນລັງ"
override val display_or: String = "ໂອຣິຢາ"
override val display_pa: String = "ປັນຈາບີ"
override val display_pl: String = "ໂປໂລຍ"
override val display_pt: String = "ພາສາປອກຕຸຍການ"
override val display_pt_BR: String = "ປອກຕຸຍການ (ບຣາຊິວ)"
override val display_pt_PT: String = "ປອກຕຸຍການເອີຣົບ"
override val display_ro: String = "ໂຣມາເນຍ"
override val display_ru: String = "ພາສາລັດເຊຍ"
override val display_si: String = "Sinhala"
override val display_sk: String = "ສະໂລວັກ"
override val display_sl: String = "ສະໂລເວເນຍ"
override val display_sq: String = "ອານບານີ"
override val display_sr: String = "ເຊີເບຍ"
override val display_sv: String = "ຊູແອັດ"
override val display_sw: String = "ສະວາຮິລີ"
override val display_ta: String = "ທະມິນ"
override val display_te: String = "ເຕລູກູ"
override val display_th: String = "ໄທ"
override val display_tr: String = "ຕວກກີ"
override val display_uk: String = "ອູແກຣນ"
override val display_ur: String = "ພາສາອູດູ"
override val display_uz: String = "ອຸສເບກ"
override val display_vi: String = "ຫວຽດນາມ"
override val display_zh_Hant_TW: String = "ຈີນ (ໄຕ້ຫວັນ, ແບບດັ້ງເດີມ)"
override val display_zu: String = "ຊູລູ"
override val document_estimate: String = "ຄາດຄະເນ"
override val document_invoice: String = "ໃບເກັບເງິນ"
override val document_quote: String = "ວົງຢືມ"
override val done: String = "ສຳເລັດ"
override val download_app: String = "ດາວໂຫລດແອັບ"
override val download_invoice: String = "ດາວໂຫລດໃບແຈ້ງໜີ້"
override val download_pdf: String = "ດາວໂຫລດ PDF"
override val draw_signature: String = "ແຕ້ມລາຍເຊັນ"
override val due: Formattable = Formattable { (date) -> "ຮອດກຳນົດ ${date}" }
override val due_date: String = "ວັນ​ຄົບ​ກໍາ​ນົດ"
override val due_day: String = "ໃນມື້ຫນຶ່ງ"
override val due_label: String = "ຮອດກຳນົດ"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ແມ່ນຮອດກຳນົດ ${dueWhen}!" }
override val due_today: String = "ມື້​ນີ້"
override val due_week: String = "ໃນຫນຶ່ງອາທິດ"
override val duplicate: String = "ຊໍ້າກັນ"
override val duration_day: String = "ມື້"
override val duration_month: String = "ເດືອນ"
override val duration_week: String = "ອາທິດ"
override val duration_year: String = "ປີ"
override val email: String = "ອີເມວ"
override val email_blank_error: String = "ຕ້ອງການອີເມລ໌."
override val email_error: String = "ທີ່ຢູ່ອີເມວບໍ່ຖືກຕ້ອງ."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "ຂອບໃຈສໍາລັບທຸລະກິດຂອງທ່ານ. ເພື່ອເບິ່ງເຕັມ ${documentType} ຄລິກທີ່ປຸ່ມທີ່ຢູ່ທ້າຍຈົດໝາຍນີ້. ໃຫ້ສັງເກດວ່າມັນໃຊ້ໄດ້ຈົນຮອດ ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "ທ່ານໄດ້ຮັບໃຫມ່${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ເບິ່ງ ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "ສະບາຍດີ ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "ຂອບໃຈສໍາລັບທຸລະກິດຂອງທ່ານ. ເພື່ອເບິ່ງເຕັມ ${documentType} ຄລິກທີ່ປຸ່ມທີ່ຢູ່ທ້າຍຈົດໝາຍນີ້. ກະລຸນາຈ່າຍຍອດເງິນຈົນຮອດ ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "ທ່ານໄດ້ຮັບໃຫມ່${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "ອີເມວເພື່ອຣີເຊັດລະຫັດຜ່ານທີ່ສົ່ງມາ, ກະລຸນາກວດເບິ່ງກ່ອງຈົດໝາຍຂອງທ່ານ."
override val empty_clients_list_message: String = "ທ່ານບໍ່ມີລູກຄ້າທີ່ຈະຕິດຕາມ. ເພີ່ມລູກຄ້າໃໝ່."
override val empty_estimate_list_message: String = "ສ້າງການຄາດຄະເນຄັ້ງທໍາອິດຂອງທ່ານ"
override val empty_expenses: String = "ທ່ານບໍ່ມີຄ່າໃຊ້ຈ່າຍໃນການຕິດຕາມ. ເພີ່ມຄ່າໃຊ້ຈ່າຍໃຫມ່."
override val empty_invoice_list_message: String = "ສ້າງໃບແຈ້ງຫນີ້ທໍາອິດຂອງທ່ານ"
override val empty_items_list_message: String = "ທ່ານບໍ່ມີລາຍການ, ເພີ່ມລາຍການໃຫມ່!"
override val empty_signatures_list_message: String = "ບໍ່ພົບລາຍເຊັນ. ສ້າງຫນຶ່ງ!"
override val empty_taxes_list_message: String = "ທ່ານບໍ່ມີກົດລະບຽບພາສີ. ເພີ່ມກົດລະບຽບພາສີໃຫມ່."
override val empty_templates_list_message: String = "ບໍ່ມີແມ່ແບບ."
override val enabled: String = "ເປີດນຳໃຊ້"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "ການຄາດຄະເນ #"
override val estimates: String = "ການຄາດຄະເນ"
override val estimates_report: String = "ການຄາດຄະເນ"
override val expense_bank_fees: String = "ຄ່າທຳນຽມທະນາຄານ"
override val expense_benefits: String = "ຜົນປະໂຫຍດ"
override val expense_communication: String = "ການສື່ສານ"
override val expense_debt: String = "ໜີ້ສິນ"
override val expense_deleted: String = "ລຶບລາຍຈ່າຍແລ້ວ"
override val expense_depreciation: String = "ການ​ເສື່ອມ​ຄຸນ​ຄ່າ"
override val expense_gifts: String = "ຂອງຂວັນ"
override val expense_insurance: String = "ປະກັນໄພ"
override val expense_inventory: String = "ສາງ"
override val expense_legal: String = "ທາງດ້ານກົດໝາຍ"
override val expense_maintenance: String = "ບໍາລຸງຮັກສາ"
override val expense_marketing: String = "ການຕະຫຼາດ"
override val expense_meals: String = "ອາຫານ"
override val expense_office_supplies: String = "ເຄື່ອງ​ໃຊ້​ຫ້ອງ​ການ"
override val expense_rent: String = "ເຊົ່າ"
override val expense_salaries: String = "ເງິນເດືອນ"
override val expense_shopping: String = "ຊື້ເຄື່ອງ"
override val expense_software: String = "ຊອບແວ"
override val expense_status_available: String = "ມີໃຫ້"
override val expense_status_processing: String = "ການປຸງແຕ່ງ"
override val expense_tax: String = "ພາສີ"
override val expense_training: String = "ການຝຶກອົບຮົມ"
override val expense_transport: String = "ການຂົນສົ່ງ"
override val expense_travel: String = "ທ່ອງ​ທ່ຽວ"
override val expense_utility: String = "ປະໂຫຍດ"
override val expenses: String = "ຄ່າໃຊ້ຈ່າຍ"
override val feedback_option: String = "ຜົນຕອບຮັບ"
override val filter: String = "ການກັ່ນຕອງ"
override val first_name_hint: String = "ຊື່​ແທ້"
override val forgot_password: String = "ຣີເຊັດລະຫັດຜ່ານ"
override val forgot_password_label: String = "ລືມລະຫັດຜ່ານ"
override val from: String = "ຈາກ"
override val general_error: String = "ເກີດຄວາມຜິດພາດຂຶ້ນ, ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "ກັບ​ຄືນ"
override val group_by: String = "ກຸ່ມໂດຍ:"
override val image_view: String = "ຮູບພາບ"
override val install: String = "ຕິດຕັ້ງ"
override val invalid_credentials_error: String = "ຂໍ້ມູນປະຈຳຕົວບໍ່ຖືກຕ້ອງ."
override val invalid_email_error: String = "ອີເມວບໍ່ຖືກຕ້ອງ."
override val invalid_user_error: String = "ຂໍ້ມູນປະຈຳຕົວຜູ້ໃຊ້ບໍ່ຖືກຕ້ອງ."
override val invoice_deleted_message: String = "ໃບແຈ້ງໜີ້ຖືກລຶບແລ້ວ"
override val invoice_menu: String = "ເມນູໃບເກັບເງິນ"
override val invoice_number: String = "ໃບແຈ້ງໜີ້ #"
override val invoices: String = "ໃບແຈ້ງໜີ້"
override val items_option: String = "ລາຍການ"
override val keep: String = "ຮັກສາ"
override val language_selector: Formattable = Formattable { (selected) -> "ພາສາ ${selected}" }
override val last_name_hint: String = "ນາມ​ສະ​ກຸນ"
override val ledger: String = "ledger"
override val legal: String = "ທາງດ້ານກົດໝາຍ"
override val legal_error: String = "ຕ້ອງຍອມຮັບເງື່ອນໄຂການໃຫ້ບໍລິການ ແລະນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ"
override val liabilities: String = "liabilities"
override val loading: String = "ກຳລັງໂຫຼດ"
override val login_required_error: String = "ຕ້ອງການເຂົ້າສູ່ລະບົບ."
override val logo_view: String = "ໂລໂກ້"
override val logout: String = "ອອກ​ຈາກ​ລະ​ບົບ"
override val logout_prompt: String = "ຂໍ້ມູນຂອງທ່ານບໍ່ໄດ້ຖືກ synchronized ກັບຄລາວ. ລຶບໃບແຈ້ງໜີ້, ການຄາດຄະເນ, ລູກຄ້າ ແລະຂໍ້ມູນອື່ນໆໃນເວລາອອກຈາກລະບົບບໍ?"
override val main_screen: String = "ໜ້າຈໍຫຼັກ"
override val mark_paid: String = "ໝາຍວ່າຈ່າຍແລ້ວ"
override val message: String = "ຂໍ້ຄວາມ"
override val missing_sender_msg: String = "ເອກະສານທີ່ບໍ່ມີຂໍ້ມູນຜູ້ສົ່ງອາດຈະບໍ່ຖືກຕ້ອງ."
override val missing_sender_title: String = "ບໍ່ມີຂໍ້ມູນຜູ້ສົ່ງ"
override val month_april: String = "ເດືອນເມສາ"
override val month_april_short: String = "ເດືອນເມສາ"
override val month_august: String = "ສິງຫາ"
override val month_august_short: String = "ສິງຫາ"
override val month_december: String = "ເດືອນທັນວາ"
override val month_december_short: String = "ທັນວາ"
override val month_february: String = "ເດືອນກຸມພາ"
override val month_february_short: String = "ກຸມພາ"
override val month_january: String = "ມັງກອນ"
override val month_january_short: String = "ມັງກອນ"
override val month_july: String = "ກໍລະກົດ"
override val month_july_short: String = "ກໍລະກົດ"
override val month_june: String = "ເດືອນມິຖຸນາ"
override val month_june_short: String = "ເດືອນມິຖຸນາ"
override val month_march: String = "ມີນາ"
override val month_march_short: String = "ມີນາ"
override val month_may: String = "ພຶດສະພາ"
override val month_may_short: String = "ພຶດສະພາ"
override val month_november: String = "ເດືອນພະຈິກ"
override val month_november_short: String = "ພະຈິກ"
override val month_october: String = "ຕຸລາ"
override val month_october_short: String = "ຕຸລາ"
override val month_september: String = "ກັນຍາ"
override val month_september_short: String = "ກັນຍາ"
override val multifactor_error: String = "ລົ້ມເຫລວໃນການກວດສອບ."
override val new: String = "ໃຫມ່"
override val news: String = "ຂ່າວ"
override val next: String = "ຕໍ່ໄປ"
override val no: String = "no"
override val no_data_available: String = "ບໍ່ມີຂໍ້ມູນ"
override val no_search_results: String = "ຂໍອະໄພ, ພວກເຮົາບໍ່ສາມາດຊອກຫາຜົນໄດ້ຮັບໃດໆ."
override val none: String = "ບໍ່ມີ"
override val note_fields: String = "ບັນທຶກ"
override val note_hint: String = "ບັນທຶກ"
override val notifications_option: String = "ການແຈ້ງເຕືອນ"
override val ok: String = "ຕົກລົງ"
override val onboarding: String = "ການຂຶ້ນເຮືອບິນ"
override val onboarding_aboutus_message: String = "ພາກສ່ວນທີ່ສຳຄັນຂອງ DingVoice ແມ່ນບໍ່ເສຍຄ່າຕະຫຼອດໄປ. ພາລະກິດຂອງພວກເຮົາແມ່ນເພື່ອຊ່ວຍໃຫ້ທ່ານຂະຫຍາຍທຸລະກິດຂອງທ່ານ, ຊ່ວຍພວກເຮົາປັບປຸງໂດຍການແບ່ງປັນຄວາມຄິດເຫັນຂອງທ່ານ."
override val onboarding_aboutus_title: String = "ພວກເຮົາຢູ່ທີ່ນີ້ເພື່ອຊ່ວຍ"
override val onboarding_paid_1: String = "ເຂົ້າເຖິງແມ່ແບບທີ່ເປັນມືອາຊີບ"
override val onboarding_paid_2: String = "ເບິ່ງຢ່າງເລິກເຊິ່ງກ່ຽວກັບການເງິນຂອງເຈົ້າ"
override val onboarding_paid_3: String = "ໄດ້​ຮັບ​ການ​ແກ້​ໄຂ​ບັນ​ຫາ​ໄວ​"
override val onboarding_paid_4: String = "ຮັກສາເອກະສານຂອງເຈົ້າໃຫ້ປອດໄພ"
override val onboarding_paid_5: String = "ຕິດຕາມການປ່ຽນແປງສະຖານະໃບແຈ້ງໜີ້"
override val onboarding_paid_title: String = "ເຕີບໃຫຍ່ໄວຂຶ້ນດ້ວຍ..."
override val onboarding_paywall_message: String = "ເພີດເພີນໄປກັບຄຸນສົມບັດພຣີມຽມທັງໝົດຕອນນີ້ໃນລາຄາທີ່ດີທີ່ສຸດ."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "ພຽງແຕ່ ${currencySymbol}${introAmount} ສໍາລັບ ${introDuration} ${introPeriod} ແລະຫຼັງຈາກນັ້ນ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "ພຽງແຕ່ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "ຟຣີສໍາລັບ ${trialDuration} ${trialPeriod} ແລະຫຼັງຈາກນັ້ນພຽງແຕ່ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "ຟຣີສໍາລັບ ${trialDuration} ${trialPeriod} ຈາກນັ້ນ ${currencySymbol}${introductoryAmount} ສໍາລັບ ${introductoryDuration} ${introPeriod} ແລະຈາກນັ້ນພຽງແຕ່ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "ສາມາດຕໍ່ອາຍຸໄດ້ໂດຍອັດຕະໂນມັດ. ຍົກເລີກໄດ້ທຸກເວລາ."
override val onboarding_paywall_restore: String = "ຟື້ນຟູການຊື້"
override val onboarding_paywall_restore_failure: String = "ການຟື້ນຟູການຊື້ລົ້ມເຫລວ, ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ ຫຼືຕິດຕໍ່ຝ່າຍຊ່ວຍເຫຼືອ."
override val onboarding_paywall_restore_success: String = "ຟື້ນຟູສຳເລັດແລ້ວ!"
override val onboarding_paywall_title: String = "ບັນລຸຄວາມຍິ່ງໃຫຍ່!"
override val onboarding_welcome_message: String = "ຊຸດເຄື່ອງມືທີ່ສົມບູນເພື່ອຈັດການໃບແຈ້ງໜີ້ ແລະ ການຄາດຄະເນຂອງທ່ານ."
override val onboarding_welcome_title: String = "ຍິນດີຕ້ອນຮັບສູ່ DingVoice"
override val other_state: String = "ອື່ນໆ"
override val overdue_state: String = "ເກີນກຳນົດ"
override val paid_state: String = "ຈ່າຍແລ້ວ"
override val password: String = "ລະຫັດຜ່ານ"
override val password_blank_error: String = "ຕ້ອງການລະຫັດຜ່ານ."
override val password_mismatch_error: String = "ລະຫັດຜ່ານບໍ່ກົງກັນ."
override val pay: String = "pay"
override val payment_instructions_label: String = "ຄໍາແນະນໍາການຈ່າຍເງິນ"
override val payment_reminders: String = "ເຕືອນການຈ່າຍເງິນ"
override val phone_number_fields: String = "ເບີ​ໂທລະ​ສັບ"
override val phone_number_hint: String = "ເບີ​ໂທລະ​ສັບ"
override val postcode_hint: String = "ລະຫັດໄປສະນີ"
override val preview: String = "ເບິ່ງຕົວຢ່າງ"
override val preview_menu_hint: String = "ເມນູຕົວຢ່າງ"
override val price: String = "ລາຄາ"
override val privacy_policy: String = "ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ"
override val profile: String = "ໂປຣໄຟລ໌"
override val profitable_client: String = "ລູກຄ້າທີ່ມີກໍາໄລຫຼາຍທີ່ສຸດຂອງທ່ານແມ່ນ ..."
override val profits_report: String = "ຜົນກຳໄລ"
override val promo_content: String = "ເນື້ອຫາສົ່ງເສີມ"
override val quantity_hint: String = "ປະລິມານ"
override val quantity_label_hint: String = "ປ້າຍກຳກັບປະລິມານ"
override val query_info: String = "ການສອບຖາມອະນຸຍາດໃຫ້ທ່ານຂຽນການຄິດໄລ່ແບບກໍານົດເອງໂດຍອ້າງອີງໃສ່ທັງສອງລາຍການແລະກົດລະບຽບພາສີອື່ນໆເປັນວັດສະດຸປ້ອນ. ເພີ່ມ @ ສັນ​ຍາ​ລັກ​ແລະ​ລາຍ​ການ​ຫຼື​ລາຍ​ລະ​ອຽດ​ພາ​ສີ​ເພື່ອ​ເຊື່ອມ​ຕໍ່​ກັບ​ການ​ເຂົ້າ​ອື່ນ +, -, *, /, % - math operators"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ໄດ້ຮັບໂດຍລູກຄ້າຂອງທ່ານ." }
override val received_state: String = "ໄດ້ຮັບ"
override val registration_code_fields: String = "ລະຫັດການລົງທະບຽນ"
override val reminder: String = "ເຕືອນ"
override val reports: String = "ບົດລາຍງານ"
override val reports_not_available: String = "ບໍ່ມີລາຍງານ, ກັບມາໃໝ່ໃນພາຍຫຼັງ."
override val reveal_password: String = "ເປີດເຜີຍລະຫັດຜ່ານ"
override val revenue: String = "revenue"
override val sales_tax: String = "ອາກອນມູນຄ່າເພີ່ມ"
override val sales_tax_number: String = "ເລກ VAT"
override val save: String = "ຊ່ວຍປະຢັດ"
override val scan_document: String = "ສະແກນເອກະສານ"
override val search: String = "ຄົ້ນຫາ"
override val select: String = "ເລືອກ"
override val select_icon: String = "ເລືອກໄອຄອນ"
override val select_signature: String = "ເລືອກລາຍເຊັນ"
override val select_template: String = "ເລືອກແມ່ແບບ"
override val send: String = "ສົ່ງ"
override val sent_state: String = "ສົ່ງແລ້ວ"
override val setup_sender: String = "ຕັ້ງຄ່າຜູ້ສົ່ງ"
override val share_option: String = "ແບ່ງປັນ"
override val ship_to: String = "ສົ່ງ​ໄປ​ທີ່"
override val sign_in: String = "ເຂົ້າ​ສູ່​ລະ​ບົບ"
override val sign_in_apple: String = "ເຂົ້າສູ່ລະບົບດ້ວຍ Apple"
override val sign_in_email: String = "ເຂົ້າສູ່ລະບົບດ້ວຍອີເມວ"
override val sign_in_facebook: String = "ເຂົ້າສູ່ລະບົບດ້ວຍ Facebook"
override val sign_in_google: String = "ເຂົ້າສູ່ລະບົບດ້ວຍ Google"
override val sign_up: String = "ລົງ​ທະ​ບຽນ"
override val sign_up_label: String = "ບໍ່ມີບັນຊີບໍ?"
override val sign_up_link_label: String = "ລົງ​ທະ​ບຽນ!"
override val signatures_option: String = "ລາຍເຊັນ"
override val skip: String = "ຂ້າມ"
override val sold_items_report: String = "ລາຍການຂາຍ"
override val sort_by: String = "ຈັດຮຽງຕາມ:"
override val status_update: String = "ອັບເດດສະຖານະເອກະສານ"
override val sub_annual: String = "ປະຈໍາປີ"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/ປີ" }
override val sub_backups: String = "Cloud Backups"
override val sub_insights: String = "ຄວາມເຂົ້າໃຈ"
override val sub_month: String = "ປະຈໍາເດືອນ"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ເດືອນ" }
override val sub_notifications: String = "ການແຈ້ງເຕືອນ"
override val sub_popup_message: String = "ການສະໝັກໃຊ້ກຳລັງຈະມາໃນໄວໆນີ້. ກະລຸນາກັບມາເບິ່ງທີ່ນີ້ໃນອີກບໍ່ດົນ."
override val sub_support: String = "ສະໜັບສະໜຸນ"
override val sub_templates: String = "ແມ່ແບບ"
override val subscribe: String = "ຈອງ"
override val subscription_header: String = "ຂະຫຍາຍທຸລະກິດຂອງທ່ານດ້ວຍ:"
override val subscription_option: String = "ການສະໝັກໃຊ້"
override val subscriptions_load_fail: String = "ຂໍອະໄພ, ພວກເຮົາບໍ່ສາມາດດຶງຂໍ້ມູນການສະໝັກໃຊ້ຂອງທ່ານໄດ້ໃນເວລານີ້. ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ ຫຼືຕິດຕໍ່ທີມງານຊ່ວຍເຫຼືອຂອງພວກເຮົາເພື່ອຂໍຄວາມຊ່ວຍເຫຼືອ."
override val subtotal: Formattable = Formattable { (sum) -> "ທັງໝົດຍ່ອຍ: ${sum}" }
override val subtotal_label: String = "ທັງໝົດຍ່ອຍ"
override val sum: String = "ລວມ"
override val support_option: String = "ສະຫນັບສະຫນູນ"
override val switch_reports: String = "ສະຫຼັບລາຍງານ"
override val tax: String = "ພາສີ"
override val tax_deleted_message: String = "ລຶບພາສີແລ້ວ"
override val tax_description_warning: String = "ຖ້າໃຊ້, ນີ້ສະແດງຢູ່ໃນໃບແຈ້ງຫນີ້"
override val tax_input_flat: String = "ຮາບພຽງ"
override val tax_input_percent: String = "ເປີເຊັນ"
override val tax_number_fields: String = "ໝາຍເລກພາສີ"
override val tax_number_hint: String = "ໝາຍເລກພາສີ"
override val tax_query_info_description: String = "ຂໍ້ມູນການສອບຖາມ"
override val tax_report: String = "ພາສີ"
override val tax_rules_option: String = "ກົດລະບຽບພາສີ"
override val taxed_clients_report: String = "ລູກຄ້າເສຍພາສີ"
override val template: String = "ແມ່ແບບ"
override val template_selector_info: String = "ປັດເພື່ອປ່ຽນແມ່ແບບເລີ່ມຕົ້ນ"
override val template_unlock_promo: String = "ເຂົ້າເຖິງແມ່ແບບເພີ່ມເຕີມດ້ວຍ Premium"
override val templates_option: String = "ແມ່ແບບ"
override val terms_of_service: String = "ເງື່ອນໄຂການໃຫ້ບໍລິການ"
override val thank_you: String = "ຂອບ​ໃຈ"
override val to: String = "ເຖິງ"
override val total: Formattable = Formattable { (sum) -> "ທັງໝົດ: ${sum}" }
override val total_label: String = "TOTAL"
override val undo_action: String = "ຍົກເລີກ"
override val unit: String = "ໜ່ວຍ"
override val unit_cost_hint: String = "ຄ່າໃຊ້ຈ່າຍຕໍ່ຫນ່ວຍ"
override val unknown_client: String = "ລູກຄ້າທີ່ບໍ່ຮູ້ຈັກ"
override val unlock: String = "ປົດລັອກ"
override val unnamed_item: String = "ລາຍການທີ່ບໍ່ມີຊື່"
override val upgrade: String = "ຍົກລະດັບ"
override val user_collision_error: String = "ມີບັນຊີຢູ່ແລ້ວ."
override val valid_until: String = "ໃຊ້ໄດ້ຈົນກ່ວາ"
override val warning: String = "ເຕືອນໄພ!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "ຍອມຮັບ ${termsOfService} ແລະ ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "ລະຫັດບໍ່ຖືກຕ້ອງ.",
	"add_client_action" to "ເພີ່ມລູກຄ້າ",
	"add_customer" to "ເພີ່ມລູກຄ້າ",
	"add_expense" to "ເພີ່ມຄ່າໃຊ້ຈ່າຍ",
	"add_fields_label" to "ເພີ່ມ:",
	"add_invoice_action" to "ສ້າງ",
	"add_item_action" to "ເພີ່ມລາຍການ",
	"add_item_button" to "ເພີ່ມລາຍການ",
	"add_signature_action" to "ເພີ່ມລາຍເຊັນ",
	"add_tax_action" to "ເພີ່ມພາສີ",
	"address_fields" to "ທີ່ຢູ່",
	"address_line_hint" to "ແຖວທີ່ຢູ່",
	"advanced" to "ຂັ້ນສູງ",
	"agree_marketing" to "ຂ້າພະເຈົ້າຕົກລົງເຫັນດີທີ່ຈະໄດ້ຮັບການສື່ສານການຕະຫຼາດໂດຍກົງ",
	"all_notifications" to "ການແຈ້ງເຕືອນທັງໝົດ",
	"amortization" to "amortization",
	"amount" to "ຈໍາ​ນວນ",
	"asset" to "asset",
	"attach_file" to "ແນບໄຟລ໌",
	"attachment" to "ໄຟລ໌ແນບ",
	"auth_error" to "ການດຳເນີນການບໍ່ສຳເລັດ, ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.",
	"available_for_paid" to "ການຊິງຄ໌ຄລາວສາມາດໃຊ້ໄດ້ພາຍໃຕ້ການສະໝັກໃຊ້ແບບເສຍເງິນເທົ່ານັ້ນ.",
	"backups_option" to "ຂໍ້ມູນສຳຮອງ",
	"balance" to "balance",
	"bank_account" to "ບັນ​ຊີ​ທະ​ນາ​ຄານ",
	"bill_number" to "ໝາຍເລກໃບບິນ",
	"cancel" to "ຍົກເລີກ",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "ໝວດໝູ່",
	"category" to "ປະເພດ",
	"category_add" to "ເພີ່ມໝວດໝູ່",
	"category_delete" to "ລຶບໝວດໝູ່",
	"category_name" to "ຊື່ປະເພດ",
	"change_language" to Formattable { (language) -> "ພາສາໃບເກັບເງິນ: ${language}" },
	"change_language_label" to "ພາສາໃບເກັບເງິນ",
	"change_template" to "ປ່ຽນແມ່ແບບ",
	"city_hint" to "ເມືອງ",
	"clear" to "ແຈ້ງ",
	"client" to "ລູກ​ຄ້າ",
	"client_actions" to "ການປະຕິບັດຂອງລູກຄ້າ",
	"client_deleted_message" to "ລຶບລູກຄ້າແລ້ວ",
	"clients_error_required_fields" to "ຕ້ອງມີຢ່າງໜ້ອຍໜຶ່ງຄ່າ.",
	"clients_option" to "ລູກຄ້າ",
	"close" to "ປິດ",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "ໄວໆນີ້!",
	"company_logo" to "ໂລໂກ້ບໍລິສັດ",
	"company_name_hint" to "ບໍລິສັດ",
	"confirm_password_label" to "ຢືນ​ຢັນ​ລະ​ຫັດ",
	"confirmation_title" to "ເຈົ້າ​ແນ່​ໃຈ​ບໍ່?",
	"convert_to_invoice" to "ປ່ຽນເປັນໃບແຈ້ງໜີ້",
	"country_hint" to "ປະເທດ",
	"create_client" to "ສ້າງລູກຄ້າ",
	"create_company" to "ສ້າງບໍລິສັດ",
	"create_document" to "ສ້າງເອກະສານ",
	"create_estimate" to "ຄາດຄະເນ",
	"create_invoice" to "ໃບເກັບເງິນ",
	"create_item" to "ສ້າງລາຍການ",
	"create_new_client" to "ສ້າງລູກຄ້າໃຫມ່",
	"create_new_item" to "ສ້າງລາຍການໃຫມ່",
	"create_new_tax" to "ສ້າງພາສີໃຫມ່",
	"create_signature" to "ລາຍເຊັນ",
	"created" to "ສ້າງແລ້ວ",
	"credit" to "credit",
	"customer" to "ລູກຄ້າ",
	"date" to "ວັນທີ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "ສະກຸນເງິນເລີ່ມຕົ້ນ: ${currency}" },
	"default_currency_label" to "ສະກຸນເງິນເລີ່ມຕົ້ນ",
	"delete" to "ລຶບ",
	"delete_account" to "ລຶບບັນຊີ",
	"delete_confirmation_message" to "ຂໍ້​ມູນ​ທັງ​ຫມົດ​ຂອງ​ທ່ານ​ຈະ​ສູນ​ເສຍ​ໄປ​.",
	"delete_item_title" to "ລຶບລາຍການອອກບໍ?",
	"delete_signature" to "ລຶບລາຍເຊັນ",
	"depreciation" to "depreciation",
	"description" to "ລາຍລະອຽດ",
	"description_hint" to "ລາຍລະອຽດ",
	"detailed_report" to "ລາຍ​ງານ​ລະ​ອຽດ",
	"disabled" to "ພິການ",
	"discount" to "ສ່ວນຫຼຸດ",
	"discount_hint" to "ສ່ວນຫຼຸດ",
	"display_af" to "ອາຟຣິກາ",
	"display_am" to "Amharic",
	"display_ar" to "ອາຣັບ",
	"display_as" to "Assamese",
	"display_az" to "ອາເຊີໄບຈັນ",
	"display_be" to "ເບລາຣຸດ",
	"display_bg" to "ບຸນກາຣີ",
	"display_bn" to "ເບັງກາລີ",
	"display_bs" to "ໂບສເນຍ",
	"display_ca" to "ຄາຕາລານ",
	"display_cs" to "ເຊັກ",
	"display_da" to "ເດັນມາກ",
	"display_de" to "ເຢຍລະມັນ",
	"display_el" to "ກຣີກ",
	"display_en" to "ພາສາອັງກິດ",
	"display_es" to "ແອສປາໂຍນ",
	"display_es_MX" to "ແອສປາໂຍນ (ເມັກຊິໂກ)",
	"display_et" to "ເອສໂຕເນຍ",
	"display_eu" to "Basque",
	"display_fa" to "ເປີເຊຍ",
	"display_fi" to "ຟິນແລນ",
	"display_fil" to "ຟີລິບປິນ",
	"display_fr" to "ຝຣັ່ງ",
	"display_fr_CA" to "ຝຣັ່ງ ການາດາ",
	"display_gl" to "ກາລິຊຽນ",
	"display_gu" to "ກູຈາຣາຕີ",
	"display_he" to "ເຮັບເຣີ",
	"display_hi" to "ບໍ່",
	"display_hr" to "ໂຄຣເອເຊຍ",
	"display_hu" to "ຮັງກາຣີ",
	"display_hy" to "ອາເມເນຍ",
	"display_id" to "ອິນໂດເນເຊຍ",
	"display_is" to "ໄອສແລນຕິກ",
	"display_it" to "ອິຕາລຽນ",
	"display_ja" to "ພາສາຍີ່ປຸ່ນ",
	"display_ka" to "ຈໍເຈຍ",
	"display_kk" to "ຄາຊັກ",
	"display_km" to "ຂະເໝນກາງ",
	"display_kn" to "ແຄນນາດາ",
	"display_ko" to "ເກົາຫຼີ",
	"display_ky" to "Kirghiz",
	"display_lo" to "ແຮງ​ງານ",
	"display_lt" to "ລິທົວເນຍ",
	"display_lv" to "ລັດເວຍ",
	"display_mk" to "ມາເຊໂດເນຍ",
	"display_ml" to "ມາລາຢາລາມ",
	"display_mn" to "ມົງໂກລີ",
	"display_mr" to "Marathi",
	"display_ms" to "ມາເລ",
	"display_my" to "ມຽນມາ",
	"display_nb" to "Norwegian Bokmål",
	"display_ne" to "ເນປານ",
	"display_nl" to "ໂຮນລັງ",
	"display_or" to "ໂອຣິຢາ",
	"display_pa" to "ປັນຈາບີ",
	"display_pl" to "ໂປໂລຍ",
	"display_pt" to "ພາສາປອກຕຸຍການ",
	"display_pt_BR" to "ປອກຕຸຍການ (ບຣາຊິວ)",
	"display_pt_PT" to "ປອກຕຸຍການເອີຣົບ",
	"display_ro" to "ໂຣມາເນຍ",
	"display_ru" to "ພາສາລັດເຊຍ",
	"display_si" to "Sinhala",
	"display_sk" to "ສະໂລວັກ",
	"display_sl" to "ສະໂລເວເນຍ",
	"display_sq" to "ອານບານີ",
	"display_sr" to "ເຊີເບຍ",
	"display_sv" to "ຊູແອັດ",
	"display_sw" to "ສະວາຮິລີ",
	"display_ta" to "ທະມິນ",
	"display_te" to "ເຕລູກູ",
	"display_th" to "ໄທ",
	"display_tr" to "ຕວກກີ",
	"display_uk" to "ອູແກຣນ",
	"display_ur" to "ພາສາອູດູ",
	"display_uz" to "ອຸສເບກ",
	"display_vi" to "ຫວຽດນາມ",
	"display_zh_Hant_TW" to "ຈີນ (ໄຕ້ຫວັນ, ແບບດັ້ງເດີມ)",
	"display_zu" to "ຊູລູ",
	"document_estimate" to "ຄາດຄະເນ",
	"document_invoice" to "ໃບເກັບເງິນ",
	"document_quote" to "ວົງຢືມ",
	"done" to "ສຳເລັດ",
	"download_app" to "ດາວໂຫລດແອັບ",
	"download_invoice" to "ດາວໂຫລດໃບແຈ້ງໜີ້",
	"download_pdf" to "ດາວໂຫລດ PDF",
	"draw_signature" to "ແຕ້ມລາຍເຊັນ",
	"due" to Formattable { (date) -> "ຮອດກຳນົດ ${date}" },
	"due_date" to "ວັນ​ຄົບ​ກໍາ​ນົດ",
	"due_day" to "ໃນມື້ຫນຶ່ງ",
	"due_label" to "ຮອດກຳນົດ",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ແມ່ນຮອດກຳນົດ ${dueWhen}!" },
	"due_today" to "ມື້​ນີ້",
	"due_week" to "ໃນຫນຶ່ງອາທິດ",
	"duplicate" to "ຊໍ້າກັນ",
	"duration_day" to "ມື້",
	"duration_month" to "ເດືອນ",
	"duration_week" to "ອາທິດ",
	"duration_year" to "ປີ",
	"email" to "ອີເມວ",
	"email_blank_error" to "ຕ້ອງການອີເມລ໌.",
	"email_error" to "ທີ່ຢູ່ອີເມວບໍ່ຖືກຕ້ອງ.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "ຂອບໃຈສໍາລັບທຸລະກິດຂອງທ່ານ. ເພື່ອເບິ່ງເຕັມ ${documentType} ຄລິກທີ່ປຸ່ມທີ່ຢູ່ທ້າຍຈົດໝາຍນີ້. ໃຫ້ສັງເກດວ່າມັນໃຊ້ໄດ້ຈົນຮອດ ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "ທ່ານໄດ້ຮັບໃຫມ່${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "ເບິ່ງ ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "ສະບາຍດີ ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "ຂອບໃຈສໍາລັບທຸລະກິດຂອງທ່ານ. ເພື່ອເບິ່ງເຕັມ ${documentType} ຄລິກທີ່ປຸ່ມທີ່ຢູ່ທ້າຍຈົດໝາຍນີ້. ກະລຸນາຈ່າຍຍອດເງິນຈົນຮອດ ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "ທ່ານໄດ້ຮັບໃຫມ່${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "ອີເມວເພື່ອຣີເຊັດລະຫັດຜ່ານທີ່ສົ່ງມາ, ກະລຸນາກວດເບິ່ງກ່ອງຈົດໝາຍຂອງທ່ານ.",
	"empty_clients_list_message" to "ທ່ານບໍ່ມີລູກຄ້າທີ່ຈະຕິດຕາມ. ເພີ່ມລູກຄ້າໃໝ່.",
	"empty_estimate_list_message" to "ສ້າງການຄາດຄະເນຄັ້ງທໍາອິດຂອງທ່ານ",
	"empty_expenses" to "ທ່ານບໍ່ມີຄ່າໃຊ້ຈ່າຍໃນການຕິດຕາມ. ເພີ່ມຄ່າໃຊ້ຈ່າຍໃຫມ່.",
	"empty_invoice_list_message" to "ສ້າງໃບແຈ້ງຫນີ້ທໍາອິດຂອງທ່ານ",
	"empty_items_list_message" to "ທ່ານບໍ່ມີລາຍການ, ເພີ່ມລາຍການໃຫມ່!",
	"empty_signatures_list_message" to "ບໍ່ພົບລາຍເຊັນ. ສ້າງຫນຶ່ງ!",
	"empty_taxes_list_message" to "ທ່ານບໍ່ມີກົດລະບຽບພາສີ. ເພີ່ມກົດລະບຽບພາສີໃຫມ່.",
	"empty_templates_list_message" to "ບໍ່ມີແມ່ແບບ.",
	"enabled" to "ເປີດນຳໃຊ້",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "ການຄາດຄະເນ #",
	"estimates" to "ການຄາດຄະເນ",
	"estimates_report" to "ການຄາດຄະເນ",
	"expense_bank_fees" to "ຄ່າທຳນຽມທະນາຄານ",
	"expense_benefits" to "ຜົນປະໂຫຍດ",
	"expense_communication" to "ການສື່ສານ",
	"expense_debt" to "ໜີ້ສິນ",
	"expense_deleted" to "ລຶບລາຍຈ່າຍແລ້ວ",
	"expense_depreciation" to "ການ​ເສື່ອມ​ຄຸນ​ຄ່າ",
	"expense_gifts" to "ຂອງຂວັນ",
	"expense_insurance" to "ປະກັນໄພ",
	"expense_inventory" to "ສາງ",
	"expense_legal" to "ທາງດ້ານກົດໝາຍ",
	"expense_maintenance" to "ບໍາລຸງຮັກສາ",
	"expense_marketing" to "ການຕະຫຼາດ",
	"expense_meals" to "ອາຫານ",
	"expense_office_supplies" to "ເຄື່ອງ​ໃຊ້​ຫ້ອງ​ການ",
	"expense_rent" to "ເຊົ່າ",
	"expense_salaries" to "ເງິນເດືອນ",
	"expense_shopping" to "ຊື້ເຄື່ອງ",
	"expense_software" to "ຊອບແວ",
	"expense_status_available" to "ມີໃຫ້",
	"expense_status_processing" to "ການປຸງແຕ່ງ",
	"expense_tax" to "ພາສີ",
	"expense_training" to "ການຝຶກອົບຮົມ",
	"expense_transport" to "ການຂົນສົ່ງ",
	"expense_travel" to "ທ່ອງ​ທ່ຽວ",
	"expense_utility" to "ປະໂຫຍດ",
	"expenses" to "ຄ່າໃຊ້ຈ່າຍ",
	"feedback_option" to "ຜົນຕອບຮັບ",
	"filter" to "ການກັ່ນຕອງ",
	"first_name_hint" to "ຊື່​ແທ້",
	"forgot_password" to "ຣີເຊັດລະຫັດຜ່ານ",
	"forgot_password_label" to "ລືມລະຫັດຜ່ານ",
	"from" to "ຈາກ",
	"general_error" to "ເກີດຄວາມຜິດພາດຂຶ້ນ, ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ.",
	"general_ledger" to "general ledger",
	"go_back_label" to "ກັບ​ຄືນ",
	"group_by" to "ກຸ່ມໂດຍ:",
	"image_view" to "ຮູບພາບ",
	"install" to "ຕິດຕັ້ງ",
	"invalid_credentials_error" to "ຂໍ້ມູນປະຈຳຕົວບໍ່ຖືກຕ້ອງ.",
	"invalid_email_error" to "ອີເມວບໍ່ຖືກຕ້ອງ.",
	"invalid_user_error" to "ຂໍ້ມູນປະຈຳຕົວຜູ້ໃຊ້ບໍ່ຖືກຕ້ອງ.",
	"invoice_deleted_message" to "ໃບແຈ້ງໜີ້ຖືກລຶບແລ້ວ",
	"invoice_menu" to "ເມນູໃບເກັບເງິນ",
	"invoice_number" to "ໃບແຈ້ງໜີ້ #",
	"invoices" to "ໃບແຈ້ງໜີ້",
	"items_option" to "ລາຍການ",
	"keep" to "ຮັກສາ",
	"language_selector" to Formattable { (selected) -> "ພາສາ ${selected}" },
	"last_name_hint" to "ນາມ​ສະ​ກຸນ",
	"ledger" to "ledger",
	"legal" to "ທາງດ້ານກົດໝາຍ",
	"legal_error" to "ຕ້ອງຍອມຮັບເງື່ອນໄຂການໃຫ້ບໍລິການ ແລະນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ",
	"liabilities" to "liabilities",
	"loading" to "ກຳລັງໂຫຼດ",
	"login_required_error" to "ຕ້ອງການເຂົ້າສູ່ລະບົບ.",
	"logo_view" to "ໂລໂກ້",
	"logout" to "ອອກ​ຈາກ​ລະ​ບົບ",
	"logout_prompt" to "ຂໍ້ມູນຂອງທ່ານບໍ່ໄດ້ຖືກ synchronized ກັບຄລາວ. ລຶບໃບແຈ້ງໜີ້, ການຄາດຄະເນ, ລູກຄ້າ ແລະຂໍ້ມູນອື່ນໆໃນເວລາອອກຈາກລະບົບບໍ?",
	"main_screen" to "ໜ້າຈໍຫຼັກ",
	"mark_paid" to "ໝາຍວ່າຈ່າຍແລ້ວ",
	"message" to "ຂໍ້ຄວາມ",
	"missing_sender_msg" to "ເອກະສານທີ່ບໍ່ມີຂໍ້ມູນຜູ້ສົ່ງອາດຈະບໍ່ຖືກຕ້ອງ.",
	"missing_sender_title" to "ບໍ່ມີຂໍ້ມູນຜູ້ສົ່ງ",
	"month_april" to "ເດືອນເມສາ",
	"month_april_short" to "ເດືອນເມສາ",
	"month_august" to "ສິງຫາ",
	"month_august_short" to "ສິງຫາ",
	"month_december" to "ເດືອນທັນວາ",
	"month_december_short" to "ທັນວາ",
	"month_february" to "ເດືອນກຸມພາ",
	"month_february_short" to "ກຸມພາ",
	"month_january" to "ມັງກອນ",
	"month_january_short" to "ມັງກອນ",
	"month_july" to "ກໍລະກົດ",
	"month_july_short" to "ກໍລະກົດ",
	"month_june" to "ເດືອນມິຖຸນາ",
	"month_june_short" to "ເດືອນມິຖຸນາ",
	"month_march" to "ມີນາ",
	"month_march_short" to "ມີນາ",
	"month_may" to "ພຶດສະພາ",
	"month_may_short" to "ພຶດສະພາ",
	"month_november" to "ເດືອນພະຈິກ",
	"month_november_short" to "ພະຈິກ",
	"month_october" to "ຕຸລາ",
	"month_october_short" to "ຕຸລາ",
	"month_september" to "ກັນຍາ",
	"month_september_short" to "ກັນຍາ",
	"multifactor_error" to "ລົ້ມເຫລວໃນການກວດສອບ.",
	"new" to "ໃຫມ່",
	"news" to "ຂ່າວ",
	"next" to "ຕໍ່ໄປ",
	"no" to "no",
	"no_data_available" to "ບໍ່ມີຂໍ້ມູນ",
	"no_search_results" to "ຂໍອະໄພ, ພວກເຮົາບໍ່ສາມາດຊອກຫາຜົນໄດ້ຮັບໃດໆ.",
	"none" to "ບໍ່ມີ",
	"note_fields" to "ບັນທຶກ",
	"note_hint" to "ບັນທຶກ",
	"notifications_option" to "ການແຈ້ງເຕືອນ",
	"ok" to "ຕົກລົງ",
	"onboarding" to "ການຂຶ້ນເຮືອບິນ",
	"onboarding_aboutus_message" to "ພາກສ່ວນທີ່ສຳຄັນຂອງ DingVoice ແມ່ນບໍ່ເສຍຄ່າຕະຫຼອດໄປ. ພາລະກິດຂອງພວກເຮົາແມ່ນເພື່ອຊ່ວຍໃຫ້ທ່ານຂະຫຍາຍທຸລະກິດຂອງທ່ານ, ຊ່ວຍພວກເຮົາປັບປຸງໂດຍການແບ່ງປັນຄວາມຄິດເຫັນຂອງທ່ານ.",
	"onboarding_aboutus_title" to "ພວກເຮົາຢູ່ທີ່ນີ້ເພື່ອຊ່ວຍ",
	"onboarding_paid_1" to "ເຂົ້າເຖິງແມ່ແບບທີ່ເປັນມືອາຊີບ",
	"onboarding_paid_2" to "ເບິ່ງຢ່າງເລິກເຊິ່ງກ່ຽວກັບການເງິນຂອງເຈົ້າ",
	"onboarding_paid_3" to "ໄດ້​ຮັບ​ການ​ແກ້​ໄຂ​ບັນ​ຫາ​ໄວ​",
	"onboarding_paid_4" to "ຮັກສາເອກະສານຂອງເຈົ້າໃຫ້ປອດໄພ",
	"onboarding_paid_5" to "ຕິດຕາມການປ່ຽນແປງສະຖານະໃບແຈ້ງໜີ້",
	"onboarding_paid_title" to "ເຕີບໃຫຍ່ໄວຂຶ້ນດ້ວຍ...",
	"onboarding_paywall_message" to "ເພີດເພີນໄປກັບຄຸນສົມບັດພຣີມຽມທັງໝົດຕອນນີ້ໃນລາຄາທີ່ດີທີ່ສຸດ.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "ພຽງແຕ່ ${currencySymbol}${introAmount} ສໍາລັບ ${introDuration} ${introPeriod} ແລະຫຼັງຈາກນັ້ນ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "ພຽງແຕ່ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "ຟຣີສໍາລັບ ${trialDuration} ${trialPeriod} ແລະຫຼັງຈາກນັ້ນພຽງແຕ່ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "ຟຣີສໍາລັບ ${trialDuration} ${trialPeriod} ຈາກນັ້ນ ${currencySymbol}${introductoryAmount} ສໍາລັບ ${introductoryDuration} ${introPeriod} ແລະຈາກນັ້ນພຽງແຕ່ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "ສາມາດຕໍ່ອາຍຸໄດ້ໂດຍອັດຕະໂນມັດ. ຍົກເລີກໄດ້ທຸກເວລາ.",
	"onboarding_paywall_restore" to "ຟື້ນຟູການຊື້",
	"onboarding_paywall_restore_failure" to "ການຟື້ນຟູການຊື້ລົ້ມເຫລວ, ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ ຫຼືຕິດຕໍ່ຝ່າຍຊ່ວຍເຫຼືອ.",
	"onboarding_paywall_restore_success" to "ຟື້ນຟູສຳເລັດແລ້ວ!",
	"onboarding_paywall_title" to "ບັນລຸຄວາມຍິ່ງໃຫຍ່!",
	"onboarding_welcome_message" to "ຊຸດເຄື່ອງມືທີ່ສົມບູນເພື່ອຈັດການໃບແຈ້ງໜີ້ ແລະ ການຄາດຄະເນຂອງທ່ານ.",
	"onboarding_welcome_title" to "ຍິນດີຕ້ອນຮັບສູ່ DingVoice",
	"other_state" to "ອື່ນໆ",
	"overdue_state" to "ເກີນກຳນົດ",
	"paid_state" to "ຈ່າຍແລ້ວ",
	"password" to "ລະຫັດຜ່ານ",
	"password_blank_error" to "ຕ້ອງການລະຫັດຜ່ານ.",
	"password_mismatch_error" to "ລະຫັດຜ່ານບໍ່ກົງກັນ.",
	"pay" to "pay",
	"payment_instructions_label" to "ຄໍາແນະນໍາການຈ່າຍເງິນ",
	"payment_reminders" to "ເຕືອນການຈ່າຍເງິນ",
	"phone_number_fields" to "ເບີ​ໂທລະ​ສັບ",
	"phone_number_hint" to "ເບີ​ໂທລະ​ສັບ",
	"postcode_hint" to "ລະຫັດໄປສະນີ",
	"preview" to "ເບິ່ງຕົວຢ່າງ",
	"preview_menu_hint" to "ເມນູຕົວຢ່າງ",
	"price" to "ລາຄາ",
	"privacy_policy" to "ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ",
	"profile" to "ໂປຣໄຟລ໌",
	"profitable_client" to "ລູກຄ້າທີ່ມີກໍາໄລຫຼາຍທີ່ສຸດຂອງທ່ານແມ່ນ ...",
	"profits_report" to "ຜົນກຳໄລ",
	"promo_content" to "ເນື້ອຫາສົ່ງເສີມ",
	"quantity_hint" to "ປະລິມານ",
	"quantity_label_hint" to "ປ້າຍກຳກັບປະລິມານ",
	"query_info" to "ການສອບຖາມອະນຸຍາດໃຫ້ທ່ານຂຽນການຄິດໄລ່ແບບກໍານົດເອງໂດຍອ້າງອີງໃສ່ທັງສອງລາຍການແລະກົດລະບຽບພາສີອື່ນໆເປັນວັດສະດຸປ້ອນ. ເພີ່ມ @ ສັນ​ຍາ​ລັກ​ແລະ​ລາຍ​ການ​ຫຼື​ລາຍ​ລະ​ອຽດ​ພາ​ສີ​ເພື່ອ​ເຊື່ອມ​ຕໍ່​ກັບ​ການ​ເຂົ້າ​ອື່ນ +, -, *, /, % - math operators",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ໄດ້ຮັບໂດຍລູກຄ້າຂອງທ່ານ." },
	"received_state" to "ໄດ້ຮັບ",
	"registration_code_fields" to "ລະຫັດການລົງທະບຽນ",
	"reminder" to "ເຕືອນ",
	"reports" to "ບົດລາຍງານ",
	"reports_not_available" to "ບໍ່ມີລາຍງານ, ກັບມາໃໝ່ໃນພາຍຫຼັງ.",
	"reveal_password" to "ເປີດເຜີຍລະຫັດຜ່ານ",
	"revenue" to "revenue",
	"sales_tax" to "ອາກອນມູນຄ່າເພີ່ມ",
	"sales_tax_number" to "ເລກ VAT",
	"save" to "ຊ່ວຍປະຢັດ",
	"scan_document" to "ສະແກນເອກະສານ",
	"search" to "ຄົ້ນຫາ",
	"select" to "ເລືອກ",
	"select_icon" to "ເລືອກໄອຄອນ",
	"select_signature" to "ເລືອກລາຍເຊັນ",
	"select_template" to "ເລືອກແມ່ແບບ",
	"send" to "ສົ່ງ",
	"sent_state" to "ສົ່ງແລ້ວ",
	"setup_sender" to "ຕັ້ງຄ່າຜູ້ສົ່ງ",
	"share_option" to "ແບ່ງປັນ",
	"ship_to" to "ສົ່ງ​ໄປ​ທີ່",
	"sign_in" to "ເຂົ້າ​ສູ່​ລະ​ບົບ",
	"sign_in_apple" to "ເຂົ້າສູ່ລະບົບດ້ວຍ Apple",
	"sign_in_email" to "ເຂົ້າສູ່ລະບົບດ້ວຍອີເມວ",
	"sign_in_facebook" to "ເຂົ້າສູ່ລະບົບດ້ວຍ Facebook",
	"sign_in_google" to "ເຂົ້າສູ່ລະບົບດ້ວຍ Google",
	"sign_up" to "ລົງ​ທະ​ບຽນ",
	"sign_up_label" to "ບໍ່ມີບັນຊີບໍ?",
	"sign_up_link_label" to "ລົງ​ທະ​ບຽນ!",
	"signatures_option" to "ລາຍເຊັນ",
	"skip" to "ຂ້າມ",
	"sold_items_report" to "ລາຍການຂາຍ",
	"sort_by" to "ຈັດຮຽງຕາມ:",
	"status_update" to "ອັບເດດສະຖານະເອກະສານ",
	"sub_annual" to "ປະຈໍາປີ",
	"sub_annual_price" to Formattable { (price) -> "${price}/ປີ" },
	"sub_backups" to "Cloud Backups",
	"sub_insights" to "ຄວາມເຂົ້າໃຈ",
	"sub_month" to "ປະຈໍາເດືອນ",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ເດືອນ" },
	"sub_notifications" to "ການແຈ້ງເຕືອນ",
	"sub_popup_message" to "ການສະໝັກໃຊ້ກຳລັງຈະມາໃນໄວໆນີ້. ກະລຸນາກັບມາເບິ່ງທີ່ນີ້ໃນອີກບໍ່ດົນ.",
	"sub_support" to "ສະໜັບສະໜຸນ",
	"sub_templates" to "ແມ່ແບບ",
	"subscribe" to "ຈອງ",
	"subscription_header" to "ຂະຫຍາຍທຸລະກິດຂອງທ່ານດ້ວຍ:",
	"subscription_option" to "ການສະໝັກໃຊ້",
	"subscriptions_load_fail" to "ຂໍອະໄພ, ພວກເຮົາບໍ່ສາມາດດຶງຂໍ້ມູນການສະໝັກໃຊ້ຂອງທ່ານໄດ້ໃນເວລານີ້. ກະລຸນາລອງໃໝ່ໃນພາຍຫຼັງ ຫຼືຕິດຕໍ່ທີມງານຊ່ວຍເຫຼືອຂອງພວກເຮົາເພື່ອຂໍຄວາມຊ່ວຍເຫຼືອ.",
	"subtotal" to Formattable { (sum) -> "ທັງໝົດຍ່ອຍ: ${sum}" },
	"subtotal_label" to "ທັງໝົດຍ່ອຍ",
	"sum" to "ລວມ",
	"support_option" to "ສະຫນັບສະຫນູນ",
	"switch_reports" to "ສະຫຼັບລາຍງານ",
	"tax" to "ພາສີ",
	"tax_deleted_message" to "ລຶບພາສີແລ້ວ",
	"tax_description_warning" to "ຖ້າໃຊ້, ນີ້ສະແດງຢູ່ໃນໃບແຈ້ງຫນີ້",
	"tax_input_flat" to "ຮາບພຽງ",
	"tax_input_percent" to "ເປີເຊັນ",
	"tax_number_fields" to "ໝາຍເລກພາສີ",
	"tax_number_hint" to "ໝາຍເລກພາສີ",
	"tax_query_info_description" to "ຂໍ້ມູນການສອບຖາມ",
	"tax_report" to "ພາສີ",
	"tax_rules_option" to "ກົດລະບຽບພາສີ",
	"taxed_clients_report" to "ລູກຄ້າເສຍພາສີ",
	"template" to "ແມ່ແບບ",
	"template_selector_info" to "ປັດເພື່ອປ່ຽນແມ່ແບບເລີ່ມຕົ້ນ",
	"template_unlock_promo" to "ເຂົ້າເຖິງແມ່ແບບເພີ່ມເຕີມດ້ວຍ Premium",
	"templates_option" to "ແມ່ແບບ",
	"terms_of_service" to "ເງື່ອນໄຂການໃຫ້ບໍລິການ",
	"thank_you" to "ຂອບ​ໃຈ",
	"to" to "ເຖິງ",
	"total" to Formattable { (sum) -> "ທັງໝົດ: ${sum}" },
	"total_label" to "TOTAL",
	"undo_action" to "ຍົກເລີກ",
	"unit" to "ໜ່ວຍ",
	"unit_cost_hint" to "ຄ່າໃຊ້ຈ່າຍຕໍ່ຫນ່ວຍ",
	"unknown_client" to "ລູກຄ້າທີ່ບໍ່ຮູ້ຈັກ",
	"unlock" to "ປົດລັອກ",
	"unnamed_item" to "ລາຍການທີ່ບໍ່ມີຊື່",
	"upgrade" to "ຍົກລະດັບ",
	"user_collision_error" to "ມີບັນຊີຢູ່ແລ້ວ.",
	"valid_until" to "ໃຊ້ໄດ້ຈົນກ່ວາ",
	"warning" to "ເຕືອນໄພ!",
	"yes" to "yes",
    )
    
}