// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class De  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Akzeptieren Sie ${termsOfService} und ${privacyPolicy}" }
override val account: String = "Konto"
override val accounting: String = "Buchhaltung"
override val accruals: String = "Rückstellungen"
override val action_code_error: String = "Ungültiger Code."
override val add_client_action: String = "Kunde hinzufügen"
override val add_customer: String = "Kunde hinzufügen"
override val add_expense: String = "Ausgaben hinzufügen"
override val add_fields_label: String = "Hinzufügen"
override val add_invoice_action: String = "erstellen."
override val add_item_action: String = "Artikel hinzufügen"
override val add_item_button: String = "Artikel hinzufügen"
override val add_signature_action: String = "Unterschrift hinzufügen"
override val add_tax_action: String = "Steuer hinzufügen"
override val address_fields: String = "Adresse"
override val address_line_hint: String = "Adress-Zeile"
override val advanced: String = "Erweitert"
override val agree_marketing: String = "Ich bin damit einverstanden, Direktmarketing-Mitteilungen zu erhalten"
override val all_notifications: String = "Alle Benachrichtigungen"
override val amortization: String = "Amortisation"
override val amount: String = "Betrag"
override val asset: String = "Gewinn"
override val attach_file: String = "Datei Anhängen"
override val attachment: String = "Anhang"
override val auth_error: String = "Aktion fehlgeschlagen, versuchen Sie es erneut."
override val available_for_paid: String = "Die Cloud-Synchronisierung ist nur im Rahmen eines kostenpflichtigen Abonnements verfügbar."
override val backups_option: String = "Backups"
override val balance: String = "Saldo"
override val bank_account: String = "Bankkonto"
override val bill_number: String = "Rechnungsnummer"
override val cancel: String = "Abbrechen"
override val cancel_trial: String = "Jederzeit kündbar"
override val capital: String = "Kapital"
override val cash: String = "Bargeld"
override val categories: String = "Kategorien"
override val category: String = "Kategorie"
override val category_add: String = "Kategorie hinzufügen"
override val category_delete: String = "Kategorie löschen"
override val category_name: String = "Kategorie-Name"
override val change_language: Formattable = Formattable { (language) -> "Sprache der Rechnung: ${language}" }
override val change_language_label: String = "Rechnungssprache"
override val change_template: String = "Vorlage ändern"
override val city_hint: String = "Stadt"
override val clear: String = "Klar"
override val client: String = "Kunde"
override val client_actions: String = "Aktionen des Kunden"
override val client_deleted_message: String = "Kunde gelöscht"
override val clients_error_required_fields: String = "Mindestens ein Wert ist erforderlich."
override val clients_option: String = "Kunden"
override val close: String = "Schließen"
override val cloud_sync: String = "Cloud-Synchronisation"
override val coming_soon: String = "Demnächst!"
override val company_logo: String = "Firmenlogo"
override val company_name_hint: String = "Firma"
override val confirm_password_label: String = "Bestätigen Sie das Passwort"
override val confirmation_title: String = "Sind Sie sicher ?"
override val convert_to_invoice: String = "In Rechnung umwandeln"
override val country_hint: String = "Land"
override val create_client: String = "Client erstellen"
override val create_company: String = "Firma erstellen"
override val create_document: String = "Dokument erstellen"
override val create_estimate: String = "Schätzung"
override val create_invoice: String = "Rechnung"
override val create_item: String = "Element erstellen"
override val create_new_client: String = "Neuen Kunden anlegen"
override val create_new_item: String = "Neues Element erstellen"
override val create_new_tax: String = "Neue Steuer erstellen"
override val create_signature: String = "Unterschrift"
override val created: String = "Erstellt"
override val credit: String = "Guthaben"
override val customer: String = "Kunde"
override val date: String = "Datum"
override val debit: String = "Soll"
override val debt: String = "Schulden"
override val default_currency: Formattable = Formattable { (currency) -> "Standardwährung: ${currency}" }
override val default_currency_label: String = "Standardwährung"
override val delete: String = "Löschen"
override val delete_account: String = "Konto löschen"
override val delete_confirmation_message: String = "Alle Ihre Daten gehen verloren."
override val delete_item_title: String = "Den Artikel löschen?"
override val delete_signature: String = "Unterschrift löschen"
override val depreciation: String = "Abschreibung"
override val description: String = "Beschreibung"
override val description_hint: String = "Beschreibung"
override val detailed_report: String = "Ausführlicher Bericht"
override val disabled: String = "Deaktiviert"
override val discount: String = "Rabatt"
override val discount_hint: String = "Rabatt"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharisch"
override val display_ar: String = "Arabisch"
override val display_as: String = "Assamisch"
override val display_az: String = "Aserbaidschanisch"
override val display_be: String = "Weißrussisch"
override val display_bg: String = "Bulgarisch"
override val display_bn: String = "Bengalisch"
override val display_bs: String = "Bosnisch"
override val display_ca: String = "Katalanisch"
override val display_cs: String = "Tschechisch"
override val display_da: String = "Dänisch"
override val display_de: String = "Deutsch"
override val display_el: String = "Griechisch"
override val display_en: String = "Englisch"
override val display_es: String = "Spanisch"
override val display_es_MX: String = "Spanisch (Mexiko)"
override val display_et: String = "Estnisch"
override val display_eu: String = "Baskisch"
override val display_fa: String = "Persisch"
override val display_fi: String = "Finnisch"
override val display_fil: String = "Filipino"
override val display_fr: String = "Französisch"
override val display_fr_CA: String = "Kanadisches Französisch"
override val display_gl: String = "Galizisch"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hebräisch"
override val display_hi: String = "Hindi"
override val display_hr: String = "Kroatisch"
override val display_hu: String = "Ungarisch"
override val display_hy: String = "Armenisch"
override val display_id: String = "Indonesisch"
override val display_is: String = "Isländisch"
override val display_it: String = "Italienisch"
override val display_ja: String = "Japanisch"
override val display_ka: String = "Georgisch"
override val display_kk: String = "Kasachstan"
override val display_km: String = "Zentral-Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Koreanisch"
override val display_ky: String = "kirgisisch"
override val display_lo: String = "Laos"
override val display_lt: String = "Litauisch"
override val display_lv: String = "Lettisch"
override val display_mk: String = "Mazedonisch"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolei"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malaiisch"
override val display_my: String = "Birmanisch"
override val display_nb: String = "Norwegisch Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Niederländisch"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polnisch"
override val display_pt: String = "Portugiesisch"
override val display_pt_BR: String = "Portugiesisch (Brasilien)"
override val display_pt_PT: String = "Europäisches Portugiesisch"
override val display_ro: String = "Rumänisch"
override val display_ru: String = "Russisch"
override val display_si: String = "Singhalesisch"
override val display_sk: String = "Slowakisch"
override val display_sl: String = "Slowenisch"
override val display_sq: String = "Albanisch"
override val display_sr: String = "Serbisch"
override val display_sv: String = "Schwedisch"
override val display_sw: String = "Suaheli"
override val display_ta: String = "Tamilisch"
override val display_te: String = "Telugu"
override val display_th: String = "Thailändisch"
override val display_tr: String = "Türkisch"
override val display_uk: String = "Ukrainisch"
override val display_ur: String = "Urdu"
override val display_uz: String = "Usbekisch"
override val display_vi: String = "Vietnamesisch"
override val display_zh_Hant_TW: String = "Chinesisch (Taiwan, Traditionell)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Schätzung"
override val document_invoice: String = "Rechnung"
override val document_quote: String = "Angebot"
override val done: String = "Erledigt"
override val download_app: String = "Die App herunterladen"
override val download_invoice: String = "Rechnung herunterladen"
override val download_pdf: String = "PDF herunterladen"
override val draw_signature: String = "Zeichnen Sie eine Unterschrift"
override val due: Formattable = Formattable { (date) -> "Fällig ${date}" }
override val due_date: String = "Fälligkeitsdatum"
override val due_day: String = "in einem Tag"
override val due_label: String = ""
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ist fällig ${dueWhen}!" }
override val due_today: String = "heute"
override val due_week: String = "in einer Woche"
override val duplicate: String = "Duplikat"
override val duration_day: String = "Tag"
override val duration_month: String = "Monat"
override val duration_week: String = "Woche"
override val duration_year: String = "Jahr"
override val email: String = "E-Mail"
override val email_blank_error: String = "E-Mail erforderlich."
override val email_error: String = "Ungültige E-Mail Adresse."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Wir danken Ihnen für Ihr Vertrauen. Um das Dokument ${documentType} vollständig anzuzeigen, klicken Sie auf die Schaltfläche am Ende dieses Schreibens. Beachten Sie, dass dieser Brief bis zum ${dueDate} gültig ist." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Sie haben eine neue ${documentType} ${invoiceNumber} erhalten!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "Rechnung ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (Kundenname) -> "Lieber ${Kundenname}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Wir danken Ihnen für Ihr Vertrauen. Für die vollständige Ansicht von ${documentType} klicken Sie auf die Schaltfläche am Ende dieses Schreibens. Bitte zahlen Sie den Restbetrag bis zum ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Sie haben eine neue ${documentType} ${invoiceNumber} erhalten!" }
override val email_reset_success_message: String = "E-Mail zum Zurücksetzen des Passworts gesendet, bitte überprüfen Sie Ihren Posteingang."
override val empty_clients_list_message: String = "Sie haben keine Kunden zu verfolgen. Fügen Sie einen neuen Kunden hinzu."
override val empty_estimate_list_message: String = "Erstellen Sie Ihren ersten Kostenvoranschlag"
override val empty_expenses: String = "Sie haben keine Ausgaben zu verfolgen. Fügen Sie eine neue Ausgabe hinzu."
override val empty_invoice_list_message: String = "Erstellen Sie Ihre erste Rechnung"
override val empty_items_list_message: String = "Sie haben keine Artikel, fügen Sie einen neuen Artikel hinzu!"
override val empty_signatures_list_message: String = "Keine Signaturen gefunden. Erstellen Sie eine!"
override val empty_taxes_list_message: String = "Sie haben keine Steuerregeln. Fügen Sie eine neue Steuerregel hinzu."
override val empty_templates_list_message: String = "Vorlagen sind nicht verfügbar."
override val enabled: String = "Aktiviert"
override val entry: String = "Eintrag"
override val equity: String = "Eigenkapital"
override val estimate_number: String = "Schätzung #"
override val estimates: String = "Schätzungen"
override val estimates_report: String = "Schätzungen"
override val expense_bank_fees: String = "Bankgebühren"
override val expense_benefits: String = "Vorteile"
override val expense_communication: String = "Kommunikation"
override val expense_debt: String = "Schuld"
override val expense_deleted: String = "Ausgaben gelöscht"
override val expense_depreciation: String = "Abschreibung"
override val expense_gifts: String = "Geschenke"
override val expense_insurance: String = "Versicherung"
override val expense_inventory: String = "Inventar"
override val expense_legal: String = "Rechtliches"
override val expense_maintenance: String = "Wartung"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Mahlzeiten"
override val expense_office_supplies: String = "Bürobedarf"
override val expense_rent: String = "Miete"
override val expense_salaries: String = "Gehälter"
override val expense_shopping: String = "Einkaufen"
override val expense_software: String = "Software"
override val expense_status_available: String = "Verfügbar"
override val expense_status_processing: String = "Verarbeitung"
override val expense_tax: String = "Steuer"
override val expense_training: String = "Training"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Reisen"
override val expense_utility: String = "Nützlichkeit"
override val expenses: String = "Ausgaben"
override val feedback_option: String = "Rückmeldung"
override val filter: String = "Filter"
override val first_name_hint: String = "Vorname"
override val forgot_password: String = "Passwort zurücksetzen"
override val forgot_password_label: String = "Passwort vergessen"
override val from: String = "Von"
override val general_error: String = "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."
override val general_ledger: String = "Hauptbuch"
override val go_back_label: String = "Zurückgehen"
override val group_by: String = "Gruppe von:"
override val image_view: String = "Bild"
override val install: String = "Installieren"
override val invalid_credentials_error: String = "Ungültige Anmeldedaten."
override val invalid_email_error: String = "Ungültige E-Mail"
override val invalid_user_error: String = "Ungültige Benutzeranmeldedaten."
override val invoice_deleted_message: String = "Rechnung gelöscht"
override val invoice_menu: String = "Menü Rechnungen"
override val invoice_number: String = "Invoice #"
override val invoices: String = "Rechnungen"
override val items_option: String = "Artikel"
override val keep: String = "Behalten Sie"
override val language_selector: Formattable = Formattable { (selected) -> "Sprache ${selected}" }
override val last_name_hint: String = "Nachname"
override val ledger: String = "Kontenblatt"
override val legal: String = "Rechtliches"
override val legal_error: String = "Muss Nutzungsbedingungen und Datenschutzbestimmungen akzeptieren"
override val liabilities: String = "Verbindlichkeiten"
override val loading: String = "Laden"
override val login_required_error: String = "Anmeldung erforderlich."
override val logo_view: String = "Paketlogo"
override val logout: String = "Abmelden"
override val logout_prompt: String = "Ihre Daten werden nicht mit der Cloud synchronisiert. Rechnungen, Kostenvoranschläge, Kunden und andere Informationen beim Abmelden löschen?"
override val main_screen: String = "Hauptbildschirm"
override val mark_paid: String = "Marke als bezahlt"
override val message: String = "Nachricht"
override val missing_sender_msg: String = "Ein Dokument ohne Absenderinformationen ist möglicherweise ungültig."
override val missing_sender_title: String = "Fehlende Senderinformationen"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "August"
override val month_august_short: String = "Aug."
override val month_december: String = "Dezember"
override val month_december_short: String = "Dez."
override val month_february: String = "Februar"
override val month_february_short: String = "Feb."
override val month_january: String = "Januar"
override val month_january_short: String = "Jan."
override val month_july: String = "Juli"
override val month_july_short: String = "Juli"
override val month_june: String = "Juni"
override val month_june_short: String = "Juni"
override val month_march: String = "März"
override val month_march_short: String = "März"
override val month_may: String = "Mai"
override val month_may_short: String = "Mai"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktober"
override val month_october_short: String = "Okt."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Verifizierung fehlgeschlagen."
override val new: String = "Neu"
override val news: String = "Nachrichten"
override val next: String = "Weiter"
override val no: String = "nein"
override val no_data_available: String = "Keine Daten verfügbar"
override val no_search_results: String = "Leider konnten wir keine Ergebnisse finden."
override val none: String = "Keine"
override val note_fields: String = "Notizen"
override val note_hint: String = "Notiz"
override val notifications_option: String = "Benachrichtigungen"
override val ok: String = "OK"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Wesentliche Teile von DingVoice sind für immer kostenlos. Unsere Mission ist es, Ihnen zu helfen, Ihr Geschäft zu erweitern. Helfen Sie uns, uns zu verbessern, indem Sie Ihr Feedback teilen."
override val onboarding_aboutus_title: String = "Wir sind hier, um zu helfen"
override val onboarding_paid_1: String = "Zugang zu professionell aussehenden Vorlagen"
override val onboarding_paid_2: String = "Werfen Sie einen genaueren Blick auf Ihre Finanzen"
override val onboarding_paid_3: String = "Probleme schnell lösen"
override val onboarding_paid_4: String = "Bewahren Sie Ihre Dokumente sicher auf"
override val onboarding_paid_5: String = "Bleiben Sie über Änderungen des Rechnungsstatus auf dem Laufenden"
override val onboarding_paid_title: String = "Wachsen Sie noch schneller mit..."
override val onboarding_paywall_message: String = "Genießen Sie jetzt alle Premium-Funktionen zum besten Preis."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Nur ${currencySymbol}${introAmount} für ${introDuration} ${introPeriod} und dann ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, Betrag, Laufzeit) -> "Nur ${currencySymbol}${Betrag} / ${Laufzeit}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Kostenlos für ${trialDuration} ${trialPeriod} und dann nur noch ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Kostenlos für ${trialDuration} ${trialPeriod}, dann ${currencySymbol}${introductoryAmount} für ${introductoryDuration} ${introPeriod} und dann nur ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automatisch verlängerbar. Jederzeit kündbar."
override val onboarding_paywall_restore: String = "Kauf wiederherstellen"
override val onboarding_paywall_restore_failure: String = "Die Wiederherstellung der Einkäufe ist fehlgeschlagen. Versuchen Sie es später noch einmal oder wenden Sie sich an den Support."
override val onboarding_paywall_restore_success: String = "Wiederherstellung erfolgreich!"
override val onboarding_paywall_title: String = "Erreichen Sie Großartiges!"
override val onboarding_welcome_message: String = "Ein komplettes Toolset zur Verwaltung Ihrer Rechnungen und Kostenvoranschläge."
override val onboarding_welcome_title: String = "Willkommen bei DingVoice"
override val other_state: String = "Andere"
override val overdue_state: String = "Überfällig"
override val paid_state: String = "Bezahlt"
override val password: String = "Passwort"
override val password_blank_error: String = "Passwort erforderlich."
override val password_mismatch_error: String = "Die Kennwörter stimmen nicht überein."
override val pay: String = "bezahlen"
override val payment_instructions_label: String = "Zahlungsanweisungen"
override val payment_reminders: String = "Zahlungserinnerungen"
override val phone_number_fields: String = "Telefonnummer"
override val phone_number_hint: String = "Telefonnummer"
override val postcode_hint: String = "Postleitzahl"
override val preview: String = "Vorschau"
override val preview_menu_hint: String = "Menü Vorschau"
override val price: String = "Preis"
override val privacy_policy: String = "Hinweise zum Datenschutz"
override val profile: String = "Profil"
override val profitable_client: String = "Ihr profitabelster Kunde ist..."
override val profits_report: String = "Gewinne"
override val promo_content: String = "Werblicher Inhalt"
override val quantity_hint: String = "Menge"
override val quantity_label_hint: String = "Menge Etikett"
override val query_info: String = "Die Abfrage ermöglicht es Ihnen, benutzerdefinierte Berechnungen zu erstellen, die sich sowohl auf Artikel als auch auf andere Steuerregeln als Eingaben beziehen. Fügen Sie das @-Symbol und eine Artikel- oder Steuerbeschreibung hinzu, um einen Link zu einem anderen Eintrag zu erstellen. +, -, *, /, % - mathematische Operatoren"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} wurde von Ihrem Client empfangen." }
override val received_state: String = "Empfangen"
override val registration_code_fields: String = "Registrierungscode"
override val reminder: String = "Erinnerung"
override val reports: String = "Berichte"
override val reports_not_available: String = "Keine Berichte verfügbar, kommen Sie später wieder."
override val reveal_password: String = "Passwort aufdecken"
override val revenue: String = "Einnahmen"
override val sales_tax: String = "Umsatzsteuer"
override val sales_tax_number: String = "Umsatzsteuer-Nummer"
override val save: String = "Sparen"
override val scan_document: String = "Dokument scannen"
override val search: String = "Suche"
override val select: String = "Auswählen"
override val select_icon: String = "Symbol auswählen"
override val select_signature: String = "Unterschrift auswählen"
override val select_template: String = "Vorlage auswählen"
override val send: String = "Senden"
override val sent_state: String = "Gesendet"
override val setup_sender: String = "Absender einrichten"
override val share_option: String = "Teilen"
override val ship_to: String = "Versand nach"
override val sign_in: String = "Anmelden"
override val sign_in_apple: String = "Mit Apple anmelden"
override val sign_in_email: String = "Mit E-Mail anmelden"
override val sign_in_facebook: String = "Mit Facebook anmelden"
override val sign_in_google: String = "Mit Google anmelden"
override val sign_up: String = "Anmelden"
override val sign_up_label: String = "Sie haben noch kein Konto?"
override val sign_up_link_label: String = "Anmelden!"
override val signatures_option: String = "Unterschriften"
override val skip: String = "Überspringen"
override val sold_items_report: String = "Verkaufte Artikel"
override val sort_by: String = "Sortieren nach:"
override val status_update: String = "Aktualisierung des Dokumentenstatus"
override val sub_annual: String = "Jährlich"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/Jahr" }
override val sub_backups: String = "Cloud-Backups"
override val sub_insights: String = "Einblicke"
override val sub_month: String = "Monatlich"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/Monat" }
override val sub_notifications: String = "Benachrichtigungen"
override val sub_popup_message: String = "Abonnements sind bald erhältlich. Schauen Sie hier in Kürze wieder vorbei."
override val sub_support: String = "Unterstützung"
override val sub_templates: String = "Vorlagen"
override val subscribe: String = "Abonnieren"
override val subscription_header: String = "Wachsen Sie mit Ihrem Unternehmen:"
override val subscription_option: String = "Abonnement"
override val subscriptions_load_fail: String = "Es tut uns leid, wir können Ihre Abonnements zur Zeit nicht abrufen. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an unser Support-Team, um Hilfe zu erhalten."
override val subtotal: Formattable = Formattable { (sum) -> "Zwischensumme: ${sum}" }
override val subtotal_label: String = "Zwischensumme"
override val sum: String = "Summe"
override val support_option: String = "Unterstützung"
override val switch_reports: String = "Bericht wechseln"
override val tax: String = "STEUER"
override val tax_deleted_message: String = "Steuer gestrichen"
override val tax_description_warning: String = "Bei Verwendung wird dies auf einer Rechnung ausgewiesen"
override val tax_input_flat: String = "Wohnung"
override val tax_input_percent: String = "Prozentsatz"
override val tax_number_fields: String = "Steuernummer"
override val tax_number_hint: String = "Steuernummer"
override val tax_query_info_description: String = "Informationen abfragen"
override val tax_report: String = "Steuer"
override val tax_rules_option: String = "Steuerliche Vorschriften"
override val taxed_clients_report: String = "Versteuerte Kunden"
override val template: String = "Vorlage"
override val template_selector_info: String = "Wischen, um die Standardvorlage zu ändern"
override val template_unlock_promo: String = "Zugriff auf mehr Vorlagen mit Premium"
override val templates_option: String = "Vorlagen"
override val terms_of_service: String = "Bedingungen für den Service"
override val thank_you: String = "Vielen Dank!"
override val to: String = "An"
override val total: Formattable = Formattable { (sum) -> "Gesamt: ${sum}" }
override val total_label: String = "GESAMT"
override val undo_action: String = "Rückgängig machen"
override val unit: String = "Gerät"
override val unit_cost_hint: String = "Kosten pro Einheit"
override val unknown_client: String = "Unbekannter Kunde"
override val unlock: String = "freischalten"
override val unnamed_item: String = "Unbenanntes Element"
override val upgrade: String = "Upgrade"
override val user_collision_error: String = "Das Konto existiert bereits."
override val valid_until: String = "Gültig bis"
override val warning: String = "Warnung!"
override val yes: String = "ja"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Akzeptieren Sie ${termsOfService} und ${privacyPolicy}" },
	"account" to "Konto",
	"accounting" to "Buchhaltung",
	"accruals" to "Rückstellungen",
	"action_code_error" to "Ungültiger Code.",
	"add_client_action" to "Kunde hinzufügen",
	"add_customer" to "Kunde hinzufügen",
	"add_expense" to "Ausgaben hinzufügen",
	"add_fields_label" to "Hinzufügen",
	"add_invoice_action" to "erstellen.",
	"add_item_action" to "Artikel hinzufügen",
	"add_item_button" to "Artikel hinzufügen",
	"add_signature_action" to "Unterschrift hinzufügen",
	"add_tax_action" to "Steuer hinzufügen",
	"address_fields" to "Adresse",
	"address_line_hint" to "Adress-Zeile",
	"advanced" to "Erweitert",
	"agree_marketing" to "Ich bin damit einverstanden, Direktmarketing-Mitteilungen zu erhalten",
	"all_notifications" to "Alle Benachrichtigungen",
	"amortization" to "Amortisation",
	"amount" to "Betrag",
	"asset" to "Gewinn",
	"attach_file" to "Datei Anhängen",
	"attachment" to "Anhang",
	"auth_error" to "Aktion fehlgeschlagen, versuchen Sie es erneut.",
	"available_for_paid" to "Die Cloud-Synchronisierung ist nur im Rahmen eines kostenpflichtigen Abonnements verfügbar.",
	"backups_option" to "Backups",
	"balance" to "Saldo",
	"bank_account" to "Bankkonto",
	"bill_number" to "Rechnungsnummer",
	"cancel" to "Abbrechen",
	"cancel_trial" to "Jederzeit kündbar",
	"capital" to "Kapital",
	"cash" to "Bargeld",
	"categories" to "Kategorien",
	"category" to "Kategorie",
	"category_add" to "Kategorie hinzufügen",
	"category_delete" to "Kategorie löschen",
	"category_name" to "Kategorie-Name",
	"change_language" to Formattable { (language) -> "Sprache der Rechnung: ${language}" },
	"change_language_label" to "Rechnungssprache",
	"change_template" to "Vorlage ändern",
	"city_hint" to "Stadt",
	"clear" to "Klar",
	"client" to "Kunde",
	"client_actions" to "Aktionen des Kunden",
	"client_deleted_message" to "Kunde gelöscht",
	"clients_error_required_fields" to "Mindestens ein Wert ist erforderlich.",
	"clients_option" to "Kunden",
	"close" to "Schließen",
	"cloud_sync" to "Cloud-Synchronisation",
	"coming_soon" to "Demnächst!",
	"company_logo" to "Firmenlogo",
	"company_name_hint" to "Firma",
	"confirm_password_label" to "Bestätigen Sie das Passwort",
	"confirmation_title" to "Sind Sie sicher ?",
	"convert_to_invoice" to "In Rechnung umwandeln",
	"country_hint" to "Land",
	"create_client" to "Client erstellen",
	"create_company" to "Firma erstellen",
	"create_document" to "Dokument erstellen",
	"create_estimate" to "Schätzung",
	"create_invoice" to "Rechnung",
	"create_item" to "Element erstellen",
	"create_new_client" to "Neuen Kunden anlegen",
	"create_new_item" to "Neues Element erstellen",
	"create_new_tax" to "Neue Steuer erstellen",
	"create_signature" to "Unterschrift",
	"created" to "Erstellt",
	"credit" to "Guthaben",
	"customer" to "Kunde",
	"date" to "Datum",
	"debit" to "Soll",
	"debt" to "Schulden",
	"default_currency" to Formattable { (currency) -> "Standardwährung: ${currency}" },
	"default_currency_label" to "Standardwährung",
	"delete" to "Löschen",
	"delete_account" to "Konto löschen",
	"delete_confirmation_message" to "Alle Ihre Daten gehen verloren.",
	"delete_item_title" to "Den Artikel löschen?",
	"delete_signature" to "Unterschrift löschen",
	"depreciation" to "Abschreibung",
	"description" to "Beschreibung",
	"description_hint" to "Beschreibung",
	"detailed_report" to "Ausführlicher Bericht",
	"disabled" to "Deaktiviert",
	"discount" to "Rabatt",
	"discount_hint" to "Rabatt",
	"display_af" to "Afrikaans",
	"display_am" to "Amharisch",
	"display_ar" to "Arabisch",
	"display_as" to "Assamisch",
	"display_az" to "Aserbaidschanisch",
	"display_be" to "Weißrussisch",
	"display_bg" to "Bulgarisch",
	"display_bn" to "Bengalisch",
	"display_bs" to "Bosnisch",
	"display_ca" to "Katalanisch",
	"display_cs" to "Tschechisch",
	"display_da" to "Dänisch",
	"display_de" to "Deutsch",
	"display_el" to "Griechisch",
	"display_en" to "Englisch",
	"display_es" to "Spanisch",
	"display_es_MX" to "Spanisch (Mexiko)",
	"display_et" to "Estnisch",
	"display_eu" to "Baskisch",
	"display_fa" to "Persisch",
	"display_fi" to "Finnisch",
	"display_fil" to "Filipino",
	"display_fr" to "Französisch",
	"display_fr_CA" to "Kanadisches Französisch",
	"display_gl" to "Galizisch",
	"display_gu" to "Gujarati",
	"display_he" to "Hebräisch",
	"display_hi" to "Hindi",
	"display_hr" to "Kroatisch",
	"display_hu" to "Ungarisch",
	"display_hy" to "Armenisch",
	"display_id" to "Indonesisch",
	"display_is" to "Isländisch",
	"display_it" to "Italienisch",
	"display_ja" to "Japanisch",
	"display_ka" to "Georgisch",
	"display_kk" to "Kasachstan",
	"display_km" to "Zentral-Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Koreanisch",
	"display_ky" to "kirgisisch",
	"display_lo" to "Laos",
	"display_lt" to "Litauisch",
	"display_lv" to "Lettisch",
	"display_mk" to "Mazedonisch",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolei",
	"display_mr" to "Marathi",
	"display_ms" to "Malaiisch",
	"display_my" to "Birmanisch",
	"display_nb" to "Norwegisch Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Niederländisch",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polnisch",
	"display_pt" to "Portugiesisch",
	"display_pt_BR" to "Portugiesisch (Brasilien)",
	"display_pt_PT" to "Europäisches Portugiesisch",
	"display_ro" to "Rumänisch",
	"display_ru" to "Russisch",
	"display_si" to "Singhalesisch",
	"display_sk" to "Slowakisch",
	"display_sl" to "Slowenisch",
	"display_sq" to "Albanisch",
	"display_sr" to "Serbisch",
	"display_sv" to "Schwedisch",
	"display_sw" to "Suaheli",
	"display_ta" to "Tamilisch",
	"display_te" to "Telugu",
	"display_th" to "Thailändisch",
	"display_tr" to "Türkisch",
	"display_uk" to "Ukrainisch",
	"display_ur" to "Urdu",
	"display_uz" to "Usbekisch",
	"display_vi" to "Vietnamesisch",
	"display_zh_Hant_TW" to "Chinesisch (Taiwan, Traditionell)",
	"display_zu" to "Zulu",
	"document_estimate" to "Schätzung",
	"document_invoice" to "Rechnung",
	"document_quote" to "Angebot",
	"done" to "Erledigt",
	"download_app" to "Die App herunterladen",
	"download_invoice" to "Rechnung herunterladen",
	"download_pdf" to "PDF herunterladen",
	"draw_signature" to "Zeichnen Sie eine Unterschrift",
	"due" to Formattable { (date) -> "Fällig ${date}" },
	"due_date" to "Fälligkeitsdatum",
	"due_day" to "in einem Tag",
	"due_label" to "",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ist fällig ${dueWhen}!" },
	"due_today" to "heute",
	"due_week" to "in einer Woche",
	"duplicate" to "Duplikat",
	"duration_day" to "Tag",
	"duration_month" to "Monat",
	"duration_week" to "Woche",
	"duration_year" to "Jahr",
	"email" to "E-Mail",
	"email_blank_error" to "E-Mail erforderlich.",
	"email_error" to "Ungültige E-Mail Adresse.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Wir danken Ihnen für Ihr Vertrauen. Um das Dokument ${documentType} vollständig anzuzeigen, klicken Sie auf die Schaltfläche am Ende dieses Schreibens. Beachten Sie, dass dieser Brief bis zum ${dueDate} gültig ist." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Sie haben eine neue ${documentType} ${invoiceNumber} erhalten!" },
	"email_invoice_cta" to Formattable { (documentType) -> "Rechnung ${documentType}" },
	"email_invoice_greeting" to Formattable { (Kundenname) -> "Lieber ${Kundenname}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Wir danken Ihnen für Ihr Vertrauen. Für die vollständige Ansicht von ${documentType} klicken Sie auf die Schaltfläche am Ende dieses Schreibens. Bitte zahlen Sie den Restbetrag bis zum ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Sie haben eine neue ${documentType} ${invoiceNumber} erhalten!" },
	"email_reset_success_message" to "E-Mail zum Zurücksetzen des Passworts gesendet, bitte überprüfen Sie Ihren Posteingang.",
	"empty_clients_list_message" to "Sie haben keine Kunden zu verfolgen. Fügen Sie einen neuen Kunden hinzu.",
	"empty_estimate_list_message" to "Erstellen Sie Ihren ersten Kostenvoranschlag",
	"empty_expenses" to "Sie haben keine Ausgaben zu verfolgen. Fügen Sie eine neue Ausgabe hinzu.",
	"empty_invoice_list_message" to "Erstellen Sie Ihre erste Rechnung",
	"empty_items_list_message" to "Sie haben keine Artikel, fügen Sie einen neuen Artikel hinzu!",
	"empty_signatures_list_message" to "Keine Signaturen gefunden. Erstellen Sie eine!",
	"empty_taxes_list_message" to "Sie haben keine Steuerregeln. Fügen Sie eine neue Steuerregel hinzu.",
	"empty_templates_list_message" to "Vorlagen sind nicht verfügbar.",
	"enabled" to "Aktiviert",
	"entry" to "Eintrag",
	"equity" to "Eigenkapital",
	"estimate_number" to "Schätzung #",
	"estimates" to "Schätzungen",
	"estimates_report" to "Schätzungen",
	"expense_bank_fees" to "Bankgebühren",
	"expense_benefits" to "Vorteile",
	"expense_communication" to "Kommunikation",
	"expense_debt" to "Schuld",
	"expense_deleted" to "Ausgaben gelöscht",
	"expense_depreciation" to "Abschreibung",
	"expense_gifts" to "Geschenke",
	"expense_insurance" to "Versicherung",
	"expense_inventory" to "Inventar",
	"expense_legal" to "Rechtliches",
	"expense_maintenance" to "Wartung",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Mahlzeiten",
	"expense_office_supplies" to "Bürobedarf",
	"expense_rent" to "Miete",
	"expense_salaries" to "Gehälter",
	"expense_shopping" to "Einkaufen",
	"expense_software" to "Software",
	"expense_status_available" to "Verfügbar",
	"expense_status_processing" to "Verarbeitung",
	"expense_tax" to "Steuer",
	"expense_training" to "Training",
	"expense_transport" to "Transport",
	"expense_travel" to "Reisen",
	"expense_utility" to "Nützlichkeit",
	"expenses" to "Ausgaben",
	"feedback_option" to "Rückmeldung",
	"filter" to "Filter",
	"first_name_hint" to "Vorname",
	"forgot_password" to "Passwort zurücksetzen",
	"forgot_password_label" to "Passwort vergessen",
	"from" to "Von",
	"general_error" to "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.",
	"general_ledger" to "Hauptbuch",
	"go_back_label" to "Zurückgehen",
	"group_by" to "Gruppe von:",
	"image_view" to "Bild",
	"install" to "Installieren",
	"invalid_credentials_error" to "Ungültige Anmeldedaten.",
	"invalid_email_error" to "Ungültige E-Mail",
	"invalid_user_error" to "Ungültige Benutzeranmeldedaten.",
	"invoice_deleted_message" to "Rechnung gelöscht",
	"invoice_menu" to "Menü Rechnungen",
	"invoice_number" to "Invoice #",
	"invoices" to "Rechnungen",
	"items_option" to "Artikel",
	"keep" to "Behalten Sie",
	"language_selector" to Formattable { (selected) -> "Sprache ${selected}" },
	"last_name_hint" to "Nachname",
	"ledger" to "Kontenblatt",
	"legal" to "Rechtliches",
	"legal_error" to "Muss Nutzungsbedingungen und Datenschutzbestimmungen akzeptieren",
	"liabilities" to "Verbindlichkeiten",
	"loading" to "Laden",
	"login_required_error" to "Anmeldung erforderlich.",
	"logo_view" to "Paketlogo",
	"logout" to "Abmelden",
	"logout_prompt" to "Ihre Daten werden nicht mit der Cloud synchronisiert. Rechnungen, Kostenvoranschläge, Kunden und andere Informationen beim Abmelden löschen?",
	"main_screen" to "Hauptbildschirm",
	"mark_paid" to "Marke als bezahlt",
	"message" to "Nachricht",
	"missing_sender_msg" to "Ein Dokument ohne Absenderinformationen ist möglicherweise ungültig.",
	"missing_sender_title" to "Fehlende Senderinformationen",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "August",
	"month_august_short" to "Aug.",
	"month_december" to "Dezember",
	"month_december_short" to "Dez.",
	"month_february" to "Februar",
	"month_february_short" to "Feb.",
	"month_january" to "Januar",
	"month_january_short" to "Jan.",
	"month_july" to "Juli",
	"month_july_short" to "Juli",
	"month_june" to "Juni",
	"month_june_short" to "Juni",
	"month_march" to "März",
	"month_march_short" to "März",
	"month_may" to "Mai",
	"month_may_short" to "Mai",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Oktober",
	"month_october_short" to "Okt.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Verifizierung fehlgeschlagen.",
	"new" to "Neu",
	"news" to "Nachrichten",
	"next" to "Weiter",
	"no" to "nein",
	"no_data_available" to "Keine Daten verfügbar",
	"no_search_results" to "Leider konnten wir keine Ergebnisse finden.",
	"none" to "Keine",
	"note_fields" to "Notizen",
	"note_hint" to "Notiz",
	"notifications_option" to "Benachrichtigungen",
	"ok" to "OK",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Wesentliche Teile von DingVoice sind für immer kostenlos. Unsere Mission ist es, Ihnen zu helfen, Ihr Geschäft zu erweitern. Helfen Sie uns, uns zu verbessern, indem Sie Ihr Feedback teilen.",
	"onboarding_aboutus_title" to "Wir sind hier, um zu helfen",
	"onboarding_paid_1" to "Zugang zu professionell aussehenden Vorlagen",
	"onboarding_paid_2" to "Werfen Sie einen genaueren Blick auf Ihre Finanzen",
	"onboarding_paid_3" to "Probleme schnell lösen",
	"onboarding_paid_4" to "Bewahren Sie Ihre Dokumente sicher auf",
	"onboarding_paid_5" to "Bleiben Sie über Änderungen des Rechnungsstatus auf dem Laufenden",
	"onboarding_paid_title" to "Wachsen Sie noch schneller mit...",
	"onboarding_paywall_message" to "Genießen Sie jetzt alle Premium-Funktionen zum besten Preis.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Nur ${currencySymbol}${introAmount} für ${introDuration} ${introPeriod} und dann ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, Betrag, Laufzeit) -> "Nur ${currencySymbol}${Betrag} / ${Laufzeit}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Kostenlos für ${trialDuration} ${trialPeriod} und dann nur noch ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Kostenlos für ${trialDuration} ${trialPeriod}, dann ${currencySymbol}${introductoryAmount} für ${introductoryDuration} ${introPeriod} und dann nur ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automatisch verlängerbar. Jederzeit kündbar.",
	"onboarding_paywall_restore" to "Kauf wiederherstellen",
	"onboarding_paywall_restore_failure" to "Die Wiederherstellung der Einkäufe ist fehlgeschlagen. Versuchen Sie es später noch einmal oder wenden Sie sich an den Support.",
	"onboarding_paywall_restore_success" to "Wiederherstellung erfolgreich!",
	"onboarding_paywall_title" to "Erreichen Sie Großartiges!",
	"onboarding_welcome_message" to "Ein komplettes Toolset zur Verwaltung Ihrer Rechnungen und Kostenvoranschläge.",
	"onboarding_welcome_title" to "Willkommen bei DingVoice",
	"other_state" to "Andere",
	"overdue_state" to "Überfällig",
	"paid_state" to "Bezahlt",
	"password" to "Passwort",
	"password_blank_error" to "Passwort erforderlich.",
	"password_mismatch_error" to "Die Kennwörter stimmen nicht überein.",
	"pay" to "bezahlen",
	"payment_instructions_label" to "Zahlungsanweisungen",
	"payment_reminders" to "Zahlungserinnerungen",
	"phone_number_fields" to "Telefonnummer",
	"phone_number_hint" to "Telefonnummer",
	"postcode_hint" to "Postleitzahl",
	"preview" to "Vorschau",
	"preview_menu_hint" to "Menü Vorschau",
	"price" to "Preis",
	"privacy_policy" to "Hinweise zum Datenschutz",
	"profile" to "Profil",
	"profitable_client" to "Ihr profitabelster Kunde ist...",
	"profits_report" to "Gewinne",
	"promo_content" to "Werblicher Inhalt",
	"quantity_hint" to "Menge",
	"quantity_label_hint" to "Menge Etikett",
	"query_info" to "Die Abfrage ermöglicht es Ihnen, benutzerdefinierte Berechnungen zu erstellen, die sich sowohl auf Artikel als auch auf andere Steuerregeln als Eingaben beziehen. Fügen Sie das @-Symbol und eine Artikel- oder Steuerbeschreibung hinzu, um einen Link zu einem anderen Eintrag zu erstellen. +, -, *, /, % - mathematische Operatoren",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} wurde von Ihrem Client empfangen." },
	"received_state" to "Empfangen",
	"registration_code_fields" to "Registrierungscode",
	"reminder" to "Erinnerung",
	"reports" to "Berichte",
	"reports_not_available" to "Keine Berichte verfügbar, kommen Sie später wieder.",
	"reveal_password" to "Passwort aufdecken",
	"revenue" to "Einnahmen",
	"sales_tax" to "Umsatzsteuer",
	"sales_tax_number" to "Umsatzsteuer-Nummer",
	"save" to "Sparen",
	"scan_document" to "Dokument scannen",
	"search" to "Suche",
	"select" to "Auswählen",
	"select_icon" to "Symbol auswählen",
	"select_signature" to "Unterschrift auswählen",
	"select_template" to "Vorlage auswählen",
	"send" to "Senden",
	"sent_state" to "Gesendet",
	"setup_sender" to "Absender einrichten",
	"share_option" to "Teilen",
	"ship_to" to "Versand nach",
	"sign_in" to "Anmelden",
	"sign_in_apple" to "Mit Apple anmelden",
	"sign_in_email" to "Mit E-Mail anmelden",
	"sign_in_facebook" to "Mit Facebook anmelden",
	"sign_in_google" to "Mit Google anmelden",
	"sign_up" to "Anmelden",
	"sign_up_label" to "Sie haben noch kein Konto?",
	"sign_up_link_label" to "Anmelden!",
	"signatures_option" to "Unterschriften",
	"skip" to "Überspringen",
	"sold_items_report" to "Verkaufte Artikel",
	"sort_by" to "Sortieren nach:",
	"status_update" to "Aktualisierung des Dokumentenstatus",
	"sub_annual" to "Jährlich",
	"sub_annual_price" to Formattable { (price) -> "${price}/Jahr" },
	"sub_backups" to "Cloud-Backups",
	"sub_insights" to "Einblicke",
	"sub_month" to "Monatlich",
	"sub_monthly_price" to Formattable { (price) -> "${price}/Monat" },
	"sub_notifications" to "Benachrichtigungen",
	"sub_popup_message" to "Abonnements sind bald erhältlich. Schauen Sie hier in Kürze wieder vorbei.",
	"sub_support" to "Unterstützung",
	"sub_templates" to "Vorlagen",
	"subscribe" to "Abonnieren",
	"subscription_header" to "Wachsen Sie mit Ihrem Unternehmen:",
	"subscription_option" to "Abonnement",
	"subscriptions_load_fail" to "Es tut uns leid, wir können Ihre Abonnements zur Zeit nicht abrufen. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an unser Support-Team, um Hilfe zu erhalten.",
	"subtotal" to Formattable { (sum) -> "Zwischensumme: ${sum}" },
	"subtotal_label" to "Zwischensumme",
	"sum" to "Summe",
	"support_option" to "Unterstützung",
	"switch_reports" to "Bericht wechseln",
	"tax" to "STEUER",
	"tax_deleted_message" to "Steuer gestrichen",
	"tax_description_warning" to "Bei Verwendung wird dies auf einer Rechnung ausgewiesen",
	"tax_input_flat" to "Wohnung",
	"tax_input_percent" to "Prozentsatz",
	"tax_number_fields" to "Steuernummer",
	"tax_number_hint" to "Steuernummer",
	"tax_query_info_description" to "Informationen abfragen",
	"tax_report" to "Steuer",
	"tax_rules_option" to "Steuerliche Vorschriften",
	"taxed_clients_report" to "Versteuerte Kunden",
	"template" to "Vorlage",
	"template_selector_info" to "Wischen, um die Standardvorlage zu ändern",
	"template_unlock_promo" to "Zugriff auf mehr Vorlagen mit Premium",
	"templates_option" to "Vorlagen",
	"terms_of_service" to "Bedingungen für den Service",
	"thank_you" to "Vielen Dank!",
	"to" to "An",
	"total" to Formattable { (sum) -> "Gesamt: ${sum}" },
	"total_label" to "GESAMT",
	"undo_action" to "Rückgängig machen",
	"unit" to "Gerät",
	"unit_cost_hint" to "Kosten pro Einheit",
	"unknown_client" to "Unbekannter Kunde",
	"unlock" to "freischalten",
	"unnamed_item" to "Unbenanntes Element",
	"upgrade" to "Upgrade",
	"user_collision_error" to "Das Konto existiert bereits.",
	"valid_until" to "Gültig bis",
	"warning" to "Warnung!",
	"yes" to "ja",
    )
    
}