// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Sk  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Prijmite ${termsOfService} a ${privacyPolicy}" }
override val account: String = "účet"
override val accounting: String = "účtovníctvo"
override val accruals: String = "časové rozlíšenie"
override val action_code_error: String = "Neplatný kód."
override val add_client_action: String = "Pridať klienta"
override val add_customer: String = "Pridať zákazníka"
override val add_expense: String = "Pridať výdavky"
override val add_fields_label: String = "Pridať:"
override val add_invoice_action: String = "Vytvoriť stránku"
override val add_item_action: String = "Pridať položku"
override val add_item_button: String = "Pridať položku"
override val add_signature_action: String = "Pridať podpis"
override val add_tax_action: String = "Pridajte daň"
override val address_fields: String = "Adresa"
override val address_line_hint: String = "Adresný riadok"
override val advanced: String = "Pokročilé"
override val agree_marketing: String = "Súhlasím so zasielaním priamej marketingovej komunikácie"
override val all_notifications: String = "Všetky oznámenia"
override val amortization: String = "amortizácia"
override val amount: String = "Suma"
override val asset: String = "aktívum"
override val attach_file: String = "Pripojiť súbor"
override val attachment: String = "Príloha"
override val auth_error: String = "Akcia sa nepodarila, skúste to znova."
override val available_for_paid: String = "Synchronizácia v cloude je k dispozícii len v rámci plateného predplatného."
override val backups_option: String = "Zálohovanie"
override val balance: String = "zostatok"
override val bank_account: String = "Bankový Účet"
override val bill_number: String = "Číslo účtu"
override val cancel: String = "Zrušiť"
override val cancel_trial: String = "Zrušte kedykoľvek"
override val capital: String = "kapitál"
override val cash: String = "hotovosť"
override val categories: String = "Kategórie"
override val category: String = "Kategória"
override val category_add: String = "Pridať kategóriu"
override val category_delete: String = "Odstrániť kategóriu"
override val category_name: String = "Názov kategórie"
override val change_language: Formattable = Formattable { (language) -> "Jazyk faktúry: ${language}" }
override val change_language_label: String = "Jazyk Faktúry"
override val change_template: String = "Zmena šablóny"
override val city_hint: String = "Mesto"
override val clear: String = "Jasné"
override val client: String = "Klient"
override val client_actions: String = "Činnosti klienta"
override val client_deleted_message: String = "Klient vymazaný"
override val clients_error_required_fields: String = "Vyžaduje sa aspoň jedna hodnota."
override val clients_option: String = "Klienti"
override val close: String = "Zatvoriť"
override val cloud_sync: String = "Synchronizácia s cloudom"
override val coming_soon: String = "Už čoskoro!"
override val company_logo: String = "Logo spoločnosti"
override val company_name_hint: String = "Spoločnosť"
override val confirm_password_label: String = "Potvrdenie hesla"
override val confirmation_title: String = "Ste si istí?"
override val convert_to_invoice: String = "Previesť na faktúru"
override val country_hint: String = "Krajina"
override val create_client: String = "Vytvoriť klienta"
override val create_company: String = "Vytvoriť spoločnosť"
override val create_document: String = "Vytvoriť dokument"
override val create_estimate: String = "Odhad"
override val create_invoice: String = "Faktúra"
override val create_item: String = "Vytvoriť položku"
override val create_new_client: String = "Vytvorenie nového klienta"
override val create_new_item: String = "Vytvorenie novej položky"
override val create_new_tax: String = "Vytvorenie novej dane"
override val create_signature: String = "Podpis"
override val created: String = "Vytvorené"
override val credit: String = "kreditná"
override val customer: String = "Zákazník"
override val date: String = "Dátum"
override val debit: String = "debetná"
override val debt: String = "dlh"
override val default_currency: Formattable = Formattable { (currency) -> "Predvolená mena: ${currency}" }
override val default_currency_label: String = "Predvolená mena"
override val delete: String = "Odstrániť"
override val delete_account: String = "Odstrániť Účet"
override val delete_confirmation_message: String = "Všetky vaše údaje budú stratené."
override val delete_item_title: String = "Vymazať položku?"
override val delete_signature: String = "Odstránenie podpisu"
override val depreciation: String = "odpisy"
override val description: String = "Popis"
override val description_hint: String = "Popis"
override val detailed_report: String = "Podrobná Správa"
override val disabled: String = "Bezbariérový"
override val discount: String = "Zľava"
override val discount_hint: String = "Zľava"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharčina"
override val display_ar: String = "Arabčina"
override val display_as: String = "Assámčina"
override val display_az: String = "Azerbajdžan"
override val display_be: String = "Bieloruský"
override val display_bg: String = "Bulharský"
override val display_bn: String = "Bengálčina"
override val display_bs: String = "Bosnianska"
override val display_ca: String = "Katalánčina"
override val display_cs: String = "Český"
override val display_da: String = "Dánsky"
override val display_de: String = "Nemčina"
override val display_el: String = "Grécky"
override val display_en: String = "Angličtina"
override val display_es: String = "Španielčina"
override val display_es_MX: String = "Španielčina (Mexiko)"
override val display_et: String = "Estónčina"
override val display_eu: String = "Baskicko"
override val display_fa: String = "Perzský"
override val display_fi: String = "Fínsky"
override val display_fil: String = "Filipínci"
override val display_fr: String = "Francúzsky"
override val display_fr_CA: String = "Kanadská francúzština"
override val display_gl: String = "Galícia"
override val display_gu: String = "Gudžarátčina"
override val display_he: String = "Hebrejčina"
override val display_hi: String = "Hindčina"
override val display_hr: String = "Chorvátsky"
override val display_hu: String = "Maďarský"
override val display_hy: String = "Arménsky"
override val display_id: String = "Indonézsky"
override val display_is: String = "Islandský"
override val display_it: String = "Taliansky"
override val display_ja: String = "Japonský"
override val display_ka: String = "Gruzínsky"
override val display_kk: String = "Kazachstan"
override val display_km: String = "Stredný Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Kórejský"
override val display_ky: String = "Kirgiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Litovský"
override val display_lv: String = "Lotyšský"
override val display_mk: String = "Macedónčina"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolský"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malajčina"
override val display_my: String = "Barmský"
override val display_nb: String = "Nórsky jazyk Bokmål"
override val display_ne: String = "Nepálsky"
override val display_nl: String = "Holandský"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Poľský"
override val display_pt: String = "Portugalčina"
override val display_pt_BR: String = "Portugalčina (Brazília)"
override val display_pt_PT: String = "Európska portugalčina"
override val display_ro: String = "Rumunský"
override val display_ru: String = "Ruský"
override val display_si: String = "Sinhálčina"
override val display_sk: String = "Slovenská"
override val display_sl: String = "Slovinčina"
override val display_sq: String = "Albánčina"
override val display_sr: String = "Srbský"
override val display_sv: String = "Švédsky"
override val display_sw: String = "Svahilčina"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Turecký"
override val display_uk: String = "Ukrajinský"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbek"
override val display_vi: String = "Vietnamci"
override val display_zh_Hant_TW: String = "Čínština (Taiwan, Tradičná)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Odhad"
override val document_invoice: String = "Faktúra"
override val document_quote: String = "Cenová ponuka"
override val done: String = "Hotovo"
override val download_app: String = "Stiahnite si aplikáciu"
override val download_invoice: String = "Stiahnuť Faktúru"
override val download_pdf: String = "Stiahnuť PDF"
override val draw_signature: String = "Nakreslite podpis"
override val due: Formattable = Formattable { (date) -> "Splatnosť ${date}" }
override val due_date: String = "Dátum splatnosti"
override val due_day: String = "za deň"
override val due_label: String = "Na stránke ."
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} je splatný ${dueWhen}!" }
override val due_today: String = "dnes"
override val due_week: String = "do týždňa"
override val duplicate: String = "Duplikovať"
override val duration_day: String = "deň"
override val duration_month: String = "Mesiac"
override val duration_week: String = "týždeň"
override val duration_year: String = "rok"
override val email: String = "E-mail"
override val email_blank_error: String = "Požadovaný e-mail."
override val email_error: String = "Neplatná e-mailová adresa."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Ďakujeme vám za váš obchod. Pre zobrazenie celého ${documentType} kliknite na tlačidlo na konci tohto listu. Všimnite si, že je platný do ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Obdržali ste nový dokument: ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ZOBRAZIŤ ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Vážený ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Ďakujeme vám za váš obchod. Ak si chcete pozrieť celý ${documentType}, kliknite na tlačidlo na konci tohto listu. Prosím, uhraďte zostatok do ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Obdržali ste nový dokument: ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "E-mail na obnovenie hesla bol odoslaný, skontrolujte si prosím schránku."
override val empty_clients_list_message: String = "Nemáte žiadnych klientov, ktorých by ste museli sledovať. Pridajte nového klienta."
override val empty_estimate_list_message: String = "Vytvorenie prvého odhadu"
override val empty_expenses: String = "Nemusíte sledovať žiadne výdavky. Pridajte nový výdavok."
override val empty_invoice_list_message: String = "Vytvorenie prvej faktúry"
override val empty_items_list_message: String = "Nemáte žiadne položky, pridajte novú položku!"
override val empty_signatures_list_message: String = "Nenašli sa žiadne podpisy. Vytvorte ho!"
override val empty_taxes_list_message: String = "Nemáte žiadne daňové pravidlá. Pridajte nové daňové pravidlo."
override val empty_templates_list_message: String = "Šablóny nie sú k dispozícii."
override val enabled: String = "Povolené"
override val entry: String = "položka"
override val equity: String = "vlastný kapitál"
override val estimate_number: String = "Odhad #"
override val estimates: String = "Odhady"
override val estimates_report: String = "Odhady"
override val expense_bank_fees: String = "Bankové poplatky"
override val expense_benefits: String = "Výhody"
override val expense_communication: String = "Komunikácia"
override val expense_debt: String = "Dlh"
override val expense_deleted: String = "Vymazané výdavky"
override val expense_depreciation: String = "Odpisy"
override val expense_gifts: String = "Darčeky"
override val expense_insurance: String = "poistenie"
override val expense_inventory: String = "Inventár"
override val expense_legal: String = "Právna stránka"
override val expense_maintenance: String = "Údržba"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Stravovanie"
override val expense_office_supplies: String = "Kancelárske potreby"
override val expense_rent: String = "Prenájom"
override val expense_salaries: String = "Platy"
override val expense_shopping: String = "Nakupovanie"
override val expense_software: String = "Softvér"
override val expense_status_available: String = "Dostupné na"
override val expense_status_processing: String = "Spracovanie"
override val expense_tax: String = "Daň"
override val expense_training: String = "Školenie"
override val expense_transport: String = "Doprava"
override val expense_travel: String = "Cestovanie"
override val expense_utility: String = "Utility"
override val expenses: String = "Výdavky"
override val feedback_option: String = "Spätná väzba"
override val filter: String = "Filter"
override val first_name_hint: String = "Krstné meno"
override val forgot_password: String = "Obnovenie hesla"
override val forgot_password_label: String = "Zabudnuté heslo"
override val from: String = "Z adresy"
override val general_error: String = "Vyskytla sa chyba, skúste to prosím neskôr."
override val general_ledger: String = "hlavná kniha"
override val go_back_label: String = "Vráťte sa späť"
override val group_by: String = "Skupina podľa:"
override val image_view: String = "Obrázok"
override val install: String = "Inštalovať"
override val invalid_credentials_error: String = "Neplatné poverenia."
override val invalid_email_error: String = "Neplatný e-mail."
override val invalid_user_error: String = "Neplatné poverenia používateľa."
override val invoice_deleted_message: String = "Vymazaná faktúra"
override val invoice_menu: String = "Ponuka faktúr"
override val invoice_number: String = "Faktúra #"
override val invoices: String = "Faktúry"
override val items_option: String = "Položky"
override val keep: String = "Udržujte"
override val language_selector: Formattable = Formattable { (selected) -> "Jazyk ${selected}" }
override val last_name_hint: String = "Priezvisko"
override val ledger: String = "účtovná kniha"
override val legal: String = "Právna stránka"
override val legal_error: String = "Musí akceptovať podmienky používania služby a zásady ochrany osobných údajov"
override val liabilities: String = "záväzky"
override val loading: String = "Načítavanie"
override val login_required_error: String = "Vyžaduje sa prihlásenie."
override val logo_view: String = "Logo"
override val logout: String = "Odhlásenie"
override val logout_prompt: String = "Vaše údaje nie sú synchronizované s cloudom. Odstránenie faktúr, odhadov, klientov a ďalších informácií pri odhlásení?"
override val main_screen: String = "Hlavná obrazovka"
override val mark_paid: String = "Označiť ako zaplatené"
override val message: String = "Správa"
override val missing_sender_msg: String = "Dokument bez informácií o odosielateľovi môže byť neplatný."
override val missing_sender_title: String = "Chýbajú informácie o odosielateľovi"
override val month_april: String = "apríl"
override val month_april_short: String = "Apríl"
override val month_august: String = "August"
override val month_august_short: String = "Aug."
override val month_december: String = "December"
override val month_december_short: String = "Dec."
override val month_february: String = "Február"
override val month_february_short: String = "Feb."
override val month_january: String = "Január"
override val month_january_short: String = "Jan."
override val month_july: String = "Júl"
override val month_july_short: String = "Júl"
override val month_june: String = "jún"
override val month_june_short: String = "Jún"
override val month_march: String = "Marec"
override val month_march_short: String = "Marec"
override val month_may: String = "Máj"
override val month_may_short: String = "Máj"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Október"
override val month_october_short: String = "Okt."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Nepodarilo sa overiť."
override val new: String = "Nový"
override val news: String = "Novinky"
override val next: String = "Ďalšie"
override val no: String = "nie"
override val no_data_available: String = "Nie sú k dispozícii žiadne údaje"
override val no_search_results: String = "Je nám ľúto, ale nenašli sme žiadne výsledky."
override val none: String = "Žiadne"
override val note_fields: String = "Poznámky"
override val note_hint: String = "Poznámka"
override val notifications_option: String = "Oznámenia"
override val ok: String = "OK"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Základné časti aplikácie DingVoice sú navždy bezplatné. Naším poslaním je pomáhať vám rozvíjať vaše podnikanie, pomôžte nám zlepšovať sa zdieľaním vašej spätnej väzby."
override val onboarding_aboutus_title: String = "Sme tu, aby sme vám pomohli"
override val onboarding_paid_1: String = "Prístup k profesionálne vyzerajúcim šablónam"
override val onboarding_paid_2: String = "Pozrite sa hlbšie na svoje financie"
override val onboarding_paid_3: String = "Rýchle riešenie problémov"
override val onboarding_paid_4: String = "Uchovávajte svoje dokumenty v bezpečí"
override val onboarding_paid_5: String = "Aktuálne informácie o zmenách stavu faktúr"
override val onboarding_paid_title: String = "Rastiete ešte rýchlejšie s..."
override val onboarding_paywall_message: String = "Užívajte si všetky prémiové funkcie za najlepšiu cenu."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Len ${currencySymbol}${introAmount} za ${introDuration} ${introPeriod} a potom ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Len ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Zadarmo na ${trialDuration} ${trialPeriod} a potom len ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Zadarmo pre ${trialDuration} ${trialPeriod}, potom ${currencySymbol}${introductoryAmount} pre ${introductoryDuration} ${introPeriod} a potom už len ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automatická obnova. Zrušiť kedykoľvek."
override val onboarding_paywall_restore: String = "Obnovenie nákupu"
override val onboarding_paywall_restore_failure: String = "Obnovenie nákupov zlyhalo, skúste to neskôr alebo kontaktujte podporu."
override val onboarding_paywall_restore_success: String = "Obnovenie úspešné!"
override val onboarding_paywall_title: String = "Dosiahnite veľkosť!"
override val onboarding_welcome_message: String = "Kompletný súbor nástrojov na správu faktúr a odhadov."
override val onboarding_welcome_title: String = "Vitajte v službe DingVoice"
override val other_state: String = "Ostatné"
override val overdue_state: String = "Premlčané"
override val paid_state: String = "Platené"
override val password: String = "Heslo"
override val password_blank_error: String = "Vyžaduje sa heslo."
override val password_mismatch_error: String = "Heslá sa nezhodujú."
override val pay: String = "zaplatiť"
override val payment_instructions_label: String = "Pokyny na platbu"
override val payment_reminders: String = "Pripomienky platieb"
override val phone_number_fields: String = "Telefónne číslo"
override val phone_number_hint: String = "Telefónne číslo"
override val postcode_hint: String = "poštové smerovacie číslo"
override val preview: String = "Náhľad"
override val preview_menu_hint: String = "Ponuka náhľadu"
override val price: String = "Cena"
override val privacy_policy: String = "Zásady ochrany osobných údajov"
override val profile: String = "Profil"
override val profitable_client: String = "Váš najziskovejší klient je..."
override val profits_report: String = "Zisky"
override val promo_content: String = "Propagačný obsah"
override val quantity_hint: String = "Množstvo"
override val quantity_label_hint: String = "Označenie množstva"
override val query_info: String = "Dotaz umožňuje napísať vlastné výpočty, ktoré sa vzťahujú na položky a iné daňové pravidlá ako vstupy. Pridanie symbolu @ a popisu položky alebo dane na prepojenie s iným záznamom +, -, *, /, % - matematické operátory"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} bol prijatý vaším klientom." }
override val received_state: String = "Prijaté"
override val registration_code_fields: String = "Registračný Kód"
override val reminder: String = "Pripomienka"
override val reports: String = "Správy"
override val reports_not_available: String = "Žiadne správy nie sú k dispozícii, vráťte sa neskôr."
override val reveal_password: String = "Odhaliť heslo"
override val revenue: String = "výnosy"
override val sales_tax: String = "DPH"
override val sales_tax_number: String = "IČ DPH"
override val save: String = "Uložiť"
override val scan_document: String = "Skenovanie dokumentu"
override val search: String = "Vyhľadávanie"
override val select: String = "Vyberte"
override val select_icon: String = "Vyberte ikonu"
override val select_signature: String = "Vyberte podpis"
override val select_template: String = "Vyberte šablónu"
override val send: String = "Odoslať"
override val sent_state: String = "Odoslané"
override val setup_sender: String = "Nastaviť odosielateľa"
override val share_option: String = "Zdieľať"
override val ship_to: String = "Odoslať na adresu"
override val sign_in: String = "Prihlásiť sa"
override val sign_in_apple: String = "Prihlásenie pomocou Apple"
override val sign_in_email: String = "Prihláste sa pomocou e-mailu"
override val sign_in_facebook: String = "Prihláste sa pomocou služby Facebook"
override val sign_in_google: String = "Prihlásenie pomocou služby Google"
override val sign_up: String = "Zaregistrujte sa"
override val sign_up_label: String = "Nemáte účet?"
override val sign_up_link_label: String = "Zaregistrujte sa!"
override val signatures_option: String = "Podpisy"
override val skip: String = "Vynechať"
override val sold_items_report: String = "Predané Položky"
override val sort_by: String = "Zoradiť podľa:"
override val status_update: String = "Aktualizácia stavu dokumentu"
override val sub_annual: String = "Výročný"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/rok" }
override val sub_backups: String = "Cloudové zálohy"
override val sub_insights: String = "Insights"
override val sub_month: String = "Mesačne"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mesiac" }
override val sub_notifications: String = "Notifikácie"
override val sub_popup_message: String = "Predplatné sa čoskoro blíži. Skontroluj to tu za chvíľku."
override val sub_support: String = "Podpora"
override val sub_templates: String = "Šablóny"
override val subscribe: String = "Prihlásiť sa na"
override val subscription_header: String = "Rozšírte svoje podnikanie s:"
override val subscription_option: String = "Predplatné"
override val subscriptions_load_fail: String = "Je nám ľúto, ale momentálne nemôžeme načítať vaše predplatné. Skúste to prosím neskôr alebo kontaktujte náš tím podpory a požiadajte o pomoc."
override val subtotal: Formattable = Formattable { (sum) -> "Medzisúčet: ${sum}" }
override val subtotal_label: String = "Medzisúčet"
override val sum: String = "Suma"
override val support_option: String = "Podpora"
override val switch_reports: String = "Prepnúť Prehľad"
override val tax: String = "Daň"
override val tax_deleted_message: String = "Vymazaná daň"
override val tax_description_warning: String = "Ak sa použije, zobrazí sa na faktúre"
override val tax_input_flat: String = "Ploché"
override val tax_input_percent: String = "Percentá"
override val tax_number_fields: String = "Daňové číslo"
override val tax_number_hint: String = "Daňové číslo"
override val tax_query_info_description: String = "Informácie o požiadavke"
override val tax_report: String = "Daň"
override val tax_rules_option: String = "Daňové pravidlá"
override val taxed_clients_report: String = "Zdanení klienti"
override val template: String = "Šablóna"
override val template_selector_info: String = "Potiahnite prstom a zmeňte predvolenú šablónu"
override val template_unlock_promo: String = "Prístup k ďalším šablónam so službou Premium"
override val templates_option: String = "Šablóny"
override val terms_of_service: String = "Podmienky služby"
override val thank_you: String = "Ďakujeme"
override val to: String = "Na"
override val total: Formattable = Formattable { (sum) -> "Spolu: ${sum}" }
override val total_label: String = "Celkom"
override val undo_action: String = "Zrušiť"
override val unit: String = "jednotka"
override val unit_cost_hint: String = "Jednotkové náklady"
override val unknown_client: String = "Neznámy Klient"
override val unlock: String = "Odomknutie stránky"
override val unnamed_item: String = "Nepomenovaná položka"
override val upgrade: String = "Aktualizácia"
override val user_collision_error: String = "Účet už existuje."
override val valid_until: String = "Platné Do"
override val warning: String = "Varovanie!"
override val yes: String = "áno"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Prijmite ${termsOfService} a ${privacyPolicy}" },
	"account" to "účet",
	"accounting" to "účtovníctvo",
	"accruals" to "časové rozlíšenie",
	"action_code_error" to "Neplatný kód.",
	"add_client_action" to "Pridať klienta",
	"add_customer" to "Pridať zákazníka",
	"add_expense" to "Pridať výdavky",
	"add_fields_label" to "Pridať:",
	"add_invoice_action" to "Vytvoriť stránku",
	"add_item_action" to "Pridať položku",
	"add_item_button" to "Pridať položku",
	"add_signature_action" to "Pridať podpis",
	"add_tax_action" to "Pridajte daň",
	"address_fields" to "Adresa",
	"address_line_hint" to "Adresný riadok",
	"advanced" to "Pokročilé",
	"agree_marketing" to "Súhlasím so zasielaním priamej marketingovej komunikácie",
	"all_notifications" to "Všetky oznámenia",
	"amortization" to "amortizácia",
	"amount" to "Suma",
	"asset" to "aktívum",
	"attach_file" to "Pripojiť súbor",
	"attachment" to "Príloha",
	"auth_error" to "Akcia sa nepodarila, skúste to znova.",
	"available_for_paid" to "Synchronizácia v cloude je k dispozícii len v rámci plateného predplatného.",
	"backups_option" to "Zálohovanie",
	"balance" to "zostatok",
	"bank_account" to "Bankový Účet",
	"bill_number" to "Číslo účtu",
	"cancel" to "Zrušiť",
	"cancel_trial" to "Zrušte kedykoľvek",
	"capital" to "kapitál",
	"cash" to "hotovosť",
	"categories" to "Kategórie",
	"category" to "Kategória",
	"category_add" to "Pridať kategóriu",
	"category_delete" to "Odstrániť kategóriu",
	"category_name" to "Názov kategórie",
	"change_language" to Formattable { (language) -> "Jazyk faktúry: ${language}" },
	"change_language_label" to "Jazyk Faktúry",
	"change_template" to "Zmena šablóny",
	"city_hint" to "Mesto",
	"clear" to "Jasné",
	"client" to "Klient",
	"client_actions" to "Činnosti klienta",
	"client_deleted_message" to "Klient vymazaný",
	"clients_error_required_fields" to "Vyžaduje sa aspoň jedna hodnota.",
	"clients_option" to "Klienti",
	"close" to "Zatvoriť",
	"cloud_sync" to "Synchronizácia s cloudom",
	"coming_soon" to "Už čoskoro!",
	"company_logo" to "Logo spoločnosti",
	"company_name_hint" to "Spoločnosť",
	"confirm_password_label" to "Potvrdenie hesla",
	"confirmation_title" to "Ste si istí?",
	"convert_to_invoice" to "Previesť na faktúru",
	"country_hint" to "Krajina",
	"create_client" to "Vytvoriť klienta",
	"create_company" to "Vytvoriť spoločnosť",
	"create_document" to "Vytvoriť dokument",
	"create_estimate" to "Odhad",
	"create_invoice" to "Faktúra",
	"create_item" to "Vytvoriť položku",
	"create_new_client" to "Vytvorenie nového klienta",
	"create_new_item" to "Vytvorenie novej položky",
	"create_new_tax" to "Vytvorenie novej dane",
	"create_signature" to "Podpis",
	"created" to "Vytvorené",
	"credit" to "kreditná",
	"customer" to "Zákazník",
	"date" to "Dátum",
	"debit" to "debetná",
	"debt" to "dlh",
	"default_currency" to Formattable { (currency) -> "Predvolená mena: ${currency}" },
	"default_currency_label" to "Predvolená mena",
	"delete" to "Odstrániť",
	"delete_account" to "Odstrániť Účet",
	"delete_confirmation_message" to "Všetky vaše údaje budú stratené.",
	"delete_item_title" to "Vymazať položku?",
	"delete_signature" to "Odstránenie podpisu",
	"depreciation" to "odpisy",
	"description" to "Popis",
	"description_hint" to "Popis",
	"detailed_report" to "Podrobná Správa",
	"disabled" to "Bezbariérový",
	"discount" to "Zľava",
	"discount_hint" to "Zľava",
	"display_af" to "Afrikaans",
	"display_am" to "Amharčina",
	"display_ar" to "Arabčina",
	"display_as" to "Assámčina",
	"display_az" to "Azerbajdžan",
	"display_be" to "Bieloruský",
	"display_bg" to "Bulharský",
	"display_bn" to "Bengálčina",
	"display_bs" to "Bosnianska",
	"display_ca" to "Katalánčina",
	"display_cs" to "Český",
	"display_da" to "Dánsky",
	"display_de" to "Nemčina",
	"display_el" to "Grécky",
	"display_en" to "Angličtina",
	"display_es" to "Španielčina",
	"display_es_MX" to "Španielčina (Mexiko)",
	"display_et" to "Estónčina",
	"display_eu" to "Baskicko",
	"display_fa" to "Perzský",
	"display_fi" to "Fínsky",
	"display_fil" to "Filipínci",
	"display_fr" to "Francúzsky",
	"display_fr_CA" to "Kanadská francúzština",
	"display_gl" to "Galícia",
	"display_gu" to "Gudžarátčina",
	"display_he" to "Hebrejčina",
	"display_hi" to "Hindčina",
	"display_hr" to "Chorvátsky",
	"display_hu" to "Maďarský",
	"display_hy" to "Arménsky",
	"display_id" to "Indonézsky",
	"display_is" to "Islandský",
	"display_it" to "Taliansky",
	"display_ja" to "Japonský",
	"display_ka" to "Gruzínsky",
	"display_kk" to "Kazachstan",
	"display_km" to "Stredný Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Kórejský",
	"display_ky" to "Kirgiz",
	"display_lo" to "Lao",
	"display_lt" to "Litovský",
	"display_lv" to "Lotyšský",
	"display_mk" to "Macedónčina",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolský",
	"display_mr" to "Marathi",
	"display_ms" to "Malajčina",
	"display_my" to "Barmský",
	"display_nb" to "Nórsky jazyk Bokmål",
	"display_ne" to "Nepálsky",
	"display_nl" to "Holandský",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Poľský",
	"display_pt" to "Portugalčina",
	"display_pt_BR" to "Portugalčina (Brazília)",
	"display_pt_PT" to "Európska portugalčina",
	"display_ro" to "Rumunský",
	"display_ru" to "Ruský",
	"display_si" to "Sinhálčina",
	"display_sk" to "Slovenská",
	"display_sl" to "Slovinčina",
	"display_sq" to "Albánčina",
	"display_sr" to "Srbský",
	"display_sv" to "Švédsky",
	"display_sw" to "Svahilčina",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Turecký",
	"display_uk" to "Ukrajinský",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbek",
	"display_vi" to "Vietnamci",
	"display_zh_Hant_TW" to "Čínština (Taiwan, Tradičná)",
	"display_zu" to "Zulu",
	"document_estimate" to "Odhad",
	"document_invoice" to "Faktúra",
	"document_quote" to "Cenová ponuka",
	"done" to "Hotovo",
	"download_app" to "Stiahnite si aplikáciu",
	"download_invoice" to "Stiahnuť Faktúru",
	"download_pdf" to "Stiahnuť PDF",
	"draw_signature" to "Nakreslite podpis",
	"due" to Formattable { (date) -> "Splatnosť ${date}" },
	"due_date" to "Dátum splatnosti",
	"due_day" to "za deň",
	"due_label" to "Na stránke .",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} je splatný ${dueWhen}!" },
	"due_today" to "dnes",
	"due_week" to "do týždňa",
	"duplicate" to "Duplikovať",
	"duration_day" to "deň",
	"duration_month" to "Mesiac",
	"duration_week" to "týždeň",
	"duration_year" to "rok",
	"email" to "E-mail",
	"email_blank_error" to "Požadovaný e-mail.",
	"email_error" to "Neplatná e-mailová adresa.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Ďakujeme vám za váš obchod. Pre zobrazenie celého ${documentType} kliknite na tlačidlo na konci tohto listu. Všimnite si, že je platný do ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Obdržali ste nový dokument: ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "ZOBRAZIŤ ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Vážený ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Ďakujeme vám za váš obchod. Ak si chcete pozrieť celý ${documentType}, kliknite na tlačidlo na konci tohto listu. Prosím, uhraďte zostatok do ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Obdržali ste nový dokument: ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "E-mail na obnovenie hesla bol odoslaný, skontrolujte si prosím schránku.",
	"empty_clients_list_message" to "Nemáte žiadnych klientov, ktorých by ste museli sledovať. Pridajte nového klienta.",
	"empty_estimate_list_message" to "Vytvorenie prvého odhadu",
	"empty_expenses" to "Nemusíte sledovať žiadne výdavky. Pridajte nový výdavok.",
	"empty_invoice_list_message" to "Vytvorenie prvej faktúry",
	"empty_items_list_message" to "Nemáte žiadne položky, pridajte novú položku!",
	"empty_signatures_list_message" to "Nenašli sa žiadne podpisy. Vytvorte ho!",
	"empty_taxes_list_message" to "Nemáte žiadne daňové pravidlá. Pridajte nové daňové pravidlo.",
	"empty_templates_list_message" to "Šablóny nie sú k dispozícii.",
	"enabled" to "Povolené",
	"entry" to "položka",
	"equity" to "vlastný kapitál",
	"estimate_number" to "Odhad #",
	"estimates" to "Odhady",
	"estimates_report" to "Odhady",
	"expense_bank_fees" to "Bankové poplatky",
	"expense_benefits" to "Výhody",
	"expense_communication" to "Komunikácia",
	"expense_debt" to "Dlh",
	"expense_deleted" to "Vymazané výdavky",
	"expense_depreciation" to "Odpisy",
	"expense_gifts" to "Darčeky",
	"expense_insurance" to "poistenie",
	"expense_inventory" to "Inventár",
	"expense_legal" to "Právna stránka",
	"expense_maintenance" to "Údržba",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Stravovanie",
	"expense_office_supplies" to "Kancelárske potreby",
	"expense_rent" to "Prenájom",
	"expense_salaries" to "Platy",
	"expense_shopping" to "Nakupovanie",
	"expense_software" to "Softvér",
	"expense_status_available" to "Dostupné na",
	"expense_status_processing" to "Spracovanie",
	"expense_tax" to "Daň",
	"expense_training" to "Školenie",
	"expense_transport" to "Doprava",
	"expense_travel" to "Cestovanie",
	"expense_utility" to "Utility",
	"expenses" to "Výdavky",
	"feedback_option" to "Spätná väzba",
	"filter" to "Filter",
	"first_name_hint" to "Krstné meno",
	"forgot_password" to "Obnovenie hesla",
	"forgot_password_label" to "Zabudnuté heslo",
	"from" to "Z adresy",
	"general_error" to "Vyskytla sa chyba, skúste to prosím neskôr.",
	"general_ledger" to "hlavná kniha",
	"go_back_label" to "Vráťte sa späť",
	"group_by" to "Skupina podľa:",
	"image_view" to "Obrázok",
	"install" to "Inštalovať",
	"invalid_credentials_error" to "Neplatné poverenia.",
	"invalid_email_error" to "Neplatný e-mail.",
	"invalid_user_error" to "Neplatné poverenia používateľa.",
	"invoice_deleted_message" to "Vymazaná faktúra",
	"invoice_menu" to "Ponuka faktúr",
	"invoice_number" to "Faktúra #",
	"invoices" to "Faktúry",
	"items_option" to "Položky",
	"keep" to "Udržujte",
	"language_selector" to Formattable { (selected) -> "Jazyk ${selected}" },
	"last_name_hint" to "Priezvisko",
	"ledger" to "účtovná kniha",
	"legal" to "Právna stránka",
	"legal_error" to "Musí akceptovať podmienky používania služby a zásady ochrany osobných údajov",
	"liabilities" to "záväzky",
	"loading" to "Načítavanie",
	"login_required_error" to "Vyžaduje sa prihlásenie.",
	"logo_view" to "Logo",
	"logout" to "Odhlásenie",
	"logout_prompt" to "Vaše údaje nie sú synchronizované s cloudom. Odstránenie faktúr, odhadov, klientov a ďalších informácií pri odhlásení?",
	"main_screen" to "Hlavná obrazovka",
	"mark_paid" to "Označiť ako zaplatené",
	"message" to "Správa",
	"missing_sender_msg" to "Dokument bez informácií o odosielateľovi môže byť neplatný.",
	"missing_sender_title" to "Chýbajú informácie o odosielateľovi",
	"month_april" to "apríl",
	"month_april_short" to "Apríl",
	"month_august" to "August",
	"month_august_short" to "Aug.",
	"month_december" to "December",
	"month_december_short" to "Dec.",
	"month_february" to "Február",
	"month_february_short" to "Feb.",
	"month_january" to "Január",
	"month_january_short" to "Jan.",
	"month_july" to "Júl",
	"month_july_short" to "Júl",
	"month_june" to "jún",
	"month_june_short" to "Jún",
	"month_march" to "Marec",
	"month_march_short" to "Marec",
	"month_may" to "Máj",
	"month_may_short" to "Máj",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Október",
	"month_october_short" to "Okt.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Nepodarilo sa overiť.",
	"new" to "Nový",
	"news" to "Novinky",
	"next" to "Ďalšie",
	"no" to "nie",
	"no_data_available" to "Nie sú k dispozícii žiadne údaje",
	"no_search_results" to "Je nám ľúto, ale nenašli sme žiadne výsledky.",
	"none" to "Žiadne",
	"note_fields" to "Poznámky",
	"note_hint" to "Poznámka",
	"notifications_option" to "Oznámenia",
	"ok" to "OK",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Základné časti aplikácie DingVoice sú navždy bezplatné. Naším poslaním je pomáhať vám rozvíjať vaše podnikanie, pomôžte nám zlepšovať sa zdieľaním vašej spätnej väzby.",
	"onboarding_aboutus_title" to "Sme tu, aby sme vám pomohli",
	"onboarding_paid_1" to "Prístup k profesionálne vyzerajúcim šablónam",
	"onboarding_paid_2" to "Pozrite sa hlbšie na svoje financie",
	"onboarding_paid_3" to "Rýchle riešenie problémov",
	"onboarding_paid_4" to "Uchovávajte svoje dokumenty v bezpečí",
	"onboarding_paid_5" to "Aktuálne informácie o zmenách stavu faktúr",
	"onboarding_paid_title" to "Rastiete ešte rýchlejšie s...",
	"onboarding_paywall_message" to "Užívajte si všetky prémiové funkcie za najlepšiu cenu.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Len ${currencySymbol}${introAmount} za ${introDuration} ${introPeriod} a potom ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Len ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Zadarmo na ${trialDuration} ${trialPeriod} a potom len ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Zadarmo pre ${trialDuration} ${trialPeriod}, potom ${currencySymbol}${introductoryAmount} pre ${introductoryDuration} ${introPeriod} a potom už len ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automatická obnova. Zrušiť kedykoľvek.",
	"onboarding_paywall_restore" to "Obnovenie nákupu",
	"onboarding_paywall_restore_failure" to "Obnovenie nákupov zlyhalo, skúste to neskôr alebo kontaktujte podporu.",
	"onboarding_paywall_restore_success" to "Obnovenie úspešné!",
	"onboarding_paywall_title" to "Dosiahnite veľkosť!",
	"onboarding_welcome_message" to "Kompletný súbor nástrojov na správu faktúr a odhadov.",
	"onboarding_welcome_title" to "Vitajte v službe DingVoice",
	"other_state" to "Ostatné",
	"overdue_state" to "Premlčané",
	"paid_state" to "Platené",
	"password" to "Heslo",
	"password_blank_error" to "Vyžaduje sa heslo.",
	"password_mismatch_error" to "Heslá sa nezhodujú.",
	"pay" to "zaplatiť",
	"payment_instructions_label" to "Pokyny na platbu",
	"payment_reminders" to "Pripomienky platieb",
	"phone_number_fields" to "Telefónne číslo",
	"phone_number_hint" to "Telefónne číslo",
	"postcode_hint" to "poštové smerovacie číslo",
	"preview" to "Náhľad",
	"preview_menu_hint" to "Ponuka náhľadu",
	"price" to "Cena",
	"privacy_policy" to "Zásady ochrany osobných údajov",
	"profile" to "Profil",
	"profitable_client" to "Váš najziskovejší klient je...",
	"profits_report" to "Zisky",
	"promo_content" to "Propagačný obsah",
	"quantity_hint" to "Množstvo",
	"quantity_label_hint" to "Označenie množstva",
	"query_info" to "Dotaz umožňuje napísať vlastné výpočty, ktoré sa vzťahujú na položky a iné daňové pravidlá ako vstupy. Pridanie symbolu @ a popisu položky alebo dane na prepojenie s iným záznamom +, -, *, /, % - matematické operátory",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} bol prijatý vaším klientom." },
	"received_state" to "Prijaté",
	"registration_code_fields" to "Registračný Kód",
	"reminder" to "Pripomienka",
	"reports" to "Správy",
	"reports_not_available" to "Žiadne správy nie sú k dispozícii, vráťte sa neskôr.",
	"reveal_password" to "Odhaliť heslo",
	"revenue" to "výnosy",
	"sales_tax" to "DPH",
	"sales_tax_number" to "IČ DPH",
	"save" to "Uložiť",
	"scan_document" to "Skenovanie dokumentu",
	"search" to "Vyhľadávanie",
	"select" to "Vyberte",
	"select_icon" to "Vyberte ikonu",
	"select_signature" to "Vyberte podpis",
	"select_template" to "Vyberte šablónu",
	"send" to "Odoslať",
	"sent_state" to "Odoslané",
	"setup_sender" to "Nastaviť odosielateľa",
	"share_option" to "Zdieľať",
	"ship_to" to "Odoslať na adresu",
	"sign_in" to "Prihlásiť sa",
	"sign_in_apple" to "Prihlásenie pomocou Apple",
	"sign_in_email" to "Prihláste sa pomocou e-mailu",
	"sign_in_facebook" to "Prihláste sa pomocou služby Facebook",
	"sign_in_google" to "Prihlásenie pomocou služby Google",
	"sign_up" to "Zaregistrujte sa",
	"sign_up_label" to "Nemáte účet?",
	"sign_up_link_label" to "Zaregistrujte sa!",
	"signatures_option" to "Podpisy",
	"skip" to "Vynechať",
	"sold_items_report" to "Predané Položky",
	"sort_by" to "Zoradiť podľa:",
	"status_update" to "Aktualizácia stavu dokumentu",
	"sub_annual" to "Výročný",
	"sub_annual_price" to Formattable { (price) -> "${price}/rok" },
	"sub_backups" to "Cloudové zálohy",
	"sub_insights" to "Insights",
	"sub_month" to "Mesačne",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mesiac" },
	"sub_notifications" to "Notifikácie",
	"sub_popup_message" to "Predplatné sa čoskoro blíži. Skontroluj to tu za chvíľku.",
	"sub_support" to "Podpora",
	"sub_templates" to "Šablóny",
	"subscribe" to "Prihlásiť sa na",
	"subscription_header" to "Rozšírte svoje podnikanie s:",
	"subscription_option" to "Predplatné",
	"subscriptions_load_fail" to "Je nám ľúto, ale momentálne nemôžeme načítať vaše predplatné. Skúste to prosím neskôr alebo kontaktujte náš tím podpory a požiadajte o pomoc.",
	"subtotal" to Formattable { (sum) -> "Medzisúčet: ${sum}" },
	"subtotal_label" to "Medzisúčet",
	"sum" to "Suma",
	"support_option" to "Podpora",
	"switch_reports" to "Prepnúť Prehľad",
	"tax" to "Daň",
	"tax_deleted_message" to "Vymazaná daň",
	"tax_description_warning" to "Ak sa použije, zobrazí sa na faktúre",
	"tax_input_flat" to "Ploché",
	"tax_input_percent" to "Percentá",
	"tax_number_fields" to "Daňové číslo",
	"tax_number_hint" to "Daňové číslo",
	"tax_query_info_description" to "Informácie o požiadavke",
	"tax_report" to "Daň",
	"tax_rules_option" to "Daňové pravidlá",
	"taxed_clients_report" to "Zdanení klienti",
	"template" to "Šablóna",
	"template_selector_info" to "Potiahnite prstom a zmeňte predvolenú šablónu",
	"template_unlock_promo" to "Prístup k ďalším šablónam so službou Premium",
	"templates_option" to "Šablóny",
	"terms_of_service" to "Podmienky služby",
	"thank_you" to "Ďakujeme",
	"to" to "Na",
	"total" to Formattable { (sum) -> "Spolu: ${sum}" },
	"total_label" to "Celkom",
	"undo_action" to "Zrušiť",
	"unit" to "jednotka",
	"unit_cost_hint" to "Jednotkové náklady",
	"unknown_client" to "Neznámy Klient",
	"unlock" to "Odomknutie stránky",
	"unnamed_item" to "Nepomenovaná položka",
	"upgrade" to "Aktualizácia",
	"user_collision_error" to "Účet už existuje.",
	"valid_until" to "Platné Do",
	"warning" to "Varovanie!",
	"yes" to "áno",
    )
    
}