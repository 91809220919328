package com.dingvoice

import androidx.compose.runtime.Composable
import com.dingvoice.content.FeaturesPage
import com.dingvoice.content.StoreBadges
import com.dingvoice.localizations.Localizations
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.HTMLElement
import org.w3c.dom.Window

external fun require(module: String): dynamic

@ExperimentalComposeWebApi
fun main() {
    val rootElement = document.getElementById("root") as? HTMLElement
    val readerRootElement = document.getElementById("readerRoot") as? HTMLElement
    val accountDeletionRootElement = document.getElementById("accountDeletion") as? HTMLElement

    val language = window.navigator.language
    if (Localizations.isSupported(language)) {
        Localizations.setLocale(language)
    }

    if (rootElement != null) {
        renderComposable(root = rootElement) {
            MainLandingPage()
        }
    } else if (readerRootElement != null) {
        renderComposable(root = readerRootElement) {
            InvoiceReaderPage()
        }
    } else if (accountDeletionRootElement != null) {
        renderComposable(root = accountDeletionRootElement) {
            AccountDeletionPage()
        }
    }
}

@ExperimentalComposeWebApi
@Composable
fun Landing(isMobile: Boolean) {
    Div(attrs = {
        style {
            width(100.percent)
            height(100.percent)
        }
    }) {
        FeaturesPage(isMobile)
//        PricingPage()

        StoreBadges()
    }
}

fun Window.isMobile(): Boolean {
    val matchScreen = window.matchMedia("only screen and (max-width: 760px)").matches

    return window.innerWidth < 720 || matchScreen ||
        Regex(
            "/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/",
        ).containsMatchIn(
            window.navigator.userAgent,
        ) ||
        Regex(
            "/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/",
        ).containsMatchIn(
            window.navigator.platform,
        )
}
