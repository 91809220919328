// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Hi  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} और ${privacyPolicy} स्वीकार करें" }
override val account: String = "अकाउंट"
override val accounting: String = "लेखांकन"
override val accruals: String = "स्त्रोतों"
override val action_code_error: String = "अमान्य कोड."
override val add_client_action: String = "क्लाइंट जोड़ें"
override val add_customer: String = "ग्राहक जोड़ें"
override val add_expense: String = "खर्च जोड़ें"
override val add_fields_label: String = "जोड़ना:"
override val add_invoice_action: String = "बनाना"
override val add_item_action: String = "आइटम जोड़ें"
override val add_item_button: String = "आइटम जोड़ें"
override val add_signature_action: String = "हस्ताक्षर जोड़ें"
override val add_tax_action: String = "कर जोड़ें"
override val address_fields: String = "पता"
override val address_line_hint: String = "पता रेखा"
override val advanced: String = "उन्नत"
override val agree_marketing: String = "मैं प्रत्यक्ष विपणन संचार प्राप्त करने के लिए सहमत हूं"
override val all_notifications: String = "सभी सूचनाएँ"
override val amortization: String = "ऋणमुक्ति"
override val amount: String = "राशि"
override val asset: String = "संपत्ति"
override val attach_file: String = "फ़ाइल अटैच करें"
override val attachment: String = "आसक्ति"
override val auth_error: String = "क्रिया विफल रही, पुन: प्रयास करें."
override val available_for_paid: String = "क्लाउड सिंक्रनाइज़ेशन केवल सशुल्क सदस्यता के अंतर्गत उपलब्ध है."
override val backups_option: String = "बैकअप"
override val balance: String = "संतुलन"
override val bank_account: String = "बैंक खाता"
override val bill_number: String = "बिल की संख्या"
override val cancel: String = "रद्द करना"
override val cancel_trial: String = "किसी भी समय रद्द करें"
override val capital: String = "पूंजी"
override val cash: String = "नकद"
override val categories: String = "श्रेणियाँ"
override val category: String = "श्रेणी"
override val category_add: String = "श्रेणी जोड़ें"
override val category_delete: String = "श्रेणी हटाएं"
override val category_name: String = "श्रेणी नाम"
override val change_language: Formattable = Formattable { (language) -> "चालान भाषा: ${language}" }
override val change_language_label: String = "चालान भाषा"
override val change_template: String = "टेम्पलेट परिवर्तित करें"
override val city_hint: String = "शहर"
override val clear: String = "स्पष्ट"
override val client: String = "ग्राहक"
override val client_actions: String = "क्लाइंट क्रियाएँ"
override val client_deleted_message: String = "क्लाइंट हटाया गया"
override val clients_error_required_fields: String = "कम से कम एक मान की आवश्यकता है।"
override val clients_option: String = "ग्राहकों"
override val close: String = "बंद करना"
override val cloud_sync: String = "क्लाउड सिंक्रनाइज़ेशन"
override val coming_soon: String = "जल्दी ही आगमन!"
override val company_logo: String = "कंपनी लोगो"
override val company_name_hint: String = "कंपनी"
override val confirm_password_label: String = "पासवर्ड की पुष्टि करें"
override val confirmation_title: String = "क्या तुम पक्के हो?"
override val convert_to_invoice: String = "इनवॉइस में कनवर्ट करें"
override val country_hint: String = "देश"
override val create_client: String = "क्लाइंट बनाएँ"
override val create_company: String = "कंपनी बनाएँ"
override val create_document: String = "दस्तावेज़ बनाएँ"
override val create_estimate: String = "अनुमान"
override val create_invoice: String = "बीजक"
override val create_item: String = "आइटम बनाएँ"
override val create_new_client: String = "नया क्लाइंट बनाएँ"
override val create_new_item: String = "नया आइटम बनाएँ"
override val create_new_tax: String = "नया कर बनाएँ"
override val create_signature: String = "हस्ताक्षर"
override val created: String = "बनाया"
override val credit: String = "क्रेडिट"
override val customer: String = "ग्राहक"
override val date: String = "खजूर"
override val debit: String = "डेबिट"
override val debt: String = "कर्ज़"
override val default_currency: Formattable = Formattable { (currency) -> "डिफ़ॉल्ट मुद्रा ${currency}" }
override val default_currency_label: String = "डिफ़ॉल्ट मुद्रा"
override val delete: String = "मिटाना"
override val delete_account: String = "खाता हटा दो"
override val delete_confirmation_message: String = "आपका सारा डेटा खो जाएगा।"
override val delete_item_title: String = "आइटम हटाएँ?"
override val delete_signature: String = "हस्ताक्षर हटाएँ"
override val depreciation: String = "अवमूल्यन"
override val description: String = "वर्णन"
override val description_hint: String = "वर्णन"
override val detailed_report: String = "विस्तृत रिपोर्ट"
override val disabled: String = "विकलांग"
override val discount: String = "छूट"
override val discount_hint: String = "छूट"
override val display_af: String = "अफ्रीकी"
override val display_am: String = "अम्हारिक्"
override val display_ar: String = "अरबी"
override val display_as: String = "असमिया"
override val display_az: String = "आज़रबाइजानी"
override val display_be: String = "बेलारूसी"
override val display_bg: String = "बल्गेरियाई"
override val display_bn: String = "बंगाली"
override val display_bs: String = "बोस्नियन"
override val display_ca: String = "कातालान"
override val display_cs: String = "चेक"
override val display_da: String = "दानिश"
override val display_de: String = "जर्मन"
override val display_el: String = "यूनानी"
override val display_en: String = "अंग्रेज़ी"
override val display_es: String = "स्पेनी"
override val display_es_MX: String = "स्पैनिश (मेक्सिको)"
override val display_et: String = "एस्टोनियाई"
override val display_eu: String = "बास्क"
override val display_fa: String = "फारसी"
override val display_fi: String = "फिनिश"
override val display_fil: String = "फ़िलिपीनो"
override val display_fr: String = "फ्रांसीसी"
override val display_fr_CA: String = "कैनेडियन फ्रेंच"
override val display_gl: String = "गैलिशियन्"
override val display_gu: String = "गुजराती"
override val display_he: String = "हिब्रू"
override val display_hi: String = "हिंदी"
override val display_hr: String = "क्रोएशियाई"
override val display_hu: String = "हंगेरियन"
override val display_hy: String = "अर्मेनियाई"
override val display_id: String = "इन्डोनेशियाई"
override val display_is: String = "आइसलैंड का"
override val display_it: String = "इतालवी"
override val display_ja: String = "जापानी"
override val display_ka: String = "जॉर्जीयन्"
override val display_kk: String = "कजाख"
override val display_km: String = "सेंट्रल खमेर"
override val display_kn: String = "कन्नड़"
override val display_ko: String = "कोरियाई"
override val display_ky: String = "किरगिज़"
override val display_lo: String = "लाओ भाषा"
override val display_lt: String = "लिथुआनियाई"
override val display_lv: String = "लातवियाई"
override val display_mk: String = "मेसीडोनियन"
override val display_ml: String = "मलयालम"
override val display_mn: String = "मंगोलियन"
override val display_mr: String = "मराठी"
override val display_ms: String = "मलय"
override val display_my: String = "बर्मी"
override val display_nb: String = "नॉर्वेजियन बोकमाली"
override val display_ne: String = "नेपाली"
override val display_nl: String = "डच"
override val display_or: String = "ओरिया"
override val display_pa: String = "पंजाबी"
override val display_pl: String = "पोलिश"
override val display_pt: String = "पुर्तगाली"
override val display_pt_BR: String = "पुर्तगाली (ब्राजील)"
override val display_pt_PT: String = "यूरोपीय पुर्तगाली"
override val display_ro: String = "रोमानियाई"
override val display_ru: String = "रूसी"
override val display_si: String = "सिन्हाला"
override val display_sk: String = "स्लोवाकी"
override val display_sl: String = "स्लोवेनियाई"
override val display_sq: String = "अल्बानियन"
override val display_sr: String = "सर्बियाई"
override val display_sv: String = "स्वीडिश"
override val display_sw: String = "स्वाहिली"
override val display_ta: String = "तामिल"
override val display_te: String = "तेलुगू"
override val display_th: String = "थाई"
override val display_tr: String = "तुर्कीयाई"
override val display_uk: String = "यूक्रेनी"
override val display_ur: String = "उर्दू"
override val display_uz: String = "उज़बेक"
override val display_vi: String = "वियतनामी"
override val display_zh_Hant_TW: String = "चीनी (ताइवान, पारंपरिक)"
override val display_zu: String = "ज़ुलु"
override val document_estimate: String = "अनुमान"
override val document_invoice: String = "बीजक"
override val document_quote: String = "कीमत की पेशकश"
override val done: String = "किया हुआ"
override val download_app: String = "ऐप डाउनलोड करें"
override val download_invoice: String = "इनवाइस को डाउनलोड करो"
override val download_pdf: String = "डाउनलोड पीडीऍफ़"
override val draw_signature: String = "कोई हस्ताक्षर आरेखित करें"
override val due: Formattable = Formattable { (date) -> "देय ${date}" }
override val due_date: String = "नियत दिनांक"
override val due_day: String = "प्रति दिन"
override val due_label: String = "बाकी"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} देय है ${dueWhen}!" }
override val due_today: String = "आज"
override val due_week: String = "एक सप्ताह के भीतर"
override val duplicate: String = "डुप्लिकेट"
override val duration_day: String = "दिन"
override val duration_month: String = "महीना"
override val duration_week: String = "सप्ताह"
override val duration_year: String = "वर्ष"
override val email: String = "ईमेल"
override val email_blank_error: String = "ईमेल की आवश्यकता है।"
override val email_error: String = "अमान्य ई-मेल पता."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "आपके व्यापार के लिए धन्यवाद। पूरे ${documentType} दस्तावेज़ को देखने के लिए, इस पत्र के अंत में बटन पर क्लिक करें। कृपया ध्यान दें कि यह ${dueDate} तक वैध है।" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "आपको एक नया ${documentType} ${invoiceNumber} प्राप्त हुआ है!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} देखें" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "प्रिय ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "आपके व्यापार के लिए धन्यवाद। पूरे ${documentType} को देखने के लिए इस पत्र के अंत में बटन पर क्लिक करें। कृपया शेष राशि का भुगतान ${dueDate} तक करें।" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "आपको एक नया ${documentType} ${invoiceNumber} प्राप्त हुआ है!" }
override val email_reset_success_message: String = "भेजे गए पासवर्ड को रीसेट करने के लिए ईमेल, कृपया अपने इनबॉक्स की जाँच करें।"
override val empty_clients_list_message: String = "आपके पास ट्रैक करने के लिए कोई ग्राहक नहीं है। कोई नया क्लाइंट जोड़ें."
override val empty_estimate_list_message: String = "अपना पहला अनुमान बनाएँ"
override val empty_expenses: String = "आपके पास ट्रैक करने के लिए कोई खर्च नहीं है। नया खर्च जोड़ें।"
override val empty_invoice_list_message: String = "अपना पहला इनवॉइस बनाएँ"
override val empty_items_list_message: String = "आपके पास कोई आइटम नहीं है, एक नया आइटम जोड़ें!"
override val empty_signatures_list_message: String = "कोई हस्ताक्षर नहीं मिले. एक बनाएँ!"
override val empty_taxes_list_message: String = "आपके पास कोई कर नियम नहीं हैं। कोई नया कर नियम जोड़ें."
override val empty_templates_list_message: String = "टेम्पलेट्स अनुपलब्ध हैं."
override val enabled: String = "सक्षम"
override val entry: String = "लेखा पृविष्टि"
override val equity: String = "इक्विटी"
override val estimate_number: String = "अनुमान #"
override val estimates: String = "अनुमान"
override val estimates_report: String = "अनुमान"
override val expense_bank_fees: String = "बैंक शुल्क"
override val expense_benefits: String = "फायदे"
override val expense_communication: String = "संचार"
override val expense_debt: String = "कर्ज"
override val expense_deleted: String = "खर्च हटा दिया गया"
override val expense_depreciation: String = "मूल्यह्रास"
override val expense_gifts: String = "उपहार"
override val expense_insurance: String = "बीमा"
override val expense_inventory: String = "इन्वेंटरी"
override val expense_legal: String = "कानूनी"
override val expense_maintenance: String = "रखरखाव"
override val expense_marketing: String = "मार्केटिंग"
override val expense_meals: String = "भोजन"
override val expense_office_supplies: String = "कार्यालय की आपूर्ति"
override val expense_rent: String = "किराया"
override val expense_salaries: String = "वेतन"
override val expense_shopping: String = "खरीदारी"
override val expense_software: String = "सॉफ्टवेयर"
override val expense_status_available: String = "उपलब्ध"
override val expense_status_processing: String = "प्रसंस्करण"
override val expense_tax: String = "कर"
override val expense_training: String = "प्रशिक्षण"
override val expense_transport: String = "परिवहन"
override val expense_travel: String = "यात्रा"
override val expense_utility: String = "उपयोगिता"
override val expenses: String = "खर्च"
override val feedback_option: String = "प्रतिपुष्टि"
override val filter: String = "छानना"
override val first_name_hint: String = "प्रथम नाम"
override val forgot_password: String = "रीसेट पासवर्ड"
override val forgot_password_label: String = "पासवर्ड भूल गए"
override val from: String = "से"
override val general_error: String = "गड़बड़ी हुई, कृपया बाद में फिर से कोशिश करें।"
override val general_ledger: String = "सामान्य खाता बही"
override val go_back_label: String = "पस जाओ"
override val group_by: String = "इसके द्वारा समूहीकृत करें:"
override val image_view: String = "प्रतिबिंब"
override val install: String = "इंस्टॉल करें"
override val invalid_credentials_error: String = "अमान्य क्रेडेंशियल्स."
override val invalid_email_error: String = "अमान्य ईमेल."
override val invalid_user_error: String = "अमान्य उपयोगकर्ता क्रेडेंशियल्स."
override val invoice_deleted_message: String = "इनवॉइस हटाया गया"
override val invoice_menu: String = "इनवॉइस मेनू"
override val invoice_number: String = "बीजक #"
override val invoices: String = "चालान"
override val items_option: String = "आइटम"
override val keep: String = "रखना"
override val language_selector: Formattable = Formattable { (selected) -> "भाषा ${selected}" }
override val last_name_hint: String = "कुलनाम"
override val ledger: String = "खाता बही"
override val legal: String = "कानूनी"
override val legal_error: String = "सेवा की शर्तों और गोपनीयता नीति को स्वीकार करना होगा"
override val liabilities: String = "प्रतिबद्धताएँ"
override val loading: String = "लोड"
override val login_required_error: String = "लॉगिन आवश्यक है."
override val logo_view: String = "लोगो"
override val logout: String = "लॉगआउट"
override val logout_prompt: String = "आपका डेटा क्लाउड के साथ सिंक्रनाइज़ नहीं है. लॉगआउट पर इनवॉइस, अनुमान, क्लाइंट और अन्य जानकारी हटाएँ?"
override val main_screen: String = "मुख्य स्क्रीन"
override val mark_paid: String = "भुगतान के रूप में चिह्नित करें"
override val message: String = "संदेश"
override val missing_sender_msg: String = "प्रेषक की जानकारी के बिना दस्तावेज़ अमान्य हो सकता है।"
override val missing_sender_title: String = "प्रेषक की जानकारी गुम है"
override val month_april: String = "अप्रैल"
override val month_april_short: String = "अप्रैल"
override val month_august: String = "अगस्त"
override val month_august_short: String = "अगस्त"
override val month_december: String = "दिसंबर"
override val month_december_short: String = "दिसंबर"
override val month_february: String = "फरवरी"
override val month_february_short: String = "फ़रवरी"
override val month_january: String = "जनवरी"
override val month_january_short: String = "जनवरी"
override val month_july: String = "जुलाई"
override val month_july_short: String = "जुलाई"
override val month_june: String = "जून"
override val month_june_short: String = "जून"
override val month_march: String = "मार्च"
override val month_march_short: String = "मार्च"
override val month_may: String = "मई"
override val month_may_short: String = "मई"
override val month_november: String = "नवंबर"
override val month_november_short: String = "नवंबर"
override val month_october: String = "अक्टूबर"
override val month_october_short: String = "अक्टूबर"
override val month_september: String = "सितंबर"
override val month_september_short: String = "सितम्बर"
override val multifactor_error: String = "सत्यापित करने में विफल."
override val new: String = "नया"
override val news: String = "समाचार"
override val next: String = "अगला"
override val no: String = "नहीं"
override val no_data_available: String = "कोई डाटा उपलब्ध नहीं"
override val no_search_results: String = "क्षमा करें, हम कोई परिणाम नहीं मिल सका।"
override val none: String = "कोई नहीं"
override val note_fields: String = "नोट्स"
override val note_hint: String = "नोट"
override val notifications_option: String = "सूचनाएँ"
override val ok: String = "ठीक है"
override val onboarding: String = "ज्ञानप्राप्ति"
override val onboarding_aboutus_message: String = "DingVoice के आवश्यक भाग हमेशा के लिए निःशुल्क हैं। हमारा मिशन आपको अपना व्यवसाय बढ़ाने में मदद करना है, अपनी प्रतिक्रिया साझा करके हमें बेहतर बनाने में मदद करना है।"
override val onboarding_aboutus_title: String = "हम यहाँ मदद करने के लिए हैं"
override val onboarding_paid_1: String = "पेशेवर दिखने वाले टेम्प्लेट एक्सेस करें"
override val onboarding_paid_2: String = "अपने वित्तीय में गहराई से देखें"
override val onboarding_paid_3: String = "समस्याओं का शीघ्र निस्तारण कराएं"
override val onboarding_paid_4: String = "अपने दस्तावेज सुरक्षित रखें"
override val onboarding_paid_5: String = "चालान स्थिति में परिवर्तन के साथ अद्यतित रहें"
override val onboarding_paid_title: String = "इसके साथ और भी तेजी से बढ़ें..."
override val onboarding_paywall_message: String = "सर्वोत्तम मूल्य पर अभी सभी प्रीमियम सुविधाओं का आनंद लें।"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} के लिए बस ${currencySymbol}${introAmount} और फिर ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "बस ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} के लिए मुफ़्त और फिर ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} के लिए मुफ़्त, फिर ${currencySymbol}${introductoryAmount} के लिए ${introductoryDuration} ${introPeriod} के लिए और उसके बाद सिर्फ़ ${currencySymbol}${amount} / ${duration} के लिए" }
override val onboarding_paywall_renewable: String = "स्वत: नवीकरणीय। किसी भी समय रद्द करें।"
override val onboarding_paywall_restore: String = "पुनःस्थापन क्रय"
override val onboarding_paywall_restore_failure: String = "खरीदारी पुनर्स्थापित करना विफल हो गया है, बाद में पुन: प्रयास करें या समर्थन से संपर्क करें।"
override val onboarding_paywall_restore_success: String = "बहाल करना सफल!"
override val onboarding_paywall_title: String = "महानता हासिल करो!"
override val onboarding_welcome_message: String = "आपके चालान और अनुमानों को प्रबंधित करने के लिए एक संपूर्ण टूलसेट।"
override val onboarding_welcome_title: String = "डिंगवॉइस में आपका स्वागत है"
override val other_state: String = "अन्य"
override val overdue_state: String = "विलंबित"
override val paid_state: String = "भुगतान किया"
override val password: String = "पासवर्ड"
override val password_blank_error: String = "पासवर्ड की आवश्यकता है."
override val password_mismatch_error: String = "पासवर्ड मेल नहीं खाते."
override val pay: String = "भुगतान करें"
override val payment_instructions_label: String = "भुगतान निर्देश"
override val payment_reminders: String = "भुगतान अनुस्मारक"
override val phone_number_fields: String = "फोन संख्या"
override val phone_number_hint: String = "फोन संख्या"
override val postcode_hint: String = "पोस्टकोडअ"
override val preview: String = "पूर्वदर्शन"
override val preview_menu_hint: String = "पूर्वावलोकन मेनू"
override val price: String = "दाम"
override val privacy_policy: String = "गोपनीयता नीति"
override val profile: String = "रूपरेखा"
override val profitable_client: String = "आपका सबसे लाभदायक ग्राहक है ..."
override val profits_report: String = "मुनाफे"
override val promo_content: String = "प्रचार सामग्री"
override val quantity_hint: String = "परिमाण"
override val quantity_label_hint: String = "मात्रा लेबल"
override val query_info: String = "क्वेरी आपको इनपुट के रूप में आइटम और अन्य कर नियमों दोनों का उल्लेख करते हुए कस्टम परिकलन लिखने की अनुमति देती है. किसी अन्य प्रविष्टि +, -, *, /, % - गणित ऑपरेटरों से लिंक करने के लिए @ प्रतीक और आइटम या कर विवरण जोड़ें"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "आपके क्लाइंट को प्राप्त हुआ था ${documentType} ${documentNumber}।" }
override val received_state: String = "प्राप्त हुआ"
override val registration_code_fields: String = "रजिस्ट्रेशन कोड"
override val reminder: String = "अनुस्मारक"
override val reports: String = "रिपोर्ट"
override val reports_not_available: String = "कोई रिपोर्ट उपलब्ध नहीं है, बाद में वापस आएं।"
override val reveal_password: String = "पासवर्ड प्रकट करें"
override val revenue: String = "रेवेन्यू"
override val sales_tax: String = "वैट"
override val sales_tax_number: String = "वैट नंबर"
override val save: String = "रक्षा कर"
override val scan_document: String = "दस्तावेज़ स्कैन करें"
override val search: String = "ढूँढ"
override val select: String = "चुनें"
override val select_icon: String = "आइकन चुनें"
override val select_signature: String = "हस्ताक्षर का चयन करें"
override val select_template: String = "टेम्पलेट का चयन करें"
override val send: String = "भेजना"
override val sent_state: String = "प्रेषित"
override val setup_sender: String = "सेंडर सेट अप करें"
override val share_option: String = "हिस्सा"
override val ship_to: String = "यहां भेजें"
override val sign_in: String = "साइन इन करें"
override val sign_in_apple: String = "Apple के साथ साइन इन करें"
override val sign_in_email: String = "ईमेल के साथ साइन इन करें"
override val sign_in_facebook: String = "Facebook के साथ साइन इन करें"
override val sign_in_google: String = "Google के साथ साइन इन करें"
override val sign_up: String = "नाम लिखो"
override val sign_up_label: String = "कोई खाता नहीं है?"
override val sign_up_link_label: String = "नाम लिखो!"
override val signatures_option: String = "हस्ताक्षर"
override val skip: String = "छोड़ें"
override val sold_items_report: String = "बेचे गए आइटम"
override val sort_by: String = "इसके द्वारा सॉर्ट करें:"
override val status_update: String = "दस्तावेज़ स्थिति अद्यतन"
override val sub_annual: String = "वार्षिक"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/वर्ष" }
override val sub_backups: String = "क्लाउड बैकअप"
override val sub_insights: String = "इनसाइट्स"
override val sub_month: String = "मासिक"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/महीना" }
override val sub_notifications: String = "सूचनाएं"
override val sub_popup_message: String = "सब्सक्रिप्शन जल्द ही आ रहे हैं। थोड़ी देर में यहाँ वापस देखें।"
override val sub_support: String = "सहायता"
override val sub_templates: String = "टेम्पलेट"
override val subscribe: String = "सदस्यता लें"
override val subscription_header: String = "अपने व्यवसाय को निम्न के साथ बढ़ाएँ:"
override val subscription_option: String = "चंदा"
override val subscriptions_load_fail: String = "क्षमा करें, हम इस समय आपकी सदस्यताएँ पुनर्प्राप्त करने में असमर्थ हैं। कृपया बाद में पुन: प्रयास करें या सहायता के लिए हमारी सहायता टीम से संपर्क करें।"
override val subtotal: Formattable = Formattable { (sum) -> "उप-योग: ${sum}" }
override val subtotal_label: String = "उप-योग"
override val sum: String = "राशि"
override val support_option: String = "सहारा"
override val switch_reports: String = "रिपोर्ट स्विच करें"
override val tax: String = "कर"
override val tax_deleted_message: String = "कर हटाया गया"
override val tax_description_warning: String = "यदि उपयोग किया जाता है तो यह एक चालान पर दिखाई देता है"
override val tax_input_flat: String = "समतल"
override val tax_input_percent: String = "प्रतिशत"
override val tax_number_fields: String = "कर संख्या"
override val tax_number_hint: String = "कर संख्या"
override val tax_query_info_description: String = "क्वेरी जानकारी"
override val tax_report: String = "कर"
override val tax_rules_option: String = "कर नियम"
override val taxed_clients_report: String = "कर लगाए गए ग्राहक"
override val template: String = "साँचा"
override val template_selector_info: String = "डिफ़ॉल्ट टेम्पलेट परिवर्तित करने के लिए स्वाइप करें"
override val template_unlock_promo: String = "प्रीमियम के साथ अधिक टेम्प्लेट एक्सेस करें"
override val templates_option: String = "टेम्पलेट्स"
override val terms_of_service: String = "सेवा की शर्तें"
override val thank_you: String = "धन्यवाद"
override val to: String = "तक"
override val total: Formattable = Formattable { (sum) -> "कुल: ${sum}" }
override val total_label: String = "कुल"
override val undo_action: String = "खोलना"
override val unit: String = "इकाई"
override val unit_cost_hint: String = "इकाई लागत"
override val unknown_client: String = "अज्ञात क्लाइंट"
override val unlock: String = "अनलॉक करें"
override val unnamed_item: String = "अनाम आइटम"
override val upgrade: String = "अपग्रेड"
override val user_collision_error: String = "खाता पहले से मौजूद है."
override val valid_until: String = "तब तक मान्य"
override val warning: String = "चेतावनी!"
override val yes: String = "हाँ"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} और ${privacyPolicy} स्वीकार करें" },
	"account" to "अकाउंट",
	"accounting" to "लेखांकन",
	"accruals" to "स्त्रोतों",
	"action_code_error" to "अमान्य कोड.",
	"add_client_action" to "क्लाइंट जोड़ें",
	"add_customer" to "ग्राहक जोड़ें",
	"add_expense" to "खर्च जोड़ें",
	"add_fields_label" to "जोड़ना:",
	"add_invoice_action" to "बनाना",
	"add_item_action" to "आइटम जोड़ें",
	"add_item_button" to "आइटम जोड़ें",
	"add_signature_action" to "हस्ताक्षर जोड़ें",
	"add_tax_action" to "कर जोड़ें",
	"address_fields" to "पता",
	"address_line_hint" to "पता रेखा",
	"advanced" to "उन्नत",
	"agree_marketing" to "मैं प्रत्यक्ष विपणन संचार प्राप्त करने के लिए सहमत हूं",
	"all_notifications" to "सभी सूचनाएँ",
	"amortization" to "ऋणमुक्ति",
	"amount" to "राशि",
	"asset" to "संपत्ति",
	"attach_file" to "फ़ाइल अटैच करें",
	"attachment" to "आसक्ति",
	"auth_error" to "क्रिया विफल रही, पुन: प्रयास करें.",
	"available_for_paid" to "क्लाउड सिंक्रनाइज़ेशन केवल सशुल्क सदस्यता के अंतर्गत उपलब्ध है.",
	"backups_option" to "बैकअप",
	"balance" to "संतुलन",
	"bank_account" to "बैंक खाता",
	"bill_number" to "बिल की संख्या",
	"cancel" to "रद्द करना",
	"cancel_trial" to "किसी भी समय रद्द करें",
	"capital" to "पूंजी",
	"cash" to "नकद",
	"categories" to "श्रेणियाँ",
	"category" to "श्रेणी",
	"category_add" to "श्रेणी जोड़ें",
	"category_delete" to "श्रेणी हटाएं",
	"category_name" to "श्रेणी नाम",
	"change_language" to Formattable { (language) -> "चालान भाषा: ${language}" },
	"change_language_label" to "चालान भाषा",
	"change_template" to "टेम्पलेट परिवर्तित करें",
	"city_hint" to "शहर",
	"clear" to "स्पष्ट",
	"client" to "ग्राहक",
	"client_actions" to "क्लाइंट क्रियाएँ",
	"client_deleted_message" to "क्लाइंट हटाया गया",
	"clients_error_required_fields" to "कम से कम एक मान की आवश्यकता है।",
	"clients_option" to "ग्राहकों",
	"close" to "बंद करना",
	"cloud_sync" to "क्लाउड सिंक्रनाइज़ेशन",
	"coming_soon" to "जल्दी ही आगमन!",
	"company_logo" to "कंपनी लोगो",
	"company_name_hint" to "कंपनी",
	"confirm_password_label" to "पासवर्ड की पुष्टि करें",
	"confirmation_title" to "क्या तुम पक्के हो?",
	"convert_to_invoice" to "इनवॉइस में कनवर्ट करें",
	"country_hint" to "देश",
	"create_client" to "क्लाइंट बनाएँ",
	"create_company" to "कंपनी बनाएँ",
	"create_document" to "दस्तावेज़ बनाएँ",
	"create_estimate" to "अनुमान",
	"create_invoice" to "बीजक",
	"create_item" to "आइटम बनाएँ",
	"create_new_client" to "नया क्लाइंट बनाएँ",
	"create_new_item" to "नया आइटम बनाएँ",
	"create_new_tax" to "नया कर बनाएँ",
	"create_signature" to "हस्ताक्षर",
	"created" to "बनाया",
	"credit" to "क्रेडिट",
	"customer" to "ग्राहक",
	"date" to "खजूर",
	"debit" to "डेबिट",
	"debt" to "कर्ज़",
	"default_currency" to Formattable { (currency) -> "डिफ़ॉल्ट मुद्रा ${currency}" },
	"default_currency_label" to "डिफ़ॉल्ट मुद्रा",
	"delete" to "मिटाना",
	"delete_account" to "खाता हटा दो",
	"delete_confirmation_message" to "आपका सारा डेटा खो जाएगा।",
	"delete_item_title" to "आइटम हटाएँ?",
	"delete_signature" to "हस्ताक्षर हटाएँ",
	"depreciation" to "अवमूल्यन",
	"description" to "वर्णन",
	"description_hint" to "वर्णन",
	"detailed_report" to "विस्तृत रिपोर्ट",
	"disabled" to "विकलांग",
	"discount" to "छूट",
	"discount_hint" to "छूट",
	"display_af" to "अफ्रीकी",
	"display_am" to "अम्हारिक्",
	"display_ar" to "अरबी",
	"display_as" to "असमिया",
	"display_az" to "आज़रबाइजानी",
	"display_be" to "बेलारूसी",
	"display_bg" to "बल्गेरियाई",
	"display_bn" to "बंगाली",
	"display_bs" to "बोस्नियन",
	"display_ca" to "कातालान",
	"display_cs" to "चेक",
	"display_da" to "दानिश",
	"display_de" to "जर्मन",
	"display_el" to "यूनानी",
	"display_en" to "अंग्रेज़ी",
	"display_es" to "स्पेनी",
	"display_es_MX" to "स्पैनिश (मेक्सिको)",
	"display_et" to "एस्टोनियाई",
	"display_eu" to "बास्क",
	"display_fa" to "फारसी",
	"display_fi" to "फिनिश",
	"display_fil" to "फ़िलिपीनो",
	"display_fr" to "फ्रांसीसी",
	"display_fr_CA" to "कैनेडियन फ्रेंच",
	"display_gl" to "गैलिशियन्",
	"display_gu" to "गुजराती",
	"display_he" to "हिब्रू",
	"display_hi" to "हिंदी",
	"display_hr" to "क्रोएशियाई",
	"display_hu" to "हंगेरियन",
	"display_hy" to "अर्मेनियाई",
	"display_id" to "इन्डोनेशियाई",
	"display_is" to "आइसलैंड का",
	"display_it" to "इतालवी",
	"display_ja" to "जापानी",
	"display_ka" to "जॉर्जीयन्",
	"display_kk" to "कजाख",
	"display_km" to "सेंट्रल खमेर",
	"display_kn" to "कन्नड़",
	"display_ko" to "कोरियाई",
	"display_ky" to "किरगिज़",
	"display_lo" to "लाओ भाषा",
	"display_lt" to "लिथुआनियाई",
	"display_lv" to "लातवियाई",
	"display_mk" to "मेसीडोनियन",
	"display_ml" to "मलयालम",
	"display_mn" to "मंगोलियन",
	"display_mr" to "मराठी",
	"display_ms" to "मलय",
	"display_my" to "बर्मी",
	"display_nb" to "नॉर्वेजियन बोकमाली",
	"display_ne" to "नेपाली",
	"display_nl" to "डच",
	"display_or" to "ओरिया",
	"display_pa" to "पंजाबी",
	"display_pl" to "पोलिश",
	"display_pt" to "पुर्तगाली",
	"display_pt_BR" to "पुर्तगाली (ब्राजील)",
	"display_pt_PT" to "यूरोपीय पुर्तगाली",
	"display_ro" to "रोमानियाई",
	"display_ru" to "रूसी",
	"display_si" to "सिन्हाला",
	"display_sk" to "स्लोवाकी",
	"display_sl" to "स्लोवेनियाई",
	"display_sq" to "अल्बानियन",
	"display_sr" to "सर्बियाई",
	"display_sv" to "स्वीडिश",
	"display_sw" to "स्वाहिली",
	"display_ta" to "तामिल",
	"display_te" to "तेलुगू",
	"display_th" to "थाई",
	"display_tr" to "तुर्कीयाई",
	"display_uk" to "यूक्रेनी",
	"display_ur" to "उर्दू",
	"display_uz" to "उज़बेक",
	"display_vi" to "वियतनामी",
	"display_zh_Hant_TW" to "चीनी (ताइवान, पारंपरिक)",
	"display_zu" to "ज़ुलु",
	"document_estimate" to "अनुमान",
	"document_invoice" to "बीजक",
	"document_quote" to "कीमत की पेशकश",
	"done" to "किया हुआ",
	"download_app" to "ऐप डाउनलोड करें",
	"download_invoice" to "इनवाइस को डाउनलोड करो",
	"download_pdf" to "डाउनलोड पीडीऍफ़",
	"draw_signature" to "कोई हस्ताक्षर आरेखित करें",
	"due" to Formattable { (date) -> "देय ${date}" },
	"due_date" to "नियत दिनांक",
	"due_day" to "प्रति दिन",
	"due_label" to "बाकी",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} देय है ${dueWhen}!" },
	"due_today" to "आज",
	"due_week" to "एक सप्ताह के भीतर",
	"duplicate" to "डुप्लिकेट",
	"duration_day" to "दिन",
	"duration_month" to "महीना",
	"duration_week" to "सप्ताह",
	"duration_year" to "वर्ष",
	"email" to "ईमेल",
	"email_blank_error" to "ईमेल की आवश्यकता है।",
	"email_error" to "अमान्य ई-मेल पता.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "आपके व्यापार के लिए धन्यवाद। पूरे ${documentType} दस्तावेज़ को देखने के लिए, इस पत्र के अंत में बटन पर क्लिक करें। कृपया ध्यान दें कि यह ${dueDate} तक वैध है।" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "आपको एक नया ${documentType} ${invoiceNumber} प्राप्त हुआ है!" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} देखें" },
	"email_invoice_greeting" to Formattable { (clientName) -> "प्रिय ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "आपके व्यापार के लिए धन्यवाद। पूरे ${documentType} को देखने के लिए इस पत्र के अंत में बटन पर क्लिक करें। कृपया शेष राशि का भुगतान ${dueDate} तक करें।" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "आपको एक नया ${documentType} ${invoiceNumber} प्राप्त हुआ है!" },
	"email_reset_success_message" to "भेजे गए पासवर्ड को रीसेट करने के लिए ईमेल, कृपया अपने इनबॉक्स की जाँच करें।",
	"empty_clients_list_message" to "आपके पास ट्रैक करने के लिए कोई ग्राहक नहीं है। कोई नया क्लाइंट जोड़ें.",
	"empty_estimate_list_message" to "अपना पहला अनुमान बनाएँ",
	"empty_expenses" to "आपके पास ट्रैक करने के लिए कोई खर्च नहीं है। नया खर्च जोड़ें।",
	"empty_invoice_list_message" to "अपना पहला इनवॉइस बनाएँ",
	"empty_items_list_message" to "आपके पास कोई आइटम नहीं है, एक नया आइटम जोड़ें!",
	"empty_signatures_list_message" to "कोई हस्ताक्षर नहीं मिले. एक बनाएँ!",
	"empty_taxes_list_message" to "आपके पास कोई कर नियम नहीं हैं। कोई नया कर नियम जोड़ें.",
	"empty_templates_list_message" to "टेम्पलेट्स अनुपलब्ध हैं.",
	"enabled" to "सक्षम",
	"entry" to "लेखा पृविष्टि",
	"equity" to "इक्विटी",
	"estimate_number" to "अनुमान #",
	"estimates" to "अनुमान",
	"estimates_report" to "अनुमान",
	"expense_bank_fees" to "बैंक शुल्क",
	"expense_benefits" to "फायदे",
	"expense_communication" to "संचार",
	"expense_debt" to "कर्ज",
	"expense_deleted" to "खर्च हटा दिया गया",
	"expense_depreciation" to "मूल्यह्रास",
	"expense_gifts" to "उपहार",
	"expense_insurance" to "बीमा",
	"expense_inventory" to "इन्वेंटरी",
	"expense_legal" to "कानूनी",
	"expense_maintenance" to "रखरखाव",
	"expense_marketing" to "मार्केटिंग",
	"expense_meals" to "भोजन",
	"expense_office_supplies" to "कार्यालय की आपूर्ति",
	"expense_rent" to "किराया",
	"expense_salaries" to "वेतन",
	"expense_shopping" to "खरीदारी",
	"expense_software" to "सॉफ्टवेयर",
	"expense_status_available" to "उपलब्ध",
	"expense_status_processing" to "प्रसंस्करण",
	"expense_tax" to "कर",
	"expense_training" to "प्रशिक्षण",
	"expense_transport" to "परिवहन",
	"expense_travel" to "यात्रा",
	"expense_utility" to "उपयोगिता",
	"expenses" to "खर्च",
	"feedback_option" to "प्रतिपुष्टि",
	"filter" to "छानना",
	"first_name_hint" to "प्रथम नाम",
	"forgot_password" to "रीसेट पासवर्ड",
	"forgot_password_label" to "पासवर्ड भूल गए",
	"from" to "से",
	"general_error" to "गड़बड़ी हुई, कृपया बाद में फिर से कोशिश करें।",
	"general_ledger" to "सामान्य खाता बही",
	"go_back_label" to "पस जाओ",
	"group_by" to "इसके द्वारा समूहीकृत करें:",
	"image_view" to "प्रतिबिंब",
	"install" to "इंस्टॉल करें",
	"invalid_credentials_error" to "अमान्य क्रेडेंशियल्स.",
	"invalid_email_error" to "अमान्य ईमेल.",
	"invalid_user_error" to "अमान्य उपयोगकर्ता क्रेडेंशियल्स.",
	"invoice_deleted_message" to "इनवॉइस हटाया गया",
	"invoice_menu" to "इनवॉइस मेनू",
	"invoice_number" to "बीजक #",
	"invoices" to "चालान",
	"items_option" to "आइटम",
	"keep" to "रखना",
	"language_selector" to Formattable { (selected) -> "भाषा ${selected}" },
	"last_name_hint" to "कुलनाम",
	"ledger" to "खाता बही",
	"legal" to "कानूनी",
	"legal_error" to "सेवा की शर्तों और गोपनीयता नीति को स्वीकार करना होगा",
	"liabilities" to "प्रतिबद्धताएँ",
	"loading" to "लोड",
	"login_required_error" to "लॉगिन आवश्यक है.",
	"logo_view" to "लोगो",
	"logout" to "लॉगआउट",
	"logout_prompt" to "आपका डेटा क्लाउड के साथ सिंक्रनाइज़ नहीं है. लॉगआउट पर इनवॉइस, अनुमान, क्लाइंट और अन्य जानकारी हटाएँ?",
	"main_screen" to "मुख्य स्क्रीन",
	"mark_paid" to "भुगतान के रूप में चिह्नित करें",
	"message" to "संदेश",
	"missing_sender_msg" to "प्रेषक की जानकारी के बिना दस्तावेज़ अमान्य हो सकता है।",
	"missing_sender_title" to "प्रेषक की जानकारी गुम है",
	"month_april" to "अप्रैल",
	"month_april_short" to "अप्रैल",
	"month_august" to "अगस्त",
	"month_august_short" to "अगस्त",
	"month_december" to "दिसंबर",
	"month_december_short" to "दिसंबर",
	"month_february" to "फरवरी",
	"month_february_short" to "फ़रवरी",
	"month_january" to "जनवरी",
	"month_january_short" to "जनवरी",
	"month_july" to "जुलाई",
	"month_july_short" to "जुलाई",
	"month_june" to "जून",
	"month_june_short" to "जून",
	"month_march" to "मार्च",
	"month_march_short" to "मार्च",
	"month_may" to "मई",
	"month_may_short" to "मई",
	"month_november" to "नवंबर",
	"month_november_short" to "नवंबर",
	"month_october" to "अक्टूबर",
	"month_october_short" to "अक्टूबर",
	"month_september" to "सितंबर",
	"month_september_short" to "सितम्बर",
	"multifactor_error" to "सत्यापित करने में विफल.",
	"new" to "नया",
	"news" to "समाचार",
	"next" to "अगला",
	"no" to "नहीं",
	"no_data_available" to "कोई डाटा उपलब्ध नहीं",
	"no_search_results" to "क्षमा करें, हम कोई परिणाम नहीं मिल सका।",
	"none" to "कोई नहीं",
	"note_fields" to "नोट्स",
	"note_hint" to "नोट",
	"notifications_option" to "सूचनाएँ",
	"ok" to "ठीक है",
	"onboarding" to "ज्ञानप्राप्ति",
	"onboarding_aboutus_message" to "DingVoice के आवश्यक भाग हमेशा के लिए निःशुल्क हैं। हमारा मिशन आपको अपना व्यवसाय बढ़ाने में मदद करना है, अपनी प्रतिक्रिया साझा करके हमें बेहतर बनाने में मदद करना है।",
	"onboarding_aboutus_title" to "हम यहाँ मदद करने के लिए हैं",
	"onboarding_paid_1" to "पेशेवर दिखने वाले टेम्प्लेट एक्सेस करें",
	"onboarding_paid_2" to "अपने वित्तीय में गहराई से देखें",
	"onboarding_paid_3" to "समस्याओं का शीघ्र निस्तारण कराएं",
	"onboarding_paid_4" to "अपने दस्तावेज सुरक्षित रखें",
	"onboarding_paid_5" to "चालान स्थिति में परिवर्तन के साथ अद्यतित रहें",
	"onboarding_paid_title" to "इसके साथ और भी तेजी से बढ़ें...",
	"onboarding_paywall_message" to "सर्वोत्तम मूल्य पर अभी सभी प्रीमियम सुविधाओं का आनंद लें।",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} के लिए बस ${currencySymbol}${introAmount} और फिर ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "बस ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} के लिए मुफ़्त और फिर ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} के लिए मुफ़्त, फिर ${currencySymbol}${introductoryAmount} के लिए ${introductoryDuration} ${introPeriod} के लिए और उसके बाद सिर्फ़ ${currencySymbol}${amount} / ${duration} के लिए" },
	"onboarding_paywall_renewable" to "स्वत: नवीकरणीय। किसी भी समय रद्द करें।",
	"onboarding_paywall_restore" to "पुनःस्थापन क्रय",
	"onboarding_paywall_restore_failure" to "खरीदारी पुनर्स्थापित करना विफल हो गया है, बाद में पुन: प्रयास करें या समर्थन से संपर्क करें।",
	"onboarding_paywall_restore_success" to "बहाल करना सफल!",
	"onboarding_paywall_title" to "महानता हासिल करो!",
	"onboarding_welcome_message" to "आपके चालान और अनुमानों को प्रबंधित करने के लिए एक संपूर्ण टूलसेट।",
	"onboarding_welcome_title" to "डिंगवॉइस में आपका स्वागत है",
	"other_state" to "अन्य",
	"overdue_state" to "विलंबित",
	"paid_state" to "भुगतान किया",
	"password" to "पासवर्ड",
	"password_blank_error" to "पासवर्ड की आवश्यकता है.",
	"password_mismatch_error" to "पासवर्ड मेल नहीं खाते.",
	"pay" to "भुगतान करें",
	"payment_instructions_label" to "भुगतान निर्देश",
	"payment_reminders" to "भुगतान अनुस्मारक",
	"phone_number_fields" to "फोन संख्या",
	"phone_number_hint" to "फोन संख्या",
	"postcode_hint" to "पोस्टकोडअ",
	"preview" to "पूर्वदर्शन",
	"preview_menu_hint" to "पूर्वावलोकन मेनू",
	"price" to "दाम",
	"privacy_policy" to "गोपनीयता नीति",
	"profile" to "रूपरेखा",
	"profitable_client" to "आपका सबसे लाभदायक ग्राहक है ...",
	"profits_report" to "मुनाफे",
	"promo_content" to "प्रचार सामग्री",
	"quantity_hint" to "परिमाण",
	"quantity_label_hint" to "मात्रा लेबल",
	"query_info" to "क्वेरी आपको इनपुट के रूप में आइटम और अन्य कर नियमों दोनों का उल्लेख करते हुए कस्टम परिकलन लिखने की अनुमति देती है. किसी अन्य प्रविष्टि +, -, *, /, % - गणित ऑपरेटरों से लिंक करने के लिए @ प्रतीक और आइटम या कर विवरण जोड़ें",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "आपके क्लाइंट को प्राप्त हुआ था ${documentType} ${documentNumber}।" },
	"received_state" to "प्राप्त हुआ",
	"registration_code_fields" to "रजिस्ट्रेशन कोड",
	"reminder" to "अनुस्मारक",
	"reports" to "रिपोर्ट",
	"reports_not_available" to "कोई रिपोर्ट उपलब्ध नहीं है, बाद में वापस आएं।",
	"reveal_password" to "पासवर्ड प्रकट करें",
	"revenue" to "रेवेन्यू",
	"sales_tax" to "वैट",
	"sales_tax_number" to "वैट नंबर",
	"save" to "रक्षा कर",
	"scan_document" to "दस्तावेज़ स्कैन करें",
	"search" to "ढूँढ",
	"select" to "चुनें",
	"select_icon" to "आइकन चुनें",
	"select_signature" to "हस्ताक्षर का चयन करें",
	"select_template" to "टेम्पलेट का चयन करें",
	"send" to "भेजना",
	"sent_state" to "प्रेषित",
	"setup_sender" to "सेंडर सेट अप करें",
	"share_option" to "हिस्सा",
	"ship_to" to "यहां भेजें",
	"sign_in" to "साइन इन करें",
	"sign_in_apple" to "Apple के साथ साइन इन करें",
	"sign_in_email" to "ईमेल के साथ साइन इन करें",
	"sign_in_facebook" to "Facebook के साथ साइन इन करें",
	"sign_in_google" to "Google के साथ साइन इन करें",
	"sign_up" to "नाम लिखो",
	"sign_up_label" to "कोई खाता नहीं है?",
	"sign_up_link_label" to "नाम लिखो!",
	"signatures_option" to "हस्ताक्षर",
	"skip" to "छोड़ें",
	"sold_items_report" to "बेचे गए आइटम",
	"sort_by" to "इसके द्वारा सॉर्ट करें:",
	"status_update" to "दस्तावेज़ स्थिति अद्यतन",
	"sub_annual" to "वार्षिक",
	"sub_annual_price" to Formattable { (price) -> "${price}/वर्ष" },
	"sub_backups" to "क्लाउड बैकअप",
	"sub_insights" to "इनसाइट्स",
	"sub_month" to "मासिक",
	"sub_monthly_price" to Formattable { (price) -> "${price}/महीना" },
	"sub_notifications" to "सूचनाएं",
	"sub_popup_message" to "सब्सक्रिप्शन जल्द ही आ रहे हैं। थोड़ी देर में यहाँ वापस देखें।",
	"sub_support" to "सहायता",
	"sub_templates" to "टेम्पलेट",
	"subscribe" to "सदस्यता लें",
	"subscription_header" to "अपने व्यवसाय को निम्न के साथ बढ़ाएँ:",
	"subscription_option" to "चंदा",
	"subscriptions_load_fail" to "क्षमा करें, हम इस समय आपकी सदस्यताएँ पुनर्प्राप्त करने में असमर्थ हैं। कृपया बाद में पुन: प्रयास करें या सहायता के लिए हमारी सहायता टीम से संपर्क करें।",
	"subtotal" to Formattable { (sum) -> "उप-योग: ${sum}" },
	"subtotal_label" to "उप-योग",
	"sum" to "राशि",
	"support_option" to "सहारा",
	"switch_reports" to "रिपोर्ट स्विच करें",
	"tax" to "कर",
	"tax_deleted_message" to "कर हटाया गया",
	"tax_description_warning" to "यदि उपयोग किया जाता है तो यह एक चालान पर दिखाई देता है",
	"tax_input_flat" to "समतल",
	"tax_input_percent" to "प्रतिशत",
	"tax_number_fields" to "कर संख्या",
	"tax_number_hint" to "कर संख्या",
	"tax_query_info_description" to "क्वेरी जानकारी",
	"tax_report" to "कर",
	"tax_rules_option" to "कर नियम",
	"taxed_clients_report" to "कर लगाए गए ग्राहक",
	"template" to "साँचा",
	"template_selector_info" to "डिफ़ॉल्ट टेम्पलेट परिवर्तित करने के लिए स्वाइप करें",
	"template_unlock_promo" to "प्रीमियम के साथ अधिक टेम्प्लेट एक्सेस करें",
	"templates_option" to "टेम्पलेट्स",
	"terms_of_service" to "सेवा की शर्तें",
	"thank_you" to "धन्यवाद",
	"to" to "तक",
	"total" to Formattable { (sum) -> "कुल: ${sum}" },
	"total_label" to "कुल",
	"undo_action" to "खोलना",
	"unit" to "इकाई",
	"unit_cost_hint" to "इकाई लागत",
	"unknown_client" to "अज्ञात क्लाइंट",
	"unlock" to "अनलॉक करें",
	"unnamed_item" to "अनाम आइटम",
	"upgrade" to "अपग्रेड",
	"user_collision_error" to "खाता पहले से मौजूद है.",
	"valid_until" to "तब तक मान्य",
	"warning" to "चेतावनी!",
	"yes" to "हाँ",
    )
    
}