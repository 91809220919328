// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Es_MX  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Aceptar ${termsOfService} y ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Código Inválido."
override val add_client_action: String = "Añadir cliente"
override val add_customer: String = "Añadir clientes"
override val add_expense: String = "Add expense"
override val add_fields_label: String = "Añadir:"
override val add_invoice_action: String = "Crear"
override val add_item_action: String = "Añadir artículo"
override val add_item_button: String = "Añadir artículo"
override val add_signature_action: String = "Añadir firma"
override val add_tax_action: String = "Añadir impuesto"
override val address_fields: String = "Dirección"
override val address_line_hint: String = "Dirección"
override val advanced: String = "Avanzado"
override val agree_marketing: String = "Acepto recibir comunicaciones de marketing directo"
override val all_notifications: String = "Todas las notificaciones"
override val amortization: String = "amortization"
override val amount: String = "Cantidad"
override val asset: String = "asset"
override val attach_file: String = "Attach File"
override val attachment: String = "Attachment"
override val auth_error: String = "Acción fallida, vuelva a intentarlo."
override val available_for_paid: String = "La sincronización en la nube sólo está disponible bajo suscripción de pago."
override val backups_option: String = "Copias de seguridad"
override val balance: String = "balance"
override val bank_account: String = "Cuenta bancaria"
override val bill_number: String = "Bill Number"
override val cancel: String = "Cancelar"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Categories"
override val category: String = "Category"
override val category_add: String = "Add Category"
override val category_delete: String = "Delete category"
override val category_name: String = "Category name"
override val change_language: Formattable = Formattable { (language) -> "Idioma de la factura: ${language}" }
override val change_language_label: String = "Idioma de la factura"
override val change_template: String = "Cambiar plantilla"
override val city_hint: String = "Ciudad"
override val clear: String = "Borrar"
override val client: String = "Cliente"
override val client_actions: String = "Acciones de los clientes"
override val client_deleted_message: String = "Cliente eliminado"
override val clients_error_required_fields: String = "Se requiere al menos un valor."
override val clients_option: String = "Clientes"
override val close: String = "Cerrar"
override val cloud_sync: String = "Sincronización en la nube"
override val coming_soon: String = "¡Próximamente!"
override val company_logo: String = "Logotipo de la empresa"
override val company_name_hint: String = "Empresa"
override val confirm_password_label: String = "Confirmar contraseña"
override val confirmation_title: String = " ¿Está seguro?"
override val convert_to_invoice: String = "Convertir en factura"
override val country_hint: String = "País"
override val create_client: String = "Crear cliente"
override val create_company: String = "Crear Empresa"
override val create_document: String = "Crear documento"
override val create_estimate: String = "Estimación"
override val create_invoice: String = "Factura"
override val create_item: String = "Crear artículo"
override val create_new_client: String = "Crear un nuevo cliente"
override val create_new_item: String = "Crear un nuevo artículo"
override val create_new_tax: String = "Crear un nuevo impuesto"
override val create_signature: String = "Firma"
override val created: String = "Creado"
override val credit: String = "credit"
override val customer: String = "Cliente"
override val date: String = "Fecha"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Moneda por defecto: ${currency}" }
override val default_currency_label: String = " Moneda por defecto"
override val delete: String = "Eliminar"
override val delete_account: String = "Eliminar la cuenta"
override val delete_confirmation_message: String = "Todos tus datos se perderán."
override val delete_item_title: String = "¿Borrar el artículo?"
override val delete_signature: String = "Borrar firma"
override val depreciation: String = "depreciation"
override val description: String = "Descripción"
override val description_hint: String = "Descripción"
override val detailed_report: String = "Reporte detallado"
override val disabled: String = "Deshabilitado"
override val discount: String = "Descuento"
override val discount_hint: String = "Descuento"
override val display_af: String = "Afrikáans"
override val display_am: String = "Amárico"
override val display_ar: String = "Árabe"
override val display_as: String = "Asamés"
override val display_az: String = "Azerbaiyán"
override val display_be: String = "Bielorruso"
override val display_bg: String = "Búlgaro"
override val display_bn: String = "Bengalí"
override val display_bs: String = "Bosnia"
override val display_ca: String = "Catalán"
override val display_cs: String = "Checa"
override val display_da: String = "Danés"
override val display_de: String = "Alemán"
override val display_el: String = "Griego"
override val display_en: String = "Inglés"
override val display_es: String = "Español"
override val display_es_MX: String = "Español (México)"
override val display_et: String = "Estonio"
override val display_eu: String = "Vasco"
override val display_fa: String = "Persa"
override val display_fi: String = "En finlandés"
override val display_fil: String = "Filipino"
override val display_fr: String = "Francés"
override val display_fr_CA: String = "Francés canadiense"
override val display_gl: String = "Gallego"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hebreo"
override val display_hi: String = "Hindi"
override val display_hr: String = "Croata"
override val display_hu: String = "Húngaro"
override val display_hy: String = "Armenio"
override val display_id: String = "Indonesia"
override val display_is: String = "Islandia"
override val display_it: String = "Italiano"
override val display_ja: String = "Japonés"
override val display_ka: String = "Georgiano"
override val display_kk: String = "Kazajistán"
override val display_km: String = "Jemer central"
override val display_kn: String = "Kannada"
override val display_ko: String = "Coreano"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Lituania"
override val display_lv: String = "Letón"
override val display_mk: String = "Macedonio"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolia"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malayo"
override val display_my: String = "Birmano"
override val display_nb: String = "Bokmål noruego"
override val display_ne: String = "Nepalí"
override val display_nl: String = "Holandés"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Polaco"
override val display_pt: String = "Portugués"
override val display_pt_BR: String = "Portugués (Brasil)"
override val display_pt_PT: String = "Portugués europeo"
override val display_ro: String = "Rumano"
override val display_ru: String = "Ruso"
override val display_si: String = "Sinhala"
override val display_sk: String = "Eslovaquia"
override val display_sl: String = "Eslovenia"
override val display_sq: String = "Albanés"
override val display_sr: String = "Serbio"
override val display_sv: String = "Sueco"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Tailandés"
override val display_tr: String = "Turco"
override val display_uk: String = "Ucraniano"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbekistán"
override val display_vi: String = "Vietnamita"
override val display_zh_Hant_TW: String = "Chino (Taiwán, tradicional)"
override val display_zu: String = "Zulú"
override val document_estimate: String = "Estimación"
override val document_invoice: String = "Factura"
override val document_quote: String = "Cita"
override val done: String = "Hecho"
override val download_app: String = "Descargar la aplicación"
override val download_invoice: String = "Descargar factura"
override val download_pdf: String = "Descargar PDF"
override val draw_signature: String = "Dibujar una firma"
override val due: Formattable = Formattable { (date) -> "Con vencimiento en ${date}" }
override val due_date: String = "Fecha de vencimiento"
override val due_day: String = "en un día"
override val due_label: String = "Debido"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "¡${documentType} ${documentNumber} se debe ${dueWhen}!" }
override val due_today: String = "Hoy"
override val due_week: String = "en una semana"
override val duplicate: String = "Duplicado"
override val duration_day: String = "día"
override val duration_month: String = "Mes"
override val duration_week: String = "semana"
override val duration_year: String = "año"
override val email: String = "Correo electrónico"
override val email_blank_error: String = "Correo electrónico obligatorio."
override val email_error: String = "Correo electrónico no válido."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Gracias por su negocio. Para ver el ${documentType} completo haga clic en el botón al final de esta carta. Tenga en cuenta que es válida hasta ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "¡Ha recibido un nuevo ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "VER ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Estimado ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Gracias por su negocio. Para ver el ${documentType} completo haga clic en el botón al final de esta carta. Por favor, pague el saldo hasta ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "¡Ha recibido un nuevo ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Se envió un correo electrónico para restablecer la contraseña, por favor revise su bandeja de entrada."
override val empty_clients_list_message: String = "No tiene clientes que seguir. Añade un nuevo cliente."
override val empty_estimate_list_message: String = "Cree su primer presupuesto"
override val empty_expenses: String = "You have no expenses to track. Add a new expense."
override val empty_invoice_list_message: String = "Cree su primera factura"
override val empty_items_list_message: String = "No tienes artículos, ¡añade un nuevo artículo!"
override val empty_signatures_list_message: String = "No se han encontrado firmas. ¡Crea una!"
override val empty_taxes_list_message: String = "No tiene reglas fiscales. Añade una nueva regla fiscal."
override val empty_templates_list_message: String = "Plantillas no disponibles."
override val enabled: String = "Habilitado(a)"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Estimación #"
override val estimates: String = "Estimaciones"
override val estimates_report: String = "Estimaciones"
override val expense_bank_fees: String = "Bank Fees"
override val expense_benefits: String = "Benefits"
override val expense_communication: String = "Communication"
override val expense_debt: String = "Debt"
override val expense_deleted: String = "Expense deleted"
override val expense_depreciation: String = "Depreciation"
override val expense_gifts: String = "Gifts"
override val expense_insurance: String = "Insurance"
override val expense_inventory: String = "Inventory"
override val expense_legal: String = "Legal"
override val expense_maintenance: String = "Maintenance"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Meals"
override val expense_office_supplies: String = "Office Supplies"
override val expense_rent: String = "Rent"
override val expense_salaries: String = "Salaries"
override val expense_shopping: String = "Shopping"
override val expense_software: String = "Software"
override val expense_status_available: String = "Available"
override val expense_status_processing: String = "Processing"
override val expense_tax: String = "Impuesto"
override val expense_training: String = "Training"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Travel"
override val expense_utility: String = "Utility"
override val expenses: String = "Expenses"
override val feedback_option: String = "Comentarios"
override val filter: String = "Filtrar"
override val first_name_hint: String = "Primer nombre"
override val forgot_password: String = "Restablecer contraseña"
override val forgot_password_label: String = "¿Olvidaste tu contraseña?"
override val from: String = "de"
override val general_error: String = "Se ha producido un error, inténtelo de nuevo más tarde."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Volver"
override val group_by: String = "Grupo por:"
override val image_view: String = "Imagen"
override val install: String = "Instalar"
override val invalid_credentials_error: String = "Credenciales no válidas."
override val invalid_email_error: String = "Correo electrónico no válido."
override val invalid_user_error: String = "Credenciales de usuario no válidas."
override val invoice_deleted_message: String = "Factura eliminada"
override val invoice_menu: String = "Menú de la factura"
override val invoice_number: String = "Factura #"
override val invoices: String = "Facturas"
override val items_option: String = "artículos"
override val keep: String = "Mantenga"
override val language_selector: Formattable = Formattable { (seleccionado) -> "Idioma ${seleccionado}" }
override val last_name_hint: String = "Apellido"
override val ledger: String = "ledger"
override val legal: String = "Legal"
override val legal_error: String = "Debe aceptar las condiciones de servicio y la política de privacidad"
override val liabilities: String = "liabilities"
override val loading: String = "Cargando…"
override val login_required_error: String = "Debe iniciar sesión primero."
override val logo_view: String = "Logo"
override val logout: String = "Cierre de sesión"
override val logout_prompt: String = "Sus datos no están sincronizados con la nube. Borrar facturas, presupuestos, clientes y otra información al cerrar la sesión?"
override val main_screen: String = "Pantalla principal"
override val mark_paid: String = "Marcar como Pagado"
override val message: String = "Mensaje"
override val missing_sender_msg: String = "Un documento sin la información del remitente podría ser inválido."
override val missing_sender_title: String = "Falta información del remitente"
override val month_april: String = "Abril"
override val month_april_short: String = "Abril"
override val month_august: String = "Agosto"
override val month_august_short: String = "Agosto."
override val month_december: String = "Diciembre"
override val month_december_short: String = "Diciembre."
override val month_february: String = "Febrero"
override val month_february_short: String = "Feb."
override val month_january: String = "Enero"
override val month_january_short: String = "Jan."
override val month_july: String = "Julio"
override val month_july_short: String = "Julio"
override val month_june: String = "Junio"
override val month_june_short: String = "Junio"
override val month_march: String = "Marzo"
override val month_march_short: String = "Marzo"
override val month_may: String = "Mayo"
override val month_may_short: String = "Mayo"
override val month_november: String = "Noviembre"
override val month_november_short: String = "Nov."
override val month_october: String = "Octubre"
override val month_october_short: String = "Oct."
override val month_september: String = "Septiembre"
override val month_september_short: String = "Septiembre."
override val multifactor_error: String = "No se pudo verificar."
override val new: String = "New"
override val news: String = "Noticias"
override val next: String = "Siguiente"
override val no: String = "no"
override val no_data_available: String = "No hay datos disponibles"
override val no_search_results: String = "Lo sentimos, no pudimos encontrar ningún resultado."
override val none: String = "None"
override val note_fields: String = "Notas"
override val note_hint: String = "Nota"
override val notifications_option: String = "Notificaciones"
override val ok: String = "Aceptar"
override val onboarding: String = "Embarque"
override val onboarding_aboutus_message: String = "Las partes esenciales de DingVoice son gratuitas para siempre. Nuestra misión es ayudarle a hacer crecer su negocio, ayúdenos a mejorar compartiendo sus comentarios."
override val onboarding_aboutus_title: String = "Estamos aquí para ayudar"
override val onboarding_paid_1: String = "Acceda a plantillas de aspecto profesional"
override val onboarding_paid_2: String = "Profundice en sus finanzas"
override val onboarding_paid_3: String = "Rápida resolución de problemas"
override val onboarding_paid_4: String = "Proteja sus documentos"
override val onboarding_paid_5: String = "Manténgase al día de los cambios en el estado de las facturas"
override val onboarding_paid_title: String = "Crece aún más rápido con..."
override val onboarding_paywall_message: String = "Disfruta ahora de todas las prestaciones premium al mejor precio."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Solo ${currencySymbol}${introAmount} para ${introDuration} ${introPeriod} y luego ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duración) -> "Sólo ${currencySymbol}${amount} / ${duración}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis por ${trialDuration} ${trialPeriod} y luego solo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis durante ${trialDuration} ${trialPeriod} luego ${currencySymbol}${introductoryAmount} durante ${introductoryDuration} ${introPeriod} y luego sólo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Renovable automáticamente. Cancele cuando quiera."
override val onboarding_paywall_restore: String = "Restaurar la compra"
override val onboarding_paywall_restore_failure: String = "La restauración de las compras ha fallado, inténtelo más tarde o póngase en contacto con el servicio de asistencia."
override val onboarding_paywall_restore_success: String = "¡Restauración con éxito!"
override val onboarding_paywall_title: String = "Alcanzar la grandeza"
override val onboarding_welcome_message: String = "Un completo conjunto de herramientas para gestionar sus facturas y presupuestos."
override val onboarding_welcome_title: String = "Bienvenido a DingVoice"
override val other_state: String = "Otro"
override val overdue_state: String = "Atrasado"
override val paid_state: String = "Pagado"
override val password: String = "Contraseña"
override val password_blank_error: String = "Contraseña requerida"
override val password_mismatch_error: String = "Las contraseñas no coinciden."
override val pay: String = "pay"
override val payment_instructions_label: String = "Instrucciones de pago"
override val payment_reminders: String = "Recordatorios de pago"
override val phone_number_fields: String = "Número de teléfono"
override val phone_number_hint: String = "Número de Teléfono"
override val postcode_hint: String = "Código Postal"
override val preview: String = "Vista previa"
override val preview_menu_hint: String = "Menú Anterior"
override val price: String = "Precio"
override val privacy_policy: String = "Política de privacidad"
override val profile: String = "Perfil"
override val profitable_client: String = "Su cliente más rentable es..."
override val profits_report: String = "Ganancias"
override val promo_content: String = "Contenido promocional"
override val quantity_hint: String = "Cantidad"
override val quantity_label_hint: String = "Etiqueta de cantidad"
override val query_info: String = "La consulta permite escribir cálculos personalizados referidos tanto a artículos como a otras reglas fiscales como entradas. Añadir el símbolo @ y la descripción del artículo o del impuesto para enlazar con otra entrada +, -, *, /, % - operadores matemáticos"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} fue recibido por su cliente." }
override val received_state: String = "Recibido"
override val registration_code_fields: String = "Código de registro"
override val reminder: String = "Recordatorio"
override val reports: String = "Informes"
override val reports_not_available: String = "No hay informes disponibles, regrese más tarde."
override val reveal_password: String = "Mostrar Contraseña"
override val revenue: String = "revenue"
override val sales_tax: String = "IVA"
override val sales_tax_number: String = "Número de IVA"
override val save: String = "Guardar"
override val scan_document: String = "Scan document"
override val search: String = "Buscar"
override val select: String = "Select"
override val select_icon: String = "Select icon"
override val select_signature: String = "Seleccione la firma"
override val select_template: String = "Seleccione la plantilla"
override val send: String = "Enviar"
override val sent_state: String = "Enviado"
override val setup_sender: String = "Configurar el remitente"
override val share_option: String = "Compartir"
override val ship_to: String = "Enviar a"
override val sign_in: String = "Acceder"
override val sign_in_apple: String = "Acceder con Apple"
override val sign_in_email: String = "Acceder con correo electrónico"
override val sign_in_facebook: String = "Acceder con Facebook"
override val sign_in_google: String = "Acceder con Google"
override val sign_up: String = "Registrarse"
override val sign_up_label: String = "¿Aún no tienes una cuenta?"
override val sign_up_link_label: String = "Registrarse"
override val signatures_option: String = "Firmas"
override val skip: String = "Saltar"
override val sold_items_report: String = "Artículos vendidos"
override val sort_by: String = "Ordenar por:"
override val status_update: String = "Actualización del estado del documento"
override val sub_annual: String = "Anual"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/año" }
override val sub_backups: String = "Copias de seguridad en la nube"
override val sub_insights: String = "Perspectivas"
override val sub_month: String = "Mensualmente"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mes" }
override val sub_notifications: String = "Notificaciones"
override val sub_popup_message: String = "Las suscripciones están a punto de llegar. Vuelve a consultar aquí dentro de poco."
override val sub_support: String = "Soporte"
override val sub_templates: String = "Plantillas"
override val subscribe: String = "Suscribirse"
override val subscription_header: String = "Haga crecer su negocio con:"
override val subscription_option: String = "Suscripción"
override val subscriptions_load_fail: String = "Lo sentimos, no podemos recuperar sus suscripciones en este momento. Vuelve a intentarlo más tarde o ponte en contacto con nuestro equipo de asistencia."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "Subtotal"
override val sum: String = "Suma"
override val support_option: String = "Soporte"
override val switch_reports: String = "Informe de cambio"
override val tax: String = "Impuestos"
override val tax_deleted_message: String = "Impuesto eliminado"
override val tax_description_warning: String = "Si se utiliza esto aparece en una factura"
override val tax_input_flat: String = "Plano"
override val tax_input_percent: String = "Porcentaje"
override val tax_number_fields: String = "Número del TIN"
override val tax_number_hint: String = "Número del TIN"
override val tax_query_info_description: String = "Información de la consulta"
override val tax_report: String = "Impuesto"
override val tax_rules_option: String = "Normas fiscales"
override val taxed_clients_report: String = "Clientes con impuestos"
override val template: String = "Plantilla"
override val template_selector_info: String = "Desliza el dedo para cambiar la plantilla por defecto"
override val template_unlock_promo: String = "Acceda a más plantillas con Premium"
override val templates_option: String = "Plantillas"
override val terms_of_service: String = "Condiciones del servicio"
override val thank_you: String = "¡Gracias!"
override val to: String = "a"
override val total: Formattable = Formattable { (sum) -> "Total: ${sum}" }
override val total_label: String = "TOTAL"
override val undo_action: String = "Deshacer"
override val unit: String = "Unidad"
override val unit_cost_hint: String = "Coste unitario"
override val unknown_client: String = "Cliente desconocido"
override val unlock: String = "Desbloquear"
override val unnamed_item: String = "Elemento sin nombre"
override val upgrade: String = "Actualizar"
override val user_collision_error: String = "La cuenta ya existe."
override val valid_until: String = "Válido hasta"
override val warning: String = "Advertencia"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Aceptar ${termsOfService} y ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Código Inválido.",
	"add_client_action" to "Añadir cliente",
	"add_customer" to "Añadir clientes",
	"add_expense" to "Add expense",
	"add_fields_label" to "Añadir:",
	"add_invoice_action" to "Crear",
	"add_item_action" to "Añadir artículo",
	"add_item_button" to "Añadir artículo",
	"add_signature_action" to "Añadir firma",
	"add_tax_action" to "Añadir impuesto",
	"address_fields" to "Dirección",
	"address_line_hint" to "Dirección",
	"advanced" to "Avanzado",
	"agree_marketing" to "Acepto recibir comunicaciones de marketing directo",
	"all_notifications" to "Todas las notificaciones",
	"amortization" to "amortization",
	"amount" to "Cantidad",
	"asset" to "asset",
	"attach_file" to "Attach File",
	"attachment" to "Attachment",
	"auth_error" to "Acción fallida, vuelva a intentarlo.",
	"available_for_paid" to "La sincronización en la nube sólo está disponible bajo suscripción de pago.",
	"backups_option" to "Copias de seguridad",
	"balance" to "balance",
	"bank_account" to "Cuenta bancaria",
	"bill_number" to "Bill Number",
	"cancel" to "Cancelar",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Categories",
	"category" to "Category",
	"category_add" to "Add Category",
	"category_delete" to "Delete category",
	"category_name" to "Category name",
	"change_language" to Formattable { (language) -> "Idioma de la factura: ${language}" },
	"change_language_label" to "Idioma de la factura",
	"change_template" to "Cambiar plantilla",
	"city_hint" to "Ciudad",
	"clear" to "Borrar",
	"client" to "Cliente",
	"client_actions" to "Acciones de los clientes",
	"client_deleted_message" to "Cliente eliminado",
	"clients_error_required_fields" to "Se requiere al menos un valor.",
	"clients_option" to "Clientes",
	"close" to "Cerrar",
	"cloud_sync" to "Sincronización en la nube",
	"coming_soon" to "¡Próximamente!",
	"company_logo" to "Logotipo de la empresa",
	"company_name_hint" to "Empresa",
	"confirm_password_label" to "Confirmar contraseña",
	"confirmation_title" to " ¿Está seguro?",
	"convert_to_invoice" to "Convertir en factura",
	"country_hint" to "País",
	"create_client" to "Crear cliente",
	"create_company" to "Crear Empresa",
	"create_document" to "Crear documento",
	"create_estimate" to "Estimación",
	"create_invoice" to "Factura",
	"create_item" to "Crear artículo",
	"create_new_client" to "Crear un nuevo cliente",
	"create_new_item" to "Crear un nuevo artículo",
	"create_new_tax" to "Crear un nuevo impuesto",
	"create_signature" to "Firma",
	"created" to "Creado",
	"credit" to "credit",
	"customer" to "Cliente",
	"date" to "Fecha",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Moneda por defecto: ${currency}" },
	"default_currency_label" to " Moneda por defecto",
	"delete" to "Eliminar",
	"delete_account" to "Eliminar la cuenta",
	"delete_confirmation_message" to "Todos tus datos se perderán.",
	"delete_item_title" to "¿Borrar el artículo?",
	"delete_signature" to "Borrar firma",
	"depreciation" to "depreciation",
	"description" to "Descripción",
	"description_hint" to "Descripción",
	"detailed_report" to "Reporte detallado",
	"disabled" to "Deshabilitado",
	"discount" to "Descuento",
	"discount_hint" to "Descuento",
	"display_af" to "Afrikáans",
	"display_am" to "Amárico",
	"display_ar" to "Árabe",
	"display_as" to "Asamés",
	"display_az" to "Azerbaiyán",
	"display_be" to "Bielorruso",
	"display_bg" to "Búlgaro",
	"display_bn" to "Bengalí",
	"display_bs" to "Bosnia",
	"display_ca" to "Catalán",
	"display_cs" to "Checa",
	"display_da" to "Danés",
	"display_de" to "Alemán",
	"display_el" to "Griego",
	"display_en" to "Inglés",
	"display_es" to "Español",
	"display_es_MX" to "Español (México)",
	"display_et" to "Estonio",
	"display_eu" to "Vasco",
	"display_fa" to "Persa",
	"display_fi" to "En finlandés",
	"display_fil" to "Filipino",
	"display_fr" to "Francés",
	"display_fr_CA" to "Francés canadiense",
	"display_gl" to "Gallego",
	"display_gu" to "Gujarati",
	"display_he" to "Hebreo",
	"display_hi" to "Hindi",
	"display_hr" to "Croata",
	"display_hu" to "Húngaro",
	"display_hy" to "Armenio",
	"display_id" to "Indonesia",
	"display_is" to "Islandia",
	"display_it" to "Italiano",
	"display_ja" to "Japonés",
	"display_ka" to "Georgiano",
	"display_kk" to "Kazajistán",
	"display_km" to "Jemer central",
	"display_kn" to "Kannada",
	"display_ko" to "Coreano",
	"display_ky" to "Kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Lituania",
	"display_lv" to "Letón",
	"display_mk" to "Macedonio",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolia",
	"display_mr" to "Marathi",
	"display_ms" to "Malayo",
	"display_my" to "Birmano",
	"display_nb" to "Bokmål noruego",
	"display_ne" to "Nepalí",
	"display_nl" to "Holandés",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Polaco",
	"display_pt" to "Portugués",
	"display_pt_BR" to "Portugués (Brasil)",
	"display_pt_PT" to "Portugués europeo",
	"display_ro" to "Rumano",
	"display_ru" to "Ruso",
	"display_si" to "Sinhala",
	"display_sk" to "Eslovaquia",
	"display_sl" to "Eslovenia",
	"display_sq" to "Albanés",
	"display_sr" to "Serbio",
	"display_sv" to "Sueco",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Tailandés",
	"display_tr" to "Turco",
	"display_uk" to "Ucraniano",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbekistán",
	"display_vi" to "Vietnamita",
	"display_zh_Hant_TW" to "Chino (Taiwán, tradicional)",
	"display_zu" to "Zulú",
	"document_estimate" to "Estimación",
	"document_invoice" to "Factura",
	"document_quote" to "Cita",
	"done" to "Hecho",
	"download_app" to "Descargar la aplicación",
	"download_invoice" to "Descargar factura",
	"download_pdf" to "Descargar PDF",
	"draw_signature" to "Dibujar una firma",
	"due" to Formattable { (date) -> "Con vencimiento en ${date}" },
	"due_date" to "Fecha de vencimiento",
	"due_day" to "en un día",
	"due_label" to "Debido",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "¡${documentType} ${documentNumber} se debe ${dueWhen}!" },
	"due_today" to "Hoy",
	"due_week" to "en una semana",
	"duplicate" to "Duplicado",
	"duration_day" to "día",
	"duration_month" to "Mes",
	"duration_week" to "semana",
	"duration_year" to "año",
	"email" to "Correo electrónico",
	"email_blank_error" to "Correo electrónico obligatorio.",
	"email_error" to "Correo electrónico no válido.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Gracias por su negocio. Para ver el ${documentType} completo haga clic en el botón al final de esta carta. Tenga en cuenta que es válida hasta ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "¡Ha recibido un nuevo ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "VER ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Estimado ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Gracias por su negocio. Para ver el ${documentType} completo haga clic en el botón al final de esta carta. Por favor, pague el saldo hasta ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "¡Ha recibido un nuevo ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Se envió un correo electrónico para restablecer la contraseña, por favor revise su bandeja de entrada.",
	"empty_clients_list_message" to "No tiene clientes que seguir. Añade un nuevo cliente.",
	"empty_estimate_list_message" to "Cree su primer presupuesto",
	"empty_expenses" to "You have no expenses to track. Add a new expense.",
	"empty_invoice_list_message" to "Cree su primera factura",
	"empty_items_list_message" to "No tienes artículos, ¡añade un nuevo artículo!",
	"empty_signatures_list_message" to "No se han encontrado firmas. ¡Crea una!",
	"empty_taxes_list_message" to "No tiene reglas fiscales. Añade una nueva regla fiscal.",
	"empty_templates_list_message" to "Plantillas no disponibles.",
	"enabled" to "Habilitado(a)",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Estimación #",
	"estimates" to "Estimaciones",
	"estimates_report" to "Estimaciones",
	"expense_bank_fees" to "Bank Fees",
	"expense_benefits" to "Benefits",
	"expense_communication" to "Communication",
	"expense_debt" to "Debt",
	"expense_deleted" to "Expense deleted",
	"expense_depreciation" to "Depreciation",
	"expense_gifts" to "Gifts",
	"expense_insurance" to "Insurance",
	"expense_inventory" to "Inventory",
	"expense_legal" to "Legal",
	"expense_maintenance" to "Maintenance",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Meals",
	"expense_office_supplies" to "Office Supplies",
	"expense_rent" to "Rent",
	"expense_salaries" to "Salaries",
	"expense_shopping" to "Shopping",
	"expense_software" to "Software",
	"expense_status_available" to "Available",
	"expense_status_processing" to "Processing",
	"expense_tax" to "Impuesto",
	"expense_training" to "Training",
	"expense_transport" to "Transport",
	"expense_travel" to "Travel",
	"expense_utility" to "Utility",
	"expenses" to "Expenses",
	"feedback_option" to "Comentarios",
	"filter" to "Filtrar",
	"first_name_hint" to "Primer nombre",
	"forgot_password" to "Restablecer contraseña",
	"forgot_password_label" to "¿Olvidaste tu contraseña?",
	"from" to "de",
	"general_error" to "Se ha producido un error, inténtelo de nuevo más tarde.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Volver",
	"group_by" to "Grupo por:",
	"image_view" to "Imagen",
	"install" to "Instalar",
	"invalid_credentials_error" to "Credenciales no válidas.",
	"invalid_email_error" to "Correo electrónico no válido.",
	"invalid_user_error" to "Credenciales de usuario no válidas.",
	"invoice_deleted_message" to "Factura eliminada",
	"invoice_menu" to "Menú de la factura",
	"invoice_number" to "Factura #",
	"invoices" to "Facturas",
	"items_option" to "artículos",
	"keep" to "Mantenga",
	"language_selector" to Formattable { (seleccionado) -> "Idioma ${seleccionado}" },
	"last_name_hint" to "Apellido",
	"ledger" to "ledger",
	"legal" to "Legal",
	"legal_error" to "Debe aceptar las condiciones de servicio y la política de privacidad",
	"liabilities" to "liabilities",
	"loading" to "Cargando…",
	"login_required_error" to "Debe iniciar sesión primero.",
	"logo_view" to "Logo",
	"logout" to "Cierre de sesión",
	"logout_prompt" to "Sus datos no están sincronizados con la nube. Borrar facturas, presupuestos, clientes y otra información al cerrar la sesión?",
	"main_screen" to "Pantalla principal",
	"mark_paid" to "Marcar como Pagado",
	"message" to "Mensaje",
	"missing_sender_msg" to "Un documento sin la información del remitente podría ser inválido.",
	"missing_sender_title" to "Falta información del remitente",
	"month_april" to "Abril",
	"month_april_short" to "Abril",
	"month_august" to "Agosto",
	"month_august_short" to "Agosto.",
	"month_december" to "Diciembre",
	"month_december_short" to "Diciembre.",
	"month_february" to "Febrero",
	"month_february_short" to "Feb.",
	"month_january" to "Enero",
	"month_january_short" to "Jan.",
	"month_july" to "Julio",
	"month_july_short" to "Julio",
	"month_june" to "Junio",
	"month_june_short" to "Junio",
	"month_march" to "Marzo",
	"month_march_short" to "Marzo",
	"month_may" to "Mayo",
	"month_may_short" to "Mayo",
	"month_november" to "Noviembre",
	"month_november_short" to "Nov.",
	"month_october" to "Octubre",
	"month_october_short" to "Oct.",
	"month_september" to "Septiembre",
	"month_september_short" to "Septiembre.",
	"multifactor_error" to "No se pudo verificar.",
	"new" to "New",
	"news" to "Noticias",
	"next" to "Siguiente",
	"no" to "no",
	"no_data_available" to "No hay datos disponibles",
	"no_search_results" to "Lo sentimos, no pudimos encontrar ningún resultado.",
	"none" to "None",
	"note_fields" to "Notas",
	"note_hint" to "Nota",
	"notifications_option" to "Notificaciones",
	"ok" to "Aceptar",
	"onboarding" to "Embarque",
	"onboarding_aboutus_message" to "Las partes esenciales de DingVoice son gratuitas para siempre. Nuestra misión es ayudarle a hacer crecer su negocio, ayúdenos a mejorar compartiendo sus comentarios.",
	"onboarding_aboutus_title" to "Estamos aquí para ayudar",
	"onboarding_paid_1" to "Acceda a plantillas de aspecto profesional",
	"onboarding_paid_2" to "Profundice en sus finanzas",
	"onboarding_paid_3" to "Rápida resolución de problemas",
	"onboarding_paid_4" to "Proteja sus documentos",
	"onboarding_paid_5" to "Manténgase al día de los cambios en el estado de las facturas",
	"onboarding_paid_title" to "Crece aún más rápido con...",
	"onboarding_paywall_message" to "Disfruta ahora de todas las prestaciones premium al mejor precio.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Solo ${currencySymbol}${introAmount} para ${introDuration} ${introPeriod} y luego ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duración) -> "Sólo ${currencySymbol}${amount} / ${duración}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratis por ${trialDuration} ${trialPeriod} y luego solo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratis durante ${trialDuration} ${trialPeriod} luego ${currencySymbol}${introductoryAmount} durante ${introductoryDuration} ${introPeriod} y luego sólo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Renovable automáticamente. Cancele cuando quiera.",
	"onboarding_paywall_restore" to "Restaurar la compra",
	"onboarding_paywall_restore_failure" to "La restauración de las compras ha fallado, inténtelo más tarde o póngase en contacto con el servicio de asistencia.",
	"onboarding_paywall_restore_success" to "¡Restauración con éxito!",
	"onboarding_paywall_title" to "Alcanzar la grandeza",
	"onboarding_welcome_message" to "Un completo conjunto de herramientas para gestionar sus facturas y presupuestos.",
	"onboarding_welcome_title" to "Bienvenido a DingVoice",
	"other_state" to "Otro",
	"overdue_state" to "Atrasado",
	"paid_state" to "Pagado",
	"password" to "Contraseña",
	"password_blank_error" to "Contraseña requerida",
	"password_mismatch_error" to "Las contraseñas no coinciden.",
	"pay" to "pay",
	"payment_instructions_label" to "Instrucciones de pago",
	"payment_reminders" to "Recordatorios de pago",
	"phone_number_fields" to "Número de teléfono",
	"phone_number_hint" to "Número de Teléfono",
	"postcode_hint" to "Código Postal",
	"preview" to "Vista previa",
	"preview_menu_hint" to "Menú Anterior",
	"price" to "Precio",
	"privacy_policy" to "Política de privacidad",
	"profile" to "Perfil",
	"profitable_client" to "Su cliente más rentable es...",
	"profits_report" to "Ganancias",
	"promo_content" to "Contenido promocional",
	"quantity_hint" to "Cantidad",
	"quantity_label_hint" to "Etiqueta de cantidad",
	"query_info" to "La consulta permite escribir cálculos personalizados referidos tanto a artículos como a otras reglas fiscales como entradas. Añadir el símbolo @ y la descripción del artículo o del impuesto para enlazar con otra entrada +, -, *, /, % - operadores matemáticos",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} fue recibido por su cliente." },
	"received_state" to "Recibido",
	"registration_code_fields" to "Código de registro",
	"reminder" to "Recordatorio",
	"reports" to "Informes",
	"reports_not_available" to "No hay informes disponibles, regrese más tarde.",
	"reveal_password" to "Mostrar Contraseña",
	"revenue" to "revenue",
	"sales_tax" to "IVA",
	"sales_tax_number" to "Número de IVA",
	"save" to "Guardar",
	"scan_document" to "Scan document",
	"search" to "Buscar",
	"select" to "Select",
	"select_icon" to "Select icon",
	"select_signature" to "Seleccione la firma",
	"select_template" to "Seleccione la plantilla",
	"send" to "Enviar",
	"sent_state" to "Enviado",
	"setup_sender" to "Configurar el remitente",
	"share_option" to "Compartir",
	"ship_to" to "Enviar a",
	"sign_in" to "Acceder",
	"sign_in_apple" to "Acceder con Apple",
	"sign_in_email" to "Acceder con correo electrónico",
	"sign_in_facebook" to "Acceder con Facebook",
	"sign_in_google" to "Acceder con Google",
	"sign_up" to "Registrarse",
	"sign_up_label" to "¿Aún no tienes una cuenta?",
	"sign_up_link_label" to "Registrarse",
	"signatures_option" to "Firmas",
	"skip" to "Saltar",
	"sold_items_report" to "Artículos vendidos",
	"sort_by" to "Ordenar por:",
	"status_update" to "Actualización del estado del documento",
	"sub_annual" to "Anual",
	"sub_annual_price" to Formattable { (price) -> "${price}/año" },
	"sub_backups" to "Copias de seguridad en la nube",
	"sub_insights" to "Perspectivas",
	"sub_month" to "Mensualmente",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mes" },
	"sub_notifications" to "Notificaciones",
	"sub_popup_message" to "Las suscripciones están a punto de llegar. Vuelve a consultar aquí dentro de poco.",
	"sub_support" to "Soporte",
	"sub_templates" to "Plantillas",
	"subscribe" to "Suscribirse",
	"subscription_header" to "Haga crecer su negocio con:",
	"subscription_option" to "Suscripción",
	"subscriptions_load_fail" to "Lo sentimos, no podemos recuperar sus suscripciones en este momento. Vuelve a intentarlo más tarde o ponte en contacto con nuestro equipo de asistencia.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "Subtotal",
	"sum" to "Suma",
	"support_option" to "Soporte",
	"switch_reports" to "Informe de cambio",
	"tax" to "Impuestos",
	"tax_deleted_message" to "Impuesto eliminado",
	"tax_description_warning" to "Si se utiliza esto aparece en una factura",
	"tax_input_flat" to "Plano",
	"tax_input_percent" to "Porcentaje",
	"tax_number_fields" to "Número del TIN",
	"tax_number_hint" to "Número del TIN",
	"tax_query_info_description" to "Información de la consulta",
	"tax_report" to "Impuesto",
	"tax_rules_option" to "Normas fiscales",
	"taxed_clients_report" to "Clientes con impuestos",
	"template" to "Plantilla",
	"template_selector_info" to "Desliza el dedo para cambiar la plantilla por defecto",
	"template_unlock_promo" to "Acceda a más plantillas con Premium",
	"templates_option" to "Plantillas",
	"terms_of_service" to "Condiciones del servicio",
	"thank_you" to "¡Gracias!",
	"to" to "a",
	"total" to Formattable { (sum) -> "Total: ${sum}" },
	"total_label" to "TOTAL",
	"undo_action" to "Deshacer",
	"unit" to "Unidad",
	"unit_cost_hint" to "Coste unitario",
	"unknown_client" to "Cliente desconocido",
	"unlock" to "Desbloquear",
	"unnamed_item" to "Elemento sin nombre",
	"upgrade" to "Actualizar",
	"user_collision_error" to "La cuenta ya existe.",
	"valid_until" to "Válido hasta",
	"warning" to "Advertencia",
	"yes" to "yes",
    )
    
}