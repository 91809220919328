package com.dingvoice

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.dingvoice.components.Layout
import com.dingvoice.components.MainContentLayout
import com.dingvoice.content.HeaderCentered
import com.dingvoice.content.PageFooter
import com.dingvoice.localizations.Localizations
import com.dingvoice.style.AppCSSVariables
import com.dingvoice.style.AppStylesheet
import com.dingvoice.style.WtCols
import com.dingvoice.style.WtContainer
import com.dingvoice.style.WtTexts
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.B
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import org.w3c.dom.events.Event
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.Json
import kotlin.js.Promise
import kotlin.js.json
import kotlin.time.Duration.Companion.seconds

@Composable
fun AccountDeletionPage() {
    var isMobile by remember {
        mutableStateOf(window.isMobile())
    }
    val scope = rememberCoroutineScope()

    require("firebase/compat/auth")
    val firebaseApp: dynamic = require("firebase/compat/app")
    require("firebaseui")
    val firebaseConfig: Json =
        json(
            "apiKey" to "AIzaSyBznOrEusdTe_VoX74TQo91C4nZE7oIb2k",
            "authDomain" to "dingvoice-dev-7cf9e.firebaseapp.com",
            "databaseURL" to "https://dingvoice-dev-7cf9e-default-rtdb.europe-west1.firebasedatabase.app",
            "projectId" to "dingvoice-dev-7cf9e",
            "storageBucket" to "dingvoice-dev-7cf9e.appspot.com",
            "messagingSenderId" to "1073258381762",
            "appId" to "1:1073258381762:web:5e7a479782317f8a3cd65b",
        )
    firebaseApp.initializeApp(firebaseConfig)
    val ui by remember { mutableStateOf(js("new firebaseui.auth.AuthUI(firebaseApp.auth());")) }

    var authResult by remember { mutableStateOf<dynamic>(null) }
    val user = firebaseApp.auth().currentUser
    val isLoading = remember { mutableStateOf(true) }
    var isConfirmationShown by remember { mutableStateOf(false) }
    var isDeletionError by remember { mutableStateOf(false) }
    var isDeleteSuccess by remember { mutableStateOf(false) }

    val delete =
        remember {
            { handleError: Boolean ->
                scope.launch {
                    try {
                        val currentUser = firebaseApp.auth().currentUser

                        (currentUser.delete() as Promise<*>).await()
                        (firebaseApp.auth().signOut() as Promise<*>).await()
                        authResult = null
                        isDeletionError = false
                        isDeleteSuccess = true
                    } catch (error: Throwable) {
                        if (handleError) {
                            isDeletionError = true
                        }
                    }
                }
            }
        }

    DisposableEffect(Unit) {
        val callback: ((Event) -> Unit) = { _ ->
            isMobile = window.isMobile()
        }
        window.addEventListener("resize", callback = callback)

        onDispose {
            window.removeEventListener("resize", callback)
        }
    }

    Style(AppStylesheet)

    Layout {
        HeaderCentered()
        MainContentLayout(isMobile = isMobile) {
            Div(attrs = {
                id("firebaseui-auth-container")
            })

            Div({ classes(WtContainer.wtContainer) }) {
                if (authResult != null) {
                    Div({
                        classes(WtCols.wtColInline)
                        style {
                            AppCSSVariables.wtColCount(0)
                            width(100.percent)
                            display(DisplayStyle.Flex)
                            property("flex-basis", "auto")
                            property("flex-direction", "column")
                            property("justify-content", "center")
                            property("align-items", "center")
                            paddingTop(20.px)
                        }
                    }) {
                        if (isConfirmationShown) {
                            DeletionConfirmation(delete, onCancel = {
                                isConfirmationShown = false
                                isDeletionError = false
                            })
                        } else {
                            DeletionMenu(scope, firebaseApp, onDelete = {
                                isConfirmationShown = true
                            }, onSuccess = {
                                authResult = null
                            })
                        }
                    }
                } else if (isLoading.value) {
                    DeletionLoader()
                }
            }
        }

        DeletionSnackbar(isDeletionError, isDeleteSuccess, delete) {
            isDeleteSuccess = false
        }

        Div(attrs = {
            style {
                paddingTop(32.px)
            }
        }) {
            PageFooter(isMobile)
        }
    }

    LaunchedEffect(authResult) {
        if (authResult == null && user == null) {
            authResult = presentFirebaseLogin(firebaseApp, isLoading, ui)
        }
    }
}

@Composable
private fun DeletionConfirmation(
    delete: (Boolean) -> Job,
    onCancel: () -> Unit,
) {
    P {
        Text(
            "Are you sure you want to delete your account?",
        )
    }

    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            property("flex-direction", "row")
        }
    }) {
        Button(attrs = {
            onClick {
                onCancel()
            }
            classes(WtTexts.wtButton)
            style {
                marginRight(16.px)
            }
        }) {
            Text(Localizations.currentLocale.cancel)
        }

        Button(attrs = {
            onClick {
                delete(true)
            }
            classes(WtTexts.wtButton, WtTexts.destructiveButton)
            style {
                marginLeft(16.px)
            }
        }) {
            Text(Localizations.currentLocale.delete)
        }
    }
}

@Composable
private fun DeletionMenu(
    scope: CoroutineScope,
    firebaseApp: dynamic,
    onDelete: () -> Unit,
    onSuccess: () -> Unit,
) {
    DeletionDisclaimer()

    Button(attrs = {
        onClick {
            onDelete()
        }
        classes(WtTexts.wtButton, WtTexts.destructiveButton)
    }) {
        Text(Localizations.currentLocale.delete)
    }

    Button(attrs = {
        onClick {
            scope.launch {
                (firebaseApp.auth().signOut() as Promise<*>).await()
                onSuccess()
            }
        }
        classes(WtTexts.wtButton)
        style {
            marginTop(16.px)
        }
    }) {
        Text(Localizations.currentLocale.logout)
    }
}

@Composable
private fun DeletionLoader() {
    Div(attrs = {
        id("spinner")
        style {
            width(100.percent)
            display(DisplayStyle.Flex)
            property("flex-basis", "auto")
            property("flex-direction", "column")
            property("justify-content", "center")
            property("align-items", "center")
        }
    }) {
        Div(attrs = {
            classes(AppStylesheet.loader)
        })
    }
}

@Composable
private fun DeletionSnackbar(
    isDeletionError: Boolean,
    isDeleteSuccess: Boolean,
    delete: (Boolean) -> Job,
    dismissSucess: () -> Unit,
) {
    Div(attrs = {
        id("snackbar")

        classes(AppStylesheet.snackbar)

        if (isDeletionError) {
            style {
                property("visibility", "visible")
            }
        } else if (isDeleteSuccess) {
            classes(AppStylesheet.snackbarShowSuccess)
        }
    }) {
        if (isDeletionError) {
            Text(
                "Failed to delete due to a system error",
            )
            Button(attrs = {
                classes(AppStylesheet.actionButton)
                style {
                    marginLeft(8.px)
                }
                onClick {
                    delete(false)
                }
            }) {
                Text(
                    "Retry",
                )
            }
        } else if (isDeleteSuccess) {
            Text(
                "Account deleted.",
            )

            LaunchedEffect(isDeleteSuccess) {
                delay(2.9.seconds)
                dismissSucess()
            }
        }
    }
}

@Composable
private fun DeletionDisclaimer() {
    Div(attrs = {
        style {
            padding(16.px)
            maxWidth(600.px)
        }
    }) {
        P {
            Text(
                "Please be aware that once your account is deleted, all your data will be permanently removed and cannot be recovered. This includes any personal information, settings, and any content you have created or stored within the account.",
            )
        }
        P {
            B {
                Text("Important:")
            }
        }
        P {
            Ul {
                Li {
                    Text("All your data will be permanently deleted.")
                }
                Li {
                    Text("This action is irreversible.")
                }
            }
        }
    }
}

private suspend fun presentFirebaseLogin(
    firebaseApp: dynamic,
    isLoading: MutableState<Boolean>,
    ui: dynamic,
): dynamic {
    return suspendCoroutine { cont ->
        val input =
            json(
                "signInOptions" to
                    arrayOf(
                        json(
                            "provider" to firebaseApp.auth.EmailAuthProvider.PROVIDER_ID,
                            "requireDisplayName" to false,
                            "signInMethod" to firebaseApp.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                        ),
                        json("provider" to firebaseApp.auth.GoogleAuthProvider.PROVIDER_ID),
                        json("provider" to firebaseApp.auth.FacebookAuthProvider.PROVIDER_ID),
                        "apple.com",
                    ),
                "privacyPolicyUrl" to "https://dingvoice.com/privacy",
                "tosUrl" to "https://dingvoice.com/tos",
                "callbacks" to
                    json(
                        "uiShown" to {
                            isLoading.value = false
                        },
                        "signInSuccessWithAuthResult" to function@{ authResult: dynamic, redirectUrl: dynamic ->
                            cont.resume(authResult)
                            return@function false
                        },
                    ),
            )

        ui.start(
            "#firebaseui-auth-container",
            input,
        )

        Unit
    }
}
