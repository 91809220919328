// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Mr  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} आणि ${privacyPolicy} स्वीकारा" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "अवैध कोड ."
override val add_client_action: String = "क्लायंट जोडा"
override val add_customer: String = "ग्राहक जोडा"
override val add_expense: String = "खर्च जोडा"
override val add_fields_label: String = "मिळवणे:"
override val add_invoice_action: String = "तयार करा"
override val add_item_action: String = "आयटम जोडा"
override val add_item_button: String = "आयटम जोडा"
override val add_signature_action: String = "स्वाक्षरी जोडा"
override val add_tax_action: String = "कर जोडा"
override val address_fields: String = "पत्ता"
override val address_line_hint: String = "पत्ता ओळ"
override val advanced: String = "प्रगत"
override val agree_marketing: String = "मी डायरेक्ट मार्केटिंग कम्युनिकेशन्स प्राप्त करण्यास सहमत आहे"
override val all_notifications: String = "सर्व सूचना"
override val amortization: String = "amortization"
override val amount: String = "रक्कम"
override val asset: String = "asset"
override val attach_file: String = "फाईल जोडा"
override val attachment: String = "संलग्नक"
override val auth_error: String = "कारवाई अयशस्वी झाली, पुन्हा प्रयत्न करा."
override val available_for_paid: String = "क्लाऊड सिंक केवळ सशुल्क सदस्यता अंतर्गत उपलब्ध आहे."
override val backups_option: String = "बॅकअप"
override val balance: String = "balance"
override val bank_account: String = "बँक खाते"
override val bill_number: String = "बिल क्रमांक"
override val cancel: String = "रद्द करा"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "श्रेण्या"
override val category: String = "श्रेणी"
override val category_add: String = "श्रेणी जोडा"
override val category_delete: String = "श्रेणी हटवा"
override val category_name: String = "श्रेणीचे नाव"
override val change_language: Formattable = Formattable { (language) -> "चलन भाषा: ${language}" }
override val change_language_label: String = "चलन भाषा"
override val change_template: String = "साचा बदलवा"
override val city_hint: String = "शहर"
override val clear: String = "पारदर्शी"
override val client: String = "अशील"
override val client_actions: String = "क्लायंटच्या क्रिया"
override val client_deleted_message: String = "ग्राहक हटवला"
override val clients_error_required_fields: String = "किमान एक मूल्य आवश्यक आहे."
override val clients_option: String = "ग्राहक"
override val close: String = "मिटणे"
override val cloud_sync: String = "क्लाउड सिंकName"
override val coming_soon: String = "लवकरच येत आहे!"
override val company_logo: String = "कंपनी लोगोName"
override val company_name_hint: String = "कंपनी"
override val confirm_password_label: String = "गुप्तशब्दाची पुष्टी करा"
override val confirmation_title: String = "तुला खात्री आहे?"
override val convert_to_invoice: String = "चलनात रुपांतरीत करा"
override val country_hint: String = "देश"
override val create_client: String = "ग्राहक तयार करा"
override val create_company: String = "कंपनी निर्माण करा"
override val create_document: String = "दस्तऐवज बनवा"
override val create_estimate: String = "अदमासें"
override val create_invoice: String = "बीजक"
override val create_item: String = "घटक तयार करा"
override val create_new_client: String = "नविन ग्राहक तयार करा"
override val create_new_item: String = "नविन घटक तयार करा"
override val create_new_tax: String = "नविन कर तयार करा"
override val create_signature: String = "सही"
override val created: String = "निर्माण केले"
override val credit: String = "credit"
override val customer: String = "ग्राहक"
override val date: String = "तारीख"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "डीफॉल्ट चलन: ${currency}" }
override val default_currency_label: String = "डीफॉल्ट चलन"
override val delete: String = "हटवा"
override val delete_account: String = "खाते हटवा"
override val delete_confirmation_message: String = "तुमचा सर्व डेटा नष्ट होईल."
override val delete_item_title: String = "आयटम हटवा?"
override val delete_signature: String = "स्वाक्षरी नष्ट करा"
override val depreciation: String = "depreciation"
override val description: String = "वर्णनName"
override val description_hint: String = "वर्णनName"
override val detailed_report: String = "तपशीलवार अहवाल"
override val disabled: String = "अकार्यान्वीत"
override val discount: String = "सवलत"
override val discount_hint: String = "सूट"
override val display_af: String = "आफ्रिकन"
override val display_am: String = "अम्हारिक"
override val display_ar: String = "अरबी"
override val display_as: String = "आसामी"
override val display_az: String = "अझरबैजानी"
override val display_be: String = "बेलारूसी"
override val display_bg: String = "बल्गेरियन"
override val display_bn: String = "बंगाली"
override val display_bs: String = "बोस्नियन"
override val display_ca: String = "कॅटलान"
override val display_cs: String = "झेक"
override val display_da: String = "डॅनिश"
override val display_de: String = "जर्मन"
override val display_el: String = "ग्रीक"
override val display_en: String = "इंग्रजी"
override val display_es: String = "स्पॅनिश"
override val display_es_MX: String = "स्पॅनिश (मेक्सिको)"
override val display_et: String = "एस्टोनियन"
override val display_eu: String = "बास्क"
override val display_fa: String = "पर्शियन"
override val display_fi: String = "फिनिश"
override val display_fil: String = "फिलिपिनो"
override val display_fr: String = "फ्रेंच"
override val display_fr_CA: String = "कॅनेडियन फ्रेंच"
override val display_gl: String = "गॅलिशियन"
override val display_gu: String = "गुजराती"
override val display_he: String = "हिब्रू"
override val display_hi: String = "हिंदी"
override val display_hr: String = "क्रोएशियन"
override val display_hu: String = "हंगेरियन"
override val display_hy: String = "आर्मेनियन"
override val display_id: String = "इंडोनेशियन"
override val display_is: String = "आइसलँडिक"
override val display_it: String = "इटालियन"
override val display_ja: String = "जपानी"
override val display_ka: String = "जॉर्जियन"
override val display_kk: String = "कझाक"
override val display_km: String = "मध्य ख्मेर"
override val display_kn: String = "कन्नड"
override val display_ko: String = "कोरियन"
override val display_ky: String = "किरगीझ"
override val display_lo: String = "श्रम"
override val display_lt: String = "लिथुआनियन"
override val display_lv: String = "लाटवियन"
override val display_mk: String = "मॅसेडोनियन"
override val display_ml: String = "मल्याळम"
override val display_mn: String = "मंगोलियन"
override val display_mr: String = "मराठी"
override val display_ms: String = "मलय"
override val display_my: String = "बर्मी"
override val display_nb: String = "नॉर्वेजियन बोकमाल"
override val display_ne: String = "नेपाळी"
override val display_nl: String = "डच"
override val display_or: String = "ओरिया"
override val display_pa: String = "पंजाबी"
override val display_pl: String = "पोलिश"
override val display_pt: String = "पोर्तुगीज"
override val display_pt_BR: String = "पोर्तुगीज (ब्राझील)"
override val display_pt_PT: String = "युरोपियन पोर्तुगीज"
override val display_ro: String = "रोमानियन"
override val display_ru: String = "रशियन"
override val display_si: String = "सिंहली"
override val display_sk: String = "स्लोव्हाक"
override val display_sl: String = "स्लोव्हेनियन"
override val display_sq: String = "अल्बेनियन"
override val display_sr: String = "सर्बियन"
override val display_sv: String = "स्वीडिश"
override val display_sw: String = "स्वाहिली"
override val display_ta: String = "तमिळ"
override val display_te: String = "तेलुगु"
override val display_th: String = "थाई"
override val display_tr: String = "तुर्की"
override val display_uk: String = "युक्रेनियन"
override val display_ur: String = "उर्दू"
override val display_uz: String = "उझबेक"
override val display_vi: String = "व्हिएतनामी"
override val display_zh_Hant_TW: String = "चीनी (तैवान, पारंपारिक)"
override val display_zu: String = "झुलू"
override val document_estimate: String = "अदमासें"
override val document_invoice: String = "बीजक"
override val document_quote: String = "कोट"
override val done: String = "पूर्ण झाले"
override val download_app: String = "अॅप डाउनलोड करा"
override val download_invoice: String = "बीजक डाउनलोड करा"
override val download_pdf: String = "PDF डाउनलोड करा"
override val draw_signature: String = "स्वाक्षरी काढा"
override val due: Formattable = Formattable { (date) -> "देय ${date}" }
override val due_date: String = "देय तारीख"
override val due_day: String = "एका दिवसात"
override val due_label: String = "देय"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} देय आहे ${dueWhen}!" }
override val due_today: String = "आज"
override val due_week: String = "एका आठवड्यात"
override val duplicate: String = "नक्कल"
override val duration_day: String = "दिवस"
override val duration_month: String = "महिना"
override val duration_week: String = "आठवडा"
override val duration_year: String = "वर्ष"
override val email: String = "ईमेल"
override val email_blank_error: String = "ईमेल आवश्यक आहे."
override val email_error: String = "अवैध ईमेल पत्ता ."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "तुमच्या व्यवसायाबद्दल धन्यवाद. संपूर्ण ${documentType} पाहण्यासाठी या अक्षराच्या शेवटी बटणावर क्लिक करा. लक्षात ठेवा ते ${dueDate} पर्यंत वैध आहे." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "तुम्हाला एक नवीन मिळाले आहे${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} पहा" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "प्रिय ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "तुमच्या व्यवसायाबद्दल धन्यवाद. संपूर्ण ${documentType} पाहण्यासाठी या पत्राच्या शेवटी बटणावर क्लिक करा. कृपया ${dueDate} पर्यंत शिल्लक भरा." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "तुम्हाला एक नवीन मिळाले आहे${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "पाठविलेला पासवर्ड रीसेट करण्यासाठी ईमेल करा, कृपया आपला इनबॉक्स तपासा."
override val empty_clients_list_message: String = "आपल्याकडे ट्रॅक करण्यासाठी ग्राहक नाहीत. एक नवीन क्लायंट जोडा."
override val empty_estimate_list_message: String = "आपला पहिला अंदाज तयार करा"
override val empty_expenses: String = "तुम्हाला ट्रॅक करण्यासाठी कोणताही खर्च नाही. नवीन खर्च जोडा."
override val empty_invoice_list_message: String = "आपले पहिले चलन तयार करा"
override val empty_items_list_message: String = "आपल्याकडे वस्तू नाहीत, नवीन वस्तू जोडा!"
override val empty_signatures_list_message: String = "सही मिळाली नाही. एक निर्माण करा!"
override val empty_taxes_list_message: String = "आपल्याकडे कराचे कोणतेही नियम नाहीत. नवीन कर नियम जोडा ."
override val empty_templates_list_message: String = "टेम्पलेट्स अनुपलब्ध."
override val enabled: String = "सक्षम केले"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "अंदाज #"
override val estimates: String = "अंदाज"
override val estimates_report: String = "अंदाज"
override val expense_bank_fees: String = "बँक फी"
override val expense_benefits: String = "फायदे"
override val expense_communication: String = "संवाद"
override val expense_debt: String = "कर्ज"
override val expense_deleted: String = "खर्च हटवला"
override val expense_depreciation: String = "घसारा"
override val expense_gifts: String = "भेटवस्तू"
override val expense_insurance: String = "विमा"
override val expense_inventory: String = "इन्व्हेंटरी"
override val expense_legal: String = "कायदेशीर"
override val expense_maintenance: String = "देखभाल"
override val expense_marketing: String = "मार्केटिंग"
override val expense_meals: String = "जेवण"
override val expense_office_supplies: String = "कार्यालयीन सामान"
override val expense_rent: String = "भाड्याने"
override val expense_salaries: String = "पगार"
override val expense_shopping: String = "खरेदी"
override val expense_software: String = "सॉफ्टवेअर"
override val expense_status_available: String = "उपलब्ध"
override val expense_status_processing: String = "प्रक्रिया करत आहे"
override val expense_tax: String = "कर"
override val expense_training: String = "प्रशिक्षण"
override val expense_transport: String = "वाहतूक"
override val expense_travel: String = "प्रवास"
override val expense_utility: String = "उपयुक्तता"
override val expenses: String = "खर्च"
override val feedback_option: String = "अभिप्राय"
override val filter: String = "गाळणे"
override val first_name_hint: String = "पहिले नाव"
override val forgot_password: String = "गुप्तशब्द पुन्हस्थापीत करा"
override val forgot_password_label: String = "पासवर्ड विसरला"
override val from: String = "हून"
override val general_error: String = "एरर आली, कृपया नंतर पुन्हा प्रयत्न करा."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "परत जा"
override val group_by: String = "ग्रुप बाय:"
override val image_view: String = "प्रतिमा"
override val install: String = "स्थापित करा"
override val invalid_credentials_error: String = "अवैध क्रेडेन्शियल्स ."
override val invalid_email_error: String = "अवैध ईमेल ."
override val invalid_user_error: String = "अवैध वापरकर्ता क्रेडेन्शियल्स."
override val invoice_deleted_message: String = "चलन हटविले"
override val invoice_menu: String = "चलन मेन्यूName"
override val invoice_number: String = "बीजक #"
override val invoices: String = "पावत्या"
override val items_option: String = "आयटम"
override val keep: String = "ठेवा"
override val language_selector: Formattable = Formattable { (selected) -> "भाषा ${selected}" }
override val last_name_hint: String = "आडनाव"
override val ledger: String = "ledger"
override val legal: String = "कायदेशीर"
override val legal_error: String = "सेवा अटी आणि गोपनीयता धोरण स्वीकारणे आवश्यक आहे"
override val liabilities: String = "liabilities"
override val loading: String = "दाखल करत आहे"
override val login_required_error: String = "लॉगइन आवश्यक ."
override val logo_view: String = "लोगोName"
override val logout: String = "लॉगआउटName"
override val logout_prompt: String = "आपला डेटा क्लाऊडसह समक्रमित केला जात नाही. लॉगआउटवरील पावत्या, अंदाज, ग्राहक आणि इतर माहिती नष्ट करा?"
override val main_screen: String = "मुख्य पडदाComment"
override val mark_paid: String = "पेड म्हणून चिन्हांकित करा"
override val message: String = "संदेश"
override val missing_sender_msg: String = "प्रेषकाच्या माहितीशिवाय कागदपत्र अवैध असू शकते."
override val missing_sender_title: String = "प्रेषक माहिती गहाळ आहे"
override val month_april: String = "एप्रिल"
override val month_april_short: String = "एप्रिल"
override val month_august: String = "ऑगस्ट"
override val month_august_short: String = "ऑगस्ट"
override val month_december: String = "डिसेंबर"
override val month_december_short: String = "डिसें."
override val month_february: String = "फेब्रुवारी"
override val month_february_short: String = "फेब्रु"
override val month_january: String = "जानेवारी"
override val month_january_short: String = "जाने."
override val month_july: String = "जुलै"
override val month_july_short: String = "जुलै"
override val month_june: String = "जून"
override val month_june_short: String = "जून"
override val month_march: String = "मार्च"
override val month_march_short: String = "मार्च"
override val month_may: String = "मे"
override val month_may_short: String = "मे"
override val month_november: String = "नोव्हेंबर"
override val month_november_short: String = "नोव्हें"
override val month_october: String = "ऑक्टोबर"
override val month_october_short: String = "ऑक्टो"
override val month_september: String = "सप्टेंबर"
override val month_september_short: String = "सप्टें"
override val multifactor_error: String = "सत्यापित करण्यात अपयशी."
override val new: String = "नवीन"
override val news: String = "बातमी"
override val next: String = "पुढील"
override val no: String = "no"
override val no_data_available: String = "माहिती उपलब्ध नाही"
override val no_search_results: String = "क्षमस्व, आम्हाला कोणताही परिणाम सापडला नाही."
override val none: String = "काहीही नाही"
override val note_fields: String = "नोट्स"
override val note_hint: String = "नोट"
override val notifications_option: String = "सूचनाName"
override val ok: String = "ठीक आहे"
override val onboarding: String = "ऑनबोर्डिंग"
override val onboarding_aboutus_message: String = "DingVoice चे आवश्यक भाग कायमचे विनामूल्य आहेत. तुमचा व्यवसाय वाढवण्यात मदत करणे, तुमचा अभिप्राय शेअर करून आम्हाला सुधारण्यात मदत करणे हे आमचे ध्येय आहे."
override val onboarding_aboutus_title: String = "आम्ही मदत करण्यासाठी येथे आहोत"
override val onboarding_paid_1: String = "व्यावसायिक दिसणार्‍या टेम्पलेट्समध्ये प्रवेश करा"
override val onboarding_paid_2: String = "तुमच्या आर्थिक स्थितीत सखोल नजर टाका"
override val onboarding_paid_3: String = "समस्यांचे जलद निराकरण करा"
override val onboarding_paid_4: String = "तुमची कागदपत्रे सुरक्षित ठेवा"
override val onboarding_paid_5: String = "इन्व्हॉइस स्थितीतील बदलांसह अद्ययावत रहा"
override val onboarding_paid_title: String = "यासह आणखी जलद वाढवा..."
override val onboarding_paywall_message: String = "आता सर्वोत्तम किंमतीत सर्व प्रीमियम वैशिष्ट्यांचा आनंद घ्या."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} साठी फक्त ${currencySymbol}${introAmount} आणि नंतर ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "फक्त ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} आणि नंतर फक्त ${currencySymbol}${amount} / ${duration} साठी विनामूल्य" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} साठी विनामूल्य, नंतर ${introductoryDuration} ${introPeriod} साठी ${currencySymbol}${introductoryAmount} आणि नंतर फक्त ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "स्वयं-नूतनीकरणयोग्य. कधीही रद्द करा."
override val onboarding_paywall_restore: String = "खरेदी पुनर्संचयित करा"
override val onboarding_paywall_restore_failure: String = "खरेदी पुनर्संचयित करणे अयशस्वी झाले आहे, नंतर पुन्हा प्रयत्न करा किंवा समर्थनाशी संपर्क साधा."
override val onboarding_paywall_restore_success: String = "पुनर्संचयित यशस्वी!"
override val onboarding_paywall_title: String = "महानता प्राप्त करा!"
override val onboarding_welcome_message: String = "तुमचे इनव्हॉइस आणि अंदाज व्यवस्थापित करण्यासाठी संपूर्ण टूलसेट."
override val onboarding_welcome_title: String = "DingVoice मध्ये आपले स्वागत आहे"
override val other_state: String = "इतर"
override val overdue_state: String = "थकित"
override val paid_state: String = "सशुल्क"
override val password: String = "गुप्तशब्दComment"
override val password_blank_error: String = "पासवर्ड आवश्यक ."
override val password_mismatch_error: String = "पासवर्ड जुळत नाहीत."
override val pay: String = "pay"
override val payment_instructions_label: String = "देयक सूचना"
override val payment_reminders: String = "देयक स्मरणपत्रे"
override val phone_number_fields: String = "फोन नंबर"
override val phone_number_hint: String = "फोन नंबर"
override val postcode_hint: String = "PostcodeComment"
override val preview: String = "पूर्वदृश्यName"
override val preview_menu_hint: String = "पूर्वदृश्य मेन्यू"
override val price: String = "किंमत"
override val privacy_policy: String = "गोपनीयता धोरण"
override val profile: String = "प्रोफाइलName"
override val profitable_client: String = "तुमचा सर्वात फायदेशीर ग्राहक आहे..."
override val profits_report: String = "नफा"
override val promo_content: String = "प्रचारात्मक सामग्री"
override val quantity_hint: String = "परिमाण"
override val quantity_label_hint: String = "मात्रा लेबल"
override val query_info: String = "क्वेरी आपल्याला आयटम आणि इतर कर नियमांना इनपुट म्हणून संदर्भित सानुकूल गणना लिहिण्यास अनुमती देते. दुसर् या प्रविष्टि +, -, *, /, / % - गणित प्रचालकांशी दुवा साधण्यासाठी @ चिन्ह आणि आयटम किंवा कर वर्णन जोडा"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} तुमच्या क्लायंटला मिळाले." }
override val received_state: String = "मिळाले"
override val registration_code_fields: String = "नोंदणी कोड"
override val reminder: String = "स्मरणपत्र"
override val reports: String = "रिपोर्ट"
override val reports_not_available: String = "कोणतेही अहवाल उपलब्ध नाहीत, नंतर परत या."
override val reveal_password: String = "गुप्तशब्द उघड करा"
override val revenue: String = "revenue"
override val sales_tax: String = "व्हॅट"
override val sales_tax_number: String = "VAT number"
override val save: String = "बचत करणे"
override val scan_document: String = "दस्तऐवज स्कॅन करा"
override val search: String = "शोधणे"
override val select: String = "निवडा"
override val select_icon: String = "चिन्ह निवडा"
override val select_signature: String = "स्वाक्षरी निवडा"
override val select_template: String = "साचा निवडा"
override val send: String = "पाठवणे"
override val sent_state: String = "पाठविली"
override val setup_sender: String = "प्रेषक सेट करा"
override val share_option: String = "भाग"
override val ship_to: String = "कडे पाठवा"
override val sign_in: String = "साइन इन"
override val sign_in_apple: String = "अॅपलबरोबर साइन इन करा"
override val sign_in_email: String = "ईमेलसह साइन इन करा"
override val sign_in_facebook: String = "Facebook सह साइन इन करा"
override val sign_in_google: String = "Google सह साइन इन करा"
override val sign_up: String = "साइन अप करा"
override val sign_up_label: String = "खाते नाही?"
override val sign_up_link_label: String = "साइन अप करा!"
override val signatures_option: String = "स्वाक्षर् या"
override val skip: String = "वगळा"
override val sold_items_report: String = "विकलेल्या वस्तू"
override val sort_by: String = "क्रमवारीनुसार:"
override val status_update: String = "दस्तऐवज स्थिती अद्यतन"
override val sub_annual: String = "वार्षिक"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/वर्ष" }
override val sub_backups: String = "मेघ बॅकअप"
override val sub_insights: String = "अंतर्दृष्टी"
override val sub_month: String = "मासिक"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/महिना" }
override val sub_notifications: String = "सूचना"
override val sub_popup_message: String = "सदस्यता लवकरच येत आहेत. थोड्या वेळाने येथे परत तपासा."
override val sub_support: String = "समर्थन"
override val sub_templates: String = "टेम्पलेट्स"
override val subscribe: String = "Subscribe"
override val subscription_header: String = "यासह तुमचा व्यवसाय वाढवा:"
override val subscription_option: String = "सब्सक्रिप्शन"
override val subscriptions_load_fail: String = "क्षमस्व, आम्ही यावेळी आपल्या सदस्यता पुनर्प्राप्त करण्यात अक्षम आहोत. कृपया नंतर पुन्हा प्रयत्न करा किंवा मदतीसाठी आमच्या समर्थन कार्यसंघाशी संपर्क साधा."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "बेरजे"
override val sum: String = "बेरीज"
override val support_option: String = "पुष्टी"
override val switch_reports: String = "अहवाल स्विच करा"
override val tax: String = "कर"
override val tax_deleted_message: String = "कर हटवला"
override val tax_description_warning: String = "वापरल्यास हे चलनावर दिसून येते"
override val tax_input_flat: String = "सपाट"
override val tax_input_percent: String = "प्रतिशत"
override val tax_number_fields: String = "कर संख्या"
override val tax_number_hint: String = "कर संख्या"
override val tax_query_info_description: String = "क्वेरी माहिती"
override val tax_report: String = "कर"
override val tax_rules_option: String = "कर नियम"
override val taxed_clients_report: String = "कर भरलेले ग्राहक"
override val template: String = "साचा"
override val template_selector_info: String = "मुलभूत टेम्पलेट बदलविण्याकरीता स्वाइप करा"
override val template_unlock_promo: String = "प्रीमियमसह अधिक टेम्पलेट्समध्ये प्रवेश करा"
override val templates_option: String = "टेम्पलेट्स"
override val terms_of_service: String = "सेवाशर्ती"
override val thank_you: String = "धन्यवाद"
override val to: String = ""
override val total: Formattable = Formattable { (sum) -> "एकूण: ${sum}" }
override val total_label: String = "एकूण"
override val undo_action: String = "पूर्ववत करा"
override val unit: String = "एकक"
override val unit_cost_hint: String = "एकक मूल्य"
override val unknown_client: String = "अज्ञात क्लायंट"
override val unlock: String = "अनलॉक करा"
override val unnamed_item: String = "अनामित आयटम"
override val upgrade: String = "श्रेणीसुधारित करा"
override val user_collision_error: String = "खाते आधीच अस्तित्वात आहे."
override val valid_until: String = "वैध होईपर्यंत"
override val warning: String = "ताकीद!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} आणि ${privacyPolicy} स्वीकारा" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "अवैध कोड .",
	"add_client_action" to "क्लायंट जोडा",
	"add_customer" to "ग्राहक जोडा",
	"add_expense" to "खर्च जोडा",
	"add_fields_label" to "मिळवणे:",
	"add_invoice_action" to "तयार करा",
	"add_item_action" to "आयटम जोडा",
	"add_item_button" to "आयटम जोडा",
	"add_signature_action" to "स्वाक्षरी जोडा",
	"add_tax_action" to "कर जोडा",
	"address_fields" to "पत्ता",
	"address_line_hint" to "पत्ता ओळ",
	"advanced" to "प्रगत",
	"agree_marketing" to "मी डायरेक्ट मार्केटिंग कम्युनिकेशन्स प्राप्त करण्यास सहमत आहे",
	"all_notifications" to "सर्व सूचना",
	"amortization" to "amortization",
	"amount" to "रक्कम",
	"asset" to "asset",
	"attach_file" to "फाईल जोडा",
	"attachment" to "संलग्नक",
	"auth_error" to "कारवाई अयशस्वी झाली, पुन्हा प्रयत्न करा.",
	"available_for_paid" to "क्लाऊड सिंक केवळ सशुल्क सदस्यता अंतर्गत उपलब्ध आहे.",
	"backups_option" to "बॅकअप",
	"balance" to "balance",
	"bank_account" to "बँक खाते",
	"bill_number" to "बिल क्रमांक",
	"cancel" to "रद्द करा",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "श्रेण्या",
	"category" to "श्रेणी",
	"category_add" to "श्रेणी जोडा",
	"category_delete" to "श्रेणी हटवा",
	"category_name" to "श्रेणीचे नाव",
	"change_language" to Formattable { (language) -> "चलन भाषा: ${language}" },
	"change_language_label" to "चलन भाषा",
	"change_template" to "साचा बदलवा",
	"city_hint" to "शहर",
	"clear" to "पारदर्शी",
	"client" to "अशील",
	"client_actions" to "क्लायंटच्या क्रिया",
	"client_deleted_message" to "ग्राहक हटवला",
	"clients_error_required_fields" to "किमान एक मूल्य आवश्यक आहे.",
	"clients_option" to "ग्राहक",
	"close" to "मिटणे",
	"cloud_sync" to "क्लाउड सिंकName",
	"coming_soon" to "लवकरच येत आहे!",
	"company_logo" to "कंपनी लोगोName",
	"company_name_hint" to "कंपनी",
	"confirm_password_label" to "गुप्तशब्दाची पुष्टी करा",
	"confirmation_title" to "तुला खात्री आहे?",
	"convert_to_invoice" to "चलनात रुपांतरीत करा",
	"country_hint" to "देश",
	"create_client" to "ग्राहक तयार करा",
	"create_company" to "कंपनी निर्माण करा",
	"create_document" to "दस्तऐवज बनवा",
	"create_estimate" to "अदमासें",
	"create_invoice" to "बीजक",
	"create_item" to "घटक तयार करा",
	"create_new_client" to "नविन ग्राहक तयार करा",
	"create_new_item" to "नविन घटक तयार करा",
	"create_new_tax" to "नविन कर तयार करा",
	"create_signature" to "सही",
	"created" to "निर्माण केले",
	"credit" to "credit",
	"customer" to "ग्राहक",
	"date" to "तारीख",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "डीफॉल्ट चलन: ${currency}" },
	"default_currency_label" to "डीफॉल्ट चलन",
	"delete" to "हटवा",
	"delete_account" to "खाते हटवा",
	"delete_confirmation_message" to "तुमचा सर्व डेटा नष्ट होईल.",
	"delete_item_title" to "आयटम हटवा?",
	"delete_signature" to "स्वाक्षरी नष्ट करा",
	"depreciation" to "depreciation",
	"description" to "वर्णनName",
	"description_hint" to "वर्णनName",
	"detailed_report" to "तपशीलवार अहवाल",
	"disabled" to "अकार्यान्वीत",
	"discount" to "सवलत",
	"discount_hint" to "सूट",
	"display_af" to "आफ्रिकन",
	"display_am" to "अम्हारिक",
	"display_ar" to "अरबी",
	"display_as" to "आसामी",
	"display_az" to "अझरबैजानी",
	"display_be" to "बेलारूसी",
	"display_bg" to "बल्गेरियन",
	"display_bn" to "बंगाली",
	"display_bs" to "बोस्नियन",
	"display_ca" to "कॅटलान",
	"display_cs" to "झेक",
	"display_da" to "डॅनिश",
	"display_de" to "जर्मन",
	"display_el" to "ग्रीक",
	"display_en" to "इंग्रजी",
	"display_es" to "स्पॅनिश",
	"display_es_MX" to "स्पॅनिश (मेक्सिको)",
	"display_et" to "एस्टोनियन",
	"display_eu" to "बास्क",
	"display_fa" to "पर्शियन",
	"display_fi" to "फिनिश",
	"display_fil" to "फिलिपिनो",
	"display_fr" to "फ्रेंच",
	"display_fr_CA" to "कॅनेडियन फ्रेंच",
	"display_gl" to "गॅलिशियन",
	"display_gu" to "गुजराती",
	"display_he" to "हिब्रू",
	"display_hi" to "हिंदी",
	"display_hr" to "क्रोएशियन",
	"display_hu" to "हंगेरियन",
	"display_hy" to "आर्मेनियन",
	"display_id" to "इंडोनेशियन",
	"display_is" to "आइसलँडिक",
	"display_it" to "इटालियन",
	"display_ja" to "जपानी",
	"display_ka" to "जॉर्जियन",
	"display_kk" to "कझाक",
	"display_km" to "मध्य ख्मेर",
	"display_kn" to "कन्नड",
	"display_ko" to "कोरियन",
	"display_ky" to "किरगीझ",
	"display_lo" to "श्रम",
	"display_lt" to "लिथुआनियन",
	"display_lv" to "लाटवियन",
	"display_mk" to "मॅसेडोनियन",
	"display_ml" to "मल्याळम",
	"display_mn" to "मंगोलियन",
	"display_mr" to "मराठी",
	"display_ms" to "मलय",
	"display_my" to "बर्मी",
	"display_nb" to "नॉर्वेजियन बोकमाल",
	"display_ne" to "नेपाळी",
	"display_nl" to "डच",
	"display_or" to "ओरिया",
	"display_pa" to "पंजाबी",
	"display_pl" to "पोलिश",
	"display_pt" to "पोर्तुगीज",
	"display_pt_BR" to "पोर्तुगीज (ब्राझील)",
	"display_pt_PT" to "युरोपियन पोर्तुगीज",
	"display_ro" to "रोमानियन",
	"display_ru" to "रशियन",
	"display_si" to "सिंहली",
	"display_sk" to "स्लोव्हाक",
	"display_sl" to "स्लोव्हेनियन",
	"display_sq" to "अल्बेनियन",
	"display_sr" to "सर्बियन",
	"display_sv" to "स्वीडिश",
	"display_sw" to "स्वाहिली",
	"display_ta" to "तमिळ",
	"display_te" to "तेलुगु",
	"display_th" to "थाई",
	"display_tr" to "तुर्की",
	"display_uk" to "युक्रेनियन",
	"display_ur" to "उर्दू",
	"display_uz" to "उझबेक",
	"display_vi" to "व्हिएतनामी",
	"display_zh_Hant_TW" to "चीनी (तैवान, पारंपारिक)",
	"display_zu" to "झुलू",
	"document_estimate" to "अदमासें",
	"document_invoice" to "बीजक",
	"document_quote" to "कोट",
	"done" to "पूर्ण झाले",
	"download_app" to "अॅप डाउनलोड करा",
	"download_invoice" to "बीजक डाउनलोड करा",
	"download_pdf" to "PDF डाउनलोड करा",
	"draw_signature" to "स्वाक्षरी काढा",
	"due" to Formattable { (date) -> "देय ${date}" },
	"due_date" to "देय तारीख",
	"due_day" to "एका दिवसात",
	"due_label" to "देय",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} देय आहे ${dueWhen}!" },
	"due_today" to "आज",
	"due_week" to "एका आठवड्यात",
	"duplicate" to "नक्कल",
	"duration_day" to "दिवस",
	"duration_month" to "महिना",
	"duration_week" to "आठवडा",
	"duration_year" to "वर्ष",
	"email" to "ईमेल",
	"email_blank_error" to "ईमेल आवश्यक आहे.",
	"email_error" to "अवैध ईमेल पत्ता .",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "तुमच्या व्यवसायाबद्दल धन्यवाद. संपूर्ण ${documentType} पाहण्यासाठी या अक्षराच्या शेवटी बटणावर क्लिक करा. लक्षात ठेवा ते ${dueDate} पर्यंत वैध आहे." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "तुम्हाला एक नवीन मिळाले आहे${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} पहा" },
	"email_invoice_greeting" to Formattable { (clientName) -> "प्रिय ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "तुमच्या व्यवसायाबद्दल धन्यवाद. संपूर्ण ${documentType} पाहण्यासाठी या पत्राच्या शेवटी बटणावर क्लिक करा. कृपया ${dueDate} पर्यंत शिल्लक भरा." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "तुम्हाला एक नवीन मिळाले आहे${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "पाठविलेला पासवर्ड रीसेट करण्यासाठी ईमेल करा, कृपया आपला इनबॉक्स तपासा.",
	"empty_clients_list_message" to "आपल्याकडे ट्रॅक करण्यासाठी ग्राहक नाहीत. एक नवीन क्लायंट जोडा.",
	"empty_estimate_list_message" to "आपला पहिला अंदाज तयार करा",
	"empty_expenses" to "तुम्हाला ट्रॅक करण्यासाठी कोणताही खर्च नाही. नवीन खर्च जोडा.",
	"empty_invoice_list_message" to "आपले पहिले चलन तयार करा",
	"empty_items_list_message" to "आपल्याकडे वस्तू नाहीत, नवीन वस्तू जोडा!",
	"empty_signatures_list_message" to "सही मिळाली नाही. एक निर्माण करा!",
	"empty_taxes_list_message" to "आपल्याकडे कराचे कोणतेही नियम नाहीत. नवीन कर नियम जोडा .",
	"empty_templates_list_message" to "टेम्पलेट्स अनुपलब्ध.",
	"enabled" to "सक्षम केले",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "अंदाज #",
	"estimates" to "अंदाज",
	"estimates_report" to "अंदाज",
	"expense_bank_fees" to "बँक फी",
	"expense_benefits" to "फायदे",
	"expense_communication" to "संवाद",
	"expense_debt" to "कर्ज",
	"expense_deleted" to "खर्च हटवला",
	"expense_depreciation" to "घसारा",
	"expense_gifts" to "भेटवस्तू",
	"expense_insurance" to "विमा",
	"expense_inventory" to "इन्व्हेंटरी",
	"expense_legal" to "कायदेशीर",
	"expense_maintenance" to "देखभाल",
	"expense_marketing" to "मार्केटिंग",
	"expense_meals" to "जेवण",
	"expense_office_supplies" to "कार्यालयीन सामान",
	"expense_rent" to "भाड्याने",
	"expense_salaries" to "पगार",
	"expense_shopping" to "खरेदी",
	"expense_software" to "सॉफ्टवेअर",
	"expense_status_available" to "उपलब्ध",
	"expense_status_processing" to "प्रक्रिया करत आहे",
	"expense_tax" to "कर",
	"expense_training" to "प्रशिक्षण",
	"expense_transport" to "वाहतूक",
	"expense_travel" to "प्रवास",
	"expense_utility" to "उपयुक्तता",
	"expenses" to "खर्च",
	"feedback_option" to "अभिप्राय",
	"filter" to "गाळणे",
	"first_name_hint" to "पहिले नाव",
	"forgot_password" to "गुप्तशब्द पुन्हस्थापीत करा",
	"forgot_password_label" to "पासवर्ड विसरला",
	"from" to "हून",
	"general_error" to "एरर आली, कृपया नंतर पुन्हा प्रयत्न करा.",
	"general_ledger" to "general ledger",
	"go_back_label" to "परत जा",
	"group_by" to "ग्रुप बाय:",
	"image_view" to "प्रतिमा",
	"install" to "स्थापित करा",
	"invalid_credentials_error" to "अवैध क्रेडेन्शियल्स .",
	"invalid_email_error" to "अवैध ईमेल .",
	"invalid_user_error" to "अवैध वापरकर्ता क्रेडेन्शियल्स.",
	"invoice_deleted_message" to "चलन हटविले",
	"invoice_menu" to "चलन मेन्यूName",
	"invoice_number" to "बीजक #",
	"invoices" to "पावत्या",
	"items_option" to "आयटम",
	"keep" to "ठेवा",
	"language_selector" to Formattable { (selected) -> "भाषा ${selected}" },
	"last_name_hint" to "आडनाव",
	"ledger" to "ledger",
	"legal" to "कायदेशीर",
	"legal_error" to "सेवा अटी आणि गोपनीयता धोरण स्वीकारणे आवश्यक आहे",
	"liabilities" to "liabilities",
	"loading" to "दाखल करत आहे",
	"login_required_error" to "लॉगइन आवश्यक .",
	"logo_view" to "लोगोName",
	"logout" to "लॉगआउटName",
	"logout_prompt" to "आपला डेटा क्लाऊडसह समक्रमित केला जात नाही. लॉगआउटवरील पावत्या, अंदाज, ग्राहक आणि इतर माहिती नष्ट करा?",
	"main_screen" to "मुख्य पडदाComment",
	"mark_paid" to "पेड म्हणून चिन्हांकित करा",
	"message" to "संदेश",
	"missing_sender_msg" to "प्रेषकाच्या माहितीशिवाय कागदपत्र अवैध असू शकते.",
	"missing_sender_title" to "प्रेषक माहिती गहाळ आहे",
	"month_april" to "एप्रिल",
	"month_april_short" to "एप्रिल",
	"month_august" to "ऑगस्ट",
	"month_august_short" to "ऑगस्ट",
	"month_december" to "डिसेंबर",
	"month_december_short" to "डिसें.",
	"month_february" to "फेब्रुवारी",
	"month_february_short" to "फेब्रु",
	"month_january" to "जानेवारी",
	"month_january_short" to "जाने.",
	"month_july" to "जुलै",
	"month_july_short" to "जुलै",
	"month_june" to "जून",
	"month_june_short" to "जून",
	"month_march" to "मार्च",
	"month_march_short" to "मार्च",
	"month_may" to "मे",
	"month_may_short" to "मे",
	"month_november" to "नोव्हेंबर",
	"month_november_short" to "नोव्हें",
	"month_october" to "ऑक्टोबर",
	"month_october_short" to "ऑक्टो",
	"month_september" to "सप्टेंबर",
	"month_september_short" to "सप्टें",
	"multifactor_error" to "सत्यापित करण्यात अपयशी.",
	"new" to "नवीन",
	"news" to "बातमी",
	"next" to "पुढील",
	"no" to "no",
	"no_data_available" to "माहिती उपलब्ध नाही",
	"no_search_results" to "क्षमस्व, आम्हाला कोणताही परिणाम सापडला नाही.",
	"none" to "काहीही नाही",
	"note_fields" to "नोट्स",
	"note_hint" to "नोट",
	"notifications_option" to "सूचनाName",
	"ok" to "ठीक आहे",
	"onboarding" to "ऑनबोर्डिंग",
	"onboarding_aboutus_message" to "DingVoice चे आवश्यक भाग कायमचे विनामूल्य आहेत. तुमचा व्यवसाय वाढवण्यात मदत करणे, तुमचा अभिप्राय शेअर करून आम्हाला सुधारण्यात मदत करणे हे आमचे ध्येय आहे.",
	"onboarding_aboutus_title" to "आम्ही मदत करण्यासाठी येथे आहोत",
	"onboarding_paid_1" to "व्यावसायिक दिसणार्‍या टेम्पलेट्समध्ये प्रवेश करा",
	"onboarding_paid_2" to "तुमच्या आर्थिक स्थितीत सखोल नजर टाका",
	"onboarding_paid_3" to "समस्यांचे जलद निराकरण करा",
	"onboarding_paid_4" to "तुमची कागदपत्रे सुरक्षित ठेवा",
	"onboarding_paid_5" to "इन्व्हॉइस स्थितीतील बदलांसह अद्ययावत रहा",
	"onboarding_paid_title" to "यासह आणखी जलद वाढवा...",
	"onboarding_paywall_message" to "आता सर्वोत्तम किंमतीत सर्व प्रीमियम वैशिष्ट्यांचा आनंद घ्या.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} साठी फक्त ${currencySymbol}${introAmount} आणि नंतर ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "फक्त ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} आणि नंतर फक्त ${currencySymbol}${amount} / ${duration} साठी विनामूल्य" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} साठी विनामूल्य, नंतर ${introductoryDuration} ${introPeriod} साठी ${currencySymbol}${introductoryAmount} आणि नंतर फक्त ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "स्वयं-नूतनीकरणयोग्य. कधीही रद्द करा.",
	"onboarding_paywall_restore" to "खरेदी पुनर्संचयित करा",
	"onboarding_paywall_restore_failure" to "खरेदी पुनर्संचयित करणे अयशस्वी झाले आहे, नंतर पुन्हा प्रयत्न करा किंवा समर्थनाशी संपर्क साधा.",
	"onboarding_paywall_restore_success" to "पुनर्संचयित यशस्वी!",
	"onboarding_paywall_title" to "महानता प्राप्त करा!",
	"onboarding_welcome_message" to "तुमचे इनव्हॉइस आणि अंदाज व्यवस्थापित करण्यासाठी संपूर्ण टूलसेट.",
	"onboarding_welcome_title" to "DingVoice मध्ये आपले स्वागत आहे",
	"other_state" to "इतर",
	"overdue_state" to "थकित",
	"paid_state" to "सशुल्क",
	"password" to "गुप्तशब्दComment",
	"password_blank_error" to "पासवर्ड आवश्यक .",
	"password_mismatch_error" to "पासवर्ड जुळत नाहीत.",
	"pay" to "pay",
	"payment_instructions_label" to "देयक सूचना",
	"payment_reminders" to "देयक स्मरणपत्रे",
	"phone_number_fields" to "फोन नंबर",
	"phone_number_hint" to "फोन नंबर",
	"postcode_hint" to "PostcodeComment",
	"preview" to "पूर्वदृश्यName",
	"preview_menu_hint" to "पूर्वदृश्य मेन्यू",
	"price" to "किंमत",
	"privacy_policy" to "गोपनीयता धोरण",
	"profile" to "प्रोफाइलName",
	"profitable_client" to "तुमचा सर्वात फायदेशीर ग्राहक आहे...",
	"profits_report" to "नफा",
	"promo_content" to "प्रचारात्मक सामग्री",
	"quantity_hint" to "परिमाण",
	"quantity_label_hint" to "मात्रा लेबल",
	"query_info" to "क्वेरी आपल्याला आयटम आणि इतर कर नियमांना इनपुट म्हणून संदर्भित सानुकूल गणना लिहिण्यास अनुमती देते. दुसर् या प्रविष्टि +, -, *, /, / % - गणित प्रचालकांशी दुवा साधण्यासाठी @ चिन्ह आणि आयटम किंवा कर वर्णन जोडा",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} तुमच्या क्लायंटला मिळाले." },
	"received_state" to "मिळाले",
	"registration_code_fields" to "नोंदणी कोड",
	"reminder" to "स्मरणपत्र",
	"reports" to "रिपोर्ट",
	"reports_not_available" to "कोणतेही अहवाल उपलब्ध नाहीत, नंतर परत या.",
	"reveal_password" to "गुप्तशब्द उघड करा",
	"revenue" to "revenue",
	"sales_tax" to "व्हॅट",
	"sales_tax_number" to "VAT number",
	"save" to "बचत करणे",
	"scan_document" to "दस्तऐवज स्कॅन करा",
	"search" to "शोधणे",
	"select" to "निवडा",
	"select_icon" to "चिन्ह निवडा",
	"select_signature" to "स्वाक्षरी निवडा",
	"select_template" to "साचा निवडा",
	"send" to "पाठवणे",
	"sent_state" to "पाठविली",
	"setup_sender" to "प्रेषक सेट करा",
	"share_option" to "भाग",
	"ship_to" to "कडे पाठवा",
	"sign_in" to "साइन इन",
	"sign_in_apple" to "अॅपलबरोबर साइन इन करा",
	"sign_in_email" to "ईमेलसह साइन इन करा",
	"sign_in_facebook" to "Facebook सह साइन इन करा",
	"sign_in_google" to "Google सह साइन इन करा",
	"sign_up" to "साइन अप करा",
	"sign_up_label" to "खाते नाही?",
	"sign_up_link_label" to "साइन अप करा!",
	"signatures_option" to "स्वाक्षर् या",
	"skip" to "वगळा",
	"sold_items_report" to "विकलेल्या वस्तू",
	"sort_by" to "क्रमवारीनुसार:",
	"status_update" to "दस्तऐवज स्थिती अद्यतन",
	"sub_annual" to "वार्षिक",
	"sub_annual_price" to Formattable { (price) -> "${price}/वर्ष" },
	"sub_backups" to "मेघ बॅकअप",
	"sub_insights" to "अंतर्दृष्टी",
	"sub_month" to "मासिक",
	"sub_monthly_price" to Formattable { (price) -> "${price}/महिना" },
	"sub_notifications" to "सूचना",
	"sub_popup_message" to "सदस्यता लवकरच येत आहेत. थोड्या वेळाने येथे परत तपासा.",
	"sub_support" to "समर्थन",
	"sub_templates" to "टेम्पलेट्स",
	"subscribe" to "Subscribe",
	"subscription_header" to "यासह तुमचा व्यवसाय वाढवा:",
	"subscription_option" to "सब्सक्रिप्शन",
	"subscriptions_load_fail" to "क्षमस्व, आम्ही यावेळी आपल्या सदस्यता पुनर्प्राप्त करण्यात अक्षम आहोत. कृपया नंतर पुन्हा प्रयत्न करा किंवा मदतीसाठी आमच्या समर्थन कार्यसंघाशी संपर्क साधा.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "बेरजे",
	"sum" to "बेरीज",
	"support_option" to "पुष्टी",
	"switch_reports" to "अहवाल स्विच करा",
	"tax" to "कर",
	"tax_deleted_message" to "कर हटवला",
	"tax_description_warning" to "वापरल्यास हे चलनावर दिसून येते",
	"tax_input_flat" to "सपाट",
	"tax_input_percent" to "प्रतिशत",
	"tax_number_fields" to "कर संख्या",
	"tax_number_hint" to "कर संख्या",
	"tax_query_info_description" to "क्वेरी माहिती",
	"tax_report" to "कर",
	"tax_rules_option" to "कर नियम",
	"taxed_clients_report" to "कर भरलेले ग्राहक",
	"template" to "साचा",
	"template_selector_info" to "मुलभूत टेम्पलेट बदलविण्याकरीता स्वाइप करा",
	"template_unlock_promo" to "प्रीमियमसह अधिक टेम्पलेट्समध्ये प्रवेश करा",
	"templates_option" to "टेम्पलेट्स",
	"terms_of_service" to "सेवाशर्ती",
	"thank_you" to "धन्यवाद",
	"to" to "",
	"total" to Formattable { (sum) -> "एकूण: ${sum}" },
	"total_label" to "एकूण",
	"undo_action" to "पूर्ववत करा",
	"unit" to "एकक",
	"unit_cost_hint" to "एकक मूल्य",
	"unknown_client" to "अज्ञात क्लायंट",
	"unlock" to "अनलॉक करा",
	"unnamed_item" to "अनामित आयटम",
	"upgrade" to "श्रेणीसुधारित करा",
	"user_collision_error" to "खाते आधीच अस्तित्वात आहे.",
	"valid_until" to "वैध होईपर्यंत",
	"warning" to "ताकीद!",
	"yes" to "yes",
    )
    
}