// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

val localizations = Localizations

data class LocaleMapping(
    val code: String,
    val locale: Locale,
    val displayName: String
)

object Localizations {
    var currentLocale: Locale = En()
        private set
    var currentLocaleCode: String = "en_US"
        private set
	var currentLanguage: String = "en"
		private set
    var currentMapping: LocaleMapping = LocaleMapping(
        code = "en",
        locale = En(),
        displayName = currentLocale.display_en
    )
        private set

    private val defaultLocale = En()
    private val localeMap = mapOf(
        "sl" to LocaleMapping(code = "sl", locale = Sl(), displayName = currentLocale.display_sl),
	"sk" to LocaleMapping(code = "sk", locale = Sk(), displayName = currentLocale.display_sk),
	"ur" to LocaleMapping(code = "ur", locale = Ur(), displayName = currentLocale.display_ur),
	"sw" to LocaleMapping(code = "sw", locale = Sw(), displayName = currentLocale.display_sw),
	"uz" to LocaleMapping(code = "uz", locale = Uz(), displayName = currentLocale.display_uz),
	"pl" to LocaleMapping(code = "pl", locale = Pl(), displayName = currentLocale.display_pl),
	"vi" to LocaleMapping(code = "vi", locale = Vi(), displayName = currentLocale.display_vi),
	"sq" to LocaleMapping(code = "sq", locale = Sq(), displayName = currentLocale.display_sq),
	"sv" to LocaleMapping(code = "sv", locale = Sv(), displayName = currentLocale.display_sv),
	"he" to LocaleMapping(code = "he", locale = He(), displayName = currentLocale.display_he),
	"ms" to LocaleMapping(code = "ms", locale = Ms(), displayName = currentLocale.display_ms),
	"km" to LocaleMapping(code = "km", locale = Km(), displayName = currentLocale.display_km),
	"hy" to LocaleMapping(code = "hy", locale = Hy(), displayName = currentLocale.display_hy),
	"am" to LocaleMapping(code = "am", locale = Am(), displayName = currentLocale.display_am),
	"be" to LocaleMapping(code = "be", locale = Be(), displayName = currentLocale.display_be),
	"da" to LocaleMapping(code = "da", locale = Da(), displayName = currentLocale.display_da),
	"mr" to LocaleMapping(code = "mr", locale = Mr(), displayName = currentLocale.display_mr),
	"kk" to LocaleMapping(code = "kk", locale = Kk(), displayName = currentLocale.display_kk),
	"ky" to LocaleMapping(code = "ky", locale = Ky(), displayName = currentLocale.display_ky),
	"gu" to LocaleMapping(code = "gu", locale = Gu(), displayName = currentLocale.display_gu),
	"mn" to LocaleMapping(code = "mn", locale = Mn(), displayName = currentLocale.display_mn),
	"ja" to LocaleMapping(code = "ja", locale = Ja(), displayName = currentLocale.display_ja),
	"el" to LocaleMapping(code = "el", locale = El(), displayName = currentLocale.display_el),
	"lv" to LocaleMapping(code = "lv", locale = Lv(), displayName = currentLocale.display_lv),
	"it" to LocaleMapping(code = "it", locale = It(), displayName = currentLocale.display_it),
	"ca" to LocaleMapping(code = "ca", locale = Ca(), displayName = currentLocale.display_ca),
	"is" to LocaleMapping(code = "is", locale = Is(), displayName = currentLocale.display_is),
	"cs" to LocaleMapping(code = "cs", locale = Cs(), displayName = currentLocale.display_cs),
	"es_MX" to LocaleMapping(code = "es_MX", locale = Es_MX(), displayName = currentLocale.display_es_MX),
	"zh_Hant_TW" to LocaleMapping(code = "zh_Hant_TW", locale = Zh_Hant_TW(), displayName = currentLocale.display_zh_Hant_TW),
	"te" to LocaleMapping(code = "te", locale = Te(), displayName = currentLocale.display_te),
	"ru" to LocaleMapping(code = "ru", locale = Ru(), displayName = currentLocale.display_ru),
	"ro" to LocaleMapping(code = "ro", locale = Ro(), displayName = currentLocale.display_ro),
	"zu" to LocaleMapping(code = "zu", locale = Zu(), displayName = currentLocale.display_zu),
	"pt" to LocaleMapping(code = "pt", locale = Pt(), displayName = currentLocale.display_pt),
	"fr_CA" to LocaleMapping(code = "fr_CA", locale = Fr_CA(), displayName = currentLocale.display_fr_CA),
	"uk" to LocaleMapping(code = "uk", locale = Uk(), displayName = currentLocale.display_uk),
	"sr" to LocaleMapping(code = "sr", locale = Sr(), displayName = currentLocale.display_sr),
	"pa" to LocaleMapping(code = "pa", locale = Pa(), displayName = currentLocale.display_pa),
	"si" to LocaleMapping(code = "si", locale = Si(), displayName = currentLocale.display_si),
	"ml" to LocaleMapping(code = "ml", locale = Ml(), displayName = currentLocale.display_ml),
	"mk" to LocaleMapping(code = "mk", locale = Mk(), displayName = currentLocale.display_mk),
	"kn" to LocaleMapping(code = "kn", locale = Kn(), displayName = currentLocale.display_kn),
	"bs" to LocaleMapping(code = "bs", locale = Bs(), displayName = currentLocale.display_bs),
	"my" to LocaleMapping(code = "my", locale = My(), displayName = currentLocale.display_my),
	"ar" to LocaleMapping(code = "ar", locale = Ar(), displayName = currentLocale.display_ar),
	"gl" to LocaleMapping(code = "gl", locale = Gl(), displayName = currentLocale.display_gl),
	"hr" to LocaleMapping(code = "hr", locale = Hr(), displayName = currentLocale.display_hr),
	"pt_BR" to LocaleMapping(code = "pt_BR", locale = Pt_BR(), displayName = currentLocale.display_pt_BR),
	"hu" to LocaleMapping(code = "hu", locale = Hu(), displayName = currentLocale.display_hu),
	"nl" to LocaleMapping(code = "nl", locale = Nl(), displayName = currentLocale.display_nl),
	"bg" to LocaleMapping(code = "bg", locale = Bg(), displayName = currentLocale.display_bg),
	"bn" to LocaleMapping(code = "bn", locale = Bn(), displayName = currentLocale.display_bn),
	"ne" to LocaleMapping(code = "ne", locale = Ne(), displayName = currentLocale.display_ne),
	"fil" to LocaleMapping(code = "fil", locale = Fil(), displayName = currentLocale.display_fil),
	"af" to LocaleMapping(code = "af", locale = Af(), displayName = currentLocale.display_af),
	"nb" to LocaleMapping(code = "nb", locale = Nb(), displayName = currentLocale.display_nb),
	"hi" to LocaleMapping(code = "hi", locale = Hi(), displayName = currentLocale.display_hi),
	"ka" to LocaleMapping(code = "ka", locale = Ka(), displayName = currentLocale.display_ka),
	"de" to LocaleMapping(code = "de", locale = De(), displayName = currentLocale.display_de),
	"as" to LocaleMapping(code = "as", locale = As(), displayName = currentLocale.display_as),
	"az" to LocaleMapping(code = "az", locale = Az(), displayName = currentLocale.display_az),
	"ko" to LocaleMapping(code = "ko", locale = Ko(), displayName = currentLocale.display_ko),
	"fi" to LocaleMapping(code = "fi", locale = Fi(), displayName = currentLocale.display_fi),
	"id" to LocaleMapping(code = "id", locale = Id(), displayName = currentLocale.display_id),
	"fr" to LocaleMapping(code = "fr", locale = Fr(), displayName = currentLocale.display_fr),
	"es" to LocaleMapping(code = "es", locale = Es(), displayName = currentLocale.display_es),
	"et" to LocaleMapping(code = "et", locale = Et(), displayName = currentLocale.display_et),
	"en" to LocaleMapping(code = "en", locale = En(), displayName = currentLocale.display_en),
	"fa" to LocaleMapping(code = "fa", locale = Fa(), displayName = currentLocale.display_fa),
	"lt" to LocaleMapping(code = "lt", locale = Lt(), displayName = currentLocale.display_lt),
	"or" to LocaleMapping(code = "or", locale = Or(), displayName = currentLocale.display_or),
	"eu" to LocaleMapping(code = "eu", locale = Eu(), displayName = currentLocale.display_eu),
	"lo" to LocaleMapping(code = "lo", locale = Lo(), displayName = currentLocale.display_lo),
	"ta" to LocaleMapping(code = "ta", locale = Ta(), displayName = currentLocale.display_ta),
	"th" to LocaleMapping(code = "th", locale = Th(), displayName = currentLocale.display_th),
	"tr" to LocaleMapping(code = "tr", locale = Tr(), displayName = currentLocale.display_tr)
    )

	fun isSupported(language: String): Boolean {
		return localeMap.keys.map { it.split("_").first() }.contains(language)
	}

	fun getAvailableLocale(language: String = "", country: String = ""): Locale? {
		val key = language + "_" + country
		return localeMap[key]?.locale ?: localeMap[language]?.locale
	}

    fun getAllLocales(): List<LocaleMapping> {
        return localeMap.values.distinct()
    }

    fun getAvailableMapping(language: String = "", country: String = ""): LocaleMapping {
		val key = language + "_" + country
		return localeMap[key] ?: localeMap[language] ?: LocaleMapping(
            code = "en",
            locale = En(),
            displayName = currentLocale.display_en
        )
	}

    fun setLocale(language: String = "", country: String = "") {
		val key = language + "_" + country
		val locale = getAvailableLocale(language, country)
        currentLanguage = language
        currentLocaleCode = key
        currentLocale = locale ?: defaultLocale
        currentMapping = getAvailableMapping(language, country)
    }
}