// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ne  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} र ${privacyPolicy} स्वीकार गर्नुहोस्" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "अवैध कोड।"
override val add_client_action: String = "ग्राहक थप्नुहोस्"
override val add_customer: String = "ग्राहक थप्नुहोस्"
override val add_expense: String = "खर्च थप्नुहोस्"
override val add_fields_label: String = "थप्नुहोस्:"
override val add_invoice_action: String = "सिर्जना गर्नुहोस्"
override val add_item_action: String = "वस्तु थप्नुहोस्"
override val add_item_button: String = "वस्तु थप्नुहोस्"
override val add_signature_action: String = "हस्ताक्षर थप्नुहोस्"
override val add_tax_action: String = "कर थप्नुहोस्"
override val address_fields: String = "ठेगाना"
override val address_line_hint: String = "ठेगाना लाइन"
override val advanced: String = "उन्नत"
override val agree_marketing: String = "म प्रत्यक्ष मार्केटिङ संचार प्राप्त गर्न सहमत छु"
override val all_notifications: String = "सबै सूचनाहरू"
override val amortization: String = "amortization"
override val amount: String = "रकम"
override val asset: String = "asset"
override val attach_file: String = "फाइल संलग्न गर्नुहोस्"
override val attachment: String = "संलग्नक"
override val auth_error: String = "कार्य असफल भयो, फेरि प्रयास गर्नुहोस्।"
override val available_for_paid: String = "क्लाउड सिंक मात्र सशुल्क सदस्यता अन्तर्गत उपलब्ध छ।"
override val backups_option: String = "ब्याकअपहरू"
override val balance: String = "balance"
override val bank_account: String = "बैंक खाता"
override val bill_number: String = "बिल नम्बर"
override val cancel: String = "रद्द गर्नुहोस्"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "कोटीहरू"
override val category: String = "श्रेणी"
override val category_add: String = "श्रेणी थप्नुहोस्"
override val category_delete: String = "कोटी मेटाउनुहोस्"
override val category_name: String = "श्रेणी नाम"
override val change_language: Formattable = Formattable { (language) -> "इनभ्वाइस भाषा: ${language}" }
override val change_language_label: String = "इनभ्वाइस भाषा"
override val change_template: String = "टेम्प्लेट परिवर्तन गर्नुहोस्"
override val city_hint: String = "सहर"
override val clear: String = "खाली गर्नुहोस्"
override val client: String = "ग्राहक"
override val client_actions: String = "ग्राहक कार्यहरू"
override val client_deleted_message: String = "ग्राहक मेटाइयो"
override val clients_error_required_fields: String = "कम्तिमा एउटा मान आवश्यक छ।"
override val clients_option: String = "ग्राहकहरु"
override val close: String = "बन्द"
override val cloud_sync: String = "क्लाउड सिंक"
override val coming_soon: String = "आउदैछ!"
override val company_logo: String = "कम्पनीको लोगो"
override val company_name_hint: String = "कम्पनी"
override val confirm_password_label: String = "पासवर्ड सुनिश्चित गर्नुहोस"
override val confirmation_title: String = "साच्चै हो?"
override val convert_to_invoice: String = "इनभ्वाइसमा रूपान्तरण गर्नुहोस्"
override val country_hint: String = "देश"
override val create_client: String = "ग्राहक सिर्जना गर्नुहोस्"
override val create_company: String = "कम्पनी बनाउनुहोस्"
override val create_document: String = "कागजात सिर्जना गर्नुहोस्"
override val create_estimate: String = "अनुमान गर्नुहोस्"
override val create_invoice: String = "इनभ्वाइस"
override val create_item: String = "वस्तु सिर्जना गर्नुहोस्"
override val create_new_client: String = "नयाँ ग्राहक सिर्जना गर्नुहोस्"
override val create_new_item: String = "नयाँ वस्तु सिर्जना गर्नुहोस्"
override val create_new_tax: String = "नयाँ कर सिर्जना गर्नुहोस्"
override val create_signature: String = "हस्ताक्षर"
override val created: String = "सिर्जना गरियो"
override val credit: String = "credit"
override val customer: String = "ग्राहक"
override val date: String = "मिति"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "पूर्वनिर्धारित मुद्रा: ${currency}" }
override val default_currency_label: String = "पूर्वनिर्धारित मुद्रा"
override val delete: String = "मेट्नुहोस्"
override val delete_account: String = "खाता मेटाउनुहोस्"
override val delete_confirmation_message: String = "तपाईंको सबै डाटा हराउनेछ।"
override val delete_item_title: String = "वस्तु मेट्ने हो?"
override val delete_signature: String = "हस्ताक्षर मेटाउनुहोस्"
override val depreciation: String = "depreciation"
override val description: String = "विवरण"
override val description_hint: String = "वर्णन"
override val detailed_report: String = "विस्तृत रिपोर्ट"
override val disabled: String = "अक्षम"
override val discount: String = "छुट"
override val discount_hint: String = "छुट"
override val display_af: String = "अफ्रिकी"
override val display_am: String = "अम्हारिक"
override val display_ar: String = "अरबी"
override val display_as: String = "असमिया"
override val display_az: String = "अजरबैजानी"
override val display_be: String = "बेलारूसी"
override val display_bg: String = "बुल्गेरियन"
override val display_bn: String = "बंगाली"
override val display_bs: String = "बोस्नियाली"
override val display_ca: String = "क्याटालान"
override val display_cs: String = "चेक"
override val display_da: String = "डेनिस"
override val display_de: String = "जर्मन"
override val display_el: String = "ग्रीक"
override val display_en: String = "अंग्रेजी"
override val display_es: String = "स्पेनी"
override val display_es_MX: String = "स्पेनिश (मेक्सिको)"
override val display_et: String = "इस्टोनियाली"
override val display_eu: String = "बास्क"
override val display_fa: String = "फारसी"
override val display_fi: String = "फिनिस"
override val display_fil: String = "फिलिपिनो"
override val display_fr: String = "फ्रान्सेली"
override val display_fr_CA: String = "क्यानेडियन फ्रान्सेली"
override val display_gl: String = "ग्यालिसियन"
override val display_gu: String = "गुजराती"
override val display_he: String = "हिब्रू"
override val display_hi: String = "होइन"
override val display_hr: String = "क्रोएसियाली"
override val display_hu: String = "हंगेरी"
override val display_hy: String = "आर्मेनियाली"
override val display_id: String = "इन्डोनेसियाली"
override val display_is: String = "आइसल्याण्डिक"
override val display_it: String = "इटालियन"
override val display_ja: String = "जापानी"
override val display_ka: String = "जर्जियन"
override val display_kk: String = "कजाख"
override val display_km: String = "केन्द्रीय खमेर"
override val display_kn: String = "कन्नड"
override val display_ko: String = "कोरियाली"
override val display_ky: String = "किर्गिज"
override val display_lo: String = "श्रम"
override val display_lt: String = "लिथुआनियाली"
override val display_lv: String = "लाटभियन"
override val display_mk: String = "म्यासेडोनियन"
override val display_ml: String = "मलयालम"
override val display_mn: String = "मङ्गोलियन"
override val display_mr: String = "मराठी"
override val display_ms: String = "मलय"
override val display_my: String = "बर्मी"
override val display_nb: String = "नर्वेजियन बोकमाल"
override val display_ne: String = "नेपाली"
override val display_nl: String = "डच"
override val display_or: String = "उडिया"
override val display_pa: String = "पञ्जाबी"
override val display_pl: String = "पोलिश"
override val display_pt: String = "पोर्चुगिज"
override val display_pt_BR: String = "पोर्चुगिज (ब्राजिल)"
override val display_pt_PT: String = "युरोपेली पोर्चुगिज"
override val display_ro: String = "रोमानियाली"
override val display_ru: String = "रूसी"
override val display_si: String = "सिंहला"
override val display_sk: String = "स्लोभाक"
override val display_sl: String = "स्लोभेनियाली"
override val display_sq: String = "अल्बेनियन्"
override val display_sr: String = "सर्बियाई"
override val display_sv: String = "स्विडेनी"
override val display_sw: String = "स्वाहिली"
override val display_ta: String = "तमिल"
override val display_te: String = "तेलुगु"
override val display_th: String = "थाई"
override val display_tr: String = "टर्की"
override val display_uk: String = "युक्रेनी"
override val display_ur: String = "उर्दू"
override val display_uz: String = "उज्बेक"
override val display_vi: String = "भियतनामी"
override val display_zh_Hant_TW: String = "चिनियाँ (ताइवान, परम्परागत)"
override val display_zu: String = "जुलु"
override val document_estimate: String = "अनुमान गर्नुहोस्"
override val document_invoice: String = "इनभ्वाइस"
override val document_quote: String = "उद्धरण"
override val done: String = "भयो"
override val download_app: String = "एप डाउनलोड गर्नुहोस्"
override val download_invoice: String = "इनभ्वाइस डाउनलोड गर्नुहोस्"
override val download_pdf: String = "PDF डाउनलोड गर्नुहोस्"
override val draw_signature: String = "हस्ताक्षर कोर्नुहोस्"
override val due: Formattable = Formattable { (date) -> "बाँकी ${date}" }
override val due_date: String = "अन्तिम मिति"
override val due_day: String = "एक दिनमा"
override val due_label: String = "बाँकी"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} बाँकी छ ${dueWhen}!" }
override val due_today: String = "आज"
override val due_week: String = "एक हप्तामा"
override val duplicate: String = "नक्कल"
override val duration_day: String = "दिन"
override val duration_month: String = "महिना"
override val duration_week: String = "हप्ता"
override val duration_year: String = "वर्ष"
override val email: String = "ईमेल"
override val email_blank_error: String = "इमेल आवश्यक छ।"
override val email_error: String = "अवैध इमेल ठेगाना।"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "तपाईंको व्यवसायको लागि धन्यवाद। पूरा ${documentType} हेर्न यो अक्षरको अन्त्यमा रहेको बटनमा क्लिक गर्नुहोस्। नोट गर्नुहोस् यो ${dueDate} सम्म मान्य छ।" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "तपाईंले नयाँ प्राप्त गर्नुभएको छ${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} हेर्नुहोस्" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "प्रिय ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "तपाईंको व्यवसायको लागि धन्यवाद। पूरा ${documentType} हेर्न यो अक्षरको अन्त्यमा रहेको बटनमा क्लिक गर्नुहोस्। कृपया ${dueDate} सम्मको ब्यालेन्स तिर्नुहोस्।" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "तपाईंले नयाँ प्राप्त गर्नुभएको छ${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "पासवर्ड रिसेट गर्न इमेल पठाइयो, कृपया आफ्नो इनबक्स जाँच गर्नुहोस्।"
override val empty_clients_list_message: String = "तपाईंसँग ट्र्याक गर्न कुनै ग्राहक छैन। नयाँ ग्राहक थप्नुहोस्।"
override val empty_estimate_list_message: String = "आफ्नो पहिलो अनुमान सिर्जना गर्नुहोस्"
override val empty_expenses: String = "तपाईं ट्र्याक गर्न कुनै खर्च छैन। नयाँ खर्च थप्नुहोस्।"
override val empty_invoice_list_message: String = "आफ्नो पहिलो इनभ्वाइस सिर्जना गर्नुहोस्"
override val empty_items_list_message: String = "तपाईंसँग कुनै वस्तु छैन, नयाँ वस्तु थप्नुहोस्!"
override val empty_signatures_list_message: String = "कुनै हस्ताक्षर भेटिएन। एउटा बनाउनुहोस्!"
override val empty_taxes_list_message: String = "तपाईंसँग कुनै कर नियमहरू छैनन्। नयाँ कर नियम थप्नुहोस्।"
override val empty_templates_list_message: String = "टेम्प्लेटहरू उपलब्ध छैनन्।"
override val enabled: String = "सक्षम गरियो"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "अनुमान #"
override val estimates: String = "अनुमान"
override val estimates_report: String = "अनुमान"
override val expense_bank_fees: String = "बैंक शुल्क"
override val expense_benefits: String = "फाइदाहरू"
override val expense_communication: String = "सञ्चार"
override val expense_debt: String = "ऋण"
override val expense_deleted: String = "खर्च हटाइयो"
override val expense_depreciation: String = "मूल्यह्रास"
override val expense_gifts: String = "उपहारहरू"
override val expense_insurance: String = "बीमा"
override val expense_inventory: String = "सूची"
override val expense_legal: String = "कानूनी"
override val expense_maintenance: String = "मर्मतसम्भार"
override val expense_marketing: String = "मार्केटिङ"
override val expense_meals: String = "खाना"
override val expense_office_supplies: String = "कार्यालय आपूर्ति"
override val expense_rent: String = "भाडा"
override val expense_salaries: String = "तलब"
override val expense_shopping: String = "किनमेल"
override val expense_software: String = "सफ्टवेयर"
override val expense_status_available: String = "उपलब्ध"
override val expense_status_processing: String = "प्रशोधन गर्दै"
override val expense_tax: String = "कर"
override val expense_training: String = "तालिम"
override val expense_transport: String = "यातायात"
override val expense_travel: String = "यात्रा"
override val expense_utility: String = "उपयोगिता"
override val expenses: String = "खर्च"
override val feedback_option: String = "प्रतिक्रिया"
override val filter: String = "फिल्टर"
override val first_name_hint: String = "पहिलो नाम"
override val forgot_password: String = "पासवर्ड रिसेट"
override val forgot_password_label: String = "पासवर्ड भुल्नु भयो"
override val from: String = "बाट"
override val general_error: String = "त्रुटि भयो, कृपया पछि पुन: प्रयास गर्नुहोस्।"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "पछाडी जाउ"
override val group_by: String = "द्वारा समूह:"
override val image_view: String = "छवि"
override val install: String = "स्थापना गर्नुहोस्"
override val invalid_credentials_error: String = "अवैध प्रमाणहरू।"
override val invalid_email_error: String = "अवैध इमेल।"
override val invalid_user_error: String = "अवैध प्रयोगकर्ता प्रमाणहरू।"
override val invoice_deleted_message: String = "बीजक मेटाइयो"
override val invoice_menu: String = "इनभ्वाइस मेनु"
override val invoice_number: String = "बीजक #"
override val invoices: String = "इनभ्वाइसहरू"
override val items_option: String = "वस्तुहरू"
override val keep: String = "राख्न"
override val language_selector: Formattable = Formattable { (selected) -> "भाषा ${selected}" }
override val last_name_hint: String = "थर"
override val ledger: String = "ledger"
override val legal: String = "कानूनी"
override val legal_error: String = "सेवाका सर्तहरू र गोपनीयता नीति स्वीकार गर्नुपर्छ"
override val liabilities: String = "liabilities"
override val loading: String = "लोड गर्दैछ…"
override val login_required_error: String = "लगइन आवश्यक छ।"
override val logo_view: String = "लोगो"
override val logout: String = "बाहिर निस्कनु"
override val logout_prompt: String = "तपाईंको डाटा क्लाउडसँग सिङ्क्रोनाइज गरिएको छैन। लगआउटमा इनभ्वाइसहरू, अनुमानहरू, ग्राहकहरू र अन्य जानकारी मेटाउने हो?"
override val main_screen: String = "मुख्य स्क्रिन"
override val mark_paid: String = "भुक्तान गरिएको रूपमा चिन्ह लगाउनुहोस्"
override val message: String = "सन्देश"
override val missing_sender_msg: String = "प्रेषक जानकारी बिनाको कागजात अमान्य हुन सक्छ।"
override val missing_sender_title: String = "प्रेषक जानकारी छुटेको छ"
override val month_april: String = "अप्रेल"
override val month_april_short: String = "अप्रेल"
override val month_august: String = "अगस्त"
override val month_august_short: String = "अगस्ट"
override val month_december: String = "दिसम्बर"
override val month_december_short: String = "डिसेम्बर"
override val month_february: String = "फरवरी"
override val month_february_short: String = "फेब्रुअरी"
override val month_january: String = "जनवरी"
override val month_january_short: String = "जनवरी"
override val month_july: String = "जुलाई"
override val month_july_short: String = "जुलाई"
override val month_june: String = "जुन"
override val month_june_short: String = "जुन"
override val month_march: String = "मार्च"
override val month_march_short: String = "मार्च"
override val month_may: String = "मई"
override val month_may_short: String = "मई"
override val month_november: String = "नवम्बर"
override val month_november_short: String = "नोभेम्बर"
override val month_october: String = "अक्टूबर"
override val month_october_short: String = "अक्टोबर"
override val month_september: String = "सितम्बर"
override val month_september_short: String = "सेप्टेम्बर"
override val multifactor_error: String = "प्रमाणीकरण गर्न असफल।"
override val new: String = "नयाँ"
override val news: String = "समाचार"
override val next: String = "अर्को"
override val no: String = "no"
override val no_data_available: String = "कुनै डाटा उपलब्ध छैन"
override val no_search_results: String = "माफ गर्नुहोस्, हामीले कुनै परिणाम फेला पार्न सकेनौं।"
override val none: String = "कुनै पनि छैन"
override val note_fields: String = "नोटहरू"
override val note_hint: String = "नोट"
override val notifications_option: String = "सूचनाहरू"
override val ok: String = "ठिक छ"
override val onboarding: String = "अनबोर्डिङ"
override val onboarding_aboutus_message: String = "DingVoice को अत्यावश्यक भागहरू सधैंको लागि निःशुल्क छन्। हाम्रो मिशन भनेको तपाईलाई तपाईको व्यवसाय बढाउन मद्दत गर्नु हो, तपाईको प्रतिक्रिया साझा गरेर हामीलाई सुधार गर्न मद्दत गर्नु हो।"
override val onboarding_aboutus_title: String = "हामी मद्दत गर्न यहाँ छौं"
override val onboarding_paid_1: String = "पेशेवर देखिने टेम्प्लेटहरू पहुँच गर्नुहोस्"
override val onboarding_paid_2: String = "आफ्नो वित्तीय मा एक गहिरो नजर लिनुहोस्"
override val onboarding_paid_3: String = "समस्याहरू छिटो समाधान गर्नुहोस्"
override val onboarding_paid_4: String = "आफ्नो कागजात सुरक्षित राख्नुहोस्"
override val onboarding_paid_5: String = "इनभ्वाइस स्थिति परिवर्तन संग अद्यावधिक रहनुहोस्"
override val onboarding_paid_title: String = "यसको साथ अझ छिटो बढ्नुहोस् ..."
override val onboarding_paywall_message: String = "अब उत्तम मूल्यमा सबै प्रिमियम सुविधाहरूको आनन्द लिनुहोस्।"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} को लागि केवल ${currencySymbol}${introAmount} र त्यसपछि ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "केवल ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} र त्यसपछि मात्र ${currencySymbol}${amount} / ${duration} को लागि निःशुल्क" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "को लागी नि: शुल्क${trialDuration}${trialPeriod} त्यसपछि${currencySymbol}${introductoryAmount} को लागी${introductoryDuration}${introPeriod} र त्यसपछि मात्र${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "स्वत: नवीकरणीय। जुनसुकै बेला रद्द गर्नुहोस्।"
override val onboarding_paywall_restore: String = "खरिद पुनर्स्थापित गर्नुहोस्"
override val onboarding_paywall_restore_failure: String = "खरिदहरू पुनर्स्थापना असफल भएको छ, पछि पुन: प्रयास गर्नुहोस् वा समर्थनलाई सम्पर्क गर्नुहोस्।"
override val onboarding_paywall_restore_success: String = "पुनर्स्थापना सफल भयो!"
override val onboarding_paywall_title: String = "महानता प्राप्त गर्नुहोस्!"
override val onboarding_welcome_message: String = "तपाईंको इनभ्वाइसहरू र अनुमानहरू प्रबन्ध गर्न पूर्ण उपकरणसेट।"
override val onboarding_welcome_title: String = "DingVoice मा स्वागत छ"
override val other_state: String = "अन्य"
override val overdue_state: String = "ओभरडियो"
override val paid_state: String = "भुक्तान गरियो"
override val password: String = "पासवर्ड"
override val password_blank_error: String = "पासवर्ड आवश्यक छ।"
override val password_mismatch_error: String = "पासवर्डहरू मेल खाँदैनन्।"
override val pay: String = "pay"
override val payment_instructions_label: String = "भुक्तानी निर्देशनहरू"
override val payment_reminders: String = "भुक्तानी रिमाइन्डरहरू"
override val phone_number_fields: String = "फोन नम्बर"
override val phone_number_hint: String = "फोन नम्बर"
override val postcode_hint: String = "पोष्टकोड"
override val preview: String = "पूर्वावलोकन"
override val preview_menu_hint: String = "पूर्वावलोकन मेनु"
override val price: String = "मूल्य"
override val privacy_policy: String = "गोपनीयता नीति"
override val profile: String = "प्रोफाइल"
override val profitable_client: String = "तपाईंको सबैभन्दा लाभदायक ग्राहक हो ..."
override val profits_report: String = "नाफा"
override val promo_content: String = "प्रचार सामग्री"
override val quantity_hint: String = "मात्रा"
override val quantity_label_hint: String = "मात्रा लेबल"
override val query_info: String = "क्वेरीले तपाईंलाई दुवै वस्तुहरू र अन्य कर नियमहरूलाई इनपुटको रूपमा उल्लेख गर्दै आफू अनुकूल गणनाहरू लेख्न अनुमति दिन्छ। @ प्रतीक र वस्तु वा कर विवरण थप्नुहोस् अर्को प्रविष्टिमा लिङ्क गर्न +, -, *, /, % - गणित अपरेटरहरू"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} तपाईंको क्लाइन्टले प्राप्त गरेको थियो।" }
override val received_state: String = "प्राप्त भयो"
override val registration_code_fields: String = "दर्ता कोड"
override val reminder: String = "रिमाइन्डर"
override val reports: String = "रिपोर्टहरू"
override val reports_not_available: String = "कुनै रिपोर्ट उपलब्ध छैन, पछि फर्कनुहोस्।"
override val reveal_password: String = "पासवर्ड प्रकट गर्नुहोस्"
override val revenue: String = "revenue"
override val sales_tax: String = "VAT"
override val sales_tax_number: String = "भ्याट नम्बर"
override val save: String = "बचत गर्नुहोस्"
override val scan_document: String = "कागजात स्क्यान गर्नुहोस्"
override val search: String = "खोज्नुहोस्"
override val select: String = "चयन गर्नुहोस्"
override val select_icon: String = "आइकन चयन गर्नुहोस्"
override val select_signature: String = "हस्ताक्षर चयन गर्नुहोस्"
override val select_template: String = "टेम्प्लेट चयन गर्नुहोस्"
override val send: String = "पठाउनुहोस्"
override val sent_state: String = "पठाइयो"
override val setup_sender: String = "प्रेषक सेट अप गर्नुहोस्"
override val share_option: String = "साझा गर्नुहोस्"
override val ship_to: String = "मा पठाउनुहोस्"
override val sign_in: String = "साइन इन गर्नुहोस्"
override val sign_in_apple: String = "एप्पलको साथ साइन इन गर्नुहोस्"
override val sign_in_email: String = "ईमेलको साथ साइन इन गर्नुहोस्"
override val sign_in_facebook: String = "फेसबुकको साथ साइन इन गर्नुहोस्"
override val sign_in_google: String = "गुगलका साथ साईन ईन गर्नुहोस्"
override val sign_up: String = "साइन अप"
override val sign_up_label: String = "खाता छैन?"
override val sign_up_link_label: String = "साइन अप!"
override val signatures_option: String = "हस्ताक्षरहरू"
override val skip: String = "छोड्नुहोस्"
override val sold_items_report: String = "बेचेका वस्तुहरू"
override val sort_by: String = "क्रमबद्ध गर्नुहोस्:"
override val status_update: String = "कागजात स्थिति अद्यावधिक"
override val sub_annual: String = "वार्षिक"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/वर्ष" }
override val sub_backups: String = "क्लाउड ब्याकअपहरू"
override val sub_insights: String = "अन्तर्दृष्टिहरू"
override val sub_month: String = "मासिक"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/महिना" }
override val sub_notifications: String = "सुचना"
override val sub_popup_message: String = "सदस्यताहरू चाँडै आउँदैछन्। केही बेरमा यहाँ फेरि जाँच गर्नुहोस्।"
override val sub_support: String = "सहायता"
override val sub_templates: String = "टेम्प्लेटहरू"
override val subscribe: String = "सदस्यता लिनुहोस्"
override val subscription_header: String = "यसका साथ आफ्नो व्यवसाय बढाउनुहोस्:"
override val subscription_option: String = "सदस्यता"
override val subscriptions_load_fail: String = "माफ गर्नुहोस्, हामी यस समयमा तपाईंको सदस्यताहरू पुन: प्राप्त गर्न असमर्थ छौं। कृपया पछि पुन: प्रयास गर्नुहोस् वा सहायताको लागि हाम्रो समर्थन टोलीलाई सम्पर्क गर्नुहोस्।"
override val subtotal: Formattable = Formattable { (sum) -> "उप-योग: ${sum}" }
override val subtotal_label: String = "उप-योग"
override val sum: String = "योग"
override val support_option: String = "समर्थन"
override val switch_reports: String = "रिपोर्ट स्विच गर्नुहोस्"
override val tax: String = "कर"
override val tax_deleted_message: String = "कर हटाइयो"
override val tax_description_warning: String = "यदि प्रयोग गरियो भने यो इनभ्वाइसमा देखिन्छ"
override val tax_input_flat: String = "फ्ल्याट"
override val tax_input_percent: String = "प्रतिशत"
override val tax_number_fields: String = "कर नम्बर"
override val tax_number_hint: String = "कर नम्बर"
override val tax_query_info_description: String = "क्वेरी जानकारी"
override val tax_report: String = "कर"
override val tax_rules_option: String = "कर नियमहरू"
override val taxed_clients_report: String = "कर लगाइएका ग्राहकहरू"
override val template: String = "टेम्प्लेट"
override val template_selector_info: String = "पूर्वनिर्धारित टेम्प्लेट परिवर्तन गर्न स्वाइप गर्नुहोस्"
override val template_unlock_promo: String = "Premium मार्फत थप टेम्प्लेटहरू पहुँच गर्नुहोस्"
override val templates_option: String = "टेम्प्लेटहरू"
override val terms_of_service: String = "सेवाका सर्तहरु"
override val thank_you: String = "धन्यवाद"
override val to: String = "को"
override val total: Formattable = Formattable { (sum) -> "कुल: ${sum}" }
override val total_label: String = "कुल"
override val undo_action: String = "अन्डु गर्नुहोस्"
override val unit: String = "एकाइ"
override val unit_cost_hint: String = "एकाइ लागत"
override val unknown_client: String = "अज्ञात ग्राहक"
override val unlock: String = "अनलक गर्नुहोस्"
override val unnamed_item: String = "अज्ञात वस्तु"
override val upgrade: String = "अपग्रेड गर्नुहोस्"
override val user_collision_error: String = "खाता पहिल्यै अवस्थित छ।"
override val valid_until: String = "मान्य सम्म"
override val warning: String = "चेतावनी!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} र ${privacyPolicy} स्वीकार गर्नुहोस्" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "अवैध कोड।",
	"add_client_action" to "ग्राहक थप्नुहोस्",
	"add_customer" to "ग्राहक थप्नुहोस्",
	"add_expense" to "खर्च थप्नुहोस्",
	"add_fields_label" to "थप्नुहोस्:",
	"add_invoice_action" to "सिर्जना गर्नुहोस्",
	"add_item_action" to "वस्तु थप्नुहोस्",
	"add_item_button" to "वस्तु थप्नुहोस्",
	"add_signature_action" to "हस्ताक्षर थप्नुहोस्",
	"add_tax_action" to "कर थप्नुहोस्",
	"address_fields" to "ठेगाना",
	"address_line_hint" to "ठेगाना लाइन",
	"advanced" to "उन्नत",
	"agree_marketing" to "म प्रत्यक्ष मार्केटिङ संचार प्राप्त गर्न सहमत छु",
	"all_notifications" to "सबै सूचनाहरू",
	"amortization" to "amortization",
	"amount" to "रकम",
	"asset" to "asset",
	"attach_file" to "फाइल संलग्न गर्नुहोस्",
	"attachment" to "संलग्नक",
	"auth_error" to "कार्य असफल भयो, फेरि प्रयास गर्नुहोस्।",
	"available_for_paid" to "क्लाउड सिंक मात्र सशुल्क सदस्यता अन्तर्गत उपलब्ध छ।",
	"backups_option" to "ब्याकअपहरू",
	"balance" to "balance",
	"bank_account" to "बैंक खाता",
	"bill_number" to "बिल नम्बर",
	"cancel" to "रद्द गर्नुहोस्",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "कोटीहरू",
	"category" to "श्रेणी",
	"category_add" to "श्रेणी थप्नुहोस्",
	"category_delete" to "कोटी मेटाउनुहोस्",
	"category_name" to "श्रेणी नाम",
	"change_language" to Formattable { (language) -> "इनभ्वाइस भाषा: ${language}" },
	"change_language_label" to "इनभ्वाइस भाषा",
	"change_template" to "टेम्प्लेट परिवर्तन गर्नुहोस्",
	"city_hint" to "सहर",
	"clear" to "खाली गर्नुहोस्",
	"client" to "ग्राहक",
	"client_actions" to "ग्राहक कार्यहरू",
	"client_deleted_message" to "ग्राहक मेटाइयो",
	"clients_error_required_fields" to "कम्तिमा एउटा मान आवश्यक छ।",
	"clients_option" to "ग्राहकहरु",
	"close" to "बन्द",
	"cloud_sync" to "क्लाउड सिंक",
	"coming_soon" to "आउदैछ!",
	"company_logo" to "कम्पनीको लोगो",
	"company_name_hint" to "कम्पनी",
	"confirm_password_label" to "पासवर्ड सुनिश्चित गर्नुहोस",
	"confirmation_title" to "साच्चै हो?",
	"convert_to_invoice" to "इनभ्वाइसमा रूपान्तरण गर्नुहोस्",
	"country_hint" to "देश",
	"create_client" to "ग्राहक सिर्जना गर्नुहोस्",
	"create_company" to "कम्पनी बनाउनुहोस्",
	"create_document" to "कागजात सिर्जना गर्नुहोस्",
	"create_estimate" to "अनुमान गर्नुहोस्",
	"create_invoice" to "इनभ्वाइस",
	"create_item" to "वस्तु सिर्जना गर्नुहोस्",
	"create_new_client" to "नयाँ ग्राहक सिर्जना गर्नुहोस्",
	"create_new_item" to "नयाँ वस्तु सिर्जना गर्नुहोस्",
	"create_new_tax" to "नयाँ कर सिर्जना गर्नुहोस्",
	"create_signature" to "हस्ताक्षर",
	"created" to "सिर्जना गरियो",
	"credit" to "credit",
	"customer" to "ग्राहक",
	"date" to "मिति",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "पूर्वनिर्धारित मुद्रा: ${currency}" },
	"default_currency_label" to "पूर्वनिर्धारित मुद्रा",
	"delete" to "मेट्नुहोस्",
	"delete_account" to "खाता मेटाउनुहोस्",
	"delete_confirmation_message" to "तपाईंको सबै डाटा हराउनेछ।",
	"delete_item_title" to "वस्तु मेट्ने हो?",
	"delete_signature" to "हस्ताक्षर मेटाउनुहोस्",
	"depreciation" to "depreciation",
	"description" to "विवरण",
	"description_hint" to "वर्णन",
	"detailed_report" to "विस्तृत रिपोर्ट",
	"disabled" to "अक्षम",
	"discount" to "छुट",
	"discount_hint" to "छुट",
	"display_af" to "अफ्रिकी",
	"display_am" to "अम्हारिक",
	"display_ar" to "अरबी",
	"display_as" to "असमिया",
	"display_az" to "अजरबैजानी",
	"display_be" to "बेलारूसी",
	"display_bg" to "बुल्गेरियन",
	"display_bn" to "बंगाली",
	"display_bs" to "बोस्नियाली",
	"display_ca" to "क्याटालान",
	"display_cs" to "चेक",
	"display_da" to "डेनिस",
	"display_de" to "जर्मन",
	"display_el" to "ग्रीक",
	"display_en" to "अंग्रेजी",
	"display_es" to "स्पेनी",
	"display_es_MX" to "स्पेनिश (मेक्सिको)",
	"display_et" to "इस्टोनियाली",
	"display_eu" to "बास्क",
	"display_fa" to "फारसी",
	"display_fi" to "फिनिस",
	"display_fil" to "फिलिपिनो",
	"display_fr" to "फ्रान्सेली",
	"display_fr_CA" to "क्यानेडियन फ्रान्सेली",
	"display_gl" to "ग्यालिसियन",
	"display_gu" to "गुजराती",
	"display_he" to "हिब्रू",
	"display_hi" to "होइन",
	"display_hr" to "क्रोएसियाली",
	"display_hu" to "हंगेरी",
	"display_hy" to "आर्मेनियाली",
	"display_id" to "इन्डोनेसियाली",
	"display_is" to "आइसल्याण्डिक",
	"display_it" to "इटालियन",
	"display_ja" to "जापानी",
	"display_ka" to "जर्जियन",
	"display_kk" to "कजाख",
	"display_km" to "केन्द्रीय खमेर",
	"display_kn" to "कन्नड",
	"display_ko" to "कोरियाली",
	"display_ky" to "किर्गिज",
	"display_lo" to "श्रम",
	"display_lt" to "लिथुआनियाली",
	"display_lv" to "लाटभियन",
	"display_mk" to "म्यासेडोनियन",
	"display_ml" to "मलयालम",
	"display_mn" to "मङ्गोलियन",
	"display_mr" to "मराठी",
	"display_ms" to "मलय",
	"display_my" to "बर्मी",
	"display_nb" to "नर्वेजियन बोकमाल",
	"display_ne" to "नेपाली",
	"display_nl" to "डच",
	"display_or" to "उडिया",
	"display_pa" to "पञ्जाबी",
	"display_pl" to "पोलिश",
	"display_pt" to "पोर्चुगिज",
	"display_pt_BR" to "पोर्चुगिज (ब्राजिल)",
	"display_pt_PT" to "युरोपेली पोर्चुगिज",
	"display_ro" to "रोमानियाली",
	"display_ru" to "रूसी",
	"display_si" to "सिंहला",
	"display_sk" to "स्लोभाक",
	"display_sl" to "स्लोभेनियाली",
	"display_sq" to "अल्बेनियन्",
	"display_sr" to "सर्बियाई",
	"display_sv" to "स्विडेनी",
	"display_sw" to "स्वाहिली",
	"display_ta" to "तमिल",
	"display_te" to "तेलुगु",
	"display_th" to "थाई",
	"display_tr" to "टर्की",
	"display_uk" to "युक्रेनी",
	"display_ur" to "उर्दू",
	"display_uz" to "उज्बेक",
	"display_vi" to "भियतनामी",
	"display_zh_Hant_TW" to "चिनियाँ (ताइवान, परम्परागत)",
	"display_zu" to "जुलु",
	"document_estimate" to "अनुमान गर्नुहोस्",
	"document_invoice" to "इनभ्वाइस",
	"document_quote" to "उद्धरण",
	"done" to "भयो",
	"download_app" to "एप डाउनलोड गर्नुहोस्",
	"download_invoice" to "इनभ्वाइस डाउनलोड गर्नुहोस्",
	"download_pdf" to "PDF डाउनलोड गर्नुहोस्",
	"draw_signature" to "हस्ताक्षर कोर्नुहोस्",
	"due" to Formattable { (date) -> "बाँकी ${date}" },
	"due_date" to "अन्तिम मिति",
	"due_day" to "एक दिनमा",
	"due_label" to "बाँकी",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} बाँकी छ ${dueWhen}!" },
	"due_today" to "आज",
	"due_week" to "एक हप्तामा",
	"duplicate" to "नक्कल",
	"duration_day" to "दिन",
	"duration_month" to "महिना",
	"duration_week" to "हप्ता",
	"duration_year" to "वर्ष",
	"email" to "ईमेल",
	"email_blank_error" to "इमेल आवश्यक छ।",
	"email_error" to "अवैध इमेल ठेगाना।",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "तपाईंको व्यवसायको लागि धन्यवाद। पूरा ${documentType} हेर्न यो अक्षरको अन्त्यमा रहेको बटनमा क्लिक गर्नुहोस्। नोट गर्नुहोस् यो ${dueDate} सम्म मान्य छ।" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "तपाईंले नयाँ प्राप्त गर्नुभएको छ${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} हेर्नुहोस्" },
	"email_invoice_greeting" to Formattable { (clientName) -> "प्रिय ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "तपाईंको व्यवसायको लागि धन्यवाद। पूरा ${documentType} हेर्न यो अक्षरको अन्त्यमा रहेको बटनमा क्लिक गर्नुहोस्। कृपया ${dueDate} सम्मको ब्यालेन्स तिर्नुहोस्।" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "तपाईंले नयाँ प्राप्त गर्नुभएको छ${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "पासवर्ड रिसेट गर्न इमेल पठाइयो, कृपया आफ्नो इनबक्स जाँच गर्नुहोस्।",
	"empty_clients_list_message" to "तपाईंसँग ट्र्याक गर्न कुनै ग्राहक छैन। नयाँ ग्राहक थप्नुहोस्।",
	"empty_estimate_list_message" to "आफ्नो पहिलो अनुमान सिर्जना गर्नुहोस्",
	"empty_expenses" to "तपाईं ट्र्याक गर्न कुनै खर्च छैन। नयाँ खर्च थप्नुहोस्।",
	"empty_invoice_list_message" to "आफ्नो पहिलो इनभ्वाइस सिर्जना गर्नुहोस्",
	"empty_items_list_message" to "तपाईंसँग कुनै वस्तु छैन, नयाँ वस्तु थप्नुहोस्!",
	"empty_signatures_list_message" to "कुनै हस्ताक्षर भेटिएन। एउटा बनाउनुहोस्!",
	"empty_taxes_list_message" to "तपाईंसँग कुनै कर नियमहरू छैनन्। नयाँ कर नियम थप्नुहोस्।",
	"empty_templates_list_message" to "टेम्प्लेटहरू उपलब्ध छैनन्।",
	"enabled" to "सक्षम गरियो",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "अनुमान #",
	"estimates" to "अनुमान",
	"estimates_report" to "अनुमान",
	"expense_bank_fees" to "बैंक शुल्क",
	"expense_benefits" to "फाइदाहरू",
	"expense_communication" to "सञ्चार",
	"expense_debt" to "ऋण",
	"expense_deleted" to "खर्च हटाइयो",
	"expense_depreciation" to "मूल्यह्रास",
	"expense_gifts" to "उपहारहरू",
	"expense_insurance" to "बीमा",
	"expense_inventory" to "सूची",
	"expense_legal" to "कानूनी",
	"expense_maintenance" to "मर्मतसम्भार",
	"expense_marketing" to "मार्केटिङ",
	"expense_meals" to "खाना",
	"expense_office_supplies" to "कार्यालय आपूर्ति",
	"expense_rent" to "भाडा",
	"expense_salaries" to "तलब",
	"expense_shopping" to "किनमेल",
	"expense_software" to "सफ्टवेयर",
	"expense_status_available" to "उपलब्ध",
	"expense_status_processing" to "प्रशोधन गर्दै",
	"expense_tax" to "कर",
	"expense_training" to "तालिम",
	"expense_transport" to "यातायात",
	"expense_travel" to "यात्रा",
	"expense_utility" to "उपयोगिता",
	"expenses" to "खर्च",
	"feedback_option" to "प्रतिक्रिया",
	"filter" to "फिल्टर",
	"first_name_hint" to "पहिलो नाम",
	"forgot_password" to "पासवर्ड रिसेट",
	"forgot_password_label" to "पासवर्ड भुल्नु भयो",
	"from" to "बाट",
	"general_error" to "त्रुटि भयो, कृपया पछि पुन: प्रयास गर्नुहोस्।",
	"general_ledger" to "general ledger",
	"go_back_label" to "पछाडी जाउ",
	"group_by" to "द्वारा समूह:",
	"image_view" to "छवि",
	"install" to "स्थापना गर्नुहोस्",
	"invalid_credentials_error" to "अवैध प्रमाणहरू।",
	"invalid_email_error" to "अवैध इमेल।",
	"invalid_user_error" to "अवैध प्रयोगकर्ता प्रमाणहरू।",
	"invoice_deleted_message" to "बीजक मेटाइयो",
	"invoice_menu" to "इनभ्वाइस मेनु",
	"invoice_number" to "बीजक #",
	"invoices" to "इनभ्वाइसहरू",
	"items_option" to "वस्तुहरू",
	"keep" to "राख्न",
	"language_selector" to Formattable { (selected) -> "भाषा ${selected}" },
	"last_name_hint" to "थर",
	"ledger" to "ledger",
	"legal" to "कानूनी",
	"legal_error" to "सेवाका सर्तहरू र गोपनीयता नीति स्वीकार गर्नुपर्छ",
	"liabilities" to "liabilities",
	"loading" to "लोड गर्दैछ…",
	"login_required_error" to "लगइन आवश्यक छ।",
	"logo_view" to "लोगो",
	"logout" to "बाहिर निस्कनु",
	"logout_prompt" to "तपाईंको डाटा क्लाउडसँग सिङ्क्रोनाइज गरिएको छैन। लगआउटमा इनभ्वाइसहरू, अनुमानहरू, ग्राहकहरू र अन्य जानकारी मेटाउने हो?",
	"main_screen" to "मुख्य स्क्रिन",
	"mark_paid" to "भुक्तान गरिएको रूपमा चिन्ह लगाउनुहोस्",
	"message" to "सन्देश",
	"missing_sender_msg" to "प्रेषक जानकारी बिनाको कागजात अमान्य हुन सक्छ।",
	"missing_sender_title" to "प्रेषक जानकारी छुटेको छ",
	"month_april" to "अप्रेल",
	"month_april_short" to "अप्रेल",
	"month_august" to "अगस्त",
	"month_august_short" to "अगस्ट",
	"month_december" to "दिसम्बर",
	"month_december_short" to "डिसेम्बर",
	"month_february" to "फरवरी",
	"month_february_short" to "फेब्रुअरी",
	"month_january" to "जनवरी",
	"month_january_short" to "जनवरी",
	"month_july" to "जुलाई",
	"month_july_short" to "जुलाई",
	"month_june" to "जुन",
	"month_june_short" to "जुन",
	"month_march" to "मार्च",
	"month_march_short" to "मार्च",
	"month_may" to "मई",
	"month_may_short" to "मई",
	"month_november" to "नवम्बर",
	"month_november_short" to "नोभेम्बर",
	"month_october" to "अक्टूबर",
	"month_october_short" to "अक्टोबर",
	"month_september" to "सितम्बर",
	"month_september_short" to "सेप्टेम्बर",
	"multifactor_error" to "प्रमाणीकरण गर्न असफल।",
	"new" to "नयाँ",
	"news" to "समाचार",
	"next" to "अर्को",
	"no" to "no",
	"no_data_available" to "कुनै डाटा उपलब्ध छैन",
	"no_search_results" to "माफ गर्नुहोस्, हामीले कुनै परिणाम फेला पार्न सकेनौं।",
	"none" to "कुनै पनि छैन",
	"note_fields" to "नोटहरू",
	"note_hint" to "नोट",
	"notifications_option" to "सूचनाहरू",
	"ok" to "ठिक छ",
	"onboarding" to "अनबोर्डिङ",
	"onboarding_aboutus_message" to "DingVoice को अत्यावश्यक भागहरू सधैंको लागि निःशुल्क छन्। हाम्रो मिशन भनेको तपाईलाई तपाईको व्यवसाय बढाउन मद्दत गर्नु हो, तपाईको प्रतिक्रिया साझा गरेर हामीलाई सुधार गर्न मद्दत गर्नु हो।",
	"onboarding_aboutus_title" to "हामी मद्दत गर्न यहाँ छौं",
	"onboarding_paid_1" to "पेशेवर देखिने टेम्प्लेटहरू पहुँच गर्नुहोस्",
	"onboarding_paid_2" to "आफ्नो वित्तीय मा एक गहिरो नजर लिनुहोस्",
	"onboarding_paid_3" to "समस्याहरू छिटो समाधान गर्नुहोस्",
	"onboarding_paid_4" to "आफ्नो कागजात सुरक्षित राख्नुहोस्",
	"onboarding_paid_5" to "इनभ्वाइस स्थिति परिवर्तन संग अद्यावधिक रहनुहोस्",
	"onboarding_paid_title" to "यसको साथ अझ छिटो बढ्नुहोस् ...",
	"onboarding_paywall_message" to "अब उत्तम मूल्यमा सबै प्रिमियम सुविधाहरूको आनन्द लिनुहोस्।",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} को लागि केवल ${currencySymbol}${introAmount} र त्यसपछि ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "केवल ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} र त्यसपछि मात्र ${currencySymbol}${amount} / ${duration} को लागि निःशुल्क" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "को लागी नि: शुल्क${trialDuration}${trialPeriod} त्यसपछि${currencySymbol}${introductoryAmount} को लागी${introductoryDuration}${introPeriod} र त्यसपछि मात्र${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "स्वत: नवीकरणीय। जुनसुकै बेला रद्द गर्नुहोस्।",
	"onboarding_paywall_restore" to "खरिद पुनर्स्थापित गर्नुहोस्",
	"onboarding_paywall_restore_failure" to "खरिदहरू पुनर्स्थापना असफल भएको छ, पछि पुन: प्रयास गर्नुहोस् वा समर्थनलाई सम्पर्क गर्नुहोस्।",
	"onboarding_paywall_restore_success" to "पुनर्स्थापना सफल भयो!",
	"onboarding_paywall_title" to "महानता प्राप्त गर्नुहोस्!",
	"onboarding_welcome_message" to "तपाईंको इनभ्वाइसहरू र अनुमानहरू प्रबन्ध गर्न पूर्ण उपकरणसेट।",
	"onboarding_welcome_title" to "DingVoice मा स्वागत छ",
	"other_state" to "अन्य",
	"overdue_state" to "ओभरडियो",
	"paid_state" to "भुक्तान गरियो",
	"password" to "पासवर्ड",
	"password_blank_error" to "पासवर्ड आवश्यक छ।",
	"password_mismatch_error" to "पासवर्डहरू मेल खाँदैनन्।",
	"pay" to "pay",
	"payment_instructions_label" to "भुक्तानी निर्देशनहरू",
	"payment_reminders" to "भुक्तानी रिमाइन्डरहरू",
	"phone_number_fields" to "फोन नम्बर",
	"phone_number_hint" to "फोन नम्बर",
	"postcode_hint" to "पोष्टकोड",
	"preview" to "पूर्वावलोकन",
	"preview_menu_hint" to "पूर्वावलोकन मेनु",
	"price" to "मूल्य",
	"privacy_policy" to "गोपनीयता नीति",
	"profile" to "प्रोफाइल",
	"profitable_client" to "तपाईंको सबैभन्दा लाभदायक ग्राहक हो ...",
	"profits_report" to "नाफा",
	"promo_content" to "प्रचार सामग्री",
	"quantity_hint" to "मात्रा",
	"quantity_label_hint" to "मात्रा लेबल",
	"query_info" to "क्वेरीले तपाईंलाई दुवै वस्तुहरू र अन्य कर नियमहरूलाई इनपुटको रूपमा उल्लेख गर्दै आफू अनुकूल गणनाहरू लेख्न अनुमति दिन्छ। @ प्रतीक र वस्तु वा कर विवरण थप्नुहोस् अर्को प्रविष्टिमा लिङ्क गर्न +, -, *, /, % - गणित अपरेटरहरू",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} तपाईंको क्लाइन्टले प्राप्त गरेको थियो।" },
	"received_state" to "प्राप्त भयो",
	"registration_code_fields" to "दर्ता कोड",
	"reminder" to "रिमाइन्डर",
	"reports" to "रिपोर्टहरू",
	"reports_not_available" to "कुनै रिपोर्ट उपलब्ध छैन, पछि फर्कनुहोस्।",
	"reveal_password" to "पासवर्ड प्रकट गर्नुहोस्",
	"revenue" to "revenue",
	"sales_tax" to "VAT",
	"sales_tax_number" to "भ्याट नम्बर",
	"save" to "बचत गर्नुहोस्",
	"scan_document" to "कागजात स्क्यान गर्नुहोस्",
	"search" to "खोज्नुहोस्",
	"select" to "चयन गर्नुहोस्",
	"select_icon" to "आइकन चयन गर्नुहोस्",
	"select_signature" to "हस्ताक्षर चयन गर्नुहोस्",
	"select_template" to "टेम्प्लेट चयन गर्नुहोस्",
	"send" to "पठाउनुहोस्",
	"sent_state" to "पठाइयो",
	"setup_sender" to "प्रेषक सेट अप गर्नुहोस्",
	"share_option" to "साझा गर्नुहोस्",
	"ship_to" to "मा पठाउनुहोस्",
	"sign_in" to "साइन इन गर्नुहोस्",
	"sign_in_apple" to "एप्पलको साथ साइन इन गर्नुहोस्",
	"sign_in_email" to "ईमेलको साथ साइन इन गर्नुहोस्",
	"sign_in_facebook" to "फेसबुकको साथ साइन इन गर्नुहोस्",
	"sign_in_google" to "गुगलका साथ साईन ईन गर्नुहोस्",
	"sign_up" to "साइन अप",
	"sign_up_label" to "खाता छैन?",
	"sign_up_link_label" to "साइन अप!",
	"signatures_option" to "हस्ताक्षरहरू",
	"skip" to "छोड्नुहोस्",
	"sold_items_report" to "बेचेका वस्तुहरू",
	"sort_by" to "क्रमबद्ध गर्नुहोस्:",
	"status_update" to "कागजात स्थिति अद्यावधिक",
	"sub_annual" to "वार्षिक",
	"sub_annual_price" to Formattable { (price) -> "${price}/वर्ष" },
	"sub_backups" to "क्लाउड ब्याकअपहरू",
	"sub_insights" to "अन्तर्दृष्टिहरू",
	"sub_month" to "मासिक",
	"sub_monthly_price" to Formattable { (price) -> "${price}/महिना" },
	"sub_notifications" to "सुचना",
	"sub_popup_message" to "सदस्यताहरू चाँडै आउँदैछन्। केही बेरमा यहाँ फेरि जाँच गर्नुहोस्।",
	"sub_support" to "सहायता",
	"sub_templates" to "टेम्प्लेटहरू",
	"subscribe" to "सदस्यता लिनुहोस्",
	"subscription_header" to "यसका साथ आफ्नो व्यवसाय बढाउनुहोस्:",
	"subscription_option" to "सदस्यता",
	"subscriptions_load_fail" to "माफ गर्नुहोस्, हामी यस समयमा तपाईंको सदस्यताहरू पुन: प्राप्त गर्न असमर्थ छौं। कृपया पछि पुन: प्रयास गर्नुहोस् वा सहायताको लागि हाम्रो समर्थन टोलीलाई सम्पर्क गर्नुहोस्।",
	"subtotal" to Formattable { (sum) -> "उप-योग: ${sum}" },
	"subtotal_label" to "उप-योग",
	"sum" to "योग",
	"support_option" to "समर्थन",
	"switch_reports" to "रिपोर्ट स्विच गर्नुहोस्",
	"tax" to "कर",
	"tax_deleted_message" to "कर हटाइयो",
	"tax_description_warning" to "यदि प्रयोग गरियो भने यो इनभ्वाइसमा देखिन्छ",
	"tax_input_flat" to "फ्ल्याट",
	"tax_input_percent" to "प्रतिशत",
	"tax_number_fields" to "कर नम्बर",
	"tax_number_hint" to "कर नम्बर",
	"tax_query_info_description" to "क्वेरी जानकारी",
	"tax_report" to "कर",
	"tax_rules_option" to "कर नियमहरू",
	"taxed_clients_report" to "कर लगाइएका ग्राहकहरू",
	"template" to "टेम्प्लेट",
	"template_selector_info" to "पूर्वनिर्धारित टेम्प्लेट परिवर्तन गर्न स्वाइप गर्नुहोस्",
	"template_unlock_promo" to "Premium मार्फत थप टेम्प्लेटहरू पहुँच गर्नुहोस्",
	"templates_option" to "टेम्प्लेटहरू",
	"terms_of_service" to "सेवाका सर्तहरु",
	"thank_you" to "धन्यवाद",
	"to" to "को",
	"total" to Formattable { (sum) -> "कुल: ${sum}" },
	"total_label" to "कुल",
	"undo_action" to "अन्डु गर्नुहोस्",
	"unit" to "एकाइ",
	"unit_cost_hint" to "एकाइ लागत",
	"unknown_client" to "अज्ञात ग्राहक",
	"unlock" to "अनलक गर्नुहोस्",
	"unnamed_item" to "अज्ञात वस्तु",
	"upgrade" to "अपग्रेड गर्नुहोस्",
	"user_collision_error" to "खाता पहिल्यै अवस्थित छ।",
	"valid_until" to "मान्य सम्म",
	"warning" to "चेतावनी!",
	"yes" to "yes",
    )
    
}