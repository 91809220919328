// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ko  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} 및 ${privacyPolicy}에 동의합니다." }
override val account: String = "계정"
override val accounting: String = "회계"
override val accruals: String = "발생"
override val action_code_error: String = "잘못된 코드입니다."
override val add_client_action: String = "클라이언트 추가"
override val add_customer: String = "고객 추가"
override val add_expense: String = "비용 추가"
override val add_fields_label: String = "추가:"
override val add_invoice_action: String = "만들기"
override val add_item_action: String = "아이템 추가"
override val add_item_button: String = "아이템 추가"
override val add_signature_action: String = "서명 추가"
override val add_tax_action: String = "세금 추가"
override val address_fields: String = "주소"
override val address_line_hint: String = "주소 입력란"
override val advanced: String = "고급"
override val agree_marketing: String = "직접 마케팅 커뮤니케이션 수신에 동의합니다."
override val all_notifications: String = "모든 알림"
override val amortization: String = "상각"
override val amount: String = "금액"
override val asset: String = "자산"
override val attach_file: String = "파일 첨부"
override val attachment: String = "첨부 파일"
override val auth_error: String = "작업이 실패했습니다. 다시 시도하십시오."
override val available_for_paid: String = "클라우드 동기화는 유료 구독에서만 사용할 수 있습니다."
override val backups_option: String = "백업"
override val balance: String = "잔액"
override val bank_account: String = "은행 계좌"
override val bill_number: String = "청구서 번호"
override val cancel: String = "취소하기"
override val cancel_trial: String = "언제든 취소 가능"
override val capital: String = "자본"
override val cash: String = "현금"
override val categories: String = "카테고리"
override val category: String = "카테고리"
override val category_add: String = "카테고리 추가"
override val category_delete: String = "카테고리 삭제"
override val category_name: String = "카테고리 이름"
override val change_language: Formattable = Formattable { (language) -> "인보이스 언어: ${language}" }
override val change_language_label: String = "인보이스 언어"
override val change_template: String = "템플릿 변경"
override val city_hint: String = "시티"
override val clear: String = "명확한"
override val client: String = "클라이언트"
override val client_actions: String = "클라이언트 액션"
override val client_deleted_message: String = "클라이언트가 삭제됨"
override val clients_error_required_fields: String = "하나 이상의 값이 필요합니다."
override val clients_option: String = "고객사"
override val close: String = "닫으세요"
override val cloud_sync: String = "클라우드 싱크"
override val coming_soon: String = "출시 예정!"
override val company_logo: String = "회사 로고"
override val company_name_hint: String = "회사소개"
override val confirm_password_label: String = "비밀번호 확인"
override val confirmation_title: String = "확실한가요?"
override val convert_to_invoice: String = "인보이스로 변환"
override val country_hint: String = "국가"
override val create_client: String = "클라이언트 생성"
override val create_company: String = "회사 만들기"
override val create_document: String = "문서 만들기"
override val create_estimate: String = "추정치"
override val create_invoice: String = "인보이스"
override val create_item: String = "아이템 생성"
override val create_new_client: String = "새 클라이언트 생성"
override val create_new_item: String = "새 항목 만들기"
override val create_new_tax: String = "새 세금 생성"
override val create_signature: String = "시그니처"
override val created: String = "생성됨"
override val credit: String = "크레딧"
override val customer: String = "고객"
override val date: String = "날짜"
override val debit: String = "직불"
override val debt: String = "빚"
override val default_currency: Formattable = Formattable { (currency) -> "기본 통화: ${currency}" }
override val default_currency_label: String = "기본 통화"
override val delete: String = "삭제"
override val delete_account: String = "계정 삭제"
override val delete_confirmation_message: String = "모든 데이터가 손실됩니다."
override val delete_item_title: String = "항목을 삭제하시겠습니까?"
override val delete_signature: String = "서명 삭제"
override val depreciation: String = "감가상각"
override val description: String = "설명"
override val description_hint: String = "설명"
override val detailed_report: String = "상세 보고서"
override val disabled: String = "사용 안함"
override val discount: String = "할인"
override val discount_hint: String = "할인"
override val display_af: String = "아프리칸스어"
override val display_am: String = "암하라어"
override val display_ar: String = "아랍어"
override val display_as: String = "아삼어"
override val display_az: String = "아제르바이잔어"
override val display_be: String = "벨라루스어"
override val display_bg: String = "불가리아어"
override val display_bn: String = "벵골어"
override val display_bs: String = "보스니아어"
override val display_ca: String = "카탈로니아어"
override val display_cs: String = "체코어"
override val display_da: String = "덴마크어"
override val display_de: String = "독일어"
override val display_el: String = "그리스어"
override val display_en: String = "영어"
override val display_es: String = "스페인어"
override val display_es_MX: String = "스페인어(멕시코)"
override val display_et: String = "에스토니아어"
override val display_eu: String = "바스크어"
override val display_fa: String = "페르시아어"
override val display_fi: String = "핀란드어"
override val display_fil: String = "필리핀어"
override val display_fr: String = "프랑스어"
override val display_fr_CA: String = "캐나다 프랑스어"
override val display_gl: String = "갈리시아어"
override val display_gu: String = "구자라트어"
override val display_he: String = "히브리어"
override val display_hi: String = "힌디어"
override val display_hr: String = "크로아티아어"
override val display_hu: String = "헝가리어"
override val display_hy: String = "아르메니아어"
override val display_id: String = "인도네시아어"
override val display_is: String = "아이슬란드어"
override val display_it: String = "이탈리아어"
override val display_ja: String = "일본어"
override val display_ka: String = "조지아어"
override val display_kk: String = "카자흐어"
override val display_km: String = "중부 크메르어"
override val display_kn: String = "카나다어"
override val display_ko: String = "한국어"
override val display_ky: String = "키르기스어"
override val display_lo: String = "라오스어"
override val display_lt: String = "리투아니아어"
override val display_lv: String = "라트비아어"
override val display_mk: String = "마케도니아어"
override val display_ml: String = "말라얄람어"
override val display_mn: String = "몽고어"
override val display_mr: String = "마라티어"
override val display_ms: String = "말레이어"
override val display_my: String = "버마어"
override val display_nb: String = "노르웨이 보크몰어"
override val display_ne: String = "네팔어"
override val display_nl: String = "네덜란드어"
override val display_or: String = "오리야어"
override val display_pa: String = "펀자브어"
override val display_pl: String = "폴란드어"
override val display_pt: String = "포르투갈어"
override val display_pt_BR: String = "포르투갈어(브라질)"
override val display_pt_PT: String = "유럽 포르투갈어"
override val display_ro: String = "루마니아어"
override val display_ru: String = "러시아어"
override val display_si: String = "신할라어"
override val display_sk: String = "슬로바키아어"
override val display_sl: String = "슬로베니아어"
override val display_sq: String = "알바니아어"
override val display_sr: String = "세르비아어"
override val display_sv: String = "스웨덴어"
override val display_sw: String = "스와힐리어"
override val display_ta: String = "타밀어"
override val display_te: String = "텔루구어"
override val display_th: String = "태국어"
override val display_tr: String = "터키어"
override val display_uk: String = "우크라이나어"
override val display_ur: String = "우르두어"
override val display_uz: String = "우즈베크어"
override val display_vi: String = "베트남어"
override val display_zh_Hant_TW: String = "중국어 (대만, 번체)"
override val display_zu: String = "줄루어"
override val document_estimate: String = "추정치"
override val document_invoice: String = "인보이스"
override val document_quote: String = "견적"
override val done: String = "끝난"
override val download_app: String = "앱 다운로드"
override val download_invoice: String = "인보이스 다운로드"
override val download_pdf: String = "PDF 다운로드"
override val draw_signature: String = "서명 그리기"
override val due: Formattable = Formattable { (date) -> "만기 ${date}" }
override val due_date: String = "마감일"
override val due_day: String = "하루 내로"
override val due_label: String = "만기"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} 마감일은 ${dueWhen} 입니다!" }
override val due_today: String = "오늘"
override val due_week: String = "일주일 내로"
override val duplicate: String = "복사"
override val duration_day: String = "일"
override val duration_month: String = "달"
override val duration_week: String = "주"
override val duration_year: String = "년"
override val email: String = "이메일"
override val email_blank_error: String = "이메일이 필요합니다."
override val email_error: String = "잘못된 이메일 주소입니다."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "거래해주셔서 감사합니다. 전체 ${documentType} 문서를 보려면 끝에 있는 버튼을 클릭하세요. ${dueDate} 까지 유효합니다." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "새 ${documentType} ${invoiceNumber}을(를) 받았습니다!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "청구서 ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "${clientName} 님" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "비즈니스 해주셔서 감사합니다.전체 ${documentType}} 문서를 보려면 이 글자 끝에 있는 버튼을 클릭하십시오. ${dueDate} 까지 잔액을 지불해 주십시오." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "새 ${documentType} ${invoiceNumber}을(를) 받았습니다!" }
override val email_reset_success_message: String = "비밀번호 재설정 이메일이 발송되었습니다. 받은 편지함을 확인하십시오."
override val empty_clients_list_message: String = "추적할 클라이언트가 없습니다.새 클라이언트를 추가합니다."
override val empty_estimate_list_message: String = "첫 번째 견적 작성"
override val empty_expenses: String = "추적할 비용이 없습니다. 새 비용을 추가합니다."
override val empty_invoice_list_message: String = "첫 인보이스 만들기"
override val empty_items_list_message: String = "아이템이 없습니다. 새 아이템을 추가하세요!"
override val empty_signatures_list_message: String = "서명이 없습니다.하나 만들어 보세요!"
override val empty_taxes_list_message: String = "세금 규정이 없습니다.새 세금 규칙을 추가합니다."
override val empty_templates_list_message: String = "템플릿이 제공되지 않습니다."
override val enabled: String = "활성화됨"
override val entry: String = "엔트리"
override val equity: String = "지분"
override val estimate_number: String = "추정치 #"
override val estimates: String = "추정치"
override val estimates_report: String = "추정치"
override val expense_bank_fees: String = "은행 수수료"
override val expense_benefits: String = "혜택"
override val expense_communication: String = "커뮤니케이션"
override val expense_debt: String = "부채"
override val expense_deleted: String = "비용 삭제됨"
override val expense_depreciation: String = "감가상각"
override val expense_gifts: String = "선물"
override val expense_insurance: String = "보험"
override val expense_inventory: String = "인벤토리"
override val expense_legal: String = "법률"
override val expense_maintenance: String = "유지 관리"
override val expense_marketing: String = "마케팅"
override val expense_meals: String = "식사"
override val expense_office_supplies: String = "사무용품"
override val expense_rent: String = "임대료"
override val expense_salaries: String = "급여"
override val expense_shopping: String = "쇼핑"
override val expense_software: String = "소프트웨어"
override val expense_status_available: String = "사용 가능"
override val expense_status_processing: String = "처리"
override val expense_tax: String = "세금"
override val expense_training: String = "교육"
override val expense_transport: String = "운송"
override val expense_travel: String = "여행"
override val expense_utility: String = "유틸리티"
override val expenses: String = "비용"
override val feedback_option: String = "피드백"
override val filter: String = "필터"
override val first_name_hint: String = "이름 (이름)"
override val forgot_password: String = "비밀번호 재설정"
override val forgot_password_label: String = "비밀번호 분실"
override val from: String = "부터"
override val general_error: String = "오류가 발생했습니다. 나중에 다시 시도해 주세요."
override val general_ledger: String = "총계정원장"
override val go_back_label: String = "뒤로 가기"
override val group_by: String = "그룹 기준:"
override val image_view: String = "이미지"
override val install: String = "설치"
override val invalid_credentials_error: String = "잘못된 자격 증명."
override val invalid_email_error: String = "잘못된 이메일입니다."
override val invalid_user_error: String = "사용자 자격 증명이 잘못되었습니다."
override val invoice_deleted_message: String = "청구서 삭제됨"
override val invoice_menu: String = "인보이스 메뉴"
override val invoice_number: String = "인보이스 번호"
override val invoices: String = "인보이스"
override val items_option: String = "아이템"
override val keep: String = "유지"
override val language_selector: Formattable = Formattable { (selected) -> "언어 ${selected}" }
override val last_name_hint: String = "성 (성)"
override val ledger: String = "원장"
override val legal: String = "법률"
override val legal_error: String = "서비스 약관 및 개인 정보 보호 정책에 동의해야 함"
override val liabilities: String = "부채"
override val loading: String = "로딩 중"
override val login_required_error: String = "로그인이 필요합니다."
override val logo_view: String = "로고"
override val logout: String = "로그 아웃"
override val logout_prompt: String = "데이터가 클라우드와 동기화되지 않습니다.로그아웃 시 송장, 견적, 고객 및 기타 정보를 삭제하시겠습니까?"
override val main_screen: String = "메인 화면"
override val mark_paid: String = "지불됨으로 표시"
override val message: String = "메시지"
override val missing_sender_msg: String = "발신자 정보가 없는 문서는 유효하지 않을 수 있습니다."
override val missing_sender_title: String = "발신자 정보 누락"
override val month_april: String = "4월"
override val month_april_short: String = "4월"
override val month_august: String = "8월"
override val month_august_short: String = "8월"
override val month_december: String = "12월"
override val month_december_short: String = "12월"
override val month_february: String = "2월"
override val month_february_short: String = "2월"
override val month_january: String = "1월"
override val month_january_short: String = "1월"
override val month_july: String = "7월"
override val month_july_short: String = "7월"
override val month_june: String = "6월"
override val month_june_short: String = "6월"
override val month_march: String = "3월"
override val month_march_short: String = "3월"
override val month_may: String = "5월"
override val month_may_short: String = "5월"
override val month_november: String = "11월"
override val month_november_short: String = "11월"
override val month_october: String = "10월"
override val month_october_short: String = "10월"
override val month_september: String = "9월"
override val month_september_short: String = "9월"
override val multifactor_error: String = "확인하지 못했습니다."
override val new: String = "신규"
override val news: String = "뉴스"
override val next: String = "다음"
override val no: String = "아니오"
override val no_data_available: String = "사용 가능한 데이터 없음"
override val no_search_results: String = "죄송합니다. 결과를 찾을 수 없습니다."
override val none: String = "없음"
override val note_fields: String = "참고 사항"
override val note_hint: String = "노트"
override val notifications_option: String = "알림"
override val ok: String = "OK"
override val onboarding: String = "온보딩"
override val onboarding_aboutus_message: String = "DingVoice의 필수 부분은 영원히 무료입니다. 우리의 임무는 귀하의 비즈니스 성장을 돕고 귀하의 피드백을 공유하여 개선할 수 있도록 돕는 것입니다."
override val onboarding_aboutus_title: String = "도와드리겠습니다."
override val onboarding_paid_1: String = "전문가 수준의 템플릿에 액세스"
override val onboarding_paid_2: String = "재무 상태를 자세히 살펴보세요."
override val onboarding_paid_3: String = "문제를 빠르게 해결"
override val onboarding_paid_4: String = "문서를 안전하게 보관하십시오"
override val onboarding_paid_5: String = "인보이스 상태 변경에 대한 최신 정보 유지"
override val onboarding_paid_title: String = "더 빠른 성장..."
override val onboarding_paywall_message: String = "지금 최고의 가격으로 모든 프리미엄 기능을 즐기세요."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} 기간은 ${currencySymbol}${introAmount} 금액이므로 ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} / ${duration}만" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod}는 무료, ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} 동안 무료, ${introductoryDuration} ${introPeriod} 기간 동안${currencySymbol}${introductoryAmount} 금액 및 ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "자동 갱신 가능. 언제든지 취소하세요."
override val onboarding_paywall_restore: String = "구매 복원"
override val onboarding_paywall_restore_failure: String = "구매 복원에 실패했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요."
override val onboarding_paywall_restore_success: String = "복원 성공!"
override val onboarding_paywall_title: String = "위대함을 달성하십시오!"
override val onboarding_welcome_message: String = "인보이스 및 견적을 관리할 수 있는 완벽한 도구 모음입니다."
override val onboarding_welcome_title: String = "DingVoice에 오신 것을 환영합니다."
override val other_state: String = "기타"
override val overdue_state: String = "연체"
override val paid_state: String = "유료"
override val password: String = "비밀번호"
override val password_blank_error: String = "비밀번호가 필요합니다."
override val password_mismatch_error: String = "비밀번호가 일치하지 않습니다."
override val pay: String = "결제"
override val payment_instructions_label: String = "결제 지침"
override val payment_reminders: String = "결제 미리 알림"
override val phone_number_fields: String = "전화번호"
override val phone_number_hint: String = "전화번호"
override val postcode_hint: String = "우편 번호"
override val preview: String = "시사"
override val preview_menu_hint: String = "미리 보기 메뉴"
override val price: String = "가격"
override val privacy_policy: String = "개인 정보 정책"
override val profile: String = "윤곽"
override val profitable_client: String = "가장 수익성 높은 고객은..."
override val profits_report: String = "수익"
override val promo_content: String = "프로모션 콘텐츠"
override val quantity_hint: String = "수량"
override val quantity_label_hint: String = "수량 라벨"
override val query_info: String = "쿼리를 사용하면 항목 및 기타 세금 규칙을 모두 입력으로 참조하는 사용자 지정 계산을 작성할 수 있습니다.@ 기호 및 항목 또는 세금 설명을 추가하여 다른 항목 +, -, *,/,% - 수학 연산자에 연결합니다."
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber}이(가) 클라이언트에 의해 수신되었습니다." }
override val received_state: String = "수령"
override val registration_code_fields: String = "등록 코드"
override val reminder: String = "미리 알림"
override val reports: String = "보고서"
override val reports_not_available: String = "사용 가능한 보고서가 없습니다, 나중에 다시 방문하세요."
override val reveal_password: String = "비밀번호 공개"
override val revenue: String = "수익"
override val sales_tax: String = "부가가치세"
override val sales_tax_number: String = "부가세 번호"
override val save: String = "저장"
override val scan_document: String = "문서 스캔"
override val search: String = "검색"
override val select: String = "선택"
override val select_icon: String = "아이콘 선택"
override val select_signature: String = "서명 선택"
override val select_template: String = "템플릿 선택"
override val send: String = "보내기"
override val sent_state: String = "보냄"
override val setup_sender: String = "발신자 설정"
override val share_option: String = "공유하기"
override val ship_to: String = "배송지"
override val sign_in: String = "로그인"
override val sign_in_apple: String = "Apple로 로그인하기"
override val sign_in_email: String = "이메일로 로그인"
override val sign_in_facebook: String = "페이스북으로 로그인"
override val sign_in_google: String = "구글로 로그인하기"
override val sign_up: String = "회원 가입"
override val sign_up_label: String = "계정이 없으신가요?"
override val sign_up_link_label: String = "가입!"
override val signatures_option: String = "시그니처"
override val skip: String = "건너 뛰기"
override val sold_items_report: String = "판매된 아이템"
override val sort_by: String = "정렬 기준:"
override val status_update: String = "문서 상태 업데이트"
override val sub_annual: String = "연간"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/년" }
override val sub_backups: String = "클라우드 백업"
override val sub_insights: String = "인사이트"
override val sub_month: String = "월간 간행물"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/월" }
override val sub_notifications: String = "알림"
override val sub_popup_message: String = "곧 구독이 시작됩니다. 잠시 후 여기를 다시 확인하세요."
override val sub_support: String = "지원"
override val sub_templates: String = "템플릿"
override val subscribe: String = "구독하기"
override val subscription_header: String = "다음을 통해 비즈니스 성장:"
override val subscription_option: String = "구독"
override val subscriptions_load_fail: String = "죄송합니다. 지금은 구독을 검색할 수 없습니다. 나중에 다시 시도하거나 지원 팀에 도움을 요청하십시오."
override val subtotal: Formattable = Formattable { (sum) -> "소계: ${sum}" }
override val subtotal_label: String = "소계"
override val sum: String = "합계"
override val support_option: String = "지원"
override val switch_reports: String = "보고서 전환"
override val tax: String = "세금"
override val tax_deleted_message: String = "세금 삭제"
override val tax_description_warning: String = "사용된 경우 인보이스에 표시됩니다."
override val tax_input_flat: String = "플랫"
override val tax_input_percent: String = "퍼센트"
override val tax_number_fields: String = "세금 번호"
override val tax_number_hint: String = "세금 번호"
override val tax_query_info_description: String = "쿼리 정보"
override val tax_report: String = "세금"
override val tax_rules_option: String = "세금 규칙"
override val taxed_clients_report: String = "과세 고객"
override val template: String = "템플릿"
override val template_selector_info: String = "스와이프하여 기본 템플릿 변경"
override val template_unlock_promo: String = "Premium으로 더 많은 템플릿에 액세스"
override val templates_option: String = "템플릿"
override val terms_of_service: String = "서비스 약관"
override val thank_you: String = "감사합니다"
override val to: String = "에"
override val total: Formattable = Formattable { (sum) -> "총 금액: ${sum}" }
override val total_label: String = "총계"
override val undo_action: String = "실행 취소"
override val unit: String = "유닛"
override val unit_cost_hint: String = "단가"
override val unknown_client: String = "알 수 없는 클라이언트"
override val unlock: String = "잠금 해제"
override val unnamed_item: String = "명명되지 않은 아이템"
override val upgrade: String = "업그레이드"
override val user_collision_error: String = "계정이 이미 존재합니다."
override val valid_until: String = "까지 유효"
override val warning: String = "경고!"
override val yes: String = "네"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} 및 ${privacyPolicy}에 동의합니다." },
	"account" to "계정",
	"accounting" to "회계",
	"accruals" to "발생",
	"action_code_error" to "잘못된 코드입니다.",
	"add_client_action" to "클라이언트 추가",
	"add_customer" to "고객 추가",
	"add_expense" to "비용 추가",
	"add_fields_label" to "추가:",
	"add_invoice_action" to "만들기",
	"add_item_action" to "아이템 추가",
	"add_item_button" to "아이템 추가",
	"add_signature_action" to "서명 추가",
	"add_tax_action" to "세금 추가",
	"address_fields" to "주소",
	"address_line_hint" to "주소 입력란",
	"advanced" to "고급",
	"agree_marketing" to "직접 마케팅 커뮤니케이션 수신에 동의합니다.",
	"all_notifications" to "모든 알림",
	"amortization" to "상각",
	"amount" to "금액",
	"asset" to "자산",
	"attach_file" to "파일 첨부",
	"attachment" to "첨부 파일",
	"auth_error" to "작업이 실패했습니다. 다시 시도하십시오.",
	"available_for_paid" to "클라우드 동기화는 유료 구독에서만 사용할 수 있습니다.",
	"backups_option" to "백업",
	"balance" to "잔액",
	"bank_account" to "은행 계좌",
	"bill_number" to "청구서 번호",
	"cancel" to "취소하기",
	"cancel_trial" to "언제든 취소 가능",
	"capital" to "자본",
	"cash" to "현금",
	"categories" to "카테고리",
	"category" to "카테고리",
	"category_add" to "카테고리 추가",
	"category_delete" to "카테고리 삭제",
	"category_name" to "카테고리 이름",
	"change_language" to Formattable { (language) -> "인보이스 언어: ${language}" },
	"change_language_label" to "인보이스 언어",
	"change_template" to "템플릿 변경",
	"city_hint" to "시티",
	"clear" to "명확한",
	"client" to "클라이언트",
	"client_actions" to "클라이언트 액션",
	"client_deleted_message" to "클라이언트가 삭제됨",
	"clients_error_required_fields" to "하나 이상의 값이 필요합니다.",
	"clients_option" to "고객사",
	"close" to "닫으세요",
	"cloud_sync" to "클라우드 싱크",
	"coming_soon" to "출시 예정!",
	"company_logo" to "회사 로고",
	"company_name_hint" to "회사소개",
	"confirm_password_label" to "비밀번호 확인",
	"confirmation_title" to "확실한가요?",
	"convert_to_invoice" to "인보이스로 변환",
	"country_hint" to "국가",
	"create_client" to "클라이언트 생성",
	"create_company" to "회사 만들기",
	"create_document" to "문서 만들기",
	"create_estimate" to "추정치",
	"create_invoice" to "인보이스",
	"create_item" to "아이템 생성",
	"create_new_client" to "새 클라이언트 생성",
	"create_new_item" to "새 항목 만들기",
	"create_new_tax" to "새 세금 생성",
	"create_signature" to "시그니처",
	"created" to "생성됨",
	"credit" to "크레딧",
	"customer" to "고객",
	"date" to "날짜",
	"debit" to "직불",
	"debt" to "빚",
	"default_currency" to Formattable { (currency) -> "기본 통화: ${currency}" },
	"default_currency_label" to "기본 통화",
	"delete" to "삭제",
	"delete_account" to "계정 삭제",
	"delete_confirmation_message" to "모든 데이터가 손실됩니다.",
	"delete_item_title" to "항목을 삭제하시겠습니까?",
	"delete_signature" to "서명 삭제",
	"depreciation" to "감가상각",
	"description" to "설명",
	"description_hint" to "설명",
	"detailed_report" to "상세 보고서",
	"disabled" to "사용 안함",
	"discount" to "할인",
	"discount_hint" to "할인",
	"display_af" to "아프리칸스어",
	"display_am" to "암하라어",
	"display_ar" to "아랍어",
	"display_as" to "아삼어",
	"display_az" to "아제르바이잔어",
	"display_be" to "벨라루스어",
	"display_bg" to "불가리아어",
	"display_bn" to "벵골어",
	"display_bs" to "보스니아어",
	"display_ca" to "카탈로니아어",
	"display_cs" to "체코어",
	"display_da" to "덴마크어",
	"display_de" to "독일어",
	"display_el" to "그리스어",
	"display_en" to "영어",
	"display_es" to "스페인어",
	"display_es_MX" to "스페인어(멕시코)",
	"display_et" to "에스토니아어",
	"display_eu" to "바스크어",
	"display_fa" to "페르시아어",
	"display_fi" to "핀란드어",
	"display_fil" to "필리핀어",
	"display_fr" to "프랑스어",
	"display_fr_CA" to "캐나다 프랑스어",
	"display_gl" to "갈리시아어",
	"display_gu" to "구자라트어",
	"display_he" to "히브리어",
	"display_hi" to "힌디어",
	"display_hr" to "크로아티아어",
	"display_hu" to "헝가리어",
	"display_hy" to "아르메니아어",
	"display_id" to "인도네시아어",
	"display_is" to "아이슬란드어",
	"display_it" to "이탈리아어",
	"display_ja" to "일본어",
	"display_ka" to "조지아어",
	"display_kk" to "카자흐어",
	"display_km" to "중부 크메르어",
	"display_kn" to "카나다어",
	"display_ko" to "한국어",
	"display_ky" to "키르기스어",
	"display_lo" to "라오스어",
	"display_lt" to "리투아니아어",
	"display_lv" to "라트비아어",
	"display_mk" to "마케도니아어",
	"display_ml" to "말라얄람어",
	"display_mn" to "몽고어",
	"display_mr" to "마라티어",
	"display_ms" to "말레이어",
	"display_my" to "버마어",
	"display_nb" to "노르웨이 보크몰어",
	"display_ne" to "네팔어",
	"display_nl" to "네덜란드어",
	"display_or" to "오리야어",
	"display_pa" to "펀자브어",
	"display_pl" to "폴란드어",
	"display_pt" to "포르투갈어",
	"display_pt_BR" to "포르투갈어(브라질)",
	"display_pt_PT" to "유럽 포르투갈어",
	"display_ro" to "루마니아어",
	"display_ru" to "러시아어",
	"display_si" to "신할라어",
	"display_sk" to "슬로바키아어",
	"display_sl" to "슬로베니아어",
	"display_sq" to "알바니아어",
	"display_sr" to "세르비아어",
	"display_sv" to "스웨덴어",
	"display_sw" to "스와힐리어",
	"display_ta" to "타밀어",
	"display_te" to "텔루구어",
	"display_th" to "태국어",
	"display_tr" to "터키어",
	"display_uk" to "우크라이나어",
	"display_ur" to "우르두어",
	"display_uz" to "우즈베크어",
	"display_vi" to "베트남어",
	"display_zh_Hant_TW" to "중국어 (대만, 번체)",
	"display_zu" to "줄루어",
	"document_estimate" to "추정치",
	"document_invoice" to "인보이스",
	"document_quote" to "견적",
	"done" to "끝난",
	"download_app" to "앱 다운로드",
	"download_invoice" to "인보이스 다운로드",
	"download_pdf" to "PDF 다운로드",
	"draw_signature" to "서명 그리기",
	"due" to Formattable { (date) -> "만기 ${date}" },
	"due_date" to "마감일",
	"due_day" to "하루 내로",
	"due_label" to "만기",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} 마감일은 ${dueWhen} 입니다!" },
	"due_today" to "오늘",
	"due_week" to "일주일 내로",
	"duplicate" to "복사",
	"duration_day" to "일",
	"duration_month" to "달",
	"duration_week" to "주",
	"duration_year" to "년",
	"email" to "이메일",
	"email_blank_error" to "이메일이 필요합니다.",
	"email_error" to "잘못된 이메일 주소입니다.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "거래해주셔서 감사합니다. 전체 ${documentType} 문서를 보려면 끝에 있는 버튼을 클릭하세요. ${dueDate} 까지 유효합니다." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "새 ${documentType} ${invoiceNumber}을(를) 받았습니다!" },
	"email_invoice_cta" to Formattable { (documentType) -> "청구서 ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "${clientName} 님" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "비즈니스 해주셔서 감사합니다.전체 ${documentType}} 문서를 보려면 이 글자 끝에 있는 버튼을 클릭하십시오. ${dueDate} 까지 잔액을 지불해 주십시오." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "새 ${documentType} ${invoiceNumber}을(를) 받았습니다!" },
	"email_reset_success_message" to "비밀번호 재설정 이메일이 발송되었습니다. 받은 편지함을 확인하십시오.",
	"empty_clients_list_message" to "추적할 클라이언트가 없습니다.새 클라이언트를 추가합니다.",
	"empty_estimate_list_message" to "첫 번째 견적 작성",
	"empty_expenses" to "추적할 비용이 없습니다. 새 비용을 추가합니다.",
	"empty_invoice_list_message" to "첫 인보이스 만들기",
	"empty_items_list_message" to "아이템이 없습니다. 새 아이템을 추가하세요!",
	"empty_signatures_list_message" to "서명이 없습니다.하나 만들어 보세요!",
	"empty_taxes_list_message" to "세금 규정이 없습니다.새 세금 규칙을 추가합니다.",
	"empty_templates_list_message" to "템플릿이 제공되지 않습니다.",
	"enabled" to "활성화됨",
	"entry" to "엔트리",
	"equity" to "지분",
	"estimate_number" to "추정치 #",
	"estimates" to "추정치",
	"estimates_report" to "추정치",
	"expense_bank_fees" to "은행 수수료",
	"expense_benefits" to "혜택",
	"expense_communication" to "커뮤니케이션",
	"expense_debt" to "부채",
	"expense_deleted" to "비용 삭제됨",
	"expense_depreciation" to "감가상각",
	"expense_gifts" to "선물",
	"expense_insurance" to "보험",
	"expense_inventory" to "인벤토리",
	"expense_legal" to "법률",
	"expense_maintenance" to "유지 관리",
	"expense_marketing" to "마케팅",
	"expense_meals" to "식사",
	"expense_office_supplies" to "사무용품",
	"expense_rent" to "임대료",
	"expense_salaries" to "급여",
	"expense_shopping" to "쇼핑",
	"expense_software" to "소프트웨어",
	"expense_status_available" to "사용 가능",
	"expense_status_processing" to "처리",
	"expense_tax" to "세금",
	"expense_training" to "교육",
	"expense_transport" to "운송",
	"expense_travel" to "여행",
	"expense_utility" to "유틸리티",
	"expenses" to "비용",
	"feedback_option" to "피드백",
	"filter" to "필터",
	"first_name_hint" to "이름 (이름)",
	"forgot_password" to "비밀번호 재설정",
	"forgot_password_label" to "비밀번호 분실",
	"from" to "부터",
	"general_error" to "오류가 발생했습니다. 나중에 다시 시도해 주세요.",
	"general_ledger" to "총계정원장",
	"go_back_label" to "뒤로 가기",
	"group_by" to "그룹 기준:",
	"image_view" to "이미지",
	"install" to "설치",
	"invalid_credentials_error" to "잘못된 자격 증명.",
	"invalid_email_error" to "잘못된 이메일입니다.",
	"invalid_user_error" to "사용자 자격 증명이 잘못되었습니다.",
	"invoice_deleted_message" to "청구서 삭제됨",
	"invoice_menu" to "인보이스 메뉴",
	"invoice_number" to "인보이스 번호",
	"invoices" to "인보이스",
	"items_option" to "아이템",
	"keep" to "유지",
	"language_selector" to Formattable { (selected) -> "언어 ${selected}" },
	"last_name_hint" to "성 (성)",
	"ledger" to "원장",
	"legal" to "법률",
	"legal_error" to "서비스 약관 및 개인 정보 보호 정책에 동의해야 함",
	"liabilities" to "부채",
	"loading" to "로딩 중",
	"login_required_error" to "로그인이 필요합니다.",
	"logo_view" to "로고",
	"logout" to "로그 아웃",
	"logout_prompt" to "데이터가 클라우드와 동기화되지 않습니다.로그아웃 시 송장, 견적, 고객 및 기타 정보를 삭제하시겠습니까?",
	"main_screen" to "메인 화면",
	"mark_paid" to "지불됨으로 표시",
	"message" to "메시지",
	"missing_sender_msg" to "발신자 정보가 없는 문서는 유효하지 않을 수 있습니다.",
	"missing_sender_title" to "발신자 정보 누락",
	"month_april" to "4월",
	"month_april_short" to "4월",
	"month_august" to "8월",
	"month_august_short" to "8월",
	"month_december" to "12월",
	"month_december_short" to "12월",
	"month_february" to "2월",
	"month_february_short" to "2월",
	"month_january" to "1월",
	"month_january_short" to "1월",
	"month_july" to "7월",
	"month_july_short" to "7월",
	"month_june" to "6월",
	"month_june_short" to "6월",
	"month_march" to "3월",
	"month_march_short" to "3월",
	"month_may" to "5월",
	"month_may_short" to "5월",
	"month_november" to "11월",
	"month_november_short" to "11월",
	"month_october" to "10월",
	"month_october_short" to "10월",
	"month_september" to "9월",
	"month_september_short" to "9월",
	"multifactor_error" to "확인하지 못했습니다.",
	"new" to "신규",
	"news" to "뉴스",
	"next" to "다음",
	"no" to "아니오",
	"no_data_available" to "사용 가능한 데이터 없음",
	"no_search_results" to "죄송합니다. 결과를 찾을 수 없습니다.",
	"none" to "없음",
	"note_fields" to "참고 사항",
	"note_hint" to "노트",
	"notifications_option" to "알림",
	"ok" to "OK",
	"onboarding" to "온보딩",
	"onboarding_aboutus_message" to "DingVoice의 필수 부분은 영원히 무료입니다. 우리의 임무는 귀하의 비즈니스 성장을 돕고 귀하의 피드백을 공유하여 개선할 수 있도록 돕는 것입니다.",
	"onboarding_aboutus_title" to "도와드리겠습니다.",
	"onboarding_paid_1" to "전문가 수준의 템플릿에 액세스",
	"onboarding_paid_2" to "재무 상태를 자세히 살펴보세요.",
	"onboarding_paid_3" to "문제를 빠르게 해결",
	"onboarding_paid_4" to "문서를 안전하게 보관하십시오",
	"onboarding_paid_5" to "인보이스 상태 변경에 대한 최신 정보 유지",
	"onboarding_paid_title" to "더 빠른 성장...",
	"onboarding_paywall_message" to "지금 최고의 가격으로 모든 프리미엄 기능을 즐기세요.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} 기간은 ${currencySymbol}${introAmount} 금액이므로 ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} / ${duration}만" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod}는 무료, ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} 동안 무료, ${introductoryDuration} ${introPeriod} 기간 동안${currencySymbol}${introductoryAmount} 금액 및 ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "자동 갱신 가능. 언제든지 취소하세요.",
	"onboarding_paywall_restore" to "구매 복원",
	"onboarding_paywall_restore_failure" to "구매 복원에 실패했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.",
	"onboarding_paywall_restore_success" to "복원 성공!",
	"onboarding_paywall_title" to "위대함을 달성하십시오!",
	"onboarding_welcome_message" to "인보이스 및 견적을 관리할 수 있는 완벽한 도구 모음입니다.",
	"onboarding_welcome_title" to "DingVoice에 오신 것을 환영합니다.",
	"other_state" to "기타",
	"overdue_state" to "연체",
	"paid_state" to "유료",
	"password" to "비밀번호",
	"password_blank_error" to "비밀번호가 필요합니다.",
	"password_mismatch_error" to "비밀번호가 일치하지 않습니다.",
	"pay" to "결제",
	"payment_instructions_label" to "결제 지침",
	"payment_reminders" to "결제 미리 알림",
	"phone_number_fields" to "전화번호",
	"phone_number_hint" to "전화번호",
	"postcode_hint" to "우편 번호",
	"preview" to "시사",
	"preview_menu_hint" to "미리 보기 메뉴",
	"price" to "가격",
	"privacy_policy" to "개인 정보 정책",
	"profile" to "윤곽",
	"profitable_client" to "가장 수익성 높은 고객은...",
	"profits_report" to "수익",
	"promo_content" to "프로모션 콘텐츠",
	"quantity_hint" to "수량",
	"quantity_label_hint" to "수량 라벨",
	"query_info" to "쿼리를 사용하면 항목 및 기타 세금 규칙을 모두 입력으로 참조하는 사용자 지정 계산을 작성할 수 있습니다.@ 기호 및 항목 또는 세금 설명을 추가하여 다른 항목 +, -, *,/,% - 수학 연산자에 연결합니다.",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber}이(가) 클라이언트에 의해 수신되었습니다." },
	"received_state" to "수령",
	"registration_code_fields" to "등록 코드",
	"reminder" to "미리 알림",
	"reports" to "보고서",
	"reports_not_available" to "사용 가능한 보고서가 없습니다, 나중에 다시 방문하세요.",
	"reveal_password" to "비밀번호 공개",
	"revenue" to "수익",
	"sales_tax" to "부가가치세",
	"sales_tax_number" to "부가세 번호",
	"save" to "저장",
	"scan_document" to "문서 스캔",
	"search" to "검색",
	"select" to "선택",
	"select_icon" to "아이콘 선택",
	"select_signature" to "서명 선택",
	"select_template" to "템플릿 선택",
	"send" to "보내기",
	"sent_state" to "보냄",
	"setup_sender" to "발신자 설정",
	"share_option" to "공유하기",
	"ship_to" to "배송지",
	"sign_in" to "로그인",
	"sign_in_apple" to "Apple로 로그인하기",
	"sign_in_email" to "이메일로 로그인",
	"sign_in_facebook" to "페이스북으로 로그인",
	"sign_in_google" to "구글로 로그인하기",
	"sign_up" to "회원 가입",
	"sign_up_label" to "계정이 없으신가요?",
	"sign_up_link_label" to "가입!",
	"signatures_option" to "시그니처",
	"skip" to "건너 뛰기",
	"sold_items_report" to "판매된 아이템",
	"sort_by" to "정렬 기준:",
	"status_update" to "문서 상태 업데이트",
	"sub_annual" to "연간",
	"sub_annual_price" to Formattable { (price) -> "${price}/년" },
	"sub_backups" to "클라우드 백업",
	"sub_insights" to "인사이트",
	"sub_month" to "월간 간행물",
	"sub_monthly_price" to Formattable { (price) -> "${price}/월" },
	"sub_notifications" to "알림",
	"sub_popup_message" to "곧 구독이 시작됩니다. 잠시 후 여기를 다시 확인하세요.",
	"sub_support" to "지원",
	"sub_templates" to "템플릿",
	"subscribe" to "구독하기",
	"subscription_header" to "다음을 통해 비즈니스 성장:",
	"subscription_option" to "구독",
	"subscriptions_load_fail" to "죄송합니다. 지금은 구독을 검색할 수 없습니다. 나중에 다시 시도하거나 지원 팀에 도움을 요청하십시오.",
	"subtotal" to Formattable { (sum) -> "소계: ${sum}" },
	"subtotal_label" to "소계",
	"sum" to "합계",
	"support_option" to "지원",
	"switch_reports" to "보고서 전환",
	"tax" to "세금",
	"tax_deleted_message" to "세금 삭제",
	"tax_description_warning" to "사용된 경우 인보이스에 표시됩니다.",
	"tax_input_flat" to "플랫",
	"tax_input_percent" to "퍼센트",
	"tax_number_fields" to "세금 번호",
	"tax_number_hint" to "세금 번호",
	"tax_query_info_description" to "쿼리 정보",
	"tax_report" to "세금",
	"tax_rules_option" to "세금 규칙",
	"taxed_clients_report" to "과세 고객",
	"template" to "템플릿",
	"template_selector_info" to "스와이프하여 기본 템플릿 변경",
	"template_unlock_promo" to "Premium으로 더 많은 템플릿에 액세스",
	"templates_option" to "템플릿",
	"terms_of_service" to "서비스 약관",
	"thank_you" to "감사합니다",
	"to" to "에",
	"total" to Formattable { (sum) -> "총 금액: ${sum}" },
	"total_label" to "총계",
	"undo_action" to "실행 취소",
	"unit" to "유닛",
	"unit_cost_hint" to "단가",
	"unknown_client" to "알 수 없는 클라이언트",
	"unlock" to "잠금 해제",
	"unnamed_item" to "명명되지 않은 아이템",
	"upgrade" to "업그레이드",
	"user_collision_error" to "계정이 이미 존재합니다.",
	"valid_until" to "까지 유효",
	"warning" to "경고!",
	"yes" to "네",
    )
    
}