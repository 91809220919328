// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Or  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} ଏବଂ ${privacyPolicy} ଗ୍ରହଣ କରନ୍ତୁ" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "ଅବୈଧ କୋଡ୍।"
override val add_client_action: String = "ଗ୍ରାହକ ଯୋଡନ୍ତୁ"
override val add_customer: String = "ଗ୍ରାହକ ଯୋଡନ୍ତୁ"
override val add_expense: String = "ଖର୍ଚ୍ଚ ଯୋଡନ୍ତୁ"
override val add_fields_label: String = "ଯୋଡନ୍ତୁ:"
override val add_invoice_action: String = "ସୃଷ୍ଟି କରନ୍ତୁ"
override val add_item_action: String = "ଆଇଟମ୍ ଯୋଡନ୍ତୁ"
override val add_item_button: String = "ଆଇଟମ୍ ଯୋଡନ୍ତୁ"
override val add_signature_action: String = "ଦସ୍ତଖତ ଯୋଡନ୍ତୁ"
override val add_tax_action: String = "ଟିକସ ଯୋଡନ୍ତୁ"
override val address_fields: String = "ଠିକଣା"
override val address_line_hint: String = "ଠିକଣା ରେଖା"
override val advanced: String = "ଉନ୍ନତ"
override val agree_marketing: String = "ମୁଁ ପ୍ରତ୍ୟକ୍ଷ ମାର୍କେଟିଂ ଯୋଗାଯୋଗ ପ୍ରାପ୍ତ କରିବାକୁ ରାଜି"
override val all_notifications: String = "ସମସ୍ତ ବିଜ୍ଞପ୍ତିଗୁଡିକ"
override val amortization: String = "amortization"
override val amount: String = "ପରିମାଣ"
override val asset: String = "asset"
override val attach_file: String = "ଫାଇଲ୍ ସଂଯୋଗ କରନ୍ତୁ"
override val attachment: String = "ସଂଯୋଗ[ସମ୍ପାଦନା]"
override val auth_error: String = "କ୍ରିୟା ବିଫଳ ହେଲା, ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ।"
override val available_for_paid: String = "କ୍ଲାଉଡ୍ ସିଙ୍କ୍ କେବଳ ଦେୟ ସଦସ୍ୟତା ଅଧୀନରେ ଉପଲବ୍ଧ।"
override val backups_option: String = "ବ୍ୟାକଅପ୍"
override val balance: String = "balance"
override val bank_account: String = "ବ୍ୟାଙ୍କ୍ ଆକାଉଣ୍ଟ"
override val bill_number: String = "ବିଲ୍ ନମ୍ବର"
override val cancel: String = "ବାତିଲ୍‌ କରନ୍ତୁ"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "ଶ୍ରେଣୀ[ସମ୍ପାଦନା]"
override val category: String = "ଶ୍ରେଣୀ[ସମ୍ପାଦନା]"
override val category_add: String = "ଶ୍ରେଣୀ ଯୋଡନ୍ତୁ"
override val category_delete: String = "ଶ୍ରେଣୀ ବିଲୋପ କରନ୍ତୁ"
override val category_name: String = "ଶ୍ରେଣୀ ନାମ[ସମ୍ପାଦନା]"
override val change_language: Formattable = Formattable { (language) -> "ଇନଭଏସ୍ ଭାଷା: ${language}" }
override val change_language_label: String = "ଇନଭଏସ୍ ଭାଷା"
override val change_template: String = "ଟେମ୍ପଲେଟ୍ ପରିବର୍ତ୍ତନ କରନ୍ତୁ"
override val city_hint: String = "ସହର"
override val clear: String = "ସ୍ପଷ୍ଟ"
override val client: String = "ଗ୍ରାହକ"
override val client_actions: String = "କ୍ଲାଏଣ୍ଟ କାର୍ଯ୍ୟ"
override val client_deleted_message: String = "କ୍ଲାଏଣ୍ଟ ବିଲୋପ ହୋଇଛି"
override val clients_error_required_fields: String = "ଅତି କମରେ ଗୋଟିଏ ମୂଲ୍ୟ ଆବଶ୍ୟକ।"
override val clients_option: String = "ଗ୍ରାହକ"
override val close: String = "ବନ୍ଦ"
override val cloud_sync: String = "କ୍ଲାଉଡ୍ ସିଙ୍କ୍"
override val coming_soon: String = "ଶୀଘ୍ର ଆସୁଅଛି!"
override val company_logo: String = "କମ୍ପାନୀ ଲୋଗୋ"
override val company_name_hint: String = "କମ୍ପାନୀ"
override val confirm_password_label: String = "ପାସୱାର୍ଡ୍ ନିଶ୍ଚିତ କରନ୍ତୁ"
override val confirmation_title: String = "କଣ ଆପଣ ସୁନିଶ୍ଚିତ ଅଛନ୍ତି?"
override val convert_to_invoice: String = "ଇନଭଏସ୍ ରେ ରୂପାନ୍ତରିତ କରନ୍ତୁ"
override val country_hint: String = "ଦେଶ"
override val create_client: String = "କ୍ଲାଏଣ୍ଟ ସୃଷ୍ଟି କରନ୍ତୁ"
override val create_company: String = "କମ୍ପାନୀ ସୃଷ୍ଟି କରନ୍ତୁ"
override val create_document: String = "ଡକ୍ୟୁମେଣ୍ଟ ସୃଷ୍ଟି କରନ୍ତୁ"
override val create_estimate: String = "ଆକଳନ"
override val create_invoice: String = "ଇନଭଏସ୍"
override val create_item: String = "ଆଇଟମ୍ ସୃଷ୍ଟି କରନ୍ତୁ"
override val create_new_client: String = "ନୂତନ ଗ୍ରାହକ ସୃଷ୍ଟି କରନ୍ତୁ"
override val create_new_item: String = "ନୂତନ ଆଇଟମ୍ ସୃଷ୍ଟି କରନ୍ତୁ"
override val create_new_tax: String = "ନୂତନ ଟିକସ ସୃଷ୍ଟି କରନ୍ତୁ"
override val create_signature: String = "ଦସ୍ତଖତ"
override val created: String = "ସୃଷ୍ଟି ହୋଇଛି"
override val credit: String = "credit"
override val customer: String = "ଗ୍ରାହକ"
override val date: String = "ତାରିଖ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "ଡିଫଲ୍ଟ ମୁଦ୍ରା: ${currency}" }
override val default_currency_label: String = "ଡିଫଲ୍ଟ ମୁଦ୍ରା"
override val delete: String = "ବିଲୋପ କରନ୍ତୁ"
override val delete_account: String = "ଆକାଉଣ୍ଟ ବିଲୋପ କରନ୍ତୁ"
override val delete_confirmation_message: String = "ଆପଣଙ୍କ ସମସ୍ତ ଡାଟା ହଜିଯିବ।"
override val delete_item_title: String = "ଆଇଟମ୍ ବିଲୋପ କରିବେ?"
override val delete_signature: String = "ଦସ୍ତଖତ ବିଲୋପ କରନ୍ତୁ"
override val depreciation: String = "depreciation"
override val description: String = "ବର୍ଣ୍ଣନା"
override val description_hint: String = "ବର୍ଣ୍ଣନା"
override val detailed_report: String = "ବିସ୍ତୃତ ରିପୋର୍ଟ"
override val disabled: String = "ଅକ୍ଷମ ହୋଇଛି"
override val discount: String = "ରିହାତି"
override val discount_hint: String = "ରିହାତି"
override val display_af: String = "ଆଫ୍ରିକୀୟ"
override val display_am: String = "ଅମ୍ହାରିକ୍"
override val display_ar: String = "ଆରବୀୟ"
override val display_as: String = "ଆସାମୀ"
override val display_az: String = "ଆଜରବାଇଜାନୀ"
override val display_be: String = "ବେଲାରୁସିଆନ୍‌"
override val display_bg: String = "ବୁଲଗେରିଆନ"
override val display_bn: String = "ବଙ୍ଗଳା"
override val display_bs: String = "ବୋସନିଆନ"
override val display_ca: String = "କାଟଲାନ"
override val display_cs: String = "ଚେକ୍‌"
override val display_da: String = "ଦାନିଶ୍‍"
override val display_de: String = "ଜର୍ମାନ"
override val display_el: String = "ଗ୍ରୀକ"
override val display_en: String = "ଇଂରାଜୀ"
override val display_es: String = "ସ୍ପାନିଶ୍‌"
override val display_es_MX: String = "ସ୍ପେନ୍ (ମେକ୍ସିକୋ)"
override val display_et: String = "ଇଷ୍ଟୋନିଆନ"
override val display_eu: String = "ବସ୍କ"
override val display_fa: String = "ପର୍ସିଆନ"
override val display_fi: String = "ଫିନିସ୍‌"
override val display_fil: String = "ଫିଲିପିନୋ"
override val display_fr: String = "ଫ୍ରେଞ୍ଚ"
override val display_fr_CA: String = "କାନାଡିୟ ଫରାସୀ"
override val display_gl: String = "ଗାଲିସିଆନ୍‌"
override val display_gu: String = "ଗୁଜରାଟୀ"
override val display_he: String = "ହିବ୍ରୁ"
override val display_hi: String = "ହିନ୍ଦୀ"
override val display_hr: String = "କ୍ରୋଏସିଆନ"
override val display_hu: String = "ହଙ୍ଗେରିଆନ"
override val display_hy: String = "ଆର୍ମେନିଆନ୍‌"
override val display_id: String = "ଇଣ୍ଡୋନେସିଆନ୍‌"
override val display_is: String = "ଆଇସ୍‍ଲାଣ୍ଡିକ୍‍"
override val display_it: String = "ଇଟାଲିଆନ"
override val display_ja: String = "ଜାପାନୀଜ"
override val display_ka: String = "ଜର୍ଜିଆନ୍‌"
override val display_kk: String = "କଜାଖ"
override val display_km: String = "କେନ୍ଦ୍ରୀୟ ଖମେର"
override val display_kn: String = "କନ୍ନଡ"
override val display_ko: String = "କୋରିଆନ"
override val display_ky: String = "କିର୍ଗିଜ୍"
override val display_lo: String = "ଲାଓ"
override val display_lt: String = "ଲିଥୁଆନିଆନ୍‌"
override val display_lv: String = "ଲାଟଭିଆନ"
override val display_mk: String = "ମାସେଡୋନିଆନ୍‌"
override val display_ml: String = "ମାଲାୟାଲାମ"
override val display_mn: String = "ମଙ୍ଗୋଲିଆନ"
override val display_mr: String = "ମରାଠି"
override val display_ms: String = "ମାଲୟ"
override val display_my: String = "ବର୍ମା"
override val display_nb: String = "ନର୍ୱେଜିଆନ୍ Bokmål"
override val display_ne: String = "ନେପାଳୀ"
override val display_nl: String = "ଡଚ୍‌"
override val display_or: String = "ଓଡ଼ିଆ"
override val display_pa: String = "ପଞ୍ଜାବୀ"
override val display_pl: String = "ପୋଲିଶ"
override val display_pt: String = "ପର୍ତ୍ତୁଗୀଜ"
override val display_pt_BR: String = "ପର୍ତ୍ତୁଗୀଜ (ବ୍ରାଜିଲ)"
override val display_pt_PT: String = "ୟୁରୋପୀୟ ପର୍ତ୍ତୁଗୀଜ"
override val display_ro: String = "ରୋମାନିଆନ"
override val display_ru: String = "ରଷିଆନ"
override val display_si: String = "ସିନହାଲା"
override val display_sk: String = "ସ୍ଲୋଭାକ"
override val display_sl: String = "ସ୍ଲୋଭେନିଆନ"
override val display_sq: String = "ଆଲବାନିଆନ୍‌"
override val display_sr: String = "ସର୍ବିୟାନ୍"
override val display_sv: String = "ସ୍ୱଦେଶୀ"
override val display_sw: String = "ସ୍ୱାହିଲି"
override val display_ta: String = "ତାମିଲ"
override val display_te: String = "ତେଲୁଗୁ"
override val display_th: String = "ଥାଇ"
override val display_tr: String = "ତୁର୍କୀସ"
override val display_uk: String = "ୟୁକ୍ରେନୀୟ"
override val display_ur: String = "ଉର୍ଦ୍ଦୁ"
override val display_uz: String = "ଉଜବେକ"
override val display_vi: String = "ଭିଏତନାମୀ"
override val display_zh_Hant_TW: String = "ଚାଇନିଜ୍ (ତାଇୱାନ୍, ପାରମ୍ପାରିକ)"
override val display_zu: String = "ଜୁଲୁ"
override val document_estimate: String = "ଆକଳନ"
override val document_invoice: String = "ଇନଭଏସ୍"
override val document_quote: String = "ଆକଳନ"
override val done: String = "ସମାପ୍ତ ହୋଇଛି"
override val download_app: String = "ଆପ୍ଲିକେସନ୍ ଡାଉନଲୋଡ୍ କରନ୍ତୁ"
override val download_invoice: String = "ଇନଭଏସ୍ ଡାଉନଲୋଡ୍ କରନ୍ତୁ"
override val download_pdf: String = "ପିଡିଏଫ୍ ଡାଉନଲୋଡ୍ କରନ୍ତୁ"
override val draw_signature: String = "ଏକ ଦସ୍ତଖତ ଅଙ୍କନ କରନ୍ତୁ"
override val due: Formattable = Formattable { (date) -> "ଦେୟ ${date}" }
override val due_date: String = "ଧାର୍ଯ୍ୟ ତାରିଖ"
override val due_day: String = "ଗୋଟିଏ ଦିନରେ"
override val due_label: String = "ଦେୟ"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ${dueWhen}!" }
override val due_today: String = "ଆଜି"
override val due_week: String = "ଏକ ସପ୍ତାହରେ"
override val duplicate: String = "ନକଲ"
override val duration_day: String = "ଦିନ"
override val duration_month: String = "ମାସ"
override val duration_week: String = "ସପ୍ତାହ"
override val duration_year: String = "ବର୍ଷ"
override val email: String = "ଇମେଲ୍"
override val email_blank_error: String = "ଇମେଲ୍ ଆବଶ୍ୟକ।"
override val email_error: String = "ଅବୈଧ ଇମେଲ୍ ଠିକଣା।"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "ଆପଣଙ୍କ ବ୍ୟବସାୟ ପାଇଁ ଧନ୍ୟବାଦ। ପୂର୍ଣ୍ଣ ${documentType} ଦର୍ଶନ କରିବାକୁ ଏହି ଅକ୍ଷରର ଶେଷରେ ବଟନ୍ କ୍ଲିକ୍ କରନ୍ତୁ। ନୋଟ୍ କରନ୍ତୁ ଏହା ${dueDate}ପର୍ଯ୍ୟନ୍ତ ବୈଧ ଅଟେ।" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "ଆପଣ ଏକ ନୂତନ ${documentType} ${invoiceNumber} ପାଇଛନ୍ତି!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} ଦର୍ଶନ କରନ୍ତୁ" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "ପ୍ରିୟ ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "ଆପଣଙ୍କ ବ୍ୟବସାୟ ପାଇଁ ଧନ୍ୟବାଦ। ପୂର୍ଣ୍ଣ ${documentType} ଦର୍ଶନ କରିବାକୁ ଏହି ଅକ୍ଷରର ଶେଷରେ ବଟନ୍ କ୍ଲିକ୍ କରନ୍ତୁ। ଦୟାକରି ${dueDate}ପର୍ଯ୍ୟନ୍ତ ବାଲାନ୍ସ ଦେୟ କରନ୍ତୁ।" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "ଆପଣ ଏକ ନୂତନ ${documentType} ${invoiceNumber} ପାଇଛନ୍ତି!" }
override val email_reset_success_message: String = "ପାସୱାର୍ଡ୍ ପୁନଃସେଟ୍ କରିବାକୁ ଇମେଲ୍ ପ୍ରେରିତ, ଦୟାକରି ଆପଣଙ୍କ ଇନବକ୍ସ ଯାଞ୍ଚ କରନ୍ତୁ।"
override val empty_clients_list_message: String = "ଟ୍ରାକ୍ କରିବାକୁ ଆପଣଙ୍କର କୌଣସି ଗ୍ରାହକ ନାହାଁନ୍ତି। ଏକ ନୂତନ ଗ୍ରାହକ ଯୋଡନ୍ତୁ।"
override val empty_estimate_list_message: String = "ଆପଣଙ୍କ ପ୍ରଥମ ଆକଳନ ସୃଷ୍ଟି କରନ୍ତୁ"
override val empty_expenses: String = "ଟ୍ରାକ୍ କରିବା ପାଇଁ ଆପଣଙ୍କର କୌଣସି ଖର୍ଚ୍ଚ ନାହିଁ। ନୂଆ ଖର୍ଚ୍ଚ ଯୋଡନ୍ତୁ।"
override val empty_invoice_list_message: String = "ଆପଣଙ୍କ ପ୍ରଥମ ଇନଭଏସ୍ ସୃଷ୍ଟି କରନ୍ତୁ"
override val empty_items_list_message: String = "ଆପଣଙ୍କ ପାଖରେ କୌଣସି ଆଇଟମ୍ ନାହିଁ, ଏକ ନୂତନ ଆଇଟମ୍ ଯୋଡନ୍ତୁ!"
override val empty_signatures_list_message: String = "କୌଣସି ଦସ୍ତଖତ ମିଳିଲା ନାହିଁ। ଗୋଟିଏ ସୃଷ୍ଟି କରନ୍ତୁ!"
override val empty_taxes_list_message: String = "ଆପଣଙ୍କର କୌଣସି ଟିକସ ନିୟମ ନାହିଁ। ଏକ ନୂତନ ଟିକସ ନିୟମ ଯୋଡନ୍ତୁ।"
override val empty_templates_list_message: String = "ଟେମ୍ପଲେଟ୍ ଅନୁପଲବ୍ଧ।"
override val enabled: String = "ସକ୍ଷମ ହୋଇଛି"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "ଆକଳନ #"
override val estimates: String = "ଆକଳନ"
override val estimates_report: String = "ଆକଳନ"
override val expense_bank_fees: String = "ବ୍ୟାଙ୍କ ଫି'"
override val expense_benefits: String = "ଫାଇଦା"
override val expense_communication: String = "ଯୋଗାଯୋଗ[ସମ୍ପାଦନା]"
override val expense_debt: String = "ଋଣଭାର"
override val expense_deleted: String = "ଖର୍ଚ୍ଚ ବିଲୋପ ହୋଇଛି"
override val expense_depreciation: String = "ଅବନତି[ସମ୍ପାଦନା]"
override val expense_gifts: String = "ଉପହାର[ସମ୍ପାଦନା]"
override val expense_insurance: String = "ବୀମା[ସମ୍ପାଦନା]"
override val expense_inventory: String = "ତାଲିକା[ସମ୍ପାଦନା]"
override val expense_legal: String = "ଆଇନଗତ"
override val expense_maintenance: String = "ରକ୍ଷଣାବେକ୍ଷଣ[ସମ୍ପାଦନା]"
override val expense_marketing: String = "ମାର୍କେଟିଂ"
override val expense_meals: String = "ଭୋଜନ[ସମ୍ପାଦନା]"
override val expense_office_supplies: String = "ଅଫିସ ଯୋଗାଣ[ସମ୍ପାଦନା]"
override val expense_rent: String = "ଭଡ଼ା"
override val expense_salaries: String = "ଦରମା[ସମ୍ପାଦନା]"
override val expense_shopping: String = "ସପିଂ କରିବା"
override val expense_software: String = "ସଫ୍ଟୱେୟାର"
override val expense_status_available: String = "ଉପଲବ୍ଧ"
override val expense_status_processing: String = "ପ୍ରକ୍ରିୟାକରଣ[ସମ୍ପାଦନା]"
override val expense_tax: String = "ଟିକସ"
override val expense_training: String = "ପ୍ରଶିକ୍ଷଣ [ସମ୍ପାଦନା]"
override val expense_transport: String = "ପରିବହନ[ସମ୍ପାଦନା]"
override val expense_travel: String = "ଯାତ୍ରା କରନ୍ତୁ"
override val expense_utility: String = "ଉପଯୋଗିତା[ସମ୍ପାଦନା]"
override val expenses: String = "ଖର୍ଚ୍ଚ[ସମ୍ପାଦନା]"
override val feedback_option: String = "ମତାମତ"
override val filter: String = "ଫିଲ୍ଟର"
override val first_name_hint: String = "ପ୍ରଥମ ନାମ"
override val forgot_password: String = "ପାସୱାର୍ଡ୍ ପୁନଃସେଟ୍ କରନ୍ତୁ"
override val forgot_password_label: String = "ପାସୱାର୍ଡ୍ ଭୁଲିଯାଇଛନ୍ତି"
override val from: String = "ରୁ"
override val general_error: String = "ତ୍ରୁଟି ଘଟିଛି, ଦୟାକରି ପରେ ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ।"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "ପଛକୁ ଯାଆନ୍ତୁ"
override val group_by: String = "ଗୋଷ୍ଠୀ ଦ୍ୱାରା:"
override val image_view: String = "ପ୍ରତିଛବି"
override val install: String = "ଇନ୍ଷ୍ଟଲ୍ କରନ୍ତୁ"
override val invalid_credentials_error: String = "ଅବୈଧ ପରିଚୟପତ୍ର।"
override val invalid_email_error: String = "ଅବୈଧ ଇମେଲ୍।"
override val invalid_user_error: String = "ଅବୈଧ ଉପଭୋକ୍ତା ପରିଚୟପତ୍ର।"
override val invoice_deleted_message: String = "ଇନଭଏସ୍ ବିଲୋପ ହୋଇଛି"
override val invoice_menu: String = "ଇନଭଏସ୍ ମେନ୍ୟୁ"
override val invoice_number: String = "ଇନଭଏସ୍ #"
override val invoices: String = "ଇନଭଏସ୍"
override val items_option: String = "ଆଇଟମଗୁଡିକ"
override val keep: String = "ରଖନ୍ତୁ"
override val language_selector: Formattable = Formattable { (selected) -> "ଭାଷା ${selected}" }
override val last_name_hint: String = "ଶେଷ ନାମ"
override val ledger: String = "ledger"
override val legal: String = "ଆଇନଗତ"
override val legal_error: String = "ସେବା ଏବଂ ଗୋପନୀୟତା ନୀତିର ସର୍ତ୍ତାବଳୀ ଗ୍ରହଣ କରିବା ଆବଶ୍ୟକ"
override val liabilities: String = "liabilities"
override val loading: String = "ଲୋଡିଂ"
override val login_required_error: String = "ଲଗଇନ୍ ଆବଶ୍ୟକ।"
override val logo_view: String = "ଲୋଗୋ"
override val logout: String = "ଲଗଆଉଟ୍ କରନ୍ତୁ"
override val logout_prompt: String = "ଆପଣଙ୍କ ଡାଟା କ୍ଲାଉଡ୍ ସହ ସିଙ୍କ୍ରୋନାଇଜ୍ ହୋଇନାହିଁ। ଲଗଆଉଟ୍ ରେ ଇନଭଏସ୍, ଆକଳନ, ଗ୍ରାହକ ଏବଂ ଅନ୍ୟ ସୂଚନା ବିଲୋପ କରିବେ?"
override val main_screen: String = "ମୁଖ୍ୟ ସ୍କ୍ରିନ୍"
override val mark_paid: String = "ଦେୟ ଭାବରେ ମାର୍କ କରନ୍ତୁ"
override val message: String = "ସନ୍ଦେଶ[ସମ୍ପାଦନା]"
override val missing_sender_msg: String = "ପ୍ରେରକ ସୂଚନା ବିନା ଏକ ଡକ୍ୟୁମେଣ୍ଟ ଅବୈଧ ହୋଇପାରେ।"
override val missing_sender_title: String = "ପ୍ରେରକ ସୂଚନା ନିଖୋଜ"
override val month_april: String = "ଚୈତ୍ର"
override val month_april_short: String = "ଚୈତ୍ର"
override val month_august: String = "ଶ୍ରାବଣ"
override val month_august_short: String = "ଅଗ."
override val month_december: String = "ମାର୍ଗଶୀର"
override val month_december_short: String = "ଡି."
override val month_february: String = "ମାଘ"
override val month_february_short: String = "ଫେ."
override val month_january: String = "ପୌଷ"
override val month_january_short: String = "ଜାନ."
override val month_july: String = "ଆଷାଢ"
override val month_july_short: String = "ଆଷାଢ"
override val month_june: String = "ଜ୍ୟେଷ୍ଠ"
override val month_june_short: String = "ଜ୍ୟେଷ୍ଠ"
override val month_march: String = "ଫାଲ୍‌ଗୁନ"
override val month_march_short: String = "ଫାଲ୍‌ଗୁନ"
override val month_may: String = "ବୈଶାଖ"
override val month_may_short: String = "ବୈଶାଖ"
override val month_november: String = "କାର୍ତ୍ତିକ"
override val month_november_short: String = "ନଭ."
override val month_october: String = "ଆଶ୍ୱିନ"
override val month_october_short: String = "ଅକ."
override val month_september: String = "ଭାଦ୍ରବ"
override val month_september_short: String = "ସାତ।"
override val multifactor_error: String = "ଯାଞ୍ଚ କରିବାକୁ ବିଫଳ ହୋଇଛି।"
override val new: String = "ନୂଆ"
override val news: String = "ଖବର"
override val next: String = "ପରବର୍ତ୍ତୀ"
override val no: String = "no"
override val no_data_available: String = "କୌଣସି ଡାଟା ଉପଲବ୍ଧ ନାହିଁ"
override val no_search_results: String = "ଦୁଃଖିତ, ଆମେ କୌଣସି ଫଳାଫଳ ପାଇପାରିଲୁ ନାହିଁ।"
override val none: String = "କିଛି ନାହିଁ"
override val note_fields: String = "ଟିପ୍ପଣୀଗୁଡିକ"
override val note_hint: String = "ନୋଟ୍ କରନ୍ତୁ"
override val notifications_option: String = "ବିଜ୍ଞପ୍ତିଗୁଡିକ"
override val ok: String = "ଠିକ୍‌ ଅଛି"
override val onboarding: String = "ଅନ୍ ବୋର୍ଡିଂ"
override val onboarding_aboutus_message: String = "ଡିଙ୍ଗଭଏସ୍ ର ଅତ୍ୟାବଶ୍ୟକ ଅଂଶସବୁଦିନ ପାଇଁ ମାଗଣା ଅଟେ | ଆମର ମିଶନ ହେଉଛି ତୁମର ବ୍ୟବସାୟ ବୃଦ୍ଧି କରିବାରେ ସାହାଯ୍ୟ କରିବା, ତୁମର ମତାମତ ଅଂଶୀଦାର କରି ଆମକୁ ଉନ୍ନତି କରିବାରେ ସାହାଯ୍ୟ କରିବା |"
override val onboarding_aboutus_title: String = "ଆମେ ସାହାଯ୍ୟ କରିବାକୁ ଏଠାରେ ଅଛୁ"
override val onboarding_paid_1: String = "ବୃତ୍ତିଗତ ଦେଖାଯାଉଥିବା ଟେମ୍ପଲେଟ୍ ଆକସେସ୍ କରନ୍ତୁ"
override val onboarding_paid_2: String = "ଆପଣଙ୍କ ଆର୍ଥିକ ବିଷୟରେ ଗଭୀର ଦୃଷ୍ଟି ଦିଅନ୍ତୁ"
override val onboarding_paid_3: String = "ଶୀଘ୍ର ସମାଧାନ ହୋଇଥିବା ସମସ୍ୟାର ସମାଧାନ କରନ୍ତୁ"
override val onboarding_paid_4: String = "ଆପଣଙ୍କ ଡକ୍ୟୁମେଣ୍ଟଗୁଡିକ ସୁରକ୍ଷିତ ରଖନ୍ତୁ"
override val onboarding_paid_5: String = "ଇନଭଏସ୍ ସ୍ଥିତି ପରିବର୍ତ୍ତନଗୁଡିକ ସହ ଅଦ୍ୟତନ ରୁହନ୍ତୁ"
override val onboarding_paid_title: String = "ଏହା ସହିତ ଆହୁରି ଦ୍ରୁତ ଗତିରେ ବଢାନ୍ତୁ..."
override val onboarding_paywall_message: String = "ସର୍ବୋତ୍ତମ ମୂଲ୍ୟରେ ବର୍ତ୍ତମାନ ସମସ୍ତ ପ୍ରିମିୟମ୍ ବୈଶିଷ୍ଟ୍ୟଉପଭୋଗ କରନ୍ତୁ।"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Just ${currencySymbol}${introAmount} for ${introDuration} ${introPeriod} and then ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "କେବଳ ${currencySymbol} ${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} and then just ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} then ${currencySymbol}${introductoryAmount} for ${introductoryDuration} ${introPeriod} and then just ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "ଅଟୋ-ନବୀକରଣଯୋଗ୍ୟ। ଯେକୌଣସି ସମୟରେ ବାତିଲ୍ କରନ୍ତୁ।"
override val onboarding_paywall_restore: String = "କ୍ରୟ ପୁନରୁଦ୍ଧାର କରନ୍ତୁ"
override val onboarding_paywall_restore_failure: String = "କ୍ରୟ ପୁନରୁଦ୍ଧାର କରିବା ବିଫଳ ହୋଇଛି, ପରେ ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ କିମ୍ୱା ସମ୍ପର୍କ ସମର୍ଥନ।"
override val onboarding_paywall_restore_success: String = "ସଫଳ ପୁନରୁଦ୍ଧାର କରନ୍ତୁ!"
override val onboarding_paywall_title: String = "ମହାନତା ହାସଲ କରନ୍ତୁ!"
override val onboarding_welcome_message: String = "ଆପଣଙ୍କ ଇନଭଏସ୍ ଏବଂ ଆକଳନ ପରିଚାଳନା କରିବାକୁ ଏକ ସମ୍ପୂର୍ଣ୍ଣ ଟୁଲସେଟ୍।"
override val onboarding_welcome_title: String = "ଡିଙ୍ଗଭଏସ୍ କୁ ସ୍ଵାଗତ"
override val other_state: String = "ଅନ୍ୟ"
override val overdue_state: String = "ଅତ୍ୟଧିକ"
override val paid_state: String = "ଦେୟ"
override val password: String = "ପାସୱାର୍ଡ୍"
override val password_blank_error: String = "ପାସୱାର୍ଡ୍ ଆବଶ୍ୟକ।"
override val password_mismatch_error: String = "ପାସୱାର୍ଡ୍ ମେଳକ ହୁଏ ନାହିଁ।"
override val pay: String = "pay"
override val payment_instructions_label: String = "ଦେୟ ନିର୍ଦ୍ଦେଶାବଳୀ"
override val payment_reminders: String = "ଦେୟ ସ୍ମାରକଗୁଡିକ"
override val phone_number_fields: String = "ଫୋନ୍ ନମ୍ଵର୍"
override val phone_number_hint: String = "ଫୋନ୍ ନମ୍ଵର୍"
override val postcode_hint: String = "ପୋଷ୍ଟକୋଡ୍"
override val preview: String = "ପୂର୍ବାବଲୋକନ"
override val preview_menu_hint: String = "ପୂର୍ବାବଲୋକନ ମେନ୍ୟୁ"
override val price: String = "ମୂଲ୍ୟ"
override val privacy_policy: String = "ଗୋପନୀୟତା ନୀତି"
override val profile: String = "ପ୍ରୋଫାଇଲ୍"
override val profitable_client: String = "ଆପଣଙ୍କର ସବୁଠାରୁ ଲାଭଦାୟକ ଗ୍ରାହକ ହେଉଛି..."
override val profits_report: String = "ଲାଭ"
override val promo_content: String = "ପ୍ରୋତ୍ସାହନମୂଳକ ବିଷୟବସ୍ତୁ"
override val quantity_hint: String = "ପରିମାଣ"
override val quantity_label_hint: String = "ପରିମାଣ ଲେବଲ୍"
override val query_info: String = "ପ୍ରଶ୍ନ ଆପଣଙ୍କୁ ଉଭୟ ଆଇଟମ୍ ଏବଂ ଅନ୍ୟାନ୍ୟ ଟିକସ ନିୟମକୁ ଇନପୁଟ୍ ଭାବରେ ଉଲ୍ଲେଖ କରି କଷ୍ଟମ୍ ଗଣନା ଲେଖିବାକୁ ଅନୁମତି ଦିଏ | ଅନ୍ୟ ଏଣ୍ଟ୍ରି +, -, *, /, % - ଗଣିତ ଅପରେଟର୍ କୁ ଲିଙ୍କ୍ କରିବାକୁ @ ପ୍ରତୀକ ଏବଂ ଆଇଟମ୍ କିମ୍ୱା ଟିକସ ବିବରଣୀ ଯୋଡନ୍ତୁ"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "ଆପଣଙ୍କ ଗ୍ରାହକଙ୍କ ଦ୍ୱାରା ${documentType} ${documentNumber} ପ୍ରାପ୍ତ ହୋଇଛି।" }
override val received_state: String = "ପ୍ରାପ୍ତ ହୋଇଛି"
override val registration_code_fields: String = "ପଞ୍ଜିକରଣ କୋଡ୍"
override val reminder: String = "ସ୍ମାରକ"
override val reports: String = "ରିପୋର୍ଟଗୁଡିକ"
override val reports_not_available: String = "କୌଣସି ରିପୋର୍ଟ୍ ଉପଲବ୍ଧ ନାହିଁ, ପରେ ଫେରି ଆସନ୍ତୁ।"
override val reveal_password: String = "ପାସୱାର୍ଡ୍ ପ୍ରକାଶ କରନ୍ତୁ"
override val revenue: String = "revenue"
override val sales_tax: String = "ଭ୍ୟାଟ୍"
override val sales_tax_number: String = "ଭାଟ୍ ନମ୍ଵର୍"
override val save: String = "ସଞ୍ଚୟ କରନ୍ତୁ"
override val scan_document: String = "ଦସ୍ତାବିଜ୍ ସ୍କାନ୍ କରନ୍ତୁ"
override val search: String = "ସନ୍ଧାନ କରନ୍ତୁ"
override val select: String = "ଚୟନ କରନ୍ତୁ"
override val select_icon: String = "ଆଇକନ୍ ଚୟନ କରନ୍ତୁ"
override val select_signature: String = "ଦସ୍ତଖତ ଚୟନ କରନ୍ତୁ"
override val select_template: String = "ଟେମ୍ପଲେଟ୍ ଚୟନ କରନ୍ତୁ"
override val send: String = "ପ୍ରେରଣ କରନ୍ତୁ"
override val sent_state: String = "ପ୍ରେରିତ"
override val setup_sender: String = "ପ୍ରେରକ ସେଟ୍ ଅପ୍ କରନ୍ତୁ"
override val share_option: String = "ଅଂଶୀଦାର କରନ୍ତୁ"
override val ship_to: String = "ଜାହାଜକୁ"
override val sign_in: String = "ସାଇନ୍‌ ଇନ୍‌"
override val sign_in_apple: String = "ଆପଲ୍ ସହ ସାଇନ୍ ଇନ୍ କରନ୍ତୁ"
override val sign_in_email: String = "ଇମେଲ୍ ସହ ସାଇନ୍ ଇନ୍ କରନ୍ତୁ"
override val sign_in_facebook: String = "ଫେସବୁକ୍ ସହ ସାଇନ୍ ଇନ୍ କରନ୍ତୁ"
override val sign_in_google: String = "ଗୁଗୁଲ୍ ସହ ସାଇନ୍ ଇନ୍ କରନ୍ତୁ"
override val sign_up: String = "ସାଇନ୍‌ ଅପ୍‌"
override val sign_up_label: String = "ଆକାଉଣ୍ଟ ନାହିଁ?"
override val sign_up_link_label: String = "ସାଇନ୍‌ ଅପ୍‌!"
override val signatures_option: String = "ଦସ୍ତଖତ"
override val skip: String = "ସ୍କିପ୍ କରନ୍ତୁ"
override val sold_items_report: String = "ବିକ୍ରୟ ହୋଇଥିବା ଆଇଟମଗୁଡିକ"
override val sort_by: String = "ଦ୍ୱାରା ସର୍ଟ କରନ୍ତୁ:"
override val status_update: String = "ଡକ୍ୟୁମେଣ୍ଟ ସ୍ଥିତି ଅପଡେଟ୍"
override val sub_annual: String = "ବାର୍ଷିକ"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/ବର୍ଷ" }
override val sub_backups: String = "କ୍ଲାଉଡ୍ ବ୍ୟାକଅପ୍"
override val sub_insights: String = "ଅନ୍ତଦୃଷ୍ଟି"
override val sub_month: String = "ମାସିକ"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ମାସ" }
override val sub_notifications: String = "ବିଜ୍ଞପ୍ତିଗୁଡିକ"
override val sub_popup_message: String = "ସଦସ୍ୟତା ଶୀଘ୍ର ଆସୁଛି। କିଛି ସମୟ ମଧ୍ୟରେ ଏଠାରେ ଯାଞ୍ଚ କରନ୍ତୁ।"
override val sub_support: String = "ସମର୍ଥନ"
override val sub_templates: String = "ଟେମ୍ପଲେଟ୍"
override val subscribe: String = "ସଦସ୍ୟତା ନିଅନ୍ତୁ"
override val subscription_header: String = "ଆପଣଙ୍କ ବ୍ୟବସାୟ ସହିତ ବଢାନ୍ତୁ:"
override val subscription_option: String = "ସଦସ୍ୟତା"
override val subscriptions_load_fail: String = "ଦୁଃଖିତ, ଆମେ ଏହି ସମୟରେ ଆପଣଙ୍କ ସଦସ୍ୟତା ପୁନରୁଦ୍ଧାର କରିବାକୁ ଅସମର୍ଥ। ଦୟାକରି ପରେ ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ କିମ୍ୱା ସହାୟତା ପାଇଁ ଆମର ସମର୍ଥନ ଦଳ ସହ ଯୋଗାଯୋଗ କରନ୍ତୁ।"
override val subtotal: Formattable = Formattable { (sum) -> "ସବଟୋଟାଲ୍: ${sum}" }
override val subtotal_label: String = "ସବ୍ ଟୋଟାଲ୍"
override val sum: String = "ରାଶି"
override val support_option: String = "ସମର୍ଥନ"
override val switch_reports: String = "ରିପୋର୍ଟ୍ ସ୍ଵିଚ୍ କରନ୍ତୁ"
override val tax: String = "ଟିକସ"
override val tax_deleted_message: String = "ଟିକସ ବିଲୋପ ହୋଇଛି"
override val tax_description_warning: String = "ଯଦି ଏହା ବ୍ୟବହାର କରାଯାଏ ତେବେ ଏକ ଇନଭଏସ୍ ରେ ଦେଖାଯାଏ"
override val tax_input_flat: String = "ସମତଳ"
override val tax_input_percent: String = "ପ୍ରତିଶତ"
override val tax_number_fields: String = "ଟିକସ ସଂଖ୍ୟା"
override val tax_number_hint: String = "ଟିକସ ସଂଖ୍ୟା"
override val tax_query_info_description: String = "ପ୍ରଶ୍ନ ସୂଚନା"
override val tax_report: String = "ଟିକସ"
override val tax_rules_option: String = "ଟିକସ ନିୟମ"
override val taxed_clients_report: String = "ଟିକସ ପ୍ରାପ୍ତ ଗ୍ରାହକ"
override val template: String = "ଟେମ୍ପଲେଟ୍"
override val template_selector_info: String = "ଡିଫଲ୍ଟ ଟେମ୍ପଲେଟ୍ ପରିବର୍ତ୍ତନ କରିବାକୁ ସ୍ଵାଇପ୍ କରନ୍ତୁ"
override val template_unlock_promo: String = "ପ୍ରିମିୟମ୍ ସହ ଅଧିକ ଟେମ୍ପଲେଟ୍ ଆକସେସ୍ କରନ୍ତୁ"
override val templates_option: String = "ଟେମ୍ପଲେଟ୍"
override val terms_of_service: String = "ସେବାର ସର୍ତ୍ତାବଳୀ"
override val thank_you: String = "ଧନ୍ୟବାଦ"
override val to: String = ""
override val total: Formattable = Formattable { (sum) -> "ମୋଟ: ${sum}" }
override val total_label: String = "ସମୁଦାୟ"
override val undo_action: String = "ପୂର୍ବବତ୍ କରନ୍ତୁ"
override val unit: String = "ୟୁନିଟ୍"
override val unit_cost_hint: String = "ୟୁନିଟ୍ ମୂଲ୍ୟ"
override val unknown_client: String = "ଅଜ୍ଞାତ ଗ୍ରାହକ"
override val unlock: String = "ଅନଲକ୍ କରନ୍ତୁ"
override val unnamed_item: String = "ନାମହୀନ ଆଇଟମ୍"
override val upgrade: String = "ଅପଗ୍ରେଡ୍ କରନ୍ତୁ"
override val user_collision_error: String = "ଆକାଉଣ୍ଟ ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛି।"
override val valid_until: String = "ବୈଧ ହେବା ପର୍ଯ୍ୟନ୍ତ"
override val warning: String = "ଚେତାବନୀ!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} ଏବଂ ${privacyPolicy} ଗ୍ରହଣ କରନ୍ତୁ" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "ଅବୈଧ କୋଡ୍।",
	"add_client_action" to "ଗ୍ରାହକ ଯୋଡନ୍ତୁ",
	"add_customer" to "ଗ୍ରାହକ ଯୋଡନ୍ତୁ",
	"add_expense" to "ଖର୍ଚ୍ଚ ଯୋଡନ୍ତୁ",
	"add_fields_label" to "ଯୋଡନ୍ତୁ:",
	"add_invoice_action" to "ସୃଷ୍ଟି କରନ୍ତୁ",
	"add_item_action" to "ଆଇଟମ୍ ଯୋଡନ୍ତୁ",
	"add_item_button" to "ଆଇଟମ୍ ଯୋଡନ୍ତୁ",
	"add_signature_action" to "ଦସ୍ତଖତ ଯୋଡନ୍ତୁ",
	"add_tax_action" to "ଟିକସ ଯୋଡନ୍ତୁ",
	"address_fields" to "ଠିକଣା",
	"address_line_hint" to "ଠିକଣା ରେଖା",
	"advanced" to "ଉନ୍ନତ",
	"agree_marketing" to "ମୁଁ ପ୍ରତ୍ୟକ୍ଷ ମାର୍କେଟିଂ ଯୋଗାଯୋଗ ପ୍ରାପ୍ତ କରିବାକୁ ରାଜି",
	"all_notifications" to "ସମସ୍ତ ବିଜ୍ଞପ୍ତିଗୁଡିକ",
	"amortization" to "amortization",
	"amount" to "ପରିମାଣ",
	"asset" to "asset",
	"attach_file" to "ଫାଇଲ୍ ସଂଯୋଗ କରନ୍ତୁ",
	"attachment" to "ସଂଯୋଗ[ସମ୍ପାଦନା]",
	"auth_error" to "କ୍ରିୟା ବିଫଳ ହେଲା, ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ।",
	"available_for_paid" to "କ୍ଲାଉଡ୍ ସିଙ୍କ୍ କେବଳ ଦେୟ ସଦସ୍ୟତା ଅଧୀନରେ ଉପଲବ୍ଧ।",
	"backups_option" to "ବ୍ୟାକଅପ୍",
	"balance" to "balance",
	"bank_account" to "ବ୍ୟାଙ୍କ୍ ଆକାଉଣ୍ଟ",
	"bill_number" to "ବିଲ୍ ନମ୍ବର",
	"cancel" to "ବାତିଲ୍‌ କରନ୍ତୁ",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "ଶ୍ରେଣୀ[ସମ୍ପାଦନା]",
	"category" to "ଶ୍ରେଣୀ[ସମ୍ପାଦନା]",
	"category_add" to "ଶ୍ରେଣୀ ଯୋଡନ୍ତୁ",
	"category_delete" to "ଶ୍ରେଣୀ ବିଲୋପ କରନ୍ତୁ",
	"category_name" to "ଶ୍ରେଣୀ ନାମ[ସମ୍ପାଦନା]",
	"change_language" to Formattable { (language) -> "ଇନଭଏସ୍ ଭାଷା: ${language}" },
	"change_language_label" to "ଇନଭଏସ୍ ଭାଷା",
	"change_template" to "ଟେମ୍ପଲେଟ୍ ପରିବର୍ତ୍ତନ କରନ୍ତୁ",
	"city_hint" to "ସହର",
	"clear" to "ସ୍ପଷ୍ଟ",
	"client" to "ଗ୍ରାହକ",
	"client_actions" to "କ୍ଲାଏଣ୍ଟ କାର୍ଯ୍ୟ",
	"client_deleted_message" to "କ୍ଲାଏଣ୍ଟ ବିଲୋପ ହୋଇଛି",
	"clients_error_required_fields" to "ଅତି କମରେ ଗୋଟିଏ ମୂଲ୍ୟ ଆବଶ୍ୟକ।",
	"clients_option" to "ଗ୍ରାହକ",
	"close" to "ବନ୍ଦ",
	"cloud_sync" to "କ୍ଲାଉଡ୍ ସିଙ୍କ୍",
	"coming_soon" to "ଶୀଘ୍ର ଆସୁଅଛି!",
	"company_logo" to "କମ୍ପାନୀ ଲୋଗୋ",
	"company_name_hint" to "କମ୍ପାନୀ",
	"confirm_password_label" to "ପାସୱାର୍ଡ୍ ନିଶ୍ଚିତ କରନ୍ତୁ",
	"confirmation_title" to "କଣ ଆପଣ ସୁନିଶ୍ଚିତ ଅଛନ୍ତି?",
	"convert_to_invoice" to "ଇନଭଏସ୍ ରେ ରୂପାନ୍ତରିତ କରନ୍ତୁ",
	"country_hint" to "ଦେଶ",
	"create_client" to "କ୍ଲାଏଣ୍ଟ ସୃଷ୍ଟି କରନ୍ତୁ",
	"create_company" to "କମ୍ପାନୀ ସୃଷ୍ଟି କରନ୍ତୁ",
	"create_document" to "ଡକ୍ୟୁମେଣ୍ଟ ସୃଷ୍ଟି କରନ୍ତୁ",
	"create_estimate" to "ଆକଳନ",
	"create_invoice" to "ଇନଭଏସ୍",
	"create_item" to "ଆଇଟମ୍ ସୃଷ୍ଟି କରନ୍ତୁ",
	"create_new_client" to "ନୂତନ ଗ୍ରାହକ ସୃଷ୍ଟି କରନ୍ତୁ",
	"create_new_item" to "ନୂତନ ଆଇଟମ୍ ସୃଷ୍ଟି କରନ୍ତୁ",
	"create_new_tax" to "ନୂତନ ଟିକସ ସୃଷ୍ଟି କରନ୍ତୁ",
	"create_signature" to "ଦସ୍ତଖତ",
	"created" to "ସୃଷ୍ଟି ହୋଇଛି",
	"credit" to "credit",
	"customer" to "ଗ୍ରାହକ",
	"date" to "ତାରିଖ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "ଡିଫଲ୍ଟ ମୁଦ୍ରା: ${currency}" },
	"default_currency_label" to "ଡିଫଲ୍ଟ ମୁଦ୍ରା",
	"delete" to "ବିଲୋପ କରନ୍ତୁ",
	"delete_account" to "ଆକାଉଣ୍ଟ ବିଲୋପ କରନ୍ତୁ",
	"delete_confirmation_message" to "ଆପଣଙ୍କ ସମସ୍ତ ଡାଟା ହଜିଯିବ।",
	"delete_item_title" to "ଆଇଟମ୍ ବିଲୋପ କରିବେ?",
	"delete_signature" to "ଦସ୍ତଖତ ବିଲୋପ କରନ୍ତୁ",
	"depreciation" to "depreciation",
	"description" to "ବର୍ଣ୍ଣନା",
	"description_hint" to "ବର୍ଣ୍ଣନା",
	"detailed_report" to "ବିସ୍ତୃତ ରିପୋର୍ଟ",
	"disabled" to "ଅକ୍ଷମ ହୋଇଛି",
	"discount" to "ରିହାତି",
	"discount_hint" to "ରିହାତି",
	"display_af" to "ଆଫ୍ରିକୀୟ",
	"display_am" to "ଅମ୍ହାରିକ୍",
	"display_ar" to "ଆରବୀୟ",
	"display_as" to "ଆସାମୀ",
	"display_az" to "ଆଜରବାଇଜାନୀ",
	"display_be" to "ବେଲାରୁସିଆନ୍‌",
	"display_bg" to "ବୁଲଗେରିଆନ",
	"display_bn" to "ବଙ୍ଗଳା",
	"display_bs" to "ବୋସନିଆନ",
	"display_ca" to "କାଟଲାନ",
	"display_cs" to "ଚେକ୍‌",
	"display_da" to "ଦାନିଶ୍‍",
	"display_de" to "ଜର୍ମାନ",
	"display_el" to "ଗ୍ରୀକ",
	"display_en" to "ଇଂରାଜୀ",
	"display_es" to "ସ୍ପାନିଶ୍‌",
	"display_es_MX" to "ସ୍ପେନ୍ (ମେକ୍ସିକୋ)",
	"display_et" to "ଇଷ୍ଟୋନିଆନ",
	"display_eu" to "ବସ୍କ",
	"display_fa" to "ପର୍ସିଆନ",
	"display_fi" to "ଫିନିସ୍‌",
	"display_fil" to "ଫିଲିପିନୋ",
	"display_fr" to "ଫ୍ରେଞ୍ଚ",
	"display_fr_CA" to "କାନାଡିୟ ଫରାସୀ",
	"display_gl" to "ଗାଲିସିଆନ୍‌",
	"display_gu" to "ଗୁଜରାଟୀ",
	"display_he" to "ହିବ୍ରୁ",
	"display_hi" to "ହିନ୍ଦୀ",
	"display_hr" to "କ୍ରୋଏସିଆନ",
	"display_hu" to "ହଙ୍ଗେରିଆନ",
	"display_hy" to "ଆର୍ମେନିଆନ୍‌",
	"display_id" to "ଇଣ୍ଡୋନେସିଆନ୍‌",
	"display_is" to "ଆଇସ୍‍ଲାଣ୍ଡିକ୍‍",
	"display_it" to "ଇଟାଲିଆନ",
	"display_ja" to "ଜାପାନୀଜ",
	"display_ka" to "ଜର୍ଜିଆନ୍‌",
	"display_kk" to "କଜାଖ",
	"display_km" to "କେନ୍ଦ୍ରୀୟ ଖମେର",
	"display_kn" to "କନ୍ନଡ",
	"display_ko" to "କୋରିଆନ",
	"display_ky" to "କିର୍ଗିଜ୍",
	"display_lo" to "ଲାଓ",
	"display_lt" to "ଲିଥୁଆନିଆନ୍‌",
	"display_lv" to "ଲାଟଭିଆନ",
	"display_mk" to "ମାସେଡୋନିଆନ୍‌",
	"display_ml" to "ମାଲାୟାଲାମ",
	"display_mn" to "ମଙ୍ଗୋଲିଆନ",
	"display_mr" to "ମରାଠି",
	"display_ms" to "ମାଲୟ",
	"display_my" to "ବର୍ମା",
	"display_nb" to "ନର୍ୱେଜିଆନ୍ Bokmål",
	"display_ne" to "ନେପାଳୀ",
	"display_nl" to "ଡଚ୍‌",
	"display_or" to "ଓଡ଼ିଆ",
	"display_pa" to "ପଞ୍ଜାବୀ",
	"display_pl" to "ପୋଲିଶ",
	"display_pt" to "ପର୍ତ୍ତୁଗୀଜ",
	"display_pt_BR" to "ପର୍ତ୍ତୁଗୀଜ (ବ୍ରାଜିଲ)",
	"display_pt_PT" to "ୟୁରୋପୀୟ ପର୍ତ୍ତୁଗୀଜ",
	"display_ro" to "ରୋମାନିଆନ",
	"display_ru" to "ରଷିଆନ",
	"display_si" to "ସିନହାଲା",
	"display_sk" to "ସ୍ଲୋଭାକ",
	"display_sl" to "ସ୍ଲୋଭେନିଆନ",
	"display_sq" to "ଆଲବାନିଆନ୍‌",
	"display_sr" to "ସର୍ବିୟାନ୍",
	"display_sv" to "ସ୍ୱଦେଶୀ",
	"display_sw" to "ସ୍ୱାହିଲି",
	"display_ta" to "ତାମିଲ",
	"display_te" to "ତେଲୁଗୁ",
	"display_th" to "ଥାଇ",
	"display_tr" to "ତୁର୍କୀସ",
	"display_uk" to "ୟୁକ୍ରେନୀୟ",
	"display_ur" to "ଉର୍ଦ୍ଦୁ",
	"display_uz" to "ଉଜବେକ",
	"display_vi" to "ଭିଏତନାମୀ",
	"display_zh_Hant_TW" to "ଚାଇନିଜ୍ (ତାଇୱାନ୍, ପାରମ୍ପାରିକ)",
	"display_zu" to "ଜୁଲୁ",
	"document_estimate" to "ଆକଳନ",
	"document_invoice" to "ଇନଭଏସ୍",
	"document_quote" to "ଆକଳନ",
	"done" to "ସମାପ୍ତ ହୋଇଛି",
	"download_app" to "ଆପ୍ଲିକେସନ୍ ଡାଉନଲୋଡ୍ କରନ୍ତୁ",
	"download_invoice" to "ଇନଭଏସ୍ ଡାଉନଲୋଡ୍ କରନ୍ତୁ",
	"download_pdf" to "ପିଡିଏଫ୍ ଡାଉନଲୋଡ୍ କରନ୍ତୁ",
	"draw_signature" to "ଏକ ଦସ୍ତଖତ ଅଙ୍କନ କରନ୍ତୁ",
	"due" to Formattable { (date) -> "ଦେୟ ${date}" },
	"due_date" to "ଧାର୍ଯ୍ୟ ତାରିଖ",
	"due_day" to "ଗୋଟିଏ ଦିନରେ",
	"due_label" to "ଦେୟ",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ${dueWhen}!" },
	"due_today" to "ଆଜି",
	"due_week" to "ଏକ ସପ୍ତାହରେ",
	"duplicate" to "ନକଲ",
	"duration_day" to "ଦିନ",
	"duration_month" to "ମାସ",
	"duration_week" to "ସପ୍ତାହ",
	"duration_year" to "ବର୍ଷ",
	"email" to "ଇମେଲ୍",
	"email_blank_error" to "ଇମେଲ୍ ଆବଶ୍ୟକ।",
	"email_error" to "ଅବୈଧ ଇମେଲ୍ ଠିକଣା।",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "ଆପଣଙ୍କ ବ୍ୟବସାୟ ପାଇଁ ଧନ୍ୟବାଦ। ପୂର୍ଣ୍ଣ ${documentType} ଦର୍ଶନ କରିବାକୁ ଏହି ଅକ୍ଷରର ଶେଷରେ ବଟନ୍ କ୍ଲିକ୍ କରନ୍ତୁ। ନୋଟ୍ କରନ୍ତୁ ଏହା ${dueDate}ପର୍ଯ୍ୟନ୍ତ ବୈଧ ଅଟେ।" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "ଆପଣ ଏକ ନୂତନ ${documentType} ${invoiceNumber} ପାଇଛନ୍ତି!" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} ଦର୍ଶନ କରନ୍ତୁ" },
	"email_invoice_greeting" to Formattable { (clientName) -> "ପ୍ରିୟ ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "ଆପଣଙ୍କ ବ୍ୟବସାୟ ପାଇଁ ଧନ୍ୟବାଦ। ପୂର୍ଣ୍ଣ ${documentType} ଦର୍ଶନ କରିବାକୁ ଏହି ଅକ୍ଷରର ଶେଷରେ ବଟନ୍ କ୍ଲିକ୍ କରନ୍ତୁ। ଦୟାକରି ${dueDate}ପର୍ଯ୍ୟନ୍ତ ବାଲାନ୍ସ ଦେୟ କରନ୍ତୁ।" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "ଆପଣ ଏକ ନୂତନ ${documentType} ${invoiceNumber} ପାଇଛନ୍ତି!" },
	"email_reset_success_message" to "ପାସୱାର୍ଡ୍ ପୁନଃସେଟ୍ କରିବାକୁ ଇମେଲ୍ ପ୍ରେରିତ, ଦୟାକରି ଆପଣଙ୍କ ଇନବକ୍ସ ଯାଞ୍ଚ କରନ୍ତୁ।",
	"empty_clients_list_message" to "ଟ୍ରାକ୍ କରିବାକୁ ଆପଣଙ୍କର କୌଣସି ଗ୍ରାହକ ନାହାଁନ୍ତି। ଏକ ନୂତନ ଗ୍ରାହକ ଯୋଡନ୍ତୁ।",
	"empty_estimate_list_message" to "ଆପଣଙ୍କ ପ୍ରଥମ ଆକଳନ ସୃଷ୍ଟି କରନ୍ତୁ",
	"empty_expenses" to "ଟ୍ରାକ୍ କରିବା ପାଇଁ ଆପଣଙ୍କର କୌଣସି ଖର୍ଚ୍ଚ ନାହିଁ। ନୂଆ ଖର୍ଚ୍ଚ ଯୋଡନ୍ତୁ।",
	"empty_invoice_list_message" to "ଆପଣଙ୍କ ପ୍ରଥମ ଇନଭଏସ୍ ସୃଷ୍ଟି କରନ୍ତୁ",
	"empty_items_list_message" to "ଆପଣଙ୍କ ପାଖରେ କୌଣସି ଆଇଟମ୍ ନାହିଁ, ଏକ ନୂତନ ଆଇଟମ୍ ଯୋଡନ୍ତୁ!",
	"empty_signatures_list_message" to "କୌଣସି ଦସ୍ତଖତ ମିଳିଲା ନାହିଁ। ଗୋଟିଏ ସୃଷ୍ଟି କରନ୍ତୁ!",
	"empty_taxes_list_message" to "ଆପଣଙ୍କର କୌଣସି ଟିକସ ନିୟମ ନାହିଁ। ଏକ ନୂତନ ଟିକସ ନିୟମ ଯୋଡନ୍ତୁ।",
	"empty_templates_list_message" to "ଟେମ୍ପଲେଟ୍ ଅନୁପଲବ୍ଧ।",
	"enabled" to "ସକ୍ଷମ ହୋଇଛି",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "ଆକଳନ #",
	"estimates" to "ଆକଳନ",
	"estimates_report" to "ଆକଳନ",
	"expense_bank_fees" to "ବ୍ୟାଙ୍କ ଫି'",
	"expense_benefits" to "ଫାଇଦା",
	"expense_communication" to "ଯୋଗାଯୋଗ[ସମ୍ପାଦନା]",
	"expense_debt" to "ଋଣଭାର",
	"expense_deleted" to "ଖର୍ଚ୍ଚ ବିଲୋପ ହୋଇଛି",
	"expense_depreciation" to "ଅବନତି[ସମ୍ପାଦନା]",
	"expense_gifts" to "ଉପହାର[ସମ୍ପାଦନା]",
	"expense_insurance" to "ବୀମା[ସମ୍ପାଦନା]",
	"expense_inventory" to "ତାଲିକା[ସମ୍ପାଦନା]",
	"expense_legal" to "ଆଇନଗତ",
	"expense_maintenance" to "ରକ୍ଷଣାବେକ୍ଷଣ[ସମ୍ପାଦନା]",
	"expense_marketing" to "ମାର୍କେଟିଂ",
	"expense_meals" to "ଭୋଜନ[ସମ୍ପାଦନା]",
	"expense_office_supplies" to "ଅଫିସ ଯୋଗାଣ[ସମ୍ପାଦନା]",
	"expense_rent" to "ଭଡ଼ା",
	"expense_salaries" to "ଦରମା[ସମ୍ପାଦନା]",
	"expense_shopping" to "ସପିଂ କରିବା",
	"expense_software" to "ସଫ୍ଟୱେୟାର",
	"expense_status_available" to "ଉପଲବ୍ଧ",
	"expense_status_processing" to "ପ୍ରକ୍ରିୟାକରଣ[ସମ୍ପାଦନା]",
	"expense_tax" to "ଟିକସ",
	"expense_training" to "ପ୍ରଶିକ୍ଷଣ [ସମ୍ପାଦନା]",
	"expense_transport" to "ପରିବହନ[ସମ୍ପାଦନା]",
	"expense_travel" to "ଯାତ୍ରା କରନ୍ତୁ",
	"expense_utility" to "ଉପଯୋଗିତା[ସମ୍ପାଦନା]",
	"expenses" to "ଖର୍ଚ୍ଚ[ସମ୍ପାଦନା]",
	"feedback_option" to "ମତାମତ",
	"filter" to "ଫିଲ୍ଟର",
	"first_name_hint" to "ପ୍ରଥମ ନାମ",
	"forgot_password" to "ପାସୱାର୍ଡ୍ ପୁନଃସେଟ୍ କରନ୍ତୁ",
	"forgot_password_label" to "ପାସୱାର୍ଡ୍ ଭୁଲିଯାଇଛନ୍ତି",
	"from" to "ରୁ",
	"general_error" to "ତ୍ରୁଟି ଘଟିଛି, ଦୟାକରି ପରେ ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ।",
	"general_ledger" to "general ledger",
	"go_back_label" to "ପଛକୁ ଯାଆନ୍ତୁ",
	"group_by" to "ଗୋଷ୍ଠୀ ଦ୍ୱାରା:",
	"image_view" to "ପ୍ରତିଛବି",
	"install" to "ଇନ୍ଷ୍ଟଲ୍ କରନ୍ତୁ",
	"invalid_credentials_error" to "ଅବୈଧ ପରିଚୟପତ୍ର।",
	"invalid_email_error" to "ଅବୈଧ ଇମେଲ୍।",
	"invalid_user_error" to "ଅବୈଧ ଉପଭୋକ୍ତା ପରିଚୟପତ୍ର।",
	"invoice_deleted_message" to "ଇନଭଏସ୍ ବିଲୋପ ହୋଇଛି",
	"invoice_menu" to "ଇନଭଏସ୍ ମେନ୍ୟୁ",
	"invoice_number" to "ଇନଭଏସ୍ #",
	"invoices" to "ଇନଭଏସ୍",
	"items_option" to "ଆଇଟମଗୁଡିକ",
	"keep" to "ରଖନ୍ତୁ",
	"language_selector" to Formattable { (selected) -> "ଭାଷା ${selected}" },
	"last_name_hint" to "ଶେଷ ନାମ",
	"ledger" to "ledger",
	"legal" to "ଆଇନଗତ",
	"legal_error" to "ସେବା ଏବଂ ଗୋପନୀୟତା ନୀତିର ସର୍ତ୍ତାବଳୀ ଗ୍ରହଣ କରିବା ଆବଶ୍ୟକ",
	"liabilities" to "liabilities",
	"loading" to "ଲୋଡିଂ",
	"login_required_error" to "ଲଗଇନ୍ ଆବଶ୍ୟକ।",
	"logo_view" to "ଲୋଗୋ",
	"logout" to "ଲଗଆଉଟ୍ କରନ୍ତୁ",
	"logout_prompt" to "ଆପଣଙ୍କ ଡାଟା କ୍ଲାଉଡ୍ ସହ ସିଙ୍କ୍ରୋନାଇଜ୍ ହୋଇନାହିଁ। ଲଗଆଉଟ୍ ରେ ଇନଭଏସ୍, ଆକଳନ, ଗ୍ରାହକ ଏବଂ ଅନ୍ୟ ସୂଚନା ବିଲୋପ କରିବେ?",
	"main_screen" to "ମୁଖ୍ୟ ସ୍କ୍ରିନ୍",
	"mark_paid" to "ଦେୟ ଭାବରେ ମାର୍କ କରନ୍ତୁ",
	"message" to "ସନ୍ଦେଶ[ସମ୍ପାଦନା]",
	"missing_sender_msg" to "ପ୍ରେରକ ସୂଚନା ବିନା ଏକ ଡକ୍ୟୁମେଣ୍ଟ ଅବୈଧ ହୋଇପାରେ।",
	"missing_sender_title" to "ପ୍ରେରକ ସୂଚନା ନିଖୋଜ",
	"month_april" to "ଚୈତ୍ର",
	"month_april_short" to "ଚୈତ୍ର",
	"month_august" to "ଶ୍ରାବଣ",
	"month_august_short" to "ଅଗ.",
	"month_december" to "ମାର୍ଗଶୀର",
	"month_december_short" to "ଡି.",
	"month_february" to "ମାଘ",
	"month_february_short" to "ଫେ.",
	"month_january" to "ପୌଷ",
	"month_january_short" to "ଜାନ.",
	"month_july" to "ଆଷାଢ",
	"month_july_short" to "ଆଷାଢ",
	"month_june" to "ଜ୍ୟେଷ୍ଠ",
	"month_june_short" to "ଜ୍ୟେଷ୍ଠ",
	"month_march" to "ଫାଲ୍‌ଗୁନ",
	"month_march_short" to "ଫାଲ୍‌ଗୁନ",
	"month_may" to "ବୈଶାଖ",
	"month_may_short" to "ବୈଶାଖ",
	"month_november" to "କାର୍ତ୍ତିକ",
	"month_november_short" to "ନଭ.",
	"month_october" to "ଆଶ୍ୱିନ",
	"month_october_short" to "ଅକ.",
	"month_september" to "ଭାଦ୍ରବ",
	"month_september_short" to "ସାତ।",
	"multifactor_error" to "ଯାଞ୍ଚ କରିବାକୁ ବିଫଳ ହୋଇଛି।",
	"new" to "ନୂଆ",
	"news" to "ଖବର",
	"next" to "ପରବର୍ତ୍ତୀ",
	"no" to "no",
	"no_data_available" to "କୌଣସି ଡାଟା ଉପଲବ୍ଧ ନାହିଁ",
	"no_search_results" to "ଦୁଃଖିତ, ଆମେ କୌଣସି ଫଳାଫଳ ପାଇପାରିଲୁ ନାହିଁ।",
	"none" to "କିଛି ନାହିଁ",
	"note_fields" to "ଟିପ୍ପଣୀଗୁଡିକ",
	"note_hint" to "ନୋଟ୍ କରନ୍ତୁ",
	"notifications_option" to "ବିଜ୍ଞପ୍ତିଗୁଡିକ",
	"ok" to "ଠିକ୍‌ ଅଛି",
	"onboarding" to "ଅନ୍ ବୋର୍ଡିଂ",
	"onboarding_aboutus_message" to "ଡିଙ୍ଗଭଏସ୍ ର ଅତ୍ୟାବଶ୍ୟକ ଅଂଶସବୁଦିନ ପାଇଁ ମାଗଣା ଅଟେ | ଆମର ମିଶନ ହେଉଛି ତୁମର ବ୍ୟବସାୟ ବୃଦ୍ଧି କରିବାରେ ସାହାଯ୍ୟ କରିବା, ତୁମର ମତାମତ ଅଂଶୀଦାର କରି ଆମକୁ ଉନ୍ନତି କରିବାରେ ସାହାଯ୍ୟ କରିବା |",
	"onboarding_aboutus_title" to "ଆମେ ସାହାଯ୍ୟ କରିବାକୁ ଏଠାରେ ଅଛୁ",
	"onboarding_paid_1" to "ବୃତ୍ତିଗତ ଦେଖାଯାଉଥିବା ଟେମ୍ପଲେଟ୍ ଆକସେସ୍ କରନ୍ତୁ",
	"onboarding_paid_2" to "ଆପଣଙ୍କ ଆର୍ଥିକ ବିଷୟରେ ଗଭୀର ଦୃଷ୍ଟି ଦିଅନ୍ତୁ",
	"onboarding_paid_3" to "ଶୀଘ୍ର ସମାଧାନ ହୋଇଥିବା ସମସ୍ୟାର ସମାଧାନ କରନ୍ତୁ",
	"onboarding_paid_4" to "ଆପଣଙ୍କ ଡକ୍ୟୁମେଣ୍ଟଗୁଡିକ ସୁରକ୍ଷିତ ରଖନ୍ତୁ",
	"onboarding_paid_5" to "ଇନଭଏସ୍ ସ୍ଥିତି ପରିବର୍ତ୍ତନଗୁଡିକ ସହ ଅଦ୍ୟତନ ରୁହନ୍ତୁ",
	"onboarding_paid_title" to "ଏହା ସହିତ ଆହୁରି ଦ୍ରୁତ ଗତିରେ ବଢାନ୍ତୁ...",
	"onboarding_paywall_message" to "ସର୍ବୋତ୍ତମ ମୂଲ୍ୟରେ ବର୍ତ୍ତମାନ ସମସ୍ତ ପ୍ରିମିୟମ୍ ବୈଶିଷ୍ଟ୍ୟଉପଭୋଗ କରନ୍ତୁ।",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Just ${currencySymbol}${introAmount} for ${introDuration} ${introPeriod} and then ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "କେବଳ ${currencySymbol} ${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} and then just ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Free for ${trialDuration} ${trialPeriod} then ${currencySymbol}${introductoryAmount} for ${introductoryDuration} ${introPeriod} and then just ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "ଅଟୋ-ନବୀକରଣଯୋଗ୍ୟ। ଯେକୌଣସି ସମୟରେ ବାତିଲ୍ କରନ୍ତୁ।",
	"onboarding_paywall_restore" to "କ୍ରୟ ପୁନରୁଦ୍ଧାର କରନ୍ତୁ",
	"onboarding_paywall_restore_failure" to "କ୍ରୟ ପୁନରୁଦ୍ଧାର କରିବା ବିଫଳ ହୋଇଛି, ପରେ ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ କିମ୍ୱା ସମ୍ପର୍କ ସମର୍ଥନ।",
	"onboarding_paywall_restore_success" to "ସଫଳ ପୁନରୁଦ୍ଧାର କରନ୍ତୁ!",
	"onboarding_paywall_title" to "ମହାନତା ହାସଲ କରନ୍ତୁ!",
	"onboarding_welcome_message" to "ଆପଣଙ୍କ ଇନଭଏସ୍ ଏବଂ ଆକଳନ ପରିଚାଳନା କରିବାକୁ ଏକ ସମ୍ପୂର୍ଣ୍ଣ ଟୁଲସେଟ୍।",
	"onboarding_welcome_title" to "ଡିଙ୍ଗଭଏସ୍ କୁ ସ୍ଵାଗତ",
	"other_state" to "ଅନ୍ୟ",
	"overdue_state" to "ଅତ୍ୟଧିକ",
	"paid_state" to "ଦେୟ",
	"password" to "ପାସୱାର୍ଡ୍",
	"password_blank_error" to "ପାସୱାର୍ଡ୍ ଆବଶ୍ୟକ।",
	"password_mismatch_error" to "ପାସୱାର୍ଡ୍ ମେଳକ ହୁଏ ନାହିଁ।",
	"pay" to "pay",
	"payment_instructions_label" to "ଦେୟ ନିର୍ଦ୍ଦେଶାବଳୀ",
	"payment_reminders" to "ଦେୟ ସ୍ମାରକଗୁଡିକ",
	"phone_number_fields" to "ଫୋନ୍ ନମ୍ଵର୍",
	"phone_number_hint" to "ଫୋନ୍ ନମ୍ଵର୍",
	"postcode_hint" to "ପୋଷ୍ଟକୋଡ୍",
	"preview" to "ପୂର୍ବାବଲୋକନ",
	"preview_menu_hint" to "ପୂର୍ବାବଲୋକନ ମେନ୍ୟୁ",
	"price" to "ମୂଲ୍ୟ",
	"privacy_policy" to "ଗୋପନୀୟତା ନୀତି",
	"profile" to "ପ୍ରୋଫାଇଲ୍",
	"profitable_client" to "ଆପଣଙ୍କର ସବୁଠାରୁ ଲାଭଦାୟକ ଗ୍ରାହକ ହେଉଛି...",
	"profits_report" to "ଲାଭ",
	"promo_content" to "ପ୍ରୋତ୍ସାହନମୂଳକ ବିଷୟବସ୍ତୁ",
	"quantity_hint" to "ପରିମାଣ",
	"quantity_label_hint" to "ପରିମାଣ ଲେବଲ୍",
	"query_info" to "ପ୍ରଶ୍ନ ଆପଣଙ୍କୁ ଉଭୟ ଆଇଟମ୍ ଏବଂ ଅନ୍ୟାନ୍ୟ ଟିକସ ନିୟମକୁ ଇନପୁଟ୍ ଭାବରେ ଉଲ୍ଲେଖ କରି କଷ୍ଟମ୍ ଗଣନା ଲେଖିବାକୁ ଅନୁମତି ଦିଏ | ଅନ୍ୟ ଏଣ୍ଟ୍ରି +, -, *, /, % - ଗଣିତ ଅପରେଟର୍ କୁ ଲିଙ୍କ୍ କରିବାକୁ @ ପ୍ରତୀକ ଏବଂ ଆଇଟମ୍ କିମ୍ୱା ଟିକସ ବିବରଣୀ ଯୋଡନ୍ତୁ",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "ଆପଣଙ୍କ ଗ୍ରାହକଙ୍କ ଦ୍ୱାରା ${documentType} ${documentNumber} ପ୍ରାପ୍ତ ହୋଇଛି।" },
	"received_state" to "ପ୍ରାପ୍ତ ହୋଇଛି",
	"registration_code_fields" to "ପଞ୍ଜିକରଣ କୋଡ୍",
	"reminder" to "ସ୍ମାରକ",
	"reports" to "ରିପୋର୍ଟଗୁଡିକ",
	"reports_not_available" to "କୌଣସି ରିପୋର୍ଟ୍ ଉପଲବ୍ଧ ନାହିଁ, ପରେ ଫେରି ଆସନ୍ତୁ।",
	"reveal_password" to "ପାସୱାର୍ଡ୍ ପ୍ରକାଶ କରନ୍ତୁ",
	"revenue" to "revenue",
	"sales_tax" to "ଭ୍ୟାଟ୍",
	"sales_tax_number" to "ଭାଟ୍ ନମ୍ଵର୍",
	"save" to "ସଞ୍ଚୟ କରନ୍ତୁ",
	"scan_document" to "ଦସ୍ତାବିଜ୍ ସ୍କାନ୍ କରନ୍ତୁ",
	"search" to "ସନ୍ଧାନ କରନ୍ତୁ",
	"select" to "ଚୟନ କରନ୍ତୁ",
	"select_icon" to "ଆଇକନ୍ ଚୟନ କରନ୍ତୁ",
	"select_signature" to "ଦସ୍ତଖତ ଚୟନ କରନ୍ତୁ",
	"select_template" to "ଟେମ୍ପଲେଟ୍ ଚୟନ କରନ୍ତୁ",
	"send" to "ପ୍ରେରଣ କରନ୍ତୁ",
	"sent_state" to "ପ୍ରେରିତ",
	"setup_sender" to "ପ୍ରେରକ ସେଟ୍ ଅପ୍ କରନ୍ତୁ",
	"share_option" to "ଅଂଶୀଦାର କରନ୍ତୁ",
	"ship_to" to "ଜାହାଜକୁ",
	"sign_in" to "ସାଇନ୍‌ ଇନ୍‌",
	"sign_in_apple" to "ଆପଲ୍ ସହ ସାଇନ୍ ଇନ୍ କରନ୍ତୁ",
	"sign_in_email" to "ଇମେଲ୍ ସହ ସାଇନ୍ ଇନ୍ କରନ୍ତୁ",
	"sign_in_facebook" to "ଫେସବୁକ୍ ସହ ସାଇନ୍ ଇନ୍ କରନ୍ତୁ",
	"sign_in_google" to "ଗୁଗୁଲ୍ ସହ ସାଇନ୍ ଇନ୍ କରନ୍ତୁ",
	"sign_up" to "ସାଇନ୍‌ ଅପ୍‌",
	"sign_up_label" to "ଆକାଉଣ୍ଟ ନାହିଁ?",
	"sign_up_link_label" to "ସାଇନ୍‌ ଅପ୍‌!",
	"signatures_option" to "ଦସ୍ତଖତ",
	"skip" to "ସ୍କିପ୍ କରନ୍ତୁ",
	"sold_items_report" to "ବିକ୍ରୟ ହୋଇଥିବା ଆଇଟମଗୁଡିକ",
	"sort_by" to "ଦ୍ୱାରା ସର୍ଟ କରନ୍ତୁ:",
	"status_update" to "ଡକ୍ୟୁମେଣ୍ଟ ସ୍ଥିତି ଅପଡେଟ୍",
	"sub_annual" to "ବାର୍ଷିକ",
	"sub_annual_price" to Formattable { (price) -> "${price}/ବର୍ଷ" },
	"sub_backups" to "କ୍ଲାଉଡ୍ ବ୍ୟାକଅପ୍",
	"sub_insights" to "ଅନ୍ତଦୃଷ୍ଟି",
	"sub_month" to "ମାସିକ",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ମାସ" },
	"sub_notifications" to "ବିଜ୍ଞପ୍ତିଗୁଡିକ",
	"sub_popup_message" to "ସଦସ୍ୟତା ଶୀଘ୍ର ଆସୁଛି। କିଛି ସମୟ ମଧ୍ୟରେ ଏଠାରେ ଯାଞ୍ଚ କରନ୍ତୁ।",
	"sub_support" to "ସମର୍ଥନ",
	"sub_templates" to "ଟେମ୍ପଲେଟ୍",
	"subscribe" to "ସଦସ୍ୟତା ନିଅନ୍ତୁ",
	"subscription_header" to "ଆପଣଙ୍କ ବ୍ୟବସାୟ ସହିତ ବଢାନ୍ତୁ:",
	"subscription_option" to "ସଦସ୍ୟତା",
	"subscriptions_load_fail" to "ଦୁଃଖିତ, ଆମେ ଏହି ସମୟରେ ଆପଣଙ୍କ ସଦସ୍ୟତା ପୁନରୁଦ୍ଧାର କରିବାକୁ ଅସମର୍ଥ। ଦୟାକରି ପରେ ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ କିମ୍ୱା ସହାୟତା ପାଇଁ ଆମର ସମର୍ଥନ ଦଳ ସହ ଯୋଗାଯୋଗ କରନ୍ତୁ।",
	"subtotal" to Formattable { (sum) -> "ସବଟୋଟାଲ୍: ${sum}" },
	"subtotal_label" to "ସବ୍ ଟୋଟାଲ୍",
	"sum" to "ରାଶି",
	"support_option" to "ସମର୍ଥନ",
	"switch_reports" to "ରିପୋର୍ଟ୍ ସ୍ଵିଚ୍ କରନ୍ତୁ",
	"tax" to "ଟିକସ",
	"tax_deleted_message" to "ଟିକସ ବିଲୋପ ହୋଇଛି",
	"tax_description_warning" to "ଯଦି ଏହା ବ୍ୟବହାର କରାଯାଏ ତେବେ ଏକ ଇନଭଏସ୍ ରେ ଦେଖାଯାଏ",
	"tax_input_flat" to "ସମତଳ",
	"tax_input_percent" to "ପ୍ରତିଶତ",
	"tax_number_fields" to "ଟିକସ ସଂଖ୍ୟା",
	"tax_number_hint" to "ଟିକସ ସଂଖ୍ୟା",
	"tax_query_info_description" to "ପ୍ରଶ୍ନ ସୂଚନା",
	"tax_report" to "ଟିକସ",
	"tax_rules_option" to "ଟିକସ ନିୟମ",
	"taxed_clients_report" to "ଟିକସ ପ୍ରାପ୍ତ ଗ୍ରାହକ",
	"template" to "ଟେମ୍ପଲେଟ୍",
	"template_selector_info" to "ଡିଫଲ୍ଟ ଟେମ୍ପଲେଟ୍ ପରିବର୍ତ୍ତନ କରିବାକୁ ସ୍ଵାଇପ୍ କରନ୍ତୁ",
	"template_unlock_promo" to "ପ୍ରିମିୟମ୍ ସହ ଅଧିକ ଟେମ୍ପଲେଟ୍ ଆକସେସ୍ କରନ୍ତୁ",
	"templates_option" to "ଟେମ୍ପଲେଟ୍",
	"terms_of_service" to "ସେବାର ସର୍ତ୍ତାବଳୀ",
	"thank_you" to "ଧନ୍ୟବାଦ",
	"to" to "",
	"total" to Formattable { (sum) -> "ମୋଟ: ${sum}" },
	"total_label" to "ସମୁଦାୟ",
	"undo_action" to "ପୂର୍ବବତ୍ କରନ୍ତୁ",
	"unit" to "ୟୁନିଟ୍",
	"unit_cost_hint" to "ୟୁନିଟ୍ ମୂଲ୍ୟ",
	"unknown_client" to "ଅଜ୍ଞାତ ଗ୍ରାହକ",
	"unlock" to "ଅନଲକ୍ କରନ୍ତୁ",
	"unnamed_item" to "ନାମହୀନ ଆଇଟମ୍",
	"upgrade" to "ଅପଗ୍ରେଡ୍ କରନ୍ତୁ",
	"user_collision_error" to "ଆକାଉଣ୍ଟ ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛି।",
	"valid_until" to "ବୈଧ ହେବା ପର୍ଯ୍ୟନ୍ତ",
	"warning" to "ଚେତାବନୀ!",
	"yes" to "yes",
    )
    
}