// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ro  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Acceptați ${termsOfService} și ${privacyPolicy}" }
override val account: String = "cont"
override val accounting: String = "contabilitate"
override val accruals: String = "acumulări"
override val action_code_error: String = "Cod invalid."
override val add_client_action: String = "Adăugați clientul"
override val add_customer: String = "Adăugați clientul"
override val add_expense: String = "Adăugați cheltuieli"
override val add_fields_label: String = "Adăugați:"
override val add_invoice_action: String = "Creați"
override val add_item_action: String = "Adăugați elementul"
override val add_item_button: String = "Adăugați elementul"
override val add_signature_action: String = "Adăugați semnătura"
override val add_tax_action: String = "Adăugați impozitul"
override val address_fields: String = "Adresa"
override val address_line_hint: String = "Linia de adresă"
override val advanced: String = "AVANSAT"
override val agree_marketing: String = "Sunt de acord să primesc comunicări de marketing direct"
override val all_notifications: String = "Toate notificările"
override val amortization: String = "amortizare"
override val amount: String = "Suma"
override val asset: String = "activ"
override val attach_file: String = "Atașați un Fișier"
override val attachment: String = "Atașament"
override val auth_error: String = "Acțiunea a eșuat, încercați din nou."
override val available_for_paid: String = "Sincronizarea în cloud este disponibilă numai în cadrul unui abonament plătit."
override val backups_option: String = "Copii de rezervă"
override val balance: String = "sold"
override val bank_account: String = "Cont Bancar"
override val bill_number: String = "Număr de Factură"
override val cancel: String = "Anulează"
override val cancel_trial: String = "Anulați în orice moment"
override val capital: String = "capital"
override val cash: String = "numerar"
override val categories: String = "Categorii"
override val category: String = "Categorie"
override val category_add: String = "Adăugați o categorie"
override val category_delete: String = "Ștergeți categoria"
override val category_name: String = "Numele categoriei"
override val change_language: Formattable = Formattable { (language) -> "Limba facturii: ${language}" }
override val change_language_label: String = "Limba Facturii"
override val change_template: String = "Modificați șablonul"
override val city_hint: String = "Orașul"
override val clear: String = "Clar"
override val client: String = "Client"
override val client_actions: String = "Acțiuni ale clienților"
override val client_deleted_message: String = "Client șters"
override val clients_error_required_fields: String = "Este necesară cel puțin o valoare."
override val clients_option: String = "Clienți"
override val close: String = "Închideți"
override val cloud_sync: String = "Sincronizare în cloud"
override val coming_soon: String = "În curând!"
override val company_logo: String = "Logo-ul companiei"
override val company_name_hint: String = "Compania"
override val confirm_password_label: String = "Confirmați parola"
override val confirmation_title: String = "Ești sigur(ă)?"
override val convert_to_invoice: String = "Conversia în factură"
override val country_hint: String = "Țara"
override val create_client: String = "Creați un client"
override val create_company: String = "Creați compania"
override val create_document: String = "Creați documentul"
override val create_estimate: String = "Estimare"
override val create_invoice: String = "Factura"
override val create_item: String = "Creați elementul"
override val create_new_client: String = "Creați un nou client"
override val create_new_item: String = "Creați un nou element"
override val create_new_tax: String = "Crearea unui nou impozit"
override val create_signature: String = "Semnătură"
override val created: String = "a creat"
override val credit: String = "de credit"
override val customer: String = "Client"
override val date: String = "Data"
override val debit: String = "de debit"
override val debt: String = "datorie"
override val default_currency: Formattable = Formattable { (currency) -> "Moneda implicită: ${currency}" }
override val default_currency_label: String = "Monedă implicită"
override val delete: String = "Ștergeți"
override val delete_account: String = "Ștergeți Contul"
override val delete_confirmation_message: String = "Toate datele dvs. se vor pierde."
override val delete_item_title: String = "Ștergeți articolul?"
override val delete_signature: String = "Ștergeți semnătura"
override val depreciation: String = "depreciere"
override val description: String = "Descriere"
override val description_hint: String = "Descriere"
override val detailed_report: String = "Raport Detaliat"
override val disabled: String = "Persoane cu handicap"
override val discount: String = "Reducere"
override val discount_hint: String = "Reducere"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharic"
override val display_ar: String = "Arabă"
override val display_as: String = "Assamese"
override val display_az: String = "Azerbaidjan"
override val display_be: String = "Bielorusă"
override val display_bg: String = "Bulgară"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosniacă"
override val display_ca: String = "Catalan"
override val display_cs: String = "Cehia"
override val display_da: String = "Daneză"
override val display_de: String = "Germană"
override val display_el: String = "Greacă"
override val display_en: String = "Engleză"
override val display_es: String = "Spaniolă"
override val display_es_MX: String = "Spaniolă (Mexic)"
override val display_et: String = "Estoniană"
override val display_eu: String = "Bască"
override val display_fa: String = "Persană"
override val display_fi: String = "Finlandeză"
override val display_fil: String = "Filipineză"
override val display_fr: String = "Franceză"
override val display_fr_CA: String = "Franceză canadiană"
override val display_gl: String = "Galiciană"
override val display_gu: String = "Gujarati"
override val display_he: String = "Ebraică"
override val display_hi: String = "Hindi"
override val display_hr: String = "Croată"
override val display_hu: String = "Maghiară"
override val display_hy: String = "Armenească"
override val display_id: String = "Indoneziană"
override val display_is: String = "Islandeză"
override val display_it: String = "Italian"
override val display_ja: String = "Japoneză"
override val display_ka: String = "Georgian"
override val display_kk: String = "Kazahstan"
override val display_km: String = "Central Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Coreeană"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Lituaniene"
override val display_lv: String = "Letonă"
override val display_mk: String = "Macedoneană"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolă"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malay"
override val display_my: String = "Birmaneză"
override val display_nb: String = "Norvegiană Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Olandeză"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Poloneză"
override val display_pt: String = "Portugheză"
override val display_pt_BR: String = "Portugheză (Brazilia)"
override val display_pt_PT: String = "Portugheză europeană"
override val display_ro: String = "Românesc"
override val display_ru: String = "Rusă"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovacă"
override val display_sl: String = "Slovenă"
override val display_sq: String = "Albaneză"
override val display_sr: String = "Sârbă"
override val display_sv: String = "Suedeză"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Turcă"
override val display_uk: String = "Ucraineană"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbecă"
override val display_vi: String = "Vietnameză"
override val display_zh_Hant_TW: String = "Chineză (Taiwan, Tradițională)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Estimare"
override val document_invoice: String = "Factura"
override val document_quote: String = "Oferta"
override val done: String = "Realizat"
override val download_app: String = "Descărcați aplicația"
override val download_invoice: String = "Descărcați Factură"
override val download_pdf: String = "Descarcă PDF"
override val draw_signature: String = "Desenați o semnătură"
override val due: Formattable = Formattable { (date) -> "Cu scadență ${date}" }
override val due_date: String = "Data limită"
override val due_day: String = "într-o zi"
override val due_label: String = "Datorita"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} este datorat ${dueWhen}!" }
override val due_today: String = "astăzi"
override val due_week: String = "într-o săptămână"
override val duplicate: String = "Duplicați"
override val duration_day: String = "zi"
override val duration_month: String = "luna"
override val duration_week: String = "săptămână"
override val duration_year: String = "an"
override val email: String = "Email"
override val email_blank_error: String = "Email necesar."
override val email_error: String = "Adresa de e-mail invalidă."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Vă mulțumim pentru afacerea dvs. Pentru a vizualiza întregul ${documentType} faceți clic pe butonul de la sfârșitul acestei scrisori. Rețineți că este valabil până la ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Ați primit un nou ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "VEZI ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Dragă ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Vă mulțumim pentru afacerea dvs. Pentru a vizualiza întregul ${documentType} faceți clic pe butonul de la sfârșitul acestei scrisori. Vă rugăm să plătiți soldul până la ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Ați primit un nou ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Ați primit un e-mail de resetare a parolei, vă rugăm să verificați căsuța poștală."
override val empty_clients_list_message: String = "Nu aveți clienți de urmărit. Adăugați un nou client."
override val empty_estimate_list_message: String = "Creați prima dvs. estimare"
override val empty_expenses: String = "Nu aveți cheltuieli de urmărit. Adăugați o nouă cheltuială."
override val empty_invoice_list_message: String = "Creați prima dumneavoastră factură"
override val empty_items_list_message: String = "Nu aveți niciun articol, adăugați un articol nou!"
override val empty_signatures_list_message: String = "Nu s-au găsit semnături. Creați una!"
override val empty_taxes_list_message: String = "Nu aveți norme fiscale. Adăugați o nouă regulă fiscală."
override val empty_templates_list_message: String = "Modele indisponibile."
override val enabled: String = "Activat"
override val entry: String = "înregistrare"
override val equity: String = "capitaluri proprii"
override val estimate_number: String = "Estimare #"
override val estimates: String = "Estimări"
override val estimates_report: String = "Estimări"
override val expense_bank_fees: String = "Comisioane Bancare"
override val expense_benefits: String = "Beneficii"
override val expense_communication: String = "Comunicare"
override val expense_debt: String = "Datoria"
override val expense_deleted: String = "Cheltuieli șterse"
override val expense_depreciation: String = "Depreciere"
override val expense_gifts: String = "Cadouri"
override val expense_insurance: String = "Asigurare"
override val expense_inventory: String = "Inventar"
override val expense_legal: String = "Legal"
override val expense_maintenance: String = "Întreținere"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Mese"
override val expense_office_supplies: String = "Consumabile de Birou"
override val expense_rent: String = "Chirie"
override val expense_salaries: String = "Salarii"
override val expense_shopping: String = "Cumpărături"
override val expense_software: String = "Software"
override val expense_status_available: String = "Disponibil"
override val expense_status_processing: String = "Se procesează"
override val expense_tax: String = "Impozit"
override val expense_training: String = "Pregătire"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Călătorii"
override val expense_utility: String = "Utilitate"
override val expenses: String = "Cheltuieli"
override val feedback_option: String = "Feedback"
override val filter: String = "Filtru"
override val first_name_hint: String = "Primul nume"
override val forgot_password: String = "Resetare parolă"
override val forgot_password_label: String = "Am uitat parola"
override val from: String = "de la"
override val general_error: String = "A apărut o eroare, vă rugăm să încercați din nou mai târziu."
override val general_ledger: String = "registrul general"
override val go_back_label: String = "Înapoi"
override val group_by: String = "Grup de:"
override val image_view: String = "Imagine"
override val install: String = "Instalați"
override val invalid_credentials_error: String = "Acreditare invalidă."
override val invalid_email_error: String = "E-mail invalid."
override val invalid_user_error: String = "Acreditive de utilizator nevalabile."
override val invoice_deleted_message: String = "Factură ștearsă"
override val invoice_menu: String = "Meniul Factura"
override val invoice_number: String = "Factura #"
override val invoices: String = "Facturi"
override val items_option: String = "Articole"
override val keep: String = "Păstrați"
override val language_selector: Formattable = Formattable { (selected) -> "Limba ${selected}" }
override val last_name_hint: String = "Numele de familie"
override val ledger: String = "registrul contabil"
override val legal: String = "Legal"
override val legal_error: String = "Trebuie să accepte Termenii de utilizare și Politica de confidențialitate"
override val liabilities: String = "pasive"
override val loading: String = "Încărcare"
override val login_required_error: String = "Este necesară autentificarea."
override val logo_view: String = "Logo"
override val logout: String = "Logout"
override val logout_prompt: String = "Datele dvs. nu sunt sincronizate cu cloud-ul. Ștergeți facturile, estimările, clienții și alte informații la deconectare?"
override val main_screen: String = "Ecranul principal"
override val mark_paid: String = "Marcați ca fiind plătită"
override val message: String = "Mesaj"
override val missing_sender_msg: String = "Un document fără informații despre expeditor poate fi invalid."
override val missing_sender_title: String = "Lipsesc informații despre expeditor"
override val month_april: String = "Aprilie"
override val month_april_short: String = "Aprilie"
override val month_august: String = "August"
override val month_august_short: String = "Aug."
override val month_december: String = "Decembrie"
override val month_december_short: String = "Dec."
override val month_february: String = "Februarie"
override val month_february_short: String = "Feb."
override val month_january: String = "Ianuarie"
override val month_january_short: String = "Ian."
override val month_july: String = "Iulie"
override val month_july_short: String = "Iulie"
override val month_june: String = "Iunie"
override val month_june_short: String = "Iunie"
override val month_march: String = "Martie"
override val month_march_short: String = "Martie"
override val month_may: String = "Mai"
override val month_may_short: String = "Mai"
override val month_november: String = "Noiembrie"
override val month_november_short: String = "Noi."
override val month_october: String = "Octombrie"
override val month_october_short: String = "Oct."
override val month_september: String = "Septembrie"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Nu s-a reușit verificarea."
override val new: String = "Nou"
override val news: String = "Știri"
override val next: String = "Următorul"
override val no: String = "nu"
override val no_data_available: String = "Nu există date disponibile"
override val no_search_results: String = "Ne pare rău, nu am găsit niciun rezultat."
override val none: String = "Niciunul"
override val note_fields: String = "Note"
override val note_hint: String = "Notă"
override val notifications_option: String = "Notificări"
override val ok: String = "OK"
override val onboarding: String = "Îmbarcarea la bord"
override val onboarding_aboutus_message: String = "Părțile esențiale ale DingVoice sunt gratuite pentru totdeauna. Misiunea noastră este să vă ajutăm să vă dezvoltați afacerea, ajutați-ne să ne îmbunătățim prin împărtășirea feedback-ului dumneavoastră."
override val onboarding_aboutus_title: String = "Suntem aici pentru a vă ajuta"
override val onboarding_paid_1: String = "Accesați șabloane cu aspect profesional"
override val onboarding_paid_2: String = "Aruncați o privire mai atentă asupra situației financiare"
override val onboarding_paid_3: String = "Rezolvarea rapidă a problemelor"
override val onboarding_paid_4: String = "Păstrați-vă documentele în siguranță"
override val onboarding_paid_5: String = "Rămâneți la curent cu modificările de stare a facturilor"
override val onboarding_paid_title: String = "Creșteți și mai repede cu..."
override val onboarding_paywall_message: String = "Bucurați-vă acum de toate caracteristicile premium la cel mai bun preț."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Doar ${currencySymbol}${introAmount} pentru ${introDuration} ${introPeriod} și apoi ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, durata) -> "Doar ${currencySymbol}${amount} / ${durata}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratuit pentru ${trialDuration} ${trialPeriod} și apoi doar ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Liber pentru ${trialDuration} ${trialPeriod} apoi ${currencySymbol}${introductoryAmount} pentru ${introductoryDuration} ${introPeriod} și apoi doar ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Auto-regenerabil. Anulați oricând."
override val onboarding_paywall_restore: String = "Restabilirea achiziției"
override val onboarding_paywall_restore_failure: String = "Restaurarea achizițiilor a eșuat, încercați din nou mai târziu sau contactați serviciul de asistență."
override val onboarding_paywall_restore_success: String = "Restaurare reușită!"
override val onboarding_paywall_title: String = "Atingeți măreția!"
override val onboarding_welcome_message: String = "Un set complet de instrumente pentru a vă gestiona facturile și estimările."
override val onboarding_welcome_title: String = "Bine ați venit la DingVoice"
override val other_state: String = "Alte"
override val overdue_state: String = "Întârziat"
override val paid_state: String = "Plătită"
override val password: String = "Parola"
override val password_blank_error: String = "Parola este necesară."
override val password_mismatch_error: String = "Parolele nu se potrivesc."
override val pay: String = "plăti"
override val payment_instructions_label: String = "Instrucțiuni de plată"
override val payment_reminders: String = "Rapoarte de plată"
override val phone_number_fields: String = "Număr de telefon"
override val phone_number_hint: String = "Număr de telefon"
override val postcode_hint: String = "Cod poștal"
override val preview: String = "Previzualizare"
override val preview_menu_hint: String = "Meniu de previzualizare"
override val price: String = "Preț"
override val privacy_policy: String = "Politica de confidențialitate"
override val profile: String = "Profil"
override val profitable_client: String = "Cel mai profitabil client al tău este..."
override val profits_report: String = "Profiturile"
override val promo_content: String = "Conținut promoțional"
override val quantity_hint: String = "Cantitate"
override val quantity_label_hint: String = "Etichetă de cantitate"
override val query_info: String = "Query vă permite să scrieți calcule personalizate care se referă atât la articole, cât și la alte norme fiscale ca intrări. Adăugați simbolul @ și descrierea articolului sau a taxei pentru a face legătura cu o altă intrare +, -, *, /, % - operatori matematici"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} a fost primit de clientul dvs." }
override val received_state: String = "Primită"
override val registration_code_fields: String = "Cod de înregistrare"
override val reminder: String = "Memento"
override val reports: String = "Rapoarte"
override val reports_not_available: String = "Nu există rapoarte disponibile, reveniți mai târziu."
override val reveal_password: String = "Dezvăluiți parola"
override val revenue: String = "venituri"
override val sales_tax: String = "TVA"
override val sales_tax_number: String = "Număr TVA"
override val save: String = "Salvați"
override val scan_document: String = "Scanați documentul"
override val search: String = "Căutare"
override val select: String = "Selectați"
override val select_icon: String = "Selectați o pictogramă"
override val select_signature: String = "Selectați semnătura"
override val select_template: String = "Selectați șablonul"
override val send: String = "Trimiteți"
override val sent_state: String = "Trimis"
override val setup_sender: String = "Configurați expeditorul"
override val share_option: String = "Share"
override val ship_to: String = "Trimiteți la"
override val sign_in: String = "Conectați-vă"
override val sign_in_apple: String = "Conectați-vă cu Apple"
override val sign_in_email: String = "Autentificați-vă cu e-mail"
override val sign_in_facebook: String = "Conectați-vă cu Facebook"
override val sign_in_google: String = "Conectați-vă cu Google"
override val sign_up: String = "Înscrieți-vă"
override val sign_up_label: String = "Nu aveți un cont?"
override val sign_up_link_label: String = "Înscrieți-vă!"
override val signatures_option: String = "Semnături"
override val skip: String = "Skip"
override val sold_items_report: String = "Articole Vândute"
override val sort_by: String = "Sortează după:"
override val status_update: String = "Actualizarea stării documentului"
override val sub_annual: String = "Anual"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/an" }
override val sub_backups: String = "Copii de rezervă în cloud"
override val sub_insights: String = "Insights"
override val sub_month: String = "Lunar"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/lună" }
override val sub_notifications: String = "Notificări"
override val sub_popup_message: String = "Abonamentele vor fi disponibile în curând. Reveniți aici peste puțin timp."
override val sub_support: String = "Asistență"
override val sub_templates: String = "Șabloane"
override val subscribe: String = "Abonează-te la"
override val subscription_header: String = "Dezvoltă-ți afacerea cu:"
override val subscription_option: String = "Abonament"
override val subscriptions_load_fail: String = "Ne pare rău, nu putem recupera abonamentele dvs. în acest moment. Vă rugăm să încercați din nou mai târziu sau să contactați echipa noastră de asistență pentru asistență."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "Subtotal"
override val sum: String = "Suma"
override val support_option: String = "Suport"
override val switch_reports: String = "Comutare Raport"
override val tax: String = "Impozit"
override val tax_deleted_message: String = "Impozit eliminat"
override val tax_description_warning: String = "În cazul în care este utilizat, acest lucru apare pe o factură"
override val tax_input_flat: String = "Flat"
override val tax_input_percent: String = "Procent"
override val tax_number_fields: String = "Număr de impozit"
override val tax_number_hint: String = "Număr de impozit"
override val tax_query_info_description: String = "Informații de interogare"
override val tax_report: String = "Impozit"
override val tax_rules_option: String = "Reguli fiscale"
override val taxed_clients_report: String = "Clienți impozitați"
override val template: String = "Șablon"
override val template_selector_info: String = "Glisați pentru a schimba șablonul implicit"
override val template_unlock_promo: String = "Accesați mai multe șabloane cu Premium"
override val templates_option: String = "Șabloane"
override val terms_of_service: String = "Termeni și condiții de utilizare"
override val thank_you: String = "Mulțumim"
override val to: String = "la"
override val total: Formattable = Formattable { (sum) -> "Total: ${sum}" }
override val total_label: String = "Total"
override val undo_action: String = "Desfaceți"
override val unit: String = "Unitatea"
override val unit_cost_hint: String = "Costul unitar"
override val unknown_client: String = "Client necunoscut"
override val unlock: String = "Deblocare"
override val unnamed_item: String = "Element fără nume"
override val upgrade: String = "Actualizare"
override val user_collision_error: String = "Contul există deja."
override val valid_until: String = "Valabil până la"
override val warning: String = "Atenție!"
override val yes: String = "da"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Acceptați ${termsOfService} și ${privacyPolicy}" },
	"account" to "cont",
	"accounting" to "contabilitate",
	"accruals" to "acumulări",
	"action_code_error" to "Cod invalid.",
	"add_client_action" to "Adăugați clientul",
	"add_customer" to "Adăugați clientul",
	"add_expense" to "Adăugați cheltuieli",
	"add_fields_label" to "Adăugați:",
	"add_invoice_action" to "Creați",
	"add_item_action" to "Adăugați elementul",
	"add_item_button" to "Adăugați elementul",
	"add_signature_action" to "Adăugați semnătura",
	"add_tax_action" to "Adăugați impozitul",
	"address_fields" to "Adresa",
	"address_line_hint" to "Linia de adresă",
	"advanced" to "AVANSAT",
	"agree_marketing" to "Sunt de acord să primesc comunicări de marketing direct",
	"all_notifications" to "Toate notificările",
	"amortization" to "amortizare",
	"amount" to "Suma",
	"asset" to "activ",
	"attach_file" to "Atașați un Fișier",
	"attachment" to "Atașament",
	"auth_error" to "Acțiunea a eșuat, încercați din nou.",
	"available_for_paid" to "Sincronizarea în cloud este disponibilă numai în cadrul unui abonament plătit.",
	"backups_option" to "Copii de rezervă",
	"balance" to "sold",
	"bank_account" to "Cont Bancar",
	"bill_number" to "Număr de Factură",
	"cancel" to "Anulează",
	"cancel_trial" to "Anulați în orice moment",
	"capital" to "capital",
	"cash" to "numerar",
	"categories" to "Categorii",
	"category" to "Categorie",
	"category_add" to "Adăugați o categorie",
	"category_delete" to "Ștergeți categoria",
	"category_name" to "Numele categoriei",
	"change_language" to Formattable { (language) -> "Limba facturii: ${language}" },
	"change_language_label" to "Limba Facturii",
	"change_template" to "Modificați șablonul",
	"city_hint" to "Orașul",
	"clear" to "Clar",
	"client" to "Client",
	"client_actions" to "Acțiuni ale clienților",
	"client_deleted_message" to "Client șters",
	"clients_error_required_fields" to "Este necesară cel puțin o valoare.",
	"clients_option" to "Clienți",
	"close" to "Închideți",
	"cloud_sync" to "Sincronizare în cloud",
	"coming_soon" to "În curând!",
	"company_logo" to "Logo-ul companiei",
	"company_name_hint" to "Compania",
	"confirm_password_label" to "Confirmați parola",
	"confirmation_title" to "Ești sigur(ă)?",
	"convert_to_invoice" to "Conversia în factură",
	"country_hint" to "Țara",
	"create_client" to "Creați un client",
	"create_company" to "Creați compania",
	"create_document" to "Creați documentul",
	"create_estimate" to "Estimare",
	"create_invoice" to "Factura",
	"create_item" to "Creați elementul",
	"create_new_client" to "Creați un nou client",
	"create_new_item" to "Creați un nou element",
	"create_new_tax" to "Crearea unui nou impozit",
	"create_signature" to "Semnătură",
	"created" to "a creat",
	"credit" to "de credit",
	"customer" to "Client",
	"date" to "Data",
	"debit" to "de debit",
	"debt" to "datorie",
	"default_currency" to Formattable { (currency) -> "Moneda implicită: ${currency}" },
	"default_currency_label" to "Monedă implicită",
	"delete" to "Ștergeți",
	"delete_account" to "Ștergeți Contul",
	"delete_confirmation_message" to "Toate datele dvs. se vor pierde.",
	"delete_item_title" to "Ștergeți articolul?",
	"delete_signature" to "Ștergeți semnătura",
	"depreciation" to "depreciere",
	"description" to "Descriere",
	"description_hint" to "Descriere",
	"detailed_report" to "Raport Detaliat",
	"disabled" to "Persoane cu handicap",
	"discount" to "Reducere",
	"discount_hint" to "Reducere",
	"display_af" to "Afrikaans",
	"display_am" to "Amharic",
	"display_ar" to "Arabă",
	"display_as" to "Assamese",
	"display_az" to "Azerbaidjan",
	"display_be" to "Bielorusă",
	"display_bg" to "Bulgară",
	"display_bn" to "Bengali",
	"display_bs" to "Bosniacă",
	"display_ca" to "Catalan",
	"display_cs" to "Cehia",
	"display_da" to "Daneză",
	"display_de" to "Germană",
	"display_el" to "Greacă",
	"display_en" to "Engleză",
	"display_es" to "Spaniolă",
	"display_es_MX" to "Spaniolă (Mexic)",
	"display_et" to "Estoniană",
	"display_eu" to "Bască",
	"display_fa" to "Persană",
	"display_fi" to "Finlandeză",
	"display_fil" to "Filipineză",
	"display_fr" to "Franceză",
	"display_fr_CA" to "Franceză canadiană",
	"display_gl" to "Galiciană",
	"display_gu" to "Gujarati",
	"display_he" to "Ebraică",
	"display_hi" to "Hindi",
	"display_hr" to "Croată",
	"display_hu" to "Maghiară",
	"display_hy" to "Armenească",
	"display_id" to "Indoneziană",
	"display_is" to "Islandeză",
	"display_it" to "Italian",
	"display_ja" to "Japoneză",
	"display_ka" to "Georgian",
	"display_kk" to "Kazahstan",
	"display_km" to "Central Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Coreeană",
	"display_ky" to "Kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Lituaniene",
	"display_lv" to "Letonă",
	"display_mk" to "Macedoneană",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolă",
	"display_mr" to "Marathi",
	"display_ms" to "Malay",
	"display_my" to "Birmaneză",
	"display_nb" to "Norvegiană Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Olandeză",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Poloneză",
	"display_pt" to "Portugheză",
	"display_pt_BR" to "Portugheză (Brazilia)",
	"display_pt_PT" to "Portugheză europeană",
	"display_ro" to "Românesc",
	"display_ru" to "Rusă",
	"display_si" to "Sinhala",
	"display_sk" to "Slovacă",
	"display_sl" to "Slovenă",
	"display_sq" to "Albaneză",
	"display_sr" to "Sârbă",
	"display_sv" to "Suedeză",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Turcă",
	"display_uk" to "Ucraineană",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbecă",
	"display_vi" to "Vietnameză",
	"display_zh_Hant_TW" to "Chineză (Taiwan, Tradițională)",
	"display_zu" to "Zulu",
	"document_estimate" to "Estimare",
	"document_invoice" to "Factura",
	"document_quote" to "Oferta",
	"done" to "Realizat",
	"download_app" to "Descărcați aplicația",
	"download_invoice" to "Descărcați Factură",
	"download_pdf" to "Descarcă PDF",
	"draw_signature" to "Desenați o semnătură",
	"due" to Formattable { (date) -> "Cu scadență ${date}" },
	"due_date" to "Data limită",
	"due_day" to "într-o zi",
	"due_label" to "Datorita",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} este datorat ${dueWhen}!" },
	"due_today" to "astăzi",
	"due_week" to "într-o săptămână",
	"duplicate" to "Duplicați",
	"duration_day" to "zi",
	"duration_month" to "luna",
	"duration_week" to "săptămână",
	"duration_year" to "an",
	"email" to "Email",
	"email_blank_error" to "Email necesar.",
	"email_error" to "Adresa de e-mail invalidă.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Vă mulțumim pentru afacerea dvs. Pentru a vizualiza întregul ${documentType} faceți clic pe butonul de la sfârșitul acestei scrisori. Rețineți că este valabil până la ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Ați primit un nou ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "VEZI ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Dragă ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Vă mulțumim pentru afacerea dvs. Pentru a vizualiza întregul ${documentType} faceți clic pe butonul de la sfârșitul acestei scrisori. Vă rugăm să plătiți soldul până la ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Ați primit un nou ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Ați primit un e-mail de resetare a parolei, vă rugăm să verificați căsuța poștală.",
	"empty_clients_list_message" to "Nu aveți clienți de urmărit. Adăugați un nou client.",
	"empty_estimate_list_message" to "Creați prima dvs. estimare",
	"empty_expenses" to "Nu aveți cheltuieli de urmărit. Adăugați o nouă cheltuială.",
	"empty_invoice_list_message" to "Creați prima dumneavoastră factură",
	"empty_items_list_message" to "Nu aveți niciun articol, adăugați un articol nou!",
	"empty_signatures_list_message" to "Nu s-au găsit semnături. Creați una!",
	"empty_taxes_list_message" to "Nu aveți norme fiscale. Adăugați o nouă regulă fiscală.",
	"empty_templates_list_message" to "Modele indisponibile.",
	"enabled" to "Activat",
	"entry" to "înregistrare",
	"equity" to "capitaluri proprii",
	"estimate_number" to "Estimare #",
	"estimates" to "Estimări",
	"estimates_report" to "Estimări",
	"expense_bank_fees" to "Comisioane Bancare",
	"expense_benefits" to "Beneficii",
	"expense_communication" to "Comunicare",
	"expense_debt" to "Datoria",
	"expense_deleted" to "Cheltuieli șterse",
	"expense_depreciation" to "Depreciere",
	"expense_gifts" to "Cadouri",
	"expense_insurance" to "Asigurare",
	"expense_inventory" to "Inventar",
	"expense_legal" to "Legal",
	"expense_maintenance" to "Întreținere",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Mese",
	"expense_office_supplies" to "Consumabile de Birou",
	"expense_rent" to "Chirie",
	"expense_salaries" to "Salarii",
	"expense_shopping" to "Cumpărături",
	"expense_software" to "Software",
	"expense_status_available" to "Disponibil",
	"expense_status_processing" to "Se procesează",
	"expense_tax" to "Impozit",
	"expense_training" to "Pregătire",
	"expense_transport" to "Transport",
	"expense_travel" to "Călătorii",
	"expense_utility" to "Utilitate",
	"expenses" to "Cheltuieli",
	"feedback_option" to "Feedback",
	"filter" to "Filtru",
	"first_name_hint" to "Primul nume",
	"forgot_password" to "Resetare parolă",
	"forgot_password_label" to "Am uitat parola",
	"from" to "de la",
	"general_error" to "A apărut o eroare, vă rugăm să încercați din nou mai târziu.",
	"general_ledger" to "registrul general",
	"go_back_label" to "Înapoi",
	"group_by" to "Grup de:",
	"image_view" to "Imagine",
	"install" to "Instalați",
	"invalid_credentials_error" to "Acreditare invalidă.",
	"invalid_email_error" to "E-mail invalid.",
	"invalid_user_error" to "Acreditive de utilizator nevalabile.",
	"invoice_deleted_message" to "Factură ștearsă",
	"invoice_menu" to "Meniul Factura",
	"invoice_number" to "Factura #",
	"invoices" to "Facturi",
	"items_option" to "Articole",
	"keep" to "Păstrați",
	"language_selector" to Formattable { (selected) -> "Limba ${selected}" },
	"last_name_hint" to "Numele de familie",
	"ledger" to "registrul contabil",
	"legal" to "Legal",
	"legal_error" to "Trebuie să accepte Termenii de utilizare și Politica de confidențialitate",
	"liabilities" to "pasive",
	"loading" to "Încărcare",
	"login_required_error" to "Este necesară autentificarea.",
	"logo_view" to "Logo",
	"logout" to "Logout",
	"logout_prompt" to "Datele dvs. nu sunt sincronizate cu cloud-ul. Ștergeți facturile, estimările, clienții și alte informații la deconectare?",
	"main_screen" to "Ecranul principal",
	"mark_paid" to "Marcați ca fiind plătită",
	"message" to "Mesaj",
	"missing_sender_msg" to "Un document fără informații despre expeditor poate fi invalid.",
	"missing_sender_title" to "Lipsesc informații despre expeditor",
	"month_april" to "Aprilie",
	"month_april_short" to "Aprilie",
	"month_august" to "August",
	"month_august_short" to "Aug.",
	"month_december" to "Decembrie",
	"month_december_short" to "Dec.",
	"month_february" to "Februarie",
	"month_february_short" to "Feb.",
	"month_january" to "Ianuarie",
	"month_january_short" to "Ian.",
	"month_july" to "Iulie",
	"month_july_short" to "Iulie",
	"month_june" to "Iunie",
	"month_june_short" to "Iunie",
	"month_march" to "Martie",
	"month_march_short" to "Martie",
	"month_may" to "Mai",
	"month_may_short" to "Mai",
	"month_november" to "Noiembrie",
	"month_november_short" to "Noi.",
	"month_october" to "Octombrie",
	"month_october_short" to "Oct.",
	"month_september" to "Septembrie",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Nu s-a reușit verificarea.",
	"new" to "Nou",
	"news" to "Știri",
	"next" to "Următorul",
	"no" to "nu",
	"no_data_available" to "Nu există date disponibile",
	"no_search_results" to "Ne pare rău, nu am găsit niciun rezultat.",
	"none" to "Niciunul",
	"note_fields" to "Note",
	"note_hint" to "Notă",
	"notifications_option" to "Notificări",
	"ok" to "OK",
	"onboarding" to "Îmbarcarea la bord",
	"onboarding_aboutus_message" to "Părțile esențiale ale DingVoice sunt gratuite pentru totdeauna. Misiunea noastră este să vă ajutăm să vă dezvoltați afacerea, ajutați-ne să ne îmbunătățim prin împărtășirea feedback-ului dumneavoastră.",
	"onboarding_aboutus_title" to "Suntem aici pentru a vă ajuta",
	"onboarding_paid_1" to "Accesați șabloane cu aspect profesional",
	"onboarding_paid_2" to "Aruncați o privire mai atentă asupra situației financiare",
	"onboarding_paid_3" to "Rezolvarea rapidă a problemelor",
	"onboarding_paid_4" to "Păstrați-vă documentele în siguranță",
	"onboarding_paid_5" to "Rămâneți la curent cu modificările de stare a facturilor",
	"onboarding_paid_title" to "Creșteți și mai repede cu...",
	"onboarding_paywall_message" to "Bucurați-vă acum de toate caracteristicile premium la cel mai bun preț.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Doar ${currencySymbol}${introAmount} pentru ${introDuration} ${introPeriod} și apoi ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, durata) -> "Doar ${currencySymbol}${amount} / ${durata}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratuit pentru ${trialDuration} ${trialPeriod} și apoi doar ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Liber pentru ${trialDuration} ${trialPeriod} apoi ${currencySymbol}${introductoryAmount} pentru ${introductoryDuration} ${introPeriod} și apoi doar ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Auto-regenerabil. Anulați oricând.",
	"onboarding_paywall_restore" to "Restabilirea achiziției",
	"onboarding_paywall_restore_failure" to "Restaurarea achizițiilor a eșuat, încercați din nou mai târziu sau contactați serviciul de asistență.",
	"onboarding_paywall_restore_success" to "Restaurare reușită!",
	"onboarding_paywall_title" to "Atingeți măreția!",
	"onboarding_welcome_message" to "Un set complet de instrumente pentru a vă gestiona facturile și estimările.",
	"onboarding_welcome_title" to "Bine ați venit la DingVoice",
	"other_state" to "Alte",
	"overdue_state" to "Întârziat",
	"paid_state" to "Plătită",
	"password" to "Parola",
	"password_blank_error" to "Parola este necesară.",
	"password_mismatch_error" to "Parolele nu se potrivesc.",
	"pay" to "plăti",
	"payment_instructions_label" to "Instrucțiuni de plată",
	"payment_reminders" to "Rapoarte de plată",
	"phone_number_fields" to "Număr de telefon",
	"phone_number_hint" to "Număr de telefon",
	"postcode_hint" to "Cod poștal",
	"preview" to "Previzualizare",
	"preview_menu_hint" to "Meniu de previzualizare",
	"price" to "Preț",
	"privacy_policy" to "Politica de confidențialitate",
	"profile" to "Profil",
	"profitable_client" to "Cel mai profitabil client al tău este...",
	"profits_report" to "Profiturile",
	"promo_content" to "Conținut promoțional",
	"quantity_hint" to "Cantitate",
	"quantity_label_hint" to "Etichetă de cantitate",
	"query_info" to "Query vă permite să scrieți calcule personalizate care se referă atât la articole, cât și la alte norme fiscale ca intrări. Adăugați simbolul @ și descrierea articolului sau a taxei pentru a face legătura cu o altă intrare +, -, *, /, % - operatori matematici",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} a fost primit de clientul dvs." },
	"received_state" to "Primită",
	"registration_code_fields" to "Cod de înregistrare",
	"reminder" to "Memento",
	"reports" to "Rapoarte",
	"reports_not_available" to "Nu există rapoarte disponibile, reveniți mai târziu.",
	"reveal_password" to "Dezvăluiți parola",
	"revenue" to "venituri",
	"sales_tax" to "TVA",
	"sales_tax_number" to "Număr TVA",
	"save" to "Salvați",
	"scan_document" to "Scanați documentul",
	"search" to "Căutare",
	"select" to "Selectați",
	"select_icon" to "Selectați o pictogramă",
	"select_signature" to "Selectați semnătura",
	"select_template" to "Selectați șablonul",
	"send" to "Trimiteți",
	"sent_state" to "Trimis",
	"setup_sender" to "Configurați expeditorul",
	"share_option" to "Share",
	"ship_to" to "Trimiteți la",
	"sign_in" to "Conectați-vă",
	"sign_in_apple" to "Conectați-vă cu Apple",
	"sign_in_email" to "Autentificați-vă cu e-mail",
	"sign_in_facebook" to "Conectați-vă cu Facebook",
	"sign_in_google" to "Conectați-vă cu Google",
	"sign_up" to "Înscrieți-vă",
	"sign_up_label" to "Nu aveți un cont?",
	"sign_up_link_label" to "Înscrieți-vă!",
	"signatures_option" to "Semnături",
	"skip" to "Skip",
	"sold_items_report" to "Articole Vândute",
	"sort_by" to "Sortează după:",
	"status_update" to "Actualizarea stării documentului",
	"sub_annual" to "Anual",
	"sub_annual_price" to Formattable { (price) -> "${price}/an" },
	"sub_backups" to "Copii de rezervă în cloud",
	"sub_insights" to "Insights",
	"sub_month" to "Lunar",
	"sub_monthly_price" to Formattable { (price) -> "${price}/lună" },
	"sub_notifications" to "Notificări",
	"sub_popup_message" to "Abonamentele vor fi disponibile în curând. Reveniți aici peste puțin timp.",
	"sub_support" to "Asistență",
	"sub_templates" to "Șabloane",
	"subscribe" to "Abonează-te la",
	"subscription_header" to "Dezvoltă-ți afacerea cu:",
	"subscription_option" to "Abonament",
	"subscriptions_load_fail" to "Ne pare rău, nu putem recupera abonamentele dvs. în acest moment. Vă rugăm să încercați din nou mai târziu sau să contactați echipa noastră de asistență pentru asistență.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "Subtotal",
	"sum" to "Suma",
	"support_option" to "Suport",
	"switch_reports" to "Comutare Raport",
	"tax" to "Impozit",
	"tax_deleted_message" to "Impozit eliminat",
	"tax_description_warning" to "În cazul în care este utilizat, acest lucru apare pe o factură",
	"tax_input_flat" to "Flat",
	"tax_input_percent" to "Procent",
	"tax_number_fields" to "Număr de impozit",
	"tax_number_hint" to "Număr de impozit",
	"tax_query_info_description" to "Informații de interogare",
	"tax_report" to "Impozit",
	"tax_rules_option" to "Reguli fiscale",
	"taxed_clients_report" to "Clienți impozitați",
	"template" to "Șablon",
	"template_selector_info" to "Glisați pentru a schimba șablonul implicit",
	"template_unlock_promo" to "Accesați mai multe șabloane cu Premium",
	"templates_option" to "Șabloane",
	"terms_of_service" to "Termeni și condiții de utilizare",
	"thank_you" to "Mulțumim",
	"to" to "la",
	"total" to Formattable { (sum) -> "Total: ${sum}" },
	"total_label" to "Total",
	"undo_action" to "Desfaceți",
	"unit" to "Unitatea",
	"unit_cost_hint" to "Costul unitar",
	"unknown_client" to "Client necunoscut",
	"unlock" to "Deblocare",
	"unnamed_item" to "Element fără nume",
	"upgrade" to "Actualizare",
	"user_collision_error" to "Contul există deja.",
	"valid_until" to "Valabil până la",
	"warning" to "Atenție!",
	"yes" to "da",
    )
    
}