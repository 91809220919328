// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Zu  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Yamukela i-${termsOfService} kanye ne-${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Ikhodi engavumelekile."
override val add_client_action: String = "Engeza iklayenti"
override val add_customer: String = "Engeza ikhasimende"
override val add_expense: String = "Engeza izindleko"
override val add_fields_label: String = "Engeza:"
override val add_invoice_action: String = "Dala"
override val add_item_action: String = "Engeza into"
override val add_item_button: String = "Engeza into"
override val add_signature_action: String = "Engeza isiginesha"
override val add_tax_action: String = "Engeza intela"
override val address_fields: String = "Ikheli"
override val address_line_hint: String = "Ulayini we kheli"
override val advanced: String = "Okuthuthukile"
override val agree_marketing: String = "Ngiyavuma ukwamukela i-Direct Marketing Communications"
override val all_notifications: String = "Zonke Izaziso"
override val amortization: String = "amortization"
override val amount: String = "Inani"
override val asset: String = "asset"
override val attach_file: String = "Namathisela Ifayela"
override val attachment: String = "Okunamathiselwe"
override val auth_error: String = "Isenzo sihlulekile, zama futhi."
override val available_for_paid: String = "Ukuvumelanisa kwamafu kutholakala kuphela ngaphansi kokubhaliselwe okukhokhelwayo."
override val backups_option: String = "Izipele"
override val balance: String = "balance"
override val bank_account: String = "I-Akhawunti Yasebhange"
override val bill_number: String = "Inombolo Yomthethosivivinywa"
override val cancel: String = "Khansela"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Izigaba"
override val category: String = "Isigaba"
override val category_add: String = "Engeza Isigaba"
override val category_delete: String = "Susa isigaba"
override val category_name: String = "Igama lesigaba"
override val change_language: Formattable = Formattable { (language) -> "Ulimi lwe-invoyisi: ${language}" }
override val change_language_label: String = "Ulimi Lwe-invoyisi"
override val change_template: String = "Shintsha isifanekiso"
override val city_hint: String = "Idolobha"
override val clear: String = "Kucace"
override val client: String = "Iklayenti"
override val client_actions: String = "Izenzo zeklayenti"
override val client_deleted_message: String = "Iklayenti lisusiwe"
override val clients_error_required_fields: String = "Kudingeka okungenani inani elilodwa."
override val clients_option: String = "Amaklayenti"
override val close: String = "Vala"
override val cloud_sync: String = "Ukuvumelanisa Kwamafu"
override val coming_soon: String = "Kuyeza maduze!"
override val company_logo: String = "Ilogo Yenkampani"
override val company_name_hint: String = "Inkampani"
override val confirm_password_label: String = "Qinisekisa Iphasiwedi"
override val confirmation_title: String = "Uqinisekile?"
override val convert_to_invoice: String = "Guqulela ku-invoyisi"
override val country_hint: String = "Izwe"
override val create_client: String = "Dala iKlayenti"
override val create_company: String = "Dala Inkampani"
override val create_document: String = "Dala idokhumenti"
override val create_estimate: String = "Linganisa"
override val create_invoice: String = "I-invoyisi"
override val create_item: String = "Dala Into"
override val create_new_client: String = "Dala iklayenti elisha"
override val create_new_item: String = "Dala into entsha"
override val create_new_tax: String = "Dala intela entsha"
override val create_signature: String = "Isiginesha"
override val created: String = "Kwakhiwe"
override val credit: String = "credit"
override val customer: String = "Ikhasimende"
override val date: String = "Usuku"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Imali ezenzakalelayo: ${currency}" }
override val default_currency_label: String = "Imali ezenzakalelayo"
override val delete: String = "Susa"
override val delete_account: String = "Susa i-akhawunti"
override val delete_confirmation_message: String = "Yonke idatha yakho izolahleka."
override val delete_item_title: String = "Susa into?"
override val delete_signature: String = "Susa isiginesha"
override val depreciation: String = "depreciation"
override val description: String = "Incazelo"
override val description_hint: String = "Incazelo"
override val detailed_report: String = "Umbiko onemininingwane"
override val disabled: String = "Kukhutshaziwe"
override val discount: String = "Isaphulelo"
override val discount_hint: String = "Isaphulelo"
override val display_af: String = "umAfrika"
override val display_am: String = "Isi-Amharic"
override val display_ar: String = "Isi-Arabhu"
override val display_as: String = "Isi-Assamese"
override val display_az: String = "Isi-Azerbaijani"
override val display_be: String = "IsiBelarusian"
override val display_bg: String = "IsiBulgaria"
override val display_bn: String = "Isi-Bengali"
override val display_bs: String = "Isi-Bosnia"
override val display_ca: String = "IsiCatalan"
override val display_cs: String = "IsiCzech"
override val display_da: String = "IsiDanish"
override val display_de: String = "IsiJalimane"
override val display_el: String = "isiGreki"
override val display_en: String = "IsiNgisi"
override val display_es: String = "ISpanishi"
override val display_es_MX: String = "Isi-Spanish (Mexico)"
override val display_et: String = "Isi-Estonian"
override val display_eu: String = "Isi-Basque"
override val display_fa: String = "isiPheresiya"
override val display_fi: String = "IsiFinnish"
override val display_fil: String = "IsiFilipino"
override val display_fr: String = "IsiFulentshi"
override val display_fr_CA: String = "IsiFulentshi saseCanada"
override val display_gl: String = "IsiGalicia"
override val display_gu: String = "IsiGujarati"
override val display_he: String = "IsiHebheru"
override val display_hi: String = "Cha"
override val display_hr: String = "IsiCroatia"
override val display_hu: String = "IsiHungary"
override val display_hy: String = "Isi-Armenian"
override val display_id: String = "Isi-Indonesian"
override val display_is: String = "Isi-Icelandic"
override val display_it: String = "IsiNtaliyane"
override val display_ja: String = "IsiJapane"
override val display_ka: String = "IsiGeorgia"
override val display_kk: String = "Isi-Kazakh"
override val display_km: String = "I-Central Khmer"
override val display_kn: String = "Isi-Kannada"
override val display_ko: String = "IsiKorea"
override val display_ky: String = "IsiKirghiz"
override val display_lo: String = "Umsebenzi"
override val display_lt: String = "IsiLithuanian"
override val display_lv: String = "Isi-Latvian"
override val display_mk: String = "IsiMacedonian"
override val display_ml: String = "Isi-Malayalam"
override val display_mn: String = "IsiMongolia"
override val display_mr: String = "IsiMarathi"
override val display_ms: String = "Isi-Malay"
override val display_my: String = "IsiBurma"
override val display_nb: String = "Isi-Norwegian Bokmål"
override val display_ne: String = "IsiNepali"
override val display_nl: String = "IsiDashi"
override val display_or: String = "Isi-Oriya"
override val display_pa: String = "IsiPunjabi"
override val display_pl: String = "IsiPolish"
override val display_pt: String = "IsiPutukezi"
override val display_pt_BR: String = "Isi-Portuguese (Brazil)"
override val display_pt_PT: String = "IsiPutukezi saseYurophu"
override val display_ro: String = "IsiRomania"
override val display_ru: String = "IsiRashiya"
override val display_si: String = "IsiSinhala"
override val display_sk: String = "IsiSlovak"
override val display_sl: String = "IsiSlovenian"
override val display_sq: String = "Isi-Albania"
override val display_sr: String = "IsiSerbia"
override val display_sv: String = "IsiSwidi"
override val display_sw: String = "IsiSwahili"
override val display_ta: String = "IsiTamil"
override val display_te: String = "Isi-Telugu"
override val display_th: String = "IsiThai"
override val display_tr: String = "IsiTurkey"
override val display_uk: String = "Isi-Ukraine"
override val display_ur: String = "Isi-Urdu"
override val display_uz: String = "Isi-Uzbek"
override val display_vi: String = "Isi-Vietnamese"
override val display_zh_Hant_TW: String = "IsiShayina (Taiwan, Traditional)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Linganisa"
override val document_invoice: String = "I-invoyisi"
override val document_quote: String = "Quote"
override val done: String = "Kwenziwe"
override val download_app: String = "Landa uhlelo lokusebenza"
override val download_invoice: String = "Landa i-invoyisi"
override val download_pdf: String = "Landa i-PDF"
override val draw_signature: String = "Dweba isiginesha"
override val due: Formattable = Formattable { (date) -> "Ifuneka ngo-${date}" }
override val due_date: String = "Usuku olumisiwe"
override val due_day: String = "ngosuku"
override val due_label: String = "Ifuneka"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ifuneka ngo-${dueWhen}!" }
override val due_today: String = "namuhla"
override val due_week: String = "ngeviki"
override val duplicate: String = "Impinda"
override val duration_day: String = "usuku"
override val duration_month: String = "Inyanga"
override val duration_week: String = "isonto"
override val duration_year: String = "unyaka"
override val email: String = "Imeyili"
override val email_blank_error: String = "I-imeyili iyadingeka."
override val email_error: String = "Ikheli le-imeyili elingavumelekile."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Siyabonga ngebhizinisi lakho. Ukuze ubuke i-${documentType} egcwele chofoza inkinobho ekugcineni kwale ncwadi. Qaphela ukuthi ivumeleke kuze kube ngu-${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Uthole okusha${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "BUKA ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "${clientName} othandekayo" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Siyabonga ngebhizinisi lakho. Ukuze ubuke i-${documentType} egcwele chofoza inkinobho ekugcineni kwale ncwadi. Sicela ukhokhe ibhalansi kuze kube ngu-${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Uthole okusha${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "I-imeyili yokusetha kabusha iphasiwedi ithunyelwe, sicela uhlole ibhokisi lakho lokungenayo."
override val empty_clients_list_message: String = "Awunawo amaklayenti ozowalandelela. Engeza iklayenti elisha."
override val empty_estimate_list_message: String = "Dala isilinganiso sakho sokuqala"
override val empty_expenses: String = "Awunazo izindleko zokulandelela. Engeza izindleko ezintsha."
override val empty_invoice_list_message: String = "Dala i-invoyisi yakho yokuqala"
override val empty_items_list_message: String = "Awunazo izinto, engeza into entsha!"
override val empty_signatures_list_message: String = "Awekho amasiginesha atholiwe. Dala eyodwa!"
override val empty_taxes_list_message: String = "Awunayo imithetho yentela. Engeza umthetho omusha wentela."
override val empty_templates_list_message: String = "Izifanekiso azitholakali."
override val enabled: String = "Inikwe amandla"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Linganisela #"
override val estimates: String = "Izilinganiso"
override val estimates_report: String = "Izilinganiso"
override val expense_bank_fees: String = "Imali Yebhange"
override val expense_benefits: String = "Izinzuzo"
override val expense_communication: String = "Ukuxhumana"
override val expense_debt: String = "Isikweletu"
override val expense_deleted: String = "Izindleko zisusiwe"
override val expense_depreciation: String = "Ukwehla kwenani"
override val expense_gifts: String = "Izipho"
override val expense_insurance: String = "Umshwalense"
override val expense_inventory: String = "Inventory"
override val expense_legal: String = "Okusemthethweni"
override val expense_maintenance: String = "Isondlo"
override val expense_marketing: String = "Ukumaketha"
override val expense_meals: String = "Ukudla"
override val expense_office_supplies: String = "Izinsiza zasehhovisi"
override val expense_rent: String = "Qasha"
override val expense_salaries: String = "Amaholo"
override val expense_shopping: String = "Ukuthenga"
override val expense_software: String = "Isofthiwe"
override val expense_status_available: String = "Iyatholakala"
override val expense_status_processing: String = "Iyacubungula"
override val expense_tax: String = "Intela"
override val expense_training: String = "Ukuqeqeshwa"
override val expense_transport: String = "Ezokuthutha"
override val expense_travel: String = "Ukuhamba"
override val expense_utility: String = "Isisetshenziswa"
override val expenses: String = "Izindleko"
override val feedback_option: String = "Impendulo"
override val filter: String = "Hlunga"
override val first_name_hint: String = "Igama"
override val forgot_password: String = "Setha kabusha iphasiwedi"
override val forgot_password_label: String = "Ukhohlwe iphasiwedi"
override val from: String = "Kusuka"
override val general_error: String = "Kuvele iphutha, sicela uzame futhi emuva kwesikhathi."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Buyela emuva"
override val group_by: String = "Iqembu Nge:"
override val image_view: String = "Isithombe"
override val install: String = "Faka"
override val invalid_credentials_error: String = "Ukuqinisekisa okungavumelekile."
override val invalid_email_error: String = "I-imeyili engavumelekile."
override val invalid_user_error: String = "Imininingwane yomsebenzisi engavumelekile."
override val invoice_deleted_message: String = "I-invoyisi isusiwe"
override val invoice_menu: String = "Imenyu ye-invoyisi"
override val invoice_number: String = "I-invoyisi #"
override val invoices: String = "Ama-invoyisi"
override val items_option: String = "Izinto"
override val keep: String = "Gcina"
override val language_selector: Formattable = Formattable { (okukhethiwe) -> "Ulimi ${okukhethiwe}" }
override val last_name_hint: String = "Isibongo"
override val ledger: String = "ledger"
override val legal: String = "Okusemthethweni"
override val legal_error: String = "Kufanele wamukele Imigomo Yesevisi kanye Nenqubomgomo Yobumfihlo"
override val liabilities: String = "liabilities"
override val loading: String = "Iyalayisha"
override val login_required_error: String = "Kudingeka ukungena ngemvume."
override val logo_view: String = "Ilogo"
override val logout: String = "Phuma"
override val logout_prompt: String = "Idatha yakho ayivumelaniswanga nefu. Susa ama-invoyisi, izilinganiso, amaklayenti nolunye ulwazi ekuphumeni?"
override val main_screen: String = "Isikrini esikhulu"
override val mark_paid: String = "Maka njengeKhokhiwe"
override val message: String = "Umlayezo"
override val missing_sender_msg: String = "Idokhumenti engenalo ulwazi lomthumeli ingase ingavumelekile."
override val missing_sender_title: String = "Ulwazi lomthumeli alukho"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "Agasti"
override val month_august_short: String = "Aug."
override val month_december: String = "Disemba"
override val month_december_short: String = "Dec."
override val month_february: String = "February"
override val month_february_short: String = "Feb."
override val month_january: String = "January"
override val month_january_short: String = "Jan."
override val month_july: String = "July"
override val month_july_short: String = "July"
override val month_june: String = "Juni"
override val month_june_short: String = "Juni"
override val month_march: String = "Mashi"
override val month_march_short: String = "Mashi"
override val month_may: String = "Meyi"
override val month_may_short: String = "Meyi"
override val month_november: String = "Novemba"
override val month_november_short: String = "Nov."
override val month_october: String = "Okthoba"
override val month_october_short: String = "Oct."
override val month_september: String = "September"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Yehlulekile ukuqinisekisa."
override val new: String = "Okusha"
override val news: String = "Izindaba"
override val next: String = "Okulandelayo"
override val no: String = "no"
override val no_data_available: String = "Ayikho idatha etholakalayo"
override val no_search_results: String = "Uxolo, asikwazanga ukuthola imiphumela."
override val none: String = "Lutho"
override val note_fields: String = "Amanothi"
override val note_hint: String = "Qaphela"
override val notifications_option: String = "Izaziso"
override val ok: String = "KULUNGILE"
override val onboarding: String = "Ukugibela"
override val onboarding_aboutus_message: String = "Izingxenye ezibalulekile ze-DingVoice zimahhala. Umgomo wethu uwukusiza ukhulise ibhizinisi lakho, usisize sithuthuke ngokwabelana ngempendulo yakho."
override val onboarding_aboutus_title: String = "Silapha ukuzosiza"
override val onboarding_paid_1: String = "Finyelela izifanekiso ezibukeka kahle"
override val onboarding_paid_2: String = "Bheka kabanzi ezezimali zakho"
override val onboarding_paid_3: String = "Thola izinkinga zixazululwe ngokushesha"
override val onboarding_paid_4: String = "Gcina amadokhumenti akho ephephile"
override val onboarding_paid_5: String = "Hlala unolwazi lwakamuva ngezinguquko zesimo se-invoyisi"
override val onboarding_paid_title: String = "Khula ngokushesha okukhulu nge..."
override val onboarding_paywall_message: String = "Jabulela zonke izici ze-premium manje ngentengo engcono kakhulu."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "I-${currencySymbol}${introAmount} ye-${introDuration} ${introPeriod} bese kuba ngu-${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Kumahhala ngo-${trialDuration} ${trialPeriod} bese kuba ngu-${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Mahhala${trialDuration}${trialPeriod} bese${currencySymbol}${introductoryAmount} okwe${introductoryDuration}${introPeriod} bese kuthi nje${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "Ivuseleleka ngokuzenzakalelayo. Khansela noma nini."
override val onboarding_paywall_restore: String = "Buyisela ukuthenga"
override val onboarding_paywall_restore_failure: String = "Ukubuyisela ukuthenga kuhlulekile, zama futhi emuva kwesikhathi noma uthinte abosekelo."
override val onboarding_paywall_restore_success: String = "Ukubuyisela kuphumelele!"
override val onboarding_paywall_title: String = "Zuza Ubukhulu!"
override val onboarding_welcome_message: String = "Isethi yamathuluzi ephelele yokuphatha ama-invoyisi akho nezilinganiso."
override val onboarding_welcome_title: String = "Siyakwamukela ku-DingVoice"
override val other_state: String = "其他"
override val overdue_state: String = "Sekwephuzile"
override val paid_state: String = "Ikhokhiwe"
override val password: String = "Iphasiwedi"
override val password_blank_error: String = "Kudingeka iphasiwedi."
override val password_mismatch_error: String = "Amagama-mfihlo awafani."
override val pay: String = "pay"
override val payment_instructions_label: String = "Imiyalelo Yokukhokha"
override val payment_reminders: String = "Izikhumbuzi zenkokhelo"
override val phone_number_fields: String = "Inombolo yocingo"
override val phone_number_hint: String = "Inombolo yocingo"
override val postcode_hint: String = "Ikhodi yeposi"
override val preview: String = "Buka kuqala"
override val preview_menu_hint: String = "Buka kuqala imenyu"
override val price: String = "Inani"
override val privacy_policy: String = "Inqubomgomo yobumfihlo"
override val profile: String = "Iphrofayili"
override val profitable_client: String = "Iklayenti lakho elinenzuzo kakhulu..."
override val profits_report: String = "Inzuzo"
override val promo_content: String = "Okuqukethwe kokuphromotha"
override val quantity_hint: String = "Ubuningi"
override val quantity_label_hint: String = "Inani Lelebula"
override val query_info: String = "Umbuzo ikuvumela ukuthi ubhale izibalo zangokwezifiso ubhekisele kuzo zombili izinto neminye imithetho yentela njengokufakiwe. Faka uphawu luka-@ kanye nento noma incazelo yentela ukuze uxhume kokunye okufakiwe +, -, *, /, % - abasebenzisa izibalo"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "I-${documentType} ${documentNumber} itholwe iklayenti lakho." }
override val received_state: String = "Kwamukelwe"
override val registration_code_fields: String = "Ikhodi Yokubhalisa"
override val reminder: String = "Isikhumbuzi"
override val reports: String = "Imibiko"
override val reports_not_available: String = "Ayikho imibiko etholakalayo, buya ngemuva kwesikhathi."
override val reveal_password: String = "Veza iphasiwedi"
override val revenue: String = "revenue"
override val sales_tax: String = "I-VAT"
override val sales_tax_number: String = "inombolo ye-VAT"
override val save: String = "Gcina"
override val scan_document: String = "Skena idokhumenti"
override val search: String = "Sesha"
override val select: String = "Khetha"
override val select_icon: String = "Khetha isithonjana"
override val select_signature: String = "Khetha isiginesha"
override val select_template: String = "Khetha isifanekiso"
override val send: String = "Thumela"
override val sent_state: String = "Ithunyelwe"
override val setup_sender: String = "Misa umthumeli"
override val share_option: String = "Yabelana"
override val ship_to: String = "Thumela e"
override val sign_in: String = "Ngena ngemvume"
override val sign_in_apple: String = "Ngena ngemvume nge-Apple"
override val sign_in_email: String = "Ngena ngemvume nge-imeyili"
override val sign_in_facebook: String = "Ngena ngemvume nge-Facebook"
override val sign_in_google: String = "Ngena ngemvume nge-Google"
override val sign_up: String = "Bhalisela"
override val sign_up_label: String = "Awunayo i-akhawunti?"
override val sign_up_link_label: String = "Bhalisela!"
override val signatures_option: String = "Amasignesha"
override val skip: String = "Yeqa"
override val sold_items_report: String = "Izinto Ezithengiswayo"
override val sort_by: String = "Hlunga nge:"
override val status_update: String = "Isibuyekezo sesimo sedokhumenti"
override val sub_annual: String = "Ngonyaka"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/ngonyaka" }
override val sub_backups: String = "Cloud Backups"
override val sub_insights: String = "Ukuqonda"
override val sub_month: String = "Nyanga zonke"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ngenyanga" }
override val sub_notifications: String = "Izaziso"
override val sub_popup_message: String = "Okubhaliselwe kuyeza maduze. Bheka futhi lapha ngemva kwesikhashana."
override val sub_support: String = "Ukusekela"
override val sub_templates: String = "Izifanekiso"
override val subscribe: String = "Bhalisa"
override val subscription_header: String = "Khulisa ibhizinisi lakho nge:"
override val subscription_option: String = "Ukubhalisa"
override val subscriptions_load_fail: String = "Uxolo, asikwazi ukubuyisa okubhaliselwe kwakho ngalesi sikhathi. Sicela uzame futhi emuva kwesikhathi noma uthinte ithimba lethu losekelo ukuze uthole usizo."
override val subtotal: Formattable = Formattable { (sum) -> "Isamba esincane: ${sum}" }
override val subtotal_label: String = "Isamba esingaphansi"
override val sum: String = "Ingqikithi"
override val support_option: String = "Ukusekela"
override val switch_reports: String = "Shintsha umbiko"
override val tax: String = "Intela"
override val tax_deleted_message: String = "Intela isusiwe"
override val tax_description_warning: String = "Uma kusetshenziswa lokhu kuvela ku-invoyisi"
override val tax_input_flat: String = "Isicaba"
override val tax_input_percent: String = "Amaphesenti"
override val tax_number_fields: String = "Inombolo Yentela"
override val tax_number_hint: String = "Inombolo Yentela"
override val tax_query_info_description: String = "Ulwazi Lombuzo"
override val tax_report: String = "Intela"
override val tax_rules_option: String = "Imithetho Yentela"
override val taxed_clients_report: String = "Amaklayenti Akhokhiswa intela"
override val template: String = "Isifanekiso"
override val template_selector_info: String = "Swayipha ukuze uguqule isifanekiso esizenzakalelayo"
override val template_unlock_promo: String = "Finyelela izifanekiso ezengeziwe nge-Premium"
override val templates_option: String = "Izifanekiso"
override val terms_of_service: String = "Imigomo Yesevisi"
override val thank_you: String = "Ngiyabonga"
override val to: String = "Kuya"
override val total: Formattable = Formattable { (sum) -> "Isamba: ${sum}" }
override val total_label: String = "Ingqikithi"
override val undo_action: String = "Hlehlisa"
override val unit: String = "Iyunithi"
override val unit_cost_hint: String = "Izindleko zeyunithi"
override val unknown_client: String = "Iklayenti Elingaziwa"
override val unlock: String = "Vula"
override val unnamed_item: String = "Into engashiwongo"
override val upgrade: String = "Thuthukisa"
override val user_collision_error: String = "I-akhawunti isivele ikhona."
override val valid_until: String = "Ingasebenze kuze kube"
override val warning: String = "Isexwayiso!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Yamukela i-${termsOfService} kanye ne-${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Ikhodi engavumelekile.",
	"add_client_action" to "Engeza iklayenti",
	"add_customer" to "Engeza ikhasimende",
	"add_expense" to "Engeza izindleko",
	"add_fields_label" to "Engeza:",
	"add_invoice_action" to "Dala",
	"add_item_action" to "Engeza into",
	"add_item_button" to "Engeza into",
	"add_signature_action" to "Engeza isiginesha",
	"add_tax_action" to "Engeza intela",
	"address_fields" to "Ikheli",
	"address_line_hint" to "Ulayini we kheli",
	"advanced" to "Okuthuthukile",
	"agree_marketing" to "Ngiyavuma ukwamukela i-Direct Marketing Communications",
	"all_notifications" to "Zonke Izaziso",
	"amortization" to "amortization",
	"amount" to "Inani",
	"asset" to "asset",
	"attach_file" to "Namathisela Ifayela",
	"attachment" to "Okunamathiselwe",
	"auth_error" to "Isenzo sihlulekile, zama futhi.",
	"available_for_paid" to "Ukuvumelanisa kwamafu kutholakala kuphela ngaphansi kokubhaliselwe okukhokhelwayo.",
	"backups_option" to "Izipele",
	"balance" to "balance",
	"bank_account" to "I-Akhawunti Yasebhange",
	"bill_number" to "Inombolo Yomthethosivivinywa",
	"cancel" to "Khansela",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Izigaba",
	"category" to "Isigaba",
	"category_add" to "Engeza Isigaba",
	"category_delete" to "Susa isigaba",
	"category_name" to "Igama lesigaba",
	"change_language" to Formattable { (language) -> "Ulimi lwe-invoyisi: ${language}" },
	"change_language_label" to "Ulimi Lwe-invoyisi",
	"change_template" to "Shintsha isifanekiso",
	"city_hint" to "Idolobha",
	"clear" to "Kucace",
	"client" to "Iklayenti",
	"client_actions" to "Izenzo zeklayenti",
	"client_deleted_message" to "Iklayenti lisusiwe",
	"clients_error_required_fields" to "Kudingeka okungenani inani elilodwa.",
	"clients_option" to "Amaklayenti",
	"close" to "Vala",
	"cloud_sync" to "Ukuvumelanisa Kwamafu",
	"coming_soon" to "Kuyeza maduze!",
	"company_logo" to "Ilogo Yenkampani",
	"company_name_hint" to "Inkampani",
	"confirm_password_label" to "Qinisekisa Iphasiwedi",
	"confirmation_title" to "Uqinisekile?",
	"convert_to_invoice" to "Guqulela ku-invoyisi",
	"country_hint" to "Izwe",
	"create_client" to "Dala iKlayenti",
	"create_company" to "Dala Inkampani",
	"create_document" to "Dala idokhumenti",
	"create_estimate" to "Linganisa",
	"create_invoice" to "I-invoyisi",
	"create_item" to "Dala Into",
	"create_new_client" to "Dala iklayenti elisha",
	"create_new_item" to "Dala into entsha",
	"create_new_tax" to "Dala intela entsha",
	"create_signature" to "Isiginesha",
	"created" to "Kwakhiwe",
	"credit" to "credit",
	"customer" to "Ikhasimende",
	"date" to "Usuku",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Imali ezenzakalelayo: ${currency}" },
	"default_currency_label" to "Imali ezenzakalelayo",
	"delete" to "Susa",
	"delete_account" to "Susa i-akhawunti",
	"delete_confirmation_message" to "Yonke idatha yakho izolahleka.",
	"delete_item_title" to "Susa into?",
	"delete_signature" to "Susa isiginesha",
	"depreciation" to "depreciation",
	"description" to "Incazelo",
	"description_hint" to "Incazelo",
	"detailed_report" to "Umbiko onemininingwane",
	"disabled" to "Kukhutshaziwe",
	"discount" to "Isaphulelo",
	"discount_hint" to "Isaphulelo",
	"display_af" to "umAfrika",
	"display_am" to "Isi-Amharic",
	"display_ar" to "Isi-Arabhu",
	"display_as" to "Isi-Assamese",
	"display_az" to "Isi-Azerbaijani",
	"display_be" to "IsiBelarusian",
	"display_bg" to "IsiBulgaria",
	"display_bn" to "Isi-Bengali",
	"display_bs" to "Isi-Bosnia",
	"display_ca" to "IsiCatalan",
	"display_cs" to "IsiCzech",
	"display_da" to "IsiDanish",
	"display_de" to "IsiJalimane",
	"display_el" to "isiGreki",
	"display_en" to "IsiNgisi",
	"display_es" to "ISpanishi",
	"display_es_MX" to "Isi-Spanish (Mexico)",
	"display_et" to "Isi-Estonian",
	"display_eu" to "Isi-Basque",
	"display_fa" to "isiPheresiya",
	"display_fi" to "IsiFinnish",
	"display_fil" to "IsiFilipino",
	"display_fr" to "IsiFulentshi",
	"display_fr_CA" to "IsiFulentshi saseCanada",
	"display_gl" to "IsiGalicia",
	"display_gu" to "IsiGujarati",
	"display_he" to "IsiHebheru",
	"display_hi" to "Cha",
	"display_hr" to "IsiCroatia",
	"display_hu" to "IsiHungary",
	"display_hy" to "Isi-Armenian",
	"display_id" to "Isi-Indonesian",
	"display_is" to "Isi-Icelandic",
	"display_it" to "IsiNtaliyane",
	"display_ja" to "IsiJapane",
	"display_ka" to "IsiGeorgia",
	"display_kk" to "Isi-Kazakh",
	"display_km" to "I-Central Khmer",
	"display_kn" to "Isi-Kannada",
	"display_ko" to "IsiKorea",
	"display_ky" to "IsiKirghiz",
	"display_lo" to "Umsebenzi",
	"display_lt" to "IsiLithuanian",
	"display_lv" to "Isi-Latvian",
	"display_mk" to "IsiMacedonian",
	"display_ml" to "Isi-Malayalam",
	"display_mn" to "IsiMongolia",
	"display_mr" to "IsiMarathi",
	"display_ms" to "Isi-Malay",
	"display_my" to "IsiBurma",
	"display_nb" to "Isi-Norwegian Bokmål",
	"display_ne" to "IsiNepali",
	"display_nl" to "IsiDashi",
	"display_or" to "Isi-Oriya",
	"display_pa" to "IsiPunjabi",
	"display_pl" to "IsiPolish",
	"display_pt" to "IsiPutukezi",
	"display_pt_BR" to "Isi-Portuguese (Brazil)",
	"display_pt_PT" to "IsiPutukezi saseYurophu",
	"display_ro" to "IsiRomania",
	"display_ru" to "IsiRashiya",
	"display_si" to "IsiSinhala",
	"display_sk" to "IsiSlovak",
	"display_sl" to "IsiSlovenian",
	"display_sq" to "Isi-Albania",
	"display_sr" to "IsiSerbia",
	"display_sv" to "IsiSwidi",
	"display_sw" to "IsiSwahili",
	"display_ta" to "IsiTamil",
	"display_te" to "Isi-Telugu",
	"display_th" to "IsiThai",
	"display_tr" to "IsiTurkey",
	"display_uk" to "Isi-Ukraine",
	"display_ur" to "Isi-Urdu",
	"display_uz" to "Isi-Uzbek",
	"display_vi" to "Isi-Vietnamese",
	"display_zh_Hant_TW" to "IsiShayina (Taiwan, Traditional)",
	"display_zu" to "Zulu",
	"document_estimate" to "Linganisa",
	"document_invoice" to "I-invoyisi",
	"document_quote" to "Quote",
	"done" to "Kwenziwe",
	"download_app" to "Landa uhlelo lokusebenza",
	"download_invoice" to "Landa i-invoyisi",
	"download_pdf" to "Landa i-PDF",
	"draw_signature" to "Dweba isiginesha",
	"due" to Formattable { (date) -> "Ifuneka ngo-${date}" },
	"due_date" to "Usuku olumisiwe",
	"due_day" to "ngosuku",
	"due_label" to "Ifuneka",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ifuneka ngo-${dueWhen}!" },
	"due_today" to "namuhla",
	"due_week" to "ngeviki",
	"duplicate" to "Impinda",
	"duration_day" to "usuku",
	"duration_month" to "Inyanga",
	"duration_week" to "isonto",
	"duration_year" to "unyaka",
	"email" to "Imeyili",
	"email_blank_error" to "I-imeyili iyadingeka.",
	"email_error" to "Ikheli le-imeyili elingavumelekile.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Siyabonga ngebhizinisi lakho. Ukuze ubuke i-${documentType} egcwele chofoza inkinobho ekugcineni kwale ncwadi. Qaphela ukuthi ivumeleke kuze kube ngu-${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Uthole okusha${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "BUKA ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "${clientName} othandekayo" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Siyabonga ngebhizinisi lakho. Ukuze ubuke i-${documentType} egcwele chofoza inkinobho ekugcineni kwale ncwadi. Sicela ukhokhe ibhalansi kuze kube ngu-${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Uthole okusha${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "I-imeyili yokusetha kabusha iphasiwedi ithunyelwe, sicela uhlole ibhokisi lakho lokungenayo.",
	"empty_clients_list_message" to "Awunawo amaklayenti ozowalandelela. Engeza iklayenti elisha.",
	"empty_estimate_list_message" to "Dala isilinganiso sakho sokuqala",
	"empty_expenses" to "Awunazo izindleko zokulandelela. Engeza izindleko ezintsha.",
	"empty_invoice_list_message" to "Dala i-invoyisi yakho yokuqala",
	"empty_items_list_message" to "Awunazo izinto, engeza into entsha!",
	"empty_signatures_list_message" to "Awekho amasiginesha atholiwe. Dala eyodwa!",
	"empty_taxes_list_message" to "Awunayo imithetho yentela. Engeza umthetho omusha wentela.",
	"empty_templates_list_message" to "Izifanekiso azitholakali.",
	"enabled" to "Inikwe amandla",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Linganisela #",
	"estimates" to "Izilinganiso",
	"estimates_report" to "Izilinganiso",
	"expense_bank_fees" to "Imali Yebhange",
	"expense_benefits" to "Izinzuzo",
	"expense_communication" to "Ukuxhumana",
	"expense_debt" to "Isikweletu",
	"expense_deleted" to "Izindleko zisusiwe",
	"expense_depreciation" to "Ukwehla kwenani",
	"expense_gifts" to "Izipho",
	"expense_insurance" to "Umshwalense",
	"expense_inventory" to "Inventory",
	"expense_legal" to "Okusemthethweni",
	"expense_maintenance" to "Isondlo",
	"expense_marketing" to "Ukumaketha",
	"expense_meals" to "Ukudla",
	"expense_office_supplies" to "Izinsiza zasehhovisi",
	"expense_rent" to "Qasha",
	"expense_salaries" to "Amaholo",
	"expense_shopping" to "Ukuthenga",
	"expense_software" to "Isofthiwe",
	"expense_status_available" to "Iyatholakala",
	"expense_status_processing" to "Iyacubungula",
	"expense_tax" to "Intela",
	"expense_training" to "Ukuqeqeshwa",
	"expense_transport" to "Ezokuthutha",
	"expense_travel" to "Ukuhamba",
	"expense_utility" to "Isisetshenziswa",
	"expenses" to "Izindleko",
	"feedback_option" to "Impendulo",
	"filter" to "Hlunga",
	"first_name_hint" to "Igama",
	"forgot_password" to "Setha kabusha iphasiwedi",
	"forgot_password_label" to "Ukhohlwe iphasiwedi",
	"from" to "Kusuka",
	"general_error" to "Kuvele iphutha, sicela uzame futhi emuva kwesikhathi.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Buyela emuva",
	"group_by" to "Iqembu Nge:",
	"image_view" to "Isithombe",
	"install" to "Faka",
	"invalid_credentials_error" to "Ukuqinisekisa okungavumelekile.",
	"invalid_email_error" to "I-imeyili engavumelekile.",
	"invalid_user_error" to "Imininingwane yomsebenzisi engavumelekile.",
	"invoice_deleted_message" to "I-invoyisi isusiwe",
	"invoice_menu" to "Imenyu ye-invoyisi",
	"invoice_number" to "I-invoyisi #",
	"invoices" to "Ama-invoyisi",
	"items_option" to "Izinto",
	"keep" to "Gcina",
	"language_selector" to Formattable { (okukhethiwe) -> "Ulimi ${okukhethiwe}" },
	"last_name_hint" to "Isibongo",
	"ledger" to "ledger",
	"legal" to "Okusemthethweni",
	"legal_error" to "Kufanele wamukele Imigomo Yesevisi kanye Nenqubomgomo Yobumfihlo",
	"liabilities" to "liabilities",
	"loading" to "Iyalayisha",
	"login_required_error" to "Kudingeka ukungena ngemvume.",
	"logo_view" to "Ilogo",
	"logout" to "Phuma",
	"logout_prompt" to "Idatha yakho ayivumelaniswanga nefu. Susa ama-invoyisi, izilinganiso, amaklayenti nolunye ulwazi ekuphumeni?",
	"main_screen" to "Isikrini esikhulu",
	"mark_paid" to "Maka njengeKhokhiwe",
	"message" to "Umlayezo",
	"missing_sender_msg" to "Idokhumenti engenalo ulwazi lomthumeli ingase ingavumelekile.",
	"missing_sender_title" to "Ulwazi lomthumeli alukho",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "Agasti",
	"month_august_short" to "Aug.",
	"month_december" to "Disemba",
	"month_december_short" to "Dec.",
	"month_february" to "February",
	"month_february_short" to "Feb.",
	"month_january" to "January",
	"month_january_short" to "Jan.",
	"month_july" to "July",
	"month_july_short" to "July",
	"month_june" to "Juni",
	"month_june_short" to "Juni",
	"month_march" to "Mashi",
	"month_march_short" to "Mashi",
	"month_may" to "Meyi",
	"month_may_short" to "Meyi",
	"month_november" to "Novemba",
	"month_november_short" to "Nov.",
	"month_october" to "Okthoba",
	"month_october_short" to "Oct.",
	"month_september" to "September",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Yehlulekile ukuqinisekisa.",
	"new" to "Okusha",
	"news" to "Izindaba",
	"next" to "Okulandelayo",
	"no" to "no",
	"no_data_available" to "Ayikho idatha etholakalayo",
	"no_search_results" to "Uxolo, asikwazanga ukuthola imiphumela.",
	"none" to "Lutho",
	"note_fields" to "Amanothi",
	"note_hint" to "Qaphela",
	"notifications_option" to "Izaziso",
	"ok" to "KULUNGILE",
	"onboarding" to "Ukugibela",
	"onboarding_aboutus_message" to "Izingxenye ezibalulekile ze-DingVoice zimahhala. Umgomo wethu uwukusiza ukhulise ibhizinisi lakho, usisize sithuthuke ngokwabelana ngempendulo yakho.",
	"onboarding_aboutus_title" to "Silapha ukuzosiza",
	"onboarding_paid_1" to "Finyelela izifanekiso ezibukeka kahle",
	"onboarding_paid_2" to "Bheka kabanzi ezezimali zakho",
	"onboarding_paid_3" to "Thola izinkinga zixazululwe ngokushesha",
	"onboarding_paid_4" to "Gcina amadokhumenti akho ephephile",
	"onboarding_paid_5" to "Hlala unolwazi lwakamuva ngezinguquko zesimo se-invoyisi",
	"onboarding_paid_title" to "Khula ngokushesha okukhulu nge...",
	"onboarding_paywall_message" to "Jabulela zonke izici ze-premium manje ngentengo engcono kakhulu.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "I-${currencySymbol}${introAmount} ye-${introDuration} ${introPeriod} bese kuba ngu-${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Kumahhala ngo-${trialDuration} ${trialPeriod} bese kuba ngu-${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Mahhala${trialDuration}${trialPeriod} bese${currencySymbol}${introductoryAmount} okwe${introductoryDuration}${introPeriod} bese kuthi nje${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "Ivuseleleka ngokuzenzakalelayo. Khansela noma nini.",
	"onboarding_paywall_restore" to "Buyisela ukuthenga",
	"onboarding_paywall_restore_failure" to "Ukubuyisela ukuthenga kuhlulekile, zama futhi emuva kwesikhathi noma uthinte abosekelo.",
	"onboarding_paywall_restore_success" to "Ukubuyisela kuphumelele!",
	"onboarding_paywall_title" to "Zuza Ubukhulu!",
	"onboarding_welcome_message" to "Isethi yamathuluzi ephelele yokuphatha ama-invoyisi akho nezilinganiso.",
	"onboarding_welcome_title" to "Siyakwamukela ku-DingVoice",
	"other_state" to "其他",
	"overdue_state" to "Sekwephuzile",
	"paid_state" to "Ikhokhiwe",
	"password" to "Iphasiwedi",
	"password_blank_error" to "Kudingeka iphasiwedi.",
	"password_mismatch_error" to "Amagama-mfihlo awafani.",
	"pay" to "pay",
	"payment_instructions_label" to "Imiyalelo Yokukhokha",
	"payment_reminders" to "Izikhumbuzi zenkokhelo",
	"phone_number_fields" to "Inombolo yocingo",
	"phone_number_hint" to "Inombolo yocingo",
	"postcode_hint" to "Ikhodi yeposi",
	"preview" to "Buka kuqala",
	"preview_menu_hint" to "Buka kuqala imenyu",
	"price" to "Inani",
	"privacy_policy" to "Inqubomgomo yobumfihlo",
	"profile" to "Iphrofayili",
	"profitable_client" to "Iklayenti lakho elinenzuzo kakhulu...",
	"profits_report" to "Inzuzo",
	"promo_content" to "Okuqukethwe kokuphromotha",
	"quantity_hint" to "Ubuningi",
	"quantity_label_hint" to "Inani Lelebula",
	"query_info" to "Umbuzo ikuvumela ukuthi ubhale izibalo zangokwezifiso ubhekisele kuzo zombili izinto neminye imithetho yentela njengokufakiwe. Faka uphawu luka-@ kanye nento noma incazelo yentela ukuze uxhume kokunye okufakiwe +, -, *, /, % - abasebenzisa izibalo",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "I-${documentType} ${documentNumber} itholwe iklayenti lakho." },
	"received_state" to "Kwamukelwe",
	"registration_code_fields" to "Ikhodi Yokubhalisa",
	"reminder" to "Isikhumbuzi",
	"reports" to "Imibiko",
	"reports_not_available" to "Ayikho imibiko etholakalayo, buya ngemuva kwesikhathi.",
	"reveal_password" to "Veza iphasiwedi",
	"revenue" to "revenue",
	"sales_tax" to "I-VAT",
	"sales_tax_number" to "inombolo ye-VAT",
	"save" to "Gcina",
	"scan_document" to "Skena idokhumenti",
	"search" to "Sesha",
	"select" to "Khetha",
	"select_icon" to "Khetha isithonjana",
	"select_signature" to "Khetha isiginesha",
	"select_template" to "Khetha isifanekiso",
	"send" to "Thumela",
	"sent_state" to "Ithunyelwe",
	"setup_sender" to "Misa umthumeli",
	"share_option" to "Yabelana",
	"ship_to" to "Thumela e",
	"sign_in" to "Ngena ngemvume",
	"sign_in_apple" to "Ngena ngemvume nge-Apple",
	"sign_in_email" to "Ngena ngemvume nge-imeyili",
	"sign_in_facebook" to "Ngena ngemvume nge-Facebook",
	"sign_in_google" to "Ngena ngemvume nge-Google",
	"sign_up" to "Bhalisela",
	"sign_up_label" to "Awunayo i-akhawunti?",
	"sign_up_link_label" to "Bhalisela!",
	"signatures_option" to "Amasignesha",
	"skip" to "Yeqa",
	"sold_items_report" to "Izinto Ezithengiswayo",
	"sort_by" to "Hlunga nge:",
	"status_update" to "Isibuyekezo sesimo sedokhumenti",
	"sub_annual" to "Ngonyaka",
	"sub_annual_price" to Formattable { (price) -> "${price}/ngonyaka" },
	"sub_backups" to "Cloud Backups",
	"sub_insights" to "Ukuqonda",
	"sub_month" to "Nyanga zonke",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ngenyanga" },
	"sub_notifications" to "Izaziso",
	"sub_popup_message" to "Okubhaliselwe kuyeza maduze. Bheka futhi lapha ngemva kwesikhashana.",
	"sub_support" to "Ukusekela",
	"sub_templates" to "Izifanekiso",
	"subscribe" to "Bhalisa",
	"subscription_header" to "Khulisa ibhizinisi lakho nge:",
	"subscription_option" to "Ukubhalisa",
	"subscriptions_load_fail" to "Uxolo, asikwazi ukubuyisa okubhaliselwe kwakho ngalesi sikhathi. Sicela uzame futhi emuva kwesikhathi noma uthinte ithimba lethu losekelo ukuze uthole usizo.",
	"subtotal" to Formattable { (sum) -> "Isamba esincane: ${sum}" },
	"subtotal_label" to "Isamba esingaphansi",
	"sum" to "Ingqikithi",
	"support_option" to "Ukusekela",
	"switch_reports" to "Shintsha umbiko",
	"tax" to "Intela",
	"tax_deleted_message" to "Intela isusiwe",
	"tax_description_warning" to "Uma kusetshenziswa lokhu kuvela ku-invoyisi",
	"tax_input_flat" to "Isicaba",
	"tax_input_percent" to "Amaphesenti",
	"tax_number_fields" to "Inombolo Yentela",
	"tax_number_hint" to "Inombolo Yentela",
	"tax_query_info_description" to "Ulwazi Lombuzo",
	"tax_report" to "Intela",
	"tax_rules_option" to "Imithetho Yentela",
	"taxed_clients_report" to "Amaklayenti Akhokhiswa intela",
	"template" to "Isifanekiso",
	"template_selector_info" to "Swayipha ukuze uguqule isifanekiso esizenzakalelayo",
	"template_unlock_promo" to "Finyelela izifanekiso ezengeziwe nge-Premium",
	"templates_option" to "Izifanekiso",
	"terms_of_service" to "Imigomo Yesevisi",
	"thank_you" to "Ngiyabonga",
	"to" to "Kuya",
	"total" to Formattable { (sum) -> "Isamba: ${sum}" },
	"total_label" to "Ingqikithi",
	"undo_action" to "Hlehlisa",
	"unit" to "Iyunithi",
	"unit_cost_hint" to "Izindleko zeyunithi",
	"unknown_client" to "Iklayenti Elingaziwa",
	"unlock" to "Vula",
	"unnamed_item" to "Into engashiwongo",
	"upgrade" to "Thuthukisa",
	"user_collision_error" to "I-akhawunti isivele ikhona.",
	"valid_until" to "Ingasebenze kuze kube",
	"warning" to "Isexwayiso!",
	"yes" to "yes",
    )
    
}