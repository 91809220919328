// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Tr  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} ve ${privacyPolicy}'ni kabul edin" }
override val account: String = "hesap"
override val accounting: String = "muhasebe"
override val accruals: String = "tahakkuklar"
override val action_code_error: String = "Geçersiz kod."
override val add_client_action: String = "İstemci ekle"
override val add_customer: String = "Müşteri ekle"
override val add_expense: String = "Gider ekle"
override val add_fields_label: String = "Eklemek:"
override val add_invoice_action: String = "Yaratmak"
override val add_item_action: String = "Öğe ekle"
override val add_item_button: String = "Öğe ekle"
override val add_signature_action: String = "İmza ekle"
override val add_tax_action: String = "Vergi ekle"
override val address_fields: String = "Adres"
override val address_line_hint: String = "Adres Satırı"
override val advanced: String = "Gelişmiş"
override val agree_marketing: String = "Doğrudan Pazarlama İletişimi almayı kabul ediyorum"
override val all_notifications: String = "Tüm Bildirimler"
override val amortization: String = "amortisman"
override val amount: String = "miktar"
override val asset: String = "varlık"
override val attach_file: String = "Dosya ekle"
override val attachment: String = "Ek"
override val auth_error: String = "Eylem başarısız oldu, yeniden deneyin."
override val available_for_paid: String = "Bulut senkronizasyonu yalnızca ücretli abonelik altında kullanılabilir."
override val backups_option: String = "Yedekleme"
override val balance: String = "bakiye"
override val bank_account: String = "Banka Hesabı"
override val bill_number: String = "Fatura Numarası"
override val cancel: String = "İptal"
override val cancel_trial: String = "İstediğiniz zaman iptal edin"
override val capital: String = "sermaye"
override val cash: String = "nakit"
override val categories: String = "Kategoriler"
override val category: String = "Kategori"
override val category_add: String = "Kategori Ekle"
override val category_delete: String = "Kategoriyi sil"
override val category_name: String = "Kategori adı"
override val change_language: Formattable = Formattable { (language) -> "Fatura Dili: ${language}" }
override val change_language_label: String = "Fatura Dili"
override val change_template: String = "Şablonu değiştir"
override val city_hint: String = "Şehir"
override val clear: String = "berrak"
override val client: String = "Müşteri"
override val client_actions: String = "İstemci eylemleri"
override val client_deleted_message: String = "İstemci silindi"
override val clients_error_required_fields: String = "En az bir değer gereklidir."
override val clients_option: String = "Istemci"
override val close: String = "Yakın"
override val cloud_sync: String = "Bulut Senkronizasyonu"
override val coming_soon: String = "Çok yakında!"
override val company_logo: String = "Şirket Logosu"
override val company_name_hint: String = "Şirket"
override val confirm_password_label: String = "Parolayı onayla"
override val confirmation_title: String = "Emin misin?"
override val convert_to_invoice: String = "Faturaya dönüştür"
override val country_hint: String = "Ülke"
override val create_client: String = "İstemci Oluştur"
override val create_company: String = "Şirket Oluştur"
override val create_document: String = "Belge oluştur"
override val create_estimate: String = "Tahmin"
override val create_invoice: String = "Fatura"
override val create_item: String = "Madde Oluştur"
override val create_new_client: String = "Yeni istemci oluştur"
override val create_new_item: String = "Yeni öğe oluştur"
override val create_new_tax: String = "Yeni vergi oluştur"
override val create_signature: String = "imza"
override val created: String = "Oluşturulan"
override val credit: String = "kredi"
override val customer: String = "Müşteri"
override val date: String = "Tarih"
override val debit: String = "borç"
override val debt: String = "borç"
override val default_currency: Formattable = Formattable { (currency) -> "Varsayılan para birimi: ${currency}" }
override val default_currency_label: String = "Varsayılan para birimi"
override val delete: String = "Silmek"
override val delete_account: String = "Hesabı Sil"
override val delete_confirmation_message: String = "Tüm verileriniz kaybolacaktır."
override val delete_item_title: String = "Öğe silinsin mi?"
override val delete_signature: String = "İmzayı sil"
override val depreciation: String = "amortisman"
override val description: String = "Açıklama"
override val description_hint: String = "Açıklama"
override val detailed_report: String = "Detaylı Rapor"
override val disabled: String = "Sakat"
override val discount: String = "İndirim"
override val discount_hint: String = "İskonto"
override val display_af: String = "Afrikanca"
override val display_am: String = "Amharca"
override val display_ar: String = "Arapça"
override val display_as: String = "Assamca"
override val display_az: String = "Azerice"
override val display_be: String = "Belarusça"
override val display_bg: String = "Bulgarca"
override val display_bn: String = "Bengalce"
override val display_bs: String = "Boşnakça"
override val display_ca: String = "Katalanca"
override val display_cs: String = "Çek Cumhuriyeti"
override val display_da: String = "Danimarka"
override val display_de: String = "Alman"
override val display_el: String = "Yunan"
override val display_en: String = "İngilizce"
override val display_es: String = "İspanyolca"
override val display_es_MX: String = "İspanyolca (Meksika)"
override val display_et: String = "Estonca"
override val display_eu: String = "Bask"
override val display_fa: String = "Farsça"
override val display_fi: String = "Fince"
override val display_fil: String = "Filipinli"
override val display_fr: String = "Fransızca"
override val display_fr_CA: String = "Kanada Fransızcası"
override val display_gl: String = "Galiçyaca"
override val display_gu: String = "Gujarati"
override val display_he: String = "İbranice"
override val display_hi: String = "Hintçe"
override val display_hr: String = "Hırvatça"
override val display_hu: String = "Macarca"
override val display_hy: String = "Ermenice"
override val display_id: String = "Endonezce"
override val display_is: String = "İzlandaca"
override val display_it: String = "İtalyan"
override val display_ja: String = "Japonca"
override val display_ka: String = "Gürcüce"
override val display_kk: String = "Kazakça"
override val display_km: String = "Orta Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korece"
override val display_ky: String = "Kırgız"
override val display_lo: String = "Lao"
override val display_lt: String = "Litvanyalı"
override val display_lv: String = "Letonca"
override val display_mk: String = "Makedonca"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Moğolca"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malay"
override val display_my: String = "Birmanya"
override val display_nb: String = "Norveççe Bokmål"
override val display_ne: String = "Nepalce"
override val display_nl: String = "Hollandaca"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Lehçe"
override val display_pt: String = "Portekizce"
override val display_pt_BR: String = "Portekizce (Brezilya)"
override val display_pt_PT: String = "Avrupa Portekizcesi"
override val display_ro: String = "Romence"
override val display_ru: String = "Rusça"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovakça"
override val display_sl: String = "Slovence"
override val display_sq: String = "Arnavutça"
override val display_sr: String = "Sırpça"
override val display_sv: String = "İsveç"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamilce"
override val display_te: String = "Telugu"
override val display_th: String = "Tayland"
override val display_tr: String = "Türkçe"
override val display_uk: String = "Ukrayna"
override val display_ur: String = "Urduca"
override val display_uz: String = "Özbekçe"
override val display_vi: String = "Vietnamca"
override val display_zh_Hant_TW: String = "Çince (Tayvan, Geleneksel)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Tahmin"
override val document_invoice: String = "Fatura"
override val document_quote: String = "Fiyat Teklifi"
override val done: String = "Bitti"
override val download_app: String = "Uygulamayı indirin"
override val download_invoice: String = "Faturayı İndir"
override val download_pdf: String = "İndir PDF"
override val draw_signature: String = "İmza çizme"
override val due: Formattable = Formattable { (date) -> "Son tarih ${date}" }
override val due_date: String = "Son Tarih"
override val due_day: String = "bir günde"
override val due_label: String = "Neden -iyle"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} için son ödeme tarihi ${dueWhen}!" }
override val due_today: String = "bugün"
override val due_week: String = "bir hafta içinde"
override val duplicate: String = "Kopya"
override val duration_day: String = "gün"
override val duration_month: String = "Ay"
override val duration_week: String = "hafta"
override val duration_year: String = "yıl"
override val email: String = "E-posta"
override val email_blank_error: String = "E-posta gerekli."
override val email_error: String = "Geçersiz e-posta adresi."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "İşiniz için teşekkürler. ${documentType}'ın tamamını görüntülemek için bu mektubun sonundaki düğmeyi tıklayın. ${dueDate} tarihine kadar geçerli olduğunu unutmayın." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Yeni bir ${documentType} ${invoiceNumber} aldınız!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "FATURA ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Sayın ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "İşiniz için teşekkür ederiz. ${documentType}'ın tamamını görüntülemek için bu mektubun sonundaki düğmeyi tıklayın. Lütfen bakiyeyi ${dueDate} tarihine kadar ödeyin." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Yeni bir ${documentType} ${invoiceNumber} aldınız!" }
override val email_reset_success_message: String = "Gönderilen şifreyi sıfırlamak için e-posta gönderin, lütfen gelen kutunuzu kontrol edin."
override val empty_clients_list_message: String = "İzlerken müşterin yok. Yeni bir istemci ekleyin."
override val empty_estimate_list_message: String = "İlk tahmininizi oluşturun"
override val empty_expenses: String = "Takip edilecek gideriniz yok. Yeni bir gider ekleyin."
override val empty_invoice_list_message: String = "İlk faturanızı oluşturma"
override val empty_items_list_message: String = "Öğeniz yok, yeni bir öğe ekleyin!"
override val empty_signatures_list_message: String = "İmza bulunamadı. Bir tane yarat!"
override val empty_taxes_list_message: String = "Vergi kuralın yok. Yeni bir vergi kuralı ekleyin."
override val empty_templates_list_message: String = "Şablonlar kullanılamıyor."
override val enabled: String = "Etkinleştirildi"
override val entry: String = "giriş"
override val equity: String = "öz sermaye"
override val estimate_number: String = "Tahmin #"
override val estimates: String = "Tahmin"
override val estimates_report: String = "Tahminler"
override val expense_bank_fees: String = "Banka Ücretleri"
override val expense_benefits: String = "Faydalar"
override val expense_communication: String = "İletişim"
override val expense_debt: String = "Borç"
override val expense_deleted: String = "Gider silindi"
override val expense_depreciation: String = "Amortisman"
override val expense_gifts: String = "Hediyeler"
override val expense_insurance: String = "Sigorta"
override val expense_inventory: String = "Envanter"
override val expense_legal: String = "Yasal"
override val expense_maintenance: String = "Bakım"
override val expense_marketing: String = "Pazarlama"
override val expense_meals: String = "Yemekler"
override val expense_office_supplies: String = "Ofis Malzemeleri"
override val expense_rent: String = "Kira"
override val expense_salaries: String = "Maaşlar"
override val expense_shopping: String = "Alışveriş"
override val expense_software: String = "Yazılım"
override val expense_status_available: String = "Mevcut"
override val expense_status_processing: String = "İşleniyor"
override val expense_tax: String = "Vergi"
override val expense_training: String = "Eğitim"
override val expense_transport: String = "Ulaşım"
override val expense_travel: String = "Seyahat"
override val expense_utility: String = "Yarar"
override val expenses: String = "Masraflar"
override val feedback_option: String = "Geri besleme"
override val filter: String = "Filtre"
override val first_name_hint: String = "Ad"
override val forgot_password: String = "Parolayı Sıfırla"
override val forgot_password_label: String = "Parolayı unuttum"
override val from: String = "Kaynak"
override val general_error: String = "Hata oluştu, lütfen daha sonra tekrar deneyin."
override val general_ledger: String = "genel muhasebe"
override val go_back_label: String = "Geri Git"
override val group_by: String = "Grupla:"
override val image_view: String = "Görüntü"
override val install: String = "Yükle"
override val invalid_credentials_error: String = "Geçersiz kimlik bilgileri."
override val invalid_email_error: String = "Geçersiz e-posta."
override val invalid_user_error: String = "Geçersiz kullanıcı kimlik bilgileri."
override val invoice_deleted_message: String = "Fatura silindi"
override val invoice_menu: String = "Fatura menüsü"
override val invoice_number: String = "Fatura #"
override val invoices: String = "Fatura"
override val items_option: String = "eşya"
override val keep: String = "Tutmak"
override val language_selector: Formattable = Formattable { (seçili) -> "Dil ${seçili}" }
override val last_name_hint: String = "Soyadı"
override val ledger: String = "defter"
override val legal: String = "Yasal"
override val legal_error: String = "Hizmet Şartları ve Gizlilik Politikası kabul etmelidir"
override val liabilities: String = "yükümlülükler"
override val loading: String = "Yükleme"
override val login_required_error: String = "Giriş gerekli."
override val logo_view: String = "Logo"
override val logout: String = "Oturum kapatma"
override val logout_prompt: String = "Verileriniz bulutla eşitlenmez. Faturalar, tahminler, istemciler ve oturum kapatmayla ilgili diğer bilgiler silinsin mi?"
override val main_screen: String = "Ana ekran"
override val mark_paid: String = "Ödendi Olarak İşaretle"
override val message: String = "Mesaj"
override val missing_sender_msg: String = "Gönderen bilgisi olmayan bir belge geçersiz olabilir."
override val missing_sender_title: String = "Gönderen bilgileri eksik"
override val month_april: String = "Nisan"
override val month_april_short: String = "Nisan"
override val month_august: String = "Ağustos"
override val month_august_short: String = "Ağu."
override val month_december: String = "Aralık"
override val month_december_short: String = "Ara."
override val month_february: String = "Şubat"
override val month_february_short: String = "Şubat"
override val month_january: String = "Ocak"
override val month_january_short: String = "Ocak"
override val month_july: String = "Temmuz"
override val month_july_short: String = "Temmuz"
override val month_june: String = "Haziran"
override val month_june_short: String = "Haziran"
override val month_march: String = "Mart"
override val month_march_short: String = "Mart"
override val month_may: String = "Mayıs"
override val month_may_short: String = "Mayıs"
override val month_november: String = "Kasım"
override val month_november_short: String = "Kas."
override val month_october: String = "Ekim"
override val month_october_short: String = "Eki."
override val month_september: String = "Eylül"
override val month_september_short: String = "Eyl."
override val multifactor_error: String = "Doğrulanamadı."
override val new: String = "Yeni"
override val news: String = "Haberler"
override val next: String = "Sonraki"
override val no: String = "hayır"
override val no_data_available: String = "Kullanılabilir veri yok"
override val no_search_results: String = "Üzgünüm, sonuç bulamadık."
override val none: String = "Hiçbiri"
override val note_fields: String = "Notlar"
override val note_hint: String = "Not"
override val notifications_option: String = "BİLDİRİM"
override val ok: String = "Tamam"
override val onboarding: String = "İşe Alım"
override val onboarding_aboutus_message: String = "DingVoice'un temel parçaları sonsuza kadar ücretsizdir. Misyonumuz, işinizi büyütmenize yardımcı olmak, geri bildirimlerinizi paylaşarak gelişmemize yardımcı olmaktır."
override val onboarding_aboutus_title: String = "Yardım etmek için buradayız"
override val onboarding_paid_1: String = "Profesyonel görünümlü şablonlara erişin"
override val onboarding_paid_2: String = "Mali durumunuza daha derinlemesine bakın"
override val onboarding_paid_3: String = "Sorunların hızlı çözülmesini sağlayın"
override val onboarding_paid_4: String = "Belgelerinizi güvende tutun"
override val onboarding_paid_5: String = "Fatura durum değişikliklerinden haberdar olun"
override val onboarding_paid_title: String = "ile daha da hızlı büyüyün..."
override val onboarding_paywall_message: String = "Tüm premium özelliklerin keyfini şimdi en iyi fiyata çıkarın."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} için yalnızca ${currencySymbol}${introAmount} ve ardından ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, süre) -> "Sadece ${currencySymbol}${amount} / ${süre}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} için ücretsiz ve ardından sadece ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} için ücretsiz, sonra ${currencySymbol}${introductoryAmount} için ${introductoryDuration} ${introPeriod} ve sonra sadece ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Otomatik yenilenebilir. İstediğiniz zaman iptal edin."
override val onboarding_paywall_restore: String = "Satın alma işlemini geri yükle"
override val onboarding_paywall_restore_failure: String = "Satın alımları geri yükleme başarısız oldu, daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin."
override val onboarding_paywall_restore_success: String = "Geri yükleme başarılı!"
override val onboarding_paywall_title: String = "Mükemmelliğe Ulaşın!"
override val onboarding_welcome_message: String = "Faturalarınızı ve tahminlerinizi yönetmek için eksiksiz bir araç seti."
override val onboarding_welcome_title: String = "DingVoice'a Hoş Geldiniz"
override val other_state: String = "Diğer"
override val overdue_state: String = "Gecik -miş"
override val paid_state: String = "Ödenen"
override val password: String = "Parola"
override val password_blank_error: String = "Parola gerekli."
override val password_mismatch_error: String = "Parolalar eşleşmiyor."
override val pay: String = "ödeme"
override val payment_instructions_label: String = "Ödeme Talimatları"
override val payment_reminders: String = "Ödeme anımsatıcıları"
override val phone_number_fields: String = "Telefon numarası"
override val phone_number_hint: String = "Telefon numarası"
override val postcode_hint: String = "Posta kodu"
override val preview: String = "Önizleme"
override val preview_menu_hint: String = "Önizleme menüsü"
override val price: String = "Fiyat"
override val privacy_policy: String = "Gizlilik Politikası"
override val profile: String = "Profil"
override val profitable_client: String = "En karlı müşteriniz..."
override val profits_report: String = "Kazançlar"
override val promo_content: String = "Tanıtım içeriği"
override val quantity_hint: String = "Miktar"
override val quantity_label_hint: String = "Miktar Etiketi"
override val query_info: String = "Sorgu, giriş olarak hem maddelere hem de diğer vergi kurallarına başvuran özel hesaplamalar yazmanıza olanak tanır. Başka bir girişe bağlanmak için @ sembolü ve öğe veya vergi açıklaması ekleyin +, -, *, /, % - matematik işleçleri"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} istemciniz tarafından alındı." }
override val received_state: String = "Alındı"
override val registration_code_fields: String = "Kayıt Kodu"
override val reminder: String = "Hatırlatıcı"
override val reports: String = "Raporlar"
override val reports_not_available: String = "Kullanılabilir raporlar yok, daha sonra tekrar gelin."
override val reveal_password: String = "Parolayı göster"
override val revenue: String = "gelir"
override val sales_tax: String = "KDV"
override val sales_tax_number: String = "KDV numarası"
override val save: String = "Kaydetmek"
override val scan_document: String = "Belgeyi tara"
override val search: String = "Arama"
override val select: String = "Seçiniz"
override val select_icon: String = "Simge seçin"
override val select_signature: String = "İmza seç"
override val select_template: String = "Şablon seç"
override val send: String = "Gönder"
override val sent_state: String = "Gönderilen"
override val setup_sender: String = "Göndereni ayarla"
override val share_option: String = "Paylaş"
override val ship_to: String = "Gönderilecek"
override val sign_in: String = "Oturum Aç"
override val sign_in_apple: String = "Apple ile oturum açma"
override val sign_in_email: String = "E-postayla oturum açma"
override val sign_in_facebook: String = "Facebook ile oturum açma"
override val sign_in_google: String = "Google ile oturum açma"
override val sign_up: String = "Kaydol"
override val sign_up_label: String = "Hesabınız yok mu?"
override val sign_up_link_label: String = "Kaydol!"
override val signatures_option: String = "Imza"
override val skip: String = "Atla"
override val sold_items_report: String = "Satılan Ürünler"
override val sort_by: String = "Sıralama Ölçütü:"
override val status_update: String = "Belge durum güncellemesi"
override val sub_annual: String = "Yıllık"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/yıl" }
override val sub_backups: String = "Bulut Yedeklemeleri"
override val sub_insights: String = "İçgörüler"
override val sub_month: String = "Aylık"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ay" }
override val sub_notifications: String = "Bildirimler"
override val sub_popup_message: String = "Abonelikler yakında geliyor. Kısa bir süre sonra burayı tekrar kontrol edin."
override val sub_support: String = "Destek"
override val sub_templates: String = "Şablonlar"
override val subscribe: String = "Abone olmak"
override val subscription_header: String = "İşinizi aşağıdakilerle büyütün:"
override val subscription_option: String = "Abonelik"
override val subscriptions_load_fail: String = "Üzgünüz, şu anda aboneliklerinizi alamıyoruz. Lütfen daha sonra tekrar deneyin veya yardım için destek ekibimizle iletişime geçin."
override val subtotal: Formattable = Formattable { (sum) -> "Alt Toplam: ${sum}" }
override val subtotal_label: String = "Ara Toplam"
override val sum: String = "Toplam"
override val support_option: String = "Destek"
override val switch_reports: String = "Raporu Değiştir"
override val tax: String = "Vergi"
override val tax_deleted_message: String = "Vergi silindi"
override val tax_description_warning: String = "Kullanılırsa bu bir faturada gösterilir"
override val tax_input_flat: String = "Düz"
override val tax_input_percent: String = "Yüzde"
override val tax_number_fields: String = "Vergi Numarası"
override val tax_number_hint: String = "Vergi Numarası"
override val tax_query_info_description: String = "Sorgu Bilgisi"
override val tax_report: String = "Vergi"
override val tax_rules_option: String = "Vergi Kuralları"
override val taxed_clients_report: String = "Vergilendirilmiş Müşteriler"
override val template: String = "Şablon"
override val template_selector_info: String = "Varsayılan şablonu değiştirmek için kaydırın"
override val template_unlock_promo: String = "Premium ile daha fazla şablona erişin"
override val templates_option: String = "Şablon"
override val terms_of_service: String = "Hizmet Şartları"
override val thank_you: String = "Teşekkürler"
override val to: String = "Hedef"
override val total: Formattable = Formattable { (sum) -> "Toplam: ${sum}" }
override val total_label: String = "Toplam"
override val undo_action: String = "GERİ ALMAK"
override val unit: String = "Birim"
override val unit_cost_hint: String = "Birim Maliyet"
override val unknown_client: String = "Bilinmeyen İstemci"
override val unlock: String = "Kilidi aç"
override val unnamed_item: String = "Adsız öğe"
override val upgrade: String = "Yükseltmek"
override val user_collision_error: String = "Hesap zaten var."
override val valid_until: String = "Şu Tarihe Kadar Geçerlidir"
override val warning: String = "Uyarı!"
override val yes: String = "evet"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} ve ${privacyPolicy}'ni kabul edin" },
	"account" to "hesap",
	"accounting" to "muhasebe",
	"accruals" to "tahakkuklar",
	"action_code_error" to "Geçersiz kod.",
	"add_client_action" to "İstemci ekle",
	"add_customer" to "Müşteri ekle",
	"add_expense" to "Gider ekle",
	"add_fields_label" to "Eklemek:",
	"add_invoice_action" to "Yaratmak",
	"add_item_action" to "Öğe ekle",
	"add_item_button" to "Öğe ekle",
	"add_signature_action" to "İmza ekle",
	"add_tax_action" to "Vergi ekle",
	"address_fields" to "Adres",
	"address_line_hint" to "Adres Satırı",
	"advanced" to "Gelişmiş",
	"agree_marketing" to "Doğrudan Pazarlama İletişimi almayı kabul ediyorum",
	"all_notifications" to "Tüm Bildirimler",
	"amortization" to "amortisman",
	"amount" to "miktar",
	"asset" to "varlık",
	"attach_file" to "Dosya ekle",
	"attachment" to "Ek",
	"auth_error" to "Eylem başarısız oldu, yeniden deneyin.",
	"available_for_paid" to "Bulut senkronizasyonu yalnızca ücretli abonelik altında kullanılabilir.",
	"backups_option" to "Yedekleme",
	"balance" to "bakiye",
	"bank_account" to "Banka Hesabı",
	"bill_number" to "Fatura Numarası",
	"cancel" to "İptal",
	"cancel_trial" to "İstediğiniz zaman iptal edin",
	"capital" to "sermaye",
	"cash" to "nakit",
	"categories" to "Kategoriler",
	"category" to "Kategori",
	"category_add" to "Kategori Ekle",
	"category_delete" to "Kategoriyi sil",
	"category_name" to "Kategori adı",
	"change_language" to Formattable { (language) -> "Fatura Dili: ${language}" },
	"change_language_label" to "Fatura Dili",
	"change_template" to "Şablonu değiştir",
	"city_hint" to "Şehir",
	"clear" to "berrak",
	"client" to "Müşteri",
	"client_actions" to "İstemci eylemleri",
	"client_deleted_message" to "İstemci silindi",
	"clients_error_required_fields" to "En az bir değer gereklidir.",
	"clients_option" to "Istemci",
	"close" to "Yakın",
	"cloud_sync" to "Bulut Senkronizasyonu",
	"coming_soon" to "Çok yakında!",
	"company_logo" to "Şirket Logosu",
	"company_name_hint" to "Şirket",
	"confirm_password_label" to "Parolayı onayla",
	"confirmation_title" to "Emin misin?",
	"convert_to_invoice" to "Faturaya dönüştür",
	"country_hint" to "Ülke",
	"create_client" to "İstemci Oluştur",
	"create_company" to "Şirket Oluştur",
	"create_document" to "Belge oluştur",
	"create_estimate" to "Tahmin",
	"create_invoice" to "Fatura",
	"create_item" to "Madde Oluştur",
	"create_new_client" to "Yeni istemci oluştur",
	"create_new_item" to "Yeni öğe oluştur",
	"create_new_tax" to "Yeni vergi oluştur",
	"create_signature" to "imza",
	"created" to "Oluşturulan",
	"credit" to "kredi",
	"customer" to "Müşteri",
	"date" to "Tarih",
	"debit" to "borç",
	"debt" to "borç",
	"default_currency" to Formattable { (currency) -> "Varsayılan para birimi: ${currency}" },
	"default_currency_label" to "Varsayılan para birimi",
	"delete" to "Silmek",
	"delete_account" to "Hesabı Sil",
	"delete_confirmation_message" to "Tüm verileriniz kaybolacaktır.",
	"delete_item_title" to "Öğe silinsin mi?",
	"delete_signature" to "İmzayı sil",
	"depreciation" to "amortisman",
	"description" to "Açıklama",
	"description_hint" to "Açıklama",
	"detailed_report" to "Detaylı Rapor",
	"disabled" to "Sakat",
	"discount" to "İndirim",
	"discount_hint" to "İskonto",
	"display_af" to "Afrikanca",
	"display_am" to "Amharca",
	"display_ar" to "Arapça",
	"display_as" to "Assamca",
	"display_az" to "Azerice",
	"display_be" to "Belarusça",
	"display_bg" to "Bulgarca",
	"display_bn" to "Bengalce",
	"display_bs" to "Boşnakça",
	"display_ca" to "Katalanca",
	"display_cs" to "Çek Cumhuriyeti",
	"display_da" to "Danimarka",
	"display_de" to "Alman",
	"display_el" to "Yunan",
	"display_en" to "İngilizce",
	"display_es" to "İspanyolca",
	"display_es_MX" to "İspanyolca (Meksika)",
	"display_et" to "Estonca",
	"display_eu" to "Bask",
	"display_fa" to "Farsça",
	"display_fi" to "Fince",
	"display_fil" to "Filipinli",
	"display_fr" to "Fransızca",
	"display_fr_CA" to "Kanada Fransızcası",
	"display_gl" to "Galiçyaca",
	"display_gu" to "Gujarati",
	"display_he" to "İbranice",
	"display_hi" to "Hintçe",
	"display_hr" to "Hırvatça",
	"display_hu" to "Macarca",
	"display_hy" to "Ermenice",
	"display_id" to "Endonezce",
	"display_is" to "İzlandaca",
	"display_it" to "İtalyan",
	"display_ja" to "Japonca",
	"display_ka" to "Gürcüce",
	"display_kk" to "Kazakça",
	"display_km" to "Orta Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Korece",
	"display_ky" to "Kırgız",
	"display_lo" to "Lao",
	"display_lt" to "Litvanyalı",
	"display_lv" to "Letonca",
	"display_mk" to "Makedonca",
	"display_ml" to "Malayalam",
	"display_mn" to "Moğolca",
	"display_mr" to "Marathi",
	"display_ms" to "Malay",
	"display_my" to "Birmanya",
	"display_nb" to "Norveççe Bokmål",
	"display_ne" to "Nepalce",
	"display_nl" to "Hollandaca",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Lehçe",
	"display_pt" to "Portekizce",
	"display_pt_BR" to "Portekizce (Brezilya)",
	"display_pt_PT" to "Avrupa Portekizcesi",
	"display_ro" to "Romence",
	"display_ru" to "Rusça",
	"display_si" to "Sinhala",
	"display_sk" to "Slovakça",
	"display_sl" to "Slovence",
	"display_sq" to "Arnavutça",
	"display_sr" to "Sırpça",
	"display_sv" to "İsveç",
	"display_sw" to "Swahili",
	"display_ta" to "Tamilce",
	"display_te" to "Telugu",
	"display_th" to "Tayland",
	"display_tr" to "Türkçe",
	"display_uk" to "Ukrayna",
	"display_ur" to "Urduca",
	"display_uz" to "Özbekçe",
	"display_vi" to "Vietnamca",
	"display_zh_Hant_TW" to "Çince (Tayvan, Geleneksel)",
	"display_zu" to "Zulu",
	"document_estimate" to "Tahmin",
	"document_invoice" to "Fatura",
	"document_quote" to "Fiyat Teklifi",
	"done" to "Bitti",
	"download_app" to "Uygulamayı indirin",
	"download_invoice" to "Faturayı İndir",
	"download_pdf" to "İndir PDF",
	"draw_signature" to "İmza çizme",
	"due" to Formattable { (date) -> "Son tarih ${date}" },
	"due_date" to "Son Tarih",
	"due_day" to "bir günde",
	"due_label" to "Neden -iyle",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} için son ödeme tarihi ${dueWhen}!" },
	"due_today" to "bugün",
	"due_week" to "bir hafta içinde",
	"duplicate" to "Kopya",
	"duration_day" to "gün",
	"duration_month" to "Ay",
	"duration_week" to "hafta",
	"duration_year" to "yıl",
	"email" to "E-posta",
	"email_blank_error" to "E-posta gerekli.",
	"email_error" to "Geçersiz e-posta adresi.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "İşiniz için teşekkürler. ${documentType}'ın tamamını görüntülemek için bu mektubun sonundaki düğmeyi tıklayın. ${dueDate} tarihine kadar geçerli olduğunu unutmayın." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Yeni bir ${documentType} ${invoiceNumber} aldınız!" },
	"email_invoice_cta" to Formattable { (documentType) -> "FATURA ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Sayın ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "İşiniz için teşekkür ederiz. ${documentType}'ın tamamını görüntülemek için bu mektubun sonundaki düğmeyi tıklayın. Lütfen bakiyeyi ${dueDate} tarihine kadar ödeyin." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Yeni bir ${documentType} ${invoiceNumber} aldınız!" },
	"email_reset_success_message" to "Gönderilen şifreyi sıfırlamak için e-posta gönderin, lütfen gelen kutunuzu kontrol edin.",
	"empty_clients_list_message" to "İzlerken müşterin yok. Yeni bir istemci ekleyin.",
	"empty_estimate_list_message" to "İlk tahmininizi oluşturun",
	"empty_expenses" to "Takip edilecek gideriniz yok. Yeni bir gider ekleyin.",
	"empty_invoice_list_message" to "İlk faturanızı oluşturma",
	"empty_items_list_message" to "Öğeniz yok, yeni bir öğe ekleyin!",
	"empty_signatures_list_message" to "İmza bulunamadı. Bir tane yarat!",
	"empty_taxes_list_message" to "Vergi kuralın yok. Yeni bir vergi kuralı ekleyin.",
	"empty_templates_list_message" to "Şablonlar kullanılamıyor.",
	"enabled" to "Etkinleştirildi",
	"entry" to "giriş",
	"equity" to "öz sermaye",
	"estimate_number" to "Tahmin #",
	"estimates" to "Tahmin",
	"estimates_report" to "Tahminler",
	"expense_bank_fees" to "Banka Ücretleri",
	"expense_benefits" to "Faydalar",
	"expense_communication" to "İletişim",
	"expense_debt" to "Borç",
	"expense_deleted" to "Gider silindi",
	"expense_depreciation" to "Amortisman",
	"expense_gifts" to "Hediyeler",
	"expense_insurance" to "Sigorta",
	"expense_inventory" to "Envanter",
	"expense_legal" to "Yasal",
	"expense_maintenance" to "Bakım",
	"expense_marketing" to "Pazarlama",
	"expense_meals" to "Yemekler",
	"expense_office_supplies" to "Ofis Malzemeleri",
	"expense_rent" to "Kira",
	"expense_salaries" to "Maaşlar",
	"expense_shopping" to "Alışveriş",
	"expense_software" to "Yazılım",
	"expense_status_available" to "Mevcut",
	"expense_status_processing" to "İşleniyor",
	"expense_tax" to "Vergi",
	"expense_training" to "Eğitim",
	"expense_transport" to "Ulaşım",
	"expense_travel" to "Seyahat",
	"expense_utility" to "Yarar",
	"expenses" to "Masraflar",
	"feedback_option" to "Geri besleme",
	"filter" to "Filtre",
	"first_name_hint" to "Ad",
	"forgot_password" to "Parolayı Sıfırla",
	"forgot_password_label" to "Parolayı unuttum",
	"from" to "Kaynak",
	"general_error" to "Hata oluştu, lütfen daha sonra tekrar deneyin.",
	"general_ledger" to "genel muhasebe",
	"go_back_label" to "Geri Git",
	"group_by" to "Grupla:",
	"image_view" to "Görüntü",
	"install" to "Yükle",
	"invalid_credentials_error" to "Geçersiz kimlik bilgileri.",
	"invalid_email_error" to "Geçersiz e-posta.",
	"invalid_user_error" to "Geçersiz kullanıcı kimlik bilgileri.",
	"invoice_deleted_message" to "Fatura silindi",
	"invoice_menu" to "Fatura menüsü",
	"invoice_number" to "Fatura #",
	"invoices" to "Fatura",
	"items_option" to "eşya",
	"keep" to "Tutmak",
	"language_selector" to Formattable { (seçili) -> "Dil ${seçili}" },
	"last_name_hint" to "Soyadı",
	"ledger" to "defter",
	"legal" to "Yasal",
	"legal_error" to "Hizmet Şartları ve Gizlilik Politikası kabul etmelidir",
	"liabilities" to "yükümlülükler",
	"loading" to "Yükleme",
	"login_required_error" to "Giriş gerekli.",
	"logo_view" to "Logo",
	"logout" to "Oturum kapatma",
	"logout_prompt" to "Verileriniz bulutla eşitlenmez. Faturalar, tahminler, istemciler ve oturum kapatmayla ilgili diğer bilgiler silinsin mi?",
	"main_screen" to "Ana ekran",
	"mark_paid" to "Ödendi Olarak İşaretle",
	"message" to "Mesaj",
	"missing_sender_msg" to "Gönderen bilgisi olmayan bir belge geçersiz olabilir.",
	"missing_sender_title" to "Gönderen bilgileri eksik",
	"month_april" to "Nisan",
	"month_april_short" to "Nisan",
	"month_august" to "Ağustos",
	"month_august_short" to "Ağu.",
	"month_december" to "Aralık",
	"month_december_short" to "Ara.",
	"month_february" to "Şubat",
	"month_february_short" to "Şubat",
	"month_january" to "Ocak",
	"month_january_short" to "Ocak",
	"month_july" to "Temmuz",
	"month_july_short" to "Temmuz",
	"month_june" to "Haziran",
	"month_june_short" to "Haziran",
	"month_march" to "Mart",
	"month_march_short" to "Mart",
	"month_may" to "Mayıs",
	"month_may_short" to "Mayıs",
	"month_november" to "Kasım",
	"month_november_short" to "Kas.",
	"month_october" to "Ekim",
	"month_october_short" to "Eki.",
	"month_september" to "Eylül",
	"month_september_short" to "Eyl.",
	"multifactor_error" to "Doğrulanamadı.",
	"new" to "Yeni",
	"news" to "Haberler",
	"next" to "Sonraki",
	"no" to "hayır",
	"no_data_available" to "Kullanılabilir veri yok",
	"no_search_results" to "Üzgünüm, sonuç bulamadık.",
	"none" to "Hiçbiri",
	"note_fields" to "Notlar",
	"note_hint" to "Not",
	"notifications_option" to "BİLDİRİM",
	"ok" to "Tamam",
	"onboarding" to "İşe Alım",
	"onboarding_aboutus_message" to "DingVoice'un temel parçaları sonsuza kadar ücretsizdir. Misyonumuz, işinizi büyütmenize yardımcı olmak, geri bildirimlerinizi paylaşarak gelişmemize yardımcı olmaktır.",
	"onboarding_aboutus_title" to "Yardım etmek için buradayız",
	"onboarding_paid_1" to "Profesyonel görünümlü şablonlara erişin",
	"onboarding_paid_2" to "Mali durumunuza daha derinlemesine bakın",
	"onboarding_paid_3" to "Sorunların hızlı çözülmesini sağlayın",
	"onboarding_paid_4" to "Belgelerinizi güvende tutun",
	"onboarding_paid_5" to "Fatura durum değişikliklerinden haberdar olun",
	"onboarding_paid_title" to "ile daha da hızlı büyüyün...",
	"onboarding_paywall_message" to "Tüm premium özelliklerin keyfini şimdi en iyi fiyata çıkarın.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} için yalnızca ${currencySymbol}${introAmount} ve ardından ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, süre) -> "Sadece ${currencySymbol}${amount} / ${süre}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} için ücretsiz ve ardından sadece ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} için ücretsiz, sonra ${currencySymbol}${introductoryAmount} için ${introductoryDuration} ${introPeriod} ve sonra sadece ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Otomatik yenilenebilir. İstediğiniz zaman iptal edin.",
	"onboarding_paywall_restore" to "Satın alma işlemini geri yükle",
	"onboarding_paywall_restore_failure" to "Satın alımları geri yükleme başarısız oldu, daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.",
	"onboarding_paywall_restore_success" to "Geri yükleme başarılı!",
	"onboarding_paywall_title" to "Mükemmelliğe Ulaşın!",
	"onboarding_welcome_message" to "Faturalarınızı ve tahminlerinizi yönetmek için eksiksiz bir araç seti.",
	"onboarding_welcome_title" to "DingVoice'a Hoş Geldiniz",
	"other_state" to "Diğer",
	"overdue_state" to "Gecik -miş",
	"paid_state" to "Ödenen",
	"password" to "Parola",
	"password_blank_error" to "Parola gerekli.",
	"password_mismatch_error" to "Parolalar eşleşmiyor.",
	"pay" to "ödeme",
	"payment_instructions_label" to "Ödeme Talimatları",
	"payment_reminders" to "Ödeme anımsatıcıları",
	"phone_number_fields" to "Telefon numarası",
	"phone_number_hint" to "Telefon numarası",
	"postcode_hint" to "Posta kodu",
	"preview" to "Önizleme",
	"preview_menu_hint" to "Önizleme menüsü",
	"price" to "Fiyat",
	"privacy_policy" to "Gizlilik Politikası",
	"profile" to "Profil",
	"profitable_client" to "En karlı müşteriniz...",
	"profits_report" to "Kazançlar",
	"promo_content" to "Tanıtım içeriği",
	"quantity_hint" to "Miktar",
	"quantity_label_hint" to "Miktar Etiketi",
	"query_info" to "Sorgu, giriş olarak hem maddelere hem de diğer vergi kurallarına başvuran özel hesaplamalar yazmanıza olanak tanır. Başka bir girişe bağlanmak için @ sembolü ve öğe veya vergi açıklaması ekleyin +, -, *, /, % - matematik işleçleri",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} istemciniz tarafından alındı." },
	"received_state" to "Alındı",
	"registration_code_fields" to "Kayıt Kodu",
	"reminder" to "Hatırlatıcı",
	"reports" to "Raporlar",
	"reports_not_available" to "Kullanılabilir raporlar yok, daha sonra tekrar gelin.",
	"reveal_password" to "Parolayı göster",
	"revenue" to "gelir",
	"sales_tax" to "KDV",
	"sales_tax_number" to "KDV numarası",
	"save" to "Kaydetmek",
	"scan_document" to "Belgeyi tara",
	"search" to "Arama",
	"select" to "Seçiniz",
	"select_icon" to "Simge seçin",
	"select_signature" to "İmza seç",
	"select_template" to "Şablon seç",
	"send" to "Gönder",
	"sent_state" to "Gönderilen",
	"setup_sender" to "Göndereni ayarla",
	"share_option" to "Paylaş",
	"ship_to" to "Gönderilecek",
	"sign_in" to "Oturum Aç",
	"sign_in_apple" to "Apple ile oturum açma",
	"sign_in_email" to "E-postayla oturum açma",
	"sign_in_facebook" to "Facebook ile oturum açma",
	"sign_in_google" to "Google ile oturum açma",
	"sign_up" to "Kaydol",
	"sign_up_label" to "Hesabınız yok mu?",
	"sign_up_link_label" to "Kaydol!",
	"signatures_option" to "Imza",
	"skip" to "Atla",
	"sold_items_report" to "Satılan Ürünler",
	"sort_by" to "Sıralama Ölçütü:",
	"status_update" to "Belge durum güncellemesi",
	"sub_annual" to "Yıllık",
	"sub_annual_price" to Formattable { (price) -> "${price}/yıl" },
	"sub_backups" to "Bulut Yedeklemeleri",
	"sub_insights" to "İçgörüler",
	"sub_month" to "Aylık",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ay" },
	"sub_notifications" to "Bildirimler",
	"sub_popup_message" to "Abonelikler yakında geliyor. Kısa bir süre sonra burayı tekrar kontrol edin.",
	"sub_support" to "Destek",
	"sub_templates" to "Şablonlar",
	"subscribe" to "Abone olmak",
	"subscription_header" to "İşinizi aşağıdakilerle büyütün:",
	"subscription_option" to "Abonelik",
	"subscriptions_load_fail" to "Üzgünüz, şu anda aboneliklerinizi alamıyoruz. Lütfen daha sonra tekrar deneyin veya yardım için destek ekibimizle iletişime geçin.",
	"subtotal" to Formattable { (sum) -> "Alt Toplam: ${sum}" },
	"subtotal_label" to "Ara Toplam",
	"sum" to "Toplam",
	"support_option" to "Destek",
	"switch_reports" to "Raporu Değiştir",
	"tax" to "Vergi",
	"tax_deleted_message" to "Vergi silindi",
	"tax_description_warning" to "Kullanılırsa bu bir faturada gösterilir",
	"tax_input_flat" to "Düz",
	"tax_input_percent" to "Yüzde",
	"tax_number_fields" to "Vergi Numarası",
	"tax_number_hint" to "Vergi Numarası",
	"tax_query_info_description" to "Sorgu Bilgisi",
	"tax_report" to "Vergi",
	"tax_rules_option" to "Vergi Kuralları",
	"taxed_clients_report" to "Vergilendirilmiş Müşteriler",
	"template" to "Şablon",
	"template_selector_info" to "Varsayılan şablonu değiştirmek için kaydırın",
	"template_unlock_promo" to "Premium ile daha fazla şablona erişin",
	"templates_option" to "Şablon",
	"terms_of_service" to "Hizmet Şartları",
	"thank_you" to "Teşekkürler",
	"to" to "Hedef",
	"total" to Formattable { (sum) -> "Toplam: ${sum}" },
	"total_label" to "Toplam",
	"undo_action" to "GERİ ALMAK",
	"unit" to "Birim",
	"unit_cost_hint" to "Birim Maliyet",
	"unknown_client" to "Bilinmeyen İstemci",
	"unlock" to "Kilidi aç",
	"unnamed_item" to "Adsız öğe",
	"upgrade" to "Yükseltmek",
	"user_collision_error" to "Hesap zaten var.",
	"valid_until" to "Şu Tarihe Kadar Geçerlidir",
	"warning" to "Uyarı!",
	"yes" to "evet",
    )
    
}