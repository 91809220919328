// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Km  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "ទទួលយក ${termsOfService} និង ${privacyPolicy}" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "លេខកូដមិនត្រឹមត្រូវ។"
override val add_client_action: String = "បន្ថែមអតិថិជន"
override val add_customer: String = "បន្ថែមអតិថិជន"
override val add_expense: String = "បន្ថែមការចំណាយ"
override val add_fields_label: String = "បន្ថែម៖"
override val add_invoice_action: String = "បង្កើត"
override val add_item_action: String = "បន្ថែមធាតុ"
override val add_item_button: String = "បន្ថែមធាតុ"
override val add_signature_action: String = "បន្ថែមហត្ថលេខា"
override val add_tax_action: String = "បន្ថែមពន្ធ"
override val address_fields: String = "អាស័យដ្ឋាន"
override val address_line_hint: String = "ជួរ​អាស័យ​ដ្ឋាន"
override val advanced: String = "កម្រិតខ្ពស់"
override val agree_marketing: String = "ខ្ញុំយល់ព្រមទទួលការទំនាក់ទំនងទីផ្សារផ្ទាល់"
override val all_notifications: String = "ការជូនដំណឹងទាំងអស់។"
override val amortization: String = "amortization"
override val amount: String = "ចំនួនទឹកប្រាក់"
override val asset: String = "asset"
override val attach_file: String = "ភ្ជាប់ឯកសារ"
override val attachment: String = "ឯកសារ​ភ្ជាប់"
override val auth_error: String = "សកម្មភាពបានបរាជ័យ សូមព្យាយាមម្តងទៀត។"
override val available_for_paid: String = "សមកាលកម្មលើពពកអាចប្រើបានតែក្រោមការជាវដែលបានបង់ប្រាក់ប៉ុណ្ណោះ។"
override val backups_option: String = "ការបម្រុងទុក"
override val balance: String = "balance"
override val bank_account: String = "គណនីធនាគារ"
override val bill_number: String = "លេខវិក័យប័ត្រ"
override val cancel: String = "បោះបង់"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "ប្រភេទ"
override val category: String = "ប្រភេទ"
override val category_add: String = "បន្ថែមប្រភេទ"
override val category_delete: String = "លុបប្រភេទ"
override val category_name: String = "ឈ្មោះប្រភេទ"
override val change_language: Formattable = Formattable { (language) -> "ភាសាវិក្កយបត្រ៖ ${language}" }
override val change_language_label: String = "ភាសាវិក្កយបត្រ"
override val change_template: String = "ផ្លាស់ប្តូរគំរូ"
override val city_hint: String = "ទីក្រុង"
override val clear: String = "ច្បាស់"
override val client: String = "អតិថិជន"
override val client_actions: String = "សកម្មភាពរបស់អតិថិជន"
override val client_deleted_message: String = "បានលុបអតិថិជន"
override val clients_error_required_fields: String = "យ៉ាងហោចណាស់តម្លៃមួយត្រូវបានទាមទារ។"
override val clients_option: String = "អតិថិជន"
override val close: String = "បិទ"
override val cloud_sync: String = "Cloud សមកាលកម្ម"
override val coming_soon: String = "មកដល់ឆាប់ៗនេះ!"
override val company_logo: String = "និមិត្តសញ្ញាក្រុមហ៊ុន"
override val company_name_hint: String = "ក្រុមហ៊ុន"
override val confirm_password_label: String = "បញ្ជាក់ពាក្យសម្ងាត់"
override val confirmation_title: String = "តើអ្នកប្រាកដរីទេ"
override val convert_to_invoice: String = "បម្លែងទៅជាវិក្កយបត្រ"
override val country_hint: String = "ប្រទេស"
override val create_client: String = "បង្កើតអតិថិជន"
override val create_company: String = "បង្កើតក្រុមហ៊ុន"
override val create_document: String = "បង្កើតឯកសារ"
override val create_estimate: String = "ការប៉ាន់ស្មាន"
override val create_invoice: String = "វិក្កយបត្រ"
override val create_item: String = "បង្កើតធាតុ"
override val create_new_client: String = "បង្កើតអតិថិជនថ្មី។"
override val create_new_item: String = "បង្កើតធាតុថ្មី។"
override val create_new_tax: String = "បង្កើតពន្ធថ្មី។"
override val create_signature: String = "ហត្ថលេខា"
override val created: String = "បានបង្កើត"
override val credit: String = "credit"
override val customer: String = "អតិថិជន"
override val date: String = "កាលបរិច្ឆេទ"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "រូបិយប័ណ្ណលំនាំដើម៖ ${currency}" }
override val default_currency_label: String = "រូបិយប័ណ្ណលំនាំដើម"
override val delete: String = "លុប"
override val delete_account: String = "លុប​គណនី"
override val delete_confirmation_message: String = "ទិន្នន័យរបស់អ្នកទាំងអស់នឹងត្រូវបាត់បង់។"
override val delete_item_title: String = "លុបធាតុ?"
override val delete_signature: String = "លុបហត្ថលេខា"
override val depreciation: String = "depreciation"
override val description: String = "ការពិពណ៌នា"
override val description_hint: String = "ការពិពណ៌នា"
override val detailed_report: String = "របាយការណ៍លម្អិត"
override val disabled: String = "ពិការ"
override val discount: String = "បញ្ចុះតម្លៃ"
override val discount_hint: String = "បញ្ចុះតម្លៃ"
override val display_af: String = "អាហ្រ្វិក"
override val display_am: String = "អាំហារិក"
override val display_ar: String = "ភាសាអារ៉ាប់"
override val display_as: String = "អាសាំ"
override val display_az: String = "អាស៊ែបៃហ្សង់"
override val display_be: String = "បេឡារុស្ស"
override val display_bg: String = "ប៊ុលហ្គារី"
override val display_bn: String = "បង់ក្លាដែស"
override val display_bs: String = "បូស្នៀ"
override val display_ca: String = "កាតាឡាន"
override val display_cs: String = "ឆេក"
override val display_da: String = "ដាណឺម៉ាក"
override val display_de: String = "អាឡឺម៉ង់"
override val display_el: String = "ក្រិក"
override val display_en: String = "ភាសាអង់គ្លេស"
override val display_es: String = "ភាសាអេស្ប៉ាញ"
override val display_es_MX: String = "អេស្ប៉ាញ (ម៉ិកស៊ិក)"
override val display_et: String = "អេស្តូនី"
override val display_eu: String = "បាស"
override val display_fa: String = "ពែរ្ស"
override val display_fi: String = "ហ្វាំងឡង់"
override val display_fil: String = "ហ្វីលីពីន"
override val display_fr: String = "បារាំង"
override val display_fr_CA: String = "កាណាដា បារាំង"
override val display_gl: String = "ហ្គាលីសៀន"
override val display_gu: String = "ហ្គូចារ៉ាទី"
override val display_he: String = "ភាសាហេព្រើរ"
override val display_hi: String = "ទេ"
override val display_hr: String = "ក្រូអាត"
override val display_hu: String = "ហុងគ្រី"
override val display_hy: String = "អាមេនី"
override val display_id: String = "ឥណ្ឌូនេស៊ី"
override val display_is: String = "អ៊ីស្លង់"
override val display_it: String = "អ៊ីតាលី"
override val display_ja: String = "ជប៉ុន"
override val display_ka: String = "ហ្សកហ្ស៊ី"
override val display_kk: String = "កាហ្សាក់ស្ថាន"
override val display_km: String = "ខ្មែរកណ្តាល"
override val display_kn: String = "កាណាដា"
override val display_ko: String = "កូរ៉េ"
override val display_ky: String = "គៀហ្គីស"
override val display_lo: String = "ពលកម្ម"
override val display_lt: String = "លីទុយអានី"
override val display_lv: String = "ឡាតវី"
override val display_mk: String = "ម៉ាសេដូនៀ"
override val display_ml: String = "ម៉ាឡាយ៉ាឡា"
override val display_mn: String = "ម៉ុងហ្គោលី"
override val display_mr: String = "ម៉ារ៉ាធី"
override val display_ms: String = "ម៉ាឡេ"
override val display_my: String = "ភូមា"
override val display_nb: String = "ន័រវែស Bokmål"
override val display_ne: String = "នេប៉ាល់"
override val display_nl: String = "ហូឡង់"
override val display_or: String = "អូរីយ៉ា"
override val display_pa: String = "ពុនចាប៊ី"
override val display_pl: String = "ប៉ូឡូញ"
override val display_pt: String = "ព័រទុយហ្គាល់"
override val display_pt_BR: String = "ព័រទុយហ្គាល់ (ប្រេស៊ីល)"
override val display_pt_PT: String = "ព័រទុយហ្គាល់អឺរ៉ុប"
override val display_ro: String = "រ៉ូម៉ានី"
override val display_ru: String = "រុស្សី"
override val display_si: String = "ស៊ីនហាឡា"
override val display_sk: String = "ស្លូវ៉ាគី"
override val display_sl: String = "ស្លូវេនី"
override val display_sq: String = "អាល់បានី"
override val display_sr: String = "ស៊ែប៊ី"
override val display_sv: String = "ស៊ុយអែត"
override val display_sw: String = "ស្វាហ៊ីលី"
override val display_ta: String = "តាមីល"
override val display_te: String = "តេលូហ្គូ"
override val display_th: String = "ថៃ"
override val display_tr: String = "ទួរគី"
override val display_uk: String = "អ៊ុយក្រែន"
override val display_ur: String = "អ៊ូឌូ"
override val display_uz: String = "អ៊ូសបេក"
override val display_vi: String = "វៀតណាម"
override val display_zh_Hant_TW: String = "ចិន (តៃវ៉ាន់ ប្រពៃណី)"
override val display_zu: String = "ហ្សូលូ"
override val document_estimate: String = "ការប៉ាន់ស្មាន"
override val document_invoice: String = "វិក្កយបត្រ"
override val document_quote: String = "សម្រង់"
override val done: String = "រួចរាល់"
override val download_app: String = "ទាញយកកម្មវិធី"
override val download_invoice: String = "ទាញយកវិក្កយបត្រ"
override val download_pdf: String = "ទាញយក PDF"
override val draw_signature: String = "គូរហត្ថលេខា"
override val due: Formattable = Formattable { (date) -> "ដល់កំណត់ ${date}" }
override val due_date: String = "កាលបរិច្ឆេទ​កំណត់"
override val due_day: String = "ក្នុងមួយថ្ងៃ"
override val due_label: String = "ដល់កំណត់"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ដល់កំណត់ ${dueWhen}!" }
override val due_today: String = "ថ្ងៃនេះ"
override val due_week: String = "ក្នុងមួយសប្តាហ៍"
override val duplicate: String = "ស្ទួន"
override val duration_day: String = "ថ្ងៃ"
override val duration_month: String = "ខែ"
override val duration_week: String = "សប្តាហ៍"
override val duration_year: String = "ឆ្នាំ"
override val email: String = "សារអេឡិចត្រូនិច"
override val email_blank_error: String = "ទាមទារអ៊ីមែល។"
override val email_error: String = "អាសយដ្ឋានអ៊ីមែលមិនត្រឹមត្រូវ។"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "សូមអរគុណសម្រាប់អាជីវកម្មរបស់អ្នក។ ដើម្បីមើល ${documentType} ពេញលេញ សូមចុចប៊ូតុងនៅចុងបញ្ចប់នៃអក្សរនេះ។ ចំណាំថាវាមានសុពលភាពរហូតដល់ ${dueDate}។" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "អ្នកបានទទួលថ្មី។${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "មើល ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "សូមគោរព ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "សូមអរគុណសម្រាប់អាជីវកម្មរបស់អ្នក។ ដើម្បីមើល ${documentType} ពេញលេញ សូមចុចប៊ូតុងនៅចុងបញ្ចប់នៃអក្សរនេះ។ សូមបង់សមតុល្យរហូតដល់ ${dueDate}។" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "អ្នកបានទទួល ${documentType} ${invoiceNumber} ថ្មី!" }
override val email_reset_success_message: String = "អ៊ីមែលដើម្បីកំណត់ពាក្យសម្ងាត់ដែលបានផ្ញើឡើងវិញ សូមពិនិត្យមើលប្រអប់សំបុត្ររបស់អ្នក។"
override val empty_clients_list_message: String = "អ្នកមិនមានអតិថិជនដើម្បីតាមដានទេ។ បន្ថែមអតិថិជនថ្មី។"
override val empty_estimate_list_message: String = "បង្កើតការប៉ាន់ស្មានដំបូងរបស់អ្នក។"
override val empty_expenses: String = "អ្នកមិនមានការចំណាយដើម្បីតាមដានទេ។ បន្ថែមការចំណាយថ្មី។"
override val empty_invoice_list_message: String = "បង្កើតវិក្កយបត្រដំបូងរបស់អ្នក។"
override val empty_items_list_message: String = "អ្នកមិនមានរបស់របរទេ សូមបន្ថែមធាតុថ្មី!"
override val empty_signatures_list_message: String = "រកមិនឃើញហត្ថលេខាទេ។ បង្កើតមួយ!"
override val empty_taxes_list_message: String = "អ្នកមិនមានច្បាប់ពន្ធទេ។ បន្ថែមច្បាប់ពន្ធថ្មី។"
override val empty_templates_list_message: String = "មិន​អាច​ប្រើ​ទម្រង់​គំរូ​បាន​ទេ។"
override val enabled: String = "បានបើក"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "ការប៉ាន់ស្មាន #"
override val estimates: String = "ការប៉ាន់ស្មាន"
override val estimates_report: String = "ការប៉ាន់ស្មាន"
override val expense_bank_fees: String = "ថ្លៃសេវាធនាគារ"
override val expense_benefits: String = "អត្ថប្រយោជន៍"
override val expense_communication: String = "ការ​ទំនាក់ទំនង"
override val expense_debt: String = "បំណុល"
override val expense_deleted: String = "បានលុបការចំណាយ"
override val expense_depreciation: String = "រំលោះ"
override val expense_gifts: String = "អំណោយ"
override val expense_insurance: String = "ធានារ៉ាប់រង"
override val expense_inventory: String = "សារពើភ័ណ្ឌ"
override val expense_legal: String = "ផ្លូវច្បាប់"
override val expense_maintenance: String = "ការថែទាំ"
override val expense_marketing: String = "ទីផ្សារ"
override val expense_meals: String = "អាហារ"
override val expense_office_supplies: String = "សម្ភារ​ការិយាល័យ"
override val expense_rent: String = "ជួល"
override val expense_salaries: String = "ប្រាក់ខែ"
override val expense_shopping: String = "ការដើរទិញឥវ៉ាន់"
override val expense_software: String = "កម្មវិធី"
override val expense_status_available: String = "មាន"
override val expense_status_processing: String = "ដំណើរការ"
override val expense_tax: String = "ពន្ធ"
override val expense_training: String = "ការបណ្តុះបណ្តាល"
override val expense_transport: String = "ការដឹកជញ្ជូន"
override val expense_travel: String = "ការធ្វើដំណើរ"
override val expense_utility: String = "ឧបករណ៍ប្រើប្រាស់"
override val expenses: String = "ការចំណាយ"
override val feedback_option: String = "មតិអ្នកប្រើ"
override val filter: String = "តម្រង"
override val first_name_hint: String = "ឈ្មោះដំបូង"
override val forgot_password: String = "កំណត់ពាក្យសម្ងាត់ឡើងវិញ"
override val forgot_password_label: String = "ភ្លេច​លេខសំងាត់​"
override val from: String = "ពី"
override val general_error: String = "កំហុសបានកើតឡើង សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។"
override val general_ledger: String = "general ledger"
override val go_back_label: String = "ត្រឡប់​ក្រោយ"
override val group_by: String = "ក្រុមដោយ៖"
override val image_view: String = "រូបភាព"
override val install: String = "ដំឡើង"
override val invalid_credentials_error: String = "លិខិត​សម្គាល់​មិន​ត្រឹមត្រូវ។"
override val invalid_email_error: String = "អ៊ីមែលមិនត្រឹមត្រូវ។"
override val invalid_user_error: String = "អត្តសញ្ញាណអ្នកប្រើប្រាស់មិនត្រឹមត្រូវ។"
override val invoice_deleted_message: String = "វិក្កយបត្រត្រូវបានលុប"
override val invoice_menu: String = "ម៉ឺនុយវិក្កយបត្រ"
override val invoice_number: String = "វិក្កយបត្រ #"
override val invoices: String = "វិក្កយបត្រ"
override val items_option: String = "ធាតុ"
override val keep: String = "រក្សា"
override val language_selector: Formattable = Formattable { (selected) -> "ភាសា ${selected}" }
override val last_name_hint: String = "នាមត្រកូល"
override val ledger: String = "ledger"
override val legal: String = "ផ្លូវច្បាប់"
override val legal_error: String = "ត្រូវតែទទួលយកលក្ខខណ្ឌនៃសេវាកម្ម និងគោលការណ៍ឯកជនភាព"
override val liabilities: String = "liabilities"
override val loading: String = "កំពុងផ្ទុក"
override val login_required_error: String = "តម្រូវឱ្យចូល។"
override val logo_view: String = "និមិត្តសញ្ញា"
override val logout: String = "ចាកចេញ"
override val logout_prompt: String = "ទិន្នន័យរបស់អ្នកមិនត្រូវបានធ្វើសមកាលកម្មជាមួយពពកទេ។ លុបវិក្កយបត្រ ការប៉ាន់ស្មាន អតិថិជន និងព័ត៌មានផ្សេងទៀតនៅពេលចេញ?"
override val main_screen: String = "អេក្រង់មេ"
override val mark_paid: String = "សម្គាល់ថាបានបង់"
override val message: String = "សារ"
override val missing_sender_msg: String = "ឯកសារដែលគ្មានព័ត៌មានអ្នកផ្ញើអាចមិនត្រឹមត្រូវ។"
override val missing_sender_title: String = "បាត់ព័ត៌មានអ្នកផ្ញើ"
override val month_april: String = "មេសា"
override val month_april_short: String = "មេសា"
override val month_august: String = "សីហា"
override val month_august_short: String = "សីហា"
override val month_december: String = "ខែធ្នូ"
override val month_december_short: String = "ខែធ្នូ"
override val month_february: String = "ខែកុម្ភៈ"
override val month_february_short: String = "ខែកុម្ភៈ"
override val month_january: String = "មករា"
override val month_january_short: String = "មករា"
override val month_july: String = "ខែកក្កដា"
override val month_july_short: String = "ខែកក្កដា"
override val month_june: String = "ខែមិថុនា"
override val month_june_short: String = "ខែមិថុនា"
override val month_march: String = "ខែមីនា"
override val month_march_short: String = "ខែមីនា"
override val month_may: String = "ឧសភា"
override val month_may_short: String = "ឧសភា"
override val month_november: String = "ខែវិច្ឆិកា"
override val month_november_short: String = "ខែវិច្ឆិកា"
override val month_october: String = "តុលា"
override val month_october_short: String = "តុលា"
override val month_september: String = "ខែកញ្ញា"
override val month_september_short: String = "កញ្ញា"
override val multifactor_error: String = "បរាជ័យក្នុងការផ្ទៀងផ្ទាត់។"
override val new: String = "ថ្មី។"
override val news: String = "ព័ត៌មាន"
override val next: String = "បន្ទាប់"
override val no: String = "no"
override val no_data_available: String = "មិនមានទិន្នន័យទេ។"
override val no_search_results: String = "សូមអភ័យទោស យើងមិនអាចរកឃើញលទ្ធផលណាមួយទេ។"
override val none: String = "គ្មាន"
override val note_fields: String = "កំណត់ចំណាំ"
override val note_hint: String = "ចំណាំ"
override val notifications_option: String = "ការជូនដំណឹង"
override val ok: String = "យល់ព្រម"
override val onboarding: String = "ដំណើរការ"
override val onboarding_aboutus_message: String = "ផ្នែកសំខាន់ៗនៃ DingVoice គឺឥតគិតថ្លៃជារៀងរហូត។ បេសកកម្មរបស់យើងគឺដើម្បីជួយអ្នកឱ្យរីកចម្រើនអាជីវកម្មរបស់អ្នក ជួយយើងឱ្យប្រសើរឡើងដោយការចែករំលែកមតិកែលម្អរបស់អ្នក។"
override val onboarding_aboutus_title: String = "យើងនៅទីនេះដើម្បីជួយ"
override val onboarding_paid_1: String = "ចូលប្រើគំរូដែលមានជំនាញវិជ្ជាជីវៈ"
override val onboarding_paid_2: String = "ពិនិត្យមើលឱ្យបានស៊ីជម្រៅអំពីហិរញ្ញវត្ថុរបស់អ្នក។"
override val onboarding_paid_3: String = "ដោះស្រាយបញ្ហាឱ្យបានឆាប់រហ័ស"
override val onboarding_paid_4: String = "រក្សាឯកសាររបស់អ្នកឱ្យមានសុវត្ថិភាព"
override val onboarding_paid_5: String = "ទទួលបានព័ត៌មានថ្មីៗជាមួយនឹងការផ្លាស់ប្តូរស្ថានភាពវិក្កយបត្រ"
override val onboarding_paid_title: String = "កាន់តែរីកកាន់តែលឿនជាមួយ..."
override val onboarding_paywall_message: String = "សូមរីករាយជាមួយមុខងារពិសេសទាំងអស់ឥឡូវនេះក្នុងតម្លៃដ៏ល្អបំផុត។"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "គ្រាន់តែ ${currencySymbol}${introAmount} សម្រាប់ ${introDuration} ${introPeriod} ហើយបន្ទាប់មក ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "គ្រាន់តែ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "ឥតគិតថ្លៃសម្រាប់ ${trialDuration} ${trialPeriod} ហើយបន្ទាប់មកគ្រាន់តែ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "ឥតគិតថ្លៃសម្រាប់ ${trialDuration} ${trialPeriod} បន្ទាប់មក ${currencySymbol}${introductoryAmount} សម្រាប់ ${introductoryDuration} ${introPeriod} ហើយបន្ទាប់មកគ្រាន់តែ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "អាចបន្តដោយស្វ័យប្រវត្តិ។ បោះបង់នៅពេលណាក៏បាន។"
override val onboarding_paywall_restore: String = "ស្តា​រ​ការ​ទិញ"
override val onboarding_paywall_restore_failure: String = "ការស្តារការទិញឡើងវិញបានបរាជ័យ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ ឬទាក់ទងផ្នែកជំនួយ។"
override val onboarding_paywall_restore_success: String = "ស្តារឡើងវិញដោយជោគជ័យ!"
override val onboarding_paywall_title: String = "ទទួលបានភាពអស្ចារ្យ!"
override val onboarding_welcome_message: String = "ឧបករណ៍ពេញលេញសម្រាប់គ្រប់គ្រងវិក្កយបត្រ និងការប៉ាន់ស្មានរបស់អ្នក។"
override val onboarding_welcome_title: String = "សូមស្វាគមន៍មកកាន់ DingVoice"
override val other_state: String = "ផ្សេងៗ"
override val overdue_state: String = "ហួសកាលកំណត់"
override val paid_state: String = "បង់"
override val password: String = "លេខសំងាត់"
override val password_blank_error: String = "ទាមទារពាក្យសម្ងាត់។"
override val password_mismatch_error: String = "ពាក្យសម្ងាត់មិនត្រូវគ្នាទេ។"
override val pay: String = "pay"
override val payment_instructions_label: String = "ការណែនាំអំពីការទូទាត់"
override val payment_reminders: String = "ការរំលឹកការទូទាត់"
override val phone_number_fields: String = "លេខទូរសព្ទ"
override val phone_number_hint: String = "លេខទូរសព្ទ"
override val postcode_hint: String = "លេខកូដប្រៃសណីយ៍"
override val preview: String = "មើលជាមុន"
override val preview_menu_hint: String = "ម៉ឺនុយមើលជាមុន"
override val price: String = "តម្លៃ"
override val privacy_policy: String = "គោលការណ៍ភាពឯកជន"
override val profile: String = "ប្រវត្តិរូប"
override val profitable_client: String = "អតិថិជនដែលរកចំនូលបានច្រើនបំផុតរបស់អ្នកគឺ..."
override val profits_report: String = "ប្រាក់ចំណេញ"
override val promo_content: String = "មាតិកាផ្សព្វផ្សាយ"
override val quantity_hint: String = "បរិមាណ"
override val quantity_label_hint: String = "ស្លាកបរិមាណ"
override val query_info: String = "សំណួរអនុញ្ញាតឱ្យអ្នកសរសេរការគណនាផ្ទាល់ខ្លួនដោយយោងទៅលើធាតុទាំងពីរ និងច្បាប់ពន្ធផ្សេងទៀតជាធាតុបញ្ចូល។ បន្ថែម @ និមិត្តសញ្ញា និងធាតុ ឬការពិពណ៌នាអំពីពន្ធ ដើម្បីភ្ជាប់ទៅធាតុផ្សេងទៀត +, -, *, /, % - ប្រតិបត្តិករគណិតវិទ្យា"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ត្រូវបានទទួលដោយអតិថិជនរបស់អ្នក។" }
override val received_state: String = "បានទទួល"
override val registration_code_fields: String = "លេខកូដចុះឈ្មោះ"
override val reminder: String = "ការ​រំលឹក"
override val reports: String = "របាយការណ៍"
override val reports_not_available: String = "មិនមានរបាយការណ៍ទេ សូមត្រលប់មកពេលក្រោយ។"
override val reveal_password: String = "បង្ហាញពាក្យសម្ងាត់"
override val revenue: String = "revenue"
override val sales_tax: String = "អាករ"
override val sales_tax_number: String = "លេខ VAT"
override val save: String = "រក្សាទុក"
override val scan_document: String = "ស្កេនឯកសារ"
override val search: String = "ស្វែងរក"
override val select: String = "ជ្រើសរើស"
override val select_icon: String = "ជ្រើសរើសរូបតំណាង"
override val select_signature: String = "ជ្រើសរើសហត្ថលេខា"
override val select_template: String = "ជ្រើសរើសគំរូ"
override val send: String = "ផ្ញើ"
override val sent_state: String = "បានផ្ញើ"
override val setup_sender: String = "រៀបចំអ្នកផ្ញើ"
override val share_option: String = "ចែករំលែក"
override val ship_to: String = "ផ្ញើ​ទៅ"
override val sign_in: String = "ចូល"
override val sign_in_apple: String = "ចូលជាមួយ Apple"
override val sign_in_email: String = "ចូលដោយប្រើអ៊ីមែល"
override val sign_in_facebook: String = "ចូលជាមួយ Facebook"
override val sign_in_google: String = "ចូលជាមួយ Google"
override val sign_up: String = "Sign Up"
override val sign_up_label: String = "មិនមានគណនីមែនទេ?"
override val sign_up_link_label: String = "ចុះ​ឈ្មោះ!"
override val signatures_option: String = "ហត្ថលេខា"
override val skip: String = "រំលង"
override val sold_items_report: String = "ទំនិញដែលបានលក់"
override val sort_by: String = "តម្រៀបតាម៖"
override val status_update: String = "ការធ្វើបច្ចុប្បន្នភាពស្ថានភាពឯកសារ"
override val sub_annual: String = "ប្រចាំឆ្នាំ"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/ឆ្នាំ" }
override val sub_backups: String = "ការបម្រុងទុកតាមពពក"
override val sub_insights: String = "ការយល់ដឹង"
override val sub_month: String = "ប្រចាំខែ"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ខែ" }
override val sub_notifications: String = "ការជុនដំណឹង"
override val sub_popup_message: String = "ការជាវនឹងមកដល់ក្នុងពេលឆាប់ៗនេះ។ សូមពិនិត្យមើលនៅទីនេះបន្តិចទៀត។"
override val sub_support: String = "គាំទ្រ"
override val sub_templates: String = "ពុម្ពគំរូ"
override val subscribe: String = "ជាវ"
override val subscription_header: String = "ពង្រីកអាជីវកម្មរបស់អ្នកជាមួយ៖"
override val subscription_option: String = "ការជាវ"
override val subscriptions_load_fail: String = "សូមអភ័យទោស យើងមិនអាចទាញយកការជាវរបស់អ្នកបានទេនៅពេលនេះ។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ ឬទាក់ទងក្រុមគាំទ្ររបស់យើងសម្រាប់ជំនួយ។"
override val subtotal: Formattable = Formattable { (sum) -> "សរុបរង៖ ${sum}" }
override val subtotal_label: String = "សរុបរង"
override val sum: String = "ផលបូក"
override val support_option: String = "គាំទ្រ"
override val switch_reports: String = "ប្តូររបាយការណ៍"
override val tax: String = "ពន្ធ"
override val tax_deleted_message: String = "ពន្ធត្រូវបានលុប"
override val tax_description_warning: String = "ប្រសិនបើប្រើ វានឹងបង្ហាញនៅលើវិក្កយបត្រ"
override val tax_input_flat: String = "ផ្ទះល្វែង"
override val tax_input_percent: String = "ភាគរយ"
override val tax_number_fields: String = "លេខពន្ធ"
override val tax_number_hint: String = "លេខពន្ធ"
override val tax_query_info_description: String = "សំណួរព័ត៌មាន"
override val tax_report: String = "ពន្ធ"
override val tax_rules_option: String = "ច្បាប់ពន្ធ"
override val taxed_clients_report: String = "អតិថិជនបង់ពន្ធ"
override val template: String = "គំរូ"
override val template_selector_info: String = "អូសដើម្បីផ្លាស់ប្តូរគំរូលំនាំដើម"
override val template_unlock_promo: String = "ចូលប្រើគំរូច្រើនទៀតដោយប្រើ Premium"
override val templates_option: String = "គំរូ"
override val terms_of_service: String = "លក្ខខណ្ឌ​នៃ​សេវាកម្ម"
override val thank_you: String = "សូមអរគុណ"
override val to: String = "ទៅ"
override val total: Formattable = Formattable { (sum) -> "សរុប៖ ${sum}" }
override val total_label: String = "សរុប"
override val undo_action: String = "មិនធ្វើវិញ"
override val unit: String = "ឯកតា"
override val unit_cost_hint: String = "តម្លៃឯកតា"
override val unknown_client: String = "អតិថិជនមិនស្គាល់"
override val unlock: String = "ដោះសោ"
override val unnamed_item: String = "ធាតុគ្មានឈ្មោះ"
override val upgrade: String = "ធ្វើឱ្យប្រសើរឡើង"
override val user_collision_error: String = "មានគណនីរួចហើយ។"
override val valid_until: String = "មាន​សុពលភាព​ដល់"
override val warning: String = "ព្រមាន!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "ទទួលយក ${termsOfService} និង ${privacyPolicy}" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "លេខកូដមិនត្រឹមត្រូវ។",
	"add_client_action" to "បន្ថែមអតិថិជន",
	"add_customer" to "បន្ថែមអតិថិជន",
	"add_expense" to "បន្ថែមការចំណាយ",
	"add_fields_label" to "បន្ថែម៖",
	"add_invoice_action" to "បង្កើត",
	"add_item_action" to "បន្ថែមធាតុ",
	"add_item_button" to "បន្ថែមធាតុ",
	"add_signature_action" to "បន្ថែមហត្ថលេខា",
	"add_tax_action" to "បន្ថែមពន្ធ",
	"address_fields" to "អាស័យដ្ឋាន",
	"address_line_hint" to "ជួរ​អាស័យ​ដ្ឋាន",
	"advanced" to "កម្រិតខ្ពស់",
	"agree_marketing" to "ខ្ញុំយល់ព្រមទទួលការទំនាក់ទំនងទីផ្សារផ្ទាល់",
	"all_notifications" to "ការជូនដំណឹងទាំងអស់។",
	"amortization" to "amortization",
	"amount" to "ចំនួនទឹកប្រាក់",
	"asset" to "asset",
	"attach_file" to "ភ្ជាប់ឯកសារ",
	"attachment" to "ឯកសារ​ភ្ជាប់",
	"auth_error" to "សកម្មភាពបានបរាជ័យ សូមព្យាយាមម្តងទៀត។",
	"available_for_paid" to "សមកាលកម្មលើពពកអាចប្រើបានតែក្រោមការជាវដែលបានបង់ប្រាក់ប៉ុណ្ណោះ។",
	"backups_option" to "ការបម្រុងទុក",
	"balance" to "balance",
	"bank_account" to "គណនីធនាគារ",
	"bill_number" to "លេខវិក័យប័ត្រ",
	"cancel" to "បោះបង់",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "ប្រភេទ",
	"category" to "ប្រភេទ",
	"category_add" to "បន្ថែមប្រភេទ",
	"category_delete" to "លុបប្រភេទ",
	"category_name" to "ឈ្មោះប្រភេទ",
	"change_language" to Formattable { (language) -> "ភាសាវិក្កយបត្រ៖ ${language}" },
	"change_language_label" to "ភាសាវិក្កយបត្រ",
	"change_template" to "ផ្លាស់ប្តូរគំរូ",
	"city_hint" to "ទីក្រុង",
	"clear" to "ច្បាស់",
	"client" to "អតិថិជន",
	"client_actions" to "សកម្មភាពរបស់អតិថិជន",
	"client_deleted_message" to "បានលុបអតិថិជន",
	"clients_error_required_fields" to "យ៉ាងហោចណាស់តម្លៃមួយត្រូវបានទាមទារ។",
	"clients_option" to "អតិថិជន",
	"close" to "បិទ",
	"cloud_sync" to "Cloud សមកាលកម្ម",
	"coming_soon" to "មកដល់ឆាប់ៗនេះ!",
	"company_logo" to "និមិត្តសញ្ញាក្រុមហ៊ុន",
	"company_name_hint" to "ក្រុមហ៊ុន",
	"confirm_password_label" to "បញ្ជាក់ពាក្យសម្ងាត់",
	"confirmation_title" to "តើអ្នកប្រាកដរីទេ",
	"convert_to_invoice" to "បម្លែងទៅជាវិក្កយបត្រ",
	"country_hint" to "ប្រទេស",
	"create_client" to "បង្កើតអតិថិជន",
	"create_company" to "បង្កើតក្រុមហ៊ុន",
	"create_document" to "បង្កើតឯកសារ",
	"create_estimate" to "ការប៉ាន់ស្មាន",
	"create_invoice" to "វិក្កយបត្រ",
	"create_item" to "បង្កើតធាតុ",
	"create_new_client" to "បង្កើតអតិថិជនថ្មី។",
	"create_new_item" to "បង្កើតធាតុថ្មី។",
	"create_new_tax" to "បង្កើតពន្ធថ្មី។",
	"create_signature" to "ហត្ថលេខា",
	"created" to "បានបង្កើត",
	"credit" to "credit",
	"customer" to "អតិថិជន",
	"date" to "កាលបរិច្ឆេទ",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "រូបិយប័ណ្ណលំនាំដើម៖ ${currency}" },
	"default_currency_label" to "រូបិយប័ណ្ណលំនាំដើម",
	"delete" to "លុប",
	"delete_account" to "លុប​គណនី",
	"delete_confirmation_message" to "ទិន្នន័យរបស់អ្នកទាំងអស់នឹងត្រូវបាត់បង់។",
	"delete_item_title" to "លុបធាតុ?",
	"delete_signature" to "លុបហត្ថលេខា",
	"depreciation" to "depreciation",
	"description" to "ការពិពណ៌នា",
	"description_hint" to "ការពិពណ៌នា",
	"detailed_report" to "របាយការណ៍លម្អិត",
	"disabled" to "ពិការ",
	"discount" to "បញ្ចុះតម្លៃ",
	"discount_hint" to "បញ្ចុះតម្លៃ",
	"display_af" to "អាហ្រ្វិក",
	"display_am" to "អាំហារិក",
	"display_ar" to "ភាសាអារ៉ាប់",
	"display_as" to "អាសាំ",
	"display_az" to "អាស៊ែបៃហ្សង់",
	"display_be" to "បេឡារុស្ស",
	"display_bg" to "ប៊ុលហ្គារី",
	"display_bn" to "បង់ក្លាដែស",
	"display_bs" to "បូស្នៀ",
	"display_ca" to "កាតាឡាន",
	"display_cs" to "ឆេក",
	"display_da" to "ដាណឺម៉ាក",
	"display_de" to "អាឡឺម៉ង់",
	"display_el" to "ក្រិក",
	"display_en" to "ភាសាអង់គ្លេស",
	"display_es" to "ភាសាអេស្ប៉ាញ",
	"display_es_MX" to "អេស្ប៉ាញ (ម៉ិកស៊ិក)",
	"display_et" to "អេស្តូនី",
	"display_eu" to "បាស",
	"display_fa" to "ពែរ្ស",
	"display_fi" to "ហ្វាំងឡង់",
	"display_fil" to "ហ្វីលីពីន",
	"display_fr" to "បារាំង",
	"display_fr_CA" to "កាណាដា បារាំង",
	"display_gl" to "ហ្គាលីសៀន",
	"display_gu" to "ហ្គូចារ៉ាទី",
	"display_he" to "ភាសាហេព្រើរ",
	"display_hi" to "ទេ",
	"display_hr" to "ក្រូអាត",
	"display_hu" to "ហុងគ្រី",
	"display_hy" to "អាមេនី",
	"display_id" to "ឥណ្ឌូនេស៊ី",
	"display_is" to "អ៊ីស្លង់",
	"display_it" to "អ៊ីតាលី",
	"display_ja" to "ជប៉ុន",
	"display_ka" to "ហ្សកហ្ស៊ី",
	"display_kk" to "កាហ្សាក់ស្ថាន",
	"display_km" to "ខ្មែរកណ្តាល",
	"display_kn" to "កាណាដា",
	"display_ko" to "កូរ៉េ",
	"display_ky" to "គៀហ្គីស",
	"display_lo" to "ពលកម្ម",
	"display_lt" to "លីទុយអានី",
	"display_lv" to "ឡាតវី",
	"display_mk" to "ម៉ាសេដូនៀ",
	"display_ml" to "ម៉ាឡាយ៉ាឡា",
	"display_mn" to "ម៉ុងហ្គោលី",
	"display_mr" to "ម៉ារ៉ាធី",
	"display_ms" to "ម៉ាឡេ",
	"display_my" to "ភូមា",
	"display_nb" to "ន័រវែស Bokmål",
	"display_ne" to "នេប៉ាល់",
	"display_nl" to "ហូឡង់",
	"display_or" to "អូរីយ៉ា",
	"display_pa" to "ពុនចាប៊ី",
	"display_pl" to "ប៉ូឡូញ",
	"display_pt" to "ព័រទុយហ្គាល់",
	"display_pt_BR" to "ព័រទុយហ្គាល់ (ប្រេស៊ីល)",
	"display_pt_PT" to "ព័រទុយហ្គាល់អឺរ៉ុប",
	"display_ro" to "រ៉ូម៉ានី",
	"display_ru" to "រុស្សី",
	"display_si" to "ស៊ីនហាឡា",
	"display_sk" to "ស្លូវ៉ាគី",
	"display_sl" to "ស្លូវេនី",
	"display_sq" to "អាល់បានី",
	"display_sr" to "ស៊ែប៊ី",
	"display_sv" to "ស៊ុយអែត",
	"display_sw" to "ស្វាហ៊ីលី",
	"display_ta" to "តាមីល",
	"display_te" to "តេលូហ្គូ",
	"display_th" to "ថៃ",
	"display_tr" to "ទួរគី",
	"display_uk" to "អ៊ុយក្រែន",
	"display_ur" to "អ៊ូឌូ",
	"display_uz" to "អ៊ូសបេក",
	"display_vi" to "វៀតណាម",
	"display_zh_Hant_TW" to "ចិន (តៃវ៉ាន់ ប្រពៃណី)",
	"display_zu" to "ហ្សូលូ",
	"document_estimate" to "ការប៉ាន់ស្មាន",
	"document_invoice" to "វិក្កយបត្រ",
	"document_quote" to "សម្រង់",
	"done" to "រួចរាល់",
	"download_app" to "ទាញយកកម្មវិធី",
	"download_invoice" to "ទាញយកវិក្កយបត្រ",
	"download_pdf" to "ទាញយក PDF",
	"draw_signature" to "គូរហត្ថលេខា",
	"due" to Formattable { (date) -> "ដល់កំណត់ ${date}" },
	"due_date" to "កាលបរិច្ឆេទ​កំណត់",
	"due_day" to "ក្នុងមួយថ្ងៃ",
	"due_label" to "ដល់កំណត់",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ដល់កំណត់ ${dueWhen}!" },
	"due_today" to "ថ្ងៃនេះ",
	"due_week" to "ក្នុងមួយសប្តាហ៍",
	"duplicate" to "ស្ទួន",
	"duration_day" to "ថ្ងៃ",
	"duration_month" to "ខែ",
	"duration_week" to "សប្តាហ៍",
	"duration_year" to "ឆ្នាំ",
	"email" to "សារអេឡិចត្រូនិច",
	"email_blank_error" to "ទាមទារអ៊ីមែល។",
	"email_error" to "អាសយដ្ឋានអ៊ីមែលមិនត្រឹមត្រូវ។",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "សូមអរគុណសម្រាប់អាជីវកម្មរបស់អ្នក។ ដើម្បីមើល ${documentType} ពេញលេញ សូមចុចប៊ូតុងនៅចុងបញ្ចប់នៃអក្សរនេះ។ ចំណាំថាវាមានសុពលភាពរហូតដល់ ${dueDate}។" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "អ្នកបានទទួលថ្មី។${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "មើល ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "សូមគោរព ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "សូមអរគុណសម្រាប់អាជីវកម្មរបស់អ្នក។ ដើម្បីមើល ${documentType} ពេញលេញ សូមចុចប៊ូតុងនៅចុងបញ្ចប់នៃអក្សរនេះ។ សូមបង់សមតុល្យរហូតដល់ ${dueDate}។" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "អ្នកបានទទួល ${documentType} ${invoiceNumber} ថ្មី!" },
	"email_reset_success_message" to "អ៊ីមែលដើម្បីកំណត់ពាក្យសម្ងាត់ដែលបានផ្ញើឡើងវិញ សូមពិនិត្យមើលប្រអប់សំបុត្ររបស់អ្នក។",
	"empty_clients_list_message" to "អ្នកមិនមានអតិថិជនដើម្បីតាមដានទេ។ បន្ថែមអតិថិជនថ្មី។",
	"empty_estimate_list_message" to "បង្កើតការប៉ាន់ស្មានដំបូងរបស់អ្នក។",
	"empty_expenses" to "អ្នកមិនមានការចំណាយដើម្បីតាមដានទេ។ បន្ថែមការចំណាយថ្មី។",
	"empty_invoice_list_message" to "បង្កើតវិក្កយបត្រដំបូងរបស់អ្នក។",
	"empty_items_list_message" to "អ្នកមិនមានរបស់របរទេ សូមបន្ថែមធាតុថ្មី!",
	"empty_signatures_list_message" to "រកមិនឃើញហត្ថលេខាទេ។ បង្កើតមួយ!",
	"empty_taxes_list_message" to "អ្នកមិនមានច្បាប់ពន្ធទេ។ បន្ថែមច្បាប់ពន្ធថ្មី។",
	"empty_templates_list_message" to "មិន​អាច​ប្រើ​ទម្រង់​គំរូ​បាន​ទេ។",
	"enabled" to "បានបើក",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "ការប៉ាន់ស្មាន #",
	"estimates" to "ការប៉ាន់ស្មាន",
	"estimates_report" to "ការប៉ាន់ស្មាន",
	"expense_bank_fees" to "ថ្លៃសេវាធនាគារ",
	"expense_benefits" to "អត្ថប្រយោជន៍",
	"expense_communication" to "ការ​ទំនាក់ទំនង",
	"expense_debt" to "បំណុល",
	"expense_deleted" to "បានលុបការចំណាយ",
	"expense_depreciation" to "រំលោះ",
	"expense_gifts" to "អំណោយ",
	"expense_insurance" to "ធានារ៉ាប់រង",
	"expense_inventory" to "សារពើភ័ណ្ឌ",
	"expense_legal" to "ផ្លូវច្បាប់",
	"expense_maintenance" to "ការថែទាំ",
	"expense_marketing" to "ទីផ្សារ",
	"expense_meals" to "អាហារ",
	"expense_office_supplies" to "សម្ភារ​ការិយាល័យ",
	"expense_rent" to "ជួល",
	"expense_salaries" to "ប្រាក់ខែ",
	"expense_shopping" to "ការដើរទិញឥវ៉ាន់",
	"expense_software" to "កម្មវិធី",
	"expense_status_available" to "មាន",
	"expense_status_processing" to "ដំណើរការ",
	"expense_tax" to "ពន្ធ",
	"expense_training" to "ការបណ្តុះបណ្តាល",
	"expense_transport" to "ការដឹកជញ្ជូន",
	"expense_travel" to "ការធ្វើដំណើរ",
	"expense_utility" to "ឧបករណ៍ប្រើប្រាស់",
	"expenses" to "ការចំណាយ",
	"feedback_option" to "មតិអ្នកប្រើ",
	"filter" to "តម្រង",
	"first_name_hint" to "ឈ្មោះដំបូង",
	"forgot_password" to "កំណត់ពាក្យសម្ងាត់ឡើងវិញ",
	"forgot_password_label" to "ភ្លេច​លេខសំងាត់​",
	"from" to "ពី",
	"general_error" to "កំហុសបានកើតឡើង សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ។",
	"general_ledger" to "general ledger",
	"go_back_label" to "ត្រឡប់​ក្រោយ",
	"group_by" to "ក្រុមដោយ៖",
	"image_view" to "រូបភាព",
	"install" to "ដំឡើង",
	"invalid_credentials_error" to "លិខិត​សម្គាល់​មិន​ត្រឹមត្រូវ។",
	"invalid_email_error" to "អ៊ីមែលមិនត្រឹមត្រូវ។",
	"invalid_user_error" to "អត្តសញ្ញាណអ្នកប្រើប្រាស់មិនត្រឹមត្រូវ។",
	"invoice_deleted_message" to "វិក្កយបត្រត្រូវបានលុប",
	"invoice_menu" to "ម៉ឺនុយវិក្កយបត្រ",
	"invoice_number" to "វិក្កយបត្រ #",
	"invoices" to "វិក្កយបត្រ",
	"items_option" to "ធាតុ",
	"keep" to "រក្សា",
	"language_selector" to Formattable { (selected) -> "ភាសា ${selected}" },
	"last_name_hint" to "នាមត្រកូល",
	"ledger" to "ledger",
	"legal" to "ផ្លូវច្បាប់",
	"legal_error" to "ត្រូវតែទទួលយកលក្ខខណ្ឌនៃសេវាកម្ម និងគោលការណ៍ឯកជនភាព",
	"liabilities" to "liabilities",
	"loading" to "កំពុងផ្ទុក",
	"login_required_error" to "តម្រូវឱ្យចូល។",
	"logo_view" to "និមិត្តសញ្ញា",
	"logout" to "ចាកចេញ",
	"logout_prompt" to "ទិន្នន័យរបស់អ្នកមិនត្រូវបានធ្វើសមកាលកម្មជាមួយពពកទេ។ លុបវិក្កយបត្រ ការប៉ាន់ស្មាន អតិថិជន និងព័ត៌មានផ្សេងទៀតនៅពេលចេញ?",
	"main_screen" to "អេក្រង់មេ",
	"mark_paid" to "សម្គាល់ថាបានបង់",
	"message" to "សារ",
	"missing_sender_msg" to "ឯកសារដែលគ្មានព័ត៌មានអ្នកផ្ញើអាចមិនត្រឹមត្រូវ។",
	"missing_sender_title" to "បាត់ព័ត៌មានអ្នកផ្ញើ",
	"month_april" to "មេសា",
	"month_april_short" to "មេសា",
	"month_august" to "សីហា",
	"month_august_short" to "សីហា",
	"month_december" to "ខែធ្នូ",
	"month_december_short" to "ខែធ្នូ",
	"month_february" to "ខែកុម្ភៈ",
	"month_february_short" to "ខែកុម្ភៈ",
	"month_january" to "មករា",
	"month_january_short" to "មករា",
	"month_july" to "ខែកក្កដា",
	"month_july_short" to "ខែកក្កដា",
	"month_june" to "ខែមិថុនា",
	"month_june_short" to "ខែមិថុនា",
	"month_march" to "ខែមីនា",
	"month_march_short" to "ខែមីនា",
	"month_may" to "ឧសភា",
	"month_may_short" to "ឧសភា",
	"month_november" to "ខែវិច្ឆិកា",
	"month_november_short" to "ខែវិច្ឆិកា",
	"month_october" to "តុលា",
	"month_october_short" to "តុលា",
	"month_september" to "ខែកញ្ញា",
	"month_september_short" to "កញ្ញា",
	"multifactor_error" to "បរាជ័យក្នុងការផ្ទៀងផ្ទាត់។",
	"new" to "ថ្មី។",
	"news" to "ព័ត៌មាន",
	"next" to "បន្ទាប់",
	"no" to "no",
	"no_data_available" to "មិនមានទិន្នន័យទេ។",
	"no_search_results" to "សូមអភ័យទោស យើងមិនអាចរកឃើញលទ្ធផលណាមួយទេ។",
	"none" to "គ្មាន",
	"note_fields" to "កំណត់ចំណាំ",
	"note_hint" to "ចំណាំ",
	"notifications_option" to "ការជូនដំណឹង",
	"ok" to "យល់ព្រម",
	"onboarding" to "ដំណើរការ",
	"onboarding_aboutus_message" to "ផ្នែកសំខាន់ៗនៃ DingVoice គឺឥតគិតថ្លៃជារៀងរហូត។ បេសកកម្មរបស់យើងគឺដើម្បីជួយអ្នកឱ្យរីកចម្រើនអាជីវកម្មរបស់អ្នក ជួយយើងឱ្យប្រសើរឡើងដោយការចែករំលែកមតិកែលម្អរបស់អ្នក។",
	"onboarding_aboutus_title" to "យើងនៅទីនេះដើម្បីជួយ",
	"onboarding_paid_1" to "ចូលប្រើគំរូដែលមានជំនាញវិជ្ជាជីវៈ",
	"onboarding_paid_2" to "ពិនិត្យមើលឱ្យបានស៊ីជម្រៅអំពីហិរញ្ញវត្ថុរបស់អ្នក។",
	"onboarding_paid_3" to "ដោះស្រាយបញ្ហាឱ្យបានឆាប់រហ័ស",
	"onboarding_paid_4" to "រក្សាឯកសាររបស់អ្នកឱ្យមានសុវត្ថិភាព",
	"onboarding_paid_5" to "ទទួលបានព័ត៌មានថ្មីៗជាមួយនឹងការផ្លាស់ប្តូរស្ថានភាពវិក្កយបត្រ",
	"onboarding_paid_title" to "កាន់តែរីកកាន់តែលឿនជាមួយ...",
	"onboarding_paywall_message" to "សូមរីករាយជាមួយមុខងារពិសេសទាំងអស់ឥឡូវនេះក្នុងតម្លៃដ៏ល្អបំផុត។",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "គ្រាន់តែ ${currencySymbol}${introAmount} សម្រាប់ ${introDuration} ${introPeriod} ហើយបន្ទាប់មក ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "គ្រាន់តែ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "ឥតគិតថ្លៃសម្រាប់ ${trialDuration} ${trialPeriod} ហើយបន្ទាប់មកគ្រាន់តែ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "ឥតគិតថ្លៃសម្រាប់ ${trialDuration} ${trialPeriod} បន្ទាប់មក ${currencySymbol}${introductoryAmount} សម្រាប់ ${introductoryDuration} ${introPeriod} ហើយបន្ទាប់មកគ្រាន់តែ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "អាចបន្តដោយស្វ័យប្រវត្តិ។ បោះបង់នៅពេលណាក៏បាន។",
	"onboarding_paywall_restore" to "ស្តា​រ​ការ​ទិញ",
	"onboarding_paywall_restore_failure" to "ការស្តារការទិញឡើងវិញបានបរាជ័យ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ ឬទាក់ទងផ្នែកជំនួយ។",
	"onboarding_paywall_restore_success" to "ស្តារឡើងវិញដោយជោគជ័យ!",
	"onboarding_paywall_title" to "ទទួលបានភាពអស្ចារ្យ!",
	"onboarding_welcome_message" to "ឧបករណ៍ពេញលេញសម្រាប់គ្រប់គ្រងវិក្កយបត្រ និងការប៉ាន់ស្មានរបស់អ្នក។",
	"onboarding_welcome_title" to "សូមស្វាគមន៍មកកាន់ DingVoice",
	"other_state" to "ផ្សេងៗ",
	"overdue_state" to "ហួសកាលកំណត់",
	"paid_state" to "បង់",
	"password" to "លេខសំងាត់",
	"password_blank_error" to "ទាមទារពាក្យសម្ងាត់។",
	"password_mismatch_error" to "ពាក្យសម្ងាត់មិនត្រូវគ្នាទេ។",
	"pay" to "pay",
	"payment_instructions_label" to "ការណែនាំអំពីការទូទាត់",
	"payment_reminders" to "ការរំលឹកការទូទាត់",
	"phone_number_fields" to "លេខទូរសព្ទ",
	"phone_number_hint" to "លេខទូរសព្ទ",
	"postcode_hint" to "លេខកូដប្រៃសណីយ៍",
	"preview" to "មើលជាមុន",
	"preview_menu_hint" to "ម៉ឺនុយមើលជាមុន",
	"price" to "តម្លៃ",
	"privacy_policy" to "គោលការណ៍ភាពឯកជន",
	"profile" to "ប្រវត្តិរូប",
	"profitable_client" to "អតិថិជនដែលរកចំនូលបានច្រើនបំផុតរបស់អ្នកគឺ...",
	"profits_report" to "ប្រាក់ចំណេញ",
	"promo_content" to "មាតិកាផ្សព្វផ្សាយ",
	"quantity_hint" to "បរិមាណ",
	"quantity_label_hint" to "ស្លាកបរិមាណ",
	"query_info" to "សំណួរអនុញ្ញាតឱ្យអ្នកសរសេរការគណនាផ្ទាល់ខ្លួនដោយយោងទៅលើធាតុទាំងពីរ និងច្បាប់ពន្ធផ្សេងទៀតជាធាតុបញ្ចូល។ បន្ថែម @ និមិត្តសញ្ញា និងធាតុ ឬការពិពណ៌នាអំពីពន្ធ ដើម្បីភ្ជាប់ទៅធាតុផ្សេងទៀត +, -, *, /, % - ប្រតិបត្តិករគណិតវិទ្យា",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ត្រូវបានទទួលដោយអតិថិជនរបស់អ្នក។" },
	"received_state" to "បានទទួល",
	"registration_code_fields" to "លេខកូដចុះឈ្មោះ",
	"reminder" to "ការ​រំលឹក",
	"reports" to "របាយការណ៍",
	"reports_not_available" to "មិនមានរបាយការណ៍ទេ សូមត្រលប់មកពេលក្រោយ។",
	"reveal_password" to "បង្ហាញពាក្យសម្ងាត់",
	"revenue" to "revenue",
	"sales_tax" to "អាករ",
	"sales_tax_number" to "លេខ VAT",
	"save" to "រក្សាទុក",
	"scan_document" to "ស្កេនឯកសារ",
	"search" to "ស្វែងរក",
	"select" to "ជ្រើសរើស",
	"select_icon" to "ជ្រើសរើសរូបតំណាង",
	"select_signature" to "ជ្រើសរើសហត្ថលេខា",
	"select_template" to "ជ្រើសរើសគំរូ",
	"send" to "ផ្ញើ",
	"sent_state" to "បានផ្ញើ",
	"setup_sender" to "រៀបចំអ្នកផ្ញើ",
	"share_option" to "ចែករំលែក",
	"ship_to" to "ផ្ញើ​ទៅ",
	"sign_in" to "ចូល",
	"sign_in_apple" to "ចូលជាមួយ Apple",
	"sign_in_email" to "ចូលដោយប្រើអ៊ីមែល",
	"sign_in_facebook" to "ចូលជាមួយ Facebook",
	"sign_in_google" to "ចូលជាមួយ Google",
	"sign_up" to "Sign Up",
	"sign_up_label" to "មិនមានគណនីមែនទេ?",
	"sign_up_link_label" to "ចុះ​ឈ្មោះ!",
	"signatures_option" to "ហត្ថលេខា",
	"skip" to "រំលង",
	"sold_items_report" to "ទំនិញដែលបានលក់",
	"sort_by" to "តម្រៀបតាម៖",
	"status_update" to "ការធ្វើបច្ចុប្បន្នភាពស្ថានភាពឯកសារ",
	"sub_annual" to "ប្រចាំឆ្នាំ",
	"sub_annual_price" to Formattable { (price) -> "${price}/ឆ្នាំ" },
	"sub_backups" to "ការបម្រុងទុកតាមពពក",
	"sub_insights" to "ការយល់ដឹង",
	"sub_month" to "ប្រចាំខែ",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ខែ" },
	"sub_notifications" to "ការជុនដំណឹង",
	"sub_popup_message" to "ការជាវនឹងមកដល់ក្នុងពេលឆាប់ៗនេះ។ សូមពិនិត្យមើលនៅទីនេះបន្តិចទៀត។",
	"sub_support" to "គាំទ្រ",
	"sub_templates" to "ពុម្ពគំរូ",
	"subscribe" to "ជាវ",
	"subscription_header" to "ពង្រីកអាជីវកម្មរបស់អ្នកជាមួយ៖",
	"subscription_option" to "ការជាវ",
	"subscriptions_load_fail" to "សូមអភ័យទោស យើងមិនអាចទាញយកការជាវរបស់អ្នកបានទេនៅពេលនេះ។ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ ឬទាក់ទងក្រុមគាំទ្ររបស់យើងសម្រាប់ជំនួយ។",
	"subtotal" to Formattable { (sum) -> "សរុបរង៖ ${sum}" },
	"subtotal_label" to "សរុបរង",
	"sum" to "ផលបូក",
	"support_option" to "គាំទ្រ",
	"switch_reports" to "ប្តូររបាយការណ៍",
	"tax" to "ពន្ធ",
	"tax_deleted_message" to "ពន្ធត្រូវបានលុប",
	"tax_description_warning" to "ប្រសិនបើប្រើ វានឹងបង្ហាញនៅលើវិក្កយបត្រ",
	"tax_input_flat" to "ផ្ទះល្វែង",
	"tax_input_percent" to "ភាគរយ",
	"tax_number_fields" to "លេខពន្ធ",
	"tax_number_hint" to "លេខពន្ធ",
	"tax_query_info_description" to "សំណួរព័ត៌មាន",
	"tax_report" to "ពន្ធ",
	"tax_rules_option" to "ច្បាប់ពន្ធ",
	"taxed_clients_report" to "អតិថិជនបង់ពន្ធ",
	"template" to "គំរូ",
	"template_selector_info" to "អូសដើម្បីផ្លាស់ប្តូរគំរូលំនាំដើម",
	"template_unlock_promo" to "ចូលប្រើគំរូច្រើនទៀតដោយប្រើ Premium",
	"templates_option" to "គំរូ",
	"terms_of_service" to "លក្ខខណ្ឌ​នៃ​សេវាកម្ម",
	"thank_you" to "សូមអរគុណ",
	"to" to "ទៅ",
	"total" to Formattable { (sum) -> "សរុប៖ ${sum}" },
	"total_label" to "សរុប",
	"undo_action" to "មិនធ្វើវិញ",
	"unit" to "ឯកតា",
	"unit_cost_hint" to "តម្លៃឯកតា",
	"unknown_client" to "អតិថិជនមិនស្គាល់",
	"unlock" to "ដោះសោ",
	"unnamed_item" to "ធាតុគ្មានឈ្មោះ",
	"upgrade" to "ធ្វើឱ្យប្រសើរឡើង",
	"user_collision_error" to "មានគណនីរួចហើយ។",
	"valid_until" to "មាន​សុពលភាព​ដល់",
	"warning" to "ព្រមាន!",
	"yes" to "yes",
    )
    
}