// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ja  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} と ${privacyPolicy} に同意してください" }
override val account: String = "アカウント"
override val accounting: String = "会計"
override val accruals: String = "発生"
override val action_code_error: String = "無効なコードです。"
override val add_client_action: String = "クライアントの追加"
override val add_customer: String = "お客様の追加"
override val add_expense: String = "経費を追加"
override val add_fields_label: String = "追加です。"
override val add_invoice_action: String = "クリエイト"
override val add_item_action: String = "アイテムの追加"
override val add_item_button: String = "アイテムの追加"
override val add_signature_action: String = "署名の追加"
override val add_tax_action: String = "付加価値税"
override val address_fields: String = "アドレス"
override val address_line_hint: String = "アドレスライン"
override val advanced: String = "アドバンスド"
override val agree_marketing: String = "ダイレクト・マーケティング・コミュニケーションの受信に同意します。"
override val all_notifications: String = "すべての通知"
override val amortization: String = "償却"
override val amount: String = "金額"
override val asset: String = "資産"
override val attach_file: String = "ファイルを添付"
override val attachment: String = "アタッチメント"
override val auth_error: String = "アクションが失敗したので、もう一度やり直してください。"
override val available_for_paid: String = "クラウドシンクは、有料のサブスクリプションでのみ利用可能です。"
override val backups_option: String = "バックアップ"
override val balance: String = "残高"
override val bank_account: String = "銀行口座"
override val bill_number: String = "請求書番号"
override val cancel: String = "キャンセル"
override val cancel_trial: String = "いつでもキャンセル可能"
override val capital: String = "資本"
override val cash: String = "現金"
override val categories: String = "カテゴリー"
override val category: String = "カテゴリー"
override val category_add: String = "カテゴリを追加"
override val category_delete: String = "カテゴリーを削除"
override val category_name: String = "カテゴリー名"
override val change_language: Formattable = Formattable { (language) -> "インボイスの言語: ${language}" }
override val change_language_label: String = "インボイスの言語"
override val change_template: String = "テンプレートの変更"
override val city_hint: String = "シティ"
override val clear: String = "クリア"
override val client: String = "クライアント"
override val client_actions: String = "クライアントの行動"
override val client_deleted_message: String = "クライアントの削除"
override val clients_error_required_fields: String = "最低でも1つの値が必要です。"
override val clients_option: String = "クライアント"
override val close: String = "閉じる"
override val cloud_sync: String = "クラウドシンク"
override val coming_soon: String = "もうすぐです。"
override val company_logo: String = "会社ロゴ"
override val company_name_hint: String = "会社概要"
override val confirm_password_label: String = "パスワードの確認"
override val confirmation_title: String = "本気ですか？"
override val convert_to_invoice: String = "インボイスへの変換"
override val country_hint: String = "国名"
override val create_client: String = "クライアントの作成"
override val create_company: String = "クリエイトカンパニー"
override val create_document: String = "ドキュメントの作成"
override val create_estimate: String = "見積もり"
override val create_invoice: String = "インボイス"
override val create_item: String = "アイテムの作成"
override val create_new_client: String = "新規クライアントの作成"
override val create_new_item: String = "新規アイテムの作成"
override val create_new_tax: String = "新しい税金を作る"
override val create_signature: String = "シグネチャー"
override val created: String = "作成"
override val credit: String = "クレジット"
override val customer: String = "お客様"
override val date: String = "日付"
override val debit: String = "デビット"
override val debt: String = "借金"
override val default_currency: Formattable = Formattable { (currency) -> "既定通貨 ${currency}" }
override val default_currency_label: String = "デフォルト通貨"
override val delete: String = "削除"
override val delete_account: String = "アカウントを削除"
override val delete_confirmation_message: String = "すべてのデータが失われます。"
override val delete_item_title: String = "アイテムの削除は？"
override val delete_signature: String = "署名の削除"
override val depreciation: String = "減価償却費"
override val description: String = "説明"
override val description_hint: String = "説明"
override val detailed_report: String = "詳細レポート"
override val disabled: String = "無効化"
override val discount: String = "割引"
override val discount_hint: String = "割引"
override val display_af: String = "アフリカーンス"
override val display_am: String = "アムハラ語"
override val display_ar: String = "アラビア語"
override val display_as: String = "アッサム語"
override val display_az: String = "アゼルバイジャン語"
override val display_be: String = "ベラルーシ語"
override val display_bg: String = "ブルガリア語"
override val display_bn: String = "ベンガル語"
override val display_bs: String = "ボスニア語"
override val display_ca: String = "カタロニア語"
override val display_cs: String = "チェコ語"
override val display_da: String = "デンマーク語"
override val display_de: String = "ドイツ語"
override val display_el: String = "グリーク"
override val display_en: String = "英語"
override val display_es: String = "スペイン語"
override val display_es_MX: String = "スペイン語（メキシコ）"
override val display_et: String = "エストニア語"
override val display_eu: String = "バスク"
override val display_fa: String = "ペルシャ語"
override val display_fi: String = "フィンランド語"
override val display_fil: String = "フィリピン人"
override val display_fr: String = "フランス語"
override val display_fr_CA: String = "カナディアンフレンチ"
override val display_gl: String = "ガリシア語"
override val display_gu: String = "グジャラート"
override val display_he: String = "ヘブライ語"
override val display_hi: String = "ヒンディー語"
override val display_hr: String = "クロアチア語"
override val display_hu: String = "ハンガリー語"
override val display_hy: String = "アルメニア語"
override val display_id: String = "インドネシア語"
override val display_is: String = "アイスランド語"
override val display_it: String = "イタリア語"
override val display_ja: String = "日本語"
override val display_ka: String = "グルジア語"
override val display_kk: String = "カザフスタン"
override val display_km: String = "中央クメール"
override val display_kn: String = "カンナダ語"
override val display_ko: String = "韓国語"
override val display_ky: String = "キルヒーズ"
override val display_lo: String = "ラオウ"
override val display_lt: String = "リトアニア語"
override val display_lv: String = "ラトビア語"
override val display_mk: String = "マケドニア語"
override val display_ml: String = "マラヤーラム語"
override val display_mn: String = "モンゴル語"
override val display_mr: String = "マラーティー語"
override val display_ms: String = "マレイ"
override val display_my: String = "ビルマ語"
override val display_nb: String = "ノルウェー語Bokmål"
override val display_ne: String = "ネパール語"
override val display_nl: String = "オランダ語"
override val display_or: String = "オリヤー"
override val display_pa: String = "パンジャブ語"
override val display_pl: String = "ポーランド語"
override val display_pt: String = "ポルトガル語"
override val display_pt_BR: String = "ポルトガル語（ブラジル）"
override val display_pt_PT: String = "欧州ポルトガル語"
override val display_ro: String = "ルーマニア語"
override val display_ru: String = "ロシア語"
override val display_si: String = "シンハラ語"
override val display_sk: String = "スロバキア語"
override val display_sl: String = "スロベニア語"
override val display_sq: String = "アルバニア語"
override val display_sr: String = "セルビア人"
override val display_sv: String = "スウェーデン語"
override val display_sw: String = "スワヒリ語"
override val display_ta: String = "タミル語"
override val display_te: String = "テルグ語"
override val display_th: String = "タイ人"
override val display_tr: String = "トルコ語"
override val display_uk: String = "ウクライナ人"
override val display_ur: String = "ウルドゥー語"
override val display_uz: String = "ウズベク語"
override val display_vi: String = "ベトナム語"
override val display_zh_Hant_TW: String = "中国語 (台湾、繁体)"
override val display_zu: String = "ズールー語"
override val document_estimate: String = "見積もり"
override val document_invoice: String = "インボイス"
override val document_quote: String = "価格提示"
override val done: String = "完了"
override val download_app: String = "アプリをダウンロードする"
override val download_invoice: String = "請求書をダウンロード"
override val download_pdf: String = "PDFダウンロード"
override val draw_signature: String = "サインを描く"
override val due: Formattable = Formattable { (date) -> "期限 ${date}。" }
override val due_date: String = "期限"
override val due_day: String = "1日以内"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} の期限は ${dueWhen} です!" }
override val due_today: String = "今日"
override val due_week: String = "一週間以内に"
override val duplicate: String = "複製"
override val duration_day: String = "日"
override val duration_month: String = "月"
override val duration_week: String = "週"
override val duration_year: String = "年"
override val email: String = "電子メール"
override val email_blank_error: String = "Eメールが必要です。"
override val email_error: String = "メールアドレスが無効です。"
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "お買い上げくださってありがとうございます。完全な ${documentType} を表示するには、このレターの末尾にあるボタンをクリックしてください。 ${dueDate} まで有効であることに注意してください。" }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "新しい ${documentType} ${invoiceNumber} を受け取りました!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType} を表示" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "拝啓 ${clientName}様" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "お買い上げくださってありがとうございます。完全な ${documentType} を表示するには、このレターの末尾にあるボタンをクリックしてください。 残金は ${dueDate} までお支払いください。" }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "新しい ${documentType} ${invoiceNumber} を受け取りました!" }
override val email_reset_success_message: String = "パスワード再設定用のメールを送信しましたので、受信箱をご確認ください。"
override val empty_clients_list_message: String = "追跡できるクライアントがありません。新しいクライアントを追加してください。"
override val empty_estimate_list_message: String = "最初の見積書の作成"
override val empty_expenses: String = "追跡する経費がありません。新しい支出を追加してください。"
override val empty_invoice_list_message: String = "最初の請求書の作成"
override val empty_items_list_message: String = "あなたはアイテムを持っていないので、新しいアイテムを追加してください。"
override val empty_signatures_list_message: String = "シグネチャーが見つかりません。作成してください"
override val empty_taxes_list_message: String = "タックスルールがありません。新しいタックスルールを追加してください。"
override val empty_templates_list_message: String = "テンプレートは使用できません。"
override val enabled: String = "有効"
override val entry: String = "アイテム"
override val equity: String = "エクイティ"
override val estimate_number: String = "推定 #"
override val estimates: String = "見積もり"
override val estimates_report: String = "見積り"
override val expense_bank_fees: String = "銀行手数料"
override val expense_benefits: String = "特典"
override val expense_communication: String = "コミュニケーション"
override val expense_debt: String = "借金"
override val expense_deleted: String = "経費が削除されました"
override val expense_depreciation: String = "減価償却費"
override val expense_gifts: String = "ギフト"
override val expense_insurance: String = "保険"
override val expense_inventory: String = "インベントリー"
override val expense_legal: String = "リーガル"
override val expense_maintenance: String = "メンテナンス"
override val expense_marketing: String = "マーケティング"
override val expense_meals: String = "食事"
override val expense_office_supplies: String = "事務用品"
override val expense_rent: String = "家賃"
override val expense_salaries: String = "給与"
override val expense_shopping: String = "ショッピング"
override val expense_software: String = "ソフトウェア"
override val expense_status_available: String = "利用可能"
override val expense_status_processing: String = "処理中"
override val expense_tax: String = "税"
override val expense_training: String = "トレーニング"
override val expense_transport: String = "輸送"
override val expense_travel: String = "トラベル"
override val expense_utility: String = "ユーティリティ"
override val expenses: String = "経費"
override val feedback_option: String = "フィードバック"
override val filter: String = "フィルター"
override val first_name_hint: String = "名前"
override val forgot_password: String = "パスワードの再設定"
override val forgot_password_label: String = "パスワードを忘れた場合"
override val from: String = "より"
override val general_error: String = "エラーが発生しました。後でもう一度試してください。"
override val general_ledger: String = "総勘定元帳"
override val go_back_label: String = "戻る"
override val group_by: String = "Group By:"
override val image_view: String = "イメージ"
override val install: String = "インストール"
override val invalid_credentials_error: String = "認証情報が無効です。"
override val invalid_email_error: String = "無効なメールです。"
override val invalid_user_error: String = "ユーザー認証が無効です。"
override val invoice_deleted_message: String = "インボイス削除"
override val invoice_menu: String = "インボイスメニュー"
override val invoice_number: String = "インボイス番号"
override val invoices: String = "請求書"
override val items_option: String = "アイテム"
override val keep: String = "キープ"
override val language_selector: Formattable = Formattable { (selected) -> "言語 ${selected}" }
override val last_name_hint: String = "姓名"
override val ledger: String = "帳簿"
override val legal: String = "リーガル"
override val legal_error: String = "利用規約とプライバシーポリシーへの同意が必要"
override val liabilities: String = "負債"
override val loading: String = "ローディング"
override val login_required_error: String = "ログインが必要です。"
override val logo_view: String = "ロゴ"
override val logout: String = "ログアウト"
override val logout_prompt: String = "データがクラウドと同期されていません。ログアウト時に請求書、見積書、顧客などの情報を削除することができますか？"
override val main_screen: String = "メイン画面"
override val mark_paid: String = "支払い済みとしてマーク"
override val message: String = "メッセージ"
override val missing_sender_msg: String = "送信者情報のないドキュメントは無効である可能性があります。"
override val missing_sender_title: String = "送信者情報がありません"
override val month_april: String = "4月"
override val month_april_short: String = "4月"
override val month_august: String = "8月"
override val month_august_short: String = "8月"
override val month_december: String = "12月"
override val month_december_short: String = "12月"
override val month_february: String = "2月"
override val month_february_short: String = "2月"
override val month_january: String = "1月"
override val month_january_short: String = "1月"
override val month_july: String = "7月"
override val month_july_short: String = "7月"
override val month_june: String = "6月"
override val month_june_short: String = "6月"
override val month_march: String = "3月"
override val month_march_short: String = "3月"
override val month_may: String = "5月"
override val month_may_short: String = "5月"
override val month_november: String = "11月"
override val month_november_short: String = "11月"
override val month_october: String = "10月"
override val month_october_short: String = "10月"
override val month_september: String = "9月"
override val month_september_short: String = "9月"
override val multifactor_error: String = "検証に失敗しました。"
override val new: String = "新規"
override val news: String = "ニュース"
override val next: String = "次のページ"
override val no: String = "いいえ"
override val no_data_available: String = "利用可能なデータがありません"
override val no_search_results: String = "Sorry, we couldn't find a result."
override val none: String = "なし"
override val note_fields: String = "備考"
override val note_hint: String = "ノート"
override val notifications_option: String = "お知らせ"
override val ok: String = "OK"
override val onboarding: String = "オンボーディング"
override val onboarding_aboutus_message: String = "DingVoiceの重要な部分は永久に無料です。私たちの使命は、お客様のビジネスの成長を支援することです。お客様のフィードバックを共有することで、私たちの改善にお役立てください。"
override val onboarding_aboutus_title: String = "私たちがお手伝いします"
override val onboarding_paid_1: String = "プロ仕様のテンプレートにアクセス"
override val onboarding_paid_2: String = "財務をより深く理解する"
override val onboarding_paid_3: String = "問題を迅速に解決する"
override val onboarding_paid_4: String = "ドキュメントを安全に保管する"
override val onboarding_paid_5: String = "請求書のステータスの変化を常に把握"
override val onboarding_paid_title: String = "さらに速く成長するために..."
override val onboarding_paywall_message: String = "すべてのプレミアム機能を今すぐベストプライスでお楽しみください。"
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} の ${currencySymbol}${introAmount} だけで、次に ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "わずか ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} は無料、その後は ${currencySymbol}${amount} / ${duration} のみ" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} は無料、次に ${introductoryDuration} ${introPeriod} は ${currencySymbol}${introductoryAmount}、その後は ${currencySymbol}${amount} / ${duration} だけ" }
override val onboarding_paywall_renewable: String = "自動更新です。いつでも解約できる。"
override val onboarding_paywall_restore: String = "購入履歴の復元"
override val onboarding_paywall_restore_failure: String = "購入履歴の復元に失敗しました。後でもう一度試してみるか、サポートに連絡してください。"
override val onboarding_paywall_restore_success: String = "リストア成功"
override val onboarding_paywall_title: String = "偉業を成し遂げよう"
override val onboarding_welcome_message: String = "請求書と見積書を管理するための完全なツールセットです。"
override val onboarding_welcome_title: String = "DingVoiceへようこそ"
override val other_state: String = "その他"
override val overdue_state: String = "期限切れ"
override val paid_state: String = "有料"
override val password: String = "パスワード"
override val password_blank_error: String = "パスワードが必要です。"
override val password_mismatch_error: String = "パスワードが一致しない。"
override val pay: String = "支払う"
override val payment_instructions_label: String = "支払い方法"
override val payment_reminders: String = "支払いの催促"
override val phone_number_fields: String = "電話番号"
override val phone_number_hint: String = "電話番号"
override val postcode_hint: String = "郵便番号"
override val preview: String = "プレビュー"
override val preview_menu_hint: String = "プレビューメニュー"
override val price: String = "価格"
override val privacy_policy: String = "プライバシーポリシー"
override val profile: String = "プロフィール"
override val profitable_client: String = "あなたの最も収益性の高いクライアントは..."
override val profits_report: String = "利益"
override val promo_content: String = "プロモーションコンテンツ"
override val quantity_hint: String = "数量"
override val quantity_label_hint: String = "数量ラベル"
override val query_info: String = "クエリでは、アイテムと他の課税規則の両方を入力として参照するカスタム計算を書くことができます。シンボルと項目または税の説明を追加して、別のエントリにリンクする +, -, *, /, % - 算術演算子"
override val received_notification_body: Formattable = Formattable { (documentNumber) -> "documentType} ${documentNumber} がクライアントに受信されました。" }
override val received_state: String = "受信"
override val registration_code_fields: String = "登録コード"
override val reminder: String = "リマインダー"
override val reports: String = "レポート"
override val reports_not_available: String = "利用可能なレポートはありません, 後で戻ってきてください。"
override val reveal_password: String = "パスワードの公開"
override val revenue: String = "収益"
override val sales_tax: String = "バット"
override val sales_tax_number: String = "VAT番号"
override val save: String = "保存"
override val scan_document: String = "文書をスキャン"
override val search: String = "検索"
override val select: String = "選択"
override val select_icon: String = "アイコンを選択"
override val select_signature: String = "セレクトサイン"
override val select_template: String = "テンプレートの選択"
override val send: String = "送信"
override val sent_state: String = "送信"
override val setup_sender: String = "送信者を設定する"
override val share_option: String = "シェア"
override val ship_to: String = "発送先"
override val sign_in: String = "サインイン"
override val sign_in_apple: String = "Appleでサインイン"
override val sign_in_email: String = "Eメールでサインイン"
override val sign_in_facebook: String = "Facebookでサインイン"
override val sign_in_google: String = "Googleでサインイン"
override val sign_up: String = "サインアップ"
override val sign_up_label: String = "アカウントをお持ちでない方"
override val sign_up_link_label: String = "サインアップ!"
override val signatures_option: String = "署名"
override val skip: String = "スキップ"
override val sold_items_report: String = "販売アイテム"
override val sort_by: String = "Sort By"
override val status_update: String = "ドキュメントのステータス更新"
override val sub_annual: String = "年間"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/年" }
override val sub_backups: String = "クラウドバックアップ"
override val sub_insights: String = "インサイト"
override val sub_month: String = "月間"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/月" }
override val sub_notifications: String = "通知"
override val sub_popup_message: String = "定期購読は近日中に開始します。しばらくしたら、またここをチェックしてください。"
override val sub_support: String = "サポート"
override val sub_templates: String = "テンプレート"
override val subscribe: String = "購読"
override val subscription_header: String = "でビジネスを成長させる。"
override val subscription_option: String = "サブスクリプション"
override val subscriptions_load_fail: String = "申し訳ございません。現在、お客様の定期購読を取得することができません。後ほどもう一度お試しいただくか、弊社サポートチームまでお問い合わせください。"
override val subtotal: Formattable = Formattable { (sum) -> "サブトータル： ${sum}" }
override val subtotal_label: String = "サブトータル"
override val sum: String = "サム"
override val support_option: String = "サポート"
override val switch_reports: String = "スイッチ レポート"
override val tax: String = "税金"
override val tax_deleted_message: String = "削除された税金"
override val tax_description_warning: String = "使用した場合は、請求書に記載されます。"
override val tax_input_flat: String = "フラット"
override val tax_input_percent: String = "パーセント"
override val tax_number_fields: String = "課税番号"
override val tax_number_hint: String = "課税番号"
override val tax_query_info_description: String = "問い合わせ情報"
override val tax_report: String = "税"
override val tax_rules_option: String = "タックスルール"
override val taxed_clients_report: String = "課税対象顧客"
override val template: String = "テンプレート"
override val template_selector_info: String = "スワイプしてデフォルトのテンプレートを変更"
override val template_unlock_promo: String = "プレミアムでより多くのテンプレートにアクセス"
override val templates_option: String = "テンプレート"
override val terms_of_service: String = "利用規約"
override val thank_you: String = "ありがとうございます"
override val to: String = "にしています。"
override val total: Formattable = Formattable { (sum) -> "Total: ${sum}." }
override val total_label: String = "TOTAL"
override val undo_action: String = "Undo"
override val unit: String = "ユニット"
override val unit_cost_hint: String = "ユニットコスト"
override val unknown_client: String = "不明なクライアント"
override val unlock: String = "ロック解除"
override val unnamed_item: String = "名前のないアイテム"
override val upgrade: String = "アップグレード"
override val user_collision_error: String = "アカウントはすでに存在しています。"
override val valid_until: String = "まで有効"
override val warning: String = "警告!"
override val yes: String = "はい"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} と ${privacyPolicy} に同意してください" },
	"account" to "アカウント",
	"accounting" to "会計",
	"accruals" to "発生",
	"action_code_error" to "無効なコードです。",
	"add_client_action" to "クライアントの追加",
	"add_customer" to "お客様の追加",
	"add_expense" to "経費を追加",
	"add_fields_label" to "追加です。",
	"add_invoice_action" to "クリエイト",
	"add_item_action" to "アイテムの追加",
	"add_item_button" to "アイテムの追加",
	"add_signature_action" to "署名の追加",
	"add_tax_action" to "付加価値税",
	"address_fields" to "アドレス",
	"address_line_hint" to "アドレスライン",
	"advanced" to "アドバンスド",
	"agree_marketing" to "ダイレクト・マーケティング・コミュニケーションの受信に同意します。",
	"all_notifications" to "すべての通知",
	"amortization" to "償却",
	"amount" to "金額",
	"asset" to "資産",
	"attach_file" to "ファイルを添付",
	"attachment" to "アタッチメント",
	"auth_error" to "アクションが失敗したので、もう一度やり直してください。",
	"available_for_paid" to "クラウドシンクは、有料のサブスクリプションでのみ利用可能です。",
	"backups_option" to "バックアップ",
	"balance" to "残高",
	"bank_account" to "銀行口座",
	"bill_number" to "請求書番号",
	"cancel" to "キャンセル",
	"cancel_trial" to "いつでもキャンセル可能",
	"capital" to "資本",
	"cash" to "現金",
	"categories" to "カテゴリー",
	"category" to "カテゴリー",
	"category_add" to "カテゴリを追加",
	"category_delete" to "カテゴリーを削除",
	"category_name" to "カテゴリー名",
	"change_language" to Formattable { (language) -> "インボイスの言語: ${language}" },
	"change_language_label" to "インボイスの言語",
	"change_template" to "テンプレートの変更",
	"city_hint" to "シティ",
	"clear" to "クリア",
	"client" to "クライアント",
	"client_actions" to "クライアントの行動",
	"client_deleted_message" to "クライアントの削除",
	"clients_error_required_fields" to "最低でも1つの値が必要です。",
	"clients_option" to "クライアント",
	"close" to "閉じる",
	"cloud_sync" to "クラウドシンク",
	"coming_soon" to "もうすぐです。",
	"company_logo" to "会社ロゴ",
	"company_name_hint" to "会社概要",
	"confirm_password_label" to "パスワードの確認",
	"confirmation_title" to "本気ですか？",
	"convert_to_invoice" to "インボイスへの変換",
	"country_hint" to "国名",
	"create_client" to "クライアントの作成",
	"create_company" to "クリエイトカンパニー",
	"create_document" to "ドキュメントの作成",
	"create_estimate" to "見積もり",
	"create_invoice" to "インボイス",
	"create_item" to "アイテムの作成",
	"create_new_client" to "新規クライアントの作成",
	"create_new_item" to "新規アイテムの作成",
	"create_new_tax" to "新しい税金を作る",
	"create_signature" to "シグネチャー",
	"created" to "作成",
	"credit" to "クレジット",
	"customer" to "お客様",
	"date" to "日付",
	"debit" to "デビット",
	"debt" to "借金",
	"default_currency" to Formattable { (currency) -> "既定通貨 ${currency}" },
	"default_currency_label" to "デフォルト通貨",
	"delete" to "削除",
	"delete_account" to "アカウントを削除",
	"delete_confirmation_message" to "すべてのデータが失われます。",
	"delete_item_title" to "アイテムの削除は？",
	"delete_signature" to "署名の削除",
	"depreciation" to "減価償却費",
	"description" to "説明",
	"description_hint" to "説明",
	"detailed_report" to "詳細レポート",
	"disabled" to "無効化",
	"discount" to "割引",
	"discount_hint" to "割引",
	"display_af" to "アフリカーンス",
	"display_am" to "アムハラ語",
	"display_ar" to "アラビア語",
	"display_as" to "アッサム語",
	"display_az" to "アゼルバイジャン語",
	"display_be" to "ベラルーシ語",
	"display_bg" to "ブルガリア語",
	"display_bn" to "ベンガル語",
	"display_bs" to "ボスニア語",
	"display_ca" to "カタロニア語",
	"display_cs" to "チェコ語",
	"display_da" to "デンマーク語",
	"display_de" to "ドイツ語",
	"display_el" to "グリーク",
	"display_en" to "英語",
	"display_es" to "スペイン語",
	"display_es_MX" to "スペイン語（メキシコ）",
	"display_et" to "エストニア語",
	"display_eu" to "バスク",
	"display_fa" to "ペルシャ語",
	"display_fi" to "フィンランド語",
	"display_fil" to "フィリピン人",
	"display_fr" to "フランス語",
	"display_fr_CA" to "カナディアンフレンチ",
	"display_gl" to "ガリシア語",
	"display_gu" to "グジャラート",
	"display_he" to "ヘブライ語",
	"display_hi" to "ヒンディー語",
	"display_hr" to "クロアチア語",
	"display_hu" to "ハンガリー語",
	"display_hy" to "アルメニア語",
	"display_id" to "インドネシア語",
	"display_is" to "アイスランド語",
	"display_it" to "イタリア語",
	"display_ja" to "日本語",
	"display_ka" to "グルジア語",
	"display_kk" to "カザフスタン",
	"display_km" to "中央クメール",
	"display_kn" to "カンナダ語",
	"display_ko" to "韓国語",
	"display_ky" to "キルヒーズ",
	"display_lo" to "ラオウ",
	"display_lt" to "リトアニア語",
	"display_lv" to "ラトビア語",
	"display_mk" to "マケドニア語",
	"display_ml" to "マラヤーラム語",
	"display_mn" to "モンゴル語",
	"display_mr" to "マラーティー語",
	"display_ms" to "マレイ",
	"display_my" to "ビルマ語",
	"display_nb" to "ノルウェー語Bokmål",
	"display_ne" to "ネパール語",
	"display_nl" to "オランダ語",
	"display_or" to "オリヤー",
	"display_pa" to "パンジャブ語",
	"display_pl" to "ポーランド語",
	"display_pt" to "ポルトガル語",
	"display_pt_BR" to "ポルトガル語（ブラジル）",
	"display_pt_PT" to "欧州ポルトガル語",
	"display_ro" to "ルーマニア語",
	"display_ru" to "ロシア語",
	"display_si" to "シンハラ語",
	"display_sk" to "スロバキア語",
	"display_sl" to "スロベニア語",
	"display_sq" to "アルバニア語",
	"display_sr" to "セルビア人",
	"display_sv" to "スウェーデン語",
	"display_sw" to "スワヒリ語",
	"display_ta" to "タミル語",
	"display_te" to "テルグ語",
	"display_th" to "タイ人",
	"display_tr" to "トルコ語",
	"display_uk" to "ウクライナ人",
	"display_ur" to "ウルドゥー語",
	"display_uz" to "ウズベク語",
	"display_vi" to "ベトナム語",
	"display_zh_Hant_TW" to "中国語 (台湾、繁体)",
	"display_zu" to "ズールー語",
	"document_estimate" to "見積もり",
	"document_invoice" to "インボイス",
	"document_quote" to "価格提示",
	"done" to "完了",
	"download_app" to "アプリをダウンロードする",
	"download_invoice" to "請求書をダウンロード",
	"download_pdf" to "PDFダウンロード",
	"draw_signature" to "サインを描く",
	"due" to Formattable { (date) -> "期限 ${date}。" },
	"due_date" to "期限",
	"due_day" to "1日以内",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} の期限は ${dueWhen} です!" },
	"due_today" to "今日",
	"due_week" to "一週間以内に",
	"duplicate" to "複製",
	"duration_day" to "日",
	"duration_month" to "月",
	"duration_week" to "週",
	"duration_year" to "年",
	"email" to "電子メール",
	"email_blank_error" to "Eメールが必要です。",
	"email_error" to "メールアドレスが無効です。",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "お買い上げくださってありがとうございます。完全な ${documentType} を表示するには、このレターの末尾にあるボタンをクリックしてください。 ${dueDate} まで有効であることに注意してください。" },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "新しい ${documentType} ${invoiceNumber} を受け取りました!" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType} を表示" },
	"email_invoice_greeting" to Formattable { (clientName) -> "拝啓 ${clientName}様" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "お買い上げくださってありがとうございます。完全な ${documentType} を表示するには、このレターの末尾にあるボタンをクリックしてください。 残金は ${dueDate} までお支払いください。" },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "新しい ${documentType} ${invoiceNumber} を受け取りました!" },
	"email_reset_success_message" to "パスワード再設定用のメールを送信しましたので、受信箱をご確認ください。",
	"empty_clients_list_message" to "追跡できるクライアントがありません。新しいクライアントを追加してください。",
	"empty_estimate_list_message" to "最初の見積書の作成",
	"empty_expenses" to "追跡する経費がありません。新しい支出を追加してください。",
	"empty_invoice_list_message" to "最初の請求書の作成",
	"empty_items_list_message" to "あなたはアイテムを持っていないので、新しいアイテムを追加してください。",
	"empty_signatures_list_message" to "シグネチャーが見つかりません。作成してください",
	"empty_taxes_list_message" to "タックスルールがありません。新しいタックスルールを追加してください。",
	"empty_templates_list_message" to "テンプレートは使用できません。",
	"enabled" to "有効",
	"entry" to "アイテム",
	"equity" to "エクイティ",
	"estimate_number" to "推定 #",
	"estimates" to "見積もり",
	"estimates_report" to "見積り",
	"expense_bank_fees" to "銀行手数料",
	"expense_benefits" to "特典",
	"expense_communication" to "コミュニケーション",
	"expense_debt" to "借金",
	"expense_deleted" to "経費が削除されました",
	"expense_depreciation" to "減価償却費",
	"expense_gifts" to "ギフト",
	"expense_insurance" to "保険",
	"expense_inventory" to "インベントリー",
	"expense_legal" to "リーガル",
	"expense_maintenance" to "メンテナンス",
	"expense_marketing" to "マーケティング",
	"expense_meals" to "食事",
	"expense_office_supplies" to "事務用品",
	"expense_rent" to "家賃",
	"expense_salaries" to "給与",
	"expense_shopping" to "ショッピング",
	"expense_software" to "ソフトウェア",
	"expense_status_available" to "利用可能",
	"expense_status_processing" to "処理中",
	"expense_tax" to "税",
	"expense_training" to "トレーニング",
	"expense_transport" to "輸送",
	"expense_travel" to "トラベル",
	"expense_utility" to "ユーティリティ",
	"expenses" to "経費",
	"feedback_option" to "フィードバック",
	"filter" to "フィルター",
	"first_name_hint" to "名前",
	"forgot_password" to "パスワードの再設定",
	"forgot_password_label" to "パスワードを忘れた場合",
	"from" to "より",
	"general_error" to "エラーが発生しました。後でもう一度試してください。",
	"general_ledger" to "総勘定元帳",
	"go_back_label" to "戻る",
	"group_by" to "Group By:",
	"image_view" to "イメージ",
	"install" to "インストール",
	"invalid_credentials_error" to "認証情報が無効です。",
	"invalid_email_error" to "無効なメールです。",
	"invalid_user_error" to "ユーザー認証が無効です。",
	"invoice_deleted_message" to "インボイス削除",
	"invoice_menu" to "インボイスメニュー",
	"invoice_number" to "インボイス番号",
	"invoices" to "請求書",
	"items_option" to "アイテム",
	"keep" to "キープ",
	"language_selector" to Formattable { (selected) -> "言語 ${selected}" },
	"last_name_hint" to "姓名",
	"ledger" to "帳簿",
	"legal" to "リーガル",
	"legal_error" to "利用規約とプライバシーポリシーへの同意が必要",
	"liabilities" to "負債",
	"loading" to "ローディング",
	"login_required_error" to "ログインが必要です。",
	"logo_view" to "ロゴ",
	"logout" to "ログアウト",
	"logout_prompt" to "データがクラウドと同期されていません。ログアウト時に請求書、見積書、顧客などの情報を削除することができますか？",
	"main_screen" to "メイン画面",
	"mark_paid" to "支払い済みとしてマーク",
	"message" to "メッセージ",
	"missing_sender_msg" to "送信者情報のないドキュメントは無効である可能性があります。",
	"missing_sender_title" to "送信者情報がありません",
	"month_april" to "4月",
	"month_april_short" to "4月",
	"month_august" to "8月",
	"month_august_short" to "8月",
	"month_december" to "12月",
	"month_december_short" to "12月",
	"month_february" to "2月",
	"month_february_short" to "2月",
	"month_january" to "1月",
	"month_january_short" to "1月",
	"month_july" to "7月",
	"month_july_short" to "7月",
	"month_june" to "6月",
	"month_june_short" to "6月",
	"month_march" to "3月",
	"month_march_short" to "3月",
	"month_may" to "5月",
	"month_may_short" to "5月",
	"month_november" to "11月",
	"month_november_short" to "11月",
	"month_october" to "10月",
	"month_october_short" to "10月",
	"month_september" to "9月",
	"month_september_short" to "9月",
	"multifactor_error" to "検証に失敗しました。",
	"new" to "新規",
	"news" to "ニュース",
	"next" to "次のページ",
	"no" to "いいえ",
	"no_data_available" to "利用可能なデータがありません",
	"no_search_results" to "Sorry, we couldn't find a result.",
	"none" to "なし",
	"note_fields" to "備考",
	"note_hint" to "ノート",
	"notifications_option" to "お知らせ",
	"ok" to "OK",
	"onboarding" to "オンボーディング",
	"onboarding_aboutus_message" to "DingVoiceの重要な部分は永久に無料です。私たちの使命は、お客様のビジネスの成長を支援することです。お客様のフィードバックを共有することで、私たちの改善にお役立てください。",
	"onboarding_aboutus_title" to "私たちがお手伝いします",
	"onboarding_paid_1" to "プロ仕様のテンプレートにアクセス",
	"onboarding_paid_2" to "財務をより深く理解する",
	"onboarding_paid_3" to "問題を迅速に解決する",
	"onboarding_paid_4" to "ドキュメントを安全に保管する",
	"onboarding_paid_5" to "請求書のステータスの変化を常に把握",
	"onboarding_paid_title" to "さらに速く成長するために...",
	"onboarding_paywall_message" to "すべてのプレミアム機能を今すぐベストプライスでお楽しみください。",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} の ${currencySymbol}${introAmount} だけで、次に ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "わずか ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} は無料、その後は ${currencySymbol}${amount} / ${duration} のみ" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} は無料、次に ${introductoryDuration} ${introPeriod} は ${currencySymbol}${introductoryAmount}、その後は ${currencySymbol}${amount} / ${duration} だけ" },
	"onboarding_paywall_renewable" to "自動更新です。いつでも解約できる。",
	"onboarding_paywall_restore" to "購入履歴の復元",
	"onboarding_paywall_restore_failure" to "購入履歴の復元に失敗しました。後でもう一度試してみるか、サポートに連絡してください。",
	"onboarding_paywall_restore_success" to "リストア成功",
	"onboarding_paywall_title" to "偉業を成し遂げよう",
	"onboarding_welcome_message" to "請求書と見積書を管理するための完全なツールセットです。",
	"onboarding_welcome_title" to "DingVoiceへようこそ",
	"other_state" to "その他",
	"overdue_state" to "期限切れ",
	"paid_state" to "有料",
	"password" to "パスワード",
	"password_blank_error" to "パスワードが必要です。",
	"password_mismatch_error" to "パスワードが一致しない。",
	"pay" to "支払う",
	"payment_instructions_label" to "支払い方法",
	"payment_reminders" to "支払いの催促",
	"phone_number_fields" to "電話番号",
	"phone_number_hint" to "電話番号",
	"postcode_hint" to "郵便番号",
	"preview" to "プレビュー",
	"preview_menu_hint" to "プレビューメニュー",
	"price" to "価格",
	"privacy_policy" to "プライバシーポリシー",
	"profile" to "プロフィール",
	"profitable_client" to "あなたの最も収益性の高いクライアントは...",
	"profits_report" to "利益",
	"promo_content" to "プロモーションコンテンツ",
	"quantity_hint" to "数量",
	"quantity_label_hint" to "数量ラベル",
	"query_info" to "クエリでは、アイテムと他の課税規則の両方を入力として参照するカスタム計算を書くことができます。シンボルと項目または税の説明を追加して、別のエントリにリンクする +, -, *, /, % - 算術演算子",
	"received_notification_body" to Formattable { (documentNumber) -> "documentType} ${documentNumber} がクライアントに受信されました。" },
	"received_state" to "受信",
	"registration_code_fields" to "登録コード",
	"reminder" to "リマインダー",
	"reports" to "レポート",
	"reports_not_available" to "利用可能なレポートはありません, 後で戻ってきてください。",
	"reveal_password" to "パスワードの公開",
	"revenue" to "収益",
	"sales_tax" to "バット",
	"sales_tax_number" to "VAT番号",
	"save" to "保存",
	"scan_document" to "文書をスキャン",
	"search" to "検索",
	"select" to "選択",
	"select_icon" to "アイコンを選択",
	"select_signature" to "セレクトサイン",
	"select_template" to "テンプレートの選択",
	"send" to "送信",
	"sent_state" to "送信",
	"setup_sender" to "送信者を設定する",
	"share_option" to "シェア",
	"ship_to" to "発送先",
	"sign_in" to "サインイン",
	"sign_in_apple" to "Appleでサインイン",
	"sign_in_email" to "Eメールでサインイン",
	"sign_in_facebook" to "Facebookでサインイン",
	"sign_in_google" to "Googleでサインイン",
	"sign_up" to "サインアップ",
	"sign_up_label" to "アカウントをお持ちでない方",
	"sign_up_link_label" to "サインアップ!",
	"signatures_option" to "署名",
	"skip" to "スキップ",
	"sold_items_report" to "販売アイテム",
	"sort_by" to "Sort By",
	"status_update" to "ドキュメントのステータス更新",
	"sub_annual" to "年間",
	"sub_annual_price" to Formattable { (price) -> "${price}/年" },
	"sub_backups" to "クラウドバックアップ",
	"sub_insights" to "インサイト",
	"sub_month" to "月間",
	"sub_monthly_price" to Formattable { (price) -> "${price}/月" },
	"sub_notifications" to "通知",
	"sub_popup_message" to "定期購読は近日中に開始します。しばらくしたら、またここをチェックしてください。",
	"sub_support" to "サポート",
	"sub_templates" to "テンプレート",
	"subscribe" to "購読",
	"subscription_header" to "でビジネスを成長させる。",
	"subscription_option" to "サブスクリプション",
	"subscriptions_load_fail" to "申し訳ございません。現在、お客様の定期購読を取得することができません。後ほどもう一度お試しいただくか、弊社サポートチームまでお問い合わせください。",
	"subtotal" to Formattable { (sum) -> "サブトータル： ${sum}" },
	"subtotal_label" to "サブトータル",
	"sum" to "サム",
	"support_option" to "サポート",
	"switch_reports" to "スイッチ レポート",
	"tax" to "税金",
	"tax_deleted_message" to "削除された税金",
	"tax_description_warning" to "使用した場合は、請求書に記載されます。",
	"tax_input_flat" to "フラット",
	"tax_input_percent" to "パーセント",
	"tax_number_fields" to "課税番号",
	"tax_number_hint" to "課税番号",
	"tax_query_info_description" to "問い合わせ情報",
	"tax_report" to "税",
	"tax_rules_option" to "タックスルール",
	"taxed_clients_report" to "課税対象顧客",
	"template" to "テンプレート",
	"template_selector_info" to "スワイプしてデフォルトのテンプレートを変更",
	"template_unlock_promo" to "プレミアムでより多くのテンプレートにアクセス",
	"templates_option" to "テンプレート",
	"terms_of_service" to "利用規約",
	"thank_you" to "ありがとうございます",
	"to" to "にしています。",
	"total" to Formattable { (sum) -> "Total: ${sum}." },
	"total_label" to "TOTAL",
	"undo_action" to "Undo",
	"unit" to "ユニット",
	"unit_cost_hint" to "ユニットコスト",
	"unknown_client" to "不明なクライアント",
	"unlock" to "ロック解除",
	"unnamed_item" to "名前のないアイテム",
	"upgrade" to "アップグレード",
	"user_collision_error" to "アカウントはすでに存在しています。",
	"valid_until" to "まで有効",
	"warning" to "警告!",
	"yes" to "はい",
    )
    
}