// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Az  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} və ${privacyPolicy} qəbul edin" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Yanlış kod."
override val add_client_action: String = "Müştəri əlavə et"
override val add_customer: String = "müştəri əlavə edin"
override val add_expense: String = "Xərc əlavə edin"
override val add_fields_label: String = "Əlavə et:"
override val add_invoice_action: String = "Yarat"
override val add_item_action: String = "Maddə əlavə et"
override val add_item_button: String = "Maddə əlavə et"
override val add_signature_action: String = "İmza əlavə et"
override val add_tax_action: String = "Vergi əlavə et"
override val address_fields: String = "Ünvan"
override val address_line_hint: String = "Ünvan xətti"
override val advanced: String = "Ətraflı"
override val agree_marketing: String = "Birbaşa Marketinq Kommunikasiyalarını almağa razıyam"
override val all_notifications: String = "Bütün Bildirişlər"
override val amortization: String = "amortization"
override val amount: String = "Məbləğ"
override val asset: String = "asset"
override val attach_file: String = "Fayl əlavə edin"
override val attachment: String = "Əlavə"
override val auth_error: String = "Fəaliyyət uğursuz oldu, yenidən cəhd edin."
override val available_for_paid: String = "Cloud sync yalnız ödənişli abunə altında mövcuddur."
override val backups_option: String = "yedeklemeler"
override val balance: String = "balance"
override val bank_account: String = "Bank Hesabı"
override val bill_number: String = "Hesabın nömrəsi"
override val cancel: String = "Ləğv"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Kateqoriyalar"
override val category: String = "Kateqoriya"
override val category_add: String = "kateqoriya əlavə edin"
override val category_delete: String = "Kateqoriyanı sil"
override val category_name: String = "Kateqoriya adı"
override val change_language: Formattable = Formattable { (language) -> "Faktura Dili: ${language}" }
override val change_language_label: String = "Faktura Dili"
override val change_template: String = "Şablonu dəyişdirin"
override val city_hint: String = "Şəhər"
override val clear: String = "Clear"
override val client: String = "Müştəri"
override val client_actions: String = "Müştəri hərəkətləri"
override val client_deleted_message: String = "Müştəri silindi"
override val clients_error_required_fields: String = "Ən azı bir dəyər tələb olunur."
override val clients_option: String = "Müştərilər"
override val close: String = "Yaxın"
override val cloud_sync: String = "Cloud Sinxronizasiya"
override val coming_soon: String = "Tezliklə!"
override val company_logo: String = "Şirkət Loqosu"
override val company_name_hint: String = "Şirkət"
override val confirm_password_label: String = "Şifrəni təsdiq edin"
override val confirmation_title: String = "Sən əminsən?"
override val convert_to_invoice: String = "Fakturaya çevirmək"
override val country_hint: String = "ölkə"
override val create_client: String = "Müştəri Yarat"
override val create_company: String = "Şirkət yaradın"
override val create_document: String = "Sənəd yaradın"
override val create_estimate: String = "Qiymətləndirmək"
override val create_invoice: String = "Faktura"
override val create_item: String = "Maddə Yarat"
override val create_new_client: String = "Yeni müştəri yaradın"
override val create_new_item: String = "Yeni maddə yaradın"
override val create_new_tax: String = "Yeni vergi yaradın"
override val create_signature: String = "İmza"
override val created: String = "yaradılmışdır"
override val credit: String = "credit"
override val customer: String = "Müştəri"
override val date: String = "Tarix"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Defolt valyuta: ${currency}" }
override val default_currency_label: String = "Defolt valyuta"
override val delete: String = "silmək"
override val delete_account: String = "Hesabı silin"
override val delete_confirmation_message: String = "Bütün məlumatlarınız itəcək."
override val delete_item_title: String = "Maddə silin?"
override val delete_signature: String = "İmzanı silin"
override val depreciation: String = "depreciation"
override val description: String = "Təsviri"
override val description_hint: String = "Təsviri"
override val detailed_report: String = "Ətraflı hesabat"
override val disabled: String = "Əlillər"
override val discount: String = "Endirim"
override val discount_hint: String = "Endirim"
override val display_af: String = "Afrikanlar"
override val display_am: String = "Amharic"
override val display_ar: String = "Ərəb"
override val display_as: String = "Assamese"
override val display_az: String = "Azərbaycan"
override val display_be: String = "Belarusiya"
override val display_bg: String = "Bolqar"
override val display_bn: String = "Benqal"
override val display_bs: String = "Bosniyalı"
override val display_ca: String = "Katalan"
override val display_cs: String = "Çex"
override val display_da: String = "Danimarkalı"
override val display_de: String = "Alman"
override val display_el: String = "Yunan"
override val display_en: String = "ingilis dili"
override val display_es: String = "İspan"
override val display_es_MX: String = "İspan (Meksika)"
override val display_et: String = "Eston"
override val display_eu: String = "Bask"
override val display_fa: String = "Fars"
override val display_fi: String = "Fin"
override val display_fil: String = "Filipin"
override val display_fr: String = "Fransız dili"
override val display_fr_CA: String = "Kanadalı fransız"
override val display_gl: String = "Qalisiya dili"
override val display_gu: String = "Qujarati"
override val display_he: String = "İbrani"
override val display_hi: String = "Hindi"
override val display_hr: String = "Xorvatiya"
override val display_hu: String = "Macar"
override val display_hy: String = "erməni"
override val display_id: String = "İndoneziya"
override val display_is: String = "İslandiya"
override val display_it: String = "İtalyan"
override val display_ja: String = "Yapon"
override val display_ka: String = "Gürcü"
override val display_kk: String = "Qazax"
override val display_km: String = "Mərkəzi Kxmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Koreyalı"
override val display_ky: String = "Qırğız"
override val display_lo: String = "Lao"
override val display_lt: String = "Litva"
override val display_lv: String = "Latviya"
override val display_mk: String = "Makedon"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Monqol"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malay"
override val display_my: String = "Birma"
override val display_nb: String = "Norveç Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Holland"
override val display_or: String = "Oriya"
override val display_pa: String = "Pəncabi"
override val display_pl: String = "Polyak"
override val display_pt: String = "Portuqalca"
override val display_pt_BR: String = "portuqal (Braziliya)"
override val display_pt_PT: String = "Avropa Portuqaliyası"
override val display_ro: String = "Rumun"
override val display_ru: String = "rus"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovakiya"
override val display_sl: String = "Sloveniya"
override val display_sq: String = "Alban"
override val display_sr: String = "Serb"
override val display_sv: String = "İsveç"
override val display_sw: String = "Svahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Teluqu"
override val display_th: String = "Tayland"
override val display_tr: String = "Türk"
override val display_uk: String = "Ukraynalı"
override val display_ur: String = "Urdu"
override val display_uz: String = "Özbək"
override val display_vi: String = "Vyetnamca"
override val display_zh_Hant_TW: String = "Çin (Tayvan, Ənənəvi)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Qiymətləndirmək"
override val document_invoice: String = "Faktura"
override val document_quote: String = "Sitat"
override val done: String = "edildi"
override val download_app: String = "Tətbiqi yüklə"
override val download_invoice: String = "Fakturanı yüklə"
override val download_pdf: String = "PDF yüklə"
override val draw_signature: String = "İmza çəkmək"
override val due: Formattable = Formattable { (date) -> "Due ${date}" }
override val due_date: String = "Vaxtı tarixi"
override val due_day: String = "bir gündə"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} görə ${dueWhen}!" }
override val due_today: String = "Bu gün"
override val due_week: String = "bir həftə içində"
override val duplicate: String = "Dublikat"
override val duration_day: String = "Gün"
override val duration_month: String = "ay"
override val duration_week: String = "Həftə"
override val duration_year: String = "İl"
override val email: String = "E-poçt"
override val email_blank_error: String = "E-poçt tələb."
override val email_error: String = "Yanlış e-poçt ünvanı."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Biznesiniz üçün təşəkkür edirik. Tam ${documentType} görmək üçün bu məktubun sonunda düyməni basın. Qeyd ${dueDate} -ə qədər etibarlıdır." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Yeni aldınız ${documentType}${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "BAX ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Hörmətli ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Biznesiniz üçün çox sağolun. Tam ${documentType}-ə baxmaq üçün bu məktubun sonundakı düyməyə klikləyin. Zəhmət olmasa balansı ${dueDate} tarixinə qədər ödəyin." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Yeni aldınız ${documentType}${invoiceNumber}!" }
override val email_reset_success_message: String = "Göndərilən parol sıfırlamak üçün e-poçt, gelen kutunuza baxın."
override val empty_clients_list_message: String = "Siz izləmək üçün heç bir müştəri yoxdur. Yeni bir müştəri əlavə edin."
override val empty_estimate_list_message: String = "İlk təxmininizi yaradın"
override val empty_expenses: String = "İzləmək üçün heç bir xərcləriniz yoxdur. Yeni bir xərc əlavə edin."
override val empty_invoice_list_message: String = "İlk fakturanı yaradın"
override val empty_items_list_message: String = "Siz heç bir maddələr var, yeni bir maddə əlavə!"
override val empty_signatures_list_message: String = "İmza tapılmadı. Birini yaradın!"
override val empty_taxes_list_message: String = "Vergi qaydaları yoxdur. Yeni vergi qayda əlavə et."
override val empty_templates_list_message: String = "Şablonları əlçatmaz."
override val enabled: String = "Enabled"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Qiymətləndirmə #"
override val estimates: String = "Qiymətləndirmələr"
override val estimates_report: String = "Qiymətləndirmələr"
override val expense_bank_fees: String = "Bank ödənişləri"
override val expense_benefits: String = "Faydaları"
override val expense_communication: String = "Rabitə"
override val expense_debt: String = "Borc"
override val expense_deleted: String = "Xərc silindi"
override val expense_depreciation: String = "Amortizasiya"
override val expense_gifts: String = "Hədiyyələr"
override val expense_insurance: String = "Sığorta"
override val expense_inventory: String = "İnventarlaşdırma"
override val expense_legal: String = "Hüquqi"
override val expense_maintenance: String = "Baxım"
override val expense_marketing: String = "Marketinq"
override val expense_meals: String = "Yeməklər"
override val expense_office_supplies: String = "Ofis ləvazimatları"
override val expense_rent: String = "İcarə"
override val expense_salaries: String = "Əmək haqqı"
override val expense_shopping: String = "Alış-veriş"
override val expense_software: String = "Proqram"
override val expense_status_available: String = "Mövcud"
override val expense_status_processing: String = "emal"
override val expense_tax: String = "Vergi"
override val expense_training: String = "Təlim"
override val expense_transport: String = "Nəqliyyat"
override val expense_travel: String = "Səyahət"
override val expense_utility: String = "Kommunal xidmət"
override val expenses: String = "Xərclər"
override val feedback_option: String = "Geribildiriminiz"
override val filter: String = "Süzgəc"
override val first_name_hint: String = "Ad"
override val forgot_password: String = "Şifrəni sıfırla"
override val forgot_password_label: String = "Şifrəni unutmusunuz"
override val from: String = "etibarən"
override val general_error: String = "Səhv baş verdi, xahiş edirik daha sonra yenidən cəhd edin."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Geri qayıdın"
override val group_by: String = "Qrup üzrə:"
override val image_view: String = "Image"
override val install: String = "Quraşdırın"
override val invalid_credentials_error: String = "Yanlış etimadnamə."
override val invalid_email_error: String = "Yanlış e-poçt."
override val invalid_user_error: String = "Yanlış istifadəçi etimadnaməsi."
override val invoice_deleted_message: String = "Faktura silindi"
override val invoice_menu: String = "Faktura menyusu"
override val invoice_number: String = "Faktura #"
override val invoices: String = "Fakturalar"
override val items_option: String = "maddələr"
override val keep: String = "Saxlayın"
override val language_selector: Formattable = Formattable { (selected) -> "Dil ${selected}" }
override val last_name_hint: String = "Soyad"
override val ledger: String = "ledger"
override val legal: String = "Hüquqi"
override val legal_error: String = "Xidmət şərtləri və Məxfilik Siyasətini qəbul etməlidir"
override val liabilities: String = "liabilities"
override val loading: String = "Loading"
override val login_required_error: String = "Giriş tələb."
override val logo_view: String = "Logo"
override val logout: String = "giriş"
override val logout_prompt: String = "Məlumatlarınız bulud ilə sinxronlaşdırılmır. Hesablamalar, təxminlər, müştərilər və çıxış haqqında digər məlumatları silin?"
override val main_screen: String = "Əsas ekran"
override val mark_paid: String = "Ödənişli olaraq qeyd edin"
override val message: String = "Mesaj"
override val missing_sender_msg: String = "Göndərən məlumatı olmayan sənəd etibarsız ola bilər."
override val missing_sender_title: String = "Göndərən məlumatları itkin"
override val month_april: String = "Aprel"
override val month_april_short: String = "Aprel"
override val month_august: String = "Avqust"
override val month_august_short: String = "Avq."
override val month_december: String = "Dekabr"
override val month_december_short: String = "Dekb"
override val month_february: String = "Fevral"
override val month_february_short: String = "Fevral"
override val month_january: String = "yanvar"
override val month_january_short: String = "Yanvar"
override val month_july: String = "İyul"
override val month_july_short: String = "İyul"
override val month_june: String = "İyun"
override val month_june_short: String = "İyun"
override val month_march: String = "mart"
override val month_march_short: String = "mart"
override val month_may: String = "May"
override val month_may_short: String = "May"
override val month_november: String = "Noyabr"
override val month_november_short: String = "Noyr."
override val month_october: String = "oktyabr"
override val month_october_short: String = "Okt."
override val month_september: String = "Sentyabr"
override val month_september_short: String = "Sentyabr."
override val multifactor_error: String = "Təsdiq edə bilmədi."
override val new: String = "Yeni"
override val news: String = "Xəbərlər"
override val next: String = "növbəti"
override val no: String = "no"
override val no_data_available: String = "Mövcud məlumat yoxdur"
override val no_search_results: String = "Bağışlayın, heç bir nəticə tapa bilmədik."
override val none: String = "Heç biri"
override val note_fields: String = "Qeydlər"
override val note_hint: String = "Qeyd"
override val notifications_option: String = "Bildirişlər"
override val ok: String = "Ok"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "DingVoice-nin əsas hissələri həmişəlik pulsuzdur. Bizim missiyamız biznesinizi inkişaf etdirməyə kömək etmək, rəyinizi bölüşərək təkmilləşdirməmizə kömək etməkdir."
override val onboarding_aboutus_title: String = "Biz kömək etmək üçün buradayıq"
override val onboarding_paid_1: String = "Professional görünüşlü şablonlara daxil olun"
override val onboarding_paid_2: String = "Maliyyə vəziyyətinizə daha dərindən baxın"
override val onboarding_paid_3: String = "Problemləri tez həll edin"
override val onboarding_paid_4: String = "Sənədlərinizi təhlükəsiz saxlayın"
override val onboarding_paid_5: String = "Faktura statusu dəyişikliklərindən xəbərdar olun"
override val onboarding_paid_title: String = "Daha da sürətlə inkişaf edin..."
override val onboarding_paywall_message: String = "İndi ən yaxşı qiymətə bütün premium funksiyalardan həzz alın."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} üçün sadəcə ${currencySymbol}${introAmount} və sonra ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Sadəcə ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} ödənişsiz, sonra isə sadəcə ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} üçün pulsuz, sonra ${introductoryDuration} ${introPeriod} üçün ${currencySymbol}${introductoryAmount} və sonra sadəcə ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Avtomatik yenilənən. İstənilən vaxt ləğv edin."
override val onboarding_paywall_restore: String = "Satınalmanı bərpa edin"
override val onboarding_paywall_restore_failure: String = "Satınalmaları bərpa etmək uğursuz oldu, sonra yenidən cəhd edin və ya dəstək xidməti ilə əlaqə saxlayın."
override val onboarding_paywall_restore_success: String = "Uğurlu bərpa edin!"
override val onboarding_paywall_title: String = "Böyüklüyə nail olun!"
override val onboarding_welcome_message: String = "Hesab-fakturalarınızı və təxminlərinizi idarə etmək üçün tam alətlər dəsti."
override val onboarding_welcome_title: String = "DingVoice-ə xoş gəlmisiniz"
override val other_state: String = "Digər"
override val overdue_state: String = "Vaxtı"
override val paid_state: String = "Ödənişli"
override val password: String = "Şifrə"
override val password_blank_error: String = "Şifrə tələb olunur."
override val password_mismatch_error: String = "Şifrələr uyğun deyil."
override val pay: String = "pay"
override val payment_instructions_label: String = "ödəniş təlimatları"
override val payment_reminders: String = "Ödəniş xatırlatmaları"
override val phone_number_fields: String = "Telefon nömrəsi"
override val phone_number_hint: String = "Telefon nömrəsi"
override val postcode_hint: String = "Poçt"
override val preview: String = "önizleme"
override val preview_menu_hint: String = "Preview menyusu"
override val price: String = "Qiymət"
override val privacy_policy: String = "Gizlilik Siyasəti"
override val profile: String = "Profil"
override val profitable_client: String = "Sizin ən sərfəli müştəriniz..."
override val profits_report: String = "Mənfəət"
override val promo_content: String = "Təqdimat məzmunu"
override val quantity_hint: String = "Miqdarı"
override val quantity_label_hint: String = "Miqdarı Etiket"
override val query_info: String = "Sorğu giriş kimi həm əşyalara, həm də digər vergi qaydalarına istinadən xüsusi hesablamalar yazmağa imkan verir. Başqa bir girişə keçid etmək üçün @ simvolu və maddə və ya vergi təsviri əlavə et +, -, *,/,% - riyaziyyat operatorları"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} müştəriniz tərəfindən qəbul edildi." }
override val received_state: String = "Alınan"
override val registration_code_fields: String = "Qeydiyyat kodu"
override val reminder: String = "Xatırlatma"
override val reports: String = "Hesabatlar"
override val reports_not_available: String = "No hesabat, sonra geri gəlmək."
override val reveal_password: String = "Şifrəni aşkar edin"
override val revenue: String = "revenue"
override val sales_tax: String = "ƏDV"
override val sales_tax_number: String = "ƏDV nömrəsi"
override val save: String = "Saxla"
override val scan_document: String = "Sənədi skan edin"
override val search: String = "Axtarış"
override val select: String = "Seçin"
override val select_icon: String = "İşarəni seçin"
override val select_signature: String = "İmzanı seçin"
override val select_template: String = "Şablonu seçin"
override val send: String = "Göndər"
override val sent_state: String = "Sent"
override val setup_sender: String = "Göndərən qurun"
override val share_option: String = "Share"
override val ship_to: String = "Gəmi"
override val sign_in: String = "Daxil ol"
override val sign_in_apple: String = "Apple ilə daxil ol"
override val sign_in_email: String = "E-poçt ilə daxil olun"
override val sign_in_facebook: String = "Facebook ilə daxil ol"
override val sign_in_google: String = "Google ilə daxil olun"
override val sign_up: String = "Qeydiyyatdan keçin"
override val sign_up_label: String = "Hesabınız yoxdur?"
override val sign_up_link_label: String = "Qeydiyyatdan keçin!"
override val signatures_option: String = "İmzalar"
override val skip: String = "Keç"
override val sold_items_report: String = "Satılmış Məhsullar"
override val sort_by: String = "Sort By:"
override val status_update: String = "Sənədin vəziyyəti yeniləmə"
override val sub_annual: String = "İllik"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/il" }
override val sub_backups: String = "Bulud yedeklemeler"
override val sub_insights: String = "Insights"
override val sub_month: String = "Aylıq"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/ay" }
override val sub_notifications: String = "Bildirişlər"
override val sub_popup_message: String = "Abunəliklər tezliklə gəlir. Bir az müddət burada geri yoxlayın."
override val sub_support: String = "Dəstək"
override val sub_templates: String = "Şablonlar"
override val subscribe: String = "Abunə ol"
override val subscription_header: String = "Biznesinizi inkişaf etdirin:"
override val subscription_option: String = "Abunə"
override val subscriptions_load_fail: String = "Üzr istəyirik, hazırda abunəliklərinizi əldə edə bilmirik. Lütfən, sonra yenidən cəhd edin və ya yardım üçün dəstək komandamızla əlaqə saxlayın."
override val subtotal: Formattable = Formattable { (sum) -> "Ümumi cəmi: ${sum}" }
override val subtotal_label: String = "Cəmi"
override val sum: String = "Sum"
override val support_option: String = "Dəstək"
override val switch_reports: String = "Hesabatı Switch"
override val tax: String = "Vergi"
override val tax_deleted_message: String = "Vergi silindi"
override val tax_description_warning: String = "Istifadə edilərsə, bu bir faktura göstərir"
override val tax_input_flat: String = "Mənzil"
override val tax_input_percent: String = "Yüzdə"
override val tax_number_fields: String = "Vergi Nömrəsi"
override val tax_number_hint: String = "Vergi Nömrəsi"
override val tax_query_info_description: String = "Sorğu məlumatı"
override val tax_report: String = "Vergi"
override val tax_rules_option: String = "Vergi Qaydaları"
override val taxed_clients_report: String = "Vergiyə cəlb olunan müştərilər"
override val template: String = "Şablon"
override val template_selector_info: String = "Standart şablonu dəyişdirmək üçün sürüşdürün"
override val template_unlock_promo: String = "Premium ilə daha çox şablon əldə edin"
override val templates_option: String = "Şablonlar"
override val terms_of_service: String = "Xidmət şərtləri"
override val thank_you: String = "Təşəkkürlər"
override val to: String = "Tə"
override val total: Formattable = Formattable { (sum) -> "Cəmi: ${sum}" }
override val total_label: String = "Cəmi"
override val undo_action: String = "Qalma"
override val unit: String = "Unit"
override val unit_cost_hint: String = "Vahid qiyməti"
override val unknown_client: String = "Bilinməyən müştəri"
override val unlock: String = "Kilidini aç"
override val unnamed_item: String = "Adsız maddə"
override val upgrade: String = "Upgrade"
override val user_collision_error: String = "Hesab artıq mövcuddur."
override val valid_until: String = "Tənə qədər etibarlıdır"
override val warning: String = "Xəbərdarlıq!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} və ${privacyPolicy} qəbul edin" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Yanlış kod.",
	"add_client_action" to "Müştəri əlavə et",
	"add_customer" to "müştəri əlavə edin",
	"add_expense" to "Xərc əlavə edin",
	"add_fields_label" to "Əlavə et:",
	"add_invoice_action" to "Yarat",
	"add_item_action" to "Maddə əlavə et",
	"add_item_button" to "Maddə əlavə et",
	"add_signature_action" to "İmza əlavə et",
	"add_tax_action" to "Vergi əlavə et",
	"address_fields" to "Ünvan",
	"address_line_hint" to "Ünvan xətti",
	"advanced" to "Ətraflı",
	"agree_marketing" to "Birbaşa Marketinq Kommunikasiyalarını almağa razıyam",
	"all_notifications" to "Bütün Bildirişlər",
	"amortization" to "amortization",
	"amount" to "Məbləğ",
	"asset" to "asset",
	"attach_file" to "Fayl əlavə edin",
	"attachment" to "Əlavə",
	"auth_error" to "Fəaliyyət uğursuz oldu, yenidən cəhd edin.",
	"available_for_paid" to "Cloud sync yalnız ödənişli abunə altında mövcuddur.",
	"backups_option" to "yedeklemeler",
	"balance" to "balance",
	"bank_account" to "Bank Hesabı",
	"bill_number" to "Hesabın nömrəsi",
	"cancel" to "Ləğv",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Kateqoriyalar",
	"category" to "Kateqoriya",
	"category_add" to "kateqoriya əlavə edin",
	"category_delete" to "Kateqoriyanı sil",
	"category_name" to "Kateqoriya adı",
	"change_language" to Formattable { (language) -> "Faktura Dili: ${language}" },
	"change_language_label" to "Faktura Dili",
	"change_template" to "Şablonu dəyişdirin",
	"city_hint" to "Şəhər",
	"clear" to "Clear",
	"client" to "Müştəri",
	"client_actions" to "Müştəri hərəkətləri",
	"client_deleted_message" to "Müştəri silindi",
	"clients_error_required_fields" to "Ən azı bir dəyər tələb olunur.",
	"clients_option" to "Müştərilər",
	"close" to "Yaxın",
	"cloud_sync" to "Cloud Sinxronizasiya",
	"coming_soon" to "Tezliklə!",
	"company_logo" to "Şirkət Loqosu",
	"company_name_hint" to "Şirkət",
	"confirm_password_label" to "Şifrəni təsdiq edin",
	"confirmation_title" to "Sən əminsən?",
	"convert_to_invoice" to "Fakturaya çevirmək",
	"country_hint" to "ölkə",
	"create_client" to "Müştəri Yarat",
	"create_company" to "Şirkət yaradın",
	"create_document" to "Sənəd yaradın",
	"create_estimate" to "Qiymətləndirmək",
	"create_invoice" to "Faktura",
	"create_item" to "Maddə Yarat",
	"create_new_client" to "Yeni müştəri yaradın",
	"create_new_item" to "Yeni maddə yaradın",
	"create_new_tax" to "Yeni vergi yaradın",
	"create_signature" to "İmza",
	"created" to "yaradılmışdır",
	"credit" to "credit",
	"customer" to "Müştəri",
	"date" to "Tarix",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Defolt valyuta: ${currency}" },
	"default_currency_label" to "Defolt valyuta",
	"delete" to "silmək",
	"delete_account" to "Hesabı silin",
	"delete_confirmation_message" to "Bütün məlumatlarınız itəcək.",
	"delete_item_title" to "Maddə silin?",
	"delete_signature" to "İmzanı silin",
	"depreciation" to "depreciation",
	"description" to "Təsviri",
	"description_hint" to "Təsviri",
	"detailed_report" to "Ətraflı hesabat",
	"disabled" to "Əlillər",
	"discount" to "Endirim",
	"discount_hint" to "Endirim",
	"display_af" to "Afrikanlar",
	"display_am" to "Amharic",
	"display_ar" to "Ərəb",
	"display_as" to "Assamese",
	"display_az" to "Azərbaycan",
	"display_be" to "Belarusiya",
	"display_bg" to "Bolqar",
	"display_bn" to "Benqal",
	"display_bs" to "Bosniyalı",
	"display_ca" to "Katalan",
	"display_cs" to "Çex",
	"display_da" to "Danimarkalı",
	"display_de" to "Alman",
	"display_el" to "Yunan",
	"display_en" to "ingilis dili",
	"display_es" to "İspan",
	"display_es_MX" to "İspan (Meksika)",
	"display_et" to "Eston",
	"display_eu" to "Bask",
	"display_fa" to "Fars",
	"display_fi" to "Fin",
	"display_fil" to "Filipin",
	"display_fr" to "Fransız dili",
	"display_fr_CA" to "Kanadalı fransız",
	"display_gl" to "Qalisiya dili",
	"display_gu" to "Qujarati",
	"display_he" to "İbrani",
	"display_hi" to "Hindi",
	"display_hr" to "Xorvatiya",
	"display_hu" to "Macar",
	"display_hy" to "erməni",
	"display_id" to "İndoneziya",
	"display_is" to "İslandiya",
	"display_it" to "İtalyan",
	"display_ja" to "Yapon",
	"display_ka" to "Gürcü",
	"display_kk" to "Qazax",
	"display_km" to "Mərkəzi Kxmer",
	"display_kn" to "Kannada",
	"display_ko" to "Koreyalı",
	"display_ky" to "Qırğız",
	"display_lo" to "Lao",
	"display_lt" to "Litva",
	"display_lv" to "Latviya",
	"display_mk" to "Makedon",
	"display_ml" to "Malayalam",
	"display_mn" to "Monqol",
	"display_mr" to "Marathi",
	"display_ms" to "Malay",
	"display_my" to "Birma",
	"display_nb" to "Norveç Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Holland",
	"display_or" to "Oriya",
	"display_pa" to "Pəncabi",
	"display_pl" to "Polyak",
	"display_pt" to "Portuqalca",
	"display_pt_BR" to "portuqal (Braziliya)",
	"display_pt_PT" to "Avropa Portuqaliyası",
	"display_ro" to "Rumun",
	"display_ru" to "rus",
	"display_si" to "Sinhala",
	"display_sk" to "Slovakiya",
	"display_sl" to "Sloveniya",
	"display_sq" to "Alban",
	"display_sr" to "Serb",
	"display_sv" to "İsveç",
	"display_sw" to "Svahili",
	"display_ta" to "Tamil",
	"display_te" to "Teluqu",
	"display_th" to "Tayland",
	"display_tr" to "Türk",
	"display_uk" to "Ukraynalı",
	"display_ur" to "Urdu",
	"display_uz" to "Özbək",
	"display_vi" to "Vyetnamca",
	"display_zh_Hant_TW" to "Çin (Tayvan, Ənənəvi)",
	"display_zu" to "Zulu",
	"document_estimate" to "Qiymətləndirmək",
	"document_invoice" to "Faktura",
	"document_quote" to "Sitat",
	"done" to "edildi",
	"download_app" to "Tətbiqi yüklə",
	"download_invoice" to "Fakturanı yüklə",
	"download_pdf" to "PDF yüklə",
	"draw_signature" to "İmza çəkmək",
	"due" to Formattable { (date) -> "Due ${date}" },
	"due_date" to "Vaxtı tarixi",
	"due_day" to "bir gündə",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} görə ${dueWhen}!" },
	"due_today" to "Bu gün",
	"due_week" to "bir həftə içində",
	"duplicate" to "Dublikat",
	"duration_day" to "Gün",
	"duration_month" to "ay",
	"duration_week" to "Həftə",
	"duration_year" to "İl",
	"email" to "E-poçt",
	"email_blank_error" to "E-poçt tələb.",
	"email_error" to "Yanlış e-poçt ünvanı.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Biznesiniz üçün təşəkkür edirik. Tam ${documentType} görmək üçün bu məktubun sonunda düyməni basın. Qeyd ${dueDate} -ə qədər etibarlıdır." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Yeni aldınız ${documentType}${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "BAX ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Hörmətli ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Biznesiniz üçün çox sağolun. Tam ${documentType}-ə baxmaq üçün bu məktubun sonundakı düyməyə klikləyin. Zəhmət olmasa balansı ${dueDate} tarixinə qədər ödəyin." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Yeni aldınız ${documentType}${invoiceNumber}!" },
	"email_reset_success_message" to "Göndərilən parol sıfırlamak üçün e-poçt, gelen kutunuza baxın.",
	"empty_clients_list_message" to "Siz izləmək üçün heç bir müştəri yoxdur. Yeni bir müştəri əlavə edin.",
	"empty_estimate_list_message" to "İlk təxmininizi yaradın",
	"empty_expenses" to "İzləmək üçün heç bir xərcləriniz yoxdur. Yeni bir xərc əlavə edin.",
	"empty_invoice_list_message" to "İlk fakturanı yaradın",
	"empty_items_list_message" to "Siz heç bir maddələr var, yeni bir maddə əlavə!",
	"empty_signatures_list_message" to "İmza tapılmadı. Birini yaradın!",
	"empty_taxes_list_message" to "Vergi qaydaları yoxdur. Yeni vergi qayda əlavə et.",
	"empty_templates_list_message" to "Şablonları əlçatmaz.",
	"enabled" to "Enabled",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Qiymətləndirmə #",
	"estimates" to "Qiymətləndirmələr",
	"estimates_report" to "Qiymətləndirmələr",
	"expense_bank_fees" to "Bank ödənişləri",
	"expense_benefits" to "Faydaları",
	"expense_communication" to "Rabitə",
	"expense_debt" to "Borc",
	"expense_deleted" to "Xərc silindi",
	"expense_depreciation" to "Amortizasiya",
	"expense_gifts" to "Hədiyyələr",
	"expense_insurance" to "Sığorta",
	"expense_inventory" to "İnventarlaşdırma",
	"expense_legal" to "Hüquqi",
	"expense_maintenance" to "Baxım",
	"expense_marketing" to "Marketinq",
	"expense_meals" to "Yeməklər",
	"expense_office_supplies" to "Ofis ləvazimatları",
	"expense_rent" to "İcarə",
	"expense_salaries" to "Əmək haqqı",
	"expense_shopping" to "Alış-veriş",
	"expense_software" to "Proqram",
	"expense_status_available" to "Mövcud",
	"expense_status_processing" to "emal",
	"expense_tax" to "Vergi",
	"expense_training" to "Təlim",
	"expense_transport" to "Nəqliyyat",
	"expense_travel" to "Səyahət",
	"expense_utility" to "Kommunal xidmət",
	"expenses" to "Xərclər",
	"feedback_option" to "Geribildiriminiz",
	"filter" to "Süzgəc",
	"first_name_hint" to "Ad",
	"forgot_password" to "Şifrəni sıfırla",
	"forgot_password_label" to "Şifrəni unutmusunuz",
	"from" to "etibarən",
	"general_error" to "Səhv baş verdi, xahiş edirik daha sonra yenidən cəhd edin.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Geri qayıdın",
	"group_by" to "Qrup üzrə:",
	"image_view" to "Image",
	"install" to "Quraşdırın",
	"invalid_credentials_error" to "Yanlış etimadnamə.",
	"invalid_email_error" to "Yanlış e-poçt.",
	"invalid_user_error" to "Yanlış istifadəçi etimadnaməsi.",
	"invoice_deleted_message" to "Faktura silindi",
	"invoice_menu" to "Faktura menyusu",
	"invoice_number" to "Faktura #",
	"invoices" to "Fakturalar",
	"items_option" to "maddələr",
	"keep" to "Saxlayın",
	"language_selector" to Formattable { (selected) -> "Dil ${selected}" },
	"last_name_hint" to "Soyad",
	"ledger" to "ledger",
	"legal" to "Hüquqi",
	"legal_error" to "Xidmət şərtləri və Məxfilik Siyasətini qəbul etməlidir",
	"liabilities" to "liabilities",
	"loading" to "Loading",
	"login_required_error" to "Giriş tələb.",
	"logo_view" to "Logo",
	"logout" to "giriş",
	"logout_prompt" to "Məlumatlarınız bulud ilə sinxronlaşdırılmır. Hesablamalar, təxminlər, müştərilər və çıxış haqqında digər məlumatları silin?",
	"main_screen" to "Əsas ekran",
	"mark_paid" to "Ödənişli olaraq qeyd edin",
	"message" to "Mesaj",
	"missing_sender_msg" to "Göndərən məlumatı olmayan sənəd etibarsız ola bilər.",
	"missing_sender_title" to "Göndərən məlumatları itkin",
	"month_april" to "Aprel",
	"month_april_short" to "Aprel",
	"month_august" to "Avqust",
	"month_august_short" to "Avq.",
	"month_december" to "Dekabr",
	"month_december_short" to "Dekb",
	"month_february" to "Fevral",
	"month_february_short" to "Fevral",
	"month_january" to "yanvar",
	"month_january_short" to "Yanvar",
	"month_july" to "İyul",
	"month_july_short" to "İyul",
	"month_june" to "İyun",
	"month_june_short" to "İyun",
	"month_march" to "mart",
	"month_march_short" to "mart",
	"month_may" to "May",
	"month_may_short" to "May",
	"month_november" to "Noyabr",
	"month_november_short" to "Noyr.",
	"month_october" to "oktyabr",
	"month_october_short" to "Okt.",
	"month_september" to "Sentyabr",
	"month_september_short" to "Sentyabr.",
	"multifactor_error" to "Təsdiq edə bilmədi.",
	"new" to "Yeni",
	"news" to "Xəbərlər",
	"next" to "növbəti",
	"no" to "no",
	"no_data_available" to "Mövcud məlumat yoxdur",
	"no_search_results" to "Bağışlayın, heç bir nəticə tapa bilmədik.",
	"none" to "Heç biri",
	"note_fields" to "Qeydlər",
	"note_hint" to "Qeyd",
	"notifications_option" to "Bildirişlər",
	"ok" to "Ok",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "DingVoice-nin əsas hissələri həmişəlik pulsuzdur. Bizim missiyamız biznesinizi inkişaf etdirməyə kömək etmək, rəyinizi bölüşərək təkmilləşdirməmizə kömək etməkdir.",
	"onboarding_aboutus_title" to "Biz kömək etmək üçün buradayıq",
	"onboarding_paid_1" to "Professional görünüşlü şablonlara daxil olun",
	"onboarding_paid_2" to "Maliyyə vəziyyətinizə daha dərindən baxın",
	"onboarding_paid_3" to "Problemləri tez həll edin",
	"onboarding_paid_4" to "Sənədlərinizi təhlükəsiz saxlayın",
	"onboarding_paid_5" to "Faktura statusu dəyişikliklərindən xəbərdar olun",
	"onboarding_paid_title" to "Daha da sürətlə inkişaf edin...",
	"onboarding_paywall_message" to "İndi ən yaxşı qiymətə bütün premium funksiyalardan həzz alın.",
	"onboarding_paywall_price_introductory" to Formattable { (introDuration, introPeriod, currencySymbol, introAmount, amount, duration) -> "${introDuration} ${introPeriod} üçün sadəcə ${currencySymbol}${introAmount} və sonra ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Sadəcə ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} ödənişsiz, sonra isə sadəcə ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, introductoryDuration, introPeriod, currencySymbol, introductoryAmount, amount, duration) -> "${trialDuration} ${trialPeriod} üçün pulsuz, sonra ${introductoryDuration} ${introPeriod} üçün ${currencySymbol}${introductoryAmount} və sonra sadəcə ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Avtomatik yenilənən. İstənilən vaxt ləğv edin.",
	"onboarding_paywall_restore" to "Satınalmanı bərpa edin",
	"onboarding_paywall_restore_failure" to "Satınalmaları bərpa etmək uğursuz oldu, sonra yenidən cəhd edin və ya dəstək xidməti ilə əlaqə saxlayın.",
	"onboarding_paywall_restore_success" to "Uğurlu bərpa edin!",
	"onboarding_paywall_title" to "Böyüklüyə nail olun!",
	"onboarding_welcome_message" to "Hesab-fakturalarınızı və təxminlərinizi idarə etmək üçün tam alətlər dəsti.",
	"onboarding_welcome_title" to "DingVoice-ə xoş gəlmisiniz",
	"other_state" to "Digər",
	"overdue_state" to "Vaxtı",
	"paid_state" to "Ödənişli",
	"password" to "Şifrə",
	"password_blank_error" to "Şifrə tələb olunur.",
	"password_mismatch_error" to "Şifrələr uyğun deyil.",
	"pay" to "pay",
	"payment_instructions_label" to "ödəniş təlimatları",
	"payment_reminders" to "Ödəniş xatırlatmaları",
	"phone_number_fields" to "Telefon nömrəsi",
	"phone_number_hint" to "Telefon nömrəsi",
	"postcode_hint" to "Poçt",
	"preview" to "önizleme",
	"preview_menu_hint" to "Preview menyusu",
	"price" to "Qiymət",
	"privacy_policy" to "Gizlilik Siyasəti",
	"profile" to "Profil",
	"profitable_client" to "Sizin ən sərfəli müştəriniz...",
	"profits_report" to "Mənfəət",
	"promo_content" to "Təqdimat məzmunu",
	"quantity_hint" to "Miqdarı",
	"quantity_label_hint" to "Miqdarı Etiket",
	"query_info" to "Sorğu giriş kimi həm əşyalara, həm də digər vergi qaydalarına istinadən xüsusi hesablamalar yazmağa imkan verir. Başqa bir girişə keçid etmək üçün @ simvolu və maddə və ya vergi təsviri əlavə et +, -, *,/,% - riyaziyyat operatorları",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} müştəriniz tərəfindən qəbul edildi." },
	"received_state" to "Alınan",
	"registration_code_fields" to "Qeydiyyat kodu",
	"reminder" to "Xatırlatma",
	"reports" to "Hesabatlar",
	"reports_not_available" to "No hesabat, sonra geri gəlmək.",
	"reveal_password" to "Şifrəni aşkar edin",
	"revenue" to "revenue",
	"sales_tax" to "ƏDV",
	"sales_tax_number" to "ƏDV nömrəsi",
	"save" to "Saxla",
	"scan_document" to "Sənədi skan edin",
	"search" to "Axtarış",
	"select" to "Seçin",
	"select_icon" to "İşarəni seçin",
	"select_signature" to "İmzanı seçin",
	"select_template" to "Şablonu seçin",
	"send" to "Göndər",
	"sent_state" to "Sent",
	"setup_sender" to "Göndərən qurun",
	"share_option" to "Share",
	"ship_to" to "Gəmi",
	"sign_in" to "Daxil ol",
	"sign_in_apple" to "Apple ilə daxil ol",
	"sign_in_email" to "E-poçt ilə daxil olun",
	"sign_in_facebook" to "Facebook ilə daxil ol",
	"sign_in_google" to "Google ilə daxil olun",
	"sign_up" to "Qeydiyyatdan keçin",
	"sign_up_label" to "Hesabınız yoxdur?",
	"sign_up_link_label" to "Qeydiyyatdan keçin!",
	"signatures_option" to "İmzalar",
	"skip" to "Keç",
	"sold_items_report" to "Satılmış Məhsullar",
	"sort_by" to "Sort By:",
	"status_update" to "Sənədin vəziyyəti yeniləmə",
	"sub_annual" to "İllik",
	"sub_annual_price" to Formattable { (price) -> "${price}/il" },
	"sub_backups" to "Bulud yedeklemeler",
	"sub_insights" to "Insights",
	"sub_month" to "Aylıq",
	"sub_monthly_price" to Formattable { (price) -> "${price}/ay" },
	"sub_notifications" to "Bildirişlər",
	"sub_popup_message" to "Abunəliklər tezliklə gəlir. Bir az müddət burada geri yoxlayın.",
	"sub_support" to "Dəstək",
	"sub_templates" to "Şablonlar",
	"subscribe" to "Abunə ol",
	"subscription_header" to "Biznesinizi inkişaf etdirin:",
	"subscription_option" to "Abunə",
	"subscriptions_load_fail" to "Üzr istəyirik, hazırda abunəliklərinizi əldə edə bilmirik. Lütfən, sonra yenidən cəhd edin və ya yardım üçün dəstək komandamızla əlaqə saxlayın.",
	"subtotal" to Formattable { (sum) -> "Ümumi cəmi: ${sum}" },
	"subtotal_label" to "Cəmi",
	"sum" to "Sum",
	"support_option" to "Dəstək",
	"switch_reports" to "Hesabatı Switch",
	"tax" to "Vergi",
	"tax_deleted_message" to "Vergi silindi",
	"tax_description_warning" to "Istifadə edilərsə, bu bir faktura göstərir",
	"tax_input_flat" to "Mənzil",
	"tax_input_percent" to "Yüzdə",
	"tax_number_fields" to "Vergi Nömrəsi",
	"tax_number_hint" to "Vergi Nömrəsi",
	"tax_query_info_description" to "Sorğu məlumatı",
	"tax_report" to "Vergi",
	"tax_rules_option" to "Vergi Qaydaları",
	"taxed_clients_report" to "Vergiyə cəlb olunan müştərilər",
	"template" to "Şablon",
	"template_selector_info" to "Standart şablonu dəyişdirmək üçün sürüşdürün",
	"template_unlock_promo" to "Premium ilə daha çox şablon əldə edin",
	"templates_option" to "Şablonlar",
	"terms_of_service" to "Xidmət şərtləri",
	"thank_you" to "Təşəkkürlər",
	"to" to "Tə",
	"total" to Formattable { (sum) -> "Cəmi: ${sum}" },
	"total_label" to "Cəmi",
	"undo_action" to "Qalma",
	"unit" to "Unit",
	"unit_cost_hint" to "Vahid qiyməti",
	"unknown_client" to "Bilinməyən müştəri",
	"unlock" to "Kilidini aç",
	"unnamed_item" to "Adsız maddə",
	"upgrade" to "Upgrade",
	"user_collision_error" to "Hesab artıq mövcuddur.",
	"valid_until" to "Tənə qədər etibarlıdır",
	"warning" to "Xəbərdarlıq!",
	"yes" to "yes",
    )
    
}