// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class El  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Αποδοχή των ${termsOfService} και ${privacyPolicy}" }
override val account: String = "λογαριασμός"
override val accounting: String = "λογιστική"
override val accruals: String = "δεδουλευμένα"
override val action_code_error: String = "Μη έγκυρος κωδικός."
override val add_client_action: String = "Προσθήκη πελάτη"
override val add_customer: String = "Προσθήκη πελάτη"
override val add_expense: String = "Προσθήκη δαπανών"
override val add_fields_label: String = "Προσθέστε:"
override val add_invoice_action: String = "Δημιουργία"
override val add_item_action: String = "Προσθήκη αντικειμένου"
override val add_item_button: String = "Προσθήκη αντικειμένου"
override val add_signature_action: String = "Προσθήκη υπογραφής"
override val add_tax_action: String = "Προσθήκη φόρου"
override val address_fields: String = "Διεύθυνση"
override val address_line_hint: String = "Γραμμή διεύθυνσης"
override val advanced: String = "Προχωρημένο"
override val agree_marketing: String = "Συμφωνώ να λαμβάνω επικοινωνίες άμεσου μάρκετινγκ"
override val all_notifications: String = "Όλες οι ειδοποιήσεις"
override val amortization: String = "απόσβεση"
override val amount: String = "Ποσό"
override val asset: String = "περιουσιακό στοιχείο"
override val attach_file: String = "Επισύναψη Αρχείου"
override val attachment: String = "Συνημμένο"
override val auth_error: String = "Η ενέργεια απέτυχε, προσπαθήστε ξανά."
override val available_for_paid: String = "Ο συγχρονισμός στο σύννεφο είναι διαθέσιμος μόνο στο πλαίσιο συνδρομής επί πληρωμή."
override val backups_option: String = "Αντίγραφα ασφαλείας"
override val balance: String = "υπόλοιπο"
override val bank_account: String = "Τραπεζικός Λογαριασμός"
override val bill_number: String = "Αριθμός λογαριασμού"
override val cancel: String = "Ακύρωση"
override val cancel_trial: String = "Ακύρωση ανά πάσα στιγμή"
override val capital: String = "κεφάλαιο"
override val cash: String = "μετρητά"
override val categories: String = "Κατηγορίες"
override val category: String = "Κατηγορία"
override val category_add: String = "Προσθήκη Κατηγορίας"
override val category_delete: String = "Διαγραφή κατηγορίας"
override val category_name: String = "Όνομα κατηγορίας"
override val change_language: Formattable = Formattable { (language) -> "Γλώσσα τιμολογίου: ${language}" }
override val change_language_label: String = "Γλώσσα Τιμολογίου"
override val change_template: String = "Αλλαγή προτύπου"
override val city_hint: String = "Πόλη"
override val clear: String = "Σαφής"
override val client: String = "Πελάτης"
override val client_actions: String = "Ενέργειες πελατών"
override val client_deleted_message: String = "Ο πελάτης διαγράφηκε"
override val clients_error_required_fields: String = "Απαιτείται τουλάχιστον μία τιμή."
override val clients_option: String = "Πελάτες"
override val close: String = "Κλείστε το"
override val cloud_sync: String = "Συγχρονισμός Cloud"
override val coming_soon: String = "Έρχεται σύντομα!"
override val company_logo: String = "Λογότυπο εταιρείας"
override val company_name_hint: String = "Εταιρεία"
override val confirm_password_label: String = "Επιβεβαίωση κωδικού πρόσβασης"
override val confirmation_title: String = "Είσαι σίγουρος;"
override val convert_to_invoice: String = "Μετατροπή σε τιμολόγιο"
override val country_hint: String = "Χώρα"
override val create_client: String = "Δημιουργία πελάτη"
override val create_company: String = "Δημιουργία εταιρείας"
override val create_document: String = "Δημιουργία εγγράφου"
override val create_estimate: String = "Εκτίμηση"
override val create_invoice: String = "Τιμολόγιο"
override val create_item: String = "Δημιουργία αντικειμένου"
override val create_new_client: String = "Δημιουργία νέου πελάτη"
override val create_new_item: String = "Δημιουργία νέου στοιχείου"
override val create_new_tax: String = "Δημιουργία νέου φόρου"
override val create_signature: String = "Υπογραφή"
override val created: String = "Δημιουργήθηκε"
override val credit: String = "πιστωτική"
override val customer: String = "Πελάτης"
override val date: String = "Ημερομηνία"
override val debit: String = "χρεωστική"
override val debt: String = "χρέος"
override val default_currency: Formattable = Formattable { (currency) -> "Προεπιλεγμένο νόμισμα: ${currency}" }
override val default_currency_label: String = "Προεπιλεγμένο νόμισμα"
override val delete: String = "Διαγραφή"
override val delete_account: String = "Διαγραφή λογαριασμού"
override val delete_confirmation_message: String = "Όλα τα δεδομένα σας θα χαθούν."
override val delete_item_title: String = "Να διαγράψετε το αντικείμενο;"
override val delete_signature: String = "Διαγραφή υπογραφής"
override val depreciation: String = "υποτίμηση"
override val description: String = "Περιγραφή"
override val description_hint: String = "Περιγραφή"
override val detailed_report: String = "Λεπτομερής Αναφορά"
override val disabled: String = "Άτομα με ειδικές ανάγκες"
override val discount: String = "Έκπτωση"
override val discount_hint: String = "Έκπτωση"
override val display_af: String = "Afrikaans"
override val display_am: String = "Αμχαρικά"
override val display_ar: String = "Αραβικά"
override val display_as: String = "Assamese"
override val display_az: String = "Αζερμπαϊτζάν"
override val display_be: String = "Λευκορωσία"
override val display_bg: String = "Βουλγαρική"
override val display_bn: String = "Μπενγκάλι"
override val display_bs: String = "Βοσνιακή"
override val display_ca: String = "Καταλανικά"
override val display_cs: String = "Τσεχική"
override val display_da: String = "Δανικά"
override val display_de: String = "Γερμανικά"
override val display_el: String = "Ελληνική"
override val display_en: String = "αγγλικά"
override val display_es: String = "Ισπανικά"
override val display_es_MX: String = "Ισπανικά (Μεξικό)"
override val display_et: String = "Εσθονικά"
override val display_eu: String = "Βάσκοι"
override val display_fa: String = "Περσικό"
override val display_fi: String = "Φινλανδική"
override val display_fil: String = "Φιλιππινέζικα"
override val display_fr: String = "Γαλλικά"
override val display_fr_CA: String = "Καναδέζικα γαλλικά"
override val display_gl: String = "Γαλικία"
override val display_gu: String = "Γκουτζαράτι"
override val display_he: String = "Εβραϊκά"
override val display_hi: String = "Χίντι"
override val display_hr: String = "Κροατικά"
override val display_hu: String = "Ουγγρικό"
override val display_hy: String = "Αρμένιοι"
override val display_id: String = "Ινδονησιακή"
override val display_is: String = "Ισλανδική"
override val display_it: String = "Ιταλικά"
override val display_ja: String = "Ιαπωνικά"
override val display_ka: String = "Γεωργιανή"
override val display_kk: String = "Καζακστάν"
override val display_km: String = "Κεντρική Χμερ"
override val display_kn: String = "Κανάντα"
override val display_ko: String = "Κορεάτικα"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Λάος"
override val display_lt: String = "Λιθουανικά"
override val display_lv: String = "Λετονία"
override val display_mk: String = "Μακεδονικό"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Μογγολικά"
override val display_mr: String = "Μαράθι"
override val display_ms: String = "Μαλαισία"
override val display_my: String = "Βιρμανίας"
override val display_nb: String = "Νορβηγικό Bokmål"
override val display_ne: String = "Νεπάλ"
override val display_nl: String = "Ολλανδικά"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Πολωνικά"
override val display_pt: String = "Πορτογαλικά"
override val display_pt_BR: String = "Πορτογαλικά (Βραζιλία)"
override val display_pt_PT: String = "Ευρωπαϊκά πορτογαλικά"
override val display_ro: String = "Ρουμανικά"
override val display_ru: String = "Ρωσική"
override val display_si: String = "Σινχάλα"
override val display_sk: String = "Σλοβακική"
override val display_sl: String = "Σλοβενική"
override val display_sq: String = "Αλβανικά"
override val display_sr: String = "Σέρβικα"
override val display_sv: String = "Σουηδικά"
override val display_sw: String = "Σουαχίλι"
override val display_ta: String = "Ταμίλ"
override val display_te: String = "Telugu"
override val display_th: String = "Ταϊλάνδης"
override val display_tr: String = "Τουρκικά"
override val display_uk: String = "Ουκρανικό"
override val display_ur: String = "Ουρντού"
override val display_uz: String = "Ουζμπεκιστάν"
override val display_vi: String = "Βιετναμέζικα"
override val display_zh_Hant_TW: String = "Κινέζικα (Ταϊβάν, Παραδοσιακά)"
override val display_zu: String = "Ζουλού"
override val document_estimate: String = "Εκτίμηση"
override val document_invoice: String = "Τιμολόγιο"
override val document_quote: String = "Απόσπασμα"
override val done: String = "Έγινε"
override val download_app: String = "Κατεβάστε την εφαρμογή"
override val download_invoice: String = "Λήψη τιμολογίου"
override val download_pdf: String = "Λήψη PDF"
override val draw_signature: String = "Σχεδιάστε μια υπογραφή"
override val due: Formattable = Formattable { (date) -> "Λήξη ${date}" }
override val due_date: String = "Ημερομηνία λήξης"
override val due_day: String = "σε μια μέρα"
override val due_label: String = "Λόγω"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "Το ${documentType} ${documentNumber} πρέπει να πληρωθεί ${dueWhen}!" }
override val due_today: String = "Σήμερα"
override val due_week: String = "σε μια εβδομάδα"
override val duplicate: String = "Αντίγραφο"
override val duration_day: String = "Ημέρα"
override val duration_month: String = "μήνας"
override val duration_week: String = "εβδομάδα"
override val duration_year: String = "έτος"
override val email: String = "Ηλεκτρονικό ταχυδρομείο"
override val email_blank_error: String = "Απαιτείται email."
override val email_error: String = "Μη έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Σας ευχαριστούμε για την επιχείρησή σας. Για να δείτε ολόκληρο το ${documentType} κάντε κλικ στο κουμπί στο τέλος αυτής της επιστολής. Σημειώστε ότι ισχύει μέχρι την ημερομηνία λήξης ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Λάβατε ένα νέο ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ΔΕΙΤΕ ΤΟΝ ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Αγαπητέ ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Σας ευχαριστούμε για την επιχείρησή σας. Για να δείτε ολόκληρο το ${documentType} κάντε κλικ στο κουμπί στο τέλος αυτής της επιστολής. Παρακαλούμε να πληρώσετε το υπόλοιπο μέχρι την ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Λάβατε ένα νέο ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Το email για την επαναφορά του κωδικού πρόσβασης εστάλη, παρακαλούμε ελέγξτε τα εισερχόμενά σας."
override val empty_clients_list_message: String = "Δεν έχετε πελάτες να παρακολουθείτε. Προσθέστε έναν νέο πελάτη."
override val empty_estimate_list_message: String = "Δημιουργήστε την πρώτη σας εκτίμηση"
override val empty_expenses: String = "Δεν έχετε έξοδα να παρακολουθείτε. Προσθέστε μια νέα δαπάνη."
override val empty_invoice_list_message: String = "Δημιουργήστε το πρώτο σας τιμολόγιο"
override val empty_items_list_message: String = "Δεν έχετε αντικείμενα, προσθέστε ένα νέο αντικείμενο!"
override val empty_signatures_list_message: String = "Δεν βρέθηκαν υπογραφές. Δημιουργήστε μία!"
override val empty_taxes_list_message: String = "Δεν έχετε φορολογικούς κανόνες. Προσθέστε έναν νέο φορολογικό κανόνα."
override val empty_templates_list_message: String = "Τα πρότυπα δεν είναι διαθέσιμα."
override val enabled: String = "Ενεργοποιημένο"
override val entry: String = "είσοδος"
override val equity: String = "μετοχικό κεφάλαιο"
override val estimate_number: String = "Εκτίμηση #"
override val estimates: String = "Εκτιμήσεις"
override val estimates_report: String = "Εκτιμήσεις"
override val expense_bank_fees: String = "Τραπεζικά τέλη"
override val expense_benefits: String = "Οφέλη"
override val expense_communication: String = "Επικοινωνία"
override val expense_debt: String = "Χρέος"
override val expense_deleted: String = "Οι δαπάνες διαγράφηκαν"
override val expense_depreciation: String = "Απόσβεση"
override val expense_gifts: String = "Δώρα"
override val expense_insurance: String = "Ασφάλιση"
override val expense_inventory: String = "Απογραφή"
override val expense_legal: String = "Νομική"
override val expense_maintenance: String = "Συντήρηση"
override val expense_marketing: String = "Μάρκετινγκ"
override val expense_meals: String = "Γεύματα"
override val expense_office_supplies: String = "Προμήθειες γραφείου"
override val expense_rent: String = "Ενοίκιο"
override val expense_salaries: String = "Μισθοί"
override val expense_shopping: String = "Αγορές"
override val expense_software: String = "Λογισμικό"
override val expense_status_available: String = "Διαθέσιμο"
override val expense_status_processing: String = "Επεξεργασία"
override val expense_tax: String = "Φόρος"
override val expense_training: String = "Εκπαίδευση"
override val expense_transport: String = "Μεταφορές"
override val expense_travel: String = "Ταξίδι"
override val expense_utility: String = "Χρησιμότητα"
override val expenses: String = "Δαπάνες"
override val feedback_option: String = "Ανατροφοδότηση"
override val filter: String = "Φίλτρο"
override val first_name_hint: String = "Όνομα"
override val forgot_password: String = "Επαναφορά κωδικού πρόσβασης"
override val forgot_password_label: String = "Ξεχάσατε τον κωδικό πρόσβασης"
override val from: String = "Από το"
override val general_error: String = "Παρουσιάστηκε σφάλμα, παρακαλώ δοκιμάστε ξανά αργότερα."
override val general_ledger: String = "γενικό καθολικό"
override val go_back_label: String = "Πηγαίνετε πίσω"
override val group_by: String = "Ομάδα από:"
override val image_view: String = "Εικόνα"
override val install: String = "Εγκατάσταση"
override val invalid_credentials_error: String = "Μη έγκυρα διαπιστευτήρια."
override val invalid_email_error: String = "Μη έγκυρο email."
override val invalid_user_error: String = "Μη έγκυρα διαπιστευτήρια χρήστη."
override val invoice_deleted_message: String = "Διαγραφή τιμολογίου"
override val invoice_menu: String = "Μενού τιμολογίου"
override val invoice_number: String = "Τιμολόγιο #"
override val invoices: String = "Τιμολόγια"
override val items_option: String = "Στοιχεία"
override val keep: String = "Κρατήστε το"
override val language_selector: Formattable = Formattable { (selected) -> "Γλώσσα ${selected}" }
override val last_name_hint: String = "Επώνυμο"
override val ledger: String = "καθολικό"
override val legal: String = "Νομικό"
override val legal_error: String = "Πρέπει να αποδέχεστε τους Όρους Υπηρεσίας και την Πολιτική Απορρήτου"
override val liabilities: String = "υποχρεώσεις"
override val loading: String = "Φόρτωση"
override val login_required_error: String = "Απαιτείται σύνδεση."
override val logo_view: String = "Λογότυπο"
override val logout: String = "Αποσύνδεση"
override val logout_prompt: String = "Τα δεδομένα σας δεν συγχρονίζονται με το σύννεφο. Διαγραφή τιμολογίων, εκτιμήσεων, πελατών και άλλων πληροφοριών κατά την αποσύνδεση;"
override val main_screen: String = "Κύρια οθόνη"
override val mark_paid: String = "Χαρακτηρίστε ως πληρωμένο"
override val message: String = "Μήνυμα"
override val missing_sender_msg: String = "Ένα έγγραφο χωρίς πληροφορίες αποστολέα ενδέχεται να μην είναι έγκυρο."
override val missing_sender_title: String = "Λείπουν πληροφορίες αποστολέα"
override val month_april: String = "Απρίλιος"
override val month_april_short: String = "Απρίλιος"
override val month_august: String = "Αύγουστος"
override val month_august_short: String = "Αυγ."
override val month_december: String = "Δεκέμβριος"
override val month_december_short: String = "Δεκ."
override val month_february: String = "Φεβρουάριος"
override val month_february_short: String = "Φεβ."
override val month_january: String = "Ιανουάριος"
override val month_january_short: String = "Ιαν."
override val month_july: String = "Ιούλιος"
override val month_july_short: String = "Ιούλιος"
override val month_june: String = "Ιούνιος"
override val month_june_short: String = "Ιούνιος"
override val month_march: String = "Μάρτιος"
override val month_march_short: String = "Μάρτιος"
override val month_may: String = "Μάιος"
override val month_may_short: String = "Μάιος"
override val month_november: String = "Νοέμβριος"
override val month_november_short: String = "Νοε."
override val month_october: String = "Οκτώβριος"
override val month_october_short: String = "Οκτ."
override val month_september: String = "Σεπτέμβριος"
override val month_september_short: String = "Σεπτ."
override val multifactor_error: String = "Απέτυχε η επαλήθευση."
override val new: String = "Νέα"
override val news: String = "Νέα"
override val next: String = "Επόμενο"
override val no: String = "όχι"
override val no_data_available: String = "Δεν υπάρχουν διαθέσιμα δεδομένα"
override val no_search_results: String = "Λυπούμαστε, δεν μπορέσαμε να βρούμε αποτελέσματα."
override val none: String = "Κανένα"
override val note_fields: String = "Σημειώσεις"
override val note_hint: String = "Σημείωση"
override val notifications_option: String = "Ειδοποιήσεις"
override val ok: String = "Εντάξει"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Τα βασικά μέρη του DingVoice είναι για πάντα δωρεάν. Η αποστολή μας είναι να σας βοηθήσουμε να αναπτύξετε την επιχείρησή σας, βοηθήστε μας να βελτιωθούμε μοιραζόμενοι τα σχόλιά σας."
override val onboarding_aboutus_title: String = "Είμαστε εδώ για να βοηθήσουμε"
override val onboarding_paid_1: String = "Πρόσβαση σε επαγγελματικά πρότυπα"
override val onboarding_paid_2: String = "Ρίξτε μια βαθύτερη ματιά στα οικονομικά σας"
override val onboarding_paid_3: String = "Επιλύστε τα προβλήματα γρήγορα"
override val onboarding_paid_4: String = "Διατηρήστε τα έγγραφά σας ασφαλή"
override val onboarding_paid_5: String = "Μείνετε ενήμεροι για τις αλλαγές στην κατάσταση των τιμολογίων"
override val onboarding_paid_title: String = "Αναπτυχθείτε ακόμα πιο γρήγορα με..."
override val onboarding_paywall_message: String = "Απολαύστε όλα τα premium χαρακτηριστικά τώρα στην καλύτερη τιμή."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Μόνο το ${currencySymbol}${introAmount} για το ${introDuration} ${introPeriod} και στη συνέχεια ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Μόλις ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Δωρεάν για ${trialDuration} ${trialPeriod} και στη συνέχεια μόνο ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Δωρεάν για ${trialDuration} ${trialPeriod} μετά ${currencySymbol}${introductoryAmount} για ${introductoryDuration} ${introPeriod} και μετά μόνο ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Αυτοανανεώσιμη. Ακύρωση ανά πάσα στιγμή."
override val onboarding_paywall_restore: String = "Επαναφορά αγοράς"
override val onboarding_paywall_restore_failure: String = "Η επαναφορά των αγορών απέτυχε, προσπαθήστε ξανά αργότερα ή επικοινωνήστε με την υποστήριξη."
override val onboarding_paywall_restore_success: String = "Επαναφορά επιτυχής!"
override val onboarding_paywall_title: String = "Πετύχετε το μεγαλείο!"
override val onboarding_welcome_message: String = "Ένα πλήρες σύνολο εργαλείων για τη διαχείριση των τιμολογίων και των εκτιμήσεών σας."
override val onboarding_welcome_title: String = "Καλώς ήρθατε στη DingVoice"
override val other_state: String = "Άλλο"
override val overdue_state: String = "Καθυστερημένο"
override val paid_state: String = "Πληρωμένο"
override val password: String = "Κωδικός πρόσβασης"
override val password_blank_error: String = "Απαιτείται κωδικός πρόσβασης."
override val password_mismatch_error: String = "Οι κωδικοί πρόσβασης δεν ταιριάζουν."
override val pay: String = "πληρωμή"
override val payment_instructions_label: String = "Οδηγίες πληρωμής"
override val payment_reminders: String = "Υπενθυμίσεις πληρωμής"
override val phone_number_fields: String = "Αριθμός τηλεφώνου"
override val phone_number_hint: String = "Αριθμός τηλεφώνου"
override val postcode_hint: String = "Ταχυδρομικός κώδικας"
override val preview: String = "Προεπισκόπηση"
override val preview_menu_hint: String = "Μενού προεπισκόπησης"
override val price: String = "Τιμή"
override val privacy_policy: String = "Πολιτική απορρήτου"
override val profile: String = "Προφίλ"
override val profitable_client: String = "Ο πιο κερδοφόρος πελάτης σας είναι..."
override val profits_report: String = "Κέρδη"
override val promo_content: String = "Περιεχόμενο προώθησης"
override val quantity_hint: String = "Ποσότητα"
override val quantity_label_hint: String = "Ετικέτα ποσότητας"
override val query_info: String = "Το Query σας επιτρέπει να γράφετε προσαρμοσμένους υπολογισμούς που αναφέρονται τόσο σε στοιχεία όσο και σε άλλους φορολογικούς κανόνες ως δεδομένα εισόδου. Προσθέστε το σύμβολο @ και την περιγραφή του στοιχείου ή του φόρου για να συνδεθείτε με μια άλλη καταχώρηση +, -, *, /, % - μαθηματικοί τελεστές"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ελήφθη από τον πελάτη σας." }
override val received_state: String = "Ελήφθη"
override val registration_code_fields: String = "Κωδικός Εγγραφής"
override val reminder: String = "Υπενθύμιση"
override val reports: String = "Αναφορές"
override val reports_not_available: String = "Δεν υπάρχουν διαθέσιμες αναφορές, επιστρέψτε αργότερα."
override val reveal_password: String = "Αποκάλυψη κωδικού πρόσβασης"
override val revenue: String = "έσοδα"
override val sales_tax: String = "ΦΠΑ"
override val sales_tax_number: String = "Αριθμός φορολογικού μητρώου (Α.Φ.Μ.)"
override val save: String = "Αποθήκευση"
override val scan_document: String = "Σάρωση εγγράφου"
override val search: String = "Αναζήτηση"
override val select: String = "Επιλέξτε"
override val select_icon: String = "Επιλογή εικονιδίου"
override val select_signature: String = "Επιλέξτε υπογραφή"
override val select_template: String = "Επιλογή προτύπου"
override val send: String = "Στείλτε"
override val sent_state: String = "Αποστολή"
override val setup_sender: String = "Ρύθμιση αποστολέα"
override val share_option: String = "Μοιραστείτε το"
override val ship_to: String = "Αποστολή σε"
override val sign_in: String = "Σύνδεση"
override val sign_in_apple: String = "Συνδεθείτε με την Apple"
override val sign_in_email: String = "Συνδεθείτε με email"
override val sign_in_facebook: String = "Συνδεθείτε με το Facebook"
override val sign_in_google: String = "Συνδεθείτε με το Google"
override val sign_up: String = "Εγγραφείτε"
override val sign_up_label: String = "Δεν έχετε λογαριασμό;"
override val sign_up_link_label: String = "Εγγραφείτε!"
override val signatures_option: String = "Υπογραφές"
override val skip: String = "Παράλειψη"
override val sold_items_report: String = "Πωληθέντα αντικείμενα"
override val sort_by: String = "Ταξινόμηση κατά:"
override val status_update: String = "Ενημέρωση της κατάστασης του εγγράφου"
override val sub_annual: String = "Ετήσια"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/έτος" }
override val sub_backups: String = "Δημιουργία αντιγράφων ασφαλείας Cloud"
override val sub_insights: String = "Διαπιστώσεις"
override val sub_month: String = "Μηνιαία"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/μήνα" }
override val sub_notifications: String = "Ειδοποιήσεις"
override val sub_popup_message: String = "Οι συνδρομές έρχονται σύντομα. Επιστρέψτε εδώ σε λίγο καιρό."
override val sub_support: String = "Υποστήριξη"
override val sub_templates: String = "Πρότυπα"
override val subscribe: String = "Εγγραφείτε στο"
override val subscription_header: String = "Αναπτύξτε την επιχείρησή σας με:"
override val subscription_option: String = "Συνδρομή"
override val subscriptions_load_fail: String = "Λυπούμαστε, δεν μπορούμε να ανακτήσουμε τις συνδρομές σας αυτή τη στιγμή. Παρακαλούμε προσπαθήστε ξανά αργότερα ή επικοινωνήστε με την ομάδα υποστήριξής μας για βοήθεια."
override val subtotal: Formattable = Formattable { (sum) -> "Υποσύνολο: ${sum}" }
override val subtotal_label: String = "Υποσύνολο"
override val sum: String = "Άθροισμα"
override val support_option: String = "Υποστήριξη"
override val switch_reports: String = "Αναφορά εναλλαγής"
override val tax: String = "Φόρος"
override val tax_deleted_message: String = "Φόρος διαγράφεται"
override val tax_description_warning: String = "Εάν χρησιμοποιηθεί, αυτό εμφανίζεται σε ένα τιμολόγιο"
override val tax_input_flat: String = "Επίπεδη"
override val tax_input_percent: String = "Ποσοστό"
override val tax_number_fields: String = "Αριθμός φορολογικού μητρώου"
override val tax_number_hint: String = "Αριθμός φορολογικού μητρώου"
override val tax_query_info_description: String = "Πληροφορίες ερωτήματος"
override val tax_report: String = "Φόρος"
override val tax_rules_option: String = "Φορολογικοί κανόνες"
override val taxed_clients_report: String = "Φορολογημένοι πελάτες"
override val template: String = "Πρότυπο"
override val template_selector_info: String = "Σύρετε για να αλλάξετε το προεπιλεγμένο πρότυπο"
override val template_unlock_promo: String = "Πρόσβαση σε περισσότερα πρότυπα με το Premium"
override val templates_option: String = "Πρότυπα"
override val terms_of_service: String = "Όροι χρήσης"
override val thank_you: String = "Σας ευχαριστώ"
override val to: String = "Προς"
override val total: Formattable = Formattable { (sum) -> "Σύνολο: ${sum}" }
override val total_label: String = "Σύνολο"
override val undo_action: String = "Αναίρεση"
override val unit: String = "Μονάδα"
override val unit_cost_hint: String = "Κόστος μονάδας"
override val unknown_client: String = "Άγνωστος πελάτης"
override val unlock: String = "Ξεκλειδώστε το"
override val unnamed_item: String = "Ανώνυμο στοιχείο"
override val upgrade: String = "Αναβάθμιση"
override val user_collision_error: String = "Ο λογαριασμός υπάρχει ήδη."
override val valid_until: String = "Ισχύει μέχρι"
override val warning: String = "Προειδοποίηση!"
override val yes: String = "ναι"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Αποδοχή των ${termsOfService} και ${privacyPolicy}" },
	"account" to "λογαριασμός",
	"accounting" to "λογιστική",
	"accruals" to "δεδουλευμένα",
	"action_code_error" to "Μη έγκυρος κωδικός.",
	"add_client_action" to "Προσθήκη πελάτη",
	"add_customer" to "Προσθήκη πελάτη",
	"add_expense" to "Προσθήκη δαπανών",
	"add_fields_label" to "Προσθέστε:",
	"add_invoice_action" to "Δημιουργία",
	"add_item_action" to "Προσθήκη αντικειμένου",
	"add_item_button" to "Προσθήκη αντικειμένου",
	"add_signature_action" to "Προσθήκη υπογραφής",
	"add_tax_action" to "Προσθήκη φόρου",
	"address_fields" to "Διεύθυνση",
	"address_line_hint" to "Γραμμή διεύθυνσης",
	"advanced" to "Προχωρημένο",
	"agree_marketing" to "Συμφωνώ να λαμβάνω επικοινωνίες άμεσου μάρκετινγκ",
	"all_notifications" to "Όλες οι ειδοποιήσεις",
	"amortization" to "απόσβεση",
	"amount" to "Ποσό",
	"asset" to "περιουσιακό στοιχείο",
	"attach_file" to "Επισύναψη Αρχείου",
	"attachment" to "Συνημμένο",
	"auth_error" to "Η ενέργεια απέτυχε, προσπαθήστε ξανά.",
	"available_for_paid" to "Ο συγχρονισμός στο σύννεφο είναι διαθέσιμος μόνο στο πλαίσιο συνδρομής επί πληρωμή.",
	"backups_option" to "Αντίγραφα ασφαλείας",
	"balance" to "υπόλοιπο",
	"bank_account" to "Τραπεζικός Λογαριασμός",
	"bill_number" to "Αριθμός λογαριασμού",
	"cancel" to "Ακύρωση",
	"cancel_trial" to "Ακύρωση ανά πάσα στιγμή",
	"capital" to "κεφάλαιο",
	"cash" to "μετρητά",
	"categories" to "Κατηγορίες",
	"category" to "Κατηγορία",
	"category_add" to "Προσθήκη Κατηγορίας",
	"category_delete" to "Διαγραφή κατηγορίας",
	"category_name" to "Όνομα κατηγορίας",
	"change_language" to Formattable { (language) -> "Γλώσσα τιμολογίου: ${language}" },
	"change_language_label" to "Γλώσσα Τιμολογίου",
	"change_template" to "Αλλαγή προτύπου",
	"city_hint" to "Πόλη",
	"clear" to "Σαφής",
	"client" to "Πελάτης",
	"client_actions" to "Ενέργειες πελατών",
	"client_deleted_message" to "Ο πελάτης διαγράφηκε",
	"clients_error_required_fields" to "Απαιτείται τουλάχιστον μία τιμή.",
	"clients_option" to "Πελάτες",
	"close" to "Κλείστε το",
	"cloud_sync" to "Συγχρονισμός Cloud",
	"coming_soon" to "Έρχεται σύντομα!",
	"company_logo" to "Λογότυπο εταιρείας",
	"company_name_hint" to "Εταιρεία",
	"confirm_password_label" to "Επιβεβαίωση κωδικού πρόσβασης",
	"confirmation_title" to "Είσαι σίγουρος;",
	"convert_to_invoice" to "Μετατροπή σε τιμολόγιο",
	"country_hint" to "Χώρα",
	"create_client" to "Δημιουργία πελάτη",
	"create_company" to "Δημιουργία εταιρείας",
	"create_document" to "Δημιουργία εγγράφου",
	"create_estimate" to "Εκτίμηση",
	"create_invoice" to "Τιμολόγιο",
	"create_item" to "Δημιουργία αντικειμένου",
	"create_new_client" to "Δημιουργία νέου πελάτη",
	"create_new_item" to "Δημιουργία νέου στοιχείου",
	"create_new_tax" to "Δημιουργία νέου φόρου",
	"create_signature" to "Υπογραφή",
	"created" to "Δημιουργήθηκε",
	"credit" to "πιστωτική",
	"customer" to "Πελάτης",
	"date" to "Ημερομηνία",
	"debit" to "χρεωστική",
	"debt" to "χρέος",
	"default_currency" to Formattable { (currency) -> "Προεπιλεγμένο νόμισμα: ${currency}" },
	"default_currency_label" to "Προεπιλεγμένο νόμισμα",
	"delete" to "Διαγραφή",
	"delete_account" to "Διαγραφή λογαριασμού",
	"delete_confirmation_message" to "Όλα τα δεδομένα σας θα χαθούν.",
	"delete_item_title" to "Να διαγράψετε το αντικείμενο;",
	"delete_signature" to "Διαγραφή υπογραφής",
	"depreciation" to "υποτίμηση",
	"description" to "Περιγραφή",
	"description_hint" to "Περιγραφή",
	"detailed_report" to "Λεπτομερής Αναφορά",
	"disabled" to "Άτομα με ειδικές ανάγκες",
	"discount" to "Έκπτωση",
	"discount_hint" to "Έκπτωση",
	"display_af" to "Afrikaans",
	"display_am" to "Αμχαρικά",
	"display_ar" to "Αραβικά",
	"display_as" to "Assamese",
	"display_az" to "Αζερμπαϊτζάν",
	"display_be" to "Λευκορωσία",
	"display_bg" to "Βουλγαρική",
	"display_bn" to "Μπενγκάλι",
	"display_bs" to "Βοσνιακή",
	"display_ca" to "Καταλανικά",
	"display_cs" to "Τσεχική",
	"display_da" to "Δανικά",
	"display_de" to "Γερμανικά",
	"display_el" to "Ελληνική",
	"display_en" to "αγγλικά",
	"display_es" to "Ισπανικά",
	"display_es_MX" to "Ισπανικά (Μεξικό)",
	"display_et" to "Εσθονικά",
	"display_eu" to "Βάσκοι",
	"display_fa" to "Περσικό",
	"display_fi" to "Φινλανδική",
	"display_fil" to "Φιλιππινέζικα",
	"display_fr" to "Γαλλικά",
	"display_fr_CA" to "Καναδέζικα γαλλικά",
	"display_gl" to "Γαλικία",
	"display_gu" to "Γκουτζαράτι",
	"display_he" to "Εβραϊκά",
	"display_hi" to "Χίντι",
	"display_hr" to "Κροατικά",
	"display_hu" to "Ουγγρικό",
	"display_hy" to "Αρμένιοι",
	"display_id" to "Ινδονησιακή",
	"display_is" to "Ισλανδική",
	"display_it" to "Ιταλικά",
	"display_ja" to "Ιαπωνικά",
	"display_ka" to "Γεωργιανή",
	"display_kk" to "Καζακστάν",
	"display_km" to "Κεντρική Χμερ",
	"display_kn" to "Κανάντα",
	"display_ko" to "Κορεάτικα",
	"display_ky" to "Kirghiz",
	"display_lo" to "Λάος",
	"display_lt" to "Λιθουανικά",
	"display_lv" to "Λετονία",
	"display_mk" to "Μακεδονικό",
	"display_ml" to "Malayalam",
	"display_mn" to "Μογγολικά",
	"display_mr" to "Μαράθι",
	"display_ms" to "Μαλαισία",
	"display_my" to "Βιρμανίας",
	"display_nb" to "Νορβηγικό Bokmål",
	"display_ne" to "Νεπάλ",
	"display_nl" to "Ολλανδικά",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Πολωνικά",
	"display_pt" to "Πορτογαλικά",
	"display_pt_BR" to "Πορτογαλικά (Βραζιλία)",
	"display_pt_PT" to "Ευρωπαϊκά πορτογαλικά",
	"display_ro" to "Ρουμανικά",
	"display_ru" to "Ρωσική",
	"display_si" to "Σινχάλα",
	"display_sk" to "Σλοβακική",
	"display_sl" to "Σλοβενική",
	"display_sq" to "Αλβανικά",
	"display_sr" to "Σέρβικα",
	"display_sv" to "Σουηδικά",
	"display_sw" to "Σουαχίλι",
	"display_ta" to "Ταμίλ",
	"display_te" to "Telugu",
	"display_th" to "Ταϊλάνδης",
	"display_tr" to "Τουρκικά",
	"display_uk" to "Ουκρανικό",
	"display_ur" to "Ουρντού",
	"display_uz" to "Ουζμπεκιστάν",
	"display_vi" to "Βιετναμέζικα",
	"display_zh_Hant_TW" to "Κινέζικα (Ταϊβάν, Παραδοσιακά)",
	"display_zu" to "Ζουλού",
	"document_estimate" to "Εκτίμηση",
	"document_invoice" to "Τιμολόγιο",
	"document_quote" to "Απόσπασμα",
	"done" to "Έγινε",
	"download_app" to "Κατεβάστε την εφαρμογή",
	"download_invoice" to "Λήψη τιμολογίου",
	"download_pdf" to "Λήψη PDF",
	"draw_signature" to "Σχεδιάστε μια υπογραφή",
	"due" to Formattable { (date) -> "Λήξη ${date}" },
	"due_date" to "Ημερομηνία λήξης",
	"due_day" to "σε μια μέρα",
	"due_label" to "Λόγω",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "Το ${documentType} ${documentNumber} πρέπει να πληρωθεί ${dueWhen}!" },
	"due_today" to "Σήμερα",
	"due_week" to "σε μια εβδομάδα",
	"duplicate" to "Αντίγραφο",
	"duration_day" to "Ημέρα",
	"duration_month" to "μήνας",
	"duration_week" to "εβδομάδα",
	"duration_year" to "έτος",
	"email" to "Ηλεκτρονικό ταχυδρομείο",
	"email_blank_error" to "Απαιτείται email.",
	"email_error" to "Μη έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Σας ευχαριστούμε για την επιχείρησή σας. Για να δείτε ολόκληρο το ${documentType} κάντε κλικ στο κουμπί στο τέλος αυτής της επιστολής. Σημειώστε ότι ισχύει μέχρι την ημερομηνία λήξης ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Λάβατε ένα νέο ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "ΔΕΙΤΕ ΤΟΝ ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Αγαπητέ ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Σας ευχαριστούμε για την επιχείρησή σας. Για να δείτε ολόκληρο το ${documentType} κάντε κλικ στο κουμπί στο τέλος αυτής της επιστολής. Παρακαλούμε να πληρώσετε το υπόλοιπο μέχρι την ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Λάβατε ένα νέο ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Το email για την επαναφορά του κωδικού πρόσβασης εστάλη, παρακαλούμε ελέγξτε τα εισερχόμενά σας.",
	"empty_clients_list_message" to "Δεν έχετε πελάτες να παρακολουθείτε. Προσθέστε έναν νέο πελάτη.",
	"empty_estimate_list_message" to "Δημιουργήστε την πρώτη σας εκτίμηση",
	"empty_expenses" to "Δεν έχετε έξοδα να παρακολουθείτε. Προσθέστε μια νέα δαπάνη.",
	"empty_invoice_list_message" to "Δημιουργήστε το πρώτο σας τιμολόγιο",
	"empty_items_list_message" to "Δεν έχετε αντικείμενα, προσθέστε ένα νέο αντικείμενο!",
	"empty_signatures_list_message" to "Δεν βρέθηκαν υπογραφές. Δημιουργήστε μία!",
	"empty_taxes_list_message" to "Δεν έχετε φορολογικούς κανόνες. Προσθέστε έναν νέο φορολογικό κανόνα.",
	"empty_templates_list_message" to "Τα πρότυπα δεν είναι διαθέσιμα.",
	"enabled" to "Ενεργοποιημένο",
	"entry" to "είσοδος",
	"equity" to "μετοχικό κεφάλαιο",
	"estimate_number" to "Εκτίμηση #",
	"estimates" to "Εκτιμήσεις",
	"estimates_report" to "Εκτιμήσεις",
	"expense_bank_fees" to "Τραπεζικά τέλη",
	"expense_benefits" to "Οφέλη",
	"expense_communication" to "Επικοινωνία",
	"expense_debt" to "Χρέος",
	"expense_deleted" to "Οι δαπάνες διαγράφηκαν",
	"expense_depreciation" to "Απόσβεση",
	"expense_gifts" to "Δώρα",
	"expense_insurance" to "Ασφάλιση",
	"expense_inventory" to "Απογραφή",
	"expense_legal" to "Νομική",
	"expense_maintenance" to "Συντήρηση",
	"expense_marketing" to "Μάρκετινγκ",
	"expense_meals" to "Γεύματα",
	"expense_office_supplies" to "Προμήθειες γραφείου",
	"expense_rent" to "Ενοίκιο",
	"expense_salaries" to "Μισθοί",
	"expense_shopping" to "Αγορές",
	"expense_software" to "Λογισμικό",
	"expense_status_available" to "Διαθέσιμο",
	"expense_status_processing" to "Επεξεργασία",
	"expense_tax" to "Φόρος",
	"expense_training" to "Εκπαίδευση",
	"expense_transport" to "Μεταφορές",
	"expense_travel" to "Ταξίδι",
	"expense_utility" to "Χρησιμότητα",
	"expenses" to "Δαπάνες",
	"feedback_option" to "Ανατροφοδότηση",
	"filter" to "Φίλτρο",
	"first_name_hint" to "Όνομα",
	"forgot_password" to "Επαναφορά κωδικού πρόσβασης",
	"forgot_password_label" to "Ξεχάσατε τον κωδικό πρόσβασης",
	"from" to "Από το",
	"general_error" to "Παρουσιάστηκε σφάλμα, παρακαλώ δοκιμάστε ξανά αργότερα.",
	"general_ledger" to "γενικό καθολικό",
	"go_back_label" to "Πηγαίνετε πίσω",
	"group_by" to "Ομάδα από:",
	"image_view" to "Εικόνα",
	"install" to "Εγκατάσταση",
	"invalid_credentials_error" to "Μη έγκυρα διαπιστευτήρια.",
	"invalid_email_error" to "Μη έγκυρο email.",
	"invalid_user_error" to "Μη έγκυρα διαπιστευτήρια χρήστη.",
	"invoice_deleted_message" to "Διαγραφή τιμολογίου",
	"invoice_menu" to "Μενού τιμολογίου",
	"invoice_number" to "Τιμολόγιο #",
	"invoices" to "Τιμολόγια",
	"items_option" to "Στοιχεία",
	"keep" to "Κρατήστε το",
	"language_selector" to Formattable { (selected) -> "Γλώσσα ${selected}" },
	"last_name_hint" to "Επώνυμο",
	"ledger" to "καθολικό",
	"legal" to "Νομικό",
	"legal_error" to "Πρέπει να αποδέχεστε τους Όρους Υπηρεσίας και την Πολιτική Απορρήτου",
	"liabilities" to "υποχρεώσεις",
	"loading" to "Φόρτωση",
	"login_required_error" to "Απαιτείται σύνδεση.",
	"logo_view" to "Λογότυπο",
	"logout" to "Αποσύνδεση",
	"logout_prompt" to "Τα δεδομένα σας δεν συγχρονίζονται με το σύννεφο. Διαγραφή τιμολογίων, εκτιμήσεων, πελατών και άλλων πληροφοριών κατά την αποσύνδεση;",
	"main_screen" to "Κύρια οθόνη",
	"mark_paid" to "Χαρακτηρίστε ως πληρωμένο",
	"message" to "Μήνυμα",
	"missing_sender_msg" to "Ένα έγγραφο χωρίς πληροφορίες αποστολέα ενδέχεται να μην είναι έγκυρο.",
	"missing_sender_title" to "Λείπουν πληροφορίες αποστολέα",
	"month_april" to "Απρίλιος",
	"month_april_short" to "Απρίλιος",
	"month_august" to "Αύγουστος",
	"month_august_short" to "Αυγ.",
	"month_december" to "Δεκέμβριος",
	"month_december_short" to "Δεκ.",
	"month_february" to "Φεβρουάριος",
	"month_february_short" to "Φεβ.",
	"month_january" to "Ιανουάριος",
	"month_january_short" to "Ιαν.",
	"month_july" to "Ιούλιος",
	"month_july_short" to "Ιούλιος",
	"month_june" to "Ιούνιος",
	"month_june_short" to "Ιούνιος",
	"month_march" to "Μάρτιος",
	"month_march_short" to "Μάρτιος",
	"month_may" to "Μάιος",
	"month_may_short" to "Μάιος",
	"month_november" to "Νοέμβριος",
	"month_november_short" to "Νοε.",
	"month_october" to "Οκτώβριος",
	"month_october_short" to "Οκτ.",
	"month_september" to "Σεπτέμβριος",
	"month_september_short" to "Σεπτ.",
	"multifactor_error" to "Απέτυχε η επαλήθευση.",
	"new" to "Νέα",
	"news" to "Νέα",
	"next" to "Επόμενο",
	"no" to "όχι",
	"no_data_available" to "Δεν υπάρχουν διαθέσιμα δεδομένα",
	"no_search_results" to "Λυπούμαστε, δεν μπορέσαμε να βρούμε αποτελέσματα.",
	"none" to "Κανένα",
	"note_fields" to "Σημειώσεις",
	"note_hint" to "Σημείωση",
	"notifications_option" to "Ειδοποιήσεις",
	"ok" to "Εντάξει",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Τα βασικά μέρη του DingVoice είναι για πάντα δωρεάν. Η αποστολή μας είναι να σας βοηθήσουμε να αναπτύξετε την επιχείρησή σας, βοηθήστε μας να βελτιωθούμε μοιραζόμενοι τα σχόλιά σας.",
	"onboarding_aboutus_title" to "Είμαστε εδώ για να βοηθήσουμε",
	"onboarding_paid_1" to "Πρόσβαση σε επαγγελματικά πρότυπα",
	"onboarding_paid_2" to "Ρίξτε μια βαθύτερη ματιά στα οικονομικά σας",
	"onboarding_paid_3" to "Επιλύστε τα προβλήματα γρήγορα",
	"onboarding_paid_4" to "Διατηρήστε τα έγγραφά σας ασφαλή",
	"onboarding_paid_5" to "Μείνετε ενήμεροι για τις αλλαγές στην κατάσταση των τιμολογίων",
	"onboarding_paid_title" to "Αναπτυχθείτε ακόμα πιο γρήγορα με...",
	"onboarding_paywall_message" to "Απολαύστε όλα τα premium χαρακτηριστικά τώρα στην καλύτερη τιμή.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Μόνο το ${currencySymbol}${introAmount} για το ${introDuration} ${introPeriod} και στη συνέχεια ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Μόλις ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Δωρεάν για ${trialDuration} ${trialPeriod} και στη συνέχεια μόνο ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Δωρεάν για ${trialDuration} ${trialPeriod} μετά ${currencySymbol}${introductoryAmount} για ${introductoryDuration} ${introPeriod} και μετά μόνο ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Αυτοανανεώσιμη. Ακύρωση ανά πάσα στιγμή.",
	"onboarding_paywall_restore" to "Επαναφορά αγοράς",
	"onboarding_paywall_restore_failure" to "Η επαναφορά των αγορών απέτυχε, προσπαθήστε ξανά αργότερα ή επικοινωνήστε με την υποστήριξη.",
	"onboarding_paywall_restore_success" to "Επαναφορά επιτυχής!",
	"onboarding_paywall_title" to "Πετύχετε το μεγαλείο!",
	"onboarding_welcome_message" to "Ένα πλήρες σύνολο εργαλείων για τη διαχείριση των τιμολογίων και των εκτιμήσεών σας.",
	"onboarding_welcome_title" to "Καλώς ήρθατε στη DingVoice",
	"other_state" to "Άλλο",
	"overdue_state" to "Καθυστερημένο",
	"paid_state" to "Πληρωμένο",
	"password" to "Κωδικός πρόσβασης",
	"password_blank_error" to "Απαιτείται κωδικός πρόσβασης.",
	"password_mismatch_error" to "Οι κωδικοί πρόσβασης δεν ταιριάζουν.",
	"pay" to "πληρωμή",
	"payment_instructions_label" to "Οδηγίες πληρωμής",
	"payment_reminders" to "Υπενθυμίσεις πληρωμής",
	"phone_number_fields" to "Αριθμός τηλεφώνου",
	"phone_number_hint" to "Αριθμός τηλεφώνου",
	"postcode_hint" to "Ταχυδρομικός κώδικας",
	"preview" to "Προεπισκόπηση",
	"preview_menu_hint" to "Μενού προεπισκόπησης",
	"price" to "Τιμή",
	"privacy_policy" to "Πολιτική απορρήτου",
	"profile" to "Προφίλ",
	"profitable_client" to "Ο πιο κερδοφόρος πελάτης σας είναι...",
	"profits_report" to "Κέρδη",
	"promo_content" to "Περιεχόμενο προώθησης",
	"quantity_hint" to "Ποσότητα",
	"quantity_label_hint" to "Ετικέτα ποσότητας",
	"query_info" to "Το Query σας επιτρέπει να γράφετε προσαρμοσμένους υπολογισμούς που αναφέρονται τόσο σε στοιχεία όσο και σε άλλους φορολογικούς κανόνες ως δεδομένα εισόδου. Προσθέστε το σύμβολο @ και την περιγραφή του στοιχείου ή του φόρου για να συνδεθείτε με μια άλλη καταχώρηση +, -, *, /, % - μαθηματικοί τελεστές",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} ελήφθη από τον πελάτη σας." },
	"received_state" to "Ελήφθη",
	"registration_code_fields" to "Κωδικός Εγγραφής",
	"reminder" to "Υπενθύμιση",
	"reports" to "Αναφορές",
	"reports_not_available" to "Δεν υπάρχουν διαθέσιμες αναφορές, επιστρέψτε αργότερα.",
	"reveal_password" to "Αποκάλυψη κωδικού πρόσβασης",
	"revenue" to "έσοδα",
	"sales_tax" to "ΦΠΑ",
	"sales_tax_number" to "Αριθμός φορολογικού μητρώου (Α.Φ.Μ.)",
	"save" to "Αποθήκευση",
	"scan_document" to "Σάρωση εγγράφου",
	"search" to "Αναζήτηση",
	"select" to "Επιλέξτε",
	"select_icon" to "Επιλογή εικονιδίου",
	"select_signature" to "Επιλέξτε υπογραφή",
	"select_template" to "Επιλογή προτύπου",
	"send" to "Στείλτε",
	"sent_state" to "Αποστολή",
	"setup_sender" to "Ρύθμιση αποστολέα",
	"share_option" to "Μοιραστείτε το",
	"ship_to" to "Αποστολή σε",
	"sign_in" to "Σύνδεση",
	"sign_in_apple" to "Συνδεθείτε με την Apple",
	"sign_in_email" to "Συνδεθείτε με email",
	"sign_in_facebook" to "Συνδεθείτε με το Facebook",
	"sign_in_google" to "Συνδεθείτε με το Google",
	"sign_up" to "Εγγραφείτε",
	"sign_up_label" to "Δεν έχετε λογαριασμό;",
	"sign_up_link_label" to "Εγγραφείτε!",
	"signatures_option" to "Υπογραφές",
	"skip" to "Παράλειψη",
	"sold_items_report" to "Πωληθέντα αντικείμενα",
	"sort_by" to "Ταξινόμηση κατά:",
	"status_update" to "Ενημέρωση της κατάστασης του εγγράφου",
	"sub_annual" to "Ετήσια",
	"sub_annual_price" to Formattable { (price) -> "${price}/έτος" },
	"sub_backups" to "Δημιουργία αντιγράφων ασφαλείας Cloud",
	"sub_insights" to "Διαπιστώσεις",
	"sub_month" to "Μηνιαία",
	"sub_monthly_price" to Formattable { (price) -> "${price}/μήνα" },
	"sub_notifications" to "Ειδοποιήσεις",
	"sub_popup_message" to "Οι συνδρομές έρχονται σύντομα. Επιστρέψτε εδώ σε λίγο καιρό.",
	"sub_support" to "Υποστήριξη",
	"sub_templates" to "Πρότυπα",
	"subscribe" to "Εγγραφείτε στο",
	"subscription_header" to "Αναπτύξτε την επιχείρησή σας με:",
	"subscription_option" to "Συνδρομή",
	"subscriptions_load_fail" to "Λυπούμαστε, δεν μπορούμε να ανακτήσουμε τις συνδρομές σας αυτή τη στιγμή. Παρακαλούμε προσπαθήστε ξανά αργότερα ή επικοινωνήστε με την ομάδα υποστήριξής μας για βοήθεια.",
	"subtotal" to Formattable { (sum) -> "Υποσύνολο: ${sum}" },
	"subtotal_label" to "Υποσύνολο",
	"sum" to "Άθροισμα",
	"support_option" to "Υποστήριξη",
	"switch_reports" to "Αναφορά εναλλαγής",
	"tax" to "Φόρος",
	"tax_deleted_message" to "Φόρος διαγράφεται",
	"tax_description_warning" to "Εάν χρησιμοποιηθεί, αυτό εμφανίζεται σε ένα τιμολόγιο",
	"tax_input_flat" to "Επίπεδη",
	"tax_input_percent" to "Ποσοστό",
	"tax_number_fields" to "Αριθμός φορολογικού μητρώου",
	"tax_number_hint" to "Αριθμός φορολογικού μητρώου",
	"tax_query_info_description" to "Πληροφορίες ερωτήματος",
	"tax_report" to "Φόρος",
	"tax_rules_option" to "Φορολογικοί κανόνες",
	"taxed_clients_report" to "Φορολογημένοι πελάτες",
	"template" to "Πρότυπο",
	"template_selector_info" to "Σύρετε για να αλλάξετε το προεπιλεγμένο πρότυπο",
	"template_unlock_promo" to "Πρόσβαση σε περισσότερα πρότυπα με το Premium",
	"templates_option" to "Πρότυπα",
	"terms_of_service" to "Όροι χρήσης",
	"thank_you" to "Σας ευχαριστώ",
	"to" to "Προς",
	"total" to Formattable { (sum) -> "Σύνολο: ${sum}" },
	"total_label" to "Σύνολο",
	"undo_action" to "Αναίρεση",
	"unit" to "Μονάδα",
	"unit_cost_hint" to "Κόστος μονάδας",
	"unknown_client" to "Άγνωστος πελάτης",
	"unlock" to "Ξεκλειδώστε το",
	"unnamed_item" to "Ανώνυμο στοιχείο",
	"upgrade" to "Αναβάθμιση",
	"user_collision_error" to "Ο λογαριασμός υπάρχει ήδη.",
	"valid_until" to "Ισχύει μέχρι",
	"warning" to "Προειδοποίηση!",
	"yes" to "ναι",
    )
    
}