// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Mk  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Прифатете ${termsOfService} и ${privacyPolicy}" }
override val account: String = "сметка"
override val accounting: String = "сметководство"
override val accruals: String = "акумулации"
override val action_code_error: String = "Погрешен код."
override val add_client_action: String = "Додадете клиент"
override val add_customer: String = "Додадете клиент"
override val add_expense: String = "Додадете трошок"
override val add_fields_label: String = "Додај:"
override val add_invoice_action: String = "Креирај"
override val add_item_action: String = "Додадете ставка"
override val add_item_button: String = "Додадете ставка"
override val add_signature_action: String = "Додадете потпис"
override val add_tax_action: String = "Додадете данок"
override val address_fields: String = "Адреса"
override val address_line_hint: String = "Адресна линија"
override val advanced: String = "Напредно"
override val agree_marketing: String = "Се согласувам да добивам директни маркетинг комуникации"
override val all_notifications: String = "Сите известувања"
override val amortization: String = "амортизација"
override val amount: String = "износ"
override val asset: String = "средство"
override val attach_file: String = "Прикачи датотека"
override val attachment: String = "Прилог"
override val auth_error: String = "Дејството не успеа, обидете се повторно."
override val available_for_paid: String = "Синхронизацијата во облак е достапна само со платена претплата."
override val backups_option: String = "Бекап"
override val balance: String = "салдо"
override val bank_account: String = "Банкарска Cметка"
override val bill_number: String = "Број на сметка"
override val cancel: String = "Откажи"
override val cancel_trial: String = "Откажете во секое време"
override val capital: String = "капитал"
override val cash: String = "готовина"
override val categories: String = "категории"
override val category: String = "Категорија"
override val category_add: String = "Додадете категорија"
override val category_delete: String = "Избриши категорија"
override val category_name: String = "Име на категоријата"
override val change_language: Formattable = Formattable { (language) -> "Јазик на фактура: ${language}" }
override val change_language_label: String = "Јазик на фактура"
override val change_template: String = "Променете го шаблонот"
override val city_hint: String = "Градот"
override val clear: String = "Јасно"
override val client: String = "Клиент"
override val client_actions: String = "Дејства на клиентот"
override val client_deleted_message: String = "Клиентот е избришан"
override val clients_error_required_fields: String = "Потребна е најмалку една вредност."
override val clients_option: String = "Клиенти"
override val close: String = "Затвори"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "Наскоро!"
override val company_logo: String = "Лого на компанијата"
override val company_name_hint: String = "Компанијата"
override val confirm_password_label: String = "Потврди ја лозинката"
override val confirmation_title: String = "Дали сте сигурни?"
override val convert_to_invoice: String = "Конвертирај во фактура"
override val country_hint: String = "Земја"
override val create_client: String = "Креирај клиент"
override val create_company: String = "Креирај компанија"
override val create_document: String = "Креирај документ"
override val create_estimate: String = "Процени"
override val create_invoice: String = "Фактура"
override val create_item: String = "Креирај ставка"
override val create_new_client: String = "Создадете нов клиент"
override val create_new_item: String = "Создадете нова ставка"
override val create_new_tax: String = "Создадете нов данок"
override val create_signature: String = "Потпис"
override val created: String = "Создаден"
override val credit: String = "кредит"
override val customer: String = "Клиент"
override val date: String = "Датум"
override val debit: String = "дебитна"
override val debt: String = "долг"
override val default_currency: Formattable = Formattable { (currency) -> "Стандардна валута: ${currency}" }
override val default_currency_label: String = "Стандардна валута"
override val delete: String = "избриши"
override val delete_account: String = "Избриши Cметка"
override val delete_confirmation_message: String = "Сите ваши податоци ќе бидат изгубени."
override val delete_item_title: String = "Да се избрише ставката?"
override val delete_signature: String = "Избриши потпис"
override val depreciation: String = "амортизација"
override val description: String = "Опис"
override val description_hint: String = "Опис"
override val detailed_report: String = "Детален извештај"
override val disabled: String = "Оневозможено"
override val discount: String = "Попуст"
override val discount_hint: String = "Попуст"
override val display_af: String = "Африканс"
override val display_am: String = "Амхарски"
override val display_ar: String = "Арапски"
override val display_as: String = "Асамски"
override val display_az: String = "Азербејџански"
override val display_be: String = "Белоруски"
override val display_bg: String = "Бугарски"
override val display_bn: String = "Бенгалски"
override val display_bs: String = "Босански"
override val display_ca: String = "Каталонски"
override val display_cs: String = "Чешки"
override val display_da: String = "Дански"
override val display_de: String = "Германски"
override val display_el: String = "Грчки"
override val display_en: String = "Англиски"
override val display_es: String = "Шпански"
override val display_es_MX: String = "Шпански (Мексико)"
override val display_et: String = "Естонски"
override val display_eu: String = "Баскија"
override val display_fa: String = "Персиски"
override val display_fi: String = "Фински"
override val display_fil: String = "Филипински"
override val display_fr: String = "Француски"
override val display_fr_CA: String = "Канадски француски"
override val display_gl: String = "Галициски"
override val display_gu: String = "Гуџарати"
override val display_he: String = "Еврејски"
override val display_hi: String = "Хинди"
override val display_hr: String = "Хрватски"
override val display_hu: String = "Унгарски"
override val display_hy: String = "Ерменски"
override val display_id: String = "Индонезиски"
override val display_is: String = "Исландски"
override val display_it: String = "Италијански"
override val display_ja: String = "Јапонски"
override val display_ka: String = "Грузиски"
override val display_kk: String = "Казахстански"
override val display_km: String = "Централни Кмери"
override val display_kn: String = "Канада"
override val display_ko: String = "Корејски"
override val display_ky: String = "Kиргистански"
override val display_lo: String = "Пороѓај"
override val display_lt: String = "Литвански"
override val display_lv: String = "Летонски"
override val display_mk: String = "Македонски"
override val display_ml: String = "Малајалам"
override val display_mn: String = "Монголски"
override val display_mr: String = "Марати"
override val display_ms: String = "Малајски"
override val display_my: String = "Бурмански"
override val display_nb: String = "Норвешки"
override val display_ne: String = "Непалски"
override val display_nl: String = "Холандски"
override val display_or: String = "Орија"
override val display_pa: String = "Пенџапски"
override val display_pl: String = "Полски"
override val display_pt: String = "Португалски"
override val display_pt_BR: String = "Португалски (Бразил)"
override val display_pt_PT: String = "Eвропски португалски"
override val display_ro: String = "Романски"
override val display_ru: String = "Руски"
override val display_si: String = "Синхала"
override val display_sk: String = "Словачки"
override val display_sl: String = "Словенечки"
override val display_sq: String = "Албански"
override val display_sr: String = "Српски"
override val display_sv: String = "Шведски"
override val display_sw: String = "Свахили"
override val display_ta: String = "тамилски"
override val display_te: String = "Телугу"
override val display_th: String = "Тајландски"
override val display_tr: String = "Турски"
override val display_uk: String = "Украински"
override val display_ur: String = "Урду"
override val display_uz: String = "Узбечки"
override val display_vi: String = "Виетнамски"
override val display_zh_Hant_TW: String = "Kинески (Tајван, Tрадиционален)"
override val display_zu: String = "Зулу"
override val document_estimate: String = "Процени"
override val document_invoice: String = "Фактура"
override val document_quote: String = "Цитат"
override val done: String = "Направено"
override val download_app: String = "Преземете ја апликацијата"
override val download_invoice: String = "Преземете Фактура"
override val download_pdf: String = "Преземете PDF"
override val draw_signature: String = "Нацртајте потпис"
override val due: Formattable = Formattable { (date) -> "Краен рок ${date}" }
override val due_date: String = "Термин на доспевање"
override val due_day: String = "за еден ден"
override val due_label: String = "Се должи"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} доспева ${dueWhen}!" }
override val due_today: String = "денес"
override val due_week: String = "во рок од една недела"
override val duplicate: String = "Дупликат"
override val duration_day: String = "ден"
override val duration_month: String = "Месец"
override val duration_week: String = "седмица"
override val duration_year: String = "година"
override val email: String = "Е -пошта"
override val email_blank_error: String = "Потребна е е-пошта."
override val email_error: String = "Неважечка адреса на е-пошта."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Ви благодариме за вашиот бизнис. За да го видите целосниот ${documentType} кликнете на копчето на крајот од ова писмо. Имајте предвид дека важи до ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Добивте нов документ: ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ПРИКАЖИ ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Почитуван ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Ви благодариме за вашиот бизнис. За да го видите целосниот ${documentType} кликнете на копчето на крајот од ова писмо. Платете го салдото до ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Добивте нов документ: ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Испратена е-пошта за ресетирање на лозинката, проверете го вашето сандаче."
override val empty_clients_list_message: String = "Немате клиенти за следење. Додадете нов клиент."
override val empty_estimate_list_message: String = "Направете ја вашата прва проценка"
override val empty_expenses: String = "Немате трошоци за следење. Додадете нов трошок."
override val empty_invoice_list_message: String = "Направете ја вашата прва фактура"
override val empty_items_list_message: String = "Немате ставки, додајте нова ставка!"
override val empty_signatures_list_message: String = "Не се пронајдени потписи. Направете еден!"
override val empty_taxes_list_message: String = "Немате даночни правила. Додадете ново даночно правило."
override val empty_templates_list_message: String = "Шаблоните се недостапни."
override val enabled: String = "Овозможено"
override val entry: String = "влез"
override val equity: String = "капитал"
override val estimate_number: String = "Проценка #"
override val estimates: String = "Проценки"
override val estimates_report: String = "Проценки"
override val expense_bank_fees: String = "Банкарски такси"
override val expense_benefits: String = "Придобивки"
override val expense_communication: String = "Комуникација"
override val expense_debt: String = "Долг"
override val expense_deleted: String = "Трошокот е избришан"
override val expense_depreciation: String = "Амортизација"
override val expense_gifts: String = "Подароци"
override val expense_insurance: String = "Осигурување"
override val expense_inventory: String = "Инвентар"
override val expense_legal: String = "Правни"
override val expense_maintenance: String = "Одржување"
override val expense_marketing: String = "Маркетинг"
override val expense_meals: String = "Оброци"
override val expense_office_supplies: String = "Канцелариски материјали"
override val expense_rent: String = "Изнајмување"
override val expense_salaries: String = "Плати"
override val expense_shopping: String = "Купување"
override val expense_software: String = "Софтвер"
override val expense_status_available: String = "Достапно"
override val expense_status_processing: String = "Обработка"
override val expense_tax: String = "Данок"
override val expense_training: String = "Обука"
override val expense_transport: String = "Транспорт"
override val expense_travel: String = "Патува"
override val expense_utility: String = "Алатка"
override val expenses: String = "Трошоци"
override val feedback_option: String = "Повратни информации"
override val filter: String = "Филтер"
override val first_name_hint: String = "Име"
override val forgot_password: String = "Ресетирање на Лозинка"
override val forgot_password_label: String = "Ја заборави лозинката"
override val from: String = "од"
override val general_error: String = "Настана грешка, обидете се повторно подоцна."
override val general_ledger: String = "главни бележник"
override val go_back_label: String = "Врати се назад"
override val group_by: String = "Група според:"
override val image_view: String = "Слика"
override val install: String = "Инсталирај"
override val invalid_credentials_error: String = "Неважечки акредитиви."
override val invalid_email_error: String = "Неточен Емаил."
override val invalid_user_error: String = "Неважечки кориснички акредитиви."
override val invoice_deleted_message: String = "Фактурата е избришана"
override val invoice_menu: String = "Мени за фактура"
override val invoice_number: String = "Фактура #"
override val invoices: String = "Фактури"
override val items_option: String = "Предмети"
override val keep: String = "Задржи"
override val language_selector: Formattable = Formattable { (selected) -> "Јазик ${selected}" }
override val last_name_hint: String = "Презиме"
override val ledger: String = "бележник"
override val legal: String = "Правни"
override val legal_error: String = "Мора да ги прифати Условите за користење и Политиката за приватност"
override val liabilities: String = "обврските"
override val loading: String = "Се вчитува"
override val login_required_error: String = "Потребно е најавување."
override val logo_view: String = "Лого"
override val logout: String = "Одјавување"
override val logout_prompt: String = "Вашите податоци не се синхронизирани со облакот. Да се избришат фактурите, проценките, клиентите и другите информации при одјавување?"
override val main_screen: String = "Главен екран"
override val mark_paid: String = "Обележете како платено"
override val message: String = "Порака"
override val missing_sender_msg: String = "Документ без информации за испраќачот може да биде неважечки."
override val missing_sender_title: String = "Недостасуваат информации за испраќачот"
override val month_april: String = "Април"
override val month_april_short: String = "Април"
override val month_august: String = "Август"
override val month_august_short: String = "Авг."
override val month_december: String = "Декември"
override val month_december_short: String = "Дек."
override val month_february: String = "Февруари"
override val month_february_short: String = "Фев."
override val month_january: String = "Јануари"
override val month_january_short: String = "Јан."
override val month_july: String = "Јули"
override val month_july_short: String = "Јули"
override val month_june: String = "Јуни"
override val month_june_short: String = "Јуни"
override val month_march: String = "Март"
override val month_march_short: String = "Март"
override val month_may: String = "Мај"
override val month_may_short: String = "Мај"
override val month_november: String = "Ноември"
override val month_november_short: String = "Ное."
override val month_october: String = "Октомври"
override val month_october_short: String = "Окт."
override val month_september: String = "Септември"
override val month_september_short: String = "Септ."
override val multifactor_error: String = "Не успеа да се потврди."
override val new: String = "Ново"
override val news: String = "Вести"
override val next: String = "Следно"
override val no: String = "не"
override val no_data_available: String = "Нема достапни податоци"
override val no_search_results: String = "За жал, не можевме да најдеме резултати."
override val none: String = "Никој"
override val note_fields: String = "Забелешки"
override val note_hint: String = "Забелешка"
override val notifications_option: String = "Известувања"
override val ok: String = "добро"
override val onboarding: String = "Вклучување"
override val onboarding_aboutus_message: String = "Основните делови на DingVoice се засекогаш бесплатни. Нашата мисија е да ви помогнеме да го развиете вашиот бизнис, да ни помогнеме да се подобриме со споделување на вашите повратни информации."
override val onboarding_aboutus_title: String = "Ние сме тука да помогнеме"
override val onboarding_paid_1: String = "Пристапете до шаблони со професионален изглед"
override val onboarding_paid_2: String = "Подлабоко погледнете ги вашите финансии"
override val onboarding_paid_3: String = "Брзо решете ги проблемите"
override val onboarding_paid_4: String = "Чувајте ги вашите документи безбедни"
override val onboarding_paid_5: String = "Бидете во тек со промените на статусот на фактурата"
override val onboarding_paid_title: String = "Расте уште побрзо со..."
override val onboarding_paywall_message: String = "Уживајте во сите премиум функции сега по најдобра цена."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Само ${currencySymbol}${introAmount} за ${introDuration} ${introPeriod} и потоа ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Само ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Бесплатно за ${trialDuration} ${trialPeriod} и потоа само ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Бесплатно за ${trialDuration} ${trialPeriod}, потоа ${currencySymbol}${introductoryAmount} за ${introductoryDuration} ${introPeriod}и потоа само ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Автоматско обновување. Откажете во секое време."
override val onboarding_paywall_restore: String = "Вратете го купувањето"
override val onboarding_paywall_restore_failure: String = "Враќањето на купувањата не успеа, обидете се повторно подоцна или контактирајте со поддршката."
override val onboarding_paywall_restore_success: String = "Враќањето е успешно!"
override val onboarding_paywall_title: String = "Постигнете величина!"
override val onboarding_welcome_message: String = "Комплетна група алатки за управување со вашите фактури и проценки."
override val onboarding_welcome_title: String = "Добредојдовте во DingVoice"
override val other_state: String = "Други"
override val overdue_state: String = "Задоцнета"
override val paid_state: String = "Платено"
override val password: String = "Лозинка"
override val password_blank_error: String = "Потребна е лозинка."
override val password_mismatch_error: String = "Лозинките не се совпаѓаат."
override val pay: String = "плати"
override val payment_instructions_label: String = "Инструкции за плаќање"
override val payment_reminders: String = "Потсетници за плаќање"
override val phone_number_fields: String = "Телефонски број"
override val phone_number_hint: String = "Телефонски број"
override val postcode_hint: String = "Поштенски код"
override val preview: String = "Преглед"
override val preview_menu_hint: String = "Мени за преглед"
override val price: String = "Цена"
override val privacy_policy: String = "Политика за приватност"
override val profile: String = "Профил"
override val profitable_client: String = "Вашиот најпрофитабилен клиент е..."
override val profits_report: String = "Профит"
override val promo_content: String = "Промотивна содржина"
override val quantity_hint: String = "Квантитет"
override val quantity_label_hint: String = "Ознака за количина"
override val query_info: String = "Барањето ви овозможува да пишувате сопствени пресметки кои се однесуваат на ставките и другите даночни правила како влезни податоци. Додајте @ симбол и ставка или опис на данок за да се поврзете со друг запис +, -, *, /, % - математички оператори"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} беше примен од вашиот клиент." }
override val received_state: String = "Примено"
override val registration_code_fields: String = "Код за регистрација"
override val reminder: String = "Потсетник"
override val reports: String = "Извештаи"
override val reports_not_available: String = "Нема достапни извештаи, вратете се подоцна."
override val reveal_password: String = "Откријте ја лозинката"
override val revenue: String = "приходите"
override val sales_tax: String = "ДДВ"
override val sales_tax_number: String = "ДДВ број"
override val save: String = "Зачувај"
override val scan_document: String = "Скенирајте документ"
override val search: String = "Пребарување"
override val select: String = "Изберете"
override val select_icon: String = "Изберете икона"
override val select_signature: String = "Изберете потпис"
override val select_template: String = "Изберете шаблон"
override val send: String = "Испрати"
override val sent_state: String = "Испратено"
override val setup_sender: String = "Поставете испраќач"
override val share_option: String = "Сподели"
override val ship_to: String = "Се праќа во"
override val sign_in: String = "Најави се"
override val sign_in_apple: String = "Пријавете се со Apple"
override val sign_in_email: String = "Пријавете се со е-пошта"
override val sign_in_facebook: String = "Пријавете се со Фејсбук"
override val sign_in_google: String = "Пријавете се со Google"
override val sign_up: String = "Пријавете се"
override val sign_up_label: String = "Немате сметка?"
override val sign_up_link_label: String = "Пријавете се!"
override val signatures_option: String = "Потписи"
override val skip: String = "Прескокни"
override val sold_items_report: String = "Продадени Предмети"
override val sort_by: String = "Сортирај по:"
override val status_update: String = "Ажурирање на статусот на документот"
override val sub_annual: String = "Годишен"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/година" }
override val sub_backups: String = "Резервни копии во облак"
override val sub_insights: String = "Увиди"
override val sub_month: String = "Месечно"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/месечно" }
override val sub_notifications: String = "Известувања"
override val sub_popup_message: String = "Претплатите доаѓаат наскоро. Проверете повторно овде за малку време."
override val sub_support: String = "Поддршка"
override val sub_templates: String = "Шаблони"
override val subscribe: String = "Претплати се"
override val subscription_header: String = "Развијте го вашиот бизнис со:"
override val subscription_option: String = "Претплата"
override val subscriptions_load_fail: String = "За жал, не можеме да ги вратиме вашите претплати во моментов. Обидете се повторно подоцна или контактирајте со нашиот тим за поддршка за помош."
override val subtotal: Formattable = Formattable { (sum) -> "Подзбир: ${sum}" }
override val subtotal_label: String = "Субтотал"
override val sum: String = "Збир"
override val support_option: String = "Поддршка"
override val switch_reports: String = "Вклучи Извештај"
override val tax: String = "Данок"
override val tax_deleted_message: String = "Данокот е избришан"
override val tax_description_warning: String = "Ако се користи, ова се појавува на фактура"
override val tax_input_flat: String = "Рамен"
override val tax_input_percent: String = "проценти"
override val tax_number_fields: String = "Даночен број"
override val tax_number_hint: String = "Даночен број"
override val tax_query_info_description: String = "Информации за барање"
override val tax_report: String = "Данок"
override val tax_rules_option: String = "Даночни правила"
override val taxed_clients_report: String = "Оданочени клиенти"
override val template: String = "Шаблон"
override val template_selector_info: String = "Повлечете за да го промените стандардниот шаблон"
override val template_unlock_promo: String = "Пристапете до повеќе шаблони со Premium"
override val templates_option: String = "Шаблони"
override val terms_of_service: String = "Услови за користење"
override val thank_you: String = "Благодариме"
override val to: String = "До"
override val total: Formattable = Formattable { (sum) -> "Вкупно: ${sum}" }
override val total_label: String = "Вкупно"
override val undo_action: String = "Врати"
override val unit: String = "Единица"
override val unit_cost_hint: String = "Единечна цена"
override val unknown_client: String = "Непознат Kлиент"
override val unlock: String = "Отклучи"
override val unnamed_item: String = "Неименувана ставка"
override val upgrade: String = "Надградба"
override val user_collision_error: String = "Сметката веќе постои."
override val valid_until: String = "Употребливо до"
override val warning: String = "Предупредување!"
override val yes: String = "да"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Прифатете ${termsOfService} и ${privacyPolicy}" },
	"account" to "сметка",
	"accounting" to "сметководство",
	"accruals" to "акумулации",
	"action_code_error" to "Погрешен код.",
	"add_client_action" to "Додадете клиент",
	"add_customer" to "Додадете клиент",
	"add_expense" to "Додадете трошок",
	"add_fields_label" to "Додај:",
	"add_invoice_action" to "Креирај",
	"add_item_action" to "Додадете ставка",
	"add_item_button" to "Додадете ставка",
	"add_signature_action" to "Додадете потпис",
	"add_tax_action" to "Додадете данок",
	"address_fields" to "Адреса",
	"address_line_hint" to "Адресна линија",
	"advanced" to "Напредно",
	"agree_marketing" to "Се согласувам да добивам директни маркетинг комуникации",
	"all_notifications" to "Сите известувања",
	"amortization" to "амортизација",
	"amount" to "износ",
	"asset" to "средство",
	"attach_file" to "Прикачи датотека",
	"attachment" to "Прилог",
	"auth_error" to "Дејството не успеа, обидете се повторно.",
	"available_for_paid" to "Синхронизацијата во облак е достапна само со платена претплата.",
	"backups_option" to "Бекап",
	"balance" to "салдо",
	"bank_account" to "Банкарска Cметка",
	"bill_number" to "Број на сметка",
	"cancel" to "Откажи",
	"cancel_trial" to "Откажете во секое време",
	"capital" to "капитал",
	"cash" to "готовина",
	"categories" to "категории",
	"category" to "Категорија",
	"category_add" to "Додадете категорија",
	"category_delete" to "Избриши категорија",
	"category_name" to "Име на категоријата",
	"change_language" to Formattable { (language) -> "Јазик на фактура: ${language}" },
	"change_language_label" to "Јазик на фактура",
	"change_template" to "Променете го шаблонот",
	"city_hint" to "Градот",
	"clear" to "Јасно",
	"client" to "Клиент",
	"client_actions" to "Дејства на клиентот",
	"client_deleted_message" to "Клиентот е избришан",
	"clients_error_required_fields" to "Потребна е најмалку една вредност.",
	"clients_option" to "Клиенти",
	"close" to "Затвори",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "Наскоро!",
	"company_logo" to "Лого на компанијата",
	"company_name_hint" to "Компанијата",
	"confirm_password_label" to "Потврди ја лозинката",
	"confirmation_title" to "Дали сте сигурни?",
	"convert_to_invoice" to "Конвертирај во фактура",
	"country_hint" to "Земја",
	"create_client" to "Креирај клиент",
	"create_company" to "Креирај компанија",
	"create_document" to "Креирај документ",
	"create_estimate" to "Процени",
	"create_invoice" to "Фактура",
	"create_item" to "Креирај ставка",
	"create_new_client" to "Создадете нов клиент",
	"create_new_item" to "Создадете нова ставка",
	"create_new_tax" to "Создадете нов данок",
	"create_signature" to "Потпис",
	"created" to "Создаден",
	"credit" to "кредит",
	"customer" to "Клиент",
	"date" to "Датум",
	"debit" to "дебитна",
	"debt" to "долг",
	"default_currency" to Formattable { (currency) -> "Стандардна валута: ${currency}" },
	"default_currency_label" to "Стандардна валута",
	"delete" to "избриши",
	"delete_account" to "Избриши Cметка",
	"delete_confirmation_message" to "Сите ваши податоци ќе бидат изгубени.",
	"delete_item_title" to "Да се избрише ставката?",
	"delete_signature" to "Избриши потпис",
	"depreciation" to "амортизација",
	"description" to "Опис",
	"description_hint" to "Опис",
	"detailed_report" to "Детален извештај",
	"disabled" to "Оневозможено",
	"discount" to "Попуст",
	"discount_hint" to "Попуст",
	"display_af" to "Африканс",
	"display_am" to "Амхарски",
	"display_ar" to "Арапски",
	"display_as" to "Асамски",
	"display_az" to "Азербејџански",
	"display_be" to "Белоруски",
	"display_bg" to "Бугарски",
	"display_bn" to "Бенгалски",
	"display_bs" to "Босански",
	"display_ca" to "Каталонски",
	"display_cs" to "Чешки",
	"display_da" to "Дански",
	"display_de" to "Германски",
	"display_el" to "Грчки",
	"display_en" to "Англиски",
	"display_es" to "Шпански",
	"display_es_MX" to "Шпански (Мексико)",
	"display_et" to "Естонски",
	"display_eu" to "Баскија",
	"display_fa" to "Персиски",
	"display_fi" to "Фински",
	"display_fil" to "Филипински",
	"display_fr" to "Француски",
	"display_fr_CA" to "Канадски француски",
	"display_gl" to "Галициски",
	"display_gu" to "Гуџарати",
	"display_he" to "Еврејски",
	"display_hi" to "Хинди",
	"display_hr" to "Хрватски",
	"display_hu" to "Унгарски",
	"display_hy" to "Ерменски",
	"display_id" to "Индонезиски",
	"display_is" to "Исландски",
	"display_it" to "Италијански",
	"display_ja" to "Јапонски",
	"display_ka" to "Грузиски",
	"display_kk" to "Казахстански",
	"display_km" to "Централни Кмери",
	"display_kn" to "Канада",
	"display_ko" to "Корејски",
	"display_ky" to "Kиргистански",
	"display_lo" to "Пороѓај",
	"display_lt" to "Литвански",
	"display_lv" to "Летонски",
	"display_mk" to "Македонски",
	"display_ml" to "Малајалам",
	"display_mn" to "Монголски",
	"display_mr" to "Марати",
	"display_ms" to "Малајски",
	"display_my" to "Бурмански",
	"display_nb" to "Норвешки",
	"display_ne" to "Непалски",
	"display_nl" to "Холандски",
	"display_or" to "Орија",
	"display_pa" to "Пенџапски",
	"display_pl" to "Полски",
	"display_pt" to "Португалски",
	"display_pt_BR" to "Португалски (Бразил)",
	"display_pt_PT" to "Eвропски португалски",
	"display_ro" to "Романски",
	"display_ru" to "Руски",
	"display_si" to "Синхала",
	"display_sk" to "Словачки",
	"display_sl" to "Словенечки",
	"display_sq" to "Албански",
	"display_sr" to "Српски",
	"display_sv" to "Шведски",
	"display_sw" to "Свахили",
	"display_ta" to "тамилски",
	"display_te" to "Телугу",
	"display_th" to "Тајландски",
	"display_tr" to "Турски",
	"display_uk" to "Украински",
	"display_ur" to "Урду",
	"display_uz" to "Узбечки",
	"display_vi" to "Виетнамски",
	"display_zh_Hant_TW" to "Kинески (Tајван, Tрадиционален)",
	"display_zu" to "Зулу",
	"document_estimate" to "Процени",
	"document_invoice" to "Фактура",
	"document_quote" to "Цитат",
	"done" to "Направено",
	"download_app" to "Преземете ја апликацијата",
	"download_invoice" to "Преземете Фактура",
	"download_pdf" to "Преземете PDF",
	"draw_signature" to "Нацртајте потпис",
	"due" to Formattable { (date) -> "Краен рок ${date}" },
	"due_date" to "Термин на доспевање",
	"due_day" to "за еден ден",
	"due_label" to "Се должи",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} доспева ${dueWhen}!" },
	"due_today" to "денес",
	"due_week" to "во рок од една недела",
	"duplicate" to "Дупликат",
	"duration_day" to "ден",
	"duration_month" to "Месец",
	"duration_week" to "седмица",
	"duration_year" to "година",
	"email" to "Е -пошта",
	"email_blank_error" to "Потребна е е-пошта.",
	"email_error" to "Неважечка адреса на е-пошта.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Ви благодариме за вашиот бизнис. За да го видите целосниот ${documentType} кликнете на копчето на крајот од ова писмо. Имајте предвид дека важи до ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Добивте нов документ: ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "ПРИКАЖИ ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Почитуван ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Ви благодариме за вашиот бизнис. За да го видите целосниот ${documentType} кликнете на копчето на крајот од ова писмо. Платете го салдото до ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Добивте нов документ: ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Испратена е-пошта за ресетирање на лозинката, проверете го вашето сандаче.",
	"empty_clients_list_message" to "Немате клиенти за следење. Додадете нов клиент.",
	"empty_estimate_list_message" to "Направете ја вашата прва проценка",
	"empty_expenses" to "Немате трошоци за следење. Додадете нов трошок.",
	"empty_invoice_list_message" to "Направете ја вашата прва фактура",
	"empty_items_list_message" to "Немате ставки, додајте нова ставка!",
	"empty_signatures_list_message" to "Не се пронајдени потписи. Направете еден!",
	"empty_taxes_list_message" to "Немате даночни правила. Додадете ново даночно правило.",
	"empty_templates_list_message" to "Шаблоните се недостапни.",
	"enabled" to "Овозможено",
	"entry" to "влез",
	"equity" to "капитал",
	"estimate_number" to "Проценка #",
	"estimates" to "Проценки",
	"estimates_report" to "Проценки",
	"expense_bank_fees" to "Банкарски такси",
	"expense_benefits" to "Придобивки",
	"expense_communication" to "Комуникација",
	"expense_debt" to "Долг",
	"expense_deleted" to "Трошокот е избришан",
	"expense_depreciation" to "Амортизација",
	"expense_gifts" to "Подароци",
	"expense_insurance" to "Осигурување",
	"expense_inventory" to "Инвентар",
	"expense_legal" to "Правни",
	"expense_maintenance" to "Одржување",
	"expense_marketing" to "Маркетинг",
	"expense_meals" to "Оброци",
	"expense_office_supplies" to "Канцелариски материјали",
	"expense_rent" to "Изнајмување",
	"expense_salaries" to "Плати",
	"expense_shopping" to "Купување",
	"expense_software" to "Софтвер",
	"expense_status_available" to "Достапно",
	"expense_status_processing" to "Обработка",
	"expense_tax" to "Данок",
	"expense_training" to "Обука",
	"expense_transport" to "Транспорт",
	"expense_travel" to "Патува",
	"expense_utility" to "Алатка",
	"expenses" to "Трошоци",
	"feedback_option" to "Повратни информации",
	"filter" to "Филтер",
	"first_name_hint" to "Име",
	"forgot_password" to "Ресетирање на Лозинка",
	"forgot_password_label" to "Ја заборави лозинката",
	"from" to "од",
	"general_error" to "Настана грешка, обидете се повторно подоцна.",
	"general_ledger" to "главни бележник",
	"go_back_label" to "Врати се назад",
	"group_by" to "Група според:",
	"image_view" to "Слика",
	"install" to "Инсталирај",
	"invalid_credentials_error" to "Неважечки акредитиви.",
	"invalid_email_error" to "Неточен Емаил.",
	"invalid_user_error" to "Неважечки кориснички акредитиви.",
	"invoice_deleted_message" to "Фактурата е избришана",
	"invoice_menu" to "Мени за фактура",
	"invoice_number" to "Фактура #",
	"invoices" to "Фактури",
	"items_option" to "Предмети",
	"keep" to "Задржи",
	"language_selector" to Formattable { (selected) -> "Јазик ${selected}" },
	"last_name_hint" to "Презиме",
	"ledger" to "бележник",
	"legal" to "Правни",
	"legal_error" to "Мора да ги прифати Условите за користење и Политиката за приватност",
	"liabilities" to "обврските",
	"loading" to "Се вчитува",
	"login_required_error" to "Потребно е најавување.",
	"logo_view" to "Лого",
	"logout" to "Одјавување",
	"logout_prompt" to "Вашите податоци не се синхронизирани со облакот. Да се избришат фактурите, проценките, клиентите и другите информации при одјавување?",
	"main_screen" to "Главен екран",
	"mark_paid" to "Обележете како платено",
	"message" to "Порака",
	"missing_sender_msg" to "Документ без информации за испраќачот може да биде неважечки.",
	"missing_sender_title" to "Недостасуваат информации за испраќачот",
	"month_april" to "Април",
	"month_april_short" to "Април",
	"month_august" to "Август",
	"month_august_short" to "Авг.",
	"month_december" to "Декември",
	"month_december_short" to "Дек.",
	"month_february" to "Февруари",
	"month_february_short" to "Фев.",
	"month_january" to "Јануари",
	"month_january_short" to "Јан.",
	"month_july" to "Јули",
	"month_july_short" to "Јули",
	"month_june" to "Јуни",
	"month_june_short" to "Јуни",
	"month_march" to "Март",
	"month_march_short" to "Март",
	"month_may" to "Мај",
	"month_may_short" to "Мај",
	"month_november" to "Ноември",
	"month_november_short" to "Ное.",
	"month_october" to "Октомври",
	"month_october_short" to "Окт.",
	"month_september" to "Септември",
	"month_september_short" to "Септ.",
	"multifactor_error" to "Не успеа да се потврди.",
	"new" to "Ново",
	"news" to "Вести",
	"next" to "Следно",
	"no" to "не",
	"no_data_available" to "Нема достапни податоци",
	"no_search_results" to "За жал, не можевме да најдеме резултати.",
	"none" to "Никој",
	"note_fields" to "Забелешки",
	"note_hint" to "Забелешка",
	"notifications_option" to "Известувања",
	"ok" to "добро",
	"onboarding" to "Вклучување",
	"onboarding_aboutus_message" to "Основните делови на DingVoice се засекогаш бесплатни. Нашата мисија е да ви помогнеме да го развиете вашиот бизнис, да ни помогнеме да се подобриме со споделување на вашите повратни информации.",
	"onboarding_aboutus_title" to "Ние сме тука да помогнеме",
	"onboarding_paid_1" to "Пристапете до шаблони со професионален изглед",
	"onboarding_paid_2" to "Подлабоко погледнете ги вашите финансии",
	"onboarding_paid_3" to "Брзо решете ги проблемите",
	"onboarding_paid_4" to "Чувајте ги вашите документи безбедни",
	"onboarding_paid_5" to "Бидете во тек со промените на статусот на фактурата",
	"onboarding_paid_title" to "Расте уште побрзо со...",
	"onboarding_paywall_message" to "Уживајте во сите премиум функции сега по најдобра цена.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Само ${currencySymbol}${introAmount} за ${introDuration} ${introPeriod} и потоа ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Само ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Бесплатно за ${trialDuration} ${trialPeriod} и потоа само ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Бесплатно за ${trialDuration} ${trialPeriod}, потоа ${currencySymbol}${introductoryAmount} за ${introductoryDuration} ${introPeriod}и потоа само ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Автоматско обновување. Откажете во секое време.",
	"onboarding_paywall_restore" to "Вратете го купувањето",
	"onboarding_paywall_restore_failure" to "Враќањето на купувањата не успеа, обидете се повторно подоцна или контактирајте со поддршката.",
	"onboarding_paywall_restore_success" to "Враќањето е успешно!",
	"onboarding_paywall_title" to "Постигнете величина!",
	"onboarding_welcome_message" to "Комплетна група алатки за управување со вашите фактури и проценки.",
	"onboarding_welcome_title" to "Добредојдовте во DingVoice",
	"other_state" to "Други",
	"overdue_state" to "Задоцнета",
	"paid_state" to "Платено",
	"password" to "Лозинка",
	"password_blank_error" to "Потребна е лозинка.",
	"password_mismatch_error" to "Лозинките не се совпаѓаат.",
	"pay" to "плати",
	"payment_instructions_label" to "Инструкции за плаќање",
	"payment_reminders" to "Потсетници за плаќање",
	"phone_number_fields" to "Телефонски број",
	"phone_number_hint" to "Телефонски број",
	"postcode_hint" to "Поштенски код",
	"preview" to "Преглед",
	"preview_menu_hint" to "Мени за преглед",
	"price" to "Цена",
	"privacy_policy" to "Политика за приватност",
	"profile" to "Профил",
	"profitable_client" to "Вашиот најпрофитабилен клиент е...",
	"profits_report" to "Профит",
	"promo_content" to "Промотивна содржина",
	"quantity_hint" to "Квантитет",
	"quantity_label_hint" to "Ознака за количина",
	"query_info" to "Барањето ви овозможува да пишувате сопствени пресметки кои се однесуваат на ставките и другите даночни правила како влезни податоци. Додајте @ симбол и ставка или опис на данок за да се поврзете со друг запис +, -, *, /, % - математички оператори",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} беше примен од вашиот клиент." },
	"received_state" to "Примено",
	"registration_code_fields" to "Код за регистрација",
	"reminder" to "Потсетник",
	"reports" to "Извештаи",
	"reports_not_available" to "Нема достапни извештаи, вратете се подоцна.",
	"reveal_password" to "Откријте ја лозинката",
	"revenue" to "приходите",
	"sales_tax" to "ДДВ",
	"sales_tax_number" to "ДДВ број",
	"save" to "Зачувај",
	"scan_document" to "Скенирајте документ",
	"search" to "Пребарување",
	"select" to "Изберете",
	"select_icon" to "Изберете икона",
	"select_signature" to "Изберете потпис",
	"select_template" to "Изберете шаблон",
	"send" to "Испрати",
	"sent_state" to "Испратено",
	"setup_sender" to "Поставете испраќач",
	"share_option" to "Сподели",
	"ship_to" to "Се праќа во",
	"sign_in" to "Најави се",
	"sign_in_apple" to "Пријавете се со Apple",
	"sign_in_email" to "Пријавете се со е-пошта",
	"sign_in_facebook" to "Пријавете се со Фејсбук",
	"sign_in_google" to "Пријавете се со Google",
	"sign_up" to "Пријавете се",
	"sign_up_label" to "Немате сметка?",
	"sign_up_link_label" to "Пријавете се!",
	"signatures_option" to "Потписи",
	"skip" to "Прескокни",
	"sold_items_report" to "Продадени Предмети",
	"sort_by" to "Сортирај по:",
	"status_update" to "Ажурирање на статусот на документот",
	"sub_annual" to "Годишен",
	"sub_annual_price" to Formattable { (price) -> "${price}/година" },
	"sub_backups" to "Резервни копии во облак",
	"sub_insights" to "Увиди",
	"sub_month" to "Месечно",
	"sub_monthly_price" to Formattable { (price) -> "${price}/месечно" },
	"sub_notifications" to "Известувања",
	"sub_popup_message" to "Претплатите доаѓаат наскоро. Проверете повторно овде за малку време.",
	"sub_support" to "Поддршка",
	"sub_templates" to "Шаблони",
	"subscribe" to "Претплати се",
	"subscription_header" to "Развијте го вашиот бизнис со:",
	"subscription_option" to "Претплата",
	"subscriptions_load_fail" to "За жал, не можеме да ги вратиме вашите претплати во моментов. Обидете се повторно подоцна или контактирајте со нашиот тим за поддршка за помош.",
	"subtotal" to Formattable { (sum) -> "Подзбир: ${sum}" },
	"subtotal_label" to "Субтотал",
	"sum" to "Збир",
	"support_option" to "Поддршка",
	"switch_reports" to "Вклучи Извештај",
	"tax" to "Данок",
	"tax_deleted_message" to "Данокот е избришан",
	"tax_description_warning" to "Ако се користи, ова се појавува на фактура",
	"tax_input_flat" to "Рамен",
	"tax_input_percent" to "проценти",
	"tax_number_fields" to "Даночен број",
	"tax_number_hint" to "Даночен број",
	"tax_query_info_description" to "Информации за барање",
	"tax_report" to "Данок",
	"tax_rules_option" to "Даночни правила",
	"taxed_clients_report" to "Оданочени клиенти",
	"template" to "Шаблон",
	"template_selector_info" to "Повлечете за да го промените стандардниот шаблон",
	"template_unlock_promo" to "Пристапете до повеќе шаблони со Premium",
	"templates_option" to "Шаблони",
	"terms_of_service" to "Услови за користење",
	"thank_you" to "Благодариме",
	"to" to "До",
	"total" to Formattable { (sum) -> "Вкупно: ${sum}" },
	"total_label" to "Вкупно",
	"undo_action" to "Врати",
	"unit" to "Единица",
	"unit_cost_hint" to "Единечна цена",
	"unknown_client" to "Непознат Kлиент",
	"unlock" to "Отклучи",
	"unnamed_item" to "Неименувана ставка",
	"upgrade" to "Надградба",
	"user_collision_error" to "Сметката веќе постои.",
	"valid_until" to "Употребливо до",
	"warning" to "Предупредување!",
	"yes" to "да",
    )
    
}