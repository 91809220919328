// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Bs  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Prihvatite ${termsOfService} i ${privacyPolicy}" }
override val account: String = "račun"
override val accounting: String = "računovodstvo"
override val accruals: String = "obračunski troškovi"
override val action_code_error: String = "Nevažeći kod."
override val add_client_action: String = "Dodaj klijenta"
override val add_customer: String = "Dodaj mušteriju"
override val add_expense: String = "Dodajte trošak"
override val add_fields_label: String = "Dodati:"
override val add_invoice_action: String = "Kreirati"
override val add_item_action: String = "Dodaj stavku"
override val add_item_button: String = "Dodaj stavku"
override val add_signature_action: String = "Dodaj potpis"
override val add_tax_action: String = "Dodaj porez"
override val address_fields: String = "Adresa"
override val address_line_hint: String = "Red adrese"
override val advanced: String = "Za napredne"
override val agree_marketing: String = "Slažem se da primam Direct Marketing Communications"
override val all_notifications: String = "Sva obaveštenja"
override val amortization: String = "amortizacija"
override val amount: String = "iznos"
override val asset: String = "imovina"
override val attach_file: String = "Priloži datoteku"
override val attachment: String = "Prilog"
override val auth_error: String = "Akcija nije uspjela, pokušaj ponovo."
override val available_for_paid: String = "Sinhronizacija oblaka je dostupna samo pod plaćenom pretplatom."
override val backups_option: String = "Rezervne kopije"
override val balance: String = "balans"
override val bank_account: String = "Bankovni Račun"
override val bill_number: String = "Broj računa"
override val cancel: String = "otkazati"
override val cancel_trial: String = "Otkažite bilo kada"
override val capital: String = "kapital"
override val cash: String = "gotovina"
override val categories: String = "Kategorije"
override val category: String = "Kategorija"
override val category_add: String = "Dodaj kategoriju"
override val category_delete: String = "Izbriši kategoriju"
override val category_name: String = "Naziv kategorije"
override val change_language: Formattable = Formattable { (language) -> "Jezik fakture: ${language}" }
override val change_language_label: String = "Jezik Fakture"
override val change_template: String = "Promijeni predložak"
override val city_hint: String = "Grad"
override val clear: String = "Čisto"
override val client: String = "Klijent"
override val client_actions: String = "Akcije klijenta"
override val client_deleted_message: String = "Klijent izbrisan"
override val clients_error_required_fields: String = "Potrebna je barem jedna vrijednost."
override val clients_option: String = "Klijenata"
override val close: String = "blizak"
override val cloud_sync: String = "Sinhronizacija oblaka"
override val coming_soon: String = "Dolazim brzo!"
override val company_logo: String = "Logotip kompanije"
override val company_name_hint: String = "Društvo"
override val confirm_password_label: String = "Potvrdi lozinku"
override val confirmation_title: String = "Da li ste sigurni?"
override val convert_to_invoice: String = "Pretvori u fakturu"
override val country_hint: String = "Zemlja"
override val create_client: String = "Kreiraj klijenta"
override val create_company: String = "Kreiraj kompaniju"
override val create_document: String = "Kreiraj dokument"
override val create_estimate: String = "Procjena"
override val create_invoice: String = "Faktura"
override val create_item: String = "Kreiraj stavku"
override val create_new_client: String = "Kreiraj novi klijent"
override val create_new_item: String = "Kreiraj novu stavku"
override val create_new_tax: String = "Kreiraj novi porez"
override val create_signature: String = "Potpis"
override val created: String = "Kreiran"
override val credit: String = "kredit"
override val customer: String = "Mušterija"
override val date: String = "Datum"
override val debit: String = "zaduženje"
override val debt: String = "dug"
override val default_currency: Formattable = Formattable { (currency) -> "Zadana valuta: ${currency}" }
override val default_currency_label: String = "Zadana valuta"
override val delete: String = "Izbrisati"
override val delete_account: String = "Izbrisati Račun"
override val delete_confirmation_message: String = "Svi vaši podaci će biti izgubljeni."
override val delete_item_title: String = "Izbrisati stavku?"
override val delete_signature: String = "Izbriši potpis"
override val depreciation: String = "amortizacija"
override val description: String = "Opis"
override val description_hint: String = "Opis"
override val detailed_report: String = "Detaljan Izvještaj"
override val disabled: String = "Onemogućena"
override val discount: String = "Popust"
override val discount_hint: String = "Popust"
override val display_af: String = "Afrikanski"
override val display_am: String = "Amharski"
override val display_ar: String = "Arapski"
override val display_as: String = "Asamski"
override val display_az: String = "Azerbejdžanski"
override val display_be: String = "Bjeloruski"
override val display_bg: String = "Bugarski"
override val display_bn: String = "Bengalski"
override val display_bs: String = "Bosanski"
override val display_ca: String = "Katalonski"
override val display_cs: String = "Češki"
override val display_da: String = "Danski"
override val display_de: String = "Njemački"
override val display_el: String = "Grčki"
override val display_en: String = "Engleski"
override val display_es: String = "Španski"
override val display_es_MX: String = "Španski (Meksiko)"
override val display_et: String = "Estonski"
override val display_eu: String = "Baskijski"
override val display_fa: String = "Perzijski"
override val display_fi: String = "Finski"
override val display_fil: String = "Filipinski"
override val display_fr: String = "Francuski"
override val display_fr_CA: String = "Kanadski francuski"
override val display_gl: String = "Galicijski"
override val display_gu: String = "Gudžarati"
override val display_he: String = "Hebrejski"
override val display_hi: String = "Hindski"
override val display_hr: String = "Hrvatski"
override val display_hu: String = "Mađarski"
override val display_hy: String = "Jermenski"
override val display_id: String = "Indonezijski"
override val display_is: String = "Islandski"
override val display_it: String = "Talijanski"
override val display_ja: String = "Japanski"
override val display_ka: String = "Gruzijski"
override val display_kk: String = "Kazahstanski"
override val display_km: String = "Central Khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korejski"
override val display_ky: String = "Kirgiski"
override val display_lo: String = "Laoski"
override val display_lt: String = "Litvanski"
override val display_lv: String = "Latvian"
override val display_mk: String = "Makedonski"
override val display_ml: String = "Malajalam"
override val display_mn: String = "Mongolski"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malajski"
override val display_my: String = "Burmanski"
override val display_nb: String = "Norveški"
override val display_ne: String = "Nepalski"
override val display_nl: String = "Nizozemski"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Poljski"
override val display_pt: String = "Portugalski"
override val display_pt_BR: String = "Portugalski (Brazil)"
override val display_pt_PT: String = "Evropski portugalski"
override val display_ro: String = "Rumunski"
override val display_ru: String = "Ruski"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovački"
override val display_sl: String = "Slovenački"
override val display_sq: String = "Albanski"
override val display_sr: String = "Srpski"
override val display_sv: String = "Švedski"
override val display_sw: String = "Svahili"
override val display_ta: String = "Tamilski"
override val display_te: String = "Telugu"
override val display_th: String = "Tajlandski"
override val display_tr: String = "Turski"
override val display_uk: String = "Ukrajinski"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbek"
override val display_vi: String = "Vijetnamski"
override val display_zh_Hant_TW: String = "Kineski (Tajvan, Tradicionalni)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Procjena"
override val document_invoice: String = "Faktura"
override val document_quote: String = "Navođenje"
override val done: String = "Gotovo"
override val download_app: String = "Preuzmite aplikaciju"
override val download_invoice: String = "Preuzmite Fakturu"
override val download_pdf: String = "Preuzmite PDF"
override val draw_signature: String = "Nacrtaj potpis"
override val due: Formattable = Formattable { (date) -> "Zbog ${date}" }
override val due_date: String = "Rok dosude"
override val due_day: String = "za jedan dan"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} treba platiti do ${dueWhen}!" }
override val due_today: String = "danas"
override val due_week: String = "u roku od nedelju dana"
override val duplicate: String = "Dupliciraj"
override val duration_day: String = "dan"
override val duration_month: String = "Mjesec"
override val duration_week: String = "sedmica"
override val duration_year: String = "godina"
override val email: String = "E-pošta"
override val email_blank_error: String = "Potreban e-mail."
override val email_error: String = "Nevažeća e-adresa."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Hvala vam na poslu. Za prikaz punog ${documentType} kliknite na dugme na kraju ovog pisma. Imajte na umu da vrijedi do ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Primili ste novi dokument: ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "POGLEDAJ ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Poštovani ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Hvala vam na poslu. Za prikaz punog ${documentType} kliknite na dugme na kraju ovog pisma. Molimo Vas da platite saldo ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Primili ste novi dokument: ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "E-mail za reset lozinku poslanu, molim vas provjerite vaš inbox."
override val empty_clients_list_message: String = "Nemaš klijente za praćenje. Dodaj novog klijenta."
override val empty_estimate_list_message: String = "Kreiraj svoju prvu procjenu"
override val empty_expenses: String = "Nemate troškove za praćenje. Dodajte novi trošak."
override val empty_invoice_list_message: String = "Kreiraj prvu fakturu"
override val empty_items_list_message: String = "Nemaš stavke, dodaj novu stavku!"
override val empty_signatures_list_message: String = "Nema pronađenih potpisa. Stvori jednu!"
override val empty_taxes_list_message: String = "Nemaš porezna pravila. Dodaj novo pravilo o porezu."
override val empty_templates_list_message: String = "Predlošci nedostupni."
override val enabled: String = "Omogućeno"
override val entry: String = "ulaz"
override val equity: String = "kapital"
override val estimate_number: String = "Procjena #"
override val estimates: String = "Procjene"
override val estimates_report: String = "Procjene"
override val expense_bank_fees: String = "Bankarske naknade"
override val expense_benefits: String = "Prednosti"
override val expense_communication: String = "Komunikacija"
override val expense_debt: String = "Dug"
override val expense_deleted: String = "Troškovi su izbrisani"
override val expense_depreciation: String = "Amortizacija"
override val expense_gifts: String = "Pokloni"
override val expense_insurance: String = "Osiguranje"
override val expense_inventory: String = "Inventar"
override val expense_legal: String = "Pravni"
override val expense_maintenance: String = "Održavanje"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Obroci"
override val expense_office_supplies: String = "Kancelarijski materijal"
override val expense_rent: String = "Najam"
override val expense_salaries: String = "Plate"
override val expense_shopping: String = "Kupovina"
override val expense_software: String = "Softver"
override val expense_status_available: String = "Dostupan"
override val expense_status_processing: String = "Obrada"
override val expense_tax: String = "Porez"
override val expense_training: String = "Trening"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Putovanje"
override val expense_utility: String = "Utility"
override val expenses: String = "Troškovi"
override val feedback_option: String = "Povratne informacije"
override val filter: String = "Filter"
override val first_name_hint: String = "Ime"
override val forgot_password: String = "Reset lozinka"
override val forgot_password_label: String = "Zaboravio sam lozinku"
override val from: String = "Iz"
override val general_error: String = "Došlo je do greške, pokušajte ponovo kasnije."
override val general_ledger: String = "glavna knjiga"
override val go_back_label: String = "Vrati se"
override val group_by: String = "Group By:"
override val image_view: String = "Slika"
override val install: String = "Instaliraj"
override val invalid_credentials_error: String = "Nevažeći akreditivi."
override val invalid_email_error: String = "Nevažeći e-mail."
override val invalid_user_error: String = "Nevažeći korisnički akreditivi."
override val invoice_deleted_message: String = "Faktura izbrisana"
override val invoice_menu: String = "Meni fakture"
override val invoice_number: String = "Faktura #"
override val invoices: String = "Fakture"
override val items_option: String = "Stavke"
override val keep: String = "Zadržati"
override val language_selector: Formattable = Formattable { (selected) -> "Jezik ${selected}" }
override val last_name_hint: String = "Prezime"
override val ledger: String = "knjiga"
override val legal: String = "Legalno"
override val legal_error: String = "Mora prihvatiti Uvjete usluge i politike privatnosti"
override val liabilities: String = "obaveze"
override val loading: String = "Učitavanje"
override val login_required_error: String = "Prijava potrebna."
override val logo_view: String = "Logo"
override val logout: String = "Odjava"
override val logout_prompt: String = "Tvoji podaci nisu sinhronizovani sa oblakom. Brisanje faktura, procjena, klijenata i drugih informacija o odjavi?"
override val main_screen: String = "Glavni ekran"
override val mark_paid: String = "Označi kao plaćeno"
override val message: String = "Poruka"
override val missing_sender_msg: String = "Dokument bez informacija o pošiljatelju može biti nevažeći."
override val missing_sender_title: String = "Nedostaju podaci o pošiljatelju"
override val month_april: String = "April"
override val month_april_short: String = "April"
override val month_august: String = "Avgust"
override val month_august_short: String = "Aug."
override val month_december: String = "Decembar"
override val month_december_short: String = "Dec."
override val month_february: String = "Februar"
override val month_february_short: String = "Feb."
override val month_january: String = "Januar"
override val month_january_short: String = "Jan."
override val month_july: String = "Juli"
override val month_july_short: String = "Juli"
override val month_june: String = "Juni"
override val month_june_short: String = "Juni"
override val month_march: String = "Mart"
override val month_march_short: String = "Mart"
override val month_may: String = "Maj"
override val month_may_short: String = "Maj"
override val month_november: String = "Novembar"
override val month_november_short: String = "Nov."
override val month_october: String = "Oktobar"
override val month_october_short: String = "Okt."
override val month_september: String = "Septembar"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Nije uspjelo potvrditi."
override val new: String = "Novo"
override val news: String = "Vijesti"
override val next: String = "Sljedeći"
override val no: String = "ne"
override val no_data_available: String = "Nema dostupnih podataka"
override val no_search_results: String = "Izvini, nismo mogli da nađemo rezultate."
override val none: String = "Nema"
override val note_fields: String = "Zabilješke"
override val note_hint: String = "Napomena"
override val notifications_option: String = "Obavještenja"
override val ok: String = "Ok"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Osnovni dijelovi DingVoicea su zauvijek besplatni. Naša misija je da vam pomognemo da razvijete svoje poslovanje, pomognete nam da poboljšamo tako što ćemo podijeliti vaše povratne informacije."
override val onboarding_aboutus_title: String = "Tu smo da pomognemo"
override val onboarding_paid_1: String = "Pristupite predlošcima profesionalnog izgleda"
override val onboarding_paid_2: String = "Pogledajte dublje u svoje finansije"
override val onboarding_paid_3: String = "Brzo riješite probleme"
override val onboarding_paid_4: String = "Čuvajte svoje dokumente"
override val onboarding_paid_5: String = "Budite u toku sa promjenama statusa fakture"
override val onboarding_paid_title: String = "Rastite još brže sa..."
override val onboarding_paywall_message: String = "Uživajte u svim premium funkcijama sada po najboljoj cijeni."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Samo ${currencySymbol}${introAmount} za ${introDuration} ${introPeriod}, a zatim ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Samo ${currencySymbol}${amount}/${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Besplatno za ${trialDuration} ${trialPeriod}, a zatim samo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Besplatno za ${trialDuration} ${trialPeriod}, zatim ${currencySymbol}${introductoryAmount} za ${introductoryDuration} ${introPeriod}, a zatim samo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automatski obnovljiv. Otkažite bilo kada."
override val onboarding_paywall_restore: String = "Vratite kupovinu"
override val onboarding_paywall_restore_failure: String = "Vraćanje kupovine nije uspjelo, pokušajte ponovo kasnije ili kontaktirajte podršku."
override val onboarding_paywall_restore_success: String = "Obnavljanje uspješno!"
override val onboarding_paywall_title: String = "Achieve Greatness!"
override val onboarding_welcome_message: String = "Kompletan skup alata za upravljanje vašim fakturama i procjenama."
override val onboarding_welcome_title: String = "Dobrodošli u DingVoice"
override val other_state: String = "Ostalo"
override val overdue_state: String = "Prekoračenje"
override val paid_state: String = "Platio"
override val password: String = "Lozinka"
override val password_blank_error: String = "Potrebna lozinka."
override val password_mismatch_error: String = "Lozinke se ne podudaraju."
override val pay: String = "plati"
override val payment_instructions_label: String = "Upute za plaćanje"
override val payment_reminders: String = "Podsjetnik na plaćanje"
override val phone_number_fields: String = "Telefonski broj"
override val phone_number_hint: String = "Telefonski broj"
override val postcode_hint: String = "Postkod"
override val preview: String = "Pregled"
override val preview_menu_hint: String = "Pregled menija"
override val price: String = "Cijena"
override val privacy_policy: String = "Politika privatnosti"
override val profile: String = "Profil"
override val profitable_client: String = "Vaš najprofitabilniji klijent je..."
override val profits_report: String = "Zaradu"
override val promo_content: String = "Promotivni sadržaj"
override val quantity_hint: String = "Količina"
override val quantity_label_hint: String = "Oznaka količine"
override val query_info: String = "Upit vam omogućava da napišete prilagođene izračune koji se odnose i na stavke i na druga poreska pravila kao na ulaze. Dodaj @ simbol i stavku ili opis poreza za link na drugi unos +, -, *, /, % - operatori matematike"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} je primio vaš klijent." }
override val received_state: String = "Primljeno"
override val registration_code_fields: String = "Registracioni kod"
override val reminder: String = "Podsjetnik"
override val reports: String = "Izvještava"
override val reports_not_available: String = "Nema dostupnih izvještaja, vratite se kasnije."
override val reveal_password: String = "Otkrij lozinku"
override val revenue: String = "dohodak"
override val sales_tax: String = "PDV-OM"
override val sales_tax_number: String = "PDV broj"
override val save: String = "Spasiti"
override val scan_document: String = "Skenirajte dokument"
override val search: String = "Tražiti"
override val select: String = "Odaberite"
override val select_icon: String = "Odaberite ikonu"
override val select_signature: String = "Izaberi potpis"
override val select_template: String = "Odaberi predložak"
override val send: String = "poslati"
override val sent_state: String = "Poslao"
override val setup_sender: String = "Postavite pošiljatelja"
override val share_option: String = "Dijeliti"
override val ship_to: String = "Pošaljite na"
override val sign_in: String = "Prijavi se"
override val sign_in_apple: String = "Prijavite se uz Apple"
override val sign_in_email: String = "Prijavite se e-poštom"
override val sign_in_facebook: String = "Prijavite se sa Facebookom"
override val sign_in_google: String = "Prijavite se googleom"
override val sign_up: String = "Prijavi se"
override val sign_up_label: String = "Nemaš račun?"
override val sign_up_link_label: String = "Prijavi se!"
override val signatures_option: String = "Potpise"
override val skip: String = "Skip"
override val sold_items_report: String = "Prodane Stavke"
override val sort_by: String = "Sortiraj po:"
override val status_update: String = "Ažuriranje statusa dokumenta"
override val sub_annual: String = "Godišnji"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/godina" }
override val sub_backups: String = "Cloud sigurnosne kopije"
override val sub_insights: String = "Uvidi"
override val sub_month: String = "Mjesečno"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price} /mjesec" }
override val sub_notifications: String = "Obavještenja"
override val sub_popup_message: String = "Pretplate dolaze uskoro. Provjerite natrag ovdje za malo vremena."
override val sub_support: String = "Podržati"
override val sub_templates: String = "Šabloni"
override val subscribe: String = "Pretplati se"
override val subscription_header: String = "Razvijajte svoje poslovanje sa:"
override val subscription_option: String = "Pretplate"
override val subscriptions_load_fail: String = "Žao nam je, trenutno ne možemo preuzeti vaše pretplate. Pokušajte ponovo kasnije ili kontaktirajte naš tim za podršku za pomoć."
override val subtotal: Formattable = Formattable { (sum) -> "Podzvrd: ${sum}" }
override val subtotal_label: String = "Međuzbroj"
override val sum: String = "Suma"
override val support_option: String = "Podržati"
override val switch_reports: String = "Zamijeni Izvještaj"
override val tax: String = "Poreza"
override val tax_deleted_message: String = "Porez izbrisan"
override val tax_description_warning: String = "Ako se ovo koristi pojavi se na fakturi"
override val tax_input_flat: String = "Ravan"
override val tax_input_percent: String = "Postotak"
override val tax_number_fields: String = "Porezni broj"
override val tax_number_hint: String = "Porezni broj"
override val tax_query_info_description: String = "Informacije o upitu"
override val tax_report: String = "Porez"
override val tax_rules_option: String = "Poreska pravila"
override val taxed_clients_report: String = "Oporezovani klijenti"
override val template: String = "Šablon"
override val template_selector_info: String = "Prijevucite da promijenite zadani predložak"
override val template_unlock_promo: String = "Pristupite više šablona uz Premium"
override val templates_option: String = "Predložaka"
override val terms_of_service: String = "Uslovi usluge"
override val thank_you: String = "Hvala Vam"
override val to: String = "Do"
override val total: Formattable = Formattable { (sum) -> "Ukupno: ${sum}" }
override val total_label: String = "Ukupno"
override val undo_action: String = "Poništiti"
override val unit: String = "Jedinica"
override val unit_cost_hint: String = "Trošak po jedinici"
override val unknown_client: String = "Nepoznati Klijent"
override val unlock: String = "Otključaj"
override val unnamed_item: String = "Neimenovana stavka"
override val upgrade: String = "Nadogradnju"
override val user_collision_error: String = "Račun već postoji."
override val valid_until: String = "Vrijedi do"
override val warning: String = "Upozorenje!"
override val yes: String = "da"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Prihvatite ${termsOfService} i ${privacyPolicy}" },
	"account" to "račun",
	"accounting" to "računovodstvo",
	"accruals" to "obračunski troškovi",
	"action_code_error" to "Nevažeći kod.",
	"add_client_action" to "Dodaj klijenta",
	"add_customer" to "Dodaj mušteriju",
	"add_expense" to "Dodajte trošak",
	"add_fields_label" to "Dodati:",
	"add_invoice_action" to "Kreirati",
	"add_item_action" to "Dodaj stavku",
	"add_item_button" to "Dodaj stavku",
	"add_signature_action" to "Dodaj potpis",
	"add_tax_action" to "Dodaj porez",
	"address_fields" to "Adresa",
	"address_line_hint" to "Red adrese",
	"advanced" to "Za napredne",
	"agree_marketing" to "Slažem se da primam Direct Marketing Communications",
	"all_notifications" to "Sva obaveštenja",
	"amortization" to "amortizacija",
	"amount" to "iznos",
	"asset" to "imovina",
	"attach_file" to "Priloži datoteku",
	"attachment" to "Prilog",
	"auth_error" to "Akcija nije uspjela, pokušaj ponovo.",
	"available_for_paid" to "Sinhronizacija oblaka je dostupna samo pod plaćenom pretplatom.",
	"backups_option" to "Rezervne kopije",
	"balance" to "balans",
	"bank_account" to "Bankovni Račun",
	"bill_number" to "Broj računa",
	"cancel" to "otkazati",
	"cancel_trial" to "Otkažite bilo kada",
	"capital" to "kapital",
	"cash" to "gotovina",
	"categories" to "Kategorije",
	"category" to "Kategorija",
	"category_add" to "Dodaj kategoriju",
	"category_delete" to "Izbriši kategoriju",
	"category_name" to "Naziv kategorije",
	"change_language" to Formattable { (language) -> "Jezik fakture: ${language}" },
	"change_language_label" to "Jezik Fakture",
	"change_template" to "Promijeni predložak",
	"city_hint" to "Grad",
	"clear" to "Čisto",
	"client" to "Klijent",
	"client_actions" to "Akcije klijenta",
	"client_deleted_message" to "Klijent izbrisan",
	"clients_error_required_fields" to "Potrebna je barem jedna vrijednost.",
	"clients_option" to "Klijenata",
	"close" to "blizak",
	"cloud_sync" to "Sinhronizacija oblaka",
	"coming_soon" to "Dolazim brzo!",
	"company_logo" to "Logotip kompanije",
	"company_name_hint" to "Društvo",
	"confirm_password_label" to "Potvrdi lozinku",
	"confirmation_title" to "Da li ste sigurni?",
	"convert_to_invoice" to "Pretvori u fakturu",
	"country_hint" to "Zemlja",
	"create_client" to "Kreiraj klijenta",
	"create_company" to "Kreiraj kompaniju",
	"create_document" to "Kreiraj dokument",
	"create_estimate" to "Procjena",
	"create_invoice" to "Faktura",
	"create_item" to "Kreiraj stavku",
	"create_new_client" to "Kreiraj novi klijent",
	"create_new_item" to "Kreiraj novu stavku",
	"create_new_tax" to "Kreiraj novi porez",
	"create_signature" to "Potpis",
	"created" to "Kreiran",
	"credit" to "kredit",
	"customer" to "Mušterija",
	"date" to "Datum",
	"debit" to "zaduženje",
	"debt" to "dug",
	"default_currency" to Formattable { (currency) -> "Zadana valuta: ${currency}" },
	"default_currency_label" to "Zadana valuta",
	"delete" to "Izbrisati",
	"delete_account" to "Izbrisati Račun",
	"delete_confirmation_message" to "Svi vaši podaci će biti izgubljeni.",
	"delete_item_title" to "Izbrisati stavku?",
	"delete_signature" to "Izbriši potpis",
	"depreciation" to "amortizacija",
	"description" to "Opis",
	"description_hint" to "Opis",
	"detailed_report" to "Detaljan Izvještaj",
	"disabled" to "Onemogućena",
	"discount" to "Popust",
	"discount_hint" to "Popust",
	"display_af" to "Afrikanski",
	"display_am" to "Amharski",
	"display_ar" to "Arapski",
	"display_as" to "Asamski",
	"display_az" to "Azerbejdžanski",
	"display_be" to "Bjeloruski",
	"display_bg" to "Bugarski",
	"display_bn" to "Bengalski",
	"display_bs" to "Bosanski",
	"display_ca" to "Katalonski",
	"display_cs" to "Češki",
	"display_da" to "Danski",
	"display_de" to "Njemački",
	"display_el" to "Grčki",
	"display_en" to "Engleski",
	"display_es" to "Španski",
	"display_es_MX" to "Španski (Meksiko)",
	"display_et" to "Estonski",
	"display_eu" to "Baskijski",
	"display_fa" to "Perzijski",
	"display_fi" to "Finski",
	"display_fil" to "Filipinski",
	"display_fr" to "Francuski",
	"display_fr_CA" to "Kanadski francuski",
	"display_gl" to "Galicijski",
	"display_gu" to "Gudžarati",
	"display_he" to "Hebrejski",
	"display_hi" to "Hindski",
	"display_hr" to "Hrvatski",
	"display_hu" to "Mađarski",
	"display_hy" to "Jermenski",
	"display_id" to "Indonezijski",
	"display_is" to "Islandski",
	"display_it" to "Talijanski",
	"display_ja" to "Japanski",
	"display_ka" to "Gruzijski",
	"display_kk" to "Kazahstanski",
	"display_km" to "Central Khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Korejski",
	"display_ky" to "Kirgiski",
	"display_lo" to "Laoski",
	"display_lt" to "Litvanski",
	"display_lv" to "Latvian",
	"display_mk" to "Makedonski",
	"display_ml" to "Malajalam",
	"display_mn" to "Mongolski",
	"display_mr" to "Marathi",
	"display_ms" to "Malajski",
	"display_my" to "Burmanski",
	"display_nb" to "Norveški",
	"display_ne" to "Nepalski",
	"display_nl" to "Nizozemski",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Poljski",
	"display_pt" to "Portugalski",
	"display_pt_BR" to "Portugalski (Brazil)",
	"display_pt_PT" to "Evropski portugalski",
	"display_ro" to "Rumunski",
	"display_ru" to "Ruski",
	"display_si" to "Sinhala",
	"display_sk" to "Slovački",
	"display_sl" to "Slovenački",
	"display_sq" to "Albanski",
	"display_sr" to "Srpski",
	"display_sv" to "Švedski",
	"display_sw" to "Svahili",
	"display_ta" to "Tamilski",
	"display_te" to "Telugu",
	"display_th" to "Tajlandski",
	"display_tr" to "Turski",
	"display_uk" to "Ukrajinski",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbek",
	"display_vi" to "Vijetnamski",
	"display_zh_Hant_TW" to "Kineski (Tajvan, Tradicionalni)",
	"display_zu" to "Zulu",
	"document_estimate" to "Procjena",
	"document_invoice" to "Faktura",
	"document_quote" to "Navođenje",
	"done" to "Gotovo",
	"download_app" to "Preuzmite aplikaciju",
	"download_invoice" to "Preuzmite Fakturu",
	"download_pdf" to "Preuzmite PDF",
	"draw_signature" to "Nacrtaj potpis",
	"due" to Formattable { (date) -> "Zbog ${date}" },
	"due_date" to "Rok dosude",
	"due_day" to "za jedan dan",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} treba platiti do ${dueWhen}!" },
	"due_today" to "danas",
	"due_week" to "u roku od nedelju dana",
	"duplicate" to "Dupliciraj",
	"duration_day" to "dan",
	"duration_month" to "Mjesec",
	"duration_week" to "sedmica",
	"duration_year" to "godina",
	"email" to "E-pošta",
	"email_blank_error" to "Potreban e-mail.",
	"email_error" to "Nevažeća e-adresa.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Hvala vam na poslu. Za prikaz punog ${documentType} kliknite na dugme na kraju ovog pisma. Imajte na umu da vrijedi do ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Primili ste novi dokument: ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "POGLEDAJ ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Poštovani ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Hvala vam na poslu. Za prikaz punog ${documentType} kliknite na dugme na kraju ovog pisma. Molimo Vas da platite saldo ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Primili ste novi dokument: ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "E-mail za reset lozinku poslanu, molim vas provjerite vaš inbox.",
	"empty_clients_list_message" to "Nemaš klijente za praćenje. Dodaj novog klijenta.",
	"empty_estimate_list_message" to "Kreiraj svoju prvu procjenu",
	"empty_expenses" to "Nemate troškove za praćenje. Dodajte novi trošak.",
	"empty_invoice_list_message" to "Kreiraj prvu fakturu",
	"empty_items_list_message" to "Nemaš stavke, dodaj novu stavku!",
	"empty_signatures_list_message" to "Nema pronađenih potpisa. Stvori jednu!",
	"empty_taxes_list_message" to "Nemaš porezna pravila. Dodaj novo pravilo o porezu.",
	"empty_templates_list_message" to "Predlošci nedostupni.",
	"enabled" to "Omogućeno",
	"entry" to "ulaz",
	"equity" to "kapital",
	"estimate_number" to "Procjena #",
	"estimates" to "Procjene",
	"estimates_report" to "Procjene",
	"expense_bank_fees" to "Bankarske naknade",
	"expense_benefits" to "Prednosti",
	"expense_communication" to "Komunikacija",
	"expense_debt" to "Dug",
	"expense_deleted" to "Troškovi su izbrisani",
	"expense_depreciation" to "Amortizacija",
	"expense_gifts" to "Pokloni",
	"expense_insurance" to "Osiguranje",
	"expense_inventory" to "Inventar",
	"expense_legal" to "Pravni",
	"expense_maintenance" to "Održavanje",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Obroci",
	"expense_office_supplies" to "Kancelarijski materijal",
	"expense_rent" to "Najam",
	"expense_salaries" to "Plate",
	"expense_shopping" to "Kupovina",
	"expense_software" to "Softver",
	"expense_status_available" to "Dostupan",
	"expense_status_processing" to "Obrada",
	"expense_tax" to "Porez",
	"expense_training" to "Trening",
	"expense_transport" to "Transport",
	"expense_travel" to "Putovanje",
	"expense_utility" to "Utility",
	"expenses" to "Troškovi",
	"feedback_option" to "Povratne informacije",
	"filter" to "Filter",
	"first_name_hint" to "Ime",
	"forgot_password" to "Reset lozinka",
	"forgot_password_label" to "Zaboravio sam lozinku",
	"from" to "Iz",
	"general_error" to "Došlo je do greške, pokušajte ponovo kasnije.",
	"general_ledger" to "glavna knjiga",
	"go_back_label" to "Vrati se",
	"group_by" to "Group By:",
	"image_view" to "Slika",
	"install" to "Instaliraj",
	"invalid_credentials_error" to "Nevažeći akreditivi.",
	"invalid_email_error" to "Nevažeći e-mail.",
	"invalid_user_error" to "Nevažeći korisnički akreditivi.",
	"invoice_deleted_message" to "Faktura izbrisana",
	"invoice_menu" to "Meni fakture",
	"invoice_number" to "Faktura #",
	"invoices" to "Fakture",
	"items_option" to "Stavke",
	"keep" to "Zadržati",
	"language_selector" to Formattable { (selected) -> "Jezik ${selected}" },
	"last_name_hint" to "Prezime",
	"ledger" to "knjiga",
	"legal" to "Legalno",
	"legal_error" to "Mora prihvatiti Uvjete usluge i politike privatnosti",
	"liabilities" to "obaveze",
	"loading" to "Učitavanje",
	"login_required_error" to "Prijava potrebna.",
	"logo_view" to "Logo",
	"logout" to "Odjava",
	"logout_prompt" to "Tvoji podaci nisu sinhronizovani sa oblakom. Brisanje faktura, procjena, klijenata i drugih informacija o odjavi?",
	"main_screen" to "Glavni ekran",
	"mark_paid" to "Označi kao plaćeno",
	"message" to "Poruka",
	"missing_sender_msg" to "Dokument bez informacija o pošiljatelju može biti nevažeći.",
	"missing_sender_title" to "Nedostaju podaci o pošiljatelju",
	"month_april" to "April",
	"month_april_short" to "April",
	"month_august" to "Avgust",
	"month_august_short" to "Aug.",
	"month_december" to "Decembar",
	"month_december_short" to "Dec.",
	"month_february" to "Februar",
	"month_february_short" to "Feb.",
	"month_january" to "Januar",
	"month_january_short" to "Jan.",
	"month_july" to "Juli",
	"month_july_short" to "Juli",
	"month_june" to "Juni",
	"month_june_short" to "Juni",
	"month_march" to "Mart",
	"month_march_short" to "Mart",
	"month_may" to "Maj",
	"month_may_short" to "Maj",
	"month_november" to "Novembar",
	"month_november_short" to "Nov.",
	"month_october" to "Oktobar",
	"month_october_short" to "Okt.",
	"month_september" to "Septembar",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Nije uspjelo potvrditi.",
	"new" to "Novo",
	"news" to "Vijesti",
	"next" to "Sljedeći",
	"no" to "ne",
	"no_data_available" to "Nema dostupnih podataka",
	"no_search_results" to "Izvini, nismo mogli da nađemo rezultate.",
	"none" to "Nema",
	"note_fields" to "Zabilješke",
	"note_hint" to "Napomena",
	"notifications_option" to "Obavještenja",
	"ok" to "Ok",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Osnovni dijelovi DingVoicea su zauvijek besplatni. Naša misija je da vam pomognemo da razvijete svoje poslovanje, pomognete nam da poboljšamo tako što ćemo podijeliti vaše povratne informacije.",
	"onboarding_aboutus_title" to "Tu smo da pomognemo",
	"onboarding_paid_1" to "Pristupite predlošcima profesionalnog izgleda",
	"onboarding_paid_2" to "Pogledajte dublje u svoje finansije",
	"onboarding_paid_3" to "Brzo riješite probleme",
	"onboarding_paid_4" to "Čuvajte svoje dokumente",
	"onboarding_paid_5" to "Budite u toku sa promjenama statusa fakture",
	"onboarding_paid_title" to "Rastite još brže sa...",
	"onboarding_paywall_message" to "Uživajte u svim premium funkcijama sada po najboljoj cijeni.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Samo ${currencySymbol}${introAmount} za ${introDuration} ${introPeriod}, a zatim ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Samo ${currencySymbol}${amount}/${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Besplatno za ${trialDuration} ${trialPeriod}, a zatim samo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Besplatno za ${trialDuration} ${trialPeriod}, zatim ${currencySymbol}${introductoryAmount} za ${introductoryDuration} ${introPeriod}, a zatim samo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automatski obnovljiv. Otkažite bilo kada.",
	"onboarding_paywall_restore" to "Vratite kupovinu",
	"onboarding_paywall_restore_failure" to "Vraćanje kupovine nije uspjelo, pokušajte ponovo kasnije ili kontaktirajte podršku.",
	"onboarding_paywall_restore_success" to "Obnavljanje uspješno!",
	"onboarding_paywall_title" to "Achieve Greatness!",
	"onboarding_welcome_message" to "Kompletan skup alata za upravljanje vašim fakturama i procjenama.",
	"onboarding_welcome_title" to "Dobrodošli u DingVoice",
	"other_state" to "Ostalo",
	"overdue_state" to "Prekoračenje",
	"paid_state" to "Platio",
	"password" to "Lozinka",
	"password_blank_error" to "Potrebna lozinka.",
	"password_mismatch_error" to "Lozinke se ne podudaraju.",
	"pay" to "plati",
	"payment_instructions_label" to "Upute za plaćanje",
	"payment_reminders" to "Podsjetnik na plaćanje",
	"phone_number_fields" to "Telefonski broj",
	"phone_number_hint" to "Telefonski broj",
	"postcode_hint" to "Postkod",
	"preview" to "Pregled",
	"preview_menu_hint" to "Pregled menija",
	"price" to "Cijena",
	"privacy_policy" to "Politika privatnosti",
	"profile" to "Profil",
	"profitable_client" to "Vaš najprofitabilniji klijent je...",
	"profits_report" to "Zaradu",
	"promo_content" to "Promotivni sadržaj",
	"quantity_hint" to "Količina",
	"quantity_label_hint" to "Oznaka količine",
	"query_info" to "Upit vam omogućava da napišete prilagođene izračune koji se odnose i na stavke i na druga poreska pravila kao na ulaze. Dodaj @ simbol i stavku ili opis poreza za link na drugi unos +, -, *, /, % - operatori matematike",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} je primio vaš klijent." },
	"received_state" to "Primljeno",
	"registration_code_fields" to "Registracioni kod",
	"reminder" to "Podsjetnik",
	"reports" to "Izvještava",
	"reports_not_available" to "Nema dostupnih izvještaja, vratite se kasnije.",
	"reveal_password" to "Otkrij lozinku",
	"revenue" to "dohodak",
	"sales_tax" to "PDV-OM",
	"sales_tax_number" to "PDV broj",
	"save" to "Spasiti",
	"scan_document" to "Skenirajte dokument",
	"search" to "Tražiti",
	"select" to "Odaberite",
	"select_icon" to "Odaberite ikonu",
	"select_signature" to "Izaberi potpis",
	"select_template" to "Odaberi predložak",
	"send" to "poslati",
	"sent_state" to "Poslao",
	"setup_sender" to "Postavite pošiljatelja",
	"share_option" to "Dijeliti",
	"ship_to" to "Pošaljite na",
	"sign_in" to "Prijavi se",
	"sign_in_apple" to "Prijavite se uz Apple",
	"sign_in_email" to "Prijavite se e-poštom",
	"sign_in_facebook" to "Prijavite se sa Facebookom",
	"sign_in_google" to "Prijavite se googleom",
	"sign_up" to "Prijavi se",
	"sign_up_label" to "Nemaš račun?",
	"sign_up_link_label" to "Prijavi se!",
	"signatures_option" to "Potpise",
	"skip" to "Skip",
	"sold_items_report" to "Prodane Stavke",
	"sort_by" to "Sortiraj po:",
	"status_update" to "Ažuriranje statusa dokumenta",
	"sub_annual" to "Godišnji",
	"sub_annual_price" to Formattable { (price) -> "${price}/godina" },
	"sub_backups" to "Cloud sigurnosne kopije",
	"sub_insights" to "Uvidi",
	"sub_month" to "Mjesečno",
	"sub_monthly_price" to Formattable { (price) -> "${price} /mjesec" },
	"sub_notifications" to "Obavještenja",
	"sub_popup_message" to "Pretplate dolaze uskoro. Provjerite natrag ovdje za malo vremena.",
	"sub_support" to "Podržati",
	"sub_templates" to "Šabloni",
	"subscribe" to "Pretplati se",
	"subscription_header" to "Razvijajte svoje poslovanje sa:",
	"subscription_option" to "Pretplate",
	"subscriptions_load_fail" to "Žao nam je, trenutno ne možemo preuzeti vaše pretplate. Pokušajte ponovo kasnije ili kontaktirajte naš tim za podršku za pomoć.",
	"subtotal" to Formattable { (sum) -> "Podzvrd: ${sum}" },
	"subtotal_label" to "Međuzbroj",
	"sum" to "Suma",
	"support_option" to "Podržati",
	"switch_reports" to "Zamijeni Izvještaj",
	"tax" to "Poreza",
	"tax_deleted_message" to "Porez izbrisan",
	"tax_description_warning" to "Ako se ovo koristi pojavi se na fakturi",
	"tax_input_flat" to "Ravan",
	"tax_input_percent" to "Postotak",
	"tax_number_fields" to "Porezni broj",
	"tax_number_hint" to "Porezni broj",
	"tax_query_info_description" to "Informacije o upitu",
	"tax_report" to "Porez",
	"tax_rules_option" to "Poreska pravila",
	"taxed_clients_report" to "Oporezovani klijenti",
	"template" to "Šablon",
	"template_selector_info" to "Prijevucite da promijenite zadani predložak",
	"template_unlock_promo" to "Pristupite više šablona uz Premium",
	"templates_option" to "Predložaka",
	"terms_of_service" to "Uslovi usluge",
	"thank_you" to "Hvala Vam",
	"to" to "Do",
	"total" to Formattable { (sum) -> "Ukupno: ${sum}" },
	"total_label" to "Ukupno",
	"undo_action" to "Poništiti",
	"unit" to "Jedinica",
	"unit_cost_hint" to "Trošak po jedinici",
	"unknown_client" to "Nepoznati Klijent",
	"unlock" to "Otključaj",
	"unnamed_item" to "Neimenovana stavka",
	"upgrade" to "Nadogradnju",
	"user_collision_error" to "Račun već postoji.",
	"valid_until" to "Vrijedi do",
	"warning" to "Upozorenje!",
	"yes" to "da",
    )
    
}