package com.dingvoice.core.data

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.contentOrNull
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.putJsonArray

val mapSerializer = Json {
    ignoreUnknownKeys = true
    isLenient = true
    allowSpecialFloatingPointValues = false
}

fun Map<*, *>.toJson(): JsonObject {
    return buildJsonObject {
        for (key in keys) {
            when (val value = get(key)) {
                is Map<*, *> -> put(key.toString(), value.toJson())
                is Iterable<*> -> {
                    putJsonArray(key.toString()) { value.forEach { add(it.toJsonElement()) } }
                }

                is Array<*> -> {
                    putJsonArray(key.toString()) { value.forEach { add(it.toJsonElement()) } }
                }

                else -> put(key.toString(), value.toJsonElement())
            }
        }
    }
}

inline fun <reified T : Any> T.convertToMap(): Map<String, Any?> {
    return Json.encodeToJsonElement(this).jsonObject.asMap()
}

fun JsonObject.asMap(): Map<String, Any?> {
    return this.toMap().map { (key, value) ->
        key to value.fromJsonElement()
    }.toMap()
}

fun JsonElement.fromJsonElement(): Any? {
    return when (this) {
        is JsonPrimitive -> {
            if (this.isString) {
                this.contentOrNull
            } else if (this.contentOrNull?.toBooleanStrictOrNull() != null) {
                this.contentOrNull?.toBooleanStrictOrNull()
            } else {
                this.contentOrNull?.toFloatOrNull()
            }
        }

        is JsonObject -> this.asMap()
        is JsonNull -> null
        is JsonArray -> this.map { it.fromJsonElement() }
        else -> this.toString()
    }
}

fun Any?.toJsonElement(): JsonElement {
    return if (this is Map<*, *>) {
        toJson()
    } else {
        if (this == null) {
            JsonNull
        } else {
            when (this) {
                is Number -> JsonPrimitive(this)
                is Boolean -> JsonPrimitive(this)
                is String -> JsonPrimitive(this)
                else -> JsonPrimitive(toString())
            }
        }
    }
}

/**
 * Instantiates instance of T with properties
 *
 * @return instance of type T hydrated with Map properties
 */
inline fun <reified T> Map<*, *>.toType(): T = mapSerializer.decodeFromString("${this.toJson()}")

fun listOfNotEmpty(vararg elements: String?): List<String> =
    elements.filterNot { it.isNullOrBlank() }.filterNotNull()

operator fun <T> Array<T>.component6(): T = get(5)
operator fun <T> Array<T>.component7(): T = get(6)
operator fun <T> Array<T>.component8(): T = get(7)
operator fun <T> Array<T>.component9(): T = get(8)
