// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Hr  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Prihvatite ${termsOfService} i ${privacyPolicy}" }
override val account: String = "račun"
override val accounting: String = "računovodstvo"
override val accruals: String = "obračunski troškovi"
override val action_code_error: String = "Kod nije valjan."
override val add_client_action: String = "Dodaj klijenta"
override val add_customer: String = "Dodaj kupca"
override val add_expense: String = "Dodajte trošak"
override val add_fields_label: String = "Dodati:"
override val add_invoice_action: String = "Stvoriti"
override val add_item_action: String = "Dodaj stavku"
override val add_item_button: String = "Dodaj stavku"
override val add_signature_action: String = "Dodaj potpis"
override val add_tax_action: String = "Dodaj porez"
override val address_fields: String = "Adresa"
override val address_line_hint: String = "Redak adrese"
override val advanced: String = "Napredan"
override val agree_marketing: String = "Slažem se s primanjem izravnih marketinških komunikacija"
override val all_notifications: String = "Sve obavijesti"
override val amortization: String = "amortizacija"
override val amount: String = "iznos"
override val asset: String = "imovina"
override val attach_file: String = "Priložite datoteku"
override val attachment: String = "Prilog"
override val auth_error: String = "Akcija nije uspjela, pokušajte ponovno."
override val available_for_paid: String = "Sinkronizacija u oblaku dostupna je samo u okviru plaćene pretplate."
override val backups_option: String = "Sigurnosne kopije"
override val balance: String = "saldo"
override val bank_account: String = "Bankovni Račun"
override val bill_number: String = "Broj računa"
override val cancel: String = "otkazati"
override val cancel_trial: String = "Otkažite u bilo koje vrijeme"
override val capital: String = "kapital"
override val cash: String = "gotovina"
override val categories: String = "Kategorije"
override val category: String = "Kategorija"
override val category_add: String = "Dodaj kategoriju"
override val category_delete: String = "Izbriši kategoriju"
override val category_name: String = "Ime kategorije"
override val change_language: Formattable = Formattable { (language) -> "Jezik fakture: ${language}" }
override val change_language_label: String = "Jezik Fakture"
override val change_template: String = "Promjena predloška"
override val city_hint: String = "Grad"
override val clear: String = "jasan"
override val client: String = "Klijent"
override val client_actions: String = "Klijentske akcije"
override val client_deleted_message: String = "Klijent je izbrisan"
override val clients_error_required_fields: String = "Potrebna je barem jedna vrijednost."
override val clients_option: String = "Klijentima"
override val close: String = "blizak"
override val cloud_sync: String = "Sinkronizacija u oblaku"
override val coming_soon: String = "Dolazim brzo!"
override val company_logo: String = "Logotip tvrtke"
override val company_name_hint: String = "Tvrtka"
override val confirm_password_label: String = "Potvrda lozinke"
override val confirmation_title: String = "Jeste li sigurni?"
override val convert_to_invoice: String = "Pretvori u fakturu"
override val country_hint: String = "Zemlja"
override val create_client: String = "Stvori klijenta"
override val create_company: String = "Kreiraj tvrtku"
override val create_document: String = "Stvaranje dokumenta"
override val create_estimate: String = "Procjena"
override val create_invoice: String = "Faktura"
override val create_item: String = "Kreiraj stavku"
override val create_new_client: String = "Stvori novog klijenta"
override val create_new_item: String = "Stvaranje nove stavke"
override val create_new_tax: String = "Stvaranje novog poreza"
override val create_signature: String = "Potpis"
override val created: String = "Kreiran"
override val credit: String = "kredit"
override val customer: String = "Kupac"
override val date: String = "Datum"
override val debit: String = "zaduženje"
override val debt: String = "dug"
override val default_currency: Formattable = Formattable { (currency) -> "Zadana valuta: ${currency}" }
override val default_currency_label: String = "Zadana valuta"
override val delete: String = "Izbrisati"
override val delete_account: String = "Izbrisati Račun"
override val delete_confirmation_message: String = "Svi vaši podaci bit će izgubljeni."
override val delete_item_title: String = "Želite li izbrisati stavku?"
override val delete_signature: String = "Izbriši potpis"
override val depreciation: String = "amortizacija"
override val description: String = "Opis"
override val description_hint: String = "Opis"
override val detailed_report: String = "Detaljno Izvješće"
override val disabled: String = "Onemogućen"
override val discount: String = "Popust"
override val discount_hint: String = "Popust"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharski"
override val display_ar: String = "Arapski"
override val display_as: String = "Asamski"
override val display_az: String = "Azerbejdžanski"
override val display_be: String = "Bjeloruski"
override val display_bg: String = "Bugarski"
override val display_bn: String = "Bengalski"
override val display_bs: String = "Bosanski"
override val display_ca: String = "Katalonski"
override val display_cs: String = "Češki"
override val display_da: String = "Danski"
override val display_de: String = "Njemački"
override val display_el: String = "Grčki"
override val display_en: String = "Engleski"
override val display_es: String = "Španjolski"
override val display_es_MX: String = "Španjolski (Meksiko)"
override val display_et: String = "Estonski"
override val display_eu: String = "Baskijski"
override val display_fa: String = "Perzijski"
override val display_fi: String = "Finski"
override val display_fil: String = "Filipinski"
override val display_fr: String = "Francuski"
override val display_fr_CA: String = "Kanadski Francuski"
override val display_gl: String = "Galicijski"
override val display_gu: String = "Gudžaratski"
override val display_he: String = "Hebrejski"
override val display_hi: String = "Hindski"
override val display_hr: String = "Hrvatski"
override val display_hu: String = "Mađarski"
override val display_hy: String = "Armenski"
override val display_id: String = "Indonezijski"
override val display_is: String = "Islandski"
override val display_it: String = "Talijanski"
override val display_ja: String = "Japanski"
override val display_ka: String = "Gruzijski"
override val display_kk: String = "Kazaški"
override val display_km: String = "Središnji Crveni"
override val display_kn: String = "Kannadski"
override val display_ko: String = "Korejski"
override val display_ky: String = "Kirgizski"
override val display_lo: String = "Laoski"
override val display_lt: String = "Litvanski"
override val display_lv: String = "Latvijski"
override val display_mk: String = "Makedonski"
override val display_ml: String = "Malajamski"
override val display_mn: String = "Mongolski"
override val display_mr: String = "Marathski"
override val display_ms: String = "Malajski"
override val display_my: String = "Burmanski"
override val display_nb: String = "Norveški Bokmål"
override val display_ne: String = "Nepalski"
override val display_nl: String = "Nizozemski"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Poljski"
override val display_pt: String = "Portugalski"
override val display_pt_BR: String = "Portugalski (Brazil)"
override val display_pt_PT: String = "Europski Portugalski"
override val display_ro: String = "Rumunjski"
override val display_ru: String = "Ruski"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovački"
override val display_sl: String = "Slovenski"
override val display_sq: String = "Albanski"
override val display_sr: String = "Srpski"
override val display_sv: String = "Švedski"
override val display_sw: String = "Svahili"
override val display_ta: String = "Tamilski"
override val display_te: String = "Teluški"
override val display_th: String = "Tajlandski"
override val display_tr: String = "Turski"
override val display_uk: String = "Ukrajinski"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbekistanski"
override val display_vi: String = "Vijetnamski"
override val display_zh_Hant_TW: String = "Kineski (Tajvan, Tradicionalni)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Procjena"
override val document_invoice: String = "Faktura"
override val document_quote: String = "Ponuda"
override val done: String = "Gotovo"
override val download_app: String = "Preuzmite aplikaciju"
override val download_invoice: String = "Preuzmite Fakturu"
override val download_pdf: String = "Preuzmite PDF"
override val draw_signature: String = "Crtanje potpisa"
override val due: Formattable = Formattable { (date) -> "Krajnji rok ${date}" }
override val due_date: String = "Dospijeća"
override val due_day: String = "u jednom danu"
override val due_label: String = "Dug"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ima rok ${dueWhen}!" }
override val due_today: String = "danas"
override val due_week: String = "u tjedan dana"
override val duplicate: String = "Duplicirati"
override val duration_day: String = "dan"
override val duration_month: String = "Mjesec"
override val duration_week: String = "tjedan"
override val duration_year: String = "godina"
override val email: String = "E-pošta"
override val email_blank_error: String = "Potrebna je e-pošta."
override val email_error: String = "Adresa e-pošte nije valjana."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Hvala vam na poslovanju. Za prikaz punog ${documentType} kliknite gumb na kraju ovog pisma. Imajte na umu da vrijedi do ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Primili ste novi dokument: ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "POGLEDAJ ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Poštovani ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Hvala vam na poslovanju. Za prikaz punog ${documentType} kliknite gumb na kraju ovog pisma. Molimo vas da platite ostatak do ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Primili ste novi dokument: ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Pošaljite e-poštu za ponovno postavljanje lozinke, provjerite pristiglu poštu."
override val empty_clients_list_message: String = "Nemate klijenata za praćenje. Dodajte novog klijenta."
override val empty_estimate_list_message: String = "Izradite prvu procjenu"
override val empty_expenses: String = "Nemate nikakvih troškova za praćenje. Dodajte novi trošak."
override val empty_invoice_list_message: String = "Stvaranje prve fakture"
override val empty_items_list_message: String = "Nemate stavki, dodajte novu stavku!"
override val empty_signatures_list_message: String = "Potpisi nisu pronađeni. Napravite jedan!"
override val empty_taxes_list_message: String = "Nemate porezna pravila. Dodajte novo porezno pravilo."
override val empty_templates_list_message: String = "Predlošci nisu dostupni."
override val enabled: String = "Omogućeno"
override val entry: String = "ulaz"
override val equity: String = "kapital"
override val estimate_number: String = "Procjena #"
override val estimates: String = "Procjene"
override val estimates_report: String = "Procjene"
override val expense_bank_fees: String = "Bankovne naknade"
override val expense_benefits: String = "Prednosti"
override val expense_communication: String = "Komunikacija"
override val expense_debt: String = "Dug"
override val expense_deleted: String = "Trošak izbrisan"
override val expense_depreciation: String = "Amortizacija"
override val expense_gifts: String = "Pokloni"
override val expense_insurance: String = "Osiguranje"
override val expense_inventory: String = "Inventar"
override val expense_legal: String = "Pravno"
override val expense_maintenance: String = "Održavanje"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Obroci"
override val expense_office_supplies: String = "Uredski pribor"
override val expense_rent: String = "Najam"
override val expense_salaries: String = "Plaće"
override val expense_shopping: String = "Kupovina"
override val expense_software: String = "Softver"
override val expense_status_available: String = "Dostupno"
override val expense_status_processing: String = "Obrada"
override val expense_tax: String = "Porez"
override val expense_training: String = "Trening"
override val expense_transport: String = "Prijevoz"
override val expense_travel: String = "Putovati"
override val expense_utility: String = "Korisnost"
override val expenses: String = "Troškovi"
override val feedback_option: String = "Povratna informacija"
override val filter: String = "Filtar"
override val first_name_hint: String = "Ime"
override val forgot_password: String = "Vrati izvornu lozinku"
override val forgot_password_label: String = "Zaboravljena lozinka"
override val from: String = "Od"
override val general_error: String = "Došlo je do pogreške, molimo pokušajte ponovno kasnije."
override val general_ledger: String = "glavna knjiga"
override val go_back_label: String = "Vrati se"
override val group_by: String = "Grupiraj po:"
override val image_view: String = "Slika"
override val install: String = "Instaliraj"
override val invalid_credentials_error: String = "Vjerodajnice nisu valjane."
override val invalid_email_error: String = "E-pošta nije valjana."
override val invalid_user_error: String = "Korisničke vjerodajnice nisu valjane."
override val invoice_deleted_message: String = "Faktura je izbrisana"
override val invoice_menu: String = "Izbornik Faktura"
override val invoice_number: String = "Faktura #"
override val invoices: String = "Fakture"
override val items_option: String = "Predmeta"
override val keep: String = "Čuvati"
override val language_selector: Formattable = Formattable { (selected) -> "Jezik ${selected}" }
override val last_name_hint: String = "Prezime"
override val ledger: String = "knjiga"
override val legal: String = "Pravni"
override val legal_error: String = "Morate prihvatiti Uvjete pružanja usluge i Pravila o privatnosti"
override val liabilities: String = "obveze"
override val loading: String = "Učitavanje"
override val login_required_error: String = "Potrebna je prijava."
override val logo_view: String = "Logotip"
override val logout: String = "Odjava"
override val logout_prompt: String = "Podaci se ne sinkroniziraju s oblakom. Želite li izbrisati fakture, procjene, klijente i druge informacije o odjavi?"
override val main_screen: String = "Glavni zaslon"
override val mark_paid: String = "Označi kao plaćeno"
override val message: String = "Poruka"
override val missing_sender_msg: String = "Dokument bez podataka o pošiljatelju može biti nevažeći."
override val missing_sender_title: String = "Nedostaju podaci o pošiljatelju"
override val month_april: String = "Travanj"
override val month_april_short: String = "Travanj"
override val month_august: String = "Kolovoz"
override val month_august_short: String = "Kol."
override val month_december: String = "Prosinac"
override val month_december_short: String = "Pro."
override val month_february: String = "Veljača"
override val month_february_short: String = "Velj."
override val month_january: String = "Siječanj"
override val month_january_short: String = "Sij."
override val month_july: String = "Srpanj"
override val month_july_short: String = "Srpanj"
override val month_june: String = "Lipanj"
override val month_june_short: String = "Lipanj"
override val month_march: String = "Ožujak"
override val month_march_short: String = "Ožujak"
override val month_may: String = "Svibanj"
override val month_may_short: String = "Svibanj"
override val month_november: String = "Studeni"
override val month_november_short: String = "Stu."
override val month_october: String = "Listopad"
override val month_october_short: String = "Lis."
override val month_september: String = "Rujan"
override val month_september_short: String = "Ruj."
override val multifactor_error: String = "Provjera nije uspjela."
override val new: String = "Novi"
override val news: String = "Novosti"
override val next: String = "Sljedeći"
override val no: String = "ne"
override val no_data_available: String = "Nema dostupnih podataka"
override val no_search_results: String = "Žao mi je, nismo našli nikakve rezultate."
override val none: String = "Nijedan"
override val note_fields: String = "Bilješke"
override val note_hint: String = "Bilješka"
override val notifications_option: String = "Obavijesti"
override val ok: String = "Ok"
override val onboarding: String = "Ukrcavanje"
override val onboarding_aboutus_message: String = "Osnovni dijelovi DingVoicea zauvijek su besplatni. Naša misija je pomoći vam da razvijete svoje poslovanje, pomozite nam da se poboljšamo dijeljenjem vaših povratnih informacija."
override val onboarding_aboutus_title: String = "Ovdje smo da pomognemo"
override val onboarding_paid_1: String = "Pristupite predlošcima profesionalnog izgleda"
override val onboarding_paid_2: String = "Zavirite dublje u svoje financije"
override val onboarding_paid_3: String = "Brzo riješite probleme"
override val onboarding_paid_4: String = "Čuvajte svoje dokumente na sigurnom"
override val onboarding_paid_5: String = "Budite u tijeku s promjenama statusa računa"
override val onboarding_paid_title: String = "Rastite još brže uz..."
override val onboarding_paywall_message: String = "Uživajte u svim premium značajkama sada po najboljoj cijeni."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Samo ${currencySymbol}${introAmount} za ${introDuration} ${introPeriod}, a zatim ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Samo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Besplatno za ${trialDuration} ${trialPeriod}, a zatim samo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Besplatno za ${trialDuration} ${trialPeriod} zatim ${currencySymbol}${introductoryAmount} za ${introductoryDuration} ${introPeriod} i zatim samo ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automatski obnovljiv. Otkaži bilo kada."
override val onboarding_paywall_restore: String = "Obnovi kupnju"
override val onboarding_paywall_restore_failure: String = "Vraćanje kupnji nije uspjelo, pokušajte ponovno kasnije ili kontaktirajte podršku."
override val onboarding_paywall_restore_success: String = "Vraćanje uspješno!"
override val onboarding_paywall_title: String = "Postignite veličinu!"
override val onboarding_welcome_message: String = "Kompletan skup alata za upravljanje vašim fakturama i procjenama."
override val onboarding_welcome_title: String = "Dobro došli u DingVoice"
override val other_state: String = "Ostalo"
override val overdue_state: String = "Prošao rok"
override val paid_state: String = "Plaća"
override val password: String = "Lozinka"
override val password_blank_error: String = "Potrebna je lozinka."
override val password_mismatch_error: String = "Lozinke se ne podudaraju."
override val pay: String = "plati"
override val payment_instructions_label: String = "Upute za plaćanje"
override val payment_reminders: String = "Podsjetnici na plaćanje"
override val phone_number_fields: String = "Telefonski broj"
override val phone_number_hint: String = "Telefonski broj"
override val postcode_hint: String = "Poštanski broj"
override val preview: String = "Pregled"
override val preview_menu_hint: String = "Izbornik Pretpregled"
override val price: String = "Cijena"
override val privacy_policy: String = "Privatnosti"
override val profile: String = "Profil"
override val profitable_client: String = "Vaš najprofitabilniji klijent je..."
override val profits_report: String = "Dobit"
override val promo_content: String = "Promotivni sadržaj"
override val quantity_hint: String = "Količina"
override val quantity_label_hint: String = "Oznaka količine"
override val query_info: String = "Upit vam omogućuje pisanje prilagođenih izračuna koji se odnose na obje stavke i druga porezna pravila kao ulazne podatke. Dodaj @ simbol i stavku ili porezni opis za povezivanje s drugom stavkom +, -, *, /, % - matematički operatori"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} je primio vaš klijent." }
override val received_state: String = "Primljeno"
override val registration_code_fields: String = "Registracijski kod"
override val reminder: String = "Podsjetnik"
override val reports: String = "Izvješćima"
override val reports_not_available: String = "Nema dostupnih izvještaja, vratite se kasnije."
override val reveal_password: String = "Otkrij lozinku"
override val revenue: String = "prihod"
override val sales_tax: String = "PDV"
override val sales_tax_number: String = "Porezni broj"
override val save: String = "spasiti"
override val scan_document: String = "Skeniraj dokument"
override val search: String = "Pretraživanje"
override val select: String = "Izaberi"
override val select_icon: String = "Odaberite ikonu"
override val select_signature: String = "Odabir potpisa"
override val select_template: String = "Odabir predloška"
override val send: String = "slati"
override val sent_state: String = "Poslane"
override val setup_sender: String = "Postavite pošiljatelja"
override val share_option: String = "Dijeliti"
override val ship_to: String = "Brod na"
override val sign_in: String = "Prijavi se"
override val sign_in_apple: String = "Prijavite se pomoću Applea"
override val sign_in_email: String = "Prijava e-poštom"
override val sign_in_facebook: String = "Prijava putem Facebooka"
override val sign_in_google: String = "Prijava pomoću Googlea"
override val sign_up: String = "Prijavi se"
override val sign_up_label: String = "Nemate račun?"
override val sign_up_link_label: String = "Prijavi se!"
override val signatures_option: String = "Potpisa"
override val skip: String = "Preskočiti"
override val sold_items_report: String = "Prodani Predmeti"
override val sort_by: String = "Sortiraj po:"
override val status_update: String = "Ažuriranje statusa dokumenta"
override val sub_annual: String = "Godišnji"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/godina" }
override val sub_backups: String = "Sigurnosne kopije u oblaku"
override val sub_insights: String = "Uvidi"
override val sub_month: String = "Mjesečno"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mjesec" }
override val sub_notifications: String = "Obavijesti"
override val sub_popup_message: String = "Pretplate dolaze uskoro. Provjerite natrag u malo vremena."
override val sub_support: String = "Podrška"
override val sub_templates: String = "Predlošci"
override val subscribe: String = "Pretplatiti se"
override val subscription_header: String = "Povećajte svoje poslovanje pomoću:"
override val subscription_option: String = "Pretplata"
override val subscriptions_load_fail: String = "Nažalost, trenutačno ne možemo vratiti vaše pretplate. Molimo pokušajte ponovno kasnije ili kontaktirajte našu podršku za pomoć."
override val subtotal: Formattable = Formattable { (sum) -> "Podzbroj: ${sum}" }
override val subtotal_label: String = "Međuzbroj"
override val sum: String = "Suma"
override val support_option: String = "Podrška"
override val switch_reports: String = "Prebaci Izvješće"
override val tax: String = "Porez"
override val tax_deleted_message: String = "Porez izbrisan"
override val tax_description_warning: String = "Ako se koristi, prikazuje se na fakturi"
override val tax_input_flat: String = "Stan"
override val tax_input_percent: String = "Posto"
override val tax_number_fields: String = "Porezni broj"
override val tax_number_hint: String = "Porezni broj"
override val tax_query_info_description: String = "Informacije o upitu"
override val tax_report: String = "Porez"
override val tax_rules_option: String = "Porezna pravila"
override val taxed_clients_report: String = "Oporezeni Klijenti"
override val template: String = "Predložak"
override val template_selector_info: String = "Prelazak prstom za promjenu zadanog predloška"
override val template_unlock_promo: String = "Pristupite više predložaka uz Premium"
override val templates_option: String = "Predlošci"
override val terms_of_service: String = "Uvjeti pružanja usluge"
override val thank_you: String = "Hvala"
override val to: String = "Do"
override val total: Formattable = Formattable { (sum) -> "Ukupno: ${sum}" }
override val total_label: String = "Ukupno"
override val undo_action: String = "Poništiti"
override val unit: String = "Jedinica"
override val unit_cost_hint: String = "Jedinična cijena"
override val unknown_client: String = "Nepoznati Klijent"
override val unlock: String = "Otključaj"
override val unnamed_item: String = "Stavka bez naziva"
override val upgrade: String = "Nadograditi"
override val user_collision_error: String = "Račun već postoji."
override val valid_until: String = "Vrijedi Do"
override val warning: String = "Upozorenje!"
override val yes: String = "da"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Prihvatite ${termsOfService} i ${privacyPolicy}" },
	"account" to "račun",
	"accounting" to "računovodstvo",
	"accruals" to "obračunski troškovi",
	"action_code_error" to "Kod nije valjan.",
	"add_client_action" to "Dodaj klijenta",
	"add_customer" to "Dodaj kupca",
	"add_expense" to "Dodajte trošak",
	"add_fields_label" to "Dodati:",
	"add_invoice_action" to "Stvoriti",
	"add_item_action" to "Dodaj stavku",
	"add_item_button" to "Dodaj stavku",
	"add_signature_action" to "Dodaj potpis",
	"add_tax_action" to "Dodaj porez",
	"address_fields" to "Adresa",
	"address_line_hint" to "Redak adrese",
	"advanced" to "Napredan",
	"agree_marketing" to "Slažem se s primanjem izravnih marketinških komunikacija",
	"all_notifications" to "Sve obavijesti",
	"amortization" to "amortizacija",
	"amount" to "iznos",
	"asset" to "imovina",
	"attach_file" to "Priložite datoteku",
	"attachment" to "Prilog",
	"auth_error" to "Akcija nije uspjela, pokušajte ponovno.",
	"available_for_paid" to "Sinkronizacija u oblaku dostupna je samo u okviru plaćene pretplate.",
	"backups_option" to "Sigurnosne kopije",
	"balance" to "saldo",
	"bank_account" to "Bankovni Račun",
	"bill_number" to "Broj računa",
	"cancel" to "otkazati",
	"cancel_trial" to "Otkažite u bilo koje vrijeme",
	"capital" to "kapital",
	"cash" to "gotovina",
	"categories" to "Kategorije",
	"category" to "Kategorija",
	"category_add" to "Dodaj kategoriju",
	"category_delete" to "Izbriši kategoriju",
	"category_name" to "Ime kategorije",
	"change_language" to Formattable { (language) -> "Jezik fakture: ${language}" },
	"change_language_label" to "Jezik Fakture",
	"change_template" to "Promjena predloška",
	"city_hint" to "Grad",
	"clear" to "jasan",
	"client" to "Klijent",
	"client_actions" to "Klijentske akcije",
	"client_deleted_message" to "Klijent je izbrisan",
	"clients_error_required_fields" to "Potrebna je barem jedna vrijednost.",
	"clients_option" to "Klijentima",
	"close" to "blizak",
	"cloud_sync" to "Sinkronizacija u oblaku",
	"coming_soon" to "Dolazim brzo!",
	"company_logo" to "Logotip tvrtke",
	"company_name_hint" to "Tvrtka",
	"confirm_password_label" to "Potvrda lozinke",
	"confirmation_title" to "Jeste li sigurni?",
	"convert_to_invoice" to "Pretvori u fakturu",
	"country_hint" to "Zemlja",
	"create_client" to "Stvori klijenta",
	"create_company" to "Kreiraj tvrtku",
	"create_document" to "Stvaranje dokumenta",
	"create_estimate" to "Procjena",
	"create_invoice" to "Faktura",
	"create_item" to "Kreiraj stavku",
	"create_new_client" to "Stvori novog klijenta",
	"create_new_item" to "Stvaranje nove stavke",
	"create_new_tax" to "Stvaranje novog poreza",
	"create_signature" to "Potpis",
	"created" to "Kreiran",
	"credit" to "kredit",
	"customer" to "Kupac",
	"date" to "Datum",
	"debit" to "zaduženje",
	"debt" to "dug",
	"default_currency" to Formattable { (currency) -> "Zadana valuta: ${currency}" },
	"default_currency_label" to "Zadana valuta",
	"delete" to "Izbrisati",
	"delete_account" to "Izbrisati Račun",
	"delete_confirmation_message" to "Svi vaši podaci bit će izgubljeni.",
	"delete_item_title" to "Želite li izbrisati stavku?",
	"delete_signature" to "Izbriši potpis",
	"depreciation" to "amortizacija",
	"description" to "Opis",
	"description_hint" to "Opis",
	"detailed_report" to "Detaljno Izvješće",
	"disabled" to "Onemogućen",
	"discount" to "Popust",
	"discount_hint" to "Popust",
	"display_af" to "Afrikaans",
	"display_am" to "Amharski",
	"display_ar" to "Arapski",
	"display_as" to "Asamski",
	"display_az" to "Azerbejdžanski",
	"display_be" to "Bjeloruski",
	"display_bg" to "Bugarski",
	"display_bn" to "Bengalski",
	"display_bs" to "Bosanski",
	"display_ca" to "Katalonski",
	"display_cs" to "Češki",
	"display_da" to "Danski",
	"display_de" to "Njemački",
	"display_el" to "Grčki",
	"display_en" to "Engleski",
	"display_es" to "Španjolski",
	"display_es_MX" to "Španjolski (Meksiko)",
	"display_et" to "Estonski",
	"display_eu" to "Baskijski",
	"display_fa" to "Perzijski",
	"display_fi" to "Finski",
	"display_fil" to "Filipinski",
	"display_fr" to "Francuski",
	"display_fr_CA" to "Kanadski Francuski",
	"display_gl" to "Galicijski",
	"display_gu" to "Gudžaratski",
	"display_he" to "Hebrejski",
	"display_hi" to "Hindski",
	"display_hr" to "Hrvatski",
	"display_hu" to "Mađarski",
	"display_hy" to "Armenski",
	"display_id" to "Indonezijski",
	"display_is" to "Islandski",
	"display_it" to "Talijanski",
	"display_ja" to "Japanski",
	"display_ka" to "Gruzijski",
	"display_kk" to "Kazaški",
	"display_km" to "Središnji Crveni",
	"display_kn" to "Kannadski",
	"display_ko" to "Korejski",
	"display_ky" to "Kirgizski",
	"display_lo" to "Laoski",
	"display_lt" to "Litvanski",
	"display_lv" to "Latvijski",
	"display_mk" to "Makedonski",
	"display_ml" to "Malajamski",
	"display_mn" to "Mongolski",
	"display_mr" to "Marathski",
	"display_ms" to "Malajski",
	"display_my" to "Burmanski",
	"display_nb" to "Norveški Bokmål",
	"display_ne" to "Nepalski",
	"display_nl" to "Nizozemski",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Poljski",
	"display_pt" to "Portugalski",
	"display_pt_BR" to "Portugalski (Brazil)",
	"display_pt_PT" to "Europski Portugalski",
	"display_ro" to "Rumunjski",
	"display_ru" to "Ruski",
	"display_si" to "Sinhala",
	"display_sk" to "Slovački",
	"display_sl" to "Slovenski",
	"display_sq" to "Albanski",
	"display_sr" to "Srpski",
	"display_sv" to "Švedski",
	"display_sw" to "Svahili",
	"display_ta" to "Tamilski",
	"display_te" to "Teluški",
	"display_th" to "Tajlandski",
	"display_tr" to "Turski",
	"display_uk" to "Ukrajinski",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbekistanski",
	"display_vi" to "Vijetnamski",
	"display_zh_Hant_TW" to "Kineski (Tajvan, Tradicionalni)",
	"display_zu" to "Zulu",
	"document_estimate" to "Procjena",
	"document_invoice" to "Faktura",
	"document_quote" to "Ponuda",
	"done" to "Gotovo",
	"download_app" to "Preuzmite aplikaciju",
	"download_invoice" to "Preuzmite Fakturu",
	"download_pdf" to "Preuzmite PDF",
	"draw_signature" to "Crtanje potpisa",
	"due" to Formattable { (date) -> "Krajnji rok ${date}" },
	"due_date" to "Dospijeća",
	"due_day" to "u jednom danu",
	"due_label" to "Dug",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} ima rok ${dueWhen}!" },
	"due_today" to "danas",
	"due_week" to "u tjedan dana",
	"duplicate" to "Duplicirati",
	"duration_day" to "dan",
	"duration_month" to "Mjesec",
	"duration_week" to "tjedan",
	"duration_year" to "godina",
	"email" to "E-pošta",
	"email_blank_error" to "Potrebna je e-pošta.",
	"email_error" to "Adresa e-pošte nije valjana.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Hvala vam na poslovanju. Za prikaz punog ${documentType} kliknite gumb na kraju ovog pisma. Imajte na umu da vrijedi do ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Primili ste novi dokument: ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "POGLEDAJ ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Poštovani ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Hvala vam na poslovanju. Za prikaz punog ${documentType} kliknite gumb na kraju ovog pisma. Molimo vas da platite ostatak do ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Primili ste novi dokument: ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Pošaljite e-poštu za ponovno postavljanje lozinke, provjerite pristiglu poštu.",
	"empty_clients_list_message" to "Nemate klijenata za praćenje. Dodajte novog klijenta.",
	"empty_estimate_list_message" to "Izradite prvu procjenu",
	"empty_expenses" to "Nemate nikakvih troškova za praćenje. Dodajte novi trošak.",
	"empty_invoice_list_message" to "Stvaranje prve fakture",
	"empty_items_list_message" to "Nemate stavki, dodajte novu stavku!",
	"empty_signatures_list_message" to "Potpisi nisu pronađeni. Napravite jedan!",
	"empty_taxes_list_message" to "Nemate porezna pravila. Dodajte novo porezno pravilo.",
	"empty_templates_list_message" to "Predlošci nisu dostupni.",
	"enabled" to "Omogućeno",
	"entry" to "ulaz",
	"equity" to "kapital",
	"estimate_number" to "Procjena #",
	"estimates" to "Procjene",
	"estimates_report" to "Procjene",
	"expense_bank_fees" to "Bankovne naknade",
	"expense_benefits" to "Prednosti",
	"expense_communication" to "Komunikacija",
	"expense_debt" to "Dug",
	"expense_deleted" to "Trošak izbrisan",
	"expense_depreciation" to "Amortizacija",
	"expense_gifts" to "Pokloni",
	"expense_insurance" to "Osiguranje",
	"expense_inventory" to "Inventar",
	"expense_legal" to "Pravno",
	"expense_maintenance" to "Održavanje",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Obroci",
	"expense_office_supplies" to "Uredski pribor",
	"expense_rent" to "Najam",
	"expense_salaries" to "Plaće",
	"expense_shopping" to "Kupovina",
	"expense_software" to "Softver",
	"expense_status_available" to "Dostupno",
	"expense_status_processing" to "Obrada",
	"expense_tax" to "Porez",
	"expense_training" to "Trening",
	"expense_transport" to "Prijevoz",
	"expense_travel" to "Putovati",
	"expense_utility" to "Korisnost",
	"expenses" to "Troškovi",
	"feedback_option" to "Povratna informacija",
	"filter" to "Filtar",
	"first_name_hint" to "Ime",
	"forgot_password" to "Vrati izvornu lozinku",
	"forgot_password_label" to "Zaboravljena lozinka",
	"from" to "Od",
	"general_error" to "Došlo je do pogreške, molimo pokušajte ponovno kasnije.",
	"general_ledger" to "glavna knjiga",
	"go_back_label" to "Vrati se",
	"group_by" to "Grupiraj po:",
	"image_view" to "Slika",
	"install" to "Instaliraj",
	"invalid_credentials_error" to "Vjerodajnice nisu valjane.",
	"invalid_email_error" to "E-pošta nije valjana.",
	"invalid_user_error" to "Korisničke vjerodajnice nisu valjane.",
	"invoice_deleted_message" to "Faktura je izbrisana",
	"invoice_menu" to "Izbornik Faktura",
	"invoice_number" to "Faktura #",
	"invoices" to "Fakture",
	"items_option" to "Predmeta",
	"keep" to "Čuvati",
	"language_selector" to Formattable { (selected) -> "Jezik ${selected}" },
	"last_name_hint" to "Prezime",
	"ledger" to "knjiga",
	"legal" to "Pravni",
	"legal_error" to "Morate prihvatiti Uvjete pružanja usluge i Pravila o privatnosti",
	"liabilities" to "obveze",
	"loading" to "Učitavanje",
	"login_required_error" to "Potrebna je prijava.",
	"logo_view" to "Logotip",
	"logout" to "Odjava",
	"logout_prompt" to "Podaci se ne sinkroniziraju s oblakom. Želite li izbrisati fakture, procjene, klijente i druge informacije o odjavi?",
	"main_screen" to "Glavni zaslon",
	"mark_paid" to "Označi kao plaćeno",
	"message" to "Poruka",
	"missing_sender_msg" to "Dokument bez podataka o pošiljatelju može biti nevažeći.",
	"missing_sender_title" to "Nedostaju podaci o pošiljatelju",
	"month_april" to "Travanj",
	"month_april_short" to "Travanj",
	"month_august" to "Kolovoz",
	"month_august_short" to "Kol.",
	"month_december" to "Prosinac",
	"month_december_short" to "Pro.",
	"month_february" to "Veljača",
	"month_february_short" to "Velj.",
	"month_january" to "Siječanj",
	"month_january_short" to "Sij.",
	"month_july" to "Srpanj",
	"month_july_short" to "Srpanj",
	"month_june" to "Lipanj",
	"month_june_short" to "Lipanj",
	"month_march" to "Ožujak",
	"month_march_short" to "Ožujak",
	"month_may" to "Svibanj",
	"month_may_short" to "Svibanj",
	"month_november" to "Studeni",
	"month_november_short" to "Stu.",
	"month_october" to "Listopad",
	"month_october_short" to "Lis.",
	"month_september" to "Rujan",
	"month_september_short" to "Ruj.",
	"multifactor_error" to "Provjera nije uspjela.",
	"new" to "Novi",
	"news" to "Novosti",
	"next" to "Sljedeći",
	"no" to "ne",
	"no_data_available" to "Nema dostupnih podataka",
	"no_search_results" to "Žao mi je, nismo našli nikakve rezultate.",
	"none" to "Nijedan",
	"note_fields" to "Bilješke",
	"note_hint" to "Bilješka",
	"notifications_option" to "Obavijesti",
	"ok" to "Ok",
	"onboarding" to "Ukrcavanje",
	"onboarding_aboutus_message" to "Osnovni dijelovi DingVoicea zauvijek su besplatni. Naša misija je pomoći vam da razvijete svoje poslovanje, pomozite nam da se poboljšamo dijeljenjem vaših povratnih informacija.",
	"onboarding_aboutus_title" to "Ovdje smo da pomognemo",
	"onboarding_paid_1" to "Pristupite predlošcima profesionalnog izgleda",
	"onboarding_paid_2" to "Zavirite dublje u svoje financije",
	"onboarding_paid_3" to "Brzo riješite probleme",
	"onboarding_paid_4" to "Čuvajte svoje dokumente na sigurnom",
	"onboarding_paid_5" to "Budite u tijeku s promjenama statusa računa",
	"onboarding_paid_title" to "Rastite još brže uz...",
	"onboarding_paywall_message" to "Uživajte u svim premium značajkama sada po najboljoj cijeni.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Samo ${currencySymbol}${introAmount} za ${introDuration} ${introPeriod}, a zatim ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Samo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Besplatno za ${trialDuration} ${trialPeriod}, a zatim samo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Besplatno za ${trialDuration} ${trialPeriod} zatim ${currencySymbol}${introductoryAmount} za ${introductoryDuration} ${introPeriod} i zatim samo ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automatski obnovljiv. Otkaži bilo kada.",
	"onboarding_paywall_restore" to "Obnovi kupnju",
	"onboarding_paywall_restore_failure" to "Vraćanje kupnji nije uspjelo, pokušajte ponovno kasnije ili kontaktirajte podršku.",
	"onboarding_paywall_restore_success" to "Vraćanje uspješno!",
	"onboarding_paywall_title" to "Postignite veličinu!",
	"onboarding_welcome_message" to "Kompletan skup alata za upravljanje vašim fakturama i procjenama.",
	"onboarding_welcome_title" to "Dobro došli u DingVoice",
	"other_state" to "Ostalo",
	"overdue_state" to "Prošao rok",
	"paid_state" to "Plaća",
	"password" to "Lozinka",
	"password_blank_error" to "Potrebna je lozinka.",
	"password_mismatch_error" to "Lozinke se ne podudaraju.",
	"pay" to "plati",
	"payment_instructions_label" to "Upute za plaćanje",
	"payment_reminders" to "Podsjetnici na plaćanje",
	"phone_number_fields" to "Telefonski broj",
	"phone_number_hint" to "Telefonski broj",
	"postcode_hint" to "Poštanski broj",
	"preview" to "Pregled",
	"preview_menu_hint" to "Izbornik Pretpregled",
	"price" to "Cijena",
	"privacy_policy" to "Privatnosti",
	"profile" to "Profil",
	"profitable_client" to "Vaš najprofitabilniji klijent je...",
	"profits_report" to "Dobit",
	"promo_content" to "Promotivni sadržaj",
	"quantity_hint" to "Količina",
	"quantity_label_hint" to "Oznaka količine",
	"query_info" to "Upit vam omogućuje pisanje prilagođenih izračuna koji se odnose na obje stavke i druga porezna pravila kao ulazne podatke. Dodaj @ simbol i stavku ili porezni opis za povezivanje s drugom stavkom +, -, *, /, % - matematički operatori",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} je primio vaš klijent." },
	"received_state" to "Primljeno",
	"registration_code_fields" to "Registracijski kod",
	"reminder" to "Podsjetnik",
	"reports" to "Izvješćima",
	"reports_not_available" to "Nema dostupnih izvještaja, vratite se kasnije.",
	"reveal_password" to "Otkrij lozinku",
	"revenue" to "prihod",
	"sales_tax" to "PDV",
	"sales_tax_number" to "Porezni broj",
	"save" to "spasiti",
	"scan_document" to "Skeniraj dokument",
	"search" to "Pretraživanje",
	"select" to "Izaberi",
	"select_icon" to "Odaberite ikonu",
	"select_signature" to "Odabir potpisa",
	"select_template" to "Odabir predloška",
	"send" to "slati",
	"sent_state" to "Poslane",
	"setup_sender" to "Postavite pošiljatelja",
	"share_option" to "Dijeliti",
	"ship_to" to "Brod na",
	"sign_in" to "Prijavi se",
	"sign_in_apple" to "Prijavite se pomoću Applea",
	"sign_in_email" to "Prijava e-poštom",
	"sign_in_facebook" to "Prijava putem Facebooka",
	"sign_in_google" to "Prijava pomoću Googlea",
	"sign_up" to "Prijavi se",
	"sign_up_label" to "Nemate račun?",
	"sign_up_link_label" to "Prijavi se!",
	"signatures_option" to "Potpisa",
	"skip" to "Preskočiti",
	"sold_items_report" to "Prodani Predmeti",
	"sort_by" to "Sortiraj po:",
	"status_update" to "Ažuriranje statusa dokumenta",
	"sub_annual" to "Godišnji",
	"sub_annual_price" to Formattable { (price) -> "${price}/godina" },
	"sub_backups" to "Sigurnosne kopije u oblaku",
	"sub_insights" to "Uvidi",
	"sub_month" to "Mjesečno",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mjesec" },
	"sub_notifications" to "Obavijesti",
	"sub_popup_message" to "Pretplate dolaze uskoro. Provjerite natrag u malo vremena.",
	"sub_support" to "Podrška",
	"sub_templates" to "Predlošci",
	"subscribe" to "Pretplatiti se",
	"subscription_header" to "Povećajte svoje poslovanje pomoću:",
	"subscription_option" to "Pretplata",
	"subscriptions_load_fail" to "Nažalost, trenutačno ne možemo vratiti vaše pretplate. Molimo pokušajte ponovno kasnije ili kontaktirajte našu podršku za pomoć.",
	"subtotal" to Formattable { (sum) -> "Podzbroj: ${sum}" },
	"subtotal_label" to "Međuzbroj",
	"sum" to "Suma",
	"support_option" to "Podrška",
	"switch_reports" to "Prebaci Izvješće",
	"tax" to "Porez",
	"tax_deleted_message" to "Porez izbrisan",
	"tax_description_warning" to "Ako se koristi, prikazuje se na fakturi",
	"tax_input_flat" to "Stan",
	"tax_input_percent" to "Posto",
	"tax_number_fields" to "Porezni broj",
	"tax_number_hint" to "Porezni broj",
	"tax_query_info_description" to "Informacije o upitu",
	"tax_report" to "Porez",
	"tax_rules_option" to "Porezna pravila",
	"taxed_clients_report" to "Oporezeni Klijenti",
	"template" to "Predložak",
	"template_selector_info" to "Prelazak prstom za promjenu zadanog predloška",
	"template_unlock_promo" to "Pristupite više predložaka uz Premium",
	"templates_option" to "Predlošci",
	"terms_of_service" to "Uvjeti pružanja usluge",
	"thank_you" to "Hvala",
	"to" to "Do",
	"total" to Formattable { (sum) -> "Ukupno: ${sum}" },
	"total_label" to "Ukupno",
	"undo_action" to "Poništiti",
	"unit" to "Jedinica",
	"unit_cost_hint" to "Jedinična cijena",
	"unknown_client" to "Nepoznati Klijent",
	"unlock" to "Otključaj",
	"unnamed_item" to "Stavka bez naziva",
	"upgrade" to "Nadograditi",
	"user_collision_error" to "Račun već postoji.",
	"valid_until" to "Vrijedi Do",
	"warning" to "Upozorenje!",
	"yes" to "da",
    )
    
}