// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Mn  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} болон ${privacyPolicy}-г зөвшөөрөх" }
override val account: String = "account"
override val accounting: String = "accounting"
override val accruals: String = "accruals"
override val action_code_error: String = "Буруу код."
override val add_client_action: String = "Үйлчлүүлэгч нэмэх"
override val add_customer: String = "Үйлчлүүлэгч нэмэх"
override val add_expense: String = "Зардал нэмнэ үү"
override val add_fields_label: String = "Нэмэх:"
override val add_invoice_action: String = "Үүсгэ"
override val add_item_action: String = "Зүйл нэмэх"
override val add_item_button: String = "Зүйл нэмэх"
override val add_signature_action: String = "Гарын үсэг нэмнэ үү"
override val add_tax_action: String = "Татвар нэмнэ"
override val address_fields: String = "Хаяг"
override val address_line_hint: String = "Хаягийн шугам"
override val advanced: String = "Дэвшилтэт"
override val agree_marketing: String = "Би шууд маркетингийн харилцааг хүлээн авахыг зөвшөөрч байна"
override val all_notifications: String = "Бүх мэдэгдэл"
override val amortization: String = "amortization"
override val amount: String = "Дүн"
override val asset: String = "asset"
override val attach_file: String = "Файл хавсаргах"
override val attachment: String = "Хавсралт"
override val auth_error: String = "Үйлдэл амжилтгүй боллоо, дахин оролдоно уу."
override val available_for_paid: String = "Клоуд синкийг зөвхөн төлбөртэй захиалгаар ашиглах боломжтой."
override val backups_option: String = "Нөөцлөлт"
override val balance: String = "balance"
override val bank_account: String = "Банкны данс"
override val bill_number: String = "Тооцооны дугаар"
override val cancel: String = "Цуцлах"
override val cancel_trial: String = "Cancel at any time"
override val capital: String = "capital"
override val cash: String = "cash"
override val categories: String = "Ангилал"
override val category: String = "Ангилал"
override val category_add: String = "Ангилал нэмэх"
override val category_delete: String = "Ангилал устгах"
override val category_name: String = "Ангиллын нэр"
override val change_language: Formattable = Formattable { (language) -> "Нэхэмжлэхийн хэл: ${language}" }
override val change_language_label: String = "Нэхэмжлэхийн хэл"
override val change_template: String = "Загварыг өөрчлөх"
override val city_hint: String = "Хот"
override val clear: String = "Тодорхой"
override val client: String = "Үйлчлүүлэгч"
override val client_actions: String = "Үйлчлүүлэгчийн үйлдэл"
override val client_deleted_message: String = "Үйлчлүүлэгчийг устгасан"
override val clients_error_required_fields: String = "Дор хаяж нэг утга шаардлагатай."
override val clients_option: String = "Үйлчлүүлэгчид"
override val close: String = "Хаах"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "Тун удахгүй!"
override val company_logo: String = "Компанийн лого"
override val company_name_hint: String = "Компани"
override val confirm_password_label: String = "Нууц үгээ баталгаажуулна уу"
override val confirmation_title: String = "Чи итгэлтэй байна уу?"
override val convert_to_invoice: String = "Нэхэмжлэх болгон хөрвүүлэх"
override val country_hint: String = "Улс"
override val create_client: String = "Үйлчлүүлэгч үүсгэх"
override val create_company: String = "Компани үүсгэх"
override val create_document: String = "Баримт бичиг үүсгэх"
override val create_estimate: String = "Тооцоолох"
override val create_invoice: String = "Нэхэмжлэх"
override val create_item: String = "Зүйл үүсгэх"
override val create_new_client: String = "Шинэ үйлчлүүлэгч үүсгэх"
override val create_new_item: String = "Шинэ зүйл үүсгэх"
override val create_new_tax: String = "Шинэ татвар бий болгох"
override val create_signature: String = "Гарын үсэг"
override val created: String = "Үүсгэсэн"
override val credit: String = "credit"
override val customer: String = "Хэрэглэгч"
override val date: String = "Огноо"
override val debit: String = "debit"
override val debt: String = "debt"
override val default_currency: Formattable = Formattable { (currency) -> "Өгөгдмөл мөнгөн тэмдэгт: ${currency}" }
override val default_currency_label: String = "Өгөгдмөл валют"
override val delete: String = "устгах"
override val delete_account: String = "Бүртгэл устгах"
override val delete_confirmation_message: String = "Таны бүх өгөгдөл устах болно."
override val delete_item_title: String = "Тухайн зүйлийг устгах уу?"
override val delete_signature: String = "Гарын үсгийг устгах"
override val depreciation: String = "depreciation"
override val description: String = "Тодорхойлолт"
override val description_hint: String = "Тодорхойлолт"
override val detailed_report: String = "Нарийвчилсан тайлан"
override val disabled: String = "Хөгжлийн бэрхшээлтэй"
override val discount: String = "Хөнгөлөлт"
override val discount_hint: String = "Хөнгөлөлт"
override val display_af: String = "Африк"
override val display_am: String = "Амхар"
override val display_ar: String = "Араб"
override val display_as: String = "Ассам"
override val display_az: String = "Азербайжан"
override val display_be: String = "Беларусь"
override val display_bg: String = "Болгар"
override val display_bn: String = "Бенгал"
override val display_bs: String = "Босни"
override val display_ca: String = "каталон"
override val display_cs: String = "чех"
override val display_da: String = "Дани"
override val display_de: String = "Герман"
override val display_el: String = "Грек"
override val display_en: String = "Англи"
override val display_es: String = "Испани"
override val display_es_MX: String = "Испани (Мексик)"
override val display_et: String = "Эстони"
override val display_eu: String = "Баск"
override val display_fa: String = "Перс"
override val display_fi: String = "Финланд"
override val display_fil: String = "Филиппин"
override val display_fr: String = "Франц"
override val display_fr_CA: String = "Канадын франц"
override val display_gl: String = "Галис"
override val display_gu: String = "Гужарати"
override val display_he: String = "Еврей"
override val display_hi: String = "Үгүй ээ"
override val display_hr: String = "Хорват"
override val display_hu: String = "Унгар"
override val display_hy: String = "армян"
override val display_id: String = "Индонез"
override val display_is: String = "Исланд"
override val display_it: String = "итали"
override val display_ja: String = "Япон"
override val display_ka: String = "Гүрж"
override val display_kk: String = "Казак"
override val display_km: String = "Төв Кхмер"
override val display_kn: String = "Каннада"
override val display_ko: String = "Солонгос"
override val display_ky: String = "Киргиз"
override val display_lo: String = "Хөдөлмөр"
override val display_lt: String = "Литва"
override val display_lv: String = "Латви"
override val display_mk: String = "македон"
override val display_ml: String = "Малайлам"
override val display_mn: String = "Монгол"
override val display_mr: String = "Марати"
override val display_ms: String = "малай"
override val display_my: String = "Бирм"
override val display_nb: String = "Норвегийн бокмал"
override val display_ne: String = "Непал"
override val display_nl: String = "Голланд"
override val display_or: String = "Ория"
override val display_pa: String = "Пунжаби"
override val display_pl: String = "Польш"
override val display_pt: String = "португал"
override val display_pt_BR: String = "Португали (Бразил)"
override val display_pt_PT: String = "Европын португал хэл"
override val display_ro: String = "румын"
override val display_ru: String = "орос"
override val display_si: String = "Синхал"
override val display_sk: String = "словак"
override val display_sl: String = "Словен"
override val display_sq: String = "Албани"
override val display_sr: String = "Серб"
override val display_sv: String = "швед"
override val display_sw: String = "Суахили"
override val display_ta: String = "Тамил"
override val display_te: String = "Тэлугу"
override val display_th: String = "Тайланд"
override val display_tr: String = "турк"
override val display_uk: String = "украин"
override val display_ur: String = "Урду"
override val display_uz: String = "узбек"
override val display_vi: String = "вьетнам"
override val display_zh_Hant_TW: String = "Хятад (Тайвань, Уламжлалт)"
override val display_zu: String = "Зулу"
override val document_estimate: String = "Тооцоолох"
override val document_invoice: String = "Нэхэмжлэх"
override val document_quote: String = "Иш татах"
override val done: String = "Дууслаа"
override val download_app: String = "Апп татах"
override val download_invoice: String = "Нэхэмжлэхийг татаж авах"
override val download_pdf: String = "PDF татаж авах"
override val draw_signature: String = "Гарын үсэг зурах"
override val due: Formattable = Formattable { (date) -> "Хугацаа ${date}" }
override val due_date: String = "Дуусах хугацаа"
override val due_day: String = "нэг өдрийн дотор"
override val due_label: String = "Хугацаа нь"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} хугацаа ${dueWhen} байна!" }
override val due_today: String = "Өнөөдөр"
override val due_week: String = "долоо хоногт"
override val duplicate: String = "Хуулбарлах"
override val duration_day: String = "өдөр"
override val duration_month: String = "Сар"
override val duration_week: String = "долоо хоног"
override val duration_year: String = "жил"
override val email: String = "Имэйл"
override val email_blank_error: String = "Имэйл шаардлагатай."
override val email_error: String = "Хүчингүй мэйл хаяг."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Таны бизнест баярлалаа. ${documentType}-г бүтэн үзэхийн тулд энэ захидлын төгсгөлд байгаа товчийг дарна уу. Энэ нь ${dueDate} хүртэл хүчинтэй гэдгийг анхаарна уу." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Та шинэ хүлээн авсан байна${documentType}${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "${documentType}-г ХАРАХ" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Эрхэм ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Таны бизнест баярлалаа. ${documentType}-г бүтэн үзэхийн тулд энэ захидлын төгсгөлд байгаа товчийг дарна уу. Үлдэгдэл төлбөрийг ${dueDate} хүртэл төлнө үү." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Та шинэ хүлээн авсан байна${documentType}${invoiceNumber} !" }
override val email_reset_success_message: String = "Нууц үг солих имэйл илгээсэн тул ирсэн имэйлээ шалгана уу."
override val empty_clients_list_message: String = "Танд хянах үйлчлүүлэгч байхгүй байна. Шинэ үйлчлүүлэгч нэмнэ үү."
override val empty_estimate_list_message: String = "Эхний тооцоогоо гарга"
override val empty_expenses: String = "Танд хянах зардал байхгүй. Шинэ зардал нэмнэ үү."
override val empty_invoice_list_message: String = "Эхний нэхэмжлэхээ үүсгэ"
override val empty_items_list_message: String = "Танд ямар ч зүйл байхгүй, шинэ зүйл нэмнэ үү!"
override val empty_signatures_list_message: String = "Гарын үсэг олдсонгүй. Нэгийг үүсгэ!"
override val empty_taxes_list_message: String = "Танд татварын дүрэм байхгүй. Татварын шинэ дүрэм нэмнэ үү."
override val empty_templates_list_message: String = "Загваруудыг ашиглах боломжгүй."
override val enabled: String = "Идэвхжсэн"
override val entry: String = "entry"
override val equity: String = "equity"
override val estimate_number: String = "Тооцоолол #"
override val estimates: String = "Тооцоолол"
override val estimates_report: String = "Тооцоолол"
override val expense_bank_fees: String = "Банкны шимтгэл"
override val expense_benefits: String = "Ашиг тус"
override val expense_communication: String = "Харилцаа холбоо"
override val expense_debt: String = "Өр"
override val expense_deleted: String = "Зардлыг устгасан"
override val expense_depreciation: String = "Элэгдэл"
override val expense_gifts: String = "Бэлэг"
override val expense_insurance: String = "Даатгал"
override val expense_inventory: String = "Бараа материал"
override val expense_legal: String = "Хууль эрх зүйн"
override val expense_maintenance: String = "Засвар үйлчилгээ"
override val expense_marketing: String = "Маркетинг"
override val expense_meals: String = "Хоол"
override val expense_office_supplies: String = "Албан тасалгааны хангамж"
override val expense_rent: String = "Түрээс"
override val expense_salaries: String = "Цалин"
override val expense_shopping: String = "Дэлгүүр хэсэх"
override val expense_software: String = "Програм хангамж"
override val expense_status_available: String = "Боломжтой"
override val expense_status_processing: String = "Боловсруулж байна"
override val expense_tax: String = "Татвар"
override val expense_training: String = "Сургалт"
override val expense_transport: String = "Тээвэрлэлт"
override val expense_travel: String = "Аялал"
override val expense_utility: String = "Хэрэгсэл"
override val expenses: String = "Зардал"
override val feedback_option: String = "Санал хүсэлт"
override val filter: String = "Шүүлтүүр"
override val first_name_hint: String = "Нэр"
override val forgot_password: String = "Нууц үгээ анхны байдалд нь оруулж"
override val forgot_password_label: String = "Нууц үгээ мартсан"
override val from: String = "Хаанаас"
override val general_error: String = "Алдаа гарлаа, дараа дахин оролдоно уу."
override val general_ledger: String = "general ledger"
override val go_back_label: String = "Буцах"
override val group_by: String = "Бүлэглэсэн:"
override val image_view: String = "Зураг"
override val install: String = "Суулгах"
override val invalid_credentials_error: String = "Хүчингүй итгэмжлэл."
override val invalid_email_error: String = "Хүчингүй и-мэйл."
override val invalid_user_error: String = "Хэрэглэгчийн итгэмжлэл буруу байна."
override val invoice_deleted_message: String = "Нэхэмжлэхийг устгасан"
override val invoice_menu: String = "Нэхэмжлэлийн цэс"
override val invoice_number: String = "Нэхэмжлэх #"
override val invoices: String = "Нэхэмжлэх"
override val items_option: String = "Эд зүйлс"
override val keep: String = "Хадгалах"
override val language_selector: Formattable = Formattable { (сонгосон) -> "${сонгосон} хэл" }
override val last_name_hint: String = "Овог"
override val ledger: String = "ledger"
override val legal: String = "Хууль эрх зүйн"
override val legal_error: String = "Үйлчилгээний нөхцөл болон Нууцлалын бодлогыг хүлээн зөвшөөрөх ёстой"
override val liabilities: String = "liabilities"
override val loading: String = "Ачааж байна"
override val login_required_error: String = "Нэвтрэх шаардлагатай."
override val logo_view: String = "Лого"
override val logout: String = "Гарах"
override val logout_prompt: String = "Таны өгөгдлийг клоудтай синхрончлоогүй байна. Гарах үед нэхэмжлэх, тооцоо, үйлчлүүлэгч болон бусад мэдээллийг устгах уу?"
override val main_screen: String = "Үндсэн дэлгэц"
override val mark_paid: String = "Төлбөртэй гэж тэмдэглэ"
override val message: String = "Захиа"
override val missing_sender_msg: String = "Илгээгчийн мэдээлэлгүй баримт бичиг хүчингүй байж магадгүй."
override val missing_sender_title: String = "Илгээгчийн мэдээлэл дутуу байна"
override val month_april: String = "Дөрөвдүгээр сар"
override val month_april_short: String = "Дөрөвдүгээр сар"
override val month_august: String = "Наймдугаар сар"
override val month_august_short: String = "Наймдугаар сар"
override val month_december: String = "Арванхоёрдугаар сар"
override val month_december_short: String = "12-р сар"
override val month_february: String = "2-р сар"
override val month_february_short: String = "Хоёрдугаар сар"
override val month_january: String = "1-р сар"
override val month_january_short: String = "1-р сар."
override val month_july: String = "7-р сар"
override val month_july_short: String = "7-р сар"
override val month_june: String = "6-р сар"
override val month_june_short: String = "6-р сар"
override val month_march: String = "3-р сар"
override val month_march_short: String = "3-р сар"
override val month_may: String = "5-р сар"
override val month_may_short: String = "5-р сар"
override val month_november: String = "Арваннэгдүгээр сар"
override val month_november_short: String = "11-р сар"
override val month_october: String = "Аравдугаар сар"
override val month_october_short: String = "Аравдугаар сар"
override val month_september: String = "9-р сар"
override val month_september_short: String = "9-р сар"
override val multifactor_error: String = "Баталгаажуулж чадсангүй."
override val new: String = "Шинэ"
override val news: String = "Мэдээ"
override val next: String = "Дараагийн"
override val no: String = "no"
override val no_data_available: String = "Өгөгдөл байхгүй байна"
override val no_search_results: String = "Уучлаарай, бид ямар ч илэрц олж чадсангүй."
override val none: String = "Байхгүй"
override val note_fields: String = "Тэмдэглэл"
override val note_hint: String = "Тэмдэглэл"
override val notifications_option: String = "Мэдэгдэл"
override val ok: String = "БОЛЖ БАЙНА УУ"
override val onboarding: String = "Нэвтрэх"
override val onboarding_aboutus_message: String = "DingVoice-ийн чухал хэсгүүд үүрд үнэ төлбөргүй байдаг. Бидний эрхэм зорилго бол таны бизнесийг өргөжүүлэх, санал хүсэлтээ хуваалцах замаар сайжруулахад туслах явдал юм."
override val onboarding_aboutus_title: String = "Бид туслахаар энд байна"
override val onboarding_paid_1: String = "Мэргэжлийн харагдах загваруудад хандах"
override val onboarding_paid_2: String = "Санхүүгээ илүү гүнзгий хараарай"
override val onboarding_paid_3: String = "Асуудлыг хурдан шийдээрэй"
override val onboarding_paid_4: String = "Баримт бичгээ аюулгүй байлгаарай"
override val onboarding_paid_5: String = "Нэхэмжлэлийн төлөвийн өөрчлөлтийг цаг тухайд нь авч байгаарай"
override val onboarding_paid_title: String = "Илүү хурдан өсөх..."
override val onboarding_paywall_message: String = "Дээд зэрэглэлийн бүх функцийг одоо хамгийн сайн үнээр ашиглаарай."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Зүгээр л ${currencySymbol}${introAmount} ${introDuration} ${introPeriod}, дараа нь ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Зүгээр л ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} үнэгүй, дараа нь ердөө ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} үнэ төлбөргүй, дараа нь ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}, дараа нь ердөө ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Автоматаар шинэчлэгдэх боломжтой. Хэзээ ч цуцлах."
override val onboarding_paywall_restore: String = "Худалдан авалтыг сэргээх"
override val onboarding_paywall_restore_failure: String = "Худалдан авалтыг сэргээж чадсангүй. Дараа дахин оролдох эсвэл тусламжтай холбогдоно уу."
override val onboarding_paywall_restore_success: String = "Амжилттай сэргээлээ!"
override val onboarding_paywall_title: String = "Агуу амжилтанд хүр!"
override val onboarding_welcome_message: String = "Нэхэмжлэх, тооцоогоо удирдах бүрэн хэрэгслийн багц."
override val onboarding_welcome_title: String = "DingVoice-д тавтай морил"
override val other_state: String = "Бусад"
override val overdue_state: String = "Хугацаа хэтэрсэн"
override val paid_state: String = "Төлбөртэй"
override val password: String = "Нууц үг"
override val password_blank_error: String = "Нууц үг шаардлагатай."
override val password_mismatch_error: String = "Нууц үг таарахгүй байна."
override val pay: String = "pay"
override val payment_instructions_label: String = "Төлбөрийн заавар"
override val payment_reminders: String = "Төлбөрийн сануулга"
override val phone_number_fields: String = "Утасны дугаар"
override val phone_number_hint: String = "Утасны дугаар"
override val postcode_hint: String = "Шуудангийн код"
override val preview: String = "Урьдчилан үзэх"
override val preview_menu_hint: String = "Урьдчилан харах цэс"
override val price: String = "Үнэ"
override val privacy_policy: String = "Нууцлалын бодлого"
override val profile: String = "Профайл"
override val profitable_client: String = "Таны хамгийн ашигтай үйлчлүүлэгч бол..."
override val profits_report: String = "Ашиг"
override val promo_content: String = "Сурталчилгааны агуулга"
override val quantity_hint: String = "Тоо хэмжээ"
override val quantity_label_hint: String = "Тоо хэмжээ шошго"
override val query_info: String = "Query нь зүйл болон татварын бусад дүрмийн аль алинд нь хамаарсан захиалгат тооцоог оролт болгон бичих боломжийг танд олгоно. Өөр +, -, *, /, % - математикийн операторуудтай холбохын тулд @ тэмдэг болон зүйл эсвэл татварын тайлбар нэмнэ үү"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber}-г таны үйлчлүүлэгч хүлээн авсан." }
override val received_state: String = "Хүлээн авсан"
override val registration_code_fields: String = "Бүртгэлийн код"
override val reminder: String = "Сануулагч"
override val reports: String = "Тайлангууд"
override val reports_not_available: String = "Тайлан байхгүй, дараа буцаж ирээрэй."
override val reveal_password: String = "Нууц үгийг илчлэх"
override val revenue: String = "revenue"
override val sales_tax: String = "НӨАТ"
override val sales_tax_number: String = "НӨАТ-ын дугаар"
override val save: String = "Хадгалах"
override val scan_document: String = "Баримт бичгийг сканнердах"
override val search: String = "Хайх"
override val select: String = "Сонго"
override val select_icon: String = "Дүрсийг сонгоно уу"
override val select_signature: String = "Гарын үсэг сонгоно уу"
override val select_template: String = "Загвар сонгох"
override val send: String = "Илгээх"
override val sent_state: String = "Илгээсэн"
override val setup_sender: String = "Илгээгчийг тохируулах"
override val share_option: String = "Хуваалцах"
override val ship_to: String = "руу тээвэрлэх"
override val sign_in: String = "Нэвтрэх"
override val sign_in_apple: String = "Apple-ээр нэвтэрнэ үү"
override val sign_in_email: String = "Имэйлээр нэвтэрнэ үү"
override val sign_in_facebook: String = "Facebook-ээр нэвтэрнэ үү"
override val sign_in_google: String = "Google-ээр нэвтэрнэ үү"
override val sign_up: String = "Бүртгүүлэх"
override val sign_up_label: String = "Данс байхгүй юу?"
override val sign_up_link_label: String = "Бүртгүүлэх!"
override val signatures_option: String = "Гарын үсэг"
override val skip: String = "Алгасах"
override val sold_items_report: String = "Борлуулсан эд зүйлс"
override val sort_by: String = "Эрэмбэлэх:"
override val status_update: String = "Баримт бичгийн төлөвийн шинэчлэлт"
override val sub_annual: String = "Жил бүрийн"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/жил" }
override val sub_backups: String = "Үүлэн нөөцлөлт"
override val sub_insights: String = "Үзэл бодол"
override val sub_month: String = "сар бүр"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/сар" }
override val sub_notifications: String = "Мэдэгдэл"
override val sub_popup_message: String = "Захиалга удахгүй гарах болно. Хэсэг хугацааны дараа энд дахин шалгаарай."
override val sub_support: String = "Хэрэглэгчийн төвд"
override val sub_templates: String = "Загвар"
override val subscribe: String = "Бүртгүүлэх"
override val subscription_header: String = "Бизнесээ дараах байдлаар хөгжүүлээрэй:"
override val subscription_option: String = "Захиалга"
override val subscriptions_load_fail: String = "Уучлаарай, одоогоор бид таны захиалгыг сэргээх боломжгүй байна. Дараа дахин оролдоно уу эсвэл тусламж авахын тулд манай тусламжийн багтай холбогдоно уу."
override val subtotal: Formattable = Formattable { (sum) -> "Дунд нийлбэр: ${sum}" }
override val subtotal_label: String = "Дунд нийлбэр"
override val sum: String = "Нийлбэр"
override val support_option: String = "Дэмжлэг"
override val switch_reports: String = "Тайлан солих"
override val tax: String = "Татвар"
override val tax_deleted_message: String = "Татварыг устгасан"
override val tax_description_warning: String = "Хэрэв ашигласан бол энэ нь нэхэмжлэх дээр харагдана"
override val tax_input_flat: String = "Хавтгай"
override val tax_input_percent: String = "Хувь"
override val tax_number_fields: String = "Татварын дугаар"
override val tax_number_hint: String = "Татварын дугаар"
override val tax_query_info_description: String = "Асуулгын мэдээлэл"
override val tax_report: String = "Татвар"
override val tax_rules_option: String = "Татварын дүрэм"
override val taxed_clients_report: String = "Татвартай үйлчлүүлэгчид"
override val template: String = "Загвар"
override val template_selector_info: String = "Үндсэн загварыг өөрчлөхийн тулд шударна уу"
override val template_unlock_promo: String = "Premium-ээр илүү олон загварт хандаарай"
override val templates_option: String = "Загварууд"
override val terms_of_service: String = "Үйлчилгээний нөхцөл"
override val thank_you: String = "Баярлалаа"
override val to: String = "руу"
override val total: Formattable = Formattable { (sum) -> "Нийт: ${sum}" }
override val total_label: String = "Нийлбэр"
override val undo_action: String = "Буцаах"
override val unit: String = "Нэгж"
override val unit_cost_hint: String = "Нэгжийн өртөг"
override val unknown_client: String = "Үл мэдэгдэх үйлчлүүлэгч"
override val unlock: String = "Түгжээг тайлах"
override val unnamed_item: String = "Нэргүй зүйл"
override val upgrade: String = "Шинэчлэх"
override val user_collision_error: String = "Бүртгэл аль хэдийн байна."
override val valid_until: String = "Хүртэл хүчинтэй"
override val warning: String = "Анхааруулга!"
override val yes: String = "yes"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "${termsOfService} болон ${privacyPolicy}-г зөвшөөрөх" },
	"account" to "account",
	"accounting" to "accounting",
	"accruals" to "accruals",
	"action_code_error" to "Буруу код.",
	"add_client_action" to "Үйлчлүүлэгч нэмэх",
	"add_customer" to "Үйлчлүүлэгч нэмэх",
	"add_expense" to "Зардал нэмнэ үү",
	"add_fields_label" to "Нэмэх:",
	"add_invoice_action" to "Үүсгэ",
	"add_item_action" to "Зүйл нэмэх",
	"add_item_button" to "Зүйл нэмэх",
	"add_signature_action" to "Гарын үсэг нэмнэ үү",
	"add_tax_action" to "Татвар нэмнэ",
	"address_fields" to "Хаяг",
	"address_line_hint" to "Хаягийн шугам",
	"advanced" to "Дэвшилтэт",
	"agree_marketing" to "Би шууд маркетингийн харилцааг хүлээн авахыг зөвшөөрч байна",
	"all_notifications" to "Бүх мэдэгдэл",
	"amortization" to "amortization",
	"amount" to "Дүн",
	"asset" to "asset",
	"attach_file" to "Файл хавсаргах",
	"attachment" to "Хавсралт",
	"auth_error" to "Үйлдэл амжилтгүй боллоо, дахин оролдоно уу.",
	"available_for_paid" to "Клоуд синкийг зөвхөн төлбөртэй захиалгаар ашиглах боломжтой.",
	"backups_option" to "Нөөцлөлт",
	"balance" to "balance",
	"bank_account" to "Банкны данс",
	"bill_number" to "Тооцооны дугаар",
	"cancel" to "Цуцлах",
	"cancel_trial" to "Cancel at any time",
	"capital" to "capital",
	"cash" to "cash",
	"categories" to "Ангилал",
	"category" to "Ангилал",
	"category_add" to "Ангилал нэмэх",
	"category_delete" to "Ангилал устгах",
	"category_name" to "Ангиллын нэр",
	"change_language" to Formattable { (language) -> "Нэхэмжлэхийн хэл: ${language}" },
	"change_language_label" to "Нэхэмжлэхийн хэл",
	"change_template" to "Загварыг өөрчлөх",
	"city_hint" to "Хот",
	"clear" to "Тодорхой",
	"client" to "Үйлчлүүлэгч",
	"client_actions" to "Үйлчлүүлэгчийн үйлдэл",
	"client_deleted_message" to "Үйлчлүүлэгчийг устгасан",
	"clients_error_required_fields" to "Дор хаяж нэг утга шаардлагатай.",
	"clients_option" to "Үйлчлүүлэгчид",
	"close" to "Хаах",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "Тун удахгүй!",
	"company_logo" to "Компанийн лого",
	"company_name_hint" to "Компани",
	"confirm_password_label" to "Нууц үгээ баталгаажуулна уу",
	"confirmation_title" to "Чи итгэлтэй байна уу?",
	"convert_to_invoice" to "Нэхэмжлэх болгон хөрвүүлэх",
	"country_hint" to "Улс",
	"create_client" to "Үйлчлүүлэгч үүсгэх",
	"create_company" to "Компани үүсгэх",
	"create_document" to "Баримт бичиг үүсгэх",
	"create_estimate" to "Тооцоолох",
	"create_invoice" to "Нэхэмжлэх",
	"create_item" to "Зүйл үүсгэх",
	"create_new_client" to "Шинэ үйлчлүүлэгч үүсгэх",
	"create_new_item" to "Шинэ зүйл үүсгэх",
	"create_new_tax" to "Шинэ татвар бий болгох",
	"create_signature" to "Гарын үсэг",
	"created" to "Үүсгэсэн",
	"credit" to "credit",
	"customer" to "Хэрэглэгч",
	"date" to "Огноо",
	"debit" to "debit",
	"debt" to "debt",
	"default_currency" to Formattable { (currency) -> "Өгөгдмөл мөнгөн тэмдэгт: ${currency}" },
	"default_currency_label" to "Өгөгдмөл валют",
	"delete" to "устгах",
	"delete_account" to "Бүртгэл устгах",
	"delete_confirmation_message" to "Таны бүх өгөгдөл устах болно.",
	"delete_item_title" to "Тухайн зүйлийг устгах уу?",
	"delete_signature" to "Гарын үсгийг устгах",
	"depreciation" to "depreciation",
	"description" to "Тодорхойлолт",
	"description_hint" to "Тодорхойлолт",
	"detailed_report" to "Нарийвчилсан тайлан",
	"disabled" to "Хөгжлийн бэрхшээлтэй",
	"discount" to "Хөнгөлөлт",
	"discount_hint" to "Хөнгөлөлт",
	"display_af" to "Африк",
	"display_am" to "Амхар",
	"display_ar" to "Араб",
	"display_as" to "Ассам",
	"display_az" to "Азербайжан",
	"display_be" to "Беларусь",
	"display_bg" to "Болгар",
	"display_bn" to "Бенгал",
	"display_bs" to "Босни",
	"display_ca" to "каталон",
	"display_cs" to "чех",
	"display_da" to "Дани",
	"display_de" to "Герман",
	"display_el" to "Грек",
	"display_en" to "Англи",
	"display_es" to "Испани",
	"display_es_MX" to "Испани (Мексик)",
	"display_et" to "Эстони",
	"display_eu" to "Баск",
	"display_fa" to "Перс",
	"display_fi" to "Финланд",
	"display_fil" to "Филиппин",
	"display_fr" to "Франц",
	"display_fr_CA" to "Канадын франц",
	"display_gl" to "Галис",
	"display_gu" to "Гужарати",
	"display_he" to "Еврей",
	"display_hi" to "Үгүй ээ",
	"display_hr" to "Хорват",
	"display_hu" to "Унгар",
	"display_hy" to "армян",
	"display_id" to "Индонез",
	"display_is" to "Исланд",
	"display_it" to "итали",
	"display_ja" to "Япон",
	"display_ka" to "Гүрж",
	"display_kk" to "Казак",
	"display_km" to "Төв Кхмер",
	"display_kn" to "Каннада",
	"display_ko" to "Солонгос",
	"display_ky" to "Киргиз",
	"display_lo" to "Хөдөлмөр",
	"display_lt" to "Литва",
	"display_lv" to "Латви",
	"display_mk" to "македон",
	"display_ml" to "Малайлам",
	"display_mn" to "Монгол",
	"display_mr" to "Марати",
	"display_ms" to "малай",
	"display_my" to "Бирм",
	"display_nb" to "Норвегийн бокмал",
	"display_ne" to "Непал",
	"display_nl" to "Голланд",
	"display_or" to "Ория",
	"display_pa" to "Пунжаби",
	"display_pl" to "Польш",
	"display_pt" to "португал",
	"display_pt_BR" to "Португали (Бразил)",
	"display_pt_PT" to "Европын португал хэл",
	"display_ro" to "румын",
	"display_ru" to "орос",
	"display_si" to "Синхал",
	"display_sk" to "словак",
	"display_sl" to "Словен",
	"display_sq" to "Албани",
	"display_sr" to "Серб",
	"display_sv" to "швед",
	"display_sw" to "Суахили",
	"display_ta" to "Тамил",
	"display_te" to "Тэлугу",
	"display_th" to "Тайланд",
	"display_tr" to "турк",
	"display_uk" to "украин",
	"display_ur" to "Урду",
	"display_uz" to "узбек",
	"display_vi" to "вьетнам",
	"display_zh_Hant_TW" to "Хятад (Тайвань, Уламжлалт)",
	"display_zu" to "Зулу",
	"document_estimate" to "Тооцоолох",
	"document_invoice" to "Нэхэмжлэх",
	"document_quote" to "Иш татах",
	"done" to "Дууслаа",
	"download_app" to "Апп татах",
	"download_invoice" to "Нэхэмжлэхийг татаж авах",
	"download_pdf" to "PDF татаж авах",
	"draw_signature" to "Гарын үсэг зурах",
	"due" to Formattable { (date) -> "Хугацаа ${date}" },
	"due_date" to "Дуусах хугацаа",
	"due_day" to "нэг өдрийн дотор",
	"due_label" to "Хугацаа нь",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} хугацаа ${dueWhen} байна!" },
	"due_today" to "Өнөөдөр",
	"due_week" to "долоо хоногт",
	"duplicate" to "Хуулбарлах",
	"duration_day" to "өдөр",
	"duration_month" to "Сар",
	"duration_week" to "долоо хоног",
	"duration_year" to "жил",
	"email" to "Имэйл",
	"email_blank_error" to "Имэйл шаардлагатай.",
	"email_error" to "Хүчингүй мэйл хаяг.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Таны бизнест баярлалаа. ${documentType}-г бүтэн үзэхийн тулд энэ захидлын төгсгөлд байгаа товчийг дарна уу. Энэ нь ${dueDate} хүртэл хүчинтэй гэдгийг анхаарна уу." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Та шинэ хүлээн авсан байна${documentType}${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "${documentType}-г ХАРАХ" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Эрхэм ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Таны бизнест баярлалаа. ${documentType}-г бүтэн үзэхийн тулд энэ захидлын төгсгөлд байгаа товчийг дарна уу. Үлдэгдэл төлбөрийг ${dueDate} хүртэл төлнө үү." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Та шинэ хүлээн авсан байна${documentType}${invoiceNumber} !" },
	"email_reset_success_message" to "Нууц үг солих имэйл илгээсэн тул ирсэн имэйлээ шалгана уу.",
	"empty_clients_list_message" to "Танд хянах үйлчлүүлэгч байхгүй байна. Шинэ үйлчлүүлэгч нэмнэ үү.",
	"empty_estimate_list_message" to "Эхний тооцоогоо гарга",
	"empty_expenses" to "Танд хянах зардал байхгүй. Шинэ зардал нэмнэ үү.",
	"empty_invoice_list_message" to "Эхний нэхэмжлэхээ үүсгэ",
	"empty_items_list_message" to "Танд ямар ч зүйл байхгүй, шинэ зүйл нэмнэ үү!",
	"empty_signatures_list_message" to "Гарын үсэг олдсонгүй. Нэгийг үүсгэ!",
	"empty_taxes_list_message" to "Танд татварын дүрэм байхгүй. Татварын шинэ дүрэм нэмнэ үү.",
	"empty_templates_list_message" to "Загваруудыг ашиглах боломжгүй.",
	"enabled" to "Идэвхжсэн",
	"entry" to "entry",
	"equity" to "equity",
	"estimate_number" to "Тооцоолол #",
	"estimates" to "Тооцоолол",
	"estimates_report" to "Тооцоолол",
	"expense_bank_fees" to "Банкны шимтгэл",
	"expense_benefits" to "Ашиг тус",
	"expense_communication" to "Харилцаа холбоо",
	"expense_debt" to "Өр",
	"expense_deleted" to "Зардлыг устгасан",
	"expense_depreciation" to "Элэгдэл",
	"expense_gifts" to "Бэлэг",
	"expense_insurance" to "Даатгал",
	"expense_inventory" to "Бараа материал",
	"expense_legal" to "Хууль эрх зүйн",
	"expense_maintenance" to "Засвар үйлчилгээ",
	"expense_marketing" to "Маркетинг",
	"expense_meals" to "Хоол",
	"expense_office_supplies" to "Албан тасалгааны хангамж",
	"expense_rent" to "Түрээс",
	"expense_salaries" to "Цалин",
	"expense_shopping" to "Дэлгүүр хэсэх",
	"expense_software" to "Програм хангамж",
	"expense_status_available" to "Боломжтой",
	"expense_status_processing" to "Боловсруулж байна",
	"expense_tax" to "Татвар",
	"expense_training" to "Сургалт",
	"expense_transport" to "Тээвэрлэлт",
	"expense_travel" to "Аялал",
	"expense_utility" to "Хэрэгсэл",
	"expenses" to "Зардал",
	"feedback_option" to "Санал хүсэлт",
	"filter" to "Шүүлтүүр",
	"first_name_hint" to "Нэр",
	"forgot_password" to "Нууц үгээ анхны байдалд нь оруулж",
	"forgot_password_label" to "Нууц үгээ мартсан",
	"from" to "Хаанаас",
	"general_error" to "Алдаа гарлаа, дараа дахин оролдоно уу.",
	"general_ledger" to "general ledger",
	"go_back_label" to "Буцах",
	"group_by" to "Бүлэглэсэн:",
	"image_view" to "Зураг",
	"install" to "Суулгах",
	"invalid_credentials_error" to "Хүчингүй итгэмжлэл.",
	"invalid_email_error" to "Хүчингүй и-мэйл.",
	"invalid_user_error" to "Хэрэглэгчийн итгэмжлэл буруу байна.",
	"invoice_deleted_message" to "Нэхэмжлэхийг устгасан",
	"invoice_menu" to "Нэхэмжлэлийн цэс",
	"invoice_number" to "Нэхэмжлэх #",
	"invoices" to "Нэхэмжлэх",
	"items_option" to "Эд зүйлс",
	"keep" to "Хадгалах",
	"language_selector" to Formattable { (сонгосон) -> "${сонгосон} хэл" },
	"last_name_hint" to "Овог",
	"ledger" to "ledger",
	"legal" to "Хууль эрх зүйн",
	"legal_error" to "Үйлчилгээний нөхцөл болон Нууцлалын бодлогыг хүлээн зөвшөөрөх ёстой",
	"liabilities" to "liabilities",
	"loading" to "Ачааж байна",
	"login_required_error" to "Нэвтрэх шаардлагатай.",
	"logo_view" to "Лого",
	"logout" to "Гарах",
	"logout_prompt" to "Таны өгөгдлийг клоудтай синхрончлоогүй байна. Гарах үед нэхэмжлэх, тооцоо, үйлчлүүлэгч болон бусад мэдээллийг устгах уу?",
	"main_screen" to "Үндсэн дэлгэц",
	"mark_paid" to "Төлбөртэй гэж тэмдэглэ",
	"message" to "Захиа",
	"missing_sender_msg" to "Илгээгчийн мэдээлэлгүй баримт бичиг хүчингүй байж магадгүй.",
	"missing_sender_title" to "Илгээгчийн мэдээлэл дутуу байна",
	"month_april" to "Дөрөвдүгээр сар",
	"month_april_short" to "Дөрөвдүгээр сар",
	"month_august" to "Наймдугаар сар",
	"month_august_short" to "Наймдугаар сар",
	"month_december" to "Арванхоёрдугаар сар",
	"month_december_short" to "12-р сар",
	"month_february" to "2-р сар",
	"month_february_short" to "Хоёрдугаар сар",
	"month_january" to "1-р сар",
	"month_january_short" to "1-р сар.",
	"month_july" to "7-р сар",
	"month_july_short" to "7-р сар",
	"month_june" to "6-р сар",
	"month_june_short" to "6-р сар",
	"month_march" to "3-р сар",
	"month_march_short" to "3-р сар",
	"month_may" to "5-р сар",
	"month_may_short" to "5-р сар",
	"month_november" to "Арваннэгдүгээр сар",
	"month_november_short" to "11-р сар",
	"month_october" to "Аравдугаар сар",
	"month_october_short" to "Аравдугаар сар",
	"month_september" to "9-р сар",
	"month_september_short" to "9-р сар",
	"multifactor_error" to "Баталгаажуулж чадсангүй.",
	"new" to "Шинэ",
	"news" to "Мэдээ",
	"next" to "Дараагийн",
	"no" to "no",
	"no_data_available" to "Өгөгдөл байхгүй байна",
	"no_search_results" to "Уучлаарай, бид ямар ч илэрц олж чадсангүй.",
	"none" to "Байхгүй",
	"note_fields" to "Тэмдэглэл",
	"note_hint" to "Тэмдэглэл",
	"notifications_option" to "Мэдэгдэл",
	"ok" to "БОЛЖ БАЙНА УУ",
	"onboarding" to "Нэвтрэх",
	"onboarding_aboutus_message" to "DingVoice-ийн чухал хэсгүүд үүрд үнэ төлбөргүй байдаг. Бидний эрхэм зорилго бол таны бизнесийг өргөжүүлэх, санал хүсэлтээ хуваалцах замаар сайжруулахад туслах явдал юм.",
	"onboarding_aboutus_title" to "Бид туслахаар энд байна",
	"onboarding_paid_1" to "Мэргэжлийн харагдах загваруудад хандах",
	"onboarding_paid_2" to "Санхүүгээ илүү гүнзгий хараарай",
	"onboarding_paid_3" to "Асуудлыг хурдан шийдээрэй",
	"onboarding_paid_4" to "Баримт бичгээ аюулгүй байлгаарай",
	"onboarding_paid_5" to "Нэхэмжлэлийн төлөвийн өөрчлөлтийг цаг тухайд нь авч байгаарай",
	"onboarding_paid_title" to "Илүү хурдан өсөх...",
	"onboarding_paywall_message" to "Дээд зэрэглэлийн бүх функцийг одоо хамгийн сайн үнээр ашиглаарай.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Зүгээр л ${currencySymbol}${introAmount} ${introDuration} ${introPeriod}, дараа нь ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Зүгээр л ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "${trialDuration} ${trialPeriod} үнэгүй, дараа нь ердөө ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "${trialDuration} ${trialPeriod} үнэ төлбөргүй, дараа нь ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}, дараа нь ердөө ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Автоматаар шинэчлэгдэх боломжтой. Хэзээ ч цуцлах.",
	"onboarding_paywall_restore" to "Худалдан авалтыг сэргээх",
	"onboarding_paywall_restore_failure" to "Худалдан авалтыг сэргээж чадсангүй. Дараа дахин оролдох эсвэл тусламжтай холбогдоно уу.",
	"onboarding_paywall_restore_success" to "Амжилттай сэргээлээ!",
	"onboarding_paywall_title" to "Агуу амжилтанд хүр!",
	"onboarding_welcome_message" to "Нэхэмжлэх, тооцоогоо удирдах бүрэн хэрэгслийн багц.",
	"onboarding_welcome_title" to "DingVoice-д тавтай морил",
	"other_state" to "Бусад",
	"overdue_state" to "Хугацаа хэтэрсэн",
	"paid_state" to "Төлбөртэй",
	"password" to "Нууц үг",
	"password_blank_error" to "Нууц үг шаардлагатай.",
	"password_mismatch_error" to "Нууц үг таарахгүй байна.",
	"pay" to "pay",
	"payment_instructions_label" to "Төлбөрийн заавар",
	"payment_reminders" to "Төлбөрийн сануулга",
	"phone_number_fields" to "Утасны дугаар",
	"phone_number_hint" to "Утасны дугаар",
	"postcode_hint" to "Шуудангийн код",
	"preview" to "Урьдчилан үзэх",
	"preview_menu_hint" to "Урьдчилан харах цэс",
	"price" to "Үнэ",
	"privacy_policy" to "Нууцлалын бодлого",
	"profile" to "Профайл",
	"profitable_client" to "Таны хамгийн ашигтай үйлчлүүлэгч бол...",
	"profits_report" to "Ашиг",
	"promo_content" to "Сурталчилгааны агуулга",
	"quantity_hint" to "Тоо хэмжээ",
	"quantity_label_hint" to "Тоо хэмжээ шошго",
	"query_info" to "Query нь зүйл болон татварын бусад дүрмийн аль алинд нь хамаарсан захиалгат тооцоог оролт болгон бичих боломжийг танд олгоно. Өөр +, -, *, /, % - математикийн операторуудтай холбохын тулд @ тэмдэг болон зүйл эсвэл татварын тайлбар нэмнэ үү",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber}-г таны үйлчлүүлэгч хүлээн авсан." },
	"received_state" to "Хүлээн авсан",
	"registration_code_fields" to "Бүртгэлийн код",
	"reminder" to "Сануулагч",
	"reports" to "Тайлангууд",
	"reports_not_available" to "Тайлан байхгүй, дараа буцаж ирээрэй.",
	"reveal_password" to "Нууц үгийг илчлэх",
	"revenue" to "revenue",
	"sales_tax" to "НӨАТ",
	"sales_tax_number" to "НӨАТ-ын дугаар",
	"save" to "Хадгалах",
	"scan_document" to "Баримт бичгийг сканнердах",
	"search" to "Хайх",
	"select" to "Сонго",
	"select_icon" to "Дүрсийг сонгоно уу",
	"select_signature" to "Гарын үсэг сонгоно уу",
	"select_template" to "Загвар сонгох",
	"send" to "Илгээх",
	"sent_state" to "Илгээсэн",
	"setup_sender" to "Илгээгчийг тохируулах",
	"share_option" to "Хуваалцах",
	"ship_to" to "руу тээвэрлэх",
	"sign_in" to "Нэвтрэх",
	"sign_in_apple" to "Apple-ээр нэвтэрнэ үү",
	"sign_in_email" to "Имэйлээр нэвтэрнэ үү",
	"sign_in_facebook" to "Facebook-ээр нэвтэрнэ үү",
	"sign_in_google" to "Google-ээр нэвтэрнэ үү",
	"sign_up" to "Бүртгүүлэх",
	"sign_up_label" to "Данс байхгүй юу?",
	"sign_up_link_label" to "Бүртгүүлэх!",
	"signatures_option" to "Гарын үсэг",
	"skip" to "Алгасах",
	"sold_items_report" to "Борлуулсан эд зүйлс",
	"sort_by" to "Эрэмбэлэх:",
	"status_update" to "Баримт бичгийн төлөвийн шинэчлэлт",
	"sub_annual" to "Жил бүрийн",
	"sub_annual_price" to Formattable { (price) -> "${price}/жил" },
	"sub_backups" to "Үүлэн нөөцлөлт",
	"sub_insights" to "Үзэл бодол",
	"sub_month" to "сар бүр",
	"sub_monthly_price" to Formattable { (price) -> "${price}/сар" },
	"sub_notifications" to "Мэдэгдэл",
	"sub_popup_message" to "Захиалга удахгүй гарах болно. Хэсэг хугацааны дараа энд дахин шалгаарай.",
	"sub_support" to "Хэрэглэгчийн төвд",
	"sub_templates" to "Загвар",
	"subscribe" to "Бүртгүүлэх",
	"subscription_header" to "Бизнесээ дараах байдлаар хөгжүүлээрэй:",
	"subscription_option" to "Захиалга",
	"subscriptions_load_fail" to "Уучлаарай, одоогоор бид таны захиалгыг сэргээх боломжгүй байна. Дараа дахин оролдоно уу эсвэл тусламж авахын тулд манай тусламжийн багтай холбогдоно уу.",
	"subtotal" to Formattable { (sum) -> "Дунд нийлбэр: ${sum}" },
	"subtotal_label" to "Дунд нийлбэр",
	"sum" to "Нийлбэр",
	"support_option" to "Дэмжлэг",
	"switch_reports" to "Тайлан солих",
	"tax" to "Татвар",
	"tax_deleted_message" to "Татварыг устгасан",
	"tax_description_warning" to "Хэрэв ашигласан бол энэ нь нэхэмжлэх дээр харагдана",
	"tax_input_flat" to "Хавтгай",
	"tax_input_percent" to "Хувь",
	"tax_number_fields" to "Татварын дугаар",
	"tax_number_hint" to "Татварын дугаар",
	"tax_query_info_description" to "Асуулгын мэдээлэл",
	"tax_report" to "Татвар",
	"tax_rules_option" to "Татварын дүрэм",
	"taxed_clients_report" to "Татвартай үйлчлүүлэгчид",
	"template" to "Загвар",
	"template_selector_info" to "Үндсэн загварыг өөрчлөхийн тулд шударна уу",
	"template_unlock_promo" to "Premium-ээр илүү олон загварт хандаарай",
	"templates_option" to "Загварууд",
	"terms_of_service" to "Үйлчилгээний нөхцөл",
	"thank_you" to "Баярлалаа",
	"to" to "руу",
	"total" to Formattable { (sum) -> "Нийт: ${sum}" },
	"total_label" to "Нийлбэр",
	"undo_action" to "Буцаах",
	"unit" to "Нэгж",
	"unit_cost_hint" to "Нэгжийн өртөг",
	"unknown_client" to "Үл мэдэгдэх үйлчлүүлэгч",
	"unlock" to "Түгжээг тайлах",
	"unnamed_item" to "Нэргүй зүйл",
	"upgrade" to "Шинэчлэх",
	"user_collision_error" to "Бүртгэл аль хэдийн байна.",
	"valid_until" to "Хүртэл хүчинтэй",
	"warning" to "Анхааруулга!",
	"yes" to "yes",
    )
    
}