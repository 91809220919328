// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class He  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "קבל ${termsOfService} ו-${privacyPolicy}" }
override val account: String = "חשבון"
override val accounting: String = "הנהלת חשבונות"
override val accruals: String = "צבירות"
override val action_code_error: String = "קוד לא חוקי."
override val add_client_action: String = "הוסף לקוח"
override val add_customer: String = "הוסף לקוח"
override val add_expense: String = "הוסף הוצאה"
override val add_fields_label: String = "הוסף:"
override val add_invoice_action: String = "יצר"
override val add_item_action: String = "הוספת פריט"
override val add_item_button: String = "הוספת פריט"
override val add_signature_action: String = "הוסף חתימה"
override val add_tax_action: String = "הוספת מס"
override val address_fields: String = "כתובת"
override val address_line_hint: String = "שורת כתובת"
override val advanced: String = "מתקדמים"
override val agree_marketing: String = "אני מסכים לקבל הודעות שיווק ישיר"
override val all_notifications: String = "כל העדכונים"
override val amortization: String = "אמורטיזציה"
override val amount: String = "כמות"
override val asset: String = "נכס"
override val attach_file: String = "צרף קובץ"
override val attachment: String = "צרופה"
override val auth_error: String = "הפעולה נכשלה, נסה שוב."
override val available_for_paid: String = "סנכרון ענן זמין רק עבור מנוי בתשלום."
override val backups_option: String = "גיבויים"
override val balance: String = "איזון"
override val bank_account: String = "חשבון בנק"
override val bill_number: String = "מספר חשבון"
override val cancel: String = "ביטל"
override val cancel_trial: String = "בטל בכל עת"
override val capital: String = "הון"
override val cash: String = "מזומנים"
override val categories: String = "קטגוריות"
override val category: String = "קטגוריה"
override val category_add: String = "הוסף קטגוריה"
override val category_delete: String = "מחק קטגוריה"
override val category_name: String = "שם קטגוריה"
override val change_language: Formattable = Formattable { (language) -> "שפת חשבונית: ${language}" }
override val change_language_label: String = "שפת חשבונית המס"
override val change_template: String = "שינוי תבנית"
override val city_hint: String = "עיר"
override val clear: String = "צלול"
override val client: String = "לקוח"
override val client_actions: String = "פעולות לקוח"
override val client_deleted_message: String = "הלקוח נמחק"
override val clients_error_required_fields: String = "נדרש לפחות ערך אחד."
override val clients_option: String = "לקוחות"
override val close: String = "קרוב"
override val cloud_sync: String = "סינכרון ענן"
override val coming_soon: String = "בקרוב!"
override val company_logo: String = "לוגו החברה"
override val company_name_hint: String = "חברה"
override val confirm_password_label: String = "אשר סיסמה"
override val confirmation_title: String = "האם אתה בטוח?"
override val convert_to_invoice: String = "המר לחשבונית"
override val country_hint: String = "מדינה"
override val create_client: String = "צור לקוח"
override val create_company: String = "צור חברה"
override val create_document: String = "יצירת מסמך"
override val create_estimate: String = "הערכה"
override val create_invoice: String = "חשבונית"
override val create_item: String = "צור פריט"
override val create_new_client: String = "צור לקוח חדש"
override val create_new_item: String = "יצירת פריט חדש"
override val create_new_tax: String = "צור מס חדש"
override val create_signature: String = "חתימה"
override val created: String = "שנוצרו"
override val credit: String = "אשראי"
override val customer: String = "לקוח"
override val date: String = "תמר"
override val debit: String = "חיוב"
override val debt: String = "חוב"
override val default_currency: Formattable = Formattable { (currency) -> "מטבע ברירת מחדל: ${currency}" }
override val default_currency_label: String = "מטבע ברירת המחדל"
override val delete: String = "למחוק"
override val delete_account: String = "מחק חשבון"
override val delete_confirmation_message: String = "כל הנתונים שלך יאבדו."
override val delete_item_title: String = "האם למחוק את הפריט?"
override val delete_signature: String = "מחק חתימה"
override val depreciation: String = "פחת"
override val description: String = "תיאור"
override val description_hint: String = "תיאור"
override val detailed_report: String = "דוח מפורט"
override val disabled: String = "לא זמין"
override val discount: String = "הנחה"
override val discount_hint: String = "הנחה"
override val display_af: String = "אפריקנס"
override val display_am: String = "אמהרית"
override val display_ar: String = "ערבית"
override val display_as: String = "אסאמית"
override val display_az: String = "אזרית"
override val display_be: String = "בלרוסית"
override val display_bg: String = "בולגרית"
override val display_bn: String = "בנגלית"
override val display_bs: String = "בוסנית"
override val display_ca: String = "קטלונית"
override val display_cs: String = "צ'כית"
override val display_da: String = "דנית"
override val display_de: String = "גרמנית"
override val display_el: String = "יוונית"
override val display_en: String = "אנגלית"
override val display_es: String = "ספרדית"
override val display_es_MX: String = "ספרדית (מקסיקו)"
override val display_et: String = "אסטונית"
override val display_eu: String = "בסקית"
override val display_fa: String = "פרסית"
override val display_fi: String = "פינית"
override val display_fil: String = "פיליפינית"
override val display_fr: String = "צרפתית"
override val display_fr_CA: String = "צרפתית קנדית"
override val display_gl: String = "גליציאנית"
override val display_gu: String = "גוג'ראטית"
override val display_he: String = "עברית"
override val display_hi: String = "הינדית"
override val display_hr: String = "קרואטית"
override val display_hu: String = "הונגרית"
override val display_hy: String = "ארמנית"
override val display_id: String = "אינדונזית"
override val display_is: String = "איסלנדית"
override val display_it: String = "איטלקית"
override val display_ja: String = "יפנית"
override val display_ka: String = "גיאורגית"
override val display_kk: String = "קזאחית"
override val display_km: String = "מרכז חמר"
override val display_kn: String = "קאנדה"
override val display_ko: String = "קוריאנית"
override val display_ky: String = "קירגיזית"
override val display_lo: String = "לאו"
override val display_lt: String = "ליטאית"
override val display_lv: String = "לטבית"
override val display_mk: String = "מקדונית"
override val display_ml: String = "מלאיאלאם"
override val display_mn: String = "מונגולית"
override val display_mr: String = "מראטי"
override val display_ms: String = "מלזית"
override val display_my: String = "בורמזית"
override val display_nb: String = "נורבגית"
override val display_ne: String = "נפאלית"
override val display_nl: String = "הולנדית"
override val display_or: String = "אוריה"
override val display_pa: String = "פונג'אבית"
override val display_pl: String = "פולנית"
override val display_pt: String = "פורטוגזית"
override val display_pt_BR: String = "פורטוגזית (ברזיל)"
override val display_pt_PT: String = "פורטוגזית אירופית"
override val display_ro: String = "רומנית"
override val display_ru: String = "רוסית"
override val display_si: String = "סינהלית"
override val display_sk: String = "סלובקית"
override val display_sl: String = "סלובנית"
override val display_sq: String = "אלבנית"
override val display_sr: String = "סרבית"
override val display_sv: String = "שבדית"
override val display_sw: String = "סוואהילית"
override val display_ta: String = "טאמילית"
override val display_te: String = "טלוגו"
override val display_th: String = "תאילנדית"
override val display_tr: String = "טורקית"
override val display_uk: String = "אוקראינית"
override val display_ur: String = "אורדוּ"
override val display_uz: String = "אוזבקית"
override val display_vi: String = "ויאטנמית"
override val display_zh_Hant_TW: String = "סינית (טייוואן, מסורתית)"
override val display_zu: String = "זולו"
override val document_estimate: String = "אומדן"
override val document_invoice: String = "חשבונית"
override val document_quote: String = "ציטוט"
override val done: String = "בוצע"
override val download_app: String = "הורידו את האפליקציה"
override val download_invoice: String = "הורד חשבונית"
override val download_pdf: String = "הורד קובץ PDF"
override val draw_signature: String = "ציור חתימה"
override val due: Formattable = Formattable { (date) -> "תאריך יעד ${date}" }
override val due_date: String = "תאריך יעד"
override val due_day: String = "בעוד יום"
override val due_label: String = "בשל"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "תאריך היעד של ${documentType} ${documentNumber} הוא ${dueWhen}!" }
override val due_today: String = "היום"
override val due_week: String = "בעוד שבוע"
override val duplicate: String = "שכפל"
override val duration_day: String = "יום"
override val duration_month: String = "חוֹדֶשׁ"
override val duration_week: String = "שבוע"
override val duration_year: String = "שנה"
override val email: String = "דואר אלקטרוני"
override val email_blank_error: String = "דרוש דואל."
override val email_error: String = "כתובת דואר אלקטרוני לא חוקית."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "תודה על העסק שלך. לצפייה מלאה ב-${documentType} , לחץ על הכפתור בסוף מכתב זה. שימו לב שהוא תקף עד ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "קיבלת ${documentType} ${invoiceNumber} חדש!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "הצג את ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "${clientName} היקר" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "תודה על העסק שלך. לצפייה מלאה ב-${documentType} , לחץ על הכפתור בסוף מכתב זה. אנא שלם את היתרה עד ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "קיבלת ${documentType} ${invoiceNumber} חדש!" }
override val email_reset_success_message: String = "דואר אלקטרוני לאיפוס הסיסמה שנשלחה, נא בדוק את תיבת הדואר הנכנס שלך."
override val empty_clients_list_message: String = "אין לך לקוחות לעקוב אחריהם. הוסף לקוח חדש."
override val empty_estimate_list_message: String = "יצירת ההערכה הראשונה שלך"
override val empty_expenses: String = "אין לך הוצאות לעקוב אחריהן. הוסף הוצאה חדשה."
override val empty_invoice_list_message: String = "יצירת החשבונית הראשונה שלך"
override val empty_items_list_message: String = "אין לך פריטים, הוסף פריט חדש!"
override val empty_signatures_list_message: String = "לא נמצאו חתימות. צור אחד!"
override val empty_taxes_list_message: String = "אין לך חוקי מס. הוסף כלל מס חדש."
override val empty_templates_list_message: String = "תבניות אינן זמינות."
override val enabled: String = "מאופשר"
override val entry: String = "כניסה"
override val equity: String = "הון עצמי"
override val estimate_number: String = "אומדן #"
override val estimates: String = "הערכות"
override val estimates_report: String = "הערכות"
override val expense_bank_fees: String = "עמלות בנק"
override val expense_benefits: String = "הטבות"
override val expense_communication: String = "תקשורת"
override val expense_debt: String = "חוב"
override val expense_deleted: String = "הוצאה נמחקה"
override val expense_depreciation: String = "פחת"
override val expense_gifts: String = "מתנות"
override val expense_insurance: String = "ביטוח"
override val expense_inventory: String = "מלאי"
override val expense_legal: String = "משפטי"
override val expense_maintenance: String = "תחזוקה"
override val expense_marketing: String = "שיווק"
override val expense_meals: String = "ארוחות"
override val expense_office_supplies: String = "ציוד משרדי"
override val expense_rent: String = "שכירות"
override val expense_salaries: String = "משכורות"
override val expense_shopping: String = "קניות"
override val expense_software: String = "תוכנה"
override val expense_status_available: String = "זמין"
override val expense_status_processing: String = "מעבד"
override val expense_tax: String = "מס"
override val expense_training: String = "הדרכה"
override val expense_transport: String = "תחבורה"
override val expense_travel: String = "נסיעות"
override val expense_utility: String = "שירות ציבורי"
override val expenses: String = "הוצאות"
override val feedback_option: String = "משוב"
override val filter: String = "מסנן"
override val first_name_hint: String = "שם פרטי"
override val forgot_password: String = "איפוס סיסמה"
override val forgot_password_label: String = "שכחתי סיסמה"
override val from: String = "מ"
override val general_error: String = "אירעה שגיאה, אנא נסה שוב מאוחר יותר."
override val general_ledger: String = "ספר חשבונות כללי"
override val go_back_label: String = "תחזור"
override val group_by: String = "קבץ לפי:"
override val image_view: String = "תמונה"
override val install: String = "התקנה"
override val invalid_credentials_error: String = "אישורים לא חוקיים."
override val invalid_email_error: String = "דואר אלקטרוני לא חוקי."
override val invalid_user_error: String = "אישורי משתמש לא חוקיים."
override val invoice_deleted_message: String = "חשבונית נמחקה"
override val invoice_menu: String = "תפריט חשבונית"
override val invoice_number: String = "חשבונית #"
override val invoices: String = "חשבוניות"
override val items_option: String = "פריטים"
override val keep: String = "לשמור"
override val language_selector: Formattable = Formattable { (selected) -> "שפה ${selected}" }
override val last_name_hint: String = "שם משפחה"
override val ledger: String = "ספר חשבונות"
override val legal: String = "משפטי"
override val legal_error: String = "חייב לקבל את תנאי השירות ומדיניות הפרטיות"
override val liabilities: String = "התחייבויות"
override val loading: String = "טעינת"
override val login_required_error: String = "נדרשת כניסה."
override val logo_view: String = "לוגו"
override val logout: String = "יציאה"
override val logout_prompt: String = "הנתונים שלך אינם מסונכרנים עם ענן. האם למחוק חשבוניות, הערכות, לקוחות ומידע נוסף על יציאה?"
override val main_screen: String = "מסך ראשי"
override val mark_paid: String = "סמן כתשלום"
override val message: String = "הודעה"
override val missing_sender_msg: String = "מסמך ללא פרטי שולח עלול להיות לא חוקי."
override val missing_sender_title: String = "חסר מידע שולח "
override val month_april: String = "אפריל"
override val month_april_short: String = "אפריל"
override val month_august: String = "אוגוסט"
override val month_august_short: String = "אוג׳"
override val month_december: String = "דצמבר"
override val month_december_short: String = "דצמ׳"
override val month_february: String = "פברואר"
override val month_february_short: String = "פבר׳"
override val month_january: String = "ינואר"
override val month_january_short: String = "ינו׳"
override val month_july: String = "יולי"
override val month_july_short: String = "יולי"
override val month_june: String = "יוני"
override val month_june_short: String = "יוני"
override val month_march: String = "מרץ"
override val month_march_short: String = "מרץ"
override val month_may: String = "מאי"
override val month_may_short: String = "מאי"
override val month_november: String = "נובמבר"
override val month_november_short: String = "נוב׳"
override val month_october: String = "אוקטובר"
override val month_october_short: String = "אוק׳"
override val month_september: String = "ספטמבר"
override val month_september_short: String = "ספט'"
override val multifactor_error: String = "האימות נכשל."
override val new: String = "חדש"
override val news: String = "חדשות"
override val next: String = "הַבָּא"
override val no: String = "לא"
override val no_data_available: String = "אין נתונים זמינים"
override val no_search_results: String = "מצטערים, לא הצלחנו למצוא תוצאות."
override val none: String = "ללא"
override val note_fields: String = "הערות"
override val note_hint: String = "הערה"
override val notifications_option: String = "הודעות"
override val ok: String = "אוקיי"
override val onboarding: String = "עלייה למטוס"
override val onboarding_aboutus_message: String = "חלקים חיוניים של DingVoice הם בחינם לנצח. המשימה שלנו היא לעזור לך להצמיח את העסק שלך, לעזור לנו להשתפר על ידי שיתוף המשוב שלך."
override val onboarding_aboutus_title: String = "אנחנו כאן כדי לעזור"
override val onboarding_paid_1: String = "גישה לתבניות במראה מקצועי"
override val onboarding_paid_2: String = "בדוק לעומק את המצב הכלכלי שלך"
override val onboarding_paid_3: String = "פתרו בעיות מהר"
override val onboarding_paid_4: String = "שמור את המסמכים שלך בטוחים"
override val onboarding_paid_5: String = "הישאר מעודכן בשינויים בסטטוס החשבונית"
override val onboarding_paid_title: String = "לגדול אפילו מהר יותר עם..."
override val onboarding_paywall_message: String = "תהנה מכל תכונות הפרימיום עכשיו במחיר הטוב ביותר."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "רק ${currencySymbol}${introAmount} עבור ${introDuration} ${introPeriod} ולאחר מכן ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "רק ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "חינם עבור ${trialDuration} ${trialPeriod} ורק לאחר מכן ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "חינם עבור ${trialDuration} ${trialPeriod} ולאחר מכן ${currencySymbol}${introductoryAmount} עבור ${introductoryDuration} ${introPeriod} ורק לאחר מכן ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "ניתן לחידוש אוטומטי. בטל בכל עת."
override val onboarding_paywall_restore: String = "שחזר רכישה"
override val onboarding_paywall_restore_failure: String = "שחזור הרכישות נכשל, נסה שוב מאוחר יותר או פנה לתמיכה."
override val onboarding_paywall_restore_success: String = "השחזור מוצלח!"
override val onboarding_paywall_title: String = "להשיג גדולות!"
override val onboarding_welcome_message: String = "ערכת כלים מלאה לניהול החשבוניות וההערכות שלך."
override val onboarding_welcome_title: String = "ברוכים הבאים ל-DingVoice"
override val other_state: String = "אחר"
override val overdue_state: String = "איחור"
override val paid_state: String = "שולם"
override val password: String = "סיסמה"
override val password_blank_error: String = "נדרשת סיסמה."
override val password_mismatch_error: String = "סיסמאות אינן תואמות."
override val pay: String = "שלם"
override val payment_instructions_label: String = "הוראות תשלום"
override val payment_reminders: String = "תזכורות תשלום"
override val phone_number_fields: String = "מספר טלפון"
override val phone_number_hint: String = "מספר טלפון"
override val postcode_hint: String = "מיקוד"
override val preview: String = "תצוגה מקדימה"
override val preview_menu_hint: String = "תפריט תצוגה מקדימה"
override val price: String = "מחיר"
override val privacy_policy: String = "מדיניות פרטיות"
override val profile: String = "פרופיל"
override val profitable_client: String = "הלקוח הרווחי ביותר שלך הוא..."
override val profits_report: String = "רווחים"
override val promo_content: String = "תוכן פרסומי"
override val quantity_hint: String = "כמות"
override val quantity_label_hint: String = "תווית כמות"
override val query_info: String = "השאילתה מאפשרת לך לכתוב חישובים מותאמים אישית המתייחסים הן לפריטים והן לכללי מס אחרים כתשומות. הוסף @ סמל ופריט או תיאור מס כדי לקשר לערך אחר +, -, *, /, % - אופרטורים מתמטיים"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} התקבל על ידי הלקוח שלך." }
override val received_state: String = "התקבל"
override val registration_code_fields: String = "קוד הרשמה"
override val reminder: String = "תזכורת"
override val reports: String = "דוחות"
override val reports_not_available: String = "אין דוחות זמינים, חזור מאוחר יותר."
override val reveal_password: String = "חשוף סיסמה"
override val revenue: String = "הכנסות"
override val sales_tax: String = "מע״מ "
override val sales_tax_number: String = "מספר מע״מ"
override val save: String = "שמר"
override val scan_document: String = "סרוק מסמך"
override val search: String = "חיפוש"
override val select: String = "בחר"
override val select_icon: String = "בחר סמל"
override val select_signature: String = "בחר חתימה"
override val select_template: String = "בחר תבנית"
override val send: String = "שלח"
override val sent_state: String = "שלח"
override val setup_sender: String = "הגדר שולח "
override val share_option: String = "לשתף"
override val ship_to: String = "לשלוח ל"
override val sign_in: String = "הירשם"
override val sign_in_apple: String = "כניסה באמצעות Apple"
override val sign_in_email: String = "היכנס באמצעות דואר אלקטרוני"
override val sign_in_facebook: String = "כניסה באמצעות פייסבוק"
override val sign_in_google: String = "היכנס באמצעות Google"
override val sign_up: String = "הירשם"
override val sign_up_label: String = "אין לך חשבון?"
override val sign_up_link_label: String = "הירשם!"
override val signatures_option: String = "חתימות"
override val skip: String = "לדלג"
override val sold_items_report: String = "פריטים שנמכרו"
override val sort_by: String = "מיין לפי:"
override val status_update: String = "עדכון מצב המסמך"
override val sub_annual: String = "שנתי"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/שנה" }
override val sub_backups: String = "גיבויים בענן"
override val sub_insights: String = "תובנות"
override val sub_month: String = "חודשי"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/חודש" }
override val sub_notifications: String = "הודעות"
override val sub_popup_message: String = "מנויים יבואו בקרוב. תבדוק כאן עוד מעט זמן."
override val sub_support: String = "תמיכה"
override val sub_templates: String = "תבניות"
override val subscribe: String = "הירשם"
override val subscription_header: String = "הגדל את העסק שלך עם:"
override val subscription_option: String = "מנוי"
override val subscriptions_load_fail: String = "מצטערים, אין באפשרותנו לאחזר את המנויים שלך בשלב זה. אנא נסה שוב מאוחר יותר או צור קשר עם צוות התמיכה שלנו לקבלת סיוע."
override val subtotal: Formattable = Formattable { (sum) -> "סכום ביניים: ${sum}" }
override val subtotal_label: String = "סכום ביניים"
override val sum: String = "סכום"
override val support_option: String = "תמיכה"
override val switch_reports: String = "החלף דוח"
override val tax: String = "מס"
override val tax_deleted_message: String = "מס נמחק"
override val tax_description_warning: String = "אם נעשה שימוש, זה מופיע בחשבונית"
override val tax_input_flat: String = "שטוח"
override val tax_input_percent: String = "אחוז"
override val tax_number_fields: String = "מספר מס"
override val tax_number_hint: String = "מספר מס"
override val tax_query_info_description: String = "פרטי שאילתה"
override val tax_report: String = "מס"
override val tax_rules_option: String = "כללי מס"
override val taxed_clients_report: String = "לקוחות במס"
override val template: String = "תבנית"
override val template_selector_info: String = "החלק במהירות כדי לשנות את תבנית ברירת המחדל"
override val template_unlock_promo: String = "גש לתבניות נוספות בגרסת ה  Premium"
override val templates_option: String = "תבניות"
override val terms_of_service: String = "תנאי שירות"
override val thank_you: String = "תודה!"
override val to: String = "ל"
override val total: Formattable = Formattable { (sum) -> "סהכ: ${sum}" }
override val total_label: String = "סך הכל"
override val undo_action: String = "לבטל"
override val unit: String = "יחידה"
override val unit_cost_hint: String = "עלות יחידה"
override val unknown_client: String = "לקוח לא ידוע"
override val unlock: String = "ביטול נעילה"
override val unnamed_item: String = "פריט ללא שם"
override val upgrade: String = "שדרג"
override val user_collision_error: String = "החשבון כבר קיים."
override val valid_until: String = "בתוקף עד"
override val warning: String = "אזהרה!"
override val yes: String = "כן"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "קבל ${termsOfService} ו-${privacyPolicy}" },
	"account" to "חשבון",
	"accounting" to "הנהלת חשבונות",
	"accruals" to "צבירות",
	"action_code_error" to "קוד לא חוקי.",
	"add_client_action" to "הוסף לקוח",
	"add_customer" to "הוסף לקוח",
	"add_expense" to "הוסף הוצאה",
	"add_fields_label" to "הוסף:",
	"add_invoice_action" to "יצר",
	"add_item_action" to "הוספת פריט",
	"add_item_button" to "הוספת פריט",
	"add_signature_action" to "הוסף חתימה",
	"add_tax_action" to "הוספת מס",
	"address_fields" to "כתובת",
	"address_line_hint" to "שורת כתובת",
	"advanced" to "מתקדמים",
	"agree_marketing" to "אני מסכים לקבל הודעות שיווק ישיר",
	"all_notifications" to "כל העדכונים",
	"amortization" to "אמורטיזציה",
	"amount" to "כמות",
	"asset" to "נכס",
	"attach_file" to "צרף קובץ",
	"attachment" to "צרופה",
	"auth_error" to "הפעולה נכשלה, נסה שוב.",
	"available_for_paid" to "סנכרון ענן זמין רק עבור מנוי בתשלום.",
	"backups_option" to "גיבויים",
	"balance" to "איזון",
	"bank_account" to "חשבון בנק",
	"bill_number" to "מספר חשבון",
	"cancel" to "ביטל",
	"cancel_trial" to "בטל בכל עת",
	"capital" to "הון",
	"cash" to "מזומנים",
	"categories" to "קטגוריות",
	"category" to "קטגוריה",
	"category_add" to "הוסף קטגוריה",
	"category_delete" to "מחק קטגוריה",
	"category_name" to "שם קטגוריה",
	"change_language" to Formattable { (language) -> "שפת חשבונית: ${language}" },
	"change_language_label" to "שפת חשבונית המס",
	"change_template" to "שינוי תבנית",
	"city_hint" to "עיר",
	"clear" to "צלול",
	"client" to "לקוח",
	"client_actions" to "פעולות לקוח",
	"client_deleted_message" to "הלקוח נמחק",
	"clients_error_required_fields" to "נדרש לפחות ערך אחד.",
	"clients_option" to "לקוחות",
	"close" to "קרוב",
	"cloud_sync" to "סינכרון ענן",
	"coming_soon" to "בקרוב!",
	"company_logo" to "לוגו החברה",
	"company_name_hint" to "חברה",
	"confirm_password_label" to "אשר סיסמה",
	"confirmation_title" to "האם אתה בטוח?",
	"convert_to_invoice" to "המר לחשבונית",
	"country_hint" to "מדינה",
	"create_client" to "צור לקוח",
	"create_company" to "צור חברה",
	"create_document" to "יצירת מסמך",
	"create_estimate" to "הערכה",
	"create_invoice" to "חשבונית",
	"create_item" to "צור פריט",
	"create_new_client" to "צור לקוח חדש",
	"create_new_item" to "יצירת פריט חדש",
	"create_new_tax" to "צור מס חדש",
	"create_signature" to "חתימה",
	"created" to "שנוצרו",
	"credit" to "אשראי",
	"customer" to "לקוח",
	"date" to "תמר",
	"debit" to "חיוב",
	"debt" to "חוב",
	"default_currency" to Formattable { (currency) -> "מטבע ברירת מחדל: ${currency}" },
	"default_currency_label" to "מטבע ברירת המחדל",
	"delete" to "למחוק",
	"delete_account" to "מחק חשבון",
	"delete_confirmation_message" to "כל הנתונים שלך יאבדו.",
	"delete_item_title" to "האם למחוק את הפריט?",
	"delete_signature" to "מחק חתימה",
	"depreciation" to "פחת",
	"description" to "תיאור",
	"description_hint" to "תיאור",
	"detailed_report" to "דוח מפורט",
	"disabled" to "לא זמין",
	"discount" to "הנחה",
	"discount_hint" to "הנחה",
	"display_af" to "אפריקנס",
	"display_am" to "אמהרית",
	"display_ar" to "ערבית",
	"display_as" to "אסאמית",
	"display_az" to "אזרית",
	"display_be" to "בלרוסית",
	"display_bg" to "בולגרית",
	"display_bn" to "בנגלית",
	"display_bs" to "בוסנית",
	"display_ca" to "קטלונית",
	"display_cs" to "צ'כית",
	"display_da" to "דנית",
	"display_de" to "גרמנית",
	"display_el" to "יוונית",
	"display_en" to "אנגלית",
	"display_es" to "ספרדית",
	"display_es_MX" to "ספרדית (מקסיקו)",
	"display_et" to "אסטונית",
	"display_eu" to "בסקית",
	"display_fa" to "פרסית",
	"display_fi" to "פינית",
	"display_fil" to "פיליפינית",
	"display_fr" to "צרפתית",
	"display_fr_CA" to "צרפתית קנדית",
	"display_gl" to "גליציאנית",
	"display_gu" to "גוג'ראטית",
	"display_he" to "עברית",
	"display_hi" to "הינדית",
	"display_hr" to "קרואטית",
	"display_hu" to "הונגרית",
	"display_hy" to "ארמנית",
	"display_id" to "אינדונזית",
	"display_is" to "איסלנדית",
	"display_it" to "איטלקית",
	"display_ja" to "יפנית",
	"display_ka" to "גיאורגית",
	"display_kk" to "קזאחית",
	"display_km" to "מרכז חמר",
	"display_kn" to "קאנדה",
	"display_ko" to "קוריאנית",
	"display_ky" to "קירגיזית",
	"display_lo" to "לאו",
	"display_lt" to "ליטאית",
	"display_lv" to "לטבית",
	"display_mk" to "מקדונית",
	"display_ml" to "מלאיאלאם",
	"display_mn" to "מונגולית",
	"display_mr" to "מראטי",
	"display_ms" to "מלזית",
	"display_my" to "בורמזית",
	"display_nb" to "נורבגית",
	"display_ne" to "נפאלית",
	"display_nl" to "הולנדית",
	"display_or" to "אוריה",
	"display_pa" to "פונג'אבית",
	"display_pl" to "פולנית",
	"display_pt" to "פורטוגזית",
	"display_pt_BR" to "פורטוגזית (ברזיל)",
	"display_pt_PT" to "פורטוגזית אירופית",
	"display_ro" to "רומנית",
	"display_ru" to "רוסית",
	"display_si" to "סינהלית",
	"display_sk" to "סלובקית",
	"display_sl" to "סלובנית",
	"display_sq" to "אלבנית",
	"display_sr" to "סרבית",
	"display_sv" to "שבדית",
	"display_sw" to "סוואהילית",
	"display_ta" to "טאמילית",
	"display_te" to "טלוגו",
	"display_th" to "תאילנדית",
	"display_tr" to "טורקית",
	"display_uk" to "אוקראינית",
	"display_ur" to "אורדוּ",
	"display_uz" to "אוזבקית",
	"display_vi" to "ויאטנמית",
	"display_zh_Hant_TW" to "סינית (טייוואן, מסורתית)",
	"display_zu" to "זולו",
	"document_estimate" to "אומדן",
	"document_invoice" to "חשבונית",
	"document_quote" to "ציטוט",
	"done" to "בוצע",
	"download_app" to "הורידו את האפליקציה",
	"download_invoice" to "הורד חשבונית",
	"download_pdf" to "הורד קובץ PDF",
	"draw_signature" to "ציור חתימה",
	"due" to Formattable { (date) -> "תאריך יעד ${date}" },
	"due_date" to "תאריך יעד",
	"due_day" to "בעוד יום",
	"due_label" to "בשל",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "תאריך היעד של ${documentType} ${documentNumber} הוא ${dueWhen}!" },
	"due_today" to "היום",
	"due_week" to "בעוד שבוע",
	"duplicate" to "שכפל",
	"duration_day" to "יום",
	"duration_month" to "חוֹדֶשׁ",
	"duration_week" to "שבוע",
	"duration_year" to "שנה",
	"email" to "דואר אלקטרוני",
	"email_blank_error" to "דרוש דואל.",
	"email_error" to "כתובת דואר אלקטרוני לא חוקית.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "תודה על העסק שלך. לצפייה מלאה ב-${documentType} , לחץ על הכפתור בסוף מכתב זה. שימו לב שהוא תקף עד ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "קיבלת ${documentType} ${invoiceNumber} חדש!" },
	"email_invoice_cta" to Formattable { (documentType) -> "הצג את ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "${clientName} היקר" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "תודה על העסק שלך. לצפייה מלאה ב-${documentType} , לחץ על הכפתור בסוף מכתב זה. אנא שלם את היתרה עד ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "קיבלת ${documentType} ${invoiceNumber} חדש!" },
	"email_reset_success_message" to "דואר אלקטרוני לאיפוס הסיסמה שנשלחה, נא בדוק את תיבת הדואר הנכנס שלך.",
	"empty_clients_list_message" to "אין לך לקוחות לעקוב אחריהם. הוסף לקוח חדש.",
	"empty_estimate_list_message" to "יצירת ההערכה הראשונה שלך",
	"empty_expenses" to "אין לך הוצאות לעקוב אחריהן. הוסף הוצאה חדשה.",
	"empty_invoice_list_message" to "יצירת החשבונית הראשונה שלך",
	"empty_items_list_message" to "אין לך פריטים, הוסף פריט חדש!",
	"empty_signatures_list_message" to "לא נמצאו חתימות. צור אחד!",
	"empty_taxes_list_message" to "אין לך חוקי מס. הוסף כלל מס חדש.",
	"empty_templates_list_message" to "תבניות אינן זמינות.",
	"enabled" to "מאופשר",
	"entry" to "כניסה",
	"equity" to "הון עצמי",
	"estimate_number" to "אומדן #",
	"estimates" to "הערכות",
	"estimates_report" to "הערכות",
	"expense_bank_fees" to "עמלות בנק",
	"expense_benefits" to "הטבות",
	"expense_communication" to "תקשורת",
	"expense_debt" to "חוב",
	"expense_deleted" to "הוצאה נמחקה",
	"expense_depreciation" to "פחת",
	"expense_gifts" to "מתנות",
	"expense_insurance" to "ביטוח",
	"expense_inventory" to "מלאי",
	"expense_legal" to "משפטי",
	"expense_maintenance" to "תחזוקה",
	"expense_marketing" to "שיווק",
	"expense_meals" to "ארוחות",
	"expense_office_supplies" to "ציוד משרדי",
	"expense_rent" to "שכירות",
	"expense_salaries" to "משכורות",
	"expense_shopping" to "קניות",
	"expense_software" to "תוכנה",
	"expense_status_available" to "זמין",
	"expense_status_processing" to "מעבד",
	"expense_tax" to "מס",
	"expense_training" to "הדרכה",
	"expense_transport" to "תחבורה",
	"expense_travel" to "נסיעות",
	"expense_utility" to "שירות ציבורי",
	"expenses" to "הוצאות",
	"feedback_option" to "משוב",
	"filter" to "מסנן",
	"first_name_hint" to "שם פרטי",
	"forgot_password" to "איפוס סיסמה",
	"forgot_password_label" to "שכחתי סיסמה",
	"from" to "מ",
	"general_error" to "אירעה שגיאה, אנא נסה שוב מאוחר יותר.",
	"general_ledger" to "ספר חשבונות כללי",
	"go_back_label" to "תחזור",
	"group_by" to "קבץ לפי:",
	"image_view" to "תמונה",
	"install" to "התקנה",
	"invalid_credentials_error" to "אישורים לא חוקיים.",
	"invalid_email_error" to "דואר אלקטרוני לא חוקי.",
	"invalid_user_error" to "אישורי משתמש לא חוקיים.",
	"invoice_deleted_message" to "חשבונית נמחקה",
	"invoice_menu" to "תפריט חשבונית",
	"invoice_number" to "חשבונית #",
	"invoices" to "חשבוניות",
	"items_option" to "פריטים",
	"keep" to "לשמור",
	"language_selector" to Formattable { (selected) -> "שפה ${selected}" },
	"last_name_hint" to "שם משפחה",
	"ledger" to "ספר חשבונות",
	"legal" to "משפטי",
	"legal_error" to "חייב לקבל את תנאי השירות ומדיניות הפרטיות",
	"liabilities" to "התחייבויות",
	"loading" to "טעינת",
	"login_required_error" to "נדרשת כניסה.",
	"logo_view" to "לוגו",
	"logout" to "יציאה",
	"logout_prompt" to "הנתונים שלך אינם מסונכרנים עם ענן. האם למחוק חשבוניות, הערכות, לקוחות ומידע נוסף על יציאה?",
	"main_screen" to "מסך ראשי",
	"mark_paid" to "סמן כתשלום",
	"message" to "הודעה",
	"missing_sender_msg" to "מסמך ללא פרטי שולח עלול להיות לא חוקי.",
	"missing_sender_title" to "חסר מידע שולח ",
	"month_april" to "אפריל",
	"month_april_short" to "אפריל",
	"month_august" to "אוגוסט",
	"month_august_short" to "אוג׳",
	"month_december" to "דצמבר",
	"month_december_short" to "דצמ׳",
	"month_february" to "פברואר",
	"month_february_short" to "פבר׳",
	"month_january" to "ינואר",
	"month_january_short" to "ינו׳",
	"month_july" to "יולי",
	"month_july_short" to "יולי",
	"month_june" to "יוני",
	"month_june_short" to "יוני",
	"month_march" to "מרץ",
	"month_march_short" to "מרץ",
	"month_may" to "מאי",
	"month_may_short" to "מאי",
	"month_november" to "נובמבר",
	"month_november_short" to "נוב׳",
	"month_october" to "אוקטובר",
	"month_october_short" to "אוק׳",
	"month_september" to "ספטמבר",
	"month_september_short" to "ספט'",
	"multifactor_error" to "האימות נכשל.",
	"new" to "חדש",
	"news" to "חדשות",
	"next" to "הַבָּא",
	"no" to "לא",
	"no_data_available" to "אין נתונים זמינים",
	"no_search_results" to "מצטערים, לא הצלחנו למצוא תוצאות.",
	"none" to "ללא",
	"note_fields" to "הערות",
	"note_hint" to "הערה",
	"notifications_option" to "הודעות",
	"ok" to "אוקיי",
	"onboarding" to "עלייה למטוס",
	"onboarding_aboutus_message" to "חלקים חיוניים של DingVoice הם בחינם לנצח. המשימה שלנו היא לעזור לך להצמיח את העסק שלך, לעזור לנו להשתפר על ידי שיתוף המשוב שלך.",
	"onboarding_aboutus_title" to "אנחנו כאן כדי לעזור",
	"onboarding_paid_1" to "גישה לתבניות במראה מקצועי",
	"onboarding_paid_2" to "בדוק לעומק את המצב הכלכלי שלך",
	"onboarding_paid_3" to "פתרו בעיות מהר",
	"onboarding_paid_4" to "שמור את המסמכים שלך בטוחים",
	"onboarding_paid_5" to "הישאר מעודכן בשינויים בסטטוס החשבונית",
	"onboarding_paid_title" to "לגדול אפילו מהר יותר עם...",
	"onboarding_paywall_message" to "תהנה מכל תכונות הפרימיום עכשיו במחיר הטוב ביותר.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "רק ${currencySymbol}${introAmount} עבור ${introDuration} ${introPeriod} ולאחר מכן ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "רק ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "חינם עבור ${trialDuration} ${trialPeriod} ורק לאחר מכן ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "חינם עבור ${trialDuration} ${trialPeriod} ולאחר מכן ${currencySymbol}${introductoryAmount} עבור ${introductoryDuration} ${introPeriod} ורק לאחר מכן ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "ניתן לחידוש אוטומטי. בטל בכל עת.",
	"onboarding_paywall_restore" to "שחזר רכישה",
	"onboarding_paywall_restore_failure" to "שחזור הרכישות נכשל, נסה שוב מאוחר יותר או פנה לתמיכה.",
	"onboarding_paywall_restore_success" to "השחזור מוצלח!",
	"onboarding_paywall_title" to "להשיג גדולות!",
	"onboarding_welcome_message" to "ערכת כלים מלאה לניהול החשבוניות וההערכות שלך.",
	"onboarding_welcome_title" to "ברוכים הבאים ל-DingVoice",
	"other_state" to "אחר",
	"overdue_state" to "איחור",
	"paid_state" to "שולם",
	"password" to "סיסמה",
	"password_blank_error" to "נדרשת סיסמה.",
	"password_mismatch_error" to "סיסמאות אינן תואמות.",
	"pay" to "שלם",
	"payment_instructions_label" to "הוראות תשלום",
	"payment_reminders" to "תזכורות תשלום",
	"phone_number_fields" to "מספר טלפון",
	"phone_number_hint" to "מספר טלפון",
	"postcode_hint" to "מיקוד",
	"preview" to "תצוגה מקדימה",
	"preview_menu_hint" to "תפריט תצוגה מקדימה",
	"price" to "מחיר",
	"privacy_policy" to "מדיניות פרטיות",
	"profile" to "פרופיל",
	"profitable_client" to "הלקוח הרווחי ביותר שלך הוא...",
	"profits_report" to "רווחים",
	"promo_content" to "תוכן פרסומי",
	"quantity_hint" to "כמות",
	"quantity_label_hint" to "תווית כמות",
	"query_info" to "השאילתה מאפשרת לך לכתוב חישובים מותאמים אישית המתייחסים הן לפריטים והן לכללי מס אחרים כתשומות. הוסף @ סמל ופריט או תיאור מס כדי לקשר לערך אחר +, -, *, /, % - אופרטורים מתמטיים",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} התקבל על ידי הלקוח שלך." },
	"received_state" to "התקבל",
	"registration_code_fields" to "קוד הרשמה",
	"reminder" to "תזכורת",
	"reports" to "דוחות",
	"reports_not_available" to "אין דוחות זמינים, חזור מאוחר יותר.",
	"reveal_password" to "חשוף סיסמה",
	"revenue" to "הכנסות",
	"sales_tax" to "מע״מ ",
	"sales_tax_number" to "מספר מע״מ",
	"save" to "שמר",
	"scan_document" to "סרוק מסמך",
	"search" to "חיפוש",
	"select" to "בחר",
	"select_icon" to "בחר סמל",
	"select_signature" to "בחר חתימה",
	"select_template" to "בחר תבנית",
	"send" to "שלח",
	"sent_state" to "שלח",
	"setup_sender" to "הגדר שולח ",
	"share_option" to "לשתף",
	"ship_to" to "לשלוח ל",
	"sign_in" to "הירשם",
	"sign_in_apple" to "כניסה באמצעות Apple",
	"sign_in_email" to "היכנס באמצעות דואר אלקטרוני",
	"sign_in_facebook" to "כניסה באמצעות פייסבוק",
	"sign_in_google" to "היכנס באמצעות Google",
	"sign_up" to "הירשם",
	"sign_up_label" to "אין לך חשבון?",
	"sign_up_link_label" to "הירשם!",
	"signatures_option" to "חתימות",
	"skip" to "לדלג",
	"sold_items_report" to "פריטים שנמכרו",
	"sort_by" to "מיין לפי:",
	"status_update" to "עדכון מצב המסמך",
	"sub_annual" to "שנתי",
	"sub_annual_price" to Formattable { (price) -> "${price}/שנה" },
	"sub_backups" to "גיבויים בענן",
	"sub_insights" to "תובנות",
	"sub_month" to "חודשי",
	"sub_monthly_price" to Formattable { (price) -> "${price}/חודש" },
	"sub_notifications" to "הודעות",
	"sub_popup_message" to "מנויים יבואו בקרוב. תבדוק כאן עוד מעט זמן.",
	"sub_support" to "תמיכה",
	"sub_templates" to "תבניות",
	"subscribe" to "הירשם",
	"subscription_header" to "הגדל את העסק שלך עם:",
	"subscription_option" to "מנוי",
	"subscriptions_load_fail" to "מצטערים, אין באפשרותנו לאחזר את המנויים שלך בשלב זה. אנא נסה שוב מאוחר יותר או צור קשר עם צוות התמיכה שלנו לקבלת סיוע.",
	"subtotal" to Formattable { (sum) -> "סכום ביניים: ${sum}" },
	"subtotal_label" to "סכום ביניים",
	"sum" to "סכום",
	"support_option" to "תמיכה",
	"switch_reports" to "החלף דוח",
	"tax" to "מס",
	"tax_deleted_message" to "מס נמחק",
	"tax_description_warning" to "אם נעשה שימוש, זה מופיע בחשבונית",
	"tax_input_flat" to "שטוח",
	"tax_input_percent" to "אחוז",
	"tax_number_fields" to "מספר מס",
	"tax_number_hint" to "מספר מס",
	"tax_query_info_description" to "פרטי שאילתה",
	"tax_report" to "מס",
	"tax_rules_option" to "כללי מס",
	"taxed_clients_report" to "לקוחות במס",
	"template" to "תבנית",
	"template_selector_info" to "החלק במהירות כדי לשנות את תבנית ברירת המחדל",
	"template_unlock_promo" to "גש לתבניות נוספות בגרסת ה  Premium",
	"templates_option" to "תבניות",
	"terms_of_service" to "תנאי שירות",
	"thank_you" to "תודה!",
	"to" to "ל",
	"total" to Formattable { (sum) -> "סהכ: ${sum}" },
	"total_label" to "סך הכל",
	"undo_action" to "לבטל",
	"unit" to "יחידה",
	"unit_cost_hint" to "עלות יחידה",
	"unknown_client" to "לקוח לא ידוע",
	"unlock" to "ביטול נעילה",
	"unnamed_item" to "פריט ללא שם",
	"upgrade" to "שדרג",
	"user_collision_error" to "החשבון כבר קיים.",
	"valid_until" to "בתוקף עד",
	"warning" to "אזהרה!",
	"yes" to "כן",
    )
    
}