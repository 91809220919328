// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Sq  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Pranoni ${termsOfService} dhe ${privacyPolicy}" }
override val account: String = "llogari"
override val accounting: String = "kontabiliteti"
override val accruals: String = "akruale"
override val action_code_error: String = "Kodi i pavlefshëm."
override val add_client_action: String = "Shto klient"
override val add_customer: String = "Shto konsumatorin"
override val add_expense: String = "Shto shpenzime"
override val add_fields_label: String = "Shto:"
override val add_invoice_action: String = "Krijo"
override val add_item_action: String = "Shto artikull"
override val add_item_button: String = "Shto artikull"
override val add_signature_action: String = "Shto nënshkrimin"
override val add_tax_action: String = "Shto taksën"
override val address_fields: String = "Adresa"
override val address_line_hint: String = "Linja e adresës"
override val advanced: String = "Avancuar"
override val agree_marketing: String = "Unë pranoj të marr Komunikime të Drejtpërdrejta të Marketingut"
override val all_notifications: String = "Të gjitha njoftimet"
override val amortization: String = "amortizimi"
override val amount: String = "Shuma"
override val asset: String = "aktiv"
override val attach_file: String = "Bashkangjit skedarin"
override val attachment: String = "Bashkëngjitje"
override val auth_error: String = "Veprimi dështoi, provoni përsëri."
override val available_for_paid: String = "Sync Cloud është në dispozicion vetëm nën abonim të paguar."
override val backups_option: String = "Kopje rezervë"
override val balance: String = "bilanci"
override val bank_account: String = "Llogari Bankare"
override val bill_number: String = "Numri i Faturës"
override val cancel: String = "Anuloj"
override val cancel_trial: String = "Anuloni në çdo kohë"
override val capital: String = "kapitali"
override val cash: String = "para të gatshme"
override val categories: String = "Kategoritë"
override val category: String = "Kategoria"
override val category_add: String = "Shto kategori"
override val category_delete: String = "Fshi kategorinë"
override val category_name: String = "Emri i kategorisë"
override val change_language: Formattable = Formattable { (language) -> "Gjuha e faturës: ${language}" }
override val change_language_label: String = "Gjuha e Faturës"
override val change_template: String = "Ndrysho modelin"
override val city_hint: String = "Qytet"
override val clear: String = "Qartë"
override val client: String = "Klienti"
override val client_actions: String = "Veprimet e klientit"
override val client_deleted_message: String = "Klienti fshihet"
override val clients_error_required_fields: String = "Kërkohet të paktën një vlerë."
override val clients_option: String = "Klientët"
override val close: String = "Mbylle"
override val cloud_sync: String = "Sinkronizimi i reve"
override val coming_soon: String = "Vjen së shpejti!"
override val company_logo: String = "Logoja e kompanisë"
override val company_name_hint: String = "kompania"
override val confirm_password_label: String = "Konfirmo fjalëkalimin"
override val confirmation_title: String = "Jeni i sigurtë?"
override val convert_to_invoice: String = "Konvertoni në faturë"
override val country_hint: String = "Shteti"
override val create_client: String = "Krijo Klientin"
override val create_company: String = "Krijoni kompani"
override val create_document: String = "Krijo dokument"
override val create_estimate: String = "Vlerësim"
override val create_invoice: String = "Faturë"
override val create_item: String = "Krijo artikull"
override val create_new_client: String = "Krijo klient të ri"
override val create_new_item: String = "Krijo artikull të ri"
override val create_new_tax: String = "Krijoni taksë të re"
override val create_signature: String = "Nënshkrimi"
override val created: String = "Krijuar"
override val credit: String = "krediti"
override val customer: String = "Konsumatori"
override val date: String = "Data"
override val debit: String = "debiti"
override val debt: String = "borxh"
override val default_currency: Formattable = Formattable { (currency) -> "Monedha e paracaktuar: ${currency}" }
override val default_currency_label: String = "Monedha e paracaktuar"
override val delete: String = "Fshi"
override val delete_account: String = "Fshi Llogarinë"
override val delete_confirmation_message: String = "Të gjitha të dhënat tuaja do të humbasin."
override val delete_item_title: String = "Fshini artikullin?"
override val delete_signature: String = "Fshi nënshkrimin"
override val depreciation: String = "amortizimi"
override val description: String = "Përshkrimi"
override val description_hint: String = "Përshkrimi"
override val detailed_report: String = "Raport i Detajuar"
override val disabled: String = "I paaftë"
override val discount: String = "Zbritje"
override val discount_hint: String = "Zbritje"
override val display_af: String = "Afrikanisht"
override val display_am: String = "Amhariq"
override val display_ar: String = "arabisht"
override val display_as: String = "Assamese"
override val display_az: String = "Azerbajxhanit"
override val display_be: String = "Belarusian"
override val display_bg: String = "Bullgarisht"
override val display_bn: String = "Bengali"
override val display_bs: String = "boshnjake"
override val display_ca: String = "Katalanisht"
override val display_cs: String = "çek"
override val display_da: String = "daneze"
override val display_de: String = "gjermanisht"
override val display_el: String = "grek"
override val display_en: String = "anglisht"
override val display_es: String = "spanjoll"
override val display_es_MX: String = "Spanjisht (Meksikë)"
override val display_et: String = "Estonisht"
override val display_eu: String = "baske"
override val display_fa: String = "persisht"
override val display_fi: String = "Finlandisht"
override val display_fil: String = "Tagalogisht"
override val display_fr: String = "frëngjisht"
override val display_fr_CA: String = "Frëngjisht kanadez"
override val display_gl: String = "Galician"
override val display_gu: String = "Gujarati"
override val display_he: String = "hebraike"
override val display_hi: String = "Hindi"
override val display_hr: String = "kroate"
override val display_hu: String = "hungareze"
override val display_hy: String = "armen"
override val display_id: String = "Indonezisht"
override val display_is: String = "islandeze"
override val display_it: String = "italian"
override val display_ja: String = "japonez"
override val display_ka: String = "gjeorgjiane"
override val display_kk: String = "Kazakistanit"
override val display_km: String = "Khmer Qendror"
override val display_kn: String = "Kannada"
override val display_ko: String = "korean"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Lituanisht"
override val display_lv: String = "Letonisht"
override val display_mk: String = "maqedonase"
override val display_ml: String = "Malajalam"
override val display_mn: String = "Mongolian"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malajese"
override val display_my: String = "Burmese"
override val display_nb: String = "Norvegjeze Bokmål"
override val display_ne: String = "Nepaleze"
override val display_nl: String = "holandez"
override val display_or: String = "Oriya"
override val display_pa: String = "Punxhabi"
override val display_pl: String = "lustër"
override val display_pt: String = "portugalisht"
override val display_pt_BR: String = "Portugalisht (Brazil)"
override val display_pt_PT: String = "Portugalisht evropiane"
override val display_ro: String = "rumune"
override val display_ru: String = "Rus"
override val display_si: String = "Sinhala"
override val display_sk: String = "Sllovake"
override val display_sl: String = "sllovene"
override val display_sq: String = "shqiptare"
override val display_sr: String = "serbe"
override val display_sv: String = "suedisht"
override val display_sw: String = "suahili"
override val display_ta: String = "Tamile"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "turk"
override val display_uk: String = "ukrainas"
override val display_ur: String = "urdu"
override val display_uz: String = "Uzbekisht"
override val display_vi: String = "Vietnamese"
override val display_zh_Hant_TW: String = "Kineze (Tajvan, Tradicionale)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Vlerësim"
override val document_invoice: String = "Faturë"
override val document_quote: String = "Oferta"
override val done: String = "U krye"
override val download_app: String = "Shkarkoni aplikacionin"
override val download_invoice: String = "Shkarko Faturën"
override val download_pdf: String = "Shkarko PDF"
override val draw_signature: String = "Vizatoni një nënshkrim"
override val due: Formattable = Formattable { (date) -> "shkak ${date}" }
override val due_date: String = "Data e duhur"
override val due_day: String = "në një ditë"
override val due_label: String = "për shkak"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} është për shkak ${dueWhen}!" }
override val due_today: String = "sot"
override val due_week: String = "në një javë"
override val duplicate: String = "Kopjoni"
override val duration_day: String = "ditë"
override val duration_month: String = "Muaj"
override val duration_week: String = "javë"
override val duration_year: String = "vit"
override val email: String = "Email"
override val email_blank_error: String = "Email nevojshme."
override val email_error: String = "Adresa e pavlefshme e postës elektronike."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Faleminderit për biznesin tuaj. Për të parë të plotë ${documentType} klikoni butonin në fund të kësaj letre. Vini re se është e vlefshme deri në ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Ju keni marrë një të ri ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "SHIKO ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Të nderuar ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Faleminderit për biznesin tuaj. Për të parë të plotë ${documentType} klikoni butonin në fund të kësaj letre. Ju lutemi paguani bilancin deri në ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Ju keni marrë një të ri ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Email për të rivendosur fjalëkalimin dërguar, ju lutem kontrolloni kutinë tuaj."
override val empty_clients_list_message: String = "Ju nuk keni klientë për të ndjekur. Shto një klient të ri."
override val empty_estimate_list_message: String = "Krijo vlerësimin tuaj të parë"
override val empty_expenses: String = "Nuk keni shpenzime për të gjurmuar. Shto një shpenzim të ri."
override val empty_invoice_list_message: String = "Krijoni faturën tuaj të parë"
override val empty_items_list_message: String = "Ju nuk keni artikuj, shtoni një artikull të ri!"
override val empty_signatures_list_message: String = "Asnjë nënshkrim nuk u gjet. Krijo një!"
override val empty_taxes_list_message: String = "Ju nuk keni rregulla tatimore. Shto një rregull të ri tatimor."
override val empty_templates_list_message: String = "Templates padisponueshëm."
override val enabled: String = "Aktivizuar"
override val entry: String = "hyrje"
override val equity: String = "kapitali neto"
override val estimate_number: String = "Vlerësimi #"
override val estimates: String = "Vlerësimet"
override val estimates_report: String = "Vlerësimet"
override val expense_bank_fees: String = "Tarifat bankare"
override val expense_benefits: String = "Përfitimet"
override val expense_communication: String = "Komunikimi"
override val expense_debt: String = "Borxh"
override val expense_deleted: String = "Shpenzimet u fshinë"
override val expense_depreciation: String = "amortizimi"
override val expense_gifts: String = "Dhurata"
override val expense_insurance: String = "Sigurimi"
override val expense_inventory: String = "Inventari"
override val expense_legal: String = "Ligjore"
override val expense_maintenance: String = "Mirëmbajtja"
override val expense_marketing: String = "Marketingu"
override val expense_meals: String = "Ushqimet"
override val expense_office_supplies: String = "Pajisjet e zyrës"
override val expense_rent: String = "Qira"
override val expense_salaries: String = "Pagat"
override val expense_shopping: String = "Pazar"
override val expense_software: String = "Software"
override val expense_status_available: String = "Në dispozicion"
override val expense_status_processing: String = "Përpunimi"
override val expense_tax: String = "Taksa"
override val expense_training: String = "Trajnimi"
override val expense_transport: String = "Transporti"
override val expense_travel: String = "Udhëtim"
override val expense_utility: String = "Shërbimet"
override val expenses: String = "Shpenzimet"
override val feedback_option: String = "Reagime"
override val filter: String = "Filtri"
override val first_name_hint: String = "Emri i parë"
override val forgot_password: String = "Rivendos fjalëkalimin"
override val forgot_password_label: String = "Keni harruar fjalëkalimin"
override val from: String = "Nga"
override val general_error: String = "Ndodhi një gabim, provoni përsëri më vonë."
override val general_ledger: String = "libri kryesor"
override val go_back_label: String = "Kthehu mbrapa"
override val group_by: String = "Grupi Sipas:"
override val image_view: String = "Imazh"
override val install: String = "Instalo"
override val invalid_credentials_error: String = "Kredencialet e pavlefshme."
override val invalid_email_error: String = "Email i pavlefshëm."
override val invalid_user_error: String = "Kredencialet e pavlefshme të përdoruesit."
override val invoice_deleted_message: String = "Fatura fshihet"
override val invoice_menu: String = "Menuja e faturës"
override val invoice_number: String = "Fatura #"
override val invoices: String = "Faturat"
override val items_option: String = "Artikuj"
override val keep: String = "Mbani"
override val language_selector: Formattable = Formattable { (selected) -> "Gjuha ${selected}" }
override val last_name_hint: String = "Mbiemri"
override val ledger: String = "libër llogarish"
override val legal: String = "Ligjore"
override val legal_error: String = "Duhet të pranojë Kushtet e Shërbimit dhe Politika e Privatësisë"
override val liabilities: String = "detyrimet"
override val loading: String = "Duke u ngarkuar"
override val login_required_error: String = "Identifikohu kërkohet."
override val logo_view: String = "Logoja"
override val logout: String = "Logout"
override val logout_prompt: String = "Të dhënat tuaja nuk sinkronizohen me re. Fshini faturat, vlerësimet, klientët dhe informacione të tjera mbi logout?"
override val main_screen: String = "Ekrani kryesor"
override val mark_paid: String = "Shënoni si të paguar"
override val message: String = "Mesazh"
override val missing_sender_msg: String = "Një dokument pa informacion të dërguesit mund të jetë i pavlefshëm."
override val missing_sender_title: String = "Informacioni i dërguesit që mungon"
override val month_april: String = "prill"
override val month_april_short: String = "prill"
override val month_august: String = "gusht"
override val month_august_short: String = "gusht."
override val month_december: String = "dhjetor"
override val month_december_short: String = "Dhjetor."
override val month_february: String = "shkurt"
override val month_february_short: String = "Shkurt."
override val month_january: String = "janar"
override val month_january_short: String = "janar."
override val month_july: String = "korrik"
override val month_july_short: String = "korrik"
override val month_june: String = "qershor"
override val month_june_short: String = "qershor"
override val month_march: String = "marsh"
override val month_march_short: String = "marsh"
override val month_may: String = "Maj"
override val month_may_short: String = "Maj"
override val month_november: String = "nëntor"
override val month_november_short: String = "Nëntor."
override val month_october: String = "tetor"
override val month_october_short: String = "Tetor"
override val month_september: String = "shtator"
override val month_september_short: String = "Shtator."
override val multifactor_error: String = "Dështoi për të verifikuar."
override val new: String = "I ri"
override val news: String = "Lajme"
override val next: String = "Tjetra"
override val no: String = "jo"
override val no_data_available: String = "Nuk ka të dhëna në dispozicion"
override val no_search_results: String = "Na vjen keq, ne nuk mund të gjeni ndonjë rezultat."
override val none: String = "Asnje"
override val note_fields: String = "Shënime"
override val note_hint: String = "Shënim"
override val notifications_option: String = "Njoftimet"
override val ok: String = "OK"
override val onboarding: String = "Hyrja në bord"
override val onboarding_aboutus_message: String = "Pjesët thelbësore të DingVoice janë përgjithmonë falas. Misioni ynë është t&#39;ju ndihmojmë të rritni biznesin tuaj, të na ndihmoni të përmirësohemi duke ndarë komentet tuaja."
override val onboarding_aboutus_title: String = "Ne jemi këtu për të ndihmuar"
override val onboarding_paid_1: String = "Hyni në shabllone me pamje profesionale"
override val onboarding_paid_2: String = "Hidhni një vështrim më të thellë në financat tuaja"
override val onboarding_paid_3: String = "Zgjidhini problemet shpejt"
override val onboarding_paid_4: String = "Mbani dokumentet tuaja të sigurta"
override val onboarding_paid_5: String = "Qëndroni të përditësuar me ndryshimet e statusit të faturës"
override val onboarding_paid_title: String = "Rriteni edhe më shpejt me..."
override val onboarding_paywall_message: String = "Shijoni të gjitha veçoritë premium tani me çmimin më të mirë."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Vetëm ${currencySymbol}${introAmount} për ${introDuration} ${introPeriod} dhe më pas ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Vetëm ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Falas për ${trialDuration} ${trialPeriod} dhe më pas vetëm ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Falas për${trialDuration}${trialPeriod} pastaj${currencySymbol}${introductoryAmount} për${introductoryDuration}${introPeriod} dhe pastaj vetëm${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "Auto-rinovueshme. Anulo në çdo kohë."
override val onboarding_paywall_restore: String = "Rivendos blerjen"
override val onboarding_paywall_restore_failure: String = "Rivendosja e blerjeve dështoi, provo sërish më vonë ose kontakto mbështetjen."
override val onboarding_paywall_restore_success: String = "Rivendosja me sukses!"
override val onboarding_paywall_title: String = "Arri madhështinë!"
override val onboarding_welcome_message: String = "Një grup i plotë mjetesh për të menaxhuar faturat dhe vlerësimet tuaja."
override val onboarding_welcome_title: String = "Mirë se vini në DingVoice"
override val other_state: String = "Tjetër"
override val overdue_state: String = "Prapambetur"
override val paid_state: String = "Paguar"
override val password: String = "Fjalëkalimi"
override val password_blank_error: String = "Kërkohet fjalëkalimi."
override val password_mismatch_error: String = "Fjalëkalimet nuk përputhen."
override val pay: String = "paguaj"
override val payment_instructions_label: String = "Udhëzimet e pagesës"
override val payment_reminders: String = "Përkujtuesit e pagesave"
override val phone_number_fields: String = "Numri i telefonit"
override val phone_number_hint: String = "Numri i telefonit"
override val postcode_hint: String = "Kodi Postar"
override val preview: String = "inspektim"
override val preview_menu_hint: String = "Menuja paraprake"
override val price: String = "Çmimi"
override val privacy_policy: String = "Politika e privatësisë"
override val profile: String = "Profili"
override val profitable_client: String = "Klienti juaj më fitimprurës është..."
override val profits_report: String = "Fitimet"
override val promo_content: String = "Përmbajtja promovuese"
override val quantity_hint: String = "Sasia"
override val quantity_label_hint: String = "Etiketa e sasisë"
override val query_info: String = "Query ju lejon të shkruani llogaritjet me porosi duke iu referuar të dy artikujve dhe rregullave të tjera të taksave si inpute. Shtoni @ simbolin dhe artikullin ose përshkrimin e taksave për të lidhur me një hyrje tjetër +, -, *,/,% - operatorët e matematikës"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} u prit nga klienti juaj." }
override val received_state: String = "Pranuar"
override val registration_code_fields: String = "Kodi i Regjistrimit"
override val reminder: String = "Kujtuesi"
override val reports: String = "Raportet"
override val reports_not_available: String = "Nuk ka raporte në dispozicion, kthehen më vonë."
override val reveal_password: String = "Zbuloni fjalëkalimin"
override val revenue: String = "të ardhurat"
override val sales_tax: String = "TVSH"
override val sales_tax_number: String = "Numri i TVSH-së"
override val save: String = "Ruaje"
override val scan_document: String = "Skanoni dokumentin"
override val search: String = "Kërko"
override val select: String = "Zgjidhni"
override val select_icon: String = "Zgjidhni ikonën"
override val select_signature: String = "Zgjidh nënshkrimin"
override val select_template: String = "Zgjidhni shabllonin"
override val send: String = "Dërgo"
override val sent_state: String = "Dërguar"
override val setup_sender: String = "Vendosni dërguesin"
override val share_option: String = "Share"
override val ship_to: String = "Anije në"
override val sign_in: String = "Identifikohu"
override val sign_in_apple: String = "Identifikohu me Apple"
override val sign_in_email: String = "Identifikohu me email"
override val sign_in_facebook: String = "Hyr me Facebook"
override val sign_in_google: String = "Identifikohu me Google"
override val sign_up: String = "Regjistrohu"
override val sign_up_label: String = "A nuk keni një llogari?"
override val sign_up_link_label: String = "Regjistrohu!"
override val signatures_option: String = "Nënshkrimet"
override val skip: String = "Kapërce"
override val sold_items_report: String = "Artikujt e Shitur"
override val sort_by: String = "Rendit Sipas:"
override val status_update: String = "Azhurnimi i statusit të"
override val sub_annual: String = "Vjetor"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/vit" }
override val sub_backups: String = "Kopje rezervë në re"
override val sub_insights: String = "Vështrime"
override val sub_month: String = "Mujor"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/muaj" }
override val sub_notifications: String = "Njoftimet"
override val sub_popup_message: String = "Abonimet po vijnë së shpejti. Kontrolloni përsëri këtu për pak kohë."
override val sub_support: String = "Mbështetje"
override val sub_templates: String = "Shabllone"
override val subscribe: String = "Regjistrohu"
override val subscription_header: String = "Rritni biznesin tuaj me:"
override val subscription_option: String = "Abonim"
override val subscriptions_load_fail: String = "Na vjen keq, ne nuk mund t&#39;i rikuperojmë abonimet tuaja në këtë moment. Ju lutemi provoni përsëri më vonë ose kontaktoni ekipin tonë të mbështetjes për ndihmë."
override val subtotal: Formattable = Formattable { (sum) -> "Nëntotali: ${sum}" }
override val subtotal_label: String = "Nëntotali"
override val sum: String = "Shuma"
override val support_option: String = "Mbështetje"
override val switch_reports: String = "Ndrysho Raportin"
override val tax: String = "Tatimi"
override val tax_deleted_message: String = "Tatimi fshihet"
override val tax_description_warning: String = "Nëse përdoret, kjo shfaqet në një faturë"
override val tax_input_flat: String = "I sheshtë"
override val tax_input_percent: String = "Përqindja"
override val tax_number_fields: String = "Numri i Taksave"
override val tax_number_hint: String = "Numri i Taksave"
override val tax_query_info_description: String = "Informacione për pyetjen"
override val tax_report: String = "Taksa"
override val tax_rules_option: String = "Rregullat Tatimore"
override val taxed_clients_report: String = "Klientët e tatosur"
override val template: String = "Shabllon"
override val template_selector_info: String = "Rrëshqitni për të ndryshuar modelin e paracaktuar"
override val template_unlock_promo: String = "Aksesoni më shumë shabllone me Premium"
override val templates_option: String = "Shabllone"
override val terms_of_service: String = "Kushtet e Shërbimit"
override val thank_you: String = "Faleminderit"
override val to: String = "Për"
override val total: Formattable = Formattable { (sum) -> "Gjithsej: ${sum}" }
override val total_label: String = "Gjithsej"
override val undo_action: String = "Zhbëj"
override val unit: String = "Njësia"
override val unit_cost_hint: String = "Kostoja e njësisë"
override val unknown_client: String = "Klienti i panjohur"
override val unlock: String = "Hape"
override val unnamed_item: String = "Artikulli pa emër"
override val upgrade: String = "Upgrade"
override val user_collision_error: String = "Llogaria tashmë ekziston."
override val valid_until: String = "Vlefshme Deri"
override val warning: String = "paralajmërim!"
override val yes: String = "po"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Pranoni ${termsOfService} dhe ${privacyPolicy}" },
	"account" to "llogari",
	"accounting" to "kontabiliteti",
	"accruals" to "akruale",
	"action_code_error" to "Kodi i pavlefshëm.",
	"add_client_action" to "Shto klient",
	"add_customer" to "Shto konsumatorin",
	"add_expense" to "Shto shpenzime",
	"add_fields_label" to "Shto:",
	"add_invoice_action" to "Krijo",
	"add_item_action" to "Shto artikull",
	"add_item_button" to "Shto artikull",
	"add_signature_action" to "Shto nënshkrimin",
	"add_tax_action" to "Shto taksën",
	"address_fields" to "Adresa",
	"address_line_hint" to "Linja e adresës",
	"advanced" to "Avancuar",
	"agree_marketing" to "Unë pranoj të marr Komunikime të Drejtpërdrejta të Marketingut",
	"all_notifications" to "Të gjitha njoftimet",
	"amortization" to "amortizimi",
	"amount" to "Shuma",
	"asset" to "aktiv",
	"attach_file" to "Bashkangjit skedarin",
	"attachment" to "Bashkëngjitje",
	"auth_error" to "Veprimi dështoi, provoni përsëri.",
	"available_for_paid" to "Sync Cloud është në dispozicion vetëm nën abonim të paguar.",
	"backups_option" to "Kopje rezervë",
	"balance" to "bilanci",
	"bank_account" to "Llogari Bankare",
	"bill_number" to "Numri i Faturës",
	"cancel" to "Anuloj",
	"cancel_trial" to "Anuloni në çdo kohë",
	"capital" to "kapitali",
	"cash" to "para të gatshme",
	"categories" to "Kategoritë",
	"category" to "Kategoria",
	"category_add" to "Shto kategori",
	"category_delete" to "Fshi kategorinë",
	"category_name" to "Emri i kategorisë",
	"change_language" to Formattable { (language) -> "Gjuha e faturës: ${language}" },
	"change_language_label" to "Gjuha e Faturës",
	"change_template" to "Ndrysho modelin",
	"city_hint" to "Qytet",
	"clear" to "Qartë",
	"client" to "Klienti",
	"client_actions" to "Veprimet e klientit",
	"client_deleted_message" to "Klienti fshihet",
	"clients_error_required_fields" to "Kërkohet të paktën një vlerë.",
	"clients_option" to "Klientët",
	"close" to "Mbylle",
	"cloud_sync" to "Sinkronizimi i reve",
	"coming_soon" to "Vjen së shpejti!",
	"company_logo" to "Logoja e kompanisë",
	"company_name_hint" to "kompania",
	"confirm_password_label" to "Konfirmo fjalëkalimin",
	"confirmation_title" to "Jeni i sigurtë?",
	"convert_to_invoice" to "Konvertoni në faturë",
	"country_hint" to "Shteti",
	"create_client" to "Krijo Klientin",
	"create_company" to "Krijoni kompani",
	"create_document" to "Krijo dokument",
	"create_estimate" to "Vlerësim",
	"create_invoice" to "Faturë",
	"create_item" to "Krijo artikull",
	"create_new_client" to "Krijo klient të ri",
	"create_new_item" to "Krijo artikull të ri",
	"create_new_tax" to "Krijoni taksë të re",
	"create_signature" to "Nënshkrimi",
	"created" to "Krijuar",
	"credit" to "krediti",
	"customer" to "Konsumatori",
	"date" to "Data",
	"debit" to "debiti",
	"debt" to "borxh",
	"default_currency" to Formattable { (currency) -> "Monedha e paracaktuar: ${currency}" },
	"default_currency_label" to "Monedha e paracaktuar",
	"delete" to "Fshi",
	"delete_account" to "Fshi Llogarinë",
	"delete_confirmation_message" to "Të gjitha të dhënat tuaja do të humbasin.",
	"delete_item_title" to "Fshini artikullin?",
	"delete_signature" to "Fshi nënshkrimin",
	"depreciation" to "amortizimi",
	"description" to "Përshkrimi",
	"description_hint" to "Përshkrimi",
	"detailed_report" to "Raport i Detajuar",
	"disabled" to "I paaftë",
	"discount" to "Zbritje",
	"discount_hint" to "Zbritje",
	"display_af" to "Afrikanisht",
	"display_am" to "Amhariq",
	"display_ar" to "arabisht",
	"display_as" to "Assamese",
	"display_az" to "Azerbajxhanit",
	"display_be" to "Belarusian",
	"display_bg" to "Bullgarisht",
	"display_bn" to "Bengali",
	"display_bs" to "boshnjake",
	"display_ca" to "Katalanisht",
	"display_cs" to "çek",
	"display_da" to "daneze",
	"display_de" to "gjermanisht",
	"display_el" to "grek",
	"display_en" to "anglisht",
	"display_es" to "spanjoll",
	"display_es_MX" to "Spanjisht (Meksikë)",
	"display_et" to "Estonisht",
	"display_eu" to "baske",
	"display_fa" to "persisht",
	"display_fi" to "Finlandisht",
	"display_fil" to "Tagalogisht",
	"display_fr" to "frëngjisht",
	"display_fr_CA" to "Frëngjisht kanadez",
	"display_gl" to "Galician",
	"display_gu" to "Gujarati",
	"display_he" to "hebraike",
	"display_hi" to "Hindi",
	"display_hr" to "kroate",
	"display_hu" to "hungareze",
	"display_hy" to "armen",
	"display_id" to "Indonezisht",
	"display_is" to "islandeze",
	"display_it" to "italian",
	"display_ja" to "japonez",
	"display_ka" to "gjeorgjiane",
	"display_kk" to "Kazakistanit",
	"display_km" to "Khmer Qendror",
	"display_kn" to "Kannada",
	"display_ko" to "korean",
	"display_ky" to "Kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Lituanisht",
	"display_lv" to "Letonisht",
	"display_mk" to "maqedonase",
	"display_ml" to "Malajalam",
	"display_mn" to "Mongolian",
	"display_mr" to "Marathi",
	"display_ms" to "Malajese",
	"display_my" to "Burmese",
	"display_nb" to "Norvegjeze Bokmål",
	"display_ne" to "Nepaleze",
	"display_nl" to "holandez",
	"display_or" to "Oriya",
	"display_pa" to "Punxhabi",
	"display_pl" to "lustër",
	"display_pt" to "portugalisht",
	"display_pt_BR" to "Portugalisht (Brazil)",
	"display_pt_PT" to "Portugalisht evropiane",
	"display_ro" to "rumune",
	"display_ru" to "Rus",
	"display_si" to "Sinhala",
	"display_sk" to "Sllovake",
	"display_sl" to "sllovene",
	"display_sq" to "shqiptare",
	"display_sr" to "serbe",
	"display_sv" to "suedisht",
	"display_sw" to "suahili",
	"display_ta" to "Tamile",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "turk",
	"display_uk" to "ukrainas",
	"display_ur" to "urdu",
	"display_uz" to "Uzbekisht",
	"display_vi" to "Vietnamese",
	"display_zh_Hant_TW" to "Kineze (Tajvan, Tradicionale)",
	"display_zu" to "Zulu",
	"document_estimate" to "Vlerësim",
	"document_invoice" to "Faturë",
	"document_quote" to "Oferta",
	"done" to "U krye",
	"download_app" to "Shkarkoni aplikacionin",
	"download_invoice" to "Shkarko Faturën",
	"download_pdf" to "Shkarko PDF",
	"draw_signature" to "Vizatoni një nënshkrim",
	"due" to Formattable { (date) -> "shkak ${date}" },
	"due_date" to "Data e duhur",
	"due_day" to "në një ditë",
	"due_label" to "për shkak",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} është për shkak ${dueWhen}!" },
	"due_today" to "sot",
	"due_week" to "në një javë",
	"duplicate" to "Kopjoni",
	"duration_day" to "ditë",
	"duration_month" to "Muaj",
	"duration_week" to "javë",
	"duration_year" to "vit",
	"email" to "Email",
	"email_blank_error" to "Email nevojshme.",
	"email_error" to "Adresa e pavlefshme e postës elektronike.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Faleminderit për biznesin tuaj. Për të parë të plotë ${documentType} klikoni butonin në fund të kësaj letre. Vini re se është e vlefshme deri në ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Ju keni marrë një të ri ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "SHIKO ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Të nderuar ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Faleminderit për biznesin tuaj. Për të parë të plotë ${documentType} klikoni butonin në fund të kësaj letre. Ju lutemi paguani bilancin deri në ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Ju keni marrë një të ri ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Email për të rivendosur fjalëkalimin dërguar, ju lutem kontrolloni kutinë tuaj.",
	"empty_clients_list_message" to "Ju nuk keni klientë për të ndjekur. Shto një klient të ri.",
	"empty_estimate_list_message" to "Krijo vlerësimin tuaj të parë",
	"empty_expenses" to "Nuk keni shpenzime për të gjurmuar. Shto një shpenzim të ri.",
	"empty_invoice_list_message" to "Krijoni faturën tuaj të parë",
	"empty_items_list_message" to "Ju nuk keni artikuj, shtoni një artikull të ri!",
	"empty_signatures_list_message" to "Asnjë nënshkrim nuk u gjet. Krijo një!",
	"empty_taxes_list_message" to "Ju nuk keni rregulla tatimore. Shto një rregull të ri tatimor.",
	"empty_templates_list_message" to "Templates padisponueshëm.",
	"enabled" to "Aktivizuar",
	"entry" to "hyrje",
	"equity" to "kapitali neto",
	"estimate_number" to "Vlerësimi #",
	"estimates" to "Vlerësimet",
	"estimates_report" to "Vlerësimet",
	"expense_bank_fees" to "Tarifat bankare",
	"expense_benefits" to "Përfitimet",
	"expense_communication" to "Komunikimi",
	"expense_debt" to "Borxh",
	"expense_deleted" to "Shpenzimet u fshinë",
	"expense_depreciation" to "amortizimi",
	"expense_gifts" to "Dhurata",
	"expense_insurance" to "Sigurimi",
	"expense_inventory" to "Inventari",
	"expense_legal" to "Ligjore",
	"expense_maintenance" to "Mirëmbajtja",
	"expense_marketing" to "Marketingu",
	"expense_meals" to "Ushqimet",
	"expense_office_supplies" to "Pajisjet e zyrës",
	"expense_rent" to "Qira",
	"expense_salaries" to "Pagat",
	"expense_shopping" to "Pazar",
	"expense_software" to "Software",
	"expense_status_available" to "Në dispozicion",
	"expense_status_processing" to "Përpunimi",
	"expense_tax" to "Taksa",
	"expense_training" to "Trajnimi",
	"expense_transport" to "Transporti",
	"expense_travel" to "Udhëtim",
	"expense_utility" to "Shërbimet",
	"expenses" to "Shpenzimet",
	"feedback_option" to "Reagime",
	"filter" to "Filtri",
	"first_name_hint" to "Emri i parë",
	"forgot_password" to "Rivendos fjalëkalimin",
	"forgot_password_label" to "Keni harruar fjalëkalimin",
	"from" to "Nga",
	"general_error" to "Ndodhi një gabim, provoni përsëri më vonë.",
	"general_ledger" to "libri kryesor",
	"go_back_label" to "Kthehu mbrapa",
	"group_by" to "Grupi Sipas:",
	"image_view" to "Imazh",
	"install" to "Instalo",
	"invalid_credentials_error" to "Kredencialet e pavlefshme.",
	"invalid_email_error" to "Email i pavlefshëm.",
	"invalid_user_error" to "Kredencialet e pavlefshme të përdoruesit.",
	"invoice_deleted_message" to "Fatura fshihet",
	"invoice_menu" to "Menuja e faturës",
	"invoice_number" to "Fatura #",
	"invoices" to "Faturat",
	"items_option" to "Artikuj",
	"keep" to "Mbani",
	"language_selector" to Formattable { (selected) -> "Gjuha ${selected}" },
	"last_name_hint" to "Mbiemri",
	"ledger" to "libër llogarish",
	"legal" to "Ligjore",
	"legal_error" to "Duhet të pranojë Kushtet e Shërbimit dhe Politika e Privatësisë",
	"liabilities" to "detyrimet",
	"loading" to "Duke u ngarkuar",
	"login_required_error" to "Identifikohu kërkohet.",
	"logo_view" to "Logoja",
	"logout" to "Logout",
	"logout_prompt" to "Të dhënat tuaja nuk sinkronizohen me re. Fshini faturat, vlerësimet, klientët dhe informacione të tjera mbi logout?",
	"main_screen" to "Ekrani kryesor",
	"mark_paid" to "Shënoni si të paguar",
	"message" to "Mesazh",
	"missing_sender_msg" to "Një dokument pa informacion të dërguesit mund të jetë i pavlefshëm.",
	"missing_sender_title" to "Informacioni i dërguesit që mungon",
	"month_april" to "prill",
	"month_april_short" to "prill",
	"month_august" to "gusht",
	"month_august_short" to "gusht.",
	"month_december" to "dhjetor",
	"month_december_short" to "Dhjetor.",
	"month_february" to "shkurt",
	"month_february_short" to "Shkurt.",
	"month_january" to "janar",
	"month_january_short" to "janar.",
	"month_july" to "korrik",
	"month_july_short" to "korrik",
	"month_june" to "qershor",
	"month_june_short" to "qershor",
	"month_march" to "marsh",
	"month_march_short" to "marsh",
	"month_may" to "Maj",
	"month_may_short" to "Maj",
	"month_november" to "nëntor",
	"month_november_short" to "Nëntor.",
	"month_october" to "tetor",
	"month_october_short" to "Tetor",
	"month_september" to "shtator",
	"month_september_short" to "Shtator.",
	"multifactor_error" to "Dështoi për të verifikuar.",
	"new" to "I ri",
	"news" to "Lajme",
	"next" to "Tjetra",
	"no" to "jo",
	"no_data_available" to "Nuk ka të dhëna në dispozicion",
	"no_search_results" to "Na vjen keq, ne nuk mund të gjeni ndonjë rezultat.",
	"none" to "Asnje",
	"note_fields" to "Shënime",
	"note_hint" to "Shënim",
	"notifications_option" to "Njoftimet",
	"ok" to "OK",
	"onboarding" to "Hyrja në bord",
	"onboarding_aboutus_message" to "Pjesët thelbësore të DingVoice janë përgjithmonë falas. Misioni ynë është t&#39;ju ndihmojmë të rritni biznesin tuaj, të na ndihmoni të përmirësohemi duke ndarë komentet tuaja.",
	"onboarding_aboutus_title" to "Ne jemi këtu për të ndihmuar",
	"onboarding_paid_1" to "Hyni në shabllone me pamje profesionale",
	"onboarding_paid_2" to "Hidhni një vështrim më të thellë në financat tuaja",
	"onboarding_paid_3" to "Zgjidhini problemet shpejt",
	"onboarding_paid_4" to "Mbani dokumentet tuaja të sigurta",
	"onboarding_paid_5" to "Qëndroni të përditësuar me ndryshimet e statusit të faturës",
	"onboarding_paid_title" to "Rriteni edhe më shpejt me...",
	"onboarding_paywall_message" to "Shijoni të gjitha veçoritë premium tani me çmimin më të mirë.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Vetëm ${currencySymbol}${introAmount} për ${introDuration} ${introPeriod} dhe më pas ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Vetëm ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Falas për ${trialDuration} ${trialPeriod} dhe më pas vetëm ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Falas për${trialDuration}${trialPeriod} pastaj${currencySymbol}${introductoryAmount} për${introductoryDuration}${introPeriod} dhe pastaj vetëm${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "Auto-rinovueshme. Anulo në çdo kohë.",
	"onboarding_paywall_restore" to "Rivendos blerjen",
	"onboarding_paywall_restore_failure" to "Rivendosja e blerjeve dështoi, provo sërish më vonë ose kontakto mbështetjen.",
	"onboarding_paywall_restore_success" to "Rivendosja me sukses!",
	"onboarding_paywall_title" to "Arri madhështinë!",
	"onboarding_welcome_message" to "Një grup i plotë mjetesh për të menaxhuar faturat dhe vlerësimet tuaja.",
	"onboarding_welcome_title" to "Mirë se vini në DingVoice",
	"other_state" to "Tjetër",
	"overdue_state" to "Prapambetur",
	"paid_state" to "Paguar",
	"password" to "Fjalëkalimi",
	"password_blank_error" to "Kërkohet fjalëkalimi.",
	"password_mismatch_error" to "Fjalëkalimet nuk përputhen.",
	"pay" to "paguaj",
	"payment_instructions_label" to "Udhëzimet e pagesës",
	"payment_reminders" to "Përkujtuesit e pagesave",
	"phone_number_fields" to "Numri i telefonit",
	"phone_number_hint" to "Numri i telefonit",
	"postcode_hint" to "Kodi Postar",
	"preview" to "inspektim",
	"preview_menu_hint" to "Menuja paraprake",
	"price" to "Çmimi",
	"privacy_policy" to "Politika e privatësisë",
	"profile" to "Profili",
	"profitable_client" to "Klienti juaj më fitimprurës është...",
	"profits_report" to "Fitimet",
	"promo_content" to "Përmbajtja promovuese",
	"quantity_hint" to "Sasia",
	"quantity_label_hint" to "Etiketa e sasisë",
	"query_info" to "Query ju lejon të shkruani llogaritjet me porosi duke iu referuar të dy artikujve dhe rregullave të tjera të taksave si inpute. Shtoni @ simbolin dhe artikullin ose përshkrimin e taksave për të lidhur me një hyrje tjetër +, -, *,/,% - operatorët e matematikës",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} u prit nga klienti juaj." },
	"received_state" to "Pranuar",
	"registration_code_fields" to "Kodi i Regjistrimit",
	"reminder" to "Kujtuesi",
	"reports" to "Raportet",
	"reports_not_available" to "Nuk ka raporte në dispozicion, kthehen më vonë.",
	"reveal_password" to "Zbuloni fjalëkalimin",
	"revenue" to "të ardhurat",
	"sales_tax" to "TVSH",
	"sales_tax_number" to "Numri i TVSH-së",
	"save" to "Ruaje",
	"scan_document" to "Skanoni dokumentin",
	"search" to "Kërko",
	"select" to "Zgjidhni",
	"select_icon" to "Zgjidhni ikonën",
	"select_signature" to "Zgjidh nënshkrimin",
	"select_template" to "Zgjidhni shabllonin",
	"send" to "Dërgo",
	"sent_state" to "Dërguar",
	"setup_sender" to "Vendosni dërguesin",
	"share_option" to "Share",
	"ship_to" to "Anije në",
	"sign_in" to "Identifikohu",
	"sign_in_apple" to "Identifikohu me Apple",
	"sign_in_email" to "Identifikohu me email",
	"sign_in_facebook" to "Hyr me Facebook",
	"sign_in_google" to "Identifikohu me Google",
	"sign_up" to "Regjistrohu",
	"sign_up_label" to "A nuk keni një llogari?",
	"sign_up_link_label" to "Regjistrohu!",
	"signatures_option" to "Nënshkrimet",
	"skip" to "Kapërce",
	"sold_items_report" to "Artikujt e Shitur",
	"sort_by" to "Rendit Sipas:",
	"status_update" to "Azhurnimi i statusit të",
	"sub_annual" to "Vjetor",
	"sub_annual_price" to Formattable { (price) -> "${price}/vit" },
	"sub_backups" to "Kopje rezervë në re",
	"sub_insights" to "Vështrime",
	"sub_month" to "Mujor",
	"sub_monthly_price" to Formattable { (price) -> "${price}/muaj" },
	"sub_notifications" to "Njoftimet",
	"sub_popup_message" to "Abonimet po vijnë së shpejti. Kontrolloni përsëri këtu për pak kohë.",
	"sub_support" to "Mbështetje",
	"sub_templates" to "Shabllone",
	"subscribe" to "Regjistrohu",
	"subscription_header" to "Rritni biznesin tuaj me:",
	"subscription_option" to "Abonim",
	"subscriptions_load_fail" to "Na vjen keq, ne nuk mund t&#39;i rikuperojmë abonimet tuaja në këtë moment. Ju lutemi provoni përsëri më vonë ose kontaktoni ekipin tonë të mbështetjes për ndihmë.",
	"subtotal" to Formattable { (sum) -> "Nëntotali: ${sum}" },
	"subtotal_label" to "Nëntotali",
	"sum" to "Shuma",
	"support_option" to "Mbështetje",
	"switch_reports" to "Ndrysho Raportin",
	"tax" to "Tatimi",
	"tax_deleted_message" to "Tatimi fshihet",
	"tax_description_warning" to "Nëse përdoret, kjo shfaqet në një faturë",
	"tax_input_flat" to "I sheshtë",
	"tax_input_percent" to "Përqindja",
	"tax_number_fields" to "Numri i Taksave",
	"tax_number_hint" to "Numri i Taksave",
	"tax_query_info_description" to "Informacione për pyetjen",
	"tax_report" to "Taksa",
	"tax_rules_option" to "Rregullat Tatimore",
	"taxed_clients_report" to "Klientët e tatosur",
	"template" to "Shabllon",
	"template_selector_info" to "Rrëshqitni për të ndryshuar modelin e paracaktuar",
	"template_unlock_promo" to "Aksesoni më shumë shabllone me Premium",
	"templates_option" to "Shabllone",
	"terms_of_service" to "Kushtet e Shërbimit",
	"thank_you" to "Faleminderit",
	"to" to "Për",
	"total" to Formattable { (sum) -> "Gjithsej: ${sum}" },
	"total_label" to "Gjithsej",
	"undo_action" to "Zhbëj",
	"unit" to "Njësia",
	"unit_cost_hint" to "Kostoja e njësisë",
	"unknown_client" to "Klienti i panjohur",
	"unlock" to "Hape",
	"unnamed_item" to "Artikulli pa emër",
	"upgrade" to "Upgrade",
	"user_collision_error" to "Llogaria tashmë ekziston.",
	"valid_until" to "Vlefshme Deri",
	"warning" to "paralajmërim!",
	"yes" to "po",
    )
    
}