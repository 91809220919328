// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Uk  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Прийняти ${termsOfService} та ${privacyPolicy}" }
override val account: String = "обліковий запис"
override val accounting: String = "бухгалтерський облік"
override val accruals: String = "нарахування"
override val action_code_error: String = "Неприпустимий код."
override val add_client_action: String = "Додати клієнт"
override val add_customer: String = "Додати клієнта"
override val add_expense: String = "Додати витрати"
override val add_fields_label: String = "Додати:"
override val add_invoice_action: String = "Творити"
override val add_item_action: String = "Додати елемент"
override val add_item_button: String = "Додати елемент"
override val add_signature_action: String = "Додати підпис"
override val add_tax_action: String = "Додати податок"
override val address_fields: String = "Адреса"
override val address_line_hint: String = "Рядок адреси"
override val advanced: String = "Передовий"
override val agree_marketing: String = "Я згоден отримувати прямі маркетингові комунікації"
override val all_notifications: String = "Усі сповіщення"
override val amortization: String = "амортизація"
override val amount: String = "кількість"
override val asset: String = "актив"
override val attach_file: String = "Прикріпити файл"
override val attachment: String = "Вкладення"
override val auth_error: String = "Не вдалося виконати дію, повторіть спробу."
override val available_for_paid: String = "Синхронізація в хмарі доступна лише за платною підпискою."
override val backups_option: String = "Резервні копії"
override val balance: String = "баланс"
override val bank_account: String = "Банківський Pахунок"
override val bill_number: String = "Номер рахунку"
override val cancel: String = "скасувати"
override val cancel_trial: String = "Скасувати в будь-який час"
override val capital: String = "капітал"
override val cash: String = "готівка"
override val categories: String = "Категорії"
override val category: String = "Категорія"
override val category_add: String = "Додати категорію"
override val category_delete: String = "Видалити категорію"
override val category_name: String = "Назва категорії"
override val change_language: Formattable = Formattable { (language) -> "Мова рахунка-фактури: ${language}" }
override val change_language_label: String = "Мова Rахунків-фактур"
override val change_template: String = "Змінити шаблон"
override val city_hint: String = "Місто"
override val clear: String = "ясний"
override val client: String = "Клієнт"
override val client_actions: String = "Дії клієнта"
override val client_deleted_message: String = "Клієнт видалено"
override val clients_error_required_fields: String = "Потрібно принаймні одне значення."
override val clients_option: String = "Клієнтів"
override val close: String = "Закрити"
override val cloud_sync: String = "Синхронізація хмари"
override val coming_soon: String = "Незабаром!"
override val company_logo: String = "Логотип компанії"
override val company_name_hint: String = "Компанія"
override val confirm_password_label: String = "Підтвердження пароля"
override val confirmation_title: String = "Ви впевнені?"
override val convert_to_invoice: String = "Перетворити на рахунок-фактуру"
override val country_hint: String = "Країна"
override val create_client: String = "Створити клієнта"
override val create_company: String = "Створити компанію"
override val create_document: String = "Створення документа"
override val create_estimate: String = "Оцінка"
override val create_invoice: String = "Рахунок"
override val create_item: String = "Створити елемент"
override val create_new_client: String = "Створити новий клієнт"
override val create_new_item: String = "Створення нового елемента"
override val create_new_tax: String = "Створення нового податку"
override val create_signature: String = "Підпис"
override val created: String = "Створений"
override val credit: String = "кредит"
override val customer: String = "Клієнтів"
override val date: String = "Дата"
override val debit: String = "дебет"
override val debt: String = "борг"
override val default_currency: Formattable = Formattable { (currency) -> "Валюта за умовчанням: ${currency}" }
override val default_currency_label: String = "Валюта за замовчуванням"
override val delete: String = "Видалити"
override val delete_account: String = "Видалити Обліковий запис"
override val delete_confirmation_message: String = "Усі ваші дані буде втрачено."
override val delete_item_title: String = "Видалити елемент?"
override val delete_signature: String = "Видалити підпис"
override val depreciation: String = "амортизація"
override val description: String = "Опис"
override val description_hint: String = "Опис"
override val detailed_report: String = "Докладний Звіт"
override val disabled: String = "Вимкнуто"
override val discount: String = "Знижка"
override val discount_hint: String = "Знижка"
override val display_af: String = "Африкаанс"
override val display_am: String = "Амхарська"
override val display_ar: String = "Арабська"
override val display_as: String = "Ассамська"
override val display_az: String = "Азербайджанська"
override val display_be: String = "Білоруська"
override val display_bg: String = "Болгарська"
override val display_bn: String = "Бенгальська"
override val display_bs: String = "Боснійська"
override val display_ca: String = "Каталонська"
override val display_cs: String = "Чеська"
override val display_da: String = "Датська"
override val display_de: String = "Німецька"
override val display_el: String = "Грецька"
override val display_en: String = "Англійська"
override val display_es: String = "Іспанська"
override val display_es_MX: String = "Іспанська (Мексика)"
override val display_et: String = "Естонська"
override val display_eu: String = "Баскська"
override val display_fa: String = "Перська"
override val display_fi: String = "Фінська"
override val display_fil: String = "Філіппінська"
override val display_fr: String = "Французька"
override val display_fr_CA: String = "Канадська французька"
override val display_gl: String = "Галісійська"
override val display_gu: String = "Гуджараті"
override val display_he: String = "Іврит"
override val display_hi: String = "Хінді"
override val display_hr: String = "Хорватська"
override val display_hu: String = "Угорська"
override val display_hy: String = "Вірменська"
override val display_id: String = "індонезійська"
override val display_is: String = "Ісландська"
override val display_it: String = "Італійська"
override val display_ja: String = "Японська"
override val display_ka: String = "Грузинська"
override val display_kk: String = "Казахська"
override val display_km: String = "Центральна кхмерська"
override val display_kn: String = "Каннада"
override val display_ko: String = "Корейська"
override val display_ky: String = "Киргизська"
override val display_lo: String = "Лаоська"
override val display_lt: String = "Литовська"
override val display_lv: String = "Латиська"
override val display_mk: String = "Македонська"
override val display_ml: String = "Малаялам"
override val display_mn: String = "Монгольська"
override val display_mr: String = "Маратхі"
override val display_ms: String = "Малайська"
override val display_my: String = "Бірманська"
override val display_nb: String = "Букмол"
override val display_ne: String = "Непальська"
override val display_nl: String = "Нідерландська"
override val display_or: String = "Орія"
override val display_pa: String = "Пенджабі"
override val display_pl: String = "Польська"
override val display_pt: String = "Португальська"
override val display_pt_BR: String = "Португальська (Бразилія)"
override val display_pt_PT: String = "Європейська португальська"
override val display_ro: String = "Румунська"
override val display_ru: String = "Російська"
override val display_si: String = "Сингальська"
override val display_sk: String = "Словацька"
override val display_sl: String = "Словенська"
override val display_sq: String = "Албанська"
override val display_sr: String = "Сербська"
override val display_sv: String = "Шведська"
override val display_sw: String = "Суахілі"
override val display_ta: String = "Тамільська"
override val display_te: String = "Телугу"
override val display_th: String = "Тайська"
override val display_tr: String = "Турецька"
override val display_uk: String = "Українська"
override val display_ur: String = "Урду"
override val display_uz: String = "Узбецька"
override val display_vi: String = "В'єтнамська"
override val display_zh_Hant_TW: String = "Китайська (Тайвань, Традиційна)"
override val display_zu: String = "Зулу"
override val document_estimate: String = "Оцінка"
override val document_invoice: String = "Рахунок"
override val document_quote: String = "Пропозицiя"
override val done: String = "Зроблено."
override val download_app: String = "Завантажити додаток"
override val download_invoice: String = "Завантажити Рахунок-Фактуру"
override val download_pdf: String = "Завантажити PDF"
override val draw_signature: String = "Малювання підпису"
override val due: Formattable = Formattable { (date) -> "Термін ${date}" }
override val due_date: String = "Термін"
override val due_day: String = "через день"
override val due_label: String = "Належне"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} оплачується ${dueWhen}!" }
override val due_today: String = "cьогодні"
override val due_week: String = "через тиждень"
override val duplicate: String = "Дублювати"
override val duration_day: String = "день"
override val duration_month: String = "Місяць"
override val duration_week: String = "тиждень"
override val duration_year: String = "рік"
override val email: String = "Електронна пошта"
override val email_blank_error: String = "Потрібна електронна пошта."
override val email_error: String = "Неприпустима адреса електронної пошти."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Дякуємо за Вашу співпрацю. Для повного перегляду ${documentType} натисніть кнопку в кінці цього листа. Зверніть увагу, що він дійсний до ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Ви отримали новий ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ПЕРЕГЛЯНУТИ ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Шановний(-а) ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Дякуємо за Вашу співпрацю. Для повного перегляду ${documentType} натисніть кнопку в кінці цього листа. Будь ласка, сплатіть залишок до ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Ви отримали новий ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Електронна пошта для скидання пароля, надісланого, будь ласка, перевірте свою поштову скриньку."
override val empty_clients_list_message: String = "У вас немає клієнтів, щоб відстежувати. Додати нового клієнта."
override val empty_estimate_list_message: String = "Створіть свою першу оцінку"
override val empty_expenses: String = "У вас немає витрат для відстеження. Додайте нові витрати."
override val empty_invoice_list_message: String = "Створення першого рахунку-фактури"
override val empty_items_list_message: String = "У вас немає елементів, додайте новий елемент!"
override val empty_signatures_list_message: String = "Підписів не знайдено. Створіть один!"
override val empty_taxes_list_message: String = "У вас немає податкових правил. Додайте нове податкове правило."
override val empty_templates_list_message: String = "Шаблони недоступні."
override val enabled: String = "Увімкнено"
override val entry: String = "запис"
override val equity: String = "власний капітал"
override val estimate_number: String = "Оцінка #"
override val estimates: String = "Оцінками"
override val estimates_report: String = "Оцінками"
override val expense_bank_fees: String = "Банківські комісії"
override val expense_benefits: String = "Переваги"
override val expense_communication: String = "Комунікація"
override val expense_debt: String = "Борг"
override val expense_deleted: String = "Витрати видалені"
override val expense_depreciation: String = "Амортизація"
override val expense_gifts: String = "Подарунки"
override val expense_insurance: String = "Страхування"
override val expense_inventory: String = "Інвентар"
override val expense_legal: String = "Юридичні витрати"
override val expense_maintenance: String = "Технічне обслуговування"
override val expense_marketing: String = "Маркетинг"
override val expense_meals: String = "Харчування"
override val expense_office_supplies: String = "Канцелярське приладдя"
override val expense_rent: String = "Оренда"
override val expense_salaries: String = "Зарплати"
override val expense_shopping: String = "Шопінг"
override val expense_software: String = "Програмне забезпечення"
override val expense_status_available: String = "Доступно"
override val expense_status_processing: String = "Обробка"
override val expense_tax: String = "Податки"
override val expense_training: String = "Навчання"
override val expense_transport: String = "Транспорт"
override val expense_travel: String = "Подорожі"
override val expense_utility: String = "Утиліта"
override val expenses: String = "Витрати"
override val feedback_option: String = "Зворотний зв'язок"
override val filter: String = "Фільтр"
override val first_name_hint: String = "Ім'я"
override val forgot_password: String = "Скинути пароль"
override val forgot_password_label: String = "Забули пароль"
override val from: String = "Від"
override val general_error: String = "Виникла помилка, будь ласка, спробуйте пізніше."
override val general_ledger: String = "головна книга"
override val go_back_label: String = "Повертатися"
override val group_by: String = "Група за:"
override val image_view: String = "Образ"
override val install: String = "Установити"
override val invalid_credentials_error: String = "Неприпустимі облікові дані."
override val invalid_email_error: String = "Неприпустима електронна пошта."
override val invalid_user_error: String = "Неприпустимі облікові дані користувача."
override val invoice_deleted_message: String = "Рахунок-фактура видалено"
override val invoice_menu: String = "Меню рахунків-фактур"
override val invoice_number: String = "Рахунок #"
override val invoices: String = "Рахунків"
override val items_option: String = "Елементи"
override val keep: String = "Тримати"
override val language_selector: Formattable = Formattable { (selected) -> "Мова ${selected}" }
override val last_name_hint: String = "Прізвище"
override val ledger: String = "бухгалтерська книга"
override val legal: String = "Юридичних"
override val legal_error: String = "Повинні прийняти Умови надання послуг та Політику конфіденційності"
override val liabilities: String = "зобов'язання"
override val loading: String = "Завантаження"
override val login_required_error: String = "Вхід обов'язковий."
override val logo_view: String = "Емблема"
override val logout: String = "Вихід"
override val logout_prompt: String = "Ваші дані не синхронізуються з хмарою. Видалити рахунки-фактури, оцінки, клієнтів та іншу інформацію про вихід із системи?"
override val main_screen: String = "Головний екран"
override val mark_paid: String = "Позначити як платне"
override val message: String = "Повідомлення"
override val missing_sender_msg: String = "Документ без інформації про відправника може бути недійсним."
override val missing_sender_title: String = "Відсутня інформація про відправника"
override val month_april: String = "Квітень"
override val month_april_short: String = "Квітень"
override val month_august: String = "Серпень"
override val month_august_short: String = "Серп."
override val month_december: String = "Грудень"
override val month_december_short: String = "Груд."
override val month_february: String = "Лютий"
override val month_february_short: String = "Лют."
override val month_january: String = "Січень"
override val month_january_short: String = "Січ."
override val month_july: String = "Липень"
override val month_july_short: String = "Липень"
override val month_june: String = "Червень"
override val month_june_short: String = "Червень"
override val month_march: String = "Березень"
override val month_march_short: String = "Березень"
override val month_may: String = "Травень"
override val month_may_short: String = "Травень"
override val month_november: String = "Листопад"
override val month_november_short: String = "Лис."
override val month_october: String = "Жовтень"
override val month_october_short: String = "Жовт."
override val month_september: String = "Вересень"
override val month_september_short: String = "Вер."
override val multifactor_error: String = "Не вдалося перевірити."
override val new: String = "Новий"
override val news: String = "Вісті"
override val next: String = "Далі"
override val no: String = "ні"
override val no_data_available: String = "Немає доступних даних"
override val no_search_results: String = "На жаль, ми не змогли знайти жодних результатів."
override val none: String = "Жодного"
override val note_fields: String = "Нотатки"
override val note_hint: String = "Примітка"
override val notifications_option: String = "Сповіщення"
override val ok: String = "Гаразд"
override val onboarding: String = "Onboarding"
override val onboarding_aboutus_message: String = "Основні частини DingVoice назавжди залишаються безкоштовними. Наша місія - допомогти вам розвивати свій бізнес, допоможіть нам вдосконалюватися, поділившись своїми відгуками."
override val onboarding_aboutus_title: String = "Ми тут, щоб допомогти"
override val onboarding_paid_1: String = "Доступ до професійних шаблонів"
override val onboarding_paid_2: String = "Погляньте глибше на свої фінанси"
override val onboarding_paid_3: String = "Швидко вирішуйте проблеми"
override val onboarding_paid_4: String = "Зберігайте свої документи в безпеці"
override val onboarding_paid_5: String = "Будьте в курсі змін статусу рахунку"
override val onboarding_paid_title: String = "Розвивайтеся ще швидше з..."
override val onboarding_paywall_message: String = "Насолоджуйтесь усіма преміальними функціями зараз за найкращою ціною."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Просто ${currencySymbol}${introAmount} для ${introDuration} ${introPeriod}, а потім ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, тривалість) -> "Просто ${currencySymbol}${amount} / ${тривалість}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Безкоштовно протягом ${trialDuration} ${trialPeriod}, а потім просто ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Безкоштовно для ${trialDuration} ${trialPeriod}, потім ${currencySymbol}${introductoryAmount} для ${introductoryDuration} ${introPeriod}, а потім просто ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Автоматично поновлюється. Скасувати в будь-який час."
override val onboarding_paywall_restore: String = "Відновити покупку"
override val onboarding_paywall_restore_failure: String = "Відновити покупки не вдалося, спробуйте пізніше або зверніться до служби підтримки."
override val onboarding_paywall_restore_success: String = "Відновлення пройшло успішно!"
override val onboarding_paywall_title: String = "Досягніть Величності!"
override val onboarding_welcome_message: String = "Повний набір інструментів для управління рахунками-фактурами та кошторисами."
override val onboarding_welcome_title: String = "Ласкаво просимо до DingVoice"
override val other_state: String = "Інше"
override val overdue_state: String = "Прострочені"
override val paid_state: String = "Платні"
override val password: String = "Пароль"
override val password_blank_error: String = "Пароль обов'язковий."
override val password_mismatch_error: String = "Паролі не збігаються."
override val pay: String = "оплата"
override val payment_instructions_label: String = "Інструкції з оплати"
override val payment_reminders: String = "Нагадування про оплату"
override val phone_number_fields: String = "Номер телефону"
override val phone_number_hint: String = "Номер телефону"
override val postcode_hint: String = "Поштовий індекс"
override val preview: String = "Попередній перегляд"
override val preview_menu_hint: String = "Меню попереднього перегляду"
override val price: String = "ціна"
override val privacy_policy: String = "Політика конфіденційності"
override val profile: String = "Профіль"
override val profitable_client: String = "Ваш найприбутковіший клієнт - це..."
override val profits_report: String = "Прибуток"
override val promo_content: String = "Рекламний контент"
override val quantity_hint: String = "Кількість"
override val quantity_label_hint: String = "Мітка кількості"
override val query_info: String = "Запит дає змогу записувати настроювані обчислення, посилаючись як на елементи, так і на інші податкові правила як на входи. Додати символ @ і елемент або податковий опис для посилання на інший запис +, -, *, /, % - математичні оператори"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "Ваш клієнт отримав ${documentType} ${documentNumber}." }
override val received_state: String = "Отримано"
override val registration_code_fields: String = "Код реєстрації"
override val reminder: String = "Нагадування"
override val reports: String = "Звіти"
override val reports_not_available: String = "Немає доступних звітів, поверніться пізніше."
override val reveal_password: String = "Розкрити пароль"
override val revenue: String = "дохід"
override val sales_tax: String = "ПДВ"
override val sales_tax_number: String = "Номер ПДВ"
override val save: String = "рятувати"
override val scan_document: String = "Сканувати документ"
override val search: String = "Шукати"
override val select: String = "Виберіть"
override val select_icon: String = "Виберіть значок"
override val select_signature: String = "Вибір підпису"
override val select_template: String = "Вибір шаблону"
override val send: String = "надіслати"
override val sent_state: String = "Відправлені"
override val setup_sender: String = "Налаштувати відправника"
override val share_option: String = "Ділити"
override val ship_to: String = "Відправити до"
override val sign_in: String = "Ввійти"
override val sign_in_apple: String = "Увійдіть за допомогою Apple"
override val sign_in_email: String = "Увійдіть електронною поштою"
override val sign_in_facebook: String = "Увійдіть за допомогою Facebook"
override val sign_in_google: String = "Увійдіть за допомогою Google"
override val sign_up: String = "Зареєструватися"
override val sign_up_label: String = "У вас немає облікового запису?"
override val sign_up_link_label: String = "Зареєструватися!"
override val signatures_option: String = "Підписи"
override val skip: String = "Пропустити"
override val sold_items_report: String = "Продані Елементи"
override val sort_by: String = "Сортувати за:"
override val status_update: String = "Оновлення статусу документа"
override val sub_annual: String = "Щорічний"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/рік" }
override val sub_backups: String = "Хмарні резервні копії"
override val sub_insights: String = "Інсайти"
override val sub_month: String = "Щомісяця"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/місяць" }
override val sub_notifications: String = "Сповіщення"
override val sub_popup_message: String = "Підписки найближчим часом. Перевірте тут через деякий час."
override val sub_support: String = "Підтримка"
override val sub_templates: String = "Шаблони"
override val subscribe: String = "Підписатися"
override val subscription_header: String = "Розвивайте свій бізнес за допомогою:"
override val subscription_option: String = "Абонемент"
override val subscriptions_load_fail: String = "На жаль, наразі ми не можемо відновити ваші підписки. Будь ласка, спробуйте пізніше або зверніться за допомогою до нашої служби підтримки."
override val subtotal: Formattable = Formattable { (sum) -> "Проміжний підсумок: ${sum}" }
override val subtotal_label: String = "Проміжний підсумок"
override val sum: String = "Сума"
override val support_option: String = "Підтримка"
override val switch_reports: String = "Повідомлення про Зміни"
override val tax: String = "Податок"
override val tax_deleted_message: String = "Податок видалено"
override val tax_description_warning: String = "Якщо це використано, це відображається в рахунку-фактурі"
override val tax_input_flat: String = "Квартира"
override val tax_input_percent: String = "Відсотків"
override val tax_number_fields: String = "Податковий номер"
override val tax_number_hint: String = "Податковий номер"
override val tax_query_info_description: String = "Відомості про запит"
override val tax_report: String = "Податок"
override val tax_rules_option: String = "Податкові правила"
override val taxed_clients_report: String = "Оподатковувані клієнти"
override val template: String = "Шаблон"
override val template_selector_info: String = "Проведіть пальцем, щоб змінити шаблон за промовчанням"
override val template_unlock_promo: String = "Отримуйте більше шаблонів із Premium"
override val templates_option: String = "Шаблони"
override val terms_of_service: String = "Умови надання послуг"
override val thank_you: String = "Дякуємо"
override val to: String = "до"
override val total: Formattable = Formattable { (sum) -> "Разом: ${sum}" }
override val total_label: String = "Всього"
override val undo_action: String = "Скасувати"
override val unit: String = "Одиниці"
override val unit_cost_hint: String = "Вартість одиниці"
override val unknown_client: String = "Невідомий Kлієнт"
override val unlock: String = "Розблокувати"
override val unnamed_item: String = "Елемент без назви"
override val upgrade: String = "Оновлення"
override val user_collision_error: String = "Обліковий запис уже існує."
override val valid_until: String = "Діє до"
override val warning: String = "Попередження!"
override val yes: String = "так"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Прийняти ${termsOfService} та ${privacyPolicy}" },
	"account" to "обліковий запис",
	"accounting" to "бухгалтерський облік",
	"accruals" to "нарахування",
	"action_code_error" to "Неприпустимий код.",
	"add_client_action" to "Додати клієнт",
	"add_customer" to "Додати клієнта",
	"add_expense" to "Додати витрати",
	"add_fields_label" to "Додати:",
	"add_invoice_action" to "Творити",
	"add_item_action" to "Додати елемент",
	"add_item_button" to "Додати елемент",
	"add_signature_action" to "Додати підпис",
	"add_tax_action" to "Додати податок",
	"address_fields" to "Адреса",
	"address_line_hint" to "Рядок адреси",
	"advanced" to "Передовий",
	"agree_marketing" to "Я згоден отримувати прямі маркетингові комунікації",
	"all_notifications" to "Усі сповіщення",
	"amortization" to "амортизація",
	"amount" to "кількість",
	"asset" to "актив",
	"attach_file" to "Прикріпити файл",
	"attachment" to "Вкладення",
	"auth_error" to "Не вдалося виконати дію, повторіть спробу.",
	"available_for_paid" to "Синхронізація в хмарі доступна лише за платною підпискою.",
	"backups_option" to "Резервні копії",
	"balance" to "баланс",
	"bank_account" to "Банківський Pахунок",
	"bill_number" to "Номер рахунку",
	"cancel" to "скасувати",
	"cancel_trial" to "Скасувати в будь-який час",
	"capital" to "капітал",
	"cash" to "готівка",
	"categories" to "Категорії",
	"category" to "Категорія",
	"category_add" to "Додати категорію",
	"category_delete" to "Видалити категорію",
	"category_name" to "Назва категорії",
	"change_language" to Formattable { (language) -> "Мова рахунка-фактури: ${language}" },
	"change_language_label" to "Мова Rахунків-фактур",
	"change_template" to "Змінити шаблон",
	"city_hint" to "Місто",
	"clear" to "ясний",
	"client" to "Клієнт",
	"client_actions" to "Дії клієнта",
	"client_deleted_message" to "Клієнт видалено",
	"clients_error_required_fields" to "Потрібно принаймні одне значення.",
	"clients_option" to "Клієнтів",
	"close" to "Закрити",
	"cloud_sync" to "Синхронізація хмари",
	"coming_soon" to "Незабаром!",
	"company_logo" to "Логотип компанії",
	"company_name_hint" to "Компанія",
	"confirm_password_label" to "Підтвердження пароля",
	"confirmation_title" to "Ви впевнені?",
	"convert_to_invoice" to "Перетворити на рахунок-фактуру",
	"country_hint" to "Країна",
	"create_client" to "Створити клієнта",
	"create_company" to "Створити компанію",
	"create_document" to "Створення документа",
	"create_estimate" to "Оцінка",
	"create_invoice" to "Рахунок",
	"create_item" to "Створити елемент",
	"create_new_client" to "Створити новий клієнт",
	"create_new_item" to "Створення нового елемента",
	"create_new_tax" to "Створення нового податку",
	"create_signature" to "Підпис",
	"created" to "Створений",
	"credit" to "кредит",
	"customer" to "Клієнтів",
	"date" to "Дата",
	"debit" to "дебет",
	"debt" to "борг",
	"default_currency" to Formattable { (currency) -> "Валюта за умовчанням: ${currency}" },
	"default_currency_label" to "Валюта за замовчуванням",
	"delete" to "Видалити",
	"delete_account" to "Видалити Обліковий запис",
	"delete_confirmation_message" to "Усі ваші дані буде втрачено.",
	"delete_item_title" to "Видалити елемент?",
	"delete_signature" to "Видалити підпис",
	"depreciation" to "амортизація",
	"description" to "Опис",
	"description_hint" to "Опис",
	"detailed_report" to "Докладний Звіт",
	"disabled" to "Вимкнуто",
	"discount" to "Знижка",
	"discount_hint" to "Знижка",
	"display_af" to "Африкаанс",
	"display_am" to "Амхарська",
	"display_ar" to "Арабська",
	"display_as" to "Ассамська",
	"display_az" to "Азербайджанська",
	"display_be" to "Білоруська",
	"display_bg" to "Болгарська",
	"display_bn" to "Бенгальська",
	"display_bs" to "Боснійська",
	"display_ca" to "Каталонська",
	"display_cs" to "Чеська",
	"display_da" to "Датська",
	"display_de" to "Німецька",
	"display_el" to "Грецька",
	"display_en" to "Англійська",
	"display_es" to "Іспанська",
	"display_es_MX" to "Іспанська (Мексика)",
	"display_et" to "Естонська",
	"display_eu" to "Баскська",
	"display_fa" to "Перська",
	"display_fi" to "Фінська",
	"display_fil" to "Філіппінська",
	"display_fr" to "Французька",
	"display_fr_CA" to "Канадська французька",
	"display_gl" to "Галісійська",
	"display_gu" to "Гуджараті",
	"display_he" to "Іврит",
	"display_hi" to "Хінді",
	"display_hr" to "Хорватська",
	"display_hu" to "Угорська",
	"display_hy" to "Вірменська",
	"display_id" to "індонезійська",
	"display_is" to "Ісландська",
	"display_it" to "Італійська",
	"display_ja" to "Японська",
	"display_ka" to "Грузинська",
	"display_kk" to "Казахська",
	"display_km" to "Центральна кхмерська",
	"display_kn" to "Каннада",
	"display_ko" to "Корейська",
	"display_ky" to "Киргизська",
	"display_lo" to "Лаоська",
	"display_lt" to "Литовська",
	"display_lv" to "Латиська",
	"display_mk" to "Македонська",
	"display_ml" to "Малаялам",
	"display_mn" to "Монгольська",
	"display_mr" to "Маратхі",
	"display_ms" to "Малайська",
	"display_my" to "Бірманська",
	"display_nb" to "Букмол",
	"display_ne" to "Непальська",
	"display_nl" to "Нідерландська",
	"display_or" to "Орія",
	"display_pa" to "Пенджабі",
	"display_pl" to "Польська",
	"display_pt" to "Португальська",
	"display_pt_BR" to "Португальська (Бразилія)",
	"display_pt_PT" to "Європейська португальська",
	"display_ro" to "Румунська",
	"display_ru" to "Російська",
	"display_si" to "Сингальська",
	"display_sk" to "Словацька",
	"display_sl" to "Словенська",
	"display_sq" to "Албанська",
	"display_sr" to "Сербська",
	"display_sv" to "Шведська",
	"display_sw" to "Суахілі",
	"display_ta" to "Тамільська",
	"display_te" to "Телугу",
	"display_th" to "Тайська",
	"display_tr" to "Турецька",
	"display_uk" to "Українська",
	"display_ur" to "Урду",
	"display_uz" to "Узбецька",
	"display_vi" to "В'єтнамська",
	"display_zh_Hant_TW" to "Китайська (Тайвань, Традиційна)",
	"display_zu" to "Зулу",
	"document_estimate" to "Оцінка",
	"document_invoice" to "Рахунок",
	"document_quote" to "Пропозицiя",
	"done" to "Зроблено.",
	"download_app" to "Завантажити додаток",
	"download_invoice" to "Завантажити Рахунок-Фактуру",
	"download_pdf" to "Завантажити PDF",
	"draw_signature" to "Малювання підпису",
	"due" to Formattable { (date) -> "Термін ${date}" },
	"due_date" to "Термін",
	"due_day" to "через день",
	"due_label" to "Належне",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} оплачується ${dueWhen}!" },
	"due_today" to "cьогодні",
	"due_week" to "через тиждень",
	"duplicate" to "Дублювати",
	"duration_day" to "день",
	"duration_month" to "Місяць",
	"duration_week" to "тиждень",
	"duration_year" to "рік",
	"email" to "Електронна пошта",
	"email_blank_error" to "Потрібна електронна пошта.",
	"email_error" to "Неприпустима адреса електронної пошти.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Дякуємо за Вашу співпрацю. Для повного перегляду ${documentType} натисніть кнопку в кінці цього листа. Зверніть увагу, що він дійсний до ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Ви отримали новий ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "ПЕРЕГЛЯНУТИ ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Шановний(-а) ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Дякуємо за Вашу співпрацю. Для повного перегляду ${documentType} натисніть кнопку в кінці цього листа. Будь ласка, сплатіть залишок до ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Ви отримали новий ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Електронна пошта для скидання пароля, надісланого, будь ласка, перевірте свою поштову скриньку.",
	"empty_clients_list_message" to "У вас немає клієнтів, щоб відстежувати. Додати нового клієнта.",
	"empty_estimate_list_message" to "Створіть свою першу оцінку",
	"empty_expenses" to "У вас немає витрат для відстеження. Додайте нові витрати.",
	"empty_invoice_list_message" to "Створення першого рахунку-фактури",
	"empty_items_list_message" to "У вас немає елементів, додайте новий елемент!",
	"empty_signatures_list_message" to "Підписів не знайдено. Створіть один!",
	"empty_taxes_list_message" to "У вас немає податкових правил. Додайте нове податкове правило.",
	"empty_templates_list_message" to "Шаблони недоступні.",
	"enabled" to "Увімкнено",
	"entry" to "запис",
	"equity" to "власний капітал",
	"estimate_number" to "Оцінка #",
	"estimates" to "Оцінками",
	"estimates_report" to "Оцінками",
	"expense_bank_fees" to "Банківські комісії",
	"expense_benefits" to "Переваги",
	"expense_communication" to "Комунікація",
	"expense_debt" to "Борг",
	"expense_deleted" to "Витрати видалені",
	"expense_depreciation" to "Амортизація",
	"expense_gifts" to "Подарунки",
	"expense_insurance" to "Страхування",
	"expense_inventory" to "Інвентар",
	"expense_legal" to "Юридичні витрати",
	"expense_maintenance" to "Технічне обслуговування",
	"expense_marketing" to "Маркетинг",
	"expense_meals" to "Харчування",
	"expense_office_supplies" to "Канцелярське приладдя",
	"expense_rent" to "Оренда",
	"expense_salaries" to "Зарплати",
	"expense_shopping" to "Шопінг",
	"expense_software" to "Програмне забезпечення",
	"expense_status_available" to "Доступно",
	"expense_status_processing" to "Обробка",
	"expense_tax" to "Податки",
	"expense_training" to "Навчання",
	"expense_transport" to "Транспорт",
	"expense_travel" to "Подорожі",
	"expense_utility" to "Утиліта",
	"expenses" to "Витрати",
	"feedback_option" to "Зворотний зв'язок",
	"filter" to "Фільтр",
	"first_name_hint" to "Ім'я",
	"forgot_password" to "Скинути пароль",
	"forgot_password_label" to "Забули пароль",
	"from" to "Від",
	"general_error" to "Виникла помилка, будь ласка, спробуйте пізніше.",
	"general_ledger" to "головна книга",
	"go_back_label" to "Повертатися",
	"group_by" to "Група за:",
	"image_view" to "Образ",
	"install" to "Установити",
	"invalid_credentials_error" to "Неприпустимі облікові дані.",
	"invalid_email_error" to "Неприпустима електронна пошта.",
	"invalid_user_error" to "Неприпустимі облікові дані користувача.",
	"invoice_deleted_message" to "Рахунок-фактура видалено",
	"invoice_menu" to "Меню рахунків-фактур",
	"invoice_number" to "Рахунок #",
	"invoices" to "Рахунків",
	"items_option" to "Елементи",
	"keep" to "Тримати",
	"language_selector" to Formattable { (selected) -> "Мова ${selected}" },
	"last_name_hint" to "Прізвище",
	"ledger" to "бухгалтерська книга",
	"legal" to "Юридичних",
	"legal_error" to "Повинні прийняти Умови надання послуг та Політику конфіденційності",
	"liabilities" to "зобов'язання",
	"loading" to "Завантаження",
	"login_required_error" to "Вхід обов'язковий.",
	"logo_view" to "Емблема",
	"logout" to "Вихід",
	"logout_prompt" to "Ваші дані не синхронізуються з хмарою. Видалити рахунки-фактури, оцінки, клієнтів та іншу інформацію про вихід із системи?",
	"main_screen" to "Головний екран",
	"mark_paid" to "Позначити як платне",
	"message" to "Повідомлення",
	"missing_sender_msg" to "Документ без інформації про відправника може бути недійсним.",
	"missing_sender_title" to "Відсутня інформація про відправника",
	"month_april" to "Квітень",
	"month_april_short" to "Квітень",
	"month_august" to "Серпень",
	"month_august_short" to "Серп.",
	"month_december" to "Грудень",
	"month_december_short" to "Груд.",
	"month_february" to "Лютий",
	"month_february_short" to "Лют.",
	"month_january" to "Січень",
	"month_january_short" to "Січ.",
	"month_july" to "Липень",
	"month_july_short" to "Липень",
	"month_june" to "Червень",
	"month_june_short" to "Червень",
	"month_march" to "Березень",
	"month_march_short" to "Березень",
	"month_may" to "Травень",
	"month_may_short" to "Травень",
	"month_november" to "Листопад",
	"month_november_short" to "Лис.",
	"month_october" to "Жовтень",
	"month_october_short" to "Жовт.",
	"month_september" to "Вересень",
	"month_september_short" to "Вер.",
	"multifactor_error" to "Не вдалося перевірити.",
	"new" to "Новий",
	"news" to "Вісті",
	"next" to "Далі",
	"no" to "ні",
	"no_data_available" to "Немає доступних даних",
	"no_search_results" to "На жаль, ми не змогли знайти жодних результатів.",
	"none" to "Жодного",
	"note_fields" to "Нотатки",
	"note_hint" to "Примітка",
	"notifications_option" to "Сповіщення",
	"ok" to "Гаразд",
	"onboarding" to "Onboarding",
	"onboarding_aboutus_message" to "Основні частини DingVoice назавжди залишаються безкоштовними. Наша місія - допомогти вам розвивати свій бізнес, допоможіть нам вдосконалюватися, поділившись своїми відгуками.",
	"onboarding_aboutus_title" to "Ми тут, щоб допомогти",
	"onboarding_paid_1" to "Доступ до професійних шаблонів",
	"onboarding_paid_2" to "Погляньте глибше на свої фінанси",
	"onboarding_paid_3" to "Швидко вирішуйте проблеми",
	"onboarding_paid_4" to "Зберігайте свої документи в безпеці",
	"onboarding_paid_5" to "Будьте в курсі змін статусу рахунку",
	"onboarding_paid_title" to "Розвивайтеся ще швидше з...",
	"onboarding_paywall_message" to "Насолоджуйтесь усіма преміальними функціями зараз за найкращою ціною.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Просто ${currencySymbol}${introAmount} для ${introDuration} ${introPeriod}, а потім ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, тривалість) -> "Просто ${currencySymbol}${amount} / ${тривалість}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Безкоштовно протягом ${trialDuration} ${trialPeriod}, а потім просто ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Безкоштовно для ${trialDuration} ${trialPeriod}, потім ${currencySymbol}${introductoryAmount} для ${introductoryDuration} ${introPeriod}, а потім просто ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Автоматично поновлюється. Скасувати в будь-який час.",
	"onboarding_paywall_restore" to "Відновити покупку",
	"onboarding_paywall_restore_failure" to "Відновити покупки не вдалося, спробуйте пізніше або зверніться до служби підтримки.",
	"onboarding_paywall_restore_success" to "Відновлення пройшло успішно!",
	"onboarding_paywall_title" to "Досягніть Величності!",
	"onboarding_welcome_message" to "Повний набір інструментів для управління рахунками-фактурами та кошторисами.",
	"onboarding_welcome_title" to "Ласкаво просимо до DingVoice",
	"other_state" to "Інше",
	"overdue_state" to "Прострочені",
	"paid_state" to "Платні",
	"password" to "Пароль",
	"password_blank_error" to "Пароль обов'язковий.",
	"password_mismatch_error" to "Паролі не збігаються.",
	"pay" to "оплата",
	"payment_instructions_label" to "Інструкції з оплати",
	"payment_reminders" to "Нагадування про оплату",
	"phone_number_fields" to "Номер телефону",
	"phone_number_hint" to "Номер телефону",
	"postcode_hint" to "Поштовий індекс",
	"preview" to "Попередній перегляд",
	"preview_menu_hint" to "Меню попереднього перегляду",
	"price" to "ціна",
	"privacy_policy" to "Політика конфіденційності",
	"profile" to "Профіль",
	"profitable_client" to "Ваш найприбутковіший клієнт - це...",
	"profits_report" to "Прибуток",
	"promo_content" to "Рекламний контент",
	"quantity_hint" to "Кількість",
	"quantity_label_hint" to "Мітка кількості",
	"query_info" to "Запит дає змогу записувати настроювані обчислення, посилаючись як на елементи, так і на інші податкові правила як на входи. Додати символ @ і елемент або податковий опис для посилання на інший запис +, -, *, /, % - математичні оператори",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "Ваш клієнт отримав ${documentType} ${documentNumber}." },
	"received_state" to "Отримано",
	"registration_code_fields" to "Код реєстрації",
	"reminder" to "Нагадування",
	"reports" to "Звіти",
	"reports_not_available" to "Немає доступних звітів, поверніться пізніше.",
	"reveal_password" to "Розкрити пароль",
	"revenue" to "дохід",
	"sales_tax" to "ПДВ",
	"sales_tax_number" to "Номер ПДВ",
	"save" to "рятувати",
	"scan_document" to "Сканувати документ",
	"search" to "Шукати",
	"select" to "Виберіть",
	"select_icon" to "Виберіть значок",
	"select_signature" to "Вибір підпису",
	"select_template" to "Вибір шаблону",
	"send" to "надіслати",
	"sent_state" to "Відправлені",
	"setup_sender" to "Налаштувати відправника",
	"share_option" to "Ділити",
	"ship_to" to "Відправити до",
	"sign_in" to "Ввійти",
	"sign_in_apple" to "Увійдіть за допомогою Apple",
	"sign_in_email" to "Увійдіть електронною поштою",
	"sign_in_facebook" to "Увійдіть за допомогою Facebook",
	"sign_in_google" to "Увійдіть за допомогою Google",
	"sign_up" to "Зареєструватися",
	"sign_up_label" to "У вас немає облікового запису?",
	"sign_up_link_label" to "Зареєструватися!",
	"signatures_option" to "Підписи",
	"skip" to "Пропустити",
	"sold_items_report" to "Продані Елементи",
	"sort_by" to "Сортувати за:",
	"status_update" to "Оновлення статусу документа",
	"sub_annual" to "Щорічний",
	"sub_annual_price" to Formattable { (price) -> "${price}/рік" },
	"sub_backups" to "Хмарні резервні копії",
	"sub_insights" to "Інсайти",
	"sub_month" to "Щомісяця",
	"sub_monthly_price" to Formattable { (price) -> "${price}/місяць" },
	"sub_notifications" to "Сповіщення",
	"sub_popup_message" to "Підписки найближчим часом. Перевірте тут через деякий час.",
	"sub_support" to "Підтримка",
	"sub_templates" to "Шаблони",
	"subscribe" to "Підписатися",
	"subscription_header" to "Розвивайте свій бізнес за допомогою:",
	"subscription_option" to "Абонемент",
	"subscriptions_load_fail" to "На жаль, наразі ми не можемо відновити ваші підписки. Будь ласка, спробуйте пізніше або зверніться за допомогою до нашої служби підтримки.",
	"subtotal" to Formattable { (sum) -> "Проміжний підсумок: ${sum}" },
	"subtotal_label" to "Проміжний підсумок",
	"sum" to "Сума",
	"support_option" to "Підтримка",
	"switch_reports" to "Повідомлення про Зміни",
	"tax" to "Податок",
	"tax_deleted_message" to "Податок видалено",
	"tax_description_warning" to "Якщо це використано, це відображається в рахунку-фактурі",
	"tax_input_flat" to "Квартира",
	"tax_input_percent" to "Відсотків",
	"tax_number_fields" to "Податковий номер",
	"tax_number_hint" to "Податковий номер",
	"tax_query_info_description" to "Відомості про запит",
	"tax_report" to "Податок",
	"tax_rules_option" to "Податкові правила",
	"taxed_clients_report" to "Оподатковувані клієнти",
	"template" to "Шаблон",
	"template_selector_info" to "Проведіть пальцем, щоб змінити шаблон за промовчанням",
	"template_unlock_promo" to "Отримуйте більше шаблонів із Premium",
	"templates_option" to "Шаблони",
	"terms_of_service" to "Умови надання послуг",
	"thank_you" to "Дякуємо",
	"to" to "до",
	"total" to Formattable { (sum) -> "Разом: ${sum}" },
	"total_label" to "Всього",
	"undo_action" to "Скасувати",
	"unit" to "Одиниці",
	"unit_cost_hint" to "Вартість одиниці",
	"unknown_client" to "Невідомий Kлієнт",
	"unlock" to "Розблокувати",
	"unnamed_item" to "Елемент без назви",
	"upgrade" to "Оновлення",
	"user_collision_error" to "Обліковий запис уже існує.",
	"valid_until" to "Діє до",
	"warning" to "Попередження!",
	"yes" to "так",
    )
    
}