// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Fr  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Acceptez ${termsOfService} et ${privacyPolicy}" }
override val account: String = "compte"
override val accounting: String = "comptabilité"
override val accruals: String = "charges à payer"
override val action_code_error: String = "Code invalide."
override val add_client_action: String = "Ajouter un client"
override val add_customer: String = "Ajouter un client"
override val add_expense: String = "Ajouter une dépense"
override val add_fields_label: String = "Ajouter :"
override val add_invoice_action: String = "Créer"
override val add_item_action: String = "Ajouter un élément"
override val add_item_button: String = "Ajouter un élément"
override val add_signature_action: String = "Ajouter une signature"
override val add_tax_action: String = "Ajouter la taxe"
override val address_fields: String = "Adresse"
override val address_line_hint: String = "Ligne d'adresse"
override val advanced: String = "AVANCÉ"
override val agree_marketing: String = "J'accepte de recevoir des communications de marketing direct"
override val all_notifications: String = "Toutes les notifications"
override val amortization: String = "amortissement"
override val amount: String = "Montant"
override val asset: String = "actif"
override val attach_file: String = "Joindre un fichier"
override val attachment: String = "Pièce jointe"
override val auth_error: String = "L'action a échoué, réessayez."
override val available_for_paid: String = "La synchronisation dans le nuage n'est disponible que dans le cadre d'un abonnement payant."
override val backups_option: String = "Sauvegardes"
override val balance: String = "solde"
override val bank_account: String = "Compte Bancaire"
override val bill_number: String = "Numéro de facture"
override val cancel: String = "Annuler"
override val cancel_trial: String = "Annulez à tout moment"
override val capital: String = "capital"
override val cash: String = "argent liquide"
override val categories: String = "Catégories"
override val category: String = "Catégorie"
override val category_add: String = "Ajouter une catégorie"
override val category_delete: String = "Supprimer la catégorie"
override val category_name: String = "Nom de catégorie"
override val change_language: Formattable = Formattable { (language) -> "Langue de la facture: ${language}" }
override val change_language_label: String = "Langue de la Facture"
override val change_template: String = "Modifier le modèle"
override val city_hint: String = "Ville"
override val clear: String = "Clair"
override val client: String = "Client"
override val client_actions: String = "Actions du client"
override val client_deleted_message: String = "Client supprimé"
override val clients_error_required_fields: String = "Au moins une valeur est requise."
override val clients_option: String = "Clients"
override val close: String = "Fermer"
override val cloud_sync: String = "Cloud Sync"
override val coming_soon: String = "Bientôt disponible !"
override val company_logo: String = "Logo de l'entreprise"
override val company_name_hint: String = "Entreprise"
override val confirm_password_label: String = "Confirmer le mot de passe"
override val confirmation_title: String = "Êtes-vous sûr?"
override val convert_to_invoice: String = "Convertir en facture"
override val country_hint: String = "Pays"
override val create_client: String = "Créer un client"
override val create_company: String = "Créer une entreprise"
override val create_document: String = "Créer un document"
override val create_estimate: String = "Estimation"
override val create_invoice: String = "Facture"
override val create_item: String = "Créer un Élément"
override val create_new_client: String = "Créer un nouveau client"
override val create_new_item: String = "Créer un nouvel élément"
override val create_new_tax: String = "Créer une nouvelle taxe"
override val create_signature: String = "Signature"
override val created: String = "Créé"
override val credit: String = "crédit"
override val customer: String = "Client"
override val date: String = "Date"
override val debit: String = "débit"
override val debt: String = "dette"
override val default_currency: Formattable = Formattable { (currency) -> "devise par défaut: ${currency}" }
override val default_currency_label: String = "Devise par défaut"
override val delete: String = "Supprimer"
override val delete_account: String = "Supprimer le Compte"
override val delete_confirmation_message: String = "Toutes vos données seront perdues."
override val delete_item_title: String = "Supprimer l'article ?"
override val delete_signature: String = "Supprimer la signature"
override val depreciation: String = "dépréciation"
override val description: String = "Description"
override val description_hint: String = "Description"
override val detailed_report: String = "Rapport Détaillé"
override val disabled: String = "Handicapés"
override val discount: String = "Remise"
override val discount_hint: String = "Remise"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharique"
override val display_ar: String = "Arabe"
override val display_as: String = "Assamais"
override val display_az: String = "Azerbaïdjanais"
override val display_be: String = "Biélorusse"
override val display_bg: String = "Bulgare"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosniaque"
override val display_ca: String = "Catalan"
override val display_cs: String = "Tchèque"
override val display_da: String = "Danois"
override val display_de: String = "Allemand"
override val display_el: String = "Grec"
override val display_en: String = "Anglais"
override val display_es: String = "Espagnol"
override val display_es_MX: String = "Espagnol (Mexique)"
override val display_et: String = "Estonien"
override val display_eu: String = "Basque"
override val display_fa: String = "Persan"
override val display_fi: String = "Finnois"
override val display_fil: String = "Philippin"
override val display_fr: String = "Français"
override val display_fr_CA: String = "Français canadien"
override val display_gl: String = "Galicien"
override val display_gu: String = "Gujarati"
override val display_he: String = "Hébreu"
override val display_hi: String = "Hindou"
override val display_hr: String = "Croate"
override val display_hu: String = "Hongrois"
override val display_hy: String = "Arménien"
override val display_id: String = "Indonésien"
override val display_is: String = "Islandais"
override val display_it: String = "Italien"
override val display_ja: String = "Japonais"
override val display_ka: String = "Géorgien"
override val display_kk: String = "Kazakh"
override val display_km: String = "Khmer Central"
override val display_kn: String = "Kannada"
override val display_ko: String = "Coréen"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Laotien"
override val display_lt: String = "Lituanien"
override val display_lv: String = "Letton"
override val display_mk: String = "Macédonien"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongole"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malais"
override val display_my: String = "Birmane"
override val display_nb: String = "Norvégien Bokmål"
override val display_ne: String = "Népalais"
override val display_nl: String = "Néerlandais"
override val display_or: String = "Oriya"
override val display_pa: String = "Pendjabi"
override val display_pl: String = "Polonais"
override val display_pt: String = "Portugais"
override val display_pt_BR: String = "Portugais (Brésil)"
override val display_pt_PT: String = "Portugais Européen"
override val display_ro: String = "Roumain"
override val display_ru: String = "Russe"
override val display_si: String = "Cinghalais"
override val display_sk: String = "Slovaque"
override val display_sl: String = "Slovène"
override val display_sq: String = "Albanais"
override val display_sr: String = "Serbe"
override val display_sv: String = "Suédois"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamoul"
override val display_te: String = "Télougou"
override val display_th: String = "Thaï"
override val display_tr: String = "Turc"
override val display_uk: String = "Ukrainien"
override val display_ur: String = "Ourdou"
override val display_uz: String = "Ouzbek"
override val display_vi: String = "Vietnamien"
override val display_zh_Hant_TW: String = "Chinois (Taiwan, Traditionnel)"
override val display_zu: String = "Zoulou"
override val document_estimate: String = "Estimation"
override val document_invoice: String = "Facture"
override val document_quote: String = "Offre"
override val done: String = "Fait"
override val download_app: String = "Télécharger l'application"
override val download_invoice: String = "Télécharger la facture"
override val download_pdf: String = "Télécharger PDF"
override val draw_signature: String = "Dessinez une signature"
override val due: Formattable = Formattable { (date) -> "Due ${date}" }
override val due_date: String = "Date d'échéance"
override val due_day: String = "en un jour"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} est dû ${dueWhen} !" }
override val due_today: String = "aujourd'hui"
override val due_week: String = "en une semaine"
override val duplicate: String = "Dupliquer"
override val duration_day: String = "jour"
override val duration_month: String = "Mois"
override val duration_week: String = "semaine"
override val duration_year: String = "année"
override val email: String = "Courriel"
override val email_blank_error: String = "Courriel requis."
override val email_error: String = "Adresse électronique non valide."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Nous vous remercions pour votre confiance. Pour afficher l'intégralité de ${documentType}, cliquez sur le bouton à la fin de cette lettre. Notez qu'il est valide jusqu'au ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Vous avez reçu un nouveau document : ${documentType} ${invoiceNumber} !" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "Voir la ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Cher(e) ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Nous vous remercions pour votre confiance. Pour afficher l'intégralité de ${documentType}, cliquez sur le bouton à la fin de cette lettre. Veuillez payer le solde jusqu'au ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Vous avez reçu un nouveau document : ${documentType} ${invoiceNumber} !" }
override val email_reset_success_message: String = "L'e-mail pour réinitialiser le mot de passe a été envoyé, veuillez vérifier votre boîte de réception."
override val empty_clients_list_message: String = "Vous n'avez aucun client à suivre. Ajoutez un nouveau client."
override val empty_estimate_list_message: String = "Créez votre première estimation"
override val empty_expenses: String = "Aucune dépense à suivre. Ajoutez une nouvelle dépense."
override val empty_invoice_list_message: String = "Créez votre première facture"
override val empty_items_list_message: String = "Vous n'avez aucun article, ajoutez un nouvel article !"
override val empty_signatures_list_message: String = "Aucune signature trouvée. Créez-en une !"
override val empty_taxes_list_message: String = "Vous n'avez aucune règle fiscale. Ajoutez une nouvelle règle fiscale."
override val empty_templates_list_message: String = "Modèles non disponibles."
override val enabled: String = "Activé"
override val entry: String = "entrée"
override val equity: String = "équité"
override val estimate_number: String = "Estimation #"
override val estimates: String = "Estimations"
override val estimates_report: String = "Estimations"
override val expense_bank_fees: String = "Frais bancaires"
override val expense_benefits: String = "Avantages"
override val expense_communication: String = "Communication"
override val expense_debt: String = "Dette"
override val expense_deleted: String = "Dépense supprimée"
override val expense_depreciation: String = "Dépréciation"
override val expense_gifts: String = "Cadeaux"
override val expense_insurance: String = "Assurance"
override val expense_inventory: String = "Inventaire"
override val expense_legal: String = "Mentions légales"
override val expense_maintenance: String = "Maintenance"
override val expense_marketing: String = "Commercialisation"
override val expense_meals: String = "Les Repas"
override val expense_office_supplies: String = "Fournitures Bureautiques"
override val expense_rent: String = "Location"
override val expense_salaries: String = "Salaires"
override val expense_shopping: String = "Achats"
override val expense_software: String = "Logicielles"
override val expense_status_available: String = "Disponibles"
override val expense_status_processing: String = "Traitement en cours"
override val expense_tax: String = "Impôt"
override val expense_training: String = "Formation"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Voyage"
override val expense_utility: String = "Utilité"
override val expenses: String = "Dépenses"
override val feedback_option: String = "Retour d'information"
override val filter: String = "Filtre"
override val first_name_hint: String = "Prénom        "
override val forgot_password: String = "Réinitialiser le mot de passe"
override val forgot_password_label: String = "Mot de passe oublié"
override val from: String = "Tiré de"
override val general_error: String = "Une erreur s'est produite, veuillez réessayer plus tard."
override val general_ledger: String = "registre général"
override val go_back_label: String = "RETOUR"
override val group_by: String = "Groupe par :"
override val image_view: String = "Image"
override val install: String = "Installer"
override val invalid_credentials_error: String = "Informations d'identification non valides."
override val invalid_email_error: String = "Courriel non valide."
override val invalid_user_error: String = "Les informations d'identification de l'utilisateur ne sont pas valides."
override val invoice_deleted_message: String = "Facture supprimée"
override val invoice_menu: String = "Menu Facture"
override val invoice_number: String = "N° de facture"
override val invoices: String = "factures"
override val items_option: String = "Articles"
override val keep: String = "Gardez"
override val language_selector: Formattable = Formattable { (selected) -> "Langue ${selected}" }
override val last_name_hint: String = "Nom de famille"
override val ledger: String = "registre"
override val legal: String = "Mentions légales"
override val legal_error: String = "Doit accepter les conditions de service et la politique de confidentialité"
override val liabilities: String = "responsabilités"
override val loading: String = "Chargement"
override val login_required_error: String = "Connexion requise."
override val logo_view: String = "Logo"
override val logout: String = "Déconnexion"
override val logout_prompt: String = "Vos données ne sont pas synchronisées avec le cloud. Supprimer les factures, devis, clients et autres informations lors de la déconnexion ?"
override val main_screen: String = "Écran principal"
override val mark_paid: String = "Marquer comme payé"
override val message: String = "Message"
override val missing_sender_msg: String = "Un document sans informations sur l'expéditeur n'est peut-être pas valide."
override val missing_sender_title: String = "Informations d'expéditeur manquantes"
override val month_april: String = "Avril"
override val month_april_short: String = "Avril"
override val month_august: String = "Août"
override val month_august_short: String = "Août"
override val month_december: String = "Décembre"
override val month_december_short: String = "Déc."
override val month_february: String = "Février"
override val month_february_short: String = "Fév."
override val month_january: String = "Janvier"
override val month_january_short: String = "Jan."
override val month_july: String = "Juillet"
override val month_july_short: String = "Juillet"
override val month_june: String = "Juin"
override val month_june_short: String = "Juin"
override val month_march: String = "Mars"
override val month_march_short: String = "Mars"
override val month_may: String = "Mai"
override val month_may_short: String = "Mai"
override val month_november: String = "Novembre"
override val month_november_short: String = "Nov."
override val month_october: String = "Octobre"
override val month_october_short: String = "Oct."
override val month_september: String = "Septembre"
override val month_september_short: String = "Sept."
override val multifactor_error: String = "Échec de la vérification."
override val new: String = "Nouveau"
override val news: String = "Nouvelles"
override val next: String = "Suivant"
override val no: String = "non"
override val no_data_available: String = "Aucune donnée disponible"
override val no_search_results: String = "Désolé, nous n'avons pas trouvé de résultats."
override val none: String = "Aucun"
override val note_fields: String = "Notes"
override val note_hint: String = "Note"
override val notifications_option: String = "Notifications"
override val ok: String = "OK"
override val onboarding: String = "embarquement"
override val onboarding_aboutus_message: String = "Les parties essentielles de DingVoice sont gratuites pour toujours. Notre mission est de vous aider à développer votre entreprise. Aidez-nous à nous améliorer en partageant vos commentaires."
override val onboarding_aboutus_title: String = "Nous sommes là pour vous aider"
override val onboarding_paid_1: String = "Accédez à des modèles d'apparence professionnelle"
override val onboarding_paid_2: String = "Examinez de plus près vos finances"
override val onboarding_paid_3: String = "Résoudre rapidement les problèmes"
override val onboarding_paid_4: String = "Protégez vos documents"
override val onboarding_paid_5: String = "Restez au courant des changements de statut des factures"
override val onboarding_paid_title: String = "Croissez encore plus vite avec..."
override val onboarding_paywall_message: String = "Profitez maintenant de toutes les fonctionnalités premium au meilleur prix."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Juste ${currencySymbol}${introAmount} pour ${introDuration} ${introPeriod} puis ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, mount, durée) -> "Juste ${currencySymbol}${mount} / ${durée}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratuit pour ${trialDuration} ${trialPeriod} et ensuite juste ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratuit pour ${trialDuration} ${trialPeriod} puis ${currencySymbol}${introductoryAmount} pour ${introductoryDuration} ${introPeriod} et ensuite juste ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Auto-renouvelable. Annulez à tout moment."
override val onboarding_paywall_restore: String = "Rétablir l'achat"
override val onboarding_paywall_restore_failure: String = "La restauration des achats a échoué, réessayez plus tard ou contactez l'assistance."
override val onboarding_paywall_restore_success: String = "Restauration réussie !"
override val onboarding_paywall_title: String = "Atteignez la grandeur !"
override val onboarding_welcome_message: String = "Un ensemble complet d'outils pour gérer vos factures et vos devis."
override val onboarding_welcome_title: String = "Bienvenue à DingVoice"
override val other_state: String = "Autre"
override val overdue_state: String = "En retard de paiement"
override val paid_state: String = "Payé"
override val password: String = "Mot de passe"
override val password_blank_error: String = "Mot de passe requis."
override val password_mismatch_error: String = "Les mots de passe ne correspondent pas."
override val pay: String = "payer"
override val payment_instructions_label: String = "Instructions de paiement"
override val payment_reminders: String = "Rappels de paiement"
override val phone_number_fields: String = "Numéro de téléphone"
override val phone_number_hint: String = "Numéro de téléphone"
override val postcode_hint: String = "Code postal"
override val preview: String = "Aperçu"
override val preview_menu_hint: String = "Menu de prévisualisation"
override val price: String = "Prix"
override val privacy_policy: String = "Politique de confidentialité"
override val profile: String = "Profil"
override val profitable_client: String = "Votre client le plus rentable est..."
override val profits_report: String = "Bénéfices"
override val promo_content: String = "Contenu promotionnel"
override val quantity_hint: String = "Quantité"
override val quantity_label_hint: String = "Étiquette de quantité"
override val query_info: String = "La requête vous permet d'écrire des calculs personnalisés en vous référant à la fois aux articles et à d'autres règles fiscales comme entrées. Ajoutez le symbole @ et la description de l'article ou de la taxe pour établir un lien avec une autre entrée +, -, *, /, % - opérateurs mathématiques"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} a été reçu par votre client." }
override val received_state: String = "Reçu"
override val registration_code_fields: String = "Code d'Enregistrement"
override val reminder: String = "Rappel"
override val reports: String = "Rapports"
override val reports_not_available: String = "Aucun rapport n'est disponible, revenez plus tard."
override val reveal_password: String = "Révéler le mot de passe"
override val revenue: String = "revenu"
override val sales_tax: String = "TVA"
override val sales_tax_number: String = "Numéro de TVA"
override val save: String = "Sauvez"
override val scan_document: String = "Scanner le document"
override val search: String = "Recherche"
override val select: String = "Sélectionner"
override val select_icon: String = "Sélectionnez une icône"
override val select_signature: String = "Sélectionnez la signature"
override val select_template: String = "Sélectionner le modèle"
override val send: String = "Envoyer"
override val sent_state: String = "Envoyé"
override val setup_sender: String = "Configurer l'expéditeur"
override val share_option: String = "Partager"
override val ship_to: String = "Expédier à"
override val sign_in: String = "Se connecter"
override val sign_in_apple: String = "Se connecter avec Apple"
override val sign_in_email: String = "S'identifier avec l'email"
override val sign_in_facebook: String = "Se connecter avec Facebook"
override val sign_in_google: String = "Connectez-vous avec Google"
override val sign_up: String = "S'inscrire"
override val sign_up_label: String = "Vous n'avez pas de compte ?"
override val sign_up_link_label: String = "Inscrivez-vous !"
override val signatures_option: String = "Signatures"
override val skip: String = "Skip"
override val sold_items_report: String = "Articles Vendus"
override val sort_by: String = "Trier par :"
override val status_update: String = "Mise à jour de l'état des documents"
override val sub_annual: String = "Annuel"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/année" }
override val sub_backups: String = "Sauvegardes Cloud"
override val sub_insights: String = "Statistiques"
override val sub_month: String = "Mensuel"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mois" }
override val sub_notifications: String = "Notifications"
override val sub_popup_message: String = "Les abonnements seront bientôt disponibles. Revenez plus tard."
override val sub_support: String = "Support"
override val sub_templates: String = "Modèles"
override val subscribe: String = "S'abonner à"
override val subscription_header: String = "Développez votre business grâce à:"
override val subscription_option: String = "Abonnement"
override val subscriptions_load_fail: String = "Désolé, nous ne sommes pas en mesure de récupérer vos abonnements pour le moment. Veuillez réessayer plus tard ou contacter notre équipe d'assistance pour obtenir de l'aide."
override val subtotal: Formattable = Formattable { (sum) -> "Sous-total : ${sum}" }
override val subtotal_label: String = "Sous-total"
override val sum: String = "Somme"
override val support_option: String = "Support"
override val switch_reports: String = "Rapport de changement"
override val tax: String = "TAXE"
override val tax_deleted_message: String = "Taxe supprimée"
override val tax_description_warning: String = "Si elle est utilisée, elle apparaît sur une facture"
override val tax_input_flat: String = "Appartement"
override val tax_input_percent: String = "Pourcentage"
override val tax_number_fields: String = "Numéro d'identification fiscale"
override val tax_number_hint: String = "Numéro d'identification fiscale"
override val tax_query_info_description: String = "Informations sur les requêtes"
override val tax_report: String = "Taxe"
override val tax_rules_option: String = "Règles fiscales"
override val taxed_clients_report: String = "Clients imposés"
override val template: String = "Modèle"
override val template_selector_info: String = "Passez la souris pour changer le modèle par défaut"
override val template_unlock_promo: String = "Accédez à plus de modèles avec Premium"
override val templates_option: String = "Modèles"
override val terms_of_service: String = "Conditions d'utilisation"
override val thank_you: String = "Merci"
override val to: String = "A"
override val total: Formattable = Formattable { (sum) -> "Total : ${sum}" }
override val total_label: String = "TOTAL"
override val undo_action: String = "Annuler"
override val unit: String = "Unité"
override val unit_cost_hint: String = "Coût unitaire"
override val unknown_client: String = "Client Inconnu"
override val unlock: String = "Déverrouiller"
override val unnamed_item: String = "Élément sans nom"
override val upgrade: String = "Mise à niveau"
override val user_collision_error: String = "Le compte existe déjà."
override val valid_until: String = "Valable Jusqu'à"
override val warning: String = "Attention !"
override val yes: String = "oui"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Acceptez ${termsOfService} et ${privacyPolicy}" },
	"account" to "compte",
	"accounting" to "comptabilité",
	"accruals" to "charges à payer",
	"action_code_error" to "Code invalide.",
	"add_client_action" to "Ajouter un client",
	"add_customer" to "Ajouter un client",
	"add_expense" to "Ajouter une dépense",
	"add_fields_label" to "Ajouter :",
	"add_invoice_action" to "Créer",
	"add_item_action" to "Ajouter un élément",
	"add_item_button" to "Ajouter un élément",
	"add_signature_action" to "Ajouter une signature",
	"add_tax_action" to "Ajouter la taxe",
	"address_fields" to "Adresse",
	"address_line_hint" to "Ligne d'adresse",
	"advanced" to "AVANCÉ",
	"agree_marketing" to "J'accepte de recevoir des communications de marketing direct",
	"all_notifications" to "Toutes les notifications",
	"amortization" to "amortissement",
	"amount" to "Montant",
	"asset" to "actif",
	"attach_file" to "Joindre un fichier",
	"attachment" to "Pièce jointe",
	"auth_error" to "L'action a échoué, réessayez.",
	"available_for_paid" to "La synchronisation dans le nuage n'est disponible que dans le cadre d'un abonnement payant.",
	"backups_option" to "Sauvegardes",
	"balance" to "solde",
	"bank_account" to "Compte Bancaire",
	"bill_number" to "Numéro de facture",
	"cancel" to "Annuler",
	"cancel_trial" to "Annulez à tout moment",
	"capital" to "capital",
	"cash" to "argent liquide",
	"categories" to "Catégories",
	"category" to "Catégorie",
	"category_add" to "Ajouter une catégorie",
	"category_delete" to "Supprimer la catégorie",
	"category_name" to "Nom de catégorie",
	"change_language" to Formattable { (language) -> "Langue de la facture: ${language}" },
	"change_language_label" to "Langue de la Facture",
	"change_template" to "Modifier le modèle",
	"city_hint" to "Ville",
	"clear" to "Clair",
	"client" to "Client",
	"client_actions" to "Actions du client",
	"client_deleted_message" to "Client supprimé",
	"clients_error_required_fields" to "Au moins une valeur est requise.",
	"clients_option" to "Clients",
	"close" to "Fermer",
	"cloud_sync" to "Cloud Sync",
	"coming_soon" to "Bientôt disponible !",
	"company_logo" to "Logo de l'entreprise",
	"company_name_hint" to "Entreprise",
	"confirm_password_label" to "Confirmer le mot de passe",
	"confirmation_title" to "Êtes-vous sûr?",
	"convert_to_invoice" to "Convertir en facture",
	"country_hint" to "Pays",
	"create_client" to "Créer un client",
	"create_company" to "Créer une entreprise",
	"create_document" to "Créer un document",
	"create_estimate" to "Estimation",
	"create_invoice" to "Facture",
	"create_item" to "Créer un Élément",
	"create_new_client" to "Créer un nouveau client",
	"create_new_item" to "Créer un nouvel élément",
	"create_new_tax" to "Créer une nouvelle taxe",
	"create_signature" to "Signature",
	"created" to "Créé",
	"credit" to "crédit",
	"customer" to "Client",
	"date" to "Date",
	"debit" to "débit",
	"debt" to "dette",
	"default_currency" to Formattable { (currency) -> "devise par défaut: ${currency}" },
	"default_currency_label" to "Devise par défaut",
	"delete" to "Supprimer",
	"delete_account" to "Supprimer le Compte",
	"delete_confirmation_message" to "Toutes vos données seront perdues.",
	"delete_item_title" to "Supprimer l'article ?",
	"delete_signature" to "Supprimer la signature",
	"depreciation" to "dépréciation",
	"description" to "Description",
	"description_hint" to "Description",
	"detailed_report" to "Rapport Détaillé",
	"disabled" to "Handicapés",
	"discount" to "Remise",
	"discount_hint" to "Remise",
	"display_af" to "Afrikaans",
	"display_am" to "Amharique",
	"display_ar" to "Arabe",
	"display_as" to "Assamais",
	"display_az" to "Azerbaïdjanais",
	"display_be" to "Biélorusse",
	"display_bg" to "Bulgare",
	"display_bn" to "Bengali",
	"display_bs" to "Bosniaque",
	"display_ca" to "Catalan",
	"display_cs" to "Tchèque",
	"display_da" to "Danois",
	"display_de" to "Allemand",
	"display_el" to "Grec",
	"display_en" to "Anglais",
	"display_es" to "Espagnol",
	"display_es_MX" to "Espagnol (Mexique)",
	"display_et" to "Estonien",
	"display_eu" to "Basque",
	"display_fa" to "Persan",
	"display_fi" to "Finnois",
	"display_fil" to "Philippin",
	"display_fr" to "Français",
	"display_fr_CA" to "Français canadien",
	"display_gl" to "Galicien",
	"display_gu" to "Gujarati",
	"display_he" to "Hébreu",
	"display_hi" to "Hindou",
	"display_hr" to "Croate",
	"display_hu" to "Hongrois",
	"display_hy" to "Arménien",
	"display_id" to "Indonésien",
	"display_is" to "Islandais",
	"display_it" to "Italien",
	"display_ja" to "Japonais",
	"display_ka" to "Géorgien",
	"display_kk" to "Kazakh",
	"display_km" to "Khmer Central",
	"display_kn" to "Kannada",
	"display_ko" to "Coréen",
	"display_ky" to "Kirghiz",
	"display_lo" to "Laotien",
	"display_lt" to "Lituanien",
	"display_lv" to "Letton",
	"display_mk" to "Macédonien",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongole",
	"display_mr" to "Marathi",
	"display_ms" to "Malais",
	"display_my" to "Birmane",
	"display_nb" to "Norvégien Bokmål",
	"display_ne" to "Népalais",
	"display_nl" to "Néerlandais",
	"display_or" to "Oriya",
	"display_pa" to "Pendjabi",
	"display_pl" to "Polonais",
	"display_pt" to "Portugais",
	"display_pt_BR" to "Portugais (Brésil)",
	"display_pt_PT" to "Portugais Européen",
	"display_ro" to "Roumain",
	"display_ru" to "Russe",
	"display_si" to "Cinghalais",
	"display_sk" to "Slovaque",
	"display_sl" to "Slovène",
	"display_sq" to "Albanais",
	"display_sr" to "Serbe",
	"display_sv" to "Suédois",
	"display_sw" to "Swahili",
	"display_ta" to "Tamoul",
	"display_te" to "Télougou",
	"display_th" to "Thaï",
	"display_tr" to "Turc",
	"display_uk" to "Ukrainien",
	"display_ur" to "Ourdou",
	"display_uz" to "Ouzbek",
	"display_vi" to "Vietnamien",
	"display_zh_Hant_TW" to "Chinois (Taiwan, Traditionnel)",
	"display_zu" to "Zoulou",
	"document_estimate" to "Estimation",
	"document_invoice" to "Facture",
	"document_quote" to "Offre",
	"done" to "Fait",
	"download_app" to "Télécharger l'application",
	"download_invoice" to "Télécharger la facture",
	"download_pdf" to "Télécharger PDF",
	"draw_signature" to "Dessinez une signature",
	"due" to Formattable { (date) -> "Due ${date}" },
	"due_date" to "Date d'échéance",
	"due_day" to "en un jour",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} est dû ${dueWhen} !" },
	"due_today" to "aujourd'hui",
	"due_week" to "en une semaine",
	"duplicate" to "Dupliquer",
	"duration_day" to "jour",
	"duration_month" to "Mois",
	"duration_week" to "semaine",
	"duration_year" to "année",
	"email" to "Courriel",
	"email_blank_error" to "Courriel requis.",
	"email_error" to "Adresse électronique non valide.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Nous vous remercions pour votre confiance. Pour afficher l'intégralité de ${documentType}, cliquez sur le bouton à la fin de cette lettre. Notez qu'il est valide jusqu'au ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Vous avez reçu un nouveau document : ${documentType} ${invoiceNumber} !" },
	"email_invoice_cta" to Formattable { (documentType) -> "Voir la ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Cher(e) ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Nous vous remercions pour votre confiance. Pour afficher l'intégralité de ${documentType}, cliquez sur le bouton à la fin de cette lettre. Veuillez payer le solde jusqu'au ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Vous avez reçu un nouveau document : ${documentType} ${invoiceNumber} !" },
	"email_reset_success_message" to "L'e-mail pour réinitialiser le mot de passe a été envoyé, veuillez vérifier votre boîte de réception.",
	"empty_clients_list_message" to "Vous n'avez aucun client à suivre. Ajoutez un nouveau client.",
	"empty_estimate_list_message" to "Créez votre première estimation",
	"empty_expenses" to "Aucune dépense à suivre. Ajoutez une nouvelle dépense.",
	"empty_invoice_list_message" to "Créez votre première facture",
	"empty_items_list_message" to "Vous n'avez aucun article, ajoutez un nouvel article !",
	"empty_signatures_list_message" to "Aucune signature trouvée. Créez-en une !",
	"empty_taxes_list_message" to "Vous n'avez aucune règle fiscale. Ajoutez une nouvelle règle fiscale.",
	"empty_templates_list_message" to "Modèles non disponibles.",
	"enabled" to "Activé",
	"entry" to "entrée",
	"equity" to "équité",
	"estimate_number" to "Estimation #",
	"estimates" to "Estimations",
	"estimates_report" to "Estimations",
	"expense_bank_fees" to "Frais bancaires",
	"expense_benefits" to "Avantages",
	"expense_communication" to "Communication",
	"expense_debt" to "Dette",
	"expense_deleted" to "Dépense supprimée",
	"expense_depreciation" to "Dépréciation",
	"expense_gifts" to "Cadeaux",
	"expense_insurance" to "Assurance",
	"expense_inventory" to "Inventaire",
	"expense_legal" to "Mentions légales",
	"expense_maintenance" to "Maintenance",
	"expense_marketing" to "Commercialisation",
	"expense_meals" to "Les Repas",
	"expense_office_supplies" to "Fournitures Bureautiques",
	"expense_rent" to "Location",
	"expense_salaries" to "Salaires",
	"expense_shopping" to "Achats",
	"expense_software" to "Logicielles",
	"expense_status_available" to "Disponibles",
	"expense_status_processing" to "Traitement en cours",
	"expense_tax" to "Impôt",
	"expense_training" to "Formation",
	"expense_transport" to "Transport",
	"expense_travel" to "Voyage",
	"expense_utility" to "Utilité",
	"expenses" to "Dépenses",
	"feedback_option" to "Retour d'information",
	"filter" to "Filtre",
	"first_name_hint" to "Prénom        ",
	"forgot_password" to "Réinitialiser le mot de passe",
	"forgot_password_label" to "Mot de passe oublié",
	"from" to "Tiré de",
	"general_error" to "Une erreur s'est produite, veuillez réessayer plus tard.",
	"general_ledger" to "registre général",
	"go_back_label" to "RETOUR",
	"group_by" to "Groupe par :",
	"image_view" to "Image",
	"install" to "Installer",
	"invalid_credentials_error" to "Informations d'identification non valides.",
	"invalid_email_error" to "Courriel non valide.",
	"invalid_user_error" to "Les informations d'identification de l'utilisateur ne sont pas valides.",
	"invoice_deleted_message" to "Facture supprimée",
	"invoice_menu" to "Menu Facture",
	"invoice_number" to "N° de facture",
	"invoices" to "factures",
	"items_option" to "Articles",
	"keep" to "Gardez",
	"language_selector" to Formattable { (selected) -> "Langue ${selected}" },
	"last_name_hint" to "Nom de famille",
	"ledger" to "registre",
	"legal" to "Mentions légales",
	"legal_error" to "Doit accepter les conditions de service et la politique de confidentialité",
	"liabilities" to "responsabilités",
	"loading" to "Chargement",
	"login_required_error" to "Connexion requise.",
	"logo_view" to "Logo",
	"logout" to "Déconnexion",
	"logout_prompt" to "Vos données ne sont pas synchronisées avec le cloud. Supprimer les factures, devis, clients et autres informations lors de la déconnexion ?",
	"main_screen" to "Écran principal",
	"mark_paid" to "Marquer comme payé",
	"message" to "Message",
	"missing_sender_msg" to "Un document sans informations sur l'expéditeur n'est peut-être pas valide.",
	"missing_sender_title" to "Informations d'expéditeur manquantes",
	"month_april" to "Avril",
	"month_april_short" to "Avril",
	"month_august" to "Août",
	"month_august_short" to "Août",
	"month_december" to "Décembre",
	"month_december_short" to "Déc.",
	"month_february" to "Février",
	"month_february_short" to "Fév.",
	"month_january" to "Janvier",
	"month_january_short" to "Jan.",
	"month_july" to "Juillet",
	"month_july_short" to "Juillet",
	"month_june" to "Juin",
	"month_june_short" to "Juin",
	"month_march" to "Mars",
	"month_march_short" to "Mars",
	"month_may" to "Mai",
	"month_may_short" to "Mai",
	"month_november" to "Novembre",
	"month_november_short" to "Nov.",
	"month_october" to "Octobre",
	"month_october_short" to "Oct.",
	"month_september" to "Septembre",
	"month_september_short" to "Sept.",
	"multifactor_error" to "Échec de la vérification.",
	"new" to "Nouveau",
	"news" to "Nouvelles",
	"next" to "Suivant",
	"no" to "non",
	"no_data_available" to "Aucune donnée disponible",
	"no_search_results" to "Désolé, nous n'avons pas trouvé de résultats.",
	"none" to "Aucun",
	"note_fields" to "Notes",
	"note_hint" to "Note",
	"notifications_option" to "Notifications",
	"ok" to "OK",
	"onboarding" to "embarquement",
	"onboarding_aboutus_message" to "Les parties essentielles de DingVoice sont gratuites pour toujours. Notre mission est de vous aider à développer votre entreprise. Aidez-nous à nous améliorer en partageant vos commentaires.",
	"onboarding_aboutus_title" to "Nous sommes là pour vous aider",
	"onboarding_paid_1" to "Accédez à des modèles d'apparence professionnelle",
	"onboarding_paid_2" to "Examinez de plus près vos finances",
	"onboarding_paid_3" to "Résoudre rapidement les problèmes",
	"onboarding_paid_4" to "Protégez vos documents",
	"onboarding_paid_5" to "Restez au courant des changements de statut des factures",
	"onboarding_paid_title" to "Croissez encore plus vite avec...",
	"onboarding_paywall_message" to "Profitez maintenant de toutes les fonctionnalités premium au meilleur prix.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Juste ${currencySymbol}${introAmount} pour ${introDuration} ${introPeriod} puis ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, mount, durée) -> "Juste ${currencySymbol}${mount} / ${durée}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratuit pour ${trialDuration} ${trialPeriod} et ensuite juste ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratuit pour ${trialDuration} ${trialPeriod} puis ${currencySymbol}${introductoryAmount} pour ${introductoryDuration} ${introPeriod} et ensuite juste ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Auto-renouvelable. Annulez à tout moment.",
	"onboarding_paywall_restore" to "Rétablir l'achat",
	"onboarding_paywall_restore_failure" to "La restauration des achats a échoué, réessayez plus tard ou contactez l'assistance.",
	"onboarding_paywall_restore_success" to "Restauration réussie !",
	"onboarding_paywall_title" to "Atteignez la grandeur !",
	"onboarding_welcome_message" to "Un ensemble complet d'outils pour gérer vos factures et vos devis.",
	"onboarding_welcome_title" to "Bienvenue à DingVoice",
	"other_state" to "Autre",
	"overdue_state" to "En retard de paiement",
	"paid_state" to "Payé",
	"password" to "Mot de passe",
	"password_blank_error" to "Mot de passe requis.",
	"password_mismatch_error" to "Les mots de passe ne correspondent pas.",
	"pay" to "payer",
	"payment_instructions_label" to "Instructions de paiement",
	"payment_reminders" to "Rappels de paiement",
	"phone_number_fields" to "Numéro de téléphone",
	"phone_number_hint" to "Numéro de téléphone",
	"postcode_hint" to "Code postal",
	"preview" to "Aperçu",
	"preview_menu_hint" to "Menu de prévisualisation",
	"price" to "Prix",
	"privacy_policy" to "Politique de confidentialité",
	"profile" to "Profil",
	"profitable_client" to "Votre client le plus rentable est...",
	"profits_report" to "Bénéfices",
	"promo_content" to "Contenu promotionnel",
	"quantity_hint" to "Quantité",
	"quantity_label_hint" to "Étiquette de quantité",
	"query_info" to "La requête vous permet d'écrire des calculs personnalisés en vous référant à la fois aux articles et à d'autres règles fiscales comme entrées. Ajoutez le symbole @ et la description de l'article ou de la taxe pour établir un lien avec une autre entrée +, -, *, /, % - opérateurs mathématiques",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} a été reçu par votre client." },
	"received_state" to "Reçu",
	"registration_code_fields" to "Code d'Enregistrement",
	"reminder" to "Rappel",
	"reports" to "Rapports",
	"reports_not_available" to "Aucun rapport n'est disponible, revenez plus tard.",
	"reveal_password" to "Révéler le mot de passe",
	"revenue" to "revenu",
	"sales_tax" to "TVA",
	"sales_tax_number" to "Numéro de TVA",
	"save" to "Sauvez",
	"scan_document" to "Scanner le document",
	"search" to "Recherche",
	"select" to "Sélectionner",
	"select_icon" to "Sélectionnez une icône",
	"select_signature" to "Sélectionnez la signature",
	"select_template" to "Sélectionner le modèle",
	"send" to "Envoyer",
	"sent_state" to "Envoyé",
	"setup_sender" to "Configurer l'expéditeur",
	"share_option" to "Partager",
	"ship_to" to "Expédier à",
	"sign_in" to "Se connecter",
	"sign_in_apple" to "Se connecter avec Apple",
	"sign_in_email" to "S'identifier avec l'email",
	"sign_in_facebook" to "Se connecter avec Facebook",
	"sign_in_google" to "Connectez-vous avec Google",
	"sign_up" to "S'inscrire",
	"sign_up_label" to "Vous n'avez pas de compte ?",
	"sign_up_link_label" to "Inscrivez-vous !",
	"signatures_option" to "Signatures",
	"skip" to "Skip",
	"sold_items_report" to "Articles Vendus",
	"sort_by" to "Trier par :",
	"status_update" to "Mise à jour de l'état des documents",
	"sub_annual" to "Annuel",
	"sub_annual_price" to Formattable { (price) -> "${price}/année" },
	"sub_backups" to "Sauvegardes Cloud",
	"sub_insights" to "Statistiques",
	"sub_month" to "Mensuel",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mois" },
	"sub_notifications" to "Notifications",
	"sub_popup_message" to "Les abonnements seront bientôt disponibles. Revenez plus tard.",
	"sub_support" to "Support",
	"sub_templates" to "Modèles",
	"subscribe" to "S'abonner à",
	"subscription_header" to "Développez votre business grâce à:",
	"subscription_option" to "Abonnement",
	"subscriptions_load_fail" to "Désolé, nous ne sommes pas en mesure de récupérer vos abonnements pour le moment. Veuillez réessayer plus tard ou contacter notre équipe d'assistance pour obtenir de l'aide.",
	"subtotal" to Formattable { (sum) -> "Sous-total : ${sum}" },
	"subtotal_label" to "Sous-total",
	"sum" to "Somme",
	"support_option" to "Support",
	"switch_reports" to "Rapport de changement",
	"tax" to "TAXE",
	"tax_deleted_message" to "Taxe supprimée",
	"tax_description_warning" to "Si elle est utilisée, elle apparaît sur une facture",
	"tax_input_flat" to "Appartement",
	"tax_input_percent" to "Pourcentage",
	"tax_number_fields" to "Numéro d'identification fiscale",
	"tax_number_hint" to "Numéro d'identification fiscale",
	"tax_query_info_description" to "Informations sur les requêtes",
	"tax_report" to "Taxe",
	"tax_rules_option" to "Règles fiscales",
	"taxed_clients_report" to "Clients imposés",
	"template" to "Modèle",
	"template_selector_info" to "Passez la souris pour changer le modèle par défaut",
	"template_unlock_promo" to "Accédez à plus de modèles avec Premium",
	"templates_option" to "Modèles",
	"terms_of_service" to "Conditions d'utilisation",
	"thank_you" to "Merci",
	"to" to "A",
	"total" to Formattable { (sum) -> "Total : ${sum}" },
	"total_label" to "TOTAL",
	"undo_action" to "Annuler",
	"unit" to "Unité",
	"unit_cost_hint" to "Coût unitaire",
	"unknown_client" to "Client Inconnu",
	"unlock" to "Déverrouiller",
	"unnamed_item" to "Élément sans nom",
	"upgrade" to "Mise à niveau",
	"user_collision_error" to "Le compte existe déjà.",
	"valid_until" to "Valable Jusqu'à",
	"warning" to "Attention !",
	"yes" to "oui",
    )
    
}