package com.dingvoice.content

import androidx.compose.runtime.Composable
import com.dingvoice.style.WtTexts
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@ExperimentalComposeWebApi
@Composable
fun StoreBadges() {
    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
            padding(48.px)
        }
    }) {
        Span({
            classes(WtTexts.wtH3)
        }) {
            Text("Download now!")
        }

        Div(attrs = {
            style {
                display(DisplayStyle.Flex)
                justifyContent(JustifyContent.Center)
                alignItems(AlignItems.Center)
            }
        }) {
            GooglePlayBadge()
            AppStoreBadge()
        }
    }
}

@Composable
fun GooglePlayBadge() {
    A(
        href = "https://play.google.com/store/apps/details?id=com.dingvoice.androidApp&launch=true&utm_source=landing&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
    ) {
        Img(
            "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png",
            "Get it on Google Play",
            attrs = {
                style {
                    width(150.px)
                    marginTop(4.px)
                }
            }
        )
    }
}

@ExperimentalComposeWebApi
@Composable
fun AppStoreBadge() {
    A(
        href = "https://apps.apple.com/app/dingvoice-invoice-generator/id6445985144"
    ) {
        Img(
            "App_Store_Badge.svg",
            "Get it on AppStore"
        )
    }
}
