// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Be  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Прыняць ${termsOfService} і ${privacyPolicy}" }
override val account: String = "уліковы запіс"
override val accounting: String = "бухгалтэрыя"
override val accruals: String = "налічэнні"
override val action_code_error: String = "Несапраўдны код."
override val add_client_action: String = "Дадаць кліент"
override val add_customer: String = "Дадаць кліента"
override val add_expense: String = "Дадайце выдаткі"
override val add_fields_label: String = "Дадаць:"
override val add_invoice_action: String = "Стварыць"
override val add_item_action: String = "Дадаць элемент"
override val add_item_button: String = "Дадаць элемент"
override val add_signature_action: String = "Дадаць подпіс"
override val add_tax_action: String = "Дадаць падатак"
override val address_fields: String = "Адрас"
override val address_line_hint: String = "Адрасны радок"
override val advanced: String = "Пашыраны"
override val agree_marketing: String = "Я згодны атрымліваць прамыя маркетынгавыя паведамленні"
override val all_notifications: String = "Усе апавяшчэнні"
override val amortization: String = "амартызацыя"
override val amount: String = "Сума"
override val asset: String = "актыў"
override val attach_file: String = "Далучыць файл"
override val attachment: String = "Далучэнне"
override val auth_error: String = "Не ўдалося выканаць дзеянне, паўтарыце спробу."
override val available_for_paid: String = "Воблачная сінхранізацыя даступная толькі пры платнай падпісцы."
override val backups_option: String = "Рэзервовае капіраванне"
override val balance: String = "баланс"
override val bank_account: String = "Банкаўскі рахунак"
override val bill_number: String = "Нумар рахунку"
override val cancel: String = "Ануляваць"
override val cancel_trial: String = "Адмяніць у любы час"
override val capital: String = "капітал"
override val cash: String = "наяўныя грошы"
override val categories: String = "Катэгорыі"
override val category: String = "Катэгорыя"
override val category_add: String = "Дадаць катэгорыю"
override val category_delete: String = "Выдаліць катэгорыю"
override val category_name: String = "Назва катэгорыі"
override val change_language: Formattable = Formattable { (language) -> "Мова рахунка-фактуры: ${language}" }
override val change_language_label: String = "Мова фактуры"
override val change_template: String = "Змяніць шаблон"
override val city_hint: String = "Горад"
override val clear: String = "Ясна"
override val client: String = "Кліент"
override val client_actions: String = "Дзеянні кліента"
override val client_deleted_message: String = "Кліент выдалены"
override val clients_error_required_fields: String = "Патрабуецца прынамсі адно значэнне."
override val clients_option: String = "Кліенты"
override val close: String = "Зачыніць"
override val cloud_sync: String = "Воблачная сінхранізацыя"
override val coming_soon: String = "Хутка!"
override val company_logo: String = "Лагатып кампаніі"
override val company_name_hint: String = "Кампанія"
override val confirm_password_label: String = "Пацвердзіце пароль"
override val confirmation_title: String = "Вы ўпэўнены?"
override val convert_to_invoice: String = "Перавесці ў рахунак-фактуру"
override val country_hint: String = "Краіна"
override val create_client: String = "Стварыць кліент"
override val create_company: String = "Стварыць кампанію"
override val create_document: String = "Стварыць дакумент"
override val create_estimate: String = "Ацэнка"
override val create_invoice: String = "Рахунак-фактура"
override val create_item: String = "Стварыць элемент"
override val create_new_client: String = "Стварыце новага кліента"
override val create_new_item: String = "Стварыць новы элемент"
override val create_new_tax: String = "Стварыце новы падатак"
override val create_signature: String = "Подпіс"
override val created: String = "Створаны"
override val credit: String = "крэдытная"
override val customer: String = "Заказчык"
override val date: String = "Дата"
override val debit: String = "дэбетавая"
override val debt: String = "доўг"
override val default_currency: Formattable = Formattable { (currency) -> "Валюта па змаўчанні: ${currency}" }
override val default_currency_label: String = "Валюта па змаўчанні"
override val delete: String = "Выдаляць"
override val delete_account: String = "Выдаліць уліковы запіс"
override val delete_confirmation_message: String = "Усе вашы даныя будуць страчаны."
override val delete_item_title: String = "Выдаліць элемент?"
override val delete_signature: String = "Выдаліць подпіс"
override val depreciation: String = "амартызацыя"
override val description: String = "Апісанне"
override val description_hint: String = "Апісанне"
override val detailed_report: String = "Падрабязны Cправаздачу"
override val disabled: String = "Інвалідам"
override val discount: String = "Зніжка"
override val discount_hint: String = "Зніжка"
override val display_af: String = "афрыканскі"
override val display_am: String = "Амхарская"
override val display_ar: String = "арабская"
override val display_as: String = "Асамская"
override val display_az: String = "Азербайджанская"
override val display_be: String = "беларускі"
override val display_bg: String = "балгарская"
override val display_bn: String = "бенгальская"
override val display_bs: String = "басьнійскі"
override val display_ca: String = "каталонская"
override val display_cs: String = "чэшская"
override val display_da: String = "дацкая"
override val display_de: String = "нямецкі"
override val display_el: String = "грэцкі"
override val display_en: String = "АНГЛІЙСКАЯ"
override val display_es: String = "іспанскі"
override val display_es_MX: String = "Іспанская (Мексіка)"
override val display_et: String = "эстонскі"
override val display_eu: String = "Баскская"
override val display_fa: String = "фарсі"
override val display_fi: String = "фінская"
override val display_fil: String = "філіпінскі"
override val display_fr: String = "французскі"
override val display_fr_CA: String = "Канадскі французскі"
override val display_gl: String = "галісійскі"
override val display_gu: String = "Гуджараці"
override val display_he: String = "іўрыт"
override val display_hi: String = "не"
override val display_hr: String = "харвацкая"
override val display_hu: String = "венгерскі"
override val display_hy: String = "армянская"
override val display_id: String = "інданезійскі"
override val display_is: String = "ісландская"
override val display_it: String = "італьянскі"
override val display_ja: String = "Японскі"
override val display_ka: String = "грузінскі"
override val display_kk: String = "казахская"
override val display_km: String = "цэнтральна-кхмерскі"
override val display_kn: String = "каннада"
override val display_ko: String = "карэйская"
override val display_ky: String = "кіргізская"
override val display_lo: String = "Праца"
override val display_lt: String = "літоўскі"
override val display_lv: String = "Латышскі"
override val display_mk: String = "Македонская"
override val display_ml: String = "Малаялам"
override val display_mn: String = "Мангольская"
override val display_mr: String = "Маратхі"
override val display_ms: String = "малайская"
override val display_my: String = "бірманскі"
override val display_nb: String = "Нарвежская букмол"
override val display_ne: String = "непальская"
override val display_nl: String = "галандскі"
override val display_or: String = "орыя"
override val display_pa: String = "Панджабі"
override val display_pl: String = "польская"
override val display_pt: String = "партугальская"
override val display_pt_BR: String = "партугальская (Бразілія)"
override val display_pt_PT: String = "Еўрапейская партугальская"
override val display_ro: String = "румынская"
override val display_ru: String = "рускі"
override val display_si: String = "сінгальская"
override val display_sk: String = "славацкая"
override val display_sl: String = "Славенская"
override val display_sq: String = "албанец"
override val display_sr: String = "сербская"
override val display_sv: String = "шведская"
override val display_sw: String = "Суахілі"
override val display_ta: String = "тамільская"
override val display_te: String = "Тэлугу"
override val display_th: String = "Тайская"
override val display_tr: String = "турэцкая"
override val display_uk: String = "украінскі"
override val display_ur: String = "Урду"
override val display_uz: String = "узбекская"
override val display_vi: String = "в&#39;етнамская"
override val display_zh_Hant_TW: String = "Кітайская (Тайвань, Традыцыйная)"
override val display_zu: String = "Зулу"
override val document_estimate: String = "Ацэнка"
override val document_invoice: String = "Рахунак-фактура"
override val document_quote: String = "Прапанова"
override val done: String = "Гатова"
override val download_app: String = "Спампаваць праграму"
override val download_invoice: String = "Спампаваць Фактуру"
override val download_pdf: String = "Спампаваць PDF"
override val draw_signature: String = "Намалюйце подпіс"
override val due: Formattable = Formattable { (date) -> "Тэрмін: ${date}" }
override val due_date: String = "Тэрмін"
override val due_day: String = "праз дзень"
override val due_label: String = "З-за"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} аплачваецца ${dueWhen}!" }
override val due_today: String = "сёння"
override val due_week: String = "праз тыдзень"
override val duplicate: String = "Дублікат"
override val duration_day: String = "дзень"
override val duration_month: String = "Месяц"
override val duration_week: String = "тыдзень"
override val duration_year: String = "год"
override val email: String = "электронная пошта"
override val email_blank_error: String = "Патрабуецца адрас электроннай пошты."
override val email_error: String = "Няправільны адрас электроннай пошты."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Дзякуй за вашу справу. Каб прагледзець ${documentType} цалкам, націсніце кнопку ў канцы гэтага ліста. Звярніце ўвагу, што ён сапраўдны да ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Вы атрымалі новы ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "ПРАГЛЯД ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Паважаны ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Дзякуй за вашу справу. Каб прагледзець ${documentType} цалкам, націсніце кнопку ў канцы гэтага ліста. Аплаціце астатак да ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Вы атрымалі новы ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Адпраўлены ліст для скіду пароля, праверце сваю паштовую скрыню."
override val empty_clients_list_message: String = "У вас няма кліентаў для адсочвання. Дадаць новага кліента."
override val empty_estimate_list_message: String = "Стварыце сваю першую ацэнку"
override val empty_expenses: String = "Вам не трэба адсочваць выдаткі. Дадайце новыя выдаткі."
override val empty_invoice_list_message: String = "Стварыце свой першы рахунак-фактуру"
override val empty_items_list_message: String = "У вас няма элементаў, дадайце новы элемент!"
override val empty_signatures_list_message: String = "Подпісы не знойдзены. Стварыце адзін!"
override val empty_taxes_list_message: String = "У вас няма падатковых правілаў. Дадайце новае падатковае правіла."
override val empty_templates_list_message: String = "Шаблоны недаступныя."
override val enabled: String = "Уключана"
override val entry: String = "запіс"
override val equity: String = "уласны капітал"
override val estimate_number: String = "Ацэнка №"
override val estimates: String = "Ацэнкі"
override val estimates_report: String = "Ацэнкі"
override val expense_bank_fees: String = "Банкаўскія зборы"
override val expense_benefits: String = "Перавагі"
override val expense_communication: String = "Сувязь"
override val expense_debt: String = "Запазычанасць"
override val expense_deleted: String = "Выдаткі выдалены"
override val expense_depreciation: String = "Амартызацыя"
override val expense_gifts: String = "падарункі"
override val expense_insurance: String = "Страхоўка"
override val expense_inventory: String = "Інвентар"
override val expense_legal: String = "Юрыдычныя"
override val expense_maintenance: String = "Тэхнічнае абслугоўванне"
override val expense_marketing: String = "Маркетынг"
override val expense_meals: String = "Харчаванне"
override val expense_office_supplies: String = "Канцтавары"
override val expense_rent: String = "Арэнда"
override val expense_salaries: String = "Заробкі"
override val expense_shopping: String = "Шопінг"
override val expense_software: String = "праграмнае забеспячэнне"
override val expense_status_available: String = "Даступны"
override val expense_status_processing: String = "Апрацоўка"
override val expense_tax: String = "Падатак"
override val expense_training: String = "Навучанне"
override val expense_transport: String = "Транспарт"
override val expense_travel: String = "Падарожжа"
override val expense_utility: String = "Карыснасць"
override val expenses: String = "Выдаткі"
override val feedback_option: String = "Зваротная сувязь"
override val filter: String = "Фільтраваць"
override val first_name_hint: String = "Імя"
override val forgot_password: String = "Скінуць пароль"
override val forgot_password_label: String = "ЗАБЫЛІСЯ НА ПАРОЛЬ"
override val from: String = "Ад"
override val general_error: String = "Адбылася памылка, паўтарыце спробу пазней."
override val general_ledger: String = "галоўная бухгалтарская кніга"
override val go_back_label: String = "Вяртацца"
override val group_by: String = "Група па:"
override val image_view: String = "Выява"
override val install: String = "Усталяваць"
override val invalid_credentials_error: String = "Несапраўдныя ўліковыя даныя."
override val invalid_email_error: String = "Несапраўдны адрас электроннай пошты."
override val invalid_user_error: String = "Няправільныя ўліковыя даныя карыстальніка."
override val invoice_deleted_message: String = "Рахунак-фактура выдалены"
override val invoice_menu: String = "Меню рахункаў-фактур"
override val invoice_number: String = "Рахунак-фактура №"
override val invoices: String = "Рахункі-фактуры"
override val items_option: String = "Прадметы"
override val keep: String = "Трымайце"
override val language_selector: Formattable = Formattable { (абрана) -> "Мова ${абрана}" }
override val last_name_hint: String = "Прозвішча"
override val ledger: String = "гросбух"
override val legal: String = "Юрыдычныя"
override val legal_error: String = "Павінен прыняць Умовы абслугоўвання і Палітыку прыватнасці"
override val liabilities: String = "абавязацельствы"
override val loading: String = "Загрузка"
override val login_required_error: String = "Патрабуецца ўваход у сістэму."
override val logo_view: String = "Лагатып"
override val logout: String = "Выхад"
override val logout_prompt: String = "Вашы даныя не сінхранізуюцца з воблакам. Выдаліць рахункі-фактуры, каштарысы, кліентаў і іншую інфармацыю пры выхадзе з сістэмы?"
override val main_screen: String = "Галоўны экран"
override val mark_paid: String = "Адзначыць як аплачанае"
override val message: String = "Паведамленне"
override val missing_sender_msg: String = "Дакумент без інфармацыі аб адпраўніку можа быць несапраўдным."
override val missing_sender_title: String = "Адсутнічае інфармацыя аб адпраўніку"
override val month_april: String = "Красавік"
override val month_april_short: String = "Красавік"
override val month_august: String = "Жнівень"
override val month_august_short: String = "Жнівень"
override val month_december: String = "Снежань"
override val month_december_short: String = "снежань"
override val month_february: String = "Люты"
override val month_february_short: String = "Люты"
override val month_january: String = "Студзень"
override val month_january_short: String = "Ян."
override val month_july: String = "Ліпень"
override val month_july_short: String = "Ліпень"
override val month_june: String = "Чэрвень"
override val month_june_short: String = "Чэрвень"
override val month_march: String = "Сакавік"
override val month_march_short: String = "Сакавік"
override val month_may: String = "Травень"
override val month_may_short: String = "Травень"
override val month_november: String = "Лістапад"
override val month_november_short: String = "Сту"
override val month_october: String = "Кастрычніцкая"
override val month_october_short: String = "Кастрычнік"
override val month_september: String = "Верасень"
override val month_september_short: String = "верасень"
override val multifactor_error: String = "Не ўдалося праверыць."
override val new: String = "Новы"
override val news: String = "Навіны"
override val next: String = "Далей"
override val no: String = "не"
override val no_data_available: String = "Дадзеных няма"
override val no_search_results: String = "На жаль, мы не змаглі знайсці ніякіх вынікаў."
override val none: String = "Няма"
override val note_fields: String = "Нататкі"
override val note_hint: String = "Нататка"
override val notifications_option: String = "Апавяшчэнні"
override val ok: String = "ОК"
override val onboarding: String = "Падрыхтоўка"
override val onboarding_aboutus_message: String = "Асноўныя часткі DingVoice назаўжды бясплатныя. Наша місія - дапамагчы вам развіць ваш бізнес, дапамагчы нам палепшыць, падзяліўшыся вашымі водгукамі."
override val onboarding_aboutus_title: String = "Мы тут, каб дапамагчы"
override val onboarding_paid_1: String = "Доступ да шаблонаў прафесійнага выгляду"
override val onboarding_paid_2: String = "Паглядзіце глыбей на свае фінансы"
override val onboarding_paid_3: String = "Хутка вырашайце праблемы"
override val onboarding_paid_4: String = "Захоўвайце свае дакументы ў бяспецы"
override val onboarding_paid_5: String = "Будзьце ў курсе змяненняў у статусе рахунку"
override val onboarding_paid_title: String = "Расці яшчэ хутчэй з..."
override val onboarding_paywall_message: String = "Атрымлівайце асалоду ад усіх прэміум-функцый зараз па лепшай цане."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Усяго ${currencySymbol}${introAmount} за ${introDuration} ${introPeriod}, а потым ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Усяго ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Бясплатна за ${trialDuration} ${trialPeriod}, а потым усяго за ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Бясплатна за ${trialDuration} ${trialPeriod}, затым ${currencySymbol}${introductoryAmount} за ${introductoryDuration} ${introPeriod}, а потым усяго за ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Аўтаматычнае абнаўленне. Адмяніць у любы час."
override val onboarding_paywall_restore: String = "Аднавіць куплю"
override val onboarding_paywall_restore_failure: String = "Не ўдалося аднавіць пакупкі, паўтарыце спробу пазней або звярніцеся ў службу падтрымкі."
override val onboarding_paywall_restore_success: String = "Аднаўленне паспяхова!"
override val onboarding_paywall_title: String = "Дасягнуць велічы!"
override val onboarding_welcome_message: String = "Поўны набор інструментаў для кіравання рахункамі-фактурамі і ацэнкамі."
override val onboarding_welcome_title: String = "Сардэчна запрашаем у DingVoice"
override val other_state: String = "Іншае"
override val overdue_state: String = "Пратэрмінаваны"
override val paid_state: String = "Платныя"
override val password: String = "Пароль"
override val password_blank_error: String = "Патрабуецца пароль."
override val password_mismatch_error: String = "Паролі не супадаюць."
override val pay: String = "oплатить"
override val payment_instructions_label: String = "Інструкцыя па аплаце"
override val payment_reminders: String = "Напамін аб аплаце"
override val phone_number_fields: String = "Нумар тэлефона"
override val phone_number_hint: String = "Нумар тэлефона"
override val postcode_hint: String = "Паштовы індэкс"
override val preview: String = "Папярэдні прагляд"
override val preview_menu_hint: String = "Меню папярэдняга прагляду"
override val price: String = "Кошт"
override val privacy_policy: String = "Палітыка прыватнасці"
override val profile: String = "Профіль"
override val profitable_client: String = "Ваш самы прыбытковы кліент - гэта..."
override val profits_report: String = "Прыбытак"
override val promo_content: String = "Рэкламны кантэнт"
override val quantity_hint: String = "Колькасць"
override val quantity_label_hint: String = "Этыкетка колькасці"
override val query_info: String = "Запыт дазваляе пісаць карыстальніцкія разлікі са спасылкай як на артыкулы, так і на іншыя падатковыя правілы ў якасці ўваходных дадзеных. Дадайце сімвал @ і апісанне пункта або падатку для спасылкі на іншы запіс +, -, *, /, % - матэматычныя аператары"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "Ваш кліент атрымаў ${documentType} ${documentNumber}." }
override val received_state: String = "Атрымаў"
override val registration_code_fields: String = "Код рэгістрацыі"
override val reminder: String = "Напамін"
override val reports: String = "Справаздачы"
override val reports_not_available: String = "Няма даступных справаздач, вярніцеся пазней."
override val reveal_password: String = "Адкрыйце пароль"
override val revenue: String = "даход"
override val sales_tax: String = "ПДВ"
override val sales_tax_number: String = "Нумар ПДВ"
override val save: String = "Захаваць"
override val scan_document: String = "Сканаваць дакумент"
override val search: String = "Пошук"
override val select: String = "Выберыце"
override val select_icon: String = "Выберыце значок"
override val select_signature: String = "Выберыце подпіс"
override val select_template: String = "Выберыце шаблон"
override val send: String = "Адправіць"
override val sent_state: String = "Адпраўлена"
override val setup_sender: String = "Наладзіць адпраўніка"
override val share_option: String = "доля"
override val ship_to: String = "Адправіць да"
override val sign_in: String = "Увайсці"
override val sign_in_apple: String = "Увайдзіце ў сістэму з Apple"
override val sign_in_email: String = "Увайдзіце з дапамогай электроннай пошты"
override val sign_in_facebook: String = "Увайдзіце на Facebook"
override val sign_in_google: String = "Увайсці праз Google"
override val sign_up: String = "ЗАРЭГІСТРАВАЦЦА"
override val sign_up_label: String = "У вас няма ўліковага запісу?"
override val sign_up_link_label: String = "Зарэгістравацца!"
override val signatures_option: String = "Подпісы"
override val skip: String = "Прапусціць"
override val sold_items_report: String = "Прададзеныя Pэчы"
override val sort_by: String = "Сартаваць па:"
override val status_update: String = "Абнаўленне статусу дакумента"
override val sub_annual: String = "Гадавы"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/год" }
override val sub_backups: String = "Воблачнае рэзервовае капіраванне"
override val sub_insights: String = "Інсайты"
override val sub_month: String = "Штомесяц"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/месяц" }
override val sub_notifications: String = "Апавяшчэнні"
override val sub_popup_message: String = "Хутка з&#39;явяцца падпіскі. Верніцеся сюды праз некаторы час."
override val sub_support: String = "Падтрымаць"
override val sub_templates: String = "Шаблоны"
override val subscribe: String = "Падпісацца"
override val subscription_header: String = "Развівайце свой бізнес з:"
override val subscription_option: String = "Падпіска"
override val subscriptions_load_fail: String = "На жаль, зараз мы не можам аднавіць вашы падпіскі. Паўтарыце спробу пазней або звярніцеся па дапамогу да нашай службы падтрымкі."
override val subtotal: Formattable = Formattable { (sum) -> "Прамежкавы вынік: ${sum}" }
override val subtotal_label: String = "Прамежкавы вынік"
override val sum: String = "Сума"
override val support_option: String = "Падтрымаць"
override val switch_reports: String = "Пераключыць Cправаздачу"
override val tax: String = "Падатак"
override val tax_deleted_message: String = "Падатак выдалены"
override val tax_description_warning: String = "Калі выкарыстоўваецца, гэта паказваецца ў рахунку-фактуры"
override val tax_input_flat: String = "Кватэра"
override val tax_input_percent: String = "Працэнт"
override val tax_number_fields: String = "Падатковы нумар"
override val tax_number_hint: String = "Падатковы нумар"
override val tax_query_info_description: String = "Інфармацыя аб запыце"
override val tax_report: String = "Падатак"
override val tax_rules_option: String = "Падатковыя правілы"
override val taxed_clients_report: String = "Падатковыя кліенты"
override val template: String = "шаблон"
override val template_selector_info: String = "Правядзіце пальцам, каб змяніць шаблон па змаўчанні"
override val template_unlock_promo: String = "Атрымлівайце доступ да большай колькасці шаблонаў з Premium"
override val templates_option: String = "Шаблоны"
override val terms_of_service: String = "Terms of Service"
override val thank_you: String = "Дзякуй"
override val to: String = "Каб"
override val total: Formattable = Formattable { (sum) -> "Усяго: ${sum}" }
override val total_label: String = "Усяго"
override val undo_action: String = "Адмяніць"
override val unit: String = "Адзінка"
override val unit_cost_hint: String = "Кошт адзінкі"
override val unknown_client: String = "Невядомы Kліент"
override val unlock: String = "Разблакіроўка"
override val unnamed_item: String = "Элемент без назвы"
override val upgrade: String = "Абнавіць"
override val user_collision_error: String = "Уліковы запіс ужо існуе."
override val valid_until: String = "Дзейнічае да"
override val warning: String = "Увага!"
override val yes: String = "tак"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Прыняць ${termsOfService} і ${privacyPolicy}" },
	"account" to "уліковы запіс",
	"accounting" to "бухгалтэрыя",
	"accruals" to "налічэнні",
	"action_code_error" to "Несапраўдны код.",
	"add_client_action" to "Дадаць кліент",
	"add_customer" to "Дадаць кліента",
	"add_expense" to "Дадайце выдаткі",
	"add_fields_label" to "Дадаць:",
	"add_invoice_action" to "Стварыць",
	"add_item_action" to "Дадаць элемент",
	"add_item_button" to "Дадаць элемент",
	"add_signature_action" to "Дадаць подпіс",
	"add_tax_action" to "Дадаць падатак",
	"address_fields" to "Адрас",
	"address_line_hint" to "Адрасны радок",
	"advanced" to "Пашыраны",
	"agree_marketing" to "Я згодны атрымліваць прамыя маркетынгавыя паведамленні",
	"all_notifications" to "Усе апавяшчэнні",
	"amortization" to "амартызацыя",
	"amount" to "Сума",
	"asset" to "актыў",
	"attach_file" to "Далучыць файл",
	"attachment" to "Далучэнне",
	"auth_error" to "Не ўдалося выканаць дзеянне, паўтарыце спробу.",
	"available_for_paid" to "Воблачная сінхранізацыя даступная толькі пры платнай падпісцы.",
	"backups_option" to "Рэзервовае капіраванне",
	"balance" to "баланс",
	"bank_account" to "Банкаўскі рахунак",
	"bill_number" to "Нумар рахунку",
	"cancel" to "Ануляваць",
	"cancel_trial" to "Адмяніць у любы час",
	"capital" to "капітал",
	"cash" to "наяўныя грошы",
	"categories" to "Катэгорыі",
	"category" to "Катэгорыя",
	"category_add" to "Дадаць катэгорыю",
	"category_delete" to "Выдаліць катэгорыю",
	"category_name" to "Назва катэгорыі",
	"change_language" to Formattable { (language) -> "Мова рахунка-фактуры: ${language}" },
	"change_language_label" to "Мова фактуры",
	"change_template" to "Змяніць шаблон",
	"city_hint" to "Горад",
	"clear" to "Ясна",
	"client" to "Кліент",
	"client_actions" to "Дзеянні кліента",
	"client_deleted_message" to "Кліент выдалены",
	"clients_error_required_fields" to "Патрабуецца прынамсі адно значэнне.",
	"clients_option" to "Кліенты",
	"close" to "Зачыніць",
	"cloud_sync" to "Воблачная сінхранізацыя",
	"coming_soon" to "Хутка!",
	"company_logo" to "Лагатып кампаніі",
	"company_name_hint" to "Кампанія",
	"confirm_password_label" to "Пацвердзіце пароль",
	"confirmation_title" to "Вы ўпэўнены?",
	"convert_to_invoice" to "Перавесці ў рахунак-фактуру",
	"country_hint" to "Краіна",
	"create_client" to "Стварыць кліент",
	"create_company" to "Стварыць кампанію",
	"create_document" to "Стварыць дакумент",
	"create_estimate" to "Ацэнка",
	"create_invoice" to "Рахунак-фактура",
	"create_item" to "Стварыць элемент",
	"create_new_client" to "Стварыце новага кліента",
	"create_new_item" to "Стварыць новы элемент",
	"create_new_tax" to "Стварыце новы падатак",
	"create_signature" to "Подпіс",
	"created" to "Створаны",
	"credit" to "крэдытная",
	"customer" to "Заказчык",
	"date" to "Дата",
	"debit" to "дэбетавая",
	"debt" to "доўг",
	"default_currency" to Formattable { (currency) -> "Валюта па змаўчанні: ${currency}" },
	"default_currency_label" to "Валюта па змаўчанні",
	"delete" to "Выдаляць",
	"delete_account" to "Выдаліць уліковы запіс",
	"delete_confirmation_message" to "Усе вашы даныя будуць страчаны.",
	"delete_item_title" to "Выдаліць элемент?",
	"delete_signature" to "Выдаліць подпіс",
	"depreciation" to "амартызацыя",
	"description" to "Апісанне",
	"description_hint" to "Апісанне",
	"detailed_report" to "Падрабязны Cправаздачу",
	"disabled" to "Інвалідам",
	"discount" to "Зніжка",
	"discount_hint" to "Зніжка",
	"display_af" to "афрыканскі",
	"display_am" to "Амхарская",
	"display_ar" to "арабская",
	"display_as" to "Асамская",
	"display_az" to "Азербайджанская",
	"display_be" to "беларускі",
	"display_bg" to "балгарская",
	"display_bn" to "бенгальская",
	"display_bs" to "басьнійскі",
	"display_ca" to "каталонская",
	"display_cs" to "чэшская",
	"display_da" to "дацкая",
	"display_de" to "нямецкі",
	"display_el" to "грэцкі",
	"display_en" to "АНГЛІЙСКАЯ",
	"display_es" to "іспанскі",
	"display_es_MX" to "Іспанская (Мексіка)",
	"display_et" to "эстонскі",
	"display_eu" to "Баскская",
	"display_fa" to "фарсі",
	"display_fi" to "фінская",
	"display_fil" to "філіпінскі",
	"display_fr" to "французскі",
	"display_fr_CA" to "Канадскі французскі",
	"display_gl" to "галісійскі",
	"display_gu" to "Гуджараці",
	"display_he" to "іўрыт",
	"display_hi" to "не",
	"display_hr" to "харвацкая",
	"display_hu" to "венгерскі",
	"display_hy" to "армянская",
	"display_id" to "інданезійскі",
	"display_is" to "ісландская",
	"display_it" to "італьянскі",
	"display_ja" to "Японскі",
	"display_ka" to "грузінскі",
	"display_kk" to "казахская",
	"display_km" to "цэнтральна-кхмерскі",
	"display_kn" to "каннада",
	"display_ko" to "карэйская",
	"display_ky" to "кіргізская",
	"display_lo" to "Праца",
	"display_lt" to "літоўскі",
	"display_lv" to "Латышскі",
	"display_mk" to "Македонская",
	"display_ml" to "Малаялам",
	"display_mn" to "Мангольская",
	"display_mr" to "Маратхі",
	"display_ms" to "малайская",
	"display_my" to "бірманскі",
	"display_nb" to "Нарвежская букмол",
	"display_ne" to "непальская",
	"display_nl" to "галандскі",
	"display_or" to "орыя",
	"display_pa" to "Панджабі",
	"display_pl" to "польская",
	"display_pt" to "партугальская",
	"display_pt_BR" to "партугальская (Бразілія)",
	"display_pt_PT" to "Еўрапейская партугальская",
	"display_ro" to "румынская",
	"display_ru" to "рускі",
	"display_si" to "сінгальская",
	"display_sk" to "славацкая",
	"display_sl" to "Славенская",
	"display_sq" to "албанец",
	"display_sr" to "сербская",
	"display_sv" to "шведская",
	"display_sw" to "Суахілі",
	"display_ta" to "тамільская",
	"display_te" to "Тэлугу",
	"display_th" to "Тайская",
	"display_tr" to "турэцкая",
	"display_uk" to "украінскі",
	"display_ur" to "Урду",
	"display_uz" to "узбекская",
	"display_vi" to "в&#39;етнамская",
	"display_zh_Hant_TW" to "Кітайская (Тайвань, Традыцыйная)",
	"display_zu" to "Зулу",
	"document_estimate" to "Ацэнка",
	"document_invoice" to "Рахунак-фактура",
	"document_quote" to "Прапанова",
	"done" to "Гатова",
	"download_app" to "Спампаваць праграму",
	"download_invoice" to "Спампаваць Фактуру",
	"download_pdf" to "Спампаваць PDF",
	"draw_signature" to "Намалюйце подпіс",
	"due" to Formattable { (date) -> "Тэрмін: ${date}" },
	"due_date" to "Тэрмін",
	"due_day" to "праз дзень",
	"due_label" to "З-за",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} аплачваецца ${dueWhen}!" },
	"due_today" to "сёння",
	"due_week" to "праз тыдзень",
	"duplicate" to "Дублікат",
	"duration_day" to "дзень",
	"duration_month" to "Месяц",
	"duration_week" to "тыдзень",
	"duration_year" to "год",
	"email" to "электронная пошта",
	"email_blank_error" to "Патрабуецца адрас электроннай пошты.",
	"email_error" to "Няправільны адрас электроннай пошты.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Дзякуй за вашу справу. Каб прагледзець ${documentType} цалкам, націсніце кнопку ў канцы гэтага ліста. Звярніце ўвагу, што ён сапраўдны да ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Вы атрымалі новы ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "ПРАГЛЯД ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Паважаны ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Дзякуй за вашу справу. Каб прагледзець ${documentType} цалкам, націсніце кнопку ў канцы гэтага ліста. Аплаціце астатак да ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Вы атрымалі новы ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Адпраўлены ліст для скіду пароля, праверце сваю паштовую скрыню.",
	"empty_clients_list_message" to "У вас няма кліентаў для адсочвання. Дадаць новага кліента.",
	"empty_estimate_list_message" to "Стварыце сваю першую ацэнку",
	"empty_expenses" to "Вам не трэба адсочваць выдаткі. Дадайце новыя выдаткі.",
	"empty_invoice_list_message" to "Стварыце свой першы рахунак-фактуру",
	"empty_items_list_message" to "У вас няма элементаў, дадайце новы элемент!",
	"empty_signatures_list_message" to "Подпісы не знойдзены. Стварыце адзін!",
	"empty_taxes_list_message" to "У вас няма падатковых правілаў. Дадайце новае падатковае правіла.",
	"empty_templates_list_message" to "Шаблоны недаступныя.",
	"enabled" to "Уключана",
	"entry" to "запіс",
	"equity" to "уласны капітал",
	"estimate_number" to "Ацэнка №",
	"estimates" to "Ацэнкі",
	"estimates_report" to "Ацэнкі",
	"expense_bank_fees" to "Банкаўскія зборы",
	"expense_benefits" to "Перавагі",
	"expense_communication" to "Сувязь",
	"expense_debt" to "Запазычанасць",
	"expense_deleted" to "Выдаткі выдалены",
	"expense_depreciation" to "Амартызацыя",
	"expense_gifts" to "падарункі",
	"expense_insurance" to "Страхоўка",
	"expense_inventory" to "Інвентар",
	"expense_legal" to "Юрыдычныя",
	"expense_maintenance" to "Тэхнічнае абслугоўванне",
	"expense_marketing" to "Маркетынг",
	"expense_meals" to "Харчаванне",
	"expense_office_supplies" to "Канцтавары",
	"expense_rent" to "Арэнда",
	"expense_salaries" to "Заробкі",
	"expense_shopping" to "Шопінг",
	"expense_software" to "праграмнае забеспячэнне",
	"expense_status_available" to "Даступны",
	"expense_status_processing" to "Апрацоўка",
	"expense_tax" to "Падатак",
	"expense_training" to "Навучанне",
	"expense_transport" to "Транспарт",
	"expense_travel" to "Падарожжа",
	"expense_utility" to "Карыснасць",
	"expenses" to "Выдаткі",
	"feedback_option" to "Зваротная сувязь",
	"filter" to "Фільтраваць",
	"first_name_hint" to "Імя",
	"forgot_password" to "Скінуць пароль",
	"forgot_password_label" to "ЗАБЫЛІСЯ НА ПАРОЛЬ",
	"from" to "Ад",
	"general_error" to "Адбылася памылка, паўтарыце спробу пазней.",
	"general_ledger" to "галоўная бухгалтарская кніга",
	"go_back_label" to "Вяртацца",
	"group_by" to "Група па:",
	"image_view" to "Выява",
	"install" to "Усталяваць",
	"invalid_credentials_error" to "Несапраўдныя ўліковыя даныя.",
	"invalid_email_error" to "Несапраўдны адрас электроннай пошты.",
	"invalid_user_error" to "Няправільныя ўліковыя даныя карыстальніка.",
	"invoice_deleted_message" to "Рахунак-фактура выдалены",
	"invoice_menu" to "Меню рахункаў-фактур",
	"invoice_number" to "Рахунак-фактура №",
	"invoices" to "Рахункі-фактуры",
	"items_option" to "Прадметы",
	"keep" to "Трымайце",
	"language_selector" to Formattable { (абрана) -> "Мова ${абрана}" },
	"last_name_hint" to "Прозвішча",
	"ledger" to "гросбух",
	"legal" to "Юрыдычныя",
	"legal_error" to "Павінен прыняць Умовы абслугоўвання і Палітыку прыватнасці",
	"liabilities" to "абавязацельствы",
	"loading" to "Загрузка",
	"login_required_error" to "Патрабуецца ўваход у сістэму.",
	"logo_view" to "Лагатып",
	"logout" to "Выхад",
	"logout_prompt" to "Вашы даныя не сінхранізуюцца з воблакам. Выдаліць рахункі-фактуры, каштарысы, кліентаў і іншую інфармацыю пры выхадзе з сістэмы?",
	"main_screen" to "Галоўны экран",
	"mark_paid" to "Адзначыць як аплачанае",
	"message" to "Паведамленне",
	"missing_sender_msg" to "Дакумент без інфармацыі аб адпраўніку можа быць несапраўдным.",
	"missing_sender_title" to "Адсутнічае інфармацыя аб адпраўніку",
	"month_april" to "Красавік",
	"month_april_short" to "Красавік",
	"month_august" to "Жнівень",
	"month_august_short" to "Жнівень",
	"month_december" to "Снежань",
	"month_december_short" to "снежань",
	"month_february" to "Люты",
	"month_february_short" to "Люты",
	"month_january" to "Студзень",
	"month_january_short" to "Ян.",
	"month_july" to "Ліпень",
	"month_july_short" to "Ліпень",
	"month_june" to "Чэрвень",
	"month_june_short" to "Чэрвень",
	"month_march" to "Сакавік",
	"month_march_short" to "Сакавік",
	"month_may" to "Травень",
	"month_may_short" to "Травень",
	"month_november" to "Лістапад",
	"month_november_short" to "Сту",
	"month_october" to "Кастрычніцкая",
	"month_october_short" to "Кастрычнік",
	"month_september" to "Верасень",
	"month_september_short" to "верасень",
	"multifactor_error" to "Не ўдалося праверыць.",
	"new" to "Новы",
	"news" to "Навіны",
	"next" to "Далей",
	"no" to "не",
	"no_data_available" to "Дадзеных няма",
	"no_search_results" to "На жаль, мы не змаглі знайсці ніякіх вынікаў.",
	"none" to "Няма",
	"note_fields" to "Нататкі",
	"note_hint" to "Нататка",
	"notifications_option" to "Апавяшчэнні",
	"ok" to "ОК",
	"onboarding" to "Падрыхтоўка",
	"onboarding_aboutus_message" to "Асноўныя часткі DingVoice назаўжды бясплатныя. Наша місія - дапамагчы вам развіць ваш бізнес, дапамагчы нам палепшыць, падзяліўшыся вашымі водгукамі.",
	"onboarding_aboutus_title" to "Мы тут, каб дапамагчы",
	"onboarding_paid_1" to "Доступ да шаблонаў прафесійнага выгляду",
	"onboarding_paid_2" to "Паглядзіце глыбей на свае фінансы",
	"onboarding_paid_3" to "Хутка вырашайце праблемы",
	"onboarding_paid_4" to "Захоўвайце свае дакументы ў бяспецы",
	"onboarding_paid_5" to "Будзьце ў курсе змяненняў у статусе рахунку",
	"onboarding_paid_title" to "Расці яшчэ хутчэй з...",
	"onboarding_paywall_message" to "Атрымлівайце асалоду ад усіх прэміум-функцый зараз па лепшай цане.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Усяго ${currencySymbol}${introAmount} за ${introDuration} ${introPeriod}, а потым ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Усяго ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Бясплатна за ${trialDuration} ${trialPeriod}, а потым усяго за ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Бясплатна за ${trialDuration} ${trialPeriod}, затым ${currencySymbol}${introductoryAmount} за ${introductoryDuration} ${introPeriod}, а потым усяго за ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Аўтаматычнае абнаўленне. Адмяніць у любы час.",
	"onboarding_paywall_restore" to "Аднавіць куплю",
	"onboarding_paywall_restore_failure" to "Не ўдалося аднавіць пакупкі, паўтарыце спробу пазней або звярніцеся ў службу падтрымкі.",
	"onboarding_paywall_restore_success" to "Аднаўленне паспяхова!",
	"onboarding_paywall_title" to "Дасягнуць велічы!",
	"onboarding_welcome_message" to "Поўны набор інструментаў для кіравання рахункамі-фактурамі і ацэнкамі.",
	"onboarding_welcome_title" to "Сардэчна запрашаем у DingVoice",
	"other_state" to "Іншае",
	"overdue_state" to "Пратэрмінаваны",
	"paid_state" to "Платныя",
	"password" to "Пароль",
	"password_blank_error" to "Патрабуецца пароль.",
	"password_mismatch_error" to "Паролі не супадаюць.",
	"pay" to "oплатить",
	"payment_instructions_label" to "Інструкцыя па аплаце",
	"payment_reminders" to "Напамін аб аплаце",
	"phone_number_fields" to "Нумар тэлефона",
	"phone_number_hint" to "Нумар тэлефона",
	"postcode_hint" to "Паштовы індэкс",
	"preview" to "Папярэдні прагляд",
	"preview_menu_hint" to "Меню папярэдняга прагляду",
	"price" to "Кошт",
	"privacy_policy" to "Палітыка прыватнасці",
	"profile" to "Профіль",
	"profitable_client" to "Ваш самы прыбытковы кліент - гэта...",
	"profits_report" to "Прыбытак",
	"promo_content" to "Рэкламны кантэнт",
	"quantity_hint" to "Колькасць",
	"quantity_label_hint" to "Этыкетка колькасці",
	"query_info" to "Запыт дазваляе пісаць карыстальніцкія разлікі са спасылкай як на артыкулы, так і на іншыя падатковыя правілы ў якасці ўваходных дадзеных. Дадайце сімвал @ і апісанне пункта або падатку для спасылкі на іншы запіс +, -, *, /, % - матэматычныя аператары",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "Ваш кліент атрымаў ${documentType} ${documentNumber}." },
	"received_state" to "Атрымаў",
	"registration_code_fields" to "Код рэгістрацыі",
	"reminder" to "Напамін",
	"reports" to "Справаздачы",
	"reports_not_available" to "Няма даступных справаздач, вярніцеся пазней.",
	"reveal_password" to "Адкрыйце пароль",
	"revenue" to "даход",
	"sales_tax" to "ПДВ",
	"sales_tax_number" to "Нумар ПДВ",
	"save" to "Захаваць",
	"scan_document" to "Сканаваць дакумент",
	"search" to "Пошук",
	"select" to "Выберыце",
	"select_icon" to "Выберыце значок",
	"select_signature" to "Выберыце подпіс",
	"select_template" to "Выберыце шаблон",
	"send" to "Адправіць",
	"sent_state" to "Адпраўлена",
	"setup_sender" to "Наладзіць адпраўніка",
	"share_option" to "доля",
	"ship_to" to "Адправіць да",
	"sign_in" to "Увайсці",
	"sign_in_apple" to "Увайдзіце ў сістэму з Apple",
	"sign_in_email" to "Увайдзіце з дапамогай электроннай пошты",
	"sign_in_facebook" to "Увайдзіце на Facebook",
	"sign_in_google" to "Увайсці праз Google",
	"sign_up" to "ЗАРЭГІСТРАВАЦЦА",
	"sign_up_label" to "У вас няма ўліковага запісу?",
	"sign_up_link_label" to "Зарэгістравацца!",
	"signatures_option" to "Подпісы",
	"skip" to "Прапусціць",
	"sold_items_report" to "Прададзеныя Pэчы",
	"sort_by" to "Сартаваць па:",
	"status_update" to "Абнаўленне статусу дакумента",
	"sub_annual" to "Гадавы",
	"sub_annual_price" to Formattable { (price) -> "${price}/год" },
	"sub_backups" to "Воблачнае рэзервовае капіраванне",
	"sub_insights" to "Інсайты",
	"sub_month" to "Штомесяц",
	"sub_monthly_price" to Formattable { (price) -> "${price}/месяц" },
	"sub_notifications" to "Апавяшчэнні",
	"sub_popup_message" to "Хутка з&#39;явяцца падпіскі. Верніцеся сюды праз некаторы час.",
	"sub_support" to "Падтрымаць",
	"sub_templates" to "Шаблоны",
	"subscribe" to "Падпісацца",
	"subscription_header" to "Развівайце свой бізнес з:",
	"subscription_option" to "Падпіска",
	"subscriptions_load_fail" to "На жаль, зараз мы не можам аднавіць вашы падпіскі. Паўтарыце спробу пазней або звярніцеся па дапамогу да нашай службы падтрымкі.",
	"subtotal" to Formattable { (sum) -> "Прамежкавы вынік: ${sum}" },
	"subtotal_label" to "Прамежкавы вынік",
	"sum" to "Сума",
	"support_option" to "Падтрымаць",
	"switch_reports" to "Пераключыць Cправаздачу",
	"tax" to "Падатак",
	"tax_deleted_message" to "Падатак выдалены",
	"tax_description_warning" to "Калі выкарыстоўваецца, гэта паказваецца ў рахунку-фактуры",
	"tax_input_flat" to "Кватэра",
	"tax_input_percent" to "Працэнт",
	"tax_number_fields" to "Падатковы нумар",
	"tax_number_hint" to "Падатковы нумар",
	"tax_query_info_description" to "Інфармацыя аб запыце",
	"tax_report" to "Падатак",
	"tax_rules_option" to "Падатковыя правілы",
	"taxed_clients_report" to "Падатковыя кліенты",
	"template" to "шаблон",
	"template_selector_info" to "Правядзіце пальцам, каб змяніць шаблон па змаўчанні",
	"template_unlock_promo" to "Атрымлівайце доступ да большай колькасці шаблонаў з Premium",
	"templates_option" to "Шаблоны",
	"terms_of_service" to "Terms of Service",
	"thank_you" to "Дзякуй",
	"to" to "Каб",
	"total" to Formattable { (sum) -> "Усяго: ${sum}" },
	"total_label" to "Усяго",
	"undo_action" to "Адмяніць",
	"unit" to "Адзінка",
	"unit_cost_hint" to "Кошт адзінкі",
	"unknown_client" to "Невядомы Kліент",
	"unlock" to "Разблакіроўка",
	"unnamed_item" to "Элемент без назвы",
	"upgrade" to "Абнавіць",
	"user_collision_error" to "Уліковы запіс ужо існуе.",
	"valid_until" to "Дзейнічае да",
	"warning" to "Увага!",
	"yes" to "tак",
    )
    
}