// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Fi  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Hyväksy ${termsOfService} ja ${privacyPolicy}" }
override val account: String = "tili"
override val accounting: String = "kirjanpito"
override val accruals: String = "siirtovelat"
override val action_code_error: String = "Virheellinen koodi."
override val add_client_action: String = "Lisää asiakas"
override val add_customer: String = "Lisää asiakas"
override val add_expense: String = "Lisää kuluja"
override val add_fields_label: String = "Lisää:"
override val add_invoice_action: String = "Luo"
override val add_item_action: String = "Lisää tuote"
override val add_item_button: String = "Lisää tuote"
override val add_signature_action: String = "Lisää allekirjoitus"
override val add_tax_action: String = "Lisää vero"
override val address_fields: String = "Osoite"
override val address_line_hint: String = "Osoiterivi"
override val advanced: String = "Edistynyt"
override val agree_marketing: String = "Hyväksyn suoramarkkinointiviestinnän vastaanottamisen"
override val all_notifications: String = "Kaikki ilmoitukset"
override val amortization: String = "poistot"
override val amount: String = "Määrä"
override val asset: String = "omaisuuserä"
override val attach_file: String = "Liitä tiedosto"
override val attachment: String = "Liite"
override val auth_error: String = "Toimenpide epäonnistui, yritä uudelleen."
override val available_for_paid: String = "Pilvisynkronointi on käytettävissä vain maksullisessa tilauksessa."
override val backups_option: String = "Varmuuskopiot"
override val balance: String = "saldo"
override val bank_account: String = "Pankkitili"
override val bill_number: String = "Laskun numero"
override val cancel: String = "Peruuta"
override val cancel_trial: String = "Peruuta milloin tahansa"
override val capital: String = "pääoma"
override val cash: String = "käteinen"
override val categories: String = "Kategoriat"
override val category: String = "Luokka"
override val category_add: String = "Lisää luokka"
override val category_delete: String = "Poista luokka"
override val category_name: String = "Kategorian nimi"
override val change_language: Formattable = Formattable { (language) -> "Laskun kieli: ${language}" }
override val change_language_label: String = "Laskun Kieli"
override val change_template: String = "Muuta mallia"
override val city_hint: String = "Kaupunki"
override val clear: String = "Kirkas"
override val client: String = "Asiakas"
override val client_actions: String = "Asiakkaan toimet"
override val client_deleted_message: String = "Asiakas poistettu"
override val clients_error_required_fields: String = "Vähintään yksi arvo vaaditaan."
override val clients_option: String = "Asiakkaat"
override val close: String = "Sulje"
override val cloud_sync: String = "Pilvi-synkronointi"
override val coming_soon: String = "Tulossa pian!"
override val company_logo: String = "Yrityksen logo"
override val company_name_hint: String = "Yritys"
override val confirm_password_label: String = "Vahvista salasana"
override val confirmation_title: String = "Oletko varma?"
override val convert_to_invoice: String = "Muunna laskuksi"
override val country_hint: String = "Maa"
override val create_client: String = "Luo asiakas"
override val create_company: String = "Luo yritys"
override val create_document: String = "Luo asiakirja"
override val create_estimate: String = "Arvio"
override val create_invoice: String = "Lasku"
override val create_item: String = "Luo kohde"
override val create_new_client: String = "Luo uusi asiakas"
override val create_new_item: String = "Luo uusi kohde"
override val create_new_tax: String = "Luo uusi vero"
override val create_signature: String = "Allekirjoitus"
override val created: String = "Luotu"
override val credit: String = "luotto"
override val customer: String = "Asiakas"
override val date: String = "Päivämäärä"
override val debit: String = "pankki"
override val debt: String = "velka"
override val default_currency: Formattable = Formattable { (currency) -> "Oletusvaluutta: ${currency}" }
override val default_currency_label: String = "Oletusvaluutta"
override val delete: String = "Poista"
override val delete_account: String = "Poista Tili"
override val delete_confirmation_message: String = "Kaikki tietosi menetetään."
override val delete_item_title: String = "Poista kohde?"
override val delete_signature: String = "Poista allekirjoitus"
override val depreciation: String = "poistot"
override val description: String = "Kuvaus"
override val description_hint: String = "Kuvaus"
override val detailed_report: String = "Yksityiskohtainen Raportti"
override val disabled: String = "Vammaiset"
override val discount: String = "Alennus"
override val discount_hint: String = "Alennus"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharic"
override val display_ar: String = "Arabia"
override val display_as: String = "Assamilainen"
override val display_az: String = "Azerbaidžan"
override val display_be: String = "Valko-Venäjän"
override val display_bg: String = "Bulgarian"
override val display_bn: String = "Bengali"
override val display_bs: String = "Bosnian"
override val display_ca: String = "Katalaani"
override val display_cs: String = "Tšekki"
override val display_da: String = "Tanskalainen"
override val display_de: String = "Saksan"
override val display_el: String = "Kreikkalainen"
override val display_en: String = "Englanti"
override val display_es: String = "Espanjan"
override val display_es_MX: String = "espanja (Meksiko)"
override val display_et: String = "Viron"
override val display_eu: String = "Baskimaan"
override val display_fa: String = "Persialainen"
override val display_fi: String = "Suomalainen"
override val display_fil: String = "Filippiiniläinen"
override val display_fr: String = "Ranskan"
override val display_fr_CA: String = "Kanadan ranska"
override val display_gl: String = "Galician"
override val display_gu: String = "Gujarati"
override val display_he: String = "Heprea"
override val display_hi: String = "Hindi"
override val display_hr: String = "Kroatian"
override val display_hu: String = "Unkarilainen"
override val display_hy: String = "Armenian"
override val display_id: String = "Indonesialainen"
override val display_is: String = "Islantilainen"
override val display_it: String = "Italian"
override val display_ja: String = "Japanilainen"
override val display_ka: String = "Georgian"
override val display_kk: String = "Kazakstan"
override val display_km: String = "Keski-khmeri"
override val display_kn: String = "Kannada"
override val display_ko: String = "Korean"
override val display_ky: String = "Kirgisia"
override val display_lo: String = "Lao"
override val display_lt: String = "Liettuan"
override val display_lv: String = "Latvian"
override val display_mk: String = "Makedonia"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongolian"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malaiji"
override val display_my: String = "Burman"
override val display_nb: String = "Norjalainen Bokmål"
override val display_ne: String = "Nepali"
override val display_nl: String = "Hollantilainen"
override val display_or: String = "Oriya"
override val display_pa: String = "Punjabi"
override val display_pl: String = "Puolan"
override val display_pt: String = "Portugalin"
override val display_pt_BR: String = "portugali (Brasilia)"
override val display_pt_PT: String = "Euroopan portugali"
override val display_ro: String = "Romanian"
override val display_ru: String = "Venäläinen"
override val display_si: String = "Sinhala"
override val display_sk: String = "Slovakian"
override val display_sl: String = "Slovenian"
override val display_sq: String = "Albanian"
override val display_sr: String = "Serbian"
override val display_sv: String = "Ruotsalainen"
override val display_sw: String = "Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Turkkilainen"
override val display_uk: String = "Ukrainan"
override val display_ur: String = "Urdu"
override val display_uz: String = "Uzbekistan"
override val display_vi: String = "Vietnamilainen"
override val display_zh_Hant_TW: String = "Kiina (Taiwan, Perinteinen)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Arvio"
override val document_invoice: String = "Lasku"
override val document_quote: String = "Hintatarjous"
override val done: String = "Valmis"
override val download_app: String = "Lataa sovellus"
override val download_invoice: String = "Lataa Lasku"
override val download_pdf: String = "Lataa PDF"
override val draw_signature: String = "Piirrä allekirjoitus"
override val due: Formattable = Formattable { (date) -> "Eräpäivä ${date}" }
override val due_date: String = "Eräpäivä"
override val due_day: String = "päivässä"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} on erääntynyt ${dueWhen}!" }
override val due_today: String = "tänään"
override val due_week: String = "viikossa"
override val duplicate: String = "Duplikaatti"
override val duration_day: String = "päivä"
override val duration_month: String = "kuukausi"
override val duration_week: String = "viikko"
override val duration_year: String = "vuosi"
override val email: String = "Sähköposti"
override val email_blank_error: String = "Sähköposti vaaditaan."
override val email_error: String = "Virheellinen sähköpostiosoite."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Kiitos liiketoiminnastanne. Jos haluat nähdä koko ${documentType}:n, klikkaa tämän kirjeen lopussa olevaa painiketta. Huomaa, että se on voimassa ${dueDate} asti." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Olet saanut uuden asiakirjan: ${documentType} ${invoiceNumber}!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "NÄYTÄ ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Hyvä ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Kiitos liiketoiminnastanne. Jos haluat nähdä koko ${documentType}:n, klikkaa tämän kirjeen lopussa olevaa painiketta. Maksakaa loppuerä ${dueDate} mennessä." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Olet saanut uuden asiakirjan: ${documentType} ${invoiceNumber}!" }
override val email_reset_success_message: String = "Sähköposti salasanan palauttamiseksi on lähetetty, tarkista saapuneet sähköpostiviestit."
override val empty_clients_list_message: String = "Sinulla ei ole asiakkaita seurattavaksi. Lisää uusi asiakas."
override val empty_estimate_list_message: String = "Luo ensimmäinen arvio"
override val empty_expenses: String = "Sinulla ei ole seurattavia kuluja. Lisää uusi kulu."
override val empty_invoice_list_message: String = "Luo ensimmäinen lasku"
override val empty_items_list_message: String = "Sinulla ei ole kohteita, lisää uusi kohde!"
override val empty_signatures_list_message: String = "Allekirjoituksia ei löytynyt. Luo sellainen!"
override val empty_taxes_list_message: String = "Sinulla ei ole verosääntöjä. Lisää uusi verosääntö."
override val empty_templates_list_message: String = "Mallit eivät ole käytettävissä."
override val enabled: String = "Käytössä"
override val entry: String = "kirjanpitokirjaus"
override val equity: String = "oma pääoma"
override val estimate_number: String = "Arvio #"
override val estimates: String = "Arviot"
override val estimates_report: String = "Arviot"
override val expense_bank_fees: String = "Pankkimaksut"
override val expense_benefits: String = "Edut"
override val expense_communication: String = "Viestintä"
override val expense_debt: String = "Velka"
override val expense_deleted: String = "Menot poistettu"
override val expense_depreciation: String = "Poistot"
override val expense_gifts: String = "Lahjat"
override val expense_insurance: String = "Vakuutus"
override val expense_inventory: String = "inventaario"
override val expense_legal: String = "Oikeudellinen"
override val expense_maintenance: String = "Huolto"
override val expense_marketing: String = "Markkinointi"
override val expense_meals: String = "Ateriat"
override val expense_office_supplies: String = "Toimistotarvikkeet"
override val expense_rent: String = "Vuokraa"
override val expense_salaries: String = "Palkat"
override val expense_shopping: String = "Ostokset"
override val expense_software: String = "Ohjelmisto"
override val expense_status_available: String = "Saatavilla"
override val expense_status_processing: String = "Käsittely"
override val expense_tax: String = "Vero"
override val expense_training: String = "Koulutus"
override val expense_transport: String = "Liikenne"
override val expense_travel: String = "matka"
override val expense_utility: String = "Apuohjelma"
override val expenses: String = "Kulut"
override val feedback_option: String = "Palaute"
override val filter: String = "Suodatin"
override val first_name_hint: String = "Etunimi"
override val forgot_password: String = "Nollaa salasana"
override val forgot_password_label: String = "Unohditko salasanan"
override val from: String = "osoitteesta"
override val general_error: String = "Tapahtui virhe, yritä myöhemmin uudelleen."
override val general_ledger: String = "pääkirja"
override val go_back_label: String = "Palaa takaisin"
override val group_by: String = "Ryhmän nimi:"
override val image_view: String = "Kuva"
override val install: String = "Asenna"
override val invalid_credentials_error: String = "Virheelliset valtakirjat."
override val invalid_email_error: String = "Virheellinen sähköpostiosoite."
override val invalid_user_error: String = "Virheelliset käyttäjätunnukset."
override val invoice_deleted_message: String = "Lasku poistettu"
override val invoice_menu: String = "Laskun valikko"
override val invoice_number: String = "Lasku #"
override val invoices: String = "Laskut"
override val items_option: String = "kohteet"
override val keep: String = "Pidä"
override val language_selector: Formattable = Formattable { (valittu) -> "Kieli ${valittu}" }
override val last_name_hint: String = "Sukunimi"
override val ledger: String = "pääkirja"
override val legal: String = "Oikeudellinen"
override val legal_error: String = "On hyväksyttävä käyttöehdot ja tietosuojakäytäntö"
override val liabilities: String = "velat"
override val loading: String = "Ladataan"
override val login_required_error: String = "Sisäänkirjautuminen vaaditaan."
override val logo_view: String = "Logo"
override val logout: String = "Kirjaudu ulos"
override val logout_prompt: String = "Tietojasi ei synkronoida pilven kanssa. Poista laskuja, arvioita, asiakkaita ja muita tietoja uloskirjautumisen yhteydessä?"
override val main_screen: String = "Päänäyttö"
override val mark_paid: String = "Merkitse maksetuksi"
override val message: String = "Viesti"
override val missing_sender_msg: String = "Asiakirja, jossa ei ole lähettäjän tietoja, saattaa olla virheellinen."
override val missing_sender_title: String = "Lähettäjän tiedot puuttuvat"
override val month_april: String = "Huhtikuu"
override val month_april_short: String = "Huhtikuu"
override val month_august: String = "Elokuu"
override val month_august_short: String = "Elo."
override val month_december: String = "Joulukuu"
override val month_december_short: String = "Jou."
override val month_february: String = "Helmikuu"
override val month_february_short: String = "Hel."
override val month_january: String = "Tammikuu"
override val month_january_short: String = "Tam."
override val month_july: String = "Heinäkuu"
override val month_july_short: String = "Heinäkuu"
override val month_june: String = "Kesäkuu"
override val month_june_short: String = "Kesäkuu"
override val month_march: String = "Maaliskuu"
override val month_march_short: String = "Maaliskuu"
override val month_may: String = "Toukokuu"
override val month_may_short: String = "Toukokuu"
override val month_november: String = "Marraskuu"
override val month_november_short: String = "Mar."
override val month_october: String = "Lokakuu"
override val month_october_short: String = "Lok."
override val month_september: String = "Syyskuu"
override val month_september_short: String = "Syys."
override val multifactor_error: String = "Epäonnistui tarkistuksessa."
override val new: String = "Uusi"
override val news: String = "Uutiset"
override val next: String = "Seuraava"
override val no: String = "ei"
override val no_data_available: String = "Tietoja ei ole saatavilla"
override val no_search_results: String = "Valitettavasti emme löytäneet tuloksia."
override val none: String = "Ei ole"
override val note_fields: String = "Huomautukset"
override val note_hint: String = "Huomautus"
override val notifications_option: String = "ilmoitukset"
override val ok: String = "OK"
override val onboarding: String = "Käyttöönotto"
override val onboarding_aboutus_message: String = "DingVoicen olennaiset osat ovat ikuisesti ilmaisia. Tehtävämme on auttaa sinua kasvattamaan liiketoimintaasi, auta meitä parantamaan sitä jakamalla palautettasi."
override val onboarding_aboutus_title: String = "Olemme täällä auttamassa"
override val onboarding_paid_1: String = "Pääsy ammattimaisen näköisiin malleihin"
override val onboarding_paid_2: String = "Tutustu tarkemmin talouteesi"
override val onboarding_paid_3: String = "Ongelmat ratkaistaan nopeasti"
override val onboarding_paid_4: String = "Säilytä asiakirjasi turvassa"
override val onboarding_paid_5: String = "Pysy ajan tasalla laskujen tilamuutoksista"
override val onboarding_paid_title: String = "Kasva vielä nopeammin..."
override val onboarding_paywall_message: String = "Nauti kaikista premium-ominaisuuksista nyt parhaaseen hintaan."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Vain ${currencySymbol}${introAmount} ${introDuration} ${introPeriod} ja sitten ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (valuuttasymboli, summa, kesto) -> "Vain ${valuuttasymboli}${summa} / ${kesto}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Ilmainen ${trialDuration} ${trialPeriod} ajan ja sitten vain ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Ilmainen ${trialDuration} ${trialPeriod} ajan, sitten ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}ajan ja sitten vain ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automaattisesti uusiutuva. Peruutus milloin tahansa."
override val onboarding_paywall_restore: String = "Palauta osto"
override val onboarding_paywall_restore_failure: String = "Ostosten palauttaminen epäonnistui, yritä myöhemmin uudelleen tai ota yhteyttä asiakaspalveluun."
override val onboarding_paywall_restore_success: String = "Palautus onnistui!"
override val onboarding_paywall_title: String = "Saavuta suuruutta!"
override val onboarding_welcome_message: String = "Täydellinen työkalupaketti laskujen ja arvioiden hallintaan."
override val onboarding_welcome_title: String = "Tervetuloa DingVoiceen"
override val other_state: String = "Muu"
override val overdue_state: String = "Myöhästyneet"
override val paid_state: String = "Maksettu"
override val password: String = "Salasana"
override val password_blank_error: String = "Salasana vaaditaan."
override val password_mismatch_error: String = "Salasanat eivät täsmää."
override val pay: String = "maksa"
override val payment_instructions_label: String = "Maksuohjeet"
override val payment_reminders: String = "Maksumuistutukset"
override val phone_number_fields: String = "Puhelinnumero"
override val phone_number_hint: String = "Puhelinnumero"
override val postcode_hint: String = "Postinumero"
override val preview: String = "Esikatselu"
override val preview_menu_hint: String = "Esikatseluvalikko"
override val price: String = "Hinta"
override val privacy_policy: String = "Tietosuojakäytäntö"
override val profile: String = "Profiili"
override val profitable_client: String = "Kannattavin asiakkaasi on..."
override val profits_report: String = "Voitot"
override val promo_content: String = "Myynninedistämissisältö"
override val quantity_hint: String = "Määrä"
override val quantity_label_hint: String = "Määrä Tarra"
override val query_info: String = "Queryn avulla voit kirjoittaa mukautettuja laskelmia, joissa viitataan sekä eriin että muihin verosääntöihin syötteinä. Lisää @-symboli ja nimikkeen tai veron kuvaus linkittääksesi toiseen nimikkeeseen +, -, *, /, % - matemaattiset operaattorit."
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} on vastaanotettu asiakkaasi toimesta." }
override val received_state: String = "Vastaanotettu"
override val registration_code_fields: String = "Rekisteröintikoodi"
override val reminder: String = "Muistutus"
override val reports: String = "Raportit"
override val reports_not_available: String = "Raportteja ei ole saatavilla, palaa myöhemmin."
override val reveal_password: String = "Paljasta salasana"
override val revenue: String = "tulot"
override val sales_tax: String = "ALV"
override val sales_tax_number: String = "ALV-numero"
override val save: String = "Tallenna"
override val scan_document: String = "Skannaa asiakirja"
override val search: String = "Etsi"
override val select: String = "Valitse"
override val select_icon: String = "Valitse kuvake"
override val select_signature: String = "Valitse allekirjoitus"
override val select_template: String = "Valitse malli"
override val send: String = "Lähetä"
override val sent_state: String = "Lähetetty"
override val setup_sender: String = "Aseta lähettäjä"
override val share_option: String = "Jaa"
override val ship_to: String = "Laiva osoitteeseen"
override val sign_in: String = "Kirjaudu sisään"
override val sign_in_apple: String = "Kirjaudu sisään Applen kanssa"
override val sign_in_email: String = "Kirjaudu sisään sähköpostilla"
override val sign_in_facebook: String = "Kirjaudu sisään Facebookilla"
override val sign_in_google: String = "Kirjaudu sisään Googlen avulla"
override val sign_up: String = "Rekisteröidy"
override val sign_up_label: String = "Eikö sinulla ole tiliä?"
override val sign_up_link_label: String = "Rekisteröidy!"
override val signatures_option: String = "Allekirjoitukset"
override val skip: String = "Ohita"
override val sold_items_report: String = "Myydyt Kohteet"
override val sort_by: String = "Lajittelu:"
override val status_update: String = "Asiakirjan tilapäivitys"
override val sub_annual: String = "vuotuinen"
override val sub_annual_price: Formattable = Formattable { (hinta) -> "${hinta}/vuosi" }
override val sub_backups: String = "Pilvivarmistukset"
override val sub_insights: String = "Insights"
override val sub_month: String = "Kuukausittain"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/kk" }
override val sub_notifications: String = "Ilmoitukset"
override val sub_popup_message: String = "Tilaukset ovat tulossa pian. Käy katsomassa täältä hetken kuluttua."
override val sub_support: String = "Tuki"
override val sub_templates: String = "Mallit"
override val subscribe: String = "Tilaa"
override val subscription_header: String = "Kasvata liiketoimintaasi:"
override val subscription_option: String = "Tilaus"
override val subscriptions_load_fail: String = "Valitettavasti emme voi tällä hetkellä hakea tilauksiasi. Yritä myöhemmin uudelleen tai ota yhteyttä tukitiimiimme."
override val subtotal: Formattable = Formattable { (sum) -> "Välisumma: ${sum}" }
override val subtotal_label: String = "Yhteensä"
override val sum: String = "Sum"
override val support_option: String = "Tuki"
override val switch_reports: String = "Vaihda Raportti"
override val tax: String = "Vero"
override val tax_deleted_message: String = "Vero poistettu"
override val tax_description_warning: String = "Jos tätä käytetään, tämä näkyy laskussa"
override val tax_input_flat: String = "Litteä"
override val tax_input_percent: String = "Prosenttia"
override val tax_number_fields: String = "Veronumero"
override val tax_number_hint: String = "Veronumero"
override val tax_query_info_description: String = "Kyselyn tiedot"
override val tax_report: String = "Vero"
override val tax_rules_option: String = "Verosäännöt"
override val taxed_clients_report: String = "Verotetut asiakkaat"
override val template: String = "Malli"
override val template_selector_info: String = "Vaihda oletusmallia pyyhkäisemällä"
override val template_unlock_promo: String = "Pääset käyttämään enemmän malleja Premiumin avulla"
override val templates_option: String = "Mallit"
override val terms_of_service: String = "Käyttöehdot"
override val thank_you: String = "Kiitos"
override val to: String = "osoitteeseen"
override val total: Formattable = Formattable { (sum) -> "Yhteensä: ${sum}" }
override val total_label: String = "Yhteensä"
override val undo_action: String = "Kumoa"
override val unit: String = "Yksikkö"
override val unit_cost_hint: String = "Yksikkökustannukset"
override val unknown_client: String = "Tuntematon Asiakas"
override val unlock: String = "Avaa"
override val unnamed_item: String = "Nimetön kohde"
override val upgrade: String = "Päivitys"
override val user_collision_error: String = "Tili on jo olemassa."
override val valid_until: String = "Viimeinen voimassaolopäivä"
override val warning: String = "Varoitus!"
override val yes: String = "kyllä"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Hyväksy ${termsOfService} ja ${privacyPolicy}" },
	"account" to "tili",
	"accounting" to "kirjanpito",
	"accruals" to "siirtovelat",
	"action_code_error" to "Virheellinen koodi.",
	"add_client_action" to "Lisää asiakas",
	"add_customer" to "Lisää asiakas",
	"add_expense" to "Lisää kuluja",
	"add_fields_label" to "Lisää:",
	"add_invoice_action" to "Luo",
	"add_item_action" to "Lisää tuote",
	"add_item_button" to "Lisää tuote",
	"add_signature_action" to "Lisää allekirjoitus",
	"add_tax_action" to "Lisää vero",
	"address_fields" to "Osoite",
	"address_line_hint" to "Osoiterivi",
	"advanced" to "Edistynyt",
	"agree_marketing" to "Hyväksyn suoramarkkinointiviestinnän vastaanottamisen",
	"all_notifications" to "Kaikki ilmoitukset",
	"amortization" to "poistot",
	"amount" to "Määrä",
	"asset" to "omaisuuserä",
	"attach_file" to "Liitä tiedosto",
	"attachment" to "Liite",
	"auth_error" to "Toimenpide epäonnistui, yritä uudelleen.",
	"available_for_paid" to "Pilvisynkronointi on käytettävissä vain maksullisessa tilauksessa.",
	"backups_option" to "Varmuuskopiot",
	"balance" to "saldo",
	"bank_account" to "Pankkitili",
	"bill_number" to "Laskun numero",
	"cancel" to "Peruuta",
	"cancel_trial" to "Peruuta milloin tahansa",
	"capital" to "pääoma",
	"cash" to "käteinen",
	"categories" to "Kategoriat",
	"category" to "Luokka",
	"category_add" to "Lisää luokka",
	"category_delete" to "Poista luokka",
	"category_name" to "Kategorian nimi",
	"change_language" to Formattable { (language) -> "Laskun kieli: ${language}" },
	"change_language_label" to "Laskun Kieli",
	"change_template" to "Muuta mallia",
	"city_hint" to "Kaupunki",
	"clear" to "Kirkas",
	"client" to "Asiakas",
	"client_actions" to "Asiakkaan toimet",
	"client_deleted_message" to "Asiakas poistettu",
	"clients_error_required_fields" to "Vähintään yksi arvo vaaditaan.",
	"clients_option" to "Asiakkaat",
	"close" to "Sulje",
	"cloud_sync" to "Pilvi-synkronointi",
	"coming_soon" to "Tulossa pian!",
	"company_logo" to "Yrityksen logo",
	"company_name_hint" to "Yritys",
	"confirm_password_label" to "Vahvista salasana",
	"confirmation_title" to "Oletko varma?",
	"convert_to_invoice" to "Muunna laskuksi",
	"country_hint" to "Maa",
	"create_client" to "Luo asiakas",
	"create_company" to "Luo yritys",
	"create_document" to "Luo asiakirja",
	"create_estimate" to "Arvio",
	"create_invoice" to "Lasku",
	"create_item" to "Luo kohde",
	"create_new_client" to "Luo uusi asiakas",
	"create_new_item" to "Luo uusi kohde",
	"create_new_tax" to "Luo uusi vero",
	"create_signature" to "Allekirjoitus",
	"created" to "Luotu",
	"credit" to "luotto",
	"customer" to "Asiakas",
	"date" to "Päivämäärä",
	"debit" to "pankki",
	"debt" to "velka",
	"default_currency" to Formattable { (currency) -> "Oletusvaluutta: ${currency}" },
	"default_currency_label" to "Oletusvaluutta",
	"delete" to "Poista",
	"delete_account" to "Poista Tili",
	"delete_confirmation_message" to "Kaikki tietosi menetetään.",
	"delete_item_title" to "Poista kohde?",
	"delete_signature" to "Poista allekirjoitus",
	"depreciation" to "poistot",
	"description" to "Kuvaus",
	"description_hint" to "Kuvaus",
	"detailed_report" to "Yksityiskohtainen Raportti",
	"disabled" to "Vammaiset",
	"discount" to "Alennus",
	"discount_hint" to "Alennus",
	"display_af" to "Afrikaans",
	"display_am" to "Amharic",
	"display_ar" to "Arabia",
	"display_as" to "Assamilainen",
	"display_az" to "Azerbaidžan",
	"display_be" to "Valko-Venäjän",
	"display_bg" to "Bulgarian",
	"display_bn" to "Bengali",
	"display_bs" to "Bosnian",
	"display_ca" to "Katalaani",
	"display_cs" to "Tšekki",
	"display_da" to "Tanskalainen",
	"display_de" to "Saksan",
	"display_el" to "Kreikkalainen",
	"display_en" to "Englanti",
	"display_es" to "Espanjan",
	"display_es_MX" to "espanja (Meksiko)",
	"display_et" to "Viron",
	"display_eu" to "Baskimaan",
	"display_fa" to "Persialainen",
	"display_fi" to "Suomalainen",
	"display_fil" to "Filippiiniläinen",
	"display_fr" to "Ranskan",
	"display_fr_CA" to "Kanadan ranska",
	"display_gl" to "Galician",
	"display_gu" to "Gujarati",
	"display_he" to "Heprea",
	"display_hi" to "Hindi",
	"display_hr" to "Kroatian",
	"display_hu" to "Unkarilainen",
	"display_hy" to "Armenian",
	"display_id" to "Indonesialainen",
	"display_is" to "Islantilainen",
	"display_it" to "Italian",
	"display_ja" to "Japanilainen",
	"display_ka" to "Georgian",
	"display_kk" to "Kazakstan",
	"display_km" to "Keski-khmeri",
	"display_kn" to "Kannada",
	"display_ko" to "Korean",
	"display_ky" to "Kirgisia",
	"display_lo" to "Lao",
	"display_lt" to "Liettuan",
	"display_lv" to "Latvian",
	"display_mk" to "Makedonia",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongolian",
	"display_mr" to "Marathi",
	"display_ms" to "Malaiji",
	"display_my" to "Burman",
	"display_nb" to "Norjalainen Bokmål",
	"display_ne" to "Nepali",
	"display_nl" to "Hollantilainen",
	"display_or" to "Oriya",
	"display_pa" to "Punjabi",
	"display_pl" to "Puolan",
	"display_pt" to "Portugalin",
	"display_pt_BR" to "portugali (Brasilia)",
	"display_pt_PT" to "Euroopan portugali",
	"display_ro" to "Romanian",
	"display_ru" to "Venäläinen",
	"display_si" to "Sinhala",
	"display_sk" to "Slovakian",
	"display_sl" to "Slovenian",
	"display_sq" to "Albanian",
	"display_sr" to "Serbian",
	"display_sv" to "Ruotsalainen",
	"display_sw" to "Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Turkkilainen",
	"display_uk" to "Ukrainan",
	"display_ur" to "Urdu",
	"display_uz" to "Uzbekistan",
	"display_vi" to "Vietnamilainen",
	"display_zh_Hant_TW" to "Kiina (Taiwan, Perinteinen)",
	"display_zu" to "Zulu",
	"document_estimate" to "Arvio",
	"document_invoice" to "Lasku",
	"document_quote" to "Hintatarjous",
	"done" to "Valmis",
	"download_app" to "Lataa sovellus",
	"download_invoice" to "Lataa Lasku",
	"download_pdf" to "Lataa PDF",
	"draw_signature" to "Piirrä allekirjoitus",
	"due" to Formattable { (date) -> "Eräpäivä ${date}" },
	"due_date" to "Eräpäivä",
	"due_day" to "päivässä",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} on erääntynyt ${dueWhen}!" },
	"due_today" to "tänään",
	"due_week" to "viikossa",
	"duplicate" to "Duplikaatti",
	"duration_day" to "päivä",
	"duration_month" to "kuukausi",
	"duration_week" to "viikko",
	"duration_year" to "vuosi",
	"email" to "Sähköposti",
	"email_blank_error" to "Sähköposti vaaditaan.",
	"email_error" to "Virheellinen sähköpostiosoite.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Kiitos liiketoiminnastanne. Jos haluat nähdä koko ${documentType}:n, klikkaa tämän kirjeen lopussa olevaa painiketta. Huomaa, että se on voimassa ${dueDate} asti." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Olet saanut uuden asiakirjan: ${documentType} ${invoiceNumber}!" },
	"email_invoice_cta" to Formattable { (documentType) -> "NÄYTÄ ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Hyvä ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Kiitos liiketoiminnastanne. Jos haluat nähdä koko ${documentType}:n, klikkaa tämän kirjeen lopussa olevaa painiketta. Maksakaa loppuerä ${dueDate} mennessä." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Olet saanut uuden asiakirjan: ${documentType} ${invoiceNumber}!" },
	"email_reset_success_message" to "Sähköposti salasanan palauttamiseksi on lähetetty, tarkista saapuneet sähköpostiviestit.",
	"empty_clients_list_message" to "Sinulla ei ole asiakkaita seurattavaksi. Lisää uusi asiakas.",
	"empty_estimate_list_message" to "Luo ensimmäinen arvio",
	"empty_expenses" to "Sinulla ei ole seurattavia kuluja. Lisää uusi kulu.",
	"empty_invoice_list_message" to "Luo ensimmäinen lasku",
	"empty_items_list_message" to "Sinulla ei ole kohteita, lisää uusi kohde!",
	"empty_signatures_list_message" to "Allekirjoituksia ei löytynyt. Luo sellainen!",
	"empty_taxes_list_message" to "Sinulla ei ole verosääntöjä. Lisää uusi verosääntö.",
	"empty_templates_list_message" to "Mallit eivät ole käytettävissä.",
	"enabled" to "Käytössä",
	"entry" to "kirjanpitokirjaus",
	"equity" to "oma pääoma",
	"estimate_number" to "Arvio #",
	"estimates" to "Arviot",
	"estimates_report" to "Arviot",
	"expense_bank_fees" to "Pankkimaksut",
	"expense_benefits" to "Edut",
	"expense_communication" to "Viestintä",
	"expense_debt" to "Velka",
	"expense_deleted" to "Menot poistettu",
	"expense_depreciation" to "Poistot",
	"expense_gifts" to "Lahjat",
	"expense_insurance" to "Vakuutus",
	"expense_inventory" to "inventaario",
	"expense_legal" to "Oikeudellinen",
	"expense_maintenance" to "Huolto",
	"expense_marketing" to "Markkinointi",
	"expense_meals" to "Ateriat",
	"expense_office_supplies" to "Toimistotarvikkeet",
	"expense_rent" to "Vuokraa",
	"expense_salaries" to "Palkat",
	"expense_shopping" to "Ostokset",
	"expense_software" to "Ohjelmisto",
	"expense_status_available" to "Saatavilla",
	"expense_status_processing" to "Käsittely",
	"expense_tax" to "Vero",
	"expense_training" to "Koulutus",
	"expense_transport" to "Liikenne",
	"expense_travel" to "matka",
	"expense_utility" to "Apuohjelma",
	"expenses" to "Kulut",
	"feedback_option" to "Palaute",
	"filter" to "Suodatin",
	"first_name_hint" to "Etunimi",
	"forgot_password" to "Nollaa salasana",
	"forgot_password_label" to "Unohditko salasanan",
	"from" to "osoitteesta",
	"general_error" to "Tapahtui virhe, yritä myöhemmin uudelleen.",
	"general_ledger" to "pääkirja",
	"go_back_label" to "Palaa takaisin",
	"group_by" to "Ryhmän nimi:",
	"image_view" to "Kuva",
	"install" to "Asenna",
	"invalid_credentials_error" to "Virheelliset valtakirjat.",
	"invalid_email_error" to "Virheellinen sähköpostiosoite.",
	"invalid_user_error" to "Virheelliset käyttäjätunnukset.",
	"invoice_deleted_message" to "Lasku poistettu",
	"invoice_menu" to "Laskun valikko",
	"invoice_number" to "Lasku #",
	"invoices" to "Laskut",
	"items_option" to "kohteet",
	"keep" to "Pidä",
	"language_selector" to Formattable { (valittu) -> "Kieli ${valittu}" },
	"last_name_hint" to "Sukunimi",
	"ledger" to "pääkirja",
	"legal" to "Oikeudellinen",
	"legal_error" to "On hyväksyttävä käyttöehdot ja tietosuojakäytäntö",
	"liabilities" to "velat",
	"loading" to "Ladataan",
	"login_required_error" to "Sisäänkirjautuminen vaaditaan.",
	"logo_view" to "Logo",
	"logout" to "Kirjaudu ulos",
	"logout_prompt" to "Tietojasi ei synkronoida pilven kanssa. Poista laskuja, arvioita, asiakkaita ja muita tietoja uloskirjautumisen yhteydessä?",
	"main_screen" to "Päänäyttö",
	"mark_paid" to "Merkitse maksetuksi",
	"message" to "Viesti",
	"missing_sender_msg" to "Asiakirja, jossa ei ole lähettäjän tietoja, saattaa olla virheellinen.",
	"missing_sender_title" to "Lähettäjän tiedot puuttuvat",
	"month_april" to "Huhtikuu",
	"month_april_short" to "Huhtikuu",
	"month_august" to "Elokuu",
	"month_august_short" to "Elo.",
	"month_december" to "Joulukuu",
	"month_december_short" to "Jou.",
	"month_february" to "Helmikuu",
	"month_february_short" to "Hel.",
	"month_january" to "Tammikuu",
	"month_january_short" to "Tam.",
	"month_july" to "Heinäkuu",
	"month_july_short" to "Heinäkuu",
	"month_june" to "Kesäkuu",
	"month_june_short" to "Kesäkuu",
	"month_march" to "Maaliskuu",
	"month_march_short" to "Maaliskuu",
	"month_may" to "Toukokuu",
	"month_may_short" to "Toukokuu",
	"month_november" to "Marraskuu",
	"month_november_short" to "Mar.",
	"month_october" to "Lokakuu",
	"month_october_short" to "Lok.",
	"month_september" to "Syyskuu",
	"month_september_short" to "Syys.",
	"multifactor_error" to "Epäonnistui tarkistuksessa.",
	"new" to "Uusi",
	"news" to "Uutiset",
	"next" to "Seuraava",
	"no" to "ei",
	"no_data_available" to "Tietoja ei ole saatavilla",
	"no_search_results" to "Valitettavasti emme löytäneet tuloksia.",
	"none" to "Ei ole",
	"note_fields" to "Huomautukset",
	"note_hint" to "Huomautus",
	"notifications_option" to "ilmoitukset",
	"ok" to "OK",
	"onboarding" to "Käyttöönotto",
	"onboarding_aboutus_message" to "DingVoicen olennaiset osat ovat ikuisesti ilmaisia. Tehtävämme on auttaa sinua kasvattamaan liiketoimintaasi, auta meitä parantamaan sitä jakamalla palautettasi.",
	"onboarding_aboutus_title" to "Olemme täällä auttamassa",
	"onboarding_paid_1" to "Pääsy ammattimaisen näköisiin malleihin",
	"onboarding_paid_2" to "Tutustu tarkemmin talouteesi",
	"onboarding_paid_3" to "Ongelmat ratkaistaan nopeasti",
	"onboarding_paid_4" to "Säilytä asiakirjasi turvassa",
	"onboarding_paid_5" to "Pysy ajan tasalla laskujen tilamuutoksista",
	"onboarding_paid_title" to "Kasva vielä nopeammin...",
	"onboarding_paywall_message" to "Nauti kaikista premium-ominaisuuksista nyt parhaaseen hintaan.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Vain ${currencySymbol}${introAmount} ${introDuration} ${introPeriod} ja sitten ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (valuuttasymboli, summa, kesto) -> "Vain ${valuuttasymboli}${summa} / ${kesto}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Ilmainen ${trialDuration} ${trialPeriod} ajan ja sitten vain ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Ilmainen ${trialDuration} ${trialPeriod} ajan, sitten ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod}ajan ja sitten vain ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automaattisesti uusiutuva. Peruutus milloin tahansa.",
	"onboarding_paywall_restore" to "Palauta osto",
	"onboarding_paywall_restore_failure" to "Ostosten palauttaminen epäonnistui, yritä myöhemmin uudelleen tai ota yhteyttä asiakaspalveluun.",
	"onboarding_paywall_restore_success" to "Palautus onnistui!",
	"onboarding_paywall_title" to "Saavuta suuruutta!",
	"onboarding_welcome_message" to "Täydellinen työkalupaketti laskujen ja arvioiden hallintaan.",
	"onboarding_welcome_title" to "Tervetuloa DingVoiceen",
	"other_state" to "Muu",
	"overdue_state" to "Myöhästyneet",
	"paid_state" to "Maksettu",
	"password" to "Salasana",
	"password_blank_error" to "Salasana vaaditaan.",
	"password_mismatch_error" to "Salasanat eivät täsmää.",
	"pay" to "maksa",
	"payment_instructions_label" to "Maksuohjeet",
	"payment_reminders" to "Maksumuistutukset",
	"phone_number_fields" to "Puhelinnumero",
	"phone_number_hint" to "Puhelinnumero",
	"postcode_hint" to "Postinumero",
	"preview" to "Esikatselu",
	"preview_menu_hint" to "Esikatseluvalikko",
	"price" to "Hinta",
	"privacy_policy" to "Tietosuojakäytäntö",
	"profile" to "Profiili",
	"profitable_client" to "Kannattavin asiakkaasi on...",
	"profits_report" to "Voitot",
	"promo_content" to "Myynninedistämissisältö",
	"quantity_hint" to "Määrä",
	"quantity_label_hint" to "Määrä Tarra",
	"query_info" to "Queryn avulla voit kirjoittaa mukautettuja laskelmia, joissa viitataan sekä eriin että muihin verosääntöihin syötteinä. Lisää @-symboli ja nimikkeen tai veron kuvaus linkittääksesi toiseen nimikkeeseen +, -, *, /, % - matemaattiset operaattorit.",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} on vastaanotettu asiakkaasi toimesta." },
	"received_state" to "Vastaanotettu",
	"registration_code_fields" to "Rekisteröintikoodi",
	"reminder" to "Muistutus",
	"reports" to "Raportit",
	"reports_not_available" to "Raportteja ei ole saatavilla, palaa myöhemmin.",
	"reveal_password" to "Paljasta salasana",
	"revenue" to "tulot",
	"sales_tax" to "ALV",
	"sales_tax_number" to "ALV-numero",
	"save" to "Tallenna",
	"scan_document" to "Skannaa asiakirja",
	"search" to "Etsi",
	"select" to "Valitse",
	"select_icon" to "Valitse kuvake",
	"select_signature" to "Valitse allekirjoitus",
	"select_template" to "Valitse malli",
	"send" to "Lähetä",
	"sent_state" to "Lähetetty",
	"setup_sender" to "Aseta lähettäjä",
	"share_option" to "Jaa",
	"ship_to" to "Laiva osoitteeseen",
	"sign_in" to "Kirjaudu sisään",
	"sign_in_apple" to "Kirjaudu sisään Applen kanssa",
	"sign_in_email" to "Kirjaudu sisään sähköpostilla",
	"sign_in_facebook" to "Kirjaudu sisään Facebookilla",
	"sign_in_google" to "Kirjaudu sisään Googlen avulla",
	"sign_up" to "Rekisteröidy",
	"sign_up_label" to "Eikö sinulla ole tiliä?",
	"sign_up_link_label" to "Rekisteröidy!",
	"signatures_option" to "Allekirjoitukset",
	"skip" to "Ohita",
	"sold_items_report" to "Myydyt Kohteet",
	"sort_by" to "Lajittelu:",
	"status_update" to "Asiakirjan tilapäivitys",
	"sub_annual" to "vuotuinen",
	"sub_annual_price" to Formattable { (hinta) -> "${hinta}/vuosi" },
	"sub_backups" to "Pilvivarmistukset",
	"sub_insights" to "Insights",
	"sub_month" to "Kuukausittain",
	"sub_monthly_price" to Formattable { (price) -> "${price}/kk" },
	"sub_notifications" to "Ilmoitukset",
	"sub_popup_message" to "Tilaukset ovat tulossa pian. Käy katsomassa täältä hetken kuluttua.",
	"sub_support" to "Tuki",
	"sub_templates" to "Mallit",
	"subscribe" to "Tilaa",
	"subscription_header" to "Kasvata liiketoimintaasi:",
	"subscription_option" to "Tilaus",
	"subscriptions_load_fail" to "Valitettavasti emme voi tällä hetkellä hakea tilauksiasi. Yritä myöhemmin uudelleen tai ota yhteyttä tukitiimiimme.",
	"subtotal" to Formattable { (sum) -> "Välisumma: ${sum}" },
	"subtotal_label" to "Yhteensä",
	"sum" to "Sum",
	"support_option" to "Tuki",
	"switch_reports" to "Vaihda Raportti",
	"tax" to "Vero",
	"tax_deleted_message" to "Vero poistettu",
	"tax_description_warning" to "Jos tätä käytetään, tämä näkyy laskussa",
	"tax_input_flat" to "Litteä",
	"tax_input_percent" to "Prosenttia",
	"tax_number_fields" to "Veronumero",
	"tax_number_hint" to "Veronumero",
	"tax_query_info_description" to "Kyselyn tiedot",
	"tax_report" to "Vero",
	"tax_rules_option" to "Verosäännöt",
	"taxed_clients_report" to "Verotetut asiakkaat",
	"template" to "Malli",
	"template_selector_info" to "Vaihda oletusmallia pyyhkäisemällä",
	"template_unlock_promo" to "Pääset käyttämään enemmän malleja Premiumin avulla",
	"templates_option" to "Mallit",
	"terms_of_service" to "Käyttöehdot",
	"thank_you" to "Kiitos",
	"to" to "osoitteeseen",
	"total" to Formattable { (sum) -> "Yhteensä: ${sum}" },
	"total_label" to "Yhteensä",
	"undo_action" to "Kumoa",
	"unit" to "Yksikkö",
	"unit_cost_hint" to "Yksikkökustannukset",
	"unknown_client" to "Tuntematon Asiakas",
	"unlock" to "Avaa",
	"unnamed_item" to "Nimetön kohde",
	"upgrade" to "Päivitys",
	"user_collision_error" to "Tili on jo olemassa.",
	"valid_until" to "Viimeinen voimassaolopäivä",
	"warning" to "Varoitus!",
	"yes" to "kyllä",
    )
    
}