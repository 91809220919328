// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Vi  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Chấp nhận ${termsOfService} và ${privacyPolicy}" }
override val account: String = "tài khoản"
override val accounting: String = "kế toán"
override val accruals: String = "chi phí dồn tích"
override val action_code_error: String = "Mã không hợp lệ."
override val add_client_action: String = "Thêm khách hàng"
override val add_customer: String = "Thêm khách hàng"
override val add_expense: String = "Thêm chi phí"
override val add_fields_label: String = "Thêm:"
override val add_invoice_action: String = "Tạo"
override val add_item_action: String = "Thêm mục"
override val add_item_button: String = "Thêm mục"
override val add_signature_action: String = "Thêm chữ ký"
override val add_tax_action: String = "Thêm thuế"
override val address_fields: String = "Địa chỉ"
override val address_line_hint: String = "Đường Địa chỉ"
override val advanced: String = "Tiên tiến"
override val agree_marketing: String = "Tôi đồng ý nhận Truyền thông Tiếp thị Trực tiếp"
override val all_notifications: String = "Tất cả Thông báo"
override val amortization: String = "khấu hao tài sản cố định vô hình"
override val amount: String = "Lượng"
override val asset: String = "tài sản"
override val attach_file: String = "Đính kèm tập tin"
override val attachment: String = "Tập tin đính kèm"
override val auth_error: String = "Hành động thất bại, hãy thử lại."
override val available_for_paid: String = "Đồng bộ hóa đám mây chỉ khả dụng theo đăng ký trả phí."
override val backups_option: String = "Sao lưu"
override val balance: String = "số dư"
override val bank_account: String = "Tài khoản ngân hàng"
override val bill_number: String = "số hóa đơn"
override val cancel: String = "Hủy"
override val cancel_trial: String = "Hủy tùy thích"
override val capital: String = "vốn"
override val cash: String = "tiền mặt"
override val categories: String = "Thể loại"
override val category: String = "Danh mục"
override val category_add: String = "thêm thể loại"
override val category_delete: String = "Xóa danh mục"
override val category_name: String = "Tên danh mục"
override val change_language: Formattable = Formattable { (language) -> "Ngôn ngữ hóa đơn: ${language}" }
override val change_language_label: String = "Ngôn ngữ hóa đơn"
override val change_template: String = "Thay đổi mẫu"
override val city_hint: String = "Thành phố"
override val clear: String = "Trong"
override val client: String = "Khách hàng"
override val client_actions: String = "Hành động của khách hàng"
override val client_deleted_message: String = "Máy khách đã xóa"
override val clients_error_required_fields: String = "Ít nhất một giá trị là cần thiết."
override val clients_option: String = "Khách hàng"
override val close: String = "Đóng"
override val cloud_sync: String = "Đồng bộ đám mây"
override val coming_soon: String = "Đến sớm!"
override val company_logo: String = "Logo công ty"
override val company_name_hint: String = "Công ty"
override val confirm_password_label: String = "Xác nhận mật khẩu"
override val confirmation_title: String = "Bạn có chắc không?"
override val convert_to_invoice: String = "Chuyển đổi sang hóa đơn"
override val country_hint: String = "Quốc gia"
override val create_client: String = "Tạo Khách hàng"
override val create_company: String = "Tạo công ty"
override val create_document: String = "Tạo tài liệu"
override val create_estimate: String = "Ước tính"
override val create_invoice: String = "Hóa đơn"
override val create_item: String = "Tạo Mục"
override val create_new_client: String = "Tạo khách hàng mới"
override val create_new_item: String = "Tạo mục mới"
override val create_new_tax: String = "Tạo thuế mới"
override val create_signature: String = "Chữ ký"
override val created: String = "Tạo"
override val credit: String = "ghi có"
override val customer: String = "Khách hàng"
override val date: String = "Ngày"
override val debit: String = "ghi nợ"
override val debt: String = "nợ"
override val default_currency: Formattable = Formattable { (currency) -> "Tiền tệ mặc định: ${currency}" }
override val default_currency_label: String = "Tiền tệ mặc định"
override val delete: String = "Xóa"
override val delete_account: String = "Xóa tài khoản"
override val delete_confirmation_message: String = "Tất cả dữ liệu của bạn sẽ bị mất."
override val delete_item_title: String = "Xóa mục?"
override val delete_signature: String = "Xóa chữ ký"
override val depreciation: String = "khấu hao tài sản cố định hữu hình"
override val description: String = "Sự miêu tả"
override val description_hint: String = "Sự miêu tả"
override val detailed_report: String = "Báo cáo chi tiết"
override val disabled: String = "Tàn tật"
override val discount: String = "Chiết khấu"
override val discount_hint: String = "Sự bớt"
override val display_af: String = "Người châu Phi"
override val display_am: String = "Amharic"
override val display_ar: String = "tiếng Ả Rập"
override val display_as: String = "Tiếng Assam"
override val display_az: String = "Azerbaijan"
override val display_be: String = "Người Belarus"
override val display_bg: String = "Người Bungari"
override val display_bn: String = "Tiếng Bengali"
override val display_bs: String = "Người Bosnia"
override val display_ca: String = "Catalan"
override val display_cs: String = "Tiếng Séc"
override val display_da: String = "người Đan Mạch"
override val display_de: String = "tiếng Đức"
override val display_el: String = "người Hy Lạp"
override val display_en: String = "tiếng Anh"
override val display_es: String = "người Tây Ban Nha"
override val display_es_MX: String = "Tiếng Tây Ban Nha (Mexico)"
override val display_et: String = "Người Estonia"
override val display_eu: String = "Xứ Basque"
override val display_fa: String = "Tiếng ba tư"
override val display_fi: String = "Phần lan"
override val display_fil: String = "Người Phi Luật Tân"
override val display_fr: String = "người Pháp"
override val display_fr_CA: String = "Canada tiếng Pháp"
override val display_gl: String = "Galicia"
override val display_gu: String = "Gujarati"
override val display_he: String = "Tiếng Do Thái"
override val display_hi: String = "Không."
override val display_hr: String = "Người Croatia"
override val display_hu: String = "người Hungary"
override val display_hy: String = "Tiếng Armenia"
override val display_id: String = "Người Indonesia"
override val display_is: String = "Tiếng Iceland"
override val display_it: String = "người Ý"
override val display_ja: String = "tiếng Nhật"
override val display_ka: String = "Người Gruzia"
override val display_kk: String = "Tiếng Kazakh"
override val display_km: String = "Khơ me miền Trung"
override val display_kn: String = "Tiếng Kannada"
override val display_ko: String = "Hàn Quốc"
override val display_ky: String = "Kirghiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Tiếng Lithuania"
override val display_lv: String = "Người Latvia"
override val display_mk: String = "Người Macedonian"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Tiếng Mông Cổ"
override val display_mr: String = "Marathi"
override val display_ms: String = "Tiếng Mã Lai"
override val display_my: String = "Miến Điện"
override val display_nb: String = "Bokmål của Na Uy"
override val display_ne: String = "Tiếng Nepal"
override val display_nl: String = "Tiếng hà lan"
override val display_or: String = "Oriya"
override val display_pa: String = "Tiếng Punjabi"
override val display_pl: String = "Đánh bóng"
override val display_pt: String = "Người Bồ Đào Nha"
override val display_pt_BR: String = "Tiếng Bồ Đào Nha (Brazil)"
override val display_pt_PT: String = "Tiếng Bồ Đào Nha Châu Âu"
override val display_ro: String = "Tiếng Rumani"
override val display_ru: String = "tiếng Nga"
override val display_si: String = "Sinhala"
override val display_sk: String = "Tiếng Slovak"
override val display_sl: String = "Người Slovenia"
override val display_sq: String = "Người Albanian"
override val display_sr: String = "Tiếng Serbia"
override val display_sv: String = "Tiếng Thụy Điển"
override val display_sw: String = "Tiếng Swahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Tiếng Telugu"
override val display_th: String = "Thái lan"
override val display_tr: String = "Thổ nhĩ kỳ"
override val display_uk: String = "Người Ukraina"
override val display_ur: String = "Tiếng Urdu"
override val display_uz: String = "Tiếng Uzbek"
override val display_vi: String = "Tiếng Việt"
override val display_zh_Hant_TW: String = "Tiếng Trung (Đài Loan, Phồn thể)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Ước tính"
override val document_invoice: String = "Hóa đơn"
override val document_quote: String = "Trích dẫn"
override val done: String = "Làm xong"
override val download_app: String = "Tải xuống ứng dụng"
override val download_invoice: String = "Tải xuống hóa đơn"
override val download_pdf: String = "tải PDF"
override val draw_signature: String = "Vẽ chữ ký"
override val due: Formattable = Formattable { (date) -> "Đến hạn ${date}" }
override val due_date: String = "Ngày đến hạn"
override val due_day: String = "Vào một ngày"
override val due_label: String = "Do"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} đến hạn ${dueWhen}!" }
override val due_today: String = "Hôm nay"
override val due_week: String = "trong một tuần"
override val duplicate: String = "Bản sao"
override val duration_day: String = "ngày"
override val duration_month: String = "tháng"
override val duration_week: String = "tuần"
override val duration_year: String = "năm"
override val email: String = "Email"
override val email_blank_error: String = "Cần gửi email."
override val email_error: String = "Địa chỉ email không hợp lệ."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Cảm ơn vì đã giao dịch. Để xem toàn bộ ${documentType}, hãy nhấp vào nút ở cuối lá thư này. Xin lưu ý, tài liệu này có hiệu lực đến ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Bạn đã nhận được ${documentType} ${invoiceNumber} mới!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "XEM ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Kính gửi ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Cảm ơn bạn cho doanh nghiệp của bạn. Để xem đầy đủ ${documentType}, hãy nhấp vào nút ở cuối bức thư này. Vui lòng thanh toán số dư cho đến ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Bạn đã nhận được ${documentType} ${invoiceNumber} mới!" }
override val email_reset_success_message: String = "Email để đặt lại mật khẩu đã gửi, vui lòng kiểm tra hộp thư đến của bạn."
override val empty_clients_list_message: String = "Bạn không có khách hàng để theo dõi. Thêm một khách hàng mới."
override val empty_estimate_list_message: String = "Tạo ước tính đầu tiên của bạn"
override val empty_expenses: String = "Bạn không có chi phí để theo dõi. Thêm một khoản chi phí mới."
override val empty_invoice_list_message: String = "Tạo hóa đơn đầu tiên của bạn"
override val empty_items_list_message: String = "Bạn không có mục, thêm một mục mới!"
override val empty_signatures_list_message: String = "Không tìm thấy chữ ký. Tạo một!"
override val empty_taxes_list_message: String = "Bạn không có quy định về thuế. Thêm một quy tắc thuế mới."
override val empty_templates_list_message: String = "Mẫu không sẵn dùng."
override val enabled: String = "Đã bật"
override val entry: String = "bút toán"
override val equity: String = "vốn chủ sở hữu"
override val estimate_number: String = "Số ước tính"
override val estimates: String = "Ước tính"
override val estimates_report: String = "Ước tính"
override val expense_bank_fees: String = "Phí ngân hàng"
override val expense_benefits: String = "Lợi ích"
override val expense_communication: String = "Giao tiếp"
override val expense_debt: String = "Nợ nần"
override val expense_deleted: String = "Đã xóa chi phí"
override val expense_depreciation: String = "Khấu hao tài sản cố định hữu hình"
override val expense_gifts: String = "Những món quà"
override val expense_insurance: String = "Bảo hiểm"
override val expense_inventory: String = "Hàng tồn kho"
override val expense_legal: String = "Hợp pháp"
override val expense_maintenance: String = "BẢO TRÌ"
override val expense_marketing: String = "Tiếp thị"
override val expense_meals: String = "Các bữa ăn"
override val expense_office_supplies: String = "Văn phòng phẩm"
override val expense_rent: String = "Thuê"
override val expense_salaries: String = "Lương"
override val expense_shopping: String = "Mua sắm"
override val expense_software: String = "Phần mềm"
override val expense_status_available: String = "Có sẵn"
override val expense_status_processing: String = "Xử lý"
override val expense_tax: String = "Thuế"
override val expense_training: String = "Đào tạo"
override val expense_transport: String = "Chuyên chở"
override val expense_travel: String = "Du lịch"
override val expense_utility: String = "Tính thiết thực"
override val expenses: String = "Chi phí"
override val feedback_option: String = "Phản hồi"
override val filter: String = "Bộ lọc"
override val first_name_hint: String = "Tên thánh"
override val forgot_password: String = "Đặt lại mật khẩu"
override val forgot_password_label: String = "Quên mật khẩu"
override val from: String = "Từ"
override val general_error: String = "Đã xảy ra lỗi, vui lòng thử lại sau."
override val general_ledger: String = "sổ cái chung"
override val go_back_label: String = "Quay lại"
override val group_by: String = "Nhóm theo:"
override val image_view: String = "Ảnh"
override val install: String = "Cài đặt"
override val invalid_credentials_error: String = "Chứng danh không hợp lệ."
override val invalid_email_error: String = "Email không hợp lệ."
override val invalid_user_error: String = "Chứng danh người dùng không hợp lệ."
override val invoice_deleted_message: String = "Hóa đơn đã xóa"
override val invoice_menu: String = "Menu hóa đơn"
override val invoice_number: String = "Hóa đơn #"
override val invoices: String = "Hoá đơn"
override val items_option: String = "Mục"
override val keep: String = "Giữ"
override val language_selector: Formattable = Formattable { (selected) -> "Ngôn ngữ ${selected}" }
override val last_name_hint: String = "Họ"
override val ledger: String = "sổ cái"
override val legal: String = "Hợp pháp"
override val legal_error: String = "Phải chấp nhận Điều khoản dịch vụ và chính sách bảo mật"
override val liabilities: String = "nợ phải trả"
override val loading: String = "Tải"
override val login_required_error: String = "Yêu cầu đăng nhập."
override val logo_view: String = "Logo"
override val logout: String = "Logout"
override val logout_prompt: String = "Dữ liệu của bạn không được đồng bộ hóa với đám mây. Xóa hóa đơn, ước tính, khách hàng và thông tin khác về logout?"
override val main_screen: String = "Màn hình chính"
override val mark_paid: String = "Đánh dấu là đã trả phí"
override val message: String = "Tin nhắn"
override val missing_sender_msg: String = "Tài liệu thiếu thông tin người gửi có thể không hợp lệ."
override val missing_sender_title: String = "Thiếu thông tin người gửi"
override val month_april: String = "Tháng tư"
override val month_april_short: String = "Tháng tư"
override val month_august: String = "Tháng tám"
override val month_august_short: String = "Th.8"
override val month_december: String = "Tháng 12"
override val month_december_short: String = "Th.12"
override val month_february: String = "tháng 2"
override val month_february_short: String = "Th.2"
override val month_january: String = "tháng Giêng"
override val month_january_short: String = "Th.1"
override val month_july: String = "Tháng bảy"
override val month_july_short: String = "Tháng bảy"
override val month_june: String = "Tháng sáu"
override val month_june_short: String = "Tháng sáu"
override val month_march: String = "Bước đều"
override val month_march_short: String = "Bước đều"
override val month_may: String = "Có thể"
override val month_may_short: String = "Có thể"
override val month_november: String = "Tháng mười một"
override val month_november_short: String = "Th.11"
override val month_october: String = "Tháng Mười"
override val month_october_short: String = "Th.10"
override val month_september: String = "Tháng 9"
override val month_september_short: String = "Th.9"
override val multifactor_error: String = "Không xác minh được."
override val new: String = "mới"
override val news: String = "Tin tức"
override val next: String = "Kế tiếp"
override val no: String = "không"
override val no_data_available: String = "Không có sẵn dữ liệu"
override val no_search_results: String = "Xin lỗi, chúng tôi không thể tìm thấy bất kỳ kết quả nào."
override val none: String = "Không có"
override val note_fields: String = "Ghi chú"
override val note_hint: String = "Ghi"
override val notifications_option: String = "Thông báo"
override val ok: String = "OK"
override val onboarding: String = "giới thiệu"
override val onboarding_aboutus_message: String = "Các phần thiết yếu của DingVoice mãi mãi miễn phí. Nhiệm vụ của chúng tôi là giúp bạn phát triển doanh nghiệp của mình, giúp chúng tôi cải thiện bằng cách chia sẻ phản hồi của bạn."
override val onboarding_aboutus_title: String = "Chúng tôi ở đây để giúp đỡ"
override val onboarding_paid_1: String = "Truy cập các mẫu tìm kiếm chuyên nghiệp"
override val onboarding_paid_2: String = "Nhìn sâu hơn vào tài chính của bạn"
override val onboarding_paid_3: String = "Giải quyết vấn đề nhanh chóng"
override val onboarding_paid_4: String = "Giữ tài liệu của bạn an toàn"
override val onboarding_paid_5: String = "Luôn cập nhật các thay đổi về trạng thái hóa đơn"
override val onboarding_paid_title: String = "Phát triển nhanh hơn nữa với..."
override val onboarding_paywall_message: String = "Tận hưởng tất cả các tính năng cao cấp ngay bây giờ với giá tốt nhất."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Chỉ ${currencySymbol}${introAmount} trong giai đoạn ${introDuration} ${introPeriod} và sau đó là ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Chỉ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Miễn phí trong giai đoạn ${trialDuration} ${trialPeriod} và sau đó chỉ ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Miễn phí cho${trialDuration}${trialPeriod} sau đó${currencySymbol}${introductoryAmount} vì${introductoryDuration}${introPeriod} và sau đó chỉ${currencySymbol}${amount} /${duration}" }
override val onboarding_paywall_renewable: String = "Tự động tái tạo. Hủy bỏ bất cứ lúc nào."
override val onboarding_paywall_restore: String = "Khôi phục mua hàng"
override val onboarding_paywall_restore_failure: String = "Khôi phục giao dịch mua không thành công, hãy thử lại sau hoặc liên hệ với bộ phận hỗ trợ."
override val onboarding_paywall_restore_success: String = "Khôi phục thành công!"
override val onboarding_paywall_title: String = "Đạt được sự vĩ đại!"
override val onboarding_welcome_message: String = "Một bộ công cụ hoàn chỉnh để quản lý hóa đơn và ước tính của bạn."
override val onboarding_welcome_title: String = "Chào mừng đến với DingVoice"
override val other_state: String = "Khác"
override val overdue_state: String = "Quá hạn"
override val paid_state: String = "Trả tiền"
override val password: String = "Mật khẩu"
override val password_blank_error: String = "Cần mật khẩu."
override val password_mismatch_error: String = "Mật khẩu không khớp."
override val pay: String = "thanh toán"
override val payment_instructions_label: String = "Hướng dẫn thanh toán"
override val payment_reminders: String = "Lời nhắc thanh toán"
override val phone_number_fields: String = "Số điện thoại"
override val phone_number_hint: String = "Số điện thoại"
override val postcode_hint: String = "Mã bưu chính"
override val preview: String = "Download"
override val preview_menu_hint: String = "Menu Xem trước"
override val price: String = "Giá"
override val privacy_policy: String = "Chính sách bảo mật"
override val profile: String = "Hồ sơ"
override val profitable_client: String = "Khách hàng có lợi nhất của bạn là ..."
override val profits_report: String = "Lợi nhuận"
override val promo_content: String = "Nội dung quảng cáo"
override val quantity_hint: String = "Số lượng"
override val quantity_label_hint: String = "Nhãn số lượng"
override val query_info: String = "Truy vấn cho phép bạn viết các tính toán tùy chỉnh đề cập đến cả mục và các quy tắc thuế khác làm đầu vào. Thêm biểu tượng @ và mô tả mục hoặc thuế để liên kết đến mục nhập khác +, -, *, /, % - toán học"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "Khách hàng của bạn đã nhận được ${documentType} ${documentNumber}." }
override val received_state: String = "Nhận"
override val registration_code_fields: String = "Mã đăng ký"
override val reminder: String = "Nhắc nhở"
override val reports: String = "Báo cáo"
override val reports_not_available: String = "Không có báo cáo nào, vui lòng quay lại sau."
override val reveal_password: String = "Tiết lộ mật khẩu"
override val revenue: String = "doanh thu"
override val sales_tax: String = "Thuế GTGT"
override val sales_tax_number: String = "Số thuế GTGT"
override val save: String = "Cứu"
override val scan_document: String = "Quét tài liệu"
override val search: String = "Tìm kiếm"
override val select: String = "Lựa chọn"
override val select_icon: String = "Chọn biểu tượng"
override val select_signature: String = "Chọn chữ ký"
override val select_template: String = "Chọn mẫu"
override val send: String = "Gửi"
override val sent_state: String = "Gửi"
override val setup_sender: String = "Thiết lập người gửi"
override val share_option: String = "Chia sẻ"
override val ship_to: String = "Tàu"
override val sign_in: String = "Đăng nhập"
override val sign_in_apple: String = "Đăng nhập với Apple"
override val sign_in_email: String = "Đăng nhập bằng email"
override val sign_in_facebook: String = "Đăng nhập bằng Facebook"
override val sign_in_google: String = "Đăng nhập với Google"
override val sign_up: String = "Đăng ký"
override val sign_up_label: String = "Không có tài khoản?"
override val sign_up_link_label: String = "Đăng ký!"
override val signatures_option: String = "Chữ ký"
override val skip: String = "Nhảy"
override val sold_items_report: String = "Các mặt hàng đã bán"
override val sort_by: String = "Sắp xếp theo:"
override val status_update: String = "Cập nhật trạng thái tài liệu"
override val sub_annual: String = "Hàng năm"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/năm" }
override val sub_backups: String = "Sao lưu đám mây"
override val sub_insights: String = "Thông tin chi tiết"
override val sub_month: String = "Hàng tháng"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/tháng" }
override val sub_notifications: String = "Thông báo"
override val sub_popup_message: String = "Đăng ký sẽ sớm ra mắt. Kiểm tra lại đây trong một thời gian ngắn."
override val sub_support: String = "Hỗ trợ"
override val sub_templates: String = "Mẫu"
override val subscribe: String = "Đăng ký"
override val subscription_header: String = "Phát triển doanh nghiệp của bạn với:"
override val subscription_option: String = "Đăng ký"
override val subscriptions_load_fail: String = "Rất tiếc, chúng tôi không thể truy xuất đăng ký của bạn vào lúc này. Vui lòng thử lại sau hoặc liên hệ với nhóm hỗ trợ của chúng tôi để được trợ giúp."
override val subtotal: Formattable = Formattable { (sum) -> "Tổng phụ: ${sum}" }
override val subtotal_label: String = "Tổng phụ"
override val sum: String = "Tổng"
override val support_option: String = "Hỗ trợ"
override val switch_reports: String = "Chuyển báo cáo"
override val tax: String = "Thuế"
override val tax_deleted_message: String = "Thuế đã xóa"
override val tax_description_warning: String = "Nếu sử dụng, điều này sẽ hiển thị trên hóa đơn"
override val tax_input_flat: String = "Bằng"
override val tax_input_percent: String = "Phần trăm"
override val tax_number_fields: String = "Số thuế"
override val tax_number_hint: String = "Số thuế"
override val tax_query_info_description: String = "Thông tin Truy vấn"
override val tax_report: String = "Thuế"
override val tax_rules_option: String = "Quy tắc thuế"
override val taxed_clients_report: String = "Khách hàng bị đánh thuế"
override val template: String = "Mẫu"
override val template_selector_info: String = "Trượt nhanh để thay đổi mẫu mặc định"
override val template_unlock_promo: String = "Truy cập nhiều mẫu hơn với Premium"
override val templates_option: String = "Mẫu"
override val terms_of_service: String = "Điều khoản dịch vụ"
override val thank_you: String = "Cảm ơn bạn"
override val to: String = "Đến"
override val total: Formattable = Formattable { (sum) -> "Tổng cộng: ${sum}" }
override val total_label: String = "Toàn bộ"
override val undo_action: String = "Hoàn tác"
override val unit: String = "Đơn vị"
override val unit_cost_hint: String = "Chi phí đơn vị"
override val unknown_client: String = "Khách hàng không xác định"
override val unlock: String = "Mở khóa"
override val unnamed_item: String = "Mục không tên"
override val upgrade: String = "Nâng cấp"
override val user_collision_error: String = "Tài khoản đã tồn tại."
override val valid_until: String = "Có hiệu lực đến"
override val warning: String = "Cảnh báo!"
override val yes: String = "có"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Chấp nhận ${termsOfService} và ${privacyPolicy}" },
	"account" to "tài khoản",
	"accounting" to "kế toán",
	"accruals" to "chi phí dồn tích",
	"action_code_error" to "Mã không hợp lệ.",
	"add_client_action" to "Thêm khách hàng",
	"add_customer" to "Thêm khách hàng",
	"add_expense" to "Thêm chi phí",
	"add_fields_label" to "Thêm:",
	"add_invoice_action" to "Tạo",
	"add_item_action" to "Thêm mục",
	"add_item_button" to "Thêm mục",
	"add_signature_action" to "Thêm chữ ký",
	"add_tax_action" to "Thêm thuế",
	"address_fields" to "Địa chỉ",
	"address_line_hint" to "Đường Địa chỉ",
	"advanced" to "Tiên tiến",
	"agree_marketing" to "Tôi đồng ý nhận Truyền thông Tiếp thị Trực tiếp",
	"all_notifications" to "Tất cả Thông báo",
	"amortization" to "khấu hao tài sản cố định vô hình",
	"amount" to "Lượng",
	"asset" to "tài sản",
	"attach_file" to "Đính kèm tập tin",
	"attachment" to "Tập tin đính kèm",
	"auth_error" to "Hành động thất bại, hãy thử lại.",
	"available_for_paid" to "Đồng bộ hóa đám mây chỉ khả dụng theo đăng ký trả phí.",
	"backups_option" to "Sao lưu",
	"balance" to "số dư",
	"bank_account" to "Tài khoản ngân hàng",
	"bill_number" to "số hóa đơn",
	"cancel" to "Hủy",
	"cancel_trial" to "Hủy tùy thích",
	"capital" to "vốn",
	"cash" to "tiền mặt",
	"categories" to "Thể loại",
	"category" to "Danh mục",
	"category_add" to "thêm thể loại",
	"category_delete" to "Xóa danh mục",
	"category_name" to "Tên danh mục",
	"change_language" to Formattable { (language) -> "Ngôn ngữ hóa đơn: ${language}" },
	"change_language_label" to "Ngôn ngữ hóa đơn",
	"change_template" to "Thay đổi mẫu",
	"city_hint" to "Thành phố",
	"clear" to "Trong",
	"client" to "Khách hàng",
	"client_actions" to "Hành động của khách hàng",
	"client_deleted_message" to "Máy khách đã xóa",
	"clients_error_required_fields" to "Ít nhất một giá trị là cần thiết.",
	"clients_option" to "Khách hàng",
	"close" to "Đóng",
	"cloud_sync" to "Đồng bộ đám mây",
	"coming_soon" to "Đến sớm!",
	"company_logo" to "Logo công ty",
	"company_name_hint" to "Công ty",
	"confirm_password_label" to "Xác nhận mật khẩu",
	"confirmation_title" to "Bạn có chắc không?",
	"convert_to_invoice" to "Chuyển đổi sang hóa đơn",
	"country_hint" to "Quốc gia",
	"create_client" to "Tạo Khách hàng",
	"create_company" to "Tạo công ty",
	"create_document" to "Tạo tài liệu",
	"create_estimate" to "Ước tính",
	"create_invoice" to "Hóa đơn",
	"create_item" to "Tạo Mục",
	"create_new_client" to "Tạo khách hàng mới",
	"create_new_item" to "Tạo mục mới",
	"create_new_tax" to "Tạo thuế mới",
	"create_signature" to "Chữ ký",
	"created" to "Tạo",
	"credit" to "ghi có",
	"customer" to "Khách hàng",
	"date" to "Ngày",
	"debit" to "ghi nợ",
	"debt" to "nợ",
	"default_currency" to Formattable { (currency) -> "Tiền tệ mặc định: ${currency}" },
	"default_currency_label" to "Tiền tệ mặc định",
	"delete" to "Xóa",
	"delete_account" to "Xóa tài khoản",
	"delete_confirmation_message" to "Tất cả dữ liệu của bạn sẽ bị mất.",
	"delete_item_title" to "Xóa mục?",
	"delete_signature" to "Xóa chữ ký",
	"depreciation" to "khấu hao tài sản cố định hữu hình",
	"description" to "Sự miêu tả",
	"description_hint" to "Sự miêu tả",
	"detailed_report" to "Báo cáo chi tiết",
	"disabled" to "Tàn tật",
	"discount" to "Chiết khấu",
	"discount_hint" to "Sự bớt",
	"display_af" to "Người châu Phi",
	"display_am" to "Amharic",
	"display_ar" to "tiếng Ả Rập",
	"display_as" to "Tiếng Assam",
	"display_az" to "Azerbaijan",
	"display_be" to "Người Belarus",
	"display_bg" to "Người Bungari",
	"display_bn" to "Tiếng Bengali",
	"display_bs" to "Người Bosnia",
	"display_ca" to "Catalan",
	"display_cs" to "Tiếng Séc",
	"display_da" to "người Đan Mạch",
	"display_de" to "tiếng Đức",
	"display_el" to "người Hy Lạp",
	"display_en" to "tiếng Anh",
	"display_es" to "người Tây Ban Nha",
	"display_es_MX" to "Tiếng Tây Ban Nha (Mexico)",
	"display_et" to "Người Estonia",
	"display_eu" to "Xứ Basque",
	"display_fa" to "Tiếng ba tư",
	"display_fi" to "Phần lan",
	"display_fil" to "Người Phi Luật Tân",
	"display_fr" to "người Pháp",
	"display_fr_CA" to "Canada tiếng Pháp",
	"display_gl" to "Galicia",
	"display_gu" to "Gujarati",
	"display_he" to "Tiếng Do Thái",
	"display_hi" to "Không.",
	"display_hr" to "Người Croatia",
	"display_hu" to "người Hungary",
	"display_hy" to "Tiếng Armenia",
	"display_id" to "Người Indonesia",
	"display_is" to "Tiếng Iceland",
	"display_it" to "người Ý",
	"display_ja" to "tiếng Nhật",
	"display_ka" to "Người Gruzia",
	"display_kk" to "Tiếng Kazakh",
	"display_km" to "Khơ me miền Trung",
	"display_kn" to "Tiếng Kannada",
	"display_ko" to "Hàn Quốc",
	"display_ky" to "Kirghiz",
	"display_lo" to "Lao",
	"display_lt" to "Tiếng Lithuania",
	"display_lv" to "Người Latvia",
	"display_mk" to "Người Macedonian",
	"display_ml" to "Malayalam",
	"display_mn" to "Tiếng Mông Cổ",
	"display_mr" to "Marathi",
	"display_ms" to "Tiếng Mã Lai",
	"display_my" to "Miến Điện",
	"display_nb" to "Bokmål của Na Uy",
	"display_ne" to "Tiếng Nepal",
	"display_nl" to "Tiếng hà lan",
	"display_or" to "Oriya",
	"display_pa" to "Tiếng Punjabi",
	"display_pl" to "Đánh bóng",
	"display_pt" to "Người Bồ Đào Nha",
	"display_pt_BR" to "Tiếng Bồ Đào Nha (Brazil)",
	"display_pt_PT" to "Tiếng Bồ Đào Nha Châu Âu",
	"display_ro" to "Tiếng Rumani",
	"display_ru" to "tiếng Nga",
	"display_si" to "Sinhala",
	"display_sk" to "Tiếng Slovak",
	"display_sl" to "Người Slovenia",
	"display_sq" to "Người Albanian",
	"display_sr" to "Tiếng Serbia",
	"display_sv" to "Tiếng Thụy Điển",
	"display_sw" to "Tiếng Swahili",
	"display_ta" to "Tamil",
	"display_te" to "Tiếng Telugu",
	"display_th" to "Thái lan",
	"display_tr" to "Thổ nhĩ kỳ",
	"display_uk" to "Người Ukraina",
	"display_ur" to "Tiếng Urdu",
	"display_uz" to "Tiếng Uzbek",
	"display_vi" to "Tiếng Việt",
	"display_zh_Hant_TW" to "Tiếng Trung (Đài Loan, Phồn thể)",
	"display_zu" to "Zulu",
	"document_estimate" to "Ước tính",
	"document_invoice" to "Hóa đơn",
	"document_quote" to "Trích dẫn",
	"done" to "Làm xong",
	"download_app" to "Tải xuống ứng dụng",
	"download_invoice" to "Tải xuống hóa đơn",
	"download_pdf" to "tải PDF",
	"draw_signature" to "Vẽ chữ ký",
	"due" to Formattable { (date) -> "Đến hạn ${date}" },
	"due_date" to "Ngày đến hạn",
	"due_day" to "Vào một ngày",
	"due_label" to "Do",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} đến hạn ${dueWhen}!" },
	"due_today" to "Hôm nay",
	"due_week" to "trong một tuần",
	"duplicate" to "Bản sao",
	"duration_day" to "ngày",
	"duration_month" to "tháng",
	"duration_week" to "tuần",
	"duration_year" to "năm",
	"email" to "Email",
	"email_blank_error" to "Cần gửi email.",
	"email_error" to "Địa chỉ email không hợp lệ.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Cảm ơn vì đã giao dịch. Để xem toàn bộ ${documentType}, hãy nhấp vào nút ở cuối lá thư này. Xin lưu ý, tài liệu này có hiệu lực đến ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Bạn đã nhận được ${documentType} ${invoiceNumber} mới!" },
	"email_invoice_cta" to Formattable { (documentType) -> "XEM ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Kính gửi ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Cảm ơn bạn cho doanh nghiệp của bạn. Để xem đầy đủ ${documentType}, hãy nhấp vào nút ở cuối bức thư này. Vui lòng thanh toán số dư cho đến ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Bạn đã nhận được ${documentType} ${invoiceNumber} mới!" },
	"email_reset_success_message" to "Email để đặt lại mật khẩu đã gửi, vui lòng kiểm tra hộp thư đến của bạn.",
	"empty_clients_list_message" to "Bạn không có khách hàng để theo dõi. Thêm một khách hàng mới.",
	"empty_estimate_list_message" to "Tạo ước tính đầu tiên của bạn",
	"empty_expenses" to "Bạn không có chi phí để theo dõi. Thêm một khoản chi phí mới.",
	"empty_invoice_list_message" to "Tạo hóa đơn đầu tiên của bạn",
	"empty_items_list_message" to "Bạn không có mục, thêm một mục mới!",
	"empty_signatures_list_message" to "Không tìm thấy chữ ký. Tạo một!",
	"empty_taxes_list_message" to "Bạn không có quy định về thuế. Thêm một quy tắc thuế mới.",
	"empty_templates_list_message" to "Mẫu không sẵn dùng.",
	"enabled" to "Đã bật",
	"entry" to "bút toán",
	"equity" to "vốn chủ sở hữu",
	"estimate_number" to "Số ước tính",
	"estimates" to "Ước tính",
	"estimates_report" to "Ước tính",
	"expense_bank_fees" to "Phí ngân hàng",
	"expense_benefits" to "Lợi ích",
	"expense_communication" to "Giao tiếp",
	"expense_debt" to "Nợ nần",
	"expense_deleted" to "Đã xóa chi phí",
	"expense_depreciation" to "Khấu hao tài sản cố định hữu hình",
	"expense_gifts" to "Những món quà",
	"expense_insurance" to "Bảo hiểm",
	"expense_inventory" to "Hàng tồn kho",
	"expense_legal" to "Hợp pháp",
	"expense_maintenance" to "BẢO TRÌ",
	"expense_marketing" to "Tiếp thị",
	"expense_meals" to "Các bữa ăn",
	"expense_office_supplies" to "Văn phòng phẩm",
	"expense_rent" to "Thuê",
	"expense_salaries" to "Lương",
	"expense_shopping" to "Mua sắm",
	"expense_software" to "Phần mềm",
	"expense_status_available" to "Có sẵn",
	"expense_status_processing" to "Xử lý",
	"expense_tax" to "Thuế",
	"expense_training" to "Đào tạo",
	"expense_transport" to "Chuyên chở",
	"expense_travel" to "Du lịch",
	"expense_utility" to "Tính thiết thực",
	"expenses" to "Chi phí",
	"feedback_option" to "Phản hồi",
	"filter" to "Bộ lọc",
	"first_name_hint" to "Tên thánh",
	"forgot_password" to "Đặt lại mật khẩu",
	"forgot_password_label" to "Quên mật khẩu",
	"from" to "Từ",
	"general_error" to "Đã xảy ra lỗi, vui lòng thử lại sau.",
	"general_ledger" to "sổ cái chung",
	"go_back_label" to "Quay lại",
	"group_by" to "Nhóm theo:",
	"image_view" to "Ảnh",
	"install" to "Cài đặt",
	"invalid_credentials_error" to "Chứng danh không hợp lệ.",
	"invalid_email_error" to "Email không hợp lệ.",
	"invalid_user_error" to "Chứng danh người dùng không hợp lệ.",
	"invoice_deleted_message" to "Hóa đơn đã xóa",
	"invoice_menu" to "Menu hóa đơn",
	"invoice_number" to "Hóa đơn #",
	"invoices" to "Hoá đơn",
	"items_option" to "Mục",
	"keep" to "Giữ",
	"language_selector" to Formattable { (selected) -> "Ngôn ngữ ${selected}" },
	"last_name_hint" to "Họ",
	"ledger" to "sổ cái",
	"legal" to "Hợp pháp",
	"legal_error" to "Phải chấp nhận Điều khoản dịch vụ và chính sách bảo mật",
	"liabilities" to "nợ phải trả",
	"loading" to "Tải",
	"login_required_error" to "Yêu cầu đăng nhập.",
	"logo_view" to "Logo",
	"logout" to "Logout",
	"logout_prompt" to "Dữ liệu của bạn không được đồng bộ hóa với đám mây. Xóa hóa đơn, ước tính, khách hàng và thông tin khác về logout?",
	"main_screen" to "Màn hình chính",
	"mark_paid" to "Đánh dấu là đã trả phí",
	"message" to "Tin nhắn",
	"missing_sender_msg" to "Tài liệu thiếu thông tin người gửi có thể không hợp lệ.",
	"missing_sender_title" to "Thiếu thông tin người gửi",
	"month_april" to "Tháng tư",
	"month_april_short" to "Tháng tư",
	"month_august" to "Tháng tám",
	"month_august_short" to "Th.8",
	"month_december" to "Tháng 12",
	"month_december_short" to "Th.12",
	"month_february" to "tháng 2",
	"month_february_short" to "Th.2",
	"month_january" to "tháng Giêng",
	"month_january_short" to "Th.1",
	"month_july" to "Tháng bảy",
	"month_july_short" to "Tháng bảy",
	"month_june" to "Tháng sáu",
	"month_june_short" to "Tháng sáu",
	"month_march" to "Bước đều",
	"month_march_short" to "Bước đều",
	"month_may" to "Có thể",
	"month_may_short" to "Có thể",
	"month_november" to "Tháng mười một",
	"month_november_short" to "Th.11",
	"month_october" to "Tháng Mười",
	"month_october_short" to "Th.10",
	"month_september" to "Tháng 9",
	"month_september_short" to "Th.9",
	"multifactor_error" to "Không xác minh được.",
	"new" to "mới",
	"news" to "Tin tức",
	"next" to "Kế tiếp",
	"no" to "không",
	"no_data_available" to "Không có sẵn dữ liệu",
	"no_search_results" to "Xin lỗi, chúng tôi không thể tìm thấy bất kỳ kết quả nào.",
	"none" to "Không có",
	"note_fields" to "Ghi chú",
	"note_hint" to "Ghi",
	"notifications_option" to "Thông báo",
	"ok" to "OK",
	"onboarding" to "giới thiệu",
	"onboarding_aboutus_message" to "Các phần thiết yếu của DingVoice mãi mãi miễn phí. Nhiệm vụ của chúng tôi là giúp bạn phát triển doanh nghiệp của mình, giúp chúng tôi cải thiện bằng cách chia sẻ phản hồi của bạn.",
	"onboarding_aboutus_title" to "Chúng tôi ở đây để giúp đỡ",
	"onboarding_paid_1" to "Truy cập các mẫu tìm kiếm chuyên nghiệp",
	"onboarding_paid_2" to "Nhìn sâu hơn vào tài chính của bạn",
	"onboarding_paid_3" to "Giải quyết vấn đề nhanh chóng",
	"onboarding_paid_4" to "Giữ tài liệu của bạn an toàn",
	"onboarding_paid_5" to "Luôn cập nhật các thay đổi về trạng thái hóa đơn",
	"onboarding_paid_title" to "Phát triển nhanh hơn nữa với...",
	"onboarding_paywall_message" to "Tận hưởng tất cả các tính năng cao cấp ngay bây giờ với giá tốt nhất.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Chỉ ${currencySymbol}${introAmount} trong giai đoạn ${introDuration} ${introPeriod} và sau đó là ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Chỉ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Miễn phí trong giai đoạn ${trialDuration} ${trialPeriod} và sau đó chỉ ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Miễn phí cho${trialDuration}${trialPeriod} sau đó${currencySymbol}${introductoryAmount} vì${introductoryDuration}${introPeriod} và sau đó chỉ${currencySymbol}${amount} /${duration}" },
	"onboarding_paywall_renewable" to "Tự động tái tạo. Hủy bỏ bất cứ lúc nào.",
	"onboarding_paywall_restore" to "Khôi phục mua hàng",
	"onboarding_paywall_restore_failure" to "Khôi phục giao dịch mua không thành công, hãy thử lại sau hoặc liên hệ với bộ phận hỗ trợ.",
	"onboarding_paywall_restore_success" to "Khôi phục thành công!",
	"onboarding_paywall_title" to "Đạt được sự vĩ đại!",
	"onboarding_welcome_message" to "Một bộ công cụ hoàn chỉnh để quản lý hóa đơn và ước tính của bạn.",
	"onboarding_welcome_title" to "Chào mừng đến với DingVoice",
	"other_state" to "Khác",
	"overdue_state" to "Quá hạn",
	"paid_state" to "Trả tiền",
	"password" to "Mật khẩu",
	"password_blank_error" to "Cần mật khẩu.",
	"password_mismatch_error" to "Mật khẩu không khớp.",
	"pay" to "thanh toán",
	"payment_instructions_label" to "Hướng dẫn thanh toán",
	"payment_reminders" to "Lời nhắc thanh toán",
	"phone_number_fields" to "Số điện thoại",
	"phone_number_hint" to "Số điện thoại",
	"postcode_hint" to "Mã bưu chính",
	"preview" to "Download",
	"preview_menu_hint" to "Menu Xem trước",
	"price" to "Giá",
	"privacy_policy" to "Chính sách bảo mật",
	"profile" to "Hồ sơ",
	"profitable_client" to "Khách hàng có lợi nhất của bạn là ...",
	"profits_report" to "Lợi nhuận",
	"promo_content" to "Nội dung quảng cáo",
	"quantity_hint" to "Số lượng",
	"quantity_label_hint" to "Nhãn số lượng",
	"query_info" to "Truy vấn cho phép bạn viết các tính toán tùy chỉnh đề cập đến cả mục và các quy tắc thuế khác làm đầu vào. Thêm biểu tượng @ và mô tả mục hoặc thuế để liên kết đến mục nhập khác +, -, *, /, % - toán học",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "Khách hàng của bạn đã nhận được ${documentType} ${documentNumber}." },
	"received_state" to "Nhận",
	"registration_code_fields" to "Mã đăng ký",
	"reminder" to "Nhắc nhở",
	"reports" to "Báo cáo",
	"reports_not_available" to "Không có báo cáo nào, vui lòng quay lại sau.",
	"reveal_password" to "Tiết lộ mật khẩu",
	"revenue" to "doanh thu",
	"sales_tax" to "Thuế GTGT",
	"sales_tax_number" to "Số thuế GTGT",
	"save" to "Cứu",
	"scan_document" to "Quét tài liệu",
	"search" to "Tìm kiếm",
	"select" to "Lựa chọn",
	"select_icon" to "Chọn biểu tượng",
	"select_signature" to "Chọn chữ ký",
	"select_template" to "Chọn mẫu",
	"send" to "Gửi",
	"sent_state" to "Gửi",
	"setup_sender" to "Thiết lập người gửi",
	"share_option" to "Chia sẻ",
	"ship_to" to "Tàu",
	"sign_in" to "Đăng nhập",
	"sign_in_apple" to "Đăng nhập với Apple",
	"sign_in_email" to "Đăng nhập bằng email",
	"sign_in_facebook" to "Đăng nhập bằng Facebook",
	"sign_in_google" to "Đăng nhập với Google",
	"sign_up" to "Đăng ký",
	"sign_up_label" to "Không có tài khoản?",
	"sign_up_link_label" to "Đăng ký!",
	"signatures_option" to "Chữ ký",
	"skip" to "Nhảy",
	"sold_items_report" to "Các mặt hàng đã bán",
	"sort_by" to "Sắp xếp theo:",
	"status_update" to "Cập nhật trạng thái tài liệu",
	"sub_annual" to "Hàng năm",
	"sub_annual_price" to Formattable { (price) -> "${price}/năm" },
	"sub_backups" to "Sao lưu đám mây",
	"sub_insights" to "Thông tin chi tiết",
	"sub_month" to "Hàng tháng",
	"sub_monthly_price" to Formattable { (price) -> "${price}/tháng" },
	"sub_notifications" to "Thông báo",
	"sub_popup_message" to "Đăng ký sẽ sớm ra mắt. Kiểm tra lại đây trong một thời gian ngắn.",
	"sub_support" to "Hỗ trợ",
	"sub_templates" to "Mẫu",
	"subscribe" to "Đăng ký",
	"subscription_header" to "Phát triển doanh nghiệp của bạn với:",
	"subscription_option" to "Đăng ký",
	"subscriptions_load_fail" to "Rất tiếc, chúng tôi không thể truy xuất đăng ký của bạn vào lúc này. Vui lòng thử lại sau hoặc liên hệ với nhóm hỗ trợ của chúng tôi để được trợ giúp.",
	"subtotal" to Formattable { (sum) -> "Tổng phụ: ${sum}" },
	"subtotal_label" to "Tổng phụ",
	"sum" to "Tổng",
	"support_option" to "Hỗ trợ",
	"switch_reports" to "Chuyển báo cáo",
	"tax" to "Thuế",
	"tax_deleted_message" to "Thuế đã xóa",
	"tax_description_warning" to "Nếu sử dụng, điều này sẽ hiển thị trên hóa đơn",
	"tax_input_flat" to "Bằng",
	"tax_input_percent" to "Phần trăm",
	"tax_number_fields" to "Số thuế",
	"tax_number_hint" to "Số thuế",
	"tax_query_info_description" to "Thông tin Truy vấn",
	"tax_report" to "Thuế",
	"tax_rules_option" to "Quy tắc thuế",
	"taxed_clients_report" to "Khách hàng bị đánh thuế",
	"template" to "Mẫu",
	"template_selector_info" to "Trượt nhanh để thay đổi mẫu mặc định",
	"template_unlock_promo" to "Truy cập nhiều mẫu hơn với Premium",
	"templates_option" to "Mẫu",
	"terms_of_service" to "Điều khoản dịch vụ",
	"thank_you" to "Cảm ơn bạn",
	"to" to "Đến",
	"total" to Formattable { (sum) -> "Tổng cộng: ${sum}" },
	"total_label" to "Toàn bộ",
	"undo_action" to "Hoàn tác",
	"unit" to "Đơn vị",
	"unit_cost_hint" to "Chi phí đơn vị",
	"unknown_client" to "Khách hàng không xác định",
	"unlock" to "Mở khóa",
	"unnamed_item" to "Mục không tên",
	"upgrade" to "Nâng cấp",
	"user_collision_error" to "Tài khoản đã tồn tại.",
	"valid_until" to "Có hiệu lực đến",
	"warning" to "Cảnh báo!",
	"yes" to "có",
    )
    
}