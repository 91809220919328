// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Hu  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Elfogadom a ${termsOfService} és a ${privacyPolicy}" }
override val account: String = "számla"
override val accounting: String = "könyvelés"
override val accruals: String = "elhatárolások"
override val action_code_error: String = "Érvénytelen kód."
override val add_client_action: String = "Ügyfél hozzáadása"
override val add_customer: String = "Ügyfél hozzáadása"
override val add_expense: String = "Költségek hozzáadása"
override val add_fields_label: String = "Add:"
override val add_invoice_action: String = "Hozzon létre"
override val add_item_action: String = "Tétel hozzáadása"
override val add_item_button: String = "Tétel hozzáadása"
override val add_signature_action: String = "Aláírás hozzáadása"
override val add_tax_action: String = "Adó hozzáadása"
override val address_fields: String = "Cím:"
override val address_line_hint: String = "Címsor"
override val advanced: String = "ADVANCED"
override val agree_marketing: String = "Hozzájárulok a közvetlen marketing kommunikációhoz"
override val all_notifications: String = "Minden értesítés"
override val amortization: String = "amortizáció"
override val amount: String = "Összeg"
override val asset: String = "eszköz"
override val attach_file: String = "Fájl csatolása"
override val attachment: String = "Csatolás"
override val auth_error: String = "A művelet sikertelen, próbálja meg újra."
override val available_for_paid: String = "A felhőszinkronizálás csak fizetős előfizetés esetén érhető el."
override val backups_option: String = "Biztonsági mentések"
override val balance: String = "egyenleg"
override val bank_account: String = "Bankszámla"
override val bill_number: String = "Számla száma"
override val cancel: String = "Törölje"
override val cancel_trial: String = "Bármikor lemondható"
override val capital: String = "tőke"
override val cash: String = "készpénz"
override val categories: String = "Kategóriák"
override val category: String = "Kategória"
override val category_add: String = "Kategória hozzáadása"
override val category_delete: String = "Kategória törlése"
override val category_name: String = "Kategória neve"
override val change_language: Formattable = Formattable { (language) -> "Számla nyelve: ${language}" }
override val change_language_label: String = "Számla Nyelve"
override val change_template: String = "Sablon módosítása"
override val city_hint: String = "Város"
override val clear: String = "Tiszta"
override val client: String = "Ügyfél"
override val client_actions: String = "Ügyfélintézkedések"
override val client_deleted_message: String = "Ügyfél törölve"
override val clients_error_required_fields: String = "Legalább egy érték szükséges."
override val clients_option: String = "Ügyfelek"
override val close: String = "Zárja be a"
override val cloud_sync: String = "Felhő szinkronizálás"
override val coming_soon: String = "Hamarosan!"
override val company_logo: String = "Céglogó"
override val company_name_hint: String = "Cég"
override val confirm_password_label: String = "Jelszó megerősítése"
override val confirmation_title: String = "Biztos vagy benne?"
override val convert_to_invoice: String = "Számlává alakítás"
override val country_hint: String = "Ország"
override val create_client: String = "Ügyfél létrehozása"
override val create_company: String = "Vállalat létrehozása"
override val create_document: String = "Dokumentum létrehozása"
override val create_estimate: String = "Becslés"
override val create_invoice: String = "Számla"
override val create_item: String = "Elem létrehozása"
override val create_new_client: String = "Új ügyfél létrehozása"
override val create_new_item: String = "Új elem létrehozása"
override val create_new_tax: String = "Új adó létrehozása"
override val create_signature: String = "Aláírás"
override val created: String = "létrehozott"
override val credit: String = "hitel"
override val customer: String = "Ügyfél"
override val date: String = "Dátum"
override val debit: String = "betéti"
override val debt: String = "adósság"
override val default_currency: Formattable = Formattable { (currency) -> "Alapértelmezett pénznem: ${currency}" }
override val default_currency_label: String = "Alapértelmezett pénznem"
override val delete: String = "Törölje a  címet."
override val delete_account: String = "Fiók törlése"
override val delete_confirmation_message: String = "Minden adata elvész."
override val delete_item_title: String = "Törölje az elemet?"
override val delete_signature: String = "Aláírás törlése"
override val depreciation: String = "értékcsökkenés"
override val description: String = "Leírás"
override val description_hint: String = "Leírás"
override val detailed_report: String = "Részletes Jelentés"
override val disabled: String = "Mozgáskorlátozottak"
override val discount: String = "Kedvezmény"
override val discount_hint: String = "Kedvezmény"
override val display_af: String = "Afrikaans"
override val display_am: String = "Amharic"
override val display_ar: String = "Arabic"
override val display_as: String = "Assamese"
override val display_az: String = "Azerbajdzsán"
override val display_be: String = "Fehérorosz"
override val display_bg: String = "Bolgár"
override val display_bn: String = "Bengáli"
override val display_bs: String = "Boszniai"
override val display_ca: String = "Katalán"
override val display_cs: String = "Cseh"
override val display_da: String = "Dán"
override val display_de: String = "Német"
override val display_el: String = "Görög"
override val display_en: String = "Angol"
override val display_es: String = "Spanyol"
override val display_es_MX: String = "Spanyol (Mexikó)"
override val display_et: String = "Észt"
override val display_eu: String = "Baszk"
override val display_fa: String = "Perzsa"
override val display_fi: String = "Finn"
override val display_fil: String = "Fülöp-szigeteki"
override val display_fr: String = "Francia"
override val display_fr_CA: String = "Kanadai francia"
override val display_gl: String = "Galíciai"
override val display_gu: String = "Gujarati"
override val display_he: String = "Héber"
override val display_hi: String = "Hindi"
override val display_hr: String = "Horvát"
override val display_hu: String = "Magyar"
override val display_hy: String = "Örmény"
override val display_id: String = "Indonéz"
override val display_is: String = "Izlandi"
override val display_it: String = "Olasz"
override val display_ja: String = "Japán"
override val display_ka: String = "Grúz"
override val display_kk: String = "Kazahsztán"
override val display_km: String = "Központi khmer"
override val display_kn: String = "Kannada"
override val display_ko: String = "Koreai"
override val display_ky: String = "Kirgiz"
override val display_lo: String = "Lao"
override val display_lt: String = "Litvánia"
override val display_lv: String = "Lett"
override val display_mk: String = "Macedóniai"
override val display_ml: String = "Malayalam"
override val display_mn: String = "Mongol"
override val display_mr: String = "Marathi"
override val display_ms: String = "Maláj"
override val display_my: String = "Burmai"
override val display_nb: String = "Norvég Bokmål"
override val display_ne: String = "Nepáli"
override val display_nl: String = "Holland"
override val display_or: String = "Oriya"
override val display_pa: String = "Pandzsábi"
override val display_pl: String = "Lengyel"
override val display_pt: String = "Portugál"
override val display_pt_BR: String = "Portugál (Brazília)"
override val display_pt_PT: String = "Európai portugál"
override val display_ro: String = "Román"
override val display_ru: String = "Orosz"
override val display_si: String = "Sinhala"
override val display_sk: String = "Szlovák"
override val display_sl: String = "Szlovén"
override val display_sq: String = "Albán"
override val display_sr: String = "Szerb"
override val display_sv: String = "Svéd"
override val display_sw: String = "Szuahéli"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "Thai"
override val display_tr: String = "Török"
override val display_uk: String = "Ukrán"
override val display_ur: String = "Urdu"
override val display_uz: String = "Üzbég"
override val display_vi: String = "Vietnámi"
override val display_zh_Hant_TW: String = "Kínai (Tajvan, Hagyományos)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Becslés"
override val document_invoice: String = "Számla"
override val document_quote: String = "Árajánlat"
override val done: String = "Kész"
override val download_app: String = "Az alkalmazás letöltése"
override val download_invoice: String = "Számla letöltése"
override val download_pdf: String = "PDF letöltése"
override val draw_signature: String = "Aláírás rajzolása"
override val due: Formattable = Formattable { (date) -> "Esedékes ${date}" }
override val due_date: String = "Esedékesség dátuma"
override val due_day: String = "egy nap alatt"
override val due_label: String = "Due"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} esedékes ${dueWhen}!" }
override val due_today: String = "Ma"
override val due_week: String = "egy héten belül"
override val duplicate: String = "Duplikálás"
override val duration_day: String = "nap"
override val duration_month: String = "Hónap"
override val duration_week: String = "hét"
override val duration_year: String = "év"
override val email: String = "E-mail"
override val email_blank_error: String = "Email szükséges."
override val email_error: String = "Érvénytelen e-mail cím."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Köszönjük az üzletet. A teljes ${documentType} megtekintéséhez kattintson a levél végén található gombra. Vegye figyelembe, hogy ${dueDate}-ig érvényes." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Ön egy új ${documentType} ${invoiceNumber} dokumentumot kapott!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "SZÁMLA ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (ügyfélNév) -> "Kedves ${ügyfélNév}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Köszönjük az üzletet. A teljes ${documentType} megtekintéséhez kattintson a levél végén található gombra. Kérjük, hogy a fennmaradó összeget ${dueDate}-ig fizesse ki." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Ön egy új ${documentType} ${invoiceNumber} dokumentumot kapott!" }
override val email_reset_success_message: String = "A jelszó visszaállításához küldött e-mail, kérjük, ellenőrizze postaládáját."
override val empty_clients_list_message: String = "Nincsenek követendő ügyfeleid. Adjon hozzá egy új ügyfelet."
override val empty_estimate_list_message: String = "Hozzon létre egy első becslést"
override val empty_expenses: String = "Nem kell nyomon követni a kiadásokat. Adjon hozzá egy új költséget."
override val empty_invoice_list_message: String = "Az első számla létrehozása"
override val empty_items_list_message: String = "Nincsenek tárgyai, adjon hozzá egy új tárgyat!"
override val empty_signatures_list_message: String = "Nem találtak aláírást. Hozzon létre egyet!"
override val empty_taxes_list_message: String = "Nincsenek adószabályok. Adjon hozzá egy új adószabályt."
override val empty_templates_list_message: String = "Sablonok nem elérhetők."
override val enabled: String = "Engedélyezve"
override val entry: String = "bejegyzés"
override val equity: String = "saját tőke"
override val estimate_number: String = "Becslés #"
override val estimates: String = "Becslések"
override val estimates_report: String = "Becslések"
override val expense_bank_fees: String = "Banki díjak"
override val expense_benefits: String = "Előnyök"
override val expense_communication: String = "Kommunikáció"
override val expense_debt: String = "Adósság"
override val expense_deleted: String = "Költségek törölve"
override val expense_depreciation: String = "Értékcsökkenés"
override val expense_gifts: String = "Ajándékok"
override val expense_insurance: String = "Biztosítás"
override val expense_inventory: String = "Leltár"
override val expense_legal: String = "Jogi"
override val expense_maintenance: String = "Karbantartás"
override val expense_marketing: String = "Marketing"
override val expense_meals: String = "Étkezés"
override val expense_office_supplies: String = "Irodaszerek"
override val expense_rent: String = "Bérlés"
override val expense_salaries: String = "Fizetések"
override val expense_shopping: String = "Vásárlás"
override val expense_software: String = "Szoftver"
override val expense_status_available: String = "Elérhető"
override val expense_status_processing: String = "Feldolgozás"
override val expense_tax: String = "Adó"
override val expense_training: String = "Képzés"
override val expense_transport: String = "Szállítás"
override val expense_travel: String = "Utazás"
override val expense_utility: String = "Közművek"
override val expenses: String = "Költségek"
override val feedback_option: String = "Visszajelzés"
override val filter: String = "Szűrő"
override val first_name_hint: String = "Keresztnév"
override val forgot_password: String = "Jelszó visszaállítása"
override val forgot_password_label: String = "Elfelejtett jelszó"
override val from: String = "a  címről"
override val general_error: String = "Hiba történt, kérjük, próbálja meg később újra."
override val general_ledger: String = "főkönyvi"
override val go_back_label: String = "Vissza"
override val group_by: String = "Csoport:"
override val image_view: String = "Kép"
override val install: String = "Telepítés"
override val invalid_credentials_error: String = "Érvénytelen hitelesítő adatok."
override val invalid_email_error: String = "Érvénytelen e-mail."
override val invalid_user_error: String = "Érvénytelen felhasználói hitelesítő adatok."
override val invoice_deleted_message: String = "Számla törölve"
override val invoice_menu: String = "Számla menü"
override val invoice_number: String = "Számla #"
override val invoices: String = "Számlák"
override val items_option: String = "Tételek"
override val keep: String = "Tartsa a  címet."
override val language_selector: Formattable = Formattable { (kiválasztott) -> "Nyelv ${kiválasztott}" }
override val last_name_hint: String = "Vezetéknév"
override val ledger: String = "főkönyv"
override val legal: String = "Jogi"
override val legal_error: String = "El kell fogadnia a szolgáltatási feltételeket és az adatvédelmi szabályzatot"
override val liabilities: String = "kötelezettségek"
override val loading: String = "Betöltés"
override val login_required_error: String = "Bejelentkezés szükséges."
override val logo_view: String = "Logó"
override val logout: String = "Kijelentkezés"
override val logout_prompt: String = "Az Ön adatai nem szinkronizálódnak a felhővel. Számlák, becslések, ügyfelek és egyéb információk törlése kijelentkezéskor?"
override val main_screen: String = "Főképernyő"
override val mark_paid: String = "Fizetettként megjelölni"
override val message: String = "Üzenet"
override val missing_sender_msg: String = "A feladó adatait nem tartalmazó dokumentum érvénytelen lehet."
override val missing_sender_title: String = "Hiányzó feladó információ"
override val month_april: String = "Április"
override val month_april_short: String = "Április"
override val month_august: String = "Augusztus"
override val month_august_short: String = "Aug."
override val month_december: String = "December"
override val month_december_short: String = "Dec."
override val month_february: String = "Február"
override val month_february_short: String = "Feb."
override val month_january: String = "Január"
override val month_january_short: String = "Jan."
override val month_july: String = "Július"
override val month_july_short: String = "Július"
override val month_june: String = "Június"
override val month_june_short: String = "Június"
override val month_march: String = "Március"
override val month_march_short: String = "Március"
override val month_may: String = "Május"
override val month_may_short: String = "Május"
override val month_november: String = "November"
override val month_november_short: String = "Nov."
override val month_october: String = "Október"
override val month_october_short: String = "Okt."
override val month_september: String = "Szeptember"
override val month_september_short: String = "Szept."
override val multifactor_error: String = "Nem sikerült ellenőrizni."
override val new: String = "Új"
override val news: String = "Hírek"
override val next: String = "Következő"
override val no: String = "nem"
override val no_data_available: String = "Nem áll rendelkezésre adat"
override val no_search_results: String = "Sajnáljuk, de nem találtunk semmilyen eredményt."
override val none: String = "Nincs"
override val note_fields: String = "Megjegyzések"
override val note_hint: String = "Megjegyzés:"
override val notifications_option: String = "Értesítések"
override val ok: String = "OK"
override val onboarding: String = "Beépítés"
override val onboarding_aboutus_message: String = "A DingVoice alapvető részei örökre ingyenesek. Küldetésünk, hogy segítsünk Önnek vállalkozásának növekedésében, segítsen nekünk a fejlődésben visszajelzései megosztásával."
override val onboarding_aboutus_title: String = "Azért vagyunk itt, hogy segítsünk"
override val onboarding_paid_1: String = "Hozzáférés professzionális megjelenésű sablonokhoz"
override val onboarding_paid_2: String = "Vessen mélyebb pillantást a pénzügyeire"
override val onboarding_paid_3: String = "A problémák gyors megoldása"
override val onboarding_paid_4: String = "Tartsa biztonságban dokumentumait"
override val onboarding_paid_5: String = "Maradjon naprakész a számla státuszának változásaival kapcsolatban"
override val onboarding_paid_title: String = "Még gyorsabb növekedés a..."
override val onboarding_paywall_message: String = "Élvezze az összes prémium funkciót most a legjobb áron."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Csak ${currencySymbol}${introAmount} a ${introDuration} ${introPeriod} és aztán ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (valutaSzimbólum, összeg, tartam) -> "Csak ${valutaSzimbólum}${összeg} / ${tartam}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Ingyenes ${trialDuration} ${trialPeriod} és utána csak ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Ingyenes ${trialDuration} ${trialPeriod}, majd ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod} áron, majd csak ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Automatikusan megújuló. Bármikor lemondható."
override val onboarding_paywall_restore: String = "Vásárlás visszaállítása"
override val onboarding_paywall_restore_failure: String = "A vásárlások visszaállítása sikertelen volt, próbálja meg később újra, vagy lépjen kapcsolatba az ügyfélszolgálattal."
override val onboarding_paywall_restore_success: String = "Visszaállítás sikeres!"
override val onboarding_paywall_title: String = "Érj el nagyságot!"
override val onboarding_welcome_message: String = "Teljes eszközkészlet a számlák és becslések kezeléséhez."
override val onboarding_welcome_title: String = "Üdvözöljük a DingVoice-nál"
override val other_state: String = "Egyéb"
override val overdue_state: String = "Késedelmes"
override val paid_state: String = "Fizetett"
override val password: String = "Jelszó"
override val password_blank_error: String = "Jelszó szükséges."
override val password_mismatch_error: String = "A jelszavak nem egyeznek."
override val pay: String = "fizetés"
override val payment_instructions_label: String = "Fizetési utasítások"
override val payment_reminders: String = "Fizetési emlékeztetők"
override val phone_number_fields: String = "Telefonszám"
override val phone_number_hint: String = "Telefonszám"
override val postcode_hint: String = "Irányítószám"
override val preview: String = "Előnézet"
override val preview_menu_hint: String = "Előnézet menü"
override val price: String = "Ár"
override val privacy_policy: String = "Adatvédelmi irányelvek"
override val profile: String = "Profil"
override val profitable_client: String = "A legjövedelmezőbb ügyfeled..."
override val profits_report: String = "Nyereség"
override val promo_content: String = "Promóciós tartalom"
override val quantity_hint: String = "Mennyiség"
override val quantity_label_hint: String = "Mennyiség Címke"
override val query_info: String = "A lekérdezés lehetővé teszi, hogy egyéni számításokat írjon mind a tételekre, mind más adószabályokra mint bemeneti adatokra hivatkozva. @ szimbólum és tétel vagy adóleírás hozzáadása egy másik bejegyzésre való hivatkozáshoz +, -, *, /, % - matematikai operátorok"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} érkezett az ügyfélhez." }
override val received_state: String = "Beérkezett"
override val registration_code_fields: String = "Regisztrációs Kód"
override val reminder: String = "Emlékeztető"
override val reports: String = "Jelentések"
override val reports_not_available: String = "Nem áll rendelkezésre jelentések, gyere vissza később."
override val reveal_password: String = "Jelszó feltárása"
override val revenue: String = "bevétel"
override val sales_tax: String = "ÁFA"
override val sales_tax_number: String = "ÁFA szám"
override val save: String = "Mentés"
override val scan_document: String = "Dokumentum beolvasása"
override val search: String = "Keresés"
override val select: String = "Válassza ki a  címet."
override val select_icon: String = "Ikon kiválasztása"
override val select_signature: String = "Aláírás kiválasztása"
override val select_template: String = "Sablon kiválasztása"
override val send: String = "Küldje el a"
override val sent_state: String = "Elküldve"
override val setup_sender: String = "Feladó beállítása"
override val share_option: String = "Megosztás"
override val ship_to: String = "Szállítás a következő címre"
override val sign_in: String = "Bejelentkezés"
override val sign_in_apple: String = "Bejelentkezés az Apple alkalmazással"
override val sign_in_email: String = "Bejelentkezés e-mailben"
override val sign_in_facebook: String = "Bejelentkezés Facebookkal"
override val sign_in_google: String = "Bejelentkezés a Google segítségével"
override val sign_up: String = "Feliratkozás"
override val sign_up_label: String = "Nincs fiókja?"
override val sign_up_link_label: String = "Regisztrálj!"
override val signatures_option: String = "Aláírások"
override val skip: String = "Skip"
override val sold_items_report: String = "Eladott tételek"
override val sort_by: String = "Rendezés:"
override val status_update: String = "A dokumentum állapotának frissítése"
override val sub_annual: String = "Éves"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/év" }
override val sub_backups: String = "Felhő biztonsági mentések"
override val sub_insights: String = "Betekintés"
override val sub_month: String = "Havi"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/hónap" }
override val sub_notifications: String = "Értesítések"
override val sub_popup_message: String = "Az előfizetések hamarosan megjelennek. Nézz vissza ide egy kis idő múlva."
override val sub_support: String = "Támogatás"
override val sub_templates: String = "Sablonok"
override val subscribe: String = "Feliratkozás"
override val subscription_header: String = "Növelje vállalkozását:"
override val subscription_option: String = "Előfizetés"
override val subscriptions_load_fail: String = "Sajnáljuk, de jelenleg nem tudjuk lekérni az előfizetéseit. Kérjük, próbálja meg később újra, vagy forduljon segítségért ügyfélszolgálatunkhoz."
override val subtotal: Formattable = Formattable { (sum) -> "Részösszeg: ${sum}" }
override val subtotal_label: String = "Részösszeg"
override val sum: String = "Sum"
override val support_option: String = "Támogatás"
override val switch_reports: String = "Switch jelentés"
override val tax: String = "Adó"
override val tax_deleted_message: String = "Adó törölve"
override val tax_description_warning: String = "Ha ezt használják, ez megjelenik a számlán"
override val tax_input_flat: String = "Lapos"
override val tax_input_percent: String = "Százalék"
override val tax_number_fields: String = "Adószám"
override val tax_number_hint: String = "Adószám"
override val tax_query_info_description: String = "Információ lekérdezés"
override val tax_report: String = "Adó"
override val tax_rules_option: String = "Adózási szabályok"
override val taxed_clients_report: String = "Adózott ügyfelek"
override val template: String = "Sablon"
override val template_selector_info: String = "Lapozás az alapértelmezett sablon megváltoztatásához"
override val template_unlock_promo: String = "Hozzáférés több sablonhoz a Prémiummal"
override val templates_option: String = "Sablonok"
override val terms_of_service: String = "Szolgáltatási feltételek"
override val thank_you: String = "Köszönöm"
override val to: String = "a  címre."
override val total: Formattable = Formattable { (összeg) -> "Összesen: ${összeg}" }
override val total_label: String = "Összesen"
override val undo_action: String = "Undo"
override val unit: String = "Egység"
override val unit_cost_hint: String = "Egységköltség"
override val unknown_client: String = "Ismeretlen Ügyfél"
override val unlock: String = "Feloldja a"
override val unnamed_item: String = "Névtelen elem"
override val upgrade: String = "Frissítés"
override val user_collision_error: String = "A fiók már létezik."
override val valid_until: String = "Érvényesség Lejárata"
override val warning: String = "Figyelem!"
override val yes: String = "igen"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Elfogadom a ${termsOfService} és a ${privacyPolicy}" },
	"account" to "számla",
	"accounting" to "könyvelés",
	"accruals" to "elhatárolások",
	"action_code_error" to "Érvénytelen kód.",
	"add_client_action" to "Ügyfél hozzáadása",
	"add_customer" to "Ügyfél hozzáadása",
	"add_expense" to "Költségek hozzáadása",
	"add_fields_label" to "Add:",
	"add_invoice_action" to "Hozzon létre",
	"add_item_action" to "Tétel hozzáadása",
	"add_item_button" to "Tétel hozzáadása",
	"add_signature_action" to "Aláírás hozzáadása",
	"add_tax_action" to "Adó hozzáadása",
	"address_fields" to "Cím:",
	"address_line_hint" to "Címsor",
	"advanced" to "ADVANCED",
	"agree_marketing" to "Hozzájárulok a közvetlen marketing kommunikációhoz",
	"all_notifications" to "Minden értesítés",
	"amortization" to "amortizáció",
	"amount" to "Összeg",
	"asset" to "eszköz",
	"attach_file" to "Fájl csatolása",
	"attachment" to "Csatolás",
	"auth_error" to "A művelet sikertelen, próbálja meg újra.",
	"available_for_paid" to "A felhőszinkronizálás csak fizetős előfizetés esetén érhető el.",
	"backups_option" to "Biztonsági mentések",
	"balance" to "egyenleg",
	"bank_account" to "Bankszámla",
	"bill_number" to "Számla száma",
	"cancel" to "Törölje",
	"cancel_trial" to "Bármikor lemondható",
	"capital" to "tőke",
	"cash" to "készpénz",
	"categories" to "Kategóriák",
	"category" to "Kategória",
	"category_add" to "Kategória hozzáadása",
	"category_delete" to "Kategória törlése",
	"category_name" to "Kategória neve",
	"change_language" to Formattable { (language) -> "Számla nyelve: ${language}" },
	"change_language_label" to "Számla Nyelve",
	"change_template" to "Sablon módosítása",
	"city_hint" to "Város",
	"clear" to "Tiszta",
	"client" to "Ügyfél",
	"client_actions" to "Ügyfélintézkedések",
	"client_deleted_message" to "Ügyfél törölve",
	"clients_error_required_fields" to "Legalább egy érték szükséges.",
	"clients_option" to "Ügyfelek",
	"close" to "Zárja be a",
	"cloud_sync" to "Felhő szinkronizálás",
	"coming_soon" to "Hamarosan!",
	"company_logo" to "Céglogó",
	"company_name_hint" to "Cég",
	"confirm_password_label" to "Jelszó megerősítése",
	"confirmation_title" to "Biztos vagy benne?",
	"convert_to_invoice" to "Számlává alakítás",
	"country_hint" to "Ország",
	"create_client" to "Ügyfél létrehozása",
	"create_company" to "Vállalat létrehozása",
	"create_document" to "Dokumentum létrehozása",
	"create_estimate" to "Becslés",
	"create_invoice" to "Számla",
	"create_item" to "Elem létrehozása",
	"create_new_client" to "Új ügyfél létrehozása",
	"create_new_item" to "Új elem létrehozása",
	"create_new_tax" to "Új adó létrehozása",
	"create_signature" to "Aláírás",
	"created" to "létrehozott",
	"credit" to "hitel",
	"customer" to "Ügyfél",
	"date" to "Dátum",
	"debit" to "betéti",
	"debt" to "adósság",
	"default_currency" to Formattable { (currency) -> "Alapértelmezett pénznem: ${currency}" },
	"default_currency_label" to "Alapértelmezett pénznem",
	"delete" to "Törölje a  címet.",
	"delete_account" to "Fiók törlése",
	"delete_confirmation_message" to "Minden adata elvész.",
	"delete_item_title" to "Törölje az elemet?",
	"delete_signature" to "Aláírás törlése",
	"depreciation" to "értékcsökkenés",
	"description" to "Leírás",
	"description_hint" to "Leírás",
	"detailed_report" to "Részletes Jelentés",
	"disabled" to "Mozgáskorlátozottak",
	"discount" to "Kedvezmény",
	"discount_hint" to "Kedvezmény",
	"display_af" to "Afrikaans",
	"display_am" to "Amharic",
	"display_ar" to "Arabic",
	"display_as" to "Assamese",
	"display_az" to "Azerbajdzsán",
	"display_be" to "Fehérorosz",
	"display_bg" to "Bolgár",
	"display_bn" to "Bengáli",
	"display_bs" to "Boszniai",
	"display_ca" to "Katalán",
	"display_cs" to "Cseh",
	"display_da" to "Dán",
	"display_de" to "Német",
	"display_el" to "Görög",
	"display_en" to "Angol",
	"display_es" to "Spanyol",
	"display_es_MX" to "Spanyol (Mexikó)",
	"display_et" to "Észt",
	"display_eu" to "Baszk",
	"display_fa" to "Perzsa",
	"display_fi" to "Finn",
	"display_fil" to "Fülöp-szigeteki",
	"display_fr" to "Francia",
	"display_fr_CA" to "Kanadai francia",
	"display_gl" to "Galíciai",
	"display_gu" to "Gujarati",
	"display_he" to "Héber",
	"display_hi" to "Hindi",
	"display_hr" to "Horvát",
	"display_hu" to "Magyar",
	"display_hy" to "Örmény",
	"display_id" to "Indonéz",
	"display_is" to "Izlandi",
	"display_it" to "Olasz",
	"display_ja" to "Japán",
	"display_ka" to "Grúz",
	"display_kk" to "Kazahsztán",
	"display_km" to "Központi khmer",
	"display_kn" to "Kannada",
	"display_ko" to "Koreai",
	"display_ky" to "Kirgiz",
	"display_lo" to "Lao",
	"display_lt" to "Litvánia",
	"display_lv" to "Lett",
	"display_mk" to "Macedóniai",
	"display_ml" to "Malayalam",
	"display_mn" to "Mongol",
	"display_mr" to "Marathi",
	"display_ms" to "Maláj",
	"display_my" to "Burmai",
	"display_nb" to "Norvég Bokmål",
	"display_ne" to "Nepáli",
	"display_nl" to "Holland",
	"display_or" to "Oriya",
	"display_pa" to "Pandzsábi",
	"display_pl" to "Lengyel",
	"display_pt" to "Portugál",
	"display_pt_BR" to "Portugál (Brazília)",
	"display_pt_PT" to "Európai portugál",
	"display_ro" to "Román",
	"display_ru" to "Orosz",
	"display_si" to "Sinhala",
	"display_sk" to "Szlovák",
	"display_sl" to "Szlovén",
	"display_sq" to "Albán",
	"display_sr" to "Szerb",
	"display_sv" to "Svéd",
	"display_sw" to "Szuahéli",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "Thai",
	"display_tr" to "Török",
	"display_uk" to "Ukrán",
	"display_ur" to "Urdu",
	"display_uz" to "Üzbég",
	"display_vi" to "Vietnámi",
	"display_zh_Hant_TW" to "Kínai (Tajvan, Hagyományos)",
	"display_zu" to "Zulu",
	"document_estimate" to "Becslés",
	"document_invoice" to "Számla",
	"document_quote" to "Árajánlat",
	"done" to "Kész",
	"download_app" to "Az alkalmazás letöltése",
	"download_invoice" to "Számla letöltése",
	"download_pdf" to "PDF letöltése",
	"draw_signature" to "Aláírás rajzolása",
	"due" to Formattable { (date) -> "Esedékes ${date}" },
	"due_date" to "Esedékesség dátuma",
	"due_day" to "egy nap alatt",
	"due_label" to "Due",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} esedékes ${dueWhen}!" },
	"due_today" to "Ma",
	"due_week" to "egy héten belül",
	"duplicate" to "Duplikálás",
	"duration_day" to "nap",
	"duration_month" to "Hónap",
	"duration_week" to "hét",
	"duration_year" to "év",
	"email" to "E-mail",
	"email_blank_error" to "Email szükséges.",
	"email_error" to "Érvénytelen e-mail cím.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Köszönjük az üzletet. A teljes ${documentType} megtekintéséhez kattintson a levél végén található gombra. Vegye figyelembe, hogy ${dueDate}-ig érvényes." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Ön egy új ${documentType} ${invoiceNumber} dokumentumot kapott!" },
	"email_invoice_cta" to Formattable { (documentType) -> "SZÁMLA ${documentType}" },
	"email_invoice_greeting" to Formattable { (ügyfélNév) -> "Kedves ${ügyfélNév}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Köszönjük az üzletet. A teljes ${documentType} megtekintéséhez kattintson a levél végén található gombra. Kérjük, hogy a fennmaradó összeget ${dueDate}-ig fizesse ki." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Ön egy új ${documentType} ${invoiceNumber} dokumentumot kapott!" },
	"email_reset_success_message" to "A jelszó visszaállításához küldött e-mail, kérjük, ellenőrizze postaládáját.",
	"empty_clients_list_message" to "Nincsenek követendő ügyfeleid. Adjon hozzá egy új ügyfelet.",
	"empty_estimate_list_message" to "Hozzon létre egy első becslést",
	"empty_expenses" to "Nem kell nyomon követni a kiadásokat. Adjon hozzá egy új költséget.",
	"empty_invoice_list_message" to "Az első számla létrehozása",
	"empty_items_list_message" to "Nincsenek tárgyai, adjon hozzá egy új tárgyat!",
	"empty_signatures_list_message" to "Nem találtak aláírást. Hozzon létre egyet!",
	"empty_taxes_list_message" to "Nincsenek adószabályok. Adjon hozzá egy új adószabályt.",
	"empty_templates_list_message" to "Sablonok nem elérhetők.",
	"enabled" to "Engedélyezve",
	"entry" to "bejegyzés",
	"equity" to "saját tőke",
	"estimate_number" to "Becslés #",
	"estimates" to "Becslések",
	"estimates_report" to "Becslések",
	"expense_bank_fees" to "Banki díjak",
	"expense_benefits" to "Előnyök",
	"expense_communication" to "Kommunikáció",
	"expense_debt" to "Adósság",
	"expense_deleted" to "Költségek törölve",
	"expense_depreciation" to "Értékcsökkenés",
	"expense_gifts" to "Ajándékok",
	"expense_insurance" to "Biztosítás",
	"expense_inventory" to "Leltár",
	"expense_legal" to "Jogi",
	"expense_maintenance" to "Karbantartás",
	"expense_marketing" to "Marketing",
	"expense_meals" to "Étkezés",
	"expense_office_supplies" to "Irodaszerek",
	"expense_rent" to "Bérlés",
	"expense_salaries" to "Fizetések",
	"expense_shopping" to "Vásárlás",
	"expense_software" to "Szoftver",
	"expense_status_available" to "Elérhető",
	"expense_status_processing" to "Feldolgozás",
	"expense_tax" to "Adó",
	"expense_training" to "Képzés",
	"expense_transport" to "Szállítás",
	"expense_travel" to "Utazás",
	"expense_utility" to "Közművek",
	"expenses" to "Költségek",
	"feedback_option" to "Visszajelzés",
	"filter" to "Szűrő",
	"first_name_hint" to "Keresztnév",
	"forgot_password" to "Jelszó visszaállítása",
	"forgot_password_label" to "Elfelejtett jelszó",
	"from" to "a  címről",
	"general_error" to "Hiba történt, kérjük, próbálja meg később újra.",
	"general_ledger" to "főkönyvi",
	"go_back_label" to "Vissza",
	"group_by" to "Csoport:",
	"image_view" to "Kép",
	"install" to "Telepítés",
	"invalid_credentials_error" to "Érvénytelen hitelesítő adatok.",
	"invalid_email_error" to "Érvénytelen e-mail.",
	"invalid_user_error" to "Érvénytelen felhasználói hitelesítő adatok.",
	"invoice_deleted_message" to "Számla törölve",
	"invoice_menu" to "Számla menü",
	"invoice_number" to "Számla #",
	"invoices" to "Számlák",
	"items_option" to "Tételek",
	"keep" to "Tartsa a  címet.",
	"language_selector" to Formattable { (kiválasztott) -> "Nyelv ${kiválasztott}" },
	"last_name_hint" to "Vezetéknév",
	"ledger" to "főkönyv",
	"legal" to "Jogi",
	"legal_error" to "El kell fogadnia a szolgáltatási feltételeket és az adatvédelmi szabályzatot",
	"liabilities" to "kötelezettségek",
	"loading" to "Betöltés",
	"login_required_error" to "Bejelentkezés szükséges.",
	"logo_view" to "Logó",
	"logout" to "Kijelentkezés",
	"logout_prompt" to "Az Ön adatai nem szinkronizálódnak a felhővel. Számlák, becslések, ügyfelek és egyéb információk törlése kijelentkezéskor?",
	"main_screen" to "Főképernyő",
	"mark_paid" to "Fizetettként megjelölni",
	"message" to "Üzenet",
	"missing_sender_msg" to "A feladó adatait nem tartalmazó dokumentum érvénytelen lehet.",
	"missing_sender_title" to "Hiányzó feladó információ",
	"month_april" to "Április",
	"month_april_short" to "Április",
	"month_august" to "Augusztus",
	"month_august_short" to "Aug.",
	"month_december" to "December",
	"month_december_short" to "Dec.",
	"month_february" to "Február",
	"month_february_short" to "Feb.",
	"month_january" to "Január",
	"month_january_short" to "Jan.",
	"month_july" to "Július",
	"month_july_short" to "Július",
	"month_june" to "Június",
	"month_june_short" to "Június",
	"month_march" to "Március",
	"month_march_short" to "Március",
	"month_may" to "Május",
	"month_may_short" to "Május",
	"month_november" to "November",
	"month_november_short" to "Nov.",
	"month_october" to "Október",
	"month_october_short" to "Okt.",
	"month_september" to "Szeptember",
	"month_september_short" to "Szept.",
	"multifactor_error" to "Nem sikerült ellenőrizni.",
	"new" to "Új",
	"news" to "Hírek",
	"next" to "Következő",
	"no" to "nem",
	"no_data_available" to "Nem áll rendelkezésre adat",
	"no_search_results" to "Sajnáljuk, de nem találtunk semmilyen eredményt.",
	"none" to "Nincs",
	"note_fields" to "Megjegyzések",
	"note_hint" to "Megjegyzés:",
	"notifications_option" to "Értesítések",
	"ok" to "OK",
	"onboarding" to "Beépítés",
	"onboarding_aboutus_message" to "A DingVoice alapvető részei örökre ingyenesek. Küldetésünk, hogy segítsünk Önnek vállalkozásának növekedésében, segítsen nekünk a fejlődésben visszajelzései megosztásával.",
	"onboarding_aboutus_title" to "Azért vagyunk itt, hogy segítsünk",
	"onboarding_paid_1" to "Hozzáférés professzionális megjelenésű sablonokhoz",
	"onboarding_paid_2" to "Vessen mélyebb pillantást a pénzügyeire",
	"onboarding_paid_3" to "A problémák gyors megoldása",
	"onboarding_paid_4" to "Tartsa biztonságban dokumentumait",
	"onboarding_paid_5" to "Maradjon naprakész a számla státuszának változásaival kapcsolatban",
	"onboarding_paid_title" to "Még gyorsabb növekedés a...",
	"onboarding_paywall_message" to "Élvezze az összes prémium funkciót most a legjobb áron.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Csak ${currencySymbol}${introAmount} a ${introDuration} ${introPeriod} és aztán ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (valutaSzimbólum, összeg, tartam) -> "Csak ${valutaSzimbólum}${összeg} / ${tartam}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Ingyenes ${trialDuration} ${trialPeriod} és utána csak ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Ingyenes ${trialDuration} ${trialPeriod}, majd ${currencySymbol}${introductoryAmount} ${introductoryDuration} ${introPeriod} áron, majd csak ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Automatikusan megújuló. Bármikor lemondható.",
	"onboarding_paywall_restore" to "Vásárlás visszaállítása",
	"onboarding_paywall_restore_failure" to "A vásárlások visszaállítása sikertelen volt, próbálja meg később újra, vagy lépjen kapcsolatba az ügyfélszolgálattal.",
	"onboarding_paywall_restore_success" to "Visszaállítás sikeres!",
	"onboarding_paywall_title" to "Érj el nagyságot!",
	"onboarding_welcome_message" to "Teljes eszközkészlet a számlák és becslések kezeléséhez.",
	"onboarding_welcome_title" to "Üdvözöljük a DingVoice-nál",
	"other_state" to "Egyéb",
	"overdue_state" to "Késedelmes",
	"paid_state" to "Fizetett",
	"password" to "Jelszó",
	"password_blank_error" to "Jelszó szükséges.",
	"password_mismatch_error" to "A jelszavak nem egyeznek.",
	"pay" to "fizetés",
	"payment_instructions_label" to "Fizetési utasítások",
	"payment_reminders" to "Fizetési emlékeztetők",
	"phone_number_fields" to "Telefonszám",
	"phone_number_hint" to "Telefonszám",
	"postcode_hint" to "Irányítószám",
	"preview" to "Előnézet",
	"preview_menu_hint" to "Előnézet menü",
	"price" to "Ár",
	"privacy_policy" to "Adatvédelmi irányelvek",
	"profile" to "Profil",
	"profitable_client" to "A legjövedelmezőbb ügyfeled...",
	"profits_report" to "Nyereség",
	"promo_content" to "Promóciós tartalom",
	"quantity_hint" to "Mennyiség",
	"quantity_label_hint" to "Mennyiség Címke",
	"query_info" to "A lekérdezés lehetővé teszi, hogy egyéni számításokat írjon mind a tételekre, mind más adószabályokra mint bemeneti adatokra hivatkozva. @ szimbólum és tétel vagy adóleírás hozzáadása egy másik bejegyzésre való hivatkozáshoz +, -, *, /, % - matematikai operátorok",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "${documentType} ${documentNumber} érkezett az ügyfélhez." },
	"received_state" to "Beérkezett",
	"registration_code_fields" to "Regisztrációs Kód",
	"reminder" to "Emlékeztető",
	"reports" to "Jelentések",
	"reports_not_available" to "Nem áll rendelkezésre jelentések, gyere vissza később.",
	"reveal_password" to "Jelszó feltárása",
	"revenue" to "bevétel",
	"sales_tax" to "ÁFA",
	"sales_tax_number" to "ÁFA szám",
	"save" to "Mentés",
	"scan_document" to "Dokumentum beolvasása",
	"search" to "Keresés",
	"select" to "Válassza ki a  címet.",
	"select_icon" to "Ikon kiválasztása",
	"select_signature" to "Aláírás kiválasztása",
	"select_template" to "Sablon kiválasztása",
	"send" to "Küldje el a",
	"sent_state" to "Elküldve",
	"setup_sender" to "Feladó beállítása",
	"share_option" to "Megosztás",
	"ship_to" to "Szállítás a következő címre",
	"sign_in" to "Bejelentkezés",
	"sign_in_apple" to "Bejelentkezés az Apple alkalmazással",
	"sign_in_email" to "Bejelentkezés e-mailben",
	"sign_in_facebook" to "Bejelentkezés Facebookkal",
	"sign_in_google" to "Bejelentkezés a Google segítségével",
	"sign_up" to "Feliratkozás",
	"sign_up_label" to "Nincs fiókja?",
	"sign_up_link_label" to "Regisztrálj!",
	"signatures_option" to "Aláírások",
	"skip" to "Skip",
	"sold_items_report" to "Eladott tételek",
	"sort_by" to "Rendezés:",
	"status_update" to "A dokumentum állapotának frissítése",
	"sub_annual" to "Éves",
	"sub_annual_price" to Formattable { (price) -> "${price}/év" },
	"sub_backups" to "Felhő biztonsági mentések",
	"sub_insights" to "Betekintés",
	"sub_month" to "Havi",
	"sub_monthly_price" to Formattable { (price) -> "${price}/hónap" },
	"sub_notifications" to "Értesítések",
	"sub_popup_message" to "Az előfizetések hamarosan megjelennek. Nézz vissza ide egy kis idő múlva.",
	"sub_support" to "Támogatás",
	"sub_templates" to "Sablonok",
	"subscribe" to "Feliratkozás",
	"subscription_header" to "Növelje vállalkozását:",
	"subscription_option" to "Előfizetés",
	"subscriptions_load_fail" to "Sajnáljuk, de jelenleg nem tudjuk lekérni az előfizetéseit. Kérjük, próbálja meg később újra, vagy forduljon segítségért ügyfélszolgálatunkhoz.",
	"subtotal" to Formattable { (sum) -> "Részösszeg: ${sum}" },
	"subtotal_label" to "Részösszeg",
	"sum" to "Sum",
	"support_option" to "Támogatás",
	"switch_reports" to "Switch jelentés",
	"tax" to "Adó",
	"tax_deleted_message" to "Adó törölve",
	"tax_description_warning" to "Ha ezt használják, ez megjelenik a számlán",
	"tax_input_flat" to "Lapos",
	"tax_input_percent" to "Százalék",
	"tax_number_fields" to "Adószám",
	"tax_number_hint" to "Adószám",
	"tax_query_info_description" to "Információ lekérdezés",
	"tax_report" to "Adó",
	"tax_rules_option" to "Adózási szabályok",
	"taxed_clients_report" to "Adózott ügyfelek",
	"template" to "Sablon",
	"template_selector_info" to "Lapozás az alapértelmezett sablon megváltoztatásához",
	"template_unlock_promo" to "Hozzáférés több sablonhoz a Prémiummal",
	"templates_option" to "Sablonok",
	"terms_of_service" to "Szolgáltatási feltételek",
	"thank_you" to "Köszönöm",
	"to" to "a  címre.",
	"total" to Formattable { (összeg) -> "Összesen: ${összeg}" },
	"total_label" to "Összesen",
	"undo_action" to "Undo",
	"unit" to "Egység",
	"unit_cost_hint" to "Egységköltség",
	"unknown_client" to "Ismeretlen Ügyfél",
	"unlock" to "Feloldja a",
	"unnamed_item" to "Névtelen elem",
	"upgrade" to "Frissítés",
	"user_collision_error" to "A fiók már létezik.",
	"valid_until" to "Érvényesség Lejárata",
	"warning" to "Figyelem!",
	"yes" to "igen",
    )
    
}