// This file is auto generated, any changes will be overwritten
package com.dingvoice.localizations

import com.dingvoice.core.data.Formattable
import com.dingvoice.core.data.component6
import com.dingvoice.core.data.component7
import com.dingvoice.core.data.component8
import com.dingvoice.core.data.component9

 class Ca  : Locale() {
    override val accept_legal_message: Formattable = Formattable { (termsOfService, privacyPolicy) -> "Accepteu ${termsOfService} i ${privacyPolicy}" }
override val account: String = "compte"
override val accounting: String = "comptabilitat"
override val accruals: String = "meritacions"
override val action_code_error: String = "El codi no és vàlid."
override val add_client_action: String = "Afegeix un client"
override val add_customer: String = "Afegeix un client"
override val add_expense: String = "Afegeix despesa"
override val add_fields_label: String = "Sumar:"
override val add_invoice_action: String = "crear"
override val add_item_action: String = "Afegeix un element"
override val add_item_button: String = "Afegeix un element"
override val add_signature_action: String = "Afegeix una signatura"
override val add_tax_action: String = "Afegeix impostos"
override val address_fields: String = "Adreça"
override val address_line_hint: String = "Línia d'adreça"
override val advanced: String = "Avançada"
override val agree_marketing: String = "Accepto rebre comunicacions de màrqueting directe"
override val all_notifications: String = "Totes les notificacions"
override val amortization: String = "amortització"
override val amount: String = "quantitat"
override val asset: String = "actiu"
override val attach_file: String = "Adjuntar arxiu"
override val attachment: String = "Adjunt"
override val auth_error: String = "L'acció ha fallat, torneu-ho a provar."
override val available_for_paid: String = "La sincronització al núvol només està disponible sota subscripció de pagament."
override val backups_option: String = "Reserves"
override val balance: String = "Balanç"
override val bank_account: String = "Conta del banc"
override val bill_number: String = "Número de factura"
override val cancel: String = "cancel·lar"
override val cancel_trial: String = "Cancel·la en qualsevol moment"
override val capital: String = "capital"
override val cash: String = "efectiu"
override val categories: String = "Categories"
override val category: String = "Categoria"
override val category_add: String = "Afegeix una categoria"
override val category_delete: String = "Suprimeix la categoria"
override val category_name: String = "Nom de la categoria"
override val change_language: Formattable = Formattable { (language) -> "Idioma de la factura: ${language}" }
override val change_language_label: String = "Idioma de la Factura"
override val change_template: String = "Canvia la plantilla"
override val city_hint: String = "Ciutat"
override val clear: String = "Clar"
override val client: String = "Client"
override val client_actions: String = "Accions del client"
override val client_deleted_message: String = "Client suprimit"
override val clients_error_required_fields: String = "Es requereix com a mínim un valor."
override val clients_option: String = "Clients"
override val close: String = "tancar"
override val cloud_sync: String = "Sincronització del núvol"
override val coming_soon: String = "Aviat!"
override val company_logo: String = "Logotip de l'empresa"
override val company_name_hint: String = "companyia"
override val confirm_password_label: String = "Confirmació de la contrasenya"
override val confirmation_title: String = "Esteu segur?"
override val convert_to_invoice: String = "Converteix-ho en factura"
override val country_hint: String = "País"
override val create_client: String = "Crea un client"
override val create_company: String = "Crea una empresa"
override val create_document: String = "Crea un document"
override val create_estimate: String = "Estimació"
override val create_invoice: String = "Factura"
override val create_item: String = "Crea un element"
override val create_new_client: String = "Crea un client nou"
override val create_new_item: String = "Crea un element nou"
override val create_new_tax: String = "Crea un impost nou"
override val create_signature: String = "signatura"
override val created: String = "Creada"
override val credit: String = "crèdit"
override val customer: String = "Client"
override val date: String = "datar"
override val debit: String = "dèbit"
override val debt: String = "deute"
override val default_currency: Formattable = Formattable { (currency) -> "Moneda per defecte: ${currency}" }
override val default_currency_label: String = "Moneda per defecte"
override val delete: String = "esborrar"
override val delete_account: String = "Suprimeix el Compte"
override val delete_confirmation_message: String = "Totes les seves dades es perdran."
override val delete_item_title: String = "Voleu suprimir l'element?"
override val delete_signature: String = "Suprimeix la signatura"
override val depreciation: String = "amortització"
override val description: String = "descripció"
override val description_hint: String = "descripció"
override val detailed_report: String = "Informe Detallat"
override val disabled: String = "Discapacitat"
override val discount: String = "Descompte"
override val discount_hint: String = "Descompte"
override val display_af: String = "afrikaans"
override val display_am: String = "amàric"
override val display_ar: String = "Àrab"
override val display_as: String = "assameses"
override val display_az: String = "azerbaidjana"
override val display_be: String = "Bielorús"
override val display_bg: String = "búlgar"
override val display_bn: String = "bengalí"
override val display_bs: String = "bosnià"
override val display_ca: String = "Català"
override val display_cs: String = "Txec"
override val display_da: String = "danès"
override val display_de: String = "Alemany"
override val display_el: String = "Grec"
override val display_en: String = "Anglès"
override val display_es: String = "Espanyol"
override val display_es_MX: String = "espanyol (Mèxic)"
override val display_et: String = "estonià"
override val display_eu: String = "basc"
override val display_fa: String = "persa"
override val display_fi: String = "Finès"
override val display_fil: String = "filipí"
override val display_fr: String = "Francès"
override val display_fr_CA: String = "francès canadenc"
override val display_gl: String = "Gallec"
override val display_gu: String = "Gujarati"
override val display_he: String = "hebreu"
override val display_hi: String = "No"
override val display_hr: String = "croat"
override val display_hu: String = "Hongarès"
override val display_hy: String = "armeni"
override val display_id: String = "Indonesi"
override val display_is: String = "islandès"
override val display_it: String = "Italià"
override val display_ja: String = "Japonès"
override val display_ka: String = "georgiana"
override val display_kk: String = "Kazakh"
override val display_km: String = "Khmer central"
override val display_kn: String = "Kannada"
override val display_ko: String = "Coreà"
override val display_ky: String = "kirguiz"
override val display_lo: String = "Treball"
override val display_lt: String = "lituà"
override val display_lv: String = "letó"
override val display_mk: String = "macedoni"
override val display_ml: String = "malayalam"
override val display_mn: String = "mongol"
override val display_mr: String = "Marathi"
override val display_ms: String = "Malai"
override val display_my: String = "birmà"
override val display_nb: String = "Bokmål noruec"
override val display_ne: String = "nepalí"
override val display_nl: String = "Neerlandès"
override val display_or: String = "Oriya"
override val display_pa: String = "panjabi"
override val display_pl: String = "Polonès"
override val display_pt: String = "Portuguès"
override val display_pt_BR: String = "Portuguès (Brasil)"
override val display_pt_PT: String = "Portuguès europeu"
override val display_ro: String = "romanès"
override val display_ru: String = "Rus"
override val display_si: String = "Sinhala"
override val display_sk: String = "eslovac"
override val display_sl: String = "eslovè"
override val display_sq: String = "albanès"
override val display_sr: String = "serbi"
override val display_sv: String = "Suec"
override val display_sw: String = "suahili"
override val display_ta: String = "Tamil"
override val display_te: String = "Telugu"
override val display_th: String = "tailandès"
override val display_tr: String = "Turc"
override val display_uk: String = "Ucraïnès"
override val display_ur: String = "Urdú"
override val display_uz: String = "Uzbek"
override val display_vi: String = "vietnamita"
override val display_zh_Hant_TW: String = "Xinès (Taiwan, Tradicional)"
override val display_zu: String = "Zulu"
override val document_estimate: String = "Estimació"
override val document_invoice: String = "Factura"
override val document_quote: String = "Oferta"
override val done: String = "Fet"
override val download_app: String = "Descarrega l&#39;aplicació"
override val download_invoice: String = "Descarregar Factura"
override val download_pdf: String = "Descarregar PDF"
override val draw_signature: String = "Dibuixa una signatura"
override val due: Formattable = Formattable { (date) -> "Venç ${date}" }
override val due_date: String = "Data de venciment"
override val due_day: String = "en un dia"
override val due_label: String = "Venciment"
override val due_notification_body: Formattable = Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} es deu ${dueWhen}!" }
override val due_today: String = "avui"
override val due_week: String = "en una setmana"
override val duplicate: String = "Duplicat"
override val duration_day: String = "dia"
override val duration_month: String = "Mes"
override val duration_week: String = "setmana"
override val duration_year: String = "Any"
override val email: String = "Correu electrònic"
override val email_blank_error: String = "Cal un correu electrònic."
override val email_error: String = "L'adreça electrònica no és vàlida."
override val email_estimate_message: Formattable = Formattable { (documentType, dueDate) -> "Gràcies pel teu negoci. Per veure ${documentType} complet, feu clic al botó al final d&#39;aquesta carta. Tingueu en compte que és vàlid fins a ${dueDate}." }
override val email_estimate_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Heu rebut un ${documentType} ${invoiceNumber} nou!" }
override val email_invoice_cta: Formattable = Formattable { (documentType) -> "VEURE ${documentType}" }
override val email_invoice_greeting: Formattable = Formattable { (clientName) -> "Benvolgut ${clientName}" }
override val email_invoice_message: Formattable = Formattable { (documentType, dueDate) -> "Gràcies pel teu negoci. Per veure ${documentType} complet, feu clic al botó al final d&#39;aquesta carta. Pagueu el saldo fins a ${dueDate}." }
override val email_invoice_title: Formattable = Formattable { (documentType, invoiceNumber) -> "Heu rebut un ${documentType} ${invoiceNumber} nou!" }
override val email_reset_success_message: String = "Correu electrònic per restablir la contrasenya enviada, comproveu la safata d'entrada."
override val empty_clients_list_message: String = "No hi ha clients per rastrejar. Afegeix un client nou."
override val empty_estimate_list_message: String = "Crea la teva primera estimació"
override val empty_expenses: String = "No teniu despeses per fer un seguiment. Afegeix una nova despesa."
override val empty_invoice_list_message: String = "Crea la teva primera factura"
override val empty_items_list_message: String = "No teniu elements, afegiu-hi un element nou!"
override val empty_signatures_list_message: String = "No s'ha trobat cap signatura. Crea'n una!"
override val empty_taxes_list_message: String = "No hi ha regles fiscals. Afegeix una nova regla fiscal."
override val empty_templates_list_message: String = "Plantilles no disponibles."
override val enabled: String = "Permès"
override val entry: String = "entrada"
override val equity: String = "equitat"
override val estimate_number: String = "Estimació #"
override val estimates: String = "Estima"
override val estimates_report: String = "Estimacions"
override val expense_bank_fees: String = "Comissions bancàries"
override val expense_benefits: String = "BENEFICIS"
override val expense_communication: String = "Comunicació"
override val expense_debt: String = "Deute"
override val expense_deleted: String = "Despesa suprimida"
override val expense_depreciation: String = "Amortització"
override val expense_gifts: String = "Regals"
override val expense_insurance: String = "Assegurança"
override val expense_inventory: String = "Inventari"
override val expense_legal: String = "Legal"
override val expense_maintenance: String = "Manteniment"
override val expense_marketing: String = "Màrqueting"
override val expense_meals: String = "Menjars"
override val expense_office_supplies: String = "Material d&#39;oficina"
override val expense_rent: String = "Lloguer"
override val expense_salaries: String = "Salaris"
override val expense_shopping: String = "Compres"
override val expense_software: String = "Programari"
override val expense_status_available: String = "Available"
override val expense_status_processing: String = "Tramitació"
override val expense_tax: String = "Impost"
override val expense_training: String = "Formació"
override val expense_transport: String = "Transport"
override val expense_travel: String = "Viatjar"
override val expense_utility: String = "Utilitat"
override val expenses: String = "Despeses"
override val feedback_option: String = "Retroalimentació"
override val filter: String = "filtre"
override val first_name_hint: String = "Nom"
override val forgot_password: String = "Reinicialitza la contrasenya"
override val forgot_password_label: String = "S'ha oblidat la contrasenya"
override val from: String = "de"
override val general_error: String = "S&#39;ha produït un error, torneu-ho a provar més tard."
override val general_ledger: String = "llibre major"
override val go_back_label: String = "Tornar"
override val group_by: String = "Agrupa per:"
override val image_view: String = "imatge"
override val install: String = "Instal·la"
override val invalid_credentials_error: String = "Les credencials no són vàlides."
override val invalid_email_error: String = "El correu electrònic no és vàlid."
override val invalid_user_error: String = "Les credencials d'usuari no són vàlides."
override val invoice_deleted_message: String = "S'ha suprimit la factura"
override val invoice_menu: String = "Menú Factura"
override val invoice_number: String = "Factura #"
override val invoices: String = "Factures"
override val items_option: String = "Elements"
override val keep: String = "Continuar"
override val language_selector: Formattable = Formattable { (seleccionat) -> "Idioma ${seleccionat}" }
override val last_name_hint: String = "Cognoms"
override val ledger: String = "llibre major"
override val legal: String = "Legal"
override val legal_error: String = "Ha d'acceptar les condicions del servei i la política de privacitat"
override val liabilities: String = "passius"
override val loading: String = "Càrrega"
override val login_required_error: String = "Cal iniciar la sessió."
override val logo_view: String = "Logo"
override val logout: String = "Logout"
override val logout_prompt: String = "Les vostres dades no estan sincronitzades amb el núvol. Voleu suprimir factures, estimacions, clients i altra informació sobre la sortida?"
override val main_screen: String = "Pantalla principal"
override val mark_paid: String = "Marca com a pagat"
override val message: String = "Missatge"
override val missing_sender_msg: String = "És possible que un document sense informació del remitent no sigui vàlid."
override val missing_sender_title: String = "Falta informació del remitent"
override val month_april: String = "Abril"
override val month_april_short: String = "Abril"
override val month_august: String = "Agost"
override val month_august_short: String = "agost."
override val month_december: String = "Desembre"
override val month_december_short: String = "desembre"
override val month_february: String = "Febrer"
override val month_february_short: String = "Febr"
override val month_january: String = "Gener"
override val month_january_short: String = "Jan."
override val month_july: String = "Juliol"
override val month_july_short: String = "Juliol"
override val month_june: String = "Juny"
override val month_june_short: String = "Juny"
override val month_march: String = "Març"
override val month_march_short: String = "Març"
override val month_may: String = "Mai"
override val month_may_short: String = "Mai"
override val month_november: String = "Novembre"
override val month_november_short: String = "Nov."
override val month_october: String = "Octubre"
override val month_october_short: String = "Oct."
override val month_september: String = "Setembre"
override val month_september_short: String = "setembre"
override val multifactor_error: String = "No s'ha pogut verificar."
override val new: String = "Nou"
override val news: String = "Notícia"
override val next: String = "Següent"
override val no: String = "no"
override val no_data_available: String = "No hi ha dades disponibles"
override val no_search_results: String = "Ho sentim, no hem pogut trobar cap resultat."
override val none: String = "CAP"
override val note_fields: String = "Notes"
override val note_hint: String = "Nota"
override val notifications_option: String = "Notificacions"
override val ok: String = "OK"
override val onboarding: String = "En l&#39;embarcament"
override val onboarding_aboutus_message: String = "Les parts essencials de DingVoice són sempre gratuïtes. La nostra missió és ajudar-vos a fer créixer el vostre negoci, ajudar-nos a millorar compartint els vostres comentaris."
override val onboarding_aboutus_title: String = "Estem aquí per ajudar"
override val onboarding_paid_1: String = "Accediu a plantilles d&#39;aspecte professional"
override val onboarding_paid_2: String = "Fes una ullada més a fons a les teves finances"
override val onboarding_paid_3: String = "Resol els problemes ràpidament"
override val onboarding_paid_4: String = "Manteniu els vostres documents segurs"
override val onboarding_paid_5: String = "Estigueu al dia dels canvis d&#39;estat de la factura"
override val onboarding_paid_title: String = "Creixeu encara més ràpid amb..."
override val onboarding_paywall_message: String = "Gaudeix de totes les funcions premium ara al millor preu."
override val onboarding_paywall_price_introductory: Formattable = Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Només ${currencySymbol}${introAmount} per ${introDuration} ${introPeriod} i després ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_regular: Formattable = Formattable { (currencySymbol, amount, duration) -> "Només ${currencySymbol}${amount}/${duration}" }
override val onboarding_paywall_price_trial: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratuït per ${trialDuration} ${trialPeriod} i després només ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_price_trial_introductory: Formattable = Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratuït per ${trialDuration} ${trialPeriod} després ${currencySymbol}${introductoryAmount} per ${introductoryDuration} ${introPeriod} i després només ${currencySymbol}${amount} / ${duration}" }
override val onboarding_paywall_renewable: String = "Auto-renovable. Cancel·la en qualsevol moment."
override val onboarding_paywall_restore: String = "Restaurar la compra"
override val onboarding_paywall_restore_failure: String = "No s&#39;ha pogut restaurar les compres, torna-ho a provar més tard o contacta amb l&#39;assistència."
override val onboarding_paywall_restore_success: String = "Restaurar amb èxit!"
override val onboarding_paywall_title: String = "Aconsegueix la Grandesa!"
override val onboarding_welcome_message: String = "Un conjunt d&#39;eines complet per gestionar les vostres factures i pressupostos."
override val onboarding_welcome_title: String = "Benvingut a DingVoice"
override val other_state: String = "Altres"
override val overdue_state: String = "Endarrerit"
override val paid_state: String = "Pagat"
override val password: String = "contrasenya"
override val password_blank_error: String = "Cal una contrasenya."
override val password_mismatch_error: String = "Les contrasenyes no coincideixen."
override val pay: String = "pagament"
override val payment_instructions_label: String = "Instruccions de pagament"
override val payment_reminders: String = "Recordatoris de pagament"
override val phone_number_fields: String = "Número de telèfon"
override val phone_number_hint: String = "Número de telèfon"
override val postcode_hint: String = "Codi postal"
override val preview: String = "preestrena"
override val preview_menu_hint: String = "Menú de visualització prèvia"
override val price: String = "preu"
override val privacy_policy: String = "Política de privadesa"
override val profile: String = "perfil"
override val profitable_client: String = "El teu client més rendible és..."
override val profits_report: String = "Beneficis"
override val promo_content: String = "Contingut promocional"
override val quantity_hint: String = "Quantitat"
override val quantity_label_hint: String = "Etiqueta de quantitat"
override val query_info: String = "La consulta us permet escriure càlculs personalitzats que es refereixen tant a elements com a altres regles fiscals com a entrades. Afegeix @ símbol i element o descripció d'impostos per enllaçar a una altra entrada +, -, *, /, % - operadors matemàtics"
override val received_notification_body: Formattable = Formattable { (documentType, documentNumber) -> "El vostre client ha rebut ${documentType} ${documentNumber}." }
override val received_state: String = "Rebut"
override val registration_code_fields: String = "Codi de Registre"
override val reminder: String = "Recordatori"
override val reports: String = "Informes"
override val reports_not_available: String = "No hi ha informes disponibles, torneu més tard."
override val reveal_password: String = "Mostra la contrasenya"
override val revenue: String = "ingressos"
override val sales_tax: String = "IVA"
override val sales_tax_number: String = "Número d'IVA"
override val save: String = "salvar"
override val scan_document: String = "Escaneja el document"
override val search: String = "buscar"
override val select: String = "Seleccioneu"
override val select_icon: String = "Seleccioneu la icona"
override val select_signature: String = "Selecciona la signatura"
override val select_template: String = "Selecciona una plantilla"
override val send: String = "enviar"
override val sent_state: String = "Enviat"
override val setup_sender: String = "Configura el remitent"
override val share_option: String = "Compartir"
override val ship_to: String = "Envia a"
override val sign_in: String = "Inicia la sessió"
override val sign_in_apple: String = "Inicia la sessió amb Apple"
override val sign_in_email: String = "Inicia la sessió amb correu electrònic"
override val sign_in_facebook: String = "Inicia la sessió amb Facebook"
override val sign_in_google: String = "Inicia la sessió amb Google"
override val sign_up: String = "Inscriure"
override val sign_up_label: String = "No tens compte?"
override val sign_up_link_label: String = "Inscriure!"
override val signatures_option: String = "Signatures"
override val skip: String = "Ometre"
override val sold_items_report: String = "Elements Venuts"
override val sort_by: String = "Ordena per:"
override val status_update: String = "Actualització de l&#39;estat del document"
override val sub_annual: String = "Anual"
override val sub_annual_price: Formattable = Formattable { (price) -> "${price}/any" }
override val sub_backups: String = "Còpies de seguretat al núvol"
override val sub_insights: String = "Idees"
override val sub_month: String = "Mensual"
override val sub_monthly_price: Formattable = Formattable { (price) -> "${price}/mes" }
override val sub_notifications: String = "Notificacions"
override val sub_popup_message: String = "Les subscripcions arribaran aviat. Torneu-ho a comprovar aquí d'aquí a una estona."
override val sub_support: String = "Suport"
override val sub_templates: String = "Plantilles"
override val subscribe: String = "Subscriure"
override val subscription_header: String = "Fes créixer el teu negoci amb:"
override val subscription_option: String = "Subscripció"
override val subscriptions_load_fail: String = "Ho sentim, no podem recuperar les vostres subscripcions en aquest moment. Torneu-ho a provar més tard o poseu-vos en contacte amb el nostre equip d&#39;assistència per obtenir ajuda."
override val subtotal: Formattable = Formattable { (sum) -> "Subtotal: ${sum}" }
override val subtotal_label: String = "Subtotal"
override val sum: String = "Suma"
override val support_option: String = "Recolzar"
override val switch_reports: String = "Canvia l'Informe"
override val tax: String = "Impost"
override val tax_deleted_message: String = "S'ha suprimit l'impost"
override val tax_description_warning: String = "Si s'utilitza, es mostra en una factura"
override val tax_input_flat: String = "Bemoll"
override val tax_input_percent: String = "Percentatge"
override val tax_number_fields: String = "Número d'identificació fiscal"
override val tax_number_hint: String = "Número d'identificació fiscal"
override val tax_query_info_description: String = "Informació de la consulta"
override val tax_report: String = "Impost"
override val tax_rules_option: String = "Normes fiscals"
override val taxed_clients_report: String = "Clients amb impostos"
override val template: String = "plantilla"
override val template_selector_info: String = "Passa el dit per canviar la plantilla per defecte"
override val template_unlock_promo: String = "Accediu a més plantilles amb Premium"
override val templates_option: String = "Plantilles"
override val terms_of_service: String = "Condicions del servei"
override val thank_you: String = "Gràcies"
override val to: String = "a"
override val total: Formattable = Formattable { (sum) -> "Total: ${sum}" }
override val total_label: String = "Total"
override val undo_action: String = "Desfer"
override val unit: String = "Unitat"
override val unit_cost_hint: String = "Preu unitari"
override val unknown_client: String = "Client desconegut"
override val unlock: String = "Desblocar"
override val unnamed_item: String = "Element sense nom"
override val upgrade: String = "Rampa"
override val user_collision_error: String = "El compte ja existeix."
override val valid_until: String = "Vàlid fins"
override val warning: String = "Advertiment!"
override val yes: String = "sí"
    override val keysMap: Map<String, Any> = mapOf(
        "accept_legal_message" to Formattable { (termsOfService, privacyPolicy) -> "Accepteu ${termsOfService} i ${privacyPolicy}" },
	"account" to "compte",
	"accounting" to "comptabilitat",
	"accruals" to "meritacions",
	"action_code_error" to "El codi no és vàlid.",
	"add_client_action" to "Afegeix un client",
	"add_customer" to "Afegeix un client",
	"add_expense" to "Afegeix despesa",
	"add_fields_label" to "Sumar:",
	"add_invoice_action" to "crear",
	"add_item_action" to "Afegeix un element",
	"add_item_button" to "Afegeix un element",
	"add_signature_action" to "Afegeix una signatura",
	"add_tax_action" to "Afegeix impostos",
	"address_fields" to "Adreça",
	"address_line_hint" to "Línia d'adreça",
	"advanced" to "Avançada",
	"agree_marketing" to "Accepto rebre comunicacions de màrqueting directe",
	"all_notifications" to "Totes les notificacions",
	"amortization" to "amortització",
	"amount" to "quantitat",
	"asset" to "actiu",
	"attach_file" to "Adjuntar arxiu",
	"attachment" to "Adjunt",
	"auth_error" to "L'acció ha fallat, torneu-ho a provar.",
	"available_for_paid" to "La sincronització al núvol només està disponible sota subscripció de pagament.",
	"backups_option" to "Reserves",
	"balance" to "Balanç",
	"bank_account" to "Conta del banc",
	"bill_number" to "Número de factura",
	"cancel" to "cancel·lar",
	"cancel_trial" to "Cancel·la en qualsevol moment",
	"capital" to "capital",
	"cash" to "efectiu",
	"categories" to "Categories",
	"category" to "Categoria",
	"category_add" to "Afegeix una categoria",
	"category_delete" to "Suprimeix la categoria",
	"category_name" to "Nom de la categoria",
	"change_language" to Formattable { (language) -> "Idioma de la factura: ${language}" },
	"change_language_label" to "Idioma de la Factura",
	"change_template" to "Canvia la plantilla",
	"city_hint" to "Ciutat",
	"clear" to "Clar",
	"client" to "Client",
	"client_actions" to "Accions del client",
	"client_deleted_message" to "Client suprimit",
	"clients_error_required_fields" to "Es requereix com a mínim un valor.",
	"clients_option" to "Clients",
	"close" to "tancar",
	"cloud_sync" to "Sincronització del núvol",
	"coming_soon" to "Aviat!",
	"company_logo" to "Logotip de l'empresa",
	"company_name_hint" to "companyia",
	"confirm_password_label" to "Confirmació de la contrasenya",
	"confirmation_title" to "Esteu segur?",
	"convert_to_invoice" to "Converteix-ho en factura",
	"country_hint" to "País",
	"create_client" to "Crea un client",
	"create_company" to "Crea una empresa",
	"create_document" to "Crea un document",
	"create_estimate" to "Estimació",
	"create_invoice" to "Factura",
	"create_item" to "Crea un element",
	"create_new_client" to "Crea un client nou",
	"create_new_item" to "Crea un element nou",
	"create_new_tax" to "Crea un impost nou",
	"create_signature" to "signatura",
	"created" to "Creada",
	"credit" to "crèdit",
	"customer" to "Client",
	"date" to "datar",
	"debit" to "dèbit",
	"debt" to "deute",
	"default_currency" to Formattable { (currency) -> "Moneda per defecte: ${currency}" },
	"default_currency_label" to "Moneda per defecte",
	"delete" to "esborrar",
	"delete_account" to "Suprimeix el Compte",
	"delete_confirmation_message" to "Totes les seves dades es perdran.",
	"delete_item_title" to "Voleu suprimir l'element?",
	"delete_signature" to "Suprimeix la signatura",
	"depreciation" to "amortització",
	"description" to "descripció",
	"description_hint" to "descripció",
	"detailed_report" to "Informe Detallat",
	"disabled" to "Discapacitat",
	"discount" to "Descompte",
	"discount_hint" to "Descompte",
	"display_af" to "afrikaans",
	"display_am" to "amàric",
	"display_ar" to "Àrab",
	"display_as" to "assameses",
	"display_az" to "azerbaidjana",
	"display_be" to "Bielorús",
	"display_bg" to "búlgar",
	"display_bn" to "bengalí",
	"display_bs" to "bosnià",
	"display_ca" to "Català",
	"display_cs" to "Txec",
	"display_da" to "danès",
	"display_de" to "Alemany",
	"display_el" to "Grec",
	"display_en" to "Anglès",
	"display_es" to "Espanyol",
	"display_es_MX" to "espanyol (Mèxic)",
	"display_et" to "estonià",
	"display_eu" to "basc",
	"display_fa" to "persa",
	"display_fi" to "Finès",
	"display_fil" to "filipí",
	"display_fr" to "Francès",
	"display_fr_CA" to "francès canadenc",
	"display_gl" to "Gallec",
	"display_gu" to "Gujarati",
	"display_he" to "hebreu",
	"display_hi" to "No",
	"display_hr" to "croat",
	"display_hu" to "Hongarès",
	"display_hy" to "armeni",
	"display_id" to "Indonesi",
	"display_is" to "islandès",
	"display_it" to "Italià",
	"display_ja" to "Japonès",
	"display_ka" to "georgiana",
	"display_kk" to "Kazakh",
	"display_km" to "Khmer central",
	"display_kn" to "Kannada",
	"display_ko" to "Coreà",
	"display_ky" to "kirguiz",
	"display_lo" to "Treball",
	"display_lt" to "lituà",
	"display_lv" to "letó",
	"display_mk" to "macedoni",
	"display_ml" to "malayalam",
	"display_mn" to "mongol",
	"display_mr" to "Marathi",
	"display_ms" to "Malai",
	"display_my" to "birmà",
	"display_nb" to "Bokmål noruec",
	"display_ne" to "nepalí",
	"display_nl" to "Neerlandès",
	"display_or" to "Oriya",
	"display_pa" to "panjabi",
	"display_pl" to "Polonès",
	"display_pt" to "Portuguès",
	"display_pt_BR" to "Portuguès (Brasil)",
	"display_pt_PT" to "Portuguès europeu",
	"display_ro" to "romanès",
	"display_ru" to "Rus",
	"display_si" to "Sinhala",
	"display_sk" to "eslovac",
	"display_sl" to "eslovè",
	"display_sq" to "albanès",
	"display_sr" to "serbi",
	"display_sv" to "Suec",
	"display_sw" to "suahili",
	"display_ta" to "Tamil",
	"display_te" to "Telugu",
	"display_th" to "tailandès",
	"display_tr" to "Turc",
	"display_uk" to "Ucraïnès",
	"display_ur" to "Urdú",
	"display_uz" to "Uzbek",
	"display_vi" to "vietnamita",
	"display_zh_Hant_TW" to "Xinès (Taiwan, Tradicional)",
	"display_zu" to "Zulu",
	"document_estimate" to "Estimació",
	"document_invoice" to "Factura",
	"document_quote" to "Oferta",
	"done" to "Fet",
	"download_app" to "Descarrega l&#39;aplicació",
	"download_invoice" to "Descarregar Factura",
	"download_pdf" to "Descarregar PDF",
	"draw_signature" to "Dibuixa una signatura",
	"due" to Formattable { (date) -> "Venç ${date}" },
	"due_date" to "Data de venciment",
	"due_day" to "en un dia",
	"due_label" to "Venciment",
	"due_notification_body" to Formattable { (documentType, documentNumber, dueWhen) -> "${documentType} ${documentNumber} es deu ${dueWhen}!" },
	"due_today" to "avui",
	"due_week" to "en una setmana",
	"duplicate" to "Duplicat",
	"duration_day" to "dia",
	"duration_month" to "Mes",
	"duration_week" to "setmana",
	"duration_year" to "Any",
	"email" to "Correu electrònic",
	"email_blank_error" to "Cal un correu electrònic.",
	"email_error" to "L'adreça electrònica no és vàlida.",
	"email_estimate_message" to Formattable { (documentType, dueDate) -> "Gràcies pel teu negoci. Per veure ${documentType} complet, feu clic al botó al final d&#39;aquesta carta. Tingueu en compte que és vàlid fins a ${dueDate}." },
	"email_estimate_title" to Formattable { (documentType, invoiceNumber) -> "Heu rebut un ${documentType} ${invoiceNumber} nou!" },
	"email_invoice_cta" to Formattable { (documentType) -> "VEURE ${documentType}" },
	"email_invoice_greeting" to Formattable { (clientName) -> "Benvolgut ${clientName}" },
	"email_invoice_message" to Formattable { (documentType, dueDate) -> "Gràcies pel teu negoci. Per veure ${documentType} complet, feu clic al botó al final d&#39;aquesta carta. Pagueu el saldo fins a ${dueDate}." },
	"email_invoice_title" to Formattable { (documentType, invoiceNumber) -> "Heu rebut un ${documentType} ${invoiceNumber} nou!" },
	"email_reset_success_message" to "Correu electrònic per restablir la contrasenya enviada, comproveu la safata d'entrada.",
	"empty_clients_list_message" to "No hi ha clients per rastrejar. Afegeix un client nou.",
	"empty_estimate_list_message" to "Crea la teva primera estimació",
	"empty_expenses" to "No teniu despeses per fer un seguiment. Afegeix una nova despesa.",
	"empty_invoice_list_message" to "Crea la teva primera factura",
	"empty_items_list_message" to "No teniu elements, afegiu-hi un element nou!",
	"empty_signatures_list_message" to "No s'ha trobat cap signatura. Crea'n una!",
	"empty_taxes_list_message" to "No hi ha regles fiscals. Afegeix una nova regla fiscal.",
	"empty_templates_list_message" to "Plantilles no disponibles.",
	"enabled" to "Permès",
	"entry" to "entrada",
	"equity" to "equitat",
	"estimate_number" to "Estimació #",
	"estimates" to "Estima",
	"estimates_report" to "Estimacions",
	"expense_bank_fees" to "Comissions bancàries",
	"expense_benefits" to "BENEFICIS",
	"expense_communication" to "Comunicació",
	"expense_debt" to "Deute",
	"expense_deleted" to "Despesa suprimida",
	"expense_depreciation" to "Amortització",
	"expense_gifts" to "Regals",
	"expense_insurance" to "Assegurança",
	"expense_inventory" to "Inventari",
	"expense_legal" to "Legal",
	"expense_maintenance" to "Manteniment",
	"expense_marketing" to "Màrqueting",
	"expense_meals" to "Menjars",
	"expense_office_supplies" to "Material d&#39;oficina",
	"expense_rent" to "Lloguer",
	"expense_salaries" to "Salaris",
	"expense_shopping" to "Compres",
	"expense_software" to "Programari",
	"expense_status_available" to "Available",
	"expense_status_processing" to "Tramitació",
	"expense_tax" to "Impost",
	"expense_training" to "Formació",
	"expense_transport" to "Transport",
	"expense_travel" to "Viatjar",
	"expense_utility" to "Utilitat",
	"expenses" to "Despeses",
	"feedback_option" to "Retroalimentació",
	"filter" to "filtre",
	"first_name_hint" to "Nom",
	"forgot_password" to "Reinicialitza la contrasenya",
	"forgot_password_label" to "S'ha oblidat la contrasenya",
	"from" to "de",
	"general_error" to "S&#39;ha produït un error, torneu-ho a provar més tard.",
	"general_ledger" to "llibre major",
	"go_back_label" to "Tornar",
	"group_by" to "Agrupa per:",
	"image_view" to "imatge",
	"install" to "Instal·la",
	"invalid_credentials_error" to "Les credencials no són vàlides.",
	"invalid_email_error" to "El correu electrònic no és vàlid.",
	"invalid_user_error" to "Les credencials d'usuari no són vàlides.",
	"invoice_deleted_message" to "S'ha suprimit la factura",
	"invoice_menu" to "Menú Factura",
	"invoice_number" to "Factura #",
	"invoices" to "Factures",
	"items_option" to "Elements",
	"keep" to "Continuar",
	"language_selector" to Formattable { (seleccionat) -> "Idioma ${seleccionat}" },
	"last_name_hint" to "Cognoms",
	"ledger" to "llibre major",
	"legal" to "Legal",
	"legal_error" to "Ha d'acceptar les condicions del servei i la política de privacitat",
	"liabilities" to "passius",
	"loading" to "Càrrega",
	"login_required_error" to "Cal iniciar la sessió.",
	"logo_view" to "Logo",
	"logout" to "Logout",
	"logout_prompt" to "Les vostres dades no estan sincronitzades amb el núvol. Voleu suprimir factures, estimacions, clients i altra informació sobre la sortida?",
	"main_screen" to "Pantalla principal",
	"mark_paid" to "Marca com a pagat",
	"message" to "Missatge",
	"missing_sender_msg" to "És possible que un document sense informació del remitent no sigui vàlid.",
	"missing_sender_title" to "Falta informació del remitent",
	"month_april" to "Abril",
	"month_april_short" to "Abril",
	"month_august" to "Agost",
	"month_august_short" to "agost.",
	"month_december" to "Desembre",
	"month_december_short" to "desembre",
	"month_february" to "Febrer",
	"month_february_short" to "Febr",
	"month_january" to "Gener",
	"month_january_short" to "Jan.",
	"month_july" to "Juliol",
	"month_july_short" to "Juliol",
	"month_june" to "Juny",
	"month_june_short" to "Juny",
	"month_march" to "Març",
	"month_march_short" to "Març",
	"month_may" to "Mai",
	"month_may_short" to "Mai",
	"month_november" to "Novembre",
	"month_november_short" to "Nov.",
	"month_october" to "Octubre",
	"month_october_short" to "Oct.",
	"month_september" to "Setembre",
	"month_september_short" to "setembre",
	"multifactor_error" to "No s'ha pogut verificar.",
	"new" to "Nou",
	"news" to "Notícia",
	"next" to "Següent",
	"no" to "no",
	"no_data_available" to "No hi ha dades disponibles",
	"no_search_results" to "Ho sentim, no hem pogut trobar cap resultat.",
	"none" to "CAP",
	"note_fields" to "Notes",
	"note_hint" to "Nota",
	"notifications_option" to "Notificacions",
	"ok" to "OK",
	"onboarding" to "En l&#39;embarcament",
	"onboarding_aboutus_message" to "Les parts essencials de DingVoice són sempre gratuïtes. La nostra missió és ajudar-vos a fer créixer el vostre negoci, ajudar-nos a millorar compartint els vostres comentaris.",
	"onboarding_aboutus_title" to "Estem aquí per ajudar",
	"onboarding_paid_1" to "Accediu a plantilles d&#39;aspecte professional",
	"onboarding_paid_2" to "Fes una ullada més a fons a les teves finances",
	"onboarding_paid_3" to "Resol els problemes ràpidament",
	"onboarding_paid_4" to "Manteniu els vostres documents segurs",
	"onboarding_paid_5" to "Estigueu al dia dels canvis d&#39;estat de la factura",
	"onboarding_paid_title" to "Creixeu encara més ràpid amb...",
	"onboarding_paywall_message" to "Gaudeix de totes les funcions premium ara al millor preu.",
	"onboarding_paywall_price_introductory" to Formattable { (currencySymbol, introAmount, introDuration, introPeriod, amount, duration) -> "Només ${currencySymbol}${introAmount} per ${introDuration} ${introPeriod} i després ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_regular" to Formattable { (currencySymbol, amount, duration) -> "Només ${currencySymbol}${amount}/${duration}" },
	"onboarding_paywall_price_trial" to Formattable { (trialDuration, trialPeriod, currencySymbol, amount, duration) -> "Gratuït per ${trialDuration} ${trialPeriod} i després només ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_price_trial_introductory" to Formattable { (trialDuration, trialPeriod, currencySymbol, introductoryAmount, introductoryDuration, introPeriod, amount, duration) -> "Gratuït per ${trialDuration} ${trialPeriod} després ${currencySymbol}${introductoryAmount} per ${introductoryDuration} ${introPeriod} i després només ${currencySymbol}${amount} / ${duration}" },
	"onboarding_paywall_renewable" to "Auto-renovable. Cancel·la en qualsevol moment.",
	"onboarding_paywall_restore" to "Restaurar la compra",
	"onboarding_paywall_restore_failure" to "No s&#39;ha pogut restaurar les compres, torna-ho a provar més tard o contacta amb l&#39;assistència.",
	"onboarding_paywall_restore_success" to "Restaurar amb èxit!",
	"onboarding_paywall_title" to "Aconsegueix la Grandesa!",
	"onboarding_welcome_message" to "Un conjunt d&#39;eines complet per gestionar les vostres factures i pressupostos.",
	"onboarding_welcome_title" to "Benvingut a DingVoice",
	"other_state" to "Altres",
	"overdue_state" to "Endarrerit",
	"paid_state" to "Pagat",
	"password" to "contrasenya",
	"password_blank_error" to "Cal una contrasenya.",
	"password_mismatch_error" to "Les contrasenyes no coincideixen.",
	"pay" to "pagament",
	"payment_instructions_label" to "Instruccions de pagament",
	"payment_reminders" to "Recordatoris de pagament",
	"phone_number_fields" to "Número de telèfon",
	"phone_number_hint" to "Número de telèfon",
	"postcode_hint" to "Codi postal",
	"preview" to "preestrena",
	"preview_menu_hint" to "Menú de visualització prèvia",
	"price" to "preu",
	"privacy_policy" to "Política de privadesa",
	"profile" to "perfil",
	"profitable_client" to "El teu client més rendible és...",
	"profits_report" to "Beneficis",
	"promo_content" to "Contingut promocional",
	"quantity_hint" to "Quantitat",
	"quantity_label_hint" to "Etiqueta de quantitat",
	"query_info" to "La consulta us permet escriure càlculs personalitzats que es refereixen tant a elements com a altres regles fiscals com a entrades. Afegeix @ símbol i element o descripció d'impostos per enllaçar a una altra entrada +, -, *, /, % - operadors matemàtics",
	"received_notification_body" to Formattable { (documentType, documentNumber) -> "El vostre client ha rebut ${documentType} ${documentNumber}." },
	"received_state" to "Rebut",
	"registration_code_fields" to "Codi de Registre",
	"reminder" to "Recordatori",
	"reports" to "Informes",
	"reports_not_available" to "No hi ha informes disponibles, torneu més tard.",
	"reveal_password" to "Mostra la contrasenya",
	"revenue" to "ingressos",
	"sales_tax" to "IVA",
	"sales_tax_number" to "Número d'IVA",
	"save" to "salvar",
	"scan_document" to "Escaneja el document",
	"search" to "buscar",
	"select" to "Seleccioneu",
	"select_icon" to "Seleccioneu la icona",
	"select_signature" to "Selecciona la signatura",
	"select_template" to "Selecciona una plantilla",
	"send" to "enviar",
	"sent_state" to "Enviat",
	"setup_sender" to "Configura el remitent",
	"share_option" to "Compartir",
	"ship_to" to "Envia a",
	"sign_in" to "Inicia la sessió",
	"sign_in_apple" to "Inicia la sessió amb Apple",
	"sign_in_email" to "Inicia la sessió amb correu electrònic",
	"sign_in_facebook" to "Inicia la sessió amb Facebook",
	"sign_in_google" to "Inicia la sessió amb Google",
	"sign_up" to "Inscriure",
	"sign_up_label" to "No tens compte?",
	"sign_up_link_label" to "Inscriure!",
	"signatures_option" to "Signatures",
	"skip" to "Ometre",
	"sold_items_report" to "Elements Venuts",
	"sort_by" to "Ordena per:",
	"status_update" to "Actualització de l&#39;estat del document",
	"sub_annual" to "Anual",
	"sub_annual_price" to Formattable { (price) -> "${price}/any" },
	"sub_backups" to "Còpies de seguretat al núvol",
	"sub_insights" to "Idees",
	"sub_month" to "Mensual",
	"sub_monthly_price" to Formattable { (price) -> "${price}/mes" },
	"sub_notifications" to "Notificacions",
	"sub_popup_message" to "Les subscripcions arribaran aviat. Torneu-ho a comprovar aquí d'aquí a una estona.",
	"sub_support" to "Suport",
	"sub_templates" to "Plantilles",
	"subscribe" to "Subscriure",
	"subscription_header" to "Fes créixer el teu negoci amb:",
	"subscription_option" to "Subscripció",
	"subscriptions_load_fail" to "Ho sentim, no podem recuperar les vostres subscripcions en aquest moment. Torneu-ho a provar més tard o poseu-vos en contacte amb el nostre equip d&#39;assistència per obtenir ajuda.",
	"subtotal" to Formattable { (sum) -> "Subtotal: ${sum}" },
	"subtotal_label" to "Subtotal",
	"sum" to "Suma",
	"support_option" to "Recolzar",
	"switch_reports" to "Canvia l'Informe",
	"tax" to "Impost",
	"tax_deleted_message" to "S'ha suprimit l'impost",
	"tax_description_warning" to "Si s'utilitza, es mostra en una factura",
	"tax_input_flat" to "Bemoll",
	"tax_input_percent" to "Percentatge",
	"tax_number_fields" to "Número d'identificació fiscal",
	"tax_number_hint" to "Número d'identificació fiscal",
	"tax_query_info_description" to "Informació de la consulta",
	"tax_report" to "Impost",
	"tax_rules_option" to "Normes fiscals",
	"taxed_clients_report" to "Clients amb impostos",
	"template" to "plantilla",
	"template_selector_info" to "Passa el dit per canviar la plantilla per defecte",
	"template_unlock_promo" to "Accediu a més plantilles amb Premium",
	"templates_option" to "Plantilles",
	"terms_of_service" to "Condicions del servei",
	"thank_you" to "Gràcies",
	"to" to "a",
	"total" to Formattable { (sum) -> "Total: ${sum}" },
	"total_label" to "Total",
	"undo_action" to "Desfer",
	"unit" to "Unitat",
	"unit_cost_hint" to "Preu unitari",
	"unknown_client" to "Client desconegut",
	"unlock" to "Desblocar",
	"unnamed_item" to "Element sense nom",
	"upgrade" to "Rampa",
	"user_collision_error" to "El compte ja existeix.",
	"valid_until" to "Vàlid fins",
	"warning" to "Advertiment!",
	"yes" to "sí",
    )
    
}